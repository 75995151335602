import {
  Box,
  Button,
  IconButton,
  InputBase,
  Modal,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import InputBox from "../../../common/InputBox";
import { useDispatch } from "react-redux";
import { updateSuccessRate } from "../../../../redux/admin/maintenance";

const SuccessRatePopup = ({ handleClose, open, value ,jobId,allJobs,setAllJobs,handleClosePopup}) => {
  const theme = useTheme();
  const [errors, setErrors] = useState(false);
  const [formState, setFormState] = useState("");
  const dispatch = useDispatch();


  const handleUpdateSuccess = async () => {
    const payload = {
      job_id:jobId,
      value: formState,
    };
    if (!formState) {
      setErrors(true);
    } else {
      const response = await dispatch(updateSuccessRate(payload));
      if (response?.payload?.status === "success") {
        let updatedData = [...allJobs];
      let index = updatedData.findIndex((item) => item?.job_id === jobId);
      if (index !== -1) {
        updatedData[index] = { ...updatedData[index], success_fees: formState };
      }
      setAllJobs(updatedData);
      handleClosePopup()
      handleClose()
      }
    }
  };

  const handleBountyChane = (e) => {
    setFormState(e.target.value);
  };
  useEffect(() => {
    setFormState(value);
  }, [value]);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    height: "300px",
    width: "450px",
    bgcolor: "background.paper",
    borderRadius: "25px",
    // pyb: 4,
    boxShadow: "none !important",
    // border: "1px solid black !important",
    "&:focus-visible": {
      outline: "none",
    },
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Button
          variant="contained"
          color="promoterButton"
          sx={{ width: "150px", height: "45px", borderRadius: "25px 0 25px 0" }}
        >
          edit success
        </Button>
        <IconButton
          aria-label="close"
          onClick={() => {
            handleClose();
          }}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
          disableRipple={true}
        >
          <Box
            sx={{
              width: "20px",
              height: "20px",
              color: "#000000",
              border: 2,
              fontSize: "18px",
              borderRadius: "5px",
            }}
          >
            X
          </Box>
        </IconButton>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            height: "calc(100% - 45px)",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "30px",
              padding: "16px",
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Typography
                sx={{
                  fontSize: "14px",
                  width: "120px",
                  fontWeight: theme.typography.Bold,
                }}
              >
                Success Rate
              </Typography>
              <Box sx={{ display: "flex", gap: "10px", alignItems: "center" }}>
                <InputBox
                  id="successRate"
                  value={formState}
                  type="number"
                  onChange={(event) => handleBountyChane(event)}
                  placeholder={"Enter success rate"}
                  sx={{
                    border: errors
                      ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
                      : "1px solid #E0E0E0",
                    width: "80% !important",
                    borderRadius: "10px !important",
                    "& .MuiInputBase-root": {
                      margin: "0 10px",
                    },
                  }}
                  min={0}
                />
              </Box>
            </Box>
          </Box>

          <Button
            variant="contained"
            color="redButton"
            sx={{
              width: "100%",
              height: "60px",
              borderRadius: "0 0 25px 25px",
            }}
            onClick={() => handleUpdateSuccess()}
          >
            update
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default SuccessRatePopup;
