import { useEffect, useState } from "react";
import { useTheme } from "@mui/material/styles";
import job_volume from "../../../assets/Padding Excluded/Crayon Icon_Promote.svg";
import job_exp from "../../../assets/Padding Included/Green_Duration.svg";
import profileURL from "../../../assets/Crayon_Favicon.svg";

import profileIcon from "../../../assets/Padding Excluded/Black_Talent copy.svg";
import downArrow from "../../../assets/Black_Down_Open - Copy.svg";
import upArrow from "../../../assets/Black_Up_Close - Copy.svg";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Unstable_Grid2";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import locale from "../../../i18n/locale";
import MUIRadialChart from "../MUIRadialChart";
import ButtonMenu from "./ButtonMenu";
import {
  convertDatetimeAgo,
  dateConverterMonth,
  daysRemainingToComplete30Days,
} from "../../../utils/DateTime";
import { Card, Tooltip } from "@mui/material";
import ManageButtonMenu from "./ManageButtonMenu";
import { Link, useNavigate } from "react-router-dom";
import { formatCurrencyWithCommas } from "../../../utils/Currency";
import { Circle } from "@mui/icons-material";
import TrackButton from "./TrackButton";
import Slider from "../Slider";

import BlueCurrency from "../../../assets/Blue_Salary.svg";
import redLocation from "../../../assets/Red_Location.svg";
import calendar from "../../../assets/Padding Included/Yellow_Calendar.svg";

import flip from "../../../assets/NewFlipIcon.svg";
import profile_challenger from "../../../assets/Personality Logos/personality_characters_challenger.svg";

// import profile_challenger from "../../../assets/Personality Logos/crayon_personality_grey_challeneger.svg";
import profile_grey_challenger from "../../../assets/Personality Logos/crayon_personality_grey_challenger.svg";
import profile_grey_character from "../../../assets/Personality Logos/crayon_personality_grey_character.svg";
import profile_character from "../../../assets/Personality Logos/crayon_personality_character.svg";
import profile_collaborator from "../../../assets/Personality Logos/crayon_personality_collaborator.svg";
import profile_contemplator from "../../../assets/Personality Logos/crayon_personality_contemplator.svg";

import TalentIcon from "../../../assets/Padding Included/White_Talent.svg";
import Slider2 from "../Slider2";
import TrackButtonLayout from "./TrackButtonLayout";
import {
  getToken,
  renderColor,
  splitString,
  useQuery,
} from "../../../utils/Common";
import playButtonIcon from "../../../assets/Padding Excluded/Black_reactive_job copy.svg";
import playButtonIconWhite from "../../../assets/Padding Excluded/Black_reactive_job.svg";
import submitCandidate from "../../../assets/Padding Excluded/SubmitCandidate.svg";

import jwt_decode from "jwt-decode";
import SubmitCandidate from "./SubmitCandidate";
import NewSlider from "../NewSlider";
import JobPromoteCard from "../../promoter/JobPromoteCard";
import post_incognito from "../../../assets/Incognito.svg";
import { LightTooltip } from "../../../utils/CommonComponent";
import { setAlert } from "../../../redux/configSlice";
import { ALERT_TYPE } from "../../../utils/Constants";
import { useDispatch } from "react-redux";
import lockIcon from "../../../assets/Padding Excluded/Black_Lock.svg";
import unlockIcon from "../../../assets/Padding Excluded/White_Unlock.svg";
import externalLink from "../../../assets/Padding Excluded/External_Link.svg";
import greyExternalLink from "../../../assets/WhiteIcons/Grey_External_Link.svg";
import JobsSlider from "../../guest/jobs/JobsSlider";

export default function MySpecBackCard({
  index,
  job,
  setisFlipped,
  handleReviewVideoDialog,
  newCardWidth,
  hasJobVideo,
  setOpenRecordDialog,
  isHorizontalFlipped,
  indexNumber,
  jobslength,
  currentPageJobsCount,
  setShowLogin,
  setAllJobs,
  allJobs,
  setCoinSpend,
  setJobExtend,
  setSelectedJob,
  setGetCoins,
  setNoCoins,
  setOpenExternal,
  setActiveStep,
  setSelectedPage,
}) {
  const i18n = locale.en;
  const dispatch = useDispatch();
  const query = useQuery();
  const theme = useTheme();
  const [buttonMenu, setButtonMenu] = useState(false);
  const [trackButton, setTrackButton] = useState(false);
  const [cardWidth, setCardWidth] = useState();
  const [isSubmitCandidate, setIsSubmitCandidate] = useState(false);
  const [jobPromote, setJobPromote] = useState(false);

  const Skills = splitString(job?.JobSkills);
  const Tools = splitString(job?.JobTools);
  const Traits = splitString(job?.JobTraits);
  const history = useNavigate();

  const token = getToken();

  let decodedToken;
  if (token) {
    decodedToken = jwt_decode(token);
  }
  const planType = decodedToken?.data?.plan_type;

  const handleVideoButton = (video) => {
    !video && handleReviewVideoDialog(true);
  };

  const calculateHeight = () => {
    const screenWidth = window.innerWidth;

    if (screenWidth < 600) {
      // xs
      return 100;
    } else if (screenWidth < 900) {
      // sm
      return 100;
    } else if (screenWidth < 1366) {
      // md
      return 100;
    } else if (screenWidth < 1920) {
      // lg
      return 100;
    } else if (screenWidth < 2559) {
      // xl
      return 100;
    } else {
      // xxl or greater
      return 100;
    }
  };

  const getCardWidth = () => {
    const cardElement = document.querySelector(".sliderClass");
    if (cardElement) {
      const cardWidth = cardElement?.clientWidth;
      setCardWidth(cardWidth);
    }
    return null; // Return null if the card element is not found
  };

  const handleSubmitCandidate = () => {
    // if (job?.jobStatusName === "active") {
    //   setIsSubmitCandidate(true);
    // } else {
    //   dispatch(
    //     setAlert({
    //       show: true,
    //       type: ALERT_TYPE?.ERROR,
    //       msg: "Job is not active",
    //     })
    //   );
    // }
  };

  const unlockManageTalent = () => {
    if (job?.job_unlock || decodedToken?.data?.talent_plan) {
      if (decodedToken?.data?.role_id === 6) {
        window.open(`/recruiter/manage-talent/${job?.job_id}`, "_blank");
      } else {
        window.open(`/employer/manage-talent/${job?.job_id}`, "_blank");
      }
    } else {
      setSelectedJob(job);
      setGetCoins(true);
    }
  };

  useEffect(() => {
    getCardWidth();
    window.addEventListener("resize", getCardWidth);
    return () => {
      window.removeEventListener("resize", getCardWidth);
    };
  }, [cardWidth]);
  const handleEditJob = () => {
    setActiveStep(0);
    setSelectedPage(1);
    decodedToken?.data?.role_id === 6
      ? history(`/recruiter/post-a-job/${job?.job_id}`)
      : history(`/employer/post-a-job/${job?.job_id}`);
  };
  const handleClickViewJob = () => {
    const url = job?.post_incognito
      ? `/jobs/${job?.country_name?.replace(
          /\s|\//g,
          ""
        )}/${job?.townName?.replace(/\s|\//g, "")}/${job?.title?.replace(
          /\s|\//g,
          ""
        )}/${job?.job_id}`
      : `/jobs/${job?.country_name?.replace(
          /\s|\//g,
          ""
        )}/${job?.townName?.replace(/\s|\//g, "")}/${job?.CompanyName?.replace(
          /\s|\//g,
          ""
        )}/${job?.title?.replace(/\s|\//g, "")}/${job?.job_id}`;
    window.open(url, "_blank");
  };
  return (
    <Card
      sx={{
        borderRadius: "25px",
        position: "relative",
        flexGrow: 1,
        display: "flex",
        flexDirection: "column",
        boxShadow: "0px 5px 10px #00000029",
        height: "100%",
        justifyContent: "space-between",
      }}
    >
      {!isSubmitCandidate && !jobPromote && (
        <>
          <Grid
            container
            justifyContent="space-between"
            overflow={"hidden"}
            sx={{
              borderRadius: "25px 25px 0 0",
              width: "100%",
              flexWrap: "nowrap",
            }}
          >
            <Box sx={{ flexGrow: 1 }}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Box></Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    width: "240px",
                  }}
                >
                  <>
                    <Box sx={{ display: "flex", paddingBottom: "7px" }}>
                      <Button
                        color={`${job?.job_type}Button`}
                        variant="contained"
                        sx={{
                          height: "auto",
                          minWidth: "60px",
                          minHeight: "45px",
                          borderRadius: "0 0 0 10px",
                          padding: 0,
                          fontSize: theme.typography.fontSize.xs,
                          fontWeight: theme.typography.Bold,
                          cursor: "default",
                          ":hover": {
                            boxShadow: 0,
                          },
                        }}
                      >
                        {job?.job_type}
                      </Button>
                      <Button
                        sx={{
                          height: "auto",
                          minWidth: "60px",
                          minHeight: "45px",
                          borderRadius: 0,
                          padding: 0,
                          // fontSize: { xs: "8px", lg: "10px", xl: "12px" },
                          fontSize: theme.typography.fontSize.xs,
                          fontWeight: theme.typography.Bold,
                          background:
                            job?.job_draft === true
                              ? theme?.palette?.nandos?.main
                              : theme?.palette?.greenColor?.main,
                          color: "white",
                          cursor: "default",
                          ":hover": {
                            background:
                              job?.job_draft === true
                                ? theme?.palette?.nandos?.main
                                : theme?.palette?.greenColor?.main,
                          },
                        }}
                      >
                        {job?.job_draft === true ? "draft" : "posted"}
                      </Button>
                      <Button
                        variant="contained"
                        color="announceButton"
                        disabled={true}
                        sx={{
                          height: "45px",
                          // minWidth: { xs: "40px", lg: "50px", xl: "60px" },
                          minWidth: "60px",
                          maxHeight: { xs: "60px" },
                          maxWidth: { xs: "60px" },
                          borderRadius: 0,
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          "&.Mui-disabled": {
                            backgroundColor: theme.palette.grayButton600.main, // Custom color for disabled state
                            color: "#fff", // Custom text color for disabled state
                          },
                          ":hover": { boxShadow: 0 },
                        }}
                        onClick={() => setJobPromote(true)}
                      >
                        <Box
                          component="img"
                          sx={{
                            height: 30,
                            width: 30,
                            maxHeight: { xs: 30 },
                            maxWidth: { xs: 30 },
                          }}
                          alt="job_volume"
                          src={job_volume}
                        />
                      </Button>
                      <Button
                        color="playButton"
                        variant="contained"
                        sx={{
                          // position: "absolute",
                          // right: 0,
                          // top: "72px",
                          zIndex: 10,
                          height: "auto",
                          minWidth: "60px",
                          minHeight: "45px",
                          borderRadius: 0,
                          // borderBottomLeftRadius: "10px",
                          background:
                            job?.job_video === null &&
                            theme.palette.grayButton600.main,
                          padding: 0,
                          ":hover": {
                            boxShadow: "none",
                          },
                        }}
                        // disabled={!job?.job_video}
                        onClick={() =>
                          hasJobVideo
                            ? setOpenRecordDialog(true)
                            : handleVideoButton(!job?.job_video)
                        }
                      >
                        <Box
                          component={"img"}
                          src={
                            // job?.job_video !== null
                            //   ? playButtonIconWhite
                            //   : playButtonIcon
                            playButtonIconWhite
                          }
                          sx={{
                            height: "16px",
                            width: "20px",
                          }}
                        />
                      </Button>
                    </Box>
                    {!trackButton && !buttonMenu && (
                      <Typography
                        sx={{
                          fontWeight: theme.typography.Regular,
                          fontSize: theme.typography.fontSize.xs,
                          width: "100%",
                          whiteSpace: "nowrap", // Prevents text from wrapping
                          overflow: "hidden", // Hides any overflowing content
                          textOverflow: "ellipsis",
                          color: theme.palette.deleteVideoButton.main,
                          lineHeight: "16px",
                        }}
                      >
                        posted {convertDatetimeAgo(job?.created_at)} ago
                      </Typography>
                    )}
                  </>
                </Box>
              </Box>

              {trackButton && (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "end",
                    paddingLeft: "19px",
                    paddingTop: "10px",
                    lineHeight: "19px",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: theme.typography.fontSize.sm,
                      fontWeight: theme.typography.Bold,
                    }}
                  >
                    {i18n["empMyCam.whatsJobStatus"]}
                  </Typography>
                </Box>
              )}
              {buttonMenu && (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "end",
                    paddingLeft: "19px",
                    paddingTop: "10px",
                    lineHeight: "19px",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: theme.typography.fontSize.sm,
                      fontWeight: theme.typography.Bold,
                      lineHeight: "19px",
                    }}
                  >
                    {i18n["empMyCam.whoCanAccess"]}
                  </Typography>
                </Box>
              )}
            </Box>
          </Grid>

          {buttonMenu && (
            <ButtonMenu
              jobId={job?.job_id}
              closeFunc={setButtonMenu}
              newCardWidth={newCardWidth}
            />
          )}
          {trackButton && (
            <TrackButton
              newCardWidth={newCardWidth}
              theme={theme}
              closeFunc={setTrackButton}
              jobCreationDate={job?.job_active_date}
              setJobExtend={setJobExtend}
              setGetCoins={setGetCoins}
              setSelectedJob={() => setSelectedJob(job)}
              status={job?.jobStatusName}
            />
          )}
          {!buttonMenu && !trackButton && (
            <>
              <Box
                sx={{
                  width: "100%",
                }}
              >
                <Grid
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    padding: "0 20px 0 15px",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: "8px",
                      minHeight: "67px",
                      maxWidth: "100%",
                    }}
                  >
                    {job?.profile_url !== "No URL" && job?.post_incognito ? (
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "flex-start",
                          gap: "5px",
                        }}
                      >
                        <Box
                          component="img"
                          sx={{
                            height: "60px",
                            width: "60px",
                            maxHeight: { xs: "60px" },
                            maxWidth: { xs: "60px" },
                            borderRadius: "10px",
                          }}
                          alt="job_logo"
                          src={post_incognito}
                        />
                        <Tooltip disableInteractive>
                          <LightTooltip
                            title={i18n["popupBoxText.postIncognito"]}
                            placement="right"
                          >
                            <Typography
                              sx={{
                                mt: "4px",
                                cursor: "pointer",
                                height: "17px",
                                width: "17px",
                                borderRadius: "5px",
                                fontSize: "12px",
                                lineHeight: 0.1,
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                fontWeight: "bold", // Corrected here
                                boxShadow: "0px 2px 4px #00000029",
                                border: 1,
                              }}
                            >
                              i
                            </Typography>
                          </LightTooltip>
                        </Tooltip>
                      </Box>
                    ) : job?.profile_url === "DEFAULT URL" ? (
                      <Link
                        to={`/jobs/company/${job?.company_id}`}
                        target={"_blank"}
                        style={{
                          textDecoration: "none",
                          color: theme.palette.black,
                        }}
                      >
                        <Box
                          component="img"
                          sx={{
                            height: "60px",
                            width: "60px",
                            maxHeight: { xs: "60px" },
                            maxWidth: { xs: "60px" },
                            borderRadius: "10px",
                          }}
                          alt="job_logo"
                          src={profileURL}
                        />
                      </Link>
                    ) : (
                      job?.profile_url !== "No URL" && (
                        <Link
                          to={`/jobs/company/${job?.company_id}`}
                          target={"_blank"}
                          style={{
                            textDecoration: "none",
                            color: theme.palette.black,
                          }}
                        >
                          <Box
                            component="img"
                            sx={{
                              height: "60px",
                              width: "60px",
                              maxHeight: { xs: "60px" },
                              maxWidth: { xs: "60px" },
                              borderRadius: "10px",
                            }}
                            alt="job_logo"
                            src={job?.profile_url}
                          />
                        </Link>
                      )
                    )}

                    <Tooltip
                      disableInteractive
                      arrow
                      title={job?.title}
                      placement="top"
                    >
                      <Link
                        to={
                          job?.post_incognito
                            ? `/jobs/${job?.country_name?.replace(
                                /\s|\//g,
                                ""
                              )}/${job?.townName?.replace(
                                /\s|\//g,
                                ""
                              )}/${job?.title?.replace(/\s|\//g, "")}/${
                                job?.job_id
                              }`
                            : `/jobs/${job?.country_name?.replace(
                                /\s|\//g,
                                ""
                              )}/${job?.townName?.replace(
                                /\s|\//g,
                                ""
                              )}/${job?.CompanyName?.replace(
                                /\s|\//g,
                                ""
                              )}/${job?.title?.replace(/\s|\//g, "")}/${
                                job?.job_id
                              }`
                        }
                        target={"_blank"}
                        style={{
                          textDecoration: "none",
                          color: theme.palette.black,
                        }}
                      >
                        <Typography
                          sx={{
                            // minHeight: "60px",
                            wordBreak: "break-all",
                            fontWeight: theme.typography.Bold,
                            fontSize: theme.typography.fontSize.xl,
                            overflow: "hidden",
                            display: "-webkit-box",
                            WebkitBoxOrient: "vertical",
                            WebkitLineClamp: 1,
                            zIndex: -999,
                          }}
                        >
                          {job?.title}
                        </Typography>
                      </Link>
                    </Tooltip>
                    <Box
                      component={"img"}
                      src={job?.external_link ? externalLink : greyExternalLink}
                      sx={{
                        height: "30px",
                        width: "30px",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setOpenExternal(true);
                        setSelectedJob(job);
                      }}
                    />
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "10px",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                        height: "19px",
                      }}
                    >
                      <Box
                        component="img"
                        sx={{
                          height: 16,
                          width: 15,
                          maxHeight: { xs: 16 },
                          maxWidth: { xs: 15 },
                        }}
                        alt="currency"
                        src={BlueCurrency}
                      />
                      <Typography
                        sx={{
                          fontWeight: theme.typography.Bold,
                          fontSize: theme.typography.fontSize.sm,
                          letterSpacing: "0.25px",
                        }}
                      >
                        {job?.salaryMin === 0 ? (
                          <>
                            {job?.currencySymbol}
                            {formatCurrencyWithCommas(job?.salaryMax)}{" "}
                            {job?.type === "freelance"
                              ? "per hour"
                              : "per month"}
                          </>
                        ) : job?.currencySymbol ||
                          job?.salaryMin ||
                          job?.salaryMax ? (
                          <>
                            {job?.currencySymbol}
                            {formatCurrencyWithCommas(job?.salaryMin)} to{" "}
                            {job?.currencySymbol}
                            {formatCurrencyWithCommas(job?.salaryMax)}{" "}
                            {job?.type === "freelance"
                              ? "per hour"
                              : "per month"}
                          </>
                        ) : (
                          "-"
                        )}
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                        height: "19px",
                      }}
                    >
                      <Box
                        component="img"
                        sx={{
                          height: 16,
                          width: 15,
                          maxHeight: { xs: 16 },
                          maxWidth: { xs: 15 },
                        }}
                        alt="location"
                        src={redLocation}
                      />
                      <Typography
                        sx={{
                          fontWeight: theme.typography.Bold,
                          fontSize: theme.typography.fontSize.sm,
                          letterSpacing: "0.25px",
                        }}
                      >
                        {job?.townName}, {job?.country_name}
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                        height: "19px",
                      }}
                    >
                      <Box
                        component="img"
                        sx={{
                          height: 16,
                          width: 15,
                          maxHeight: { xs: 16 },
                          maxWidth: { xs: 15 },
                        }}
                        alt="job_exp"
                        src={job_exp}
                      />
                      <Typography
                        sx={{
                          fontWeight: theme.typography.Bold,
                          fontSize: theme.typography.fontSize.sm,
                          letterSpacing: "0.25px",
                        }}
                      >
                        {job?.experienceYearStart} to {job?.experienceYearEnd}{" "}
                        years
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                        height: "19px",
                      }}
                    >
                      <Box
                        component="img"
                        sx={{
                          height: 16,
                          width: 14,
                          maxHeight: { xs: 16 },
                          maxWidth: { xs: 14 },
                        }}
                        alt="calendar"
                        src={calendar}
                      />
                      <Typography
                        sx={{
                          fontWeight: theme.typography.Bold,
                          fontSize: theme.typography.fontSize.sm,
                          letterSpacing: "0.25px",
                        }}
                      >
                        {job?.job_start_date
                          ? dateConverterMonth(job?.job_start_date)
                          : "-"}
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "10px",
                      height: "130px",
                      padding: "15px 0 0px 0px",
                    }}
                  >
                    {isHorizontalFlipped && (
                      <>
                        <JobsSlider
                          items={
                            Skills?.sort((a, b) => a.length - b.length) || []
                          }
                          color={"traitsButton"}
                          hideTagsAfter={2}
                          height="25px"
                          job={job}
                          index={indexNumber}
                          jobslength={jobslength}
                          isBackSide
                          id={job?.job_id}
                          currentPageJobsCount={currentPageJobsCount}
                        />

                        <JobsSlider
                          items={
                            Tools?.sort((a, b) => a.length - b.length) || []
                          }
                          color={"toolsButton"}
                          hideTagsAfter={2}
                          height="25px"
                          job={job}
                          index={indexNumber}
                          jobslength={jobslength}
                          isBackSide
                          id={job?.job_id}
                          currentPageJobsCount={currentPageJobsCount}
                        />
                      </>
                    )}
                    <Slider
                      items={Traits || []}
                      color={"skillsButton"}
                      minHeight="60px"
                      // marginTop="5px"
                    />
                  </Box>
                </Grid>
              </Box>

              <Grid
                container
                width={"100%"}
                justifyContent={"space-between"}
                alignItems={"center"}
                // marginBottom={"16px"}
                flexWrap={"nowrap"}
                padding={"2px 12px 15px 14px"}
              >
                <Box
                  component="img"
                  height={{ xs: "100px", lg: "100px", xl: "100px" }}
                  // sx={{ margin: "0 -22px 0 -22px" }}
                  alt="primaryName"
                  src={
                    (job?.primaryName === "collaborator" &&
                      profile_collaborator) ||
                    (job?.primaryName === "challenger" && profile_challenger) ||
                    (job?.primaryName === "character" && profile_character) ||
                    (job?.primaryName === "contemplator" &&
                      profile_contemplator) ||
                    profile_grey_challenger
                  }
                />
                <Box
                  component="img"
                  height={{ xs: "100px", lg: "100px", xl: "100px" }}
                  // sx={{ margin: "0 -22px 0 -22px" }}
                  alt="shadowName"
                  src={
                    (job?.shadowName === "collaborator" &&
                      profile_collaborator) ||
                    (job?.shadowName === "challenger" && profile_challenger) ||
                    (job?.shadowName === "character" && profile_character) ||
                    (job?.shadowName === "contemplator" &&
                      profile_contemplator) ||
                    profile_grey_character
                  }
                />
                <MUIRadialChart
                  chartName={"grit score"}
                  value={job?.grit_score}
                  size={calculateHeight()}
                  color={"grit_score"}
                  max={100}
                  countFontSize={16}
                  labelFontSize={12}
                  labelFontWeight={theme.typography.Regular}
                />
              </Grid>
              <Grid
                container
                // padding="0 8px 8px 8px"
                alignItems="center"
                overflow={"hidden"}
                sx={{
                  width: "101%",
                  borderRadius: "0 0 25px 25px",
                  height: "60px",
                }}
              >
                {job?.job_draft === true ? (
                  <>
                    <Button
                      sx={{
                        boxShadow: 0,
                        fontSize: "14px",
                        width: "33.33%",
                        height: "60px",
                        borderRadius: "0 0 0 0",
                        color: "#fff",
                      }}
                      variant="contained"
                      color="yoco"
                      onClick={() => handleSubmitCandidate()}
                      // startIcon={
                      //   <Box
                      //     component={"img"}
                      //     src={submitCandidate}
                      //     sx={{
                      //       height: "25px",
                      //       width: "25px",
                      //     }}
                      //   />
                      // }
                    >
                      delete
                    </Button>
                    <Button
                      sx={{
                        boxShadow: 0,
                        fontSize: "14px",
                        width: "33.33%",
                        height: "60px",
                        borderRadius: "0 0 0 0",
                        color: "#fff",
                      }}
                      variant="contained"
                      color="tymeColor"
                      onClick={() => handleClickViewJob()}
                      // startIcon={
                      //   <Box
                      //     component={"img"}
                      //     src={submitCandidate}
                      //     sx={{
                      //       height: "25px",
                      //       width: "25px",
                      //     }}
                      //   />
                      // }
                    >
                      preview
                    </Button>
                    <Button
                      sx={{
                        boxShadow: 0,
                        fontSize: "14px",
                        width: "33.33%",
                        height: "60px",
                        borderRadius: "0 0 0 0",
                        color: "#fff",
                      }}
                      variant="contained"
                      color="nandos"
                      onClick={() => handleEditJob()}
                      // startIcon={
                      //   <Box
                      //     component={"img"}
                      //     src={submitCandidate}
                      //     sx={{
                      //       height: "25px",
                      //       width: "25px",
                      //     }}
                      //   />
                      // }
                    >
                      edit
                    </Button>
                  </>
                ) : (
                  <>
                    <Button
                      sx={{
                        boxShadow: 0,
                        fontSize: "14px",
                        width: "50%",
                        height: "60px",
                        borderRadius: "0 0 0 0",
                        color: "#fff",
                      }}
                      variant="contained"
                      color="tymeColor"
                      onClick={() => handleClickViewJob()}
                      // startIcon={
                      //   <Box
                      //     component={"img"}
                      //     src={submitCandidate}
                      //     sx={{
                      //       height: "25px",
                      //       width: "25px",
                      //     }}
                      //   />
                      // }
                    >
                      view job
                    </Button>
                    <Button
                      sx={{
                        boxShadow: 0,
                        fontSize: "14px",
                        width: "50%",
                        height: "60px",
                        borderRadius: "0 0 0 0",
                        color: "#fff",
                      }}
                      variant="contained"
                      color="nandos"
                      onClick={() => {}}
                      // startIcon={
                      //   <Box
                      //     component={"img"}
                      //     src={submitCandidate}
                      //     sx={{
                      //       height: "25px",
                      //       width: "25px",
                      //     }}
                      //   />
                      // }
                    >
                      repost
                    </Button>
                  </>
                )}
              </Grid>
              <Button
                variant="contained"
                color="flipButton"
                sx={{
                  width: "20px",
                  height: "100px",
                  padding: 0,
                  position: "absolute",
                  right: 0,
                  top: `calc(50% - 50px)`,
                  minWidth: "20px",
                  fontSize: "20px",
                  borderRadius: "10px 0 0 10px",
                }}
                onClick={() => setisFlipped(false)}
              >
                <Box
                  component={"img"}
                  src={flip}
                  sx={{
                    height: "20px",
                    width: "15px",
                  }}
                />
              </Button>
            </>
          )}
        </>
      )}

      {isSubmitCandidate && (
        <SubmitCandidate
          setIsSubmitCandidate={setIsSubmitCandidate}
          job={job}
          allJobs={allJobs}
          setAllJobs={setAllJobs}
        />
      )}
      {jobPromote && (
        <JobPromoteCard
          setJobPromote={setJobPromote}
          jobID={job?.job_id}
          job={job}
          setShowLogin={setShowLogin}
          height="100%"
        />
      )}
    </Card>
  );
}
