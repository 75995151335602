import {
  Box,
  Button,
  InputBase,
  Modal,
  Paper,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useState } from "react";
import CustomDialog from "../common/CustomDialog";
import crayBot from "../../assets/CrayBotIcons/Send_To_Mate.svg";
import CloseIcon from "../common/CloseIcon";

export default function EmailInputBox({
  openEdit,
  handleClose,
  handleRefer,
  setEmail,
  errors,
  setErrors,
}) {
  const theme = useTheme();
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    height: "auto",
    width: { xs: "360px", tablet: "450px" },
    bgcolor: "background.paper",
    borderRadius: "25px",
    // pyb: 4,
    boxShadow: "none !important",
    // border: "1px solid black !important",
    ".&focus-visible": {
      outlineColor: "none",
    },
  };
  return (
    <Modal
      open={openEdit}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Box onClick={() => handleClose()}>
          <CloseIcon top="15px" right="15px" />
        </Box>
        <Box
          sx={{
            padding: "25px 8px 0px 8px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Box
            component={"img"}
            src={crayBot}
            sx={{ width: "127px", height: "125px" }}
          />

          <Typography
            sx={{
              fontSize: theme.typography.fontSize.xl,
              fontWeight: theme.typography.Bold,
              textAlign: "center",
              marginBottom: "15px",
            }}
          >
            Send it to a mate
          </Typography>
          <Typography
            sx={{
              fontSize: theme.typography.fontSize.sm,
              fontWeight: theme.typography.Bold,
              textAlign: "center",
              marginBottom: "15px",
            }}
          >
            We’ll send the job link to your friend with your tracking code
            attached.
          </Typography>
          <Typography
            sx={{
              fontSize: theme.typography.fontSize.sm,
              fontWeight: theme.typography.Regular,
              textAlign: "center",
              marginBottom: "15px",
            }}
          >
            If they get hired, you’ll earn any associated Bounty!
          </Typography>
          <Paper
            sx={{
              display: "flex",
              height: "40px",
              borderRadius: "10px !important",
              boxShadow: "none",
              width: "100%",
              marginBottom: "20px",
              border: errors?.find((error) => error?.key === "email")
                ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
                : "1px solid #E0E0E0",
            }}
          >
            <InputBase
              name="grit_score"
              sx={{ ml: 2, mr: 2, width: "90%" }}
              placeholder="Enter your mate's email address"
              type="text"
              onChange={(event) => {
                setEmail(event?.target?.value);
                setErrors(errors?.filter((item) => item?.key !== "email"));
              }}
              onKeyDown={(event) => {
                if (event.key === "Enter") {
                  event.preventDefault();
                  handleRefer();
                }
              }}
            />
          </Paper>
        </Box>
        <Button
          variant="contained"
          color="nandos"
          sx={{
            width: "100%",
            minHeight: "60px",
            maxHeight:"60px",
            borderRadius: "0 0 25px 25px",
            boxShadow: "none",
          }}
          onClick={() => handleRefer()}
        >
          send it off
        </Button>
      </Box>
    </Modal>
  );
}
