import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  Divider,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import companyLogo from "../../../../assets/Crayon_Favicon.svg";
import InfiniteScroll from "react-infinite-scroll-component";
import {
  ALERT_TYPE,
  COINS_STATUS,
  COIN_HISTORY,
} from "../../../../utils/Constants";
import {
  TableCells,
  ToggleCheckedSwitch,
} from "../../../../utils/CommonComponent";
import { getPaymentHistory } from "../../../../redux/admin/maintenance";
import { setAlert } from "../../../../redux/configSlice";
import { useDispatch } from "react-redux";
import SelectMenu from "../../../common/SelectMenu";
import { dateConverterMonth } from "../../../../utils/DateTime";

export default function CompanyPaymentInfo({
  companyInfo,
  handleClose,
  companyPaymentInfo,
}) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [companyPaymentDetail, setCompanyPaymentDetail] = useState([]);
  const [lastKey, setLastKey] = useState(0);
  const [confirm, setConfirm] = useState(false);
  const [info, setInfo] = useState({});

  const getCompanyPaymentInfo = async (lastKeyy) => {
    try {
      const { payload } = await dispatch(
        getPaymentHistory({
          lastKey: lastKeyy,
          company_id: companyPaymentInfo?.company_id,
        })
      );
      if (payload?.status === "success") {
        if (payload?.pageNumber === 0) {
          setCompanyPaymentDetail(payload?.data);
          setLastKey(payload?.pageNumber + 1);
        } else if (payload?.data?.length > 0) {
          setCompanyPaymentDetail((prev) => [...prev, ...payload?.data]);
          setLastKey(payload?.pageNumber + 1);
        }
      } else {
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE?.ERROR,
            msg: payload?.message,
          })
        );
      }
    } catch (error) {
      dispatch(
        setAlert({
          show: true,
          type: ALERT_TYPE?.ERROR,
          msg: error,
        })
      );
    }
  };

  const handleSelectChangePaid = (event, row, id, name) => {
    setCompanyPaymentDetail(
      companyPaymentDetail?.map((item) => {
        return item?.payment_transaction_id === row?.payment_transaction_id
          ? {
              ...item,
              paid: info?.id,
            }
          : item;
      })
    );
    setConfirm(false);
  };

  const handleSelectChange = (event, row, id, name) => {
    setCompanyPaymentDetail(
      companyPaymentDetail?.map((item) => {
        return item?.payment_transaction_id === row?.payment_transaction_id
          ? { ...item, invoice: id }
          : item;
      })
    );
  };

  const handleChangeStatus = (event, row) => {
    const {
      target: { value },
      target: { name },
      target: { id },
    } = event;
    const statusName = COINS_STATUS?.find((item) => item?.id === value)?.id;
    setCompanyPaymentDetail(
      companyPaymentDetail?.map((item) => {
        return item?.payment_transaction_id === row?.payment_transaction_id
          ? { ...item, transaction_status_id: statusName }
          : item;
      })
    );
  };

  useEffect(() => {
    getCompanyPaymentInfo(0);
  }, []);

  return (
    <Dialog
      open={companyInfo}
      fullWidth={true}
      //   maxWidth={"xs"}
      scroll="body"
      padding={0}
      sx={{
        // zIndex: 100000,
        "& .MuiPaper-root": {
          borderRadius: "25px",
          maxWidth: "1387px !important",
        },
      }}
    >
      <DialogTitle sx={{ padding: "24px 30px" }}>
        <Box
          sx={{
            display: "flex",
            width: "340px",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box sx={{ display: "flex", gap: "13px", alignItems: "center" }}>
            <Box
              component={"img"}
              src={companyLogo}
              sx={{ width: 40, height: 40, borderRadius: "10px" }}
            />
            <Typography
              sx={{ fontSize: "14px", fontWeight: theme.typography.Bold }}
            >
              {companyPaymentInfo?.company?.name}
            </Typography>
          </Box>
          <Box
            sx={{
              width: "135px",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography
              sx={{ fontSize: "14px", fontWeight: theme.typography.Bold }}
            >
              Coin Balance
            </Typography>
            <Button
              variant="contained"
              color="submitButton"
              sx={{
                minWidth: "fit-content",
                height: "26px",
                borderRadius: "7px",
                padding: "7px",
                color: "black",
              }}
            >
              97
            </Button>
          </Box>
        </Box>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Box
            sx={{
              width: "20px",
              height: "20px",
              color: "#000000",
              border: 2,
              fontSize: "18px",
              borderRadius: "5px",
            }}
          >
            X
          </Box>
        </IconButton>
      </DialogTitle>
      <Divider />

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "15px",
          width: "98%",
          mt: "8px",
          overflowY: "auto",
          height: "400px",
          "::-webkit-scrollbar": {
            height: "5px",
            background: "#DCDEEB",
            display: "unset !important",
          },
        }}
        id="matchTaleneList"
      >
        <InfiniteScroll
          style={{
            overflowX: "hidden",
            scrollbarWidth: "thin",
            display: "flex",
            flexDirection: "column",
            gap: "16px",
            width: "100%",
          }}
          dataLength={companyPaymentDetail?.length}
          next={() => getCompanyPaymentInfo(lastKey)}
          scrollThreshold={"100px"}
          scrollableTarget={"matchTaleneList"}
          hasMore={true}
          endMessage={
            <p style={{ textAlign: "center" }}>
              <b>Yay! You have seen it all</b>
            </p>
          }
        >
          <TableContainer component={Paper} sx={{ height: "417px" }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCells sx={{ padding: "22px 16px 10px 30px" }}>
                    <Typography variant="subtitle1" fontWeight="bold">
                      Payment ID
                    </Typography>
                  </TableCells>
                  <TableCells sx={{}}>
                    <Typography
                      variant="subtitle1"
                      fontWeight="bold"
                      textAlign={"center"}
                    >
                      User
                    </Typography>
                  </TableCells>
                  <TableCells sx={{}}>
                    <Typography
                      variant="subtitle1"
                      fontWeight="bold"
                      textAlign={"center"}
                    >
                      Item
                    </Typography>
                  </TableCells>
                  <TableCells>
                    <Typography
                      variant="subtitle1"
                      fontWeight="bold"
                      textAlign={"center"}
                    >
                      ID
                    </Typography>
                  </TableCells>
                  <TableCells sx={{}}>
                    <Typography
                      variant="subtitle1"
                      fontWeight="bold"
                      textAlign={"center"}
                    >
                      Coins
                    </Typography>
                  </TableCells>
                  <TableCells sx={{}}>
                    <Typography
                      variant="subtitle1"
                      fontWeight="bold"
                      textAlign={"center"}
                    >
                      Date
                    </Typography>
                  </TableCells>
                  <TableCells sx={{}}>
                    <Typography
                      variant="subtitle1"
                      fontWeight="bold"
                      textAlign={"center"}
                    >
                      Cost
                    </Typography>
                  </TableCells>
                  <TableCells sx={{}}>
                    <Typography
                      variant="subtitle1"
                      fontWeight="bold"
                      textAlign={"center"}
                    >
                      Invoiced
                    </Typography>
                  </TableCells>
                  <TableCells sx={{}}>
                    <Typography
                      variant="subtitle1"
                      fontWeight="bold"
                      textAlign={"center"}
                    >
                      Paid
                    </Typography>
                  </TableCells>
                  <TableCells sx={{}}>
                    <Typography
                      variant="subtitle1"
                      fontWeight="bold"
                      textAlign={"center"}
                    >
                      Status
                    </Typography>
                  </TableCells>
                </TableRow>
              </TableHead>
              <TableBody>
                {companyPaymentDetail?.map((row) => (
                  <TableRow
                    key={row?.payment_transaction_id}
                    style={{
                      "& .css-12zgwp-MuiTableCell-root": {
                        padding: "0 16px !important",
                      },
                    }}
                  >
                    <TableCells
                      sx={{
                        fontWeight: theme.typography.Bold,
                        padding: "22px 16px 10px 30px",
                      }}
                    >
                      {row?.payment_transaction_id}
                    </TableCells>
                    <TableCells sx={{ textAlign: "center" }}>
                      {row?.user?.first_name} {row?.user?.last_name}
                    </TableCells>
                    <TableCells sx={{ textAlign: "center" }}>
                      {row?.transaction_name?.name}
                    </TableCells>
                    <TableCells sx={{ textAlign: "center" }}>
                      {row?.transaction_name_id}
                    </TableCells>
                    <TableCells sx={{ textAlign: "center" }}>
                      {row?.coins}
                    </TableCells>
                    <TableCells sx={{ textAlign: "center" }}>
                      {dateConverterMonth(row?.created_at)}
                    </TableCells>
                    <TableCells sx={{ textAlign: "center" }}>
                      {row?.currency?.symbol}
                      {row?.cost}
                    </TableCells>

                    <TableCells sx={{ textAlign: "center" }}>
                      {
                        <ToggleCheckedSwitch
                          checkedColor={"recentButton"}
                          notCheckedColor={"employerButton"}
                          checked={row?.invoice}
                          onChange={(event, id, name) =>
                            handleSelectChange(event, row, id, name)
                          }
                        />
                      }
                    </TableCells>
                    <TableCells sx={{ textAlign: "center" }}>
                      {
                        <ToggleCheckedSwitch
                          checkedColor={
                            row?.transaction_status?.name === "Paid"
                              ? "recentButton"
                              : "talentButton"
                          }
                          notCheckedColor={"employerButton"}
                          checked={row?.paid}
                          onChange={(event, id, name) =>
                            // handleSelectChangePaid(event, row, id, name)
                            {
                              if (!row?.paid) {
                                setConfirm(true);
                                setInfo({ row, id, switch: "paid" });
                              }
                            }
                          }
                        />
                      }
                    </TableCells>
                    <TableCells
                      sx={{ display: "flex", justifyContent: "center" }}
                    >
                      {
                        <SelectMenu
                          readOnly={row?.transaction_status_id === 4}
                          clear={true}
                          name="transaction_status_id"
                          value={row?.transaction_status_id}
                          onHandleChange={(e) => handleChangeStatus(e, row)}
                          options={COINS_STATUS}
                          placeholder={"Job stage"}
                          borderRadius="10px !important"
                          sx={{
                            width: "120px !important",
                            textAlign: "center",
                          }}
                        />
                      }
                    </TableCells>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </InfiniteScroll>
      </Box>
    </Dialog>
  );
}
