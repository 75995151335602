import React, { useEffect, useState } from "react";
import {
  getEditQuestionAndAnswer,
  postUpdatedAnswers,
} from "../../../redux/guest/getQuestions";
import { useDispatch } from "react-redux";
import { setAlert } from "../../../redux/configSlice";
import { ALERT_TYPE } from "../../../utils/Constants";
import CustomDialog from "../../common/CustomDialog";
import { Box, Button, TextField, Typography } from "@mui/material";
import { ThemeProvider, useTheme } from "@emotion/react";

import { ApplyJobsPopupTheme } from "../../../utils/Theme";
import oneImage from "../../../assets/CrayBotIcons/Crayon Craybot QA Character_1.svg";
import twoImage from "../../../assets/CrayBotIcons/Crayon Craybot QA Character_2.svg";
import threeImage from "../../../assets/CrayBotIcons/Crayon Craybot QA Character_3.svg";
import fourImage from "../../../assets/CrayBotIcons/Crayon Craybot QA Character_4.svg";
import fiveImage from "../../../assets/CrayBotIcons/Crayon Craybot QA Character_5.svg";
import CloseIcon from "../../common/CloseIcon";

const questionImages = [oneImage, twoImage, threeImage, fourImage, fiveImage];

export default function EditQandA({
  jobId,
  openQandADialog,
  setopenQandADialog,
}) {
  const dispatch = useDispatch();
  const theme = useTheme();

  const [questions, setQuestions] = useState([]);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [answers, setAnswers] = useState();

  const getquestionsandanswer = async () => {
    const { payload } = await dispatch(getEditQuestionAndAnswer(jobId));
    if (payload?.status === "success") {
      if (payload?.data?.length === 0) {
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE.ERROR,
            msg: "NO Questions avaliable",
          })
        );
      } else {
        setQuestions(payload?.data);
      }
    } else {
      dispatch(
        setAlert({
          show: true,
          type: ALERT_TYPE.ERROR,
          msg: payload?.message,
        })
      );
    }
  };

  const nextQuestion = (question) => {
    if (currentQuestionIndex < questions?.length - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
      updateAnswer(question?.question_id, question?.job_id, "next");
    } else {
      updateAnswer(question?.question_id, question?.job_id, "prev");
      handleCloseQuestions();
    }
  };
  const handleNext = () => {
    if (currentQuestionIndex < questions?.length - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
    } else {
      handleCloseQuestions();
    }
  };
  const handlePrev = (question) => {
    if (
      currentQuestionIndex <= questions?.length - 1 &&
      currentQuestionIndex !== 0
    ) {
      setCurrentQuestionIndex(currentQuestionIndex - 1);
    } else {
      handleCloseQuestions();
    }
  };
  const previousQuestion = (question) => {
    if (
      currentQuestionIndex <= questions?.length - 1 &&
      currentQuestionIndex !== 0
    ) {
      setCurrentQuestionIndex(currentQuestionIndex - 1);
      updateAnswer(question?.question_id, question?.job_id, "prev");
    } else {
      handleCloseQuestions();
      updateAnswer(question?.question_id, question?.job_id, "prev");
    }
  };

  const handleCloseQuestions = () => {
    setAnswers("");
    setopenQandADialog(false);
    setCurrentQuestionIndex(0);
  };

  const handleChange = (event, id) => {
    setAnswers(event?.target?.value);
  };

  const updateAnswer = async (qid, jobId, move) => {
    try {
      const data = {
        question_id: qid,
        answer: answers,
        job_id: jobId,
      };
      const { payload } = await dispatch(postUpdatedAnswers(data));
      if (payload?.status === "success") {
        move === "next" ? handleNext() : handlePrev();
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE.SUCCESS,
            msg: "Answer updated succesfully!!",
          })
        );
      }
    } catch (error) {
      dispatch(
        setAlert({
          show: true,
          type: ALERT_TYPE.SUCCESS,
          msg: "",
        })
      );
    }
  };

  useEffect(() => {
    getquestionsandanswer();
  }, []);

  return (
    <ThemeProvider theme={ApplyJobsPopupTheme}>
      {questions?.map((question, index) => {
        const isCurrentQuestion = index === currentQuestionIndex;
        return (
          <CustomDialog
            key={question?.questionnaire_answer?.question_id}
            show={openQandADialog && isCurrentQuestion}
            hideButton={false}
            onDialogClose={handleCloseQuestions}
            dialogWidth="xs"
            showFooter={false}
            // title={isLoggedIn ? i18n["login.login"] : i18n["login.signUp"]}
            isApplyJob
            width={"450px"}
            padding={0}
            backgroundColor={theme.palette.applyBoxBackground}
          >
            <CloseIcon onClick={handleCloseQuestions} />
            <Box
              sx={{
                textAlign: "center",
                display: "flex",
                flexDirection: "column",
                padding: { xs: "0 10px", tablet: "0 30px" },
                // gap: 3,
              }}
            >
              <Box
                component={"img"}
                src={questionImages[index]}
                sx={{ height: 125 }}
                marginTop={"20px"}
              />
              <Typography
                sx={{
                  fontSize: theme.typography.fontSize.xl,
                  fontWeight: theme.typography.Bold,
                  marginTop: "20px",
                  marginBottom: "20px",
                }}
              >
                A quick Q&A session
              </Typography>
              <Typography
                sx={{
                  fontSize: theme.typography.fontSize.base,
                  fontWeight: theme.typography.Bold,
                  marginBottom: "3px",
                }}
              >
                Question {currentQuestionIndex + 1}
              </Typography>
              <Box>
                <Typography
                  sx={{
                    fontSize: theme.typography.fontSize.sm,
                    fontWeight: theme.typography.Regular,
                    marginBottom: "20px",
                  }}
                >
                  {question?.question}
                </Typography>
                <TextField
                  defaultValue={question?.questionnaire_answer?.answer}
                  id="answer"
                  type="text"
                  rows={3}
                  multiline
                  onChange={handleChange}
                  placeholder={"Enter your answer here..."}
                  sx={{
                    mb: 2,
                    width: "100%",
                    margin: "auto",
                    "& .MuiOutlinedInput-root": {
                      background: "#fff !important",
                      borderRadius: "10px",
                    },
                  }}
                />
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                mt: "20px",
                width: "100%",
              }}
            >
              <Button
                disabled={currentQuestionIndex === 0}
                sx={{
                  boxShadow: 0,
                  fontSize: "12px",
                  width: "50%",
                  height: "60px",
                  borderRadius: 0,
                }}
                variant="contained"
                color="grayButton600"
                onClick={() => previousQuestion(question)}
              >
                Previous
              </Button>
              {/* <Button
                sx={{
                  boxShadow: 0,
                  fontSize: "12px",
                  width: "50%",
                  height: "60px",
                  borderRadius: 0,
                }}
                variant="contained"
                color="updateCVButton"
                onClick={() =>
                  updateAnswer(question?.question_id, question?.job_id)
                }
              >
                Update answer
              </Button> */}
              <Button
                sx={{
                  boxShadow: 0,
                  fontSize: "12px",
                  width: "50%",
                  height: "60px",
                  borderRadius: 0,
                }}
                variant="contained"
                color="nextQuestionButton"
                onClick={() => nextQuestion(question)}
              >
                Thank you, next
              </Button>
            </Box>
          </CustomDialog>
        );
      })}
    </ThemeProvider>
  );
}
