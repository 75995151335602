import { Box, Button, InputBase, Typography, useTheme } from "@mui/material";
import React from "react";
import questionIcon from "../../../../assets/Padding Excluded/Crayon Icon_Experience_White.svg";
import editIcon from "../../../../assets/Padding Included/Yellow_Edit.svg";

export default function QuestionsBox({ jobContent }) {
  const theme = useTheme();
  return (
    <>
      <Box sx={{ display: "flex" }}>
        <Button
          variant="contained"
          color="recruiterButton"
          sx={{
            width: "150px",
            height: "45px",
            borderRadius: "25px 0 25px 0",
            padding: "6px !important",
            boxShadow: "none",
          }}
          startIcon={
            <Box
              component={"img"}
              src={questionIcon}
              sx={{ width: 20, height: 20 }}
            />
          }
        >
          questions ({jobContent?.jobQuestions?.length})
        </Button>

        <Box
          component={"img"}
          src={editIcon}
          sx={{ height: 35, width: 35, cursor: "pointer" }}
        />
      </Box>

      <Box
        sx={{
          width: "90%",
          padding: "10px 20px 15px 20px",
          display: "flex",
          flexDirection: "column",
          gap: "16px",
        }}
      >
        {jobContent?.jobQuestions?.map((questions, index) => {
          return (
            <Box>
              <Typography
                sx={{ fontSize: "12px", fontWeight: theme.typography.Bold }}
              >
                Question {index + 1}:
              </Typography>
              <Typography
                sx={{
                  fontSize: "12px",
                  fontWeight: theme.typography.Regular,
                  wordBreak: "break-all",
                }}
              >
                {questions?.question}
              </Typography>
            </Box>
          );
        })}
      </Box>
    </>
  );
}
