import { Box, Button, InputLabel, Slider } from "@mui/material";
import React, { useCallback, useEffect, useRef, useState } from "react";
import theme from "../../utils/Theme";
import GreenEducation from "../../assets/Padding Included/GreenEducation.svg";
import BlueFlag from "../../assets/Padding Included/SearchFlagIcon.svg";
import BlueSkills from "../../assets/Padding Included/topBarSkillIcon.svg";
import RedLocation from "../../assets/Padding Included/Red_Location.svg";
import YellowTool from "../../assets/Padding Excluded/AdvanceToolIcon.svg";
import SalaryIcon from "../../assets/Padding Included/Red_Salary.svg";
import ExperienceIcon from "../../assets/Padding Included/Yellow_Pending.svg";
import GreenCountry from "../../assets/Padding Included/Green_Country.svg";
import GreenTitle from "../../assets/Padding Excluded/Black_Experience_Title_Job.svg";
import BlueCompany from "../../assets/Padding Excluded/Yellow_Following_Company.svg";
import { useSelector } from "react-redux";
import closeIcon from "../../assets/Black_Up_Close - Copy.svg";
import resetIcon from "../../assets/Reset.svg";
import {
  getCountry,
  getCurrency,
  getRequiredQualification,
  getSkills,
  getTools,
  getTitles,
} from "../../redux/employer/postJobSlice";
import { setAlert, setLoading } from "../../redux/configSlice";
import {
  ALERT_TYPE,
  BASIC_RANGEMARKS,
  ERROR_MSG,
  EXPERIENCE,
} from "../../utils/Constants";
import { useDispatch } from "react-redux";
import {
  getCompanies,
  getCandidateCompany,
} from "../../redux/employer/empProfileSlice";
import SearchIcon from "../../assets/Padding Excluded/White_Search.svg";
import filterIcon from "../../assets/Padding Included/White_Filter_Stats.svg";
import {
  checkNumberRange,
  debounce,
  formatNumber,
  genarateLabelValue,
  genarateLabelValueForFreelance,
  useQuery,
} from "../../utils/Common";
import { getTowns } from "../../redux/candidate/myProfileSlice";
import CustomSelect from "../candidate/myCV/CustomSelect";
import { getSearchedResult } from "../../redux/guest/jobsSlice";
import useViewport from "./ViewPort";
import SearchBarDropDown from "./SearchBarDropDown";

const AdvanceSection = ({
  setBasicData,
  basicData,
  setOpenAdvanceSearch,
  openAdvanceSearch,
  handleJobSearch,
  setShowArrow,
  showArrow,
  setSearchBox,
  color,
  showSearchBar,
  setShowSearchBar,
  handleShowSearchBtn,
  handleShowSearchBar,
  searchbtnColor = "expiredStatus",
  homePage = false,
  setSearchText,
}) => {
  const dispatch = useDispatch();
  const query = useQuery();
  const boxRef = useRef(null);
  const { width } = useViewport();
  const isTalentPage =
    window?.location?.pathname
      ?.split("/")
      ?.filter((part) => part !== "")?.[0] === "talent";
  const [selectedValue, setSelectedValue] = useState("");
  const [rangeValue, setRangeValue] = useState([0, 10]);
  const [expRange, setExpRange] = useState([0, 2]);
  const [isLoading, setIsLoading] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [salaryRange, setSalaryRange] = useState(BASIC_RANGEMARKS);
  const [interval, setInterval] = useState();

  const { titles, skills, tools, requiredQua, currency, country } = useSelector(
    (state) => state.postJobs
  );
  const { town } = useSelector((state) => state.myProfileSli);

  const { companies, candidateCompany } = useSelector(
    (state) => state.myProfile
  );

  const rangeValueHandler = (value) => {
    // if (rangeValue[0] === 0 && rangeValue[1] === 10) {
    //   return "0";
    // }
    const firstCurrency = basicData?.currency_id?.map((val) => {
      return currency?.find((item) => item?.currency_id === val?.value);
    });

    const currencyWithMaxSalary = firstCurrency?.reduce((max, curr) => {
      return curr?.max_salary > max?.max_salary ? curr : max;
    }, firstCurrency[firstCurrency?.length - 1]);

    const symbol = currency?.find((curr) =>
      currencyWithMaxSalary
        ? curr?.currency_id === currencyWithMaxSalary?.currency_id
        : curr?.currency_id === 1
    )
      ? currency?.find((curr) =>
          currencyWithMaxSalary
            ? curr?.currency_id === currencyWithMaxSalary?.currency_id
            : curr?.currency_id === 1
        )
      : "";
    let valueNumberRange = checkNumberRange(symbol?.max_salary);

    return `${
      symbol?.symbol && basicData?.currency_id?.length === 1
        ? symbol?.symbol
        : ""
    } ${
      valueNumberRange === "thousands"
        ? formatNumber(value * 1000)
        : formatNumber(value * 10000)
    }`;
  };

  const rangeValueExperience = (value) => {
    return value > 1 ? `${value} years` : `${value} year`;
  };

  const getAllData = async (para) => {
    try {
      switch (para) {
        case "country":
          await dispatch(getCountry());
          return;
        case "skills":
          await dispatch(getSkills());
          return;
        case "town":
          await dispatch(getTowns());
          return;
        case "tools":
          await dispatch(getTools());
          return;
        case "companies":
          await dispatch(getCompanies());
          return;
        case "candidateCompany":
          await dispatch(getCandidateCompany());
          return;
        case "currency":
          await dispatch(getCurrency());
          return;
        case "requiredQua":
          await dispatch(getRequiredQualification());
          return;
        default:
          return;
      }
    } catch (error) {
      dispatch(setLoading(false));
      dispatch(
        setAlert({
          show: true,
          type: ALERT_TYPE.ERROR,
          msg: ERROR_MSG,
        })
      );
    }
  };

  const getInitialCurrency = async () => {
    try {
      await dispatch(getCurrency());
    } catch (error) {
      dispatch(
        setAlert({
          show: true,
          type: ALERT_TYPE.ERROR,
          msg: ERROR_MSG,
        })
      );
    }
  };
  const handleRangeSliderChange = (event, newValue) => {
    setRangeValue(newValue);
    let newArr = newValue?.map((val) => val * 1000);
    const includesZAR = basicData?.currency_id?.some(
      (item) => item?.value === 1
    );
    const newBasicData = {
      ...basicData,
      [event?.target?.name]: basicData?.currency_id?.length === 0 || includesZAR
        ? [newArr?.[0] * 10, newArr?.[1] * 10]
        : newArr,
    };
    setBasicData(newBasicData);
  };

  const handleRangeSliderChange2 = (event, newValue) => {
    setRangeValue(newValue);
    let newArr = newValue?.map((val) =>
      basicData?.currency_id === 1 ? val * 100 : val * 10
    );
    const newBasicData = {
      ...basicData,
      [event?.target?.name]: newArr,
    };
    setBasicData(newBasicData);
  };

  const rangeValueHandler2 = (value) => {
    // if (rangeValue[0] === 0 && rangeValue[1] === 10) {
    //   return "0";
    // }
    const symbol = currency?.find(
      (curr) => curr?.currency_id === basicData?.currency_id
    )
      ? currency?.find((curr) => curr?.currency_id === basicData?.currency_id)
      : "$";
    return `${symbol?.symbol ? symbol?.symbol : symbol} ${
      symbol?.currency === "ZAR"
        ? formatNumber(value * 100)
        : formatNumber(value * 10)
    }`;
  };
  const expHandleChange = (event, newValue) => {
    setExpRange(newValue);
    const {
      target: { value },
      target: { name },
    } = event;

    const newBasicData = {
      ...basicData,
      [name]: value,
    };
    setBasicData(newBasicData);
  };

  const getData = () => {
    if (query.get("location")) {
      getAllData("town");
    } else if (query.get("region_id")) {
      getAllData("country");
    } else if (query.get("tag_id")) {
      getAllData("skills");
    } else if (query.get("tool_id")) {
      getAllData("tools");
    } else if (query.get("company_id") && isTalentPage) {
      getAllData("candidateCompany");
    } else if (query.get("company_id")) {
      getAllData("companies");
    } else if (query.get("currency_id")) {
      getAllData("currency");
    } else if (query.get("highest_qualification_id")) {
      getAllData("requiredQua");
    }
  };

  const handleOnSelectChange = (ind, newValue, val, inputId) => {
    console.log("handleOnSelectChange")
    let newBasicData = { ...basicData };
    if (
      ind === "tag_id" ||
      ind === "currency_id" ||
      ind === "highest_qualification_id" ||
      ind === "company_id" ||
      ind === "town_id" ||
      ind === "tool_id" ||
      ind === "job_title" ||
      ind === "region_id"
    ) {
      // if (ind === "industries") {
      //   dispatch(updateToInitialIndustrie());
      // }
      // if (ind === "tools") {
      //   dispatch(updateToInitialTools());
      // }
      // if (ind === "tags") {
      //   dispatch(updateToInitialSkills());
      // }
      if (ind === "currency_id") {
        let range;

        const firstCurrency = newValue?.map((val) => {
          return currency?.find((item) => item?.currency_id === val?.value);
        });

        const currencyWithMaxSalary = firstCurrency?.reduce((max, curr) => {
          return curr?.max_salary > max?.max_salary ? curr : max;
        }, firstCurrency[0]);

        range = genarateLabelValue(
          currencyWithMaxSalary ? currencyWithMaxSalary : { max_salary: 205000 }
        );
        if (currencyWithMaxSalary?.currency === "ZAR") {
          setRangeValue([0, 10]);
          newBasicData = {
            ...basicData,
            salary: [0, 100000],
          };
        } else if (currencyWithMaxSalary === undefined) {
          // newBasicData = {
          //   ...basicData,
          //   salary: [],
          // };
          // setRangeValue([0, 10]);
          setRangeValue([0, 10]);
          newBasicData = {
            ...basicData,
            salary: [0, 100000],
          };
        } else {
          newBasicData = {
            ...basicData,
            salary: [currencyWithMaxSalary?.min_salary, 5200],
          };
          setRangeValue([0, 5.2]);
        }
        setSalaryRange(range);
      }
      if (ind === "job_title") {
        setSearchText("");
      }
      newBasicData[ind] = newValue;
    } else if (
      newValue &&
      newValue.label &&
      !["industries", "tags", "tools"].includes(newValue)
    ) {
      newBasicData[inputId] = newValue;
    } else {
      newBasicData[inputId] = newValue?.id || newValue;
    }
    setBasicData(newBasicData);
  };

  const debouncedHandleOnSelectInputChange = useCallback(
    debounce(
      async (newValue, type) => {
        let params = {
          title: newValue || "",
          limit: 200,
        };
        if (newValue) {
          // getlist(params, type);
          try {
            if (type === "tag_id") {
              dispatch(getSkills(params));
            }
            if (type === "tool_id") {
              dispatch(getTools(params));
            }
            if (type === "job_title") {
              dispatch(getTitles(params));
            }
            if (type === "company_id") {
              window?.location?.pathname
                ?.split("/")
                ?.filter((part) => part !== "")?.[0] === "talent"
                ? dispatch(getCandidateCompany(params))
                : dispatch(getCompanies(params));
            }
            if (type === "town_id") {
              let data = {
                ...params,
                id: "",
              };
              dispatch(getTowns(data));
            }
          } catch (error) {
            console.log(error, "error");
          }
        }
      },
      750,
      setIsLoading
    ),
    []
  );

  const handleOnSelectInputChange = (newValue, inputId) => {
    debouncedHandleOnSelectInputChange(newValue, inputId);
  };

  const getTownValue = () => {
    if (basicData?.town_id?.[0]?.label) {
      return basicData?.town_id;
    }
    if (basicData?.town_id?.length > 0) {
      const towns = town?.find((item) => item?.id == basicData?.town_id);
      let finalTown = {
        value: towns?.town_id,
        label: towns?.name,
      };
      return finalTown;
    }
  };

  const getSearchedData = async () => {
    try {
      let data = {
        job_title: query?.get("job_title")?.split(","),
        town_id: query
          ?.get("location")
          ?.split(",")
          ?.map((str) => parseInt(str)),
        tag_id: query
          ?.get("tag_id")
          ?.split(",")
          ?.map((str) => parseInt(str)),
        tool_id: query
          ?.get("tool_id")
          ?.split(",")
          ?.map((str) => parseInt(str)),
        country_id: query
          ?.get("region_id")
          ?.split(",")
          ?.map((str) => parseInt(str)),
        company_id: query
          ?.get("company_id")
          ?.split(",")
          ?.map((str) => parseInt(str)),
        highest_qualification_id: query
          ?.get("highest_qualification_id")
          ?.split(",")
          ?.map((str) => parseInt(str)),
        currency_id: query
          ?.get("currency_id")
          ?.split(",")
          ?.map((str) => parseInt(str)),
      };
      const { payload } = await dispatch(getSearchedResult(data));
      if (payload?.status === "success") {
        let range;
        let newBasicData;

        const firstCurrency = payload?.data?.currency?.map((val) => {
          return currency?.find(
            (item) => item?.currency_id === val?.currency_id
          );
        });

        const currencyWithMaxSalary = firstCurrency?.reduce((max, curr) => {
          return curr?.max_salary > max?.max_salary ? curr : max;
        }, firstCurrency[0]);

        range = genarateLabelValue(
          currencyWithMaxSalary ? currencyWithMaxSalary : { max_salary: 205000 }
        );
        if (currencyWithMaxSalary?.currency === "ZAR") {
          setRangeValue(
            query
              .get("salary")
              .split(",")
              .map((item) => Number(item) / 10000)
          );
          newBasicData = {
            salary: query
              ?.get("salary")
              ?.split(",")
              ?.map((str) => parseInt(str)),
          };
        } else if (currencyWithMaxSalary === undefined) {
          // newBasicData = {
          //   salary: [],
          // };
          // setRangeValue([0, 10]);
          setRangeValue(
            query
              .get("salary")
              .split(",")
              .map((item) => Number(item) / 10000)
          );
          newBasicData = {
            salary: query
              ?.get("salary")
              ?.split(",")
              ?.map((str) => parseInt(str)),
          };
        } else {
          newBasicData = {
            salary: query
              ?.get("salary")
              ?.split(",")
              ?.map((str) => parseInt(str)),
          };
          setRangeValue(
            query
              .get("salary")
              .split(",")
              .map((item) => Number(item) / 1000)
          );
        }
        setSalaryRange(range);
        if (query.get("experience")) {
          setExpRange(
            query
              .get("experience")
              .split(",")
              .map((item) => Number(item))
          );
        }

        setBasicData({
          ...newBasicData,
          experience: query.get("experience")
            ? query
                .get("experience")
                .split(",")
                .map((item) => Number(item))
            : "",
          job_title: Array.from(payload?.data?.jobTitle, (item) => ({
            value: item?.job_title_id,
            label: item?.title,
          })),
          region_id: Array.from(payload?.data?.countries, (item) => ({
            value: item?.country_id,
            label: item?.name,
          })),
          tag_id: Array.from(payload?.data?.tags, (item) => ({
            value: item?.tag_id,
            label: item?.tag,
          })),
          town_id: Array.from(payload?.data?.towns, (item) => ({
            value: item?.town_id,
            label: item?.name,
          })),
          tool_id: Array.from(payload?.data?.tools, (item) => ({
            value: item?.tool_id,
            label: item?.name,
          })),
          currency_id: Array.from(payload?.data?.currency, (item) => ({
            value: item?.currency_id,
            label: item?.currency,
          })),
          highest_qualification_id: Array.from(
            payload?.data?.highestQual,
            (item) => ({
              value: item?.highest_qualification_id,
              label: item?.description,
            })
          ),
          company_id: Array.from(payload?.data?.companies, (item) => ({
            value: item?.company_id,
            label: item?.name,
          })),
        });
      }
    } catch (error) {}
  };
  const handleReset = () => {
    setBasicData({
      job_title: [],
      region_id: [],
      tag_id: [],
      town_id: [],
      tool_id: [],
      salary: [],
      experience: [],
      company_id: [],
      currency_id: [],
      highest_qualification_id: [],
    });
    setExpRange([0, 2]);
  };
  useEffect(() => {
    query.size > 0 && getData();
  }, [query.size]);

  useEffect(() => {
    // setBasicData({...basicData,salary:[0,10000],currency_id:[{label:"ZAR", value:"1"}]})
    getInitialCurrency();
  }, []);
  useEffect(()=>{
console.log(rangeValue,"rangeValue")
  },[rangeValue])
  useEffect(() => {
    console.log("Hello")
    const currencySalary = currency?.find(
      (item) => item?.currency_id === basicData?.currency_id
    );
    let range = [0, 10];
    let salaryInterval = 1;
    if (basicData?.job_role_type === "freelance") {
      const { rangeArray, interval } = genarateLabelValueForFreelance(
        currencySalary ? currencySalary : { max_rate: 2050 }
      );
      range = rangeArray?.length ? rangeArray : [0, 10];
      salaryInterval = interval || 1;

      setInterval(salaryInterval);
      setRangeValue([0, Math.floor(range.length / 2)]);

    } else {
      const generatedRange = genarateLabelValue(
        currencySalary ? currencySalary : { max_salary: 205000 }
      );
      range = generatedRange?.length ? generatedRange : [0, 10];
    }
    setSalaryRange(range);
    if (basicData?.job_role_type === "freelance") {
      if (basicData?.currency_id === 1) {
        setRangeValue([
          basicData?.salary?.[0] ? basicData.salary[0] / 100 : 0,
          basicData?.salary?.[1] ? basicData.salary[1] / 100 : 10,
        ]);
      } else {
        setRangeValue([
          basicData?.salary?.[0] ? basicData.salary[0] / salaryInterval : 0,
          basicData?.salary?.[1] ? basicData.salary[1] / salaryInterval : 10,
        ]);
      }
    } else {
      if (basicData?.currency_id === 1) {
        setRangeValue([
          basicData?.salary?.[0] ? basicData.salary[0] / 10000 : 0,
          basicData?.salary?.[1] ? basicData.salary[1] / 10000 : 10,
        ]);
      } else {
        setRangeValue([
          basicData?.salary?.[0] ? basicData.salary[0] / 10000 : 0,
          basicData?.salary?.[1] ? basicData.salary[1] / 10000 : 10,
        ]);
      }
    }
  }, []);

  useEffect(() => {
    if (openAdvanceSearch && query?.size > 0) {
      getSearchedData();
    }
  }, [openAdvanceSearch]);

  return (
    <Box
      ref={boxRef}
      sx={{
        display: "flex",
        alignItems: "center",
        height: "auto",
        flexDirection: "column",
      }}
    >
      {openAdvanceSearch && (
        <Box
          sx={{
            width: homePage ? "97.4%" : "-webkit-fill-available",
            // width: homePage ? "97.4%" ",
            background: theme.palette.chart.gray,
            padding: { xs: "0 10px", tablet: "0 30px" },
            paddingBottom: { xs: "0px", tablet: "0" },
            pb: 0,
            pt: 2,
            gap: 1,
            display: "flex",
            boxShadow: 1,
            flexDirection: "column",
            borderRadius: "0 0 20px 20px",
            marginLeft: homePage && "16px",
            marginBottom: { xs: "45px", tablet: "0px" },
          }}
        >
          {!showSearchBar && (
            <Box sx={{ marginTop: "0px", textAlign: "center" }}>
              <Button
                sx={{
                  padding: "4px 20px",
                  height: 30,
                  width: { xs: "64px", tablet: "100px" },
                  borderRadius: "0 0 10px 10px",
                  boxShadow: `0px 5px 10px ${theme.palette.boxShadow.main} !important`,
                  "& .MuiButton-endIcon": {
                    margin: "0px !important",
                  },
                }}
                size="small"
                variant="contained"
                endIcon={
                  <Box
                    component="img"
                    className="eye"
                    alt="eye logo"
                    src={SearchIcon}
                    sx={{
                      height: 20,
                      width: 20,
                    }}
                    // onClick={() => {
                    //   setOpenAdvanceSearch((prevState) => !prevState);
                    //   setBasicData(BASIC);
                    // }}
                  />
                }
                color={"nandos"}
                onClick={() => {
                  handleShowSearchBar();
                  // setShowSearchBar(!showSearchBar)
                }}
              ></Button>
            </Box>
          )}
          <Box
            sx={{
              display: "flex",
              gap: { xs: "20px", md24: "60px" },
              width: "100%",
              flexDirection: { xs: "column", md24: "row" },
            }}
          >
            <Box sx={{ width: { xs: "100%", md24: "50%" } }}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "end",
                  marginLeft: "9px",
                  gap: "10px",
                  marginBottom: "3px",
                }}
              >
                <Box
                  component={"img"}
                  src={GreenTitle}
                  sx={{
                    height: 20,
                    width: 16,
                  }}
                />
                <InputLabel
                  sx={{
                    color: "black",
                    fontSize: theme.typography.fontSize.sm,
                    fontWeight: theme.typography.Bold,
                    lineHeight: "18px",
                  }}
                >
                  Job Titles
                </InputLabel>
              </Box>

              <CustomSelect
                closeMenuOnSelect={false}
                variant="multiSelect"
                onInputChange={handleOnSelectInputChange}
                onChange={handleOnSelectChange}
                placeholder={"Select a job title"}
                inputId="job_title"
                name="job_title"
                value={
                  basicData?.job_title?.length > 0 ? basicData?.job_title : null
                }
                multiOnFocus={() => {
                  if (titles?.length < 1) {
                    dispatch(getTitles());
                  }
                  setIsMenuOpen(true);
                }}
                isLoading={isLoading}
                customStyle={{
                  menu: (provided) => ({
                    ...provided,
                    zIndex: 9999,
                  }),
                  menuList: (provided) => ({
                    ...provided,
                    "&::-webkit-scrollbar": {
                      width: "6px",
                      height: "6px",
                      borderRadius: "2.5px",
                      backgroundColor: "#efefef",
                    },
                    "&::-webkit-scrollbar-thumb": {
                      borderRadius: "2.5px",
                    },
                  }),
                  option: (
                    styles,
                    { data, isDisabled, isFocused, isSelected }
                  ) => {
                    return {
                      ...styles,
                      color: isSelected ? "#fff" : "black",
                      ":active": {
                        ...styles[":active"],
                      },
                    };
                  },
                  control: (baseStyles) => ({
                    ...baseStyles,
                    boxShadow: "none",
                    borderRadius: "10px",
                    border: "1px solid #E0E0E0",
                    "&:hover": "1px solid #E0E0E0",
                    width: `100%`,
                  }),
                }}
                multiSelectOptions={titles?.map((item) => ({
                  value: item?.job_title_id,
                  label: item?.title,
                }))}
              />
            </Box>
            <Box sx={{ width: { xs: "100%", md24: "50%" } }}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  marginLeft: "8px",
                  gap: "10px",
                  marginBottom: "3px",
                }}
              >
                <Box
                  component={"img"}
                  src={BlueFlag}
                  sx={{
                    height: 18,
                    width: 16,
                  }}
                />
                <InputLabel
                  sx={{
                    color: "black",
                    fontSize: theme.typography.fontSize.sm,
                    fontWeight: theme.typography.Bold,
                    lineHeight: "18px",
                  }}
                >
                  Country
                </InputLabel>
              </Box>
              <CustomSelect
                closeMenuOnSelect={false}
                variant="multiSelect"
                onInputChange={handleOnSelectInputChange}
                onChange={handleOnSelectChange}
                placeholder={"Select the country you are in"}
                inputId="region_id"
                name="region_id"
                filterOption={"filter"}
                value={
                  basicData?.region_id?.length > 0 ? basicData?.region_id : null
                }
                multiOnFocus={() => {
                  if (country.length === 0) {
                    getAllData("country");
                  }
                  setIsMenuOpen(true);
                }}
                isLoading={isLoading}
                customStyle={{
                  menu: (provided) => ({
                    ...provided,
                    zIndex: 9999,
                  }),
                  menuList: (provided) => ({
                    ...provided,
                    "&::-webkit-scrollbar": {
                      width: "6px",
                      height: "6px",
                      borderRadius: "2.5px",
                      backgroundColor: "#efefef",
                    },
                    "&::-webkit-scrollbar-thumb": {
                      borderRadius: "2.5px",
                    },
                  }),
                  option: (
                    styles,
                    { data, isDisabled, isFocused, isSelected }
                  ) => {
                    return {
                      ...styles,
                      color: isSelected ? "#fff" : "black",
                      ":active": {
                        ...styles[":active"],
                      },
                    };
                  },
                  control: (baseStyles) => ({
                    ...baseStyles,
                    boxShadow: "none",
                    borderRadius: "10px",
                    border: "1px solid #E0E0E0",
                    "&:hover": "1px solid #E0E0E0",
                    width: `100%`,
                  }),
                }}
                multiSelectOptions={country?.map((item) => ({
                  value: item?.country_id,
                  label: item?.name,
                }))}
              />
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              gap: { xs: "20px", md24: "60px" },
              width: "100%",
              flexDirection: { xs: "column", md24: "row" },
            }}
          >
            <Box sx={{ width: { xs: "100%", md24: "50%" } }}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  marginLeft: "9px",
                  gap: "10px",
                  marginBottom: "3px",
                }}
              >
                <Box
                  component={"img"}
                  src={BlueSkills}
                  sx={{
                    height: 18,
                    width: 16,
                  }}
                />
                <InputLabel
                  sx={{
                    color: "black",
                    fontSize: theme.typography.fontSize.sm,
                    fontWeight: theme.typography.Bold,
                    lineHeight: "18px",
                  }}
                >
                  Skills
                </InputLabel>
              </Box>
              <CustomSelect
                closeMenuOnSelect={false}
                variant="multiSelect"
                onInputChange={handleOnSelectInputChange}
                onChange={handleOnSelectChange}
                placeholder={"Select the skills you are proficient in "}
                inputId="tag_id"
                name="tag_id"
                value={basicData.tag_id?.length > 0 ? basicData.tag_id : null}
                multiOnFocus={() => {
                  if (skills.length === 0) {
                    getAllData("skills");
                  }
                  setIsMenuOpen(true);
                }}
                // initialOptions={initialTitles?.map((item) => ({
                //   value: item?.job_title_id,
                //   label: item?.title,
                // }))}
                isLoading={isLoading}
                customStyle={{
                  menu: (provided) => ({
                    ...provided,
                    zIndex: 9999,
                  }),
                  menuList: (provided) => ({
                    ...provided,
                    "&::-webkit-scrollbar": {
                      width: "6px",
                      height: "6px",
                      borderRadius: "2.5px",
                      backgroundColor: "#efefef",
                    },
                    "&::-webkit-scrollbar-thumb": {
                      borderRadius: "2.5px",
                    },
                  }),
                  option: (
                    styles,
                    { data, isDisabled, isFocused, isSelected }
                  ) => {
                    return {
                      ...styles,
                      color: isSelected ? "#fff" : "black",
                      ":active": {
                        ...styles[":active"],
                      },
                    };
                  },
                  control: (baseStyles) => ({
                    ...baseStyles,
                    boxShadow: "none",
                    borderRadius: "10px",
                    border: "1px solid #E0E0E0",
                    "&:hover": "1px solid #E0E0E0",
                    width: `100%`,
                  }),
                }}
                multiSelectOptions={skills?.map((item) => ({
                  value: item?.tag_id,
                  label: item?.tag,
                }))}
              />
            </Box>
            <Box sx={{ width: { xs: "100%", md24: "50%" } }}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  marginLeft: "9px",
                  gap: "10px",
                  marginBottom: "3px",
                }}
              >
                <Box
                  component={"img"}
                  src={RedLocation}
                  sx={{
                    height: 16,
                    width: 16,
                    maxHeight: { xs: 16 },
                    maxWidth: { xs: 16 },
                  }}
                />
                <InputLabel
                  sx={{
                    color: "black",
                    fontSize: theme.typography.fontSize.sm,
                    fontWeight: theme.typography.Bold,
                    lineHeight: "18px",
                  }}
                >
                  Location
                </InputLabel>
              </Box>

              <CustomSelect
                closeMenuOnSelect={false}
                variant="multiSelect"
                onInputChange={handleOnSelectInputChange}
                onChange={handleOnSelectChange}
                placeholder={"Select your town or city"}
                inputId="town_id"
                name="town_id"
                filterOption={"filter"}
                value={getTownValue()}
                multiOnFocus={() => {
                  if (town?.length === 0) {
                    // getAllData("town");
                    dispatch(getTowns(""));
                  }
                  setIsMenuOpen(true);
                }}
                // initialOptions={initialTitles?.map((item) => ({
                //   value: item?.job_title_id,
                //   label: item?.title,
                // }))}
                isLoading={isLoading}
                customStyle={{
                  menu: (provided) => ({
                    ...provided,
                    zIndex: 9999,
                  }),
                  menuList: (provided) => ({
                    ...provided,
                    "&::-webkit-scrollbar": {
                      width: "6px",
                      height: "6px",
                      borderRadius: "2.5px",
                      backgroundColor: "#efefef",
                    },
                    "&::-webkit-scrollbar-thumb": {
                      borderRadius: "2.5px",
                    },
                  }),
                  option: (
                    styles,
                    { data, isDisabled, isFocused, isSelected }
                  ) => {
                    return {
                      ...styles,
                      color: isSelected ? "#fff" : "black",
                      ":active": {
                        ...styles[":active"],
                      },
                    };
                  },
                  control: (baseStyles) => ({
                    ...baseStyles,
                    boxShadow: "none",
                    borderRadius: "10px",
                    border: "1px solid #E0E0E0",
                    "&:hover": "1px solid #E0E0E0",
                    width: `100%`,
                  }),
                }}
                multiSelectOptions={town?.map((item) => ({
                  value: item?.town_id,
                  label: item?.name,
                }))}
              />
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              gap: { xs: "20px", md24: "60px" },
              width: "100%",
              flexDirection: { xs: "column", md24: "row" },
            }}
          >
            <Box sx={{ width: { xs: "100%", md24: "50%" } }}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  marginLeft: "9px",
                  gap: "10px",
                  marginBottom: "3px",
                }}
              >
                <Box
                  component={"img"}
                  src={YellowTool}
                  sx={{
                    height: 18,
                    width: 16,
                  }}
                />
                <InputLabel
                  sx={{
                    color: "black",
                    fontSize: theme.typography.fontSize.sm,
                    fontWeight: theme.typography.Bold,
                    lineHeight: "18px",
                  }}
                >
                  Tools
                </InputLabel>
              </Box>

              <CustomSelect
                closeMenuOnSelect={false}
                variant="multiSelect"
                onInputChange={handleOnSelectInputChange}
                onChange={handleOnSelectChange}
                placeholder={"Select the the tools you are proficient in "}
                inputId="tool_id"
                name="tool_id"
                value={basicData.tool_id?.length > 0 ? basicData.tool_id : null}
                multiOnFocus={() => {
                  if (tools.length === 0) {
                    getAllData("tools");
                  }
                  // setIsMenuOpen(true);
                }}
                // initialOptions={initialTitles?.map((item) => ({
                //   value: item?.job_title_id,
                //   label: item?.title,
                // }))}
                isLoading={isLoading}
                customStyle={{
                  menu: (provided) => ({
                    ...provided,
                    zIndex: 9999,
                  }),
                  menuList: (provided) => ({
                    ...provided,
                    "&::-webkit-scrollbar": {
                      width: "6px",
                      height: "6px",
                      borderRadius: "2.5px",
                      backgroundColor: "#efefef",
                    },
                    "&::-webkit-scrollbar-thumb": {
                      borderRadius: "2.5px",
                    },
                  }),
                  option: (
                    styles,
                    { data, isDisabled, isFocused, isSelected }
                  ) => {
                    return {
                      ...styles,
                      color: isSelected ? "#fff" : "black",
                      ":active": {
                        ...styles[":active"],
                      },
                    };
                  },
                  control: (baseStyles) => ({
                    ...baseStyles,
                    boxShadow: "none",
                    borderRadius: "10px",
                    border: "1px solid #E0E0E0",
                    "&:hover": "1px solid #E0E0E0",
                    width: `100%`,
                  }),
                }}
                multiSelectOptions={tools?.map((item) => ({
                  value: item?.tool_id,
                  label: item?.name,
                }))}
              />
            </Box>
            <Box sx={{ width: { xs: "100%", md24: "50%" } }}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  marginLeft: "9px",
                  gap: "10px",
                  marginBottom: "3px",
                }}
              >
                <Box
                  component={"img"}
                  src={BlueCompany}
                  sx={{
                    // padding: "5px",
                    height: 18,
                    width: 16,
                  }}
                />
                <InputLabel
                  sx={{
                    color: "black",
                    fontSize: theme.typography.fontSize.sm,
                    fontWeight: theme.typography.Bold,
                    lineHeight: "18px",
                  }}
                >
                  Companies
                </InputLabel>
              </Box>
              {isTalentPage ? (
                <CustomSelect
                  closeMenuOnSelect={false}
                  variant="multiSelect"
                  onInputChange={handleOnSelectInputChange}
                  onChange={handleOnSelectChange}
                  placeholder={"Select your preferred next company"}
                  inputId="company_id"
                  name="company_id"
                  value={
                    basicData?.company_id?.length > 0
                      ? basicData?.company_id
                      : null
                  }
                  multiOnFocus={() => {
                    if (candidateCompany?.length === 0) {
                      getAllData("candidateCompany");
                    }
                    // setIsMenuOpen(true);
                  }}
                  isLoading={isLoading}
                  customStyle={{
                    menu: (provided) => ({
                      ...provided,
                      zIndex: 9999,
                    }),
                    menuList: (provided) => ({
                      ...provided,
                      "&::-webkit-scrollbar": {
                        width: "6px",
                        height: "6px",
                        borderRadius: "2.5px",
                        backgroundColor: "#efefef",
                      },
                      "&::-webkit-scrollbar-thumb": {
                        borderRadius: "2.5px",
                      },
                    }),
                    option: (
                      styles,
                      { data, isDisabled, isFocused, isSelected }
                    ) => {
                      return {
                        ...styles,
                        color: isSelected ? "#fff" : "black",
                        ":active": {
                          ...styles[":active"],
                        },
                      };
                    },
                    control: (baseStyles) => ({
                      ...baseStyles,
                      boxShadow: "none",
                      borderRadius: "10px",
                      border: "1px solid #E0E0E0",
                      "&:hover": "1px solid #E0E0E0",
                      width: `100%`,
                    }),
                  }}
                  multiSelectOptions={candidateCompany?.map((item) => ({
                    value: item?.candidate_company_id,
                    label: item?.name,
                  }))}
                />
              ) : (
                <CustomSelect
                  closeMenuOnSelect={false}
                  variant="multiSelect"
                  onInputChange={handleOnSelectInputChange}
                  onChange={handleOnSelectChange}
                  placeholder={"Select your preferred next company"}
                  inputId="company_id"
                  name="company_id"
                  value={
                    basicData?.company_id?.length > 0
                      ? basicData?.company_id
                      : null
                  }
                  multiOnFocus={() => {
                    if (companies?.length === 0) {
                      getAllData("companies");
                    }
                    // setIsMenuOpen(true);
                  }}
                  // initialOptions={initialTitles?.map((item) => ({
                  //   value: item?.job_title_id,
                  //   label: item?.title,
                  // }))}
                  isLoading={isLoading}
                  customStyle={{
                    menu: (provided) => ({
                      ...provided,
                      zIndex: 9999,
                    }),
                    menuList: (provided) => ({
                      ...provided,
                      "&::-webkit-scrollbar": {
                        width: "6px",
                        height: "6px",
                        borderRadius: "2.5px",
                        backgroundColor: "#efefef",
                      },
                      "&::-webkit-scrollbar-thumb": {
                        borderRadius: "2.5px",
                      },
                    }),
                    option: (
                      styles,
                      { data, isDisabled, isFocused, isSelected }
                    ) => {
                      return {
                        ...styles,
                        color: isSelected ? "#fff" : "black",
                        ":active": {
                          ...styles[":active"],
                        },
                      };
                    },
                    control: (baseStyles) => ({
                      ...baseStyles,
                      boxShadow: "none",
                      borderRadius: "10px",
                      border: "1px solid #E0E0E0",
                      "&:hover": "1px solid #E0E0E0",
                      width: `100%`,
                    }),
                  }}
                  multiSelectOptions={companies?.map((item) => ({
                    value: item?.company_id,
                    label: item?.name,
                  }))}
                />
              )}
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              gap: { xs: "20px", md24: "60px" },
              width: "100%",
              flexDirection: { xs: "column", md24: "row" },
            }}
          >
            <Box sx={{ width: { xs: "100%", md24: "50%" } }}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  marginLeft: "9px",
                  gap: "10px",
                  marginBottom: "3px",
                }}
              >
                <Box
                  component={"img"}
                  src={GreenCountry}
                  sx={{
                    height: 18,
                    width: 16,
                    // padding: "4px",
                    boxSizing: "border-box",
                  }}
                />
                <InputLabel
                  sx={{
                    color: "black",
                    fontSize: theme.typography.fontSize.sm,
                    fontWeight: theme.typography.Bold,
                    lineHeight: "18px",
                  }}
                >
                  Currency
                </InputLabel>
              </Box>
              <CustomSelect
                closeMenuOnSelect={false}
                variant="multiSelect"
                onInputChange={handleOnSelectInputChange}
                onChange={handleOnSelectChange}
                placeholder={"Indicate your preferred currency"}
                inputId="currency_id"
                name="currency_id"
                filterOption={"filter"}
                value={
                  basicData?.currency_id?.length > 0
                    ? basicData?.currency_id
                    : null
                }
                multiOnFocus={() => {
                  if (currency?.length === 0) {
                    getAllData("currency");
                  }
                  // setIsMenuOpen(true);
                }}
                // initialOptions={initialTitles?.map((item) => ({
                //   value: item?.job_title_id,
                //   label: item?.title,
                // }))}
                isLoading={isLoading}
                customStyle={{
                  menu: (provided) => ({
                    ...provided,
                    zIndex: 9999,
                  }),
                  menuList: (provided) => ({
                    ...provided,
                    "&::-webkit-scrollbar": {
                      width: "6px",
                      height: "6px",
                      borderRadius: "2.5px",
                      backgroundColor: "#efefef",
                    },
                    "&::-webkit-scrollbar-thumb": {
                      borderRadius: "2.5px",
                    },
                  }),
                  option: (
                    styles,
                    { data, isDisabled, isFocused, isSelected }
                  ) => {
                    return {
                      ...styles,
                      color: isSelected ? "#fff" : "black",
                      ":active": {
                        ...styles[":active"],
                      },
                    };
                  },
                  control: (baseStyles) => ({
                    ...baseStyles,
                    boxShadow: "none",
                    borderRadius: "10px",
                    border: "1px solid #E0E0E0",
                    "&:hover": "1px solid #E0E0E0",
                    width: `100%`,
                  }),
                }}
                multiSelectOptions={currency?.map((item) => ({
                  value: item?.currency_id,
                  label: item?.currency,
                }))}
              />
            </Box>
            <Box sx={{ width: { xs: "100%", md24: "50%" } }}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  marginLeft: "9px",
                  gap: "10px",
                  marginBottom: "3px",
                }}
              >
                <Box
                  component={"img"}
                  src={GreenEducation}
                  sx={{
                    height: 18,
                    width: 16,
                  }}
                />
                <InputLabel
                  sx={{
                    color: "black",
                    fontSize: theme.typography.fontSize.sm,
                    fontWeight: theme.typography.Bold,
                    lineHeight: "18px",
                  }}
                >
                  Highest Qualification
                </InputLabel>
              </Box>

              <CustomSelect
                closeMenuOnSelect={false}
                variant="multiSelect"
                filterOption={"filter"}
                onInputChange={handleOnSelectInputChange}
                onChange={handleOnSelectChange}
                placeholder={"Select your highest qualification"}
                inputId="highest_qualification_id"
                name="highest_qualification_id"
                value={
                  basicData.highest_qualification_id?.length > 0
                    ? basicData.highest_qualification_id
                    : null
                }
                multiOnFocus={() => {
                  if (requiredQua.length === 0) {
                    getAllData("requiredQua");
                  }
                  // setIsMenuOpen(true);
                }}
                // initialOptions={initialTitles?.map((item) => ({
                //   value: item?.job_title_id,
                //   label: item?.title,
                // }))}
                isLoading={isLoading}
                customStyle={{
                  menu: (provided) => ({
                    ...provided,
                    zIndex: 9999,
                  }),
                  menuList: (provided) => ({
                    ...provided,
                    "&::-webkit-scrollbar": {
                      width: "6px",
                      height: "6px",
                      borderRadius: "2.5px",
                      backgroundColor: "#efefef",
                    },
                    "&::-webkit-scrollbar-thumb": {
                      borderRadius: "2.5px",
                    },
                  }),
                  option: (
                    styles,
                    { data, isDisabled, isFocused, isSelected }
                  ) => {
                    return {
                      ...styles,
                      color: isSelected ? "#fff" : "black",
                      ":active": {
                        ...styles[":active"],
                      },
                    };
                  },
                  control: (baseStyles) => ({
                    ...baseStyles,
                    boxShadow: "none",
                    borderRadius: "10px",
                    border: "1px solid #E0E0E0",
                    "&:hover": "1px solid #E0E0E0",
                    width: `100%`,
                  }),
                }}
                multiSelectOptions={requiredQua?.map((item) => ({
                  value: item?.highest_qualification_id,
                  label: item?.description,
                }))}
              />
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              gap: { xs: "10px", md24: "3" },
              width: "100%",
              flexDirection: "column",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                marginLeft: "9px",
                gap: "10px",
                marginBottom: "3px",
              }}
            >
              <Box
                component={"img"}
                src={SalaryIcon}
                sx={{
                  height: 18,
                  width: 16,
                }}
              />
              <InputLabel
                sx={{
                  color: "black",
                  fontSize: theme.typography.fontSize.sm,
                  fontWeight: theme.typography.Bold,
                  lineHeight: "18px",
                }}
              >
                Salary / Rate Range
              </InputLabel>
            </Box>
            <Slider
              disableSwap
              valueLabelDisplay="on"
              // Keep the slider enabled at all times by removing the condition on the disabled prop
              disabled={false}
              name="salary"
              getAriaLabel={() => "Temperature range"}
              value={rangeValue}
              onChange={
                basicData?.job_role_type == "freelance"
                  ? handleRangeSliderChange2
                  : handleRangeSliderChange
              }
              color="salarySlider"
              valueLabelFormat={
                basicData?.job_role_type == "freelance"
                  ? rangeValueHandler2
                  : rangeValueHandler
              }
              getAriaValueText={
                basicData?.job_role_type == "freelance"
                  ? rangeValueHandler2
                  : rangeValueHandler
              }
              marks={
                width < 768
                  ? [salaryRange?.[0], salaryRange?.[salaryRange?.length - 1]]
                  : salaryRange
              }
              min={0}
              max={salaryRange?.length - 1}
              step={0.1}
              sx={{
                marginTop: 4,
                color:"#B98B5F",
                width: { xs: "85%", tablet: "95%" },
                ml: "25px",
                "& .MuiSlider-rail": {
                  backgroundColor: theme?.palette?.grayButton700?.main,
                  height: "10px",
                  border: `1px solid ${theme?.palette?.grayButton700?.main}`,
                },
                "& .MuiSlider-track": {
                  backgroundColor: theme?.palette?.discovery?.main,
                  borderRadius: "3px",
                  height: "10px",
                },
                "& .MuiSlider-thumb": {
                  backgroundColor: theme?.palette?.discovery?.contrastText,
                  borderRadius: "15%",
                },
                "& .MuiSlider-valueLabel.MuiSlider-valueLabelOpen": {
                  backgroundColor: "#EBECF3",
                },
                "& .MuiSlider-valueLabel:before": {
                  display: "none",
                },
                "& .MuiSlider-valueLabel": {
                  fontSize: 12,
                  fontWeight: "normal",
                  top: -6,
                  borderRadius: 1,
                  color: "#000",
                  "&:before": {
                    display: "none",
                  },
                  "& *": {
                    background: "transparent",
                    color: theme.palette.mode === "dark" ? "#fff" : "#000",
                  },
                },
              }}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              gap: 3,
              width: "100%",
              flexDirection: "column",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "10px",
                marginBottom: "7px",
                marginLeft: "9px",
              }}
            >
              <Box
                component={"img"}
                src={ExperienceIcon}
                sx={{
                  height: 16,
                  width: 15,
                  maxHeight: { xs: 16 },
                  maxWidth: { xs: 15 },
                }}
              />
              <InputLabel
                sx={{
                  color: "black",
                  fontSize: theme.typography.fontSize.sm,
                  fontWeight: theme.typography.Bold,
                  lineHeight: "18px",
                }}
              >
                Experience
              </InputLabel>
            </Box>
            <Slider
              disableSwap
              sx={{
                width: { xs: "85%", tablet: "95%" },
                ml: "25px",
                "& .MuiSlider-rail": {
                  backgroundColor: theme.palette.grayButton700.main,
                  height: "10px",
                  border: `1px solid ${theme?.palette?.grayButton700?.main}`,
                },
                "& .MuiSlider-track": {
                  height: "10px",
                },
                "& .MuiSlider-thumb": {
                  borderRadius: "15%",
                  background: "white",
                },
                "& .MuiSlider-valueLabel": {
                  fontSize: 12,
                  fontWeight: "bold",
                  top: -6,
                  backgroundColor: theme.palette.base.main,
                  borderRadius: 1,
                  color: theme.palette.text.primary,
                  "&:before": {
                    display: "none",
                  },
                  "& *": {
                    background: "transparent",
                    color: theme.palette.mode === "dark" ? "#fff" : "#000",
                  },
                },
              }}
              name="experience"
              getAriaLabel={() => "Temperature range"}
              value={expRange}
              valueLabelDisplay="on"
              onChange={expHandleChange}
              color="tealColor"
              valueLabelFormat={rangeValueExperience}
              getAriaValueText={rangeValueExperience}
              min={0}
              max={20}
              step={1}
              marks={
                // EXPERIENCE
                width < 768
                  ? [EXPERIENCE?.[0], EXPERIENCE?.[EXPERIENCE?.length - 1]]
                  : EXPERIENCE
              }
            />
          </Box>
          <Box
            sx={{
              margin: "auto",
              paddingTop: 2,
              display: { xs: "none", tablet: "flex" },
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
                marginRight: "5px",
              }}
            >
              <Box
                component={"img"}
                src={resetIcon}
                height={20}
                width={20}
                onClick={handleReset}
              />
            </Box>
            <Button
              variant="contained"
              color={"nandos"}
              sx={{
                borderRadius: "10px 0 0 0",
                width: "100px",
                height: "45px",
                fontSize: theme.typography.fontSize.sm,
                fontWeight: theme?.typography?.Bold,
                padding: "0px",
              }}
              onClick={handleJobSearch}
            >
              find work
            </Button>
            <Button
              variant="contained"
              color={"yoco"}
              // color={color}
              sx={{
                borderRadius: "0px",
                width: "100px",
                height: "45px",
                fontSize: theme.typography.fontSize.sm,
                fontWeight: theme?.typography?.Bold,
                padding: "0px",
              }}
              onClick={() => handleJobSearch("talent")}
            >
              find talent
            </Button>
            <Button
              variant="contained"
              color={"tymeColor"}
              // color={color}
              sx={{
                borderRadius: "0 10px 0 0",
                width: "100px",
                height: "45px",
                fontSize: theme.typography.fontSize.sm,
                fontWeight: theme?.typography?.Bold,
                padding: "0px",
              }}
              onClick={() => handleJobSearch("whoshiring")}
            >
              find a biz
            </Button>
            <Box
              sx={{ display: "flex", alignItems: "center", cursor: "pointer" }}
            >
              <Box
                component={"img"}
                src={closeIcon}
                height={30}
                width={30}
                onClick={() => {
                  setOpenAdvanceSearch((prevState) => !prevState);
                  setShowArrow(false);
                  setShowSearchBar(false);
                }}
              />
            </Box>
          </Box>
          <Box
            sx={{
              display: {
                xs: "flex",
                tablet: "none",
                width: "100%",
                justifyContent: "center",
              },
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
                marginRight: "5px",
              }}
            >
              <Box
                component={"img"}
                src={resetIcon}
                height={20}
                width={20}
                onClick={handleReset}
              />
            </Box>
            <Button
              variant="contained"
              color="grayButton200"
              onClick={() => {
                setOpenAdvanceSearch((prevState) => !prevState);
                setShowArrow(false);
                setShowSearchBar(false);
              }}
              sx={{
                borderRadius: "25px 0 0 0",
                width: "100px",
                height: "45px",
                fontSize: { xs: "12px", tablet: "16px" },

                fontWeight: theme.typography.Bold,
                ".MuiButton-startIcon": {
                  marginRight: "0px !important",
                },
              }}
              endIcon={
                <Box component={"img"} src={closeIcon} height={30} width={30} />
              }
            >
              close
            </Button>
            <SearchBarDropDown
              handleJobSearch={handleJobSearch}
              filterSection={true}
              selectedValue={selectedValue}
              setSelectedValue={setSelectedValue}
              top={false}
            />
          </Box>
        </Box>
      )}
      {!openAdvanceSearch && !showArrow && (
        <Box sx={{ width: "200px", textAlign: { xs: "center" } }}>
          {!showSearchBar && (
            <Button
              sx={{
                padding: `${width > 767 ? "4px 20px" : "4px,0px"}`,
                height: 30,
                // width: `${width>767? 100:60}`,
                width: { xs: "64px", tablet: "100px" },
                borderRadius: "0 0 0 10px",
                boxShadow: `0px 5px 10px ${theme.palette.boxShadow.main} !important`,
                "& .MuiButton-endIcon": {
                  margin: "0px !important",
                },
              }}
              size="small"
              variant="contained"
              endIcon={
                <Box
                  component="img"
                  className="eye"
                  alt="eye logo"
                  src={SearchIcon}
                  sx={{
                    height: 20,
                    width: 20,
                  }}
                  // onClick={() => {
                  //   setOpenAdvanceSearch((prevState) => !prevState);
                  //   setBasicData(BASIC);
                  // }}
                />
              }
              color={"nandos"}
              onClick={() => {
                handleShowSearchBtn();
                // setShowSearchBar(!showSearchBar)
              }}
            ></Button>
          )}
          <Button
            sx={{
              padding: `${width > 1024 ? "4px 20px" : "4px,0px"}`,
              height: 30,
              // width: `${width>1024? 100:60}`,
              width: { xs: "64px", tablet: "100px" },
              borderRadius: showSearchBar ? "0 0 10px 10px" : "0 0 10px 0px",
              boxShadow: `0px 5px 10px ${theme.palette.boxShadow.main} !important`,
              "& .MuiButton-endIcon": {
                margin: "0px !important",
              },
            }}
            size="small"
            variant="contained"
            endIcon={
              <Box
                component="img"
                className="eye"
                alt="eye logo"
                // src={!openAdvanceSearch ? filterIcon : SearchIcon}
                src={filterIcon}
                sx={{
                  height: 20,
                  width: 20,
                }}
                // onClick={() => {
                //   setOpenAdvanceSearch((prevState) => !prevState);
                //   setBasicData(BASIC);
                // }}
              />
            }
            color={"tymeColor"}
            onClick={() => {
              // setShowArrow(false);
              // setShowSearchBar(false);
              setOpenAdvanceSearch((prevState) => !prevState);
              setBasicData(basicData);
              openAdvanceSearch && setShowArrow((prev) => !prev);
            }}
          ></Button>
        </Box>
      )}
    </Box>
  );
};

export default AdvanceSection;
