import { Box } from "@material-ui/core";
import React from "react";
import { Button, Typography, useTheme } from "@mui/material";
import jobVideoicon from "../../../assets/CrayBotIcons/Crayon Job Video Character.svg";
import myVideoicon from "../../../assets/CrayBotIcons/Crayon Cam Video Charactersvg.svg";
import jobVideoHeaderLogo from "../../../assets/Cam/JobVideoIcon.svg";
import myVideoHeaderLogo from "../../../assets/Cam/MyVideoIcon.svg";
import RollCamera from "../../../assets/Cam/RollCamerIcon.svg";
import whiteRecord from "../../../assets/Padding Excluded/WhiteRecord.svg";
import PlayIcon from "../../../assets/Cam/PlayIcon.svg"
// import { useDispatch } from "react-redux";
// import { getMyCamVideo } from "../../../redux/candidate/candidateJobs";
// import { setLoading } from "../../../redux/configSlice";
import useViewport from "../../common/ViewPort";

const MyVideolist = ({
  handleChangeStep,
  newPadding,
  isVideoUploaded,
  setIsVideoUploaded,
  setActiveStep,
}) => {
  const theme = useTheme();
  const { width } = useViewport();

  return (
    <Box
      flexDirection={{ xs: "column", lg: "row" }}
      sx={{
        width: "100%",
        display: "flex",
        gap: "25px",
        paddingBottom: "30px",
        height: { xs: "auto", md: "calc(100% - 10%)" },
        marginTop: width <= 768 || width !== 0 ? "55px" : "55px",
      }}
    >
      <Box
        width={{ xs: "100%", lg: "50%" }}
        sx={{
          background: "#FFF",
          boxShadow: "0px 3px 6px #00000029",
          borderRadius: "25px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <Button
          variant="contained"
          color="precium"
          sx={{
            borderRadius: "25px 0 10px 0",
            width: "150px",
            height: "45px",
            fontSize: "14px",
            gap: "10px",
            fontWeight: theme.typography.Bold,
          }}
        >
          <Box
            component={"img"}
            src={myVideoHeaderLogo}
            sx={{
              height: "20px",
              width: "24px",
            }}
          />
          my video
        </Button>
        <Box
          sx={{
            padding: { xs: "10px 20px 20px", md: "30px 20px 57px" },
            flexDirection: "column",
            alignItems: "center",
            display: "flex",
            gap: "25px",
          }}
        >
          <Box
            sx={{
              width: { xs: "90%", md: "70%" },
              // height: "200px",
              flexDirection: "column",
              alignItems: "center",
              display: "flex",
              gap: "20px",
              marginBottom: { xs: "10px", md: "" },
            }}
          >
            <Typography
              sx={{
                fontSize: "20px",
                fontWeight: theme.typography.Bold,
                textAlign: "center",
              }}
            >
              You’re the star of the show!
            </Typography>
          </Box>

          <Box
            component={"img"}
            src={myVideoicon}
            sx={{
              height: {
                xs: "200px",
                xs360: "200px",
                xs390: "200px",
                md: "250px",
                md24: "250px",
                lgm: "250px",
              },
              maxHeight: "250px",
            }}
          />
          <Box
            sx={{
              width: { xs: "90%", md: "80%" },
              // height: "200px",
              flexDirection: "column",
              alignItems: "center",
              display: "flex",
              gap: "20px",
              marginBottom: { xs: "10px", md: "" },
            }}
          >
            <Typography
              sx={{
                fontSize: "14px",
                textAlign: "center",
                fontWeight: theme.typography.Regular,
              }}
            >
              Give us your elevator pitch! Record or upload a short intro video,
              highlighting your experience, skills, preferred bag of tools and
              what your ideal next career steps might look like.
            </Typography>
            <Typography
              sx={{
                fontSize: "14px",
                textAlign: "center",
                fontWeight: theme.typography.Regular,
              }}
            >
              Completing your profile with a video increases your chances with
              employers and is often the first thing looked at!
            </Typography>
          </Box>
        </Box>
        
        {isVideoUploaded ? (
          <Box sx={{ width: "100%" }}>
            <Button
              variant="contained"
              color="yoco"
              sx={{
                width: "50%",
                height: "60px",
                borderRadius: "0 0 0px 25px",
                fontSize: "15px",
                gap: "10px",
                fontWeight: theme.typography.Bold,
              }}
              onClick={() => setActiveStep(2)}
            >
              <Box
                component={"img"}
                src={RollCamera}
                sx={{
                  height: "20px",
                  width: "24px",
                }}
              />
              roll camera, again
            </Button>
            <Button
              variant="contained"
              color="nandos"
              sx={{
                width: "50%",
                height: "60px",
                borderRadius: "0 0 25px 0",
                fontSize: "15px",
                gap: "10px",
                fontWeight: theme.typography.Bold,
              }}
              onClick={() => handleChangeStep(2)}
            >
              <Box
                component={"img"}
                src={PlayIcon}
                sx={{
                  height: "20px",
                  width: "24px",
                }}
              />
              view video
            </Button>
          </Box>
        ) : (
          <Button
            variant="contained"
            color="nandos"
            sx={{
              width: "100%",
              height: "60px",
              borderRadius: "0 0 25px 25px",
              fontSize: "15px",
              gap: "10px",
              fontWeight: theme.typography.Bold,
            }}
            onClick={() => handleChangeStep(2)}
          >
            <Box
              component={"img"}
              src={RollCamera}
              sx={{
                height: "20px",
                width: "24px",
              }}
            />
            {isVideoUploaded ? "view video" : "roll camera"}
          </Button>
        )}
      </Box>

      <Box
        sx={{
          background: "#FFF",
          boxShadow: "0px 3px 6px #00000029",
          width: { xs: "100%", lg: "50%" },
          borderRadius: "25px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <Button
          variant="contained"
          color="peachPayment"
          sx={{
            borderRadius: "25px 0 10px 0",
            width: "150px",
            height: "45px",
            fontSize: "14px",
            fontWeight: theme.typography.Bold,
            gap: "10px",
          }}
        >
          <Box
            component={"img"}
            src={jobVideoHeaderLogo}
            sx={{
              height: "20px",
              width: "24px",
            }}
          />
          job videos
        </Button>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: { xs: "10px 20px 20px", md: "10px 20px 57px" },
            gap: "25px",
          }}
        >
          <Box
            sx={{
              width: { xs: "90%", md: "70%" },
              // height: "200px",
              flexDirection: "column",
              alignItems: "center",
              display: "flex",
              gap: "20px",
              marginBottom: { xs: "10px", md: "" },
            }}
          >
            <Typography
              sx={{
                fontSize: "20px",
                fontWeight: theme.typography.Bold,
                textAlign: "center",
              }}
            >
              Let’s keep it reels!
            </Typography>
          </Box>

          <Box
            component={"img"}
            src={jobVideoicon}
            sx={{
              height: {
                xs: "200px",
                xs360: "200px",
                xs390: "200px",
                md: "300px",
              },
              width: "auto",
            }}
          />
          <Box
            sx={{
              width: { xs: "90%", md: "80%" },
              // height: "200px",
              flexDirection: "column",
              alignItems: "center",
              display: "flex",
              gap: "20px",
              marginBottom: { xs: "10px", md: "" },
            }}
          >
            <Typography
              sx={{
                fontSize: "14px",
                textAlign: "center",
                fontWeight: theme.typography.Regular,
              }}
            >
              Certain jobs require a quick video as part of the application
              process. Here you will get to showcase skills specific to each job
              and help elevate your profile above the rest.
            </Typography>
            <Typography
              sx={{
                fontSize: "14px",
                textAlign: "center",
                fontWeight: theme.typography.Regular,
              }}
            >
              Make sure to read the brief per job.
            </Typography>
          </Box>
        </Box>
        <Button
          variant="contained"
          color="videoButton"
          sx={{
            width: "100%",
            height: "60px",
            borderRadius: "0 0 25px 25px",
            fontSize: "15px",
            gap: "10px",
            fontWeight: theme.typography.Bold,
          }}
          onClick={() => handleChangeStep(3)}
        >
          <Box
            component={"img"}
            src={PlayIcon}
            sx={{
              height: "20px",
              width: "24px",
            }}
          />
          view video(s)
        </Button>
      </Box>
    </Box>
  );
};

export default MyVideolist;
