import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import DOMPurify from "dompurify";

import { Box, Grid, Popover } from "@mui/material";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import Button from "@mui/material/Button";

import SmallButton from "./SmallButton";
import MUIRadialChart from "./MUIRadialChart";
import job_logo from "../../assets/Crayon_Favicon.svg";
import post_incognito from "../../assets/Incognito.svg";

import job_volume from "../../assets/Padding Excluded/Crayon Icon_Promote.svg";
import education_type from "../../assets/Padding Included/Crayon Icon_Education Type.svg";
import own_equipment from "../../assets/Padding Included/Crayon Icon_Own Equipment.svg";
import gray_own_equipment from "../../assets/Padding Included/gray_Crayon Icon_Own Equipment.svg";
import own_vehicle from "../../assets/Padding Included/Crayon Icon_Own Vehicle.svg";
import gray_own_vehicle from "../../assets/Padding Included/gray_Crayon Icon_Own Vehicle.svg";

import own_internet from "../../assets/Padding Included/Crayon Icon_Own Internet.svg";
import gray_own_internet from "../../assets/Padding Included/gray_Crayon Icon_Own Internet.svg";
import favouriteGrey from "../../assets/Crayon Favourite Icon_Grey.svg";
import favouriteRed from "../../assets/Crayon Favourite Icon_Red.svg";
import profile_challenger from "../../assets/Personality Logos/personality_characters_challenger.svg";
import profile_character from "../../assets/Personality Logos/crayon_personality_character.svg";
import profile_collaborator from "../../assets/Personality Logos/crayon_personality_collaborator.svg";
import profile_contemplator from "../../assets/Personality Logos/crayon_personality_contemplator.svg";
import gray_profile_challenger from "../../assets/Personality Logos/crayon_personality_grey_challenger.svg";
import gray_profile_collaborator from "../../assets/Personality Logos/crayon_personality_grey_collaborator.svg";

import matchMeIcon from "../../assets/Padding Excluded/Black_Match_me.svg";
import applyIcon from "../../assets/Padding Excluded/Black_Follower.svg";
import {
  getAllIndustries,
  setAlert,
  setLoading,
} from "../../redux/configSlice";
import { getJobDetail, getsingleJobDetail } from "../../redux/guest/jobsSlice";

import VerticalStepper from "../../components/guest/jobs/VerticalStepper";
import ApplyJobs from "../../components/guest/jobs/ApplyJobs";
import QuestionStepper from "../../components/guest/jobs/QuestionStepper";

import { ALERT_TYPE, ERROR_MSG } from "../../utils/Constants";
import { convertDatetimeAgo, dateConverterMonth } from "../../utils/DateTime";
import { formatCurrencyWithCommas } from "../../utils/Currency";

import { getAllTypes } from "../../redux/allTypes";
import { getAllJobRoleType } from "../../redux/jobRole";
import { getAllStages } from "../../redux/stages";
import locale from "../../i18n/locale";

import { favouriteJob } from "../../redux/guest/talentSlice";

import BlueCurrency from "../../assets/Blue_Salary.svg";
import experienceIcon from "../../assets/Yellow_Pending.svg";
import redLocation from "../../assets/Red_Location.svg";
import calendar from "../../assets/Green_Notice_Period.svg";
import playButtonIconWhite from "../../assets/Padding Excluded/Black_reactive_job.svg";
import { followCompany, unFollow } from "../../redux/candidate/myProfileSlice";
import { useSelector } from "react-redux";
import { changeStatus } from "../../redux/candidate/candidateJobs";
import { getMyStatusFilter } from "../../redux/candidate/myStatusFilter";
import { getMatchData } from "../../redux/guest/jobsSlice";
import ReviewVideoDialog from "./ReviewVideoDialog";
import JobDetailMatchBox from "./JobDetailMatchBox";
import {
  getDecodedToken,
  getToken,
  renderColor,
  setCookie,
} from "../../utils/Common";
import { LightTooltip } from "../../utils/CommonComponent";
import JobPromoteCard from "../promoter/JobPromoteCard";
import useViewport from "./ViewPort";
import * as Flag from "country-flag-icons/react/3x2";
import crayonLogo from "../../assets/Crayon_Logo.svg";
import QRCode from "react-qr-code";
import JobDetailCompanyComponent from "./JobDetailCompanyComponent";
import Slider2 from "./Slider2";
import upArrow from "../../assets/Black_Up_Close - Copy.svg";
import downArrow from "../../assets/Black_Down_Open - Copy.svg";
import CompanyLogo from "../../assets/Padding Excluded/Blue_Following_Company-.svg";
import CalendarIcon from "../../assets/Padding Included/Yellow_Calendar.svg";
import viewjobsIcon from "../../assets/Padding Excluded/Black_Linked_User_Databases.svg";
import CompanyLink from "../../assets/Padding Excluded/Black_World_Website.svg";
import FollowIcon from "../../assets/Padding Excluded/Black_Company Followers.svg";
import Video_PlayDot from "../../assets/Video_PlayDot.svg";

const JobsDetailsPage = ({
  jobId,
  theme,
  setOpenNotLoggedIn,
  isEditPostjob,
  setShowLogin,
  showLogin,
  pdfRef,
  exportButton,
  exportAsCSV = false,
  routedFrom,
}) => {
  const i18n = locale.en;
  const label1 = "applied";
  const label2 = "shortlisted";
  const label3 = "interviewed";
  const label4 = "grit score";
  const label5 = "contract term";
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let { pathname } = useLocation();
  const location = useLocation();

  let CountryFlag;
  const jobPromoteRef = useRef();
  const [job, setJob] = useState();
  const [jobPromote, setJobPromote] = useState(false);
  const openJobPromote = Boolean(jobPromote);
  const myStatus = useSelector((state) => state.configMyStatus.mystatusfilter);
  const [newCardWidth, setNewCardWidth] = useState(450);
  const [companyFollowed, setCompanyFollowed] = useState(false);
  const [openApplyJobDialog, setopenApplyJobDialog] = useState(false);
  const [viewMoreExpanded, setViewMoreExpanded] = useState(
    exportAsCSV ? exportAsCSV : false
  );
  const [openMatchBox, setOpenMatchMeBox] = useState(false);
  const [isStar, setIsStarSelected] = useState(false);
  const [changedStatus, setChangedStatus] = useState("");
  const [matchedIsLoading, setMatchedIsLoading] = useState(true);
  const [videoLink, setVideoLink] = useState("");
  // const [isLoading, setIsloading] = useState(true);
  const isLoading = useSelector((state) => state.config.loading);

  const [match, setMatch] = useState(false);
  const [openReviewVideoDialog, setOpenReviewVideoDialog] = useState(false);
  const [jobVideoRequired, setJobVideoRequired] = useState(false);
  const [isHorizontalFlipped, setIsHorizontalFlipped] = useState(false);
  const decodedToken = getDecodedToken(getToken());
  const [isApplied, setIsApplied] = useState(false);
  const [showMoreButton, setShowMoreButton] = useState(false);
  const descriptionRef = useRef(null);
  const createMarkup = (html) => {
    return {
      __html: DOMPurify.sanitize(html),
    };
  };
  const { width } = useViewport();

  const calculateHeight = () => {
    const screenWidth = window.innerWidth;

    if (screenWidth < 600) {
      // xs
      return 90;
    } else if (screenWidth < 900) {
      // sm
      return 90;
    } else if (screenWidth < 1366) {
      // md
      return 90;
    } else if (screenWidth < 1789) {
      return 90;
    } else if (screenWidth < 1920) {
      // lg
      return 110;
    } else if (screenWidth < 2559) {
      // xl
      return 125;
    } else {
      // xxl or greater
      return 125;
    }
  };

  const resizeFunction = () => {
    getCardWidth();
  };
  useEffect(() => {
    window.addEventListener("resize", resizeFunction);
    return () => {
      window.removeEventListener("resize", resizeFunction);
    };
  }, []);
  const handleViewMore = () => {
    setViewMoreExpanded((prevState) => !prevState);
  };

  const handleFavourite = (event, jobid) => {
    setIsStarSelected(!isStar);
    setCookie("favoriteJobs", jobid, 30);
  };

  const handleStar = async () => {
    if (decodedToken?.data?.role_id === 3) {
      await dispatch(favouriteJob({ reqid: job?.job_id }));
      setIsStarSelected(!isStar);
    } else {
      dispatch(
        setAlert({
          show: true,
          type: ALERT_TYPE.ERROR,
          msg: "Login as candidate to add this job as favourite",
        })
      );
    }
  };

  const handleCandidateStatus = async (value) => {
    const data = {
      job_id: job?.job_id,
      status_id: value,
    };
    const { payload } = await dispatch(changeStatus(data));
    if (payload?.status === "success") {
      dispatch(
        setAlert({
          show: true,
          type: ALERT_TYPE.SUCCESS,
          msg: "Status Changed Successfully",
        })
      );
      setChangedStatus(
        myStatus.find((status) => status?.candidate_job_status_id === value)
          ?.name
      );
    } else {
      dispatch(
        setAlert({
          show: true,
          type: ALERT_TYPE.ERROR,
          msg: payload?.message,
        })
      );
    }
  };

  const fetchData = async () => {
    // setIsloading(true); // Start loading
    try {
      // Execute all dispatch calls
      await Promise.all([
        dispatch(getAllTypes()),
        dispatch(getAllIndustries()),
        dispatch(getAllJobRoleType()),
        dispatch(getAllStages()),
      ]);
      decodedToken && dispatch(getMyStatusFilter());
      // setIsloading(false);
    } catch (error) {
      console.error("Error fetching data: ", error);
      // Handle any errors here
      // setIsloading(false);
    } finally {
      // setIsloading(false); // End loading
    }
  };

  const getCardWidth = () => {
    const cardElement = document.querySelector(".sliderClass");
    if (cardElement) {
      const newcardWidth = cardElement.clientWidth;
      setNewCardWidth(newcardWidth);
      return newcardWidth;
    }
    return null; // Return null if the card element is not found
  };

  const handleViewJobs = (company_id) => {
    navigate(`/jobs/company/${company_id}`);
  };

  const getMatch = async () => {
    // dispatch(setLoading(true))
    try {
      const data = {
        job_id: job?.job_id,
        user_id: decodedToken?.data?.user_id,
      };
      const { payload } = await dispatch(getMatchData(data));
      if (payload.status === "success") {
        setMatch(payload.data);
        // setOpenMatchMeBox(true);
      }
      // dispatch(setLoading(false))
    } catch (error) {
      dispatch(
        setAlert({
          show: true,
          type: ALERT_TYPE.ERROR,
          msg: error,
        })
      );
      // dispatch(setLoading(false))
    }
  };

  const followComp = async (compID) => {
    try {
      const data = {
        company_id: compID,
      };
      const { payload } = !companyFollowed
        ? await dispatch(followCompany(data))
        : await dispatch(unFollow(data));

      if (
        payload?.data?.status === "success" ||
        payload?.status === "success"
      ) {
        !companyFollowed ? setCompanyFollowed(true) : setCompanyFollowed(false);
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE.SUCCESS,
            msg: "Company followed Successfully",
          })
        );
      }
    } catch (error) {
      dispatch(
        setAlert({
          show: true,
          type: ALERT_TYPE.ERROR,
          msg: error?.message,
        })
      );
    }
  };

  const handleClick = () => {
    if (decodedToken?.data?.role_id !== 4) {
      setopenApplyJobDialog(true);
    } else {
      dispatch(
        setAlert({
          show: true,
          type: ALERT_TYPE.ERROR,
          msg: "Login as candidate to apply for this job",
        })
      );
    }
  };

  const handleCardClick = async () => {
    dispatch(setLoading({ value: true, apiName: "handleCardClick" }));
    try {
      const { payload } =
        routedFrom === "inside"
          ? await dispatch(
              getJobDetail({
                job_id: jobId,
                user_id: decodedToken?.data?.user_id || "",
                decodedToken: decodedToken,
              })
            )
          : await dispatch(
              getsingleJobDetail({
                job_id: jobId,
                user_id: decodedToken?.data?.user_id || "",
                decodedToken: decodedToken,
              })
            );
      if (payload?.status === "success") {
        setCompanyFollowed(payload?.data?.followflag);
        setIsStarSelected(payload?.data?.favflag);
        setJob(payload?.data);
        CountryFlag = Flag[payload?.data?.country_code];
        setChangedStatus(payload?.data?.candidatestatus);
        setJobVideoRequired(payload?.data?.is_video_require);
      }
      dispatch(setLoading({ value: false, apiName: "handleCardClick" }));
    } catch (error) {
      dispatch(setLoading({ value: false, apiName: "handleCardClick" }));

      dispatch(
        setAlert({
          show: true,
          type: ALERT_TYPE?.ERROR,
          msg: ERROR_MSG,
        })
      );
    }
  };

  const handleOpenMatchBox = () => {
    if (decodedToken?.data?.role_id === 3) {
      setOpenMatchMeBox(true);
      getMatch();
    } else {
      setOpenNotLoggedIn(true);
    }
  };

  const handleCloseDialog = () => {
    setVideoLink("");
    setOpenReviewVideoDialog(false);
  };

  useEffect(() => {
    handleCardClick();
    fetchData();
  }, []);

  useEffect(() => {
    if (showLogin) {
      setJobPromote(null);
    }
  }, [showLogin]);

  useEffect(() => {
    if (descriptionRef.current) {
      const lineHeight = 1.6;
      const maxLines = 4;
      const maxHeight = lineHeight * maxLines * 12;
      if (descriptionRef.current.scrollHeight > maxHeight) {
        setShowMoreButton(true);
      }
    }
  }, [job]);

  return (
    !isLoading && (
      <>
        <Grid
          // container
          ref={pdfRef}
          sx={{
            boxShadow:
              pathname?.split("/")?.[1] === "admin" &&
              !exportAsCSV &&
              `0px 5px 10px ${theme.palette.boxShadow.main} !important`,
            borderRadius: pathname?.split("/")?.[1] === "admin" && "25px",
            background: pathname?.split("/")?.[1] === "admin" && "white",
            // padding: "0",
            // gap: "10px",
            justifyContent: "space-between",
            width: "100%",
            // padding: `${(width < 769 & width !== 0) ? "0px 0px" : "0px 40px"}`,
            padding: `${
              (width < 769) & (width !== 0)
                ? "0px 0px"
                : pathname?.split("/")?.[1] === "admin"
                ? "40px 40px"
                : "0px 0px"
            }`,
            // margin: "55px 50px 50px 50px",
          }}
        >
          {/* Header Section Starts */}

          {/* {location?.pathname?.includes("admin") ? (
            <Box sx={{ width: "100%", textAlign: "center" }}>
              <Link
                to={
                  job?.post_incognito
                    ? `/jobs/${job?.town?.country?.name?.replace(
                        /\s|\//g,
                        ""
                      )}/${job?.town?.name?.replace(
                        /\s|\//g,
                        ""
                      )}/${job?.title?.replace(/\s|\//g, "")}/${job?.job_id}`
                    : `/jobs/${job?.town?.country?.name?.replace(
                        /\s|\//g,
                        ""
                      )}/${job?.town?.name?.replace(
                        /\s|\//g,
                        ""
                      )}/${job?.company?.name?.replace(
                        /\s|\//g,
                        ""
                      )}/${job?.title?.replace(/\s|\//g, "")}/${job?.job_id}`
                }
                target={"_blank"}
                style={{
                  textDecoration: "none",
                }}
              >
                <Button
                  variant="contained"
                  color="redButton100"
                  sx={{
                    width: "150px",
                    height: "60px",
                    borderRadius: "0 0 25px 25px",
                    fontSize: "20px",
                    fontWeight: theme.typography.Bold,
                  }}
                >
                  learn more
                </Button>
              </Link>
            </Box>
          ) : (
            ""
          )} */}
          {/* Header Section Ends */}
          <Box
            sx={{
              display: "flex",
              // flexDirection: { xs: "column", tb769: "row" },
              flexDirection: exportAsCSV
                ? "row"
                : { xs: "column", tb769: "row" },
              // padding: { xs: "0 30px", lg: "0 35px", xl: "0 50px" },
              justifyContent: "space-between",
              width: "100%",
              gap: exportAsCSV ? "50px" : { xs: "", tb769: "50px" },
            }}
          >
            {/* left Body Starts */}
            {/* <Grid container> */}
            <Box
              item
              sx={{
                display: "flex",
                flexDirection: "column",
                // width: `${width < 769 ? "100%" : "60%"}`,
                flex: "1",
              }}
              gap={"30px"}
            >
              <Box
                sx={{
                  borderRadius: "25px",
                  boxShadow: `0px 5px 10px ${theme.palette.boxShadow.main} !important`,
                  // padding: "15px",
                  background: theme.palette.base.main,
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "flex-end",
                    // padding: "0px 40px",
                    // padding: `${width < 769 ? "0px 0px" : "0px 0px"}`,
                    padding: `0px`,
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                    }}
                  >
                    <Button
                      color={
                        job?.job_type === "Recruit"
                          ? "RecruitButton"
                          : "DirectButton"
                      }
                      variant="contained"
                      sx={{
                        height: "auto",
                        minWidth: "60px",
                        minHeight: "45px",
                        borderRadius: 0,
                        borderBottomLeftRadius: "10px",
                        padding: 0,
                        boxShadow: 0,
                        fontSize: 12,
                        fontWeight: theme.typography.Bold,
                        cursor: "default",
                        ":hover": {
                          boxShadow: 0,
                        },
                      }}
                    >
                      {job?.job_type}
                    </Button>
                    <Button
                      sx={{
                        height: "auto",
                        minWidth: "60px",
                        minHeight: "45px",
                        borderRadius: 0,
                        padding: 0,
                        fontSize: 12,
                        fontWeight: theme.typography.Bold,
                        background:
                          theme.manageTalent?.[renderColor(job?.stage?.name)]
                            ?.main,
                        color: "white",
                        cursor: "default",
                        ":hover": {
                          background:
                            theme.manageTalent?.[renderColor(job?.stage?.name)]
                              ?.main,
                        },
                      }}
                    >
                      {job?.stage?.name || "-"}
                    </Button>
                    <Button
                      ref={jobPromoteRef}
                      color={"jobAnnounceButton"}
                      variant="contained"
                      sx={{
                        height: "auto",
                        minWidth: "60px",
                        minHeight: "45px",
                        borderRadius: 0,
                        padding: 0,
                        fontSize: 12,
                        fontWeight: "normal",
                        ":hover": {
                          boxShadow: "none",
                        },
                      }}
                      onClick={() => setJobPromote(jobPromoteRef?.current)}
                    >
                      <Box
                        component="img"
                        sx={{
                          height: 30,
                          width: 30,
                          maxHeight: { xs: 30 },
                          maxWidth: { xs: 30 },
                          ":hover": {
                            boxShadow: "none",
                          },
                        }}
                        alt="job_volume"
                        src={job_volume}
                      />
                    </Button>
                    <Popover
                      id="dropdown-menu"
                      open={openJobPromote}
                      anchorEl={jobPromote}
                      onClose={() => setJobPromote(null)}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "center",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "center",
                      }}
                      style={{
                        zIndex: 1000000,
                      }}
                      sx={{
                        "& .MuiPopover-paper": {
                          width: "360px",
                          borderRadius: "25px !important",
                        },
                      }}
                    >
                      <JobPromoteCard
                        setJobPromote={setJobPromote}
                        jobID={job?.job_id}
                        job={job}
                        setShowLogin={setShowLogin}
                      />
                    </Popover>
                    <Button
                      color="playButton"
                      variant="contained"
                      sx={{
                        height: "auto",
                        minWidth: "60px",
                        minHeight: "45px",
                        borderRadius: 0,
                        borderTopRightRadius: "25px",
                        background:
                          job?.video_url === "No URL" &&
                          theme.palette.grayButton600.main,
                        padding: 0,
                        ":hover": {
                          boxShadow: "none",
                        },
                      }}
                      // disabled={job?.video_url === "No URL"}
                      onClick={() => {
                        if (job?.video_url !== "No URL") {
                          setVideoLink(job?.video_url);
                          setOpenReviewVideoDialog(true);
                        }
                      }}
                    >
                      <Box
                        component={"img"}
                        src={
                          // job?.video_url !== "No URL"
                          //   ? playButtonIconWhite
                          //   : playButtonIcon
                          playButtonIconWhite
                        }
                        sx={{
                          height: "14px",
                          width: "14px",
                        }}
                      />
                    </Button>
                  </Box>
                </Box>
                <Box sx={{}}>
                  <Box
                    sx={{
                      display: "flex",
                      gap: "15px",
                      alignItems: "end",
                      paddingX: "15px",
                    }}
                  >
                    {job?.post_incognito && !job?.shortlistedflag ? (
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "flex-start",
                          gap: "5px",
                        }}
                      >
                        <Box
                          component="img"
                          sx={{
                            height: { xs: 90, lg: 90, xl: 125 },
                            width: { xs: 90, lg: 90, xl: 125 },
                            borderRadius: "10px",
                            // border: 1,
                            // borderColor: "lightgray",
                          }}
                          alt="job_logo"
                          src={post_incognito}
                        />
                        <Tooltip disableInteractive>
                          <LightTooltip
                            title={i18n["popupBoxText.postIncognito"]}
                            placement="right"
                          >
                            <Typography
                              sx={{
                                mt: "2px",
                                cursor: "pointer",
                                height: "17px",
                                width: "17px",
                                borderRadius: "5px",
                                fontSize: "12px",
                                lineHeight: 0.1,
                                /* text-align: center; */
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                fontWeight: theme.typography.Bold,
                                boxShadow: "0px 2px 4px #00000029",
                                border: 1,
                              }}
                            >
                              i
                            </Typography>
                          </LightTooltip>
                        </Tooltip>
                      </Box>
                    ) : (
                      <Link
                        to={`/jobs/company/${job?.company_id}`}
                        target={"_blank"}
                        style={{
                          textDecoration: "none",
                          color: theme.palette.black,
                        }}
                      >
                        <Box
                          component="img"
                          sx={{
                            height: { xs: 90, lg: 90, xl: 125 },
                            width: { xs: 90, lg: 90, xl: 125 },
                            borderRadius: "10px",
                            // border: 1,
                            // borderColor: "lightgray",
                          }}
                          alt="job_logo"
                          src={
                            job?.profile_url !== "No URL"
                              ? job?.profile_url
                              : job_logo
                          }
                        />
                      </Link>
                    )}
                    <Box
                      sx={{
                        flexGrow: 1,
                        width: "100%",
                      }}
                    >
                      <Typography
                        sx={{
                          // fontWeight: theme.typography.Regular,
                          fontWeight: "Regular",
                          fontSize: { xs: "12px", lg: "16px", mlg: "16px" },
                          letterSpacing: "0.24px",
                          marginBottom: "5px",
                        }}
                      >
                        posted {convertDatetimeAgo(job?.created_at)} ago
                      </Typography>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: "7px",
                        }}
                      >
                        <Tooltip
                          disableInteractive
                          arrow
                          title={job?.title}
                          placement="top"
                        >
                          <Typography
                            sx={{
                              fontSize: {
                                xs: "18px",
                                lg: "22px",
                                mlg: "25px",
                                xl: "25px",
                              },
                              fontWeight: "bold",
                              textWrap: "wrap",
                            }}
                            noWrap={true}
                          >
                            {job?.title}
                          </Typography>
                        </Tooltip>
                        {job?.country_code && (
                          <CountryFlag
                            title="United States"
                            className="..."
                            style={{ width: 22, height: 20 }}
                          />
                        )}
                        <Box
                          component={"img"}
                          src={
                            isStar ||
                            document.cookie
                              ?.match(/\d+/g)
                              ?.map((item) => parseInt(item, 10))
                              ?.includes(parseInt(jobId, 10))
                              ? favouriteRed
                              : favouriteGrey
                          }
                          sx={{
                            height: "20px",
                            width: "20px",
                            cursor: "pointer",
                          }}
                          onClick={(event) =>
                            decodedToken === undefined
                              ? handleFavourite(event, job?.job_id)
                              : handleStar(job?.job_id)
                          }
                        />
                      </Box>
                    </Box>
                  </Box>

                  <Box sx={{ padding: "15px" }}>
                    <Box
                      sx={{
                        display: "flex",
                        flexWrap: "wrap",
                        width: "99%",
                        gap: 2,
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "start",
                          gap: "10px",
                        }}
                      >
                        <Box
                          component={"img"}
                          src={BlueCurrency}
                          sx={{
                            height: 16,
                            width: 15,
                            maxHeight: { xs: 16 },
                            maxWidth: { xs: 15 },
                          }}
                        />
                        <Typography
                          sx={{
                            fontWeight: "bold",
                            fontSize: "14px",
                          }}
                          noWrap
                        >
                          {job?.salary?.min === 0 ? (
                            <>
                              {job?.salary?.currency?.symbol}
                              {formatCurrencyWithCommas(job?.salary?.max)}{" "}
                              {job?.type === "freelance"
                                ? "per hour"
                                : "per month"}
                            </>
                          ) : job?.salary?.currency?.symbol ||
                            job?.salary?.min ||
                            job?.salary?.max ? (
                            <>
                              {job?.salary?.currency?.symbol}
                              {formatCurrencyWithCommas(
                                job?.salary?.min
                              )} to {job?.salary?.currency?.symbol}
                              {formatCurrencyWithCommas(job?.salary?.max)}{" "}
                              {job?.type === "freelance"
                                ? "per hour"
                                : "per month"}
                            </>
                          ) : (
                            "-"
                          )}
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "start",
                          gap: "10px",
                        }}
                      >
                        <Box
                          component={"img"}
                          src={redLocation}
                          sx={{
                            height: 16,
                            width: 15,
                            maxHeight: { xs: 16 },
                            maxWidth: { xs: 15 },
                          }}
                        />
                        <Typography
                          sx={{
                            fontWeight: "bold",
                            fontSize: "14px",
                          }}
                          noWrap
                        >
                          {job?.town?.name}, {job?.town?.country?.name}
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "start",
                          gap: "10px",
                        }}
                      >
                        <Box
                          component="img"
                          sx={{
                            height: 16,
                            width: 15,
                            maxHeight: { xs: 16 },
                            maxWidth: { xs: 15 },
                          }}
                          alt="experience"
                          src={experienceIcon}
                        />
                        <Typography
                          sx={{
                            fontWeight: "bold",
                            fontSize: "14px",
                          }}
                          noWrap
                        >
                          {job?.experience?.year_start} to{" "}
                          {job?.experience?.year_end} years
                        </Typography>
                      </Box>

                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "start",
                          gap: "10px",
                        }}
                      >
                        <Box
                          component={"img"}
                          src={calendar}
                          sx={{
                            height: 16,
                            width: 15,
                            maxHeight: { xs: 16 },
                            maxWidth: { xs: 15 },
                          }}
                        />

                        <Typography
                          sx={{
                            fontWeight: "bold",
                            fontSize: "14px",
                          }}
                          noWrap
                        >
                          {job?.job_start_date
                            ? dateConverterMonth(job?.job_start_date)
                            : "-"}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      padding: "15px",
                      // background: theme.palette.boxBackground,
                      background: theme.palette.base.main,
                    }}
                  >
                    <Box>
                      <Grid
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "8px",
                          marginBottom: "8px",
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: "12px",
                            height: "15px",
                            fontWeight: theme.typography.Bold,
                          }}
                        >
                          Work Type
                        </Typography>
                        <Box
                          sx={{
                            display: "flex",
                            flexWrap: "wrap",
                            gap: 1,
                          }}
                        >
                          {job?.type && (
                            <SmallButton
                              color={"nandos"}
                              height={25}
                              value={job?.type}
                              label={job?.type?.split(" ")[0]}
                              fontSize={12}
                            />
                          )}
                          {job?.work_setup && (
                            <SmallButton
                              color={"nandos"}
                              height={25}
                              value={job?.work_setup}
                              label={job?.work_setup}
                              fontSize={12}
                            />
                          )}
                        </Box>
                      </Grid>
                      <Grid
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "8px",
                          marginBottom: "8px",
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: "12px",
                            height: "15px",
                            fontWeight: theme.typography.Bold,
                          }}
                        >
                          Industry(ies)
                        </Typography>
                        <Box
                          sx={{
                            display: "flex",
                            flexWrap: "wrap",
                            gap: 1,
                          }}
                        >
                          {job?.industry_jobs?.map((item) => {
                            return (
                              <SmallButton
                                color={"tymeColor"}
                                height={25}
                                value={item?.industry?.name}
                                label={item?.industry?.name}
                                fontSize={12}
                              />
                            );
                          })}
                        </Box>
                      </Grid>
                      <Grid
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "8px",
                          marginBottom: "8px",
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: "12px",
                            height: "15px",
                            fontWeight: theme.typography.Bold,
                          }}
                        >
                          Skills
                        </Typography>
                        <Box
                          sx={{
                            display: "flex",
                            flexWrap: "wrap",
                            gap: 1,
                          }}
                        >
                          {job?.job_tags?.map((item) => {
                            return (
                              <SmallButton
                                color={"yoco"}
                                height={25}
                                value={item?.tag?.tag}
                                label={item?.tag?.tag}
                                fontSize={12}
                              />
                            );
                          })}
                        </Box>
                      </Grid>
                      <Grid
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "8px",
                          marginBottom: "8px",
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: "12px",
                            height: "15px",
                            fontWeight: theme.typography.Bold,
                          }}
                        >
                          Tools
                        </Typography>
                        <Box
                          sx={{
                            display: "flex",
                            flexWrap: "wrap",
                            gap: 1,
                          }}
                        >
                          {job?.job_tools?.map((item) => {
                            return (
                              <SmallButton
                                color={"checkers"}
                                height={25}
                                value={item?.tool?.name}
                                label={item?.tool?.name}
                                fontSize={12}
                              />
                            );
                          })}
                        </Box>
                      </Grid>
                      <Grid
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "8px",
                          marginBottom: "8px",
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: "12px",
                            height: "15px",
                            fontWeight: theme.typography.Bold,
                          }}
                        >
                          Personality
                        </Typography>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "start",
                            gap: "15px",
                          }}
                        >
                          <Box
                            component="img"
                            sx={{
                              height: {
                                xs: "90px",
                                lg: "90px",
                                mlg: "110px",
                                xl: "125px",
                              },
                              width: {
                                xs: "90px",
                                lg: "90px",
                                mlg: "110px",
                                xl: "125px",
                              },
                            }}
                            alt="job_exp"
                            src={
                              (job?.shadow?.name === "collaborator" &&
                                profile_collaborator) ||
                              (job?.shadow?.name === "challenger" &&
                                profile_challenger) ||
                              (job?.shadow?.name === "character" &&
                                profile_character) ||
                              (job?.shadow?.name === "contemplator" &&
                                profile_contemplator) ||
                              gray_profile_collaborator
                            }
                          />
                          <Box
                            component="img"
                            sx={{
                              height: {
                                xs: "90px",
                                lg: "90px",
                                mlg: "110px",
                                xl: "125px",
                              },
                              width: {
                                xs: "90px",
                                lg: "90px",
                                mlg: "110px",
                                xl: "125px",
                              },
                            }}
                            alt="job_exp"
                            src={
                              (job?.primary?.name === "collaborator" &&
                                profile_collaborator) ||
                              (job?.primary?.name === "challenger" &&
                                profile_challenger) ||
                              (job?.primary?.name === "character" &&
                                profile_character) ||
                              (job?.primary?.name === "contemplator" &&
                                profile_contemplator) ||
                              gray_profile_challenger
                            }
                          />
                          <MUIRadialChart
                            backgroundRingColor="darkGray"
                            size={calculateHeight()}
                            chartName={label4}
                            color={"grit_score_new"}
                            value={Number(job?.grit_score)}
                            countFontSize={14}
                            labelFontSize={12}
                            max={100}
                          />
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            flexWrap: "wrap",
                            gap: 1,
                          }}
                        >
                          {job?.job_traits?.map((item) => {
                            return (
                              <SmallButton
                                color={"precium"}
                                height={25}
                                value={item?.trait?.name}
                                label={item?.trait?.name}
                                fontSize={12}
                              />
                            );
                          })}
                        </Box>
                      </Grid>
                    </Box>
                  </Box>
                </Box>
                <Box sx={{ width: "100%" }}>
                  <Link
                    to={`/jobs`}
                    style={{
                      textDecoration: "none",
                      color: theme.palette.black,
                    }}
                  >
                    <Button
                      variant="contained"
                      color="submitButton"
                      sx={{
                        width: "33.33%",
                        height: "60px",
                        borderRadius: "0 0 0 25px",
                        fontSize: "14px",
                        fontWeight: theme?.typography?.Bold,
                        color: "black",
                      }}
                      onClick={() => {}}
                    >
                      view all jobs
                    </Button>
                  </Link>
                  <Button
                    variant="contained"
                    color="tymeColor"
                    sx={{
                      width: "33.33%",
                      height: "60px",
                      borderRadius: "0 0 0 0px",
                      fontSize: "14px",
                      fontWeight: theme?.typography?.Bold,
                    }}
                    onClick={() => {}}
                    startIcon={
                      <Box
                        component={"img"}
                        sx={{
                          height: 25,
                          width: 25,
                        }}
                        src={viewjobsIcon}
                      />
                    }
                  >
                    view similar jobs
                  </Button>
                  <Button
                    variant="contained"
                    color="nandos"
                    sx={{
                      width: "33.33%",
                      height: "60px",
                      borderRadius: "0 0 25px 0",
                      fontSize: "14px",
                      fontWeight: theme?.typography?.Bold,
                    }}
                    disabled={
                      job?.job_status?.job_status_id === 4 ? true : false
                    }
                    onClick={handleClick}
                    startIcon={
                      <Box
                        component={"img"}
                        sx={{
                          height: "20px",
                          width: "24px",
                        }}
                        src={applyIcon}
                      />
                    }
                  >
                    {job?.applidflag === true ? "applied" : "apply"}
                  </Button>
                </Box>
              </Box>

              <Box
                sx={{
                  borderRadius: "25px",
                  boxShadow: `0px 5px 10px ${theme.palette.boxShadow.main} !important`,
                  padding: "15px",
                  background: theme?.palette?.referenceBtn?.contrastText,
                  marginBottom: { xs: "30px", tb769: "0px" },
                }}
              >
                <Grid
                  sx={{
                    mb: 1.5,
                    mt: 1.5,
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: theme.typography.Bold,

                      fontSize: "14px",
                      letterSpacing: "0",
                      marginBottom: "8px",
                    }}
                  >
                    How you'll role
                  </Typography>
                  <Typography
                    sx={{
                      fontWeight: theme.typography.Regular,
                      fontSize: 14,
                      overflow: "hidden",
                      // display: "-webkit-box",
                      letterSpacing: "0.25px",
                      lineHeight: "1.6",
                    }}
                    color={theme.palette.black100}
                    gutterBottom={true}
                    dangerouslySetInnerHTML={createMarkup(job?.description)}
                  />
                </Grid>

                <Grid
                  sx={{
                    mb: 1.5,
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: theme.typography.Bold,

                      fontSize: "14px",
                      letterSpacing: "0",
                      marginBottom: "8px",
                    }}
                  >
                    What you'll do
                  </Typography>

                  <Typography
                    sx={{
                      fontWeight: theme.typography.Regular,
                      fontSize: 14,
                      overflow: "hidden",
                      // display: "-webkit-box",
                      letterSpacing: "0.25px",
                      lineHeight: "1.6",
                    }}
                    color={theme.palette.black100}
                    gutterBottom={true}
                    dangerouslySetInnerHTML={createMarkup(
                      job?.role_responsibilty
                    )}
                  />
                </Grid>

                <Grid
                  sx={{
                    mb: 1,
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: theme.typography.Bold,
                      fontSize: 14,
                      letterSpacing: "0",
                      marginBottom: "8px",
                    }}
                  >
                    What you'll need
                  </Typography>
                  <Typography
                    sx={{
                      fontWeight: theme.typography.Regular,
                      fontSize: "14px",
                      overflow: "hidden",
                      // display: "-webkit-box",
                      letterSpacing: "0.25px",
                      lineHeight: "1.6",
                    }}
                    color={theme.palette.black100}
                    gutterBottom={true}
                    dangerouslySetInnerHTML={createMarkup(
                      job?.role_requirements
                    )}
                  />
                </Grid>
                {job?.job_notes && (
                  <Grid
                    sx={{
                      // mb: 1,
                      fontWeight: theme.typography.Regular,
                      fontSize: "14px",
                      letterSpacing: "0",
                      marginBottom: "8px",
                    }}
                  >
                    <Typography
                      sx={{
                        fontWeight: theme.typography.Bold,
                        fontSize: 14,
                        letterSpacing: "0",
                        marginBottom: "8px",
                      }}
                    >
                      Job Notes
                    </Typography>
                    <Typography
                      sx={{
                        fontWeight: theme.typography.Regular,
                        fontSize: "14px",
                        overflow: "hidden",
                        // display: "-webkit-box",
                        letterSpacing: "0.25px",
                        lineHeight: "1.6",
                      }}
                      color={theme.palette.black100}
                      gutterBottom={true}
                      dangerouslySetInnerHTML={createMarkup(job?.job_notes)}
                    />
                  </Grid>
                )}
              </Box>
              {/* Job Question */}
              {/* {!location?.pathname?.includes("admin") && ( */}
              <Grid
                sx={{
                  borderRadius: "25px",
                  // background: theme.palette.boxBackground,
                  background: theme.palette.base.main,
                  boxShadow: "0px 5px 10px #00000029",
                  display: { xs: "none", tablet: "none", tb769: "block" },
                }}
              >
                <Button
                  variant="contained"
                  color={job?.job_type === "Direct" ? "checkers" : "yoco"}
                  sx={{
                    borderRadius: "25px 0 10px 0",
                    fontSize: "14px",
                    fontWeight: "bold",
                    minHeight:"45px",
                    maxHeight:"45px",
                    maxWidth: "150px",
                    minWidth:"150px",
                    ":hover": {
                      boxShadow: 0,
                    },
                    cursor: "default",
                  }}
                >
                  Job questions
                </Button>
                <QuestionStepper
                  color={job?.job_type === "Direct" ? "checkers" : "yoco"}
                  questionList={job?.job_questions}
                />
              </Grid>

              {/* )} */}
              {location?.pathname?.includes("admin") && (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "flex-end",
                    height: "100%",
                  }}
                >
                  <QRCode
                    size={256}
                    style={{
                      height: "125px",
                      maxWidth: "125px",
                      width: "125px",
                    }}
                    value={
                      job?.post_incognito
                        ? `${
                            window.location.origin
                          }/jobs/${job?.town?.country?.name?.replace(
                            /\s|\//g,
                            ""
                          )}/${job?.town?.name?.replace(
                            /\s|\//g,
                            ""
                          )}/${job?.title?.replace(/\s|\//g, "")}/${
                            job?.job_id
                          }`
                        : `${
                            window.location.origin
                          }/jobs/${job?.town?.country?.name?.replace(
                            /\s|\//g,
                            ""
                          )}/${job?.town?.name?.replace(
                            /\s|\//g,
                            ""
                          )}/${job?.company?.name?.replace(
                            /\s|\//g,
                            ""
                          )}/${job?.title?.replace(/\s|\//g, "")}/${
                            job?.job_id
                          }`
                    }
                    viewBox={`0 0 256 256`}
                  />
                </Box>
              )}
            </Box>
            {/* left section ends */}

            <Box
              item
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "30px",
                // pt: "85px",
                // pt: `${
                //   width < 769
                //     ? "0px"
                //     : location?.pathname?.includes("admin")
                //     ? "0px"
                //     : "85px"
                // }`,
                // width: "35%",
                // width: `${width < 769 ? "100%" : "35%"}`,
                width: `360px`,
                maxWidth: `360px`,
              }}
            >
              {/* JOB INFO SECTION */}
              <Box
                sx={{
                  borderRadius: "25px",
                  background: theme.palette.base.main,
                  boxShadow: `0px 5px 10px ${theme.palette.boxShadow.main} !important`,
                  maxWidth: "360px",
                  width: "360px",
                }}
              >
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Button
                    variant="contained"
                    color={job?.job_type === "Direct" ? "checkers" : "yoco"}
                    sx={{
                      borderRadius: "25px 0 10px 0",
                      fontSize: "14px",
                      fontWeight: "bold",
                      maxWidth: "150px",
                      minWidth: "150px",
                      height: "45px",
                      minHeight:"45px",
                      maxHeight:"45px",
                      ":hover": {
                        boxShadow: 0,
                      },
                      cursor: "default",
                    }}
                  >
                    Job Info{" "}
                    <LightTooltip
                      placement="right"
                      title={
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "10px",
                          }}
                        >
                          {job?.job_type === "Recruit" ? (
                            <p>
                              You'll be dealing directly via the Crayon team and
                              not with the cient directly until appropriate.
                            </p>
                          ) : (
                            <p>
                              You'll be dealing directly with the client and not
                              via the Crayon team.
                            </p>
                          )}
                        </Box>
                      }
                    >
                      <Typography
                        sx={{
                          marginLeft: "5px",
                          padding: "4px",
                          height: "8px",
                          width: "8px",
                          borderRadius: "50%",
                          fontSize: "12px",
                          lineHeight: 0,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          fontWeight: 700,
                          background: "transparent",
                          color: "white",
                          border: "1px solid white",
                        }}
                      >
                        i
                      </Typography>
                    </LightTooltip>
                  </Button>
                  <Box>
                    <Button
                      color={"precium"}
                      variant="contained"
                      disabled={!job?.is_video_require}
                      sx={{
                        height: "auto",
                        minWidth: "60px",
                        minHeight: "45px",
                        borderRadius: "0 0 0 10px",
                        padding: 0,
                        fontSize: 12,
                        fontWeight: theme.typography.Bold,
                        // cursor: "default",
                      }}
                    >
                      <Box
                        component="img"
                        sx={{
                          height: 20,
                          width: 20,
                          maxHeight: { xs: 20 },
                          maxWidth: { xs: 20 },
                          ":hover": {
                            boxShadow: "none",
                          },
                        }}
                        alt=""
                        src={Video_PlayDot}
                      />
                    </Button>
                    <Button
                      ref={jobPromoteRef}
                      color="peachPayment"
                      variant="contained"
                      disabled={job?.job_questions?.length < 0}
                      sx={{
                        height: "auto",
                        minWidth: "60px",
                        minHeight: "45px",
                        borderRadius: "0 25px 0 0",
                        padding: 0,
                        fontSize: 12,
                        fontWeight: theme.typography.Bold,
                        ":hover": {
                          boxShadow: "none",
                        },
                      }}
                      onClick={handleClick}
                    >
                      {/* <Box
                        component="img"
                        sx={{
                          height: 30,
                          width: 30,
                          maxHeight: { xs: 30 },
                          maxWidth: { xs: 30 },
                          ":hover": {
                            boxShadow: "none",
                          },
                        }}
                        alt="job_volume"
                        src={job_volume}
                      /> */}
                      Q&A
                    </Button>
                  </Box>
                </Box>

                <Box
                  padding={"20px"}
                  sx={{
                    display: "flex",
                    flexWrap: "wrap",
                  }}
                >
                  <Box
                    width={"33.33%"}
                    display={"flex"}
                    alignItems={"center"}
                    flexDirection={"column"}
                  >
                    <Box
                      component="img"
                      sx={{
                        height: 50,
                        width: 50,
                      }}
                      alt="own_vehicle"
                      src={job?.own_transport ? own_vehicle : gray_own_vehicle}
                    />
                    <Typography
                      sx={{
                        fontSize: "12px",
                        fontWeight: "medium",
                      }}
                      paragraph
                    >
                      Own Transport
                    </Typography>
                  </Box>
                  <Box
                    width={"33.33%"}
                    display={"flex"}
                    alignItems={"center"}
                    flexDirection={"column"}
                  >
                    <Box
                      component="img"
                      sx={{
                        height: 50,
                        width: 50,
                      }}
                      alt="own_vehicle"
                      src={
                        job?.own_equipment ? own_equipment : gray_own_equipment
                      }
                    />
                    <Typography
                      sx={{
                        fontSize: "12px",
                        fontWeight: "medium",
                      }}
                      paragraph
                    >
                      own equipment
                    </Typography>
                  </Box>
                  <Box
                    width={"33.33%"}
                    display={"flex"}
                    alignItems={"center"}
                    flexDirection={"column"}
                  >
                    <Box
                      component="img"
                      sx={{
                        height: 50,
                        width: 50,
                      }}
                      alt="own_vehicle"
                      src={
                        job?.internet_access ? own_internet : gray_own_internet
                      }
                    />
                    <Typography
                      sx={{
                        fontSize: "12px",
                        fontWeight: "medium",
                      }}
                      paragraph
                    >
                      home setup
                    </Typography>
                  </Box>
                  <Box
                    width={"33.33%"}
                    display={"flex"}
                    flexDirection={"column"}
                    alignItems={"center"}
                  >
                    <Box
                      component="img"
                      sx={{
                        height: 50,
                        width: 50,
                      }}
                      alt="education_type"
                      src={education_type}
                    />
                    <Typography
                      sx={{
                        fontSize: "12px",
                        fontWeight: "medium",
                        textAlign: "center",
                      }}
                      paragraph
                    >
                      {job?.highest_qualification?.description}
                    </Typography>
                  </Box>

                  <Box
                    width={"33.33%"}
                    display={"flex"}
                    alignItems={"center"}
                    flexDirection={"column"}
                    justifyContent={"start"}
                  >
                    <Typography
                      sx={{
                        fontSize: "35px",
                        fontWeight: 700,
                        height: "50px",
                      }}
                    >
                      {job?.salary?.currency?.symbol}
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "12px",
                        fontWeight: "medium",
                      }}
                      paragraph
                    >
                      currency
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      width: "33.33%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <MUIRadialChart
                      backgroundRingColor="darkGray"
                      size={Number(`${width < 768 ? 80 : 100}`)}
                      chartName={label5}
                      color={"contractDuration"}
                      value={job?.contract_duration || 0}
                      formattedValue={`${job?.contract_duration || 0} months`}
                      // countFontSize={14}
                      // labelFontSize={12}
                      countFontSize={Number(`${width < 768 ? 12 : 14}`)}
                      labelFontSize={Number(`${width < 768 ? 10 : 12}`)}
                      max={100}
                    />
                  </Box>
                </Box>
              </Box>
              {/* THIS IS A CRAYON DIRECT JOB */}
              {!location?.pathname?.includes("admin") && (
                <Box
                  sx={{
                    // background: theme.palette.boxBackground,
                    background: theme.palette.base.main,
                    borderRadius: "25px",
                    overflow: "hidden",
                    position: "relative",
                    height: openMatchBox ? "auto" : "250px",
                    maxWidth: "360px",
                    width: "360px",
                    boxShadow: `0px 5px 10px ${theme.palette.boxShadow.main} !important`,
                  }}
                >
                  {openMatchBox ? (
                    <JobDetailMatchBox
                      setOpenMatchMeBox={setOpenMatchMeBox}
                      match={match}
                      matchedIsLoading={matchedIsLoading}
                      setMatchedIsLoading={setMatchedIsLoading}
                      job={job}
                      handleApplyClick={handleClick}
                    />
                  ) : (
                    <>
                      <Button
                        variant="contained"
                        color={job?.job_type === "Direct" ? "checkers" : "yoco"}
                        sx={{
                          height: "45px",
                          borderRadius: "25px 0 10px 0",
                          gap: 1,
                          fontSize: "14px",
                          minHeight:"45px",
                          maxHeight:"45px",
                          maxWidth: "150px",
                          minWidth:"150px",
                          fontWeight: "bold",
                          ":hover": {
                            boxShadow: 0,
                          },
                          cursor: "default",
                        }}
                      >
                        {/* {i18n["jobCard.thisisa"]} {job?.job_type}{" "}
                        {i18n["jobCard.job"]} */}
                        job stats
                      </Button>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          gap: 3,
                          alignItems: "center",
                          height: "145px",
                        }}
                      >
                        <MUIRadialChart
                          backgroundRingColor="darkGray"
                          size={Number(`${width < 768 ? 80 : 100}`)}
                          chartName={label2}
                          value={Number(job?.TotalUserShortlisted)}
                          max={100}
                          color={"employerShortListed"}
                          countFontSize={Number(`${width < 768 ? 14 : 16}`)}
                          labelFontSize={Number(`${width < 768 ? 12 : 14}`)}
                        />
                        <MUIRadialChart
                          backgroundRingColor="darkGray"
                          size={Number(`${width < 768 ? 80 : 100}`)}
                          chartName={label1}
                          value={Number(job?.TotalUserCount)}
                          max={500}
                          color={"AppliedNew"}
                          countFontSize={Number(`${width < 768 ? 14 : 16}`)}
                          labelFontSize={Number(`${width < 768 ? 12 : 14}`)}
                        />
                        <MUIRadialChart
                          backgroundRingColor="darkGray"
                          size={Number(`${width < 768 ? 80 : 100}`)}
                          chartName={label3}
                          value={Number(job?.TotalUserInterviewed)}
                          max={50}
                          color={"interview"}
                          countFontSize={Number(`${width < 768 ? 14 : 16}`)}
                          labelFontSize={Number(`${width < 768 ? 12 : 14}`)}
                        />
                      </Box>
                    </>
                  )}
                  {!openMatchBox && (
                    <Grid
                      container
                      display={"flex"}
                      flexWrap={"nowrap"}
                      height={"60px"}
                      className="sliderClass"
                    >
                      <Grid sx={{ width: "100%", padding: 0 }}>
                        <Button
                          sx={{
                            boxShadow: 0,
                            fontWeight: theme.typography.Bold,
                            fontSize: "14px",
                            width: "100%",
                            borderRadius: 0,
                            height: "100%",
                          }}
                          onClick={handleOpenMatchBox}
                          color="yoco"
                          variant="contained"
                          startIcon={
                            <Box
                              component={"img"}
                              sx={{
                                height: "20px",
                                width: "26px",
                              }}
                              src={matchMeIcon}
                            />
                          }
                        >
                          {i18n["jobCard.matchnow"]}
                        </Button>
                      </Grid>
                    </Grid>
                  )}
                </Box>
              )}

              {location?.pathname?.includes("admin") ? (
                <>
                  <Grid
                    sx={{
                      // background: theme.palette.boxBackground,
                      background: theme.palette.base.main,
                      boxShadow: `0px 5px 10px ${theme.palette.boxShadow.main} !important`,
                      paddingBottom: 0,
                      borderRadius: "25px",
                      position: "relative",
                      // minHeight: "380px",
                      minHeight: "564px",
                      maxWidth: "360px",
                      width: "360px",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <Box
                      width={"100%"}
                      textAlign={"end"}
                      sx={{
                        display: "flex",
                        justifyContent: "end",
                        // marginBottom: "6px",
                        position: "absolute",
                        right: 0,
                        top: 0,
                      }}
                    >
                      <Box
                        sx={{
                          height: "45px",
                          color: "white",
                          minWidth: "60px",
                          display: "flex",
                          fontSize: "12px",
                          maxWidth: "60px",
                          justifyContent: "center",
                          borderRadius: "0 0 0 10px",
                          paddingX: "0px",
                          fontWeight: theme.typography.Bold,
                          alignItems: "center",
                          background: theme.palette.precium.main,
                        }}
                      >
                        {job?.company?.company_type
                          ? job?.company?.company_type
                          : "-"}
                      </Box>
                      <Box
                        sx={{
                          height: "45px",
                          color: "white",
                          minWidth: "60px",
                          display: "flex",
                          paddingX: "0px",
                          fontSize: "12px",
                          maxWidth: "60px",
                          justifyContent: "center",
                          alignItems: "center",
                          fontWeight: theme.typography.Bold,
                          background: theme.palette.peachPayment.main,
                        }}
                      >
                        {job?.company?.company_size
                          ? job?.company?.company_size
                          : "-"}
                      </Box>
                      <Button
                        color="playButton"
                        variant="contained"
                        sx={{
                          height: "auto",
                          minWidth: "60px",
                          minHeight: "45px",
                          borderRadius: "0 25px 0 0px",
                          padding: 0,
                          background:
                            job?.company_video_url === "No URL" &&
                            theme.palette.grayButton600.main,
                          ":hover": {
                            boxShadow: "none",
                          },
                        }}
                        // disabled={job?.company_video_url === "No URL"}
                        onClick={() => {
                          if (job?.company_video_url !== "No URL") {
                            setVideoLink(job?.company_video_url);
                            setOpenReviewVideoDialog(true);
                          }
                        }}
                      >
                        <Box
                          component={"img"}
                          src={
                            // job?.company_video_url !== "No URL"
                            //   ? playButtonIconWhite
                            //   : playButtonIcon
                            playButtonIconWhite
                          }
                          sx={{
                            height: "14px",
                            width: "14px",
                          }}
                        />
                      </Button>
                    </Box>

                    <Box
                      sx={{
                        padding: 2,
                        display: "flex",
                        flexDirection: "column",
                        gap: "10px",
                        flex: "1",
                        paddingBottom: 0,
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          gap: 2,
                          flexDirection: "column",
                          justifyContent: "space-between",
                        }}
                      >
                        {job?.post_incognito && !job?.shortlistedflag ? (
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "flex-start",
                              gap: "5px",
                            }}
                          >
                            <Box
                              component="img"
                              sx={{
                                height: "125px",
                                width: "125px",
                                borderRadius: "10px",
                              }}
                              //   alt="job_logo"
                              src={post_incognito}
                            />
                            <Tooltip disableInteractive>
                              <LightTooltip
                                title={i18n["popupBoxText.postIncognito"]}
                                placement="right"
                              >
                                <Typography
                                  sx={{
                                    mt: "2px",
                                    cursor: "pointer",
                                    height: "17px",
                                    width: "17px",
                                    borderRadius: "5px",
                                    fontSize: "12px",
                                    lineHeight: 0.1,
                                    /* text-align: center; */
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    fontWeight: theme.typography.Bold,
                                    boxShadow: "0px 2px 4px #00000029",
                                    border: 1,
                                  }}
                                >
                                  i
                                </Typography>
                              </LightTooltip>
                            </Tooltip>
                          </Box>
                        ) : (
                          <Link
                            to={`/jobs/company/${job?.company_id}`}
                            target={"_blank"}
                            style={{
                              textDecoration: "none",
                              color: theme.palette.black,
                            }}
                          >
                            <Box
                              component="img"
                              sx={{
                                height: "125px",
                                width: "125px",
                                borderRadius: "10px",
                              }}
                              //   alt="job_logo"
                              src={
                                job?.profile_url !== "No URL"
                                  ? job?.profile_url
                                  : job_logo
                              }
                            />
                          </Link>
                        )}
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "space-between",
                            width: "100%",
                            paddingTop: "10px",
                            paddingBottom: "10px",
                            gap: "10px",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "start",
                              height: "12px",
                              gap: 1,
                            }}
                          >
                            <Box
                              component="img"
                              sx={{
                                height: 18,
                                width: 18,
                                maxHeight: { xs: 28 },
                                maxWidth: { xs: 28 },
                              }}
                              alt="company"
                              src={CompanyLogo}
                            />
                            {job?.post_incognito && !job?.shortlistedflag ? (
                              <Typography
                                sx={{
                                  fontWeight: "bold",
                                  fontSize: "20px",
                                  letterSpacing: "0.36px",
                                  mt: 0.5,
                                  // width: { xs: "60%", lg: "70%", xl: "80%" },
                                  textOverflow: "ellipsis",
                                  overflow: "hidden",
                                }}
                                noWrap={true}
                              >
                                {"incognito"}
                              </Typography>
                            ) : (
                              <Link
                                to={`/jobs/company/${job?.company_id}`}
                                target={"_blank"}
                                style={{
                                  textDecoration: "none",
                                  color: theme.palette.black,
                                  width: "90%",
                                }}
                              >
                                <Typography
                                  sx={{
                                    fontWeight: theme?.typography?.Bold,
                                    fontSize: "20px",
                                    letterSpacing: "0.36px",
                                    mt: 0.5,
                                    // width: { xs: "60%", lg: "70%", xl: "80%" },
                                    textOverflow: "ellipsis",
                                    overflow: "hidden",
                                  }}
                                  noWrap={true}
                                >
                                  {job?.company?.name
                                    ? job?.company?.name
                                    : "-"}
                                </Typography>
                              </Link>
                            )}
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "start",
                              height: "12px",
                              gap: "10px",
                            }}
                          >
                            <Box
                              component={"img"}
                              src={redLocation}
                              sx={{
                                height: 16,
                                width: 16,
                                maxHeight: { xs: 16 },
                                maxWidth: { xs: 16 },
                                marginLeft: "-2px",
                              }}
                            />
                            <Typography
                              sx={{
                                fontWeight: theme?.typography?.Bold,
                                fontSize: "14px",
                                letterSpacing: "0px",
                                //   opacity: 0.8,
                                mt: 0.5,
                                width: "90%",
                                textOverflow: "ellipsis",
                                overflow: "hidden",
                              }}
                              noWrap
                            >
                              {job?.company?.town?.name || "-"}
                              {job?.company?.town?.name &&
                                `, ${job?.company?.town?.country?.name} `}
                            </Typography>
                          </Box>

                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "start",
                              height: "12px",
                              gap: "10px",
                            }}
                          >
                            <Box
                              component="img"
                              sx={{
                                height: 18,
                                width: 18,
                                maxHeight: { xs: 28 },
                                maxWidth: { xs: 28 },
                              }}
                              alt="company link"
                              src={CompanyLink}
                            />

                            <Typography
                              sx={{
                                fontWeight: theme?.typography?.Bold,
                                fontSize: "14px",
                                letterSpacing: "0px",
                                mt: 0.5,
                                width: "90%",
                                textOverflow: "ellipsis",
                                overflow: "hidden",
                              }}
                              noWrap
                            >
                              {job?.company?.website !== "anonymous" ? (
                                <Tooltip
                                  disableInteractive
                                  title={job?.company?.website}
                                  placement="top"
                                >
                                  <Link
                                    style={{
                                      textDecoration: "none",
                                      color: "black",
                                    }}
                                    target="_blank"
                                    to={job?.company?.website} // Removed the optional chaining operator (?.)
                                  >
                                    {job?.company?.website}
                                  </Link>
                                </Tooltip>
                              ) : job?.post_incognito ? (
                                <span
                                  style={{
                                    textDecoration: "none",
                                    color: "black",
                                  }}
                                >
                                  {"incognito"}
                                </span>
                              ) : (
                                "-"
                              )}
                            </Typography>
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "start",
                              height: "12px",
                              gap: 1,
                            }}
                          >
                            <Box
                              component="img"
                              sx={{
                                height: 18,
                                width: 18,
                                maxHeight: { xs: 28 },
                                maxWidth: { xs: 28 },
                              }}
                              alt="calendar"
                              src={CalendarIcon}
                            />
                            <Tooltip
                              disableInteractive
                              title={
                                job?.post_incognito && !job?.shortlistedflag
                                  ? "incognito"
                                  : dateConverterMonth(job?.company?.created_at)
                              }
                              placement="top"
                            >
                              <Typography
                                sx={{
                                  fontWeight: theme?.typography?.Bold,
                                  fontSize: "14px",
                                  letterSpacing: "0px",
                                  mt: 0.5,
                                  width: "90%",
                                  textOverflow: "ellipsis",
                                  overflow: "hidden",
                                }}
                                noWrap
                              >
                                {/* Date joined: */}
                                {job?.post_incognito && !job?.shortlistedflag
                                  ? "incognito"
                                  : job?.company?.created_at
                                  ? dateConverterMonth(job?.company?.created_at)
                                  : "-"}
                              </Typography>
                            </Tooltip>
                          </Box>
                        </Box>
                      </Box>
                      <Slider2
                        type={"listArray"}
                        items={job?.company?.employer_industries}
                        color={"tymeColor"}
                        hideTagsAfter={2}
                      />
                      <Grid sx={{}}>
                        <Typography
                          sx={{
                            fontWeight: "Bold",
                            fontSize: 12,
                            letterSpacing: "0.75px",
                          }}
                        >
                          Company bio
                        </Typography>
                        <Typography
                          ref={descriptionRef}
                          sx={{
                            fontWeight: "Medium",
                            fontSize: 12,
                            letterSpacing: "0px",
                            lineHeight: "1.6",
                            overflow: viewMoreExpanded ? "scroll" : "hidden",
                            display: "-webkit-box",
                            WebkitBoxOrient: "vertical",
                            WebkitLineClamp:
                              viewMoreExpanded || exportAsCSV ? "none" : 4,
                            wordBreak: "break-word",
                            maxHeight:
                              viewMoreExpanded || exportAsCSV
                                ? "none"
                                : "calc(4 * 1.6em)", // 4 lines times line height
                          }}
                        >
                          {job?.company?.description}
                        </Typography>
                        {showMoreButton && !exportAsCSV && (
                          <Button
                            sx={{
                              color: "black",
                              padding: 0,
                              height: "30px",
                            }}
                            startIcon={
                              <Box
                                component="img"
                                sx={{
                                  height: 20,
                                  width: 20,
                                  cursor: "pointer",
                                }}
                                alt="crayon logo"
                                src={viewMoreExpanded ? upArrow : downArrow}
                              />
                            }
                            onClick={handleViewMore}
                          >
                            {viewMoreExpanded ? "less" : "more"}
                          </Button>
                        )}
                      </Grid>
                    </Box>
                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                      <Box sx={{ paddingLeft: 2 }}>
                        <Slider2
                          type={"culture"}
                          items={job?.company?.employer_cultures}
                          color={"precium"}
                          hideTagsAfter={2}
                        />
                      </Box>
                      <Box
                        sx={{
                          width: "100%",
                          height: "60px",
                          margin: "auto",
                          marginTop: "10px",
                        }}
                      >
                        <Button
                          disabled={
                            job?.post_incognito && !job?.shortlistedflag
                          }
                          variant="contained"
                          color="tymeColor"
                          sx={{
                            borderRadius: "0 0 0 25px",
                            fontSize: "16px",
                            fontWeight: "bold",
                            height: "100%",
                            width: "50%",
                            ":hover": {
                              boxShadow: 0,
                            },
                          }}
                          startIcon={
                            <Box
                              component={"img"}
                              sx={{
                                height: 20,
                                width: 20,
                              }}
                              src={viewjobsIcon}
                            />
                          }
                          onClick={() =>
                            handleViewJobs(job?.company?.company_id)
                          }
                        >
                          view biz jobs
                        </Button>
                        <Button
                          disabled={
                            job?.post_incognito && !job?.shortlistedflag
                          }
                          variant="contained"
                          color="nandos"
                          sx={{
                            borderRadius: "0 0 25px 0 ",
                            fontSize: "16px",
                            fontWeight: "bold",
                            height: "100%",
                            width: "50%",
                            ":hover": {
                              boxShadow: 0,
                            },
                          }}
                          startIcon={
                            <Box
                              component={"img"}
                              sx={{
                                height: 20,
                                width: 20,
                              }}
                              src={FollowIcon}
                            />
                          }
                          onClick={() => followComp(job?.company?.company_id)}
                        >
                          {companyFollowed ? "following" : "follow"}
                        </Button>
                      </Box>
                    </Box>
                  </Grid>
                </>
              ) : (
                <JobDetailCompanyComponent
                  isHorizontalFlipped={isHorizontalFlipped}
                  job={job}
                  theme={theme}
                  setVideoLink={setVideoLink}
                  setOpenReviewVideoDialog={setOpenReviewVideoDialog}
                  descriptionRef={descriptionRef}
                  viewMoreExpanded={viewMoreExpanded}
                  exportAsCSV={exportAsCSV}
                  showMoreButton={showMoreButton}
                  handleViewMore={handleViewMore}
                  handleViewJobs={handleViewJobs}
                  followComp={followComp}
                  companyFollowed={companyFollowed}
                  setisFlipped={setIsHorizontalFlipped}
                />
              )}
              <Grid
                sx={{
                  borderRadius: "25px",
                  background: theme.palette.base.main,
                  boxShadow: "0px 5px 10px #00000029",
                  display: { xs: "block", tablet: "block", tb769: "none" },
                }}
              >
                <Button
                  variant="contained"
                  color={job?.job_type === "Direct" ? "checkers" : "yoco"}
                  sx={{
                    borderRadius: "25px 0 10px 0",
                    fontSize: "14px",
                    minHeight:"45px",
                    maxHeight:"45px",
                    maxWidth: "150px",
                    minWidth:"150px",
                    fontWeight: "bold",
                    ":hover": {
                      boxShadow: 0,
                    },
                    cursor: "default",
                  }}
                >
                  Job questions
                </Button>
                <QuestionStepper
                  color={job?.job_type === "Direct" ? "checkers" : "yoco"}
                  questionList={job?.job_questions}
                />
              </Grid>
              <Box
                sx={{
                  borderRadius: "25px",
                  boxShadow: `0px 5px 10px ${theme.palette.boxShadow.main} !important`,
                  background: theme.palette.base.main,
                  maxWidth: "360px",
                  width: "360px",
                }}
              >
                <Button
                  variant="contained"
                  color={job?.job_type === "Direct" ? "checkers" : "yoco"}
                  sx={{
                    minWidth: "150px",
                    maxWidth:"150px",
                    minHeight:"45px",
                    maxHeight:"45px",
                    fontSize: "14px",
                    fontWeight: "bold",
                    borderRadius: "25px 0 10px 0",
                    ":hover": {
                      boxShadow: 0,
                    },
                    cursor: "default",
                  }}
                >
                  Next steps
                </Button>

                <VerticalStepper
                  color={job?.job_type === "Direct" ? "checkers" : "yoco"}
                />
                <Box>
                  <Button
                    variant="contained"
                    color="nandos"
                    sx={{
                      width: "100%",
                      height: "60px",
                      borderRadius: "0 0 25px 25px",
                      fontSize: "14px",
                      fontWeight: theme?.typography?.Bold,
                    }}
                    disabled={
                      job?.job_status?.job_status_id === 4 ? true : false
                    }
                    onClick={handleClick}
                    startIcon={
                      <Box
                        component={"img"}
                        sx={{
                          height: "20px",
                          width: "24px",
                        }}
                        src={applyIcon}
                      />
                    }
                  >
                    {job?.applidflag === true ? "applied" : "apply"}
                  </Button>
                </Box>
              </Box>

              {location?.pathname?.includes("admin") && (
                <Box sx={{ width: "100%", textAlign: "end" }}>
                  <Box
                    component={"img"}
                    src={crayonLogo}
                    sx={{ width: "214px", height: "55px" }}
                  />
                </Box>
              )}
            </Box>
            {/* </Grid> */}

            {/* right body Ends */}
          </Box>

          {openApplyJobDialog && (
            <ApplyJobs
              jobId={jobId}
              setopenApplyJobDialog={setopenApplyJobDialog}
              setIsApplied={setIsApplied}
              setChangedStatus={setChangedStatus}
              jobVideoRequired={jobVideoRequired}
              openApplyJobDialog={openApplyJobDialog}
            />
          )}
        </Grid>
        <style>
          {`.jobDetailSec::-webkit-scrollbar {
                   display: none;/* Set the background color of the scrollbar */
                }`}
        </style>
        {openReviewVideoDialog && (
          <ReviewVideoDialog
            videoLink={videoLink}
            open={openReviewVideoDialog}
            setOpen={handleCloseDialog}
          />
        )}
      </>
    )
  );
};

export default JobsDetailsPage;
