import React from 'react';

const YouTubeEmbed = ({ videoId,width, height,borderRadius }) => {
  return (
    <div className="video-responsive">
      <iframe
        style={{borderRadius:borderRadius?borderRadius:"25px"}}
        width={width?width:"320px"}
        height={height?height:"240px"}
        s
        src={`https://www.youtube.com/embed/${videoId}`}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        title="Embedded youtube"
      ></iframe>
    </div>
  );
};

export default YouTubeEmbed;

