import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  Divider,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import companyLogo from "../../../../assets/Crayon_Favicon.svg";
import InfiniteScroll from "react-infinite-scroll-component";
import { ALERT_TYPE, COIN_HISTORY } from "../../../../utils/Constants";
import {
  TableCells,
  ToggleCheckedSwitch,
} from "../../../../utils/CommonComponent";
import { setAlert } from "../../../../redux/configSlice";
import { getCoins } from "../../../../redux/admin/maintenance";
import { useDispatch } from "react-redux";
import { dateConverterMonth } from "../../../../utils/DateTime";

export default function CompanyCoins({
  companyCoins,
  handleClose,
  companyInfo,
}) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [companySpend, setCompanySpend] = useState([]);
  const [lastKey, setLastKey] = useState(0);

  const handleSelectChangePaid = (event, row, id, name) => {
    setCompanySpend(
      companySpend?.map((item) => {
        return item?.spend_id === row?.spend_id ? { ...item, paid: id } : item;
      })
    );
  };

  const handleSelectChange = (event, row, id, name) => {
    setCompanySpend(
      companySpend?.map((item) => {
        return item?.spend_id === row?.spend_id
          ? { ...item, invoice: id }
          : item;
      })
    );
  };

  const getCoinsHistory = async (lastKeyy) => {
    try {
      const { payload } = await dispatch(
        getCoins({ lastKey: lastKeyy, company_id: companyInfo?.company_id })
      );
      if (payload?.status === "success") {
        if (payload?.pageNumber === 0) {
          setCompanySpend(payload?.data);
          setLastKey(payload?.pageNumber + 1);
        } else if (payload?.data?.length > 0) {
          setCompanySpend((prev) => [...prev, ...payload?.data]);
          setLastKey(payload?.pageNumber + 1);
        }
      } else {
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE?.ERROR,
            msg: payload?.message,
          })
        );
      }
    } catch (error) {
      dispatch(
        setAlert({
          show: true,
          type: ALERT_TYPE?.ERROR,
          msg: error,
        })
      );
    }
  };

  useEffect(() => {
    getCoinsHistory(0);
  }, []);
  return (
    <Dialog
      open={companyCoins}
      fullWidth={true}
      //   maxWidth={"xs"}
      scroll="body"
      padding={0}
      sx={{
        // zIndex: 100000,
        "& .MuiPaper-root": {
          borderRadius: "25px",
          // maxWidth: "1387px !important",
          maxWidth: "1100px !important",
        },
      }}
    >
      <DialogTitle sx={{ padding: "24px 30px" }}>
        <Box
          sx={{
            display: "flex",
            width: "340px",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box sx={{ display: "flex", gap: "13px", alignItems: "center" }}>
            <Box
              component={"img"}
              src={
                companyInfo?.company?.profile_url === "No URL"
                  ? companyLogo
                  : companyInfo?.company?.profile_url
              }
              sx={{ width: 40, height: 40, borderRadius: "10px" }}
            />
            <Typography
              sx={{ fontSize: "14px", fontWeight: theme.typography.Bold }}
            >
              {companyInfo?.company?.name}
            </Typography>
          </Box>
          <Box
            sx={{
              width: "135px",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography
              sx={{ fontSize: "14px", fontWeight: theme.typography.Bold }}
            >
              Coin Balance
            </Typography>
            <Button
              variant="contained"
              color="submitButton"
              sx={{
                minWidth: "fit-content",
                height: "26px",
                borderRadius: "7px",
                padding: "7px",
                color: "black",
              }}
            >
              {companyInfo?.company?.coins}
            </Button>
          </Box>
        </Box>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Box
            sx={{
              width: "20px",
              height: "20px",
              color: "#000000",
              border: 2,
              fontSize: "18px",
              borderRadius: "5px",
            }}
          >
            X
          </Box>
        </IconButton>
      </DialogTitle>
      <Divider />

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "15px",
          width: "98%",
          mt: "8px",
          overflowY: "auto",
          height: "400px",
          "::-webkit-scrollbar": {
            height: "5px",
            background: "#DCDEEB",
            display: "unset !important",
          },
        }}
        id="matchTaleneList"
      >
        <InfiniteScroll
          style={{
            overflowX: "hidden",
            scrollbarWidth: "thin",
            display: "flex",
            flexDirection: "column",
            gap: "16px",
            width: "100%",
          }}
          dataLength={companySpend?.length}
          next={() => getCoinsHistory(lastKey)}
          scrollThreshold={"100px"}
          scrollableTarget={"matchTaleneList"}
          hasMore={true}
          endMessage={
            <p style={{ textAlign: "center" }}>
              <b>Yay! You have seen it all</b>
            </p>
          }
        >
          <TableContainer component={Paper} sx={{ height: "417px" }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCells
                    sx={{ padding: "22px 16px 10px 30px", width: "111px" }}
                  >
                    <Typography variant="subtitle1" fontWeight="bold">
                      Spend ID
                    </Typography>
                  </TableCells>
                  <TableCells sx={{ padding: "22px 0px 10px 0px" }}>
                    <Typography
                      variant="subtitle1"
                      fontWeight="bold"
                      textAlign={"center"}
                    >
                      User
                    </Typography>
                  </TableCells>
                  <TableCells sx={{ padding: "22px 0px 10px 0px" }}>
                    <Typography
                      variant="subtitle1"
                      fontWeight="bold"
                      textAlign={"center"}
                    >
                      Item
                    </Typography>
                  </TableCells>
                  <TableCells sx={{ padding: "22px 0px 10px 0px" }}>
                    <Typography
                      variant="subtitle1"
                      fontWeight="bold"
                      textAlign={"center"}
                    >
                      ID
                    </Typography>
                  </TableCells>
                  <TableCells sx={{ padding: "22px 0px 10px 0px" }}>
                    <Typography
                      variant="subtitle1"
                      fontWeight="bold"
                      textAlign={"center"}
                    >
                      Coins
                    </Typography>
                  </TableCells>

                  <TableCells sx={{ padding: "22px 0px 10px 0px" }}>
                    <Typography
                      variant="subtitle1"
                      fontWeight="bold"
                      textAlign={"center"}
                    >
                      Date
                    </Typography>
                  </TableCells>
                  <TableCells>
                    <Typography
                      variant="subtitle1"
                      fontWeight="bold"
                      textAlign={"center"}
                    >
                      Coin Expiry Date
                    </Typography>
                  </TableCells>
                  <TableCells sx={{ padding: "22px 0px 10px 0px" }}>
                    <Typography
                      variant="subtitle1"
                      fontWeight="bold"
                      textAlign={"center"}
                    >
                      Spend Coins
                    </Typography>
                  </TableCells>
                  {/*<TableCells sx={{ padding: "22px 16px 10px 30px" }}>
                    <Typography variant="subtitle1" fontWeight="bold" 
                        textAlign={"center"}
                    
                    >
                      Invoiced
                    </Typography>
                  </TableCells>
                  <TableCells sx={{ padding: "22px 16px 10px 30px" }}>
                    <Typography variant="subtitle1" fontWeight="bold" 
                        textAlign={"center"}
                    
                    >
                      Paid
                    </Typography>
                  </TableCells>
                  <TableCells sx={{ padding: "22px 16px 10px 30px" }}>
                    <Typography variant="subtitle1" fontWeight="bold" 
                        textAlign={"center"}
                    
                    >
                      Status
                    </Typography>
                  </TableCells>
                  <TableCells sx={{ padding: "22px 16px 10px 30px" }}>
                    <Typography variant="subtitle1" fontWeight="bold" 
                        textAlign={"center"}
                    
                    >
                      Balance
                    </Typography>
                    </TableCells>*/}
                </TableRow>
              </TableHead>
              <TableBody>
                {companySpend?.map((row) => (
                  <TableRow
                    key={row.transaction_id}
                    style={{
                      "& .css-12zgwp-MuiTableCell-root": {
                        padding: "0 16px !important",
                      },
                    }}
                  >
                    <TableCells
                      sx={{
                        fontWeight: theme.typography.Bold,
                        padding: "0px 0px 16px 30px",
                      }}
                    >
                      {row?.transaction_id}
                    </TableCells>
                    <TableCells
                      sx={{
                        padding: "0px 0px 16px 0px",
                        textAlign: "center",
                      }}
                    >
                      {row?.user?.first_name} {row?.user?.last_name}
                    </TableCells>
                    <TableCells
                      sx={{
                        padding: "0px 0px 16px 0px",
                        textAlign: "center",
                      }}
                    >
                      {row?.transaction_name?.name}
                    </TableCells>
                    <TableCells
                      sx={{
                        padding: "0px 0px 16px 0px",
                        textAlign: "center",
                      }}
                    >
                      {row?.id}
                    </TableCells>
                    <TableCells
                      sx={{
                        padding: "0px 0px 16px 0px",
                        textAlign: "center",
                      }}
                    >
                      {row?.coins}
                    </TableCells>
                    <TableCells
                      sx={{
                        padding: "0px 0px 16px 0px",
                        textAlign: "center",
                      }}
                    >
                      {row?.created_at === null
                        ? "-"
                        : dateConverterMonth(row?.created_at)}
                    </TableCells>
                    <TableCells
                      sx={{ padding: "0px 0px 16px 0px", textAlign: "center" }}
                    >
                      {row?.expiry_coin_date === null
                        ? "-"
                        : dateConverterMonth(row?.expiry_coin_date)}
                    </TableCells>
                    <TableCells
                      sx={{
                        padding: "0px 0px 16px 0px",
                        textAlign: "center",
                      }}
                    >
                      {/* {row?.symbol} */}
                      {row?.spend_coins === null ? "0" : row?.spend_coins}
                    </TableCells>
                    {/*<TableCells sx={{ padding: "0px 0px 16px 0px",textAlign: "center" }}>
                      {
                        <ToggleCheckedSwitch
                          checkedColor={"recentButton"}
                          notCheckedColor={"employerButton"}
                          checked={row?.invoice}
                          onChange={(event, id, name) =>
                            handleSelectChange(event, row, id, name)
                          }
                        />
                      }
                    </TableCells>
                    <TableCells sx={{ padding: "0px 0px 16px 0px",textAlign: "center" }}>
                      {
                        <ToggleCheckedSwitch
                          checkedColor={
                            row?.status === "Paid"
                              ? "recentButton"
                              : "talentButton"
                          }
                          notCheckedColor={"employerButton"}
                          checked={row?.paid}
                          onChange={(event, id, name) =>
                            handleSelectChangePaid(event, row, id, name)
                          }
                        />
                      }
                    </TableCells>
                    <TableCells sx={{ padding: "0px 0px 16px 0px",textAlign: "center" }}>
                      {row?.transaction_status?.name}
                    </TableCells>
                    <TableCells sx={{ padding: "0px 0px 16px 0px",textAlign: "center" }}>
                      {row?.remaining_coins}
                    </TableCells>*/}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </InfiniteScroll>
      </Box>
    </Dialog>
  );
}
