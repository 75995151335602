import { Avatar, Box, Button, Typography, useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  getAdminMembers,
  getAllTeamMembers,
  searchPoolPermission,
  talentPoolPermission,
} from "../../../../redux/employer/myTeams";
import { ALERT_TYPE, ERROR_MSG, USER_ROLES } from "../../../../utils/Constants";
import { setAlert, setLoading } from "../../../../redux/configSlice";
import { ToggleSwitch } from "../../../../utils/CommonComponent";
import InfiniteScroll from "react-infinite-scroll-component";
import {
  getColorByIndex,
  getDecodedToken,
  getToken,
} from "../../../../utils/Common";
import locale from "../../../../i18n/locale";

const TeamAccessList = ({ jobId, openDialog, closeFunc, searchPool }) => {
  const theme = useTheme();
  const i18n = locale.en;
  const dispatch = useDispatch();
  let decodedToken = getDecodedToken(getToken());
  const userType = decodedToken?.data?.role_id;
  const [teamMembers, setTeamMembers] = useState([]);
  const handleGetTeamMembersDetails = async () => {
    try {
      if (
        (searchPool && decodedToken?.data?.role_id === 1) ||
        (searchPool && decodedToken?.data?.role_id === 2)
      ) {
        const { payload } = await dispatch(
          getAdminMembers({ search_id: jobId })
        );
        if (payload?.status === "success") {
          setTeamMembers([...payload?.data]);
        }
        // http://stageapi.crayondev.co.za/admin/getCrayonPermission?search_id=88
      } else {
        if (userType === USER_ROLES?.at(3)?.role_id) {
          const { payload } = await dispatch(
            getAllTeamMembers(
              searchPool ? { search_id: jobId } : { pool_id: jobId }
            )
          );
          if (payload?.status === "success") {
            setTeamMembers([...payload?.data]);
          }
        }
        if (userType === USER_ROLES?.at(0)?.role_id) {
          const { payload } = await dispatch(
            getAdminMembers({ pool_id: jobId })
          );
          if (payload?.status === "success") {
            setTeamMembers([...payload?.data]);
          }
        }
      }
    } catch (error) {
      dispatch(setLoading(false));
      dispatch(
        setAlert({
          show: true,
          type: ALERT_TYPE?.ERROR,
          msg: ERROR_MSG,
        })
      );
    }
  };
  const handleSwitch = async (val, user_id) => {
    let payload = {
      pool_id: jobId,
      employer_id: user_id,
      access_type: val.target.checked,
    };
    let searchPayload = {
      search_id: jobId,
      employer_id: user_id,
      access_type: val.target.checked,
    };
    setTeamMembers((prev) =>
      prev?.map((item) =>
        item?.user_id === user_id
          ? { ...item, pool_member_status: val?.target?.checked }
          : item
      )
    );
    try {
      if (searchPool) {
        await dispatch(searchPoolPermission(searchPayload));
      } else {
        await dispatch(talentPoolPermission(payload));
      }
    } catch (error) {
      // Handle error (e.g., show an alert)
    }
  };

  useEffect(() => {
    handleGetTeamMembersDetails();
  }, [jobId]);

  return (
    <>
      {openDialog ? (
        <Box
          sx={{
            position: "absolute",
            left: 0,
            marginTop: 1,
            background: "#ffff",
            padding: 0,
            width: 300,
            zIndex: 100,
            borderRadius: "25px",
            display: "flex",
            flexDirection: "column",
            gap: "12px",
            boxShadow: 2,
          }}
        >
          <Box
            sx={{
              padding: "16px 16px 0px 16px",
              display: "flex",
              flexDirection: "column",
              gap: 1,
            }}
          >
            <Typography
              sx={{
                fontSize: "14px",
                fontWeight: 700,
              }}
            >
              Who can access this job?
            </Typography>
            <Typography
              sx={{
                fontSize: "12px",
              }}
            >
              Below is a list of your team members that can view, edit and
              manage this pool.
            </Typography>
            <Box
              id="talentList"
              sx={{
                margin: "0px 16px",
                height: { xs: "283px", xl: "292px" },
                overflowY: "scroll",
                "::-webkit-scrollbar": {
                  height: "5px",
                  background: "#DCDEEB",
                  display: "unset !important",
                },
              }}
              className="accessList-infinite-scroll-component"
            >
              <InfiniteScroll
                style={{
                  height: { xs: "283px", xl: "292px" },
                  overflowX: "hidden",
                  scrollbarWidth: "thin",
                }}
                dataLength={teamMembers?.length}
                hasMore={true}
                scrollThreshold={"50px"}
                scrollableTarget="talentList"
                endMessage={
                  <p style={{ textAlign: "center" }}>
                    <b>Yay! You have seen it all</b>
                  </p>
                }
              >
                {teamMembers?.map((teamMember, index) => (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "start",
                      padding: "10px 5px",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        gap: "10px",
                        alignItems: "center",
                      }}
                    >
                      <Avatar
                        sx={{
                          bgcolor: getColorByIndex(index),
                          borderRadius: "10px",
                        }}
                      >
                        {teamMember?.user?.first_name?.[0]}
                      </Avatar>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "start",
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: "16px",
                            fontWeight: theme.typography.Bold,
                          }}
                        >
                          {teamMember?.user?.first_name || teamMember?.name}
                        </Typography>
                        <Box>
                          <Typography
                            sx={{
                              fontSize: "13px",
                              fontWeight: theme.typography.Regular,
                            }}
                          >
                            {teamMember?.employer_role_type?.name ||
                              teamMember?.role_type}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                    <ToggleSwitch
                      defaultChecked={
                        // teamMember?.assigned_job !== null ||
                        teamMember?.role_type_id === 1 ||
                        teamMember?.role_type === "superadmin"
                      }
                      color={
                        teamMember?.role_type_id === 1 ||
                        teamMember?.role_type === "superadmin"
                          ? "adminCheckColor"
                          : "checkColor"
                      }
                      checked={teamMember?.pool_member_status}
                      disabled={
                        teamMember?.role_type_id === 1 ||
                        teamMember?.role_type === "superadmin"
                          ? true
                          : false
                      }
                      onChange={(event) =>
                        handleSwitch(event, teamMember?.user_id)
                      }
                    />
                  </Box>
                ))}
              </InfiniteScroll>
            </Box>
          </Box>
          <Box sx={{ display: "flex", width: "100%" }}>
            <Button
              variant="contained"
              color="grayButton200"
              sx={{
                borderRadius: "0 0 0 25px",
                width: "30%",
                padding: "25px 40px",
                fontSize: "10px",
              }}
              onClick={() => closeFunc()}
            >
              close
            </Button>

            <Button
              variant="contained"
              backgroundColor={"redButton"}
              sx={{
                borderRadius: "0 0 25px 0",
                width: "100%",
                padding: "25px 20px",
                fontSize: "10px",
              }}
              color="redButton100"
            >
              Add/edit a team member
            </Button>
          </Box>
        </Box>
      ) : (
        ""
      )}
    </>
  );
};

export default TeamAccessList;
