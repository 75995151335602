import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Grid,
  InputLabel,
  Modal,
  Slider,
  Typography,
} from "@mui/material";
import { useParams } from "react-router-dom";
import challanger from "../../../../assets/Personality Logos/crayon_personality_grey_challeneger.svg";
import character from "../../../../assets/Personality Logos/crayon_personality_character.svg";
import contemplator from "../../../../assets/Personality Logos/crayon_personality_collaborator.svg";
import contemplator1 from "../../../../assets/Personality Logos/crayon_personality_contemplator.svg";
import AutoComplete from "../../../common/AutoComplete";
import { useTheme } from "@emotion/react";
import { useDispatch } from "react-redux";
import { setAlert } from "../../../../redux/configSlice";
import {
  ALERT_TYPE,
  CULTURE_MARKS,
  ERROR_MSG,
} from "../../../../utils/Constants";
import { talentPersonality } from "../../../../redux/admin/jobsSlice";
import { StyledBox } from "../../../../utils/CommonComponent";
import * as Yup from "yup";
import { validateSchema } from "../../../../utils/Common";
import {
  getPersonalities,
  getTraits,
} from "../../../../redux/employer/postJobSlice";
import { useSelector } from "react-redux";
import CustomSelect from "../../../candidate/myCV/CustomSelect";
import {
  getCompanyCulture,
  updateToInitialCulture,
} from "../../../../redux/candidate/myCvSlice";
import _ from "lodash";
import personalityIcon from "../../../../assets/Padding Included/User_Profile.svg";

export default function EditPersonality({
  show,
  handleOpen,
  setPersonalityAdded,
  talentContent,
  seteditPersonality,
  handleDropDataApi,
  setPersonalitiesData,
  personalitiesData,
  setButtonArr,
  buttonArr,
}) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { id } = useParams();
  const { companyCultures, initialCompanyCulture } = useSelector(
    (state) => state.myCv
  );
  const [errors, setErrors] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const personalitySchema = Yup.object().shape({
    primary_personality: Yup.string()
      .nullable()
      .required("Primary Personality is Required."),
    shadow_personality: Yup.string()
      .nullable()
      .required("Shadow Personality is Required."),
    grit_score: Yup.number().nullable().required("Grit Score is Required."),
    traits: Yup.array()
      .min(5, "At least five trait is required.")
      .required("Traits are Required."),
  });

  const handleMultipleAutoComplete = ({ event, newValue, id }) => {
    if (newValue?.length <= 5) {
      let newPersonalitiesData = {
        ...personalitiesData,
        [id]: newValue?.map((val) => val?.inputValue || val?.trait_id || val),
      };
      setErrors(errors?.filter((item) => item?.key !== id));
      setPersonalitiesData(newPersonalitiesData);
    } else {
      newValue?.splice(5, 1);
      dispatch(
        setAlert({
          show: true,
          type: ALERT_TYPE.ERROR,
          msg: "You can't add more than 5 traits!!",
        })
      );
    }
  };
  const getTraitsValue = () => {
    if (personalitiesData?.traits?.length === 0) {
      return [];
    }

    return personalitiesData?.traits?.map(
      (id) => traits?.find((trait) => trait.id == id) || id
    );
  };

  const handleChange = (event, value, id) => {
    const {
      target: { name },
    } = event;
    setButtonArr(
      buttonArr?.map((item) =>
        item.id === id
          ? { ...item, activeLabel: name }
          : {
              ...item,
              activeLabel: item.activeLabel === name ? "" : item.activeLabel,
            }
      )
    );

    const newPersonalitiesData = {
      ...personalitiesData,
      [name]: Number(id),
    };
    setErrors(errors?.filter((item) => item?.key !== name));
    setPersonalitiesData(newPersonalitiesData);
  };

  const addPersonality = async () => {
    let {
      primary_personality,
      shadow_personality,
      grit_score,
      traits,
      culture_ids,
    } = personalitiesData;

    let testErrorState = {
      primary_personality: primary_personality,
      shadow_personality: shadow_personality,
      grit_score: grit_score,
      traits,
      culture_ids: culture_ids?.map((item) => item?.value),
    };
    validateSchema(personalitySchema, testErrorState)
      .then(() => {
        try {
          const data = {
            ...personalitiesData,
            user_id: talentContent?.candidate_user_id || talentContent?.user_id,
            culture_ids: culture_ids?.map((item) => item?.value),
          };
          dispatch(talentPersonality(data)).then((payload) => {
            if (payload?.payload?.status === "success") {
              dispatch(
                setAlert({
                  show: true,
                  type: ALERT_TYPE.SUCCESS,
                  msg: "Personality Added Successfully",
                })
              );
              setPersonalityAdded(true);
              handleClose();
              handleDropDataApi &&
                handleDropDataApi(talentContent?.candidate_user_id, true);
            } else {
              dispatch(
                setAlert({
                  show: true,
                  type: ALERT_TYPE.ERROR,
                  msg: "Something went wrong! please relaod the window",
                })
              );
            }
          });
        } catch (error) {
          dispatch(
            setAlert({
              show: true,
              type: ALERT_TYPE?.ERROR,
              msg: ERROR_MSG,
            })
          );
        }
      })
      .catch((error) => {
        setErrors(
          Object.keys(error)?.map((item) => ({
            message: error[item],
            key: item,
          }))
        );
      });
  };

  const rangeHandler = (event) => {
    const {
      target: { value },
      target: { name },
    } = event;

    const newPersonalitiesData = {
      ...personalitiesData,
      [name]: value,
    };

    setErrors(errors?.filter((item) => item?.key !== name));
    setPersonalitiesData(newPersonalitiesData);
  };

  const handleClose = () => {
    setErrors([]);
    seteditPersonality((prev) => !prev);
  };

  const getCultureValue = () => {
    if (personalitiesData?.culture_ids?.length === 0) {
      return [];
    }

    return personalitiesData?.culture_ids?.map(
      (id) => companyCultures?.find((culture) => culture?.id === id) || id
    );
  };
  const { traits } = useSelector((state) => state.postJobs);

  const getAllData = async () => {
    setIsLoading(true);
    try {
      await Promise.all([
        dispatch(
          getCompanyCulture({ setState: setIsLoading, type: "culture_ids" })
        ),
        dispatch(getPersonalities()),
        dispatch(getTraits()),
      ]);
      setIsLoading(false);
    } catch (error) {
      dispatch(
        setAlert({
          show: true,
          type: ALERT_TYPE?.ERROR,
          msg: ERROR_MSG,
        })
      );
      setIsLoading(false);
    }
  };

  const textValue = (value) => {
    return value;
  };

  useEffect(() => {
    getAllData();
  }, []);
  const handleOnSelectChange = (ind, newValue, val, inputId) => {
    let newBasicData = { ...personalitiesData };
    if (ind === "culture_ids") {
      if (ind === "culture_ids") {
        dispatch(updateToInitialCulture());
      }
      newBasicData[ind] = newValue;
    } else {
      newBasicData[inputId] = newValue?.id || newValue;
    }
    setPersonalitiesData(newBasicData);
  };
  const updateDebounceFunc = async (newValue, type) => {
    let params = {
      title: newValue || "",
      limit: 200,
    };
    if (newValue) {
      if (newValue) {
        try {
          if (type === "culture_ids") {
            dispatch(
              getCompanyCulture({ ...params, setState: setIsLoading, type })
            );
          }
        } catch (error) {
          dispatch(
            setAlert({
              show: true,
              type: ALERT_TYPE?.ERROR,
              msg: "Something went wrong",
            })
          );
        }
      }
    }
  };
  const debounceFunction = _.debounce(updateDebounceFunc, 750);

  const handleOnSelectInputChange = (newValue, inputId) => {
    debounceFunction(newValue, inputId);
  };
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: { xs: "auto", tablet: 380 },
    bgcolor: "background.paper",
    borderRadius: "25px",
    boxShadow: "none !important",
    height: "fit-content",
    "&:focus-visible": {
      outline: "none",
    },
  };
  return (
    <Modal
      open={show}
      hideButton={false}
      maxWidth="xs"
      fullWidth={true}
      showFooter={false}
      scroll="body"
      onClose={handleClose}
    >
      <Box sx={style}>
        <Box sx={{}}>
          <Button
            variant="contained"
            color="ideaGritScoreSlider"
            sx={{
              borderRadius: "25px 0 25px 0",
              fontSize: "14px",
              gap: "5px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "8px",
              }}
            >
              <Box
                component={"img"}
                src={personalityIcon}
                sx={{ width: 20, height: 20 }}
              />
            </Box>
            <Typography
              sx={{
                fontSize: "14px",
                fontWeight: theme.typography.Bold,
              }}
            >
              edit personality
            </Typography>
          </Button>
        </Box>
        <Box
          sx={{
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            gap: 3,
            padding: "16px",
          }}
        >
          <Typography sx={{ fontSize: "12px", fontWeight: 700 }}>
            Select a primary and seconday/shado personality below:
          </Typography>
          <Box
            sx={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
            }}
          >
            <Box
              sx={{
                display: "flex",
                width: "50%",
                justifyContent: "space-between",
              }}
            >
              <Box
                sx={{
                  height: "230px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <StyledBox
                  className="homeImages"
                  alt="Home Image"
                  src={challanger}
                />
                <StyledBox
                  className="homeImages"
                  alt="Home Image"
                  src={character}
                />
                <StyledBox
                  className="homeImages"
                  alt="Home Image"
                  src={contemplator1}
                />
                <StyledBox
                  className="homeImages"
                  alt="Home Image"
                  src={contemplator}
                />
              </Box>

              <Box
                sx={{
                  height: "230px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "12px",
                    height: "50px",
                    width: "fit-content",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  Challenger
                </Typography>
                <Typography
                  sx={{
                    fontSize: "12px",
                    height: "50px",
                    width: "fit-content",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  Character
                </Typography>
                <Typography
                  sx={{
                    fontSize: "12px",
                    height: "50px",
                    width: "fit-content",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  Contemplator
                </Typography>
                <Typography
                  sx={{
                    fontSize: "12px",
                    height: "50px",
                    width: "fit-content",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  Collaborator
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                height: "230px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              {buttonArr?.map((item, index) => {
                return (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-end",
                      height: "45px",
                      justifyContent: "space-between",
                    }}
                  >
                    <Button
                      name="primary_personality"
                      variant="contained"
                      color={
                        item.activeLabel === "primary_personality"
                          ? "lightGreenButton300"
                          : "grayButton200"
                      }
                      mr="8px"
                      onClick={(event) => {
                        handleChange(event, item.name, item.id);
                      }}
                      sx={{
                        border: errors?.find(
                          (error) => error?.key === "primary_personality"
                        )
                          ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
                          : "1px solid #E0E0E0",
                        width: "100px !important",
                        height: "20px",
                        fontWeight: 300,
                        letterSpacing: "0.75px",
                        boxShadow: 0,
                        borderRadius: "5px",
                        color: "white",
                        padding: "0 8px",
                        fontSize: "10px !important",
                      }}
                    >
                      {item.label}
                    </Button>
                    <Button
                      name="shadow_personality"
                      variant="contained"
                      color={
                        item.activeLabel === "shadow_personality"
                          ? "orangeButton"
                          : "grayButton200"
                      }
                      mr="8px"
                      onClick={(event) => {
                        handleChange(event, item.name, item.id);
                      }}
                      sx={{
                        border: errors?.find(
                          (error) => error?.key === "shadow_personality"
                        )
                          ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
                          : "1px solid #E0E0E0",
                        width: "100px !important",
                        height: "20px",
                        fontWeight: 300,
                        letterSpacing: "0.75px",
                        boxShadow: 0,
                        borderRadius: "5px",
                        color: "white",
                        padding: "0 8px",
                        fontSize: "10px !important",
                      }}
                    >
                      {item.label1}
                    </Button>
                  </Box>
                );
              })}
            </Box>
          </Box>
          <Box sx={{ mb: 1, width: "100%" }}>
            <Typography
              sx={{ fontSize: "12px", fontWeight: 700, marginBottom: "5px" }}
            >
              Add or edit company culture preferences below (optional):
            </Typography>
            <CustomSelect
              inputId={"culture_ids"}
              name={"culture_ids"}
              variant={"createMultiSelect"}
              closeMenuOnSelect={true}
              isMulti={true}
              isLoading={isLoading["culture_ids"]}
              multiSelectOptions={companyCultures?.map((item) => ({
                value: item?.company_culture_id,
                label: item?.name,
              }))}
              placeholder={"Enter 5 Company culture preference"}
              onChange={(e, val) => handleOnSelectChange(e, val)}
              customStyle={{
                option: (
                  styles,
                  { data, isDisabled, isFocused, isSelected }
                ) => {
                  return {
                    ...styles,
                    color: isSelected ? "#fff" : "black",
                    ":active": {
                      ...styles[":active"],
                    },
                  };
                },
                control: (baseStyles) => ({
                  ...baseStyles,
                  boxShadow: "none",
                  borderRadius: "10px",
                  minHeight: `40px`,
                  border: "1px solid #E0E0E0",
                  "&:hover": "1px solid #E0E0E0",
                  width: `100%`,
                }),
              }}
              onInputChange={handleOnSelectInputChange}
              multiOnFocus={() => {
                if (companyCultures?.length === 0) {
                  getAllData("culture_ids");
                }
              }}
              value={getCultureValue()}
              limitTags={5}
              initialOptions={initialCompanyCulture?.map((item) => ({
                value: item?.company_culture_id,
                label: item?.name,
              }))}
            />
          </Box>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
            }}
          >
            <Typography
              sx={{
                fontSize: "12px",
                fontWeight: 700,
                marginBottom: "5px",
                paddingLeft: "10px",
              }}
            >
              Add & edit personality traits below:
            </Typography>
            <AutoComplete
              disableCloseOnSelect={true}
              multiple={true}
              id="traits"
              name="traits"
              value={getTraitsValue()}
              onChange={handleMultipleAutoComplete}
              sx={{
                width: "100%",
                // display: "inline-table",
                display: "flex",
                fontSize: "14px",
                alignItems: "center",
                border: errors?.find((error) => error?.key === "traits")
                  ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
                  : "1px solid #E0E0E0",
              }}
              placeholder={"Enter or edit personality trait"}
              data={traits}
              limitTags={5}
              height={20}
            ></AutoComplete>
          </Box>

          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
            }}
          >
            <Typography sx={{ fontSize: "12px", fontWeight: 700 }}>
              Enter grit score below:
            </Typography>
            <Slider
              name="grit_score"
              aria-label="Custom marks"
              color="ideaGritScoreSlider"
              value={personalitiesData?.grit_score}
              getAriaValueText={textValue}
              step={1}
              onChange={(event) => rangeHandler(event)}
              valueLabelDisplay="auto"
              valueLabelFormat={textValue}
              marks={CULTURE_MARKS}
              sx={{
                width: "88%",
                ml: 2,
                "& .MuiSlider-rail": {
                  backgroundColor: theme.palette.eyeview100.main,
                  height: "10px",
                  border: errors?.find((error) => error?.key === "grit_score")
                    ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
                    : "1px solid #E0E0E0",
                },
                "& .MuiSlider-track": {
                  height: "10px",
                },
                "& .MuiSlider-thumb": {
                  borderRadius: "15%",
                },
              }}
            />
          </Box>
        </Box>
        <Grid
          container
          alignItems="center"
          overflow={"hidden"}
          sx={{
            width: "100%",
            height: 51,
            mt: 2,
          }}
        >
          <Button
            variant="contained"
            id="broad"
            sx={{
              borderRadius: "0 0 25px 25px",
              width: "100%",
              height: "100%",
              fontSize: "14px",
            }}
            color="redButton100"
            onClick={addPersonality}
          >
            Update
          </Button>
        </Grid>
      </Box>
    </Modal>
  );
}
