import React, { useEffect, useRef, useState } from 'react';
import {
  Box,
  Button,
  DialogTitle,
  IconButton,
  Paper,
  Typography,
  useTheme,
} from '@mui/material';
import CloseIcon from '../../common/CloseIcon';

const MAX_VIDEO_LENGTH_MS = 10000; // 1 minute in milliseconds
const MAX_VIDEO_SIZE_BYTES = 100 * 1024 * 1024; // 100 MB in bytes

const RecordingInProgress = ({ nextStep, onRecordingStop, onCloseCamera }) => {
  const theme = useTheme();
  const [mediaStream, setMediaStream] = useState(null);
  const [recording, setRecording] = useState(false);
  const [mediaStreamReady, setMediaStreamReady] = useState(false);
  const videoRef = useRef();
  const mediaRecorderRef = useRef(null);
  const recordedChunksRef = useRef([]);
  const recordingStartTimeRef = useRef(null);
  const [startRecording, setStartRecording] = useState(false);

  async function setupCamera() {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: true, audio: true });
      videoRef.current.srcObject = stream;
      setMediaStream(stream);
      setMediaStreamReady(true);
    } catch (error) {
      console.error('Error accessing camera:', error);
    }
  }
  useEffect(() => {
    setupCamera()
  }, [])
  const handleStartRecording = () => {
    // Start recording when the MediaStream is ready
    if (mediaStreamReady) {
      recordedChunksRef.current = [];
      mediaRecorderRef.current = new MediaRecorder(mediaStream);
      mediaRecorderRef.current.ondataavailable = (e) => {
        if (e?.data?.size > 0) {
          recordedChunksRef?.current?.push(e.data);
          if (recordedChunksRef?.current?.length === 1) {
            setTimeout(handleStopRecording, MAX_VIDEO_LENGTH_MS);
          }
          // Check if the recorded video size exceeds the maximum limit
          if (
            recordedChunksRef?.current?.reduce((acc, chunk) => acc + chunk.size, 0) >
            MAX_VIDEO_SIZE_BYTES
          ) {
            alert('The recorded video exceeds the maximum size limit (100 MB).');
            handleStopRecording();
          }
        }
      };
      mediaRecorderRef.current.onstop = () => {
        const blob = new Blob(recordedChunksRef.current, { type: 'video/webm' });
        onRecordingStop(blob);
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        URL.revokeObjectURL(url);
        setRecording(false);
      };
      recordingStartTimeRef.current = Date.now();
      mediaRecorderRef.current.start();
      setRecording(true);
    }
  };

  const handleStopRecording = () => {
    if (mediaRecorderRef.current && mediaRecorderRef.current.state === 'recording') {
      mediaRecorderRef.current.stop();
    }
  };

  const handleCloseCamera = () => {
    if (mediaStream) {
      mediaStream?.getTracks()?.forEach((track) => {
        track?.stop();
      });
      setMediaStream(null);
    }
  };

  return (
    <>
      <DialogTitle>
        {/* <IconButton
          aria-label="close"
          onClick={() => {
            handleCloseCamera()
            onCloseCamera()
          }}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
          disableRipple={true}
        >
          <Box
            sx={{
              width: "20px",
              height: "20px",
              color: "#000000",
              border: 2,
              fontSize: "18px",
              borderRadius: "5px",

            }}
          >
            X
          </Box>
        </IconButton> */}
        <CloseIcon
              top="15px"
              right="15px"
              onClick={() =>{
                handleCloseCamera()
                onCloseCamera()
              }}
            />
      </DialogTitle>
      <Paper
        sx={{
          padding: 3,
          paddingBottom: 0,
          display: 'flex',
          flexDirection: 'column',
          gap: 3,
          height: '30rem',
        }}
      >
        <Box
          sx={{
            background: theme.palette.mainBackground,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: '25px',
            marginBottom: 2,
            gap: 2,
            flexGrow: 1,
          }}
        >
          <video
            ref={videoRef}
            style={{
              width: '100%',
              borderRadius: '25px',
              height: 'auto',
            }}
            autoPlay
            playsInline
            muted
          ></video>
        </Box>
        <Box>
          <Typography
            sx={{
              textAlign: 'center',
              fontSize: '20px',
              fontWeight: 700,
            }}
          >
            Recording in Progress
          </Typography>
          <Typography
            sx={{
              textAlign: 'center',
              fontSize: '14px',
              fontWeight: 700,
              marginBottom: 3,
            }}
          >
            Click Stop to complete Recording
          </Typography>
        </Box>
        <Box sx={{ margin: 'auto', width: '80%' }}>
          <Button
            variant="contained"
            color="redButton100"
            sx={{
              borderRadius: 0,
              width: '100%',
              height: '47px',
              borderTopLeftRadius: 25,
              borderTopRightRadius: 25,
            }}
            onClick={() => {
              if (startRecording) {
                handleStopRecording();
                handleCloseCamera();
                setStartRecording(false);
              } else {
                handleStartRecording();
                setStartRecording(true);
              }
            }}
          >
            {startRecording ? 'Stop Recording' : 'Start Recording'}
          </Button>
        </Box>
      </Paper>
    </>
  );
};

export default RecordingInProgress;
