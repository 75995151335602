import { useState } from "react";
import ReactCardFlip from "react-card-flip";
import HiringCardFront from "./HiringCardFront";
import HiringCardBack from "./HiringCardBack";
import ReviewVideoDialog from "../../common/ReviewVideoDialog";
export default function HiringCard({
  index,
  job,
  setJobId,
  onHandleClose,
  setopenApplyJobDialog,
  getCompanyHiringList,
  favouriteFilter,
  filterCompany,
  currentPageJobsCount,
  jobslength,
  page
}) {
  const [isHorizontalFlipped, setisHorizontalFlipped] = useState(false);

  const [openReviewVideoDialog, setOpenReviewVideoDialog] = useState(false);

  const [videoLink, setVideoLink] = useState(job?.video_url);
  const handleCloseDialog = () => {
    setVideoLink(job?.video_url);
    setOpenReviewVideoDialog(false);
  };
  return (
    <>
      <ReactCardFlip
        isFlipped={isHorizontalFlipped}
        flipDirection={"horizontal"}
        flipSpeedBackToFront="0.5"
        flipSpeedFrontToBack="0.5"
        key={job?.company_id}
      >
        <HiringCardFront
          index={job.job_id}
          job={job}
          setJobId={setJobId}
          onHandleClose={onHandleClose}
          setopenApplyJobDialog={setopenApplyJobDialog}
          setisFlipped={setisHorizontalFlipped}
          isHorizontalFlipped={isHorizontalFlipped}
          handleReviewVideoDialog={setOpenReviewVideoDialog}
          getCompanyHiringList={getCompanyHiringList}
          filterCompany={filterCompany}
          jobslength={jobslength}
          currentPageJobsCount={currentPageJobsCount}
          indexNumber={index}
          page={page}
        />
        <HiringCardBack
          index={job.job_id}
          job={job}
          setJobId={setJobId}
          onHandleClose={onHandleClose}
          setopenApplyJobDialog={setopenApplyJobDialog}
          setisFlipped={setisHorizontalFlipped}
          isHorizontalFlipped={isHorizontalFlipped}
          handleReviewVideoDialog={setOpenReviewVideoDialog}
          getCompanyHiringList={getCompanyHiringList}
          favouriteFilter={favouriteFilter}
          filterCompany={filterCompany}
          jobslength={jobslength}
          currentPageJobsCount={currentPageJobsCount}
          indexNumber={index}
          page={page}
        />
      </ReactCardFlip>
      {openReviewVideoDialog && (
        <ReviewVideoDialog
          videoLink={videoLink}
          open={openReviewVideoDialog}
          setOpen={handleCloseDialog}
          allVideoData={job}
        />
      )}
    </>
  );
}
