import React from "react";
import { Box } from "@mui/material";
import SmallButtonTalent from "./SmallButtonTalent";

export default function SVGButton({
  color,
  source,
  onClick,
  height,
  width,
  outerHeight = 40,
  outerWidth = 40,
  padding,
  margin,
  startIconMargin,
  minWidth,
  justifyContent = "center",
  cursor,
  ref
}) {

  return (
    <SmallButtonTalent
      onClick={onClick}
      color={color}
      ref={ref}
      startIcon={
        <Box
          component="img"
          className="eye"
          alt="logo"
          src={source}
          sx={{
            height: height,
            width: width,
          }}
        />
      }
      minWidth={minWidth}
      padding={padding}
      justifyContent={justifyContent}
      borderRadius={50}
      height={outerHeight}
      width={outerWidth}
      fontWeight={700}
      margin={margin}
      startIconMargin={startIconMargin}
      cursor={cursor}
    />
  );
}
