import { Box } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import React, { useState } from "react";
import ReactCardFlip from "react-card-flip";
import CandidatePersonalityFrontCard from "./CandidatePersonalityFrontCard";
import CandidatePersonalityBackCard from "./CandidatePersonalityBackCard";
import { getDecodedToken, getToken } from "../../../utils/Common";
import CandidateInfoFrontCard from "./CandidateInfoFrontCard";
import CandidateInfoBackCard from "./CandidateInfoBackCard";

const CandidateMyProfileCardRow = ({
  profile,
  handlePhotoCapture,
  hiddenFileInput,
  handleImageChange,
  handleImageClick,
  calculateHeight,
  handleLeftClick,
  arrSlider2,
  handleRightClick,
  expanded,
  handleClickMyInfo,
  leftExpanded,
  setVideoLink,
  setOpenReviewVideoDialog,
}) => {
  const theme = useTheme();
  const [isHorizontalFlippedInfo, setisHorizontalFlippedInfo] = useState(false);
  const [isHorizontalFlipped, setisHorizontalFlipped] = useState(false);
  const decodedToken = getDecodedToken(getToken());

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: { xs: "column", lg: "row" },
        alignItems: "center",
        width: { xs: "100%", md: "100%" },
        gap: "30px",
      }}
    >
      <Box
        sx={{
          minWidth: {
            xs: "340px",
            xs390: "360px",
            sm: "90%",
            tablet: "100%",
            md24: "96.5%",
            lg: "50%",
            mlg: `calc(50% - 15px)`,
          },
          width: {
            xs: "340px",
            xs390: "360px",
            sm: "90%",
            tablet: "100%",
            md24: "96.5%",
            lg: "50%",
            mlg: `calc(50% - 15px)`,
          },
          display: "flex",
          flexDirection: "column",
          marginBottom: { xs: "30px", lg: "0px" },
          minHeight: {
            xs: "300px",
            tablet: "225px",
            lg: "280px",
            mlg: "225px",
          },
        }}
      >
        <Box sx={{ width: "100%" }}>
          <ReactCardFlip
            isFlipped={isHorizontalFlippedInfo}
            flipDirection={"horizontal"}
            flipSpeedBackToFront="0.5"
            flipSpeedFrontToBack="0.5"
          >
            <CandidateInfoFrontCard
              profile={profile}
              hiddenFileInput={hiddenFileInput}
              handleImageClick={handleImageClick}
              handleImageChange={handleImageChange}
              handlePhotoCapture={handlePhotoCapture}
              setisHorizontalFlipped={setisHorizontalFlippedInfo}
              isHorizontalFlipped={isHorizontalFlippedInfo}
              expanded={expanded}
              handleClickMyInfo={handleClickMyInfo}
              setVideoLink={setVideoLink}
              setOpenReviewVideoDialog={setOpenReviewVideoDialog}
            />

            <CandidateInfoBackCard
              profile={profile}
              hiddenFileInput={hiddenFileInput}
              handleImageClick={handleImageClick}
              handleImageChange={handleImageChange}
              handlePhotoCapture={handlePhotoCapture}
              setisHorizontalFlipped={setisHorizontalFlippedInfo}
              isHorizontalFlipped={isHorizontalFlippedInfo}
              expanded={expanded}
              handleClickMyInfo={handleClickMyInfo}
              setOpenReviewVideoDialog={setOpenReviewVideoDialog}
              setVideoLink={setVideoLink}
            />
          </ReactCardFlip>
        </Box>
      </Box>
      <Box
        sx={{
          minWidth: {
            xs: "340px",
            xs390: "360px",
            sm: "90%",
            tablet: "100%",
            md24: "96.5%",
            lg: "50%",
            mlg: `calc(50% - 15px)`,
          },
          width: {
            xs: "340px",
            xs390: "360px",
            sm: "90%",
            tablet: "100%",
            md24: "96.5%",
            lg: "50%",
            mlg: `calc(50% - 15px)`,
          },
          display: "flex",
          flexDirection: "column",
          marginBottom: { xs: "30px", lg: "0px" },
          minHeight: {
            xs: "300px",
            tablet: "225px",
            lg: "280px",
            mlg: "225px",
          },
        }}
      >
        <Box sx={{ width: "100%" }}>
          <ReactCardFlip
            isFlipped={isHorizontalFlipped}
            flipDirection={"horizontal"}
            flipSpeedBackToFront="0.5"
            flipSpeedFrontToBack="0.5"
          >
            <CandidatePersonalityFrontCard
              profile={profile}
              handleLeftClick={handleLeftClick}
              arrSlider2={arrSlider2}
              handleRightClick={handleRightClick}
              calculateHeight={calculateHeight}
              setisHorizontalFlipped={setisHorizontalFlipped}
            />

            <CandidatePersonalityBackCard
              profile={profile}
              setisHorizontalFlipped={setisHorizontalFlipped}
              isHorizontalFlipped={isHorizontalFlipped}
              calculateHeight={calculateHeight}
            />
          </ReactCardFlip>
        </Box>
      </Box>
    </Box>
  );
};

export default CandidateMyProfileCardRow;
