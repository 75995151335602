import React, { useCallback, useEffect, useState } from "react";
import SolutionsFilter from "./SolutionsFilter";
import { useDispatch } from "react-redux";
import {
  getSolitionList,
  getSolutionData,
} from "../../../../redux/admin/maintenance";
import { setAlert } from "../../../../redux/configSlice";
import { ALERT_TYPE, ERROR_MSG } from "../../../../utils/Constants";
import InfiniteScroll from "react-infinite-scroll-component";
import { Box } from "@mui/material";
import SolutionAdminCard from "./SolutionAdminCard";
import AddSolutionModal from "./AddSolutionModal";
import { debounce } from "../../../../utils/Common";
import { FilterVintage } from "@mui/icons-material";

export default function AdminSolutions() {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [openFilter, setOpenFilter] = useState(false);
  const [solutionList, setSolutionList] = useState([]);
  const [totalSolution, setTotalSolution] = useState(0);
  const [showMsg, setShowMsg] = useState(false);
  const [lastKey, setLastKey] = useState("");
  const [solutionTypeList, setSolutionTypeList] = useState([]);
  const [isAddSolutionModal, setIsAddSoutionModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [editSolutionData, setEditSolutionData] = useState({});
  const [filtersValue, setFiltersValue] = useState({
    quickSearch: "",
    solution_types: "",
    user_types: "",
  });
  const getSolutionList = async (lastkeyy, userType, solutionType, title) => {
    const { payload } = await dispatch(
      getSolutionData({
        lastKey: lastkeyy,
        user_types: userType ? userType : "",
        solution_types: solutionType ? solutionType : "",
        title: title,
      })
    );
    if (payload?.status === "success") {
      setTotalSolution(payload?.talentCount);
      if (payload?.pageNumber === 0) {
        setLastKey(payload?.pageNumber + 1);
        setSolutionList(payload?.data);
      } else {
        setLastKey(payload?.pageNumber + 1);
        setSolutionList((prevState) => [...prevState, ...payload?.data]);
      }
    } else if (payload === undefined) {
      return;
    } else {
      dispatch(
        setAlert({
          show: true,
          type: ALERT_TYPE?.ERROR,
          msg: "Something went wrong! please relaod the window",
        })
      );
    }
  };

  const debouncedHandleOnTextSearch = useCallback(
    debounce(
      async (newValue, newFilterData) => {
        if (!newValue) return; // Early return
        try {
          const response = await getSolutionList(
            0,
            newFilterData?.user_types?.value,
            newFilterData?.solution_types?.label,
            newValue?.target?.value
          );
          if (response && response?.payload) {
            const { payload } = response;
            dispatch(
              payload?.status === "success"
                ? setSolutionList(payload?.data)
                : setAlert({
                    show: true,
                    type: ALERT_TYPE?.ERROR,
                    msg: "Error",
                  })
            );
          }
          setOpenFilter(false);
        } catch (error) {
          dispatch(
            setAlert({ show: true, type: ALERT_TYPE?.ERROR, msg: ERROR_MSG })
          );
        }
      },
      500, // Adjust delay as needed
      setIsLoading
    ),
    [dispatch, setSolutionList, setIsLoading]
  );

  const handleInputSearch = (event) => {
    setFiltersValue({
      ...filtersValue,
      quickSearch: event?.target?.value,
    });
    debouncedHandleOnTextSearch(event, filtersValue);
  };

  useEffect(() => {
    getSolutionList(0);
  }, []);

  const handleAddSolModalClose = () => {
    setIsAddSoutionModal(false);
  };
  const handleAddSolModalOpen = () => {
    setIsAddSoutionModal(true);
  };
  const handleAddSolModalCancel = () => {
    setEditSolutionData({});
    setIsAddSoutionModal(false);
  };
  const handleAddSolModalCreate = () => {
    setIsAddSoutionModal(false);
  };

  const getSolution = async () => {
    try {
      let response = await dispatch(getSolitionList());
      if (response?.payload?.status === "success") {
        setSolutionTypeList(response?.payload?.data);
      }
    } catch (error) {
      console.log(error, "error");
    }
  };

  useEffect(() => {
    getSolution();
  }, []);

  return (
    <>
      <SolutionsFilter
        title={"all solutions"}
        total={solutionList?.length}
        handleInputSearch={handleInputSearch}
        filtersValue={filtersValue}
        setFiltersValue={setFiltersValue}
        setOpenFilter={setOpenFilter}
        openFilter={openFilter}
        isAddSolutionModal={isAddSolutionModal}
        setIsAddSoutionModal={setIsAddSoutionModal}
        solutionTypeList={solutionTypeList}
        getSolution={getSolution}
        setEditSolutionData={setEditSolutionData}
        editSolutionData={editSolutionData}
        setIsEdit={setIsEdit}
        getSolutionList={getSolutionList}
      />

      <InfiniteScroll
        style={{ overflow: "hidden" }}
        dataLength={solutionList?.length}
        next={() => getSolutionList(lastKey)}
        scrollThreshold={"100px"}
        scrollableTarget={"jobList"}
        hasMore={solutionList?.length < totalSolution ? true : false}
        // loader={<Box>loading....</Box>}
      >
        <Box sx={{ mt: "317px", mb: "16px" }}>
          {solutionList?.length > 0
            ? solutionList?.map((solution, index) => (
                <SolutionAdminCard
                  key={index}
                  index={solution?.job_id}
                  solutionContentData={solution}
                  setSolutionList={setSolutionList}
                  solutionList={solutionList}
                  setIsAddSoutionModal={setIsAddSoutionModal}
                  setIsEdit={setIsEdit}
                  setEditSolutionData={setEditSolutionData}
                />
              ))
            : showMsg && (
                <Box
                  sx={{
                    width: "100%",
                    textAlign: "center",
                    mt: 4,
                    color: "black",
                  }}
                >
                  No Jobs
                </Box>
              )}
        </Box>
        <style>
          {`.infinite-scroll-component__outerdiv{
          width:100%
        }`}
        </style>
      </InfiniteScroll>

      {isAddSolutionModal && (
        <AddSolutionModal
          isAddSolutionModal={isAddSolutionModal}
          setIsAddSoutionModal={setIsAddSoutionModal}
          handleClose={handleAddSolModalClose}
          handleOpen={handleAddSolModalOpen}
          edit={isEdit}
          handleCancel={handleAddSolModalCancel}
          handleCreate={handleAddSolModalCreate}
          solutionTypeList={solutionTypeList}
          getSolutionList={getSolutionList}
          editSolutionData={editSolutionData}
          setEditSolutionData={setEditSolutionData}
        />
      )}
    </>
  );
}
