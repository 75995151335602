import { Box, Button, Grid, useTheme } from "@mui/material";
import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import VideoAddSteps from "./VideoAddSteps";
import CamList from "./CamList";
import MyVideolist from "./MyVideolist";
import LeftArrow from "../../common/LeftArrow";
import RightArrow from "../../common/RightArrow";
import { StyledButtonLeft } from "../../../utils/CommonComponent";
import useViewport from "../../common/ViewPort";
import { useDispatch } from "react-redux";
import {
  setCardsPadding,
  setFilterExpanded,
} from "../../../redux/guest/jobsSlice";
import { useSelector } from "react-redux";
import { getMyCamVideo } from "../../../redux/candidate/candidateJobs";
import { getProfileProgressData } from "../../../redux/candidate/myProfileSlice";
import { useLocation } from "react-router-dom";

const MyCam = () => {
  const theme = useTheme();
  const mediaRecorderRef = useRef(null);
  const gridRef = useRef();
const location = useLocation()
  const { width } = useViewport();
  const filterExpanded = useSelector(
    (state) => state?.configjobslice?.filterExpanded
  );
  const [itemsFetched, setItemsFetched] = useState(false);
  const dispatch = useDispatch();
  const [isVideoUploaded, setIsVideoUploaded] = useState(false);
  const [step, setStep] = useState(1);
  const [recordedVideoData, setRecordedVideoData] = useState(null);
  const [userData, setUserData] = useState();
  const [mediaStream, setMediaStream] = useState(null);
  const [newCardWidth, setNewCardWidth] = useState(340);
  const [newPadding, setNewPadding] = useState(15);
  const [leftExpanded, setLeftExpand] = useState(true);
  const [activeStep, setActiveStep] = useState(1);
  const [rightBoxY, setRightBoxY] = useState((window.innerWidth * 380) / 1920);
  const handleChangeStep = (step) => {
    setActiveStep(step);
  };
useEffect(()=>{
  if(location?.state){
    setActiveStep(3)
  }
},[location])
  const getNewWidth = () => {
    setRightBoxY((window.innerWidth * 380) / 1920);
  };

  const handleStopRecording = () => {
    if (
      mediaRecorderRef?.current &&
      mediaRecorderRef?.current?.state === "recording"
    ) {
      mediaRecorderRef?.current?.stop();
    }
  };

  const handleCloseCamera = () => {
    if (mediaStream) {
      mediaStream?.getTracks()?.forEach((track) => {
        track?.stop();
      });
      setMediaStream(null);
    }
  };

  function calculateCardWidthAndPadding(totalWidth) {
    let cardWidth = 340;
    let padding = 15;
    let numCards = Math.floor(totalWidth / (cardWidth + padding));
    let remainingSpace =
      totalWidth - (numCards * cardWidth + padding * (numCards + 1));

    while (remainingSpace > 0) {
      if (remainingSpace < cardWidth + padding) {
        if (padding < 30) {
          padding++;
          remainingSpace =
            totalWidth - (numCards * cardWidth + padding * (numCards + 1));
        } else {
          break;
        }
      } else {
        numCards++;
      }
    }

    cardWidth = (totalWidth - padding * (numCards + 1)) / numCards;
    if (cardWidth > 380) {
      cardWidth = 380;
    }
    remainingSpace =
      totalWidth - (numCards * cardWidth + padding * (numCards + 1));

    padding = padding + remainingSpace / (numCards + 1);

    remainingSpace =
      totalWidth - (numCards * cardWidth + padding * (numCards + 1));

    setNewCardWidth(cardWidth - 1);
    setNewPadding(padding);
    dispatch(setCardsPadding(padding));
    return { numCards, cardWidth, padding, remainingSpace };
  }
  const getWidth = () => {
    if (gridRef?.current) {
      calculateCardWidthAndPadding(gridRef?.current?.clientWidth);
    }
  };
  useLayoutEffect(() => {
    if (gridRef?.current) {
      calculateCardWidthAndPadding(gridRef?.current?.clientWidth);
    }
    window.addEventListener("resize", getWidth);
    return () => {
      window.removeEventListener("resize", getWidth);
    };
  }, [itemsFetched, leftExpanded]);

  useLayoutEffect(() => {
    activeStep !== 2 && handleCloseCamera();
    activeStep !== 2 && handleStopRecording();
  }, [activeStep]);

  useLayoutEffect(() => {
    window.addEventListener("resize", getNewWidth);
    return () => {
      window.removeEventListener("resize", getNewWidth);
    };
  }, []);

  const getVideo = async () => {
    // setLoading(true);
    const { payload } = await dispatch(getMyCamVideo({ user_id: "" }));
    if (payload?.status === "success") {
      if (payload?.data?.cam_url !== "NO URL") {
        // setVideoLink(payload?.data?.cam_url);
        setRecordedVideoData(payload?.data?.cam_url);
        setUserData(payload?.data?.user);
        setStep(4);
        await dispatch(getProfileProgressData());
      }
      if (payload?.data?.length === 0 || payload?.data?.cam_url === "NO URL") {
        setIsVideoUploaded(false);
      } else {
        setIsVideoUploaded(true);
      }
    } else if (payload === undefined) {
      return;
    }
    // setLoading(false);
  };
  useEffect(() => {
    getVideo();
  }, [activeStep]);

  useEffect(() => {
    if (width > 1024) {
      setLeftExpand(true);
    } else {
      setLeftExpand(false);
    }
  }, [width]);
  if (width === 0) {
    return null;
  }
  return (
    <Box
      sx={{
        display: "flex",
        height: {
          xs: `calc(100vh - 95px)`,
          sm: `calc(100vh - 120px )`,
          md: `calc(100vh - 120px)`,
          lg: `calc(100vh - 135px)`,
          xl: `calc(100vh - 155px)`,
          xxl: "calc(100vh - 175px)",
        },
      }}
    >
      <Box
        item
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: `${width >= 768 && "10px"}`,
          position: `${width < 768 ? "absolute" : "relative"}`,
          // width: leftExpanded
          //   ? { xs: "150px", mdl: "123px", mlg: "150px", xl: "150px" }
          //   : "30px",
          width: leftExpanded ? "125px" : "30px",
          minWidth: leftExpanded ? "125px" : "30px",
          // width: leftExpanded && "150px",
          zIndex: "30",
        }}
      >
        <Button
          variant="contained"
          sx={{
            position: "static",
            borderRadius: "0 10px 10px 0",
            minWidth: "30px",
            width: "30px",
            height: "45px",
            boxShadow: "0px 5px 10px #00000029",
          }}
          color="filterButtons"
          onClick={() => {
            setLeftExpand((prevState) => !prevState);
            dispatch(setFilterExpanded(!filterExpanded));
          }}
        >
          {leftExpanded ? <LeftArrow /> : <RightArrow />}
        </Button>

        {leftExpanded && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              height: {
                xs: `calc(100vh - 95px)`,
                sm: `calc(100vh - 120px )`,
                md: `calc(100vh - 120px)`,
                lg: `calc(100vh - 135px)`,
                xl: `calc(100vh - 155px)`,
                xxl: "calc(100vh - 175px)",
              },
              width: `${(width < 768 && width !==0 )&& "135px"}`,
              paddingTop: `${(width < 768 && width !==0 ) && "10px"}`,
              background: `${width > 0 && width < 768 && "#EBECF3"}`,
              boxShadow: `${
                width > 0 && width < 768 && "0px 5px 10px #00000029"
              }`,
              opacity: `${(width < 768 && width !==0 ) && "1"}`,
              borderRadius: `${(width < 768 && width !==0 ) && "10px"}`,
              overflowX: "hidden",
              overflowY: leftExpanded ? "scroll" : "unset",
            }}
          >
            <StyledButtonLeft
              onClick={() => setActiveStep(1)}
              variant={"contained"}
              color={activeStep === 1 ? "filterButtons" : "buttonColor"}
              sx={{
                // width: leftExpanded
                //   ? { xs: "150px", lg: "150px", mlg: "150px", xl: "150px" }
                //   : "30px",
                width: leftExpanded ? "125px" : "30px",
                minWidth: leftExpanded ? "125px" : "30px",
                borderRadius: "0 10px 10px 0",
                border: 0,
                fontWeight:
                  activeStep === 1
                    ? theme.typography.Bold
                    : theme.typography.Regular,
                "&:hover": {
                  backgroundColor: theme?.palette?.filterButtons?.main,
                  color: theme?.palette?.filterButtons?.contrastText,
                  fontWeight: theme.typography.Bold,
                },
              }}
            >
              {"Crayon cam"}
            </StyledButtonLeft>
            <StyledButtonLeft
              onClick={() => setActiveStep(2)}
              variant={"contained"}
              color={activeStep === 2 ? "precium" : "buttonColor"}
              sx={{
                borderRadius: "0 10px 10px 0",
                border: 0,
                // width: leftExpanded
                //   ? { xs: "150px", lg: "150px", mlg: "150px", xl: "150px" }
                //   : "30px",
                width: leftExpanded ? "125px" : "30px",
                minWidth: leftExpanded ? "125px" : "30px",
                fontWeight:
                  activeStep === 2
                    ? theme.typography.Bold
                    : theme.typography.Regular,
                "&:hover": {
                  backgroundColor: theme?.palette?.precium?.main,
                  color: theme?.palette?.precium?.contrastText,
                  fontWeight: theme.typography.Bold,
                },
              }}
            >
              {"my cam"}
            </StyledButtonLeft>
            <StyledButtonLeft
              onClick={() => setActiveStep(3)}
              variant={"contained"}
              color={activeStep === 3 ? "peachPayment" : "buttonColor"}
              sx={{
                mb: 1,
                borderRadius: "0 10px 10px 0",
                // width: leftExpanded
                //   ? { xs: "150px", lg: "150px", mlg: "150px", xl: "150px" }
                //   : "30px",
                width: leftExpanded ? "125px" : "30px",
                minWidth: leftExpanded ? "125px" : "30px",
                fontWeight:
                  activeStep === 3
                    ? theme.typography.Bold
                    : theme.typography.Regular,
                "&:hover": {
                  backgroundColor: theme?.palette?.peachPayment?.main,
                  color: theme?.palette?.peachPayment?.contrastText,
                  fontWeight: theme.typography.Bold,
                },
                border: 0,
              }}
            >
              {"job videos"}
            </StyledButtonLeft>
          </Box>
        )}
      </Box>
      <Box
        item
        // xs={12}
        // sm={6}
        // md={8}
        // lg={9}
        // xl={10}
        justifyContent={width < 768 && width !==0 && "center"}
        sx={{
          display: "flex",
          flexGrow: 1,
          alignItems: "center",
          padding:
            activeStep !== 3 && ((width < 768 && width !==0) ? "10px" : `0 30px`),
          // paddingLeft:activeStep !== 3 && (newPadding>30?"30px":newPadding),
            flexDirection: "column",

          width: {
            xs: `95%`,
            md24: `calc(100vw - 255px)`,
          },
          height:"auto",
          // minWidth: "360px",
          // width: `calc(100vh - 255px)`,
          position: "sticky",
        }}
        ref={gridRef}
        // gap={"54px"}
        // flexGrow="1 !important"
      >
        {activeStep === 1 && (
          <MyVideolist
            handleChangeStep={handleChangeStep}
            isVideoUploaded={isVideoUploaded}
            setIsVideoUploaded={setIsVideoUploaded}
            setActiveStep={setActiveStep}
          />
        )}
        {activeStep === 2 && (
          <VideoAddSteps
            handleStopRecording={handleStopRecording}
            handleCloseCamera={handleCloseCamera}
            setMediaStream={setMediaStream}
            mediaStream={mediaStream}
            mediaRecorderRef={mediaRecorderRef}
            step={step} 
            setStep={setStep}
            userData={userData} 
            setUserData={setUserData}
            recordedVideoData={recordedVideoData} 
            setRecordedVideoData={setRecordedVideoData}
            setActiveStep={setActiveStep}
          />
        )}
        {activeStep === 3 && (
          <CamList
            setActiveStep={setActiveStep}
            leftExpanded={leftExpanded}
            setItemsFetched={setItemsFetched}
            newCardWidth={newCardWidth}
            newPadding={newPadding}
            gridRef={gridRef}
          />
        )}
      </Box>
      {/* {leftExpanded && ( */}
        <Grid
          item
          sx={{
            display: "flex",
            flexDirection: "column",
            // position: `${(width < 768 && width !==0 ) ? "absolute" : "relative"}`,
            position: "relative",
            gap: "65px",
            direction: "rtl",
            width: "30px",
          }}
        ></Grid>
      {/* )} */}
    </Box>
  );
};

export default MyCam;
