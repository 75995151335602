import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import { useTheme } from "@mui/material/styles";
import pending from "../../../assets/Padding Excluded/Red_Pending.svg";
import pendingWhite from "../../../assets/Padding Excluded/White_Pending.svg";
import likeThis from "../../../assets/Padding Excluded/Blue_Like.svg";
import hired from "../../../assets/Padding Excluded/HiredIcon.svg";
import hiredWhite from "../../../assets/Padding Excluded/HiredIconWhite.svg";
import notForMe from "../../../assets/Padding Excluded/NotForMeIcon.svg";
import notForMeWhite from "../../../assets/Padding Excluded/NotForMeIconWhite.svg";
import likeThisWhite from "../../../assets/Padding Excluded/White_Like.svg";
import loveThis from "../../../assets/Padding Excluded/LoveThisIcon.svg";
import loveThisWhite from "../../../assets/Padding Excluded/LoveThisIconWhite.svg";
import reject from "../../../assets/Padding Excluded/RejectedIcon.svg";
import rejectWhite from "../../../assets/Padding Excluded/RejectedIconWhite.svg";
import { Typography } from "@mui/material";

export default function FiltersPanel({
  panelData,
  side,
  onChangeFilter = () => {},
}) {
  const theme = useTheme();
  const [selectedBtns, setSelectedBtn] = useState([panelData?.[0]?.id]);

  const [hoveredButton, setHoveredButton] = useState(null);

  useEffect(() => {
    setSelectedBtn([panelData?.[0]?.id]);
  }, [panelData]);

  const removeBtnSelection = (selectedBtnList, id) => {
    selectedBtnList?.splice(
      selectedBtns?.findIndex((selectedBtn) => selectedBtn == id),
      1
    );
  };
  const handleButtonClick = (event, { id, name, title }) => {
    if (title == true) {
      if (selectedBtns?.find((selectedBtn) => selectedBtn == id)) {
        const selectedBtnList = [...selectedBtns];
        id !== 1111 && removeBtnSelection(selectedBtnList, id);
        setSelectedBtn(selectedBtnList);
        onChangeFilter(selectedBtnList);
      } else {
        setSelectedBtn([panelData?.[0]?.id]);
        onChangeFilter([panelData?.[0]?.id]);
      }
    } else {
      if (selectedBtns?.find((selectedBtn) => selectedBtn == id)) {
        const selectedBtnList = [...selectedBtns];
        id !== 1111 && removeBtnSelection(selectedBtnList, id);
        if (selectedBtnList?.length == 0) {
          setSelectedBtn([panelData?.[0]?.id]);
          onChangeFilter([panelData?.[0]?.id]);
        } else {
          setSelectedBtn(selectedBtnList);
          onChangeFilter(selectedBtnList);
        }
      } else {
        if (
          selectedBtns?.find((selectedBtn) => selectedBtn == panelData?.[0]?.id)
        ) {
          const selectedBtnList = [...selectedBtns];
          id !== 1111 && removeBtnSelection(selectedBtnList, id);
          selectedBtnList?.push(id);
          setSelectedBtn(selectedBtnList);
          onChangeFilter(selectedBtnList);
        } else {
          setSelectedBtn((prevState) => [...prevState, id]);
          onChangeFilter([...selectedBtns, id]);
        }
      }
    }
  };

  const handleMouseOver = (name) => {
    setHoveredButton(name);
  };

  const handleMouseOut = () => {
    setHoveredButton(null);
  };

  const getFiltersImage = (hover, name) => {
    switch (name) {
      case "pending":
        return hover ? pendingWhite : pending;
      case "i like this":
        return hover ? likeThisWhite : likeThis;
      case "i love this":
        return hover ? loveThisWhite : loveThis;
      case "not for me":
        return hover ? notForMeWhite : notForMe;
      case "rejected":
        return hover ? rejectWhite : reject;
      case "hired":
        return hover ? hiredWhite : hired;
      default:
        break;
    }
  };

  const renderColor = (column) => {
    switch (column) {
      case "pending":
        return "tymeColor";
      case "i like this":
        return "yoco";
      case "i love this":
        return "safair";
      case "not for me":
        return "peachPayment";
      case "hired":
        return "springboks";
      case "rejected":
        return "nandos";
      default:
        return "filterButtons";
    }
  };

  return (
    <Box
      sx={{
        textAlign: side === "right" && "end",
        display: { xs: "none", sm: "flex" },
        flexDirection: "column",
        gap: "10px",
      }}
    >
      {panelData?.map((btn) => (
        <Tooltip
          disableInteractive
          title={btn?.name}
          key={btn?.id}
          placement="top-end"
        >
          <Button
            sx={{
              gap: "8px",
              justifyContent: btn?.name !== "My Status" && "space-between",
              padding: "6px 7px",
              lineHeight: "inherit",
              borderRadius: "10px",
              fontSize: theme.typography.fontSize.xs,
              borderBottomLeftRadius: side === "left" ? { sm: 0 } : "10px",
              borderTopLeftRadius: side === "left" ? { sm: 0 } : "10px",
              borderTopRightRadius: side === "right" ? { sm: 0 } : "10px",
              borderBottomRightRadius: side === "right" ? { sm: 0 } : "10px",
              mr: { xs: 1, sm: 0 },
              minWidth: { xs: "90px", sm: 0 },
              fontWeight:
                selectedBtns?.includes(btn?.id) || hoveredButton === btn?.name
                  ? theme.typography.Bold
                  : theme.typography.Regular,
              "&:hover": {
                backgroundColor: theme.palette[renderColor(btn?.name)]?.main,
                color: theme.palette.white,
                fontWeight: theme.typography.Bold,
              },
              height: "45px",
            }}
            onClick={(e) => {
              setHoveredButton(btn?.name);
              handleButtonClick(e, btn);
            }}
            disableElevation
            variant="contained"
            color={
              selectedBtns?.includes(btn?.id) ? renderColor(btn?.name) : "base"
            }
            onMouseOver={() => handleMouseOver(btn?.name)}
            onMouseOut={handleMouseOut}
          >
            {btn?.name !== "My Status" && (
              <Box
                component={"img"}
                src={getFiltersImage(
                  hoveredButton === btn?.name || selectedBtns?.includes(btn?.id),
                  btn?.name
                )}
                sx={{
                  height: "20px",
                  width: "20px",
                }}
              />
            )}
            {btn?.name !== "My Status" ? (
              <Typography
                sx={{
                  fontSize: theme.typography.fontSize.xs,
                  minWidth: "100px",
                  textAlign: "left",
                  color: (selectedBtns?.includes(btn?.id) || hoveredButton === btn?.name) && "white",
                  fontWeight: selectedBtns?.includes(btn?.id) || hoveredButton === btn?.name
                    ? theme.typography.Bold
                    : theme.typography.Regular,
                }}
              >
                {btn?.name}{" "}
                {btn?.name !== "My Status" && `(${btn?.statusCount})`}
              </Typography>
            ) : (
              String(btn?.name)?.toLowerCase()
            )}
          </Button>
        </Tooltip>
      ))}
    </Box>
  );
}
