import { Box, Button } from "@mui/material";
import React, { useEffect } from "react";

const TrackButtonLayout = ({ daysReamining,job }) => {
  daysReamining =isNaN(daysReamining)?0:daysReamining
  return (
    <Box
      sx={{
        display: "flex",
        paddingTop: 1,
        gap: 0.2,
      }}
    >
      <Button
        variant="contained"
        color={
          daysReamining === 0 
            ? "grayButton400"
            : daysReamining > 20
            ? "lightGreenButton300"
            : daysReamining >= 10
            ? "yellowButton100"
            : "grayButton400"
        }
        sx={{
          height: "6px",
          minWidth: 10,
          padding: "0px",
          borderRadius: "5px 0 0 5px",
        }}
      ></Button>
      <Button
        variant="contained"
        color={
          daysReamining === 0
            ? "grayButton400"
            : daysReamining > 20
            ? "lightGreenButton300"
            : daysReamining >= 10
            ? "yellowButton100"
            : "grayButton400"
        }
        sx={{
          height: "6px",
          minWidth: 10,
          padding: "0px",
          borderRadius: 0,
        }}
      ></Button>
      <Button
        variant="contained"
        color={
          daysReamining === 0
            ? "grayButton400"
            : daysReamining > 20
            ? "lightGreenButton300"
            : daysReamining >= 10
            ? "grayButton400"
            : "redButton"
        }
        sx={{
          height: "6px",
          minWidth: 10,
          padding: "0px",
          borderRadius: "0 5px 5px 0",
        }}
      ></Button>
    </Box>
  );
};

export default TrackButtonLayout;
