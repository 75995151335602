import React, { useState, useEffect, useCallback, Suspense } from "react";
import TalentFilters from "./TalentFilters";
import { useDispatch } from "react-redux";
import {
  getAdminTalentJobList,
  getTalentDatabases,
  getTalentPoolForSearch,
  getTalentsData,
} from "../../../../redux/admin/jobsSlice";
import { setAlert } from "../../../../redux/configSlice";
import { ALERT_TYPE, ERROR_MSG } from "../../../../utils/Constants";
import { Box } from "@mui/material";
import InfiniteScroll from "react-infinite-scroll-component";

import { debounce } from "../../../../utils/Common";
import { useParams } from "react-router-dom";
import { getCompanies } from "../../../../redux/employer/empProfileSlice";
const TalentsCard = React.lazy(() => import("../../../common/TalentsCard"));

const TalentsCommonComponent = ({ listName, poolDelete }) => {
  const BASIC = {
    lastKey: 0,
    applicantpool: false,
    followerpool: false,
    pool_id: "",
    job_id: "",
    stage: "",
  };
  const { id } = useParams();
  const dispatch = useDispatch();
  const [totalTalents, setTotalTalents] = useState(0);
  const [allTalents, setAllTalent] = useState([]);
  const [lastKey, setLastKey] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [basicData, setBasicData] = useState(BASIC);
  const [searchTitle, setSearchTitle] = useState("");
  const [selectedTalent, setSelectedTalent] = useState();
  const [showText, setShowText] = useState(false);
  // const [openFilter, setOpenFilter] = useState(false);
  const [databases, setDatabases] = useState([]);
  const [associatedJobs, setAssociatedJobs] = useState([]);
  const [poolData, setPoolData] = useState([]);
  const [filtersValue, setFiltersValue] = useState({
    quickSearch: "",
    talentStage: "",
    talentPool: "",
    job: "",
    talentStatus: "",
    databases: "",
  });
  const [stageArray, setStageArray] = useState([
    {
      id: 1,
      name: "incomplete",
    },
    {
      id: 2,
      name: "matched",
    },
    {
      id: 3,
      name: "review",
    },
    {
      id: 4,
      name: "considering",
    },
    {
      id: 5,
      name: "shortlist",
    },
    {
      id: 6,
      name: "interview",
    },
    {
      id: 7,
      name: "assessment",
    },
    {
      id: 8,
      name: "offer",
    },
    {
      id: 9,
      name: "hired",
    },
    {
      id: 10,
      name: "rejected",
    },
  ]);

  const getJobList = async (lastkeyy, searchtitle, newBasicData) => {
    const data = {
      ...newBasicData,
      mytalent: listName === "My Talent" && true,
      applicant: listName === "Applicants" && true,
      follower: listName === "Followers" && true,
    };
    const { payload } = await dispatch(
      getTalentsData({
        payload: data,
        lastKey: lastkeyy,
        query:
          JSON.parse(sessionStorage.getItem("searchTitle")) === null
            ? ""
            : JSON.parse(sessionStorage.getItem("searchTitle")),
        candidateID: id ? id : "",
      })
    );
    if (payload?.status === "success") {
      setTotalTalents(payload?.talentCount);
      if (payload?.data?.length <= 0 && payload?.pageNumber === 0) {
        setShowText(true);
        setAllTalent([]);
      } else {
        if (payload?.pageNumber === 0) {
          setAllTalent(
            payload?.data?.map((item) => ({
              currencyname: item?.currencyname,
              candidate_user_id: item?.candidate_user_id,
              first_name: item.first_name,
              last_name: item?.last_name,
              job_title: item?.jobTitle,
              dob: item?.dob,
              town_name: item?.town_name,
              country_name: item?.country_name,
              currency_symbol: item?.currencySymbol,
              salary_min: item?.salaryMin,
              salary_max: item?.salaryMax,
              experience: item?.experienceYearEnd,
              notice_period: item?.noticePeriod_description,
              email: item?.email,
              total_user_count: item?.TotalUserCount,
              total_user_shortlist: item?.totalusershorlisted,
              total_user_interview: item?.totaluserinterviewed,
              employement_type: item?.employment_type,
              work_setup: item?.work_setup,
              created_at: item?.created_at,
              maxInterviewStageName: item?.maxInterviewStageName,
              gender: item?.gender,
              ...item,
            }))
          );
          setLastKey(payload?.pageNumber + 1);
        } else {
          setLastKey(payload?.pageNumber + 1);
          setAllTalent((prevState) => [
            ...prevState,
            ...payload?.data?.map((item) => ({
              currencyname: item?.currencyname,
              candidate_user_id: item?.candidate_user_id,
              first_name: item.first_name,
              last_name: item?.last_name,
              job_title: item?.jobTitle,
              dob: item?.dob,
              town_name: item?.town_name,
              country_name: item?.country_name,
              currency_symbol: item?.currencySymbol,
              salary_min: item?.salaryMin,
              salary_max: item?.salaryMax,
              experience: item?.experienceYearEnd,
              notice_period: item?.noticePeriod_description,
              email: item?.email,
              total_user_count: item?.TotalUserCount,
              total_user_shortlist: item?.totalusershorlisted,
              total_user_interview: item?.totaluserinterviewed,
              employement_type: item?.employment_type,
              work_setup: item?.work_setup,
              created_at: item?.created_at,
              maxInterviewStageName: item?.maxInterviewStageName,
              gender: item?.gender,
              ...item,
            })),
          ]);
        }
        setShowText(false);
      }
    } else if (payload?.status === "error") {
      dispatch(
        setAlert({
          show: true,
          type: ALERT_TYPE?.ERROR,
          msg: payload?.message,
        })
      );
    }
  };

  const debouncedHandleOnTextSearch = useCallback(
    debounce(
      async (newValue, newBasicData) => {
        if (!newValue) return; // Early return
        try {
          // let newBasicData = {
          //   ...basicData,
          //   title: newValue?.target?.value,
          // };
          // setBasicData(newBasicData);
          const response = await getJobList(
            0,
            newValue?.target?.value,
            newBasicData
          );
          setSearchTitle(newValue?.target?.value);
          if (response && response?.payload) {
            const { payload } = response;
            dispatch(
              payload?.status === "success"
                ? setAllTalent(
                    payload?.data?.map((item) => ({
                      currencyname: item?.currencyname,
                      candidate_user_id: item?.candidate_user_id,
                      first_name: item?.first_name,
                      last_name: item?.last_name,
                      job_title: item?.jobTitle,
                      dob: item?.dob,
                      town_name: item?.town_name,
                      country_name: item?.country_name,
                      currency_symbol: item?.currencySymbol,
                      salary_min: item?.salaryMin,
                      salary_max: item?.salaryMax,
                      experience: item?.experienceYearEnd,
                      notice_period: item?.noticePeriod_description,
                      email: item?.email,
                      total_user_count: item?.TotalUserCount,
                      total_user_shortlist: item?.totalusershorlisted,
                      total_user_interview: item?.totaluserinterviewed,
                      employement_type: item?.employment_type,
                      work_setup: item?.work_setup,
                      created_at: item?.created_at,
                      maxInterviewStageName:
                        item?.maxInterviewStageName === null
                          ? "-"
                          : item?.maxInterviewStageName,
                      gender: item?.gender,
                      ...item,
                    }))
                  )
                : setAlert({
                    show: true,
                    type: ALERT_TYPE?.ERROR,
                    msg: "Error",
                  })
            );
          }
          // setOpenFilter(false);
        } catch (error) {
          dispatch(
            setAlert({ show: true, type: ALERT_TYPE?.ERROR, msg: ERROR_MSG })
          );
        }
      },
      1000, // Adjust delay as needed
      setIsLoading
    ),
    [dispatch, setAllTalent, setIsLoading]
  );

  const handleInputSearch = async (event) => {
    let newBasicData = {
      ...basicData,
      title: event?.target?.value,
    };
    // setBasicData(newBasicData);
    setFiltersValue({
      ...filtersValue,
      quickSearch: event?.target?.value,
    });
    debouncedHandleOnTextSearch(event, newBasicData);
  };

  const handleJobRoleChange = async (event) => {
    const {
      target: { value },
      target: { name },
      target: { id },
    } = event;
    const temp = stageArray.find((item) => item.id === value);

    let newBasicData = {
      ...basicData,
      stage: value === "" ? "" : [temp?.name] || "",
    };

    // setBasicData(newBasicData);
    setAllTalent([]);
    await getJobList("", searchTitle, newBasicData);
    setFiltersValue({
      ...filtersValue,
      talentStage: temp?.name,
    });
    // setOpenFilter(false);
  };

  const handleAssociatedJob = async (event) => {
    const {
      target: { value },
      target: { name },
      target: { id },
    } = event;

    let newBasicData = {
      ...basicData,
      job_id: value === "" ? "" : [value],
    };
    setBasicData(newBasicData);
    setAllTalent([]);
    await getJobList("", searchTitle, newBasicData);
    setFiltersValue({
      ...filtersValue,
      job: value,
    });
    // setOpenFilter(false);
  };
  const handleTalentPool = async (event) => {
    const {
      target: { value },
      target: { name },
      target: { id },
    } = event;
    let newBasicData = {
      ...basicData,
      pool_id: value === "" ? "" : value,
    };
    setBasicData(newBasicData);
    setAllTalent([]);
    await getJobList("", searchTitle, newBasicData);
    setFiltersValue({
      ...filtersValue,
      talentPool: value,
    });
    // setOpenFilter(false);
  };

  const handleJobStatus = async (event) => {
    const {
      target: { value },
      target: { name },
      target: { id },
    } = event;

    let newBasicData = {
      ...basicData,
      user_status: value === "" ? "" : value,
    };
    setBasicData(newBasicData);
    setAllTalent([]);
    await getJobList("", searchTitle, newBasicData);
    setFiltersValue({
      ...filtersValue,
      talentStatus: value,
    });
    // setOpenFilter(false);
  };

  const getDatabases = async (lastkeyy) => {
    try {
      const { payload } = await dispatch(
        getTalentDatabases({ lastKey: lastkeyy })
      );
      if (payload?.status === "success") {
        if (lastkeyy === 0) {
          setDatabases(
            payload.data.map((item, index) => {
              return {
                ...item,
                id: index,
                name: `${item?.user?.first_name} ${item?.user?.last_name}`,
              };
            })
          );
          setLastKey(payload.pageNumber + 1);
        } else {
          setLastKey(payload.pageNumber + 1);
          setDatabases((prevState) => [
            ...prevState,
            ...payload.data.map((item, index) => {
              return {
                ...item,
                id: index,
                name: `${item?.user?.first_name} ${item?.user?.last_name}`,
              };
            }),
          ]);
        }
      }
    } catch (error) {}
  };

  const handleOnSelectChange = async (ind, newValue, val, inputId, name) => {
    if (newValue === null) {
      setAssociatedJobs([]);
      setPoolData([]);
    }
    let filters = {
      ...filtersValue,
      [inputId]: newValue,
    };
    let newBasicData = {
      ...basicData,
      company_id:
        newValue !== null && inputId === "databases"
          ? [newValue?.value]
          : filters?.databases !== "" && filters?.databases !== null
          ? [filters?.databases?.value]
          : "",
      job_id:
        inputId === "job" && newValue !== null
          ? [newValue?.value]
          : filters?.job !== "" && filters?.job !== null
          ? [filters?.job?.value]
          : "",
      pool_id:
        inputId === "talentPool" && newValue !== null
          ? [newValue?.value]
          : filters?.talentPool !== "" && filters?.talentPool !== null
          ? [filters?.talentPool?.value]
          : "",
    };
    // setBasicData(newBasicData);
    setFiltersValue({
      ...filtersValue,
      [inputId]: newValue,
    });
    setAllTalent([]);
    await getJobList("", searchTitle, newBasicData);
    // setOpenFilter(false);
  };

  const debouncedHandleOnSelectInputChange = useCallback(
    debounce(
      async (newValue, type) => {
        let params = {
          title: newValue || "",
          limit: 200,
        };
        if (newValue) {
          try {
            if (type === "databases") {
              dispatch(getCompanies(params));
            }
            if (type === "job") {
              setAssociatedJobs([]);
              const { payload } = await dispatch(
                getAdminTalentJobList({ lastKey: 0, query: newValue })
              );
              if (payload?.status === "success") {
                setAssociatedJobs(payload?.data);
              }
            }
            if (type === "talentPool") {
              setPoolData([]);
              const { payload } = await dispatch(
                getTalentPoolForSearch({ lastKey: 0, query: newValue })
              );
              if (payload?.status === "success") {
                setPoolData(payload.data);
              }
            }
          } catch (error) {
            dispatch(
              setAlert({
                show: true,
                type: ALERT_TYPE.ERROR,
                msg: error,
              })
            );
          }
        }
      },
      750,
      setIsLoading
    ),
    []
  );

  const handleOnSelectInputChange = (newValue, inputId) => {
    debouncedHandleOnSelectInputChange(newValue, inputId);
  };

  useEffect(() => {
    sessionStorage.clear();
    getJobList(
      0,
      JSON.parse(sessionStorage.getItem("searchTitle")),
      JSON.parse(sessionStorage.getItem(`${listName}`))
    );
  }, []);

  return (
    <>
      <TalentFilters
        title={listName}
        totlaCount={totalTalents}
        // handleJobRoleChange={handleJobRoleChange}
        // handleInputSearch={handleInputSearch}
        stageArray={stageArray}
        // handleAssociatedJob={handleAssociatedJob}
        // handleTalentPool={handleTalentPool}
        // handleJobStatus={handleJobStatus}
        filtersValue={filtersValue}
        // setOpenFilter={setOpenFilter}
        // openFilter={openFilter}
        getDatabases={getDatabases}
        databases={databases}
        // handleOnSelectChange={handleOnSelectChange}
        // handleOnSelectInputChange={handleOnSelectInputChange}

        // setAssociatedJobs={setAssociatedJobs}
        // associatedJobs={associatedJobs}
        // setPoolData={setPoolData}
        // poolData={poolData}

        setAllTalent={setAllTalent}
        getJobList={getJobList}
      />
      <InfiniteScroll
        style={{ overflow: "hidden" }}
        dataLength={allTalents?.length}
        next={() =>
          getJobList(
            lastKey,
            JSON.parse(sessionStorage.getItem("searchTitle")),
            JSON.parse(sessionStorage.getItem(`${listName}`))
          )
        }
        scrollThreshold={"100px"}
        scrollableTarget={"jobList"}
        hasMore={true}
        endMessage={
          <p style={{ textAlign: "center" }}>
            <b>Yay! You have seen it all</b>
          </p>
        }
      >
        <Box sx={{ mt: "215px", mb: "16px" }}>
          {allTalents?.length > 0
            ? allTalents?.map((talent, index) => (
                <Suspense fallback={null}>
                  <TalentsCard
                    talentContent={talent}
                    key={index}
                    allTalent={allTalents}
                    getJobList={getJobList}
                    setAllTalent={setAllTalent}
                    telId={talent?.candidate_user_id}
                    poolDelete={poolDelete}
                  />
                </Suspense>
              ))
            : showText && (
                <Box
                  sx={{
                    width: "100%",
                    textAlign: "center",
                    mt: 4,
                    color: "black",
                  }}
                >
                  No Talent in this pool
                </Box>
              )}
        </Box>
      </InfiniteScroll>
    </>
  );
};

export default TalentsCommonComponent;
