import React, { useCallback, useEffect, useRef, useState } from "react";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import DraggableCard from "./DraggableCardNew";
import {
  changeJobApplicationStatus,
  getTalentJobStatusApplications,
  getTalentJobStatusCount,
  getTalentMatchData,
  runMatch,
} from "../../../redux/employer/myJobsSlice";
import { useDispatch } from "react-redux";
import {
  setAlert,
  setLoading,
} from "../../../redux/employer/employerJobsConfigSlice";
import {
  ALERT_TYPE,
  BASIC_MANAGE_TALENT,
  ERROR_MSG,
  SKINZ,
  TALENT_LEFT_JOB_RESPONSE_BUTTON_GROUP,
} from "../../../utils/Constants";
import InfiniteScroll from "react-infinite-scroll-component";
import SortButton from "./SortButton";
import { useNavigate, useParams } from "react-router-dom";
import { Button, CircularProgress, Grid, Paper, Snackbar } from "@mui/material";
import {
  TALENT_LEFT_JOB_APPLICATION_BUTTON_GROUP,
  TALENT_RIGHT_JOB_INFO_BUTTON_GROUP,
  TALENT_RIGHT_JOB_ACTIVITY_BUTTON_GROUP,
  JOBS_LEFT_TYPES_BUTTON_GROUP,
} from "../../../utils/Constants";
import ButtonPanel from "../../common/ButtonPanel";
import CardsTopBar from "./DraggableCardComponents/CardsTopBar";
import LeftArrow from "../../common/LeftArrow";
import RightArrow from "../../common/RightArrow";
import LeftMenu from "../../admin/LeftDrawer";
import EmployerButtonPanel from "../../common/EmployerButtonPanel";
import {
  getDecodedToken,
  getToken,
  renderColor,
  useQuery,
} from "../../../utils/Common";
import { getRequiredQualification } from "../../../redux/employer/postJobSlice";
import { useSelector } from "react-redux";
import RefreshIcon from "@mui/icons-material/Refresh";
import MuiAlert from "@mui/material/Alert";
import { StyledBoxManageTalent } from "../../../utils/CommonComponent";
import SubscriptionPopModal from "../../guest/talent/SubscriptionPopModal";
import _ from "lodash";
import RunMatchBox from "./RunMatchBox";
import BoardColumn from "./BoardColumn";
import LeftFilterButton from "../LeftFilterButton";
import RightFilterButton from "../RightFilterButton";

export default function ManageJob() {
  const dispatch = useDispatch();
  const theme = useTheme();
  const query = useQuery();
  const navigate = useNavigate();
  let decodedToken = getDecodedToken(getToken());
  const planType = decodedToken?.data?.talent_plan;
  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const selectedJobApplication = useRef([]);
  const selectedResponse = useRef([]);
  const selectedJobActivity = useRef([]);
  const selectedJobType = useRef([]);
  const selectedJobTalent = useRef([]);
  const { requiredQua } = useSelector((state) => state?.postJobs);

  const [isLoading, setIsLoading] = useState(false);
  const [randomLoading, setRandomLoading] = useState({
    incomplete: true,
    matched: true,
    review: true,
    considering: true,
    shortlist: true,
    interview: true,
    assessment: true,
    offer: true,
    hired: true,
    rejected: true,
  });
  const [isFilterApplied, setIsFilterApplied] = useState(true);
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [hasMore, setHasMore] = useState(false);
  const [talentStatus, setTalentStatus] = useState([]);
  const [jobDetail, setJobDetail] = useState([]);
  const { jobId } = useParams();
  const [leftExpanded, setLeftExpand] = useState(false);
  const [rightExpanded, setRightExpanded] = useState(false);
  const [leftMenu, setLeftMenu] = useState(false);
  const [basicData, setBasicData] = useState(BASIC_MANAGE_TALENT);
  const [isForbidden, setIsForbidden] = useState(false);
  const [gender, setGender] = useState("");
  const [qualification, setQualification] = useState("");
  const [skinColor, setSkinColor] = useState([]);
  const [isSubscriptionModal, setIsSubscriptionModal] = useState(false);
  const [talents, setTalents] = useState([]);
  const [salaryRange, setSalaryRange] = useState([]);
  const [experienceRange, setExperienceRange] = useState([]);
  const basicDataRef = useRef(basicData);
  const topBoxRef = useRef(null);
  const bottomBoxRef = useRef(null);
  const [boxWidth, setBoxWidth] = useState(0);
  const [showRunMatch, setShowRunMatch] = useState(false);
  const [selectedBtns, setSelectedBtn] = useState([]);
  const [isClear, setIsClear] = useState(false);
  const [selectedTab, setSelectedTab]= useState()
  const handleScroll = (event, scrollElement) => {
    if (topBoxRef?.current && bottomBoxRef?.current) {
      // Synchronize horizontal scroll
      scrollElement.current.scrollLeft = event?.target?.scrollLeft;
    }
  };

  let width = 0;
  const calculateWidth = () => {
    width = width + (leftMenu ? 80 : 80);
    width = width + (!leftExpanded ? 125 : 0);
    width += !rightExpanded ? 125 : 0;
    width += !leftExpanded ? 30 : 10;
    setBoxWidth(width);
  };

  useEffect(() => {
    calculateWidth();
  }, [leftMenu, leftExpanded, rightExpanded]);

  const getQualificationData = async () => {
    try {
      await dispatch(getRequiredQualification());
    } catch (error) {
      dispatch(setLoading(false));
      dispatch(
        setAlert({
          show: true,
          type: ALERT_TYPE?.ERROR,
          msg: ERROR_MSG,
        })
      );
    }
  };

  useEffect(() => {
    getQualificationData();
  }, []);

  const onFilterSelection = () => {
    setRandomLoading({
      incomplete: true,
      matched: true,
      review: true,
      considering: true,
      shortlist: true,
      interview: true,
      assessment: true,
      offer: true,
      hired: true,
      rejected: true,
    });
    setTalents((prevTalents) => {
      const updatedTalents = prevTalents?.map((item) => {
        return {
          ...item,
          items: [],
          count: prevTalents?.totalData,
          pageNumber: prevTalents?.pageNumber,
        };
      });
      return [...updatedTalents];
    });
  };

  const getTalentStatusApplications = async (
    jobId,
    jobStatusId,
    manageStatus,
    item,
    pageNumber
  ) => {
    try {
      const data = {
        job_id: jobId,
        job_status_id: jobStatusId,
        ...basicDataRef?.current,
      };
      const [manage] = await Promise.all([
        dispatch(
          getTalentJobStatusApplications({ pageNumber: 0, payload: data })
        ),
      ]);

      setRandomLoading((prev) => ({ ...prev, [item?.status]: false }));

      // lastkey += 1;
      setTalents((prevTalents) => {
        const updatedTalents = manageStatus?.map((item) => {
          if (item?.id === jobStatusId) {
            return {
              ...item,
              items: manage?.payload?.data,
              count: manage?.payload?.totalData,
              pageNumber: manage?.payload?.pageNumber,
            };
          } else {
            const existingItem = prevTalents.find(
              (prevItem) => prevItem?.id === item?.id
            );
            return {
              ...item,
              items: existingItem?.items ? existingItem?.items : [],
              count: existingItem?.count ? existingItem?.count : 0,
              pageNumber: existingItem?.pageNumber
                ? existingItem?.pageNumber
                : 0,
            };
          }
        });
        return [...updatedTalents];
      });
    } catch (error) {
      setIsLoading(false);
      setRandomLoading((prev) => ({ ...prev, [item?.status]: true }));
      dispatch(
        setAlert({
          show: true,
          type: ALERT_TYPE?.ERROR,
          msg: ERROR_MSG,
        })
      );
    }
  };
  const getMatchTalent = async (
    jobId,
    jobStatusId,
    manageStatus,
    pageNumber
  ) => {
    setRandomLoading((prev) => ({ ...prev, matched: true }));
    try {
      const data = {
        job_id: jobId,
        job_status_id: jobStatusId,
        ...basicDataRef?.current,
      };
      const [manage] = await Promise.all([
        dispatch(getTalentMatchData({ pageNumber: 0, payload: data })),
      ]);
      manageStatus &&
        setTalents((prevTalents) => {
          const updatedTalents = manageStatus?.map((item) => {
            if (item?.id === jobStatusId) {
              return {
                ...item,
                items: manage?.payload?.data,
                count: manage?.payload?.totalData,
                pageNumber: manage?.payload?.pageNumber,
              };
            } else {
              const existingItem = prevTalents?.find(
                (prevItem) => prevItem?.id === item?.id
              );
              return {
                ...item,
                items: existingItem?.items ? existingItem?.items : [],
                count: existingItem?.count ? existingItem?.count : 0,
                pageNumber: existingItem?.pageNumber
                  ? existingItem?.pageNumber
                  : 0,
              };
            }
          });
          return [...updatedTalents];
        });
      setRandomLoading((prev) => ({ ...prev, matched: false }));
    } catch (error) {
      setRandomLoading((prev) => ({ ...prev, matched: false }));
      dispatch(
        setAlert({
          show: true,
          type: ALERT_TYPE?.ERROR,
          msg: ERROR_MSG,
        })
      );
    }
  };

  const getNextData = async (
    jobId,
    jobStatusId,
    manageStatus,
    pageNumber,
    columnStatus
  ) => {
    const data = {
      job_id: jobId,
      job_status_id: jobStatusId,
      ...basicDataRef?.current,
      genderType: query?.get("gender")
        ? query?.get("gender")
        : basicDataRef?.current?.genderType,
      skin_id: query?.get("skinz")
        ? query?.get("skinz")
        : basicDataRef?.current?.skin_id,
      qualification_id: query?.get("qualification")
        ? query?.get("qualification")
        : basicDataRef?.current?.qualification_id,
      salary: query?.get("salary")
        ? query
            ?.get("salary")
            ?.split(",")
            ?.map((str) => Number(str))
        : basicDataRef?.current?.salary,
      experience: query?.get("experience")
        ? query
            ?.get("experience")
            ?.split(",")
            ?.map((str) => Number(str))
        : basicDataRef?.current?.experience,
    };
    const [manage] = await Promise.all([
      jobStatusId === 1
        ? dispatch(
            getTalentMatchData({
              pageNumber,
              payload: data,
            })
          )
        : dispatch(
            getTalentJobStatusApplications({
              pageNumber,
              payload: data,
            })
          ),
    ]);
    setTalents((prevTalents) => {
      const updatedTalents = prevTalents?.map((item) => {
        if (item?.id === jobStatusId) {
          return {
            ...item,
            items: [...item?.items, ...manage?.payload?.data],
            count: manage?.payload?.totalData,
            pageNumber: manage?.payload?.pageNumber,
          };
        } else {
          const existingItem = prevTalents?.find(
            (prevItem) => prevItem?.id === item?.id
          );
          return {
            ...item,
            items: existingItem ? existingItem?.items : [],
            count: existingItem ? existingItem?.count : 0,
            pageNumber: existingItem ? existingItem?.pageNumber : 0,
          };
        }
      });
      return updatedTalents;
    });
    // console.log({ jobId, jobStatusId, manageStatus, pageNumber, columnStatus });
  };

  const getTalentMyJobStatusCount = async (jobId) => {
    setIsLoading(true);
    try {
      const [manage] = await Promise.all([
        dispatch(getTalentJobStatusCount({ job_id: jobId })),
      ]);
      setJobDetail(manage?.payload?.jobdetail);
      setTalentStatus(manage?.payload?.data);

      const filteredData = manage?.payload?.data?.filter((item) => {
        if (
          selectedJobApplication?.current?.at(0) === 2 &&
          selectedJobApplication?.current?.length === 1
        ) {
          setRandomLoading((prev) => ({
            ...prev,
            [item?.status]: true,
            incomplete: false,
            matched: false,
          }));
          return item?.id !== 0 && item?.id !== 1;
        } else if (
          selectedJobApplication?.current?.includes(3) &&
          selectedJobApplication?.current?.includes(2)
        ) {
          setRandomLoading((prev) => ({
            ...prev,
            [item?.status]: true,
            matched: false,
          }));
          return item?.id !== 1;
        } else if (
          selectedJobApplication?.current?.at(0) === 3 &&
          selectedJobApplication?.current?.length === 1
        ) {
          setRandomLoading((prev) => ({
            ...prev,
            [item?.status]: true,
            matched: false,
            review: false,
            considering: false,
            shortlist: false,
            interview: false,
            assessment: false,
            offer: false,
            hired: false,
            rejected: false,
          }));
          return item?.id === 0;
        }
        return true;
      });
      filteredData?.map((item) => {
        if (item?.count > 0 || item?.id === 1) {
          getTalentStatusApplications(
            jobId,
            item?.id,
            manage?.payload?.data,
            item
          );
        } else {
          setRandomLoading((prev) => ({ ...prev, [item?.status]: false }));
          setTalents((prevTalents) => {
            const updatedTalents = manage?.payload?.data?.map((item) => {
              const existingItem = prevTalents?.find(
                (prevItem) => prevItem?.id === item?.id
              );
              return {
                ...item,
                items: existingItem ? existingItem?.items : [],
                count: existingItem?.count ? existingItem?.count : 0,
                pageNumber: existingItem?.pageNumber
                  ? existingItem?.pageNumber
                  : 0,
              };
            });
            return [...updatedTalents];
          });
        }
      });

      if (
        selectedJobApplication?.current?.at(0) === 2 &&
        selectedJobApplication?.current?.length === 1
      ) {
        setRandomLoading((prev) => ({ ...prev, matched: false }));
        return;
      } else if (
        selectedJobApplication?.current?.at(0) === 3 &&
        selectedJobApplication?.current?.length === 1
      ) {
        setRandomLoading((prev) => ({ ...prev, matched: false }));
        return;
      } else {
        // await getMatchTalent(jobId, 1, manage?.payload?.data);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      dispatch(setLoading(false));
      dispatch(
        setAlert({
          show: true,
          type: ALERT_TYPE?.ERROR,
          msg: ERROR_MSG,
        })
      );
    }
  };

  const talentStatuscount = async () => {
    try {
      const [manage] = await Promise.all([
        dispatch(getTalentJobStatusCount({ job_id: jobId })),
      ]);
      setJobDetail(manage?.payload?.jobdetail);
    } catch (error) {}
  };

  const handleMoveJobApplicationStatus = async (
    jobId,
    destinationColumn,
    draggableColumn
  ) => {
    try {
      const [manage] = await Promise.all([
        dispatch(
          changeJobApplicationStatus({
            job_id: jobId,
            job_status_id: destinationColumn?.id,
            candidateuser_id: draggableColumn?.user_id,
          })
        ),
      ]);
      if (manage?.payload?.data?.at(0) === 1) {
        if (isFilterApplied) {
          talentStatuscount();
        }
      }
    } catch (error) {
      dispatch(setLoading(false));
      dispatch(
        setAlert({
          show: true,
          type: ALERT_TYPE?.ERROR,
          msg: ERROR_MSG,
        })
      );
    }
  };

  useEffect(() => {
    let timer;
    if (isForbidden) {
      timer = setTimeout(() => {
        setIsForbidden(false);
      }, 3000);
    }
    return () => clearTimeout(timer);
  }, [isForbidden]);

  const onDragEnd = (result) => {
    if (!result?.destination) return;
    if (
      result?.destination?.droppableId === "0" ||
      result?.destination?.droppableId === "1" || 
      result?.destination?.droppableId === "2"
    ) {
      setIsForbidden(true);
      return;
    }
    const { source, destination, draggableId } = result;
    if (source.droppableId !== destination?.droppableId) {
      const sourceColumn = talents?.find(
        (item) => item?.id === Number(source?.droppableId)
      );
      const destinationColumn = talents?.find(
        (item) => item?.id === Number(destination?.droppableId)
      );

      const draggableColumn = talents
        ?.find((item) => item?.id === Number(source?.droppableId))
        ?.items?.find((item) => item?.user_id === Number(draggableId));

      const talentAfterDragEnd = talents?.map((talent) => {
        if (talent?.id === sourceColumn?.id) {
          return {
            ...talent,
            items: talent?.items?.filter(
              (item) => item?.user_id !== draggableColumn?.user_id
            ),
            count: talent?.count - 1,
            pageNumber: talent?.pageNumber,
          };
        }
        if (talent?.id === destinationColumn?.id) {
          return {
            ...talent,
            items: [draggableColumn, ...talent?.items],
            count: talent?.count + 1,
            pageNumber: talent?.pageNumber,
          };
        }
        return talent;
      });
      setTalents(talentAfterDragEnd);
      handleMoveJobApplicationStatus(jobId, destinationColumn, draggableColumn);
    }
  };

  const handleDropDownFilters = async () => {
    const range = [salaryRange[0] * 2000, salaryRange[1] * 2000];

    setBasicData((prevBasicData) => ({
      ...prevBasicData,
      genderType: gender,
      qualification_id: qualification !== "" ? qualification?.toString() : "",
      skin_id: skinColor?.length > 0 ? skinColor?.toString() : "",
      salary:
        query?.get("salary") !== null
          ? query
              ?.get("salary")
              ?.split(",")
              ?.map((str) => parseInt(str, 10))
          : salaryRange.length > 0
          ? range
          : [],
      experience:
        query?.get("experience") !== null
          ? query
              ?.get("experience")
              ?.split(",")
              ?.map((str) => parseInt(str, 10))
          : experienceRange,
    }));
  };

  useEffect(() => {
    requiredQua?.length > 0 && getTalentMyJobStatusCount(jobId);
  }, [requiredQua]);

  useEffect(() => {
    if (
      gender ||
      qualification ||
      skinColor?.length ||
      salaryRange?.length ||
      experienceRange?.length
    ) {
      handleDropDownFilters();
    }
  }, [gender, qualification, skinColor, salaryRange, experienceRange]);

  useEffect(() => {
    // Update the ref whenever the state changes
    basicDataRef.current = basicData;
  }, [basicData]);

  const handleSortedValue = (columnName, value, status) => {
    // console.log({ columnName, value, status });
    setTalents((prevTalents) => {
      const updatedTalents = prevTalents?.map((item) => {
        if (item?.id === columnName) {
          return {
            ...item,
            items: value?.data,
            count: value?.totalData,
            pageNumber: value?.pageNumber,
          };
        } else {
          const existingItem = prevTalents?.find(
            (prevItem) => prevItem?.id === item?.id
          );
          return {
            ...item,
            items: existingItem ? existingItem?.items : [],
            count: existingItem ? existingItem?.count : 0,
            pageNumber: existingItem ? existingItem?.pageNumber : 0,
          };
        }
      });
      // console.log({ updatedTalents });
      return [...updatedTalents];
    });
    setRandomLoading((prev) => ({ ...prev, [status]: false }));
    setHasMore(false);
  };

  const handleFilterSelection = (paramType, filterName) => {
    if (
      filterName[0] === "all applications" ||
      filterName[0] === "all responses" ||
      filterName[0] === "all talent" ||
      filterName[0] === "all activity" ||
      filterName[0] === "all types" ||
      filterName === "gender" ||
      filterName === "skinz" ||
      filterName === "qualification"
    ) {
      filterName[0] === "all applications" &&
        (selectedJobApplication.current = []);
      filterName[0] === "all responses" && (selectedResponse.current = []);
      filterName[0] === "all talent" && (selectedJobTalent.current = []);
      filterName === "gender" &&
        (selectedJobTalent.current = selectedBtns?.filter(
          (item) => item !== 8
        ));
      filterName === "skinz" &&
        (selectedJobTalent.current = selectedBtns?.filter(
          (item) => item !== 9
        ));
      filterName === "qualification" &&
        (selectedJobTalent.current = selectedBtns?.filter(
          (item) => item !== 11
        ));
      filterName[0] === "all activity" && (selectedJobActivity.current = []);
      filterName[0] === "all types" && (selectedJobType.current = []);

      let queryParams = new URLSearchParams(window.location.search);
      queryParams.delete(paramType); // Remove the parameter from the query string
      if (paramType === "all talent" && queryParams?.size > 0) {
        setIsClear(true);
        queryParams = "";
      }
      navigate(`/employer/manage-talent/${jobId}?${queryParams.toString()}`);
      return;
    } else {
      const updatedFilters = selectedFilters?.includes(filterName)
        ? selectedFilters?.filter((filter) => filter !== filterName)
        : [filterName];
      const queryParams = new URLSearchParams(window?.location?.search);
      queryParams?.set(paramType, updatedFilters?.join(","));
      navigate(`${window?.location?.pathname}?${queryParams?.toString()}`);
      setSelectedFilters(updatedFilters);
    }
  };

  const talentRightFIlters = async (selectedFilter) => {
    let jobs = [];
    selectedFilter?.map((type) => {
      let selectedJobType = TALENT_RIGHT_JOB_INFO_BUTTON_GROUP?.find(
        (jobtype) => jobtype.id === type
      );
      jobs?.push(selectedJobType?.name);
    });
    onFilterSelection();
    setIsFilterApplied(true);

    setBasicData((prevBasicData) => ({
      ...prevBasicData,
      jobtype_id: jobs[0] === "all talent" ? "" : jobs?.toString(),
    }));
    handleFilterSelection("all talent", jobs);
    await getTalentMyJobStatusCount(jobId);
  };

  const onChangeFilterType = async (selectedFilter) => {
    let talenttype = [];
    selectedFilter?.map((type) => {
      let selectedJobTypes = JOBS_LEFT_TYPES_BUTTON_GROUP?.find(
        (jobtype) => jobtype?.id === type
      );
      talenttype?.push(selectedJobTypes?.name);
    });
    onFilterSelection();
    setIsFilterApplied(true);
    setBasicData((prevBasicData) => ({
      ...prevBasicData,
      personalitytype_id:
        selectedFilter[0] === "all types" || selectedFilter[0] === 1111
          ? ""
          : selectedFilter?.toString(),
    }));

    selectedJobType.current = selectedFilter;

    handleFilterSelection("all types", talenttype);

    await getTalentMyJobStatusCount(jobId);
  };

  const reRunMatch = async () => {
    try {
      const { payload } = await dispatch(runMatch({ job_id: jobId }));
      if (payload?.status === "success") {
        // selectedJobApplication.current = [];
        // setRandomLoading({
        //   incomplete: true,
        //   matched: true,
        //   review: true,
        //   considering: true,
        //   shortlist: true,
        //   interview: true,
        //   assessment: true,
        //   offer: true,
        //   hired: true,
        //   rejected: true,
        // });
        // setTalents([]);
        setShowRunMatch(true);
        // await getTalentMyJobStatusCount(jobId);
      }
    } catch (error) {
      dispatch(
        setAlert({
          show: true,
          type: ALERT_TYPE?.ERROR,
          msg: ERROR_MSG,
        })
      );
    }
  };

  const activityFilter = async (selectedFilter) => {
    let jobactivity = [];
    selectedFilter?.map((type) => {
      let selectedJobActivities = TALENT_RIGHT_JOB_ACTIVITY_BUTTON_GROUP?.find(
        (jobactivity) => jobactivity?.id === type
      );
      jobactivity?.push(selectedJobActivities?.name);
    });
    onFilterSelection();
    setIsFilterApplied(true);

    setBasicData((prev) => ({
      ...prev,
      recent: selectedFilter?.includes(2) ? true : false,
      active: selectedFilter?.includes(3) ? true : false,
      InDemand: selectedFilter?.includes(4) ? true : false,
      favourite: selectedFilter?.includes(5) ? true : false,
      mytalent: selectedFilter?.includes(6) ? true : false,
    }));
    selectedJobActivity.current = selectedFilter;

    handleFilterSelection("all activity", jobactivity);

    await getTalentMyJobStatusCount(jobId);
  };

  const applicationFilter = async (selectedFilter) => {
    let jobapplication = [];
    selectedFilter?.map((type) => {
      let selectedJobType = TALENT_LEFT_JOB_APPLICATION_BUTTON_GROUP?.find(
        (jobtype) => jobtype?.id === type
      );
      jobapplication?.push(selectedJobType?.name);
    });
    onFilterSelection();
    setIsFilterApplied(true);
    setBasicData((prev) => ({
      ...prev,
      video: selectedFilter?.includes(4) ? true : false,
      crayon_cam: selectedFilter?.includes(5) ? true : false,
      portfolio: selectedFilter?.includes(6) ? true : false,
    }));

    selectedJobApplication.current = selectedFilter;
    handleFilterSelection("all applications", jobapplication);
    await getTalentMyJobStatusCount(jobId);
  };

  const responseFilter = async (selectedFilter) => {
    let jobResponse = [];
    selectedFilter?.map((type) => {
      let selectedJobType = TALENT_LEFT_JOB_RESPONSE_BUTTON_GROUP?.find(
        (jobtype) => jobtype?.id === type
      );
      jobResponse?.push(selectedJobType?.name);
    });
    onFilterSelection();
    setIsFilterApplied(true);
    setBasicData((prev) => ({
      ...prev,
      candidate_status_type: selectedFilter,
    }));

    selectedResponse.current = selectedFilter;
    handleFilterSelection("all responses", jobResponse);
    await getTalentMyJobStatusCount(jobId);
  };

  useEffect(() => {
    let jobapplication = [];
    let jobresponse = [];
    let jobtalent = [];
    let jobactivity = [];
    let jobtype = [];
    let jobskinz = "";
    let jobtypestr = "";
    let jobqualification = "";
    let data = {};

    query
      ?.get("all applications")
      ?.split(",")
      ?.map((type) => {
        let selecteJobApplication =
          TALENT_LEFT_JOB_APPLICATION_BUTTON_GROUP?.find(
            (jobtype) => jobtype.name === type
          );
        jobapplication?.push(selecteJobApplication?.id);
      });
    selectedJobApplication.current = jobapplication;

    query
      ?.get("all responses")
      ?.split(",")
      ?.map((type) => {
        let selecteJobApplication = TALENT_LEFT_JOB_RESPONSE_BUTTON_GROUP?.find(
          (jobtype) => jobtype?.name === type
        );
        jobresponse?.push(selecteJobApplication?.id);
      });
    selectedResponse.current = jobresponse;

    query
      ?.get("all talent")
      ?.split(",")
      ?.map((type) => {
        let selectedJobTalents = TALENT_RIGHT_JOB_INFO_BUTTON_GROUP?.find(
          (jobtype) => jobtype.name === type
        );
        jobtalent?.push(selectedJobTalents?.id);
      });
    selectedJobTalent.current = jobtalent;

    query
      ?.get("gender")
      ?.split(",")
      ?.map((type) => {
        let selectedJobTalents = TALENT_RIGHT_JOB_INFO_BUTTON_GROUP?.find(
          (jobtype) => jobtype.name === "gender"
        );
        jobtalent?.push(selectedJobTalents?.id);
      });
    selectedJobTalent.current = jobtalent;

    query
      ?.get("skinz")
      ?.split(",")
      ?.map((type) => {
        let selectedJobTalents = TALENT_RIGHT_JOB_INFO_BUTTON_GROUP?.find(
          (jobtype) => jobtype.name === "race"
        );
        jobtalent?.push(selectedJobTalents?.id);
      });
    selectedJobTalent.current = jobtalent;

    query
      ?.get("qualification")
      ?.split(",")
      ?.map((type) => {
        let selectedJobTalents = TALENT_RIGHT_JOB_INFO_BUTTON_GROUP?.find(
          (jobtype) => jobtype.name === "qualification"
        );
        jobtalent?.push(selectedJobTalents?.id);
      });
    selectedJobTalent.current = jobtalent;

    query
      ?.get("salary")
      ?.split(",")
      ?.map((type) => {
        let selectedJobTalents = TALENT_RIGHT_JOB_INFO_BUTTON_GROUP?.find(
          (jobtype) => jobtype.name === "salary"
        );
        jobtalent?.push(selectedJobTalents?.id);
      });
    selectedJobTalent.current = jobtalent;

    query
      ?.get("experience")
      ?.split(",")
      ?.map((type) => {
        let selectedJobTalents = TALENT_RIGHT_JOB_INFO_BUTTON_GROUP?.find(
          (jobtype) => jobtype.name === "experience"
        );
        jobtalent?.push(selectedJobTalents?.id);
      });
    selectedJobTalent.current = jobtalent;

    query
      ?.get("skinz")
      ?.split(",")
      ?.map((type) => {
        let selectedJobTalents = SKINZ?.find(
          (jobtype) => jobtype.name === type
        );
        jobskinz = jobskinz + String(selectedJobTalents?.id) + ",";
      });

    query
      ?.get("qualification")
      ?.split(",")
      ?.map((type) => {
        let selectedJobTalents = requiredQua?.find(
          (jobtype) => jobtype.name === type
        );
        jobqualification =
          jobqualification + String(selectedJobTalents?.id) + ",";
      });

    query
      ?.get("all activity")
      ?.split(",")
      ?.map((type) => {
        let selectedJobActivities =
          TALENT_RIGHT_JOB_ACTIVITY_BUTTON_GROUP?.find(
            (jobtype) => jobtype.name === type
          );
        jobactivity?.push(selectedJobActivities?.id);
      });
    selectedJobActivity.current = jobactivity;

    query
      ?.get("all types")
      ?.split(",")
      ?.map((type) => {
        let selectedJobTypes = JOBS_LEFT_TYPES_BUTTON_GROUP?.find(
          (jobtypes) => jobtypes.name === type
        );
        jobtype?.push(selectedJobTypes?.id);
        jobtypestr = jobtypestr + String(selectedJobTypes?.id) + ",";
      });
    selectedJobType.current = jobtype;

    data = {
      ...basicDataRef?.current,
      video: selectedJobApplication?.current?.includes(4) ? true : false,
      crayon_cam: selectedJobApplication?.current?.includes(5) ? true : false,
      portfolio: selectedJobApplication?.current?.includes(6) ? true : false,
      recent: selectedJobActivity?.current?.includes(2) ? true : false,
      active: selectedJobActivity?.current?.includes(3) ? true : false,
      InDemand: selectedJobActivity?.current?.includes(4) ? true : false,
      favourite: selectedJobActivity?.current?.includes(5) ? true : false,
      mytalent: selectedJobActivity?.current?.includes(6) ? true : false,
      // response: jobapplication,
      candidate_status_type: jobresponse,
      personalitytype_id: jobtypestr?.slice(0, -1),
      jobtype_id:
        query?.get("all talent") !== null ? query?.get("all talent") : "",
      genderType: query?.get("gender") !== null ? query?.get("gender") : "",
      skin_id: query?.get("skinz") !== null ? jobskinz?.slice(0, -1) : "",
      qualification_id:
        query?.get("qualification") !== null
          ? jobqualification?.slice(0, -1)
          : "",
      salary:
        query?.get("salary") !== null
          ? query
              ?.get("salary")
              ?.split(",")
              ?.map((str) => parseInt(str, 10))
          : [],
      experience:
        query?.get("experience") !== null
          ? query
              ?.get("experience")
              ?.split(",")
              ?.map((str) => parseInt(str, 10))
          : [],
    };
    basicDataRef.current = data;
  }, [requiredQua, query.size]);

  const shouldUpdateListProps = (prevProps, nextProps) => {
    return (
      prevProps.isDraggingOver !== nextProps.isDraggingOver || // Re-render if drag state changes
      !_.isEqual(prevProps.rest, nextProps.rest) // Shallow comparison with Lodash
    );
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    // dispatch(setAlert({ show: false, type: ALERT_TYPE.SUCCESS }));
  };

  const handleDragEnd = useCallback(
    (result) => {
      onDragEnd(result); // Call the original onDragEnd function
    },
    [onDragEnd]
  );

  return (
    <Grid
      container
      spacing={0}
      flexDirection={{ xs: "column", sm: "row" }}
      justifyContent="space-between"
      flexWrap={"nowrap"}
      sx={{
        overflowX: "hidden",
      }}
    >
      <Grid
        item
        sx={{
          display: "flex",
          overflow: "scroll none",
          height: {
            xs: `calc(100vh - 80px)`,
            sm: `calc(100vh - 80px )`,
            md: `calc(100vh - 80px)`,
            lg: `calc(100vh - 80px)`,
            mlg: `calc(100vh - 80px)`,
            xl: `calc(100vh - 100px)`,
            xxl: "calc(100vh - 120px)",
          },
        }}
      >
        <Grid
          item
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            overflowY: "scroll",
            position: "absolute",
            zIndex: "1100",
            boxShadow: "0px 5px 10px #00000029",
            borderRadius: "0 0 25px 0",
          }}
          onMouseEnter={() => {
            setLeftMenu(true);
          }}
          onMouseLeave={() => {
            setLeftMenu(false);
          }}
        >
          <Box
            sx={{ opacity: planType ? 1 : 0.2 }}
            onClick={() => {
              !planType && setIsSubscriptionModal(true);
            }}
          >
            <LeftMenu
              leftExpanded={leftMenu}
              isSubscriptionModal={isSubscriptionModal}
              setIsSubscriptionModal={setIsSubscriptionModal}
            />
          </Box>
        </Grid>
        <Grid
          item
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            width: !leftExpanded ? "125px" : "30px",
            // marginRight: "15px",
            marginLeft: "80px",
            position: "absolute",
          }}
        >
          <LeftFilterButton
            onClick={() => {
              setLeftExpand((prevState) => !prevState);
              setRightExpanded((prevState) => !prevState);
            }}
            leftExpanded={leftExpanded}
            color="filterButton"
          />
          {!leftExpanded && (
            <Box
              className="filterSec"
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "10px",
                width: "125px",
                height: {
                  xs: `calc(100vh - 170px)`,
                  sm: `calc(100vh - 120px )`,
                  md: `calc(100vh - 120px)`,
                  lg: `calc(100vh - 135px)`,
                  xl: `calc(100vh - 155px)`,
                  xxl: "calc(100vh - 250px )",
                },

                overflowY: "scroll",
              }}
            >
              <Paper
                sx={{
                  background: "transparent",
                  marginLeft: "1px",
                  boxShadow: "none",
                  gap: "10px",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <ButtonPanel
                  selected={selectedJobApplication?.current}
                  panelData={TALENT_LEFT_JOB_APPLICATION_BUTTON_GROUP}
                  side="left"
                  color="filterButton"
                  onChangeFilter={applicationFilter}
                />
                <ButtonPanel
                  selected={selectedResponse?.current}
                  panelData={TALENT_LEFT_JOB_RESPONSE_BUTTON_GROUP}
                  side="left"
                  color="filterButton"
                  onChangeFilter={responseFilter}
                />
              </Paper>
            </Box>
          )}
        </Grid>
      </Grid>

      <Grid
        className="centerSection"
        sx={{
          marginTop: "55px",
          width: `calc(100vw - ${boxWidth}px)`,
          marginLeft: leftExpanded ? "80px" : "205px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            background: theme.palette.white,
            // height: "75px",
            borderRadius: "25px 25px 0 0",
            justifyContent: "space-between",
          }}
        >
          <CardsTopBar
            jobDetail={jobDetail}
            theme={theme}
            talents={talents}
            isFilterApplied={isFilterApplied}
            reRunMatch={reRunMatch}
          />
          <Box
            ref={topBoxRef}
            onScroll={(event) => handleScroll(event, bottomBoxRef)}
            sx={{
              overflowX: "scroll",
              overflowY: "hidden",
              // height: `calc(100% - 75px)`,
              // width: `calc(100vw - ${boxWidth}px)`,
              // width: "100vw",
              color: "transparent",
              "::-webkit-scrollbar": {
                height: "5px",
                background: "#DCDEEB",
                display: "unset !important",
              },
            }}
          >
            <Box
              sx={{
                display: "hidden",
                height: "5px",
                width: "3540px",
              }}
            >
              h
            </Box>
          </Box>
        </Box>

        <Box
          ref={bottomBoxRef}
          onScroll={(event) => handleScroll(event, topBoxRef)}
          sx={{
            overflowX: "scroll",
            overflowY: "hidden",
            // width: `calc(100vw - ${boxWidth}px)`,
            // width: "100vw",
            height: `calc(100% - 75px)`,
          }}
        >
          <DragDropContext onDragEnd={onDragEnd}>
            <Box
              sx={{
                display: "flex",
                maxHeight: "100%",
                gap: "15px",
              }}
            >
              {Object?.entries(talents)?.map(([columnId, column], index) => {
                return (
                  <Droppable
                    key={`${column?.id}`}
                    droppableId={`${column?.id}`}
                    shouldUpdateListProps={shouldUpdateListProps}
                  >
                    {(provided, snapshot) => (
                      <Box
                        xs={3}
                        ref={provided?.innerRef}
                        {...provided?.droppableProps}
                        sx={{
                          flex: "1 1 0px",
                          minWidth: "340px",
                          height: {
                            xs: `calc(100vh - 185px)`,
                            sm: `calc(100vh - 190px )`,
                            md: `calc(100vh - 220px)`,
                            lg: `calc(100vh - 235px)`,
                            xl: `calc(100vh - 220px)`,
                            xxl: "calc(100vh - 220px)",
                          },
                        }}
                      >
                        <StyledBoxManageTalent
                          color={renderColor(column?.status)}
                          column={column}
                          jobId={jobId}  
                          selectedTab={selectedTab}
                        >
                          <Box sx={{ display: "flex" }}>
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                mr: "20px",
                                fontSize: "16px",
                                fontWeight: theme.typography.Bold,
                              }}
                            >
                              {column?.status}
                            </Box>
                            <Button
                              variant="contained"
                              color="base"
                              label={column?.count}
                              padding="0 4px"
                              alignItems="end"
                              sx={{
                                fontSize: "16px",
                                fontWeight: theme.typography.Bold,
                                letterSpacing: "0.75px",
                                height: 25,
                                borderRadius: "6px",
                                boxShadow: 0,
                                color:
                                  theme.manageTalent[
                                    renderColor(column?.status)
                                  ]?.main,
                                minWidth: "30px !important",
                                padding: "0px 3px !important",
                              }}
                            >
                              {column?.count}
                            </Button>
                          </Box>
                          {/* {column?.id === 1 && (
                            <Box
                              onClick={() =>
                                getTalentStatusApplications(jobId, 1, talents)
                              }
                            >
                              <RefreshIcon />
                            </Box>
                          )} */}
                          <SortButton
                            setBasicData={setBasicData}
                            basicData={basicData}
                            jobId={jobId}
                            jobStatusId={column?.id}
                            handleSortedValue={handleSortedValue}
                            randomLoading={randomLoading}
                            setRandomLoading={setRandomLoading}
                            columnStatus={column?.status}
                            setTalents={setTalents}
                            talents={talents}
                            setHasMore={setHasMore}
                            color={renderColor(column?.status)}
                            setSelectedTab={setSelectedTab}
                          />
                        </StyledBoxManageTalent>
                        <Box
                          sx={{
                            width: "100%",
                            overflowX: "hidden",
                            overflowY: "auto",
                            maxHeight: "100%",
                            marginTop: "-25px",
                            height: {
                              xs: `calc(100vh - 185px)`,
                              sm: `calc(100vh - 190px )`,
                              md: `calc(100vh - 220px)`,
                              lg: `calc(100vh - 235px)`,
                              xl: `calc(100vh - 220px)`,
                              xxl: "calc(100vh - 220px)",
                            },
                          }}
                          id={`talentList${column?.id}`}
                        >
                          <InfiniteScroll
                            dataLength={talents?.[index]?.items?.length}
                            next={() =>
                              getNextData(
                                jobId,
                                column?.id,
                                talents?.[index]?.items,
                                column?.pageNumber + 1,
                                column?.status
                              )
                            }
                            hasMore={
                              talents?.[index]?.items?.length + 1 <
                                column?.count && !hasMore
                                ? true
                                : false
                            }
                            scrollableTarget={`talentList${column?.id}`}
                            scrollThreshold={"50px"}
                            style={{
                              minHeight: "-webkit-fill-available",
                              width: "100%",
                            }}
                            loader={
                              randomLoading[column?.status] ? (
                                ""
                              ) : (
                                <Box
                                  style={{
                                    margin: "15px 0",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                  }}
                                >
                                  <CircularProgress color="inherit" size={20} />
                                </Box>
                              )
                            }
                          >
                            {randomLoading[column?.status] ? (
                              <Box
                                key={`talentList${column?.id}`}
                                style={{
                                  margin: "15px 0",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                <CircularProgress color="inherit" size={20} />
                              </Box>
                            ) : (
                              <Box sx={{ paddingTop: "25px" }}>
                                <BoardColumn
                                  list={talents?.[index]}
                                  onDragEnd={handleDragEnd}
                                  droppableId={column?.id}
                                  talentStatus={talentStatus}
                                  jobDetail={jobDetail}
                                  setJobDetail={setJobDetail}
                                />
                              </Box>
                            )}

                            {/*talents?.[index]?.items?.map((item, index) => (
                              <DraggableCard
                                userID={item?.user_id}
                                itemData={item}
                                index={index}
                                droppableId={column?.id}
                                onDragEnd={onDragEnd}
                                jobId={jobId}
                                talentStatus={talentStatus}
                                key={item?.user_id}
                                getTalentMyJobStatusCount={
                                  getTalentMyJobStatusCount
                                }
                                jobDetail={jobDetail}
                              />
                            ))*/}
                            <style>
                              {`
                              .infinite-scroll-component {
                              }
                              .infinite-scroll-component__outerdiv {
                                margin-top:15px;
                                height: -webkit-fill-available;
                              }`}
                            </style>
                          </InfiniteScroll>
                          {provided?.placeholder}
                        </Box>
                      </Box>
                    )}
                  </Droppable>
                );
              })}
            </Box>
          </DragDropContext>
          {/* {isForbidden && (
            <Snackbar
              style={{ zIndex: "9999" }}
              open={isForbidden}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              autoHideDuration={3000}
              onClose={handleClose}
            >
              <Alert
                onClose={handleClose}
                severity={ALERT_TYPE?.ERROR}
                sx={{ width: "100%" }}
              >
                {"Forbidden"}
              </Alert>
            </Snackbar>
          )} */}
        </Box>
      </Grid>

      <Grid
        item
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "10px",
          direction: "rtl",
          width: !rightExpanded ? "125px" : "",
          // marginLeft: "15px",
        }}
      >
        <RightFilterButton
          onClick={() => {
            setLeftExpand((prevState) => !prevState);
            setRightExpanded((prevState) => !prevState);
          }}
          rightExpanded={rightExpanded}
          color="filterButton"
        />
        {!rightExpanded && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              width: "125px",
              height: {
                xs: `calc(100vh - 170px)`,
                sm: `calc(100vh - 120px )`,
                md: `calc(100vh - 120px)`,
                lg: `calc(100vh - 135px)`,
                xl: `calc(100vh - 155px)`,
                xxl: "calc(100vh - 250px )",
              },

              overflowY: "scroll",
            }}
            // className="rightfilterSec"
          >
            <Paper
              sx={{
                background: "transparent",
                boxShadow: "none",
                gap: "10px",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <EmployerButtonPanel
                panelData={TALENT_RIGHT_JOB_INFO_BUTTON_GROUP}
                setSelectedBtn={setSelectedBtn}
                selectedBtns={selectedBtns}
                isClear={isClear}
                setIsClear={setIsClear}
                side="right"
                color={"filterButton"}
                onChangeFilter={talentRightFIlters}
                setGender={setGender}
                setQualification={setQualification}
                setSkinColor={setSkinColor}
                skinColor={skinColor}
                setSalary={setSalaryRange}
                setExperience={setExperienceRange}
                selected={selectedJobTalent?.current}
                handleFilterSelection={handleFilterSelection}
                setIsFilterApplied={setIsFilterApplied}
                getTalentMyJobStatusCount={getTalentMyJobStatusCount}
                setBasicData={setBasicData}
                requiredQua={requiredQua}
                onFilterSelection={onFilterSelection}
              />
              <ButtonPanel
                selected={selectedJobActivity?.current}
                panelData={TALENT_RIGHT_JOB_ACTIVITY_BUTTON_GROUP}
                side="right"
                color="filterButton"
                onChangeFilter={activityFilter}
              />
              <ButtonPanel
                selected={selectedJobType?.current}
                panelData={JOBS_LEFT_TYPES_BUTTON_GROUP}
                side="right"
                color="filterButton"
                onChangeFilter={onChangeFilterType}
              />
            </Paper>
          </Box>
        )}
      </Grid>

      {isSubscriptionModal && (
        <SubscriptionPopModal
          handleOpen={isSubscriptionModal}
          handleClose={() => setIsSubscriptionModal(false)}
          handleSubscriptionOk={() => setIsSubscriptionModal(false)}
        />
      )}
      {showRunMatch && (
        <RunMatchBox
          handleClose={() => setShowRunMatch(false)}
          openEdit={showRunMatch}
        />
      )}
    </Grid>
  );
}
