import {
  Box,
  Button,
  InputBase,
  Paper,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useState } from "react";
import CustomDialog from "../../common/CustomDialog";

export default function EditBounty({
  bounty,
  openEdit,
  handleClose,
  handleEdit,
  symbol,
}) {
  const theme = useTheme();
  const [bountyAmount, setBountyAmount] = useState(bounty);

  return (
    <CustomDialog
      show={openEdit}
      hideButton={false}
      dialogWidth="xs"
      showFooter={false}
      onDialogClose={handleClose}
      padding={0}
    >
      <Typography
        sx={{
          fontSize: "18px",
          fontWeight: theme.typography.Bold,
          textAlign: "center",
        }}
      >
        Crayon Bounty
      </Typography>
      <Box
        sx={{
          padding: 4,
        }}
      >
        <Paper
          sx={{
            display: "flex",
            height: "40px",
            borderRadius: "10px !important",
            boxShadow: "none",
            width: "100%",
            border: "1px solid #E0E0E0",
          }}
        >
          <InputBase
            name="grit_score"
            value={symbol}
            sx={{
              marginLeft: "10px",
              width: "5%",
              borderRight: `1px solid ${theme.palette.grayBorder}`,
            }}
          />
          <InputBase
            name="grit_score"
            value={bountyAmount}
            sx={{ ml: 2, mr: 2, width: "90%" }}
            placeholder="Enter or edit bounty amount"
            type="number"
            onChange={(event) => setBountyAmount(event?.target?.value)}
          />
        </Paper>
      </Box>
      <Box>
        <Button
          sx={{
            boxShadow: 0,
            fontSize: "14px",
            width: "50%",
            borderRadius: 0,
            height: "43px",
            color: "black",
            padding: 3,
          }}
          color="submitButton"
          variant="contained"
          onClick={handleClose}
        >
          cancel
        </Button>
        <Button
          sx={{
            boxShadow: 0,
            fontSize: "14px",
            width: "50%",
            borderRadius: 0,
            height: "43px",
            padding: 3,
          }}
          variant="contained"
          color="redButton100"
          onClick={() => handleEdit(bountyAmount)}
        >
          edit
        </Button>
      </Box>
    </CustomDialog>
  );
}
