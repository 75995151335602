import { Box, Button, Typography, useTheme } from "@mui/material";
import React from "react";
import CloseIcon from "../../common/CloseIcon";
import crayBot from "../../../assets/CrayBotIcons/Crayon Craybot Notification-07.svg";
import darkIcon from "../../../assets/Padding Excluded/Black_Trash_Delete_2.svg";
import deleteIcon from "../../../assets/Cam/DeleteIconWhite.svg";
import locale from "../../../i18n/locale";

export default function EmployerBizSettingPortal({
  onClose,
  settingsRef,
  setGoDark,
  setDeleteProfile,
}) {
  const i18n = locale.en;
  const theme = useTheme();
  return (
    <Box sx={{ width: "360px", height: "291px", background: "#FFFFFF" }}>
      <Box>
        <Button
          variant="contained"
          color="greyPlayButton"
          sx={{
            width: "125px",
            height: "45px",
            boxShadow: "none !important",
            borderRadius: "25px 0 0 0",
            color: "black",
            padding: "0px",
          }}
        >
          nuts and bolts
        </Button>
        <Button
          variant="contained"
          color="playButton"
          sx={{
            width: "60px !important",
            minWidth: "60px !important",
            height: "45px",
            boxShadow: "none !important",
            borderRadius: "0 0 10px 0",
          }}
        >
          active
        </Button>

        <Box onClick={onClose}>
          <CloseIcon />
        </Box>
      </Box>

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          gap: "11px",
          padding: "6px 0 23px 20px",
        }}
      >
         <Box sx={{ 
           display: "flex",
           flexDirection: "column",
           alignItems: "center"
             }}>
          <Box
            component={"img"}
            src={crayBot}
            sx={{ height: "100px",marginTop:"10px" }}
          />
          <Typography
            sx={{
              fontSize: theme.typography.fontSize.xs,
              fontWeight: theme.typography.Regular,
              width: "100%",
               textAlign:"center"
            }}
          >
            {i18n["myProfile.settingText"]}
          </Typography>
        </Box>

        <Box
          sx={{
            width: "125px",
            display: "flex",
            gap: "10px",
            flexDirection: "column",
          }}
        >
          <Button
            variant="contained"
            color="peachPayment"
            sx={{
              fontSize: theme.typography.fontSize.xs,
              fontWeight: theme.typography.Bold,
              width: "125px",
              height: "45px",
              boxShadow: `0px 5px 10px ${theme?.palette?.boxShadow?.main}`,
              borderRadius: "10px 0 0 10px",
              padding: "0 0 0 10px",
              gap: "10px",
              justifyContent: "flex-start",
            }}
            startIcon={
              <Box
                component={"img"}
                src={darkIcon}
                sx={{ height: "20px", width: "20px" }}
              />
            }
            onClick={() => {
              setGoDark(settingsRef.current);
              onClose();
            }}
          >
            go dark
          </Button>
          <Button
            variant="contained"
            color="nandos"
            sx={{
              fontSize: theme.typography.fontSize.xs,
              fontWeight: theme.typography.Bold,
              width: "125px",
              height: "45px",
              boxShadow: `0px 5px 10px ${theme?.palette?.boxShadow?.main}`,
              borderRadius: "10px 0 0 10px",
              padding: "0px",
            }}
            startIcon={
              <Box
                component={"img"}
                src={deleteIcon}
                sx={{ height: "20px", width: "13px" }}
              />
            }
            onClick={() => {
              setDeleteProfile(settingsRef.current);
              onClose();
            }}
          >
            delete profile
          </Button>
        </Box>
      </Box>
    </Box>
  );
}
