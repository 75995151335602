import React, { useEffect, useState } from "react";
import { Box, Button, useTheme } from "@mui/material";
import MUIRadialChart from "../../common/MUIRadialChart";
import leftIcon from "../../../assets/Black_Left_Previous - Copy.svg";
import rightIcon from "../../../assets/Black_Right_Next - Copy.svg";
import ReactCardFlip from "react-card-flip";
import flip from "../../../assets/NewFlipIcon.svg";

export default function CompanyStats({ companyDetail, calculateHeight }) {
  const theme = useTheme();
  const [arrSlider2, setArrSlider2] = useState([]);
  const [arrSlider3, setArrSlider3] = useState([]);
  const handleRightClick = () => {
    setArrSlider2([...arrSlider2?.slice(1), ...arrSlider2?.slice(0, 1)]);
  };
  const [isHorizontalFlipped, setIsHorizontalFlipped] = useState(false);
  const handleLeftClick = () => {
    setArrSlider2([
      ...arrSlider2?.slice(arrSlider2?.length - 1),
      ...arrSlider2?.slice(0, arrSlider2?.length - 1),
    ]);
  };

  useEffect(() => {
    setArrSlider2([
      {
        id: 1,
        value: companyDetail?.TotalJobCount,
        color: "jobPostingNew",
        size: 100,
        chartName: "job postings",
      },
      {
        id: 2,
        value: companyDetail?.ActiveJobCount,
        color: "activeJobs",
        size: 100,
        chartName: "active jobs",
      },
      {
        id: 6,
        value: companyDetail?.TotalUserHired,
        color: "hiredNew",
        size: 100,
        chartName: "hires",
      },
    
    ]);
    setArrSlider3([
      {
        id: 3,
        value: companyDetail?.TotalUserCount,
        color: "activeJobNew",
        size: 500,
        chartName: "applications",
      },
      {
        id: 4,
        value: companyDetail?.TotalUserShortlisted,
        color: "averageMatch",
        size: 100,
        chartName: "shortlistings",
      },
      {
        id: 5,
        value: companyDetail?.TotalUserInterviewed,
        color: "shortListings",
        size: 50,
        chartName: "interviews",
      },
    
    ]);
  }, [companyDetail]);

  return (
    <ReactCardFlip
      isFlipped={isHorizontalFlipped}
      flipDirection={"horizontal"}
      flipSpeedBackToFront="0.5"
      flipSpeedFrontToBack="0.5"
      // key={job?.job_id}
    >
      <Box
        sx={{
          height: "225px",
          // width: { xs: "360px", lg: "750px", xl: "750px" },
          background: "#ffff",
          boxShadow: "0px 5px 10px #00000029",
          borderRadius: "25px",
          flex: "1",
          display:"flex",
          flexDirection:"column"
       
        }}
      >
        <Box>
          <Button
            variant="contained"
            color="boxTitleButton"
            sx={{
              height: "45px",
              minWidth: "125px",
              maxWidth: "125px",
              fontSize: "14px",
              borderRadius: "25px 0px 10px 0px",
              cursor: "default",
              ":hover": {
                boxShadow: "none !important",
              },
            }}
          >
            the stats
          </Button>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", xl: "row" },
            marginBottom: {xs:"18px",tablet:"8px"},
            flex: "1",
             justifyContent:"center"
          }}
        >
          <Box
            sx={{
              display: { xs: "flex" },
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "row",
              gap: "10px",
              width: {
                xs360: "90%",
                xs390: "87%",
                sm: "100%",
                lgmm: "50%",
                lg: "100% !important",
              },
              minWidth: {
                xs360: "90%",
                xs390: "87%",
                sm: "100%",
                lgmm: "50%",
                lg: "100% !important",
              },
              boxSizing: "border-box",
              overflow: "hidden",
            }}
          >
            {/* <Box
              component={"img"}
              src={leftIcon}
              sx={{
                height: "20px",
                width: "20px",
                cursor: "pointer",
              }}
              onClick={handleLeftClick}
            /> */}
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-around",
                gap: {
                  sm: "3px",
                  lgmm: "10px",
                  xl: "10px",
                  xll: "10px",
                },
                width:"100%",
                overflow: "hidden",
              }}
            >
              {arrSlider2?.map((item) => {
                return (
                  <MUIRadialChart
                    size={calculateHeight()}
                    max={item?.size}
                    countFontSize={20}
                    countFontWeight={theme.typography.Bold}
                    labelFontSize={12}
                    labelFontWeight={theme.typography.Bold}
                    chartName={item?.chartName}
                    value={item?.value}
                    color={item?.color}
                  />
                );
              })}
            </Box>
          </Box>
        </Box>
        <Button
          variant="contained"
          color="redButton100"
          sx={{
            width: "20px",
            height: "100px",
            padding: 0,
            position: "absolute",
            right: 0,
            top: `calc(50% - 50px)`,
            minWidth: "20px",
            fontSize: "20px",
            borderRadius: "10px 0 0 10px",
          }}
          // onClick={() => setisHorizontalFlipped(true)}
          onClick={() => setIsHorizontalFlipped((prev) => !prev)}
        >
          <Box
            component={"img"}
            src={flip}
            sx={{
              height: "20px",
              width: "15px",
            }}
          />
        </Button>
      </Box>

      <Box
        sx={{
          height: "225px",
          // width: { xs: "360px", lg: "750px", xl: "750px" },
          background: "#ffff",
          boxShadow: "0px 5px 10px #00000029",
          borderRadius: "25px",
          flex: "1",
          display:"flex",
          flexDirection:"column"
        }}
      >
        <Box>
          <Button
            variant="contained"
            color="boxTitleButton"
            sx={{
              height: "45px",
              minWidth: "125px",
              maxWidth: "125px",
              fontSize: "14px",
              borderRadius: "25px 0px 10px 0px",
              cursor: "default",
              ":hover": {
                boxShadow: "none !important",
              },
            }}
          >
            the stats
          </Button>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", xl: "row" },
            marginBottom: {xs:"18px",tablet:"8px"},
            flex:"1",
            justifyContent:"center"
          }}
        >
          <Box
            sx={{
              display: { xs: "flex" },
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "row",
              gap: "10px",
              width: {
                xs360: "90%",
                xs390: "87%",
                sm: "100%",
                lgmm: "50%",
                lg: "100% !important",
              },
              minWidth: {
                xs360: "90%",
                xs390: "87%",
                sm: "100%",
                lgmm: "50%",
                lg: "100% !important",
              },
              boxSizing: "border-box",
              overflow: "hidden",
            }}
          >
            {/* <Box
              component={"img"}
              src={leftIcon}
              sx={{
                height: "20px",
                width: "20px",
                cursor: "pointer",
              }}
              onClick={handleLeftClick}
            /> */}
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-around",
                gap: {
                  sm: "3px",
                  lgmm: "10px",
                  xl: "10px",
                  xll: "10px",
                },
                width:"100%",
                overflow: "hidden",
              }}
            >
              {arrSlider3?.map((item) => {
                return (
                  <MUIRadialChart
                    size={calculateHeight()}
                    max={item?.size}
                    countFontSize={20}
                    countFontWeight={theme.typography.Bold}
                    labelFontSize={12}
                    labelFontWeight={theme.typography.Bold}
                    chartName={item?.chartName}
                    value={item?.value}
                    color={item?.color}
                  />
                );
              })}
            </Box>
          </Box>
        </Box>
        <Button
          variant="contained"
          color="redButton100"
          sx={{
            width: "20px",
            height: "100px",
            padding: 0,
            position: "absolute",
            right: 0,
            top: `calc(50% - 50px)`,
            minWidth: "20px",
            fontSize: "20px",
            borderRadius: "10px 0 0 10px",
          }}
          // onClick={() => setisHorizontalFlipped(true)}
          onClick={() => setIsHorizontalFlipped((prev) => !prev)}
        >
          <Box
            component={"img"}
            src={flip}
            sx={{
              height: "20px",
              width: "15px",
            }}
          />
        </Button>
      </Box>
    </ReactCardFlip>
  );
}
