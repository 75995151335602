import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Typography,
  useTheme,
} from "@mui/material";
import React from "react";
import TalentSVGButton from "../../../common/TalentSVGButton";
import InfiniteScroll from "react-infinite-scroll-component";
import profile from "../../../../assets/user_logo.svg";
import { Link } from "react-router-dom";
import teamIcon from "../../../../assets/Black_User_Profile.svg";

export default function TeamMemberBox({
  text,
  teamMember,
  getTeamMember,
  lastKey,
  teamMemberCount,
  buttonText,
  icon,
  color,
  iconwidth,
  iconheight,
  showBelowtext,
  height,
}) {
  const theme = useTheme();
  return (
    <>
      <Button
        variant="contained"
        color={color}
        sx={{
          width: "150px",
          height: "45px",
          borderRadius: "25px 0 25px 0",
          padding: "0px !important",
        }}
        startIcon={
          <Box
            component={"img"}
            src={icon}
            sx={{
              width: iconwidth,
              height: iconheight,
            }}
          />
        }
      >
        {buttonText} ({teamMemberCount})
      </Button>
      <Box
        sx={{
          padding: "12px 15px 20px 26px !important",
        }}
      >
        <Typography sx={{ fontSize: "11px", fontWeight: 700 }}>
          {text}
        </Typography>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "15px",
            width: "97%",
            mt: "8px",
            overflowY: "auto",
            height: height,
            "::-webkit-scrollbar": {
              height: "5px",
              background: "#DCDEEB",
              display: "unset !important",
            },
          }}
          id="matchTaleneList"
          className="scroll-container"
        >
          <InfiniteScroll
            style={{
              overflowX: "hidden",
              scrollbarWidth: "thin",
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              width: "93%",
            }}
            dataLength={teamMember?.length}
            next={() => getTeamMember(lastKey ? lastKey : "")}
            hasMore={teamMember?.length < teamMemberCount ? true : false}
            scrollableTarget="matchTaleneList"
            scrollThreshold={"50px"}
            loader={
              <Box
                style={{
                  margin: "15px 0 0 0",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CircularProgress color="inherit" size={20} />
              </Box>
            }
          >
            {teamMember?.length > 0 ? (
              teamMember?.map((item, index) => {
                return (
                  <>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Box
                        component="img"
                        className="profileAvatar"
                        alt="crayon logo"
                        src={
                          item?.profile_url !== "No URL"
                            ? item?.profile_url
                            : profile
                        }
                        sx={{
                          mr: 1,
                          height: "50px !important",
                          width: "46px !important",
                          borderRadius: "10px",
                        }}
                      />
                      <Box sx={{ display: "flex", flexDirection: "column" }}>
                        <Link
                          to={
                            item?.employer_role_type?.name === "Recruiter"
                              ? `/admin/users/user-recruiters/${item?.user_id}`
                              : `/admin/users/user-employers/${item?.user_id}`
                          }
                          target={"_blank"}
                          style={{
                            textDecoration: "none",
                            color: theme.palette.black,
                          }}
                        >
                          <Typography
                            sx={{
                              fontSize: "12px",
                              fontWeight: theme.typography.Bold,
                              width: "100%",
                              whiteSpace: "nowrap", // Prevents text from wrapping
                              overflow: "hidden", // Hides any overflowing content
                              textOverflow: "ellipsis",
                            }}
                          >
                            {buttonText === "recruiter"
                              ? `${
                                  item?.company_name ? item?.company_name : "-"
                                }`
                              : `${item?.user?.first_name} ${item?.user?.last_name}`}
                          </Typography>
                          {showBelowtext && (
                            <Typography
                              sx={{
                                fontSize: "12px",
                                fontWeight: theme.typography.Regular,
                                width: "170px",
                              }}
                            >
                              {buttonText === "recruiter"
                                ? `${item?.user?.first_name} ${item?.user?.last_name}`
                                : `${item?.employer_role_type?.name}`}
                            </Typography>
                          )}
                        </Link>
                      </Box>
                    </Box>
                  </>
                );
              })
            ) : (
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: theme.typography.Bold,
                  }}
                >
                  No Data
                </Typography>
              </Box>
            )}
          </InfiniteScroll>
        </Box>
      </Box>
    </>
  );
}
