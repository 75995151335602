import React, { useCallback, useEffect, useState } from "react";
import TalentFilters from "../../adminTalent/CommonComponent/TalentFilters";
import { Box } from "@mui/material";
import EmployerCard from "../Common/EmployerCard";
import { getUserEmployer } from "../../../../redux/admin/jobsSlice";
import { setAlert } from "../../../../redux/configSlice";
import { ALERT_TYPE, ERROR_MSG, USER_ROLES } from "../../../../utils/Constants";
import { useDispatch } from "react-redux";
import InfiniteScroll from "react-infinite-scroll-component";
import { debounce } from "../../../../utils/Common";

export default function UserRecruiter() {
  const dispatch = useDispatch();

  const [recruiterData, setRecruiterData] = useState([]);
  const [lastKey, setLastKey] = useState();
  const [showText, setShowText] = useState(false);
  const [employerCount, setEmployerCount] = useState(0);
  const [searchTitle, setSearchTitle] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [openFilter, setOpenFilter] = useState(false);
  // const [filtersValue, setFiltersValue] = useState({
  //   quickSearch: "",
  //   talentStage: "",
  //   talentPool: "",
  //   job: "",
  //   talentStatus: "",
  // });

  const getRecruiterList = async (lastkeyy, search) => {
    try {
      const { payload } = await dispatch(
        getUserEmployer({
          lastKey: lastkeyy,
          roleid: USER_ROLES?.[4]?.role_id,
          userId: "",
          query: search===null?"":search,
        })
      );

      if (payload?.status === "success") {
        setEmployerCount(payload?.talentCount);
        if (payload?.data?.length <= 0 && payload?.pageNumber === 0) {
          setRecruiterData([]);
          setShowText(true);
        } else {
          if (payload?.pageNumber === 0) {
            setRecruiterData(payload?.data);
            setLastKey(payload?.pageNumber + 1);
          } else {
            setLastKey(payload?.pageNumber + 1);
            setRecruiterData((prevState) => [...prevState, ...payload?.data]);
          }
        }
      } else {
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE?.ERROR,
            msg: payload?.message,
          })
        );
      }
    } catch (error) {
      dispatch(
        setAlert({
          show: true,
          type: ALERT_TYPE?.ERROR,
          msg: "ERROR",
        })
      );
    }
  };

  const handleInputSearch = async (event) => {
    // setFiltersValue({
    //   ...filtersValue,
    //   quickSearch: event?.target?.value,
    // });
    debouncedHandleOnTextSearch(event);
  };

  const debouncedHandleOnTextSearch = useCallback(
    debounce(
      async (newValue) => {
        // if (!newValue) return; // Early return
        try {
          const response = await getRecruiterList(0, (newValue?.target?.value || ""));
          setSearchTitle(newValue?.target?.value || "");
          if (response && response?.payload) {
            const { payload } = response;
            dispatch(
              payload?.status === "success"
                ? setRecruiterData(payload?.data)
                : setAlert({
                    show: true,
                    type: ALERT_TYPE?.ERROR,
                    msg: "Error",
                  })
            );
          }
          setOpenFilter(false);
        } catch (error) {
          dispatch(
            setAlert({ show: true, type: ALERT_TYPE?.ERROR, msg: ERROR_MSG })
          );
        }
      },
      1000, // Adjust delay as needed
      setIsLoading
    ),
    [dispatch, setRecruiterData, setIsLoading]
  );

  useEffect(() => {
    sessionStorage.clear();
    getRecruiterList(0, "");
  }, []);

  return (
    <>
      <TalentFilters
        title={"All Recruiter"}
        totlaCount={employerCount}
        // handleInputSearch={handleInputSearch}
        additionalHandleChange={handleInputSearch}
        setOpenFilter={setOpenFilter}
        openFilter={openFilter}
        // filtersValue={filtersValue}
      />

      <InfiniteScroll
        style={{ overflow: "hidden" }}
        dataLength={recruiterData?.length}
        next={() => getRecruiterList(lastKey,  JSON.parse(sessionStorage.getItem("searchTitle")))}
        scrollThreshold={"100px"}
        scrollableTarget={"jobList"}
        hasMore={true}
        endMessage={
          <p style={{ textAlign: "center" }}>
            <b>Yay! You have seen it all</b>
          </p>
        }
      >
        <Box sx={{ mt: "215px", mb: "16px" }}>
          {recruiterData?.length > 0
            ? recruiterData?.map((recruiter, index) => (
                <EmployerCard
                  key={recruiter?.user_id}
                  employerData={recruiter}
                  getEmployerList={getRecruiterList}
                  roleId={USER_ROLES?.[4]?.role_id}
                  setEmployerData={setRecruiterData}
                  allEmployerData={recruiterData}
                />
              ))
            : showText && (
                <Box
                  sx={{
                    width: "100%",
                    textAlign: "center",
                    mt: 4,
                    color: "black",
                  }}
                >
                  No Talent in this pool
                </Box>
              )}
        </Box>
      </InfiniteScroll>
    </>
  );
}
