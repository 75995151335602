import { useCallback, useEffect, useState, useRef } from "react";
import { useDispatch } from "react-redux";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import locale from "../../../i18n/locale";
import InputBox from "./InputBox";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

import maleBlack from "../../../assets/male_blue.svg";
import maleWhite from "../../../assets/male_white.svg";
import femaleBlack from "../../../assets/female_pink.svg";
import femaleWhite from "../../../assets/female_white (2).svg";

import { setAlert } from "../../../redux/configSlice";
import {
  ALERT_TYPE,
  ERROR_MSG,
  PASSWORD_REGEX,
  isValidLinkedInUrl,
} from "../../../utils/Constants";
import { useTheme } from "@emotion/react";
import { setLoading } from "../../../redux/configSlice";
import {
  createInfo,
  getCountries,
  resetLoggedInUserPassword,
  resetPassword,
} from "../../../redux/employer/empProfileSlice";
import { Switch, TextField } from "@mui/material";
import { alpha } from "@material-ui/core";
import PhoneInput, { formatPhoneNumberIntl } from "react-phone-number-input";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { useSelector } from "react-redux";
import { CheckCircle } from "@mui/icons-material";
import { isValidPhoneNumber } from "libphonenumber-js/max";
import * as Yup from "yup";
import {
  debounce,
  getContactDetail,
  validateSchema,
} from "../../../utils/Common";
import CustomSelect from "../../candidate/myCV/CustomSelect";
import { getTown } from "../../../redux/employer/postJobSlice";
import _ from "lodash";
const regionNames = new Intl.DisplayNames(["en"], { type: "region" });

export default function Info({
  profile,
  errors,
  setErrors,
  handleNextStep,
  handleInfoSave,
  setEmployerData,
  employerData,
}) {
  const i18n = locale.en;
  const theme = useTheme();
  const currentDate = new Date();

  const dispatch = useDispatch();
  const [inputType, setInputType] = useState("password");
  const [showPassword, setShowPassword] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [profileData, setProfileData] = useState(profile);
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [preferNotToSay, setPreferNotToSay] = useState(false);
  const { countries, town } = useSelector((state) => state?.postJobs);
  const [contactNumber, setContactNumber] = useState(profile?.contact_no || "");
  const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [previousCountryCode, setPreviousCountryCode] = useState("");
  const profileDataRef = useRef(profileData);

  const infoFormSchema = Yup.object().shape({
    first_name: Yup.string().required("First Name is Required."),
    surname: Yup.string().required("Surname is required"),
    email: Yup.string()
      .matches(emailRegex, "Invalid email address") // Use custom regex for email validation
      .required("Email is Required."),
    dob: Yup.string().required("dob is Required."),
    country_id: Yup.mixed().required("Country is Required."),
    town_id: Yup.string().required("Town/City is Required."),
    linkedin_profile_link: Yup.string()
      .when("validateLinkedin", {
        is: true,
        then: (schema) =>
          schema
            .test(
              "isValidLinkedIn",
              "Invalid LinkedIn URL",
              (value) => !value || isValidLinkedInUrl(value)
            )
            .required("Hyperlink is Required."),
        otherwise: (schema) => schema.optional(),
      })
      .nullable()
      .notRequired(),
    calendar_link: Yup.string()
      .when("validateCalendarLink", {
        is: true,
        then: (schema) =>
          schema
            .test(
              "isValidCalendar",
              "Invalid Calendar URL",
              (value) => !value || isValidLinkedInUrl(value)
            )
            .required("Hyperlink is Required."),
        otherwise: (schema) => schema.optional(),
      })
      .nullable()
      .notRequired(),
    // gender: Yup.string()
    //   .when("validateGender", {
    //     is: true,
    //     then: (schema) => schema.required("Hyperlink is Required."),
    //     otherwise: (schema) => schema.optional(),
    //   })
    //   .nullable()
    //   .notRequired(),
  });

  const getAllData = async () => {
    try {
      await Promise.all([dispatch(getCountries())]);
    } catch (error) {
      dispatch(setLoading(false));
      dispatch(
        setAlert({
          show: true,
          type: ALERT_TYPE?.ERROR,
          msg: ERROR_MSG,
        })
      );
    }
  };

  const handleCountryIdChange = async (countryId) => {
    let updatedCountryCode = countries?.filter(
      (item) => item?.country_id === countryId
    )?.[0]?.country_code;
    setPreviousCountryCode(updatedCountryCode);
    handleChange(countryId);
  };

  const handleContactNumber = async (name, value) => {
    setContactNumber(value);
  };

  const handleShowPassword = () => {
    if (showPassword) setInputType("password");
    else setInputType("text");

    setShowPassword(!showPassword);
  };
  const validateEmail = (email) => {
    const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    return regex?.test(email);
  };
  const isValidLinkedInUrl = (url) => {
    const regex =
      /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/|www\.)[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/[^\s]*)?$/;
    return regex.test(url);
  };
  const handleInputChange = (event) => {
    const newProfileData = {
      ...profileData,
      [event.target.id]: event?.target?.value,
    };
    if (event?.target?.id === "first_name" || event?.target?.id === "surname") {
      setEmployerData((prev) => ({
        ...prev,
        user: {
          ...prev?.user,
          [event.target.id]: event?.target?.value,
        },
      }));
      setErrors(errors?.filter((item) => item?.key !== event?.target?.id));
    }
    if (event?.target?.id === "email") {
      validateEmail(event?.target?.value) &&
        setErrors(errors?.filter((item) => item?.key !== event?.target?.id));
    }
    if (
      event?.target?.id === "linkedin_profile_link" ||
      event?.target?.id === "calendar_link"
    ) {
      isValidLinkedInUrl(event?.target?.value) &&
        setErrors(errors?.filter((item) => item?.key !== event?.target?.id));
    }
    setProfileData(newProfileData);
  };

  const handleDateChange = (newValue) => {
    let formatedDate =
      dayjs(newValue)?.format("YYYY-MM-DD") === "Invalid Date"
        ? ""
        : dayjs(newValue)?.format("YYYY-MM-DD");
    const newProfileData = {
      ...profileData,
      dob: formatedDate,
    };
    const filteredErrors = errors?.filter((item) => item?.key != "dob");
    setErrors(filteredErrors);
    setProfileData(newProfileData);
  };

  const handlePassword = (event) => {
    setNewPassword(event?.target?.value);
    if (!PASSWORD_REGEX?.test(event?.target?.value)) {
      setErrors((prev) => [
        ...prev,
        { message: "password not matching", key: "password" },
      ]);
      return;
    } else {
      const filteredErrors = errors?.filter((item) => item?.key !== "password");
      setErrors(filteredErrors);
    }
  };

  const handlePasswordReset = async () => {
    const params = {
      new_password: newPassword,
    };
    if (!PASSWORD_REGEX?.test(newPassword)) {
      setErrors((prev) => [
        ...prev,
        { message: "password not matching", key: "password" },
      ]);
      return;
    }
    try {
      const { payload } = await dispatch(resetLoggedInUserPassword(params));
      if (payload?.status == "success") {
        // setNewPassword("");
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE?.SUCCESS,
            msg: "Password reset Successfully!",
          })
        );
      } else {
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE?.ERROR,
            msg: payload?.message,
          })
        );
      }
    } catch (error) {
      dispatch(setAlert({ show: true }));
    }
  };

  const handleChange = async (value) => {
    if (isDataLoading) {
      setIsDataLoading(false);
      return;
    }
    let selectedCountry = countries?.find((item) => item?.id === value);
    const newProfileData = {
      ...profileData,
      country_code:
        selectedCountry?.country_code_name || profileData?.country_code,
      country_id: value || profileData?.country_id,
      town_id: "",
      town_name: "",
      country_name: selectedCountry?.name,
    };
    if (value === undefined && selectedCountry?.country_code) {
      setContactNumber(`${selectedCountry?.country_code}`);
    }
    setErrors(errors?.filter((item) => item?.key !== "country_id"));
    setProfileData(newProfileData);
  };

  const handleGender = (event, gender) => {
    const newProfileData = {
      ...profileData,
      gender: profileData.gender === gender ? null : gender,
    };
    const filteredErrors = errors?.filter((item) => item?.key != "gender");
    setErrors(filteredErrors);
    setProfileData(newProfileData);
  };

  const handlePrefer = (event, name) => {
    const preferToSay = event?.target?.checked ? true : false;
    const newProfileData = {
      ...profileData,
      [name]: preferToSay,
      gender: null,
    };
    setProfileData(newProfileData);
  };

  const getMaleIcon = (
    <Box
      component="img"
      alt="male white"
      src={
        profileData?.gender === "male" || profileData?.gender_flag
          ? maleWhite
          : maleBlack
      }
      sx={{ width: "7px" }}
    />
  );
  const getFemaleIcon = (
    <Box
      component="img"
      alt="female white"
      src={
        profileData?.gender === "female" || profileData?.gender_flag
          ? femaleWhite
          : femaleBlack
      }
      sx={{ width: "9px" }}
    />
  );

  const saveInfo = async (data, changeNext) => {
    let {
      first_name,
      linkedin_profile_link,
      surname,
      email,
      dob,
      country_id,
      town_id,
      calendar_link,
      gender,
      gender_flag,
    } = data;
    let testErrorState = {
      first_name,
      surname,
      email,
      dob,
      country_id,
      town_id,
      linkedin_profile_link,
      validateLinkedin: linkedin_profile_link?.length > 0 ? true : false,
      calendar_link,
      validateCalendarLink: calendar_link?.length > 0 ? true : false,
      gender,
      validateGender: !gender_flag,
    };
    validateSchema(infoFormSchema, testErrorState)
      .then(() => {
        const contactInfo = getContactDetail(
          formatPhoneNumberIntl(
            contactNumber?.startsWith("+")
              ? contactNumber
              : `${profileData?.country_code}${contactNumber}`
          )
        );
        const updatedData = {
          ...data,
          contact_no: contactInfo ? contactInfo?.[1] : contactNumber,
          country_code: contactInfo
            ? contactInfo?.[0]
            : profileData?.country_code,
        };
        try {
          if (
            !isValidPhoneNumber(
              contactNumber?.startsWith("+")
                ? contactNumber
                : `${profileData?.country_code}${contactNumber}` || ""
            )
          ) {
            let phoneError = {
              message: "Invalid contact number",
              key: "contact_no",
            };
            //
            if (updatedData?.gender === null) {
              updatedData["gender"] = "";
            }
            let exap = [...errors, phoneError];
            setErrors(exap);
            return;
          }
          dispatch(createInfo(updatedData)).then((payload) => {
            if (payload?.payload?.status === "success") {
              // dispatch(
              //   setAlert({
              //     show: true,
              //     type: ALERT_TYPE?.SUCCESS,
              //     msg: "Profile updated successfully!",
              //   })
              // );
              handleInfoSave();
              setErrors([]);
              changeNext && handleNextStep(2);
            } else if (payload?.payload?.status === "error") {
              setErrors(payload?.payload?.message);
            } else {
              dispatch(
                setAlert({
                  show: true,
                  type: ALERT_TYPE?.ERROR,
                  msg: payload?.payload?.message,
                })
              );
            }
          });
        } catch (error) {
          dispatch(
            setAlert({ show: true, type: ALERT_TYPE?.ERROR, msg: error })
          );
        }
      })
      .catch((error) => {
        setErrors(
          Object.keys(error)?.map((item) => ({
            message: error[item],
            key: item,
          }))
        );
      });
  };

  const onMenuClose = async () => {
    try {
      const { payload } = await dispatch(
        getTown(profileData?.country_id || "")
      );
    } catch (error) {
      dispatch(
        setAlert({
          show: true,
          type: ALERT_TYPE?.ERROR,
          msg: ERROR_MSG,
        })
      );
    }

    setIsMenuOpen(true);
  };

  const onMenuOpen = async () => {
    if (town?.length === 0) {
      try {
        const { payload } = await dispatch(
          getTown(profileData?.country_id || "")
        );
      } catch (error) {
        dispatch(setLoading(false));
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE?.ERROR,
            msg: ERROR_MSG,
          })
        );
      }
    }
    setIsMenuOpen(true);
  };

  const extractPhoneNumber = (
    oldCountryCode,
    newCountryCode,
    fullPhoneNumber
  ) => {
    if (fullPhoneNumber === undefined || fullPhoneNumber === null) {
      return `${newCountryCode}`;
    }
    let oldCountryCodeStr = String(oldCountryCode);
    let newCountryCodeStr = String(newCountryCode);
    let normalizedNumber = fullPhoneNumber?.startsWith("+")
      ? fullPhoneNumber?.substring(1)
      : fullPhoneNumber;
    if (normalizedNumber?.startsWith(oldCountryCodeStr)) {
      normalizedNumber = normalizedNumber?.substring(oldCountryCodeStr?.length);
    }
    return `+${newCountryCodeStr}${normalizedNumber}`;
  };

  const handleOnSelectChange = async (ind, newValue, val, inputId) => {
    if (inputId === "country_id") {
      let countryId = newValue?.value;
      let selectedCountry = countries?.find((item) => item?.id === countryId);
      if (selectedCountry && selectedCountry?.country_code) {
        let updatedNumber = extractPhoneNumber(
          previousCountryCode,
          selectedCountry?.country_code,
          contactNumber
        );
        setPreviousCountryCode(selectedCountry?.country_code);
        const newProfileData = {
          ...profileData,
          country_code: `+${selectedCountry?.country_code}`,
          country_id: countryId,
          town_id: "",
          town_name: "",
          country_name: newValue?.label || profile?.country_name,
        };
        setContactNumber(updatedNumber);
        setProfileData(newProfileData);
        setErrors(errors?.filter((item) => item?.key !== "country_id"));
        try {
          setLoading({ town_id: true });
          const { payload } = await dispatch(getTown(countryId));
          setLoading({ town_id: false });
        } catch (error) {
          setLoading({ town_id: false });
          dispatch(
            setAlert({
              show: true,
              type: ALERT_TYPE?.ERROR,
              msg: ERROR_MSG,
            })
          );
        }
      }
    }
    if (inputId === "town_id") {
      const newProfileData = {
        ...profileData,
        town_id: newValue?.value,
        town_name: newValue?.label,
      };
      setProfileData(newProfileData);
      setErrors(errors?.filter((item) => item?.key !== "town_id"));
    }
  };
  const updateDebounceFunc = async (newValue, type) => {
    let params = {
      title: newValue || "",
      id: profileDataRef.current?.country_id,
      limit: 200,
    };
    if (newValue) {
      try {
        if (type !== "country_id") {
          dispatch(getTown({ ...params, setState: setIsLoading, type }));
        }
      } catch (error) {
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE?.ERROR,
            msg: error,
          })
        );
      }
    }
  };

  const debounceFunction = _.debounce(updateDebounceFunc, 750);

  const debouncedHandleOnSelectInputChange = useCallback(
    debounce(
      async (newValue, type) => {
        let params = {
          title: newValue || "",
          id: profileDataRef.current?.country_id,
          limit: 200,
        };
        if (newValue) {
          try {
            if (type !== "country_id") {
              dispatch(getTown(params));
            }
          } catch (error) {
            dispatch(
              setAlert({
                show: true,
                type: ALERT_TYPE?.ERROR,
                msg: error,
              })
            );
          }
        }
      },
      750,
      setIsLoading
    ),
    []
  );

  const handleOnSelectInputChange = (newValue, inputId) => {
    // debouncedHandleOnSelectInputChange(newValue, inputId);
    debounceFunction(newValue, inputId);
  };

  useEffect(() => {
    profileDataRef.current = profileData;
  }, [profileData]);

  useEffect(() => {
    getAllData();
  }, []);

  useEffect(() => {
    setIsDataLoading(true);
    let updatedCountryCode = countries?.filter(
      (item) => item?.name === profile?.country_name
    )?.[0];
    setPreviousCountryCode(updatedCountryCode?.country_code);
    let newBasicData = {
      ...profile,
      country_id: updatedCountryCode?.country_id,
    };
    setProfileData(newBasicData);
    setContactNumber(profile?.contact_no || "");
  }, [profile, countries]);

  return (
    <>
      <Box width={`calc(100vw-360px)`}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            mb: "20px",
            gap: { xs: "20px", tablet: "0px" },
            flexDirection: { xs: "column", tablet: "row" },
          }}
        >
          <Box sx={{ width: { xs: "100%", tablet: "47%" } }}>
            <Typography
              sx={{
                marginBottom: "4px",
                fontSize: "14px",
                fontWeight: theme.typography.Bold,
              }}
            >
              {i18n["empMyProfile.firstNameReq"]}
            </Typography>
            <InputBox
              id="first_name"
              value={profileData?.first_name}
              onChange={handleInputChange}
              placeholder={i18n["empMyProfile.firstNamePlace"]}
              sx={{
                border: errors?.find((error) => error?.key == "first_name")
                  ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
                  : "1px solid #E0E0E0",
              }}
            />
          </Box>
          <Box sx={{ width: { xs: "100%", tablet: "47%" } }}>
            <Typography
              sx={{
                marginBottom: "4px",
                fontSize: "14px",
                fontWeight: theme.typography.Bold,
              }}
            >
              {i18n["empMyProfile.surnameReq"]}
            </Typography>
            <InputBox
              placeholder={i18n["empMyProfile.surnamePlace"]}
              value={profileData?.surname}
              id="surname"
              onChange={handleInputChange}
              sx={{
                border: errors?.find((error) => error?.key == "surname")
                  ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
                  : "1px solid #E0E0E0",
              }}
            />
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            mb: "20px",
            gap: { xs: "20px", tablet: "0px" },
            flexDirection: { xs: "column", tablet: "row" },
          }}
        >
          <Box sx={{ width: { xs: "100%", tablet: "47%" } }}>
            <Typography
              sx={{
                marginBottom: "4px",
                fontSize: "14px",
                fontWeight: theme.typography.Bold,
              }}
            >
              {i18n["empMyProfile.emailReq"]}
            </Typography>
            <InputBox
              placeholder={i18n["empMyProfile.emailPlace"]}
              value={profileData?.email}
              id="email"
              onChange={handleInputChange}
              sx={{
                border: errors?.find((error) => error?.key == "email")
                  ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
                  : "1px solid #E0E0E0",
              }}
            />
          </Box>
          <Box sx={{ width: { xs: "100%", tablet: "47%" } }}>
            <Typography
              sx={{
                marginBottom: "4px",
                fontSize: "14px",
                fontWeight: theme.typography.Bold,
              }}
            >
              {i18n["myProfile.genderLabelOpt"]}
            </Typography>
            <Box
              sx={{
                display: "flex",
                gap: "30px",
                flexDirection: { xs: "column", tablet: "row" },
              }}
            >
              <Paper
                elevation={3}
                sx={{
                  width: "176px",
                  height: "40px",
                  boxShadow: "none",
                  border: errors?.find((error) => error?.key === "gender")
                    ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
                    : `1px solid ${theme.palette.grayBorder}`,
                  borderRadius: "10px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Button
                    startIcon={getMaleIcon}
                    variant="contained"
                    color={
                      profileData?.gender == "male"
                        ? "blueButton400"
                        : "whiteButton"
                    }
                    onClick={(event) => handleGender(event, "male")}
                    sx={{
                      height: "40px",
                      width: "85px",
                      fontWeight: theme.typography.Regular,
                      borderRadius: "10px",
                      fontSize: "14px",
                      color:
                        profileData?.gender == "female" && theme.palette.black,
                      background:
                        profileData?.gender == "female" &&
                        theme.palette.base.main,
                      ":hover": {
                        boxShadow: "none",
                      },
                    }}
                    disabled={profileData?.gender_flag}
                  >
                    {i18n["empMyProfile.male"]}
                  </Button>
                  <Button
                    startIcon={getFemaleIcon}
                    sx={{
                      height: "40px",
                      width: "85px",
                      fontWeight: theme.typography.Regular,
                      borderRadius: "10px",
                      fontSize: "14px",
                      color:
                        profileData?.gender == "male" && theme.palette.black,
                      background:
                        profileData?.gender == "male" &&
                        theme.palette.base.main,
                      ":hover": {
                        boxShadow: "none",
                      },
                    }}
                    variant="contained"
                    color={
                      profileData?.gender == "female"
                        ? "purpleButton300"
                        : "grayBorder"
                    }
                    onClick={(event) => handleGender(event, "female")}
                    disabled={profileData?.gender_flag}
                  >
                    {i18n["empMyProfile.female"]}
                  </Button>
                </Box>
              </Paper>

              {/* <Box sx={{ display: "flex", alignItems: "center" }}>
                <Typography
                  sx={{
                    minWidth: "fit-content",
                  }}
                >
                  Prefer not to say
                </Typography>
                <Switch
                  checked={profileData?.gender_flag}
                  sx={{
                    "& .MuiSwitch-switchBase.Mui-checked": {
                      color: theme.palette.blueButton700.main,
                      "&:hover": {
                        backgroundColor: alpha(
                          theme.palette.blueButton700.main,
                          theme.palette.action.hoverOpacity
                        ),
                      },
                    },
                    "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
                      backgroundColor: theme.palette.blueButton700.main,
                    },
                    "& .MuiSwitch-track": {
                      margin: "auto",
                      height: "60% !important",
                    },
                    "& .MuiSwitch-thumb": {
                      borderRadius: "6px !important",
                    },
                    "& .MuiButtonBase-root-MuiSwitch-switchBase": {
                      borderRadius: "15% !important",
                    },
                    "& .MuiButtonBase-root": {
                      padding: "9px !important",
                    },
                  }}
                  onChange={(event) => {
                    handlePrefer(event, "gender_flag");
                    setPreferNotToSay((prevState) => !prevState);
                  }}
                />
              </Box> */}
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            mb: "20px",
            gap: { xs: "20px", tablet: "0px" },
            flexDirection: { xs: "column", tablet: "row" },
          }}
        >
          <Box sx={{ width: { xs: "100%", tablet: "47%" } }}>
            <Typography
              sx={{
                marginBottom: "4px",
                fontSize: "14px",
                fontWeight: theme.typography.Bold,
              }}
            >
              {i18n["empMyProfile.phoneNumberReq"]}
            </Typography>
            <Paper
              sx={{
                display: "flex",
                width: "100%",
                height: "40px",
                borderRadius: "10px",
                boxShadow: "none",
              }}
            >
              <Box
                sx={{
                  background: "transparent",
                  width: "100%",
                  height: "38px",
                  borderRadius: "10px",
                  display: "flex",
                  justifyContent: "space-between",
                  border: errors?.find((error) => error?.key === "contact_no")
                    ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
                    : `1px solid ${theme.palette.grayBorder}`,
                }}
              >
                <PhoneInput
                  international
                  id="contact_no"
                  placeholder="Enter phone number"
                  countryCallingCodeEditable={false}
                  value={
                    contactNumber?.startsWith("+")
                      ? contactNumber
                      : `${profileData?.country_code}${contactNumber}` || ""
                  }
                  onChange={(value) => {
                    handleContactNumber("contact_no", value);
                  }}
                  // defaultCountry={profileData?.country_code?.toUpperCase()}
                  focusInputOnCountrySelection={true}
                  onCountryChange={(value) => {
                    const newCountryId = value
                      ? countries?.find(
                          (val) => val?.name === regionNames?.of(value)
                        )?.id
                      : "";
                    handleCountryIdChange(newCountryId);
                  }}
                  style={{
                    font: "inherit",
                    padding: "10px",
                  }}
                />
                {isValidPhoneNumber(contactNumber || "") && (
                  <Typography
                    sx={{
                      color: theme.palette.tealColor.main,
                      fontSize: "14px",
                      alignItems: "center",
                      display: "flex",
                      gap: "5px",
                      marginRight: "5px",
                      justifyContent: "end",
                    }}
                  >
                    Correct <CheckCircle fontSize="small" />
                  </Typography>
                )}
              </Box>
            </Paper>
          </Box>
          <Box
            sx={{
              width: { xs: "100%", tablet: "47%" },
            }}
          >
            <Typography
              sx={{
                marginBottom: "4px",
                fontSize: "14px",
                fontWeight: theme.typography.Bold,
              }}
            >
              {i18n["empMyProfile.birthDateReq"]}
            </Typography>
            <Box
              sx={{
                width: "54%",
              }}
            >
              <LocalizationProvider dateAdapter={AdapterDayjs} locale="en-gb">
                <DatePicker
                  name="dob"
                  value={profileData?.dob}
                  onChange={handleDateChange}
                  maxDate={currentDate}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      sx={{
                        width: "100%",
                        "& .MuiInputBase-root": {
                          height: "40px",
                          width: "100%",
                          borderRadius: "10px",
                          border: errors?.find((error) => error?.key === "dob")
                            ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
                            : "1px solid #E0E0E0",
                          ":hover": {
                            border: errors?.find(
                              (error) => error?.key === "dob"
                            )
                              ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
                              : "1px solid #E0E0E0",
                          },
                        },
                        "& fieldset": {
                          border: "none",
                        },
                        "& .MuiIconButton-root": {
                          color: theme.palette.DobTextColor, // Change this to the desired color
                        },
                      }}
                    />
                  )}
                />
              </LocalizationProvider>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            mb: "20px",
            gap: { xs: "20px", tablet: "0px" },
            flexDirection: { xs: "column", tablet: "row" },
          }}
        >
          <Box sx={{ width: { xs: "100%", tablet: "47%" } }}>
            <Typography
              sx={{
                marginBottom: "4px",
                fontSize: "14px",
                fontWeight: theme.typography.Bold,
              }}
            >
              {i18n["empMyProfile.countryReq"]}
            </Typography>
            <CustomSelect
              onMenuOpen={() => {}}
              variant="default"
              onInputChange={handleOnSelectInputChange}
              onChange={handleOnSelectChange}
              placeholder={"Select"}
              inputId="country_id"
              name="country_id"
              value={
                profileData?.country_id && profileData?.country_name
                  ? {
                      value: profileData?.country_id
                        ? profileData?.country_id
                        : "",
                      label: profileData?.country_name
                        ? profileData?.country_name
                        : "",
                    }
                  : null
              }
              // isLoading={isLoading}
              filterOption={"filter"}
              customStyle={{
                menu: (provided) => ({
                  ...provided,
                  zIndex: 9999,
                }),
                menuList: (provided) => ({
                  ...provided,
                  "&::-webkit-scrollbar": {
                    width: "6px",
                    height: "6px",
                    borderRadius: "2.5px",
                    backgroundColor: "#efefef",
                  },
                  "&::-webkit-scrollbar-thumb": {
                    borderRadius: "2.5px",
                  },
                }),
                option: (
                  styles,
                  { data, isDisabled, isFocused, isSelected }
                ) => {
                  return {
                    ...styles,
                    color: isSelected ? "#fff" : "black",
                    ":active": {
                      ...styles[":active"],
                    },
                  };
                },
                control: (baseStyles) => ({
                  ...baseStyles,
                  boxShadow: "none",
                  borderRadius: "10px",
                  border: errors?.find((error) => error?.key == "country_id")
                    ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
                    : "1px solid #E0E0E0",
                  "&:hover": errors?.find((error) => error?.key == "country_id")
                    ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
                    : "1px solid #E0E0E0",
                  width: `100%`,
                }),
              }}
              option={countries?.map((item) => ({
                value: item?.country_id,
                label: item?.name,
              }))}
            />
          </Box>
          <Box sx={{ width: { xs: "100%", tablet: "47%" } }}>
            <Typography
              sx={{
                marginBottom: "4px",
                fontSize: "14px",
                fontWeight: theme.typography.Bold,
              }}
            >
              {i18n["empMyProfile.cityTownReq"]}
            </Typography>

            <Box
              sx={{
                width: { xs: "100%", tablet: "54%" },
              }}
            >
              <CustomSelect
                onMenuClose={onMenuClose}
                onMenuOpen={onMenuOpen}
                variant="default"
                onInputChange={handleOnSelectInputChange}
                onChange={handleOnSelectChange}
                placeholder={"Select"}
                inputId="town_id"
                name="town_id"
                value={
                  profileData?.town_id && profileData?.town_name
                    ? {
                        value: profileData?.town_id ? profileData?.town_id : "",
                        label: profileData?.town_name
                          ? profileData?.town_name
                          : "",
                      }
                    : null
                }
                isLoading={isLoading["town_id"]}
                customStyle={{
                  menu: (provided) => ({
                    ...provided,
                    zIndex: 9999,
                  }),
                  menuList: (provided) => ({
                    ...provided,
                    "&::-webkit-scrollbar": {
                      width: "6px",
                      height: "6px",
                      borderRadius: "2.5px",
                      backgroundColor: "#efefef",
                    },
                    "&::-webkit-scrollbar-thumb": {
                      borderRadius: "2.5px",
                    },
                  }),
                  option: (
                    styles,
                    { data, isDisabled, isFocused, isSelected }
                  ) => {
                    return {
                      ...styles,
                      color: isSelected ? "#fff" : "black",
                      ":active": {
                        ...styles[":active"],
                      },
                    };
                  },
                  control: (baseStyles) => ({
                    ...baseStyles,
                    boxShadow: "none",
                    borderRadius: "10px",
                    border: errors?.find((error) => error?.key == "town_id")
                      ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
                      : "1px solid #E0E0E0",
                    "&:hover": errors?.find((error) => error?.key == "town_id")
                      ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
                      : "1px solid #E0E0E0",
                  }),
                }}
                option={town?.map((item) => ({
                  value: item?.town_id,
                  label: item?.name,
                }))}
              />
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            mb: "20px",
            gap: { xs: "20px", tablet: "0px" },
            flexDirection: { xs: "column", tablet: "row" },
          }}
        >
          <Box sx={{ width: { xs: "100%", tablet: "47%" } }}>
            <Typography
              sx={{
                marginBottom: "4px",
                fontSize: "14px",
                fontWeight: theme.typography.Bold,
              }}
            >
              {i18n["empMyProfile.linkedinProfileOpt"]}
            </Typography>
            <InputBox
              placeholder={i18n["empMyProfile.linkedprofile"]}
              value={
                profileData?.linkedin_profile_link
                  ? profileData?.linkedin_profile_link
                  : ""
              }
              sx={{
                border: errors?.find(
                  (error) => error?.key === "linkedin_profile_link"
                )
                  ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
                  : "1px solid #E0E0E0",
              }}
              id="linkedin_profile_link"
              onChange={handleInputChange}
            />
          </Box>
          {/* <Box sx={{ width: { xs: "100%", tablet: "47%" } }}> */}
            {/* <Typography
              sx={{
                marginBottom: "4px",
                fontSize: "14px",
                fontWeight: theme.typography.Bold,
              }}
            >
              {i18n["empMyProfile.changePasswordOpt"]}
            </Typography> */}
            {/* <Paper
              sx={{
                display: "flex",
                height: "40px",
                borderRadius: "10px",
                boxShadow: "none",
                border: errors?.find((error) => error?.key === "password")
                  ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
                  : "1px solid #E0E0E0",
              }}
            >
              <Paper
                elevation={0}
                component="form"
                sx={{
                  display: "flex",
                  borderRadius: "25px",
                  alignItems: "center",
                  width: 1,
                }}
              >
                <InputBase
                  sx={{
                    ml: 2,
                    width: 1,
                  }}
                  value={newPassword}
                  onChange={(event) => handlePassword(event)}
                  placeholder={i18n["empMyProfile.changePasswordPlace"]}
                  type={inputType}
                />
              </Paper>
              <IconButton
                color=""
                aria-label="reset password"
                component="button"
                onClick={handleShowPassword}
              >
                {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
              </IconButton>
              <Button
                sx={{
                  ml: "4px",
                  minWidth: "fit-content",
                  boxShadow: 0,
                  height: "auto",
                  width: "171px",
                  borderRadius: "0 10px 10px 0",
                }}
                disabled={newPassword?.length === 0}
                variant="contained"
                color="resetPasswordButton"
                onClick={handlePasswordReset}
              >
                {i18n["empMyProfile.resetPassword"]}
              </Button>
            </Paper>
            {errors?.find((error) => error?.key === "password") && (
              <Typography
                sx={{
                  fontSize: "14px",
                  fontWeight: theme.typography.Medium,
                  color: "red",
                  mt: "4px",
                  // textAlign: "center",
                  // width: "272px",
                }}
              >
                {i18n["alert.passwordmustcontain"]}
              </Typography>
            )} */}
          {/* </Box> */}
          <Box sx={{ width: { xs: "100%", tablet: "47%" } }}>
            <Typography
              sx={{
                marginBottom: "4px",
                fontSize: "14px",
                fontWeight: theme.typography.Bold,
              }}
            >
              {i18n["empMyProfile.calendarLinkOpt"]}
            </Typography>
            <InputBox
              placeholder={i18n["empMyProfile.linkedprofile"]}
              value={
                profileData?.calendar_link ? profileData?.calendar_link : ""
              }
              sx={{
                border: errors?.find((error) => error?.key === "calendar_link")
                  ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
                  : "1px solid #E0E0E0",
              }}
              id="calendar_link"
              onChange={handleInputChange}
            />
          </Box>
        </Box>
        {/* <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            mb: "20px",
            gap: { xs: "20px", tablet: "0px" },
            flexDirection: { xs: "column", tablet: "row" },
          }}
        >
          <Box sx={{ width: { xs: "100%", tablet: "47%" } }}>
            <Typography
              sx={{
                marginBottom: "4px",
                fontSize: "14px",
                fontWeight: theme.typography.Bold,
              }}
            >
              {i18n["empMyProfile.calendarLinkOpt"]}
            </Typography>
            <InputBox
              placeholder={i18n["empMyProfile.linkedprofile"]}
              value={
                profileData?.calendar_link ? profileData?.calendar_link : ""
              }
              sx={{
                border: errors?.find((error) => error?.key === "calendar_link")
                  ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
                  : "1px solid #E0E0E0",
              }}
              id="calendar_link"
              onChange={handleInputChange}
            />
          </Box>
        </Box> */}
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          mt: "30px",
        }}
      >
        <Button
          onClick={() => {
            saveInfo(profileData, false);
          }}
          variant="contained"
          color="lockItinButton"
          sx={{
            width: "150px",
            height: "60px",
            fontSize: "15px",
            fontWeight: theme.typography.Bold,
            borderRadius: "10px 0 0 0",
            ":hover": {
              boxShadow: "none",
            },
          }}
        >
          lock it in
        </Button>
        <Button
          onClick={() => {
            saveInfo(profileData, true);
          }}
          variant="contained"
           color="nandos"
          sx={{
            width: "150px",
            height: "60px",
            fontSize: "15px",
            fontWeight: theme.typography.Bold,
            borderRadius: "0 10px 0 0 ",
            ":hover": {
              boxShadow: "none",
            },
          }}
        >
          company info
        </Button>
      </Box>
    </>
  );
}
