import { useTheme } from "@mui/material/styles";

import { Paper, InputBase } from "@mui/material";
import { Modal } from "@mui/material";
import React, { useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

const LinkedToPoolModal = ({
  handleOpen,
  handleClose,
  handleLinkedPoolSave,
  handleLinkedPoolCancel,
  talentName
}) => {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    borderRadius: "12px",
    p: 4,
    boxShadow: "none !important",
    border: "1px solid black !important",
  };
  return (
    <Modal
      open={handleOpen}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography
          id="modal-modal-title"
          variant="h6"
          component="h2"
          textAlign={"center"}
          sx={{ marginBottom: "10px" }}
        >
          Add Candidates to Talent Pool
        </Typography>
        <Box>
          <Box sx={{ textAlign: "center", marginBottom: "20px" }}>
         { `Are you sure you want to add these candidates to the '${talentName}' Talent Pool?`}
          </Box>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            sx={{
              height: "38px",
              mr: 2,
              borderRadius: "10px",
              color: "black",
            }}
            variant="contained"
            color="grayButton300"
            onClick={handleLinkedPoolSave}
          >
            YES
          </Button>
          <Button
            sx={{
              height: "38px",
              mr: 2,
              borderRadius: "10px",
            }}
            onClick={handleLinkedPoolCancel}
            variant="contained"
            color="redButton100"
          >
            NO
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default LinkedToPoolModal;
