import { InputBase, InputLabel, Modal, Paper } from "@mui/material";
import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import "cropperjs/dist/cropper.css";
import { useTheme } from "@emotion/react";
import { useDispatch } from "react-redux";
import { addSpendItem, editPlan, editSpendItem } from "../../../redux/admin/maintenance";
import { setAlert } from "../../../redux/configSlice";
import { ALERT_TYPE } from "../../../utils/Constants";
import * as Yup from "yup";
import { validateSchema } from "../../../utils/Common";

const EditCrayonPlan = ({
  handleOpen,
  handleClose,
  selectedCoinItem,
  getCrayonPlan,
  selectedCrayonCoinItem,
}) => {
  const [formData, setFormData] = useState({ crayon_plan_id: "",
  type: "",
  starter: "",
  scaler: "",
  launcher: "",
  orbiter: ""});
  const [errors, setErrors] = useState({});
  const dispatch = useDispatch();
  const formSchema = Yup.object().shape({
    starter: Yup.number()
      .typeError("starter must be a number")
      .required("starter is required")
      .min(0, "starter must be at least 0"),
    scaler: Yup.number()
      .typeError("scaler must be a number")
      .required("scaler is required")
      .min(0, "scaler must be at least 0"),
    launcher: Yup.number()
      .typeError("launcher must be a number")
      .required("launcher is required")
      .min(0, "launcher must be at least 0"),
    orbiter: Yup.number()
      .typeError("orbiter must be a number")
      .required("orbiter is required")
      .min(0, "orbiter must be at least 0"),
  });

  const theme = useTheme();
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: { xs: "auto", tablet: 700 },
    bgcolor: "background.paper",
    borderRadius: "25px",
    boxShadow: "none !important",
    ".&focus-visible": {
      outlineColor: "none",
    },
  };

  const handleFormInput = (event, name) => {
    event?.preventDefault();
    event?.stopPropagation();
    setFormData((prev) => ({ ...prev, [name]: event?.target?.value }));
    setErrors((prev) => ({ ...prev, [name]: '' }));
  };

  const editItem = async () => {
    validateSchema(formSchema, formData)
      .then(() => {
        dispatch(editPlan(formData)).then((payload) => {
          if (payload?.payload?.status === "success") {
            getCrayonPlan();
            handleCancel();
          } else {
            dispatch(
              setAlert({
                show: true,
                type: ALERT_TYPE?.ERROR,
                msg: "Something went wrong",
              })
            );
          }
        });
      })
      .catch((error) => {
        setErrors(error);
      });
  };
  const handleCreate = () => {
    editItem();
  };

  const handleCancel = () => {
    handleClose();
  };
  useEffect(() => {
    if (selectedCrayonCoinItem) {
        setFormData({
          crayon_plan_id: selectedCrayonCoinItem?.crayon_plan_id,
          type: selectedCrayonCoinItem?.type,
          starter: selectedCrayonCoinItem?.starter,
          scaler: selectedCrayonCoinItem?.scaler,
          launcher: selectedCrayonCoinItem?.launcher,
          orbiter: selectedCrayonCoinItem?.orbiter,
        });
      }
  }, [selectedCrayonCoinItem]);

  return (
    <Modal open={handleOpen} onClose={handleClose}>
    <Box sx={style}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "end",
        }}
      >
        <Button
          variant="contained"
          color="yellowButton400"
          sx={{
            height: "45px",
            width: "200px",
            borderRadius: "0 25px 0 25px",
          }}
        >
          Edit Crayon plans
        </Button>
      </Box>
      <Box sx={{ padding: "20px" }}>
        <Box sx={{ marginBottom: "5px" }}>
          <InputLabel
            htmlFor="type"
            sx={{
              color: "black",
              paddingBottom: "2px",
              fontSize: "14px",
              fontWeight: 600,
            }}
          >
            Plan Type
          </InputLabel>
          <Paper
            elevation={3}
            sx={{
              display: "flex",
              borderRadius: "10px",
              height: "40px",
              boxShadow: "none",
              border: errors?.["type"]
                ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
                : `1px solid ${theme.palette.grayBorder}`,
            }}
          >
            <InputBase
              disabled={true}
              type="type"
              sx={{ ml: 2, mr: 2, width: "100%" }}
              id="type"
              placeholder={"Enter your Plan Type"}
              value={formData?.type}
              onChange={(e) => handleFormInput(e, "type")}
            />
          </Paper>
        </Box>
        <Box sx={{ marginBottom: "5px" }}>
          <InputLabel
            htmlFor="starter"
            sx={{
              color: "black",
              paddingBottom: "2px",
              fontSize: "14px",
              fontWeight: 600,
            }}
          >
            Starter
          </InputLabel>
          <Paper
            elevation={3}
            sx={{
              display: "flex",
              borderRadius: "10px",
              height: "40px",
              boxShadow: "none",
              border: errors?.["starter"]
                ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
                : `1px solid ${theme.palette.grayBorder}`,
            }}
          >
            <InputBase
              type="number"
              sx={{ ml: 2, mr: 2, width: "100%" }}
              id="starter"
              placeholder={"Enter your starter"}
              value={formData?.starter}
              onChange={(e) => handleFormInput(e, "starter")}
            />
          </Paper>
        </Box>
        <Box sx={{ marginBottom: "5px" }}>
          <InputLabel
            htmlFor="scaler"
            sx={{
              color: "black",
              paddingBottom: "2px",
              fontSize: "14px",
              fontWeight: 600,
            }}
          >
            Scaler
          </InputLabel>
          <Paper
            elevation={3}
            sx={{
              display: "flex",
              borderRadius: "10px",
              height: "40px",
              boxShadow: "none",
              border: errors?.["scaler"]
                ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
                : `1px solid ${theme.palette.grayBorder}`,
            }}
          >
            <InputBase
              type="number"
              sx={{ ml: 2, mr: 2, width: "100%" }}
              id="scaler"
              placeholder={"Enter your scaler"}
              value={formData?.scaler}
              onChange={(e) => handleFormInput(e, "scaler")}
            />
          </Paper>
        </Box>
        <Box sx={{ marginBottom: "5px" }}>
          <InputLabel
            htmlFor="launcher"
            sx={{
              color: "black",
              paddingBottom: "2px",
              fontSize: "14px",
              fontWeight: 600,
            }}
          >
            Launcher
          </InputLabel>
          <Paper
            elevation={3}
            sx={{
              display: "flex",
              borderRadius: "10px",
              height: "40px",
              boxShadow: "none",
              border: errors?.["launcher"]
                ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
                : `1px solid ${theme.palette.grayBorder}`,
            }}
          >
            <InputBase
              type="number"
              sx={{ ml: 2, mr: 2, width: "100%" }}
              id="launcher"
              placeholder={"Enter your launcher"}
              value={formData?.launcher}
              onChange={(e) => handleFormInput(e, "launcher")}
            />
          </Paper>
        </Box>
        <Box sx={{ marginBottom: "5px" }}>
          <InputLabel
            htmlFor="orbiter"
            sx={{
              color: "black",
              paddingBottom: "2px",
              fontSize: "14px",
              fontWeight: 600,
            }}
          >
            Orbiter
          </InputLabel>
          <Paper
            elevation={3}
            sx={{
              display: "flex",
              borderRadius: "10px",
              height: "40px",
              boxShadow: "none",
              border: errors?.["orbiter"]
                ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
                : `1px solid ${theme.palette.grayBorder}`,
            }}
          >
            <InputBase
              type="number"
              sx={{ ml: 2, mr: 2, width: "100%" }}
              id="orbiter"
              placeholder={"Enter your orbiter"}
              value={formData?.orbiter}
              onChange={(e) => handleFormInput(e, "orbiter")}
            />
          </Paper>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          width: "100%",
        }}
      >
        <Button
          variant="contained"
          color="grayButton100"
          sx={{
            borderRadius: 0,
            width: "50%",
            height: "47px",
            borderBottomLeftRadius: 25,
          }}
          onClick={handleCancel}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          color="yellowButton100"
          sx={{
            borderRadius: 0,
            width: "50%",
            height: "47px",
            borderBottomRightRadius: 25,
          }}
          onClick={handleCreate}
        >
          Edit
        </Button>
      </Box>
    </Box>
  </Modal>

  );
};

export default EditCrayonPlan;
