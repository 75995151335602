import {
  Avatar,
  Box,
  Button,
  Card,
  InputBase,
  Paper,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import InputBox from "../../common/InputBox";
import locale from "../../../i18n/locale";
import { useTheme } from "@mui/material/styles";
import PhoneInput, {
  formatPhoneNumberIntl,
  isValidPhoneNumber,
} from "react-phone-number-input";
import { CheckCircle } from "@mui/icons-material";
import signupIcon from "../../../assets/ContactUs/BookDemo.svg";
import YouTubeEmbed from "./YouTubeEmbed";

const BookADemoContactComponent = ({
  heading,
  subHeading,
  content,
  Button1Text,
  Button2Text,
  handleYoutubeLink,
  handleBookDemo,
  newCardWidth,
}) => {
  const theme = useTheme();
  const i18n = locale.en;
  const handleSubmit = () => {};

  return (
    <Card
      sx={{
        borderRadius: "25px",
        position: "relative",
        boxShadow: "0px 5px 10px #00000029",
        width: newCardWidth,
        maxWidth:"374px",
        height: "695px",
        display: "flex",
        flexDirection: "column",
      }}
      className="cardboxdetail"
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          paddingLeft: "15px",
          paddingRight: "15px",
        }}
      >
        <Box sx={{ marginTop: "15px" }}>
          <Box
            component={"img"}
            src={signupIcon}
            sx={{ width: 124, height: 125 }}
          />
        </Box>
        <Typography
          sx={{
            fontSize: theme.typography.fontSize.xl,
            letterSpacing: "0.4px",
            // lineHeight: "32px",
            marginTop: "15px",
            fontWeight: theme.typography.Bold,
            textAlign: "center",
          }}
        >
          {heading}
        </Typography>
        <Typography
          sx={{
            fontSize: theme.typography.fontSize.sm,
            marginTop: "15px",
            fontWeight: theme.typography.Bold,
            letterSpacing: "0.28px",
            marginBottom: "19px",
            textAlign: "center",
          }}
        >
          {subHeading}
        </Typography>
        <Typography
          sx={{
            fontSize: theme.typography.fontSize.xs,
            letterSpacing: "0.24px",
            fontWeight: theme.typography.Medium,
            // lineHeight: "16px",
            marginBottom: "35px",
            textAlign: "center",
          }}
        >
          {content}
        </Typography>
      </Box>
      <Box
        sx={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          paddingX: "20px",
          paddingTop: "0px",
          paddingBottom: "36px",
          // width:"100%",
          borderRadius: "25px",
        }}
      >
        <YouTubeEmbed videoId={"JoUi4bVhQP0"} />
      </Box>
      <Box
        sx={{
          display: "flex",
          width: "100%",
          justifyContent: "space-between",
        }}
      >
        <Button
          sx={{
            width: "100%",
            height: "60px",
            maxHeight: "60px",
            minHeight: "60px",
            borderRadius: 0,
            padding: 3,
            fontSize: theme.typography.fontSize.sm,
            fontWeight: theme.typography.Bold,
          }}
          variant="contained"
          color="grayButton600"
          onClick={handleYoutubeLink}
        >
          {Button1Text}
        </Button>
        <Button
          sx={{
            width: "100%",
            height: "60px",
            maxHeight: "60px",
            minHeight: "60px",
            borderRadius: 0,
            padding: 3,
            fontSize: theme.typography.fontSize.sm,
            fontWeight: theme.typography.Bold,
          }}
          variant="contained"
          color="deleteVideoButton"
          onClick={handleBookDemo}
        >
          {Button2Text}
        </Button>
      </Box>
    </Card>
  );
};

export default BookADemoContactComponent;
