import {
  Box,
  Button,
  IconButton,
  InputBase,
  InputLabel,
  Paper,
  Typography,
} from "@mui/material";
import React, { useRef, useState } from "react";
import IconSection from "../../adminTalent/IconSection";
import { useTheme } from "@emotion/react";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import CustomSelect from "../../../candidate/myCV/CustomSelect";
import locale from "../../../../i18n/locale";
import CropModal from "../../../employer/myProfile/cropModal";
import { uploadCrayonTeamLogo } from "../../../../redux/admin/userAdminSlice";
import { setAlert } from "../../../../redux/configSlice";
import { ALERT_TYPE } from "../../../../utils/Constants";
import { useDispatch } from "react-redux";
import companyLogo from "../../../../assets/user_logo.svg";

const CommonCreate = ({
  errors,
  formData,
  handleFormInput,
  handleCancel,
  handleCreate,
  pageTitle,
  edit,
  handleCreateSelectOnChange,
  roleType,
  roleList,
  setFormData,
}) => {
  const theme = useTheme();
  const i18n = locale.en;
  const hiddenFileInput = useRef(null);
  const dispatch = useDispatch();

  const [showNewPassword, setShowNewPassword] = useState(false);
  const [roleTypeList, setRoleTypeList] = useState([]);
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [imageBlob, setImageBlob] = useState();
  const [croppedImageUrl, setCroppedImageUrl] = useState(null);
  const [imagePreview, setImagePreview] = useState([]);
  const [imageName, setImageName] = useState("My pic");
  const [image, setImage] = useState([]);
  const [openEditImage, setOpenEditImage] = useState(false);
  const [profilePic, setProfielPic] = useState(formData?.profile_pic);

  const handleShowPassword = () => {
    setShowNewPassword(!showNewPassword);
  };
  const handleSelectOnChange = (newValue, inputId) => {
    let updatedState = { ...formData };

    updatedState["roleType"] = inputId;
    setFormData({ ...updatedState });
  };
  // const handleOnSelectChange = (ind, newValue, val, index) =>{

  // }

  const handleImageClick = (e) => {
    setImagePreview(null);
    hiddenFileInput?.current?.click();
  };

  const cropInputFUnction = (e) => {
    const files = e?.target?.files;
    if (files && files?.length > 0) {
      const reader = new FileReader();
      reader.onload = () => setImage(reader?.result);
      reader?.readAsDataURL(files?.[0]);
    }
  };

  const handleImageChange = async (event) => {
    if (event?.target?.files?.[0] === undefined) {
      return;
    }
    cropInputFUnction(event);
    const render = new FileReader();
    setZoom(1);
    setImageName(event?.target?.files?.[0].name);
    const imageData = window.URL.createObjectURL(
      new Blob(event?.target?.files, { type: "application/*" })
    );
    setImagePreview(imageData);
    setOpenEditImage(true);
    event.target.value = "";
  };

  const handleSubscriptionOk = async () => {
    // const croppedImage = await getCroppedImg(
    //   imagePreview,
    //   croppedAreaPixels,
    //   0
    // );
    const imageData = new FormData();
    const blobTofile = new File([imageBlob], imageName, {
      type: "image/jpeg",
    });

    if (edit) {
      imageData?.append("profile-pic", blobTofile);
      imageData?.append("admin_id", formData?.userId);
    } else {
      imageData?.append("profile-pic", blobTofile);
    }

    try {
      const { payload } = await dispatch(uploadCrayonTeamLogo(imageData));
      if (payload?.status === "success") {
        setOpenEditImage(false);
        setFormData({
          ...formData,
          profile_pic: payload?.fileName,
        });
        setProfielPic(payload?.data);
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE?.SUCCESS,
            msg: i18n["alertMessage.logoUploaded"],
          })
        );
      } else if (payload?.status === "error") {
        setImageName("My pic");
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE?.ERROR,
            msg: i18n["alertMessage.Imageformatnotsupported"],
          })
        );
      }
    } catch (error) {
      setImageName("My pic");
      dispatch(setAlert({ show: true }));
    }
  };

  const handleSubscriptionCancel = () => {
    setImageName("My pic");
    setOpenEditImage(false);
    setImageBlob();
    setCroppedImageUrl(null);
    setImage();
  };

  return (
    <>
      <Paper
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: 2,
          mt: 6,
          pt: 2,
        }}
      >
        <IconSection />
        <Box>
          <Typography
            sx={{
              fontWeight: 700,
              textAlign: "center",
            }}
          >
            {`${edit ? "Edit" : "Create"} ${pageTitle}`}
          </Typography>
        </Box>
        <Box
          sx={{
            width: "30%",
            display: "flex",
            flexDirection: "column",
            gap: 1,
          }}
        >
          <Box sx={{ marginBottom: "5px" }}>
            <InputLabel
              htmlFor="first_name"
              sx={{
                color: "black",
                // paddingLeft: "10px",
                paddingBottom: "2px",
                fontSize: "14px",
                fontWeight: 600,
              }}
            >
              First Name
            </InputLabel>
            <Paper
              elevation={3}
              sx={{
                display: "flex",
                borderRadius: "10px",
                height: "40px",
                boxShadow: "none",
                // border: "1px solid red",
                border: errors?.["first_name"]
                  ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
                  : `1px solid ${theme.palette.grayBorder}`,
              }}
            >
              <InputBase
                sx={{ ml: 2, mr: 2, width: "100%" }}
                id="first_name"
                placeholder={"Enter your first name"}
                value={formData?.first_name}
                onChange={(e) => handleFormInput(e, "first_name")}
              />
            </Paper>
          </Box>
          <Box sx={{ marginBottom: "5px" }}>
            <InputLabel
              htmlFor="last_name"
              sx={{
                color: "black",
                paddingBottom: "2px",
                fontSize: "14px",
                fontWeight: 600,
              }}
            >
              Last Name
            </InputLabel>
            <Paper
              elevation={3}
              sx={{
                display: "flex",
                borderRadius: "10px",
                height: "40px",
                boxShadow: "none",
                border: errors?.["last_name"]
                  ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
                  : `1px solid ${theme.palette.grayBorder}`,
              }}
            >
              <InputBase
                sx={{ ml: 2, mr: 2, width: "100%" }}
                id="last_name"
                placeholder={"Enter your last name"}
                value={formData?.last_name}
                onChange={(e) => handleFormInput(e, "last_name")}
              />
            </Paper>
          </Box>
          <Box sx={{ marginBottom: "5px" }}>
            <InputLabel
              htmlFor="email"
              sx={{
                color: "black",
                // paddingLeft: "10px",
                paddingBottom: "2px",
                fontSize: "14px",
                fontWeight: 600,
              }}
            >
              Email
            </InputLabel>
            <Paper
              elevation={3}
              sx={{
                display: "flex",
                borderRadius: "10px",
                height: "40px",
                boxShadow: "none",
                border: errors?.["email"]
                  ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
                  : `1px solid ${theme.palette.grayBorder}`,
              }}
            >
              <InputBase
                sx={{ ml: 2, mr: 2, width: "100%" }}
                id="email"
                placeholder={"Enter your email"}
                value={formData?.email}
                onChange={(e) => handleFormInput(e, "email")}
              />
            </Paper>
          </Box>
          {edit && (
            <Box sx={{ marginBottom: "5px" }}>
              <InputLabel
                htmlFor="roleType"
                sx={{
                  color: "black",
                  // paddingLeft: "10px",
                  paddingBottom: "2px",
                  fontSize: "14px",
                  fontWeight: 600,
                }}
              >
                Role Type
              </InputLabel>
              {/* <Paper
              elevation={3}
              sx={{
                display: "flex",
                borderRadius: "10px",
                height: "40px",
                boxShadow: "none",
                border: errors?.["roleType"]
                  ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
                  : `1px solid ${theme.palette.grayBorder}`,
              }}
            > */}
              {/* <InputBase
             sx={{ ml: 2, mr: 2, width: "100%" }}
             id="email"
             placeholder={"Enter your roleType"}
             value={formData?.email}
             onChange={(e) => handleFormInput(e, "roleType")}
           /> */}
              {edit ? (
                <CustomSelect
                  onMenuOpen={() => {}}
                  variant="default"
                  onInputChange={handleCreateSelectOnChange}
                  onChange={handleSelectOnChange}
                  placeholder={`Select RoleType`}
                  inputId="roleType"
                  name="roleType"
                  isClearable={false}
                  value={formData["roleType"]}
                  // isLoading={isLoading}
                  customStyle={{
                    menu: (provided) => ({
                      ...provided,
                      zIndex: 9999,
                      width: "100%",
                    }),
                    menuList: (provided) => ({
                      ...provided,
                      "&::-webkit-scrollbar": {
                        width: "6px",
                        height: "6px",
                        borderRadius: "2.5px",
                        backgroundColor: "#efefef",
                      },
                      "&::-webkit-scrollbar-thumb": {
                        borderRadius: "2.5px",
                      },
                      width: "100%",
                    }),
                    option: (
                      styles,
                      { data, isDisabled, isFocused, isSelected }
                    ) => {
                      return {
                        ...styles,
                        color: isSelected ? "#fff" : "black",
                        ":active": {
                          ...styles[":active"],
                          width: "100%",
                        },
                      };
                    },
                    control: (baseStyles) => ({
                      ...baseStyles,
                      boxShadow: "none",
                      borderRadius: "10px",
                      border: "1px solid #E0E0E0",
                      "&:hover": "1px solid #E0E0E0",
                      width: `100%`,
                    }),
                  }}
                  option={roleList?.map((item) => ({
                    value: item?.roleTypeId,
                    label: item?.name,
                  }))}
                />
              ) : null}

              {/* </Paper> */}
            </Box>
          )}

          {edit ? null : (
            <Box sx={{ marginBottom: "5px" }}>
              <InputLabel
                htmlFor="password"
                sx={{
                  color: "black",
                  paddingBottom: "2px",
                  fontSize: "14px",
                  fontWeight: 600,
                }}
              >
                Password
              </InputLabel>
              <Paper
                elevation={3}
                sx={{
                  display: "flex",
                  borderRadius: "10px",
                  height: "40px",
                  boxShadow: "none",
                  border: errors?.["password"]
                    ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
                    : `1px solid ${theme.palette.grayBorder}`,
                }}
              >
                <InputBase
                  type={`${showNewPassword ? "text" : "password"}`}
                  sx={{ ml: 2, mr: 2, width: "100%" }}
                  id="password"
                  placeholder={"Enter your password"}
                  value={formData?.password}
                  onChange={(e) => handleFormInput(e, "password")}
                />
                <IconButton
                  sx={{ py: 0 }}
                  color=""
                  aria-label="reset password"
                  component="button"
                  onClick={handleShowPassword}
                  disableRipple={true}
                >
                  {showNewPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                </IconButton>
              </Paper>
              {errors?.["password"] && formData?.password !== "" && (
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: theme.typography.Medium,
                    color: "red",
                    mt: "4px",
                  }}
                >
                  {i18n["alert.passwordmustcontain"]}
                </Typography>
              )}
            </Box>
          )}

          <Box sx={{ marginBottom: "5px" }}>
            <InputLabel
              htmlFor="email"
              sx={{
                color: "black",
                // paddingLeft: "10px",
                paddingBottom: "2px",
                fontSize: "14px",
                fontWeight: 600,
              }}
            >
              Calendar link
            </InputLabel>
            <Paper
              elevation={3}
              sx={{
                display: "flex",
                borderRadius: "10px",
                height: "40px",
                boxShadow: "none",
                border: errors?.["calendar_link"]
                  ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
                  : `1px solid ${theme.palette.grayBorder}`,
              }}
            >
              <InputBase
                sx={{ ml: 2, mr: 2, width: "100%" }}
                id="calendar_link"
                placeholder={"Enter calendar link"}
                value={formData?.calendar_link}
                onChange={(e) => handleFormInput(e, "calendar_link")}
              />
            </Paper>
          </Box>

          <Box
            sx={{
              marginBottom: "5px",
              display: "flex",
              alignItems: "center",
              gap: "60px",
            }}
          >
            <input
              ref={hiddenFileInput}
              type="file"
              accept="image/*"
              onChange={(event) => handleImageChange(event, "company")}
              style={{ display: "none" }}
            />
            <Box
              component={"img"}
              className="companyLogo"
              alt="crayon logo"
              src={
                profilePic !== "NO URL" && profilePic !== undefined
                  ? profilePic
                  : companyLogo
              }
              sx={{
                height: "100px",
                width: "100px",
                // border: 1,
                // borderColor: "lightgrey",
                borderRadius: "10px",
              }}
            />
            <Button
              color="yellowButton100"
              variant="contained"
              type="file"
              sx={{
                height: "45px",
                width: "150px",
                fontSize: "14px",
                padding: 0,
                display: "flex",
                flexDirection: "column",
                gap: "20px",
                borderRadius: "10px",
                boxShadow: 0,
                ":hover": {
                  boxShadow: 0,
                },
                "& .MuiButton-startIcon": {
                  margin: "0 !important",
                },
              }}
              onClick={handleImageClick}
            >
              {edit ? "Edit image" : "Upload image"}
            </Button>
          </Box>
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            width: "25%",
          }}
        >
          <Button
            variant="contained"
            color="grayButton100"
            sx={{
              borderRadius: 0,
              width: "33.33%",
              height: "47px",
              borderTopLeftRadius: 25,
            }}
            onClick={handleCancel}
          >
            go back
          </Button>
          <Button
            variant="contained"
            color="yellowButton100"
            sx={{
              borderRadius: 0,
              width: "33.33%",
              height: "47px",
              borderTopRightRadius: 25,
            }}
            onClick={() => {
              handleCreate();
            }}
          >
            {`${edit ? "edit" : "create"}`}
          </Button>
        </Box>
      </Paper>
      {openEditImage && (
        <CropModal
          handleOpen={openEditImage}
          handleClose={setOpenEditImage}
          handleSubscriptionOk={handleSubscriptionOk}
          handleSubscriptionCancel={handleSubscriptionCancel}
          imagePreview={imagePreview}
          image={image}
          setImage={setImage}
          croppedImageUrl={croppedImageUrl}
          setCroppedImageUrl={setCroppedImageUrl}
          imageBlob={imageBlob}
          setImageBlob={setImageBlob}
        />
      )}
    </>
  );
};

export default CommonCreate;
