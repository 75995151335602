import { Box, Typography, useTheme } from "@mui/material";
import React from "react";

export default function SortDropDown({ list, handleClick }) {
  const theme = useTheme();
  return (
    <Box
      sx={{
        padding: "16px !important",
      }}
    >
      <Typography
        sx={{
          fontSize: "12px",
          fontWeight: theme.typography.Medium,
          cursor: "pointer",
        }}
        onClick={() => {
          handleClick(list?.[0]?.id);
        }}
      >
        {list?.[0]?.name}
      </Typography>
    </Box>
  );
}
