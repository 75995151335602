import {
  Box,
  InputLabel,
  useTheme,
  Tooltip,
  Typography,
  TextField,
} from "@mui/material";
import React from "react";
import TextEditor from "../../../common/postJobsSection/TextEditor";
import { BlueSwitch, LightTooltip } from "../../../../utils/CommonComponent";

export default function DetailInfo({
  jobDetail,
  setJobDetail,
  errors,
  setErrors,
  setSameJobAndBio,
  sameJobAndBio,
}) {
  const theme = useTheme();
  const handleInputChange = (inputText, type) => {
    const newDetailData = {
      ...jobDetail,
      [type]: inputText,
    };
    setErrors(errors.filter((item) => item.key !== type));
    setJobDetail(newDetailData);
  };

  const handleSwitch = (event) => {
    setSameJobAndBio(event?.target?.checked);
    const newDetailData = {
      ...jobDetail,
      [event?.target?.name]: event?.target?.checked,
    };
    setJobDetail(newDetailData);
  };

  const handleJobBioChange = (event) => {
    const newDetailData = {
      ...jobDetail,
      job_bio: event?.target?.value,
    };
    setJobDetail(newDetailData);
  };

  return (
    <Box sx={{ p: 4, display: "flex", flexDirection: "column", gap: "16px" }}>
      {!sameJobAndBio && (
        <Box>
          <InputLabel
            htmlFor="jobBio"
            sx={{
              color: "black",
              pb: 1,
              fontSize: "14px",
              fontWeight: theme.typography.Bold,
            }}
          >
            Job bio (this will show on the job card)*
          </InputLabel>
          <TextField
            name="job_bio"
            value={jobDetail?.job_bio}
            onChange={handleJobBioChange}
            multiline
            rows={2}
            sx={{
              width: "100%",
              height: "80px",
              borderColor: theme.palette.borderColor,
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderRadius: "10px",
                  border: errors?.find((error) => error?.key === "job_bio")
                    ? "1px solid red"
                    : `1px solid ${theme.palette.borderColor}`,
                },
                "&:hover fieldset": {
                  border: errors?.find((error) => error?.key === "job_bio")
                    ? "1px solid red"
                    : `1px solid ${theme.palette.borderColor}`,
                },
                "&.Mui-focused fieldset": {
                  borderColor: theme.palette.borderColor,
                },
              },
            }}
          ></TextField>
        </Box>
      )}
      <Box>
        <Box
          sx={{
            display: "flex",
            gap: "10px",
            height: "19px",
            pb: 1,
            alignItems: "center",
          }}
        >
          <InputLabel
            htmlFor="roleSummary"
            sx={{
              color: "black",
              fontSize: "14px",
              fontWeight: theme.typography.Bold,
            }}
          >
            How they’ll role (job summary)*
          </InputLabel>
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: theme.typography.Regular,
            }}
          >
            Use this for job bio
          </Typography>
          <Tooltip disableInteractive>
            <LightTooltip
              title={"Role summary and bio will be same if turned on"}
              placement="right"
            >
              <Typography
                sx={{
                  height: "17px",
                  width: "17px",
                  borderRadius: "5px",
                  fontSize: "12px",
                  lineHeight: 0.1,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  fontWeight: theme.typography.Bold,
                  boxShadow: "0px 2px 4px #00000029",
                  border: 1,
                }}
              >
                i
              </Typography>
            </LightTooltip>
          </Tooltip>
          <BlueSwitch
            name="job_summary_flag"
            checked={sameJobAndBio}
            onChange={(event) => handleSwitch(event)}
          />
        </Box>
        <TextEditor
          minHeight="126px"
          value={jobDetail?.description}
          borderRadius="10px"
          border={
            // !detailData?.role_summary &&
            errors?.find((error) => error.key === "description")
              ? "1px solid red"
              : `1px solid ${theme.palette.borderColor}`
          }
          type="description"
          onInputCHange={handleInputChange}
        />
      </Box>
      <Box>
        <InputLabel
          htmlFor="roleResponsibility"
          sx={{
            color: "black",
            paddingBottom: "8px",
            fontSize: "14px",
            height: "19px",
            fontWeight: theme.typography.Bold,
          }}
        >
          What they’ll do (role responsibilities)*
        </InputLabel>
        <TextEditor
          minHeight="126px"
          value={jobDetail?.role_responsibilty}
          borderRadius="10px"
          border={
            // !detailData?.role_responsibilty &&
            errors?.find((error) => error.key === "role_responsibilty")
              ? "1px solid red"
              : `1px solid ${theme.palette.borderColor}`
          }
          type="role_responsibilty"
          onInputCHange={handleInputChange}
        />
      </Box>
      <Box>
        <InputLabel
          htmlFor="roleRequirement"
          sx={{
            color: "black",
            pb: 1,
            height: "19px",
            fontSize: "14px",
            fontWeight: theme.typography.Bold,
          }}
        >
          What they’ll need (role requirements)*
        </InputLabel>
        <TextEditor
          minHeight="126px"
          value={jobDetail?.role_requirements}
          borderRadius="10px"
          border={
            // !detailData?.role_requirements &&
            errors?.find((error) => error.key === "role_requirements")
              ? "1px solid red"
              : `1px solid ${theme.palette.borderColor}`
          }
          type="role_requirements"
          onInputCHange={handleInputChange}
        />
      </Box>
      <Box>
        <InputLabel
          htmlFor="jobNotes"
          sx={{
            color: "black",
            pb: 1,
            height: "19px",
            fontSize: "14px",
            fontWeight: theme.typography.Bold,
          }}
        >
          Job Notes(Optional)
        </InputLabel>
        <TextEditor
          minHeight="126px"
          value={jobDetail?.job_notes}
          borderRadius="10px"
          border={`1px solid ${theme.palette.borderColor}`}
          type="job_notes"
          onInputCHange={handleInputChange}
        />
      </Box>
    </Box>
  );
}
