import { useState } from "react";
import { useTheme } from "@mui/material/styles";
import job_logo from "../../../assets/Crayon_Favicon.svg";
import post_incognito from "../../../assets/Incognito.svg";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Unstable_Grid2";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import locale from "../../../i18n/locale";
import SmallButton from "../../common/SmallButton";

import TextWrapper from "../../common/TextWrapper";
import {
  convertDatetimeAgo,
  dateConverterMonth,
} from "../../../utils/DateTime";
import { Card, Tooltip } from "@mui/material";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { changeStatus } from "../../../redux/candidate/candidateJobs";
import { setAlert } from "../../../redux/configSlice";
import { ALERT_TYPE } from "../../../utils/Constants";
import TrackButton from "./TrackButton";
import { Link } from "react-router-dom";

import downArrow from "../../../assets/Black_Down_Open - Copy.svg";

import { formatCurrencyWithCommas } from "../../../utils/Currency";
import { Circle } from "@mui/icons-material";
import TrackButtonLayout from "../../common/TrackButtonLayout";
import MatchMeButton from "./MatchMeButton";
import StatusButton from "./StatusButton";
import { truncate } from "lodash";
import job_exp from "../../../assets/Padding Included/Green_Duration.svg";
import BlueCurrency from "../../../assets/Blue_Salary.svg";
import redLocation from "../../../assets/Red_Location.svg";
import calendar from "../../../assets/Padding Included/Yellow_Calendar.svg";
import flip from "../../../assets/NewFlipIcon.svg";
import MUIRadialChart from "../../common/MUIRadialChart";
import playButtonIcon from "../../../assets/Padding Excluded/Black_reactive_job copy.svg";
import playButtonIconWhite from "../../../assets/Padding Excluded/Black_reactive_job.svg";

import matchMeIcon from "../../../assets/Padding Excluded/Black_Match_me.svg";
import viewIcon from "../../../assets/Padding Excluded/ViewMoreNew.svg";
import {
  getDecodedToken,
  getToken,
  renderColor,
  splitString,
} from "../../../utils/Common";
import { getMatchData } from "../../../redux/guest/jobsSlice";
import { LightTooltip } from "../../../utils/CommonComponent";
import externalLink from "../../../assets/Padding Excluded/External_Link.svg";
import greyExternalLink from "../../../assets/WhiteIcons/Grey_External_Link.svg";
import JobsSlider from "../../guest/jobs/JobsSlider";

export default function MyJobsCard({
  job,
  getJobs,
  setisFlipped,
  statusChanged,
  cardWidth,
  handleReviewVideoDialog,
  jobslength,
  indexNumber,
  currentPageJobsCount,
  setOpenExternal,
  setSelectedJob,
  setNoExternal,
  setCandidateJobs
}) {
  const i18n = locale.en;
  const theme = useTheme();
  const dispatch = useDispatch();
  const [isHovered, setIsHovered] = useState(false);

  const myStatus = useSelector(
    (state) => state?.configMyStatus?.mystatusfilter
  );
  const [trackButton, setTrackButton] = useState(job?.incompleteApplication);
  const [matchMeButton, setMatchButton] = useState(false);
  const decodedToken = getDecodedToken(getToken());
  const [match, setMatch] = useState();
  const industries = splitString(job?.JobIndustries);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const label1 = "applicants";
  const label2 = "shortlisted";
  const label3 = "interviews";
  const calculateHeight = () => {
    const screenWidth = window.innerWidth;

    if (screenWidth < 600) {
      // xs
      return 90;
    } else if (screenWidth < 900) {
      // sm
      return 90;
    } else if (screenWidth < 1366) {
      // md
      return 90;
    } else if (screenWidth < 1920) {
      // lg
      return 90;
    } else if (screenWidth < 2559) {
      // xl
      return 100;
    } else {
      // xxl or greater
      return 100;
    }
  };
  const getDescriptionText = (html) => {
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = html;
    return tempDiv?.textContent || tempDiv?.innerText;
  };

  const handleCandidateStatus = async (value) => {
    const data = {
      job_id: job?.job_id,
      status_id: value,
    };

    const { payload } = await dispatch(changeStatus(data));
    if (payload?.status === "success") {
      dispatch(
        setAlert({
          show: true,
          type: ALERT_TYPE.SUCCESS,
          msg: "Status Changed Successfully",
        })
      );
      statusChanged.current = true;
      // await getmyStatus();
      await getJobs();
    } else {
      dispatch(
        setAlert({
          show: true,
          type: ALERT_TYPE.ERROR,
          msg: payload?.message,
        })
      );
    }
  };

  const handleMatchMeButton = () => {
    getMatch();
    setMatchButton((prevState) => !prevState);
  };

  const getMatch = async () => {
    try {
      const data = {
        job_id: job?.job_id,
        user_id: decodedToken?.data?.user_id,
      };
      const { payload } = await dispatch(getMatchData(data));
      if (payload?.status === "success") {
        setMatch(payload?.data);
      }
    } catch (error) {
      dispatch(
        setAlert({
          show: true,
          type: ALERT_TYPE.ERROR,
          msg: error,
        })
      );
    }
  };
  return (
    <Card
      sx={{
        width: "100%",
        borderRadius: "25px",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        boxShadow: "0px 5px 10px #00000029",
      }}
      handleMouseEnter={() => setIsHovered(true)}
      handleMouseLeave={() => setIsHovered(false)}
      className="sliderClass"
    >
      <Grid
        container
        justifyContent="space-between"
        overflow={"hidden"}
        flexWrap={"nowrap"}
        sx={{
          borderRadius: "25px 25px 0 0",
        }}
        visibility={!matchMeButton ? "visible" : "hidden"}
      >
        {!trackButton && !matchMeButton ? (
          <>
            {job?.post_incognito && !job?.shortlistedflag ? (
              <Box
                sx={{ display: "flex", alignItems: "flex-start", gap: "5px" }}
              >
                <Box
                  component="img"
                  sx={{
                    height: "60px",
                    width: "60px",
                    maxHeight: { xs: "60px" },
                    maxWidth: { xs: "60px" },
                    ml: "15px",
                    mt: "15px",
                    mb: "15px",
                    // border: 1,
                    // borderColor: "lightgrey",
                    borderRadius: "10px",
                  }}
                  alt="job_logo"
                  src={post_incognito}
                />
                <Tooltip disableInteractive>
                  <LightTooltip
                    title={i18n["popupBoxText.postIncognito"]}
                    placement="right"
                  >
                    <Typography
                      sx={{
                        mt: "15px",
                        cursor: "pointer",
                        height: "17px",
                        width: "17px",
                        borderRadius: "5px",
                        fontSize: "12px",
                        lineHeight: 0.1,
                        /* text-align: center; */
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        fontWeight: theme.typography.Bold,
                        boxShadow: "0px 2px 4px #00000029",
                        border: 1,
                      }}
                    >
                      i
                    </Typography>
                  </LightTooltip>
                </Tooltip>
              </Box>
            ) : (
              <Link
                to={`/jobs/company/${job?.company_id}`}
                target={"_blank"}
                style={{
                  textDecoration: "none",
                  color: theme.palette.black,
                }}
              >
                <Box
                  component="img"
                  sx={{
                    height: "60px",
                    width: "60px",
                    maxHeight: { xs: "60px" },
                    maxWidth: { xs: "60px" },
                    ml: "15px",
                    mt: "15px",
                    mb: "15px",
                    borderRadius: "10px",
                  }}
                  alt="job_logo"
                  src={
                    job?.profile_url !== "No URL" ? job?.profile_url : job_logo
                  }
                />
              </Link>
            )}

            <Box
              sx={{
                display: "flex",
                width: "300px",
                justifyContent: "flex-end",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  width: "180px",
                  height: "70px",
                }}
              >
                <Box sx={{ display: "flex" }}>
                  <Button
                    variant="contained"
                    sx={{
                      height: "auto",
                      minWidth: "60px",
                      minHeight: "45px",
                      borderRadius: "0 0 0 10px",
                      padding: 0,
                      fontSize: 12,
                      fontWeight: theme.typography.Bold,
                      background:
                        job?.job_type !== null &&
                        job?.job_type?.includes("Recruit")
                          ? theme.jobCard.jobType.recruit.main
                          : theme.jobCard.jobType.direct.main,
                      color: theme.jobCard.jobType.recruit.contrastText,
                      cursor: "default",
                      ":hover": {
                        boxShadow: 0,
                        background:
                          job?.job_type !== null &&
                          job?.job_type?.includes("Recruit")
                            ? theme.jobCard.jobType.recruit.main
                            : theme.jobCard.jobType.direct.main,
                      },
                    }}
                  >
                    {job?.job_type || "-"}
                  </Button>
                  <Tooltip
                    disableInteractive
                    arrow
                    title={`${job?.stage_name}`}
                    placement="top"
                  >
                    <Button
                      sx={{
                        height: "auto",
                        minWidth: "60px",
                        minHeight: "45px",
                        borderRadius: 0,
                        padding: 0,
                        fontSize: 12,
                        fontWeight: theme.typography.Bold,
                        background:
                          theme.manageTalent[renderColor(job?.stage_name)]
                            ?.main,
                        cursor: "default",
                        color: "white",
                        ":hover": {
                          background:
                            theme.manageTalent[renderColor(job?.stage_name)]
                              ?.main,
                        },
                      }}
                    >
                      {job?.stage_name
                        ? truncate(job?.stage_name, { length: 9 })
                        : "-"}
                    </Button>
                  </Tooltip>
                  <Button
                    color="playButton"
                    variant="contained"
                    sx={{
                      // position: "absolute",
                      // right: 0,
                      // top: "71px",
                      zIndex: 10,
                      height: "auto",
                      minWidth: "60px",
                      minHeight: "45px",
                      borderRadius: 0,
                      // borderBottomLeftRadius: "10px",
                      cursor: !job?.JobCamVideo ? "default" : "pointer",
                      background:
                        !job?.JobCamVideo && theme.palette.grayButton600.main,
                      padding: 0,
                      ":hover": {
                        boxShadow: "none",
                      },
                    }}
                    // disabled={!job?.JobCamVideo}
                    onClick={() =>
                      job?.JobCamVideo && handleReviewVideoDialog(true)
                    }
                  >
                    <Box
                      component={"img"}
                      src={
                        !job?.JobCamVideo ? playButtonIconWhite : playButtonIcon
                      }
                      sx={{
                        height: "16px",
                        width: "20px",
                      }}
                    />
                  </Button>
                </Box>
                <Tooltip
                  disableInteractive
                  title={`posted ${convertDatetimeAgo(job?.created_at)}ago`}
                  placement="top"
                >
                  <Typography
                    sx={{
                      fontWeight: theme.typography.Regular,
                      fontSize: 12,
                      width: "100%",
                      whiteSpace: "nowrap", // Prevents text from wrapping
                      overflow: "hidden", // Hides any overflowing content
                      textOverflow: "ellipsis",
                      color: theme.palette.grayButton600.main,
                    }}
                  >
                    posted {convertDatetimeAgo(job?.created_at)} ago
                  </Typography>
                </Tooltip>
              </Box>
              <Box
                sx={{
                  alignItems: "center",
                  display: "flex",
                  // width: { xs: "80px", lg: "90px", xl: "120px" },
                  minWidth: "60px !important",
                  maxWidth: "60px !important",
                  flexDirection: "column",
                  borderTop: 0,
                  borderRight: 0,
                  borderRadius: "0 0px 0px 10px",
                  height: "71px",
                  position: "relative",
                }}
              >
                <Button
                  variant="contained"
                  sx={{
                    boxShadow: "none !important",
                    height: "45px !important",
                    borderRadius: 0,
                    minWidth: "60px !important",
                    maxWidth: "60px !important",
                    display: "flex",
                    flexDirection: "column",
                  }}
                  onClick={() => {
                    setTrackButton((prevState) => !prevState);
                  }}
                  color="grayButton100"
                >
                  <TrackButtonLayout
                    job={job}
                    colorPattern={[
                      job?.profileCompleted,
                      job?.cvCompleted,
                      job?.qaCompleted,
                      job?.is_video_require?job?.JobCamVideo:"grey",
                    ]}
                  />
                  {
                    <Box
                      component={"img"}
                      src={downArrow}
                      sx={{
                        height: 25,
                        width: 25,
                      }}
                    />
                  }
                </Button>
                {!trackButton && !matchMeButton && (
                  <>
                    <Typography
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: 1,
                        fontWeight: "Bold",
                        fontSize: "14px",
                        cursor: "default",
                        position: "absolute",
                        right: "5px",
                        bottom: "0px",
                      }}
                    >
                      {job?.job_status_name || "Status"}{" "}
                      <Circle
                        fontSize="string"
                        // marginRight="20px"
                        color={
                          job?.job_status_name === "paused"
                            ? "pausedStatus"
                            : job?.job_status_name === "closed"
                            ? "closedStatus"
                            : job?.job_status_name === "rejected"
                            ? "rejectedStatus"
                            : job?.job_status_name === "pending"
                            ? "pendingStatus"
                            : job?.job_status_name === "active"
                            ? "activeStatus"
                            : job?.job_status_name === "expired"
                            ? "peachPayment"
                            : ""
                        }
                      />
                    </Typography>
                  </>
                )}
              </Box>
            </Box>
          </>
        ) : (
          <Box />
        )}
      </Grid>
      {trackButton && (
        <TrackButton
          job={job}
          closeFunc={setTrackButton}
          cardWidth={cardWidth}
          setCandidateJobs={setCandidateJobs}
        />
      )}
      {matchMeButton && (
        <MatchMeButton
          closeFunc={handleMatchMeButton}
          job={job}
          myStatus={myStatus}
          cardWidth={cardWidth}
          handleCandidateStatus={handleCandidateStatus}
          match={match}
          setMatch={setMatch}
        />
      )}
      {!trackButton && !matchMeButton && (
        <>
          <Box
            sx={{
              display: "flex",
              width: "100%",
            }}
          >
            <Grid
              paddingTop={0}
              paddingBottom={0}
              sx={{
                flexGrow: 1,
                display: "flex",
                flexDirection: "column",
                gap: "15px",
                padding: "0 20px 0 15px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                  width: "100%",
                }}
              >
                <Tooltip
                  disableInteractive
                  arrow
                  title={`${job?.title}`}
                  placement="top"
                >
                  <Link
                    to={
                      job?.post_incognito
                        ? `/jobs/${job?.country_name?.replace(
                            /\s|\//g,
                            ""
                          )}/${job?.town_name?.replace(
                            /\s|\//g,
                            ""
                          )}/${job?.title?.replace(/\s|\//g, "")}/${
                            job?.job_id
                          }`
                        : `/jobs/${job?.country_name?.replace(
                            /\s|\//g,
                            ""
                          )}/${job?.town_name?.replace(
                            /\s|\//g,
                            ""
                          )}/${job?.CompanyName?.replace(
                            /\s|\//g,
                            ""
                          )}/${job?.title?.replace(/\s|\//g, "")}/${
                            job?.job_id
                          }`
                    }
                    target={"_blank"}
                    style={{
                      textDecoration: "none",
                      color: theme.palette.black,
                      maxWidth: `calc(100% - 40px)`,
                    }}
                  >
                    <Typography
                      sx={{
                        fontWeight: 700,
                        fontSize: 20,
                        overflow: "hidden",
                        lineHeight: 1,
                        maxWidth: "100%",
                        wordBreak: "break-word",
                      }}
                      noWrap={true}
                    >
                      {job?.title !== null && job?.title}
                    </Typography>
                  </Link>
                </Tooltip>

                <Box
                  component={"img"}
                  src={job?.external_link ? externalLink : greyExternalLink}
                  sx={{
                    height: "30px",
                    width: "30px",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    if (job?.external_link) {
                      setOpenExternal(true);
                    } else {
                      setNoExternal(true);
                    }
                    setSelectedJob(job);
                  }}
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "start",
                    gap: "10px",
                    height: "14px",
                  }}
                >
                  <Box
                    component="img"
                    sx={{
                      height: 16,
                      width: 15,
                      maxHeight: { xs: 16 },
                      maxWidth: { xs: 15 },
                    }}
                    alt="currency"
                    src={BlueCurrency}
                  />
                  <Typography
                    sx={{
                      fontWeight: 700,
                      fontSize: 14,
                      letterSpacing: "0.25px",
                      lineHeight: 1,
                    }}
                  >
                    {/* {job?.currency_symbol}
                    {formatCurrencyWithCommas(job?.salary_min)} to{" "}
                    {job?.currency_symbol}
                    {formatCurrencyWithCommas(job?.salary_max)} per month */}
                    {job?.salary_min === 0 ? (
                      <>
                        {job?.currency_symbol}
                        {formatCurrencyWithCommas(job?.salary_max)}{" "}
                        {job?.type === "freelance" ? "per hour" : "per month"}
                      </>
                    ) : job?.currency_symbol ||
                      job?.salary_min ||
                      job?.salary_max ? (
                      <>
                        {job?.currency_symbol}
                        {formatCurrencyWithCommas(job?.salary_min)} to{" "}
                        {job?.currency_symbol}
                        {formatCurrencyWithCommas(job?.salary_max)}{" "}
                        {job?.type === "freelance" ? "per hour" : "per month"}
                      </>
                    ) : (
                      "-"
                    )}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "start",
                    gap: "10px",
                    height: "14px",
                  }}
                >
                  <Box
                    component="img"
                    sx={{
                      height: 16,
                      width: 15,
                      maxHeight: { xs: 16 },
                      maxWidth: { xs: 15 },
                    }}
                    alt="location"
                    src={redLocation}
                  />
                  <Typography
                    sx={{
                      fontWeight: 700,
                      fontSize: 14,
                      letterSpacing: "0.25px",
                      lineHeight: 1,
                    }}
                  >
                    {job?.town_name}, {job?.country_name}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "start",
                    gap: "10px",
                    height: "14px",
                  }}
                >
                  <Box
                    component="img"
                    sx={{
                      height: 16,
                      width: 15,
                      maxHeight: { xs: 16 },
                      maxWidth: { xs: 15 },
                    }}
                    alt="job_exp"
                    src={job_exp}
                  />
                  <Typography
                    sx={{
                      fontWeight: 700,
                      fontSize: 14,
                      letterSpacing: "0.25px",
                      lineHeight: 1,
                    }}
                  >
                    {job?.experienceYearStart} to {job?.experienceYearEnd} years
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "start",
                    gap: "10px",
                    height: "14px",
                  }}
                >
                  <Box
                    component="img"
                    sx={{
                      height: 16,
                      width: 14,
                      maxHeight: { xs: 16 },
                      maxWidth: { xs: 14 },
                    }}
                    alt="calendar"
                    src={calendar}
                  />
                  <Typography
                    sx={{
                      fontWeight: 700,
                      fontSize: 14,
                      letterSpacing: "0.25px",
                      lineHeight: 1,
                    }}
                  >
                    {job?.job_start_date
                      ? dateConverterMonth(job?.job_start_date)
                      : "-"}
                  </Typography>
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                  height: "60px",
                  marginRight: "15px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    gap: "5px",
                  }}
                >
                  {job?.type && (
                    <SmallButton
                      fontSize={12}
                      color={"nandos"}
                      height={25}
                      value={job?.type}
                      label={truncate(job?.type)}
                      cursor={"default"}
                    />
                  )}
                  {job?.work_setup && (
                    <SmallButton
                      fontSize={12}
                      color={"nandos"}
                      height={25}
                      value={job?.work_setup}
                      label={truncate(job?.work_setup)}
                      cursor={"default"}
                    />
                  )}
                </Box>
                {/*<Slider2
                  items={industries}
                  color={"industriesButton"}
                  hideTagsAfter={2}
                  cardWidth={cardWidth}
                  />*/}
                {/* <NewSlider
                  items={industries?.sort((a, b) => a.length - b.length) || []}
                  color={"industriesButton"}
                  hideTagsAfter={3}
                  height="25px"
                  job={job}
                  index={indexNumber}
                  jobslength={jobslength}
                  id={job?.job_id}
                  currentPageJobsCount={currentPageJobsCount}
                />*/}
                <JobsSlider
                  items={industries?.sort((a, b) => a.length - b.length) || []}
                  color={"industriesButton"}
                  hideTagsAfter={3}
                  height="25px"
                  job={job}
                  index={indexNumber}
                  jobslength={jobslength}
                  id={job?.job_id}
                  currentPageJobsCount={currentPageJobsCount}
                />
              </Box>

              <TextWrapper
                height={"55px"}
                size={12}
                color={theme.palette.black100}
                letterSpacing="0.25px"
                lineHeight={1.6}
              >
                {getDescriptionText(job?.description)}
              </TextWrapper>
            </Grid>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            ></Box>
          </Box>

          <Grid
            container
            padding="15px 15px 15px 15px"
            gap={{ xs: "10px", lg: "8px", xl: "15px", xll: "2px" }}
            width={"100%"}
            flexWrap={"nowrap"}
            justifyContent="space-between"
          >
            <MUIRadialChart
              value={job?.TotalUserCount}
              chartName={label1}
              max={500}
              size={calculateHeight()}
              countFontSize={16}
              labelFontSize={12}
              color={"AppliedUltra"}
            />
            <MUIRadialChart
              value={job?.totalusershorlisted}
              chartName={label2}
              max={100}
              size={calculateHeight()}
              countFontSize={16}
              labelFontSize={12}
              color={"Shortlisted"}
            />

            <MUIRadialChart
              value={job?.totaluserinterviewed}
              chartName={label3}
              max={50}
              size={calculateHeight()}
              countFontSize={16}
              labelFontSize={12}
              color={"Interviewed"}
            />
          </Grid>

          <Grid
            container
            flexWrap={"nowrap"}
            alignItems="center"
            overflow={"hidden"}
            sx={{
              width: `${cardWidth}px`,
              borderRadius: "0 0 25px 25px",
              height: "60px",
            }}
          >
            <Button
              variant="contained"
              sx={{
                borderRadius: 0,
                width: `${cardWidth / 3}px`,
                height: "100%",
                fontSize: "14px",
                fontWeight: "bold",
                ".MuiButton-startIcon": {
                  marginRight: "0px !important",
                  marginLeft: 0,
                },
                padding: "0px !important",
                gap: 1,
              }}
              color="yoco"
              startIcon={
                <Box
                  component={"img"}
                  sx={{
                    height: "20px",
                    width: "26px",
                  }}
                  src={matchMeIcon}
                />
              }
              onClick={handleMatchMeButton}
            >
              match
            </Button>
            <Link
              to={
                job?.post_incognito
                  ? `/jobs/${job?.country_name?.replace(
                      /\s|\//g,
                      ""
                    )}/${job?.town_name?.replace(
                      /\s|\//g,
                      ""
                    )}/${job?.title?.replace(/\s|\//g, "")}/${job?.job_id}`
                  : `/jobs/${job?.country_name?.replace(
                      /\s|\//g,
                      ""
                    )}/${job?.town_name?.replace(
                      /\s|\//g,
                      ""
                    )}/${job?.CompanyName?.replace(
                      /\s|\//g,
                      ""
                    )}/${job?.title?.replace(/\s|\//g, "")}/${job?.job_id}`
              }
              target={"_blank"}
              style={{
                textDecoration: "none",
                color: theme.palette.black,
                width: `${cardWidth / 3}px`,
                height: "100%",
              }}
            >
              <Button
                variant="contained"
                sx={{
                  borderRadius: 0,
                  fontSize: "14px",
                  fontWeight: "bold",
                  whiteSpace: "nowrap",
                  height: "100%",
                  width: "100%",
                  justifyContent: "center",
                  ":hover": {
                    boxShadow: "none",
                  },
                  ".MuiButton-startIcon": {
                    marginRight: "0px !important",
                    marginLeft: 0,
                    justifyContent: "end",
                    width: "26px",
                  },
                  padding: "0px !important",
                }}
                color="tymeColor"
                startIcon={
                  <Box
                    component={"img"}
                    sx={{
                      height: 17,
                      width: 28,
                      padding: 0,
                      paddingRight: "5px",
                    }}
                    src={viewIcon}
                  />
                }
              >
                view more
              </Button>
            </Link>
            <Box
              sx={{
                width: `${cardWidth / 3}px`,
                height: "100%",
              }}
            >
              <StatusButton
                selectedStatus={job?.candidate_status}
                options={myStatus?.filter(
                  (status) =>
                    status.id !== 1111 &&
                    status.id !== job?.candidate_job_status_id &&
                    status.id !== 1
                )}
                handleStatusChange={handleCandidateStatus}
                cardWidth={`${cardWidth / 3}px`}
              />
            </Box>
          </Grid>
          <Button
            variant="contained"
            color="rejectedStatus"
            sx={{
              width: "20px",
              height: "100px",
              padding: 0,
              position: "absolute",
              right: 0,
              top: `calc(50% - 50px)`,
              minWidth: "20px",
              fontSize: "20px",
              borderRadius: "10px 0 0 10px",
            }}
            onClick={() => setisFlipped(true)}
          >
            <Box
              component={"img"}
              src={flip}
              sx={{
                height: "20px",
                width: "15px",
              }}
            />
          </Button>
        </>
      )}
    </Card>
  );
}
