import React, { useRef, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  Grid,
  Button,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import InfiniteScroll from "react-infinite-scroll-component";
import { useDispatch } from "react-redux";
import {
  deleteSearchPool,
  getTalentPool,
} from "../../../redux/admin/jobsSlice";
import { Link } from "react-router-dom";
import { useTheme } from "@emotion/react";
import SmallButton from "../../common/SmallButton";
import locale from "../../../i18n/locale";
import { useSelector } from "react-redux";
import IconSection from "../adminTalent/IconSection";
import { dateConverterMonth } from "../../../utils/DateTime";
import { ExpandMore } from "@mui/icons-material";
import TalentSVGButton from "../../common/TalentSVGButton";
import editIcon from "../../../assets/Padding Included/Yellow_Edit.svg";
import deleteIcon from "../../../assets/Padding Excluded/Black_Trash_Delete_1 - Copy.svg";
import Delete from "../../candidate/myCam/dialog/Delete";
import {
  getRefreshSearch,
  getSaveSearch,
} from "../../../redux/admin/searchSlice";
import { useNavigate } from "react-router-dom";
import TeamAccessList from "../adminTalent/DialogBox/TeamAccessList";
import RefreshIcon from "@mui/icons-material/Refresh";
import { setAlert } from "../../../redux/configSlice";
import { ALERT_TYPE, ERROR_MSG } from "../../../utils/Constants";

export default function TalentPool() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const theme = useTheme();
  const isClientSearch = useRef(false);
  isClientSearch.current = window?.location?.pathname?.includes("client-search")
    ? true
    : false;

  const [openDialogRow, setOpenDialogRow] = useState(null);
  const [poolId, setPoolId] = useState(0);
  const [confirmDelete, setConfirmDelete] = useState(false);

  const [buttonMenu, setButtonMenu] = useState(false);
  const [lastKey, setLastKey] = useState(0);
  const [tableData, setTableData] = useState([]);
  const [jobTitleCount, setJobTitleCount] = useState(0);
  const [openDelete, setOpenDelete] = useState(false);
  const {
    searchSubList,
    clientSearchList,
    searchSublistCount,
    clientSearchCount,
  } = useSelector((state) => state?.searchSlice);

  const searchList = isClientSearch?.current ? clientSearchList : searchSubList;

  const getTitles = async (lastkeyy) => {
    try {
      const { payload } = await dispatch(getTalentPool({ lastKey: lastkeyy }));
      if (payload.status === "success") {
        setJobTitleCount(payload.poolCount);
        if (lastkeyy === 0) {
          setTableData(payload.data);
          setLastKey(payload.pageNumber + 1);
        } else {
          setLastKey(payload.pageNumber + 1);
          setTableData((prevState) => [...prevState, ...payload.data]);
        }
      }
    } catch (error) {}
  };

  const handleOpenPermissionDialog = (rowId) => {
    setButtonMenu((prevState) => !prevState);
    setOpenDialogRow(rowId);
  };
  const handleDeleteDialog = (poolId) => {
    setPoolId(poolId);
    setOpenDelete((prevState) => !prevState);
  };
  const handleEditDialog = (poolInformation) => {
    if (isClientSearch?.current) {
      navigate(`/admin/search/client-search/${poolInformation?.search_id}`, {
        state: { modalOpen: true },
      });
    } else {
      navigate(`/admin/search/my-search/${poolInformation?.search_id}`, {
        state: { modalOpen: true },
      });
    }
  };
  const handleCancelDelete = () => {
    setOpenDelete((prevState) => !prevState);
    setConfirmDelete(false);
  };
  const handleDeletePool = async () => {
    const { payload } = await dispatch(deleteSearchPool(poolId));
    if (payload.status === "success") {
      await dispatch(getSaveSearch());
      setOpenDelete(false);
    } else {
      // dispatch(
      //   setAlert({
      //     show: true,
      //     type: ALERT_TYPE?.ERROR,
      //     msg: payload?.message?.message,
      //   })
      // );
    }
  };

  const refreshSearch = async () => {
    try {
      let data = {
        search_id: searchList?.map((item) => item?.search_id),
      };
      const { payload } = await dispatch(getRefreshSearch(data));
      if (payload?.status === "error") {
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE?.ERROR,
            msg: ERROR_MSG,
          })
        );
      }
    } catch (error) {
      dispatch(
        setAlert({
          show: true,
          type: ALERT_TYPE?.ERROR,
          msg: ERROR_MSG,
        })
      );
    }
  };
  return (
    <>
      <Box sx={{ ml: "16px" }}>
        <Typography
          sx={{
            fontSize: "36px",
            fontWeight: 700,
            textAlign: "center",
          }}
        >
          {isClientSearch?.current ? "client searches" : "my searches"}
        </Typography>

        <Grid
          container
          spacing={2}
          flexDirection={"column"}
          sx={{
            display: { xs: "none", md: "flex" },
            marginTop: "30px",
            background: "white",
            borderRadius: "25px",
          }}
          boxShadow={1}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              gap: "10px",
            }}
          >
            <Box sx={{ width: 160 }}></Box>
            <Box mt={2}>
              <IconSection />
            </Box>
            <Button
              variant="contained"
              color="yellowButton100"
              sx={{
                borderRadius: "0 25px 0 25px",
              }}
            >
              {isClientSearch?.current ? "client searches" : "my searches"}
            </Button>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              paddingX: 2,
              paddingY: 1,
              borderBottom: 2,
              borderColor: theme.palette.grayBorder,
            }}
          >
            <Box>
              <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                <Typography
                  sx={{
                    fontWeight: 700,
                  }}
                >
                  {isClientSearch?.current ? "Client Searches" : "My Searches"}
                </Typography>
                <SmallButton
                  color="userID"
                  label={
                    isClientSearch?.current
                      ? clientSearchCount
                      : searchSublistCount
                  }
                  paddingX={2}
                  fontSize={10}
                  fontWeight={700}
                  textColor="#000"
                  backgroundColor="#DCDEEB"
                  borderRadius="6px"
                  width="fit-content"
                ></SmallButton>
                <Box
                  onClick={() => refreshSearch()}
                  sx={{ display: "flex", cursor: "pointer" }}
                >
                  <RefreshIcon />
                </Box>
              </Box>
            </Box>
          </Box>
          <InfiniteScroll
            style={{ overflow: "visible" }}
            dataLength={tableData.length}
            next={() => {}}
            scrollThreshold={"10px"}
            scrollableTarget={"jobList"}
            hasMore={tableData.length < jobTitleCount ? true : false}
          >
            <Box
              sx={{
                mt: 2,
              }}
            >
              <TableContainer
                component={Box}
                sx={{
                  overflowX: "unset",
                }}
              >
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <Typography
                          variant="subtitle1"
                          fontWeight="bold"
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <span>Search Name</span>
                            <span>
                              <ExpandMore
                                sx={{
                                  width: "20px",
                                  height: "20px",
                                  marginTop: "5px",
                                }}
                              />
                            </span>
                          </div>
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                          variant="subtitle1"
                          fontWeight="bold"
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <span>Date Created</span>
                            <span>
                              <ExpandMore
                                sx={{
                                  width: "20px",
                                  height: "20px",
                                  marginTop: "5px",
                                }}
                              />
                            </span>
                          </div>
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                          variant="subtitle1"
                          fontWeight="bold"
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <span>Job titles</span>
                            <span>
                              <ExpandMore
                                sx={{
                                  width: "20px",
                                  height: "20px",
                                  marginTop: "5px",
                                }}
                              />
                            </span>
                          </div>
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                          variant="subtitle1"
                          fontWeight="bold"
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <span>Candidates</span>
                            <span>
                              <ExpandMore
                                sx={{
                                  width: "20px",
                                  height: "20px",
                                  marginTop: "5px",
                                }}
                              />
                            </span>
                          </div>
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                          variant="subtitle1"
                          fontWeight="bold"
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <span>Created by</span>
                            <span>
                              <ExpandMore
                                sx={{
                                  width: "20px",
                                  height: "20px",
                                  marginTop: "5px",
                                }}
                              />
                            </span>
                          </div>
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                          variant="subtitle1"
                          fontWeight="bold"
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <span>Team access</span>
                            <span>
                              <ExpandMore
                                sx={{
                                  width: "20px",
                                  height: "20px",
                                  marginTop: "5px",
                                }}
                              />
                            </span>
                          </div>
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                          variant="subtitle1"
                          fontWeight="bold"
                        ></Typography>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {searchList?.map((row, index) => {
                      return (
                        <TableRow
                          key={row?.search_id}
                          style={{
                            background: `${index % 2 === 0 ? "#F2F4FB" : ""}`,
                            "& .css-12zgwp-MuiTableCell-root": {
                              padding: "0 16px !important",
                            },
                          }}
                        >
                          <TableCell>
                            <Link
                              to={
                                isClientSearch?.current
                                  ? `/admin/search/client-search/${row?.search_id}`
                                  : `/admin/search/my-search/${row?.search_id}`
                              }
                              state={row}
                              style={{
                                textDecoration: "none",
                                color: theme?.palette?.black,
                                fontWeight: "700",
                              }}
                            >
                              {row?.name}
                            </Link>
                          </TableCell>
                          <TableCell>
                            {dateConverterMonth(row?.created_at)}
                          </TableCell>
                          <TableCell>{row?.titlecount}</TableCell>
                          <TableCell>{row?.talentcount}</TableCell>
                          <TableCell
                            sx={{ fontWeight: theme.typography.Bold }}
                          >{`${row?.user?.first_name} ${row?.user?.last_name}`}</TableCell>
                          <TableCell
                            sx={{
                              position: "relative",
                            }}
                          >
                            <Button
                              variant="contained"
                              color="grayButton100"
                              sx={{
                                width: "70%",
                                justifyContent: "space-around",
                                height: "25px",
                                borderRadius: 2,
                                backgroundColor: "#DCDEEB",
                              }}
                              endIcon={<ExpandMore />}
                              onClick={() => handleOpenPermissionDialog(index)}
                            >
                              Permissions
                            </Button>
                            {openDialogRow === index && (
                              <TeamAccessList
                                openDialog={buttonMenu}
                                jobId={row?.search_id}
                                closeFunc={handleOpenPermissionDialog}
                                searchPool={true}
                              />
                            )}
                          </TableCell>
                          <TableCell
                            sx={{
                              display: "flex",
                            }}
                          >
                            <TalentSVGButton
                              color={"white"}
                              source={editIcon}
                              height={34}
                              width={35}
                              padding={0}
                              onClick={() => handleEditDialog(row)}
                            />
                            <TalentSVGButton
                              color={"white"}
                              source={deleteIcon}
                              height={24}
                              width={18}
                              padding={0}
                              onClick={() => handleDeleteDialog(row?.search_id)}
                            />
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </InfiniteScroll>
        </Grid>
        <Delete
          show={openDelete}
          handleOpen={handleDeleteDialog}
          handleCancel={handleCancelDelete}
          handleDelete={handleDeletePool}
          confirmDelete={confirmDelete}
          setConfirmDelete={setConfirmDelete}
        />
      </Box>
    </>
  );
}
