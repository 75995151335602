import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import locale from "../../../i18n/locale";
import InputBox from "../../common/InputBox";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";

import {
  createProfile,
  resetPassword,
} from "../../../redux/candidate/myProfileSlice";

import { getTown } from "../../../redux/employer/postJobSlice";
import { setAlert } from "../../../redux/configSlice";
import {
  ALERT_TYPE,
  ERROR_MSG,
  PASSWORD_REGEX,
} from "../../../utils/Constants";
import { useTheme } from "@emotion/react";
import { setLoading } from "../../../redux/configSlice";
import maleBlack from "../../../assets/male_blue.svg";
import maleWhite from "../../../assets/male_white.svg";
import femaleBlack from "../../../assets/female_pink.svg";
import femaleWhite from "../../../assets/female_white (2).svg";
import "dayjs/locale/en-gb";
import { useSelector } from "react-redux";
import { CheckCircle } from "@mui/icons-material";
import black from "../../../assets/Characters/Race/crayon_race_black.svg";
import blackFemale from "../../../assets/Characters/Race/crayon_black_female.svg";
import whiteFemale from "../../../assets/Characters/Race/crayon_white_female.svg";
import indianFemale from "../../../assets/Characters/Race/crayon_Indian_female.svg";
import colouredFemale from "../../../assets/Characters/Race/crayon_coloured_female.svg";
import white from "../../../assets/Characters/Race/crayon_race_white.svg";
import indian from "../../../assets/Characters/Race/crayon_race_indian.svg";
import coloured from "../../../assets/Characters/Race/crayon_race_coloured.svg";
import own_internet from "../../../assets/Padding Included/Crayon Icon_Own Internet.svg";
import own_equipment from "../../../assets/Padding Included/Crayon Icon_Own Equipment.svg";
import own_vehicle from "../../../assets/Padding Included/Crayon Icon_Own Vehicle.svg";
import "react-phone-number-input/style.css";
import PhoneInput, { formatPhoneNumberIntl } from "react-phone-number-input";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { isValidPhoneNumber } from "react-phone-number-input";
import { ToggleSwitch } from "../../../utils/CommonComponent";
import CustomSelect from "../myCV/CustomSelect";
import {
  debounce,
  getContactDetail,
  getDecodedToken,
  getToken,
} from "../../../utils/Common";
import { useRef } from "react";
import _ from "lodash";
import CommonLoader from "../../common/CommonLoader";
import { Switch } from "@mui/material";
import { alpha } from "@material-ui/core";
import { resetLoggedInUserPassword } from "../../../redux/employer/empProfileSlice";
import {
  getCompanyCulture,
  uploadIDProof,
  uploadPortfolio,
} from "../../../redux/candidate/myCvSlice";
import view from "../../../assets/Padding Excluded/Black_view._job.svg";
import SelectMenu from "../../common/SelectMenu";

dayjs.locale("en-gb");
const CustomCalendarIcon = () => (
  <svg
  id="Layer_1"
  data-name="Layer 1"
  xmlns="http://www.w3.org/2000/svg"
  viewBox="0 0 23.89 23.97"
  width="20"  // Set width to 20px
  height="20" // Set height to 20px
>
  <path
    style={{ fill: 'none', strokeWidth: 0 }}
    d="M2.07,20.44c0,.94.52,1.46,1.45,1.46h16.85c.92,0,1.45-.53,1.45-1.46v-11.27s-19.75,0-19.75,0v11.27ZM18.19,10.53h1.81v1.83h-1.81v-1.83ZM18.19,14.24h1.81v1.83h-1.81v-1.83ZM18.19,17.93h1.81v1.83h-1.81v-1.83ZM14.59,10.53h1.81v1.83h-1.81v-1.83ZM14.59,14.24h1.81v1.83h-1.81v-1.83ZM14.59,17.93h1.81v1.83h-1.81v-1.83ZM10.92,10.53h1.81v1.83h-1.81v-1.83ZM10.92,14.24h1.81v1.83h-1.81v-1.83ZM10.92,17.93h1.81v1.83h-1.81v-1.83ZM7.29,10.53h1.81v1.83h-1.81v-1.83ZM7.29,14.24h1.81v1.83h-1.81v-1.83ZM7.29,17.93h1.81v1.83h-1.81v-1.83ZM3.63,10.53h1.81v1.83h-1.81v-1.83ZM3.63,14.24h1.81v1.83h-1.81v-1.83ZM3.63,17.93h1.81v1.83h-1.81v-1.83Z"
  />
  <path
    style={{ fill: '#FFC425', strokeWidth: 0 }}
    d="M23.89,5.34c0-.82-.29-1.56-.86-2.21-1.06-1.2-2.45-1.19-3.68-1.09l-.24.02v2.03h.62c.24,0,.47,0,.7,0,.84,0,1.39.55,1.39,1.4,0,.53,0,1.05,0,1.58H2.07v-.34c0-.43,0-.86,0-1.29,0-.78.56-1.33,1.35-1.34.56,0,1.12,0,1.68,0h.33v1.95h2.09V-.03h-2.09v2.05h-.67c-.49,0-.98,0-1.47,0C1.49,2.05.01,3.53,0,5.33,0,10.48,0,15.64,0,20.65c0,1.79,1.52,3.3,3.3,3.31,2.82,0,5.73,0,8.69,0s5.67,0,8.57,0c1.82,0,3.31-1.49,3.31-3.32,0-5.13,0-10.16,0-15.29ZM21.82,20.43c0,.93-.53,1.46-1.45,1.46H3.53c-.94,0-1.45-.52-1.45-1.46v-11.27h19.75v11.27Z"
  />
  <polygon
    style={{ fill: '#FFC425', strokeWidth: 0 }}
    points="16.36 6.04 18.45 6.04 18.45 -.03 16.36 -.03 16.36 2.03 8.2 2.03 8.2 4.1 16.36 4.1 16.36 6.04"
  />
  <rect
    style={{ fill: '#FFC425', strokeWidth: 0 }}
    x="3.63"
    y="10.53"
    width="1.81"
    height="1.83"
  />
  <rect
    style={{ fill: '#FFC425', strokeWidth: 0 }}
    x="7.29"
    y="10.53"
    width="1.81"
    height="1.83"
  />
  <rect
    style={{ fill: '#FFC425', strokeWidth: 0 }}
    x="10.92"
    y="10.53"
    width="1.81"
    height="1.83"
  />
  <rect
    style={{ fill: '#FFC425', strokeWidth: 0 }}
    x="14.59"
    y="10.53"
    width="1.81"
    height="1.83"
  />
  <rect
    style={{ fill: '#FFC425', strokeWidth: 0 }}
    x="18.19"
    y="10.53"
    width="1.81"
    height="1.83"
  />
  <rect
    style={{ fill: '#FFC425', strokeWidth: 0 }}
    x="3.63"
    y="14.24"
    width="1.81"
    height="1.83"
  />
  <rect
    style={{ fill: '#FFC425', strokeWidth: 0 }}
    x="7.29"
    y="14.24"
    width="1.81"
    height="1.83"
  />
  <rect
    style={{ fill: '#FFC425', strokeWidth: 0 }}
    x="10.92"
    y="14.24"
    width="1.81"
    height="1.83"
  />
  <rect
    style={{ fill: '#FFC425', strokeWidth: 0 }}
    x="14.59"
    y="14.24"
    width="1.81"
    height="1.83"
  />
  <rect
    style={{ fill: '#FFC425', strokeWidth: 0 }}
    x="18.19"
    y="14.24"
    width="1.81"
    height="1.83"
  />
  <rect
    style={{ fill: '#FFC425', strokeWidth: 0 }}
    x="3.63"
    y="17.93"
    width="1.81"
    height="1.83"
  />
  <rect
    style={{ fill: '#FFC425', strokeWidth: 0 }}
    x="7.29"
    y="17.93"
    width="1.81"
    height="1.83"
  />
  <rect
    style={{ fill: '#FFC425', strokeWidth: 0 }}
    x="10.92"
    y="17.93"
    width="1.81"
    height="1.83"
  />
  <rect
    style={{ fill: '#FFC425', strokeWidth: 0 }}
    x="14.59"
    y="17.93"
    width="1.81"
    height="1.83"
  />
  <rect
    style={{ fill: '#FFC425', strokeWidth: 0 }}
    x="18.19"
    y="17.93"
    width="1.81"
    height="1.83"
  />
</svg>
);
export default function TheBasics({
  profile,
  errors,
  setErrors,
  handleInfoSave,
  isLoadingData,
}) {
  const regionNames = new Intl.DisplayNames(["en"], { type: "region" });
  const { nationality, language, disability } = useSelector(
    (state) => state?.myCv
  );
  const { initialIndustries, companyCultures, initialCompanyCulture } =
    useSelector((state) => state?.myCv);
  const { companyType, companySize } = useSelector((state) => state?.myProfile);
  const { countries, town } = useSelector((state) => state?.postJobs);
  const i18n = locale.en;
  const theme = useTheme();
  const navigate = useNavigate();
  const currentDate = new Date();
  const dispatch = useDispatch();
  const decodedToken = getDecodedToken(getToken());
  // const fileAccept = "application/pdf, application/doc, application/docx";
  const fileAccept = "application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document";
  const hiddenFileInput = useRef(null);
  const hiddenIdProof = useRef(null);
  const location = useLocation();

  const [idProofName, setIdProofName] = useState(
    profile?.id_proof ? profile?.id_proof : ""
  );
  const [portfolioName, setPortFolioName] = useState(
    profile?.portfolio ? profile?.portfolio : ""
  );
  const [inputType, setInputType] = useState("password");
  const [showPassword, setShowPassword] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [profileData, setProfileData] = useState({});
  const [contactNumber, setContactNumber] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [previousCountryCode, setPreviousCountryCode] = useState("");
  const [pageIsLoad, setPageIsLoad] = useState(false);
  const profileDataRef = useRef(profileData);
  const [value1, setValue1] = useState("");

  const checkPhoneNumber = (
    oldCountryCode,
    newCountryCode,
    fullPhoneNumber
  ) => {
    if (fullPhoneNumber === undefined || fullPhoneNumber === null) {
      return `${newCountryCode}`;
    }
    let oldCountryCodeStr = String(oldCountryCode);
    let newCountryCodeStr = String(newCountryCode);
    let normalizedNumber = fullPhoneNumber?.startsWith("+")
      ? fullPhoneNumber?.substring(1)
      : fullPhoneNumber;
    if (normalizedNumber?.startsWith(oldCountryCodeStr)) {
      normalizedNumber = normalizedNumber?.substring(oldCountryCodeStr?.length);
    }
    return `${newCountryCodeStr}${normalizedNumber}`;
  };
  const [characterCount, setCharacterCount] = useState(
    profileData?.my_bio?.length || 0
  );
  const [count, setCount] = useState(profileData?.my_bio?.length || 0);
  const handleShowPassword = () => {
    if (showPassword) setInputType("password");
    else setInputType("text");

    setShowPassword(!showPassword);
  };

  const handleColor = (event, color) => {
    const newProfileData = {
      ...profileData,
      skinz: profileData.skinz === color ? null : color,
    };
    setProfileData(newProfileData);
  };

  const handleCountryIdChange = async (countryId) => {
    let updatedCountryCode = countries?.filter(
      (item) => item?.country_id === countryId
    )?.[0]?.country_code;
    setPreviousCountryCode(updatedCountryCode);
    handleCountryChange(countryId);
  };
  const handleCountryChange = async (value) => {
    if (pageIsLoad) {
      setPageIsLoad(false);
      return;
    }

    let selectedCountry = countries?.find((item) => item.id === value);
    const newProfileData = {
      ...profileData,
      country_code:
        selectedCountry?.country_code_name || profileData?.country_code,
      country_id: value || profileData?.country_id,
      town_id: value ? "" : profileData?.town_id ? profileData?.town_id : "",
      town_name: value
        ? ""
        : profileData?.town_name
        ? profileData?.town_name
        : "",
      country_name: selectedCountry?.name,
    };
    if (value === undefined && selectedCountry?.country_code) {
      setContactNumber(`${selectedCountry?.country_code}`);
    }
    setProfileData(newProfileData);
  };

  const handleSwitch = (event) => {
    const newProfileData = {
      ...profileData,
      [event.target.id]: Number(event?.target?.checked),
    };
    setProfileData(newProfileData);
  };

  const handleInputChange = (event) => {
    if (event.target.id === "contact_no") {
      const newProfileData = {
        ...profileData,
        [event.target.id]: event?.target?.value,
      };
      setProfileData(newProfileData);
    } else if (
      event?.target?.value?.slice(0, 500)?.length <= 500 &&
      event?.target?.id === "my_bio"
    ) {
      const newProfileData = {
        ...profileData,
        [event.target.id]: event?.target?.value?.slice(0, 500),
      };
      setCharacterCount(event?.target?.value?.slice(0, 500)?.length);
      setProfileData(newProfileData);
    } else if (
      event?.target?.value?.length > 500 &&
      event?.target?.id === "my_bio"
    ) {
      setCount(event?.target?.value?.length);
    } else {
      const newProfileData = {
        ...profileData,
        [event.target.id]: event?.target?.value,
      };
      setProfileData(newProfileData);
    }
  };

  const handleContactNumber = async (name, value) => {
    setContactNumber(value);
    // setErrors([]);
  };

  const handleMultipleAutoComplete = ({ event, newValue, id }) => {
    let newProfileData = {};
    newProfileData = {
      ...profileData,
      [id]: newValue.map((val) => val?.inputValue || val?.id || val),
    };
    setProfileData(newProfileData);
  };

  const handlePassword = (event) => {
    setNewPassword(event?.target?.value);
    if (!PASSWORD_REGEX?.test(event?.target?.value)) {
      setErrors((prev) => [
        ...prev,
        { message: "password not matching", key: "password" },
      ]);
      return;
    } else {
      const filteredErrors = errors?.filter((item) => item?.key !== "password");
      setErrors(filteredErrors);
    }
  };

  const handlePasswordReset = async () => {
    const params = {
      new_password: newPassword,
    };
    if (!PASSWORD_REGEX?.test(newPassword)) {
      setErrors((prev) => [
        ...prev,
        { message: "password not matching", key: "password" },
      ]);
      return;
    }
    try {
      const { payload } = await dispatch(resetLoggedInUserPassword(params));
      if (payload?.status === "success") {
        // setNewPassword("");
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE.SUCCESS,
            msg: "Password reset Successfully!",
          })
        );
      } else {
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE.ERROR,
            msg: payload?.message,
          })
        );
      }
    } catch (error) {
      dispatch(setAlert({ show: true }));
    }
  };

  const getNatiValue = () => {
    if (profileData?.nationality_ids?.length === 0) {
      return [];
    }
    return profileData?.nationality_ids?.map(
      (nation) => nationality?.find((nati) => nati.id === nation) || nation
    );
  };
  const getLangValue = () => {
    if (profileData?.language_ids?.length === 0) {
      return [];
    }
    return profileData?.language_ids?.map(
      (lang) => language?.find((language) => language.id === lang) || lang
    );
  };

  const handleGender = (event, gender) => {
    const newProfileData = {
      ...profileData,
      gender: profileData.gender === gender ? null : gender,
    };
    const filteredErrors = errors?.filter((item) => item.key !== "gender");
    setErrors(filteredErrors);

    setProfileData(newProfileData);
  };

  const handleDateChange = (newValue) => {
    const newProfileData = {
      ...profileData,
      dob: dayjs(newValue).format("YYYY-MM-DD"),
    };
    const filteredErrors = errors?.filter((item) => item.key !== "dob");
    setErrors(filteredErrors);
    setProfileData(newProfileData);
  };

  const handleChangeSetup = (event, name) => {
    const newProfileData = {
      ...profileData,
      [name]: event?.target?.checked,
      criminal_record: event?.target?.checked
        ? ""
        : profileData?.criminal_record,
    };
    setProfileData(newProfileData);
  };

  const handlePrefer = (event, name) => {
    const preferToSay = event?.target?.checked ? true : false;
    const newProfileData = {
      ...profileData,
      [name]: preferToSay,
      gender: null,
    };
    setProfileData(newProfileData);
  };

  const getMaleIcon = (
    <Box
      component="img"
      alt="male white"
      src={
        profileData?.gender === "male" || profileData?.reveal_gender
          ? maleWhite
          : maleBlack
      }
      sx={{ width: "7px" }}
    />
  );
  const getFemaleIcon = (
    <Box
      component="img"
      alt="female white"
      src={
        profileData?.gender === "female" || profileData?.reveal_gender
          ? femaleWhite
          : femaleBlack
      }
      sx={{ width: "9px" }}
    />
  );

  const onSaveProfile = async (data, changeNext) => {
    try {
      if (profileData?.relocate === null) {
        data = {
          ...profileData,
          relocate: 0,
        };
      }
      const contactInfo = getContactDetail(
        formatPhoneNumberIntl(
          contactNumber?.startsWith("+") ? contactNumber : `+${contactNumber}`
        )
      );
      const updatedData = {
        ...data,
        contact_no: contactInfo?.[1],
        country_code: contactInfo?.[0],
        nationality_ids: profileData?.nationality_ids?.map(
          (item) => item?.value
        ),
        language_ids: profileData?.language_ids?.map((item) => item?.value),
        disability_ids: profileData?.disability_ids?.map((item) => item?.value),
        culture_ids: profileData?.company_culture?.map((item) => item?.value),
        // reveal_gender: !profileData?.reveal_gender,
      };
      const { payload } = await dispatch(createProfile(updatedData));
      if (payload?.data?.status === "success") {
        await dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE.SUCCESS,
            msg: "Profile created successfully!",
          })
        );
        setErrors([]);
        handleInfoSave();
        changeNext && navigate("/candidate/my-cv");
      } else if (payload?.data?.status === "error") {
        setErrors(payload?.data?.message);
      } else {
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE.ERROR,
            msg: payload?.data?.message?.[0]?.message,
          })
        );
      }
    } catch (error) {
      setErrors(error);
    }
  };

  const handleOnSelectChange = async (ind, newValue, val, inputId) => {
    if (inputId === "country_id") {
      let countryId = newValue?.value;
      let selectedCountry = countries?.find((item) => item.id === countryId);

      if (selectedCountry && selectedCountry?.country_code) {
        let updatedNumber = checkPhoneNumber(
          previousCountryCode,
          selectedCountry?.country_code,
          contactNumber
        );

        setPreviousCountryCode(selectedCountry?.country_code);
        const newProfileData = {
          ...profileData,
          country_code: selectedCountry?.country_code,
          country_id: countryId,
          town_id: "",
          town_name: "",
          country_name: newValue?.label || profile?.country_name,
        };
        setContactNumber(updatedNumber);
        setProfileData(newProfileData);
        setErrors(errors?.filter((item) => item.key !== "country_id"));
        try {
          dispatch(setLoading(true));
          const { payload } = await dispatch(getTown(countryId));
          dispatch(setLoading(false));
        } catch (error) {
          dispatch(setLoading(false));
          dispatch(
            setAlert({
              show: true,
              type: ALERT_TYPE.ERROR,
              msg: ERROR_MSG,
            })
          );
        }
      }
    }
    if (inputId === "town_id") {
      const newProfileData = {
        ...profileData,
        town_id: newValue?.value,
        town_name: newValue?.label,
      };
      setProfileData(newProfileData);
      setErrors(errors?.filter((item) => item.key !== "town_id"));
    }
    if (ind === "nationality_ids") {
      let newProfileData = {};
      newProfileData = {
        ...profileData,
        [ind]: newValue,
      };
      setProfileData(newProfileData);
      setErrors(errors?.filter((item) => item.key !== "nationality_ids"));
    }
    if (ind === "language_ids") {
      let newProfileData = {};
      newProfileData = {
        ...profileData,
        [ind]: newValue,
      };
      setProfileData(newProfileData);
      setErrors(errors?.filter((item) => item.key !== "language_ids"));
    }
    if (ind === "disability_ids") {
      let newProfileData = {};
      newProfileData = {
        ...profileData,
        [ind]: newValue,
      };
      setProfileData(newProfileData);
      setErrors(errors?.filter((item) => item.key !== "disability_ids"));
    }
    if (ind === "company_culture") {
      let newProfileData = {};
      newProfileData = {
        ...profileData,
        [ind]: newValue,
      };
      setProfileData(newProfileData);
    }
  };
  const updateDebounceFunc = async (newValue, type) => {
    let params;
    if (type !== "company_culture") {
      params = {
        title: newValue || "",
        id: profileDataRef.current?.country_id,
        limit: 200,
      };
    } else {
      params = {
        title: newValue || "",
        limit: 200,
      };
    }
    if (newValue) {
      try {
        if (type === "town_id") {
          dispatch(getTown({ ...params, setState: setIsLoading, type }));
        }
        if (type === "company_culture") {
          dispatch(getCompanyCulture(params));
        }
      } catch (error) {
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE?.ERROR,
            msg: error,
          })
        );
      }
    }
  };
  const debounceFunction = _.debounce(updateDebounceFunc, 750);

  const debouncedHandleOnSelectInputChange = useCallback(
    debounce(
      async (newValue, type) => {
        let params = {
          title: newValue || "",
          id: profileDataRef.current?.country_id,
          limit: 200,
        };
        if (newValue) {
          try {
            if (type === "town_id") {
              dispatch(getTown(params));
            }
          } catch (error) {
            dispatch(
              setAlert({
                show: true,
                type: ALERT_TYPE.ERROR,
                msg: error,
              })
            );
          }
        }
      },
      750,
      setIsLoading
    ),
    []
  );

  const handleOnSelectInputChange = (newValue, inputId) => {
    // debouncedHandleOnSelectInputChange(newValue, inputId);
    debounceFunction(newValue, inputId);
  };

  const onMenuClose = async () => {
    try {
      const { payload } = await dispatch(
        getTown(profileData?.country_id || "")
      );
    } catch (error) {
      dispatch(
        setAlert({
          show: true,
          type: ALERT_TYPE.ERROR,
          msg: ERROR_MSG,
        })
      );
    }

    setIsMenuOpen(true);
  };

  const onMenuOpen = async () => {
    if (town?.length === 0) {
      try {
        const { payload } = await dispatch(
          getTown(profileData?.country_id || "")
        );
      } catch (error) {
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE.ERROR,
            msg: ERROR_MSG,
          })
        );
      }
    }
    setIsMenuOpen(true);
  };

  const handleIdProofClick = () => {
    hiddenIdProof?.current?.click();
  };

  const handleFileClick = () => {
    hiddenFileInput?.current?.click();
  };

  const handleIDProofChange = async (event) => {
    const formData = new FormData();
    formData.append("idproof", event?.target?.files?.[0]);
    try {
      const { payload } = await dispatch(uploadIDProof(formData));
      if (payload?.status === "success") {
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE?.SUCCESS,
            msg: "ID proof uploaded Successfully!",
          })
        );
        setProfileData({ ...profileData, id_proof_url: payload?.id_proof_url });
        setIdProofName(event?.target?.files?.[0]?.name);
      } else {
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE?.ERROR,
            msg: payload?.message,
          })
        );
      }
    } catch (error) {
      dispatch(setAlert({ show: true }));
    }
  };

  const handleFileChange = async (event) => {
    const formData = new FormData();
    formData.append("portfolio", event?.target?.files?.[0]);
    try {
      const { payload } = await dispatch(uploadPortfolio(formData));
      if (payload?.status === "success") {
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE?.SUCCESS,
            msg: "Portfolio uploaded Successfully!",
          })
        );
        setProfileData({
          ...profileData,
          portfolio_url: payload?.portfolio_url,
        });
        setPortFolioName(event?.target?.files?.[0]?.name);
      } else {
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE?.ERROR,
            msg: payload?.message,
          })
        );
      }
    } catch (error) {
      dispatch(setAlert({ show: true }));
    }
  };

  const handleCompanySize = (event) => {
    const {
      target: { value },
      target: { name },
    } = event;
    const newProfileData = {
      ...profileData,
      [name]: companySize?.find((role) => role?.id == value)?.name || "",
    };
    setProfileData(newProfileData);
  };
  const handleCompanyType = (event) => {
    const {
      target: { value },
      target: { name },
    } = event;

    const newProfileData = {
      ...profileData,
      [name]: companyType?.find((role) => role?.id == value)?.name || "",
    };
    setProfileData(newProfileData);
  };

  const getCultureValue = () => {
    if (profileData?.company_culture?.length === 0) {
      return "";
    }
    return profileData?.company_culture?.map((item) => ({
      value: item?.value,
      label: item?.label,
    }));
  };

  useEffect(() => {
    profileDataRef.current = profileData;
  }, [profileData]);

  useEffect(() => {
    let updatedNationalityList;
    if (profile?.nationality_ids) {
      updatedNationalityList = profile?.nationality_ids
        ?.map((nation) => nationality?.find((nati) => nati.id === nation))
        ?.map((i) => ({ label: i?.name, value: i?.nationality_id }));
    }
    let updatedLanguageList;
    if (profile?.language_ids) {
      updatedLanguageList = profile?.language_ids
        ?.map((lang) => language?.find((language) => language.id === lang))
        ?.map((i) => ({ label: i?.name, value: i?.language_id }));
    }
    let updatedDisabilityList;
    if (profile?.disability_ids) {
      updatedDisabilityList = profile?.disability_ids
        ?.map((dis) => disability?.find((disab) => disab?.id === dis))
        ?.map((i) => ({ label: i?.name, value: i?.disability_id }));
    }
    let updatedCountryCode = countries?.filter(
      (item) => item?.name === profile?.country_name
    )?.[0];
    let updatedData = {
      ...profile,
      country_id: updatedCountryCode?.country_id,
      nationality_ids: [...(updatedNationalityList || [])],
      language_ids: [...(updatedLanguageList || [])],
      disability_ids: [...(updatedDisabilityList || [])],
    };
    setProfileData(updatedData);
    setPreviousCountryCode(updatedCountryCode?.country_code);
    setContactNumber(`${profile?.country_code}${profile?.contact_no}` || "");
    setPageIsLoad(true);
  }, [countries, profile, nationality, language, disability]);

  return (
    <>
      <Box className="" sx={{ mt: 0 }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            mb: "20px",
            width: "100%",
            gap: { xs: "20px", mddl: "0px" },
            flexDirection: { xs: "column", md: "column", mddl: "row" },
          }}
        >
          <Box sx={{ width: { xs: "100%", mddl: "50%" } }}>
            <Typography
              sx={{
                fontSize: theme.typography.fontSize.sm,
                fontWeight: theme.typography.Bold,
                marginBottom: "4px",
              }}
            >
              {i18n["myProfile.nameLabelRequired"]}
            </Typography>
            <InputBox
              sx={{
                width: "95%",
                height: "38px",
                borderRadius: "10px",
                borderColor:
                  profileData?.name === "" &&
                  errors?.find((error) => error.key === "name") &&
                  theme.jobCard.jobStatus.Rejected.main,
              }}
              placeholder={i18n["myProfile.name"]}
              value={profileData?.name}
              id="name"
              onChange={handleInputChange}
            />
          </Box>
          <Box sx={{ width: { xs: "100%", mddl: "50%" } }}>
            <Typography
              sx={{
                fontSize: theme.typography.fontSize.sm,
                fontWeight: theme.typography.Bold,
                marginBottom: "4px",
              }}
            >
              {i18n["myProfile.surnameLabelRequired"]}
            </Typography>
            <InputBox
              sx={{
                width: "95%",
                height: "38px",
                borderRadius: "10px",
                borderColor:
                  profileData?.surname === "" &&
                  errors?.find((error) => error.key === "surname") &&
                  theme.jobCard.jobStatus.Rejected.main,
              }}
              placeholder={i18n["myProfile.surname"]}
              value={profileData?.surname}
              id="surname"
              onChange={handleInputChange}
            />
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            mb: "20px",
            width: "100%",
            gap: { xs: "20px", mddl: "0px" },
            flexDirection: { xs: "column", md: "column", mddl: "row" },
          }}
        >
          <Box sx={{ width: { xs: "100%", mddl: "50%" } }}>
            <Typography
              sx={{
                fontSize: theme.typography.fontSize.sm,
                fontWeight: theme.typography.Bold,
                marginBottom: "4px",
              }}
            >
              {i18n["myProfile.emailLabelRequired"]}
            </Typography>
            <InputBox
              sx={{
                width: "95%",
                height: "38px",
                borderRadius: "10px",
                borderColor:
                  profileData?.email === "" &&
                  errors?.find((error) => error.key === "email") &&
                  theme.jobCard.jobStatus.Rejected.main,
              }}
              placeholder={i18n["myProfile.email"]}
              value={profileData?.email}
              id="email"
              onChange={handleInputChange}
            />
          </Box>

          <Box sx={{ width: { xs: "100%", mddl: "50%" } }}>
            <Typography
              sx={{
                fontSize: theme.typography.fontSize.sm,
                fontWeight: theme.typography.Bold,
                marginBottom: "4px",
              }}
            >
              {i18n["myProfile.contactLabelReq"]}
            </Typography>

            <Paper
              sx={{
                display: "flex",
                width: "95%",
                height: "38px",
                borderRadius: "10px",
                boxShadow: "none",
              }}
            >
              <Box
                sx={{
                  background: "transparent",
                  width: "100%",
                  height: "38px",
                  borderRadius: "10px",
                  display: "flex",
                  justifyContent: "space-between",
                  border: errors?.find((error) => error.key === "contact_no")
                    ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
                    : `1px solid ${theme.palette.grayBorder}`,
                }}
              >
                <PhoneInput
                  international
                  id="contact_no"
                  placeholder="Enter phone number"
                  countryCallingCodeEditable={false}
                  value={
                    contactNumber?.startsWith("+")
                      ? contactNumber
                      : `+${contactNumber}` || ""
                  }
                  onChange={(value) => {
                    handleContactNumber("contact_no", value);
                  }}
                  // defaultCountry={profileData?.country_code?.toUpperCase()}
                  focusInputOnCountrySelection={true}
                  onCountryChange={(value) => {
                    const newCountryId = value
                      ? countries?.find(
                          (val) => val?.name === regionNames?.of(value)
                        )?.id
                      : "";
                    handleCountryIdChange(newCountryId);
                  }}
                  style={{
                    font: "inherit",
                    padding: "10px",
                    fontSize: "14px",
                  }}
                />
                {isValidPhoneNumber(contactNumber || "") && (
                  <Typography
                    sx={{
                      color: theme.palette.tealColor.main,
                      fontSize: "14px",
                      alignItems: "center",
                      display: "flex",
                      gap: "5px",
                      marginRight: "5px",
                      justifyContent: "end",
                    }}
                  >
                    Correct <CheckCircle fontSize="small" />
                  </Typography>
                )}
              </Box>
            </Paper>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            mb: "20px",
            width: "100%",
            gap: { xs: "20px", mddl: "0px" },
            flexDirection: { xs: "column", md: "column", mddl: "row" },
          }}
        >
          <Box sx={{ width: { xs: "100%", tablet: "50%" } }}>
            <Typography
              sx={{
                fontSize: theme.typography.fontSize.sm,
                fontWeight: theme.typography.Bold,
                marginBottom: "4px",
              }}
            >
              {i18n["myProfile.birthLabelRequired"]}
            </Typography>

            <LocalizationProvider dateAdapter={AdapterDayjs} locale="en-gb">
              <DatePicker
                name="dob"
                value={profileData?.dob}
                onChange={handleDateChange}
                maxDate={currentDate}
                components={{
                  OpenPickerIcon: CustomCalendarIcon, // Use your custom SVG component here
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    sx={{
                      width: { xs: "100%", tablet: "50%" },

                      "& .MuiInputBase-root": {
                        height: "40px",
                        width: "100%",
                        borderRadius: "10px",
                        fontSize: "14px",
                        border: errors?.find((error) => error.key === "dob")
                          ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
                          : "1px solid #E0E0E0",
                        ":hover": {
                          border: errors?.find((error) => error.key === "dob")
                            ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
                            : "1px solid #E0E0E0",
                        },
                      },
                      "& fieldset": {
                        border: "none",
                      },
                      "& .MuiIconButton-root": {
                        color: theme.palette.yellowColor,
                      },
                    }}
                  />
                )}
              />
            </LocalizationProvider>
          </Box>

          <Box sx={{ width: { xs: "100%", mddl: "50%" } }}>
            <Typography
              sx={{
                fontSize: theme.typography.fontSize.sm,
                fontWeight: theme.typography.Bold,
                marginBottom: "4px",
              }}
            >
              {i18n["myProfile.genderLabelOpt"]}{" "}
              <span
                style={{
                  fontSize: theme.typography.fontSize.sm,
                  fontWeight: theme.typography.Regular,
                }}
              >
                (optional)
              </span>
            </Typography>
            <Box
              sx={{
                display: "flex",
                gap: "30px",
                flexDirection: { xs: "column", tb: "row" },
              }}
            >
              <Paper
                elevation={3}
                sx={{
                  width: "176px",
                  height: "40px",
                  boxShadow: "none",
                  border: errors?.find((error) => error.key === "gender")
                    ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
                    : `1px solid ${theme.palette.grayBorder}`,
                  borderRadius: "10px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Button
                    // disabled={
                    //   profileData?.reveal_gender === null
                    //     ? true
                    //     : !profileData?.reveal_gender
                    // }
                    disabled={profileData?.reveal_gender}
                    name="gender"
                    startIcon={getMaleIcon}
                    variant="contained"
                    color={
                      profileData?.gender === "male"
                        ? "blueButton400"
                        : "grayBorder"
                    }
                    onClick={(event) => handleGender(event, "male")}
                    sx={{
                      borderRadius: "10px",
                      height: "40px",
                      width: "85px",
                      fontWeight: 600,
                      fontSize: "14px",
                      color:
                        profileData?.gender === "female" && theme.palette.black,
                      background:
                        profileData?.gender === "female" &&
                        theme.palette.base.main,
                      ":hover": {
                        boxShadow: "none",
                      },
                    }}
                  >
                    {i18n["empMyProfile.male"]}
                  </Button>
                  <Button
                    // disabled={
                    //   profileData?.reveal_gender === null
                    //     ? true
                    //     : !profileData?.reveal_gender
                    // }
                    disabled={profileData?.reveal_gender}
                    name="gender"
                    startIcon={getFemaleIcon}
                    sx={{
                      borderRadius: "10px",
                      height: "40px",
                      width: "85px",
                      fontWeight: 600,
                      fontSize: "14px",
                      color:
                        profileData?.gender === "male" && theme.palette.black,
                      background:
                        profileData?.gender === "male" &&
                        theme.palette.base.main,
                      ":hover": {
                        boxShadow: "none",
                      },
                    }}
                    variant="contained"
                    color={
                      profileData?.gender === "female"
                        ? "purpleButton300"
                        : "grayBorder"
                    }
                    onClick={(event) => handleGender(event, "female")}
                  >
                    {i18n["empMyProfile.female"]}
                  </Button>
                </Box>
              </Paper>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            mb: "20px",
            width: "100%",
            gap: { xs: "20px", mddl: "0px" },
            flexDirection: { xs: "column", md: "column", mddl: "row" },
          }}
        >
          <Box sx={{ width: { xs: "100%", xl: "50%" } }}>
            <Typography
              sx={{
                fontSize: theme.typography.fontSize.sm,
                fontWeight: theme.typography.Bold,
                marginBottom: "4px",
              }}
            >
              {i18n["myProfile.countryRequired"]}
            </Typography>
            <CustomSelect
              onMenuOpen={() => {}}
              variant="default"
              onInputChange={handleOnSelectInputChange}
              onChange={handleOnSelectChange}
              placeholder={"Select"}
              inputId="country_id"
              name="country_id"
              value={
                profileData?.country_id && profileData?.country_name
                  ? {
                      value: profileData?.country_id
                        ? profileData?.country_id
                        : "",
                      label: profileData?.country_name
                        ? profileData?.country_name
                        : "",
                    }
                  : null
                //   {
                //   value: profileData?.country_id,
                //   label: profileData?.country_name,
                // }
              }
              // isLoading={isLoading}
              filterOption={"filter"}
              customStyle={{
                menu: (provided) => ({
                  ...provided,
                  zIndex: 9999,
                }),
                menuList: (provided) => ({
                  ...provided,
                  "&::-webkit-scrollbar": {
                    width: "6px",
                    height: "6px",
                    borderRadius: "2.5px",
                    backgroundColor: "#efefef",
                  },
                  "&::-webkit-scrollbar-thumb": {
                    borderRadius: "2.5px",
                  },
                }),
                option: (
                  styles,
                  { data, isDisabled, isFocused, isSelected }
                ) => {
                  return {
                    ...styles,
                    color: isSelected ? "#fff" : "black",
                    ":active": {
                      ...styles[":active"],
                    },
                  };
                },
                control: (baseStyles) => ({
                  ...baseStyles,
                  boxShadow: "none",
                  borderRadius: "10px",
                  width: "95%",
                  border:
                    profileData?.country_id === null &&
                    errors?.find((error) => error.key === "country_id")
                      ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
                      : "1px solid #E0E0E0",
                  "&:hover":
                    profileData?.country_id === null &&
                    errors?.find((error) => error.key === "country_id")
                      ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
                      : "1px solid #E0E0E0",
                  // width: `100%`,
                }),
              }}
              option={countries?.map((item) => ({
                value: item?.country_id,
                label: item?.name,
              }))}
            />
          </Box>

          <Box
            sx={{
              width: { xs: "100%", xl: "50%" },
              display: "flex",
              flexDirection: { xs: "column", tb: "row" },
              justifyContent: "space-between",
              alignItems: { xs: "start", tb: "center" },
            }}
          >
            <Box sx={{ width: "70%" }}>
              <Typography
                sx={{
                  fontSize: theme.typography.fontSize.sm,
                  fontWeight: theme.typography.Bold,
                  marginBottom: "4px",
                }}
              >
                {i18n["myProfile.townLabelRequired"]}
              </Typography>
              <CustomSelect
                onMenuClose={onMenuClose}
                onMenuOpen={onMenuOpen}
                variant="default"
                onInputChange={handleOnSelectInputChange}
                onChange={handleOnSelectChange}
                placeholder={i18n["myProfile.city"]}
                inputId="town_id"
                name="town_id"
                value={
                  profileData?.town_id && profileData?.town_name
                    ? {
                        value: profileData?.town_id ? profileData?.town_id : "",
                        label: profileData?.town_name
                          ? profileData?.town_name
                          : "",
                      }
                    : null
                  //   {
                  //   value: profileData?.town_id,
                  //   label: profileData?.town_name,
                  // }
                }
                isLoading={isLoading["town_id"]}
                customStyle={{
                  menu: (provided) => ({
                    ...provided,
                    zIndex: 9999,
                  }),
                  menuList: (provided) => ({
                    ...provided,
                    "&::-webkit-scrollbar": {
                      width: "6px",
                      height: "6px",
                      borderRadius: "2.5px",
                      backgroundColor: "#efefef",
                    },
                    "&::-webkit-scrollbar-thumb": {
                      borderRadius: "2.5px",
                    },
                  }),
                  option: (
                    styles,
                    { data, isDisabled, isFocused, isSelected }
                  ) => {
                    return {
                      ...styles,
                      color: isSelected ? "#fff" : "black",
                      ":active": {
                        ...styles[":active"],
                      },
                    };
                  },
                  control: (baseStyles) => ({
                    ...baseStyles,
                    boxShadow: "none",
                    borderRadius: "10px",
                    border:
                      !town?.find(
                        (val) => val?.town_id === profileData?.town_id
                      )?.name &&
                      errors?.find((error) => error.key === "town_id")
                        ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
                        : "1px solid #E0E0E0",
                    "&:hover":
                      !town?.find(
                        (val) => val?.town_id === profileData?.town_id
                      )?.name &&
                      errors?.find((error) => error.key === "town_id")
                        ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
                        : "1px solid #E0E0E0",
                  }),
                }}
                option={town?.map((item) => ({
                  value: item?.town_id,
                  label: item?.name,
                }))}
              />
            </Box>
            <Box
              sx={{
                width: "30%",
                display: "flex",
                alignItems: "center",
                gap: "40px",
                marginTop: "20px",
                marginLeft: "20px",
              }}
            >
              <Typography
                sx={{
                  marginRight: "-30px",
                  minWidth: "fit-content",
                }}
              >
                {i18n["myProfile.willingToRelocate"]}
              </Typography>
              <ToggleSwitch
                color="greenSwitch"
                id="relocate"
                checked={!!Number(profileData?.relocate)}
                onChange={handleSwitch}
              />
            </Box>
            {!profileData?.relocate &&
              errors?.find((error) => error.key === "relocate") && (
                <Typography color={"red !important"}>
                  {`*${
                    errors?.find((error) => error.key === "relocate")?.message
                  }`}
                </Typography>
              )}
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            mb: "20px",
            width: "100%",
            gap: { xs: "20px", mddl: "0px" },
            flexDirection: { xs: "column", md: "column", mddl: "row" },
          }}
        >
          <Box sx={{ width: { xs: "100%", xl: "50%" } }}>
            <Typography
              sx={{
                fontSize: theme.typography.fontSize.sm,
                fontWeight: theme.typography.Bold,
                marginBottom: "4px",
              }}
            >
              {i18n["myProfile.nationalityLabelRequired"]}
            </Typography>

            <CustomSelect
              onMenuOpen={() => {}}
              variant="multiSelect"
              filterOption={"filter"}
              onInputChange={handleOnSelectInputChange}
              onChange={handleOnSelectChange}
              placeholder={i18n["myProfile.nationality"]}
              inputId="nationality_ids"
              name="nationality_ids"
              value={
                profileData?.nationality_ids?.length > 0
                  ? profileData?.nationality_ids
                  : []
              }
              // isLoading={isLoading}
              customStyle={{
                menu: (provided) => ({
                  ...provided,
                  zIndex: 9999,
                }),
                menuList: (provided) => ({
                  ...provided,
                  "&::-webkit-scrollbar": {
                    width: "6px",
                    height: "6px",
                    borderRadius: "2.5px",
                    backgroundColor: "#efefef",
                  },
                  "&::-webkit-scrollbar-thumb": {
                    borderRadius: "2.5px",
                  },
                }),
                option: (
                  styles,
                  { data, isDisabled, isFocused, isSelected }
                ) => {
                  return {
                    ...styles,
                    color: isSelected ? "#fff" : "black",
                    ":active": {
                      ...styles[":active"],
                    },
                  };
                },
                control: (baseStyles) => ({
                  ...baseStyles,
                  boxShadow: "none",
                  borderRadius: "10px",
                  width: "95%",
                  border:
                    getNatiValue()?.length === 0 &&
                    errors?.find((error) => error.key === "nationality_ids")
                      ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
                      : "1px solid #E0E0E0",
                  "&:hover":
                    getNatiValue()?.length === 0 &&
                    errors?.find((error) => error.key === "nationality_ids")
                      ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
                      : "1px solid #E0E0E0",
                }),
              }}
              multiSelectOptions={nationality?.map((item) => ({
                value: item?.nationality_id,
                label: item?.name,
              }))}
            />
          </Box>
          <Box sx={{ width: { xs: "100%", xl: "50%" } }}>
            <Typography
              sx={{
                fontSize: theme.typography.fontSize.sm,
                fontWeight: theme.typography.Bold,
                marginBottom: "4px",
              }}
            >
              {i18n["myProfile.languageLabelRequired"]}
            </Typography>
            <CustomSelect
              onMenuOpen={() => {}}
              variant="multiSelect"
              onInputChange={handleOnSelectInputChange}
              onChange={handleOnSelectChange}
              placeholder={i18n["myProfile.language"]}
              inputId="language_ids"
              name="language_ids"
              filterOption={"filter"}
              value={profileData?.language_ids}
              // isLoading={isLoading}
              customStyle={{
                menu: (provided) => ({
                  ...provided,
                  zIndex: 9999,
                }),
                menuList: (provided) => ({
                  ...provided,
                  "&::-webkit-scrollbar": {
                    width: "6px",
                    height: "6px",
                    borderRadius: "2.5px",
                    backgroundColor: "#efefef",
                  },
                  "&::-webkit-scrollbar-thumb": {
                    borderRadius: "2.5px",
                  },
                }),
                option: (
                  styles,
                  { data, isDisabled, isFocused, isSelected }
                ) => {
                  return {
                    ...styles,
                    color: isSelected ? "#fff" : "black",
                    ":active": {
                      ...styles[":active"],
                    },
                  };
                },
                control: (baseStyles) => ({
                  ...baseStyles,
                  boxShadow: "none",
                  borderRadius: "10px",
                  width: "95%",
                  border:
                    getLangValue()?.length === 0 &&
                    errors?.find((error) => error.key === "language_ids")
                      ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
                      : "1px solid #E0E0E0",
                  "&:hover":
                    getLangValue()?.length === 0 &&
                    errors?.find((error) => error.key === "language_ids")
                      ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
                      : "1px solid #E0E0E0",
                }),
              }}
              multiSelectOptions={language?.map((item) => ({
                value: item?.language_id,
                label: item?.name,
              }))}
            />
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            mb: "20px",
            width: "100%",
            gap: { xs: "20px", mddl: "0px" },
            flexDirection: { xs: "column", md: "column", mddl: "row" },
          }}
        >
          <Box sx={{ width: { xs: "100%", xl: "50%" } }}>
            <Typography
              sx={{
                fontSize: theme.typography.fontSize.sm,
                fontWeight: theme.typography.Bold,
                marginBottom: "4px",
              }}
            >
              {i18n["myProfile.myBioRequired"]}{" "}
              <span>({characterCount}/500)</span>
            </Typography>
            <InputBox
              multiline={true}
              sx={{
                width: "95%",
                height: "120px",
                borderRadius: "10px",
                alignItems: "flex-start",
                border:
                  (profileData?.my_bio === null ||
                    profileData?.my_bio === "" ||
                    count > 500) &&
                  errors?.find((error) => error.key === "my_bio")
                    ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
                    : "1px solid #E0E0E0",
              }}
              placeholder={i18n["myProfile.myBioPlace"]}
              value={profileData?.my_bio}
              id="my_bio"
              onChange={handleInputChange}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: { xs: "100%", xl: "50%" },
              gap: "15px",
            }}
          >
            <Box sx={{ width: "100%" }}>
              <Typography
                sx={{
                  fontSize: theme.typography.fontSize.sm,
                  fontWeight: theme.typography.Bold,
                  marginBottom: "4px",
                }}
              >
                {i18n["myProfile.linkedInLabel"]}{" "}
                <span
                  style={{
                    fontSize: theme.typography.fontSize.sm,
                    fontWeight: theme.typography.Regular,
                  }}
                >
                  (optional)
                </span>
              </Typography>
              <InputBox
                sx={{
                  width: "95%",
                  height: "38px",
                  borderRadius: "10px",
                  border:
                    (profileData?.linkedin_profile_link === null ||
                      profileData?.linkedin_profile_link === "" ||
                      (profileData?.linkedin_profile_link &&
                        !profileData?.linkedin_profile_link?.startsWith(
                          "http"
                        ))) &&
                    errors?.find(
                      (error) => error.key === "linkedin_profile_link"
                    )
                      ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
                      : "1px solid #E0E0E0",
                }}
                placeholder={i18n["myProfile.linkedIn"]}
                value={profileData?.linkedin_profile_link}
                id="linkedin_profile_link"
                onChange={handleInputChange}
              />
            </Box>
            <Box sx={{ width: "100%" }}>
              <Typography
                sx={{
                  marginBottom: "4px",
                  display: "flex",
                  justifyContent: "space-between",
                  width: "95%",
                  fontSize: theme.typography.fontSize.sm,
                  fontWeight: theme.typography.Bold,
                }}
              >
                <Box>
                  {i18n["myProfile.portfolioLabelOpt"]}{" "}
                  <span
                    style={{
                      fontSize: theme.typography.fontSize.sm,
                      fontWeight: theme.typography.Regular,
                    }}
                  >
                    (optional)
                  </span>
                </Box>
                {profileData?.portfolio_url !== "NO URL" && (
                  <Link
                    to={`${profileData?.portfolio_url}`}
                    style={{
                      textDecoration: "none",
                      color: theme.palette.black,
                    }}
                    target="_blank"
                  >
                    <span
                      style={{
                        display: "flex",
                        gap: "12px",
                        cursor: "pointer",
                      }}
                    >
                      preview
                      <Box
                        component="img"
                        className="eye"
                        alt="eye logo"
                        src={view}
                        sx={{
                          height: 20,
                          width: 20,
                        }}
                      />
                    </span>
                  </Link>
                )}
              </Typography>
              <input
                accept={fileAccept}
                ref={hiddenFileInput}
                type="file"
                onChange={handleFileChange}
                style={{ display: "none" }}
                required
              />
              <Paper
                sx={{
                  display: "flex",
                  height: "38px",
                  borderRadius: "10px",
                  border: `1px solid ${theme.palette.grayBorder}`,
                  width: "95%",
                  boxShadow: "none",
                }}
              >
                <Paper
                  elevation={0}
                  component="form"
                  sx={{
                    display: "flex",
                    borderRadius: "25px",
                    alignItems: "center",
                    width: 1,
                  }}
                >
                  <InputBase
                    sx={{
                      ml: 2,
                      width: 1,
                      fontSize: "14px",
                    }}
                    value={profileData?.portfolio_link}
                    onChange={(event) =>
                      setProfileData({
                        ...profileData,
                        portfolio_link: event?.target?.value,
                      })
                    }
                    placeholder={i18n["myProfile.addlink"]}
                    type={"text"}
                  />
                </Paper>
                <Button
                  sx={{
                    ml: "4px",
                    minWidth: "fit-content",
                    boxShadow: 0,
                    borderRadius: "0 10px 10px 0",
                    height: "auto",
                    fontSize: "14px",
                    fontWeight: theme.typography.Bold,
                  }}
                  variant="contained"
                  color={profile?.portfolio ? "greenColor" : "nandos"}
                  onClick={handleFileClick}
                >
                  {i18n["myProfile.uploadportfolio"]}
                </Button>
              </Paper>
              <Typography
                sx={{
                  width: "94%",
                  textAlign: "end",
                  fontSize: "14px",
                  fontWeight: theme?.typography?.Regular,
                  ml: 1,
                  mt: "4px",
                }}
              >
                {portfolioName?.substring(portfolioName?.indexOf("_") + 1)}
              </Typography>
            </Box>
          </Box>
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            mb: "20px",
            width: "100%",
            gap: { xs: "20px", mddl: "0px" },
            flexDirection: { xs: "column", md: "column", mddl: "row" },
          }}
        >
          <Box
            sx={{
              display: "flex",
              // justifyContent: "space-between",
              flexDirection: { xs: "column", tablet: "row" },
              gap: { xs: "20px", tablet: "30px" },
              width: { xs: "100%", tablet: "100%", lg: "50%" },
            }}
          >
            <Box sx={{ width: { xs: "100%", tablet: "45.6%" } }}>
              <Typography
                sx={{
                  fontSize: theme.typography.fontSize.sm,
                  fontWeight: theme.typography.Bold,
                  marginBottom: "4px",
                }}
              >
                {i18n["myProfile.PreferredCompanyType"]}{" "}
                <span
                  style={{
                    fontSize: theme.typography.fontSize.sm,
                    fontWeight: theme.typography.Regular,
                  }}
                >
                  (optional)
                </span>
              </Typography>
              <SelectMenu
                clear={true}
                name="company_type"
                value={profileData?.company_type || ""}
                onHandleChange={handleCompanyType}
                options={companyType}
                borderRadius="10px"
                sx={{ width: "100%" }}
                placeholder={"Select the company type"}
                border={"1px solid #E0E0E0"}
              />
            </Box>
            <Box sx={{ width: { xs: "100%", tablet: "45.6%" } }}>
              <Typography
                sx={{
                  fontSize: theme.typography.fontSize.sm,
                  fontWeight: theme.typography.Bold,
                  marginBottom: "4px",
                }}
              >
                {i18n["myProfile.PreferredCompanySize"]}{" "}
                <span
                  style={{
                    fontSize: theme.typography.fontSize.sm,
                    fontWeight: theme.typography.Regular,
                  }}
                >
                  (optional)
                </span>
              </Typography>
              <SelectMenu
                clear={true}
                name="company_size"
                value={profileData?.company_size || ""}
                onHandleChange={handleCompanySize}
                options={companySize}
                borderRadius="10px"
                sx={{ width: "100%" }}
                border={
                  // !profileData?.company_size &&
                  errors?.find((error) => error?.key === "company_size")
                    ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
                    : "1px solid #E0E0E0"
                }
                placeholder={"Select the size of company"}
              />
            </Box>
          </Box>
          <Box sx={{ width: { xs: "100%", tablet: "100%", lg: "50%" } }}>
            <Typography
              sx={{
                fontSize: theme.typography.fontSize.sm,
                fontWeight: theme.typography.Bold,
                marginBottom: "4px",
              }}
            >
              {i18n["myProfile.PreferredCompanyCulture"]}{" "}
              <span
                style={{
                  fontSize: theme.typography.fontSize.sm,
                  fontWeight: theme.typography.Regular,
                }}
              >
                (optional)
              </span>
            </Typography>
            <CustomSelect
              inputId={"company_culture"}
              name={"company_culture"}
              variant={"multiSelect"}
              closeMenuOnSelect={true}
              isMulti={true}
              multiSelectOptions={companyCultures?.map((item) => ({
                value: item?.company_culture_id,
                label: item?.name,
              }))}
              placeholder={
                "Select 5 adjectives that best describe your preferred company culture"
              }
              onChange={handleOnSelectChange}
              onInputChange={handleOnSelectInputChange}
              customStyle={{
                menu: (provided) => ({
                  ...provided,
                  zIndex: 9999,
                }),
                menuList: (provided) => ({
                  ...provided,
                  "&::-webkit-scrollbar": {
                    width: "6px",
                    height: "6px",
                    borderRadius: "2.5px",
                    backgroundColor: "#efefef",
                  },
                  "&::-webkit-scrollbar-thumb": {
                    borderRadius: "2.5px",
                  },
                }),
                option: (
                  styles,
                  { data, isDisabled, isFocused, isSelected }
                ) => {
                  return {
                    ...styles,
                    color: isSelected ? "#fff" : "black",
                    ":active": {
                      ...styles[":active"],
                    },
                  };
                },
                control: (baseStyles) => ({
                  ...baseStyles,
                  boxShadow: "none",
                  borderRadius: "10px",
                  width: "95%",
                  border: "1px solid #E0E0E0",
                  "&:hover": "1px solid #E0E0E0",
                }),
              }}
              multiOnFocus={() => {
                if (companyCultures?.length === 0) {
                  dispatch(getCompanyCulture());
                }
              }}
              value={getCultureValue()}
              limitTags={5}
              initialOptions={initialCompanyCulture?.map((item) => ({
                value: item?.company_culture_id,
                label: item?.name,
              }))}
            />
          </Box>
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            mb: "20px",
            width: "100%",
            gap: { xs: "20px", mddl: "0px" },
            flexDirection: { xs: "column", md: "column", mddl: "row" },
          }}
        >
          <Box
            sx={{
              width: { xs: "100%", tablet: "50%" },
              border:
                profileData?.skinz === null &&
                errors?.find((error) => error.key === "skinz") &&
                "1px solid red",
              borderRadius: "10px",
            }}
          >
            <Typography
              sx={{
                fontSize: theme.typography.fontSize.sm,
                fontWeight: theme.typography.Bold,
                marginBottom: "4px",
              }}
            >
              {i18n["myProfile.yourCrayonSkinz"]}{" "}
              <span
                style={{
                  fontSize: theme.typography.fontSize.sm,
                  fontWeight: theme.typography.Regular,
                }}
              >
                (optional)
              </span>
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "column", tablet: "row" },
                alignItems: { xs: "center", tablet: "start" },
                gap: { xs: "20px", md: "20px", lg: "40px" },
                width: "100%",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: { xs: "row", tablet: "row" },
                  alignItems: "baseline",
                  width: "50%",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "flex-end",
                    cursor: "pointer",
                    width: "50%",
                  }}
                  onClick={(event) => handleColor(event, 0)}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <Box
                      component={"img"}
                      src={
                        profileData?.gender === "male" ||
                        profileData?.gender === "" ||
                        profileData?.gender === null
                          ? white
                          : whiteFemale
                      }
                      sx={{
                        height: "60px",
                        width: "54px",
                      }}
                    />
                    <Typography
                      sx={{
                        color: "black",
                        fontSize: "14px",
                        fontWeight: 500,
                      }}
                    >
                      White
                    </Typography>
                    {profileData?.skinz === 0 && (
                      <CheckCircle
                        sx={{
                          fontSize: 30,
                        }}
                        color="checkColor"
                      />
                    )}
                  </Box>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    alignItems: "flex-end",
                    cursor: "pointer",
                    width: "50%",
                  }}
                  onClick={(event) => handleColor(event, 1)}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <Box
                      component={"img"}
                      src={
                        profileData?.gender === "male" ||
                        profileData?.gender === "" ||
                        profileData?.gender === null
                          ? coloured
                          : colouredFemale
                      }
                      sx={{
                        height: "60px",
                        width: "54px",
                      }}
                    />
                    <Typography
                      sx={{
                        color: "black",
                        fontSize: "14px",
                        fontWeight: 500,
                      }}
                    >
                      Coloured
                    </Typography>
                    {profileData?.skinz === 1 && (
                      <CheckCircle
                        sx={{
                          fontSize: 30,
                        }}
                        color="checkColor"
                      />
                    )}
                  </Box>
                </Box>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: { xs: "row", tablet: "row" },
                  alignItems: "baseline",
                  width: "50%",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "flex-end",
                    cursor: "pointer",
                    width: "50%",
                  }}
                  onClick={(event) => handleColor(event, 2)}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <Box
                      component={"img"}
                      src={
                        profileData?.gender === "male" ||
                        profileData?.gender === "" ||
                        profileData?.gender === null
                          ? indian
                          : indianFemale
                      }
                      sx={{
                        height: "60px",
                        width: "54px",
                      }}
                    />
                    <Typography
                      sx={{
                        color: "black",
                        fontSize: "14px",
                        fontWeight: 500,
                      }}
                    >
                      Indian
                    </Typography>
                    {profileData?.skinz === 2 && (
                      <CheckCircle
                        sx={{
                          fontSize: 30,
                        }}
                        color="checkColor"
                      />
                    )}
                  </Box>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    alignItems: "flex-start",
                    cursor: "pointer",
                    width: "50%",
                  }}
                  onClick={(event) => handleColor(event, 3)}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <Box
                      component={"img"}
                      src={
                        profileData?.gender === "male" ||
                        profileData?.gender === "" ||
                        profileData?.gender === null
                          ? black
                          : blackFemale
                      }
                      sx={{
                        height: "60px",
                        width: "54px",
                      }}
                    />
                    <Typography
                      sx={{
                        color: "black",
                        fontSize: "14px",
                        fontWeight: 500,
                      }}
                    >
                      Black
                    </Typography>
                    {profileData?.skinz === 3 && (
                      <CheckCircle
                        sx={{
                          fontSize: 30,
                        }}
                        color="checkColor"
                      />
                    )}
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>

          <Box
            sx={{
              width: { xs: "100%", md: "50%", mddl: "50%" },
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <Typography
              sx={{
                fontSize: theme.typography.fontSize.sm,
                fontWeight: theme.typography.Bold,
                marginBottom: "4px",
              }}
            >
              {i18n["myProfile.YourWorkSetup"]}{" "}
              <span
                style={{
                  fontSize: theme.typography.fontSize.sm,
                  fontWeight: theme.typography.Regular,
                }}
              >
                (optional)
              </span>
            </Typography>
            <Box
              sx={{
                display: "flex",
                gap: { xs: "5px", tablet: "100px" },
                // justifyContent: "space-between",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: { xs: "baseline", tablet: "flex-end" },
                  flexDirection: { xs: "column", tablet: "row" },
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Box
                    component={"img"}
                    src={own_vehicle}
                    sx={{
                      height: "50px",
                      width: "62px",
                    }}
                  />
                  <Typography
                    sx={{
                      color: "black",
                      fontSize: theme.typography.fontSize.sm,
                      fontWeight: theme.typography.Regular,
                    }}
                  >
                    own transport
                  </Typography>
                  <ToggleSwitch
                    color="greenSwitch"
                    onChange={(event) =>
                      handleChangeSetup(event, "own_transport")
                    }
                    checked={profileData?.own_transport}
                  />
                </Box>
              </Box>
              {/* <Box
                sx={{
                  display: "flex",
                  alignItems: { xs: "baseline", tablet: "flex-end" },
                  flexDirection: { xs: "column", tablet: "row" },
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Box
                    component={"img"}
                    src={own_vehicle}
                    sx={{
                      height: "70px",
                      width: "70px",
                    }}
                  />
                  <Typography
                    sx={{
                      color: "black",
                      fontSize: "14px",
                      fontWeight: 500,
                    }}
                  >
                    Driver’s license
                  </Typography>
                  <ToggleSwitch
                    color="greenSwitch"
                    onChange={(event) =>
                      handleChangeSetup(event, "driving_license")
                    }
                    checked={profileData?.driving_license}
                  />
                </Box>
              </Box>*/}
              <Box
                sx={{
                  display: "flex",
                  alignItems: { xs: "baseline", tablet: "flex-end" },
                  flexDirection: { xs: "column", tablet: "row" },
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Box
                    component={"img"}
                    src={own_equipment}
                    sx={{
                      height: "50px",
                      width: "62px",
                    }}
                  />
                  <Typography
                    sx={{
                      color: "black",
                      fontSize: theme.typography.fontSize.sm,
                      fontWeight: theme.typography.Regular,
                    }}
                  >
                    own equipment
                  </Typography>
                  <ToggleSwitch
                    color="greenSwitch"
                    onChange={(event) =>
                      handleChangeSetup(event, "own_equipment")
                    }
                    checked={profileData?.own_equipment}
                  />
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: { xs: "baseline", tablet: "flex-end" },
                  flexDirection: { xs: "column", tablet: "row" },
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Box
                    component={"img"}
                    src={own_internet}
                    sx={{
                      height: "50px",
                      width: "62px",
                    }}
                  />
                  <Typography
                    sx={{
                      color: "black",
                      fontSize: theme.typography.fontSize.sm,
                      fontWeight: theme.typography.Regular,
                    }}
                  >
                    home Wi-Fi
                  </Typography>
                  <ToggleSwitch
                    color="greenSwitch"
                    onChange={(event) =>
                      handleChangeSetup(event, "internet_access")
                    }
                    checked={profileData?.internet_access}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            mb: "20px",
            width: { xs: "100%", tablet: "50%" },
            gap: { xs: "20px", mddl: "0px" },
            flexDirection: { xs: "column", md: "column", mddl: "row" },
          }}
        >
          <Box sx={{ width: "100%" }}>
            <Typography
              sx={{
                marginBottom: "4px",
                display: "flex",
                justifyContent: "space-between",
                width: "95%",
                fontSize: theme.typography.fontSize.sm,
                fontWeight: theme.typography.Bold,
              }}
            >
              <span>
                {i18n["myProfile.idPassportLabelOpt"]}{" "}
                <span
                  style={{
                    fontSize: theme.typography.fontSize.sm,
                    fontWeight: theme.typography.Regular,
                  }}
                >
                  (optional)
                </span>
              </span>
              {profileData?.id_proof_url !== "NO URL" && (
                <Link
                  to={`${profileData?.id_proof_url}`}
                  style={{
                    textDecoration: "none",
                    color: theme.palette.black,
                  }}
                  target="_blank"
                >
                  <span
                    style={{
                      display: "flex",
                      gap: "12px",
                      cursor: "pointer",
                      fontSize: "14px",
                    }}
                  >
                    preview
                    <Box
                      component="img"
                      className="eye"
                      alt="eye logo"
                      src={view}
                      sx={{
                        height: 20,
                        width: 20,
                      }}
                    />
                  </span>
                </Link>
              )}
            </Typography>
            <input
              accept={fileAccept}
              ref={hiddenIdProof}
              type="file"
              onChange={handleIDProofChange}
              style={{ display: "none" }}
              required
            />
            <Paper
              sx={{
                display: "flex",
                height: "38px",
                borderRadius: "10px",
                border: `1px solid ${theme.palette.grayBorder}`,
                width: "95%",
                boxShadow: "none",
              }}
            >
              <Paper
                elevation={0}
                component="form"
                sx={{
                  display: "flex",
                  borderRadius: "25px",
                  alignItems: "center",
                  width: 1,
                }}
              >
                <InputBase
                  sx={{
                    ml: 2,
                    width: 1,
                    fontSize: "14px",
                  }}
                  value={profileData?.idproof_number}
                  onChange={(event) =>
                    setProfileData({
                      ...profileData,
                      idproof_number: event?.target?.value,
                    })
                  }
                  placeholder={i18n["myProfile.enteryourIDnumber"]}
                />
              </Paper>
              <Button
                sx={{
                  ml: "4px",
                  minWidth: "fit-content",
                  boxShadow: 0,
                  borderRadius: "0 10px 10px 0",
                  height: "auto",
                  fontSize: "14px",
                  fontWeight: theme.typography.Bold,
                }}
                variant="contained"
                color="nandos"
                onClick={handleIdProofClick}
              >
                {i18n["myProfile.uploadId"]}
              </Button>
            </Paper>
            <Typography
              sx={{
                width: "94%",
                textAlign: "end",
                fontSize: "14px",
                fontWeight: theme?.typography?.Regular,
                ml: 1,
                mt: "4px",
              }}
            >
              {idProofName?.substring(idProofName?.indexOf("_") + 1)}
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: {
              xs: "baseline",
              tablet: "flex-start",
              mddl: "center",
            },
            mb: "20px",
            width: { xs: "100%", tablet: "100%", mddl: "68%" },
            gap: { xs: "20px", mddl: "0px" },
            flexDirection: { xs: "column", md: "column", mddl: "row" },
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography
              sx={{
                fontSize: "14px",
                fontWeight: theme?.typography?.Regular,
                width:"300px"
              }}
            >
              I require a work permit or visa to work where I live
            </Typography>
            <ToggleSwitch
              color="greenSwitch"
              onChange={(event) => handleChangeSetup(event, "work_permit_flag")}
              checked={profileData?.work_permit_flag}
            />
          </Box>
          <Box sx={{ width: { xs: "90%", tablet: "95.3%", mddl: "60.7%" } }}>
            <InputBox
              disabled={!profileData?.work_permit_flag}
              sx={{
                width: "100%",
                height: "40px",
                borderRadius: "10px",
                borderColor:
                  profileData?.work_permit_description === "" &&
                  errors?.find(
                    (error) => error.key === "work_permit_description"
                  ) &&
                  theme.jobCard.jobStatus.Rejected.main,
              }}
              placeholder={
                "If yes, please describe the nature of your current work permit or visa"
              }
              value={profileData?.work_permit_description}
              id="work_permit_description"
              onChange={handleInputChange}
            />
          </Box>
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: {
              xs: "baseline",
              tablet: "flex-start",
              mddl: "center",
            },
            mb: "20px",
            width: { xs: "100%", tablet: "100%", mddl: "68%" },
            gap: { xs: "20px", mddl: "0px" },
            flexDirection: { xs: "column", md: "column", mddl: "row" },
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography
              sx={{
                fontSize: "14px",
                fontWeight: theme?.typography?.Regular,
                width:"300px"
              }}
            >
              {i18n["myProfile.disability"]}
            </Typography>
            <ToggleSwitch
              color="greenSwitch"
              onChange={(event) => handleChangeSetup(event, "disability_flag")}
              checked={profileData?.disability_flag}
            />
          </Box>
          <Box sx={{ width: { xs: "90%", tablet: "95.3%", mddl: "60.7%" } }}>
            <CustomSelect
              isDisabled={!profileData?.disability_flag}
              onMenuOpen={() => {}}
              variant="multiSelect"
              onInputChange={handleOnSelectInputChange}
              onChange={handleOnSelectChange}
              placeholder={i18n["myProfile.specifyimpairment"]}
              inputId="disability_ids"
              name="disability_ids"
              filterOption={"filter"}
              value={profileData?.disability_ids}
              // isLoading={isLoading}
              customStyle={{
                menu: (provided) => ({
                  ...provided,
                  zIndex: 9999,
                }),
                menuList: (provided) => ({
                  ...provided,
                  "&::-webkit-scrollbar": {
                    width: "6px",
                    height: "6px",
                    borderRadius: "2.5px",
                    backgroundColor: "#efefef",
                  },
                  "&::-webkit-scrollbar-thumb": {
                    borderRadius: "2.5px",
                  },
                }),
                option: (
                  styles,
                  { data, isDisabled, isFocused, isSelected }
                ) => {
                  return {
                    ...styles,
                    color: isSelected ? "#fff" : "black",
                    ":active": {
                      ...styles[":active"],
                    },
                  };
                },
                control: (baseStyles) => ({
                  ...baseStyles,
                  boxShadow: "none",
                  borderRadius: "10px",
                  width: "100%",
                  border:
                    getLangValue()?.length === 0 &&
                    errors?.find((error) => error.key === "disability_ids")
                      ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
                      : "1px solid #E0E0E0",
                  "&:hover":
                    getLangValue()?.length === 0 &&
                    errors?.find((error) => error.key === "disability_ids")
                      ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
                      : "1px solid #E0E0E0",
                }),
              }}
              multiSelectOptions={disability?.map((item) => ({
                value: item?.disability_id,
                label: item?.name,
              }))}
            />
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: {
              xs: "baseline",
              tablet: "flex-start",
              mddl: "center",
            },
            mb: "20px",
            width: { xs: "100%", tablet: "100%", mddl: "68%" },
            gap: { xs: "20px", mddl: "0px" },
            flexDirection: { xs: "column", md: "column", mddl: "row" },
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography
              sx={{
                fontSize: "14px",
                fontWeight: theme?.typography?.Regular,
                width: "300px",
              }}
            >
              {i18n["myProfile.criminalrecord"]}
            </Typography>
            <ToggleSwitch
              color="greenSwitch"
              onChange={(event) => handleChangeSetup(event, "criminal_flag")}
              checked={profileData?.criminal_flag}
            />
          </Box>
          <Box sx={{ width: { xs: "90%", tablet: "95.3%", mddl: "60.7%" } }}>
            <InputBox
              disabled={profileData?.criminal_flag}
              sx={{
                width: "100%",
                height: "40px",
                borderRadius: "10px",
                borderColor:
                  profileData?.criminal_record === "" &&
                  errors?.find((error) => error.key === "criminal_record") &&
                  theme.jobCard.jobStatus.Rejected.main,
              }}
              placeholder={i18n["myProfile.providedetails"]}
              value={profileData?.criminal_record}
              id="criminal_record"
              onChange={handleInputChange}
            />
          </Box>
        </Box>
      </Box>
      {location?.pathname?.includes("promoter") ? (
        <Box sx={{ width: "100%", textAlign: "center" }}>
          <Button
            onClick={() => {
              onSaveProfile(profileData, false);
            }}
            variant="contained"
            sx={{
              backgroundColor: theme.palette.chart.darkGray,
              width: "150px",
              height: "57px",
              borderRadius: "10px 10px 0 0",
              color: "black",
              ":hover": {
                backgroundColor: theme.palette.chart.darkGray,
                boxShadow: "none",
              },
            }}
          >
            lock it in
          </Button>
        </Box>
      ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Button
            onClick={() => {
              onSaveProfile(profileData, false);
            }}
            variant="contained"
            sx={{
              backgroundColor: theme.palette.chart.darkGray,
              width: "150px",
              height: "57px",
              borderRadius: "10px 0 0 0",
              color: "black",
              ":hover": {
                backgroundColor: theme.palette.chart.darkGray,
                boxShadow: "none",
              },
            }}
          >
            lock it in
          </Button>

          <Button
            onClick={() => {
              onSaveProfile(profileData, true);
            }}
            variant="contained"
            color="nandos"
            sx={{
              width: "150px",
              height: "57px",
              borderRadius: "0 10px 0 0 ",
              ":hover": {
                boxShadow: "none",
              },
            }}
          >
            go to CV
          </Button>
        </Box>
      )}
    </>
  );
}
