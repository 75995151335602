import {
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import strongMatch from "../../assets/CrayBotIcons/Crayon Craybot Notification-07.svg";
import averageMatch from "../../assets/CrayBotIcons/Crayon Craybot Notification-09.svg";
import weakMatch from "../../assets/CrayBotIcons/Crayon Craybot Notification-05.svg";
import { useTheme } from "@emotion/react";
import MUIRadialChart from "../common/MUIRadialChart";
import close from "../../assets/Padding Included/Black_Close.svg";
import { Link } from "react-router-dom";
import viewIcon from "../../assets/Padding Excluded/Crayon Icon_Promote.svg";
import emailIcon from "../../assets/Padding Excluded/White_Email.svg";
import { useDispatch } from "react-redux";
import locale from "../../i18n/locale";
import {
  emailRegex,
  getDecodedToken,
  getToken,
  validateSchema,
} from "../../utils/Common";
import { LightTooltip } from "../../utils/CommonComponent";
import CrayonBountyInfo from "./CrayonBountyInfo";
import ShareInfo from "./ShareInfo";
import {
  getShareUrl,
  referAFriend,
  setReferal,
} from "../../redux/guest/talentSlice";
import { getJobDetail } from "../../redux/guest/jobsSlice";
import { setAlert } from "../../redux/configSlice";
import { ALERT_TYPE, ERROR_MSG } from "../../utils/Constants";
import Template from "./Template";
import { formatCurrencyWithCommas } from "../../utils/Currency";
import EmailInputBox from "./EmailInputBox";
import * as Yup from "yup";
import NewTemplate from "./NewTemplate";
import { dateConverterFullMonth3 } from "../../utils/DateTime";

export default function JobPromoteCard({
  setJobPromote,
  jobPromote,
  jobID,
  job,
  setShowLogin,
  height = "557.19px",
}) {
  const dispatch = useDispatch();
  const formData = new FormData();
  const i18n = locale.en;
  const theme = useTheme();
  const decodedToken = getDecodedToken(getToken());
  const [shareUrl, setShareUrl] = useState("");
  const [shareInfo, setShareInfo] = useState(false);
  const [src, setsrc] = useState(null);
  const [jobInfo, setJobInfo] = useState();
  const [showEmailBox, setShowEmailBox] = useState(false);
  const [email, setEmail] = useState("");
  const [errors, setErrors] = useState([]);
  const [showShareInfo, setShowShareInfo] = useState(false);
  const[isShowCard, setIsShowCard]= useState(false)
  const [dynamicText, setDynamicText] = useState(
    `${window.location.origin}/job-detail/${job?.country_name?.replace(
      /\s|\//g,
      ""
    )}/${job?.town_name?.replace(/\s|\//g, "")}/${job?.title?.replace(
      /\s|\//g,
      ""
    )}/${job?.job_id}`
  );

  const handleShare = () => {
    if(isShowCard === false){
      if (decodedToken === undefined) {
        setShowLogin(true);
      } else {
        setIsShowCard(true)
        setShowShareInfo(true);
        setShareInfo(true);
      }
    }
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .matches(emailRegex, "Invalid email address") // Use custom regex for email validation
      .required("Email is Required."),
  });

  const handleReferFriend = () => {
    let testErrorState = {
      email: email,
    };
    validateSchema(validationSchema, testErrorState)
      .then(() => {
        const { payload } = dispatch(
          referAFriend({ email: email, job_id: job?.job_id })
        ).then((payload) => {
          if (payload?.payload?.status === "success") {
            dispatch(
              setAlert({
                show: true,
                type: ALERT_TYPE?.SUCCESS,
                msg: "Email sent successfully!",
              })
            );
            setShowEmailBox(false);
          }
          if (payload?.payload?.status === "error") {
            dispatch(
              setAlert({
                show: true,
                type: ALERT_TYPE?.ERROR,
                msg: "Error",
              })
            );
          }
        });
      })
      .catch((error) => {
        setErrors(
          Object.keys(error)?.map((item) => ({
            message: error[item],
            key: item,
          }))
        );
      });
  };
  const getUrl = async (id) => {
    setIsShowCard(true)
    try {
      let response = await dispatch(getShareUrl(id));
      if (response?.payload?.status === "success") {
        setShareUrl(response?.payload);
      }
      setIsShowCard(false)
    } catch (error) {
      setIsShowCard(false)
    }
  };

  const applyFunction = async (id) => {
    let payload = {
      job_id: jobID,
      platform_type: "facebook",
    };
    try {
      // let response = await dispatch(setReferal(payload));
      // const htmlBody = response?.payload?.meta_url;
      // const linkText = "Click here to view the job details";

      const subject = "Crayon – Referral request";
      const encodedEmailBody = encodeURIComponent(shareUrl?.shared_url);
      const mailtoLink = `mailto:?subject=${encodeURIComponent(
        subject
      )}&body=${encodedEmailBody}`;
      window.location.href = mailtoLink;
    } catch (error) {}
  };

  const handleCardClick = async () => {
    try {
      const { payload } = await dispatch(
        getJobDetail({
          job_id: jobID,
          user_id: "",
          decodedToken: decodedToken,
        })
      );
      if (payload?.status === "success") {
        setJobInfo(payload?.data);
      }
    } catch (error) {
      dispatch(
        setAlert({
          show: true,
          type: ALERT_TYPE?.ERROR,
          msg: ERROR_MSG,
        })
      );
    }
  };

  const handleShareBtn = async () => {
    if (jobID && decodedToken !== undefined) {
      await getUrl(jobID);
    }
    await handleShare();
  };

  // useEffect(() => {
  //   if (jobID && decodedToken !== undefined && src) {
  //     getUrl(jobID);
  //   }
  // }, [src, jobID]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: height,
        width: "100%",
        justifyContent: "space-between",
        position:"relative"
      }}
    >
      {!shareInfo && (
        <CrayonBountyInfo
          setJobPromote={setJobPromote}
          job={job}
          handleShare={handleShare}
          setShowLogin={setShowLogin}
          setShowEmailBox={setShowEmailBox}
          shareInfo={shareInfo}
          setSrc={setsrc}
          setIsShowCard={setIsShowCard}
        />
      )}
      {showShareInfo && (
        <ShareInfo
          setShareInfo={setShareInfo}
          setShowShareInfo={setShowShareInfo}
          setJobPromote={setJobPromote}
          jobID={jobID}
          shareUrl={shareUrl}
          setShowLogin={setShowLogin}
          setShowEmailBox={setShowEmailBox}
          shareInfo={shareInfo}
          job={job}
          setSrc={setsrc}
          getUrl={getUrl}
          setShareUrl={setShareUrl}
          isShowCard={isShowCard}
        />
      )}
      {showEmailBox && (
        <EmailInputBox
          openEdit={showEmailBox}
          handleClose={() => setShowEmailBox(false)}
          handleRefer={handleReferFriend}
          setEmail={setEmail}
          errors={errors}
          setErrors={setErrors}
        />
      )}
      {shareInfo && job?.job_image === null && (
        <NewTemplate
          jobID={job?.job_id}
          title={job?.title}
          location={`${job?.town_name}, ${job?.country_name}`}
          companyLogo={job?.companyURL}
          salary={`${job?.currency_symbol}${formatCurrencyWithCommas(
            job?.salary_min
          )} to ${job?.currency_symbol}${formatCurrencyWithCommas(
            job?.salary_max
          )} ${job?.type === "freelance" ? "per hour" : "per month"}`}
          // experience={`${
          //   job?.experience_year_start === 0
          //     ? `${job?.experience_year_end} years`
          //     : `${job?.experience_year_start} to ${job?.experience_year_end} years`
          // }`}
          experience={`${job?.experience_year_start} to ${job?.experience_year_end} years`}
          primaryPersonality={job?.primary_name}
          shadowPersonality={job?.shadow_name}
          startDate={dateConverterFullMonth3(job?.job_start_date)}
          handleApprove={() => {}}
          formData={formData}
          statusName={"active"}
          setShowShareInfo={setShowShareInfo}
          getUrl={getUrl}
          setIsShowCard={setIsShowCard}
        />
      )}
      {
        isShowCard &&  <Box sx={{position:"absolute",zIndex:"999999999", top:"40%", left:"40%", transform:"translate(-50%,-50%"}}><div className="loader"></div></Box>
      }
    </Box>
  );
}
