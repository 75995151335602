import { Box, Button, Typography, useTheme } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import Chat from "../../assets/TalentMenu/Crayon_Talent_Menu_Chat.svg";
import Search from "../../assets/TalentMenu/Crayon_Talent_Menu_Search.svg";
import Dashboard from "../../assets/TalentMenu/Crayon_Talent_Menu_Dashboard.svg";
import Maintenance from "../../assets/TalentMenu/Crayon_Talent_Menu_Maintenance.svg";
import Users from "../../assets/TalentMenu/User_Databases.svg";
import Talent from "../../assets/TalentMenu/Crayon_Talent_Menu_Talent.svg";
import Jobs from "../../assets/TalentMenu/Crayon_Talent_Menu_Jobs.svg";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import downArrow from "../../assets/Black_Down_Open - Copy.svg";
import upArrow from "../../assets/Black_Up_Close - Copy.svg";
import {
  getClientPools,
  getLeftDrawerClientPool,
  getLeftDrawerTalentPool,
  getTalentPool,
} from "../../redux/admin/jobsSlice";
import { useDispatch } from "react-redux";
import {
  JOBS,
  MAINTENANCE,
  SEARCH,
  TALENT,
  USERS,
  USER_ROLES,
} from "../../utils/Constants";
import { getClientSearch, getSaveSearch } from "../../redux/admin/searchSlice";
import { useSelector } from "react-redux";
import { getDecodedToken, getToken } from "../../utils/Common";
import SubscriptionPopModal from "../guest/talent/SubscriptionPopModal";

export default function LeftMenu({
  leftExpanded,
  isSubscriptionModal,
  setIsSubscriptionModal = () => {},
  onMouseEnter,
}) {
  const dispatch = useDispatch();
  const { searchSubList, clientSearchList } = useSelector(
    (state) => state?.searchSlice
  );
  const { leftDrawerTalentPool, leftDrawerClientPool } = useSelector(
    (state) => state?.adminSlice
  );
  const theme = useTheme();
  const navigate = useNavigate();
  const { poolId, id, searchID } = useParams();
  const poolRef = useRef();
  const searchRef = useRef();
  const params = useLocation();
  const decodedToken = getDecodedToken(getToken());
  const planType = decodedToken?.data?.talent_plan;
  // const [isSubscriptionModal, setIsSubscriptionModal] = useState(false);

  const [open, setOpen] = useState(TALENT.map((item) => false));
  const [poolData, setPoolData] = useState([]);
  const [clientPoolData, setClientPoolData] = useState([]);
  const [clientPoolLastKey, setClientPoolLastKey] = useState("");
  const [totalClientPoolData, setTotalClientPoolData] = useState();
  const [talentList, setTalentList] = useState(
    decodedToken?.data?.role_id === USER_ROLES?.[0]?.role_id ||
      decodedToken?.data?.role_id === USER_ROLES?.[1]?.role_id ||
      decodedToken?.data?.role_id === USER_ROLES?.[6]?.role_id
      ? TALENT?.filter((item) => item.id !== 1)
      : TALENT?.filter((item) => item.id !== 3)
  );
  const [selectedTab, setSelectedTab] = useState("");
  const [talentPoolLastKey, setTalentPoolLastKey] = useState("");
  const [totalPoolData, setTotalPoolData] = useState();
  const [searchList, setSearchList] = useState(
    decodedToken?.data?.role_id === USER_ROLES?.[0]?.role_id ||
      decodedToken?.data?.role_id === USER_ROLES?.[1]?.role_id ||
      decodedToken?.data?.role_id === USER_ROLES?.[6]?.role_id
      ? SEARCH
      : SEARCH?.filter((item) => item?.id !== 2)
  );
  const [searchOpen, setSearchOpen] = useState(
    searchList?.map((item) => false)
  );

  const getTalentPoolList = async (lastkeyy) => {
    try {
      const { payload } = await dispatch(
        getLeftDrawerTalentPool({ lastKey: 0 })
      );
      if (payload?.status === "success") {
      }
    } catch (error) {}
  };

  const getClientPoolList = async (lastkeyy) => {
    try {
      const { payload } = await dispatch(
        getLeftDrawerClientPool({ lastKey: 0 })
      );
      if (payload?.status === "success") {
      }
    } catch (error) {}
  };

  const getSearchMenuData = async () => {
    try {
      let response = await dispatch(getSaveSearch());
    } catch (error) {}
  };

  const getClientSearchData = async () => {
    try {
      let response = await dispatch(getClientSearch());
    } catch (error) {}
  };

  const getSelectedTab = () => {
    const pathname = params?.pathname?.split("/");
    let name;
    if (pathname?.includes("adminJobs")) {
      name = JOBS?.find(
        (item) => item?.path === pathname[pathname?.length - 1]
      )?.label;
    } else if (pathname?.includes("admin-talent")) {
      if (poolId) {
        pathname[pathname?.length - 2] === "client-pool"
          ? (name = clientPoolData?.find(
              (item) => item?.pool_id == poolId
            )?.title)
          : (name = poolData?.find((item) => item?.pool_id == poolId)?.title);
        setOpen(
          open?.map((item, itemindex) => {
            if (
              pathname[pathname?.length - 2] === "client-pool" &&
              itemindex === 1
            ) {
              return true;
            } else if (
              pathname[pathname?.length - 2] === "talent-pool" &&
              itemindex === 2
            ) {
              return true;
            }
            return false;
          })
        );
        // setSelectedTab(name);
      } else if (
        pathname?.includes("applicants") ||
        pathname?.includes("followers")
      ) {
        setOpen(
          open?.map((item, itemindex) => (itemindex === 1 ? true : false))
        );
        let mytalent = TALENT?.find(
          (item) => item?.path === pathname[pathname?.length - 2]
        );
        name = mytalent?.submenu?.find(
          (item) => item?.path === pathname[pathname?.length - 1]
        )?.title;
      } else {
        name = TALENT?.find((item) =>
          pathname?.includes("candidate-cv")
            ? item?.path === pathname[pathname?.length - 3]
            : item?.path === pathname[pathname?.length - 1]
        )?.label;
      }
    } else if (pathname?.includes("search")) {
      if (searchID && pathname?.includes("my-search")) {
        setSearchOpen(
          searchList?.map((item, itemindex) => (itemindex === 1 ? true : false))
        );
        name = searchList
          ?.find((item) => {
            return item?.path === "my-search";
          })
          ?.mySearch?.find((item) => item?.search_id == searchID)?.name;
      } else if (searchID && pathname?.includes("client-search")) {
        setSearchOpen(
          searchList?.map((item, itemindex) => (itemindex === 2 ? true : false))
        );
        name = searchList
          ?.find((item) => {
            return item?.path === "client-search";
          })
          ?.mySearch?.find((item) => item?.search_id == searchID)?.name;
      } else {
        name = SEARCH?.find(
          (item) => item?.path === pathname[pathname?.length - 1]
        )?.label;
      }
    } else if (pathname?.includes("maintenance")) {
      if (
        pathname?.includes("vouchers") ||
        pathname?.includes("coins-history") ||
        pathname?.includes("payment-history") ||
        pathname?.includes("solutions")|| 
        pathname?.includes("policyPrivacy")
      ) {
        name = MAINTENANCE?.find(
          (item) => item?.path === pathname?.[pathname?.length - 1]
        )?.path;
      } else {
        name = MAINTENANCE?.[0]?.databaseList?.find(
          (item) => item?.path === pathname?.[pathname?.length - 1]
        )?.path;
      }
    } else if (pathname?.includes("users")) {
      if (pathname[pathname?.length - 1] === "user-crayon-team") {
        name = USERS?.[4]?.path;
      } else if (pathname?.includes("user-crayon-team")) {
        name = USERS?.[4]?.team?.find(
          (item) => item?.path === pathname[pathname?.length - 1]
        )?.path;
      } else {
        name = USERS?.find(
          (item) =>
            item?.path ===
            pathname[id ? pathname?.length - 2 : pathname?.length - 1]
        )?.path;
      }
    }
    setSelectedTab(name);
  };

  useEffect(() => {
    getTalentPoolList(0);
    if (
      decodedToken?.data?.role_id === 1 ||
      decodedToken?.data?.role_id === 2 ||
      decodedToken?.data?.role_id === 8
    ) {
      getClientPoolList(0);
    }
  }, []);

  useEffect(() => {
    getSearchMenuData();
    if (
      decodedToken?.data?.role_id === 1 ||
      decodedToken?.data?.role_id === 2 ||
      decodedToken?.data?.role_id === 8
    ) {
      getClientSearchData();
    }
  }, []);

  useEffect(() => {
    if (poolData?.length > 0 || clientPoolData?.length > 0) {
      getSelectedTab();
    }
  }, [poolData, params, clientPoolData]);

  useEffect(() => {
    poolRef.current = leftDrawerTalentPool?.data;
    setPoolData(leftDrawerTalentPool?.data ? leftDrawerTalentPool?.data : []);
    setTalentPoolLastKey(leftDrawerTalentPool?.pageNumber + 1);
    setTotalPoolData(leftDrawerTalentPool?.poolCount);
    setClientPoolData(
      leftDrawerClientPool?.data ? leftDrawerClientPool?.data : []
    );
    setClientPoolLastKey(leftDrawerClientPool?.pageNumber + 1);
    setTotalClientPoolData(leftDrawerClientPool?.poolCount);
    const talentPoolIndex = talentList?.findIndex((item) => item.id === 4);
    const clientPoolIndex = talentList?.findIndex((item) => item.id === 3);
    const updatedTalentList = [...talentList];
    updatedTalentList[talentPoolIndex] = {
      ...updatedTalentList[talentPoolIndex],
      submenu:
        poolData?.length < leftDrawerTalentPool?.poolCount
          ? [...poolData, ...leftDrawerTalentPool?.data]
          : leftDrawerTalentPool?.data,
    };

    updatedTalentList[clientPoolIndex] = {
      ...updatedTalentList[clientPoolIndex],
      submenu:
        poolData?.length < leftDrawerClientPool?.poolCount
          ? [...poolData, ...leftDrawerClientPool?.data]
          : leftDrawerClientPool?.data,
    };
    setTalentList(updatedTalentList);
  }, [leftDrawerTalentPool, leftDrawerClientPool]);

  // useEffect(() => {
  //   setSearchOpen(searchList?.map((item) => false));
  // }, []);

  const handleClickOpen = (name, index, path) => {
    if (!planType) {
      setIsSubscriptionModal(true);
      return;
    }
    scrollToTop();
    setSelectedTab(name);
    setOpen(
      open?.map((item, itemindex) => (itemindex === index ? !item : false))
    );
    if (path) {
      navigate(`/admin/admin-talent/${path}`, { replace: false });
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const handleSubClick = (
    name,
    event,
    parentPath,
    childPath,
    subIndex,
    poolID
  ) => {
    if (childPath === undefined) {
      return;
    }
    if (!planType) {
      setIsSubscriptionModal(true);
      return;
    }
    scrollToTop();
    if (parentPath === "maintenance") {
      setSelectedTab(childPath);
    } else if (parentPath === "users/user-crayon-team") {
      setSelectedTab(childPath);
    } else if (parentPath === "users") {
      setSelectedTab(childPath);
    } else {
      setSelectedTab(name);
    }
    if (poolID && parentPath === "admin-talent") {
      navigate(`/admin/${parentPath}/${childPath}/${poolID}`, {
        replace: false,
      });
      return;
    } else if (childPath === "applicants" || childPath === "followers") {
      navigate(`/admin/${parentPath}/my-talent/${childPath}`, {
        replace: false,
      });
      return;
    } else if (parentPath === "my-search") {
      navigate(`/admin/search/my-search/${poolID}`);
      return;
    } else if (parentPath === "client-search") {
      navigate(`/admin/search/client-search/${poolID}`);
      return;
    }
    if (parentPath === "search" && subIndex && !childPath) {
      navigate(`/admin/${parentPath}/build-search/${subIndex}`, {
        replace: false,
      });
      return;
    } else if (
      childPath === "my-talent" &&
      (name === "Applicants" || name === "Followers")
    ) {
      navigate(`/admin/${parentPath}/my-talent/${name}`, {
        replace: false,
      });
      return;
    } else {
      navigate(`/admin/${parentPath}/${childPath}`, { replace: false });
      return;
    }
  };

  const handleSearchClick = (name, index) => {
    if (!planType) {
      setIsSubscriptionModal(true);
      return;
    }
    scrollToTop();
    setSelectedTab(name);
    setSearchOpen(
      searchOpen?.map((item, itemindex) =>
        itemindex === index ? !item : false
      )
    );
  };
  useEffect(() => {
    // searchRef.current = searchSubList;
    const talentPoolIndex = searchList?.findIndex((item) => item?.id === 1);
    const clientSearchIndex = searchList?.findIndex((item) => item?.id === 2);
    const updatedTalentList = [...searchList];
    updatedTalentList[talentPoolIndex] = {
      ...updatedTalentList[talentPoolIndex],
      mySearch: searchSubList?.length > 0 ? [...searchSubList] : [],
    };
    updatedTalentList[clientSearchIndex] = {
      ...updatedTalentList[clientSearchIndex],
      mySearch: clientSearchList?.length > 0 ? [...clientSearchList] : [],
    };
    setSearchList(updatedTalentList);
  }, [searchSubList, clientSearchList]);
  return (
    <>
      <Box
        sx={{
          width: leftExpanded ? "250px" : "80px",
          backgroundColor: "#f2f4fb",
          borderRadius: "0",
          display: "flex",
          flexDirection: "column",
          alignItems: leftExpanded ? "flex-start" : "center",
          cursor: "pointer",
          height: {
            xs: `calc(100vh - 80px)`,
            lg: `calc(100vh - 80px)`,
            mlg: `calc(100vh - 80px)`,
            xl: `calc(100vh - 100px)`,
            xxl: `calc(100vh - 120px)`,
          },
          boxShadow: `0px 5px 10px ${theme?.palette?.boxShadow?.main}`,
          overflow: "scroll",
        }}
        className="filterSec"
      >
        <Box sx={{ display: "flex", marginTop: "55px" }}>
          <Box
            component={"img"}
            src={Dashboard}
            sx={{
              height: "60px",
              width: "60px",
            }}
          />
          {leftExpanded && (
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography
                sx={{
                  fontSize: "20px",
                  fontWeight: 700,
                }}
              >
                Dashboard
              </Typography>
              <Typography
                sx={{
                  fontSize: "16px",
                  fontWeight: 700,
                }}
              >
                The stats and facts
              </Typography>
              <Typography
                sx={{
                  fontSize: "15px",
                  fontWeight: 500,
                }}
              >
                Item 1
              </Typography>
              <Typography
                sx={{
                  fontSize: "15px",
                  fontWeight: 500,
                }}
              >
                Item 2
              </Typography>
              <Typography
                sx={{
                  fontSize: "15px",
                  fontWeight: 500,
                }}
              >
                Item 3
              </Typography>
            </Box>
          )}
        </Box>

        <Box sx={{ display: "flex", marginTop: "45px" }}>
          <Box
            component={"img"}
            src={Jobs}
            sx={{
              height: "60px",
              width: "60px",
            }}
          />
          {leftExpanded && (
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography
                sx={{
                  fontSize: "20px",
                  fontWeight: 700,
                }}
              >
                Jobs
              </Typography>
              <Typography
                sx={{
                  fontSize: "16px",
                  fontWeight: 700,
                }}
              >
                Manage your jobs
              </Typography>
              {JOBS?.map((item) => {
                return (
                  <Typography
                    sx={{
                      fontSize: "15px",
                      fontWeight: item?.label === selectedTab ? "bold" : 500,
                    }}
                    onClick={(event) =>
                      handleSubClick(
                        item?.label,
                        event,
                        "adminJobs",
                        item?.path
                      )
                    }
                  >
                    {item?.label}
                  </Typography>
                );
              })}
            </Box>
          )}
        </Box>

        <Box
          sx={{
            display: "flex",
            marginTop: "45px",
            justifyContent: "center",
            width: leftExpanded ? "100%" : "80px",
          }}
        >
          <Box
            component={"img"}
            src={Talent}
            sx={{
              height: "60px",
              width: "60px",
            }}
          />
          {leftExpanded && (
            <Box
              sx={{ display: "flex", flexDirection: "column", width: "100%" }}
            >
              <Typography
                sx={{
                  fontSize: "20px",
                  fontWeight: 700,
                }}
              >
                Talent
              </Typography>
              <Typography
                sx={{
                  fontSize: "16px",
                  fontWeight: 700,
                }}
              >
                Manage your talent
              </Typography>
              {talentList?.map((subMenuItem, subIndex) => (
                <List disablePadding={true}>
                  <ListItemButton
                    key={subIndex}
                    sx={{
                      padding: "0px !important",
                    }}
                    onClick={(event) => {
                      subMenuItem?.submenu &&
                        handleClickOpen(
                          subMenuItem?.label,
                          subIndex,
                          subMenuItem?.path
                        );
                    }}
                  >
                    <ListItemText
                      sx={{
                        margin: "0px !important",
                        "& .MuiTypography-root": {
                          fontSize: "15px",
                          fontWeight:
                            subMenuItem?.label === selectedTab ? "bold" : 500,
                        },
                      }}
                      primary={subMenuItem?.label}
                      onClick={(event) => {
                        subMenuItem?.submenu && handleClickOpen(subIndex);
                        subMenuItem?.submenu?.length <= 0 &&
                          handleSubClick(
                            subMenuItem?.label,
                            event,
                            "admin-talent",
                            subMenuItem?.path,
                            subIndex
                          );
                      }}
                    />
                    {subMenuItem?.submenu?.length ? (
                      open?.[subIndex] ? (
                        <Box
                          component={"img"}
                          src={upArrow}
                          sx={{
                            height: "28px",
                            width: "30px",
                          }}
                        />
                      ) : (
                        <Box
                          component={"img"}
                          src={downArrow}
                          sx={{
                            height: "28px",
                            width: "30px",
                          }}
                        />
                      )
                    ) : null}
                  </ListItemButton>
                  <Collapse in={open[subIndex]} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                      {subMenuItem?.submenu?.map((item, index) => (
                        <ListItemButton
                          sx={{ padding: "0 0 0 24px!important" }}
                        >
                          <ListItemText
                            sx={{
                              margin: "0px !important",
                              "& .MuiTypography-root": {
                                fontSize: "15px",
                                fontWeight:
                                  item?.title === selectedTab ? "bold" : 500,
                              },
                            }}
                            key={index}
                            primary={item?.title}
                            onClick={(event) =>
                              handleSubClick(
                                item?.title,
                                event,
                                "admin-talent",
                                subMenuItem?.path,
                                index,
                                item?.pool_id
                              )
                            }
                          />
                        </ListItemButton>
                      ))}
                    </List>
                    {/* {open[2] && subMenuItem?.submenu?.length < totalPoolData && (
                    <Button
                      sx={{
                        padding: "0 0 0 24px!important",
                        color: "black",
                        fontSize: "15px",
                        fontWeight: 500,
                        ":hover": {
                          backgroundColor: "#f2f4fb",
                          boxShadow: "none",
                        },
                      }}
                      onClick={() => getTalentPoolList(talentPoolLastKey)}
                    >
                      {poolData?.length > 0 ? "more +" : ""}
                    </Button>
                  )} */}
                  </Collapse>
                </List>
              ))}
            </Box>
          )}
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            marginTop: "45px",
            width: leftExpanded ? "100%" : "80px",
          }}
        >
          <Box
            component={"img"}
            src={Search}
            sx={{
              height: "60px",
              width: "60px",
            }}
          />
          {leftExpanded && (
            <Box
              sx={{ display: "flex", flexDirection: "column", width: "100%" }}
            >
              <Typography
                sx={{
                  fontSize: "20px",
                  fontWeight: 700,
                }}
              >
                Search
              </Typography>
              <Typography
                sx={{
                  fontSize: "16px",
                  fontWeight: 700,
                }}
              >
                Find talent, fast!
              </Typography>
              {searchList?.map((item, itemIndex) => {
                return (
                  <List disablePadding={true}>
                    <ListItemButton
                      key={itemIndex}
                      sx={{
                        padding: "0px !important",
                      }}
                      onClick={() => {
                        item?.mySearch &&
                          handleSearchClick(item?.label, itemIndex);
                      }}
                    >
                      <ListItemText
                        sx={{
                          margin: "0px !important",
                          "& .MuiTypography-root": {
                            fontSize: "15px",
                            fontWeight:
                              item?.label === selectedTab ? "bold" : 500,
                          },
                        }}
                        primary={item?.label}
                        onClick={(event) => {
                          handleSubClick(
                            item?.label,
                            event,
                            "search",
                            item?.path,
                            itemIndex
                          );
                        }}
                      />
                      {item?.mySearch?.length ? (
                        searchOpen[itemIndex] ? (
                          <Box
                            component={"img"}
                            src={upArrow}
                            sx={{
                              height: "28px",
                              width: "30px",
                            }}
                          />
                        ) : (
                          <Box
                            component={"img"}
                            src={downArrow}
                            sx={{
                              height: "28px",
                              width: "30px",
                            }}
                          />
                        )
                      ) : null}
                    </ListItemButton>
                    <Collapse
                      in={searchOpen?.[itemIndex]}
                      timeout="auto"
                      unmountOnExit
                    >
                      <List component="div" disablePadding>
                        {item?.mySearch?.map((i, index) => (
                          <ListItemButton
                            sx={{ padding: "0 0 0 24px!important" }}
                          >
                            <ListItemText
                              sx={{
                                margin: "0px !important",
                                "& .MuiTypography-root": {
                                  fontSize: "15px",
                                  fontWeight:
                                    i?.name === selectedTab ? "bold" : 500,
                                },
                              }}
                              key={index}
                              primary={i?.name}
                              onClick={(event) => {
                                handleSubClick(
                                  i?.name,
                                  event,
                                  // "my-search",
                                  item?.path,
                                  i?.path,
                                  index,
                                  i?.search_id
                                );
                              }}
                            />
                          </ListItemButton>
                        ))}
                      </List>
                    </Collapse>
                  </List>
                );
              })}
            </Box>
          )}
        </Box>

        <Box sx={{ display: "flex", marginTop: "45px" }}>
          <Box
            component={"img"}
            src={Chat}
            sx={{
              height: "60px",
              width: "60px",
            }}
          />
          {leftExpanded && (
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography
                sx={{
                  fontSize: "20px",
                  fontWeight: 700,
                }}
              >
                Chat
              </Typography>
              <Typography
                sx={{
                  fontSize: "16px",
                  fontWeight: 700,
                }}
              >
                Who said what
              </Typography>
            </Box>
          )}
        </Box>
        {decodedToken?.data?.role_id !== 4 &&
          decodedToken?.data?.role_id !== 6 &&
          decodedToken?.data?.role_id !== 8 && (
            <Box sx={{ display: "flex", marginTop: "45px" }}>
              <Box
                component={"img"}
                src={Users}
                sx={{
                  height: "60px",
                  width: "60px",
                }}
              />
              {leftExpanded && (
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  <Typography
                    sx={{
                      fontSize: "20px",
                      fontWeight: 700,
                    }}
                  >
                    Users
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "16px",
                      fontWeight: 700,
                    }}
                  >
                    Crayon clients
                  </Typography>
                  {(decodedToken?.data?.role_id === 2
                    ? USERS?.filter((item) => item?.label !== "Crayon Team")
                    : USERS
                  )?.map((user, itemIndex) => {
                    return (
                      <>
                        <List disablePadding={true}>
                          <ListItemButton
                            key={itemIndex}
                            sx={{ padding: "0px !important" }}
                          >
                            <ListItemText
                              sx={{
                                margin: "0px !important",
                                "& .MuiTypography-root": {
                                  fontSize: "15px",
                                  fontWeight:
                                    user?.path === selectedTab ? "bold" : 500,
                                },
                              }}
                              primary={user?.label}
                              onClick={(event) =>
                                handleSubClick(
                                  user?.label,
                                  event,
                                  "users",
                                  user?.path,
                                  itemIndex
                                )
                              }
                            />
                          </ListItemButton>

                          <List component="div" disablePadding>
                            {user?.team?.map((item, index) => (
                              <ListItemButton
                                sx={{ padding: "0 0 0 24px!important" }}
                              >
                                <ListItemText
                                  sx={{
                                    margin: "0px !important",
                                    "& .MuiTypography-root": {
                                      fontSize: "15px",
                                      fontWeight:
                                        item?.path === selectedTab
                                          ? "bold"
                                          : 500,
                                    },
                                  }}
                                  key={index}
                                  primary={item?.label}
                                  onClick={(event) =>
                                    handleSubClick(
                                      item?.label,
                                      event,
                                      "users/user-crayon-team",
                                      item?.path,
                                      index
                                    )
                                  }
                                />
                              </ListItemButton>
                            ))}
                          </List>
                        </List>
                      </>
                    );
                  })}
                </Box>
              )}
            </Box>
          )}

        {decodedToken?.data?.role_id !== 4 &&
          decodedToken?.data?.role_id !== 6 &&
          decodedToken?.data?.role_id !== 8 && (
            <Box sx={{ display: "flex", marginTop: "45px" }}>
              <Box
                component={"img"}
                src={Maintenance}
                sx={{
                  height: "60px",
                  width: "60px",
                }}
              />
              {leftExpanded && (
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  <Typography
                    sx={{
                      fontSize: "20px",
                      fontWeight: 700,
                    }}
                  >
                    Maintenance
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "16px",
                      fontWeight: 700,
                    }}
                  >
                    The nuts and bolts
                  </Typography>
                  {MAINTENANCE?.map((item, itemIndex) => {
                    return (
                      <>
                        <List disablePadding={true}>
                          <ListItemButton
                            key={itemIndex}
                            sx={{
                              padding: "0px !important",
                            }}
                            onClick={(event) =>
                              handleSubClick(
                                item?.label,
                                event,
                                "maintenance",
                                item?.path,
                                itemIndex
                              )
                            }
                          >
                            <ListItemText
                              sx={{
                                margin: "0px !important",
                                "& .MuiTypography-root": {
                                  fontSize: "15px",
                                  fontWeight:
                                    item?.path === selectedTab ? "bold" : 500,
                                },
                              }}
                              primary={item?.label}
                            />
                          </ListItemButton>
                          <List component="div" disablePadding>
                            {item?.databaseList?.map((item, index) => (
                              <ListItemButton
                                sx={{ padding: "0 0 0 24px!important" }}
                              >
                                <ListItemText
                                  sx={{
                                    margin: "0px !important",
                                    "& .MuiTypography-root": {
                                      fontSize: "15px",
                                      fontWeight:
                                        item?.path === selectedTab
                                          ? "bold"
                                          : 500,
                                    },
                                  }}
                                  key={index}
                                  primary={item?.label}
                                  onClick={(event) =>
                                    handleSubClick(
                                      item?.label,
                                      event,
                                      "maintenance",
                                      item?.path,
                                      index
                                    )
                                  }
                                />
                              </ListItemButton>
                            ))}
                          </List>
                        </List>
                      </>
                    );
                  })}
                </Box>
              )}
            </Box>
          )}
      </Box>
    </>
  );
}
