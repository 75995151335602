import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import RecordingInProgress from "./dialog/RecordingInProgress";
import AddVideo from "./dialog/AddVideo";
import CounterDialog from "./dialog/CounterDialog";
import PublishVideo from "./dialog/PublishVideo";
import { Box } from "@mui/material";

import jwt_decode from "jwt-decode";
import { useDispatch } from "react-redux";
import {
  getMyCamVideo,
  uploadMyCamVideo,
} from "../../../redux/candidate/candidateJobs";
import { setAlert, setLoading } from "../../../redux/configSlice";
import { getProfileProgressData } from "../../../redux/candidate/myProfileSlice";
import { ALERT_TYPE } from "../../../utils/Constants";
import { getVideoFileDuration } from "../../../utils/Common";

const VideoAddSteps = ({
  handleStopRecording,
  handleCloseCamera,
  setMediaStream,
  mediaStream,
  mediaRecorderRef,
  step,
  setStep,
  userData,
  setUserData,
  recordedVideoData,
  setRecordedVideoData,
  setActiveStep,
}) => {
  const dispatch = useDispatch();
  // const [step, setStep] = useState(1);
  // const [recordedVideoData, setRecordedVideoData] = useState(null);
  // const [userData, setUserData] = useState();

  const [videoBlob, setVideoBlob] = useState("");
  const [textChange, setTextChange] = useState(
    recordedVideoData ? true : false
  );

  const token = localStorage?.getItem("token");
  let decodedToken;
  if (token) {
    decodedToken = jwt_decode(token);
  }
  const getVideo = async () => {
    // setLoading(true);
    const { payload } = await dispatch(getMyCamVideo({ user_id: "" }));
    if (payload?.status === "success") {
      if (payload?.data?.cam_url !== "NO URL") {
        // setVideoLink(payload?.data?.cam_url);
        setRecordedVideoData(payload?.data?.cam_url);
        setUserData(payload?.data?.user);
        setStep(4);
        await dispatch(getProfileProgressData());
      }
    } else if (payload === undefined) {
      return;
    }
    // setLoading(false);
  };
    // const getVideo = async () => {
  //   setLoading(true);
  //   const { payload } = await dispatch(getEmployerMyCamVideo());
  //   if (payload?.status === "success") {
  //     if (
  //       payload?.data?.length === 0 ||
  //       (payload?.data?.length > 0 &&
  //         payload?.data?.filter((item) => item?.default)?.[0]?.video_url ===
  //           "No URL")
  //     ) {
  //       setIsVideoUploaded(false);
  //     } else {
  //       setIsVideoUploaded(true);
  //     }
  //   } else {
  //     dispatch(
  //       setAlert({
  //         show: true,
  //         type: ALERT_TYPE?.ERROR,
  //         msg: "No video",
  //       })
  //     );
  //   }
  //   setLoading(false);
  // };

  const uploadVideo = async () => {
    dispatch(setLoading(true));
    const myFile = new File([videoBlob], "videoFile.webm", {
      type: videoBlob?.type,
    });
    getVideoFileDuration(myFile, async (error, videoDuration, fileType) => {
      if (error) {
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE?.ERROR,
            msg: error,
          })
        );
        return;
      } else if (myFile?.size > 50000000) {
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE?.ERROR,
            msg: "Video size should be less than 50MB",
          })
        );
        return;
      }

      const formData = new FormData();
      formData?.append("cam", myFile);
      try {
        const { payload } = await dispatch(uploadMyCamVideo(formData));
        if (payload?.status === "success") {
          dispatch(
            setAlert({
              show: true,
              type: ALERT_TYPE.SUCCESS,
              msg: "Video uploaded Successfully!",
            })
          );
          setTextChange(true);
          await getVideo();
          dispatch(setLoading(false));
        } else {
          dispatch(setLoading(false));
          dispatch(
            setAlert({
              show: true,
              type: ALERT_TYPE.ERROR,
              msg: "Failed to upload Video",
            })
          );
        }
        dispatch(setLoading(false));
      } catch (error) {
        dispatch(setAlert({ show: true }));
        dispatch(setLoading(false));
      }
    });
  };

  // useEffect(() => {
  //   getVideo();
  // }, []);

  const handleVideoData = (videoData) => {};

  return (
    <Box
      sx={{
        background: "#FFF",
        boxShadow: "0px 3px 6px #00000029",
        width: { xs: "100%", lg: "100%", lgm: "50%" },
        borderRadius: "25px",
        display: "flex",
        // height: { xs: "auto", tablet: "784px" },
        // maxHeight:"683px",
        height: "calc(100% - 10%)",
        // maxWidth:"50%",
        flexDirection: "column",
        justifyContent: { xs: "start", lg: "space-between" },
        marginBottom: "30px",
        marginTop: { xs: "55px", md: "55px" },
      }}
    >
      {step === 1 && (
        <AddVideo
          nextStep={setStep}
          getVideo={getVideo}
          setTextChange={setTextChange}
        />
      )}
      {step === 2 && <CounterDialog nextStep={setStep} name={"my"}  />}
      {step === 3 && (
        <RecordingInProgress
          nextStep={setStep}
          onRecordingStop={handleVideoData}
          uploadFunc={uploadVideo}
          name={"my"}
          handleStopRecording={handleStopRecording}
          handleCloseCamera={handleCloseCamera}
          setMediaStream={setMediaStream}
          mediaStream={mediaStream}
          mediaRecorderRef={mediaRecorderRef}
          setRecordedVideoData={setRecordedVideoData}
          setVideoBlob={setVideoBlob}
          setTextChange={setTextChange}
        />
      )}
      {step === 4 && (
        <PublishVideo
          nextStep={setStep}
          videoData={recordedVideoData}
          userData={userData}
          getVideo={getVideo}
          setActiveStep={setActiveStep}
          uploadVideo={uploadVideo}
          textChange={textChange}
        />
      )}
    </Box>
  );
};

export default VideoAddSteps;
