import {
  Box,
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React from "react";
import IconSection from "../../adminTalent/IconSection";
import InfiniteScroll from "react-infinite-scroll-component";
import { useTheme } from "@emotion/react";
import { ExpandMore } from "@mui/icons-material";
import TalentSVGButton from "../../../common/TalentSVGButton";
import editIcon from "../../../../assets/Padding Included/Yellow_Edit.svg";
import deleteIcon from "../../../../assets/Padding Excluded/Black_Trash_Delete_1 - Copy.svg";
import LockResetIcon from "@mui/icons-material/LockReset";
import profile from "../../../../assets/user_logo.svg";

const AdminTable = ({
  handleOpenAdd,
  tableList,
  jobTitleCount,
  handleEditDialog,
  handleDeleteDialog,
  pageTitle,
  rightButtonText,
  width,
  handeResetPassword,
}) => {
  const theme = useTheme();

  return (
    <Box sx={{ ml: "16px" }}>
      <Typography
        sx={{
          fontSize: "36px",
          fontWeight: 700,
          textAlign: "center",
        }}
      >
        {pageTitle}
      </Typography>
      <Grid
        container
        spacing={2}
        flexDirection={"column"}
        sx={{
          display: { xs: "none", md: "flex" },
          marginTop: "30px",
          background: "white",
          borderRadius: "25px",
        }}
        boxShadow={1}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            gap: "10px",
          }}
        >
          <Box sx={{ width: 160 }}></Box>
          <Box mt={2}>
            <IconSection />
          </Box>
          <Button
            variant="contained"
            color="yellowButton100"
            sx={{
              width: `${width ? width : "160px"}`,
              borderRadius: "0 25px 0 25px",
            }}
            onClick={handleOpenAdd}
          >
            {rightButtonText}
          </Button>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            paddingX: 2,
            paddingY: 1,
            borderBottom: 2,
            borderColor: theme.palette.grayBorder,
          }}
        >
          <Box>
            <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
              <Typography
                sx={{
                  fontWeight: 700,
                }}
              ></Typography>
            </Box>
          </Box>
        </Box>
        <InfiniteScroll
          style={{ overflow: "visible" }}
          dataLength={tableList?.length}
          // next={() => getTitles(lastKey, searchTitle)}
          scrollThreshold={"10px"}
          scrollableTarget={"jobList"}
          hasMore={tableList?.length < jobTitleCount ? true : false}
        >
          <Box
            sx={{
              mt: 2,
            }}
          >
            <TableContainer
              component={Box}
              sx={{
                overflowX: "unset",
              }}
            >
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ width: "55px" }}></TableCell>
                    <TableCell>
                      <Typography
                        variant="subtitle1"
                        fontWeight="bold"
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <span>First Name</span>
                          <span>
                            <ExpandMore
                              sx={{
                                width: "20px",
                                height: "20px",
                                marginTop: "5px",
                              }}
                            />
                          </span>
                        </div>
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography
                        variant="subtitle1"
                        fontWeight="bold"
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <span>Last Name</span>
                          <span>
                            <ExpandMore
                              sx={{
                                width: "20px",
                                height: "20px",
                                marginTop: "5px",
                              }}
                            />
                          </span>
                        </div>
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography
                        variant="subtitle1"
                        fontWeight="bold"
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <span>Email</span>{" "}
                          <span>
                            <ExpandMore
                              sx={{
                                width: "20px",
                                height: "20px",
                                marginTop: "5px",
                              }}
                            />
                          </span>
                        </div>
                      </Typography>
                    </TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {tableList?.map((row, index) => {
                    return (
                      <TableRow
                        key={row?.id}
                        style={{
                          background: `${index % 2 === 0 ? "#F2F4FB" : ""}`,
                          "& .css-12zgwp-MuiTableCell-root": {
                            padding: "0 16px !important",
                          },
                        }}
                      >
                        <TableCell
                          sx={{
                            width: "46px",
                            textAlign: "center",
                            padding: "0px !important",
                          }}
                        >
                          {row?.profile_pic !== "NO URL" ? (
                            <Box
                              component="img"
                              className="profileAvatar"
                              alt="logo"
                              src={row?.profile_pic}
                              sx={{
                                height: "46px !important",
                                width: "46px !important",
                                borderRadius: "10px",
                              }}
                            />
                          ) : (
                            <Box
                              component="img"
                              className="profileAvatar"
                              src={profile}
                              sx={{
                                height: "46px !important",
                                width: "46px !important",
                                borderRadius: "10px",
                              }}
                            />
                          )}
                        </TableCell>
                        <TableCell>{row?.firstName}</TableCell>
                        <TableCell>{row?.lastName}</TableCell>
                        <TableCell>{row?.email}</TableCell>
                        <TableCell
                          sx={{
                            display: "flex",
                          }}
                        >
                          <TalentSVGButton
                            color={"white"}
                            source={editIcon}
                            height={34}
                            width={35}
                            padding={0}
                            onClick={() => handleEditDialog(row)}
                          />
                          <TalentSVGButton
                            color={"white"}
                            source={deleteIcon}
                            height={24}
                            width={18}
                            padding={0}
                            onClick={() => handleDeleteDialog(row)}
                          />
                          {/* <TalentSVGButton
                          color={"white"}
                          source={<LockResetIcon/>}
                          width= "30px"
                          height= "30px"
                          padding={0}
                          onClick={() => handeResetPassword(row)}
                        /> */}
                          {/* <Box  > */}
                          <LockResetIcon
                            sx={{
                              width: "30px",
                              height: "30px",
                              // marginTop: "5px",
                            }}
                            onClick={() => handeResetPassword(row)}
                          />
                          {/* </Box> */}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </InfiniteScroll>
      </Grid>
    </Box>
  );
};

export default AdminTable;
