import React, { useState } from "react";
import upArrow from "../../../assets/Black_Up_Close - Copy.svg";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Typography } from "@mui/material";
import { useTheme } from "@emotion/react";
import redTriangleSmile from "../../../assets/Characters/CrayBotIcons/Crayon Craybot Notification-07.svg";
import editIcon from "../../../assets/Black_Edit copy.svg";
import inProcess from "../../../assets/CircularIcon/Yellow/Circular Icons__Yellow_In_Process.svg";
import inProcessGrey from "../../../assets/CircularIcon/Grey/Circular Icons__Grey_In_Process.svg";
import { CheckCircle, Circle } from "@mui/icons-material";
import TrackButtonLayout from "../../common/TrackButtonLayout";
import SVGButton from "../../common/SVGButton";
import EditQandA from "./EditQandA";
import { renderColor } from "../../../utils/Common";
import { truncate } from "lodash";

const TrackButton = ({ job, closeFunc, cardWidth,setCandidateJobs }) => {
  const theme = useTheme();
const navigate = useNavigate()
  const [openQandADialog, setopenQandADialog] = useState(false);
  const handleVideoApplicationNavigate = () =>{
    navigate("/candidate/my-cam",{state:job?.job_id})
  }
  
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "column",
        height: "100%",
        background: job?.incompleteApplication === true ? "#E8D7E0" : "#FFFFFF",
      }}
    >
      <Box sx={{}}>
        <Box
          // mb={"10px"}
          sx={{ display: "flex", justifyContent: "space-between", height:"96px" }}
        >
          <Box paddingTop={"15px"} paddingLeft={"16px"}>
            <Typography
              sx={{
                fontSize: theme.typography.fontSize.sm,
                fontWeight: theme.typography.Bold,
              }}
            >
              My application stage:
            </Typography>
            {/* <SmallButton
              height={"25px"}
              backgroundColor={
                theme.manageTalent?.[renderColor(job?.job_user_status)]?.main
              }
              label={job?.job_user_status}
            /> */}
          </Box>
          <Box
            sx={{
              alignItems: "start",
              display: "flex",
              // width: { xs: "80px", lg: "90px", xl: "120px" },
              width: "120px",
              flexDirection: "row",
              // border: `1px solid ${theme.palette.chart.gray}`,
              borderTop: 0,
              borderRight: 0,
              borderRadius: "0 25px 0px 10px",
              height: "70px",
              position: "relative",
            }}
          >
            <Button
              sx={{
                height: "auto",
                minWidth: "60px",
                minHeight: "45px",
                borderRadius: "0 0 0 10px",
                padding: 0,
                fontSize: 12,
                fontWeight: theme.typography.Bold,
                background:
                  theme.manageTalent[renderColor(job?.job_user_status)]?.main,
                cursor: "default",
                color: "white",
                ":hover": {
                  background:
                    theme.manageTalent[renderColor(job?.job_user_status)]?.main,
                },
              }}
            >
              {job?.job_user_status
                ? truncate(job?.job_user_status, { length: 9 })
                : "-"}
            </Button>
            <Button
              variant="contained"
              sx={{
                boxShadow: "none !important",
                height: "45px !important",
                borderRadius: "0 25px 0 0",
                minWidth: "60px !important",
                maxWidth: "60px !important",
                display: "flex",
                flexDirection: "column",
              }}
              onClick={() => {
                closeFunc((prevState) => !prevState);
              }}
              color="grayButton100"
            >
              <TrackButtonLayout
                job={job}
                colorPattern={[
                  job?.profileCompleted,
                  job?.cvCompleted,
                  job?.qaCompleted,
                  job?.is_video_require?job?.JobCamVideo:"grey",
                ]}
              />
              <Box
                component={"img"}
                src={upArrow}
                sx={{
                  height: 25,
                  width: 25,
                }}
              />
            </Button>
            <Box
              sx={{
                position: "absolute",
                right: "5px",
                bottom: "0px",
                display: "flex",
                width: "170px",
                justifyContent: "space-between",
              }}
            >
              <Typography
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 1,
                  fontWeight: theme.typography.Bold,
                  fontSize: "14px",
                  marginRight: "20px",
                }}
              >
                Job status:
              </Typography>
              <Typography
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 1,
                  fontWeight: theme.typography.Bold,
                  fontSize: "14px",
                }}
              >
                {job?.job_status_name || "Status"}{" "}
                <Circle
                  fontSize="string"
                  color={
                    job?.job_status_name === "paused"
                      ? "pausedStatus"
                      : job?.job_status_name === "closed"
                      ? "closedStatus"
                      : job?.job_status_name === "rejected"
                      ? "rejectedStatus"
                      : job?.job_status_name === "pending"
                      ? "pendingStatus"
                      : job?.job_status_name === "active"
                      ? "activeStatus"
                      : job?.job_status_name === "expired"
                      ? "peachPayment"
                      : ""
                  }
                />
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box
          mb={"10px"}
          sx={{ paddingX: "15px", display: "flex", justifyContent: "left" }}
        >
          <Typography
            noWrap={true}
            sx={{
              fontSize: "20px",
              maxWidth: `calc(100% - 40px)`,
              wordBreak: "break-word",
              overflow: "hidden",
              fontWeight: theme.typography.Bold,
              textAlign: "center",
            }}
          >
            {job?.title}
          </Typography>
        </Box>

        <Box
          sx={{
            display: "flex",
            gap: "22px",
            // padding: "0 17px 21px 30px",
            paddingBottom: "21px",
            justifyContent: "flex-end",
            width: "100%",
          }}
        >
          <Box sx={{ width: "100px" }}>
            <Box
              component={"img"}
              src={redTriangleSmile}
              sx={{ width: 100, height: 100, position: "absolute", left: 0 }}
            />
          </Box>
          <Box sx={{ width: "calc(100% - 55px)" }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                // gap: 2,
                // paddingLeft: "5px",
                alignItems: "center",
                // margin: "10px 0 28px 0",
                paddingRight: "10px",
              }}
            >
              <Typography
                sx={{
                  fontSize: theme.typography.fontSize.xs,
                  color: "black",
                  fontWeight: theme.typography.Regular,
                  width: "100%",
                  textAlign: "center",
                }}
              >
                A valid application requires, at a minimum, that your Profile
                and ‘the basics’ section of your Crayon Vitae be completed.
              </Typography>
              {/* <Typography
              sx={{
                fontSize: theme.typography.fontSize.xs,
                color: "black",
                fontWeight: theme.typography.Regular,
              }}
            >
              that your Profile and
            </Typography> */}
              {/* <Typography
              sx={{
                fontSize: theme.typography.fontSize.xs,
                color: "black",
                fontWeight: theme.typography.Regular,
              }}
            >
              ‘the basics’ section of your Crayon
            </Typography> */}
              {/* <Typography
              sx={{
                fontSize: theme.typography.fontSize.xs,
                color: "black",
                fontWeight: theme.typography.Regular,
              }}
            >
              Vitae be completed.
            </Typography> */}
              <Typography
                sx={{
                  fontSize: theme.typography.fontSize.xs,
                  color: "black",
                  width: "198px",
                  minHeight: "32px",
                  lineHeight: "16px",
                  fontWeight: theme.typography.Regular,
                  textAlign: "center",
                  marginTop: "10px",
                }}
              >
                Remember, the more you complete, the stronger you can compete!
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            padding: "0px 30px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "11px",
              // px: "15px",
            }}
          >
            <Link
              to={"/candidate/my-profile"}
              style={{
                textDecoration: "none",
                color: theme.palette.black,
              }}
            >
              <Button
                variant="contained"
                sx={{
                  borderRadius: "10px",
                  backgroundColor: theme.palette.chart.gray,
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                  padding: 1,
                  height: "45px",
                  boxShadow: "none !important",
                  color: "black",
                  ":hover": {
                    backgroundColor: theme.palette.chart.gray,
                  },
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: 1,
                  }}
                >
                  {job?.profileCompleted ? (
                    <CheckCircle
                      sx={{
                        height: "30px",
                        width: "30px",
                      }}
                      color="lightGreenButton300"
                    />
                  ) : (
                    <SVGButton
                      color={"white"}
                      source={inProcess}
                      height={{ xs: 25, lgm: 25 }}
                      width={{ xs: 25, lgm: 25 }}
                      startIconMargin={"0px !important"}
                      padding={"0px !important"}
                      justifyContent="center"
                      outerHeight={30}
                      outerWidth={30}
                    />
                  )}
                  <Typography
                    sx={{
                      fontSize: theme.typography.fontSize.sm,
                      fontWeight: theme.typography.Bold,
                    }}
                  >
                    my info
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Box
                    component={"img"}
                    src={editIcon}
                    sx={{ width: 20, height: 20, paddingLeft: "6px" }}
                  />
                </Box>
              </Button>
            </Link>

            <Link
              to={"/candidate/my-cv"}
              style={{
                textDecoration: "none",
                color: theme.palette.black,
              }}
            >
              <Button
                variant="contained"
                sx={{
                  borderRadius: "10px",
                  backgroundColor: theme.palette.chart.gray,
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                  height: "45px",
                  padding: 1,
                  boxShadow: "none !important",
                  color: "black",
                  ":hover": {
                    backgroundColor: theme.palette.chart.gray,
                  },
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: 1,
                  }}
                >
                  {job?.cvCompleted ? (
                    <CheckCircle
                      sx={{
                        height: "30px",
                        width: "30px",
                      }}
                      color="lightGreenButton300"
                    />
                  ) : (
                    <SVGButton
                      color={"white"}
                      source={inProcess}
                      height={{ xs: 25, lgm: 25 }}
                      width={{ xs: 25, lgm: 25 }}
                      startIconMargin={"0px !important"}
                      padding={"0px !important"}
                      justifyContent="center"
                      outerHeight={30}
                      outerWidth={30}
                    />
                  )}{" "}
                  <Typography
                    sx={{
                      fontSize: theme.typography.fontSize.sm,
                      fontWeight: theme.typography.Bold,
                    }}
                  >
                    Crayon vitae
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Box
                    component={"img"}
                    src={editIcon}
                    sx={{ width: 20, height: 20, paddingLeft: "6px" }}
                  />
                </Box>
              </Button>
            </Link>
            <Button
              variant="contained"
              sx={{
                borderRadius: "10px",
                backgroundColor: theme.palette.chart.gray,
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                height: "45px",
                width: "100%",
                padding: 1,
                boxShadow: "none !important",
                color: "black",
                ":hover": {
                  backgroundColor: theme.palette.chart.gray,
                },
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 1,
                }}
              >
                {/* <CheckCircle
                  sx={{
                    height: "30px",
                    width: "30px",
                  }}
                  color="lightGreenButton300"
                /> */}
                {job?.qaCompleted ? (
                  <CheckCircle
                    sx={{
                      height: "30px",
                      width: "30px",
                    }}
                    color="lightGreenButton300"
                  />
                ) : (
                  <SVGButton
                    color={"white"}
                    source={inProcess}
                    height={{ xs: 25, lgm: 25 }}
                    width={{ xs: 25, lgm: 25 }}
                    startIconMargin={"0px !important"}
                    padding={"0px !important"}
                    justifyContent="center"
                    outerHeight={30}
                    outerWidth={30}
                  />
                )}
                <Typography
                  sx={{
                    fontSize: theme.typography.fontSize.sm,
                    fontWeight: theme.typography.Bold,
                  }}
                >
                  Q&A
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
                onClick={() => setopenQandADialog(true)}
              >
                <Box
                  component={"img"}
                  src={editIcon}
                  sx={{ width: 20, height: 20, paddingLeft: "6px" }}
                />
              </Box>
            </Button>
            {job?.is_video_require ? (
              // <Link
              //   to={"/candidate/my-cam"}
              //   style={{
              //     textDecoration: "none",
              //     color: theme.palette.black,
              //   }}
              // >
              //  </Link> 
                <Button
                  variant="contained"
                  onClick={handleVideoApplicationNavigate}
                  sx={{
                    borderRadius: "10px",
                    backgroundColor: theme.palette.chart.gray,
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                    padding: 1,
                    height: "45px",
                    boxShadow: "none !important",
                    color: "black",
                    ":hover": {
                      backgroundColor: theme.palette.chart.gray,
                    },
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: 1,
                    }}
                  >
                    {job?.JobCamVideo ? (
                      <CheckCircle
                        sx={{
                          height: "30px",
                          width: "30px",
                        }}
                        color="lightGreenButton300"
                      />
                    ) : (
                      <SVGButton
                        color={"white"}
                        source={
                          job?.is_video_require ? inProcess : inProcessGrey
                        }
                        height={{ xs: 25, lgm: 25 }}
                        width={{ xs: 25, lgm: 25 }}
                        startIconMargin={"0px !important"}
                        padding={"0px !important"}
                        justifyContent="center"
                        outerHeight={30}
                        outerWidth={30}
                      />
                    )}{" "}
                    <Typography
                      sx={{
                        fontSize: theme.typography.fontSize.sm,
                        fontWeight: theme.typography.Bold,
                      }}
                    >
                      video application
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Box
                      component={"img"}
                      src={editIcon}
                      sx={{ width: 20, height: 20, paddingLeft: "6px" }}
                    />
                  </Box>
                </Button>
            ) : (
              <Button
                variant="contained"
                sx={{
                  borderRadius: "10px",
                  backgroundColor: theme.palette.chart.gray,
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                  padding: 1,
                  height: "45px",
                  boxShadow: "none !important",
                  color: "black",
                  ":hover": {
                    backgroundColor: theme.palette.chart.gray,
                  },
                  cursor: "default",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: 1,
                  }}
                >
                  {job?.JobCamVideo ? (
                    <CheckCircle
                      sx={{
                        height: "30px",
                        width: "30px",
                      }}
                      color="lightGreenButton300"
                    />
                  ) : (
                    <SVGButton
                      color={"white"}
                      source={job?.is_video_require ? inProcess : inProcessGrey}
                      height={{ xs: 25, lgm: 25 }}
                      width={{ xs: 25, lgm: 25 }}
                      startIconMargin={"0px !important"}
                      padding={"0px !important"}
                      justifyContent="center"
                      outerHeight={30}
                      outerWidth={30}
                    />
                  )}{" "}
                  <Typography
                    sx={{
                      fontSize: theme.typography.fontSize.sm,
                      fontWeight: theme.typography.Bold,
                    }}
                  >
                    video application
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Box
                    component={"img"}
                    src={editIcon}
                    sx={{ width: 20, height: 20, paddingLeft: "6px" }}
                  />
                </Box>
              </Button>
            )}
          </Box>
        </Box>
      </Box>
      <Button
        variant="contained"
        color="redButton100"
        sx={{
          width: "100%",
          height: "60px",
          paddingY: "25px",
          borderRadius: "0 0 25px 25px",
        }}
        onClick={() => closeFunc((prevState) => !prevState)}
      >
        close
      </Button>
      {openQandADialog && (
        <EditQandA
          jobId={job?.job_id}
          openQandADialog={openQandADialog}
          setopenQandADialog={setopenQandADialog}
        />
      )}
    </Box>
  );
};

export default TrackButton;
