import React, { useEffect, useState } from "react";
import { MenuItem, Select } from "@mui/material";
import { useDispatch } from "react-redux";
import { changeStatus } from "../../../redux/employer/myTeams";
import { setAlert } from "../../../redux/configSlice";
import { ALERT_TYPE, PERMISSION_OPTIONS } from "../../../utils/Constants";
import { BootstrapInput } from "../../../utils/CommonComponent";
import locale from "../../../i18n/locale";

const SelectPermission = ({
  selectedPermission,
  user_id,
  role,
  superAdminFlag,
}) => {
  const i18n = locale.en;
  const dispatch = useDispatch();
  const [selectedValue, setSelectedValue] = useState("");

  useEffect(() => {
    setSelectedValue(findValueByLabel(selectedPermission));
  }, [selectedPermission]);

  const handleChange = (event) => {
    setSelectedValue(event?.target?.value);
    permissionChange(event?.target?.value);
  };

  const findValueByLabel = (label) => {
    const option = PERMISSION_OPTIONS?.find(
      (option) => option?.label === label
    );
    return option ? option?.value : "";
  };

  const permissionChange = async (role_id) => {
    try {
      const data = {
        new_role_id: role_id,
        employerid: user_id,
      };
      const { payload } = await dispatch(changeStatus(data));
      if (payload?.status === "success") {
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE?.SUCCESS,
            msg: i18n["alertMessage.Permissionchangedsuccessfully"],
          })
        );
      } else {
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE?.ERROR,
            msg: payload?.message,
          })
        );
      }
    } catch (error) {
      dispatch(
        setAlert({
          show: true,
          type: ALERT_TYPE?.ERROR,
          msg: error,
        })
      );
    }
  };

  return (
    <Select
      readOnly={
        role === 3 ||
        role === 4 ||
        role === 5 ||
        (selectedPermission === PERMISSION_OPTIONS?.[0]?.label &&
          !superAdminFlag)
      }
      value={selectedValue}
      input={<BootstrapInput />}
      onChange={handleChange}
      sx={{
        width: 200,
      }}
    >
      {PERMISSION_OPTIONS?.map((option) => (
        <MenuItem key={option?.value} value={option?.value}>
          {option?.label}
        </MenuItem>
      ))}
    </Select>
  );
};

export default SelectPermission;
