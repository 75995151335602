import { useCallback, useEffect, useRef, useState } from "react";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { styled, alpha } from "@mui/material/styles";
import locale from "../../../i18n/locale";
import Switch from "@mui/material/Switch";
import { Paper, Popover } from "@mui/material";
import {
  ALERT_TYPE,
  BASIC_RANGEMARKS_SLIDER,
  BUILD_SEARCH_ADVANCE_FILTER,
  ERROR_MSG,
  INITIALSEARCHDATA,
  SEARCH_BAR_BUTTON_GROUP,
  findFirstKeyWithNonEmptyField,
  marks,
  rangeValueExperience,
} from "../../../utils/Constants";
import Slider from "@mui/material/Slider";
import {
  getQualification,
  getSkills,
  getTools,
  getTown,
  updateToInitialTools,
  updateToInitialSkills,
  updateToInitialTown,
  updateToInitialqualifications,
} from "../../../redux/employer/postJobSlice";
import { setAlert, setLoading } from "../../../redux/configSlice";
import { useDispatch } from "react-redux";
import {
  getSchool,
  getAssociation,
  getInstitute,
  getLanguage,
  getNationality,
  getIndustries,
  getTitles,
  updateToInitialSchools,
  updateToInitialAssociation,
  updateToInitialInstitute,
  updateToInitialLanguage,
  updateToInitialNationality,
  updateToInitialIndustrie,
  updateToInitialTitles,
  getCurrency,
} from "../../../redux/candidate/myCvSlice";
import { useSelector } from "react-redux";
import TalentSVGButton from "../../common/TalentSVGButton";
import link from "../../../assets/CircularIcon/Red/Circular Icons__Red_Title_Job_Experience.svg";
import infoGrey from "../../../assets/CircularIcon/Grey/infoGrey.svg";
import skill from "../../../assets/CircularIcon/Red/Circular Icons__Red_Filter_Stats.svg";
import tool from "../../../assets/CircularIcon/Red/Circular Icons__Red_Own_Equipment.svg";
import instituteSvg from "../../../assets/CircularIcon/Red/Circular Icons__Red_Instructions.svg";
import associationSvg from "../../../assets/CircularIcon/Red/Circular Icons__Red_Network.svg";
import townsSvg from "../../../assets/CircularIcon/Red/Circular Icons__Red_Location.svg";
import nationalitySvg from "../../../assets/CircularIcon/Red/Circular Icons__Red_Flag.svg";
import experienceSvg from "../../../assets/CircularIcon/Red/Circular Icons__Red_Duration.svg";
import salarySvg from "../../../assets/CircularIcon/Red/Circular Icons__Red_Salary.svg";
import diamond from "../../../assets/Characters/Red_Diamond.svg";
import {
  // getCompanies,
  getCandidateCompany,
  updateInitialCandidateCompanies,
  updateInitialCompanies,
} from "../../../redux/employer/empProfileSlice";
import {
  buildSearchNew,
  getSaveSearch,
  getSaveSearchById,
  saveSearch,
  adminLinkPool,
} from "../../../redux/admin/searchSlice";
import SmallButtonTalent from "../../common/SmallButtonTalent";
import leftArrow from "../../../assets/Black_Left_Previous.svg";
import rightArrow from "../../../assets/Black_Right_Next.svg";
import CustomSelect from "../../candidate/myCV/CustomSelect";
import {
  checkNumberRange,
  debounce,
  formatNumber,
  genarateLabelValue,
  genarateLabelValueForFreelance,
} from "../../../utils/Common";
import SearchSaveModal from "./SearchSaveModal";
import { useLocation, useParams } from "react-router-dom";
import BuildSearchAccordion from "./BuildSearchAccordion";
import SelectMenu from "../../common/SelectMenu";
import EmployerButtonPanel from "../../common/EmployerButtonPanel";
import { useNavigate } from "react-router-dom";
import AddToPool from "../adminTalent/DialogBox/AddToPool";
import { getTalentPool, removeLinkedJob } from "../../../redux/admin/jobsSlice";
import LinkedToPoolModal from "./LinkedToPoolModal";
import CommonLoader from "../../common/CommonLoader";
import Loader from "../../common/Loader";

const YellowSwitch = styled(Switch)(({ theme, checked, disabled }) => ({
  "& .MuiSwitch-switchBase.Mui-checked": {
    color: disabled
      ? "#F2F4FB"
      : !checked
      ? theme.palette.yellowButton100.main
      : "#009AE0",
    "&:hover": {
      backgroundColor: alpha(
        disabled
          ? "#F2F4FB"
          : !checked
          ? theme.palette.yellowButton100.main
          : "#009AE0",
        theme.palette.action.hoverOpacity
      ),
    },
  },
  "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
    backgroundColor: disabled
      ? "#F2F4FB"
      : !checked
      ? theme.palette.yellowButton100.main
      : "#009AE0",
  },
  "& .MuiSwitch-track": {
    margin: "auto",
    height: "50% !important",
    width: "90% !important",
    padding: "0px !important",
    backgroundColor: disabled
      ? "#C9CAD8"
      : !checked
      ? theme.palette.yellowButton100.main
      : "#009AE0",
  },
  ".MuiButtonBase-root.MuiSwitch-switchBase": {
    borderRadius: "15% !important",
    padding: "11px !important",
  },
  "& .MuiSwitch-thumb": {
    borderRadius: "6px !important",
    height: "15px !important",
    width: "15px !important",
    backgroundColor: disabled
      ? "#F2F4FB"
      : !checked
      ? theme.palette.yellowButton100.main
      : "#009AE0",
  },
  "& .MuiButtonBase-root-MuiSwitch-switchBase": {
    borderRadius: "15% !important",
    padding: "11px !important",
  },
}));
const GreenSwitch = styled(Switch)(({ theme, checked }) => ({
  "& .MuiSwitch-switchBase.Mui-checked": {
    color: !checked ? "#38A8AE" : "#CF011B",
    "&:hover": {
      backgroundColor: alpha(
        !checked ? "#38A8AE" : "#CF011B",
        theme.palette.action.hoverOpacity
      ),
    },
  },
  "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
    backgroundColor: !checked ? "#38A8AE" : "#CF011B",
  },
  "& .MuiSwitch-track": {
    margin: "auto",
    height: "50% !important",
    width: "90% !important",
    padding: "0px !important",
    backgroundColor: !checked ? "#38A8AE" : "#CF011B",
  },
  ".MuiButtonBase-root.MuiSwitch-switchBase": {
    borderRadius: "15% !important",
    padding: "11px !important",
  },
  "& .MuiSwitch-thumb": {
    borderRadius: "6px !important",
    height: "15px !important",
    width: "15px !important",
    backgroundColor: !checked ? "#38A8AE" : "#CF011B",
  },
  "& .MuiButtonBase-root-MuiSwitch-switchBase": {
    borderRadius: "15% !important",
    padding: "11px !important",
  },
}));

const StyledBox = styled("img")(() => ({
  cursor: "pointer",
  height: 60,
  width: 60,
  textAlign: "center",
}));

export default function BuildSearchNew() {
  const i18n = locale.en;
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const modalOpen = location.state?.modalOpen;
  const { searchID } = useParams();
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const poolIDRef = useRef(null);
  const isClientSearch = useRef(false);
  isClientSearch.current = window?.location?.pathname?.includes("client-search")
    ? true
    : false;
  const talentCountRef = useRef(0);
  const titleCountRef = useRef(0);
  const [buttonDisable, setButtonDisable] = useState(false);
  const [rangeValue, setRangeValue] = useState([]);
  const [interval, setInterval] = useState();
  const [expRange, setExpRange] = useState([0, 20]);
  const [basicData, setBasicData] = useState(INITIALSEARCHDATA);
  const [totalJobs, setTotalJobs] = useState();
  const [anchorElReferral, setAnchorElReferral] = useState(null);
  const [anchorElInfo, setAnchorElInfo] = useState(null);
  const [anchorElInfo1, setAnchorElInfo1] = useState(null);
  const [anchorElInfo2, setAnchorElInfo2] = useState(null);
  const autocompleteInputRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const selectedAllPost = useRef([]);
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [rightExpand, setRightExpand] = useState(true);
  const openReferral = Boolean(anchorElReferral);
  const openInfo = Boolean(anchorElInfo);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalName, setModalName] = useState("");
  const [accordionList, setAccordionList] = useState([]);
  const { searchSubList } = useSelector((state) => state.searchSlice);
  const [openSearch, setOpenSearch] = useState(false);
  const { candidateCompany } = useSelector((state) => state.myProfile);
  const [pageNumber, setPageNumber] = useState(0);
  const [isLinkedToPoolModal, setIsLinkedToPoolModal] = useState(false);
  const [isTextLoading, setTextIsLoading] = useState(false);
  const [talentID, setTalentID] = useState(location?.state?.talentpool_id);
  const { skills, tools, town, traits, qualifications } = useSelector(
    (state) => state.postJobs
  );
  const buttonRef = useRef(null);
  const storeOpenModalState = useRef(false);
  const { currency } = useSelector((state) => state.myCv);
  const [titleCount, setTitleCount] = useState();
  const salaryTypeList = [
    { name: "salary", id: "salary" },
    { name: "rate", id: "rate" },
  ];
  const [talents, setTalents] = useState([]);
  const [gender, setGender] = useState("");
  const [advanceFilter, setAdvanceFilter] = useState(
    BUILD_SEARCH_ADVANCE_FILTER
  );
  const [salaryRange, setSalaryRange] = useState(BASIC_RANGEMARKS_SLIDER);
  const [selectedFilterKeys, setSelectedFiltersKeys] = useState([]);
  const [skinColor, setSkinColor] = useState([]);
  const [isFilterApplied, setIsFilterApplied] = useState(true);
  const [tableData, setTableData] = useState([]);
  const [totalPoolData, setTotalPoolData] = useState();
  const [lastKey, setLastKey] = useState(0);
  const [isClear, setIsClear] = useState(false);
  const [showTest, setShowTest] = useState(false);
  const [selectedBtns, setSelectedBtn] = useState([]);
  const [randomLoading, setRandomLoading] = useState({
    incomplete: true,
    matched: true,
    review: true,
    considering: true,
    shortlist: true,
    interview: true,
    assessment: true,
    offer: true,
    hired: true,
    rejected: true,
  });
  // setSkinColor
  // setGender
  const {
    industries,
    language,
    school,
    association,
    institution,
    nationality,
    titles,
  } = useSelector((state) => state.myCv);
  let selectStyle = {
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        color: isSelected ? "#fff" : "black",
        ":active": {
          ...styles[":active"],
        },
      };
    },
    control: (baseStyles) => ({
      ...baseStyles,
      boxShadow: "none",
      borderRadius: "10px",
      width: `98%`,
    }),
  };
  const getTalent = async (lastKeyy) => {
    try {
      const { payload } = await dispatch(getTalentPool({ lastKey: lastKeyy }));
      if (payload.status === "success") {
        setTotalPoolData(payload?.poolCount);
        if (lastKeyy === 0) {
          setTableData(
            Array.from(payload?.data, (item) => ({
              ...item,
              poolUserFlag: false,
            }))
          );
          setLastKey(payload?.pageNumber + 1);
        } else {
          setLastKey(payload?.pageNumber + 1);
          setTableData((prevState) => [
            ...prevState,
            ...Array.from(payload?.data, (item) => ({
              ...item,
              poolUserFlag: false,
            })),
          ]);
        }
      }
    } catch (error) {
      dispatch(
        setAlert({
          show: true,
          type: ALERT_TYPE?.ERROR,
          msg: error,
        })
      );
    }
  };

  const removeLinkedJobFromPool = async (jobid, poolId) => {
    try {
      let updatedList = tableData?.map((item) => {
        if (item?.pool_id === poolId) {
          return {
            ...item,
            poolUserFlag: false,
          };
        }
        return item;
      });
      setTableData([...updatedList]);
    } catch (error) {
      dispatch(
        setAlert({
          show: true,
          type: ALERT_TYPE?.ERROR,
          msg: error,
        })
      );
    }
  };

  const handleClick = async (event) => {
    if (
      location?.state?.talentpool_id &&
      talentID &&
      !storeOpenModalState.current
    ) {
      setIsLinkedToPoolModal(true);
    }
    if (
      talentID === false ||
      talentID === "" ||
      talentID === undefined ||
      storeOpenModalState.current
    ) {
      setAnchorEl(event?.currentTarget);
      tableData?.length === 0 && (await getTalent(""));
    }
  };

  const addToPool = async (a, poolID, nav) => {
    if (poolID === null || buttonDisable) {
      return;
    }
    let updatedList = tableData?.map((item) => {
      if (item?.pool_id === poolID) {
        return {
          ...item,
          poolUserFlag: true,
        };
      }
      return item;
    });
    setTableData([...updatedList]);
    try {
      let apiPayload = {
        pool_id: poolID,
        isFirstQuery: findFirstKeyWithNonEmptyField(basicData),
        data: searchPayloadCreator(basicData),
        ...renameKeys(advanceFilter),
      };
      setButtonDisable(true);

      const { payload } = await dispatch(adminLinkPool(apiPayload));
      if (payload?.status === "success") {
        if (nav) {
          navigate(`/admin/admin-talent/talent-pool/${poolID}`);
        }
        // setAnchorEl(null);
        poolIDRef.current = null;
        setButtonDisable(false);
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE?.SUCCESS,
            msg: "Searched talent added successfully",
          })
        );
      } else {
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE?.ERROR,
            msg: "Something went wrong",
          })
        );
      }
    } catch (error) {
      dispatch(
        setAlert({
          show: true,
          type: ALERT_TYPE?.ERROR,
          msg: "Something went wrong",
        })
      );
    }
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const resetSearch = async () => {
    setIsClear(true);
    setPageNumber(0);
    setOpenSearch(false);
    setAccordionList([]);
    setSelectedFiltersKeys(BUILD_SEARCH_ADVANCE_FILTER);
    setAdvanceFilter(BUILD_SEARCH_ADVANCE_FILTER);
    selectedAllPost.current = [];
    setBasicData(INITIALSEARCHDATA);
    setRangeValue([0, 100]);
    setExpRange([0, 20]);
  };

  const getAllData = async (para) => {
    try {
      switch (para) {
        case "title":
          await dispatch(getTitles());
          return;
        case "industry":
          await dispatch(getIndustries());
          return;
        case "company":
          await dispatch(getCandidateCompany());
          return;
        case "skills":
          await dispatch(getSkills());
          return;
        case "tools":
          await dispatch(getTools());
          return;
        case "qualification":
          await dispatch(getQualification());
          return;
        case "institution":
          await dispatch(getInstitute());
          return;
        case "association":
          await dispatch(getAssociation());
          return;
        case "school":
          await dispatch(getSchool());
          return;
        case "towns":
          await dispatch(getTown(""));
          return;
        case "nationality":
          await dispatch(getNationality());
          return;
        case "language":
          await dispatch(getLanguage());
          return;
        default:
          return;
      }
    } catch (error) {
      dispatch(setLoading(false));
      dispatch(
        setAlert({
          show: true,
          type: ALERT_TYPE?.ERROR,
          msg: ERROR_MSG,
        })
      );
    }
  };

  const handlePopoverCloseInfo = () => {
    setAnchorElInfo(null);
  };

  const expHandleChange = (event, newValue) => {
    let newArr = newValue?.map((val) => val);
    let newBasicData = {
      ...basicData,
      experience: {
        ...basicData["experience"],
        field: newArr,
      },
    };
    setExpRange(newArr);
    setBasicData(newBasicData);
  };
  const updateSwitchValues = (data, value) => {
    const updatedData = { ...data };
    for (const key in updatedData) {
      if (
        Object.hasOwnProperty.call(updatedData, key) &&
        typeof updatedData[key] === "object" &&
        updatedData[key] !== null
      ) {
        updatedData[key].switch = value;
      }
    }
    return updatedData;
  };
  const updateDisabledValues = (data, value) => {
    const updatedData = { ...data };
    for (const key in updatedData) {
      if (
        Object.hasOwnProperty.call(updatedData, key) &&
        typeof updatedData[key] === "object" &&
        updatedData[key] !== null
      ) {
        updatedData[key].disabled = value;
      }
    }
    return updatedData;
  };

  const handleSwitch = (event, value) => {
    let newBasicData;
    if (value === "all") {
      newBasicData = updateSwitchValues(
        basicData,
        event.target.checked ? 1 : 0
      );
      setBasicData(newBasicData);
    } else {
      newBasicData = {
        ...basicData,
        [value]: {
          ...basicData[value],
          switch: event.target.checked ? 1 : 0,
        },
      };
      setBasicData(newBasicData);
    }
  };
  const handleGreenSwitch = (event, value) => {
    if (value === "all") {
      let newBasicData = updateDisabledValues(basicData, event.target.checked);
      newBasicData = {
        ...basicData,
        [value]: {
          field: [],
          green: event?.target?.checked,
          disabled: event?.target?.checked,
          switch: basicData?.[value]?.switch,
        },
      };
      setBasicData(newBasicData);
    } else {
      let newBasicData;
      if (event.target.checked) {
        newBasicData = {
          ...basicData,
          [value]: {
            // Preserve the field key if value is "salary" or "experience"
            field:
              value === "salary" || value === "experience"
                ? basicData[value].field
                : [],
            switch: basicData?.[value]?.switch,
            disabled: true,
          },
        };
      } else {
        newBasicData = {
          ...basicData,
          [value]: {
            // Preserve the field key if value is "salary" or "experience"
            field:
              value === "salary" || value === "experience"
                ? basicData[value].field
                : [],
            switch: basicData?.[value]?.switch,
            disabled: false,
          },
        };
      }
      setRangeValue(
        newBasicData?.salaryType === "rate"
          ? [0, salaryRange?.length / 2]
          : [0, 10]
      );
      setBasicData(newBasicData);
    }
  };

  const renameKeys = (obj) => {
    const keyMap = {
      contact: "contract",
      "in-office": "inoffice",
      "in demand": "indemand",
      "my talent": "searchMyTalent",
      genderType: "gender",
    };
    let newObj = {};
    for (let key in obj) {
      if (keyMap[key]) {
        newObj[keyMap[key]] = obj[key];
      } else {
        newObj[key] = obj[key];
      }
    }
    if (!("gender" in newObj)) {
      newObj["gender"] = "";
    }
    if (!("skin_id" in newObj)) {
      newObj["skin_id"] = "";
    }
    delete newObj.race;
    return newObj;
  };
  const handleSearch = async (titleListFlag, titlt_id, searchData, page) => {
    setShowTest(false);
    dispatch(setLoading(true))
    // setTextIsLoading(true);
    let updatedData2 = {
      titleListFlag: titleListFlag ? titleListFlag : true,
      title_id: titlt_id ? titlt_id : null,
      isFirstQuery: findFirstKeyWithNonEmptyField(
        searchData ? searchData : basicData
      ),
      data: searchPayloadCreator(searchData ? searchData : basicData),
      ...renameKeys(advanceFilter),
    };

    let updatedData = {
      titleListFlag: titleListFlag ? titleListFlag : true,
      title_id: titlt_id ? titlt_id : null,
      isFirstQuery: findFirstKeyWithNonEmptyField(
        searchData ? searchData : basicData
      ),
      data: searchPayloadCreator(searchData ? searchData : basicData),
    };
    const { payload } = await dispatch(
      buildSearchNew({
        lastKey: page || page === 0 ? page : pageNumber,
        payload: updatedData2,
      })
    );
    if (payload?.status === "success") {
      setShowTest(false);

      setAccordionList((prevAccordionList) => [
        ...prevAccordionList,
        ...payload?.data,
      ]);
      if (payload?.data?.length === 0 && payload?.pageNumber === 0) {
        setShowTest(true);
      }
      talentCountRef.current = payload?.talentCount;
      titleCountRef.current = payload?.titleCount;
      setTitleCount(payload?.titleCount);
      setPageNumber(payload?.pageNumber + 1);
      setTotalJobs(payload?.talentCount);
      // setTextIsLoading(false);
      dispatch(setLoading(false))
    } else {
      // dispatch(
      //   setAlert({
      //     show: true,
      //     type: ALERT_TYPE?.ERROR,
      //     msg: "Something went wrong! please relaod the window",
      //   })
      // );
      setShowTest(false);
      // setTextIsLoading(false);
      dispatch(setLoading(false))
    }
  };

  const handleEditSearch = () => {
    setOpenSearch(false);
  };

  const handleOnSelectChange = (ind, newValue, val, inputId) => {
    const updatedData = {
      ...basicData,
      [ind]: {
        switch: basicData?.[ind]?.switch,
        field:
          basicData[ind] && Array.isArray(basicData[ind].field)
            ? [...newValue]
            : [...newValue],
        disabled: basicData?.[ind]?.disabled,
      },
    };
    if (ind === "industry_id") {
      dispatch(updateToInitialIndustrie());
    }
    if (ind === "current_job_title_id") {
      dispatch(updateToInitialTitles());
    }
    if (ind === "town_id") {
      dispatch(updateToInitialTown());
    }
    if (ind === "nationality_id") {
      dispatch(updateToInitialNationality());
    }
    if (ind === "tag_id") {
      dispatch(updateToInitialSkills());
    }
    if (ind === "qualification_id") {
      dispatch(updateToInitialqualifications());
    }
    if (ind === "institution_id") {
      dispatch(updateToInitialInstitute());
    }
    if (ind === "school_id") {
      dispatch(updateToInitialSchools());
    }
    if (ind === "association_id") {
      dispatch(updateToInitialAssociation());
    }
    if (ind === "language_id") {
      dispatch(updateToInitialLanguage());
    }
    if (ind === "company_name") {
      dispatch(updateInitialCandidateCompanies());
    }
    if (ind === "tool_id") {
      dispatch(updateToInitialTools());
    }

    setBasicData(updatedData);
  };

  const debouncedHandleOnSelectInputChange = useCallback(
    debounce(
      async (newValue, type) => {
        let params = {
          title: newValue || "",
          limit: 200,
        };
        if (newValue) {
          try {
            if (type === "industry_id") {
              dispatch(getIndustries(params));
            }
            if (type === "current_job_title_id") {
              dispatch(getTitles(params));
            }
            if (type === "town_id") {
              let payload = {
                title: newValue || "",
                limit: 200,
                id: "",
              };
              dispatch(getTown(payload));
            }
            if (type === "nationality_id") {
              dispatch(getNationality(params));
            }
            if (type === "tag_id") {
              dispatch(getSkills(params));
            }
            if (type === "qualification_id") {
              dispatch(getQualification(params));
            }
            if (type === "institution_id") {
              dispatch(getInstitute(params));
            }
            if (type === "school_id") {
              dispatch(getSchool(params));
            }
            if (type === "association_id") {
              dispatch(getAssociation(params));
            }
            if (type === "language_id") {
              dispatch(getLanguage(params));
            }
            if (type === "company_name") {
              dispatch(getCandidateCompany(params));
            }
            if (type === "tool_id") {
              dispatch(getTools(params));
            }
          } catch (error) {
            console.log(error, "error");
          }
        }
      },
      750,
      setIsLoading
    ),
    []
  );

  const handleOnSelectInputChange = (newValue, inputId) => {
    debouncedHandleOnSelectInputChange(newValue, inputId);
  };

  const handleFilterSelection = (paramType, filterName) => {
    if (filterName[0] === "quick filters") {
      filterName[0] === "quick filters" && (selectedAllPost.current = []);
      const queryParams = new URLSearchParams(window?.location?.search);
      queryParams.delete(paramType);
    } else {
      const updatedFilters = selectedFilters?.includes(filterName)
        ? selectedFilters?.filter((filter) => filter !== filterName)
        : [filterName];
      const queryParams = new URLSearchParams(window.location.search);
      queryParams.set(paramType, updatedFilters?.join(","));
      setSelectedFilters(updatedFilters);
    }
  };

  const searchPayloadCreator = (input) => {
    const result = {};
    for (const key in input) {
      if (input.hasOwnProperty(key) && key !== "all") {
        const item = input[key];
        if (!item?.disabled) {
          if (key === "salaryType" || key === "currency_id") {
            result[key] = item;
          } else {
            result[key] = {
              switch: item?.switch,
              field:
                item?.field && item?.field?.length > 0
                  ? item?.field?.map((fieldItem) =>
                      fieldItem &&
                      typeof fieldItem === "object" &&
                      "value" in fieldItem
                        ? fieldItem?.value
                        : fieldItem
                    )
                  : [],
            };
          }
        }
      }
    }
    return result;
  };

  const init = async () => {
    await getSearchData(searchID);
    let updatedName = searchSubList?.filter(
      (item) => item?.search_id == searchID
    )?.[0]?.name;
    await handleEditSearch();
    await setModalName(updatedName);
    if (!modalOpen) {
      setOpenSearch(true);
    }
  };

  useEffect(() => {
    if (searchID) {
      setOpenSearch(true);
      init();
    } else {
      setBasicData(INITIALSEARCHDATA);
      setOpenSearch(false);
    }
  }, [searchID]);

  useEffect(() => {
    if (!searchSubList) {
      dispatch(getSaveSearch());
    }
  }, [searchSubList]);

  useEffect(() => {
    dispatch(getCurrency());
  }, []);

  const handleCurrencyDropDown = (e, name) => {
    let updatedData = { ...basicData };
    updatedData[e.target.name] = e.target.value;
    const currencySalary = currency?.find(
      (item) => item?.currency_id === updatedData?.currency_id
    );
    let range;
    let salaryInterval;
    if (updatedData?.salaryType === "rate") {
      let { rangeArray, interval } = genarateLabelValueForFreelance(
        currencySalary ? currencySalary : { max_rate: 2050 }
      );
      range = rangeArray;
      setInterval(interval);
      setRangeValue([0, range?.length / 2]);
      updatedData = {
        ...updatedData,
        salary: {
          ...updatedData["salary"],
          field: [0, (range?.length / 2) * salaryInterval],
        },
      };
    } else {
      range = genarateLabelValue(
        currencySalary ? currencySalary : { max_salary: 205000 }
      );
      if (currencySalary?.currency === "ZAR") {
        setRangeValue([0, 10]);
        updatedData = {
          ...updatedData,
          salary: {
            ...updatedData["salary"],
            field: [0, 100000],
          },
        };
      } else {
        updatedData = {
          ...updatedData,
          salary: {
            ...updatedData["salary"],
            field: [0, Math.floor(currencySalary?.max_salary / 2 / 100) * 100],
          },
        };
        setRangeValue([0, 5.2]);
      }
    }
    setSalaryRange(range);
    setBasicData(updatedData);
  };

  useEffect(() => {
    let newArr = rangeValue?.map((val) =>
      basicData?.salaryType === "rate" ? Math.round(val * interval) : val * 1000
    );
    let newArrExp = expRange?.map((val) => val);
    const newBasicData = {
      ...basicData,
      salary: {
        ...basicData["salary"],
        field:
          basicData?.currency_id === 1 &&
          newArr.length !== 0 &&
          basicData?.salaryType !== "rate"
            ? [newArr?.[0] * 10, newArr?.[1] * 10]
            : newArr,
      },
      experience: {
        ...basicData["experience"],
        field: newArrExp,
      },
    };
    setBasicData(newBasicData);
  }, [rangeValue, expRange]);

  const getSearchData = async (id) => {
    let response = await dispatch(getSaveSearchById(id));
    if (response?.payload?.status === "success") {
      if (response?.payload?.data?.["salary"]) {
        setRangeValue([
          response?.payload?.data?.["salary"]?.field?.[0] / 1000,
          response?.payload?.data?.["salary"]?.field?.[1] / 1000,
        ]);
      }
      if (response?.payload?.data?.["experience"]) {
        setExpRange(response?.payload?.data?.["experience"]?.field);
      }
      setBasicData({ ...basicData, ...response?.payload?.data });
      setModalName(response?.payload?.name);
      setAdvanceFilter({
        ...advanceFilter,
        skin_id: response?.payload?.["advanceSearch"]?.["skin_id"],
        genderType: response?.payload?.["advanceSearch"]?.["gender"],
      });
      selectedAllPost.current =
        response?.payload?.["advanceSearch"]?.["filter"];
      setSelectedFiltersKeys(response?.payload?.["advanceSearch"]?.["filter"]);
      let newSearchData = { ...basicData, ...response?.payload?.data };
      await setAccordionList([]);
      await handleSearch(null, null, newSearchData, 0);
    }
  };

  const handleSearchSaveSubmit = async () => {
    await handleSearch();
    let load = {
      name: modalName,
      data: { ...basicData },
      advanceSearch: {
        filter: [...selectedFilterKeys],
        gender: advanceFilter?.genderType ? advanceFilter?.genderType : "",
        skin_id: advanceFilter?.skin_id ? advanceFilter?.skin_id : "",
      },
      talentcount: talentCountRef?.current,
      titlecount: titleCountRef?.current,
    };
    try {
      let { payload } = await dispatch(saveSearch(load));
      if (payload?.status === "success") {
        dispatch(getSaveSearch());
        setIsModalOpen(false);
      }
    } catch (error) {
      dispatch(
        setAlert({
          show: true,
          type: ALERT_TYPE?.ERROR,
          msg: "Something Went Wrong",
        })
      );
    }
  };

  const handleRangeSliderChange2 = (event, newValue) => {
    setRangeValue(newValue);
    let newArr = newValue?.map((val) => Math.round(val * interval));
    const newBasicData = {
      ...basicData,
      salary: {
        ...basicData["salary"],
        field: newArr,
      },
      // [event?.target?.name]: newArr,
    };
    setBasicData(newBasicData);
  };

  const handleRangeSliderChange1 = (event, newValue) => {
    setRangeValue(newValue);
    let newArr = newValue?.map((val) => val * 1000);
    const newBasicData = {
      ...basicData,
      salary: {
        ...basicData["salary"],
        field:
          basicData?.currency_id === 1
            ? [newArr?.[0] * 10, newArr?.[1] * 10]
            : newArr,
      },
      // [event?.target?.name]: newArr,
    };
    setBasicData(newBasicData);
  };

  const rangeValueHandler = (value) => {
    const symbol = currency?.find(
      (curr) => curr?.currency_id === basicData?.currency_id
    )
      ? currency?.find((curr) => curr?.currency_id === basicData?.currency_id)
      : "$";
    let valueNumberRange = checkNumberRange(symbol?.max_salary);
    let extractedValue = salaryRange?.find(
      (item) => item?.value === value
    )?.label;
    let finalValue = extractedValue ? parseFloat(extractedValue) : value;
    return `${symbol?.symbol ? symbol?.symbol : symbol} ${
      valueNumberRange === "thousands"
        ? formatNumber(value * 1000)
        : formatNumber(value * 10000)
    }`;
  };

  const rangeValueHandler2 = (value) => {
    const symbol = currency?.find(
      (curr) => curr?.currency_id === basicData?.currency_id
    )
      ? currency?.find((curr) => curr?.currency_id === basicData?.currency_id)
      : "$";
    return `${symbol?.symbol ? symbol?.symbol : symbol} ${formatNumber(
      value * interval
    )}`;
  };

  const onFilterSelection = () => {
    setRandomLoading({
      incomplete: true,
      matched: true,
      review: true,
      considering: true,
      shortlist: true,
      interview: true,
      assessment: true,
      offer: true,
      hired: true,
      rejected: true,
    });
    setTalents((prevTalents) => {
      const updatedTalents = prevTalents?.map((item) => {
        return {
          ...item,
          items: [],
          count: prevTalents?.totalData,
          pageNumber: prevTalents?.pageNumber,
        };
      });
      return [...updatedTalents];
    });
  };
  function updateAdvanceFilter(advance, jobs) {
    let updatedFilter = { ...advance };
    const skinId = updatedFilter?.skin_id;
    const genderType = updatedFilter?.genderType;

    for (let key in updatedFilter) {
      if (key === "skin_id") {
        updatedFilter[key] = skinId;
      } else if (key === "genderType") {
        updatedFilter[key] = genderType;
      } else if (Array.isArray(updatedFilter[key])) {
        updatedFilter[key] = [];
      } else {
        updatedFilter[key] = false;
      }
    }

    jobs.forEach((job) => {
      if (updatedFilter?.hasOwnProperty(job)) {
        updatedFilter[job] = true;
      }
    });

    delete updatedFilter?.race;
    // delete updatedFilter.gender;
    return updatedFilter;
  }

  const talentRightFIlters = async (selectedFilter) => {
    let jobs = [];
    selectedAllPost.current = [...selectedFilter];
    setSelectedFiltersKeys(selectedFilter);
    selectedFilter?.map((type) => {
      let selectedJobType = SEARCH_BAR_BUTTON_GROUP?.find(
        (jobtype) => jobtype?.id === type
      );
      jobs?.push(selectedJobType?.name);
    });
    setIsFilterApplied(true);
    let value = updateAdvanceFilter(advanceFilter, jobs);
    if (selectedFilter?.[0] !== 1111) {
      setAdvanceFilter(value);
    }
    handleFilterSelection("quick filters", jobs);
  };
  const handleMySearchNavigate = (e) => {
    navigate(
      isClientSearch?.current
        ? "/admin/search/client-search"
        : "/admin/search/my-search"
    );
  };

  const handleQuickReset = (id) => {
    if (id === 1111) {
      setIsClear(true);
      setSelectedFiltersKeys({ ...BUILD_SEARCH_ADVANCE_FILTER });
      setAdvanceFilter({ ...BUILD_SEARCH_ADVANCE_FILTER });
      selectedAllPost.current = [];
    }
  };

  const handleLinkedPoolCancel = async () => {
    storeOpenModalState.current = true;
    setTalentID(false);
    setIsLinkedToPoolModal(false);
    // handleClick(false)
    triggerButtonClick();
    // setAnchorEl(true);
    // tableData?.length === 0 && (await getTalent(""));
  };

  const handleLinkedPoolSave = () => {
    // setTalentID(false)
    // storeOpenModalState.current =false
    setIsLinkedToPoolModal(false);
    addToPool("", location?.state?.talentpool_id, true);
  };

  const triggerButtonClick = () => {
    if (buttonRef.current) {
      buttonRef.current.click();
    }
  };
  return (
    <>
      <Box sx={{ pb: 3 }}>
        <Typography
          sx={{
            fontSize: "36px",
            fontWeight: 700,
            textAlign: "center",
          }}
        >
          {i18n["buildSearch.title"]}
        </Typography>
        <Paper
          sx={{
            pl: 3,
            pt: 0,
            borderRadius: "20px",
            position: "relative",
            width: `${!rightExpand ? "91.25%" : "98%"}`,
          }}
        >
          {!rightExpand && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                position: "absolute",
                right: "0",
                gap: "10px",
                height: {
                  xs: `calc(100vh - 95px)`,
                  sm: `calc(100vh - 120px )`,
                  md: `calc(100vh - 120px)`,
                  lg: `calc(100vh - 135px)`,
                  xl: `calc(100vh - 155px)`,
                  xxl: "calc(100vh - 175px )",
                },
                left: "calc(100% - 0px)",
                top: "110px",
                minWidth: "100px",
                overflowY: rightExpand ? "scroll" : "unset",
              }}
              className="rightfilterSec"
            >
              <style>
                {`.rightfilterSec::-webkit-scrollbar {
                  display:none;
                }
                `}
              </style>
              <Paper
                sx={{
                  background: "transparent",
                  boxShadow: 0,
                  gap: "10px",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                {/* <ButtonPanel
                  selected={selectedAllPost?.current}
                  panelData={SEARCH_BAR_BUTTON_GROUP}
                  onChangeFilter={onChangefavourite}
                  side="left"
                  color="searchButtons"
                /> */}
                <EmployerButtonPanel
                  panelData={SEARCH_BAR_BUTTON_GROUP}
                  side="left"
                  color={"searchButtons"}
                  searchbuildColor={"searchButtons"}
                  onChangeFilter={talentRightFIlters}
                  selected={selectedAllPost?.current}
                  setGender={setGender}
                  setSkinColor={setSkinColor}
                  skinColor={skinColor}
                  onFilterSelection={() => {}}
                  setIsFilterApplied={() => {}}
                  setBasicData={setAdvanceFilter}
                  genderData={advanceFilter}
                  handleFilterSelection={() => {}}
                  getTalentMyJobStatusCount={() => {}}
                  isClear={isClear}
                  setIsClear={setIsClear}
                  setSelectedBtn={setSelectedBtn}
                  selectedBtns={selectedBtns}
                  buildSearchInitialData={BUILD_SEARCH_ADVANCE_FILTER}
                  handleQuickReset={handleQuickReset}
                />
              </Paper>
            </Box>
          )}
          <Box sx={{ display: "flex", justifyContent: "flex-end", mb: 4 }}>
            <Box
              sx={{
                display: "flex",
                alignItems: "flex-start",
                justifyContent: "space-between",
              }}
              width={openSearch ? "59%" : "66%"}
            >
              <Box
                sx={{
                  display: "flex",
                  height: "100px",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <StyledBox
                  className="homeImages"
                  alt="Home Image"
                  src={diamond}
                />

                {openSearch ? (
                  <>
                    <Box
                      sx={{
                        display: "flex",
                        width: "220px",
                        marginBottom: "5px",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography sx={{ fontSize: "12px", fontWeight: 700 }}>
                        Result based on your search:
                      </Typography>
                      <SmallButtonTalent
                        fontWeight={700}
                        textColor={"#000"}
                        color="grayButton200"
                        label={totalJobs}
                        mr={1}
                      />
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        width: "220px",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography sx={{ fontSize: "12px", fontWeight: 700 }}>
                        Number of job title:
                      </Typography>
                      <SmallButtonTalent
                        fontWeight={700}
                        textColor={"#000"}
                        color="grayButton200"
                        label={titleCount}
                        mr={1}
                      />
                    </Box>
                  </>
                ) : (
                  <Typography sx={{ fontSize: "12px", fontWeight: 700 }}>
                    Select or add the information you want to search for below
                  </Typography>
                )}
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  alignItems: "flex-end",
                  height: "100px",
                }}
              >
                <Button
                  sx={{
                    boxShadow: 0,
                    fontSize: "14px",
                    borderRadius: "0 20px 0 20px !important",
                    height: "43px",
                    padding: 3,
                  }}
                  onClick={(e) => {
                    handleMySearchNavigate(e);
                  }}
                  variant="contained"
                  color="yellowButton100"
                  width="fit-content"
                >
                  {isClientSearch?.current ? "client searches" : "my searches"}
                </Button>
                <Button
                  sx={{
                    boxShadow: 0,
                    fontSize: "14px",
                    borderRadius: "10px 0 0 10px !important",
                    height: "43px",
                    minWidth: "40px !important",
                    padding: 0,
                    ".MuiButton-startIcon": {
                      margin: "0px !important",
                    },
                  }}
                  onClick={() => setRightExpand((prev) => !prev)}
                  variant="contained"
                  color="blueButton700"
                  startIcon={
                    <Box
                      component="img"
                      className="eye"
                      alt="eye logo"
                      src={rightExpand ? rightArrow : leftArrow}
                      sx={{
                        height: 26,
                        width: 26,
                      }}
                    />
                  }
                />
              </Box>
            </Box>
          </Box>
          {!openSearch && (
            <Box sx={{ overflow: "auto", marginTop: "-82px" }}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-around",
                  alignItems: "center",
                  mb: 3,
                  width: "98%",
                  // marginTop: "-76px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    width: "14%",
                    justifyContent: "start", // Center content within the box
                    alignItems: "center",
                    ml: "6px",
                  }}
                ></Box>

                <Box
                  sx={{
                    width: "59%",
                    mx: "10px",
                  }}
                ></Box>
                <Box
                  sx={{
                    display: "flex",
                    width: "27.25%",
                    justifyContent: "start",
                    marginLeft: "15px",
                    alignItems: "center",
                    position: "relative",
                  }}
                >
                  <Box sx={{}}>
                    <Box
                      sx={{
                        display: "flex",
                        width: "344px",
                        justifyContent: "space-evenly",
                        alignItems: "center",
                        height: "74px",
                        background: "#F2F4FB 0% 0% no-repeat padding-box",
                        borderRadius: "35px",
                        flexDirection: "column",
                      }}
                    >
                      <Box sx={{ fontSize: "15px", fontWeight: "600" }}>
                        Toggle all
                      </Box>
                      <Box sx={{ display: "flex" }}>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            marginRight: "20px",
                          }}
                        >
                          <Typography
                            sx={{
                              padding: "5px",
                              height: "8px",
                              width: "8px",
                              borderRadius: "5px",
                              fontSize: "15px",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              fontWeight: 700,
                              border: `1px solid ${theme?.palette?.boxShadow?.main}`,
                              cursor: "pointer",
                              marginRight: "5px",
                              boxShadow: `0px 1px 3px ${theme?.palette?.boxShadow?.main}`,
                            }}
                            onClick={(event) => {
                              setAnchorElInfo1(event.target);
                            }}
                          >
                            i
                          </Typography>

                          <Popover
                            id="dropdown"
                            open={anchorElInfo1}
                            anchorEl={anchorElInfo1}
                            onClose={() => {
                              setAnchorElInfo1(false);
                            }}
                            anchorOrigin={{
                              vertical: "top",
                              horizontal: "center",
                            }}
                            transformOrigin={{
                              vertical: "bottom",
                              horizontal: "center",
                            }}
                            PaperProps={{
                              sx: {
                                minWidth: "13% !important",
                                borderRadius: "10px !important",
                                padding: "8px !important",
                                background: "#F2F4FB",
                                boxShadow: "0px 3px 6px #00000029",
                              },
                            }}
                          >
                            <Typography
                              sx={{
                                fontSize: "12px",
                                fontWeight: 700,
                                textAlign: "left",
                              }}
                            >
                              The broad/narrow toggle acts like an <br /> or/and
                              selector. If you elect to go <br /> broad, the
                              items within that field will <br />
                              be included in the search on an
                              <br />
                              optional basis. If you elect to go <br />
                              narrow, the items within that field <br />
                              must exist in order to dispay a result.
                            </Typography>
                          </Popover>

                          <Typography sx={{ fontSize: "12px" }}>
                            broad
                          </Typography>
                          <YellowSwitch
                            onChange={(event) => handleSwitch(event, "all")}
                            checked={basicData?.all?.switch}
                            disabled={basicData?.all?.green}
                          />
                          <Typography sx={{ fontSize: "12px" }}>
                            narrow
                          </Typography>
                        </Box>
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          <Typography
                            sx={{
                              padding: "5px",
                              height: "8px",
                              width: "8px",
                              borderRadius: "5px",
                              fontSize: "15px",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              fontWeight: 700,
                              // border: 1,
                              cursor: "pointer",
                              marginRight: "5px",
                              border: `1px solid ${theme?.palette?.black100?.main}`,
                              boxShadow: `0px 3px 6px ${theme?.palette?.boxShadow?.main}`,
                            }}
                            onClick={(event) => {
                              setAnchorElInfo2(event.target);
                            }}
                          >
                            i
                          </Typography>

                          <Popover
                            id="dropdown"
                            open={anchorElInfo2}
                            anchorEl={anchorElInfo2}
                            onClose={() => {
                              setAnchorElInfo2(false);
                            }}
                            anchorOrigin={{
                              vertical: "top",
                              horizontal: "center",
                            }}
                            transformOrigin={{
                              vertical: "bottom",
                              horizontal: "center",
                            }}
                            PaperProps={{
                              sx: {
                                minWidth: "13% !important",
                                borderRadius: "20px !important",
                                padding: "8px !important",
                                background: "#F2F4FB",
                                boxShadow: `0px 3px 6px ${theme?.palette?.boxShadow?.main}`,
                              },
                            }}
                          >
                            <Typography
                              sx={{
                                fontSize: "12px",
                                fontWeight: 700,
                                textAlign: "left",
                              }}
                            >
                              Include or exclude the <br /> selected field in
                              the <br />
                              search query and results.
                            </Typography>
                          </Popover>

                          <Typography sx={{ fontSize: "12px" }}>on</Typography>
                          <GreenSwitch
                            onChange={(event) =>
                              handleGreenSwitch(event, "all")
                            }
                            checked={basicData?.all?.green}
                          />
                          <Typography sx={{ fontSize: "12px" }}>off</Typography>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-around",
                  alignItems: "center",
                  mb: 3,
                  width: "98%",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    width: "14%",
                    justifyContent: "start", // Center content within the box
                    alignItems: "center",
                    ml: "6px",
                  }}
                >
                  <Typography
                    sx={{
                      padding: "5px",
                      height: "8px",
                      width: "8px",
                      borderRadius: "5px",
                      fontSize: "15px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      fontWeight: 700,
                      border: 1,
                      cursor: "pointer",
                      marginRight: "5px",
                    }}
                    onClick={(event) => {
                      setAnchorElInfo(event.target);
                    }}
                  >
                    i
                  </Typography>
                  {/* <InfoIcon /> */}

                  <Popover
                    id="dropdown"
                    open={openInfo}
                    anchorEl={anchorElInfo}
                    onClose={handlePopoverCloseInfo}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "center",
                    }}
                    transformOrigin={{
                      vertical: "bottom",
                      horizontal: "center",
                    }}
                    PaperProps={{
                      sx: {
                        minWidth: "13% !important",
                        borderRadius: "20px !important",
                        padding: "8px !important",
                        background: "#F2F4FB",
                        boxShadow: `0px 3px 6px ${theme?.palette?.boxShadow?.main}`,
                      },
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "12px",
                        fontWeight: 700,
                        textAlign: "left",
                      }}
                    >
                      The combined build a search <br /> function uses existing
                      databse <br /> items as the basis for the search. If{" "}
                      <br /> you wish to do a text-based search <br /> on any
                      particular item, please use the <br /> individual search
                      pages available.
                    </Typography>
                  </Popover>

                  <TalentSVGButton
                    color={"white"}
                    // source={link}
                    source={
                      basicData?.current_job_title_id?.disabled
                        ? infoGrey
                        : link
                    }
                    height={30}
                    width={30}
                    startIconMargin={"0px !important"}
                    padding={"0px !important"}
                  />

                  <Typography
                    sx={{
                      fontSize: "12px",
                      fontWeight: 700,
                      width: "100px",
                      textAlign: "left",
                      marginLeft: "15px",
                    }}
                  >
                    Job Title(s)
                  </Typography>
                </Box>
                <Box
                  sx={{
                    width: "59%",
                    mx: "10px",
                  }}
                >
                  <CustomSelect
                    isDisabled={basicData?.current_job_title_id?.disabled}
                    closeMenuOnSelect={false}
                    variant="multiSelect"
                    isMulti={true}
                    onInputChange={handleOnSelectInputChange}
                    onChange={handleOnSelectChange}
                    // placeholder={"Search or add job title"}
                    placeholder={i18n["buildSearch.jobPlacements"]}
                    inputId="current_job_title_id"
                    name="current_job_title_id"
                    value={
                      basicData?.current_job_title_id?.field?.length > 0
                        ? basicData?.current_job_title_id?.field
                        : null
                    }
                    multiOnFocus={() => {
                      if (titles?.length < 1) {
                        getAllData("title");
                      }
                      setIsMenuOpen(true);
                    }}
                    isLoading={isLoading}
                    customStyle={selectStyle}
                    multiSelectOptions={titles?.map((item) => ({
                      value: item?.job_title_id,
                      label: item?.title,
                    }))}
                  />
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    width: "22%",
                    justifyContent: "start",
                    marginLeft: "15px",
                    alignItems: "center",
                    position: "relative",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      marginRight: "40px",
                    }}
                  >
                    <Typography sx={{ fontSize: "12px" }}>broad</Typography>
                    <YellowSwitch
                      onChange={(event) =>
                        handleSwitch(event, "current_job_title_id")
                      }
                      checked={basicData?.current_job_title_id?.switch}
                      disabled={basicData?.current_job_title_id?.disabled}
                    />
                    <Typography sx={{ fontSize: "12px" }}>narrow</Typography>
                  </Box>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Typography sx={{ fontSize: "12px" }}>on</Typography>
                    <GreenSwitch
                      onChange={(event) =>
                        handleGreenSwitch(event, "current_job_title_id")
                      }
                      checked={basicData?.current_job_title_id?.disabled}
                    />
                    <Typography sx={{ fontSize: "12px" }}>off</Typography>
                  </Box>
                </Box>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-around",
                  alignItems: "center",
                  mb: 3,
                  width: "98%", // Ensure full width
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    width: "14%",
                    justifyContent: "start", // Center content within the box
                    alignItems: "center",
                    ml: "6px",
                  }}
                >
                  <Typography
                    sx={{
                      padding: "5px",
                      height: "8px",
                      width: "8px",
                      borderRadius: "5px",
                      fontSize: "15px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      fontWeight: 700,
                      border: 1,
                      opacity: 0,
                    }}
                  >
                    i
                  </Typography>
                  <TalentSVGButton
                    color={"white"}
                    source={basicData?.industry_id?.disabled ? infoGrey : link}
                    height={30}
                    width={30}
                    startIconMargin={"0px !important"}
                    padding={"0px !important"}
                  />

                  <Typography
                    sx={{
                      fontSize: "12px",
                      fontWeight: 700,
                      width: "100px",
                      textAlign: "left",
                      marginLeft: "15px",
                    }}
                  >
                    Industry(ies)
                  </Typography>
                </Box>

                <Box
                  sx={{
                    // display: "flex",
                    width: "59%",
                    mx: "10px",
                  }}
                >
                  <CustomSelect
                    variant={"multiSelect"}
                    inputId={"industry_id"}
                    name={"industry_id"}
                    isDisabled={basicData?.industry_id?.disabled}
                    closeMenuOnSelect={false}
                    isMulti={true}
                    multiSelectOptions={industries?.map((item) => ({
                      value: item?.industry_id,
                      label: item?.name,
                    }))}
                    placeholder={i18n["buildSearch.industryPlacement"]}
                    onChange={(e, val) => handleOnSelectChange(e, val)}
                    customStyle={selectStyle}
                    onInputChange={handleOnSelectInputChange}
                    multiOnFocus={() => {
                      if (industries?.length === 0) {
                        getAllData("industry");
                      }
                    }}
                    value={
                      basicData?.industry_id?.field?.length > 0
                        ? basicData?.industry_id?.field
                        : null

                      // getIndustryValue()
                    }
                  />
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    width: "22%",
                    justifyContent: "start",
                    marginLeft: "15px",
                    alignItems: "center",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      marginRight: "40px",
                    }}
                  >
                    <Typography sx={{ fontSize: "12px" }}>broad</Typography>
                    <YellowSwitch
                      onChange={(event) => handleSwitch(event, "industry_id")}
                      checked={basicData?.industry_id?.switch}
                      disabled={basicData?.industry_id?.disabled}
                    />
                    <Typography sx={{ fontSize: "12px" }}>narrow</Typography>
                  </Box>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Typography sx={{ fontSize: "12px" }}>on</Typography>
                    <GreenSwitch
                      onChange={(event) =>
                        handleGreenSwitch(event, "industry_id")
                      }
                      checked={basicData?.industry_id?.disabled}
                    />
                    <Typography sx={{ fontSize: "12px" }}>off</Typography>
                  </Box>
                </Box>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-around",
                  alignItems: "center",
                  mb: 3,
                  width: "98%", // Ensure full width
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    width: "14%",
                    justifyContent: "start", // Center content within the box
                    alignItems: "center",
                    ml: "6px",
                  }}
                >
                  <Typography
                    sx={{
                      padding: "5px",
                      height: "8px",
                      width: "8px",
                      borderRadius: "5px",
                      fontSize: "15px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      fontWeight: 700,
                      border: 1,
                      opacity: 0,
                    }}
                  >
                    i
                  </Typography>
                  <TalentSVGButton
                    color={"white"}
                    source={basicData?.company_name?.disabled ? infoGrey : link}
                    height={30}
                    width={30}
                    startIconMargin={"0px !important"}
                    padding={"0px !important"}
                  />

                  <Typography
                    sx={{
                      fontSize: "12px",
                      fontWeight: 700,
                      width: "100px",
                      textAlign: "left",
                      marginLeft: "15px",
                    }}
                  >
                    Company(ies)
                  </Typography>
                </Box>

                <Box
                  sx={{
                    width: "59%",
                    mx: "10px",
                  }}
                >
                  <CustomSelect
                    isDisabled={basicData?.company_name?.disabled}
                    closeMenuOnSelect={false}
                    variant="multiSelect"
                    isMulti={true}
                    onInputChange={handleOnSelectInputChange}
                    onChange={handleOnSelectChange}
                    placeholder={i18n["buildSearch.companyPlacement"]}
                    inputId="company_name"
                    name="company_name"
                    value={
                      basicData?.company_name?.field?.length > 0
                        ? basicData?.company_name?.field
                        : null
                    }
                    multiOnFocus={() => {
                      if (candidateCompany?.length === 0) {
                        getAllData("company");
                      }
                      setIsMenuOpen(true);
                    }}
                    isLoading={isLoading}
                    customStyle={selectStyle}
                    multiSelectOptions={candidateCompany?.map((item) => ({
                      value: item?.candidate_company_id,
                      label: item?.name,
                    }))}
                  />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    width: "22%",
                    justifyContent: "start",
                    marginLeft: "15px",
                    alignItems: "center",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      marginRight: "40px",
                    }}
                  >
                    <Typography sx={{ fontSize: "12px" }}>broad</Typography>
                    <YellowSwitch
                      onChange={(event) => handleSwitch(event, "company_name")}
                      checked={basicData?.company_name?.switch}
                      disabled={basicData?.company_name?.disabled}
                    />
                    <Typography sx={{ fontSize: "12px" }}>narrow</Typography>
                  </Box>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Typography sx={{ fontSize: "12px" }}>on</Typography>
                    <GreenSwitch
                      onChange={(event) =>
                        handleGreenSwitch(event, "company_name")
                      }
                      checked={basicData?.company_name?.disabled}
                    />
                    <Typography sx={{ fontSize: "12px" }}>off</Typography>
                  </Box>
                </Box>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-around",
                  alignItems: "center",
                  mb: 3,
                  width: "98%",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    width: "14%",
                    justifyContent: "start",
                    alignItems: "center",
                    ml: "6px",
                  }}
                >
                  <Typography
                    sx={{
                      padding: "5px",
                      height: "8px",
                      width: "8px",
                      borderRadius: "5px",
                      fontSize: "15px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      fontWeight: 700,
                      border: 1,
                      opacity: 0,
                    }}
                  >
                    i
                  </Typography>
                  <TalentSVGButton
                    color={"white"}
                    source={basicData?.tag_id?.disabled ? infoGrey : skill}
                    height={30}
                    width={30}
                    startIconMargin={"0px !important"}
                    padding={"0px !important"}
                  />

                  <Typography
                    sx={{
                      fontSize: "12px",
                      fontWeight: 700,
                      width: "100px",
                      textAlign: "left",
                      marginLeft: "15px",
                    }}
                  >
                    Skills
                  </Typography>
                </Box>
                <Box
                  sx={{
                    width: "59%",
                    mx: "10px",
                  }}
                >
                  <CustomSelect
                    isDisabled={basicData?.tag_id?.disabled}
                    closeMenuOnSelect={false}
                    variant="multiSelect"
                    isMulti={true}
                    onInputChange={handleOnSelectInputChange}
                    onChange={handleOnSelectChange}
                    placeholder={i18n["buildSearch.skillPlacement"]}
                    inputId="tag_id"
                    name="tag_id"
                    value={
                      basicData?.tag_id?.field?.length > 0
                        ? basicData?.tag_id?.field
                        : null
                      // getSkillValue()
                    }
                    multiOnFocus={() => {
                      if (skills?.length === 0) {
                        getAllData("skills");
                      }
                      setIsMenuOpen(true);
                    }}
                    isLoading={isLoading}
                    customStyle={selectStyle}
                    multiSelectOptions={skills?.map((item) => ({
                      value: item?.tag_id,
                      label: item?.tag,
                    }))}
                  />
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    width: "22%",
                    justifyContent: "start",
                    marginLeft: "15px",
                    alignItems: "center",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      marginRight: "40px",
                    }}
                  >
                    <Typography sx={{ fontSize: "12px" }}>broad</Typography>
                    <YellowSwitch
                      onChange={(event) => handleSwitch(event, "tag_id")}
                      checked={basicData?.tag_id?.switch}
                      disabled={basicData?.tag_id?.disabled}
                    />
                    <Typography sx={{ fontSize: "12px" }}>narrow</Typography>
                  </Box>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Typography sx={{ fontSize: "12px" }}>on</Typography>
                    <GreenSwitch
                      onChange={(event) => handleGreenSwitch(event, "tag_id")}
                      checked={basicData?.tag_id?.disabled}
                    />
                    <Typography sx={{ fontSize: "12px" }}>off</Typography>
                  </Box>
                </Box>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-around",
                  alignItems: "center",
                  mb: 3,
                  width: "98%", // Ensure full width
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    width: "14%",
                    justifyContent: "start", // Center content within the box
                    alignItems: "center",
                    ml: "6px",
                  }}
                >
                  <Typography
                    sx={{
                      padding: "5px",
                      height: "8px",
                      width: "8px",
                      borderRadius: "5px",
                      fontSize: "15px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      fontWeight: 700,
                      border: 1,
                      opacity: 0,
                    }}
                  >
                    i
                  </Typography>
                  <TalentSVGButton
                    color={"white"}
                    source={basicData?.tool_id?.disabled ? infoGrey : tool}
                    height={30}
                    width={30}
                    startIconMargin={"0px !important"}
                    padding={"0px !important"}
                  />

                  <Typography
                    sx={{
                      fontSize: "12px",
                      fontWeight: 700,
                      width: "100px",
                      textAlign: "left",
                      marginLeft: "15px",
                    }}
                  >
                    Tools
                  </Typography>
                </Box>
                <Box
                  sx={{
                    // display: "flex",
                    width: "59%",
                    mx: "10px",
                  }}
                >
                  <CustomSelect
                    isDisabled={basicData?.tool_id?.disabled}
                    closeMenuOnSelect={false}
                    variant="multiSelect"
                    isMulti={true}
                    onInputChange={handleOnSelectInputChange}
                    onChange={handleOnSelectChange}
                    placeholder={i18n["buildSearch.toolsPlacement"]}
                    inputId="tool_id"
                    name="tool_id"
                    value={
                      basicData?.tool_id?.field?.length > 0
                        ? basicData?.tool_id?.field
                        : null
                      // getToolValue()
                    }
                    multiOnFocus={() => {
                      if (tools?.length === 0) {
                        getAllData("tools");
                      }
                      setIsMenuOpen(true);
                    }}
                    isLoading={isLoading}
                    customStyle={selectStyle}
                    multiSelectOptions={tools?.map((item) => ({
                      value: item?.tool_id,
                      label: item?.name,
                    }))}
                  />
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    width: "22%",
                    justifyContent: "start",
                    marginLeft: "15px",
                    alignItems: "center",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      marginRight: "40px",
                    }}
                  >
                    <Typography sx={{ fontSize: "12px" }}>broad</Typography>
                    <YellowSwitch
                      onChange={(event) => handleSwitch(event, "tool_id")}
                      checked={basicData?.tool_id?.switch}
                      disabled={basicData?.tool_id?.disabled}
                    />
                    <Typography sx={{ fontSize: "12px" }}>narrow</Typography>
                  </Box>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Typography sx={{ fontSize: "12px" }}>on</Typography>
                    <GreenSwitch
                      onChange={(event) => handleGreenSwitch(event, "tool_id")}
                      checked={basicData?.tool_id?.disabled}
                    />
                    <Typography sx={{ fontSize: "12px" }}>off</Typography>
                  </Box>
                </Box>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-around",
                  alignItems: "center",
                  mb: 3,
                  width: "98%", // Ensure full width
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    width: "14%",
                    justifyContent: "start", // Center content within the box
                    alignItems: "center",
                    ml: "6px",
                  }}
                >
                  <Typography
                    sx={{
                      padding: "5px",
                      height: "8px",
                      width: "8px",
                      borderRadius: "5px",
                      fontSize: "15px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      fontWeight: 700,
                      border: 1,
                      opacity: 0,
                    }}
                  >
                    i
                  </Typography>
                  <TalentSVGButton
                    color={"white"}
                    source={
                      basicData?.qualification_id?.disabled ? infoGrey : tool
                    }
                    height={30}
                    width={30}
                    startIconMargin={"0px !important"}
                    padding={"0px !important"}
                  />

                  <Typography
                    sx={{
                      fontSize: "12px",
                      fontWeight: 700,
                      width: "100px",
                      textAlign: "left",
                      marginLeft: "15px",
                    }}
                  >
                    Qualifications
                  </Typography>
                </Box>
                <Box
                  sx={{
                    // display: "flex",
                    width: "59%",
                    mx: "10px",
                  }}
                >
                  <CustomSelect
                    isDisabled={basicData?.qualification_id?.disabled}
                    closeMenuOnSelect={false}
                    variant="multiSelect"
                    isMulti={true}
                    onInputChange={handleOnSelectInputChange}
                    onChange={handleOnSelectChange}
                    placeholder={i18n["buildSearch.qualificationPlacement"]}
                    inputId="qualification_id"
                    name="qualification_id"
                    value={
                      basicData?.qualification_id?.field?.length > 0
                        ? basicData?.qualification_id?.field
                        : null
                      // getQualValue()
                    }
                    multiOnFocus={() => {
                      if (qualifications?.length === 0) {
                        getAllData("qualification");
                      }
                      setIsMenuOpen(true);
                    }}
                    isLoading={isLoading}
                    customStyle={selectStyle}
                    multiSelectOptions={qualifications?.map((item) => ({
                      value: item?.qualification_id,
                      label: item?.name,
                    }))}
                  />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    width: "22%",
                    justifyContent: "start",
                    marginLeft: "15px",
                    alignItems: "center",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      marginRight: "40px",
                    }}
                  >
                    <Typography sx={{ fontSize: "12px" }}>broad</Typography>
                    <YellowSwitch
                      onChange={(event) =>
                        handleSwitch(event, "qualification_id")
                      }
                      checked={basicData?.qualification_id?.switch}
                      disabled={basicData?.qualification_id?.disabled}
                    />
                    <Typography sx={{ fontSize: "12px" }}>narrow</Typography>
                  </Box>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Typography sx={{ fontSize: "12px" }}>on</Typography>
                    <GreenSwitch
                      onChange={(event) =>
                        handleGreenSwitch(event, "qualification_id")
                      }
                      checked={basicData?.qualification_id?.disabled}
                    />
                    <Typography sx={{ fontSize: "12px" }}>off</Typography>
                  </Box>
                </Box>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-around",
                  alignItems: "center",
                  mb: 3,
                  width: "98%", // Ensure full width
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    width: "14%",
                    justifyContent: "start", // Center content within the box
                    alignItems: "center",
                    ml: "6px",
                  }}
                >
                  <Typography
                    sx={{
                      padding: "5px",
                      height: "8px",
                      width: "8px",
                      borderRadius: "5px",
                      fontSize: "15px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      fontWeight: 700,
                      border: 1,
                      opacity: 0,
                    }}
                  >
                    i
                  </Typography>
                  <TalentSVGButton
                    color={"white"}
                    source={
                      basicData?.institution_id?.disabled
                        ? infoGrey
                        : instituteSvg
                    }
                    height={30}
                    width={30}
                    startIconMargin={"0px !important"}
                    padding={"0px !important"}
                  />

                  <Typography
                    sx={{
                      fontSize: "12px",
                      fontWeight: 700,
                      width: "100px",
                      textAlign: "left",
                      marginLeft: "15px",
                    }}
                  >
                    Institutions
                  </Typography>
                </Box>
                <Box
                  sx={{
                    // display: "flex",
                    width: "59%",
                    mx: "10px",
                  }}
                >
                  <CustomSelect
                    isDisabled={basicData?.institution_id?.disabled}
                    closeMenuOnSelect={false}
                    variant="multiSelect"
                    isMulti={true}
                    onInputChange={handleOnSelectInputChange}
                    onChange={handleOnSelectChange}
                    placeholder={i18n["buildSearch.institutionsPlacement"]}
                    inputId="institution_id"
                    name="institution_id"
                    value={
                      basicData?.institution_id?.field?.length > 0
                        ? basicData?.institution_id?.field
                        : null

                      // getInstituteValue()
                    }
                    multiOnFocus={() => {
                      if (institution?.length === 0) {
                        getAllData("institution");
                      }
                      setIsMenuOpen(true);
                    }}
                    isLoading={isLoading}
                    customStyle={selectStyle}
                    multiSelectOptions={institution?.map((item) => ({
                      value: item?.institution_id,
                      label: item?.name,
                    }))}
                  />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    width: "22%",
                    justifyContent: "start",
                    marginLeft: "15px",
                    alignItems: "center",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      marginRight: "40px",
                    }}
                  >
                    <Typography sx={{ fontSize: "12px" }}>broad</Typography>
                    <YellowSwitch
                      onChange={(event) =>
                        handleSwitch(event, "institution_id")
                      }
                      checked={basicData?.institution_id?.switch}
                      disabled={basicData?.institution_id?.disabled}
                    />
                    <Typography sx={{ fontSize: "12px" }}>narrow</Typography>
                  </Box>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Typography sx={{ fontSize: "12px" }}>on</Typography>
                    <GreenSwitch
                      onChange={(event) =>
                        handleGreenSwitch(event, "institution_id")
                      }
                      checked={basicData?.institution_id?.disabled}
                    />
                    <Typography sx={{ fontSize: "12px" }}>off</Typography>
                  </Box>
                </Box>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-around",
                  alignItems: "center",
                  mb: 3,
                  width: "98%", // Ensure full width
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    width: "14%",
                    justifyContent: "start", // Center content within the box
                    alignItems: "center",
                    ml: "6px",
                  }}
                >
                  <Typography
                    sx={{
                      padding: "5px",
                      height: "8px",
                      width: "8px",
                      borderRadius: "5px",
                      fontSize: "15px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      fontWeight: 700,
                      border: 1,
                      opacity: 0,
                    }}
                  >
                    i
                  </Typography>
                  <TalentSVGButton
                    color={"white"}
                    source={
                      basicData?.association_id?.disabled
                        ? infoGrey
                        : associationSvg
                    }
                    height={30}
                    width={30}
                    startIconMargin={"0px !important"}
                    padding={"0px !important"}
                  />

                  <Typography
                    sx={{
                      fontSize: "12px",
                      fontWeight: 700,
                      width: "100px",
                      textAlign: "left",
                      marginLeft: "15px",
                    }}
                  >
                    Associations
                  </Typography>
                </Box>

                <Box
                  sx={{
                    // display: "flex",
                    width: "59%",
                    mx: "10px",
                  }}
                >
                  <CustomSelect
                    isDisabled={basicData?.association_id?.disabled}
                    closeMenuOnSelect={false}
                    variant="multiSelect"
                    isMulti={true}
                    onInputChange={handleOnSelectInputChange}
                    onChange={handleOnSelectChange}
                    placeholder={i18n["buildSearch.associationsPlacement"]}
                    inputId="association_id"
                    name="association_id"
                    value={
                      basicData?.association_id?.field?.length > 0
                        ? basicData?.association_id?.field
                        : null

                      // getAssociationValue()
                    }
                    multiOnFocus={() => {
                      if (association?.length === 0) {
                        getAllData("association");
                      }
                      setIsMenuOpen(true);
                    }}
                    isLoading={isLoading}
                    customStyle={selectStyle}
                    multiSelectOptions={association?.map((item) => ({
                      value: item?.association_id,
                      label: item?.name,
                    }))}
                  />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    width: "22%",
                    justifyContent: "start",
                    marginLeft: "15px",
                    alignItems: "center",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      marginRight: "40px",
                    }}
                  >
                    <Typography sx={{ fontSize: "12px" }}>broad</Typography>
                    <YellowSwitch
                      onChange={(event) =>
                        handleSwitch(event, "association_id")
                      }
                      checked={basicData?.association_id?.switch}
                      disabled={basicData?.association_id?.disabled}
                    />
                    <Typography sx={{ fontSize: "12px" }}>narrow</Typography>
                  </Box>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Typography sx={{ fontSize: "12px" }}>on</Typography>
                    <GreenSwitch
                      onChange={(event) =>
                        handleGreenSwitch(event, "association_id")
                      }
                      checked={basicData?.association_id?.disabled}
                    />
                    <Typography sx={{ fontSize: "12px" }}>off</Typography>
                  </Box>
                </Box>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-around",
                  alignItems: "center",
                  mb: 3,
                  width: "98%", // Ensure full width
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    width: "14%",
                    justifyContent: "start", // Center content within the box
                    alignItems: "center",
                    ml: "6px",
                  }}
                >
                  <Typography
                    sx={{
                      padding: "5px",
                      height: "8px",
                      width: "8px",
                      borderRadius: "5px",
                      fontSize: "15px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      fontWeight: 700,
                      border: 1,
                      opacity: 0,
                    }}
                  >
                    i
                  </Typography>
                  <TalentSVGButton
                    color={"white"}
                    source={basicData?.school_id?.disabled ? infoGrey : tool}
                    height={30}
                    width={30}
                    startIconMargin={"0px !important"}
                    padding={"0px !important"}
                  />

                  <Typography
                    sx={{
                      fontSize: "12px",
                      fontWeight: 700,
                      width: "100px",
                      textAlign: "left",
                      marginLeft: "15px",
                    }}
                  >
                    Schools
                  </Typography>
                </Box>

                <Box
                  sx={{
                    // display: "flex",
                    width: "59%",
                    mx: "10px",
                  }}
                >
                  <CustomSelect
                    isDisabled={basicData?.school_id?.disabled}
                    closeMenuOnSelect={false}
                    variant="multiSelect"
                    isMulti={true}
                    onInputChange={handleOnSelectInputChange}
                    onChange={handleOnSelectChange}
                    placeholder={i18n["buildSearch.schoolsPlacements"]}
                    inputId="school_id"
                    name="school_id"
                    value={
                      basicData?.school_id?.field?.length > 0
                        ? basicData?.school_id?.field
                        : null
                      // getSchoolValue()
                    }
                    multiOnFocus={() => {
                      if (school?.length === 0) {
                        getAllData("school");
                      }
                      setIsMenuOpen(true);
                    }}
                    isLoading={isLoading}
                    customStyle={selectStyle}
                    multiSelectOptions={school?.map((item) => ({
                      value: item?.school_id,
                      label: item?.name,
                    }))}
                  />
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    width: "22%",
                    justifyContent: "start",
                    marginLeft: "15px",
                    alignItems: "center",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      marginRight: "40px",
                    }}
                  >
                    <Typography sx={{ fontSize: "12px" }}>broad</Typography>
                    <YellowSwitch
                      onChange={(event) => handleSwitch(event, "school_id")}
                      checked={basicData?.school_id?.switch}
                      disabled={basicData?.school_id?.disabled}
                    />
                    <Typography sx={{ fontSize: "12px" }}>narrow</Typography>
                  </Box>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Typography sx={{ fontSize: "12px" }}>on</Typography>
                    <GreenSwitch
                      onChange={(event) =>
                        handleGreenSwitch(event, "school_id")
                      }
                      checked={basicData?.school_id?.disabled}
                    />
                    <Typography sx={{ fontSize: "12px" }}>off</Typography>
                  </Box>
                </Box>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-around",
                  alignItems: "center",
                  mb: 3,
                  width: "98%", // Ensure full width
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    width: "14%",
                    justifyContent: "start", // Center content within the box
                    alignItems: "center",
                    ml: "6px",
                  }}
                >
                  <Typography
                    sx={{
                      padding: "5px",
                      height: "8px",
                      width: "8px",
                      borderRadius: "5px",
                      fontSize: "15px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      fontWeight: 700,
                      border: 1,
                      opacity: 0,
                    }}
                  >
                    i
                  </Typography>
                  <TalentSVGButton
                    color={"white"}
                    source={basicData?.town_id?.disabled ? infoGrey : townsSvg}
                    height={30}
                    width={30}
                    startIconMargin={"0px !important"}
                    padding={"0px !important"}
                  />

                  <Typography
                    sx={{
                      fontSize: "12px",
                      fontWeight: 700,
                      width: "100px",
                      textAlign: "left",
                      marginLeft: "15px",
                    }}
                  >
                    Towns
                  </Typography>
                </Box>

                <Box
                  sx={{
                    // display: "flex",
                    width: "59%",
                    mx: "10px",
                  }}
                >
                  <CustomSelect
                    isDisabled={basicData?.town_id?.disabled}
                    closeMenuOnSelect={false}
                    variant="multiSelect"
                    isMulti={true}
                    onInputChange={handleOnSelectInputChange}
                    onChange={handleOnSelectChange}
                    placeholder={i18n["buildSearch.townPlaceHolder"]}
                    inputId="town_id"
                    name="town_id"
                    value={
                      basicData?.town_id?.field?.length > 0
                        ? basicData?.town_id?.field
                        : null

                      // getTownValue()
                    }
                    multiOnFocus={() => {
                      if (town?.length === 0) {
                        getAllData("towns");
                      }
                      setIsMenuOpen(true);
                    }}
                    isLoading={isLoading}
                    customStyle={selectStyle}
                    multiSelectOptions={town?.map((item) => ({
                      value: item?.town_id,
                      label: item?.name,
                    }))}
                  />
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    width: "22%",
                    justifyContent: "start",
                    marginLeft: "15px",
                    alignItems: "center",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      marginRight: "40px",
                    }}
                  >
                    <Typography sx={{ fontSize: "12px" }}>broad</Typography>
                    <YellowSwitch
                      onChange={(event) => handleSwitch(event, "town_id")}
                      checked={basicData?.town_id?.switch}
                      disabled={basicData?.town_id?.disabled}
                    />
                    <Typography sx={{ fontSize: "12px" }}>narrow</Typography>
                  </Box>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Typography sx={{ fontSize: "12px" }}>on</Typography>
                    <GreenSwitch
                      onChange={(event) => handleGreenSwitch(event, "town_id")}
                      checked={basicData?.town_id?.disabled}
                    />
                    <Typography sx={{ fontSize: "12px" }}>off</Typography>
                  </Box>
                </Box>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-around",
                  alignItems: "center",
                  mb: 3,
                  width: "98%", // Ensure full width
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    width: "14%",
                    justifyContent: "start", // Center content within the box
                    alignItems: "center",
                    ml: "6px",
                  }}
                >
                  <Typography
                    sx={{
                      padding: "5px",
                      height: "8px",
                      width: "8px",
                      borderRadius: "5px",
                      fontSize: "15px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      fontWeight: 700,
                      border: 1,
                      opacity: 0,
                    }}
                  >
                    i
                  </Typography>
                  <TalentSVGButton
                    color={"white"}
                    source={
                      basicData?.nationality_id?.disabled
                        ? infoGrey
                        : nationalitySvg
                    }
                    height={30}
                    width={30}
                    startIconMargin={"0px !important"}
                    padding={"0px !important"}
                  />

                  <Typography
                    sx={{
                      fontSize: "12px",
                      fontWeight: 700,
                      width: "100px",
                      textAlign: "left",
                      marginLeft: "15px",
                    }}
                  >
                    Nationality
                  </Typography>
                </Box>
                <Box
                  sx={{
                    // display: "flex",
                    width: "59%",
                    mx: "10px",
                  }}
                >
                  <CustomSelect
                    isDisabled={basicData?.nationality_id?.disabled}
                    closeMenuOnSelect={false}
                    variant="multiSelect"
                    isMulti={true}
                    filterOption={"filter"}
                    onInputChange={handleOnSelectInputChange}
                    onChange={handleOnSelectChange}
                    placeholder={i18n["buildSearch.nationalityPlaceholder"]}
                    inputId="nationality_id"
                    name="nationality_id"
                    value={
                      basicData?.nationality_id?.field?.length > 0
                        ? basicData?.nationality_id?.field
                        : null
                      // getNationalityValue()
                    }
                    multiOnFocus={() => {
                      if (nationality?.length === 0) {
                        getAllData("nationality");
                      }
                      setIsMenuOpen(true);
                    }}
                    isLoading={isLoading}
                    customStyle={selectStyle}
                    multiSelectOptions={nationality?.map((item) => ({
                      value: item?.nationality_id,
                      label: item?.nationality,
                    }))}
                  />
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    width: "22%",
                    justifyContent: "start",
                    marginLeft: "15px",
                    alignItems: "center",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      marginRight: "40px",
                    }}
                  >
                    <Typography sx={{ fontSize: "12px" }}>broad</Typography>
                    <YellowSwitch
                      onChange={(event) =>
                        handleSwitch(event, "nationality_id")
                      }
                      checked={basicData?.nationality_id?.switch}
                      disabled={basicData?.nationality_id?.disabled}
                    />
                    <Typography sx={{ fontSize: "12px" }}>narrow</Typography>
                  </Box>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Typography sx={{ fontSize: "12px" }}>on</Typography>
                    <GreenSwitch
                      onChange={(event) =>
                        handleGreenSwitch(event, "nationality_id")
                      }
                      checked={basicData?.nationality_id?.disabled}
                    />
                    <Typography sx={{ fontSize: "12px" }}>off</Typography>
                  </Box>
                </Box>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-around",
                  alignItems: "center",
                  mb: 3,
                  width: "98%", // Ensure full width
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    width: "14%",
                    justifyContent: "start", // Center content within the box
                    alignItems: "center",
                    ml: "6px",
                  }}
                >
                  <Typography
                    sx={{
                      padding: "5px",
                      height: "8px",
                      width: "8px",
                      borderRadius: "5px",
                      fontSize: "15px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      fontWeight: 700,
                      border: 1,
                      opacity: 0,
                    }}
                  >
                    i
                  </Typography>
                  <TalentSVGButton
                    color={"white"}
                    source={basicData?.language_id?.disabled ? infoGrey : link}
                    height={30}
                    width={30}
                    startIconMargin={"0px !important"}
                    padding={"0px !important"}
                  />

                  <Typography
                    sx={{
                      fontSize: "12px",
                      fontWeight: 700,
                      width: "100px",
                      textAlign: "left",
                      marginLeft: "15px",
                    }}
                  >
                    Language
                  </Typography>
                </Box>
                <Box
                  sx={{
                    // display: "flex",
                    width: "59%",
                    mx: "10px",
                  }}
                >
                  <CustomSelect
                    isDisabled={basicData?.language_id?.disabled}
                    closeMenuOnSelect={false}
                    variant="multiSelect"
                    filterOption={"filter"}
                    isMulti={true}
                    onInputChange={handleOnSelectInputChange}
                    onChange={handleOnSelectChange}
                    placeholder={i18n["buildSearch.languagePlaceholder"]}
                    inputId="language_id"
                    name="language_id"
                    value={
                      basicData?.language_id?.field?.length > 0
                        ? basicData?.language_id?.field
                        : null
                      // getLangValue()
                    }
                    multiOnFocus={() => {
                      if (language?.length === 0) {
                        getAllData("language");
                      }
                      setIsMenuOpen(true);
                    }}
                    isLoading={isLoading}
                    customStyle={selectStyle}
                    multiSelectOptions={language?.map((item) => ({
                      value: item?.language_id,
                      label: item?.language,
                    }))}
                  />
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    width: "22%",
                    justifyContent: "start",
                    marginLeft: "15px",
                    alignItems: "center",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      marginRight: "40px",
                    }}
                  >
                    <Typography sx={{ fontSize: "12px" }}>broad</Typography>
                    <YellowSwitch
                      onChange={(event) => handleSwitch(event, "language_id")}
                      checked={basicData?.language_id?.switch}
                      disabled={basicData?.language_id?.disabled}
                    />
                    <Typography sx={{ fontSize: "12px" }}>narrow</Typography>
                  </Box>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Typography sx={{ fontSize: "12px" }}>on</Typography>
                    <GreenSwitch
                      onChange={(event) =>
                        handleGreenSwitch(event, "language_id")
                      }
                      checked={basicData?.language_id?.disabled}
                    />
                    <Typography sx={{ fontSize: "12px" }}>off</Typography>
                  </Box>
                </Box>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-around",
                  alignItems: "center",
                  mb: 3,
                  width: "98%", // Ensure full width
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    width: "14%",
                    justifyContent: "start", // Center content within the box
                    alignItems: "center",
                    ml: "6px",
                  }}
                >
                  <Typography
                    sx={{
                      padding: "5px",
                      height: "8px",
                      width: "8px",
                      borderRadius: "5px",
                      fontSize: "15px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      fontWeight: 700,
                      border: 1,
                      opacity: 0,
                    }}
                  >
                    i
                  </Typography>
                  <TalentSVGButton
                    color={"white"}
                    source={
                      basicData?.experience?.disabled ? infoGrey : experienceSvg
                    }
                    height={30}
                    width={30}
                    startIconMargin={"0px !important"}
                    padding={"0px !important"}
                  />

                  <Typography
                    sx={{
                      fontSize: "12px",
                      fontWeight: 700,
                      width: "100px",
                      textAlign: "left",
                      marginLeft: "15px",
                    }}
                  >
                    Experience
                  </Typography>
                </Box>
                <Box
                  sx={{
                    // display: "flex",
                    width: "59%",
                    mx: "10px",
                  }}
                >
                  <Slider
                    disabled={basicData?.experience?.disabled ? true : false}
                    disableSwap
                    valueLabelDisplay="on"
                    sx={{
                      marginTop: 4,
                      width: "98%",
                      ml: 3,
                      "& .MuiSlider-rail": {
                        backgroundColor: theme.palette.SliderBackground,
                        height: "10px",
                      },
                      "& .MuiSlider-track": {
                        height: "10px",
                      },
                      "& .MuiSlider-thumb": {
                        borderRadius: "15%",
                        background: "white",
                      },
                      "& .MuiSlider-valueLabel": {
                        fontSize: 12,
                        fontWeight: "normal",
                        top: -6,
                        backgroundColor: theme.palette.grayBackground,
                        borderRadius: 1,
                        color: theme.palette.text.primary,
                        "&:before": {
                          display: "none",
                        },
                        "& *": {
                          background: "transparent",
                          color:
                            theme.palette.mode === "dark" ? "#fff" : "#000",
                        },
                      },
                    }}
                    name="experience"
                    getAriaLabel={() => "Temperature range"}
                    value={expRange}
                    onChange={expHandleChange}
                    color="blueButton800"
                    valueLabelFormat={rangeValueExperience}
                    getAriaValueText={rangeValueExperience}
                    min={0}
                    max={20}
                    step={1}
                    marks={marks}
                  />
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    width: "22%",
                    justifyContent: "start",
                    marginLeft: "15px",
                    alignItems: "center",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      marginRight: "40px",
                    }}
                  >
                    <Typography sx={{ fontSize: "12px" }}>broad</Typography>
                    <YellowSwitch
                      onChange={(event) => handleSwitch(event, "experience")}
                      checked={basicData?.experience?.switch}
                      disabled={basicData?.experience?.disabled}
                    />
                    <Typography sx={{ fontSize: "12px" }}>narrow</Typography>
                  </Box>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Typography sx={{ fontSize: "12px" }}>on</Typography>
                    <GreenSwitch
                      onChange={(event) =>
                        handleGreenSwitch(event, "experience")
                      }
                      checked={basicData?.experience?.disabled}
                    />
                    <Typography sx={{ fontSize: "12px" }}>off</Typography>
                  </Box>
                </Box>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-around",
                  alignItems: "center",
                  mb: 3,
                  width: "98%", // Ensure full width
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    width: "14%",
                    justifyContent: "start", // Center content within the box
                    alignItems: "center",
                    ml: "6px",
                  }}
                >
                  <Typography
                    sx={{
                      padding: "5px",
                      height: "8px",
                      width: "8px",
                      borderRadius: "5px",
                      fontSize: "15px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      fontWeight: 700,
                      border: 1,
                      opacity: 0,
                    }}
                  >
                    i
                  </Typography>
                  <TalentSVGButton
                    color={"white"}
                    source={basicData?.salary?.disabled ? infoGrey : salarySvg}
                    height={30}
                    width={30}
                    startIconMargin={"0px !important"}
                    padding={"0px !important"}
                  />

                  <Typography
                    sx={{
                      fontSize: "12px",
                      fontWeight: 700,
                      width: "100px",
                      textAlign: "left",
                      marginLeft: "15px",
                    }}
                  >
                    Salary
                  </Typography>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    width: "59%",
                    mx: "10px",
                  }}
                >
                  <Box sx={{ marginRight: "15px" }}>
                    <Box sx={{ marginBottom: "5px" }}>
                      <SelectMenu
                        disabled={basicData?.salary?.disabled ? true : false}
                        clear={true}
                        border={"1px solid #E0E0E0"}
                        borderRadius="10px"
                        height="40px"
                        width={"100px"}
                        name="currency_id"
                        value={basicData?.currency_id}
                        onHandleChange={handleCurrencyDropDown}
                        options={currency}
                        placeholder={"Select..."}
                      />
                    </Box>

                    <SelectMenu
                      disabled={basicData?.salary?.disabled ? true : false}
                      clear={true}
                      border={"1px solid #E0E0E0"}
                      borderRadius="10px"
                      height="40px"
                      width={"100px"}
                      name="salaryType"
                      value={basicData?.salaryType}
                      onHandleChange={handleCurrencyDropDown}
                      options={salaryTypeList}
                      placeholder={"Select..."}
                    />
                  </Box>

                  <Slider
                    disabled={basicData?.salary?.disabled ? true : false}
                    disableSwap
                    valueLabelDisplay="on"
                    name="salary"
                    getAriaLabel={() => "Temperature range"}
                    value={rangeValue}
                    onChange={
                      basicData?.salaryType == "rate"
                        ? handleRangeSliderChange2
                        : handleRangeSliderChange1
                    }
                    color="salarySlider"
                    valueLabelFormat={
                      basicData?.salaryType == "rate"
                        ? rangeValueHandler2
                        : rangeValueHandler
                    }
                    getAriaValueText={
                      basicData?.salaryType == "rate"
                        ? rangeValueHandler2
                        : rangeValueHandler
                    }
                    marks={salaryRange}
                    // marks={
                    //   basicData.job_role_type == "freelance"
                    //     ? BASIC_RANGEMARKS2
                    //     : salaryRange
                    // }
                    min={0}
                    max={salaryRange?.length - 1}
                    step={0.1}
                    sx={{
                      marginTop: 4,
                      width: "90%",
                      // ml: 1,
                      "& .MuiSlider-rail": {
                        backgroundColor: theme.palette.SliderBackground,
                        height: "10px",
                      },
                      "& .MuiSlider-track": {
                        height: "10px",
                      },
                      "& .MuiSlider-thumb": {
                        borderRadius: "15%",
                        background: "white",
                      },
                      "& .MuiSlider-valueLabel": {
                        fontSize: 12,
                        fontWeight: "normal",
                        top: -6,
                        backgroundColor: theme.palette.grayBackground,
                        borderRadius: 1,
                        color: theme.palette.text.primary,
                        "&:before": {
                          display: "none",
                        },
                        "& *": {
                          background: "transparent",
                          color:
                            theme.palette.mode === "dark" ? "#fff" : "#000",
                        },
                      },
                    }}
                  />
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    width: "22%",
                    justifyContent: "start",
                    marginLeft: "15px",
                    alignItems: "center",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      marginRight: "40px",
                    }}
                  >
                    <Typography sx={{ fontSize: "12px" }}>broad</Typography>
                    <YellowSwitch
                      onChange={(event) => handleSwitch(event, "salary")}
                      checked={basicData?.salary?.switch}
                      disabled={basicData?.salary?.disabled}
                    />
                    <Typography sx={{ fontSize: "12px" }}>narrow</Typography>
                  </Box>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Typography sx={{ fontSize: "12px" }}>on</Typography>
                    <GreenSwitch
                      onChange={(event) => handleGreenSwitch(event, "salary")}
                      checked={basicData?.salary?.disabled}
                    />
                    <Typography sx={{ fontSize: "12px" }}>off</Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          )}

          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              mt: 6,
            }}
          >
            <Button
              sx={{
                boxShadow: 0,
                fontSize: "14px",
                borderRadius: "20px 0 0 0 !important",
                height: "43px",
                padding: 3,
                color: "#000",
              }}
              variant="contained"
              color="grayButton200"
              width="fit-content"
              onClick={resetSearch}
            >
              reset search
            </Button>
            <Button
              sx={{
                boxShadow: 0,
                fontSize: "14px",
                borderRadius: "0px !important",
                height: "43px",
                padding: 3,
              }}
              variant="contained"
              color="yellowButton100"
              width="fit-content"
              onClick={() => {
                setIsModalOpen(true);
              }}
            >
              save search
            </Button>
            {openSearch && (
              <>
                <Button
                  sx={{
                    boxShadow: 0,
                    fontSize: "14px",
                    borderRadius: "0px !important",
                    height: "43px",
                    padding: 3,
                  }}
                  variant="contained"
                  id="broad"
                  aria-controls={anchorEl ? "broad-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={anchorEl ? "true" : undefined}
                  color="recruitButton"
                  width="fit-content"
                  ref={buttonRef}
                  onClick={(e) => handleClick(e)}
                >
                  link to pool
                </Button>
                <Popover
                  id="dropdown-menu"
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                  sx={{
                    borderRadius: "25px",
                    "& .MuiPaper-root-MuiPopover-paper": {
                      minWidth: "18% !important",
                      borderRadius: "20px !important",
                      mt: 2,
                    },
                    "& .MuiPopover-paper": {
                      borderRadius: "25px !important",
                    },
                  }}
                >
                  <AddToPool
                    talentData={tableData}
                    setAnchorEl={setAnchorEl}
                    addToPool={addToPool}
                    userID={""}
                    getTalent={getTalent}
                    lastKey={lastKey}
                    totalPoolData={totalPoolData}
                    removeFromPool={removeLinkedJobFromPool}
                    setTableData={setTableData}
                    poolIDRef={poolIDRef}
                    setButtonDisable={setButtonDisable}
                    apiPayload={{
                      isFirstQuery: findFirstKeyWithNonEmptyField(basicData),
                      data: searchPayloadCreator(basicData),
                      ...renameKeys(advanceFilter),
                    }}
                  />
                </Popover>
              </>
            )}

            <Button
              sx={{
                boxShadow: 0,
                fontSize: "14px",
                width: "130px !important",
                borderRadius: "0 20px 0 0 !important",
                height: "43px",
                padding: 3,
              }}
              variant="contained"
              color="redButton100"
              onClick={() => {
                if (!openSearch) {
                  handleEditSearch();
                  handleSearch();
                  setOpenSearch(true);
                } else {
                  setAccordionList([]);
                  setOpenSearch(false);
                  setPageNumber(0);
                }
              }}
            >
              {`${!openSearch ? "let's go" : "edit search"}`}
            </Button>
          </Box>
        </Paper>

        {openSearch && (
          <Paper
            sx={{
              p: 3,
              mt: 4,
              borderRadius: "20px",
              width: `${!rightExpand ? "89.25%" : "97%"}`,
            }}
          >
            {/* {isTextLoading && <Loader />} */}
            {accordionList?.length === 0 ? (
              <Box
                sx={{
                  textAlign: "center",
                  fontWeight: 600,
                }}
              >
                {showTest
                  ? "No talents with your search criteria please edit your parameter to find proper matching talents."
                  : ""}
              </Box>
            ) : (
              <BuildSearchAccordion
                titlesList={accordionList}
                basicData={basicData}
                handleSearch={handleSearch}
                advanceFilter={advanceFilter}
              />
            )}
          </Paper>
        )}
        {isModalOpen && (
          <SearchSaveModal
            handleOpen={isModalOpen}
            handleClose={() => setIsModalOpen(false)}
            handleSubmit={handleSearchSaveSubmit}
            modalName={modalName}
            setModalName={setModalName}
            searchID={searchID}
          />
        )}
        {isLinkedToPoolModal && (
          <LinkedToPoolModal
            handleOpen={isLinkedToPoolModal}
            handleClose={() => setIsLinkedToPoolModal(false)}
            handleLinkedPoolSave={handleLinkedPoolSave}
            handleLinkedPoolCancel={handleLinkedPoolCancel}
            talentName={location?.state?.talentName}
          />
        )}
      </Box>
    </>
  );
}
