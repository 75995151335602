import React, { useRef, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  IconButton,
  Typography,
  useTheme,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { setAlert } from "../../../redux/configSlice";
import { ALERT_TYPE } from "../../../utils/Constants";
import {
  deleteCandidateJobVideo,
  uploadJobCamVideo,
} from "../../../redux/candidate/candidateJobs";
import CounterDialog from "./dialog/CounterDialog";
import RecordingInProgress from "./dialog/RecordingInProgress";
import Delete from "./dialog/Delete";
import BriefBox from "./dialog/BriefBox";

import jobVideoicon from "../../../assets/CrayBotIcons/Crayon Job Video Character.svg";

import myVideoHeaderLogo from "../../../assets/Cam/JobVideoIcon.svg";
import uploadNewVideoIcon from "../../../assets/Cam/UploadVideoIcon.svg";
import PlayButton from "../../common/PlayButton";
import deleteIcon from "../../../assets/Cam/DeleteIcon.svg";
import { getVideoFileDuration } from "../../../utils/Common";
import useViewport from "../../common/ViewPort";
import CloseIcon from "../../common/CloseIcon";
import JobVideoStepBrief from "./dialog/JobVideoStepBrief";
import jobVideoHeaderLogo from "../../../assets/Cam/JobVideoIcon.svg";
import lockItInIcon from "../../../assets/Cam/MyVideoIcon.svg";
import takeTwoIcon from "../../../assets/Cam/TakeTwoIcon.svg";

const JobVideoUpload = ({
  show,
  closeFunc,
  job_id,
  reloadVideo,
  setAllJobList,
  job,
  setOpenRecordDialog,
  setSteps,
  steps,
  setActiveStep,
}) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const VideoRef = useRef(null);
  const { width } = useViewport();
  // const [steps, setSteps] = useState(1);
  const mediaRecorderRef = useRef(null);
  const [mediaStream, setMediaStream] = useState(null);
  const [recordedVideoData, setRecordedVideoData] = useState(null);
  const [videoLink, setVideoLink] = useState("");
  const [openDelete, setOpenDelete] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [disabledButton, setDisabledButton] = useState(true);
  const [isPlaying, setIsPlaying] = useState(false);
  const [showBrief, setShowBrief] = useState(false);
  const [videoBlob, setVideoBlob] = useState("");
  const [textChange, setTextChange] = useState(
    recordedVideoData ? true : false
  );

  const fileAccept = "video/*";
  const hiddenFileInput = useRef(null);

  const buttonSize = 60;
  const triangleSize = 25;

  const handleCloseBriefBox = () => {
    setShowBrief(false);
  };

  const validateFileSize = (file) => {
    if (file.size > 100000000) {
      dispatch(
        setAlert({
          show: true,
          type: ALERT_TYPE.ERROR,
          msg: "Video size should be less than 100MB",
        })
      );
      return false;
    }
    return true;
  };

  const handleDeleteVideo = async () => {
    try {
      const { payload } = await dispatch(
        deleteCandidateJobVideo({ job_id: job_id })
      );
      if (payload?.status === "success") {
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE.SUCCESS,
            msg: "Job video removed successfully!",
          })
        );
        handleCancelDelete();
      }
    } catch (error) {}
  };

  const uploadVideo = async (file, isUpload) => {
    getVideoFileDuration(file, async (error, videoDuration, fileType) => {
      if (error) {
        setVideoLink("")
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE?.ERROR,
            msg: error,
          })
        );
        return;
      } else if (
        file?.name?.split(".")?.pop()?.toLowerCase() !== "mp4" &&
        isUpload &&
        file?.name?.split(".")?.pop()?.toLowerCase() !== "mov" &&
        isUpload &&  
        file?.name?.split(".")?.pop()?.toLowerCase() !== "webm" &&
        isUpload
      ) {
        setVideoLink("")
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE?.ERROR,
            msg: "Please upload .mp4 or .mov file",
          })
        );
        return;
      } else if (videoDuration > 60 && isUpload) {
        setVideoLink("")
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE?.ERROR,
            msg: "Video length should be less than 1 min",
          })
        );
        return;
      } else if (file?.size > 100000000) {
        setVideoLink("")
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE?.ERROR,
            msg: "Video size should be less than 100MB",
          })
        );
        return;
      }

      const formData = new FormData();
      const myFile = new File([file], "videoFile.webm", {
        type: file.type,
      });
      formData.append("jobcam", myFile);
      formData.append("job_id", job_id);

      try {
        const { payload } = await dispatch(uploadJobCamVideo(formData));
        if (payload?.status === "success") {
          setSteps(4);
          setTextChange(true);
          setAllJobList((prev) =>
            prev.map((item) =>
              item.job_id === job_id
                ? {
                    ...item,
                    job_cam_url: URL.createObjectURL(file),
                    JobCamVideo: true,
                  }
                : item
            )
          );
          dispatch(
            setAlert({
              show: true,
              type: ALERT_TYPE.SUCCESS,
              msg: "Video uploaded Successfully!",
            })
          );
          reloadVideo(URL.createObjectURL(file));
          // closeFunc();
          setSteps(4);
        } else {
          dispatch(
            setAlert({
              show: true,
              type: ALERT_TYPE.ERROR,
              msg: "Failed to upload Video",
            })
          );
        }
      } catch (error) {
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE.ERROR,
            msg: "Failed to upload Video",
          })
        );
      }
    });
  };

  const handleFileChange = (event) => {
    console.log(event,"event")
    const selectedFile = event.target.files[0];

    if (!selectedFile) {
      return;
    }

    if (validateFileSize(selectedFile)) {
      const link = URL.createObjectURL(selectedFile);
      setVideoLink(link);
      uploadVideo(selectedFile, true);
    }
  };
  const handleFileClick = () => {
    // hiddenFileInput.current.click();
    uploadVideo(videoBlob, false);
    setActiveStep(3);
    setOpenRecordDialog(false);
  };
const handleUploadFileClick = () =>{
    hiddenFileInput.current.click();
}
  // Callback function to receive recorded video data
  const handleVideoData = (videoData) => {
    setRecordedVideoData(videoData);
  };

  const handleOpenDelete = () => {
    closeFunc();
    setOpenDelete((prevState) => !prevState);
    setConfirmDelete(false);
  };
  const handleCancelDelete = () => {
    setOpenDelete((prevState) => !prevState);
    setConfirmDelete(false);
  };

  const handleStopRecording = () => {
    if (
      mediaRecorderRef.current &&
      mediaRecorderRef.current.state === "recording"
    ) {
      mediaRecorderRef.current.stop();
    }
  };

  const handleCloseCamera = () => {
    if (mediaStream) {
      mediaStream.getTracks().forEach((track) => {
        track.stop();
      });
      setMediaStream(null);
    }
  };

  const handleClose = () => {
    if (steps === 1 || steps === 2) {
      closeFunc();
    } else if (steps === 3) {
      setSteps(4);
    }

    handleStopRecording();
    handleCloseCamera();
  };

  return (
    <>
      <Dialog
        open={show}
        fullWidth={true}
        onClose={() => setOpenRecordDialog(false)}
        // maxWidth={"md"}
        sx={{
          "& .MuiPaper-root": {
            borderRadius: "25px",
            maxWidth: "760px",
            height: { xs: "auto", md: "739px" },
          },
        }}
      >
        <DialogTitle
          sx={{
            padding: 0,
          }}
        >
          <IconButton
            aria-label="close"
            onClick={() => {
              handleClose();
              // nextStep(4);
            }}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          ></IconButton>
          <Box>
            <CloseIcon
              onClick={() => {
                if(steps ===1){
                  setOpenRecordDialog(false);
                }else{
                  setSteps(1)
                }
              }}
            />
          </Box>
        </DialogTitle>
        {steps === 1 && (
          <>
            <Button
              variant="contained"
              color="peachPayment"
              sx={{
                borderRadius: "25px 0 10px 0",
                width: "150px",
                height: "45px",
                fontSize: "14px",

                fontWeight: theme.typography.Bold,
                gap: "10px",
              }}
            >
              <Box
                component={"img"}
                src={myVideoHeaderLogo}
                sx={{
                  height: "20px",
                  width: "24px",
                }}
              />
              job video
            </Button>

            <Box
              sx={{
                padding: "30px 20px 31px",
                flexDirection: "column",
                alignItems: "center",
                display: "flex",
                gap: "27px",
                flex: "1",
              }}
            >
              <Box
                sx={{
                  width: { xs: "90%", md: "70%" },
                  flexDirection: "column",
                  alignItems: "center",
                  display: "flex",
                  gap: "20px",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "20px",
                    fontWeight: theme.typography.Bold,
                    textAlign: "center",
                  }}
                >
                  Roll cameras…
                </Typography>
                {/* <Typography
                  sx={{
                    fontSize: "14px",
                    textAlign: "center",
                    width: {xs:"90%",md:"453px"},
                    fontWeight: theme.typography.Regular,
                  }}
                >
                  This is your chance to attract top talent with a brief summary
                  video of the job.
                </Typography> */}
              </Box>

              <Box
                sx={{
                  width: { xs: "90%", md: "500px" },
                  height: { xs: "auto", md: "auto" },
                  flexGrow: 1,
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <Box sx={{}}>
                  <Box
                    component={"img"}
                    src={jobVideoicon}
                    sx={{
                      height: {
                        xs: "200px",
                        xs360: "240px",
                        xs390: "266px",
                        md: "359px",
                      },
                      width: "auto",
                      border: "2px solid #C9CAD8",
                      borderRadius: "25px 25px 0 0",
                      borderBottom: "none",
                      marginBottom: "-6px",
                    }}
                  />
                  <Box
                    sx={{
                      display: "flex",
                      width: "100%",
                      background: theme.palette.mainBackground,
                      borderRadius: "0 0 25px 25px",
                      height: "60px",
                    }}
                  >
                    <Box
                      sx={{
                        width: { xs: "60%", md: "70%" },
                        margin: "auto",
                        padding: "0 0 0 20px ",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "15px",
                          fontWeight: theme.typography.Bold,
                        }}
                      >
                        {job?.title}
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "13px",
                          fontWeight: theme.typography.Regular,
                        }}
                      >
                        {job?.company?.name}
                      </Typography>
                    </Box>
                    <Button
                      variant="contained"
                      color="videoButton"
                      sx={{
                        width: { xs: "40%", md: "30%" },
                        fontSize: "14px",
                        height: "100%",
                        borderRadius: "0 0 25px 0",
                      }}
                      onClick={() => setShowBrief(true)}
                    >
                      the brief
                    </Button>

                    {showBrief && (
                      // <BriefBox
                      //   show={showBrief}
                      //   handleOpen={handleCloseBriefBox}
                      // />
                      <JobVideoStepBrief 
                       content={job?.video_description}
                       handleOpen={handleCloseBriefBox} 
                       show={showBrief}/>
                    )}
                  </Box>
                </Box>

                <Box
                  sx={{
                    width: { xs: "90%", md: "70%" },
                    flexDirection: "column",
                    alignItems: "center",
                    display: "flex",
                    // gap: "20px",
                    marginTop: "30px",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "14px",
                      textAlign: "center",
                      width: { xs: "90%", md: "453px" },
                      fontWeight: theme.typography.Regular,
                    }}
                  >
                    Review the employer brief and record your elevator pitch for
                    the job you’ve applied to or been shortlisted for.
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "14px",
                      textAlign: "center",
                      width: { xs: "90%", md: "453px" },
                      fontWeight: theme.typography.Regular,
                      marginTop:"10px"
                    }}
                  >
                   Note: Videos need to be max 1 minute and under 100mb.
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
              }}
            >
              <input
                accept={fileAccept}
                ref={hiddenFileInput}
                type="file"
                onChange={handleFileChange}
                style={{ display: "none" }}
              />
              <Button
                variant="contained"
                color="uploadVideoButton"
                sx={{
                  width: "50%",
                  height: "60px",
                  borderRadius: "0 0 0px 25px",
                  fontSize: "15px",
                  gap: "10px",
                  fontWeight: theme.typography.Bold,
                }}
                onClick={handleUploadFileClick}
              >
                <Box
                  component={"img"}
                  src={uploadNewVideoIcon}
                  sx={{
                    height: "20px",
                    width: "24px",
                    display: { xs: "none", tablet: "block" },
                  }}
                />
                upload video
              </Button>
              <Button
                variant="contained"
                color="videoButton"
                sx={{
                  width: "50%",
                  height: "60px",
                  borderRadius: "0 0 25px 0px",
                  fontSize: "14px",
                  gap: "8px",
                  fontWeight: theme.typography.Bold,
                }}
                onClick={() => setSteps(2)}
              >
                
                {/* {width > 767 && <RadioButtonChecked />} */}
                {(width > 767 || width === 0) && (
                <Box
                  component={"img"}
                  src={jobVideoHeaderLogo}
                  sx={{
                    height: "20px",
                    width: "24px",
                  }}
                />
              )}
                record video
              </Button>
            </Box>
          </>
        )}
        {steps === 2 && <CounterDialog nextStep={setSteps} name={"job"} page="jobVideo" />}
        {steps === 3 && (
          <Box
            sx={{
              width: "100%",
              height: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <RecordingInProgress
              nextStep={setSteps}
              onRecordingStop={handleVideoData}
              uploadFunc={uploadVideo}
              name={"job"}
              handleStopRecording={handleStopRecording}
              handleCloseCamera={handleCloseCamera}
              setMediaStream={setMediaStream}
              mediaStream={mediaStream}
              mediaRecorderRef={mediaRecorderRef}
              setRecordedVideoData={setRecordedVideoData}
              setVideoBlob={setVideoBlob}
              setTextChange={setTextChange}
              page="jobVideo"
            />
          </Box>
        )}
        {steps === 4 && (
          <>
            <Button
              variant="contained"
              color="jobVideoButton"
              sx={{
                borderRadius: "25px 0 10px 0",
                width: "150px",
                height: "45px",
                fontSize: "14px",
                fontWeight: theme.typography.Bold,
                gap: "10px",
              }}
            >
              <Box
                component={"img"}
                src={myVideoHeaderLogo}
                sx={{
                  height: "20px",
                  width: "24px",
                }}
              />
              job video
            </Button>
            <Box
              sx={{
                width: "70%",
                margin: "auto",
                flexDirection: "column",
                alignItems: "center",
                display: "flex",
                gap: "20px",
                marginTop: "30px",
              }}
            >
              <Typography
                sx={{
                  fontSize: "20px",
                  fontWeight: theme.typography.Bold,
                  textAlign: "center",
                }}
              >
                You nailed it!
              </Typography>
              {/* <Typography
                sx={{
                  fontSize: "14px",
                  textAlign: "center",

                  fontWeight: theme.typography.Regular,
                }}
              >
                Your video has been successfully recorded. Hollywood awaits!
              </Typography>
              <Typography
                sx={{
                  fontSize: "14px",
                  textAlign: "center",

                  fontWeight: theme.typography.Regular,
                }}
              >
                Review, re-record or upload a new video below.
              </Typography> */}
            </Box>
            <Box
              sx={{
                flexGrow: 1,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: "30px",
                flexDirection: "column",
                position: "relative",
              }}
            >
              {!isPlaying && (
                <PlayButton
                  buttonSize={buttonSize}
                  triangleSize={triangleSize}
                  top={"calc(50% - 100px)"}
                />
              )}
              <video
                ref={VideoRef}
                preload="auto"
                loops
                autoPlay={false}
                style={{
                  width: disabledButton ? "60%" : "60%",
                  borderRadius: "25px 25px 0 0",
                  height: "auto",
                  aspectRatio: "4/3",
                  objectFit: "cover",
                  cursor: disabledButton ? "wait" : "pointer",
                  border: disabledButton
                    ? `1px solid ${theme.palette.grayButton600.main}`
                    : "none",
                  borderBottom: "none", // To remove the border for the bottom side
                }}
                src={videoLink || recordedVideoData}
                type="video/mp4"
                controls={false}
                onClick={() => {
                  if (!disabledButton) {
                    if (VideoRef.current.paused) {
                      VideoRef.current.play();
                      setIsPlaying(true);
                    } else {
                      VideoRef.current.pause();
                      setIsPlaying(false);
                    }
                  }
                }}
                onLoadedData={() => {
                  setDisabledButton(false);
                }}
                onEnded={() => setIsPlaying(false)}
              ></video>
              <Box
                sx={{
                  display: "flex",
                  width: "60%",
                  background: theme.palette.mainBackground,
                  borderRadius: "0 0 25px 25px",
                  height: "60px",
                }}
              >
                <Box
                  sx={{
                    width: "70%",
                    margin: "auto",
                    padding: "0 0 0 20px ",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "15px",
                      fontWeight: theme.typography.Bold,
                    }}
                  >
                    {job?.title}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "13px",
                      fontWeight: theme.typography.Regular,
                    }}
                  >
                    {job?.company?.name}
                  </Typography>
                </Box>
                <Button
                  variant="contained"
                  color="videoButton"
                  sx={{
                    width: "30%",
                    height: "100%",
                    borderRadius: "0 0 25px 0",
                  }}
                  onClick={() => setShowBrief(true)}
                >
                  the brief
                </Button>
                {showBrief && (
                  <BriefBox show={showBrief} handleOpen={handleCloseBriefBox} />
                )}
              </Box>
              <Box
                sx={{
                  width: "70%",
                  margin: "auto",
                  flexDirection: "column",
                  alignItems: "center",
                  display: "flex",
                  gap: "20px",
                  marginTop: "30px",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "14px",
                    textAlign: "center",

                    fontWeight: theme.typography.Regular,
                  }}
                >
                  Your job application video has been successfully recorded.
                  Hollywood awaits!
                </Typography>
                <Typography
                  sx={{
                    fontSize: "14px",
                    textAlign: "center",

                    fontWeight: theme.typography.Regular,
                  }}
                >
                 Review your view and then lock it in, or try again if you weren’t happy with the first take.
                </Typography>
              </Box>
            </Box>

            <Box
              sx={{
                margin: "auto",
                width: "100%",
                height: "60px",
              }}
            >
              <Button
                variant="contained"
                color="deleteVideoButton"
                sx={{
                  borderRadius: "0 0 0 25px",
                  width: "33.33%",
                  height: "100%",
                  gap: "8px",
                }}
                onClick={handleOpenDelete}
              >
                <Box
                  component={"img"}
                  src={deleteIcon}
                  sx={{ height: 18, display: { xs: "none", tablet: "block" } }}
                />
                delete
              </Button>
              <Button
                  variant="contained"
                  color="yoco"
                  sx={{
                    borderRadius: 0,
                    width: "33.33%",
                    height: "100%",
                    gap: "8px",
                  }}
                  onClick={() => setSteps(2)}
                >
                  {/* {width > 767 && <RadioButtonChecked />} */}
                  {
                    (width > 767 || width ===0) && 
                    <Box
                    component={"img"}
                    src={takeTwoIcon}
                    sx={{
                      height: "20px",
                      width: "24px",
                    }}
                  />
                  }
                  {/* record new video */}
                  take two
                </Button>
              <input
                accept={fileAccept}
                ref={hiddenFileInput}
                type="file"
                onChange={handleFileChange}
                style={{ display: "none" }}
              />
              <Button
                  variant="contained"
                  color="nandos"
                  sx={{
                    borderRadius: "0 0 25px 0",
                    width: "33.33%",
                    height: "100%",
                    gap: "8px",
                  }}
                  onClick={handleFileClick}
                >
                  <Box
                    component={"img"}
                    src={lockItInIcon}
                    sx={{
                      height: "20px",
                      width: "24px",
                      display: { xs: "none", tablet: "block" },
                    }}
                  />
                  lock it in
                </Button>
            </Box>
          </>
        )}
      </Dialog>
      <Delete
        show={openDelete}
        handleOpen={handleOpenDelete}
        handleCancel={handleCancelDelete}
        handleDelete={handleDeleteVideo}
        confirmDelete={confirmDelete}
        setConfirmDelete={setConfirmDelete}
      />
    </>
  );
};

export default JobVideoUpload;
