import { Box, Button, IconButton, Tooltip, Typography } from "@mui/material";
import React from "react";
import locale from "../../../i18n/locale";
import { useTheme } from "@emotion/react";
import { LightTooltip } from "../../../utils/CommonComponent";
import notificationBot from "../../../assets/Characters/CrayBotIcons/NotificationBot.svg";
import settings from "../../../assets/Padding Excluded/White_Settings.svg";
import CloseIcon from "../../common/CloseIcon";

export default function SpoilerAlert({
  width,
  rightExpand,
  handleClose,
  setOpenSetting,
  spoilerRef,
}) {
  const i18n = locale.en;
  const theme = useTheme();
  return (
    <Box
      ref={spoilerRef}
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        // width: `${width}px`,
        width: width,
        // height: "556px",
        height: "auto",
        background: "#ffff",
        borderRadius: "0 0 25px 25px",
        boxShadow: "0px 5px 10px #00000029 !important",
        zIndex: 1000,
        position: "absolute",
        // padding: "0px 0px 0 20px",
        right: 0,
        boxSizing: "border-box",
        marginRight: !rightExpand && "119px",
      }}
    >
      <Button
        variant="contained"
        color="recentButton"
        startIcon={
          <Box
            component="img"
            className="eye"
            alt="eye logo"
            src={settings}
            sx={{
              height: 18,
              width: 18,
            }}
          />
        }
        sx={{
          width: "150px",
          minWidth: "150px",
          maxWidth: "150px",
          height: "45px",
          borderRadius: "0px 0px 10px 0px",
          fontSize: "14px",
          fontWeight: theme.typography.Bold,
          textAlign: "left",
          justifyContent: "space-between",
          // gap: 1,
        }}
      >
        {i18n["alert.alertsetting"]}
        {/* <Tooltip disableInteractive>
          <LightTooltip title={""} placement="right-end">
            <Typography
              sx={{
                padding: "5px",
                height: "8px",
                width: "8px",
                borderRadius: "5px",
                fontSize: "15px",
                lineHeight: 0.1,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontWeight: 700,
                background: "white",
                color: "black",
              }}
            >
              i
            </Typography>
          </LightTooltip>
        </Tooltip> */}
      </Button>

      <IconButton
        aria-label="close"
        onClick={() => handleClose()}
        sx={{
          position: "absolute",
          right: 8,
        }}
        disableRipple={true}
      >
        {/* <Box
          sx={{
            width: "20px",
            height: "20px",
            color: "#000000",
            border: 2,
            fontSize: "18px",
            borderRadius: "5px",
          }}
        >
          X
        </Box> */}
        <CloseIcon />
      </IconButton>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems:"center"
        }}
      >
        <Box
          component={"img"}
          src={notificationBot}
          sx={{
            width: "75px",
            height: "125px",
            margin: "0 auto",
          }}
        />
        <Typography
          sx={{
            marginTop:"15px",
            fontSize: "20px",
            fontWeight: theme.typography.Bold,
            textAlign: "center",
          }}
        >
          {i18n["alert.spoileralert"]}
        </Typography>
        <Box sx={{paddingX:"10px",maxWidth:"320px",textAlign:"center"}}>
          <Typography
            sx={{
            marginTop:"15px",
              fontSize: "16px",
              fontWeight: theme.typography.Bold,
              textAlign: "center",
            }}
          >
            …you’ll receive emails based on your job alert settings.
          </Typography>
          <Typography
            sx={{
              fontSize: "16px",
            marginTop:"15px",
              fontWeight: theme.typography.Regular,
              textAlign: "center",
            }}
          >
            New jobs where your match % exceeds your selected level, will
            trigger an alert email. You can also build your own custom alerts.
          </Typography>
          <Typography
            sx={{
              fontSize: "16px",
            marginTop:"15px",
              fontWeight: theme.typography.Bold,
              textAlign: "center",
            }}
          >
            Emails received as system alerts are not marketing and cannot be
            unsubscribed from. Rather hop onto your dashboard and toggle off
            alerts.
          </Typography>
          {/* <Typography
            sx={{
              fontSize: "18px",
              fontWeight: theme.typography.Regular,
              textAlign: "center",
            }}
          >
            {i18n["alert.newjobswhereyourmatch1"]}
          </Typography> */}
          {/* <Typography
            sx={{
              fontSize: "18px",
              fontWeight: theme.typography.Regular,
              textAlign: "center",
            }}
          >
            {i18n["alert.newjobswhereyourmatch2"]}
          </Typography>
          <Typography
            sx={{
              fontSize: "18px",
              fontWeight: theme.typography.Regular,
              textAlign: "center",
            }}
          >
            {i18n["alert.newjobswhereyourmatch3"]}
          </Typography> */}
        </Box>
        {/* <Typography
          sx={{
            fontSize: "18px",
            fontWeight: theme.typography.Bold,
            textAlign: "center",
          }}
        >
          {i18n["alert.emailsreceived"]}
        </Typography>
        <Typography
          sx={{
            fontSize: "18px",
            fontWeight: theme.typography.Bold,
            textAlign: "center",
          }}
        >
          {i18n["alert.emailsreceived1"]}
        </Typography>
        <Typography
          sx={{
            fontSize: "18px",
            fontWeight: theme.typography.Bold,
            textAlign: "center",
          }}
        >
          {i18n["alert.emailsreceived2"]}
        </Typography>
        <Typography
          sx={{
            fontSize: "18px",
            fontWeight: theme.typography.Bold,
            textAlign: "center",
          }}
        >
          {i18n["alert.emailsreceived3"]}
        </Typography> */}
      </Box>
      <Box>
        <Button
          variant="contained"
          color="closeButton"
          sx={{
            width: "100%",
            height: "60px",
            marginTop:"15px",
            minHeight: "60px",
            maxHeight: "60px",
            borderRadius: "0 0 25px 25px",
            fontSize: "14px",
            fontWeight: theme.typography.Bold,
            color: "black",
          }}
          onClick={() => {
            setOpenSetting(true);
            handleClose();
          }}
        >
          {i18n["alert.back"]}
        </Button>
      </Box>
    </Box>
  );
}
