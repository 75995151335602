import { Box, Slider, Typography, useTheme } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { textValue, useQuery } from "../../utils/Common";
import { EXPERIENCE } from "../../utils/Constants";

export default function ExperienceBox({
  setExperience,
  buttonID,
  handleClosePopover,
  handleFilterSelection,
  setIsFilterApplied,
  getTalentMyJobStatusCount,
  setBasicData,
  onFilterSelection,
}) {
  const [expRange, setExpRange] = useState([0, 2]);
  const theme = useTheme();
  const query = useQuery();
  const { jobId } = useParams();

  const experienceRef = useRef(expRange);
  useEffect(() => {
    // Update the ref whenever the state changes
    experienceRef.current =
      JSON.stringify(expRange) === JSON.stringify([0, 2]) ? [] : expRange;
    experienceRef.current.length && setExperience(experienceRef.current);
  }, [expRange]);

  const expHandleChange = async (event, newValue) => {
    // onFilterSelection();
    setExpRange(newValue);
    setIsFilterApplied(true);

    setBasicData((prevBasicData) => ({
      ...prevBasicData,
      experience: [newValue[0], newValue[1]],
    }));

    handleFilterSelection(
      "experience",
      newValue ? [newValue[0], newValue[1]] : "experience"
    );

    setTimeout(async () => {
      newValue.length > 0 && onFilterSelection();
      newValue.length > 0 && handleClosePopover(buttonID);
      newValue.length > 0 && (await getTalentMyJobStatusCount(jobId));
    }, 5000);

    // handleClosePopover(buttonID);
    // await getTalentMyJobStatusCount(jobId);

    // navigate(
    //   `/employer/manage-talent/${jobId}?gender=${
    //     query.get("gender") === null ? "" : query.get("gender")
    //   }&race=${
    //     query.get("race") === null ? "" : query.get("race")
    //   }&qualification=${
    //     query.get("qualification") === null ? "" : query.get("qualification")
    //   }&salary=${
    //     query.get("salary") === null ? "" : query.get("salary")
    //   }&experience=${[newValue[0], newValue[1]]}`
    // );
  };

  useEffect(() => {
    let experienceParams;
    if (query.get("experience") !== null || query.get("experience") !== "") {
      experienceParams = query.get("experience")?.split(",");
    }
    if (query.get("experience")?.length > 0) {
      setExpRange([experienceParams[0], experienceParams[1]]);
    }
  }, []);
  return (
    <Box
      sx={{
        width: "1000px",
      }}
    >
      <Typography sx={{ fontWeight: 500 }}>Year's work experience</Typography>
      <Slider
        disableSwap
        valueLabelDisplay="on"
        sx={{
          marginTop: 4,
          width: "95%",
          marginLeft: "30px !important",
          "& .MuiSlider-rail": {
            backgroundColor: theme.palette.eyeview100.main,
            height: "10px",
          },
          "& .MuiSlider-track": {
            height: "10px",
          },
          "& .MuiSlider-thumb": {
            borderRadius: "15%",
            background: "white",
          },
          "& .MuiSlider-valueLabel": {
            fontSize: 12,
            fontWeight: "normal",
            top: -6,
            backgroundColor: theme.palette.grayBackground,
            borderRadius: 1,
            color: theme.palette.text.primary,
            "&:before": {
              display: "none",
            },
            "& *": {
              background: "transparent",
              color: theme.palette.mode === "dark" ? "#fff" : "#000",
            },
          },
        }}
        name="experience"
        getAriaLabel={() => "Temperature range"}
        value={expRange}
        onChange={expHandleChange}
        color="blueButton800"
        valueLabelFormat={textValue}
        getAriaValueText={textValue}
        min={0}
        max={20}
        step={1}
        marks={EXPERIENCE}
      />
    </Box>
  );
}
