import {
  Box,
  Button,
  CircularProgress,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import locale from "../../../../../i18n/locale";
import { setAlert } from "../../../../../redux/configSlice";
import { ALERT_TYPE } from "../../../../../utils/Constants";
import { useDispatch } from "react-redux";
import { getCrayonCoinsHistory } from "../../../../../redux/admin/maintenance";
import InfiniteScroll from "react-infinite-scroll-component";

export default function CoinHistoryBox({
  company_id,
  coins,
  setCompanyTopUp,
  setCoinHistory,
  coinHistoryRef,
}) {
  const i18n = locale.en;
  const theme = useTheme();
  const dispatch = useDispatch();
  const [lastKey, setLastKey] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [coinsHistory, setCoinsHistory] = useState([]);

  const getCoinsHistory = async (lastKeyy) => {
    setIsLoading(true);
    try {
      const { payload } = await dispatch(
        getCrayonCoinsHistory({ lastKey: lastKeyy, company_id: company_id })
      );
      if (payload?.status === "success") {
        if (payload?.pageNumber === 0) {
          setCoinsHistory(payload?.data);
          setLastKey(payload?.pageNumber + 1);
        } else if (payload?.data?.length > 0) {
          setCoinsHistory((prev) => [...prev, ...payload?.data]);
          setLastKey(payload?.pageNumber + 1);
        }
      } else {
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE?.ERROR,
            msg: payload?.message,
          })
        );
      }
      setIsLoading(false);
    } catch (error) {
      dispatch(
        setAlert({
          show: true,
          type: ALERT_TYPE?.ERROR,
          msg: error,
        })
      );
    }
  };

  useEffect(() => {
    getCoinsHistory(0);
  }, []);

  return (
    <>
      <Button
        variant="contained"
        color="getCoins"
        sx={{ width: "150px", height: "45px", borderRadius: "25px 0 25px 0" }}
      >
        coin history
      </Button>
      <Typography
        sx={{
          fontSize: "12px",
          fontWeight: theme.typography.Regular,
          padding: "18px 24px 22px 17px",
        }}
      >
        {i18n["adminUsers.coinHistoryText"]}
      </Typography>
      <Box sx={{ padding: "0 0 0 18px" }}>
        <Typography
          sx={{
            fontSize: "14px",
            fontWeight: theme.typography.Bold,
            width: "91%",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          {i18n["myplan.mycoinbalance"]}
          {
            <Button
              variant="contained"
              color="getCoins"
              sx={{
                minWidth: "fit-content",
                height: "24px",
                borderRadius: "8px",
                padding: "12px !important",
              }}
            >
              {coins}
            </Button>
          }
        </Typography>
        <Typography
          sx={{
            fontSize: "14px",
            fontWeight: theme.typography.Bold,
            width: "91%",
            display: "flex",
            justifyContent: "space-between",
            my: "10px",
          }}
        >
          {i18n["myplan.mycoinhistory"]}
          {
            <span
              style={{ fontSize: "14px", fontWeight: theme.typography.Bold }}
            ></span>
          }
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "15px",
            width: "97%",
            mt: "8px",
            overflowY: "auto",
            height: "285px",
            "::-webkit-scrollbar": {
              height: "5px",
              background: "#DCDEEB",
              display: "unset !important",
            },
          }}
          id="matchTaleneList"
        >
          <InfiniteScroll
            style={{
              overflowX: "hidden",
              scrollbarWidth: "thin",
              display: "flex",
              flexDirection: "column",
              gap: "16px",
              width: "93%",
            }}
            dataLength={coinsHistory?.length || 0}
            next={() => getCoinsHistory(lastKey)}
            hasMore={true}
            scrollableTarget="matchTaleneList"
            scrollThreshold={"50px"}
          >
            {coinsHistory?.length > 0 ? (
              coinsHistory?.map((item) => {
                return (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontWeight: theme.typography.Regular,
                      }}
                    >
                      {item?.transaction_name?.name}
                    </Typography>

                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontWeight: theme.typography.Bold,
                      }}
                    >
                      {item?.transaction_type
                        ? "+"
                        : item?.coins > 0
                        ? "-"
                        : ""}
                      {item?.coins}
                    </Typography>
                  </Box>
                );
              })
            ) : isLoading ? (
              <Box
                style={{
                  margin: "15px 0 0 0",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CircularProgress color="inherit" size={20} />
              </Box>
            ) : (
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: theme.typography.Bold,
                  }}
                >
                  No coins history
                </Typography>
              </Box>
            )}
          </InfiniteScroll>
        </Box>
      </Box>
      <Box>
        <Button
          variant="contained"
          color="talentButton"
          sx={{
            width: "100%",
            height: "60px",
            borderRadius: "0 0 25px 25px",
          }}
          onClick={() => {
            setCoinHistory(null);
            setCompanyTopUp(coinHistoryRef?.current);
          }}
        >
          {i18n["adminUsers.addcoins"]}
        </Button>
      </Box>
    </>
  );
}
