import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import SearchBox from "./Dialogbox/SearchBox";
import AddCoinSpend from "./Dialogbox/AddCoinSpend";
import InfiniteScroll from "react-infinite-scroll-component";
import {
  ALERT_TYPE,
  COINS_STATUS,
  COIN_HISTORY,
} from "../../../utils/Constants";
import logo from "../../../assets/Crayon_Favicon.svg";
import {
  TableCells,
  ToggleCheckedSwitch,
  ToggleSwitch,
} from "../../../utils/CommonComponent";
import CompanyCoins from "./Dialogbox/CompanyCoins";
import CoinsPrice from "./Dialogbox/CoinsPrice";
import SpendItems from "./Dialogbox/SpendItems";
import SelectMenu from "../../common/SelectMenu";
import ConfirmationPopUp from "./Dialogbox/ConfirmationPopUp";
import {
  changePaymentStatus,
  getCoins,
  getPaymentHistory,
  togglePaymentSwitch,
} from "../../../redux/admin/maintenance";
import { useDispatch } from "react-redux";
import { setAlert } from "../../../redux/configSlice";
import { dateConverterMonth } from "../../../utils/DateTime";
import CompanyPaymentInfo from "./Dialogbox/CompanyPaymentInfo";

export default function PaymentHistory() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [addCoinSpend, setAddCoinSpend] = useState(false);
  const [coinsHistory, setCoinsHistory] = useState([]);
  const [companyInfo, setCompanyInfo] = useState(false);
  const [companyPaymentInfo, setCompanyPaymentInfo] = useState();
  const [confirm, setConfirm] = useState(false);
  const [info, setInfo] = useState({});
  const [lastKey, setLastKey] = useState(0);

  const handleText = async (event) => {
    // setSearchTitle(event?.target?.value);
    // debouncedHandleOnTextSearch(event);
  };

  const getPaymentInfo = async (lastKeyy) => {
    try {
      const { payload } = await dispatch(
        getPaymentHistory({ lastKey: lastKeyy })
      );
      if (payload?.status === "success") {
        if (payload?.pageNumber === 0) {
          setCoinsHistory(payload?.data);
          setLastKey(payload?.pageNumber + 1);
        } else if (payload?.data?.length > 0) {
          setCoinsHistory((prev) => [...prev, ...payload?.data]);
          setLastKey(payload?.pageNumber + 1);
        }
      } else {
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE?.ERROR,
            msg: payload?.message,
          })
        );
      }
    } catch (error) {
      dispatch(
        setAlert({
          show: true,
          type: ALERT_TYPE?.ERROR,
          msg: error,
        })
      );
    }
  };

  const handleSelectChangePaid = async (event, row, id, name) => {
    await setCoinsHistory(
      coinsHistory?.map((item) => {
        return item?.payment_transaction_id ===
          info?.row?.payment_transaction_id
          ? {
              ...item,
              paid: !info?.row?.paid,
            }
          : item;
      })
    );
    await handleToggle("paid", !info?.row?.paid, info?.row);
    setConfirm(false);
  };

  const handleToggle = async (key, value, row) => {
    const payload = {
      payment_transaction_id: row?.payment_transaction_id,
      [key]: value,
    };
    try {
      const response = await dispatch(togglePaymentSwitch(payload));
      if (response?.payload?.status === "success") {
      }
    } catch (error) {
      dispatch(
        setAlert({
          show: true,
          type: ALERT_TYPE?.ERROR,
          msg: error,
        })
      );
    }
  };

  const handleSelectChange = async (event, row, id, name) => {
    await setCoinsHistory(
      coinsHistory?.map((item) => {
        return item?.payment_transaction_id === row?.payment_transaction_id
          ? { ...item, invoiced: id }
          : item;
      })
    );
    await handleToggle("invoiced", id, row);
  };

  const handleChangeStatus = async (event, row) => {
    try {
      const {
        target: { value },
        target: { name },
        target: { id },
      } = event;
      const statusName = COINS_STATUS?.find((item) => item?.id === value)?.id;
      setCoinsHistory(
        coinsHistory?.map((item) => {
          return item?.payment_transaction_id === row?.payment_transaction_id
            ? { ...item, transaction_status_id: statusName }
            : item;
        })
      );
      let data = {
        payment_transaction_id: row?.payment_transaction_id,
        coins: row?.coins,
        employer_id: row?.user_id,
        company_id: row?.company_id,
        transaction_name_id: row?.transaction_name_id,
        approved: true,
      };
      const { payload } = await dispatch(changePaymentStatus(data));

      if (payload?.status === "success") {
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE?.SUCCESS,
            msg: "Payment status updated successfully",
          })
        );
        // setCoinsHistory(
        //   coinsHistory?.map((item) => {
        //     return item?.payment_transaction_id === row?.payment_transaction_id
        //       ? { ...item, transaction_status_id: statusName }
        //       : item;
        //   })
        // );
      } else {
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE?.ERROR,
            msg: payload?.message,
          })
        );
      }
    } catch (error) {
      dispatch(
        setAlert({
          show: true,
          type: ALERT_TYPE?.ERROR,
          msg: error,
        })
      );
    }
  };

  useEffect(() => {
    getPaymentInfo(0);
  }, []);

  return (
    <>
      <Box
        sx={{
          background: "white",
          borderRadius: "25px",
          mt: "45px",
          boxShadow: `0px 5px 10px ${theme?.palette?.boxShadow?.main}`,
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Box sx={{ display: "flex", gap: "26px", width: "675px" }}>
            <Button
              variant="contained"
              color="redButton"
              sx={{
                width: "150px",
                height: "45px",
                borderRadius: "25px 0 25px 0",
                padding: "0px",
              }}
            >
              payment history
            </Button>

            <SearchBox
              onChange={(event) => handleText(event)}
              width="500px"
              margin="5px 0 0 0"
              placeholder="search for a transaction or company"
            />
          </Box>
          {/*<Button
          variant="contained"
          color="yellowButton400"
          sx={{
            width: "150px",
            height: "45px",
            borderRadius: "0 25px 0 25px",
          }}
          onClick={() => setAddCoinSpend(true)}
        >
          add coin spend
        </Button>*/}
        </Box>
        <Box>
          <InfiniteScroll
            style={{ overflow: "hidden" }}
            dataLength={coinsHistory?.length}
            next={() => getPaymentInfo(lastKey)}
            scrollThreshold={"100px"}
            scrollableTarget={"jobList"}
            hasMore={true}
            endMessage={
              <p style={{ textAlign: "center" }}>
                <b>Yay! You have seen it all</b>
              </p>
            }
          >
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCells>
                      <Typography variant="subtitle1" fontWeight="bold">
                        Payment ID
                      </Typography>
                    </TableCells>
                    <TableCells></TableCells>
                    <TableCells>
                      <Typography variant="subtitle1" fontWeight="bold">
                        Company
                      </Typography>
                    </TableCells>

                    <TableCells>
                      <Typography
                        variant="subtitle1"
                        fontWeight="bold"
                        textAlign={"center"}
                      >
                        User
                      </Typography>
                    </TableCells>
                    <TableCells>
                      <Typography
                        variant="subtitle1"
                        fontWeight="bold"
                        textAlign={"center"}
                      >
                        Item
                      </Typography>
                    </TableCells>
                    <TableCells>
                      <Typography
                        variant="subtitle1"
                        fontWeight="bold"
                        textAlign={"center"}
                      >
                        ID
                      </Typography>
                    </TableCells>
                    <TableCells>
                      <Typography
                        variant="subtitle1"
                        fontWeight="bold"
                        textAlign={"center"}
                      >
                        Coins
                      </Typography>
                    </TableCells>
                    <TableCells>
                      <Typography
                        variant="subtitle1"
                        fontWeight="bold"
                        textAlign={"center"}
                      >
                        Date
                      </Typography>
                    </TableCells>
                    <TableCells>
                      <Typography
                        variant="subtitle1"
                        fontWeight="bold"
                        textAlign={"center"}
                      >
                        Cost
                      </Typography>
                    </TableCells>
                    <TableCells>
                      <Typography
                        variant="subtitle1"
                        fontWeight="bold"
                        textAlign={"center"}
                      >
                        Invoiced
                      </Typography>
                    </TableCells>
                    <TableCells>
                      <Typography
                        variant="subtitle1"
                        fontWeight="bold"
                        textAlign={"center"}
                      >
                        Paid
                      </Typography>
                    </TableCells>
                    <TableCells>
                      <Typography
                        variant="subtitle1"
                        fontWeight="bold"
                        textAlign={"center"}
                      >
                        Status
                      </Typography>
                    </TableCells>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {coinsHistory?.map((row) => (
                    <TableRow
                      key={row?.payment_transaction_id}
                      style={{
                        "& .css-12zgwp-MuiTableCell-root": {
                          padding: "0 16px !important",
                        },
                      }}
                    >
                      <TableCells
                        sx={{
                          fontWeight: theme.typography.Bold,
                          padding: "22px 16px 10px 30px",
                        }}
                      >
                        {row?.payment_transaction_id}
                      </TableCells>
                      <TableCells>
                        {
                          <Box
                            component={"img"}
                            src={
                              row?.company?.profile_url === "No URL"
                                ? logo
                                : row?.company?.profile_url
                            }
                            sx={{ height: 40, width: 40, borderRadius: "10px" }}
                          />
                        }
                      </TableCells>
                      <TableCells
                        sx={{
                          fontWeight: theme.typography.Bold,
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setCompanyPaymentInfo(row);
                          //   setCompanyInfo(true);
                        }}
                      >
                        {row?.company?.name}
                      </TableCells>
                      <TableCells sx={{ textAlign: "center" }}>
                        {row?.user?.first_name} {row?.user?.last_name}
                      </TableCells>
                      <TableCells sx={{ textAlign: "center" }}>
                        {row?.transaction_name?.name}
                      </TableCells>
                      <TableCells sx={{ textAlign: "center" }}>
                        {row?.transaction_name_id}
                      </TableCells>
                      <TableCells sx={{ textAlign: "center" }}>
                        {row?.coins}
                      </TableCells>
                      <TableCells sx={{ textAlign: "center" }}>
                        {dateConverterMonth(row?.created_at)}
                      </TableCells>
                      <TableCells sx={{ textAlign: "center" }}>
                        {row?.currency?.symbol}
                        {row?.cost}
                      </TableCells>

                      <TableCells sx={{ textAlign: "center" }}>
                        {
                          <ToggleCheckedSwitch
                            checkedColor={"recentButton"}
                            notCheckedColor={"employerButton"}
                            checked={row?.invoiced}
                            onChange={(event, id, name) =>
                              handleSelectChange(event, row, id, name)
                            }
                          />
                        }
                      </TableCells>
                      <TableCells sx={{ textAlign: "center" }}>
                        {
                          <ToggleCheckedSwitch
                            checkedColor={
                              row?.transaction_status?.name === "Paid"
                                ? "recentButton"
                                : "talentButton"
                            }
                            notCheckedColor={"employerButton"}
                            checked={row?.paid}
                            onChange={(event, id, name) =>
                              // handleSelectChangePaid(event, row, id, name)
                              {
                                if (!row?.paid) {
                                  setConfirm(true);
                                  setInfo({ row, id, switch: "paid" });
                                }
                              }
                            }
                          />
                        }
                      </TableCells>
                      <TableCells
                        sx={{ display: "flex", justifyContent: "center" }}
                      >
                        {
                          <SelectMenu
                            readOnly={row?.transaction_status_id === 4}
                            clear={false}
                            name="transaction_status_id"
                            value={row?.transaction_status_id}
                            onHandleChange={(e) => handleChangeStatus(e, row)}
                            options={COINS_STATUS}
                            placeholder={"Job stage"}
                            borderRadius="10px !important"
                            sx={{
                              width: "120px !important",
                              textAlign: "center",
                            }}
                          />
                        }
                      </TableCells>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </InfiniteScroll>
        </Box>
      </Box>
      <ConfirmationPopUp
        handleOpen={confirm}
        handleClose={() => {
          setConfirm(false);
        }}
        // info={info}
        handleYes={() => handleSelectChangePaid()}
      />
      {companyInfo && (
        <CompanyPaymentInfo
          companyInfo={companyInfo}
          handleClose={() => setCompanyInfo(false)}
          companyPaymentInfo={companyPaymentInfo}
        />
      )}
    </>
  );
}
