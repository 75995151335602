import { useEffect, useState } from "react";
import { useTheme } from "@mui/material/styles";
import job_volume from "../../../assets/Padding Excluded/Crayon Icon_Promote.svg";
import job_exp from "../../../assets/Padding Included/Green_Duration.svg";
import downArrow from "../../../assets/Black_Down_Open - Copy.svg";
import upArrow from "../../../assets/Black_Up_Close - Copy.svg";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Unstable_Grid2";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import ButtonMenu from "../myJobsSectionEmployerAndRecruiter/ButtonMenu";
import {
  convertDatetimeAgo,
  dateMonthConverter,
} from "../../../utils/DateTime";
import { Card, Tooltip } from "@mui/material";
import { Link } from "react-router-dom";
import { formatCurrencyWithCommas } from "../../../utils/Currency";
import videoPlaceholder from "../../../assets/VideoPlaceholder.png";
import deleteIcon from "../../../assets/Cam/DeleteIcon.svg";
import whiteRecord from "../../../assets/Cam/VideoRecordingIcon.svg";

import BlueCurrency from "../../../assets/Blue_Salary.svg";
import redLocation from "../../../assets/Red_Location.svg";
import calendar from "../../../assets/Padding Included/Yellow_Calendar.svg";
import TrackButton from "../myJobsSectionEmployerAndRecruiter/TrackButton";
import profileIcon from "../../../assets/Padding Excluded/ProfileIconNew.svg";
import TrackButtonLayout from "../myJobsSectionEmployerAndRecruiter/TrackButtonLayout";
import { daysRemainingToComplete30Days } from "../../../utils/DateTime";
import { Circle } from "@mui/icons-material";
import { useRef } from "react";
import EmployerJobVideoUpload from "./EmployerJobVideoUpload";
import PlayButton from "../PlayButton";
import { deleteEmployerJobVideo } from "../../../redux/employer/postJobSlice";
import { useDispatch } from "react-redux";
import Delete from "./dialog/Delete";
import { setAlert } from "../../../redux/configSlice";
import { ALERT_TYPE } from "../../../utils/Constants";
import locale from "../../../i18n/locale";
import { renderColor } from "../../../utils/Common";
import ReviewVideoDialog from "../ReviewVideoDialog";
import YouTubeEmbed from "../../guest/contact/YouTubeEmbed";

export default function EmployerVideoCard({
  job,
  allVideoJobs,
  setAllJobList,
  setActiveStep,
  allJobsList,
}) {
  const i18n = locale.en;
  const theme = useTheme();
  const [buttonMenu, setButtonMenu] = useState(false);
  const [trackButton, setTrackButton] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [disabledButton, setDisabledButton] = useState(true);
  const [openDelete, setOpenDelete] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [videoLink, setVideoLink] = useState(job?.job_video_url);
  const VideoRef = useRef(null);
  const [openRecordDialog, setOpenRecordDialog] = useState(false);
  const [steps, setSteps] = useState();
  const [openReviewVideoDialog, setOpenReviewVideoDialog] = useState(false);

  const dispatch = useDispatch();

  const handleCloseDialog = () => {
    setOpenReviewVideoDialog(false);
  };


  const handleOpenRecordDialog = () => {
    setSteps(1);
    setOpenRecordDialog((prevState) => !prevState);
  };

  const handleVideoUploaded = (uploadedVideoLink) => {
    setVideoLink(uploadedVideoLink);
    VideoRef?.current?.load();
  };

  const handleOpenDelete = () => {
    setOpenDelete((prevState) => !prevState);
    setConfirmDelete(false);
  };
  const handleCancelDelete = () => {
    setOpenDelete((prevState) => !prevState);
    setConfirmDelete(false);
  };

  const handleDeleteVideo = async () => {
    try {
      const { payload } = await dispatch(
        deleteEmployerJobVideo({ job_id: job?.job_id })
      );
      if (payload?.status === "success") {
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE?.SUCCESS,
            msg: "Job video removed successfully!",
          })
        );
        handleCancelDelete();
        setAllJobList((prev) =>
          prev?.map((item) =>
            item.job_id === job?.job_id
              ? {
                  ...item,
                  linkFlag: false,
                  job_video_url: "No URL",
                  job_video_link: "No URL",
                }
              : item
          )
        );
        setVideoLink("No URL");
      } else if (payload?.status === "error") {
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE?.ERROR,
            msg: "Server Error",
          })
        );
      }
    } catch (error) {}
  };

  return (
    <>
      <Card
        sx={{
          borderRadius: "25px",
          flexGrow: 1,
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "column",
          height: "100%",
          boxShadow: "0px 5px 10px #00000029",
        }}
        className="sliderClass"
      >
        <Grid
          container
          justifyContent="space-between"
          overflow={"hidden"}
          sx={{
            borderRadius: "25px 25px 0 0",
            width: "100%",
            flexWrap: "nowrap",
          }}
        >
          <Box sx={{ flexGrow: 1 }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Box sx={{}}>
                <Button
                  color="accessListButton"
                  sx={{
                    borderRadius: "25px 0 10px 0",
                    padding: 0,
                    height: "45px",
                    width: "45px",
                    minWidth: "45px",
                    maxWidth: "45px",
                    border: "none",
                  }}
                  onClick={() => {
                    setTrackButton(false);
                    setButtonMenu((prevState) => !prevState);
                  }}
                  variant="contained"
                >
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <Box
                      component={"img"}
                      src={profileIcon}
                      sx={{
                        height: 20,
                        width: 20,
                      }}
                    />
                    <Box
                      component={"img"}
                      src={buttonMenu ? upArrow : downArrow}
                      sx={{
                        height: 20,
                        width: 20,
                      }}
                    />
                  </Box>
                </Button>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  width: "180px",
                }}
              >
                <Box sx={{ display: "flex" }}>
                  <Button
                    color={`${job?.job_type}Button`}
                    variant="contained"
                    sx={{
                      height: "auto",
                      // minWidth: { xs: "40xp", lg: "50px", xl: "60px" },
                      minWidth: "60px",
                      maxWidth: "60px",
                      minHeight: "45px",
                      borderRadius: "0 0 0 10px",
                      padding: 0,
                      // fontSize: { xs: "8px", lg: "10px", xl: "12px" },
                      fontSize: "12px",
                      fontWeight: theme.typography.Bold,
                      color: theme.jobCard.jobType.recruit.contrastText,
                      background:
                        job?.job_type !== null &&
                        job?.job_type?.includes("Recruit")
                          ? theme.jobCard.jobType.recruit.main
                          : theme.jobCard.jobType.direct.main,
                      cursor: "default",
                      ":hover": {
                        boxShadow: 0,
                        background:
                          job?.job_type !== null &&
                          job?.job_type?.includes("Recruit")
                            ? theme.jobCard.jobType.recruit.main
                            : theme.jobCard.jobType.direct.main,
                      },
                    }}
                  >
                    {job?.job_type}
                  </Button>
                  <Button
                    sx={{
                      height: "auto",
                      // minWidth: { xs: "40xp", lg: "50px", xl: "60px" },
                      maxWidth: "70px",
                      minHeight: "45px",
                      width: "60px",
                      borderRadius: 0,
                      padding: 0,
                      // fontSize: { xs: "8px", lg: "10px", xl: "12px" },
                      fontSize: "12px",
                      fontWeight: theme.typography.Bold,
                      background:
                        theme.manageTalent?.[renderColor(job?.stage_name)]
                          ?.main,
                      color: "white",
                      cursor: "default",
                      ":hover": {
                        background:
                          theme.manageTalent?.[renderColor(job?.stage_name)]
                            ?.main,
                      },
                    }}
                  >
                    {job?.stage_name || "-"}
                  </Button>
                  <Button
                    variant="contained"
                    color="announceButton"
                    sx={{
                      height: "45px",
                      // minWidth: { xs: "40px", lg: "50px", xl: "60px" },
                      minWidth: "60px",
                      maxHeight: { xs: "60px" },
                      maxWidth: { xs: "60px" },
                      borderRadius: 0,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      ":hover": { boxShadow: 0 },
                    }}
                  >
                    <Box
                      component="img"
                      sx={{
                        height: 30,
                        width: 30,
                        maxHeight: { xs: 30 },
                        maxWidth: { xs: 30 },
                      }}
                      alt="job_volume"
                      src={job_volume}
                    />
                  </Button>
                </Box>
                {!trackButton && !buttonMenu && (
                  <Tooltip
                    disableInteractive
                    title={`posted ${convertDatetimeAgo(job?.created_at)} ago`}
                    placement="top"
                  >
                    <Typography
                      sx={{
                        fontWeight: theme.typography.Regular,
                        fontSize: 12,
                        width: "100%",
                        whiteSpace: "nowrap", // Prevents text from wrapping
                        overflow: "hidden", // Hides any overflowing content
                        textOverflow: "ellipsis",
                        color: "#C9CAD8",

                        // paddingBottom: "8px",
                      }}
                    >
                      {i18n["empMyCam.posted"]}{" "}
                      {convertDatetimeAgo(job?.created_at)} ago
                    </Typography>
                  </Tooltip>
                )}
              </Box>
            </Box>

            {trackButton && (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "end",
                  paddingLeft: 2,
                  paddingTop: "5px",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: theme.typography.Bold,
                  }}
                >
                  {i18n["empMyCam.whatsJobStatus"]}
                </Typography>
              </Box>
            )}
            {buttonMenu && (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "end",
                  paddingLeft: 2,
                  paddingTop: "5px",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: theme.typography.Bold,
                  }}
                >
                  {i18n["empMyCam.whoCanAccess"]}
                </Typography>
              </Box>
            )}
          </Box>
          <Box
            sx={{
              alignItems: "center",
              display: "flex",
              // width: { xs: "80px", lg: "90px", xl: "120px" },
              width: "60px",
              flexDirection: "column",
              // border: !trackButton
              //   ? `1px solid ${theme.palette.chart.gray} `
              //   : "none",
              borderTop: 0,
              borderRight: 0,
              borderRadius: "0 0px 0px 10px",
              height: "70px",
            }}
          >
            <Button
              variant="contained"
              sx={{
                boxShadow: "none !important",
                height: "45px !important",
                borderRadius: 0,
                width: "100%",
                display: "flex",
                flexDirection: "column",
              }}
              onClick={() => {
                setButtonMenu(false);
                setTrackButton((prevState) => !prevState);
              }}
              color="greyPlayButton"
            >
              <TrackButtonLayout
                daysReamining={daysRemainingToComplete30Days(job?.created_at)}
              />
              <Box
                component={"img"}
                src={trackButton ? upArrow : downArrow}
                sx={{
                  height: 25,
                  width: 25,
                }}
              />
            </Button>
            <Typography
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 1,
                fontWeight: theme.typography.Bold,
                fontSize: "14px",
                // margin: "auto",
                marginRight: "20px",
              }}
            >
              {job?.job_status || "Status"}{" "}
              <Circle fontSize="string" color={`${job?.job_status}Status`} />
            </Typography>
          </Box>
        </Grid>

        {buttonMenu && (
          <ButtonMenu jobId={job?.job_id} closeFunc={setButtonMenu} />
        )}
        {trackButton && (
          <TrackButton
            theme={theme}
            closeFunc={setTrackButton}
            jobCreationDate={job?.created_at}
          />
        )}
        {!buttonMenu && !trackButton && (
          <>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
              }}
            >
              <Grid
                sx={{
                  flexGrow: 1,
                  display: "flex",
                  flexDirection: "column",
                  gap: "15px",
                  padding: "0 20px 0 15px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "6px",
                    // minHeight: "64px",
                  }}
                >
                  {job?.profile_url !== "No URL" && (
                    <Link
                      to={`/jobs/company/${job?.company_id}`}
                      target={"_blank"}
                      style={{
                        textDecoration: "none",
                        color: theme.palette.black,
                      }}
                    >
                      <Box
                        component="img"
                        sx={{
                          // height: "60px",
                          width: "60px",
                          // maxHeight: { xs: "60px" },
                          maxWidth: { xs: "60px" },
                          // ml: "15px",
                          // mt: "15px",
                          // mb: "15px",
                          // border: 1,
                          // borderColor: "lightgrey",
                          borderRadius: "10px",
                        }}
                        alt="job_logo"
                        src={job?.profile_url}
                      />
                    </Link>
                  )}
                  <Tooltip
                    disableInteractive
                    arrow
                    title={job?.title}
                    placement="top"
                  >
                    <Link
                      to={
                        job?.post_incognito
                          ? `/jobs/${job?.country?.replace(
                              /\s|\//g,
                              ""
                            )}/${job?.town?.replace(
                              /\s|\//g,
                              ""
                            )}/${job?.title?.replace(/\s|\//g, "")}/${
                              job?.job_id
                            }`
                          : `/jobs/${job?.country?.replace(
                              /\s|\//g,
                              ""
                            )}/${job?.town?.replace(
                              /\s|\//g,
                              ""
                            )}/${job?.CompanyName?.replace(
                              /\s|\//g,
                              ""
                            )}/${job?.title?.replace(/\s|\//g, "")}/${
                              job?.job_id
                            }`
                      }
                      target={"_blank"}
                      style={{
                        textDecoration: "none",
                        color: theme.palette.black,
                      }}
                    >
                      <Typography
                        sx={{
                          wordBreak: "break-all",
                          fontWeight: theme.typography.Bold,
                          fontSize: "20px",
                          height: "25px",
                          overflow: "hidden",
                          display: "-webkit-box",
                          WebkitBoxOrient: "vertical",
                          WebkitLineClamp: 1,
                        }}
                      >
                        {job?.title}
                      </Typography>
                    </Link>
                  </Tooltip>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                      height: "14px",
                    }}
                  >
                    <Box
                      component="img"
                      sx={{
                        height: 16,
                        width: 15,
                        maxHeight: { xs: 16 },
                        maxWidth: { xs: 15 },
                      }}
                      alt="currency"
                      src={BlueCurrency}
                    />
                    <Typography
                      sx={{
                        fontWeight: theme.typography.Bold,
                        fontSize: "14px",
                        letterSpacing: "0.25px",
                      }}
                    >
                      {job?.salary_min === 0 ? (
                        <>
                          {job?.currency_symbol}
                          {formatCurrencyWithCommas(job?.salary_max)}{" "}
                          {job?.type === "freelance" ? "per hour" : "per month"}
                        </>
                      ) : job?.currency_symbol ||
                        job?.salary_min ||
                        job?.salary_max ? (
                        <>
                          {job?.currency_symbol}
                          {formatCurrencyWithCommas(job?.salary_min)} to{" "}
                          {job?.currency_symbol}
                          {formatCurrencyWithCommas(job?.salary_max)}{" "}
                          {job?.type === "freelance" ? "per hour" : "per month"}
                        </>
                      ) : (
                        "-"
                      )}
                      {/* {job?.currency_symbol}
                      {formatCurrencyWithCommas(job?.salary_min)} to{" "}
                      {job?.currency_symbol}
                      {formatCurrencyWithCommas(job?.salary_max)} per month */}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                      height: "14px",
                    }}
                  >
                    <Box
                      component="img"
                      sx={{
                        height: 16,
                        width: 15,
                        maxHeight: { xs: 16 },
                        maxWidth: { xs: 15 },
                      }}
                      alt="location"
                      src={redLocation}
                    />
                    <Typography
                      sx={{
                        fontWeight: theme.typography.Bold,
                        fontSize: "14px",
                        letterSpacing: "0.25px",
                      }}
                    >
                      {job?.town}, {job?.country}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                      height: "14px",
                    }}
                  >
                    <Box
                      component="img"
                      sx={{
                        height: 16,
                        width: 15,
                        maxHeight: { xs: 16 },
                        maxWidth: { xs: 15 },
                      }}
                      alt="job_exp"
                      src={job_exp}
                    />
                    <Typography
                      sx={{
                        fontWeight: theme.typography.Bold,
                        fontSize: "14px",
                        letterSpacing: "0.25px",
                      }}
                    >
                      {job?.experienceYearStart} to {job?.experienceYearEnd}{" "}
                      years
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                      height: "14px",
                    }}
                  >
                    <Box
                      component="img"
                      sx={{
                        height: 16,
                        width: 14,
                        maxHeight: { xs: 16 },
                        maxWidth: { xs: 14 },
                      }}
                      alt="calendar"
                      src={calendar}
                    />
                    <Typography
                      sx={{
                        fontWeight: theme.typography.Bold,
                        fontSize: "14px",
                        letterSpacing: "0.25px",
                      }}
                    >
                      {job?.job_start_date
                        ? dateMonthConverter(job?.job_start_date)
                        : "-"}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              <Box
                sx={{
                  flexGrow: 1,
                  padding: "12px 12px 3px 12px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  position: "relative",
                  height: "240px",
                }}
              >
                {(job?.job_video_url !== "No URL" &&
                  !isPlaying &&
                  job?.linkFlag === false) ||
                (videoLink !== "No URL" &&
                  !isPlaying &&
                  job?.linkFlag === false) ? (
                  <PlayButton />
                ) : null}
                {job?.linkFlag === false &&
                (job?.job_video_url !== "No URL" || videoLink !== "No URL") ? (
                  <video
                    ref={VideoRef}
                    autoPlay={false}
                    loop
                    preload="auto"
                    style={{
                      width: "320px",
                      height: "240px",
                      borderRadius: "25px",
                      objectFit: "cover",
                      cursor: disabledButton ? "wait" : "pointer",
                    }}
                    controls={false}
                    onClick={() => setOpenReviewVideoDialog(true)}
                    onLoadedData={() => setDisabledButton(false)}
                    onEnded={() => setIsPlaying(false)}
                  >
                    <source
                      src={videoLink || job?.job_video_url}
                      type="video/mp4"
                    />
                  </video>
                ) : job?.linkFlag === true &&
                  (job?.job_video_url !== "No URL" ||
                    videoLink !== "No URL" ||
                    job?.job_video_link !== "No URL") ? (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      paddingX: "20px",
                      paddingTop: "0px",
                      borderRadius: "25px",
                    }}
                  >
                    <YouTubeEmbed videoId={job?.job_video_url} />
                  </Box>
                ) : (
                  <Box
                    sx={{
                      flexGrow: 1,
                      margin: "12px 12px 3px 12px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      position: "relative",
                      height: "240px",
                      boxSizing: "border-box",
                    }}
                  >
                    <Box
                      sx={{
                        borderRadius: "25px",
                        width: "320px",
                        backgroundColor: "#F2F4FB",
                        height: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Box
                        component={"img"}
                        src={videoPlaceholder}
                        sx={{
                          height: "70px",
                          width: "70px",
                        }}
                      />
                    </Box>
                  </Box>
                )}
              </Box>
            </Box>
            <Grid
              container
              alignItems="center"
              overflow={"hidden"}
              sx={{
                width: "100%",
                borderRadius: "0 0 25px 25px",
                height: "60px",
              }}
            >
              {job?.job_video_url === "No URL" && videoLink === "No URL" ? (
                <Button
                  variant="contained"
                  sx={{
                    fontSize: "14px",
                    width: "100%",
                    height: "60px",
                    paddingY: "25px",
                    borderRadius: "0 0 25px 25px",
                  }}
                  color="videoButton"
                  startIcon={
                    <Box
                      component={"img"}
                      src={whiteRecord}
                      sx={{
                        height: "20px",
                        width: "24px",
                      }}
                    />
                  }
                  onClick={handleOpenRecordDialog}
                >
                  {i18n["empMyCam.completeVideo"]}
                </Button>
              ) : (
                <>
                  <Button
                    variant="contained"
                    sx={{
                      borderRadius: 0,
                      width: "50%",
                      height: "100%",
                      fontSize: "14px",
                    }}
                    color="deleteVideoButton"
                    startIcon={
                      <Box
                        component={"img"}
                        src={deleteIcon}
                        sx={{ height: 18 }}
                      />
                    }
                    onClick={handleOpenDelete}
                  >
                    {i18n["empMyCam.Delete"]}
                  </Button>
                  <Button
                    variant="contained"
                    sx={{
                      borderRadius: 0,
                      width: "50%",
                      height: "100%",
                      fontSize: "14px",
                    }}
                    color="videoButton"
                    startIcon={
                      <Box
                        component={"img"}
                        src={whiteRecord}
                        sx={{
                          height: "20px",
                          width: "24px",
                        }}
                      />
                    }
                    onClick={handleOpenRecordDialog}
                  >
                    {i18n["empMyCam.updateVideo"]}
                  </Button>
                </>
              )}
            </Grid>
          </>
        )}
      </Card>
      <EmployerJobVideoUpload
        show={openRecordDialog}
        closeFunc={handleOpenRecordDialog}
        reloadVideo={handleVideoUploaded}
        job_id={job?.job_id}
        allVideoJobs={allVideoJobs}
        setAllJobList={setAllJobList}
        job={job}
        setSteps={setSteps}
        steps={steps}
        setActiveStep={setActiveStep}
        setOpenRecordDialog={setOpenRecordDialog}
        allJobsList={allJobsList}
      />
      <Delete
        show={openDelete}
        handleOpen={handleOpenDelete}
        handleCancel={handleCancelDelete}
        handleDelete={handleDeleteVideo}
        confirmDelete={confirmDelete}
        setConfirmDelete={setConfirmDelete}
      />
      {openReviewVideoDialog && (
        <ReviewVideoDialog
          videoLink={videoLink}
          open={openReviewVideoDialog}
          setOpen={handleCloseDialog}
        />
      )}
    </>
  );
}
