import axios from "axios";
import { getLocalStorage } from "./Common";

const BASE_URL = process.env.REACT_APP_DEV_URL;
const requestCache = {};
const postRequestCache = {};

export const getApi = (url, isAuthToken = false, configs) => {
  if (requestCache[url]) {
    // Return the cached promise if it exists
    return requestCache[url];
  }
  const Axios = axios.create({
    baseURL: BASE_URL,
    headers: {
      "Access-Control-Allow-Origin": "*",
    },
  });

  // Setting up request interceptor within the function
  Axios.interceptors.request.use(
    (config) => {
      const accessToken = getLocalStorage("token");

      if (accessToken || isAuthToken) {
        if (accessToken) {
          config.headers["Authorization"] = `Bearer ${accessToken}`;
        }
      }
      // Attach the cancel token to the config
      config.cancelToken = new axios.CancelToken((cancel) => {
        requestCache[url] = cancel;
      });
      return config;
    },
    (error) => Promise.reject(error)
  );

  // Setting up response interceptor within the function
  Axios.interceptors.response.use(
    (response) => {
      delete requestCache[url]; // Clear the request from cache on response
      return response;
    },
    (error) => {
      if (!axios.isCancel(error)) {
        delete requestCache[url]; // Clear the request from cache on error
        // Handle errors
        if (error?.response?.status === 401) {
          window.location.href = window.location.origin;
          localStorage.clear();
        }
        if (
          error?.response?.status === 403 &&
          error?.response?.data?.message ===
            "You are not authorized to access this route"
        ) {
          window.location.href = `${window.location.origin}/forbidden`;
        }
        if (
          error?.response?.status === 403 &&
          error?.response?.data?.message === "Forbidden"
        ) {
          window.location.href = `${window.location.origin}/forbidden`;
        }
      }
      return Promise.reject(error);
    }
  );

  // Making the API request
  const requestPromise = Axios.get(url,configs||{})
    .then((response) => response)
    .catch((error) => {
      const errorRes = {
        data: {
          status: "error",
          code: error.code,
          message: error.response?.data?.message,
        },
      };
      return errorRes;
    });

  // Store the promise in the cache
  requestCache[url] = requestPromise;

  return requestPromise;
};

const generateCacheKey = (url, payload) => {
  return `${url}_${JSON.stringify(payload)}`;
};

export const postApi = (
  url,
  payload,
  isAuthToken = false,
  contentType = "application/json; charset=utf-8"
) => {
  const cacheKey = generateCacheKey(url, payload);
  // Cancel any existing request with the same key before making a new request
  if (postRequestCache[cacheKey]) {
    postRequestCache[cacheKey].cancel(
      "Cancelled due to a new request with the same payload"
    );
  }

  let cancel;
  const cancelToken = new axios.CancelToken((c) => (cancel = c));
  const accessToken = getLocalStorage("token");

  const Axios = axios.create({
    baseURL: BASE_URL,
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": contentType,
      ...((accessToken || isAuthToken) && {
        Authorization: `Bearer ${getLocalStorage("token")}`,
      }),
    },
    cancelToken: cancelToken,
  });

  // Making the API request and storing the cancel token
  const requestPromise = Axios.post(url, payload)
    .then((response) => {
      delete postRequestCache[cacheKey]; // Clear cache after success
      return response;
    })
    .catch((error) => {
      if (!axios.isCancel(error)) {
        delete postRequestCache[cacheKey]; // Clear cache after failure
        const statusCode = error?.response?.status;
        const errorMessage = error?.response?.data?.message;
        if (statusCode === 401) {
          window.location.href = window.location.origin;
          localStorage.clear();
        } else if (statusCode === 403) {
          if (
            errorMessage === "You are not authorized to access this route" ||
            errorMessage === "Forbidden"
          ) {
            window.location.href = `${window.location.origin}/forbidden`;
          }
        }
        const errorRes = {
          data: {
            status: "error",
            code: error?.code,
            message:
              error?.response && error?.response?.data
                ? error?.response?.data?.errors
                  ? error?.response?.data?.errors
                  : error?.response?.data
                : "Unknown error",
          },
        };
        return errorRes;
      }
    });
  // Store the new promise and cancel token in the cache
  postRequestCache[cacheKey] = { promise: requestPromise, cancel };
  return requestPromise;
};

// export const postApi = (
//   url,
//   payload,
//   isAuthToken = false,
//   contentType = "application/json; charset=utf-8"
// ) => {
//   // return axios({
//   //   method: "post",
//   //   url: BASE_URL + url,
//   //   headers: {
//   //     "Access-Control-Allow-Origin": "*",
//   //     "Content-Type": contentType,
//   //     Authorization: isAuthToken ? "Bearer " + getLocalStorage("token") : "",
//   //   },
//   //   data: payload,
//   // })
//   //   .then(function (response) {
//   //     return response;
//   //   })
//   //   .catch(function (error) {
//   //     const errorRes = {
//   //       data: {
//   //         status: "error",
//   //         code: error.code,
//   //         message: error.response.data.errors
//   //           ? error.response.data.errors
//   //           : error.response.data,
//   //       },
//   //     };
//   //     return errorRes;
//   //   });

//   const AxiosWithoutInterceptors = axios.create({ baseURL: BASE_URL });
//   AxiosWithoutInterceptors.interceptors.response.use(
//     (response) => response,
//     (error) => Promise.reject(error)
//   );
//   const Axios = axios.create({
//     baseURL: BASE_URL,
//     // method: "GET",
//     headers: {
//       "Access-Control-Allow-Origin": "*",
//       "Content-Type": contentType,
//     },
//   });

//   Axios.interceptors.response.use(
//     (response) => response,
//     (error) => {
//       // const originalReq = error.config;
//       // if (
//       //   error?.response?.status === 401 &&
//       //   error.response?.data?.message === "Token expired" //modify  access token RefreshToken
//       // ) {
//       //   const userDataFromLocalStorage = getLocalStorage("token");
//       //   const refresh_token = getLocalStorage("token")
//       //     ? getLocalStorage("token")
//       //     : null;

//       //   if (refresh_token) {
//       //     // fromAPI
//       //     // Construct the request to refresh the token
//       //     return AxiosWithoutInterceptors.get("/refreshtoken", {
//       //       // generate new token  API
//       //       headers: {
//       //         Authorization: `Bearer ${refresh_token}`,
//       //       },
//       //     })
//       //       .then((res) => {
//       //         const newAccessToken = res?.data?.token; // structure changed

//       //         // Update the accessToken in userData
//       //         if (newAccessToken) {
//       //           // const userData =getLocalStorage("token") || {};
//       //           // userData.accessToken = newAccessToken;

//       //           // Save the updated userData back to local storage
//       //           localStorage.setItem("token", newAccessToken);

//       //           // Update the Authorization header in the original request
//       //           originalReq.headers[
//       //             "Authorization"
//       //           ] = `Bearer ${newAccessToken}`;

//       //           // Retry the original request with the new access token
//       //           return Axios(originalReq)
//       //             .then(function (response) {
//       //               return response;
//       //             })
//       //             .then(function (response) {
//       //               return response;
//       //             })
//       //             .catch(function (error) {
//       //               const errorRes = {
//       //                 data: {
//       //                   status: "error",
//       //                   code: error.code,
//       //                   message: error.response.data.errors
//       //                     ? error.response.data.errors
//       //                     : error.response.data,
//       //                 },
//       //               };
//       //               return errorRes;
//       //             });
//       //           // console.log("token Expired")
//       //         } else {
//       //           // Handle the case where newAccessToken is not available in the response
//       //           return Promise.reject(
//       //             new Error("New access token not received")
//       //           );
//       //         }
//       //       })
//       //       .catch((err) => {
//       //         return Promise.reject(err);
//       //       });
//       //   } else {
//       //     // Handle the case where refresh_token is not available in local storage
//       //     return Promise.reject(new Error("Refresh token not found"));
//       //   }
//       // }
//       if(error?.response?.status ===403 && error?.response?.data?.message === "You are not authorized to access this route"){
//         window.location.href = `${window.location.origin}/forbidden`;
//       }
//       if(error?.response?.status ===403 && error?.response?.data?.message === "Forbidden"){
//         window.location.href = `${window.location.origin}/forbidden`;
//       }
//       if (
//         error?.response?.status === 401) {
//          window.location.href=window.location.origin
//          localStorage.clear()
//       }
//       return Promise.reject(error);
//     }
//   );

//   Axios.interceptors.request.use(
//     (request) => {
//       const accessToken = getLocalStorage("token");
//       if (accessToken) {
//         request.headers["Authorization"] = `Bearer ${accessToken}`;
//       }
//       return request;
//     },
//     (err) => Promise.reject(err)
//   );
//   return Axios.post(url, payload, {
//     headers: {
//       "Access-Control-Allow-Origin": "*",
//       "Content-Type": contentType,
//     },
//   })
//     .then(function (response) {
//       return response;
//     })
//     .catch(function (error) {
//       const errorRes = {
//         data: {
//           status: "error",
//           code: error.code,
//           message: error.response.data.errors
//             ? error.response.data.errors
//             : error.response.data,
//         },
//       };
//       return errorRes;
//     });
// };

// export const getApi = (url, isAuthToken = false) => {
//   return axios({
//     method: "get",
//     url: BASE_URL + url,
//     headers: {
//       "Access-Control-Allow-Origin": "*",
//       Authorization: isAuthToken ? "Bearer " + getLocalStorage("token") : "",
//     },
//   })
//     .then(function (response) {
//       return response;
//     })
//     .catch(function (error) {
//       const errorRes = {
//         data: {
//           status: "error",
//           code: error.code,
//           // message: error.response.data.data,
//           message: error.response.data.message,
//         },
//       };
//       return errorRes;
//     });
// };

// export const postApi = (
//   url,
//   payload,
//   isAuthToken = false,
//   contentType = "application/json; charset=utf-8"
// ) => {
//   return axios({
//     method: "post",
//     url: BASE_URL + url,
//     headers: {
//       "Access-Control-Allow-Origin": "*",
//       "Content-Type": contentType,
//       Authorization: isAuthToken ? "Bearer " + getLocalStorage("token") : "",
//     },
//     data: payload,
//   })
//     .then(function (response) {
//       return response;
//     })
//     .catch(function (error) {
//       const errorRes = {
//         data: {
//           status: "error",
//           code: error.code,
//           message: error.response.data.errors
//             ? error.response.data.errors
//             : error.response.data,
//         },
//       };
//       return errorRes;
//     });
// };