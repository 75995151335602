import {
  Box,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import locale from "../../i18n/locale";
import { useDispatch } from "react-redux";
import { getHistoryLogsManageDashboard } from "../../redux/admin/jobsSlice";
import { setAlert } from "../../redux/configSlice";
import { ALERT_TYPE } from "../../utils/Constants";
import InfiniteScroll from "react-infinite-scroll-component";
import CloseIcon from "./CloseIcon";
import AlertIcon from "../../assets/Padding Excluded/Alert_yellow.svg"

const ManageDashboardHistoryBox = ({
  handleOpen,
  handleClose,
  openFor,
  email,
  component,
  jobDetail,
}) => {
  const i18n = locale.en;
  const dispatch = useDispatch();

  const [history, setHistory] = useState([]);
  const [lastKey, setLastKey] = useState(0);
const [isShow, setIsShow]= useState(false)
  const getHistoryList = async (lastKeyy) => {
    const data = {
      email: email,
      lastKey: lastKeyy,
    };
    if (component) {
      data["job_id"] = jobDetail?.job_id;
    }
    const { payload } = await dispatch(getHistoryLogsManageDashboard(data));

    if (payload?.status === "success") {
      setHistory((prev) => [...prev, ...payload?.data]);
      setLastKey(payload?.pageNumber + 1);
      setIsShow(true)
    } else if (payload === undefined) {
      return;
    } else {
      dispatch(
        setAlert({
          show: true,
          type: ALERT_TYPE?.ERROR,
          msg: "Something went wrong! please relaod the window",
        })
      );
    }
  };

  useEffect(() => {
    getHistoryList(0);
  }, []);

  return (
    <Box >
      <Box sx={{ display: "flex", marginTop: "10px", marginBottom: "20px", marginLeft:"15px" }}>
        <Box sx={{display:"flex"}}>
        <Box component="img" src={AlertIcon} sx={{width:"15px", height:"15px", marginRight:"5px", alignItems:"center"}}></Box>
        <Box sx={{fontWeight:"700", fontSize:"14px"}}>Candidate job history log</Box>
        </Box>
      </Box>
      <Box onClick={handleClose}>
        <CloseIcon />
      </Box>
      <InfiniteScroll
        style={{
          overflowX: "hidden",
          scrollbarWidth: "thin",
          display: "flex",
          flexDirection: "column",
          gap: "10px",
        }}
        dataLength={history?.length || 0}
        next={() => getHistoryList({ lastKey: lastKey })}
        hasMore={true}
        // loader={
        //   <Box
        //     style={{
        //       margin: "15px 0 0 0",
        //       display: "flex",
        //       justifyContent: "center",
        //       alignItems: "center",
        //     }}
        //   >
        //     <CircularProgress color="inherit" size={20} />
        //   </Box>
        // }
        scrollableTarget="shortListTaleneList"
        scrollThreshold={"50px"}
      >
        <Box sx={{marginLeft:"15px", marginRight:"15px", marginBottom:"15px"}}>
          {history?.length>0 ?history?.map((item) => {
            return <Box sx={{fontSize:"14px",fontWeight:"400"}}>{item?.log_record}</Box>;
          })
          :isShow?<Box sx={{fontSize:"14px",width:"100%",textAlign:"center",fontWeight:"400", marginBottom:"20px"}}>No History Logs available</Box>:""
          }
          
        </Box>
      </InfiniteScroll>
    </Box>
  );
};

export default ManageDashboardHistoryBox;
