import React, { useState } from "react";
import { Box, Typography, Switch, Button } from "@mui/material";
import Paper from "@mui/material/Paper";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import CustomDialog from "../CustomDialog";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { IconButton, InputBase, Popover } from "@mui/material";
import { useTheme } from "@emotion/react";
import {
  editUserPassword,
  removeMember,
} from "../../../redux/employer/myTeams";
import { useDispatch } from "react-redux";
import { setAlert } from "../../../redux/configSlice";
import { ALERT_TYPE, PASSWORD_REGEX } from "../../../utils/Constants";
import locale from "../../../i18n/locale";
import Delete from "../../candidate/myCam/dialog/Delete";

const Actions = ({
  userID,
  permission,
  setDeleted,
  role,
  userName,
  isSuperAdmin,
  superAdminFlag,
}) => {
  const i18n = locale.en;
  const [openDelete, setOpenDelete] = useState(false);
  const [openInfoDialog, setOpenInfoDialog] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [inputType, setInputType] = useState("password");
  const [showPassword, setShowPassword] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [error, setError] = useState({});
  const theme = useTheme();
  const dispatch = useDispatch();

  const handleOpenDelete = () => {
    setOpenDelete((prevState) => !prevState);
    setConfirmDelete(false);
  };
  const handleEdit = (event) => {
    setOpenInfoDialog(true);
    !openInfoDialog && setAnchorEl(event?.target);
  };

  const handleShowPassword = () => {
    if (showPassword) setInputType("password");
    else setInputType("text");

    setShowPassword(!showPassword);
  };

  const handlePassword = (event) => {
    setNewPassword(event?.target?.value);
    if (!PASSWORD_REGEX?.test(event?.target?.value)) {
      setError({ errorText: true });
      return;
    } else {
      setError({});
    }
  };

  const onHandleClose = () => {
    setOpenInfoDialog(false);
    setAnchorEl(null);
    setError({});
  };

  const handleChangePassword = async () => {
    try {
      const data = {
        userid: userID,
        password: newPassword,
      };
      if (!PASSWORD_REGEX?.test(newPassword)) {
        setError({ errorText: true });
        return;
      }
      const { payload } = await dispatch(editUserPassword(data));
      if (payload?.status === "success") {
        onHandleClose();
        setOpenInfoDialog(false);
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE?.SUCCESS,
            msg: "Password changed successfully",
          })
        );
      } else {
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE?.ERROR,
            msg: payload?.message,
          })
        );
      }
    } catch (error) {
      dispatch(
        setAlert({
          show: true,
          type: ALERT_TYPE?.ERROR,
          msg: error,
        })
      );
    }
  };

  const handleDeleteMember = async () => {
    try {
      const data = {
        userids: [userID],
      };
      const { payload } = await dispatch(removeMember(data));
      if (payload?.status === "success") {
        setOpenDelete(false);
        setDeleted(payload?.data);
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE?.SUCCESS,
            msg: "Member removed",
          })
        );
      } else {
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE?.ERROR,
            msg: payload?.message,
          })
        );
      }
    } catch (error) {
      dispatch(
        setAlert({
          show: true,
          type: ALERT_TYPE?.ERROR,
          msg: error,
        })
      );
    }
  };

  return (
    <>
      {role === 1 || role === 2 ? (
        <Box
          sx={{
            display: "flex",
            gap: 2,
          }}
        >
          {(isSuperAdmin && permission === "Recruiter") ||
          (isSuperAdmin && permission === "Super Admin") ? null : (
            <DeleteIcon
              color="blueButton400"
              onClick={handleOpenDelete}
              sx={{ cursor: "pointer" }}
            />
          )}

          {isSuperAdmin &&
          permission === "Super Admin" &&
          !superAdminFlag ? null : (
            <EditIcon
              color="redButton100"
              onClick={handleEdit}
              sx={{ cursor: "pointer" }}
            />
          )}
          <Popover
            id="dropdown-menu"
            open={openInfoDialog}
            anchorEl={anchorEl}
            onClose={onHandleClose}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            sx={{
              width: "100% !important",
              "& .css-ll95b0-MuiPaper-root-MuiPopover-paper": {
                padding: "16px !important",
              },
            }}
          >
            <Box sx={{ mt: 3 }}>
              {error?.errorText && (
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: theme.typography.Medium,
                    color: "red",
                    textAlign: "center",
                    width: "272px",
                  }}
                >
                  {i18n["alert.passwordmustcontain"]}
                </Typography>
              )}
              <Paper
                sx={{
                  display: "flex",
                  height: "40px",
                  borderRadius: "25px",
                  boxShadow: "none",
                  border: `1px solid ${theme.palette.grayBorder}`,
                }}
              >
                <InputBase
                  sx={{ ml: 2, mr: 2, width: "100%" }}
                  id="password"
                  type={inputType}
                  placeholder="Change password"
                  onChange={(event) => handlePassword(event)}
                />
                <IconButton
                  sx={{ py: 0 }}
                  color=""
                  aria-label="reset password"
                  component="button"
                  onClick={handleShowPassword}
                >
                  {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                </IconButton>
              </Paper>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                mt: 0,
              }}
            >
              <Button
                sx={{
                  width: 150,
                  mt: 3,
                }}
                variant="contained"
                color="redButton"
                onClick={handleChangePassword}
              >
                {i18n["myTeam.confirm"]}
              </Button>
            </Box>
          </Popover>
        </Box>
      ) : null}
      <Delete
        show={openDelete}
        handleOpen={handleOpenDelete}
        handleCancel={handleOpenDelete}
        handleDelete={handleDeleteMember}
        confirmDelete={confirmDelete}
        setConfirmDelete={setConfirmDelete}
        dialogText={userName}
      />
    </>
  );
};

export default Actions;
