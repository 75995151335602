import { Box, Button, Typography, useTheme } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import companyLogo from "../../../assets/user_logo.svg";
import userProfile from "../../../assets/Icons/Red_User_Profile.svg";
import calendar from "../../../assets/Padding Included/Yellow_Calendar.svg";
import { dateConverterFullMonth } from "../../../utils/DateTime";
import redLocation from "../../../assets/Red_Location.svg";
import flip from "../../../assets/NewFlipIcon.svg";
import SmallButton from "../../common/SmallButton";
import Slider2 from "../../common/Slider2";
import uploadIcon from "../../../assets/Padding Excluded/Black_Upload.svg";
import takePhotoIcon from "../../../assets/Padding Included/Profile_Details.svg";
import playButtonIcon from "../../../assets/Padding Excluded/Black_reactive_job copy.svg";
import playButtonIconWhite from "../../../assets/Padding Excluded/Black_reactive_job.svg";
import downArrow from "../../../assets/Black_Down_Open.svg";
import upArrow from "../../../assets/Black_Up_Close.svg";
import moreDownArrow from "../../../assets/Black_Down_Open - Copy.svg";
import moreUpArrow from "../../../assets/Black_Up_Close - Copy.svg";

export default function CandidateInfoBackCard({
  profile,
  hiddenFileInput,
  handleImageClick,
  handleImageChange,
  handlePhotoCapture,
  setisHorizontalFlipped,
  handleClickMyInfo,
  expanded,
  setVideoLink,
  setOpenReviewVideoDialog,
}) {
  const theme = useTheme();

  const typographyRef = useRef(null);
  const [isOverflowing, setIsOverflowing] = useState(false);
  const [viewMoreExpanded, setViewMoreExpanded] = useState(false);

  const handleViewMore = () => {
    setViewMoreExpanded((prevState) => !prevState);
  };

  useEffect(() => {
    const typographyElement = typographyRef?.current;

    if (typographyElement) {
      const fullHeight = typographyElement?.scrollHeight;
      const lineHeight = 20;
      const clampedHeight = lineHeight * 4;

      if (fullHeight > clampedHeight) {
        setIsOverflowing(true);
      }
    }
  }, [profile?.my_bio]);

  return (
    <>
      <Box
        sx={{
          height: { xs: "300px", tablet: "225px", lg: "280px", mlg: "225px" },
          background: "#ffff",
          boxShadow: "0px 5px 10px #00000029",
          borderRadius: "25px 25px 25px 0px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            flex: "1",
          }}
        >
          <Box
            component={"img"}
            src={
              profile?.profile_url !== "NO URL"
                ? profile?.profile_url
                : companyLogo
            }
            sx={{
              //   height: { xs: "96px", xl: "96px" },
              //   width: { xs: "96px", xl: "96px" },
              //   minWidth: { xs: "96px", xl: "96px" },
              height: { xs: "100px", tablet: "175px" },
              width: { xs: "100px", tablet: "175px" },
              borderRadius: "10px",
              margin: "24px 14px 25px 21px",
            }}
          />
          <Box
            sx={{
              width: { xs: `calc(100% - 120px)`, tablet: `calc(100% - 44px)` },
              boxSizing: "border-box",
              marginTop: { xs: "55px", tablet: "0px" },
            }}
          >
            <Typography
              sx={{
                fontSize: theme.typography.fontSize.sm,
                fontWeight: theme.typography.Bold,
              }}
            >
              My bio
            </Typography>
            <Typography
              ref={typographyRef}
              sx={{
                fontSize: theme.typography.fontSize.xs,
                fontWeight: theme.typography.Regular,
                overflow: viewMoreExpanded ? "scroll" : "hidden",
                display: "-webkit-box",
                WebkitLineClamp: 4,
                WebkitBoxOrient: "vertical",
                width: `calc(100% - 44px)`,
                height: "auto",
                lineHeight: "20px",
              }}
            >
              {profile?.my_bio}
            </Typography>
            {isOverflowing && (
              <Box sx={{ width: `calc(100% - 44px)` }}>
                <Button
                  sx={{
                    color: "black",
                    padding: 0,
                    fontSize: "12px !important",
                    fontWeight: theme.typography.Bold,
                    ".MuiButton-startIcon": {
                      marginRight: "0px !important",
                      marginLeft: "-18px !important",
                    },
                    height: "30px",
                  }}
                  startIcon={
                    <Box
                      component="img"
                      sx={{
                        height: 30,
                        width: 30,
                        cursor: "pointer",
                      }}
                      alt="crayon logo"
                      src={viewMoreExpanded ? moreUpArrow : moreDownArrow}
                    />
                  }
                  onClick={handleViewMore}
                >
                  {viewMoreExpanded ? "less" : "more"}
                </Button>
              </Box>
            )}
            <Box sx={{ width: "100%", gap: "5px", display: "flex" }}>
              {profile?.company_culture?.map((item) => {
                return (
                  <SmallButton
                    color={"precium"}
                    fontSize={12}
                    height={25}
                    value={item?.value}
                    label={item?.label}
                    cursor={"default"}
                  />
                );
              })}
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            position: "absolute",
            top: 0,
            right: 0,
          }}
        >
          <Button
            color="precium"
            variant="contained"
            sx={{
              fontSize: theme.typography.fontSize.xs,
              fontWeight: theme.typography.Bold,
              height: "auto",
              minWidth: "60px",
              minHeight: "45px",
              borderRadius: "0px 0 0 10px",
              padding: 0,
              ":hover": {
                boxShadow: "none",
              },
            }}
          >
            {profile?.work_setup}
          </Button>
          <Button
            color="peachPayment"
            variant="contained"
            sx={{
              fontSize: theme.typography.fontSize.xs,
              fontWeight: theme.typography.Bold,
              height: "auto",
              minWidth: "60px",
              minHeight: "45px",
              borderRadius: 0,
              padding: 0,
              ":hover": {
                boxShadow: "none",
              },
            }}
          >
            {profile?.employment_type}
          </Button>
          <Button
            color="playButton"
            variant="contained"
            onClick={() => {
              if (profile?.cam_url !== "NO URL") {
                setVideoLink(profile?.cam_url);
                setOpenReviewVideoDialog(true);
              }
            }}
            sx={{
              height: "auto",
              minWidth: "60px",
              minHeight: "45px",
              background:
                profile?.cam_url === "NO URL" && theme.palette.grayButton600.main,
              borderRadius: 0,
              borderTopRightRadius: "25px",
              cursor: profile?.cam_url === "NO URL" ? "default" : "pointer",
              padding: 0,
              ":hover": {
                boxShadow: "none",
              },
            }}
          >
            <Box
              component={"img"}
              src={
                profile?.cam_url !== "NO URL"
                  ? playButtonIconWhite
                  : playButtonIcon
              }
              sx={{
                height: "16px",
                width: "20px",
              }}
            />
          </Button>
        </Box>
      </Box>
      <Button
        variant="contained"
        color="redButton100"
        sx={{
          width: "20px",
          height: "100px",
          padding: 0,
          position: "absolute",
          right: 0,
          top: `calc(50% - 50px)`,
          minWidth: "20px",
          fontSize: "20px",
          borderRadius: "10px 0 0 10px",
        }}
        onClick={() => setisHorizontalFlipped(false)}
      >
        <Box
          component={"img"}
          src={flip}
          sx={{
            height: "20px",
            width: "15px",
          }}
        />
      </Button>
      <Box sx={{ position: "absolute", width: "100%", textAlign: "left" }}>
        <Button
          sx={{
            height: "45px",
            width: "125px",
            borderRadius: "0 0 10px 10px",
            fontSize: theme.typography.fontSize.sm,
            fontWeight: theme.typography.Bold,
            padding: "12px 17px 13px 27px",
            "& .MuiButton-endIcon": {
              margin: "0px !important",
            },
          }}
          size="small"
          variant="contained"
          color="redButton"
          endIcon={
            <Box
              component={"img"}
              src={expanded ? upArrow : downArrow}
              sx={{
                height: 25,
                width: 25,
              }}
            />
          }
          onClick={() => {
            handleClickMyInfo();
          }}
        >
          my info
        </Button>
      </Box>
    </>
  );
}
