import React from "react";
import locale from "../../../i18n/locale";
import { Box, Divider, Typography, useTheme } from "@mui/material";
import { Link } from "react-router-dom";
import { calculateDaysBetween, createMarkup } from "../../../utils/Common";
import location from "../../../assets/Padding Excluded/Blue_Location.svg";
import contact from "../../../assets/Padding Excluded/Blue_Contact.svg";
import email from "../../../assets/Padding Excluded/Blue_Email.svg";
import { formatPhoneNumberIntl } from "react-phone-number-input";
import TalentSVGButton from "../../common/TalentSVGButton";
import linkedin from "../../../assets/linkedin.svg";
import portfolioIcon from "../../../assets/CircularIcon/Red/Circular Icons_Red_Portfolio.svg";
import { monthYear } from "../../../utils/DateTime";
import useViewport from "../../common/ViewPort";
import crayonLogo from "../../../assets/Crayon_Logo.svg";

export default function ClassicCVPageRightSide({ exportAsPDF, stateData }) {
  const i18n = locale.en;
  const theme = useTheme();
  const { width } = useViewport();

  return (
    <Box
      className="rightSide"
      sx={{
        width: exportAsPDF
          ? "785px"
          : {
              md24: `calc(100% - 400px)`,
              xl: "785px",
              xl1920: `calc(100% - 400px)`,
            },
        display: "flex",
        flexDirection: "column",
        padding: "42px 50px 42px 50px",
        borderRadius:
          width > 390 || exportAsPDF ? "25px 0 0 25px" : "0 0 25px 25px",
      }}
    >
      <Typography
        sx={{
          fontSize: "30px",
          fontWeight: theme.typography.Bold,
          textAlign: "center",
        }}
      >
        {stateData?.jobTitle}
      </Typography>

      <Box
        sx={{
          display: "flex",
          gap: width > 1366 || exportAsPDF ? "40px" : "20px",
          alignItems: width > 1366 || exportAsPDF ? "center" : "flex-start",
          marginBottom: { xs: "20px", lg: "48px" },
          marginTop: { xs: "20px", lg: "0px" },
          flexDirection: width > 1366 || exportAsPDF ? "row" : "column",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
          <Box
            component="img"
            sx={{
              height: 15,
              width: 13,
            }}
            alt=""
            src={contact}
          />
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: theme.typography.Regular,
            }}
          >
            {formatPhoneNumberIntl(
              stateData?.contact_no?.startsWith("+")
                ? stateData?.contact_no
                : stateData?.contact_no?.startsWith(
                    stateData?.country_code?.slice(1)
                  )
                ? `${stateData?.country_code}${stateData?.contact_no?.slice(
                    stateData?.country_code?.length - 1
                  )}`
                : `${stateData?.country_code}${stateData?.contact_no}`
            ) || "-"}
          </Typography>
        </Box>
        <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
          <Box
            component="img"
            sx={{
              height: 15,
              width: 13,
            }}
            alt=""
            src={email}
          />
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: theme.typography.Regular,
            }}
          >
            {stateData?.email || "-"}
          </Typography>
        </Box>
        <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
          <Box
            component="img"
            sx={{
              height: 15,
              width: 13,
            }}
            alt=""
            src={location}
          />
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: theme.typography.Regular,
            }}
          >
            {`${
              stateData?.town_name && stateData?.country_name
                ? `${stateData?.town_name}, ${stateData?.country_name}`
                : "-"
            }`}
          </Typography>
        </Box>
        <Box sx={{ display: "flex", alignItems: "flex-end", gap: "10px" }}>
          {stateData?.linkedin_profile_link ? (
            <Link
              to={stateData?.linkedin_profile_link}
              target={"_blank"}
              style={{
                textDecoration: "none",
                color: theme.palette.black,
              }}
            >
              <Box
                component="img"
                className="profileAvatar"
                alt="crayon logo"
                src={linkedin}
                sx={{
                  mr: 1,
                  width: "20px",
                  minHeight: "20px",
                  cursor: "pointer",
                }}
              />
            </Link>
          ) : (
            <Box
              component="img"
              className="profileAvatar"
              alt="crayon logo"
              src={linkedin}
              sx={{
                mr: 1,
                width: "20px",
                minHeight: "20px",
                cursor: "pointer",
              }}
            />
          )}
          {stateData?.cv_url !== "No URL" ? (
            <Link
              to={`${stateData?.cv_url}`}
              target={"_blank"}
              style={{
                textDecoration: "none",
                color: theme.palette.black,
              }}
            >
              <TalentSVGButton
                cursor={"pointer"}
                color={"white"}
                source={portfolioIcon}
                height={25}
                width={25}
                startIconMargin={"0px !important"}
                padding={"0px !important"}
              />
            </Link>
          ) : (
            <TalentSVGButton
              cursor={"pointer"}
              color={"white"}
              source={portfolioIcon}
              height={25}
              width={25}
              startIconMargin={"0px !important"}
              padding={"0px !important"}
            />
          )}
        </Box>
      </Box>

      <Box sx={{ marginBottom: "6px" }}>
        <Typography
          sx={{ fontSize: "18px", fontWeight: theme.typography.Bold }}
        >
          {i18n["myCV.summary"]}
        </Typography>
        <Box sx={{ width: "100%", my: "5px" }}>
          <Divider sx={{ opacity: 1, backgroundColor: "black" }} />
        </Box>
        <Typography
          sx={{
            fontSize: "14px",
            fontWeight: theme.typography.Regular,
            minHeight: "57px",
          }}
        >
          {stateData?.my_bio}
        </Typography>
      </Box>

      <Box sx={{ marginBottom: "27px" }}>
        <Typography
          sx={{ fontSize: "18px", fontWeight: theme.typography.Bold }}
        >
          {i18n["myCV.experience"]}
        </Typography>
        <Box sx={{ width: "100%", my: "5px" }}>
          <Divider sx={{ opacity: 1, backgroundColor: "black" }} />
        </Box>
        <Box sx={{ display: "flex", gap: "10px", flexDirection: "column" }}>
          {stateData?.work_exp?.map((item) => {
            return (
              <Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Box>
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontWeight: theme.typography.Bold,
                      }}
                    >
                      {item?.title}
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontWeight: theme.typography.Regular,
                      }}
                    >
                      {item?.name}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontWeight: theme.typography.Regular,
                      }}
                    >
                      {monthYear(item?.start_date)} -{" "}
                      {monthYear(item?.end_date)}{" "}
                      {`(${calculateDaysBetween(
                        item?.start_date,
                        item?.end_date
                      )})`}
                    </Typography>
                  </Box>
                </Box>
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: theme.typography.Regular,
                  }}
                  dangerouslySetInnerHTML={createMarkup(
                    item?.clients_worked_on_awards
                  )}
                />
              </Box>
            );
          })}
        </Box>
      </Box>

      <Box sx={{ marginBottom: "27px" }}>
        <Typography
          sx={{ fontSize: "18px", fontWeight: theme.typography.Bold }}
        >
          {i18n["myCV.qualifications"]}
        </Typography>
        <Box sx={{ width: "100%", my: "5px" }}>
          <Divider sx={{ opacity: 1, backgroundColor: "black" }} />
        </Box>
        {stateData?.UserQualification?.map((item) => {
          return (
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "8px",
              }}
            >
              <Box>
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: theme.typography.Bold,
                  }}
                >
                  {item?.qualification?.name}
                </Typography>
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: theme.typography.Regular,
                  }}
                >
                  {item?.institution?.name}
                </Typography>
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: theme.typography.Regular,
                  }}
                >
                  {item?.qualification_type?.name}
                </Typography>
              </Box>
              <Box>
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: theme.typography.Regular,
                  }}
                >
                  {monthYear(item?.start_date)}
                </Typography>
              </Box>
            </Box>
          );
        })}
        {stateData?.UserSchool?.map((item) => {
          return (
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "8px",
              }}
            >
              <Typography
                sx={{
                  fontSize: "14px",
                  fontWeight: theme.typography.Bold,
                  textWrap: "wrap",
                }}
              >
                {item?.name}
              </Typography>
              <Typography
                sx={{
                  fontSize: "14px",
                  fontWeight: theme.typography.Regular,
                }}
              >
                Completed: {item?.year_ended}
              </Typography>
            </Box>
          );
        })}
      </Box>

      <Box sx={{ marginBottom: "27px" }}>
        <Typography
          sx={{ fontSize: "18px", fontWeight: theme.typography.Bold }}
        >
          {i18n["myCV.professionalAssociations"]}
        </Typography>
        <Box sx={{ width: "100%", my: "5px" }}>
          <Divider sx={{ opacity: 1, backgroundColor: "black" }} />
        </Box>
        {stateData?.UserAssociation?.map((item) => {
          return (
            <Box
              sx={{
                marginBottom: "8px",
              }}
            >
              <Typography
                sx={{
                  fontSize: "14px",
                  fontWeight: theme.typography.Bold,
                }}
              >
                {item?.name}
              </Typography>
              <Typography
                sx={{
                  fontSize: "14px",
                  fontWeight: theme.typography.Regular,
                }}
              >
                {item?.year_ended}
              </Typography>
            </Box>
          );
        })}
      </Box>

      <Box sx={{ marginBottom: "27px" }}>
        <Typography
          sx={{ fontSize: "18px", fontWeight: theme.typography.Bold }}
        >
          {i18n["myCV.references"]}
        </Typography>
        <Box sx={{ width: "100%", my: "5px" }}>
          <Divider sx={{ opacity: 1, backgroundColor: "black" }} />
        </Box>
        {stateData?.reference?.map((item) => {
          return (
            <Box
              sx={{
                marginBottom: "8px",
              }}
            >
              <Typography
                sx={{
                  fontSize: "14px",
                  fontWeight: theme.typography.Bold,
                }}
              >
                {item.name}
              </Typography>
              <Typography
                sx={{
                  fontSize: "14px",
                  fontWeight: theme.typography.Regular,
                }}
              >
                {`${item?.title}, ${item?.company_name}`}
              </Typography>
              <Typography
                sx={{
                  fontSize: "14px",
                  fontWeight: theme.typography.Regular,
                }}
              >
                {formatPhoneNumberIntl(`${item?.country_code}${item?.contact}`)}
              </Typography>
              <Typography
                sx={{
                  fontSize: "14px",
                  fontWeight: theme.typography.Regular,
                }}
              >
                {item?.email}
              </Typography>
            </Box>
          );
        })}
      </Box>
      <Box
        sx={{
          height: "100%",
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "flex-end",
        }}
      >
        <Box
          component={"img"}
          src={crayonLogo}
          sx={{ width: "214px", height: "56px", opacity: 0.5 }}
        />
      </Box>
    </Box>
  );
}
