import React, { useEffect, useState } from "react";
import { Routes, Route } from "react-router-dom";
import {
  jobListingPageTheme,
  jobDetailsPageTheme,
  talentListingPageTheme,
  candidateMyCVDefaultTheme,
  PostAjobTheme,
  employerDefaulTheme,
  candidateChallengerTheme,
  candidateCharacterTheme,
  candidateContemplatorTheme,
  candidateCollaboratorTheme,
  jobListingPageChallengerTheme,
  jobListingPageCharacterTheme,
  jobListingPageContemplatorTheme,
  jobListingPageCollaboratorTheme,
  talentListingPageChallengerTheme,
  talentListingPageCharacterTheme,
  talentListingPageContemplatorTheme,
  talentListingPageCollaboratorTheme,
  homePageTheme,
  adminUsersTheme,
  promoterTheme,
} from "./utils/Theme";
import { ThemeProvider } from "@emotion/react";
import { Box } from "@mui/material";
import jwt_decode from "jwt-decode";
import "./App.css";
import PrivateRoute from "./components/route/PrivateRoute";
import { postAjobDirectTheme } from "./utils/componentThemes/postAjobDirectTheme";
import { postAjobRecruitTheme } from "./utils/componentThemes/postAjobRecruitTheme";
import Main from "./views/Main";
import Jobs from "./components/guest/jobs/Jobs";
import Talent from "./components/guest/talent/Talent";
import MyJobs from "./components/employer/myJobs/MyJobs";
import Search from "./components/admin/search/Search";
import AdminTalent from "./components/admin/adminTalent/AdminTalent";
import AdminJobs from "./components/admin/adminJobs/AdminJobs";
import BuildSearch from "./components/admin/search/BuildSearchNew";
import SearchPool from "./components/admin/search/SearchPool";
import JobTitles from "./components/admin/maintenance/JobTitles";
import ActiveJobs from "./components/admin/adminJobs/activeJobs/ActiveJobs";
import ActiveJobDetail from "./components/admin/adminJobs/ActiveJobDetail";
import TalentPool from "./components/admin/adminTalent/TalentPoolNew";
import Admin from "./components/admin/index";
import MyJobsCandidate from "./components/candidate/myJobs/MyJobs";
import MyCV from "./components/candidate/myCV/MyCVNew";
import ComingSoon from "./components/common/ComingSoon";
import Applicants from "./components/admin/myTalent/Applicants";
import Followers from "./components/admin/myTalent/Followers";
import Candidate from "./components/candidate";
import Promoter from "./components/promoter";
import Employer from "./components/employer";
import Recruiter from "./components/recruiter";
import PostAJob from "./components/employer/postAJob/PostAJob";
import MyProfileCan from "./components/candidate/myProfile/MyProfile";
import MyProfileEmp from "./components/employer/myProfile/ProfileCardNew";
import PendingJobs from "./components/admin/adminJobs/pendingJobs/PendingJobs";
import PausedJobs from "./components/admin/adminJobs/pausedJobs/PausedJobs";
import ClosedJobs from "./components/admin/adminJobs/closedJobs/ClosedJobs";
import JobsDetailPage from "./components/guest/jobs/JobsDetailPageNew";
import ManageJob from "./components/common/myJobsSectionEmployerAndRecruiter/ManageJob";
import MyTeams from "./components/employer/myTeams/MyTeams";
import AddNewMember from "./components/employer/myTeams/AddNewMember";
import TeamTable from "./components/employer/myTeams/TeamTable";
import TeamInfo from "./components/employer/myTeams/TeamInfo";
import Company from "./components/admin/maintenance/Company";
import Maintenance from "./components/admin/maintenance/Maintenance";
import Skills from "./components/admin/maintenance/Skills";
import Tools from "./components/admin/maintenance/Tools";
import Qualification from "./components/admin/maintenance/Qualification";
import Associations from "./components/admin/maintenance/Associations";
import Institution from "./components/admin/maintenance/Institution";
import Schools from "./components/admin/maintenance/Schools";
import Towns from "./components/admin/maintenance/Towns";
import Nationalities from "./components/admin/maintenance/Nationalities";
import Languages from "./components/admin/maintenance/Languages";
import Industries from "./components/admin/maintenance/Industries";
import QualificationTypes from "./components/admin/maintenance/QualificationTypes";
import Currencies from "./components/admin/maintenance/Currencies";
import TalentPoolInfo from "./components/admin/adminTalent/TalentPoolInfoNew";
import RecruiterMyJobs from "./components/recruiter/myJobs/RecruiterMyJobs";
import MyCam from "./components/candidate/myCam/MyCam";
import JobTitleSearch from "./components/admin/search/JobTitleSearch";
import TagsSearch from "./components/admin/search/TagsSearch";
import ToolsSearch from "./components/admin/search/ToolsSearch";
import QualificationsSearch from "./components/admin/search/QualificationsSearch";
import InstitutionSearch from "./components/admin/search/InstitutionSearch";
import AssociationSearch from "./components/admin/search/AssociationSearch";
import SchoolSearch from "./components/admin/search/SchoolSearch";
import TownsSearch from "./components/admin/search/TownsSearch";
import NationalitySearch from "./components/admin/search/NationalitySearch";
import LanguageSearch from "./components/admin/search/LanguageSearch";
import AllJobs from "./components/admin/adminJobs/AllJobs";
import CrayonDirect from "./components/admin/adminJobs/directJobs/CrayonDirect";
import CrayonRecruit from "./components/admin/adminJobs/recruitJobs/CrayonRecruit";
import CompanySearch from "./components/admin/search/CompanySearch";
import IndustrySearch from "./components/admin/search/IndustrySearch";
import HomeFlip from "./views/HomeFlip";
import Hiring from "./components/guest/whoshiring/Hiring";
import EmployerMyCam from "./components/employer/myCam/EmployerMyCam";
import CandidateCVPageNew from "./components/candidate/cvPage/CandidateCVPageNew";
import RecruiterProfileCard from "./components/recruiter/myProfile/RecruiterProfileCard";
import RecruiterPostAJob from "./components/recruiter/postAJob/RecruiterPostAJob";
import RecruiterMyCam from "./components/recruiter/myCam/RecruiterMyCam";
import TalentsCommonComponent from "./components/admin/adminTalent/CommonComponent/TalentsCommonComponent";
import Companies from "./components/admin/users/companies/Companies";
import UserEmployer from "./components/admin/users/employer/UserEmployer";
import UserRecruiter from "./components/admin/users/recruiter/UserRecruiter";
import MyTalents from "./components/admin/myTalent/MyTalents";
import ErrorPage from "./components/common/AccessDeniedPage";
import CrayonsTeamAdmins from "./components/admin/users/team/CrayonsTeamAdmins";
import EmployerDetailInfo from "./components/admin/users/Common/EmployerDetailInfo";
import RecruiterDetailInfo from "./components/admin/users/Common/RecruiterDetailInfo";
import TalentPartnerAdmins from "./components/admin/users/team/TalentPartnerAdmins";
import UserCrayonTeam from "./components/admin/users/team/UserCrayonTeam";
import CompaniesDetailInfo from "./components/admin/users/companies/CompaniesDetailInfo";
import TalentDetailPageNew from "./components/admin/adminTalent/TalentDetailPageNew";
import AdminJobdetailInfo from "./components/admin/adminJobs/AdminJobdetailInfo";
import CandidateCompanies from "./components/admin/maintenance/CandidateCompanies";
import CrayonsSuperAdmin from "./components/admin/users/team/CrayonsSuperAdmin";
import ShareJob from "./components/promoter/ShareJob";
import PromoterProfileCard from "./components/promoter/profile/PromoterProfileCard";
import SharedJobs from "./components/promoter/shareJobs/SharedJobs";
import Referrals from "./components/promoter/referrals/Referrals";
import Promoters from "./components/admin/users/promoter/Promoters";
import PromoterDetailInfo from "./components/admin/users/promoter/PromoterDetailInfo";
import CompanyCulture from "./components/admin/maintenance/CompanyCulture";
import Vouchers from "./components/admin/maintenance/Vouchers";
import CoinsHistory from "./components/admin/maintenance/CoinsHistory";
import PaymentHistory from "./components/admin/maintenance/PaymentHistory";
import IncognitoJobs from "./components/admin/adminJobs/InCognitoJobs/InCognitoJobs";
import ExpiredJobs from "./components/admin/adminJobs/ExpiredJobs/ExpiredJobs";
import Solutions from "./components/guest/solutions/Solutions";
import AdminSolutions from "./components/admin/maintenance/AdminSolutions/AdminSolutions";
import JobsSystemSpec from "./components/admin/adminJobs/JobsSystemSpec";
import CandidateAssesment from "./components/candidate/CandidateAssesment/CandidateAssesment";
import AdminAssessment from "./components/admin/maintenance/AdminAssessment/AdminAssessment";
import Contact from "./components/guest/contact/Contact";
import ContactUsQuery from "./components/admin/maintenance/ContactUsQuery";
import PaymentPage from "./components/login/PaymentPage";
import PolicyPrivacy from "./components/admin/maintenance/PolicyPrivacy";
import TermsOfService from "./components/guest/jobs/TermsOfService";
import Policy from "./components/guest/jobs/Policy";
import CommonAssessment from "./components/candidate/CandidateAssesment/CommonAssessment";
import Pricing from "./components/guest/Pricing/Pricing";
import ForbiddenPage from "./components/NotFoundCommonPages/ForbiddenPage";
import NotFoundPage from "./components/NotFoundCommonPages/NotFoundPage";
import SubscribeList from "./components/admin/maintenance/SubscribeList";

export default function App() {
  const token = localStorage?.getItem("token");
  let decodedToken;
  if (token) {
    decodedToken = jwt_decode(token);
  }
  const [dyanmicTheme, setDynamicTheme] = useState(PostAjobTheme);
  const [candidateDynamicTheme, setCandidateDynamicTheme] = useState(
    candidateMyCVDefaultTheme
  );
  const [jobsListingDynamicTheme, setJobsListingDynamicTheme] =
    useState(jobListingPageTheme);
  const [talentListingDynamicTheme, setTalentListingDynamicTheme] = useState(
    talentListingPageTheme
  );

  const handleDynamicTheme = (value) => {
    if (value === "Recruit") {
      setDynamicTheme(postAjobRecruitTheme);
    } else if (value === "Direct") {
      setDynamicTheme(postAjobDirectTheme);
    } else {
      setDynamicTheme(PostAjobTheme);
    }
  };

  const handleCandidateDynamicTheme = (value) => {
    if (value === 1) {
      setCandidateDynamicTheme(candidateChallengerTheme);
    } else if (value === 2) {
      setCandidateDynamicTheme(candidateCharacterTheme);
    } else if (value === 3) {
      setCandidateDynamicTheme(candidateContemplatorTheme);
    } else if (value === 4) {
      setCandidateDynamicTheme(candidateCollaboratorTheme);
    } else {
      setCandidateDynamicTheme(candidateMyCVDefaultTheme);
    }
  };

  const handleJobsListingDynamicTheme = (value) => {
    if (value === 1) {
      setJobsListingDynamicTheme(jobListingPageChallengerTheme);
    } else if (value === 2) {
      setJobsListingDynamicTheme(jobListingPageCharacterTheme);
    } else if (value === 3) {
      setJobsListingDynamicTheme(jobListingPageContemplatorTheme);
    } else if (value === 4) {
      setJobsListingDynamicTheme(jobListingPageCollaboratorTheme);
    } else {
      setJobsListingDynamicTheme(jobListingPageTheme);
    }
  };

  const handleTalentListingDynamicTheme = (value) => {
    if (value === 1) {
      setTalentListingDynamicTheme(talentListingPageChallengerTheme);
    } else if (value === 2) {
      setTalentListingDynamicTheme(talentListingPageCharacterTheme);
    } else if (value === 3) {
      setTalentListingDynamicTheme(talentListingPageContemplatorTheme);
    } else if (value === 4) {
      setTalentListingDynamicTheme(talentListingPageCollaboratorTheme);
    } else {
      setTalentListingDynamicTheme(talentListingPageTheme);
    }
  };

  useEffect(() => {
    if (decodedToken?.data?.role_id === 3 || decodedToken === undefined) {
      handleTalentListingDynamicTheme(decodedToken?.data?.personality);
    }
    if (decodedToken?.data?.role_id === 3 || decodedToken === undefined) {
      handleJobsListingDynamicTheme(decodedToken?.data?.personality);
    }
    if (decodedToken?.data?.role_id === 3 || decodedToken === undefined) {
      handleCandidateDynamicTheme(decodedToken?.data?.personality);
    }
  }, []);

  return (
    <Routes>
      <Route exact path="/" element={<Main />}>
        <Route
          path="/"
          element={
            <ThemeProvider theme={homePageTheme}>
              <HomeFlip />
            </ThemeProvider>
          }
        />
        <Route
          path="/payment/:paymentId"
          element={
            <ThemeProvider theme={homePageTheme}>
              <PaymentPage />
            </ThemeProvider>
          }
        />
        <Route
          path="solutions"
          element={
            <ThemeProvider theme={jobsListingDynamicTheme}>
              <Solutions
                handleJobsListingDynamicTheme={handleJobsListingDynamicTheme}
              />
            </ThemeProvider>
          }
        />
              <Route
          path="pricing"
          element={
            <ThemeProvider theme={jobsListingDynamicTheme}>
              <Pricing
                handleJobsListingDynamicTheme={handleJobsListingDynamicTheme}
              />
            </ThemeProvider>
          }
        />
        <Route
          path="termsOfService"
          element={
            <ThemeProvider theme={jobsListingDynamicTheme}>
             <TermsOfService/>
            </ThemeProvider>
          }
        />
        <Route
          path="privacyPolicy"
          element={
            <ThemeProvider theme={jobsListingDynamicTheme}>
             <Policy/>
            </ThemeProvider>
          }
        />
        <Route
          path="contact"
          element={
            <ThemeProvider theme={jobsListingDynamicTheme}>
              <Contact
                handleJobsListingDynamicTheme={handleJobsListingDynamicTheme}
              />
            </ThemeProvider>
          }
        />
        <Route
          path="jobs"
          element={
            <ThemeProvider theme={jobsListingDynamicTheme}>
              <Jobs
                handleJobsListingDynamicTheme={handleJobsListingDynamicTheme}
              />
            </ThemeProvider>
          }
        />
        <Route
          path="forbidden"
          element={
            <ThemeProvider theme={jobsListingDynamicTheme}>
              {/* <ErrorPage
                handleJobsListingDynamicTheme={handleJobsListingDynamicTheme}
              /> */}
              <ForbiddenPage/>
            </ThemeProvider>
          }
        />
          <Route
          path="pageNotFound"
          element={
            <ThemeProvider theme={jobsListingDynamicTheme}>
              {/* <ErrorPage
                handleJobsListingDynamicTheme={handleJobsListingDynamicTheme}
              /> */}
              <NotFoundPage/>
            </ThemeProvider>
          }
        />
        <Route
          path="whoshiring"
          element={
            <ThemeProvider theme={jobsListingDynamicTheme}>
              <Hiring
                handleJobsListingDynamicTheme={handleJobsListingDynamicTheme}
              />
            </ThemeProvider>
          }
        />
        <Route
          path="jobs/:basic"
          element={
            <ThemeProvider theme={jobsListingDynamicTheme}>
              <Jobs
                handleJobsListingDynamicTheme={handleJobsListingDynamicTheme}
              />
            </ThemeProvider>
          }
        />
        <Route
            path="assessment"
            element={
              <PrivateRoute>
                <ThemeProvider theme={candidateDynamicTheme}>
                  <CommonAssessment
                    handleCandidateDynamicTheme={handleCandidateDynamicTheme}
                  />
                </ThemeProvider>
              </PrivateRoute>
            }
          />
        <Route
          path="jobs/company/:companyid"
          element={
            <ThemeProvider theme={jobsListingDynamicTheme}>
              <Jobs
                handleJobsListingDynamicTheme={handleJobsListingDynamicTheme}
              />
            </ThemeProvider>
          }
        />
        <Route
          path="talent"
          element={
            <ThemeProvider theme={talentListingDynamicTheme}>
              <Talent
                handleTalentListingDynamicTheme={
                  handleTalentListingDynamicTheme
                }
              />
            </ThemeProvider>
          }
        />
        <Route
          path="talent/:basic"
          element={
            <ThemeProvider theme={talentListingDynamicTheme}>
              <Talent
                handleTalentListingDynamicTheme={
                  handleTalentListingDynamicTheme
                }
              />
            </ThemeProvider>
          }
        />
        {/* <Route path='solutions' element={<Solutions />} /> */}
        <Route
          exact
          path="recruiter"
          element={
            <PrivateRoute>
              <Recruiter />
            </PrivateRoute>
          }
        >
          <Route
            path="my-profile"
            element={
              <PrivateRoute>
                <ThemeProvider theme={employerDefaulTheme}>
                  <RecruiterProfileCard />
                </ThemeProvider>
              </PrivateRoute>
            }
          />
          <Route
            path="my-jobs"
            element={
              <PrivateRoute>
                <ThemeProvider theme={employerDefaulTheme}>
                  <RecruiterMyJobs />
                </ThemeProvider>
              </PrivateRoute>
            }
          />
          <Route
            path="post-a-job/:jobId?"
            element={
              <PrivateRoute>
                <ThemeProvider theme={dyanmicTheme}>
                  <RecruiterPostAJob handleDynamicTheme={handleDynamicTheme} />
                </ThemeProvider>
              </PrivateRoute>
            }
          />

          <Route
            path="my-cam"
            element={
              <PrivateRoute>
                <ThemeProvider theme={employerDefaulTheme}>
                  <RecruiterMyCam />
                </ThemeProvider>
              </PrivateRoute>
            }
          />

          <Route
            path="manage-talent/:jobId/:basic?"
            element={
              <PrivateRoute>
                <ThemeProvider theme={employerDefaulTheme}>
                  <ManageJob />
                </ThemeProvider>
              </PrivateRoute>
            }
          />

          <Route
            path="my-team"
            element={
              <PrivateRoute>
                <ThemeProvider theme={employerDefaulTheme}>
                  <MyTeams />
                </ThemeProvider>
              </PrivateRoute>
            }
          >
            <Route
              path=""
              element={
                <PrivateRoute>
                  <ThemeProvider theme={employerDefaulTheme}>
                    <TeamTable />
                  </ThemeProvider>
                </PrivateRoute>
              }
            />
            <Route
              path="add-new-member"
              element={
                <PrivateRoute>
                  <ThemeProvider theme={employerDefaulTheme}>
                    <AddNewMember />
                  </ThemeProvider>
                </PrivateRoute>
              }
            />
            <Route
              path="team-info/:id"
              element={
                <PrivateRoute>
                  <ThemeProvider theme={employerDefaulTheme}>
                    <TeamInfo />
                  </ThemeProvider>
                </PrivateRoute>
              }
            />
          </Route>

          <Route
            path="shared-jobs"
            element={
              <PrivateRoute>
                <ThemeProvider theme={promoterTheme}>
                  <SharedJobs />
                </ThemeProvider>
              </PrivateRoute>
            }
          />
          <Route
            path="my-referrals"
            element={
              <PrivateRoute>
                <ThemeProvider theme={promoterTheme}>
                  <Referrals />
                </ThemeProvider>
              </PrivateRoute>
            }
          />
        </Route>
        <Route
          exact
          path="employer"
          element={
            <PrivateRoute>
              <Employer />
            </PrivateRoute>
          }
        >
          <Route
            path="my-team"
            element={
              <PrivateRoute>
                <ThemeProvider theme={employerDefaulTheme}>
                  <MyTeams />
                </ThemeProvider>
              </PrivateRoute>
            }
          >
            <Route
              path=""
              element={
                <PrivateRoute>
                  <ThemeProvider theme={employerDefaulTheme}>
                    <TeamTable />
                  </ThemeProvider>
                </PrivateRoute>
              }
            />
            <Route
              path="add-new-member"
              element={
                <PrivateRoute>
                  <ThemeProvider theme={employerDefaulTheme}>
                    <AddNewMember />
                  </ThemeProvider>
                </PrivateRoute>
              }
            />
            <Route
              path="team-info/:id"
              element={
                <PrivateRoute>
                  <ThemeProvider theme={employerDefaulTheme}>
                    <TeamInfo />
                  </ThemeProvider>
                </PrivateRoute>
              }
            />
          </Route>
          <Route
            path="my-jobs"
            element={
              <PrivateRoute>
                <ThemeProvider theme={employerDefaulTheme}>
                  <MyJobs />
                </ThemeProvider>
              </PrivateRoute>
            }
          />
          <Route
            path="my-cam"
            element={
              <PrivateRoute>
                <ThemeProvider theme={employerDefaulTheme}>
                  <EmployerMyCam />
                </ThemeProvider>
              </PrivateRoute>
            }
          />
          <Route
            path="post-a-job/:jobId?"
            element={
              <PrivateRoute>
                <ThemeProvider theme={dyanmicTheme}>
                  <PostAJob handleDynamicTheme={handleDynamicTheme} />
                </ThemeProvider>
              </PrivateRoute>
            }
          />
          <Route
            path="post-a-job"
            element={
              <PrivateRoute>
                <ThemeProvider theme={dyanmicTheme}>
                  <PostAJob handleDynamicTheme={handleDynamicTheme} />
                </ThemeProvider>
              </PrivateRoute>
            }
          />
          <Route
            path="my-profile"
            element={
              <PrivateRoute>
                <ThemeProvider theme={employerDefaulTheme}>
                  <MyProfileEmp />
                </ThemeProvider>
              </PrivateRoute>
            }
          />
          <Route
            path="manage-talent/:jobId/:basic?"
            element={
              <PrivateRoute>
                <ThemeProvider theme={employerDefaulTheme}>
                  <ManageJob />
                </ThemeProvider>
              </PrivateRoute>
            }
          />
          <Route
            path="shared-jobs"
            element={
              <PrivateRoute>
                <ThemeProvider theme={promoterTheme}>
                  <SharedJobs />
                </ThemeProvider>
              </PrivateRoute>
            }
          />
          <Route
            path="my-referrals"
            element={
              <PrivateRoute>
                <ThemeProvider theme={promoterTheme}>
                  <Referrals />
                </ThemeProvider>
              </PrivateRoute>
            }
          />
        </Route>
        <Route
          exact
          path="candidate"
          element={
            <PrivateRoute>
              <Candidate />
            </PrivateRoute>
          }
        >
          <Route
            path="my-jobs"
            element={
              <PrivateRoute>
                <ThemeProvider theme={candidateDynamicTheme}>
                  <MyJobsCandidate
                    handleCandidateDynamicTheme={handleCandidateDynamicTheme}
                  />
                </ThemeProvider>
              </PrivateRoute>
            }
          />
          <Route
            path="my-cv"
            element={
              <PrivateRoute>
                <ThemeProvider theme={candidateDynamicTheme}>
                  <MyCV
                    handleCandidateDynamicTheme={handleCandidateDynamicTheme}
                  />
                </ThemeProvider>
              </PrivateRoute>
            }
          />
          <Route
            path="assessment"
            element={
              <PrivateRoute>
                <ThemeProvider theme={candidateDynamicTheme}>
                  <CandidateAssesment
                    handleCandidateDynamicTheme={handleCandidateDynamicTheme}
                  />
                </ThemeProvider>
              </PrivateRoute>
            }
          />
          <Route
            path="my-cam"
            element={
              <PrivateRoute>
                <ThemeProvider theme={candidateDynamicTheme}>
                  <MyCam />
                </ThemeProvider>
              </PrivateRoute>
            }
          />
          <Route
            path="my-profile"
            element={
              <PrivateRoute>
                <ThemeProvider theme={candidateDynamicTheme}>
                  <MyProfileCan
                    handleCandidateDynamicTheme={handleCandidateDynamicTheme}
                  />
                </ThemeProvider>
              </PrivateRoute>
            }
          />
          <Route
            path="shared-jobs"
            element={
              <PrivateRoute>
                <ThemeProvider theme={promoterTheme}>
                  <SharedJobs />
                </ThemeProvider>
              </PrivateRoute>
            }
          />
          <Route
            path="my-referrals"
            element={
              <PrivateRoute>
                <ThemeProvider theme={promoterTheme}>
                  <Referrals />
                </ThemeProvider>
              </PrivateRoute>
            }
          />
        </Route>
        <Route
          exact
          path="promoter"
          element={
            <PrivateRoute>
              <Promoter />
            </PrivateRoute>
          }
        >
          <Route
            path="my-profile"
            element={
              <PrivateRoute>
                <ThemeProvider theme={promoterTheme}>
                  <PromoterProfileCard />
                </ThemeProvider>
              </PrivateRoute>
            }
          />

          <Route
            path="shared-jobs"
            element={
              <PrivateRoute>
                <ThemeProvider theme={promoterTheme}>
                  <SharedJobs />
                </ThemeProvider>
              </PrivateRoute>
            }
          />
          <Route
            path="my-referrals"
            element={
              <PrivateRoute>
                <ThemeProvider theme={promoterTheme}>
                  <Referrals />
                </ThemeProvider>
              </PrivateRoute>
            }
          />
        </Route>
        <Route
          exact
          path="admin"
          element={
            <PrivateRoute>
              <Admin />
            </PrivateRoute>
          }
        >
          <Route
            path="admin-talent"
            element={
              <PrivateRoute>
                <AdminTalent />
              </PrivateRoute>
            }
          >
            <Route
              path="all-talent"
              element={
                <PrivateRoute>
                  <TalentsCommonComponent listName="All Talent" />
                </PrivateRoute>
              }
            />
            <Route
              path="client-pool"
              element={
                <PrivateRoute>
                  <TalentPool />
                </PrivateRoute>
              }
            />
            <Route
              path="client-pool/:poolId?"
              element={
                <PrivateRoute>
                  <TalentPoolInfo />
                </PrivateRoute>
              }
            />
            <Route
              path="talent-pool"
              element={
                <PrivateRoute>
                  <TalentPool />
                </PrivateRoute>
              }
            />
            <Route
              path="talent-pool/:poolId?"
              element={
                <PrivateRoute>
                  <TalentPoolInfo />
                </PrivateRoute>
              }
            />
            <Route
              path="my-talent"
              element={
                <PrivateRoute>
                  <MyTalents />
                </PrivateRoute>
              }
            />
            <Route
              path="my-talent/applicants"
              element={
                <PrivateRoute>
                  <Applicants />
                </PrivateRoute>
              }
            />
            <Route
              path="my-talent/followers"
              element={
                <PrivateRoute>
                  <Followers />
                </PrivateRoute>
              }
            />
          </Route>
          <Route
            path="maintenance"
            element={
              <PrivateRoute>
                <Maintenance />
              </PrivateRoute>
            }
          >
            <Route
              path="companies"
              element={
                <PrivateRoute>
                  <Company />
                </PrivateRoute>
              }
            />
            <Route
              path="candidatecompany"
              element={
                <PrivateRoute>
                  <CandidateCompanies />
                </PrivateRoute>
              }
            />
            <Route
              path="jobtitle"
              element={
                <PrivateRoute>
                  <JobTitles />
                </PrivateRoute>
              }
            />
            <Route
              path="skills"
              element={
                <PrivateRoute>
                  <Skills />
                </PrivateRoute>
              }
            />
            <Route
              path="tools"
              element={
                <PrivateRoute>
                  <Tools />
                </PrivateRoute>
              }
            />
            <Route
              path="qualifications"
              element={
                <PrivateRoute>
                  <Qualification />
                </PrivateRoute>
              }
            />
            <Route
              path="associations"
              element={
                <PrivateRoute>
                  <Associations />
                </PrivateRoute>
              }
            />
            <Route
              path="institutions"
              element={
                <PrivateRoute>
                  <Institution />
                </PrivateRoute>
              }
            />
            <Route
              path="schools"
              element={
                <PrivateRoute>
                  <Schools />
                </PrivateRoute>
              }
            />
            <Route
              path="towns"
              element={
                <PrivateRoute>
                  <Towns />
                </PrivateRoute>
              }
            />
            <Route
              path="nationalities"
              element={
                <PrivateRoute>
                  <Nationalities />
                </PrivateRoute>
              }
            />
            <Route
              path="languages"
              element={
                <PrivateRoute>
                  <Languages />
                </PrivateRoute>
              }
            />
            <Route
              path="industries"
              element={
                <PrivateRoute>
                  <Industries />
                </PrivateRoute>
              }
            />
            <Route
              path="company-culture"
              element={
                <PrivateRoute>
                  <CompanyCulture />
                </PrivateRoute>
              }
            />
            <Route
              path="qualification-types"
              element={
                <PrivateRoute>
                  <QualificationTypes />
                </PrivateRoute>
              }
            />
            <Route
              path="currencies"
              element={
                <PrivateRoute>
                  <Currencies />
                </PrivateRoute>
              }
            />
            <Route
              path="vouchers"
              element={
                <PrivateRoute>
                  <Vouchers />
                </PrivateRoute>
              }
            />
            <Route
              path="coins-history"
              element={
                <PrivateRoute>
                  <CoinsHistory />
                </PrivateRoute>
              }
            />
            <Route
              path="payment-history"
              element={
                <PrivateRoute>
                  <PaymentHistory />
                </PrivateRoute>
              }
            />
            <Route
              path="solutions"
              element={
                <PrivateRoute>
                  <AdminSolutions />
                </PrivateRoute>
              }
            />
            <Route
              path="policyPrivacy"
              element={
                <PrivateRoute>
                  <PolicyPrivacy />
                </PrivateRoute>
              }
            />
            <Route
              path="assessment"
              element={
                <PrivateRoute>
                  <AdminAssessment />
                </PrivateRoute>
              }
            />
            <Route
              path="contactUsQuery"
              element={
                <PrivateRoute>
                  <ContactUsQuery />
                </PrivateRoute>
              }
            />
            <Route
              path="subscribeList"
              element={
                <PrivateRoute>
                  <SubscribeList />
                </PrivateRoute>
              }
            />
          </Route>
          <Route
            path="adminJobs"
            element={
              <PrivateRoute>
                <AdminJobs />
              </PrivateRoute>
            }
          >
            <Route
              path="all-jobs/:compname?"
              element={
                <PrivateRoute>
                  <AllJobs />
                </PrivateRoute>
              }
            />
            <Route
              path="/admin/adminJobs/:page/system-spec/:id"
              // element={<AdminJobsDetailPage />}
              element={<JobsSystemSpec />}
            />
            <Route
              path="active-jobs"
              element={
                <PrivateRoute>
                  <ActiveJobs />
                </PrivateRoute>
              }
            />
            <Route
              path="active-jobs/:id"
              element={
                <PrivateRoute>
                  <ActiveJobDetail />
                </PrivateRoute>
              }
            />
            <Route
              path="pending-jobs"
              element={
                <PrivateRoute>
                  <PendingJobs />
                </PrivateRoute>
              }
            />
            <Route
              path="paused-jobs"
              element={
                <PrivateRoute>
                  <PausedJobs />
                </PrivateRoute>
              }
            />
            <Route
              path="closed-jobs"
              element={
                <PrivateRoute>
                  <ClosedJobs />
                </PrivateRoute>
              }
            />
            <Route
              path="expired_jobs"
              element={
                <PrivateRoute>
                  <ExpiredJobs />
                </PrivateRoute>
              }
            />
            <Route
              path="incognito-jobs"
              element={
                <PrivateRoute>
                  <IncognitoJobs />
                </PrivateRoute>
              }
            />

            <Route
              path="crayon-direct"
              element={
                <PrivateRoute>
                  <CrayonDirect />
                </PrivateRoute>
              }
            />
            <Route
              path="crayon-recruit"
              element={
                <PrivateRoute>
                  <CrayonRecruit />
                </PrivateRoute>
              }
            />
          </Route>
          <Route
            path="Search"
            element={
              <PrivateRoute>
                <Search />
              </PrivateRoute>
            }
          >
            <Route
              path="my-search"
              element={
                <PrivateRoute>
                  <SearchPool />
                </PrivateRoute>
              }
            />
            <Route
              path="my-search/:searchID"
              element={
                <PrivateRoute>
                  <BuildSearch />
                </PrivateRoute>
              }
            />
            <Route
              path="client-search"
              element={
                <PrivateRoute>
                  <SearchPool />
                </PrivateRoute>
              }
            />
            <Route
              path="client-search/:searchID"
              element={
                <PrivateRoute>
                  <BuildSearch />
                </PrivateRoute>
              }
            />
            <Route
              path="build-search"
              element={
                <PrivateRoute>
                  <BuildSearch />
                </PrivateRoute>
              }
            />
            <Route
              path="job-title-search"
              element={
                <PrivateRoute>
                  <JobTitleSearch />
                </PrivateRoute>
              }
            />
            <Route
              path="companies"
              element={
                <PrivateRoute>
                  <CompanySearch />
                </PrivateRoute>
              }
            />
            <Route
              path="industries"
              element={
                <PrivateRoute>
                  <IndustrySearch />
                </PrivateRoute>
              }
            />
            <Route
              path="skills-search"
              element={
                <PrivateRoute>
                  <TagsSearch />
                </PrivateRoute>
              }
            />
            <Route
              path="tools-search"
              element={
                <PrivateRoute>
                  <ToolsSearch />
                </PrivateRoute>
              }
            />
            <Route
              path="qualifications-search"
              element={
                <PrivateRoute>
                  <QualificationsSearch />
                </PrivateRoute>
              }
            />
            <Route
              path="institution-search"
              element={
                <PrivateRoute>
                  <InstitutionSearch />
                </PrivateRoute>
              }
            />
            <Route
              path="associations-search"
              element={
                <PrivateRoute>
                  <AssociationSearch />
                </PrivateRoute>
              }
            />
            <Route
              path="schools-search"
              element={
                <PrivateRoute>
                  <SchoolSearch />
                </PrivateRoute>
              }
            />
            <Route
              path="towns-search"
              element={
                <PrivateRoute>
                  <TownsSearch />
                </PrivateRoute>
              }
            />
            <Route
              path="nationalities-search"
              element={
                <PrivateRoute>
                  <NationalitySearch />
                </PrivateRoute>
              }
            />
            <Route
              path="languages-search"
              element={
                <PrivateRoute>
                  <LanguageSearch />
                </PrivateRoute>
              }
            />
            <Route
              path="association"
              element={
                <PrivateRoute>
                  <AssociationSearch />
                </PrivateRoute>
              }
            />
          </Route>

          <Route path="users">
            <Route
              path="user-companies"
              element={
                <PrivateRoute>
                  <ThemeProvider theme={adminUsersTheme}>
                    <Companies />
                  </ThemeProvider>
                </PrivateRoute>
              }
            />
            <Route
              path="user-companies/:id"
              element={
                <PrivateRoute>
                  <ThemeProvider theme={adminUsersTheme}>
                    <CompaniesDetailInfo />
                  </ThemeProvider>
                </PrivateRoute>
              }
            />
            <Route
              path="user-employers"
              element={
                <PrivateRoute>
                  <UserEmployer />
                </PrivateRoute>
              }
            />
            <Route
              path="user-employers/:id"
              element={
                <PrivateRoute>
                  <EmployerDetailInfo />
                </PrivateRoute>
              }
            />
            <Route
              path="user-recruiters"
              element={
                <PrivateRoute>
                  <UserRecruiter />
                </PrivateRoute>
              }
            />
            <Route
              path="user-recruiters/:id"
              element={
                <PrivateRoute>
                  <RecruiterDetailInfo />
                </PrivateRoute>
              }
            />
            <Route
              path="user-promoters"
              element={
                <PrivateRoute>
                  <Promoters />
                </PrivateRoute>
              }
            />
            <Route
              path="user-crayon-team/crayonTeam-admins"
              element={
                <PrivateRoute>
                  <CrayonsTeamAdmins />
                </PrivateRoute>
              }
            />
            <Route
              path="user-crayon-team/crayonTeam-superAdmins"
              element={
                <PrivateRoute>
                  <CrayonsSuperAdmin />
                </PrivateRoute>
              }
            />
            <Route
              path="user-crayon-team"
              element={
                <PrivateRoute>
                  <UserCrayonTeam />
                </PrivateRoute>
              }
            />
            <Route
              path="user-crayon-team/crayonTeam-talentPartners"
              element={
                <PrivateRoute>
                  <TalentPartnerAdmins />
                </PrivateRoute>
              }
            />
          </Route>

          <Route
            path="*"
            element={
              <PrivateRoute>
                <ComingSoon />
              </PrivateRoute>
            }
          />
        </Route>
        <Route path="*" element={<ComingSoon />} />
        <Route
          path="candidate-cv/:id"
          element={
            <ThemeProvider theme={candidateDynamicTheme}>
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <CandidateCVPageNew />
              </Box>
            </ThemeProvider>
          }
        />
        <Route
          path="/:prev1/:prev2/:prev3/candidate-cv/:id"
          element={<TalentDetailPageNew />}
        />
        <Route
          path="/:prev1/:prev2/:prev3/:id"
          element={<PromoterDetailInfo />}
        />
        <Route
          path="/jobs/:location/:location1/:company?/:title/:id"
          element={
            <ThemeProvider theme={jobDetailsPageTheme}>
              <JobsDetailPage />
            </ThemeProvider>
          }
        />
        <Route
          path="/jobs/:location/:location1/:company/:title/:id/:shareToken"
          element={
            <ThemeProvider theme={jobDetailsPageTheme}>
              <JobsDetailPage />
            </ThemeProvider>
          }
        />
        <Route
          path="/job-detail/:location/:location1/:title/:id"
          element={
            <ThemeProvider theme={jobDetailsPageTheme}>
              <JobsDetailPage />
            </ThemeProvider>
          }
        />
        <Route
          path="/job-detail/:location/:location1/:title/:id/:shareToken"
          element={
            <ThemeProvider theme={jobDetailsPageTheme}>
              <JobsDetailPage />
            </ThemeProvider>
          }
        />
        <Route
          path="/admin/job-detail/:location/:id"
          // element={<AdminJobsDetailPage />}
          element={<AdminJobdetailInfo />}
        />
        <Route
          path="/job/:id/sharejob"
          // element={<AdminJobsDetailPage />}
          element={<ShareJob />}
        />
      </Route>
      <Route
        path="*"
        element={
          <PrivateRoute>
            <ComingSoon />
          </PrivateRoute>
        }
      />
    </Routes>
  );
}
