import * as Yup from "yup";

export const CANDIDATE_BASIC_SCHEMA = Yup.object().shape({
  current_job_title_id: Yup.string().required("Current job title is required"),
  dream_job_title_id: Yup.string().required("Dream job title is required"),
  employment_type: Yup.string().required("Employment type is required"),
  work_setup: Yup.string().required("Work setup is required"),
  industries: Yup.array()
    .min(1, "At least one industry is required")
    .max(5, "No more than 5 industries")
    .of(Yup.string()),
  tags: Yup.array()
    .min(3, "At least three skills are required")
    .max(10, "No more than 10 skills")
    .of(Yup.string()),
  tools: Yup.array()
    .min(3, "At least three tools are required")
    .max(10, "No more than 10 tools")
    .of(Yup.string()),
  qualification_level: Yup.string().required("Qualification level is required"),
  currency_id: Yup.string().required("Currency is required"),
  salary: Yup.array().of(Yup.number().required("Salary range is required")),
  experience_id: Yup.number()
    .min(0, "Invalid experience")
    .max(20, "Invalid experience")
    .required("Experience is required"),
  notice_period_id: Yup.number()
    .min(0, "Invalid notice period")
    .max(7, "Invalid notice period")
    .required("Notice period is required"),
});

export const EDIT_TALENT_SCHEMA = Yup.object().shape({
  current_job_title_id: Yup.string().required("Current job title is required"),
  dream_job_title_id: Yup.string().required("Dream job title is required"),
  employment_type: Yup.string().required("Employment type is required"),
  work_setup: Yup.string().required("Work setup is required"),
  industries: Yup.array()
    .min(1, "At least one industry is required")
    .max(5, "No more than 5 industries")
    .of(Yup.string()),
  tags: Yup.array()
    .min(3, "At least three skills are required")
    .max(10, "No more than 10 skills")
    .of(Yup.string()),
  tools: Yup.array()
    .min(3, "At least three tools are required")
    .max(10, "No more than 10 tools")
    .of(Yup.string()),
  qualification_level: Yup.string().required("Qualification level is required"),
  currency_id: Yup.string().required("Currency is required"),
  salary: Yup.array().of(Yup.number().required("Salary range is required")),
  experience_id: Yup.number()
    .min(0, "Invalid experience")
    .max(20, "Invalid experience")
    .required("Experience is required"),
  notice_period_id: Yup.number()
    .min(0, "Invalid notice period")
    .max(7, "Invalid notice period")
    .required("Notice period is required"),
  company_name: Yup.string().required("Company name is required"),
  title: Yup.string().required("Job title is required"),
  start_date: Yup.date()
    .nullable()
    .required("Start date is required")
    .max(Yup.ref("end_date"), "Start date must be before end date"),
});
