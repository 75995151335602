import React, { useEffect, useState } from "react";
import locale from "../../../i18n/locale";
import { Box, Divider, Typography, useTheme } from "@mui/material";
import { useDispatch } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { setAlert, setLoading } from "../../../redux/configSlice";
import { getCandidateCV } from "../../../redux/employer/myJobsSlice";
import { ALERT_TYPE, ERROR_MSG } from "../../../utils/Constants";
import {
  calculateDaysBetween,
  createMarkup,
  splitStringWithParentheses,
} from "../../../utils/Common";
import { formatCurrencyWithCommas } from "../../../utils/Currency";
import location from "../../../assets/Padding Excluded/Blue_Location.svg";
import contact from "../../../assets/Padding Excluded/Blue_Contact.svg";
import email from "../../../assets/Padding Excluded/Blue_Email.svg";
import { formatPhoneNumberIntl } from "react-phone-number-input";
import TalentSVGButton from "../../common/TalentSVGButton";
import linkedin from "../../../assets/linkedin.svg";
import portfolioIcon from "../../../assets/CircularIcon/Red/Circular Icons_Red_Portfolio.svg";
import { monthYear } from "../../../utils/DateTime";
import { Circle } from "@mui/icons-material";
import useViewport from "../../common/ViewPort";
import ClassCVPageLeftSide from "./ClassCVPageLeftSide";
import ClassicCVPageRightSide from "./ClassicCVPageRightSide";

export default function ClassicCVPage({
  pdfRef,
  leftExpanded,
  candidateCV,
  exportAsPDF,
}) {
  const i18n = locale.en;
  const theme = useTheme();
  const dispatch = useDispatch();
  const { id } = useParams();
  const { width } = useViewport();
  // const [isLoading, setIsLoading] = useState(true);
  const [stateData, setStateData] = useState([]);
  const isLoading = useSelector((state) => state?.config?.loading);

  const handleCandidateCV = async () => {
    // setIsLoading(true);
    dispatch(setLoading(true));
    try {
      const user = {
        user_id: id !== undefined ? id : "",
      };
      const { payload } = await dispatch(getCandidateCV(user));
      if (payload?.status === "success") {
        setStateData(payload?.data?.at(0));
        // setCVLink(payload?.data?.candidate_profile?.candidate_info?.cv_link);
      }
      // setIsLoading(false);
      dispatch(setLoading(false));
    } catch (error) {
      dispatch(
        setAlert({
          show: true,
          type: ALERT_TYPE?.ERROR,
          msg: ERROR_MSG,
        })
      );
      // setIsLoading(false);
      dispatch(setLoading(false));
    }
  };

  const calculateHeight = () => {
    const screenWidth = window.innerWidth;

    if (screenWidth < 600) {
      // xs
      return 90;
    } else if (screenWidth < 900) {
      // sm
      return 90;
    } else if (screenWidth < 1366) {
      // md
      return 90;
    } else if (screenWidth < 1920) {
      // lg
      return 90;
    } else if (screenWidth < 2559) {
      // xl
      return 100;
    } else {
      // xxl or greater
      return 100;
    }
  };

  useEffect(() => {
    handleCandidateCV();
  }, []);

  const calculateAge = (dateString) => {
    const birthDate = new Date(dateString);
    const currentDate = new Date();
    let years = currentDate?.getUTCFullYear() - birthDate?.getUTCFullYear();
    let months = currentDate?.getUTCMonth() - birthDate?.getUTCMonth();
    if (
      months < 0 ||
      (months === 0 && currentDate?.getUTCDate() < birthDate?.getUTCDate())
    ) {
      years--;
      months = 12 + months;
    }
    // If the age is less than a month, return "1 month"
    if (years === 0 && months === 0) {
      return "1 month";
    }
    let result = "";
    if (years > 0) result += `${years} year${years > 1 ? "s" : ""} `;
    if (months > 0) result += `${months} month${months > 1 ? "s" : ""}`;
    return result?.trim();
  };

  return (
    !isLoading && (
      <Box
        width={!candidateCV ? "62%" : "100%"}
        marginTop={!candidateCV && "55px"}
        sx={{
          cursor: "auto !important",
          boxShadow: "0px 5px 10px #00000029 !important",
          borderRadius: "25px",
          marginBottom: !candidateCV && "55px",
          position: "relative",
          background: "#ffff",
        }}
      >
        <Box
          ref={pdfRef}
          sx={{
            display: "flex",
            flexDirection: width > 390 || exportAsPDF ? "row" : "column",
          }}
        >
          <ClassCVPageLeftSide
            exportAsPDF={exportAsPDF}
            stateData={stateData}
          />

          <ClassicCVPageRightSide
            exportAsPDF={exportAsPDF}
            stateData={stateData}
          />
        </Box>
      </Box>
    )
  );
}
