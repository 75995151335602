import {
  Box,
  Button,
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Stack,
  useTheme,
} from "@mui/material";
import React, { useRef, useState } from "react";
import activeDownClose from "../../../../assets/Black_Down_Open - Copy.svg";
import { Circle } from "@mui/icons-material";

export default function ChangeSolutionsStatus({
  status = "active",
  loggedInUser,
}) {
  const anchorRef = useRef(null);
  const theme = useTheme();
  const [openActive, setOpenActive] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const isDisabled = loggedInUser !== 1 ? true : false;

  const handleCloseActive = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event?.target)) {
      return;
    }

    setOpenActive(false);
  };

  const handleToggle = () => {
    setOpenActive((prevOpen) => !prevOpen);
  };

  const handleListKeyDown = (event) => {
    if (event?.key === "Tab") {
      event.preventDefault();
      setOpenActive(false);
    } else if (event?.key === "Escape") {
      setOpenActive(false);
    }
  };

  return (
    <>
      <Button
        ref={anchorRef}
        id="composition-button"
        aria-controls={open ? "composition-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
        variant="contained"
        color="base"
        endIcon={
          <Box
            component="img"
            className="eye"
            alt="eye logo"
            src={activeDownClose}
            sx={{
              height: 25,
              width: 25,
            }}
          />
        }
        sx={{
          "&:hover": {
            boxShadow: "none",
          },
          fontSize: "12px",
          fontWeight: 700,
          color: "#000",
          width: "100%",
          padding: "8px 12px 8px 18px",
          borderRadius: "0px 10px 0 0",
          borderRight: "1px solid #EBECF3",
          borderBottom: "1px solid #EBECF3",
          height: "100% !important",
          cursor: `${isDisabled && "default"}`,
          "& .MuiButton-endIcon": {
            margin: "0px !important",
          },
        }}
      >
        {status}
        <Circle
          fontSize="string"
          color={
            status === "pending"
              ? "orangeButton"
              : status === "active"
              ? "lightGreenButton300"
              : status === "reactive"
              ? "lightGreenButton300"
              : status === "paused"
              ? "orangeButton"
              : "redButton100"
          }
          sx={{ marginLeft: "10px" }}
        />
      </Button>
      <Stack
        direction="row"
        spacing={2}
        sx={{
          zIndex: "1000 !important",
          position: "relative",
          cursor: "pointer",
        }}
      >
        <Box>
          <Popper
            open={isDisabled ? false : openActive}
            anchorEl={anchorRef.current}
            role={undefined}
            placement="bottom-start"
            transition
            disablePortal={isDisabled}
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin:
                    placement === "bottom-start" ? "left top" : "left bottom",
                }}
              >
                <Paper
                  sx={{
                    width: "100px !important",
                    borderRadius: "0 0 10px 10px !important",
                  }}
                >
                  <ClickAwayListener onClickAway={handleCloseActive}>
                    <MenuList
                      autoFocusItem={openActive}
                      id="composition-menu"
                      aria-labelledby="composition-button"
                      onKeyDown={handleListKeyDown}
                    >
                      {status === "active" && [
                        <>
                          <MenuItem
                            key="closed"
                            // onClick={() => handleStatusChange("closed")}
                            sx={{
                              fontSize: "12px",
                              fontWeight: theme.typography.Regular,
                              justifyContent: "space-between",
                            }}
                          >
                            close
                            <Circle
                              fontSize="string"
                              color={"redButton100"}
                              sx={{ marginLeft: "10px" }}
                            />
                          </MenuItem>
                        </>,
                      ]}
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </Box>
      </Stack>
    </>
  );
}
