import React, { useEffect, useRef, useState } from "react";
import { Box, IconButton, Popover, Tooltip } from "@mui/material";
import Typography from "@mui/material/Typography";
import cv from "../../../../assets/JMD/CVIcon.svg";
import playIcon from "../../../../assets/JMD/Crayon_Cam.svg";
import playIconGrey from "../../../../assets/JMD/Crayon_Cam_Grey.svg"
import playIconRed from "../../../../assets/CircularIcon/Red/Circular Icons__Red_Play.svg";
import chatHistory from "../../../../assets/JMD/AlertIcon.svg";
import chat from "../../../../assets/JMD/MessageIcon.svg";
import user from "../../../../assets/CircularIcon/Red/Circular Icons__Red_User_Profile.svg";
import email from "../../../../assets/JMD/UnReadMessageIcon.svg";
import duplicate from "../../../../assets/JMD/CopyLinkIcon.svg";
import contact from "../../../../assets/JMD/CallIcon.svg";
import linkedin from "../../../../assets/linkedin.svg";
import personalDetail from "../../../../assets/JMD/ContactInfoIcon.svg";
import SVGButton from "../../../common/SVGButton";
import { useDispatch } from "react-redux";
import CopyToClipboard from "react-copy-to-clipboard";
import { setAlert } from "../../../../redux/configSlice";
import { ALERT_TYPE } from "../../../../utils/Constants";
import { Link, useNavigate } from "react-router-dom";
import { useTheme } from "@emotion/react";
import ReviewVideoDialog from "../../../common/ReviewVideoDialog";
import VideoDialog from "../../../admin/adminTalent/DialogBox/VideoDialog";
import Document from "../../../admin/adminTalent/DialogBox/Document";
import HistoryBox from "../../HistoryBox";
import ChatBox from "../ChatBox";
import CloseIcon from "../../CloseIcon";
import ManageDashboardHistoryBox from "../../ManageDashboardHistoryBox";

export default function ExpandBottomButtons({
  phoneNo,
  emailAddress,
  linkedinAddress,
  userID,
  cvLink,
  videoLink,
  crayonCam,
  portfolio_url,
  jobDetail,
  calendar_link,
  job_id,
  setJobDetail,
  talentInfo
}) {
  const dispatch = useDispatch();
  const theme = useTheme();
  const navigate = useNavigate();
  const historyChatRef = useRef(null);
  const [openInfoDialog, setOpenInfoDialog] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [opeVideoDialog, setOpeVideoDialog] = useState(false);
  const [personalDetails, setPersonalDetail] = useState(false);
  const [openReviewVideoDialog, setOpenReviewVideoDialog] = useState(false);
  const [viewVideoLink, setViewVideolink] = useState("");
  const [isOpenHistoryBox, setOpenHistoryBox] = useState(false);
  const [openChatDialog, setOpenChatDialog] = useState(false);
  
  const handleCloseDialog = () => {
    setViewVideolink("");
    setOpenReviewVideoDialog(false);
  };
  const handlePlay = (link) => {
    if (link !== "No URL") {
      setOpenReviewVideoDialog(true);
      setViewVideolink(link);
    } else {
      dispatch(
        setAlert({
          show: true,
          type: ALERT_TYPE?.ERROR,
          msg: "No video is provided by the candidate",
        })
      );
    }
  };

  const onHandleClose = () => {
    setOpenInfoDialog(false);
    setAnchorEl(null);
  };

  const handleInfoDialog = (event) => {
    setOpenInfoDialog(true);
    !openInfoDialog && setAnchorEl(event?.target);
  };

  const handleVideoDialog = (event) => {
    setOpeVideoDialog(true);
    !opeVideoDialog && setAnchorEl(event?.target);
    
  };

  const handlePersonalDetail = (event) => {
    setPersonalDetail(true);
    !opeVideoDialog && setAnchorEl(event?.target);
  };

  const onHandleCloseVideo = () => {
    setOpeVideoDialog(false);
    setAnchorEl(null);
  };
  const handleCloseContact = () => {
    setPersonalDetail();
    setAnchorEl(null);
  };
  const onHandleClosePersonal = () => {
    setPersonalDetail(false);
    setAnchorEl(null);
  };

  const handleChatDialog = (event) => {
    setOpenChatDialog(true);
    !openChatDialog && setAnchorEl(event?.target);
  };

  const handleHistoryDialog = (event) => {
    setOpenHistoryBox(historyChatRef.current);
    // !isOpenHistoryBox && setAnchorEl();
  };
  const onHandleCloseHistory = () => {
    setOpenHistoryBox(null);
    setAnchorEl(null);
  };
  const onHandleCloseChat = () => {
    setOpenChatDialog(false);
    setAnchorEl(null);
  };

  const handleCvLink = (cv) => {
    if (cv !== "No URL") {
      window?.open(cv, "_blank");
    } else {
      dispatch(
        setAlert({
          show: true,
          type: ALERT_TYPE?.ERROR,
          msg: "CV is not uploaded",
        })
      );
    }
  };

  const handleLinkedin = (linkedin) => {
    if (linkedin) {
      window?.open(`${linkedin}`);
    } else {
      dispatch(
        setAlert({
          show: true,
          type: ALERT_TYPE?.ERROR,
          msg: "Link is not provided by the user",
        })
      );
    }
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          margin: "10px",
          gap: "10px",
        }}
      >
        <SVGButton
          color={"white"}
          source={(talentInfo?.job_cam_url === "No URL" && talentInfo?.cam_url === "No URL") ? playIconGrey:playIcon}
          onClick={(event)=>{
            if(talentInfo?.job_cam_url === "No URL" && talentInfo?.cam_url === "No URL"){

            }else{
              handleVideoDialog(event)
            }
          }}
          height={35}
          width={35}
          startIconMargin={"0px !important"}
          padding={"0px !important"}
        />
        <Popover
          id="dropdown-menu"
          open={opeVideoDialog}
          anchorEl={anchorEl}
          onClose={onHandleCloseVideo}
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          sx={{
            "& .css-ll95b0-MuiPaper-root-MuiPopover-paper": {
              minWidth: "325px !important",
              maxWidth: "325px !important",
              borderRadius: "10px !important",
              mt: 1,
            },
          }}
        >
          <VideoDialog
            handleOpen={onHandleCloseVideo}
            camlink={crayonCam}
            applicationlink={videoLink}
            isScreeningInterview={true}
            userID={userID}
            crayonCam={talentInfo?.cam_url}
            applicationCamLink={talentInfo?.job_cam_url}
          />
        </Popover>

        <SVGButton
          color={"white"}
          source={cv}
          onClick={handleInfoDialog}
          height={35}
          width={35}
          startIconMargin={"0px !important"}
          padding={"0px !important"}
        />

        <Popover
          id="dropdown-menu"
          open={openInfoDialog}
          anchorEl={anchorEl}
          onClose={onHandleClose}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          sx={{
            "& .css-ll95b0-MuiPaper-root-MuiPopover-paper": {
              minWidth: "325px !important",
              maxWidth: "325px !important",
              borderRadius: "10px !important",
              mt: 1,
            },
          }}
        >
          <Document
            handleOpen={onHandleClose}
            cvLink={cvLink}
            userID={userID}
            portfolio_url={portfolio_url ? portfolio_url : "No URL"}
            isPortfolioLink={true}
          />
        </Popover>

        <SVGButton
          color={"white"}
          source={personalDetail}
          onClick={handlePersonalDetail}
          height={35}
          width={35}
          outerHeight={35}
          outerWidth={35}
          startIconMargin={"0px !important"}
          padding={"0px !important"}
        />
        <Popover
          id="dropdown-menu"
          open={personalDetails}
          anchorEl={anchorEl}
          onClose={onHandleClosePersonal}
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          sx={{
            "& .css-ll95b0-MuiPaper-root-MuiPopover-paper": {
              minWidth: "325px !important",
              maxWidth: "325px !important",
              borderRadius: "10px !important",
              mt: 1,
            },
          }}
        >
          <Box
            sx={{
              width: "250px",
              padding: "16px !important",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography
                sx={{
                  fontWeight: 700,
                  fontSize: "14px",
                }}
              >
                Contact info
              </Typography>
              <IconButton
                aria-label="close"
                onClick={() => {
                  handleCloseContact();
                }}
                sx={{
                  position: "absolute",
                  top: 0,
                  right: "5px",
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                <Box
                  sx={{
                    width: "5px",
                    height: "5px",
                    color: "#000000",
                    fontSize: "10px",
                  }}
                >
                  <CloseIcon />
                </Box>
              </IconButton>
            </Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Box sx={{ display: "flex", pt: 1 }}>
                <SVGButton
                  color={"white"}
                  source={email}
                  height={35}
                  width={35}
                  outerHeight={35}
                  outerWidth={35}
                  startIconMargin={"0px !important"}
                  padding={"0px !important"}
                />

                <Tooltip
                  disableInteractive
                  title={emailAddress}
                  placement="top-end"
                >
                  <Typography
                    sx={{
                      fontWeight: 700,
                      fontSize: "14px",
                      marginLeft: "16px !important",
                      width: "150px",
                      margin: "auto",
                      whiteSpace: "nowrap", // Prevents text from wrapping
                      overflow: "hidden", // Hides any overflowing content
                      textOverflow: "ellipsis", // Adds dots at the end of overflowing text
                    }}
                  >
                    {emailAddress}
                  </Typography>
                </Tooltip>
              </Box>
              <CopyToClipboard
                text={emailAddress}
                onCopy={() => {
                  dispatch(
                    setAlert({
                      show: true,
                      type: ALERT_TYPE?.SUCCESS,
                      msg: "Copied to clipboard",
                    })
                  );
                }}
              >
                <SVGButton
                  color={"black"}
                  source={duplicate}
                  height={40}
                  width={40}
                  startIconMargin={"0px !important"}
                  padding={"0px !important"}
                />
              </CopyToClipboard>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Box sx={{ display: "flex", pt: 1 }}>
                <SVGButton
                  color={"white"}
                  source={contact}
                  height={35}
                  width={35}
                  outerHeight={35}
                  outerWidth={35}
                  startIconMargin={"0px !important"}
                  padding={"0px !important"}
                />
                <Typography
                  sx={{
                    fontWeight: 700,
                    fontSize: "14px",
                    margin: "auto",
                    marginLeft: "16px !important",
                  }}
                >
                  {phoneNo}
                  {/*formatPhoneNumberIntl(
                    `${countryCode}${phoneNo}`
                  )*/}
                </Typography>
              </Box>
              <CopyToClipboard
                text={phoneNo}
                onCopy={() => {
                  dispatch(
                    setAlert({
                      show: true,
                      type: ALERT_TYPE?.SUCCESS,
                      msg: "Copied to clipboard",
                    })
                  );
                }}
              >
                <SVGButton
                  color={"black"}
                  source={duplicate}
                  height={40}
                  width={40}
                  startIconMargin={"0px !important"}
                  padding={"0px !important"}
                />
              </CopyToClipboard>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                cursor: linkedinAddress !== null ? "pointer" : "default",
              }}
            >
              {/* <Link
                to={`${linkedinAddress}`}
                target="_blank"
                style={{
                  textDecoration: "none",
                  color: theme.palette.black,
                  cursor: "pointer",
                }}
              > */}
              <Box
                sx={{
                  display: "flex",
                  pt: 1,
                  cursor: linkedinAddress !== null ? "pointer" : "default",
                }}
                onClick={() =>
                  linkedinAddress !== null && handleLinkedin(linkedinAddress)
                }
              >
                <SVGButton
                  color={"white"}
                  source={linkedin}
                  cursor={linkedinAddress !== null ? "pointer" : "default"}
                />
                <Tooltip
                  disableInteractive
                  title={linkedinAddress}
                  placement="top"
                >
                  <Typography
                    sx={{
                      fontWeight: 700,
                      fontSize: "14px",
                      marginLeft: "16px !important",
                      width: "150px",
                      margin: "auto",
                      whiteSpace: "nowrap", // Prevents text from wrapping
                      overflow: "hidden", // Hides any overflowing content
                      textOverflow: "ellipsis", // Adds dots at the end of overflowing text
                    }}
                  >
                    {linkedinAddress !== null ? linkedinAddress : "-"}
                  </Typography>
                </Tooltip>
              </Box>
              {/* </Link> */}
              <CopyToClipboard
                text={linkedinAddress}
                onCopy={() => {
                  linkedinAddress !== null &&
                    dispatch(
                      setAlert({
                        show: true,
                        type: ALERT_TYPE?.SUCCESS,
                        msg: "Copied to clipboard",
                      })
                    );
                }}
              >
                <SVGButton
                  color={"black"}
                  source={duplicate}
                  height={40}
                  width={40}
                  startIconMargin={"0px !important"}
                  padding={"0px !important"}
                  cursor={linkedinAddress !== null ? "pointer" : "default"}
                />
              </CopyToClipboard>
            </Box>
          </Box>
        </Popover>
        <Box ref={historyChatRef}>
          <SVGButton
            color={"white"}
            source={chatHistory}
            height={35}
            width={35}
            startIconMargin={"0px !important"}
            padding={"0px !important"}
            onClick={() => handleHistoryDialog()}
            ref={historyChatRef}
          />
        </Box>

        <Popover
          id="dropdown-menu"
          open={Boolean(isOpenHistoryBox)}
          anchorEl={isOpenHistoryBox}
          onClose={onHandleCloseHistory}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          sx={{
            "& .css-ll95b0-MuiPaper-root-MuiPopover-paper": {
              maxWidth: "450px !important",
              minWidth: "450px !important",
              maxHeight: "336px",
              borderRadius: "10px !important",
              mt: 1,
            },
          }}
        >
          <ManageDashboardHistoryBox
            handleOpen={isOpenHistoryBox}
            handleClose={() => setOpenHistoryBox(false)}
            openFor={"jobs"}
            email={emailAddress}
            component={"manageDashboard"}
            jobDetail={jobDetail}
          />
        </Popover>
        <SVGButton
          color={"white"}
          source={chat}
          height={35}
          width={35}
          startIconMargin={"0px !important"}
          padding={"0px !important"}
          onClick={handleChatDialog}
        />
        <Popover
          id="dropdown-menu"
          open={openChatDialog}
          anchorEl={anchorEl}
          onClose={onHandleCloseChat}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          sx={{
            "& .css-ll95b0-MuiPaper-root-MuiPopover-paper": {
              minWidth: "325px !important",
              maxWidth: "325px !important",
              borderRadius: "10px !important",
              mt: 1,
            },
          }}
        >
          <ChatBox
            handleOpen={onHandleCloseChat}
            jobDetail={jobDetail}
            userID={userID}
            calendar_link={calendar_link}
            job_id={job_id}
            setJobDetail={setJobDetail}
          />
        </Popover>
      </Box>
      {openReviewVideoDialog && (
        <ReviewVideoDialog
          videoLink={viewVideoLink}
          open={openReviewVideoDialog}
          setOpen={handleCloseDialog}
        />
      )}
      {/* {isOpenHistoryBox && (
        <HistoryBox
          handleOpen={isOpenHistoryBox}
          handleClose={() => setOpenHistoryBox(false)}
          openFor={"jobs"}
          email={emailAddress}
          component={"manageDashboard"}
          jobDetail={jobDetail}
        />
      )} */}
    </>
  );
}
