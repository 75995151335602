import React, { useState } from "react";
import GreenPlay from "../../../../assets/CircularIcon/Green/Circular Icons__Green_Play copy.svg";
import GreenPlayGrey from "../../../../assets/CircularIcon/Grey/Circular_Icon_Grey_Play.svg";
import { Box, IconButton, Typography, useTheme } from "@mui/material";
import SVGButton from "../../../common/SVGButton";
import ReviewVideoDialog from "../../../common/ReviewVideoDialog";

export default function VideoBox({
  handleClose,
  jobContent,
  text1,
  text2,
  employerVideo,
  jobVideo,
}) {
  const theme = useTheme();
  const [openReviewVideoDialog, setOpenReviewVideoDialog] = useState(false);
  const [viewVideoLink, setViewVideolink] = useState("");

  const handleCloseDialog = () => {
    setViewVideolink("");
    setOpenReviewVideoDialog(false);
  };

  return (
    <>
      <Box
        sx={{
          padding: "10px !important",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{
              fontWeight: 700,
              fontSize: "14px",
            }}
          >
            Videos:
          </Typography>
          <IconButton
            aria-label="close"
            onClick={() => {
              handleClose();
            }}
            sx={{
              position: "absolute",
              top: 0,
              right: "5px",
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <Box
              sx={{
                width: "5px",
                height: "5px",
                color: "#000000",
                fontSize: "10px",
              }}
            >
              X
            </Box>
          </IconButton>
        </Box>

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "10px",
            cursor: "pointer",
          }}
          onClick={() => {
            if (employerVideo !== "No URL") {
              setViewVideolink(employerVideo);
              setOpenReviewVideoDialog(true);
            }
          }}
        >
          <SVGButton
            color={"white"}
            source={employerVideo !== "No URL" ? GreenPlay : GreenPlayGrey}
            height={35}
            width={35}
            startIconMargin={"0px !important"}
            padding={"0px !important"}
          />
          <Typography
            sx={{ fontSize: "12px", fontWeight: theme.typography.Regular }}
          >
            {text1}
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "10px",
            cursor: "pointer",
          }}
          onClick={() => {
            if (jobVideo !== "No URL") {
              setViewVideolink(jobVideo);
              setOpenReviewVideoDialog(true);
            }
          }}
        >
          <SVGButton
            color={"white"}
            source={jobVideo !== "No URL" ? GreenPlay : GreenPlayGrey}
            height={35}
            width={35}
            startIconMargin={"0px !important"}
            padding={"0px !important"}
          />
          <Typography
            sx={{ fontSize: "12px", fontWeight: theme.typography.Regular }}
          >
            {text2}
          </Typography>
        </Box>
      </Box>
      {openReviewVideoDialog && (
        <ReviewVideoDialog
          videoLink={viewVideoLink}
          open={openReviewVideoDialog}
          setOpen={handleCloseDialog}
        />
      )}
    </>
  );
}
