import { Backdrop, Box, Button, ThemeProvider, useTheme } from "@mui/material";
import React, { useCallback, useEffect, useRef, useState } from "react";
import JobsDetailsPage from "../../common/JobsDetailsPage";
import { useParams } from "react-router-dom";
import { jobDetailsPageTheme } from "../../../utils/Theme";
import ReactToPrint from "react-to-print";
import { useDispatch } from "react-redux";
import { exportProfilePDF } from "../../../redux/employer/myJobsSlice";
import LoaderGif from "../../../assets/Loader.gif"

export default function JobsSystemSpec() {
  const theme = useTheme();
  const [openNotLoggedIn, setOpenNotLoggedIn] = useState(false);
  const [showLogin, setShowLogin] = useState(false);
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const pdfRef = useRef();
  let dispatch = useDispatch();
  const onBeforeGetContentResolve = useRef(null);
  const [exportAsCSV, setExportAsCSV] = useState(false);
  const handleAfterPrint = useCallback(() => {
    setExportAsCSV(false);
  }, []);
  const handleOnBeforeGetContent = useCallback(() => {
    return new Promise((resolve) => {
      onBeforeGetContentResolve.current = resolve;
      setExportAsCSV(true);
      setTimeout(() => {
        resolve();
      }, 1000);
    });
  }, []);
  const handleBeforePrint = useCallback(() => {}, []);
  const reactToPrintContent = useCallback(() => {
    return pdfRef.current;
  }, [pdfRef.current]);

  const handleExportPDF = async () => {
    setIsLoading(true);
    try {
      const { payload } = await dispatch(exportProfilePDF({ job_id: id }));
      if (payload) {
        const fileURL = window.URL.createObjectURL(
          new Blob([payload], { type: "application/pdf" })
        );
        const fileLink = document.createElement("a");
        fileLink.href = fileURL;
        fileLink.setAttribute(
          "download",
          `${payload?.title || "filename"}.pdf`
        );
        document.body.appendChild(fileLink);
        fileLink.click();
        document.body.removeChild(fileLink);
        setIsLoading(false);
      } else {
        console.error("No payload received");
        setIsLoading(false);
      }
    } catch (error) {
      console.error("Error exporting PDF:", error);
      setIsLoading(false);
    }
  };

  const reactToPrintTriggerBottom = useCallback(() => {
    return (
      <Button
        variant="contained"
        color="nandos"
        onClick={() => handleExportPDF()}
        sx={{
          minWidth: "150px !important",
          height: "45px",
          fontSize: "14px",
          fontWeight: theme.typography.Bold,
          borderRadius: "0px 0px 10px 10px",
        }}
      >
        export to PDF
      </Button>
    );
  }, []);

  const reactToPrintTrigger = useCallback(() => {
    return (
      <Button
        variant="contained"
        color="nandos"
        onClick={() => handleExportPDF()}
        sx={{
          minWidth: "150px !important",
          height: "45px",
          fontSize: "14px",
          fontWeight: theme.typography.Bold,
          borderRadius: "10px 10px 0px 0px",
        }}
      >
        export to PDF
      </Button>
    );
  }, []);

  useEffect(() => {
    if (exportAsCSV) {
      onBeforeGetContentResolve.current();
    }
  }, [exportAsCSV, onBeforeGetContentResolve]);

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        margin: "90px 0px",
      }}
    >
      {isLoading &&     <div>
        <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1111111111 }}
        open={isLoading}
        // onClick={handleClose}
        
      >
        {/* <CircularProgress color="inherit" /> */}
        {/* <Loader/> */}
        <img style={{height:"100px", width:"100px"}} src={LoaderGif} alt="Loading..." />
        {/* <div className="loader"></div> */}
      </Backdrop>
      </div> }
      <Box sx={{ width: `1285px` }}>
        <Box
          sx={{
            width: "100%",
            textAlign: "center",
          }}
        >
          {/* <ReactToPrint
            content={reactToPrintContent}
            documentTitle="AwesomeFileName"
            onAfterPrint={handleAfterPrint}
            onBeforeGetContent={handleOnBeforeGetContent}
            onBeforePrint={handleBeforePrint}
            removeAfterPrint
            trigger={reactToPrintTrigger}
          /> */}
          {reactToPrintTrigger()}
        </Box>
        <ThemeProvider theme={jobDetailsPageTheme}>
          <JobsDetailsPage
            jobId={id}
            theme={theme}
            setOpenNotLoggedIn={setOpenNotLoggedIn}
            setShowLogin={setShowLogin}
            showLogin={showLogin}
            pdfRef={pdfRef}
            exportAsCSV={exportAsCSV}
            routedFrom={"inside"}
          />
        </ThemeProvider>
        <Box
          sx={{
            width: "100%",
            textAlign: "center",
            marginBottom: "30px",
          }}
        >
          {/* <ReactToPrint
            content={reactToPrintContent}
            documentTitle="AwesomeFileName"
            onAfterPrint={handleAfterPrint}
            onBeforeGetContent={handleOnBeforeGetContent}
            onBeforePrint={handleBeforePrint}
            removeAfterPrint
            trigger={reactToPrintTriggerBottom}
          /> */}
          {reactToPrintTriggerBottom()}
        </Box>
      </Box>
    </Box>
  );
}
