import React from "react";
import SmallButton from "./SmallButton";
import { truncate } from "lodash";
import { nanoid } from "@reduxjs/toolkit";
import { Box } from "@mui/material";
import { useEffect } from "react";
import { useState } from "react";
import { useRef } from "react";
import SmallButtonSlider from "./SmallButtonSlider";
import NewSmallButtonSlider from "./NewSmallButtonSlider";

const Slider = ({ items, theme, color, minHeight = "25px", ...props }) => {
  const boxRef = useRef(null);

  const [buttonWidth, setButtonWidth] = useState(
    items?.map((name) => ({
      name,
      width: 0,
    }))
  );

  const arrayDiff = (array1, array2) => {
    return array1?.filter((item) => array2?.indexOf(item) < 0);
  };

  const remaining = (value) => {
    const arrayOfNames = visibleButtons?.map((obj) => obj?.name);
    const difference = arrayDiff(items, arrayOfNames);
    return difference.join(", ");
  };

  const [visibleButtons, setVisibleButtons] = useState([]);
  const [remainingButtons, setRemainingButtons] = useState(0);

  // const calculateVisibleButtons = () => {
  //   let cardWidth = document.querySelector(".slider")?.clientWidth;
  //   let totalWidth = 35.6875;
  //   let visibleButton = [];
  //   let remainingButton = 0;
  //   // console.log(
  //   //   { 1: remainingButton, 2: visibleButton, 3: buttonWidth, 4: cardWidth },
  //   //   "SLIDER"
  //   // );
  //   console.log(
  //     {
  //       document: document.querySelector(".slider"),
  //       condition: document.querySelector(".slider")?.clientWidth,
  //     },
  //     "document.querySelector"
  //   );
  //   buttonWidth?.forEach((item) => {
  //     // console.log({ totalWidth: totalWidth });
  //     // console.log(item.width, "item?.width");
  //     console.log(
  //       {
  //         condition: totalWidth + item?.width <= cardWidth * 2 - 15,
  //         itemwidth: item.width,
  //         cardWidth: cardWidth,
  //         totalWidth: totalWidth,
  //       },
  //       "cardWidth * 2 - 15"
  //     );
  //     if (totalWidth + item?.width <= cardWidth * 2 - 15) {
  //       // if (
  //       //   totalWidth + item.width <= cardWidth ||
  //       //   (totalWidth + item.width <= cardWidth && !flag)
  //       // ) {
  //       totalWidth += item.width;
  //       visibleButton?.push(item);
  //     } else {
  //       remainingButton++;
  //     }
  //   });

  //   setVisibleButtons(visibleButton);
  //   setRemainingButtons(remainingButton);
  // };
  useEffect(() => {
    const calculateVisibleButtons = () => {
      let cardWidth = boxRef.current?.clientWidth;
      let totalWidth = 35.6875;
      let visibleButton = [];
      let remainingButton = 0;

      buttonWidth?.forEach((item) => {
        if (totalWidth + item?.width <= cardWidth * 2 - 15) {
          totalWidth += item.width;
          visibleButton?.push(item);
        } else {
          remainingButton++;
        }
      });

      setVisibleButtons(visibleButton);
      setRemainingButtons(remainingButton);
    };

    calculateVisibleButtons();
  }, [buttonWidth, items]);

  return (
    <Box
      ref={boxRef}
      className="slider"
      sx={{
        // width: "320px !important",
        display: "flex",
        flexWrap: "wrap",
        gap: "5px",
        minHeight: minHeight,
        ...props,
      }}
    >
      {visibleButtons
        ?.filter((item) => item !== null)
        ?.map((item, index) => {
          if (item !== undefined && index <= items?.length - 1) {
            return (
              <NewSmallButtonSlider
                setButtonWidth={setButtonWidth}
                buttonWidth={buttonWidth}
                fontSize={12}
                key={nanoid()}
                color={color}
                height={25}
                value={item?.name}
                label={item?.name}
                mr="5px"
              />
            );
          }
        })}
      {remainingButtons !== 0 && (
        <SmallButton
          fontSize={12}
          key={nanoid()}
          color={color}
          height={25}
          value={remaining(remainingButtons)}
          label={`+ ${remainingButtons}`}
          mr="5px"
        />
      )}
    </Box>
  );
};

export default Slider;
// import React, { useState } from 'react';
// import { IconButton, Box } from '@mui/material';
// import SmallButton from './SmallButton';
// import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
// import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
// import { truncate } from 'lodash';

// const Slider = ({ items, theme, color }) => {
//     const [arrSlider, setArrSlider] = useState(items);

//     const handleSliderClick = (direction) => {
//         const offset = direction === 'left' ? 1 : arrSlider.length - 1;
//         const newArrSlider = [
//             ...arrSlider.slice(offset),
//             ...arrSlider.slice(0, offset),
//         ];
//         setArrSlider(newArrSlider);
//     };

//     return (
//         <Box
//             minHeight={25}
//             sx={{
//                 display: 'flex',
//                 justifyContent: 'space-between'
//             }}
//         >
//             {arrSlider.length >= 3 && (
//                 <IconButton
//                     sx={{
//                         border: `1px solid ${theme.palette.grayBorder}`,
//                         borderRadius: '8px',
//                         width: '27px',
//                         height: '27px',
//                     }}
//                     color="redButton100"
//                     aria-label="search job"
//                     component="button"
//                     onClick={() => handleSliderClick('left')}
//                 >
//                     <KeyboardArrowLeftIcon />
//                 </IconButton>
//             )}
//             <Box
//                 sx={
//                     {
//                         // width: '80%',
//                         display: 'flex',
//                         overflow: 'hidden',
//                     }
//                 }
//             >
//                 {arrSlider
//                     .filter((item) => item != null)
//                     .map((item, index) => {
//                         if (item !== undefined) {
//                             return (
//                                 <SmallButton
//                                     color={color}
//                                     height={25}
//                                     value={item}
//                                     label={truncate(item, {length: 20})}
//                                     mr="4px"
//                                 />
//                             );
//                         }
//                         return null; // Ensure you always return something in map
//                     })}
//             </Box>
//             {arrSlider.length >= 3 && (
//                 <IconButton
//                     sx={{
//                         border: `1px solid ${theme.palette.grayBorder}`,
//                         borderRadius: '8px',
//                         width: '27px',
//                         height: '27px',
//                     }}
//                     color="redButton100"
//                     aria-label="search job"
//                     component="button"
//                     onClick={() => handleSliderClick('right')}
//                 >
//                     <KeyboardArrowRightIcon />
//                 </IconButton>
//             )}
//         </Box>
//     );
// };

// export default Slider;

{
  /* <div class="slider MuiBox-root css-1gonsa2">
  <span
    class="MuiSkeleton-root MuiSkeleton-rounded MuiSkeleton-pulse css-7uzo9y-MuiSkeleton-root"
    style="width: 260px; height: 25px;"
  ></span>
  <span
    class="MuiSkeleton-root MuiSkeleton-rounded MuiSkeleton-pulse css-7uzo9y-MuiSkeleton-root"
    style="width: 40px; height: 25px;"
  ></span>
</div>;

<div class="slider MuiBox-root css-1gonsa2">
  <span
    class="MuiSkeleton-root MuiSkeleton-rounded MuiSkeleton-pulse css-7uzo9y-MuiSkeleton-root"
    style="width: 260px; height: 25px;"
  ></span>
  <span
    class="MuiSkeleton-root MuiSkeleton-rounded MuiSkeleton-pulse css-7uzo9y-MuiSkeleton-root"
    style="width: 40px; height: 25px;"
  ></span>
</div>; */
}
