import { configureStore } from "@reduxjs/toolkit";
import configReducer from "./redux/configSlice";
import loginReducer from "./redux/login/loginSlice";
import myCVReducer from "./redux/candidate/myCvSlice";
import handleSignReducer from "./redux/signUp/reducer";
import allGuestJobs from "./redux/guest/jobsSlice";
import  contactSlice  from "./redux/guest/contactSlice";
import allGuestTalent from "./redux/guest/talentSlice";
import companyHiringList from "./redux/guest/whoshiring";
import talentTypes from "./redux/guest/talentTypes";
import jobTypeReducer from "./redux/jobRole";
import allStages from "./redux/stages";
import jobStatus from "./redux/status";
import myStatus from "./redux/candidate/myStatusFilter";
import allTypes from "./redux/allTypes";
import allEmployerJobs from "./redux/employer/employerJobsConfigSlice";
import allTeamMember from "./redux/employer/myTeams";
import myJobsSlice from "./redux/employer/myJobsSlice";
import allEmployerJoblisting from "./redux/employer/empJobListing";
import allQuesitons from "./redux/guest/getQuestions";
import candidateJobs from "./redux/candidate/candidateJobs";
import alertJobs from "./redux/candidate/alerts";
import myCVNew from "./redux/candidate/myCVNew";
import empProfileSlice from "./redux/employer/empProfileSlice";
import postJobSlice from "./redux/employer/postJobSlice";
import filtersReducer from "./redux/employer/myTeamFilter";
import myProfileSlice from "./redux/candidate/myProfileSlice";
import jobsSlice from "./redux/admin/jobsSlice";
import searchSlice from "./redux/admin/searchSlice";
import userAdminReducer from "./redux/admin/userAdminSlice";
import configMaintenance from "./redux/admin/maintenance";

export const store = configureStore({
  reducer: {
    config: configReducer,
    filters: filtersReducer,
    login: loginReducer,
    myCv: myCVReducer,
    sign: handleSignReducer,
    jobtype: jobTypeReducer,
    talenttype: talentTypes,
    configstages: allStages,
    configjobstatus: jobStatus,
    configAllTypes: allTypes,
    configemployerjobs: allEmployerJobs,
    employerjoblisting: allEmployerJoblisting,
    configjobslice: allGuestJobs,
    configtalentslice: allGuestTalent,
    configWhosHiring: companyHiringList,
    configquestion: allQuesitons,
    configcandidatejobs: candidateJobs,
    configalert: alertJobs,
    configmycvnew: myCVNew,
    configMyStatus: myStatus,
    myProfile: empProfileSlice,
    postJobs: postJobSlice,
    myProfileSli: myProfileSlice,
    myJobs: myJobsSlice,
    configMyTeams: allTeamMember,
    adminSlice: jobsSlice,
    searchSlice: searchSlice,
    userAdmin: userAdminReducer,
    maintenance: configMaintenance,
    configContactSlice:contactSlice
  },
});
