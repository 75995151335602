import {
  Box,
  Button,
  IconButton,
  Modal,
  Typography,
  useTheme,
} from "@mui/material";
import React from "react";
import externalLink from "../../../assets/Padding Excluded/External_Link.svg";
import locale from "../../../i18n/locale";

export default function CandidateExternalLinkBox({
  open,
  handleClose,
  selectedJob,
  height,
  text1,
  text1Margin,
  text2,
  text2Margin,
  text3,
  text3Margin,
  buttonText,
  buttonClick,
  text3Width,
}) {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    height: height,
    width: "360px",
    bgcolor: "background.paper",
    borderRadius: "25px",
    boxShadow: "none !important",
    border: "none !important",
  };

  const i18n = locale.en;
  const theme = useTheme();

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <IconButton
          aria-label="close"
          onClick={() => {
            handleClose();
          }}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
          disableRipple={true}
        >
          <Box
            sx={{
              width: "20px",
              height: "20px",
              color: "#000000",
              border: 2,
              fontSize: "18px",
              borderRadius: "5px",
            }}
          >
            X
          </Box>
        </IconButton>

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <Box
            component={"img"}
            src={externalLink}
            sx={{
              height: "50px",
              width: "55px",
              mt: "28px",
            }}
          />
          <Typography
            sx={{
              fontSize: "20px",
              fontWeight: theme.typography.Bold,
              margin: text1Margin,
              width: "308px",
              textAlign: "center",
            }}
          >
            {text1}
          </Typography>
          <Typography
            sx={{
              fontSize: "18px",
              fontWeight: theme.typography.Regular,
              margin: text2Margin,
              width: "294px",
              textAlign: "center",
            }}
          >
            {text2}
          </Typography>
          <Typography
            sx={{
              fontSize: "18px",
              fontWeight: theme.typography.Regular,
              margin: text3Margin,
              width: text3Width,
              textAlign: "center",
            }}
          >
            {text3}
          </Typography>

          <Button
            variant="contained"
            color="redButton100"
            sx={{
              width: "100%",
              height: "60px",
              borderRadius: "0 0 25px 25px",
            }}
            onClick={() => buttonClick()}
          >
            {buttonText}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}
