import React, { useRef, useState } from "react";
import { Box, Button, Typography, useTheme } from "@mui/material";
import { RadioButtonChecked } from "@mui/icons-material";
import { useDispatch } from "react-redux";
import { setAlert } from "../../../../redux/configSlice";
import { ALERT_TYPE } from "../../../../utils/Constants";

import myVideoicon from "../../../../assets/CrayBotIcons/Crayon Cam Video Charactersvg.svg";
// import myVideoHeaderLogo from "../../../../assets/Padding Excluded/White_myVideo.svg";
import uploadRecord from "../../../../assets/Padding Excluded/Black_Upload.svg";
import {
  updateCamLink,
  uploadEmployerMyCamVideo,
} from "../../../../redux/employer/myJobsSlice";
import locale from "../../../../i18n/locale";
import { getVideoFileDuration } from "../../../../utils/Common";
import jobVideoHeaderLogo from "../../../../assets/Cam/JobVideoIcon.svg";
import myVideoHeaderLogo from "../../../../assets/Cam/MyVideoIcon.svg";
import uploadNewVideoIcon from "../../../../assets/Cam/UploadVideoIcon.svg";
import addALink from "../../../../assets/Cam/AddLinkLogo.svg";
import AddYoutubeLink from "./AddYoutubeLink";

export default function AddVideo({
  nextStep,
  getVideo,
  companyID,
  setTextChange,
  allData,
  setAllData,
}) {
  const theme = useTheme();
  const i18n = locale.en;
  const dispatch = useDispatch();
  const hiddenFileInput = useRef(null);
  const fileAccept = "video/*";
  const [videoLink, setVideoLink] = useState(null);
  const VideoRef = useRef(null);
  const [youtubeLink, setYoutubeLink] = useState("");
  const [youtubeLinkModal, setYoutubeLinkModal] = useState("");
  const [errors, setErrors] = useState(false);
  const handleFileChange = async (event) => {
    const file = event?.target?.files?.[0];

    getVideoFileDuration(file, async (error, videoDuration, fileType) => {
      if (error) {
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE?.ERROR,
            msg: error,
          })
        );
        return;
      } else if (
        file?.name?.split(".")?.pop()?.toLowerCase() !== "mp4" &&
        file?.name?.split(".")?.pop()?.toLowerCase() !== "mov" &&
        file?.name?.split(".")?.pop()?.toLowerCase() !== "webm"
      ) {
        setVideoLink("")
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE?.ERROR,
            msg: "Please upload .mp4 or .mov file",
          })
        );
        return;
      } else if (videoDuration > 60) {
        setVideoLink("")
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE?.ERROR,
            msg: "Video length should be less than 1 min",
          })
        );
        return;
      } else if (file?.size > 100000000) {
        setVideoLink("")
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE?.ERROR,
            msg: "Video size should be less than 100MB",
          })
        );
        return;
      }
      const formData = new FormData();
      formData?.append("company-video", event?.target?.files?.[0]);
      formData?.append("company_id", companyID);
      try {
        const { payload } = await dispatch(uploadEmployerMyCamVideo(formData));
        if (payload?.status === "success") {
          dispatch(
            setAlert({
              show: true,
              type: ALERT_TYPE?.SUCCESS,
              msg: "Video uploaded Successfully!",
            })
          );
          setTextChange(true);
          nextStep(4);
          await getVideo();
          setVideoLink(URL.createObjectURL(event?.target?.files?.[0]));
          VideoRef?.current?.load();
        } else {
          dispatch(
            setAlert({
              show: true,
              type: ALERT_TYPE?.ERROR,
              msg: "Failed to upload Video",
            })
          );
        }
      } catch (error) {
        dispatch(setAlert({ show: true }));
      }
    });
  };
  const handleFileClick = () => {
    hiddenFileInput?.current?.click();
  };
  const updatecamYoutubeLink = async (url) => {
    let payload = {
      company_id: companyID,
      // "job_id":1274,
      link: url,
    };
    try {
      let response = await dispatch(updateCamLink(payload));
      if (response?.payload?.status === "success") {
        setAllData({ ...allData, linkFlag: true, video_url: url });
        setYoutubeLinkModal(false);
        setTextChange(true);
        nextStep(4);
        await getVideo();
      }
    } catch (error) {}
  };

  const handleSubmit = (url) => {
    updatecamYoutubeLink(url);
  };

  return (
    <>
      <Button
        variant="contained"
        color="precium"
        sx={{
          borderRadius: "25px 0 10px 0",
          width: "150px",
          height: "45px",
          fontSize: "14px",
          fontWeight: theme.typography.Bold,
          gap: "5px",
          padding: "6px 6px",
        }}
      >
        <Box
          component={"img"}
          src={myVideoHeaderLogo}
          sx={{
            height: "20px",
            width: "24px",
          }}
        />
        company video
      </Button>
      <Box
        sx={{
          // width: { xs: "90%", md: "70%" },
          width: "100%",
          flexDirection: "column",
          alignItems: "center",
          display: "flex",
          gap: "20px",
          marginBottom: { xs: "10px", md: "" },
          marginTop: "30px",
        }}
      >
        <Typography
          sx={{
            fontSize: "20px",
            fontWeight: theme.typography.Bold,
            textAlign: "center",
          }}
        >
          Showcase your biz!
        </Typography>
      </Box>
      <Box
        sx={{
          padding: { xs: "10px 20px 20px", md: "10px 20px 57px" },
          flexDirection: "column",
          alignItems: "center",
          display: "flex",
          gap: "25px",
          flex: { xs: "1", lg1440: "" },
          justifyContent: { tb: "center", lg1440: "" },
        }}
      >
        <Box
          component={"img"}
          src={myVideoicon}
          sx={{
            height: {
              xs: "200px",
              xs360: "200px",
              xs390: "200px",
              md24: "250px",
              lgm: "250px",
            },
            maxHeight: "250px",
            width: "auto",
          }}
        />
        <Box
          sx={{
            width: { xs: "90%", md: "70%" },
            flexDirection: "column",
            alignItems: "center",
            display: "flex",
            gap: "20px",
            marginBottom: { xs: "10px", md: "" },
          }}
        >
          <Typography
            sx={{
              fontSize: "14px",
              textAlign: "center",

              fontWeight: theme.typography.Regular,
            }}
          >
            Give us your company elevator pitch! Record or upload a short intro
            video, highlighting the nature of your business, what makes it great
            to work at, growth plans, company culture, etc.
          </Typography>
          <Typography
            sx={{
              fontSize: "14px",
              textAlign: "center",
              fontWeight: theme.typography.Regular,
            }}
          >
            Adding your company video increases your chances of attracting top
            talent and is often the first thing looked at by candidates!
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
        }}
      >
        {/* <Button
          variant="contained"
          color="videoButton"
          sx={{
            width: "50%",
            height: "60px",
            borderRadius: "0 0 0px 25px",
            fontSize: "14px",
            gap: "8px",
            fontWeight: theme.typography.Bold,
          }}
          onClick={() => nextStep(2)}
        >
          <RadioButtonChecked />
          {i18n["empMyCam.recordvideo"]}
        </Button> */}
        <input
          accept={fileAccept}
          ref={hiddenFileInput}
          type="file"
          onChange={handleFileChange}
          style={{ display: "none" }}
        />
        <Button
          variant="contained"
          color="yoco"
          sx={{
            width: "50%",
            height: "60px",
            borderRadius: "0 0 0px 25px",
            fontSize: "15px",
            gap: "10px",
            fontWeight: theme.typography.Bold,
          }}
          onClick={handleFileClick}
        >
          <Box
            component={"img"}
            src={uploadNewVideoIcon}
            sx={{
              height: "20px",
              width: "24px",
            }}
          />
          upload video
        </Button>
        <Button
          variant="contained"
          color="tymeColor"
          sx={{
            width: "50%",
            height: "60px",
            borderRadius: "0 0 0 0",
            fontSize: "14px",
            gap: "8px",
            fontWeight: theme.typography.Bold,
          }}
          onClick={() => {
            setYoutubeLinkModal(true);
          }}
        >
          <Box
            component={"img"}
            src={addALink}
            sx={{
              height: "20px",
              width: "24px",
            }}
          />
          add a link
        </Button>
        <Button
          variant="contained"
          color="videoButton"
          sx={{
            width: "50%",
            height: "60px",
            borderRadius: "0 0  25px 0px",
            fontSize: "14px",
            gap: "8px",
            fontWeight: theme.typography.Bold,
          }}
          onClick={() => nextStep(2)}
        >
          <Box
            component={"img"}
            src={jobVideoHeaderLogo}
            sx={{
              height: "20px",
              width: "24px",
            }}
          />
          record video
        </Button>
      </Box>
      {youtubeLinkModal && (
        <AddYoutubeLink
          open={youtubeLinkModal}
          setOpen={() => setYoutubeLinkModal(false)}
          handleSubmit={handleSubmit}
          youtubeLink={youtubeLink}
          setErrors={setErrors}
          errors={errors}
          setYoutubeLink={setYoutubeLink}
        />
      )}
    </>
  );
}
