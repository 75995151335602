import { Box, Button } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { setAlert } from "../../../redux/configSlice";
import { getCompanyType } from "../../../redux/employer/empProfileSlice";
import { ALERT_TYPE, ERROR_MSG } from "../../../utils/Constants";
import { useDispatch } from "react-redux";
import { useTheme } from "@emotion/react";
import { useLocation, useNavigate } from "react-router-dom";
import { useQuery } from "../../../utils/Common";

export default function CompanyTypeFilter({
  setCompanyType,
  handlePopoverCloseTypeFilter,
  onChangeFilter,
  color,
  setOpenDropDown,
  companiesType,
  companiesSize,
  setOpen,
  type
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { companyType } = useSelector((state) => state.myProfile);
  const theme = useTheme();
  const query = useQuery();
  const [selectedBtns, setSelectedBtn] = useState([]);
  const [openDropDown, setOpenDropDownId] = useState(null);
  const [selectedFilters, setSelectedFilters] = useState([]);

  const location = useLocation();

  const companyTypeRef = useRef(selectedBtns);
  const boxRef = useRef();
  const companySizeRef = useRef(query.get("company-size"));

  const handleClickOutside = (event) => {
    if (
      (boxRef.current &&
        !boxRef.current.contains(event.target) &&
        companiesType === "" &&
        companiesSize === "") ||
      (boxRef.current &&
        !boxRef.current.contains(event.target) &&
        companiesType?.length === 0 &&
        companiesSize === 0)
    ) {
      setOpen([false, false, false]);
      setOpenDropDown(1);
    }
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const getAllData = async () => {
    try {
      await Promise.all([dispatch(getCompanyType())]);
    } catch (error) {
      dispatch(
        setAlert({
          show: true,
          type: ALERT_TYPE?.ERROR,
          msg: ERROR_MSG,
        })
      );
    }
  };

  const removeBtnSelection = (selectedBtnList, id) => {
    selectedBtnList?.splice(
      selectedBtns?.findIndex((selectedBtn) => selectedBtn == id),
      1
    );
  };

  const handleButtonClick = (event, { id, name, title, dropDown }) => {
    let index;

    if (dropDown) {
      setOpenDropDownId((prevState) => (prevState === id ? null : id));
      return;
    }
    if (title === true) {
      if (selectedBtns?.find((selectedBtn) => selectedBtn == id)) {
        const selectedBtnList = [...selectedBtns];
        removeBtnSelection(selectedBtnList, id);
        setSelectedBtn(selectedBtnList);
        onChangeFilter(selectedBtnList);
      }
      // else {
      //   setSelectedBtn([companyType[0]?.id]);
      //   onChangeFilter([companyType[0]?.id]);
      // }
    } else {
      if (selectedBtns?.find((selectedBtn) => selectedBtn == id)) {
        const selectedBtnList = [...selectedBtns];
        removeBtnSelection(selectedBtnList, id);
        setSelectedBtn(selectedBtnList);
        onChangeFilter(selectedBtnList);
      } else {
        if (
          selectedBtns?.find(
            (selectedBtn) => selectedBtn == companyType?.[0]?.id
          )
        ) {
          const selectedBtnList = [...selectedBtns];
          // removeBtnSelection(selectedBtnList, id);
          selectedBtnList?.push(id);
          setSelectedBtn(selectedBtnList);
          onChangeFilter(selectedBtnList);
        } else {
          setSelectedBtn((prevState) => [...prevState, id]);
          onChangeFilter([...selectedBtns, id]);
        }
      }
    }
    index = companyTypeRef?.current?.indexOf(name);
    if (index === -1) {
      companyTypeRef?.current?.push(name);
    } else {
      companyTypeRef?.current?.splice(index, 1);
    }
    handleFilterSelection("companyType", companyTypeRef.current);
    setCompanyType(companyTypeRef.current);
    handlePopoverCloseTypeFilter();
  };

  const handleFilterSelection = (paramType, filterName) => {
    // Toggle filter selection
    if (filterName.length === 0) {
      const queryParams = new URLSearchParams(window.location.search);
      queryParams.delete(paramType); // Remove the parameter from the query string
      if(type === "detailPage"){
        window.open(`/jobs?${queryParams.toString()}`);
      }else{
        navigate(`${location.pathname}?${queryParams.toString()}`);
        return;
      }
  
    } else {
      const updatedFilters = selectedFilters?.includes(filterName)
        ? selectedFilters?.filter((filter) => filter !== filterName)
        : [filterName];
      const queryParams = new URLSearchParams(window.location.search);
      queryParams.set(paramType, updatedFilters.join(","));
      if(type === "detailPage"){
        window.open(`/jobs?${queryParams.toString()}`);
      }else{
        navigate(`${window.location.pathname}?${queryParams.toString()}`);
      }
      setSelectedFilters(updatedFilters);
    }
  };

  useEffect(() => {
    getAllData();
  }, []);

  useEffect(() => {
    let companies = [];
    selectedBtns?.map((type) => {
      let selectedJobType = companyType?.find((jobtype) => jobtype.id === type);
      companies?.push(selectedJobType?.name);
    });
    // setCompanyType(companies);
  }, [selectedBtns]);

  useEffect(() => {
    const companySizeParams = query.get("companyType");
    if (companySizeParams) {
      const sizes = companySizeParams?.split(",");
      companyTypeRef.current = sizes;
    }
  }, [selectedBtns]);

  useEffect(() => {
    let companies = [];
    const companySizeParams = query.get("companyType");
    if (companySizeParams && companyType.length > 0) {
      const sizes = companySizeParams?.split(",");
      sizes?.map((items) => {
        let selectedSize = companyType?.find((item) => item?.name === items);
        companies?.push(selectedSize.id);
      });
      setSelectedBtn(companies);
      // Add your logic to pre-select buttons using the 'sizes' array
    }
  }, [companyType?.length > 0]);

  return (
    <Box
      ref={boxRef}
      sx={{
        width: "150px",
      }}
    >
      {companyType?.map((item) => (
        <Button
          sx={{
            padding: "6px 7px",
            lineHeight: "inherit",
            borderRadius: "5px",
            width: "100%",
            borderBottomRightRadius: "5px",
            mr: { xs: 1, sm: 0 },
            minWidth: { xs: "90px", sm: 0 },
            fontWeight: theme.typography.Regular,
            "&:hover": {
              // opacity: 1,
              // backgroundColor: theme.palette[item.color]?.main,
              backgroundColor: theme.palette[color].main,
              color: theme.palette.white,
              fontWeight: theme.typography.Bold,
            },
            // opacity: selectedBtns.includes(item.id) ? 1 : 0.5
          }}
          onClick={(e) => {
            handleButtonClick(e, item);
          }}
          disableElevation
          variant="contained"
          color={selectedBtns?.includes(item?.id) ? color : "base"}
          key={item?.id}
        >
          {item?.name}
        </Button>
      ))}
    </Box>
  );
}
