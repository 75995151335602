import React from "react";
import { Box, Button, Typography } from "@mui/material";
import greenTriangle from "../../../assets/Characters/Green_Triangle_Happy.svg";
import { Link } from "react-router-dom";
import { useTheme } from "@emotion/react";
import jwt_decode from "jwt-decode";
import { getToken } from "../../../utils/Common";

export default function NoJobsBox({ page,handleSubmitBtn }) {
  const theme = useTheme();

  const token = getToken();

  let decodedToken;
  if (token) {
    decodedToken = jwt_decode(token);
  }

  return (
    <Box
      sx={{
        textAlign: "center",
        display: "flex",
        flexDirection: "column",
        gap: 3,
        height: "350px",
        width: "400px",
        backgroundColor: "white",
        borderRadius: "25px",
      }}
    >
      <Box
        component={"img"}
        src={greenTriangle}
        sx={{ width: 96, height: 96, margin: "auto", mb: 0 }}
      />
      <Typography
        sx={{
          fontSize: "22px",
          fontWeight: 700,
          textAlign: "center",
          // paddingX: 3,
        }}
      >
        It doesn't look like you've posted
        <br /> any jobs yet.
      </Typography>

      <Typography
        sx={{
          fontSize: "16px",
          fontWeight: 700,
          //   paddingX: 3,
        }}
      >
        Let's do something about that!
      </Typography>
      {page === "mySpec" ? (
        <Button
          variant="contained"
          sx={{
            borderRadius: "0px 0px 25px 25px",
            width: "100%",
            height: "60px",
            minHeight: "60px",
            maxHeight: "60px",
          }}
          color="redButton"
          onClick={handleSubmitBtn}
        >
          find your next champion
        </Button>
      ) : (
        <Link
          to={
            decodedToken?.data?.role_id === 6
              ? `/recruiter/post-a-job`
              : `/employer/post-a-job`
          }
          style={{
            textDecoration: "none",
            color: theme.palette.black,
          }}
        >
          <Button
            variant="contained"
            sx={{
              borderRadius: "0px 0px 25px 25px",
              width: "100%",
              height: "60px",
              minHeight: "60px",
              maxHeight: "60px",
            }}
            color="redButton"
          >
            find your next champion
          </Button>
        </Link>
      )}
    </Box>
  );
}
