import React, { useEffect, useRef, useState } from "react";
import MenuItem from "@mui/material/MenuItem";
import activeDownClose from "../../../assets/Black_Down_Open - Copy.svg";
import activeUpClose from "../../../assets/Black_Up_Close - Copy.svg";

import { useDispatch } from "react-redux";
import { statusChange } from "../../../redux/employer/myJobsSlice";
import { setAlert } from "../../../redux/configSlice";
import { ALERT_TYPE } from "../../../utils/Constants";

import { approveJob, jobAlertGet } from "../../../redux/admin/jobsSlice";
import { Circle } from "@mui/icons-material";
import {
  Box,
  ClickAwayListener,
  Grow,
  MenuList,
  Paper,
  Stack,
  useTheme,
} from "@mui/material";
import Button from "@mui/material/Button";
import Popper from "@mui/material/Popper";
import NewTemplate from "../../promoter/NewTemplate";
import { formatCurrencyWithCommas } from "../../../utils/Currency";
import { dateConverterFullMonth3 } from "../../../utils/DateTime";
import { getDecodedToken, getToken } from "../../../utils/Common";
const ChangeStatusButton = ({
  loggedInUser,
  jobId,
  jobStatus,
  employerIndustry,
  getJobList,
  setAllJobs,
  setTotalJob,
  page,
  jobContent,
}) => {
  let template;
  const decodedToken = getDecodedToken(getToken());
  const theme = useTheme();
  const formData = new FormData();
  const isDisabled = loggedInUser !== 1 ? true : false;
  const [status, setStatus] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const anchorRef = useRef(null);
  const [openActive, setOpenActive] = useState(false);
  const open = Boolean(anchorEl);
  const [statusName, setStatusName] = useState();
  const [shareImage, setShareImage] = useState(false);

  useEffect(() => {
    setStatus(jobStatus);
  }, [jobStatus]);

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpenActive(false);
    } else if (event.key === "Escape") {
      setOpenActive(false);
    }
  }

  const dispatch = useDispatch();

  const handleToggle = () => {
    setOpenActive((prevOpen) => !prevOpen);
  };

  const handleCloseActive = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpenActive(false);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const pauseJobData = {
    job_id: jobId,
    status: "paused",
  };
  const closeJobData = {
    job_id: jobId,
    status: "closed",
  };

  const reactivateJobData = {
    job_id: jobId,
    status: "reactivate",
  };
  const extendJobData = {
    job_id: jobId,
    status: "extend",
  };
  const pauseJob = async () => {
    try {
      const { payload } = await dispatch(statusChange(pauseJobData));
      if (payload?.status == "success") {
        !page && setStatus(payload?.statusname);
        setOpenActive(false);
        page &&
          setAllJobs((prev) => prev?.filter((item) => item?.job_id !== jobId));
        page && setTotalJob((prev) => prev - 1);
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE.SUCCESS,
            msg: "Job Paused successfully!",
          })
        );
        // await getJobList("");
      } else {
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE.ERROR,
            msg: payload?.message,
          })
        );
      }
    } catch (error) {
      dispatch(setAlert({ show: true }));
    }
  };

  const closeJob = async () => {
    try {
      const { payload } = await dispatch(statusChange(closeJobData));
      if (payload?.status == "success") {
        !page && setStatus(payload?.statusname);
        setOpenActive(false);
        page &&
          setAllJobs((prev) => prev?.filter((item) => item?.job_id !== jobId));
        page && setTotalJob((prev) => prev - 1);
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE.SUCCESS,
            msg: "Job Closed successfully!",
          })
        );
        // await getJobList("");
      } else {
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE.ERROR,
            msg: payload?.message,
          })
        );
      }
    } catch (error) {
      dispatch(setAlert({ show: true }));
    }
  };
  const extendJob = async () => {
    try {
      const { payload } = await dispatch(statusChange(extendJobData));
      if (payload?.status == "success") {
        !page && setStatus(payload?.statusname);
        setOpenActive(false);
        page &&
          setAllJobs((prev) => prev?.filter((item) => item?.job_id !== jobId));
        page && setTotalJob((prev) => prev - 1);
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE.SUCCESS,
            msg: "Job Closed successfully!",
          })
        );
        // await getJobList("");
      } else {
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE.ERROR,
            msg: payload?.message,
          })
        );
      }
    } catch (error) {
      dispatch(setAlert({ show: true }));
    }
  };

  const reactivateJob = async () => {
    try {
      const { payload } = await dispatch(statusChange(reactivateJobData));
      if (payload?.status == "success") {
        setOpenActive(false);
        !page && setStatus("active");
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE.SUCCESS,
            msg: "Job Reactivated successfully!",
          })
        );
        page &&
          setAllJobs((prev) => prev?.filter((item) => item?.job_id !== jobId));
        page && setTotalJob((prev) => prev - 1);
        // await getJobList("");
      } else {
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE.ERROR,
            msg: payload?.message,
          })
        );
      }
    } catch (error) {
      dispatch(setAlert({ show: true }));
    }
  };

  const handleApprove = async (formData) => {
    try {
      const { payload } = await dispatch(approveJob(formData));
      !page && setStatus(payload?.statusname);
      setOpenActive(false);
      page &&
        setAllJobs((prev) => prev?.filter((item) => item?.job_id !== jobId));
      page && setTotalJob((prev) => prev - 1);
      if (payload?.data?.length > 0) {
        // await dispatch(jobAlertGet({ job_id: jobId }));

        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE.SUCCESS,
            msg: "Job Activated Successfully!",
          })
        );
        setShareImage(false);
        // await getJobList("");
      } else {
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE.ERROR,
            msg: payload?.message,
          })
        );
      }
    } catch (error) {
      dispatch(
        setAlert({
          show: true,
          type: ALERT_TYPE.ERROR,
          msg: "Without.",
        })
      );
    }
  };

  const handleStatusChange = (status) => {
    if (status === "paused") {
      pauseJob();
    } else if (status === "closed") {
      closeJob();
    } else if (status === "extend") {
      extendJob();
    } else if (status === "reactive") {
      setShareImage(true);
      setStatusName("reactive");
    } else if (status === "active") {
      setShareImage(true);
      setStatusName("active");
    }
    handleClose();
  };
  return (
    <>
      <Button
        ref={anchorRef}
        id="composition-button"
        aria-controls={open ? "composition-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
        variant="contained"
        color="base"
        endIcon={
          decodedToken?.data?.role_id !== 8 && (
            <Box
              component="img"
              className="eye"
              alt="eye logo"
              src={openActive ? activeUpClose : activeDownClose}
              sx={{
                height: 25,
                width: 25,
              }}
            />
          )
        }
        sx={{
          "&:hover": {
            boxShadow: "none",
          },
          fontSize: "12px",
          fontWeight: 700,
          color: "#000",
          width: "100%",
          padding: "8px 12px 8px 18px",
          borderRadius: "0px 10px 0 0",
          borderRight: "1px solid #EBECF3",
          borderBottom: "1px solid #EBECF3",
          height: "100% !important",
          cursor: `${isDisabled && "default"}`,
          "& .MuiButton-endIcon": {
            margin: "0px !important",
          },
        }}
      >
        {status}
        <Circle
          fontSize="string"
          color={
            status === "pending"
              ? "orangeButton"
              : status === "active"
              ? "lightGreenButton300"
              : status === "reactive"
              ? "lightGreenButton300"
              : status === "paused"
              ? "orangeButton"
              : "redButton100"
          }
          sx={{ marginLeft: "10px" }}
        />
      </Button>
      <Stack
        direction="row"
        spacing={2}
        sx={{
          zIndex: "1000 !important",
          position: "relative",
          cursor: "pointer",
        }}
      >
        <Box>
          <Popper
            open={isDisabled ? false : openActive}
            anchorEl={anchorRef.current}
            role={undefined}
            placement="bottom-start"
            transition
            disablePortal={isDisabled}
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin:
                    placement === "bottom-start" ? "left top" : "left bottom",
                }}
              >
                <Paper
                  sx={{
                    width: "100px !important",
                    borderRadius: "0 0 10px 10px !important",
                  }}
                >
                  <ClickAwayListener onClickAway={handleCloseActive}>
                    <MenuList
                      autoFocusItem={openActive}
                      id="composition-menu"
                      aria-labelledby="composition-button"
                      onKeyDown={handleListKeyDown}
                    >
                      {status === "expired" && [
                        <MenuItem
                          key="active"
                          onClick={() => handleStatusChange("extend")}
                          sx={{
                            fontSize: "12px",
                            fontWeight: theme.typography.Regular,
                            justifyContent: "space-between",
                          }}
                        >
                          extend{" "}
                          <Circle
                            fontSize="string"
                            color={"lightGreenButton300"}
                            sx={{ marginLeft: "10px" }}
                          />
                        </MenuItem>,
                        <MenuItem
                          key="paused"
                          onClick={() => handleStatusChange("paused")}
                          sx={{
                            fontSize: "12px",
                            fontWeight: theme.typography.Regular,
                            justifyContent: "space-between",
                          }}
                        >
                          pause{" "}
                          <Circle
                            fontSize="string"
                            color={"orangeButton"}
                            sx={{ marginLeft: "10px" }}
                          />
                        </MenuItem>,
                        <MenuItem
                          key="closed"
                          onClick={() => handleStatusChange("closed")}
                          sx={{
                            fontSize: "12px",
                            fontWeight: theme.typography.Regular,
                            justifyContent: "space-between",
                          }}
                        >
                          close
                          <Circle
                            fontSize="string"
                            color={"redButton100"}
                            sx={{ marginLeft: "10px" }}
                          />
                        </MenuItem>,
                      ]}
                      {status === "pending" && [
                        <MenuItem
                          key="active"
                          onClick={() => handleStatusChange("active")}
                          sx={{
                            fontSize: "12px",
                            fontWeight: theme.typography.Regular,
                            justifyContent: "space-between",
                          }}
                        >
                          approve{" "}
                          <Circle
                            fontSize="string"
                            color={"lightGreenButton300"}
                            sx={{ marginLeft: "10px" }}
                          />
                        </MenuItem>,
                        <MenuItem
                          key="paused"
                          onClick={() => handleStatusChange("paused")}
                          sx={{
                            fontSize: "12px",
                            fontWeight: theme.typography.Regular,
                            justifyContent: "space-between",
                          }}
                        >
                          pause{" "}
                          <Circle
                            fontSize="string"
                            color={"orangeButton"}
                            sx={{ marginLeft: "10px" }}
                          />
                        </MenuItem>,
                        <MenuItem
                          key="closed"
                          onClick={() => handleStatusChange("closed")}
                          sx={{
                            fontSize: "12px",
                            fontWeight: theme.typography.Regular,
                            justifyContent: "space-between",
                          }}
                        >
                          close
                          <Circle
                            fontSize="string"
                            color={"redButton100"}
                            sx={{ marginLeft: "10px" }}
                          />
                        </MenuItem>,
                      ]}
                      {status === "active" && [
                        <>
                          <MenuItem
                            key="paused"
                            onClick={() => handleStatusChange("paused")}
                            sx={{
                              fontSize: "12px",
                              fontWeight: theme.typography.Regular,
                              justifyContent: "space-between",
                            }}
                          >
                            pause{" "}
                            <Circle
                              fontSize="string"
                              color={"orangeButton"}
                              sx={{ marginLeft: "10px" }}
                            />
                          </MenuItem>
                          <MenuItem
                            key="closed"
                            onClick={() => handleStatusChange("closed")}
                            sx={{
                              fontSize: "12px",
                              fontWeight: theme.typography.Regular,
                              justifyContent: "space-between",
                            }}
                          >
                            close
                            <Circle
                              fontSize="string"
                              color={"redButton100"}
                              sx={{ marginLeft: "10px" }}
                            />
                          </MenuItem>
                        </>,
                      ]}
                      {status === "paused" && [
                        <>
                          <MenuItem
                            onClick={() => handleStatusChange("reactive")}
                            sx={{
                              fontSize: "12px",
                              fontWeight: theme.typography.Regular,
                              justifyContent: "space-between",
                            }}
                          >
                            reactive
                            <Circle
                              fontSize="string"
                              color={"lightGreenButton300"}
                              sx={{ marginLeft: "10px" }}
                            />
                          </MenuItem>
                          <MenuItem
                            key="closed"
                            onClick={() => handleStatusChange("closed")}
                            sx={{
                              fontSize: "12px",
                              fontWeight: theme.typography.Regular,
                              justifyContent: "space-between",
                            }}
                          >
                            close
                            <Circle
                              fontSize="string"
                              color={"redButton100"}
                              sx={{ marginLeft: "10px" }}
                            />
                          </MenuItem>
                        </>,
                      ]}
                      {status === "closed" && (
                        <MenuItem
                          onClick={() => handleStatusChange("reactive")}
                          sx={{
                            fontSize: "12px",
                            fontWeight: theme.typography.Regular,
                            justifyContent: "space-between",
                          }}
                        >
                          reactive
                          <Circle
                            fontSize="string"
                            color={"lightGreenButton300"}
                            sx={{ marginLeft: "10px" }}
                          />
                        </MenuItem>
                      )}
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </Box>
      </Stack>
      {shareImage && (
        <NewTemplate
          jobID={jobContent?.job_id}
          title={jobContent?.title}
          location={`${jobContent?.town_name}, ${jobContent?.region_name}`}
          companyLogo={jobContent?.companyURL}
          salary={`${jobContent?.currency_symbol}${formatCurrencyWithCommas(
            jobContent?.salary_min
          )} to ${jobContent?.currency_symbol}${formatCurrencyWithCommas(
            jobContent?.salary_max
          )} ${jobContent?.type === "freelance" ? "per hour" : "per month"}`}
          // experience={`${
          //   jobContent?.experience_year_start === 0
          //     ? `${jobContent?.experience_year_end} years`
          //     : `${jobContent?.experience_year_start} to ${jobContent?.experience_year_end} years`
          // }`}
          experience={`${jobContent?.experience_year_start} to ${jobContent?.experience_year_end} years`}
          primaryPersonality={jobContent?.primary_name}
          shadowPersonality={jobContent?.shadow_name}
          startDate={dateConverterFullMonth3(jobContent?.job_start_date)}
          handleApprove={handleApprove}
          formData={formData}
          statusName={statusName}
          getUrl={() => {}}
          setImageLoading={() => {}}
          setShowShareInfo={() => {}}
        />
      )}
    </>
  );
};

export default ChangeStatusButton;
