import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import locale from "../../../i18n/locale";
import { useDispatch } from "react-redux";
import { setAlert, setLoading } from "../../../redux/configSlice";
import { useEffect, useRef, useState } from "react";
import {
  getContractDetails,
  updateToInitialSkills,
} from "../../../redux/employer/postJobSlice";
import {
  Typography,
  useTheme,
  Paper,
  Select,
  MenuItem,
  TextField,
} from "@mui/material";
import Slider from "@mui/material/Slider";
import SelectMenu from "../../common/SelectMenu";
import AutoComplete from "../../common/AutoComplete";
import { InputLabel } from "@mui/material";
import {
  ALERT_TYPE,
  ERROR_MSG,
  BASIC_MARKS,
  BASIC_RANGEMARKS,
  BASIC_RANGEMARKS2,
  CV_STEPS,
  BASIC_RANGEMARKS_SLIDER,
  SALARY_VALUE_OPTION,
} from "../../../utils/Constants";
import {
  getTitles,
  getSkills,
  getQualification,
  getRequiredQualification,
  getCurrency,
  getCountry,
  getRoleTypes,
  getWorkSetup,
  getTown,
  getTools,
} from "../../../redux/employer/postJobSlice";
import { useSelector } from "react-redux";
import {
  getIndustries,
  getLanguage,
  getAssociation,
  getNationality,
} from "../../../redux/candidate/myCvSlice";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import black from "../../../assets/Characters/Race/crayon_race_black.svg";
import white from "../../../assets/Characters/Race/crayon_race_white.svg";
import indian from "../../../assets/Characters/Race/crayon_race_indian.svg";
import coloured from "../../../assets/Characters/Race/crayon_race_coloured.svg";
import blackFemale from "../../../assets/Characters/Race/crayon_black_female.svg";
import whiteFemale from "../../../assets/Characters/Race/crayon_white_female.svg";
import indianFemale from "../../../assets/Characters/Race/crayon_Indian_female.svg";
import colouredFemale from "../../../assets/Characters/Race/crayon_coloured_female.svg";
import own_internet from "../../../assets/Padding Included/Crayon Icon_Own Internet.svg";
import own_equipment from "../../../assets/Padding Included/Crayon Icon_Own Equipment.svg";
import own_vehicle from "../../../assets/Padding Included/Crayon Icon_Own Vehicle.svg";
import maleBlack from "../../../assets/male_blue.svg";
import maleWhite from "../../../assets/male_white.svg";
import femaleBlack from "../../../assets/female_pink.svg";
import femaleWhite from "../../../assets/female_white (2).svg";
import { CheckCircle } from "@mui/icons-material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import "dayjs/locale/en-gb";
import CustomSelect from "../../candidate/myCV/CustomSelect";
import {
  checkNumberRange,
  genarateLabelValue,
  genarateLabelValueForFreelance,
} from "../../../utils/Common";
import { BlueSwitch, ToggleSwitch } from "../../../utils/CommonComponent";
import { formatNumber, rangeValueExperience } from "../../../utils/Common";
import specIcon from "../../../assets/Padding Excluded/Black_Upload.svg";
import scrapeLinkedinIcon from "../../../assets/MyCV/Black_View.svg";
import nextIcon from "../../../assets/Black_Right_Next.svg";
import _ from "lodash";
import UploadSpec from "./UploadSpec";
import useViewport from "../ViewPort";
import SalarySlider from "../SalarySlider";
import previousIcon from "../../../assets/Black_Left_Previous.svg";

dayjs.locale("en-gb");

export default function TheBasics({
  changeStep,
  basicData,
  setBasicData,
  errors,
  setErrors,
  saveBasic,
  openSpecBox,
  setOpenSpecBox,
  isEditPostjob,
  handleOpenSaveAndExitDialog,
}) {
  // const fileAccept = "application/pdf, application/doc, application/docx";
  const fileAccept =
    "application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document";
  const i18n = locale.en;
  const dispatch = useDispatch();
  const theme = useTheme();
  const profileDataRef = useRef(basicData);
  const hiddenFileInput = useRef(null);

  const [salaryRangeValue, setSalaryRangeValue] = useState(
    basicData?.salary?.[0] > 0 ? true : false
  );
  const [experienceRangeValue, setExperienceRangeValue] = useState(
    basicData?.experience?.length > 0 ? true : false
  );
  const [singleSalaryValue, setSingleSalaryValue] = useState(0);
  const currentDate = new Date();
  const [salary, setSalary] = useState([]);
  const [salaryRange, setSalaryRange] = useState(BASIC_RANGEMARKS_SLIDER);
  const { width } = useViewport();
  const [roleType, setRoleType] = useState(0);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isLoading, setIsLoading] = useState({
    association_ids: false,
    country_id: false,
    industry_id: false,
    job_title_id: false,
    preferred_qualification_ids: false,
    skills: false,
    tools: false,
    town_id: false,
  });
  const [rangeValue, setRangeValue] = useState([]);
  const [interval, setInterval] = useState();
  const [expRange, setExpRange] = useState(
    basicData?.experience?.length > 0 ? basicData?.experience : 2
  );
  const [expSingleValue, setExpSingleValue] = useState(2);
  const {
    titles,
    skills,
    tools,
    qualifications,
    requiredQua,
    currency,
    contractDetails,
    country,
    town,
    roleTypes,
    workSetup,
    initialSkills,
  } = useSelector((state) => state?.postJobs);
  const { industries, language, association, nationality } = useSelector(
    (state) => state?.myCv
  );

  const getMaleIcon = (
    <Box
      component="img"
      alt="male white"
      src={basicData?.gender === "male" ? maleWhite : maleBlack}
      sx={{ width: "7px" }}
    />
  );
  const getFemaleIcon = (
    <Box
      component="img"
      alt="female white"
      src={basicData?.gender === "female" ? femaleWhite : femaleBlack}
      sx={{ width: "9px" }}
    />
  );

  const handleGenderPrefer = (event) => {
    setBasicData({
      ...basicData,
      gender: "",
      gender_flag: event?.target?.checked,
    });
    const filteredErrors = errors?.filter((item) => item?.key != "gender");
    setErrors(filteredErrors);
  };

  const handleGender = (event, gender) => {
    const newBasicData = {
      ...basicData,
      gender: basicData?.gender === gender ? "" : gender,
    };
    const filteredErrors = errors?.filter((item) => item?.key != "gender");
    setErrors(filteredErrors);

    setBasicData(newBasicData);
  };

  const handleDateChange = (newValue) => {
    let formatedDate =
      dayjs(newValue)?.format("YYYY-MM-DD") === "Invalid Date"
        ? ""
        : dayjs(newValue)?.format("YYYY-MM-DD");
    const newBasicData = {
      ...basicData,
      job_start_date: formatedDate,
    };
    const filteredErrors = errors?.filter(
      (item) => item?.key != "job_start_date"
    );
    setErrors(filteredErrors);
    setBasicData(newBasicData);
  };

  const getAllDataNew = async (para, country_id) => {
    try {
      switch (para) {
        case "titles":
          await dispatch(
            getTitles({ setState: setIsLoading, type: "job_title_id" })
          );
          return;
        case "jobRole":
          await dispatch(getRoleTypes());
          return;
        case "contractDetails":
          await dispatch(getContractDetails());
          return;
        case "workSetup":
          await dispatch(getWorkSetup());
          return;
        case "country":
          await dispatch(getCountry());
          return;
        case "townsMain":
          await dispatch(
            getTown({
              payload: country_id,
              setState: setIsLoading,
              type: "town_id",
            })
          );
          return;
        case "industries":
          await dispatch(
            getIndustries({ setState: setIsLoading, type: "industry_id" })
          );
          return;
        case "skills":
          await dispatch(getSkills({ setState: setIsLoading, type: "skills" }));
          return;
        case "tools":
          await dispatch(getTools({ setState: setIsLoading, type: "tools" }));
          return;
        case "nationality":
          await dispatch(getNationality());
          return;
        case "language":
          await dispatch(getLanguage());
          return;
        case "requiredQua":
          await dispatch(getRequiredQualification());
          return;
        case "qualifications":
          await dispatch(
            getQualification({
              setState: setIsLoading,
              type: "preferred_qualification_ids",
            })
          );
          return;
        case "associations":
          await dispatch(
            getAssociation({ setState: setIsLoading, type: "association_ids" })
          );
          return;

        case "currency":
          await dispatch(getCurrency());
          return;

        default:
          return;
      }
    } catch (error) {
      dispatch(setLoading(false));
      dispatch(
        setAlert({
          show: true,
          type: ALERT_TYPE.ERROR,
          msg: ERROR_MSG,
        })
      );
    }
  };

  const getInitialData = async (basic) => {
    try {
      if (basic?.job_title_id !== "") {
        await getAllDataNew("titles");
      }
      if (basic?.job_role_type !== "") {
        getAllDataNew("jobRole");
      }
      if (basic?.contract_duration !== 0) {
        getAllDataNew("contractDetails");
      }
      if (basic?.work_setup !== "") {
        getAllDataNew("workSetup");
      }
      if (basic?.country_id !== 0) {
        getAllDataNew("country");
      }
      // if (basic?.town_id !== 0 || basic?.country_id !== 0) {
      //   getAllDataNew("townsMain", basic?.country_id);
      // }
      if (basic?.industry_id?.length !== 0) {
        getAllDataNew("industries");
      }
      if (basic?.skills?.length !== 0) {
        getAllDataNew("skills");
      }
      if (basic?.tools?.length !== 0) {
        getAllDataNew("tools");
      }
      if (basic?.nationality_ids?.length !== 0) {
        getAllDataNew("nationality");
      }
      if (basic?.language_ids?.length !== 0) {
        getAllDataNew("language");
      }
      if (basic?.required_qualification_id !== "") {
        getAllDataNew("requiredQua");
      }
      if (basic?.preferred_qualification_ids?.length !== 0) {
        getAllDataNew("qualifications");
      }
      if (basic?.association_ids?.length !== 0) {
        getAllDataNew("associations");
      }
      if (basic?.currency_id !== "") {
        getAllDataNew("currency");
      }
    } catch (error) {
      dispatch(setLoading(false));
      dispatch(
        setAlert({
          show: true,
          type: ALERT_TYPE?.ERROR,
          msg: ERROR_MSG,
        })
      );
    }
  };

  const handleJobRoleChange = (event) => {
    let newBasicData = { ...basicData };
    setRoleType(event?.target?.value);
    const currencySalary = currency?.find(
      (item) => item?.currency_id === basicData?.currency_id
    );
    let range;
    if (event?.target?.value === 3) {
      let { rangeArray, interval } = genarateLabelValueForFreelance(
        currencySalary ? currencySalary : { max_rate: 2050 }
      );
      range = rangeArray;
      setInterval(interval);
      setRangeValue([range?.length / 10, range?.length / 2]);
      setSingleSalaryValue(range?.length / 2);
      newBasicData = {
        ...newBasicData,
        salary: [
          (range?.length / 10) * interval,
          (range?.length / 2) * interval,
        ],
      };
    } else {
      range = genarateLabelValue(
        currencySalary ? currencySalary : { max_salary: 205000 }
      );
      if (basicData?.currency_id === 1) {
        newBasicData = {
          ...newBasicData,
          salary: [30000, 100000],
        };
        setRangeValue([3, 10]);
        setSingleSalaryValue(10);
      } else {
        newBasicData = {
          ...newBasicData,
          salary: [
            2000,
            Math.floor(currencySalary?.max_salary / 2 / 100) * 100,
          ],
        };
        setRangeValue([2, 5.2]);
        setSingleSalaryValue(5.2);
      }
    }
    setSalaryRange(range);
    newBasicData = {
      ...newBasicData,
      job_role_type:
        event?.target?.value === "None"
          ? ""
          : roleTypes?.find((role) => role?.id == event?.target?.value)?.name,
      contract_duration:
        event?.target?.value !== 2 ? "" : basicData?.contract_duration,
    };
    setErrors(errors?.filter((item) => item?.key !== event?.target?.name));
    setBasicData(newBasicData);
  };

  const handleContractDurationChange = (event) => {
    const newBasicData = {
      ...basicData,
      contract_duration:
        event?.target?.value === "None"
          ? ""
          : event?.target?.value?.split(" ")?.[0],
    };
    setBasicData(newBasicData);
  };

  const handleChange = (event) => {
    const {
      target: { value },
      target: { name },
      target: { id },
    } = event;
    let newBasicData = { ...basicData };
    let range;
    if (name === "currency_id") {
      const currencySalary = currency?.find(
        (item) => item?.currency_id === value
      );
      if (basicData?.job_role_type === "freelance") {
        let { rangeArray, interval } = genarateLabelValueForFreelance(
          currencySalary ? currencySalary : { max_rate: 2050 }
        );
        range = rangeArray;
        setInterval(interval);
        setRangeValue([range?.length / 10, range?.length / 2]);
        setSingleSalaryValue(range?.length / 2);
        newBasicData = {
          ...basicData,
          [name]: value,
          salary: [
            (range?.length / 10) * interval,
            (range?.length / 2) * interval,
          ],
        };
      } else {
        range = genarateLabelValue(
          currencySalary ? currencySalary : { max_salary: 205000 }
        );
        if (currencySalary?.currency === "ZAR") {
          setRangeValue([3, 10]);
          setSingleSalaryValue(10);
          newBasicData = {
            ...basicData,
            [name]: value,
            salary: [30000, 100000],
          };
        } else {
          newBasicData = {
            ...basicData,
            [name]: value,
            salary: [
              2000,
              Math.floor(currencySalary?.max_salary / 2 / 100) * 100,
            ],
          };
          setRangeValue([2, 5.2]);
          setSingleSalaryValue(5.2);
        }
      }
      setSalaryRange(range);
      setBasicData(newBasicData);
    }
  };

  const handleRangeSliderChange = (event, newValue) => {
    setRangeValue(newValue);
    let newArr = newValue?.map((val) => val * 1000);
    const newBasicData = {
      ...basicData,
      [event?.target?.name]:
        basicData?.currency_id === 1
          ? [newArr?.[0] * 10, newArr?.[1] * 10]
          : newArr,
    };
    const filteredErrors = errors?.filter(
      (item) => item?.key != event?.target?.name
    );
    setErrors(filteredErrors);
    setBasicData(newBasicData);
  };

  const handleRangeSliderChange2 = (event, newValue) => {
    setRangeValue(newValue);
    let newArr = newValue?.map((val) => Math.round(val * interval));
    const newBasicData = {
      ...basicData,
      [event?.target?.name]: newArr,
    };

    const filteredErrors = errors?.filter(
      (item) => item?.key != event?.target?.name
    );
    setErrors(filteredErrors);
    setBasicData(newBasicData);
  };

  const singleValueSlider2 = (event) => {
    const {
      target: { value },
      target: { name },
    } = event;
    let newArr = [0, Math.round(value * interval)];
    const newBasicData = {
      ...basicData,
      [event?.target?.name]: newArr,
    };
    setSingleSalaryValue(value);
    const filteredErrors = errors?.filter(
      (item) => item?.key != event?.target?.name
    );
    setErrors(filteredErrors);
    setBasicData(newBasicData);
  };

  const singleValueSlider = (event) => {
    const {
      target: { value },
      target: { name },
    } = event;
    setSingleSalaryValue(value);
    let newArr = [0, value * 1000];
    const newBasicData = {
      ...basicData,
      [event?.target?.name]:
        basicData?.currency_id === 1
          ? [newArr?.[0] * 10, newArr?.[1] * 10]
          : newArr,
    };
    const filteredErrors = errors?.filter(
      (item) => item?.key != event?.target?.name
    );
    setErrors(filteredErrors);
    setBasicData(newBasicData);
  };

  const handleWorkSetup = (event) => {
    const {
      target: { value },
      target: { name },
      target: { id },
    } = event;
    const newBasicData = {
      ...basicData,
      [name || id]: workSetup?.find((work) => work?.id == value)?.name || "",
    };
    setErrors(errors?.filter((item) => item?.key !== name));
    setBasicData(newBasicData);
  };

  const handleSalaryValue = (event) => {
    const {
      target: { value },
      target: { name },
      target: { id },
    } = event;
    setSalaryRangeValue(event?.target?.checked);

    if (basicData?.job_role_type === "freelance") {
      if (basicData?.currency_id === 1) {
        setRangeValue([
          basicData?.salary?.[0] / 100,
          basicData?.salary?.[1] / 100,
        ]);
        setSingleSalaryValue(basicData?.salary?.[1] / 100);
      } else {
        setRangeValue([
          basicData?.salary?.[0] / interval,
          basicData?.salary?.[1] / interval,
        ]);
        setSingleSalaryValue(basicData?.salary?.[1] / interval);
      }
    } else {
      if (basicData?.currency_id === 1) {
        setRangeValue([
          basicData?.salary?.[0] / 10000,
          basicData?.salary?.[1] / 10000,
        ]);
        setSingleSalaryValue(basicData?.salary?.[1] / 10000);
      } else {
        setRangeValue([
          basicData?.salary?.[0] / 1000,
          basicData?.salary?.[1] / 1000,
        ]);
        setSingleSalaryValue(basicData?.salary?.[1] / 1000);
      }
    }
    let newArr = [0, basicData?.salary?.[1]];
    const newBasicData = {
      ...basicData,
      salary:
        basicData?.currency_id === 1 ? [newArr?.[0], newArr?.[1]] : newArr,
    };
    const filteredErrors = errors?.filter(
      (item) => item?.key != event?.target?.name
    );
    setErrors(filteredErrors);
    setBasicData(newBasicData);
  };

  const handleExperienceValue = (event) => {
    setExperienceRangeValue(event?.target?.checked);
    if (event?.target?.checked) {
      setExpRange([0, 2]);
    } else {
      setExpSingleValue(2);
    }
  };

  const rangeValueHandler = (value) => {
    const symbol = currency?.find(
      (curr) => curr.currency_id === basicData?.currency_id
    )
      ? currency?.find((curr) => curr.currency_id === basicData?.currency_id)
      : "$";
    let valueNumberRange = checkNumberRange(symbol?.max_salary);
    let extractedValue = salaryRange?.find(
      (item) => item.value === value
    )?.label;
    let finalValue = extractedValue ? parseFloat(extractedValue) : value;
    return `${symbol?.symbol ? symbol?.symbol : symbol} ${
      valueNumberRange === "thousands"
        ? formatNumber(value * 1000)
        : formatNumber(value * 10000)
    }`;
  };

  const rangeValueHandler2 = (value) => {
    const symbol = currency?.find(
      (curr) => curr.currency_id === basicData?.currency_id
    )
      ? currency?.find((curr) => curr.currency_id === basicData?.currency_id)
      : "$";
    return `${symbol?.symbol ? symbol?.symbol : symbol} ${formatNumber(
      value * interval
    )}`;
  };

  const getQuaValue = () => {
    if (basicData?.preferred_qualification_ids?.length == 0) {
      return [];
    }

    return basicData?.preferred_qualification_ids;
  };
  const getSkillValue = () => {
    if (basicData?.skills?.length == 0) {
      return [];
    }
    return basicData?.skills?.map(
      (id) => skills?.find((skill) => skill?.id == id) || id
    );
  };
  const getToolValue = () => {
    if (basicData?.tools?.length == 0) {
      return [];
    }

    return basicData?.tools?.map(
      (id) => tools?.find((tool) => tool?.id == id) || id
    );
  };
  const getIndustriesValue = () => {
    if (basicData?.industry_id?.length === 0) {
      return [];
    }
    return basicData?.industry_id?.map(
      (id) => industries?.find((industry) => industry?.industry_id === id) || id
    );
  };
  const getAlternateTitleValue = () => {
    if (basicData?.alternateTitle?.length == 0) {
      return [];
    }
    return basicData?.alternateTitle?.map(
      (id) => titles?.find((industry) => industry?.job_title_id === id) || id
    );
  };

  const getLangValue = () => {
    if (basicData?.language_ids?.length === 0) {
      return [];
    }
    return basicData?.language_ids?.map(
      (lang) =>
        language?.find((language) => {
          return language?.language_id == lang;
        }) || lang
    );
  };
  const getNationalityValue = () => {
    if (basicData?.nationality_ids?.length === 0) {
      return [];
    }
    return basicData?.nationality_ids?.map(
      (national) =>
        nationality?.find((nationality) => {
          return nationality?.nationality_id == national;
        }) || national
    );
  };

  const getAssociationValue = () => {
    if (basicData?.association_ids?.length === 0) {
      return [];
    }
    return basicData?.association_ids;
  };
  const handleRequiredQualificationLevel = (event) => {
    const {
      target: { value },
      target: { name },
      target: { id },
    } = event;
    const newBasicData = {
      ...basicData,
      [name || id]: requiredQua?.find((work) => work?.id == value)?.id,
    };
    setErrors(errors?.filter((item) => item?.key !== name));
    setBasicData(newBasicData);
  };

  const handleMultipleAutoComplete = ({
    event,
    newValue,
    id,
    minTags,
    maxTags,
  }) => {
    if (newValue?.length > maxTags) {
      newValue?.splice(newValue, 1);
      dispatch(
        setAlert({
          show: true,
          type: ALERT_TYPE?.ERROR,
          msg: `The limit is ${minTags} to ${maxTags}`,
        })
      );
      return;
    }
    let newBasicData = {
      ...basicData,
      [id]: newValue?.map((val) => val?.inputValue || val?.id || val),
    };
    setErrors(errors?.filter((item) => item?.key !== id));
    setBasicData(newBasicData);
  };

  const handleNoPreference = (event, key) => {
    setBasicData({
      ...basicData,
      nationality_ids: [],
      nationality_flag: event?.target?.checked,
    });
    setErrors(errors?.filter((item) => item?.key !== key));
  };
  const handleLanguageNoPreference = (event, key) => {
    setBasicData({
      ...basicData,
      language_ids: [],
      language_flag: event?.target?.checked,
    });
    setErrors(errors?.filter((item) => item?.key !== key));
  };
  const handleSwitch = (event) => {
    const newBasicData = {
      ...basicData,
      [event?.target?.id]: event?.target?.checked,
    };
    setBasicData(newBasicData);
  };

  const expHandleChange = (event, newValue) => {
    let newBasicData;
    if (newValue?.length > 0) {
      setExpRange(newValue);
      let newArr = newValue?.map((val) => val);
      newBasicData = {
        ...basicData,
        [event?.target?.name]: newArr,
      };
    } else {
      setExpSingleValue(newValue);
      newBasicData = {
        ...basicData,
        [event?.target?.name]: [0, newValue],
      };
    }

    const filteredErrors = errors?.filter(
      (item) => item?.key != event?.target?.name
    );
    setErrors(filteredErrors);
    setBasicData(newBasicData);
  };

  const handleColor = (event, color) => {
    const newBasicData = {
      ...basicData,
      skinz: basicData?.skinz === color ? "" : color,
    };
    setBasicData(newBasicData);
  };

  const handleOnSelectChange = (ind, newValue, val, inputId) => {
    let newBasicData = { ...basicData };
    if (
      ind === "industry_id" ||
      ind === "tools" ||
      ind === "skills" ||
      ind === "alternateTitle"
    ) {
      newBasicData[ind] = newValue;
      if (ind === "tags") {
        dispatch(updateToInitialSkills());
      }
    } else if (
      newValue &&
      newValue?.label &&
      !["industry_id", "tags", "tools"]?.includes(newValue)
    ) {
      newBasicData[inputId] = newValue;
    } else {
      newBasicData[inputId] = newValue?.id || newValue;
    }
    if (inputId === "preferred_qualification_ids" && newValue?.value) {
      newBasicData[inputId] = [newValue];
    }
    if (inputId === "association_ids" && newValue?.value) {
      newBasicData[inputId] = [newValue];
    }
    if (inputId === "country_id" && newValue?.value) {
      getAllDataNew("townsMain", newValue?.value);
      newBasicData["town_id"] = "";
    }
    if (inputId === "association_ids" && newValue === null) {
      newBasicData["association_ids"] = [];
    }
    if (newBasicData?.job_title_id?.value && inputId === "job_title_id") {
      setErrors(errors?.filter((item) => item?.key !== inputId));
    }
    // if (newBasicData?.association_ids) {
    //   setErrors(errors.filter((item) => item.key !== inputId));
    // }
    if (newBasicData?.industry_id?.length > 0 && ind === "industry_id") {
      setErrors(errors?.filter((item) => item?.key !== ind));
    }
    if (newBasicData?.skills?.length >= 3 && ind === "skills") {
      setErrors(errors?.filter((item) => item?.key !== ind));
    }
    if (newBasicData?.tools?.length >= 3 && ind === "tools") {
      setErrors(errors?.filter((item) => item.key !== ind));
    }
    if (newBasicData?.country_id && inputId === "country_id") {
      setErrors(errors?.filter((item) => item.key !== inputId));
    }
    if (newBasicData?.town_id && inputId === "town_id") {
      setErrors(errors?.filter((item) => item.key !== inputId));
    } else if (newBasicData?.industry_id?.length > 0) {
      setErrors(errors?.filter((item) => item.key !== ind));
    } else if (newBasicData?.skills?.length >= 3) {
      setErrors(errors?.filter((item) => item.key !== ind));
    } else if (newBasicData?.tools?.length >= 3) {
      setErrors(errors?.filter((item) => item.key !== ind));
    } else if (newBasicData?.country_id) {
      setErrors(errors?.filter((item) => item.key !== ind));
    } else if (newBasicData?.town_id) {
    }
    if (newBasicData?.tools?.length >= 3 && ind === "tools") {
      setErrors(errors?.filter((item) => item?.key !== ind));
    }
    if (newBasicData?.country_id && inputId === "country_id") {
      setErrors(errors?.filter((item) => item?.key !== inputId));
    }
    if (newBasicData?.town_id && inputId === "town_id") {
      setErrors(errors?.filter((item) => item?.key !== inputId));
    }
    setBasicData(newBasicData);
  };

  const updateDebounceFunc = async (newValue, type) => {
    let params = {
      title: newValue || "",
      limit: 200,
    };
    if (newValue) {
      try {
        if (type === "industry_id") {
          dispatch(getIndustries({ ...params, setState: setIsLoading, type }));
        }
        if (type === "skills") {
          dispatch(getSkills({ ...params, setState: setIsLoading, type }));
        }
        if (type === "tools") {
          dispatch(getTools({ ...params, setState: setIsLoading, type }));
        }
        if (type === "preferred_qualification_ids") {
          dispatch(
            getQualification({ ...params, setState: setIsLoading, type })
          );
        }
        if (type === "association_ids") {
          dispatch(getAssociation({ ...params, setState: setIsLoading, type }));
        }
        if (type === "job_title_id" || type === "alternateTitle") {
          dispatch(getTitles({ ...params, setState: setIsLoading, type }));
        }
        if (type === "town_id") {
          dispatch(
            getTown({
              ...params,
              id: profileDataRef?.current?.country_id?.value,
              setState: setIsLoading,
              type,
            })
          );
        }
      } catch (error) {
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE?.ERROR,
            msg: error,
          })
        );
      }
    }
  };
  const debounceFunction = _.debounce(updateDebounceFunc, 750);

  const handleOnSelectInputChange = (newValue, inputId) => {
    debounceFunction(newValue, inputId);
  };
  const onCreateOption = (data, inputId) => {
    let newBasicData = {};
    if (data) {
      newBasicData = {
        ...basicData,
        [inputId]: { label: data, value: data },
      };
    }
    setErrors(errors?.filter((item) => item?.key !== inputId));
    setBasicData(newBasicData);
  };
  const onMenuOpen = () => {
    if (titles?.length < 1) {
      getAllDataNew("titles");
    }
    setIsMenuOpen(true);
  };
  const onMenuClose = () => {
    setIsMenuOpen(false);
  };

  const handleFileClick = () => {
    hiddenFileInput?.current?.click();
  };

  const handleFileChange = async (event) => {
    setOpenSpecBox(false);

    // const formData = new FormData();
    // formData.append("cv", event?.target?.files?.[0]);
    // try {
    //   const { payload } = await dispatch(uploadCv(formData));
    //   if (payload?.status === "success") {
    //     dispatch(
    //       setAlert({
    //         show: true,
    //         type: ALERT_TYPE?.SUCCESS,
    //         msg: "CV uploaded Successfully!",
    //       })
    //     );
    //     dispatch(setCVName(event?.target?.files?.[0]?.name));
    //     setOpenSpecBox(false);
    //   } else {
    //     dispatch(
    //       setAlert({
    //         show: true,
    //         type: ALERT_TYPE?.ERROR,
    //         msg: payload?.message,
    //       })
    //     );
    //   }
    // } catch (error) {
    //   dispatch(setAlert({ show: true }));
    // }
  };

  useEffect(() => {
    !isEditPostjob && setOpenSpecBox(true);
    getInitialData(basicData);
  }, []);
  useEffect(() => {
    profileDataRef.current = basicData;
  }, [basicData]);

  useEffect(() => {
    const currencySalary = currency?.find(
      (item) => item?.currency_id === basicData?.currency_id
    );
    let range;
    let salaryInterval;
    if (basicData?.job_role_type === "freelance") {
      let { rangeArray, interval } = genarateLabelValueForFreelance(
        currencySalary ? currencySalary : { max_rate: 2050 }
      );
      range = rangeArray;
      salaryInterval = interval;
      setInterval(interval);
      setRangeValue([range?.length / 10, range?.length / 2]);
      setSingleSalaryValue(range?.length / 2);
    } else {
      range = genarateLabelValue(
        currencySalary ? currencySalary : { max_salary: 205000 }
      );
    }
    setSalaryRange(range);

    if (basicData?.job_role_type === "freelance") {
      if (basicData?.currency_id === 1) {
        basicData?.salary?.[0] > 0
          ? setRangeValue([
              basicData?.salary?.[0] / 100,
              basicData?.salary?.[1] / 100,
            ])
          : setSingleSalaryValue(basicData?.salary?.[1] / 100);
      } else {
        basicData?.salary?.[0] > 0
          ? setRangeValue([
              basicData?.salary?.[0] / salaryInterval,
              basicData?.salary?.[1] / salaryInterval,
            ])
          : setSingleSalaryValue(basicData?.salary?.[1] / salaryInterval);
      }
    } else {
      if (basicData?.currency_id === 1) {
        basicData?.salary?.[0] > 0
          ? setRangeValue([
              basicData?.salary?.[0] / 10000,
              basicData?.salary?.[1] / 10000,
            ])
          : setSingleSalaryValue(basicData?.salary?.[1] / 10000);
      } else {
        basicData?.salary?.[0] > 0
          ? setRangeValue([
              basicData?.salary?.[0] / 1000,
              basicData?.salary?.[1] / 1000,
            ])
          : setSingleSalaryValue(basicData?.salary?.[1] / 1000);
      }
    }
  }, []);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          width: "100%",
          justifyContent: "space-between",
        }}
      >
        <Box>
          <Button
            variant="contained"
            color="checkers"
            sx={{
              minHeight: "45px",
              minWidth: { xs: "", md24: "150px" },
              padding: { xs: "6px 16px", md24: "6px 16px" },
              borderRadius: "25px 0 10px 0",
              textWrap: "nowrap",
              boxShadow: "none",
              "& .MuiButton-startIcon": {
                marginLeft: "0px",
              },
              color: "white",
            }}
          >
            {i18n["postAJob.thebasics"]}
          </Button>
        </Box>
        <Box sx={{ display: { xs: "block", tablet: "none" } }}>
          <Button
            variant="contained"
            color="uploadJobspec"
            sx={{
              color: "white",
              minHeight: "45px",
              minWidth: { xs: "", md24: "150px" },
              borderRadius: "0 0 0 10px",
              padding: { xs: "6px", md24: "0px" },
              textWrap: "nowrap",
              boxShadow: "none",
              "& .MuiButton-startIcon": {
                marginLeft: "0px",
              },
              fontSize: theme.typography.fontSize.sm,
              fontWeight: theme.typography.Bold,
            }}
            // onClick={handleFileClick}
            startIcon={
              <Box
                component={"img"}
                src={specIcon}
                sx={{
                  display: { xs: "none", tablet: "block" },
                  height: "20px",
                  width: "26px",
                }}
              />
            }
          >
            upload spec
          </Button>
          <Button
            variant="contained"
            color="parseJobspec"
            sx={{
              color: "white",
              minHeight: "45px",
              minWidth: { xs: "", md24: "150px" },
              padding: { xs: "6px", md24: "0px" },
              borderRadius: "0 25px 0 0",
              textWrap: "nowrap",
              "& .MuiButton-startIcon": {
                marginLeft: "0px",
              },
              fontSize: theme.typography.fontSize.sm,
              fontWeight: theme.typography.Bold,
            }}
            startIcon={
              <Box
                component={"img"}
                src={scrapeLinkedinIcon}
                sx={{
                  display: { xs: "none", tablet: "block" },
                  height: "20px",
                  width: "20px",
                }}
              />
            }
          >
            parse spec
          </Button>
        </Box>
        <Box sx={{ display: { xs: "none", tablet: "block" } }}>
          <Button
            variant="contained"
            color="uploadJobspec"
            sx={{
              color: "white",
              minHeight: "45px",
              minWidth: { xs: "", md24: "150px" },
              borderRadius: "0 0 0 10px",
              padding: { xs: "6px", md24: "0px" },
              textWrap: "nowrap",
              boxShadow: "none",
              "& .MuiButton-startIcon": {
                marginLeft: "0px",
              },
              fontSize: theme.typography.fontSize.sm,
              fontWeight: theme.typography.Bold,
            }}
            // onClick={handleFileClick}
            startIcon={
              <Box
                component={"img"}
                src={specIcon}
                sx={{
                  display: { xs: "none", tablet: "block" },
                  height: "20px",
                  width: "26px",
                }}
              />
            }
          >
            {i18n["postAJob.uploadjobspec"]}
          </Button>
          <Button
            variant="contained"
            color="parseJobspec"
            sx={{
              color: "white",
              minHeight: "45px",
              minWidth: { xs: "", md24: "150px" },
              padding: { xs: "6px", md24: "0px" },
              borderRadius: "0 25px 0 0",
              textWrap: "nowrap",
              "& .MuiButton-startIcon": {
                marginLeft: "0px",
              },
              fontSize: theme.typography.fontSize.sm,
              fontWeight: theme.typography.Bold,
            }}
            startIcon={
              <Box
                component={"img"}
                src={scrapeLinkedinIcon}
                sx={{
                  display: { xs: "none", tablet: "block" },
                  height: "20px",
                  width: "20px",
                }}
              />
            }
          >
            {i18n["postAJob.parsejobspec"]}
          </Button>
        </Box>
      </Box>
      <Box
        sx={{
          padding: "20px 30px 0 30px",
        }}
      >
        <Box>
          <Box
            sx={{
              display: "flex",
              // justifyContent: "space-between",
              marginBottom: "20px",
              flexDirection: { xs: "column", lg: "row" },
              gap: { xs: "20px", lg: "30px" },
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                // width: { xs: "100%", lg: `calc(100% / 3)` },
                flex: 1,
              }}
            >
              <InputLabel
                htmlFor="job_title_id"
                sx={{
                  color: "black",
                  paddingBottom: "5px",
                  fontSize: "14px",
                  fontWeight: theme.typography.Bold,
                }}
              >
                {i18n["postAJob.jobTitleLabelReq"]}
              </InputLabel>
              <Box>
                <CustomSelect
                  filterOption={() => true}
                  variant="createSelect"
                  onInputChange={handleOnSelectInputChange}
                  onChange={handleOnSelectChange}
                  onCreateOption={onCreateOption}
                  placeholder={i18n["postAJob.jobTitle"]}
                  inputId="job_title_id"
                  name="job_title_id"
                  value={
                    basicData?.job_title_id?.label &&
                    basicData?.job_title_id?.value
                      ? {
                          label: basicData?.job_title_id?.label
                            ? basicData?.job_title_id?.label
                            : "",
                          value: basicData?.job_title_id?.value
                            ? basicData?.job_title_id?.value
                            : "",
                        }
                      : null
                  }
                  onMenuOpen={onMenuOpen}
                  onMenuClose={onMenuClose}
                  option={titles?.map((item) => ({
                    value: item?.job_title_id,
                    label: item?.title,
                  }))}
                  isLoading={isLoading?.["job_title_id"]}
                  customStyle={{
                    menu: (provided) => ({
                      ...provided,
                      zIndex: 9999,
                    }),
                    menuList: (provided) => ({
                      ...provided,
                      "&::-webkit-scrollbar": {
                        width: "6px",
                        height: "6px",
                        borderRadius: "2.5px",
                        backgroundColor: "#efefef",
                      },
                      "&::-webkit-scrollbar-thumb": {
                        borderRadius: "2.5px",
                      },
                    }),
                    control: (baseStyles) => ({
                      ...baseStyles,
                      boxShadow: "none",
                      borderRadius: "10px",
                      height: "40px",
                      border: errors?.find(
                        (error) => error?.key == "job_title_id"
                      )
                        ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
                        : "1px solid #E0E0E0",
                      "&:hover": errors?.find(
                        (error) => error?.key == "job_title_id"
                      )
                        ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
                        : "1px solid #E0E0E0",
                      width: `100%`,
                    }),
                  }}
                />
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                // width: { xs: "100%", lg: `calc(100% / 3)` },
                flex: 1,
                flexDirection: "column",
              }}
            >
              <InputLabel
                htmlFor="job_role_type"
                sx={{
                  color: "black",
                  paddingBottom: "5px",
                  fontSize: "14px",
                  fontWeight: theme.typography.Bold,
                }}
              >
                {i18n["postAJob.roleTypeLabelReq"]}
              </InputLabel>
              <Paper
                elevation={3}
                sx={{
                  display: "flex",
                  borderRadius: "10px",
                  height: "40px",
                  boxShadow: "none",
                  border: `1px solid ${theme?.palette?.grayBorder}`,
                }}
              >
                <Select
                  name="job_role_type"
                  sx={{
                    height: "40px",
                    width: "100%",
                    borderRadius: "10px",
                    border: errors?.find(
                      (error) => error?.key == "job_role_type"
                    )
                      ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
                      : "1px solid #E0E0E0",

                    "& .MuiOutlinedInput-notchedOutline": {
                      border: "none",
                    },
                  }}
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: 400,
                      },
                    },
                  }}
                  renderValue={(selected) => {
                    if (!selected || selected === "None") {
                      return (
                        <em style={{ opacity: "0.4", fontStyle: "normal" }}>
                          Select
                        </em>
                      );
                    } else {
                      return roleTypes?.find((val) => val?.id === selected)
                        ?.name;
                    }
                  }}
                  onFocus={() => {
                    if (roleTypes?.length === 0) {
                      getAllDataNew("jobRole");
                    }
                  }}
                  onChange={handleJobRoleChange}
                  value={
                    roleTypes?.find(
                      (val) => val?.name === basicData?.job_role_type
                    )?.id || "None"
                  }
                >
                  <MenuItem value="None">
                    <em>None</em>
                  </MenuItem>
                  {roleTypes?.map((button) => (
                    <MenuItem key={button?.id} value={button?.id}>
                      {button?.name}
                    </MenuItem>
                  ))}
                </Select>
              </Paper>
            </Box>
            <Box
              sx={{
                display: "flex",
                // width: { xs: "100%", lg: `calc(100% / 3)` },
                flex: 1,
                flexDirection: "column",
              }}
            >
              <InputLabel
                htmlFor="Contract_Duration"
                sx={{
                  color: "black",
                  paddingBottom: "5px",
                  fontSize: "14px",
                  fontWeight: theme.typography.Bold,
                }}
              >
                {/* {i18n["postAJob.contemplatorsact_DurationLabelOpt"]} */}
                Contract Duration{" "}
                <span
                  style={{
                    fontSize: "14px",
                    fontWeight: theme.typography.Regular,
                  }}
                >
                  (optional)
                </span>
              </InputLabel>
              <Paper
                elevation={3}
                sx={{
                  display: "flex",
                  borderRadius: "10px",
                  height: "40px",
                  boxShadow: "none",
                  border: `1px solid ${theme.palette.grayBorder}`,
                }}
              >
                <Select
                  disabled={basicData?.job_role_type !== "contract"}
                  sx={{
                    height: "40px",
                    width: "100%",
                    borderRadius: "10px",

                    "& .MuiOutlinedInput-notchedOutline": {
                      border: "none",
                    },
                  }}
                  renderValue={(selected) => {
                    if (!selected || selected === "None") {
                      return (
                        <em style={{ opacity: "0.4", fontStyle: "normal" }}>
                          Select the contract duration
                        </em>
                      );
                    } else {
                      return selected + " months";
                    }
                  }}
                  onFocus={() => {
                    if (contractDetails?.length === 0) {
                      getAllDataNew("contractDetails");
                    }
                  }}
                  onChange={handleContractDurationChange}
                  value={basicData?.contract_duration || "None"}
                >
                  <MenuItem value="None">
                    <em>None</em>
                  </MenuItem>
                  {contractDetails?.map((button) => (
                    <MenuItem
                      key={button?.contract_duration_id}
                      value={button?.name}
                    >
                      {button?.name}
                    </MenuItem>
                  ))}
                </Select>
              </Paper>
            </Box>
          </Box>

          <Box
            sx={{
              display: "flex",
              // justifyContent: "space-between",
              marginBottom: "20px",
              flexDirection: { xs: "column", lg: "row" },
              gap: { xs: "20px", lg: "30px" },
            }}
          >
            <Box
              sx={{
                display: "flex",
                // width: { xs: "100%", lg: `calc(100% / 3)` },
                flex: 1,
                flexDirection: "column",
              }}
            >
              <InputLabel
                htmlFor="country_id"
                sx={{
                  color: "black",
                  paddingBottom: "5px",
                  fontSize: "14px",
                  fontWeight: theme.typography.Bold,
                }}
              >
                {i18n["postAJob.countryReq"]}
              </InputLabel>
              <Box>
                <CustomSelect
                  onMenuOpen={() => {
                    if (country?.length === 0) {
                      getAllDataNew("country");
                    }
                  }}
                  variant="default"
                  onInputChange={handleOnSelectInputChange}
                  onChange={handleOnSelectChange}
                  placeholder={"Select"}
                  inputId="country_id"
                  name="country_id"
                  value={basicData?.country_id ? basicData?.country_id : null}
                  isLoading={isLoading?.["country_id"]}
                  filterOption={"filter"}
                  customStyle={{
                    menu: (provided) => ({
                      ...provided,
                      zIndex: 9999,
                    }),
                    menuList: (provided) => ({
                      ...provided,
                      "&::-webkit-scrollbar": {
                        width: "6px",
                        height: "6px",
                        borderRadius: "2.5px",
                        backgroundColor: "#efefef",
                      },
                      "&::-webkit-scrollbar-thumb": {
                        borderRadius: "2.5px",
                      },
                    }),
                    option: (
                      styles,
                      { data, isDisabled, isFocused, isSelected }
                    ) => {
                      return {
                        ...styles,
                        color: isSelected ? "#fff" : "black",
                        ":active": {
                          ...styles[":active"],
                        },
                      };
                    },
                    control: (baseStyles) => ({
                      ...baseStyles,
                      boxShadow: "none",
                      borderRadius: "10px",
                      border: errors?.find(
                        (error) => error?.key == "country_id"
                      )
                        ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
                        : "1px solid #E0E0E0",
                      "&:hover": errors?.find(
                        (error) => error?.key == "country_id"
                      )
                        ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
                        : "1px solid #E0E0E0",
                      width: `100%`,
                    }),
                  }}
                  option={country?.map((item) => ({
                    value: item?.country_id,
                    label: item?.name,
                  }))}
                />
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                // width: { xs: "100%", lg: `calc(100% / 3)` },
                flex: 1,
                flexDirection: "column",
              }}
            >
              <InputLabel
                htmlFor="town_id"
                sx={{
                  color: "black",
                  paddingBottom: "5px",
                  fontSize: "14px",
                  fontWeight: theme.typography.Bold,
                }}
              >
                {i18n["postAJob.cityTown"]}
              </InputLabel>
              <Box>
                <CustomSelect
                  isDisabled={!basicData?.country_id}
                  onMenuClose={() => {}}
                  onMenuOpen={() => {
                    if (town?.length === 0) {
                      getAllDataNew(
                        "townsMain",
                        profileDataRef?.current?.country_id?.value
                      );
                    }
                  }}
                  variant="default"
                  onInputChange={handleOnSelectInputChange}
                  onChange={handleOnSelectChange}
                  placeholder={"Select"}
                  inputId="town_id"
                  name="town_id"
                  value={basicData?.town_id ? basicData?.town_id : null}
                  isLoading={isLoading?.["town_id"]}
                  customStyle={{
                    menu: (provided) => ({
                      ...provided,
                      zIndex: 9999,
                    }),
                    menuList: (provided) => ({
                      ...provided,
                      "&::-webkit-scrollbar": {
                        width: "6px",
                        height: "6px",
                        borderRadius: "2.5px",
                        backgroundColor: "#efefef",
                      },
                      "&::-webkit-scrollbar-thumb": {
                        borderRadius: "2.5px",
                      },
                    }),
                    option: (
                      styles,
                      { data, isDisabled, isFocused, isSelected }
                    ) => {
                      return {
                        ...styles,
                        color: isSelected ? "#fff" : "black",
                        ":active": {
                          ...styles[":active"],
                        },
                      };
                    },
                    control: (baseStyles) => ({
                      ...baseStyles,
                      boxShadow: "none",
                      borderRadius: "10px",
                      border: errors?.find((error) => error?.key === "town_id")
                        ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
                        : "1px solid #E0E0E0",
                      "&:hover": errors?.find(
                        (error) => error?.key === "town_id"
                      )
                        ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
                        : "1px solid #E0E0E0",
                      width: `100%`,
                    }),
                  }}
                  option={town?.map((item) => ({
                    value: item?.town_id,
                    label: item?.name,
                  }))}
                />
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                // width: { xs: "100%", lg: `calc(100% / 3)` },
                flex: 1,
              }}
            >
              <InputLabel
                htmlFor="work_setup"
                sx={{
                  color: "black",
                  paddingBottom: "5px",
                  fontSize: "14px",
                  fontWeight: theme.typography.Bold,
                }}
              >
                {i18n["postAJob.workSetupLableReq"]}
              </InputLabel>
              <Box>
                <SelectMenu
                  width={"100%"}
                  borderRadius="10px"
                  name="work_setup"
                  value={basicData?.work_setup}
                  onHandleChange={handleWorkSetup}
                  options={workSetup}
                  clear={true}
                  border={
                    errors?.find((error) => error?.key == "work_setup")
                      ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
                      : "1px solid #E0E0E0"
                  }
                  onFocus={() => {
                    if (workSetup?.length === 0) {
                      getAllDataNew("workSetup");
                    }
                  }}
                  placeholder={"Select between remote, in-office or hybrid"}
                />
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              mb: 3,
            }}
          >
            <InputLabel
              htmlFor="associated_industries"
              sx={{
                color: "black",
                paddingBottom: "5px",
                fontSize: "14px",
                fontWeight: theme.typography.Bold,
              }}
            >
              Alternative Job Title(s){" "}
              <span
                style={{
                  fontSize: "14px",
                  fontWeight: theme.typography.Regular,
                }}
              >
                (optional)
              </span>
            </InputLabel>
            <CustomSelect
              inputId={"alternateTitle"}
              name={"alternateTitle"}
              variant={"createMultiSelect"}
              closeMenuOnSelect={false}
              isMulti={true}
              isLoading={isLoading?.["alternateTitle"]}
              multiSelectOptions={titles?.map((item) => ({
                value: item?.job_title_id,
                label: item?.name,
              }))}
              placeholder={
                "Select or add up to 5 alternative job titles for the role (we use this for matching purposes)"
              }
              onChange={(e, val) => handleOnSelectChange(e, val)}
              customStyle={{
                option: (
                  styles,
                  { data, isDisabled, isFocused, isSelected }
                ) => {
                  return {
                    ...styles,
                    color: isSelected ? "#fff" : "black",
                    ":active": {
                      ...styles[":active"],
                    },
                  };
                },
                control: (baseStyles) => ({
                  ...baseStyles,
                  boxShadow: "none",
                  borderRadius: "10px",
                  minHeight: `40px`,
                  border: errors?.find((error) =>
                    error?.key?.startsWith("industry_id")
                  )
                    ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
                    : "1px solid #E0E0E0",
                  "&:hover": errors?.find((error) =>
                    error?.key?.startsWith("industry_id")
                  )
                    ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
                    : "1px solid #E0E0E0",
                  width: `100%`,
                }),
              }}
              onInputChange={handleOnSelectInputChange}
              multiOnFocus={() => {
                getAllDataNew("titles");
              }}
              value={getAlternateTitleValue()}
              limitTags={5}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              mb: 3,
            }}
          >
            <InputLabel
              htmlFor="associated_industries"
              sx={{
                color: "black",
                paddingBottom: "5px",
                fontSize: "14px",
                fontWeight: theme.typography.Bold,
              }}
            >
              {i18n["postAJob.associated_industriesLabelReq"]}
            </InputLabel>

            <CustomSelect
              inputId={"industry_id"}
              name={"industry_id"}
              variant={"multiSelect"}
              closeMenuOnSelect={false}
              isMulti={true}
              isLoading={isLoading?.["industry_id"]}
              multiSelectOptions={industries?.map((item) => ({
                value: item?.industry_id,
                label: item?.name,
              }))}
              placeholder={
                "Enter any industry(ies) relevant to the role (min 1, max 5)"
              }
              onChange={(e, val) => handleOnSelectChange(e, val)}
              customStyle={{
                option: (
                  styles,
                  { data, isDisabled, isFocused, isSelected }
                ) => {
                  return {
                    ...styles,
                    color: isSelected ? "#fff" : "black",
                    ":active": {
                      ...styles[":active"],
                    },
                  };
                },
                control: (baseStyles) => ({
                  ...baseStyles,
                  boxShadow: "none",
                  borderRadius: "10px",
                  minHeight: `40px`,
                  border: errors?.find((error) =>
                    error?.key?.startsWith("industry_id")
                  )
                    ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
                    : "1px solid #E0E0E0",
                  "&:hover": errors?.find((error) =>
                    error?.key?.startsWith("industry_id")
                  )
                    ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
                    : "1px solid #E0E0E0",
                  width: `100%`,
                }),
              }}
              onInputChange={handleOnSelectInputChange}
              multiOnFocus={() => {
                if (industries?.length < 1) {
                  getAllDataNew("industries");
                }
              }}
              value={getIndustriesValue()}
              limitTags={5}
            />
          </Box>
          <Box sx={{ display: "flex", flexDirection: "column", mb: 3 }}>
            <InputLabel
              htmlFor="skills"
              sx={{
                color: "black",
                paddingBottom: "5px",
                fontSize: "14px",
                fontWeight: theme.typography.Bold,
              }}
            >
              {i18n["postAJob.skillsLabelReq"]}
            </InputLabel>

            <CustomSelect
              inputId={"skills"}
              name={"skills"}
              variant={"createMultiSelect"}
              closeMenuOnSelect={false}
              isMulti={true}
              isLoading={isLoading?.["skills"]}
              multiSelectOptions={skills?.map((item) => ({
                value: item?.tag_id,
                label: item?.name,
              }))}
              placeholder={
                "Enter any required skill(s) relating to the job (min 3, max 10)"
              }
              onChange={(e, val) => handleOnSelectChange(e, val)}
              customStyle={{
                option: (
                  styles,
                  { data, isDisabled, isFocused, isSelected }
                ) => {
                  return {
                    ...styles,
                    color: isSelected ? "#fff" : "black",
                    ":active": {
                      ...styles[":active"],
                    },
                  };
                },
                control: (baseStyles) => ({
                  ...baseStyles,
                  boxShadow: "none",
                  borderRadius: "10px",
                  minHeight: `40px`,

                  border: errors?.find((error) =>
                    error?.key?.startsWith("skills")
                  )
                    ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
                    : "1px solid #E0E0E0",
                  "&:hover": errors?.find((error) =>
                    error?.key?.startsWith("skills")
                  )
                    ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
                    : "1px solid #E0E0E0",
                  width: `100%`,
                }),
              }}
              onInputChange={handleOnSelectInputChange}
              multiOnFocus={() => {
                if (skills?.length < 1) {
                  getAllDataNew("skills");
                }
              }}
              value={getSkillValue()}
              limitTags={10}
              initialOptions={initialSkills?.map((item) => ({
                value: item?.tag_id,
                label: item?.name,
              }))}
            />
          </Box>
          <Box sx={{ display: "flex", flexDirection: "column", mb: 3 }}>
            <InputLabel
              htmlFor="tools"
              sx={{
                color: "black",
                paddingBottom: "5px",
                fontSize: "14px",
                fontWeight: theme.typography.Bold,
              }}
            >
              {i18n["postAJob.toolsLableReq"]}
            </InputLabel>
            <CustomSelect
              inputId={"tools"}
              name={"tools"}
              variant={"createMultiSelect"}
              closeMenuOnSelect={false}
              isMulti={true}
              isLoading={isLoading?.["tools"]}
              multiSelectOptions={tools?.map((item) => ({
                value: item?.tool_id,
                label: item?.name,
              }))}
              placeholder={
                "Enter any required software tools relating to the job (min 3, max 10)"
              }
              onChange={(e, val) => handleOnSelectChange(e, val)}
              customStyle={{
                option: (
                  styles,
                  { data, isDisabled, isFocused, isSelected }
                ) => {
                  return {
                    ...styles,
                    color: isSelected ? "#fff" : "black",
                    ":active": {
                      ...styles[":active"],
                    },
                  };
                },
                control: (baseStyles) => ({
                  ...baseStyles,
                  boxShadow: "none",
                  borderRadius: "10px",
                  minHeight: `40px`,

                  border: errors?.find((error) =>
                    error?.key?.startsWith("tools")
                  )
                    ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
                    : "1px solid #E0E0E0",
                  "&:hover": errors?.find((error) => error?.key == "tools")
                    ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
                    : "1px solid #E0E0E0",
                  width: `100%`,
                }),
              }}
              onInputChange={handleOnSelectInputChange}
              multiOnFocus={() => {
                if (tools?.length < 1) {
                  getAllDataNew("tools");
                }
              }}
              value={getToolValue()}
              limitTags={10}
            />
          </Box>
          {/* Nationality,  language */}
          <Box
            sx={{
              display: "flex",
              // justifyContent: "space-between",
              marginBottom: "20px",
              flexDirection: { xs: "column", lg: "row" },
              gap: { xs: "20px", lg: "30px" },
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                width: { xs: "100%", lg: `calc(100% / 2)` },
              }}
            >
              <InputLabel
                htmlFor="nationality_ids"
                sx={{
                  color: "black",
                  paddingBottom: "5px",
                  fontSize: "14px",
                  fontWeight: theme.typography.Bold,
                }}
              >
                {/* Nationality(ies) */}
                {i18n["postAJob.nationalityReq"]}
              </InputLabel>
              <AutoComplete
                multiple={true}
                borderRadius="10px"
                id="nationality_ids"
                value={getNationalityValue()}
                onChange={handleMultipleAutoComplete}
                sx={{
                  // display: "inline-table",
                  display: "flex",
                  alignItems: "center",
                  width: "100%",
                  fontSize: "14px",
                  border:
                    // basicData.nationality_ids.length === 0 &&
                    errors?.find((error) => error?.key === "nationality_ids")
                      ? // &&
                        // !basicData?.nationality_flag
                        "1px solid red"
                      : `1px solid ${theme.palette.grayBorder}`,
                }}
                placeholder={"Select any nationality(s) required for the job"}
                data={nationality}
                onFocus={() => {
                  if (nationality?.length === 0) {
                    getAllDataNew("nationality");
                  }
                }}
                disableCloseOnSelect={true}
                disabled={basicData?.nationality_flag}
              ></AutoComplete>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                width: { xs: "100%", lg: `calc(100% / 2)` },
              }}
            >
              <InputLabel
                htmlFor="language_ids"
                sx={{
                  color: "black",
                  paddingBottom: "5px",
                  fontSize: "14px",
                  fontWeight: theme.typography.Bold,
                }}
              >
                {i18n["postAJob.languagesLabelReq"]}
              </InputLabel>

              <AutoComplete
                multiple={true}
                borderRadius="10px"
                id="language_ids"
                value={getLangValue()}
                onChange={handleMultipleAutoComplete}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  fontSize: "14px",
                  width: "100%",
                  border:
                    basicData?.language_ids?.length === 0 &&
                    errors?.find((error) => error?.key === "language_ids")
                      ? "1px solid red"
                      : `1px solid ${theme.palette.grayBorder}`,
                }}
                placeholder={"Select any language(s) required for the job"}
                data={language}
                disableCloseOnSelect={true}
                disabled={basicData?.language_flag}
                onFocus={() => {
                  if (language?.length === 0) {
                    getAllDataNew("language");
                  }
                }}
              ></AutoComplete>
            </Box>
          </Box>

          {/* Required Qualification, preferred Qualification */}
          <Box
            sx={{
              display: "flex",
              // justifyContent: "space-between",
              marginBottom: "20px",
              flexDirection: { xs: "column", lg: "row" },
              gap: { xs: "20px", lg: "30px" },
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                // width: { xs: "100%", lg: `calc(100% / 3)` },
                flex: 1,
              }}
            >
              <InputLabel
                htmlFor="required_qualification_id"
                sx={{
                  color: "black",
                  paddingBottom: "5px",
                  fontSize: "14px",
                  fontWeight: theme.typography.Bold,
                }}
              >
                {i18n["postAJob.requiredQualificationLableReq"]}
              </InputLabel>
              <SelectMenu
                borderRadius="10px"
                name="required_qualification_id"
                value={basicData?.required_qualification_id}
                onHandleChange={handleRequiredQualificationLevel}
                options={requiredQua}
                border={
                  // !basicData.required_qualification_id &&
                  errors?.find(
                    (error) => error?.key == "required_qualification_id"
                  )
                    ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
                    : "1px solid #E0E0E0"
                }
                onFocus={() => {
                  if (requiredQua?.length === 0) {
                    getAllDataNew("requiredQua");
                  }
                }}
                placeholder={"Select the minimum required qualification level"}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                // width: { xs: "100%", lg: `calc(100% / 2)` },
                flex: 2,
              }}
            >
              <InputLabel
                htmlFor="preferred_qualification_ids"
                sx={{
                  color: "black",
                  paddingBottom: "5px",
                  fontSize: "14px",
                  fontWeight: theme.typography.Bold,
                }}
              >
                {/* {i18n["postAJob.preferredQualificationLabelOpt"]} */}
                Preferred Qualification(s){" "}
                <span
                  style={{
                    fontSize: "14px",
                    fontWeight: theme.typography.Regular,
                  }}
                >
                  (optional)
                </span>
              </InputLabel>
              <Box>
                <CustomSelect
                  variant="default"
                  onInputChange={handleOnSelectInputChange}
                  onChange={handleOnSelectChange}
                  placeholder={"Enter any preferred qualification(s)"}
                  inputId="preferred_qualification_ids"
                  name="preferred_qualification_ids"
                  value={getQuaValue()}
                  onMenuOpen={() => {
                    if (qualifications?.length < 1) {
                      getAllDataNew("qualifications");
                    }
                    setIsMenuOpen(true);
                  }}
                  onMenuClose={onMenuClose}
                  option={qualifications?.map((item) => ({
                    value: item?.qualification_id,
                    label: item?.name,
                  }))}
                  isLoading={isLoading?.["preferred_qualification_ids"]}
                  customStyle={{
                    option: (
                      styles,
                      { data, isDisabled, isFocused, isSelected }
                    ) => {
                      return {
                        ...styles,
                        color: isSelected ? "#fff" : "black",
                        ":active": {
                          ...styles[":active"],
                        },
                      };
                    },
                    menu: (provided) => ({
                      ...provided,
                      zIndex: 9999,
                    }),
                    menuList: (provided) => ({
                      ...provided,
                      "&::-webkit-scrollbar": {
                        width: "6px",
                        height: "6px",
                        borderRadius: "2.5px",
                        backgroundColor: "#efefef",
                      },
                      "&::-webkit-scrollbar-thumb": {
                        borderRadius: "2.5px",
                      },
                    }),
                    control: (baseStyles) => ({
                      ...baseStyles,
                      boxShadow: "none",
                      borderRadius: "10px",
                      height: `40px`,
                      width: "100%",
                    }),
                  }}
                />
              </Box>
            </Box>
          </Box>
          {/* Preferred Associations */}
          <Box
            sx={{
              display: "flex",
              // justifyContent: "space-between",
              marginBottom: "20px",
              flexDirection: { xs: "column", lg: "row" },
              gap: { xs: "20px", lg: "30px" },
            }}
          >
            <Box
              sx={{
                // width: { xs: "100%", lg: `calc(100% / 3)` },
                flex: 1,
              }}
            >
              <InputLabel
                htmlFor="beginDate"
                sx={{
                  color: "black",
                  paddingBottom: "5px",
                  fontSize: "14px",
                  fontWeight: theme.typography.Bold,
                }}
              >
                {i18n["postAJob.preferredStartDateReq"]}
                {/* Preferred start date */}
              </InputLabel>
              <LocalizationProvider dateAdapter={AdapterDayjs} locale="en-gb">
                <DatePicker
                  name="job_start_date"
                  value={basicData?.job_start_date}
                  onChange={handleDateChange}
                  minDate={currentDate}
                  // inputFormat="DD-MM-YY"
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      value={basicData?.job_start_date}
                      // inputProps={{
                      //   placeholder: "DD-MM-YY",
                      // }}
                      sx={{
                        width: "100%",
                        "& .MuiInputBase-root": {
                          height: "40px",
                          width: "100%",
                          borderRadius: "10px",
                          border: errors?.find(
                            (error) => error?.key == "job_start_date"
                          )
                            ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
                            : "1px solid #E0E0E0",
                          ":hover": {
                            border: errors?.find(
                              (error) => error?.key == "job_start_date"
                            )
                              ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
                              : "1px solid #E0E0E0",
                          },
                        },
                        "& .MuiIconButton-root": {
                          color: theme.palette.yellowColor, // Change this to the desired color
                        },
                        "& fieldset": {
                          border: "none",
                        },
                      }}
                    />
                  )}
                />
              </LocalizationProvider>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                // width: { xs: "100%", lg: `calc(100% / 2)` },
                flex: 2,
              }}
            >
              <InputLabel
                htmlFor="association_ids"
                sx={{
                  color: "black",
                  paddingBottom: "5px",
                  fontSize: "14px",
                  fontWeight: theme.typography.Bold,
                }}
              >
                {/* {i18n["postAJob.preferredAssociationsLabel"]}  */}
                Preferred Association(s){" "}
                <span
                  style={{
                    fontSize: "14px",
                    fontWeight: theme.typography.Regular,
                  }}
                >
                  (optional)
                </span>
              </InputLabel>
              <CustomSelect
                variant="default"
                onInputChange={handleOnSelectInputChange}
                onChange={handleOnSelectChange}
                placeholder={"Enter any preferred association(s)"}
                inputId="association_ids"
                name="association_ids"
                value={getAssociationValue()}
                onMenuOpen={() => {
                  if (association?.length < 1) {
                    getAllDataNew("associations");
                  }
                  setIsMenuOpen(true);
                }}
                onMenuClose={onMenuClose}
                option={association?.map((item) => ({
                  value: item?.association_id,
                  label: item?.name,
                }))}
                isLoading={isLoading?.["association_ids"]}
                customStyle={{
                  option: (
                    styles,
                    { data, isDisabled, isFocused, isSelected }
                  ) => {
                    return {
                      ...styles,
                      color: isSelected ? "#fff" : "black",
                      ":active": {
                        ...styles[":active"],
                      },
                    };
                  },
                  menu: (provided) => ({
                    ...provided,
                    zIndex: 9999,
                  }),
                  menuList: (provided) => ({
                    ...provided,
                    "&::-webkit-scrollbar": {
                      width: "6px",
                      height: "6px",
                      borderRadius: "2.5px",
                      backgroundColor: "#efefef",
                    },
                    "&::-webkit-scrollbar-thumb": {
                      borderRadius: "2.5px",
                    },
                  }),
                  control: (baseStyles) => ({
                    ...baseStyles,
                    boxShadow: "none",
                    borderRadius: "10px",
                    height: `40px`,
                    width: "100%",
                    border:
                      // basicData.association_ids.length <= 0 &&
                      errors?.find((error) => error?.key === "association_ids")
                        ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
                        : "1px solid #E0E0E0",
                    "&:hover":
                      // basicData.association_ids.length <= 0 &&
                      errors?.find((error) => error?.key === "association_ids")
                        ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
                        : "1px solid #E0E0E0",
                  }),
                }}
              />
            </Box>
          </Box>
          {/* preferred StartData, Currency  */}
          <Box
            sx={{
              display: "flex",
              marginBottom: "20px",
              // gap: "10px",
              flexWrap: "wrap",
              // justifyContent: "space-between",
            }}
          >
            <Box
              sx={{
                width: "100%",
                // maxWidth: { xs: "100%", lg: `calc(100% / 3)` },
                marginRight: "34px",
                flex: 1,
              }}
            >
              <InputLabel
                htmlFor="currency_id"
                sx={{
                  color: "black",
                  paddingBottom: "5px",
                  fontSize: "14px",
                  fontWeight: theme.typography.Bold,
                }}
              >
                {i18n["postAJob.currencyIdLabelReq"]}
              </InputLabel>
              <SelectMenu
                clear={true}
                borderRadius="10px"
                name="currency_id"
                value={basicData?.currency_id}
                onHandleChange={handleChange}
                options={currency}
                border={
                  basicData?.currency_id?.length === 0 &&
                  errors?.find((error) => error?.key === "currency_id")
                    ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
                    : "1px solid #E0E0E0"
                }
                onFocus={() => {
                  if (currency?.length === 0) {
                    getAllDataNew("currency");
                  }
                }}
                placeholder={"Select the preferred currency of payment"}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                flex: 2,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                  height: "40px",
                  marginTop: "25px",
                  marginRight: "55px",
                }}
              >
                <Typography
                  sx={{
                    minWidth: "fit-content",
                  }}
                >
                  {i18n["postAJob.displaySalary"]}
                </Typography>
                <Typography
                  sx={{
                    padding: "5px",
                    height: "8px",
                    width: "8px",
                    borderRadius: "5px",
                    fontSize: "15px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    fontWeight: 700,
                    border: 1,
                  }}
                >
                  i
                </Typography>

                <BlueSwitch
                  id="hide_salary"
                  checked={basicData?.hide_salary}
                  onChange={(event) => {
                    const newBasicData = {
                      ...basicData,
                      hide_salary: Number(event?.target?.checked),
                    };
                    setBasicData(newBasicData);
                  }}
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                  height: "40px",
                  marginTop: "25px",
                }}
              >
                <Typography
                  sx={{
                    minWidth: "fit-content",
                  }}
                >
                  {i18n["postAJob.salaryNegotiable"]}
                </Typography>
                <BlueSwitch
                  id="salary_negotiate"
                  checked={basicData?.salary_negotiate}
                  onChange={handleSwitch}
                />
              </Box>
            </Box>
          </Box>
          {/*  salary, work experience */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              paddingLeft: "10px",
              paddingRight: "10px",
              marginBottom: "24px",
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center", gap: "30px" }}>
              <InputLabel
                htmlFor="salary_id"
                sx={{
                  color: "black",
                  fontSize: "14px",
                  fontWeight: theme.typography.Bold,
                }}
              >
                {i18n["postAJob.indicatedSalaryReq"]}
              </InputLabel>
              <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: theme.typography.Regular,
                  }}
                >
                  single salary
                </Typography>
                <ToggleSwitch
                  color="discovery"
                  id="salary_value"
                  checked={salaryRangeValue}
                  onChange={(event) => handleSalaryValue(event)}
                />
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: theme.typography.Regular,
                  }}
                >
                  salary range
                </Typography>
              </Box>
            </Box>
            <SalarySlider
              basicData={basicData}
              rangeValue={rangeValue}
              handleRangeSliderChange={handleRangeSliderChange}
              handleRangeSliderChange2={handleRangeSliderChange2}
              rangeValueHandler={rangeValueHandler}
              rangeValueHandler2={rangeValueHandler2}
              salaryRange={salaryRange}
              errors={errors}
              salaryRangeValue={salaryRangeValue}
              singleValueSlider={singleValueSlider}
              singleValueSlider2={singleValueSlider2}
              singleSalaryValue={singleSalaryValue}
              color={"salarySlider"}
              name={"salary"}
              sx={{
                width: "98%",
                ml: 1,
                marginTop: "30px",
                color: "transparent",
                "& .MuiSlider-rail": {
                  backgroundColor: theme?.palette?.eyeview100?.main,
                  height: "10px",
                  border:
                    rangeValue?.at(0) === 0 && rangeValue?.at(1) === 0
                      ? `1px solid ${theme?.jobCard?.jobStatus?.Rejected?.main}`
                      : "1px solid #E0E0E0",
                },
                "& .MuiSlider-track": {
                  backgroundColor: theme?.palette?.discovery?.main,
                  borderRadius: "3px",
                  height: "10px",
                },
                "& .MuiSlider-thumb": {
                  backgroundColor: theme?.palette?.discovery?.contrastText,
                  borderRadius: "6px",
                },
                "& .MuiSlider-markLabel": {
                  fontSize: "12px",
                },
                "& .MuiSlider-valueLabel.MuiSlider-valueLabelOpen": {
                  backgroundColor: "#EBECF3",
                },
                "& .MuiSlider-valueLabel:before": {
                  display: "none",
                },
                "& .MuiSlider-valueLabel": {
                  color: "#000",
                },
              }}
              jobRoleType={basicData?.job_role_type}
            />
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              paddingLeft: "10px",
              paddingRight: "10px",
              marginBottom: "35px",
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center", gap: "30px" }}>
              <InputLabel
                htmlFor="experience_id"
                sx={{
                  color: "black",
                  paddingBottom: "5px",
                  fontSize: "14px",
                  fontWeight: theme.typography.Bold,
                }}
              >
                How many years of work experience does the role require?
              </InputLabel>
              <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: theme.typography.Regular,
                  }}
                >
                  single term
                </Typography>
                <ToggleSwitch
                  color="discovery"
                  id="salary_value"
                  checked={experienceRangeValue}
                  onChange={(event) => handleExperienceValue(event)}
                />
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: theme.typography.Regular,
                  }}
                >
                  experience range
                </Typography>
              </Box>
            </Box>
            <Slider
              disableSwap
              valueLabelDisplay="on"
              sx={{
                marginTop: 4,
                width: "98%",
                ml: "1px",
                "& .MuiSlider-rail": {
                  backgroundColor: theme.palette.SliderBackground,
                  height: "10px",
                },
                "& .MuiSlider-track": {
                  height: "10px",
                },
                "& .MuiSlider-thumb": {
                  borderRadius: "15%",
                  background: "white",
                },
                "& .MuiSlider-valueLabel": {
                  fontSize: 12,
                  fontWeight: "normal",
                  top: -6,
                  backgroundColor: theme.palette.grayBackground,
                  borderRadius: 1,
                  color: theme.palette.text.primary,
                  "&:before": {
                    display: "none",
                  },
                  "& *": {
                    background: "transparent",
                    color: theme.palette.mode === "dark" ? "#fff" : "#000",
                  },
                },
              }}
              name="experience"
              getAriaLabel={() => "Temperature range"}
              value={experienceRangeValue ? expRange : expSingleValue}
              onChange={expHandleChange}
              color="experienceSlider"
              valueLabelFormat={rangeValueExperience}
              getAriaValueText={rangeValueExperience}
              min={0}
              max={20}
              step={1}
              marks={
                width < 768
                  ? [BASIC_MARKS?.[0], BASIC_MARKS?.[BASIC_MARKS?.length - 1]]
                  : BASIC_MARKS
              }
            />
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "35px",
            mt: { xs: "4", lg: "0" },
            width: "100%",
            flexDirection: { xs: "column", lg: "row" },
            gap: { xs: "20px", lg: "30px" },
          }}
        >
          <Box
            sx={{
              // width: { xs: "100%", lg: `calc(100% / 3)` },
              flex: 1,
              gap: "18px",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Typography
              sx={{
                fontSize: "14px",
                fontWeight: theme.typography.Bold,
              }}
            >
              {i18n["postAJob.workSetupRequirementsOpt"]}
              {/* Work setup requirements */}
            </Typography>

            <Box
              sx={{
                display: "flex",
                justifyContent: { xs: "space-around", tablet: "space-around" },
                // gap: { xs: "0px", tablet: "100px" },
                flexDirection: { xs: "column", tablet: "row" },
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Box
                  component={"img"}
                  src={own_vehicle}
                  sx={{
                    height: "60px",
                    width: "60px",
                  }}
                />
                <Typography
                  sx={{
                    color: "black",
                    fontSize: "14px",
                    fontWeight: 500,
                  }}
                >
                  Own transport
                </Typography>
                <BlueSwitch
                  id="own_transport"
                  checked={basicData?.own_transport}
                  onChange={handleSwitch}
                />
              </Box>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Box
                  component={"img"}
                  src={own_equipment}
                  sx={{
                    height: "60px",
                    width: "60px",
                  }}
                />
                <Typography
                  sx={{
                    color: "black",
                    fontSize: "14px",
                    fontWeight: 500,
                  }}
                >
                  Own equipment
                </Typography>
                <BlueSwitch
                  id="own_equipment"
                  checked={basicData?.own_equipment}
                  onChange={handleSwitch}
                />
              </Box>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Box
                  component={"img"}
                  src={own_internet}
                  sx={{
                    height: "60px",
                    width: "60px",
                  }}
                />
                <Typography
                  sx={{
                    color: "black",
                    fontSize: "14px",
                    fontWeight: 500,
                  }}
                >
                  Own internet
                </Typography>
                <BlueSwitch
                  id="internet_access"
                  checked={basicData?.internet_access}
                  onChange={handleSwitch}
                />
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              gap: "18px",
              flexDirection: { xs: "column", tablet: "column" },
              // width: { xs: "100%", lg: `calc(100% / 3)` },
              flex: 1,
            }}
          >
            <Box>
              <Typography
                sx={{
                  fontSize: "14px",
                  fontWeight: theme.typography.Bold,
                }}
              >
                Preferred Crayon Skinz{" "}
                <span
                  style={{
                    fontSize: "14px",
                    fontWeight: theme.typography.Regular,
                  }}
                >
                  (optional)
                </span>
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                gap: "20px",
                justifyContent: "space-between",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  cursor: "pointer",
                }}
                onClick={(event) => {
                  handleColor(event, 0);
                }}
              >
                <Box
                  component={"img"}
                  src={
                    basicData?.gender === "male" ||
                    basicData?.gender === "" ||
                    basicData?.gender === null
                      ? white
                      : whiteFemale
                  }
                  sx={{
                    height: {
                      xs: "60px",
                      lg: "60px",
                    },
                    width: {
                      xs: "60px",
                      lg: "60px",
                    },
                  }}
                />
                <Typography
                  sx={{
                    color: "black",
                    fontSize: "14px",
                    fontWeight: theme.typography.Regular,
                  }}
                >
                  White
                </Typography>
                {basicData?.skinz === 0 && (
                  <CheckCircle
                    sx={{
                      width: 25,
                      height: 25,
                      fontSize: 30,
                    }}
                    color="checkColor"
                  />
                )}
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  cursor: "pointer",
                }}
                onClick={(event) => {
                  handleColor(event, 1);
                }}
              >
                <Box
                  component={"img"}
                  src={
                    basicData?.gender === "male" ||
                    basicData?.gender === "" ||
                    basicData?.gender === null
                      ? coloured
                      : colouredFemale
                  }
                  sx={{
                    height: {
                      xs: "60px",
                      lg: "60px",
                    },
                    width: {
                      xs: "60px",
                      lg: "60px",
                    },
                  }}
                />
                <Typography
                  sx={{
                    color: "black",
                    fontSize: "14px",
                    fontWeight: theme.typography.Regular,
                  }}
                >
                  Coloured
                </Typography>

                {basicData?.skinz === 1 && (
                  <CheckCircle
                    sx={{
                      width: 25,
                      height: 25,
                      fontSize: 30,
                    }}
                    color="checkColor"
                  />
                )}
              </Box>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  cursor: "pointer",
                }}
                onClick={(event) => {
                  handleColor(event, 2);
                }}
              >
                <Box
                  component={"img"}
                  src={
                    basicData?.gender === "male" ||
                    basicData?.gender === "" ||
                    basicData?.gender === null
                      ? indian
                      : indianFemale
                  }
                  sx={{
                    height: {
                      xs: "60px",
                      lg: "60px",
                    },
                    width: {
                      xs: "60px",
                      lg: "60px",
                    },
                  }}
                />
                <Typography
                  sx={{
                    color: "black",
                    fontSize: "14px",
                    fontWeight: theme.typography.Regular,
                  }}
                >
                  Indian
                </Typography>

                {basicData?.skinz === 2 && (
                  <CheckCircle
                    sx={{
                      width: 25,
                      height: 25,
                      fontSize: 30,
                    }}
                    color="checkColor"
                  />
                )}
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  cursor: "pointer",
                }}
                onClick={(event) => {
                  handleColor(event, 3);
                }}
              >
                <Box
                  component={"img"}
                  src={
                    basicData?.gender === "male" ||
                    basicData?.gender === "" ||
                    basicData?.gender === null
                      ? black
                      : blackFemale
                  }
                  sx={{
                    height: {
                      xs: "60px",
                      lg: "60px",
                    },
                    width: {
                      xs: "60px",
                      lg: "60px",
                    },
                  }}
                />
                <Typography
                  sx={{
                    color: "black",
                    fontSize: "14px",
                    fontWeight: theme.typography.Regular,
                  }}
                >
                  Black
                </Typography>

                {basicData?.skinz === 3 && (
                  <CheckCircle
                    sx={{
                      width: 25,
                      height: 25,
                      fontSize: 30,
                    }}
                    color="checkColor"
                  />
                )}
              </Box>
            </Box>
          </Box>

          <Box
            sx={{
              display: "flex",
              gap: "18px",
              // width: { xs: "100%", lg: `calc(100% / 3)` },
              flex: 1,
              justifyContent: { xs: "center", tablet: "start" },
              marginBottom: { xs: "20px", tablet: "0px" },
              marginTop: { xs: "10px", tablet: "0px" },
              flexDirection: "column",
            }}
          >
            <Typography
              sx={{
                fontSize: "14px",
                fontWeight: theme.typography.Bold,
              }}
            >
              Preferred gender{" "}
              <span
                style={{
                  fontSize: "14px",
                  fontWeight: theme.typography.Regular,
                }}
              >
                (optional)
              </span>
            </Typography>
            <Paper
              elevation={3}
              sx={{
                width: "176px",
                height: "40px",
                boxShadow: "none",
                border: errors?.find((error) => error?.key == "gender")
                  ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
                  : "1px solid #E0E0E0",
                borderRadius: "10px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Button
                  startIcon={getMaleIcon}
                  variant="contained"
                  color={
                    basicData?.gender == "male"
                      ? "blueButton400"
                      : "whiteButton"
                  }
                  onClick={(event) => handleGender(event, "male")}
                  sx={{
                    height: "40px",
                    width: "85px",
                    fontWeight: theme.typography.Regular,
                    borderRadius: "10px",
                    fontSize: "14px",
                    color: basicData?.gender == "female" && theme.palette.black,
                    background:
                      basicData?.gender == "female" && theme.palette.base.main,
                    ":hover": {
                      boxShadow: "none",
                    },
                  }}
                >
                  {i18n["empMyProfile.male"]}
                </Button>
                <Button
                  startIcon={getFemaleIcon}
                  sx={{
                    height: "40px",
                    width: "85px",
                    fontWeight: theme.typography.Regular,
                    borderRadius: "10px",
                    fontSize: "14px",
                    color: basicData?.gender == "male" && theme.palette.black,
                    background:
                      basicData?.gender == "male" && theme.palette.base.main,
                    ":hover": {
                      boxShadow: "none",
                    },
                  }}
                  variant="contained"
                  color={
                    basicData?.gender == "female"
                      ? "purpleButton300"
                      : "grayBorder"
                  }
                  onClick={(event) => handleGender(event, "female")}
                >
                  {i18n["empMyProfile.female"]}
                </Button>
              </Box>
            </Paper>
            {errors && errors?.["gender"] && (
              <Typography style={{ color: "red" }}>
                {`*${errors?.["gender"]?.message}`}
              </Typography>
            )}
          </Box>
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Button
            variant="contained"
            color="tymeColor"
            sx={{
              width: "150px",
              height: "60px",
              fontSize: theme.typography.fontSize.sm,
              fontWeight: theme.typography.Bold,
              borderRadius: "10px 0 0 0",
              color: "white",
            }}
            startIcon={
              <Box
                component={"img"}
                src={previousIcon}
                sx={{
                  height: "25px",
                  width: "25px",
                }}
              />
            }
            onClick={() => saveBasic("jobtype")}
          >
            job type
          </Button>
          <Button
            variant="contained"
            color="saveAndExitButton"
            sx={{
              width: "150px",
              height: "60px",
              fontSize: theme.typography.fontSize.sm,
              fontWeight: theme.typography.Bold,
              borderRadius: "0",
            }}
            // onClick={() =>{ saveBasic("saveAndExit");handleOpenSaveAndExitDialog()}}
            onClick={() => {
              handleOpenSaveAndExitDialog();
            }}
          >
            save to drafts
          </Button>
          <Button
            variant="contained"
            color="detailButton"
            sx={{
              width: "150px",
              height: "60px",
              fontSize: "14px",
              fontWeight: theme.typography.Bold,
              borderRadius: "0 10px 0 0 ",
            }}
            endIcon={
              <Box
                component={"img"}
                src={nextIcon}
                sx={{
                  height: "25px",
                  width: "25px",
                }}
              />
            }
            onClick={() => {
              saveBasic("detail");
            }}
          >
            the details
          </Button>
        </Box>
      </Box>
      <input
        accept={fileAccept}
        ref={hiddenFileInput}
        type="file"
        onChange={handleFileChange}
        style={{ display: "none" }}
        required
      />
      <UploadSpec
        handleClose={() => setOpenSpecBox((prev) => !prev)}
        show={openSpecBox}
        // show={false}
        setOpenSpecBox={setOpenSpecBox}
        handleFileClick={handleFileClick}
        changeStep={changeStep}
        handleSaveButton={saveBasic}
      />
    </>
  );
}
