import React from "react";
import { Box, Typography } from "@mui/material";
import greenTriangle from "../../assets/Characters/Green_Triangle_Happy.svg";

export default function NoRecord() {
  return (
    <Box
      sx={{
        textAlign: "center",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        gap: 3,
        height: "200px",
        width: "300px",
        backgroundColor: "white",
        borderRadius: "25px",
        marginTop: "13px",
        boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
      }}
    >
      <Box
        component={"img"}
        src={greenTriangle}
        sx={{ width: 96, height: 96, mb: 0 }}
      />
      <Typography
        sx={{
          fontSize: "22px",
          fontWeight: 700,
          textAlign: "center",
        }}
      >
        No Record Found
      </Typography>
    </Box>
  );
}
