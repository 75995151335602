import React, { useState, useEffect } from "react";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";

import { ReactComponent as CloseBtn } from "../../../assets/HomePage/customSelectClose.svg";

const CustomSelect = ({
  variant,
  isLoading,
  onChange,
  index,
  placeholder,
  option,
  value,
  onMenuOpen,
  onCreateOption,
  inputId,
  name,
  onMenuClose,
  customStyle,
  onInputChange,
  isDisabled,
  multiSelectOptions,
  isMulti = "false",
  multiOnFocus,
  limitTags,
  initialOptions,
  isSearchable,
  filterOption,
  isClearable,
  placeholderFontSize,
}) => {
  const [localState, setLocalState] = useState([]);
  const [value1, setValue1] = useState("");
  const [menuOp, setMenuop] = useState(true);

  useEffect(() => {
    setLocalState(multiSelectOptions);
  }, [multiSelectOptions]);
  const colourStyles = {
    control: (styles) => ({ ...styles, backgroundColor: "white", fontSize: "14px" }),
    option: (styles, { isDisabled, isFocused, isSelected }) => ({
      ...styles,
      fontSize: "14px",
      color: isSelected ? "#fff" : "black",
      cursor: isDisabled ? "not-allowed" : "default",
      ":active": {
        ...styles[":active"],
      },
    }),
    multiValue: (styles) => ({
      ...styles,
      backgroundColor: "white",
      border: "1px solid #E0E0E0",
      borderRadius: "10px",
      fontSize: "14px",
    }),
    multiValueLabel: (styles) => ({
      ...styles,
      color: "black",
      fontSize: "14px",
    }),
    multiValueRemove: (styles) => ({
      ...styles,
      color: "rgba(0, 0, 0, 0.26)",
      fontSize: "14px",
    }),
    placeholder: (defaultStyles) => ({
      ...defaultStyles,
      fontSize: placeholderFontSize || "14px",
    }),
    singleValue: (defaultStyles) => ({
      ...defaultStyles,
      fontSize: "14px",
    }),
    input: (defaultStyles) => ({
      ...defaultStyles,
      fontSize: "14px",
    }),
    ...customStyle,
  };
  const CustomClearIndicator = (props) => {
    const {
      children = (
        <button
          id="customSelectcloseBtn"
          style={{
            background: "none",
            border: "none",
            outline: "none",
            width: "max-content",
            opacity: ".25",
          }}
        >
          <CloseBtn />
        </button>
      ),
      ...rest
    } = props;

    const handleClick = (e) => {
      props.clearValue();
    };

    return (
      <div {...rest} onClick={handleClick}>
        {children}
      </div>
    );
  };
  let renderComponent = (mode) => {
    switch (mode) {
      case "default":
        return (
          <Select
            isClearable={isClearable}
            isDisabled={isDisabled}
            inputId={inputId}
            name={name}
            onMenuClose={onMenuClose}
            filterOption={
              filterOption === "filter"
                ? undefined
                : filterOption === undefined
                ? () => true
                : filterOption
            }
            styles={{
              control: (baseStyles, state) => ({
                ...baseStyles,
                boxShadow: "none",
                fontSize: "14px",
              }),
              option: (styles, { data, isDisabled, isFocused, isSelected }) => {
                return {
                  ...styles,
                  color: isSelected ? "#fff" : "black",
                  fontSize: "14px",
                  ":active": {
                    ...styles[":active"],
                  },
                };
              },
              placeholder: (defaultStyles) => ({
                ...defaultStyles,
                fontSize: placeholderFontSize || "14px", 
              }),
              singleValue: (defaultStyles) => ({
                ...defaultStyles,
                fontSize: "14px", 
              }),
              input: (defaultStyles) => ({
                ...defaultStyles,
                fontSize: "14px", 
              }),
              ...customStyle,
            }}
            isLoading={isLoading}
            onChange={(newValue, triggeredAction) => {
              onChange(index, newValue, triggeredAction, inputId);
            }}
            options={option}
            placeholder={value1 || placeholder}
            value={value}
            onMenuOpen={() => onMenuOpen(index)}
            onInputChange={(e) => {
              onInputChange(e, inputId);
              setValue1((p) => (e ? e : p));
            }}
          />
        );
      case "createSelect":
        return (
          <CreatableSelect
            isSearchable={isSearchable}
            filterOption={
              filterOption === "filter"
                ? undefined
                : filterOption === undefined
                ? () => true
                : filterOption
            }
            inputId={inputId}
            name={name}
            onMenuClose={() => {
              setMenuop(false);
              onMenuClose();
            }}
            styles={{
              control: (baseStyles, state) => ({
                ...baseStyles,
                boxShadow: "none",
                fontSize: "14px",
              }),
              option: (styles, { data, isDisabled, isFocused, isSelected }) => {
                return {
                  ...styles,
                  fontSize: "14px",
                  color: isSelected ? "#fff" : "black",
                  ":active": {
                    ...styles[":active"],
                  },
                };
              },
              placeholder: (defaultStyles) => ({
                ...defaultStyles,
                fontSize: placeholderFontSize || "14px", // Use placeholderFontSize prop or fallback to 'inherit'
              }),
              singleValue: (defaultStyles) => ({
                ...defaultStyles,
                fontSize: "14px", 
              }),
              input: (defaultStyles) => ({
                ...defaultStyles,
                fontSize: "14px", 
              }),
              ...customStyle,
            }}
            isClearable
            isDisabled={isDisabled}
            isLoading={isLoading}
            onChange={(newValue, triggeredAction) => {
              onChange(index, newValue, triggeredAction, inputId);
              setValue1(newValue);
            }}
            onCreateOption={(data) => onCreateOption(data, inputId, index)}
            options={option}
            placeholder={value1 || placeholder}
            value={value}
            onMenuOpen={() => {
              onMenuOpen(index);
              setMenuop(true);
            }}
            onInputChange={(e) => {
              onInputChange(e, inputId);
              setValue1((p) => (e ? e : p));
            }}
          />
        );
      case "createMultiSelect":
        return (
          <CreatableSelect
            index={index}
            closeMenuOnSelect={false}
            isMulti={isMulti}
            options={localState}
            isLoading={isLoading}
            styles={colourStyles}
            inputId={inputId}
            name={name}
            value={value}
            isDisabled={isDisabled}
            placeholder={placeholder}
            onInputChange={(e) => {
              onInputChange(e, inputId);
              setValue1((p) => (e ? e : p));
            }}
            onChange={(newValue) => {
              onChange(inputId, newValue);
              setLocalState(initialOptions);
            }}
            isOptionDisabled={() => value?.length >= limitTags}
            onFocus={multiOnFocus}
            filterOption={
              filterOption === "filter"
                ? undefined
                : filterOption === undefined
                ? () => true
                : filterOption
            }
          />
        );
      case "multiSelect":
        return (
          <Select
            index={index}
            isLoading={isLoading}
            closeMenuOnSelect={false}
            isMulti={isMulti}
            options={localState}
            styles={colourStyles}
            inputId={inputId}
            name={name}
            isDisabled={isDisabled}
            value={value}
            placeholder={placeholder}
            onInputChange={(e) => {
              onInputChange(e, inputId);
              setValue1((p) => (e ? e : p));
            }}
            onChange={(newValue) => {
              onChange(inputId, newValue);
              setLocalState(initialOptions);
            }}
            isOptionDisabled={() => value?.length >= limitTags}
            onFocus={multiOnFocus}
            onMenuClose={onMenuClose}
            onMenuOpen={onMenuOpen}
            components={{ ClearIndicator: CustomClearIndicator }}
            filterOption={
              filterOption === "filter"
                ? undefined
                : filterOption === undefined
                ? () => true
                : filterOption
            }
          />
        );
      default:
        return null;
    }
  };
  return <>{renderComponent(variant)}</>;
};

export default CustomSelect;
