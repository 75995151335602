import { Box, IconButton, Popover, Typography } from "@mui/material";
import React, { useRef, useState } from "react";
import SVGButton from "../../../common/SVGButton";
// import playIcon from "../../../../assets/CircularIcon/Green/Circular Icons__Green_Play copy.svg";
import playIcon from "../../../../assets/JMD/Crayon_Cam.svg";
import ApplicationVideoIcon from "../../../../assets/JMD/Application_Video.svg";
import ApplicationVideoIconGrey from "../../../../assets/JMD/Application_Video_Grey.svg";

// import playIconGrey from "../../../../assets/CircularIcon/Grey/Circular_Icon_Grey_Play.svg";
import VideoIconGrey from "../../../../assets/JMD/VideoIconGrey.svg";
import playIconGrey from "../../../../assets/JMD/Crayon_Cam_Grey.svg";
import { useDispatch } from "react-redux";
import { setAlert } from "../../../../redux/configSlice";
import { ALERT_TYPE } from "../../../../utils/Constants";
import ReviewVideoDialog from "../../../common/ReviewVideoDialog";
import ApplicationVideoBox from "./ApplicationVideoBox";
import CloseIcon from "../../../common/CloseIcon";

export default function VideoDialog({
  handleOpen,
  camlink,
  applicationlink,
  userID,
  isScreeningInterview,
  link,
  crayonCam,
  applicationCamLink,
}) {
  const [openReviewVideoDialog, setOpenReviewVideoDialog] = useState(false);
  const [
    openApplicationReviewVideoDialog,
    setOpenApplicationReviewVideoDialog,
  ] = useState(false);
  const [viewVideoLink, setViewVideolink] = useState("");
  const dispatch = useDispatch();

  const [applicationVideo, setApplicationVideo] = useState(null);
  const anchorElApplicationVideo = Boolean(applicationVideo);
  const applicationVideoRef = React.useRef(null);
  const screeningInterviewVideoRef = useRef(null);

  const handleCloseDialog = () => {
    setViewVideolink("");
    setOpenReviewVideoDialog(false);
  };
  const handleCloseApplicationDialog = () => {
    setViewVideolink("");
    setOpenApplicationReviewVideoDialog(false);
  };
  const handlePlay = (link) => {
    setOpenApplicationReviewVideoDialog(false);
    if (link !== "No URL") {
      setOpenReviewVideoDialog(true);
      setViewVideolink(link);
    } else {
      // dispatch(
      //   setAlert({
      //     show: true,
      //     type: ALERT_TYPE?.ERROR,
      //     msg: "No video is provided by the candidate",
      //   })
      // );
    }
  };
  const handleApplicationPlay = (link) => {
    setOpenReviewVideoDialog(false);
    if (link !== "No URL") {
      setOpenApplicationReviewVideoDialog(true);
      setViewVideolink(link);
    } else {
      // dispatch(
      //   setAlert({
      //     show: true,
      //     type: ALERT_TYPE?.ERROR,
      //     msg: "No video is provided by the candidate",
      //   })
      // );
    }
  };
  return (
    <>
      <Box
        sx={{
          padding: "10px !important",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{
              fontWeight: 700,
              fontSize: "14px",
            }}
          >
            Video
          </Typography>
          <IconButton
            aria-label="close"
            onClick={() => {
              handleOpen();
            }}
            sx={{
              position: "absolute",
              top: 0,
              right: "5px",
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <Box
              sx={{
                width: "5px",
                height: "5px",
                color: "#000000",
                fontSize: "10px",
              }}
            >
              <CloseIcon />
            </Box>
          </IconButton>
        </Box>
        <Box
          sx={{
            display: "flex",
            pt: 1,
            cursor: camlink !== "No URL" ? "pointer" : "default",
          }}
          onClick={() => camlink !== "No URL" && handlePlay(camlink)}
        >
          <SVGButton
            color={"white"}
            source={camlink !== "No URL" ? playIcon : playIconGrey}
            height={35}
            width={35}
            startIconMargin={"0px !important"}
            padding={"0px !important"}
            cursor={camlink !== "No URL" ? "pointer" : "default"}
          />
          <Typography
            sx={{
              fontWeight: 700,
              fontSize: "14px",
              marginLeft: "16px !important",
              margin: "auto",
            }}
          >
            Crayon Cam
          </Typography>
        </Box>
        <Box
          ref={applicationVideoRef}
          sx={{
            display: "flex",
            pt: 1,
            cursor: "pointer",
          }}
          onClick={(event) => {
            // applicationCamLink !== "No URL"&& handleApplicationPlay(applicationVideoRef?.current);
            applicationCamLink !== "No URL" &&
              handleApplicationPlay(applicationCamLink);
          }}
        >
          <SVGButton
            color={"white"}
            source={
              applicationCamLink !== "No URL"
                ? ApplicationVideoIcon
                : ApplicationVideoIconGrey
            }
            height={35}
            width={35}
            startIconMargin={"0px !important"}
            padding={"0px !important"}
            cursor={applicationCamLink !== "No URL" ? "pointer" : "default"}
          />
          <Typography
            sx={{
              fontWeight: 700,
              marginLeft: "16px !important",
              margin: "auto",
              fontSize: "14px",
            }}
          >
            Application Video
          </Typography>
        </Box>
        {isScreeningInterview && (
          <Box
            ref={screeningInterviewVideoRef}
            sx={{
              display: "flex",
              pt: 1,
              cursor: "pointer",
            }}
            onClick={(event) => {
              //  setApplicationVideo(applicationVideoRef?.current);
            }}
          >
            <SVGButton
              color={"white"}
              source={VideoIconGrey}
              height={35}
              width={35}
              startIconMargin={"0px !important"}
              padding={"0px !important"}
              cursor={"default"}
            />
            <Typography
              sx={{
                fontWeight: 700,
                marginLeft: "16px !important",
                margin: "auto",
                fontSize: "14px",
              }}
            >
              Screening Interview
            </Typography>
          </Box>
        )}

        <Popover
          open={anchorElApplicationVideo}
          anchorEl={applicationVideo}
          onClose={() => setApplicationVideo(null)}
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          sx={{
            "& .MuiPopover-paper": {
              borderRadius: "25px !important",
              width: "350px !important",
            },
          }}
        >
          <ApplicationVideoBox
            userID={userID}
            setViewVideoLink={setViewVideolink}
            viewVideoLink={viewVideoLink}
            setOpenReviewVideoDialog={setOpenReviewVideoDialog}
          />
        </Popover>
      </Box>
      {openApplicationReviewVideoDialog && (
        <ReviewVideoDialog
          videoLink={applicationCamLink}
          open={openApplicationReviewVideoDialog}
          setOpen={handleCloseApplicationDialog}
        />
      )}
      {openReviewVideoDialog && (
        <ReviewVideoDialog
          videoLink={crayonCam}
          open={openReviewVideoDialog}
          setOpen={handleCloseDialog}
        />
      )}
    </>
  );
}
