import { Box, Button, Typography } from "@mui/material";
import React from "react";
import Icon403 from "../../assets/Footer/403.svg";
import { useTheme } from "@emotion/react";
import useViewport from "../common/ViewPort";
import { useNavigate } from "react-router-dom";
const ForbiddenPage = () => {
  const theme = useTheme();
  const { width } = useViewport();
  const navigate = useNavigate();
  const handleGoBackHome = () => {
    navigate("/");
  };
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        borderRadius: "25px",
        height: { xs: "87%", md24: "98%", lg: "98%" },
      }}
    >
      <Box
        item
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: `${width >= 1024 && "10px"}`,
          position: `${width <= 1024 ? "absolute" : "relative"}`,
          // width: leftExpanded
          //   ? { xs: "150px", mdl: "123px", mlg: "150px", xl: "150px" }
          //   : "30px",
          // width: leftExpanded ? "125" : "30px",
          // minWidth: leftExpanded ? "125px" : "30px",
          width: "125px",
          minWidth: "125px",
          zIndex: { xs: "99999", tablet: "30" },
        }}
      ></Box>
      <Box
        sx={{
          display: "flex",
          flexGrow: 1,
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          //   width: `calc(100vw - 255px)`,
          position: "sticky",
          background: "#fff",
          marginTop: "55px",
          marginX: "30px",
          borderRadius: "25px",
          marginBottom: "30px",
        }}
      >
        <Box
          component="img"
          src={Icon403}
          sx={{ width: "585px", height: "343px" }}
        ></Box>

        <Typography
          sx={{
            fontWeight: theme.typography.Regular,
            fontSize: "14px",
            marginBottom: "20px",
            marginTop: "20px",
          }}
        >
          (403 access - forbidden access)
        </Typography>
        <Typography
          sx={{
            fontWeight: theme.typography.Bold,
            fontSize: "20px",
            marginBottom: "20px",
          }}
        >
          VIP members only
        </Typography>
        <Typography
          sx={{
            fontWeight: theme.typography.Regular,
            fontSize: "14px",
            marginBottom: "20px",
          }}
        >
          Unlucky, you cannot access this page without a pass.
        </Typography>
        <Button
          color="nandos"
          variant="contained"
          sx={{
            borderRadius: "10px 10px 0 0",
            width: "150px",
            maxWidth: "150px",
            minWidth: "150px",
            height: "60px",
            minHeight: "60px",
            position: "absolute",
            bottom: "0",
            left: "50%",
            transform: "translateX(-50%)",
            maxHeight: "60px",
          }}
          onClick={handleGoBackHome}
        >
          go back home
        </Button>
      </Box>

      <Box
        item
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: `${width >= 1024 && "10px"}`,
          direction: "rtl",
          position: `${width <= 1024 ? "absolute" : "relative"}`,
          zIndex: { xs: "99999", tablet: "30" },
          right: "0",
          width: "125px",
          minWidth: "125px",
        }}
      ></Box>
    </Box>
  );
};

export default ForbiddenPage;
