import React from "react";
import { useTheme } from "@emotion/react";
import { Box, Button, Modal, Typography } from "@mui/material";
import loginIcon from "../../../assets/Characters/CrayBotIcons/Crayon Craybot Notification-06.svg";

const LandingShareingModal = ({
  handleOpen,
  handleClose,
  handleLogin,
  handleLetsGo,
}) => {
  const theme = useTheme();

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "360px",
    height: "500px",
    textAlign:"center",
    display:"flex",
    // justifyContent:"center",
    bgcolor: "background.paper",
    borderRadius: "25px",
    boxShadow: "none !important",
    flexDirection:"column",
    outlineColor: "transparent !important",
    outline: "none",
    alignItems: "center",
    "&:focus-visible": {
      outline: "none",
    },
  };
  return (
    <Modal open={handleOpen} onClose={handleClose}>
      <Box sx={style}>
        <Box sx={{ maxWidth: "260px" }}>
        <Box
            component={"img"}
            src={loginIcon}
            sx={{ width: 76, height: 116, objectFit: "cover", margin: "auto" , marginTop:"33px",marginBottom:"20px"}}
          />
          <Typography
            sx={{
              maxWidth: "260px",
              fontSize: "20px",
              lineHeight: "19px",
              fontWeight: theme.typography.Bold,
             
            }}
          >
            You’ve landed on Crayon, Africa’s most-loved talent platform!
          </Typography>
          <Box>
            <Typography
              sx={{
                maxWidth: "260px",
                fontSize: "14px",
                fontWeight: theme.typography.Bold,
                marginTop: "35px",
                marginBottom: "20px",
               
              }}
            >
              Check out and apply for this job, or feel free to take a look
              around and explore the full experience.
            </Typography>
          </Box>
          <Box>
            <Typography
              sx={{
                maxWidth: "260px",
                fontSize: "14px",
                fontWeight: theme.typography.Regular,
                marginBottom:"40px"
               
              }}
            >
              If you’re already with us, welcome back! We appreciate your
              support, and please keep spreading the word!
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            width: "100%",
          }}
        >
          <Button
            variant="contained"
            color="grayButton100"
            sx={{
              borderRadius: 0,
              width: "50%",
              height: "47px",
              borderBottomLeftRadius: 25,
            }}
            onClick={handleLogin}
          >
            login
          </Button>
          <Button
            variant="contained"
            color="addQuestionButton"
            sx={{
              borderRadius: 0,
              width: "50%",
              height: "47px",
              borderBottomRightRadius: 25,
            }}
            onClick={handleLetsGo}
          >
            let's go!
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default LandingShareingModal;
