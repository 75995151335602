import React, { useEffect, useRef, useState } from "react";
import ButtonPanel from "../../common/ButtonPanel";
import LeftArrow from "../../common/LeftArrow";
import RightArrow from "../../common/RightArrow";
import { Box, Button, Grid } from "@mui/material";
import { ALERT_TYPE, MY_REFERRALS_LEFT_FILTER } from "../../../utils/Constants";
import NoJobs from "../NoJobs";
import InfiniteScroll from "react-infinite-scroll-component";
import { calculatedHeight } from "../../../utils/Common";
import ReferralsCard from "./ReferralsCard";
import { getPromoterReferrals } from "../../../redux/candidate/candidateJobs";
import { useDispatch } from "react-redux";
import { setAlert } from "../../../redux/configSlice";
import useViewport from "../../common/ViewPort";
import {
  setCardsPadding,
  setFilterExpanded,
} from "../../../redux/guest/jobsSlice";
import { useSelector } from "react-redux";

export default function Referrals() {
  const gridRef = useRef();
  const { width } = useViewport();
  const dispatch = useDispatch();
  const filterExpanded = useSelector(
    (state) => state?.configjobslice?.filterExpanded
  );
  const [lastKey, setLastKey] = useState(0);
  const selectedAllReferrals = useRef([]);
  const [showBox, setShowBox] = useState(false);
  const [referralsJobs, setReferralsJobs] = useState([]);
  const [newPadding, setNewPadding] = useState(15);
  const [newCardWidth, setNewCardWidth] = useState(340);
  const [leftExpanded, setLeftExpand] = useState(true);
  const [itemsFetched, setItemsFetched] = useState(false);
  const [currentPageJobsCount, setCurrentPageJobsCount] = useState(0);
  function calculateCardWidthAndPadding(totalWidth) {
    let cardWidth = 340;
    let padding = 15;
    let numCards = Math.floor(totalWidth / (cardWidth + padding));
    let remainingSpace =
      totalWidth - (numCards * cardWidth + padding * (numCards + 1));

    while (remainingSpace > 0) {
      if (remainingSpace < cardWidth + padding) {
        if (padding < 30) {
          padding++;
          remainingSpace =
            totalWidth - (numCards * cardWidth + padding * (numCards + 1));
        } else {
          break;
        }
      } else {
        numCards++;
      }
    }

    cardWidth = (totalWidth - padding * (numCards + 1)) / numCards;
    if (cardWidth > 380) {
      cardWidth = 380;
    }
    remainingSpace =
      totalWidth - (numCards * cardWidth + padding * (numCards + 1));

    padding = padding + remainingSpace / (numCards + 1);

    remainingSpace =
      totalWidth - (numCards * cardWidth + padding * (numCards + 1));

    setNewCardWidth(cardWidth - 1);
    setNewPadding(padding);
    dispatch(setCardsPadding(padding));
    return { numCards, cardWidth, padding, remainingSpace };
  }
  const getNewWidth = () => {
    if (gridRef.current) {
      calculateCardWidthAndPadding(gridRef.current.clientWidth);
    }
  };
  useEffect(() => {
    if (gridRef.current) {
      calculateCardWidthAndPadding(gridRef.current.clientWidth);
    }
    window.addEventListener("resize", getNewWidth);
    return () => {
      window.removeEventListener("resize", getNewWidth);
    };
  }, [itemsFetched, leftExpanded]);

  const getJobList = async (lastkey) => {
    try {
      const { payload } = await dispatch(
        getPromoterReferrals({ lastKey: lastkey })
      );
      if (payload?.status === "success") {
        if (payload?.data?.length === 0) {
          setCurrentPageJobsCount(payload?.data?.length);
          setShowBox(true);
        } else {
          if (payload?.pageNumber === 0) {
            setCurrentPageJobsCount(payload?.data?.length);
            setShowBox(false);
            setLastKey(payload?.pageNumber + 1);
            setReferralsJobs(payload?.data);
            setItemsFetched(true);
          } else {
            setCurrentPageJobsCount(payload?.data?.length);
            setShowBox(false);
            setLastKey(payload?.pageNumber + 1);
            setReferralsJobs((prevState) => [...prevState, ...payload?.data]);
            setItemsFetched(true);
          }
        }
      }
    } catch (error) {
      dispatch(
        setAlert({
          show: true,
          type: ALERT_TYPE?.ERROR,
          msg: "Something went wrong! please relaod the window",
        })
      );
    }
  };

  useEffect(() => {
    getJobList(0);
  }, []);
  useEffect(() => {
    if (width > 1024) {
      setLeftExpand(true);
    } else {
      setLeftExpand(false);
    }
  }, [width]);
  if (width === 0) {
    return null;
  }
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <Box
        item
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: `${width >= 768 && "10px"}`,
          position: `${width < 768 ? "absolute" : "relative"}`,
          // width: leftExpanded
          //   ? { xs: "150px", mdl: "123px", mlg: "150px", xl: "150px" }
          //   : "30px",
          width: leftExpanded ? "125px" : "30px",
          minWidth: leftExpanded ? "125px" : "30px",
          // width: leftExpanded && "150px",
          zIndex: "30",
        }}
      >
        <Button
          variant="contained"
          sx={{
            boxShadow: "0px 5px 10px #00000029 !important",
            position: "static",
            borderRadius: "0 10px 10px 0",
            minWidth: "30px",
            width: "30px",
            height: "45px",
          }}
          color="filterButtons"
          onClick={() => {
            setLeftExpand((prevState) => !prevState);
            dispatch(setFilterExpanded(!filterExpanded));
          }}
        >
          {leftExpanded ? <LeftArrow /> : <RightArrow />}
        </Button>
        {leftExpanded && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              height: {
                xs: `calc(100vh - 95px)`,
                sm: `calc(100vh - 120px )`,
                md: `calc(100vh - 120px)`,
                lg: `calc(100vh - 135px)`,
                xl: `calc(100vh - 155px)`,
                xxl: "calc(100vh - 175px)",
              },
              width: `${width < 768 && "135px"}`,
              paddingTop: `${width < 768 && "10px"}`,
              background: `${width > 0 && width < 768 && "#EBECF3"}`,
              boxShadow: `${
                width > 0 && width < 768 && "0px 5px 10px #00000029"
              }`,
              opacity: `${width < 768 && "1"}`,
              borderRadius: `${width < 768 && "10px"}`,
              overflowX: "hidden",
              overflowY: leftExpanded ? "scroll" : "unset",
            }}
          >
            <Box
              sx={{
                // maxWidth: `${width < 768 && "150px"}`,
                maxWidth: "125px",
              }}
            >
              <ButtonPanel
                selected={selectedAllReferrals?.current}
                panelData={MY_REFERRALS_LEFT_FILTER}
                side="left"
                color={"filterButtons"}
              />
            </Box>
          </Box>
        )}
      </Box>

      <Box
        item
        sx={{
          display: "flex",
          flexGrow: 1,
          alignItems: "center",
          flexDirection: "column",
          width: `calc(100vw - 255px)`,
          position: "sticky",
        }}
        ref={gridRef}
        className="grigBox"
      >
        <InfiniteScroll
          key={""}
          scrollThreshold={"100px"}
          height={calculatedHeight()}
          dataLength={referralsJobs?.length}
          next={() => getJobList(lastKey)}
          hasMore={true}
          style={{
            overflowX: "hidden",
          }}
          endMessage={
            <p style={{ textAlign: "center" }}>
              <b>Yay! You have seen it all</b>
            </p>
          }
        >
          <Grid
            container
            // gap={`40px ${newPadding}px`}
            gap={width < 768 ? "10px" : `${newPadding}px`}
            columns={{
              xs: 1,
              md: 1,
              lg: 3,
              mlg: 3.5,
              xl: 4,
              xxl: 6,
              xxxl: 8,
            }}
            justifyContent={width < 768 && "center"}
            sx={{
              position: "relative",
              padding: width < 768 ? "10px 10px" : `0 ${newPadding}px`,
              top: { xs: "45px", tablet: "55px" },
            }}
          >
            {referralsJobs?.length > 0
              ? referralsJobs?.map((talent, index) => (
                  <Box
                    sx={{
                      width: newCardWidth,
                      height: "560px",
                    }}
                  >
                    <ReferralsCard
                      cardWidth={newCardWidth}
                      index={index}
                      job={talent}
                      getJobs={getJobList}
                      jobslength={referralsJobs?.length}
                      currentPageJobsCount={currentPageJobsCount}
                    />
                  </Box>
                ))
              : showBox && (
                  <Box
                    sx={{
                      margin: "auto",
                    }}
                  >
                    <NoJobs
                      text1={"It doesn't look like you've"}
                      text2={"any referrals yet."}
                    />
                  </Box>
                )}
          </Grid>
          <style>
            {`.infinite-scroll-component::-webkit-scrollbar {
                display:none
              }
              .infinite-scroll-component__outerdiv {
                width: 100%
              }`}
          </style>
        </InfiniteScroll>
      </Box>

      <Box
        item
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: `${width >= 768 && "10px"}`,
          direction: "rtl",
          position: `${width < 768 ? "absolute" : "relative"}`,
          zIndex: "30",
          // width: leftExpanded && "150px",
          right: "0",
          // width: leftExpanded
          //   ? { xs: "150px", mdl: "123px", mlg: "150px", xl: "150px" }
          //   : "30px",
          width: leftExpanded ? "125px" : "30px",
          minWidth: leftExpanded ? "125px" : "30px",
        }}
      ></Box>
    </Box>
  );
}
