import Box from "@mui/material/Box";
import TopBar from "../components/common/TopBar";
import { useEffect, useRef, useState } from "react";
import { useNavigate, Outlet, useLocation } from "react-router-dom";
import Snackbar from "../components/common/SnackBar";
import Backdrop from "../components/common/Backdrop";
import upArrow from "../assets/Black_Up_Close.svg";
import downArrowWhite from "../assets/Black_Down_Open.svg";

import { ThemeProvider } from "@emotion/react";
import {
  topBarChallengerTheme,
  topBarCharacterTheme,
  topBarCollaboratorTheme,
  topBarContemplatorTheme,
  topBarTheme,
} from "../utils/Theme";
import jwt_decode from "jwt-decode";
import SmallButton from "../components/common/SmallButton";
import { useTheme } from "@mui/material";
import LabTabs from "./FooterTabs";
import { useSelector } from "react-redux";

export default function Main() {
  const navigate = useNavigate();
  let { pathname } = useLocation();
  const theme = useTheme();
  const [showFooter, setShowFooter] = useState(false);
  const footerRef = useRef();
  const footerBtnRef = useRef();
  const token = localStorage?.getItem("token");
  let decodedToken;
  if (token) {
    decodedToken = jwt_decode(token);
  }
  const { leftMenuButton } = useSelector((state) => state.adminSlice);

  const [topBarDynamicTheme, setTopBarDynamicTheme] = useState(topBarTheme);
  useEffect(() => {
    if (pathname === "/") navigate("/", { replace: true });
  }, []);

  const handleCandidateDynamicTheme = (value) => {
    if (value === 1) {
      setTopBarDynamicTheme(topBarChallengerTheme);
    } else if (value === 2) {
      setTopBarDynamicTheme(topBarCharacterTheme);
    } else if (value === 3) {
      setTopBarDynamicTheme(topBarContemplatorTheme);
    } else if (value === 4) {
      setTopBarDynamicTheme(topBarCollaboratorTheme);
    } else {
      setTopBarDynamicTheme(topBarTheme);
    }
  };

  useEffect(() => {
    handleCandidateDynamicTheme(decodedToken?.data?.personality);
  }, []);

  const handleClickOutside = (event) => {
    if (
      footerRef.current &&
      !footerRef.current.contains(event.target) &&
      !footerBtnRef.current.contains(event.target)
    ) {
      setShowFooter(false);
    }
  };

  useEffect(() => {
    if (showFooter) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showFooter]);
  useEffect(() => {
    if (leftMenuButton) {
      setShowFooter(false);
    }
  }, [leftMenuButton]);

  return (
    <>
      {/* <Snackbar /> */}
      <Backdrop />
        <ThemeProvider theme={topBarDynamicTheme}>
          <TopBar handleCandidateDynamicTheme={handleCandidateDynamicTheme} />
        </ThemeProvider>
      <Box
        component="main"
        sx={{
          height: {
            xs: `calc(100vh - 60px)`,
            sm: `calc(100vh - 60px )`,
            md: `calc(100vh - 90px)`,
            mdl: `calc(100vh - 90px)`,
            lg: `calc(100vh - 80px)`,
            mlg: `calc(100vh - 80px)`,
            xl: `calc(100vh - 100px)`,
            xxl: "calc(100vh - 120px)",
          },
          // position: "relative",
        }}
      >
        <Outlet />
          <>
            <Box
              ref={footerBtnRef}
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "flex-end",
                position: "fixed",
                bottom: "0",
                zIndex: leftMenuButton ? 999 : 999999999999,
              }}
              height={
                {
                  // xs: `calc(100vh - 60px)`,
                  // sm: `calc(100vh - 60px )`,
                  // md: `calc(100vh - 90px)`,
                  // mdl: `calc(100vh - 90px)`,
                  // lg: `calc(100vh - 80px)`, 
                  // mlg: `calc(100vh - 80px)`,
                  // xl: `calc(100vh - 100px)`,
                  // xxl: "calc(100vh - 120px)",
                }
              }
            >
              <SmallButton
                backgroundColor={theme.homepage.footerButton.main}
                startIcon={
                  <Box
                    component="img"
                    sx={{
                      height: 30,
                      width: 30,
                    }}
                    alt="Down arrow"
                    src={showFooter ? downArrowWhite : upArrow}
                  />
                }
                onClick={() => {
                  setShowFooter((prev) => !prev);
                }}
                startIconMargin="0px !important"
                marginTop="0px !important"
                padding="0px !important"
                height={{ xs: 30, tablet: 25 }}
                width={{ xs: 60, tablet: 100 }}
                fontWeight={700}
                borderRadius="10px 10px 0px 0px"
              />
            </Box>
            {showFooter && (
              <Box
                ref={footerRef}
                sx={{
                  position: "fixed",
                  bottom: "-43px",
                  // minHeight: "70vh",
                  height:"70vh",
                  // maxHeight:"660px",
                  minHeight:{xs360:"100px",lgm:"660px"},
                  maxHeight:{xs360:"660px",lgm:"100%"},
                  // minHeight:"670px",
                  // maxHeight:"670px",
                  width: "100%",
                  left: "0",
                  zIndex: leftMenuButton ? 998 : "99999999",
                }}
              >
                <LabTabs />
              </Box>
            )}
          </>
      </Box>
    </>
  );
}
