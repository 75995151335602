import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { postApi, getApi } from "../../utils/Apis";
import { setLoading } from "../configSlice";
const initialState = {
  sortOption: "",
  prevSortOption: "",
  sortColumn: "",
};
export const getManage = createAsyncThunk(
  "getManage",
  async (payload, { dispatch }) => {
    const { data } = await getApi(
      `/admin/employer/jobstatus?job_id=${payload}`,
      true
    );
    return data;
  }
);

export const getQandA = createAsyncThunk(
  "getQandA",
  async ({ job_id, user_id }, { dispatch }) => {
    const { data } = await getApi(
      `/admin/employer/questionanswer?job_id=${job_id}&user_id=${user_id}`,
      true
    );
    return data;
  }
);

export const deleteEmployerUploadedVideo = createAsyncThunk(
  "deleteEmployerUploadedVideo",
  async ({ company_id }, { dispatch }) => {
    dispatch(setLoading(true));
    const { data } = await getApi(
      `/employer/deleteEmployercamvideo?company_id=${company_id}`,
      true
    );
    dispatch(setLoading(false));
    return data;
  }
);

export const uploadEmployerMyCamVideo = createAsyncThunk(
  "uploadEmployerMyCamVideo",
  async (payload, { dispatch }) => {
    dispatch(setLoading(true));
    const { data } = await postApi(
      "/upload/companycam",
      payload,
      true,
      "multipart/form-data"
    );
    dispatch(setLoading(false));
    return data;
  }
);

export const getEmployerVideoList = createAsyncThunk(
  "getEmployerVideoList",
  async ({ lastKey }, { dispatch }) => {
    dispatch(setLoading(true));
    const { data } = await getApi(
      `/employer/getEmployerJobVideos?lastKey=${lastKey}`,
      true
    );
    dispatch(setLoading(false));
    return data;
  }
);

export const getEmployerMyCamVideo = createAsyncThunk(
  "getEmployerMyCamVideo",
  async (payload, { dispatch }) => {
    dispatch(setLoading(true));

    const { data } = await getApi(`/employer/getCompanyVideoUrl`, true);
    dispatch(setLoading(false));

    return data;
  }
);

export const runMatch = createAsyncThunk(
  "runMatch",
  async ({ job_id }, { dispatch }) => {
    dispatch(setLoading(true));

    const { data } = await getApi(`/job/testmatch?job_id=${job_id}`, true);
    dispatch(setLoading(false));

    return data;
  }
);

export const runIndividualMatch = createAsyncThunk(
  "runIndividualMatch",
  async ({ job_id, user_id }, { dispatch }) => {
    dispatch(setLoading(true));

    const { data } = await getApi(
      `/job/userjobmatching?job_id=${job_id}&user_id=${user_id}`,
      true
    );
    dispatch(setLoading(false));

    return data;
  }
);

export const getCandidateCV = createAsyncThunk(
  "getCandidateCV",
  async ({ user_id }, { dispatch }) => {
    const { data } = await getApi(
      user_id === "" ? `/cv/getcvpage` : `/cv/getcvpage?user_id=${user_id}`,
      true
    );
    return data;
  }
);

export const statusChange = createAsyncThunk(
  "statusChange",
  async (payload, { dispatch }) => {
    dispatch(setLoading(true));
    const { data } = await postApi("/admin/updatestatusjob", payload, true);
    dispatch(setLoading(false));
    return data;
  }
);
export const duplicateThejob = createAsyncThunk(
  "duplicateThejob",
  async (payload, { dispatch }) => {
    dispatch(setLoading(true));
    const { data } = await postApi("/admin/duplicatejob", payload, true);
    dispatch(setLoading(false));
    return data;
  }
);
export const getTalentJobStatusCount = createAsyncThunk(
  "getTalentJobStatusCount",
  async (payload, { dispatch }) => {
    const { data } = await postApi(
      `/admin/employer/talentstatuscount`,
      payload,
      true
    );
    return data;
  }
);
export const updateCamLink = createAsyncThunk(
  "updateCamLink",
  async (payload, { dispatch }) => {
    const { data } = await postApi(
      `/employer/updatecamlink`,
      payload,
      true
    );
    return data;
  }
);

export const adminInterview = createAsyncThunk(
  "adminInterview",
  async (payload, { dispatch }) => {
    dispatch(setLoading(true));
    const { data } = await postApi(`/admin/interviews`, payload, true);
    dispatch(setLoading(false));
    return data;
  }
);
export const updateCalendarLink = createAsyncThunk(
  "updateCalendarLink",
  async (payload, { dispatch }) => {
    dispatch(setLoading(true));
    const { data } = await postApi(`/admin/editcalendarlink`, payload, true);
    dispatch(setLoading(false));
    return data;
  }
);
export const getTalentJobStatusApplications = createAsyncThunk(
  "getTalentJobStatusApplications",
  async ({ pageNumber, payload }, { dispatch }) => {
    // dispatch(setLoading(true));
    const { data } = await postApi(
      "/admin/employer/getnewTalent?lastKey=" + pageNumber,
      payload,
      true
    );
    // dispatch(setLoading(false));
    return data;
  }
);

export const exportCSV = createAsyncThunk(
  "exportCSV",
  async ({ job_id }, { dispatch }) => {
    dispatch(setLoading(true));
    const { data } = await getApi(`/admin/exportcsv?job_id=${job_id}`, true,{responseType:"blob"});
    dispatch(setLoading(false));
    return data;
  }
);

export const exportAssessment = createAsyncThunk(
  "exportAssessment",
  async (payload, { dispatch }) => {
    dispatch(setLoading(true));
    const { data } = await getApi(`/exportassessmentdata?start_date=${payload?.start_date}&end_date=${payload?.end_date}`, true,{responseType:"blob"});
    dispatch(setLoading(false));
    return data;
  }
);

export const exportProfilePDF = createAsyncThunk(
  "exportCSV",
  async ({ job_id }, { dispatch }) => {
    // dispatch(setLoading(true));
    const { data } = await getApi(`/admin/exportpdf?job_id=${job_id}`, true,{responseType:"blob"});
    // dispatch(setLoading(false));
    return data;
  }
);

export const getTalentDetails = createAsyncThunk(
  "getTalentDetails",
  async ({ talent_id, job_id, payload }, { dispatch }) => {
    // dispatch(setLoading(true));
    const { data } = await getApi(
      `/admin/employer/getcandidatedetails?talent_id=${talent_id}&job_id=${job_id}`,
      payload,
      true
    );
    // dispatch(setLoading(false));
    return data;
  }
);

export const getTalentMatchData = createAsyncThunk(
  "getTalentMatchData",
  async ({ pageNumber, payload }, { dispatch }) => {
    const { data } = await postApi(
      "/admin/employer/getNewTalentMatched?lastKey=" + pageNumber,
      payload,
      true
    );
    return data;
  }
);

export const candidateViewed = createAsyncThunk(
  "candidateViewed",
  async (payload, { dispatch }) => {
    // dispatch(setLoading(true));
    const { data } = await postApi(
      `/admin/employer/editViewStatus`,
      payload,
      true
    );
    // dispatch(setLoading(false));
    return data;
  }
);
export const changeJobApplicationStatus = createAsyncThunk(
  "changeApplicationStatus",
  async (payload, { dispatch }) => {
    // dispatch(setLoading(true));
    const { data } = await postApi(
      `/admin/employer/statusChange`,
      payload,
      true
    );
    // dispatch(setLoading(false));
    return data;
  }
);
export const getMyJobTeamMemebers = createAsyncThunk(
  "getMyJobTeamMembers",
  async (payload, { dispatch }) => {
    dispatch(setLoading(true));
    const { data } = await postApi(`/admin/employer/getTeams`, payload, true);
    dispatch(setLoading(false));
    return data;
  }
);

export const myJobsSlice = createSlice({
  name: "myjobs",
  initialState,
  reducers: {
    setSortingOption: (state, action) => {
      state.sortOption = action?.payload;
    },
    setPreviosSortOption: (state, action) => {
      state.prevSortOption = action?.payload;
    },
    setSortColumn: (state, action) => {
      state.sortColumn = action?.payload;
    },
  },
  // extraReducers(builder) {
  //     builder
  //         .addCase(signup.pending, (state, action) => {
  //             // state.status = 'loading'
  //         })
  //         .addCase(signup.fulfilled, (state, action) => {
  //             // state.status = 'succeeded'
  //         })
  //         .addCase(signup.rejected, (state, action) => {
  //             // state.status = 'failed'
  //         })
  // }
});
// Action creators are generated for each case reducer function
export const { setSortingOption, setSortColumn, setPreviosSortOption } =
  myJobsSlice.actions;

export default myJobsSlice.reducer;
