import { useState, useRef, useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import locale from "../../../i18n/locale";
import {
  uploadCompanyLogo,
  uploadProfilePic,
} from "../../../redux/candidate/myProfileSlice";
import { setAlert, setLoading } from "../../../redux/configSlice";

import LeftArrow from "../../common/LeftArrow";
import RightArrow from "../../common/RightArrow";
import {
  ALERT_TYPE,
  COMP_INFO,
  ERROR_MSG,
  PROFILE_INFO,
  RECRUITER_MYPROFILE_LEFTPANEL_BUTTONS,
} from "../../../utils/Constants";
import { useTheme } from "@emotion/react";
import getCroppedImg from "../../../utils/cropImage";
import Info from "../../common/myProfileSection/Info.js";
import CompanyInfo from "../../common/myProfileSection/CompanyInfo.js";
import {
  getEmpProfile,
  getCompanies,
  getEmpBadges,
  setProfileSteps,
} from "../../../redux/employer/empProfileSlice";

import { Dialog, Divider, Grid, IconButton, Paper } from "@mui/material";
import ButtonPanel from "../../common/myProfileSection/ButtonPanel.js";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import OpenCameraDialog from "../../common/ProfilePicCaptureDialog";
import { nanoid } from "@reduxjs/toolkit";
import ComingSoon from "../../common/ComingSoon";
import grey_profile_collaborator from "../../../assets/Personality Logos/crayon_personality_grey_collaborator.svg";
import grey_profile_contemplator from "../../../assets/Personality Logos/crayon_personality_grey_contemplator.svg";
import grey_profile_challenger from "../../../assets/Personality Logos/crayon_personality_grey_challenger.svg";
import grey_profile_character from "../../../assets/Personality Logos/crayon_personality_grey_character.svg";

import { getProfilePercentage } from "../../../redux/employer/empJobListing";
import AddCompany from "../../common/myProfileSection/AddCompany.js";
import { getIndustries } from "../../../redux/candidate/myCvSlice.js";
import Badges from "../common/Badges.js";
import CropModal from "../../employer/myProfile/cropModal.js";
import SubscriptionPopModal from "../../guest/talent/SubscriptionPopModal.js";
import { getDecodedToken, getToken } from "../../../utils/Common.js";
import { getCountry } from "../../../redux/employer/postJobSlice.js";
import MyPlans from "../../common/myProfileSection/MyPlan/MyPlans.js";
import useViewport from "../../common/ViewPort.js";
import EmployerProfileCardRow from "../../employer/myProfile/EmployerProfileCardRow.js";
import { setFilterExpanded } from "../../../redux/guest/jobsSlice.js";
import BillingComponent from "../../common/myProfileSection/MyPlan/BillingComponent.js";

export default function RecruiterProfileCard() {
  const i18n = locale.en;
  const theme = useTheme();
  const dispatch = useDispatch();
  const hiddenFileInput = useRef(null);
  const navigate = useNavigate();
  const { width } = useViewport();
  const profileSteps = useSelector((state) => state?.myProfile?.profileSteps);
  const filterExpanded = useSelector(
    (state) => state?.configjobslice?.filterExpanded
  );
  const { industries } = useSelector((state) => state?.myCv);
  const { companies } = useSelector((state) => state?.myProfile);
  const { country, town } = useSelector((state) => state?.postJobs);

  const decodedToken = getDecodedToken(getToken());

  const [rightExpanded, setRightExpanded] = useState(true);
  const [isSubscriptionModal, setIsSubscriptionModal] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [currentComp, setCurrentComp] = useState("info");
  const [imageName, setImageName] = useState("My pic");
  const [info, setInfo] = useState(PROFILE_INFO);
  const [compInfo, setCompInfo] = useState(COMP_INFO);
  const [employerData, setEmployerData] = useState({});
  const [isApproved, setIsApproved] = useState(false);

  const [openEditImage, setOpenEditImage] = useState(false);
  const [imagePreview, setImagePreview] = useState([]);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [errors, setErrors] = useState([]);
  const [progressButton, setProgressButton] = useState(false);
  const [leftExpanded, setLeftExpand] = useState(true);
  const [profileCompletion, setProfileCompletion] = useState(0);
  const [editable, setEditable] = useState(false);
  const [openCameraDialog, setOpenCameraDialog] = useState(false);
  const [badgesData, setBadgesData] = useState({});
  const [imageBlob, setImageBlob] = useState();
  const [croppedImageUrl, setCroppedImageUrl] = useState(null);
  const [image, setImage] = useState([]);
  const [companyDetail, setCompanyDetail] = useState([
    {
      TotalJobCount: "",
      ActiveJobCount: "",
      TotalUserCount: "",
      TotalUserShortlisted: "",
      TotalUserInterviewed: "",
      TotalUserHired: "",
    },
  ]);
  const [profilePercentage, setProfilePercentage] = useState({
    myInfo: 0,
    companyInfo: 0,
    myPlan: 0,
    billing: 0,
  });
  const [chartDetails, setChartAttributes] = useState({
    height: 125,
    valuefontSize: "20px",
    labelFontSize: "12px",
  });
  const [arrSlider2, setArrSlider2] = useState([
    grey_profile_contemplator,
    grey_profile_collaborator,
    grey_profile_character,
    grey_profile_challenger,
  ]);

  useEffect(() => {
    if (width > 1024) {
      setLeftExpand(true);
      setRightExpanded(true);
    } else {
      setLeftExpand(false);
      setRightExpanded(false);
    }
  }, [width]);

  const cropInputFUnction = (e) => {
    const files = e?.target?.files;
    if (files && files?.length > 0) {
      const reader = new FileReader();
      reader.onload = () => setImage(reader?.result);
      reader?.readAsDataURL(files?.[0]);
    }
  };
  const handleZoom = (direction) => {
    const step = 0.5;
    let newZoom = zoom;

    if (direction === "+") {
      newZoom = Math.min(zoom + step, 3); // Limit zoom to maximum 3x
    } else if (direction === "-") {
      newZoom = Math.max(zoom - step, 1); // Limit zoom to minimum 1x
    }
    setZoom(newZoom);
  };

  const getProfilePercentageData = async () => {
    const { payload } = await dispatch(getProfilePercentage());
    setProfilePercentage(payload?.data);
  };
  const getAllData = async () => {
    try {
      let payload = {
        limit: 5,
      };
      dispatch(setLoading(true));
      await Promise.all([
        dispatch(getCompanies(payload)),
        dispatch(getIndustries()),
        dispatch(getCountry()),
      ]);
      dispatch(setLoading(false));
    } catch (error) {
      dispatch(setLoading(false));
      dispatch(
        setAlert({
          show: true,
          type: ALERT_TYPE?.ERROR,
          msg: ERROR_MSG,
        })
      );
    }
  };

  const handleImageClick = (e) => {
    setImagePreview(null);
    hiddenFileInput.current.click();
  };

  const handleImageChange = async (event, name) => {
    if (event?.target?.files?.[0] === undefined) {
      return;
    }
    cropInputFUnction(event);
    const render = new FileReader();
    setZoom(1);
    setImageName(event?.target?.files?.[0].name);
    const imageData = window.URL.createObjectURL(
      new Blob(event?.target?.files, { type: "application/*" })
    );
    setImagePreview(imageData);
    setOpenEditImage(true);
    event.target.value = "";
  };

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const handleSubscriptionCancel = () => {
    setImageName("My pic");
    setOpenEditImage(false);
    setImageBlob();
    setCroppedImageUrl(null);
    setImage();
  };

  const handleImageEdit = useCallback(
    async (event) => {
      const croppedImage = await getCroppedImg(
        imagePreview,
        croppedAreaPixels,
        0
      );
      const formData = new FormData();
      const blobTofile = new File([croppedImage], imageName, {
        type: "image/jpeg",
      });

      formData.append("profile-pic", blobTofile);

      try {
        const { payload } = await dispatch(uploadProfilePic(formData));
        if (payload?.status === "success") {
          setEmployerData({ ...employerData, profileURL: payload.data });
          setOpenEditImage(false);
          getEmpBadgesData();
          dispatch(
            setAlert({
              show: true,
              type: ALERT_TYPE?.SUCCESS,
              msg: "Profile pic uploaded Successfully!",
            })
          );
        } else if (payload?.status === "error") {
          setImageName("My pic");
          dispatch(
            setAlert({
              show: true,
              type: ALERT_TYPE?.ERROR,
              msg: "Image format not supported",
            })
          );
        }
      } catch (error) {
        setImageName("My pic");
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE?.ERROR,
            msg: "",
          })
        );
      }
    },
    [croppedAreaPixels]
  );

  const handleSubscriptionOk = async () => {
    const formData = new FormData();
    const blobTofile = new File([imageBlob], imageName, {
      type: "image/jpeg",
    });
    formData.append("profile-pic", blobTofile);
    try {
      const { payload } = await dispatch(uploadProfilePic(formData));
      if (payload?.status === "success") {
        setEmployerData({ ...employerData, profileURL: payload?.data });
        setOpenEditImage(false);
        getEmpBadgesData();
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE?.SUCCESS,
            msg: "Profile pic uploaded Successfully!",
          })
        );
        setImageBlob();
        setCroppedImageUrl(null);
        // setImage();
      } else if (payload?.status === "error") {
        setImageName("My pic");
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE?.ERROR,
            msg: "Image format not supported",
          })
        );
      }
    } catch (error) {
      setImageName("My pic");
      dispatch(
        setAlert({
          show: true,
          type: ALERT_TYPE?.ERROR,
          msg: "",
        })
      );
    }
  };
  const handleCompanyLogo = async () => {
    // const croppedImage = await getCroppedImg(
    //   imagePreview,
    //   croppedAreaPixels,
    //   0
    // );
    const formData = new FormData();
    const blobTofile = new File([imageBlob], imageName, {
      type: "image/jpeg",
    });

    formData.append("company-logo", blobTofile);

    try {
      const { payload } = await dispatch(uploadCompanyLogo(formData));
      if (payload?.status === "success") {
        setCompInfo({
          ...compInfo,
          companyURL: payload?.data,
          logo: payload?.logo,
        });
        setOpenEditImage(false);

        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE?.SUCCESS,
            msg: "Company logo uploaded Successfully!",
          })
        );
      } else if (payload?.status === "error") {
        setImageName("My pic");
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE?.ERROR,
            msg: "Image format not supported",
          })
        );
      }
    } catch (error) {
      setImageName("My pic");
      dispatch(setAlert({ show: true }));
    }
  };

  // const handleCompanyLogo = useCallback(
  //   async (event) => {
  //     const croppedImage = await getCroppedImg(
  //       imagePreview,
  //       croppedAreaPixels,
  //       0
  //     );
  //     const formData = new FormData();
  //     const blobTofile = new File([croppedImage], imageName, {
  //       type: "image/jpeg",
  //     });

  //     formData.append("company-logo", blobTofile);

  //     try {
  //       const { payload } = await dispatch(uploadCompanyLogo(formData));
  //       if (payload?.status === "success") {
  //         setCompInfo({
  //           ...compInfo,
  //           companyURL: payload?.data,
  //           logo: payload?.logo,
  //         });
  //         setOpenEditImage(false);

  //         dispatch(
  //           setAlert({
  //             show: true,
  //             type: ALERT_TYPE?.SUCCESS,
  //             msg: "Company logo uploaded Successfully!",
  //           })
  //         );
  //       } else if (payload?.status === "error") {
  //         setImageName("My pic");
  //         dispatch(
  //           setAlert({
  //             show: true,
  //             type: ALERT_TYPE?.ERROR,
  //             msg: "Image format not supported",
  //           })
  //         );
  //       }
  //     } catch (error) {
  //       setImageName("My pic");
  //       dispatch(setAlert({ show: true }));
  //     }
  //   },
  //   [croppedAreaPixels]
  // );

  const getCompanyInfoData = (compInfoData) => {
    setCompInfo(compInfoData);
  };

  const getEmpBadgesData = async () => {
    const { payload } = await dispatch(getEmpBadges());
    setBadgesData(payload?.data);
  };

  const getAllEmpData = async () => {
    try {
      const { payload } = await dispatch(getEmpProfile());
      if (payload?.status === "success") {
        getProfilePercentageData();
        setEmployerData(payload?.data);
        setIsApproved(payload?.data?.company_approved);
        setInfo({
          first_name: payload?.data?.user?.first_name,
          surname: payload?.data?.user?.surname,
          email: payload?.data?.user?.email,
          contact_no: payload?.data?.contact_no,
          gender: payload?.data?.gender,
          town_id: payload?.data?.town_id,
          dob: payload?.data?.dob,
          linkedin_profile_link: payload?.data?.linkedin_profile_link
            ? payload?.data?.linkedin_profile_link
            : "",
          country_name: payload?.data?.country_name,
          country_code: payload?.data?.country_code,
          country_id: payload?.data?.country_id,
          town_name: payload?.data?.town_name,
          gender_flag: payload?.data?.gender_flag,
          calendar_link: payload?.data?.calendar_link,
        });
        setCompInfo({
          ...compInfo,
          companyURL:
            payload?.data?.companyLogoURL === "NO URL"
              ? []
              : payload?.data?.companyLogoURL,
          logo: payload?.data?.logo,
        });
        setProfileCompletion(payload?.data?.profile_completion_percentage);
      }
    } catch (error) {
      dispatch(setAlert({ show: true }));
    }
  };

  const handleInfoSave = async () => {
    // setActiveButton(0);
    dispatch(setProfileSteps(0));
    await getAllEmpData();
  };

  const renderFooter = <></>;

  const handleClickInvite = () => {
    navigate("/employer/my-team/add-new-member", {
      state: { companyname: compInfo?.company_name },
    });
  };

  const func = (prop) => {
    dispatch(setProfileSteps(prop));
    if (prop === 0) {
      setExpanded(false);
    }
    if (prop === 1) {
      setCurrentComp("info");
      setExpanded(true);
    } else if (prop === 2) {
      setCurrentComp("companyInfo");
      setExpanded(true);
    }
  };
  const boxRef = useRef();
  const handleClickOutside = (event) => {
    if (boxRef?.current && !boxRef?.current.contains(event?.target)) {
      setProgressButton(false);
    }
  };

  const calculateHeight = () => {
    const screenWidth = window.innerWidth;
    if (screenWidth < 600) {
      // xs
      return `calc(100vh - 95px)`;
    } else if (screenWidth < 900) {
      // sm
      return `calc(100vh - 80px)`;
    } else if (screenWidth < 1366) {
      // md
      return 90;
    } else if (screenWidth < 1650) {
      return 100;
    } else if (screenWidth < 1785) {
      return 110;
    } else if (screenWidth < 1920) {
      // lg
      return 110;
    } else if (screenWidth < 2559) {
      // xl
      return 125;
    } else {
      // xxl or greater
      return 150;
    }
  };
  const calculateValueFontSize = () => {
    const screenWidth = window.innerWidth;

    if (screenWidth < 600) {
      // xs
      return 16;
    } else if (screenWidth < 900) {
      // sm
      return 16;
    } else if (screenWidth < 1366) {
      // md
      return 16;
    } else if (screenWidth < 1920) {
      // lg
      return 18;
    } else if (screenWidth < 2559) {
      // xl
      return 20;
    } else {
      // xxl or greater
      return 22;
    }
  };
  const calculatelabelFontSize = () => {
    const screenWidth = window.innerWidth;

    if (screenWidth < 600) {
      // xs
      return "9px";
    } else if (screenWidth < 900) {
      // sm
      return "9px";
    } else if (screenWidth < 1366) {
      // md
      return "9px";
    } else if (screenWidth < 1920) {
      // lg
      return "5px";
    } else if (screenWidth < 2559) {
      // xl
      return 11;
    } else {
      // xxl or greater
      return 12;
    }
  };

  const calculateChartHeight = () => {
    const screenWidth = window.innerWidth;
      if (screenWidth < 600) {
      return 100;
    } else if (screenWidth < 900) {
      return 125;
    } else if (screenWidth < 1366) {
      // md
      return 125;
    } else if (screenWidth < 1650) {
      return 125;
    } else if (screenWidth < 1785) {
      return 125;
    } else if (screenWidth < 1920) {
      return 125;
    } else if (screenWidth < 2559) {
      return 125;
    } else {
      return 125;
    }
  };

  const handleResizeFunction = () => {
    const newChartDetails = {
      height: calculateHeight(),
      valuefontSize: calculateValueFontSize(),
      lablefontSize: calculatelabelFontSize(),
    };
    setChartAttributes(newChartDetails);
  };

  const handleImageData = (data) => {
    const id = nanoid();
    const base64Image = data?.replace(/^data:image\/jpeg;base64,/, "");
    setImage(data);
    // Decode Base64 data into binary
    const binaryData = atob(base64Image);

    // Create a Blob object
    const blob = new Blob(
      [new Uint8Array([...binaryData]?.map((char) => char.charCodeAt(0)))],
      { type: "application/*" }
    );
    setImageName(id + ".jpeg");
    const imageData = window.URL.createObjectURL(blob);
    setZoom(1);
    setImagePreview(imageData);
    setOpenEditImage(true);
  };

  const handlePhotoCapture = () => {
    setOpenCameraDialog((prevState) => !prevState);
  };

  const handleNextStep = (stepNumber) => {
    dispatch(setProfileSteps(stepNumber));
    getAllEmpData();
  };

  const handleRightClick = () => {
    setArrSlider2([...arrSlider2?.slice(1), ...arrSlider2?.slice(0, 1)]);
  };
  const handleLeftClick = () => {
    setArrSlider2([
      ...arrSlider2?.slice(arrSlider2?.length - 1),
      ...arrSlider2?.slice(0, arrSlider2?.length - 1),
    ]);
  };

  useEffect(() => {
    getAllEmpData();
    profileSteps === 0 && getEmpBadgesData();
  }, [profileSteps]);

  useEffect(() => {
    // getAllData();
    handleResizeFunction();
    getProfilePercentageData();
  }, []);

  useEffect(() => {
    window.addEventListener("resize", handleResizeFunction);

    return () => {
      window.removeEventListener("resize", handleResizeFunction);
    };
  });
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  if (width === 0) {
    return null;
  }

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Box
          item
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: `${width >= 768 && "10px"}`,
            position: `${width < 1024 ? "absolute" : "relative"}`,
            // width: leftExpanded
            //   ? { xs: "150px", mdl: "150px", mlg: "150px", xl: "150px" }
            //   : "30px",
            width: leftExpanded ? "125px" : "30px",
            zIndex: "30",
          }}
        >
          <Button
            variant="contained"
            sx={{
              position: "static",
              borderRadius: "0 10px 10px 0",
              minWidth: "30px",
              width: "30px",
              height: "45px",
              boxShadow: "0px 5px 10px #00000029",
            }}
            color="filterButtons"
            onClick={() => {
              setLeftExpand((prevState) => !prevState);
              setRightExpanded((prevState) => !prevState);
              dispatch(setFilterExpanded(!filterExpanded));
            }}
          >
            {leftExpanded ? <LeftArrow /> : <RightArrow />}
          </Button>
          {leftExpanded && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "10px",
                width: `${width < 768 && "135px"}`,
                paddingTop: `${width < 768 && "10px"}`,
                background: `${width > 0 && width < 768 && "#EBECF3"}`,
                boxShadow: `${
                  width > 0 && width < 768 && "0px 5px 10px #00000029"
                }`,
                opacity: `${width < 768 && "1"}`,
                borderRadius: `${width < 768 && "10px"}`,
                paddingBottom: `${width < 768 && "10px"}`,
              }}
              className="filterSec"
            >
              <Paper
                sx={{
                  background: "transparent",
                  // marginRight: "1px",
                  boxShadow: 0,
                  // maxWidth: `${width < 768 && "150px"}`,
                  maxWidth: "125px",
                }}
              >
                <ButtonPanel
                  activeButton={profileSteps}
                  // setActiveButton={setActiveButton}
                  onClickFunc={func}
                  side={"left"}
                  color={"filterButtons"}
                  panelData={RECRUITER_MYPROFILE_LEFTPANEL_BUTTONS}
                />
              </Paper>
            </Box>
          )}
        </Box>
        <Box
          sx={{
            height: {
              xs: `calc(100vh - 70px)`,
              sm: `calc(100vh - 70px )`,
              md: `calc(100vh - 70px)`,
              lg: `calc(100vh - 80px)`,
              xl: `calc(100vh - 100px)`,
              xxl: "calc(100vh - 120px)",
            },
            display: "flex",
            flexGrow: 1,
            alignItems: "center",
            flexDirection: "column",
            width: `calc(100vw - 255px)`,
            position: "sticky",
            overflowY: "scroll",
          }}
        >
          {profileSteps !== 3 && profileSteps !== 4 && profileSteps !== 5 ? (
            <Grid
              sx={{
                display: "flex",
                alignItems: { xs: "center", xl: "flex-start" },
                position: "relative",
                height: "auto",
                flexDirection: "column",
                marginTop: { xs: "55px", md24: "55px" },
                marginLeft: { xs: "10px", lg: "10px", xl: "35px" },
                marginRight: { xs: "10px", lg: "10px", xl: "35px" },
                width: { xs: "90%", md24: "96.5%" },
                gap: { xs: "0px", lg: "30px" },
                zIndex: "-9999",
              }}
            >
              <EmployerProfileCardRow
                profileSteps={profileSteps}
                compInfo={compInfo}
                employerData={employerData}
                companies={companies}
                handlePhotoCapture={handlePhotoCapture}
                hiddenFileInput={hiddenFileInput}
                setImagePreview={setImagePreview}
                handleImageChange={handleImageChange}
                editable={editable}
                handleImageClick={handleImageClick}
                chartDetails={chartDetails}
                handleLeftClick={handleLeftClick}
                arrSlider2={arrSlider2}
                handleRightClick={handleRightClick}
                companyDetail={companyDetail}
                calculateChartHeight={calculateChartHeight}
              />
              {profileSteps === 0 && (
                <Box
                  sx={{
                    top: { xs: "70px", lg: "30px" },
                    padding: "0px !important",
                    cursor: "auto !important",
                    boxShadow: "0px 5px 10px #00000029",
                    borderRadius: "25px",
                    marginBottom: "0px !important",
                    position: "relative",
                    background: "#ffff",
                    display: "flex",
                    flexDirection: `${width > 1024 ? "row" : "column"}`,
                    gap: "10px",
                    marginX: { xs: "10px", lg: "0px" },
                  }}
                  width={"100%"}
                >
                  <Badges badgesData={badgesData} />
                </Box>
              )}
              <Box
                sx={{
                  marginTop: { xs: "70px", lg: "30px" },
                  padding: "30px 30px 0px 30px !important",
                  cursor: "auto !important",
                  boxShadow: "0px 5px 10px #00000029",
                  borderRadius: "25px",
                  marginBottom: "30px !important",
                  position: "relative",
                  background: "#ffff",
                  display: profileSteps === 0 ? "none" : "block",
                  // height: "auto",
                  width: { xs: "90%", md24: "96.5%" },
                }}
                // width={`calc(100vw - 420px)`}
              >
                {profileSteps === 1 && (
                  <Info
                    profile={info}
                    errors={errors}
                    setErrors={setErrors}
                    handleNextStep={handleNextStep}
                    handleInfoSave={handleInfoSave}
                    setEmployerData={setEmployerData}
                  />
                )}
                {profileSteps === 2 && (
                  <CompanyInfo
                    editable={editable}
                    setEditable={setEditable}
                    companyID={employerData?.company_id}
                    profile2={compInfo}
                    handleCompanyInfoData={getCompanyInfoData}
                    errors={errors}
                    setErrors={setErrors}
                    companies={companies}
                    industries={industries}
                    employerData={employerData}
                    setEmployerData={setEmployerData}
                    setCompanyDetail={setCompanyDetail}
                    companyurl={compInfo?.companyURL}
                    handleNextStep={handleNextStep}
                    handleInfoSave={handleInfoSave}
                    getProfilePercentageData={getProfilePercentageData}
                    getAllEmpData={getAllEmpData}
                    country={country}
                    town={town}
                    setCompInfo={setCompInfo}
                    isApproved={isApproved}
                    setIsApproved={setIsApproved}
                  />
                )}
              </Box>
            </Grid>
          ) : (
            <Box
              // width={`calc(100vw - 360px)`}
              // marginTop={"109px"}
              // height="100%"
              width={"100%"}
              paddingX="30px"
              marginTop={"55px"}
              height="100%"
              boxSizing={"border-box"}
            >
              {profileSteps === 3 ? (
                <AddCompany companies={companies} />
              ) : profileSteps === 4 ? (
                <Box
                  sx={{
                    // height: "416px",
                    height: "100%",
                  }}
                >
                  <MyPlans />
                </Box>
              ) : (
                // <ComingSoon />
                <Box
                  sx={{
                    // marginTop: { xs: "70px", lg: "30px" },

                    cursor: "auto !important",
                    boxShadow: "0px 5px 10px #00000029",
                    borderRadius: "25px",
                    marginBottom: "30px !important",
                    // height: "100%",
                    background: "#FFF",
                  }}
                >
                  <BillingComponent />
                </Box>
              )}
            </Box>
          )}
        </Box>

        <Box
          item
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: `${width >= 768 && "10px"}`,
            direction: "rtl",
            position: `${width < 1024 ? "absolute" : "relative"}`,
            zIndex: "30",
            // width: rightExpand && "150px",
            right: "0",
            // width: leftExpanded
            //   ? { xs: "150px", mdl: "150px", mlg: "150px", xl: "150px" }
            //   : "30px",
            width: rightExpanded ? "125px" : "30px",
            minWidth: rightExpanded ? "125px" : "30px",
          }}
        />
      </Box>
      <Dialog open={openCameraDialog} fullWidth={true} maxWidth={"sm"}>
        <OpenCameraDialog
          onRecordingStop={setOpenCameraDialog}
          handleImage={handleImageData}
        />
      </Dialog>
      {
        <SubscriptionPopModal
          handleOpen={isSubscriptionModal}
          handleClose={() => setIsSubscriptionModal(false)}
          handleSubscriptionOk={() => setIsSubscriptionModal(false)}
        />
      }
      {openEditImage && (
        <CropModal
          handleOpen={openEditImage}
          handleClose={setOpenEditImage}
          handleSubscriptionOk={() => {
            profileSteps === 2 ? handleCompanyLogo() : handleSubscriptionOk();
          }}
          handleSubscriptionCancel={handleSubscriptionCancel}
          imagePreview={imagePreview}
          image={image}
          setImage={setImage}
          croppedImageUrl={croppedImageUrl}
          setCroppedImageUrl={setCroppedImageUrl}
          imageBlob={imageBlob}
          setImageBlob={setImageBlob}
        />
      )}
    </>
  );
}
