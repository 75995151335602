import {
  Box,
  Button,
  IconButton,
  InputBase,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useCallback, useEffect, useRef, useState } from "react";
import submitCandidate from "../../../assets/Padding Excluded/Black_Follower.svg";
import locale from "../../../i18n/locale";
import uploadCVIcon from "../../../assets/MyCV/Black_CV_Portfolio.svg";
import viewIcon from "../../../assets/Padding Excluded/Black_View.svg";
import { CheckCircle, Circle } from "@mui/icons-material";
import InputBox from "../InputBox";
import { ToggleSwitch } from "../../../utils/CommonComponent";
import {
  formatPhoneNumberIntl,
  isValidPhoneNumber,
} from "react-phone-number-input";
import PhoneInput from "react-phone-number-input";
import {
  debounce,
  getContactDetail,
  getUserCountryFromGeolocation,
  validateSchema,
} from "../../../utils/Common";
import { setAlert } from "../../../redux/configSlice";
import {
  ALERT_TYPE,
  ERROR_MSG,
  PASSWORD_REGEX,
  emailRegex,
} from "../../../utils/Constants";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import {
  getEmailData,
  submitCandidateForJob,
} from "../../../redux/employer/myTeams";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { uploadCandidateCv } from "../../../redux/candidate/myCvSlice";
import CreatableSelect from "react-select/creatable";

export default function SubmitCandidate({
  setIsSubmitCandidate,
  job,
  allJobs,
  setAllJobs,
}) {
  const theme = useTheme();
  const i18n = locale.en;
  const dispatch = useDispatch();
  // const fileAccept = "application/pdf, application/doc, application/docx";
  const fileAccept = "application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document";
  const hiddenFileInput = useRef(null);
  const [inputValue, setInputValue] = useState();
  const [options, setOptions] = useState([]); // State to store dropdown options
  const [candidateData, setCandidateData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    contact: "",
    password: "",
    cvName: "",
  });

  const candidateSchema = Yup.object().shape({
    first_name: Yup.string().required("First Name is Required."),
    password: Yup.string()
      .matches(
        PASSWORD_REGEX,
        "Password must contain at least 8 characters, one uppercase, one lowercase, one number, and one special character"
      )
      .required("Password required"),
    last_name: Yup.string().required("Last Name is required"),
    email: Yup.string()
      .matches(emailRegex, "Invalid email address")
      .required("Email is Required."),
    contact: Yup.string().required("Contact No. is Required."),
  });

  const formSchema = Yup.object().shape({
    workData: Yup.object().when("noCheck", {
      is: true,
      then: (schema) => schema.shape(candidateSchema.fields),
      otherwise: (schema) => schema.optional(),
    }),
    noCheck: Yup.bool(),
  });

  const [showPassword, setShowPassword] = useState(false);
  const [errors, setErrors] = useState([]);
  const [emailLoaded, setEmailLoaded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [initialCountryCode, setInitialCountryCode] = useState("");
  const [candidateInfo, setCandidateInfo] = useState({
    first_name: "",
    last_name: "",
    email: "",
    contact: "",
    password: "",
    cvName: "",
  });
  const [uploadedCvFile, setUplaodedCvFile] = useState();
  const [cvName, setCvName] = useState("");
  const [emailExist, setEmailExist] = useState(false);

  const debouncedHandleOnSelectInputChange = useCallback(
    debounce(
      async (newValue) => {
        let params = {
          title: newValue || "",
        };
        if (newValue) {
          try {
            let newmemberData;
            const { payload } = await dispatch(
              getEmailData({
                email: newValue?.value ? newValue?.value : newValue,
              })
            );
            if (payload?.status === "success") {
              if (payload?.data !== null) {
                setOptions([...options, { value: newValue, label: newValue }]);
              }
              newmemberData = {
                first_name:
                  payload?.data !== null ? payload?.data?.first_name : "",
                last_name:
                  payload?.data !== null ? payload?.data?.last_name : "",
                // email: newValue?.target?.value,
                email: newValue?.value ? newValue?.value : newValue,
                contact:
                  payload?.data !== null && payload?.data?.employer_profile
                    ? payload?.data?.employer_profile?.contact_no?.includes("+")
                      ? payload?.data?.employer_profile?.contact_no
                      : `+${payload?.data?.employer_profile?.contact_no}`
                    : initialCountryCode?.toUpperCase(),
              };
              setCvName(
                payload?.data !== null
                  ? payload?.data?.candidate_info?.cv_link?.match(
                      /_cv_(.+)/
                    )?.[1]
                  : ""
              );
              setCandidateData(newmemberData);
            } else {
              dispatch(
                setAlert({
                  show: true,
                  type: ALERT_TYPE?.ERROR,
                  msg: "Error",
                })
              );
            }
          } catch (error) {
            dispatch(
              setAlert({
                show: true,
                type: ALERT_TYPE?.ERROR,
                msg: ERROR_MSG,
              })
            );
          }
        }
      },
      300,
      setIsLoading
    ),
    []
  );

  const handleEmail = (newValue, inpuid, event) => {
    debouncedHandleOnSelectInputChange(newValue);
  };

  const handleInputChange = async (event) => {
    setCandidateInfo(candidateData);
    setOptions([]);
    setEmailLoaded(false);
  };

  const handleCreateOption = (inputValue) => {
    setCandidateInfo(candidateData);
    setOptions([...options, { value: inputValue, label: inputValue }]);
    setInputValue(""); // Clear input after creating option
    setEmailLoaded(true);
  };

  const handlePassword = (event) => {
    setCandidateInfo({
      ...candidateInfo,
      password: event?.target?.value,
    });
    if (!PASSWORD_REGEX?.test(event?.target?.value)) {
      setErrors((prev) => [
        ...prev,
        { message: "password not matching", key: "workData.password" },
      ]);
      return;
    } else {
      setErrors((prev) =>
        prev?.filter((item) => item?.key !== "workData.password")
      );
    }
  };

  const handleShowPassword = () => {
    setShowPassword((prevState) => !prevState);
  };

  const submit = () => {
    let { first_name, last_name, email, contact, password } = candidateInfo;

    let testErrorState = {
      first_name: first_name,
      last_name: last_name,
      email: email,
      contact: contact,
      password: password,
    };
    let data = {
      first_name: candidateInfo?.first_name,
      last_name: candidateInfo?.last_name,
      email: candidateInfo?.email,
      contact: candidateInfo?.contact,
      password: candidateInfo?.password,
    };
    validateSchema(formSchema, {
      workData: data,
      noCheck: emailLoaded,
    })
      .then(() => {
        try {
          const contact_no = formatPhoneNumberIntl(candidateInfo?.contact);
          const contactInfo = getContactDetail(contact_no);
          let data = {
            first_name: candidateInfo?.first_name,
            last_name: candidateInfo?.last_name,
            email: candidateInfo?.email,
            contact: contactInfo?.[1] ? contactInfo?.[1] : "",
            password: candidateInfo?.password,
            job_id: job?.job_id,
            country_code: contactInfo?.[0] ? contactInfo?.[0] : "",
          };
          dispatch(submitCandidateForJob(data)).then((payload) => {
            if (payload?.payload?.status === "success") {
              dispatch(
                setAlert({
                  show: true,
                  type: ALERT_TYPE?.SUCCESS,
                  msg: i18n["alertMessage.candidatesubmittedsuccessfully"],
                })
              );
              let newJobs = allJobs?.map((item) =>
                item?.job_id === job?.job_id
                  ? {
                      ...item,
                      TotalUserCount: (
                        parseInt(item?.TotalUserCount, 10) + 1
                      ).toString(),
                    }
                  : item
              );
              setAllJobs(newJobs);
              uploadedCvFile && uploadCv(payload?.payload?.user_id);
              setIsSubmitCandidate(false);
            } else if (payload?.payload?.status === "fail") {
              dispatch(
                setAlert({
                  show: true,
                  type: ALERT_TYPE?.ERROR,
                  msg: payload?.payload?.message,
                })
              );
            } else {
              dispatch(
                setAlert({
                  show: true,
                  type: ALERT_TYPE?.ERROR,
                  msg: ERROR_MSG,
                })
              );
            }
          });
        } catch (error) {
          dispatch(
            setAlert({
              show: true,
              type: ALERT_TYPE?.ERROR,
              msg: i18n["alertMessage.error"],
            })
          );
        }
      })
      .catch((error) => {
        setErrors(
          Object.keys(error)?.map((item) => ({
            message: error[item],
            key: item,
          }))
        );
      });
  };

  const handleFileClick = () => {
    hiddenFileInput?.current?.click();
  };

  const handleFileChange = async (event) => {
    setUplaodedCvFile(event?.target?.files?.[0]);
    setCvName(event?.target?.files?.[0]?.name);
    dispatch(
      setAlert({
        show: true,
        type: ALERT_TYPE.SUCCESS,
        msg: "CV uploaded Successfully!",
      })
    );
  };

  const uploadCv = async (userID) => {
    const formData = new FormData();
    formData.append("cv", uploadedCvFile);
    formData.append("candidate_id", userID);
    try {
      const { payload } = await dispatch(uploadCandidateCv(formData));
      if (payload?.status === "success") {
        setCvName(uploadedCvFile?.name);
        // dispatch(
        //   setAlert({
        //     show: true,
        //     type: ALERT_TYPE.SUCCESS,
        //     msg: "CV uploaded Successfully!",
        //   })
        // );
      } else {
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE.ERROR,
            msg: payload?.message,
          })
        );
      }
    } catch (error) {
      dispatch(setAlert({ show: true }));
    }
  };

  useEffect(() => {
    getUserCountryFromGeolocation()
      .then((countryCode) => {
        setInitialCountryCode(countryCode);
      })
      .catch((error) => {
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE?.ERROR,
            msg: "Error fetching country code",
          })
        );
      });
  }, []);

  return (
    <Box
      sx={{
        textAlign: "center",
        display: "flex",
        flexGrow: 1,
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <Box
        sx={{
          paddingBottom: 0,
          display: "flex",
          justifyContent: "space-between",
          //   flexDirection: "column",
          gap: "15px",
          // height: "100px",
        }}
      >
        <input
          accept={fileAccept}
          ref={hiddenFileInput}
          type="file"
          onChange={handleFileChange}
          style={{ display: "none" }}
          required
        />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          <Button
            variant="contained"
            color="viewJobButton"
            sx={{
              width: "200px",
              height: "45px",
              borderRadius: "25px 0 10px 0",
              padding: "0px !important",
              cursor: emailLoaded ? "pointer" : "default",
            }}
            onClick={() => emailLoaded && handleFileClick()}
            startIcon={
              <Box
                component={"img"}
                src={uploadCVIcon}
                sx={{
                  height: "20px",
                  width: "26px",
                }}
              />
            }
          >
            {i18n["submitCandidate.uploadCandidateCV"]}
          </Button>
          {emailLoaded && (
            <Typography
              sx={{
                fontSize: "14px",
                fontWeight: theme.typography.Bold,
                marginLeft: "4px",
              }}
            >
              {cvName !== "" && cvName !== undefined && cvName}
            </Typography>
          )}
        </Box>
        <Box
          sx={{
            alignItems: "center",
            display: "flex",
            width: "100px",
            height: "70px",
            flexDirection: "column",
            // border: "1px solid lightGray",
            border: `1px solid ${theme.palette.greyPlayButton.main}`,
            borderTop: 0,
            borderRight: 0,
            borderRadius: "0 0px 0px 10px",
          }}
        >
          <Button
            variant="contained"
            color="greyPlayButton"
            sx={{
              fontSize: "12px",
              fontWeight: theme.typography.Bold,
              width: "100px",
              height: "45px",
              color: "black",
              borderRadius: "0 25px 0 0",
              boxShadow: "none !important",
              padding: "0px !important",
              "& .MuiButton-startIcon": {
                margin: "0px !important",
              },
            }}
            startIcon={
              <Box
                component={"img"}
                sx={{
                  height: 25,
                  width: 25,
                }}
                src={viewIcon}
              />
            }
          >
            view jobs
          </Button>
          <Typography
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 1,
              fontWeight: theme.typography.Bold,
              fontSize: "14px",
              margin: "auto",
              zIndex: 9,
            }}
          >
            {job?.jobStatusName || "Status"}{" "}
            <Circle fontSize="string" color={`${job?.jobStatusName}Status`} />
          </Typography>
        </Box>
      </Box>

      {!emailLoaded && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            paddingX: "15px",
            gap: "13px",
            position: "relative",
            top: "-20px",
            width: "100%",
          }}
        >
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: theme.typography.Bold,
            }}
          >
            {i18n["submitCandidate.submitnewcandidate"]}
          </Typography>
          {/* <InputBox
            sx={{
              width: "100%",
              height: "40px",
              borderRadius: "10px",
            }}
            placeholder={i18n["submitCandidate.searchMyTalent"]}
            // value={profileData?.name}
            id="name"
            onChange={(event) => handleEmail(event)}
          />*/}
          <Box sx={{ width: "92%", textAlign: "justify" }}>
            <CreatableSelect
              options={options} // Dynamically populated options
              placeholder={i18n["submitCandidate.searchMyTalent"]}
              // Ensure the input value is set to the state variable holding user input
              value={inputValue}
              onInputChange={handleEmail}
              onChange={(event) => handleInputChange(event)} // Update input value on change
              // Conditionally enable onCreateOption based on emailLoaded state
              formatCreateOptionLabel={() => {
                return "Search"; // Display "Search" when creating a new option
              }}
              onCreateOption={emailExist ? undefined : handleCreateOption}
              styles={{
                menu: (provided) => ({
                  ...provided,
                  zIndex: 9999,
                }),
                menuList: (provided) => ({
                  ...provided,
                  "&::-webkit-scrollbar": {
                    width: "6px",
                    height: "6px",
                    borderRadius: "2.5px",
                    backgroundColor: "#efefef",
                  },
                  "&::-webkit-scrollbar-thumb": {
                    borderRadius: "2.5px",
                  },
                }),
                control: (baseStyles) => ({
                  ...baseStyles,
                  boxShadow: "none",
                  borderRadius: "10px",
                  minHeight: "40px",
                  border: "1px solid #E0E0E0",
                  "&:hover": "1px solid #E0E0E0",
                  width: `100%`,
                }),
              }}
            />
          </Box>
        </Box>
      )}

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          paddingX: "15px",
          gap: "12px",
          position: "relative",
          top: "-10px",
        }}
      >
        <Typography
          sx={{
            fontSize: "14px",
            fontWeight: theme.typography.Bold,
          }}
        >
          {i18n["submitCandidate.addacandidate"]}
        </Typography>
        <InputBox
          readOnly={!emailLoaded}
          sx={{
            width: "100%",
            height: "40px",
            borderRadius: "10px",
            border: errors?.find(
              (error) => error?.key === "workData.first_name"
            )
              ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
              : "1px solid #E0E0E0",
          }}
          placeholder={i18n["submitCandidate.firstname"]}
          value={candidateInfo?.first_name}
          id="first_name"
          onChange={(event) => {
            setCandidateInfo({
              ...candidateInfo,
              first_name: event?.target?.value,
            });
            setErrors((prev) =>
              prev?.filter((item) => item?.key !== "workData.first_name")
            );
          }}
        />
        <InputBox
          readOnly={!emailLoaded}
          sx={{
            width: "100%",
            height: "40px",
            borderRadius: "10px",
            border: errors?.find((error) => error?.key === "workData.last_name")
              ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
              : "1px solid #E0E0E0",
          }}
          placeholder={i18n["submitCandidate.lastname"]}
          value={candidateInfo?.last_name}
          id="last_name"
          onChange={(event) => {
            setCandidateInfo({
              ...candidateInfo,
              last_name: event?.target?.value,
            });
            setErrors((prev) =>
              prev?.filter((item) => item?.key !== "workData.last_name")
            );
          }}
        />
        <InputBox
          readOnly={!emailLoaded}
          sx={{
            width: "100%",
            height: "40px",
            borderRadius: "10px",
            border: errors?.find((error) => error?.key === "workData.email")
              ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
              : "1px solid #E0E0E0",
          }}
          placeholder={i18n["submitCandidate.email"]}
          value={candidateInfo?.email}
          id="email"
          onChange={(event) => {
            setCandidateInfo({
              ...candidateInfo,
              email: event?.target?.value,
            });
            setErrors((prev) =>
              prev?.filter((item) => item?.key !== "workData.email")
            );
          }}
        />
        {emailLoaded && (
          <Box
            sx={{
              display: "flex",
              width: "94%",
              height: "40px",
              borderRadius: "10px",
              boxShadow: "none",
              justifyContent: "space-between",
              padding: "0 10px",
              // border: `1px solid lightGray`,
              border: errors?.find(
                (error) => error?.key === "workData.password"
              )
                ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
                : `1px solid ${theme.palette.grayBorder}`,
            }}
          >
            <InputBase
              sx={{
                flexGrow: 1,
              }}
              // type={`${showPassword?"text":"password"}`}
              autoComplete="off"
              id="password"
              value={candidateInfo?.password}
              type={showPassword ? "text" : "password"} // Due to this field are pre populating
              placeholder="Enter a temporary password"
              onChange={(event) => {
                handlePassword(event);
              }}
            ></InputBase>
            <IconButton
              sx={{ py: 0 }}
              color=""
              aria-label="reset password"
              component="button"
              onClick={handleShowPassword}
            >
              {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
            </IconButton>
          </Box>
        )}
        {errors?.find((error) => error?.key === "workData.password") &&
          candidateInfo?.password !== "" && (
            <Typography
              sx={{
                fontSize: "12px",
                fontWeight: theme.typography.Medium,
                color: "red",
                textAlign: "justify",
                // width: "272px",
              }}
            >
              {i18n["alert.passwordmustcontain"]}
            </Typography>
          )}
        <Box
          sx={{
            background: "transparent",
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            height: "38px",
            borderRadius: "10px",
            border: errors?.find((error) => error?.key === "workData.contact")
              ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
              : "1px solid #E0E0E0",
          }}
        >
          <PhoneInput
            readOnly={!emailLoaded}
            international
            id="contact"
            placeholder="Enter phone number"
            countryCallingCodeEditable={false}
            value={candidateInfo?.contact}
            onChange={(value) => {
              setCandidateInfo({
                ...candidateInfo,
                contact: value,
              });
              setErrors((prev) =>
                prev?.filter((item) => item?.key !== "workData.contact")
              );
            }}
            defaultCountry={initialCountryCode?.toUpperCase()}
            focusInputOnCountrySelection={true}
            style={{
              font: "inherit",
              padding: "10px",
            }}
          />
          {isValidPhoneNumber(candidateInfo?.contact || "") && (
            <Typography
              sx={{
                color: theme.palette.tealColor.main,
                fontSize: "14px",
                alignItems: "center",
                display: "flex",
                gap: "5px",
                marginRight: "5px",
                justifyContent: "end",
              }}
            >
              Correct <CheckCircle fontSize="small" />
            </Typography>
          )}
        </Box>
      </Box>

      <Box
        sx={{
          display: "flex",
          paddingX: "15px",
          justifyContent: "space-between",
        }}
      >
        <Typography
          sx={{
            fontSize: "12px",
            fontWeight: theme.typography.Medium,
            textAlign: "left",
            maxWidth: "245px",
          }}
        >
          {i18n["submitCandidate.text"]}
        </Typography>

        <ToggleSwitch
          color="blueButton700"
          id="consent"
          defaultChecked={true}
          //   checked={!!Number(profileData?.relocate)}
          //   onChange={handleSwitch}
        />
      </Box>

      <Box sx={{ display: "flex", width: "100%" }}>
        <Button
          variant="contained"
          color="backButton"
          sx={{
            borderRadius: 0,
            width: "33.33%",
            paddingY: "25px",
            fontSize: "12px",
            height: "60px",
            fontWeight: theme.typography.Bold,
            boxShadow: 0,
            ":hover": {
              boxShadow: 0,
            },
          }}
          onClick={() => setIsSubmitCandidate(false)}
        >
          back
        </Button>
        <Button
          variant="contained"
          color="extendJobButton"
          sx={{
            borderRadius: "0 0 25px 0",
            width: "66.67%",
            paddingY: "25px",
            fontSize: "14px",
            fontWeight: theme.typography.Bold,
            height: "60px",
            overflow: "hidden",
            boxShadow: 0,
            ":hover": {
              boxShadow: 0,
            },
          }}
          startIcon={
            <Box
              component={"img"}
              src={submitCandidate}
              sx={{
                height: "25px",
                width: "25px",
              }}
            />
          }
          onClick={submit}
        >
          submit
        </Button>
      </Box>
    </Box>
  );
}
