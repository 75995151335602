import { Box, Button, Grid, Paper } from "@mui/material";
import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import LeftArrow from "../../common/LeftArrow";
import RightArrow from "../../common/RightArrow";
import InfiniteScroll from "react-infinite-scroll-component";
import SharedJobsCard from "./SharedJobsCard";
import ButtonPanel from "../../common/ButtonPanel";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { getAllStages } from "../../../redux/stages";
import { getJobStatus } from "../../../redux/status";
import { useQuery } from "../../../utils/Common";
import { useNavigate } from "react-router-dom";
import RightFiltersPanel from "../../common/myJobsSectionEmployerAndRecruiter/RightFiltersPanel";
import { getPromoterJobs } from "../../../redux/candidate/candidateJobs";
import NoJobs from "../NoJobs";
import { ALERT_TYPE } from "../../../utils/Constants";
import { setAlert } from "../../../redux/configSlice";
import useViewport from "../../common/ViewPort";
import {
  setCardsPadding,
  setFilterExpanded,
} from "../../../redux/guest/jobsSlice";

export default function SharedJobs() {
  const gridRef = useRef();
  const { width } = useViewport();
  const selectedAllStages = useRef([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const selectedJobStatus = useRef([]);
  const query = useQuery();

  const allStages = useSelector((state) => state?.configstages?.stages);
  const jobStatus = useSelector((state) => state?.configjobstatus?.status);
  const filterExpanded = useSelector(
    (state) => state?.configjobslice?.filterExpanded
  );
  const [itemsFetched, setItemsFetched] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [leftExpanded, setLeftExpand] = useState(true);
  const [rightExpand, setRightExpand] = useState(true);
  const [newCardWidth, setNewCardWidth] = useState(340);
  const [newPadding, setNewPadding] = useState(15);
  const [showBox, setShowBox] = useState(false);
  const [promoterJobs, setPromoterJobs] = useState([]);
  const [lastKey, setLastKey] = useState(0);
  const [jobListFilter, setJobListFilter] = useState([allStages?.[0]?.id]);
  const [jobStatusFilter, setJobStatusFilter] = useState([jobStatus?.[0]?.id]);

  const handleFilterSelection = (paramType, filterName) => {
    if (filterName?.[0] === "all stages" || filterName?.[0] === "job status") {
      filterName?.[0] === "all stages" && (selectedAllStages.current = []);
      filterName?.[0] === "job status" && (selectedJobStatus.current = []);
      const queryParams = new URLSearchParams(window?.location?.search);
      queryParams.delete(paramType);
      navigate(`/promoter/shared-jobs?${queryParams.toString()}`);
      return;
    } else {
      const updatedFilters = selectedFilters?.includes(filterName)
        ? selectedFilters?.filter((filter) => filter !== filterName)
        : [filterName];
      const queryParams = new URLSearchParams(window.location.search);
      queryParams.set(paramType, updatedFilters?.join(","));
      navigate(`${window.location.pathname}?${queryParams.toString()}`);
      setSelectedFilters(updatedFilters);
    }
  };

  const getStages = async () => {
    await dispatch(getAllStages());
  };
  const getStatus = async () => {
    await dispatch(getJobStatus());
  };

  const calculatedHeight = () => {
    const screenWidth = window.innerWidth;

    if (screenWidth < 600) {
      // xs
      return `calc(100vh - 95px)`;
    } else if (screenWidth < 900) {
      // sm
      return `calc(100vh - 80px)`;
    } else if (screenWidth < 1366) {
      // md
      return `calc(100vh - 80px)`;
    } else if (screenWidth < 1920) {
      // lg
      return `calc(100vh - 80px)`;
    } else if (screenWidth < 2559) {
      // xl
      return `calc(100vh - 100px)`;
    } else {
      // xxl or greater
      return `calc(100vh - 120px)`;
    }
  };

  const getJobList = async (
    jobStage = jobListFilter,
    jobStatus = jobStatusFilter,
    lastkey = lastKey
  ) => {
    try {
      let data = {
        lastKey: lastkey,
        jobStatusID: jobStatus?.[0] === 1111 ? "" : jobStatus,
        jobStageID: jobStage?.[0] === 1111 ? "" : jobStage,
      };
      const { payload } = await dispatch(getPromoterJobs(data));
      if (payload?.status === "success") {
        if (payload?.data?.length === 0) {
          setShowBox(true);
        } else {
          if (payload?.pageNumber === 0) {
            setShowBox(false);
            setLastKey(payload?.pageNumber + 1);
            setPromoterJobs(payload?.data);
            setItemsFetched(true);
          } else {
            setShowBox(false);
            setLastKey(payload?.pageNumber + 1);
            setPromoterJobs((prevState) => [...prevState, ...payload?.data]);
            setItemsFetched(true);
          }
        }
      }
    } catch (error) {
      dispatch(
        setAlert({
          show: true,
          type: ALERT_TYPE?.ERROR,
          msg: "Something went wrong! please relaod the window",
        })
      );
    }
  };

  const jobsFilter = (selectedFilter) => {
    let stages = [];
    selectedFilter?.map((item) => {
      let selcted = allStages?.find((filter) => filter?.id === item);
      stages?.push(selcted?.name);
    });

    selectedAllStages.current = selectedFilter;

    handleFilterSelection("allstages", stages);
    setPromoterJobs([]);
    setJobListFilter(selectedFilter);
    getJobList(selectedFilter, jobStatusFilter, "");
  };

  const jobsFilterLeft = (selectedFilter) => {
    let status = [];
    selectedFilter?.map((item) => {
      let selcted = jobStatus?.find((filter) => filter?.id === item);
      status?.push(selcted?.name);
    });

    selectedJobStatus.current = selectedFilter;
    handleFilterSelection("jobstatus", status);
    setPromoterJobs([]);
    setJobStatusFilter(selectedFilter);
    getJobList(jobListFilter, selectedFilter, "");
  };
  function calculateCardWidthAndPadding(totalWidth) {
    let cardWidth = 340;
    let padding = 15;
    let numCards = Math.floor(totalWidth / (cardWidth + padding));
    let remainingSpace =
      totalWidth - (numCards * cardWidth + padding * (numCards + 1));

    while (remainingSpace > 0) {
      if (remainingSpace < cardWidth + padding) {
        if (padding < 30) {
          padding++;
          remainingSpace =
            totalWidth - (numCards * cardWidth + padding * (numCards + 1));
        } else {
          break;
        }
      } else {
        numCards++;
      }
    }

    cardWidth = (totalWidth - padding * (numCards + 1)) / numCards;
    if (cardWidth > 380) {
      cardWidth = 380;
    }
    remainingSpace =
      totalWidth - (numCards * cardWidth + padding * (numCards + 1));

    padding = padding + remainingSpace / (numCards + 1);

    remainingSpace =
      totalWidth - (numCards * cardWidth + padding * (numCards + 1));

    setNewCardWidth(cardWidth - 1);
    setNewPadding(padding);
    dispatch(setCardsPadding(padding));
    return { numCards, cardWidth, padding, remainingSpace };
  }

  const getNewWidth = () => {
    if (gridRef?.current) {
      calculateCardWidthAndPadding(gridRef?.current?.clientWidth);
    }
  };
  useLayoutEffect(() => {
    if (gridRef?.current) {
      calculateCardWidthAndPadding(gridRef?.current?.clientWidth);
    }
    window.addEventListener("resize", getNewWidth);
    return () => {
      window.removeEventListener("resize", getNewWidth);
    };
  }, [itemsFetched, leftExpanded, rightExpand]);

  useEffect(() => {
    getStages();
    getStatus();
  }, []);

  useEffect(() => {
    let stagefilter = [];
    let statusfilter = [];

    query
      .get("allstages")
      ?.split(",")
      ?.map((item) => {
        let stageID = allStages?.find((stage) => stage?.name === item);
        stagefilter?.push(stageID?.id);
      });
    selectedAllStages.current = stagefilter;

    query
      .get("jobstatus")
      ?.split(",")
      ?.map((item) => {
        let stageID = jobStatus?.find((stage) => stage?.name === item);
        statusfilter?.push(stageID?.id);
      });
    selectedJobStatus.current = statusfilter;
    if (allStages?.length > 0) {
      getJobList(stagefilter, statusfilter, "");
    }
  }, [allStages, jobStatus]);

  useEffect(() => {
    if (width > 1024) {
      setLeftExpand(true);
      setRightExpand(true);
    } else {
      setLeftExpand(false);
      setRightExpand(false);
    }
  }, [width]);
  if (width === 0) {
    return null;
  }
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <Box
        item
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: `${width >= 768 && "10px"}`,
          position: `${width < 768 ? "absolute" : "relative"}`,
          // width: leftExpanded
          //   ? { xs: "150px", mdl: "123px", mlg: "150px", xl: "150px" }
          //   : "30px",
          width: leftExpanded ? "125px" : "30px",
          minWidth: leftExpanded ? "125px" : "30px",
          // width: leftExpanded && "150px",
          zIndex: "30",
        }}
      >
        <Button
          variant="contained"
          sx={{
            boxShadow: "0px 5px 10px #00000029 !important",
            position: "static",
            borderRadius: "0 10px 10px 0",
            minWidth: "30px",
            width: "30px",
            height: "45px",
          }}
          color="filterButtons"
          onClick={() => {
            setLeftExpand((prevState) => !prevState);
            setRightExpand((prevState) => !prevState);
            dispatch(setFilterExpanded(!filterExpanded));
          }}
        >
          {leftExpanded ? <LeftArrow /> : <RightArrow />}
        </Button>
        {leftExpanded && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              height: {
                xs: `calc(100vh - 95px)`,
                sm: `calc(100vh - 120px )`,
                md: `calc(100vh - 120px)`,
                lg: `calc(100vh - 135px)`,
                xl: `calc(100vh - 155px)`,
                xxl: "calc(100vh - 175px)",
              },
              width: `${width < 768 && "135px"}`,
              paddingTop: `${width < 768 && "10px"}`,
              background: `${width > 0 && width < 768 && "#EBECF3"}`,
              boxShadow: `${
                width > 0 && width < 768 && "0px 5px 10px #00000029"
              }`,
              opacity: `${width < 768 && "1"}`,
              borderRadius: `${width < 768 && "10px"}`,
              overflowX: "hidden",
              overflowY: leftExpanded ? "scroll" : "unset",
            }}
          >
            <Box
              sx={{
                // maxWidth: `${width < 768 && "150px"}`,
                maxWidth: "125px",
              }}
            >
              <ButtonPanel
                selected={selectedAllStages?.current}
                panelData={allStages}
                side="left"
                color={"filterButtons"}
                onChangeFilter={jobsFilter}
              />
            </Box>
          </Box>
        )}
      </Box>

      <Box
        item
        sx={{
          display: "flex",
          flexGrow: 1,
          alignItems: "center",
          flexDirection: "column",
          width: `calc(100vw - 255px)`,
          position: "sticky",
        }}
        ref={gridRef}
        className="grigBox"
      >
        <InfiniteScroll
          key={""}
          scrollThreshold={"100px"}
          height={calculatedHeight()}
          dataLength={promoterJobs?.length}
          next={() => getJobList(lastKey)}
          hasMore={true}
          style={{
            overflowX: "hidden",
          }}
          endMessage={
            <p style={{ textAlign: "center" }}>
              <b>Yay! You have seen it all</b>
            </p>
          }
        >
          <Grid
            container
            // gap={`40px ${newPadding}px`}
            gap={width < 768 ? "10px" : `${newPadding}px`}
            columns={{
              xs: 1,
              md: 1,
              lg: 3,
              mlg: 3.5,
              xl: 4,
              xxl: 6,
              xxxl: 8,
            }}
            justifyContent={width < 768 && "center"}
            sx={{
              position: "relative",
              padding: width < 768 ? "10px 10px" : `0 ${newPadding}px`,
              top: { xs: "45px", tablet: "55px" },
            }}
          >
            {promoterJobs?.length > 0
              ? promoterJobs?.map((talent, index) => (
                  <Box
                    sx={{
                      width: newCardWidth,
                      height: "560px",
                    }}
                  >
                    <SharedJobsCard
                      cardWidth={newCardWidth}
                      index={index}
                      job={talent}
                      getJobs={getJobList}
                    />
                  </Box>
                ))
              : showBox && (
                  <Box
                    sx={{
                      margin: "auto",
                    }}
                  >
                    <NoJobs
                      text1={"It doesn't look like you've"}
                      text2={"shared any jobs yet."}
                    />
                  </Box>
                )}
          </Grid>
          <style>
            {`.infinite-scroll-component::-webkit-scrollbar {
                  display:none
                }
                .infinite-scroll-component__outerdiv {
                  width: 100%
                }`}
          </style>
        </InfiniteScroll>
      </Box>

      <Box
        item
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: `${width >= 768 && "10px"}`,
          direction: "rtl",
          position: `${width < 768 ? "absolute" : "relative"}`,
          zIndex: "30",
          // width: rightExpand && "150px",
          right: "0",
          // width: leftExpanded
          //   ? { xs: "150px", mdl: "123px", mlg: "150px", xl: "150px" }
          //   : "30px",
          width: rightExpand ? "125px" : "30px",
          minWidth: rightExpand ? "125px" : "30px",
        }}
      >
        <Button
          variant="contained"
          sx={{
            position: "sticky",
            top: 0,
            borderRadius: "10px 0 0 10px",
            minWidth: "30px",
            width: "30px",
            height: "45px",
          }}
          color="filterButtons"
          onClick={() => {
            setLeftExpand((prevState) => !prevState);
            setRightExpand((prevState) => !prevState);
            dispatch(setFilterExpanded(!filterExpanded));
          }}
        >
          {rightExpand ? <RightArrow /> : <LeftArrow />}
        </Button>
        {rightExpand && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: `${width < 768 && "135px"}`,
              minWidth: "125px",
              paddingTop: `${width < 768 && "10px"}`,
              // paddingLeft: `${width < 768 && "10px"}`,
              background: `${width > 0 && width < 768 && "#EBECF3"}`,
              boxShadow: `${
                width > 0 && width < 768 && "0px 5px 10px #00000029"
              }`,
              opacity: `${width < 768 && "1"}`,
              borderRadius: `${width < 768 && "10px"}`,
              height: {
                xs: `calc(100vh - 95px)`,
                sm: `calc(100vh - 120px )`,
                md: `calc(100vh - 120px)`,
                lg: `calc(100vh - 135px)`,
                xl: `calc(100vh - 155px)`,
                xxl: "calc(100vh - 175px )",
              },
              overflowY: rightExpand ? "scroll" : "unset",
            }}
            className="rightfilterSec"
          >
            <style>
              {`.rightfilterSec::-webkit-scrollbar {
                  display:none;
                }
                `}
            </style>
            <Paper
              sx={{
                background: "transparent",
                boxShadow: 0,
                gap: "10px",
                display: "flex",
                flexDirection: "column",
                // maxWidth: `${width < 768 && "150px"}`,
                maxWidth: "125px",
              }}
            >
              <RightFiltersPanel
                selected={selectedJobStatus?.current}
                panelData={jobStatus}
                color="filterButtons"
                baseColor="filterButtons"
                side="right"
                onChangeFilter={jobsFilterLeft}
              />
            </Paper>
          </Box>
        )}
      </Box>
    </Box>
  );
}
