import { Box, Button, useTheme } from "@mui/material";
import React from "react";
import profile_challenger from "../../../assets/Personality Logos/personality_characters_challenger.svg";
import profile_character from "../../../assets/Personality Logos/crayon_personality_character.svg";
import profile_collaborator from "../../../assets/Personality Logos/crayon_personality_collaborator.svg";
import profile_contemplator from "../../../assets/Personality Logos/crayon_personality_contemplator.svg";
import grey_profile_collaborator from "../../../assets/Personality Logos/crayon_personality_grey_collaborator.svg";
import grey_profile_contemplator from "../../../assets/Personality Logos/crayon_personality_grey_contemplator.svg";
import MUIRadialChart from "../../common/MUIRadialChart";
import leftIcon from "../../../assets/Black_Left_Previous - Copy.svg";
import rightIcon from "../../../assets/Black_Right_Next - Copy.svg";
import useViewport from "../../common/ViewPort";
import Slider2 from "../../common/Slider2";
import { Link, useNavigate } from "react-router-dom";
import takeQuiz from "../../../assets/TakeQuizIcon.svg";

export default function EmployerPersonalityFrontCard({
  profileSteps,
  employerData,
  chartDetails,
  setisHorizontalFlipped,
  handleLeftClick,
  arrSlider2,
  handleRightClick,
  calculateHeight,
}) {
  const theme = useTheme();
  const { width } = useViewport();
  const navigate = useNavigate();
  return (
    <>
      <Box
        sx={{
          height: "225px",
          // width: { xs: "360px", lg: "750px", xl: "750px" },
          background: "#ffff",
          boxShadow: "0px 5px 10px #00000029",
          borderRadius: "25px",
          flex: "1",
          position: "relative",
        }}
      >
        <Box>
          <Button
            variant="contained"
            color="boxTitleButton"
            sx={{
              height: "45px",
              minWidth: "125px",
              maxWidth: "125px",
              fontSize: "14px",
              padding: "0px",
              borderRadius: "25px 0px 10px 0px",
              cursor: "default",
              ":hover": {
                boxShadow: "none !important",
              },
            }}
          >
            my personality
          </Button>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", xl: "row" },
            marginTop: { xs: "20px", tablet: "8px" },
          }}
        >
          <Box
            sx={{
              display: { xs: "flex" },
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
              gap: "18px",
              // width: {
              //   xs360: "90%",
              //   xs390: "87%",
              //   sm: "100%",
              //   lgmm: "50%",
              //   lg: "100% !important",
              // },
              // minWidth: {
              //   xs360: "90%",
              //   xs390: "87%",
              //   sm: "100%",
              //   lgmm: "50%",
              //   lg: "100% !important",
              // },
              width: "100%",
              boxSizing: "border-box",
              // display: { xs: "flex" },
              // alignItems: "center",
              // justifyContent: "center",
              // flexDirection: "column",
              // gap: "18px",
              // marginTop: { xs: "20px", tablet: "0px" },
              // width: {
              //   xs360: "100%",
              //   xs390: "96%",
              //   sm: "100%",
              //   lgmm: "50%",
              //   lg: "100% !important",
              // },
              // minWidth: {
              //   xs360: "100%",
              //   xs390: "96%",
              //   sm: "100%",
              //   lgmm: "50%",
              //   lg: "100% !important",
              // },
              // boxSizing: "border-box",
            }}
          >
            {profileSteps !== 2 && (
              <>
                {employerData?.primary === null &&
                employerData?.shadow === null ? (
                  <Box
                    sx={
                      width < 768
                        ? {
                            display: "flex",
                            alignItems: "center",
                            width: "90%",
                          }
                        : { display: "flex", alignItems: "center" }
                    }
                  >
                    {width < 768 && (
                      <Box
                        component={"img"}
                        src={leftIcon}
                        sx={{
                          height: "20px",
                          width: "20px",
                          cursor: "pointer",
                        }}
                        onClick={handleLeftClick}
                      />
                    )}
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: {
                          sm: "3px",
                          tb: "30px",
                          lgmm: "10px",
                          mlg: "47px",
                          xl: "47px",
                          xll: "47px",
                        },
                        justifyContent: "space-around",
                        overflow: "hidden",
                        width: "100%",
                      }}
                    >
                      {arrSlider2?.map((item) => {
                        return (
                          <Box
                            component="img"
                            height={{
                              xs: 90,
                              tb: 130,
                              md24: 125,
                              lgmm: 110,
                              mlg: 125,
                              xl: 125,
                              xxl: 150,
                            }}
                            alt="Primary personality"
                            src={item}
                          />
                        );
                      })}
                    </Box>
                    {width < 768 && (
                      <Box
                        component={"img"}
                        src={rightIcon}
                        sx={{
                          height: "20px",
                          width: "20px",
                          cursor: "pointer",
                        }}
                        onClick={handleRightClick}
                      />
                    )}
                  </Box>
                ) : (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: {
                        xs: "center",
                        tablet: "flex-start",
                        md: "center",
                      },
                      gap: {
                        sm: "3px",
                        tb: "30px",
                        lgmm: "10px",
                        mlg: "47px",
                        xl: "47px",
                        xll: "47px",
                      },
                      width: { xs: "100%", tablet: "71%", md: "100%" },
                    }}
                  >
                    <Box
                      component="img"
                      height={calculateHeight()}
                      alt="primaryName"
                      src={
                        (employerData?.primary?.name === "collaborator" &&
                          profile_collaborator) ||
                        (employerData?.primary?.name === "challenger" &&
                          profile_challenger) ||
                        (employerData?.primary?.name === "character" &&
                          profile_character) ||
                        (employerData?.primary?.name === "contemplator" &&
                          profile_contemplator) ||
                        grey_profile_collaborator
                      }
                    />
                    <Box
                      component="img"
                      height={calculateHeight()}
                      alt="shadowName"
                      src={
                        (employerData?.shadow?.name === "collaborator" &&
                          profile_collaborator) ||
                        (employerData?.shadow?.name === "challenger" &&
                          profile_challenger) ||
                        (employerData?.shadow?.name === "character" &&
                          profile_character) ||
                        (employerData?.shadow?.name === "contemplator" &&
                          profile_contemplator) ||
                        grey_profile_contemplator
                      }
                    />
                    <MUIRadialChart
                      value={employerData?.grit_score}
                      chartName={"grit score"}
                      max={100}
                      size={calculateHeight()}
                      countFontSize={20}
                      countFontWeight={theme.typography.Bold}
                      labelFontSize={12}
                      labelFontWeight={theme.typography.Regular}
                      color={"grit_score"}
                    />
                  </Box>
                )}
              </>
            )}
          </Box>
        </Box>

        {/* COMPANY TRAITS */}
        {/* <Box sx={{ display: "flex", position: "relative" }}>
          <Box
            sx={{
              display: "flex",
              gap: "5px",
              flexWrap: "wrap",
              // padding: "0 15px 15px 15px",
            }}
          >
            {employerData?.company_culture?.length > 0 && (
              <Box
                sx={{
                  display: "flex",
                  gap: "5px",
                  marginTop: { xs: "0px", lg: "10px" },
                  width: {
                    xs: "320px",
                    xs390: "340px",
                    md24: "420px",
                    lg: "240px",
                    lgm: "440px",
                  },
                }}
              >
                <Slider2
                  items={employerData?.company_culture || []}
                  color={"industriesButton"}
                  hideTagsAfter={2}
                  slidertype={"company"}
                />
              </Box>
            )}
          </Box>
        </Box> */}
        {/* <Link
          to={
            "https://docs.google.com/forms/d/e/1FAIpQLScKTA3IfBUw8xvdP03-hMFX1UObzO_pPcd5p61OsKAf3whbrg/viewform"
          }
          target="_blank"
        > */}
        <Button
          variant="contained"
          color="precium"
          sx={{
            position: "absolute",
            right: "0",
            bottom: "0",
            height: "45px",
            width: "125px",
            fontSize: "12px",
            padding: "6px 6px",
            borderRadius: "10px 0px 25px 0px",
            ".MuiButton-startIcon": {
              marginRight: "4px !important",
              marginLeft: 0,
            },
            ":hover": {
              boxShadow: "none !important",
            },
          }}
          startIcon={
            <Box component={"img"} src={takeQuiz} height={20} width={20} />
          }
          onClick={() => navigate("/assessment")}
        >
          take the quiz
        </Button>
        {/* </Link> */}
      </Box>
    </>
  );
}
