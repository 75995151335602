import { useState, useRef, useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import locale from "../../../i18n/locale";
import {
  uploadCompanyLogo,
  uploadProfilePic,
} from "../../../redux/candidate/myProfileSlice";
import { setAlert, setLoading } from "../../../redux/configSlice";

import talentIcon from "../../../assets/Padding Excluded/TalentIcon.svg";
import BoostIcon from "../../../assets/Padding Excluded/BoostProfileIcon.svg";
import inviteIcon from "../../../assets/Padding Excluded/Crayon Icon_Promote.svg";
import PreviewIcon from "../../../assets/Padding Excluded/PreviewIcon.svg";
import settingIcon from "../../../assets/Padding Excluded/White_Settings.svg";
import BizSettingIcon from "../../../assets/Padding Excluded/BizSettingIcon.svg";
import LeftArrow from "../../common/LeftArrow";
import RightArrow from "../../common/RightArrow";

import {
  ALERT_TYPE,
  COMP_INFO,
  EMPLOYER_MYPROFILE_LEFTPANEL_BUTTONS,
  PROFILE_INFO,
} from "../../../utils/Constants";
import { useTheme } from "@emotion/react";
import Info from "../../common/myProfileSection/Info.js";
import CompanyInfo from "../../common/myProfileSection/CompanyInfo.js";
import {
  getEmpProfile,
  getCompanies,
  getEmpBadges,
  getSecondaryCompanyData,
  setProfileSteps,
} from "../../../redux/employer/empProfileSlice";
import { Dialog, Grid, Paper, Popover } from "@mui/material";
import ButtonPanel from "../../common/myProfileSection/ButtonPanel.js";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

import OpenCameraDialog from "../../common/ProfilePicCaptureDialog";
import { nanoid } from "@reduxjs/toolkit";
import grey_profile_collaborator from "../../../assets/Personality Logos/crayon_personality_grey_collaborator.svg";
import grey_profile_contemplator from "../../../assets/Personality Logos/crayon_personality_grey_contemplator.svg";
import grey_profile_challenger from "../../../assets/Personality Logos/crayon_personality_grey_challenger.svg";
import grey_profile_character from "../../../assets/Personality Logos/crayon_personality_grey_character.svg";

import AddCompany from "../../common/myProfileSection/AddCompany.js";
import { getIndustries } from "../../../redux/candidate/myCvSlice.js";
import { getProfilePercentage } from "../../../redux/employer/empJobListing.js";
import Badges from "../../recruiter/common/Badges.js";
import CropModal from "./cropModal.js";
import SubscriptionPopModal from "../../guest/talent/SubscriptionPopModal.js";
import { getDecodedToken, getToken } from "../../../utils/Common.js";
import { getCountry } from "../../../redux/employer/postJobSlice.js";
import MyPlans from "../../common/myProfileSection/MyPlan/MyPlans.js";
import useViewport from "../../common/ViewPort.js";
import EmployerProfileCardRow from "./EmployerProfileCardRow.js";
import { setFilterExpanded } from "../../../redux/guest/jobsSlice.js";
import EmployerBizSettingPortal from "./EmployerBizSettingPortal.js";
import GoDark from "../../candidate/myProfile/dialog/GoDark.js";
import DeleteProfile from "../../candidate/myProfile/dialog/DeleteProfile.js";
import EmployerSettingPortal from "./EmployerSettingPortal.js";
import ResetPassword from "../../candidate/myProfile/dialog/ResetPassword.js";
import BillingComponent from "../../common/myProfileSection/MyPlan/BillingComponent.js";

export default function ProfileCard() {
  const i18n = locale.en;
  const theme = useTheme();
  const dispatch = useDispatch();
  const hiddenFileInput = useRef(null);
  const navigate = useNavigate();
  const { width } = useViewport();
  const [settingButton, setSettingButton] = useState(null);
  const [bizSettingButton, setBizSettingButton] = useState(null);
  const [goDark, setGoDark] = useState(null);
  const bizSettingsRef = useRef(null);
  const settingsRef = useRef(null);
  const [deleteProfile, setDeleteProfile] = useState(null);

  const filterExpanded = useSelector(
    (state) => state?.configjobslice?.filterExpanded
  );
  const profileSteps = useSelector((state) => state?.myProfile?.profileSteps);
  const decodedToken = getDecodedToken(getToken());
  const [isSubscriptionModal, setIsSubscriptionModal] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [currentComp, setCurrentComp] = useState("info");
  const [imageName, setImageName] = useState("My pic");
  const [info, setInfo] = useState(PROFILE_INFO);
  const [compInfo, setCompInfo] = useState(COMP_INFO);
  const [employerData, setEmployerData] = useState({});
  const [showMyCompany, setShowMyCompany] = useState(false);
  const [openEditImage, setOpenEditImage] = useState(false);
  const [imagePreview, setImagePreview] = useState([]);
  const [zoom, setZoom] = useState(1);
  const [resetPasswordButton, setResetPasswordButton] = useState(null);
  const [errors, setErrors] = useState([]);
  const [progressButton, setProgressButton] = useState(false);
  const [leftExpanded, setLeftExpand] = useState(true);
  const [rightExpanded, setRightExpanded] = useState(true);
  const [profileCompletion, setProfileCompletion] = useState(0);
  const [isApproved, setIsApproved] = useState(false);
  const [companyDetail, setCompanyDetail] = useState([
    {
      TotalJobCount: "",
      ActiveJobCount: "",
      TotalUserCount: "",
      TotalUserShortlisted: "",
      TotalUserInterviewed: "",
      TotalUserHired: "",
    },
  ]);
  const [openCameraDialog, setOpenCameraDialog] = useState(false);
  const [badgesData, setBadgesData] = useState({});
  const [chartDetails, setChartAttributes] = useState({
    height: 125,
    valuefontSize: "20px",
    labelFontSize: "12px",
  });
  const [arrSlider2, setArrSlider2] = useState([
    grey_profile_contemplator,
    grey_profile_collaborator,
    grey_profile_character,
    grey_profile_challenger,
  ]);

  const [editable, setEditable] = useState(false);

  const { industries } = useSelector((state) => state?.myCv);
  const { companies } = useSelector((state) => state?.myProfile);
  const { country, town } = useSelector((state) => state?.postJobs);

  const [image, setImage] = useState();
  const [croppedImageUrl, setCroppedImageUrl] = useState(null);
  const [imageBlob, setImageBlob] = useState();

  useEffect(() => {
    if (width > 1024) {
      setLeftExpand(true);
      setRightExpanded(true);
    } else {
      setLeftExpand(false);
      setRightExpanded(false);
    }
  }, [width]);

  const getSecondaryCompanies = async () => {
    try {
      const { payload } = await dispatch(getSecondaryCompanyData());
      if (payload?.status === "success") {
        setShowMyCompany(payload?.data?.length > 0 ? true : false);
      }
    } catch (error) {}
  };

  const getAllData = async () => {
    try {
      let payload = {
        limit: 5,
      };
      dispatch(setLoading(true));
      await dispatch(getCompanies(payload));
      await dispatch(getIndustries());
      await dispatch(getCountry());
      // await Promise.all([
      //   dispatch(getCompanies(payload)),
      //   dispatch(getIndustries()),
      //   dispatch(getCountry()),
      // ]);
      dispatch(setLoading(false));
    } catch (error) {
      dispatch(setLoading(false));
      // dispatch(
      //   setAlert({
      //     show: true,
      //     type: ALERT_TYPE?.ERROR,
      //     msg: ERROR_MSG,
      //   })
      // );
    }
  };

  const handleImageClick = (e) => {
    setImagePreview(null);
    hiddenFileInput?.current?.click();
  };
  const handleImageChange = async (event, name) => {
    if (event?.target?.files?.[0] === undefined) {
      return;
    }
    cropInputFUnction(event);
    setZoom(1);
    setImageName(event?.target?.files?.[0].name);
    const imageData = window.URL.createObjectURL(
      new Blob(event?.target?.files, { type: "application/*" })
    );
    setImagePreview(imageData);
    setOpenEditImage(true);
    event.target.value = "";
  };

  const cropInputFUnction = (e) => {
    const files = e.target.files;
    if (files && files.length > 0) {
      const reader = new FileReader();
      reader.onload = () => setImage(reader.result);
      reader.readAsDataURL(files[0]);
    }
  };
  const handleSubscriptionCancel = () => {
    setOpenEditImage(false);
    setImageBlob();
    setCroppedImageUrl(null);
    setImage();
  };
  const handleSubscriptionOk = async () => {
    const formData = new FormData();
    const blobTofile = new File([imageBlob], imageName, {
      type: "image/jpeg",
    });

    formData.append("profile-pic", blobTofile);

    try {
      const { payload } = await dispatch(uploadProfilePic(formData));
      if (payload?.status === "success") {
        setEmployerData({ ...employerData, profileURL: payload?.data });
        getEmpBadgesData();
        setOpenEditImage(false);
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE?.SUCCESS,
            msg: "Profile pic uploaded Successfully!",
          })
        );
        setImageBlob();
        setCroppedImageUrl(null);
        setImage();
      } else if (payload?.status === "error") {
        setImageName("My pic");
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE?.ERROR,
            msg: "Image format not supported",
          })
        );
      }
    } catch (error) {
      setImageName("My pic");
      dispatch(
        setAlert({
          show: true,
          type: ALERT_TYPE?.ERROR,
          msg: "",
        })
      );
    }
  };

  const handleCompanyLogo = async () => {
    const formData = new FormData();
    const blobTofile = new File([imageBlob], imageName, {
      type: "image/jpeg",
    });
    formData.append("company-logo", blobTofile);
    try {
      const { payload } = await dispatch(uploadCompanyLogo(formData));
      if (payload?.status === "success") {
        setCompInfo({
          ...compInfo,
          companyURL: payload?.data,
          logo: payload?.logo,
        });
        setOpenEditImage(false);
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE?.SUCCESS,
            msg: "Company logo uploaded Successfully!",
          })
        );
      } else if (payload?.status === "error") {
        setImageName("My pic");
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE?.ERROR,
            msg: "Image format not supported",
          })
        );
      }
    } catch (error) {
      setImageName("My pic");
      // dispatch(setAlert({ show: true }));
    }
  };

  const getCompanyInfoData = (compInfoData) => {
    setCompInfo(compInfoData);
  };

  const getEmpBadgesData = async () => {
    const { payload } = await dispatch(getEmpBadges());
    setBadgesData(payload?.data);
  };

  const getProfilePercentageData = async () => {
    const { payload } = await dispatch(getProfilePercentage());
  };

  const getAllEmpData = async () => {
    try {
      const { payload } = await dispatch(getEmpProfile());
      if (payload?.status === "success") {
        getProfilePercentageData();
        setEmployerData(payload?.data);
        setIsApproved(payload?.data?.company_approved);
        setInfo({
          first_name: payload?.data?.user?.first_name,
          surname: payload?.data?.user?.surname,
          email: payload?.data?.user?.email,
          contact_no: payload?.data?.contact_no,
          gender: payload?.data?.gender,
          town_id: payload?.data?.town_id,
          dob: payload?.data?.dob,
          linkedin_profile_link: payload?.data?.linkedin_profile_link
            ? payload?.data?.linkedin_profile_link
            : "",
          country_name: payload?.data?.country_name,
          country_code: payload?.data?.country_code,
          country_id: payload?.data?.country_id,
          town_name: payload?.data?.town_name,
          gender_flag: payload?.data?.gender_flag,
          calendar_link: payload?.data?.calendar_link,
        });
        setCompInfo({
          ...compInfo,
          companyURL:
            payload?.data?.companyLogoURL === "NO URL"
              ? []
              : payload?.data?.companyLogoURL,
          logo: payload?.data?.logo,
        });
        setProfileCompletion(payload.data?.profile_completion_percentage);
      }
    } catch (error) {
      // dispatch(setAlert({ show: true }));
    }
  };

  const handleNextStep = (stepNumber) => {
    dispatch(setProfileSteps(stepNumber));
    getAllEmpData();
  };

  const handleInfoSave = async () => {
    dispatch(setProfileSteps(0));
    await getAllEmpData();
  };

  const handleClickInvite = () => {
    navigate("/employer/my-team/add-new-member", {
      state: { companyname: compInfo?.company_name },
    });
  };

  const func = (prop) => {
    dispatch(setProfileSteps(prop));
    if (prop === 0) {
      setExpanded(false);
    }
    if (prop === 1) {
      setCurrentComp("info");
      setExpanded(true);
    } else if (prop === 2) {
      setCurrentComp("companyInfo");
      setExpanded(true);
    }
  };
  const boxRef = useRef();
  const handleClickOutside = (event) => {
    if (boxRef?.current && !boxRef?.current.contains(event?.target)) {
      setProgressButton(false);
    }
  };

  const calculateHeight = () => {
    const screenWidth = window?.innerWidth;
    if (screenWidth < 600) {
      // xs
      return  100;
    } else if (screenWidth < 900) {
      // sm
      return 125;
    } else if (screenWidth < 1366) {
      // md
      return 125;
    } else if (screenWidth < 1650) {
      return 125;
    } else if (screenWidth < 1785) {
      return 125;
    } else if (screenWidth < 1920) {
      // lg
      return 125;
    } else if (screenWidth < 2559) {
      // xl
      return 125;
    } else {
      // xxl or greater
      return 125;
    }
  };

  const calculateChartHeight = () => {
    const screenWidth = window.innerWidth;

    if (screenWidth < 600) {
      return 100;
    } else if (screenWidth < 900) {
      return 125;
    } else if (screenWidth < 1366) {
      // md
      return 125;
    } else if (screenWidth < 1650) {
      return 125;
    } else if (screenWidth < 1785) {
      return 125;
    } else if (screenWidth < 1920) {
      return 125;
    } else if (screenWidth < 2559) {
      return 125;
    } else {
      return 125;
    }
  };

  const calculateValueFontSize = () => {
    const screenWidth = window?.innerWidth;

    if (screenWidth < 600) {
      // xs
      return 16;
    } else if (screenWidth < 900) {
      // sm
      return 16;
    } else if (screenWidth < 1366) {
      // md
      return 16;
    } else if (screenWidth < 1920) {
      // lg
      return 18;
    } else if (screenWidth < 2559) {
      // xl
      return 20;
    } else {
      // xxl or greater
      return 22;
    }
  };
  const calculatelabelFontSize = () => {
    const screenWidth = window?.innerWidth;

    if (screenWidth < 600) {
      // xs
      return "9px";
    } else if (screenWidth < 900) {
      // sm
      return "9px";
    } else if (screenWidth < 1366) {
      // md
      return "9px";
    } else if (screenWidth < 1920) {
      // lg
      return "5px";
    } else if (screenWidth < 2559) {
      // xl
      return 11;
    } else {
      // xxl or greater
      return 12;
    }
  };

  const handleRightClick = () => {
    setArrSlider2([...arrSlider2?.slice(1), ...arrSlider2?.slice(0, 1)]);
  };
  const handleLeftClick = () => {
    setArrSlider2([
      ...arrSlider2?.slice(arrSlider2?.length - 1),
      ...arrSlider2?.slice(0, arrSlider2?.length - 1),
    ]);
  };

  const handleResizeFunction = () => {
    const newChartDetails = {
      height: calculateHeight(),
      valuefontSize: calculateValueFontSize(),
      lablefontSize: calculatelabelFontSize(),
    };
    setChartAttributes(newChartDetails);
  };

  const handleImageData = (data) => {
    const id = nanoid();
    const base64Image = data?.replace(/^data:image\/jpeg;base64,/, "");
    setImage(data);
    const binaryData = atob(base64Image);

    const blob = new Blob(
      [new Uint8Array([...binaryData]?.map((char) => char?.charCodeAt(0)))],
      { type: "application/*" }
    );
    setImageName(id + ".jpeg");
    const imageData = window.URL.createObjectURL(blob);
    setZoom(1);
    setImagePreview(imageData);
    setOpenEditImage(true);
  };

  const handlePhotoCapture = () => {
    setOpenCameraDialog((prevState) => !prevState);
  };

  useEffect(() => {
    getAllEmpData();
    profileSteps === 0 && getEmpBadgesData();
  }, [profileSteps]);

  useEffect(() => {
    getSecondaryCompanies();
    getAllData();
    handleResizeFunction();
  }, []);

  useEffect(() => {
    window.addEventListener("resize", handleResizeFunction);

    return () => {
      window.removeEventListener("resize", handleResizeFunction);
    };
  });
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  if (width === 0) {
    return null;
  }

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Box
          item
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: `${width >= 768 && "10px"}`,
            position: `${width < 1024 ? "absolute" : "relative"}`,
            width: leftExpanded ? "125px" : "30px",
            zIndex: "30",
          }}
        >
          <Button
            variant="contained"
            sx={{
              position: "static",
              borderRadius: "0 10px 10px 0",
              minWidth: "30px",
              width: "30px",
              height: "45px",
              boxShadow: "0px 5px 10px #00000029",
              padding: "0px",
            }}
            color="tealColor"
            onClick={() => {
              setLeftExpand((prevState) => !prevState);
              setRightExpanded((prevState) => !prevState);
              dispatch(setFilterExpanded(!filterExpanded));
            }}
          >
            {leftExpanded ? <LeftArrow /> : <RightArrow />}
          </Button>
          {leftExpanded && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "10px",
                width: `${(width < 768 && width > 0) && "135px"}`,
                paddingTop: `${(width < 768 && width > 0)&& "10px"}`,
                background: `${width > 0 && width < 768 && "#EBECF3"}`,
                boxShadow: `${
                  width > 0 && width < 768 && "0px 5px 10px #00000029"
                }`,
                opacity: `${(width < 768 && width > 0) && "1"}`,
                borderRadius: `${(width < 768 && width > 0) && "10px"}`,
                paddingBottom: `${(width < 768 && width > 0) && "10px"}`,
              }}
              className="filterSec"
            >
              <Paper
                sx={{
                  background: "transparent",
                  // marginRight: "1px",
                  boxShadow: 0,
                  // maxWidth: `${width < 768 && "150px"}`,
                  maxWidth: "125px",
                }}
              >
                <ButtonPanel
                  activeButton={profileSteps}
                  onClickFunc={func}
                  side={"left"}
                  color={"tealColor"}
                  panelData={
                    showMyCompany
                      ? EMPLOYER_MYPROFILE_LEFTPANEL_BUTTONS
                      : EMPLOYER_MYPROFILE_LEFTPANEL_BUTTONS?.filter(
                          (item) => item?.id !== 3
                        )
                  }
                />
              </Paper>
            </Box>
          )}
        </Box>
        <Box
          sx={{
            height: {
              xs: `calc(100vh - 70px)`,
              sm: `calc(100vh - 70px )`,
              md: `calc(100vh - 70px)`,
              lg: `calc(100vh - 80px)`,
              xl: `calc(100vh - 100px)`,
              xxl: "calc(100vh - 120px)",
            },
            display: "flex",
            flexGrow: 1,
            alignItems: "center",
            flexDirection: "column",
            width: `calc(100vw - 255px)`,
            position: "sticky",
            overflowY: "scroll",
          }}
        >
          {profileSteps !== 3 && profileSteps !== 4 && profileSteps !== 5 ? (
            <Grid
              sx={{
                display: "flex",
                alignItems: { xs: "center", xl: "flex-start" },
                position: "relative",
                height: "auto",
                flexDirection: "column",
                marginTop: { xs: "55px", md24: "55px" },
                marginLeft: { xs: "10px", lg: "10px", xl: "35px" },
                marginRight: { xs: "10px", lg: "10px", xl: "35px" },
                width: { xs: "90%", md24: "96.5%" },
                gap: { xs: "0px", lg: "30px" },
                zIndex: "-9999",
              }}
            >
              <EmployerProfileCardRow
                profileSteps={profileSteps}
                compInfo={compInfo}
                employerData={employerData}
                companies={companies}
                handlePhotoCapture={handlePhotoCapture}
                hiddenFileInput={hiddenFileInput}
                setImagePreview={setImagePreview}
                handleImageChange={handleImageChange}
                editable={editable}
                handleImageClick={handleImageClick}
                chartDetails={chartDetails}
                handleLeftClick={handleLeftClick}
                arrSlider2={arrSlider2}
                handleRightClick={handleRightClick}
                companyDetail={companyDetail}
                calculateChartHeight={calculateChartHeight}
              />

              {profileSteps === 0 && (
                <Box
                  sx={{
                    top: { xs: "70px", lg: "30px" },
                    padding: "0px !important",
                    cursor: "auto !important",
                    boxShadow: "0px 5px 10px #00000029",
                    borderRadius: "25px",
                    marginBottom: "0px !important",
                    position: "relative",
                    background: "#ffff",
                    display: "flex",
                    flexDirection: `${width > 1024 ? "row" : "column"}`,
                    gap: "10px",
                    marginX: { xs: "10px", lg: "0px" },
                  }}
                  width={"100%"}
                >
                  <Badges badgesData={badgesData} />
                </Box>
              )}
              <Box
                sx={{
                  marginTop: { xs: "70px", lg: "30px" },
                  padding: "30px 30px 0px 30px !important",
                  cursor: "auto !important",
                  boxShadow: "0px 5px 10px #00000029",
                  borderRadius: "25px",
                  marginBottom: "30px !important",
                  position: "relative",
                  background: "#ffff",
                  display: profileSteps === 0 ? "none" : "block",
                  width: { xs: "90%", md24: "96.5%" },
                }}
              >
                {profileSteps === 1 && (
                  <Info
                    profile={info}
                    errors={errors}
                    setErrors={setErrors}
                    handleNextStep={handleNextStep}
                    handleInfoSave={handleInfoSave}
                    setEmployerData={setEmployerData}
                    employerData={employerData}
                  />
                )}
                {profileSteps === 2 && (
                  <CompanyInfo
                    getSecondaryCompanies={getSecondaryCompanies}
                    setShowMyCompany={setShowMyCompany}
                    editable={editable}
                    setEditable={setEditable}
                    companyID={employerData?.company_id}
                    profile2={compInfo}
                    handleCompanyInfoData={getCompanyInfoData}
                    errors={errors}
                    setErrors={setErrors}
                    companies={companies}
                    industries={industries}
                    employerData={employerData}
                    setEmployerData={setEmployerData}
                    setCompanyDetail={setCompanyDetail}
                    companyurl={compInfo?.companyURL}
                    handleNextStep={handleNextStep}
                    handleInfoSave={handleInfoSave}
                    getAllEmpData={getAllEmpData}
                    country={country}
                    town={town}
                    setCompInfo={setCompInfo}
                    isApproved={isApproved}
                    setIsApproved={setIsApproved}
                  />
                )}
              </Box>
            </Grid>
          ) : (
            <Box
              // width={
              //   `calc(100vw - 313px)`
              //   // (profileSteps === 4 || profileSteps === 5)
              //   //   ? `calc(100vw - 313px)`
              //   //   : `calc(100vw - 360px)`
              // }
              width={"100%"}
              paddingX="30px"
              marginTop={"55px"}
              height="100%"
              boxSizing={"border-box"}
            >
              {profileSteps === 3 ? (
                <AddCompany companies={companies} />
              ) : profileSteps === 4 ? (
                <Box
                  sx={{
                    height: "100%",
                  }}
                >
                  <MyPlans />
                </Box>
              ) : (
                <Box
                sx={{
                  // marginTop: { xs: "70px", lg: "30px" },
                  
                  cursor: "auto !important",
                  boxShadow: "0px 5px 10px #00000029",
                  borderRadius: "25px",
                  marginBottom: "30px !important",
                  // height: "100%",
                  background: "#FFF",
                }}
              >
                <BillingComponent/>
                </Box>
              )}
            </Box>
          )}
        </Box>

        <Box
          item
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: `${width >= 768 && "10px"}`,
            direction: "rtl",
            position: `${width < 1024 ? "absolute" : "relative"}`,
            zIndex: "30",
            right: "0",
            width: rightExpanded ? "125px" : "30px",
            minWidth: rightExpanded ? "125px" : "30px",
          }}
        >
          <Button
            variant="contained"
            sx={{
              position: "sticky",
              top: 0,
              borderRadius: "10px 0 0 10px",
              minWidth: "30px",
              width: "30px",
              height: "45px",
              padding: "0px",
            }}
            color="tealColor"
            onClick={() => {
              setLeftExpand((prevState) => !prevState);
              setRightExpanded((prevState) => !prevState);
              dispatch(setFilterExpanded(!filterExpanded));
            }}
          >
            {rightExpanded ? <RightArrow /> : <LeftArrow />}
          </Button>
          {rightExpanded && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "10px",
                height: {
                  xs: `calc(100vh - 95px)`,
                  sm: `calc(100vh - 120px )`,
                  md: `calc(100vh - 120px)`,
                  lg: `calc(100vh - 135px)`,
                  xl: `calc(100vh - 155px)`,
                  xxl: "calc(100vh - 175px )",
                },
                width: `${width < 768 && width > 0 &&"135px"}`,
                minWidth: "125px",
                paddingTop: `${width < 768 && width > 0 && "10px"}`,
                background: `${width > 0 && width < 768 && "#EBECF3"}`,
                boxShadow: `${
                  width > 0 && width < 768 && "0px 5px 10px #00000029"
                }`,
                paddingBottom: `${(width < 768 && width > 0) && "10px"}`,

                opacity: `${(width < 768 && width > 0) && "1"}`,
                borderRadius: `${(width < 768 && width > 0) && "10px"}`,
                overflowY: rightExpanded ? "scroll" : "unset",
              }}
              className="rightfilterSec"
            >
              <style>
                {`.rightfilterSec::-webkit-scrollbar {
                    display:none;
                  }
                  `}
              </style>
              <Paper
                sx={{
                  background: "transparent",
                  boxShadow: 0,
                  gap: "10px",
                  display: "flex",
                  height: "100px",
                  flexDirection: "column",
                  maxWidth: "125px",
                }}
              >
                {profileSteps !== 2 && (
                  <>
                    {/* <Link
                      to={
                        "https://docs.google.com/forms/d/e/1FAIpQLScKTA3IfBUw8xvdP03-hMFX1UObzO_pPcd5p61OsKAf3whbrg/viewform"
                      }
                      target="_blank"
                    >
                      <Button
                        variant="contained"
                        color="assessmentButton"
                        sx={{
                          fontSize: theme.typography.fontSize.sm,
                          fontWeight: theme?.typography?.Bold,
                          borderRadius: "10px 0 0 10px",
                          width: "100%",
                          height: "45px",
                          padding: "0px !important",
                          direction: "ltr",
                        }}
                      >
                        <Box
                          component={"img"}
                          src={userIcon}
                          sx={{
                            height: "20px",
                            width: "20px",
                          }}
                        />
                        <Typography
                          sx={{
                            fontSize: theme.typography.fontSize.sm,
                            fontWeight: theme.typography.Bold,
                            position: "relative",
                          }}
                        >
                          take the quiz
                        </Typography>
                      </Button>
                    </Link> */}

                    <Button
                      variant="contained"
                      color="deleteButton"
                      ref={settingsRef}
                      sx={{
                        fontSize: theme.typography.fontSize.xs,
                        fontWeight: theme?.typography?.Bold,
                        borderRadius: "10px 0 0 10px",
                        width: "100%",
                        height: "45px",
                        padding: "0px !important",
                        direction: "ltr",
                        gap: "8px",
                      }}
                      onClick={() => {
                        setSettingButton(settingsRef.current);
                      }}
                    >
                      <Box
                        component={"img"}
                        src={settingIcon}
                        sx={{
                          height: "20px",
                          width: "19px !important",
                        }}
                      />
                      <Typography
                        sx={{
                          fontSize: theme.typography.fontSize.sm,
                          fontWeight: theme.typography.Bold,
                          position: "relative",
                        }}
                      >
                        settings
                      </Typography>
                    </Button>
                  </>
                )}
                {profileSteps === 2 && (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "10px",
                    }}
                  >
                    <Button
                      variant="contained"
                      color="myTalentButton"
                      sx={{
                        fontSize: theme.typography.fontSize.xs,
                        fontWeight: theme?.typography?.Bold,
                        borderRadius: "10px 0 0 10px",
                        width: "100%",
                        height: "45px",
                        padding: "0px !important",
                        direction: "ltr",
                      }}
                      startIcon={
                        <Box
                          component={"img"}
                          src={talentIcon}
                          sx={{
                            height: "20px",
                            width: "19px !important",
                          }}
                        />
                      }
                      onClick={() => {
                        if (decodedToken?.data?.talent_plan) {
                          navigate("/admin/admin-talent/my-talent");
                        } else {
                          setIsSubscriptionModal(true);
                        }
                      }}
                    >
                      my talent (
                      {companyDetail?.myTalentCount === null ||
                      companyDetail?.myTalentCount === 0
                        ? 0
                        : companyDetail?.myTalentCount}
                      )
                    </Button>
                    <Button
                      variant="contained"
                      color="inviteButton"
                      sx={{
                        fontSize: theme.typography.fontSize.xs,
                        fontWeight: theme?.typography?.Bold,
                        borderRadius: "10px 0 0 10px",
                        width: "100%",
                        height: "45px",
                        padding: "0px !important",
                        direction: "ltr",
                      }}
                      startIcon={
                        <Box
                          component={"img"}
                          src={inviteIcon}
                          sx={{
                            height: "20px",
                            width: "19px !important",
                          }}
                        />
                      }
                      onClick={handleClickInvite}
                    >
                      share page
                    </Button>
                    <Button
                      variant="contained"
                      color="discovery"
                      sx={{
                        fontSize: theme.typography.fontSize.xs,
                        fontWeight: theme?.typography?.Bold,
                        borderRadius: "10px 0 0 10px",
                        width: "100%",
                        height: "45px",
                        padding: "0px !important",
                        direction: "ltr",
                      }}
                      startIcon={
                        <Box
                          component={"img"}
                          src={BoostIcon}
                          sx={{
                            height: "20px",
                            width: "19px !important",
                          }}
                        />
                      }
                    >
                      boost profile
                    </Button>
                    <Link
                      to={`/jobs/company/${employerData?.company_id}`}
                      target={"_blank"}
                      style={{
                        textDecoration: "none",
                        color: theme?.palette?.black,
                      }}
                    >
                      <Button
                        variant="contained"
                        color="viewProfileButton"
                        sx={{
                          fontSize: theme.typography.fontSize.xs,
                          fontWeight: theme?.typography?.Bold,
                          borderRadius: "10px 0 0 10px",
                          width: "100%",
                          height: "45px",
                          padding: "0px !important",
                          direction: "ltr",
                        }}
                        startIcon={
                          <Box
                            component={"img"}
                            src={PreviewIcon}
                            sx={{
                              height: 17,
                              width: 28,
                              padding: 0,
                              paddingRight: "5px",
                            }}
                          />
                        }
                      >
                        preview page
                      </Button>
                    </Link>
                    <Button
                      variant="contained"
                      color="deleteProfileButton"
                      ref={bizSettingsRef}
                      sx={{
                        fontSize: theme.typography.fontSize.xs,
                        fontWeight: theme?.typography?.Bold,
                        borderRadius: "10px 0 0 10px",
                        width: "100%",
                        height: "45px",
                        padding: "0px !important",
                        direction: "ltr",
                      }}
                      startIcon={
                        <Box
                          component={"img"}
                          src={BizSettingIcon}
                          sx={{
                            height: "20px",
                            width: "19px !important",
                          }}
                        />
                      }
                      onClick={() => {
                        setBizSettingButton(bizSettingsRef.current);
                      }}
                    >
                      biz settings
                    </Button>
                  </Box>
                )}
              </Paper>
            </Box>
          )}
        </Box>
      </Box>
      <Dialog open={openCameraDialog} fullWidth={true} maxWidth={"sm"}>
        <OpenCameraDialog
          onRecordingStop={setOpenCameraDialog}
          handleImage={handleImageData}
        />
      </Dialog>
      {
        <SubscriptionPopModal
          handleOpen={isSubscriptionModal}
          handleClose={() => setIsSubscriptionModal(false)}
          handleSubscriptionOk={() => setIsSubscriptionModal(false)}
        />
      }
      {openEditImage && (
        <CropModal
          handleOpen={openEditImage}
          handleClose={setOpenEditImage}
          handleSubscriptionOk={() => {
            profileSteps === 2 ? handleCompanyLogo() : handleSubscriptionOk();
          }}
          handleSubscriptionCancel={handleSubscriptionCancel}
          imagePreview={imagePreview}
          image={image}
          setImage={setImage}
          croppedImageUrl={croppedImageUrl}
          setCroppedImageUrl={setCroppedImageUrl}
          imageBlob={imageBlob}
          setImageBlob={setImageBlob}
        />
      )}
      <Popover
        id="dropdown-menu"
        // anchorEl={anchorEl}
        // open={Boolean(anchorEl)}
        // onClose={handleClose}
        open={Boolean(settingButton)}
        anchorEl={settingButton}
        onClose={() => setSettingButton(null)}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        sx={{
          "& .MuiPopover-paper": {
            borderRadius: "25px 0 25px 25px !important",
          },
        }}
      >
        <EmployerSettingPortal
          onClose={() => setSettingButton(null)}
          setResetPasswordButton={setResetPasswordButton}
          settingsRef={settingsRef}
          setGoDark={setGoDark}
          setDeleteProfile={setDeleteProfile}
        />
      </Popover>

      <Popover
        id="dropdown-menu"
        // anchorEl={anchorEl}
        // open={Boolean(anchorEl)}
        // onClose={handleClose}
        open={Boolean(bizSettingButton)}
        anchorEl={bizSettingButton}
        onClose={() => setBizSettingButton(null)}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        sx={{
          "& .MuiPopover-paper": {
            borderRadius: "25px 0 25px 25px !important",
          },
        }}
      >
        <EmployerBizSettingPortal
          onClose={() => setBizSettingButton(null)}
          settingsRef={bizSettingsRef}
          setGoDark={setGoDark}
          setDeleteProfile={setDeleteProfile}
        />
      </Popover>
      <Popover
        id="dropdown-menu"
        // anchorEl={anchorEl}
        // open={Boolean(anchorEl)}
        // onClose={handleClose}
        open={Boolean(deleteProfile)}
        anchorEl={deleteProfile}
        onClose={() => setDeleteProfile(null)}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        sx={{
          "& .MuiPopover-paper": {
            borderRadius: "25px 0 25px 25px !important",
          },
        }}
      >
        <DeleteProfile
          onClose={() => setDeleteProfile(null)}
          setSettingButton={setSettingButton}
          settingsRef={settingsRef}
        />
      </Popover>
      <Popover
        id="dropdown-menu"
        // anchorEl={anchorEl}
        // open={Boolean(anchorEl)}
        // onClose={handleClose}
        open={Boolean(deleteProfile)}
        anchorEl={deleteProfile}
        onClose={() => setDeleteProfile(null)}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        sx={{
          "& .MuiPopover-paper": {
            borderRadius: "25px 0 25px 25px !important",
          },
        }}
      >
        <DeleteProfile
          onClose={() => setDeleteProfile(null)}
          setSettingButton={setSettingButton}
          settingsRef={bizSettingsRef}
        />
      </Popover>
      <Popover
        id="dropdown-menu"
        // anchorEl={anchorEl}
        // open={Boolean(anchorEl)}
        // onClose={handleClose}
        open={Boolean(goDark)}
        anchorEl={goDark}
        onClose={() => setGoDark(null)}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        sx={{
          "& .MuiPopover-paper": {
            borderRadius: "25px 0 25px 25px !important",
          },
        }}
      >
        <GoDark
          onClose={() => setGoDark(null)}
          setSettingButton={setSettingButton}
          settingsRef={bizSettingsRef}
        />
      </Popover>

      <Popover
        id="dropdown-menu"
        // anchorEl={anchorEl}
        // open={Boolean(anchorEl)}
        // onClose={handleClose}
        open={Boolean(resetPasswordButton)}
        anchorEl={resetPasswordButton}
        onClose={() => setResetPasswordButton(null)}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        sx={{
          "& .MuiPopover-paper": {
            borderRadius: "25px 0 25px 25px !important",
          },
        }}
      >
        <ResetPassword onClose={() => setResetPasswordButton(null)} />
      </Popover>
    </>
  );
}
