import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import locale from "../../i18n/locale";
import { ALERT_TYPE, CRAYON_COINS } from "../../utils/Constants";
import InfiniteScroll from "react-infinite-scroll-component";
import { Navigate, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { getCoins, getCrayonCoinsHistory } from "../../redux/admin/maintenance";
import { useDispatch } from "react-redux";
import { setAlert } from "../../redux/configSlice";
import CloseIcon from "./CloseIcon";

export default function CrayonCoins({
  width,
  rightExpand,
  handleClose,
  setOpenNotication,
  coinsRef,
  setOpenGetCoins,
}) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const i18n = locale.en;
  const navigate = useNavigate();

  const { coinsCount, company_id } = useSelector((state) => state?.configalert);

  const [lastKey, setLastKey] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [coinsHistory, setCoinsHistory] = useState([]);

  const getCoinsHistory = async (lastKeyy) => {
    setIsLoading(true);
    try {
      const { payload } = await dispatch(
        getCrayonCoinsHistory({ lastKey: lastKeyy, company_id: company_id })
      );
      if (payload?.status === "success") {
        if (payload?.pageNumber === 0) {
          setCoinsHistory(payload?.data);
          setLastKey(payload?.pageNumber + 1);
        } else if (payload?.data?.length > 0) {
          setCoinsHistory((prev) => [...prev, ...payload?.data]);
          setLastKey(payload?.pageNumber + 1);
        }
      } else {
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE?.ERROR,
            msg: payload?.message,
          })
        );
      }
      setIsLoading(false);
    } catch (error) {
      dispatch(
        setAlert({
          show: true,
          type: ALERT_TYPE?.ERROR,
          msg: error,
        })
      );
    }
  };

  useEffect(() => {
    getCoinsHistory(0);
  }, []);

  return (
    <Box
      ref={coinsRef}
      sx={{
        display: "flex",
        flexDirection: "column",
        // justifyContent: "space-between",
        width: { xs: "360px", mddl: `${width}px` },
        height: "477px",
        background: "#ffff",
        borderRadius: "0 0 25px 25px",
        boxShadow: "0px 5px 10px #00000029 !important",
        zIndex: 999,
        position: "absolute",
        // padding: "0px 0px 0 20px",
        right: 0,
        boxSizing: "border-box",
        // marginRight: !rightExpand && "119px",
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Button
          variant="contained"
          color="getCoins"
          sx={{
            width: "175px",
            height: "45px",
            borderRadius: "0px 0px 25px 0px",
            fontSize: "14px",
            fontWeight: theme.typography.Bold,
            textAlign: "left",
            justifyContent: "center",
          }}
        >
          {i18n["myplan.crayoncoins"]}
        </Button>
        <CloseIcon onClick={() => handleClose()}/>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: { xs: "135px", mddl: `calc(${width}px - 215px)` },
          }}
        >
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: theme.typography.Bold,
            }}
          >
            1 coin = R95
          </Typography>
          {/* <IconButton
            aria-label="close"
            onClick={() => handleClose()}
            sx={{
              position: "absolute",
              right: 8,
              //   color: (theme) => theme.palette.grey[500],
            }}
            disableRipple={true}
          > */}
            {/* <Box
              sx={{
                width: "20px",
                height: "20px",
                color: "#000000",
                border: 2,
                fontSize: "18px",
                borderRadius: "5px",
              }}
            >
              X
            </Box> */}
          {/* </IconButton> */}
        </Box>
      </Box>
      <Box
        sx={{ width: { xs: "93%", mddl: "100%" }, margin: "18px 0 26px 23px" }}
      >
        <Typography
          sx={{
            fontSize: "14px",
            fontWeight: theme.typography.Bold,
            width: "91%",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          {i18n["myplan.mycoinbalance"]}
          {
            <Button
              variant="contained"
              color="getCoins"
              sx={{
                minWidth: "fit-content",
                height: "24px",
                borderRadius: "8px",
                padding: "12px !important",
              }}
            >
              {coinsCount}
            </Button>
          }
        </Typography>
        <Typography
          sx={{
            fontSize: "14px",
            fontWeight: theme.typography.Bold,
            width: "91%",
            display: "flex",
            justifyContent: "space-between",
            my: "10px",
          }}
        >
          {i18n["myplan.mycoinhistory"]}
          {
            <span
              style={{ fontSize: "14px", fontWeight: theme.typography.Bold }}
            ></span>
          }
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "15px",
            width: "91%",
            mt: "8px",
            overflowY: "auto",
            height: "285px",
            "::-webkit-scrollbar": {
              height: "5px",
              background: "#DCDEEB",
              display: "unset !important",
            },
          }}
          id="matchTaleneList"
        >
          <InfiniteScroll
            style={{
              overflowX: "hidden",
              scrollbarWidth: "thin",
              display: "flex",
              flexDirection: "column",
              gap: "16px",
              width: "97%",
            }}
            dataLength={coinsHistory?.length || 0}
            next={() => getCoinsHistory(lastKey)}
            hasMore={true}
            scrollableTarget="matchTaleneList"
            scrollThreshold={"50px"}
          >
            {coinsHistory?.length > 0 ? (
              coinsHistory?.map((item) => {
                return (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontWeight: theme.typography.Regular,
                      }}
                    >
                      {item?.transaction_name?.name}
                    </Typography>

                    <Box sx={{ display: "flex", gap: "10px" }}>
                      {item?.transaction_status?.name === "Pending" && (
                        <Button
                          variant="contained"
                          color="submitButton"
                          sx={{
                            minWidth: "60px !important",
                            height: "25px",
                            borderRadius: "5px",
                            padding: "0px 7px !important",
                            fontSize: "12px",
                            fontWeight: theme.typography.Bold,
                            color: "black",
                            boxShadow: "none !important",
                            cursor: "default",
                          }}
                        >
                          {item?.transaction_status?.name}
                        </Button>
                      )}
                      <Typography
                        sx={{
                          fontSize: "14px",
                          fontWeight: theme.typography.Bold,
                        }}
                      >
                        {item?.transaction_type
                          ? "+"
                          : item?.coins > 0
                          ? "-"
                          : ""}
                        {item?.coins}
                      </Typography>
                    </Box>
                  </Box>
                );
              })
            ) : isLoading ? (
              <Box
                style={{
                  margin: "15px 0 0 0",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CircularProgress color="inherit" size={20} />
              </Box>
            ) : (
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: theme.typography.Bold,
                  }}
                >
                  No coins history
                </Typography>
              </Box>
            )}
          </InfiniteScroll>
        </Box>
      </Box>
      <Box>
        <Button
          variant="contained"
          color="submitButton"
          sx={{
            width: "50%",
            height: "60px",
            borderRadius: "0 0 0 25px",
            color: "black",
          }}
          onClick={() => {
            handleClose();
            navigate("/pricing");
          }}
        >
          {i18n["myplan.viewpricing"]}
        </Button>
        <Button
          variant="contained"
          color="getCoins"
          sx={{ width: "50%", height: "60px", borderRadius: "0 0 25px 0" }}
          onClick={() => {
            handleClose();
            setOpenGetCoins(true);
          }}
        >
          {i18n["myplan.getcoins"]}
        </Button>
      </Box>
    </Box>
  );
}
