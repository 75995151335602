import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ALERT_TYPE, ERROR_MSG } from "../utils/Constants";
import { getApi } from "../utils/Apis";
const initialState = {
  loading: false,
  alert: {
    show: false,
    type: ALERT_TYPE?.ERROR, // set default type to avoid warning in console
    msg: ERROR_MSG,
  },
  types: [],
};
export const getAllTypes = createAsyncThunk(
  "getAllTypes",
  async (payload, { dispatch }) => {
    const { data } = await getApi("/personalities");
    return data;
  }
);
export const configAllTypes = createSlice({
  name: "configtypes",
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action?.payload;
    },
    setAlert: (state, action) => {
      state.alert.show = action?.payload?.show;
      state.alert.type = action?.payload?.type;
      state.alert.msg = action?.payload?.msg;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getAllTypes?.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getAllTypes?.fulfilled, (state, action) => {
        const allTypes = action?.payload?.data?.map((type, index) => {
          // type.id = type?.personality_id;
          // type.color = "purpleButton";
          // type.name = type?.name?.toLowerCase();
          // return type;
          return {
            ...type,
            id: type?.personality_id,
            color: "purpleButton",
            name: type?.name?.toLowerCase(),
          };
        });
        state.loading = false;
        const obj = {
          id: 1111,
          name: "all types",
          color: "purpleButton",
          title: true,
        };
        allTypes?.unshift(obj);
        state.types = allTypes;
      })
      .addCase(getAllTypes?.rejected, (state, action) => {
        state.loading = false;
      });
  },
});
// Action creators are generated for each case reducer function
export const { setLoading, setAlert } = configAllTypes.actions;
export default configAllTypes.reducer;
