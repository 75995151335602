import {
  Box,
  Button,
  Grid,
  IconButton,
  InputBase,
  InputLabel,
  Paper,
  Slider,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useCallback, useEffect, useRef, useState } from "react";
import IconSection from "../IconSection";
import CompanyLogo from "../../../../assets/Crayon_Favicon.svg";
import { useDispatch } from "react-redux";
import {
  getLeftDrawerClientPool,
  getLeftDrawerTalentPool,
  updateTalentPool,
  uploadPoolImage,
} from "../../../../redux/admin/jobsSlice";
import { setAlert } from "../../../../redux/configSlice";
import { ALERT_TYPE } from "../../../../utils/Constants";
import TalentSVGButton from "../../../common/TalentSVGButton";
import editIcon from "../../../../assets/Padding Included/Yellow_Edit.svg";
import CustomDialog from "../../../common/CustomDialog";
import Cropper from "react-easy-crop";
import ZoomOutIcon from "@mui/icons-material/Remove";
import ZoomInIcon from "@mui/icons-material/Add";
import locale from "../../../../i18n/locale";
import getCroppedImg from "../../../../utils/cropImage";
import { useLocation } from "react-router-dom";
import CropModal from "../../../employer/myProfile/cropModal";

const EditPool = ({
  poolDetails,
  getUpdatedData,
  handleToggle,
  setPoolDetails,
}) => {
  const theme = useTheme();
  const i18n = locale.en;
  const dispatch = useDispatch();
  const params = useLocation();
  const hiddenFileInput = useRef(null);
  const [openEditImage, setOpenEditImage] = useState(false);
  const [imagePreview, setImagePreview] = useState([]);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [imageName, setImageName] = useState("My pic");
  const [imageBlob, setImageBlob] = useState();
  const [croppedImageUrl, setCroppedImageUrl] = useState(null);
  const [image, setImage] = useState([]);
  const isClient = params?.pathname?.includes("client-pool") ? true : false;

  const [details, setDetails] = useState({
    ...poolDetails,
    title: poolDetails.title,
    description: poolDetails.description,
  });
  const handleSubscriptionCancel = () => {
    setImageName("My pic");
    setOpenEditImage(false);
    setImageBlob();
    setCroppedImageUrl(null);
    setImage();
  };
  const cropInputFUnction = (e) => {
    const files = e.target.files;
    if (files && files.length > 0) {
      const reader = new FileReader();
      reader.onload = () => setImage(reader.result);
      reader.readAsDataURL(files?.[0]);
    }
  };
  const handleNewPoolName = (event) => {
    setDetails({ ...details, title: event?.target?.value });
  };
  const handleNewPoolDescription = (event) => {
    setDetails({ ...details, description: event.target.value });
  };

  const handleUpdatePool = async () => {
    try {
      const { payload } = await dispatch(updateTalentPool(details));
      if (payload.status === "success") {
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE.SUCCESS,
            msg: "Talent pool updated successfully",
          })
        );
        handleToggle();
        await getUpdatedData(0);
        isClient
          ? await dispatch(getLeftDrawerClientPool())
          : await dispatch(getLeftDrawerTalentPool());
      } else {
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE.ERROR,
            msg: payload?.message?.message,
          })
        );
      }
    } catch (error) {}
  };

  const handleUpdateLogo = (e) => {
    setImagePreview(null);
    hiddenFileInput?.current?.click();
  };

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const handleZoom = (direction) => {
    const step = 0.5;
    let newZoom = zoom;

    if (direction === "+") {
      newZoom = Math.min(zoom + step, 3); // Limit zoom to maximum 3x
    } else if (direction === "-") {
      newZoom = Math.max(zoom - step, 1); // Limit zoom to minimum 1x
    }
    setZoom(newZoom);
  };

  const handleImageChange = async (event, name) => {
    if (event?.target?.files?.[0] === undefined) {
      return;
    }
    cropInputFUnction(event);
    const render = new FileReader();
    setZoom(1);
    setImageName(event?.target?.files?.[0].name);
    const imageData = window.URL.createObjectURL(
      new Blob(event?.target?.files, { type: "application/*" })
    );
    setImagePreview(imageData);
    setOpenEditImage(true);
    event.target.value = "";
  };
  const handleSubscriptionOk = async () => {
    // async (event) => {
    //   const croppedImage = await getCroppedImg(
    //     imagePreview,
    //     croppedAreaPixels,
    //     0
    //   );
    const formData = new FormData();
    const blobTofile = new File([imageBlob], imageName, {
      type: "image/jpeg",
    });
    formData.append("pool-logo", blobTofile);
    formData.append("pool_id", poolDetails?.pool_id);
    try {
      const { payload } = await dispatch(uploadPoolImage(formData));
      if (payload?.status === "success") {
        setOpenEditImage(false);
        setPoolDetails({ ...poolDetails, profile_url: payload?.data });
      } else {
        setImageName("My pic");
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE?.ERROR,
            msg: payload?.message,
          })
        );
      }
    } catch (error) {
      setImageName("My pic");
      dispatch(setAlert({ show: true }));
    }
  };

  // const handleUpdatePoolLogo = useCallback(
  //   async (event) => {
  //     const croppedImage = await getCroppedImg(
  //       imagePreview,
  //       croppedAreaPixels,
  //       0
  //     );
  //     const formData = new FormData();
  //     const blobTofile = new File([croppedImage], imageName, {
  //       type: "image/jpeg",
  //     });
  //     formData.append("pool-logo", blobTofile);
  //     formData.append("pool_id", poolDetails?.pool_id);
  //     try {
  //       const { payload } = await dispatch(uploadPoolImage(formData));
  //       if (payload?.status === "success") {
  //         setOpenEditImage(false);
  //         setPoolDetails({ ...poolDetails, profile_url: payload?.data });
  //       } else {
  //         setImageName("My pic");
  //         dispatch(
  //           setAlert({
  //             show: true,
  //             type: ALERT_TYPE.ERROR,
  //             msg: payload?.message,
  //           })
  //         );
  //       }
  //     } catch (error) {
  //       setImageName("My pic");
  //       dispatch(setAlert({ show: true }));
  //     }
  //   },
  //   [croppedAreaPixels]
  // );

  const renderFooter = <></>;

  return (
    <>
      <Box sx={{ ml: "16px" }}>
        <Typography
          sx={{
            fontSize: "36px",
            fontWeight: 700,
            textAlign: "center",
            // ml: 6
          }}
        >
          Talent Pools
        </Typography>
        <Grid
          container
          spacing={2}
          flexDirection={"column"}
          sx={{
            display: { xs: "none", md: "flex" },
            marginTop: "30px",
            background: "white",
            borderRadius: "25px",
          }}
          boxShadow={1}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              gap: "10px",
            }}
          >
            <Box
              sx={{
                marginTop: 3,
                padding: 2,
              }}
            >
              <Box
                component="img"
                className="profileAvatar"
                alt="crayon logo"
                src={
                  poolDetails?.profile_url !== "No URL"
                    ? poolDetails?.profile_url
                    : CompanyLogo
                }
                sx={{
                  height: 80,
                  width: 90,
                  borderRadius: "10px",
                }}
              />
              <input
                ref={hiddenFileInput}
                type="file"
                accept="image/*"
                onChange={(event) => handleImageChange(event, "company")}
                style={{ display: "none" }}
              />
              <TalentSVGButton
                color={"white"}
                source={editIcon}
                outerHeight={25}
                height={25}
                width={25}
                padding={"0px !important"}
                startIconMargin={"0px !important"}
                onClick={handleUpdateLogo}
              />
              <Typography
                sx={{
                  fontWeight: 700,
                }}
              >
                {poolDetails.title}
              </Typography>
              <Typography>Edit pool details.</Typography>
            </Box>
            <Box mt={2}>
              <IconSection />
            </Box>
            <Button
              variant="contained"
              color="yellowButton100"
              sx={{
                borderRadius: "0 25px 0 25px",
              }}
              // onClick={handleOpenAdd}
            >
              create new pool
            </Button>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              paddingX: 2,
              paddingY: 1,
              borderBottom: 2,
              borderColor: theme.palette.grayBorder,
            }}
          ></Box>
          <Box
            sx={{
              width: "45%",
              display: "flex",
              flexDirection: "column",
              padding: 3,
              gap: 1,
            }}
          >
            <Typography
              sx={{
                fontWeight: 700,
                paddingLeft: "10px",
                paddingBottom: "2px",
              }}
            >
              Pool details
            </Typography>
            <Box>
              <InputLabel
                htmlFor="current_job_title"
                sx={{
                  color: "black",
                  paddingLeft: "10px",
                  paddingBottom: "2px",
                  fontSize: "14px",
                  fontWeight: 500,
                }}
              >
                Pool name
              </InputLabel>
              <Paper
                elevation={3}
                sx={{
                  display: "flex",
                  borderRadius: "25px",
                  height: "40px",
                  boxShadow: "none",
                  border: `1px solid ${theme.palette.grayBorder}`,
                }}
              >
                <InputBase
                  sx={{ ml: 2, mr: 2, width: "100%" }}
                  id="Pool_name"
                  placeholder={"enter your talent pool name"}
                  value={details.title}
                  onChange={handleNewPoolName}
                />
              </Paper>
            </Box>
            <Box>
              <InputLabel
                htmlFor="current_job_title"
                sx={{
                  color: "black",
                  paddingLeft: "10px",
                  paddingBottom: "2px",
                  fontSize: "14px",
                  fontWeight: 500,
                }}
              >
                Description
              </InputLabel>
              <Paper
                elevation={3}
                sx={{
                  display: "flex",
                  borderRadius: "25px",
                  height: "40px",
                  boxShadow: "none",
                  border: `1px solid ${theme.palette.grayBorder}`,
                }}
              >
                <InputBase
                  sx={{ ml: 2, mr: 2, width: "100%" }}
                  id="Pool_name"
                  placeholder={"enter a short description about the pool"}
                  value={details.description}
                  onChange={handleNewPoolDescription}
                />
              </Paper>
            </Box>
          </Box>
          <Box
            sx={{
              margin: "auto",
              width: "30%",
            }}
          >
            <Button
              variant="contained"
              color="grayButton100"
              sx={{
                borderRadius: 0,
                width: "50%",
                height: "47px",
                borderTopLeftRadius: 25,
              }}
              onClick={handleToggle}
            >
              go back
            </Button>
            <Button
              variant="contained"
              color="redButton100"
              sx={{
                borderRadius: 0,
                width: "50%",
                height: "47px",
                borderTopRightRadius: 25,
              }}
              onClick={handleUpdatePool}
              disabled={details?.name?.trim().length === 0}
            >
              update
            </Button>
          </Box>
        </Grid>
      </Box>
      {openEditImage && (
        <CropModal
          handleOpen={openEditImage}
          handleClose={setOpenEditImage}
          handleSubscriptionOk={handleSubscriptionOk}
          handleSubscriptionCancel={handleSubscriptionCancel}
          imagePreview={imagePreview}
          image={image}
          setImage={setImage}
          croppedImageUrl={croppedImageUrl}
          setCroppedImageUrl={setCroppedImageUrl}
          imageBlob={imageBlob}
          setImageBlob={setImageBlob}
        />
      )}
      {/* <CustomDialog
        dialogWidth="md"
        show={openEditImage}
        footer={renderFooter}
        isProfile
      >
        <IconButton
          aria-label="close"
          onClick={() => {
            setImageName("My pic");
            setOpenEditImage(false);
          }}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme?.palette?.grey[500],
          }}
        >
          <Box
            sx={{
              width: "20px",
              height: "20px",
              color: "#000000",
              border: 2,
              fontSize: "18px",
              borderRadius: "5px",
            }}
          >
            X
          </Box>
        </IconButton>
        <Box
          sx={{
            position: "absolute",
            top: "10%",
            left: "10%",
            right: "10%",
            bottom: "20%",
            ".reactEasyCrop_Contain": {
              maxWidth: "40% !important",
            },
          }}
        >
          <Cropper
            image={imagePreview}
            crop={crop}
            zoom={zoom}
            aspect={1}
            cropShape="round"
            showGrid={true}
            onCropChange={setCrop}
            onCropComplete={onCropComplete}
          />
        </Box>
        <Box
          className="controls"
          sx={{
            position: "absolute",
            bottom: "20px",
            left: "50%",
            width: "50%",
            transform: "translateX(-50%)",
            height: "90px",
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <Box
            sx={{
              display: "flex",
            }}
          >
            <Button variant="text" onClick={() => handleZoom("-")}>
              <ZoomOutIcon />
            </Button>
            <Box
              sx={{
                width: 200,
                mx: 3,
              }}
            >
              <Slider
                defaultValue={0}
                size="small"
                value={zoom}
                min={1}
                max={3}
                step={0.5}
                aria-labelledby="Zoom"
                onChange={(e) => {
                  setZoom(e.target.value);
                }}
                className="zoom-range"
              />
            </Box>
            <Button variant="text" onClick={() => handleZoom("+")}>
              <ZoomInIcon />
            </Button>
            <Button variant="text" onClick={() => setZoom(1)}>
              Reset
            </Button>
          </Box>

          <Box>
            <Button
              onClick={() => {
                setImageName("My pic");
                setOpenEditImage(false);
              }}
              disableElevation
              variant="outlined"
              color="redButton"
              sx={{ width: "130px", mr: 2 }}
            >
              {i18n["empMyProfile.cancel"]}
            </Button>
            <Button
              onClick={handleUpdatePoolLogo}
              disableElevation
              variant="contained"
              color="redButton"
              sx={{ width: "130px" }}
            >
              {i18n["empMyProfile.upload"]}
            </Button>
          </Box>
        </Box>
      </CustomDialog> */}
    </>
  );
};

export default EditPool;
