import { Box, Button, InputBase, Typography, useTheme } from "@mui/material";
import React from "react";
import CustomDialog from "../common/CustomDialog";
import RedTriangle from "../../assets/Characters/Red_Triangle_Blank.svg";

export default function SwitchToCandidate({
  openEdit,
  handleClose,
  setSwitchCandidateConfirm,
  setSwitchCandidate,
}) {
  const theme = useTheme();
  return (
    <CustomDialog
      show={openEdit}
      hideButton={false}
      dialogWidth="xs"
      showFooter={false}
      onDialogClose={handleClose}
      padding={0}
    >
      <Box
        sx={{
          padding: 4,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "8px",
        }}
      >
        <Box
          component={"img"}
          src={RedTriangle}
          sx={{ width: 150, height: 100 }}
        />
        <Typography
          variant="h5"
          fontWeight={"Bold"}
          textAlign={"center"}
          sx={{
            // marginBottom: 3,
            height: "60px",
            display: "flex",
            alignItems: "center",
          }}
        >
          Sure you want to switch to candidate?
        </Typography>
      </Box>
      <Box>
        <Button
          sx={{
            boxShadow: 0,
            fontSize: "14px",
            width: "50%",
            borderRadius: 0,
            height: "43px",
            color: "black",
            padding: 3,
          }}
          color="submitButton"
          variant="contained"
          onClick={handleClose}
        >
          no
        </Button>
        <Button
          sx={{
            boxShadow: 0,
            fontSize: "14px",
            width: "50%",
            borderRadius: 0,
            height: "43px",
            padding: 3,
          }}
          variant="contained"
          color="redButton100"
          onClick={() => {
            setSwitchCandidate(false);
            setSwitchCandidateConfirm(true);
          }}
        >
          yes
        </Button>
      </Box>
    </CustomDialog>
  );
}
