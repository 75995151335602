import { Box, Typography } from '@mui/material'
import React from 'react'
import theme from '../../../../utils/Theme'

const AddNewCompanyCard = ({ companyOpened }) => {
    return (
        <Box sx={{
            display: "flex",
            height: "100%",
            justifyContent: "center",
            alignItems: "center",
            width:"100%"
        }}>
            <Typography sx={{
                fontSize: "72px",
                width: "100px",
                height: "100px",
                background: "#F2F4FB",
                borderRadius: "50%",
                display: "flex",
                padding: "0",
                fontWeight: theme.typography.Bold,
                justifyContent: "center",
                alignItems: "center"
            }}>
                {companyOpened ? "-" : "+"}
            </Typography>
        </Box>
    )
}

export default AddNewCompanyCard