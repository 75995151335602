import { useRef, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { useTheme } from "@mui/material/styles";
import downArrow from "../../../assets/Black_Down_Open.svg";
import { Popover } from "@mui/material";
import ClientsList from "../../recruiter/common/ClientsList";

export default function MyClientFilter({
  panelData,
  side,
  color,
  onChangeFilter = () => {},
  setSelectedCompany,
  selectedCompany,
}) {
  const theme = useTheme();
  const clientRef = useRef();

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event?.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <Box
      sx={{
        // mt: { sx: 0, sm: topMargin ? '68px' : '16px' },
        textAlign: side === "right" && "end",
        display: { xs: "none", sm: "flex" },
        flexDirection: "column",
        gap: "10px",
        // overflow: { xs: "auto", sm: "hidden" },
      }}
    >
      <Button
        ref={clientRef}
        aria-describedby={id}
        endIcon={
          <Box
            component={"img"}
            src={downArrow}
            sx={{ height: "28px", width: "30px" }}
          />
        }
        sx={{
          ".MuiButton-startIcon": {
            marginRight: "0px !important",
            marginLeft: 0,
          },
          ".MuiButton-endIcon": {
            marginRight: "0px !important",
            marginLeft: 0,
          },
          padding: "6px 7px",
          lineHeight: "inherit",
          // borderRadius: "5px",
          // boxShadow: `0px 5px 10px ${theme.palette.boxShadow.main}`,
          borderBottomLeftRadius: side === "left" ? { sm: 0 } : "10px",
          borderTopLeftRadius: side === "left" ? { sm: 0 } : "10px",
          borderTopRightRadius: side === "right" ? { sm: 0 } : "10px",
          borderBottomRightRadius: side === "right" ? { sm: 0 } : "10px",
          mr: { xs: 1, sm: 0 },
          fontSize: theme.typography.fontSize.xs,
          minWidth: { xs: "90px", sm: 0 },
          fontWeight: theme.typography.Bold,
          "&:hover": {
            // boxShadow: 15,
            // opacity: 1,
            color: theme.palette[color]?.contrastText,
            backgroundColor: theme.palette[color]?.main,
            fontWeight: theme.typography.Bold,
          },
          height: "45px",
          // opacity: selectedBtns.includes(btn.id) ? 1 : 0.5
        }}
        onClick={handleClick}
        disableElevation
        variant="contained"
        color={"filterButtons"}
      >
        my clients
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        sx={{
          borderRadius: "0px !important",

          "& .MuiPopover-paper": {
            width: "360px",
            height: {
              sm: "440px",
              lg: "440px",
              lgm: "500px",
              mlg: "550px",
              xl: "576px",
            },
            borderRadius: "0 10px 10px 0 !important",
            left: "0px !important",
            right: "unset !important",
            zIndex: "0 !important",
            boxShadow: `0px 5px 10px ${theme.palette.boxShadow.main}`,
          },
        }}
      >
        <ClientsList
          handleClose={handleClose}
          onChangeFilter={onChangeFilter}
          selectedCompany={selectedCompany}
          setSelectedCompany={setSelectedCompany}
        />
      </Popover>
    </Box>
  );
}
