import { Box, Button, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import TextEditor from "../../common/postJobsSection/TextEditor";
import { useTheme } from "@emotion/react";
import { useDispatch } from "react-redux";
import {
  getPrivacyPolicy,
  getTermcondition,
  postprivacypolicy,
  postTermCondition,
} from "../../../redux/guest/contactSlice";

const PolicyPrivacy = () => {
  const dispatch = useDispatch();
  const [detailData, setDetailData] = useState({
    termsOfService: "",
    privacyPolicy: "",
  });
  const [isTermsBtnLabel, setIsTermsBtnLabel] = useState("edit");
  const [isPrivacyBtnLabel, setIsPrivacyBtnLabel] = useState("edit");
  const theme = useTheme();
  const handleInputChange = (inputText, type) => {
    const newDetailData = {
      ...detailData,
      [type]: inputText,
    };
    setDetailData(newDetailData);
  };
  const sanitizeHTML = (htmlContent) => {
    const tempElement = document?.createElement("div");
    tempElement.innerHTML = htmlContent;

    const isContentEmpty = tempElement?.textContent?.trim() === "";
    return isContentEmpty ? "" : htmlContent;
  };

  const handleTermBtn = async (e) => {
    e?.preventDefault();
    e?.stopPropagation();
    if (isTermsBtnLabel === "save") {
      const payload = {
        data: sanitizeHTML(detailData?.termsOfService),
      };
      try {
        const response = await dispatch(postTermCondition(payload));
        if (response?.payload?.status === "success") {
          setIsTermsBtnLabel("edit");
        }
      } catch (error) {
        console.log(error, "error");
      }
    }
  };

  const handlePrivacyBtn = async () => {
    if (isPrivacyBtnLabel === "save") {
      const payload = {
        data: sanitizeHTML(detailData?.privacyPolicy),
      };
      try {
        const response = await dispatch(postprivacypolicy(payload));
        if (response?.payload?.status === "success") {
          setIsPrivacyBtnLabel("edit");
        }
      } catch (error) {
        console.log(error, "error");
      }
    }
  };

  const getTerm = async () => {
    try {
      const response = await dispatch(getTermcondition());
      if (response?.payload?.status === "success") {
        setDetailData({
          ...detailData,
          termsOfService: response?.payload?.data?.data,
        });
      }
    } catch (error) {
      console.log(error, "error");
    }
  };

  const getPrivacy = async () => {
    try {
      const response = await dispatch(getPrivacyPolicy());
      if (response?.payload?.status === "success") {
        setDetailData({
          ...detailData,
          privacyPolicy: response?.payload?.data?.data,
        });
      }
    } catch (error) {
      console.log(error, "error");
    }
  };
  const fetchPolicyData = async () => {
    try {
      const [termsResponse, privacyResponse] = await Promise.all([
        dispatch(getTermcondition()),
        dispatch(getPrivacyPolicy()),
      ]);

      setDetailData({
        termsOfService: termsResponse?.payload?.data?.data || "",
        privacyPolicy: privacyResponse?.payload?.data?.data || "",
      });
    } catch (error) {
      console.log(error, "error");
    }
  };

  useEffect(() => {
    fetchPolicyData();
  }, []);
  return (
    <Box sx={{ display: "flex", flexDirection: "column", marginTop: "55px" }}>
      <Box
        sx={{ display: "flex", flexDirection: "column", position: "relative" }}
      >
        <Typography
          sx={{
            fontWeight: theme.typography.Bold,
            fontSize: "15px",
            marginBottom: "5px",
          }}
        >
          Terms of Service
        </Typography>
        <Box
          sx={{
            position: "relative",
            width: "100%",
            overflowX: "hidden",
            borderRadius: "25px",
          }}
        >
          <TextEditor
            minHeight="500px"
            value={detailData?.termsOfService}
            borderRadius="25x"
            border={`1px solid ${theme.palette.borderColor}`}
            type="termsOfService"
            onInputCHange={handleInputChange}
            width={"100%"}
            paddingRight={"25px"}
            disabled={isTermsBtnLabel === "edit" ? true : false}
          />
          <Button
            variant="contained"
            color="nandos"
            sx={{
              borderRadius: "0 25px 0 10px",
              width: "150px",
              height: "45px",
              position: "absolute",
              zIndex: 100,
              right: "0px",
              top: "0px",
              fontSize: "12px",
              fontWeight: theme.typography.Bold,
            }}
            onClick={() => {
              setIsTermsBtnLabel(isTermsBtnLabel === "edit" ? "save" : "edit");
              handleTermBtn();
            }}
            tabIndex={0}
          >
            {isTermsBtnLabel}
          </Button>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          position: "relative",
          marginBottom: "40px",
        }}
      >
        <Typography
          sx={{
            fontWeight: theme.typography.Bold,
            fontSize: "15px",
            marginBottom: "5px",
            marginTop: "10px",
          }}
        >
          Privacy Policy
        </Typography>
        <Box
          sx={{
            position: "relative",
            width: "100%",
            overflowX: "hidden",
            borderRadius: "25px",
          }}
        >
          <TextEditor
            minHeight="500px"
            value={detailData?.privacyPolicy}
            borderRadius="25px"
            border={`1px solid ${theme.palette.borderColor}`}
            type="privacyPolicy"
            onInputCHange={handleInputChange}
            width={"100%"}
            paddingRight={"25px"}
            disabled={isPrivacyBtnLabel === "edit" ? true : false}
          />
          <Button
            variant="contained"
            color="nandos"
            sx={{
              borderRadius: "0 25px 0 10px",
              width: "150px",
              height: "45px",
              position: "absolute",
              zIndex: 100,
              right: "0px",
              top: "0px",
              fontSize: "12px",
              fontWeight: theme.typography.Bold,
            }}
            onClick={() => {
              setIsPrivacyBtnLabel(
                isPrivacyBtnLabel === "edit" ? "save" : "edit"
              );
              handlePrivacyBtn();
            }}
            tabIndex={0}
          >
            {isPrivacyBtnLabel}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default PolicyPrivacy;
