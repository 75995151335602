import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import locale from "../../i18n/locale";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Link, useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import "./login.css";
import * as Yup from "yup";
import {
  IconButton,
  InputBase,
  Dialog,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import {
  emailRegex,
  getDecodedToken,
  getLocalStorage,
  getToken,
  validateSchema,
} from "../../utils/Common";
import loginIcon from "../../assets/Characters/CrayBotIcons/LoginIcon.svg";
import Switch from "@mui/material/Switch";
import { styled, alpha } from "@mui/material/styles";
import ForgotPassword from "./ForgotPassword";
import { useDispatch } from "react-redux";
import { login, setInitialPopup } from "../../redux/login/loginSlice";
import Cookies from "js-cookie";
import { ThemeProvider } from "@emotion/react";
import { loginPagetheme } from "../../utils/Theme";
import { setLoggedIn } from "../../redux/guest/jobsSlice";
import CloseIcon from "../common/CloseIcon";
import { getProfilePercentage } from "../../redux/employer/empJobListing";

const BlueSwitch = styled(Switch)(({ theme }) => ({
  "& .MuiSwitch-switchBase.Mui-checked": {
    color: theme.palette.rememberMeswitch,
    "&:hover": {
      backgroundColor: alpha(
        theme.palette.rememberMeswitch,
        theme.palette.action.hoverOpacity
      ),
    },
  },
  "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
    backgroundColor: theme.palette.rememberMeswitch,
  },
  "& .MuiSwitch-track": {
    margin: "auto",
    // height: "60% !important",
  },
  "& .MuiSwitch-thumb": {
    borderRadius: "6px !important",
  },
}));

export default function Login({
  handleLogin,
  toggleForm,
  openFunc,
  handleOpenLogin,
  closeFunc,
  isVideoUploaded,
  setResendMailBox,
  setEmail,
  setSelectedLabel = () => {},
  setOpenInitialApporovalModal,
  handleConfirmPasswordChange,
  uploadedFileName
}) {
  const i18n = locale.en;
  const theme = useTheme();
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const [inputType, setInputType] = useState("password");
  const [openForgotPassword, setOpenForgotPassword] = useState(false);
  const [loginTouched, setLoginTouched] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const [errors, setErrors] = useState([]);
  const [apiErrors, setApiErrors] = useState([]);
  const [formData, setFormData] = useState({});
  const [fieldTouched, setFieldTouched] = useState([]);
  const navigate = useNavigate();
  const validationSchema = Yup.object().shape({
    username: Yup.string()
      .matches(emailRegex, "Invalid email address")
      .required("Email is Required."),
    password: Yup.string().required("Password required"),
  });

  let decodedToken = getDecodedToken(getToken());

  useEffect(() => {
    const storedUserName = Cookies.get("email");
    const storedPassword = Cookies.get("password");
    if (storedUserName) {
      setFormData({
        username: storedUserName,
        password: storedPassword,
      });
    }
  }, [openFunc]);

  const handleShowPassword = () => {
    if (showPassword) setInputType("password");
    else setInputType("text");
    setShowPassword(!showPassword);
  };

  const handleSubmitNew = async () => {
    setErrors([]);
    validateSchema(validationSchema, formData)
      .then(() => {
        const formBody = {
          username: formData?.username,
          password: formData?.password,
          rememberMe: rememberMe,
          fileName:
            decodedToken?.data?.role_id === undefined
              ? (getLocalStorage("fileName") || uploadedFileName)
              : "",
          job_id:
            decodedToken?.data?.role_id === undefined
              ? getLocalStorage("job_id")
              : "",
          jobs_user_id:
            decodedToken?.data?.role_id === undefined
              ? getLocalStorage("jobs_user_id")
              : "",
        };

        if (Cookies.get("favoriteTalent")) {
          formBody["favoriteTalent"] = Cookies.get("favoriteTalent");
        }
        if (Cookies.get("favoriteCompany")) {
          formBody["favoriteCompany"] = Cookies.get("favoriteCompany");
        }
        if (Cookies.get("favoriteJobs")) {
          formBody["favoriteJobs"] = Cookies.get("favoriteJobs");
        }
        if (isVideoUploaded) {
          formBody["job_cam_flag"] = true;
        }
        const { payload } = dispatch(login(formBody)).then((payload) => {
          if (
            payload?.payload?.status === "success" &&
            payload?.payload?.token
          ) {
            if (payload?.payload?.approval_request_flag) {
              dispatch(getProfilePercentage());
              dispatch(
                setInitialPopup(payload?.payload?.approval_request_flag)
              );
              setOpenInitialApporovalModal(
                payload?.payload?.approval_request_flag
              );
            }
            setSelectedLabel();
            if (rememberMe) {
              Cookies.set("email", formBody.username, { secure: true });
              Cookies.set("password", formBody.password, { secure: true });
            }
            handleLogin(payload?.payload);
            setRememberMe(false);
            // formik.resetForm();
            setFormData({});
            dispatch(setLoggedIn(true));
          } else if (payload?.payload?.status === "error") {
            if (
              payload?.payload?.message?.status === "error" &&
              payload?.payload?.message?.data?.timeFlag
            ) {
              setResendMailBox(true);
              closeFunc();
              setEmail(payload?.payload?.message?.data?.email);
            } else {
              // dispatch(
              //   setAlert({
              //     show: true,
              //     type: ALERT_TYPE?.ERROR,
              //     msg:
              //       payload?.payload?.message?.message ||
              //       payload?.payload?.message?.data,
              //   })
              // );
            }
          }
          let newErrors = [...apiErrors];
          if (payload?.payload?.message?.emailNotExist === true) {
            newErrors.push({ message: "invalid username", key: "username" });
          }
          if (payload?.payload?.message?.inCorrectPassword === true) {
            newErrors.push({ message: "invalid password", key: "password" });
          }
          setApiErrors(newErrors);
          setErrors(newErrors);
        });

        setLoginTouched(false);
      })
      .catch((error) => {
        setErrors(
          Object.keys(error)?.map((item) => ({
            message: error[item],
            key: item,
          }))
        );
      });
  };
  const handleForgotPassword = () => {
    setOpenForgotPassword(true);
  };
  const handleOpenLoginFromforgotPassword = () => {
    handleOpenLogin(true);
    setOpenForgotPassword(false);
  };
  const handleCloseForgotPassword = () => {
    setOpenForgotPassword(false);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    handleSubmitNew();
    setLoginTouched(true);
  };

  const handleChange = (e, key) => {
    let updatedFormValue = { ...formData };
    updatedFormValue[key] = e?.target?.value;
    setFormData({ ...updatedFormValue });
    setErrors(errors?.filter((item) => item?.key !== key));
    setApiErrors(errors?.filter((item) => item?.key !== key));
  };
  const handlePrivacy = () => {
    closeFunc();
    navigate("/privacyPolicy");
  };
  const handleService = () => {
    closeFunc();
    navigate("/termsOfService");
  };
  return (
    <ThemeProvider theme={loginPagetheme}>
      {!openForgotPassword && (
        <Dialog
          open={openFunc}
          hideButton={false}
          onClose={() => {
            closeFunc();
            setFormData({});
          }}
          dialogWidth="sm"
          scroll="body"
          showFooter={false}
          // title={i18n["login.login"]}
          padding={0}
          className="LoginBox"
          sx={{
            zIndex: 100000,
            margin: "0px",
            "& .MuiPaper-root": {
              borderRadius: "25px",
              // maxWidth: "450px",
              // width: "100%",
              backgroundColor: "backgroundColor",
              margin: "0px",
            },
            "& .MuiDialog-paperScrollBody": {
              width: "100%",
              maxWidth: { xs: "97%", tablet: "450px" },
            },
            "& .MuiPaper-root-MuiDialog-paper": {
              width: "100%",
              maxWidth: "100%",
            },
          }}
        >
          <Box
            sx={{
              cursor: "pointer",
            }}
            onClick={() => {
              closeFunc();
              setLoginTouched(false);
              setFormData({});
            }}
          >
            <CloseIcon />
          </Box>
          <form onSubmit={handleSubmit}>
            <Box
              sx={{
                width: "100%",
                padding: "25px 0 0 0",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Box
                component="img"
                src={loginIcon}
                sx={{ width: 169, height: 125 }}
              ></Box>
              <Typography
                sx={{
                  fontSize: "20px",
                  fontWeight: theme.typography.Bold,
                  lineHeight: "27px",
                  marginTop: "20px",
                }}
              >
                Guess who’s back, back again!
              </Typography>
              <Typography
                sx={{
                  fontSize: "16px",
                  marginTop: "20px",
                  marginBottom: "20px",
                  fontWeight: theme.typography.Bold,
                }}
              >
                You know the drill…
              </Typography>
              <Typography
                sx={{
                  fontSize: "14px",
                  fontWeight: theme.typography.Regular,
                  lineHeight: "19px",
                  marginBottom: "20px",
                }}
              >
                Enter your login credentials below.
              </Typography>
              <Box sx={{ width: "87%", padding: 0 }}>
                <Paper
                  sx={{
                    display: "flex",
                    height: "40px",
                    width: "100% !important",
                    borderRadius: "10px  !important",
                    boxShadow: "none",
                    backgroundColor: "#fff !important",
                    border: errors?.find((error) => error.key === "username")
                      ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
                      : "1px solid borderColor",
                  }}
                >
                  <InputBase
                    id="username"
                    value={formData?.username}
                    onChange={(e) => {
                      handleChange(e, "username");
                    }}
                    onBlur={(e) => {
                      setFieldTouched([...fieldTouched, "username"]);
                    }}
                    placeholder={i18n["login.emailAddrees"]}
                    sx={{
                      ml: 2,
                      mr: 2,
                      width: "100%",
                    }}
                  />
                </Paper>
                {apiErrors?.find((error) => error.key === "username") && (
                  <Typography
                    sx={{
                      color: theme.jobCard.jobStatus.Rejected.main,
                      fontSize: "14px",
                      fontWeight: theme?.typography?.Regular,
                      textAlign: "center",
                      marginTop: "5px",
                    }}
                  >
                    Oops! We can't find a user linked to that email address.
                    <br /> Do you want to{" "}
                    <span style={{ fontWeight: theme?.typography?.Bold }}>
                      sign-up?
                    </span>{" "}
                    Or{" "}
                    <span style={{ fontWeight: theme?.typography?.Bold }}>
                      try again.
                    </span>
                  </Typography>
                )}
              </Box>

              <Box sx={{ mt: "24px", width: "87%" }}>
                <Paper
                  sx={{
                    display: "flex",
                    height: "40px",
                    width: "100% !important",
                    borderRadius: "10px  !important",
                    boxShadow: "none",
                    backgroundColor: "#fff !important",
                    border: errors?.find((error) => error.key === "password")
                      ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
                      : "1px solid borderColor",
                  }}
                >
                  <InputBase
                    sx={{ ml: 2, mr: 2, width: "100%" }}
                    id="password"
                    value={formData?.password}
                    onChange={(e) => {
                      handleChange(e, "password");
                    }}
                    onBlur={(e) => {
                      setFieldTouched([...fieldTouched, "password"]);
                    }}
                    type={inputType}
                    placeholder={i18n["login.password"]}
                  />
                  <IconButton
                    sx={{ py: 0 }}
                    color=""
                    aria-label="reset password"
                    component="button"
                    onClick={handleShowPassword}
                    disableRipple={true}
                  >
                    {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                  </IconButton>
                </Paper>
                {apiErrors?.find((error) => error.key === "password") && (
                  <Typography
                    sx={{
                      color: theme.jobCard.jobStatus.Rejected.main,
                      fontSize: "14px",
                      fontWeight: theme?.typography?.Regular,
                      textAlign: "center",
                      marginTop: "5px",
                    }}
                  >
                    Oops! That password doesn't match our records.
                    <br />
                    Do you want to{" "}
                    <span style={{ fontWeight: theme?.typography?.Bold }}>
                      reset your password?
                    </span>{" "}
                    Or{" "}
                    <span style={{ fontWeight: theme?.typography?.Bold }}>
                      try again.
                    </span>
                  </Typography>
                )}
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  marginTop: "15px",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: theme.typography.Medium,
                  }}
                >
                  remember me, CrayBot
                </Typography>
                <BlueSwitch
                  checked={rememberMe}
                  onChange={(event) => setRememberMe(event.target.checked)}
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 1,
                  marginTop: "8px",
                  // marginTop: 3,
                }}
              >
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: theme.typography.Medium,
                  }}
                >
                  Oops, I meant to
                </Typography>
                <Link style={{ textDecoration: "none" }}>
                  <Typography
                    sx={{
                      color: "black",
                      fontSize: "14px",
                      fontWeight: theme.typography.Bold,
                    }}
                    onClick={toggleForm}
                  >
                    sign-up!
                  </Typography>
                </Link>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 1,
                  marginTop: "20px",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: theme.typography.Medium,
                  }}
                >
                  Dammit, I forgot my
                </Typography>
                <Link style={{ textDecoration: "none" }}>
                  <Typography
                    sx={{
                      color: "black",
                      fontSize: "14px",
                      fontWeight: theme.typography.Bold,
                    }}
                    onClick={handleForgotPassword}
                  >
                    password!
                  </Typography>
                </Link>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                  marginTop: "20px",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "12px",
                    fontWeight: theme.typography.Medium,
                  }}
                >
                  By logging in, I (again) agree to be bound by Crayon's
                </Typography>
                <Typography
                  sx={{
                    fontSize: "12px",
                    fontWeight: theme.typography.Medium,
                  }}
                >
                  <span
                    style={{
                      fontWeight: theme.typography.Bold,
                      fontSize: "12px",
                      cursor: "pointer",
                    }}
                    // onClick={openPDFPrivacy}
                    onClick={() => {
                      handlePrivacy();
                    }}
                  >
                    Privacy Policy
                  </span>{" "}
                  and{" "}
                  <span
                    style={{
                      fontWeight: theme.typography.Bold,
                      fontSize: "12px",
                      cursor: "pointer",
                    }}
                    // onClick={openPDFService}
                    onClick={() => {
                      handleService();
                    }}
                  >
                    Terms of Service.
                  </span>
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  mt: "20px",
                  width: "100%",
                  overflow: "hidden",
                }}
              >
                <Button
                  sx={{
                    width: "100%",
                    height: "60px",
                    borderRadius: 0,
                    padding: 3,
                    fontSize: "14px",
                    fontWeight: theme.typography.Bold,
                  }}
                  variant="contained"
                  color="loginButton"
                  type="submit"
                >
                  let’s lock ’n load
                </Button>
              </Box>
            </Box>
          </form>
        </Dialog>
      )}
      <ForgotPassword
        openFunc={openForgotPassword}
        openPrevDialog={handleOpenLoginFromforgotPassword}
        closeFunc={handleCloseForgotPassword}
        handleToggleFuntion={toggleForm}
        closeLoginModal={closeFunc}
        handleConfirmPasswordChange={handleConfirmPasswordChange}
      />
    </ThemeProvider>
  );
}
