import {
  Box,
  Button,
  IconButton,
  Modal,
  Typography,
  useTheme,
} from "@mui/material";
import externalLink from "../../../assets/Padding Excluded/External_Link.svg";
import React, { useState } from "react";
import locale from "../../../i18n/locale";
import InputBox from "../InputBox";
import { useDispatch } from "react-redux";
import { saveExternalLink } from "../../../redux/employer/empProfileSlice";
import { setAlert } from "../../../redux/configSlice";
import { ALERT_TYPE, isValidLinkedInUrl } from "../../../utils/Constants";

export default function ExteranalLinkBox({
  open,
  handleClose,
  selectedJob,
  setAllJobs,
  allJobs,
}) {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    height: "468px",
    width: "360px",
    bgcolor: "background.paper",
    borderRadius: "25px",
    boxShadow: "none !important",
    border: "none !important",
  };

  const theme = useTheme();
  const dispatch = useDispatch();
  const i18n = locale.en;
  const [errors, setErrors] = useState({});
  const [link, setLink] = useState(
    selectedJob?.external_link ? selectedJob?.external_link : ""
  );

  const handleInputChange = (event) => {
    setErrors({});
    setLink(event?.target?.value);
  };

  const handleSave = async () => {
    if (!isValidLinkedInUrl(link)) {
      setErrors({ message: "URL wrong", key: "externallink" });
      return;
    }
    try {
      let data = {
        job_id: selectedJob?.job_id,
        external_link: link,
      };
      const { payload } = await dispatch(saveExternalLink(data));
      if (payload?.status === "success") {
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE?.SUCCESS,
            msg: "External link added successfully",
          })
        );
        setAllJobs(
          allJobs?.map((item) => {
            return item?.job_id === selectedJob?.job_id
              ? { ...item, external_link: link }
              : item;
          })
        );
        handleClose();
      } else {
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE?.ERROR,
            msg: "ERROR",
          })
        );
      }
    } catch (error) {
      dispatch(
        setAlert({
          show: true,
          type: ALERT_TYPE?.ERROR,
          msg: error,
        })
      );
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <IconButton
          aria-label="close"
          onClick={() => {
            handleClose();
          }}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
          disableRipple={true}
        >
          <Box
            sx={{
              width: "20px",
              height: "20px",
              color: "#000000",
              border: 2,
              fontSize: "18px",
              borderRadius: "5px",
            }}
          >
            X
          </Box>
        </IconButton>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <Box
            component={"img"}
            src={externalLink}
            sx={{
              height: "50px",
              width: "55px",
              mt: "28px",
            }}
          />
          <Typography
            sx={{
              fontSize: "20px",
              fontWeight: theme.typography.Bold,
              mt: "15px",
              mb: "8px",
            }}
          >
            {i18n["linkitUp.linkitup"]}
          </Typography>
          <Typography
            sx={{
              fontSize: "18px",
              fontWeight: theme.typography.Regular,
              width: "314px",
              mb: "15px",
              textAlign: "center",
            }}
          >
            {i18n["linkitUp.enteredit"]}
          </Typography>
          <InputBox
            sx={{
              width: "300px",
              height: "38px",
              borderRadius: "10px",
              border:
                errors.key === "externallink"
                  ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
                  : "1px solid #E0E0E0",
              my: "15px",
            }}
            defaultValue={selectedJob?.external_link}
            id="external_link"
            onChange={handleInputChange}
          />
          <Typography
            sx={{
              fontSize: "18px",
              fontWeight: theme.typography.Regular,
              width: "314px",
              mb: "18px",
              textAlign: "center",
            }}
          >
            {i18n["linkitUp.applicationsviaCrayon"]}
          </Typography>
        </Box>
        <Button
          variant="contained"
          color="redButton"
          sx={{
            width: "100%",
            height: "60px",
            borderRadius: "0 0 25px 25px",
          }}
          onClick={() => handleSave()}
        >
          save
        </Button>
      </Box>
    </Modal>
  );
}
