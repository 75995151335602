import {
  FormControl,
  InputBase,
  InputLabel,
  MenuItem,
  Modal,
  Paper,
  Select,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import "cropperjs/dist/cropper.css";
import { useTheme } from "@emotion/react";
import SelectMenu from "../../../common/SelectMenu";

const AddEditAssessmentScoreModal = ({
  handleOpen,
  handleClose,
  handleCreate,
  handleCancel,
  isEdit,
  selectedScoreItem,
  setFormData,
  formData,
  // handleFormInput,
}) => {
  const theme = useTheme();
  const [error, setError] = useState(false);
  // const [formData, setFormData] = useState();
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: { xs: "auto", tablet: 700 },
    bgcolor: "background.paper",
    borderRadius: "25px",
    boxShadow: "none !important",
    "&:focus-visible": {
      outline: "none",
    },
  };
  const [scoreOption, setScoreOption] = useState([
    { id: "1", name: "1" },
    { id: "2", name: "2" },
    { id: "3", name: "3" },
    { id: "4", name: "4" },
    { id: "5", name: "5" },
  ]);
  const handleFormInput = (e, name) => {
    let updatedData = { ...formData };
    updatedData[name] = e.target.value;
    setFormData({ ...updatedData });
  };
  useEffect(() => {
    if (selectedScoreItem?.name) {
      setFormData({
        name: selectedScoreItem?.name,
        score: selectedScoreItem?.value,
      });
    }
  }, [selectedScoreItem]);

  const handleWorkSetup = (event) => {
    const {
      target: { value },
      target: { name },
      target: { id },
    } = event;
    const newBasicData = {
      ...formData,
      [name || id]: value,
    };
    setFormData(newBasicData);
  };
  return (
    <Modal open={handleOpen} onClose={handleClose}>
      <Box sx={style}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "end",
          }}
        >
          <Button
            variant="contained"
            color="yellowButton400"
            sx={{
              height: "45px",
              width: "200px",
              borderRadius: "0 25px 0 25px",
            }}
          >
            {isEdit ? "Edit Score items" : "Add Score items"}
          </Button>
        </Box>
        <Box
          sx={{
            padding: "20px",
            display: "flex",
            alignItems: "end",
            width: "100%",
          }}
        >
          <Box sx={{ marginBottom: "5px" }}>
            <InputLabel
              htmlFor="type"
              sx={{
                color: "black",
                paddingBottom: "2px",
                fontSize: "14px",
                fontWeight: 600,
              }}
            >
              Text option
            </InputLabel>
            <Paper
              elevation={3}
              sx={{
                display: "flex",
                borderRadius: "10px",
                height: "40px",
                boxShadow: "none",
                border: error
                  ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
                  : `1px solid ${theme.palette.grayBorder}`,
              }}
            >
              <InputBase
                // disabled={true}
                type="type"
                sx={{ ml: 2, mr: 2, width: "100%" }}
                id="type"
                placeholder={"Enter Text Option"}
                value={formData?.name}
                onChange={(e) => handleFormInput(e, "name")}
              />
            </Paper>
          </Box>
          <Box sx={{ marginBottom: "7px", width: "200px", marginLeft: "20px" }}>
            <InputLabel
              htmlFor="type"
              sx={{
                color: "black",
                paddingBottom: "2px",
                fontSize: "14px",
                fontWeight: 600,
              }}
            >
              Score
            </InputLabel>
            {/* <SelectMenu
                clear={false}
                padding={"20px"}
                // name="score"
                onHandleChange={handleWorkSetup}
                // clear={true}
              
                // placeholder={"Select Score"}
                border= {"1px solid #E0E0E0"}
                borderRadius="10px"
                height="40px"
                width={{ xs: "100%", }}
                name="score"
                value={formData?.score}
                onFocus={() => {
                  // if (roleTypes?.length === 0) {
                  //   getAllData("roleType");
                  // }
                }}
                options={scoreOption}
              /> */}
            <Paper
              elevation={3}
              sx={{
                display: "flex",
                borderRadius: "10px",
                height: "40px",
                boxShadow: "none",
                border: error
                  ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
                  : `1px solid ${theme.palette.grayBorder}`,
              }}
            >
              <InputBase
                // disabled={true}
                type="number"
                sx={{ ml: 2, mr: 2, width: "100%" }}
                id="score"
                placeholder={"Enter Score Value"}
                value={formData?.score}
                onChange={(e) => {
                  const value = e.target.value;
                  if (!value || (/^\d+$/.test(value) && parseInt(value) >= 0)) {
                    handleFormInput(e, "score");
                  }
                }}
                inputProps={{
                  min: 0,
                }}
              />
            </Paper>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            width: "100%",
          }}
        >
          <Button
            variant="contained"
            color="grayButton100"
            sx={{
              borderRadius: 0,
              width: "50%",
              height: "47px",
              borderBottomLeftRadius: 25,
            }}
            onClick={handleCancel}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="yellowButton100"
            sx={{
              borderRadius: 0,
              width: "50%",
              height: "47px",
              borderBottomRightRadius: 25,
            }}
            onClick={handleCreate}
          >
            {isEdit ? "Edit" : "Add"}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default AddEditAssessmentScoreModal;
