import { useRef, useState } from "react";
import MyJobCardFront from "./MyJobCardFront";
import MyJobCardBack from "./MyJobCardBack";

import ReactCardFlip from "react-card-flip";
import { Box, Typography } from "@mui/material";
import { useNavigate } from "react-router";
import theme from "../../../utils/Theme";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import ReviewVideoDialog from "../ReviewVideoDialog";
import { getEmployerVideoLink } from "../../../redux/employer/empJobListing";
import jwt_decode from "jwt-decode";
import { getToken } from "../../../utils/Common";
import UploadJobVideo from "./UploadJobVideo";
import { ALERT_TYPE } from "../../../utils/Constants";
import { setAlert } from "../../../redux/configSlice";
import CoinSpendBox from "../CoinSpendBox";
import locale from "../../../i18n/locale";
import {
  getJobCoins,
  unlockJob,
} from "../../../redux/employer/empProfileSlice";
import {
  getNotificationCount,
  setCoinsCount,
  setNotificationCount,
} from "../../../redux/candidate/alerts";
import ExteranalLinkBox from "./ExteranalLinkBox";
import MySpecFrontCard from "./MySpecFrontCard";
import MySpecBackCard from "./MySpecBackCard";

export default function JobCard({
  index,
  job,
  getJobs,
  newCardWidth,
  setAllJobs,
  jobslength,
  currentPageJobsCount,
  allJobs,
  setActiveStep,
  setSelectedPage,
}) {
  const dispatch = useDispatch();
  const token = getToken();
  const i18n = locale.en;
  const [isHorizontalFlipped, setisHorizontalFlipped] = useState(false);
  const navigate = useNavigate();
  const [hasJobVideo, setHasJobVideo] = useState(!job?.job_video);
  const [openReviewVideoDialog, setOpenReviewVideoDialog] = useState(false);
  const [openRecordDialog, setOpenRecordDialog] = useState(false);
  const [videoLink, setVideoLink] = useState("");
  const [coinSpend, setCoinSpend] = useState(false);
  const [noCoins, setNoCoins] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const [coinsInfo, setCoinsInfo] = useState();
  const [selectedJob, setSelectedJob] = useState();
  const [getCoins, setGetCoins] = useState(false);
  const [jobExtend, setJobExtend] = useState(false);
  const [openExternal, setOpenExternal] = useState(false);

  let decodedToken;
  if (token) {
    decodedToken = jwt_decode(token);
  }

  const handleOpenRecordDialog = () => {
    setOpenRecordDialog((prevState) => !prevState);
  };

  const handleCloseDialog = () => {
    setVideoLink("");
    setOpenReviewVideoDialog(false);
  };

  const handleNoCoinsButtonClick = () => {
    if (coinsInfo?.coins === 0) {
      setNoCoins(false);
    } else {
      unlockAJob();
    }
  };

  const handleCoinSpendButtonClick = () => {
    unlockAJob();
  };

  const handleConfirmButtonClick = () => {
    setConfirm(false);
    setCoinSpend(false);
    setNoCoins(false);
  };

  const getCount = async () => {
    try {
      const { payload } = await dispatch(getNotificationCount());
      if (payload?.status === "success") {
        dispatch(setNotificationCount(payload?.count));
        dispatch(setCoinsCount(payload?.coinsCounter));
      }
    } catch (error) {}
  };

  const unlockAJob = async () => {
    try {
      let data = {
        job_id: selectedJob?.job_id,
        unlock_amount: coinsInfo?.unlock_amount,
        company_id: selectedJob?.company_id,
        transaction_name_id: coinsInfo?.transaction_name_id,
        employer_unlock: true,
        jobExtend: jobExtend && jobExtend,
      };
      const { payload } = await dispatch(unlockJob(data));
      if (payload?.status === "success") {
        if (coinsInfo?.coins === 0) {
          setNoCoins(false);
          setConfirm(true);
        } else {
          setCoinSpend(false);
          // setConfirm(true);
        }
        getCount();
        let newJobs = allJobs?.map((item) =>
          item?.job_id === selectedJob?.job_id
            ? {
                ...item,
                job_unlock: true,
              }
            : item
        );
        setAllJobs(newJobs);
      } else {
        setCoinSpend(false);
      }
    } catch (error) {
      dispatch(
        setAlert({
          show: true,
          type: ALERT_TYPE?.ERROR,
          msg: error,
        })
      );
    }
  };

  const getCoinBalance = async () => {
    try {
      const { payload } = await dispatch(
        getJobCoins({
          job_id: selectedJob?.job_id,
          company_id: selectedJob?.company_id,
          jobExtend: jobExtend && jobExtend,
        })
      );
      if (payload?.status === "success") {
        setCoinsInfo(payload?.data);
        if (payload?.data?.coins === 0) {
          setCoinSpend(false);
          setNoCoins(true);
        } else {
          setNoCoins(false);
          setCoinSpend(true);
        }
        setGetCoins(false);
      }
    } catch (error) {}
  };

  useEffect(() => {
    const fetchVideoLink = async () => {
      try {
        const dataLink = await dispatch(
          getEmployerVideoLink({ job_id: job?.job_id })
        );
        setVideoLink(dataLink?.payload?.data?.job_video_url);
      } catch (error) {
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE?.ERROR,
            msg: error,
          })
        );
      }
    };

    if (openReviewVideoDialog) {
      fetchVideoLink();
    }
  }, [dispatch, job?.job_id, openReviewVideoDialog]);

  useEffect(() => {
    getCoins && getCoinBalance();
  }, [getCoins]);

  return (
    <>
      <ReactCardFlip
        isFlipped={isHorizontalFlipped}
        flipDirection={"horizontal"}
        flipSpeedBackToFront="0.5"
        flipSpeedFrontToBack="0.5"
        containerStyle={{
          height: "100%",
        }}
        key={job?.job_id}
      >
        <MySpecFrontCard
          index={index}
          job={job}
          getJobs={getJobs}
          setisFlipped={setisHorizontalFlipped}
          handleReviewVideoDialog={setOpenReviewVideoDialog}
          newCardWidth={newCardWidth}
          hasJobVideo={hasJobVideo}
          setOpenRecordDialog={setOpenRecordDialog}
          jobslength={jobslength}
          indexNumber={index}
          currentPageJobsCount={currentPageJobsCount}
          allJobs={allJobs}
          setAllJobs={setAllJobs}
          setCoinSpend={setCoinSpend}
          setJobExtend={setJobExtend}
          setNoCoins={setNoCoins}
          setSelectedJob={setSelectedJob}
          setGetCoins={setGetCoins}
          setOpenExternal={setOpenExternal}
          setActiveStep={setActiveStep}
          setSelectedPage={setSelectedPage}
        />
        <MySpecBackCard
          index={index}
          job={job}
          getJobs={getJobs}
          setisFlipped={setisHorizontalFlipped}
          handleReviewVideoDialog={setOpenReviewVideoDialog}
          newCardWidth={newCardWidth}
          hasJobVideo={hasJobVideo}
          setOpenRecordDialog={setOpenRecordDialog}
          jobslength={jobslength}
          indexNumber={index}
          isHorizontalFlipped={isHorizontalFlipped}
          currentPageJobsCount={currentPageJobsCount}
          allJobs={allJobs}
          setAllJobs={setAllJobs}
          setCoinSpend={setCoinSpend}
          setJobExtend={setJobExtend}
          setNoCoins={setNoCoins}
          setSelectedJob={setSelectedJob}
          setGetCoins={setGetCoins}
          setOpenExternal={setOpenExternal}
          setActiveStep={setActiveStep}
          setSelectedPage={setSelectedPage}
        />
      </ReactCardFlip>
      {openReviewVideoDialog && (
        <ReviewVideoDialog
          videoLink={videoLink}
          open={openReviewVideoDialog}
          setOpen={handleCloseDialog}
        />
      )}
      {coinSpend && (
        <CoinSpendBox
          text1={i18n["myplanPopup.confirmcoinspend"]}
          text1Width={"186px"}
          text2={i18n["myplanPopup.youcurrentlyhave"]}
          // coinsBalance={"60 coins."}
          // coinsUse={"30 coins."}
          coinsBalance={`${coinsInfo?.coins} coins.`}
          coinsUse={`${coinsInfo?.unlock_amount} coins.`}
          text2Width={"236px"}
          text3={i18n["myplanPopup.thisjobpostwilluse"]}
          text3Width={"270px"}
          text3Height={"72px"}
          text4={i18n["myplanPopup.pleaseconfirm"]}
          text4Width={"220px"}
          buttonText={i18n["myplanPopup.happyletsdothis"]}
          handleClose={() => setCoinSpend(false)}
          show={coinSpend}
          buttonClick={handleCoinSpendButtonClick}
          gap={"18px"}
        />
      )}
      {noCoins && (
        <CoinSpendBox
          text1={i18n["myplanPopup.confirmcoinspend"]}
          text1Width={"186px"}
          text2={i18n["myplanPopup.youcurrentlyhave"]}
          text2Width={"224px"}
          text3={i18n["myplanPopup.jobpurchase"]}
          text3Width={"232px"}
          text4={i18n["myplanPopup.pleaseconfirm"]}
          text4Width={"220px"}
          buttonText={"happy, let’s do this"}
          coinsBalance={`${coinsInfo?.coins} coins.`}
          coinsUse={`${coinsInfo?.unlock_amount} coins.`}
          handleClose={() => setNoCoins(false)}
          show={noCoins}
          buttonClick={handleNoCoinsButtonClick}
          gap={"15px"}
        />
      )}
      {confirm && (
        <CoinSpendBox
          text1={i18n["myplanPopup.nowyourecoiningit"]}
          text1Width={"208px"}
          text3={i18n["myplanPopup.crayonadminwillreview"]}
          text3Width={"252px"}
          text3Height={"72px"}
          buttonText={"got it, thanks"}
          handleClose={() => setConfirm(false)}
          show={confirm}
          buttonClick={handleConfirmButtonClick}
          gap={"20px"}
        />
      )}

      {openExternal && (
        <ExteranalLinkBox
          setAllJobs={setAllJobs}
          allJobs={allJobs}
          selectedJob={selectedJob}
          open={openExternal}
          handleClose={() => setOpenExternal(false)}
        />
      )}

      <UploadJobVideo
        show={openRecordDialog}
        closeFunc={handleOpenRecordDialog}
        job_id={job?.job_id}
        job={job}
        setHasJobVideo={setHasJobVideo}
        getJobs={getJobs}
        setAllJobs={setAllJobs}
      />
    </>
  );
}
