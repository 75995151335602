import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { postApi, getApi } from "../../utils/Apis";
import { setLoading } from "../configSlice";
const initialState = {
  companies: [],
  initialCompanies: [],
  candidateCompany: [],
  initialCandidateCompanies: [],
  titles: [],
  companyType: [],
  companySize: [],
  countries: [],
  candiateCompanies: [],
  associatedJobs: [],
  initialAssociatedJobs: [],
  associatedUsers: [],
  initialAssociatedUsers: [],
  spendItem: [],
  profileSteps: 0,
};

export const getAssociatedJobs = createAsyncThunk(
  "getAssociatedJobs",
  async ({ company_id, title }, { dispatch }) => {
    const { data } = await getApi(
      `/getassociatedjobs?company_id=${company_id}&title=${title}`,
      true
    );
    return data;
  }
);

export const reviewEmployerPlan = createAsyncThunk(
  "reviewEmployerPlan",
  async (payload, { dispatch }) => {
    dispatch(setLoading(true));
    const { data } = await postApi("/employer/updatemyplan", payload, true);
    dispatch(setLoading(false));
    return data;
  }
);

export const getAssociatedUser = createAsyncThunk(
  "getAssociatedUser ",
  async ({ company_id, name }, { dispatch }) => {
    const { data } = await getApi(
      `/getteamdetails?company_id=${company_id}&name=${name}`,
      true
    );
    return data;
  }
);
export const getPlanDetails = createAsyncThunk(
  "getPlanDetails ",
  async () => {
    const { data } = await getApi(
      `/getyourplan`,
      true
    );
    return data;
  }
);

export const talentPlanRequest = createAsyncThunk(
  "talentPlanRequest",
  async (payload, { dispatch }) => {
    dispatch(setLoading(true));
    const { data } = await postApi(
      `/employer/talentplanrequest`,
      payload,
      true
    );
    dispatch(setLoading(false));
    return data;
  }
);
export const getSpendItem = createAsyncThunk(
  "getSpendItem ",
  async (payload, { dispatch }) => {
    const { data } = await getApi(
      `/admin/maintenance/gettransactionsname`,
      true
    );
    return data;
  }
);

export const claimVoucher = createAsyncThunk(
  "claimVoucher",
  async (payload, { dispatch }) => {
    // dispatch(setLoading(true));
    const { data } = await postApi("/admin/claimvoucher", payload, true);
    // dispatch(setLoading(false));
    return data;
  }
);

export const purchaseRequest = createAsyncThunk(
  "purchaseRequest",
  async (payload, { dispatch }) => {
    dispatch(setLoading(true));
    const { data } = await postApi(
      "/employer/addpaymentrequest",
      payload,
      true
    );
    dispatch(setLoading(false));
    return data;
  }
);

export const unlockCVandVideo = createAsyncThunk(
  "unlockCVandVideo",
  async (payload, { dispatch }) => {
    dispatch(setLoading(true));
    const { data } = await postApi("/cvVideoUnlock", payload, true);
    dispatch(setLoading(false));
    return data;
  }
);

export const unlockJob = createAsyncThunk(
  "unlockJob",
  async (payload, { dispatch }) => {
    dispatch(setLoading(true));
    const { data } = await postApi("/employer/unlockjob", payload, true);
    dispatch(setLoading(false));
    return data;
  }
);

export const saveExternalLink = createAsyncThunk(
  "saveExternalLink",
  async (payload, { dispatch }) => {
    dispatch(setLoading(true));
    const { data } = await postApi("/employer/editexternallink", payload, true);
    dispatch(setLoading(false));
    return data;
  }
);

export const getTopUpPlans = createAsyncThunk(
  "getTopUpPlans",
  async (payload, { dispatch }) => {
    const { data } = await getApi(`/employer/getTopUpPlans`, true);
    return data;
  }
);

export const getJobCoins = createAsyncThunk(
  "getJobCoins",
  async (
    { job_id, company_id, jobExtend, videoUnlock, cvUnlock },
    { dispatch }
  ) => {
    const { data } = await getApi(
      jobExtend
        ? `/employer/getJobCoins?job_id=${job_id}&company_id=${company_id}&jobExtend=${jobExtend}`
        : cvUnlock
        ? `/employer/getJobCoins?company_id=${company_id}&cvUnlock=${cvUnlock}`
        : videoUnlock
        ? `/employer/getJobCoins?company_id=${company_id}&videoUnlock=${videoUnlock}`
        : `/employer/getJobCoins?job_id=${job_id}&company_id=${company_id}`,
      true
    );
    return data;
  }
);
export const addNewUserCoin =createAsyncThunk(
  "addNewUserCoin",
  async (
    {company_id,  },
    { dispatch }
  ) => {
    const { data } = await getApi(
      `/employer/getJobCoins?company_id=${company_id}&addNewUser=true`,
      true
    );
    return data;
  }
);

export const addNewUserLicense =createAsyncThunk(
  "addNewUserLicense",
  async (payload, { dispatch }) => {
    dispatch(setLoading(true));
    const { data } = await postApi(
      "/employer/addnewuserlicense",
      payload,
      true
    );
    dispatch(setLoading(false));
    return data;
  }
);
export const getCountry = createAsyncThunk(
  "getCountry",
  async (payload, { dispatch }) => {
    const { data } = await getApi("/regions", true);
    return data;
  }
);

export const getCountries = createAsyncThunk(
  "getCountries",
  async (payload, { dispatch }) => {
    const { data } = await getApi("/countries", true);
    return data;
  }
);
export const getTitles = createAsyncThunk(
  "getTitles",
  async (payload, { dispatch }) => {
    const { data } = await getApi("/job/titles", true);
    return { data, initial: payload?.title ? false : true };
  }
);

export const getEmpProfile = createAsyncThunk(
  "getEmpProfile",
  async (payload, { dispatch }) => {
    const { data } = await getApi("/admin/employer/profile", true);
    return data;
  }
);

export const getEmpBadges = createAsyncThunk(
  "getEmpBadges",
  async (payload, { dispatch }) => {
    const { data } = await getApi("/employer/getEmployerBadges", true);
    return data;
  }
);

export const getCompanySize = createAsyncThunk(
  "getCompanySize",
  async (payload, { dispatch }) => {
    const { data } = await getApi("/employer/getCompanySize", true);
    return data;
  }
);

export const getCompanyType = createAsyncThunk(
  "getCompanyType",
  async (payload, { dispatch }) => {
    const { data } = await getApi("/employer/getCompanyType", true);
    return data;
  }
);

export const getCompanyDetail = createAsyncThunk(
  "getCompanyDetail",
  async ({ company_id }, { dispatch }) => {
    const { data } = await getApi(
      "/getCompanyDetail?company_id=" + company_id,
      true
    );
    return data;
  }
);
export const getCompanyDetailNew = createAsyncThunk(
  "getCompanyDetailNew",
  async ({ company_id }, { dispatch }) => {
    const { data } = await getApi(
      "/company/getCompanyDetail?company_id=" + company_id,
      true
    );
    return data;
  }
);
export const cancelApproval = createAsyncThunk(
  "cancelApproval",
  async (payload, { dispatch }) => {
    dispatch(setLoading(true));
    const { data } = await postApi("/employer/removerequest", payload, true);
    dispatch(setLoading(false));
    return data;
  }
);

export const getTown = createAsyncThunk(
  "getTown",
  async (payload, { dispatch }) => {
    const { data } = await getApi(
      payload?.title
        ? `/towns?country_id=${payload?.id}&title=${payload?.title}&limit=${payload?.limit}`
        : `/towns?country_id=${payload}`,
      true
    );
    return data;
  }
);

export const getIndustries = createAsyncThunk(
  "getIndustries",
  async (payload, { dispatch }) => {
    const { data } = await getApi("/industries");
    return data;
  }
);

export const getCompanies = createAsyncThunk(
  "getCompanies",
  async (payload, { dispatch }) => {
    if (payload?.setState)
      payload?.setState((prevState) => ({
        ...prevState,
        [payload?.type]: true,
      }));
    let url = "/employer/allCompanies";
    if (payload && Object?.keys(payload)?.length) {
      const queryParams = Object?.keys(payload)?.map(
        (key) => `${key}=${encodeURIComponent(payload[key])}`
      );
      url += `?${queryParams?.join("&")}`;
    }
    const { data } = await getApi(url, true);
    if (payload?.setState)
      payload?.setState((prevState) => ({
        ...prevState,
        [payload?.type]: false,
      }));
    return { data, initial: payload?.title ? false : true };
  }
);

export const getCandidateCompany = createAsyncThunk(
  "getCandidateCompany",
  async (payload, { dispatch }) => {
    if (payload?.setState)
      payload?.setState((prevState) => ({
        ...prevState,
        [payload?.type]: true,
      }));
    let url = "/candidate/getallcandidatecompanies";
    if (payload && Object.keys(payload).length) {
      const queryParams = Object.keys(payload).map(
        (key) => `${key}=${encodeURIComponent(payload[key])}`
      );
      url += `?${queryParams.join("&")}`;
    }
    const { data } = await getApi(url, true);
    if (payload?.setState)
      payload?.setState((prevState) => ({
        ...prevState,
        [payload?.type]: false,
      }));
    return { data, initial: payload?.title ? false : true };
  }
);

export const getCandidateCompanies = createAsyncThunk(
  "getCandidateCompanies",
  async ({ industry_id, title }, { dispatch }) => {
    const { data } = await getApi(
      "/candidate/getCompanies?industry_id=" + industry_id + "&title=" + title,
      true
    );
    return data;
  }
);

export const getSecondaryCompanyData = createAsyncThunk(
  "getSecondaryCompanyData",
  async (payload, { dispatch }) => {
    const { data } = await getApi("/employer/getsecondarycompany", true);
    return data;
  }
);

export const createInfo = createAsyncThunk(
  "createInfo",
  async (payload, { dispatch }) => {
    dispatch(setLoading(true));
    const { data } = await postApi(
      "/admin/employer/profile/basics",
      payload,
      true
    );
    dispatch(setLoading(false));
    return data;
  }
);

export const deleteCompany = createAsyncThunk(
  "deleteCompany",
  async ({ company_id }, { dispatch }) => {
    dispatch(setLoading(true));
    const { data } = await getApi(
      "/employer/deleteSecondarycompany?company_id=" + company_id,
      true
    );
    dispatch(setLoading(false));
    return data;
  }
);

export const postSecondaryCompanyData = createAsyncThunk(
  "postSecondaryCompanyData",
  async (payload, { dispatch }) => {
    dispatch(setLoading(true));
    const { data } = await postApi(
      "/employer/postsecondarycompany",
      payload,
      true
    );
    dispatch(setLoading(false));
    return data;
  }
);

export const editSecondaryCompanyData = createAsyncThunk(
  "editSecondaryCompanyData",
  async (payload, { dispatch }) => {
    dispatch(setLoading(true));
    const { data } = await postApi(
      "/employer/editsecondarycompany",
      payload,
      true
    );
    dispatch(setLoading(false));
    return data;
  }
);

export const createCompInfo = createAsyncThunk(
  "createCompInfo",
  async (payload, { dispatch }) => {
    dispatch(setLoading(true));
    const { data } = await postApi(
      "/admin/employer/profile/details",
      payload,
      true
    );
    dispatch(setLoading(false));
    return data;
  }
);

export const resetLoggedInUserPassword = createAsyncThunk(
  "resetLoggedInUserPassword",
  async (payload, { dispatch }) => {
    dispatch(setLoading(true));
    const { data } = await postApi("/userupdatepassword", payload, true);
    dispatch(setLoading(false));
    return data;
  }
);

export const resetPassword = createAsyncThunk(
  "resetPassword",
  async (payload, { dispatch }) => {
    dispatch(setLoading(true));
    const { data } = await postApi("/reset/password", payload, true);
    dispatch(setLoading(false));
    return data;
  }
);

export const empProfileSlice = createSlice({
  name: "myProfile",
  initialState,
  reducers: {
    clearCompany: (state, action) => {
      return { ...state, companies: [] };
    },
    setProfileSteps: (state, action) => {
      state.profileSteps = action.payload;
    },
    clearAllCompany: (state, action) => {
      state.companies = [];
    },
    updateInitialCompanies: (state, action) => {
      return { ...state, companies: state?.initialCompanies };
    },
    updateInitialCandidateCompanies: (state, action) => {
      return { ...state, candidateCompany: state?.initialCandidateCompanies };
    },
    updateInitialAssociatedUser: (state, action) => {
      return { ...state, associatedUsers: state?.initialAssociatedUsers };
    },
    updateInitialAssociatedJobs: (state, action) => {
      return { ...state, associatedJobs: state?.initialAssociatedJobs };
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getCompanies.fulfilled, (state, action) => {
        state.initialCompanies = action?.payload?.initial
          ? action?.payload?.data?.data?.map((company) => {
              return {
                ...company,
                id: company?.company_id,
                name: company?.name,
              };
            })
          : state.initialCompanies;
        state.companies = action?.payload?.data?.data?.map((company) => {
          return {
            ...company,
            id: company?.company_id,
            name: company?.name,
          };
        });
      })
      .addCase(getCandidateCompany.fulfilled, (state, action) => {
        state.initialCandidateCompanies = action?.payload?.initial
          ? action?.payload?.data?.data?.map((company) => {
              return {
                ...company,
                id: company?.candidate_company_id,
                name: company?.name,
              };
            })
          : state.initialCandidateCompanies;
        state.candidateCompany = action?.payload?.data?.data?.map((company) => {
          return {
            ...company,
            id: company?.candidate_company_id,
            name: company?.name,
          };
        });
      })
      .addCase(getCandidateCompanies?.fulfilled, (state, action) => {
        state.candiateCompanies = action?.payload?.data?.map((company) => {
          return {
            ...company,
            id: company?.company_id,
            name: company?.name,
          };
        });
      })
      .addCase(getCountries.fulfilled, (state, action) => {
        state.countries = action.payload.data.map((company) => {
          return {
            ...company,
            id: company.country_id,
            name: company.name,
          };
        });
      })
      .addCase(getTitles.fulfilled, (state, action) => {
        state.titles = action?.payload?.data?.data?.map((title) => {
          return {
            ...title,
            id: title?.job_title_id,
            name: title?.title,
          };
        });
      })
      .addCase(getAssociatedJobs.fulfilled, (state, action) => {
        state.associatedJobs = action?.payload?.data?.map((title) => {
          return {
            ...title,
            id: title?.job_title_id,
            name: title?.title,
          };
        });
      })
      .addCase(getAssociatedUser.fulfilled, (state, action) => {
        state.associatedUsers = action?.payload?.data?.map((title) => {
          return {
            ...title,
            id: title?.user_id,
            name: `${title?.user?.first_name} ${title?.user?.last_name}`,
          };
        });
      })
      .addCase(getSpendItem.fulfilled, (state, action) => {
        state.spendItem = action?.payload?.data?.map((title) => {
          return {
            ...title,
            id: title?.spend_id,
            name: title?.name,
          };
        });
      })
      .addCase(getCompanyType.fulfilled, (state, action) => {
        state.companyType = action.payload.data.map((title) => {
          return {
            ...title,
            id: title.company_type_id,
            // name: title.title,
          };
        });
      })
      .addCase(getCompanySize.fulfilled, (state, action) => {
        state.companySize = action.payload.data.map((title) => {
          return {
            ...title,
            id: title.company_size_id,
            // name: title.title,
          };
        });
      });
  },
});

// Action creators are generated for each case reducer function
export const {
  clearCompany,
  setProfileSteps,
  clearAllCompany,
  updateInitialCompanies,
  updateInitialCandidateCompanies,
  updateInitialAssociatedJobs,
  updateInitialAssociatedUser,
} = empProfileSlice.actions;

export default empProfileSlice.reducer;
