import {
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import strongMatch from "../../../../assets/CrayBotIcons/Crayon Craybot Notification-07.svg";
import averageMatch from "../../../../assets/CrayBotIcons/Crayon Craybot Notification-09.svg";
import weakMatch from "../../../../assets/CrayBotIcons/Crayon Craybot Notification-05.svg";
import { useTheme } from "@emotion/react";
import MUIRadialChart from "../../../common/MUIRadialChart";
import close from "../../../../assets/Padding Included/Black_Close.svg";
import applyIcon from "../../../../assets/Padding Excluded/Black_Follower.svg";
import { getMatchData } from "../../../../redux/guest/jobsSlice";
import { useDispatch } from "react-redux";
import { setAlert } from "../../../../redux/configSlice";
import { ALERT_TYPE } from "../../../../utils/Constants";
import { LightTooltip } from "../../../../utils/CommonComponent";

import locale from "../../../../i18n/locale";

export default function RunMatchTalent({
  job,
  closeFunc,
  setMatchButton,
  setRunMatch,
  setShortlist,
  selectedJobID,
}) {
  const theme = useTheme();
  const i18n = locale.en;
  const dispatch = useDispatch();
  const [match, setMatch] = useState();

  const getColor = (value) => {
    if (value <= 24) {
      return "weakMatch";
    } else if (value <= 74) {
      return "averageMatch";
    } else {
      return "strongMatch";
    }
  };

  const getMatch = async () => {
    try {
      const data = {
        job_id: selectedJobID,
        user_id: job?.user_id,
      };
      const { payload } = await dispatch(getMatchData(data));
      if (payload?.status === "success") {
        setMatch(payload?.data);
      }
    } catch (error) {
      dispatch(
        setAlert({
          show: true,
          type: ALERT_TYPE?.ERROR,
          msg: error,
        })
      );
    }
  };

  useEffect(() => {
    getMatch();
  }, []);
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: { sm: "576px", xl: "586px" },
        width: "100%",
        justifyContent: "space-between",
      }}
    >
      <Box sx={{ display: "flex", flexDirection: "column", gap: "10px" }}>
        <Box sx={{ marginBottom: "15px" }}>
          <Button
            variant="contained"
            color="betaVersion"
            sx={{
              fontSize: "14px",
              borderRadius: "10px 0px 10px 0px",
              color: "white",
              fontWeight: theme.typography.Bold,
              height: "45px",
              //   width: "250px",
              padding: "13.5px 12px 12.5px 22px",
              ":hover": {
                boxShadow: "none !important",
              },
            }}
          >
            {i18n["matchMeBox.beta"]}
          </Button>
          <IconButton
            aria-label="close"
            onClick={() => {
              closeFunc();
            }}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: "black",
              ":hover": {
                backgroundColor: "transparent",
              },
            }}
          >
            {/* <CloseIcon /> */}
            {/* <Box
          sx={{
            width: "20px",
            height: "20px",
            color: "#000000",
            border: 2,
            fontSize: "16px",
            borderRadius: "5px",
          }}
        >
          x
        </Box> */}
            <Box
              component={"img"}
              src={close}
              sx={{
                border: 2,
                borderRadius: "5px",
                height: 20,
                width: 20,
              }}
            />
          </IconButton>
        </Box>

        <Box
          sx={{
            padding: "5px 16px",
            height: "150px",
            marginBottom: "12px",
          }}
        >
          <Box
            mb={2}
            sx={{
              display: "flex",
              gap: 1,
            }}
          >
            <Typography
              sx={{
                fontSize: "14px",
                fontWeight: theme.typography.Bold,
              }}
            >
              {i18n["matchMeBox.talentMatchPer"]}
            </Typography>
            <Tooltip disableInteractive>
              <LightTooltip
                title={
                  "This percentage match does not necessarily mean you will or will not be shortlisted for a job, but it helps provide a quick indicator of your suitability. "
                }
                placement="right-end"
              >
                <Typography
                  sx={{
                    padding: "5px",
                    height: "8px",
                    width: "8px",
                    borderRadius: "5px",
                    fontSize: "15px",
                    lineHeight: 0.1,
                    /* text-align: center; */
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    fontWeight: 700,
                    border: 1,
                  }}
                >
                  i
                </Typography>
              </LightTooltip>
            </Tooltip>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 2,
            }}
          >
            <Typography
              sx={{
                width: "327px",
                fontSize: "12px",
                // textAlign: "left",
                color: "black",
                fontWeight: theme.typography.Medium,
              }}
            >
              {i18n["matchMeBox.craybotInfo"]}
            </Typography>
            <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
              <Box
                component={"img"}
                src={
                  match?.matchingPercentage <= 25
                    ? weakMatch
                    : match?.matchingPercentage <= 75
                    ? averageMatch
                    : strongMatch
                }
                sx={{ width: 45, height: 75 }}
              />
              <Typography
                sx={{
                  width: "200px",
                  fontSize: "12px",
                  textAlign: "left",
                  color: "black",
                  fontWeight: theme.typography.Medium,
                }}
              >
                {i18n["matchMeBox.remember"]}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          gap: 2,
          flexDirection: "column",
          margin: "auto",
          width: "40%",
          // marginBottom: "38px",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          {match ? (
            <MUIRadialChart
              value={match?.matchingPercentage}
              chartName={"match"}
              max={100}
              size={120}
              countFontSize={20}
              labelFontSize={12}
              color={getColor(match?.matchingPercentage)}
              formattedValue={`${match?.matchingPercentage} %`}
              labelFontWeight={theme.typography.Regular}
            />
          ) : (
            <Box
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                margin: "0px",
                height: "100px",
                width: "100px",
              }}
            >
              <CircularProgress color="inherit" size={20} />
            </Box>
          )}
        </Box>
        <Typography
          sx={{
            fontWeight: theme.typography.Bold,
            fontSize: "14px",
            textAlign: "center",
          }}
          width={`${
            match?.matchingPercentage <= 25
              ? "303px"
              : match?.matchingPercentage <= 75
              ? "347px"
              : "313px"
          }`}
        >
          {match?.matchingPercentage <= 25
            ? `Craybot thinks they’re a weak match for this role; maybe don’t shortlist this candidate.`
            : match?.matchingPercentage <= 75
            ? `Craybot thinks they’re an average match for this role; shortlist this candidate if you feel they fit the criteria!`
            : `Craybot thinks they’re a strong match for this role; shortlist them while they’re still available!`}
        </Typography>
      </Box>
      <Grid
        container
        // padding="0 8px 8px 8px"
        alignItems="center"
        overflow={"hidden"}
        sx={{
          width: "100%",
          borderRadius: "0 0 10px 10px",
          height: "60px",
        }}
      >
        <Button
          variant="contained"
          sx={{
            borderRadius: 0,
            fontSize: "14px",
            fontWeight: theme.typography.Bold,
            whiteSpace: "nowrap",
            height: "100%",
            width: "33.33%",
            justifyContent: "center",
            ".MuiButton-startIcon": {
              marginRight: "0px !important",
              marginLeft: 0,
              justifyContent: "end",
              width: "26px",
            },
            padding: "0px !important",
            boxShadow: "none !important",
          }}
          color="grayButton600"
          onClick={() => {
            setMatchButton(true);
            setRunMatch(false);
          }}
        >
          {i18n["matchMeBox.back"]}
        </Button>
        <Button
          variant="contained"
          sx={{
            borderRadius: "0 0 10px 0",
            width: "66.67%",
            height: "100%",
            fontSize: "14px",
            fontWeight: theme.typography.Bold,
            ":hover": {
              boxShadow: 0,
            },
            ".MuiButton-startIcon": {
              marginRight: "0px !important",
              marginLeft: 0,
            },
            padding: "0px !important",
            boxShadow: "none !important",
            gap: 1,
          }}
          color="applyButton"
          startIcon={
            <Box
              component={"img"}
              sx={{
                height: "20px",
                width: "24px",
              }}
              src={applyIcon}
            />
          }
          onClick={() => {
            setShortlist(true);
            setRunMatch(false);
            setMatchButton(false);
          }}
        >
          {i18n["matchMeBox.shortlist"]}
        </Button>
      </Grid>
    </Box>
  );
}
