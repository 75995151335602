import { Slider, Typography, useTheme } from "@mui/material";
import React from "react";
import useViewport from "./ViewPort";

export default function SalarySlider({
  basicData,
  rangeValue,
  handleRangeSliderChange,
  handleRangeSliderChange2,
  rangeValueHandler,
  rangeValueHandler2,
  salaryRange,
  errors,
  salaryRangeValue,
  singleValueSlider,
  singleValueSlider2,
  singleSalaryValue,
  color,
  sx,
  name,
  jobRoleType,
}) {
  const theme = useTheme();
  const { width } = useViewport();
  return (
    <>
      {salaryRangeValue ? (
        <Slider
          disableSwap
          valueLabelDisplay="on"
          disabled={!basicData?.currency_id}
          name={name}
          getAriaLabel={() => "Temperature range"}
          value={rangeValue}
          onChange={
            jobRoleType == "freelance"
              ? handleRangeSliderChange2
              : handleRangeSliderChange
          }
          color={color}
          valueLabelFormat={
            jobRoleType == "freelance" ? rangeValueHandler2 : rangeValueHandler
          }
          getAriaValueText={
            jobRoleType == "freelance" ? rangeValueHandler2 : rangeValueHandler
          }
          marks={
            width < 768
              ? [salaryRange?.[0], salaryRange?.[salaryRange?.length - 1]]
              : salaryRange
          }
          // marks={
          //   basicData.job_role_type == "freelance"
          //     ? BASIC_RANGEMARKS2
          //     : salaryRange
          // }
          min={0}
          max={salaryRange?.length - 1}
          step={0.1}
          sx={sx}
        />
      ) : (
        <Slider
          disabled={!basicData?.currency_id}
          name={name}
          aria-label="Custom marks"
          value={singleSalaryValue}
          color={color}
          valueLabelFormat={
            jobRoleType == "freelance" ? rangeValueHandler2 : rangeValueHandler
          }
          getAriaValueText={
            jobRoleType == "freelance" ? rangeValueHandler2 : rangeValueHandler
          }
          min={0}
          max={salaryRange?.length - 1}
          step={0.1}
          onChange={
            jobRoleType == "freelance" ? singleValueSlider2 : singleValueSlider
          }
          valueLabelDisplay="on"
          marks={
            width < 768
              ? [salaryRange?.[0], salaryRange?.[salaryRange?.length - 1]]
              : salaryRange
          }
          sx={sx}
        />
      )}

      {errors?.find((error) => error?.key == "salary") && (
        <Typography color={"red"}>
          {`*${errors?.find((error) => error?.key == "salary")?.message}`}
        </Typography>
      )}
    </>
  );
}
