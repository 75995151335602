import { useLocation } from "react-router-dom";
import { formatCurrencyWithCommas } from "./Currency";
import jwt_decode from "jwt-decode";
import * as Yup from "yup";
import { FLOOR_VALUE } from "./Constants";
import DOMPurify from "dompurify";
import _ from "lodash";

export const truncateString = (str, length, element) => {
  if (!str) return "";

  // Create a temporary span element to measure text width
  const tempSpan = document?.createElement("span");
  tempSpan.style.visibility = "hidden";
  tempSpan.style.position = "absolute";
  tempSpan.style.whiteSpace = "nowrap";
  tempSpan.style.fontSize = "14px"; // Make sure this matches your button's font size
  document?.body?.appendChild(tempSpan);

  let truncatedStr = str;
  tempSpan.innerText = truncatedStr;
  while (tempSpan?.offsetWidth > element?.offsetWidth - 14) {
    // Adjust -20 based on padding/margin
    truncatedStr = truncatedStr.slice(0, -1);
    tempSpan.innerText = truncatedStr + "...";
  }

  document?.body?.removeChild(tempSpan);
  return truncatedStr?.length < str?.length
    ? truncatedStr + "..."
    : truncatedStr;
};

export const getDaysRemainingColor = (daysRemain) => {
  if (daysRemain < 10) {
    return "deleteVideoButton";
  } else if (daysRemain >= 10 && daysRemain <= 20) {
    return "recruiterButton";
  } else if (daysRemain > 20) {
    return "checkColor";
  } else {
    return "deleteVideoButton";
  }
};

export const getUserColor = (value) => {
  if (value === "Employers") {
    return "tymeColor";
  } else if (value === "Recruiters") {
    return "nandos";
  } else if (value === "Candidates") {
    return "yoco";
  } else if (value === "Promoters") {
    return "filterButtons";
  }
};

export const calculateDynamicHeight = () => {
  const screenWidth = window.innerWidth;

  if (screenWidth < 600) {
    // xs
    return `calc(100vh - 95px)`;
  } else if (screenWidth < 900) {
    // sm
    return `calc(100vh - 80px)`;
  } else if (screenWidth < 1366) {
    // md
    return `calc(100vh - 80px)`;
  } else if (screenWidth < 1920) {
    // lg
    return `calc(100vh - 80px)`;
  } else if (screenWidth < 2559) {
    // xl
    return `calc(100vh - 100px)`;
  } else {
    // xxl or greater
    return `calc(100vh - 120px)`;
  }
};

export const getContactDetail = (str) => {
  const indexOfSpace = str.indexOf(" ");
  if (indexOfSpace !== -1) {
    const firstPart = str.slice(0, indexOfSpace);
    const secondPartWithoutSpace = str
      .slice(indexOfSpace + 1)
      .replace(/\s/g, "");
    return [firstPart, secondPartWithoutSpace];
  } else {
    // Handle case where there's no whitespace
    return [str];
  }
};

export const calculatedHeight = () => {
  const screenWidth = window.innerWidth;

  if (screenWidth < 600) {
    // xs
    return `calc(100vh - 95px)`;
  } else if (screenWidth < 900) {
    // sm
    return `calc(100vh - 80px)`;
  } else if (screenWidth < 1366) {
    // md
    return `calc(100vh - 80px)`;
  } else if (screenWidth < 1920) {
    // lg
    return `calc(100vh - 80px)`;
  } else if (screenWidth < 2559) {
    // xl
    return `calc(100vh - 100px)`;
  } else {
    // xxl or greater
    return `calc(100vh - 120px)`;
  }
};

export const checkNumberRange = (number) => {
  if (number >= 100 && number < 1000) {
    return "hundreds";
  } else if (number >= 10000 && number < 100000) {
    return "thousands";
  } else if (number >= 100000) {
    return "lakhs";
  } else {
    return "thousands";
  }
};

// export const genarateLabelValue = (value) => {
// let numberRange = checkNumberRange(value.max_salary);
// let numberRangeValue = FLOOR_VALUE.find((item) => item.label === numberRange);
// let salaryRangeValue = Array.from({ length: 21 }, (_, index) => {
//   // const firstlabel = Math.floor(value.max_salary / 10);
//   const labelvalue = (value.max_salary * index * 5) / 100;
//   const roundOfLabelValue =
//     Math.floor(labelvalue / numberRangeValue.value) * numberRangeValue.value;
//   return {
//     value: index * 5,
//     label: `${roundOfLabelValue / 1000}k`,
//   };
// });
// return salaryRangeValue;

// };
export const genarateLabelValue = (value) => {
  if (value?.max_salary <= 20000) {
    let firstTwoDigit = Math.floor(value?.max_salary / 1000);
    let numberRange = checkNumberRange(value?.max_salary);
    let numberRangeValue = FLOOR_VALUE?.find(
      (item) => item.label === numberRange
    );
    let salaryRangeValue = Array.from(
      { length: firstTwoDigit + 1 },
      (_, index) => {
        // const firstlabel = Math.floor(value.max_salary / 10);
        // const labelvalue = (value.max_salary * index * 10) / 100;
        const labelvalue = (value?.max_salary / firstTwoDigit) * index;
        const roundOfLabelValue =
          Math.floor(labelvalue / numberRangeValue?.value) *
          numberRangeValue?.value;
        return {
          value: index,
          label:
            firstTwoDigit === index
              ? `${roundOfLabelValue / 1000}k+`
              : `${roundOfLabelValue / 1000}k`,
        };
      }
    );
    return salaryRangeValue;
  } else {
    let numberRange = checkNumberRange(value?.max_salary);
    let numberRangeValue = FLOOR_VALUE?.find(
      (item) => item.label === numberRange
    );
    let salaryRangeValue = Array.from({ length: 21 }, (_, index) => {
      // const firstlabel = Math.floor(value.max_salary / 10);
      const labelvalue = (value?.max_salary * index * 5) / 100;
      const roundOfLabelValue =
        Math.floor(labelvalue / numberRangeValue?.value) *
        numberRangeValue?.value;
      return {
        value: index,
        label:
          index === 20
            ? `${roundOfLabelValue / 1000}k+`
            : `${roundOfLabelValue / 1000}k`,
      };
    });
    return salaryRangeValue;
  }
};

export const genarateLabelValueForFreelance = (value) => {
  const desiredLength = 21;

  // Determine the appropriate interval based on the max_rate value
  let interval;
  if (value?.max_rate >= 1000) {
    // If max_rate is 1000 or greater, set interval to 100
    interval = 100;
  } else if (value?.max_rate >= 220 && value?.max_rate <= 400) {
    interval = 20;
  } else if (value?.max_rate > 400 && value?.max_rate <= 1000) {
    interval = 50;
  } else {
    // If max_rate is less than 1000, set interval to 10
    interval = 10;
  }
  const length = Math.floor(value?.max_rate / interval) + 1;

  const rangeArray = Array.from({ length }, (_, index) => {
    const currentValue = index * interval;
    return {
      value: index,
      label: index === length - 1 ? `${currentValue}+` : currentValue,
    };
  });

  return { rangeArray, interval };
};

// export const genarateLabelValueForFreelance = (value) => {
//   const desiredLength = 21;

//   // Determine the appropriate interval based on the max_rate value
//   let interval;
//   if (value.max_rate >= 1000) {
//     // If max_rate is 1000 or greater, set interval to 100
//     interval = 100;
//   } else {
//     // If max_rate is less than 1000, set interval to 10
//     interval = 10;
//   }

//   const length = Math.floor(value.max_rate / interval) + 1;

//   const rangeArray = Array.from({ length }, (_, index) => {
//     const currentValue = index * interval;
//     return {
//       value: index,
//       label: index === length - 1 ? `${currentValue}+` : currentValue,
//     };
//   });

//   return rangeArray;
// };

export const checkPhoneNumber = (
  oldCountryCode,
  newCountryCode,
  fullPhoneNumber
) => {
  if (fullPhoneNumber === undefined || fullPhoneNumber === null) {
    return `${newCountryCode}`;
  }
  let oldCountryCodeStr = String(oldCountryCode);
  let newCountryCodeStr = String(newCountryCode);
  let normalizedNumber = fullPhoneNumber?.startsWith("+")
    ? fullPhoneNumber?.substring(1)
    : fullPhoneNumber;
  if (normalizedNumber?.startsWith(oldCountryCodeStr)) {
    normalizedNumber = normalizedNumber?.substring(oldCountryCodeStr?.length);
  }
  return `+${newCountryCodeStr}${normalizedNumber}`;
};

export const createMarkup = (html) => {
  return {
    __html: DOMPurify.sanitize(html),
  };
};

export const getUserCountryFromGeolocation = () => {
  return new Promise(async (resolve, reject) => {
    try {
      if ("geolocation" in navigator) {
        const position = await getCurrentPosition();
        const country = await fetchCountryFromCoordinates(position.coords);
        resolve(country);
      } else {
        throw new Error("Geolocation is not supported in this browser.");
      }
    } catch (error) {
      reject(error);
    }
  });
};

const getCurrentPosition = () => {
  return new Promise((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(
      (position) => resolve(position),
      (error) => reject(error)
    );
  });
};

const fetchCountryFromCoordinates = async (coords) => {
  try {
    const { latitude, longitude } = coords;
    const response = await fetch(
      `https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}`
    );

    if (!response.ok) {
      throw new Error("Failed to fetch location information");
    }

    const data = await response.json();
    return data?.address?.country_code || "unknown";
  } catch (error) {
    throw error;
  }
};

export const calculateDaysBetween = (startDate, endDate) => {
  endDate = endDate ? new Date(endDate) : new Date();
  startDate = new Date(startDate);
  const timeDifference = endDate - startDate;
  const years = Math.floor(timeDifference / (1000 * 60 * 60 * 24 * 365));
  const months = Math.floor(
    (timeDifference % (1000 * 60 * 60 * 24 * 365)) / (1000 * 60 * 60 * 24 * 30)
  );
  const days = Math.floor(
    (timeDifference % (1000 * 60 * 60 * 24 * 30)) / (1000 * 60 * 60 * 24)
  );

  if (years > 0) {
    return `${years} ${years === 1 ? "year" : "years"} ${
      months > 0 ? `${months} ${months === 1 ? "month" : "months"}` : ""
    }`;
  } else if (months > 0) {
    return `${months} ${months === 1 ? "month" : "months"} ${
      days > 0 ? `${days} ${days === 1 ? "day" : "days"}` : ""
    }`;
  } else {
    return `${days} ${days === 1 ? "day" : "days"}`;
  }
};

export const splitStringWithParentheses = (inputString) => {
  const regex = /([^,(]+(\([^)]*\))?)/g;
  return inputString.match(regex) || [];
};

export const getToken = () => {
  return localStorage?.getItem("token");
};

export const getDecodedToken = (token) => {
  if (token) {
    let decodedToken = jwt_decode(token);
    return decodedToken;
  }
};

export const setLocalStorage = (key, value) => {
  localStorage.setItem(key, value);
};

export const getLocalStorage = (key) => {
  return localStorage.getItem(key);
};

export const removeLocalStorage = (key) => {
  return localStorage.removeItem(key);
};

export const valueToPercent = (max, value) => {
  const percentage = (value * 100) / max;
  return Math.min(percentage, 100);
};

export const percentToValue = (max, value) => {
  return (value * max) / 100;
};

export const addId = (payload, keyId, keyName) => {
  return payload.map((item) => {
    item.id = item[keyId];
    item.name = item[keyName];
    return item;
  });
};

export const textValue = (value) => {
  return value > 1 ? `${value} years` : `${value} year`;
};

export const formatNumber = (num) => {
  if (num < 1000) {
    return Math.round(num.toString());
  } else if (num < 1000000) {
    return formatCurrencyWithCommas(Math.round(num));
  } else {
    return formatCurrencyWithCommas(Math.round(num));
  }
};

export function rangeValueExperience(value) {
  return `${value} years`;
}

export const getCookie = (name) => {
  const cookies = document.cookie
    .split(";")
    .map((cookie) => cookie.trim().split("="));
  const cookie = cookies.find((cookie) => cookie[0] === name);
  return cookie ? cookie[1] : null;
};

export const removeId = (existingIDS, idToRemove) => {
  const newIDS = existingIDS.filter((id) => id !== idToRemove);
  setCookie("favoriteJobs", JSON.stringify(newIDS), 7);
};

export const removeCookie = (name) => {
  document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
};

export const splitString = (inputString) => {
  if (inputString === null) {
    return [];
  }
  const regex = /,\s*(?![^()]*\))/g;
  const resultArray = inputString?.split(regex);
  const trimmedResultArray = resultArray?.map((item) => item?.trim());

  return trimmedResultArray;
};

export const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

export const calculateHeight = () => {
  const screenWidth = window.innerWidth;
  if (screenWidth < 600) {
    // xs
    return `calc(100vh - 95px)`;
  } else if (screenWidth < 900) {
    // sm
    return `calc(100vh - 80px)`;
  } else if (screenWidth < 1366) {
    // md
    return 90;
  } else if (screenWidth < 1650) {
    return 100;
  } else if (screenWidth < 1785) {
    return 110;
  } else if (screenWidth < 1920) {
    // lg
    return 110;
  } else if (screenWidth < 2559) {
    // xl
    return 125;
  } else {
    // xxl or greater
    return 150;
  }
};

export const calculateValueFontSize = () => {
  const screenWidth = window.innerWidth;

  if (screenWidth < 600) {
    // xs
    return 16;
  } else if (screenWidth < 900) {
    // sm
    return 16;
  } else if (screenWidth < 1366) {
    // md
    return 16;
  } else if (screenWidth < 1920) {
    // lg
    return 18;
  } else if (screenWidth < 2559) {
    // xl
    return 20;
  } else {
    // xxl or greater
    return 22;
  }
};

export const calculatelabelFontSize = () => {
  const screenWidth = window.innerWidth;

  if (screenWidth < 600) {
    // xs
    return "9px";
  } else if (screenWidth < 900) {
    // sm
    return "9px";
  } else if (screenWidth < 1366) {
    // md
    return "9px";
  } else if (screenWidth < 1920) {
    // lg
    return "5px";
  } else if (screenWidth < 2559) {
    // xl
    return 11;
  } else {
    // xxl or greater
    return 12;
  }
};

export const createData = (
  title,
  status,
  dateAdded,
  lastActive,
  permissions,
  assigned,
  job_id
) => {
  return {
    title,
    status,
    dateAdded,
    lastActive,
    permissions,
    assigned,
    job_id,
  };
};

export const descendingComparator = (a, b, orderBy) => {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
};

export const getComparator = (order, orderBy) => {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
};

export const stableSort = (array, comparator) => {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
};

export const getColorByIndex = (index) => {
  // Define an array of colors
  const colors = [
    "#FF5733", // Red
    "#4ea35d", // Green
    "#8268ff", // Blue
    "#ff83ca", // Pink
    "#00b3b3", // Cyan
  ];

  const colorIndex = index % colors.length;
  return colors[colorIndex];
};

export const setCookie = (name, value, days) => {
  const existingCookies = document.cookie;
  const existingValues = existingCookies.match(`${name}=([^;]*)`); // Check if the cookie with the given name exists

  if (existingValues) {
    let updatedValues = existingValues[1]
      .split(",")
      .map((item) => parseInt(item, 10));

    if (updatedValues.includes(value)) {
      updatedValues = updatedValues?.filter((item) => item !== value);
    } else {
      updatedValues?.push(value);
    }

    const expirationDate = new Date();
    expirationDate.setDate(expirationDate.getDate() + days);

    if (updatedValues.length === 0) {
      removeCookie(name);
      return;
    }
    document.cookie = `${name}=${updatedValues.join(
      ","
    )}; expires=${expirationDate.toUTCString()}; path=/`;
  } else {
    // Cookie with the given name doesn't exist, create a new one
    const expirationDate = new Date();
    expirationDate.setDate(expirationDate.getDate() + days);

    document.cookie = `${name}=${value}; expires=${expirationDate.toUTCString()}; path=/`;
  }
};

export const debounce = (func, delay, setIsLoading) => {
  let timeoutId;
  return function (...args) {
    clearTimeout(timeoutId);
    setIsLoading(true);
    timeoutId = setTimeout(() => {
      setIsLoading(false);
      func(...args);
    }, delay);
  };
};
export const debounceLoading = (func, delay, setIsLoading) => {
  let timeoutId;
  return function (...args) {
    const type = args[1];
    clearTimeout(timeoutId);
    setIsLoading((prevState) => ({ ...prevState, [type]: true }));
    timeoutId = setTimeout(() => {
      setIsLoading((prevState) => ({ ...prevState, [type]: false }));
      func(...args);
    }, delay);
  };
};

export const renderColor = (column) => {
  switch (column) {
    case "incomplete":
      return "manageIncomplete";
    case "matched":
      return "manageMatched";
    case "considering":
      return "manageConsidering";
    case "shortlist":
      return "manageShortlist";
    case "interview":
      return "manageInterview";
    case "assessment":
      return "manageAssesment";
    case "hired":
      return "manageHired";
    case "rejected":
      return "manageRejected";
    case "review":
      return "manageReview";
    case "offer":
      return "manageOffer";
    default:
      return "manageIncomplete";
  }
};

export const calculateHeightTalentCard = () => {
  const screenWidth = window.innerWidth;

  if (screenWidth < 600) {
    // xs
    return 90;
  } else if (screenWidth < 900) {
    // sm
    return 90;
  } else if (screenWidth < 1366) {
    // md
    return 90;
  } else if (screenWidth < 1920) {
    // lg
    return 90;
  } else if (screenWidth < 2559) {
    // xl
    return 100;
  } else {
    // xxl or greater
    return 100;
  }
};

export const calculateFontSize = () => {
  const screenWidth = window.innerWidth;

  if (screenWidth < 600) {
    // xs
    return 9;
  } else if (screenWidth < 900) {
    // sm
    return 9;
  } else if (screenWidth < 1366) {
    // md
    return 9;
  } else if (screenWidth < 1920) {
    // lg
    return 9;
  } else if (screenWidth < 2559) {
    // xl
    return 11;
  } else {
    // xxl or greater
    return 12;
  }
};

export const validateSchema = async (schema, data) => {
  try {
    schema?.validateSync(data, { abortEarly: false });
    return data;
  } catch (error) {
    if (error instanceof Yup.ValidationError) {
      const errors = {};
      error?.inner?.forEach((item) => {
        const key = item.path || "_error";
        errors[key] = errors[key] || item.message;
      });
      throw errors;
    } else {
      throw error;
    }
  }
};

// export const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
export const emailRegex = /^[a-zA-Z0-9._+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;

export const calculateMUIHeight = () => {
  const screenWidth = window.innerWidth;

  if (screenWidth < 600) {
    // xs
    return 100;
  } else if (screenWidth < 900) {
    // sm
    return 100;
  } else if (screenWidth < 1366) {
    // md
    return 100;
  } else if (screenWidth < 1920) {
    // lg
    return 100;
  } else if (screenWidth < 2559) {
    // xl
    return 100;
  } else {
    // xxl or greater
    return 100;
  }
};

export const getVideoFileDuration = (file, callback) => {
  if (!file) {
    callback("No file provided");
    return;
  }

  const videoElement = document.createElement("video");
  videoElement.style.display = "none";

  document.body.appendChild(videoElement);

  const videoURL = URL.createObjectURL(file);

  videoElement.src = videoURL;
  videoElement.load();
  videoElement.addEventListener("loadedmetadata", () => {
    const duration = videoElement.duration;
    document.body.removeChild(videoElement);
    URL.revokeObjectURL(videoURL);
    callback(null, duration);
  });

  // Handle 'error' event to provide more information
  videoElement.addEventListener("error", (error) => {
    document.body.removeChild(videoElement);
    URL.revokeObjectURL(videoURL);
    callback("Error loading video");
  });
};

export const deepCompare = (obj1, obj2) => {
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);
  const unmatchedKeys = [];

  if (keys1.length !== keys2.length) {
    return { status: false, unmatchedKeys: [...new Set([...keys1, ...keys2])] };
  }

  for (const key of keys1) {
    if (!keys2.includes(key)) {
      unmatchedKeys.push(key);
      continue;
    }

    if (typeof obj1[key] === "object" && typeof obj2[key] === "object") {
      if (obj1[key] === null || obj2[key] === null) {
        if (obj1[key] !== obj2[key]) {
          unmatchedKeys.push(key);
        }
      } else {
        const result = deepCompare(obj1[key], obj2[key]);
        if (!result.status) {
          unmatchedKeys.push(key);
        }
      }
    } else if (obj1[key] !== obj2[key]) {
      unmatchedKeys.push(key);
    }
  }

  return { status: unmatchedKeys.length === 0, unmatchedKeys };
};

export const truncateText = (text, maxLength) => {
  if (!text || text.length <= maxLength) {
    return text;
  }
  return text.substring(0, maxLength) + "...";
};
