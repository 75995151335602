import React from "react";

const PlayButton = ({ buttonSize = 60, triangleSize = 25, left, top }) => {
  return (
    <svg
      height={buttonSize}
      width={buttonSize}
      xmlns="http://www.w3.org/2000/svg"
      style={{
        position: "absolute",
        left:left?left: `calc(50% - ${buttonSize / 2}px`,
        top:top?top: `calc(50% - ${buttonSize / 2}px)`,
      }}
    >
      <defs>
        <mask id="playMask" x="0" y="0" width={buttonSize} height={buttonSize}>
          <rect
            x="0"
            y="0"
            width={buttonSize}
            height={buttonSize}
            fill="#fff"
          />

          <polygon
            points={`${buttonSize / 2 - triangleSize / 2 + 3},${
              buttonSize / 2 - triangleSize / 2
            } 
   ${buttonSize / 2 - triangleSize / 2 + 3},${
              buttonSize / 2 + triangleSize / 2
            } 
   ${buttonSize / 2 + triangleSize / 2},${buttonSize / 2}`}
            fill="#000"
          />
        </mask>
      </defs>

      <circle
        cx={buttonSize / 2}
        cy={buttonSize / 2}
        r={buttonSize / 2}
        fill="#C9CAD8"
        mask="url(#playMask)"
      />
    </svg>
  );
};

export default PlayButton;
