import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getApi, postApi } from "../../utils/Apis";
import { setLoading } from "../configSlice";
const initialState = {
  myTeamsList: [],
  showBox: false,
  currentPageJobsCount: 0,
};

export const getAllHiredTalent = createAsyncThunk(
  "getAllHiredTalent",
  async ({ lastKey, company_id }, { dispatch }) => {
    const { data } = await getApi(
      `/employer/gethiredtalent?limit=25&lastKey=${lastKey}&company_id=${company_id}`,
      true
    );
    return data;
  }
);

export const getAllTeamMembers = createAsyncThunk(
  "getAllTeamMembers",
  async ({ job_id, pool_id, search_id }, { dispatch }) => {
    dispatch(setLoading(true));
    const { data } = await getApi(
      pool_id
        ? `/employer/myteam?job_id=&roleTypeId=&inviteStatusId=&pool_id=${pool_id}`
        : search_id
        ? `/employer/myteam?job_id=&roleTypeId=&inviteStatusId=&search_id=${search_id}`
        : `/employer/myteam?job_id=${job_id}&roleTypeId=&inviteStatusId=`,
      true
    );
    dispatch(setLoading(false));
    return data;
  }
);
export const getAdminMembers = createAsyncThunk(
  "getAdminMembers",
  async ({ pool_id, search_id }, { dispatch }) => {
    dispatch(setLoading(true));
    const { data } = await getApi(
      search_id
        ? `/admin/getCrayonPermission?search_id=${search_id}`
        : `/admin/getCrayonPermission?pool_id=${pool_id}`,
      true
    );
    dispatch(setLoading(false));
    return data;
  }
);
export const getAllTeamMembersList = createAsyncThunk(
  "getAllTeamMembersList",
  async ({ job_id }, { dispatch }) => {
    const { data } = await getApi(
      "/employer/myteam?job_id=" + job_id + "&roleTypeId=&inviteStatusId=",
      true
    );
    return data;
  }
);

export const getFilteredMember = createAsyncThunk(
  "getFilteredMember",
  async ({ job_id, role_type, inviteStatusId }, { dispatch }) => {
    dispatch(setLoading(true));
    const { data } = await getApi(
      "/employer/myteam?job_id=" +
        job_id +
        "&roleTypeId=" +
        role_type +
        "&inviteStatusId=" +
        inviteStatusId,
      true
    );
    dispatch(setLoading(false));
    return data;
  }
);

export const getTeamInfo = createAsyncThunk(
  "getTeamInfo",
  async (payload, { dispatch }) => {
    dispatch(setLoading(true));
    const { data } = await getApi(
      `/employer/teammemberjob?userid=${payload}`,
      true
    );
    dispatch(setLoading(false));
    return data;
  }
);
export const getApprovalList = createAsyncThunk(
  "getApprovalList",
  async (payload, { dispatch }) => {
    dispatch(setLoading(true));
    const { data } = await getApi(
      `/employer/getcompanyapproverequest?company_id=${payload}`,
      true
    );
    dispatch(setLoading(false));
    return data;
  }
);
export const approveUser = createAsyncThunk(
  "approveUser",
  async (payload, { dispatch }) => {
    dispatch(setLoading(true));
    const { data } = await postApi(
      "/employer/companyapproverequest",
      payload,
      true
    );
    dispatch(setLoading(false));
    return data;
  }
);
export const editTeamMemberStatus = createAsyncThunk(
  "editTeamMemberStatus",
  async (payload, { dispatch }) => {
    dispatch(setLoading(true));
    const { data } = await postApi(
      "/employer/assignlicense",
      payload,
      true
    );
    dispatch(setLoading(false));
    return data;
  }
);
export const assignJobs = createAsyncThunk(
  "assignJobs",
  async (payload, { dispatch }) => {
    dispatch(setLoading(true));
    const { data } = await postApi("/employer/assignedjob", payload, true);
    dispatch(setLoading(false));
    return data;
  }
);

export const submitCandidateForJob = createAsyncThunk(
  "submitCandidateForJob",
  async (payload, { dispatch }) => {
    dispatch(setLoading(true));
    const { data } = await postApi("/recruiter/submitcandidate", payload, true);
    dispatch(setLoading(false));
    return data;
  }
);

export const talentPoolPermission = createAsyncThunk(
  "talentPoolPermission",
  async (payload, { dispatch }) => {
    dispatch(setLoading(true));
    const { data } = await postApi("/admin/pool/permission", payload, true);
    dispatch(setLoading(false));
    return data;
  }
);

export const searchPoolPermission = createAsyncThunk(
  "searchPoolPermission",
  async (payload, { dispatch }) => {
    dispatch(setLoading(true));
    const { data } = await postApi("/admin/search/permission", payload, true);
    dispatch(setLoading(false));
    return data;
  }
);

export const editUserPassword = createAsyncThunk(
  "editUserPassword",
  async (payload, { dispatch }) => {
    dispatch(setLoading(true));
    const { data } = await postApi("/employer/edituser", payload, true);
    dispatch(setLoading(false));
    return data;
  }
);

export const changeStatus = createAsyncThunk(
  "changeStatus",
  async (payload, { dispatch }) => {
    dispatch(setLoading(true));
    const { data } = await postApi("/employer/changepermission", payload, true);
    dispatch(setLoading(false));
    return data;
  }
);

export const removeMember = createAsyncThunk(
  "removeMember",
  async (payload, { dispatch }) => {
    dispatch(setLoading(true));
    const { data } = await postApi("/employer/deleteusers", payload, true);
    dispatch(setLoading(false));
    return data;
  }
);

export const removeMembersJobs = createAsyncThunk(
  "removeMembersJobs",
  async (payload, { dispatch }) => {
    dispatch(setLoading(true));
    const { data } = await postApi("/employer/deletejobs", payload, true);
    dispatch(setLoading(false));
    return data;
  }
);

export const addNewMember = createAsyncThunk(
  "addNewMember",
  async (payload, { dispatch }) => {
    dispatch(setLoading(true));
    const { data } = await postApi("/employer/addnewuser", payload, true);
    dispatch(setLoading(false));
    return data;
  }
);

export const getPermissions = createAsyncThunk(
  "getPermissions",
  async (payload, { dispatch }) => {
    const { data } = await getApi("/employer/roles", true);
    return data;
  }
);

export const getEmailData = createAsyncThunk(
  "getEmailData",
  async ({ email, roletypeid = "" }, { dispatch }) => {
    const { data } = await getApi(
      `/employer/getEmaildata?email=${email}&role_type_id=${roletypeid}`,
      true
    );
    return data;
  }
);

export const getCompanies = createAsyncThunk(
  "getCompanies",
  async (payload, { dispatch }) => {
    const { data } = await getApi("/employer/allCompanies", true);
    return { data, initial: payload?.title ? false : true };
  }
);

export const myTeamsListing = createSlice({
  name: "configMyTeams",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getCompanies.fulfilled, (state, action) => {
        state.companies = action?.payload?.data?.data?.map((company) => {
          return {
            ...company,
            id: company?.company_id,
            name: company?.name,
          };
        });
      })
      .addCase(getPermissions.fulfilled, (state, action) => {
        state.permission = action.payload.data.map((curr) => {
          return {
            ...curr,
            id: curr.role_type_id,
            name: curr.name,
          };
        });
      })
      .addCase(getAllHiredTalent.fulfilled, (state, action) => {
        if (action?.payload?.data?.length <= 0) {
          state.showBox = true;
          state.currentPageJobsCount = action?.payload?.data?.length || 0;
        }
        state.currentPageJobsCount = action?.payload?.data?.length || 0;
      });
  },
});
// Action creators are generated for each case reducer function
export default myTeamsListing.reducer;
