import { Box, Button, CircularProgress } from "@mui/material";
import React, { useState } from "react";
import MUIRadialChart from "../../../common/MUIRadialChart";
import { useDispatch } from "react-redux";
import { getTalentJobStatusApplications } from "../../../../redux/employer/myJobsSlice";
import {
  ALERT_TYPE,
  BASIC_MANAGE_TALENT,
  ERROR_MSG,
} from "../../../../utils/Constants";
import { setAlert } from "../../../../redux/configSlice";

export default function RunMatchBox({ jobContent, setJobContent }) {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const runMatch = async () => {
    setIsLoading(true);
    try {
      const data = {
        job_id: jobContent?.job_id,
        job_status_id: 1,
        ...BASIC_MANAGE_TALENT,
      };
      const { payload } = await dispatch(
        getTalentJobStatusApplications({ pageNumber: 0, payload: data })
      );
      if (payload?.status === "success") {
        setJobContent({
          ...jobContent,
          matched_column_count: payload?.totalData,
        });
      } else {
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE?.ERROR,
            msg: ERROR_MSG,
          })
        );
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      dispatch(
        setAlert({
          show: true,
          type: ALERT_TYPE?.ERROR,
          msg: ERROR_MSG,
        })
      );
    }
  };

  return (
    <>
      <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
        <Button
          variant="contained"
          color="recentButton"
          sx={{ width: "150px", height: "45px", borderRadius: "0 0 25px 0" }}
        >
          stats
        </Button>
        {isLoading && (
          <Box
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress color="inherit" size={20} />
          </Box>
        )}
      </Box>
      <Box sx={{ display: "flex", padding: "21px 10px 28px 20px", gap: "6px" }}>
        <MUIRadialChart
          value={jobContent?.matched_column_count || 0}
          size={100}
          color={"matched"}
          chartName={"matched"}
          countFontSize={16}
          labelFontSize={12}
        />
        <MUIRadialChart
          value={jobContent?.TotalUserCount || 0}
          size={100}
          color={"application"}
          chartName={"applied"}
          countFontSize={16}
          labelFontSize={12}
        />
        <MUIRadialChart
          value={jobContent?.totalusershorlisted || 0}
          size={100}
          color={"shortlisting"}
          chartName={"shortlisted"}
          countFontSize={16}
          labelFontSize={12}
        />
        <MUIRadialChart
          value={jobContent?.totaluserinterviewed || 0}
          size={100}
          color={"interview"}
          chartName={"interviewed"}
          countFontSize={16}
          labelFontSize={12}
        />
      </Box>
      <Box sx={{ position: "fixed", width: "450px", textAlign: "center" }}>
        <Button
          variant="contained"
          color="recentButton"
          sx={{
            width: "100px",
            height: "45px",
            borderRadius: "0 0 25px 25px",
            padding: "0px !important",
          }}
          onClick={() => runMatch()}
        >
          run match
        </Button>
      </Box>
    </>
  );
}
