import { Box, Button, useTheme } from "@mui/material";
import React, { useState } from "react";
import upArrow from "../../../../assets/Black_Up_Close - Copy.svg";
import downArrow from "../../../../assets/Black_Down_Open - Copy.svg";
import TalentSVGButton from "../../../common/TalentSVGButton";
import editIcon from "../../../../assets/Padding Included/Yellow_Edit.svg";
import deleteIcon from "../../../../assets/Padding Excluded/Black_Trash_Delete_1 - Copy.svg";
import { useDispatch } from "react-redux";
import {
  addSolutionList,
  deleteSolutionList,
  editSolutionList,
} from "../../../../redux/admin/maintenance";
import AddSolutionTypesModal from "./AddSolutionTypesModal";
import Delete from "../../../candidate/myCam/dialog/Delete";

const SolutionTypeModal = ({
  setSolutionTypeBtn,
  solutionTypeList,
  getSolution,
}) => {
  const theme = useTheme();

  const [viewMoreExpanded, setViewMoreExpanded] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [deleteSolutionType, setDeleteSolutionType] = useState();
  const [openDelete, setOpenDelete] = useState(false);
  const [isAddSolutionTypeModal, setIsAddSolutionTypeModal] = useState(false);
  const dispatch = useDispatch();
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [selectedSolutionType, setSelectedSolutionType] = useState();
  const handleViewMore = () => {
    setSolutionTypeBtn(null);
  };
  const handleOpenAddSpendModal = (data) => {
    setSelectedSolutionType(data);
  };

  const handleDelete = async (id) => {
    let payload = {
      solution_type_id: deleteSolutionType?.solution_type_id,
    };
    try {
      let response = await dispatch(deleteSolutionList(payload));
      if (response?.payload?.status === "success") {
        setConfirmDelete(false);
        setOpenDelete(false);
        getSolution();
      }
    } catch (error) {
      console.log(error, "error");
    }
  };

  return (
    <Box
      sx={{
        width: "400px",
        minHeight: "101px",
        background: "white",
        borderRadius: "25px",
        // mt: "45px",
        boxShadow: `0px 5px 10px ${theme?.palette?.boxShadow?.main}`,
      }}
    >
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Button
          variant="contained"
          color="recordVideoButton"
          sx={{ height: "45px", width: "150px", borderRadius: "25px 0 25px 0" }}
        >
          solution type
        </Button>
        <Button
          variant="contained"
          color="redButton"
          onClick={() => setIsAddSolutionTypeModal(true)}
          sx={{ height: "45px", width: "150px", borderRadius: "0 0 0 25px" }}
        >
          add a type
        </Button>
      </Box>
      <Box sx={{ paddingLeft: "18px", paddingRight: "16px" }}>
        <Box
          sx={{
            fontWeight: "700",
            fontSize: "14px",
            marginBottom: "12px",
            marginTop: "12px",
          }}
        >
          Type
        </Box>
        {solutionTypeList?.map((item) => {
          return (
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                fontSize: "12px",
                alignItems: "center",
              }}
            >
              <Box>{item?.name}</Box>
              <Box>
                <Box sx={{ display: "flex", gap: "8px" }}>
                  <TalentSVGButton
                    onClick={() => {
                      handleOpenAddSpendModal(item);
                      setIsEdit(true);
                      setIsAddSolutionTypeModal(true);
                    }}
                    color={"white"}
                    source={editIcon}
                    height={20}
                    width={20}
                    padding={"0px !important"}
                    startIconMargin={"0px !important"}
                  />
                  <TalentSVGButton
                    color={"white"}
                    source={deleteIcon}
                    height={20}
                    width={20}
                    padding={"0 8px 0 0 !important"}
                    startIconMargin={"0px !important"}
                    onClick={() => {
                      setDeleteSolutionType(item);
                      setOpenDelete(true);
                    }}
                  />
                </Box>
              </Box>
            </Box>
          );
        })}
      </Box>
      <Button
        sx={{
          color: "black",
          padding: 0,
          width: "100%",
          height: !viewMoreExpanded ? `calc(100% - 45px)` : "45px",
          fontSize: "16px !important",
          fontWeight: theme.typography.Bold,
          alignItems: "flex-end",
          paddingBottom: "16px",
          marginTop: !viewMoreExpanded ? "28px" : "",
          ".MuiButton-startIcon": {
            marginRight: "0px !important",
            marginLeft: "-18px !important",
          },
        }}
        endIcon={
          <Box
            component="img"
            sx={{
              height: 30,
              width: 30,
              cursor: "pointer",
            }}
            alt="crayon logo"
            // src={viewMoreExpanded ? upArrow : downArrow}
            src={upArrow}
          />
        }
        onClick={handleViewMore}
      >
        Close
        {/* {viewMoreExpanded ? "Close" : "View items"} */}
      </Button>
      {isAddSolutionTypeModal && (
        <AddSolutionTypesModal
          handleClose={() => setIsAddSolutionTypeModal(false)}
          handleOpen={isAddSolutionTypeModal}
          isAddSolutionTypeModa={isAddSolutionTypeModal}
          setIsAddSolutionTypeModal={setIsAddSolutionTypeModal}
          getSolution={getSolution}
          edit={isEdit}
          setIsEdit={setIsEdit}
          selectedSolutionType={selectedSolutionType}
        />
      )}
      <Delete
        show={openDelete}
        handleOpen={() => setOpenDelete((prevState) => !prevState)}
        handleCancel={() => {
          setOpenDelete((prevState) => !prevState);
          setConfirmDelete(false);
        }}
        handleDelete={handleDelete}
        confirmDelete={confirmDelete}
        setConfirmDelete={setConfirmDelete}
        dialogText={"solution type"}
      />
    </Box>
  );
};

export default SolutionTypeModal;
