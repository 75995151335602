import { Box, IconButton, Typography, useTheme } from "@mui/material";
import React from "react";
import SVGButton from "../../../common/SVGButton";
import BlueFolder from "../../../../assets/CircularIcon/Blue/Circular Icons__Blue_Title_Job_Experience.svg";
import BlueFolderGrey from "../../../../assets/CircularIcon/Grey/CIrcular_Icon_Grey_Folder.svg";
import { useLocation } from "react-router-dom";

export default function DocumentBox({ handleClose, jobContent }) {
  const theme = useTheme();
  const location = useLocation();
  const pathname = location?.pathname?.split("/");
  return (
    <Box
      sx={{
        padding: "10px !important",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography
          sx={{
            fontWeight: 700,
            fontSize: "14px",
          }}
        >
          Documents:
        </Typography>
        <IconButton
          aria-label="close"
          onClick={() => {
            handleClose();
          }}
          sx={{
            position: "absolute",
            top: 0,
            right: "5px",
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Box
            sx={{
              width: "5px",
              height: "5px",
              color: "#000000",
              fontSize: "10px",
            }}
          >
            X
          </Box>
        </IconButton>
      </Box>
      {jobContent?.job_spec_url !== "No URL" ? (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "10px",
            cursor: "pointer",
          }}
        >
          <a
            href={
              jobContent?.job_spec_url !== "No URL"
                ? jobContent.job_spec_url
                : "#"
            }
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: "none" }}
          >
            <SVGButton
              color={"white"}
              source={
                jobContent?.job_spec_url !== "No URL"
                  ? BlueFolder
                  : BlueFolderGrey
              }
              height={35}
              width={35}
              startIconMargin={"0px !important"}
              padding={"0px !important"}
            />
          </a>
          <Typography
            sx={{ fontSize: "12px", fontWeight: theme.typography.Regular }}
          >
            Original spec
          </Typography>
        </Box>
      ) : (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "10px",
            cursor: "default",
          }}
        >
          <SVGButton
            color={"white"}
            source={
              jobContent?.job_spec_url !== "No URL"
                ? BlueFolder
                : BlueFolderGrey
            }
            height={35}
            width={35}
            startIconMargin={"0px !important"}
            padding={"0px !important"}
            cursor={"default"}
          />
          <Typography
            sx={{ fontSize: "12px", fontWeight: theme.typography.Regular }}
          >
            Original spec
          </Typography>
          {/* <a
            href={
              jobContent?.job_spec_url !== "No URL"
                ? jobContent.job_spec_url
                : "#"
            }
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: "none" }}
          >
            <SVGButton
              color={"white"}
              source={
                jobContent?.job_spec_url !== "No URL"
                  ? BlueFolder
                  : BlueFolderGrey
              }
              height={35}
              width={35}
              startIconMargin={"0px !important"}
              padding={"0px !important"}
            />
          </a>
          <Typography
            sx={{ fontSize: "12px", fontWeight: theme.typography.Regular }}
          >
            Original spec
          </Typography> */}
        </Box>
      )}

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: "10px",
          cursor: "pointer",
        }}
        onClick={() =>
          window?.open(
            `/admin/adminJobs/${pathname.at(-1)}/system-spec/${
              jobContent?.job_id
            }`
          )
        }
      >
        <SVGButton
          color={"white"}
          source={BlueFolder}
          height={35}
          width={35}
          startIconMargin={"0px !important"}
          padding={"0px !important"}
        />
        <Typography
          sx={{ fontSize: "12px", fontWeight: theme.typography.Regular }}
        >
          System spec
        </Typography>
      </Box>
    </Box>
  );
}
