import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import {  useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { USER_TYPES } from "../../utils/Constants";

export default function SwipeableButton({
  selectedUser,
  onButtonToggle,
  setSignupTouched,
  error,
}) {
  const theme = useTheme();

  const selectedColor = (selectedUser) => {
    switch (selectedUser) {
      case "candidate":
        return "topBarCandidateButtonLightBlue";
      case "employer":
        return "topBarEmployerButton";
      case "recruiter":
        return "topBarRecruiterButton";
      case "promoter":
        return "topBarPromoterButton";
      default:
        break;
    }
  };
  const ButtonComponent = ({ selectedUser, nextUser }) => {
    return (
      <Button
        sx={{
          width: 100,
          height: "100%",
          boxShadow: 0,
          borderRadius: "10px !important",
        }}
        variant="contained"
        color={selectedColor(selectedUser)}
        onClick={(e) => onButtonToggle(e, nextUser)}
      >
        {selectedUser}
      </Button>
    );
  };

  const TypographyComponent = ({ user, children, ...otherStyles }) => {
    return (
      <Typography
        onClick={(e) => {
          onButtonToggle(e, user);
        }}
        sx={{
          fontWeight: 300,
          fontSize: "14px",
          color: error ? "errorMessage" : theme?.palette?.lightGray,
          cursor: "pointer",
          width: 97,
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          borderRadius: "10px !important",
          textAlign: "center",
          ...otherStyles,
        }}
        variant="subtitle1"
      >
        {children}
      </Typography>
    );
  };
  return (
    <Paper
      sx={{
        display: "flex",
        borderRadius: "10px !important",
        height: "40px",
        alignItems: "center",
        justifyContent: "space-between",
        overflowX: "auto",
        whiteSpace: "nowrap",
        background: "#fff !important",
        boxShadow: "none",
        border: `1px solid`,
        borderColor: "borderColor",
      }}
      style={{
        maxWidth: "99%",
      }}
    >
      {selectedUser === USER_TYPES?.[0] ? (
        <ButtonComponent selectedUser={selectedUser} nextUser={USER_TYPES?.[0]} />
      ) : (
        <TypographyComponent
          user={USER_TYPES?.[0]}
          color={selectedUser === undefined && "redButton100"}
          background={
            selectedUser === "" && theme?.palette?.candidateInitialTextColor
          }
          borderRadius={selectedUser === "" && "10px"}
        >
          {USER_TYPES?.[0]}
        </TypographyComponent>
      )}
      {selectedUser === USER_TYPES?.[1] ? (
        <ButtonComponent selectedUser={selectedUser} nextUser={USER_TYPES?.[1]} />
      ) : (
        <TypographyComponent
          user={USER_TYPES?.[1]}
          color={selectedUser === undefined && "redButton100"}
        >
          {USER_TYPES?.[1]}
        </TypographyComponent>
      )}
      {selectedUser === USER_TYPES?.[2] ? (
        <ButtonComponent selectedUser={selectedUser} nextUser={USER_TYPES?.[2]} />
      ) : (
        <TypographyComponent
          user={USER_TYPES?.[2]}
          color={selectedUser === undefined && "redButton100"}
        >
          {USER_TYPES[2]}
        </TypographyComponent>
      )}
      {selectedUser === USER_TYPES?.[3] ? (
        <ButtonComponent selectedUser={selectedUser} nextUser={USER_TYPES?.[3]} />
      ) : (
        <TypographyComponent
          user={USER_TYPES?.[3]}
          color={selectedUser === undefined && "redButton100"}
        >
          {USER_TYPES?.[3]}
        </TypographyComponent>
      )}
    </Paper>
  );
}
