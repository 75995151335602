import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useDispatch } from "react-redux";
import {
  getFollowCompany,
  unFollowCompany,
} from "../../../../redux/candidate/myProfileSlice";

import { Link } from "react-router-dom";
import { useTheme } from "@emotion/react";
import cancel from "../../../../assets/CircularIcon/Red/Circular Icons__Red_Close_2.svg";
import TalentSVGButton from "../../../common/TalentSVGButton";
import logo from "../../../../assets/Crayon_Favicon.svg";
import { setAlert } from "../../../../redux/configSlice";
import { ALERT_TYPE, ERROR_MSG } from "../../../../utils/Constants";

const DisplayFollowedCompanies = ({
  openDialog,
  totalfollowedCompanies,
  setAnchorElCompanyDialog,
  setAnchorEl,
  handleClose,
  followRef,
  getProfileData,
}) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const [followedCompany, setFollowedCompany] = useState([]);
  // const [pageNumber, setPageNumber] = useState(0)
  const [pageNumber, setPageNumber] = useState(0);
  const [totalCompanies, setTotalCompanies] = useState(0);
  const [loading, setLoading] = useState(false);
  const getFollowedCompany = async () => {
    const data = { lastKey: pageNumber };
    try {
      setLoading(true);
      const { payload } = await dispatch(getFollowCompany(data));
      if (payload?.status === "success") {
        setPageNumber(payload?.pageNumber + 1);
        setFollowedCompany((prevState) => [...prevState, ...payload?.data]);
        setTotalCompanies(payload?.totalData);
        setLoading(false);
      }
    } catch (error) {}
  };

  const handleUnfollow = async (compID) => {
    try {
      const { payload } = await dispatch(unFollowCompany(compID));
      if (payload?.status === "success") {
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE.SUCCESS,
            msg: "Company unfollowed",
          })
        );
        const company = await dispatch(getFollowCompany({ lastKey: "" }));
        setPageNumber(company?.payload?.pageNumber + 1);
        setFollowedCompany(company?.payload?.data);
        setTotalCompanies(company?.payload?.totalData);
        await getProfileData();
      }
    } catch (error) {
      dispatch(
        setAlert({
          show: true,
          type: ALERT_TYPE.ERROR,
          msg: ERROR_MSG,
        })
      );
    }
  };

  const handleClick = (event) => {
    setAnchorElCompanyDialog(followRef?.current);
    setAnchorEl(null);
  };

  useEffect(() => {
    if (openDialog) {
      getFollowedCompany();
    }
    setPageNumber(0);
    setFollowedCompany([]);
  }, [openDialog]);

  return (
    <>
      <Box
        sx={{
          padding: "19px 15px 0px 15px !important",
        }}
      >
        <Box sx={{ display: "flex", gap: "10px", flexDirection: "column" }}>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography
              sx={{
                fontSize: "16px",
                fontWeight: theme.typography.Medium,
                letterSpacing: "0.32px",
              }}
            >
              Following
            </Typography>
            <IconButton
              aria-label="close"
              onClick={() => {
                handleClose();
              }}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
              disableRipple="true"
            >
              <Box
                sx={{
                  width: "20px",
                  height: "20px",
                  color: "#000000",
                  border: 2,
                  fontSize: "18px",
                  borderRadius: "5px",
                }}
              >
                X
              </Box>
            </IconButton>
          </Box>
          <Typography
            sx={{
              fontSize: "12px",
              fontWeight: theme.typography.Medium,
              width: "307px",
            }}
          >
            View the companies you are following.
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            overflowY: "scroll",
            height: {
              sm: "309px",
              lg: "309px",
              lgmm: "310px",
              lgm: "369px",
              mlg: "419px",
              xl: "443px",
            },
            justifyContent: "flex-start",
            "::-webkit-scrollbar": {
              height: "5px",
              background: "#DCDEEB",
              display: "unset !important",
            },
          }}
          className="scroll-container"
          id="followedCompanyList"
        >
          <InfiniteScroll
            style={{
              overflowX: "hidden",
              scrollbarWidth: "thin",
            }}
            dataLength={followedCompany?.length}
            next={() => getFollowedCompany()}
            hasMore={
              followedCompany?.length < totalCompanies + 1 ? true : false
            }
            scrollableTarget="followedCompanyList"
            scrollThreshold={"50px"}
            endMessage={
              <p style={{ textAlign: "center" }}>
                <b>Yay! You have seen it all</b>
              </p>
            }
          >
            {followedCompany?.length > 0 ? (
              followedCompany?.map((item, index) => {
                return (
                  <Box
                    sx={{
                      display: "flex",
                      padding: 1,
                      alignItems: "center",
                      gap: 1,
                    }}
                    key={item?.company_user_id}
                  >
                    <Link
                      to={`/jobs/company?company_id=${item?.company_id}`}
                      target={"_blank"}
                      style={{
                        textDecoration: "none",
                        color: "black",
                      }}
                    >
                      <Box
                        component={"img"}
                        src={
                          item?.company_logo_URL !== "NO URL"
                            ? item?.company_logo_URL
                            : logo
                        }
                        sx={{
                          height: "50px",
                          width: "50px",
                          borderRadius: "10px",
                        }}
                      />
                    </Link>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: "6px",
                      }}
                    >
                      <Tooltip
                        disableInteractive
                        title={item?.company?.name}
                        placement="top-start"
                      >
                        <Typography
                          sx={{
                            maxWidth: "194px",
                            fontSize: "15px",
                            fontWeight: theme.typography.Bold,
                            wordBreak: "break-word",
                          }}
                          noWrap={true}
                        >
                          {item?.company?.name}
                        </Typography>
                      </Tooltip>
                      <Box>
                        <TalentSVGButton
                          padding={"0px !important"}
                          color={"white"}
                          source={cancel}
                          height={28}
                          width={23}
                          onClick={() => handleUnfollow(item?.company_id)}
                        />
                      </Box>
                    </Box>
                  </Box>
                );
              })
            ) : loading ? (
              <Box
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CircularProgress color="inherit" size={20} />
              </Box>
            ) : (
              <Typography
                sx={{
                  fontSize: "14px",
                  fontWeight: theme.typography.Bold,
                  textAlign: "center",
                }}
              >
                No followed company
              </Typography>
            )}

            <style>
              {`.infinite-scroll-component::-webkit-scrollbar {
                      width: 7px !important;
                      background-color: #F5F5F5; /* Set the background color of the scrollbar */
                    }

                    .infinite-scroll-component__outerdiv {
                      height:100%;
                      width: 100%;
                    }

                    .infinite-scroll-component::-webkit-scrollbar-thumb {
                      background-color: #888c; /* Set the color of the scrollbar thumb */
                    }`}
            </style>
          </InfiniteScroll>
        </Box>
      </Box>
      <Button
        variant="contained"
        color="followAlready"
        sx={{
          height: "60px",
          width: "360px",
          position: "absolute",
          borderRadius: "0 0 25px 25px",
        }}
        onClick={(event) => {
          handleClick(event);
        }}
      >
        follow a company
      </Button>
    </>
  );
};

export default DisplayFollowedCompanies;
