import { useTheme } from "@emotion/react";
import { Box, Button, InputBase, Paper, Typography } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";

import flipIcon from "../../../../assets/Crayon_User_Lite.svg";
import { setAlert } from "../../../../redux/configSlice";
import { getCompanies } from "../../../../redux/employer/empProfileSlice";
import {
  ALERT_TYPE,
  ERROR_MSG,
  MY_COMPANIES_BASIC,
} from "../../../../utils/Constants";
import { useDispatch } from "react-redux";
import locale from "../../../../i18n/locale";
import CustomSelect from "../../../candidate/myCV/CustomSelect";
import { debounce } from "lodash";
import {
  getTown,
  updateToInitialTown,
} from "../../../../redux/employer/postJobSlice";
import { getCompanyCulture, getIndustries } from "../../../../redux/candidate/myCvSlice";
import EmployerSecondaryCompanyModal from "../EmployerSecondaryCompanyModal";
import RecruiterSecondaryCompanyModal from "../RecruiterSecondaryCompanyModal";
import { getDecodedToken, getToken } from "../../../../utils/Common";

const AddDetailFrontPage = ({
  basicData,
  setBasicData,
  getCompanyData,
  setIsFlipped,
  editCompanyID,
  companies,
  setCompanyInfoCharLength,
  country,
  town,
  companyCultures,
  initialIndustries,
  initialCompanyCulture,
  companyInfoCharLength,
  handlesave,
  isEmployerModalOpen,
   setIsEmployerModalOpen,
  isRecruitModalFirst,
   setIsRecruitModalFirst,
  isRecruitModalSecond,
   setIsRecruitModalSecond
}) => {
  const decodedToken = getDecodedToken(getToken());
  const theme = useTheme();
  const i18n = locale.en;
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  
  const { industries } = useSelector((state) => state?.myCv);
  const [errors, setErrors] = useState({
    company_name: "",
    industry_ids: "",
    country_id: "",
    town_id: "",
    culture_ids: "",
    notes: "",
  });
  const [touched, setTouched] = useState({
    company_name: false,
    industry_ids: false,
    country_id: false,
    town_id: false,
    culture_ids: false,
    notes: false,
  });

  const getCultureValue = () => {
    if (basicData?.culture_ids?.length === 0) {
      return "";
    }
    return basicData?.culture_ids?.map(
      (id) =>
        companyCultures?.find(
          (culture) => culture?.company_culture_id === id
        ) || id
    );
  };

  const handleInputChange = (event) => {
    if (event?.target?.value?.length <= 2000) {
      event?.target?.id === "notes" &&
        setCompanyInfoCharLength(event?.target?.value?.length);
      const newbasicData = {
        ...basicData,
        [event.target.id]: event?.target?.value,
      };
      setBasicData({ ...newbasicData });
    } else {
    }
  };

  const validateData = () => {
    let isValid = true;
    if (!basicData?.editflag) {
      setErrors([]);
      setTouched({
        company_name: false,
        industry_ids: false,
        country_id: false,
        town_id: false,
        culture_ids: false,
        notes: false,
      });
      return true;
    }
    const newErrors = {
      company_name: "",
      industry_ids: "",
      country_id: "",
      town_id: "",
      culture_ids: "",
      notes: "",
    };

    // Validate Company Name
    if (!basicData?.company_name) {
      newErrors.company_name = "Company Name is required";
      isValid = false;
    }

    // Validate Industry(ies)
    if (!basicData?.industry_ids || basicData?.industry_ids?.length === 0) {
      newErrors.industry_ids = "Company Industry(ies) is required";
      isValid = false;
    }

    if (!basicData?.country_id) {
      newErrors.country_id = "Country is required";
      isValid = false;
    }
    // Validate Industry(ies)
    if (!basicData?.town_id) {
      newErrors.town_id = "Town is required";
      isValid = false;
    }
    // Validate Industry(ies)
    if (!basicData?.culture_ids || basicData?.culture_ids?.length === 0) {
      newErrors.culture_ids = "Company culture is required";
      isValid = false;
    }
    // Validate Industry(ies)
    if (!basicData?.notes) {
      newErrors.notes = "Company description is required";
      isValid = false;
    }

    // Set the errors state
    setErrors(newErrors);

    return isValid;
  };
  const onCreateOption = (data, inputId, index) => {
    let newProfileData = {};

    if (data) {
      newProfileData = {
        ...basicData,
        editflag: true,
        [inputId]: { value: data, label: data },
      };
      setBasicData(newProfileData);
      setErrors([]);
      setTouched({
        company_name: false,
        industry_ids: false,
        country_id: false,
        town_id: false,
        culture_ids: false,
        notes: false,
      });
    }
  };

  const handleOnSelectInputChange = (newValue, inputId) => {
    debouncedHandleOnSelectInputChange(
      newValue,
      inputId,
      basicData?.country_id?.value
    );
  };

  const debouncedHandleOnSelectInputChange = useCallback(
    debounce(
      async (newValue, type, countryID) => {
        let params = {
          title: newValue || "",
          limit: 5,
        };
        if (newValue) {
          // getlist(params, type);
          try {
            if (type === "company_name") {
              dispatch(getCompanies(params));
            }
            if (type === "industry_ids") {
              dispatch(getIndustries(params));
            }
            if (type === "town_id") {
              dispatch(
                getTown({
                  title: newValue || "",
                  limit: 200,
                  id: countryID,
                })
              );
            }
          } catch (error) {
            dispatch(
              setAlert({
                show: true,
                type: ALERT_TYPE?.ERROR,
                msg: error,
              })
            );
          }
        }
      },
      750,
      setIsLoading
    ),
    []
  );
  const handleOnSelectChange = (ind, newValue, val, index) => {
    let newProfileData = {};
    if (newValue === null) {
      newProfileData = {
        ...MY_COMPANIES_BASIC,
        [index]: newValue,
      };
      setCompanyInfoCharLength(0);
    } else if (ind === "industry_ids" || ind === "culture_ids") {
      newProfileData = {
        ...basicData,
        [ind]: newValue,
      };
      delete errors?.[ind];
      setTouched({ ...touched, [ind]: false });
    } else if (index === "country_id") {
      dispatch(updateToInitialTown());
      newProfileData = {
        ...basicData,
        [index]: newValue,
        town_id: { value: "", label: "" },
      };
      delete errors?.[index];
      setTouched({ ...touched, [index]: false });
    } else {
      newProfileData = {
        ...basicData,
        [index]: newValue,
      };
      delete errors?.[index];
      setTouched({ ...touched, [index]: false });
      if (newValue) {
        let comp = companies?.filter(
          (item) => item?.company_id === newValue?.value
        );
        if (comp[0]) {
          if (decodedToken?.data?.role_id === 4) {
            setIsEmployerModalOpen(true);
          }
          if (decodedToken?.data?.role_id === 6) {
            if (
              comp[0]?.secondary_company === true &&
              comp[0]?.recruiter_company === true
            ) {
              setIsRecruitModalSecond(true);
            } else {
              setIsRecruitModalFirst(true);
            }
          }
        }
      }
    }
    if (typeof newValue?.value === "number" && index === "company_name") {
      getCompanyData(newValue?.value);
    }
    if (newValue === null) {
      setErrors([]);
      setTouched({
        company_name: false,
        industry_ids: false,
        country_id: false,
        town_id: false,
        culture_ids: false,
        notes: false,
      });
    }
    setBasicData(newProfileData);
  };

  const onMenuOpen = () => {
    // if (companies.length === 0) {
    //   getAllData("companies");
    // }
    setIsMenuOpen(true);
  };
  const onMenuClose = () => {
    setIsMenuOpen(false);
  };

  const onMenuCloseTown = async () => {
    setIsMenuOpen(true);
  };

  const onMenuOpenTown = async () => {
    if (town?.length === 0) {
      try {
        const { payload } = await dispatch(
          getTown(basicData?.country_id?.value || "")
        );
      } catch (error) {
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE.ERROR,
            msg: ERROR_MSG,
          })
        );
      }
    }
    setIsMenuOpen(true);
  };

  return (
    <>
      <Box
        sx={{
          width: "100%",
          height: "530px",
          borderRadius: "25px",
          background: "white",
          position: "relative",
          boxShadow: "0px 5px 10px #C9CAD8",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <Button
          variant="contained"
          color="recordVideoButton"
          sx={{
            width: "150px",
            height: "45px",
            borderRadius: "25px 0 25px 0",
            cursor: "default",
          }}
        >
          {i18n["empMyProfile.addACompany"]}
        </Button>
        <Box
          sx={{
            padding: "7px 16px 16px 16px",
            display: "flex",
            flexDirection: "column",
            gap: "7px",
            overflow: "hidden",
            overflowY: "scroll",
          }}
        >
          <Box
            sx={{
              position: "relative",
            }}
          >
            <Typography
              sx={{
                fontSize: "14px",
                fontWeight: theme.typography.Bold,
              }}
            >
              {i18n["myCV.companyNameLabelReq"]}
            </Typography>
            <CustomSelect
              variant="createSelect"
              onInputChange={handleOnSelectInputChange}
              onChange={handleOnSelectChange}
              onCreateOption={onCreateOption}
              placeholder={"Enter the company’s name"}
              inputId="company_name"
              name="company_name"
              value={
                basicData?.company_name?.label && basicData?.company_name?.value
                  ? {
                      label: basicData?.company_name?.label
                        ? basicData?.company_name?.label
                        : "",
                      value: basicData?.company_name?.value
                        ? basicData?.company_name?.value
                        : "",
                    }
                  : null
              }
              onMenuOpen={onMenuOpen}
              onMenuClose={onMenuClose}
              option={companies?.map((item) => ({
                value: item?.company_id,
                label: item?.name,
              }))}
              isLoading={isLoading}
              customStyle={{
                menu: (provided) => ({
                  ...provided,
                  zIndex: 9999,
                }),
                menuList: (provided) => ({
                  ...provided,
                  "&::-webkit-scrollbar": {
                    width: "6px",
                    height: "6px",
                    borderRadius: "2.5px",
                    backgroundColor: "#efefef",
                  },
                  "&::-webkit-scrollbar-thumb": {
                    borderRadius: "2.5px",
                  },
                }),
                option: (
                  styles,
                  { data, isDisabled, isFocused, isSelected }
                ) => {
                  return {
                    ...styles,
                    color: isSelected ? "#fff" : "black",
                    ":active": {
                      ...styles[":active"],
                    },
                  };
                },
                control: (baseStyles) => ({
                  ...baseStyles,
                  boxShadow: "none",
                  borderRadius: "10px",
                  height: `40px`,
                  border:
                    touched?.company_name && errors?.company_name !== ""
                      ? "1px solid red"
                      : `1px solid ${theme.palette.grayBorder}`,
                  "&:hover":
                    touched?.company_name && errors?.company_name !== ""
                      ? "1px solid red"
                      : `1px solid ${theme.palette.grayBorder}`,
                  width: `100%`,
                }),
              }}
            />
          </Box>
          <Box>
            <Typography
              sx={{
                fontSize: "14px",
                fontWeight: theme.typography.Bold,
              }}
            >
              {i18n["empMyProfile.companyIndustryReq"]}
            </Typography>
            <CustomSelect
              isDisabled={!basicData?.editflag}
              inputId={"industry_ids"}
              name={"industry_ids"}
              variant={"multiSelect"}
              closeMenuOnSelect={false}
              isMulti={true}
              multiSelectOptions={industries?.map((item) => ({
                value: item?.industry_id,
                label: item?.name,
              }))}
              placeholder={"Select the company industry(ies)"}
              onChange={(e, val) => handleOnSelectChange(e, val)}
              onInputChange={handleOnSelectInputChange}
              customStyle={{
                option: (
                  styles,
                  { data, isDisabled, isFocused, isSelected }
                ) => {
                  return {
                    ...styles,
                    color: isSelected ? "#fff" : "black",
                    ":active": {
                      ...styles[":active"],
                    },
                  };
                },
                control: (baseStyles) => ({
                  ...baseStyles,
                  backgroundColor: "transparent",
                  boxShadow: "none",
                  borderRadius: "10px",
                  minHeight: `40px`,
                  border:
                    touched?.industry_ids && errors?.["industry_ids"]
                      ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
                      : "1px solid #E0E0E0",
                  "&:hover":
                    touched?.industry_ids && errors?.["industry_ids"]
                      ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
                      : "1px solid #E0E0E0",
                  width: `100%`,
                }),
              }}
              multiOnFocus={() => {
                // if (industries?.length === 0) {
                //   dispatch(getCompanies());
                // }
                dispatch(getIndustries());

              }}
              value={
                basicData?.industry_ids
              }
              limitTags={5}
              initialOptions={initialIndustries?.map((item) => ({
                value: item?.industry_id,
                label: item?.name,
              }))}
            />
          </Box>
          <Box>
            <Typography
              sx={{ fontSize: "14px", fontWeight: theme.typography.Bold }}
            >
              {i18n["empMyProfile.companyCountry"]}
            </Typography>
            <CustomSelect
              isDisabled={!basicData?.editflag}
              onMenuOpen={() => {}}
              variant="default"
              onInputChange={handleOnSelectInputChange}
              onChange={handleOnSelectChange}
              placeholder={"Select the country of the company"}
              inputId="country_id"
              name="country_id"
              value={
                basicData?.country_id
              }
              // isLoading={isLoading}
              filterOption={"filter"}
              customStyle={{
                menu: (provided) => ({
                  ...provided,
                  zIndex: 9999,
                }),
                menuList: (provided) => ({
                  ...provided,
                  "&::-webkit-scrollbar": {
                    width: "6px",
                    height: "6px",
                    borderRadius: "2.5px",
                    backgroundColor: "#efefef",
                  },
                  "&::-webkit-scrollbar-thumb": {
                    borderRadius: "2.5px",
                  },
                }),
                option: (
                  styles,
                  { data, isDisabled, isFocused, isSelected }
                ) => {
                  return {
                    ...styles,
                    color: isSelected ? "#fff" : "black",
                    ":active": {
                      ...styles[":active"],
                    },
                  };
                },
                control: (baseStyles) => ({
                  ...baseStyles,
                  boxShadow: "none",
                  borderRadius: "10px",
                  border:
                    touched?.country_id && errors?.["country_id"]
                      ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
                      : "1px solid #E0E0E0",
                  "&:hover":
                    touched?.country_id && errors?.["country_id"]
                      ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
                      : "1px solid #E0E0E0",
                  // width: `100%`,
                }),
              }}
              option={country?.map((item) => ({
                value: item?.country_id,
                label: item?.name,
              }))}
            />
          </Box>
          <Box>
            <Typography
              sx={{ fontSize: "14px", fontWeight: theme.typography.Bold }}
            >
              {i18n["empMyProfile.companyCityTown"]}
            </Typography>
            <CustomSelect
              isDisabled={!basicData?.editflag}
              onMenuClose={onMenuCloseTown}
              onMenuOpen={onMenuOpenTown}
              variant="default"
              onInputChange={handleOnSelectInputChange}
              onChange={handleOnSelectChange}
              placeholder={"Select the city/town of the company"}
              inputId="town_id"
              name="town_id"
              value={
                basicData?.town_id
              }
              isLoading={isLoading["town_id"]}
              customStyle={{
                menu: (provided) => ({
                  ...provided,
                  zIndex: 9999,
                }),
                menuList: (provided) => ({
                  ...provided,
                  "&::-webkit-scrollbar": {
                    width: "6px",
                    height: "6px",
                    borderRadius: "2.5px",
                    backgroundColor: "#efefef",
                  },
                  "&::-webkit-scrollbar-thumb": {
                    borderRadius: "2.5px",
                  },
                }),
                option: (
                  styles,
                  { data, isDisabled, isFocused, isSelected }
                ) => {
                  return {
                    ...styles,
                    color: isSelected ? "#fff" : "black",
                    ":active": {
                      ...styles[":active"],
                    },
                  };
                },
                control: (baseStyles) => ({
                  ...baseStyles,
                  boxShadow: "none",
                  borderRadius: "10px",
                  border:
                    touched?.town_id && errors?.["town_id"]
                      ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
                      : "1px solid #E0E0E0",
                  "&:hover":
                    touched?.town_id && errors?.["town_id"]
                      ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
                      : "1px solid #E0E0E0",
                }),
              }}
              option={town?.map((item) => ({
                value: item?.town_id,
                label: item?.name,
              }))}
            />
          </Box>
          <Box>
            <Typography
              sx={{
                fontSize: "14px",
                fontWeight: theme.typography.Bold,
              }}
            >
              {i18n["empMyCompanies.companyDescription"]}(
              {companyInfoCharLength}
              /2000)
            </Typography>
            <Paper
              elevation={3}
              sx={{
                display: "flex",
                borderRadius: "10px",
                boxShadow: "none",
                border:
                  touched?.notes && errors?.notes !== ""
                    ? "1px solid red"
                    : `1px solid ${theme.palette.grayBorder}`,
              }}
            >
              <InputBase
                readOnly={!basicData?.editflag}
                placeholder="Add a short description of the company"
                sx={{
                  ml: 2,
                  mr: 2,
                  width: "100%",
                  height: "38px",
                }}
                id="notes"
                type="text"
                value={basicData?.notes ? basicData?.notes : ""}
                onChange={handleInputChange}
                // multiline={true}
                // rows={7}
                onBlur={() => {
                  setTouched((prevTouched) => ({
                    ...prevTouched,
                    notes: true,
                  }));
                  validateData();
                }}
              />
            </Paper>
          </Box>
          <Box>
            <Typography
              sx={{ fontSize: "14px", fontWeight: theme.typography.Bold }}
            >
              {i18n["empMyProfile.companyCulture"]}
            </Typography>
            <CustomSelect
              isDisabled={!basicData?.editflag}
              inputId={"culture_ids"}
              name={"culture_ids"}
              variant={"multiSelect"}
              closeMenuOnSelect={true}
              isMulti={true}
              multiSelectOptions={companyCultures?.map((item) => ({
                value: item?.company_culture_id,
                label: item?.name,
              }))}
              placeholder={"Select 5 adjectives that describe the culture"}
              onChange={(e, val) => handleOnSelectChange(e, val)}
              onInputChange={handleOnSelectInputChange}
              customStyle={{
                option: (
                  styles,
                  { data, isDisabled, isFocused, isSelected }
                ) => {
                  return {
                    ...styles,
                    color: isSelected ? "#fff" : "black",
                    ":active": {
                      ...styles[":active"],
                    },
                  };
                },
                control: (baseStyles) => ({
                  ...baseStyles,
                  backgroundColor: "transparent",
                  boxShadow: "none",
                  borderRadius: "10px",
                  minHeight: `40px`,
                  border:
                    touched?.culture_ids && errors?.["culture_ids"]
                      ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
                      : "1px solid #E0E0E0",
                  "&:hover":
                    touched?.culture_ids && errors?.["culture_ids"]
                      ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
                      : "1px solid #E0E0E0",
                  width: `100%`,
                  "& .ValueContainer": {
                    letterSpacing: "-0.4px !important",
                  },
                }),
              }}
              multiOnFocus={() => {
                if (companyCultures?.length === 0) {
                  dispatch(getCompanyCulture());
                }
              }}
              value={getCultureValue()}
              limitTags={5}
              initialOptions={initialCompanyCulture?.map((item) => ({
                value: item?.company_culture_id,
                label: item?.name,
              }))}
            />
          </Box>
        </Box>
        <Button
          variant="contained"
          color="flipButton"
          sx={{
            borderRadius: "0 0 25px 25px",
            height: "60px",
            display: "flex",
            gap: "15px",
            ":hover": {
              boxShadow: 0,
            },
          }}
          onClick={() => {
            setTouched({
              company_name: true,
              industry_ids: true,
              country_id: true,
              town_id: true,
              culture_ids: true,
              notes: true,
            });
            const isValid = validateData();
            isValid && setIsFlipped(true);
          }}
        >
          next
          <Box
            component={"img"}
            src={flipIcon}
            sx={{
              width: "25px",
              height: "25px",
            }}
          />
        </Button>
      </Box>
      {isRecruitModalSecond && (
        <RecruiterSecondaryCompanyModal
          open={isRecruitModalSecond}
          setOpen={setIsRecruitModalSecond}
          handleSubmit={handlesave}
          type={"second"}
        />
      )}
      {isRecruitModalFirst && (
        <RecruiterSecondaryCompanyModal
          open={isRecruitModalFirst}
          setOpen={setIsRecruitModalFirst}
          handleSubmit={handlesave}
          type={"first"}
        />
      )}
      {isEmployerModalOpen && (
        <EmployerSecondaryCompanyModal
          open={isEmployerModalOpen}
          setOpen={setIsEmployerModalOpen}
          handleSubmit={handlesave}
        />
      )}
    </>
  );
};

export default AddDetailFrontPage;
