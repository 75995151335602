import React, { useEffect, useRef, useState } from "react";
import MenuItem from "@mui/material/MenuItem";
import activeDownClose from "../../../../assets/Black_Down_Open - Copy.svg";
import { Circle } from "@mui/icons-material";
import {
  Box,
  ClickAwayListener,
  Grow,
  MenuList,
  Paper,
  Stack,
  useTheme,
} from "@mui/material";
import Button from "@mui/material/Button";
import Popper from "@mui/material/Popper";

export default function ChangeStatus({ loggedInUser, companyStatus }) {
  const theme = useTheme();
  const [status, setStatus] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const anchorRef = useRef(null);
  const [openActive, setOpenActive] = useState(false);
  const open = Boolean(anchorEl);
  const isDisabled = loggedInUser === 4 ? true : false;

  const handleToggle = () => {
    setOpenActive((prevOpen) => !prevOpen);
  };
  const handleCloseActive = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpenActive(false);
  };

  const handleListKeyDown = (event) => {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpenActive(false);
    } else if (event.key === "Escape") {
      setOpenActive(false);
    }
  };

  useEffect(() => {
    setStatus(companyStatus);
  }, [companyStatus]);
  return (
    <>
      <Button
        ref={anchorRef}
        id="composition-button"
        aria-controls={open ? "composition-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
        variant="contained"
        color="base"
        endIcon={
          <Box
            component="img"
            className="eye"
            alt="eye logo"
            src={activeDownClose}
            sx={{
              height: 25,
              width: 25,
            }}
          />
        }
        sx={{
          fontSize: "12px",
          fontWeight: 700,
          color: "#000",
          width: "100%",
          padding: "8px 10px",
          borderRadius: "0px 10px 0 0",
          borderRight: "1px solid #EBECF3",
          borderBottom: "1px solid #EBECF3",
          height: "30px !important",
          gap: "4px",
          "& .MuiButton-endIcon": {
            margin: "0px !important",
          },
        }}
      >
        {status}
        <Circle
          fontSize="string"
          color={
            status === "Pending"
              ? "orangeButton"
              : status === "Active"
              ? "lightGreenButton300"
              : "redButton100"
          }
        />
      </Button>
      <Stack direction="row" spacing={2} sx={{ zIndex: "1000" }}>
        <Box>
          <Popper
            open={openActive}
            anchorEl={anchorRef.current}
            role={undefined}
            placement="bottom-start"
            transition
            disablePortal={isDisabled}
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin:
                    placement === "bottom-start" ? "left top" : "left bottom",
                }}
              >
                <Paper
                  sx={{
                    width: "100px !important",
                    borderRadius: "0px !important",
                  }}
                >
                  <ClickAwayListener onClickAway={handleCloseActive}>
                    <MenuList
                      autoFocusItem={openActive}
                      id="composition-menu"
                      aria-labelledby="composition-button"
                      onKeyDown={handleListKeyDown}
                    >
                      {status === "Pending" && [
                        <MenuItem
                          key="active"
                          sx={{
                            fontSize: "12px",
                            gap: "4px",
                            fontWeight: theme.typography.Bold,
                          }}
                        >
                          unlock{" "}
                          <Circle fontSize="string" color={"checkColor"} />
                        </MenuItem>,
                      ]}
                      {status === "Active" && [
                        <>
                          <MenuItem
                            key="paused"
                            sx={{
                              fontSize: "12px",
                              gap: "4px",
                              fontWeight: theme.typography.Bold,
                            }}
                          >
                            lock{" "}
                            <Circle fontSize="string" color={"redButton"} />
                          </MenuItem>
                        </>,
                      ]}
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </Box>
      </Stack>
    </>
  );
}
