import { Box, Button, Grid, useTheme } from "@mui/material";
import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import LeftArrow from "../../common/LeftArrow";
import RightArrow from "../../common/RightArrow";
import EmployerMyVideoList from "../../common/myCamSection/EmployerMyVideoList";
import EmployerVideoAddSteps from "../../common/myCamSection/EmployerVideoAddSteps";
import EmployerCamList from "../../common/myCamSection/EmployerCamList";
import { StyledButtonLeft } from "../../../utils/CommonComponent";
import { useDispatch } from "react-redux";
import useViewport from "../../common/ViewPort";
import CustomDialog from "../../common/CustomDialog";
import ProfileNotCompleted from "../../common/ProfileNotCompleted";
import { useSelector } from "react-redux";
import {
  setCardsPadding,
  setFilterExpanded,
} from "../../../redux/guest/jobsSlice";
import { getMyCamVideo } from "../../../redux/candidate/candidateJobs";
import { getProfileProgressData } from "../../../redux/candidate/myProfileSlice";
import { getEmployerMyCamVideo } from "../../../redux/employer/myJobsSlice";

export default function EmployerMyCam() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { width } = useViewport();
  const gridRef = useRef();
  const mediaRecorderRef = useRef(null);
  const filterExpanded = useSelector(
    (state) => state?.configjobslice?.filterExpanded
  );
  const [companyID, setCompanyID] = useState();
  const[allData, setAllData]= useState({})
  const [itemsFetched, setItemsFetched] = useState(false);
  const [newCardWidth, setNewCardWidth] = useState(340);
  const [newPadding, setNewPadding] = useState(15);
  const [leftExpanded, setLeftExpand] = useState(true);
  const [activeStep, setActiveStep] = useState(1);
  const [mediaStream, setMediaStream] = useState(null);
  const profileCompletionNumber = 50;
  const [showNoInfoBox, setShowNoInfoBox] = useState(false);
  const [recordedVideoData, setRecordedVideoData] = useState(null);
  const [userData, setUserData] = useState();
  const [step, setStep] = useState(1);
  const [isVideoUploaded, setIsVideoUploaded] = useState(false);
  const [videoLink, setVideoLink] = useState("");

  const profilePercentage = useSelector(
    (state) => state.employerjoblisting.profilePercentage
  );

  const handleChangeStep = (step) => {
    setActiveStep(step);
  };

  const getProfilePercentageData = () => {
    if (
      profilePercentage?.myInfo + profilePercentage?.companyInfo <
      profileCompletionNumber
    ) {
      setShowNoInfoBox(true);
    } else {
      setShowNoInfoBox(false);
    }
  };

  const handleStopRecording = () => {
    if (
      mediaRecorderRef.current &&
      mediaRecorderRef.current.state === "recording"
    ) {
      mediaRecorderRef.current.stop();
    }
  };

  const handleCloseCamera = () => {
    if (mediaStream) {
      mediaStream?.getTracks()?.forEach((track) => {
        track.stop();
      });
      setMediaStream(null);
    }
  };

  function calculateCardWidthAndPadding(totalWidth) {
    let cardWidth = 340;
    let padding = 15;
    let numCards = Math.floor(totalWidth / (cardWidth + padding));
    let remainingSpace =
      totalWidth - (numCards * cardWidth + padding * (numCards + 1));

    while (remainingSpace > 0) {
      if (remainingSpace < cardWidth + padding) {
        if (padding < 30) {
          padding++;
          remainingSpace =
            totalWidth - (numCards * cardWidth + padding * (numCards + 1));
        } else {
          break;
        }
      } else {
        numCards++;
      }
    }

    cardWidth = (totalWidth - padding * (numCards + 1)) / numCards;
    if (cardWidth > 380) {
      cardWidth = 380;
    }
    remainingSpace =
      totalWidth - (numCards * cardWidth + padding * (numCards + 1));

    padding = padding + remainingSpace / (numCards + 1);

    remainingSpace =
      totalWidth - (numCards * cardWidth + padding * (numCards + 1));

    setNewCardWidth(cardWidth - 1);
    setNewPadding(padding);
    dispatch(setCardsPadding(padding));
    return { numCards, cardWidth, padding, remainingSpace };
  }

  const getWidth = () => {
    if (gridRef?.current) {
      calculateCardWidthAndPadding(gridRef?.current?.clientWidth);
    }
  };
  const getVideo = async () => {
    // setLoading(true);
    const { payload } = await dispatch(getEmployerMyCamVideo());
    if (payload?.status === "success") {
      if (payload?.data?.length > 0) {
        setAllData(payload?.data?.filter((item) => item?.default)?.[0])
        setUserData(payload?.data?.filter((item) => item?.default)?.[0]?.user);
        setVideoLink(
          payload?.data?.filter((item) => item?.default)?.[0]?.video_url
        );
        setRecordedVideoData(
          payload?.data?.filter((item) => item?.default)?.[0]?.video_url
        );
        setCompanyID(
          payload?.data?.filter((item) => item?.default)?.[0]?.company_id
        );
        payload?.data?.filter((item) => item?.default)?.[0]?.video_url ===
        "No URL"
          ? setStep(1)
          : setStep(4);
      } else {
        setStep(1);
      }
    } else if (payload === undefined) {
      return;
    }
    // setLoading(false);
  };
  useEffect(() => {
    getVideo();
  }, [activeStep]);

  useLayoutEffect(() => {
    if (gridRef?.current) {
      calculateCardWidthAndPadding(gridRef?.current?.clientWidth);
    }
    window.addEventListener("resize", getWidth);
    return () => {
      window.removeEventListener("resize", getWidth);
    };
  }, [itemsFetched, leftExpanded]);

  useLayoutEffect(() => {
    activeStep !== 2 && handleCloseCamera();
    activeStep !== 2 && handleStopRecording();
  }, [activeStep]);

  useLayoutEffect(() => {
    Object?.keys(profilePercentage)?.length !== 0 && getProfilePercentageData();
  }, [profilePercentage]);

  useEffect(() => {
    if (width > 1024) {
      setLeftExpand(true);
    } else {
      setLeftExpand(false);
    }
  }, [width]);

  if (width === 0) {
    return null;
  }
  return (
    <>
      <Box
        sx={{
          display: "flex",
          height: {
            xs: `calc(100vh - 95px)`,
            sm: `calc(100vh - 120px )`,
            md: `calc(100vh - 120px)`,
            lg: `calc(100vh - 135px)`,
            xl: `calc(100vh - 155px)`,
            xxl: "calc(100vh - 175px)",
          },
        }}
      >
        <Box
          item
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: `${width >= 768 && "10px"}`,
            position: `${width < 768 ? "absolute" : "relative"}`,
            width: leftExpanded ? "125px" : "30px",
            minWidth: leftExpanded ? "125px" : "30px",
            zIndex: "30",
          }}
        >
          <Button
            variant="contained"
            sx={{
              position: "static",
              borderRadius: "0 10px 10px 0",
              minWidth: "30px",
              width: "30px",
              height: "45px",
              boxShadow: "0px 5px 10px #00000029",
            }}
            color="filterButtons"
            onClick={() => {
              setLeftExpand((prevState) => !prevState);
              dispatch(setFilterExpanded(!filterExpanded));
            }}
          >
            {leftExpanded ? <LeftArrow /> : <RightArrow />}
          </Button>

          {leftExpanded && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "10px",
                height: {
                  xs: `calc(100vh - 95px)`,
                  sm: `calc(100vh - 120px )`,
                  md: `calc(100vh - 120px)`,
                  lg: `calc(100vh - 135px)`,
                  xl: `calc(100vh - 155px)`,
                  xxl: "calc(100vh - 175px)",
                },
                width: `${(width < 768 && width !==0 )&& "135px"}`,
                paddingTop:  `${(width < 768 && width !==0 ) && "10px"}`,
                background: `${width > 0 && width < 768 && "#EBECF3"}`,
                boxShadow: `${
                  width > 0 && width < 768 && "0px 5px 10px #00000029"
                }`,
                opacity:`${(width < 768 && width !==0 ) && "1"}`,
                borderRadius:  `${(width < 768 && width !==0 ) && "10px"}`,
                overflowX: "hidden",
                overflowY: leftExpanded ? "scroll" : "unset",
              }}
            >
              <StyledButtonLeft
                onClick={() => setActiveStep(1)}
                variant={"contained"}
                color={activeStep === 1 ? "filterButtons" : "buttonColor"}
                sx={{
                  borderRadius: "0 10px 10px 0",
                  border: 0,
                  width: leftExpanded ? "125px" : "30px",
                  minWidth: leftExpanded ? "125px" : "30px",
                  "&:hover": {
                    backgroundColor: theme?.palette?.filterButtons?.main,
                    color: theme?.palette?.filterButtons?.contrastText,
                    fontWeight: theme.typography.Bold,
                  },
                  fontWeight:
                    activeStep === 1
                      ? theme?.typography?.Bold
                      : theme?.typography?.Regular,
                }}
              >
                {"Crayon cam"}
              </StyledButtonLeft>
              <StyledButtonLeft
                onClick={() => setActiveStep(2)}
                variant={"contained"}
                color={activeStep === 2 ? "precium" : "buttonColor"}
                sx={{
                  borderRadius: "0 10px 10px 0",
                  border: 0,
                  width: leftExpanded ? "125px" : "30px",
                  minWidth: leftExpanded ? "125px" : "30px",
                  "&:hover": {
                    backgroundColor: theme?.palette?.precium?.main,
                    color: theme?.palette?.precium?.contrastText,
                    fontWeight: theme.typography.Bold,
                  },
                  fontWeight:
                    activeStep === 2
                      ? theme?.typography?.Bold
                      : theme?.typography?.Regular,
                }}
              >
                {"my video"}
              </StyledButtonLeft>
              <StyledButtonLeft
                onClick={() => setActiveStep(3)}
                variant={"contained"}
                color={activeStep === 3 ? "peachPayment" : "buttonColor"}
                sx={{
                  mb: 1,
                  borderRadius: "0 10px 10px 0",
                  width: leftExpanded ? "125px" : "30px",
                  minWidth: leftExpanded ? "125px" : "30px",
                  "&:hover": {
                    backgroundColor: theme?.palette?.peachPayment?.main,
                    color: theme?.palette?.peachPayment?.contrastText,
                    fontWeight: theme.typography.Bold,
                  },
                  fontWeight:
                    activeStep === 3
                      ? theme?.typography?.Bold
                      : theme?.typography?.Regular,
                  border: 0,
                }}
              >
                {"job videos"}
              </StyledButtonLeft>
            </Box>
          )}
        </Box>
        <Box
          item
          justifyContent={width < 768 && width !==0 && "center"}
          sx={{
            display: "flex",
            flexGrow: 1,
            alignItems: "center",
            padding:
              activeStep !== 3 && ((width < 768 && width !==0) ? "10px" : `0 ${newPadding}px`),
            flexDirection: "column",
            width: {
              xs: `95%`,
              md24: `calc(100vw - 255px)`,
            },
            height:"auto",
            position: "sticky",
          }}
          ref={gridRef}
        >
          {activeStep === 1 && (
            <EmployerMyVideoList 
            handleChangeStep={handleChangeStep} 
            isVideoUploaded={isVideoUploaded}
            setIsVideoUploaded={setIsVideoUploaded}
            setActiveStep={setActiveStep}
            companyID={companyID}
            setCompanyID={setCompanyID}
            getVideo={getVideo}
            setVideoLink={setVideoLink}
            videoLink={videoLink}
            />
          )}
          {activeStep === 2 && (
            <EmployerVideoAddSteps
              handleStopRecording={handleStopRecording}
              handleCloseCamera={handleCloseCamera}
              setMediaStream={setMediaStream}
              mediaStream={mediaStream}
              mediaRecorderRef={mediaRecorderRef}
              step={step} 
              setStep={setStep}
              userData={userData} 
              setUserData={setUserData}
              recordedVideoData={recordedVideoData} 
              setRecordedVideoData={setRecordedVideoData}
              setActiveStep={setActiveStep}
              companyID={companyID}
              setCompanyID={setCompanyID}
              setAllData={setAllData}
              allData={allData}
            />
          )}
          {activeStep === 3 && (
            <EmployerCamList
              leftExpanded={leftExpanded}
              setItemsFetched={setItemsFetched}
              newCardWidth={newCardWidth}
              newPadding={newPadding}
              gridRef={gridRef}
              companyID={companyID}
              setCompanyID={setCompanyID}
              setActiveStep={setActiveStep}
            />
          )}
        </Box>
        {leftExpanded && (
          <Grid
            item
            sx={{
              display: "flex",
              flexDirection: "column",
              position: `${width < 768 ? "absolute" : "relative"}`,
              gap: "65px",
              direction: "rtl",
              width: "125px",
            }}
          ></Grid>
        )}
      </Box>
      {showNoInfoBox && (
        <CustomDialog
          show={true}
          hideButton={false}
          dialogWidth="xs"
          showFooter={false}
          padding={0}
        >
          <ProfileNotCompleted />
        </CustomDialog>
      )}
    </>
  );
}
