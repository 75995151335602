import React, { useState } from "react";
import ReactCardFlip from "react-card-flip";
import SolutionCardFront from "./SolutionCardFront";
import SolutionCardBack from "./SolutionCardBack";
import ReviewVideoDialog from "../../common/ReviewVideoDialog";

export default function SolutionCards({
  solution,
  index,
  solutionlength,
  currentPageJobsCount,
  mobileText,
}) {
  const [isHorizontalFlipped, setisHorizontalFlipped] = useState(false);
  const [openReviewVideoDialog, setOpenReviewVideoDialog] = useState(false);
  const [videoLink, setVideoLink] = useState("");

  const handleCloseDialog = () => {
    setVideoLink("");
    setOpenReviewVideoDialog(false);
  };

  return (
    <>
      <ReactCardFlip
        isFlipped={isHorizontalFlipped}
        flipDirection={"horizontal"}
        flipSpeedBackToFront="0.5"
        flipSpeedFrontToBack="0.5"
        key={solution?.id}
      >
        <SolutionCardFront
          solution={solution}
          setOpenReviewVideoDialog={setOpenReviewVideoDialog}
          setisFlipped={setisHorizontalFlipped}
          setVideoLink={setVideoLink}
          index={index}
          solutionlength={solutionlength}
          currentPageJobsCount={currentPageJobsCount}
          mobileText={mobileText}
        />
        <SolutionCardBack
          solution={solution}
          setOpenReviewVideoDialog={setOpenReviewVideoDialog}
          setisFlipped={setisHorizontalFlipped}
          setVideoLink={setVideoLink}
          index={index}
          solutionlength={solutionlength}
          currentPageJobsCount={currentPageJobsCount}
          mobileText={mobileText}
        />
      </ReactCardFlip>
      {openReviewVideoDialog && (
        <ReviewVideoDialog
          videoLink={videoLink}
          open={openReviewVideoDialog}
          setOpen={handleCloseDialog}
        />
      )}
    </>
  );
}
