import { Box, Button, Typography, useTheme } from "@mui/material";
import React from "react";
import CloseIcon from "../../../common/CloseIcon";
import crayBot from "../../../../assets/CrayBotIcons/Crayon Craybot Notification-09.svg";
import { ToggleSwitch } from "../../../../utils/CommonComponent";
import locale from "../../../../i18n/locale";

export default function DeleteProfile({
  onClose,
  settingsRef,
  setSettingButton,
}) {
  const i18n = locale.en;
  const theme = useTheme();
  return (
    <Box sx={{ width: "300px", height: "auto", background: "#FFFFFF" }}>
      <Box>
        <Button
          variant="contained"
          color="nandos"
          sx={{
            width: "150px",
            height: "45px",
            boxShadow: "none !important",
            borderRadius: "25px 0 10px 0",
            padding: "0px",
          }}
        >
          delete profile
        </Button>
        <Box onClick={onClose}>
          <CloseIcon />
        </Box>
      </Box>
      <Box sx={{ padding: "8px 12px 10px 17px", display: "flex", gap: "23px" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "66px",
          }}
        >
          <Box
            component={"img"}
            src={crayBot}
            sx={{ height: "100px", width: "143px" }}
          />
          <ToggleSwitch
            color="greenSwitch"
            //   checked={JSON?.parse(work?.completed?.toLowerCase())}
            //   onChange={(event, id, name) =>
            //     handleSelectChange2(event, id, name, index)
            //   }
          />
        </Box>

        <Box sx={{ display: "flex", flexDirection: "column", gap: "14px" }}>
          <Typography
            sx={{
              fontSize: theme.typography.fontSize.xs,
              fontWeight: theme.typography.Regular,
              width: "167px",
              lineHeight: "16px",
            }}
          >
            {i18n["myProfile.deleteProfileText"]}
          </Typography>
          <Typography
            sx={{
              fontSize: theme.typography.fontSize.xs,
              fontWeight: theme.typography.Bold,
              width: "181px",
              lineHeight: "16px",
            }}
          >
            {i18n["myProfile.deleteProfileText1"]}
          </Typography>
        </Box>
      </Box>

      <Box>
        <Button
          variant="contained"
          color="darkGrey"
          sx={{
            width: "50%",
            height: "60px",
            borderRadius: "0 0 0 25px",
            color: "black",
            padding: "0px",
          }}
          onClick={() => {
            setSettingButton(settingsRef?.current);
            onClose();
          }}
        >
          back to settings
        </Button>
        <Button
          variant="contained"
          color="redButton"
          sx={{
            width: "50%",
            height: "60px",
            borderRadius: "0 0 25px 0",
            padding: "0px",
          }}
        >
          so long, CrayBot
        </Button>
      </Box>
    </Box>
  );
}
