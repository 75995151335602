import { Box, Button, Typography, useTheme } from "@mui/material";
import React, { useState } from "react";
import CloseIcon from "../../../common/CloseIcon";
import {
  ToggleCheckedSwitch,
  ToggleSwitch,
  ToggleSwitch2,
} from "../../../../utils/CommonComponent";

export default function GoDark({ onClose, settingsRef, setSettingButton }) {
  const theme = useTheme();
  const [status, setStatus] = useState(true);
  return (
    <Box sx={{ width: "300px", height: "auto", background: "#FFFFFF" }}>
      <Box>
        <Button
          variant="contained"
          color="peachPayment"
          sx={{
            width: "150px",
            height: "45px",
            boxShadow: "none !important",
            borderRadius: "25px 0 10px 0",
            padding: "0px",
          }}
        >
          go dark
        </Button>
        <Box onClick={onClose}>
          <CloseIcon />
        </Box>
      </Box>

      <Box
        sx={{
          padding: "10px 10px 14px 16px",
          display: "flex",
          flexDirection: "column",
          gap: "15px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "flex-start",
            flexDirection: "column",
          }}
        >
          <Typography
            sx={{
              fontSize: theme.typography.fontSize.sm,
              fontWeight: theme.typography.Bold,
            }}
          >
            Profile status
          </Typography>

          <Box sx={{ display: "flex", gap: "13px", alignItems: "center" }}>
            <Typography
              sx={{
                fontSize: theme.typography.fontSize.sm,
                fontWeight: theme.typography.Regular,
              }}
            >
              Not active
            </Typography>
            <ToggleSwitch2
              checkedColor={"greenSwitch"}
              notCheckedColor={"redButton"}
              checked={status}
              onChange={(event) => setStatus(event?.target?.checked)}
            />
            <Typography
              sx={{
                fontSize: theme.typography.fontSize.sm,
                fontWeight: theme.typography.Regular,
              }}
            >
              active
            </Typography>
          </Box>
        </Box>
      </Box>

      <Box>
        <Button
          variant="contained"
          color="darkGrey"
          sx={{
            width: "100%",
            height: "60px",
            borderRadius: "0 0 25px 25px",
            color: "black",
          }}
          onClick={() => {
            setSettingButton(settingsRef?.current);
            onClose();
          }}
        >
          back to settings
        </Button>
      </Box>
    </Box>
  );
}
