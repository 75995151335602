import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import { useTheme } from "@mui/material/styles";
import { Circle } from "@mui/icons-material";
import { Typography } from "@mui/material";
export default function RightFiltersPanel({
  selected = [],
  panelData,
  side,
  color,
  baseColor = "filterButtons",
  onChangeFilter = () => {},
}) {
  const theme = useTheme();
  const [selectedBtns, setSelectedBtn] = useState([panelData?.[0]?.id]);

  useEffect(() => {
    // selected.length > 0 &&
    setSelectedBtn(selected?.length > 0 ? selected : [panelData?.[0]?.id]);
  }, [panelData, selected]);

  const removeBtnSelection = (selectedBtnList, id) => {
    selectedBtnList?.splice(
      selectedBtns?.findIndex((selectedBtn) => selectedBtn == id),
      1
    );
  };
  const handleButtonClick = (event, { id, name, title }) => {
    if (title === true) {
      if (selectedBtns?.find((selectedBtn) => selectedBtn === id)) {
        const selectedBtnList = [...selectedBtns];
        id !== 1111 && removeBtnSelection(selectedBtnList, id);
        setSelectedBtn(selectedBtnList);
        onChangeFilter(selectedBtnList);
      } else {
        setSelectedBtn([panelData?.[0]?.id]);
        onChangeFilter([panelData?.[0]?.id]);
      }
    } else {
      if (selectedBtns?.find((selectedBtn) => selectedBtn === id)) {
        const selectedBtnList = [...selectedBtns];
        id !== 1111 && removeBtnSelection(selectedBtnList, id);
        if (selectedBtnList?.length === 0) {
          setSelectedBtn([panelData?.[0]?.id]);
          onChangeFilter([panelData?.[0]?.id]);
        } else {
          setSelectedBtn(selectedBtnList);
          onChangeFilter(selectedBtnList);
        }
      } else {
        if (
          selectedBtns?.find(
            (selectedBtn) => selectedBtn === panelData?.[0]?.id
          )
        ) {
          const selectedBtnList = [...selectedBtns];
          id !== 1111 && removeBtnSelection(selectedBtnList, id);
          selectedBtnList?.push(id);
          setSelectedBtn(selectedBtnList);
          onChangeFilter(selectedBtnList);
        } else {
          setSelectedBtn((prevState) => [...prevState, id]);
          onChangeFilter([...selectedBtns, id]);
        }
      }
    }
  };
  return (
    <Box
      sx={{
        textAlign: side === "right" && "end",
        display: { xs: "flex", sm: "flex" },
        flexDirection: "column",
        gap: "10px",
      }}
    >
      {panelData?.map((btn) => (
        <Tooltip
          disableInteractive
          title={btn?.name}
          key={btn?.id}
          placement="top-end"
        >
          <Button
            sx={{
              flexDirection: "row-reverse",
              justifyContent: "center",
              padding: "6px 7px",
              lineHeight: "inherit",
              borderRadius: "10px",
              // fontSize: { lg: "12px", xl: "14px" },
              fontSize: theme.typography.fontSize.xs,
              borderBottomLeftRadius: side === "left" ? { sm: 0 } : "10px",
              borderTopLeftRadius: side === "left" ? { sm: 0 } : "10px",
              borderTopRightRadius: side === "right" ? { sm: 0 } : "10px",
              borderBottomRightRadius: side === "right" ? { sm: 0 } : "10px",
              // mr: { xs: 1, sm: 0 },
              minWidth: { xs: "90px", sm: 0 },
              // boxShadow: "0px 5px 10px #00000029 !important",
              fontWeight:
                btn?.title || selectedBtns?.includes(btn?.id)
                  ? theme.typography.Bold
                  : theme.typography.Regular,
              "&:hover": {
                // boxShadow: 15,
                // opacity: 1,
                // backgroundColor: theme.palette[btn.color]?.main,
                backgroundColor: theme.palette[color].main,
                color: theme.palette[color].contrastText,
                fontWeight: theme.typography.Bold,
              },
              height: "45px",
            }}
            onClick={(e) => handleButtonClick(e, btn)}
            disableElevation
            variant="contained"
            color={selectedBtns?.includes(btn?.id) ? baseColor : "base"}
            key={btn?.id}
          >
            {btn?.name !== "job status" ? (
              <Typography
                sx={{
                  // fontSize: { lg: "12px", xl: "14px" },
                  fontSize: theme.typography.fontSize.xs,
                  minWidth: "70px",
                  textAlign: "center",
                }}
              >
                {btn?.name}
              </Typography>
            ) : (
              btn?.name
            )}{" "}
            {btn?.name !== "job status" && (
              <Circle
                fontSize="string"
                color={
                  btn?.name === "paused"
                    ? "pausedStatus"
                    : btn?.name === "closed"
                    ? "closedStatus"
                    : btn?.name === "rejected"
                    ? "rejectedStatus"
                    : btn?.name === "pending"
                    ? "pendingStatus"
                    : btn?.name === "active"
                    ? "activeStatus"
                    : "shortlist"
                }
                sx={{ marginLeft: "0px" }}
              />
            )}
          </Button>
        </Tooltip>
      ))}
    </Box>
  );
}
