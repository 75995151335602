import * as React from "react";
import { useEffect, useState } from "react";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { styled, alpha } from "@mui/material/styles";
import Switch from "@mui/material/Switch";
import { InputBase, Paper, Popover } from "@mui/material";
import {
  ADMIN_SEARCH_FILTER,
  ALERT_TYPE,
  ERROR_MSG,
} from "../../../utils/Constants";
import { getTraits } from "../../../redux/employer/postJobSlice";
import { setAlert, setLoading } from "../../../redux/configSlice";
import { useDispatch } from "react-redux";
import TalentSVGButton from "../../common/TalentSVGButton";
import link from "../../../assets/CircularIcon/Red/Circular Icons__Red_Title_Job_Experience.svg";
import diamond from "../../../assets/Characters/Red_Diamond.svg";
import leftArrow from "../../../assets/Black_Left_Previous.svg";
import rightArrow from "../../../assets/Black_Right_Next.svg";
import FilterDrawer from "./dialogBox/FilterDrawer";
import SmallButtonTalent from "../../common/SmallButtonTalent";
import {
  buildSearchNew,
} from "../../../redux/admin/searchSlice";
import TitleAccordion from "./dialogBox/TitleAccordion";
import SearchSaveModal from "./SearchSaveModal";


const BASIC = {
  lastKey: "",
  keyword: "",
};

const StyledBox = styled("img")(() => ({
  cursor: "pointer",
  height: 60,
  width: 60,
  textAlign: "center",
}));

export default function IndustrySearch() {
  const theme = useTheme();

  const dispatch = useDispatch();

  const [basicData, setBasicData] = useState(BASIC);
  const [flip, setFlip] = useState(false);
  const [state, setState] = useState(false);
  const [anchorElReferral, setAnchorElReferral] = useState(null);
  const [pageNumber, setPageNumber] = useState(0);
  const [accordionList, setAccordionList] = useState([]);
  const [industryList, setIndustryList] = useState([]);
  const [openAccordian, setOpenAccordian] = useState(false);
  const [totalJobs, setTotalJobs] = useState();
  const [titleCount, setTitleCount] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [openSearch, setOpenSearch] = useState(false);
  const [title, setTitle] = useState("");
  const openReferral = Boolean(anchorElReferral);

  const getAllData = async () => {
    try {
      dispatch(setLoading(true));
      await dispatch(getTraits());
      dispatch(setLoading(false));
    } catch (error) {
      dispatch(setLoading(false));
      dispatch(
        setAlert({
          show: true,
          type: ALERT_TYPE.ERROR,
          msg: ERROR_MSG,
        })
      );
    }
  };

  const handleInputSearch = (event) => {
    let newBasicData = {
      ...basicData,
      [event.target.id]: event.target.value,
    };
    setBasicData(newBasicData);
  };

  const handleSearch1 = async (titlt_id, pageNum) => {
    let updatedData = {
      titleListFlag: true,
      industryName: titlt_id ? titlt_id : basicData?.keyword,
    };
    setOpenAccordian(true);

    const { payload } = await dispatch(
      buildSearchNew({
        lastKey: pageNum ? pageNum : pageNumber,
        payload: updatedData,
      })
    );
    if (payload?.status === "success") {
      setAccordionList((prevAccordionList) => [
        ...prevAccordionList,
        ...payload?.data,
      ]);
      setPageNumber(payload?.pageNumber + 1);
      setTotalJobs(payload?.talentCount);
      setTitleCount(payload?.titleCount);
      setIndustryList((prevState) => [...prevState, ...payload.data]);
    } else {
      // dispatch(
      //   setAlert({
      //     show: true,
      //     type: ALERT_TYPE?.ERROR,
      //     msg: "Something went wrong! please relaod the window",
      //   })
      // );
    }
  };

  const toggleDrawer = (open) => (event) => {
    setFlip((prev) => !prev);
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setState(open);
  };

  const handlePopoverCloseReferral = () => {
    setAnchorElReferral(null);
  };

  const resetSearch = () => {
    setOpenSearch(false);
    setOpenAccordian(false);
    setTitle("");
    setBasicData(BASIC);
    setPageNumber(0);
  };

  const editSearch = () => {
    setOpenSearch(false);
    setPageNumber(0);
    setOpenAccordian(false);
    setTitle(title);
  };

  useEffect(() => {
    getAllData();
  }, []);

  return (
    <Box>
      <Typography
        sx={{
          fontSize: "36px",
          fontWeight: 700,
          textAlign: "center",
        }}
      >
        {"Search by industry"}
      </Typography>

      <Paper sx={{ p: 3, pt: 0, pr: 0, borderRadius: "20px", pb: 0 }}>
        <Box sx={{ display: "flex", justifyContent: "flex-end", mb: 4 }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "flex-start",
              justifyContent: "space-between",
            }}
            width={openAccordian ? "59%" : "57%"}
          >
            <Box
              sx={{
                display: "flex",
                height: "100px",
                flexDirection: "column",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <StyledBox
                className="homeImages"
                alt="Home Image"
                src={diamond}
              />

              {openAccordian ? (
                <>
                  <Box
                    sx={{
                      display: "flex",
                      width: "220px",
                      marginBottom: "5px",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography sx={{ fontSize: "12px", fontWeight: 700 }}>
                      Result based on your search:
                    </Typography>
                    <SmallButtonTalent
                      fontWeight={700}
                      textColor={"#000"}
                      color="grayButton200"
                      label={totalJobs}
                      mr={1}
                    />
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      width: "220px",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography sx={{ fontSize: "12px", fontWeight: 700 }}>
                      Number of job title:
                    </Typography>
                    <SmallButtonTalent
                      fontWeight={700}
                      textColor={"#000"}
                      color="grayButton200"
                      label={titleCount}
                      mr={1}
                    />
                  </Box>
                </>
              ) : (
                <Typography sx={{ fontSize: "12px", fontWeight: 700 }}>
                  Search based on industry
                </Typography>
              )}
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                alignItems: "flex-end",
                height: "100px",
              }}
            >
              <Button
                sx={{
                  boxShadow: 0,
                  fontSize: "14px",
                  borderRadius: "0 20px 0 20px !important",
                  height: "43px",
                  padding: 3,
                }}
                variant="contained"
                color="yellowButton100"
                width="fit-content"
              >
                my searches
              </Button>
              <Button
                sx={{
                  boxShadow: 0,
                  fontSize: "14px",
                  borderRadius: "10px 0 0 10px !important",
                  height: "43px",
                  minWidth: "40px !important",
                  padding: 0,
                  ".MuiButton-startIcon": {
                    margin: "0px !important",
                  },
                }}
                variant="contained"
                color="blueButton700"
                startIcon={
                  <Box
                    component="img"
                    className="eye"
                    alt="eye logo"
                    src={openReferral ? rightArrow : leftArrow}
                    sx={{
                      height: 26,
                      width: 26,
                    }}
                  />
                }
              />
              <Popover
                id="dropdown"
                open={openReferral}
                anchorEl={anchorElReferral}
                onClose={handlePopoverCloseReferral}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                PaperProps={{
                  sx: {
                    minWidth: "10% !important",
                    borderRadius: "20px !important",
                    marginTop: "140px !important",
                    backgroundColor: "transparent !important",
                  },
                }}
              >
                <FilterDrawer
                  toggleDrawer={toggleDrawer}
                  panelData={ADMIN_SEARCH_FILTER}
                  side="right"
                />
              </Popover>
            </Box>
          </Box>
        </Box>
        {!openAccordian && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-evenly",
              alignItems: "center",
              mb: 3,
            }}
          >
            <Box
              sx={{
                width: "120px",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-around",
              }}
            >
              <TalentSVGButton
                color={"white"}
                source={link}
                height={30}
                width={30}
                startIconMargin={"0px !important"}
                padding={"0px !important"}
              />

              <Typography sx={{ fontSize: "12px", fontWeight: 700 }}>
                Industry(ies)
              </Typography>
            </Box>

            <Paper
              sx={{
                display: "flex",
                height: "40px",
                borderRadius: "10px",
                boxShadow: "none",
                border: `1px solid ${theme.palette.grayBorder}`,
                width: "70%",
              }}
            >
              <InputBase
                id="keyword"
                value={basicData?.keyword}
                onChange={handleInputSearch}
                placeholder={
                  "Enter the industry title you would like to search for..."
                }
                sx={{ ml: 2, mr: 2, width: "100%" }}
              />
            </Paper>
          </Box>
        )}

        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            mt: 4,
          }}
        >
          <Button
            sx={{
              boxShadow: 0,
              fontSize: "14px",
              borderRadius: "20px 0 0 0 !important",
              height: "43px",
              padding: 3,
              color: "#000",
            }}
            variant="contained"
            color="grayButton200"
            width="fit-content"
            onClick={resetSearch}
          >
            reset search
          </Button>
          <Button
            sx={{
              boxShadow: 0,
              fontSize: "14px",
              borderRadius: "0px !important",
              height: "43px",
              padding: 3,
            }}
            variant="contained"
            color="yellowButton100"
            width="fit-content"
          >
            save search
          </Button>
          <Button
            sx={{
              boxShadow: 0,
              fontSize: "14px",
              borderRadius: "0 20px 0 0 !important",
              height: "43px",
              padding: 3,
            }}
            variant="contained"
            color="redButton100"
            width="fit-content"
            onClick={() => {
              if (!openSearch && basicData?.keyword?.trim()?.length > 0) {
                handleSearch1(basicData?.keyword, 0);
                setIndustryList([]);
                setAccordionList([]);
                setPageNumber(0);
                setOpenSearch(true);
              } else {
                setOpenSearch(false);
                editSearch();
              }
            }}
          >
            {`${!openSearch ? "let's go" : "edit search"}`}
          </Button>
        </Box>
      </Paper>
      {openAccordian && (
        <Paper sx={{ p: 3, mt: 4, borderRadius: "20px" }}>
          <TitleAccordion
            titlesList={industryList}
            basicData={basicData}
            handleSearch={handleSearch1}
            moduleName={"industryName"}
          />
        </Paper>
      )}
      {isModalOpen && (
        <SearchSaveModal
          handleOpen={isModalOpen}
          handleClose={() => setIsModalOpen(false)}
        />
      )}
    </Box>
  );
}
