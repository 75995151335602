import { useTheme } from "@mui/material/styles";
import { Modal, Switch, Typography } from "@mui/material";
import React, { useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Bot from "../../../assets/EndModalBot.svg";
import { alpha, styled } from "@material-ui/core";
const BlueSwitch = styled(Switch)(({ theme }) => ({
  "& .MuiSwitch-switchBase.Mui-checked": {
    color: "#47D281",
    "&:hover": {
      backgroundColor: alpha("#47D281", "#C9CAD8"),
    },
  },
  "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
    backgroundColor: "#47D281",
  },
  "& .MuiSwitch-track": {
    margin: "auto",
    height: "60% !important",
  },
  "& .MuiSwitch-thumb": {
    borderRadius: "6px !important",
  },
  "& .MuiButtonBase-root-MuiSwitch-switchBase": {
    borderRadius: "15% !important",
  },
}));

const ReAssismentModal = ({
  handleOpen,
  handleClose,
  handleSubmit,
  // confirmDelete,
  // setConfirmDelete,
}) => {
  const [confirmDelete, setConfirmDelete] = useState(false);
  const theme = useTheme();
  const handleDeleteConfirm = (event) => {
    setConfirmDelete(event.target.checked);
  };
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: {xs:"360", tablet:"450px"},
    bgcolor: "background.paper",
    borderRadius: "25px",
    // p: 4,
    boxShadow: "none !important",
    // border: "1px solid black !important",
    "&:focus-visible": {
      outline: "none",
    },
  };

  return (
    <Modal
      open={handleOpen}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Box
          sx={{
            padding: "25px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Box component="img" src={Bot} sx={{ width: 83, height: 125 }}></Box>
          <Box
            sx={{
              fontSize: "20px",
              marginBottom: "25px",
              fontWeight: "700",
              textAlign: "center",
            }}
          >
            Do you want to do this again?!
          </Box>
          <Box
            sx={{
              fontSize: "14x",
              fontWeight: "700",
              marginBottom: "30px",
              textAlign: "center",
            }}
          >
         You’ve already done the assessment and been assigned the results. 
          </Box>
          <Box
            sx={{
              fontSize: "14x",
              fontWeight: theme.typography.Regular,
              marginBottom: "15px",
              textAlign: "center",
            }}
          >
            But, if you reaallly want to, you can do it again and we'll update
            your profile.
          </Box>
          <Box
            sx={{
              fontSize: "14x",
              fontWeight: theme.typography.Regular,
              marginBottom: "15px",
              textAlign: "center",
            }}
          >
           Note: If you start the assessment again, your existing personality data will be wiped. Gone, forever. Like a whole new you. 
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography>Click to confirm</Typography>
            <BlueSwitch
              checked={confirmDelete}
              onChange={(event) => handleDeleteConfirm(event)}
            />
          </Box>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            variant="contained"
            color="grayButton100"
            sx={{
              borderRadius: 0,
              width: "50%",
              minHeight: "60px",
              maxHeight: "60px",
              fontWeight: theme.typography.Bold,
              fontSize: "14px",
              borderBottomLeftRadius: 25,
            }}
            onClick={handleClose}
          >
            no thanks
          </Button>
          <Button
            variant="contained"
            color="applyButton"
            sx={{
              borderRadius: 0,
              width: "50%",
              minHeight: "60px",
              maxHeight: "60px",
              fontWeight: theme.typography.Bold,
              fontSize: "14px",
              borderBottomRightRadius: 25,
            }}
            disabled={!confirmDelete}
            onClick={handleSubmit}
          >
           let’s go
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default ReAssismentModal;
