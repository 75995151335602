import {
  Box,
  Button,
  FormControl,
  Switch,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { LightTooltip } from "../../../../utils/CommonComponent";
import SelectMenu from "../../../common/SelectMenu";
import { ALERT_TYPE, JOB_TYPE } from "../../../../utils/Constants";
import { useDispatch } from "react-redux";
import { pinAJob } from "../../../../redux/admin/jobsSlice";
import { setAlert } from "../../../../redux/configSlice";
import TalentSVGButton from "../../../common/TalentSVGButton";
import pinIcon from "../../../../assets/Icons/pinIcon.svg";
import { getDecodedToken, getToken } from "../../../../utils/Common";
import PinConfirmation from "./PinConfirmation";

const commonSwitchStyle = {
  width: "90px",
  height: "35px",
  borderRadius: "25px",
  padding: 0,
  border: "1px solid",
  borderColor: "#C9CAD8",
  "& .MuiSwitch-track": {
    backgroundColor: "#F2F4FB",
  },

  "& .MuiSwitch-switchBase.Mui-checked": {
    color: "#FFFFFF",
    transform: "translateX(55px)",
    padding: "3px",
  },
  "& .MuiSwitch-thumb": {
    height: "28px",
    width: "28px",
  },
};

export default function EditJobType({
  jobDetail,
  setJobDetail,
  isRecruitPostPublicNeeded,
  isRecruitVideoNeeded,
  isInterviewNeeded,
  setisDirectVideoNeeded,
  setisDirectPostPublicNeeded,
  setIsInterviewNeeded,
  setisRecruitVideoNeeded,
  isDirectVideoNeeded,
  isDirectPostPublicNeeded,
  setisRecruitPostPublicNeeded,
  setAllJobs,
  allJobs,
  pinJobFlag,
}) {
  const [crayonDirectExpand, setCrayonDirectExpang] = useState(false);
  const dispatch = useDispatch();
  const theme = useTheme();
  const decodedToken = getDecodedToken(getToken());
  const [openPin, setOpenPin] = useState(false);
  const [confirmPin, setConfirmPin] = useState(false);

  const handleChange = (event) => {
    const {
      target: { value },
      target: { name },
      target: { id },
    } = event;
    setJobDetail({
      ...jobDetail,
      job_type: JOB_TYPE?.find((type) => type?.id === value)?.name || "",
    });
  };

  const handlePinJob = async () => {
    try {
      let data = {
        job_id: jobDetail?.job_id,
        value: !pinJobFlag,
      };
      const { payload } = await dispatch(pinAJob(data));
      if (payload?.status === "success") {
        setAllJobs((prevAllJobs) => {
          const updatedItem = prevAllJobs
            .filter((item) => item?.job_id === jobDetail?.job_id)
            .map((item) => ({ ...item, pin_job: !pinJobFlag }))[0];
          const remainingItems = prevAllJobs.filter(
            (item) => item?.job_id !== jobDetail?.job_id
          );
          return [updatedItem, ...remainingItems];
        });
        setOpenPin(false);
        setConfirmPin(false);
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE?.SUCCESS,
            msg: "Job pinned successfully",
          })
        );
      } else {
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE?.ERROR,
            msg: "Error",
          })
        );
      }
    } catch (error) {
      dispatch(
        setAlert({
          show: true,
          type: ALERT_TYPE?.ERROR,
          msg: error,
        })
      );
    }
  };

  useEffect(() => {
    setisRecruitVideoNeeded(
      jobDetail?.job_type === "Recruit" ? jobDetail?.is_video_require : false
    );
    setIsInterviewNeeded(
      jobDetail?.job_type === "Recruit"
        ? jobDetail?.is_interview_require
        : false
    );
    setisRecruitPostPublicNeeded(
      jobDetail?.job_type === "Recruit" ? jobDetail?.post_incognito : false
    );
    setisDirectVideoNeeded(
      jobDetail?.job_type === "Direct" ? jobDetail?.is_video_require : false
    );
    setisDirectPostPublicNeeded(
      jobDetail?.job_type === "Direct" ? jobDetail?.post_incognito : false
    );
  }, []);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          p: 4,
          pb: 0,
          flexDirection: "row",
          gap: "16px",
        }}
      >
        <Box
          sx={{
            width: "50%",
            display: "flex",
            alignItems: "flex-start",
            flexDirection: "column",
          }}
        >
          <Typography
            sx={{
              fontSize: "18px",
              fontWeight: theme.typography.Bold,
              textAlign: "center",
            }}
          >
            Select Job Type
          </Typography>
          <Box
            sx={{
              display: "flex",
              width: "100%",
              justifyContent: "flex-start",
              alignItems: "center",
              gap: "10px",
              padding: "16px 0",
            }}
          >
            <SelectMenu
              sx={{
                width: "50%",
              }}
              borderRadius="10px"
              name="job_type"
              value={jobDetail?.job_type}
              onHandleChange={(event) => handleChange(event)}
              options={JOB_TYPE}
              clear={true}
              border={`1px solid ${theme.palette.borderColor}`}
              placeholder={"Select a job type"}
            />
          </Box>
          <Box sx={{ display: "flex", width: "100%", gap: "10px" }}>
            {((decodedToken?.data?.role_id === 1 && !pinJobFlag) ||
              (decodedToken?.data?.role_id === 2 && !pinJobFlag)) && (
              <>
                <Typography
                  sx={{
                    fontSize: "18px",
                    fontWeight: theme.typography.Bold,
                    textAlign: "center",
                  }}
                >
                  Do you want to pin this job?
                </Typography>

                <TalentSVGButton
                  onClick={() => setOpenPin(true)}
                  color={"white"}
                  source={pinIcon}
                  outerHeight={25}
                  height={25}
                  width={25}
                  padding={"0px !important"}
                  startIconMargin={"0px !important"}
                  margin={"2px 0 0 0"}
                />
              </>
            )}
          </Box>
        </Box>
        <Box sx={{ width: "50%" }}>
          {jobDetail?.job_type === "Direct" && (
            <Box
              sx={{
                width: "100%",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexDirection: "column",
                  gap: "16px",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "25px",
                    fontWeight: theme.typography.Bold,
                    textAlign: "center",
                  }}
                >
                  Crayon Direct
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    gap: "10px",
                    width: "100%",
                    alignItems: "center",
                    // justifyContent: "center",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "14px",
                      fontWeight: theme.typography.Regular,
                      width: "30%",
                      textAlign: "end",
                    }}
                  >
                    we don’t need videos
                  </Typography>
                  <Switch
                    checked={isDirectVideoNeeded}
                    onClick={(event) => {
                      setisDirectVideoNeeded((prevState) => !prevState);
                      setisRecruitPostPublicNeeded(false);
                      setisRecruitVideoNeeded(false);
                      setIsInterviewNeeded(false);
                      setJobDetail({
                        ...jobDetail,
                        job_type: "Direct",
                      });
                    }}
                    sx={{
                      ...commonSwitchStyle,
                      "& .MuiSwitch-switchBase": {
                        color: "DirectToggleButton",
                        padding: "3px",
                      },
                      backgroundColor: !isDirectVideoNeeded
                        ? "DefaultToggleColor"
                        : "DirectToggleButton",
                    }}
                  />
                  <Typography
                    sx={{
                      fontSize: "14px",
                      fontWeight: theme.typography.Regular,
                      width: "40%",
                      textAlign: "start",
                    }}
                  >
                    include video applications
                  </Typography>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    gap: "10px",
                    width: "100%",
                    alignItems: "center",
                    // justifyContent: "center",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "14px",
                      fontWeight: theme.typography.Regular,
                      width: "30%",
                      textAlign: "end",
                    }}
                  >
                    public post
                  </Typography>
                  <Switch
                    checked={isDirectPostPublicNeeded}
                    onClick={(event) => {
                      setisDirectPostPublicNeeded((prevState) => !prevState);
                      setisRecruitPostPublicNeeded(false);
                      setisRecruitVideoNeeded(false);
                      setIsInterviewNeeded(false);
                      setJobDetail({
                        ...jobDetail,
                        job_type: "Direct",
                      });
                    }}
                    sx={{
                      ...commonSwitchStyle,
                      "& .MuiSwitch-switchBase": {
                        color: "DirectToggleButton",
                        padding: "3px",
                      },
                      backgroundColor: !isDirectPostPublicNeeded
                        ? "DefaultToggleColor"
                        : "DirectToggleButton",
                    }}
                  />
                  <Typography
                    sx={{
                      fontSize: "14px",
                      fontWeight: theme.typography.Regular,
                      width: "30%",
                      display: "flex",
                      gap: "10px",
                      textAlign: "start",
                    }}
                  >
                    post incognito
                    <Tooltip disableInteractive>
                      <LightTooltip
                        title={"Post your job in public mode or incognito mode"}
                        placement="right-end"
                      >
                        <Typography
                          sx={{
                            height: "17px",
                            width: "17px",
                            borderRadius: "5px",
                            fontSize: "12px",
                            lineHeight: 0.1,
                            /* text-align: center; */
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            fontWeight: theme.typography.Bold,
                            boxShadow: "0px 2px 4px #00000029",
                            border: 1,
                          }}
                        >
                          i
                        </Typography>
                      </LightTooltip>
                    </Tooltip>
                  </Typography>
                </Box>
              </Box>
            </Box>
          )}

          {jobDetail?.job_type === "Recruit" && (
            <Box
              sx={{
                width: "100%",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexDirection: "column",
                  gap: "16px",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "25px",
                    fontWeight: theme.typography.Bold,
                    textAlign: "center",
                  }}
                >
                  Crayon Recruit
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    gap: "10px",
                    width: "100%",
                    alignItems: "center",
                    // justifyContent: "center",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "14px",
                      fontWeight: theme.typography.Regular,
                      width: "40%",
                      textAlign: "end",
                    }}
                  >
                    we don’t need videos
                  </Typography>
                  <Switch
                    checked={isRecruitVideoNeeded}
                    onClick={(event) => {
                      setisRecruitVideoNeeded((prevState) => !prevState);
                      setisDirectVideoNeeded(false);
                      setisDirectPostPublicNeeded(false);
                      setJobDetail({
                        ...jobDetail,
                        job_type: "Recruit",
                      });
                    }}
                    sx={{
                      ...commonSwitchStyle,
                      "& .MuiSwitch-switchBase": {
                        color: "RecruitToggleButton",
                        padding: "3px",
                      },
                      backgroundColor: !isRecruitVideoNeeded
                        ? "#F2F4FB"
                        : "#009AE0",
                    }}
                  />
                  <Typography
                    sx={{
                      fontSize: "14px",
                      fontWeight: theme.typography.Regular,
                      width: "40%",
                      textAlign: "start",
                    }}
                  >
                    include video applications
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    gap: "10px",
                    justifyContent: "center",
                    width: "100%",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "14px",
                      fontWeight: theme.typography.Regular,
                      width: "40%",
                      textAlign: "end",
                    }}
                  >
                    we don’t need interviews
                  </Typography>
                  <Switch
                    checked={isInterviewNeeded}
                    onClick={(event) => {
                      setIsInterviewNeeded((prevState) => !prevState);
                      setisDirectVideoNeeded(false);
                      setisDirectPostPublicNeeded(false);
                      setJobDetail({
                        ...jobDetail,
                        job_type: "Recruit",
                      });
                    }}
                    sx={{
                      ...commonSwitchStyle,
                      "& .MuiSwitch-switchBase": {
                        color: "RecruitToggleButton",
                        padding: "3px",
                      },
                      backgroundColor: !isInterviewNeeded
                        ? "#F2F4FB"
                        : "#009AE0",
                    }}
                  />

                  <Typography
                    sx={{
                      fontSize: "14px",
                      fontWeight: theme.typography.Regular,
                      width: "40%",
                      textAlign: "start",
                    }}
                  >
                    include recorded interviews
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    gap: "10px",
                    width: "100%",
                    alignItems: "center",
                    // justifyContent: "center",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "14px",
                      fontWeight: theme.typography.Regular,
                      width: "40%",
                      textAlign: "end",
                    }}
                  >
                    public post
                  </Typography>
                  <Switch
                    checked={isRecruitPostPublicNeeded}
                    onClick={() => {
                      setisRecruitPostPublicNeeded((prevState) => !prevState);
                      setisDirectVideoNeeded(false);
                      setisDirectPostPublicNeeded(false);
                      setJobDetail({
                        ...jobDetail,
                        job_type: "Recruit",
                      });
                    }}
                    sx={{
                      ...commonSwitchStyle,
                      "& .MuiSwitch-switchBase": {
                        color: "RecruitToggleButton",
                        padding: "3px",
                      },
                      backgroundColor: !isRecruitPostPublicNeeded
                        ? "#F2F4FB"
                        : "#009AE0",
                    }}
                  />
                  <Typography
                    sx={{
                      fontSize: "14px",
                      fontWeight: theme.typography.Regular,
                      width: "40%",
                      display: "flex",
                      gap: "10px",
                      textAlign: "start",
                    }}
                  >
                    post incognito
                    <Tooltip disableInteractive>
                      <LightTooltip
                        title={"Post your job in public mode or incognito mode"}
                        placement="right-end"
                      >
                        <Typography
                          sx={{
                            height: "17px",
                            width: "17px",
                            borderRadius: "5px",
                            fontSize: "12px",
                            lineHeight: 0.1,
                            /* text-align: center; */
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            fontWeight: theme.typography.Bold,
                            boxShadow: "0px 2px 4px #00000029",
                            border: 1,
                          }}
                        >
                          i
                        </Typography>
                      </LightTooltip>
                    </Tooltip>
                  </Typography>
                </Box>
              </Box>
            </Box>
          )}
        </Box>
      </Box>
      <PinConfirmation
        show={openPin}
        handleClose={() => {
          setOpenPin(false);
          setConfirmPin(false);
        }}
        handleDelete={handlePinJob}
        confirmPin={confirmPin}
        setConfirmPin={setConfirmPin}
        dialogText={`job ${jobDetail?.title}`}
      />
    </>
  );
}
