import {
  Box,
  Button,
  IconButton,
  InputLabel,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useCallback, useState } from "react";
import locale from "../../../../i18n/locale";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import CustomSelect from "../../../candidate/myCV/CustomSelect";
import SelectMenuYear from "../../../common/SelectMenuYear";
import { debounce } from "../../../../utils/Common";
import {
  getSchool,
  getInstitute,
  getQualification,
  getTypeQualificationValue,
  getAssociation,
  addStudyData,
} from "../../../../redux/candidate/myCvSlice";
import { useDispatch } from "react-redux";
import { yearConverter } from "../../../../utils/DateTime";
import { ToggleSwitch } from "../../../../utils/CommonComponent";
import SelectMenu from "../../../common/SelectMenu";
import {
  STUDY_LIFE_ASSOCIATION,
  STUDY_LIFE_QUALIFICATION,
  STUDY_LIFE_SCHOOL,
} from "../../../../utils/Constants";
export default function EditStudyLife({
  talentDetails,
  //   studyLife,
  //   setStudyLife,
  setTalentDetails,
  errors,
  setErrors,
  school,
  institution,
  qualification,
  association,
  typeOfQualification,
  getAllData,
}) {
  let maxOffset = 63;
  let thisYear = new Date().getFullYear();
  const theme = useTheme();
  const dispatch = useDispatch();
  const i18n = locale.en;
  const [isLoading, setIsLoading] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  let allYears = [];
  for (let x = 0; x <= maxOffset; x++) {
    allYears.push({ id: x + 1, name: thisYear - x });
  }

  const onMenuOpen = () => {
    if (institution?.length === 0) {
      getAllData("institution");
    }
    setIsMenuOpen(true);
  };
  const onMenuClose = () => {
    setIsMenuOpen(false);
  };

  const removeStudy = (event, index) => {
    if (talentDetails.qualificationdata?.length > 1) {
      const newStudy = talentDetails?.qualificationdata?.filter(
        (data, i) => i + 1 !== index
      );
      const newStudyData = { ...talentDetails, qualificationdata: newStudy };
      setTalentDetails(newStudyData);
    }
  };

  const handleOnSelectInputChange = (newValue, inputId) => {
    debouncedHandleOnSelectInputChange(newValue, inputId);
  };
  const debouncedHandleOnSelectInputChange = useCallback(
    debounce(
      async (newValue, type) => {
        let params = {
          title: newValue || "",
          limit: 200,
        };
        if (newValue) {
          // getlist(params, type);
          try {
            if (type === "institution_id") {
              dispatch(getInstitute(params));
            }
            if (type === "qualification_id") {
              dispatch(getQualification(params));
            }
            if (type === "school_id") {
              dispatch(getSchool(params));
              // dispatch(getQualification(params));
            }
            if (type === "association_id") {
              dispatch(getAssociation(params));
              // dispatch(getSchool(params));
            }
          } catch (error) {
            console.log(error, "error");
          }
        }
      },
      750,
      setIsLoading
    ),
    []
  );
  const handleOnSelectChange = (ind, newValue, val, index) => {
    const updatedWorkData = talentDetails;
    if (index === "institution_id") {
      updatedWorkData.qualificationdata[ind].institution_id = newValue?.value;
      updatedWorkData.qualificationdata[ind].institution_name = newValue?.label;
    }
    if (index === "qualification_id") {
      updatedWorkData.qualificationdata[ind].qualification_id = newValue?.value;
      updatedWorkData.qualificationdata[ind].qualification_name =
        newValue?.label;
    }
    if (index === "association_id") {
      updatedWorkData.associationdata[ind].association_id = newValue?.value;
      updatedWorkData.associationdata[ind].association_name = newValue?.label;
    }
    if (index === "school_id") {
      updatedWorkData.schooldata[ind].school_id = newValue?.value;
      updatedWorkData.schooldata[ind].school_name = newValue?.label;
    }
    setTalentDetails({ ...talentDetails, updatedWorkData });
    let obj = { ...errors };
    if (newValue?.label || newValue?.value) {
      if (index === "school_id") {
        delete obj[`schooldata[${ind}].${index}`];
        setErrors((prev) =>
          prev?.filter((item) => item?.key !== `schooldata[${ind}].${index}`)
        );
      } else if (index === "association_id") {
        delete obj[`associationdata[${ind}].${index}`];
        setErrors((prev) =>
          prev?.filter(
            (item) => item?.key !== `associationdata[${ind}].${index}`
          )
        );
      } else {
        delete obj[`qualificationdata[${ind}].${index}`];
        setErrors((prev) =>
          prev?.filter(
            (item) => item?.key !== `qualificationdata[${ind}].${index}`
          )
        );
      }
    }

    // setErrors({ ...obj });
  };

  const handleQualificationType = (event, index) => {
    const {
      target: { value },
      target: { name },
    } = event;

    let newCultureData = talentDetails?.qualificationdata;
    newCultureData[index] = {
      ...newCultureData?.[index],
      [name]: value,
    };
    setTalentDetails({ ...talentDetails, newCultureData });
    let obj = { ...errors };
    if (event?.target?.value) {
      delete obj[`qualificationdata[${index}].qualificationtype_id`];
    }
    setErrors((prev) =>
      prev?.filter(
        (item) =>
          item?.key !== `qualificationdata[${index}].qualificationtype_id`
      )
    );
  };

  const handleSelectChange = (event, index) => {
    const newStudyData = talentDetails?.qualificationdata;
    newStudyData[index].year_ended =
      allYears
        ?.find((item) => item?.id === event?.target?.value)
        ?.name?.toString() || "";

    setTalentDetails({ ...talentDetails, newStudyData });
    let obj = { ...errors };
    if (event?.target?.value) {
      delete obj[`qualificationdata[${index}].year_ended`];
    }
    setErrors((prev) =>
      prev?.filter(
        (item) => item?.key !== `qualificationdata[${index}].year_ended`
      )
    );
  };

  const getYearEndedValue = (idx) => {
    if (talentDetails?.qualificationdata?.[idx]?.year_ended === "") {
      return "";
    } else {
      const convertedYear = yearConverter(
        talentDetails?.qualificationdata?.[idx]?.year_ended
      );
      const year = allYears?.find((item) => item.name == convertedYear)?.name;
      return year;
    }
  };

  const handleSelectChange2 = (event, id, name, index) => {
    const newStudyData = talentDetails?.qualificationdata;
    if (newStudyData?.[index]) {
      newStudyData[index]["completed"] = event?.target?.checked?.toString();
      setTalentDetails({ ...talentDetails, newStudyData });
    }
  };

  const removeSchool = (event, index) => {
    if (talentDetails.schooldata.length > 1) {
      const newStudy = talentDetails.schooldata.filter(
        (data, i) => i + 1 !== index
      );
      const newStudyData = { ...talentDetails, schooldata: newStudy };
      setTalentDetails(newStudyData);
    }
  };

  const getSchoolYear = (idx) => {
    if (talentDetails?.schooldata?.[idx]?.year_completion === "") {
      return "";
    } else {
      const year = allYears?.find(
        (item) =>
          item?.name == talentDetails?.schooldata?.[idx]?.year_completion
      )?.name;
      return year;
    }
  };

  const handleSelectChange3 = (event, id, name, index) => {
    const newStudyData = talentDetails?.schooldata;
    if (newStudyData?.[index]) {
      newStudyData[index]["still_completing_flag"] = event?.target?.checked;
      setTalentDetails({ ...talentDetails, newStudyData });
    }
  };

  const handleSchoolYearChange = (event, index) => {
    const newStudyData = talentDetails?.schooldata;
    newStudyData[index].year_completion =
      allYears?.find((item) => item?.id === event?.target?.value)?.name || "";
    setTalentDetails({ ...talentDetails, newStudyData });
  };

  const removeAssociation = (event, index) => {
    if (talentDetails?.associationdata?.length > 1) {
      const newStudy = talentDetails?.associationdata?.filter(
        (data, i) => i + 1 !== index
      );
      const newStudyData = { ...talentDetails, associationdata: newStudy };
      setTalentDetails(newStudyData);
    }
  };

  const getAssociationYear = (idx) => {
    if (talentDetails?.associationdata?.[idx]?.year_completion === "") {
      return "";
    } else {
      const year = allYears?.find(
        (item) =>
          item?.name == talentDetails?.associationdata?.[idx]?.year_completion
      )?.name;
      return year;
    }
  };

  const handleAssociationYearChange = (event, index) => {
    const newStudyData = talentDetails?.associationdata;
    newStudyData[index].year_completion =
      allYears?.find((item) => item?.id === event?.target?.value)?.name || "";
    setTalentDetails({ ...talentDetails, newStudyData });
  };

  const handleSelectChange4 = (event, id, name, index) => {
    const newStudyData = talentDetails?.associationdata;
    if (newStudyData?.[index]) {
      newStudyData[index]["still_completing_flag"] = event?.target?.checked;
      setTalentDetails({ ...talentDetails, newStudyData });
    }
  };

  const addStudy = () => {
    const newStudyData = {
      ...talentDetails,
      qualificationdata: [
        ...talentDetails?.qualificationdata,
        {
          qualification_id: "", // Set appropriate default or blank values
          institution_id: "",
          completed: "false",
          qualificationtype_id: "",
          year_ended: "",
          qualification_name: "",
          institution_name: "",
        },
      ],
    };
    setTalentDetails(newStudyData);
  };

  const addSchool = () => {
    const newStudyData = {
      ...talentDetails,
      schooldata: [
        ...talentDetails?.schooldata,
        {
          school_id: null,
          still_completing_flag: false,
          year_completion: "",
        },
      ],
    };
    setTalentDetails(newStudyData);
  };

  const addAssociation = () => {
    const newStudyData = {
      ...talentDetails,
      associationdata: [
        ...talentDetails?.associationdata,
        {
          association_id: null,
          still_completing_flag: false,
          year_completion: "",
        },
      ],
    };

    setTalentDetails(newStudyData);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "16px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "16px",
          px: 4,
        }}
      >
        <Typography sx={{ fontWeight: theme.typography.Bold }}>
          {i18n["myProfile.Studylife"]}
        </Typography>
        {talentDetails?.qualificationdata &&
          talentDetails?.qualificationdata?.map((work, index) => (
            <Box key={index}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  mb: "23px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "flex-start",
                    flexDirection: "column",
                    gap: "20px",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "flex-start",
                      flexDirection: "row",
                    }}
                  >
                    {index !== 0 && (
                      <IconButton
                        aria-label="edit"
                        color="redButton"
                        sx={{
                          padding: "0 !important",
                          marginRight: "4px",
                        }}
                        onClick={(event) => removeStudy(event, index + 1)}
                      >
                        <RemoveCircleIcon />
                      </IconButton>
                    )}
                    <Typography
                      sx={{
                        fontSize: "18px",
                        fontWeight: theme?.typography?.Bold,
                        flex: 1,
                      }}
                    >
                      {index === 0
                        ? i18n["myCV.latestEducation"]
                        : i18n["myCV.previousEducation"]}
                    </Typography>
                  </Box>
                  {index !== 0 && (
                    <Typography
                      sx={{
                        fontSize: "18px",
                        fontWeight: theme?.typography?.Bold,
                        flex: 1,
                      }}
                    >
                      {"Latest Education"} {i18n["myCV.mostRecent"]}
                    </Typography>
                  )}
                </Box>

                <Box>
                  <Typography
                    sx={{
                      fontSize: "18px",
                      fontWeight: theme?.typography?.Medium,
                      pl: 1,
                      pr: 1,
                    }}
                  >
                    {index === 0 ? i18n["myCV.mostRecent"] : ""}
                  </Typography>
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  mb: "22px",
                  width: "100%",
                }}
              >
                <Box sx={{ width: "47%" }}>
                  <InputLabel
                    htmlFor="institution_id"
                    sx={{
                      color: "black",
                      paddingBottom: "2px",
                      fontSize: "14px",
                      fontWeight: theme.typography.Bold,
                    }}
                  >
                    {i18n["myCV.institutionLabelReq"]}
                  </InputLabel>

                  <CustomSelect
                    index={index}
                    variant="default"
                    onInputChange={handleOnSelectInputChange}
                    onChange={handleOnSelectChange}
                    placeholder={"Select or add the name of the institution"}
                    inputId="institution_id"
                    name="institution_id"
                    value={
                      work?.institution_name || work?.institution_id
                        ? {
                            label: work?.institution_name,
                            value: work?.institution_id,
                          }
                        : null
                    }
                    onMenuOpen={onMenuOpen}
                    onMenuClose={onMenuClose}
                    option={institution?.map((item) => ({
                      value: item?.institution_id,
                      label: item?.name,
                    }))}
                    isLoading={isLoading}
                    customStyle={{
                      option: (
                        styles,
                        { data, isDisabled, isFocused, isSelected }
                      ) => {
                        return {
                          ...styles,
                          color: isSelected ? "#fff" : "black",
                          ":active": {
                            ...styles[":active"],
                          },
                        };
                      },
                      menu: (provided) => ({
                        ...provided,
                        zIndex: 9999,
                      }),
                      menuList: (provided) => ({
                        ...provided,
                        "&::-webkit-scrollbar": {
                          width: "6px",
                          height: "6px",
                          borderRadius: "2.5px",
                          backgroundColor: "#efefef",
                        },
                        "&::-webkit-scrollbar-thumb": {
                          borderRadius: "2.5px",
                        },
                      }),
                      control: (baseStyles) => ({
                        ...baseStyles,
                        boxShadow: "none",
                        borderRadius: "10px",
                        height: `40px`,
                        border: errors?.find(
                          (item) =>
                            item?.key ===
                            `qualificationdata[${index}].institution_id`
                        )
                          ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
                          : "1px solid #E0E0E0",
                        "&:hover": errors?.find(
                          (item) =>
                            item?.key ===
                            `qualificationdata[${index}].institution_id`
                        )
                          ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
                          : "1px solid #E0E0E0",

                        width: `100%`,
                        // 'borderColor': theme?.colors?.primary['400'],
                      }),
                    }}
                  />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "flex-end",
                    width: "47%",
                    gap: "43px",
                  }}
                >
                  <Box
                    sx={{
                      width: "70%",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-end",
                    }}
                  >
                    <InputLabel
                      htmlFor="year_ended"
                      sx={{
                        color: "black",
                        paddingBottom: "2px",
                        fontSize: "14px",
                        fontWeight: theme?.typography?.Bold,
                        width: { sm: "100%" },
                      }}
                    >
                      {i18n["myCV.yearOfCompletionLabelReq"]}
                    </InputLabel>
                    <SelectMenuYear
                      index={index}
                      clear={true}
                      border={
                        // getYearEndedValue(index) === "" &&
                        // errors?.find((error) => error.key === "year_ended")
                        errors?.find(
                          (item) =>
                            item?.key ===
                            `qualificationdata[${index}].year_ended`
                        )
                          ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
                          : "1px solid #E0E0E0"
                      }
                      borderRadius="10px"
                      // height={`${inputBoxHeight}px`}
                      height="40px"
                      width={"100%"}
                      id="year_ended"
                      name="year_ended"
                      value={getYearEndedValue(index)}
                      onHandleChange={(event) =>
                        handleSelectChange(event, index)
                      }
                      options={allYears}
                      placeholder={"Select"}
                    />
                  </Box>
                  <Box
                    sx={{
                      width: "fit-content",
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                      marginTop: "21px",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontWeight: theme?.typography?.Regular,
                        textWrap: "nowrap",
                      }}
                    >
                      {i18n["myCV.stillCompletingLabel"]}
                    </Typography>
                    <ToggleSwitch
                      color="greenSwitch"
                      checked={JSON?.parse(work?.completed?.toLowerCase())}
                      onChange={(event, id, name) =>
                        handleSelectChange2(event, id, name, index)
                      }
                    />
                    {/* {errors?.find((error) => error.key === "completed") && (
                      <Typography color={"red !important"}>
                        {`*${
                          errors?.find((error) => error?.key === "completed")
                            .message
                        }`}
                      </Typography>
                    )} */}
                  </Box>
                </Box>
              </Box>

              <Box sx={{ mb: "22px", width: "100%" }}>
                <InputLabel
                  htmlFor="qualification_id"
                  sx={{
                    color: "black",
                    paddingBottom: "2px",
                    fontSize: "14px",
                    fontWeight: theme?.typography?.Bold,
                  }}
                >
                  {i18n["myCV.qualificationLabelReq"]}
                </InputLabel>

                <CustomSelect
                  index={index}
                  variant="default"
                  onInputChange={handleOnSelectInputChange}
                  onChange={handleOnSelectChange}
                  placeholder={"Select or add the name of the qualification"}
                  inputId="qualification_id"
                  name="qualification_id"
                  value={
                    work?.qualification_name || work?.qualification_id
                      ? {
                          label: work?.qualification_name,
                          value: work?.qualification_id,
                        }
                      : null
                  }
                  onMenuOpen={() => {
                    if (qualification?.length === 0) {
                      getAllData("qualification");
                    }
                    setIsMenuOpen(true);
                  }}
                  onMenuClose={onMenuClose}
                  // option={arr?.map((item)=>({value:item?.value,label:item?.label}))}
                  option={qualification?.map((item) => ({
                    value: item?.qualification_id,
                    label: item?.name,
                  }))}
                  isLoading={isLoading}
                  customStyle={{
                    option: (
                      styles,
                      { data, isDisabled, isFocused, isSelected }
                    ) => {
                      return {
                        ...styles,
                        // backgroundColor: "",
                        color: isSelected ? "#fff" : "black",
                        ":active": {
                          ...styles[":active"],
                          // backgroundColor: "#d3d3d3",
                        },
                      };
                    },
                    menu: (provided) => ({
                      ...provided,
                      zIndex: 9999,
                    }),
                    menuList: (provided) => ({
                      ...provided,
                      "&::-webkit-scrollbar": {
                        width: "6px",
                        height: "6px",
                        borderRadius: "2.5px",
                        backgroundColor: "#efefef",
                      },
                      "&::-webkit-scrollbar-thumb": {
                        // backgroundColor: `${theme?.colors?.primary['400']}`,
                        borderRadius: "2.5px",
                      },
                    }),
                    control: (baseStyles) => ({
                      ...baseStyles,
                      boxShadow: "none",
                      borderRadius: "10px",
                      height: `40px`,
                      width: `100%`,
                      border: errors?.find(
                        (item) =>
                          item?.key ===
                          `qualificationdata[${index}].qualification_id`
                      )
                        ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
                        : "1px solid #E0E0E0",
                      "&:hover": errors?.find(
                        (item) =>
                          item?.key ===
                          `qualificationdata[${index}].qualification_id`
                      )
                        ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
                        : "1px solid #E0E0E0",
                      // width: `100%`,
                      // 'borderColor': theme?.colors?.primary['400'],
                    }),
                  }}
                />
              </Box>

              <Box sx={{ width: "100%" }}>
                <InputLabel
                  htmlFor="qualificationtype_id"
                  sx={{
                    color: "black",
                    paddingLeft: "10px",
                    paddingBottom: "2px",
                    fontSize: "14px",
                    fontWeight: theme?.typography?.Bold,
                  }}
                >
                  {i18n["myCV.typeOfQualificationLabelReq"]}
                </InputLabel>
                <SelectMenu
                  clear={true}
                  border={
                    errors?.find(
                      (item) =>
                        item?.key ===
                        `qualificationdata[${index}].qualificationtype_id`
                    )
                      ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
                      : "1px solid #E0E0E0"
                  }
                  borderRadius="10px"
                  height={"40px"}
                  width={"100%"}
                  name="qualificationtype_id"
                  value={work?.qualificationtype_id}
                  onFocus={() => {
                    if (typeOfQualification?.length === 0) {
                      getAllData("typeQualification");
                    }
                  }}
                  onHandleChange={(event) =>
                    handleQualificationType(event, index)
                  }
                  options={typeOfQualification}
                  placeholder={i18n["myCV.typeOfqualificationPlaceholder"]}
                />
              </Box>
            </Box>
          ))}
      </Box>

      <Button
        variant="contained"
        color="greenSwitch"
        onClick={addStudy}
        sx={{
          borderRadius: "0px 10px 10px 0px",
          width: "230px",
          height: "41px",
        }}
      >
        {i18n["myCV.previousStudyBottonText"]}
      </Button>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "16px",
          px: 4,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
            alignItems: "flex-start",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography
              sx={{
                color: "black",
                paddingBottom: "2px",
                fontSize: "14px",
                fontWeight: theme?.typography?.Bold,
              }}
            >
              {i18n["myCV.highSchoolLabel"]}
            </Typography>
          </Box>
        </Box>

        {talentDetails?.schooldata &&
          talentDetails?.schooldata?.map((schools, index) => (
            <Box key={index}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "flex-start",
                  }}
                >
                  {index !== 0 && (
                    <IconButton
                      aria-label="edit"
                      color="redButton"
                      sx={{
                        padding: "0 !important",
                        marginRight: "4px",
                      }}
                      onClick={(event) => removeSchool(event, index + 1)}
                    >
                      <RemoveCircleIcon />
                    </IconButton>
                  )}
                  <Typography
                    sx={{
                      fontSize: "18px",
                      fontWeight: theme?.typography?.Bold,
                      flex: 1,
                      mb: 1,
                    }}
                  >
                    {index > 0 && i18n["myCV.previousEducation"]}
                  </Typography>
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <Box sx={{ width: "47%" }}>
                  <InputLabel
                    htmlFor="school_id"
                    sx={{
                      color: "black",
                      paddingBottom: "2px",
                      fontSize: "14px",
                      fontWeight: theme?.typography?.Bold,
                    }}
                  >
                    {i18n["myCV.schoolNameReq"]}
                  </InputLabel>
                  <CustomSelect
                    index={index}
                    variant="default"
                    onInputChange={handleOnSelectInputChange}
                    onChange={handleOnSelectChange}
                    placeholder={i18n["myCV.schoolPlaceholder"]}
                    inputId="school_id"
                    name="school_id"
                    value={
                      schools?.school_id || schools?.school_name
                        ? {
                            value: schools?.school_id,
                            label: schools?.school_name,
                          }
                        : null
                    }
                    onMenuOpen={() => {
                      if (school?.length === 0) {
                        getAllData("school");
                      }
                      setIsMenuOpen(true);
                    }}
                    onMenuClose={onMenuClose}
                    // option={arr?.map((item)=>({value:item?.value,label:item?.label}))}
                    option={school?.map((item) => ({
                      value: item?.school_id,
                      label: item?.name,
                    }))}
                    isLoading={isLoading}
                    customStyle={{
                      option: (
                        styles,
                        { data, isDisabled, isFocused, isSelected }
                      ) => {
                        return {
                          ...styles,
                          // backgroundColor: "",
                          color: isSelected ? "#fff" : "black",
                          ":active": {
                            ...styles[":active"],
                            // backgroundColor: "#d3d3d3",
                          },
                        };
                      },
                      menu: (provided) => ({
                        ...provided,
                        zIndex: 9999,
                      }),
                      menuList: (provided) => ({
                        ...provided,
                        "&::-webkit-scrollbar": {
                          width: "6px",
                          height: "6px",
                          borderRadius: "2.5px",
                          backgroundColor: "#efefef",
                        },
                        "&::-webkit-scrollbar-thumb": {
                          // backgroundColor: `${theme?.colors?.primary['400']}`,
                          borderRadius: "2.5px",
                        },
                      }),
                      control: (baseStyles) => ({
                        ...baseStyles,
                        boxShadow: "none",
                        borderRadius: "10px",
                        height: `40px`,
                        // width: `${inputBoxWidth}px`,
                        border: errors?.find(
                          (item) =>
                            item?.key === `schooldata[${index}].school_id`
                        )
                          ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
                          : "1px solid #E0E0E0",
                        "&:hover": errors?.find(
                          (item) =>
                            item?.key === `schooldata[${index}].school_id`
                        )
                          ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
                          : "1px solid #E0E0E0",
                        width: `100%`,
                      }),
                    }}
                  />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "flex-end",
                    width: "47%",
                    gap: "43px",
                  }}
                >
                  <Box
                    sx={{
                      width: "70%",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-end",
                    }}
                  >
                    <InputLabel
                      htmlFor="school_year_end"
                      sx={{
                        color: "black",
                        paddingBottom: "2px",
                        fontSize: "14px",
                        fontWeight: theme?.typography?.Bold,
                        width: "100%",
                      }}
                    >
                      {i18n["myCV.yearOfCompletionLabelOpt"]}
                    </InputLabel>
                    <SelectMenuYear
                      clear={true}
                      border="1px solid #C9CAD8"
                      borderRadius="10px"
                      height="40px"
                      width="100%"
                      id="school_year_end"
                      name="school_year_end"
                      value={getSchoolYear(index)}
                      onHandleChange={(event) =>
                        handleSchoolYearChange(event, index)
                      }
                      options={allYears}
                      placeholder={"Select"}
                    />
                    {/* {!schools.year_completion &&
                      errors?.find(
                        (error) => error?.key === "school_year_end"
                      ) && (
                        <Typography color={"red !important"}>
                          {`*${
                            errors?.find(
                              (error) => error?.key === "school_year_end"
                            )?.message
                          }`}
                        </Typography>
                      )} */}
                  </Box>
                  <Box
                    sx={{
                      width: "fit-content",
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                      marginTop: "21px",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontWeight: theme?.typography?.Regular,
                        textWrap: "nowrap",
                      }}
                    >
                      {i18n["myCV.stillCompletingLabel"]}
                    </Typography>
                    <ToggleSwitch
                      color="greenSwitch"
                      checked={schools?.still_completing_flag}
                      onChange={(event, id, name) =>
                        handleSelectChange3(event, id, name, index)
                      }
                    />
                    {/* {errors?.find(
                      (error) => error.key === "school_completed"
                    ) && (
                      <Typography color={"red !important"}>
                        {`*${
                          errors?.find(
                            (error) => error?.key === "school_completed"
                          )?.message
                        }`}
                      </Typography>
                    )} */}
                  </Box>
                </Box>
              </Box>
            </Box>
          ))}
      </Box>
      <Button
        variant="contained"
        color="greenSwitch"
        onClick={addSchool}
        sx={{
          borderRadius: "0px 10px 10px 0px",
          width: "230px",
          height: "41px",
        }}
      >
        {"+ add a school / A-Level"}
      </Button>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "16px",
          px: 4,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography
              sx={{
                color: "black",
                paddingBottom: "2px",
                fontSize: "14px",
                fontWeight: theme?.typography?.Bold,
              }}
            >
              {i18n["myCV.professionalAssociationLabelOpt"]}
            </Typography>
          </Box>
        </Box>
        {talentDetails?.associationdata &&
          talentDetails?.associationdata?.map((associations, index) => (
            <Box key={index}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                }}
              >
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  {index !== 0 && (
                    <IconButton
                      aria-label="edit"
                      color="redButton"
                      sx={{
                        padding: "0 !important",
                        marginRight: "4px",
                      }}
                      onClick={(event) => removeAssociation(event, index + 1)}
                    >
                      <RemoveCircleIcon />
                    </IconButton>
                  )}
                  <Typography
                    sx={{
                      fontSize: "18px",
                      fontWeight: theme?.typography?.Bold,
                      flex: 1,
                    }}
                  >
                    {index > 0 && i18n["myCV.previousEducation"]}
                  </Typography>
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <Box sx={{ width: "47%" }}>
                  <InputLabel
                    htmlFor="association_id"
                    sx={{
                      color: "black",
                      paddingBottom: "2px",
                      fontSize: "14px",
                      fontWeight: theme?.typography?.Bold,
                    }}
                  >
                    {i18n["myCV.associationLabel"]}
                  </InputLabel>
                  <CustomSelect
                    index={index}
                    variant="default"
                    onInputChange={handleOnSelectInputChange}
                    onChange={handleOnSelectChange}
                    placeholder={i18n["myCV.associationPlaceholder"]}
                    inputId="association_id"
                    name="association_id"
                    value={
                      associations?.association_id ||
                      associations?.association_name
                        ? {
                            value: associations?.association_id,
                            label: associations?.association_name,
                          }
                        : null
                    }
                    onMenuOpen={() => {
                      if (association?.length === 0) {
                        getAllData("association");
                      }
                      setIsMenuOpen(true);
                    }}
                    onMenuClose={onMenuClose}
                    option={association?.map((item) => ({
                      value: item?.association_id,
                      label: item?.name,
                    }))}
                    isLoading={isLoading}
                    customStyle={{
                      option: (
                        styles,
                        { data, isDisabled, isFocused, isSelected }
                      ) => {
                        return {
                          ...styles,
                          color: isSelected ? "#fff" : "black",
                          ":active": {
                            ...styles[":active"],
                          },
                        };
                      },
                      menu: (provided) => ({
                        ...provided,
                        zIndex: 9999,
                      }),
                      menuList: (provided) => ({
                        ...provided,
                        "&::-webkit-scrollbar": {
                          width: "6px",
                          height: "6px",
                          borderRadius: "2.5px",
                          backgroundColor: "#efefef",
                        },
                        "&::-webkit-scrollbar-thumb": {
                          borderRadius: "2.5px",
                        },
                      }),
                      control: (baseStyles) => ({
                        ...baseStyles,
                        boxShadow: "none",
                        borderRadius: "10px",
                        height: `40px`,
                        border: errors?.find(
                          (item) =>
                            item?.key ===
                            `associationdata[${index}].association_id`
                        )
                          ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
                          : "1px solid #E0E0E0",
                        "&:hover": errors?.find(
                          (item) =>
                            item?.key ===
                            `associationdata[${index}].association_id`
                        )
                          ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
                          : "1px solid #E0E0E0",
                        width: `100%`,
                      }),
                    }}
                  />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "flex-end",
                    width: "47%",
                    gap: "43px",
                  }}
                >
                  <Box
                    sx={{
                      width: "70%",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-end",
                    }}
                  >
                    <InputLabel
                      htmlFor="association_year_ended"
                      sx={{
                        color: "black",
                        paddingBottom: "2px",
                        fontSize: "14px",
                        fontWeight: theme?.typography?.Bold,
                        width: "100%",
                      }}
                    >
                      {i18n["myCV.yearOfCompletionLabelOpt"]}
                    </InputLabel>
                    <SelectMenuYear
                      clear={true}
                      border="1px solid #C9CAD8"
                      borderRadius="10px"
                      height="40px"
                      width="100%"
                      id="association_year_end"
                      name="association_year_end"
                      onHandleChange={(event) =>
                        handleAssociationYearChange(event, index)
                      }
                      value={getAssociationYear(index)}
                      options={allYears}
                      placeholder={"Select"}
                    />
                    {/* {!studyData?.otherdata?.association_year_end &&
                      errors?.find(
                        (error) => error?.key === "association_year_end"
                      ) && (
                        <Typography color={"red !important"}>
                          {`*${
                            errors?.find(
                              (error) => error?.key === "association_year_end"
                            )?.message
                          }`}
                        </Typography>
                      )} */}
                  </Box>
                  <Box
                    sx={{
                      width: "fit-content",
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                      marginTop: "21px",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontWeight: theme?.typography?.Regular,
                        textWrap: "nowrap",
                      }}
                    >
                      {i18n["myCV.stillCompletingLabel"]}
                    </Typography>

                    <ToggleSwitch
                      color="greenSwitch"
                      checked={associations.still_completing_flag}
                      onChange={(event, id, name) =>
                        handleSelectChange4(event, id, name, index)
                      }
                    />
                    {/* {errors?.find(
                      (error) => error.key === "association_completed"
                    ) && (
                      <Typography color={"red !important"}>
                        {`*${
                          errors?.find(
                            (error) => error?.key === "association_completed"
                          )?.message
                        }`}
                      </Typography>
                    )} */}
                  </Box>
                </Box>
              </Box>
            </Box>
          ))}
      </Box>
      <Button
        variant="contained"
        color="greenSwitch"
        onClick={addAssociation}
        sx={{
          borderRadius: "0px 10px 10px 0px",
          width: "230px",
          height: "41px",
          marginBottom: "32px",
        }}
      >
        {"+ add an association"}
      </Button>
    </Box>
  );
}
