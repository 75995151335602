import { Box, Typography, styled, useTheme } from "@mui/material";
import React from "react";
import close from "../../assets/Padding Included/Black_Close.svg";

export default function CloseIcon({ top = "15px", right = "15px", onClick }) {
  const CrossContainer = styled(Box)({
    width: "15px",
    height: "15px",
    position: "relative",
    // border: "2px solid black",
    borderRadius: "5px",
    boxSizing: "border-box",
  });

  const CrossLine = styled(Box)({
    position: "absolute",
    top: "50%",
    left: "50%",
    width: "70%",
    height: "2px",
    backgroundColor: "black",
    borderRadius: "5px",
  });

  return (
    <>
      {/*<CrossContainer>
      <CrossLine sx={{ transform: "translate(-50%, -50%) rotate(45deg)" }} />
      <CrossLine sx={{ transform: "translate(-50%, -50%) rotate(-45deg)" }} />
    </CrossContainer>*/}
      <Box
        sx={{
          height: "15px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          // width: "15px",
          // border: "2px solid black",
          // borderRadius: "5px",
          position: "absolute",
          top: top,
          right: right,
          cursor: "pointer",
        }}
        onClick={onClick}
      >
        <Box
          component={"img"}
          src={close}
          sx={{
            height: 23,
            width: 23,
          }}
        />
      </Box>
    </>
  );
}
