import { Box } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import TalentsCard from "../../../common/TalentsCard";
import LeftArrow from "../../../common/LeftArrow";
import RightArrow from "../../../common/RightArrow";
import { Button, useTheme } from "@mui/material";
import LeftDrawer from "../../LeftDrawer";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import {
  getTalentsData,
  setLeftMenuButton,
} from "../../../../redux/admin/jobsSlice";
import { setAlert } from "../../../../redux/configSlice";
import { ALERT_TYPE } from "../../../../utils/Constants";

export default function PromoterDetailInfo({ listName, poolDelete }) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { id } = useParams();

  const [allTalents, setAllTalent] = useState([]);
  const [talentContent, setTalentContent] = useState();
  const [leftMenu, setLeftMenu] = useState(false);
  const [filtersValue, setFiltersValue] = useState({
    quickSearch: "",
    talentStage: "",
    talentPool: "",
    job: "",
    talentStatus: "",
    databases: "",
  });

  const getJobList = async (lastkeyy, searchtitle, newBasicData) => {
    const data = {
      ...newBasicData,
      mytalent: listName === "My Talent" && true,
      applicant: listName === "Applicants" && true,
      follower: listName === "Followers" && true,
    };
    const { payload } = await dispatch(
      getTalentsData({
        payload: data,
        lastKey: lastkeyy,
        query: searchtitle ? searchtitle : filtersValue?.quickSearch,
        candidateID: id ? id : "",
      })
    );
    if (payload?.status === "success") {
      setTalentContent(
        payload?.data?.map((item) => ({
          currencyname: item?.currencyname,
          candidate_user_id: item?.candidate_user_id,
          first_name: item?.first_name,
          last_name: item?.last_name,
          job_title: item?.jobTitle,
          dob: item?.dob,
          town_name: item?.town_name,
          country_name: item?.country_name,
          currency_symbol: item?.currencySymbol,
          salary_min: item?.salaryMin,
          salary_max: item?.salaryMax,
          experience: item?.experienceYearEnd,
          notice_period: item?.noticePeriod_description,
          email: item?.email,
          total_user_count: item?.TotalUserCount,
          total_user_shortlist: item?.totalusershorlisted,
          total_user_interview: item?.totaluserinterviewed,
          employement_type: item?.employment_type,
          work_setup: item?.work_setup,
          created_at: item?.created_at,
          maxInterviewStageName: item?.maxInterviewStageName,
          gender: item?.gender,
          ...item,
        }))
      );
    } else if (payload?.status === "error") {
      dispatch(
        setAlert({
          show: true,
          type: ALERT_TYPE?.ERROR,
          msg: "Login as employer to access",
        })
      );
    }
  };

  useEffect(() => {
    getJobList();
  }, []);

  return (
    <Box sx={{ display: "flex" }}>
      <Box
        sx={{ position: "absolute", zIndex: "1100", display: "flex" }}
        onMouseEnter={() => {
          dispatch(setLeftMenuButton(true));
          setLeftMenu(true);
        }}
        onMouseLeave={() => {
          dispatch(setLeftMenuButton(false));
          setLeftMenu(false);
        }}
      >
        <LeftDrawer leftExpanded={leftMenu} />
        <Button
          variant="contained"
          sx={{
            position: "static",
            borderRadius: "0 10px 10px 0",
            minWidth: "30px !important",
            padding: "0px",
            height: "45px",
            backgroundColor: theme.palette.redButton100.main,
            ":hover": {
              backgroundColor: theme.palette.redButton100.main,
              boxShadow: "none",
              cursor: "pointer", // Change cursor to indicate it's clickable
            },
          }}
          // onClick={() => {
          //   setLeftMenu((prevState) => !prevState);
          // }}
        >
          {leftMenu ? <LeftArrow /> : <RightArrow />}
        </Button>
      </Box>
      <Box
        sx={{
          width: "100%",
          margin: "55px 40px 0 110px !important",
          overflowY: "auto", // Add this line to make the Box scrollable vertically
          maxHeight: "calc(100vh - 100px)", // Adjust the max height as needed
        }}
      >
        <TalentsCard
          talentContent={talentContent?.[0]}
          allTalent={allTalents}
          getJobList={getJobList}
          setAllTalent={setAllTalent}
          telId={talentContent?.candidate_user_id}
          poolDelete={poolDelete}
        />
      </Box>
    </Box>
  );
}
