import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getApi, postApi } from "../../utils/Apis";
import { setLoading } from "../configSlice";

const initialState = {
  jobs: [],
  talentpool: [],
  clientpool: [],
  databases: [],
  poolUpdated: false,
  leftMenuButton: false,
  createPool: false,
  candidateID: "",
  leftDrawerTalentPool: [],
  leftDrawerClientPool: [],
};

export const uploadPoolImage = createAsyncThunk(
  "uploadPoolImage",
  async (payload, { dispatch }) => {
    // dispatch(setLoading(true));
    const { data } = await postApi(
      "/upload/poollogo",
      payload,
      true,
      "multipart/form-data"
    ); //"multipart/form-data"
    // dispatch(setLoading(false));
    return data;
  }
);

export const linkedToJob = createAsyncThunk(
  "linkedToJob",
  async (
    {
      lastKey,
      status_id,
      job_stage,
      job_title,
      job_type,
      team_member_user_id,
      company_id,
    },
    { dispatch }
  ) => {
    const { data } = await getApi(
      "/admin/job?lastKey=" +
        lastKey +
        "&status_id=" +
        status_id +
        "&job_title=" +
        job_title +
        "&job_stage=" +
        job_stage +
        "&job_type=" +
        job_type +
        "&team_member_user_id=" +
        team_member_user_id +
        "&company_id=" +
        company_id,
      true
    );
    return data;
  }
);

export const getLinkedJobTalent = createAsyncThunk(
  "getLinkedJobTalent",
  async (payload, { dispatch }) => {
    // dispatch(setLoading(true));
    const { data } = await postApi("/admin/linktojob", payload, true);
    // dispatch(setLoading(false));
    return data;
  }
);

export const editJobBounty = createAsyncThunk(
  "editJobBounty",
  async (payload, { dispatch }) => {
    // dispatch(setLoading(true));
    const { data } = await postApi("/admin/bountyupdate", payload, true);
    // dispatch(setLoading(false));
    return data;
  }
);

export const companyJobsList = createAsyncThunk(
  "companyJobsList",
  async (
    {
      lastKey = "",
      status_id = "",
      job_stage = "",
      job_title = "",
      job_type = "",
      team_member_user_id = "",
      company_id = "",
      job_id = "",
    },
    { dispatch }
  ) => {
    const { data } = await getApi(
      "/admin/job?lastKey=" +
        lastKey +
        "&status_id=" +
        status_id +
        "&job_title=" +
        job_title +
        "&job_stage=" +
        job_stage +
        "&job_type=" +
        job_type +
        "&team_member_user_id=" +
        team_member_user_id +
        "&company_id=" +
        company_id +
        "&job_id=" +
        job_id,
      true
    );
    return data;
  }
);

export const adminJobsFilter = createAsyncThunk(
  "adminJobsFilter",
  async (
    {
      lastKey = "",
      status_id = "",
      job_stage = "",
      job_title = "",
      job_type = "",
      team_member_user_id = "",
      company_id = "",
      job_id = "",
      post_incognito = "",
    },
    { dispatch }
  ) => {
    dispatch(setLoading(true));
    const { data } = await getApi(
      "/admin/job?lastKey=" +
        lastKey +
        "&status_id=" +
        status_id +
        "&job_title=" +
        job_title +
        "&job_stage=" +
        job_stage +
        "&job_type=" +
        job_type +
        "&team_member_user_id=" +
        team_member_user_id +
        "&company_id=" +
        company_id +
        "&job_id=" +
        job_id +
        "&post_incognito=" +
        post_incognito,
      true
    );

    dispatch(setLoading(false));
    return data;
  }
);

export const getTeamMembers = createAsyncThunk(
  "getTeamMembers",
  async (payload, { dispatch }) => {
    dispatch(setLoading(true));
    const { data } = await getApi("/admin/teammember?lastKey=", true);
    dispatch(setLoading(false));
    return data;
  }
);

export const getHistoryLogs = createAsyncThunk(
  "getHistoryLogs",
  async ({ email, lastKey }, { dispatch }) => {
    dispatch(setLoading(true));
    const { data } = await getApi(
      `/admin/user/history?email=${email}&lastKey=${lastKey}`,
      true
    );
    dispatch(setLoading(false));
    return data;
  }
);
export const getHistoryLogsManageDashboard = createAsyncThunk(
  "getHistoryLogsManageDashboard",
  async ({ email, lastKey,job_id }, { dispatch }) => {
    dispatch(setLoading(true));
    const { data } = await getApi(
      `/admin/user/jobhistory?email=${email}&job_id=${job_id}&lastKey=${lastKey}`,
      true
    );
    dispatch(setLoading(false));
    return data;
  }
);


export const pinATalent = createAsyncThunk(
  "pinATalent",
  async (payload, { dispatch }) => {
    dispatch(setLoading(true));
    const { data } = await postApi("/admin/pintalent", payload, true);
    dispatch(setLoading(false));
    return data;
  }
);

export const pinAJob = createAsyncThunk(
  "pinAJob",
  async (payload, { dispatch }) => {
    dispatch(setLoading(true));
    const { data } = await postApi("/admin/pinjob", payload, true);
    dispatch(setLoading(false));
    return data;
  }
);

export const lockAJob = createAsyncThunk(
  "lockAJob",
  async (payload, { dispatch }) => {
    dispatch(setLoading(true));
    const { data } = await postApi("/admin/lockjob", payload, true);
    dispatch(setLoading(false));
    return data;
  }
);

export const updateJobBounty = createAsyncThunk(
  "updateJobBounty",
  async (payload, { dispatch }) => {
    dispatch(setLoading(true));
    const { data } = await postApi("/admin/bountyupdate", payload, true);
    dispatch(setLoading(false));
    return data;
  }
);

export const updateTalentPool = createAsyncThunk(
  "updateTalentPool",
  async (payload, { dispatch }) => {
    dispatch(setLoading(true));
    const { data } = await postApi("/admin/editpool", payload, true);
    dispatch(setLoading(false));
    return data;
  }
);
export const deleteTalentPool = createAsyncThunk(
  "deleteTalentPool",
  async (payload, { dispatch }) => {
    dispatch(setLoading(true));
    const { data } = await postApi("/admin/deletepool", payload, true);
    dispatch(setLoading(false));
    return data;
  }
);

export const getSearchResult = createAsyncThunk(
  "getSearchResult",
  async (payload, { dispatch }) => {
    dispatch(setLoading(true));
    const { data } = await postApi(
      "/admin/buildsearch?lastKey=",
      payload,
      true
    );
    dispatch(setLoading(false));
    return data;
  }
);
export const getTalentDataById = createAsyncThunk(
  "getTeamMembers",
  async (payload, { dispatch }) => {
    // dispatch(setLoading(true));
    const { data } = await getApi(
      `/admin/getCandidate?talent_id=${payload}`,
      true
    );
    // dispatch(setLoading(false));
    return data;
  }
);
export const deleteSearchPool = createAsyncThunk(
  "deleteSearchPool",
  async (payload, { dispatch }) => {
    const { data } = await getApi(
      `admin/search/delete?search_id=${payload}`,
      true
    );
    return data;
  }
);
export const getCandidateComments = createAsyncThunk(
  "getCandidateComments",
  async ({ candidate_id, sort_type }, { dispatch }) => {
    // dispatch(setLoading(true));
    const { data } = await getApi(
      `/admin/allCandidateComment?candidate_id=${candidate_id}&sort_type=${sort_type}`,
      true
    );
    // dispatch(setLoading(false));
    return data;
  }
);

export const getEmployerComments = createAsyncThunk(
  "getEmployerComments",
  async ({ employer_id, sort_type }, { dispatch }) => {
    // dispatch(setLoading(true));
    const { data } = await getApi(
      `/admin/getEmployerComments?employer_id=${employer_id}&sort_type=${sort_type}`,
      true
    );
    // dispatch(setLoading(false));
    return data;
  }
);

export const getRecruiterComments = createAsyncThunk(
  "getRecruiterComments",
  async ({ recruiter_id, sort_type }, { dispatch }) => {
    // dispatch(setLoading(true));
    const { data } = await getApi(
      `/admin/getRecruiterComments?recruiter_id=${recruiter_id}&sort_type=${sort_type}`,
      true
    );
    // dispatch(setLoading(false));
    return data;
  }
);

export const getCompanyComment = createAsyncThunk(
  "getCompanyComment",
  async ({ company_id, sort_type }, { dispatch }) => {
    // dispatch(setLoading(true));
    const { data } = await getApi(
      `/admin/getCompanyComments?company_id=${company_id}&sort_type=${sort_type}`,
      true
    );
    // dispatch(setLoading(false));
    return data;
  }
);

export const postTalentComment = createAsyncThunk(
  "postTalentComment",
  async (payload, { dispatch }) => {
    // dispatch(setLoading(true));
    const { data } = await postApi(`/admin/addCandidateComment`, payload, true);
    // dispatch(setLoading(false));
    return data;
  }
);

export const postCompanyComment = createAsyncThunk(
  "postCompanyComment",
  async (payload, { dispatch }) => {
    // dispatch(setLoading(true));
    const { data } = await postApi(`/admin/addCompanyComment`, payload, true);
    // dispatch(setLoading(false));
    return data;
  }
);

export const postEmployerComments = createAsyncThunk(
  "postEmployerComments",
  async (payload, { dispatch }) => {
    // dispatch(setLoading(true));
    const { data } = await postApi(`/admin/addEmployerComment`, payload, true);
    // dispatch(setLoading(false));
    return data;
  }
);

export const postRecruiterComment = createAsyncThunk(
  "postRecruiterComment",
  async (payload, { dispatch }) => {
    // dispatch(setLoading(true));
    const { data } = await postApi(`/admin/addRecruiterComment`, payload, true);
    // dispatch(setLoading(false));
    return data;
  }
);

export const getAllJobs = createAsyncThunk(
  "getAllJobs",
  async (
    { lastKey, status_id, job_stage, job_title, job_type },
    { dispatch }
  ) => {
    dispatch(setLoading(true));
    const { data } = await getApi(
      "/admin/job?lastKey=" +
        lastKey +
        "&status_id=" +
        status_id +
        "&job_title=" +
        job_title +
        "&job_stage=" +
        job_stage +
        "&job_type=" +
        job_type,
      true
    );

    dispatch(setLoading(false));
    return data;
  }
);

export const getAdminTalentJobList = createAsyncThunk(
  "getAdminTalentJobList",
  async (
    {
      lastKey = "",
      pool_id,
      candidate_id = "",
      query = "",
      linkjobflag = false,
    },
    { dispatch }
  ) => {
    const url =
      pool_id === undefined
        ? `/admin/getTalentJobData?lastKey=${lastKey}&candidate_id=${candidate_id}&query=${query}`
        : `/admin/getTalentJobData?lastKey=${lastKey}&pool_id=${pool_id}&query=${query}&linkjobflag=${linkjobflag}`;

    const { data } = await getApi(url, true);

    return data;
  }
);

export const getJobCount = createAsyncThunk(
  "getJobCount",
  async (payload, { dispatch }) => {
    dispatch(setLoading(true));
    const { data } = await getApi(`/admin/getcount?status_id=${payload}`, true);
    dispatch(setLoading(false));
    return data;
  }
);

export const jobAlertGet = createAsyncThunk(
  "jobAlertGet",
  async ({ job_id }, { dispatch }) => {
    dispatch(setLoading(true));
    const { data } = await getApi(
      `/admin/user/jobalertsent?job_id=${job_id}`,
      true
    );
    dispatch(setLoading(false));
    return data;
  }
);

// export const approveJob = createAsyncThunk(
//   "approveJob",
//   async (payload, { dispatch }) => {
//     dispatch(setLoading(true));
//     const { data } = await postApi("/admin/approvejob", payload, true);
//     dispatch(setLoading(false));
//     return data;
//   }
// );

export const approveJob = createAsyncThunk(
  "approveJob",
  async (payload, { dispatch }) => {
    dispatch(setLoading(true));
    const { data } = await postApi(
      "/admin/confirmjobstatus",
      payload,
      true,
      "multipart/form-data"
    ); //"multipart/form-data"
    dispatch(setLoading(false));
    return data;
  }
);

export const talentPersonality = createAsyncThunk(
  "talentPersonality",
  async (payload, { dispatch }) => {
    dispatch(setLoading(true));
    const { data } = await postApi("/admin/talentPersonality", payload, true);
    dispatch(setLoading(false));
    return data;
  }
);

export const addJobComment = createAsyncThunk(
  "addJobComment",
  async (payload, { dispatch }) => {
    // dispatch(setLoading(true));
    const { data } = await postApi("/admin/addJobComment", payload, true);
    // dispatch(setLoading(false));
    return data;
  }
);

export const getAllComments = createAsyncThunk(
  "getAllComments",
  async ({ job_id, sort_type }, { dispatch }) => {
    dispatch(setLoading(true));
    const { data } = await getApi(
      `/admin/allJobComment?job_id=${job_id}&sort_type=${sort_type}`,
      true
    );
    dispatch(setLoading(false));
    return data;
  }
);

export const getFreshTalentList = createAsyncThunk(
  "getFreshTalentList",
  async (
    {
      lastKey,
      title,
      applicantpool = "false",
      followerpool = "false",
      pool_id,
      job_id,
      stage,
    },
    { dispatch }
  ) => {
    const { data } = await getApi(
      "/admin/talent?lastKey=" +
        lastKey +
        "&title=" +
        title +
        "&applicantpool=" +
        applicantpool +
        "&followerpull=" +
        followerpool +
        "&pool_id=" +
        pool_id +
        "&job_id=" +
        job_id +
        "&stage=" +
        stage,
      true
    );
    return data;
  }
);

export const getAllTalentJobs = createAsyncThunk(
  "getAllTalentJobs",
  async (
    {
      lastKey = "",
      title = "",
      applicantpool = "false",
      followerpool = "false",
      pool_id = "",
      job_id = "",
      stage = "",
      talent_id = "",
    },
    { dispatch }
  ) => {
    dispatch(setLoading(true));
    const { data } = await getApi(
      "/admin/talent?lastKey=" +
        lastKey +
        "&title=" +
        title +
        "&applicantpool=" +
        applicantpool +
        "&followerpull=" +
        followerpool +
        "&pool_id=" +
        pool_id +
        "&job_id=" +
        job_id +
        "&stage=" +
        stage +
        "&limit=" +
        20,
      true
    );
    dispatch(setLoading(false));
    return data;
  }
);

export const updateTalentDetail = createAsyncThunk(
  "updateTalentDetail",
  async (payload, { dispatch }) => {
    dispatch(setLoading(true));
    const { data } = await postApi("/admin/editTalentData", payload, true);
    dispatch(setLoading(false));
    return data;
  }
);

export const getAllMyTalent = createAsyncThunk(
  "getAllMyTalent",
  async ({ lastKey }, { dispatch }) => {
    dispatch(setLoading(true));
    const { data } = await getApi("/admin/mytalent?lastKey=" + lastKey, true);
    dispatch(setLoading(false));
    return data;
  }
);

export const getTalentsData = createAsyncThunk(
  "getTalentsData",
  async (
    {
      payload,
      lastKey = "",
      query = "",
      pool_id,
      candidateID = "",
      promoterflag = "",
    },
    { dispatch }
  ) => {
    dispatch(setLoading(true));
    const url =
      pool_id === undefined
        ? `/admin/newbuildsearch?lastKey=${lastKey}&query=${query}&candidate_id=${candidateID}&promoterflag=${promoterflag}`
        : `/admin/newbuildsearch?lastKey=${lastKey}&query=${query}&pool_id=${pool_id}&candidate_id=${candidateID}`;

    const { data } = await postApi(url, payload, true);

    dispatch(setLoading(false));
    return data;
  }
);

export const getCandiateApplicationVideo = createAsyncThunk(
  "getCandiateApplicationVideo",
  async ({ talent_id, lastKey, title }, { dispatch }) => {
    // dispatch(setLoading(true));
    const { data } = await getApi(
      `/admin/jobvideolist?talent_id=${talent_id}&lastKey=${lastKey}&title=${title}&limit=25`,
      true
    );
    // dispatch(setLoading(false));
    return data;
  }
);

export const updateUserStatus = createAsyncThunk(
  "updateUserStatus",
  async (payload, { dispatch }) => {
    dispatch(setLoading(true));
    const { data } = await postApi("/admin/userstatuschange", payload, true);
    dispatch(setLoading(false));
    return data;
  }
);

export const getTalentFullDetails = createAsyncThunk(
  "getTalentFullDetails",
  async (payload, { dispatch }) => {
    dispatch(setLoading(true));
    const { data } = await getApi(
      `/admin/getFullTalentData?talent_user_id=${payload}`,
      true
    );
    dispatch(setLoading(false));
    return data;
  }
);

export const getTalentPool = createAsyncThunk(
  "getTalentPool",
  async (
    { lastKey, query = "", clientpool = "", candidate_id, job_id = "" },
    { dispatch }
  ) => {
    dispatch(setLoading(true));
    const { data } = await getApi(
      candidate_id
        ? `/admin/getpools?lastKey=${lastKey}&query=${query}&clientpool=${clientpool}&candidate_id=${candidate_id}`
        : "/admin/getpools?lastKey=" +
            lastKey +
            "&query=" +
            query +
            "&job_id=" +
            job_id,
      true
    );
    dispatch(setLoading(false));
    return data;
  }
);

export const getTalentPoolForSearch = createAsyncThunk(
  "getTalentPoolForSearch",
  async (
    { lastKey, query = "", clientpool = "", candidate_id, job_id = "" },
    { dispatch }
  ) => {
    const { data } = await getApi(
      candidate_id
        ? `/admin/getpools?lastKey=${lastKey}&query=${query}&clientpool=${clientpool}&candidate_id=${candidate_id}`
        : "/admin/getpools?lastKey=" +
            lastKey +
            "&query=" +
            query +
            "&job_id=" +
            job_id,
      true
    );
    return data;
  }
);

export const getLeftDrawerTalentPool = createAsyncThunk(
  "getLeftDrawerTalentPool",
  async () => {
    const { data } = await getApi("/admin/getpools?lastKey=0", true);
    return data;
  }
);

export const getTalentDatabases = createAsyncThunk(
  "getTalentDatabases",
  async ({ lastKey, talent_id }, { dispatch }) => {
    // dispatch(setLoading(true));
    const { data } = await getApi(
      talent_id
        ? "/admin/getEmployerlist?lastKey=" +
            lastKey +
            "&talent_id=" +
            talent_id
        : "/admin/getEmployerlist?lastKey=" + lastKey,
      true
    );
    // dispatch(setLoading(false));
    return data;
  }
);

// http://localhost:3002/admin/getpools?lastKey=&query=&clientpool=&candidate_id=32031
export const getClientPools = createAsyncThunk(
  "getClientPools",
  async ({ lastKey, query = "", clientpool = true }, { dispatch }) => {
    dispatch(setLoading(true));
    const { data } = await getApi(
      "/admin/getpools?lastKey=" +
        lastKey +
        "&query=" +
        query +
        "&clientpool=" +
        clientpool,
      true
    );
    dispatch(setLoading(false));
    return data;
  }
);

export const getLeftDrawerClientPool = createAsyncThunk(
  "getLeftDrawerClientPool",
  async ({ lastKey, query = "", clientpool = true }, { dispatch }) => {
    // dispatch(setLoading(true));
    const { data } = await getApi(
      "/admin/getpools?lastKey=0&clientpool=" + clientpool,
      true
    );
    // dispatch(setLoading(false));
    return data;
  }
);

export const removeLinkedJob = createAsyncThunk(
  "removeLinkedJob",
  async ({ pool_id, job_id }, { dispatch }) => {
    // dispatch(setLoading(true));
    const { data } = await getApi(
      "/admin/removelinkjob?pool_id=" + pool_id + "&job_id=" + job_id,
      true
    );
    // dispatch(setLoading(false));
    return data;
  }
);

export const removeLinkedCandidateFromJob = createAsyncThunk(
  "removeLinkedCandidateFromJob",
  async ({ candidate_id, job_id }, { dispatch }) => {
    // dispatch(setLoading(true));
    const { data } = await getApi(
      "admin/removeuserfromjob?job_id=" +
        job_id +
        "&candidate_id=" +
        candidate_id,
      true
    );
    // dispatch(setLoading(false));
    return data;
  }
);

export const updateCompnayTalentPool = createAsyncThunk(
  "updateCompnayTalentPool",
  async (payload, { dispatch }) => {
    dispatch(setLoading(true));
    const { data } = await postApi("/admin/removecompanypools", payload, true);
    dispatch(setLoading(false));
    return data;
  }
);

export const getCompanyPool = createAsyncThunk(
  "getCompanyPool",
  async ({ lastKey, company_id, title = "" }, { dispatch }) => {
    const { data } = await getApi(
      `/admin/companypools?lastkey=${lastKey}&title=${title}&company_id=${company_id}`,
      true
    );
    return data;
  }
);

export const getUserCompanyList = createAsyncThunk(
  "getUserCompanyList",
  async ({ lastKey, company_id, query = "" }, { dispatch }) => {
    dispatch(setLoading(true));
    const { data } = await getApi(
      `${
        company_id
          ? "/admin/usercompanies?lastKey=" +
            lastKey +
            "&company_id=" +
            company_id +
            "&query=" +
            query
          : "/admin/usercompanies?lastKey=" + lastKey + "&query=" + query
      }`,
      true
    );
    dispatch(setLoading(false));
    return data;
  }
);

export const pinACompany = createAsyncThunk(
  "pinACompany",
  async (payload, { dispatch }) => {
    dispatch(setLoading(true));
    const { data } = await postApi("/admin/pincompany", payload, true);
    dispatch(setLoading(false));
    return data;
  }
);

export const updateAlertSetting = createAsyncThunk(
  "updateAlertSetting",
  async (payload, { dispatch }) => {
    dispatch(setLoading(true));
    const { data } = await postApi("/admin/editalertsetting", payload, true);
    dispatch(setLoading(false));
    return data;
  }
);

export const updateCompanyData = createAsyncThunk(
  "updateCompanyData",
  async (payload, { dispatch }) => {
    dispatch(setLoading(true));
    const { data } = await postApi("/admin/editCompanyData", payload, true);
    dispatch(setLoading(false));
    return data;
  }
);

export const employerPersonality = createAsyncThunk(
  "employerPersonality",
  async (payload, { dispatch }) => {
    dispatch(setLoading(true));
    const { data } = await postApi("/admin/employerPersonality", payload, true);
    dispatch(setLoading(false));
    return data;
  }
);

export const getUserEmployer = createAsyncThunk(
  "getUserEmployer",
  async ({ lastKey, roleid, userId, query = "" }, { dispatch }) => {
    dispatch(setLoading(true));
    const { data } = await getApi(
      "/admin/getAllEmployers?lastKey=" +
        lastKey +
        "&role_id=" +
        roleid +
        "&employer_id=" +
        userId +
        "&query=" +
        query +
        "&limit=25",
      true
    );
    dispatch(setLoading(false));
    return data;
  }
);

export const updateJobDetail = createAsyncThunk(
  "updateJobDetail",
  async (payload, { dispatch }) => {
    dispatch(setLoading(true));
    const { data } = await postApi("/admin/editJobData", payload, true);
    dispatch(setLoading(false));
    return data;
  }
);

export const postAdminJob = createAsyncThunk(
  "postAdminJob",
  async (payload, { dispatch }) => {
    dispatch(setLoading(true));
    const { data } = await postApi("/admin/createjob", payload, true);
    dispatch(setLoading(false));
    return data;
  }
);

export const getPostAsCompany = createAsyncThunk(
  "getPostAsCompany",
  async ({ lastKey, title }, { dispatch }) => {
    // dispatch(setLoading(true));
    const { data } = await getApi(
      `/admin/getAdminAllCompany?lastKey=${lastKey}&title=${title}`,
      true
    );
    // dispatch(setLoading(false));
    return data;
  }
);

export const updateEmployerData = createAsyncThunk(
  "updateEmployerData",
  async (payload, { dispatch }) => {
    dispatch(setLoading(true));
    const { data } = await postApi("/admin/editEmployerData", payload, true);
    dispatch(setLoading(false));
    return data;
  }
);

export const getpooldetails = createAsyncThunk(
  "getPoolDetail",
  async ({ poolId }, { dispatch }) => {
    const { data } = await getApi(
      "/admin/getpooldetails?pool_id=" + poolId,
      true
    );
    return data;
  }
);

export const addTalentPool = createAsyncThunk(
  "addTalentPool",
  async (payload, { dispatch }) => {
    const { data } = await postApi("/admin/addusertopool", payload, true);
    return data;
  }
);

export const addJobToTalentPool = createAsyncThunk(
  "addTalentPool",
  async (payload, { dispatch }) => {
    const { data } = await postApi("/admin/linktojob", payload, true);
    return data;
  }
);
export const deleteCardTalentPool = createAsyncThunk(
  "deleteCardTalentPool",
  async (payload, { dispatch }) => {
    const { data } = await getApi(
      `/admin/removelinkcandidate?pool_id=${payload?.pool_id}&talent_id=${payload?.talent_id}`,
      true
    );
    return data;
  }
);

export const addTalentToJob = createAsyncThunk(
  "addTalentToJob",
  async (payload, { dispatch }) => {
    const { data } = await postApi("/admin/addtojob", payload, true);
    return data;
  }
);

export const createTalentPool = createAsyncThunk(
  "createTalentPool",
  async (payload, { dispatch }) => {
    const { data } = await postApi("/admin/createpool", payload, true);
    return data;
  }
);

export const getPoolUsers = createAsyncThunk(
  "getPoolUsers",
  async ({ lastKey, pool_id }, { dispatch }) => {
    dispatch(setLoading(true));
    const { data } = await getApi(
      "/admin/getpoolusers?lastKey=" + lastKey + "&pool_id=" + pool_id,
      true
    );
    dispatch(setLoading(false));
    return data;
  }
);

export const getApplicants = createAsyncThunk(
  "getApplicants",
  async (payload, { dispatch }) => {
    dispatch(setLoading(true));
    const { data } = await getApi(
      "/admin/getapplicantpool?lastKey=" + payload,
      true
    );
    dispatch(setLoading(false));
    return data;
  }
);

export const getFollowers = createAsyncThunk(
  "getFollowers",
  async (payload, { dispatch }) => {
    dispatch(setLoading(true));
    const { data } = await getApi(
      "/admin/getfollowerpool?lastKey=" + payload,
      true
    );
    dispatch(setLoading(false));
    return data;
  }
);

export const jobsSlice = createSlice({
  name: "config",
  initialState,
  reducers: {
    setLeftMenuButton: (state, action) => {
      state.leftMenuButton = action?.payload;
    },
    setPoolUpdate: (state, action) => {
      state.poolUpdated = action?.payload;
    },
    setCreatePool: (state, action) => {
      state.createPool = action?.payload;
    },
    setCandidateID: (state, action) => {
      state.candidateID = action?.payload;
    },
    clearTalentPool: (state, action) => {
      state.leftDrawerTalentPool = [];
    },
    clearClientPool: (state, action) => {
      state.leftDrawerClientPool = [];
    },
  },
  extraReducers(builder) {
    builder.addCase(getTalentPool.fulfilled, (state, action) => {
      state.talentpool = action?.payload;
    });
    builder.addCase(getLeftDrawerTalentPool.fulfilled, (state, action) => {
      state.leftDrawerTalentPool = action?.payload;
    });
    builder.addCase(getLeftDrawerClientPool.fulfilled, (state, action) => {
      state.leftDrawerClientPool = action?.payload;
    });
    builder.addCase(getClientPools.fulfilled, (state, action) => {
      state.clientpool = action?.payload;
    });
    builder.addCase(getTalentDatabases.fulfilled, (state, action) => {
      state.clientpool = action?.payload?.data?.map((type) => {
        return {
          ...type,
          name: `${type?.user?.first_name} ${type?.user?.last_name}`,
        };
      });
    });
  },
});
// Action creators are generated for each case reducer function
export const {
  setPoolUpdate,
  setLeftMenuButton,
  setCreatePool,
  setCandidateID,
  clearClientPool,
  clearTalentPool,
} = jobsSlice.actions;

export default jobsSlice.reducer;
