import { useTheme } from "@emotion/react";
import { Box, Button } from "@mui/material";
import React from "react";
import { getDecodedToken, getToken } from "../../../utils/Common";

const ProfileProgressButtonLayout = ({ profileCompletion }) => {
  const decodedToken = getDecodedToken(getToken());

  return (
    <Box
      sx={{
        display: "flex",
        padding: 0,
        gap: 0.2,
      }}
    >
      {decodedToken?.data?.role_id !== 5 ? (
        <>
          <Button
            variant="contained"
            color={profileCompletion >= 25 ? "progressBar" : "grayButton400"}
            sx={{
              height: "6px",
              minWidth: 10,
              padding: "0px",
              borderRadius: "5px 0 0 5px",
            }}
          ></Button>
          <Button
            variant="contained"
            color={profileCompletion >= 50 ? "progressBar" : "grayButton400"}
            sx={{
              height: "6px",
              minWidth: 10,
              padding: "0px",
              borderRadius: 0,
            }}
          ></Button>
          <Button
            variant="contained"
            color={profileCompletion >= 75 ? "progressBar" : "grayButton400"}
            sx={{
              height: "6px",
              minWidth: 10,
              borderRadius: 0,
              padding: "0px",
            }}
          ></Button>
          <Button
            variant="contained"
            color={profileCompletion >= 100 ? "progressBar" : "grayButton400"}
            sx={{
              height: "6px",
              minWidth: 10,
              padding: "0px",
              borderRadius: "0 5px 5px 0",
            }}
          ></Button>
        </>
      ) : (
        <Button
          variant="contained"
          color={profileCompletion === 25 ? "progressBar" : "grayButton400"}
          sx={{
            height: "6px",
            minWidth: 40,
            padding: "0px",
            borderRadius: "5px 0 0 5px",
          }}
        ></Button>
      )}
    </Box>
  );
};

export default ProfileProgressButtonLayout;
