import React, { useCallback, useRef, useState } from "react";
import {
  Grid,
  Box,
  Divider,
  Typography,
  InputLabel,
  Button,
  InputBase,
  Paper,
} from "@mui/material";

import { ALERT_TYPE, ERROR_MSG, MEMBER_INFO } from "../../../utils/Constants";
import InputBox from "../../common/InputBox";
import CustomDialog from "../../common/CustomDialog";
import { IconButton } from "@mui/material";

import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { useLocation, useNavigate } from "react-router-dom";
import SelectMenu from "../../common/SelectMenu";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import {
  getPermissions,
  addNewMember,
  getEmailData,
} from "../../../redux/employer/myTeams";
import { setAlert } from "../../../redux/configSlice";
import { useSelector } from "react-redux";
import PhoneInput, { formatPhoneNumberIntl } from "react-phone-number-input";
import { useTheme } from "@emotion/react";
import locale from "../../../i18n/locale";
import {
  debounce,
  getContactDetail,
  getDecodedToken,
  getToken,
  getUserCountryFromGeolocation,
  validateSchema,
} from "../../../utils/Common";
import { isValidPhoneNumber } from "react-phone-number-input";
import { CheckCircle } from "@mui/icons-material";
import { getCompanies } from "../../../redux/employer/empProfileSlice";
import CustomSelect from "../../candidate/myCV/CustomSelect";
import * as Yup from "yup";
import NotApproved from "../../common/myProfileSection/NotApproved";
import MyTeamErrorBox from "../../common/MyTeamErrorBox";

const AddNewMember = () => {
  const i18n = locale.en;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();
  const decodedToken = getDecodedToken(getToken());

  const companyName = location?.state?.companyname;
  const roleTypeID = useRef();
  const { companies } = useSelector((state) => state?.myProfile);
  const { permission } = useSelector((state) => state?.configMyTeams);
  const [invitSent, setInviteSent] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showBox, setShowBox] = useState(false);
  const [errorMessage, setErrorMessage] = useState();
  // const [companies, setCompanies] = useState([]);
  const [isCompanyFieldDisable, setIsCompanyFieldDisable] = useState(false);
  const [memberData, setMemberData] = useState(MEMBER_INFO);
  const [newPassword, setNewPassword] = useState("");
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [errors, setErrors] = useState({});
  const [initialCountryCode, setInitialCountryCode] = useState("");
  const [isUserExist, setIsUserExist] = useState(false);
  const [doValidation, setDoValidation] = useState(false);
  const [inValidContacts, setInvalidContacts] = useState([
    {
      referenceID: null,
      contactTouched: false,
      contactValid: false,
    },
  ]);
  const formValidationSchema = Yup.object().shape({
    role_type_id: Yup.string().required("Permission type is required"),
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    // company_id: Yup.string().required("Company is required"),
    first_name: Yup.string()
      // .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field ")
      .required("First name is required"),
    last_name: Yup.string()
      // .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field ")
      .required("Last name is required"),
    contact: Yup.string()
      .test("isValidPhoneNumber", "Phone number is not valid", (value) =>
        isValidPhoneNumber(value || "")
      )
      .required("Contact number is required"),
    // password: Yup.string()
    //   .min(1, "Password must be at least 8 characters")
    //   .required("Password is required"),
  });

  const formSchema = Yup.object().shape({
    workData: Yup.object().when("noCheck", {
      is: true,
      then: (schema) => schema.shape(formValidationSchema.fields),
      otherwise: (schema) => schema.optional(),
    }),
    noCheck: Yup.bool(),
  });

  const handleShowPassword = () => {
    setShowPassword((prevState) => !prevState);
  };

  const handleCancelButton = () => {
    navigate(
      decodedToken?.data?.role_id === 6
        ? "/recruiter/my-team"
        : "/employer/my-team"
    );
  };

  const getAllData = async () => {
    try {
      let payload = {
        limit: 5,
        title: companyName || "",
      };
      await dispatch(getPermissions());
      const company = await dispatch(getCompanies(payload));
      if (company?.payload?.data?.data?.length > 0) {
        let companyID = company?.payload?.data?.data?.find(
          (item) => item?.name === companyName
        )?.company_id;
        setMemberData({
          ...MEMBER_INFO,
          company_id: companyID ? companyID : companyName,
          company_name: companyName,
        });
      }
    } catch (error) {
      dispatch(
        setAlert({
          show: true,
          type: ALERT_TYPE?.ERROR,
          msg: ERROR_MSG,
        })
      );
    }
  };

  const handleChange = (event) => {
    const {
      target: { value },
      target: { name },
    } = event;

    const newmemberData = {
      ...memberData,
      [name]: value,
    };
    roleTypeID.current = value;
    if (memberData?.email !== "") {
      debouncedHandleOnSelectInputChange(memberData?.email, newmemberData);
    }
    setMemberData(newmemberData);
    // role_type_id
    const filteredErrors = { ...errors };
    delete filteredErrors[`workData.${name}`];
    setErrors({ ...filteredErrors });
  };

  const handleInputChange = (event) => {
    let newmemberData;
    if (event?.target?.id === "contact") {
      newmemberData = {
        ...memberData,
        [event.target.id]: event?.target?.value,
      };
    } else {
      newmemberData = {
        ...memberData,
        [event.target.id]: event?.target?.value,
      };
    }
    setMemberData(newmemberData);
    // const filteredErrors = errors?.filter(
    //   (item) => item?.key != event?.target?.name
    // );
    const filteredErrors = { ...errors };
    delete filteredErrors[`workData.${event?.target?.id}`];
    setErrors({ ...filteredErrors });
  };

  const debouncedHandleOnSelectInputChange = useCallback(
    debounce(
      async (newValue, data) => {
        let params = {
          title: newValue || "",
        };
        if (newValue) {
          try {
            let newmemberData;
            const { payload } = await dispatch(
              getEmailData({
                email: newValue,
                roletypeid: roleTypeID.current,
              })
            );
            if (payload?.status === "success") {
              if (payload?.data === null && payload?.company === null) {
                setDoValidation(true);
              }
              if (payload?.data === null) {
                setIsUserExist(false);
              } else {
                setIsUserExist(true);
              }
              newmemberData = {
                ...data,
                first_name:
                  payload?.data !== null ? payload?.data?.first_name : "",
                last_name:
                  payload?.data !== null ? payload?.data?.last_name : "",
                contact:
                  payload?.data !== null && payload?.data?.employer_profile
                    ? payload?.data?.employer_profile?.contact_no?.includes("+")
                      ? payload?.data?.employer_profile?.contact_no
                      : `${payload?.data?.employer_profile?.country_code}${payload?.data?.employer_profile?.contact_no}`
                    : initialCountryCode?.toUpperCase(),
                country_code:
                  payload?.data !== null && payload?.data?.employer_profile
                    ? payload?.data?.employer_profile?.country_code
                    : "",
                company_id:
                  payload?.company !== null ? payload?.company?.company_id : "",
                company_name:
                  payload?.company !== null ? payload?.company?.name : "",
              };
              setMemberData(newmemberData);
              setIsCompanyFieldDisable(payload?.companyDesableFlag);
            } else if (payload?.status === "fail") {
              setDoValidation(true);
              setIsUserExist(false);
              setShowBox(true);
              setErrorMessage(payload?.message);
            } else {
              setShowBox(false);
              dispatch(
                setAlert({
                  show: true,
                  type: ALERT_TYPE?.ERROR,
                  msg: "Error",
                })
              );
            }
          } catch (error) {
            setShowBox(false);
            dispatch(
              setAlert({
                show: true,
                type: ALERT_TYPE?.ERROR,
                msg: ERROR_MSG,
              })
            );
          }
        }
      },
      1500,
      setIsLoading
    ),
    []
  );

  const handleEmail = async (emailValue, emailID) => {
    const newmemberData = {
      ...MEMBER_INFO,
      email: emailValue,
      company_id: memberData?.company_id,
      company_name: memberData?.company_name,
      role_type_id: roleTypeID.current,
    };
    setEmail(emailValue);
    setMemberData(newmemberData);
    const filteredErrors = { ...errors };
    delete filteredErrors[`workData.${emailID}`];
    setErrors({ ...filteredErrors });
    debouncedHandleOnSelectInputChange(emailValue, newmemberData);
  };

  const handleContactChange = (value) => {
    const newmemberData = {
      ...memberData,
      contact: value,
    };
    const filteredErrors = { ...errors };
    delete filteredErrors["workData.contact"];
    setErrors({ ...filteredErrors });
    setMemberData(newmemberData);
  };

  const saveNewMember = async () => {
    validateSchema(formSchema, { workData: memberData, noCheck: doValidation })
      .then(() => {
        try {
          const contactInfo = getContactDetail(
            formatPhoneNumberIntl(memberData?.contact)
          );
          delete memberData["company_name"];
          const updatedMemberData = {
            ...memberData,
            contact: contactInfo?.[1],
            country_code: contactInfo?.[0],
          };
          dispatch(addNewMember(updatedMemberData)).then((payload) => {
            if (payload?.payload?.status === "success") {
              setMemberData({
                ...memberData,
                company_name: payload?.payload?.data?.company_name,
                company_id: payload?.payload?.data?.company_id,
              });
              setInviteSent((prevState) => !prevState);
              dispatch(
                setAlert({
                  show: true,
                  type: ALERT_TYPE?.SUCCESS,
                  msg: "Member added successfully",
                })
              );
            } else {
              dispatch(
                setAlert({
                  show: true,
                  type: ALERT_TYPE?.ERROR,
                  msg: payload?.payload?.message,
                })
              );
            }
          });
        } catch (error) {
          dispatch(
            setAlert({
              show: true,
              type: ALERT_TYPE?.ERROR,
              msg: "Something went wrong",
            })
          );
        }
      })
      .catch((error) => {
        setErrors(error);
      });
    // try {
    //   if (memberData?.company_id === "") {
    //   }
    //   delete memberData["company_name"];
    //   const { payload } = await dispatch(addNewMember(memberData));
    //   if (payload.status === "success") {
    //     setMemberData({
    //       ...memberData,
    //       company_name: payload?.data?.company_name,
    //       company_id: payload?.data?.company_id,
    //     });
    //     setInviteSent((prevState) => !prevState);
    //     dispatch(
    //       setAlert({
    //         show: true,
    //         type: ALERT_TYPE?.SUCCESS,
    //         msg: "Member added successfully",
    //       })
    //     );
    //   } else {
    //     dispatch(
    //       setAlert({
    //         show: true,
    //         type: ALERT_TYPE?.ERROR,
    //         msg: payload.message,
    //       })
    //     );
    //   }
    // } catch (error) {
    //   dispatch(
    //     setAlert({
    //       show: true,
    //       type: ALERT_TYPE?.ERROR,
    //       msg: "Entered compnay is not verfied",
    //     })
    //   );
    // }
  };

  const handlePassword = (event) => {
    setNewPassword(event?.target?.value);
    const newmemberData = {
      ...memberData,
      [event.target.id]: event?.target?.value,
    };
    const filteredErrors = { ...errors };
    delete filteredErrors["workData.password"];
    setErrors({ ...filteredErrors });
    setMemberData(newmemberData);
  };

  const handleAddAnotherUser = () => {
    setEmail("");
    setIsUserExist(false);
    setDoValidation(false);
    setMemberData(MEMBER_INFO);
    setInviteSent(false);
    navigate(
      decodedToken?.data?.role_id === 6
        ? "/recruiter/my-team/add-new-member"
        : "/employer/my-team/add-new-member"
    );
  };

  useEffect(() => {
    getAllData();
  }, []);

  useEffect(() => {
    getUserCountryFromGeolocation()
      .then((countryCode) => {
        setInitialCountryCode(countryCode);
      })
      .catch((error) => {
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE?.ERROR,
            msg: "Error fetching country code",
          })
        );
      });
  }, []);

  const onMenuOpen = () => {
    setIsMenuOpen(true);
  };
  const onMenuClose = () => {
    setIsMenuOpen(false);
  };

  const onCreateOption = async (data, inputId, index) => {
    if (typeof data === "string") {
      let newmemberData = {
        ...memberData,
        company_id: data,
        company_name: data,
      };
      setMemberData(newmemberData);
    }
  };

  const handleOnSelectChange = async (ind, newValue, val, index) => {
    let newmemberData = {};
    newmemberData = {
      ...memberData,
      company_id: newValue?.value,
      company_name: newValue?.label,
    };
    const filteredErrors = { ...errors };
    delete filteredErrors["workData.company_id"];
    setErrors({ ...filteredErrors });
    setMemberData(newmemberData);
  };

  const handleOnSelectInputChange = (newValue, inputId) => {
    debouncedHandleOnSelectCompanyChange(newValue, inputId);
  };

  const debouncedHandleOnSelectCompanyChange = useCallback(
    debounce(
      async (newValue, type) => {
        let params = {
          title: newValue || "",
          limit: 5,
        };
        if (newValue) {
          try {
            if (type === "company_id") {
              dispatch(getCompanies(params));
            }
          } catch (error) {
            dispatch(
              setAlert({
                show: true,
                type: ALERT_TYPE?.SUCCESS,
                msg: error,
              })
            );
          }
        }
      },
      750,
      setIsLoading
    ),
    []
  );

  return (
    <>
      {showBox && (
        <CustomDialog
          show={true}
          hideButton={false}
          dialogWidth="xs"
          showFooter={false}
          padding={0}
        >
          <MyTeamErrorBox
            setShowBox={setShowBox}
            errorMessage={errorMessage}
            handleAddAnotherUser={handleAddAnotherUser}
          />
        </CustomDialog>
      )}
      <Box
        sx={{
          boxShadow: 2,
          borderRadius: "15px",
          backgroundColor: "#ffff",
          // margin: "55px 24px 0 24px",
          display: "flex",
          flexGrow: 1,
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <Box
          className="HeaderSection"
          sx={{
            p: "24px 54px 24px 54px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              gap: 2,
              alignItems: "center",
            }}
          >
            <Typography
              sx={{
                fontSize: "20px",
                fontWeight: 700,
              }}
            >
              {i18n["empMyTeam.addNewUser"]}
            </Typography>
          </Box>
          <Box>
            <Typography variant="p">
              {i18n["empMyTeam.addTeamMember"]}
            </Typography>
          </Box>
        </Box>
        <Divider />
        <Grid
          container
          rowSpacing={2}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          sx={{
            padding: "24px 54px 24px 54px",
          }}
        >
          <Grid item xs={12} md={6}>
            <InputLabel
              htmlFor="role_type_id"
              sx={{
                color: "black",

                paddingBottom: "2px",
                fontSize: "14px",
                fontWeight: 700,
              }}
            >
              {i18n["empMyTeam.permissionType"]}
            </InputLabel>
            <SelectMenu
              name="role_type_id"
              value={memberData?.role_type_id}
              onHandleChange={handleChange}
              options={
                decodedToken?.data?.role_id === 6
                  ? permission?.filter((item) => item?.role_type_id === 3)
                  : permission || []
              }
              sx={{
                width: "100%",
                border: errors?.["workData.role_type_id"]
                  ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
                  : `1px solid ${theme.palette.grayBorder}`,
              }}
              placeholder={"Select permission for new user"}
            />
          </Grid>
          <Grid item xs={6}></Grid>
          <Grid item xs={12} md={6}>
            <InputLabel
              htmlFor="email"
              sx={{
                color: "black",

                paddingBottom: "2px",
                fontSize: "14px",
                fontWeight: 700,
              }}
            >
              {i18n["empMyTeam.email"]}
            </InputLabel>

            <InputBox
              value={email}
              id="email"
              sx={{
                border: errors?.["workData.email"]
                  ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
                  : `1px solid ${theme.palette.grayBorder}`,
              }}
              // onChange={handleInputChange}
              onChange={(event) => handleEmail(event?.target?.value, "email")}
              placeholder="Enter the new user's email address"
            ></InputBox>
          </Grid>
          <Grid item xs={12} md={6}>
            <InputLabel
              htmlFor="company_id"
              sx={{
                color: "black",

                paddingBottom: "2px",
                fontSize: "14px",
                fontWeight: 700,
              }}
            >
              {i18n["empMyTeam.company"]}
            </InputLabel>
            <CustomSelect
              isDisabled={isCompanyFieldDisable}
              variant="createSelect"
              onInputChange={handleOnSelectInputChange}
              onChange={handleOnSelectChange}
              onCreateOption={onCreateOption}
              placeholder={i18n["empMyProfile.companyNamePlace"]}
              inputId="company_id"
              name="company_id"
              value={
                memberData?.company_id && memberData?.company_name
                  ? {
                      value: memberData?.company_id
                        ? memberData?.company_id
                        : "",
                      label: memberData?.company_name
                        ? memberData?.company_name
                        : "",
                    }
                  : {
                      value: "",
                      label: "",
                    }
              }
              onMenuOpen={onMenuOpen}
              onMenuClose={onMenuClose}
              option={companies?.map((item) => ({
                value: item?.company_id,
                label: item?.name,
              }))}
              isLoading={isLoading}
              customStyle={{
                menu: (provided) => ({
                  ...provided,
                  zIndex: 9999,
                }),
                menuList: (provided) => ({
                  ...provided,
                  "&::-webkit-scrollbar": {
                    width: "6px",
                    height: "6px",
                    borderRadius: "2.5px",
                    backgroundColor: "#efefef",
                  },
                  "&::-webkit-scrollbar-thumb": {
                    borderRadius: "2.5px",
                  },
                }),
                option: (
                  styles,
                  { data, isDisabled, isFocused, isSelected }
                ) => {
                  return {
                    ...styles,
                    color: isSelected ? "#fff" : "black",
                    ":active": {
                      ...styles[":active"],
                    },
                  };
                },
                control: (baseStyles) => ({
                  ...baseStyles,
                  boxShadow: "none",
                  borderRadius: "25px",
                  height: `40px`,

                  border: errors?.["workData.company_id"]
                    ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
                    : `1px solid ${theme.palette.grayBorder}`,
                  // "&:hover": "1px solid #E0E0E0",
                }),
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <InputLabel
              htmlFor="first_name"
              sx={{
                color: "black",

                paddingBottom: "2px",
                fontSize: "14px",
                fontWeight: 700,
              }}
            >
              {i18n["empMyTeam.firstName"]}
            </InputLabel>
            <InputBox
              readOnly={isUserExist}
              id="first_name"
              value={
                memberData?.first_name !== "" ? memberData?.first_name : ""
              }
              onChange={handleInputChange}
              sx={{
                border: errors?.["workData.first_name"]
                  ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
                  : `1px solid ${theme.palette.grayBorder}`,
              }}
              placeholder="Enter their first name"
            ></InputBox>
          </Grid>
          <Grid item xs={12} md={6}>
            <InputLabel
              htmlFor="last_name"
              sx={{
                color: "black",

                paddingBottom: "2px",
                fontSize: "14px",
                fontWeight: 700,
              }}
            >
              {i18n["empMyTeam.surname"]}
            </InputLabel>
            <InputBox
              readOnly={isUserExist}
              value={memberData?.last_name !== "" ? memberData?.last_name : ""}
              id="last_name"
              sx={{
                border: errors?.["workData.last_name"]
                  ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
                  : `1px solid ${theme.palette.grayBorder}`,
              }}
              onChange={handleInputChange}
              placeholder="Enter their surname"
            ></InputBox>
          </Grid>
          <Grid item xs={12} md={6}>
            <InputLabel
              htmlFor="contact"
              sx={{
                color: "black",

                paddingBottom: "2px",
                fontSize: "14px",
                fontWeight: 700,
              }}
            >
              {i18n["empMyTeam.phoneNumber"]}
            </InputLabel>
            <Paper
              elevation={3}
              sx={{
                display: "flex",
                borderRadius: "25px",
                height: "40px !important",
                boxShadow: "none",
                padding: "0 10px",
                // border: `1px solid ${theme?.palette?.grayBorder}`,
                border: errors?.["workData.contact"]
                  ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
                  : `1px solid ${theme.palette.grayBorder}`,
              }}
            >
              <PhoneInput
                readOnly={isUserExist}
                international
                id="contact"
                autoComplete="off"
                placeholder="Enter their contact number"
                value={memberData?.contact && memberData?.contact}
                onChange={handleContactChange}
                defaultCountry={initialCountryCode?.toUpperCase()}
                focusInputOnCountrySelection={true}
                onCountryChange={(value) => {
                  setInitialCountryCode(value);
                }}
                style={{
                  font: "inherit",
                  padding: "10px",
                  // border: errors?.["contact"]
                  // ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
                  // : `1px solid ${theme.palette.grayBorder}`
                }}
              />
              {isValidPhoneNumber(memberData?.contact || "") && (
                <Typography
                  sx={{
                    color: theme?.palette?.tealColor?.main,
                    fontSize: "14px",
                    alignItems: "center",
                    display: "flex",
                    gap: "5px",
                    marginRight: "5px",
                    justifyContent: "end",
                  }}
                >
                  Correct <CheckCircle fontSize="small" />
                </Typography>
              )}
            </Paper>
          </Grid>
          {/*!isUserExist && (
            <Grid item xs={12} md={6}>
              <InputLabel
                htmlFor="password"
                sx={{
                  color: "black",

                  paddingBottom: "2px",
                  fontSize: "14px",
                  fontWeight: 700,
                }}
              >
                {i18n["empMyTeam.tempPassword"]}
              </InputLabel>
              <Box
                sx={{
                  display: "flex",
                  height: "40px",
                  borderRadius: "25px",
                  boxShadow: "none",
                  justifyContent: "space-between",
                  padding: "0 10px",
                  // border: `1px solid lightGray`,
                  border: errors?.["workData.password"]
                    ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
                    : `1px solid ${theme.palette.grayBorder}`,
                }}
              >
                <InputBase
                  sx={{
                    flexGrow: 1,
                  }}
                  // type={`${showPassword?"text":"password"}`}
                  autoComplete="off"
                  id="password"
                  value={newPassword}
                  type={showPassword ? "text" : "password"} // Due to this field are pre populating
                  placeholder="Enter a temporary password"
                  onChange={handlePassword}
                ></InputBase>
                <IconButton
                  sx={{ py: 0 }}
                  color=""
                  aria-label="reset password"
                  component="button"
                  onClick={handleShowPassword}
                >
                  {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                </IconButton>
              </Box>
            </Grid>
          )*/}
        </Grid>
        <Box
          sx={{
            margin: "auto",
          }}
        >
          <Button
            variant="contained"
            sx={{
              borderRadius: "20px 0 0 0",
              backgroundColor: "lightgray",
              width: "11rem",
              padding: "25px 40px",
              color: "black",
              ":hover": {
                backgroundColor: "lightgray",
              },
            }}
            onClick={handleCancelButton}
          >
            {i18n["empMyTeam.cancel"]}
          </Button>
          <Button
            variant="contained"
            backgroundColor={"redButton"}
            sx={{
              borderRadius: "0 20px 0 0",
              width: "11rem",
              padding: "25px 40px",
            }}
            color="redButton100"
            onClick={saveNewMember}
            // onClick={() => addMember && saveNewMember()}
          >
            {i18n["empMyTeam.sendInvite"]}
          </Button>
          <CustomDialog
            show={invitSent}
            hideButton={false}
            dialogWidth="xs"
            showFooter={false}
            onDialogClose={handleCancelButton}
            padding={0}
          >
            <Box
              sx={{
                padding: 4,
              }}
            >
              <Typography
                variant="h5"
                fontWeight={"Bold"}
                textAlign={"center"}
                sx={{
                  marginBottom: 3,
                }}
              >
                {i18n["empMyTeam.inviteSent"]}
              </Typography>
              <Typography
                fontSize={16}
                fontWeight={"bold"}
                textAlign={"center"}
                paragraph
              >
                {i18n["empMyTeam.joinViaEmail"]}
              </Typography>
            </Box>
            <Box>
              <Button
                sx={{
                  boxShadow: 0,
                  fontSize: "14px",
                  width: "50%",
                  borderRadius: 0,
                  height: "43px",
                  color: "black",
                  padding: 3,
                }}
                color="lightGray"
                variant="contained"
                onClick={handleCancelButton}
              >
                {i18n["empMyTeam.teamStatus"]}
              </Button>

              <Button
                sx={{
                  boxShadow: 0,
                  fontSize: "14px",
                  width: "50%",
                  borderRadius: 0,
                  height: "43px",
                  padding: 3,
                }}
                variant="contained"
                color="redButton100"
                onClick={handleAddAnotherUser}
              >
                {i18n["empMyTeam.addUser"]}
              </Button>
            </Box>
          </CustomDialog>
        </Box>
      </Box>
    </>
  );
};

export default AddNewMember;
