import {
  Box,
  IconButton,
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import locale from "../../i18n/locale";
import { dateConverterMonth } from "../../utils/DateTime";
import { useDispatch } from "react-redux";
import { getHistoryLogs } from "../../redux/admin/jobsSlice";
import { setAlert } from "../../redux/configSlice";
import { ALERT_TYPE, TEMPHISTORY } from "../../utils/Constants";
import InfiniteScroll from "react-infinite-scroll-component";

export default function HistoryBox({
  handleOpen,
  handleClose,
  openFor,
  email,
  component,
  jobDetail
}) {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    height: "800px",
    width: "1000px",
    bgcolor: "background.paper",
    borderRadius: "25px",
    py: 4,
    boxShadow: "none !important",
    // border: "1px solid black !important",
  };

  const i18n = locale.en;
  const dispatch = useDispatch();

  const [history, setHistory] = useState([]);
  const [lastKey, setLastKey] = useState(0);

  const getHistoryList = async (lastKeyy) => {
    const data = {
      email: email,
      lastKey: lastKeyy,
    };
  if(component){
    data["job_id"]=jobDetail?.job_id
  }
    const { payload } = await  dispatch(getHistoryLogs(data));
    if (payload?.status === "success") {
      setHistory((prev) => [...prev, ...payload?.data]);
      setLastKey(payload?.pageNumber + 1);
    } else if (payload === undefined) {
      return;
    } else {
      dispatch(
        setAlert({
          show: true,
          type: ALERT_TYPE?.ERROR,
          msg: "Something went wrong! please relaod the window",
        })
      );
    }
  };

  useEffect(() => {
    getHistoryList(0);
  }, []);

  return (
    <Modal
      open={handleOpen}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <IconButton
          aria-label="close"
          onClick={() => {
            handleClose();
          }}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
          disableRipple={true}
        >
          <Box
            sx={{
              width: "20px",
              height: "20px",
              color: "#000000",
              border: 2,
              fontSize: "18px",
              borderRadius: "5px",
            }}
          >
            X
          </Box>
        </IconButton>
        <Typography
          id="modal-modal-title"
          variant="h6"
          component="h2"
          textAlign={"center"}
          sx={{ marginBottom: "10px" }}
        >
          {i18n["popupBoxText.historyLogs"]}
        </Typography>
        <Box
          id="historyBox"
          sx={{ height: `calc(100% - 32px)`, overflow: "auto" }}
        >
          <InfiniteScroll
            style={{ overflow: "hidden" }}
            dataLength={history?.length}
            next={() => getHistoryList(lastKey)}
            scrollThreshold={"100px"}
            scrollableTarget={"historyBox"}
            hasMore={true}
            endMessage={
              <p style={{ textAlign: "center" }}>
                <b>Yay! You have seen it all</b>
              </p>
            }
          >
            <Box
              sx={{
                mt: 2,
              }}
            >
              <TableContainer
                component={Paper}
                sx={{
                  boxShadow: "none !important",
                }}
              >
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ textAlign: "center" }}>
                        <Typography variant="subtitle1" fontWeight="bold">
                          Id
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="subtitle1" fontWeight="bold">
                          To
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="subtitle1" fontWeight="bold">
                          From
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="subtitle1" fontWeight="bold">
                          Subject
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="subtitle1" fontWeight="bold">
                          Date
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {history?.map((row) => (
                      <TableRow
                        key={row?.email_log_id}
                        style={{
                          "& .css-12zgwp-MuiTableCell-root": {
                            padding: "0 16px !important",
                          },
                        }}
                      >
                        <TableCell sx={{ textAlign: "center" }}>
                          {row?.email_log_id}
                        </TableCell>
                        <TableCell>{row?.to}</TableCell>
                        <TableCell>{row?.from}</TableCell>
                        <TableCell sx={{ width: "340px" }}>
                          {row?.log_record}
                        </TableCell>
                        <TableCell>{dateConverterMonth(row?.date)}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </InfiniteScroll>
          {history?.length === 0 && (
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <Typography>No logs</Typography>
            </Box>
          )}
        </Box>
      </Box>
    </Modal>
  );
}
