import {
  Avatar,
  Box,
  Button,
  InputBase,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import CardImage from "../../assets/PaymentCard.svg";
import PaymentBot from "../../assets/Characters/CrayBotIcons/Crayon_paymentPage.svg";
import PaymentModal from "./PaymentModal";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { StyledButtonLeft } from "../../utils/CommonComponent";
import useViewport from "../common/ViewPort";
import LeftArrow from "../common/LeftArrow";
import RightArrow from "../common/RightArrow";
import { useDispatch } from "react-redux";
import { getPaymentdetail } from "../../redux/login/loginSlice";

const PaymentPage = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const params = useParams();
  const location = useLocation();
  const dispatch = useDispatch();
  const { width } = useViewport();
  const [leftExpanded, setLeftExpand] = useState(true);
  const [activeStep, setActiveStep] = useState(1);
  const gridRef = useRef();
  const [newPadding, setNewPadding] = useState(30);
  const [paymentData, setPaymentData] = useState();
  const [formData, setFormData] = useState({});
  const [disabled, setDisabled] = useState(true);
  const [isShowPaymentModal, setIsShowPaymentModal] = useState(true);
  const handleInputChange = (key, value) => {
    let updatedState = { ...formData };
    updatedState[key] = value;
    setFormData({ ...updatedState });
  };

  const fectDetails = async (param) => {
    try {
      let response = await dispatch(getPaymentdetail({ param }));
      if (response?.payload?.data) {
        setPaymentData(response?.payload?.data);
      }
    } catch (error) {}
  };
  useEffect(() => {
    if (params?.paymentId) {
      fectDetails(params?.paymentId);
    }
  }, [params]);
  const handleReviewLater = () => {
    setIsShowPaymentModal(false);
  };

  const handleSkip = () => {
    navigate("/");
  };
  return (
    <Box
      sx={{
        display: "flex",
        height: {
          xs: `calc(100vh - 95px)`,
          sm: `calc(100vh - 120px )`,
          md: `calc(100vh - 120px)`,
          lg: `calc(100vh - 135px)`,
          xl: `calc(100vh - 155px)`,
          xxl: "calc(100vh - 175px)",
        },
      }}
    >
      <Box
        item
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: `${width >= 768 && "10px"}`,
          position: `${width < 768 ? "absolute" : "relative"}`,
          // width: leftExpanded
          //   ? { xs: "150px", mdl: "123px", mlg: "150px", xl: "150px" }
          //   : "30px",
          width: leftExpanded ? "125px" : "30px",
          minWidth: leftExpanded ? "125px" : "30px",
          // width: leftExpanded && "150px",
          zIndex: "30",
        }}
      >
        <Button
          variant="contained"
          sx={{
            position: "static",
            borderRadius: "0 10px 10px 0",
            minWidth: "30px",
            width: "30px",
            height: "45px",
            boxShadow: "0px 5px 10px #00000029",
          }}
          color="filterButtons"
          onClick={() => {
            setLeftExpand((prevState) => !prevState);
          }}
        >
          {leftExpanded ? <LeftArrow /> : <RightArrow />}
        </Button>

        {leftExpanded && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              height: {
                xs: `calc(100vh - 95px)`,
                sm: `calc(100vh - 120px )`,
                md: `calc(100vh - 120px)`,
                lg: `calc(100vh - 110px)`,
                xl: `calc(100vh - 110px)`,
                xxl: "calc(100vh - 110px)",
              },
              width: `${width < 768 && width !== 0 && "135px"}`,
              paddingTop: `${width < 768 && width !== 0 && "10px"}`,
              background: `${width > 0 && width < 768 && "#EBECF3"}`,
              boxShadow: `${
                width > 0 && width < 768 && "0px 5px 10px #00000029"
              }`,
              opacity: `${width < 768 && width !== 0 && "1"}`,
              borderRadius: `${width < 768 && width !== 0 && "10px"}`,
              overflowX: "hidden",
              overflowY: leftExpanded ? "scroll" : "unset",
            }}
          >
            <StyledButtonLeft
              onClick={() => setActiveStep(1)}
              variant={"contained"}
              color={activeStep === 1 ? "filterButtons" : "buttonColor"}
              sx={{
                // width: leftExpanded
                //   ? { xs: "150px", lg: "150px", mlg: "150px", xl: "150px" }
                //   : "30px",
                width: leftExpanded ? "125px" : "30px",
                minWidth: leftExpanded ? "125px" : "30px",
                borderRadius: "0 10px 10px 0",
                padding: "6px 6px",
                border: 0,
                fontWeight:
                  activeStep === 1
                    ? theme.typography.Bold
                    : theme.typography.Regular,
                "&:hover": {
                  backgroundColor: theme?.palette?.filterButtons?.main,
                  color: theme?.palette?.filterButtons?.contrastText,
                  fontWeight: theme.typography.Bold,
                },
              }}
            >
              {"Payment gateway"}
            </StyledButtonLeft>
            <StyledButtonLeft
              onClick={() => setActiveStep(2)}
              variant={"contained"}
              color={activeStep === 2 ? "filterButtons" : "buttonColor"}
              sx={{
                borderRadius: "0 10px 10px 0",
                border: 0,
                // width: leftExpanded
                //   ? { xs: "150px", lg: "150px", mlg: "150px", xl: "150px" }
                //   : "30px",
                width: leftExpanded ? "125px" : "30px",
                minWidth: leftExpanded ? "125px" : "30px",
                fontWeight:
                  activeStep === 2
                    ? theme.typography.Bold
                    : theme.typography.Regular,
                "&:hover": {
                  backgroundColor: theme?.palette?.filterButtons?.main,
                  color: theme?.palette?.filterButtons?.contrastText,
                  fontWeight: theme.typography.Bold,
                },
              }}
            >
              {"billing info"}
            </StyledButtonLeft>
          </Box>
        )}
      </Box>
      <Box
        item
        // xs={12}
        // sm={6}
        // md={8}
        // lg={9}
        // xl={10}
        justifyContent={width < 768 && width !== 0 && "center"}
        sx={{
          display: "flex",
          flexGrow: 1,
          alignItems: "center",
          padding:
            activeStep !== 3 &&
            (width < 768 && width !== 0 ? "10px" : `0 30px`),
          flexDirection: "column",
          width: {
            xs: `95%`,
            md24: `95%`,
          },
          height: "auto",
          // minWidth: "360px",
          // width: `calc(100vh - 255px)`,
          position: "sticky",
        }}
        ref={gridRef}
        // gap={"54px"}
        // flexGrow="1 !important"
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            alignItems: "center",
            // height: { xs: "auto", md: "calc(100% - 10%)" },
            height: { xs: "100%" },
            marginTop: width <= 768 || width !== 0 ? "55px" : "55px",
            width: "100%",
            background: "#ffffff",
            boxShadow: `0px 5px 10px ${theme?.palette?.boxShadow?.main}`,
            borderRadius: "25px",
          }}
        >
          <Box>
            <Box
              sx={{
                marginTop: "20px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                textAlign: "center",
              }}
            >
              <Avatar src={PaymentBot} sx={{ width: 150, height: 125 }} />
              <Typography
                sx={{
                  fontSize: "20px",
                  fontWeight: theme.typography.Bold,
                  marginBottom: "20px",
                  marginTop: "20px",
                }}
              >
                Process payment
              </Typography>
              <Typography
                sx={{
                  fontSize: "16px",
                  fontWeight: theme.typography.Bold,
                  marginBottom: "20px",
                }}
              >
                Check it out!
              </Typography>
              <Typography
                sx={{
                  fontSize: "14px",
                  fontWeight: theme.typography.Regular,
                }}
              >
                Crayon has partnered with{" "}
                <span style={{ fontWeight: "700", fontSize: "14px" }}>
                  Peach Payments
                </span>{" "}
                to make your payment quick, easy and secure!
              </Typography>
            </Box>
            <Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  marginTop: "20px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    gap: "30px",
                    alignItems: "center",
                    marginBottom: "20px",
                  }}
                >
                  <Box
                    component={"img"}
                    src={CardImage}
                    sx={{ width: 105, height: 75, objectFit: "contain" }}
                  ></Box>
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <Box sx={{ display: "flex" }}>
                      <Box
                        sx={{
                          width: "200px",
                          fontSize: "14px",
                          fontWeight: theme.typography.Bold,
                        }}
                      >
                        Item:
                      </Box>
                      <Box
                        sx={{
                          fontSize: "14px",
                          fontWeight: theme.typography.Bold,
                        }}
                      >
                        {paymentData?.item}
                      </Box>
                    </Box>
                    <Box sx={{ display: "flex" }}>
                      <Box
                        sx={{
                          width: "200px",
                          fontSize: "14px",
                          fontWeight: theme.typography.Bold,
                        }}
                      >
                        Currency:
                      </Box>
                      <Box
                        sx={{
                          fontSize: "14px",
                          fontWeight: theme.typography.Bold,
                        }}
                      >
                        {paymentData?.currency}
                      </Box>
                    </Box>
                    <Box sx={{ display: "flex" }}>
                      <Box
                        sx={{
                          width: "200px",
                          fontSize: "14px",
                          fontWeight: theme.typography.Bold,
                        }}
                      >
                        Amount:
                      </Box>
                      <Box
                        sx={{
                          fontSize: "14px",
                          fontWeight: theme.typography.Bold,
                        }}
                      >
                        {`R${paymentData?.amount} +VAT`}
                      </Box>
                    </Box>
                    <Box sx={{ display: "flex" }}>
                      <Box
                        sx={{
                          width: "200px",
                          fontSize: "14px",
                          fontWeight: theme.typography.Bold,
                        }}
                      >
                        Total payable:
                      </Box>
                      <Box
                        sx={{
                          fontSize: "14px",
                          fontWeight: theme.typography.Bold,
                        }}
                      >
                        R{paymentData?.totalPayble}
                      </Box>
                    </Box>
                  </Box>
                </Box>
                <Box>
                  <Typography
                    sx={{ fontSize: "14px", fontWeight: theme.typography.Bold }}
                  >
                    Apply Voucher Code{" "}
                    <span sx={{ fontWeight: theme.typography.Regular }}>
                      (optional)
                    </span>
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      border: "1px solid #ccc",
                      borderRadius: "10px",
                      overflow: "hidden",
                      backgroundColor: "#fff",
                      //   maxWidth: "450px",
                      height: "40px",
                      //   minWidth: "320px", // Adjust the width as needed
                    }}
                  >
                    <InputBase
                      disabled={disabled}
                      sx={{
                        flex: 1,
                        border: "none",
                        outline: "none",
                        padding: "8px",
                        height: "40px",

                        "& .MuiInputBase-root": {
                          padding: 0,
                        },
                      }}
                      placeholder="Enter a valid voucher code"
                    />
                    <Button
                      color="discovery"
                      sx={{
                        borderRadius: "0px 10px 10px 0px",
                        color: "#fff",
                        padding: "8px 16px", // Adjust the padding as needed
                        height: "40px",
                        fontSize: "14px",

                        fontWeight: theme.typography.Bold,
                        "&:hover": {
                          backgroundColor: "#B98B5F",
                        },
                      }}
                      variant="contained"
                      onClick={() => {}}
                    >
                      apply voucher
                    </Button>
                  </Box>
                </Box>
                <Box
                  sx={{
                    width: "100%",
                    borderRadius: "10px",
                    overflow: "hidden",
                    marginTop: "10px",
                  }}
                >
                  <Typography
                    sx={{
                      marginBottom: "4px",
                      fontWeight: theme.typography.Bold,
                      fontSize: "14px",
                    }}
                  >
                    Card number
                  </Typography>
                  <InputBase
                    placeholder={"Enter the 16-digit card number"}
                    value={formData?.cardNumber}
                    id="cardNumber"
                    name="cardNumber"
                    type="number"
                    disabled={disabled}
                    onChange={(e) =>
                      handleInputChange("cardNumber", e.target.value)
                    }
                    sx={{
                      width: "100%",
                      borderRadius: "10px",
                      border: `1px solid ${theme.palette.grayBorder}`,
                      height: "40px !important",
                      padding: "8px",
                    }}
                  />
                </Box>
                <Box
                  sx={{
                    width: "100%",
                    borderRadius: "10px",
                    overflow: "hidden",
                    marginTop: "10px",
                  }}
                >
                  <Typography
                    sx={{
                      marginBottom: "4px",
                      fontSize: "14px",
                      fontWeight: theme.typography.Bold,
                    }}
                  >
                    Cardholder Name
                  </Typography>
                  <InputBase
                    placeholder={"Enter the name displayed on the card"}
                    value={formData?.holderName}
                    id="holderName"
                    name="holderName"
                    disabled={disabled}
                    onChange={(e) =>
                      handleInputChange("holderName", e.target.value)
                    }
                    sx={{
                      width: "100%",
                      borderRadius: "10px",
                      border: `1px solid ${theme.palette.grayBorder}`,
                      height: "40px !important",
                      padding: "8px",
                      //   padding: "10px 14px",
                      //   color: "#E0E0E0",
                    }}
                  />
                </Box>

                <Box sx={{ display: "flex", gap: "10px", marginTop: "5px" }}>
                  <Box
                    sx={{
                      width: "100%",
                      // maxWidth: "400px",
                      // border: `1px solid ${theme.palette.grayBorder}`,
                      borderRadius: "10px",
                      overflow: "hidden",
                      // "&:hover": {
                      //   borderColor: `${theme.palette.grayBorder}`,
                      // },
                      // "&.Mui-focused": {
                      //   borderColor: `${theme.palette.grayBorder}`,
                      // },
                    }}
                  >
                    <Typography
                      sx={{
                        marginBottom: "4px",
                        fontSize: "14px",
                        fontWeight: theme.typography.Bold,
                      }}
                    >
                      Expiry Date
                    </Typography>
                    <InputBase
                      placeholder={"mm/yy"}
                      value={formData?.expiryDate}
                      id="expiryDate"
                      name="expiryDate"
                      type="date"
                      disabled={disabled}
                      onChange={(e) =>
                        handleInputChange("expiryDate", e.target.value)
                      }
                      sx={{
                        width: "100%",
                        borderRadius: "10px",
                        border: `1px solid ${theme.palette.grayBorder}`,
                        height: "40px !important",
                        //   padding: "10px 14px",
                        padding: "8px",
                        //   color: "#E0E0E0",
                      }}
                    />
                  </Box>
                  <Box
                    sx={{
                      width: "100%",
                      borderRadius: "10px",
                      overflow: "hidden",
                    }}
                  >
                    <Typography
                      sx={{
                        marginBottom: "4px",
                        fontSize: "14px",
                        fontWeight: theme.typography.Bold,
                      }}
                    >
                      CVV
                    </Typography>
                    <InputBase
                      placeholder={"cvv"}
                      value={formData?.cvv}
                      id="cvv"
                      name="cvv"
                      type="number"
                      disabled={disabled}
                      onChange={(e) => handleInputChange("cvv", e.target.value)}
                      sx={{
                        width: "100%",
                        borderRadius: "10px",
                        border: `1px solid ${theme.palette.grayBorder}`,
                        height: "40px !important",
                        padding: "8px",
                      }}
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>

          <Box sx={{ display: "flex" }}>
            <Button
              variant="contained"
              color="submitButton"
              onClick={handleSkip}
              sx={{
                color: "#000",
                borderRadius: "10px 0 0 0",
                height: "60px",
                width: "150px",
                fontWeight: theme.typography.Bold,
                fontSize: "14px",
              }}
            >
              skip for now
            </Button>
            <Button
              variant="contained"
              color="discovery"
              onClick={() => setIsShowPaymentModal(true)}
              sx={{
                borderRadius: "0px 10px 0 0",
                height: "60px",
                width: "150px",
                fontWeight: theme.typography.Bold,
                fontSize: "14px",
              }}
            >
              pay now
            </Button>
          </Box>
        </Box>
      </Box>

      {isShowPaymentModal && (
        <PaymentModal
          open={isShowPaymentModal}
          setOpen={() => setIsShowPaymentModal(false)}
          handleReviewLater={handleReviewLater}
          cashAmount={paymentData?.totalPayble}
        />
      )}
    </Box>
  );
};

export default PaymentPage;
