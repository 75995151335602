import React, { useState } from "react";
import { Box, Button, Grid, Typography } from "@mui/material";
import playButtonIcon from "../../../../assets/Padding Excluded/Black_reactive_job copy.svg";
import playButtonIconWhite from "../../../../assets/Padding Excluded/Black_reactive_job.svg";

import job_logo from "../../../../assets/Crayon_Favicon.svg";
import LocationIcon from "../../../../assets/Red_Location.svg";
import CalendarIcon from "../../../../assets/Padding Included/Yellow_Calendar.svg";
import CompanyLink from "../../../../assets/Padding Excluded/Black_World_Website.svg";
import SmallButton from "../../../common/SmallButton";
import { truncate } from "lodash";
import { Link } from "react-router-dom";
import { dateConverterMonth } from "../../../../utils/DateTime";
import { formatPhoneNumberIntl } from "react-phone-number-input";
import locale from "../../../../i18n/locale";
import ReviewVideoDialog from "../../ReviewVideoDialog";
import { setProfileSteps } from "../../../../redux/employer/empProfileSlice";
import { useDispatch } from "react-redux";
import NewSlider from "../../NewSlider";

const CompanyDetailCard = ({
  companyDetails,
  industries,
  defaultCompany,
  theme,
  setActiveButton,
  setEditCompanyInfo,
  setEditCompanyID,
  setOpenAddCompanyDetails,
  editFlag,
  setCompanyID,
  handleDelete,
  companyURL,
  companyVideo,
  currentPageJobsCount,
  jobslength,
  indexNumber,
  job,
}) => {
  const i18n = locale.en;
  const dispatch = useDispatch();
  const [videoLink, setVideoLink] = useState(companyVideo);
  const [openReviewVideoDialog, setOpenReviewVideoDialog] = useState(false);
  const handleCloseDialog = () => {
    setVideoLink("");
    setOpenReviewVideoDialog(false);
  };

  const handleEdit = (companyInfo) => {
    setCompanyID(companyInfo?.company_id);
    // defaultCompany && setActiveButton(2);
    defaultCompany && dispatch(setProfileSteps(2));
    !defaultCompany &&
      setEditCompanyInfo({
        ...companyInfo,
        industry_ids: industries?.map((item) => ({
          value: item?.industry_id,
          label: item?.industry?.name,
        })),
        culture_ids: job?.culture?.map((item) => ({
          value: item?.company_culture_id,
          label: item?.company_culture?.name,
        })),
        country_id: {
          value: companyInfo?.country_id,
          label: companyInfo?.town?.country?.name,
        },
        town_id: {
          value: companyInfo?.town_id,
          label: companyInfo?.town?.name,
        },
        editFlag: editFlag,
        companyURL: companyURL,
      });

    !defaultCompany && setEditCompanyID(true);
    !defaultCompany && setOpenAddCompanyDetails(true);
  };

  return (
    <Box
      sx={{
        width: "100%",
        height: "530px",
        borderRadius: "25px",
        background: "white",
        position: "relative",
        boxShadow: "0px 5px 10px #C9CAD8",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      {defaultCompany && (
        <Button
          variant="contained"
          sx={{
            position: "absolute",
            top: "10px",
            right: "60px",
            fontSize: 12,
            fontWeight: theme.typography.Bold,
            letterSpacing: "0.75px",
            height: 25,
            boxShadow: 0,
            borderRadius: "5px",
            minWidth: "fit-content",
            padding: "0 8px",
          }}
          color="myVideoButton"
        >
          {i18n["empMyCompanies.default"]}
        </Button>
      )}
      <Button
        color="playButton"
        // color="playButton"
        variant="contained"
        sx={{
          position: "absolute",
          right: 0,
          zIndex: 10,
          height: "auto",
          minWidth: "45px",
          minHeight: "45px",
          borderRadius: "0 25px 0 10px",
          cursor: companyVideo !== "NO URL" ? "pointer" : "default",
          background: companyVideo === "NO URL" && theme.palette.grayButton600.main,
          padding: 0,
          ":hover": {
            boxShadow: "none",
          },
        }}
        onClick={() =>
          companyVideo !== "NO URL" && setOpenReviewVideoDialog(true)
        }
      >
        <Box
          component={"img"}
          src={companyVideo !== "NO URL" ? playButtonIconWhite : playButtonIcon}
          sx={{
            height: "12px",
            width: "15px",
          }}
        />
      </Button>
      <Box>
        <Link
          to={`/jobs/company/${job?.company_id}`}
          target={"_blank"}
          style={{
            textDecoration: "none",
            color: theme.palette.black,
            cursor: "pointer",
          }}
        >
          <Box
            component="img"
            sx={{
              height: "60px",
              width: "60px",
              maxHeight: { xs: "60px" },
              maxWidth: { xs: "60px" },
              ml: "15px",
              mt: "15px",
              mb: "15px",
              // border: 1,
              // borderColor: "lightgrey",
              borderRadius: "10px",
            }}
            alt="job_logo"
            src={companyURL !== "NO URL" ? companyURL : job_logo}
          />
        </Link>
        <Box
          sx={{
            padding: "0 15px",
            display: "flex",
            flexDirection: "column",
            gap: "15px",
          }}
        >
          <Typography
            sx={{
              fontSize: "20px",
              fontWeight: theme.typography.Bold,
              lineHeight: 1,
            }}
          >
            {companyDetails?.name}
          </Typography>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "start",
              height: "12px",
              gap: 1,
            }}
          >
            <Box
              component="img"
              sx={{
                height: 18,
                width: 18,
                maxHeight: { xs: 28 },
                maxWidth: { xs: 28 },
              }}
              alt="location"
              src={LocationIcon}
            />
            <Typography
              sx={{
                fontWeight: theme.typography.Bold,
                fontSize: { xs: "12px", lg: "12px", mlg: "14px" },
                letterSpacing: "0px",
                width: "80%",
                textOverflow: "ellipsis",
                overflow: "hidden",
              }}
              noWrap
            >
              {job?.company?.town?.name || "-"}
              {job?.company?.town?.name &&
                `, ${job?.company?.town?.country?.name} `}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "start",
              height: "12px",
              gap: 1,
            }}
          >
            <Box
              component="img"
              sx={{
                height: 18,
                width: 18,
                maxHeight: { xs: 28 },
                maxWidth: { xs: 28 },
              }}
              alt="company link"
              src={CompanyLink}
            />
            <Typography
              sx={{
                fontWeight: theme.typography.Bold,
                fontSize: { xs: "12px", lg: "12px", mlg: "14px" },
                letterSpacing: "0px",
                //   opacity: 0.8,
                mt: 0.5,
                width: "80%",
                textOverflow: "ellipsis",
                overflow: "hidden",
              }}
              noWrap
            >
              <Link
                style={{ textDecoration: "none", color: "black" }}
                target="_blank"
                to={companyDetails?.website}
              >
                {companyDetails?.website}
              </Link>
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "start",
              height: "12px",
              gap: 1,
            }}
          >
            <Box
              component="img"
              sx={{
                height: 18,
                width: 18,
                maxHeight: { xs: 28 },
                maxWidth: { xs: 28 },
              }}
              alt="calendar"
              src={CalendarIcon}
            />
            <Typography
              sx={{
                fontWeight: theme.typography.Bold,
                fontSize: { xs: "12px", lg: "12px", mlg: "14px" },
                letterSpacing: "0px",
                //   opacity: 0.8,
                mt: 0.5,
                width: "80%",
                textOverflow: "ellipsis",
                overflow: "hidden",
              }}
              noWrap
            >
              {i18n["empMyCompanies.dateAdded"]}
              {dateConverterMonth(companyDetails?.created_at)}
            </Typography>
          </Box>
          <Box
            sx={{
              height: "60px",
              display: "flex",
              flexDirection: "column",
              gap: "10px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                gap: "5px",
              }}
            >
              {companyDetails?.company_type && (
                <SmallButton
                  color={"companyTypeButton"}
                  height={25}
                  value={companyDetails?.company_type}
                  label={truncate(companyDetails?.company_type, {
                    length: 12,
                  })}
                />
              )}
              {companyDetails?.company_size && (
                <SmallButton
                  color={"companySizeButton"}
                  height={25}
                  value={companyDetails?.company_size}
                  label={truncate(companyDetails?.company_size, {
                    length: 12,
                  })}
                />
              )}
            </Box>
            <Box
              sx={{
                height: "25px",
              }}
            >
              <NewSlider
                items={
                  industries
                    ?.map((obj) => obj?.industry?.name)
                    ?.sort((a, b) => a.length - b.length) || []
                }
                color={"industriesButton"}
                hideTagsAfter={3}
                height="25px"
                job={job}
                index={indexNumber}
                jobslength={jobslength}
                id={job?.job_id}
                currentPageJobsCount={currentPageJobsCount}
              />
            </Box>
          </Box>
          <Grid
            sx={{
              height: "80px",
            }}
          >
            <Typography
              sx={{
                fontWeight: theme.typography.Bold,
                fontSize: 14,
                letterSpacing: "0.75px",
              }}
            >
              {i18n["empMyCompanies.about"]}
            </Typography>
            <Typography
              sx={{
                fontWeight: theme.typography.Medium,
                fontSize: 12,
                letterSpacing: "0px",
                wordWrap: "break-word",
                display: "-webkit-box",
                WebkitBoxOrient: "vertical",
                overflow: "hidden",
                WebkitLineClamp: 3,
              }}
            >
              {companyDetails?.description}
            </Typography>
          </Grid>
          <Grid>
            <Typography
              sx={{
                fontWeight: theme.typography.Bold,
                fontSize: 14,
                letterSpacing: "0.75px",
              }}
            >
              {i18n["empMyCompanies.contact"]}
            </Typography>
            <Typography
              sx={{
                fontWeight: theme.typography.Medium,
                fontSize: 12,
                letterSpacing: "0px",
                wordWrap: "break-word",
              }}
            >
              {companyDetails?.contact_person_name}
            </Typography>
            <Typography
              sx={{
                fontWeight: theme.typography.Medium,
                fontSize: 12,
                letterSpacing: "0px",
                wordWrap: "break-word",
              }}
            >
              {companyDetails?.contact_person_email}
            </Typography>
            <Typography
              sx={{
                fontWeight: theme.typography.Medium,
                fontSize: 12,
                letterSpacing: "0px",
                wordWrap: "break-word",
              }}
            >
              {formatPhoneNumberIntl(
                `${companyDetails?.country_code}${companyDetails?.contact_person_number}`
              )}
            </Typography>
          </Grid>
        </Box>
      </Box>
      <Box>
        <Button
          disabled={!editFlag}
          color="editButton"
          variant="contained"
          sx={{
            borderRadius: "0 0 0 25px",
            width: `${100 / 3}%`,
            height: "60px",
            fontSize: "14px",
            fontWeight: theme.typography.Bold,
            ":hover": {
              boxShadow: 0,
            },
          }}
          onClick={() => handleEdit(companyDetails)}
        >
          {i18n["empMyCompanies.edit"]}
        </Button>
        <Link
          to={`/jobs/company/${job?.company_id}`}
          target={"_blank"}
          style={{
            textDecoration: "none",
            color: theme.palette.black,
            cursor: "pointer",
          }}
        >
          <Button
            color="viewJobsButton"
            variant="contained"
            sx={{
              borderRadius: 0,
              width: `${100 / 3}%`,
              height: "60px",
              fontSize: "14px",
              fontWeight: theme.typography.Bold,
              ":hover": {
                boxShadow: 0,
              },
            }}
          >
            {i18n["empMyCompanies.viewJobs"]}
          </Button>
        </Link>
        <Button
          color="deleteButton"
          variant="contained"
          sx={{
            borderRadius: "0 0 25px 0",
            width: `${100 / 3}%`,
            height: "60px",
            fontSize: "14px",
            fontWeight: theme.typography.Bold,
            ":hover": {
              boxShadow: 0,
            },
          }}
          onClick={() => handleDelete(companyDetails?.company_id)}
        >
          {i18n["empMyCompanies.delete"]}
        </Button>
        {openReviewVideoDialog && (
          <ReviewVideoDialog
            videoLink={videoLink}
            open={openReviewVideoDialog}
            setOpen={handleCloseDialog}
          />
        )}
      </Box>
    </Box>
  );
};

export default CompanyDetailCard;
