import React, { useCallback, useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Checkbox,
  IconButton,
  Tooltip,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Grid } from "@mui/material";
import InfiniteScroll from "react-infinite-scroll-component";
import EditIcon from "@mui/icons-material/Edit";
import { useDispatch } from "react-redux";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  approveCompany,
  deleteEmployerCompany,
  editCompany,
  getAllCompanies,
} from "../../../redux/admin/maintenance";
import { dateConverterMonth } from "../../../utils/DateTime";
import { setAlert, setLoading } from "../../../redux/configSlice";
import { ALERT_TYPE, ERROR_MSG } from "../../../utils/Constants";
import Delete from "./Dialogbox/Delete";
import Approve from "./Dialogbox/Approve";
import Edit from "./Dialogbox/Edit";
import { getCompanies } from "../../../redux/employer/empProfileSlice";
import { useSelector } from "react-redux";
import SearchBox from "./Dialogbox/SearchBox";
import { debounce } from "../../../utils/Common";
import locale from "../../../i18n/locale";

export default function Company() {
  const dispatch = useDispatch();
  const i18n = locale.en;
  const [lastKey, setLastKey] = useState(0);
  const [tableData, setTableData] = useState([]);
  const [companyCount, setCompanyCount] = useState(0);
  const [openDelete, setOpenDelete] = useState(false);
  const [openApprove, setOpenApprove] = useState(false);
  const [openAdd, setOpenAdd] = useState(false);

  const [deleteCompany, setDeleteCompany] = useState();
  const [companyName, setcompanyName] = useState("");
  const [existingCompany, setExistingCompany] = useState();
  const [associationID, setassociationID] = useState();
  const [approveEvent, setApproveEvent] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [editableCompany, setEditableCompany] = useState();
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [searchTitle, setSearchTitle] = useState("");

  const { companies } = useSelector((state) => state.myProfile);

  const getAllData = async () => {
    try {
      dispatch(setLoading(true));
      await dispatch(getCompanies());
      dispatch(setLoading(false));
    } catch (error) {
      dispatch(setLoading(false));
      dispatch(
        setAlert({
          show: true,
          type: ALERT_TYPE.ERROR,
          msg: ERROR_MSG,
        })
      );
    }
  };

  const getCompany = async (lastkeyy, title) => {
    try {
      let data = { lastKey: lastkeyy, title: title };
      const { payload } = await dispatch(getAllCompanies(data));
      if (payload.status === "success") {
        setCompanyCount(payload.count);
        if (lastkeyy === 0) {
          setTableData(payload.data);
          setLastKey(payload.pageNumber + 1);
        } else {
          setLastKey(payload.pageNumber + 1);
          setTableData((prevState) => [...prevState, ...payload.data]);
        }
      }
    } catch (error) {}
  };

  const getCompanyApproved = async (event, companyId) => {
    try {
      const data = {
        company_id: companyId,
        flag: event.target.checked ? 0 : 1,
      };
      const { payload } = await dispatch(approveCompany(data));
      if (payload.status === "success") {
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE.SUCCESS,
            msg: "Company Approved Successfully",
          })
        );
        setOpenApprove(false);
        await getCompany(0, searchTitle);
      }
    } catch (error) {}
  };

  const handleEditCompany = async () => {
    try {
      const data = {
        new_company_id:
          existingCompany !== null || existingCompany !== undefined
            ? existingCompany?.value
            : null,
        current_company_id: editableCompany,
        name: companyName !== "" ? companyName : "",
      };
      const { payload } = await dispatch(editCompany(data));
      if (payload.status === "success") {
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE.SUCCESS,
            msg: "Company edited Successfully",
          })
        );
        setExistingCompany(null);
        setOpenEdit(false);
        await getCompany(0, searchTitle);
        await getAllData();
      }
    } catch (error) {}
  };

  const handleOpenDelete = (jobId) => {
    setOpenDelete((prevState) => !prevState);
    setDeleteCompany(jobId);
  };
  const handleOpenApprove = (event, jobId) => {
    setOpenApprove((prevState) => !prevState);
    setApproveEvent(event);
    setassociationID(jobId);
  };

  const handleCancelDelete = () => {
    setOpenDelete((prevState) => !prevState);
    setConfirmDelete(false);
  };

  const handleOpenEdit = (currCompID, compName) => {
    setOpenEdit((prevState) => !prevState);
    setEditableCompany(currCompID);
    setcompanyName(compName);
  };

  const handleCancel = () => {
    setOpenApprove(false);
  };

  const debouncedHandleOnSelectInputChange = useCallback(
    debounce(
      async (newValue, type) => {
        let params = {
          title: newValue || "",
          limit: 200,
        };
        try {
          dispatch(getCompanies(params));
        } catch (error) {
          dispatch(
            setAlert({
              show: true,
              type: ALERT_TYPE?.ERROR,
              msg: error,
            })
          );
        }
      },
      750,
      setIsLoading
    ),
    []
  );

  const handleOnChange = (newValue, inputId) => {
    debouncedHandleOnSelectInputChange(newValue, inputId);
  };

  const handleDelete = async () => {
    try {
      const { payload } = await dispatch(
        deleteEmployerCompany({ company_id: deleteCompany })
      );
      if (payload?.status === "success") {
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE?.SUCCESS,
            msg: i18n["alertMessage.companydelete"],
          })
        );
        setConfirmDelete(false);
        setOpenDelete(false);
        await getCompany(0, searchTitle);
        await getAllData();
      } else {
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE?.ERROR,
            msg: ERROR_MSG,
          })
        );
      }
    } catch (error) {
      dispatch(
        setAlert({
          show: true,
          type: ALERT_TYPE?.ERROR,
          msg: ERROR_MSG,
        })
      );
    }
  };

  const handleEdit = (event) => {
    if (event?.target?.id === "edit_input") {
      setExistingCompany(null);
      setcompanyName(event?.target?.value);
    } else if (event?.id === "existing_name") {
      let com = companies?.find(
        (title) => title?.name === event?.newValue?.name
      );
      setcompanyName("");
      setExistingCompany(com?.company_id);
    }
  };

  const handleText = async (event) => {
    setSearchTitle(event?.target?.value);
    debouncedHandleOnTextSearch(event);
  };

  const debouncedHandleOnTextSearch = useCallback(
    debounce(
      async (newValue) => {
        if (!newValue) return; // Early return
        try {
          const response = await getCompany(0, newValue.target.value);

          if (response && response.payload) {
            const { payload } = response;
            dispatch(
              payload.status === "success"
                ? setTableData(payload.data)
                : setAlert({ show: true, type: ALERT_TYPE.ERROR, msg: "Error" })
            );
          }
        } catch (error) {
          dispatch(
            setAlert({ show: true, type: ALERT_TYPE.ERROR, msg: ERROR_MSG })
          );
        }
      },
      500, // Adjust delay as needed
      setIsLoading
    ),
    [dispatch, setTableData, setIsLoading]
  );

  useEffect(() => {
    getCompany(0, searchTitle);
    getAllData();
  }, []);

  return (
    <Box sx={{ ml: "16px" }}>
      <Typography
        sx={{
          fontSize: "36px",
          fontWeight: 700,
        }}
      >
        Companies ({companyCount})
      </Typography>

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          gap: "10px",
          marginTop: "16px",
        }}
      >
        <SearchBox onChange={(event) => handleText(event)} />
      </Box>

      <Grid
        container
        spacing={2}
        flexDirection={"column"}
        sx={{
          display: { xs: "none", md: "flex" },
          marginTop: "30px",
          marginLeft: "0px",
        }}
      >
        <InfiniteScroll
          style={{ overflow: "hidden" }}
          dataLength={tableData.length}
          next={() => getCompany(lastKey, searchTitle)}
          scrollThreshold={"100px"}
          scrollableTarget={"jobList"}
          hasMore={true}
          endMessage={
            <p style={{ textAlign: "center" }}>
              <b>Yay! You have seen it all</b>
            </p>
          }
        >
          <Box
            sx={{
              mt: 2,
            }}
          >
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <Typography variant="subtitle1" fontWeight="bold">
                        Id
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="subtitle1" fontWeight="bold">
                        Name
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="subtitle1" fontWeight="bold">
                        Created By
                      </Typography>
                    </TableCell>

                    <TableCell>
                      <Typography variant="subtitle1" fontWeight="bold">
                        Created At
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="subtitle1" fontWeight="bold">
                        Action
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {tableData.map((row) => (
                    <TableRow
                      key={row.id}
                      style={{
                        "& .css-12zgwp-MuiTableCell-root": {
                          padding: "0 16px !important",
                        },
                      }}
                    >
                      <TableCell>{row?.company_id}</TableCell>
                      <TableCell sx={{ width: "450px" }}>{row?.name}</TableCell>
                      <TableCell sx={{ width: "400px" }}>
                        {`${row?.user?.first_name} ${row?.user?.last_name}`}
                      </TableCell>

                      <TableCell>
                        {dateConverterMonth(row.created_at)}
                      </TableCell>
                      <TableCell>
                        <Tooltip
                          disableInteractive
                          title="approve"
                          placement="top-end"
                        >
                          <Checkbox
                            checked={row.enabled}
                            onClick={(event) =>
                              handleOpenApprove(event, row?.company_id)
                            }
                          />
                        </Tooltip>
                        <Tooltip
                          title="edit"
                          placement="top-end"
                          disableInteractive
                        >
                          <IconButton
                            aria-label="edit"
                            color="blueButton400"
                            sx={{
                              padding: "0 !important",
                              minWidth: "18px !important",
                              "& .MuiSvgIcon-root": {
                                width: "18px",
                              },
                            }}
                          >
                            <EditIcon
                              onClick={() =>
                                handleOpenEdit(row?.company_id, row?.name)
                              }
                              sx={{ cursor: "pointer" }}
                            />
                          </IconButton>
                        </Tooltip>
                        <Tooltip
                          title="delete"
                          placement="top-end"
                          disableInteractive
                        >
                          <IconButton
                            aria-label="edit"
                            color="blueButton400"
                            sx={{
                              padding: "0 !important",
                              minWidth: "18px !important",
                              "& .MuiSvgIcon-root": {
                                width: "18px",
                              },
                            }}
                          >
                            <DeleteIcon
                              onClick={() => handleOpenDelete(row?.company_id)}
                              sx={{ cursor: "pointer" }}
                            />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </InfiniteScroll>
      </Grid>
      <Delete
        show={openDelete}
        handleOpen={handleOpenDelete}
        dialogText={"company"}
        handleCancel={handleCancelDelete}
        confirmDelete={confirmDelete}
        setConfirmDelete={setConfirmDelete}
        handleDelete={handleDelete}
      />

      <Edit
        show={openEdit}
        handleOpen={handleOpenEdit}
        handleEdit={handleEditCompany}
        handleEditJob={handleEdit}
        inputName={companyName}
        existingCompany={existingCompany}
        data={companies}
        dialogText={"company"}
        handleOnChange={handleOnChange}
        setExistingCompany={setExistingCompany}
        setcompanyName={setcompanyName}
        isLoading={isLoading}
      />
      <Approve
        dialogText={"company"}
        show={openApprove}
        handleOpen={handleOpenApprove}
        handleCancel={handleCancel}
        handleApprove={getCompanyApproved}
        approveEvent={approveEvent}
        jobID={associationID}
      />
    </Box>
  );
}
