import { Box, Grid, ThemeProvider } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { employerCompanyListTheme } from "../../../../utils/componentThemes/employerCompanyListTheme";
import CrayonLiteCard from "./CrayonLiteCard";
import CrayonTalentCard from "./CrayonTalentCard";
import ReviewPlan from "./ReviewPlan";
import { useDispatch } from "react-redux";
import {
  addNewUserCoin,
  addNewUserLicense,
  getPlanDetails,
  reviewEmployerPlan,
  talentPlanRequest,
} from "../../../../redux/employer/empProfileSlice";
import { setAlert } from "../../../../redux/configSlice";
import { ALERT_TYPE, ERROR_MSG } from "../../../../utils/Constants";
import { getProfilePercentage } from "../../../../redux/employer/empJobListing";
import { useSelector } from "react-redux";
import CoinSpendBox from "../../CoinSpendBox";
import locale from "../../../../i18n/locale";
import {
  getNotificationCount,
  setCoinsCount,
  setNotificationCount,
} from "../../../../redux/candidate/alerts";
import { setCardsPadding } from "../../../../redux/guest/jobsSlice";
import MyPlanNew from "./MyPlanNew";
import { useNavigate } from "react-router-dom";

export default function MyPlans() {
  const i18n = locale.en;
  const gridRef = useRef();
  const [unlockCV, setUnlockCV] = useState(false);
  const dispatch = useDispatch();
  const [planData, setPlanData] = useState();
  const profilePercentage = useSelector(
    (state) => state.employerjoblisting.profilePercentage
  );
  const navigate = useNavigate();
  const { company_id } = useSelector((state) => state?.configalert);
  const [newCardWidth, setNewCardWidth] = useState(340);
  const [newPadding, setNewPadding] = useState(25);
  const [coinData, setCoinData] = useState();
  const [isPlanBuyModal, setIsPlanBuyModal] = useState(false);
  const [isShowBorder, setIsShowBorder] = useState(false);
  const [reviewOptions, setReviewOptions] = useState(
    profilePercentage?.myPlan === 25 ? false : true
  );

  function calculateCardWidthAndPadding(totalWidth) {
    let cardWidth = 340;
    let padding = 25;
    let numCards = Math.floor(totalWidth / (cardWidth + padding));
    let remainingSpace =
      totalWidth - (numCards * cardWidth + padding * (numCards + 1));

    while (remainingSpace > 0) {
      if (remainingSpace < cardWidth + padding) {
        if (padding < 30) {
          padding++;
          remainingSpace =
            totalWidth - (numCards * cardWidth + padding * (numCards + 1));
        } else {
          break;
        }
      } else {
        numCards++;
      }
    }

    cardWidth = (totalWidth - padding * (numCards + 1)) / numCards;
    if (cardWidth > 380) {
      cardWidth = 380;
    }
    remainingSpace =
      totalWidth - (numCards * cardWidth + padding * (numCards + 1));

    padding = padding + remainingSpace / (numCards + 1);

    remainingSpace =
      totalWidth - (numCards * cardWidth + padding * (numCards + 1));

    setNewCardWidth(cardWidth - 1);
    setNewPadding(padding);
    dispatch(setCardsPadding(padding));
    return { numCards, cardWidth, padding, remainingSpace };
  }
  const getNewWidth = () => {
    if (gridRef?.current) {
      calculateCardWidthAndPadding(gridRef?.current?.clientWidth + 60);
    }
  };
  useEffect(() => {
    if (gridRef?.current) {
      calculateCardWidthAndPadding(gridRef?.current?.clientWidth + 60);
    }
    window.addEventListener("resize", getNewWidth);
    return () => {
      window.removeEventListener("resize", getNewWidth);
    };
  }, []);

  const getProfilePercentageData = async () => {
    const { payload } = await dispatch(getProfilePercentage());
    // setProfilePercentage(payload?.data);
  };

  const handleReview = async () => {
    try {
      const { payload } = await dispatch(reviewEmployerPlan());
      if (payload?.status === "success") {
        getProfilePercentageData();
        setReviewOptions(false);
      } else {
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE?.ERROR,
            msg: ERROR_MSG,
          })
        );
      }
    } catch (error) {
      dispatch(
        setAlert({
          show: true,
          type: ALERT_TYPE?.ERROR,
          msg: ERROR_MSG,
        })
      );
    }
  };

  const getPlan = async () => {
    setIsShowBorder(false);
    try {
      let response = await dispatch(getPlanDetails());
      if (response?.payload?.status === "success") {
        setIsShowBorder(true);

        setPlanData(response?.payload?.data);
      }
    } catch (error) {
      setIsShowBorder(false);

      dispatch(
        setAlert({
          show: true,
          type: ALERT_TYPE?.ERROR,
          msg: ERROR_MSG,
        })
      );
    }
  };

  useEffect(() => {
    getPlan();
  }, []);

  const handleUnlockCV = async () => {
    if (planData?.coins === 0 || planData?.coins === null) {
      let payload = {
        unlock_amount: coinData?.unlock_amount,
        company_id: company_id,
        transaction_name_id: coinData?.transaction_name_id,
        employer_unlock: true,
        cost: coinData?.ZARCOST,
      };
      try {
        let response = await dispatch(addNewUserLicense(payload));
        if (response?.payload?.status === "success") {
          setUnlockCV(false)
          window.open(`/payment/${response?.payload?.payment_transaction_id}`, "_blank");
          // navigate(`/payment/${response?.payload?.payment_transaction_id}`, { state: { value:response?.payload?.payment_transaction_id } });
          getPlan();
          getCount();
        }
      } catch (error) {
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE?.ERROR,
            msg: ERROR_MSG,
          })
        );
      }
    } else {
      let payload = {
        unlock_amount: coinData?.unlock_amount,
        company_id: company_id,
        transaction_name_id: coinData?.transaction_name_id,
        employer_unlock: true,
        cost: coinData?.ZARCOST,
      };
      try {
        let response = await dispatch(addNewUserLicense(payload));
        if (response?.payload?.status === "success") {
          getPlan();
          getCount();
          setUnlockCV(false);
        }
      } catch (error) {
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE?.ERROR,
            msg: ERROR_MSG,
          })
        );
      }
    }
  };
  const getCount = async () => {
    try {
      const { payload } = await dispatch(getNotificationCount());
      if (payload?.status === "success") {
        dispatch(setNotificationCount(payload?.count));
        dispatch(setCoinsCount(payload?.coinsCounter));
        // countRef.current = payload?.count;
      }
    } catch (error) {}
  };
  const handleSetUnlockCV = async (status) => {
    const payload = {
      company_id: company_id,
    };
    try {
      let response = await dispatch(addNewUserCoin(payload));
      if (response?.payload?.status === "success") {
        setCoinData(response?.payload?.data);
        setUnlockCV(status);
      }
    } catch (error) {
      dispatch(
        setAlert({
          show: true,
          type: ALERT_TYPE?.ERROR,
          msg: ERROR_MSG,
        })
      );
    }
  };

  const handlePlanBuy = async () => {
    if (planData?.coins === 0 || planData?.coins === null) {
      let payload = {
        cost: planData?.crayon_plan?.costZAR,
        currency_id: 1,
        company_id: planData?.company_id,
        transaction_name_id: planData?.transaction_name_id,
      };
      try {
        let response = await dispatch(talentPlanRequest(payload));
        if (response?.payload?.status === "success") {
          setIsPlanBuyModal(false);
          window.open(`/payment/${response?.payload?.payment_transaction_id}`, "_blank");
        }
      } catch (error) {
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE?.ERROR,
            msg: ERROR_MSG,
          })
        );
      }
    } else {
      let payload = {
        cost: planData?.crayon_plan?.costZAR,
        currency_id: 1,
        company_id: planData?.company_id,
        transaction_name_id: planData?.transaction_name_id,
      };
      try {
        let response = await dispatch(talentPlanRequest(payload));
        if (response?.payload?.status === "success") {
          setIsPlanBuyModal(false);
        }
      } catch (error) {
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE?.ERROR,
            msg: ERROR_MSG,
          })
        );
      }
    }
  };
  const handleUnlock = () => {
    setIsPlanBuyModal(true);
  };
  return (
    <>
      <ThemeProvider theme={employerCompanyListTheme}>
        <MyPlanNew
          newCardWidth={newCardWidth}
          planData={planData}
          setUnlockCV={handleSetUnlockCV}
          handleUnlock={handleUnlock}
          isShowBorder={isShowBorder}
        />
        {/* <Box
          sx={{
            display: "flex",
            height: "100%",
          }}
        >
          <Grid
            container
            sx={{
              display: "flex",
              // alignItems: "center",
              flexGrow: 1,
              flexDirection: "column",
              width: `calc(100vw - 360px)`,
              height: "100%",
            }}
            ref={gridRef}
          >
            <Grid
              container
              gap={`40px ${newPadding}px`}
              sx={{
                position: "relative",
                height: "100%",
              }}
            >
              <Box
                sx={{
                  width: newCardWidth,
                  height: "414px",
                }}
              >
                <CrayonLiteCard
                  newCardWidth={newCardWidth}
                  planData={planData}
                  setUnlockCV={handleSetUnlockCV}
                />
              </Box>
              <Box
                sx={{
                  width: newCardWidth,
                  height: "414px",
                }}
              >
                <CrayonTalentCard
                  newCardWidth={newCardWidth}
                  planData={planData}
                  setUnlockCV={handleSetUnlockCV}
                  handleUnlock={handleUnlock}
                />
              </Box>
            </Grid>
          </Grid>
        </Box> */}
      </ThemeProvider>
      {reviewOptions && (
        <ReviewPlan show={reviewOptions} handleReview={() => handleReview()} />
      )}
      {unlockCV && (
        <CoinSpendBox
          text1={i18n["myplanPopup.confirmcoinspend"]}
          text1Width={"186px"}
          text2={i18n["myplanPopup.youcurrentlyhave"]}
          coinsBalance={`${coinData?.coins} coins.`}
          coinsUse={`${coinData?.unlock_amount} coin.`}
          text2Width={"236px"}
          text3={"This additional user license will use"}
          text3Width={"358px"}
          text3Height={"72px"}
          text4={i18n["myplanPopup.pleaseconfirm"]}
          text4Width={"220px"}
          buttonText={i18n["myplanPopup.happyletsdothis"]}
          handleClose={() => setUnlockCV(false)}
          show={unlockCV}
          buttonClick={handleUnlockCV}
          gap={"18px"}
        />
      )}
      {isPlanBuyModal && (
        <CoinSpendBox
          text1={"Now you're coining it!"}
          text1Width={"290px"}
          text2={`This Plan Upgradation will cost R${planData?.crayon_plan?.costZAR}.`}
          coinsBalance={``}
          coinsUse={``}
          text2Width={"290px"}
          text3={
            "Crayon admin will review your purchase and update your account accordingly."
          }
          text3Width={"270px"}
          // text3Height={"72px"}
          text4={""}
          text4Width={"220px"}
          buttonText={"got it,thanks"}
          handleClose={() => setIsPlanBuyModal(false)}
          show={isPlanBuyModal}
          buttonClick={handlePlanBuy}
          gap={"18px"}
        />
      )}
    </>
  );
}
