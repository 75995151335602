import {
  Box,
  Button,
  Card,
  Grid,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import job_logo from "../../../assets/Crayon_Favicon.svg";
import job_volume from "../../../assets/Padding Excluded/Crayon Icon_Promote.svg";
import playButtonIconWhite from "../../../assets/Padding Excluded/Black_reactive_job.svg";
import job_exp from "../../../assets/Padding Included/Green_Duration.svg";
import BlueCurrency from "../../../assets/Blue_Salary.svg";
import redLocation from "../../../assets/Red_Location.svg";
import calendar from "../../../assets/Padding Included/Yellow_Calendar.svg";
import playButtonIcon from "../../../assets/Padding Excluded/Black_reactive_job copy.svg";
import trackIcon from "../../../assets/Padding Excluded/TrackIcon.svg";
import { renderColor } from "../../../utils/Common";
import {
  convertDatetimeAgo,
  dateConverterMonth,
} from "../../../utils/DateTime";
import favouriteGrey from "../../../assets/Crayon Favourite Icon_Grey.svg";
import favouriteRed from "../../../assets/Crayon Favourite Icon_Red.svg";
import MUIRadialChart from "../../common/MUIRadialChart";
import { formatCurrencyWithCommas } from "../../../utils/Currency";
import locale from "../../../i18n/locale";
import strongMatch from "../../../assets/CrayBotIcons/Crayon Craybot Notification-07.svg";
import JobPromoteCard from "../JobPromoteCard";

export default function SharedJobsCardFront({
  index,
  job,
  setisFlipped,
  handleReviewVideoDialog,
  cardWidth,
}) {
  const i18n = locale.en;
  const theme = useTheme();
  const [jobPromote, setJobPromote] = useState(false);
  const [isStar, setIsStarSelected] = useState(false);

  return (
    <Card
      sx={{
        borderRadius: "25px",
        position: "relative",
        boxShadow: "0px 5px 10px #00000029",
      }}
      width={cardWidth}
      className="cardboxdetail"
    >
      {!jobPromote && (
        <>
          <Grid
            container
            justifyContent="space-between"
            alignItems="start"
            sx={{
              borderRadius: "25px 25px 0 0",
              flexWrap: "nowrap",
            }}
          >
            <Link
              to={`/jobs/company/${job?.company_id}`}
              target={"_blank"}
              style={{
                textDecoration: "none",
                color: "black",
              }}
            >
              <Box
                component="img"
                sx={{
                  height: "60px",
                  width: "60px",
                  maxHeight: { xs: "60px" },
                  maxWidth: { xs: "60px" },
                  ml: "15px",
                  mt: "15px",
                  mb: "15px",
                  borderRadius: "10px",
                }}
                alt="job_logo"
                src={job_logo}
              />
            </Link>

            <Box
              sx={{
                height: "90px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  position: "relative",
                }}
              >
                <Button
                  color={
                    job?.job_type?.includes("Recruit")
                      ? "recruitButton"
                      : "directButton"
                  }
                  variant="contained"
                  sx={{
                    height: "auto",
                    minWidth: "60px",
                    minHeight: "45px",
                    borderRadius: "0 0 0 10px",
                    padding: 0,
                    fontSize: 12,
                    fontWeight: theme.typography.Bold,
                    cursor: "default",
                    ":hover": {
                      boxShadow: 0,
                    },
                  }}
                >
                  {job?.job_type}
                </Button>
                <Button
                  sx={{
                    height: "auto",
                    minWidth: "60px",
                    minHeight: "45px",
                    borderRadius: 0,
                    padding: 0,
                    fontSize: 12,
                    fontWeight: theme.typography.Bold,
                    background:
                      theme.manageTalent[renderColor(job?.stage_name)]?.main,
                    color: "white",
                    cursor: "default",
                    ":hover": {
                      background:
                        theme.manageTalent[renderColor(job?.stage_name)]?.main,
                    },
                  }}
                >
                  {job?.stage_name}
                </Button>
                <Button
                  color="jobAnnounceButton"
                  variant="contained"
                  sx={{
                    height: "45px",
                    width: "60px",
                    minWidth: "60px",
                    maxHeight: { xs: "60px" },
                    maxWidth: { xs: "60px" },
                    borderRadius: 0,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    ":hover": {
                      boxShadow: 0,
                    },
                  }}
                  onClick={() => setJobPromote(true)}
                >
                  <Box
                    component="img"
                    sx={{
                      height: 30,
                      width: 30,
                      maxHeight: { xs: 30 },
                      maxWidth: { xs: 30 },
                    }}
                    alt="job_volume"
                    src={job_volume}
                  />
                </Button>
                <Button
                  color="playButton"
                  variant="contained"
                  onClick={() =>
                    job?.job_video && handleReviewVideoDialog(true)
                  }
                  sx={{
                    height: "auto",
                    minWidth: "60px",
                    minHeight: "45px",
                    background:
                      job?.job_video === null && theme.palette.grayButton600.main,
                    borderRadius: 0,
                    borderTopRightRadius: "25px",
                    padding: 0,
                    ":hover": {
                      boxShadow: "none",
                    },
                  }}
                >
                  <Box
                    component={"img"}
                    src={
                      // job?.job_video !== null
                      //   ? playButtonIconWhite
                      //   : playButtonIcon
                      playButtonIconWhite
                    }
                    sx={{
                      height: "16px",
                      width: "20px",
                    }}
                  />
                </Button>
              </Box>
              <Typography
                sx={{
                  fontSize: "12px",
                  fontWeight: theme.typography.Regular,
                  letterSpacing: "0.75px",
                  opacity: 0.8,
                  paddingTop: "8px",
                }}
              >
                posted {convertDatetimeAgo(job?.created_at)} ago
              </Typography>
            </Box>
          </Grid>
          <Box
            sx={{
              display: "flex",
              width: "100%",
            }}
          >
            <Grid
              paddingTop={0}
              marginLeft={"15px"}
              marginRight={"20px"}
              paddingBottom={0}
              sx={{
                flexGrow: 1,
                display: "flex",
                flexDirection: "column",
                gap: "15px",
                width: `calc(100% - 40px)`,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  gap: "10px",
                  alignItems: "flex-end",
                  maxWidth: `calc(100% - 20px)`,
                }}
              >
                <Tooltip
                  disableInteractive
                  arrow
                  title={job?.title}
                  placement="top"
                >
                  <Link
                    to={
                      job?.post_incognito
                        ? `/jobs/${job?.country_name?.replace(
                            /\s|\//g,
                            ""
                          )}/${job?.town_name?.replace(
                            /\s|\//g,
                            ""
                          )}/${job?.title?.replace(/\s|\//g, "")}/${
                            job?.job_id
                          }`
                        : `/jobs/${job?.country_name?.replace(
                            /\s|\//g,
                            ""
                          )}/${job?.town_name?.replace(
                            /\s|\//g,
                            ""
                          )}/${job?.CompanyName?.replace(
                            /\s|\//g,
                            ""
                          )}/${job?.title?.replace(/\s|\//g, "")}/${
                            job?.job_id
                          }`
                    }
                    target={"_blank"}
                    style={{
                      textDecoration: "none",
                      color: theme.palette.black,
                      maxWidth: "96%",
                    }}
                  >
                    <Typography
                      sx={{
                        fontWeight: 700,
                        fontSize: 20,
                        overflow: "hidden",
                        lineHeight: 1,
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {job?.title}
                    </Typography>
                  </Link>
                </Tooltip>
                <Box
                  component={"img"}
                  src={isStar ? favouriteRed : favouriteGrey}
                  sx={{
                    height: "21px",
                    width: "21px",
                    cursor: "pointer",
                  }}
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "start",
                    gap: "10px",
                    height: "14px",
                  }}
                >
                  <Box
                    component="img"
                    sx={{
                      height: 16,
                      width: 15,
                      maxHeight: { xs: 16 },
                      maxWidth: { xs: 15 },
                    }}
                    alt="currency"
                    src={BlueCurrency}
                  />
                  <Typography
                    sx={{
                      fontWeight: 700,
                      fontSize: 14,
                      letterSpacing: "0.25px",
                      lineHeight: 1,
                    }}
                  >
                    {job?.salary_min === 0 ? (
                      <>
                        {job?.currency_symbol}
                        {formatCurrencyWithCommas(job?.salary_max)}
                        {job?.type === "freelance" ? " per hour" : " per month"}
                      </>
                    ) : job?.currency_symbol ||
                      job?.salary_min ||
                      job?.salary_max ? (
                      <>
                        {job?.currency_symbol}
                        {formatCurrencyWithCommas(job?.salary_min)} to{" "}
                        {job?.currency_symbol}
                        {formatCurrencyWithCommas(job?.salary_max)}{" "}
                        {job?.type === "freelance" ? "per hour" : "per month"}
                      </>
                    ) : (
                      "-"
                    )}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "start",
                    gap: "10px",
                    height: "14px",
                  }}
                >
                  <Box
                    component="img"
                    sx={{
                      height: 16,
                      width: 15,
                      maxHeight: { xs: 16 },
                      maxWidth: { xs: 15 },
                    }}
                    alt="location"
                    src={redLocation}
                  />
                  <Typography
                    sx={{
                      fontWeight: 700,
                      fontSize: 14,
                      letterSpacing: "0.25px",
                      lineHeight: 1,
                    }}
                  >
                    {`${job?.country_name} ${job?.town_name}`}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "start",
                    gap: "10px",
                    height: "14px",
                  }}
                >
                  <Box
                    component="img"
                    sx={{
                      height: 16,
                      width: 15,
                      maxHeight: { xs: 16 },
                      maxWidth: { xs: 15 },
                    }}
                    alt="job_exp"
                    src={job_exp}
                  />
                  <Typography
                    sx={{
                      fontWeight: 700,
                      fontSize: 14,
                      letterSpacing: "0.25px",
                      lineHeight: 1,
                    }}
                  >
                    {job?.experience_year_end || job?.experience_year_start ? (
                      <>
                        {job?.experience_year_start} to{" "}
                        {job?.experience_year_end} years
                      </>
                    ) : (
                      "-"
                    )}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "start",
                    gap: "10px",
                    height: "14px",
                  }}
                >
                  <Box
                    component="img"
                    sx={{
                      height: 16,
                      width: 14,
                      maxHeight: { xs: 16 },
                      maxWidth: { xs: 14 },
                    }}
                    alt="calendar"
                    src={calendar}
                  />
                  <Typography
                    sx={{
                      fontWeight: 700,
                      fontSize: 14,
                      letterSpacing: "0.25px",
                      lineHeight: 1,
                    }}
                  >
                    {job?.job_start_date
                      ? dateConverterMonth(job?.job_start_date)
                      : "-"}
                  </Typography>
                </Box>
              </Box>
              <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
                <Box
                  component={"img"}
                  src={strongMatch}
                  sx={{ width: 45, height: 75 }}
                />
                <Typography
                  sx={{
                    fontSize: "12px",
                    textAlign: "left",
                    color: "black",
                    fontWeight: theme?.typography?.Medium,
                  }}
                >
                  {i18n["promoter.text1"]}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  marginBottom: "10px",
                }}
              >
                <MUIRadialChart
                  value={job?.bounty_amount || 0}
                  chartName={"bounty"}
                  max={10000}
                  size={150}
                  countFontSize={20}
                  labelFontSize={15}
                  color={"strongMatch"}
                  formattedValue={`${job?.currency_symbol} ${
                    job?.bounty_amount || 0
                  }`}
                />
              </Box>
            </Grid>
          </Box>
          <Grid
            container
            flexWrap={"nowrap"}
            alignItems="center"
            overflow={"hidden"}
            sx={{
              width: "100%",
              borderRadius: "0 0 25px 25px",
              height: "60px",
            }}
          >
            <Button
              variant="contained"
              sx={{
                borderRadius: "0 0 25px 0",
                width: "100%",
                height: "100%",
                fontSize: "14px",
                fontWeight: "bold",
                ":hover": {
                  boxShadow: 0,
                },
                ".MuiButton-startIcon": {
                  marginRight: "0px !important",
                  marginLeft: 0,
                },
                padding: "0px !important",
                boxShadow: "none !important",
                gap: 1,
              }}
              color="basicColor"
              onClick={() => setisFlipped(true)}
              startIcon={
                <Box
                  component={"img"}
                  sx={{
                    height: 20,
                    width: 20,
                  }}
                  src={trackIcon}
                />
              }
            >
              {"track"}
            </Button>
          </Grid>
        </>
      )}
      {jobPromote && (
        <JobPromoteCard
          setJobPromote={setJobPromote}
          jobID={job?.job_id}
          job={job}
        />
      )}
    </Card>
  );
}
