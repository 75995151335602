import { useTheme } from "@emotion/react";
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import nextIcon from "../../../assets/Black_Right_Next.svg";
import { useDispatch } from "react-redux";
import {
  deleteCandidateAssesment,
  getAssessmentList,
  postAssessment,
  updateCandidateAssesment,
} from "../../../redux/candidate/myStatusFilter";
import { setLoading } from "../../../redux/configSlice";
import ReAssismentModal from "./ReAssismentModal";
import InitialModal from "./InitialModal";
import { useNavigate } from "react-router-dom";
import { setCurrentStep } from "../../../redux/candidate/myProfileSlice";
import { styled } from "@mui/material/styles";
import { getDecodedToken, getToken } from "../../../utils/Common";
import takeQuiz from "../../../assets/TakeQuizIcon.svg";

const GoodStart = ({ handleChangeStep }) => {
  const theme = useTheme();
  const decodedToken = getDecodedToken(getToken());

  const [assessmentList, setAssessmentList] = useState();
  const [radioInputLabel, setRadioInputLabel] = useState();
  const [apiData, setApiData] = useState();
  const dispatch = useDispatch();
  const [isInitialModal, setIsInitialModal] = useState(false);
  const navigate = useNavigate();
  const [isDoModal, setIsDoModal] = useState(false);
  const getList = async () => {
    dispatch(setLoading(true));

    let payload = {
      lastKey: 0,
    };
    try {
      let response = await dispatch(getAssessmentList(payload));
      if (response?.payload?.data?.status === "success") {
        setAssessmentList(response?.payload?.data?.data?.questions);
        setRadioInputLabel(response?.payload?.data?.data?.scores);
        setApiData(response?.payload?.data);
      }
      dispatch(setLoading(false));
    } catch (error) {
      dispatch(setLoading(false));
    }
  };
  const saveAssesment = async (payload) => {
    dispatch(setLoading(true));
    try {
      let response = await dispatch(postAssessment(payload));
      if (response?.payload?.data?.status === "success") {
        handleChangeStep(1);
      }
      dispatch(setLoading(false));
    } catch (error) {
      dispatch(setLoading(false));
    }
  };
  const handleSaveButton = () => {
    const payload = {
      scoreArray: assessmentList?.map((item) => ({
        question_id: item?.question_id,
        score_id: item?.score_id,
        value: item?.value,
      })),
    };
    if (apiData?.data?.statusFlag === false) {
      saveAssesment(payload);
    } else {
      handleChangeStep(1);
    }
    if (apiData?.data?.editFlag) {
      handleChangeStep(1);
    }
  };
  const handleChange = (item, scoreItem) => {
    let updatedList = [...assessmentList];
    updatedList = updatedList?.map((list) => {
      if (list?.question_id === item?.question_id) {
        return {
          ...list,
          value: scoreItem?.value,
          score_id: scoreItem?.score_id,
        };
      } else {
        return list;
      }
    });
    setAssessmentList(updatedList);
    if (
      apiData?.data?.statusFlag === true &&
      apiData?.data?.editFlag === false
    ) {
      handleSingleUpdate(item?.question_id, scoreItem);
    }
  };
  const handleSingleUpdate = async (question_id, scoreItem) => {
    let payload = {
      question_id: question_id,
      score_id: scoreItem?.score_id,
      value: scoreItem?.value,
    };
    try {
      let response = await dispatch(updateCandidateAssesment(payload));
      if (response?.payload?.data?.status === "success") {
      }
    } catch (error) {}
  };
  const handleSubmitReDo = async () => {
    try {
      let response = await dispatch(deleteCandidateAssesment());
      if (response?.payload?.data?.status === "success") {
        getList();
        setIsDoModal(false);
        handleChangeStep(0);
      }
    } catch (error) {
      console.log(error, "error");
    }
  };
  const handleCloseReDo = () => {
    setIsDoModal(false);
  };
  useEffect(() => {
    getList();
  }, []);

  useEffect(() => {
    if (apiData?.data?.editFlag === true) {
      setIsDoModal(true);
    }
    if (
      apiData?.data?.editFlag === false &&
      apiData?.data?.statusFlag === false
    ) {
      setIsInitialModal(true);
    }
  }, [apiData]);

  const handleSubmitInitialModal = () => {
    setIsInitialModal(false);
  };
  const handleCloseInitialModal = () => {
    if (decodedToken?.data?.role_id === 3) {
      navigate("/candidate/my-profile");
      dispatch(setCurrentStep(1));
    }
    if (decodedToken?.data?.role_id === 4) {
      //emplyer
      navigate("/employer/my-profile");
    }
    if (decodedToken?.data?.role_id === 6) {
      //recruiter
      navigate("/recruiter/my-profile");
    }
    if (decodedToken?.data?.role_id === 5) {
      //promoter
      navigate("/promoter/my-profile");
    }

    setIsInitialModal(false);
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          width: "100%",
          justifyContent: "space-between",
          alignItems: "baseline",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", md24: "row" },
          }}
        >
          <Box>
            <Button
              variant="contained"
              color="precium"
              sx={{
                minHeight: "45px",
                minWidth: { xs: "", md24: "150px" },
                padding: { xs: "6px 18px", md24: "6px 16px" },
                borderRadius: "25px 0 10px 0",
                fontSize:"14px",
                textWrap: "nowrap",
                boxShadow: "none",
                "& .MuiButton-startIcon": {
                  marginLeft: "0px",
                },
              }}
              startIcon={
                <Box component={"img"} src={takeQuiz} height={20} width={20} />
              }
            >
              take a quiz
            </Button>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", md24: "row" },
          }}
        >
          <Box>
            <Button
              variant="contained"
              color="precium"
              sx={{
                minHeight: "45px",
                minWidth: { xs: "", md24: "60px" },
                maxWidth: { xs: "", md24: "60px" },
                // padding: { xs: "6px 18px", md24: "6px 16px" },
                borderRadius: "0 25px 0 10px",
                textWrap: "nowrap",
                fontSize:"14px",
                boxShadow: "none",
                "& .MuiButton-startIcon": {
                  marginLeft: "0px",
                },
              }}
            >
              1-20
            </Button>
          </Box>
        </Box>
      </Box>
      <Box sx={{ padding: "25px" }}>
        <Typography
          sx={{
            fontSize: "14px",
            fontWeight: theme.typography.Bold,
            marginTop: "22px",
          }}
        >
          Select the answer per question that best describes you in the
          workplace. Good luck, take your time, and have fun!
        </Typography>
        <Box sx={{ padding: "25px", minHeight: "80vh" }}>
          {assessmentList?.map((item, index) => {
            return (
              <Box sx={{ width: "90%", marginBottom: "10px" }}>
                <Box>
                  <Typography
                    sx={{
                      fontWeight: theme?.typography?.Regular,
                      fontSize: "14px",
                    }}
                  >
                    {item?.question_id}
                    {". "}
                    {item?.question}
                  </Typography>
                </Box>
                <Box>
                  <FormControl sx={{ display: "flex", flexDirection: "row" }}>
                    {radioInputLabel?.map((rItem, index) => {
                      return (
                        <>
                          <RadioGroup
                            row
                            aria-labelledby="demo-radio-buttons-group-label"
                            name="radio-buttons-group"
                            value={item?.score_id}
                            onChange={(e) => {
                              handleChange(item, rItem);
                            }}
                          >
                            <FormControlLabel
                              disabled={apiData?.data?.editFlag}
                              labelPlacement="bottom"
                              value={rItem?.score_id}
                              control={<Radio />}
                              label={rItem?.name}
                              sx={{
                                fontWeight: theme.typography.Bold,
                                "& .MuiFormControlLabel-label": {
                                  fontWeight: theme.typography.Bold,
                                  fontSize: "12px",
                                },
                                "& . MuiButtonBase-root-MuiRadio-root": {
                                  padding: "0px",
                                },
                                "& .MuiRadio-root": {
                                  padding: "0px",
                                  marginTop: "5px",
                                },
                              }}
                            />
                          </RadioGroup>
                        </>
                      );
                    })}
                  </FormControl>
                </Box>
              </Box>
            );
          })}
        </Box>
      </Box>
      <Box sx={{ padding: "0px 32px 0px 32px !important" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            pt: 5,
          }}
        >
          <Button
            onClick={() => {
              handleSaveButton("goodStart");
            }}
            disabled={assessmentList?.some((item) => item?.score_id === null)}
            sx={{
              boxShadow: 0,
              padding: "0px",
              fontSize: "14px",
              width: "150px",
              height: "60px",
              borderRadius: "10px 10px 0px 0px",
              // backgroundColor: theme.palette.lockItIn.main,
              // color: "black",
              // "&:hover": {
              //   backgroundColor: theme.palette.lockItIn.main,
              // },
            }}
            color="applyButton"
            endIcon={
              <Box
                component={"img"}
                src={nextIcon}
                sx={{
                  height: "25px",
                  width: "25px",
                }}
              />
            }
            variant="contained"
          >
            keep it up
          </Button>
        </Box>
      </Box>
      {isDoModal && (
        <ReAssismentModal
          handleOpen={isDoModal}
          handleClose={handleCloseReDo}
          handleSubmit={handleSubmitReDo}
        />
      )}
      {isInitialModal && (
        <InitialModal
          handleOpen={isInitialModal}
          handleClose={handleCloseInitialModal}
          handleSubmit={handleSubmitInitialModal}
        />
      )}
    </>
  );
};

export default GoodStart;
