import React, { useState, useMemo } from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import Switch from "@mui/material/Switch";
import Button from "@mui/material/Button";
import DeleteIcon from "@mui/icons-material/Delete";
import { visuallyHidden } from "@mui/utils";
import { styled, alpha } from "@mui/material/styles";
import CustomDialog from "../CustomDialog";
import CircleIcon from "@mui/icons-material/Circle";
import { useDispatch } from "react-redux";
import { dateConverterMonth } from "../../../utils/DateTime";
import { assignJobs, removeMembersJobs } from "../../../redux/employer/myTeams";
import { setAlert } from "../../../redux/configSlice";
import { ALERT_TYPE, HEADCELLS } from "../../../utils/Constants";
import { useParams } from "react-router-dom";
import { getComparator, stableSort } from "../../../utils/Common";
import { GreenButtonSwitch } from "../../../utils/CommonComponent";
import locale from "../../../i18n/locale";
import Delete from "../../candidate/myCam/dialog/Delete";

export default function TeamsDataTable({
  rows,
  openDelete,
  setOpenDelete,
  setDeleted,
}) {
  const i18n = locale.en;
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("name");
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [dialogText, setDialogText] = useState("");
  const { id } = useParams();
  const dispatch = useDispatch();

  const handleOpenDelete = (name) => {
    setOpenDelete((prevState) => !prevState);
    setDialogText(name);
  };
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event?.target?.checked) {
      const newSelected = rows?.map((n) => n?.job_id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected?.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected?.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected?.concat(selected?.slice(1));
    } else if (selectedIndex === selected?.length - 1) {
      newSelected = newSelected?.concat(selected?.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected?.concat(
        selected?.slice(0, selectedIndex),
        selected?.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event?.target?.value, 10));
    setPage(0);
  };

  const handleJobAccess = async (event, job_id) => {
    try {
      const data = {
        userid: id,
        jobid: job_id,
        flag: event?.target?.checked,
      };
      const { payload } = await dispatch(assignJobs(data));
      if (payload?.status === "success") {
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE?.SUCCESS,
            msg: i18n["alertMessage.Jobaccessremovedsuccessfully"],
          })
        );
      } else {
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE?.SUCCESS,
            msg: payload?.error,
          })
        );
      }
    } catch (error) {
      dispatch(
        setAlert({
          show: true,
          type: ALERT_TYPE?.SUCCESS,
          msg: error,
        })
      );
    }
  };

  const isSelected = (name) => selected?.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows?.length) : 0;

  const visibleRows = useMemo(
    () =>
      stableSort(rows, getComparator(order, orderBy))?.slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      ),
    [order, orderBy, page, rowsPerPage, rows]
  );

  const handleDeleteMember = async () => {
    try {
      const data = {
        jobids: selected,
      };
      const { payload } = await dispatch(removeMembersJobs(data));
      if (payload?.status === "success") {
        setOpenDelete(false);
        setDeleted(payload?.data);
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE?.SUCCESS,
            msg: i18n["alertMessage.Memberremoved"],
          })
        );
      } else {
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE?.ERROR,
            msg: payload?.message,
          })
        );
      }
    } catch (error) {
      dispatch(
        setAlert({
          show: true,
          type: ALERT_TYPE?.ERROR,
          msg: error,
        })
      );
    }
  };

  const EnhancedTableHead = (props) => {
    const {
      onSelectAllClick,
      order,
      orderBy,
      numSelected,
      rowCount,
      onRequestSort,
    } = props;
    const createSortHandler = (property) => (event) => {
      onRequestSort(event, property);
    };

    return (
      <TableHead>
        <TableRow>
          <TableCell padding="checkbox">
            <Checkbox
              color="primary"
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{
                "aria-label": "select all rows",
              }}
            />
          </TableCell>
          {HEADCELLS?.map((headCell) => (
            <TableCell
              key={headCell?.id}
              align={"left"}
              padding={headCell?.disablePadding ? "none" : "normal"}
              sortDirection={orderBy === headCell?.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell?.id}
                direction={orderBy === headCell?.id ? order : "asc"}
                onClick={createSortHandler(headCell?.id)}
              >
                {headCell?.label}
                {orderBy === headCell?.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  };

  EnhancedTableHead.propTypes = {
    numSelected: PropTypes?.number?.isRequired,
    onRequestSort: PropTypes?.func?.isRequired,
    onSelectAllClick: PropTypes?.func?.isRequired,
    order: PropTypes?.oneOf(["asc", "desc"])?.isRequired,
    orderBy: PropTypes?.string?.isRequired,
    rowCount: PropTypes?.number?.isRequired,
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Paper sx={{ width: "100%" }}>
        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={"medium"}
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows?.length}
            />
            <TableBody>
              {visibleRows?.map((row, index) => {
                const isItemSelected = isSelected(row?.job_id);
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <TableRow
                    hover
                    onClick={(event) => handleClick(event, row?.job_id)}
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row?.job_id}
                    selected={isItemSelected}
                    sx={{ cursor: "pointer" }}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        color="primary"
                        checked={isItemSelected}
                        inputProps={{
                          "aria-labelledby": labelId,
                        }}
                      />
                    </TableCell>
                    <TableCell
                      width={"20%"}
                      component="th"
                      id={labelId}
                      scope="row"
                      padding="none"
                    >
                      {/*<NameInfo
                        name={row.title}
                        email={row.email}
                        user_id={row.user_id}
                      />*/}
                      {row?.title}
                    </TableCell>

                    <TableCell>
                      <div>
                        {row?.status === "Active" ? (
                          // <ActiveChip label="Active"/>
                          <Button
                            startIcon={<CircleIcon />}
                            size="small"
                            variant="outlined"
                            sx={{
                              padding: "0 8px 0 5px",
                              color: "green",
                              borderRadius: "8px",
                              border: "1px solid #009700",
                              height: 1,
                              fontSize: "14px",
                              fontWeight: "bold",
                              background: "#defbde",
                            }}
                          >
                            Active{" "}
                          </Button>
                        ) : (
                          <Button
                            startIcon={
                              <CircleIcon
                                sx={{
                                  fontSize: "small",
                                }}
                              />
                            }
                            size="small"
                            variant="contained"
                            color="grayButton200"
                            sx={{
                              padding: "0 5px",
                              color: "gray",
                              borderRadius: "8px",
                              height: 1,
                              fontSize: "14px",
                              fontWeight: "bold",
                            }}
                          >
                            Offline
                          </Button>
                        )}
                      </div>
                    </TableCell>
                    <TableCell>{dateConverterMonth(row?.dateAdded)}</TableCell>
                    <TableCell>{dateConverterMonth(row?.lastActive)}</TableCell>
                    <TableCell>
                      <Button
                        variant="contained"
                        color="grayButton200"
                        sx={{
                          borderRadius: "8px",
                          width: "200px",
                          height: 1,
                          justifyContent: "start !important",
                        }}
                        // onClick={handleAddNewMemberClick}
                      >
                        {row?.permissions}
                      </Button>
                    </TableCell>
                    <TableCell>
                      <Box
                        sx={{
                          display: "flex",
                          gap: 2,
                        }}
                      >
                        {row?.assigned ? (
                          <GreenButtonSwitch
                            defaultChecked={row?.assigned}
                            onChange={(event) =>
                              handleJobAccess(event, row?.job_id)
                            }
                          />
                        ) : (
                          <DeleteIcon
                            color="blueButton700"
                            onClick={() => handleOpenDelete(row?.title)}
                          />
                        )}
                      </Box>
                    </TableCell>
                  </TableRow>
                );
              })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: emptyRows,
                  }}
                >
                  <TableCell colSpan={7} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows?.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      <Delete
        show={openDelete}
        handleOpen={handleOpenDelete}
        handleCancel={handleOpenDelete}
        handleDelete={handleDeleteMember}
        confirmDelete={confirmDelete}
        setConfirmDelete={setConfirmDelete}
        dialogText={dialogText}
      />
    </Box>
  );
}
