import React from "react";
import { Box, Button, Modal, Typography, useTheme } from "@mui/material";
import blueHalfSmile from "../../../assets/CrayBotIcons/Crayon Craybot Notification-07.svg";
import locale from "../../../i18n/locale";
import CloseIcon from "../CloseIcon";

export default function UploadSpec({
  show,
  handleClose,
  setOpenSpecBox,
  handleFileClick,
  changeStep,
  handleSaveButton,
}) {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    height: "406px",
    width: { xs: "360px", tablet: "450px" },
    bgcolor: "background.paper",
    borderRadius: "25px",
    // pyb: 4,
    boxShadow: "none !important",
    // border: "1px solid black !important",
    "&:focus-visible": {
      outline: "none",
    },
  };
  const theme = useTheme();
  const i18n = locale.en;

  return (
    <Modal
      open={show}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <CloseIcon top="20px" right="20px" onClick={handleClose} />
        <Box
          sx={{
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            // gap: 3,
          }}
        >
          <Box
            component={"img"}
            src={blueHalfSmile}
            sx={{ width: 117, height: 125, marginTop: "25px" }}
          />
          <Typography
            sx={{
              fontSize: theme.typography.fontSize.xl,
              fontWeight: theme.typography.Bold,
              width: "326px",
              marginTop: "20px",
            }}
          >
            {i18n["postAJob.whatthismeanstome"]}
          </Typography>
          <Typography
            sx={{
              fontSize: theme.typography.fontSize.xl,
              fontWeight: theme.typography.Bold,
              width: "326px",
            }}
          >
            {i18n["postAJob.findout"]}
          </Typography>
          <Typography
            sx={{
              fontSize: theme.typography.fontSize.base,
              fontWeight: theme.typography.Bold,
              width: "326px",
              marginTop: "20px",
            }}
          >
            {i18n["postAJob.attachyouroriginalspec"]}
          </Typography>
          <Box sx={{ marginBottom: "20px" }}>
            <Typography
              sx={{
                fontSize: theme.typography.fontSize.sm,
                fontWeight: theme.typography.Regular,
                marginTop: "20px",
                width: "302px",
              }}
            >
              {i18n["postAJob.additionalinfo"]}
            </Typography>
          </Box>
        </Box>
        <Box>
          <Button
            variant="contained"
            color="submitButton"
            sx={{
              borderRadius: "0 0 0 25px",
              width: "50%",
              height: "57px",
              color: "black",
              fontSize: theme.typography.fontSize.sm,
              fontWeight: theme.typography.Bold,
            }}
            onClick={() => {
              setOpenSpecBox(false);
              // handleSaveButton("workLife", true);
            }}
          >
            no thanks
          </Button>
          <Button
            variant="contained"
            sx={{
              borderRadius: "0 0 25px 0",
              width: "50%",
              height: "57px",
              fontSize: theme.typography.fontSize.sm,
              fontWeight: theme.typography.Bold,
            }}
            color="talentButton"
            onClick={() => {
              handleFileClick();
            }}
          >
            upload spec
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}
