import React from "react";
import { Box, Typography, Button, Divider } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import TeamsDataTable from "../../common/myTeamSection/TeamsDataTable";
import SmallButton from "../../common/SmallButton";
import {
  getAllTeamMembers,
  getFilteredMember,
} from "../../../redux/employer/myTeams";
import { useDispatch } from "react-redux";
import { setAlert } from "../../../redux/configSlice";
import { ALERT_TYPE, ERROR_MSG } from "../../../utils/Constants";
import { useEffect } from "react";
import { useState } from "react";
import { nanoid } from "@reduxjs/toolkit";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { useSelector } from "react-redux";
import MemberInfo from "../../common/myTeamSection/MemberInfo";
import CustomDialog from "../../common/CustomDialog";
import ProfileNotCompleted from "../../common/ProfileNotCompleted";
import locale from "../../../i18n/locale";
import ApproveTeamMemberTable from "./ApproveTeamMemberTable";
import { talentListingPageTheme } from "../../../utils/Theme";

function createData(
  id,
  userId,
  user,
  Status,
  dateAdded,
  lastActive,
  permissions,
  isSuperAdmin,
  licenseFlag,
  license_id,
  role_type_id
) {
  return {
    id,
    userId,
    name: {
      fullname: `${user?.first_name} ${user?.last_name}`,
      email: user?.email,
      avatarInitial: `${user?.first_name?.[0]}${user?.last_name?.[0]}`,
    },
    Status,
    dateAdded,
    lastActive,
    permissions,
    isSuperAdmin,
    licenseFlag: licenseFlag ? licenseFlag : false,
    license_id: license_id ? license_id : "",
    role_type_id: role_type_id ? role_type_id : "",
  };
}
const TeamTable = () => {
  const i18n = locale.en;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const profileCompletionNumber = 50;
  const selectedFilter = useSelector((state) => state?.filters?.selectedFilter);
  const profilePercentage = useSelector(
    (state) => state.employerjoblisting.profilePercentage
  );
  const params = useLocation();

  useEffect(() => {
    if (params?.state?.value === "waiting") {
      setIsShowApproval(true);
      // navigate(params.pathname, { replace: true, state: {} });
    }
  }, [params?.state?.value]);
  const inviteStatusFilter = useSelector(
    (state) => state?.filters?.inviteStatusFilter
  );
  const [showNoInfoBox, setShowNoInfoBox] = useState(false);
  const [rows, setRows] = useState([]);
  const [companyName, setCompanyName] = useState("");
  const [deleted, setDeleted] = useState(0);
  const [role, setRole] = useState(0);
  const [isShowApproval, setIsShowApproval] = useState(false);
  const [superAdminFlag, setSuperAdminFlag] = useState(false);
  const [companyId, setCompanyId] = useState();
  const [companyData, setCompanyData] = useState();
  const handleAddNewMemberClick = () => {
    navigate("add-new-member", { state: { companyname: companyName } });
  };
  const [talentListingDynamicTheme, setTalentListingDynamicTheme] = useState(
    talentListingPageTheme
  );

  const getTeamsMember = async () => {
    try {
      const { payload } = await dispatch(getAllTeamMembers({ job_id: "" }));
      if (payload?.status === "success") {
        setCompanyData(payload);
        setCompanyId(payload?.companyId);
        setCompanyName(payload?.companyName);
        setRows(
          payload?.data?.map((team) => {
            return createData(
              nanoid(),
              team?.user_id,
              team?.user,
              team?.user?.is_Logged_in,
              team?.created_at,
              team?.user?.last_login_at,
              team?.employer_role_type !== null &&
                team?.employer_role_type?.name,
              team?.isSuperAdminFlag,
              team?.licenseFlag,
              team?.license_id,
              team?.role_type_id
            );
          })
        );
        setSuperAdminFlag(payload?.isSuperAdminFlag);
        setRole(payload?.roletypeId);
      } else {
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE?.ERROR,
            msg: payload?.message,
          })
        );
      }
    } catch (error) {
      dispatch(
        setAlert({
          show: true,
          type: ALERT_TYPE?.ERROR,
          msg: ERROR_MSG,
        })
      );
    }
  };
  const filteredMember = async () => {
    const { payload } = await dispatch(
      getFilteredMember({
        job_id: "",
        role_type: selectedFilter?.[0] === 1111 ? "" : selectedFilter,
        inviteStatusId:
          inviteStatusFilter?.[0] === 1111 ? "" : inviteStatusFilter,
      })
    );
    if (payload?.status === "success") {
      setCompanyData(payload);
      setRows([]);
      setCompanyName(payload?.companyName);
      setRows(
        payload?.data?.map((team) => {
          return createData(
            nanoid(),
            team?.user_id,
            team?.user,
            team?.user?.is_Logged_in,
            team?.created_at,
            team?.user?.last_login_at,
            team?.employer_role_type !== null && team?.employer_role_type?.name,
            team?.isSuperAdminFlag,
            team?.licenseFlag,
            team?.license_id,
            team?.role_type_id
          );
        })
      );
      setSuperAdminFlag(payload?.isSuperAdminFlag);
      setRole(payload?.roletypeId);
    }
  };

  useEffect(() => {
    if (
      (inviteStatusFilter?.length > 0 && !selectedFilter?.includes(6)) ||
      (selectedFilter?.length > 0 && !selectedFilter?.includes(6))
    ) {
      filteredMember();
    }
  }, [selectedFilter, inviteStatusFilter]);

  useEffect(() => {
    getTeamsMember();
  }, [deleted !== 0]);

  const getProfilePercentageData = () => {
    if (
      profilePercentage?.myInfo + profilePercentage?.companyInfo <
      profileCompletionNumber
    ) {
      setShowNoInfoBox(true);
    } else {
      setShowNoInfoBox(false);
    }
    // setProfilePercentage(payload?.data);
  };
  useEffect(() => {
    Object?.keys(profilePercentage)?.length !== 0 && getProfilePercentageData();
  }, [profilePercentage]);

  const handleApproval = () => {
    setIsShowApproval((prev) => !prev);
  };

  return (
    <Box
      sx={{
        boxShadow: 2,
        borderRadius: "15px",
        backgroundColor: "#ffff",
        // marginTop: "109px",
        // margin: "55px 24px 24px 24px",
        display: "flex",
        flexGrow: 1,
        flexDirection: "column",
        justifyContent: "space-between",
        width: "100%",
        // width: "57vh",
      }}
    >
      <Box
        className="HeaderSection"
        sx={{
          p: { xs: "10px", md: "24px 54px 0 54px" },
        }}
      >
        {role === 1 || role === 2 ? (
          <>
            <Box
              sx={{
                display: "flex",
                gap: 2,
                alignItems: "center",
              }}
            >
              <Typography
                sx={{
                  fontSize: "20px",
                  fontWeight: 700,
                }}
              >
                {companyName}
              </Typography>
              <SmallButton
                backgroundColor={"lightGray"}
                color={"black"}
                m={0}
                label={rows?.length}
              />
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Typography variant="p" sx={{ marginRight: "5px" }}>
                  Total License
                </Typography>
                <SmallButton
                  backgroundColor={"lightGray"}
                  color={"black"}
                  m={0}
                  label={companyData?.totalLicense}
                />
              </Box>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Typography variant="p" sx={{ marginRight: "5px" }}>
                  Used License
                </Typography>
                <SmallButton
                  backgroundColor={"lightGray"}
                  color={"black"}
                  m={0}
                  label={companyData?.usedLicense}
                />
              </Box>
            </Box>

            <Box>
              <Typography variant="p">
                {i18n["empMyTeam.managePermission"]}
              </Typography>
            </Box>
            <Box
              sx={{
                margin: "30px 0",
                display: "flex",
                flexDirection: { xs: "column", md: "row" },
                gap: 2,
              }}
            >
              <Button
                variant="contained"
                color="redButton"
                onClick={handleAddNewMemberClick}
              >
                {i18n["empMyTeam.addNewMember"]}
              </Button>
              <Button
                variant="contained"
                color="grayButton200"
                startIcon={<ArrowDownwardIcon />}
              >
                {i18n["empMyTeam.downloadCsv"]}
              </Button>

              <Button
                variant="contained"
                // color="grayButton200"
                // startIcon={<ArrowDownwardIcon />}
                onClick={handleApproval}
              >
                {isShowApproval
                  ? "Team list"
                  : `Waiting Approval List (${
                      companyData?.totalApprovalRequest === null ||
                      companyData?.totalApprovalRequest === 0
                        ? 0
                        : companyData?.totalApprovalRequest
                    })`}
              </Button>
            </Box>
          </>
        ) : null}
      </Box>
      {isShowApproval ? (
        <ApproveTeamMemberTable
          companyId={companyId}
          getTeamsMember={getTeamsMember}
        />
      ) : role !== null ? (
        <>
          <Divider />
          <TeamsDataTable
            rows={rows}
            setDeleted={setDeleted}
            role={role}
            superAdminFlag={superAdminFlag}
            companyId={companyId}
            filteredMember={filteredMember}
          />
        </>
      ) : (
        <>
          <MemberInfo rows={rows} role={role} />
          {showNoInfoBox && (
            <CustomDialog
              show={true}
              hideButton={false}
              dialogWidth="xs"
              showFooter={false}
              padding={0}
            >
              <ProfileNotCompleted />
            </CustomDialog>
          )}
        </>
      )}
      {/* {} */}
    </Box>
  );
};
export default TeamTable;
