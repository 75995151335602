import React, { useCallback, useEffect, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  InputBase,
  Paper,
  Tooltip,
  Typography,
} from "@mui/material";
import { useTheme } from "@emotion/react";
import SelectMenu from "../../../common/SelectMenu";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  followCompany,
  unFollowCompany,
} from "../../../../redux/candidate/myProfileSlice";

import { setAlert, setLoading } from "../../../../redux/configSlice";
import { ALERT_TYPE, ERROR_MSG } from "../../../../utils/Constants";
import { getCandidateCompanies } from "../../../../redux/employer/empProfileSlice";
import { getIndustries } from "../../../../redux/candidate/myCvSlice";
import cancel from "../../../../assets/CircularIcon/Red/Circular Icons__Red_Close_2.svg";
import logo from "../../../../assets/Crayon_Favicon.svg";
import TalentSVGButton from "../../../common/TalentSVGButton";
import { CheckCircle } from "@mui/icons-material";
import { debounce } from "../../../../utils/Common";
import locale from "../../../../i18n/locale";
import { Link } from "react-router-dom";

export default function FollowCompany({
  followedRef,
  handleClose,
  setAnchorEl,
  setAnchorElCompanyDialog,
  getProfileData,
}) {
  const dispatch = useDispatch();
  const i18n = locale.en;
  const theme = useTheme();

  const { industries } = useSelector((state) => state?.myCv);
  const { candiateCompanies } = useSelector((state) => state?.myProfile);

  const [company, setCompany] = useState([]);
  const [showLoading, setShowLoading] = useState(true);
  const [searchText, setSearchText] = useState("");
  const [industrySearch, setIndustrySearch] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const getAllData = async (para) => {
    try {
      switch (para) {
        case "industry":
          await dispatch(getIndustries());
          return;
        case "companies":
          const company = await dispatch(
            getCandidateCompanies({
              industry_id: industrySearch,
              title: searchText,
            })
          );
          setCompany(company?.payload?.data);
          return;
        default:
          return;
      }
    } catch (error) {
      dispatch(setLoading(false));
      dispatch(
        setAlert({
          show: true,
          type: ALERT_TYPE?.ERROR,
          msg: ERROR_MSG,
        })
      );
    }
  };

  const followComp = async (compID) => {
    setCompany((prev) =>
      prev?.map((item) =>
        item?.company_id === compID ? { ...item, Followed_flag: true } : item
      )
    );

    try {
      const data = {
        company_id: compID,
      };
      const { payload } = await dispatch(followCompany(data));
      if (payload?.status === "success") {
        setCompany((prev) =>
          prev?.map((item) =>
            item?.company_id === compID
              ? { ...item, Followed_flag: true }
              : item
          )
        );
      }
      dispatch(
        setAlert({
          show: true,
          type: ALERT_TYPE?.SUCCESS,
          msg: i18n["alertMessage.Companyfollowed"],
        })
      );
      const company = await dispatch(
        getCandidateCompanies({
          industry_id: industrySearch,
          title: searchText,
        })
      );
      setCompany(company?.payload?.data);
      await getProfileData();
    } catch (error) {
      dispatch(
        setAlert({
          show: true,
          type: ALERT_TYPE?.ERROR,
          msg: ERROR_MSG,
        })
      );
    }
  };

  const handleUnfollow = async (compID) => {
    setCompany((prev) =>
      prev?.map((item) =>
        item?.company_id === compID ? { ...item, Followed_flag: false } : item
      )
    );
    try {
      const { payload } = await dispatch(unFollowCompany(compID));
      if (payload?.status === "success") {
        const company = await dispatch(
          getCandidateCompanies({
            industry_id: industrySearch,
            title: searchText,
          })
        );
        setCompany(company?.payload?.data);
        setCompany((prev) =>
          prev?.map((item) =>
            item?.company_id === compID
              ? { ...item, Followed_flag: false }
              : item
          )
        );
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE?.SUCCESS,
            msg: i18n["alertMessage.Companyunfollowed"],
          })
        );
        await getProfileData();
      }
    } catch (error) {
      dispatch(
        setAlert({
          show: true,
          type: ALERT_TYPE?.ERROR,
          msg: ERROR_MSG,
        })
      );
    }
  };

  const handleIndustry = async (event) => {
    const {
      target: { value },
      target: { name },
    } = event;
    setIndustrySearch(value);
    const company = await dispatch(
      getCandidateCompanies({ industry_id: value, title: searchText })
    );
    if (company?.payload?.data?.length === 0) {
      setShowLoading(false);
    }
    setCompany(company?.payload?.data);
  };

  const getCompanies = async (industry, title) => {
    try {
      let data = {
        industry_id: industry,
        title: title,
      };
      const { payload } = await dispatch(getCandidateCompanies(data));
      if (payload?.status === "success") {
        if (payload?.data?.length === 0) {
          setShowLoading(false);
        }
        setCompany(payload?.data);
      }
    } catch (error) {}
  };

  const debouncedHandleOnTextSearch = useCallback(
    debounce(
      async (newValue, industry) => {
        if (!newValue) return; // Early return
        try {
          const response = await getCompanies(
            industry,
            newValue?.target?.value
          );

          if (response && response?.payload) {
            const { payload } = response;
            dispatch(
              payload?.status === "success"
                ? setCompany(payload?.data)
                : setAlert({
                    show: true,
                    type: ALERT_TYPE?.ERROR,
                    msg: ERROR_MSG,
                  })
            );
          }
        } catch (error) {
          dispatch(
            setAlert({ show: true, type: ALERT_TYPE?.ERROR, msg: ERROR_MSG })
          );
        }
      },
      500, // Adjust delay as needed
      setIsLoading
    ),
    [dispatch, setCompany, setIsLoading]
  );

  const handleSearch = async (event) => {
    setSearchText(event?.target?.value);
    debouncedHandleOnTextSearch(event, industrySearch);
  };

  useEffect(() => {
    getAllData("companies");
  }, []);

  const handleClick = (event) => {
    setAnchorEl(followedRef?.current);
    setAnchorElCompanyDialog(null);
  };

  return (
    <>
      <Box
        sx={{
          padding: "19px 15px 0px 15px !important",
        }}
      >
        <Box sx={{ display: "flex", gap: "10px", flexDirection: "column" }}>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography
              sx={{
                fontSize: "16px",
                fontWeight: theme.typography.Medium,
                letterSpacing: "0.32px",
              }}
            >
              Follow a company
            </Typography>
            <IconButton
              aria-label="close"
              onClick={() => {
                handleClose();
              }}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
              disableRipple="true"
            >
              <Box
                sx={{
                  width: "20px",
                  height: "20px",
                  color: "#000000",
                  border: 2,
                  fontSize: "18px",
                  borderRadius: "5px",
                }}
              >
                X
              </Box>
            </IconButton>
          </Box>
          <Typography
            sx={{
              fontSize: "12px",
              fontWeight: theme.typography.Medium,
              // mt: "10px",
              width: "307px",
            }}
          >
            Stay up to date with new jobs and other info by following a company.
          </Typography>
          <Paper
            elevation={0}
            component="form"
            sx={{
              padding: "0px 10px",
              border: "1px solid #C9CAD8",
            }}
            height={"40px"}
            width={"308px"}
          >
            <InputBase
              sx={{
                fontWeight: theme.typography.Regular,
                width: "308px",
              }}
              placeholder={"Search for a company to follow"}
              // value={searchText}
              onChange={(event) => {
                handleSearch(event);
              }}
            />
          </Paper>
          <SelectMenu
            clear={true}
            border="1px solid #C9CAD8"
            borderRadius="10px"
            height="40px"
            width={"100%"}
            name="employment_type"
            onHandleChange={(event) => handleIndustry(event)}
            onFocus={() => {
              if (industries?.length === 0) {
                getAllData("industry");
              }
            }}
            options={industries}
            placeholder={"Search by industry(ies)"}
          />

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              overflowY: "scroll",
              height: {
                sm: "187px",
                lg: "187px",
                lgm: "246px",
                mlg: "296px",
                xl: "320px",
              },
              gap: "10px",
              "::-webkit-scrollbar": {
                height: "5px",
                background: "#DCDEEB",
                display: "unset !important",
              },
            }}
            className="scroll-container"
            id="followedCompanyList"
          >
            {company?.length > 0 ? (
              company?.map((item) => (
                <Box
                  key={item?.company_id}
                  sx={{ display: "flex", alignItems: "center", gap: "9px" }}
                >
                  <Link
                    to={`/jobs/company/${item?.company_id}`}
                    target={"_blank"}
                    style={{
                      textDecoration: "none",
                      color: "black",
                    }}
                  >
                    <Box
                      component={"img"}
                      src={
                        item?.profile_url !== "No URL"
                          ? item?.profile_url
                          : logo
                      }
                      sx={{
                        height: "50px",
                        width: "50px",
                        borderRadius: "10px",
                      }}
                    />
                  </Link>
                  <Tooltip
                    disableInteractive
                    title={item?.name}
                    placement="top-start"
                  >
                    <Typography
                      sx={{
                        maxWidth: "194px",
                        fontSize: "15px",
                        fontWeight: theme.typography.Bold,
                        wordBreak: "break-word",
                        cursor: "pointer",
                      }}
                      noWrap={true}
                      onClick={() => {
                        if (!item?.Followed_flag) {
                          followComp(item?.company_id);
                        }
                      }}
                    >
                      {item?.name}
                    </Typography>
                  </Tooltip>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    {item?.Followed_flag && (
                      <>
                        <CheckCircle
                          sx={{
                            fontSize: 27,
                            cursor: "pointer",
                          }}
                          color="checkColor"
                        />
                        <TalentSVGButton
                          padding={"0px !important"}
                          color={"white"}
                          source={cancel}
                          height={28}
                          width={23}
                          onClick={() => handleUnfollow(item?.company_id)}
                        />
                      </>
                    )}
                  </Box>
                </Box>
              ))
            ) : showLoading ? (
              <Box
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CircularProgress color="inherit" size={20} />
              </Box>
            ) : (
              <Typography
                sx={{
                  fontSize: "12px",
                  fontWeight: theme.typography.Medium,
                  textAlign: "center",
                }}
              >
                No companies
              </Typography>
            )}
          </Box>
        </Box>
      </Box>
      <Button
        variant="contained"
        color="followAlready"
        sx={{
          height: "60px",
          width: "360px",
          position: "absolute",
          borderRadius: "0 0 25px 25px",
        }}
        onClick={(event) => {
          handleClick(event);
        }}
      >
        see who you follow already
      </Button>
    </>
  );
}
