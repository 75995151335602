import { useTheme } from "@mui/material/styles";

import { Modal } from "@mui/material";
import React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Bot from "../../../assets/EndModalBot.svg";

const InitialModal = ({ handleOpen, handleClose, handleSubmit }) => {
  const theme = useTheme();

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 360,
    bgcolor: "background.paper",
    borderRadius: "25px",
    // p: 4,
    boxShadow: "none !important",
    // border: "1px solid black !important",
    "&:focus-visible": {
      outline: "none",
    },
  };

  return (
    <Modal
      open={handleOpen}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Box
          sx={{
            padding: "25px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Box component="img" src={Bot} sx={{ width: 83, height: 125 }}></Box>
          <Box
            sx={{
              fontSize: "20px",
              marginBottom: "25px",
              fontWeight: "700",
              textAlign: "center",
            }}
          >
            Grab a coffee, let's settle in
          </Box>
          <Box
            sx={{
              fontSize: "14x",
              fontWeight: "700",
              marginBottom: "30px",
              textAlign: "center",
            }}
          >
            This should take about 5-10 minutes, but once done, you’ll gain some
            insights Into your primary and secondary personality, traits and
            Grit Score!
          </Box>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            variant="contained"
            color="grayButton100"
            sx={{
              borderRadius: 0,
              width: "50%",
              minHeight: "60px",
              maxHeight:"60px",
              fontWeight:theme.typography.Bold,
              fontSize:"14px",
              borderBottomLeftRadius: 25,
            }}
            onClick={handleClose}
          >
            I'll do it later
          </Button>
          <Button
            variant="contained"
            color="applyButton"
            sx={{
              borderRadius: 0,
              width: "50%",
              minHeight: "60px",
              maxHeight:"60px",
              fontWeight:theme.typography.Bold,
              fontSize:"14px",
              borderBottomRightRadius: 25,
            }}
            onClick={handleSubmit}
          >
            let's go
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default InitialModal;
