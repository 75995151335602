import { Box, Button, Divider, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import SmallButton from "../../common/SmallButton";
import TeamInfoDataTable from "../../common/myTeamSection/TeamInfoDataTable";
import { Link, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getTeamInfo } from "../../../redux/employer/myTeams";
import { setAlert } from "../../../redux/configSlice";
import { ALERT_TYPE } from "../../../utils/Constants";
import ChangeHistoryIcon from "@mui/icons-material/ChangeHistory";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { createData, getDecodedToken, getToken } from "../../../utils/Common";
import { GreenButtonSwitch } from "../../../utils/CommonComponent";
import locale from "../../../i18n/locale";

export default function TeamInfo() {
  const i18n = locale.en;
  const dispatch = useDispatch();
  const decodedToken = getDecodedToken(getToken());

  const [rows, setRows] = useState([]);
  const [openDelete, setOpenDelete] = useState(false);
  const [deleted, setDeleted] = useState(0);
  const [employerType, setEmployerType] = useState("");
  const [memberName, setmemberName] = useState({});

  const { id } = useParams();

  const getTeamsInfo = async () => {
    try {
      const { payload } = await dispatch(getTeamInfo(id));
      setmemberName(payload?.name);
      if (payload?.status === "success") {
        setEmployerType(payload?.data?.[0]?.employer_role_type);
        setRows(
          payload?.data.map((team) => {
            return createData(
              team?.title,
              "Offline",
              team?.created_at,
              team?.last_login_at,
              team?.employer_role_type,
              team?.assigned ? true : false,
              team?.job_id
            );
          })
        );
      } else {
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE?.ERROR,
            msg: payload?.message,
          })
        );
      }
    } catch (error) {
      dispatch(
        setAlert({
          show: true,
          type: ALERT_TYPE?.ERROR,
          msg: "This user has no jobs assigned",
        })
      );
    }
  };

  useEffect(() => {
    getTeamsInfo();
  }, [deleted !== 0]);
  return (
    <Box
      sx={{
        boxShadow: 2,
        borderRadius: "15px",
        backgroundColor: "#ffff",

        display: "flex",
        flexGrow: 1,
        flexDirection: "column",
        justifyContent: "space-between",
        width: "100%",
      }}
    >
      <Box
        className="HeaderSection"
        sx={{
          p: "24px 54px 0 54px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            gap: 2,
            alignItems: "center",
          }}
        >
          <Typography
            sx={{
              fontSize: "20px",
              fontWeight: 700,
            }}
          >
            {`${memberName?.first_name} ${memberName?.last_name}`}
          </Typography>
          <SmallButton color={"yellowButton100"} m={0} label={employerType} />
          <SmallButton
            backgroundColor={"lightGray"}
            color={"black"}
            m={0}
            label={rows?.length}
          />
          <EditIcon color="redButton100" />
          <ChangeHistoryIcon color="grayButton" />
          <DeleteIcon color="blueButton700" />
        </Box>
        <Box>
          <Typography variant="p">{i18n["empMyTeam.jobAccess"]}</Typography>
        </Box>

        <Box
          sx={{
            margin: "30px 0",
            display: "flex",
            gap: 2,
          }}
        >
          <Link
            to={
              decodedToken?.data?.role_id === 6
                ? `/recruiter/my-team`
                : `/employer/my-team`
            }
          >
            <Button
              variant="contained"
              color="grayButton200"
              sx={{
                width: "150px",
              }}
            >
              {i18n["empMyTeam.back"]}
            </Button>
          </Link>
          <Box
            sx={{
              display: "flex",
              marginLeft: "auto",
              alignItems: "center",
              cursor: "pointer",
            }}
          >
            <Typography> {i18n["empMyTeam.toggleAllJobs"]}</Typography>
            <GreenButtonSwitch />
          </Box>
        </Box>
      </Box>

      <Divider />
      <TeamInfoDataTable
        rows={rows}
        openDelete={openDelete}
        setOpenDelete={setOpenDelete}
        setDeleted={setDeleted}
      />
    </Box>
  );
}
