import {
  Box,
  Button,
  IconButton,
  InputLabel,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import locale from "../../../../i18n/locale";
import TextEditor from "../../../common/postJobsSection/TextEditor";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { ToggleSwitch } from "../../../../utils/CommonComponent";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import CustomSelect from "../../../candidate/myCV/CustomSelect";
import { debounce } from "../../../../utils/Common";
import {
  getCandidateCompany,
  updateInitialCandidateCompanies,
} from "../../../../redux/employer/empProfileSlice";
import { getTitles } from "../../../../redux/candidate/myCvSlice";
import { useDispatch } from "react-redux";
import { useRef } from "react";
import dayjs from "dayjs";
import { WORK } from "../../../../utils/Constants";

export default function EditWorkLife({
  talentDetails,
  setTalentDetails,
  // workLife,
  // setWorkLife,
  errors,
  setErrors,
  companies,
  getAllData,
  titles,
}) {
  const theme = useTheme();
  const i18n = locale.en;
  const WORD_LIMIT = 50;
  const dispatch = useDispatch();
  const currentDate = new Date();

  const [noWorkExp, setNoWorkExp] = useState(false);
  const [wordLimitExceed, setWordLimitExceed] = useState(false);
  const disableEndDate = useRef(null);
  const [inputBoxWidth, setInputBoxWidth] = useState(
    (window.innerWidth * 672) / 1920
  );
  const [isLoading, setIsLoading] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  // const removeWork = (event, index) => {
  //   if (talentDetails?.worklife?.length > 1) {
  //     const newWorkData = talentDetails?.worklife?.filter((data, i) => i + 1 !== index);
  //     setWorkData(newWorkData);
  //   }
  // };

  const handleOnSelectInputChange = (newValue, inputId) => {
    debouncedHandleOnSelectInputChange(newValue, inputId);
  };

  const debouncedHandleOnSelectInputChange = useCallback(
    debounce(
      async (newValue, type) => {
        let params = {
          title: newValue || "",
          limit: 200,
        };
        if (newValue) {
          // getlist(params, type);
          try {
            if (type === "company_name") {
              dispatch(getCandidateCompany(params));
            }
            if (type === "title") {
              dispatch(getTitles(params));
            }
          } catch (error) {
            console.log(error, "error");
          }
        }
      },
      750,
      setIsLoading
    ),
    []
  );

  const handleInputChange = (inputText, type, index) => {
    const updatedWorkData = talentDetails?.worklife;
    if (inputText?.split(" ")?.length <= WORD_LIMIT) {
      setWordLimitExceed(false);
      updatedWorkData[index] = {
        ...updatedWorkData[index],
        [type]: inputText,
      };
      setTalentDetails({ ...talentDetails, worklife: updatedWorkData });
    } else {
      setWordLimitExceed(true);
    }
  };

  const calculateDaysBetween = (startDate, endDate) => {
    endDate = endDate ? new Date(endDate) : new Date();
    startDate = new Date(startDate);
    const timeDifference = endDate - startDate;
    const years = Math.floor(timeDifference / (1000 * 60 * 60 * 24 * 365));
    const months = Math.floor(
      (timeDifference % (1000 * 60 * 60 * 24 * 365)) /
        (1000 * 60 * 60 * 24 * 30)
    );
    const days = Math.floor(
      (timeDifference % (1000 * 60 * 60 * 24 * 30)) / (1000 * 60 * 60 * 24)
    );

    if (years > 0) {
      return `${years} ${years === 1 ? "year" : "years"} ${
        months > 0 ? `${months} ${months === 1 ? "month" : "months"}` : ""
      }`;
    } else if (months > 0) {
      return `${months} ${months === 1 ? "month" : "months"} ${
        days > 0 ? `${days} ${days === 1 ? "day" : "days"}` : ""
      }`;
    } else {
      return `${days} ${days === 1 ? "day" : "days"}`;
    }
  };

  const calculateDuration = (index) => {
    const end =
      talentDetails?.worklife?.[index]?.end_date === "Invalid Date"
        ? null
        : talentDetails?.worklife?.[index]?.end_date
        ? new Date(talentDetails?.worklife?.[index]?.end_date)
        : null;

    const start =
      talentDetails?.worklife?.[index]?.start_date === "Invalid Date"
        ? null
        : talentDetails?.worklife?.[index]?.start_date
        ? new Date(talentDetails?.worklife?.[index]?.start_date)
        : null;
    return start === null && end === null
      ? ""
      : start === null
      ? ""
      : calculateDaysBetween(start, end);
  };

  const handleOnSelectChange = (ind, newValue, val, index) => {
    if (newValue === null) {
      dispatch(updateInitialCandidateCompanies());
    }
    const updatedWorkData = talentDetails?.worklife;
    if (index === "company_name") {
      updatedWorkData[ind] = {
        ...updatedWorkData[ind],
        company_name: newValue?.label,
        company_id: newValue?.value,
      };
    } else if (index === "title") {
      updatedWorkData[ind] = {
        ...updatedWorkData[ind],
        title: newValue?.label,
        title_id: newValue?.value,
      };
    }
    setTalentDetails({ ...talentDetails, worklife: updatedWorkData });
    setErrors((prev) =>
      prev?.filter((item) => item?.key !== `employHistory[${ind}].${index}`)
    );
  };

  const onCreateOption = (data, inputId, index) => {
    const updatedWorkData = talentDetails?.worklife;
    if (inputId === "company_name") {
      updatedWorkData[index] = {
        ...updatedWorkData[index],
        company_name: data,
        company_id: data,
      };
    } else if (inputId === "title") {
      updatedWorkData[index] = {
        ...updatedWorkData[index],
        title: data,
        title_id: data,
      };
    }
    setTalentDetails({ ...talentDetails, worklife: updatedWorkData });
  };

  const onMenuOpen = () => {
    if (companies.length < 1) {
      getAllData("companies");
    }
    setIsMenuOpen(true);
  };
  const onMenuClose = () => {
    setIsMenuOpen(false);
  };

  const handleSwitch = (event, index) => {
    const date = new Date();

    let day = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();

    let currentDate = `${year}-${month}-${day}`;

    const updatedWorkData = talentDetails?.worklife;
    updatedWorkData[index] = {
      ...updatedWorkData[index],
      [event.target.id]: event.target.checked ? 1 : 0,
      end_date: currentDate,
    };
    disableEndDate.current = event.target.checked;
    setTalentDetails({ ...talentDetails, updatedWorkData });
  };

  const handleChange = (event, index, type, id) => {
    // const newWorkData = JSON.parse(JSON.stringify(talentDetails));
    // if (event && event.target) {
    //   if (event.target.value.split(" ").length <= WORD_LIMIT) {
    //     setWordLimitExceed(false);
    //     newWorkData.worklife[index][event.target.id] = event.target.value;
    //     setTalentDetails(newWorkData);
    //   } else {
    //     setWordLimitExceed(true);
    //   }
    // } else {
    //   if (event === "" || event === null) {
    //     newWorkData[index][type] = null;
    //   } else {
    //     newWorkData[index][type] = dayjs(event).format("YYYY-MM-DD");
    //     if (
    //       newWorkData?.[index + 1] &&
    //       newWorkData?.[index + 1]?.end_date === "" &&
    //       typeof newWorkData?.[index + 1] === "object"
    //     ) {
    //       newWorkData[index + 1].send_date = dayjs(event)?.format("YYYY-MM-DD");
    //     }
    //   }
    //   setTalentDetails(newWorkData);
    // }
    // let obj = { ...errors };
    // if (event && type === "start_date") {
    //   delete obj[`workData[${index}].${type}`];
    // }
    // setErrors({ ...obj });
    const updatedWorkData = talentDetails?.worklife;
    updatedWorkData[index] = {
      ...updatedWorkData[index],
      [type]: dayjs(event)?.format("YYYY-MM-DD"),
    };
    setTalentDetails({ ...talentDetails, worklife: updatedWorkData });
    setErrors((prev) =>
      prev?.filter((item) => item?.key !== `employHistory[${index}].${type}`)
    );
  };

  const addWork = () => {
    setTalentDetails((prevState) => ({
      ...prevState,
      worklife:
        prevState?.worklife?.length > 0
          ? [
              ...prevState.worklife,
              {
                ...WORK,
                end_date:
                  prevState.worklife[prevState.worklife.length - 1]?.start_date,
              },
            ]
          : [...prevState.worklife, WORK],
    }));
  };

  const removeWork = (event, index) => {
    if (talentDetails?.worklife?.length > 1) {
      // Use the functional form of setTalentDetails to ensure correct update based on previous state
      setTalentDetails((prevState) => {
        // Filter out the work entry with the specified index
        const newWorkData = prevState?.worklife?.filter(
          (data, i) => i !== index
        );
        // Update the state with the new work data
        return { ...prevState, worklife: newWorkData };
      });
    }
  };

  return (
    <>
      <Box
        sx={{
          p: 4,
          pb: 0,
          display: "flex",
          flexDirection: "column",
          gap: "16px",
        }}
      >
        <Typography sx={{ fontWeight: theme.typography.Bold }}>
          {i18n["myProfile.Worklife"]}
        </Typography>

        {talentDetails?.worklife?.length >= 0 &&
          talentDetails?.worklife?.map((work, index) => (
            <Box key={index}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  mb: 3,
                }}
              >
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  {index !== 0 && (
                    <IconButton
                      aria-label="edit"
                      color="redButton"
                      sx={{
                        padding: "0 !important",
                        marginRight: "4px",
                      }}
                      onClick={(event) => removeWork(event, index)}
                    >
                      <RemoveCircleIcon />
                    </IconButton>
                  )}
                  <Typography
                    sx={{
                      fontWeight: theme.typography.Bold,
                      flex: 1,
                    }}
                  >
                    {index === 0
                      ? i18n["myCV.latestJobLabel"]
                      : i18n["myCV.previousJobLabel"]}
                  </Typography>
                </Box>
                <Box>
                  <Typography
                    sx={{
                      fontWeight: theme.typography.Medium,
                      pl: 1,
                      pr: 1,
                    }}
                  >
                    {index === 0 ? i18n["myCV.mostRecent"] : ""}
                  </Typography>
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  mb: 2,
                }}
              >
                <Box sx={{ width: "100%" }}>
                  <InputLabel
                    htmlFor="company_name"
                    sx={{
                      color: "black",
                      paddingBottom: "2px",
                      fontSize: "14px",
                      fontWeight: theme.typography.Bold,
                    }}
                  >
                    {i18n["myCV.companyNameLabelReq"]}
                  </InputLabel>
                  <CustomSelect
                    index={index}
                    variant="createSelect"
                    onInputChange={handleOnSelectInputChange}
                    onChange={handleOnSelectChange}
                    onCreateOption={onCreateOption}
                    placeholder={"Select or add the name of the company"}
                    inputId="company_name"
                    name="company_name"
                    value={
                      work?.company_id || work?.company_name
                        ? {
                            value: work?.company_id,
                            label: work?.company_name,
                          }
                        : null
                    }
                    onMenuOpen={onMenuOpen}
                    onMenuClose={onMenuClose}
                    option={companies?.map((item) => ({
                      value: item?.candidate_company_id,
                      label: item?.name,
                    }))}
                    isLoading={isLoading}
                    customStyle={{
                      menu: (provided) => ({
                        ...provided,
                        zIndex: 9999,
                      }),
                      menuList: (provided) => ({
                        ...provided,
                        "&::-webkit-scrollbar": {
                          width: "6px",
                          height: "6px",
                          borderRadius: "2.5px",
                          backgroundColor: "#efefef",
                        },
                        "&::-webkit-scrollbar-thumb": {
                          borderRadius: "2.5px",
                        },
                      }),
                      option: (
                        styles,
                        { data, isDisabled, isFocused, isSelected }
                      ) => {
                        return {
                          ...styles,
                          color: isSelected ? "#fff" : "black",
                          ":active": {
                            ...styles[":active"],
                          },
                        };
                      },
                      control: (baseStyles) => ({
                        ...baseStyles,
                        boxShadow: "none",
                        borderRadius: "10px",
                        height: `40px`,
                        // border: errors?.[`employHistory[${index}].company_name`]
                        //   ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
                        //   : "1px solid #E0E0E0",
                        // "&:hover": errors?.[
                        //   `employHistory[${index}].company_name`
                        // ]
                        //   ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
                        //   : "1px solid #E0E0E0",
                        border: errors?.find(
                          (item) =>
                            item?.key === `employHistory[${index}].company_name`
                        )
                          ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
                          : "1px solid #E0E0E0",
                        "&:hover": errors?.find(
                          (item) =>
                            item?.key === `employHistory[${index}].company_name`
                        )
                          ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
                          : "1px solid #E0E0E0",
                        width: "100%",
                      }),
                    }}
                  />
                </Box>
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-end",
                  }}
                >
                  <InputLabel
                    htmlFor="title"
                    sx={{
                      color: "black",
                      paddingBottom: "2px",
                      fontSize: "14px",
                      fontWeight: theme.typography.Bold,
                      width: "94%",
                    }}
                  >
                    {i18n["myCV.jobTitleLableReq"]}
                  </InputLabel>
                  <Box sx={{ width: "94%" }}>
                    <CustomSelect
                      index={index}
                      variant="createSelect"
                      onInputChange={handleOnSelectInputChange}
                      onChange={handleOnSelectChange}
                      onCreateOption={onCreateOption}
                      placeholder={"What is/was your job title?"}
                      inputId="title"
                      name="title"
                      value={
                        work?.title_id || work?.title
                          ? {
                              value: work?.title_id,
                              label: work?.title,
                            }
                          : null
                      }
                      onMenuOpen={() => {
                        if (titles.length < 1) {
                          getAllData("titles");
                        }
                        setIsMenuOpen(false);
                      }}
                      onMenuClose={onMenuClose}
                      option={titles?.map((item) => ({
                        value: item?.job_title_id,
                        label: item?.title,
                      }))}
                      isLoading={isLoading}
                      customStyle={{
                        menu: (provided) => ({
                          ...provided,
                          zIndex: 9999,
                        }),
                        option: (
                          styles,
                          { data, isDisabled, isFocused, isSelected }
                        ) => {
                          return {
                            ...styles,
                            color: isSelected ? "#fff" : "black",
                            ":active": {
                              ...styles[":active"],
                            },
                          };
                        },
                        menuList: (provided) => ({
                          ...provided,
                          "&::-webkit-scrollbar": {
                            width: "6px",
                            height: "6px",
                            borderRadius: "2.5px",
                            backgroundColor: "#efefef",
                          },
                          "&::-webkit-scrollbar-thumb": {
                            borderRadius: "2.5px",
                          },
                        }),
                        control: (baseStyles) => ({
                          ...baseStyles,
                          boxShadow: "none",
                          borderRadius: "10px",
                          height: `40px`,
                          width: "100%",
                          border: errors?.find(
                            (item) =>
                              item?.key === `employHistory[${index}].title`
                          )
                            ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
                            : "1px solid #E0E0E0",
                          "&:hover": errors?.find(
                            (item) =>
                              item?.key === `employHistory[${index}].title`
                          )
                            ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
                            : "1px solid #E0E0E0",
                        }),
                      }}
                    />
                  </Box>
                </Box>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  mb: 3,
                  width: "100%",
                  gap: "20px",
                  justifyContent: "space-between",
                }}
              >
                <Box
                  sx={{
                    width: "50%",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Box sx={{ width: "50%" }}>
                    <InputLabel
                      htmlFor="startdate"
                      sx={{
                        color: "black",
                        paddingBottom: "2px",
                        fontSize: "14px",
                        fontWeight: theme.typography.Bold,
                      }}
                    >
                      {i18n["myCV.startDateReq"]}
                    </InputLabel>
                    <LocalizationProvider
                      dateAdapter={AdapterDayjs}
                      locale="en-gb"
                    >
                      <DatePicker
                        // maxDate={work?.end_date || currentDate}
                        maxDate={work?.end_date}
                        value={work?.start_date}
                        // value={new Date()}
                        onChange={(newValue) =>
                          handleChange(newValue, index, "start_date")
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            sx={{
                              width: "95%",
                              "& .MuiInputBase-root": {
                                height: "40px",
                                width: "100%",
                                borderRadius: "10px",
                                border: errors?.find(
                                  (item) =>
                                    item?.key ===
                                    `employHistory[${index}].start_date`
                                )
                                  ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
                                  : "1px solid #E0E0E0",
                                "&:hover": errors?.find(
                                  (item) =>
                                    item?.key ===
                                    `employHistory[${index}].start_date`
                                )
                                  ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
                                  : "1px solid #E0E0E0",
                              },

                              "& fieldset": {
                                border: "none",
                              },
                            }}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </Box>
                  <Box
                    sx={{
                      width: "fit-content",
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                      marginTop: "21px",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "15px",
                        fontWeight: theme.typography.Regular,
                        textWrap: "nowrap",
                      }}
                    >
                      {"I still work here"}
                    </Typography>
                    <ToggleSwitch
                      disabled={index > 0}
                      id="currently_employed_here"
                      checked={!!Number(work?.currently_employed_here)}
                      onChange={(event) => handleSwitch(event, index)}
                      color="greenSwitch"
                    />
                  </Box>
                </Box>

                <Box
                  sx={{
                    width: "47%",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Box sx={{ width: "47%" }}>
                    <InputLabel
                      htmlFor="enddate"
                      sx={{
                        color: "black",
                        paddingBottom: "2px",
                        fontSize: "14px",
                        fontWeight: theme.typography.Bold,
                      }}
                    >
                      {i18n["myCV.endDate"]}
                    </InputLabel>
                    <LocalizationProvider
                      dateAdapter={AdapterDayjs}
                      locale="en-gb"
                    >
                      <DatePicker
                        value={work?.end_date}
                        minDate={work?.start_date}
                        onChange={(newValue) => {
                          handleChange(newValue, index, "end_date");
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            sx={{
                              "& .MuiInputBase-root": {
                                height: "41px",
                                // mr: 5,
                                borderRadius: "10px",
                              },
                              "& fieldset": {
                                borderColor: "#C9CAD8 !important",
                              },
                            }}
                          />
                        )}
                      />
                    </LocalizationProvider>
                    {/* {work.end_date === "" &&
                    errors?.find((error) => error.key === "end_date") && (
                      <Typography color={"red !important"}>
                        {`*${
                          errors?.find((error) => error?.key === "end_date")
                            .message
                        }`}
                      </Typography>
                    )} */}
                  </Box>
                  <Box sx={{ width: "47%" }}>
                    <InputLabel
                      htmlFor="enddate"
                      sx={{
                        color: "black",
                        paddingBottom: "2px",
                        fontSize: "14px",
                        fontWeight: theme.typography.Bold,
                      }}
                    >
                      Total Duration
                    </InputLabel>
                    <TextField
                      sx={{
                        "& .MuiInputBase-root": {
                          height: "41px",
                          // mr: 5,
                          borderRadius: "10px",
                        },
                        "& fieldset": {
                          borderColor: "#C9CAD8 !important",
                        },
                      }}
                      value={calculateDuration(index)}
                      placeholder="00 years, 00 months"
                    />
                  </Box>
                </Box>
              </Box>

              <Box sx={{ mb: 3 }}>
                <InputLabel
                  htmlFor="clients_worked_on_awards"
                  sx={{
                    color: "black",
                    paddingBottom: "2px",
                    fontSize: "14px",
                    fontWeight: theme.typography.Bold,
                  }}
                >
                  {i18n["myCV.descriptionJobLabelOpt"]}
                </InputLabel>
                <TextEditor
                  value={work?.clients_worked_on_awards}
                  border="1px solid #C9CAD8"
                  borderRadius="10px"
                  editorIndex={index}
                  type="clients_worked_on_awards"
                  onInputCHange={handleInputChange}
                  placeholder="Add any details you’d like to highlight"
                  width={{ sm: "97.5%", xl: 1443 }}
                  minHeight={80}
                  padding="10px"
                />
                {/* {errors?.find(
                  (error) => error.key === "clients_worked_on_awards"
                ) && (
                  <Typography color={"red !important"}>
                    {`*${
                      errors?.find(
                        (error) => error?.key === "clients_worked_on_awards"
                      )?.message
                    }`}
                  </Typography>
                )} */}
                {wordLimitExceed && (
                  <Typography color={"red !important"}>
                    Word limit {WORD_LIMIT}
                  </Typography>
                )}
              </Box>
            </Box>
          ))}
      </Box>
      <Button
        variant="contained"
        color="greenSwitch"
        onClick={addWork}
        sx={{
          marginBottom: "32px",
          borderRadius: "0px 10px 10px 0px",
          width: "230px",
          height: "41px",
        }}
      >
        {i18n["myCV.workBottonText"]}
      </Button>
    </>
  );
}
