import { Box, Button, Typography, useTheme } from "@mui/material";
import React from "react";
import profile_challenger from "../../../../assets/Personality Logos/personality_characters_challenger.svg";

// import profile_challenger from "../../../../assets/Personality Logos/crayon_personality_grey_challeneger.svg";
import profile_character from "../../../../assets/Personality Logos/crayon_personality_character.svg";
import profile_collaborator from "../../../../assets/Personality Logos/crayon_personality_collaborator.svg";
import profile_contemplator from "../../../../assets/Personality Logos/crayon_personality_contemplator.svg";
import personalityIcon from "../../../../assets/Padding Included/User_Profile.svg";
import MUIRadialChart from "../../../common/MUIRadialChart";
import SmallButton from "../../../common/SmallButton";
import { getDecodedToken, getToken } from "../../../../utils/Common";
import edit from "../../../../assets/Padding Excluded/Black_Edit.svg";
import { map } from "lodash";
import TalentSVGButton from "../../../common/TalentSVGButton";
import editIcon from "../../../../assets/Padding Included/Yellow_Edit.svg";

export default function PersonalityBox({ jobContent, handleEdit,isShow }) {
  const theme = useTheme();
  const decodedToken = getDecodedToken(getToken());
  return (
    <>
      <Box sx={{ display: "flex" }}>
        <Button
          variant="contained"
          color="ideaGritScoreSlider"
          sx={{
            width: "150px",
            height: "45px",
            borderRadius: "25px 0 25px 0",
            padding: "6px",
            marginRight: "10px",
          }}
          startIcon={
            <Box
              component={"img"}
              src={personalityIcon}
              sx={{
                width: 20,
                height: 20,
              }}
            />
          }
        >
          personality
        </Button>

        {decodedToken?.data?.role_id === 1 && isShow &&(
           <TalentSVGButton
           color={"white"}
           source={editIcon}
           height={33}
           width={33}
           padding={"0px !important"}
           startIconMargin={"0px !important"}
           onClick={handleEdit}
         />
        )}
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "11px",
          padding: "20px",
        }}
      >
        <Box sx={{ display: "flex", gap: "22px", flexDirection: "column" }}>
          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            {(jobContent?.primary_name || jobContent?.primary) && (
              <Box
                component="img"
                height={120}
                alt="job_exp"
                src={
                  ((jobContent?.primary_name === "collaborator" ||
                    jobContent?.primary === "collaborator") &&
                    profile_collaborator) ||
                  ((jobContent?.primary_name === "challenger" ||
                    jobContent?.primary === "challenger") &&
                    profile_challenger) ||
                  ((jobContent?.primary_name === "character" ||
                    jobContent?.primary === "character") &&
                    profile_character) ||
                  ((jobContent?.primary_name === "contemplator" ||
                    jobContent?.primary === "contemplator") &&
                    profile_contemplator)
                }
              />
            )}
            {(jobContent?.shadow_name || jobContent?.secondary) && (
              <Box
                component="img"
                height={120}
                alt="job_exp"
                src={
                  ((jobContent?.shadow_name === "collaborator" ||
                    jobContent?.secondary === "collaborator") &&
                    profile_collaborator) ||
                  ((jobContent?.shadow_name === "challenger" ||
                    jobContent?.secondary === "challenger") &&
                    profile_challenger) ||
                  ((jobContent?.shadow_name === "character" ||
                    jobContent?.secondary === "character") &&
                    profile_character) ||
                  ((jobContent?.shadow_name === "contemplator" ||
                    jobContent?.secondary === "contemplator") &&
                    profile_contemplator)
                }
              />
            )}
            <MUIRadialChart
              value={jobContent?.grit_score || 0}
              size={120}
              color={"grit_score1"}
              chartName={"grit score"}
              countFontSize={16}
              labelFontSize={12}
              labelFontWeight={theme.typography.Regular}
            />
          </Box>
          <Box>
          {jobContent?.traits ? 
  jobContent?.traits?.map((item) => (
    <SmallButton
      fontWeight={theme.typography.Medium}
      minWidth="10px"
      height={25}
      color="ideaGritScoreSlider"
      borderRadius="5px"
      label={item?.trait?.name}
      mr="4px"
    />
  )) : 
  jobContent?.JobTraits?.split(",").map((item) => (
    <SmallButton
      fontWeight={theme.typography.Medium}
      minWidth="10px"
      height={25}
      color="ideaGritScoreSlider"
      borderRadius="5px"
      label={item}
      mr="4px"
    />
  ))
}

          </Box>
        </Box>
        <Box>
          <Typography
            sx={{ fontSize: "12px", fontWeight: theme.typography.Bold }}
          >
            Company Culture
          </Typography>
          {jobContent?.culture_ids ? 
  (jobContent?.culture_ids?.map((item) => (
    <SmallButton
      fontWeight={theme.typography.Medium}
      minWidth="10px"
      height={25}
      color="ideaGritScoreSlider"
      borderRadius="5px"
      label={item?.company_culture?.name}
      mr="4px"
    />
  ))) : 
  (jobContent?.CompanyCulture?.split(",").map((item) => (
    <SmallButton
      fontWeight={theme.typography.Medium}
      minWidth="10px"
      height={25}
      color="ideaGritScoreSlider"
      borderRadius="5px"
      label={item}
      mr="4px"
    />
  )))
}

        </Box>
      </Box>
    </>
  );
}
