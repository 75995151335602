import React, { useCallback, useEffect, useRef, useState } from "react";
import { UserCardStyledAccordion } from "../../../../utils/CommonComponent";
import {
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  CircularProgress,
  IconButton,
  Popover,
  Slider,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import SmallButton from "../../../common/SmallButton";
import upClose from "../../../../assets/Padding Included/Black_Up_Close.svg";
import downClose from "../../../../assets/Padding Included/Black_Down_Open.svg";
import editIcon from "../../../../assets/Padding Included/Yellow_Edit.svg";
import deleteIcon from "../../../../assets/Padding Included/Blue_Trash_Delete_1.svg";
import profile from "../../../../assets/company_logo_1.svg";
import BlueCompany from "../../../../assets/Padding Excluded/Blue_Following_Company-.svg";
import TalentSVGButton from "../../../common/TalentSVGButton";
import locationIcon from "../../../../assets/Padding Excluded/Black_Location.svg";
import MUIRadialChart from "../../../common/MUIRadialChart";
import ChangeStatus from "../Common/ChangeStatus";
import SVGButton from "../../../common/SVGButton";
import CopyToClipboardIcon from "../../../../assets/CircularIcon/Yellow/Circular Icons__Yellow_Move copy.svg";
import CopyToClipboardIconGrey from "../../../../assets/CircularIcon/Grey/CIrcular_Icon_Grey_Copy.svg";
import NetworkIcon from "../../../../assets/CircularIcon/Blue/Circular Icons__Blue_Network.svg";
import DatabaseIcon from "../../../../assets/CircularIcon/Green/Circular Icons__Green_Database.svg";
import DatabaseIconGrey from "../../../../assets/CircularIcon/Grey/Circular Icons__Grey_Database.svg";
import RedView from "../../../../assets/CircularIcon/Red/Circular Icons__Red_View copy.svg";
import RedViewGrey from "../../../../assets/CircularIcon/Grey/CIrcular_Icon_Grey_View.svg";
import BlueFolder from "../../../../assets/CircularIcon/Blue/Circular Icons__Blue_Title_Job_Experience.svg";
import BlueFolderGrey from "../../../../assets/CircularIcon/Grey/CIrcular_Icon_Grey_Folder.svg";
import GreenPlay from "../../../../assets/CircularIcon/Green/Circular Icons__Green_Play copy.svg";
import GreenPlayGrey from "../../../../assets/CircularIcon/Grey/Circular_Icon_Grey_Play.svg";
import YellowChatHistory from "../../../../assets/CircularIcon/Yellow/Circular Icons__Yellow_Chat History_2 copy.svg";
import YellowChatHistoryGrey from "../../../../assets/CircularIcon/Grey/CIrcular_Icon_Grey_Chat_History.svg";
import talentIcon from "../../../../assets/HomePage/Crayon_Direct.svg";
import websiteLinkIcon from "../../../../assets/Padding Excluded/teal_World_Website.svg";
import linkedin from "../../../../assets/linkedin.svg";
import contact from "../../../../assets/Padding Excluded/Black_Contact_Yellow.svg";
import email from "../../../../assets/Padding Excluded/Black_Email_Teal.svg";
import referrals from "../../../../assets/Padding Excluded/TrackIcon.svg";
import DOWN from "../../../../assets/Padding Excluded/Black_Down_Open.svg";
import locale from "../../../../i18n/locale";
import RightSideBox from "../Common/RightSideBox";
import CommentBox from "../../adminTalent/DialogBox/CommentBox";
import Refferals from "../../adminTalent/DialogBox/Refferals";
import {
  convertDatetimeWithoutAgo,
  dateConverterMonth,
} from "../../../../utils/DateTime";
import {
  getDecodedToken,
  getToken,
  splitString,
} from "../../../../utils/Common";
import { Link, useParams } from "react-router-dom";
import { formatPhoneNumberIntl } from "react-phone-number-input";
import Slider2 from "../../../common/Slider2";
import EditCompany from "./EditCompany";
import Cropper from "react-easy-crop";
import CustomDialog from "../../../common/CustomDialog";
import ZoomOutIcon from "@mui/icons-material/Remove";
import ZoomInIcon from "@mui/icons-material/Add";
import getCroppedImg from "../../../../utils/cropImage";
import {
  getCompanyRecruiter,
  getCompanyTeamMember,
  uploadCompanyLogo,
} from "../../../../redux/candidate/myProfileSlice";
import {
  setAlert,
  setLoading,
  setUserEditPlan,
} from "../../../../redux/configSlice";
import { ALERT_TYPE, ERROR_MSG, USER_ROLES } from "../../../../utils/Constants";
import { useDispatch } from "react-redux";
import EditPlan from "./EditPlan";
import TeamMemberBox from "../Common/TeamMemberBox";
import HistoryBox from "../../../common/HistoryBox";
import RecruiterBox from "../Common/RecruiterBox";
import CropModal from "../../../employer/myProfile/cropModal";
import CountersBox from "./DialogBox/CountersBox";
import { Divider } from "@material-ui/core";
import linkToPool from "../../../../assets/Black_Add_To_Pool.svg";
import referralsIcon from "../../../../assets/Padding Excluded/TrackIcon.svg";
import commentIcon from "../../../../assets/Padding Excluded/Black_Chat.svg";
import personalityIcon from "../../../../assets/Padding Included/User_Profile.svg";
import questionIcon from "../../../../assets/Padding Excluded/Crayon Icon_Experience_White.svg";
import leftIcon from "../../../../assets/WhiteIcons/Left_Arrow.svg";
import rightIcon from "../../../../assets/WhiteIcons/Right_Arrow.svg";
import upArrow from "../../../../assets/Black_Up_Close - Copy.svg";
import downArrow from "../../../../assets/Black_Down_Open - Copy.svg";
import teamIcon from "../../../../assets/Black_User_Profile.svg";
import companiesIcon from "../../../../assets/Icons/CandidateProfile/Black_Following_Company-.svg";
import CompanyTalentPool from "./DialogBox/CompanyTalentPool";
import CoinHistoryBox from "./DialogBox/CoinHistoryBox";
import CompanyTopUp from "./DialogBox/CompanyTopUp";
import {
  getCompanyComment,
  pinACompany,
} from "../../../../redux/admin/jobsSlice";
import JobsBox from "./DialogBox/JobsBox";
import VideoBox from "../../adminJobs/DialogBox/VideoBox";
import DocumentBox from "../../adminJobs/DialogBox/DocumentBox";
import * as Flag from "country-flag-icons/react/3x2";
import AdminUnlockJob from "../../adminJobs/DialogBox/AdminUnlockJob";
import {
  addNewUserCoin,
  addNewUserLicense,
} from "../../../../redux/employer/empProfileSlice";
import pinIcon from "../../../../assets/Icons/pinIcon.svg";
import { getBillingDetails } from "../../../../redux/login/loginSlice";

export default function CompanyCardNew({
  companyData,
  getCompanyList,
  setCompanyData,
  allCompanyData,
}) {
  const theme = useTheme();
  const i18n = locale.en;
  const hiddenFileInput = useRef(null);
  const editPlanRef = useRef(null);
  const dispatch = useDispatch();
  const { id } = useParams();

  const CountryFlag =
    Flag[
      companyData?.country_code_name ? companyData?.country_code_name : "IN"
    ];

  const [isOpenHistoryBox, setOpenHistoryBox] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [editCompany, setEditCompany] = useState();
  const decodedToken = getDecodedToken(getToken());
  const companyIndustries = companyData?.Industries?.map((item) => {
    return item?.industry_name;
  });
  const companyCulture = companyData?.Company_Culture?.map((item) => {
    return item?.employer_culture_name;
  });
  const [flip, setFlip] = useState(id ? true : false);
  const [teamMemberAcbhor, setTeamMemberAnchor] = useState(null);
  const [promoterAnchor, setPromoterAnchor] = useState(null);
  const [recruiterAnchor, setRecruiterAnchor] = useState(null);
  const [openEditImage, setOpenEditImage] = useState(false);
  const [imagePreview, setImagePreview] = useState([]);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [imageName, setImageName] = useState("My pic");
  const [editPlan, setEditPlan] = useState(null);
  const [lastKey, setLastKey] = useState(0);
  const [recruiterLastKey, setRecruiterLastKey] = useState(0);
  const [teamMember, setTeamMember] = useState([]);
  const [teamMemberCount, setTeamMemberCount] = useState();
  const [recruiter, setRecruiter] = useState([]);
  const [recruiterCount, setRecruiterCount] = useState();
  const [promoter, setPromoter] = useState([]);
  const [promoterCount, setPromoterCount] = useState();
  const [promoterLastKey, setPromoterLastKey] = useState(0);
  const [imageBlob, setImageBlob] = useState();
  const [croppedImageUrl, setCroppedImageUrl] = useState(null);
  const [image, setImage] = useState([]);
  const [viewMoreExpanded, setViewMoreExpanded] = useState(false);
  const [billingFormData, setBillingFormData] = useState();
  const [isBillDetail,  setIsBillDetail]= useState(true)
  const open = Boolean(editPlan);

  const [openCounterBox, setOpenCounterBox] = useState(null);
  const anchorElCounterBox = Boolean(openCounterBox);
  const counterRef = useRef(null);

  const [linkToPoolButton, setLinkToPool] = useState(null);
  const anchorElLinkToPool = Boolean(linkToPoolButton);
  const linkToPoolRef = useRef(null);

  const [coinHistory, setCoinHistory] = useState(null);
  const anchorElCoinHistory = Boolean(coinHistory);
  const coinHistoryRef = useRef(null);

  const [companyTopUp, setCompanyTopUp] = useState(null);
  const anchorElCompanyTopUp = Boolean(companyTopUp);
  const companyTopUpRef = useRef(null);

  const [totalCommentCount, setTotalCommentCount] = useState(0);
  const [commentButton, setCommentButton] = useState(null);
  const anchorElComment = Boolean(commentButton);
  const commentRef = useRef(null);

  const [teamButton, setTeamButton] = useState(null);
  const anchorElTeam = Boolean(teamButton);
  const teamRef = useRef(null);

  const [recruiterButton, setRecruiterButton] = useState(null);
  const anchorElRecruiter = Boolean(recruiterButton);
  const recruiterRef = useRef(null);

  const [promoterButton, setPromoterButton] = useState(null);
  const anchorElPromoter = Boolean(promoterButton);
  const promoterRef = useRef(null);

  const [companiesCount, setCompaniesCount] = useState(0);
  const [companyButton, setCompanyButton] = useState(null);
  const anchorElCompany = Boolean(companyButton);
  const companiesRef = useRef(null);

  const [jobsDropDown, setJobsDropDown] = useState(null);
  const anchorElJobs = Boolean(jobsDropDown);
  const jobsRef = useRef(null);

  const [openVideo, setOpenVideo] = useState(null);
  const [anchorElDocs, setAnchorElDocs] = useState(null);
  const [openDocument, setOpenDocument] = useState(null);

  const [coinsInfo, setCoinsInfo] = useState();
  const [adminUnlock, setAdminUnlock] = useState(false);
  const [showUnlockBox, setShowUnlockBox] = useState(false);
  const [confirmUnlock, setConfirmUnlock] = useState(false);

  const handlePopoverCloseTeamMember = () => {
    setTeamMemberAnchor(null);
  };

  const handlePopoverClosePromoter = () => {
    setPromoterAnchor(null);
  };

  const handlePopoverCloseRecruiter = () => {
    setRecruiterAnchor(null);
  };

  const getDetail = async (id) => {
    setIsBillDetail(true)
    const payload = {
      company_id: id,
    };
    try {
      const response = await dispatch(getBillingDetails(payload));
      if (response?.payload?.status === "success") {
    setIsBillDetail(false)

        setBillingFormData({
          ...response?.payload?.data,
        });
      }
    setIsBillDetail(false)

    } catch (error) {
    setIsBillDetail(false)

    }
  };

  useEffect(() => {
    if (flip) {
      getDetail(companyData?.company_id);
    }
  }, [flip]);
  useEffect(() => {}, [billingFormData]);
  const handleCompanyEdit = (compId) => {
    setOpenEdit(true);
    setEditCompany(compId);
  };

  const handleClose = () => {
    setOpenEdit(false);
  };

  const handleUploadLogo = (e) => {
    setImagePreview(null);
    hiddenFileInput?.current?.click();
  };
  const handleSubscriptionCancel = () => {
    setImageName("My pic");
    setOpenEditImage(false);
    setImageBlob();
    setCroppedImageUrl(null);
    setImage();
  };

  const cropInputFUnction = (e) => {
    const files = e?.target?.files;
    if (files && files?.length > 0) {
      const reader = new FileReader();
      reader.onload = () => setImage(reader?.result);
      reader?.readAsDataURL(files?.[0]);
    }
  };
  const handleImageChange = async (event, name) => {
    if (event?.target?.files?.[0] === undefined) {
      return;
    }
    cropInputFUnction(event);
    const render = new FileReader();
    setZoom(1);
    setImageName(event?.target?.files?.[0].name);
    const imageData = window.URL.createObjectURL(
      new Blob(event?.target?.files, { type: "application/*" })
    );
    setImagePreview(imageData);
    setOpenEditImage(true);
    event.target.value = "";
  };

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const renderFooter = <></>;

  const handleZoom = (direction) => {
    const step = 0.5;
    let newZoom = zoom;

    if (direction === "+") {
      newZoom = Math.min(zoom + step, 3); // Limit zoom to maximum 3x
    } else if (direction === "-") {
      newZoom = Math.max(zoom - step, 1); // Limit zoom to minimum 1x
    }
    setZoom(newZoom);
  };
  const handleSubscriptionOk = async () => {
    // const croppedImage = await getCroppedImg(
    //   imagePreview,
    //   croppedAreaPixels,
    //   0
    // );
    const formData = new FormData();
    const blobTofile = new File([imageBlob], imageName, {
      type: "image/jpeg",
    });

    formData.append("company-logo", blobTofile);
    formData.append("company_id", companyData?.company_id);

    try {
      const { payload } = await dispatch(uploadCompanyLogo(formData));
      if (payload?.status === "success") {
        setCompanyData(
          allCompanyData?.map((item) => {
            return item?.company_id === companyData?.company_id
              ? { ...item, profile_url: payload?.data }
              : item;
          })
        );
        setOpenEditImage(false);
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE?.SUCCESS,
            msg: i18n["alertMessage.CompanylogouploadedSuccessfully"],
          })
        );
      } else if (payload?.status === "error") {
        setImageName("My pic");
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE?.ERROR,
            msg: i18n["alertMessage.Imageformatnotsupported"],
          })
        );
      }
    } catch (error) {
      setImageName("My pic");
      dispatch(setAlert({ show: true }));
    }
  };

  const handleEditPlan = () => {
    setEditPlan(editPlanRef?.current);
  };
  const handleCloseEditPlan = () => {
    setEditPlan(null);
  };

  const handleJobClick = () => {
    window.open(`/admin/adminJobs/all-jobs/${companyData?.name}`, "_blank");
  };

  const getTeamMember = async (lastKeyy) => {
    try {
      let data = {
        lastKey: lastKeyy,
        company_id: companyData?.company_id || id,
        roleTypeID: 3,
      };
      const { payload } = await dispatch(getCompanyTeamMember(data));
      if (payload?.status === "success") {
        setTeamMemberCount(payload?.talentCount);
        if (payload?.pageNumber === 0) {
          setTeamMember(payload?.data);
          setLastKey(payload?.pageNumber + 1);
        } else {
          setLastKey(payload?.pageNumber + 1);
          setTeamMember((prevState) => [...prevState, ...payload?.data]);
        }
      }
    } catch (error) {
      dispatch(
        setAlert({
          show: true,
          type: ALERT_TYPE.ERROR,
          msg: ERROR_MSG,
        })
      );
    }
  };

  const getTeamRecruiter = async (lastKeyy) => {
    try {
      let data = {
        lastKey: lastKeyy,
        company_id: companyData?.company_id || id,
        roleTypeID: 4,
      };
      const { payload } = await dispatch(getCompanyTeamMember(data));
      if (payload?.status === "success") {
        setRecruiterCount(payload?.talentCount);
        if (payload?.pageNumber === 0) {
          setRecruiter(payload?.data);
          setRecruiterLastKey(payload?.pageNumber + 1);
        } else {
          setRecruiterLastKey(payload?.pageNumber + 1);
          setRecruiter((prevState) => [...prevState, ...payload?.data]);
        }
      }
    } catch (error) {
      dispatch(
        setAlert({
          show: true,
          type: ALERT_TYPE.ERROR,
          msg: ERROR_MSG,
        })
      );
    }
  };

  const getTeamPromoter = async (lastKeyy) => {
    try {
      let data = {
        lastKey: lastKeyy,
        company_id: companyData?.company_id || id,
        roleTypeID: 5,
      };
      const { payload } = await dispatch(getCompanyTeamMember(data));
      if (payload?.status === "success") {
        setPromoterCount(payload?.talentCount);
        if (payload?.pageNumber === 0) {
          setPromoter(payload?.data);
          setPromoterLastKey(payload?.pageNumber + 1);
        } else {
          setPromoterLastKey(payload?.pageNumber + 1);
          setPromoter((prevState) => [...prevState, ...payload?.data]);
        }
      }
    } catch (error) {
      dispatch(
        setAlert({
          show: true,
          type: ALERT_TYPE.ERROR,
          msg: ERROR_MSG,
        })
      );
    }
  };

  const getComments = async (sort) => {
    try {
      const { payload } = await dispatch(
        getCompanyComment({
          company_id: companyData?.company_id || id,
          sort_type: sort,
        })
      );
      if (payload?.status == "success") {
        setTotalCommentCount(payload?.count);
      } else {
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE?.ERROR,
            msg: payload?.message,
          })
        );
      }
    } catch (error) {
      dispatch(
        setAlert({
          show: true,
          type: ALERT_TYPE?.ERROR,
          msg: error,
        })
      );
    }
  };

  const handleViewMore = () => {
    setViewMoreExpanded((prevState) => !prevState);
  };

  const handleVideo = (event) => {
    setOpenVideo(true);
    setAnchorElDocs(event?.target);
  };
  const handleCloseVideo = (event) => {
    setOpenVideo(false);
    setAnchorElDocs(null);
  };

  const handleDocument = (event) => {
    setOpenDocument(true);
    setAnchorElDocs(event?.target);
  };
  const handleCloseDocument = (event) => {
    setOpenDocument(false);
    setAnchorElDocs(null);
  };

  const addUsers = async (flag) => {
    let payload;
    if (flag) {
      payload = {
        unlock_amount: coinsInfo?.unlock_amount,
        company_id: companyData?.company_id,
        transaction_name_id: coinsInfo?.transaction_name_id,
        employer_unlock: flag,
        cost: coinsInfo?.ZARCOST,
        user_id: companyData?.user_id,
      };
    } else {
      payload = {
        unlock_amount: coinsInfo?.unlock_amount,
        company_id: companyData?.company_id,
        transaction_name_id: coinsInfo?.transaction_name_id,
        employer_unlock: flag,
        cost: coinsInfo?.ZARCOST,
      };
    }
    try {
      let response = await dispatch(addNewUserLicense(payload));
      if (response?.payload?.status === "success") {
        setCompanyData(
          allCompanyData?.map((item) =>
            item?.company_id === companyData?.company_id
              ? {
                  ...item,
                  purchased_counter: Number(item?.purchased_counter) + 1,
                }
              : item
          )
        );
        setShowUnlockBox(false);
        setConfirmUnlock(false);
      }
    } catch (error) {
      dispatch(
        setAlert({
          show: true,
          type: ALERT_TYPE?.ERROR,
          msg: ERROR_MSG,
        })
      );
    }
  };

  const handleAddUser = async () => {
    const payload = {
      company_id: companyData?.company_id,
    };
    try {
      let response = await dispatch(addNewUserCoin(payload));
      if (response?.payload?.status === "success") {
        setCoinsInfo(response?.payload?.data);
        setShowUnlockBox(true);
      }
    } catch (error) {
      dispatch(
        setAlert({
          show: true,
          type: ALERT_TYPE?.ERROR,
          msg: ERROR_MSG,
        })
      );
    }
  };
  const handleCompanyAddUsers = (isCharge) => {
    addUsers(isCharge);
  };

  const handleDorpdownClick = async (val) => {
    let payload = {
      company_id: companyData?.company_id,
      talent_plan: val === "talent" ? true : false,
    };
    try {
      dispatch(setLoading(true));
      let response = await dispatch(setUserEditPlan(payload));
      setCompanyData((pre) =>
        pre.map((item) => {
          if (item.company_id === companyData?.company_id) {
            return {
              ...item,
              talent_plan: val === "talent" ? true : false,
              crayon_plan: response?.payload?.data?.crayon_plan,
              base_counter: response?.payload?.data?.base_counter,
              purchased_counter: response?.payload?.data?.purchased_counter,
              total_pool: response?.payload?.data?.total_pool,
              total_searches: response?.payload?.data?.total_searches,
            };
          }
          return item;
        })
      );
      if (response?.payload?.status === "success") {
        handleCloseEditPlan();
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE?.SUCCESS,
            msg: "User subscription changed successfully",
          })
        );
        dispatch(setLoading(false));
      }
    } catch (error) {
      dispatch(setLoading(false));
      dispatch(
        setAlert({
          show: true,
          type: ALERT_TYPE.ERROR,
          msg: ERROR_MSG,
        })
      );
    }
  };

  const handlePinJob = async (compID) => {
    try {
      let data = {
        company_id: compID,
        value: null,
      };
      const { payload } = await dispatch(pinACompany(data));
      if (payload?.status === "success") {
        setCompanyData((prevAllJobs) => {
          const unPinnedJob = prevAllJobs
            ?.filter((item) => item?.company_id === compID)
            ?.map((item) => ({
              ...item,
              pin_company: !companyData?.pin_company,
            }))[0];
          const pinnedJobs = prevAllJobs?.filter(
            (item) => item?.pin_company && item?.company_id !== compID
          );
          const unPinnedJobs = prevAllJobs?.filter(
            (item) => !item?.pin_company
          );
          return [...pinnedJobs, unPinnedJob, ...unPinnedJobs];
        });
      } else {
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE?.ERROR,
            msg: "Error",
          })
        );
      }
    } catch (error) {
      dispatch(
        setAlert({
          show: true,
          type: ALERT_TYPE?.ERROR,
          msg: error,
        })
      );
    }
  };

  useEffect(() => {
    if (flip) {
      getComments("");
    }
    if (flip && teamMember?.length === 0) {
      getTeamMember(0);
    }
    if (flip && recruiter?.length === 0) {
      getTeamRecruiter(0);
    }
    if (flip && promoter?.length === 0) {
      getTeamPromoter(0);
    }
  }, [flip]);

  return (
    <>
      <UserCardStyledAccordion expanded={flip}>
        <AccordionSummary aria-controls="panel1a-content" id="panel1a-header">
          <SmallButton
            color="redButton"
            startIcon={
              <Box
                component="img"
                className="eye"
                alt="eye logo"
                src={flip ? upClose : downClose}
                sx={{
                  height: 16,
                  width: { xs: 10, lg: 8, mlg: 10, xl: 15 },
                }}
              />
            }
            startIconMargin="0px !important"
            padding={0}
            height={"auto"}
            minHeight="75px !important"
            width={20}
            fontWeight={700}
            borderRadius={flip ? "10px 0 0 0" : "10px 0px 0px 10px"}
            onClick={() => setFlip((prevState) => !prevState)}
          />

          <Box
            sx={{
              display: "flex",
              width: "45%",
              gap: "20px",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                width: "50px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                paddingLeft: "15px",
              }}
            >
              <Box sx={{ height: "50px" }}>
                <Box
                  component="img"
                  // className="profileAvatar"
                  alt="crayon logo"
                  src={
                    companyData?.profile_url !== "No URL"
                      ? companyData?.profile_url
                      : profile
                  }
                  sx={{
                    //   mr: 1,
                    height: "48px !important",
                    width: "48px !important",
                    borderRadius: "10px 10px 0 0",
                    border: `1px solid #C9CAD8`,
                    margin: "0px !important",
                  }}
                />
                <input
                  ref={hiddenFileInput}
                  type="file"
                  accept="image/*"
                  onChange={(event) => handleImageChange(event, "company")}
                  style={{ display: "none" }}
                />
                <Box
                  component={"img"}
                  src={editIcon}
                  sx={{
                    position: "absolute",
                    height: "20px",
                    width: "20px",
                  }}
                  onClick={handleUploadLogo}
                />
              </Box>
              <SmallButton
                color="userID"
                label={companyData?.company_id}
                fontSize={12}
                fontWeight={theme.typography.Bold}
                alignItems="center"
                textColor="#000"
                borderRadius="0px 0px 6px 6px"
                width="fit-content"
                minWidth="51px"
                height="15px !important"
              />
            </Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                paddingRight: "10px",
                gap: "3px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  gap: "20px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    gap: 1,
                    alignItems: "center",
                  }}
                >
                  <Box
                    component={"img"}
                    src={BlueCompany}
                    sx={{
                      height: "15px",
                      width: "15px",
                    }}
                  />
                  <Tooltip
                    title={`${companyData?.name}`}
                    placement="top-end"
                    disableInteractive
                  >
                    <Link
                      to={`/admin/users/user-companies/${companyData?.company_id}`}
                      target="_blank"
                      style={{
                        textDecoration: "none",
                        color: "black",
                      }}
                    >
                      {companyData?.name ? (
                        <Typography
                          sx={{
                            fontSize: "16px",
                            fontWeight: theme.typography.Bold,
                            maxWidth: {
                              xs: "150px",
                              mddl: "250px",
                              mlg: "400px",
                              xl: "500px",
                            },
                          }}
                          noWrap
                        >
                          {companyData?.name}
                        </Typography>
                      ) : (
                        "-"
                      )}
                    </Link>
                  </Tooltip>
                </Box>
                <Box>
                  <Button
                    variant="contained"
                    color={
                      companyData?.role_id === 4
                        ? "basicColor"
                        : "recruiterButton"
                    }
                    sx={{
                      borderRadius: "0 0 0 10px",
                      fontSize: "12px",
                      fontWeight: theme.typography.Bold,
                      padding: "0px 3px !important",
                      height: "30px",
                      cursor: "default",
                      minWidth: "60px",
                      ":hover": {
                        boxShadow: "none",
                      },
                    }}
                  >
                    {companyData?.role_id === 4 ? "Employer" : "Recruiter"}
                  </Button>
                  {companyData?.recruiter_company === true &&
                    companyData?.secondary_company === true && (
                      <Button
                        variant="contained"
                        sx={{
                          borderRadius: "0",
                          fontSize: "12px",
                          fontWeight: theme.typography.Bold,
                          padding: "0px 3px !important",
                          height: "30px",
                          cursor: "default",
                          background: "#FFCC7F",
                          color: "#ffffff",
                          minWidth: "45px",
                          ":hover": {
                            boxShadow: "none",
                            background: "#FFCC7F",
                            color: "#ffffff",
                          },
                        }}
                      >
                        Client
                      </Button>
                    )}
                  {(companyData?.recruiter_company === false ||
                    companyData?.recruiter_company === null) &&
                  companyData?.secondary_company === true ? (
                    <Button
                      variant="contained"
                      // color={"companySizeButton"}
                      sx={{
                        borderRadius: "0",
                        fontSize: "12px",
                        fontWeight: theme.typography.Bold,
                        background: "#85A3B5",
                        color: "#FFFFFF",
                        padding: "0px 3px !important",
                        height: "30px",
                        cursor: "default",
                        minWidth: "45px",
                        ":hover": {
                          boxShadow: "none",
                          background: "#85A3B5",
                          color: "#FFFFFF",
                        },
                      }}
                    >
                      SubCo
                    </Button>
                  ) : (
                    ""
                  )}

                  <Button
                    variant="contained"
                    color={
                      !companyData?.talent_plan ? "liteButton" : "talentButton"
                    }
                    sx={{
                      borderRadius: "0 0 10px 0px",
                      fontSize: "12px",
                      fontWeight: theme.typography.Bold,
                      padding: "0px 3px !important",
                      height: "30px",
                      minWidth: "45px",
                      cursor: "default",
                      ":hover": {
                        boxShadow: "none",
                      },
                    }}
                  >
                    {companyData?.talent_plan ? "Talent" : "Lite"}
                  </Button>
                </Box>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "9px",
                  height: "19px",
                }}
              >
                <Box
                  component={"img"}
                  src={locationIcon}
                  sx={{
                    height: "18px",
                    width: "18px",
                    marginLeft: "-4px",
                  }}
                />
                {companyData?.town || companyData?.country ? (
                  <Typography
                    sx={{
                      fontWeight: theme.typography.Medium,
                      fontSize: 15,
                      whiteSpace: "nowrap",
                    }}
                  >
                    {companyData?.town}, {companyData?.country}
                  </Typography>
                ) : (
                  "-"
                )}
              </Box>

              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "11px",
                  height: "19px",
                }}
              >
                <Box
                  component={"img"}
                  src={websiteLinkIcon}
                  sx={{
                    height: "15px",
                    width: "15px",
                  }}
                />
                <Typography
                  sx={{
                    fontWeight: theme.typography.Regular,
                    fontSize: 12,
                    whiteSpace: "nowrap",
                  }}
                >
                  {companyData?.website ? (
                    <Link
                      to={companyData?.website}
                      target="_blank"
                      style={{
                        textDecoration: "none",
                        color: "black",
                      }}
                    >
                      {companyData?.website}
                    </Link>
                  ) : (
                    "-"
                  )}
                </Typography>
              </Box>
            </Box>
          </Box>

          <Box
            ref={counterRef}
            sx={{
              display: "flex",
              justifyContent: "center",
              minWidth: "10%",
            }}
            onClick={() => setOpenCounterBox(counterRef?.current)}
          >
            <Button
              variant="contained"
              color="recentButton"
              sx={{
                minWidth: "30px !important",
                height: "30px",
                padding: "0px 4px !important",
                borderRadius: "0 0 0 10px",
                boxShadow: "none !important",
                fontSize: "12px",
                fontWeight: theme.typography.Bold,
              }}
            >
              {companyData?.totalTalent || 0}
            </Button>
            <Button
              variant="contained"
              color="employerButton"
              sx={{
                minWidth: "30px !important",
                height: "30px",
                padding: "0px 4px !important",
                borderRadius: "0",
                boxShadow: "none !important",
                fontSize: "12px",
                fontWeight: theme.typography.Bold,
              }}
            >
              {companyData?.jobUserDataCount || 0}
            </Button>
            <Button
              variant="contained"
              color="followAlready"
              sx={{
                minWidth: "30px !important",
                height: "30px",
                padding: "0px 4px !important",
                borderRadius: "0",
                boxShadow: "none !important",
                fontSize: "12px",
                fontWeight: theme.typography.Bold,
              }}
            >
              {companyData?.companyUserDataCount || 0}
            </Button>
            <Button
              variant="contained"
              color="manageBaseButton"
              sx={{
                minWidth: "30px !important",
                height: "30px",
                padding: "0px 4px !important",
                borderRadius: "0",
                boxShadow: "none !important",
                fontSize: "12px",
                fontWeight: theme.typography.Bold,
              }}
            >
              {companyData?.totaljobs || 0}
            </Button>
            <Button
              variant="contained"
              color="checkColor"
              sx={{
                minWidth: "30px !important",
                height: "30px",
                padding: "0px 4px !important",
                borderRadius: "0",
                boxShadow: "none !important",
                fontSize: "12px",
                fontWeight: theme.typography.Bold,
              }}
            >
              {companyData?.totalactivejobs || 0}
            </Button>
            <Button
              variant="contained"
              color="hired"
              sx={{
                minWidth: "30px !important",
                height: "30px",
                padding: "0px 4px !important",
                borderRadius: "0 0 10px 0",
                boxShadow: "none !important",
                fontSize: "12px",
                fontWeight: theme.typography.Bold,
              }}
            >
              {companyData?.totalhiredjobs || 0}
            </Button>
          </Box>
          <Popover
            id="dropdown"
            open={anchorElCounterBox}
            anchorEl={openCounterBox}
            onClose={() => setOpenCounterBox(null)}
            anchorOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            sx={{
              "& .MuiPopover-paper": {
                borderRadius: "0 0 25px 25px !important",
                width: "667px !important",
              },
            }}
          >
            <CountersBox companyData={companyData} />
          </Popover>
          <Box
            sx={{
              display: "flex",
              width: "45%",
              justifyContent: "flex-start",
              alignItems: "flex-end",
              flexDirection: "column",
              position: "absolute",
              right: "0",
            }}
          >
            <Box sx={{ display: "flex" }}>
              <Box
                sx={{
                  height: "20px",
                  display: "flex",
                  alignItems: "center",
                  width: "75px",
                  justifyContent: "flex-end",
                }}
              >
                {((decodedToken?.data?.role_id === 1 &&
                  companyData?.pin_company) ||
                  (decodedToken?.data?.role_id === 2 &&
                    companyData?.pin_company)) && (
                  <TalentSVGButton
                    onClick={() => handlePinJob(companyData?.company_id)}
                    color={"white"}
                    source={pinIcon}
                    outerHeight={20}
                    height={20}
                    width={18}
                    padding={"0px !important"}
                    startIconMargin={"0px !important"}
                    margin={"2px 0 0 0"}
                  />
                )}
                <Box
                  component={"img"}
                  src={editIcon}
                  sx={{
                    height: "25px",
                    width: "25px",
                    marginTop: "3px",
                  }}
                  onClick={() => handleCompanyEdit(companyData?.company_id)}
                />

                <Box
                  component={"img"}
                  src={deleteIcon}
                  sx={{
                    height: "24px",
                    width: "22px",
                  }}
                />
              </Box>
              <Box sx={{ display: "flex" }}>
                <Button
                  variant="contained"
                  color="dateButton"
                  sx={{
                    fontSize: "12px",
                    fontWeight: 700,
                    color: "#000",
                    padding: "7px 9px",
                    borderRadius: "0 0 0 10px !important",
                    borderLeft: "1px solid #EBECF3",
                    borderRight: "1px solid #EBECF3",
                    borderBottom: "1px solid #EBECF3",
                    height: "30px !important",
                    minWidth: "97px !important",
                    whiteSpace: "nowrap",
                    ":hover": {
                      boxShadow: "none",
                    },
                  }}
                >
                  {companyData?.created_at
                    ? dateConverterMonth(companyData?.created_at)
                    : "-"}
                </Button>
                <Button
                  variant="contained"
                  color="dateButton"
                  sx={{
                    fontSize: "12px",
                    fontWeight: 700,
                    color: "#000",
                    padding: "7px 9px",
                    borderRadius: "0px !important",
                    borderRight: "1px solid #EBECF3",
                    borderBottom: "1px solid #EBECF3",
                    height: "30px !important",
                    minWidth: "75px !important",
                    whiteSpace: "nowrap",
                    ":hover": {
                      boxShadow: "none",
                    },
                  }}
                >
                  {companyData?.created_at
                    ? convertDatetimeWithoutAgo(companyData?.created_at)
                    : "-"}
                </Button>
                <Box sx={{ width: "100px" }}>
                  <ChangeStatus
                    loggedInUser={decodedToken?.data?.role_id}
                    companyStatus={companyData?.enabled}
                  />
                </Box>
              </Box>
            </Box>

            <Box sx={{ display: "flex", alignItems: "center", gap: "7px" }}>
              <SVGButton
                color={"white"}
                source={DatabaseIconGrey}
                height={35}
                width={35}
                outerHeight={35}
                outerWidth={35}
                startIconMargin={"0px !important"}
                padding={"0px !important"}
              />
              <SVGButton
                color={"white"}
                source={CopyToClipboardIcon}
                height={35}
                width={35}
                outerHeight={35}
                outerWidth={35}
                startIconMargin={"0px !important"}
                padding={"0px !important"}
              />
              <SVGButton
                color={"white"}
                source={RedViewGrey}
                height={35}
                width={35}
                outerHeight={35}
                outerWidth={35}
                startIconMargin={"0px !important"}
                padding={"0px !important"}
              />
              <SVGButton
                color={"white"}
                source={BlueFolder}
                height={35}
                width={35}
                outerHeight={35}
                outerWidth={35}
                startIconMargin={"0px !important"}
                padding={"0px !important"}
                onClick={handleDocument}
              />
              <Popover
                open={openDocument}
                anchorEl={anchorElDocs}
                onClose={handleCloseDocument}
                sx={{
                  "& .MuiPopover-paper": {
                    borderRadius: "10px 0 10px 10px !important",
                    width: "158px !important",
                  },
                }}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
              >
                <DocumentBox handleClose={() => setOpenDocument(null)} />
              </Popover>
              <SVGButton
                color={"white"}
                source={
                  companyData?.video_url !== "No URL"
                    ? GreenPlay
                    : GreenPlayGrey
                }
                height={35}
                width={35}
                outerHeight={35}
                outerWidth={35}
                startIconMargin={"0px !important"}
                padding={"0px !important"}
                onClick={() => {
                  if (companyData?.video_url !== "No URL") {
                    handleVideo();
                  }
                }}
              />
              <Popover
                open={openVideo}
                anchorEl={anchorElDocs}
                onClose={handleCloseVideo}
                sx={{
                  "& .MuiPopover-paper": {
                    borderRadius: "10px 0 10px 10px !important",
                    width: "158px !important",
                  },
                }}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
              >
                <VideoBox
                  handleClose={() => setOpenVideo(null)}
                  jobContent={companyData}
                  text1={"Employer video"}
                  text2={"Job video"}
                  jobVideo={companyData?.job_video_url || "No URL"}
                  employerVideo={companyData?.video_url || "No URL"}
                />
              </Popover>
              <SVGButton
                color={"white"}
                source={YellowChatHistory}
                height={35}
                width={35}
                outerHeight={35}
                outerWidth={35}
                startIconMargin={"0px !important"}
                padding={"0px !important"}
                onClick={() => setOpenHistoryBox(true)}
              />
              <Box>
                <Button
                  ref={jobsRef}
                  variant="contained"
                  color="redButton"
                  onClick={() => handleJobClick()}
                  sx={{
                    fontSize: "14px",
                    fontWeight: theme.typography.Bold,
                    width: "173px",
                    fontSize: "14px",
                    fontWeight: theme.typography.Bold,
                    borderRadius: flip ? 0 : "0 0 10px 0",
                    padding: 0,
                    height: "45px",
                    gap: "17px",
                  }}
                >
                  <Box
                    component={"img"}
                    src={talentIcon}
                    height={25}
                    width={25}
                  />
                  jobs
                  <Box
                    component={"img"}
                    src={jobsDropDown ? upClose : downClose}
                    sx={{
                      height: "20px",
                      width: "13px",
                    }}
                    onClick={(event) => {
                      event.stopPropagation(); // Prevents the button's onClick from firing
                      setJobsDropDown(jobsRef?.current);
                    }}
                  />
                </Button>
              </Box>
              <Popover
                open={anchorElJobs}
                anchorEl={jobsDropDown}
                onClose={() => setJobsDropDown(null)}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                sx={{
                  "& .MuiPopover-paper": {
                    borderRadius: "25px 0 25px 25px !important",
                    width: "450px !important",
                  },
                }}
              >
                <JobsBox companyData={companyData} />
              </Popover>
            </Box>
          </Box>
        </AccordionSummary>
        <Box sx={{ width: `calc(100% - 173px)` }}>
          <Divider />
        </Box>
        <AccordionDetails sx={{ padding: "0px", margin: "0px" }}>
          <>
            <Box sx={{ display: "flex" }}>
              <Box
                sx={{
                  width: "20px",
                  height: "auto",
                  mt: "-2px",
                  background: `${theme.palette.talentButton.main}`,
                }}
              ></Box>
              <Box
                sx={{
                  width: "70%",
                  position: "relative",
                  left: "15px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    gap: "30px",
                    height: "19px",
                    position: "relative",
                    left: "65px",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: "11px",
                    }}
                  >
                    <Box
                      component={"img"}
                      src={email}
                      sx={{
                        height: "18px",
                        width: "15px",
                      }}
                    />
                    <Link
                      to={`/admin/users/user-employers/${companyData?.user_id}`}
                      target={"_blank"}
                      style={{
                        textDecoration: "none",
                        color: theme.palette.black,
                      }}
                    >
                      <Typography
                        sx={{
                          fontWeight: theme.typography.Regular,
                          fontSize: 12,
                        }}
                      >
                        {companyData?.email ? companyData?.email : "-"}
                      </Typography>
                    </Link>
                  </Box>
                  <Box
                    sx={{ display: "flex", alignItems: "center", gap: "4px" }}
                  >
                    <Box
                      component={"img"}
                      src={contact}
                      sx={{
                        height: "18px",
                        width: "15px",
                      }}
                    />
                    {companyData?.contact_no ? (
                      <>
                        <CountryFlag
                          title="United States"
                          className="..."
                          style={{ width: 22, height: 12 }}
                        />
                        <Typography
                          sx={{
                            fontWeight: theme.typography.Regular,
                            fontSize: 12,
                          }}
                        >
                          {companyData?.contact_no?.[0] !== "+"
                            ? companyData?.contact_no
                            : formatPhoneNumberIntl(companyData?.contact_no)}
                        </Typography>
                      </>
                    ) : (
                      "-"
                    )}
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: "8px",
                    }}
                  >
                    <Box
                      component={"img"}
                      src={linkedin}
                      sx={{
                        height: "18px",
                        width: "18px",
                      }}
                    />
                    {companyData?.linkedin_profile_link ? (
                      <Link
                        to={`${companyData?.linkedin_profile_link}`}
                        target={"_blank"}
                        style={{
                          textDecoration: "none",
                          color: theme.palette.black,
                        }}
                      >
                        <Typography
                          sx={{
                            fontWeight: theme.typography.Regular,
                            fontSize: 12,
                          }}
                        >
                          {companyData?.linkedin_profile_link
                            ? companyData?.linkedin_profile_link
                            : "-"}
                        </Typography>
                      </Link>
                    ) : (
                      <Typography
                        sx={{
                          fontWeight: theme.typography.Regular,
                          fontSize: 12,
                        }}
                      >
                        {companyData?.linkedin_profile_link
                          ? companyData?.linkedin_profile_link
                          : "-"}
                      </Typography>
                    )}
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      gap: "5px",
                      // position: "absolute",
                      // width: "72%",
                      // justifyContent: "flex-end",
                    }}
                  >
                    <Button
                      variant="contained"
                      color="workSetupButton"
                      sx={{
                        // minWidth: "65px",
                        fontSize: "12px",
                        fontWeight: theme.typography.Bold,
                        minWidth: "fit-content",
                        height: "25px",
                        padding: "0px 9px !important",
                        borderRadius: "0 0 10px 10px",
                      }}
                    >
                      {companyData?.company_type}
                    </Button>
                    <Button
                      variant="contained"
                      color="workSetupButton"
                      sx={{
                        // minWidth: "65px",
                        fontSize: "12px",
                        fontWeight: theme.typography.Bold,
                        minWidth: "fit-content",
                        height: "25px",
                        padding: "0px 9px !important",
                        borderRadius: "0 0 10px 10px",
                      }}
                    >
                      {companyData?.company_size}
                    </Button>
                  </Box>
                </Box>
                <Box sx={{ marginTop: "10px", maxWidth: "879px" }}>
                  <Typography
                    sx={{
                      fontSize: "12px",
                      fontWeight: theme.typography.Bold,
                      // width: "81px",
                    }}
                  >
                    {i18n["adminUsers.companyDescription"]}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "12px",
                      fontWeight: theme.typography.Regular,
                      wordBreak: "break-word",
                      width: "100%",
                      display: "-webkit-box",
                      WebkitLineClamp: viewMoreExpanded ? "none" : 3,
                      WebkitBoxOrient: "vertical",
                      overflow: "hidden",
                    }}
                  >
                    {companyData?.description ? companyData?.description : "-"}
                  </Typography>
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                  >
                    <Button
                      sx={{
                        justifyContent: "flex-end",
                        color: "black",
                        padding: 0,
                        // width: "74%",
                        fontSize: "12px !important",
                        fontWeight: theme.typography.Bold,
                        ".MuiButton-endIcon": {
                          margin: "0px !important",
                        },
                        height: "16px",
                      }}
                      endIcon={
                        <Box
                          component="img"
                          sx={{
                            height: 30,
                            width: 30,
                            cursor: "pointer",
                          }}
                          alt="crayon logo"
                          src={viewMoreExpanded ? upArrow : downArrow}
                        />
                      }
                      onClick={handleViewMore}
                    >
                      {viewMoreExpanded ? "collapse" : "expand"}
                    </Button>
                  </Box>
                </Box>
                <Box
                  sx={{
                    marginTop: "0px",
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "12px",
                      fontWeight: theme.typography.Bold,
                      minWidth: "81px",
                    }}
                  >
                    {i18n["adminUsers.industries"]}
                  </Typography>
                  <Box sx={{ width: "100%" }}>
                    {companyIndustries?.map((industry) => {
                      return (
                        <SmallButton
                          minWidth="10px"
                          height={25}
                          color="industiresButton"
                          borderRadius="5px"
                          mr="4px"
                          label={industry}
                        ></SmallButton>
                      );
                    })}
                  </Box>
                </Box>
                <Box
                  sx={{
                    marginTop: "6px",
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "12px",
                      fontWeight: theme.typography.Bold,
                      minWidth: "81px",
                    }}
                  >
                    {i18n["adminUsers.culture"]}
                  </Typography>
                  <Box sx={{ width: "100%" }}>
                    {companyCulture?.map((culture) => {
                      return (
                        <SmallButton
                          minWidth="10px"
                          height={25}
                          color="ideaGritScoreSlider"
                          borderRadius="5px"
                          mr="4px"
                          label={culture}
                        ></SmallButton>
                      );
                    })}
                  </Box>
                </Box>
                <Box
                  sx={{
                    marginTop: "6px",
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "12px",
                      fontWeight: theme.typography.Bold,
                      width: "81px",
                    }}
                  >
                    {i18n["adminUsers.plan"]}
                  </Typography>

                  <Box
                    sx={{ display: "flex", alignItems: "center", gap: "5px" }}
                  >
                    <SmallButton
                      color={`${
                        !companyData?.talent_plan
                          ? "liteButton"
                          : "submitButton"
                      }`}
                      label={"Lite"}
                      value={"Lite"}
                      fontSize="12px"
                      height="25px"
                    />

                    <SmallButton
                      color={`${
                        companyData?.talent_plan
                          ? "talentButton"
                          : "submitButton"
                      }`}
                      label={"Talent"}
                      value={"Talent"}
                      fontSize="12px"
                      height="25px"
                    />
                    <Divider
                      orientation="vertical"
                      flexItem
                      style={{
                        background: "#707070",
                      }}
                    />
                    <SmallButton
                      color={
                        companyData?.crayon_plan === "starter"
                          ? "talentButton"
                          : "submitButton"
                      }
                      label={"Starter"}
                      value={"Starter"}
                      fontSize="12px"
                      height="25px"
                    />
                    <SmallButton
                      color={
                        companyData?.crayon_plan === "scaler"
                          ? "talentButton"
                          : "submitButton"
                      }
                      label={"Scaler"}
                      value={"Scaler"}
                      fontSize="12px"
                      height="25px"
                    />
                    <SmallButton
                      color={
                        companyData?.crayon_plan === "launcher"
                          ? "talentButton"
                          : "submitButton"
                      }
                      label={"Launcher"}
                      value={"Launcher"}
                      fontSize="12px"
                      height="25px"
                    />
                    <SmallButton
                      color={
                        companyData?.crayon_plan === "orbiter"
                          ? "talentButton"
                          : "submitButton"
                      }
                      label={"Orbiter"}
                      value={"Orbiter"}
                      fontSize="12px"
                      height="25px"
                    />
                    <Box
                      component={"img"}
                      src={editIcon}
                      sx={{
                        height: "20px",
                        width: "20px",
                        cursor: "pointer",
                      }}
                      onClick={handleEditPlan}
                    />
                    <Box ref={editPlanRef}>
                      <Popover
                        id="dropdown"
                        open={open}
                        anchorEl={editPlan}
                        onClose={handleCloseEditPlan}
                        anchorOrigin={{
                          vertical: "top",
                          horizontal: "right",
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "left",
                        }}
                        sx={{
                          "& .MuiPopover-paper": {
                            boxShadow: "none !important",
                            borderRadius: "0px !important",
                            backgroundColor: "transparent !important",
                          },
                        }}
                      >
                        <EditPlan handleDorpdownClick={handleDorpdownClick} />
                      </Popover>
                    </Box>
                  </Box>
                </Box>
                <Box
                  sx={{
                    marginTop: "6px",
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "12px",
                      fontWeight: theme.typography.Bold,
                      width: "81px",
                    }}
                  >
                    {i18n["adminUsers.user"]}
                  </Typography>

                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <SmallButton
                      color={
                        companyData?.talent_plan ? "talentButton" : "liteButton"
                      }
                      label={
                        companyData?.base_counter
                          ? companyData?.base_counter
                          : 1
                      }
                      value={
                        companyData?.base_counter
                          ? companyData?.base_counter
                          : 1
                      }
                      mr="4px"
                      fontSize="12px"
                      padding="0px !important"
                      width="27px !important"
                      height="25px !important"
                    />
                    <SmallButton
                      color={
                        companyData?.talent_plan ? "talentButton" : "liteButton"
                      }
                      label={
                        companyData?.purchased_counter
                          ? `+${companyData?.purchased_counter}`
                          : "+0"
                      }
                      value={
                        companyData?.purchased_counter
                          ? `+${companyData?.purchased_counter}`
                          : "+0"
                      }
                      mr="4px"
                      fontSize="12px"
                      padding="0px !important"
                      width="27px !important"
                      height="25px !important"
                    />
                    <Box
                      component={"img"}
                      src={editIcon}
                      sx={{
                        height: "20px",
                        width: "20px",
                        cursor: "pointer",
                      }}
                      onClick={() => handleAddUser()}
                    />
                  </Box>
                </Box>
                <Box
                  sx={{
                    marginTop: "6px",
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "12px",
                      fontWeight: theme.typography.Bold,
                      width: "81px",
                    }}
                  >
                    {i18n["adminUsers.totalPools"]}
                  </Typography>
                  <Box>
                    <SmallButton
                      color={
                        companyData?.talent_plan ? "talentButton" : "liteButton"
                      }
                      label={
                        companyData?.plan_pools ? companyData?.plan_pools : 0
                      }
                      value={
                        companyData?.plan_pools ? companyData?.plan_pools : 0
                      }
                      mr="4px"
                      fontSize="12px"
                      padding="0px !important"
                      width="27px !important"
                      height="25px !important"
                    />
                    {companyData?.additional_pool > 0 && (
                      <SmallButton
                        color={
                          companyData?.talent_plan
                            ? "talentButton"
                            : "liteButton"
                        }
                        label={`+${companyData?.additional_pool}`}
                        value={`+${companyData?.additional_pool}`}
                        mr="4px"
                        fontSize="12px"
                        padding="0px !important"
                        width="27px !important"
                        height="25px !important"
                      />
                    )}
                  </Box>
                </Box>
                <Box
                  sx={{
                    marginTop: "6px",
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "12px",
                      fontWeight: theme.typography.Bold,
                      width: "81px",
                    }}
                  >
                    {i18n["adminUsers.totalSearches"]}
                  </Typography>
                  <Box>
                    <SmallButton
                      color={
                        companyData?.talent_plan ? "talentButton" : "liteButton"
                      }
                      label={
                        companyData?.plan_searces
                          ? companyData?.plan_searces
                          : 0
                      }
                      value={
                        companyData?.plan_searces
                          ? companyData?.plan_searces
                          : 0
                      }
                      mr="4px"
                      fontSize="12px"
                      padding="0px !important"
                      width="27px !important"
                      height="25px !important"
                    />
                    {companyData?.additional_search > 0 && (
                      <SmallButton
                        color={
                          companyData?.talent_plan
                            ? "talentButton"
                            : "liteButton"
                        }
                        label={`+${companyData?.additional_search}`}
                        value={`+${companyData?.additional_search}`}
                        mr="4px"
                        fontSize="12px"
                        padding="0px !important"
                        width="27px !important"
                        height="25px !important"
                      />
                    )}
                  </Box>
                </Box>
                <Box
                  sx={{
                    marginTop: "6px",
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "12px",
                      fontWeight: theme.typography.Bold,
                      width: "81px",
                    }}
                  >
                    {i18n["adminUsers.coins"]}
                  </Typography>

                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <SmallButton
                      color={"getCoins"}
                      label={companyData?.coins || 0}
                      value={companyData?.coins || 0}
                      mr="4px"
                      fontSize="12px"
                      padding="0px !important"
                      width="27px !important"
                      height="25px !important"
                    />
                    <Box
                      component={"img"}
                      src={editIcon}
                      sx={{
                        height: "20px",
                        width: "20px",
                        cursor: "pointer",
                      }}
                      // onClick={handleEditPlan}
                    />
                  </Box>
                </Box>
                <Box
                  sx={{
                    marginTop: "6px",
                    display: "flex",
                    // alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "12px",
                      fontWeight: theme.typography.Bold,
                      width: "81px",
                    }}
                  >
                    {i18n["adminUsers.billingInfo"]}
                  </Typography>
                  {
                    isBillDetail?<Box
                    style={{
                      margin: "15px 0 0 0",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <CircularProgress color="inherit" size={20} />
                  </Box>:
                    billingFormData?.company_id ? (
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                          {billingFormData?.company_name && (
                          <Typography
                            sx={{
                              fontSize: "12px",
                              fontWeight: theme.typography.Medium,
                            }}
                          >
                            {billingFormData?.company_name}
                          </Typography>
                        )}
                        {billingFormData?.company_address_1 && (
                          <Typography
                            sx={{
                              fontSize: "12px",
                              fontWeight: theme.typography.Medium,
                            }}
                          >
                            {billingFormData?.company_address_1}
                          </Typography>
                        )}
                        {billingFormData?.company_address_2 && (
                          <Typography
                            sx={{
                              fontSize: "12px",
                              fontWeight: theme.typography.Medium,
                            }}
                          >
                            {billingFormData?.company_address_2}
                          </Typography>
                        )}
                        {billingFormData?.company_address_3 && (
                          <Typography
                            sx={{
                              fontSize: "12px",
                              fontWeight: theme.typography.Medium,
                            }}
                          >
                            {billingFormData?.company_address_3}
                          </Typography>
                        )}
                        {(billingFormData?.town_name ||
                          billingFormData?.state) && (
                          <Typography
                            sx={{
                              fontSize: "12px",
                              fontWeight: theme.typography.Medium,
                            }}
                          >
                            {billingFormData?.town_name && billingFormData?.state
                              ? `${billingFormData?.town_name}, ${billingFormData?.state}`
                              : billingFormData?.town_name
                              ? `${billingFormData?.town_name}`
                              : `${billingFormData?.state}`}
                          </Typography>
                        )}
                        {billingFormData?.country_name && (
                          <Typography
                            sx={{
                              fontSize: "12px",
                              fontWeight: theme.typography.Medium,
                            }}
                          >
                            {billingFormData?.country_name}
                          </Typography>
                        )}
  
                        <Typography
                          sx={{
                            fontSize: "12px",
                            fontWeight: theme.typography.Medium,
                          }}
                        >
                          {i18n["adminUsers.vatNumber"]}{" "}
                          {billingFormData?.vat_number}
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: "12px",
                            fontWeight: theme.typography.Medium,
                          }}
                        >
                          {i18n["adminUsers.financePerson"]}{" "}
                          {billingFormData?.finanace_contact_person}
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: "12px",
                            fontWeight: theme.typography.Medium,
                          }}
                        >
                          {i18n["adminUsers.financeContact"]}{" "}
                          {billingFormData?.finance_contact_email}
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: "12px",
                            fontWeight: theme.typography.Medium,
                          }}
                        >
                          Finance contact phone:{" "}
                          {billingFormData?.finance_contact_number}
                        </Typography>
                        {/* <Typography
                          sx={{
                            fontSize: "12px",
                            fontWeight: theme.typography.Medium,
                          }}
                        >
                          {i18n["adminUsers.creditCard"]} 
                        </Typography> */}
                      </Box>
                    ) : (
                      <Typography
                        sx={{
                          fontSize: "12px",
                          fontWeight: theme.typography.Bold,
                        }}
                      >
                        No Data Available
                      </Typography>
                    )}
                  
                </Box>
              </Box>
              <Box
                sx={{
                  width: "30%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-end",
                  mt: "-1px",
                }}
              >
                <Button
                  ref={linkToPoolRef}
                  onClick={() => setLinkToPool(linkToPoolRef?.current)}
                  variant="contained"
                  color="recordVideoButton"
                  sx={{
                    width: "173px",
                    fontSize: "14px",
                    fontWeight: theme.typography.Bold,
                    height: "45px",
                    borderRadius: "0px",
                    justifyContent: "flex-start",
                    gap: "8px",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: "12px",
                    }}
                  >
                    <Box
                      component={"img"}
                      src={anchorElLinkToPool ? rightIcon : leftIcon}
                      sx={{ width: 12, height: 12 }}
                    />
                    <Box
                      component={"img"}
                      src={linkToPool}
                      sx={{ width: 20, height: 20 }}
                    />
                  </Box>
                  <Typography
                    sx={{
                      fontSize: "14px",
                      fontWeight: theme.typography.Bold,
                    }}
                  >
                    talent pools
                  </Typography>
                </Button>
                <Popover
                  open={anchorElLinkToPool}
                  anchorEl={linkToPoolButton}
                  onClose={() => setLinkToPool(null)}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  sx={{
                    "& .MuiPopover-paper": {
                      borderRadius: "25px !important",
                      width: "450px !important",
                    },
                  }}
                >
                  <CompanyTalentPool companyData={companyData} />
                </Popover>
                <Button
                  ref={coinHistoryRef}
                  onClick={() => setCoinHistory(coinHistoryRef?.current)}
                  variant="contained"
                  color="getCoins"
                  sx={{
                    width: "173px",
                    fontSize: "14px",
                    fontWeight: theme.typography.Bold,
                    height: "45px",
                    borderRadius: "0px",
                    justifyContent: "flex-start",
                    gap: "43px",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: "12px",
                    }}
                  >
                    <Box
                      component={"img"}
                      src={anchorElCoinHistory ? rightIcon : leftIcon}
                      sx={{ width: 12, height: 12 }}
                    />
                  </Box>
                  <Typography
                    sx={{
                      fontSize: "14px",
                      fontWeight: theme.typography.Bold,
                    }}
                  >
                    coin history
                  </Typography>
                </Button>
                <Popover
                  open={anchorElCoinHistory}
                  anchorEl={coinHistory}
                  onClose={() => setCoinHistory(null)}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  sx={{
                    "& .MuiPopover-paper": {
                      borderRadius: "25px !important",
                      width: "450px !important",
                    },
                  }}
                >
                  <CoinHistoryBox
                    company_id={companyData?.company_id}
                    coins={companyData?.coins || 0}
                    setCompanyTopUp={setCompanyTopUp}
                    setCoinHistory={setCoinHistory}
                    coinHistoryRef={coinHistoryRef}
                  />
                </Popover>
                <Popover
                  open={anchorElCompanyTopUp}
                  anchorEl={companyTopUp}
                  onClose={() => setCompanyTopUp(null)}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  sx={{
                    "& .MuiPopover-paper": {
                      borderRadius: "25px !important",
                      width: "450px !important",
                    },
                  }}
                >
                  <CompanyTopUp
                    companyData={companyData}
                    coins={companyData?.coins}
                    setCompanyTopUp={setCompanyTopUp}
                    setCompanyData={setCompanyData}
                    allCompanyData={allCompanyData}
                  />
                </Popover>

                <Button
                  ref={commentRef}
                  onClick={() => setCommentButton(commentRef?.current)}
                  variant="contained"
                  color="activeButton"
                  sx={{
                    width: "173px",
                    fontSize: "14px",
                    fontWeight: theme.typography.Bold,
                    height: "45px",
                    borderRadius: "0px",
                    justifyContent: "flex-start",
                    gap: "10px",
                    padding: "6px 0px 6px 15px !important",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: "12px",
                    }}
                  >
                    <Box
                      component={"img"}
                      src={anchorElComment ? rightIcon : leftIcon}
                      sx={{ width: 12, height: 12 }}
                    />
                    <Box
                      component={"img"}
                      src={commentIcon}
                      sx={{ width: 20, height: 20 }}
                    />
                  </Box>
                  <Typography
                    sx={{
                      fontSize: "14px",
                      fontWeight: theme.typography.Bold,
                    }}
                  >
                    {i18n["allTalent.comments"]} ({totalCommentCount})
                  </Typography>
                </Button>
                <Popover
                  open={anchorElComment}
                  anchorEl={commentButton}
                  onClose={() => setCommentButton(null)}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  sx={{
                    "& .MuiPopover-paper": {
                      borderRadius: "25px !important",
                      width: "450px !important",
                    },
                  }}
                >
                  <CommentBox
                    talentData={companyData}
                    page={"company"}
                    totalCount={totalCommentCount}
                    setTotalCommentCount={setTotalCommentCount}
                  />
                </Popover>

                <Button
                  ref={teamRef}
                  onClick={() => setTeamButton(teamRef?.current)}
                  variant="contained"
                  color="ideaGritScoreSlider"
                  sx={{
                    width: "173px",
                    fontSize: "14px",
                    fontWeight: theme.typography.Bold,
                    height: "45px",
                    borderRadius: "0px",
                    justifyContent: "flex-start",
                    gap: "10px",
                    padding: "6px 0px 6px 15px !important",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <Box
                      component={"img"}
                      src={anchorElTeam ? rightIcon : leftIcon}
                      sx={{ width: 12, height: 12 }}
                    />
                    <Box
                      component={"img"}
                      src={teamIcon}
                      sx={{ width: 28, height: 28 }}
                    />
                  </Box>
                  <Typography
                    sx={{
                      fontSize: "14px",
                      fontWeight: theme.typography.Bold,
                    }}
                  >
                    {"team"} ({teamMemberCount})
                  </Typography>
                </Button>
                <Popover
                  open={anchorElTeam}
                  anchorEl={teamButton}
                  onClose={() => setTeamButton(null)}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  sx={{
                    "& .MuiPopover-paper": {
                      borderRadius: "25px !important",
                      width: "450px !important",
                    },
                  }}
                >
                  <TeamMemberBox
                    text={i18n["adminUsers.teammembertext"]}
                    teamMember={teamMember}
                    getTeamMember={getTeamMember}
                    lastKey={lastKey}
                    teamMemberCount={teamMemberCount}
                    buttonText={"team"}
                    icon={teamIcon}
                    color={"ideaGritScoreSlider"}
                    iconwidth={28}
                    iconheight={28}
                    showBelowtext={true}
                    height={"526px"}
                  />
                </Popover>

                <Button
                  ref={companiesRef}
                  onClick={() => setCompanyButton(companiesRef?.current)}
                  variant="contained"
                  color="recentButton"
                  sx={{
                    width: "173px",
                    fontSize: "14px",
                    fontWeight: theme.typography.Bold,
                    height: "45px",
                    borderRadius: "0px",
                    justifyContent: "flex-start",
                    gap: "8px",
                    padding: "6px 0px 6px 15px !important",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: "13px",
                    }}
                  >
                    <Box
                      component={"img"}
                      src={anchorElCompany ? rightIcon : leftIcon}
                      sx={{ width: 12, height: 12 }}
                    />
                    <Box
                      component={"img"}
                      src={companiesIcon}
                      sx={{ width: 22, height: 22 }}
                    />
                  </Box>
                  <Typography
                    sx={{
                      fontSize: "14px",
                      fontWeight: theme.typography.Bold,
                    }}
                  >
                    {"companies"} ({companiesCount})
                  </Typography>
                </Button>
                <Popover
                  open={anchorElCompany}
                  anchorEl={companyButton}
                  onClose={() => setCompanyButton(null)}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  sx={{
                    "& .MuiPopover-paper": {
                      borderRadius: "25px !important",
                      width: "450px !important",
                    },
                  }}
                >
                  <TeamMemberBox
                    text={i18n["adminUsers.companiestext"]}
                    teamMember={[]}
                    getTeamMember={() => {}}
                    lastKey={lastKey}
                    teamMemberCount={companiesCount}
                    buttonText={"companies"}
                    icon={companiesIcon}
                    color={"recentButton"}
                    iconwidth={22}
                    iconheight={22}
                    showBelowtext={false}
                    height={"295px"}
                  />
                </Popover>

                <Button
                  ref={recruiterRef}
                  onClick={() => setRecruiterButton(recruiterRef?.current)}
                  variant="contained"
                  color="recruiterButton"
                  sx={{
                    width: "173px",
                    fontSize: "14px",
                    fontWeight: theme.typography.Bold,
                    height: "45px",
                    borderRadius: "0px",
                    justifyContent: "flex-start",
                    gap: "10px",
                    padding: "6px 0px 6px 15px !important",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <Box
                      component={"img"}
                      src={anchorElRecruiter ? rightIcon : leftIcon}
                      sx={{ width: 12, height: 12 }}
                    />
                    <Box
                      component={"img"}
                      src={teamIcon}
                      sx={{ width: 28, height: 28 }}
                    />
                  </Box>
                  <Typography
                    sx={{
                      fontSize: "14px",
                      fontWeight: theme.typography.Bold,
                    }}
                  >
                    {"recruiter"} ({recruiterCount})
                  </Typography>
                </Button>
                <Popover
                  open={anchorElRecruiter}
                  anchorEl={recruiterButton}
                  onClose={() => setRecruiterButton(null)}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  sx={{
                    "& .MuiPopover-paper": {
                      borderRadius: "25px !important",
                      width: "450px !important",
                    },
                  }}
                >
                  <TeamMemberBox
                    text={i18n["adminUsers.recruitertext"]}
                    teamMember={recruiter}
                    getTeamMember={getTeamRecruiter}
                    lastKey={recruiterLastKey}
                    teamMemberCount={recruiterCount}
                    buttonText={"recruiter"}
                    icon={teamIcon}
                    color={"recruiterButton"}
                    iconwidth={28}
                    iconheight={28}
                    showBelowtext={true}
                    height={"178px"}
                  />
                </Popover>

                <Button
                  ref={promoterRef}
                  onClick={() => setPromoterButton(promoterRef?.current)}
                  variant="contained"
                  color="promoterButton"
                  sx={{
                    width: "173px",
                    fontSize: "14px",
                    fontWeight: theme.typography.Bold,
                    height: "45px",
                    borderRadius: "0 0 0 25px",
                    justifyContent: "flex-start",
                    gap: "10px",
                    padding: "6px 0px 6px 15px !important",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: "14px",
                    }}
                  >
                    <Box
                      component={"img"}
                      src={anchorElPromoter ? rightIcon : leftIcon}
                      sx={{ width: 12, height: 12 }}
                    />
                    <Box
                      component={"img"}
                      src={referrals}
                      sx={{ width: 22, height: 22 }}
                    />
                  </Box>
                  <Typography
                    sx={{
                      fontSize: "14px",
                      fontWeight: theme.typography.Bold,
                    }}
                  >
                    {"promoter"} ({promoterCount})
                  </Typography>
                </Button>
                <Popover
                  open={anchorElPromoter}
                  anchorEl={promoterButton}
                  onClose={() => setPromoterButton(null)}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  sx={{
                    "& .MuiPopover-paper": {
                      borderRadius: "25px !important",
                      width: "450px !important",
                    },
                  }}
                >
                  <TeamMemberBox
                    text={i18n["adminUsers.promotertext"]}
                    teamMember={promoter}
                    getTeamMember={getTeamPromoter}
                    lastKey={promoterLastKey}
                    teamMemberCount={promoterCount}
                    buttonText={"promoter"}
                    icon={referrals}
                    color={"promoterButton"}
                    iconwidth={22}
                    iconheight={22}
                    showBelowtext={false}
                    height={"514px"}
                  />
                </Popover>
              </Box>
            </Box>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Box
                sx={{
                  width: "20px",
                  height: "auto",
                  borderRadius: "0 0 0 10px",
                  background: `${theme.palette.talentButton.main}`,
                }}
              ></Box>
              <Button
                variant="contained"
                color="redButton"
                sx={{
                  borderRadius: "5px 5px 0 0 ",
                  width: 75,
                  height: 20,
                }}
                onClick={() => setFlip((prev) => !prev)}
              >
                <Box
                  component={"img"}
                  src={upClose}
                  sx={{
                    height: 10,
                    width: 10,
                  }}
                />
              </Button>
              <Box
                sx={{
                  width: "20px",
                  height: "auto",
                }}
              />
            </Box>
          </>
        </AccordionDetails>
        {openEditImage && (
          <CropModal
            handleOpen={openEditImage}
            handleClose={setOpenEditImage}
            handleSubscriptionOk={handleSubscriptionOk}
            handleSubscriptionCancel={handleSubscriptionCancel}
            imagePreview={imagePreview}
            image={image}
            setImage={setImage}
            croppedImageUrl={croppedImageUrl}
            setCroppedImageUrl={setCroppedImageUrl}
            imageBlob={imageBlob}
            setImageBlob={setImageBlob}
          />
        )}
      </UserCardStyledAccordion>
      {openEdit && (
        <EditCompany
          show={openEdit}
          editCompany={editCompany}
          handleClose={handleClose}
          getCompanyLists={getCompanyList}
          pinCompany={companyData?.pin_company}
          setAllCompanyData={setCompanyData}
          allCompanyData={companyData}
        />
      )}
      {isOpenHistoryBox && (
        <HistoryBox
          handleOpen={isOpenHistoryBox}
          handleClose={() => setOpenHistoryBox(false)}
          openFor={"jobs"}
          email={companyData?.email}
        />
      )}
      {showUnlockBox && (
        <AdminUnlockJob
          text1={i18n["myplanPopup.confirmcoinspend"]}
          text1Width={"186px"}
          text2={`${companyData?.name} ${i18n["myplanPopup.employercoinbalance"]}`}
          coinsBalance={`${coinsInfo?.coins} coins.`}
          coinsUse={`${coinsInfo?.unlock_amount} coins.`}
          text2Width={"290px"}
          text3={`${i18n["myplanPopup.chargeemployer"]} ${companyData?.name} for`}
          text3Width={"270px"}
          text3Height={"72px"}
          // text4={i18n["myplanPopup.pleaseconfirm"]}
          // text4Width={"220px"}
          // buttonText={i18n["myplanPopup.happyletsdothis"]}
          handleClose={() => {
            setShowUnlockBox(false);
            setConfirmUnlock(false);
          }}
          show={showUnlockBox}
          buttonClick={handleCompanyAddUsers}
          gap={"18px"}
          setConfirmUnlock={setConfirmUnlock}
          confirmUnlock={confirmUnlock}
        />
      )}
    </>
  );
}
