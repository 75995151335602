export const DRAWER_WIDTH = 240;
export const PROFILE_COMPLETION_NUMBER = 50;

export const APPLICANT = "applicants";
export const SHORTLIST = "shortlisted";
export const INTERVIEW = "interviews";
export const MATCHED = "matched";
export const APPLIED = "applied";

export const SALARY_VALUE_OPTION = [
  {
    id: 1,
    name: "single value slider",
  },
  { id: 2, name: "range value slider" },
];

export const TEMPLATE_NAME = [
  {
    id: 1,
    name: "Crayon",
    selected: true,
  },
  {
    id: 2,
    name: "Classic",
    selected: false,
  },
  {
    id: 3,
    name: "Corporate",
    selected: false,
  },
];

export const skinValue = {
  white: 0,
  coloured: 1,
  indian: 2,
  black: 3,
};

export const  websiteUrlRegex =/^(https?:\/\/www\.|https?:\/\/|www\.)[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)+.*$/;

export const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
export const isValidLinkedInUrl = (url) => {
  // const regex = /^https?:\/\/(www\.)?linkedin\.com\/in\/[\w-]+\/?$/;
  var regex =
    /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/|www\.)[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/[^\s]*)?$/;
  return regex.test(url);
};

export const SOLUTION_LIST = [
  {
    id: 1,
    challange_heading: "Industry challenge…",
    industry_challange:
      "It takes too much time to review the volume of applications when I post a job.",
    solution_type: [{ id: 1, name: "filtering" }],
    industry_solution:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud",
    solution_heading: "…our solution",
    crayon_challange:
      "Crayon incorporates quick filters throughout the hiring process.",
    crayon_solution:
      "Your job management dashboard allows you to sort incoming talent by a relevance match %",
    user_type: [
      { id: 1, name: "Employers" },
      { id: 2, name: "Recruiters" },
    ],
    challange_video:
      "https://job-application-video.s3.eu-west-1.amazonaws.com/127936/1456/1456.webm?AWSAccessKeyId=AKIA5CN2EPLV7HM6BYP4&Expires=1716902514&Signature=ST3Lh4VNg3K3hT39U6e%2BFwcz8X0%3D&response-content-type=video%2Fwebm",
    solution_video:
      "https://job-application-video.s3.eu-west-1.amazonaws.com/127936/1456/1456.webm?AWSAccessKeyId=AKIA5CN2EPLV7HM6BYP4&Expires=1716902514&Signature=ST3Lh4VNg3K3hT39U6e%2BFwcz8X0%3D&response-content-type=video%2Fwebm",
  },
  {
    id: 2,
    challange_heading: "Industry challenge…",
    industry_challange:
      "It takes too much time to review the volume of applications when I post a job.",
    solution_type: [
      { id: 1, name: "referrals" },
      { id: 2, name: "Crayon Bounty" },
    ],
    industry_solution:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud",
    solution_heading: "…our solution",
    crayon_challange:
      "Crayon incorporates quick filters throughout the hiring process.",
    crayon_solution:
      "Your job management dashboard allows you to sort incoming talent by a relevance match %",
    user_type: [
      { id: 1, name: "Candidates" },
      { id: 2, name: "Promoters" },
    ],
    challange_video:
      "https://job-application-video.s3.eu-west-1.amazonaws.com/127936/1456/1456.webm?AWSAccessKeyId=AKIA5CN2EPLV7HM6BYP4&Expires=1716902514&Signature=ST3Lh4VNg3K3hT39U6e%2BFwcz8X0%3D&response-content-type=video%2Fwebm",
    solution_video:
      "https://job-application-video.s3.eu-west-1.amazonaws.com/127936/1456/1456.webm?AWSAccessKeyId=AKIA5CN2EPLV7HM6BYP4&Expires=1716902514&Signature=ST3Lh4VNg3K3hT39U6e%2BFwcz8X0%3D&response-content-type=video%2Fwebm",
  },
  {
    id: 3,
    challange_heading: "Industry challenge…",
    industry_challange:
      "There is no transparency in terms of who is hiring and what stage the job is at.",
    solution_type: [
      { id: 1, name: "filtering" },
      { id: 2, name: "information" },
    ],
    industry_solution:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud",
    solution_heading: "…our solution",
    crayon_challange:
      "Crayon encourages employers and recruiters to publish company names, salaries, etc. and candidates are kept informed of their application stage throughout the hiring journey.",
    crayon_solution:
      "Your job management dashboard allows you to sort incoming talent by a relevance match %",
    user_type: [
      { id: 1, name: "Employers" },
      { id: 2, name: "Recruiters" },
      { id: 3, name: "Candidates" },
      { id: 4, name: "Promoters" },
    ],
    challange_video:
      "https://job-application-video.s3.eu-west-1.amazonaws.com/127936/1456/1456.webm?AWSAccessKeyId=AKIA5CN2EPLV7HM6BYP4&Expires=1716902514&Signature=ST3Lh4VNg3K3hT39U6e%2BFwcz8X0%3D&response-content-type=video%2Fwebm",
    solution_video:
      "https://job-application-video.s3.eu-west-1.amazonaws.com/127936/1456/1456.webm?AWSAccessKeyId=AKIA5CN2EPLV7HM6BYP4&Expires=1716902514&Signature=ST3Lh4VNg3K3hT39U6e%2BFwcz8X0%3D&response-content-type=video%2Fwebm",
  },
  {
    id: 4,
    challange_heading: "Industry challenge…",
    industry_challange:
      "It takes too much time to review the volume of applications when I post a job.",
    solution_type: [{ id: 1, name: "filtering" }],
    industry_solution:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud",
    solution_heading: "…our solution",
    crayon_challange:
      "Crayon incorporates quick filters throughout the hiring process.",
    crayon_solution:
      "Your job management dashboard allows you to sort incoming talent by a relevance match %",
    user_type: [
      { id: 1, name: "Employers" },
      { id: 2, name: "Recruiters" },
    ],
    challange_video:
      "https://job-application-video.s3.eu-west-1.amazonaws.com/127936/1456/1456.webm?AWSAccessKeyId=AKIA5CN2EPLV7HM6BYP4&Expires=1716902514&Signature=ST3Lh4VNg3K3hT39U6e%2BFwcz8X0%3D&response-content-type=video%2Fwebm",
    solution_video:
      "https://job-application-video.s3.eu-west-1.amazonaws.com/127936/1456/1456.webm?AWSAccessKeyId=AKIA5CN2EPLV7HM6BYP4&Expires=1716902514&Signature=ST3Lh4VNg3K3hT39U6e%2BFwcz8X0%3D&response-content-type=video%2Fwebm",
  },
];

export const USER_TYPE = [
  {
    id: 1111,
    name: "user type",
    title: true,
  },
  {
    id: 1,
    name: "Employers",
  },
  {
    id: 2,
    name: "Recruiters",
  },
  {
    id: 3,
    name: "Candidates",
  },
  {
    id: 4,
    name: "Promoters",
  },
];
export const CONTACT_TYPE = [
  {
    id: 1111,
    name: "get in touch",
    title: true,
  },
  {
    id: 1,
    name: "help!",
  },
  {
    id: 2,
    name: "bugs",
  },
  {
    id: 3,
    name: "book a demo",
  },
  {
    id: 4,
    name: "suggestion box",
  },
];
export const SOLUTION_TYPE = [
  {
    id: 1111,
    name: "solution type",
    title: true,
  },
  {
    id: 1,
    name: "CV's",
  },
  {
    id: 2,
    name: "filtering",
  },
  {
    id: 3,
    name: "pricing",
  },
  {
    id: 4,
    name: "branding",
  },
  {
    id: 5,
    name: "referrals",
  },
  {
    id: 6,
    name: "Crayon Bounty",
  },
  {
    id: 7,
    name: "transparency",
  },
  {
    id: 8,
    name: "information",
  },
];

export const COINS_PRICE = [
  {
    id: 1,
    currency: "ZAR",
    amount: 95,
  },
  {
    id: 2,
    currency: "USD",
    amount: 5.95,
  },
];

export const COINS_STATUS = [
  // {
  //   id: 1,
  //   name: "Coins",
  // },
  {
    id: 2,
    name: "Pending",
  },
  // {
  //   id: 3,
  //   name: "Paid",
  // },
  {
    id: 4,
    name: "Approved",
  },
  // {
  //   id: 5,
  //   name: "Voucher",
  // },
  // {
  //   id: 6,
  //   name: "Talent",
  // },
];

export const SPEND_ITEMS = [
  {
    id: 1,
    name: "JobUnlock10",
    coins: 10,
    discount: "",
  },
  {
    id: 2,
    name: "JobUnlock20",
    coins: 20,
    discount: "",
  },
  {
    id: 3,
    name: "JobUnlock30",
    coins: 30,
    discount: "",
  },
  {
    id: 4,
    name: "AddUser10",
    coins: 10,
    discount: "",
  },
  {
    id: 5,
    name: "TopUp10",
    coins: 10,
    discount: "",
  },
  {
    id: 6,
    name: "TopUp25",
    coins: 25,
    discount: "5%",
  },
  {
    id: 7,
    name: "TopUp50",
    coins: 50,
    discount: "10%",
  },
  {
    id: 8,
    name: "TopUp100",
    coins: 100,
    discount: "20%",
  },
  {
    id: 9,
    name: "TalentTrial50",
    coins: 50,
    discount: "",
  },
  {
    id: 10,
    name: "CVUnlock1",
    coins: 1,
    discount: "",
  },
  {
    id: 11,
    name: "VideoUnlock1",
    coins: 1,
    discount: "",
  },
];

export const VOUCHERS = [
  {
    id: 1,
    voucher_name: "GRINDSTONE100",
    coins: 100,
    multiple_use: true,
    duration: "90 days",
    claims: 21,
    date_created: "21 March 2024",
    expiry_created: "21 June 2024",
    created_by: "Mike Jones",
    status: true,
  },
  {
    id: 2,
    voucher_name: "CRAYON100",
    coins: 100,
    multiple_use: true,
    duration: "90 days",
    claims: 34,
    date_created: "18 March 2024",
    expiry_created: "18 June 2024",
    created_by: "Mickey Mouse",
    status: true,
  },
  {
    id: 3,
    voucher_name: "STARTUPCLUB100",
    coins: 100,
    multiple_use: true,
    duration: "90 days",
    claims: 97,
    date_created: "28 June 2024",
    expiry_created: "28 September 2024",
    created_by: "Donal Duck",
    status: true,
  },
  {
    id: 4,
    voucher_name: "FREE50",
    coins: 50,
    multiple_use: true,
    duration: "30 days",
    claims: 12,
    date_created: "12 May 2024",
    expiry_created: "12 June 2024",
    created_by: "Daffy Duck",
    status: true,
  },
  {
    id: 5,
    voucher_name: "JOBPOST20",
    coins: 20,
    multiple_use: false,
    duration: "1 day",
    claims: 20,
    date_created: "20 July 2024",
    expiry_created: "21 July 2024",
    created_by: "Buzz Lightyear",
    status: false,
  },
];

export const VOUCHER_USE = [
  {
    id: 1,
    name: "single",
  },
  {
    id: 2,
    name: "multiple",
  },
];

export const VOUCHER_INFO = {
  voucher_name: "",
  coins: "",
  use: "",
  duration: "",
  expiry_date: "",
};

export const COIN_SPEND = {
  company_name: "",
  company_user: "",
  spend_item: "",
  associated_job: "",
  transaction_type: true,
};

export const COIN_HISTORY = [
  {
    spend_id: 100,
    company_name: "Nedbank",
    first_name: "Mike",
    last_name: "Jones",
    items: "JobUnlock20",
    id: 1245,
    coins: 20,
    date: "21 August 2024",
    symbol: "R",
    cost: 0,
    invoice: false,
    paid: false,
    status: "Coins",
    balance: 80,
  },
  {
    spend_id: 99,
    company_name: "Mediology",
    first_name: "Mickey",
    last_name: "Mouse",
    items: "JobUnlock20",
    id: 1244,
    coins: 20,
    date: "18 July 2024",
    symbol: "R",
    cost: 1900,
    invoice: true,
    paid: true,
    status: "Pending",
    balance: 0,
  },
  {
    spend_id: 98,
    company_name: "Capitec",
    first_name: "Donal",
    last_name: "Duck",
    items: "JobUnlock30",
    id: 1243,
    coins: 30,
    date: "28 July 2024",
    symbol: "R",
    cost: 2850,
    invoice: true,
    paid: true,
    status: "Paid",
    balance: 30,
  },
  {
    spend_id: 97,
    company_name: "iView Labs",
    first_name: "Daffy",
    last_name: "Duck",
    items: "AddUser10",
    id: 131345,
    coins: 10,
    date: "12 June 2024",
    symbol: "R",
    cost: 950,
    invoice: true,
    paid: true,
    status: "Pending",
    balance: 0,
  },
  {
    spend_id: 96,
    company_name: "Ozow",
    first_name: "Buzz",
    last_name: "Lightyear",
    items: "TopUp10",
    id: 845,
    coins: 10,
    date: "20 May 2024",
    symbol: "R",
    cost: 950,
    invoice: true,
    paid: true,
    status: "Pending",
    balance: 0,
  },
  {
    spend_id: 95,
    company_name: "Click learning",
    first_name: "Test",
    last_name: "Admin 1",
    items: "TopUp50",
    id: 912,
    coins: 50,
    date: "14 April 2024",
    symbol: "R",
    cost: 4275,
    invoice: true,
    paid: true,
    status: "Pending",
    balance: 0,
  },
  {
    spend_id: 94,
    company_name: "Adbobe",
    first_name: "Test",
    last_name: "Admin 2",
    items: "TalentTrial50",
    id: 842,
    coins: 50,
    date: "10 April 2024",
    symbol: "$",
    cost: 297.5,
    invoice: true,
    paid: true,
    status: "Approved",
    balance: 50,
  },
  {
    spend_id: 93,
    company_name: "Recruiter",
    first_name: "Recruiter",
    last_name: "1",
    items: "CRAYON100",
    id: 984,
    coins: 100,
    date: "15 March 2024",
    symbol: "R",
    cost: 0,
    invoice: false,
    paid: false,
    status: "Voucher",
    balance: 0,
  },
];

export const CLAIMS = [
  {
    id: 1,
    profile_url: "No URL",
    company_name: "Nedbank Team",
    first_name: "James",
    last_name: "Blunt",
    date_claimed: "24 July 2023",
    expiry_date: "24 July 2023",
  },
  {
    id: 2,
    profile_url: "No URL",
    company_name: "Strategy Team",
    first_name: "Michael",
    last_name: "Jackson",
    date_claimed: "24 July 2023",
    expiry_date: "24 July 2023",
  },
  {
    id: 3,
    profile_url: "No URL",
    company_name: "Media Team",
    first_name: "Taylor",
    last_name: "Taylor Swift",
    date_claimed: "24 July 2023",
    expiry_date: "24 July 2023",
  },
  {
    id: 4,
    profile_url: "No URL",
    company_name: "Adobe",
    first_name: "Mike",
    last_name: "Jones",
    date_claimed: "24 July 2023",
    expiry_date: "24 July 2023",
  },
  {
    id: 5,
    profile_url: "No URL",
    company_name: "iView Labs",
    first_name: "Happy",
    last_name: "Gilmore",
    date_claimed: "24 July 2023",
    expiry_date: "24 July 2023",
  },
  {
    id: 6,
    profile_url: "No URL",
    company_name: "Nedbank Team",
    first_name: "James",
    last_name: "Blunt",
    date_claimed: "24 July 2023",
    expiry_date: "24 July 2023",
  },
  {
    id: 7,
    profile_url: "No URL",
    company_name: "Strategy Team",
    first_name: "Michael",
    last_name: "Jackson",
    date_claimed: "24 July 2023",
    expiry_date: "24 July 2023",
  },
  {
    id: 8,
    profile_url: "No URL",
    company_name: "Media Team",
    first_name: "Taylor",
    last_name: "Taylor Swift",
    date_claimed: "24 July 2023",
    expiry_date: "24 July 2023",
  },
  {
    id: 9,
    profile_url: "No URL",
    company_name: "Adobe",
    first_name: "Mike",
    last_name: "Jones",
    date_claimed: "24 July 2023",
    expiry_date: "24 July 2023",
  },
  {
    id: 10,
    profile_url: "No URL",
    company_name: "iView Labs",
    first_name: "Happy",
    last_name: "Gilmore",
    date_claimed: "24 July 2023",
    expiry_date: "24 July 2023",
  },
];

export const COMPANY_CLAIMS = [
  {
    id: 1,
    profile_url: "No URL",
    voucher_name: "STARTUPCLUB100",
    first_name: "James",
    last_name: "Blunt",
    date_claimed: "24 July 2023",
    expiry_date: "24 July 2023",
  },
  {
    id: 2,
    profile_url: "No URL",
    voucher_name: "FREE50",
    first_name: "Michael",
    last_name: "Jackson",
    date_claimed: "24 July 2023",
    expiry_date: "24 July 2023",
  },
  {
    id: 3,
    profile_url: "No URL",
    voucher_name: "JOBPOST20",
    first_name: "Taylor",
    last_name: "Taylor Swift",
    date_claimed: "24 July 2023",
    expiry_date: "24 July 2023",
  },
  {
    id: 4,
    profile_url: "No URL",
    voucher_name: "NEDBANK20",
    first_name: "Mike",
    last_name: "Jones",
    date_claimed: "24 July 2023",
    expiry_date: "24 July 2023",
  },
  {
    id: 5,
    profile_url: "No URL",
    voucher_name: "FREE50",
    first_name: "Happy",
    last_name: "Gilmore",
    date_claimed: "24 July 2023",
    expiry_date: "24 July 2023",
  },
  {
    id: 6,
    profile_url: "No URL",
    voucher_name: "JOBPOST20",
    first_name: "James",
    last_name: "Blunt",
    date_claimed: "24 July 2023",
    expiry_date: "24 July 2023",
  },
  {
    id: 7,
    profile_url: "No URL",
    voucher_name: "NEDBANK20",
    first_name: "Michael",
    last_name: "Jackson",
    date_claimed: "24 July 2023",
    expiry_date: "24 July 2023",
  },
  {
    id: 8,
    profile_url: "No URL",
    voucher_name: "FREE50",
    first_name: "Taylor",
    last_name: "Taylor Swift",
    date_claimed: "24 July 2023",
    expiry_date: "24 July 2023",
  },
  {
    id: 9,
    profile_url: "No URL",
    voucher_name: "JOBPOST20",
    first_name: "Mike",
    last_name: "Jones",
    date_claimed: "24 July 2023",
    expiry_date: "24 July 2023",
  },
  {
    id: 10,
    profile_url: "No URL",
    voucher_name: "NEDBANK20",
    first_name: "Happy",
    last_name: "Gilmore",
    date_claimed: "24 July 2023",
    expiry_date: "24 July 2023",
  },
];

export const CRAYON_LITE = [
  {
    id: 1,
    text: "Job posting via Crayon Direct or Recruit",
  },
  {
    id: 2,
    text: "Job management dashboard ATS",
  },
  {
    id: 3,
    text: "Crayon Bounty sharing and tracking",
  },
  {
    id: 4,
    text: "Company page",
  },
  {
    id: 5,
    text: "Company following",
  },
  {
    id: 6,
    text: "Employer brand and job videos",
  },
  {
    id: 7,
    text: "Team management",
  },
];

export const CRAYON_TALENT = [
  {
    id: 1,
    text: "Access to our global candidate pool",
  },
  {
    id: 2,
    text: "Talent pool management",
  },
  {
    id: 3,
    text: "Advanced search functionality",
  },
  {
    id: 4,
    text: "Unlimited CV and video unlocks",
  },
  {
    id: 5,
    text: "Monthly bonus coins",
  },
  {
    id: 6,
    text: "Reduced hiring fees with Crayon Recruit",
  },
  {
    id: 7,
    text: "Pay success fees over up to a year",
  },
];

export const CRAYON_COINS = [
  {
    id: 1,
    text: "Voucher applied ",
    amount: 100,
  },
  {
    id: 2,
    text: "Coin top-up ",
    amount: 150,
  },
  {
    id: 3,
    text: "Direct job (video applications)",
    amount: -20,
  },
  {
    id: 4,
    text: "Recruit job (video applications, interviews)",
    amount: -30,
  },
  {
    id: 5,
    text: "CV unlock",
    amount: -1,
  },
  {
    id: 6,
    text: "Candidate video unlock",
    amount: -1,
  },
  {
    id: 7,
    text: "Add user (30 days)",
    amount: -10,
  },
  {
    id: 8,
    text: "Bonus coins (Crayon Talent)",
    amount: 50,
  },
];

export const GET_COINS = [
  {
    id: 1,
    coins: "10 coins",
    symbol: "R",
    amount: "950",
  },
  {
    id: 2,
    coins: "25 coins",
    discount: "(5% discount)",
    symbol: "R",
    amount: "2250",
  },
  {
    id: 3,
    coins: "50 coins",
    discount: "(10% discount)",
    symbol: "R",
    amount: "4275",
  },
  {
    id: 4,
    coins: "100 coins",
    discount: "(25% discount)",
    symbol: "R",
    amount: "7125",
  },
];

export const PERMISSION_OPTIONS = [
  { value: 1, label: "Super Admin" },
  { value: 2, label: "Admin" },
  { value: 3, label: "Team Member" },
  { value: 4, label: "Recruiter" },
  { value: 5, label: "Promoter" },
];

export const ALERTS = [
  {
    id: 1,
    title: "Brand Designer job status has changed",
    stage: "interview",
  },
  {
    id: 2,
    title: "Your stage has changed (Brand Designer)",
    stage: "Rejected",
  },
  {
    id: 3,
    title: "You’re shortlisted (Brand Designer)",
    stage: "",
  },
  {
    id: 4,
    title: "Thanks for applying (Brand Designer)",
    stage: "",
  },
  {
    id: 5,
    title: "New job alert (Graphic Designer)",
    stage: "",
  },
  {
    id: 6,
    title: "Welcome to Crayon",
    stage: "",
  },
  {
    id: 7,
    title: "Brand Designer job status has changed",
    stage: "interview",
  },
  {
    id: 8,
    title: "Your stage has changed (Brand Designer)",
    stage: "Rejected",
  },
  {
    id: 9,
    title: "You’re shortlisted (Brand Designer)",
    stage: "",
  },
  {
    id: 10,
    title: "Thanks for applying (Brand Designer)",
    stage: "",
  },
  {
    id: 11,
    title: "New job alert (Graphic Designer)",
    stage: "",
  },
  {
    id: 12,
    title: "Welcome to Crayon",
    stage: "",
  },
  {
    id: 13,
    title: "Brand Designer job status has changed",
    stage: "interview",
  },
  {
    id: 14,
    title: "Your stage has changed (Brand Designer)",
    stage: "Rejected",
  },
  {
    id: 15,
    title: "You’re shortlisted (Brand Designer)",
    stage: "",
  },
  {
    id: 16,
    title: "Thanks for applying (Brand Designer)",
    stage: "",
  },
  {
    id: 17,
    title: "New job alert (Graphic Designer)",
    stage: "",
  },
  {
    id: 18,
    title: "Welcome to Crayon",
    stage: "",
  },
];

export const COMMENT_SORTING = [
  {
    id: 1,
    name: "oldest",
  },
  {
    id: 2,
    name: "newest",
  },
];

export const USERS_STATUS = [
  {
    id: 1,
    name: "active",
    color: "lightGreenButton300",
  },
  {
    id: 3,
    name: "suspend",
    color: "redButton100",
  },
];

export const USER_STATUS = [
  {
    id: 1,
    name: "Active",
    color: "lightGreenButton300",
  },
  {
    id: 2,
    name: "Hide",
    color: "yellowButton100",
  },
  {
    id: 3,
    name: "Suspend",
    color: "redButton100",
  },
  {
    id: 4,
    name: "Blacklist",
    color: "black",
  },
];

export const TEMPHISTORY = [
  {
    id: 1,
    from: "demo1@gmail.com",
    to: "demo2@gmail.com",
    subject: "Hello",
    date: "2024-03-13T06:43:14.733Z",
  },
  {
    id: 2,
    from: "demo1@gmail.com",
    to: "demo2@gmail.com",
    subject: "Hello",
    date: "2024-03-13T06:43:14.733Z",
  },
  {
    id: 3,
    from: "demo1@gmail.com",
    to: "demo2@gmail.com",
    subject: "Hello",
    date: "2024-03-13T06:43:14.733Z",
  },
  {
    id: 4,
    from: "demo1@gmail.com",
    to: "demo2@gmail.com",
    subject: "Hello",
    date: "2024-03-13T06:43:14.733Z",
  },
  {
    id: 5,
    from: "demo1@gmail.com",
    to: "demo2@gmail.com",
    subject: "Hello",
    date: "2024-03-13T06:43:14.733Z",
  },
  {
    id: 6,
    from: "demo1@gmail.com",
    to: "demo2@gmail.com",
    subject: "Hello",
    date: "2024-03-13T06:43:14.733Z",
  },
  {
    id: 7,
    from: "demo1@gmail.com",
    to: "demo2@gmail.com",
    subject: "Hello",
    date: "2024-03-13T06:43:14.733Z",
  },
  {
    id: 8,
    from: "demo1@gmail.com",
    to: "demo2@gmail.com",
    subject: "Hello",
    date: "2024-03-13T06:43:14.733Z",
  },
  {
    id: 9,
    from: "demo1@gmail.com",
    to: "demo2@gmail.com",
    subject: "Hello",
    date: "2024-03-13T06:43:14.733Z",
  },
  {
    id: 10,
    from: "demo1@gmail.com",
    to: "demo2@gmail.com",
    subject: "Hello",
    date: "2024-03-13T06:43:14.733Z",
  },
  {
    id: 1,
    from: "demo1@gmail.com",
    to: "demo2@gmail.com",
    subject: "Hello",
    date: "2024-03-13T06:43:14.733Z",
  },
  {
    id: 2,
    from: "demo1@gmail.com",
    to: "demo2@gmail.com",
    subject: "Hello",
    date: "2024-03-13T06:43:14.733Z",
  },
  {
    id: 3,
    from: "demo1@gmail.com",
    to: "demo2@gmail.com",
    subject: "Hello",
    date: "2024-03-13T06:43:14.733Z",
  },
  {
    id: 4,
    from: "demo1@gmail.com",
    to: "demo2@gmail.com",
    subject: "Hello",
    date: "2024-03-13T06:43:14.733Z",
  },
  {
    id: 5,
    from: "demo1@gmail.com",
    to: "demo2@gmail.com",
    subject: "Hello",
    date: "2024-03-13T06:43:14.733Z",
  },
  {
    id: 6,
    from: "demo1@gmail.com",
    to: "demo2@gmail.com",
    subject: "Hello",
    date: "2024-03-13T06:43:14.733Z",
  },
  {
    id: 7,
    from: "demo1@gmail.com",
    to: "demo2@gmail.com",
    subject: "Hello",
    date: "2024-03-13T06:43:14.733Z",
  },
  {
    id: 8,
    from: "demo1@gmail.com",
    to: "demo2@gmail.com",
    subject: "Hello",
    date: "2024-03-13T06:43:14.733Z",
  },
  {
    id: 9,
    from: "demo1@gmail.com",
    to: "demo2@gmail.com",
    subject: "Hello",
    date: "2024-03-13T06:43:14.733Z",
  },
  {
    id: 10,
    from: "demo1@gmail.com",
    to: "demo2@gmail.com",
    subject: "Hello",
    date: "2024-03-13T06:43:14.733Z",
  },
];

export const PERSONALITY_BUTTONS = [
  {
    id: 1,
    name: "Challenger",
    label: "Primary",
    label1: "Secondary",
    activeLabel: "",
  },
  {
    id: 2,
    name: "Character",
    label: "Primary",
    label1: "Secondary",
    activeLabel: "",
  },
  {
    id: 3,
    name: "Contemplator",
    label: "Primary",
    label1: "Secondary",
    activeLabel: "",
  },
  {
    id: 4,
    name: "Collaborator",
    label: "Primary",
    label1: "Secondary",
    activeLabel: "",
  },
];

export const EDIT_COMPANY = {
  employer_id: "",
  company_id: "",
  company_name: "",
  hyperlink: "",
  notes: "",
  industry_ids: [],
  company_type: "",
  company_size: "",
  enabled: 0,
  culture_ids: [],
};

export const EDIT_EMPLOYER = {
  employer_id: "",
  contact_no: "",
  country_code: "",
  country_name: "",
  country_id: "",
  town_id: "",
  town_name: "",
  gender: "",
  dob: "",
  linkedin_profile_link: "",
  skinz: "",
  notes: "",
  grit_score: "",
  first_name: "",
  last_name: "",
  email: "",
  gender_flag: true,
};

export const MY_REFERRALS_LEFT_FILTER = [
  { id: 1111, name: "all referrals", title: true },
  { id: 1, name: "Bounty winner!" },
];

export const ALL_STAGES = [
  {
    id: 1111,
    // job_stage_id: 1111,
    name: "my stages",
    title: true,
  },
  {
    id: 1,
    job_stage_id: 1,
    name: "review",
    description: "your application is under review",
  },
  {
    id: 2,
    job_stage_id: 2,
    name: "shortlist",
    description: "your application is shorlisted",
  },
  {
    id: 3,
    job_stage_id: 3,
    name: "interview",
    description: "your application is ready to initiate interview",
  },
  {
    id: 4,
    job_stage_id: 4,
    name: "assessment",
    description: "your application is under assessment",
  },
  {
    id: 5,
    job_stage_id: 5,
    name: "offer",
    description: "your application is ready for the selection offer",
  },
  {
    id: 6,
    job_stage_id: 6,
    name: "hired",
    description: "your application is ready for the selection offer",
  },
];
export const JOB_STAGES = [
  {
    id: 1111,
    // job_stage_id: 1111,
    name: "job stages",
    title: true,
  },
  {
    id: 1,
    job_stage_id: 1,
    name: "review",
    description: "your application is under review",
  },
  {
    id: 2,
    job_stage_id: 2,
    name: "shortlist",
    description: "your application is shorlisted",
  },
  {
    id: 3,
    job_stage_id: 3,
    name: "interview",
    description: "your application is ready to initiate interview",
  },
  {
    id: 4,
    job_stage_id: 4,
    name: "assessment",
    description: "your application is under assessment",
  },
  {
    id: 5,
    job_stage_id: 5,
    name: "offer",
    description: "your application is ready for the selection offer",
  },
  {
    id: 6,
    job_stage_id: 6,
    name: "hired",
    description: "your application is ready for the selection offer",
  },
];

export const JOBS = [
  { id: 1, label: "All Jobs", path: "all-jobs" },
  { id: 2, label: "Pending Jobs", path: "pending-jobs" },
  { id: 3, label: "Active Jobs", path: "active-jobs" },
  { id: 4, label: "Paused Jobs", path: "paused-jobs" },
  { id: 5, label: "Closed Jobs", path: "closed-jobs" },
  { id: 8, label: "Expired Jobs", path: "expired_jobs" },
  { id: 9, label: "Incognito Jobs", path: "incognito-jobs" },
  { id: 6, label: "Crayon Direct", path: "crayon-direct" },
  { id: 7, label: "Crayon Recruit", path: "crayon-recruit" },
];

export const STUDY_LIFE_QUALIFICATION = {
  qualification_id: null,
  institution_id: null,
  year_ended: "",
  completed: "false",
  qualificationtype_id: "",
};

export const STUDY_LIFE_SCHOOL = {
  school_id: null,
  still_completing_flag: false,
  year_completion: "",
};

export const STUDY_LIFE_ASSOCIATION = {
  association_id: null,
  still_completing_flag: false,
  year_completion: "",
};

export const STUDY_LIFE_STUDY = {
  qualificationdata: [STUDY_LIFE_QUALIFICATION],
  schooldata: [STUDY_LIFE_SCHOOL],
  associationdata: [STUDY_LIFE_ASSOCIATION],
};

export const CANDIDATE_PROFILE = {
  name: "",
  surname: "",
  email: "",
  contact_no: "",
  gender: "",
  dob: "",
  my_bio: "",
  relocate: 0,
  town_id: 3,
  nationality_ids: [],
  language_ids: [1],
  skinz: 0,
  seeking_job: 1,
  hide_age: 0,
  hide_profile: 0,
  grit_score: 0,
  primary: {},
  shadow: {},
  hide_video: 0,
  linkedin_profile_link: "",
  country_id: 0,
  profile_percent_complete: 0,
  totoalapplicant: 0,
  totoalinterviewed: 0,
  totoalshortlisted: 0,
  reveal_gender: false,
};

export const TALENT = [
  { id: 0, label: "All talent", path: "all-talent", submenu: [] },
  {
    id: 1,
    label: "My Talent",
    path: "my-talent",
    submenu: [
      { id: 6, title: "Applicants", path: "applicants" },
      { id: 7, title: "Followers", path: "followers" },
    ],
  },

  {
    id: 3,
    label: "Client pools",
    path: "client-pool",
    submenu: [],
  },
  {
    id: 4,
    label: "Talent pools",
    path: "talent-pool",
    submenu: [],
  },
];

export const SEARCH = [
  { id: 0, label: "Build a search", path: "build-search", mySearch: [] },
  {
    id: 1,
    label: "My searches",
    path: "my-search",
    mySearch: [],
  },
  {
    id: 2,
    label: "Client searches",
    path: "client-search",
    mySearch: [],
  },
  { id: 3, label: "Job titles", path: "job-title-search", mySearch: [] },
  { id: 4, label: "Industries", mySearch: [], path: "industries" },
  { id: 5, label: "Companies", mySearch: [], path: "companies" },
  { id: 6, label: "Skills", path: "skills-search", mySearch: [] },
  { id: 7, label: "Tools", path: "tools-search", mySearch: [] },
  {
    id: 8,
    label: "Qualifications",
    path: "qualifications-search",
    mySearch: [],
  },
  { id: 9, label: "Institutions", path: "institution-search", mySearch: [] },
  { id: 10, label: "Schools", path: "schools-search", mySearch: [] },
  { id: 11, label: "Cities/Towns", path: "towns-search", mySearch: [] },
  {
    id: 12,
    label: "Nationalities",
    path: "nationalities-search",
    mySearch: [],
  },
  { id: 13, label: "Languages", path: "languages-search", mySearch: [] },
  { id: 14, label: "Association", path: "association", mySearch: [] },
];

export const MAINTENANCE = [
  {
    id: 1,
    label: "Database items",
    databaseList: [
      {
        id: 0,
        label: "Job titles",
        path: "jobtitle",
        counter: "JobsTitlecounter",
      },
      { id: 1, label: "Skills", path: "skills", counter: "Skillscounter" },
      { id: 2, label: "Tools", path: "tools", counter: "Toolscounter" },
      {
        id: 3,
        label: "Qualifications",
        path: "qualifications",
        counter: "Qualificationscounter",
      },
      {
        id: 4,
        label: "Institutions",
        path: "institutions",
        counter: "Institutioncounter",
      },

      {
        id: 5,
        label: "Companies",
        path: "companies",
        counter: "CompanyEmpcounter",
      },
      {
        id: 6,
        label: "Candidate companies",
        path: "candidatecompany",
        counter: "CompanyEmpcounter",
      },
      {
        id: 7,
        label: "Associations",
        path: "associations",
        counter: "Associationcounter",
      },
      { id: 8, label: "Schools", path: "schools", counter: "Schoolscounter" },
      { id: 9, label: "Towns", path: "towns", counter: "Townscounter" },
      {
        id: 10,
        label: "Nationalities",
        path: "nationalities",
        counter: "Nationalitycounter",
      },
      {
        id: 11,
        label: "Languages",
        path: "languages",
        counter: "Languagecounter",
      },
      {
        id: 12,
        label: "Industries",
        path: "industries",
        counter: "Industrycounter",
      },
      {
        id: 13,
        label: "Company Culture",
        path: "company-culture",
        counter: "CompanyCultureCounter",
      },
      {
        id: 14,
        label: "Qualification types",
        path: "qualification-types",
        counter: "QualificationTypecounter",
      },
      {
        id: 15,
        label: "Currencies",
        path: "currencies",
        counter: "Currencycounter",
      },
    ],
  },
  { id: 2, label: "Vouchers", path: "vouchers", databaseList: [] },
  { id: 3, label: "Coins history", path: "coins-history", databaseList: [] },
  { id: 4, label: "Payments", path: "payment-history", databaseList: [] },
  { id: 5, label: "Solutions", path: "solutions", databaseList: [] },
  { id: 6, label: "Assessment", path: "assessment", databaseList: [] },
  { id: 7, label: "ContactUsQuery", path: "contactUsQuery", databaseList: [] },
  { id: 8, label: "SubscribeList", path: "subscribeList", databaseList: [] },
  { id: 9, label: "PolicyPrivacy", path: "policyPrivacy", databaseList: [] },
];

export const USERS = [
  {
    id: 0,
    label: "Companies",
    path: "user-companies",
    team: [],
  },
  {
    id: 1,
    label: "Employers",
    path: "user-employers",
    team: [],
  },
  {
    id: 2,
    label: "Recruiters",
    path: "user-recruiters",
    team: [],
  },
  {
    id: 3,
    label: "Promoters",
    path: "user-promoters",
    team: [],
  },
  {
    id: 4,
    label: "Crayon Team",
    path: "user-crayon-team",
    team: [
      { id: 2, label: "Super Admins", path: "crayonTeam-superAdmins" },
      { id: 0, label: "Admins", path: "crayonTeam-admins" },
      { id: 1, label: "Talent Partners", path: "crayonTeam-talentPartners" },
    ],
  },
];

export const ALERT_TYPE = {
  ERROR: "error",
  WARNING: "warning",
  INFO: "info",
  SUCCESS: "success",
};

export const CANDIDATE_STATUS = [
  {
    id: 1,
    name: "active",
  },
  {
    id: 2,
    name: "hide",
  },
  {
    id: 3,
    name: "suspend",
  },
  {
    id: 4,
    name: "blacklist",
  },
];

export const STAGE_ARRAY = [
  {
    id: 1,
    name: "incomplete",
  },
  {
    id: 2,
    name: "matched",
  },
  {
    id: 3,
    name: "review",
  },
  {
    id: 4,
    name: "considering",
  },
  {
    id: 5,
    name: "shortlist",
  },
  {
    id: 6,
    name: "interview",
  },
  {
    id: 7,
    name: "assessment",
  },
  {
    id: 8,
    name: "offer",
  },
  {
    id: 9,
    name: "hired",
  },
  {
    id: 10,
    name: "rejected",
  },
];

export const PERSONALITY = {
  primary_personality: "",
  shadow_personality: "",
  grit_score: "",
  traits: [],
  culture_ids: [],
};

export const PERSONALITY_EMPLOYER = {
  user_id: "",
  primary_personality_id: "",
  secondary_personality_id: "",
  grit_score: "",
};

export const MY_COMPANIES_BASIC = {
  company_name: "",
  hyperlink: "",
  notes: "",
  industry_ids: [],
  company_type: "",
  company_size: "",
  contact_person_name: "",
  contact_person_email: "",
  contact_person_number: "",
  country_code: "",
  editflag: false,
  logo: null,
  companyURL: "NO URL",
  country_id: "",
  town_id: "",
  culture_ids: [],
};

export const FORM_LINK =
  "https://docs.google.com/forms/d/e/1FAIpQLScKTA3IfBUw8xvdP03-hMFX1UObzO_pPcd5p61OsKAf3whbrg/viewform";

export const USER_ROLES = [
  {
    role_id: 1,
    name: "superadmin",
    description: "An super admin user",
  },
  {
    role_id: 2,
    name: "admin",
    description: "An admin user",
  },
  {
    role_id: 3,
    name: "candidate",
    description: "A candidate user",
  },
  {
    role_id: 4,
    name: "employer",
    description: "An employer user",
  },
  {
    role_id: 6,
    name: "recruiter",
    description: "A recruiter user",
  },
  {
    role_id: 5,
    name: "promoter",
    description: "A crayon promoter user",
  },
  {
    role_id: 8,
    name: "talentPartner",
    description: "A crayon promoter user",
  },
  // {
  //     "role_id": 7,
  //     "name": "coach",
  //     "description": "a coach user"
  // }
];

export const PASSWORD_REGEX =
  /^(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?])(?=.*[A-Z])(?=.*[0-9])(?=.*[a-z]).{8,}$/;

export const NUMBERS_REGEX = /^[+]?[0-9]+$/;

export const MEMBER_INFO = {
  first_name: "",
  last_name: "",
  email: "",
  contact: "",
  password: "",
  company_id: "",
  role_type_id: "",
  company_name: "",
  country_code: "",
};

export const HEADCELLS = [
  {
    id: "title",
    numeric: false,
    disablePadding: true,
    label: "Job title name",
  },
  {
    id: "status",
    numeric: true,
    disablePadding: false,
    label: "Status",
  },
  {
    id: "dateAdded",
    numeric: true,
    disablePadding: false,
    label: "Date Added",
  },
  {
    id: "lastActive",
    numeric: true,
    disablePadding: false,
    label: "Last Active",
  },
  {
    id: "permission",
    numeric: true,
    disablePadding: false,
    label: "Permissions",
  },
  {
    id: "actions",
    numeric: true,
    disablePadding: false,
    label: "Edit",
  },
];

export const EXPERIENCE = [
  {
    value: 0,
    label: "0",
  },
  {
    value: 1,
    label: "1",
  },
  {
    value: 2,
    label: "2",
  },
  {
    value: 3,
    label: "3",
  },
  {
    value: 4,
    label: "4",
  },
  {
    value: 5,
    label: "5",
  },
  {
    value: 6,
    label: "6",
  },
  {
    value: 7,
    label: "7",
  },
  {
    value: 8,
    label: "8",
  },
  {
    value: 9,
    label: "9",
  },
  {
    value: 10,
    label: "10",
  },
  {
    value: 11,
    label: "11",
  },
  {
    value: 12,
    label: "12",
  },
  {
    value: 13,
    label: "13",
  },
  {
    value: 14,
    label: "14",
  },
  {
    value: 15,
    label: "15",
  },
  {
    value: 16,
    label: "16",
  },
  {
    value: 17,
    label: "17",
  },
  {
    value: 18,
    label: "18",
  },
  {
    value: 19,
    label: "19",
  },
  {
    value: 20,
    label: "20+",
  },
];

export const NOTICEPERIOD = [
  { value: 0, label: "Immediate" },
  { value: 1, label: "1 week" },
  { value: 2, label: "2 weeks" },
  { value: 3, label: "4 weeks" },
  { value: 4, label: "30 days" },
  { value: 5, label: "1 calendar month" },
  { value: 6, label: "2 months" },
  { value: 7, label: "2 calendar months" },
];

export const CVBASIC = {
  current_job_title_id: null,
  dream_job_title_id: null,
  industries: [],
  tags: [],
  experience_id: 0,
  notice_period_id: 0,
  qualification_level: "",
  employment_type: "",
  salary: [0, 100000],
  tools: [],
  currency_id: 1,
  portfolio_link: "",
  work_setup: "",
  experience: [],
  culture_ids: [],
};

export const WORK = {
  company_name: null,
  title: null,
  clients_worked_on_awards: "<p></p>",
  start_date: null,
  end_date: null,
  currently_employed_here: 0,
};

export const SALARY_OBJ = {
  min: 0,
  max: 0,
  step: 0,
};

export const FLOOR_VALUE = [
  {
    label: "hundreds",
    value: 50,
  },
  {
    label: "thousands",
    value: 500,
  },
  {
    label: "lakhs",
    value: 5000,
  },
];

export const BASIC_MARKS = [
  {
    value: 0,
    label: "0",
  },
  {
    value: 1,
    label: "1",
  },
  {
    value: 2,
    label: "2",
  },
  {
    value: 3,
    label: "3",
  },
  {
    value: 4,
    label: "4",
  },
  {
    value: 5,
    label: "5",
  },
  {
    value: 6,
    label: "6",
  },
  {
    value: 7,
    label: "7",
  },
  {
    value: 8,
    label: "8",
  },
  {
    value: 9,
    label: "9",
  },
  {
    value: 10,
    label: "10",
  },
  {
    value: 11,
    label: "11",
  },
  {
    value: 12,
    label: "12",
  },
  {
    value: 13,
    label: "13",
  },
  {
    value: 14,
    label: "14",
  },
  {
    value: 15,
    label: "15",
  },
  {
    value: 16,
    label: "16",
  },
  {
    value: 17,
    label: "17",
  },
  {
    value: 18,
    label: "18",
  },
  {
    value: 19,
    label: "19",
  },
  {
    value: 20,
    label: "20+",
  },
];

export const BASIC_RANGEMARKS = [
  {
    value: 0,
    label: "0",
  },
  {
    value: 5,
    label: "10k",
  },
  {
    value: 10,
    label: "20k",
  },
  {
    value: 15,
    label: "30k",
  },
  {
    value: 20,
    label: "40k",
  },
  {
    value: 25,
    label: "50k",
  },
  {
    value: 30,
    label: "60k",
  },
  {
    value: 35,
    label: "70k",
  },
  {
    value: 40,
    label: "80k",
  },
  {
    value: 45,
    label: "90k",
  },
  {
    value: 50,
    label: "100k",
  },
  {
    value: 55,
    label: "110k",
  },
  {
    value: 60,
    label: "120k",
  },
  {
    value: 65,
    label: "130k",
  },
  {
    value: 70,
    label: "140k",
  },
  {
    value: 75,
    label: "150k",
  },
  {
    value: 80,
    label: "160k",
  },
  {
    value: 85,
    label: "170k",
  },
  {
    value: 90,
    label: "180k",
  },
  {
    value: 95,
    label: "190k",
  },
  {
    value: 100,
    label: "200k+",
  },
];

export const BASIC_RANGEMARKS_SLIDER = [
  {
    value: 0,
    label: "0",
  },
  {
    value: 1,
    label: "10k",
  },
  {
    value: 2,
    label: "20k",
  },
  {
    value: 3,
    label: "30k",
  },
  {
    value: 4,
    label: "40k",
  },
  {
    value: 5,
    label: "50k",
  },
  {
    value: 6,
    label: "60k",
  },
  {
    value: 7,
    label: "70k",
  },
  {
    value: 8,
    label: "80k",
  },
  {
    value: 9,
    label: "90k",
  },
  {
    value: 10,
    label: "100k",
  },
  {
    value: 11,
    label: "110k",
  },
  {
    value: 12,
    label: "120k",
  },
  {
    value: 13,
    label: "130k",
  },
  {
    value: 14,
    label: "140k",
  },
  {
    value: 15,
    label: "150k",
  },
  {
    value: 16,
    label: "160k",
  },
  {
    value: 17,
    label: "170k",
  },
  {
    value: 18,
    label: "180k",
  },
  {
    value: 19,
    label: "190k",
  },
  {
    value: 20,
    label: "200k+",
  },
];

export const BASIC_MARKS_ALERT = [
  {
    value: 0,
    label: "0",
  },
  {
    value: 1,
    label: "2",
  },
  {
    value: 2,
    label: "4",
  },
  {
    value: 3,
    label: "6",
  },
  {
    value: 4,
    label: "8",
  },
  {
    value: 5,
    label: "10",
  },
  {
    value: 6,
    label: "12",
  },
  {
    value: 7,
    label: "14",
  },
  {
    value: 8,
    label: "16",
  },
  {
    value: 9,
    label: "18",
  },
  {
    value: 10,
    label: "20",
  },
];

export const BASIC_RANGEMARKS_ALERT = [
  {
    value: 0,
    label: "0",
  },
  {
    value: 10,
    label: "20k",
  },
  {
    value: 20,
    label: "40k",
  },
  {
    value: 30,
    label: "60k",
  },
  {
    value: 40,
    label: "80k",
  },

  {
    value: 50,
    label: "100k",
  },

  {
    value: 60,
    label: "120k",
  },

  {
    value: 70,
    label: "140k",
  },

  {
    value: 80,
    label: "160k",
  },

  {
    value: 90,
    label: "180k",
  },

  {
    value: 100,
    label: "200k+",
  },
];

export const EMPLOYER_MYPROFILE_LEFTPANEL_BUTTONS = [
  {
    id: 0,
    name: "build my profile",
    value: 0,
  },
  {
    id: 1,
    name: "my info",
    value: 1,
  },

  {
    id: 2,
    name: "company info",
    value: 2,
  },
  {
    id: 3,
    name: "my companies",
    value: 3,
  },
  {
    id: 4,
    name: "my plan",
    value: 4,
  },
  {
    id: 5,
    name: "billing info",
    value: 5,
  },
];

export const BASIC_RANGEMARKS2 = [
  {
    value: 0,
    label: "0",
  },
  {
    value: 1,
    label: "100",
  },
  {
    value: 2,
    label: "200",
  },
  {
    value: 3,
    label: "300",
  },
  {
    value: 4,
    label: "400",
  },
  {
    value: 5,
    label: "500+",
  },
];

export const JOB_TYPE = [
  {
    id: 1,
    name: "Recruit",
  },
  {
    id: 2,
    name: "Direct",
  },
];

export const BASIC_MANAGE_TALENT = {
  sortType: null,
  jobtype_id: "",
  genderType: "",
  salary: [],
  personalitytype_id: "",
  qualification_id: "",
  skin_id: "",
  experience: [],
  recent: false,
  active: false,
  InDemand: false,
  video: false,
  portfolio: false,
  crayon_cam: false,
  mytalent: false,
  favourite: false,
};
export const BUILD_SEARCH_ADVANCE_FILTER = {
  permanent: false,
  contact: false,
  "in-office": false,
  hybrid: false,
  remote: false,
  gender: false,
  // genderType:"",
  association: false,
  race: [],
  recent: false,
  active: false,
  "in demand": false,
  favourites: false,
  "my talent": false,
  // skin_id:""
};

export const BASIC_SEARCH = {
  job_title: [],
  region_id: [],
  tag_id: [],
  town_id: [],
  tool_id: [],
  salary: [],
  experience: [],
  company_id: [],
  currency_id: [],
  highest_qualification_id: [],
};

export const COMP_INFO = {
  company_name: "",
  hyperlink: "",
  notes: "",
  industry_ids: [],
  company_type: "",
  company_size: "",
  company_id: "",
  companyURL: "",
  logo: "",
  country_id: "",
  town_id: "",
  culture_ids: [],
  created_at: "",
};

export const PROFILE_INFO = {
  first_name: "",
  surname: "",
  email: "",
  contact_no: "",
  gender: "",
  // birthDate: "",
  town_id: "",
  skinz: "",
  dob: "",
  linkedin_profile_link: "",
  country_name: "",
  country_code: "",
  country_id: "",
  gender_flag: false,
  town_name: "",
};

export const POST_JOB_BASIC = {
  job_id: null,
  job_title_id: "",
  job_role_type: "",
  job_type: "",
  contract_duration: 0,
  industry_id: [],
  currency_id: 1,
  salary: [0, 100000],
  hide_salary: 1,
  salary_negotiate: true,
  work_setup: "",
  language_ids: [2],
  nationality_ids: [],
  country_id: "",
  town_id: [],
  skills: [],
  alternateTitle:[],
  tools: [],
  experience: [0, 2],
  // experience: 2,
  own_transport: false,
  own_equipment: false,
  internet_access: false,
  is_video_require: false,
  is_interview_require: false,
  post_incognito: false,
  experience_id: "",
  required_qualification_id: "",
  preferred_qualification_ids: [],
  association_ids: [],
  gender: "",
  skinz: null,
  skinz_flag: true,
  gender_flag: true,
  nationality_flag: false,
  job_start_date: null,
  language_flag: false,
};
export const SKINZ = [
  {
    id: 0,
    name: "white",
  },
  {
    id: 1,
    name: "coloured",
  },
  {
    id: 2,
    name: "indian",
  },
  {
    id: 3,
    name: "black",
  },
];

export const POST_JOB_STEP = [
  {
    label: "Job Type",
  },
  {
    label: "the basics",
  },
  {
    label: "the details",
  },
  {
    label: "culture add",
  },
  {
    label: "bounty",
  },
  {
    label: "preview job",
  },
];

export const SCREEN_QUESTIONS = {
  question_id: null,
  question: "",
};

export const CULTURE = {
  jobDetails: {
    user_id: "",
    job_id: "",
    primary_personality: "",
    shadow_personality: "",
    grit_score: 0,
    candidate_video_complete_flag: null,
    is_video_require: null,
    video_description: null,
    briffing_video_flag: null,
    video_for_shortlisted_flag: null,
  },
  screen_questions: [SCREEN_QUESTIONS],
  traits: [],
};

export const POST_JOB_ADMIN = {
  job_id: null,
  job_title_id: "",
  job_role_type: "",
  job_type: "",
  contract_duration: 0,
  industry_id: [],
  currency_id: 1,
  salary: [0, 100000],
  hide_salary: 1,
  salary_negotiate: true,
  work_setup: "",
  language_ids: [2],
  nationality_ids: [],
  country_id: "",
  town_id: [],
  skills: [],
  tools: [],
  experience: [0, 2],
  own_transport: false,
  own_equipment: false,
  internet_access: false,
  is_video_require: false,
  is_interview_require: false,
  post_incognito: false,
  experience_id: "",
  required_qualification_id: "",
  preferred_qualification_ids: [],
  association_ids: [],
  gender: "",
  skinz: null,
  skinz_flag: true,
  gender_flag: true,
  nationality_flag: false,
  job_start_date: null,
  language_flag: false,
  primary_personality: "",
  shadow_personality: "",
  grit_score: 0,
  candidate_video_complete_flag: null,
  video_description: null,
  briffing_video_flag: null,
  video_for_shortlisted_flag: null,
  job_questions: [SCREEN_QUESTIONS],
  traits: [],
  role_summary: "",
  role_responsibilty: "",
  role_requirements: "",
  job_bio: "",
  job_summary_flag: true,
  job_notes: "",
};
export const CULTURE_MARKS = [
  {
    value: 0,
    label: "00",
  },
  {
    value: 25,
    label: "25",
  },
  {
    value: 50,
    label: "50",
  },
  {
    value: 75,
    label: "75",
  },
  {
    value: 100,
    label: "100",
  },
];

export const DETAIL = {
  role_summary: "",
  role_responsibilty: "",
  role_requirements: "",
  job_bio: "",
  job_summary_flag: true,
  job_notes: "",
};

export const ERROR_MSG = "Something went wrong! Please try again later!";

export const USER_TYPES = ["candidate", "employer", "recruiter", "promoter"];

export const LOGIN_TYPE = ["login", "signup"];

export const PUBLIC_TAB_ITEMS = [
  {
    label: "jobs",
    path: "jobs",
  },
  {
    label: "talent",
    path: "talent",
  },
  {
    label: "who's hiring",
    path: "whoshiring",
  },
  {
    label: "solutions",
    path: "solutions",
  },
  {
    label: "pricing",
    path: "pricing",
  },
  {
    label: "contact",
    path: "contact",
  },
];

export const RECRUITER_MYPROFILE_LEFTPANEL_BUTTONS = [
  {
    id: 0,
    name: "build my profile",
    value: 0,
  },
  {
    id: 1,
    name: "my info",
    value: 1,
  },

  {
    id: 2,
    name: "company info",
    value: 2,
  },
  {
    id: 3,
    name: "my clients",
    value: 3,
  },
  {
    id: 4,
    name: "my plan",
    value: 4,
  },
  {
    id: 5,
    name: "billing info",
    value: 5,
  },
];

export const ADMIN_TAB_ITEMS = [
  {
    label: "",
    path: "",
  },
];

export const TAB_ITEMS_EMPLOYER = [
  {
    label: "my crayon",
    path: "employer/my-profile",
  },
  {
    label: "post a job",
    path: "employer/post-a-job",
  },
  {
    label: "my jobs",
    path: "employer/my-jobs",
  },
  {
    label: "my team",
    path: "employer/my-team",
  },
  {
    label: "my cam",
    path: "employer/my-cam",
  },
  {
    label: "get coins",
    path: "",
  },
  {
    label: "support",
    path: "/contact",
  },
  {
    label: "logout",
    path: "*",
  },
  {
    label: "Lite",
    path: "employer/my-jobs",
  },
  {
    label: "Talent",
    path: "admin/adminJobs/active-jobs",
  },

];

export const TAB_ITEMS_EMPLOYER_PROMOTER = [
  {
    label: "my crayon",
    path: "employer/my-profile",
  },

  {
    label: "post a job",
    path: "employer/post-a-job",
  },
  {
    label: "my jobs",
    path: "employer/my-jobs",
  },
  {
    label: "my team",
    path: "employer/my-team",
  },
  {
    label: "my cam",
    path: "employer/my-cam",
  },
  {
    label: "shared jobs",
    path: "employer/shared-jobs",
  },
  {
    label: "my referrals",
    path: "employer/my-referrals",
  },
  {
    label: "get coins",
    path: "",
  },
  {
    label: "support",
    path: "/contact",
  },
  {
    label: "logout",
    path: "*",
  },
  {
    label: "Lite",
    path: "employer/my-jobs",
  },
  {
    label: "Talent",
    path: "admin/adminJobs/active-jobs",
  },
];

export const TAB_ITEMS_RECRUITER = [
  {
    label: "my crayon",
    path: "recruiter/my-profile",
  },
  {
    label: "post a job",
    path: "recruiter/post-a-job",
  },
  {
    label: "my jobs",
    path: "recruiter/my-jobs",
  },
  {
    label: "my team",
    path: "recruiter/my-team",
  },
  {
    label: "my cam",
    path: "recruiter/my-cam",
  },
  {
    label: "get coins",
    path: "",
  },
  {
    label: "support",
    path: "/contact",
  },
  {
    label: "logout",
    path: "*",
  },
  {
    label: "Lite",
    path: "recruiter/my-jobs",
  },
  {
    label: "Talent",
    path: "admin/adminJobs/active-jobs",
  },
];

export const TAB_ITEMS_RECRUITER_PROMOTER = [
  {
    label: "my crayon",
    path: "recruiter/my-profile",
  },
  {
    label: "post a job",
    path: "recruiter/post-a-job",
  },
  {
    label: "my jobs",
    path: "recruiter/my-jobs",
  },
  {
    label: "my team",
    path: "recruiter/my-team",
  },
  {
    label: "my cam",
    path: "recruiter/my-cam",
  },
  {
    label: "shared jobs",
    path: "recruiter/shared-jobs",
  },
  {
    label: "my referrals",
    path: "recruiter/my-referrals",
  },
  {
    label: "get coins",
    path: "",
  },
  {
    label: "support",
    path: "/contact",
  },
  {
    label: "logout",
    path: "*",
  },
  {
    label: "Lite",
    path: "recruiter/my-jobs",
  },
  {
    label: "Talent",
    path: "admin/adminJobs/active-jobs",
  },
];

export const TAB_ITEMS_ADMIN = [
  {
    label: "logout",
    path: "*",
  },
];

export const AUTHORIZED_TAB_ITEMS_PROMOTER = [
  {
    label: "quick links",
    path: "promoter/quick_links",
  },
  {
    label: "shared jobs",
    path: "promoter/shared-jobs",
  },
  {
    label: "my referrals",
    path: "promoter/my-referrals",
  },
];

export const AUTHORIZED_TAB_ITEMS_EMPLOYER = [
  {
    label: "quick links",
    path: "employer/quick_links",
  },
  {
    label: "post a job",
    path: "employer/post-a-job",
  },
  {
    label: "my jobs",
    path: "employer/my-jobs",
  },
  {
    label: "my team",
    path: "employer/my-team",
  },
  {
    label: "my cam",
    path: "employer/my-cam",
  },
];

export const AUTHORIZED_TAB_ITEMS_EMPLOYER_PROMOTER = [
  {
    label: "quick links",
    path: "employer/quick_links",
  },
  {
    label: "post a job",
    path: "employer/post-a-job",
  },
  {
    label: "my jobs",
    path: "employer/my-jobs",
  },
  {
    label: "my team",
    path: "employer/my-team",
  },
  {
    label: "my cam",
    path: "employer/my-cam",
  },
  {
    label: "shared jobs",
    path: "employer/shared-jobs",
  },
  {
    label: "my referrals",
    path: "employer/my-referrals",
  },
];

export const AUTHORIZED_TAB_ITEMS_RECRUITER = [
  {
    label: "quick links",
    path: "recruiter/quick_links",
  },
  {
    label: "post a job",
    path: "recruiter/post-a-job",
  },
  {
    label: "my jobs",
    path: "recruiter/my-jobs",
  },
  {
    label: "my team",
    path: "recruiter/my-team",
  },
  {
    label: "my cam",
    path: "recruiter/my-cam",
  },
];

export const AUTHORIZED_TAB_ITEMS_RECRUITER_PROMOTER = [
  {
    label: "quick links",
    path: "recruiter/quick_links",
  },
  {
    label: "post a job",
    path: "recruiter/post-a-job",
  },
  {
    label: "my jobs",
    path: "recruiter/my-jobs",
  },
  {
    label: "my team",
    path: "recruiter/my-team",
  },
  {
    label: "my cam",
    path: "recruiter/my-cam",
  },
  {
    label: "shared jobs",
    path: "recruiter/shared-jobs",
  },
  {
    label: "my referrals",
    path: "recruiter/my-referrals",
  },
];

export const TAB_ITEMS_PROMOTER = [
  {
    label: "my crayon",
    path: "promoter/my-profile",
  },
  {
    label: "shared jobs",
    path: "promoter/shared-jobs",
  },
  {
    label: "my referrals",
    path: "promoter/my-referrals",
  },
  // {
  //   label: "my wallet",
  //   path: "promoter/my-wallet",
  // },
  {
    label: "support",
    path: "/contact",
  },
  {
    label: "logout",
    path: "*",
  },
  {
    label: "Promoter",
    path: "promoter/shared-jobs",
  },
];

export const TAB_ITEMS_CANDIDATE = [
  {
    label: "my crayon",
    path: "candidate/my-profile",
  },
  {
    label: "my jobs",
    path: "candidate/my-jobs",
  },
  {
    label: "my CV",
    path: "candidate/my-cv",
  },
  {
    label: "my cam",
    path: "candidate/my-cam",
  },
  // {
  //   label: "get coins",
  //   path: "/pricing",
  // },
  {
    label: "support",
    path: "/contact",
  },
  {
    label: "logout",
    path: "*",
  },
  {
    label: "Candidate",
    path: "candidate/my-jobs",
  },
];

export const TAB_ITEMS_CANDIDATE_PROMOTER = [
  {
    label: "my crayon",
    path: "candidate/my-profile",
  },
  {
    label: "my jobs",
    path: "candidate/my-jobs",
  },
  {
    label: "my CV",
    path: "candidate/my-cv",
  },
  {
    label: "my cam",
    path: "candidate/my-cam",
  },
  {
    label: "shared jobs",
    path: "candidate/shared-jobs",
  },
  {
    label: "my referrals",
    path: "candidate/my-referrals",
  },
  // {
  //   label: "get coins",
  //   path: "/pricing",
  // },
  {
    label: "support",
    path: "/contact",
  },
  {
    label: "logout",
    path: "*",
  },
  {
    label: "Candidate",
    path: "candidate/my-jobs",
  },
];

export const AUTHORIZED_TAB_ITEMS_CANDIDATE_PROMOTER = [
  {
    label: "quick links",
    path: "candidate/quick_links",
  },
  {
    label: "my jobs",
    path: "candidate/my-jobs",
  },
  {
    label: "my CV",
    path: "candidate/my-cv",
  },
  {
    label: "my cam",
    path: "candidate/my-cam",
  },
  {
    label: "shared jobs",
    path: "candidate/shared-jobs",
  },
  {
    label: "my referrals",
    path: "candidate/my-referrals",
  },
];

export const AUTHORIZED_TAB_ITEMS_CANDIDATE = [
  {
    label: "quick links",
    path: "candidate/quick_links",
  },
  {
    label: "my jobs",
    path: "candidate/my-jobs",
  },
  {
    label: "my CV",
    path: "candidate/my-cv",
  },
  {
    label: "my cam",
    path: "candidate/my-cam",
  },
];

export const MY_TEAMS_LEFT_PANEL = [
  {
    id: 1111,
    name: "Team Status",
    color: "lightGreenButton300",
    title: true,
  },
  {
    id: 3,
    name: "team members",
    color: "lightGreenButton300",
  },
  {
    id: 4,
    name: "recruiters",
    color: "lightGreenButton300",
  },
  {
    id: 5,
    name: "promoters",
    color: "lightGreenButton300",
  },
  {
    id: 6,
    name: "hired talent",
    color: "lightGreenButton300",
  },
];

export const MY_TEAMS_INVITE_STATUS = [
  {
    id: 1111,
    name: "Invite Status",
    color: "blueButton700",
    title: true,
  },
  {
    id: 1,
    name: "accepted",
    color: "blueButton700",
  },
  {
    id: 2,
    name: "pending",
    color: "blueButton700",
  },
  {
    id: 3,
    name: "rejected",
    color: "blueButton700",
  },
];

export const JOBS_LEFT_INDUSTRIES_BUTTON_GROUP = [
  {
    id: 1,
    name: "all industies",
    color: "blueButton600",
    title: true,
  },
  {
    id: 2,
    name: "tech",
    color: "blueButton600",
  },
  {
    id: 3,
    name: "digital",
    color: "blueButton600",
  },
  {
    id: 4,
    name: "marketing",
    color: "blueButton600",
  },
  {
    id: 5,
    name: "sales",
    color: "blueButton600",
  },
  {
    id: 6,
    name: "finance",
    color: "blueButton600",
  },
  {
    id: 7,
    name: "insurance",
    color: "blueButton600",
  },
  {
    id: 8,
    name: "operations",
    color: "blueButton600",
  },
  {
    id: 9,
    name: "data",
    color: "blueButton600",
  },
  {
    id: 10,
    name: "real estate",
    color: "blueButton600",
  },
  {
    id: 11,
    name: "education",
    color: "blueButton600",
  },
  {
    id: 12,
    name: "media",
    color: "blueButton600",
  },
];

export const JOBS_LEFT_TYPES_BUTTON_GROUP = [
  {
    id: 1111,
    name: "all types",
    color: "leftArrowButton",
    title: true,
  },
  {
    id: 1,
    name: "challengers",
    color: "leftArrowButton",
  },
  {
    id: 2,
    name: "characters",
    color: "leftArrowButton",
  },
  {
    id: 3,
    name: "contemplators",
    color: "leftArrowButton",
  },
  {
    id: 4,
    name: "collaborators",
    color: "leftArrowButton",
  },
];

export const JOBS_RIGHT_JOB_TYPES_BUTTON_GROUP = [
  {
    id: 1,
    name: "all job types",
    color: "blueButton700",
    title: true,
  },
  {
    id: 2,
    name: "freelance",
    color: "blueButton700",
  },
  {
    id: 3,
    name: "full-time",
    color: "blueButton700",
  },
  {
    id: 4,
    name: "remote",
    color: "blueButton700",
  },
  {
    id: 5,
    name: "in-office",
    color: "blueButton700",
  },
  {
    id: 6,
    name: "hybrid",
    color: "blueButton700",
  },
  {
    id: 7,
    name: "crayon recruit",
    color: "blueButton700",
  },
];

export const JOBS_RIGHT_POSTS_BUTTON_GROUP = [
  {
    id: 1,
    name: "all stages",
    color: "lightGreenButton300",
    title: true,
  },
  {
    id: 2,
    name: "review",
    color: "lightGreenButton300",
  },
  {
    id: 3,
    name: "shortlist",
    color: "lightGreenButton300",
  },
  {
    id: 4,
    name: "interview",
    color: "lightGreenButton300",
  },
  {
    id: 5,
    name: "assessment",
    color: "lightGreenButton300",
  },
  {
    id: 6,
    name: "offer",
    color: "lightGreenButton300",
  },
];

export const ALL_SIZE = {
  id: 1111,
  name: "all size",
  color: "black100",
  title: true,
};

export const TALENT_DATA = {
  first_name: "",
  last_name: "",
  job_title: "",
  dob: "",
  town_name: "",
  country_name: "",
  salary: "",
  experience: "",
  notice_period: "",
  applicationCount: "",
  shortlistCount: "",
  interviewCount: "",
  work_type: "",
  work_setup: "",
  candidate_stage: "",
  matchPercentage: "",
  created_at: "",
};

export const ALL_TYPE = {
  id: 1111,
  name: "all type",
  color: "black100",
  title: true,
};

export const HIRING_RIGHT_STAGES_BUTTON_GROUP = [
  {
    id: 1111,
    name: "all companies",
    color: "black100",
    title: true,
  },
  {
    id: 2,
    name: "recent",
    color: "black100",
  },
  {
    id: 3,
    name: "favourites",
    color: "black100",
  },
  {
    id: 4,
    name: "applied jobs",
    color: "black100",
  },
];

export const JOBS_RIGHT_STAGES_BUTTON_GROUP = [
  {
    id: 1111,
    name: "all activity",
    color: "black100",
    title: true,
  },
  {
    id: 2,
    name: "recent",
    color: "black100",
  },
  {
    id: 3,
    name: "favourites",
    color: "black100",
  },
  {
    id: 4,
    name: "applied jobs",
    color: "black100",
  },
];
export const SEARCH_BAR_BUTTON_GROUP = [
  {
    id: 1111,
    name: "quick filters",
    color: "black100",
    title: true,
  },
  {
    id: 2,
    name: "permanent",
    color: "black100",
  },
  {
    id: 3,
    name: "contract",
    color: "black100",
  },
  {
    id: 4,
    name: "in-office",
    color: "black100",
  },
  {
    id: 5,
    name: "hybrid",
    color: "black100",
  },
  {
    id: 6,
    name: "remote",
    color: "black100",
  },
  {
    id: 7,
    name: "gender",
    color: "black100",
    dropDown: true,
  },
  {
    id: 8,
    name: "race",
    color: "black100",
    dropDown: true,
  },
  {
    id: 9,
    name: "recent",
    color: "black100",
  },
  {
    id: 10,
    name: "active",
    color: "black100",
  },
  {
    id: 11,
    name: "in demand",
    color: "black100",
  },
  {
    id: 12,
    name: "favourites",
    color: "black100",
  },
  {
    id: 13,
    name: "my talent",
    color: "black100",
  },
  {
    id: 14,
    name: "association",
    color: "black100",
  },
];

export const PROMOTER_MY_PROFILE_LEFT = [
  {
    id: 1,
    name: "build my profile",
    color: "pausedStatus",
    title: true,
  },
  {
    id: 2,
    name: "my info",
    color: "pausedStatus",
  },
  {
    id: 3,
    name: "assessment",
    color: "pausedStatus",
  },
];

export const CANDIDATE_MY_PROFILE_LEFT = [
  {
    id: 1,
    name: "build my profile",
    color: "pausedStatus",
    title: true,
  },
  {
    id: 2,
    name: "my info",
    color: "pausedStatus",
  },
  {
    id: 3,
    name: "Crayon vitae",
    color: "pausedStatus",
  },
  {
    id: 4,
    name: "assessment",
    color: "pausedStatus",
  },
  {
    id: 5,
    name: "Crayon cam",
    color: "pausedStatus",
  },
];

export const ADMIN_SEARCH_FILTER = [
  {
    id: 1,
    name: "quick filters",
    color: "blueButton700",
    title: true,
  },
  {
    id: 2,
    name: "permanent",
    color: "blueButton700",
  },
  {
    id: 3,
    name: "contract",
    color: "blueButton700",
  },
  {
    id: 4,
    name: "freelance",
    color: "blueButton700",
  },
  {
    id: 5,
    name: "in-office",
    color: "blueButton700",
  },
  {
    id: 6,
    name: "hybrid",
    color: "blueButton700",
  },
  {
    id: 7,
    name: "remote",
    color: "blueButton700",
  },
  {
    id: 8,
    name: "gender",
    color: "blueButton700",
  },
  {
    id: 9,
    name: "race",
    color: "blueButton700",
  },
  {
    id: 10,
    name: "recent",
    color: "blueButton700",
  },
  {
    id: 11,
    name: "active",
    color: "blueButton700",
  },
  {
    id: 12,
    name: "in-demand",
    color: "blueButton700",
  },
  {
    id: 13,
    name: "favourites",
    color: "blueButton700",
  },
  {
    id: 14,
    name: "my talent",
    color: "blueButton700",
  },
];

export const TALENT_RIGHT_JOB_TYPES_BUTTON_GROUP = [
  {
    id: 1,
    name: "all talents",
    color: "blueButton700",
    title: true,
  },
  {
    id: 2,
    name: "permanent",
    color: "blueButton700",
  },
  {
    id: 3,
    name: "contract",
    color: "blueButton700",
  },
  {
    id: 4,
    name: "freelance",
    color: "blueButton700",
  },
  {
    id: 5,
    name: "in-office",
    color: "blueButton700",
  },
  {
    id: 6,
    name: "hybrid",
    color: "blueButton700",
  },
  {
    id: 7,
    name: "remote",
    color: "blueButton700",
  },
];

export const TALENT_RIGHT_JOB_ACTIVITY_BUTTON_GROUP = [
  {
    id: 1111,
    name: "all activity",
    color: "leftArrowButton",
    title: true,
  },
  {
    id: 2,
    name: "recent",
    color: "leftArrowButton",
  },
  {
    id: 3,
    name: "active",
    color: "leftArrowButton",
  },
  {
    id: 4,
    name: "in demand",
    color: "leftArrowButton",
  },
  {
    id: 5,
    name: "my favourites",
    color: "leftArrowButton",
  },
  {
    id: 6,
    name: "my talent",
    color: "leftArrowButton",
  },
  {
    id: 7,
    name: "CV unlocked",
    color: "leftArrowButton",
  },
  {
    id: 8,
    name: "video unlocked",
    color: "leftArrowButton",
  },
];

export const CARD_RIGHT_BUTTON_GROUP = [
  {
    id: 1,
    name: "adaptable",
    color: "purpleButton",
    hover: "yellowButton100",
  },
  {
    id: 2,
    name: "detailed",
    color: "purpleButton",
    hover: "yellowButton100",
  },
  {
    id: 3,
    name: "organized",
    color: "purpleButton",
    hover: "yellowButton100",
  },
  {
    id: 4,
    name: "proactive",
    color: "purpleButton",
    hover: "yellowButton100",
  },
  {
    id: 5,
    name: "thrives on stress",
    color: "purpleButton",
    hover: "yellowButton100",
  },
];

export const TALENT_LEFT_BUTTON_GROUP = [
  {
    id: 1,
    name: "all industies",
    color: "blueButton600",
    title: true,
  },
  {
    id: 2,
    name: "tech",
    color: "blueButton600",
  },
  {
    id: 3,
    name: "digital",
    color: "blueButton600",
  },
  {
    id: 4,
    name: "marketing",
    color: "blueButton600",
  },
  {
    id: 5,
    name: "sales",
    color: "blueButton600",
  },
  {
    id: 6,
    name: "finance",
    color: "blueButton600",
  },
  {
    id: 7,
    name: "insurance",
    color: "blueButton600",
  },
  {
    id: 8,
    name: "operations",
    color: "blueButton600",
  },
  {
    id: 9,
    name: "data",
    color: "blueButton600",
  },
  {
    id: 10,
    name: "real estate",
    color: "blueButton600",
  },
  {
    id: 11,
    name: "education",
    color: "blueButton600",
  },
  {
    id: 12,
    name: "media",
    color: "blueButton600",
  },
  {
    id: 13,
    name: "all types",
    color: "purpleButton",
    title: true,
    marginTop: 2,
  },
  {
    id: 14,
    name: "challengers",
    color: "purpleButton",
  },
  {
    id: 15,
    name: "characters",
    color: "purpleButton",
  },
  {
    id: 16,
    name: "contemplators",
    color: "purpleButton",
  },
  {
    id: 17,
    name: "collaborators",
    color: "purpleButton",
  },
];

export const TALENT_RIGHT_BUTTON_GROUP = [
  {
    id: 1,
    name: "all job types",
    color: "blueButton700",
    title: true,
  },
  {
    id: 2,
    name: "freelance",
    color: "blueButton700",
  },
  {
    id: 3,
    name: "full-time",
    color: "blueButton700",
  },
  {
    id: 4,
    name: "remote",
    color: "blueButton700",
  },
  {
    id: 5,
    name: "in-office",
    color: "blueButton700",
  },
  {
    id: 6,
    name: "hybrid",
    color: "blueButton700",
  },
  {
    id: 7,
    name: "crayon recruit",
    color: "blueButton700",
  },
  {
    id: 8,
    name: "all stages",
    color: "lightGreenButton300",
    title: true,
    marginTop: 2,
  },
  {
    id: 9,
    name: "review",
    color: "lightGreenButton300",
  },
  {
    id: 10,
    name: "shortlist",
    color: "lightGreenButton300",
  },
  {
    id: 11,
    name: "interview",
    color: "lightGreenButton300",
  },
  {
    id: 12,
    name: "assessment",
    color: "lightGreenButton300",
  },
  {
    id: 13,
    name: "offer",
    color: "lightGreenButton300",
  },
  {
    id: 14,
    name: "all post",
    color: "black100",
    title: true,
    marginTop: 2,
  },
  {
    id: 15,
    name: "recent",
    color: "black100",
  },
  {
    id: 16,
    name: "favourites",
    color: "black100",
  },
  {
    id: 17,
    name: "applied jobs",
    color: "black100",
  },
];

export const EMPLOYER_MY_JOBS_LEFT = [
  {
    id: 1,
    name: "jobs",
    color: "redButton",
  },
  {
    id: 2,
    name: "settings",
    color: "redButton",
  },
];

export const EMPLOYER_JOB_POSTING_LEFT = [
  {
    id: 1,
    name: "The basics",
    color: "redButton",
  },
  {
    id: 2,
    name: "The details",
    color: "redButton",
  },
  {
    id: 3,
    name: "The culture",
    color: "redButton",
  },
];

export const EMPLOYER_JOB_POSTING_SPEC_LEFT = [
  // {
  //   id: 0,
  //   name: "post a job",
  //   color: "redButton",
  // },
  {
    id: 1,
    name: "build a spec",
    color: "redButton",
  },
  {
    id: 2,
    name: "find a spec",
    color: "redButton",
  },
  {
    id: 3,
    name: "my specs",
    color: "redButton",
  },
];
export const EMPLOYER_SPEC_LEFT = [
  {
    id: 0,
    name: "all jobs",
  },
  {
    id: 1,
    name: "posted",
  },
  {
    id: 2,
    name: "drafts",
  },
];
export const TALENT_LEFT_JOB_APPLICATION_BUTTON_GROUP = [
  {
    id: 1111,
    name: "all applications",
    color: "leftArrowButton",
    title: true,
  },
  {
    id: 2,
    name: "complete",
    color: "leftArrowButton",
    // title: true,
  },
  {
    id: 3,
    name: "incomplete",
    color: "leftArrowButton",
    // title: true,
  },
  {
    id: 4,
    name: "video",
    color: "leftArrowButton",
    // title: true,
  },
  {
    id: 5,
    name: "crayon cam",
    color: "leftArrowButton",
    // title: true,
  },
  {
    id: 6,
    name: "portfolio",
    color: "leftArrowButton",
    // title: true,
  },
];

export const TALENT_LEFT_JOB_RESPONSE_BUTTON_GROUP = [
  {
    id: 1111,
    name: "all responses",
    color: "leftArrowButton",
    title: true,
  },
  {
    id: 2,
    name: "i like this",
    color: "leftArrowButton",
  },
  {
    id: 3,
    name: "i love this",
    color: "leftArrowButton",
  },
  {
    id: 4,
    name: "not for me",
    color: "leftArrowButton",
  },
  {
    id: 1,
    name: "pending",
    color: "leftArrowButton",
  },
];

export const TALENT_RIGHT_JOB_INFO_BUTTON_GROUP = [
  {
    id: 1111,
    name: "all talent",
    color: "blueButton700",
    title: true,
  },
  {
    id: 3,
    name: "contract",
    color: "blueButton700",
  },
  {
    id: 4,
    name: "freelance",
    color: "blueButton700",
  },
  {
    id: 4,
    name: "grad programme",
    color: "blueButton700",
  },
  {
    id: 4,
    name: "internship",
    color: "blueButton700",
  },
  {
    id: 2,
    name: "permanent",
    color: "blueButton700",
  },
  {
    id: 6,
    name: "hybrid",
    color: "blueButton700",
  },
  {
    id: 5,
    name: "in-office",
    color: "blueButton700",
  },
  {
    id: 7,
    name: "remote",
    color: "blueButton700",
  },
  {
    id: 8,
    name: "gender",
    color: "blueButton700",
    dropDown: true,
    // title: true,
  },
  {
    id: 9,
    name: "race",
    color: "blueButton700",
    dropDown: true,
    // title: true,
  },
  // {
  //   id: 10,
  //   name: "age",
  //   color: "blueButton700",
  //   // title: true,
  // },
  {
    id: 11,
    name: "qualification",
    color: "blueButton700",
    dropDown: true,
    // title: true,
  },
  {
    id: 12,
    name: "salary",
    color: "blueButton700",
    dropDown: true,
    // title: true,
  },
  {
    id: 13,
    name: "experience",
    color: "blueButton700",
    dropDown: true,
    // title: true,
  },
];

export const JOBS_RIGHT_COMPANIES_BUTTON = [
  {
    id: 1,
    name: "all companies",
    color: "redButton300",
    title: true,
  },
  {
    id: 2,
    name: "size",
    color: "redButton300",
    dropDown: true,
  },
  {
    id: 3,
    name: "type",
    color: "redButton300",
    dropDown: true,
  },
];

export const JOBS_RIGHT_COMPANIES_STATUS = [
  {
    id: 1111,
    name: "companies",
    color: "redButton300",
    title: true,
  },
  {
    id: 2,
    name: "active",
    color: "redButton300",
  },
  {
    id: 3,
    name: "paused",
    color: "redButton300",
  },
  {
    id: 4,
    name: "closed",
    color: "redButton300",
  },
  {
    id: 5,
    name: "expired",
    color: "redButton300",
  },
];

export const EMPLOYER_MY_JOBS_RIGHT = [
  {
    id: 1,
    name: "all",
    color: "redButton",
  },
  {
    id: 2,
    name: "recruit",
    color: "brownButton",
  },
  {
    id: 3,
    name: "talent/lite",
    color: "redButton",
  },
  {
    id: 4,
    name: "review",
    color: "yellowButton100",
  },
  {
    id: 5,
    name: "shortlist",
    color: "yellowButton100",
  },
  {
    id: 6,
    name: "interview",
    color: "yellowButton100",
  },
  {
    id: 7,
    name: "assessment",
    color: "yellowButton100",
  },
  {
    id: 8,
    name: "offer",
    color: "yellowButton100",
  },
  {
    id: 9,
    name: "recent",
    color: "orangeButton",
  },
  {
    id: 10,
    name: "favourites",
    color: "brownButton",
  },
  {
    id: 11,
    name: "applied",
    color: "lightGreenButton200",
  },
];

export const CANDIDATE_MY_CV_LEFT = [
  {
    id: 1,
    name: "the basics",
    color: "redButton",
  },
  {
    id: 2,
    name: "work life",
    color: "redButton",
  },
  {
    id: 3,
    name: "study life",
    color: "redButton",
  },
];

export const CANDIDATE_MY_CV_RIGHT = [
  {
    id: 1,
    name: "create cv",
    color: "redButton",
  },
  {
    id: 2,
    name: "view my cv",
    color: "redButton",
  },
];

export const ADMIN_LFET_PANEL = [
  {
    id: 0,
    title: "Dashboard",
    path: "dashboard",
    subTitle: "The stats",
    icon: "CreditCardIcon",
    color: "greenButton",
    menuItems: [
      { id: 0, label: "Dashboard item1" },
      { id: 1, label: "Dashboard item2" },
    ],
  },
  {
    id: 1,
    title: "Jobs",
    path: "adminJobs",
    subTitle: "Manage your jobs",
    icon: "NotificationsActiveOutlinedIcon",
    color: "yellowButton300",
    menuItems: [
      { id: 0, label: "All Jobs", path: "all-jobs" },
      { id: 1, label: "Pending Jobs", path: "pending-jobs" },
      { id: 2, label: "Active jobs", path: "active-jobs" },
      { id: 3, label: "Paused jobs", path: "paused-jobs" },
      { id: 4, label: "Closed jobs", path: "closed-jobs" },
      // { label: 'Post a job' },
    ],
  },
  {
    id: 2,
    title: "Talent",
    path: "admin-talent",
    subTitle: "Manage your talent",
    icon: "PermIdentityIcon",
    color: "blueButton400",
    menuItems: [
      { id: 0, label: "All talent", path: "all-talent" },
      { id: 1, label: "My Talent" },
      { id: 2, label: "Applicants", path: "applicants" },
      { id: 3, label: "Followers", path: "followers" },
      { id: 4, label: "Talent pools", path: "talent-pool" },
    ],
  },
  {
    id: 3,
    title: "Search",
    path: "search",
    subTitle: "Find talent, fast",
    icon: "LanguageIcon",
    color: "pinkButton",
    menuItems: [
      { id: 0, label: "Build a search", path: "build-search" },
      { id: 1, label: "Job titles", path: "job-title-search" },
      { id: 2, label: "Skills", path: "skills-search" },
      { id: 3, label: "Tools", path: "tools-search" },
      { id: 4, label: "Qualifications", path: "qualifications-search" },
      { id: 5, label: "Institutions", path: "institution-search" },
      { id: 6, label: "Associations", path: "associations-search" },
      { id: 7, label: "Schools", path: "schools-search" },
      { id: 8, label: "Towns", path: "towns-search" },
      { id: 9, label: "Nationalities", path: "nationalities-search" },
      { id: 10, label: "Languages", path: "languages-search" },
    ],
  },
  {
    id: 4,
    title: "Crayon Chat",
    path: "chat",
    subTitle: "Who said what",
    icon: "ChatBubbleOutlineIcon",
    color: "blueButton400",
    menuItems: [{ id: 0, label: "Chat item1" }],
  },
  {
    id: 5,
    title: "Maintenance",
    path: "maintenance",
    subTitle: "The nuts and bolts",
    icon: "ShowChartIcon",
    color: "yellowButton300",
    menuItems: [
      {
        id: 0,
        label: "Job titles",
        path: "jobtitle",
        counter: "JobsTitlecounter",
      },
      { id: 1, label: "Skills", path: "skills", counter: "Skillscounter" },
      { id: 2, label: "Tools", path: "tools", counter: "Toolscounter" },
      {
        id: 3,
        label: "Qualifications",
        path: "qualifications",
        counter: "Qualificationscounter",
      },
      {
        id: 4,
        label: "Institutions",
        path: "institutions",
        counter: "Institutioncounter",
      },
      {
        id: 5,
        label: "Companies",
        path: "company",
        counter: "CompanyEmpcounter",
      },
      {
        id: 6,
        label: "Associations",
        path: "associations",
        counter: "Associationcounter",
      },
      { id: 7, label: "Schools", path: "schools", counter: "Schoolscounter" },
      { id: 8, label: "Towns", path: "towns", counter: "Townscounter" },
      {
        id: 9,
        label: "Nationalities",
        path: "nationalities",
        counter: "Nationalitycounter",
      },
      {
        id: 10,
        label: "Languages",
        path: "languages",
        counter: "Languagecounter",
      },
      {
        id: 11,
        label: "Industries",
        path: "industries",
        counter: "Industrycounter",
      },
      {
        id: 12,
        label: "Qualification types",
        path: "qualification-types",
        counter: "QualificationTypecounter",
      },
      {
        id: 13,
        label: "Currencies",
        path: "currencies",
        counter: "Currencycounter",
      },
      { id: 14, label: "Vouchers", path: "vouchers", counter: "" },
    ],
  },
];

export const BUILD_SEARCH = [
  { label: "job titles" },
  { label: "tags" },
  { label: "skills" },
  { label: "tools" },
  { label: "qualifications" },
  { label: "institutions" },
  { label: "associations" },
  { label: "schools" },
  { label: "towns" },
  { label: "nationalities" },
  { label: "languages" },
];

export const ADMIN_ACTIVE_JOBS = [
  {
    id: "006",
    name: "Sally",
    logo: "",
    job: "Front End Developer",
    description: "Hooligan Development",
    status: {
      label: "considering",
      color: "yellowButton100",
    },
    crayonComfort: false,
    address: "Paarl, South Africa",
    salary: "R60,000pm",
    experience: "5 years",
    workType: "in-office",
    jobType: "full-time",
    date: "2 Jan 2023",
    days: "3 days",
  },
  {
    id: "005",
    name: "James",
    logo: "",
    job: "Data Scientist",
    description: "Click Learning",
    status: {
      label: "assessment",
      color: "blueButton100",
    },
    crayonComfort: false,
    address: "London, United Kingdom",
    salary: "R40,000pm",
    experience: "4 years",
    workType: "hybrid",
    jobType: "full-time",
    date: "15 Dec 2022",
    days: "22 days",
  },
  {
    id: "004",
    name: "Mike",
    logo: "",
    job: "Lead Developer",
    description: "Ozow Payments",
    status: {
      label: "interview",
      color: "purpleButton",
    },
    crayonComfort: true,
    address: "Cape Town, South Africa",
    salary: "R90,000pm",
    experience: "6 years",
    workType: "remote",
    jobType: "full-time",
    date: "25 Nov 2022",
    days: "42 days",
  },
];

export const ADMIN_TALENT_JOBS = [
  {
    id: "545",
    job: "Mickey Mouse",
    description: "Data Scientist",
    count: 25,
    date: "27 Apr 2022",
    days: "5 days",
    chips: [
      {
        label: "interview",
        color: "lightGreenButton300",
      },
      {
        label: "education",
        color: "blueButton600",
      },
      {
        label: "real estate",
        color: "blueButton600",
      },
    ],
    address: "Sandton, South Africa",
    salary: "R30,000pm",
    experience: "6 years",
    workType: "remote",
    jobType: "full-time",
  },
  {
    id: "546",
    job: "Daffy Duck",
    description: "Lead Engineer",
    count: 43,
    date: "7 May 2019",
    days: "16 days",
    chips: [
      {
        label: "interview",
        color: "purpleButton",
      },
      {
        label: "education",
        color: "brownButton",
      },
      {
        label: "real estate",
        color: "brownButton",
      },
    ],
    address: "Cape Town, South Africa",
    salary: "R30,000pm",
    experience: "6 years",
    workType: "in-office",
    jobType: "full-time",
  },
  {
    id: "547",
    job: "Bugs Bunny",
    description: "Graphic Designer",
    count: 32,
    date: "27 Apr 2022",
    days: "30 days",
    chips: [
      {
        label: "assessment",
        color: "purpleButton",
      },
      {
        label: "tech",
        color: "brownButton",
      },
      {
        label: "finance",
        color: "brownButton",
      },
    ],
    address: "London, United Kingdom",
    salary: "R30,000pm",
    experience: "6 years",
    workType: "in-office",
    jobType: "full-time",
  },
];

export const CV_STEPS = [
  "The Basics",
  "Work Life",
  "Study Life",
  "Reference",
  "View cv",
];

export const POST_JOB_STEPS = ["The basics", "The details", "The culture add"];

export const WORK_TYPE = [
  {
    id: "remote",
    name: "remote",
  },
  {
    id: "work from office",
    name: "work from office",
  },
];

export const ROLE_TYPE = [
  {
    id: "freelance",
    name: "freelance",
  },
  {
    id: "full-time",
    name: "full-time",
  },
  {
    id: "remote",
    name: "remote",
  },
  {
    id: "in-office",
    name: "in-office",
  },
  {
    id: "hybrid",
    name: "hybrid",
  },
  {
    id: "crayon recruit",
    name: "crayon recruit",
  },
];

export const EMP_PROFILE_STEPS = ["My info", "Company info"];

export const steps = [
  {
    id: 0,
    label: "the basics",
  },
  {
    id: 1,
    label: "work life",
  },
  {
    id: 2,
    label: "study life",
  },
  {
    id: 3,
    label: "references",
  },
  {
    id: 4,
    label: "view my CV",
  },
];
export const assesmentSteps = [
  {
    id: 0,
    label: "good start",
  },
  {
    id: 1,
    label: "keep it up",
  },
  {
    id: 2,
    label: "halfway",
  },
  {
    id: 3,
    label: "almost there",
  },
  {
    id: 4,
    label: "the end",
  },
];

export const marks = [
  {
    value: 0,
    label: "0",
  },
  {
    value: 1,
    label: "1",
  },
  {
    value: 2,
    label: "2",
  },
  {
    value: 3,
    label: "3",
  },
  {
    value: 4,
    label: "4",
  },
  {
    value: 5,
    label: "5",
  },
  {
    value: 6,
    label: "6",
  },
  {
    value: 7,
    label: "7",
  },
  {
    value: 8,
    label: "8",
  },
  {
    value: 9,
    label: "9",
  },
  {
    value: 10,
    label: "10",
  },
  {
    value: 11,
    label: "11",
  },
  {
    value: 12,
    label: "12",
  },
  {
    value: 13,
    label: "13",
  },
  {
    value: 14,
    label: "14",
  },
  {
    value: 15,
    label: "15",
  },
  {
    value: 16,
    label: "16",
  },
  {
    value: 17,
    label: "17",
  },
  {
    value: 18,
    label: "18",
  },
  {
    value: 19,
    label: "19",
  },
  {
    value: 20,
    label: "20+",
  },
];

export const BASIC = {
  town_id: [0],
  nationality_id: [0],
  current_job_title_id: [0],
  tag_id: [0],
  qualification_id: [0],
  institution_id: [0],
  school_id: [0],
  association_id: [0],
  language_id: [0],
  industry_id: [0],
  company_name: [0],
  tool_id: [0],
  salary: [0],
  experience: [0],
};

export const INITIALSEARCHDATA = {
  all: { switch: 1, green: false, disabled: true },
  current_job_title_id: { switch: 1, field: [], disabled: false },
  industry_id: { switch: 1, field: [], disabled: false },
  company_name: { switch: 1, field: [], disabled: false },
  tag_id: { switch: 1, field: [], disabled: false },
  tool_id: { switch: 1, field: [], disabled: false },
  qualification_id: { switch: 1, field: [], disabled: false },
  institution_id: { switch: 1, field: [], disabled: false },
  association_id: { switch: 1, field: [], disabled: false },
  school_id: { switch: 1, field: [], disabled: false },
  town_id: { switch: 1, field: [], disabled: false },
  nationality_id: { switch: 1, field: [], disabled: false },
  language_id: { switch: 1, field: [], disabled: false },
  experience: { switch: 1, field: [0, 10], disabled: true },
  salary: { switch: 1, field: [0, 100000], disabled: true },
  currency_id: 1,
  salaryType: "salary",
};
export const rangeMarks = [
  {
    value: 0,
    label: "0",
  },
  {
    value: 20,
    label: "20k",
  },
  {
    value: 40,
    label: "40k",
  },
  {
    value: 60,
    label: "60k",
  },
  {
    value: 80,
    label: "80k",
  },
  {
    value: 100,
    label: "100k+",
  },
];

export const formatNumber = (num) => {
  if (num < 1000) {
    return num.toString();
  } else if (num < 1000000) {
    return (num / 1000).toFixed(0) + "k";
  } else {
    return (num / 1000000).toFixed(0) + "M";
  }
};

export const rangeValueExperience = (value) => {
  return `${value} years`;
};

export const rangeValueHandler = (value) => {
  return formatNumber(value * 1000);
};

export const findFirstKeyWithNonEmptyField = (data) => {
  for (let key in data) {
    // Exclude the keys 'currency_id' and 'salaryType'
    if (key === "currency_id" || key === "salaryType") {
      continue;
    }

    // Check if the key is not disabled and the 'field' array is not empty
    if (data[key]?.disabled !== true && data[key]?.field?.length > 0) {
      return key;
    }
  }
  return null;
};

export const currentTabs1 = [
  // { label: "home", path: "/" },
  { label: "jobs", path: "/jobs" },
  { label: "talent", path: "/talent" },
  { label: "who's hiring", path: "/whoshiring" },
  { label: "solutions", path: "/solutions" },
  { label: "pricing", path: "/pricing" },
  { label: "contact", path: "/contact" },
];

export const JOBPAGE = [
  {
    urlname: "pending-jobs",
    status_id: 1,
    filters: {
      title: "Pending Jobs",
      value: "pending",
      type: "",
    },
  },
  {
    urlname: "active-jobs",
    status_id: 2,
    filters: {
      title: "Active Jobs",
      value: "active",
      type: "",
    },
  },
  {
    urlname: "paused-jobs",
    status_id: 3,
    filters: {
      title: "Paused Jobs",
      value: "paused",
      type: "",
    },
  },
  {
    urlname: "closed-jobs",
    status_id: 4,
    filters: {
      title: "Closed Jobs",
      value: "closed",
      type: "",
    },
  },
  {
    urlname: "expired-jobs",
    status_id: 5,
    filters: {
      title: "Expired Jobs",
      value: "expired_jobs",
      type: "",
    },
  },
  {
    urlname: "incognito-jobs",
    status_id: "",
    filters: {
      title: "Incognito Jobs",
      value: "incognito-jobs",
      type: "",
    },
  },
  {
    urlname: "crayon-direct",
    status_id: "",
    filters: {
      title: "Direct",
      value: "Direct",
      type: "Direct",
    },
  },
  {
    urlname: "crayon-recruit",
    status_id: "",
    filters: {
      title: "Recruit",
      value: "Recruit",
      type: "Recruit",
    },
  },
];

export const ALL_JOBS_STAGE_ARRAY = [
  {
    id: 1,
    name: "review",
  },
  {
    id: 2,
    name: "shortlist",
  },
  {
    id: 3,
    name: "interview",
  },
  {
    id: 4,
    name: "assessment",
  },
  {
    id: 5,
    name: "offer",
  },
];
export const JOB_STATUS = [
  {
    id: 1,
    name: "pending",
  },
  {
    id: 2,
    name: "active",
  },
  {
    id: 3,
    name: "paused",
  },
  {
    id: 4,
    name: "closed",
  },
];
