import { useEffect, useRef, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import { useTheme } from "@mui/material/styles";
import { truncate } from "lodash";
import leftArrow from "../../../assets/Black_Left_Previous - Copy.svg";
import leftArrowWhite from "../../../assets/Black_Left_Previous.svg";
import rightArrow from "../../../assets/Black_Right_Next - Copy.svg";
import rightArrowWhite from "../../../assets/Black_Right_Next.svg";
import CompanySizeFilter from "./CompanySizeFilter";
import { Popover } from "@mui/material";
import CompanyTypeFilter from "./CompanyTypeFilter";
import { useLocation, useNavigate } from "react-router-dom";
import { useQuery } from "../../../utils/Common";
export default function AllCompaniesFIlter({
  panelData,
  side,
  onChangeFilter = () => {},
  setCompanySize,
  setCompanyType,
  companySize,
  companyType,
  color,
  type
}) {
  const location = useLocation();
  const theme = useTheme();
  const navigate = useNavigate();
  const query = useQuery();
  const [selectedBtns, setSelectedBtn] = useState([]);
  const [openDropDown, setOpenDropDownId] = useState(
    query.size < 1 && panelData?.[0]?.id
  );

  const [anchorElOpenSizeFilter, setAnchorElOpenSizeFilter] = useState(null);
  const [hoveredButton, setHoveredButton] = useState();

  const [anchorElOpenTypeFilter, setAnchorElOpenTypeFilter] = useState(null);
  const [open, setOpen] = useState([]);

  useEffect(() => {
    setOpen(panelData?.map((item) => false));
  }, [panelData]);

  const removeBtnSelection = (id) => {
    // setSelectedBtn(id);
  };
  const handleButtonClick = (event, { id, name, title, dropDown }, index) => {
    setOpen(open?.map((item, idx) => (idx === index ? !item : false)));
    setOpenDropDownId(id);
    if (dropDown) {
      if (id === 2) {
        setAnchorElOpenSizeFilter(event.currentTarget);
      } else if (id === 3) {
        setAnchorElOpenTypeFilter(event.currentTarget);
      }
      return;
    }
    if (id === 1) {
      setSelectedBtn([1]);
      navigate(`${location.pathname}`);
    }
    removeBtnSelection(id);
    onChangeFilter(id);
  };

  const handlePopoverCloseSizeFilter = () => {
    setOpen(open?.map((item, idx) => false));
    setAnchorElOpenSizeFilter(null);
  };

  const handlePopoverCloseTypeFilter = () => {
    setOpen(open?.map((item, idx) => false));
    setAnchorElOpenTypeFilter(null);
  };

  const getFiltersImage = (hover, name) => {
    switch (name) {
      case "right":
        return hover ? rightArrowWhite : rightArrow;
      case "left":
        return hover ? leftArrowWhite : leftArrow;
      default:
        break;
    }
  };

  const handleMouseOver = (name) => {
    setHoveredButton(name);
  };

  const handleMouseOut = () => {
    setHoveredButton(null);
  };

  useEffect(() => {
    if (query?.size > 0) {
      setSelectedBtn([
        query.get("companySize")?.length > 0 ? 2 : "",
        query.get("companyType")?.length > 0 ? 3 : "",
      ]);
    } else if (query?.size === 0) {
      setSelectedBtn([panelData?.[0]?.id]);
      setOpenDropDownId(1);
    }
  }, [query?.size, companySize, companyType]);

  return (
    // <Grid xs >
    <Box
      sx={{
        // mt: { sx: 0, sm: topMargin ? '68px' : '16px' },
        textAlign: side === "right" && "end",
        // display: { xs: "none", sm: "flex" },
        display: "flex",
        flexDirection: "column",
        gap: "10px",
        // overflow: { xs: "auto", sm: "hidden" },
      }}
    >
      {panelData?.map((btn, index) => (
        <Box
          key={btn?.id}
          sx={{
            position: "relative",
          }}
        >
          <Tooltip disableInteractive title={btn?.name} placement="top-end">
            <Button
              startIcon={
                btn.dropDown && (
                  <Box
                    component={"img"}
                    src={
                      open[index]
                        ? getFiltersImage(
                            hoveredButton === btn?.id ||
                              btn?.id === openDropDown ||
                              selectedBtns
                                ?.filter((item) => item !== 1)
                                ?.includes(btn.id),
                            "right"
                          )
                        : getFiltersImage(
                            hoveredButton === btn?.id ||
                              btn?.id === openDropDown ||
                              selectedBtns
                                ?.filter((item) => item !== 1)
                                ?.includes(btn?.id),
                            "left"
                          )
                    }
                    sx={{
                      height: "28px",
                      width: "30px",
                    }}
                  />
                )
              }
              endIcon={
                btn.dropDown && (
                  <Box
                    component={"div"}
                    sx={{
                      height: "28px",
                      width: "30px",
                    }}
                  />
                )
              }
              sx={{
                ".MuiButton-startIcon": {
                  marginRight: "0px !important",
                  marginLeft: 0,
                },
                ".MuiButton-endIcon": {
                  marginRight: "0px !important",
                  marginLeft: 0,
                },
                justifyContent: btn?.dropDown && "space-between",
                flexDirection: "row-reverse",
                padding: "6px 7px",
                lineHeight: "inherit",
                borderRadius: "10px",
                width: "100%",
                borderBottomLeftRadius: side === "left" ? { sm: 0 } : "10px",
                borderTopLeftRadius: side === "left" ? { sm: 0 } : "10px",
                borderTopRightRadius: side === "right" ? { sm: 0 } : "10px",
                borderBottomRightRadius: side === "right" ? { sm: 0 } : "10px",
                mr: { xs: 0, sm: 0 },
                minWidth: { xs: "125px", sm: 0 },
                // fontSize: { lg: "12px", xl: "14px" },
                fontSize: theme.typography.fontSize.xs,
                fontWeight:
                  // btn?.name ||
                  openDropDown === btn?.id ||
                  selectedBtns?.filter((item) => item !== 1)?.includes(btn?.id)
                    ? theme.typography.Bold
                    : theme.typography.Regular,
                "&:hover": {
                  // opacity: 1,
                  // backgroundColor: theme.palette[btn.color]?.main,
                  backgroundColor: theme.palette[color].main,
                  color: theme.palette.white,
                  fontWeight: theme.typography.Bold,
                },
                // opacity: selectedBtns.includes(btn.id) ? 1 : 0.5
              }}
              onClick={(e) => {
                setHoveredButton(btn?.id);
                handleButtonClick(e, btn, index);
              }}
              onMouseOver={() => handleMouseOver(btn?.id)}
              onMouseOut={handleMouseOut}
              disableElevation
              variant="contained"
              color={
                openDropDown === btn?.id ||
                selectedBtns?.filter((item) => item !== 1)?.includes(btn?.id)
                  ? color
                  : "base"
              }
              key={btn?.id}
            >
              {truncate(btn?.name, { length: 14 })}
            </Button>
          </Tooltip>
          {btn?.dropDown && openDropDown === btn?.id && (
            <Box>
              {btn?.name === "size" && (
                <Popover
                  id="dropdown-menu"
                  anchorEl={anchorElOpenSizeFilter}
                  open={Boolean(anchorElOpenSizeFilter)}
                  onClose={handlePopoverCloseSizeFilter}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  sx={{
                    "& .MuiPaper-root": {
                      // borderRadius: "0px 0px 20px 20px !important",
                      backgroundColor: "#f2f4fb",
                      // boxShadow: "none",
                      cursor: "pointer",
                    },
                  }}
                >
                  <CompanySizeFilter
                    setCompanySize={setCompanySize}
                    handlePopoverCloseSizeFilter={handlePopoverCloseSizeFilter}
                    onChangeFilter={onChangeFilter}
                    color="filterButtons"
                    setOpenDropDown={setOpenDropDownId}
                    companiesSize={companySize}
                    companiesType={companyType}
                    setOpen={setOpen}
                    type={type}
                  />
                </Popover>
              )}
              {btn?.name === "type" && (
                <Popover
                  id="dropdown-menu"
                  anchorEl={anchorElOpenTypeFilter}
                  open={Boolean(anchorElOpenTypeFilter)}
                  onClose={handlePopoverCloseTypeFilter}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  sx={{
                    "& .MuiPaper-root": {
                      // borderRadius: "0px 0px 20px 20px !important",
                      backgroundColor: "#f2f4fb",
                      // boxShadow: "none",
                      cursor: "pointer",
                    },
                  }}
                >
                  <CompanyTypeFilter
                    setCompanyType={setCompanyType}
                    handlePopoverCloseTypeFilter={handlePopoverCloseTypeFilter}
                    onChangeFilter={onChangeFilter}
                    color={color}
                    setOpenDropDown={setOpenDropDownId}
                    companiesType={companyType}
                    companiesSize={companySize}
                    setOpen={setOpen}
                    type={type}
                  />
                </Popover>
              )}
            </Box>
          )}
        </Box>
      ))}
    </Box>
    // </Grid>
  );
}
