import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  contactList,
  getContactType,
  getContactUsList,
  queryResolve,
} from "../../../redux/guest/contactSlice";
import { Box, Button, Paper } from "@mui/material";
import CustomSelect from "../../candidate/myCV/CustomSelect";
import { debounce } from "../../../utils/Common";
import { filter } from "lodash";
import InfiniteScroll from "react-infinite-scroll-component";
import ContactUsQueryCard from "./ContactUsQueryCard";

const ContactUsQuery = () => {
  const [list, setList] = useState([]);
  const [lastkey, setLastKey] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [filtersValue, setFiltersValue] = useState({});
  const [filterList, setFilterList] = useState([]);
  const [totalSolution, setTotalSolution] = useState(0);
  const [showMsg, setShowMsg] = useState(false);

  const dispatch = useDispatch();

  let selectStyle = {
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        color: isSelected ? "#fff" : "black",
        ":active": {
          ...styles[":active"],
        },
        width: `100%`,
      };
    },
    menu: (provided) => ({
      ...provided,
      zIndex: 9999,
      width: "100%",
    }),
    control: (baseStyles) => ({
      ...baseStyles,
      boxShadow: "none",
      borderRadius: "10px",
      width: `100%`,
    }),
  };

  const getFilterList = async () => {
    try {
      let response = await dispatch(getContactType());
      if (response?.payload?.data) {
        let list = response?.payload?.data?.map((item) => ({
          label: item?.name,
          value: item?.contact_type_id,
        }));
        setFilterList([...list]);
      }
    } catch (error) {
      console.log(error, "error");
    }
  };
  const getList = async (page, key) => {
    const payload = {
      lastKey: page ? page : lastkey,
    };
    if (key) {
      payload["contact_type_id"] = key;
    }
    try {
      let response = await dispatch(getContactUsList(payload));
      if (response?.payload?.status === "success") {
        setList(response?.payload?.data);
        setShowMsg(true);
      }
    } catch (error) {
      console.log(error, "error");
    }
  };
  const queryStatusChange = async (e, value) => {
    let payload = {
      contact_id: value?.contact_id,
      value: e?.target?.checked,
    };
    try {
      let response = await dispatch(queryResolve(payload));
      if (response?.payload?.status === "success") {
        // getList(0);
        // setLastKey(0);
      }
    } catch (error) {
      console.log(error, "error");
    }
  };
  const debouncedHandleOnSelectInputChange = useCallback(
    debounce(
      async (newValue, type) => {
        let params = {
          title: newValue || "",
          limit: 200,
        };
        if (newValue) {
          try {
            if (type === "solution_types") {
            }
          } catch (error) {}
        }
      },
      750,
      setIsLoading
    ),
    []
  );
  const handleOnSelectInputChange = (newValue, inputId) => {
    debouncedHandleOnSelectInputChange(newValue, inputId);
  };
  const handleOnSelectChange = (ind, newValue, val, inputId) => {
    const updatedData = {
      ...filtersValue,
      [inputId]: newValue,
    };
    setFiltersValue(updatedData);
    getList(0, updatedData["query_types"]?.value);
  };
  const handleSwitch = (event, i) => {
    queryStatusChange(event, i);
    // let updatedList = list?.filter((item)=>item === i?.contact_id)
    let updatedList = list?.map((item) => {
      if (item?.contact_id === i?.contact_id) {
        return { ...item, query_resolve: event?.target?.checked };
      } else {
        return item;
      }
    });
    setList([...updatedList]);
  };

  useEffect(() => {
    getList();
    getFilterList();
  }, []);
  return (
    <>
      <Paper
        sx={{
          // p: "20px",
          borderRadius: "25px",
          mt: "55px",
          position: "absolute",
          left: "110px",
          zIndex: 1000,
          width: `calc(100% - 150px)`,
        }}
      >
        <Button
          variant="contained"
          color="talentButton"
          sx={{
            width: "150px",
            height: "45px",
            borderRadius: "25px 0 25px 0",
            padding: "0px !important",
          }}
        >
          all queries
        </Button>
        <Box sx={{ padding: "12px 14px 8px 14px" }}>
          <Box sx={{ width: "50%" }}>
            <CustomSelect
              isClearable={true}
              onMenuOpen={() => {}}
              closeMenuOnSelect={false}
              variant="default"
              onInputChange={handleOnSelectInputChange}
              onChange={handleOnSelectChange}
              // placeholder={"Search or add job title"}
              placeholder={"Select Query Types"}
              inputId="query_types"
              name="query_types"
              value={
                filtersValue?.query_types?.value
                  ? filtersValue?.query_types
                  : null
              }
              isLoading={false}
              customStyle={selectStyle}
              filterOption={"filter"}
              option={filterList}
            />
          </Box>
        </Box>
      </Paper>
      <InfiniteScroll
        style={{ overflow: "hidden" }}
        dataLength={list?.length}
        next={() => getList(lastkey)}
        scrollThreshold={"100px"}
        scrollableTarget={"jobList"}
        hasMore={list?.length < totalSolution ? true : false}
        // loader={<Box>loading....</Box>}
      >
        <Box sx={{ mt: "200px", mb: "16px" }}>
          {list?.length > 0
            ? list?.map((item, index) => (
                <ContactUsQueryCard item={item} handleSwitch={handleSwitch} filterList={filterList}/>
                // <SolutionAdminCard
                //   key={index}
                //   index={solution?.job_id}
                //   solutionContentData={solution}
                //   setSolutionList={setSolutionList}
                //   solutionList={solutionList}
                //   setIsAddSoutionModal={setIsAddSoutionModal}
                //   setIsEdit={setIsEdit}
                //   setEditSolutionData={setEditSolutionData}
                // />
              ))
            : showMsg && (
                <Box
                  sx={{
                    width: "100%",
                    textAlign: "center",
                    mt: 4,
                    color: "black",
                  }}
                >
                  No Jobs
                </Box>
              )}
        </Box>
        <style>
          {`.infinite-scroll-component__outerdiv{
          width:100%
        }`}
        </style>
      </InfiniteScroll>
    </>
  );
};

export default ContactUsQuery;
