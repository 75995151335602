import { Box, Button, Typography, useTheme } from "@mui/material";
import React from "react";
import flip from "../../../assets/NewFlipIcon.svg";
import { getDecodedToken, getToken } from "../../../utils/Common";
import MUIRadialChart from "../../common/MUIRadialChart";

export default function CandidatePersonalityBackCard({
  profile,
  setisHorizontalFlipped,
  isHorizontalFlipped,
  calculateHeight,
}) {
  const theme = useTheme();
  const decodedToken = getDecodedToken(getToken());
  return (
    <>
      <Box
        sx={{
          height: { xs: "300px", tablet: "225px", lg: "280px", mlg: "225px" },
          // width: { xs: "360px", lg: "750px", xl: "750px" },
          background: "#ffff",
          boxShadow: "0px 5px 10px #00000029",
          borderRadius: "25px",
          flex: "1",
        }}
      >
        <Box>
          <Button
            variant="contained"
            color="yoco"
            sx={{
              height: "45px",
              width: "125px",
              fontSize: theme.typography.fontSize.sm,
              fontWeight: theme.typography.Bold,
              borderRadius: "25px 0px 10px 0px",
              padding: "0px",
              cursor: "default",
              ":hover": {
                boxShadow: "none !important",
              },
            }}
          >
            my stats
          </Button>
        </Box>

        <Box
          sx={{
            display: { xs: "flex" },
            alignItems: "center",
            justifyContent: "center",
            gap: {
              sm: "3px",
              tb: "30px",
              lgmm: "10px",
              mlg: "100px",
              xl: "47px",
              xll: "47px",
            },
            marginTop: { xs: "0px", tablet: "0px" },
            width: { xs: "100%", tablet: "84%", md: "100%" },
            height: `calc(100% - 45px)`,
            boxSizing: "border-box",
            paddingBottom: { md24: "45px", mdl: "0px" },
          }}
        >
          {decodedToken?.data?.role_id === 3 ? (
            <>
              <MUIRadialChart
                value={profile?.totoalapplicant}
                chartName={"applications"}
                max={500}
                size={calculateHeight()}
                countFontSize={20}
                countFontWeight={theme.typography.Bold}
                labelFontSize={12}
                labelFontWeight={theme.typography.Regular}
                color={"AppliedUltra"}
              />
              <MUIRadialChart
                value={profile?.totoalshortlisted}
                chartName={"shortlistings"}
                max={100}
                size={calculateHeight()}
                countFontSize={20}
                countFontWeight={theme.typography.Bold}
                labelFontSize={12}
                labelFontWeight={theme.typography.Regular}
                color={"Shortlisted"}
              />

              <MUIRadialChart
                value={profile?.totoalinterviewed}
                chartName={"interviews"}
                max={50}
                size={calculateHeight()}
                countFontSize={20}
                countFontWeight={theme.typography.Bold}
                labelFontSize={12}
                labelFontWeight={theme.typography.Regular}
                color={"Interviewed"}
              />
            </>
          ) : (
            <>
              <MUIRadialChart
                value={profile?.totoalapplicant}
                chartName={"job shares"}
                max={500}
                size={calculateHeight()}
                countFontSize={20}
                countFontWeight={theme.typography.Bold}
                labelFontSize={12}
                labelFontWeight={theme.typography.Regular}
                color={"AppliedUltra"}
              />
              <MUIRadialChart
                value={profile?.totoalshortlisted}
                chartName={"referrals"}
                max={100}
                size={calculateHeight()}
                countFontSize={20}
                countFontWeight={theme.typography.Bold}
                labelFontSize={12}
                labelFontWeight={theme.typography.Regular}
                color={"Shortlisted"}
              />

              <MUIRadialChart
                value={profile?.totoalinterviewed}
                chartName={"bounties"}
                max={50}
                size={calculateHeight()}
                countFontSize={20}
                countFontWeight={theme.typography.Bold}
                labelFontSize={12}
                labelFontWeight={theme.typography.Regular}
                color={"Interviewed"}
              />
            </>
          )}
        </Box>
      </Box>
      <Button
        variant="contained"
        color="redButton100"
        sx={{
          width: "20px",
          height: "100px",
          padding: 0,
          position: "absolute",
          right: 0,
          top: `calc(50% - 50px)`,
          minWidth: "20px",
          fontSize: "20px",
          borderRadius: "10px 0 0 10px",
        }}
        onClick={() => setisHorizontalFlipped(false)}
      >
        <Box
          component={"img"}
          src={flip}
          sx={{
            height: "20px",
            width: "15px",
          }}
        />
      </Button>
    </>
  );
}
