import {
  Box,
  Button,
  IconButton,
  InputBase,
  InputLabel,
  Paper,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useTheme } from "@emotion/react";
import { Modal } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import locale from "../../../../i18n/locale";

const ChangePasswordModal = ({
  errors,
  formData,
  handleFormInput,
  handleCancel,
  handleSubmit,
  handleOpen,
  handleClose,
}) => {
  const i18n = locale.en;
  const theme = useTheme();
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    borderRadius: "12px",
    p: 4,
    boxShadow: "none !important",
    border: "1px solid black !important",
  };

  const handleShowPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleShowPassword1 = () => {
    setShowNewPassword(!showNewPassword);
  };

  return (
    <Modal
      open={handleOpen}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography
          id="modal-modal-title"
          variant="h6"
          component="h2"
          textAlign={"center"}
        >
          Change Password
        </Typography>
        {(errors?.confirm_password || errors?.change_password) && (
          <Typography
            id="modal-modal-title"
            // variant="h6"
            component="h6"
            textAlign={"center"}
            sx={{ color: theme.jobCard.jobStatus.Rejected.main }}
          >
            {i18n["alert.passwordsdonotmatch"]}
          </Typography>
        )}
        {errors?.errorText && (
          <Typography
            id="modal-modal-title"
            // variant="h6"
            component="h6"
            textAlign={"center"}
            sx={{ color: theme.jobCard.jobStatus.Rejected.main }}
          >
            {i18n["alert.passwordmustcontain"]}
          </Typography>
        )}
        <Box sx={{ mt: 3, mb: 4, width: "100%", padding: 0 }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: 1,
            }}
          >
            <Box sx={{ marginBottom: "5px" }}>
              <InputLabel
                htmlFor="change_password"
                sx={{
                  color: "black",
                  paddingBottom: "2px",
                  fontSize: "14px",
                  fontWeight: 600,
                }}
              >
                New Password
              </InputLabel>
              <Paper
                elevation={3}
                sx={{
                  display: "flex",
                  borderRadius: "10px",
                  height: "40px",
                  boxShadow: "none",
                  border:
                    errors?.change_password ||
                    errors?.errorText === "change_password"
                      ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
                      : `1px solid ${theme.palette.grayBorder}`,
                }}
              >
                <InputBase
                  type={`${showNewPassword ? "text" : "password"}`}
                  sx={{ ml: 2, mr: 2, width: "100%" }}
                  id="change_password"
                  placeholder={"Enter your new password"}
                  value={formData?.change_password}
                  onChange={(e) => handleFormInput(e, "change_password")}
                />
                <IconButton
                  sx={{ py: 0 }}
                  color=""
                  aria-label="reset password"
                  component="button"
                  onClick={handleShowPassword1}
                  disableRipple={true}
                >
                  {showNewPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                </IconButton>
              </Paper>
            </Box>
            <Box sx={{ marginBottom: "5px" }}>
              <InputLabel
                htmlFor="confirm_password"
                sx={{
                  color: "black",
                  paddingBottom: "2px",
                  fontSize: "14px",
                  fontWeight: 600,
                }}
              >
                Confirm Password
              </InputLabel>
              <Paper
                elevation={3}
                sx={{
                  display: "flex",
                  borderRadius: "10px",
                  height: "40px",
                  boxShadow: "none",
                  border:
                    errors?.["confirm_password"] ||
                    errors?.errorText === "confirm_password"
                      ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
                      : `1px solid ${theme.palette.grayBorder}`,
                }}
              >
                <InputBase
                  sx={{ ml: 2, mr: 2, width: "100%" }}
                  id="confirm_password"
                  type={`${showConfirmPassword ? "text" : "password"}`}
                  placeholder={"Enter confirm password"}
                  value={formData?.confirm_password}
                  onChange={(e) => handleFormInput(e, "confirm_password")}
                />
                <IconButton
                  sx={{ py: 0 }}
                  color=""
                  aria-label="reset password"
                  component="button"
                  onClick={handleShowPassword}
                  disableRipple={true}
                >
                  {showConfirmPassword ? (
                    <VisibilityIcon />
                  ) : (
                    <VisibilityOffIcon />
                  )}
                </IconButton>
              </Paper>
            </Box>
          </Box>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            sx={{
              height: "38px",
              mr: 2,
              borderRadius: "10px",
              color: "black",
            }}
            variant="contained"
            color="grayButton300"
            onClick={handleCancel}
          >
            cancel
          </Button>
          <Button
            disabled={
              !(formData?.confirm_password && formData?.change_password)
            }
            sx={{
              height: "38px",
              mr: 2,
              borderRadius: "10px",
            }}
            onClick={() => {
              handleSubmit();
            }}
            variant="contained"
            color="redButton100"
          >
            change
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default ChangePasswordModal;
