import { Box, Button, Grid, IconButton, Typography } from "@mui/material";
import React from "react";
import strongMatch from "../../assets/CrayBotIcons/Crayon Craybot Notification-07.svg";
import { useTheme } from "@emotion/react";
import MUIRadialChart from "../common/MUIRadialChart";
import close from "../../assets/Padding Included/Black_Close.svg";
import locale from "../../i18n/locale";
import viewIcon from "../../assets/Padding Excluded/Crayon Icon_Promote.svg";
import emailIcon from "../../assets/Padding Excluded/White_Email.svg";
import { getDecodedToken, getToken } from "../../utils/Common";
import job_volume from "../../assets/Padding Excluded/Crayon Icon_Promote.svg";
import CloseIcon from "../common/CloseIcon";

export default function CrayonBountyInfo({
  setJobPromote,
  job,
  handleShare,
  setShowEmailBox,
  setShowLogin,
  shareInfo,
  setSrc,
}) {
  const i18n = locale.en;
  const theme = useTheme();
  const decodedToken = getDecodedToken(getToken());

  return (
    <>
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Box sx={{ marginBottom: "15px" }}>
          <Button
            variant="contained"
            color="referredButton"
            sx={{
              borderRadius: "25px 0px 10px 0px",
              color: "white",
              fontSize: theme.typography.fontSize.sm,
              fontWeight: theme.typography.Bold,
              cursor: "default",
              ":hover": {
                boxShadow: "none !important",
              },
              width: "150px",
              height: "45px",
              padding: "0px",
            }}
            startIcon={
              <Box
                component="img"
                sx={{
                  height: 30,
                  width: 30,
                  maxHeight: { xs: 30 },
                  maxWidth: { xs: 30 },
                }}
                alt="job_volume"
                src={job_volume}
              />
            }
          >
            {i18n["promoter.topCornorText"]}
          </Button>
          <Box
            onClick={() => {
              setJobPromote(false);
            }}
          >
            <CloseIcon top="15px" right="15px" />
          </Box>
        </Box>

        <Box
          sx={{
            padding: "0px 16px 0 19px",
            height: "150px",
            marginBottom: "20px",
          }}
        >
          <Typography
            sx={{
              fontSize: theme.typography.fontSize.sm,
              fontWeight: theme?.typography?.Bold,
              lineHeight: "19px",
              marginBottom: "10px",
            }}
          >
            {i18n["promoter.headingText"]}
          </Typography>
          <Typography
            sx={{
              fontSize: theme.typography.fontSize.xs,
              color: "black",
              fontWeight: theme?.typography?.Regular,
              textAlign: "left",
              height: "64px",
              lineHeight: "16px",
            }}
          >
            {i18n["promoter.craybotInfo"]}
          </Typography>

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              height: "75px",
            }}
          >
            <Box
              component={"img"}
              src={strongMatch}
              sx={{ width: 75, height: 75, position: "absolute", left: 0 }}
            />
            <Typography
              sx={{
                fontSize: theme.typography.fontSize.xs,
                textAlign: "left",
                color: "black",
                fontWeight: theme?.typography?.Regular,
                width: "221px",
                marginLeft: "66px",
              }}
            >
              {i18n["promoter.text1"]}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          gap: "30px",
          flexDirection: "column",
          width: "100%",
          marginBottom: "38px",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <MUIRadialChart
            value={job?.bounty_amount || 0}
            chartName={"bounty"}
            max={10000}
            size={130}
            countFontSize={20}
            labelFontSize={15}
            color={"strongMatch"}
            formattedValue={`${job?.currency_symbol || job?.currencySymbol} ${
              job?.bounty_amount || 0
            }`}
          />
        </Box>
        <Typography
          sx={{
            fontWeight: theme?.typography?.Bold,
            fontSize: "14px",
            textAlign: "center",
          }}
          width={"284px"}
        >
          {i18n["promoter.text2"]}
        </Typography>
      </Box>
      <Grid
        container
        alignItems="center"
        overflow={"hidden"}
        sx={{
          width: "100%",
          borderRadius: "0 0 25px 25px",
          height: "60px",
        }}
      >
        <Button
          variant="contained"
          sx={{
            borderRadius: 0,
            fontSize: "14px",
            fontWeight: "bold",
            whiteSpace: "nowrap",
            height: "100%",
            width: "50%",
            justifyContent: "center",
            ".MuiButton-startIcon": {
              // marginRight: "0px !important",
              // marginLeft: 0,
              justifyContent: "end",
              width: "26px",
            },
            padding: "0px !important",
            boxShadow: "none !important",
          }}
          onClick={() => handleShare()}
          color="tymeColor"
          startIcon={
            <Box
              component={"img"}
              sx={{
                height: 23,
                width: 23,
                padding: 0,
              }}
              src={viewIcon}
            />
          }
        >
          {i18n["promoter.share"]}
        </Button>

        <Button
          variant="contained"
          sx={{
            borderRadius: "0 0 25px 0",
            width: "50%",
            height: "100%",
            fontSize: "14px",
            fontWeight: "bold",
            ":hover": {
              boxShadow: 0,
            },
            ".MuiButton-startIcon": {
              marginRight: "0px !important",
              marginLeft: 0,
            },
            padding: "0px !important",
            boxShadow: "none !important",
            gap: 1,
          }}
          color="nandos"
          onClick={() => {
            // applyFunction(jobID);
            if (decodedToken === undefined) {
              setShowLogin(true);
            } else {
              setShowEmailBox(true);
            }
          }}
          startIcon={
            <Box
              component={"img"}
              sx={{
                height: 20,
                width: 20,
              }}
              src={emailIcon}
            />
          }
        >
          {i18n["promoter.referafriend"]}
        </Button>
      </Grid>
    </>
  );
}
