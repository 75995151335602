import * as React from "react";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import SmallButton from "./SmallButton";
import {
  Box,
  Button,
  CircularProgress,
  Popover,
  Tooltip,
  useTheme,
} from "@mui/material";
import upClose from "../../assets/Padding Included/Black_Up_Close.svg";
import downClose from "../../assets/Padding Included/Black_Down_Open.svg";
import { NewTalentCardAccordion } from "../../utils/CommonComponent";
import profile from "../../assets/user_logo.svg";
import femaleIcon from "../../assets/Padding Excluded/Black_Female.svg";
import maleIcon from "../../assets/Padding Excluded/Black_Male.svg";
import jobTitleIcon from "../../assets/Padding Excluded/Black_Experience_Title_Job.svg";
import locationIcon from "../../assets/Padding Excluded/Red_Location.svg";
import experienceIcon from "../../assets/Padding Excluded/Yellow_Experience.svg";
import noticePeriodIcon from "../../assets/Padding Excluded/Teal_Notice_Period.svg";
import salaryIcon from "../../assets/Padding Excluded/Blue_Salary.svg";
import TalentSVGButton from "./TalentSVGButton";
import deleteIcon from "../../assets/Padding Included/Blue_Trash_Delete_1.svg";
import exitIcon from "../../assets/emergency-exit-icon.svg";
import CopyToClipboardIcon from "../../assets/CircularIcon/Yellow/Circular Icons__Yellow_Move copy.svg";
import editIcon from "../../assets/Padding Included/Yellow_Edit.svg";
import NotificationsIcon from "@mui/icons-material/Notifications";
import {
  deepCompare,
  getDecodedToken,
  getToken,
  renderColor,
} from "../../utils/Common";
import { useState } from "react";
import { useRef } from "react";
import { CheckCircle, Circle } from "@mui/icons-material";
import activeDownClose from "../../assets/Black_Down_Open - Copy.svg";
import CopyToClipboard from "react-copy-to-clipboard";
import { useDispatch } from "react-redux";
import { setAlert } from "../../redux/configSlice";
import {
  ALERT_TYPE,
  ERROR_MSG,
  PASSWORD_REGEX,
  PERSONALITY,
  PERSONALITY_BUTTONS,
  USER_STATUS,
} from "../../utils/Constants";
import SVGButton from "./SVGButton";
import Databases from "../admin/adminTalent/DialogBox/Databases";
import VideoDialog from "../admin/adminTalent/DialogBox/VideoDialog";
import Document from "../admin/adminTalent/DialogBox/Document";
import locale from "../../i18n/locale";
import EditPersonality from "../admin/adminTalent/DialogBox/EditPersonality";
import EditTalent from "../admin/adminTalent/CommonComponent/EditTalent";
import { useSelector } from "react-redux";
import { getTraits } from "../../redux/employer/postJobSlice";
import { useEffect } from "react";
import {
  deleteCardTalentPool,
  getTalentDataById,
  getTalentPool,
  pinATalent,
  updateUserStatus,
} from "../../redux/admin/jobsSlice";
import {
  convertDOBInYears,
  convertDatetimeWithoutAgo,
  dateConverterMonth,
} from "../../utils/DateTime";
import { formatCurrencyWithCommas } from "../../utils/Currency";
import { Link, useLocation, useParams } from "react-router-dom";
import { Suspense } from "react";
import DeleteConfirmationModal from "./DeleteConfirmationModal";
import HistoryBox from "./HistoryBox";
import ChangeUserStatus from "./ChangeUserStatus";
import { getPromoterReferrals } from "../../redux/candidate/candidateJobs";
import ChangePasswordModal from "../admin/users/team/ChangePasswordModal";
import { changePassword, verifyUsers } from "../../redux/admin/userAdminSlice";
import VerifyUser from "../admin/adminTalent/DialogBox/VerifyUser";
import TalentCardRunMatchPopup from "../admin/adminJobs/DialogBox/TalentCardRunMatchPopup";
import GreenPlay from "../../assets/CircularIcon/Green/Circular Icons__Green_Play copy.svg";
import GreenPlayGrey from "../../assets/CircularIcon/Grey/Circular_Icon_Grey_Play.svg";
import YellowChatHistory from "../../assets/CircularIcon/Yellow/Circular Icons__Yellow_Chat History_2 copy.svg";
import DocumentViewIcon from "../../assets/Circular_icon_green.svg";
import DocumentViewGreyIcon from "../../assets/CircularIcon/Grey/Circular_icon_Grey_Document.svg";
import GreyHistoryIcon from "../../assets/CircularIcon/Grey/Circular Icons__Grey_Chat History.svg";
import GreenHistoryIcon from "../../assets/CircularIcon/Green/Circular Icons__Green_Chat History.svg";
import DataBaseGreenIcon from "../../assets/CircularIcon/Green/Circular Icons__Green_User_Databases.svg";
import RedViewGrey from "../../assets/CircularIcon/Grey/CIrcular_Icon_Grey_View.svg";
import RedView from "../../assets/CircularIcon/Red/Circular Icons__Red_View copy.svg";
import pinIcon from "../../assets/Icons/pinIcon.svg";
import LockResetIcon from "@mui/icons-material/LockReset";
import PinConfirmation from "../admin/adminJobs/editJobs/PinConfirmation";

const TalentDetailComponent = React.lazy(() =>
  import("./TalentDetailComponent")
);

const TalentsCard = ({
  talentContent,
  getJobList,
  setAllTalent,
  allTalent,
  telId,
  poolDelete,
  poolName,
  setTotalPoolUsers,
  buttonDisabled = false,
  setIsShowWarningModal,
}) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const location = useLocation();
  const prevLocation = location.pathname;
  const i18n = locale.en;
  const param = useParams();
  const { id } = useParams();
  const decodedToken = getDecodedToken(getToken());
  const isAdmin = decodedToken?.data?.role_id === 1 ? true : false;
  const viewRef = useRef(false);
  const [searchText, setSearchText] = useState("");
  const [confirmVerify, setConfirmVerify] = useState(false);
  const [openVerify, setOpenVerify] = useState(false);
  const [count, setCount] = useState(0);
  const [isOpenHistoryBox, setOpenHistoryBox] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [isDeleteModal, setIsDeleteModal] = useState(false);
  const [flip, setFlip] = useState(id ? true : false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openActive, setOpenActive] = useState(false);
  const [anchorElPersonality, setAnchorElPersonality] = useState(null);
  const [openVideo, setOpenVideo] = useState(null);
  const [openDocument, setOpenDocument] = useState(null);
  const [anchorElReferral, setAnchorElReferral] = useState(null);
  const [editPersonality, seteditPersonality] = useState(false);
  const [personalityAdded, setPersonalityAdded] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [editTalent, setEditTalent] = useState();
  const open = Boolean(anchorEl);
  const openPersonality = Boolean(anchorElPersonality);
  const openReferral = Boolean(anchorElReferral);
  const openDialog = Boolean(openVideo);
  const openDocumentDialog = Boolean(openDocument);

  const anchorRef = useRef(null);
  const [talentData, setTalentData] = useState([]);
  const [referralsJobs, setReferralsJobs] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [lastKeyReferrals, setLastKeyReferrals] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [totalPoolData, setTotalPoolData] = useState();
  const [lastKey, setLastKey] = useState(0);
  const [detailLoading, setDetailLoading] = useState(true);
  const [personalitiesData, setPersonalitiesData] = useState({
    ...PERSONALITY,
  });
  const [buttonArr, setButtonArr] = useState(PERSONALITY_BUTTONS);
  const [isPasswordChangeModal, setIsPasswordChangeModal] = useState(false);
  const [changePasswordData, setChangePasswordData] = useState({});
  const [changePasswordError, setChangePasswordError] = useState({
    change_password: false,
    confirm_password: false,
    errorText: "",
  });
  const counterRef = useRef(null);
  const [openMatchBox, setOpenMatchBox] = useState(null);
  const anchorElMatchBox = Boolean(openMatchBox);
  const { traits } = useSelector((state) => state.postJobs);
  const [view, setView] = useState(false);
  const [openPinConfirmation, setOpenPinConfirmation] = useState(false);
  const [confirmPin, setConfirmPin] = useState(false);

  const handleView = () => {
    setView((prev) => !prev);

    const url = prevLocation.includes("user-promoters")
      ? `/admin/users/user-promoters/${talentContent?.candidate_user_id}`
      : `/admin/admin-talent/all-talent/candidate-cv/${talentContent?.candidate_user_id}`;
    window.open(url, "_blank");
  };
  const getUserStatusColor = (column) => {
    switch (column) {
      case "active":
        return "lightGreenButton300";
      case "hide":
        return "yellowButton100";
      case "suspend":
        return "redButton100";
      case "blacklist":
        return "black";
      default:
        return "lightGreenButton300";
    }
  };

  const getAllData = async () => {
    try {
      await dispatch(getTraits());
    } catch (error) {
      dispatch(
        setAlert({
          show: true,
          type: ALERT_TYPE?.ERROR,
          msg: ERROR_MSG,
        })
      );
    }
  };
  const handleClick = async (event) => {
    setTableData([]);
    setAnchorEl(event?.currentTarget);
    await getTalent("");
    // tableData?.length === 0 && (await getTalent(""));
  };
  const removeFromPool = async (canID, poolID, type) => {
    let payload = {
      pool_id: poolID,
      talent_id: canID,
    };
    try {
      let response = await dispatch(deleteCardTalentPool(payload));
      if (type === "talent") {
        if (setTotalPoolUsers) {
          setTotalPoolUsers((pre) => pre - 1);
        }
        setAllTalent(
          allTalent?.filter((item) => item?.user_id !== talentContent?.user_id)
        );
      } else {
        let updatedList = tableData?.map((item) => {
          if (item?.pool_id === poolID) {
            return {
              ...item,
              poolUserFlag: false,
            };
          }
          return item;
        });
        setTableData([...updatedList]);
      }

      if (response?.payload?.status === "success") {
        setIsDeleteModal(false);
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE?.SUCCESS,
            msg: "Talent removed from pool successfully",
          })
        );
      }
    } catch (error) {
      dispatch(
        setAlert({
          show: true,
          type: ALERT_TYPE?.ERROR,
          msg: "Something went wrong",
        })
      );
    }
  };

  const handleExpandAccordian = () => {
    setFlip((prevState) => !prevState);
  };

  const handleEdit = () => {
    seteditPersonality((prev) => !prev);
  };

  const handleToggle = () => {
    setOpenActive((prevOpen) => !prevOpen);
  };

  const handleCloseActive = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event?.target)) {
      return;
    }
    setOpenActive(false);
  };

  const handleStatusChange = async (status, statusName) => {
    const data = {
      user_status: status, // 3 for Suspended
      userId: talentContent?.user_id,
    };

    const { payload } = await dispatch(updateUserStatus(data));
    if (payload?.status === "success") {
      dispatch(
        setAlert({
          show: true,
          type: ALERT_TYPE?.SUCCESS,
          msg: i18n["alertMessage.userstatuschange"],
        })
      );
      setOpenActive(false);
      setAllTalent(
        allTalent?.map((item) => {
          if (item?.user_id === talentContent?.user_id) {
            return { ...item, user_status: statusName };
          }
          return item;
        })
      );
    } else {
      dispatch(
        setAlert({
          show: true,
          type: ALERT_TYPE?.ERROR,
          msg: ERROR_MSG,
        })
      );
    }
  };

  const handleListKeyDown = (event) => {
    if (event?.key === "Tab") {
      event.preventDefault();
      setOpenActive(false);
    } else if (event?.key === "Escape") {
      setOpenActive(false);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handlePopoverClose = () => {
    setAnchorElPersonality(null);
  };

  const handlePopoverCloseVideo = () => {
    setOpenVideo(null);
  };

  const handlePopoverCloseReferral = () => {
    setAnchorElReferral(null);
  };

  const handlePopoverCloseDocument = () => {
    setOpenDocument(null);
  };

  const handleCloseEdit = () => {
    setOpenEdit(false);
  };

  const handleTalentEdit = (talentId) => {
    setOpenEdit(true);
    setEditTalent(talentId);
  };

  const handleUserVerify = async () => {
    try {
      const data = {
        Email: talentContent?.email,
      };
      const { payload } = await dispatch(verifyUsers(data));
      if (payload?.status === "success") {
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE?.SUCCESS,
            msg: "User verified successfully!",
          })
        );
        setAllTalent(
          allTalent?.map((item) => {
            if (item?.user_id === talentContent?.user_id) {
              return { ...item, is_verified: 1 };
            }
            return item;
          })
        );
        setConfirmVerify(false);
        setOpenVerify(false);
      } else {
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE?.ERROR,
            msg: "Error",
          })
        );
      }
    } catch (error) {
      dispatch(
        setAlert({
          show: true,
          type: ALERT_TYPE?.ERROR,
          msg: error,
        })
      );
    }
  };

  const getTalent = async (lastkeyy, searchText) => {
    try {
      const { payload } = await dispatch(
        getTalentPool({
          lastKey: lastkeyy,
          clientpool: "",
          candidate_id: talentContent?.user_id,
          query: searchText,
        })
      );
      if (payload?.status === "success") {
        setTotalPoolData(payload?.poolCount);
        if (lastkeyy === 0) {
          setTableData(payload?.data);
          setLastKey(payload?.pageNumber + 1);
        } else {
          setLastKey(payload?.pageNumber + 1);
          setTableData((prevState) => [...prevState, ...payload?.data]);
        }
      }
    } catch (error) {
      dispatch(
        setAlert({
          show: true,
          type: ALERT_TYPE?.ERROR,
          msg: error,
        })
      );
    }
  };

  const handleDropDataApi = async (talentId, personalityadded = false) => {
    if (talentId === null) {
      setDetailLoading(false);
      setTalentData([]);
      return;
    }
    if (!flip || personalityadded || talentId) {
      setDetailLoading(true);
      try {
        let resposne = await dispatch(getTalentDataById(talentId));
        if (resposne?.payload?.status === "success") {
          let personalityData = {
            primary_personality: resposne?.payload?.data?.[0]
              ?.primary_personality_id
              ? resposne?.payload?.data?.[0]?.primary_personality_id
              : "",
            shadow_personality: resposne?.payload?.data?.[0]
              ?.secondary_personality_id
              ? resposne?.payload?.data?.[0]?.secondary_personality_id
              : "", // Fix the typo here
            grit_score: resposne?.payload?.data?.[0]?.grit_score
              ? resposne?.payload?.data?.[0]?.grit_score
              : "",
            traits: resposne?.payload?.data?.[0]?.traits
              ? resposne?.payload?.data?.[0]?.traits?.map((item) => {
                  return item?.trait_id;
                })
              : [],
            culture_ids: resposne?.payload?.data?.[0]?.culture_ids
              ? resposne?.payload?.data?.[0]?.culture_ids?.map((item) => ({
                  label: item?.company_culture?.name,
                  value: item?.company_culture_id,
                }))
              : [],
          };

          setButtonArr(
            PERSONALITY_BUTTONS?.map((item) =>
              item.id === resposne?.payload?.data?.[0]?.primary_personality_id
                ? { ...item, activeLabel: "primary_personality" }
                : item.id ===
                  resposne?.payload?.data?.[0]?.secondary_personality_id
                ? { ...item, activeLabel: "shadow_personality" }
                : item
            )
          );
          setTalentData(resposne?.payload?.data?.[0]);
          setPersonalitiesData(personalityData);
        }
        setDetailLoading(false);
      } catch (error) {
        setDetailLoading(false);
      }
    }
  };

  const getUserRefrrals = async (lastkey) => {
    try {
      const { payload } = await dispatch(
        getPromoterReferrals({ lastKey: lastkey, user_id: telId })
      );
      if (payload?.status === "success") {
        setCount(payload?.count);
        if (payload?.data?.length === 0) {
          setReferralsJobs([]);
        } else {
          if (payload?.pagenumber === 0) {
            setLastKeyReferrals(payload?.pagenumber + 1);
            setReferralsJobs(payload?.data);
          } else {
            setLastKeyReferrals(payload?.pagenumber + 1);
            setReferralsJobs((prevState) => [...prevState, ...payload?.data]);
          }
        }
        setIsLoading(false);
      } else if (payload?.status === "fail") {
        setCount(payload?.count);
        return;
      } else {
        setIsLoading(false);
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE?.ERROR,
            msg: payload?.message,
          })
        );
      }
    } catch (error) {
      setIsLoading(false);
      dispatch(
        setAlert({
          show: true,
          type: ALERT_TYPE?.ERROR,
          msg: "Something went wrong! please relaod the window",
        })
      );
    }
  };

  const handlePasswordModal = (data) => {
    setIsPasswordChangeModal(true);
    setChangePasswordData({ userId: data });
  };

  const handleModalClose = () => {
    setIsPasswordChangeModal(false);
    setChangePasswordError({
      change_password: false,
      confirm_password: false,
      errorText: "",
    });
  };

  const handleChangePasswordSubmit = async () => {
    let payload = {
      userid: changePasswordData?.userId,
      password: changePasswordData?.confirm_password,
    };
    let condition = deepCompare(
      changePasswordData?.change_password,
      changePasswordData?.confirm_password
    );
    if (
      condition?.status &&
      PASSWORD_REGEX?.test(changePasswordData?.change_password) &&
      PASSWORD_REGEX?.test(changePasswordData?.change_password)
    ) {
      try {
        let response = await dispatch(changePassword(payload));
        if (response?.payload?.status === "success") {
          setIsPasswordChangeModal(false);
          setChangePasswordError({
            change_password: false,
            confirm_password: false,
            errorText: "",
          });
          dispatch(
            setAlert({
              show: true,
              type: ALERT_TYPE?.SUCCESS,
              msg: "Password updated successfully",
            })
          );
        }
      } catch (error) {
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE?.ERROR,
            msg: "Something went wrong",
          })
        );
      }
    } else {
      if (condition?.status) {
        setChangePasswordError({
          change_password: false,
          confirm_password: false,
          errorText: "",
        });
      } else {
        setChangePasswordError({
          change_password: true,
          confirm_password: true,
          errorText: "",
        });
      }
    }
  };

  const handleChangePasswordOnChange = (e, name) => {
    let updatedData = { ...changePasswordData };
    updatedData[name] = e.target.value;
    setChangePasswordData({ ...updatedData });
    if (!PASSWORD_REGEX?.test(e.target.value)) {
      setChangePasswordError({
        [name]: false,
        errorText:
          e.target.id === "change_password"
            ? "change_password"
            : "confirm_password",
      });
      return;
    } else {
      setChangePasswordError({
        [name]: false,
        errorText: e.target.id === "change_password" ? "" : "",
      });
    }
  };

  const handleOpenVerify = () => {
    setOpenVerify((prev) => !prev);
  };

  const handleCancelVerify = () => {
    setOpenVerify(false);
    setConfirmVerify(false);
  };

  const handlePinTalent = async () => {
    try {
      let data = {
        talent_id: talentContent?.candidate_user_id,
        value: !talentContent?.pin_talent,
      };
      const { payload } = await dispatch(pinATalent(data));
      if (payload?.status === "success") {
        setAllTalent((prevAllTalent) => {
          const unPinnedTalent = prevAllTalent
            ?.filter(
              (item) =>
                item?.candidate_user_id === talentContent?.candidate_user_id
            )
            ?.map((item) => ({
              ...item,
              pin_talent: !talentContent?.pin_talent,
            }))[0];
          const pinnedTalent = prevAllTalent?.filter(
            (item) =>
              item?.pin_talent &&
              item?.candidate_user_id !== talentContent?.candidate_user_id
          );
          const unPinnedTalents = prevAllTalent?.filter(
            (item) => !item?.pin_talent
          );
          return [...pinnedTalent, unPinnedTalent, ...unPinnedTalents];
        });
        setOpenPinConfirmation(false);
        setConfirmPin(false);
      } else {
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE?.ERROR,
            msg: "Error",
          })
        );
      }
    } catch (error) {
      dispatch(
        setAlert({
          show: true,
          type: ALERT_TYPE?.ERROR,
          msg: error,
        })
      );
    }
  };

  useEffect(() => {
    if (flip) {
      getUserRefrrals(0);
    }
  }, [flip]);

  useEffect(() => {
    if (id) {
      handleDropDataApi(id);
    }
  }, [id]);

  useEffect(() => {
    editPersonality && getAllData();
  }, [editPersonality]);

  return (
    <NewTalentCardAccordion expanded={flip}>
      <AccordionSummary
        aria-controls={`id_${Math.random().toString(36).substr(2, 9)}`}
        id={`id_${Math.random().toString(36).substr(2, 9)}`}
        sx={{ paddingBottom: "2px" }}
      >
        <Box
          sx={{
            display: "flex",
            width: "50%",
            gap: "15px",
            alignItems: "center",
          }}
        >
          <SmallButton
            color="redButton"
            startIcon={
              <Box
                component="img"
                className="eye"
                alt="eye logo"
                src={flip ? upClose : downClose}
                sx={{
                  height: 16,
                  width: 10,
                }}
              />
            }
            startIconMargin={"0px !important"}
            padding={0}
            height={"75px"}
            width={20}
            fontWeight={700}
            borderRadius={flip ? "10px 0px 0px 0px" : "10px 0px 0px 10px"}
            onClick={() => {
              handleExpandAccordian();
              // handleAccordion(telId)
              handleDropDataApi(telId);
            }}
          />

          <Box
            sx={{
              width: "50px",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Box
              component="img"
              className="profileAvatar"
              alt="crayon logo"
              src={
                talentContent?.profile_url !== "NO URL"
                  ? talentContent?.profile_url
                  : profile
              }
              sx={{
                mr: 1,
                height: "48px !important",
                width: "47px !important",
                borderRadius: "10px 10px 0 0",
                border: `1px solid ${theme.palette.borderColor}`,
              }}
            />
            <SmallButton
              color="userID"
              label={`${
                talentContent?.candidate_user_id === null ||
                talentContent?.candidate_user_id === undefined
                  ? "-"
                  : talentContent?.candidate_user_id
              }`}
              mr={1}
              fontSize={10}
              fontWeight={700}
              alignItems="end"
              textColor="#000"
              borderRadius="0px 0px 6px 6px"
              marginTop="-2px"
              minWidth="50px"
              width="fit-content"
            ></SmallButton>
          </Box>
          <Box
            sx={{ width: "43.8%", position: "absolute", top: 0, left: "100px" }}
          >
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <TalentSVGButton
                color={"white"}
                source={
                  talentContent?.gender === "female" ? femaleIcon : maleIcon
                }
                outerHeight={20}
                height={20}
                width={18}
                padding={"0px !important"}
                startIconMargin={"0px !important"}
              />
              <Link
                to={
                  prevLocation.includes("user-promoters")
                    ? `/admin/users/user-promoters/${talentContent?.candidate_user_id}`
                    : `/admin/admin-talent/all-talent/candidate-cv/${talentContent?.candidate_user_id}`
                }
                target={"_blank"}
                style={{
                  textDecoration: "none",
                  color: theme.palette.black,
                }}
              >
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontWeight: theme.typography.Bold,
                    mr: 1,
                    ml: "4px",
                  }}
                >
                  {talentContent?.first_name} {talentContent?.last_name}
                </Typography>
              </Link>
              {talentContent?.dob != null ? (
                <Box
                  sx={{
                    widht: "20px",
                    height: "20px",
                    background: "#F2F4FB",
                    color: "#C9CAD8",
                    borderRadius: "5px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginLeft: "5px",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "12px",
                      fontWeight: theme.typography.Regular,
                      mr: 1,
                      ml: "4px",
                    }}
                  >
                    {`${convertDOBInYears(talentContent?.dob)}`}
                  </Typography>
                </Box>
              ) : (
                ""
              )}
              <Box sx={{ marginLeft: "85px" }}>
                {talentContent?.firstactivity && (
                  <Button
                    variant="contained"
                    color="activeButton"
                    sx={{
                      borderRadius: talentContent?.secondactivity
                        ? "0 0 0 10px"
                        : "0 0 10px 10px",
                      fontSize: "12px",
                      height: "30px",
                      width: "45px",
                      padding: "6px 0px",
                      fontWeight: theme.typography.Bold,
                    }}
                  >
                    {talentContent?.firstactivity}
                  </Button>
                )}
                {talentContent?.secondactivity && (
                  <Button
                    variant="contained"
                    color="recordVideoButton"
                    sx={{
                      borderRadius: talentContent?.firstactivity
                        ? "0 0px 10px 0"
                        : "0 0 10px 10px",
                      fontSize: "12px",
                      height: "30px",
                      width: "75px",
                      padding: "6px 0px",
                      fontWeight: theme.typography.Bold,
                    }}
                  >
                    {talentContent?.secondactivity}
                  </Button>
                )}
              </Box>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <TalentSVGButton
                color={"white"}
                source={jobTitleIcon}
                outerHeight={25}
                height={18}
                width={15}
                padding={"0px !important"}
                startIconMargin={"0px !important"}
              />
              {talentContent?.job_title !== null ? (
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: theme.typography.Bold,
                    mr: 1,
                    ml: "4px",
                  }}
                >
                  {talentContent?.job_title || talentContent?.jobTitle}
                </Typography>
              ) : (
                "-"
              )}
            </Box>
            <Box sx={{ display: "flex" }}>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <TalentSVGButton
                  color={"white"}
                  source={locationIcon}
                  outerHeight={15}
                  height={15}
                  width={18}
                  padding={"0px !important"}
                  startIconMargin={"0px !important"}
                />
                {talentContent?.country_name !== null ? (
                  <Tooltip
                    disableInteractive
                    title={`${talentContent?.town_name} ${talentContent?.country_name}`}
                    placement="top-end"
                  >
                    <Typography
                      sx={{
                        fontSize: "12px",
                        fontWeight: theme.typography.Regular,
                        mr: 2,
                        ml: "4px",
                      }}
                    >
                      {talentContent?.town_name}, {talentContent?.country_name}
                    </Typography>
                  </Tooltip>
                ) : (
                  "-"
                )}
              </Box>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <TalentSVGButton
                  color={"white"}
                  source={salaryIcon}
                  outerHeight={25}
                  height={14}
                  width={18}
                  padding={"0px !important"}
                  startIconMargin={"0px !important"}
                />
                {talentContent?.salary_min !== null &&
                talentContent?.salary_max !== null ? (
                  <Typography
                    sx={{
                      fontSize: "12px",
                      fontWeight: theme.typography.Regular,
                      mr: 2,
                      ml: "4px",
                    }}
                  >
                    {talentContent?.salary_min === 0 ||
                    talentContent?.salaryMin === 0 ? (
                      <>
                        {talentContent?.currency_symbol ||
                          talentContent?.currencySymbol}
                        {formatCurrencyWithCommas(
                          talentContent?.salary_max || talentContent?.salaryMax
                        )}
                        {talentContent?.employment_type === "freelance"
                          ? " phr"
                          : " pm"}
                      </>
                    ) : (
                      <>
                        {talentContent?.currency_symbol ||
                          talentContent?.currencySymbol}
                        {formatCurrencyWithCommas(
                          talentContent?.salary_min || talentContent?.salaryMin
                        )}{" "}
                        to{" "}
                        {talentContent?.currency_symbol ||
                          talentContent?.currencySymbol}
                        {formatCurrencyWithCommas(
                          talentContent?.salary_max || talentContent?.salaryMax
                        )}
                        {talentContent?.employment_type === "freelance"
                          ? " phr"
                          : " pm"}
                      </>
                    )}
                  </Typography>
                ) : (
                  "-"
                )}
              </Box>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <TalentSVGButton
                  color={"white"}
                  source={experienceIcon}
                  outerHeight={15}
                  height={15}
                  width={18}
                  padding={"0px !important"}
                  startIconMargin={"0px !important"}
                />
                {(talentContent?.experience ||
                  talentContent?.experienceYearEnd) !== null ? (
                  <Typography
                    sx={{
                      fontSize: "12px",
                      fontWeight: theme.typography.Regular,
                      mr: 2,
                      ml: "4px",
                    }}
                  >
                    {talentContent?.experience ||
                      talentContent?.experienceYearEnd}{" "}
                    years
                  </Typography>
                ) : (
                  "-"
                )}
              </Box>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <TalentSVGButton
                  color={"white"}
                  source={noticePeriodIcon}
                  outerHeight={15}
                  height={15}
                  width={18}
                  padding={"0px !important"}
                  startIconMargin={"0px !important"}
                />
                {(talentContent?.notice_period ||
                  talentContent?.noticePeriod_description) !== null ? (
                  <Typography
                    sx={{
                      fontSize: "12px",
                      fontWeight: theme.typography.Regular,
                      mr: 1,
                      ml: "4px",
                    }}
                  >
                    {talentContent?.notice_period ||
                      talentContent?.noticePeriod_description}
                  </Typography>
                ) : (
                  "-"
                )}
              </Box>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{ width: "14%", display: "flex", alignItems: "flex-start" }}
          ref={counterRef}
          onClick={() => setOpenMatchBox(counterRef?.current)}
        >
          <Button
            variant="contained"
            color="recordVideoButton"
            sx={{
              fontSize: "12px",
              fontWeight: theme.typography.Bold,
              color: "white",
              minWidth: "30px !important",
              height: "30px",
              borderRadius: "0 0 0 10px",
              padding: "0px !important",
            }}
          >
            {talentContent?.total_user_count ??
              talentContent?.TotalUserCount ??
              "-"}
          </Button>
          <Button
            variant="contained"
            color="recruiterButton"
            sx={{
              fontSize: "12px",
              fontWeight: theme.typography.Bold,
              color: "white",
              minWidth: "30px !important",
              height: "30px",
              borderRadius: "0",
              padding: "0px !important",
            }}
          >
            {talentContent?.total_user_shortlist ??
              talentContent?.totalusershortlisted ??
              "-"}
          </Button>
          <Button
            variant="contained"
            color="interview"
            sx={{
              fontSize: "12px",
              fontWeight: theme.typography.Bold,
              color: "white",
              minWidth: "30px !important",
              height: "30px",
              borderRadius: "0 0 10px 0",
              padding: "0px !important",
            }}
          >
            {talentContent?.total_user_interview ??
              talentContent?.totaluserinterviewed ??
              "-"}
          </Button>
        </Box>
        <Popover
          id="dropdown"
          open={anchorElMatchBox}
          anchorEl={openMatchBox}
          onClose={() => setOpenMatchBox(null)}
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          sx={{
            "& .MuiPopover-paper": {
              borderRadius: "0 0 25px 25px !important",
              width: "375px !important",
            },
          }}
        >
          <TalentCardRunMatchPopup talentContent={talentContent} />
        </Popover>

        <Box
          sx={{
            width: "36%",
            marginRight: "15px",
            position: "absolute",
            right: 0,
          }}
        >
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              {((decodedToken?.data?.role_id === 1 &&
                talentContent?.pin_talent) ||
                (decodedToken?.data?.role_id === 2 &&
                  talentContent?.pin_talent)) && (
                <Tooltip disableInteractive title={"unpin"} placement="top-end">
                  <Box
                   component="img"
                   sx={{
                     height: 20,
                     width: 20,
                     maxHeight: { xs: 20 },
                     maxWidth: { xs: 20 },
                     cursor:"pointer"
                   }}
                   alt="pinIcon"
                   src={pinIcon}
                   onClick={() =>
                    setOpenPinConfirmation(true)
                  }
                 />
                  {/* <TalentSVGButton
                    onClick={() =>
                      setOpenPinConfirmation(true)
                    }
                    color={"white"}
                    source={pinIcon}
                    outerHeight={25}
                    height={25}
                    width={25}
                    padding={"0px !important"}
                    startIconMargin={"0px !important"}
                    margin={"2px 0 0 0"}
                  /> */}
                </Tooltip>
              )}
              {(decodedToken?.data?.role_id === 1 ||
                decodedToken?.data?.role_id === 2) && (
                <LockResetIcon
                  sx={{
                    width: "30px",
                    height: "30px",
                    // marginTop: "5px",
                  }}
                  onClick={() => handlePasswordModal(talentContent?.user_id)}
                />
              )}
              {(decodedToken?.data?.role_id === 1 ||
                decodedToken?.data?.role_id === 2) && (
                <Box
                  onClick={() =>
                    handleTalentEdit(talentContent?.candidate_user_id)
                  }
                  // color={"white"}
                  component={"img"}
                  src={editIcon}
                  sx={{
                    height: "25px",
                    width: "25px",
                  }}
                />
              )}

              {(decodedToken?.data?.role_id === 1 ||
                decodedToken?.data?.role_id === 2) && (
                <NotificationsIcon
                  sx={{ width: "25px", height: "23px", color: "#e41e26" }}
                />
              )}

              {location?.pathname?.includes("pool") && (
                <Box
                  // color={"white"}
                  component={"img"}
                  source={exitIcon}
                  height={20}
                  width={18}
                  padding={"0 8px 0 0 !important"}
                  startIconMargin={"0px !important"}
                  onClick={() => {
                    if (!buttonDisabled) {
                      setIsDeleteModal(true);
                      setConfirmDelete(false);
                    } else {
                      setIsShowWarningModal(true);
                    }
                  }}
                />
              )}
              {(decodedToken?.data?.role_id === 1 ||
                decodedToken?.data?.role_id === 2) && (
                <Box
                  component={"img"}
                  src={deleteIcon}
                  sx={{
                    height: "24px",
                    width: "22px",
                  }}
                />
              )}
              {decodedToken?.data?.role_id === 1 ||
              decodedToken?.data?.role_id === 2 ? (
                <CheckCircle
                  sx={{
                    fontSize: 20,
                    color:
                      talentContent?.is_verified === 0
                        ? `${theme.palette.submitButton.main}`
                        : `${theme.palette.greenSwitch.main}`,
                    paddingRight: "10px",
                  }}
                  onClick={() =>
                    talentContent?.is_verified === 0 &&
                    setOpenVerify((prev) => !prev)
                  }
                />
              ) : null}
            </Box>

            <SmallButton
              backgroundColor={
                theme.manageTalent?.[
                  renderColor(talentContent?.maxInterviewStageName)
                ]?.main
              }
              label={`${
                talentContent?.maxInterviewStageName === null ||
                talentContent?.maxInterviewStageName === undefined
                  ? "-"
                  : talentContent?.maxInterviewStageName
              }`}
              height={25}
              width={70}
              borderRadius="0px 0px 0px 10px"
            />
            <Button
              variant="contained"
              color="dateButton"
              sx={{
                fontSize: "12px",
                fontWeight: theme.typography.Bold,
                color: "#000",
                maxWidth: "90px",
                padding: "8px 10px",
                borderRadius: "0px !important",
                borderRight: "1px solid #EBECF3",
                borderBottom: "1px solid #EBECF3",
                height: "25px !important",
                width: "40px !important",
                whiteSpace: "nowrap",
                ":hover": {
                  boxShadow: "none",
                },
              }}
            >
              {talentContent?.profile_percent_complete === null
                ? "-"
                : `${talentContent?.profile_percent_complete}%`}
            </Button>
            <Button
              variant="contained"
              color="dateButton"
              sx={{
                fontSize: "12px",
                fontWeight: 700,
                color: "#000",
                maxWidth: "90px",
                padding: "8px 10px",
                borderRadius: "0px !important",
                borderRight: "1px solid #EBECF3",
                borderBottom: "1px solid #EBECF3",
                height: "25px !important",
                width: "100px !important",
                whiteSpace: "nowrap",
                ":hover": {
                  boxShadow: "none",
                },
              }}
            >
              {dateConverterMonth(talentContent?.created_at)}
            </Button>
            <Button
              variant="contained"
              color="dateButton"
              sx={{
                fontSize: "12px",
                fontWeight: 700,
                color: "#000",
                padding: "8px 10px",
                maxWidth: "70px",
                borderRadius: "0px !important",
                borderRight: "1px solid #EBECF3",
                borderBottom: "1px solid #EBECF3",
                height: "25px !important",
                width: "max-content",
                whiteSpace: "nowrap",
                ":hover": {
                  boxShadow: "none",
                },
              }}
            >
              {convertDatetimeWithoutAgo(talentContent?.created_at)}
            </Button>
            {talentContent?.user_status ? (
              <>
                <Button
                  ref={anchorRef}
                  id="composition-button"
                  aria-controls={open ? "composition-menu" : undefined}
                  aria-expanded={open ? "true" : undefined}
                  aria-haspopup="true"
                  onClick={handleToggle}
                  variant="contained"
                  color="base"
                  sx={{
                    fontSize: "12px",
                    fontWeight: 700,
                    color: "#000",
                    padding: "0px",
                    width: "100px",
                    gap: "5px",
                    justifyContent: "flex-end",
                    borderRadius: "0px 10px 0px 0px !important",
                    borderBottom: "1px solid #EBECF3",
                    height: "25px !important",
                    ":hover": {
                      boxShadow: "none",
                    },
                    cursor: "pointer",
                  }}
                >
                  {talentContent?.user_status}
                  <Circle
                    fontSize="string"
                    color={getUserStatusColor(talentContent?.user_status)}
                  />
                  <Box
                    component="img"
                    className="eye"
                    alt="eye logo"
                    src={activeDownClose}
                    sx={{
                      height: 25,
                      width: 20,
                    }}
                  />
                </Button>
                <ChangeUserStatus
                  currentStatus={talentContent?.user_status}
                  openActive={openActive}
                  anchorRef={anchorRef}
                  isAdmin={isAdmin}
                  handleListKeyDown={handleListKeyDown}
                  handleCloseActive={handleCloseActive}
                  handleStatusChange={handleStatusChange}
                  userStatus={USER_STATUS}
                />
              </>
            ) : (
              <Box sx={{ width: "100px", textAlign: "center" }}>-</Box>
            )}
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              gap: "10px",
              marginTop: "5px",
            }}
          >
            {decodedToken?.data?.role_id === 1 && (
              <SVGButton
                color={"white"}
                source={DataBaseGreenIcon}
                height={35}
                width={35}
                startIconMargin={"0px !important"}
                padding={"0px !important"}
                onClick={(event) => {
                  setAnchorElPersonality(event.target);
                }}
              />
            )}
            <Popover
              id="dropdown-menu"
              open={openPersonality}
              anchorEl={anchorElPersonality}
              onClose={handlePopoverClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
              sx={{
                "& .MuiPaper-root-MuiPopover-paper": {
                  minWidth: "18% !important",
                  borderRadius: "20px !important",
                  mt: 1,
                },
              }}
            >
              <Databases userID={talentContent?.user_id} />
            </Popover>
            <CopyToClipboard
              text={`${window.location.origin}${prevLocation}/candidate-cv/${talentContent?.candidate_user_id}`}
              onCopy={() => {
                dispatch(
                  setAlert({
                    show: true,
                    type: ALERT_TYPE?.SUCCESS,
                    msg: "Copied to clipboard",
                  })
                );
              }}
            >
              <SVGButton
                color={"white"}
                source={CopyToClipboardIcon}
                height={35}
                width={35}
                startIconMargin={"0px !important"}
                padding={"0px !important"}
              />
            </CopyToClipboard>
            <SVGButton
              color={"white"}
              source={viewRef.current ? RedView : RedViewGrey}
              height={35}
              width={35}
              outerHeight={35}
              outerWidth={35}
              startIconMargin={"0px !important"}
              padding={"0px !important"}
              onClick={handleView}
            />
            <SVGButton
              color={"white"}
              source={
                talentContent?.cam_url !== "No URL" ? GreenPlay : GreenPlayGrey
              }
              height={35}
              width={35}
              startIconMargin={"0px !important"}
              padding={"0px !important"}
              onClick={(event) => setOpenVideo(event.target)}
            />
            <Popover
              id="dropdown-menu"
              open={openDialog}
              anchorEl={openVideo}
              onClose={handlePopoverCloseVideo}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
              sx={{
                "& .css-ll95b0-MuiPaper-root-MuiPopover-paper": {
                  minWidth: "155px !important",
                  borderRadius: "10px !important",
                  mt: 1,
                },
              }}
            >
              <VideoDialog
                handleOpen={handlePopoverCloseVideo}
                camlink={talentContent?.cam_url}
                applicationlink={talentContent?.application_url}
                userID={talentContent?.candidate_user_id}
              />
            </Popover>
            <SVGButton
              color={"white"}
              source={
                talentContent?.cv_url !== "No URL" ||
                talentContent?.candidate_user_id !== "No URL" ||
                talentContent?.portfolio_url !== "No URL"
                  ? DocumentViewIcon
                  : DocumentViewGreyIcon
              }
              height={35}
              width={35}
              startIconMargin={"0px !important"}
              padding={"0px !important"}
              onClick={(event) => setOpenDocument(event.target)}
            />
            <Popover
              id="dropdown-menu"
              open={openDocumentDialog}
              anchorEl={openDocument}
              onClose={handlePopoverCloseDocument}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
              sx={{
                "& .css-ll95b0-MuiPaper-root-MuiPopover-paper": {
                  minWidth: "122px !important",
                  borderRadius: "10px !important",
                  mt: 1,
                },
              }}
            >
              <Document
                handleOpen={handlePopoverCloseDocument}
                cvLink={talentContent?.cv_url}
                userID={talentContent?.candidate_user_id}
                portfolio_url={talentContent?.portfolio_url}
              />
            </Popover>
            <SVGButton
              color={"white"}
              source={YellowChatHistory}
              height={35}
              width={35}
              startIconMargin={"0px !important"}
              padding={"0px !important"}
              onClick={() => setOpenHistoryBox(true)}
            />
            <SVGButton
              color={"white"}
              source={true ? GreyHistoryIcon : GreenHistoryIcon}
              height={35}
              width={35}
              startIconMargin={"0px !important"}
              padding={"0px !important"}
            />
          </Box>
        </Box>
      </AccordionSummary>

      <AccordionDetails
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        {flip && (
          <>
            {!detailLoading ? (
              <Suspense fallback={null}>
                <TalentDetailComponent
                  talentContent={talentContent}
                  handleClick={handleClick}
                  tableData={tableData}
                  setTableData={setTableData}
                  handleClose={handleClose}
                  talentData={talentData}
                  setAnchorEl={setAnchorEl}
                  removeFromPool={removeFromPool}
                  anchorEl={anchorEl}
                  getTalent={getTalent}
                  lastKey={lastKey}
                  totalPoolData={totalPoolData}
                  setAnchorElReferral={setAnchorElReferral}
                  openReferral={openReferral}
                  anchorElReferral={anchorElReferral}
                  handlePopoverCloseReferral={handlePopoverCloseReferral}
                  handleEdit={handleEdit}
                  setFlip={setFlip}
                  handleDropDataApi={handleDropDataApi}
                  buttonDisabled={buttonDisabled}
                  setIsShowWarningModal={setIsShowWarningModal}
                  referralsJobs={referralsJobs}
                  isLoading={isLoading}
                  count={count}
                  setLastKey={setLastKey}
                  searchText={searchText}
                  setSearchText={setSearchText}
                  setTalentData={setTalentData}
                />
              </Suspense>
            ) : (
              <Box
                style={{
                  margin: "15px 0 0 0",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CircularProgress />
              </Box>
            )}
          </>
        )}
      </AccordionDetails>
      {editPersonality && (
        <EditPersonality
          talentContent={{ ...talentContent, ...talentData }}
          show={editPersonality}
          handleOpen={handleEdit}
          seteditPersonality={seteditPersonality}
          setPersonalityAdded={setPersonalityAdded}
          traits={traits}
          handleDropDataApi={handleDropDataApi}
          setPersonalitiesData={setPersonalitiesData}
          personalitiesData={personalitiesData}
          setButtonArr={setButtonArr}
          buttonArr={buttonArr}
        />
      )}
      {isDeleteModal && (
        <DeleteConfirmationModal
          handleOpen={isDeleteModal}
          handleClose={() => {
            setIsDeleteModal(false);
            setConfirmDelete(false);
          }}
          handleLinkedPoolSave={() =>
            removeFromPool(talentContent?.user_id, param?.poolId, "talent")
          }
          handleLinkedPoolCancel={() => {
            setIsDeleteModal(false);
            setConfirmDelete(false);
          }}
          talentName={poolName}
          candidateFirstName={talentContent?.first_name}
          candidateLastName={talentContent?.last_name}
          confirmDelete={confirmDelete}
          setConfirmDelete={setConfirmDelete}
        />
      )}
      {openEdit && (
        <EditTalent
          show={openEdit}
          handleClose={handleCloseEdit}
          editTalent={editTalent}
          getJobList={getJobList}
          setAllTalent={setAllTalent}
          allTalent={allTalent}
          pinTalentFlag={talentContent?.pin_talent}
          talentContent={talentContent}
        />
      )}
      {isOpenHistoryBox && (
        <HistoryBox
          handleOpen={isOpenHistoryBox}
          handleClose={() => setOpenHistoryBox(false)}
          openFor={"jobs"}
          email={talentContent?.email}
        />
      )}
      {isPasswordChangeModal && (
        <ChangePasswordModal
          errors={changePasswordError}
          formData={changePasswordData}
          handleFormInput={handleChangePasswordOnChange}
          handleCancel={handleModalClose}
          handleSubmit={handleChangePasswordSubmit}
          handleOpen={isPasswordChangeModal}
          handleClose={handleModalClose}
        />
      )}
      <VerifyUser
        show={openVerify}
        handleOpen={handleOpenVerify}
        handleCancel={handleCancelVerify}
        handleVerify={handleUserVerify}
        confirmVerify={confirmVerify}
        setConfirmVerify={setConfirmVerify}
        dialogText={`${talentContent?.first_name} ${talentContent?.last_name}`}
      />
      <PinConfirmation
        show={openPinConfirmation}
        handleClose={() => {
          setOpenPinConfirmation(false);
          setConfirmPin(false);
        }}
        handleDelete={handlePinTalent}
        confirmPin={confirmPin}
        setConfirmPin={setConfirmPin}
        text="unpin"
        dialogText={`candidate ${talentContent?.first_name} ${talentContent?.last_name}`}
      />
    </NewTalentCardAccordion>
  );
};

export default React.memo(TalentsCard);
