import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from "@mui/material";
import React from "react";
import upArrow from "../../../../assets/Black_Up_Close - Copy.svg";
import downArrow from "../../../../assets/Black_Down_Open - Copy.svg";
import { TableCells } from "../../../../utils/CommonComponent";
import TalentSVGButton from "../../../common/TalentSVGButton";
import editIcon from "../../../../assets/Padding Included/Yellow_Edit.svg";
import InfiniteScroll from "react-infinite-scroll-component";

export default function SpendItems({spendList,handleOpenAddSpendModal,setIsEdit,handleOpenDelete,viewMoreExpanded, setViewMoreExpanded}) {
  const theme = useTheme();
  // const [viewMoreExpanded, setViewMoreExpanded] = useState(false);

  const handleViewMore = () => {
    setViewMoreExpanded((prevState) => !prevState);
  };

  return (
    <Box
      sx={{
        width: "400px",
        minHeight: "101px",
        background: "white",
        borderRadius: "25px",
        mt: "45px",
        boxShadow: `0px 5px 10px ${theme?.palette?.boxShadow?.main}`,
      }}
    >
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Button
          variant="contained"
          color="redButton"
          sx={{ height: "45px", width: "150px", borderRadius: "25px 0 25px 0" }}
        >
          spend items
        </Button>
        <Button
          variant="contained"
          color="yellowButton400"
          onClick={()=>{handleOpenAddSpendModal();setIsEdit(false)}}
          sx={{ height: "45px", width: "150px", borderRadius: "0 25px 0 25px" }}
        >
          add spend item
        </Button>
      </Box>
      {viewMoreExpanded && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "15px",
            width: "95%",
            mt: "8px",
            overflowY: "auto",
            // height: "360px",
            // height: "360px",
            "::-webkit-scrollbar": {
              height: "5px",
              background: "#DCDEEB",
              display: "unset !important",
            },
          }}
          id="matchTaleneList"
        >
          <InfiniteScroll
            style={{
              overflowX: "hidden",
              scrollbarWidth: "thin",
              display: "flex",
              flexDirection: "column",
              gap: "16px",
              width: "100%",
            }}
            dataLength={spendList?.length}
            //   next={() => getAssociations(lastKey, searchTitle)}
            scrollThreshold={"100px"}
            scrollableTarget={"matchTaleneList"}
            hasMore={true}
            endMessage={
              <p style={{ textAlign: "center" }}>
                <b>Yay! You have seen it all</b>
              </p>
            }
          >
            <TableContainer
              component={Paper}
              sx={{
                boxShadow: "none !important",
                borderRadius: "0px !important",
                height: "400px",
              }}
            >
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCells>
                      <Typography
                        variant="subtitle1"
                        fontWeight="bold"
                        textAlign={"center"}
                      >
                        Item
                      </Typography>
                    </TableCells>
                    <TableCells>
                      <Typography
                        variant="subtitle1"
                        fontWeight="bold"
                        textAlign={"center"}
                      >
                        Coins
                      </Typography>
                    </TableCells>

                    <TableCells>
                      <Typography
                        variant="subtitle1"
                        fontWeight="bold"
                        textAlign={"center"}
                      >
                        Discount
                      </Typography>
                    </TableCells>
                    <TableCells></TableCells>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {spendList?.map((row) => (
                    <TableRow
                      key={row?.id}
                      style={{
                        "& .css-12zgwp-MuiTableCell-root": {
                          padding: "0 16px !important",
                        },
                      }}
                    >
                      <TableCells
                        sx={{ padding: "0px 18px", textAlign: "center" }}
                      >
                        {row?.name}
                      </TableCells>
                      <TableCells
                        sx={{ padding: "0px 18px", textAlign: "center" }}
                      >
                        {row?.amount}
                      </TableCells>
                      <TableCells
                        sx={{ padding: "0px 18px", textAlign: "center" }}
                      >
                        {row?.discount === null ? "":row?.discount}
                      </TableCells>
                      <TableCells
                        sx={{ padding: "0px 18px", textAlign: "center" }}
                      >
                        <Box sx={{ display: "flex", gap: "8px" }}>
                          <TalentSVGButton
                            onClick={()=>{handleOpenAddSpendModal(row);setIsEdit(true)}}
                            color={"white"}
                            source={editIcon}
                            height={26}
                            width={26}
                            padding={"0px !important"}
                            startIconMargin={"0px !important"}
                          />
                        </Box>
                      </TableCells>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </InfiniteScroll>
        </Box>
      )}
      <Button
        sx={{
          color: "black",
          padding: 0,
          width: "100%",
          height: !viewMoreExpanded ? `calc(100% - 45px)` : "45px",
          fontSize: "16px !important",
          fontWeight: theme.typography.Bold,
          alignItems: "flex-end",
          marginTop:!viewMoreExpanded?"28px":"",
          paddingBottom: "16px",
          ".MuiButton-startIcon": {
            marginRight: "0px !important",
            marginLeft: "-18px !important",
          },
        }}
        endIcon={
          <Box
            component="img"
            sx={{
              height: 30,
              width: 30,
              cursor: "pointer",
            }}
            alt="crayon logo"
            src={viewMoreExpanded ? upArrow : downArrow}
          />
        }
        onClick={handleViewMore}
      >
        {viewMoreExpanded ? "Close" : "View items"}
      </Button>
    </Box>
  );
}
