import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  selectedFilter: [],
  inviteStatusFilter: [],
};

const filtersSlice = createSlice({
  name: "filters",
  initialState,
  reducers: {
    setSelectedFilter: (state, action) => {
      state.selectedFilter = action.payload;
    },
    setInviteStatus: (state, action) => {
      state.inviteStatusFilter = action.payload;
    },
  },
});

export const { setSelectedFilter, setInviteStatus } = filtersSlice.actions;

export default filtersSlice.reducer;
