import React, { useEffect, useState } from "react";
import { ALERT_TYPE, USER_ROLES } from "../../../../utils/Constants";
import EmployerCard from "./EmployerCard";
import { setAlert } from "../../../../redux/configSlice";
import { getUserEmployer } from "../../../../redux/admin/jobsSlice";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Box } from "@mui/material";

export default function RecruiterDetailInfo() {
  const dispatch = useDispatch();
  const [employerData, setEmployerData] = useState([]);

  const { id } = useParams();

  const getEmployerList = async (lastkeyy, search) => {
    try {
      const { payload } = await dispatch(
        getUserEmployer({
          lastKey: lastkeyy,
          roleid: USER_ROLES?.[4]?.role_id,
          userId: id,
          query: search,
        })
      );
      if (payload?.status === "success") {
        setEmployerData(payload?.data?.[0]);
      } else {
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE?.ERROR,
            msg: payload?.message,
          })
        );
      }
    } catch (error) {
      dispatch(
        setAlert({
          show: true,
          type: ALERT_TYPE?.ERROR,
          msg: "ERROR",
        })
      );
    }
  };

  useEffect(() => {
    getEmployerList(0, "");
  }, []);

  return (
    <Box
      sx={{
        marginTop: "55px",
      }}
    >
      <EmployerCard
        key={employerData?.user_id}
        employerData={employerData}
        getEmployerList={getEmployerList}
        roleId={USER_ROLES?.[3]?.role_id}
        setEmployerData={setEmployerData}
        allEmployerData={employerData}
      />
    </Box>
  );
}
