import React from "react";
import CustomDialog from "../../../common/CustomDialog";
import { Box, Button, InputBase, Modal, Paper } from "@mui/material";
import { useTheme } from "@emotion/react";
import SelectMenu from "../../../common/SelectMenu";
import { useSelector } from "react-redux";

export default function EditTown({
  show,
  handleOpen,
  currQualification,
  currQualificationType,
  handleEditQual,
  handleEdit,
}) {
  const theme = useTheme();
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    borderRadius: "10px",
    boxShadow: "none !important",
  };
  const { country } = useSelector((state) => state.postJobs);

  return (
    <Modal
      open={show}
      onClose={handleOpen}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Box
          sx={{
            padding: 4,
          }}
        >
          <Paper
            sx={{
              display: "flex",
              height: "40px",
              borderRadius: "10px",
              boxShadow: "none",
              border: `1px solid ${theme.palette.grayBorder}`,
            }}
          >
            <InputBase
              sx={{ ml: 2, mr: 2, width: "100%" }}
              id="current_town"
              value={currQualification}
              onChange={handleEditQual}
              placeholder={"Edit current town title"}
            />
          </Paper>
          <SelectMenu
            name="required_region_id"
            value={currQualificationType}
            onHandleChange={handleEditQual}
            options={country}
            borderRadius={"10px"}
            sx={{
              width: "100%",
              mt: 2,
              border: `1px solid ${theme.palette.grayBorder}`,
              boxShadow: "none !important",
            }}
            placeholder="Select the region"
          />
        </Box>

        <Box>
          <Button
            sx={{
              boxShadow: 0,
              fontSize: "14px",
              width: "50%",
              borderRadius: 0,
              height: "43px",
              background: "lightgray",
              color: "black",
              padding: 3,
            }}
            variant="contained"
            onClick={handleOpen}
          >
            cancel
          </Button>
          <Button
            sx={{
              boxShadow: 0,
              fontSize: "14px",
              width: "50%",
              borderRadius: 0,
              height: "43px",
              padding: 3,
            }}
            variant="contained"
            color="redButton100"
            onClick={handleEdit}
          >
            edit
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}
