import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  Grid,
  Button,
  InputLabel,
  InputBase,
  Dialog,
  IconButton,
  DialogTitle,
  Slider,
  CircularProgress,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import InfiniteScroll from "react-infinite-scroll-component";
import { useDispatch } from "react-redux";
import { setAlert, setLoading } from "../../../redux/configSlice";
import {
  addTalentPool,
  createTalentPool,
  deleteTalentPool,
  getClientPools,
  getLeftDrawerClientPool,
  getLeftDrawerTalentPool,
  getTalentPool,
  setCandidateID,
  setCreatePool,
  uploadPoolImage,
} from "../../../redux/admin/jobsSlice";
import { ALERT_TYPE, ERROR_MSG, USER_ROLES } from "../../../utils/Constants";
import { dateConverterMonth } from "../../../utils/DateTime";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { useTheme } from "@emotion/react";
import IconSection from "./IconSection";
import { ExpandMore } from "@mui/icons-material";
import SmallButton from "../../common/SmallButton";

import deleteIcon from "../../../assets/Padding Excluded/Black_Trash_Delete_1 - Copy.svg";
import editIcon from "../../../assets/Padding Included/Yellow_Edit.svg";
import addProfileIcon from "../../../assets/Padding Excluded/Circular Icons__Red_User_Profile.svg";
import TalentSVGButton from "../../common/TalentSVGButton";
import yellowTriangle from "../../../assets/Characters/Yellow_Triangle_Happy.svg";
import CreatePoolIcon from "../../../assets/Black_Add_To_Pool.svg";
import Delete from "../../candidate/myCam/dialog/Delete";
import EditPool from "./DialogBox/EditPool";
import TeamAccessList from "./DialogBox/TeamAccessList";
import ZoomOutIcon from "@mui/icons-material/Remove";
import ZoomInIcon from "@mui/icons-material/Add";
import CustomDialog from "../../common/CustomDialog";
import Cropper from "react-easy-crop";
import getCroppedImg from "../../../utils/cropImage";
import locale from "../../../i18n/locale";
import { useSelector } from "react-redux";
import { debounce, getDecodedToken, getToken } from "../../../utils/Common";
import SubscriptionPopModal from "../../guest/talent/SubscriptionPopModal";
import CropModal from "../../employer/myProfile/cropModal";
import { adminLinkPool } from "../../../redux/admin/searchSlice";

export default function TalentPool() {
  const i18n = locale.en;
  const decodedToken = getDecodedToken(getToken());
  const boxRef = useRef(null);
  const dispatch = useDispatch();
  const theme = useTheme();
  const navigate = useNavigate();
  const params = useLocation();

  const hiddenFileInput = useRef(null);
  const POOL_INFO = {
    name: "",
    description: "",
    logo: null,
  };
  const location = useLocation();
  const isClient = useRef(false);
  isClient.current = window?.location?.pathname?.includes("client-pool")
    ? true
    : false;
  const { createPool, candidateID } = useSelector((state) => state.adminSlice);
  const [lastKey, setLastKey] = useState(0);
  const [tableData, setTableData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [jobTitleCount, setJobTitleCount] = useState(0);
  const [openAdd, setOpenAdd] = useState(createPool ? true : false);
  const [poolDetails, setPoolDetails] = useState(POOL_INFO);
  const [poolId, setPoolId] = useState(0);
  const [buttonMenu, setButtonMenu] = useState(false);
  const [addTalent, setAddTalent] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [openDialogRow, setOpenDialogRow] = useState(null);
  const [searchTitle, setSearchTitle] = useState("");
  const [createdPoolID, setCreatedPoolID] = useState("");
  const [openEditImage, setOpenEditImage] = useState(false);
  const [imagePreview, setImagePreview] = useState([]);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [imageName, setImageName] = useState("My pic");
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [isShowWarningModal, setIsShowWarningModal] = useState(false);
  const { talentpool } = useSelector((state) => state.adminSlice);
  const [imageBlob, setImageBlob] = useState();
  const [croppedImageUrl, setCroppedImageUrl] = useState(null);
  const [image, setImage] = useState([]);
  const [paramsApiPayload, setParamsApiPayload] = useState();
  const [companyParamsPayload, setCompanyParamsPayload] = useState();
  // Function to handle the click event and open the permission dialog
  const handleOpenPermissionDialog = (rowId) => {
    setButtonMenu((prevState) => !prevState);
    setOpenDialogRow(rowId);
  };
  const apiPayload = params?.state?.payload;
  const companyPayload = params?.state?.companypool;
  useEffect(() => {
    if (apiPayload) {
      setParamsApiPayload(apiPayload);
      navigate(location.pathname, { replace: true });
    } else if (companyPayload) {
      setCompanyParamsPayload(companyPayload);
      navigate(location.pathname, { replace: true });
    }
  }, [navigate, location.pathname]);

  const handleSubscriptionCancel = () => {
    setImageName("My pic");
    setOpenEditImage(false);
    setImageBlob();
    setCroppedImageUrl(null);
    setImage();
  };
  const cropInputFUnction = (e) => {
    const files = e?.target?.files;
    if (files && files?.length > 0) {
      const reader = new FileReader();
      reader.onload = () => setImage(reader?.result);
      reader.readAsDataURL(files?.[0]);
    }
  };
  const getTitles = async (lastkeyy, query) => {
    try {
      const { payload } = await dispatch(
        isClient?.current
          ? getClientPools({
              lastKey: lastkeyy,
              query: query,
              clientpool: true,
            })
          : getTalentPool({ lastKey: lastkeyy, query: query })
      );
      if (payload.status === "success") {
        setJobTitleCount(payload.poolCount);
        if (lastkeyy === 0) {
          setTableData(payload.data);
          setLastKey(payload.pageNumber + 1);
        } else {
          setLastKey(payload.pageNumber + 1);
          setTableData((prevState) => [...prevState, ...payload.data]);
        }
      }
    } catch (error) {}
  };

  const handleNewJobName = (event) => {
    setCreatedPoolID("");
    setPoolDetails({
      ...poolDetails,
      name: event.target.value,
    });
  };
  const handleNewDescription = (event) => {
    setCreatedPoolID("");
    setPoolDetails({
      ...poolDetails,
      description: event.target.value,
    });
  };

  const addToPool = async (canID, poolID) => {
    try {
      const data = {
        candidate_id: canID,
        pool_id: [poolID],
      };
      const { payload } = await dispatch(addTalentPool(data));
      if (payload?.status === "success") {
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE?.SUCCESS,
            msg: "Talent added to pool successfully",
          })
        );
        dispatch(setCandidateID(undefined));
      } else {
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE?.ERROR,
            msg: payload?.message?.message,
          })
        );
      }
    } catch (error) {}
  };

  const handleAddNewJob = async () => {
    try {
      if (poolDetails?.name.trim().length !== 0) {
        let data;
        if (companyParamsPayload) {
          data = {
            title: poolDetails.name,
            description: poolDetails.description,
            role_id: 4,
            ...companyParamsPayload,
          };
        } else {
          data = {
            title: poolDetails.name,
            description: poolDetails.description,
          };
        }
        const { payload } = await dispatch(createTalentPool(data));
        if (payload?.status === "success") {
          setCreatedPoolID(payload?.data?.[0]?.pool_id);
          if (companyParamsPayload) {
            await dispatch(getLeftDrawerClientPool());
            navigate(
              `/admin/admin-talent/client-pool/${payload?.data?.[0]?.pool_id}`,
              { replace: true }
            );
          }
          if (candidateID) {
            await addToPool(candidateID, payload?.data?.[0]?.pool_id);
            navigate(
              `/admin/admin-talent/talent-pool/${payload?.data?.[0]?.pool_id}`
            );
          }
          await getTitles(0);
          if (paramsApiPayload) {
            let updatedPayload = { ...paramsApiPayload };
            if (payload?.data?.[0]?.pool_id) {
              updatedPayload["pool_id"] = payload?.data?.[0]?.pool_id;
            }
            if (payload?.status === "success") {
              const { payload } = await dispatch(adminLinkPool(updatedPayload));
              navigate(
                `/admin/admin-talent/talent-pool/${updatedPayload?.pool_id}`
              );
              setParamsApiPayload();
            }
          }
        } else {
          dispatch(
            setAlert({
              show: true,
              type: ALERT_TYPE.ERROR,
              msg: payload?.message?.message,
            })
          );
        }
      } else {
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE.ERROR,
            msg: "Field is blank",
          })
        );
      }
    } catch (error) {}
  };

  const handleSkipImage = async () => {
    setOpenEditImage(false);
    setOpenAdd(false);
    createdPoolID === "" && (await handleAddNewJob());
    await getTitles(0);
    // isClient?.current
    //   ? await dispatch(getLeftDrawerClientPool())
    //   : await dispatch(getLeftDrawerTalentPool());
    setPoolDetails(POOL_INFO);
  };
  const handleOpenAdd = () => {
    // handleDeletePool()
    setOpenAdd((prevState) => !prevState);
    setPoolDetails(POOL_INFO);
  };
  const handleDeleteDialog = (poolId) => {
    setPoolId(poolId);
    setOpenDelete((prevState) => !prevState);
  };
  const handleCancelDelete = () => {
    setOpenDelete((prevState) => !prevState);
    setConfirmDelete(false);
  };

  const handleDeletePool = async () => {
    const { payload } = await dispatch(deleteTalentPool({ pool_id: poolId }));
    if (payload?.status === "success") {
      dispatch(
        setAlert({
          show: true,
          type: ALERT_TYPE?.SUCCESS,
          msg: "Talent pool Deleted successfully",
        })
      );
      handleCancelDelete();
      await getTitles(0);
      isClient?.current
        ? await dispatch(getLeftDrawerClientPool())
        : await dispatch(getLeftDrawerTalentPool());
    } else {
      dispatch(
        setAlert({
          show: true,
          type: ALERT_TYPE?.ERROR,
          msg: payload?.message?.message,
        })
      );
    }
  };

  const handleEditDialog = (poolInformation) => {
    setPoolDetails(poolInformation);
    setOpenEdit((prevState) => !prevState);
  };

  const handleAddTalentDialog = (id) => {
    // setAddTalent((prevState) => !prevState);
    navigate("/admin/search/build-search", {
      state: { talentpool_id: id?.pool_id, talentName: id?.title },
    });
  };

  const handleImageChange = async (event, name) => {
    if (event?.target?.files?.[0] === undefined) {
      return;
    }
    cropInputFUnction(event);
    const render = new FileReader();
    setZoom(1);
    setImageName(event?.target?.files?.[0].name);
    const imageData = window.URL.createObjectURL(
      new Blob(event?.target?.files, { type: "application/*" })
    );
    setImagePreview(imageData);
    setOpenEditImage(true);
    event.target.value = "";
  };

  const handleImageClick = async () => {
    setImagePreview(null);
    hiddenFileInput?.current?.click();
    // await handleAddNewJob();
  };
  const handleSubscriptionOk = async () => {
    try {
      dispatch(setLoading(true));
      if (poolDetails?.name?.trim()?.length !== 0) {
        let data;
        if (companyParamsPayload) {
          data = {
            title: poolDetails.name,
            description: poolDetails.description,
            role_id: 4,
            ...companyParamsPayload,
          };
        } else {
          data = {
            title: poolDetails.name,
            description: poolDetails.description,
          };
        }
        const response = await dispatch(createTalentPool(data));
        if (response?.payload?.status === "success") {
          const formData = new FormData();
          const blobTofile = new File([imageBlob], imageName, {
            type: "image/jpeg",
          });
          formData.append("pool-logo", blobTofile);
          formData.append("pool_id", response?.payload?.data?.[0]?.pool_id);
          const { payload } = await dispatch(uploadPoolImage(formData));
          if (payload?.status === "success") {
            setOpenEditImage(false);
            setOpenAdd(false);
            dispatch(
              setAlert({
                show: true,
                type: ALERT_TYPE?.SUCCESS,
                msg: "Talent pool added successfully!",
              })
            );
            if (companyParamsPayload) {
              await dispatch(getLeftDrawerClientPool());
              navigate(
                `/admin/admin-talent/client-pool/${response?.payload?.data?.[0]?.pool_id}`
              );
            }
            if (candidateID) {
              await addToPool(
                candidateID,
                response?.payload?.data?.[0]?.pool_id
              );
              navigate(
                `/admin/admin-talent/talent-pool/${response?.payload?.data?.[0]?.pool_id}`
              );
            }
            await getTitles(0);
            isClient?.current
              ? await dispatch(getLeftDrawerClientPool())
              : await dispatch(getLeftDrawerTalentPool());
            setPoolDetails(POOL_INFO);
            dispatch(setLoading(false));
          } else {
            setImageName("My pic");
            dispatch(
              setAlert({
                show: true,
                type: ALERT_TYPE?.ERROR,
                msg: payload?.message,
              })
            );
            dispatch(setLoading(false));
          }
          if (paramsApiPayload) {
            let updatedPayload = { ...paramsApiPayload };
            if (response?.payload?.data?.[0]?.pool_id) {
              updatedPayload["pool_id"] = response?.payload?.data?.[0]?.pool_id;
            }
            const { payload } = await dispatch(adminLinkPool(updatedPayload));
            navigate(
              `/admin/admin-talent/talent-pool/${updatedPayload?.pool_id}`
            );
            setParamsApiPayload();
          }
          setCreatedPoolID(response?.payload?.data?.[0]?.pool_id);
        } else {
          dispatch(
            setAlert({
              show: true,
              type: ALERT_TYPE?.ERROR,
              msg: response?.payload?.message?.message,
            })
          );
          dispatch(setLoading(false));
        }
      } else {
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE?.ERROR,
            msg: "Field is blank",
          })
        );
        dispatch(setLoading(false));
      }
    } catch (error) {
      setImageName("My pic");
      dispatch(setAlert({ show: true }));
      dispatch(setLoading(false));
    }
  };

  // const handleImageEdit = useCallback(
  //   async (event) => {
  //     try {
  //       dispatch(setLoading(true));
  //       if (poolDetails?.name?.trim()?.length !== 0) {
  //         const data = {
  //           title: poolDetails?.name,
  //           description: poolDetails?.description,
  //         };
  //         const response = await dispatch(createTalentPool(data));
  //         if (response?.payload?.status === "success") {
  //           const croppedImage = await getCroppedImg(
  //             imagePreview,
  //             croppedAreaPixels,
  //             0
  //           );
  //           const formData = new FormData();
  //           const blobTofile = new File([croppedImage], imageName, {
  //             type: "image/jpeg",
  //           });
  //           formData.append("pool-logo", blobTofile);
  //           formData.append("pool_id", response?.payload?.data?.[0]?.pool_id);
  //           const { payload } = await dispatch(uploadPoolImage(formData));
  //           if (payload?.status === "success") {
  //             setOpenEditImage(false);
  //             setOpenAdd(false);
  //             dispatch(
  //               setAlert({
  //                 show: true,
  //                 type: ALERT_TYPE.SUCCESS,
  //                 msg: "Talent pool added successfully!",
  //               })
  //             );
  //             await getTitles(0);
  //             isClient?.current
  //               ? await dispatch(getLeftDrawerClientPool())
  //               : await dispatch(getLeftDrawerTalentPool());
  //             setPoolDetails(POOL_INFO);
  //             dispatch(setLoading(false));
  //           } else {
  //             setImageName("My pic");
  //             dispatch(
  //               setAlert({
  //                 show: true,
  //                 type: ALERT_TYPE.ERROR,
  //                 msg: payload?.message,
  //               })
  //             );
  //             dispatch(setLoading(false));
  //           }
  //           setCreatedPoolID(response?.payload?.data?.[0]?.pool_id);
  //           // await getTitles(0);
  //         } else {
  //           dispatch(
  //             setAlert({
  //               show: true,
  //               type: ALERT_TYPE?.ERROR,
  //               msg: response?.payload?.message?.message,
  //             })
  //           );
  //           dispatch(setLoading(false));
  //         }
  //       } else {
  //         dispatch(
  //           setAlert({
  //             show: true,
  //             type: ALERT_TYPE?.ERROR,
  //             msg: "Field is blank",
  //           })
  //         );
  //         dispatch(setLoading(false));
  //       }
  //       // const { payload } = await dispatch(uploadPoolImage(formData));
  //       // if (payload?.status === "success") {
  //       //   setOpenEditImage(false);
  //       //   setOpenAdd(false);
  //       //   dispatch(
  //       //     setAlert({
  //       //       show: true,
  //       //       type: ALERT_TYPE.SUCCESS,
  //       //       msg: "Talent pool added successfully!",
  //       //     })
  //       //   );
  //       //   await getTitles(0);
  //       //   setPoolDetails(POOL_INFO);
  //       // } else {
  //       //   setImageName("My pic");
  //       //   dispatch(
  //       //     setAlert({
  //       //       show: true,
  //       //       type: ALERT_TYPE.ERROR,
  //       //       msg: payload?.message,
  //       //     })
  //       //   );
  //       // }
  //     } catch (error) {
  //       setImageName("My pic");
  //       dispatch(setAlert({ show: true }));
  //       dispatch(setLoading(false));
  //     }
  //   },
  //   [croppedAreaPixels]
  // );

  useEffect(() => {
    setJobTitleCount(0);
    setTableData([]);
    setLastKey(0);
    getTitles(0);
    isClient.current = window?.location?.pathname?.includes("client-pool")
      ? true
      : false;
    setSearchTitle("");
    dispatch(setCreatePool(false));
  }, [params]);

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const handleZoom = (direction) => {
    const step = 0.5;
    let newZoom = zoom;

    if (direction === "+") {
      newZoom = Math.min(zoom + step, 3); // Limit zoom to maximum 3x
    } else if (direction === "-") {
      newZoom = Math.max(zoom - step, 1); // Limit zoom to minimum 1x
    }

    setZoom(newZoom);
  };

  const debouncedHandleOnTextSearch = useCallback(
    debounce(
      async (newValue) => {
        if (!newValue) return; // Early return
        try {
          await getTitles(0, newValue?.target?.value);
        } catch (error) {
          dispatch(
            setAlert({ show: true, type: ALERT_TYPE?.ERROR, msg: ERROR_MSG })
          );
        }
      },
      1000, // Adjust delay as needed
      setIsLoading
    ),
    [dispatch, setTableData, setIsLoading]
  );
  const handleSearch = (event) => {
    // setSearchText(event?.target?.value);
    setSearchTitle(event?.target?.value);
    debouncedHandleOnTextSearch(event);
  };

  const renderFooter = <></>;

  return (
    <>
      {openAdd && (
        <Paper
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: 2,
            mt: 4,
            pt: 2,
          }}
        >
          <IconSection />
          <Box>
            <Typography
              sx={{
                fontWeight: 700,
                textAlign: "center",
              }}
            >
              {tableData?.length > 0
                ? `Create your talent pool`
                : `Create your first pool`}
            </Typography>
            <Typography
              sx={{
                textAlign: "center",
              }}
            >
              Add candidates who have the potential to meet your company or
              project needs
            </Typography>
          </Box>
          <Box
            sx={{
              width: "30%",
              display: "flex",
              flexDirection: "column",
              gap: 1,
            }}
          >
            <Box>
              <InputLabel
                htmlFor="current_job_title"
                sx={{
                  color: "black",
                  paddingLeft: "10px",
                  paddingBottom: "2px",
                  fontSize: "14px",
                  fontWeight: 500,
                }}
              >
                Pool name
              </InputLabel>
              <Paper
                elevation={3}
                sx={{
                  display: "flex",
                  borderRadius: "10px",
                  height: "40px",
                  boxShadow: "none",
                  border: `1px solid ${theme.palette.grayBorder}`,
                }}
              >
                <InputBase
                  sx={{ ml: 2, mr: 2, width: "100%" }}
                  id="Pool_name"
                  placeholder={"enter your talent pool name"}
                  value={poolDetails?.name}
                  onChange={handleNewJobName}
                />
              </Paper>
            </Box>
            <Box>
              <InputLabel
                htmlFor="current_job_title"
                sx={{
                  color: "black",
                  paddingLeft: "10px",
                  paddingBottom: "2px",
                  fontSize: "14px",
                  fontWeight: 500,
                }}
              >
                Description
              </InputLabel>
              <Paper
                elevation={3}
                sx={{
                  display: "flex",
                  borderRadius: "10px",
                  height: "40px",
                  boxShadow: "none",
                  border: `1px solid ${theme.palette.grayBorder}`,
                }}
              >
                <InputBase
                  sx={{ ml: 2, mr: 2, width: "100%" }}
                  id="Pool_name"
                  placeholder={"enter a short description about the pool"}
                  value={poolDetails?.description}
                  onChange={handleNewDescription}
                />
              </Paper>
            </Box>
          </Box>
          <Box
            sx={{
              margin: "auto",
              width: "30%",
            }}
          >
            <Button
              variant="contained"
              color="grayButton100"
              sx={{
                borderRadius: 0,
                width: "33.33%",
                height: "47px",
                borderTopLeftRadius: 25,
              }}
              onClick={handleOpenAdd}
            >
              go back
            </Button>
            <Button
              variant="contained"
              color="grayButton100"
              sx={{
                borderRadius: 0,
                width: "33.33%",
                height: "47px",
              }}
              onClick={handleSkipImage}
              disabled={poolDetails?.name?.trim().length === 0}
            >
              Skip image
            </Button>
            <input
              ref={hiddenFileInput}
              type="file"
              accept="image/*"
              onChange={handleImageChange}
              style={{ display: "none" }}
            />
            <Button
              variant="contained"
              color="yellowButton100"
              sx={{
                borderRadius: 0,
                width: "33.33%",
                height: "47px",
                borderTopRightRadius: 25,
              }}
              onClick={() => {
                handleImageClick();
              }}
              disabled={poolDetails?.name?.trim().length === 0}
            >
              add a pool image
            </Button>
          </Box>
        </Paper>
      )}
      {openEdit && (
        <EditPool
          poolDetails={poolDetails}
          setPoolDetails={setPoolDetails}
          getUpdatedData={getTitles}
          handleToggle={handleEditDialog}
        />
      )}
      {!openAdd && !openEdit && (
        <>
          <Box sx={{ ml: "16px" }}>
            <Typography
              sx={{
                fontSize: "36px",
                fontWeight: 700,
                textAlign: "center",
                // ml: 6
              }}
            >
              {isClient?.current ? "Client Pools" : "Talent Pools"}
              {/* Talent Pools */}
            </Typography>

            <Grid
              container
              spacing={2}
              flexDirection={"column"}
              sx={{
                display: { xs: "none", md: "flex" },
                marginTop: "30px",
                background: "white",
                borderRadius: "25px",
              }}
              boxShadow={1}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  gap: "10px",
                }}
              >
                <Box sx={{ width: 160 }}></Box>
                <Box mt={2}>
                  <IconSection />
                </Box>

                {isClient?.current ? (
                  <Box
                    sx={{
                      width: "200px",
                    }}
                  ></Box>
                ) : (
                  <Button
                    variant="contained"
                    color="yellowButton100"
                    sx={{
                      width: "200px",
                      borderRadius: "0 25px 0 25px",
                    }}
                    onClick={handleOpenAdd}
                  >
                    <TalentSVGButton
                      color={"white"}
                      source={CreatePoolIcon}
                      height={25}
                      width={25}
                      padding={0}
                    />
                    create new pool
                  </Button>
                )}
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  paddingX: 2,
                  paddingY: 1,
                  borderBottom: 2,
                  borderColor: theme.palette.grayBorder,
                }}
              >
                <Box>
                  <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                    <Typography
                      sx={{
                        fontWeight: 700,
                      }}
                    >
                      {isClient?.current ? "Client Pools" : "Talent Pools"}
                      {/* Talent Pools */}
                    </Typography>
                    <SmallButton
                      color="userID"
                      label={jobTitleCount}
                      paddingX={2}
                      fontSize={10}
                      fontWeight={700}
                      textColor="#000"
                      backgroundColor="#DCDEEB"
                      borderRadius="6px"
                      width="fit-content"
                    ></SmallButton>
                  </Box>
                  <Typography>
                    {`Create and add candidates to a ${
                      isClient?.current ? "client pool" : "talent pool"
                    }`}
                  </Typography>
                </Box>

                <Box
                  sx={{
                    width: "42%",
                  }}
                >
                  <Paper
                    elevation={3}
                    sx={{
                      display: "flex",
                      borderRadius: "10px",
                      height: "40px",
                      boxShadow: "none",
                      border: `1px solid ${theme.palette.grayBorder}`,
                    }}
                  >
                    <InputBase
                      sx={{ ml: 2, mr: 2, width: "100%" }}
                      id="Pool_name"
                      placeholder={"search within pool"}
                      value={searchTitle}
                      onChange={(event) => {
                        handleSearch(event);
                      }}
                    />
                  </Paper>
                </Box>
              </Box>
              <InfiniteScroll
                style={{ overflow: "visible" }}
                dataLength={tableData.length}
                next={() => getTitles(lastKey, searchTitle)}
                scrollThreshold={"10px"}
                scrollableTarget={"jobList"}
                hasMore={tableData.length < jobTitleCount ? true : false}
              >
                <Box
                  sx={{
                    mt: 2,
                  }}
                >
                  <TableContainer
                    component={Box}
                    sx={{
                      overflowX: "unset",
                    }}
                  >
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell sx={{ width: "55px" }}></TableCell>
                          <TableCell>
                            <Typography
                              variant="subtitle1"
                              fontWeight="bold"
                              style={{
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <span>Name</span>
                                <span>
                                  <ExpandMore
                                    sx={{
                                      width: "20px",
                                      height: "20px",
                                      marginTop: "5px",
                                    }}
                                  />
                                </span>
                              </div>
                            </Typography>
                          </TableCell>
                          {isClient?.current && (
                            <TableCell>
                              <Typography
                                variant="subtitle1"
                                fontWeight="bold"
                                display={"flex"}
                                alignItems={"center"}
                              >
                                Associated company <ExpandMore />
                              </Typography>
                            </TableCell>
                          )}
                          <TableCell>
                            <Typography
                              variant="subtitle1"
                              fontWeight="bold"
                              style={{
                                display: "flex",
                                alignItems: "center",
                                // justifyContent: "center",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <span>Date Created</span>
                                <span>
                                  <ExpandMore
                                    sx={{
                                      width: "20px",
                                      height: "20px",
                                      marginTop: "5px",
                                    }}
                                  />
                                </span>
                              </div>
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography
                              variant="subtitle1"
                              fontWeight="bold"
                              style={{
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <span> Pool size</span>{" "}
                                <span>
                                  <ExpandMore
                                    sx={{
                                      width: "20px",
                                      height: "20px",
                                      marginTop: "5px",
                                    }}
                                  />
                                </span>
                              </div>
                            </Typography>
                          </TableCell>
                          {decodedToken?.data?.role_id !==
                            USER_ROLES?.[6]?.role_id &&
                            decodedToken?.data?.role_id !==
                              USER_ROLES?.[1]?.role_id && (
                              <TableCell>
                                <Typography
                                  variant="subtitle1"
                                  fontWeight="bold"
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <span>Team Access</span>
                                    <span>
                                      <ExpandMore
                                        sx={{
                                          width: "20px",
                                          height: "20px",
                                          marginTop: "5px",
                                        }}
                                      />
                                    </span>
                                  </div>
                                </Typography>
                              </TableCell>
                            )}
                          <TableCell></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {tableData?.map((row, index) => {
                          return (
                            <TableRow
                              key={row?.id}
                              style={{
                                background: `${
                                  index % 2 === 0 ? "#F2F4FB" : ""
                                }`,
                                "& .css-12zgwp-MuiTableCell-root": {
                                  padding: "0 16px !important",
                                },
                              }}
                            >
                              <TableCell
                                sx={{
                                  width: "46px",
                                  textAlign: "right",
                                  paddingRight: "0px",
                                }}
                              >
                                {row?.profile_url !== "No URL" ? (
                                  <Box
                                    component="img"
                                    className="profileAvatar"
                                    alt="talent pool logo"
                                    src={row?.profile_url}
                                    sx={{
                                      height: "46px !important",
                                      width: "46px !important",
                                      borderRadius: "10px",
                                    }}
                                  />
                                ) : (
                                  <Box
                                    className="profileAvatar"
                                    src={""}
                                    sx={{
                                      height: "46px !important",
                                      width: "46px !important",
                                      borderRadius: "10px",
                                    }}
                                  />
                                )}
                              </TableCell>
                              <TableCell>
                                <Link
                                  to={
                                    isClient?.current
                                      ? `/admin/admin-talent/client-pool/${row?.pool_id}`
                                      : `/admin/admin-talent/talent-pool/${row?.pool_id}`
                                  }
                                  state={row}
                                  target={"_blank"}
                                  style={{
                                    textDecoration: "none",
                                    color: theme.palette.black,
                                    fontWeight: "700",
                                  }}
                                >
                                  {row?.title}
                                </Link>
                              </TableCell>
                              {isClient?.current && (
                                <TableCell>
                                  {row?.company?.company?.name}
                                </TableCell>
                              )}
                              <TableCell>
                                {dateConverterMonth(row?.created_at)}
                              </TableCell>
                              <TableCell>
                                <Link
                                  to={
                                    isClient?.current
                                      ? `/admin/admin-talent/client-pool/${row?.pool_id}`
                                      : `/admin/admin-talent/talent-pool/${row?.pool_id}`
                                  }
                                  state={row}
                                  target={"_blank"}
                                  style={{
                                    textDecoration: "none",
                                    color: theme.palette.black,
                                  }}
                                >
                                  <SmallButton
                                    color="userID"
                                    label={row?.poolUserCount}
                                    paddingX={2}
                                    fontSize={10}
                                    fontWeight={700}
                                    textColor="#000"
                                    backgroundColor="#DCDEEB"
                                    borderRadius="6px"
                                    width="fit-content"
                                  />
                                </Link>
                              </TableCell>
                              {decodedToken?.data?.role_id !==
                                USER_ROLES?.[6]?.role_id &&
                                decodedToken?.data?.role_id !==
                                  USER_ROLES?.[1]?.role_id && (
                                  <TableCell
                                    sx={{
                                      position: "relative",
                                    }}
                                  >
                                    <Button
                                      variant="contained"
                                      color="grayButton100"
                                      disabled={
                                        decodedToken?.data?.role_id ===
                                          USER_ROLES?.[6]?.role_id &&
                                        row?.owner === false
                                          ? true
                                          : false
                                      }
                                      sx={{
                                        width: "70%",
                                        justifyContent: "space-around",
                                        height: "25px",
                                        borderRadius: 2,
                                        backgroundColor: "#DCDEEB",
                                      }}
                                      endIcon={<ExpandMore />}
                                      onClick={() =>
                                        handleOpenPermissionDialog(index)
                                      }
                                    >
                                      Permissions
                                    </Button>
                                    {/* <Box ref={boxRef} > */}
                                    {openDialogRow === index && (
                                      <TeamAccessList
                                        openDialog={buttonMenu}
                                        jobId={row?.pool_id}
                                        closeFunc={handleOpenPermissionDialog}
                                      />
                                    )}
                                    {/* </Box> */}
                                  </TableCell>
                                )}

                              <TableCell
                                sx={{
                                  display: "flex",
                                }}
                              >
                                <Box
                                  component={"img"}
                                  height={30}
                                  width={30}
                                  src={addProfileIcon}
                                  sx={{
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    if (
                                      decodedToken?.data?.role_id ===
                                        USER_ROLES?.[0]?.role_id ||
                                      decodedToken?.data?.role_id ===
                                        USER_ROLES?.[1]?.role_id ||
                                      row?.owner === true
                                    ) {
                                      handleAddTalentDialog(row);
                                    } else {
                                      setIsShowWarningModal(true);
                                    }
                                  }}
                                />
                                <TalentSVGButton
                                  color={"white"}
                                  source={editIcon}
                                  height={34}
                                  width={35}
                                  padding={0}
                                  onClick={() => {
                                    if (
                                      decodedToken?.data?.role_id ===
                                        USER_ROLES?.[0]?.role_id ||
                                      decodedToken?.data?.role_id ===
                                        USER_ROLES?.[1]?.role_id ||
                                      row?.owner === true
                                    ) {
                                      handleEditDialog(row);
                                    } else {
                                      setIsShowWarningModal(true);
                                    }
                                  }}
                                />
                                <TalentSVGButton
                                  color={"white"}
                                  source={deleteIcon}
                                  height={24}
                                  width={18}
                                  padding={0}
                                  onClick={() => {
                                    if (
                                      decodedToken?.data?.role_id ===
                                        USER_ROLES?.[0]?.role_id ||
                                      decodedToken?.data?.role_id ===
                                        USER_ROLES?.[1]?.role_id ||
                                      row?.owner === true
                                    ) {
                                      handleDeleteDialog(row.pool_id);
                                    } else {
                                      setIsShowWarningModal(true);
                                    }
                                  }}
                                />
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
              </InfiniteScroll>
            </Grid>
          </Box>
          <Dialog
            open={addTalent}
            fullWidth={true}
            maxWidth={"xs"}
            scroll="body"
          >
            <DialogTitle>
              <IconButton
                aria-label="close"
                onClick={handleAddTalentDialog}
                sx={{
                  position: "absolute",
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                <Box
                  sx={{
                    width: "20px",
                    height: "20px",
                    color: "#000000",
                    border: 2,
                    fontSize: "18px",
                    borderRadius: "5px",
                  }}
                >
                  X
                </Box>
              </IconButton>
            </DialogTitle>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: 2,
              }}
            >
              <Box
                component={"img"}
                src={yellowTriangle}
                sx={{ width: 150, height: 100 }}
              />
              <Typography
                sx={{
                  textAlign: "center",
                  fontSize: "22px",
                  fontWeight: 700,
                }}
              >
                Get started choosing your A team
              </Typography>
              <Typography
                sx={{
                  textAlign: "center",
                  fontSize: "18px",
                  fontWeight: 800,
                }}
              >
                Add talent to yout First pool
              </Typography>
              <Button
                variant="contained"
                color="redButton100"
                sx={{
                  width: "100%",
                  borderRadius: 0,
                }}
              >
                Search Talents
              </Button>
            </Box>
          </Dialog>
          <Delete
            show={openDelete}
            handleOpen={handleDeleteDialog}
            handleCancel={handleCancelDelete}
            handleDelete={handleDeletePool}
            confirmDelete={confirmDelete}
            setConfirmDelete={setConfirmDelete}
          />
        </>
      )}
      {openEditImage && (
        <CropModal
          handleOpen={openEditImage}
          handleClose={setOpenEditImage}
          handleSubscriptionOk={handleSubscriptionOk}
          handleSubscriptionCancel={handleSubscriptionCancel}
          imagePreview={imagePreview}
          image={image}
          setImage={setImage}
          croppedImageUrl={croppedImageUrl}
          setCroppedImageUrl={setCroppedImageUrl}
          imageBlob={imageBlob}
          setImageBlob={setImageBlob}
        />
      )}
      {/* <CustomDialog
        dialogWidth="md"
        show={openEditImage}
        onDialogClose={() => {
          setImageName("My pic");
          setOpenEditImage(false);
        }}
        footer={renderFooter}
        isProfile
      >
        <Box
          sx={{
            position: "absolute",
            top: "10%",
            left: "10%",
            right: "10%",
            bottom: "20%",
            ".reactEasyCrop_Contain": {
              maxWidth: "40% !important",
            },
          }}
        >
          <Cropper
            image={imagePreview}
            crop={crop}
            zoom={zoom}
            aspect={1}
            cropShape="round"
            showGrid={true}
            onCropChange={setCrop}
            onCropComplete={onCropComplete}
          />
        </Box>
        <Box
          className="controls"
          sx={{
            position: "absolute",
            bottom: "20px",
            left: "50%",
            width: "50%",
            transform: "translateX(-50%)",
            height: "90px",
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <Box
            sx={{
              display: "flex",
            }}
          >
            <Button variant="text" onClick={() => handleZoom("-")}>
              <ZoomOutIcon />
            </Button>
            <Box
              className="controls"
              sx={{
                width: 200,
                mx: 3,
              }}
            >
              <Slider
                defaultValue={0}
                size="small"
                value={zoom}
                min={1}
                max={3}
                step={0.5}
                aria-labelledby="Zoom"
                onChange={(e) => {
                  setZoom(e.target.value);
                }}
                className="zoom-range"
              />
            </Box>
            <Button variant="text" onClick={() => handleZoom("+")}>
              <ZoomInIcon />
            </Button>
            <Button variant="text" onClick={() => setZoom(1)}>
              Reset
            </Button>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              paddingTop: 2,
            }}
          >
            <Button
              onClick={() => {
                setImageName("My pic");
                setOpenEditImage(false);
              }}
              disableElevation
              variant="outlined"
              color="redButton"
              sx={{ width: "130px", mr: 2 }}
            >
              {i18n["myProfile.cancel"]}
            </Button>
            <Button
              onClick={handleImageEdit}
              disableElevation
              variant="contained"
              color="redButton"
              sx={{ width: "130px" }}
            >
              {i18n["myProfile.upload"]}
            </Button>
          </Box>
        </Box>
      </CustomDialog> */}
      {isShowWarningModal && (
        <SubscriptionPopModal
          handleOpen={isShowWarningModal}
          handleClose={() => setIsShowWarningModal(false)}
          handleSubscriptionOk={() => setIsShowWarningModal(false)}
          modalHeading={"Permission Denied"}
          modalContent={"You don’t have permission to access this"}
        />
      )}
    </>
  );
}
