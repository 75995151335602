import {
  Box,
  Button,
  CircularProgress,
  InputBase,
  Paper,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import locale from "../../../../../i18n/locale";
import {
  claimVoucher,
  getTopUpPlans,
  purchaseRequest,
} from "../../../../../redux/employer/empProfileSlice";
import { useDispatch } from "react-redux";
import { setAlert } from "../../../../../redux/configSlice";
import { ALERT_TYPE } from "../../../../../utils/Constants";
import ConfirmTopUpBox from "./ConfirmTopUpBox";
import { changePaymentStatus } from "../../../../../redux/admin/maintenance";

export default function CompanyTopUp({
  setCompanyTopUp,
  companyData,
  coins,
  setCompanyData,
  allCompanyData,
}) {
  const theme = useTheme();
  const i18n = locale.en;
  const dispatch = useDispatch();
  const [topUpPlans, setTopUpPlans] = useState([]);
  const [purchaseHitory, setPurchaseHistory] = useState();
  const [voucherName, setVoucherName] = useState("");
  const [error, setError] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [confirmUnlock, setConfirmUnlock] = useState(false);

  const getTopUp = async (lastKeyy) => {
    try {
      const { payload } = await dispatch(getTopUpPlans());
      if (payload?.status === "success") {
        setTopUpPlans(payload?.data);
      } else {
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE?.ERROR,
            msg: payload?.message,
          })
        );
      }
    } catch (error) {
      dispatch(
        setAlert({
          show: true,
          type: ALERT_TYPE?.ERROR,
          msg: error,
        })
      );
    }
  };

  const handleClaim = async () => {
    setIsLoading(true);
    try {
      let data = {
        name: voucherName,
        user_id: companyData?.user_id,
      };
      const { payload } = await dispatch(claimVoucher(data));
      if (payload?.status === "success") {
        setIsLoading(false);
        if (payload?.data?.status === "fail") {
          // dispatch(
          //   setAlert({
          //     show: true,
          //     type: ALERT_TYPE?.ERROR,
          //     msg: payload?.data?.message,
          //   })
          // );
          setError(payload?.data?.message);
        } else {
          setCompanyData(
            allCompanyData?.map((item) =>
              item?.company_id === companyData?.company_id
                ? { ...item, coins: payload?.data?.coins }
                : item
            )
          );
          dispatch(
            setAlert({
              show: true,
              type: ALERT_TYPE?.SUCCESS,
              msg: "Voucher claimed successfully",
            })
          );
          setCompanyTopUp(null);
        }
      } else {
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE?.ERROR,
            msg: payload?.message,
          })
        );
      }
    } catch (error) {}
  };

  const handleVoucher = (event) => {
    const { value } = event?.target;
    const trimmedValue = value.trim();
    setError("");
    setVoucherName(trimmedValue);
  };

  const handleChangeStatus = async (paymentTransactionID) => {
    try {
      let data = {
        payment_transaction_id: paymentTransactionID,
        coins: purchaseHitory?.coins,
        employer_id: companyData?.user_id,
        company_id: companyData?.company_id,
        transaction_name_id: purchaseHitory?.transaction_name_id,
        approved: true,
      };
      const { payload } = await dispatch(changePaymentStatus(data));

      if (payload?.status === "success") {
        // dispatch(
        //   setAlert({
        //     show: true,
        //     type: ALERT_TYPE?.SUCCESS,
        //     msg: "Payment status updated successfully",
        //   })
        // );
        setCompanyData(
          allCompanyData?.map((item) =>
            item?.company_id === companyData?.company_id
              ? { ...item, coins: payload?.coins }
              : item
          )
        );
      } else {
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE?.ERROR,
            msg: payload?.message,
          })
        );
      }
    } catch (error) {
      dispatch(
        setAlert({
          show: true,
          type: ALERT_TYPE?.ERROR,
          msg: error,
        })
      );
    }
  };

  const handlePurchase = async (confirmUnlock) => {
    try {
      let data = {
        cost: purchaseHitory?.cost,
        coins: purchaseHitory?.coins,
        currency_id: purchaseHitory?.currency_id,
        company_id: companyData?.company_id,
        transaction_name_id: purchaseHitory?.transaction_name_id,
      };
      const { payload } = await dispatch(purchaseRequest(data));
      if (payload?.status === "success") {
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE?.SUCCESS,
            msg: "Transaction completed successfully",
          })
        );
        confirmUnlock &&
          (await handleChangeStatus(payload?.payment_transaction_id));
        setShowConfirm(false);
        setConfirmUnlock(false);
        setCompanyTopUp(null);
      } else {
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE?.ERROR,
            msg: payload?.message,
          })
        );
      }
    } catch (error) {
      dispatch(
        setAlert({
          show: true,
          type: ALERT_TYPE?.ERROR,
          msg: error,
        })
      );
    }
  };

  useEffect(() => {
    getTopUp();
  }, []);

  return (
    <>
      <Button
        variant="contained"
        color="getCoins"
        sx={{ width: "150px", height: "45px", borderRadius: "25px 0 25px 0" }}
      >
        coin history
      </Button>
      <Box
        sx={{
          padding: "18px 24px 20px 17px",
        }}
      >
        <Typography
          sx={{
            fontSize: "12px",
            fontWeight: theme.typography.Regular,
            paddingBottom: "22px",
          }}
        >
          {i18n["adminUsers.coinHistoryText"]}
        </Typography>
        <Typography
          sx={{
            fontSize: "14px",
            fontWeight: theme.typography.Bold,
            width: "91%",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          {i18n["myplanPopup.cointopup"]}
        </Typography>
        <Box
          sx={{
            minHeight: "135px",
            display: "flex",
            flexDirection: "column",
            gap: "12px",
            width: "78%",
            mt: "10px",
            "::-webkit-scrollbar": {
              height: "5px",
              background: "#DCDEEB",
              display: "unset !important",
            },
          }}
          id="matchTaleneList"
        >
          {topUpPlans?.length > 0 ? (
            topUpPlans?.map((item) => {
              return (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                  onClick={() => {
                    // setShowPurchase(true);
                    setPurchaseHistory(item);
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "14px",
                      fontWeight: theme.typography.Regular,
                    }}
                  >
                    {item?.coins} coins{" "}
                    {item?.discount > 0 && `(${item?.discount}% discount)`}
                  </Typography>

                  <Button
                    variant="contained"
                    color="submitButton"
                    sx={{
                      minWidth: "50px !important",
                      height: "25px",
                      borderRadius: "5px",
                      padding: "4px 2px !important",
                      fontSize: "12px",
                      fontWeight: theme.typography.Bold,
                      color: "black",
                    }}
                  >
                    R{item?.cost}
                  </Button>
                </Box>
              );
            })
          ) : (
            <Box
              style={{
                margin: "15px 0 0 0",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CircularProgress color="inherit" size={20} />
            </Box>
          )}
        </Box>
        <Box
          sx={{
            mt: "10px",
            display: "flex",
            flexDirection: "column",
            gap: "5px",
            width: "87%",
          }}
        >
          <Typography
            sx={{ fontSize: "14px", fontWeight: theme.typography.Bold }}
          >
            {i18n["myplanPopup.claimavoucher"]}
          </Typography>

          <Paper
            sx={{
              display: "flex",
              justifyContent: "space-between",
              height: "40px",
              borderRadius: "10px",
              boxShadow: "none",
              width: "90%",
            }}
          >
            <Paper
              elevation={0}
              component="form"
              sx={{
                display: "flex",
                borderRadius: "10px 0 0 10px",
                alignItems: "center",
                border: "1px solid #E0E0E0",
                width: "100%",
                // width: 1,
              }}
            >
              <InputBase
                sx={{
                  ml: 2,
                  width: 1,
                }}
                value={voucherName}
                onChange={(event) => handleVoucher(event)}
                // onKeyDown={() => voucherName !== "" && handleClaim()}
                onKeyDown={(event) => {
                  if (event.key === "Enter") {
                    event.preventDefault(); // Stop page reload
                    handleClaim();
                  }
                }}
                placeholder={i18n["myplanPopup.thevouchercode"]}
                //   type={inputType}
              />
              {isLoading && (
                <Box
                  style={{
                    mr: "2px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                  }}
                >
                  <CircularProgress color="inherit" size={20} />
                </Box>
              )}
              {error !== "" && (
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: theme.typography.Regular,
                    width: `calc(100% - 100px)`,
                    textAlign: "end",
                    mr: "2px",
                    color: "red",
                  }}
                >
                  {error}
                </Typography>
              )}
            </Paper>
            <Button
              sx={{
                minWidth: "fit-content",
                boxShadow: 0,
                height: "40px",
                width: "100px",
                borderRadius: "0 10px 10px 0",
              }}
              variant="contained"
              color="getCoins"
              onClick={() => voucherName !== "" && handleClaim()}
            >
              {i18n["myplanPopup.claim"]}
            </Button>
          </Paper>
        </Box>
        {purchaseHitory && (
          <>
            <Typography
              sx={{
                fontSize: "14px",
                fontWeight: theme.typography.Bold,
                mt: "15px",
                mb: "10px",
              }}
            >
              {i18n["myplanPopup.purchaseSummary"]}
            </Typography>
            <Box
              sx={{
                width: "78%",
                display: "flex",
                flexDirection: "column",
                gap: "10px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
                // onClick={() => {
                //   setShowPurchase(true);
                //   setPurchaseHistory((prev) => [...prev, item]);
                // }}
              >
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: theme.typography.Regular,
                  }}
                >
                  {purchaseHitory?.coins} coins{" "}
                  {purchaseHitory?.discount > 0 &&
                    `(${purchaseHitory?.discount}% discount)`}
                </Typography>
                <Box sx={{ display: "flex", gap: "4px" }}>
                  <Button
                    variant="contained"
                    color="submitButton"
                    sx={{
                      minWidth: "50px !important",
                      height: "25px",
                      borderRadius: "5px",
                      padding: "4px 2px !important",
                      fontSize: "12px",
                      fontWeight: theme.typography.Bold,
                      color: "black",
                    }}
                  >
                    R{purchaseHitory?.cost}
                  </Button>
                </Box>
              </Box>
            </Box>
          </>
        )}
      </Box>
      <Button
        onClick={() => purchaseHitory && setShowConfirm(true)}
        variant="contained"
        color="talentButton"
        sx={{ width: "100%", height: "60px", borderRadius: "0 0 25px 25px" }}
      >
        confirm
      </Button>

      {showConfirm && (
        <ConfirmTopUpBox
          text1={i18n["myplanPopup.confirmcoinspend"]}
          text1Width={"186px"}
          text2={`${companyData?.name} ${i18n["myplanPopup.employercoinbalance"]}`}
          coinsBalance={`${companyData?.coins || 0} coins.`}
          coinsUse={`${purchaseHitory?.coins} coins.`}
          text2Width={"290px"}
          text3={`${i18n["myplanPopup.addcoins"]}`}
          text3Width={"270px"}
          text3Height={"72px"}
          text4={`to ${companyData?.name}`}
          // text4Width={"220px"}
          // buttonText={i18n["myplanPopup.happyletsdothis"]}
          handleClose={() => setShowConfirm(false)}
          show={showConfirm}
          buttonClick={handlePurchase}
          gap={"18px"}
          setConfirmUnlock={setConfirmUnlock}
          confirmUnlock={confirmUnlock}
        />
      )}
    </>
  );
}
