import { Box, Button, InputBase, Typography, useTheme } from "@mui/material";
import React from "react";
import CustomDialog from "../common/CustomDialog";
import RedTriangle from "../../assets/Characters/Red_Triangle_Blank.svg";

export default function SwitchConfirmationBox({
  openEdit,
  handleClose,
  handleSwitch,
}) {
  return (
    <CustomDialog
      show={openEdit}
      hideButton={false}
      dialogWidth="xs"
      showFooter={false}
      onDialogClose={handleClose}
      padding={0}
    >
      <Box
        sx={{
          padding: 4,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Box
          component={"img"}
          src={RedTriangle}
          sx={{ width: 150, height: 100 }}
        />
        <Typography
          fontSize={16}
          fontWeight={"bold"}
          textAlign={"center"}
          paragraph
          margin={"16px 0 0 0"}
        >
          You will be logged out from the system. Please log in again with the
          same credentials.
        </Typography>
      </Box>
      <Box>
        <Button
          sx={{
            boxShadow: 0,
            fontSize: "14px",
            width: "100%",
            borderRadius: 0,
            height: "43px",
            color: "black",
            padding: 3,
          }}
          color="submitButton"
          variant="contained"
          onClick={() => handleSwitch()}
        >
          okay
        </Button>
      </Box>
    </CustomDialog>
  );
}
