import React from "react";
import { Box, Button, Typography } from "@mui/material";
import MUIRadialChart from "../MUIRadialChart";
import {
  // convertDatetimeWithValue,
  daysRemainingToComplete30Days,
} from "../../../utils/DateTime";
import locale from "../../../i18n/locale";
import { useSelector } from "react-redux";
import { getDaysRemainingColor } from "../../../utils/Common";
import { useDispatch } from "react-redux";
import { setOpenCoinBox } from "../../../redux/candidate/alerts";
import moment from "moment";

// const getDaysLeft = (createdDate) =>{
//   const daysDifference = calculateDaysFromDateToCurrent(createdDate)
//   if(daysDifference > 30){
//     return 0
//   }
//   return daysDifference
// }
const TrackButton = ({
  theme,
  closeFunc,
  jobCreationDate,
  newCardWidth,
  setGetCoins,
  setJobExtend,
  setSelectedJob,
  status,
}) => {
  const dispatch = useDispatch();
  const i18n = locale.en;
  const { coinsCount } = useSelector((state) => state?.configalert);

  const convertDatetimeWithValue = (value) => {
    if (value) {
      const now = moment();
      const datetime = moment.utc(value).local();
      const diffInDays = now.diff(datetime, "days");
      const result = 30 - diffInDays;
      return Math.max(0, result); // Ensure the result is not negative
    } else {
      return 0;
    }
  };
  const daysRemaining = convertDatetimeWithValue(jobCreationDate);
  console.log(convertDatetimeWithValue(jobCreationDate),"DATAAAAAAAAA");
  const getColor = (value) => {
    if (value < 10) {
      return "weakMatch";
    } else if (value >= 10 && value <= 20) {
      return "averageMatch";
    } else {
      return "strongMatch";
    }
  };
  return (
    <Box
      sx={{
        textAlign: "center",
        display: "flex",
        flexGrow: 1,
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <Box
        sx={{
          padding: "6px 16px 0 19px",
          display: "flex",
          flexDirection: "column",
          // gap: "15px",
          // height: "100px",
        }}
      >
        <Typography
          sx={{
            fontSize: theme.typography.fontSize.xs,
            textAlign: "justify",
            color: "black",
            width: `calc(${newCardWidth - 30}px)`,
            fontWeight: theme.typography.Regular,
            lineHeight: "16px",
            height: "48px",
          }}
        >
          {i18n["empMyJobs.Eachnewjobis"]}
          <span
            style={{
              color: "black",
              fontWeight: theme.typography.Bold,
            }}
          >
            {" "}
            {i18n["empMyJobs.days"]}
          </span>{" "}
          {i18n["empMyJobs.extendJob"]}
          <span
            style={{
              color: "black",
              fontWeight: theme.typography.Bold,
            }}
          >
            {" "}
            {i18n["empMyJobs.coins"]}
          </span>
          .{i18n["empMyJobs.extensionisvalid"]}
          <span
            style={{
              color: "black",
              fontWeight: theme.typography.Bold,
            }}
          >
            {" "}
            {i18n["empMyJobs.days"]}.
          </span>
        </Typography>
        <Box sx={{ marginBottom: "28px" }}>
          <Typography
            sx={{
              fontSize: theme.typography.fontSize.sm,
              fontWeight: theme.typography.Bold,
              margin: "13px 0 11px 0",
            }}
          >
            {i18n["empMyJobs.totaldaysremaining"]}
          </Typography>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <MUIRadialChart
              chartName={"days left"}
              value={Number(daysRemaining)}
              // value={3}
              size={100}
              max={30}
              countFontSize={20}
              labelFontSize={12}
              color={getColor(daysRemaining)}
              textColor={getColor(daysRemaining)}
            />
          </Box>
        </Box>
        <Box sx={{ marginBottom: "56px" }}>
          <Typography
            sx={{
              fontSize: theme.typography.fontSize.sm,
              fontWeight: theme.typography.Bold,
              margin: "0px 0 11px 0",
            }}
          >
            {i18n["empMyJobs.totalcreditavaliable"]}
          </Typography>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <MUIRadialChart
              chartName={"coins left"}
              value={coinsCount}
              size={100}
              max={100}
              countFontSize={20}
              labelFontSize={12}
              color={"coins"}
              textColor={"coins"}
            />
          </Box>
        </Box>
      </Box>

      <Box sx={{ display: "flex", width: "100%" }}>
        <Button
          variant="contained"
          color="topUpCoinButton"
          sx={{
            borderRadius: 0,
            width: "50%",
            paddingY: "25px",
            fontSize: theme.typography.fontSize.sm,
            fontWeight: theme.typography.Bold,
            height: "60px",
            boxShadow: 0,
            ":hover": {
              boxShadow: 0,
            },
          }}
          onClick={() => dispatch(setOpenCoinBox(true))}
        >
          {i18n["empMyJobs.topupcoins"]}
        </Button>
        <Button
          variant="contained"
          color="extendJobButton"
          sx={{
            borderRadius: "0 0 25px 0",

            width: "50%",
            paddingY: "25px",
            fontSize: theme.typography.fontSize.sm,
            fontWeight: theme.typography.Bold,
            height: "60px",
            overflow: "hidden",
            boxShadow: 0,
            ":hover": {
              boxShadow: 0,
            },
          }}
          onClick={() => {
            if (daysRemaining === 0 && status === "expired") {
              setGetCoins(true);
              setJobExtend(true);
              setSelectedJob();
            }
          }}
        >
          {daysRemaining ? "extend job" : "renew job"}
        </Button>
      </Box>
    </Box>
  );
};

export default TrackButton;
