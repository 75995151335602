import React from "react";
import CustomDialog from "../../../common/CustomDialog";
import { Box, Button, Switch, Typography } from "@mui/material";
import styled from "@emotion/styled";
import { alpha } from "@material-ui/core";
import { ToggleSwitch } from "../../../../utils/CommonComponent";

export default function VerifyUser({
  show,
  handleOpen,
  handleCancel,
  handleVerify,
  confirmVerify,
  setConfirmVerify,
  dialogText,
}) {
  const handleConfirmVerify = (event) => {
    setConfirmVerify(event.target.checked);
  };
  return (
    <CustomDialog
      show={show}
      hideButton={false}
      dialogWidth="xs"
      showFooter={false}
      onDialogClose={handleOpen}
      padding={0}
    >
      <Box
        sx={{
          padding: 4,
        }}
      >
        <Typography
          variant="h5"
          fontWeight={"Bold"}
          textAlign={"center"}
          sx={{
            marginBottom: 3,
          }}
        >
          Sure you want to verify?
        </Typography>
        <Typography
          fontSize={16}
          fontWeight={"bold"}
          textAlign={"center"}
          paragraph
        >
          Please confirm that you want to verify this user {dialogText}
        </Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography>Click to confirm</Typography>
          <ToggleSwitch
            color="greenSwitch"
            checked={confirmVerify}
            onChange={(event) => handleConfirmVerify(event)}
          />
        </Box>
      </Box>
      <Box>
        <Button
          sx={{
            boxShadow: 0,
            fontSize: "14px",
            width: "50%",
            borderRadius: 0,
            height: "43px",
            // background: "lightgray",
            color: "black",
            padding: 3,
          }}
          color="closeButton"
          variant="contained"
          onClick={handleCancel}
        >
          cancel
        </Button>
        <Button
          sx={{
            boxShadow: 0,
            fontSize: "14px",
            width: "50%",
            borderRadius: 0,
            height: "43px",
            padding: 3,
          }}
          variant="contained"
          color="redButton100"
          onClick={handleVerify}
          disabled={!confirmVerify}
        >
          continue
        </Button>
      </Box>
    </CustomDialog>
  );
}
