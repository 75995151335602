import React, { memo, useCallback } from "react";
import DraggableCardNew, { MemoizedComponent } from "./DraggableCardNew";
import { useParams } from "react-router-dom";

const BoardColumn = memo(
  ({ list, talentStatus, droppableId, onDragEnd, jobDetail,setJobDetail }) => {
    const { jobId } = useParams();
    return list.items?.map((item, index) => {
      return (
        <MemoizedComponent
          userID={item?.user_id}
          itemData={item}
          index={index}
          droppableId={droppableId}
          onDragEnd={onDragEnd}
          jobId={jobId}
          talentStatus={talentStatus}
          key={item?.user_id}
          jobDetail={jobDetail}
          setJobDetail={setJobDetail}
          // getTalentMyJobStatusCount={
          //   getTalentMyJobStatusCount
          // }
        />
      );
    });
  }
);
export default BoardColumn;
