import { Box, Button, Typography, useTheme } from "@mui/material";
import React from "react";
import education_type from "../../../../assets/Padding Included/Crayon Icon_Education Type.svg";
import education_type_grey from "../../../../assets/Padding Included/Crayon Icon_Education Type Grey.svg";
import own_equipment from "../../../../assets/Padding Included/Crayon Icon_Own Equipment.svg";
import gray_own_equipment from "../../../../assets/Padding Included/gray_Crayon Icon_Own Equipment.svg";
import own_vehicle from "../../../../assets/Padding Included/Crayon Icon_Own Vehicle.svg";
import gray_own_vehicle from "../../../../assets/Padding Included/gray_Crayon Icon_Own Vehicle.svg";

import own_internet from "../../../../assets/Padding Included/Crayon Icon_Own Internet.svg";
import gray_own_internet from "../../../../assets/Padding Included/gray_Crayon Icon_Own Internet.svg";
import MUIRadialChart from "../../../common/MUIRadialChart";
import useViewport from "../../../common/ViewPort";

export default function JobInfoBox({ jobContent }) {
  const { width } = useViewport();
  const theme = useTheme();
  const label5 = "contract term";

  return (
    <>
      <Button
        variant="contained"
        color="jobInfo"
        sx={{
          width: "150px",
          height: "45px",
          borderRadius: "25px 0 25px 0",
          justifyContent: "flex-start",
          gap: "12px",
        }}
      >
        <Typography
          sx={{
            mt: "2px",
            cursor: "pointer",
            height: "17px",
            width: "17px",
            borderRadius: "5px",
            fontSize: "12px",
            lineHeight: 0.1,
            /* text-align: center; */
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            fontWeight: theme.typography.Bold,
            boxShadow: "0px 2px 4px #00000029",
            border: 1,
          }}
        >
          i
        </Typography>
        job info
      </Button>

      <Box
        padding={"20px"}
        sx={{
          display: "flex",
          flexWrap: "wrap",
        }}
      >
        <Box
          width={"33.33%"}
          display={"flex"}
          alignItems={"center"}
          flexDirection={"column"}
        >
          <Box
            component="img"
            sx={{
              height: 50,
              width: 50,
            }}
            alt="own_vehicle"
            src={jobContent?.own_transport ? own_vehicle : gray_own_vehicle}
          />
          <Typography
            sx={{
              fontSize: "12px",
              fontWeight: "medium",
            }}
            paragraph
          >
            own transport
          </Typography>
        </Box>

        <Box
          width={"33.33%"}
          display={"flex"}
          alignItems={"center"}
          flexDirection={"column"}
        >
          <Box
            component="img"
            sx={{
              height: 50,
              width: 50,
            }}
            alt="own_vehicle"
            src={jobContent?.own_transport ? own_vehicle : gray_own_vehicle}
          />
          <Typography
            sx={{
              fontSize: "12px",
              fontWeight: "medium",
            }}
            paragraph
          >
            driver's license
          </Typography>
        </Box>

        <Box
          width={"33.33%"}
          display={"flex"}
          alignItems={"center"}
          flexDirection={"column"}
        >
          <Box
            component="img"
            sx={{
              height: 50,
              width: 50,
            }}
            alt="own_vehicle"
            src={jobContent?.internet_access ? own_internet : gray_own_internet}
          />
          <Typography
            sx={{
              fontSize: "12px",
              fontWeight: "medium",
            }}
            paragraph
          >
            internet access
          </Typography>
        </Box>

        <Box
          width={"33.33%"}
          display={"flex"}
          alignItems={"center"}
          flexDirection={"column"}
        >
          <Box
            component="img"
            sx={{
              height: 50,
              width: 50,
            }}
            alt="own_vehicle"
            src={jobContent?.own_equipment ? own_equipment : gray_own_equipment}
          />
          <Typography
            sx={{
              fontSize: "12px",
              fontWeight: "medium",
            }}
            paragraph
          >
            own equipment
          </Typography>
        </Box>
        <Box
          width={"33.33%"}
          display={"flex"}
          flexDirection={"column"}
          alignItems={"center"}
        >
          <Box
            component="img"
            sx={{
              height: 50,
              width: 50,
            }}
            alt="education_type"
            src={
              jobContent?.highest_qualification?.description
                ? education_type
                : education_type_grey
            }
          />
          <Typography
            sx={{
              fontSize: "12px",
              fontWeight: "medium",
              textAlign: "center",
            }}
            paragraph
          >
            {jobContent?.highest_qualification?.description}
          </Typography>
        </Box>
        <Box
          sx={{
            width: "33.33%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <MUIRadialChart
            backgroundRingColor="darkGray"
            size={Number(`${width < 768 ? 80 : 100}`)}
            chartName={label5}
            color={"contractDuration"}
            value={jobContent?.contract_duration || 0}
            formattedValue={`${jobContent?.contract_duration || 0} months`}
            // countFontSize={14}
            // labelFontSize={12}
            countFontSize={Number(`${width < 768 ? 12 : 14}`)}
            labelFontSize={Number(`${width < 768 ? 10 : 12}`)}
            max={100}
          />
        </Box>
      </Box>
    </>
  );
}
