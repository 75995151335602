import {
  Box,
  Button,
  CircularProgress,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { getCandiateApplicationVideo } from "../../../../redux/admin/jobsSlice";
import { setAlert } from "../../../../redux/configSlice";
import { ALERT_TYPE, ERROR_MSG } from "../../../../utils/Constants";
import PlayButton from "../../../common/PlayButton";
import InfiniteScroll from "react-infinite-scroll-component";
import videoPlaceholder from "../../../../assets/VideoPlaceholder.png";
import { debounce } from "lodash";

export default function ApplicationVideoBox({
  userID,
  setViewVideoLink,
  setOpenReviewVideoDialog,
}) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const VideoRef = useRef(null);

  const [dataLoading, setDataLoading] = useState(false);
  const [disabledButton, setDisabledButton] = useState(true);
  const [isPlaying, setIsPlaying] = useState(false);
  const [videoList, setVideoList] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [lastKey, setLastKey] = useState(0);

  const debouncedHandleOnTextSearch = useCallback(
    debounce(
      async (newValue) => {
        if (!newValue) return; // Early return
        try {
          const response = await getCandidateVideoList(
            0,
            newValue?.target?.value
          );

          if (response && response?.payload) {
            const { payload } = response;
            dispatch(
              payload?.status === "success"
                ? setVideoList(payload?.data)
                : setAlert({
                    show: true,
                    type: ALERT_TYPE?.ERROR,
                    msg: ERROR_MSG,
                  })
            );
          }
        } catch (error) {
          dispatch(
            setAlert({ show: true, type: ALERT_TYPE?.ERROR, msg: ERROR_MSG })
          );
        }
      },
      500,
      setIsLoading
    ),
    [dispatch, setVideoList, setIsLoading]
  );

  const handleSearch = async (event) => {
    setVideoList([]);
    setSearchText(event?.target?.value);
    debouncedHandleOnTextSearch(event);
  };

  const getCandidateVideoList = async (lastkeyy, query) => {
    try {
      setDataLoading(true);
      const { payload } = await dispatch(
        getCandiateApplicationVideo({
          lastKey: lastkeyy,
          talent_id: userID,
          title: query,
        })
      );
      if (payload?.status === "success") {
        if (lastkeyy === "") {
          setVideoList(payload?.data);
          setLastKey(payload?.pageNumber + 1);
        } else {
          setVideoList((prevState) => [...prevState, ...payload?.data]);
          setLastKey(payload?.pageNumber + 1);
        }
        setDataLoading(false);
      } else {
        setDataLoading(false);
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE?.ERROR,
            msg: "Something went wrong! please relaod the window",
          })
        );
      }
    } catch (error) {
      setDataLoading(false);
      dispatch(
        setAlert({
          show: true,
          type: ALERT_TYPE?.ERROR,
          msg: "Something went wrong! please relaod the window",
        })
      );
    }
  };

  useEffect(() => {
    getCandidateVideoList(0, "");
  }, []);

  return (
    <>
      <Box
        sx={{
          padding: "0 16px 16px 0 !important",
        }}
      >
        <Button
          variant="contained"
          color="redButton"
          sx={{ borderRadius: "25px 0 25px 0", fontSize: "14px", gap: "5px" }}
        >
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: theme.typography.Bold,
            }}
          >
            Application video
          </Typography>
        </Button>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            overflowY: "auto",
            paddingLeft: "16px",
            height: "380px",
            "::-webkit-scrollbar": {
              height: "5px",
              background: "#DCDEEB",
              display: "unset !important",
            },
          }}
          id="matchTaleneList"
        >
          <InfiniteScroll
            style={{
              overflowX: "hidden",
              scrollbarWidth: "thin",
            }}
            dataLength={videoList?.length || 0}
            next={() => getCandidateVideoList(lastKey, searchText)}
            hasMore={true}
            scrollableTarget="matchTaleneList"
            scrollThreshold={"50px"}
          >
            {videoList?.length > 0 ? (
              videoList?.map((item, index) => {
                return (
                  <Box
                    sx={{
                      display: "flex",
                      mt: 2,
                      gap: "10px",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        position: "relative",
                        height: "40px",
                        width: "40px",
                      }}
                    >
                      {item?.job_cam_url !== "No URL" && !isPlaying ? (
                        <PlayButton buttonSize={20} triangleSize={10} />
                      ) : null}
                      {item?.job_cam_url !== "No URL" ? (
                        <video
                          autoPlay={false}
                          loops
                          preload="auto"
                          style={{
                            width: `40px`,
                            height: "40px",
                            borderRadius: "10px",
                            objectFit: "cover",
                            cursor: disabledButton ? "wait" : "pointer",
                          }}
                          controls={false}
                          ref={VideoRef}
                          onClick={() => {
                            setViewVideoLink(item?.job_cam_url);
                            setOpenReviewVideoDialog(true);
                          }}
                          onLoadedData={() => {
                            setDisabledButton(false);
                          }}
                          onEnded={() => setIsPlaying(false)}
                        >
                          <source src={item?.job_cam_url} type="video/mp4" />
                        </video>
                      ) : (
                        <Box
                          component={"img"}
                          src={videoPlaceholder}
                          sx={{
                            height: "40px",
                            width: "40px",
                          }}
                        />
                      )}
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        gap: "10px",
                        width: `calc(100% - 40px)`,
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "12px",
                          fontWeight: theme.typography.Bold,
                          wordBreak: "break-word",
                          cursor: "pointer",
                          width: `calc(100% - 20px)`,
                        }}
                        noWrap={true}
                        key={index}
                      >
                        {item?.title}
                      </Typography>
                    </Box>
                  </Box>
                );
              })
            ) : dataLoading ? (
              <Box
                style={{
                  margin: "15px 0 0 0",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CircularProgress color="inherit" size={20} />
              </Box>
            ) : (
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <Typography>No Job Videos</Typography>
              </Box>
            )}
          </InfiniteScroll>
        </Box>
      </Box>
    </>
  );
}
