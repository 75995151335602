import React, { useEffect, useRef, useState } from "react";
import { Box, Button } from "@mui/material";
import BlackMale from "../../assets/Padding Included/Black_Male.svg";
import BlueMale from "../../assets/Padding Included/Blue_Male.svg";
import BlackFemale from "../../assets/Padding Included/Black_Female.svg";
import PinkFemale from "../../assets/Padding Included/Pink_Female.svg";
import { useQuery } from "../../utils/Common";
import { useParams } from "react-router-dom";

const GenderSection = ({
  setGender,
  id,
  handleClosePopover,
  handleFilterSelection,
  setIsFilterApplied,
  getTalentMyJobStatusCount,
  setBasicData,
  onFilterSelection,
  genderData,
  setOpenPopovers,
  openPopovers,
  panelData,
  setSelectedBtn,
}) => {
  const boxRef = useRef();
  const [maleIcon, setMaleIcon] = useState(false);
  const [femaleIcon, setFemaleIcon] = useState(false);

  const query = useQuery();
  const { jobId } = useParams();
  const handleGender = async (value) => {
    onFilterSelection();
    if (value === "male") {
      setIsFilterApplied(true);
      setGender("male");
      setFemaleIcon(false);
    } else if (value === "female") {
      setIsFilterApplied(true);
      setGender("female");
      setMaleIcon(false);
    } else {
      setIsFilterApplied(true);
      setGender("");
      setMaleIcon(false);
      setFemaleIcon(false);
    }

    setBasicData((prevBasicData) => ({
      ...prevBasicData,
      genderType: value,
    }));

    handleFilterSelection("gender", value ? value : "gender");
    handleClosePopover(id);
    await getTalentMyJobStatusCount(jobId);
    // navigate(
    //   `/employer/manage-talent/${jobId}?gender=${value}&race=${
    //     query.get("race") === null ? "" : query.get("race")
    //   }&qualification=${
    //     query.get("qualification") === null ? "" : query.get("qualification")
    //   }&salary=${
    //     query.get("salary") === null ? "" : query.get("salary")
    //   }&experience=${
    //     query.get("experience") === null ? "" : query.get("experience")
    //   }`
    // );
  };

  useEffect(() => {
    if (query.get("gender") === "male" || genderData?.genderType === "male") {
      setMaleIcon(true);
    } else if (
      query.get("gender") === "female" ||
      genderData?.genderType === "female"
    ) {
      setFemaleIcon(true);
    }
  }, [query.get("gender"), genderData]);

  const handleClickOutside = (event) => {
    if (
      boxRef?.current &&
      !boxRef?.current?.contains(event?.target) &&
      !maleIcon &&
      !femaleIcon
    ) {
      const initialOpenState = {};
      panelData?.forEach((item) => {
        initialOpenState[item?.id] = false;
      });
      setOpenPopovers(initialOpenState);
      setSelectedBtn([1111]);
    }
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  return (
    <Box
      ref={boxRef}
      sx={{
        width: 150,
        paddingY: 2,
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Button
        variant="text"
        onMouseEnter={() => {
          if (!query.get("gender") || genderData?.genderType === "male") {
            setMaleIcon(true);
            setFemaleIcon(false);
          }
        }}
        onMouseLeave={() => {
          if (!query.get("gender") || query.get("gender") !== "male") {
            setMaleIcon(false);
          }
          if (genderData?.genderType === "male") {
            setMaleIcon(true);
          }
          if (genderData?.genderType !== "male") {
            setMaleIcon(false);
          }
        }}
        sx={{
          borderRadius: 0,
        }}
      >
        <Box
          component={"img"}
          height={50}
          width={50}
          src={maleIcon ? BlueMale : BlackMale}
          onClick={() => {
            handleGender(query.get("gender") === "male" ? "" : "male");
          }}
        />
      </Button>
      <Button
        variant="text"
        onMouseEnter={() => {
          if (!query.get("gender") || genderData?.genderType === "female") {
            setFemaleIcon(true);
            setMaleIcon(false);
          }
        }}
        onMouseLeave={() => {
          if (!query.get("gender") || query.get("gender") !== "female") {
            setFemaleIcon(false);
          }
          if (genderData?.genderType === "female") {
            setFemaleIcon(true);
          }
          if (genderData?.genderType !== "female") {
            setMaleIcon(true);
          }
        }}
        sx={{
          borderRadius: 0,
        }}
      >
        <Box
          component={"img"}
          height={50}
          width={50}
          src={femaleIcon ? PinkFemale : BlackFemale}
          onClick={() => {
            handleGender(query.get("gender") === "female" ? "" : "female");
          }}
        />
      </Button>
    </Box>
  );
};

export default GenderSection;
