import { Box, Button, IconButton, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import locale from "../../../i18n/locale";
import { ALERT_TYPE } from "../../../utils/Constants";
import { useTheme } from "@emotion/react";
import alert from "../../../assets/Padding Excluded/White_Alert.svg";
import { ToggleSwitch } from "../../../utils/CommonComponent";
import CircularSlider from "@fseehawer/react-circular-slider";
import {
  getAlertSettings,
  saveAlertSetting,
} from "../../../redux/candidate/alerts";
import { useDispatch } from "react-redux";
import { setAlert } from "../../../redux/configSlice";
import CropSquareIcon from "@mui/icons-material/CropSquare";
import settings from "../../../assets/Padding Excluded/White_Settings.svg";
import informationIcon from "../../../assets/Padding Excluded/InformationIcon.svg";
import CloseIcon from "../../common/CloseIcon";

export default function Settings({
  width,
  rightExpand,
  handleClose,
  setOpenSetting,
  settingRef,
  setOpenSpoiler,
}) {
  const i18n = locale.en;
  const theme = useTheme();
  const dispatch = useDispatch();
  const [alertData, setAlertData] = useState({
    toggle_all_alert: false,
    toggle_new_job_alert: false,
    toggle_job_stage_alert: false,
    match_percentage: "",
  });
  const [matchValue, setMatchValue] = useState(50);
  const [isLoading, setIsLoading] = useState(false);

  const handleChangeSetup = (event, name) => {
    const newProfileData = {
      ...alertData,
      [name]: event?.target?.checked,
    };
    setAlertData(newProfileData);
  };

  const getAlertsSetting = async (lastkeyy) => {
    setIsLoading(true);
    try {
      const { payload } = await dispatch(getAlertSettings());
      if (payload?.status === "success") {
        setAlertData({
          toggle_all_alert: payload?.data?.toggle_all_alert,
          toggle_new_job_alert: payload?.data?.toggle_new_job_alert,
          toggle_job_stage_alert: payload?.data?.toggle_job_stage_alert,
          match_percentage: payload?.data?.match_percentage,
        });
        setMatchValue(payload?.data?.match_percentage);
      } else {
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE?.ERROR,
            msg: payload?.message,
          })
        );
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      dispatch(
        setAlert({
          show: true,
          type: ALERT_TYPE?.ERROR,
          msg: "Error",
        })
      );
    }
  };

  const saveAlertsSetting = async (lastkeyy) => {
    try {
      let data = {
        toggle_all_alert: alertData?.toggle_all_alert,
        toggle_new_job_alert: alertData?.toggle_new_job_alert,
        toggle_job_stage_alert: alertData?.toggle_job_stage_alert,
        match_percentage: alertData?.match_percentage,
      };
      const { payload } = await dispatch(saveAlertSetting(data));
      if (payload?.status === "success") {
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE?.SUCCESS,
            msg: "Alert updated successfully",
          })
        );
        setOpenSpoiler(true);
      } else {
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE?.ERROR,
            msg: payload?.message,
          })
        );
      }
    } catch (error) {
      dispatch(
        setAlert({
          show: true,
          type: ALERT_TYPE?.ERROR,
          msg: "Error",
        })
      );
    }
  };

  const handleMatch = (val) => {
    setMatchValue(Math.max(Math.min(val, 100), 0)); // Clamp value between min and max
    setAlertData({
      ...alertData,
      match_percentage: val,
    });
  };

  useEffect(() => {
    getAlertsSetting();
  }, []);

  return (
    <>
      <Box
        ref={settingRef}
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          // width: `${width}px`,
          width: width,
          // height: "auto",
          height: "438px",
          background: "#ffff",
          borderRadius: "0 0 25px 25px",
          boxShadow: "0px 5px 10px #00000029 !important",
          zIndex: 1000,
          position: "absolute",
          // padding: "0px 0px 0 20px",
          right: 0,
          boxSizing: "border-box",
          marginRight: !rightExpand && "119px",
        }}
      >
        <Button
          variant="contained"
          color="recentButton"
          startIcon={
            <Box
              component="img"
              className="eye"
              alt="eye logo"
              src={settings}
              sx={{
                height: 18,
                width: 18,
              }}
            />
          }
          sx={{
            width: "150px",
            minWidth: "150px",
            maxWidth: "150px",
            height: "45px",
            borderRadius: "0px 0px 10px 0px",
            fontSize: "14px",
            fontWeight: theme.typography.Bold,
            textAlign: "left",
            justifyContent: "flex-start",
          }}
        >
          {i18n["alert.alertsetting"]}
        </Button>
        <IconButton
          aria-label="close"
          onClick={() => handleClose()}
          sx={{
            position: "absolute",
            right: 8,
            //   color: (theme) => theme.palette.grey[500],
          }}
          disableRipple={true}
        >
          {/* <Box
            sx={{
              width: "20px",
              height: "20px",
              color: "#000000",
              border: 2,
              fontSize: "18px",
              borderRadius: "5px",
            }}
          >
            X
          </Box> */}
          <CloseIcon />
        </IconButton>

        <Box sx={{ padding: "0 48px 0 18px" }}>
          <Box
            sx={{
              display: "flex",
              width: "330px",
              alignItems: "center",
              justifyContent: "space-between",
              height: "25px",
            }}
          >
            <Typography
              sx={{ fontSize: "14px", fontWeight: theme.typography.Bold }}
            >
              {i18n["alert.toggleallalerts"]}
            </Typography>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <ToggleSwitch
                color="recentButton"
                onChange={(event) =>
                  handleChangeSetup(event, "toggle_all_alert")
                }
                checked={alertData?.toggle_all_alert}
              />
              <Box
                sx={{ width: "16px", height: "16px" }}
                src={informationIcon}
                component={"img"}
              ></Box>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              width: "330px",
              alignItems: "center",
              justifyContent: "space-between",
              height: "25px",
            }}
          >
            <Typography
              sx={{ fontSize: "14px", fontWeight: theme.typography.Regular }}
            >
              {i18n["alert.togglenewjobalerts"]}
            </Typography>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <ToggleSwitch
                color="recentButton"
                onChange={(event) =>
                  handleChangeSetup(event, "toggle_new_job_alert")
                }
                checked={alertData?.toggle_new_job_alert}
              />
              <Box
                sx={{ width: "16px", height: "16px" }}
                src={informationIcon}
                component={"img"}
              ></Box>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              width: "330px",
              alignItems: "center",
              justifyContent: "space-between",
              height: "25px",
            }}
          >
            <Typography
              sx={{ fontSize: "14px", fontWeight: theme.typography.Regular }}
            >
              {i18n["alert.togglechangedjobstagealerts"]}
            </Typography>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <ToggleSwitch
                color="recentButton"
                onChange={(event) =>
                  handleChangeSetup(event, "toggle_job_stage_alert")
                }
                checked={alertData?.toggle_job_stage_alert}
              />
              <Box
                sx={{ width: "16px", height: "16px"}}
                src={informationIcon}
                component={"img"}
              ></Box>
            </Box>
          </Box>
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: theme.typography.Regular,
              textAlign: "left",
              // width: "320px",
              marginTop: "8px",
            }}
          >
            {/* {i18n["alert.selectyourmatch"]} */}
            Select your minimum match % to receive new job alerts (minimum and
            default is 50%):
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "space-between",
              gap: "10px",
              marginTop: "10px",
              marginBottom: "10px",
            }}
          >
            <CircularSlider
              // className="circle"
              width={120}
              label="match"
              min={0}
              max={100}
              dataIndex={matchValue}
              continuous={{ enabled: false }}
              labelColor="black"
              labelBottom={true}
              knobColor="white"
              knobSize={32}
              // hideKnob={true}
              labelFontSize="14px"
              appendToValue="%"
              valueFontSize="20px"
              progressColorFrom={`${theme.palette.recruiterButton.main}`}
              progressColorTo={`${theme.palette.recruiterButton.main}`}
              progressSize={8}
              trackColor={`${theme.palette.submitButton.main}`}
              trackSize={8}
              onChange={(value) => {
                const newValue = Math.max(value, 0); // Clamp value to minimum
                handleMatch(newValue);
              }}
            >
              {/* <CropSquareIcon style={{ borderRadius: "6px", color: "white" }} /> */}
              <Box sx={{ borderRadius: "6px", backgroundColor: "white" }}>
                <CropSquareIcon style={{ color: "black", fontSize: 32 }} />
              </Box>
            </CircularSlider>

            <Typography
              sx={{
                fontSize: "14px",
                fontWeight: theme.typography.Regular,
                textAlign: "center",
              }}
            >
              {/* {i18n["alert.toqualifyfornewjob"]} */}
              To qualify for new job alerts, your profile must be deemed
              ‘active’ - i.e. logged in within the past 3 months.
            </Typography>
          </Box>
        </Box>
        <Box>
          <Button
            //   onClick={() => setOpenSetting(true)}
            variant="contained"
            color="employerButton"
            sx={{
              width: "50%",
              height: "60px",
              minHeight: "60px",
              maxHeight: "60px",
              borderRadius: "0 0 0 25px",
              fontSize: "14px",
              fontWeight: theme.typography.Bold,
            }}
            startIcon={
              <Box
                component="img"
                className="eye"
                alt="eye logo"
                src={alert}
                sx={{
                  height: 16,
                  width: 16,
                }}
              />
            }
          >
            {i18n["alert.buildnewalert"]}
          </Button>
          <Button
            variant="contained"
            color="deleteVideoButton"
            sx={{
              width: "50%",
              height: "60px",
              minHeight: "60px",
              maxHeight: "60px",
              borderRadius: "0 0 25px 0",
              fontSize: "14px",
              fontWeight: theme.typography.Bold,
            }}
            onClick={() => {
              saveAlertsSetting();
            }}
          >
            {i18n["alert.save"]}
          </Button>
        </Box>
      </Box>
    </>
  );
}
