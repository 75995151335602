import { useTheme } from "@emotion/react";
import { Box, Typography } from "@mui/material";
import React from "react";
import challenger from "../assets/Personality Logos/crayon_personality_grey_challeneger.svg";
import character from "../assets/Personality Logos/crayon_personality_character.svg";
import collaborator from "../assets/Personality Logos/crayon_personality_collaborator.svg";
import contemplator from "../assets/Personality Logos/crayon_personality_contemplator.svg";
const CultureFooterComponent = () => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        overflowY: "hidden",
        // overflowY: "auto",
        gap: "30px",
        overFlowX: "auto",
        display: "grid",
        paddingLeft: "20px",
        paddingRight: "40px",
        gridTemplateColumns: "repeat(7, minmax(360px, 360px))",
      }}
      height="100%"
    >
      <Box sx={{boxSizing:"border-box", paddingTop:"10px", height:"100%"}}>
        <Typography
          sx={{
            fontSize: "16px",
            fontWeight: theme.typography.Bold,
            marginBottom: "5px",
            lineHeight:"19px",
            letterSpacing:"0px",
          }}
        >
          Culture eats strategy for breakfast
        </Typography>
        <Typography
          sx={{
            fontSize: "14px",
            fontWeight: theme.typography.Bold,
            marginBottom: "15px",
            lineHeight:"19px",
            letterSpacing:"0px",
          }}
        >
          Culture is the sum of the parts{" "}
        </Typography>
        <Typography
          sx={{
            fontSize: "14px",
            fontWeight: theme.typography.Regular,
            marginBottom: "15px",
            lineHeight:"19px",
            letterSpacing:"0px",
          }}
        >
          Culture is the sum of the parts Skills and capabilities will always
          come first when it comes to matching great talent with opportunities.
          However, if they don’t fit, they’ll most likely quit.
        </Typography>
        <Typography
          sx={{
            fontSize: "14px",
            fontWeight: theme.typography.Regular,
            marginBottom: "15px",
            lineHeight:"19px",
            letterSpacing:"0px",
          }}
        >
          We believe in ‘culture add’ over ‘culture fit’; each individual
          joining a team adds to that culture, not just fits in. To align
          business culture with incoming talent, we make use of a tailored
          personality quiz that gives both the candidate and the employer a
          glimpse into the character of their potential next hire.
        </Typography>
        <Typography
          sx={{
            fontSize: "14px",
            fontWeight: theme.typography.Regular,
            marginBottom: "15px",
            lineHeight:"19px",
            letterSpacing:"0px",
          }}
        >
          Companies and candidates also get to indicate their perceived and
          preferred culture, all of which is taken into account when running the
          match algorithm. Add to that employer and candidate videos, and
          job-specific explainer and application videos, who needs CVs?!
        </Typography>
      </Box>
      <Box sx={{boxSizing:"border-box", height:"100%"}}>
        <Typography
          sx={{
            fontSize: "16px",
            fontWeight: theme.typography.Bold,
            height: "27px",
            marginTop:"10px",
            lineHeight:"19px",
            letterSpacing:"0px",
          }}
        >
          The Personalities
        </Typography>
        <Box sx={{width:"100%",textAlign:"center"}}>
        <Box
          component={"img"}
          sx={{
            width: "150px",
            height: "150px",
            marginBottom: "10px",
          
          }}
          src={challenger}
        ></Box>
        </Box>
        <Box
          sx={{
            fontSize: "14px",
            fontWeight: theme.typography.Bold,
            marginBottom: "10px",
            lineHeight:"19px",
            letterSpacing:"0px",
          }}
        >
          The Challenger
        </Box>
        <Typography
          sx={{
            fontSize: "14px",
            fontWeight: theme.typography.Regular,
            lineHeight:"19px",
            letterSpacing:"0px",
          }}
        >
          The Challenger The Challenger is your typical A-type individual who
          likes to be in charge of their own destiny, break down barriers and
          forge new paths. They are very goal-oriented and don’t like having
          restraints placed on them. However, the Challenger can be very direct
          and to the point, and come across as impatient and intolerant. They
          don’t like routine and will generally pushback when things become
          boring and mundane. Challengers make for potentially great
          entrepreneurs as they will do almost anything to achieve their desired
          outcomes, work well under pressure and can handle high stress
          situations. Just don’t expect them to recognise co-workers’ feelings
          and acknowledge minor achievements while the ultimate task remains at
          hand.
        </Typography>
      </Box>
      <Box sx={{boxSizing:"border-box", height:"100%"}}>
        <Typography
          sx={{
            fontSize: "16px",
            fontWeight: theme.typography.Bold,
            height: "37px",
          }}
        ></Typography>
        <Box sx={{width:"100%",textAlign:"center"}}>
        <Box
          component={"img"}
          sx={{
            width: "150px",
            height: "150px",
            marginBottom: "10px",
            textAlign: "center",
          }}
          src={character}
        ></Box>
</Box>
      
        <Box
          sx={{
            fontSize: "14px",
            fontWeight: theme.typography.Bold,
            marginBottom: "10px",
            lineHeight:"19px",
            letterSpacing:"0px",
          }}
        >
          The Character
        </Box>
        <Typography
          sx={{
            fontSize: "14px",
            fontWeight: theme.typography.Regular,
            lineHeight:"19px",
            letterSpacing:"0px",
          }}
        >
          The Challenger is the life of the (office) party, very outgoing,
          energetic and fast-paced. They love being around people and the centre
          of attention, while their overarching need is to be liked and
          recognised. The Character is who you go to if you’re looking for
          enthusiasm, an outgoing nature, and the need to persuade others,
          internally or externally. They can however be somewhat emotional and
          reactive, sometimes impatient and prefer social interaction over
          attention to detail. But, if you’re looking for a ‘dreamer’ than can
          turn ideas into reality, spontaneity, quick wit and humour, the
          Character is who you need on your team.
        </Typography>
      </Box>
      <Box sx={{boxSizing:"border-box", height:"100%"}}>
        <Typography
          sx={{
            fontSize: "16px",
            fontWeight: theme.typography.Bold,
            height: "37px",
          }}
        ></Typography>
        <Box sx={{width:"100%", textAlign:"center"}}>
        <Box
          component={"img"}
          sx={{
            width: "150px",
            height: "150px",
            marginBottom: "10px",
            textAlign: "center",
          }}
          src={contemplator}
        ></Box>
        </Box>
        
        <Box
          sx={{
            fontSize: "14px",
            fontWeight: theme.typography.Bold,
            marginBottom: "10px",
            lineHeight:"19px",
            letterSpacing:"0px",
          }}
        >
          The Contemplator
        </Box>
        <Typography
          sx={{
            fontSize: "14px",
            fontWeight: theme.typography.Regular,
            lineHeight:"19px",
            letterSpacing:"0px",
          }}
        >
          The Contemplator is a detail-oriented thinker. They like to operate in
          a stable environment and prefer accuracy, rationality and logic over a
          hyped go-with-the-flow approach. They strive for the facts and as a
          result are very outcome driven, love processes and protocols and tend
          to consider all angles before making a measured decision. The
          Contemplator however may come across as emotionless and is not one for
          decisive action. They will battle in a chaotic environment as they
          prefer their roles and responsibilities to be clearly defined and to
          know exactly what is expected of them. But, if allowed to operate
          within a well-defined playing field, the Contemplator will execute
          meticulously.
        </Typography>
      </Box>
      <Box sx={{boxSizing:"border-box", height:"100%"}}>
        <Typography
          sx={{
            fontSize: "16px",
            fontWeight: theme.typography.Bold,
            height: "37px",
          }}
        ></Typography>
        <Box sx={{width:"100%", textAlign:"center"}}>
        <Box
          component={"img"}
          sx={{
            width: "150px",
            height: "150px",
            marginBottom: "10px",
            textAlign: "center",
          }}
          src={collaborator}
        ></Box>
        </Box>
        <Box
          sx={{
            fontSize: "14px",
            fontWeight: theme.typography.Bold,
            marginBottom: "10px",
            lineHeight:"19px",
            letterSpacing:"0px",
          }}
        >
          The Collaborator
        </Box>
        <Typography
          sx={{
            fontSize: "14px",
            fontWeight: theme.typography.Regular,
            lineHeight:"19px",
            letterSpacing:"0px",
          }}
        >
          The Collaborator gets sh*t done. Making up nearly 70% of the
          population, these are the doers. They seek security and longevity and
          are happy to work hard provided they feel appreciated and receive the
          necessary reassurance along the way. They are typically organised,
          caring and compassionate and work well together in a team environment,
          both with other Collaborators and the other personalities. The
          Collaborator however requires stability and is adverse to constant
          change and risk taking. Every team requires a good mix of
          Collaborators; pick them wisely and you’ll have a team of excellent
          executors!
        </Typography>
      </Box>
      <Box sx={{boxSizing:"border-box",paddingLeft:"20px",paddingTop:"10px", height:"100%"}}>
        <Typography
          sx={{
            fontSize: "16px",
            fontWeight: theme.typography.Bold,
            height: "27px",
            marginBottom: "5px",
            lineHeight:"19px",
            letterSpacing:"0px",
          }}
        >
          Grit Score
        </Typography>
        <Box
          sx={{
            fontSize: "14px",
            fontWeight: theme.typography.Bold,
            marginBottom: "10px",
            lineHeight:"19px",
            letterSpacing:"0px",
          }}
        >
          A measure of perseverance and tenacity
        </Box>
        <Typography
          sx={{
            fontSize: "14px",
            fontWeight: theme.typography.Regular,
            marginBottom: "5px",
            lineHeight:"19px",
            letterSpacing:"0px",
          }}
        >
          Crayon combines 11 of your trait scores to determine what we call a
          Grit Score; a measure of your ability to bounce back from adversity,
          your doggedness and overall conscientiousness. There is no good or bad
          score and a high number is not necessarily better than a low number.
          The concept has previously been pioneered by the likes of Angela
          Duckworth and Paul G. Stolz, founder of the Grit Institute. He defines
          grit as your capacity to dig deep, do whatever it takes — especially
          suffer, sacrifice, struggle, and stumble — in order to achieve your
          most worthy goals. However, he also warns against bad grit in the
          context of entrepreneurship where extreme grit negatively impacts
          those around you. We’ll let Angela take it from here:
        </Typography>
      </Box>
      <Box sx={{boxSizing:"border-box",paddingLeft:"20px",paddingTop:"10px", height:"100%"}}>
        <Typography
          sx={{
            fontSize: "16px",
            fontWeight: theme.typography.Bold,
            height: "27px",
            marginBottom: "5px",
            lineHeight:"19px",
            letterSpacing:"0px",
          }}
        >
          Traits & Culture
        </Typography>
        <Box
          sx={{
            fontSize: "14px",
            fontWeight: theme.typography.Bold,
            marginBottom: "5px",
            lineHeight:"19px",
            letterSpacing:"0px",
          }}
        >
          Traits
        </Box>
        <Box
          sx={{
            fontSize: "14px",
            fontWeight: theme.typography.Regular,
            marginBottom: "10px",
            lineHeight:"19px",
            letterSpacing:"0px",
          }}
        >
          As part of the personality quiz, we’ll assign to your profile your top
          5 positive traits (because who wants to hear bad news). Your
          personality traits are measured across 20 different trait scales with
          a score and confidence rating assigned to each. When jobs are posted,
          employers are able to select the preferred traits that best match the
          company culture and job at hand.
        </Box>
        <Box
          sx={{
            fontSize: "14px",
            fontWeight: theme.typography.Bold,
            marginBottom: "5px",
            lineHeight:"19px",
            letterSpacing:"0px",
          }}
        >
          Company Culture
        </Box>
        <Typography
          sx={{
            fontSize: "14px",
            fontWeight: theme.typography.Regular,
            lineHeight:"19px",
            letterSpacing:"0px",
          }}
        >
          Wouldn’t it be great to know the company culture before applying? And
          to align your preferred culture from the get-go? Well now you can. We
          give businesses the opportunity to share the culture they are striving
          for, and you the chance to showcase the kind of culture you’d like to
          join.
        </Typography>
      </Box>
    </Box>
  );
};

export default CultureFooterComponent;
