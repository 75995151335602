import React, { useEffect, useState } from "react";
import ReactCardFlip from "react-card-flip";
import ReviewVideoDialog from "../../common/ReviewVideoDialog";
import ReferralsCardFront from "./ReferralsCardFront";
import ReferralsCardBack from "./ReferralsCardBack";
import { useDispatch } from "react-redux";
import { getMyCamVideo } from "../../../redux/candidate/candidateJobs";
import { setAlert } from "../../../redux/configSlice";
import { ALERT_TYPE } from "../../../utils/Constants";

export default function ReferralsCard({
  index,
  job,
  getJobs,
  cardWidth,
  jobslength,
  currentPageJobsCount,
}) {
  const dispatch = useDispatch();
  const [isHorizontalFlipped, setisHorizontalFlipped] = useState(false);
  const [openReviewVideoDialog, setOpenReviewVideoDialog] = useState(false);
  const [videoLink, setVideoLink] = useState("");

  const handleCloseDialog = () => {
    setVideoLink("");
    setOpenReviewVideoDialog(false);
  };

  useEffect(() => {
    const fetchVideoLink = async () => {
      const { payload } = await dispatch(
        getMyCamVideo({ user_id: job?.user_id })
      );
      if (payload?.status === "success") {
        setVideoLink(payload?.data?.cam_url);
      } else {
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE?.ERROR,
            msg: "No video",
          })
        );
      }
    };

    if (openReviewVideoDialog) {
      fetchVideoLink();
    }
  }, [dispatch, job?.user_id, openReviewVideoDialog]);

  return (
    <>
      <ReactCardFlip
        isFlipped={isHorizontalFlipped}
        flipDirection={"horizontal"}
        flipSpeedBackToFront="0.5"
        flipSpeedFrontToBack="0.5"
        key={job?.job_id}
      >
        <ReferralsCardFront
          index={job.job_id}
          job={job}
          setisFlipped={setisHorizontalFlipped}
          handleReviewVideoDialog={setOpenReviewVideoDialog}
          cardWidth={cardWidth}
          jobslength={jobslength}
          indexNumber={index}
          currentPageJobsCount={currentPageJobsCount}
        />
        <ReferralsCardBack
          index={job.job_id}
          cardWidth={cardWidth}
          job={job}
          isFlipped={isHorizontalFlipped}
          setisFlipped={setisHorizontalFlipped}
          handleReviewVideoDialog={setOpenReviewVideoDialog}
        />
      </ReactCardFlip>
      {openReviewVideoDialog && (
        <ReviewVideoDialog
          videoLink={videoLink}
          open={openReviewVideoDialog}
          setOpen={handleCloseDialog}
        />
      )}
    </>
  );
}
