import {
  Box,
  Button,
  CircularProgress,
  InputBase,
  Paper,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useCallback, useEffect, useRef, useState } from "react";
import Ozow from "../../../../assets/Crayon_Favicon.svg";
import InfiniteScroll from "react-infinite-scroll-component";
import { CheckCircle } from "@mui/icons-material";
import TalentSVGButton from "../../../common/TalentSVGButton";
import cancel from "../../../../assets/CircularIcon/Red/Circular Icons__Red_Close_2.svg";
import locale from "../../../../i18n/locale";
import { useDispatch } from "react-redux";
import { setAlert } from "../../../../redux/configSlice";
import {
  addTalentPool,
  setCandidateID,
  setCreatePool,
} from "../../../../redux/admin/jobsSlice";
import { ALERT_TYPE, ERROR_MSG } from "../../../../utils/Constants";
import { useNavigate } from "react-router-dom";
import linkToPool from "../../../../assets/Black_Add_To_Pool.svg";
import { debounce } from "../../../../utils/Common";

export default function AddToPool({
  talentData,
  userID,
  getTalent,
  lastKey,
  setLastKey,
  totalPoolData,
  removeFromPool,
  setTableData,
  poolIDRef,
  setButtonDisable,
  addToPool,
  setAnchorEl,
  apiPayload,
  addTopBtn,
  talentDetail,
  isSearch,
  searchText,
  setSearchText,
  setTalentData,
  buttonDisable,
}) {
  const i18n = locale.en;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const poolIDRef = useRef(null);
  const addToPoolRef = useRef(false);
  // const [buttonDisable, setButtonDisable] = useState(false);
  const theme = useTheme();
  const [isLoading, setIsLoading] = useState(false);

  const handleClick = () => {
    if (apiPayload) {
      navigate("/admin/admin-talent/talent-pool", {
        state: {
          payload: apiPayload,
        },
      });
    } else {
      navigate("/admin/admin-talent/talent-pool");
    }
    dispatch(setCreatePool(true));
    dispatch(setCandidateID(userID));
  };

  const debouncedHandleOnTextSearch = useCallback(
    debounce(
      async (newValue) => {
        if (!newValue) return; // Early return
        try {
          setLastKey(0);
          setTalentData([]);
          const response = await getTalent(0, newValue?.target?.value);
          if (response && response?.payload) {
            const { payload } = response;
            dispatch(
              payload?.status === "success"
                ? setTalentData(payload?.data)
                : setAlert({
                    show: true,
                    type: ALERT_TYPE?.ERROR,
                    msg: ERROR_MSG,
                  })
            );
          }
        } catch (error) {
          dispatch(
            setAlert({ show: true, type: ALERT_TYPE?.ERROR, msg: ERROR_MSG })
          );
        }
      },
      500, // Adjust delay as needed
      setIsLoading
    ),
    [dispatch, setTalentData, setIsLoading]
  );

  const handleSearch = async (event) => {
    setLastKey(0);
    setSearchText(event?.target?.value);
    setTalentData([]);
    debouncedHandleOnTextSearch(event);
  };

  const handleCancel = (removePool) => {
    setButtonDisable(true);
    setTableData(
      talentData?.map((item) =>
        item?.pool_id === removePool?.pool_id
          ? {
              ...item,
              poolUserFlag: false,
              addToPoolFlag: false,
              removePoolFlag: item?.poolUserFlag ? true : false,
            }
          : item
      )
    );
  };

  return (
    <>
      <Box
        sx={{
          padding: addTopBtn ? "0 16px 16px 0 !important" : "16px !important",
        }}
      >
        {addTopBtn ? (
          <Box sx={{ marginBottom: "10px" }}>
            <Button
              variant="contained"
              color="recordVideoButton"
              sx={{
                borderRadius: "25px 0 25px 0",
                fontSize: "14px",
                gap: "5px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "8px",
                }}
              >
                <Box
                  component={"img"}
                  src={linkToPool}
                  sx={{ width: 20, height: 20 }}
                />
              </Box>
              <Typography
                sx={{
                  fontSize: "14px",
                  fontWeight: theme.typography.Bold,
                }}
              >
                add to poll
              </Typography>
            </Button>
          </Box>
        ) : (
          <Typography
            sx={{
              fontSize: "12px",
              fontWeight: 700,
            }}
          >
            Add to pool
          </Typography>
        )}
        <Typography
          marginLeft={addTopBtn ? "16px" : "0px"}
          sx={{ fontSize: "11px", fontWeight: 700, mt: 1 }}
        >
          {talentDetail ? (
            "Select or search for an existing pool(s) you’d like to add this candidate to, or create a new pool. You can also remove this candidate from existing pools."
          ) : (
            <>
              Select the talent you want to add to an existing pool or
              <br />
              create a new pool and add.
            </>
          )}
        </Typography>
        {isSearch && (
          <Paper
            elevation={0}
            component="form"
            sx={{
              padding: "0px 10px",
              border: "1px solid #C9CAD8",
              margin: "13px 0 17px 0",
              marginLeft: "16px",
            }}
            height={"40px"}
            width={"392px"}
          >
            <InputBase
              sx={{
                fontWeight: theme.typography.Regular,
                width: "308px",
              }}
              placeholder={"Search for talent pool"}
              // value={searchText}
              onKeyDown={(event) => {
                if (event.key === "Enter") {
                  event.preventDefault(); // Stop page reload
                }
              }}
              onChange={(event) => {
                handleSearch(event);
              }}
            />
          </Paper>
        )}
        <Box
          marginLeft={addTopBtn ? "16px" : "0px"}
          sx={{
            display: "flex",
            flexDirection: "column",
            overflowY: "auto",
            height: "380px",
            "::-webkit-scrollbar": {
              height: "5px",
              background: "#DCDEEB",
              display: "unset !important",
            },
          }}
          id="matchTaleneList"
        >
          <InfiniteScroll
            style={{
              overflowX: "hidden",
              scrollbarWidth: "thin",
            }}
            dataLength={talentData?.length || 0}
            next={() => getTalent(lastKey)}
            hasMore={talentData?.length < totalPoolData ? true : false}
            loader={
              <Box
                style={{
                  margin: "15px 0 0 0",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CircularProgress color="inherit" size={20} />
              </Box>
            }
            scrollableTarget="matchTaleneList"
            scrollThreshold={"50px"}
          >
            {talentData?.length > 0 ? (
              talentData?.map((item, index) => {
                return (
                  <Box sx={{ display: "flex", mt: 2 }}>
                    <Box
                      component="img"
                      className="profileAvatar"
                      alt="crayon logo"
                      src={
                        item?.profile_url !== "No URL"
                          ? item?.profile_url
                          : Ozow
                      }
                      sx={{
                        mr: 1,
                        height: "35px !important",
                        width: "35px !important",
                        borderRadius: "5px",
                      }}
                    />
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "12px",
                          fontWeight: 700,
                          cursor: "pointer",
                          ml: "5px",
                        }}
                        key={index}
                        onClick={() => {
                          if (!item?.addToPoolFlag) {
                            setButtonDisable(true);
                            poolIDRef.current = item?.pool_id;
                            setTableData((prev) =>
                              prev?.map((existingItem) =>
                                existingItem?.pool_id === item?.pool_id
                                  ? {
                                      ...existingItem,
                                      addToPoolFlag: true,
                                      removePoolFlag: false,
                                      // poolUserFlag: true,
                                    }
                                  : existingItem
                              )
                            );
                          }
                        }}
                      >
                        {item?.title}
                      </Typography>
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        {item?.poolUserFlag && !item?.addToPoolFlag && (
                          <TalentSVGButton
                            padding={"0px !important"}
                            color={"white"}
                            source={cancel}
                            height={28}
                            width={23}
                            onClick={() => handleCancel(item)}
                          />
                        )}
                        {item?.addToPoolFlag && (
                          <>
                            <CheckCircle
                              sx={{
                                fontSize: 27,
                                cursor: "pointer",
                              }}
                              color="checkColor"
                            />
                            <TalentSVGButton
                              padding={"0px !important"}
                              color={"white"}
                              source={cancel}
                              height={28}
                              width={23}
                              onClick={() => handleCancel(item)}
                            />
                          </>
                        )}
                      </Box>
                    </Box>
                  </Box>
                );
              })
            ) : (
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <Typography>No Pools</Typography>
              </Box>
            )}
          </InfiniteScroll>
        </Box>
      </Box>
      <Box>
        <Button
          variant="contained"
          sx={{
            width: "50%",
            height: "60px",
            borderRadius: "0 0 0 25px",
            color: "black",
          }}
          color={"closeButton"}
          onClick={() => handleClick()}
        >
          {i18n["popupBoxText.createandadd"]}
        </Button>
        <Button
          variant="contained"
          sx={{
            width: "50%",
            height: "60px",
            borderRadius: "0 0 25px 0px",
          }}
          color={"redButton100"}
          onClick={() => {
            buttonDisable && addToPool(userID, poolIDRef?.current, true);
          }}
        >
          {i18n["popupBoxText.addtopool"]}
        </Button>
      </Box>
    </>
  );
}
