import React, { useEffect, useRef, useState } from "react";
import { Box } from "@mui/material";
import SmallButton from "./SmallButton";
import { nanoid } from "@reduxjs/toolkit";
import SmallButtonSlider from "./SmallButtonSlider";

const Slider2 = ({
  items,
  color,
  type,
  flexwrap = "nowrap",
  gap = 0,
  slidertype,
  margin = "5px",
  fontWeight,
  ...props
}) => {
  const boxRef = useRef(null);
  const [buttonWidth, setButtonWidth] = useState([]);

  useEffect(() => {
    if (items?.length > 0) {
      if (type) {
        if(type === "labelValue"){
          const dataList = items?.map((item)=>({name:item?.label,width:0}))
        setButtonWidth([...dataList]);

        }
        if(type === "culture"){
          const jobIndustries = items
          ?.map((industry) => industry?.company_culture?.name)
          ?.map((name) => ({
            name,
            width: 0,
          }));
        setButtonWidth([...jobIndustries]);
        }else{
          const jobIndustries = items
          ?.map((industry) => industry?.industry?.name)
          ?.map((name) => ({
            name,
            width: 0,
          }));
        setButtonWidth([...jobIndustries]);
        }
        
      } else {
        let list = items?.map((name) => ({
          name,
          width: 0,
        }));
        setButtonWidth([...list]);
      }
    }
  }, [items]);

  const arrayDiff = (array1, array2) => {
    return array1?.filter((item) => array2?.indexOf(item) < 0);
  };

  const remaining = (value) => {
    const arrayOfNames = visibleButtons?.map((obj) => obj?.name);
    const difference = arrayDiff(items, arrayOfNames);
    return difference?.join(", ");
  };

  const [visibleButtons, setVisibleButtons] = useState([]);
  const [remainingButtons, setRemainingButtons] = useState(0);

  const calculateVisibleButtons = () => {
    let cardWidth =
      slidertype === "company"
        ? document.querySelectorAll(".slider")?.[1]?.clientWidth
        : document.querySelector(".slider")?.clientWidth;
    let totalWidth = slidertype === "company" ? 30 : 35.6875;
    let visibleButton = [];
    let remainingButton = 0;
    for (const item of buttonWidth) {
      if (
        totalWidth + item?.width <= cardWidth - 20 ||
        totalWidth + item?.width <= cardWidth
      ) {
        totalWidth += item?.width;
        visibleButton?.push(item);
      } else {
        remainingButton++;
      }
    }
    setVisibleButtons(visibleButton);
    setRemainingButtons(remainingButton);
  };

  useEffect(() => {
    calculateVisibleButtons();
  }, [buttonWidth, items, boxRef.current?.clientWidth]);

  return (
    <Box
      ref={boxRef}
      className="slider"
      sx={{
        width: "100%",
        display: "flex",
        flexWrap: flexwrap,
        gap: gap,
        overflow: "hidden",
        ...props,
      }}
    >
      {visibleButtons
        ?.filter((item) => item !== null)
        ?.map((item, index) => {
          if (item !== undefined && index <= items?.length - 1) {
            return (
              <SmallButtonSlider
                setButtonWidth={setButtonWidth}
                buttonWidth={buttonWidth}
                fontSize={12}
                key={nanoid()}
                color={color}
                height={25}
                value={item?.name}
                label={item?.name}
                margin={margin}
                fontWeight={fontWeight}
              />
            );
          }
        })}
      {remainingButtons !== 0 && (
        <SmallButton
          fontSize={12}
          key={nanoid()}
          color={color}
          height={25}
          value={remaining(remainingButtons)}
          label={`+ ${remainingButtons}`}
          margin={margin}
        />
      )}
    </Box>
  );
};

export default Slider2;
