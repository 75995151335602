import styled from "@emotion/styled";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  CircularProgress,
  Grid,
  Typography,
} from "@mui/material";
import React, { useRef, useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import redTalent from "../../../assets/Padding Excluded/Black_Talent_Red.svg";
import TalentSVGButton from "../../common/TalentSVGButton";
import InfoIcon from "../../common/InfoIcon";
import { buildSearchNew } from "../../../redux/admin/searchSlice";
import { useDispatch } from "react-redux";
import SmallButtonTalent from "../../common/SmallButtonTalent";
import TalentsCard from "../../common/TalentsCard";
import { findFirstKeyWithNonEmptyField } from "../../../utils/Constants";

const StyledAccordion = styled(Accordion)(({ theme }) => ({
  boxShadow: "none",
  "& .MuiAccordionSummary-root": {
    padding: "0px !important",
  },
  ".MuiPaper-root-MuiAccordion-root": {
    backgroundColor: "#E5E5E5 !important",
  },
}));

const BuildSearchAccordion = ({ titlesList, basicData, handleSearch ,advanceFilter}) => {
  const [candidateList, setCandidateList] = useState({});
  const [currentOpen, setCurrentOpen] = useState(null);
  const [lastKeyy, setLastKeyy] = useState(0);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const mainScrollRef = useRef(null);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  // const findFirstKeyWithNonEmptyField = (data) => {
  //   for (let key in data) {
  //     if (data[key].field && data[key].field.length > 0) {
  //       return key;
  //     }
  //   }
  //   return null;
  // };
  // const findFirstKeyWithNonEmptyField = (data) => {
  //   for (let key in data) {
  //     // Exclude the keys 'currency_id' and 'salaryType'
  //     if (key === 'currency_id' || key === 'salaryType') {
  //       continue;
  //     }
  
  //     // Check if the key is not disabled and the 'field' array is not empty
  //     if (data[key]?.disabled !== true && data[key]?.field?.length > 0) {
  //       return key;
  //     }
  //   }
  //   return null;
  // };
  const renameKeys = (obj) => {
    const keyMap = {
      contact: "contract",
      "in-office": "inoffice",
      "in demand": "indemand",
      "my talent": "searchMyTalent",
      genderType: "gender",
    };
    let newObj = {};
    for (let key in obj) {
      if (keyMap[key]) {
        newObj[keyMap[key]] = obj[key]; 
      } else {
        newObj[key] = obj[key]; 
      }
    }
    if (!("gender" in newObj)) {
      newObj["gender"] = "";
    }
    if (!("skin_id" in newObj)) {
      newObj["skin_id"] = "";
    }
    delete newObj.race;
    return newObj;
  };
  const searchPayloadCreator = (input) => {
    const result = {};
    for (const key in input) {
      if (input.hasOwnProperty(key) && key !== "all") {
        const item = input[key];
        if (!item?.disabled) {
           if (key === "salaryType" || key === "currency_id") {
            result[key] = item;
          } else {
            result[key] = {
              switch: item?.switch,
              field:
                item?.field && item?.field?.length > 0
                  ? item?.field?.map((fieldItem) =>
                      fieldItem &&
                      typeof fieldItem === "object" &&
                      "value" in fieldItem
                        ? fieldItem?.value
                        : fieldItem
                    )
                  : [],
            };
          }
        }
      }
    }
 return result
  };
  const getCandidateData = async (titleId, pageNumber) => {
    if (isLoadingMore) return;
    setLoading(true);
    let updatedData = {
      titleListFlag: false,
      title_id: titleId,
      isFirstQuery: findFirstKeyWithNonEmptyField(basicData),
      data: searchPayloadCreator(basicData),
      ...renameKeys(advanceFilter)
    };
    setIsLoadingMore(true);
    const { payload } = await dispatch(
      buildSearchNew({ lastKey: lastKeyy, payload: updatedData })
    );
    if (payload?.status == "success") {
      setLastKeyy(payload?.pageNumber + 1);
      setCandidateList((prevList) => ({
        ...prevList,
        [titleId]: [...(prevList[titleId] || []), ...payload?.data],
      }));
      setLoading(false);
      setIsLoadingMore(false);
    }
  };

  const handleAccordionChange = (titleId) => {
    setCandidateList({});
    setLastKeyy(0);
    setCurrentOpen((prevOpen) => (prevOpen === titleId ? null : titleId));
    if (!candidateList[titleId]) {
      getCandidateData(titleId, 1);
    }
  };

  const handleMainScroll = () => {
    const { scrollTop, scrollHeight, clientHeight } = mainScrollRef?.current;
    if (scrollTop + clientHeight >= scrollHeight - 10) {
      handleSearch();
    }
  };
  const handleAccordionDetailsScroll = (titleId) => (event) => {
    const { scrollTop, scrollHeight, clientHeight } = event?.target;
    const scrollTrigger = scrollHeight * 0.8;

    if (scrollTop + clientHeight >= scrollTrigger && !isLoadingMore) {
      getCandidateData(titleId, lastKeyy);
    }
  };
  return (
    <Grid item xs={12} sm={6} md={8} lg={9} xl={10} sx={{ width: "100%" }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-end",
          mb: 2,
        }}
      >
        <Box
          sx={{
            display: "flex",
            width: "130px",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <TalentSVGButton
            padding={"0px !important"}
            color={"white"}
            source={redTalent}
            height={28}
            width={23}
            startIconMargin={"0px !important"}
          />
          <Typography sx={{ fontSize: "12px", fontWeigh: 900 }}>
            Talent pools
          </Typography>
          <InfoIcon />
        </Box>
        <Box
          sx={{
            display: "flex",
            width: "100px",
            justifyContent: "space-evenly",
            alignItems: "center",
            mr: 2,
          }}
        >
          <Typography sx={{ fontSize: "10px" }}>all talent</Typography>
          <Typography sx={{ fontSize: "10px" }}>my talent</Typography>
        </Box>
      </Box>
      <div
        ref={mainScrollRef}
        onScroll={handleMainScroll}
        style={{ overflowY: "auto", maxHeight: "600px" }}
      >
        {titlesList?.map((title, index) => (
          <StyledAccordion
            expanded={currentOpen === title?.title_id}
            onChange={() => handleAccordionChange(title?.title_id)}
            key={index}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`panel${index}-content`}
              key={`panel${index}${title?.title_id}`}
            >
              <Box
                sx={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography sx={{ fontWeight: "bold" }}>
                  {title?.title}
                </Typography>
                <Box sx={{ alignItems: "flex-end" }}>
                  <SmallButtonTalent
                    key={`panel2${index}${title?.title_id}`}
                    fontWeight={700}
                    textColor={"#FFFFFF"}
                    color="recruitButton"
                    label={title?.total}
                    mr={3}
                  />
                  <SmallButtonTalent
                    key={`pane3${index}${title?.title_id}`}
                    fontWeight={700}
                    textColor={"#000"}
                    color="grayButton200"
                    label={title?.mytalent_count}
                  />
                </Box>
              </Box>
            </AccordionSummary>
            <AccordionDetails
              onScroll={handleAccordionDetailsScroll(title?.title_id)}
              style={{
                overflowY: "auto",
                maxHeight: "400px",
                minHeight: "50px",
              }}
            >
              {currentOpen === title?.title_id &&
                candidateList[title?.title_id]?.map((candidate, idx) => (
                  <TalentsCard
                    talentContent={candidate}
                    key={`panel4${idx}${title?.title_id}`}
                    getJobList={getCandidateData}
                    setAllTalent={setCandidateList}
                    telId={candidate?.candidate_user_id}
                  />
                ))}
              {loading && (
                <Box sx={{ textAlign: "center" }}>
                  <CircularProgress />
                </Box>
              )}
            </AccordionDetails>
          </StyledAccordion>
        ))}
      </div>
      {/* {loading && <CircularProgress />} */}
    </Grid>
  );
};

export default BuildSearchAccordion;
