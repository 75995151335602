import { Box, Button, Paper, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getTermcondition } from "../../../redux/guest/contactSlice";
import { useTheme } from "@emotion/react";
import { createMarkup } from "../../../utils/Common";
import useViewport from "../../common/ViewPort";
import RightArrow from "../../common/RightArrow";
import LeftArrow from "../../common/LeftArrow";
import { StyledButtonLeft } from "../../../utils/CommonComponent";
import { useLocation, useNavigate } from "react-router-dom";

const TermsOfService = () => {
  const dispatch = useDispatch();
  const params = useLocation();
  const [detailData, setDetailData] = useState();
  const theme = useTheme();
  const navigate = useNavigate();
  const { width } = useViewport();
  const [activeStep, setActiveStep] = useState(1);
  const [leftExpanded, setLeftExpand] = useState(true);
  const [rightExpand, setRightExpand] = useState(true);

  const getTerm = async () => {
    try {
      const response = await dispatch(getTermcondition());
      if (response?.payload?.status === "success") {
        setDetailData(response?.payload?.data?.data);
      }
    } catch (error) {
     
    }
  };

  useEffect(() => {
    if (params?.pathname === "/termsOfService") {
      setActiveStep(1);
    } else {
      setActiveStep(2);
    }
  }, [params]);

  useEffect(() => {
    if (width > 1024) {
      setLeftExpand(true);
      setRightExpand(true);
    } else {
      setLeftExpand(false);
      setRightExpand(false);
    }
  }, [width]);

  useEffect(() => {
    getTerm();
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        height: "100%",
        width: "100%",
        boxSizing: "border-box",
        justifyContent: "center",
      }}
    >
      <Box
        item
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: `${width >= 768 && "10px"}`,
          position: `${width < 768 ? "absolute" : "relative"}`,
          // width: leftExpanded
          //   ? { xs: "150px", mdl: "123px", mlg: "150px", xl: "150px" }
          //   : "30px",
          width: leftExpanded ? "125" : "30px",
          minWidth: leftExpanded ? "125px" : "30px",
          zIndex: "30",
        }}
      >
        <Button
          variant="contained"
          sx={{
            position: "static",
            borderRadius: "0 10px 10px 0",
            minWidth: "30px",
            width: "30px",
            height: "45px",
          }}
          color="filterButtons"
          onClick={() => {
            setLeftExpand((prevState) => !prevState);
          }}
        >
          {leftExpanded ? <LeftArrow /> : <RightArrow />}
        </Button>
        {leftExpanded && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              height: {
                xs: `calc(100vh - 95px)`,
                sm: `calc(100vh - 120px )`,
                md: `calc(100vh - 120px)`,
                lg: `calc(100vh - 135px)`,
                xl: `calc(100vh - 155px)`,
                xxl: "calc(100vh - 175px)",
              },
              width: `${width < 768 && "135px"}`,
              paddingTop: `${width < 768 && "10px"}`,
              background: `${width > 0 && width < 768 && "#EBECF3"}`,
              boxShadow: `${
                width > 0 && width < 768 && "0px 5px 10px #00000029"
              }`,
              opacity: `${width < 768 && "1"}`,
              borderRadius: `${width < 768 && "10px"}`,
              overflowX: "hidden",
              overflowY: leftExpanded ? "scroll" : "unset",
            }}
          >
            <StyledButtonLeft
              onClick={() => {
                setActiveStep(1);
                navigate("/termsOfService");
              }}
              variant={"contained"}
              color={activeStep === 1 ? "activeButton" : "displayCompanyButton"}
              sx={{
                width: leftExpanded ? "125px" : "30px",
                minWidth: leftExpanded ? "125px" : "30px",
                borderRadius: "0 10px 10px 0",
                padding: "0px",
                border: 0,
                fontWeight:
                  activeStep === 1
                    ? theme.typography.Bold
                    : theme.typography.Regular,
                "&:hover": {
                  backgroundColor: theme?.palette?.activeButton?.main,
                  color: theme?.palette?.white,
                  fontWeight: theme.typography.Bold,
                },
              }}
            >
              {"terms of service"}
            </StyledButtonLeft>
            <StyledButtonLeft
              onClick={() => {
                setActiveStep(2);
                navigate("/privacyPolicy");
              }}
              variant={"contained"}
              color={activeStep === 2 ? "activeButton" : "displayCompanyButton"}
              sx={{
                borderRadius: "0 10px 10px 0",
                border: 0,
                width: leftExpanded ? "125px" : "30px",
                minWidth: leftExpanded ? "125px" : "30px",
                fontWeight:
                  activeStep === 2
                    ? theme.typography.Bold
                    : theme.typography.Regular,
                "&:hover": {
                  backgroundColor: theme?.palette?.activeButton?.main,
                  color: theme?.palette?.white,
                  fontWeight: theme.typography.Bold,
                },
              }}
            >
              {"privacy policy"}
            </StyledButtonLeft>
          </Box>
        )}
      </Box>
      <Box
        sx={{
          background: "#FFF",
          marginTop: "55px",
          height: "88%",
          width: `calc(100vw - 255px)`,
          borderRadius: "25px",
          overflowY: "hidden",
          overflowX: "hidden ",
          marginBottom: "30px",
          marginX: width <= 768 ? "10px " : `30px`,
          boxSizing: "border-box",
        }}
      >
        <Typography
          textAlign={"center"}
          sx={{ fontWeight: "700", fontSize: "16px", marginTop: "20px" }}
        >
          Terms of Service
        </Typography>
        <Box
          sx={{
            height: "100%",
            overflowY: "auto",
            padding: "15px",
            boxSizing: "border-box",
            paddingBottom: "40px",
          }}
        >
          <Typography
            sx={{
              fontWeight: theme.typography.Regular,
              fontSize: 12,
              // overflow: "hidden",
              // display: "-webkit-box",
              // letterSpacing: "0.25px",
              // lineHeight: "1.6",
              overflow: "hidden",
              display: "-webkit-box",
              WebkitBoxOrient: "vertical",
              // WebkitLineClamp: 3,
              wordBreak: "break-word",
            }}
            color={theme.palette.black100}
            gutterBottom={true}
            dangerouslySetInnerHTML={createMarkup(detailData)}
          />
        </Box>
      </Box>
      <Box
        item
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: `${width >= 768 && "10px"}`,
          direction: "rtl",
          position: `${width < 768 ? "absolute" : "relative"}`,
          zIndex: "30",
          // width: rightExpand && "150px",
          right: "0",
          // width: leftExpanded
          //   ? { xs: "150px", mdl: "123px", mlg: "150px", xl: "150px" }
          //   : "30px",
          width: rightExpand ? "125px" : "30px",
          minWidth: rightExpand ? "125px" : "30px",
        }}
      >
        <Button
          variant="contained"
          sx={{
            position: "sticky",
            top: 0,
            borderRadius: "10px 0 0 10px",
            minWidth: "30px",
            width: "30px",
            height: "45px",
          }}
          color="filterButtons"
          onClick={() => {
            setLeftExpand((prevState) => !prevState);
            setRightExpand((prevState) => !prevState);
          }}
        >
          {rightExpand ? <RightArrow /> : <LeftArrow />}
        </Button>
        {rightExpand && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: `${width < 768 && "135px"}`,
              paddingTop: `${width < 768 && "10px"}`,
              background: `${width > 0 && width < 768 && "#EBECF3"}`,
              boxShadow: `${
                width > 0 && width < 768 && "0px 5px 10px #00000029"
              }`,
              opacity: `${width < 768 && "1"}`,
              borderRadius: `${width < 768 && "10px"}`,
              height: {
                xs: `calc(100vh - 95px)`,
                sm: `calc(100vh - 120px )`,
                md: `calc(100vh - 120px)`,
                lg: `calc(100vh - 135px)`,
                xl: `calc(100vh - 155px)`,
                xxl: "calc(100vh - 175px )",
              },
              overflowY: rightExpand ? "scroll" : "unset",
            }}
            className="rightfilterSec"
          >
            <style>
              {`.rightfilterSec::-webkit-scrollbar {
                      display:none;
                    }
                    `}
            </style>
            <Paper
              sx={{
                background: "transparent",
                boxShadow: 0,
                gap: "10px",
                display: "flex",
                flexDirection: "column",
                // maxWidth: `${width < 768 && "150px"}`,
                maxWidth: "125px",
              }}
            ></Paper>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default TermsOfService;
