import { Box, Button, Paper, Typography, useTheme } from "@mui/material";
import React, { useState, useEffect, useCallback } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import {
  addBillingDetails,
  getBillingDetails,
} from "../../../../redux/login/loginSlice";
import InputBox from "../InputBox";
import CustomSelect from "../../../candidate/myCV/CustomSelect";
import { debounce } from "lodash";
import {
  getCompanies,
  getIndustries,
  setProfileSteps,
} from "../../../../redux/employer/empProfileSlice";
import { getCompanyCulture } from "../../../../redux/candidate/myCvSlice";
import { getCountry, getTown } from "../../../../redux/employer/postJobSlice";
import locale from "../../../../i18n/locale";
import { setLoading } from "../../../../redux/configSlice";
import PhoneInput, {
  formatPhoneNumberIntl,
  getCountryCallingCode,
  isValidPhoneNumber,
  parsePhoneNumber,
} from "react-phone-number-input";
// import { parsePhoneNumberFromString } from "react-phone-number-input";
import { CheckCircle } from "@mui/icons-material";
import * as Yup from "yup";
import { emailRegex, validateSchema } from "../../../../utils/Common";

const regionNames = new Intl.DisplayNames(["en"], { type: "region" });

const BillingComponent = () => {
  const i18n = locale.en;
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({});
  const [errors, setErrors] = useState([]);
  const theme = useTheme();
  const { company_id } = useSelector((state) => state?.configalert);
  const [isLoading, setIsLoading] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { country, town, countries } = useSelector((state) => state?.postJobs);

  const schema = Yup.object({
    company_name: Yup.string().required("Company Name is required"),
    finanace_contact_person: Yup.string().required(
      "Finance Contact Person is required"
    ),
    finance_contact_email: Yup.string()
      .matches(emailRegex, "Invalid email address") // Use custom regex for email validation
      .required("Email is Required."),
    finance_contact_number: Yup.string()
      .required("Contact is required")
      .test("is-valid-number", "Invalid contact", function (value) {
        let isValid = isValidPhoneNumber(value);
        return isValid;
      }),
    vat_number: Yup.string().required("VAT Number is required"),
    country_id: Yup.object().required("Country is required"),
    town_id: Yup.object().required("City/Town is required"),
    state: Yup.string().required("State/Province is required"),
    zip_code: Yup.string().required("Zip/Postal Code is required"),
  });

  const getDetail = async (id) => {
    const payload = {
      company_id: id,
    };
    try {
      const response = await dispatch(getBillingDetails(payload));
      if (response?.payload?.status === "success") {
        setFormData({
          ...response?.payload?.data,
          country_id: {
            label: response?.payload?.data?.country_name,
            value: response?.payload?.data?.country_id,
          },
          town_id: {
            label: response?.payload?.data?.town_name,
            value: response?.payload?.data?.town_id,
          },
        });
      }
    } catch (error) {
    }
  };

  const addDetails = () => {
    const payload = {
      company_id: company_id,
      company_address_1: formData?.company_address_1,
      company_address_2: formData?.company_address_2,
      company_address_3: formData?.company_address_3,
      town_id: formData?.town_id?.value,
      country_code: formData?.country_code,
      country_id: formData?.country_id?.value,
      state: formData?.state,
      zip_code: formData?.zip_code,
      finanace_contact_person: formData?.finanace_contact_person,
      finance_contact_email: formData?.finance_contact_email,
      finance_contact_number: formData?.finance_contact_number,
      vat_number: formData?.vat_number,
      company_name:formData?.company_name
    };
    validateSchema(schema, {
      ...formData,
      country_id: formData?.country_id?.value
        ? {
            value: formData?.country_id?.value,
            label: formData?.country_id?.label,
          }
        : "",
      town_id: formData?.town_id?.value
        ? { value: formData?.town_id?.value, label: formData?.town_id?.label }
        : "",
      finance_contact_number: `${formData?.country_code}${formData?.finance_contact_number}`,
    })
      .then(() => {
        dispatch(addBillingDetails(payload)).then((payload) => {
          if (payload?.payload?.status === "success") {
            dispatch(setProfileSteps(0));
          }
        });
      })
      .catch((error) => {
        setErrors(
          Object.keys(error)?.map((item) => ({
            message: error[item],
            key: item,
          }))
        );
      });
    // try {
    //   const response = await dispatch(addBillingDetails(payload));
    //   if (response?.payload?.status === "success") {
    //   }
    // } catch (error) {
    //   console.log(error, "Error");
    // }
  };

  const handleMyPlan = () => {
    dispatch(setProfileSteps(4));
  };
  const handleSave = () => {
    addDetails();
  };
  const handleInputChange = (e) => {
    const { id, value } = e.target;
    let updateData = {
      ...formData,
      [id]: value,
    };
    setFormData({ ...updateData });
    setErrors(errors?.filter((item) => item?.key !== id));
  };
  const debouncedHandleOnSelectInputChange = useCallback(
    debounce(
      async (newValue, type, compID) => {
        let params = {
          title: newValue || "",
          limit: 5,
        };
        if (newValue) {
          try {
            if (type === "company_name") {
              dispatch(getCompanies(params));
            }
            if (type === "industry_ids") {
              dispatch(getIndustries(params));
            }
            if (type === "culture_ids") {
              dispatch(getCompanyCulture(params));
            }
            if (type === "country_id") {
              dispatch(getCountry(params));
            }
            if (type === "town_id") {
              dispatch(
                getTown({
                  title: newValue || "",
                  id: compID,
                  limit: 200,
                })
              );
            }
          } catch (error) {}
        }
      },
      750,
      setIsLoading
    ),
    []
  );
  const handleOnSelectInputChange = (newValue, inputId) => {
    debouncedHandleOnSelectInputChange(newValue, inputId, formData?.country_id?.value);
  };
  const handleOnSelectChange = async (ind, newValue, val, index) => {
    const newProfileData = {
      ...formData,
    };

    if (index === "country_id") {
      dispatch(getTown(newValue?.value));
      setErrors(errors?.filter((item) => item?.key !== index));
      newProfileData[index] = newValue;
      setFormData((prev) => ({
        ...prev,
        [index]: newValue,
        country_id: newValue,
      }));
    }
    if (index === "town_id") {
      setErrors(errors?.filter((item) => item?.key !== index));
      newProfileData[index] = newValue;
      setFormData((prev) => ({
        ...prev,
        [index]: newValue,
      }));
    }
  };
  const onMenuCloseTown = async () => {
    setIsMenuOpen(true);
  };

  const onMenuOpenTown = async () => {
    if (town?.length === 0) {
      try {
        const { payload } = await dispatch(
          getTown(formData?.country_id?.value || formData?.country_code || "")
        );
      } catch (error) {}
    }
    setIsMenuOpen(true);
  };

  const getAllData = async () => {
    try {
      let payload = {
        limit: 5,
      };
      dispatch(setLoading(true));
      await dispatch(getCountry());
      // await Promise.all([
      //   dispatch(getCompanies(payload)),
      //   dispatch(getIndustries()),
      //   dispatch(getCountry()),
      // ]);
      dispatch(setLoading(false));
    } catch (error) {
      dispatch(setLoading(false));
      // dispatch(
      //   setAlert({
      //     show: true,
      //     type: ALERT_TYPE?.ERROR,
      //     msg: ERROR_MSG,
      //   })
      // );
    }
  };

  const handleContactNumber = (value) => {
    const phoneNumber = parsePhoneNumber(value || "");
    const countryCode = phoneNumber ? `+${phoneNumber.countryCallingCode}` : "";
    const number = phoneNumber ? phoneNumber.nationalNumber : "";
    setFormData((prev) => ({
      ...prev,
      finance_contact_number: number,
      country_code: countryCode,
    }));
    setErrors(errors?.filter((item) => item?.key !== "finance_contact_number"));
  };

  useEffect(() => {
    if (company_id) {
      getDetail(company_id);
    }
  }, [company_id]);
  useEffect(() => {
    getAllData();
    // getAllEmpData();
  }, []);
  return (
    <>
      <Box sx={{ display: "flex" }}>
        <Button
          variant="contained"
          color="discovery"
          sx={{
            borderRadius: "25px 0 10px 0",
            height: "45px",
            width: "150px",
            cursor: "default",
          }}
        >
          billing info
        </Button>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          borderRadius: "25px",
          gap: "20px",
          padding: "30px 30px 0px 30px !important",
        }}
      >
        <Box
          display={"flex"}
          sx={{
            justifyContent: "space-between",
            gap: { xs: "20px", tablet: "0px" },
            flexDirection: { xs: "column" },
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", tablet: "row" },
              justifyContent: "space-between",
              marginBottom: "10px",
            }}
          >
            <Box sx={{ width: { xs: "100%", tablet: "47%" } }}>
              <Typography
                sx={{
                  marginBottom: "4px",
                  fontSize: "14px",
                  fontWeight: theme.typography.Bold,
                }}
              >
                Full Company Name*
              </Typography>
              <InputBox
                placeholder={"Enter the company’s registered name"}
                value={formData?.company_name ? formData?.company_name : ""}
                id="company_name"
                onChange={handleInputChange}
                sx={{
                  // alignItems: "flex-start",
                  border: errors?.find((error) => error?.key === "company_name")
                    ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
                    : "1px solid #E0E0E0",
                }}
              />
            </Box>
            <Box sx={{ width: { xs: "100%", tablet: "47%" } }}>
              <Typography
                sx={{
                  marginBottom: "4px",
                  fontSize: "14px",
                  fontWeight: theme.typography.Bold,
                }}
              >
                Finance Contact Person*
              </Typography>
              <InputBox
                placeholder={"Enter a finance contact person"}
                value={
                  formData?.finanace_contact_person
                    ? formData?.finanace_contact_person
                    : ""
                }
                id="finanace_contact_person"
                onChange={handleInputChange}
                sx={{
                  // alignItems: "flex-start",
                  border:
                    // !profileData?.hyperlink &&
                    errors?.find(
                      (error) => error?.key === "finanace_contact_person"
                    )
                      ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
                      : "1px solid #E0E0E0",
                }}
              />
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", tablet: "row" },
              justifyContent: "space-between",
              // marginBottom:"10px"
            }}
          >
            <Box sx={{ width: { xs: "100%", tablet: "47%" } }}>
              <Typography
                sx={{
                  marginBottom: "4px",
                  fontSize: "14px",
                  fontWeight: theme.typography.Bold,
                }}
              >
                Registered Company Address
              </Typography>
              <InputBox
                placeholder={"Address line 1"}
                value={
                  formData?.company_address_1 ? formData?.company_address_1 : ""
                }
                id="company_address_1"
                onChange={handleInputChange}
                sx={{
                  marginBottom: "15px",
                  // alignItems: "flex-start",
                  border:
                    // !profileData?.hyperlink &&
                    errors?.find((error) => error?.key === "company_address_1")
                      ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
                      : "1px solid #E0E0E0",
                }}
              />
              <InputBox
                placeholder={"Address line 2"}
                value={
                  formData?.company_address_2 ? formData?.company_address_2 : ""
                }
                id="company_address_2"
                onChange={handleInputChange}
                sx={{
                  marginBottom: "15px",
                  // alignItems: "flex-start",
                  border:
                    // !profileData?.hyperlink &&
                    errors?.find((error) => error?.key === "company_address_2")
                      ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
                      : "1px solid #E0E0E0",
                }}
              />
              <InputBox
                placeholder={"Address line 3"}
                value={
                  formData?.company_address_3 ? formData?.company_address_3 : ""
                }
                id="company_address_3"
                onChange={handleInputChange}
                sx={{
                  // alignItems: "flex-start",
                  marginBottom: "15px",
                  border:
                    // !profileData?.hyperlink &&
                    errors?.find((error) => error?.key === "company_address_3")
                      ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
                      : "1px solid #E0E0E0",
                }}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                width: { xs: "100%", tablet: "47%" },
              }}
            >
              <Box
                sx={{
                  width: { xs: "100%", tablet: "100%" },
                  marginBottom: "10px",
                }}
              >
                <Typography
                  sx={{
                    marginBottom: "4px",
                    fontSize: "14px",
                    fontWeight: theme.typography.Bold,
                  }}
                >
                  Finance Contact Email*
                </Typography>
                <InputBox
                  placeholder={"Enter a finance contact email"}
                  value={
                    formData?.finance_contact_email
                      ? formData?.finance_contact_email
                      : ""
                  }
                  id="finance_contact_email"
                  onChange={handleInputChange}
                  sx={{
                    border: errors?.find(
                      (error) => error?.key === "finance_contact_email"
                    )
                      ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
                      : "1px solid #E0E0E0",
                  }}
                />
              </Box>

              <Box sx={{ width: { xs: "100%", tablet: "100%" } }}>
                <Typography
                  sx={{
                    marginBottom: "4px",
                    fontSize: "14px",
                    fontWeight: theme.typography.Bold,
                  }}
                >
                  {i18n["empMyProfile.phoneNumberReq"]}
                </Typography>
                <Paper
                  sx={{
                    display: "flex",
                    width: "100%",
                    height: "40px",
                    borderRadius: "10px",
                    boxShadow: "none",
                  }}
                >
                  <Box
                    sx={{
                      background: "transparent",
                      width: "100%",
                      height: "38px",
                      borderRadius: "10px",
                      display: "flex",
                      justifyContent: "space-between",
                      border: errors?.find(
                        (error) => error?.key === "finance_contact_number"
                      )
                        ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
                        : `1px solid ${theme.palette.grayBorder}`,
                    }}
                  >
                    <PhoneInput
                      international
                      id="contact_no"
                      placeholder="Enter phone number"
                      countryCallingCodeEditable={false}
                      value={
                        `${formData?.country_code}${formData?.finance_contact_number}` ||
                        ""
                      }
                      onChange={handleContactNumber}
                      focusInputOnCountrySelection={true}
                      style={{
                        font: "inherit",
                        padding: "10px",
                      }}
                    />
                    {isValidPhoneNumber(
                      `${formData?.country_code}${formData?.finance_contact_number}` ||
                        ""
                    ) && (
                      <Typography
                        sx={{
                          color: theme?.palette?.tealColor?.main,
                          fontSize: "14px",
                          alignItems: "center",
                          display: "flex",
                          gap: "5px",
                          marginRight: "5px",
                          justifyContent: "end",
                        }}
                      >
                        Correct <CheckCircle fontSize="small" />
                      </Typography>
                    )}
                  </Box>
                </Paper>
              </Box>
              <Box
                sx={{
                  width: { xs: "100%", tablet: "100%", marginTop: "10px" },
                }}
              >
                <Typography
                  sx={{
                    marginBottom: "4px",
                    fontSize: "14px",
                    fontWeight: theme.typography.Bold,
                  }}
                >
                  VAT Number*
                </Typography>
                <InputBox
                  placeholder={"Add the VAT number"}
                  value={formData?.vat_number ? formData?.vat_number : ""}
                  id="vat_number"
                  onChange={handleInputChange}
                  sx={{
                    border: errors?.find((error) => error?.key === "vat_number")
                      ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
                      : "1px solid #E0E0E0",
                  }}
                />
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", tablet: "column" },
              //   justifyContent: { tablet: "space-between" },
              marginBottom: "10px",
              width: { xs: "100%", tablet: "47%" },
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "10px",
              }}
            >
              <Box sx={{ width: { xs: "100%", tablet: "47%" } }}>
                <Typography
                  sx={{
                    marginBottom: "4px",
                    fontSize: "14px",
                    fontWeight: theme.typography.Bold,
                  }}
                >
                  Country*
                </Typography>
                <CustomSelect
                  onMenuOpen={() => {}}
                  variant="default"
                  dis
                  onInputChange={handleOnSelectInputChange}
                  onChange={handleOnSelectChange}
                  placeholder={"Select the country"}
                  inputId="country_id"
                  name="country_id"
                  value={formData?.country_id}
                  // isLoading={isLoading}
                  filterOption={"filter"}
                  customStyle={{
                    menu: (provided) => ({
                      ...provided,
                      zIndex: 9999,
                    }),
                    menuList: (provided) => ({
                      ...provided,
                      "&::-webkit-scrollbar": {
                        width: "6px",
                        height: "6px",
                        borderRadius: "2.5px",
                        backgroundColor: "#efefef",
                      },
                      "&::-webkit-scrollbar-thumb": {
                        borderRadius: "2.5px",
                      },
                    }),
                    option: (
                      styles,
                      { data, isDisabled, isFocused, isSelected }
                    ) => {
                      return {
                        ...styles,
                        color: isSelected ? "#fff" : "black",
                        ":active": {
                          ...styles[":active"],
                        },
                      };
                    },
                    control: (baseStyles) => ({
                      ...baseStyles,
                      boxShadow: "none",
                      borderRadius: "10px",
                      border: (errors?.find(
                        (error) => error.key === ( "country_id")
                      ) || errors?.find(
                        (error) => error.key === ("country_id.value" )
                      ))
                        ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
                        : "1px solid #E0E0E0",
                      "&:hover": (errors?.find(
                        (error) => error.key === ( "country_id")
                      ) || errors?.find(
                        (error) => error.key === ("country_id.value" )
                      ))
                        ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
                        : "1px solid #E0E0E0",
                      // width: `100%`,
                    }),
                  }}
                  option={country?.map((item) => ({
                    value: item?.country_id,
                    label: item?.name,
                  }))}
                />
              </Box>
              <Box sx={{ width: { xs: "100%", tablet: "47%" } }}>
                <Typography
                  sx={{
                    marginBottom: "4px",
                    fontSize: "14px",
                    fontWeight: theme.typography.Bold,
                  }}
                >
                  City/Town*
                </Typography>
                <CustomSelect
                  //   isDisabled={formData?.country_id ? false : true}
                  onMenuClose={onMenuCloseTown}
                  onMenuOpen={onMenuOpenTown}
                  variant="default"
                  onInputChange={handleOnSelectInputChange}
                  onChange={handleOnSelectChange}
                  placeholder={"Select the city/town"}
                  inputId="town_id"
                  name="town_id"
                  value={formData?.town_id}
                  isLoading={isLoading["town_id"]}
                  customStyle={{
                    menu: (provided) => ({
                      ...provided,
                      zIndex: 9999,
                    }),
                    menuList: (provided) => ({
                      ...provided,
                      "&::-webkit-scrollbar": {
                        width: "6px",
                        height: "6px",
                        borderRadius: "2.5px",
                        backgroundColor: "#efefef",
                      },
                      "&::-webkit-scrollbar-thumb": {
                        borderRadius: "2.5px",
                      },
                    }),
                    option: (
                      styles,
                      { data, isDisabled, isFocused, isSelected }
                    ) => {
                      return {
                        ...styles,
                        color: isSelected ? "#fff" : "black",
                        ":active": {
                          ...styles[":active"],
                        },
                      };
                    },
                    control: (baseStyles) => ({
                      ...baseStyles,
                      boxShadow: "none",
                      borderRadius: "10px",
                      border: (errors?.find(
                        (error) => error.key === ( "town_id")
                      ) || errors?.find(
                        (error) => error.key === ("town_id.value" )
                      ))
                        ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
                        : "1px solid #E0E0E0",
                      "&:hover": (errors?.find(
                        (error) => error.key === ( "town_id")
                      ) || errors?.find(
                        (error) => error.key === ("town_id.value" )
                      ))
                        ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
                        : "1px solid #E0E0E0",
                    }),
                  }}
                  option={town?.map((item) => ({
                    value: item?.town_id,
                    label: item?.name,
                  }))}
                />
              </Box>
            </Box>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Box sx={{ width: { xs: "100%", tablet: "47%" } }}>
                <Typography
                  sx={{
                    marginBottom: "4px",
                    fontSize: "14px",
                    fontWeight: theme.typography.Bold,
                  }}
                >
                  State/Province*
                </Typography>
                <InputBox
                  placeholder={"Enter state/province"}
                  value={formData?.state ? formData?.state : ""}
                  id="state"
                  onChange={handleInputChange}
                  sx={{
                    marginBottom: "10px",
                    border: errors?.find((error) => error?.key === "state")
                      ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
                      : "1px solid #E0E0E0",
                  }}
                />
              </Box>
              <Box sx={{ width: { xs: "100%", tablet: "47%" } }}>
                <Typography
                  sx={{
                    marginBottom: "4px",
                    fontSize: "14px",
                    fontWeight: theme.typography.Bold,
                  }}
                >
                  Zip/Postal code*
                </Typography>
                <InputBox
                  placeholder={"Enter zip/postal code"}
                  value={formData?.zip_code ? formData?.zip_code : ""}
                  id="zip_code"
                  type="number"
                  onChange={handleInputChange}
                  sx={{
                    marginBottom: "10px",
                    // alignItems: "flex-start",
                    border:
                      // !profileData?.hyperlink &&
                      errors?.find((error) => error?.key === "zip_code")
                        ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
                        : "1px solid #E0E0E0",
                  }}
                />
              </Box>
            </Box>
          </Box>
        </Box>
        <Box sx={{ display: "flex", width: "100%", justifyContent: "center" }}>
          <Button
            sx={{
              color: "black",
              fontSize: "14px",
              fontWeight: theme.typography.Bold,
              width: "150px",
              height: "60px",
              borderRadius: "10px 0 0 0",
            }}
            variant="contained"
            color="submitButton"
            onClick={handleMyPlan}
          >
            my plan
          </Button>
          <Button
          disabled={company_id?false:true}
            sx={{
              fontSize: "14px",
              fontWeight: theme.typography.Bold,
              width: "150px",
              height: "60px",
              borderRadius: "0 10px 0 0",
            }}
            variant="contained"
            color="nandos"
            onClick={handleSave}
          >
            save
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default BillingComponent;
