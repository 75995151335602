import React, { useState, useLayoutEffect, useEffect } from "react";
import VideoCard from "./VideoCard";
import { Box, Button, Grid, Typography, useTheme } from "@mui/material";
import { getVideoJobsList } from "../../../redux/candidate/candidateJobs";
import { useDispatch } from "react-redux";
import { setAlert } from "../../../redux/configSlice";
import { ALERT_TYPE } from "../../../utils/Constants";
import InfiniteScroll from "react-infinite-scroll-component";
import jobVideoHeaderLogo from "../../../assets/Padding Excluded/White_jobVideo.svg";
import jobVideoicon from "../../../assets/CrayBotIcons/Crayon Job Video Character.svg";
import { useLocation, useNavigate } from "react-router-dom";
import useViewport from "../../common/ViewPort";

const CamList = ({
  setActiveStep,
  leftExpanded,
  setItemsFetched,
  newCardWidth,
  newPadding,
  gridRef,
}) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { width } = useViewport();
  const [allJobsList, setAllJobList] = useState();
  const [lastKey, setLastKey] = useState(0);
  const location = useLocation(); 
  const [openRecordDialog, setOpenRecordDialog] = useState(false);
  // useEffect(() => {
  //   console.log("first");
   
  // }, []);
  const calculatedHeight = () => {
    // Calculate the desired height based on screen resolution or any other logic
    // For example, you can use window.innerWidth or window.innerHeight to adjust the height dynamically.
    const screenWidth = window.innerWidth;

    if (screenWidth < 600) {
      // xs
      return `calc(100vh - 95px)`;
    } else if (screenWidth < 900) {
      // sm
      return `calc(100vh - 80px)`;
    } else if (screenWidth < 1366) {
      // md
      return `calc(100vh - 90px)`;
    } else if (screenWidth < 1920) {
      // lg
      return `calc(100vh - 80px)`;
    } else if (screenWidth < 2559) {
      // xl
      return `calc(100vh - 100px)`;
    } else {
      // xxl or greater
      return `calc(100vh - 120px)`;
    }
  };

  const allVideoJobs = async (lastKey) => {
    const { payload } = await dispatch(getVideoJobsList({ lastKey: lastKey }));
    if (payload?.status === "success") {
      if (lastKey === 0) {
        setAllJobList(payload?.data);
        setLastKey(payload?.pageNumber + 1);
      } else {
        setAllJobList((prevState) => [...prevState, ...payload?.data]);
        setLastKey(payload?.pageNumber + 1);
      }
      setItemsFetched(true);
      // if (location?.state) {
      //   setOpenRecordDialog(true);
      //   // location.state = null;
      // }
    } else if (payload?.status === "error") {
      dispatch(
        setAlert({
          show: true,
          type: ALERT_TYPE.ERROR,
          msg: "Error",
        })
      );
    }
  };

  useLayoutEffect(() => {
    allVideoJobs(0);
  }, []);
  if (width === 0) {
    return null;
  }
  return (
    <>
      {allJobsList?.length === 0 && (
        <Box
          sx={{
            background: "#FFF",
            boxShadow: "0px 3px 6px #00000029",
            width: { xs: "100%", lg: "50%" },
            borderRadius: "25px",
            display: "flex",
            margin: "auto",
            flexDirection: "column",
            justifyContent: "space-between",
            marginTop: { xs: "35px", md: "109px" },
          }}
        >
          <Button
            variant="contained"
            color="peachPayment"
            sx={{
              borderRadius: "25px 0 25px 0",
              width: "150px",
              height: "45px",
              fontSize: "14px",

              fontWeight: theme.typography.Bold,
              gap: "10px",
            }}
          >
            <Box
              component={"img"}
              src={jobVideoHeaderLogo}
              sx={{
                height: "20px",
                width: "24px",
              }}
            />
            job videos
          </Button>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              padding: "10px 20px 57px",
              gap: "25px",
            }}
          >
            <Box
              sx={{
                width: "70%",
                // height: "200px",
                flexDirection: "column",
                alignItems: "center",
                display: "flex",
                gap: "20px",
              }}
            >
              <Typography
                sx={{
                  fontSize: "20px",
                  fontWeight: theme.typography.Bold,
                  textAlign: "center",
                }}
              >
                You have one job...
              </Typography>
            </Box>

            <Box
              component={"img"}
              src={jobVideoicon}
              sx={{
                height: "359px",
                width: "auto",
              }}
            />
            <Box
              sx={{
                width: "70%",
                // height: "200px",
                flexDirection: "column",
                alignItems: "center",
                display: "flex",
                gap: "20px",
                marginTop: "30px",
              }}
            >
              <Typography
                sx={{
                  fontSize: "14px",
                  textAlign: "center",
                  fontWeight: theme.typography.Regular,
                }}
              >
                …to apply for a job! You’ll first need to apply for, or be
                shortlisted to, a job before being able to record a job video.
              </Typography>
              <Typography
                sx={{
                  fontSize: "14px",
                  textAlign: "center",
                  fontWeight: theme.typography.Regular,
                }}
              >
                Once you’ve done that, the job card(s) will appear here instead
                of CrayBot and you will be able to add a recording per job.
              </Typography>
            </Box>
          </Box>
          <Button
            variant="contained"
            color="nandos"
            sx={{
              width: "100%",
              height: "60px",
              borderRadius: "0 0 25px 25px",
              fontSize: "15px",
              gap: "10px",
              fontWeight: theme.typography.Bold,
            }}
            onClick={() => navigate("/jobs")}
          >
            search for a job
          </Button>
        </Box>
      )}
      {allJobsList?.length > 0 && (
        <InfiniteScroll
          height={calculatedHeight()}
          dataLength={12}
          scrollThreshold={"100px"}
          next={() => allVideoJobs(lastKey)}
          style={{
            overflowX: "hidden",
          }}
          hasMore={true}
          endMessage={
            <p style={{ textAlign: "center" }}>
              <b>Yay! You have seen it all</b>
            </p>
          }
        >
          <Grid
            container
            gap={width < 768 ? "10px" : `${newPadding}px`}
            columns={{
              xs: 1,
              md: 1,
              lg: 3,
              mlg: 3.5,
              xl: 4,
              xxl: 6,
              xxxl: 8,
            }}
            justifyContent={width < 768 && width > 0 && "center"}
            sx={{
              position: "relative",
              padding: width < 768 ? "10px 10px" : `0 ${newPadding}px`,
              top: { xs: "55px", tablet: "55px" },
            }}
          >
            {allJobsList?.map((job) => (
              <Box
                sx={{
                  width: newCardWidth,
                  height: "560px",
                }}
              >
                <VideoCard
                  job={job}
                  allVideoJobs={allVideoJobs}
                  allJobsList={allJobsList}
                  setAllJobList={setAllJobList}
                  openRecordDialog={openRecordDialog}
                  setOpenRecordDialog={setOpenRecordDialog}
                  setActiveStep={setActiveStep}
                />
              </Box>
            ))}
          </Grid>
          <style>
            {`.infinite-scroll-component::-webkit-scrollbar {
                      display:none
                    }
                    .infinite-scroll-component__outerdiv {
                      width: 100%
                    }`}
          </style>
        </InfiniteScroll>
      )}
    </>
  );
};

export default CamList;
