import { useTheme } from "@mui/material/styles";

import { Paper, InputBase } from "@mui/material";
import { Modal } from "@mui/material";
import React, { useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

const ApproveConfirmationModal = ({
  handleOpen,
  handleClose,
  handleSubmit,
  modalHeading,
  selectedUser
}) => {
  const theme = useTheme();
  const [errors, setErrors] = useState();
  // const [name, setName] = useState("");
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    borderRadius: "12px",
    p: 4,
    boxShadow: "none !important",
    border: "1px solid black !important",
  };
  return (
    <Modal
      open={handleOpen}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography
          id="modal-modal-title"
          variant="h6"
          component="h2"
          textAlign={"center"}
        >
          {modalHeading} Confirmation
        </Typography>
        <Box sx={{textAlign:"center", marginBottom:"10px"}}>
         Are you Sure you want to {modalHeading === "Approval"? "approve":"rejcet"} user "{selectedUser?.user?.first_name}"?
        </Box>
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            sx={{
              height: "38px",
              mr: 2,
              borderRadius: "10px",
              color: "black",
            }}
            variant="contained"
            color="grayButton300"
            onClick={handleSubmit}
            
          >
            CONFIRM
          </Button>
          <Button
            sx={{
              height: "38px",
              mr: 2,
              borderRadius: "10px",
            }}
            onClick={handleClose}
            variant="contained"
            color="redButton100"
          >
            CANCEL
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default ApproveConfirmationModal;
