import { useTheme } from "@emotion/react";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  InputBase,
  Modal,
  Paper,
  Typography,
} from "@mui/material";
import CloseIcon from "../../CloseIcon";

const AddYoutubeLink = ({
  open,
  setOpen,
  youtubeLink,
  setErrors,
  errors,
  setYoutubeLink,
  handleSubmit,
}) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [videoId, setVideoId] = useState('');

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    height: "auto",
    width: { xs: "360px", tablet: "450px" },
    bgcolor: "background.paper",
    borderRadius: "25px",
    boxShadow: "none !important",
    "&:focus-visible": {
      outline: "none",
    },
  };

  const youtubeRegex = /^(https?\:\/\/)?(www\.youtube\.com|youtu\.?be)\/.+$/;
  const videoIdRegex = /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;

  const handleChange = (e) => {
    const url = e.target.value;
    setYoutubeLink(url);
    const match = url.match(videoIdRegex);
    if (match && match[1]) {
      setVideoId(match[1]);
      
    } else {
      setVideoId('');
    }
  };

  const handleFormSubmit = () => {
    if (youtubeRegex.test(youtubeLink) && videoId) {
      setErrors(false);
      handleSubmit(videoId);
    } else {
      setErrors(true);
    }
  };

  return (
    <Modal
      open={open}
      onClose={setOpen}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Box
          sx={{
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
            // gap: 3,
            width: "100%",
          }}
        >
          <CloseIcon top="15px" right="15px" onClick={setOpen} />
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            textAlign={"center"}
          >
            Enter YouTube link
          </Typography>
          <Box>
            <Box sx={{ mt: 3, mb: 4, width: "100%", padding: 0 }}>
              <Paper
                sx={{
                  display: "flex",
                  height: "50px",
                  width: "100% !important",
                  borderRadius: "10px  !important",
                  boxShadow: "none",
                  backgroundColor: "#fff !important",
                  border: errors
                    ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
                    : "1px solid borderColor",
                }}
              >
                <InputBase
                  id="youtubeLink"
                  value={youtubeLink}
                  onChange={handleChange}
                  placeholder={"Enter YouTube Link"}
                  sx={{
                    ml: 2,
                    mr: 2,
                    width: "100%",
                    borderRadius: "10px",
                    paddingLeft: "10px",
                    border: "1px solid #CCCCCC",
                  }}
                />
              </Paper>
              {errors && (
                <Typography color="error" variant="body2" sx={{ mt: 1 }}>
                  Please enter a valid YouTube link.
                </Typography>
              )}
            </Box>
          </Box>
          <Box sx={{ width: "100%" }}>
            <Button
              sx={{
                width: "100%",
                borderRadius: "0px 0px 25px 25px",
                height: "60px",
                fontWeight: theme.typography.Bold,
                fontSize: "14px",
              }}
              variant="contained"
              onClick={handleFormSubmit}
              color="nandos"
            >
              Submit
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default AddYoutubeLink;
