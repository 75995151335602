import { Box, Button, InputBase, useTheme } from "@mui/material";
import React from "react";

export default function SearchBox({
  onClick,
  onChange,
  width = "400px",
  margin = "",
  placeholder = "Search a job title",
}) {
  const theme = useTheme();
  return (
    <Box
      sx={{
        margin: margin,
        width: width,
        backgroundColor: "white",
        display: "flex",
        flexDirection: "row",
        border: `1px solid ${theme.palette.grayBorder}`,
        borderRadius: "10px",
        paddingLeft: "5px",
      }}
    >
      <InputBase
        sx={{
          height: "40px",
          width: "100%",
          fontSize: "14px",
          //   fontWeight: theme.typography.Bold,
        }}
        onChange={onChange}
        placeholder={placeholder}
      />
      {/* <Button
        variant="contained"
        color="redButton"
        sx={{
          borderRadius: "10px",
          boxShadow: "none",
          ":hover": {
            boxShadow: "none",
          },
        }}
        onClick={onClick}
      >
        Search
      </Button> */}
    </Box>
  );
}
