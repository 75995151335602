import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  IconButton,
  Modal,
  Typography,
  useTheme,
} from "@mui/material";
import React from "react";
import pricingTabIcon from "../../assets/TopBarIcons/PricingTabIcon.svg";
import CustomDialog from "./CustomDialog";
import CloseIcon from "./CloseIcon";

export default function CoinSpendBox({
  handleClose,
  text1,
  text1Width,
  text2,
  text2a,
  text2Width,
  text3,
  text3Width,
  text4Width,
  text4,
  text5,
  text5Width,
  buttonText,
  show,
  coinsBalance,
  coinsUse,
  buttonClick,
  gap,
  display = "inline-block",
  minHeight,
  buttonText2,
}) {
  const theme = useTheme();
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: { xs: "360px", tablet: "450px" },
    bgcolor: "background.paper",
    borderRadius: "25px",
    // pyb: 4,
    boxShadow: "none !important",
    // border: "1px solid black !important",
    "&:focus-visible": {
      outline: "none",
    },
  };
  return (
    <Modal
      open={show}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Box
          sx={{
            minWidth: "360px",
            minHeight: minHeight,
            margin: "auto",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            // gap: gap,
          }}
        >
          <CloseIcon top="20px" right="20px" onClick={handleClose} />
          <Box
            component={"img"}
            src={pricingTabIcon}
            sx={{
              height: 125,
              width: 160,
              marginTop: "-20px",
              marginBottom: gap,
            }}
          />

          <Typography
            sx={{
              fontSize: "20px",
              fontWeight: theme.typography.Bold,
              // width: text1Width,
              width:"90%",
              textAlign: "center",
              marginBottom: gap,
              display: text1 ? "block" : "none",
            }}
          >
            {text1}
          </Typography>
          <Typography
            sx={{
              fontSize: "18px",
              fontWeight: theme.typography.Medium,
              // width: text2Width,
              width:"90%",
              textAlign: "center",
              marginBottom: gap,
              display: text2 || text2a ? "block" : "none",
            }}
          >
            {text2}{" "}
            {
              <span
                style={{ fontSize: "18px", fontWeight: theme.typography.Bold }}
              >
                {coinsBalance}
              </span>
            }{" "}
            {text2a}
          </Typography>
          <Typography
            sx={{
              fontSize: "18px",
              fontWeight: theme.typography.Medium,
              // width: text3Width,
              width:"90%",
              textAlign: "center",
              display: display,
              // marginBottom: gap,
            }}
          >
            {text3}{" "}
            {
              <span
                style={{ fontSize: "18px", fontWeight: theme.typography.Bold }}
              >
                {coinsUse}
              </span>
            }{" "}
            {
              // <span
              //   style={{
              //     fontSize: "18px",
              //     width: text4Width,
              //     display: display,
              //   }}
              // >
                
              // </span>
            }{" "}
          </Typography>
          <Typography sx={{fontSize: "18px",
              fontWeight: theme.typography.Medium,
              width:"70%",
              textAlign: "center",
              marginBottom: gap,}}>
          {text4}
          </Typography>
          <Typography
            sx={{
              display: text5 ? "block" : "none",
              fontSize: "18px",
              fontWeight: theme.typography.Regular,
              // width: text5Width,
              width:"90%",
              textAlign: "center",
              marginBottom: gap,
            }}
          >
            {text5}
          </Typography>
        </Box>
        {buttonText2 ? (
          <>
            <Button
              variant="contained"
              color="darkGrey"
              sx={{
                width: "50%",
                height: "60px",
                borderRadius: "0 0 0 25px",
                color: "black",
              }}
              onClick={handleClose}
            >
              {buttonText2}
            </Button>
            <Button
              variant="contained"
              color="getCoins"
              sx={{
                width: "50%",
                height: "60px",
                borderRadius: "0 0 25px 0",
              }}
              onClick={buttonClick}
            >
              {buttonText}
            </Button>
          </>
        ) : (
          <Button
            variant="contained"
            color="getCoins"
            sx={{
              width: "100%",
              height: "60px",
              borderRadius: "0 0 25px 25px",
            }}
            onClick={buttonClick}
          >
            {buttonText}
          </Button>
        )}
      </Box>
    </Modal>
  );
}
