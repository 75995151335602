import { Box, Typography, useTheme } from "@mui/material";
import React, { useState } from "react";
import editIcon from "../../../../assets/Padding Included/Yellow_Edit.svg";
import { useDispatch } from "react-redux";
import EditBounty from "./EditBounty";
import { getDecodedToken, getToken } from "../../../../utils/Common";

export default function Bounty({
  handleClose,
  jobContent,
  setAllJobs,
  allJobs,
}) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const decodedToken = getDecodedToken(getToken());
  const [editOpen, setEditOpen] = useState(false);

  return (
    <>
      <Box sx={{ padding: "9px" }}>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Typography
            sx={{ fontSize: "12px", fontWeight: theme.typography.Bold }}
          >
            Crayon Bounty:
          </Typography>
          {decodedToken?.data?.role_id !== 8 && (
            <Box
              component={"img"}
              src={editIcon}
              sx={{
                width: 25,
                height: 25,
                cursor: "pointer",
              }}
              onClick={() => setEditOpen(true)}
            />
          )}
        </Box>

        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography
            sx={{ fontSize: "12px", fontWeight: theme.typography.Regular }}
          >
            Internal
          </Typography>
          <Typography
            sx={{ fontSize: "12px", fontWeight: theme.typography.Bold }}
          >
            {`${jobContent?.currency_symbol}${
              jobContent?.internal_bounty_amount
                ? jobContent?.internal_bounty_amount
                : "0"
            }`}
          </Typography>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography
            sx={{ fontSize: "12px", fontWeight: theme.typography.Regular }}
          >
            External
          </Typography>
          <Typography
            sx={{ fontSize: "12px", fontWeight: theme.typography.Bold }}
          >
            {`${jobContent?.currency_symbol}${
              jobContent?.external_bounty_amount
                ? jobContent?.external_bounty_amount
                : "0"
            }`}
          </Typography>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography
            sx={{ fontSize: "12px", fontWeight: theme.typography.Regular }}
          >
            Talent Partners
          </Typography>
          <Typography
            sx={{ fontSize: "12px", fontWeight: theme.typography.Bold }}
          >
            {`${jobContent?.currency_symbol}${
              jobContent?.talent_partner_bounty_amount
                ? jobContent?.talent_partner_bounty_amount
                : "0"
            }`}
          </Typography>
        </Box>
      </Box>
      {editOpen && (
        <EditBounty
          open={editOpen}
          handleClose={() => setEditOpen(false)}
          jobContent={jobContent}
          setAllJobs={setAllJobs}
          allJobs={allJobs}
        />
      )}
    </>
  );
}
