import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  IconButton,
  Popover,
  Switch,
  Tooltip,
  Typography,
  styled,
  tooltipClasses,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import crayonRecruit from "../../../assets/Characters/CrayBotIcons/Crayon_Recruit.svg";
import crayonDirect from "../../../assets/Padding Excluded/Crayon_Direct_No_Padding.svg";
import upArrow from "../../../assets/Black_Up_Close - Copy.svg";

import crayonRecruitIcon from "../../../assets/Characters/CrayBotIcons/Crayon Direct Recruit_1.svg";
import crayonDirectIcon from "../../../assets/Characters/CrayBotIcons/Crayon Direct Character.svg";
import coinIcon from "../../../assets/Crayon Talent/Crayon Coin.svg";
import crayonLogo from "../../../assets/Crayon_Favicon.svg";

import { CheckCircle } from "@mui/icons-material";
import {
  addBasicData,
  getSuccessFee,
} from "../../../redux/employer/postJobSlice";
import { useDispatch } from "react-redux";
import { setAlert } from "../../../redux/configSlice";
import { ALERT_TYPE } from "../../../utils/Constants";
import locale from "../../../i18n/locale";
import CoinSpendBox from "../CoinSpendBox";
import { useSelector } from "react-redux";
import CrayonDirectFirstBox from "./jobTypeBoxes/CrayonDirectFirstBox";
import CrayonRecruitBox from "./jobTypeBoxes/CrayonRecruitBox";

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip disableInteractive {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 11,
    maxWidth: 180,
    borderRadius: 15,
    padding: "16px 12px",
  },
}));

const commonSwitchStyle = {
  width: "90px",
  height: "35px",
  borderRadius: "25px",
  padding: 0,
  border: "1px solid",
  borderColor: "#C9CAD8",
  "& .MuiSwitch-track": {
    backgroundColor: "#F2F4FB",
  },

  "& .MuiSwitch-switchBase.Mui-checked": {
    color: "#FFFFFF",
    transform: "translateX(55px)",
    padding: "3px",
  },
  "& .MuiSwitch-thumb": {
    height: "28px",
    width: "28px",
  },
};

const JobType = ({
  changeStep,
  handleComplete,
  selectedJobTypeDetails,
  handleJobTypeDetails,
  getCompanyList,
  companyList,
  setSelectedCompanyRecruit,
  selectedCompanyRecruit,
  setSelectedCompanyDirect,
  selectedCompanyDirect,
  companyListLoading,
  handleDynamicTheme,
  jobId,
  isEditPostjob,
  basicData,
  setBasicData,
  saveBasicJobType,
}) => {
  const i18n = locale.en;
  const theme = useTheme();
  const dispatch = useDispatch();
  const { coinsCount } = useSelector((state) => state?.configalert);
  const [crayonRecruitExpand, setCrayonRecruitExpand] = useState(false);
  const [crayonDirectExpand, setCrayonDirectExpang] = useState(false);
  const [openDirectBox, setOpenDirectBox] = useState(false);
  const [openRecruitBox, setOpenRecruitBox] = useState(false);
  const [isSelectedRecruiter, setIsSelectedRecruiter] = useState(false);
  const [isSelectedDirect, setIsSelectedDirect] = useState(false);
  const [coinCharge, setCoinCharge] = useState(10);
  const [successfees, setSuccessFees] = useState(7.5);
  const [isRecruitVideoNeeded, setisRecruitVideoNeeded] = useState(
    selectedJobTypeDetails?.job_type === "Recruit"
      ? selectedJobTypeDetails?.is_video_require
      : false
  );
  const [isInterviewNeeded, setIsInterviewNeeded] = useState(
    selectedJobTypeDetails?.job_type === "Recruit"
      ? selectedJobTypeDetails?.is_interview_require
      : false
  );
  const [isRecruitPostPublicNeeded, setisRecruitPostPublicNeeded] = useState(
    selectedJobTypeDetails?.job_type === "Recruit"
      ? selectedJobTypeDetails?.post_incognito
      : false
  );
  const [isDirectVideoNeeded, setisDirectVideoNeeded] = useState(
    selectedJobTypeDetails?.job_type === "Direct"
      ? selectedJobTypeDetails?.is_video_require
      : false
  );
  const [isDirectPostPublicNeeded, setisDirectPostPublicNeeded] = useState(
    selectedJobTypeDetails?.job_type === "Direct"
      ? selectedJobTypeDetails?.post_incognito
      : false
  );
  const [directButton, setDirectButton] = useState(false);
  const [recruitButton, setRecruitButton] = useState(false);
  const [anchorElRecruit, setAnchorElRecruit] = useState(null);
  const [anchorElDirect, setAnchorElDirect] = useState(null);
  const openRecruit = Boolean(anchorElRecruit);
  const openDirect = Boolean(anchorElDirect);
  const idRecruit = openRecruit ? "simple-popover" : undefined;
  const idDirect = openDirect ? "simple-popover" : undefined;
  useEffect(() => {
    if (
      selectedJobTypeDetails?.job_type === "" ||
      selectedJobTypeDetails?.job_type === "Recruit"
    ) {
      if (
        selectedJobTypeDetails?.is_video_require ||
        selectedJobTypeDetails?.is_interview_require ||
        selectedJobTypeDetails?.post_incognito
      ) {
        setisRecruitVideoNeeded(selectedJobTypeDetails?.is_video_require);
        setIsInterviewNeeded(selectedJobTypeDetails?.is_interview_require);
        setisRecruitPostPublicNeeded(selectedJobTypeDetails?.post_incognito);
      } else {
        setisRecruitVideoNeeded(isRecruitVideoNeeded);
        setIsInterviewNeeded(isInterviewNeeded);
        setisRecruitPostPublicNeeded(isRecruitPostPublicNeeded);
      }
    } else {
      setisRecruitVideoNeeded(false);
      setIsInterviewNeeded(false);
      setisRecruitPostPublicNeeded(false);
    }
    if (selectedJobTypeDetails?.job_type === "Direct") {
      if (
        selectedJobTypeDetails?.is_video_require ||
        selectedJobTypeDetails?.post_incognito
      ) {
        setisDirectVideoNeeded(selectedJobTypeDetails?.is_video_require);
        setisDirectPostPublicNeeded(selectedJobTypeDetails?.post_incognito);
      } else {
        setisDirectVideoNeeded(isDirectVideoNeeded);
        setisDirectPostPublicNeeded(isDirectPostPublicNeeded);
      }
    } else {
      setisDirectVideoNeeded(false);
      setisDirectPostPublicNeeded(false);
    }
  }, [selectedJobTypeDetails]);

  const handleClickRecruit = (event) => {
    setAnchorElRecruit(event?.currentTarget);
    // getCompanyList();
  };

  const handleCloseRecruit = () => {
    setAnchorElRecruit(null);
  };

  const handleClickDirect = (event) => {
    setAnchorElDirect(event?.currentTarget);
    // getCompanyList();
  };

  const handleCloseDirect = () => {
    setAnchorElDirect(null);
  };
  const handleCrayonRectuitExpand = () => {
    setCrayonRecruitExpand((prevState) => !prevState);
  };
  const handleCrayonDirectExpand = () => {
    setCrayonDirectExpang((prevState) => !prevState);
  };

  const saveBasic = async (jobTypeData) => {
    if (jobId) {
      const { payload } = await dispatch(addBasicData(jobTypeData));
      if (payload?.status === "success") {
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE?.SUCCESS,
            msg: "Data Updated successfully!",
          })
        );
      }
    }
  };

  const handleImReadyBtn = () => {
    if (selectedCompanyDirect !== "") {
      if (isDirectVideoNeeded) {
        setCoinCharge(10 + 10);
      } else {
        setCoinCharge(10);
      }
      setOpenDirectBox(true);
    } else {
      handleDirectBox();
    }
  };
  const findMyDreamBtn = async () => {
    try {
      let data = {
        is_video_require: isRecruitVideoNeeded ? isRecruitVideoNeeded : "",
        is_interview_require: isInterviewNeeded ? isInterviewNeeded : "",
      };
      const { payload } = await dispatch(getSuccessFee(data));
      if (payload?.status === "success") {
        setSuccessFees(payload?.successFees);
        setCoinCharge(payload?.coin);
        setOpenRecruitBox(true);
        if (selectedCompanyRecruit !== "") {
          // setCoinCharge(100);
          // if (isRecruitVideoNeeded) {
          //   setCoinCharge(10 + 10);
          // }
          // if (isInterviewNeeded) {
          //   setCoinCharge(10 + 10);
          // }
          // if (isInterviewNeeded && isRecruitVideoNeeded) {
          //   setCoinCharge(10 + 20);
          // }
          // if (!isInterviewNeeded && !isRecruitVideoNeeded) {
          //   setCoinCharge(10);
          // }
          // setCoinCharge(payload?.coin);
          // setOpenRecruitBox(true);
        } else {
          handleRecruitBox();
        }
      }
    } catch (error) {}
  };

  const handleDirectBox = () => {
    let industries;
    let country;
    let town;
    if (selectedCompanyDirect) {
      industries = companyList?.find(
        (item) => item?.company_id === selectedCompanyDirect
      )?.industry;
      country = companyList?.find(
        (item) => item?.company_id === selectedCompanyDirect
      )?.country;
      town = companyList?.find(
        (item) => item?.company_id === selectedCompanyDirect
      )?.town;
    } else {
      industries = companyList?.find((item) => item?.default)?.[0]?.industry;
      country = companyList?.filter((item) => item?.default)?.[0]?.country;
      town = companyList?.filter((item) => item?.default)?.[0]?.town;
    }
    const newJobTypeDetails = {
      ...selectedJobTypeDetails,
      is_video_require: isDirectVideoNeeded,
      is_interview_require: false,
      post_incognito: isDirectPostPublicNeeded,
      job_type: "Direct",
      company_id: selectedCompanyDirect
        ? selectedCompanyDirect
        : companyList?.filter((item) => item?.default)?.[0]?.company_id,
      industry_id: industries?.map((item) => {
        return { label: item?.industry?.name, value: item?.industry_id };
      }),
      country_id: { label: country?.country_name, value: country?.country_id },
      town_id: { label: town?.town_name, value: town?.town_id },
      success_fees: successfees,
    };
    selectedCompanyDirect
      ? setIsSelectedDirect(false)
      : setIsSelectedDirect(true);
    setSelectedCompanyRecruit(undefined);
    handleComplete();
    if (isEditPostjob) {
      saveBasicJobType("basic", newJobTypeDetails);
    } else {
      saveBasic(newJobTypeDetails);
    }
    selectedCompanyDirect && changeStep(1);
    handleJobTypeDetails(newJobTypeDetails);
  };

  const handleRecruitBox = () => {
    let industries;
    let country;
    let town;
    if (selectedCompanyRecruit) {
      industries = companyList?.find(
        (item) => item?.company_id === selectedCompanyRecruit
      )?.industry;
      country = companyList?.find(
        (item) => item?.company_id === selectedCompanyRecruit
      )?.country;
      town = companyList?.find(
        (item) => item?.company_id === selectedCompanyRecruit
      )?.town;
    } else {
      industries = companyList?.find((item) => item?.default)?.[0]?.industry;
      country = companyList?.filter((item) => item?.default)?.[0]?.country;
      town = companyList?.filter((item) => item?.default)?.[0]?.town;
    }
    const newJobTypeDetails = {
      ...selectedJobTypeDetails,
      job_type: "Recruit",
      is_video_require: isRecruitVideoNeeded,
      is_interview_require: isInterviewNeeded,
      post_incognito: isRecruitPostPublicNeeded,
      company_id: selectedCompanyRecruit
        ? selectedCompanyRecruit
        : companyList?.filter((item) => item?.default)?.[0]?.company_id,
      industry_id: industries?.map((item) => {
        return { label: item?.industry?.name, value: item?.industry_id };
      }),
      country_id: { label: country?.country_name, value: country?.country_id },
      town_id: { label: town?.town_name, value: town?.town_id },
      success_fees: successfees,
    };
    selectedCompanyRecruit
      ? setIsSelectedRecruiter(false)
      : setIsSelectedRecruiter(true);
    setSelectedCompanyDirect(undefined);
    handleComplete();
    if (isEditPostjob) {
      saveBasicJobType("basic", newJobTypeDetails);
    } else {
      saveBasic(newJobTypeDetails);
    }
    selectedCompanyRecruit && changeStep(1);
    handleJobTypeDetails(newJobTypeDetails);
  };
useEffect(()=>{
  if(basicData?.job_type ==="Direct"){
    setDirectButton(true)
  } if(basicData?.job_type === "Recruit"){
    setRecruitButton(true)
  }

},[basicData])
  useEffect(() => {
    getCompanyList();
  }, []);
  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          gap: 2,
          marginBottom: 5,
        }}
      >
        <Box
          sx={{
            display: "flex",
            gap: "42px",
            justifyContent: "space-between",
            width: "100%",
            flexDirection: { xs: "column", md24: "row" },
          }}
        >
          {/* Crayon Direct */}
          <Box
            sx={{
              background: "#FFFFFF",
              boxShadow: "0px 5px 10px #00000029",
              borderRadius: "25px",
              width: "100%",
              border: directButton
                ? `5px solid ${theme.palette.crayonDirectButton.main}`
                : `1px solid ${theme.palette.lightGrey.main}`,
              borderBottom: directButton ? "" : 0,
              // minHeight: crayonDirectExpand ? "1290px" : "682px",
              height: "fit-content",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Button
                variant="contained"
                color="crayonDirectButton"
                sx={{
                  padding: "0px",
                  borderRadius: "25px 0px 10px 0",
                  display: "flex",
                  alignItems: "center",
                  gap: "8px",
                  height: "45px",
                  width: "150px",
                  ":hover": {
                    boxShadow: "none",
                  },
                }}
              >
                <Box
                  component={"img"}
                  src={crayonDirect}
                  sx={{ width: 18, height: 20 }}
                />
                <Typography
                  sx={{
                    fontSize: theme.typography.fontSize.sm,
                    fontWeight: theme.typography.Bold,
                  }}
                >
                  Crayon Direct
                </Typography>
              </Button>
            </Box>

            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                // padding: "30px",
                // gap: "20px",
                flexGrow: 1,
              }}
            >
              <CrayonDirectFirstBox
                crayonDirectExpand={crayonDirectExpand}
                handleImReadyBtn={handleImReadyBtn}
                handleCrayonDirectExpand={handleCrayonDirectExpand}
                setDirectButton={setDirectButton}
                directButton={directButton}
                isDirectVideoNeeded={isDirectVideoNeeded}
                setisDirectVideoNeeded={setisDirectVideoNeeded}
                isDirectPostPublicNeeded={isDirectPostPublicNeeded}
                setisDirectPostPublicNeeded={setisDirectPostPublicNeeded}
                isSelectedDirect={isSelectedDirect}
                handleClickDirect={handleClickDirect}
                selectedCompanyDirect={selectedCompanyDirect}
                companyList={companyList}
                idDirect={idDirect}
                openDirect={openDirect}
                anchorElDirect={anchorElDirect}
                handleCloseDirect={handleCloseDirect}
                setBasicData={setBasicData}
                basicData={basicData}
                setSelectedCompanyDirect={setSelectedCompanyDirect}
                setSelectedCompanyRecruit={setSelectedCompanyRecruit}
                handleDynamicTheme={handleDynamicTheme}
                setIsSelectedDirect={setIsSelectedDirect}
                companyListLoading={companyListLoading}
                setRecruitButton={setRecruitButton}
                setCrayonRecruitExpand={setCrayonRecruitExpand}
              />
            </Box>
          </Box>
          {/* Crayon Direct */}
          {/* Crayon Recruit */}
          <Box
            sx={{
              background: "#FFFFFF",
              boxShadow: "0px 5px 10px #00000029",
              borderRadius: "25px",
              width: "100%",
              // minHeight: crayonRecruitExpand ? "1290px" : "682px",
              border: recruitButton
                ? `5px solid ${theme.palette.crayonRecrutitButton.main}`
                : `1px solid ${theme.palette.lightGrey.main}`,
              borderBottom: recruitButton ? "" : 0,
              height: "fit-content",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Button
                variant="contained"
                color="crayonRecrutitButton"
                sx={{
                  padding: "0px",
                  borderRadius: "25px 0px 10px 0",
                  display: "flex",
                  alignItems: "center",
                  gap: "8px",
                  height: "45px",
                  width: "150px",
                  ":hover": {
                    boxShadow: "none",
                  },
                }}
              >
                <Box
                  component={"img"}
                  src={crayonRecruit}
                  sx={{ width: 18, height: 20 }}
                />
                <Typography
                  sx={{
                    fontSize: theme.typography.fontSize.sm,
                    fontWeight: theme.typography.Bold,
                  }}
                >
                  Crayon Recruit
                </Typography>
              </Button>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                // padding: "30px",
                // gap: "20px",
                flexGrow: 1,
              }}
            >
              <CrayonRecruitBox
                setCrayonDirectExpand={setCrayonDirectExpang}
                crayonRecruitExpand={crayonRecruitExpand}
                findMyDreamBtn={findMyDreamBtn}
                handleCrayonRectuitExpand={handleCrayonRectuitExpand}
                setRecruitButton={setRecruitButton}
                recruitButton={recruitButton}
                setDirectButton={setDirectButton}
                isRecruitVideoNeeded={isRecruitVideoNeeded}
                setisRecruitVideoNeeded={setisRecruitVideoNeeded}
                isInterviewNeeded={isInterviewNeeded}
                setIsInterviewNeeded={setIsInterviewNeeded}
                isRecruitPostPublicNeeded={isRecruitPostPublicNeeded}
                setisRecruitPostPublicNeeded={setisRecruitPostPublicNeeded}
                isSelectedRecruiter={isSelectedRecruiter}
                handleClickRecruit={handleClickRecruit}
                selectedCompanyRecruit={selectedCompanyRecruit}
                companyList={companyList}
                idRecruit={idRecruit}
                openRecruit={openRecruit}
                anchorElRecruit={anchorElRecruit}
                handleCloseRecruit={handleCloseRecruit}
                setBasicData={setBasicData}
                basicData={basicData}
                setSelectedCompanyDirect={setSelectedCompanyDirect}
                setSelectedCompanyRecruit={setSelectedCompanyRecruit}
                handleDynamicTheme={handleDynamicTheme}
                setIsSelectedRecruiter={setIsSelectedRecruiter}
                companyListLoading={companyListLoading}
              />
            </Box>
          </Box>
          {/* Crayon Recruit */}
        </Box>
      </Box>
      {openDirectBox && (
        <CoinSpendBox
          text1={i18n["myplanPopup.thisjobpostisfree"]}
          text1Width={"248px"}
          text2={i18n["myplanPopup.itwillcost"]}
          text2a={i18n["myplanPopup.tounlock"]}
          coinsBalance={`${coinCharge} coins`}
          text2Width={"248px"}
          text3={i18n["myplanPopup.youcanreview"]}
          text3Width={"320px"}
          buttonText={i18n["myplanPopup.okgotit"]}
          buttonText2={i18n["myplanPopup.edit"]}
          handleClose={() => setOpenDirectBox(false)}
          show={openDirectBox}
          buttonClick={handleDirectBox}
          gap={"18px"}
        />
      )}
      {openRecruitBox && (
        <CoinSpendBox
          text1={i18n["myplanPopup.thisjobpostisfree"]}
          text1Width={"248px"}
          text2={i18n["myplanPopup.itwillcost"]}
          text2a={i18n["myplanPopup.tounlock"]}
          coinsBalance={`${coinCharge} coins`}
          coinsUse={`${successfees}%`}
          text2Width={"248px"}
          text3={i18n["myplanPopup.theCrayonRecruitsuccessfee"]}
          text4={i18n["myplanPopup.candidateCTC"]}
          text3Width={"262px"}
          text5={i18n["myplanPopup.thejobwillbeunlocked"]}
          text5Width={"318px"}
          buttonText={i18n["myplanPopup.okgotit"]}
          buttonText2={i18n["myplanPopup.edit"]}
          handleClose={() => setOpenRecruitBox(false)}
          show={openRecruitBox}
          buttonClick={handleRecruitBox}
          gap={"25px"}
          display=""
        />
      )}
    </>
  );
};

export default JobType;
