import {
  Box,
  Button,
  Typography,
  useTheme,
  styled,
  Tooltip,
  tooltipClasses,
  Switch,
} from "@mui/material";
import React from "react";
import CustomDialog from "../../../common/CustomDialog";
import SelectMenu from "../../../common/SelectMenu";
import { JOB_TYPE } from "../../../../utils/Constants";

export default function JobType({
  openEdit,
  handleClose,
  postJobData,
  setPostJobData,
  handleNextStep,
  errors,
  handleBack,
}) {
  const theme = useTheme();
  const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip disableInteractive {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.white,
      color: "rgba(0, 0, 0, 0.87)",
      boxShadow: theme.shadows[1],
      fontSize: 11,
      maxWidth: 180,
      borderRadius: 15,
      padding: "16px 12px",
    },
  }));

  const commonSwitchStyle = {
    width: "90px",
    height: "35px",
    borderRadius: "25px",
    padding: 0,
    border: "1px solid",
    borderColor: "#C9CAD8",
    "& .MuiSwitch-track": {
      backgroundColor: "#F2F4FB",
    },

    "& .MuiSwitch-switchBase.Mui-checked": {
      color: "#FFFFFF",
      transform: "translateX(55px)",
      padding: "3px",
    },
    "& .MuiSwitch-thumb": {
      height: "28px",
      width: "28px",
    },
  };

  const handleChange = (event) => {
    const {
      target: { value },
      target: { name },
      target: { id },
    } = event;
    const newPostJobData = {
      ...postJobData,
      [name || id]: JOB_TYPE?.find((type) => type?.id === value)?.name || "",
    };
    setPostJobData(newPostJobData);
  };
  return (
    <CustomDialog
      show={openEdit}
      hideButton={false}
      dialogWidth="xs"
      showFooter={false}
      onDialogClose={handleClose}
      padding={0}
    >
      <Typography
        sx={{
          fontSize: "18px",
          fontWeight: theme.typography.Bold,
          textAlign: "center",
        }}
      >
        Select Job Type
      </Typography>
      <Box
        sx={{
          display: "flex",
          width: "100%",
          justifyContent: "center",
          alignItems: "center",
          gap: "10px",
          padding: "16px 0",
        }}
      >
        <SelectMenu
          sx={{
            width: "50%",
          }}
          borderRadius="10px"
          name="job_type"
          onHandleChange={(event) => handleChange(event)}
          options={JOB_TYPE}
          clear={true}
          border={
            errors?.find((error) => error?.key === "job_type")
              ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
              : `1px solid ${theme.palette.borderColor}`
          }
          placeholder={"Select a job type"}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "16px",
          padding: postJobData?.job_type ? "16px" : "",
        }}
      >
        {postJobData?.job_type === "Recruit" && (
          <>
            <Box
              sx={{
                display: "flex",
                gap: "10px",
                width: "100%",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography
                sx={{
                  fontSize: "14px",
                  fontWeight: theme.typography.Regular,
                  width: "40%",
                  textAlign: "end",
                }}
              >
                we don’t need videos
              </Typography>
              <Switch
                checked={postJobData?.is_video_require}
                onClick={(event) => {
                  setPostJobData({
                    ...postJobData,
                    is_video_require: event?.target?.checked,
                    jobDetails: {
                      ...postJobData?.jobDetails,
                      is_video_require: event?.target?.checked,
                    },
                  });
                }}
                sx={{
                  ...commonSwitchStyle,
                  "& .MuiSwitch-switchBase": {
                    color: "RecruitToggleButton",
                    padding: "3px",
                  },
                  backgroundColor: !postJobData?.is_video_require
                    ? "#F2F4FB"
                    : "#009AE0",
                }}
              />
              <Typography
                sx={{
                  fontSize: "14px",
                  fontWeight: theme.typography.Regular,
                  width: "40%",
                  textAlign: "start",
                }}
              >
                include video applications
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                gap: "10px",
                justifyContent: "center",
                width: "100%",
                alignItems: "center",
              }}
            >
              <Typography
                sx={{
                  fontSize: "14px",
                  fontWeight: theme.typography.Regular,
                  width: "40%",
                  textAlign: "end",
                }}
              >
                we don’t need interviews
              </Typography>
              <Switch
                checked={postJobData?.is_interview_require}
                onClick={(event) => {
                  setPostJobData({
                    ...postJobData,
                    is_interview_require: !postJobData?.is_interview_require,
                  });
                }}
                sx={{
                  ...commonSwitchStyle,
                  "& .MuiSwitch-switchBase": {
                    color: "RecruitToggleButton",
                    padding: "3px",
                  },
                  backgroundColor: !postJobData?.is_interview_require
                    ? "#F2F4FB"
                    : "#009AE0",
                }}
              />

              <Typography
                sx={{
                  fontSize: "14px",
                  fontWeight: theme.typography.Regular,
                  width: "40%",
                  textAlign: "start",
                }}
              >
                include recorded interviews
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                gap: "10px",
                width: "100%",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography
                sx={{
                  fontSize: "14px",
                  fontWeight: theme.typography.Regular,
                  width: "40%",
                  textAlign: "end",
                }}
              >
                public post
              </Typography>
              <Switch
                checked={postJobData?.post_incognito}
                onClick={() => {
                  setPostJobData({
                    ...postJobData,
                    post_incognito: !postJobData?.post_incognito,
                  });
                }}
                sx={{
                  ...commonSwitchStyle,
                  "& .MuiSwitch-switchBase": {
                    color: "RecruitToggleButton",
                    padding: "3px",
                  },
                  backgroundColor: !postJobData?.post_incognito
                    ? "#F2F4FB"
                    : "#009AE0",
                }}
              />
              <Typography
                sx={{
                  fontSize: "14px",
                  fontWeight: theme.typography.Regular,
                  width: "40%",
                  display: "flex",
                  gap: "10px",
                  textAlign: "start",
                }}
              >
                post incognito
                <Tooltip disableInteractive>
                  <LightTooltip
                    title={"Post your job in public mode or incognito mode"}
                    placement="right-end"
                  >
                    <Typography
                      sx={{
                        height: "17px",
                        width: "17px",
                        borderRadius: "5px",
                        fontSize: "12px",
                        lineHeight: 0.1,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        fontWeight: theme.typography.Bold,
                        boxShadow: "0px 2px 4px #00000029",
                        border: 1,
                      }}
                    >
                      i
                    </Typography>
                  </LightTooltip>
                </Tooltip>
              </Typography>
            </Box>
          </>
        )}
        {postJobData?.job_type === "Direct" && (
          <>
            <Box
              sx={{
                display: "flex",
                gap: "10px",
                width: "100%",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography
                sx={{
                  fontSize: "14px",
                  fontWeight: theme.typography.Regular,
                  width: "40%",
                  textAlign: "end",
                }}
              >
                we don’t need videos
              </Typography>
              <Switch
                checked={postJobData?.is_video_require}
                onClick={(event) => {
                  setPostJobData({
                    ...postJobData,
                    is_video_require: event.target.checked,
                    jobDetails: {
                      ...postJobData?.jobDetails,
                      is_video_require: event?.target?.checked,
                    },
                  });
                }}
                sx={{
                  ...commonSwitchStyle,
                  "& .MuiSwitch-switchBase": {
                    color: "DirectToggleButton",
                    padding: "3px",
                  },
                  backgroundColor: !postJobData?.is_video_require
                    ? "DefaultToggleColor"
                    : "DirectToggleButton",
                }}
              />
              <Typography
                sx={{
                  fontSize: "14px",
                  fontWeight: theme.typography.Regular,
                  width: "40%",
                  textAlign: "start",
                }}
              >
                include video applications
              </Typography>
            </Box>

            <Box
              sx={{
                display: "flex",
                gap: "10px",
                width: "100%",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography
                sx={{
                  fontSize: "14px",
                  fontWeight: theme.typography.Regular,
                  width: "40%",
                  textAlign: "end",
                }}
              >
                public post
              </Typography>
              <Switch
                checked={postJobData?.post_incognito}
                onClick={(event) => {
                  setPostJobData({
                    ...postJobData,
                    post_incognito: event.target.checked,
                  });
                }}
                sx={{
                  ...commonSwitchStyle,
                  "& .MuiSwitch-switchBase": {
                    color: "DirectToggleButton",
                    padding: "3px",
                  },
                  backgroundColor: !postJobData?.post_incognito
                    ? "DefaultToggleColor"
                    : "DirectToggleButton",
                }}
              />
              <Typography
                sx={{
                  fontSize: "14px",
                  fontWeight: theme.typography.Regular,
                  width: "40%",
                  display: "flex",
                  gap: "10px",
                  textAlign: "start",
                }}
              >
                post incognito
                <Tooltip disableInteractive>
                  <LightTooltip
                    title={"Post your job in public mode or incognito mode"}
                    placement="right-end"
                  >
                    <Typography
                      sx={{
                        height: "17px",
                        width: "17px",
                        borderRadius: "5px",
                        fontSize: "12px",
                        lineHeight: 0.1,
                        /* text-align: center; */
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        fontWeight: theme.typography.Bold,
                        boxShadow: "0px 2px 4px #00000029",
                        border: 1,
                      }}
                    >
                      i
                    </Typography>
                  </LightTooltip>
                </Tooltip>
              </Typography>
            </Box>
          </>
        )}
      </Box>
      <Box>
        <Button
          sx={{
            boxShadow: 0,
            fontSize: "14px",
            width: "50%",
            borderRadius: 0,
            height: "43px",
            color: "black",
            padding: 3,
          }}
          color="submitButton"
          variant="contained"
          onClick={handleBack}
        >
          back
        </Button>
        <Button
          sx={{
            boxShadow: 0,
            fontSize: "14px",
            width: "50%",
            borderRadius: 0,
            height: "43px",
            padding: 3,
          }}
          variant="contained"
          color="redButton100"
          onClick={handleNextStep}
        >
          next
        </Button>
      </Box>
    </CustomDialog>
  );
}
