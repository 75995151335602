import Button from "@mui/material/Button";
import { Tooltip } from "@mui/material";
import { useEffect, useRef } from "react";
import { isEqual } from "lodash";

export default function SmallButtonSlider({
  color,
  label,
  startIcon,
  endIcon,
  textColor,
  height = 20,
  startIconMargin,
  onClick,
  value,
  justifyContent,
  disabled,
  boxShadow,
  backgroundColor,
  fontSize = 10,
  fontWeight = 700,
  setButtonWidth,
  buttonWidth,
  ...props
}) {
  const buttonRef = useRef(null);

  useEffect(() => {
    if (buttonRef?.current) {
      const rect = buttonRef?.current?.getBoundingClientRect();
    

      const updatedButtonWidth = buttonWidth?.map((button) =>
        button.name === label ? { ...button, width: rect?.width } : button
      );

      if (!isEqual(buttonWidth, updatedButtonWidth)) {
        setButtonWidth(updatedButtonWidth);
      }
    }
  }, [label, buttonWidth, setButtonWidth]);
  return (
    <Tooltip
      disableInteractive
      title={value ? value : label}
      placement="top-end"
    >
      <Button
        className="tagbutton"
        ref={buttonRef}
        variant="contained"
        disabled={disabled}
        color={color}
        startIcon={startIcon}
        endIcon={endIcon}
        onClick={onClick}
        sx={{
          justifyContent: justifyContent,
          fontSize: fontSize,
          fontWeight: fontWeight,
          letterSpacing: "0.75px",
          height: height,
          boxShadow: 0,
          borderRadius: "5px",
          color: textColor,
          backgroundColor: backgroundColor,
          minWidth: "fit-content",
          padding: "0 8px",
          ".MuiButton-startIcon": {
            marginRight: startIconMargin,
            marginLeft: 0,
          },
          ":hover": {
            boxShadow: "none",
            backgroundColor: backgroundColor,
          },
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          ...props,
        }}
      >
        {label}
      </Button>
    </Tooltip>
  );
}
