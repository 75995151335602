import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import { useTheme } from "@emotion/react";

export default function InputBox({
  id,
  placeholder,
  sx,
  type = "text",
  value,
  onChange,
  disabled = false,
  multiline = false,
  readOnly,
  defaultValue = "",
  rowNumber,
  min
}) {
  const theme = useTheme();
  return (
    <Paper
      elevation={3}
      sx={{
        display: "flex",
        borderRadius: "25px",
        height: "40px !important",
        boxShadow: "none",
        border: `1px solid ${theme.palette.grayBorder}`,
        ...sx,
      }}
    >
      <InputBase
        readOnly={readOnly}
        sx={{ ml: 2, mr: 2, width: "100%",fontSize: "14px" }}
        id={id}
        placeholder={placeholder}
        type={type}
        value={value}
        defaultValue={defaultValue}
        onChange={onChange}
        disabled={disabled}
        multiline={multiline}
        rows={rowNumber?rowNumber: multiline ? 4 : 1}
        inputProps={{ min }}
      />
    </Paper>
  );
}
