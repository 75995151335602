import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Typography,
} from "@mui/material";
import React from "react";
import MUIRadialChart from "./MUIRadialChart";
import strongMatch from "../../assets/CrayBotIcons/Crayon Craybot Notification-07.svg";
import averageMatch from "../../assets/CrayBotIcons/Crayon Craybot Notification-09.svg";
import weakMatch from "../../assets/CrayBotIcons/Crayon Craybot Notification-05.svg";
import matchMeIcon from "../../assets/Padding Excluded/Black_Match_me.svg";
import applyIcon from "../../assets/Padding Excluded/Black_Follower.svg";
import locale from "../../i18n/locale";
import CloseIcon from "./CloseIcon";
import { useTheme } from "@emotion/react";

export default function JobDetailMatchBox({
  setOpenMatchMeBox,
  match,
  job,
  handleApplyClick,
}) {
  const label6 = "match";
  const i18n = locale.en;
  const theme = useTheme();

  const getColor = (value) => {
    if (value <= 24) {
      return "weakMatch";
    } else if (value <= 74) {
      return "averageMatch";
    } else {
      return "strongMatch";
    }
  };

  const calculateMatchHeight = () => {
    const screenWidth = window.innerWidth;

    if (screenWidth < 600) {
      // xs
      return 90;
    } else if (screenWidth < 900) {
      // sm
      return 90;
    } else if (screenWidth < 1366) {
      // md
      return 90;
    } else if (screenWidth < 1789) {
      return 100;
    } else if (screenWidth < 1920) {
      // lg
      return 120;
    } else if (screenWidth < 2559) {
      // xl
      return 135;
    } else {
      // xxl or greater
      return 135;
    }
  };
  return (
    <>
      {/* <IconButton
        aria-label="close"
        onClick={() => {
          setOpenMatchMeBox(false);
        }}
        sx={{
          position: "absolute",
          right: 5,
          top: 5,
          color: (theme) => theme.palette.grey[500],
        }}
        disableRipple={true}
      >
        <Box
          sx={{
            width: "20px",
            height: "20px",
            color: "#000000",
            border: 2,
            fontSize: "18px",
            borderRadius: "5px",
          }}
        >
          X
        </Box>
      </IconButton> */}
      <CloseIcon
        top="15px"
        right="15px"
        onClick={() => {
          setOpenMatchMeBox(false);
        }}
      />

      <Button
        variant="contained"
        // color={"matchTitleBox"}
        color={job?.job_type === "Direct" ? "checkers" : "yoco"}
        sx={{
          borderRadius: "25px 0 10px 0",
          fontSize: "15px",
          fontWeight: "bold",
          gap: 0,
          position: "absolute",
          padding: "6px 7px",
          width: "150px",
          maxWidth: "150px",
          height: "45px",
          ":hover": {
            boxShadow: 0,
          },
          cursor: "default",
        }}
        startIcon={
          <Box
            component={"img"}
            sx={{
              height: "20px",
              width: "20px",
            }}
            src={matchMeIcon}
          />
        }
      >
        {i18n["matchMeBox.beta"]}
      </Button>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: { xs: "center", xl: "start" },
          flexDirection: "column",
          gap: 3,
          // padding: "22.5px 15px 0 15px",
          // marginBottom: "7px",
        }}
      >
        {match ? (
          <>
            <Box sx={{ padding: "22.5px 15px 0 15px" }}>
              <Box
                sx={{
                  width: "100%",
                  paddingTop: "24.5px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    marginTop: "10px",
                    marginBottom: "10px",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "14px",
                      fontWeight: 700,
                    }}
                  >
                    {i18n["matchMeBox.matchPer"]}
                  </Typography>
                </Box>
                <Typography
                  sx={{
                    fontSize: "12px",
                    fontWeight: theme.typography.Regular,
                  }}
                >
                  {i18n["matchMeBox.craybotInfo"]}
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    gap: "10px",
                    marginTop: "10px",
                    alignItems: "center",
                  }}
                >
                  <Box
                    component={"img"}
                    src={
                      match?.matchingPercentage <= 25
                        ? weakMatch
                        : match?.matchingPercentage <= 75
                        ? averageMatch
                        : strongMatch
                    }
                    sx={{
                      height: "50px",
                      width: "30px",
                      objectFit: "cover",
                    }}
                  />
                  <Typography
                    sx={{
                      fontSize: "14px",
                      fontWeight: 700,
                    }}
                  >
                    Remember, the more you complete, the stronger you can
                    compete!
                  </Typography>
                </Box>
              </Box>
              <Box sx={{width:"100%",textAlign:"center",display:"flex",alignItems:"center",flexDirection:"column", marginY:"15px"}}>
              <MUIRadialChart
                backgroundRingColor="darkGray"
                size={calculateMatchHeight()}
                chartName={label6}
                value={match.matchingPercentage}
                max={100}
                color={getColor(match.matchingPercentage)}
                formattedValue={`${match.matchingPercentage} %`}
                countFontSize={16}
                labelFontSize={14}
              />
              </Box>
             
              <Typography
                sx={{
                  fontSize: "14px",
                  fontWeight: 700,
                  textAlign: "center",
                }}
              >
                {match?.matchingPercentage <= 25
                  ? `Craybot thinks you’re a weak match for this role!`
                  : match?.matchingPercentage <= 75
                  ? `CrayBot thinks you’re an average match for this role! Apply if you feel you fit the criteria.`
                  : `Craybot thinks you’re a strong match for this role!`}
              </Typography>
            </Box>
            <Box sx={{ width: "100%", }}>
              <Button
                variant="contained"
                color="nandos"
                sx={{
                  width: "100%",
                  height: "60px",
                  borderRadius: "0 0 25px 25px ",
                  fontSize: "14px",
                  fontWeight: theme?.typography?.Bold,
                }}
                disabled={job?.job_status?.job_status_id === 4 ? true : false}
                onClick={handleApplyClick}
                startIcon={
                  <Box
                    component={"img"}
                    sx={{
                      height: "20px",
                      width: "24px",
                    }}
                    src={applyIcon}
                  />
                }
              >
                {job?.applidflag === true ? "applied" : "apply"}
              </Button>
            </Box>
          </>
        ) : (
          <Box
            height="161px"
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <CircularProgress sx={{ color: "grey.500" }} color="inherit" />
          </Box>
        )}
      </Box>
    </>
  );
}
