import React from "react";
import { Box, Button, Typography, useTheme } from "@mui/material";
import profile_collaborator from "../../../assets/Personality Logos/crayon_personality_collaborator.svg";
import profile_contemplator from "../../../assets/Personality Logos/crayon_personality_contemplator.svg";
import leftIcon from "../../../assets/Black_Left_Previous - Copy.svg";
import rightIcon from "../../../assets/Black_Right_Next - Copy.svg";
import profile_challenger from "../../../assets/Personality Logos/personality_characters_challenger.svg";
// import profile_challenger from "../../../assets/Personality Logos/crayon_personality_grey_challeneger.svg";
import profile_character from "../../../assets/Personality Logos/crayon_personality_character.svg";
import grey_profile_collaborator from "../../../assets/Personality Logos/crayon_personality_grey_collaborator.svg";
import grey_profile_contemplator from "../../../assets/Personality Logos/crayon_personality_grey_contemplator.svg";
import MUIRadialChart from "../../common/MUIRadialChart";
import flip from "../../../assets/NewFlipIcon.svg";
import SmallButton from "../../common/SmallButton";
import { setCurrentStep } from "../../../redux/candidate/myProfileSlice";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import takeQuiz from "../../../assets/TakeQuizIcon.svg";
import { getDecodedToken, getToken } from "../../../utils/Common";

export default function CandidatePersonalityFrontCard({
  profile,
  handleLeftClick,
  arrSlider2,
  handleRightClick,
  calculateHeight,
  setisHorizontalFlipped,
}) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const decodedToken = getDecodedToken(getToken());
  return (
    <>
      <Box
        sx={{
          height: { xs: "300px", tablet: "225px", lg: "280px", mlg: "225px" },
          // width: { xs: "360px", lg: "750px", xl: "750px" },
          background: "#ffff",
          boxShadow: "0px 5px 10px #00000029",
          borderRadius: "25px",
          flex: "1",
        }}
      >
        <Box>
          <Button
            variant="contained"
            color="yoco"
            sx={{
              height: "45px",
              minWidth: "125px",
              maxWidth: "125px",
              fontSize: "14px",
              padding: "0px",
              fontWeight: theme.typography.Bold,
              borderRadius: "25px 0px 10px 0px",
            
              cursor: "default",
              ":hover": {
                boxShadow: "none !important",
              },
            }}
          >
            my personality
          </Button>
        </Box>

        <Box
          sx={{
            display: { xs: "flex" },
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            gap: "18px",
            // width: {
            //   xs360: "90%",
            //   xs390: "87%",
            //   sm: "100%",
            //   lgmm: "50%",
            //   lg: "100% !important",
            // },
            // minWidth: {
            //   xs360: "90%",
            //   xs390: "87%",
            //   sm: "100%",
            //   lgmm: "50%",
            //   lg: "100% !important",
            // },
            width: "100%",
            boxSizing: "border-box",
          }}
        >
          {profile?.primary === null && profile?.shadow === null ? (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                width: "100%",
                justifyContent: "center",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: {
                    sm: "3px",
                    tb: "30px",
                    lgmm: "10px",
                    mlg: "47px",
                    xl: "47px",
                    xll: "47px",
                  },
                  overflow: "hidden",
                }}
              >
                {arrSlider2?.map((item) => {
                  return (
                    <Box
                      component="img"
                      height={{
                        xs: 90,
                        tb: 130,
                        md24: 130,
                        lgmm: 110,
                        mlg: 110,
                        xl: 125,
                        xxl: 150,
                      }}
                      alt="Primary personality"
                      src={item}
                    />
                  );
                })}
              </Box>
            </Box>
          ) : (
            <Box
              sx={{
                //   padding: {
                //     sm: "0px",
                //     lgmm: "0px 10px",
                //     mlg: "0px 10px",
                //     xl: "0px 10px",
                //   },
                display: "flex",
                alignItems: "center",
                justifyContent: {
                  xs: "center",
                  tablet: "flex-start",
                  md: "center",
                },
                gap: {
                  sm: "3px",
                  tb: "30px",
                  lgmm: "10px",
                  mlg: "47px",
                  xl: "47px",
                  xll: "47px",
                },
                width: { xs: "100%", tablet: "71%", md: "100%" },
              }}
            >
              <Box
                component="img"
                height={calculateHeight()}
                alt="Primary personality"
                src={
                  (profile?.primary?.name === "collaborator" &&
                    profile_collaborator) ||
                  (profile?.primary?.name === "challenger" &&
                    profile_challenger) ||
                  (profile?.primary?.name === "character" &&
                    profile_character) ||
                  (profile?.primary?.name === "contemplator" &&
                    profile_contemplator) ||
                  grey_profile_collaborator
                }
              />
              <Box
                component="img"
                height={calculateHeight()}
                alt="Shadow personality"
                src={
                  (profile?.shadow?.name === "collaborator" &&
                    profile_collaborator) ||
                  (profile?.shadow?.name === "challenger" &&
                    profile_challenger) ||
                  (profile?.shadow?.name === "character" &&
                    profile_character) ||
                  (profile?.shadow?.name === "contemplator" &&
                    profile_contemplator) ||
                  grey_profile_contemplator
                }
              />

              <MUIRadialChart
                value={profile?.grit_score}
                chartName={"grit score"}
                max={100}
                size={calculateHeight()}
                countFontSize={20}
                countFontWeight={theme.typography.Bold}
                labelFontSize={12}
                labelFontWeight={theme.typography.Regular}
                color={"grit_score"}
              />
            </Box>
          )}
          <Box
            sx={{
              display: "flex",
              gap: "5px",
              flexWrap: "wrap",
              width: {
                md24: `calc(100% - 200px)`,
                lg: "100%",
                mlg: `calc(100% - 200px)`,
              },
              padding: { xs: "0 40px", tablet: "0px" },
              justifyContent: { md24: "center", mlg: "flex-start" },
              // padding: "0 15px 15px 15px",
            }}
          >
            {profile?.traits?.map((item) => {
              return (
                <SmallButton
                  fontWeight={700}
                  minWidth="10px"
                  textColor={theme?.palette?.base?.main}
                  height={25}
                  color="purpleButton"
                  borderRadius="5px"
                  label={item}
                  fontSize={"12px"}
                ></SmallButton>
              );
            })}
          </Box>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            position: "absolute",
            top: 0,
            right: 0,
            alignItems: "flex-end",
            height: "100%",
            // marginBottom: "20px",
          }}
        >
          <Button
            variant="contained"
            color="precium"
            sx={{
              height: "45px",
              width: "125px",
              // fontSize: theme.typography.fontSize.xs,
              fontSize: "12px",
              fontWeight: theme.typography.Bold,
              borderRadius: "10px 0px 25px 0px",
              padding: "0px",
              ".MuiButton-startIcon": {
                marginRight: "4px !important",
                marginLeft: 0,
              },
              ":hover": {
                boxShadow: "none !important",
              },
            }}
            onClick={() => {
              if(decodedToken?.data?.role_id === 5){
                dispatch(setCurrentStep(4));
                navigate("/assessment");
              }else{
                dispatch(setCurrentStep(4));
                navigate("/candidate/assessment");
              }
            }}
            startIcon={
              <Box
                component={"img"}
                src={takeQuiz}
                height={20}
                width={20}
              />
            }
          >
            take the quiz
          </Button>
        </Box>
      </Box>
      <Button
        variant="contained"
        color="redButton100"
        sx={{
          width: "20px",
          height: "100px",
          padding: 0,
          position: "absolute",
          right: 0,
          top: `calc(50% - 50px)`,
          minWidth: "20px",
          fontSize: "20px",
          borderRadius: "10px 0 0 10px",
        }}
        onClick={() => setisHorizontalFlipped(true)}
      >
        <Box
          component={"img"}
          src={flip}
          sx={{
            height: "20px",
            width: "15px",
          }}
        />
      </Button>
    </>
  );
}
