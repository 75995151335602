import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import { useTheme } from "@mui/material/styles";
import { truncate } from "lodash";
import GenderSection from "./GenderSection";
import Race from "./Race";
import SalaryBox from "./SalaryBox";
import QualificationBox from "./QualificationBox";
import rightArrow from "../../assets/Black_Right_Next - Copy.svg";
import leftArrow from "../../assets/Black_Left_Previous - Copy.svg";
import ExperienceBox from "./ExperienceBox";
import { Popover } from "@mui/material";
import { useQuery } from "../../utils/Common";
import { skinValue } from "../../utils/Constants";

export default function EmployerButtonPanel({
  panelData,
  side,
  color,
  onChangeFilter = () => {},
  setGender,
  setQualification,
  setSkinColor,
  skinColor,
  setSalary,
  setExperience,
  selected,
  handleFilterSelection,
  setIsFilterApplied,
  getTalentMyJobStatusCount,
  setBasicData,
  requiredQua,
  onFilterSelection,
  searchbuildColor,
  genderData,
  isClear,
  setIsClear,
  buildSearchInitialData,
  handleQuickReset,
  setSelectedBtn,
  selectedBtns,
}) {
  const theme = useTheme();
  const query = useQuery();
  const [selectedSkin, setSelectedSkin] = useState([]);
  // Manage the open state for each popover separately
  const [openPopovers, setOpenPopovers] = useState({});

  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    if (isClear) {
      setSelectedSkin([]);
      setIsClear(false);
    }
    if (query?.get("skinz")) {
      let skinzValue = query?.get("skinz")?.split(",");
      let skinzID = skinzValue?.map((item) => {
        return skinValue[item];
      });
      setSelectedSkin(skinzID);
    }
  }, [isClear, query?.get("skinz")]);

  useEffect(() => {
    setSelectedBtn(selected?.length > 0 ? selected : [panelData[0]?.id]);
    // Initialize open state for each popover
    const initialOpenState = {};
    panelData.forEach((item) => {
      initialOpenState[item.id] = false;
    });
    setOpenPopovers(initialOpenState);
  }, [panelData, selected]);

  const handleButtonClick = (event, { id, name, title, dropDown }) => {
    if (handleQuickReset) {
      handleQuickReset(id);
    }
    if (dropDown) {
      setAnchorEl(event.currentTarget);
      // Toggle the open state of the clicked popover
      setSelectedBtn((prevState) => [...prevState, id]);
      setOpenPopovers((prevState) => ({
        ...prevState,
        [id]: !prevState[id],
      }));
      return;
    }
    if (title === true) {
      // Handle title buttons
      if (selectedBtns.find((selectedBtn) => selectedBtn === id)) {
        const selectedBtnList = [...selectedBtns];
        id !== 1111 && selectedBtnList.splice(selectedBtnList.indexOf(id), 1);
        setSelectedBtn(selectedBtnList);
        onChangeFilter(selectedBtnList);
      } else {
        setSelectedBtn([panelData?.[0]?.id]);
        onChangeFilter([panelData?.[0]?.id]);
      }
    } else {
      // Handle non-title buttons
      if (selectedBtns.find((selectedBtn) => selectedBtn === id)) {
        const selectedBtnList = [...selectedBtns];
        id !== 1111 && selectedBtnList.splice(selectedBtnList.indexOf(id), 1);
        if (selectedBtnList.length === 0) {
          setSelectedBtn([panelData?.[0]?.id]);
          onChangeFilter([panelData?.[0]?.id]);
        } else {
          setSelectedBtn(selectedBtnList);
          onChangeFilter(selectedBtnList);
        }
      } else {
        if (
          selectedBtns.find((selectedBtn) => selectedBtn === panelData[0]?.id)
        ) {
          const selectedBtnList = [...selectedBtns];
          id !== 1111 && selectedBtnList.splice(selectedBtnList.indexOf(id), 1);
          selectedBtnList.push(id);
          setSelectedBtn(selectedBtnList);
          onChangeFilter(selectedBtnList);
        } else {
          setSelectedBtn((prevState) => [...prevState, id]);
          onChangeFilter([...selectedBtns, id]);
        }
      }
    }
  };

  const handleClosePopover = (id) => {
    // Set the open state of the specified popover to false
    setOpenPopovers((prevState) => ({
      ...prevState,
      [id]: false,
    }));
  };

  return (
    <Box
      sx={{
        textAlign: side === "right" && "end",
        display: { xs: "none", sm: "flex" },
        flexDirection: "column",
        gap: "10px",
      }}
    >
      {panelData?.map((btn) => (
        <>
          <Box key={btn.id} sx={{ position: "relative" }}>
            <Tooltip disableInteractive title={btn.name} placement="top-end">
              <Button
                startIcon={
                  btn.dropDown && (
                    <Box
                      component={"img"}
                      src={openPopovers[btn.id] ? rightArrow : leftArrow}
                      sx={{ height: "28px", width: "30px" }}
                    />
                  )
                }
                endIcon={
                  btn.dropDown && (
                    <Box
                      component={"div"}
                      sx={{
                        height: { lg: "28px", xl: "0px" },
                        width: { lg: "30px", xl: "0px" },
                      }}
                    />
                  )
                }
                sx={{
                  ".MuiButton-startIcon": {
                    marginRight: "0px !important",
                    marginLeft: 0,
                  },
                  ".MuiButton-endIcon": {
                    marginRight: "0px !important",
                    marginLeft: 0,
                  },
                  zIndex: openPopovers[btn.id] ? 9999 : 0,
                  justifyContent: btn.dropDown && "space-between",
                  flexDirection: "row-reverse",
                  padding: "6px 7px",
                  lineHeight: "inherit",
                  borderRadius: "10px",
                  width: "100%",
                  // boxShadow: "0px 5px 10px #00000029 !important",
                  borderBottomLeftRadius: side === "left" ? { sm: 0 } : "10px",
                  borderTopLeftRadius: side === "left" ? { sm: 0 } : "10px",
                  borderTopRightRadius: side === "right" ? { sm: 0 } : "10px",
                  borderBottomRightRadius:
                    side === "right" ? { sm: 0 } : "10px",
                  mr: { xs: 1, sm: 0 },
                  minWidth: { xs: "90px", sm: 0 },
                  height: "45px !important",
                  fontSize: theme.typography.fontSize.xs,
                  fontWeight:
                    btn?.title || selectedBtns?.includes(btn?.id)
                      ? theme.typography.Bold
                      : theme.typography.Regular,
                  "&:hover": {
                    backgroundColor: theme.palette[color].main,
                    color: theme.palette.white,
                    fontWeight: theme.typography.Bold,
                  },
                }}
                onClick={(e) => {
                  handleButtonClick(e, btn);
                }}
                disableElevation
                variant="contained"
                color={
                  selectedBtns?.includes(btn?.id) || openPopovers[btn?.id]
                    ? searchbuildColor
                      ? searchbuildColor
                      : "filterButton"
                    : "base"
                }
                key={btn?.id}
              >
                {truncate(btn?.name, { length: 14 })}
              </Button>
            </Tooltip>
          </Box>
          <Popover
            open={openPopovers[btn?.id]}
            onClose={() => handleClosePopover(btn?.id)}
            anchorEl={anchorEl}
            // anchorOrigin={{
            //   vertical: [12, 13].includes(btn.id)
            //     ? "top"
            //     : [11].includes(btn.id)
            //     ? "top"
            //     : "bottom",
            //   horizontal: [12, 13].includes(btn.id)
            //     ? "right"
            //     : [11].includes(btn.id)
            //     ? "left"
            //     : "right",
            // }}
            // transformOrigin={{
            //   vertical: "top",
            //   horizontal: "right",
            // }}
            anchorOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            sx={{
              ...(searchbuildColor
                ? {}
                : {
                    // "& .MuiPopover-paper": {
                    //   right: ![11].includes(btn.id)
                    //     ? "0px !important"
                    //     : undefined,
                    //   left: ![11].includes(btn.id)
                    //     ? "unset !important"
                    //     : undefined,
                    //   zIndex: "0 !important",
                    //   // boxShadow: "0px 5px 10px #00000029 !important",
                    // },
                  }),
            }}
          >
            {btn?.name === "gender" && (
              <GenderSection
                setOpenPopovers={setOpenPopovers}
                openPopovers={openPopovers}
                setSelectedBtn={setSelectedBtn}
                panelData={panelData}
                setGender={setGender}
                id={btn.id}
                handleClosePopover={handleClosePopover}
                handleFilterSelection={handleFilterSelection}
                setIsFilterApplied={setIsFilterApplied}
                getTalentMyJobStatusCount={getTalentMyJobStatusCount}
                setBasicData={setBasicData}
                onFilterSelection={onFilterSelection}
                genderData={genderData}
              />
            )}
            {btn?.name === "race" && (
              <Race
                setOpenPopovers={setOpenPopovers}
                openPopovers={openPopovers}
                setSelectedBtn={setSelectedBtn}
                panelData={panelData}
                setSkinColor={setSkinColor}
                id={btn.id}
                handleClosePopover={handleClosePopover}
                handleFilterSelection={handleFilterSelection}
                setIsFilterApplied={setIsFilterApplied}
                getTalentMyJobStatusCount={getTalentMyJobStatusCount}
                setBasicData={setBasicData}
                selected={selectedSkin}
                setSelected={setSelectedSkin}
                onFilterSelection={onFilterSelection}
                isClear={isClear}
                setIsClear={setIsClear}
              />
            )}
            {btn?.name === "salary" && (
              <Box
                sx={{
                  width: "1000px",
                  padding: "16px",
                  boxShadow: "0px 5px 10px #00000029 !important",
                  borderRadius: "0 0 10px 10px",
                  background: "#FFFFFF",
                }}
              >
                <SalaryBox
                  setOpenPopovers={setOpenPopovers}
                  openPopovers={openPopovers}
                  setSelectedBtn={setSelectedBtn}
                  panelData={panelData}
                  setSalary={setSalary}
                  buttonID={btn.id}
                  handleClosePopover={handleClosePopover}
                  handleFilterSelection={handleFilterSelection}
                  setIsFilterApplied={setIsFilterApplied}
                  getTalentMyJobStatusCount={getTalentMyJobStatusCount}
                  setBasicData={setBasicData}
                  onFilterSelection={onFilterSelection}
                />
              </Box>
            )}
            {btn?.name === "experience" && (
              <Box
                sx={{
                  width: "1000px",
                  padding: "16px",
                  boxShadow: "0px 5px 10px #00000029 !important",
                  borderRadius: "0 0 10px 10px",
                  zIndex: 10,
                  background: "#FFFFFF",
                }}
              >
                <ExperienceBox
                  setExperience={setExperience}
                  buttonID={btn.id}
                  handleClosePopover={handleClosePopover}
                  handleFilterSelection={handleFilterSelection}
                  setIsFilterApplied={setIsFilterApplied}
                  getTalentMyJobStatusCount={getTalentMyJobStatusCount}
                  setBasicData={setBasicData}
                  onFilterSelection={onFilterSelection}
                />
              </Box>
            )}
            {btn?.name === "qualification" && (
              <QualificationBox
                setOpenPopovers={setOpenPopovers}
                openPopovers={openPopovers}
                setSelectedButton={setSelectedBtn}
                panelData={panelData}
                setQualification={setQualification}
                buttonID={btn.id}
                handleClosePopover={handleClosePopover}
                handleFilterSelection={handleFilterSelection}
                setIsFilterApplied={setIsFilterApplied}
                getTalentMyJobStatusCount={getTalentMyJobStatusCount}
                setBasicData={setBasicData}
                requiredQua={requiredQua}
                onFilterSelection={onFilterSelection}
              />
            )}
          </Popover>
        </>
      ))}
    </Box>
  );
}
