import { useTheme } from "@emotion/react";
import {
  Box,
  Button,
  InputBase,
  InputLabel,
  Modal,
  Paper,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { validateSchema } from "../../../../utils/Common";
import { useDispatch } from "react-redux";
import { setAlert } from "../../../../redux/configSlice";
import { ALERT_TYPE } from "../../../../utils/Constants";
import { addSolutionList, editSolutionList } from "../../../../redux/admin/maintenance";

const AddSolutionTypesModal = ({
  isAddSolutionTypeModal,
  setIsAddSolutionTypeModal,
  handleClose,
  handleOpen,
  edit,
  getSolution,
  selectedSolutionType,
  setIsEdit
}) => {
  const [formData, setFormData] = useState({});
  const [errors, setErrors] = useState({});
  const theme = useTheme();
  const dispatch = useDispatch();
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: { xs: "auto", tablet: 400 },
    bgcolor: "background.paper",
    borderRadius: "25px",
    boxShadow: "none !important",
    ".&focus-visible": {
      outlineColor: "none",
    },
  };
  const formSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
  });
  const handleFormInput = (event, name) => {
    event?.preventDefault();
    event?.stopPropagation();
    setFormData((prev) => ({ ...prev, [name]: event?.target?.value }));
    const updatedError = errors;
    delete updatedError[name];
    setErrors(updatedError);
  };
  const addSolution = async () => {
    let payload = {
      name: formData?.name,
    };
    try {
      let response = await dispatch(addSolutionList(payload));
      if (response?.payload?.data?.status === "success") {
        getSolution();
      }
    } catch (error) {
      console.log(error, "errror");
    }
  };
  const editSolution = async () => {
    let payload = {
      solution_type_id: selectedSolutionType?.solution_type_id,
      name: formData?.name,
    };
    validateSchema(formSchema, payload)
    .then(() => {
      dispatch(editSolutionList(payload)).then((payload) => {
        if (payload?.payload?.status === "success") {
          getSolution();
          setIsAddSolutionTypeModal(false);
          setIsEdit(false)
        } else {
          dispatch(
            setAlert({
              show: true,
              type: ALERT_TYPE?.ERROR,
              msg: "Something went wrong",
            })
          );
        }
      });
    })
    .catch((error) => {
      setErrors(error);
    });
  };
  const addItem = async () => {
    let payload = {
      name: formData?.name,
    };
    validateSchema(formSchema, payload)
      .then(() => {
        dispatch(addSolutionList(payload)).then((payload) => {
          if (payload?.payload?.status === "success") {
            getSolution();
            setIsAddSolutionTypeModal(false);
            setIsEdit(false)
          } else {
            dispatch(
              setAlert({
                show: true,
                type: ALERT_TYPE?.ERROR,
                msg: "Something went wrong",
              })
            );
          }
        });
      })
      .catch((error) => {
        setErrors(error);
      });
  };
  const handleCancel = () => {
    setIsAddSolutionTypeModal(false)
  };
  useEffect(()=>{
if(selectedSolutionType){
  setFormData({name:selectedSolutionType?.name})
}
  },[selectedSolutionType])
  return (
    <Modal open={handleOpen} onClose={handleClose}>
      <Box sx={style}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "end",
          }}
        >
          <Button
            variant="contained"
            color="yellowButton400"
            sx={{
              height: "45px",
              width: "200px",
              borderRadius: "0 25px 0 25px",
            }}
          >
            {edit ? "edit a type" : "add a type"}
          </Button>
        </Box>
        <Box sx={{ padding: "20px" }}>
          <Box sx={{ marginBottom: "5px" }}>
            <InputLabel
              htmlFor="name"
              sx={{
                color: "black",
                paddingBottom: "2px",
                fontSize: "14px",
                fontWeight: 600,
              }}
            >
              Name
            </InputLabel>
            <Paper
              elevation={3}
              sx={{
                display: "flex",
                borderRadius: "10px",
                height: "40px",
                boxShadow: "none",
                border: errors?.["name"]
                  ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
                  : `1px solid ${theme.palette.grayBorder}`,
              }}
            >
              <InputBase
                sx={{ ml: 2, mr: 2, width: "100%" }}
                id="name"
                type="text"
                placeholder={"Enter your name"}
                value={formData?.name}
                onChange={(e) => handleFormInput(e, "name")}
              />
            </Paper>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            width: "100%",
          }}
        >
          <Button
            variant="contained"
            color="grayButton100"
            sx={{
              borderRadius: 0,
              width: "50%",
              height: "47px",
              borderBottomLeftRadius: 25,
            }}
            onClick={handleCancel}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="yellowButton100"
            sx={{
              borderRadius: 0,
              width: "50%",
              height: "47px",
              borderBottomRightRadius: 25,
            }}
            onClick={edit ? editSolution : addItem}
          >
            {edit ? "Edit" : "Create"}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default AddSolutionTypesModal;
