import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Typography, Container, Box } from '@mui/material';
import { getDecodedToken, getToken } from '../../utils/Common';

const AccessDeniedPage = () => {
  const navigateLink ={
    1:"/",
    2:"/",
    3:"/",
    4:"/",
    5:"/",
    6:"/"
  }

  let decodedToken = getDecodedToken(getToken());
  const navigate = useNavigate();
  const handleGoBack = () => {
    navigate(navigateLink[decodedToken?.data?.role_id]);
  };

  return (
    <Container maxWidth="sm" sx={{ textAlign: 'center', pt: 8 }}>
      <Typography variant="h2" component="h1" gutterBottom>
        403 - Access Denied
      </Typography>
      <Typography variant="h5" gutterBottom>
        You do not have permission to view this page.
      </Typography>
      <Box mt={4}>
        <Button variant="contained" color="primary" onClick={handleGoBack}>
          Go Back to Previous Page
        </Button>
      </Box>
    </Container>
  );
};

export default AccessDeniedPage;
