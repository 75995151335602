import {
  Avatar,
  Box,
  Button,
  Card,
  InputBase,
  Paper,
  Typography,
} from "@mui/material";
import React, { useRef, useState } from "react";
import InputBox from "../../common/InputBox";
import locale from "../../../i18n/locale";
import { useTheme } from "@mui/material/styles";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import { CheckCircle } from "@mui/icons-material";
import signupIcon from "../../../assets/CrayBotIcons/Crayon Craybot Notification-07.svg";
import needSomeOne from "../../../assets/ContactUs/NeedSomeOne.svg";
import bugIcon from "../../../assets/ContactUs/CrayonBug.svg";
import improveIcon from "../../../assets/ContactUs/HelpUsImprove.svg";
import { getDecodedToken, getToken } from "../../../utils/Common";
import { useSelector } from "react-redux";
import * as yup from "yup";
import { useDispatch } from "react-redux";

const HelpContactComponent = ({
  heading,
  subHeading,
  content,
  buttonType,
  Button1Text,
  button2Text,
  textAreaPlaceholder,
  rowNumber,
  height,
  handleSubmit,
  errors,
  setErrors,
  formData,
  setFormData,
  card,
  width,
  newCardWidth,
}) => {
  const theme = useTheme();
  const i18n = locale.en;
  const [fieldTouched, setFieldTouched] = useState([]);
  const hiddenFileInput = useRef(null);
  const [countryCode, setCountryCode] = useState("");
  const decodedToken = getDecodedToken(getToken());
  const { contactType } = useSelector((state) => state?.configContactSlice);

  const renderIcon = (key) => {
    switch (key) {
      case 1:
        return needSomeOne;
      case 2:
        return bugIcon;
      case 3:
        return improveIcon;
      default:
        return needSomeOne;
    }
  };
  let obj = {
    helpText: "description",
    firstName: "first_name",
    lastName: "last_name",
    email: "email",
  };

  const handleChange = (e, key) => {
    setFormData((prev) => ({ ...prev, [key]: e }));
    const isValidEmail = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/.test(
      e
    );
    let updatedError = { ...errors };

    if (key === "email") {
      if (isValidEmail) {
        delete updatedError[obj[key]];
        setErrors({ ...updatedError });
      }
    } else {
      delete updatedError[obj[key]];
      setErrors({ ...updatedError });
    }
  };

  const handleUploadButtonClick = () => {
    hiddenFileInput.current.click();
  };

  const handleImageChange = (event) => {
    const file = event?.target?.files?.[0];
    if (file) {
      setFormData({ ...formData, file: file });
    }
  };

  return (
    <Card
      sx={{
        borderRadius: "25px",
        position: "relative",
        boxShadow: "0px 5px 10px #00000029",
        width: newCardWidth,
        maxWidth:"374px",
        height: "695px",
        display: "flex",
        flexDirection: "column",
      }}
      className="cardboxdetail"
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          paddingLeft: "15px",
          paddingRight: "15px",
        }}
      >
        <Box sx={{ marginTop: "15px" }}>
          <Box
            component="img"
            src={renderIcon(card)}
            sx={{ width: width, height: 125 }}
          />
        </Box>
        <Typography
          sx={{
            fontSize: theme.typography.fontSize.xl,
            letterSpacing: "0.4px",
            marginTop: "15px",
            fontWeight: theme.typography.Bold,
            textAlign: "center",
          }}
        >
          {heading}
        </Typography>
        <Typography
          sx={{
            fontSize: theme.typography.fontSize.sm,
            marginTop: "15px",
            fontWeight: theme.typography.Bold,
            letterSpacing: "0.28px",
            marginBottom: "19px",
            textAlign: "center",
          }}
        >
          {subHeading}
        </Typography>
        <Typography
          sx={{
            fontSize: theme.typography.fontSize.xs,
            letterSpacing: "0.24px",
            fontWeight: theme.typography.Medium,
            marginBottom: "15px",
            textAlign: "center",
          }}
        >
          {content}
        </Typography>
      </Box>
      <Box
        sx={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          padding: "15px",
          paddingTop: "0px",
          paddingBottom: "15px",
        }}
      >
        <Box sx={{ display: "flex", width: "100%", marginBottom: "15px" }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
            }}
          >
            <InputBox
              id="firstName"
              disabled={decodedToken?.data}
              value={decodedToken?.data?.fName || formData?.firstName}
              onChange={(e) => {
                handleChange(e.target.value, "firstName");
              }}
              onBlur={(e) => {
                setFieldTouched([...fieldTouched, "firstName"]);
              }}
              placeholder={i18n["login.firstName"]}
              sx={{
                mr: 1,
                width: "90% !important",
                height: "40px",
                borderRadius: "10px !important",
                background: "white !important",
                border: errors?.["first_name"]
                  ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
                  : "1px solid #E0E0E0",
              }}
              style={{ flex: "1" }}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-end",
            }}
          >
            <InputBox
              id="lastName"
              disabled={decodedToken?.data}
              value={decodedToken?.data?.lName || formData?.lastName || ""}
              onChange={(e) => {
                handleChange(e.target.value, "lastName");
              }}
              onBlur={(e) => {
                setFieldTouched([...fieldTouched, "lastName"]);
              }}
              placeholder={i18n["login.lastName"]}
              sx={{
                width: "90% !important",
                height: "40px",
                borderRadius: "10px !important",
                background: "white !important",
                border: errors?.["last_name"]
                  ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
                  : "1px solid #E0E0E0",
              }}
              style={{ flex: "1" }}
            />
          </Box>
        </Box>
        <Box sx={{ marginBottom: "15px" }}>
          <Paper
            sx={{
              display: "flex",
              height: "40px",
              width: "100% !important",
              boxShadow: "none",
              borderRadius: "10px !important",
              background: "white !important",
              border: errors?.["email"]
                ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
                : "1px solid #E0E0E0",
            }}
          >
            <InputBase
              disabled={decodedToken?.data}
              sx={{ ml: 2, mr: 2, width: "100%" }}
              id="email"
              value={decodedToken?.data?.Email || formData?.email || ""}
              onChange={(e) => {
                handleChange(e.target.value, "email");
              }}
              onBlur={(e) => {
                setFieldTouched([...fieldTouched, "email"]);
              }}
              placeholder={i18n["login.emailAddrees"]}
              style={{
                width: "100%",
              }}
            />
          </Paper>
        </Box>
        <Box sx={{ marginBottom: "15px" }}>
          <Paper
            sx={{
              display: "flex",
              height: "40px",
              width: "100% !important",
              borderRadius: "10px !important",
              background: "white !important",
              boxShadow: "none",
              alignItems: "center",
              justifyContent: "space-between",
              border: errors?.["contact_no"]
                ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
                : "1px solid #E0E0E0",
            }}
          >
            <PhoneInput
              international
              id="contact"
              disabled={decodedToken?.data}
              placeholder="Enter phone number"
              countryCallingCodeEditable={false}
              defaultCountry={countryCode}
              value={decodedToken?.data?.contact || formData?.contact || ""}
              onChange={(value) => {
                handleChange(value, "contact");
              }}
              onBlur={(e) => {
                setFieldTouched([...fieldTouched, "Contact"]);
              }}
              focusInputOnCountrySelection={true}
              onCountryChange={(value) => {
                setCountryCode(value);
              }}
              style={{
                font: "inherit",
                padding: "10px",
              }}
            />
            {isValidPhoneNumber(formData?.contact || "") && (
              <Typography
                sx={{
                  color: theme.palette.tealColor.main,
                  fontSize: theme.typography.fontSize.sm,
                  alignItems: "center",
                  display: "flex",
                  gap: "5px",
                  marginRight: "5px",
                  justifyContent: "end",
                }}
              >
                Correct <CheckCircle fontSize="small" />
              </Typography>
            )}
          </Paper>
        </Box>
        <Box sx={{ flex: 1, display: "flex" }}>
          <InputBox
            multiline={true}
            sx={{
              width: "100%",
              height: { height },
              borderRadius: "10px",
              alignItems: "flex-start",
              border: errors?.["description"]
                ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
                : "1px solid #E0E0E0",
              flex: "1",
            }}
            placeholder={textAreaPlaceholder}
            value={formData?.helpText || ""}
            id="helpText"
            onChange={(e) => handleChange(e.target.value, "helpText")}
            rowNumber={rowNumber}
          />
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          width: "100%",
          justifyContent: "space-between",
        }}
      >
        {buttonType === "single" ? (
          <Button
            sx={{
              width: "100%",
              height: "60px",
              maxHeight: "60px",
              minHeight: "60px",
              borderRadius: 0,
              padding: 3,
              fontSize: theme.typography.fontSize.sm,
              fontWeight: theme.typography.Bold,
            }}
            variant="contained"
            color="deleteVideoButton"
            onClick={() => handleSubmit(formData)}
          >
            {Button1Text}
          </Button>
        ) : (
          <>
            <Button
              sx={{
                width: "50%",
                height: "60px",
                maxHeight: "60px",
                minHeight: "60px",
                borderRadius: 0,
                fontSize: theme.typography.fontSize.sm,
                fontWeight: theme.typography.Bold,
              }}
              variant="contained"
              color="grayButton600"
              onClick={handleUploadButtonClick}
            >
              {Button1Text}
            </Button>
            <input
              ref={hiddenFileInput}
              type="file"
              accept="image/*"
              onChange={handleImageChange}
              style={{ display: "none" }}
            />
            <Button
              sx={{
                width: "50%",
                height: "60px",
                maxHeight: "60px",
                minHeight: "60px",
                borderRadius: 0,
                fontSize: theme.typography.fontSize.sm,
                fontWeight: theme.typography.Bold,
              }}
              variant="contained"
              color="deleteVideoButton"
              onClick={() => handleSubmit(formData)}
            >
              {button2Text}
            </Button>
          </>
        )}
      </Box>
    </Card>
  );
};

export default HelpContactComponent;
