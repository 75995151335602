import {
  AccordionDetails,
  AccordionSummary,
  Box,
  Switch,
  Typography,
  styled,
  useTheme,
} from "@mui/material";
import React, { useState } from "react";
import SmallButton from "../../common/SmallButton";
import profile from "../../../assets/Crayon_Favicon.svg";
import { SolutionsCardAccordion } from "../../../utils/CommonComponent";
import TalentSVGButton from "../../common/TalentSVGButton";
import humanMale from "../../../assets/Padding Excluded/Black_Male.svg";
import email from "../../../assets/Padding Excluded/Black_Email_Red.svg";
import { alpha } from "@material-ui/core";
import upClose from "../../../assets/Padding Included/Black_Up_Close.svg";
import downClose from "../../../assets/Padding Included/Black_Down_Open.svg";
import ViewProfilePic from "../../common/myJobsSectionEmployerAndRecruiter/ViewProfilePic";
import contact from "../../../assets/Padding Excluded/Black_Contact_Yellow.svg";
import { formatPhoneNumberIntl } from "react-phone-number-input";

const YellowSwitch = styled(Switch)(({ theme, checked, disabled }) => ({
  "& .MuiSwitch-switchBase.Mui-checked": {
    color: disabled
      ? "#F2F4FB"
      : !checked
      ? theme.palette.yellowButton100.main
      : "#009AE0",
    "&:hover": {
      backgroundColor: alpha(
        disabled
          ? "#F2F4FB"
          : !checked
          ? theme.palette.yellowButton100.main
          : "#009AE0",
        theme.palette.action.hoverOpacity
      ),
    },
  },
  "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
    backgroundColor: disabled
      ? "#F2F4FB"
      : !checked
      ? theme.palette.yellowButton100.main
      : "#009AE0",
  },
  "& .MuiSwitch-track": {
    margin: "auto",
    height: "50% !important",
    width: "90% !important",
    padding: "0px !important",
    backgroundColor: disabled
      ? "#C9CAD8"
      : !checked
      ? theme.palette.yellowButton100.main
      : "#009AE0",
  },
  ".MuiButtonBase-root.MuiSwitch-switchBase": {
    borderRadius: "15% !important",
    padding: "11px !important",
  },
  "& .MuiSwitch-thumb": {
    borderRadius: "6px !important",
    height: "15px !important",
    width: "15px !important",
    backgroundColor: disabled
      ? "#F2F4FB"
      : !checked
      ? theme.palette.yellowButton100.main
      : "#009AE0",
  },
  "& .MuiButtonBase-root-MuiSwitch-switchBase": {
    borderRadius: "15% !important",
    padding: "11px !important",
  },
}));

const ContactUsQueryCard = ({ item, handleSwitch, filterList }) => {
  const [flip, setFlip] = useState(false);
  const [openScreenShot, setOpenScreenShot] = useState(false);
  const theme = useTheme();
  const truncateText = (text, maxLength) => {
    if (!text) return "";
    const words = text.split(" ");
    if (words.length <= maxLength) return text;
    return words.slice(0, maxLength).join(" ") + "...";
  };
  const obj = {
    1: "help!",
    2: "bugs",
    3: "book a demo",
    4: "suggestion box",
  };
  return (
    <SolutionsCardAccordion expanded={flip}>
      <AccordionSummary aria-controls="panel1-content" id="panel1-header">
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            cursor:"default"
          }}
        >
          <Box
            sx={{
              display: "flex",
              gap: "15px",
              alignItems: "center",
            }}
          >
            <SmallButton
              color="redButton"
              startIcon={
                <Box
                  component="img"
                  className="eye"
                  alt="eye logo"
                  src={flip ? upClose : downClose}
                  sx={{
                    height: 16,
                    width: { xs: 10, lg: 8, mlg: 10, xl: 15 },
                  }}
                />
              }
              padding={0}
              startIconMargin="0px"
              height={"75px"}
              width={20}
              fontWeight={theme.typography.Bold}
              borderRadius={flip ? "10px 0px 0px 0px" : "10px 0px 0px 10px"}
              onClick={() => setFlip((prev) => !prev)}
              cursor={"pointer"}
            />

            <Box
              sx={{
                width: "50px",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Box
                component="img"
                className="profileAvatar"
                alt="crayon logo"
                src={profile}
                sx={{
                  mr: 1,
                  height: "47px !important",
                  width: "47px !important",
                  borderRadius: "10px 10px 0 0",
                  border: `1px solid ${theme.palette.borderColor}`,
                }}
              />
              <SmallButton
                color="userID"
                label={item?.contact_id}
                mr={1}
                fontSize={10}
                fontWeight={700}
                alignItems="end"
                textColor="#000"
                borderRadius="0px 0px 6px 6px"
                marginTop="-2px"
                minWidth="50px"
                width="fit-content"
              ></SmallButton>
            </Box>

            <Box
              sx={{ height: "75px", display: "flex", flexDirection: "column" }}
            >
              <Box sx={{ display: "flex", flexDirection: "row" }}>
                <Box
                  display={"flex"}
                  alignItems={"center"}
                  gap={"10px"}
                  minWidth={"200px"}
                >
                  <TalentSVGButton
                    color={"white"}
                    source={humanMale}
                    height={24}
                    width={18}
                    padding={"0px !important"}
                  />
                  <Typography
                    sx={{
                      fontSize: "16px",
                      fontWeight: 700,
                      userSelect: "text",
                    }}
                  >
                    {item?.first_name} {item?.last_name}
                  </Typography>
                </Box>
      
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "3px",
                    minWidth: "200px",
                  }}
                >
                  <TalentSVGButton
                    color={"white"}
                    source={contact}
                    outerHeight={20}
                    height={15}
                    width={15}
                    padding={"0px !important"}
                    startIconMargin={"0px !important"}
                  />
                  {item?.contact_no ? (
                    <Typography
                      sx={{
                        fontSize: "12px",
                        fontWeight: theme.typography.Regular,
                        minWidth: "100px",
                      userSelect: "text",

                      }}
                    >
                      {formatPhoneNumberIntl(item?.contact_no)
                        ? formatPhoneNumberIntl(item?.contact_no)
                        : item?.contact_no}
                    </Typography>
                  ) : (
                    "-"
                  )}
                </Box>
                <Box
                  display={"flex"}
                  alignItems={"center"}
                  gap={"10px"}
                  // flex={"1"}
                >
                  <TalentSVGButton
                    color={"white"}
                    source={email}
                    height={28}
                    width={18}
                    padding={"0 8px 0 0 !important"}
                    
                  />
                  <Typography
                    sx={{
                      fontSize: "14px",
                      fontWeight: 700,
                      userSelect: "text",
                      // whiteSpace: "nowrap",
                      // overflow: "hidden",
                      // textOverflow: "ellipsis",
                    }}
                  >
                    {item?.email}
                  </Typography>
                </Box>
              </Box>
              <Box
                sx={{
                  width: "200px",
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontWeight: 700,
                    userSelect: "text",
                  }}
                >
                  Query
                </Typography>
                {item?.contact_type_id && (
                  <SmallButton
                    color="precium"
                    label={obj[item?.contact_type_id]}
                    mr={1}
                    fontSize={10}
                    fontWeight={700}
                    alignItems="end"
                    textColor="#FFF"
                    borderRadius="5px 5px 5px 5px"
                    // marginTop="-2px"
                    width="fit-content"
                  ></SmallButton>
                )}
              </Box>
              {/* <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "12px",
                    fontWeight: 700,
                  }}
                >
                  Discription
                </Typography>
                <Typography
                  sx={{
                    fontSize: "12px",
                    fontWeight: 700,
                  }}
                >
                  {truncateText(item?.description, 40)}
                </Typography>
              </Box> */}
            </Box>
          </Box>
          <Box
            sx={{ display: "flex", alignItems: "center", marginRight: "50px" }}
          >
            <Typography sx={{ fontSize: "12px" }}>Not Resolved</Typography>
            <YellowSwitch
              onChange={(event) => handleSwitch(event, item)}
              checked={
                item?.query_resolve === null ? false : item?.query_resolve
              }
            />
            <Typography sx={{ fontSize: "12px" }}>Resolved</Typography>
          </Box>
        </Box>
      </AccordionSummary>
      <AccordionDetails
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            display: "flex",
            width: "100%",
            borderTop: "1px solid #C9CAD8",
          }}
        >
          <Box
            sx={{
              width: "20px",
              height: "auto",
              borderRadius: "0 0 0 10px",
              mt: "-2px",
              background: `${theme.palette.talentButton.main}`,
            }}
          ></Box>
          <Box sx={{ width: "100%" }}>
            <Box
              sx={{
                display: "flex",
                gap: "5px",
                width: "100%",
                justifyContent: "center",
                marginBottom: "8px",
              }}
            ></Box>

            <Box sx={{ marginLeft: "15px", display: "flex", gap: "87px" }}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "15px",
                  width: "50%",
                }}
              >
                <Box>
                  <Typography
                    sx={{
                      fontSize: "14px",
                      fontWeight: theme.typography.Bold,
                    }}
                  >
                    Description:
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "12px",
                      fontWeight: theme.typography.Bold,
                    }}
                  >
                    {item?.description}
                  </Typography>
                </Box>
                {item?.screenshot_url !== "No URL" && (
                  <Box
                    onClick={() => setOpenScreenShot(true)}
                    sx={{ cursor: "pointer" }}
                  >
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontWeight: theme.typography.Bold,
                        color: "#5932D9",
                      }}
                    >
                      view screenshot
                    </Typography>
                  </Box>
                )}
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center !important",
              }}
            >
              <SmallButton
                color="redButton"
                startIcon={
                  <Box
                    component="img"
                    className="eye"
                    alt="eye logo"
                    src={upClose}
                    sx={{
                      height: 16,
                      width: 16,
                    }}
                  />
                }
                onClick={() => {
                  setFlip((prev) => !prev);
                }}
                startIconMargin="4px"
                marginTop="5px"
                height={20}
                width={75}
                fontWeight={700}
                borderRadius="7px 7px 0px 0px"
              />
            </Box>
          </Box>
        </Box>
      </AccordionDetails>
      {openScreenShot && item?.screenshot_url !== "No URL" && (
        <ViewProfilePic
          open={openScreenShot}
          handleClose={() => setOpenScreenShot(false)}
          profile={item?.screenshot_url}
        />
      )}
    </SolutionsCardAccordion>
  );
};

export default ContactUsQueryCard;
