import React, { useEffect, useRef, useState,useLayoutEffect } from "react";
import {
  getDecodedToken,
  getToken,
  validateSchema,
} from "../../../utils/Common";
import { Box, Button, Grid, Paper, useTheme } from "@mui/material";
import useViewport from "../../common/ViewPort";
import LeftArrow from "../../common/LeftArrow";
import RightArrow from "../../common/RightArrow";
import { ALERT_TYPE } from "../../../utils/Constants";
import HelpContactComponent from "./HelpContactComponent";
import BookADemoContactComponent from "./BookADemoContactComponent";
import {
  StyledButtonLeft,
  StyledButtonRight,
} from "../../../utils/CommonComponent";
import { useDispatch } from "react-redux";
import {
  addcontact,
  contactList,
  getContactType,
  uploadScreenshot,
} from "../../../redux/guest/contactSlice";
import { useSelector } from "react-redux";
import * as yup from "yup";
import { setAlert } from "../../../redux/configSlice";
import { setFilterExpanded,setCardsPadding } from "../../../redux/guest/jobsSlice";
import ButtonPanel from "../../common/ButtonPanel";

const Contact = () => {
  let decodedToken = getDecodedToken(getToken());
  const { width } = useViewport();
  const { contactType } = useSelector((state) => state?.configContactSlice);
  const filterExpanded = useSelector(
    (state) => state?.configjobslice?.filterExpanded
  );
  const theme = useTheme();
  const [formData, setFormData] = useState({});
  const [leftExpanded, setLeftExpand] = useState(true);
  const [rightExpand, setRightExpand] = useState(true);
  const selectedUserType = useRef();
  const gridRef = useRef();
  const [newPadding, setNewPadding] = useState(15);
  const [activeStep, setActiveStep] = useState(1);
  const [errorsHelp, setErrorsHelp] = useState({});
  const [errorsBug, setErrorsBug] = useState({});
  const [errorsImprove, setErrorsImprove] = useState({});
  const [formDataHelp, setFormDataHelp] = useState({});
  const [formDataBug, setFormDataBug] = useState({});
  const [formDataImprove, setFormDataImprove] = useState({});
  const [isHelpSuccess, setIsHelpSuccess] = useState(false);
  const [newCardWidth, setNewCardWidth] = useState(340);

  let dispatch = useDispatch();

  const validationSchema = yup.object().shape({
    first_name: yup.string().required("First name is required"),
    last_name: yup.string().required("Last name is required"),
    email: yup
      .string()
      .email("Invalid email address")
      .required("Email is required"),
    // contact_no: yup.string().required('Contact number is required').test('isValid', 'Invalid phone number', value => isValidPhoneNumber(value)),
    description: yup.string().required("Help text is required"),
  });

  const getFilterList = async () => {
    try {
      let response = await dispatch(getContactType());
      if (response?.payload?.data) {
        const list = Object.fromEntries(
          response?.payload?.data?.map((item) => [
            item?.name,
            item?.contact_type_id,
          ])
        );
        dispatch(contactList(list));
      }
    } catch (error) {
      console.log(error, "error");
    }
  };

  useEffect(() => {
    getFilterList();
  }, []);

  const renderComponent = (filter) => {
    switch (filter) {
      case 1:
        return (
          <>
            <HelpContactComponent
              heading={"Help, I need somebody!"}
              subHeading={"Get in touch"}
              content={
                "Need to contact us? Drop us a message here, and we’ll get back to you ASAP!"
              }
              buttonType={"single"}
              Button1Text={"release the bottle"}
              button2Text={false}
              textAreaPlaceholder={"how can we help?"}
              height="150px"
              rowNumber={6}
              formData={formDataHelp}
              setFormData={setFormDataHelp}
              handleSubmit={handleHelpSubmit}
              errors={errorsHelp}
              setErrors={setErrorsHelp}
              card={1}
              width={"78px"}
              newCardWidth={newCardWidth}
            />
            <HelpContactComponent
              heading={"“I’ll be bug!” ~ CrayBot"}
              subHeading={"He messed up, again"}
              content={
                "Nothing’s perfect. Join the bug hunt, and help us, help you. Add a screenshot and brief description of the (valid) issue and you’ll be entered into our monthly Bug Bounty."
              }
              buttonType={"double"}
              Button1Text={"upload screenshot"}
              button2Text={"now bugger off"}
              textAreaPlaceholder={"sorry to bug…"}
              height="132px"
              rowNumber={5}
              formData={formDataBug}
              setFormData={setFormDataBug}
              handleSubmit={handleHelpBug}
              handleUploadImage={handleUploadImage}
              errors={errorsBug}
              setErrors={setErrorsBug}
              card={2}
              width={"88px"}
              newCardWidth={newCardWidth}
            />
            <BookADemoContactComponent
              heading={"Book a demo with the team"}
              subHeading={"Is CrayBot our dem-ou?"}
              content={
                "Check out our existing videos, or find some time with our product team to do a run-through of the platform. But come prepared as we’ll want to know about your business too!"
              }
              Button1Text={"visit CrayonTube"}
              Button2Text={"book a demo"}
              handleYoutubeLink={handleYoutubeLink}
              handleBookDemo={handleBookDemo}
              card={3}
              newCardWidth={newCardWidth}
            />
            <HelpContactComponent
              heading={"Help us improve"}
              subHeading={"#strongertogether #teamwork"}
              content={
                "We’re just a platform, standing in front of a user, asking you to help us improve. We can’t implement every idea, and might’ve omitted a few obvious things, so let’s hear yours and make it a better place, for you and for me, and the entire… (ok, Michael)"
              }
              buttonType={"double"}
              Button1Text={"upload screenshot"}
              button2Text={"check my ide-yeah!"}
              textAreaPlaceholder={"shoot your shot…"}
              height="96px"
              rowNumber={4}
              formData={formDataImprove}
              setFormData={setFormDataImprove}
              handleSubmit={handleHelpSuggestion}
              handleUploadImage={handleUploadImage}
              errors={errorsImprove}
              setErrors={setErrorsImprove}
              card={3}
              width={"97px"}
              newCardWidth={newCardWidth}
            />
          </>
        );
      case 2:
        return (
          <HelpContactComponent
            heading={"Help, I need somebody!"}
            subHeading={"Get in touch"}
            content={
              "Need to contact us? Drop us a message here, and we’ll get back to you ASAP!"
            }
            buttonType={"single"}
            Button1Text={"release the bottle"}
            button2Text={false}
            textAreaPlaceholder={"how can we help?"}
            height="150px"
            rowNumber={6}
            formData={formDataHelp}
            setFormData={setFormDataHelp}
            handleSubmit={handleHelpSubmit}
            errors={errorsHelp}
            setErrors={setErrorsHelp}
            card={1}
            width={"78px"}
          />
        );
      case 3:
        return (
          <HelpContactComponent
            heading={"“I’ll be bug!” ~ CrayBot"}
            subHeading={"He messed up, again"}
            content={
              "Nothing’s perfect. Join the bug hunt, and help us, help you. Add a screenshot and brief description of the (valid) issue and you’ll be entered into our monthly Bug Bounty."
            }
            buttonType={"double"}
            Button1Text={"upload screenshot"}
            button2Text={"now bugger off"}
            textAreaPlaceholder={"sorry to bug…"}
            height="132px"
            rowNumber={5}
            formData={formDataBug}
            setFormData={setFormDataBug}
            handleSubmit={handleHelpBug}
            handleUploadImage={handleUploadImage}
            errors={errorsBug}
            setErrors={setErrorsBug}
            card={2}
            width={"88px"}
          />
        );
      case 4:
        return (
          <BookADemoContactComponent
            heading={"Book a demo with the team"}
            subHeading={"Is CrayBot our dem-ou?"}
            content={
              "Check out our existing videos, or find some time with our product team to do a run-through of the platform. But come prepared as we’ll want to know about your business too!"
            }
            Button1Text={"visit CrayonTube"}
            Button2Text={"book a demo"}
            handleYoutubeLink={handleYoutubeLink}
            handleBookDemo={handleBookDemo}
            card={3}
          />
        );
      case 5:
        return (
          <HelpContactComponent
            heading={"Help us improve"}
            subHeading={"#strongertogether #teamwork"}
            content={
              "We’re just a platform, standing in front of a user, asking you to help us improve. We can’t implement every idea, and might’ve omitted a few obvious things, so let’s hear yours and make it a better place, for you and for me, and the entire… (ok, Michael)"
            }
            buttonType={"double"}
            Button1Text={"upload screenshot"}
            button2Text={"check my ide-yeah!"}
            textAreaPlaceholder={"shoot your shot…"}
            height="96px"
            rowNumber={4}
            formData={formDataImprove}
            setFormData={setFormDataImprove}
            handleSubmit={handleHelpSuggestion}
            handleUploadImage={handleUploadImage}
            errors={errorsImprove}
            setErrors={setErrorsImprove}
            card={3}
            width={"97px"}
          />
        );

      default:
        return null;
    }
  };

  const handleHelpSubmit = (data) => {
    let payload = {};
    if (decodedToken?.data) {
      payload = {
        first_name: decodedToken.data.fName,
        last_name: decodedToken.data.lName,
        email: decodedToken.data.Email,
        contact_no: decodedToken.data.contact,
        user_id: decodedToken.data.user_id,
        contact_type_id: contactType?.["help!"],
        description: data?.helpText,
      };
    } else {
      payload = {
        first_name: data?.firstName,
        last_name: data?.lastName,
        email: data?.email,
        contact_no: data?.contact,
        description: data?.helpText,
        contact_type_id: contactType?.["help!"],
      };
    }
    validateSchema(validationSchema, payload)
      .then(() => {
        try {
          dispatch(addcontact(payload)).then((payload) => {
            if (payload?.payload?.status === "success") {
              setFormDataHelp({});
              dispatch(
                setAlert({
                  show: true,
                  type: ALERT_TYPE?.SUCCESS,
                  msg: "Query submitted successfull will get in touch",
                })
              );
              // setFormData({});
            } else {
            }
          });
        } catch (error) {
          dispatch(
            setAlert({
              show: true,
              type: ALERT_TYPE?.ERROR,
              msg: "Something went wrong",
            })
          );
        }
      })
      .catch((error) => {
        setErrorsHelp(error);
      });
  };

  const handleHelpBug = (data) => {
    let payload = {};
    if (decodedToken?.data) {
      payload = {
        first_name: decodedToken.data.fName,
        last_name: decodedToken.data.lName,
        email: decodedToken.data.Email,
        contact_no: decodedToken.data.contact,
        user_id: decodedToken.data.user_id,
        contact_type_id: contactType?.["bugs"],
        description: data?.helpText,
      };
    } else {
      payload = {
        first_name: data?.firstName,
        last_name: data?.lastName,
        email: data?.email,
        contact_no: data?.contact,
        contact_type_id: contactType?.["bugs"],
        description: data?.helpText,
      };
    }
    validateSchema(validationSchema, payload)
      .then(() => {
        try {
          dispatch(addcontact(payload))
            .then((response) => {
              if (response?.payload?.status === "success") {
                setFormDataBug({});
                dispatch(
                  setAlert({
                    show: true,
                    type: ALERT_TYPE?.SUCCESS,
                    msg: "Query submitted successfully.",
                  })
                );
                setFormData({});
                if (data?.file) {
                  const formData = new FormData();
                  formData.append("screenshot", data.file);
                  formData.append(
                    "contact_id",
                    response.payload.data.contact_id
                  );
                  dispatch(uploadScreenshot(formData));
                }
              } else {
                dispatch(
                  setAlert({
                    show: true,
                    type: ALERT_TYPE?.ERROR,
                    msg: "Failed to submit query. Please try again.",
                  })
                );
              }
            })
            .catch((error) => {
              dispatch(
                setAlert({
                  show: true,
                  type: ALERT_TYPE?.ERROR,
                  msg: "Something went wrong. Please try again.",
                })
              );
            });
        } catch (error) {
          dispatch(
            setAlert({
              show: true,
              type: ALERT_TYPE?.ERROR,
              msg: "Something went wrong. Please try again.",
            })
          );
        }
      })
      .catch((validationErrors) => {
        setErrorsBug(validationErrors);
      });
  };

  const handleHelpSuggestion = (data) => {
    let payload = {};
    if (decodedToken?.data) {
      payload = {
        first_name: decodedToken.data.fName,
        last_name: decodedToken.data.lName,
        email: decodedToken.data.Email,
        contact_no: decodedToken.data.contact,
        user_id: decodedToken.data.user_id,
        contact_type_id: contactType?.["suggestion box"],
        description: data?.helpText,
      };
    } else {
      payload = {
        first_name: data?.firstName,
        last_name: data?.lastName,
        email: data?.email,
        contact_no: data?.contact,
        description: data?.helpText,
        contact_type_id: contactType?.["suggestion box"],
      };
    }
    validateSchema(validationSchema, payload)
      .then(() => {
        try {
          dispatch(addcontact(payload))
            .then((response) => {
              if (response?.payload?.status === "success") {
                setFormDataImprove({});
                dispatch(
                  setAlert({
                    show: true,
                    type: ALERT_TYPE?.SUCCESS,
                    msg: "Query submitted successfully.",
                  })
                );
                setFormData({});
                if (data?.file) {
                  const formData = new FormData();
                  formData.append("screenshot", data.file);
                  formData.append(
                    "contact_id",
                    response.payload.data.contact_id
                  );
                  dispatch(uploadScreenshot(formData));
                }
              } else {
                dispatch(
                  setAlert({
                    show: true,
                    type: ALERT_TYPE?.ERROR,
                    msg: "Failed to submit query. Please try again.",
                  })
                );
              }
            })
            .catch((error) => {
              dispatch(
                setAlert({
                  show: true,
                  type: ALERT_TYPE?.ERROR,
                  msg: "Something went wrong. Please try again.",
                })
              );
            });
        } catch (error) {
          dispatch(
            setAlert({
              show: true,
              type: ALERT_TYPE?.ERROR,
              msg: "Something went wrong. Please try again.",
            })
          );
        }
      })
      .catch((validationErrors) => {
        setErrorsImprove(validationErrors);
      });
  };

  const handleUploadImage = async () => {
    let payload = {
      contact_id: "",
      screenshot: "",
    };
    try {
      let response = await dispatch(uploadScreenshot(payload));
    } catch (error) {
      console.log(error, "error");
    }
  };

  const handleYoutubeLink = () => {
    window.open("https://www.youtube.com/@crayonjobs", "_blank");
  };

  const handleBookDemo = () => {
    window.open("https://calendly.com/crayon/demo", "_blank");
  };
  const onChangeFilterJobType = (selectedFilter) => {
    // let solutionsType = [];
    // selectedFilter?.map((type) => {
    //   let selectedSolutionType = solutionArr?.find((sol) => sol?.id === type);
    //   solutionsType?.push(selectedSolutionType?.name);
    // });
    // solutionType.current = selectedFilter;
    // setSolutionTypeFilter(solutionsType);
  };
  function calculateCardWidthAndPadding(totalWidth) {
    let cardWidth = 360;
    let padding = 15;
    let numCards = Math.floor(totalWidth / (cardWidth + padding));
    let remainingSpace =
      totalWidth - (numCards * cardWidth + padding * (numCards + 1));

    while (remainingSpace > 0) {
      if (remainingSpace < cardWidth + padding) {
        if (padding < 30) {
          padding++;
          remainingSpace =
            totalWidth - (numCards * cardWidth + padding * (numCards + 1));
        } else {
          break;
        }
      } else {
        numCards++;
      }
    }

    cardWidth = (totalWidth - padding * (numCards + 1)) / numCards;
    if (cardWidth > 380) {
      cardWidth = 380;
    }
    remainingSpace =
      totalWidth - (numCards * cardWidth + padding * (numCards + 1));

    padding = padding + remainingSpace / (numCards + 1);

    remainingSpace =
      totalWidth - (numCards * cardWidth + padding * (numCards + 1));

    setNewCardWidth(cardWidth - 1);
    setNewPadding(padding);
    dispatch(setCardsPadding(padding));
    return { numCards, cardWidth, padding, remainingSpace };
  }
  const getNewWidth = () => {
    if (gridRef?.current) {
      calculateCardWidthAndPadding(gridRef?.current?.clientWidth);
    }
  };
  useLayoutEffect(() => {
    if (gridRef?.current) {
      calculateCardWidthAndPadding(gridRef?.current?.clientWidth);
    }
    window.addEventListener("resize", getNewWidth);
    return () => {
      window.removeEventListener("resize", getNewWidth);
    };
  }, [ leftExpanded, rightExpand]);

  useEffect(() => {
    if (width > 1024) {
      setLeftExpand(true);
      setRightExpand(true);
    } else {
      setLeftExpand(false);
      setRightExpand(false);
    }
  }, [width]);

  if (width === 0) {
    return null;
  }
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <Box
        item
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: `${width >= 768 && "10px"}`,
          position: `${width < 768 ? "absolute" : "relative"}`,
          // width: leftExpanded
          //   ? { xs: "150px", mdl: "123px", mlg: "150px", xl: "150px" }
          //   : "30px",
          width: leftExpanded ? "125" : "30px",
          minWidth: leftExpanded ? "125px" : "30px",
          zIndex: "30",
        }}
      >
        <Button
          variant="contained"
          sx={{
            position: "static",
            borderRadius: "0 10px 10px 0",
            minWidth: "30px",
            width: "30px",
            height: "45px",
          }}
          color="filterButtons"
          onClick={() => {
            setLeftExpand((prevState) => !prevState);
            dispatch(setFilterExpanded(!filterExpanded));
          }}
        >
          {leftExpanded ? <LeftArrow /> : <RightArrow />}
        </Button>
        {leftExpanded && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              height: {
                xs: `calc(100vh - 95px)`,
                sm: `calc(100vh - 120px )`,
                md: `calc(100vh - 120px)`,
                lg: `calc(100vh - 135px)`,
                xl: `calc(100vh - 155px)`,
                xxl: "calc(100vh - 175px)",
              },
              width: `${width < 768 && "135px"}`,
              paddingTop: `${width < 768 && "10px"}`,
              background: `${width > 0 && width < 768 && "#EBECF3"}`,
              boxShadow: `${
                width > 0 && width < 768 && "0px 5px 10px #00000029"
              }`,
              opacity: `${width < 768 && "1"}`,
              borderRadius: `${width < 768 && "10px"}`,
              overflowX: "hidden",
              overflowY: leftExpanded ? "scroll" : "unset",
            }}
          >
            <StyledButtonLeft
              onClick={() => setActiveStep(1)}
              variant={"contained"}
              color={activeStep === 1 ? "activeButton" : "displayCompanyButton"}
              sx={{
                width: leftExpanded ? "125px" : "30px",
                minWidth: leftExpanded ? "125px" : "30px",
                borderRadius: "0 10px 10px 0",
                border: 0,
                fontWeight:
                  activeStep === 1
                    ? theme.typography.Bold
                    : theme.typography.Regular,
                "&:hover": {
                  backgroundColor: theme?.palette?.activeButton?.main,
                  color: theme?.palette?.white,
                  fontWeight: theme.typography.Bold,
                },
              }}
            >
              {"get in touch"}
            </StyledButtonLeft>
            <StyledButtonLeft
              onClick={() => setActiveStep(2)}
              variant={"contained"}
              color={activeStep === 2 ? "activeButton" : "displayCompanyButton"}
              sx={{
                borderRadius: "0 10px 10px 0",
                border: 0,
                // width: leftExpanded
                //   ? { xs: "150px", lg: "150px", mlg: "150px", xl: "150px" }
                //   : "30px",
                width: leftExpanded ? "125px" : "30px",
                minWidth: leftExpanded ? "125px" : "30px",
                fontWeight:
                  activeStep === 2
                    ? theme.typography.Bold
                    : theme.typography.Regular,
                "&:hover": {
                  backgroundColor: theme?.palette?.activeButton?.main,
                  color: theme?.palette?.white,
                  fontWeight: theme.typography.Bold,
                },
              }}
            >
              {"help!"}
            </StyledButtonLeft>
            <StyledButtonLeft
              onClick={() => setActiveStep(3)}
              variant={"contained"}
              color={activeStep === 3 ? "activeButton" : "displayCompanyButton"}
              sx={{
                mb: 1,
                borderRadius: "0 10px 10px 0",
                // width: leftExpanded
                //   ? { xs: "150px", lg: "150px", mlg: "150px", xl: "150px" }
                //   : "30px",
                width: leftExpanded ? "125px" : "30px",
                minWidth: leftExpanded ? "125px" : "30px",
                fontWeight:
                  activeStep === 3
                    ? theme.typography.Bold
                    : theme.typography.Regular,
                "&:hover": {
                  backgroundColor: theme?.palette?.activeButton?.main,
                  color: theme?.palette?.white,
                  fontWeight: theme.typography.Bold,
                },
                border: 0,
              }}
            >
              {"bugs"}
            </StyledButtonLeft>
            <StyledButtonLeft
              onClick={() => setActiveStep(4)}
              variant={"contained"}
              color={activeStep === 4 ? "activeButton" : "displayCompanyButton"}
              sx={{
                mb: 1,
                borderRadius: "0 10px 10px 0",
                // width: leftExpanded
                //   ? { xs: "150px", lg: "150px", mlg: "150px", xl: "150px" }
                //   : "30px",
                width: leftExpanded ? "125px" : "30px",
                minWidth: leftExpanded ? "125px" : "30px",
                fontWeight:
                  activeStep === 4
                    ? theme.typography.Bold
                    : theme.typography.Regular,
                "&:hover": {
                  backgroundColor: theme?.palette?.activeButton?.main,
                  color: theme?.palette?.white,
                  fontWeight: theme.typography.Bold,
                },
                border: 0,
              }}
            >
              {"book a demo"}
            </StyledButtonLeft>
            <StyledButtonLeft
              onClick={() => setActiveStep(5)}
              variant={"contained"}
              color={activeStep === 5 ? "activeButton" : "displayCompanyButton"}
              sx={{
                mb: 1,
                borderRadius: "0 10px 10px 0",
                // width: leftExpanded
                //   ? { xs: "150px", lg: "150px", mlg: "150px", xl: "150px" }
                //   : "30px",
                width: leftExpanded ? "125px" : "30px",
                minWidth: leftExpanded ? "125px" : "30px",
                fontWeight:
                  activeStep === 5
                    ? theme.typography.Bold
                    : theme.typography.Regular,
                "&:hover": {
                  backgroundColor: theme?.palette?.activeButton?.main,
                  color: theme?.palette?.white,
                  fontWeight: theme.typography.Bold,
                },
                border: 0,
                padding: "6px 12px",
              }}
            >
              {"suggestion box"}
            </StyledButtonLeft>
          </Box>
          //   <Box
          //     sx={{
          //       display: "flex",
          //       flexDirection: "column",
          //       gap: "10px",
          //       height: {
          //         xs: `calc(100vh - 95px)`,
          //         sm: `calc(100vh - 120px )`,
          //         md: `calc(100vh - 120px)`,
          //         lg: `calc(100vh - 135px)`,
          //         xl: `calc(100vh - 155px)`,
          //         xxl: "calc(100vh - 175px)",
          //       },
          //       width: `${width < 768 && "135px"}`,
          //       paddingTop: `${width < 768 && "10px"}`,
          //       background: `${width > 0 && width < 768 && "#EBECF3"}`,
          //       boxShadow: `${
          //         width > 0 && width < 768 && "0px 5px 10px #00000029"
          //       }`,
          //       opacity: `${width < 768 && "1"}`,
          //       borderRadius: `${width < 768 && "10px"}`,
          //       overflowX: "hidden",
          //       overflowY: leftExpanded ? "scroll" : "unset",
          //     }}
          //     className="filterSec"
          //   >
          //     <Box
          //       sx={{
          //         // maxWidth: `${width < 768 && "150px"}`,
          //         maxWidth: "125px",
          //       }}
          //     >
          //       <ButtonPanel
          //         selected={selectedUserType?.current}
          //         panelData={CONTACT_TYPE}
          //         side="left"
          //         color="filterButtons"
          //         onChangeFilter={onChangeFilter}
          //       />
          //     </Box>
          //     <style>
          //       {`.filterSec::-webkit-scrollbar {
          //           display: none; /* Set the background color of the scrollbar */
          //         }`}
          //     </style>
          //   </Box>
        )}
      </Box>
      <Box
        item
        sx={{
          display: "flex",
          flexGrow: 1,
          alignItems: "center",
          flexDirection: "column",
          width: `calc(100vw - 255px)`,
          position: "sticky",
        }}
        ref={gridRef}
        className="grigBox"
      >
        <Grid
          container
          gap={width <= 768 ? "10px" : `${newPadding}px`}
          columns={{
            xs: 1,
            md: 1,
            lg: 3,
            mlg: 3.5,
            xl: 4,
            xxl: 6,
            xxxl: 8,
          }}
          justifyContent={width <= 768 && "center"}
          sx={{
            position: "relative",
            padding:
              width <= 768
                ? "10px 10px"
                : `0 ${newPadding}px ${newPadding}px ${newPadding}px`,
            top: width <= 768 ? "35px" : "55px",
          }}
        >
          {renderComponent(activeStep)}
        </Grid>
        <style>
          {`.infinite-scroll-component::-webkit-scrollbar {
                      display:none;
              }
              
              .infinite-scroll-component__outerdiv{
                width:100%
              }
                     `}
        </style>
      </Box>

      <Box
        item
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: `${width >= 768 && "10px"}`,
          direction: "rtl",
          position: `${width < 768 ? "absolute" : "relative"}`,
          zIndex: "30",
          // width: rightExpand && "150px",
          right: "0",
          // width: leftExpanded
          //   ? { xs: "150px", mdl: "123px", mlg: "150px", xl: "150px" }
          //   : "30px",
          width: rightExpand ? "125px" : "30px",
          minWidth: rightExpand ? "125px" : "30px",
        }}
      >
        <Button
          variant="contained"
          sx={{
            position: "sticky",
            top: 0,
            borderRadius: "10px 0 0 10px",
            minWidth: "30px",
            width: "30px",
            height: "45px",
          }}
          color="filterButtons"
          onClick={() => {
            setLeftExpand((prevState) => !prevState);
            setRightExpand((prevState) => !prevState);
            dispatch(setFilterExpanded(!filterExpanded));
          }}
        >
          {rightExpand ? <RightArrow /> : <LeftArrow />}
        </Button>
        {rightExpand && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: `${width < 768 && "135px"}`,
              paddingTop: `${width < 768 && "10px"}`,
              background: `${width > 0 && width < 768 && "#EBECF3"}`,
              boxShadow: `${
                width > 0 && width < 768 && "0px 5px 10px #00000029"
              }`,
              opacity: `${width < 768 && "1"}`,
              borderRadius: `${width < 768 && "10px"}`,
              height: {
                xs: `calc(100vh - 95px)`,
                sm: `calc(100vh - 120px )`,
                md: `calc(100vh - 120px)`,
                lg: `calc(100vh - 135px)`,
                xl: `calc(100vh - 155px)`,
                xxl: "calc(100vh - 175px )",
              },
              overflowY: rightExpand ? "scroll" : "unset",
            }}
            className="rightfilterSec"
          >
            <style>
              {`.rightfilterSec::-webkit-scrollbar {
                      display:none;
                    }
                    `}
            </style>
            <Paper
              sx={{
                background: "transparent",
                boxShadow: 0,
                gap: "10px",
                display: "flex",
                flexDirection: "column",
                // maxWidth: `${width < 768 && "150px"}`,
                maxWidth: "125px",
              }}
            >
              <StyledButtonRight
                onClick={() => {}}
                variant={"contained"}
                color={"activeButton"}
                sx={{
                  borderRadius: "10px 0 0 10px",
                  border: 0,
                  // width: leftExpanded
                  //   ? { xs: "150px", lg: "150px", mlg: "150px", xl: "150px" }
                  //   : "30px",
                  width: leftExpanded ? "125px" : "30px",
                  minWidth: leftExpanded ? "125px" : "30px",
                  fontWeight:
                    activeStep === 2
                      ? theme.typography.Bold
                      : theme.typography.Regular,
                  "&:hover": {
                    backgroundColor: theme?.palette?.activeButton?.main,
                    color: theme?.palette?.white,
                    fontWeight: theme.typography.Bold,
                  },
                }}
              >
                {"CrayonTube"}
              </StyledButtonRight>
              <StyledButtonRight
                onClick={() => {}}
                variant={"contained"}
                color={"displayCompanyButton"}
                sx={{
                  borderRadius: "10px 0 0 10px",
                  border: 0,
                  // width: leftExpanded
                  //   ? { xs: "150px", lg: "150px", mlg: "150px", xl: "150px" }
                  //   : "30px",
                  width: leftExpanded ? "125px" : "30px",
                  minWidth: leftExpanded ? "125px" : "30px",
                  fontWeight:
                    activeStep === 2
                      ? theme.typography.Bold
                      : theme.typography.Regular,
                  "&:hover": {
                    backgroundColor: theme?.palette?.activeButton?.main,
                    color: theme?.palette?.white,
                    fontWeight: theme.typography.Bold,
                  },
                }}
              >
                {"video 1"}
              </StyledButtonRight>
              <StyledButtonRight
                onClick={() => {}}
                variant={"contained"}
                color={"displayCompanyButton"}
                sx={{
                  borderRadius: "10px 0 0 10px",
                  border: 0,
                  // width: leftExpanded
                  //   ? { xs: "150px", lg: "150px", mlg: "150px", xl: "150px" }
                  //   : "30px",
                  width: leftExpanded ? "125px" : "30px",
                  minWidth: leftExpanded ? "125px" : "30px",
                  fontWeight:
                    activeStep === 2
                      ? theme.typography.Bold
                      : theme.typography.Regular,
                  "&:hover": {
                    backgroundColor: theme?.palette?.activeButton?.main,
                    color: theme?.palette?.white,
                    fontWeight: theme.typography.Bold,
                  },
                }}
              >
                {"video 2"}
              </StyledButtonRight>
              <StyledButtonRight
                onClick={() => {}}
                variant={"contained"}
                color={"displayCompanyButton"}
                sx={{
                  borderRadius: "10px 0 0 10px",
                  border: 0,
                  // width: leftExpanded
                  //   ? { xs: "150px", lg: "150px", mlg: "150px", xl: "150px" }
                  //   : "30px",
                  width: leftExpanded ? "125px" : "30px",
                  minWidth: leftExpanded ? "125px" : "30px",
                  fontWeight:
                    activeStep === 2
                      ? theme.typography.Bold
                      : theme.typography.Regular,
                  "&:hover": {
                    backgroundColor: theme?.palette?.activeButton?.main,
                    color: theme?.palette?.white,
                    fontWeight: theme.typography.Bold,
                  },
                }}
              >
                {"video 3"}
              </StyledButtonRight>
              <StyledButtonRight
                onClick={() => {}}
                variant={"contained"}
                color={"displayCompanyButton"}
                sx={{
                  borderRadius: "10px 0 0 10px",
                  border: 0,
                  // width: leftExpanded
                  //   ? { xs: "150px", lg: "150px", mlg: "150px", xl: "150px" }
                  //   : "30px",
                  width: leftExpanded ? "125px" : "30px",
                  minWidth: leftExpanded ? "125px" : "30px",
                  fontWeight:
                    activeStep === 2
                      ? theme.typography.Bold
                      : theme.typography.Regular,
                  "&:hover": {
                    backgroundColor: theme?.palette?.activeButton?.main,
                    color: theme?.palette?.white,
                    fontWeight: theme.typography.Bold,
                  },
                }}
              >
                {"video 4"}
              </StyledButtonRight>
            </Paper>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default Contact;
