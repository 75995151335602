import React, { useCallback } from "react";
import {
  getAdminTalentJobList,
  getLinkedJobTalent,
  removeLinkedJob,
} from "../../../../redux/admin/jobsSlice";
import { setAlert } from "../../../../redux/configSlice";
import { ALERT_TYPE, ERROR_MSG } from "../../../../utils/Constants";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { useState } from "react";
import logo from "../../../../assets/Crayon_Favicon.svg";
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  InputBase,
  Paper,
  Tooltip,
  Typography,
} from "@mui/material";
import { CheckCircle, Circle } from "@mui/icons-material";
import { useTheme } from "@emotion/react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useParams } from "react-router-dom";
import TalentSVGButton from "../../../common/TalentSVGButton";
import cancel from "../../../../assets/CircularIcon/Red/Circular Icons__Red_Close_2.svg";
import locale from "../../../../i18n/locale";
import { debounce } from "../../../../utils/Common";

export default function LinkedToJob({
  handleCloseLinkedToJob,
  setAllJobs,
  getJobList,
  setLinkedJobCount,
  linkedJobCount,
  setJobClick,
  // handleAddToJob
  handleCancel,
  selectedJob,
  setSelectedJob,
  setAnchorElLinkedJob,
  setLinkJobButton,
  getPoolDetails,
}) {
  const dispatch = useDispatch();
  const i18n = locale.en;
  const theme = useTheme();
  const { poolId } = useParams();
  const [activeJobs, setActiveJobs] = useState([]);
  const [lastKey, setLastKey] = useState("");
  const [totalJob, setTotalJob] = useState(0);
  const [hasMoreData, setHasMoreData] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [searchText, setSearchText] = useState("");

  const getLinkedJobsList = async (lastkeyy, query) => {
    let data = {
      lastKey: lastkeyy,
      pool_id: poolId,
      query: query,
    };
    const { payload } = await dispatch(getAdminTalentJobList(data));
    if (payload?.status === "success") {
      payload?.data?.length === 0 && setHasMoreData(false);
      if (lastkeyy === "") {
        setLastKey(payload?.pageNumber + 1);
        setActiveJobs(payload?.data);
        setTotalJob(payload?.talentCount);
      } else {
        setLastKey(payload?.pageNumber + 1);
        setActiveJobs((prevState) => [...prevState, ...payload?.data]);
        setTotalJob(payload?.talentCount);
      }
    } else if (payload === undefined) {
      return;
    } else {
      dispatch(
        setAlert({
          show: true,
          type: ALERT_TYPE?.ERROR,
          msg: "Something went wrong! please relaod the window",
        })
      );
    }
  };

  const removeLinkedJobFromPool = async (jobid) => {
    setActiveJobs(
      activeJobs?.map((item) =>
        item?.job_id === jobid
          ? { ...item, linkStatus: !item?.linkStatus }
          : item
      )
    );
    let updatedJobList = [...selectedJob];
    // if (!updatedJobList?.includes(jobid)) {
    //   updatedJobList?.push(jobid);
    // } else {
    updatedJobList = updatedJobList?.filter((item) => item !== jobid);
    // }
    setSelectedJob([...updatedJobList]);

    try {
      const data = {
        pool_id: poolId,
        job_id: jobid,
      };
      const { payload } = await dispatch(removeLinkedJob(data));
      if (payload?.status === "success") {
        linkedJobCount -= 1;
        setLinkedJobCount(linkedJobCount);
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE?.SUCCESS,
            msg: "Linked job removed successfully",
          })
        );
        await getJobList(0, "", updatedJobList);
      }
    } catch (error) {
      dispatch(
        setAlert({
          show: true,
          type: ALERT_TYPE?.ERROR,
          msg: error,
        })
      );
    }
  };

  useEffect(() => {
    if (selectedJob?.length > 0) {
      setButtonDisable(false);
    }
    const updatedActiveJobs = activeJobs.map((item) => ({
      ...item,
      linkStatus: selectedJob.includes(item.job_id) ? true : item.linkStatus,
    }));
    setActiveJobs(updatedActiveJobs);
  }, [selectedJob]);

  const getLinkedJobTalentList = async (jobid) => {
    let updatedJobList = [...selectedJob];
    if (!updatedJobList?.includes(jobid)) {
      updatedJobList?.push(jobid);
    } else {
      updatedJobList = updatedJobList?.filter((item) => item !== jobid);
    }
    setSelectedJob([...updatedJobList]);
    const data = {
      pool_id: poolId,
      job_id: jobid,
    };
    await getJobList(0, "", updatedJobList);
    // const { payload } = await dispatch(getLinkedJobTalent(data));
    setActiveJobs(
      activeJobs?.map((item) =>
        item?.job_id === jobid
          ? { ...item, linkStatus: !item?.linkStatus }
          : item
      )
    );
  };

  const [buttonDisable, setButtonDisable] = useState(false);

  const handleAddToJob = async () => {
    if (buttonDisable) {
      return;
    }
    const data = {
      pool_id: [Number(poolId)],
      job_id: selectedJob,
    };
    try {
      setButtonDisable(true);
      const { payload } = await dispatch(getLinkedJobTalent(data));
      if (payload?.status === "success") {
        setLinkedJobCount(payload?.count);
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE?.SUCCESS,
            msg: "Job linked successfully",
          })
        );
        setSelectedJob([]);
        setAnchorElLinkedJob(null);
        setLinkJobButton(false);
        await getPoolDetails();
        await getJobList(0, "", []);
      } else {
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE?.ERROR,
            msg: "Something went wrong!",
          })
        );
      }
    } catch (error) {
      dispatch(
        setAlert({
          show: true,
          type: ALERT_TYPE?.ERROR,
          msg: "Something went wrong!",
        })
      );
    }
  };
  const getJobStatusColor = (status) => {
    switch (status) {
      case "active":
        return "lightGreenButton300";
      case "paused":
        return "redButton100";
      case "closed":
        return "redButton100";
      case "pending":
        return "orangeButton";
      default:
        return "redButton100";
    }
  };

  const debouncedHandleOnTextSearch = useCallback(
    debounce(
      async (newValue) => {
        if (!newValue) return; // Early return
        try {
          setLastKey(0);
          setActiveJobs([]);
          const response = await getLinkedJobsList(0, newValue?.target?.value);

          // if (response && response?.payload) {
          //   const { payload } = response;
          //   dispatch(
          //     payload?.status === "success"
          //       ? setMatch((prevState) => [
          //           ...prevState,
          //           ...payload?.data.map((item) => ({
          //             ...item,
          //             job_logo_url: item?.profile_url,
          //           })),
          //         ])
          //       : setAlert({
          //           show: true,
          //           type: ALERT_TYPE?.ERROR,
          //           msg: ERROR_MSG,
          //         })
          //   );
          // }
        } catch (error) {
          dispatch(
            setAlert({ show: true, type: ALERT_TYPE?.ERROR, msg: ERROR_MSG })
          );
        }
      },
      500, // Adjust delay as needed
      setIsLoading
    ),
    [dispatch, setActiveJobs, setIsLoading]
  );

  const handleSearch = async (event) => {
    setLastKey(0);
    setSearchText(event?.target?.value);
    setActiveJobs([]);
    debouncedHandleOnTextSearch(event);
  };

  useEffect(() => {
    getLinkedJobsList(0, searchText);
  }, []);

  return (
    <>
      <Box
        sx={{
          padding: "16px !important",
        }}
      >
        {/* <Box sx={{ display: "flex", gap: "10px", flexDirection: "column" }}> */}
        {/* <Box sx={{ display: "flex", justifyContent: "space-between" }}> */}
        <Typography
          sx={{
            fontSize: "12px",
            fontWeight: 700,
          }}
        >
          Link a job
        </Typography>
        {/* <IconButton
            aria-label="close"
            onClick={() => {
              handleCloseLinkedToJob();
            }}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
            disableRipple="true"
          >
            <Box
              sx={{
                width: "20px",
                height: "20px",
                color: "#000000",
                border: 2,
                fontSize: "18px",
                borderRadius: "5px",
              }}
            >
              X
            </Box>
          </IconButton> */}
        {/* </Box> */}
        <Typography sx={{ fontSize: "11px", fontWeight: 700, my: 1 }}>
          {i18n["popupBoxText.linkAJob"]}
        </Typography>

        <Paper
          elevation={0}
          component="form"
          sx={{
            padding: "0px 10px",
            border: "1px solid #C9CAD8",
          }}
          height={"40px"}
          width={"308px"}
        >
          <InputBase
            sx={{
              fontWeight: theme.typography.Regular,
              width: "308px",
            }}
            placeholder={"Search for a job"}
            // value={searchText}
            onKeyDown={(event) => {
              if (event.key === "Enter") {
                event.preventDefault(); // Stop page reload
              }
            }}
            onChange={(event) => {
              handleSearch(event);
            }}
          />
        </Paper>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            overflowY: "auto",
            marginTop: "10px",
            height: "380px",
            "::-webkit-scrollbar": {
              height: "5px",
              background: "#DCDEEB",
              display: "unset !important",
            },
          }}
          id="matchTaleneList"
        >
          <InfiniteScroll
            style={{
              overflowX: "hidden",
              scrollbarWidth: "thin",
              display: "flex",
              flexDirection: "column",
              gap: "10px",
            }}
            dataLength={activeJobs?.length || 0}
            next={() => getLinkedJobsList(lastKey, searchText)}
            hasMore={hasMoreData}
            loader={
              <Box
                style={{
                  margin: "15px 0 0 0",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CircularProgress color="inherit" size={20} />
              </Box>
            }
            scrollableTarget="matchTaleneList"
            scrollThreshold={"50px"}
          >
            {activeJobs?.length > 0 ? (
              activeJobs?.map((item) => (
                <Box
                  key={item?.job_id}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "9px",
                    justifyContent: "space-between",
                  }}
                >
                  <Box
                    sx={{ display: "flex", alignItems: "center", gap: "9px" }}
                  >
                    <Box
                      component={"img"}
                      src={
                        item?.profile_url !== "No URL"
                          ? item?.profile_url
                          : logo
                      }
                      sx={{
                        height: "50px",
                        width: "50px",
                        borderRadius: "10px",
                      }}
                    />
                    <Tooltip
                      disableInteractive
                      title={item?.title}
                      placement="top-start"
                    >
                      <Typography
                        sx={{
                          fontSize: "15px",
                          fontWeight: theme.typography.Bold,
                          // wordBreak: "break-word",
                          cursor: "pointer",
                          width:
                            item?.linkStatus ||
                            selectedJob?.includes(item?.job_id)
                              ? "155px"
                              : "200px",
                          // width: "165px",
                        }}
                        onClick={() =>
                          // !item?.linkStatus &&
                          getLinkedJobTalentList(item?.job_id)
                        }
                        noWrap
                      >
                        {item?.title}
                      </Typography>
                    </Tooltip>
                  </Box>

                  <Box
                    sx={{ display: "flex", alignItems: "center", gap: "8px" }}
                  >
                    {(item?.linkStatus ||
                      selectedJob?.includes(item?.job_id)) && (
                      <>
                        <CheckCircle
                          sx={{
                            fontSize: 27,
                            cursor: "pointer",
                          }}
                          color="checkColor"
                        />
                      </>
                    )}
                  </Box>
                  <Circle
                    sx={{ fontSize: "25px" }}
                    color={getJobStatusColor(item?.job_status)}
                  />
                </Box>
              ))
            ) : (
              <Box
                sx={{
                  width: "100%",
                  textAlign: "center",
                  mt: 4,
                  color: "black",
                }}
              >
                No jobs
              </Box>
            )}
          </InfiniteScroll>
        </Box>
        {/* </Box> */}
      </Box>
      <Box>
        <Button
          variant="contained"
          sx={{
            width: "50%",
            height: "60px",
            borderRadius: "0 0 0 25px",
            color: "black",
          }}
          color={"closeButton"}
          onClick={handleCloseLinkedToJob}
        >
          {i18n["popupBoxText.cancel"]}
        </Button>
        <Button
          variant="contained"
          sx={{
            width: "50%",
            height: "60px",
            borderRadius: "0 0 25px 0px",
          }}
          color={"redButton100"}
          onClick={() => {
            // addToJob(jobTextRef?.current, talentContent?.user_id);
            handleAddToJob();
          }}
        >
          {i18n["popupBoxText.addtopool"]}
        </Button>
      </Box>
    </>
  );
}
