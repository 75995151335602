import { useEffect, useRef, useState } from "react";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import locale from "../../../i18n/locale";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import SmallButton from "../../common/SmallButton";
import profile from "../../../assets/Crayon_Favicon.svg";
import userLogo from "../../../assets/user_logo.svg";
import { Divider, Grid, InputBase, Paper, Popover } from "@mui/material";
import upClose from "../../../assets/Padding Included/Black_Up_Close.svg";
import downClose from "../../../assets/Padding Included/Black_Down_Open.svg";
import JobExp from "../../../assets/Padding Excluded/Black_Experience_Title_Job.svg";
import RedView from "../../../assets/CircularIcon/Red/Circular Icons__Red_View copy.svg";
import RedViewGrey from "../../../assets/CircularIcon/Grey/CIrcular_Icon_Grey_View.svg";
import BlueFolder from "../../../assets/CircularIcon/Blue/Circular Icons__Blue_Title_Job_Experience.svg";
import BlueFolderGrey from "../../../assets/CircularIcon/Grey/CIrcular_Icon_Grey_Folder.svg";
import GreenPlay from "../../../assets/CircularIcon/Green/Circular Icons__Green_Play copy.svg";
import GreenPlayGrey from "../../../assets/CircularIcon/Grey/Circular_Icon_Grey_Play.svg";
import YellowChatHistory from "../../../assets/CircularIcon/Yellow/Circular Icons__Yellow_Chat History_2 copy.svg";
import talentIcon from "../../../assets/HomePage/Crayon_Direct.svg";

import deleteIcon from "../../../assets/Padding Excluded/Black_Trash_Delete_1 - Copy.svg";
import upArrowWhite from "../../../assets/Black_Up_Close.svg";
import downArrowWhite from "../../../assets/Black_Down_Open.svg";
import upArrow from "../../../assets/Black_Up_Close - Copy.svg";
import downArrow from "../../../assets/Black_Down_Open - Copy.svg";

import {
  addJobComment,
  addJobToTalentPool,
  addTalentPool,
  editJobBounty,
  getAllComments,
  getTalentPool,
  lockAJob,
  pinAJob,
  removeLinkedJob,
} from "../../../redux/admin/jobsSlice";
import { useDispatch } from "react-redux";
import { setAlert } from "../../../redux/configSlice";
import { ALERT_TYPE } from "../../../utils/Constants";
import {
  convertDatetimeAgo,
  convertDatetimeWithValue,
  convertDatetimeWithoutAgo,
  dateConverterMonth,
} from "../../../utils/DateTime";
import { Link, useLocation, useParams } from "react-router-dom";
import jwt_decode from "jwt-decode";
import { formatCurrencyWithCommas } from "../../../utils/Currency";
import ChangeStatusButton from "./ChangeStatusButton";
import InfiniteScroll from "react-infinite-scroll-component";
import TalentSVGButton from "../../common/TalentSVGButton";
import { truncate } from "lodash";
import JobAlert from "./JobAlert";
import Refferals from "../adminTalent/DialogBox/Refferals";
import DOWN from "../../../assets/Padding Excluded/Black_Down_Open.svg";
import contact from "../../../assets/Padding Excluded/Black_Contact_Yellow.svg";
import BlueCompany from "../../../assets/Padding Excluded/Blue_Following_Company-.svg";
import email from "../../../assets/Padding Excluded/Black_Email_Teal.svg";
import referrals from "../../../assets/Padding Excluded/Black_Referals_Yellow.svg";
import linkedin from "../../../assets/linkedin.svg";
import locationIcon from "../../../assets/Padding Excluded/Black_Location.svg";
import pending from "../../../assets/Padding Excluded/Black_Pending.svg";
import salary from "../../../assets/Padding Excluded/Black_Salary.svg";
import notice from "../../../assets/Padding Excluded/Black_Notice_Period.svg";
import AddToPool from "../adminTalent/DialogBox/AddToPool";
import CopyToClipboard from "react-copy-to-clipboard";
import editIcon from "../../../assets/Padding Included/Yellow_Edit.svg";
import pinIcon from "../../../assets/Icons/pinIcon.svg";
import editBounty from "../../../assets/Padding Excluded/Black_Edit.svg";
import EditJobDetail from "./editJobs/EditJobDetail";
import {
  createMarkup,
  getDaysRemainingColor,
  getDecodedToken,
  getToken,
  renderColor,
} from "../../../utils/Common";
import {
  NewJobsCardAccordion,
  StyledTextField,
} from "../../../utils/CommonComponent";
import SVGButton from "../../common/SVGButton";
import CopyToClipboardIcon from "../../../assets/CircularIcon/Yellow/Circular Icons__Yellow_Move copy.svg";
import CopyToClipboardIconGrey from "../../../assets/CircularIcon/Grey/CIrcular_Icon_Grey_Copy.svg";
import { formatPhoneNumberIntl } from "react-phone-number-input";
import JobCommentBox from "./JobCommentBox";
import HistoryBox from "../../common/HistoryBox";
import EditBounty from "./EditBounty";
import { getPromoterJobReferrals } from "../../../redux/candidate/candidateJobs";
import AssignJobBox from "./AssignJobBox";
import Bounty from "./DialogBox/Bounty";
import linkToPool from "../../../assets/Black_Add_To_Pool.svg";
import referralsIcon from "../../../assets/Padding Excluded/TrackIcon.svg";
import commentIcon from "../../../assets/Padding Excluded/Black_Chat.svg";
import personalityIcon from "../../../assets/Padding Included/User_Profile.svg";
import questionIcon from "../../../assets/Padding Excluded/Crayon Icon_Experience_White.svg";
import leftIcon from "../../../assets/WhiteIcons/Left_Arrow.svg";
import rightIcon from "../../../assets/WhiteIcons/Right_Arrow.svg";
import LinkToPoolBox from "./DialogBox/LinkToPoolBox";
import JobInfoBox from "./DialogBox/JobInfoBox";
import CommentBox from "./DialogBox/CommentBox";
import QuestionsBox from "./DialogBox/QuestionsBox";
import PersonalityBox from "./DialogBox/PersonalityBox";
import ReferralsBox from "./DialogBox/ReferralsBox";
import RunMatchBox from "./DialogBox/RunMatchBox";
import DocumentBox from "./DialogBox/DocumentBox";
import VideoBox from "./DialogBox/VideoBox";
import lockIcon from "../../../assets/Padding Excluded/Black_Lock.svg";
import unlockIcon from "../../../assets/Padding Excluded/White_Unlock.svg";
import VideoApp from "./DialogBox/VideoApp";
import CoinSpendBox from "../../common/CoinSpendBox";
import {
  getJobCoins,
  unlockJob,
} from "../../../redux/employer/empProfileSlice";
import AdminUnlockJob from "./DialogBox/AdminUnlockJob";
import AdminLockJob from "./DialogBox/AdminLockJob";
import post_incognito from "../../../assets/Incognito.svg";
import PinConfirmation from "./editJobs/PinConfirmation";
import SuccessPopup from "./DialogBox/SuccessPopup";

const label = "grit score";
const label1 = "applied";
const label2 = "shortlisted";

export default function JobsCardComponent({
  index,
  jobContentData,
  getJobList,
  setAllJobs,
  setJobsLastKey,
  basicData,
  page,
  setTotalJob,
  allJobs,
}) {
  const i18n = locale.en;
  const theme = useTheme();
  const dispatch = useDispatch();
  const poolIDRef = useRef(null);
  const assignJobRef = useRef(null);
  const [isOpenHistoryBox, setOpenHistoryBox] = useState(false);
  const { id } = useParams();
  const [buttonDisable, setButtonDisable] = useState(false);
  const [flip, setFlip] = useState(id ? true : false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [lastKey, setLastKey] = useState(0);
  const [tableData, setTableData] = useState([]);
  const [openEdit, setOpenEdit] = useState(false);
  const [editJob, setEditJob] = useState();
  const [totalPoolData, setTotalPoolData] = useState();
  const [openEditBounty, setOpenEditBounty] = useState(false);
  const [count, setCount] = useState(0);
  const [assignJob, setAssignJob] = useState(false);
  const anchorElAssign = Boolean(assignJob);
  const [referralsData, setReferralsData] = useState([]);
  const [lastKeyReferrals, setLastKeyReferrals] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [jobContent, setJobContent] = useState(jobContentData);
  const [viewMoreExpanded, setViewMoreExpanded] = useState(false);
  const [openBounty, setOpenBounty] = useState(false);
  const [openSuccessRef, setOpenSuccessRef] = useState(false);
  const anchorElBounty = Boolean(openBounty);
  const bountyRef = useRef(null);
  const successRef = useRef(null)
  const anchorElSuccess = Boolean(openSuccessRef);

  const location = useLocation();
  const include = location.pathname.includes("pending-jobs");

  const [linkToPoolButton, setLinkToPool] = useState(null);
  const anchorElLinkToPool = Boolean(linkToPoolButton);
  const linkToPoolRef = useRef(null);

  const [jobInfoButton, setJobInfoButton] = useState(null);
  const anchorElJobInfo = Boolean(jobInfoButton);
  const jobInfoRef = useRef(null);

  const [commentButton, setCommentButton] = useState(null);
  const anchorElComment = Boolean(commentButton);
  const commentRef = useRef(null);

  const [personalityButton, setPersonalityButton] = useState(null);
  const anchorElPersonality = Boolean(personalityButton);
  const personalityRef = useRef(null);

  const [questionButton, setQuestionButton] = useState(null);
  const anchorElQuestion = Boolean(questionButton);
  const questionRef = useRef(null);

  const [referralButton, setReferralButton] = useState(null);
  const anchorElReferral = Boolean(referralButton);
  const referralRef = useRef(null);

  const [openMatchBox, setOpenMatchBox] = useState(null);
  const anchorElMatchBox = Boolean(openMatchBox);
  const counterRef = useRef(null);

  const [openVideoApp, setOpenVideoApp] = useState(null);
  const anchorElVideoApp = Boolean(openVideoApp);
  const videoAppRef = useRef(null);

  const [openDocument, setOpenDocument] = useState(null);
  const [openVideo, setOpenVideo] = useState(null);
  const [anchorElDocs, setAnchorElDocs] = useState(null);

  const [totalComment, setTotalComment] = useState(0);
  const [view, setView] = useState(false);
  const viewRef = useRef(false);
  const copyLinkRef = useRef(false);
  const [comments, setComments] = useState([]);

  const decodedToken = getDecodedToken(getToken());

  const [coinSpend, setCoinSpend] = useState(false);
  const [noCoins, setNoCoins] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const [coinsInfo, setCoinsInfo] = useState();
  const [selectedJob, setSelectedJob] = useState();
  const [getCoins, setGetCoins] = useState(false);
  const [adminUnlock, setAdminUnlock] = useState(false);
  const [showUnlockBox, setShowUnlockBox] = useState(false);
  const [confirmUnlock, setConfirmUnlock] = useState(false);
  const [confirmLock, setConfirmLock] = useState(false);
  const [openLock, setOpenLock] = useState(false);
  // const [confirmPin, setConfirmPin] = useState(false);
  const [openPinConfirmation, setOpenPinConfirmation] = useState(false);
  const [confirmPin, setConfirmPin] = useState(false);

  const planType = decodedToken?.data?.plan_type;
  const handleView = () => {
    setView((prev) => !prev);
    viewRef.current = !viewRef.current;
    window.open(
      jobContent?.post_incognito
        ? `/jobs/${`${jobContent?.region_name?.replace(
            /\s|\//g,
            ""
          )}/${jobContent?.town_name?.replace(
            /\s|\//g,
            ""
          )}`}/${jobContent?.title?.replace(/\s|\//g, "")}/${
            jobContent?.job_id
          }`
        : `/jobs/${`${jobContent?.region_name?.replace(
            /\s|\//g,
            ""
          )}/${jobContent?.town_name?.replace(
            /\s|\//g,
            ""
          )}`}/${jobContent?.CompanyName?.replace(
            /\s|\//g,
            ""
          )}/${jobContent?.title?.replace(/\s|\//g, "")}/${jobContent?.job_id}`
    );
  };

  const handleCopyUrl = () => {
    copyLinkRef.current = !copyLinkRef.current;
    const url = `${window.location.origin}/admin/job-detail/${`${
      jobContent?.town_name?.replace("/", "") +
      " " +
      jobContent?.region_name?.replace("/", "")
    }`}/${jobContent?.job_id}`;

    navigator.clipboard
      .writeText(url)
      .then(() => {
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE?.SUCCESS,
            msg: "Copied to clipboard",
          })
        );
      })
      .catch((error) => {});
  };

  const handleViewMore = () => {
    setViewMoreExpanded((prevState) => !prevState);
  };

  const handleDocument = (event) => {
    setOpenDocument(true);
    setAnchorElDocs(event?.target);
  };
  const handleCloseDocument = (event) => {
    setOpenDocument(false);
    setAnchorElDocs(null);
  };

  const handleVideo = (event) => {
    setOpenVideo(true);
    setAnchorElDocs(event?.target);
  };
  const handleCloseVideo = (event) => {
    setOpenVideo(false);
    setAnchorElDocs(null);
  };


  const getTalent = async (lastkeyy) => {
    try {
      const { payload } = await dispatch(
        getTalentPool({ lastKey: lastkeyy, job_id: jobContent?.job_id })
      );
      if (payload.status === "success") {
        setTotalPoolData(payload?.poolCount);
        if (lastkeyy === 0) {
          setTableData(payload.data);
          setLastKey(payload.pageNumber + 1);
        } else {
          setLastKey(payload.pageNumber + 1);
          setTableData((prevState) => [...prevState, ...payload.data]);
        }
      }
    } catch (error) {}
  };

  const handleClick = async (event) => {
    setTableData([]);
    setLinkToPool(linkToPoolRef?.current);
    await getTalent("");
  };


  const addToPool = async (canID, poolID) => {
    // if (poolID === null || buttonDisable) {
    //   return;
    // }
    // let updatedList = tableData?.map((item) => {
    //   if (item?.pool_id === poolID) {
    //     return {
    //       ...item,
    //       poolUserFlag: true,
    //     };
    //   }
    //   return item;
    // });
    // setTableData([...updatedList]);
    if (!buttonDisable) {
      return;
    }
    let poolIDs = tableData
      ?.filter((item) => item?.addToPoolFlag === true)
      ?.map((item) => item?.pool_id);
    let removePools = tableData
      ?.filter((item) => item?.removePoolFlag === true)
      ?.map((item) => item?.pool_id);
    try {
      const data = {
        job_id: [canID],
        pool_id: poolIDs,
        removePoolIDS: removePools,
      };

      setButtonDisable(false);
      const { payload } = await dispatch(addJobToTalentPool(data));
      if (payload?.status === "success") {
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE?.SUCCESS,
            msg: i18n["alertMessage.Jobaddedtopoolsuccessfully"],
          })
        );
        // setAnchorEl(null);
        poolIDRef.current = null;
        setButtonDisable(false);
      } else {
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE?.ERROR,
            msg: payload?.message?.message,
          })
        );
      }
    } catch (error) {}
  };

  const removeLinkedJobFromPool = async (jobid, poolId) => {
    try {
      const data = {
        pool_id: poolId,
        job_id: jobid,
      };
      const { payload } = await dispatch(removeLinkedJob(data));
      let updatedList = tableData?.map((item) => {
        if (item?.pool_id === poolId) {
          return {
            ...item,
            poolUserFlag: false,
          };
        }
        return item;
      });
      setTableData([...updatedList]);
      if (payload?.status === "success") {
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE?.SUCCESS,
            msg: "Linked job removed successfully",
          })
        );
        await getJobList(0);
      }
    } catch (error) {
      dispatch(
        setAlert({
          show: true,
          type: ALERT_TYPE?.ERROR,
          msg: error,
        })
      );
    }
  };

  const handleEditBounty = async (amount) => {
    try {
      const data = {
        job_id: jobContent?.job_id,
        bounty_amount: amount,
      };
      const { payload } = await dispatch(editJobBounty(data));
      if (payload?.status === "success") {
        setOpenEditBounty(false);
        setAllJobs((prev) =>
          prev.map((item) =>
            item?.job_id === jobContent?.job_id
              ? { ...item, bounty_amount: amount }
              : item
          )
        );
        dispatch(
          setAlert({
            show: true,
            alert: ALERT_TYPE?.SUCCESS,
            msg: "Bounty updated successfully",
          })
        );
      }
    } catch (error) {
      dispatch(
        setAlert({
          show: true,
          alert: ALERT_TYPE?.ERROR,
          msg: "ERROR",
        })
      );
    }
  };

  const handleEmployerEdit = (jobId) => {
    setOpenEdit(true);
    setEditJob(jobId);
  };

  const handleCloseEdit = () => {
    setOpenEdit(false);
  };

  const getJobsReferrals = async (lastkey) => {
    try {
      const data = {
        lastKey: lastkey,
        job_id: jobContent?.job_id,
      };
      const { payload } = await dispatch(getPromoterJobReferrals(data));
      if (payload?.status === "success") {
        setCount(payload?.count);
        if (payload?.data?.length === 0) {
          setReferralsData([]);
        } else {
          if (payload?.pagenumber === 0) {
            setLastKeyReferrals(payload?.pagenumber + 1);
            setReferralsData(payload?.data);
          } else {
            setLastKeyReferrals(payload?.pagenumber + 1);
            setReferralsData((prevState) => [...prevState, ...payload?.data]);
          }
        }
        setIsLoading(false);
      } else if (payload?.status === "fail") {
        setCount(payload?.count);
        return;
      } else {
        setIsLoading(false);
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE?.ERROR,
            msg: payload?.message,
          })
        );
      }
    } catch (error) {
      setIsLoading(false);
      dispatch(
        setAlert({
          show: true,
          type: ALERT_TYPE?.ERROR,
          msg: "Something went wrong! please relaod the window",
        })
      );
    }
  };

  const getComments = async (sort) => {
    try {
      const { payload } = await dispatch(
        getAllComments({ job_id: jobContent?.job_id, sort_type: sort })
      );
      if (payload?.status == "success") {
        setComments(payload?.data);
        setTotalComment(payload?.count);
      } else {
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE?.ERROR,
            msg: payload?.message,
          })
        );
      }
    } catch (error) {
      dispatch(
        setAlert({
          show: true,
          type: ALERT_TYPE?.ERROR,
          msg: error,
        })
      );
    }
  };

  const handleCloseAssign = () => {
    setAssignJob(null);
  };

  const unlockAJob = async (charge) => {
    try {
      let data = {
        job_id: jobContent?.job_id,
        unlock_amount: coinsInfo?.unlock_amount,
        company_id: jobContent?.company_id,
        transaction_name_id: coinsInfo?.transaction_name_id,
        employer_unlock: charge,
      };
      const { payload } = await dispatch(unlockJob(data));
      if (payload?.status === "success") {
        if (
          decodedToken?.data?.role_id === 4 ||
          decodedToken?.data?.role_id === 6
        ) {
          if (coinsInfo?.coins === 0) {
            setNoCoins(false);
            setConfirm(true);
          } else {
            setCoinSpend(false);
            // setConfirm(true);
          }
        } else {
          setGetCoins(false);
          setShowUnlockBox(false);
        }
        let newJobs = allJobs?.map((item) =>
          item?.job_id === jobContent?.job_id
            ? {
                ...item,
                job_unlock: true,
              }
            : item
        );
        setAllJobs(newJobs);
        setConfirmUnlock(false);
        // dispatch(
        //   setAlert({
        //     show: true,
        //     type: ALERT_TYPE?.SUCCESS,
        //     msg: "Job unlocked successfully",
        //   })
        // );
      } else {
        // dispatch(
        //   setAlert({
        //     show: true,
        //     type: ALERT_TYPE?.ERROR,
        //     msg: payload?.message,
        //   })
        // );
      }
    } catch (error) {
      dispatch(
        setAlert({
          show: true,
          type: ALERT_TYPE?.ERROR,
          msg: error,
        })
      );
    }
  };

  const getCoinBalance = async () => {
    try {
      const { payload } = await dispatch(
        getJobCoins({
          job_id: jobContent?.job_id,
          company_id: jobContent?.company_id,
        })
      );
      if (payload?.status === "success") {
        setCoinsInfo(payload?.data);
        if (
          decodedToken?.data?.role_id === 4 ||
          decodedToken?.data?.role_id === 6
        ) {
          if (payload?.data?.coins === 0) {
            setCoinSpend(false);
            setNoCoins(true);
          } else {
            setNoCoins(false);
            setCoinSpend(true);
          }
        } else {
          setShowUnlockBox(true);
        }
        setGetCoins(false);
        setAdminUnlock(false);
      }
    } catch (error) {}
  };

  const handleNoCoinsButtonClick = () => {
    unlockAJob(true);
    // setNoCoins(false);
    // setConfirm(true);
  };

  const handleAdminJobUnlock = (isCharge) => {
    unlockAJob(isCharge);
  };

  const handleCoinSpendButtonClick = () => {
    unlockAJob(true);
    // setCoinSpend(false);
    // setConfirm(true);
  };

  const handleConfirmButtonClick = () => {
    setConfirm(false);
    setCoinSpend(false);
    setNoCoins(false);
  };

  const handleTalentUnlock = () => {
    if (
      decodedToken?.data?.role_id === 4 ||
      decodedToken?.data?.role_id === 6
    ) {
      setGetCoins(true);
    } else if (decodedToken?.data?.role_id !== 8) {
      setAdminUnlock(true);
    }
  };

  const handleTalent = () => {
    if (
      (decodedToken?.data?.role_id === 4 && jobContent?.job_unlock) ||
      (decodedToken?.data?.role_id === 6 && jobContent?.job_unlock)
    ) {
      window.open(`/employer/manage-talent/${jobContent?.job_id}`);
    } else if (
      (!include && decodedToken?.data?.role_id === 1) ||
      (!include && decodedToken?.data?.role_id === 2) ||
      (!include && decodedToken?.data?.role_id === 8)
    ) {
      window.open(`/employer/manage-talent/${jobContent?.job_id}`);
    } else {
      handleTalentUnlock();
    }
  };

  const handlePinJob = async () => {
    try {
      let data = {
        job_id: jobContent?.job_id,
        value: !jobContent?.pin_job,
      };
      const { payload } = await dispatch(pinAJob(data));
      if (payload?.status === "success") {
        setAllJobs((prevAllJobs) => {
          const unPinnedJob = prevAllJobs
            ?.filter((item) => item?.job_id === jobContent?.job_id)
            ?.map((item) => ({ ...item, pin_job: !jobContent?.pin_job }))[0];
          const pinnedJobs = prevAllJobs?.filter(
            (item) => item?.pin_job && item?.job_id !== jobContent?.job_id
          );
          const unPinnedJobs = prevAllJobs?.filter((item) => !item?.pin_job);
          return [...pinnedJobs, unPinnedJob, ...unPinnedJobs];
        });
        setOpenPinConfirmation(false);
        setConfirmPin(false);
      } else {
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE?.ERROR,
            msg: "Error",
          })
        );
      }
    } catch (error) {
      dispatch(
        setAlert({
          show: true,
          type: ALERT_TYPE?.ERROR,
          msg: error,
        })
      );
    }
  };

  const handleLockJob = async () => {
    try {
      let data = {
        job_id: jobContent?.job_id,
      };
      const { payload } = await dispatch(lockAJob(data));
      if (payload?.status === "success") {
        let newJobs = allJobs?.map((item) =>
          item?.job_id === jobContent?.job_id
            ? {
                ...item,
                job_unlock: false,
              }
            : item
        );
        setAllJobs(newJobs);
        setOpenLock(false);
        setConfirmLock(false);
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE?.SUCCESS,
            msg: "Job locked successfully",
          })
        );
      } else {
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE?.ERROR,
            msg: "Error",
          })
        );
      }
    } catch (error) {
      dispatch(
        setAlert({
          show: true,
          type: ALERT_TYPE?.ERROR,
          msg: error,
        })
      );
    }
  };

  useEffect(() => {
    if (getCoins || adminUnlock) {
      getCoinBalance();
    }
  }, [getCoins, adminUnlock]);

  useEffect(() => {
    if (
      flip &&
      referralsData?.length === 0 &&
      jobContentData?.length !== 0 &&
      jobContent?.length !== 0
    ) {
      getJobsReferrals(0);
      getComments("");
    }
  }, [flip, jobContentData, jobContent]);

  useEffect(() => {
    setJobContent(jobContentData);
  }, [jobContentData]);

  return (
    <>
      <NewJobsCardAccordion expanded={flip}>
        <AccordionSummary aria-controls="panel1-content" id="panel1-header">
          <Box
            sx={{
              display: "flex",
              width: "50%",
              gap: "15px",
              alignItems: "center",
            }}
          >
            <SmallButton
              color="redButton"
              startIcon={
                <Box
                  component="img"
                  className="eye"
                  alt="eye logo"
                  src={flip ? upClose : downClose}
                  sx={{
                    height: 16,
                    width: { xs: 10, lg: 8, mlg: 10, xl: 15 },
                  }}
                />
              }
              padding={0}
              startIconMargin="0px"
              height={"75px"}
              width={20}
              fontWeight={theme.typography.Bold}
              borderRadius={flip ? "10px 0px 0px 0px" : "10px 0px 0px 10px"}
              onClick={() => setFlip((prev) => !prev)}
            />
            <Box
              sx={{
                width: "50px",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Box
                component="img"
                className="profileAvatar"
                alt="crayon logo"
                src={
                  jobContent?.companyURL !== "No URL"
                    ? jobContent?.companyURL
                    : profile
                }
                sx={{
                  mr: 1,
                  height: "48px !important",
                  width: "47px !important",
                  borderRadius: "10px 10px 0 0",
                  border: `1px solid ${theme.palette.borderColor}`,
                }}
              />
              <SmallButton
                color="userID"
                label={`${jobContent?.job_id ? jobContent?.job_id : "-"}`}
                mr={1}
                fontSize={10}
                fontWeight={700}
                alignItems="end"
                textColor="#000"
                borderRadius="0px 0px 6px 6px"
                marginTop="-2px"
                minWidth="50px"
                width="fit-content"
              ></SmallButton>
            </Box>

            <Box
              sx={{
                width: "43.8%",
                position: "absolute",
                top: 0,
                left: "100px",
              }}
            >
              <Box sx={{ display: "flex", gap: "20px" }}>
                <Box>
                  <Box
                    sx={{ display: "flex", alignItems: "center", gap: "10px" }}
                  >
                    <TalentSVGButton
                      color={"white"}
                      source={JobExp}
                      outerHeight={25}
                      height={18}
                      width={15}
                      padding={"0px !important"}
                      startIconMargin={"0px !important"}
                    />
                    <Tooltip
                      disableInteractive
                      title={jobContent?.title}
                      placement="top-end"
                    >
                      <Link
                        to={`/admin/job-detail/${`${
                          jobContent?.town_name?.replace("/", "") +
                          " " +
                          jobContent?.region_name?.replace("/", "")
                        }`}/${jobContent?.job_id}`}
                        target={"_blank"}
                        style={{
                          textDecoration: "none",
                          color: theme.palette.black,
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: "16px",
                            fontWeight: theme.typography.Bold,
                            maxWidth: {
                              xs: "250px",
                              mlg: "400px",
                              xl: "500px",
                            },
                          }}
                          noWrap
                        >
                          {jobContent?.title}
                        </Typography>
                      </Link>
                    </Tooltip>
                  </Box>
                  <Box
                    sx={{ display: "flex", alignItems: "center", gap: "10px" }}
                  >
                    <TalentSVGButton
                      color={"white"}
                      source={BlueCompany}
                      outerHeight={25}
                      height={18}
                      width={15}
                      padding={"0px !important"}
                      startIconMargin={"0px !important"}
                    />
                    <Tooltip
                      title={jobContent?.CompanyName}
                      placement="top-end"
                      disableInteractive
                    >
                      <Link
                        to={`/admin/users/user-companies/${jobContent?.company_id}`}
                        style={{
                          textDecoration: "none",
                          color: theme.palette.black,
                        }}
                        target="_blank"
                      >
                        <Typography
                          sx={{
                            fontSize: "14px",
                            fontWeight: theme.typography.Bold,
                          }}
                        >
                          {truncate(jobContent?.CompanyName, {
                            length: 30,
                          })}
                        </Typography>
                      </Link>
                    </Tooltip>
                  </Box>
                </Box>
                <Box sx={{ display: "flex" }}>
                  {jobContent?.post_incognito && (
                    <Box
                      component={"img"}
                      src={post_incognito}
                      sx={{ height: "30px", width: "30px", marginRight: "5px" }}
                    />
                  )}
                  <Button
                    variant={"contained"}
                    color={
                      jobContent?.plan_type ? "talentButton" : "liteButton"
                    }
                    sx={{
                      borderRadius: "0 0 0 10px",
                      fontSize: "12px",
                      fontWeight: theme.typography.Bold,
                      padding: "0px 3px !important",
                      height: "30px",
                      minWidth: "45px",
                      ":hover": {
                        boxShadow: "none",
                      },
                      cursor: "default",
                    }}
                  >
                    {jobContent?.plan_type ? "Talent" : "Lite"}
                  </Button>
                  <Button
                    variant={"contained"}
                    color={
                      jobContent?.job_type === "Recruit"
                        ? "recruitButton"
                        : "directButton"
                    }
                    sx={{
                      borderRadius: "0 0 0 0",
                      fontSize: "12px",
                      fontWeight: theme.typography.Bold,
                      padding: "0px 4px!important",
                      height: "30px",
                      minWidth: "44px",
                      ":hover": {
                        boxShadow: "none",
                      },
                      cursor: "default",
                    }}
                  >
                    {(jobContent?.job_type && jobContent?.job_type) || "-"}
                  </Button>
                  {jobContent?.success_fees && (
                    <Button
                      variant={"contained"}
                      color={"getCoins"}
                      ref={successRef}
                      sx={{
                        borderRadius: "0 0 0 0",
                        fontSize: "12px",
                        fontWeight: theme.typography.Bold,
                        padding: "0px 4px!important",
                        height: "30px",
                        minWidth: "44px",
                        ":hover": {
                          boxShadow: "none",
                        },
                      }}
                      onClick={() => setOpenSuccessRef(successRef?.current)}
                    >
                      {`${jobContent?.success_fees}%`}
                    </Button>
                  )}
                  <Button
                    variant="contained"
                    ref={bountyRef}
                    color="promoterButton"
                    sx={{
                      fontSize: "12px",
                      fontWeight: theme.typography.Bold,
                      minWidth: "60px",
                      height: "30px",
                      padding: "0px 0px 0px 4px",
                      borderRadius:
                        (jobContent?.job_type === "Recruit" &&
                          decodedToken?.data?.role_id === 1) ||
                        (jobContent?.job_type === "Recruit" &&
                          decodedToken?.data?.role_id === 2)
                          ? "0px"
                          : "0 0 10px 0",
                      "& .MuiButton-endIcon": {
                        margin: "0px !important",
                      },
                    }}
                    onClick={() => setOpenBounty(bountyRef?.current)}
                  >
                    <Typography
                      sx={{
                        fontSize: "12px",
                        fontWeight: theme.typography.Bold,
                        marginLeft: "-16px",
                      }}
                    >
                      {`${jobContent?.currency_symbol}${
                        jobContent?.talent_partner_bounty_amount
                          ? jobContent?.talent_partner_bounty_amount
                          : "-"
                      }`}
                    </Typography>
                    <Box
                      component={"img"}
                      src={openBounty ? upArrowWhite : downArrowWhite}
                      sx={{
                        position: "absolute",
                        left: "39px",
                        height: "25px",
                        width: "25px",
                      }}
                    />
                  </Button>
                  {((jobContent?.job_type === "Recruit" &&
                    decodedToken?.data?.role_id === 1) ||
                    (jobContent?.job_type === "Recruit" &&
                      decodedToken?.data?.role_id === 2) ||
                    (jobContent?.job_type === "Recruit" &&
                      decodedToken?.data?.role_id === 8)) && (
                    <>
                      <Button
                        ref={assignJobRef}
                        color="displayCompanyButton"
                        sx={{
                          border: "1px solid",
                          boxShadow: "none",
                          ":hover": {
                            boxShadow: "none",
                          },
                          borderColor: "borderColor",
                          borderRadius: "0 0 10px 0",
                          height: "30px",
                          fontWeight: theme.typography.Regular,
                          padding: "5px 10px 5px 0px !important",
                          display: "flex",
                          gap: "10px",
                        }}
                        variant="contained"
                        onClick={() =>
                          decodedToken?.data?.role_id !== 8 &&
                          setAssignJob(assignJobRef?.current)
                        }
                      >
                        <Box
                          component={"img"}
                          src={
                            jobContent?.profile_url !== "NO URL"
                              ? jobContent?.profile_url
                              : userLogo
                          }
                          sx={{
                            height: "25px",
                            width: "25px",
                            borderRadius: "0 10px 10px 0",
                          }}
                        />
                        <Typography
                          sx={{
                            width: "100%",
                            fontSize: "12px",
                            fontWeight: theme.typography.Bold,
                            textWrap: "noWrap",
                          }}
                        >
                          {jobContent?.assigned_first_name &&
                          jobContent?.assigned_last_name
                            ? `${jobContent?.assigned_first_name} ${jobContent?.assigned_last_name}`
                            : "- -"}
                        </Typography>
                        {decodedToken?.data?.role_id !== 8 && (
                          <Box
                            component={"img"}
                            src={assignJob ? upArrow : downArrow}
                            sx={{
                              height: "25px",
                              width: "25px",
                            }}
                          />
                        )}
                      </Button>

                      <Popover
                        open={anchorElAssign}
                        anchorEl={assignJob}
                        onClose={handleCloseAssign}
                        sx={{
                          "& .MuiPaper-root": {
                            borderRadius: "25px",
                          },
                        }}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "center",
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "center",
                        }}
                      >
                        <AssignJobBox
                          setJobContent={setJobContent}
                          handleClose={handleCloseAssign}
                          jobContent={jobContent}
                        />
                      </Popover>
                    </>
                  )}
                  <Popover
                    open={anchorElSuccess}
                    anchorEl={openSuccessRef}
                    onClose={() => setOpenSuccessRef(null)}
                    sx={{
                      "& .MuiPopover-paper": {
                        borderRadius: "10px 0 10px 10px !important",
                        width: "250px !important",
                      },
                    }}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "right",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                  >
                    <SuccessPopup
                      handleClose={() => setOpenSuccessRef(null)}
                      jobContent={jobContent}
                      setAllJobs={setAllJobs}
                      allJobs={allJobs}
                      value={jobContent?.success_fees}
                      jobId={index}
                    />
                  </Popover>
                  <Popover
                    open={anchorElBounty}
                    anchorEl={openBounty}
                    onClose={() => setOpenBounty(null)}
                    sx={{
                      "& .MuiPopover-paper": {
                        borderRadius: "10px 0 10px 10px !important",
                        width: "190px !important",
                      },
                    }}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "right",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                  >
                    <Bounty
                      handleClose={() => setOpenBounty(null)}
                      jobContent={jobContent}
                      setAllJobs={setAllJobs}
                      allJobs={allJobs}
                    />
                  </Popover>
                </Box>
              </Box>

              <Box sx={{ display: "flex", gap: "20px" }}>
                <Box
                  sx={{ display: "flex", alignItems: "center", gap: "10px" }}
                >
                  <TalentSVGButton
                    color={"white"}
                    source={locationIcon}
                    outerHeight={15}
                    height={15}
                    width={18}
                    padding={"0px !important"}
                    startIconMargin={"0px !important"}
                  />
                  {jobContent?.country_name !== null ? (
                    <Tooltip
                      title={`${jobContent?.town_name}, ${jobContent?.region_name}`}
                      placement="top-end"
                      disableInteractive
                    >
                      <Typography
                        sx={{
                          fontWeight: theme.typography.Regular,
                          fontSize: 12,
                          width: "100%",
                          whiteSpace: "nowrap", // Prevents text from wrapping
                          overflow: "hidden", // Hides any overflowing content
                          textOverflow: "ellipsis",
                        }}
                      >
                        {jobContent?.town_name || "-"}
                        {jobContent?.region_name &&
                          `, ${jobContent?.region_name} `}
                      </Typography>
                    </Tooltip>
                  ) : (
                    "-"
                  )}
                </Box>
                <Box
                  sx={{ display: "flex", alignItems: "center", gap: "10px" }}
                >
                  <TalentSVGButton
                    color={"white"}
                    source={salary}
                    outerHeight={25}
                    height={14}
                    width={18}
                    padding={"0px !important"}
                    startIconMargin={"0px !important"}
                  />
                  {jobContent?.salary_min !== null &&
                  jobContent?.salary_max !== null ? (
                    <Tooltip
                      title={`${jobContent?.currency_symbol} ${
                        jobContent?.salary_min
                      } to ${jobContent?.salary_max} ${
                        jobContent?.type === "freelance"
                          ? " per hour"
                          : " per month"
                      }`}
                      placement="top-end"
                      disableInteractive
                    >
                      <Typography
                        sx={{
                          fontWeight: theme.typography.Regular,
                          fontSize: 12,
                          width: "fit-content",
                          whiteSpace: "nowrap", // Prevents text from wrapping
                          overflow: "hidden", // Hides any overflowing content
                          textOverflow: "ellipsis",
                        }}
                      >
                        {jobContent?.currency_symbol}
                        {formatCurrencyWithCommas(
                          jobContent?.salary_min
                        )} to {jobContent?.currency_symbol}
                        {formatCurrencyWithCommas(jobContent?.salary_max)}
                        {jobContent?.type === "freelance"
                          ? " per hour"
                          : " per month"}
                      </Typography>
                    </Tooltip>
                  ) : (
                    "-"
                  )}
                </Box>
                <Box
                  sx={{ display: "flex", alignItems: "center", gap: "10px" }}
                >
                  <TalentSVGButton
                    color={"white"}
                    source={pending}
                    outerHeight={15}
                    height={15}
                    width={18}
                    padding={"0px !important"}
                    startIconMargin={"0px !important"}
                  />
                  {jobContent?.experience_year_start !== null ? (
                    <Tooltip
                      title={
                        jobContent?.experience_year_start
                          ? `${jobContent?.experience_year_start} to ${jobContent?.experience_year_end} years`
                          : `${jobContent?.experience_year} years`
                      }
                      placement="top-end"
                      disableInteractive
                    >
                      <Typography
                        sx={{
                          fontWeight: theme.typography.Regular,
                          fontSize: 12,
                          whiteSpace: "nowrap", // Prevents text from wrapping
                        }}
                      >
                        {jobContent?.experience_year_start
                          ? `${jobContent?.experience_year_start} to ${jobContent?.experience_year_end} years`
                          : `${jobContent?.experience_year} years`}
                      </Typography>
                    </Tooltip>
                  ) : (
                    "-"
                  )}
                </Box>
                <Box
                  sx={{ display: "flex", alignItems: "center", gap: "10px" }}
                >
                  <TalentSVGButton
                    color={"white"}
                    source={notice}
                    outerHeight={15}
                    height={15}
                    width={18}
                    padding={"0px !important"}
                    startIconMargin={"0px !important"}
                  />
                  {jobContent?.job_start_date ? (
                    <Tooltip
                      title={`${dateConverterMonth(
                        jobContent?.job_start_date
                      )}`}
                      placement="top-end"
                      disableInteractive
                    >
                      <Typography
                        sx={{
                          fontWeight: theme.typography.Regular,
                          fontSize: 12,
                          whiteSpace: "nowrap", // Prevents text from wrapping
                        }}
                      >
                        {dateConverterMonth(jobContent?.job_start_date)}
                      </Typography>
                    </Tooltip>
                  ) : (
                    "-"
                  )}
                </Box>
              </Box>
            </Box>
          </Box>

          <Box
            sx={{ width: "6.5%", display: "flex", alignItems: "flex-start" }}
            ref={counterRef}
            onClick={() => setOpenMatchBox(counterRef?.current)}
          >
            <Button
              variant="contained"
              color="recentButton"
              sx={{
                fontSize: "12px",
                fontWeight: theme.typography.Bold,
                color: "white",
                minWidth: "30px !important",
                height: "30px",
                borderRadius: "0 0 0 10px",
                padding: "0px !important",
              }}
            >
              {jobContent?.matched_column_count}
            </Button>
            <Button
              variant="contained"
              color="recordVideoButton"
              sx={{
                fontSize: "12px",
                fontWeight: theme.typography.Bold,
                color: "white",
                minWidth: "30px !important",
                height: "30px",
                borderRadius: "0",
                padding: "0px !important",
              }}
            >
              {jobContent?.TotalUserCount || "-"}
            </Button>
            <Button
              variant="contained"
              color="recruiterButton"
              sx={{
                fontSize: "12px",
                fontWeight: theme.typography.Bold,
                color: "white",
                minWidth: "30px !important",
                height: "30px",
                borderRadius: "0",
                padding: "0px !important",
              }}
            >
              {jobContent?.totalusershorlisted || "-"}
            </Button>
            <Button
              variant="contained"
              color="interview"
              sx={{
                fontSize: "12px",
                fontWeight: theme.typography.Bold,
                color: "white",
                minWidth: "30px !important",
                height: "30px",
                borderRadius: "0 0 10px 0",
                padding: "0px !important",
              }}
            >
              {jobContent?.totaluserinterviewed || "-"}
            </Button>
          </Box>
          <Popover
            id="dropdown"
            open={anchorElMatchBox}
            anchorEl={openMatchBox}
            onClose={() => setOpenMatchBox(null)}
            anchorOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            sx={{
              "& .MuiPopover-paper": {
                borderRadius: "0 0 25px 25px !important",
                width: "450px !important",
              },
            }}
          >
            <RunMatchBox
              jobContent={jobContent}
              setJobContent={setJobContent}
            />
          </Popover>
          <Box
            sx={{
              width: "44%",
              position: "absolute",
              right: 0,
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                height: "30px",
              }}
            >
              {((decodedToken?.data?.role_id === 1 && jobContent?.pin_job) ||
                (decodedToken?.data?.role_id === 2 && jobContent?.pin_job)) && (
                <TalentSVGButton
                  onClick={() => setOpenPinConfirmation(true)}
                  color={"white"}
                  source={pinIcon}
                  outerHeight={25}
                  height={25}
                  width={25}
                  padding={"0px !important"}
                  startIconMargin={"0px !important"}
                  margin={"2px 0 0 0"}
                />
              )}
              {(decodedToken?.data?.role_id === 1 ||
                decodedToken?.data?.role_id === 2) && (
                <TalentSVGButton
                  onClick={() => handleEmployerEdit(jobContent?.job_id)}
                  color={"white"}
                  source={editIcon}
                  // outerHeight={22}
                  height={33}
                  width={34}
                  padding={"0px !important"}
                  startIconMargin={"0px !important"}
                />
              )}
              {(decodedToken?.data?.role_id === 1 ||
                decodedToken?.data?.role_id === 2) && (
                <TalentSVGButton
                  color={"white"}
                  source={deleteIcon}
                  height={24}
                  width={18}
                  padding={"0 8px 0 0 !important"}
                  startIconMargin={"0px !important"}
                />
              )}
              <SmallButton
                backgroundColor={
                  theme.manageTalent?.[renderColor(jobContent?.stage_name)]
                    ?.main
                }
                label={`${jobContent?.stage_name}`}
                height={30}
                width={60}
                fontSize={12}
                fontWeight={theme.typography.Bold}
                borderRadius="0px 0px 0px 10px"
              />
              <Button
                variant="contained"
                color={getDaysRemainingColor(
                  convertDatetimeWithValue(jobContent?.job_active_date)
                )}
                sx={{
                  fontSize: "12px",
                  fontWeight: 700,
                  color: "white",
                  padding: "0px",
                  minWidth: "57px",
                  borderRadius: "0px !important",
                  // borderRight: "1px solid #EBECF3",
                  // borderBottom: "1px solid #EBECF3",
                  height: "30px !important",
                  whiteSpace: "nowrap",
                  ":hover": {
                    boxShadow: "none",
                  },
                }}
              >
                {convertDatetimeWithValue(jobContent?.job_active_date)} days
              </Button>
              <Button
                variant="contained"
                color="dateButton"
                sx={{
                  fontSize: "12px",
                  fontWeight: 700,
                  color: "#000",
                  minWidth: "100px",
                  padding: "0px",
                  borderRadius: "0px !important",
                  borderRight: "1px solid #EBECF3",
                  borderBottom: "1px solid #EBECF3",
                  height: "30px !important",
                  whiteSpace: "nowrap",
                  ":hover": {
                    boxShadow: "none",
                  },
                }}
              >
                {dateConverterMonth(jobContent?.created_at)}
              </Button>
              <Tooltip
                title={`${convertDatetimeAgo(jobContent?.created_at)} ago`}
                placement="top-end"
                disableInteractive
              >
                <Button
                  variant="contained"
                  color="dateButton"
                  sx={{
                    fontSize: "12px",
                    fontWeight: theme.typography.Bold,
                    color: "#000",
                    padding: "0 5px",
                    width: "73px",
                    maxWidth: "73px",
                    borderRadius: "0px !important",
                    borderRight: "1px solid #EBECF3",
                    borderBottom: "1px solid #EBECF3",
                    height: "30px !important",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    display: "block",
                    ":hover": {
                      boxShadow: "none",
                    },
                  }}
                >
                  {convertDatetimeAgo(jobContent?.created_at)} ago
                </Button>
              </Tooltip>
              <Box
                sx={{
                  borderTopRightRadius: "10px",
                  width: "100px",
                  height: "30px",
                }}
              >
                <ChangeStatusButton
                  loggedInUser={decodedToken?.data?.role_id}
                  jobId={index}
                  jobStatus={jobContent?.job_status_name}
                  employerIndustry={jobContent?.JobIndustriesId}
                  getJobList={getJobList}
                  setAllJobs={setAllJobs}
                  setTotalJob={setTotalJob}
                  page={page}
                  jobContent={jobContent}
                />
              </Box>
            </Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                gap: "10px",
              }}
            >
              {/* <Box sx={{ display: "flex" }}>
                {jobContent?.type && (
                  <SmallButton
                    color="workSetupButton"
                    label={jobContent?.type}
                    value={jobContent?.type}
                    mr="4px"
                    fontSize="12px"
                  ></SmallButton>
                )}

                {jobContent?.work_setup && (
                  <SmallButton
                    color="workSetupButton"
                    label={jobContent?.work_setup}
                    value={jobContent?.work_setup}
                    mr="4px"
                    fontSize="12px"
                  ></SmallButton>
                )}
              </Box>*/}
              <SVGButton
                color={"white"}
                source={CopyToClipboardIcon}
                height={35}
                width={35}
                startIconMargin={"0px !important"}
                padding={"0px !important"}
                onClick={handleCopyUrl}
              />

              <Tooltip
                title={"job detail"}
                placement="top-end"
                disableInteractive
              >
                <SVGButton
                  color={"white"}
                  source={viewRef.current ? RedView : RedViewGrey}
                  height={35}
                  width={35}
                  startIconMargin={"0px !important"}
                  padding={"0px !important"}
                  onClick={handleView}
                />
              </Tooltip>
              <SVGButton
                color={"white"}
                source={
                  // jobContent?.job_spec_url !== "No URL"
                  //   ? BlueFolder
                  //   : BlueFolderGrey
                  BlueFolder
                }
                height={35}
                width={35}
                startIconMargin={"0px !important"}
                padding={"0px !important"}
                onClick={handleDocument}
              />
              <Popover
                open={openDocument}
                anchorEl={anchorElDocs}
                onClose={handleCloseDocument}
                sx={{
                  "& .MuiPopover-paper": {
                    borderRadius: "10px 0 10px 10px !important",
                    width: "158px !important",
                  },
                }}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
              >
                <DocumentBox
                  handleClose={() => setOpenDocument(null)}
                  jobContent={jobContent}
                />
              </Popover>
              <SVGButton
                color={"white"}
                source={
                  jobContent?.job_video_url !== "No URL" ||
                  jobContent?.employer_video_url !== "No URL"
                    ? GreenPlay
                    : GreenPlayGrey
                }
                height={35}
                width={35}
                startIconMargin={"0px !important"}
                padding={"0px !important"}
                onClick={handleVideo}
              />
              <Popover
                open={openVideo}
                anchorEl={anchorElDocs}
                onClose={handleCloseVideo}
                sx={{
                  "& .MuiPopover-paper": {
                    borderRadius: "10px 0 10px 10px !important",
                    width: "158px !important",
                  },
                }}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
              >
                <VideoBox
                  handleClose={() => setOpenVideo(null)}
                  jobContent={jobContent}
                  text1={"Employer video"}
                  text2={"Job video"}
                  jobVideo={jobContent?.job_video_url}
                  employerVideo={jobContent?.employer_video_url}
                />
              </Popover>
              <SVGButton
                color={"white"}
                source={YellowChatHistory}
                height={35}
                width={35}
                startIconMargin={"0px !important"}
                padding={"0px !important"}
                onClick={() => setOpenHistoryBox(true)}
              />

              {/*  <Button
                variant="contained"
                color="redButton"
                sx={{
                  fontSize: "14px",
                  fontWeight: theme.typography.Bold,
                  width: "175px",
                  borderRadius: flip ? 0 : "0 0 10px 0",
                  padding: 0,
                  height: "45px",
                  gap: "17px",
                  "&:hover": {},
                }}
                onClick={() => handleTalent()}
              >
                <Box
                  component={"img"}
                  src={talentIcon}
                  sx={{
                    height: "21px",
                    width: "20px",
                  }}
                />
                talent
                <Box
                  component={"img"}
                  src={jobContent?.job_unlock ? unlockIcon : lockIcon}
                  sx={{
                    height: "20px",
                    width: "13px",
                  }}
                  onClick={(event) => {
                    event.stopPropagation(); // Prevents the button's onClick from firing
                    if (!jobContent?.job_unlock) {
                      handleTalentUnlock();
                    } else if (
                      decodedToken?.data?.role_id === 1 ||
                      decodedToken?.data?.role_id === 2
                    ) {
                      setOpenLock(true);
                    }
                  }}
                />
              </Button>*/}

              <Box
                sx={{
                  width: "175px",
                  borderRadius: flip ? 0 : "0 0 10px 0",
                  padding: 0,
                  height: "45px",
                  gap: "17px",
                  backgroundColor: theme.palette.redButton.main,
                  color: "white",
                }}
              >
                <Button
                  variant="contained"
                  color="redButton"
                  sx={{
                    fontSize: "14px",
                    fontWeight: theme.typography.Bold,
                    position: "absolute",
                    right: 50,
                    padding: "6px 16px 6px 28px",
                    height: "45px",
                    gap: "17px",
                    boxShadow: "none !important",
                  }}
                  onClick={() => handleTalent()}
                >
                  <Box
                    component={"img"}
                    src={talentIcon}
                    sx={{
                      height: "21px",
                      width: "20px",
                    }}
                  />
                  talent
                </Button>
                <Button
                  variant="contained"
                  color="redButton"
                  sx={{
                    fontSize: "14px",
                    fontWeight: theme.typography.Bold,
                    position: "absolute",
                    right: 0,
                    padding: "6px 22px 6px 0px",
                    borderRadius: flip ? 0 : "0 0 10px 0",
                    height: "45px",
                    boxShadow: "none !important",
                  }}
                  onClick={(event) => {
                    event.stopPropagation(); // Prevents the button's onClick from firing
                    if (!jobContent?.job_unlock) {
                      handleTalentUnlock();
                    } else if (jobContent?.job_unlock) {
                      handleTalent();
                    } else if (
                      decodedToken?.data?.role_id === 1 ||
                      decodedToken?.data?.role_id === 2
                    ) {
                      setOpenLock(true);
                    }
                  }}
                >
                  <Box
                    component={"img"}
                    src={jobContent?.job_unlock ? unlockIcon : lockIcon}
                    sx={{
                      height: "20px",
                      width: "13px",
                    }}
                  />
                </Button>
              </Box>
            </Box>
          </Box>
        </AccordionSummary>
        <Box sx={{ width: `calc(100% - 195px)` }}>
          <Divider />
        </Box>
        <AccordionDetails
          sx={{
            display: "flex",
            flexDirection: "column",
            padding: "0px",
            margin: "0px",
          }}
        >
          <>
            <Box sx={{ display: "flex" }}>
              <Box
                sx={{
                  width: "20px",
                  height: "auto",
                  mt: "-2px",
                  background: `${theme.palette.talentButton.main}`,
                }}
              ></Box>
              <Box
                className="contentBoxLeft"
                sx={{ width: "80%", position: "relative", left: "15px" }}
              >
                <Box
                  sx={{
                    display: "flex",
                    position: "relative",
                    left: "65px",
                    marginBottom: "9px",
                    flexDirection: "column",
                  }}
                >
                  <Box sx={{ display: "flex", gap: "20px" }}>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: "8px",
                      }}
                    >
                      <TalentSVGButton
                        color={"white"}
                        source={email}
                        outerHeight={22}
                        height={18}
                        width={15}
                        padding={"0px !important"}
                        startIconMargin={"0px !important"}
                      />
                      {jobContent?.email ? (
                        <Link
                          to={`/admin/users/user-employers/${jobContent?.user_id}`}
                          target={"_blank"}
                          style={{
                            textDecoration: "none",
                            color: theme.palette.black,
                          }}
                        >
                          <Typography
                            sx={{
                              fontSize: "12px",
                              fontWeight: theme.typography.Regular,
                            }}
                          >
                            {jobContent?.email}
                          </Typography>
                        </Link>
                      ) : (
                        "-"
                      )}
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: "3px",
                      }}
                    >
                      <TalentSVGButton
                        color={"white"}
                        source={contact}
                        outerHeight={20}
                        height={15}
                        width={15}
                        padding={"0px !important"}
                        startIconMargin={"0px !important"}
                      />
                      {jobContent?.employer_profile_contact_no ? (
                        <Typography
                          sx={{
                            fontSize: "12px",
                            fontWeight: theme.typography.Regular,
                            minWidth: "100px",
                          }}
                        >
                          {formatPhoneNumberIntl(
                            jobContent?.employer_profile_contact_no
                          )
                            ? formatPhoneNumberIntl(
                                jobContent?.employer_profile_contact_no
                              )
                            : jobContent?.employer_profile_contact_no}
                        </Typography>
                      ) : (
                        "-"
                      )}
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      <TalentSVGButton
                        color={"white"}
                        source={linkedin}
                        outerHeight={22}
                        height={18}
                        width={15}
                        padding={"0px !important"}
                        startIconMargin={"0px !important"}
                      />
                      {jobContent?.employer_profile_hyperlink ? (
                        <Tooltip
                          disableInteractive
                          title={jobContent?.employer_profile_hyperlink}
                          placement="top-end"
                        >
                          <Link
                            to={jobContent?.employer_profile_hyperlink}
                            target={"_blank"}
                            style={{
                              textDecoration: "none",
                              color: theme.palette.black,
                            }}
                          >
                            <Typography
                              sx={{
                                fontSize: "12px",
                                fontWeight: theme.typography.Regular,
                                maxWidth: "300px",
                              }}
                              noWrap
                            >
                              {jobContent?.employer_profile_hyperlink}
                            </Typography>
                          </Link>
                        </Tooltip>
                      ) : (
                        "-"
                      )}
                    </Box>

                    <Box sx={{ display: "flex", gap: "6px" }}>
                      <Button
                        variant="contained"
                        color="workSetupButton"
                        sx={{
                          // minWidth: "65px",
                          fontSize: "12px",
                          fontWeight: theme.typography.Bold,
                          minWidth: "fit-content",
                          height: "25px",
                          padding: "0px 9px !important",
                          borderRadius: "0 0 10px 10px",
                        }}
                      >
                        {jobContent?.type}
                      </Button>
                      <Button
                        variant="contained"
                        color="workSetupButton"
                        sx={{
                          // minWidth: "65px",
                          fontSize: "12px",
                          fontWeight: theme.typography.Bold,
                          minWidth: "fit-content",
                          height: "25px",
                          borderRadius: "0 0 10px 10px",
                          padding: "0px 9px !important",
                        }}
                      >
                        {jobContent?.work_setup}
                      </Button>
                      <Button
                        ref={videoAppRef}
                        onClick={() =>
                          jobContent?.is_video_require &&
                          setOpenVideoApp(videoAppRef?.current)
                        }
                        variant="contained"
                        color={
                          jobContent?.is_video_require
                            ? "uploadPhotoButton"
                            : "submitButton"
                        }
                        sx={{
                          // minWidth: "65px",
                          fontSize: "12px",
                          fontWeight: theme.typography.Bold,
                          minWidth: "fit-content",
                          height: "25px",
                          padding: "0px 9px !important",
                          borderRadius: "0 0 10px 10px",
                        }}
                      >
                        video apps.
                      </Button>

                      <Popover
                        id="dropdown"
                        open={anchorElVideoApp}
                        anchorEl={openVideoApp}
                        onClose={() => setOpenVideoApp(null)}
                        anchorOrigin={{
                          vertical: "top",
                          horizontal: "left",
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "left",
                        }}
                        sx={{
                          "& .MuiPopover-paper": {
                            borderRadius: "0 0 10px 10px !important",
                            width: "254px !important",
                          },
                        }}
                      >
                        <VideoApp jobContent={jobContent} />
                      </Popover>
                      {jobContent?.job_type === "Recruit" && (
                        <>
                          <Button
                            variant="contained"
                            color="recentButton"
                            sx={{
                              // minWidth: "65px",
                              fontSize: "12px",
                              fontWeight: theme.typography.Bold,
                              minWidth: "fit-content",
                              height: "25px",
                              padding: "0px 9px !important",
                              borderRadius: "0 0 10px 10px",
                            }}
                          >
                            rec. interviews
                          </Button>
                          <Button
                            variant="contained"
                            color="submitButton"
                            sx={{
                              // minWidth: "65px",
                              fontSize: "12px",
                              fontWeight: theme.typography.Bold,
                              minWidth: "fit-content",
                              height: "25px",
                              padding: "0px 9px !important",
                              borderRadius: "0 0 10px 10px",
                            }}
                          >
                            briefing call
                          </Button>
                        </>
                      )}
                    </Box>
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: viewMoreExpanded ? "15px" : "0px",
                    width: "879px",
                  }}
                >
                  <Box>
                    <Typography
                      sx={{
                        fontWeight: theme.typography.Bold,
                        fontSize: 12,
                      }}
                    >
                      Job bio
                    </Typography>
                    <Typography
                      sx={{
                        fontWeight: theme.typography.Regular,
                        fontSize: 12,
                        overflow: "hidden",
                        // display: "-webkit-box",
                        letterSpacing: "0.25px",
                        lineHeight: "1.6",
                      }}
                      color={theme.palette.black100}
                      gutterBottom={true}
                      dangerouslySetInnerHTML={createMarkup(
                        jobContent?.job_bio
                      )}
                    />
                  </Box>

                  {viewMoreExpanded && (
                    <Grid>
                      <Typography
                        sx={{
                          fontWeight: theme.typography.Bold,
                          fontSize: 12,
                          letterSpacing: "0",
                          marginBottom: "8px",
                        }}
                      >
                        How you'll role
                      </Typography>
                      <Typography
                        sx={{
                          fontWeight: theme.typography.Regular,
                          fontSize: 12,
                          overflow: "hidden",
                          // display: "-webkit-box",
                          letterSpacing: "0.25px",
                          lineHeight: "1.6",
                        }}
                        color={theme.palette.black100}
                        gutterBottom={true}
                        dangerouslySetInnerHTML={createMarkup(
                          jobContent?.description
                        )}
                      />
                    </Grid>
                  )}

                  {viewMoreExpanded && (
                    <Grid>
                      <Typography
                        sx={{
                          fontWeight: theme.typography.Bold,
                          fontSize: 12,
                          letterSpacing: "0",
                          marginBottom: "8px",
                        }}
                      >
                        What you'll do
                      </Typography>

                      <Typography
                        sx={{
                          fontWeight: theme.typography.Regular,
                          fontSize: 12,
                          overflow: "hidden",
                          // display: "-webkit-box",
                          letterSpacing: "0.25px",
                          lineHeight: "1.6",
                        }}
                        color={theme.palette.black100}
                        gutterBottom={true}
                        dangerouslySetInnerHTML={createMarkup(
                          jobContent?.role_responsibilty
                        )}
                      />
                    </Grid>
                  )}

                  {viewMoreExpanded && (
                    <Grid>
                      <Typography
                        sx={{
                          fontWeight: theme.typography.Bold,
                          fontSize: 12,
                          letterSpacing: "0",
                          marginBottom: "8px",
                        }}
                      >
                        What you'll need
                      </Typography>
                      <Typography
                        sx={{
                          fontWeight: theme.typography.Regular,
                          fontSize: 12,
                          overflow: "hidden",
                          // display: "-webkit-box",
                          letterSpacing: "0.25px",
                          lineHeight: "1.6",
                        }}
                        color={theme.palette.black100}
                        gutterBottom={true}
                        dangerouslySetInnerHTML={createMarkup(
                          jobContent?.role_requirements
                        )}
                      />
                    </Grid>
                  )}
                  {viewMoreExpanded && (
                    <Grid>
                      <Typography
                        sx={{
                          fontWeight: theme.typography.Bold,
                          fontSize: 12,
                          letterSpacing: "0",
                          marginBottom: "8px",
                        }}
                      >
                        Job Notes
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "12px",
                          fontWeight: theme.typography.Regular,
                          wordBreak: "break-word",
                          width: "100%",
                          // display: "-webkit-box",
                          WebkitLineClamp: viewMoreExpanded ? "none" : 3,
                          WebkitBoxOrient: "vertical",
                          overflow: "hidden",
                        }}
                        color={theme.palette.black100}
                        gutterBottom={true}
                        dangerouslySetInnerHTML={createMarkup(
                          jobContent?.job_notes
                        )}
                      />
                    </Grid>
                  )}

                  <Button
                    sx={{
                      justifyContent: "flex-end",
                      color: "black",
                      padding: 0,
                      width: "100%",
                      fontSize: "12px !important",
                      fontWeight: theme.typography.Bold,
                      ".MuiButton-endIcon": {
                        margin: "0px !important",
                      },
                      height: "30px",
                    }}
                    endIcon={
                      <Box
                        component="img"
                        sx={{
                          height: 30,
                          width: 30,
                          cursor: "pointer",
                        }}
                        alt="crayon logo"
                        src={viewMoreExpanded ? upArrow : downArrow}
                      />
                    }
                    onClick={handleViewMore}
                  >
                    {viewMoreExpanded ? "collapse" : "expand"}
                  </Button>
                </Box>

                <Box sx={{ display: "flex", alignItems: "center", mt: 1 }}>
                  <Typography
                    sx={{
                      fontSize: "12px",
                      fontWeight: theme.typography.Bold,
                      mr: 1,
                      width: 100,
                    }}
                  >
                    {i18n["pendingJobs.industries"]}
                  </Typography>
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      flexWrap: "wrap",
                      gap: "5px",
                    }}
                  >
                    {jobContent?.JobIndustries?.split(",")?.map(
                      (industry, index) => {
                        return (
                          <SmallButton
                            minWidth="10px"
                            height={25}
                            color="industiresButton"
                            borderRadius="5px"
                            mr="4px"
                            label={industry}
                          ></SmallButton>
                        );
                      }
                    )}
                  </Box>
                </Box>
                <Box sx={{ display: "flex", alignItems: "center", mt: 1 }}>
                  <Typography
                    sx={{
                      fontSize: "12px",
                      fontWeight: theme.typography.Bold,
                      mr: 1,
                      width: 100,
                    }}
                  >
                    {"Skills"}
                  </Typography>
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      flexWrap: "wrap",
                      gap: "5px",
                    }}
                  >
                    {jobContent?.JobSkills?.split(",")?.map((item) => {
                      return (
                        <SmallButton
                          minWidth="10px"
                          height={25}
                          color="traitsButton"
                          fontWeight={theme.typography.Bold}
                          borderRadius="5px"
                          mr="4px"
                          label={item}
                        ></SmallButton>
                      );
                    })}
                  </Box>
                </Box>
                <Box sx={{ display: "flex", alignItems: "center", mt: 1 }}>
                  <Typography
                    sx={{
                      fontSize: "12px",
                      fontWeight: theme.typography.Bold,
                      mr: 1,
                      width: 100,
                    }}
                  >
                    {i18n["pendingJobs.tools"]}
                  </Typography>
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      flexWrap: "wrap",
                      gap: "5px",
                    }}
                  >
                    {jobContent?.JobTools?.split(",")?.map((val) => {
                      return (
                        <SmallButton
                          minWidth="10px"
                          height={25}
                          color="toolsButton"
                          borderRadius="5px"
                          mr="4px"
                          label={val}
                        ></SmallButton>
                      );
                    })}
                  </Box>
                </Box>

                <Box sx={{ display: "flex", alignItems: "center", mt: 1 }}>
                  <Typography
                    sx={{
                      fontSize: "12px",
                      fontWeight: theme.typography.Bold,
                      mr: 1,
                      width: 100,
                    }}
                  >
                    {i18n["pendingJobs.nationalities"]}
                  </Typography>
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      flexWrap: "wrap",
                      gap: "5px",
                    }}
                  >
                    {jobContent?.JobLanguages?.split(",")?.map((item) => {
                      return (
                        <SmallButton
                          minWidth="10px"
                          height={25}
                          color="grayButton700"
                          textColor={theme.palette.black100.main}
                          fontWeight={theme.typography.Bold}
                          borderRadius="5px"
                          label={item}
                          mr="4px"
                        ></SmallButton>
                      );
                    })}
                  </Box>
                </Box>
                <Box sx={{ display: "flex", alignItems: "center", mt: 1 }}>
                  <Typography
                    sx={{
                      fontSize: "12px",
                      fontWeight: theme.typography.Bold,
                      mr: 1,
                      width: 100,
                    }}
                  >
                    {i18n["pendingJobs.languages"]}
                  </Typography>
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      flexWrap: "wrap",
                      gap: "5px",
                    }}
                  >
                    {jobContent?.JobLanguages?.split(",")?.map((item) => {
                      return (
                        <SmallButton
                          minWidth="10px"
                          height={25}
                          color="grayButton700"
                          textColor={theme.palette.black100.main}
                          fontWeight={theme.typography.Bold}
                          borderRadius="5px"
                          label={item}
                          mr="4px"
                        ></SmallButton>
                      );
                    })}
                  </Box>
                </Box>

                <Box sx={{ display: "flex", alignItems: "center", mt: 1 }}>
                  <Typography
                    sx={{
                      fontSize: "12px",
                      fontWeight: theme.typography.Bold,
                      mr: 1,
                      width: 100,
                    }}
                  >
                    {i18n["pendingJobs.qualifications"]}
                  </Typography>
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      flexWrap: "wrap",
                      gap: "5px",
                    }}
                  >
                    {jobContent?.JobQualifications?.split(",")?.map(
                      (qualifications, index) => {
                        return (
                          <SmallButton
                            justifyContent={"flex-start"}
                            height={25}
                            color="grayButton700"
                            textColor={theme.palette.black100.main}
                            fontWeight={theme.typography.Bold}
                            borderRadius="5px"
                            mr="4px"
                            label={qualifications}
                          ></SmallButton>
                        );
                      }
                    )}
                  </Box>
                </Box>
                <Box sx={{ display: "flex", alignItems: "center", mt: 1 }}>
                  <Typography
                    sx={{
                      fontSize: "12px",
                      fontWeight: theme.typography.Bold,
                      mr: 1,
                      width: 100,
                    }}
                  >
                    {i18n["pendingJobs.associations"]}
                  </Typography>
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      flexWrap: "wrap",
                      gap: "5px",
                    }}
                  >
                    {jobContent?.JobAssociations?.split(",")?.map(
                      (association, index) => {
                        return (
                          <SmallButton
                            minWidth="10px"
                            height={25}
                            color="grayButton700"
                            textColor={theme.palette.black100.main}
                            fontWeight={theme.typography.Bold}
                            borderRadius="5px"
                            mr="4px"
                            label={association}
                          ></SmallButton>
                        );
                      }
                    )}
                  </Box>
                </Box>

                {/*<Box sx={{ mt: 1 }}>
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontWeight: 700,
                        mr: 1,
                        display: "flex",
                        alignItems: "center",
                        gap: 1,
                      }}
                    >
                      <Box
                        component={"img"}
                        src={JobExp}
                        sx={{
                          height: "20px",
                          width: "20px",
                        }}
                      />
                      {i18n["pendingJobs.jobquestions"]}
                    </Typography>
                    <Box sx={{ display: "flex" }}>
                      <Box sx={{ width: "90%" }}>
                        {jobContent?.jobQuestions?.map((questions, index) => {
                          return (
                            <>
                              <Typography
                                sx={{
                                  fontSize: "14px",
                                  fontWeight: 400,
                                  mr: 1,
                                  mt: 1,
                                }}
                              >
                                <span
                                  style={{
                                    fontWeight: 700,
                                  }}
                                >
                                  Question {index + 1}:
                                </span>{" "}
                                <br />
                                {questions?.question}
                              </Typography>
                              <Paper
                                sx={{
                                  display: "flex",
                                  height: "40px",
                                  borderRadius: "25px",
                                  boxShadow: "none",
                                  alignItems: "center",
                                  paddingX: 0.8,
                                  justifyContent: "space-between",
                                  border: `1px solid ${theme.palette.grayBorder}`,
                                }}
                              >
                                <InputBase
                                  sx={{ ml: 2, mr: 2 }}
                                  id="password"
                                  value={questions?.answer}
                                  type="text"
                                  placeholder={i18n["pendingJobs.answer"]}
                                />
                                <Button
                                  variant="contained"
                                  color="lightGreenButton300"
                                  sx={{
                                    height: 30,
                                  }}
                                >
                                  Save
                                </Button>
                              </Paper>
                            </>
                          );
                        })}
                      </Box>
                    </Box>
                      </Box>*/}
              </Box>

              <Box
                className="contentBoxRight"
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                  alignItems: "flex-end",
                  width: "20%",
                  marginTop: "-2px",
                }}
              >
                <Button
                  ref={linkToPoolRef}
                  onClick={handleClick}
                  variant="contained"
                  color="recordVideoButton"
                  sx={{
                    width: "175px",
                    height: "45px",
                    borderRadius: "0px",
                    justifyContent: "flex-start",
                    gap: "10px",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: "12px",
                    }}
                  >
                    <Box
                      component={"img"}
                      src={anchorElLinkToPool ? rightIcon : leftIcon}
                      sx={{ width: 12, height: 12 }}
                    />
                    <Box
                      component={"img"}
                      src={linkToPool}
                      sx={{ width: 20, height: 20 }}
                    />
                  </Box>
                  <Typography
                    sx={{
                      fontSize: "14px",
                      fontWeight: theme.typography.Bold,
                    }}
                  >
                    link to pool
                  </Typography>
                </Button>

                <Popover
                  open={anchorElLinkToPool}
                  anchorEl={linkToPoolButton}
                  onClose={() => setLinkToPool(null)}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  sx={{
                    "& .MuiPopover-paper": {
                      borderRadius: "25px !important",
                      width: "450px !important",
                    },
                  }}
                >
                  <LinkToPoolBox
                    talentData={tableData}
                    setAnchorEl={setAnchorEl}
                    userID={jobContent?.job_id}
                    getTalent={getTalent}
                    lastKey={lastKey}
                    totalPoolData={totalPoolData}
                    removeFromPool={removeLinkedJobFromPool}
                    setTableData={setTableData}
                    addToPool={addToPool}
                    poolIDRef={poolIDRef}
                    setButtonDisable={setButtonDisable}
                    buttonDisable={buttonDisable}
                    flag={"jobs"}
                  />
                </Popover>

                <Button
                  ref={jobInfoRef}
                  onClick={() => setJobInfoButton(jobInfoRef?.current)}
                  variant="contained"
                  color="jobInfo"
                  sx={{
                    width: "175px",
                    height: "45px",
                    borderRadius: "0px",
                    justifyContent: "flex-start",
                    gap: "10px",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: "12px",
                    }}
                  >
                    <Box
                      component={"img"}
                      src={anchorElJobInfo ? rightIcon : leftIcon}
                      sx={{ width: 12, height: 12 }}
                    />
                    <Typography
                      sx={{
                        mt: "2px",
                        cursor: "pointer",
                        height: "17px",
                        width: "17px",
                        borderRadius: "5px",
                        fontSize: "12px",
                        lineHeight: 0.1,
                        /* text-align: center; */
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        fontWeight: theme.typography.Bold,
                        boxShadow: "0px 2px 4px #00000029",
                        border: 1,
                      }}
                    >
                      i
                    </Typography>
                  </Box>
                  <Typography
                    sx={{
                      fontSize: "14px",
                      fontWeight: theme.typography.Bold,
                    }}
                  >
                    job info
                  </Typography>
                </Button>

                <Popover
                  open={anchorElJobInfo}
                  anchorEl={jobInfoButton}
                  onClose={() => setJobInfoButton(null)}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  sx={{
                    "& .MuiPopover-paper": {
                      borderRadius: "25px !important",
                      width: "450px !important",
                    },
                  }}
                >
                  <JobInfoBox jobContent={jobContent} />
                </Popover>

                <Button
                  ref={questionRef}
                  onClick={() => setQuestionButton(questionRef?.current)}
                  variant="contained"
                  color="recruiterButton"
                  sx={{
                    width: "175px",
                    height: "45px",
                    borderRadius: "0px",
                    justifyContent: "flex-start",
                    gap: "10px",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: "12px",
                    }}
                  >
                    <Box
                      component={"img"}
                      src={anchorElQuestion ? rightIcon : leftIcon}
                      sx={{ width: 12, height: 12 }}
                    />
                    <Box
                      component={"img"}
                      src={questionIcon}
                      sx={{ width: 20, height: 20 }}
                    />
                  </Box>
                  <Typography
                    sx={{
                      fontSize: "14px",
                      fontWeight: theme.typography.Bold,
                    }}
                  >
                    questions ({jobContent?.jobQuestions?.length})
                  </Typography>
                </Button>

                <Popover
                  open={anchorElQuestion}
                  anchorEl={questionButton}
                  onClose={() => setQuestionButton(null)}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  sx={{
                    "& .MuiPopover-paper": {
                      borderRadius: "25px !important",
                      width: "450px !important",
                    },
                  }}
                >
                  <QuestionsBox jobContent={jobContent} />
                </Popover>

                <Button
                  ref={commentRef}
                  onClick={() => setCommentButton(commentRef?.current)}
                  variant="contained"
                  color="activeButton"
                  sx={{
                    width: "175px",
                    height: "45px",
                    borderRadius: "0px",
                    justifyContent: "flex-start",
                    gap: "10px",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: "12px",
                    }}
                  >
                    <Box
                      component={"img"}
                      src={anchorElComment ? rightIcon : leftIcon}
                      sx={{ width: 12, height: 12 }}
                    />
                    <Box
                      component={"img"}
                      src={commentIcon}
                      sx={{ width: 20, height: 20 }}
                    />
                  </Box>
                  <Typography
                    sx={{
                      fontSize: "14px",
                      fontWeight: theme.typography.Bold,
                    }}
                  >
                    {i18n["allTalent.comments"]} ({totalComment})
                  </Typography>
                </Button>

                <Popover
                  open={anchorElComment}
                  anchorEl={commentButton}
                  onClose={() => setCommentButton(null)}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  sx={{
                    "& .MuiPopover-paper": {
                      borderRadius: "25px !important",
                      width: "450px !important",
                    },
                  }}
                >
                  <CommentBox
                    jobContent={jobContent}
                    totalComment={totalComment}
                    setTotalComment={setTotalComment}
                    getComments={getComments}
                    setComments={setComments}
                    comments={comments}
                  />
                </Popover>

                <Button
                  ref={personalityRef}
                  onClick={() => setPersonalityButton(personalityRef?.current)}
                  variant="contained"
                  color="ideaGritScoreSlider"
                  sx={{
                    width: "175px",
                    height: "45px",
                    borderRadius: "0px",
                    justifyContent: "flex-start",
                    gap: "10px",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: "12px",
                    }}
                  >
                    <Box
                      component={"img"}
                      src={anchorElPersonality ? rightIcon : leftIcon}
                      sx={{ width: 12, height: 12 }}
                    />
                    <Box
                      component={"img"}
                      src={personalityIcon}
                      sx={{ width: 20, height: 20 }}
                    />
                  </Box>
                  <Typography
                    sx={{
                      fontSize: "14px",
                      fontWeight: theme.typography.Bold,
                    }}
                  >
                    personality
                  </Typography>
                </Button>

                <Popover
                  open={anchorElPersonality}
                  anchorEl={personalityButton}
                  onClose={() => setPersonalityButton(null)}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  sx={{
                    "& .MuiPopover-paper": {
                      borderRadius: "25px !important",
                      width: "450px !important",
                    },
                  }}
                >
                  <PersonalityBox jobContent={jobContent} />
                </Popover>

                <Button
                  ref={referralRef}
                  onClick={() => setReferralButton(referralRef?.current)}
                  variant="contained"
                  color="pinkButton300"
                  sx={{
                    width: "175px",
                    height: "45px",
                    borderRadius: anchorElReferral ? "0" : "0 0 0 25px",
                    justifyContent: "flex-start",
                    gap: "10px",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: "12px",
                    }}
                  >
                    <Box
                      component={"img"}
                      src={anchorElReferral ? rightIcon : leftIcon}
                      sx={{ width: 12, height: 12 }}
                    />
                    <Box
                      component={"img"}
                      src={referralsIcon}
                      sx={{ width: 20, height: 20 }}
                    />
                  </Box>
                  <Typography
                    sx={{
                      fontSize: "14px",
                      fontWeight: theme.typography.Bold,
                    }}
                  >
                    referrals ({count})
                  </Typography>
                </Button>

                <Popover
                  open={anchorElReferral}
                  anchorEl={referralButton}
                  onClose={() => setReferralButton(null)}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  sx={{
                    "& .MuiPopover-paper": {
                      borderRadius: "25px !important",
                      width: "450px !important",
                    },
                  }}
                >
                  <ReferralsBox
                    referralsData={referralsData}
                    text={"Below is a list of referred user linked to this job"}
                    isLoading={isLoading}
                    count={count}
                  />
                </Popover>
              </Box>
            </Box>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Box
                sx={{
                  width: "20px",
                  height: "auto",
                  borderRadius: "0 0 0 10px",
                  background: `${theme.palette.talentButton.main}`,
                }}
              ></Box>
              <Button
                variant="contained"
                color="redButton"
                sx={{
                  borderRadius: "5px 5px 0 0 ",
                  width: 75,
                  height: 20,
                }}
                onClick={() => setFlip((prev) => !prev)}
              >
                <Box
                  component={"img"}
                  src={upClose}
                  sx={{
                    height: 10,
                    width: 10,
                  }}
                />
              </Button>
              <Box
                sx={{
                  width: "20px",
                  height: "auto",
                }}
              />
            </Box>
          </>
        </AccordionDetails>
      </NewJobsCardAccordion>
      {openEdit && (
        <EditJobDetail
          show={openEdit}
          handleClose={handleCloseEdit}
          editJobId={editJob}
          getAdminJobList={getJobList}
          setAllJobs={setAllJobs}
          allJobs={allJobs}
          pinJobFlag={jobContent?.pin_job}
          setJobsLastKey={setJobsLastKey}
          basicData={basicData}
        />
      )}
      {isOpenHistoryBox && (
        <HistoryBox
          handleOpen={isOpenHistoryBox}
          handleClose={() => setOpenHistoryBox(false)}
          openFor={"jobs"}
          email={jobContent?.email}
        />
      )}
      {openEditBounty && (
        <EditBounty
          bounty={jobContent?.bounty_amount}
          symbol={jobContent?.currency_symbol}
          openEdit={openEditBounty}
          handleClose={() => setOpenEditBounty(false)}
          handleEdit={handleEditBounty}
        />
      )}

      {showUnlockBox && (
        <AdminUnlockJob
          text1={i18n["myplanPopup.confirmcoinspend"]}
          text1Width={"186px"}
          text2={`${jobContent?.CompanyName} ${i18n["myplanPopup.employercoinbalance"]}`}
          coinsBalance={`${coinsInfo?.coins} coins.`}
          coinsUse={`${coinsInfo?.unlock_amount} coins.`}
          text2Width={"290px"}
          text3={`${i18n["myplanPopup.chargeemployer"]} ${jobContent?.CompanyName} for`}
          text3Width={"270px"}
          text3Height={"72px"}
          // text4={i18n["myplanPopup.pleaseconfirm"]}
          // text4Width={"220px"}
          // buttonText={i18n["myplanPopup.happyletsdothis"]}
          handleClose={() => {
            setShowUnlockBox(false);
            setConfirmUnlock(false);
          }}
          show={showUnlockBox}
          buttonClick={handleAdminJobUnlock}
          gap={"18px"}
          setConfirmUnlock={setConfirmUnlock}
          confirmUnlock={confirmUnlock}
        />
      )}
      {coinSpend && (
        <CoinSpendBox
          text1={i18n["myplanPopup.confirmcoinspend"]}
          text1Width={"186px"}
          text2={i18n["myplanPopup.youcurrentlyhave"]}
          // coinsBalance={"60 coins."}
          // coinsUse={"30 coins."}
          coinsBalance={`${coinsInfo?.coins} coins.`}
          coinsUse={`${coinsInfo?.unlock_amount} coins.`}
          text2Width={"236px"}
          text3={i18n["myplanPopup.thisjobpostwilluse"]}
          text3Width={"270px"}
          text3Height={"72px"}
          text4={i18n["myplanPopup.pleaseconfirm"]}
          text4Width={"220px"}
          buttonText={i18n["myplanPopup.happyletsdothis"]}
          handleClose={() => setCoinSpend(false)}
          show={coinSpend}
          buttonClick={handleCoinSpendButtonClick}
          gap={"18px"}
        />
      )}
      {noCoins && (
        <CoinSpendBox
          text1={i18n["myplanPopup.confirmcoinspend"]}
          text1Width={"186px"}
          text2={i18n["myplanPopup.youcurrentlyhave"]}
          text2Width={"224px"}
          text3={i18n["myplanPopup.jobpurchase"]}
          text3Width={"232px"}
          text4={i18n["myplanPopup.pleaseconfirm"]}
          text4Width={"220px"}
          buttonText={"happy, let’s do this"}
          coinsBalance={`${coinsInfo?.coins} coins.`}
          coinsUse={`${coinsInfo?.unlock_amount} coins.`}
          handleClose={() => setNoCoins(false)}
          show={noCoins}
          buttonClick={handleNoCoinsButtonClick}
          gap={"15px"}
        />
      )}
      {confirm && (
        <CoinSpendBox
          text1={i18n["myplanPopup.nowyourecoiningit"]}
          text1Width={"208px"}
          text3={i18n["myplanPopup.crayonadminwillreview"]}
          text3Width={"252px"}
          text3Height={"72px"}
          buttonText={"got it, thanks"}
          handleClose={() => setConfirm(false)}
          show={confirm}
          buttonClick={handleConfirmButtonClick}
          gap={"20px"}
        />
      )}
      <AdminLockJob
        show={openLock}
        handleClose={() => {
          setOpenLock(false);
          setConfirmLock(false);
        }}
        handleLock={handleLockJob}
        confirmLock={confirmLock}
        setConfirmLock={setConfirmLock}
        dialogText={`job ${jobContent?.title}`}
      />
      <PinConfirmation
        show={openPinConfirmation}
        handleClose={() => {
          setOpenPinConfirmation(false);
          setConfirmPin(false);
        }}
        handleDelete={handlePinJob}
        confirmPin={confirmPin}
        setConfirmPin={setConfirmPin}
        dialogText={`job ${jobContent?.title}`}
        text="unpin"
      />
    </>
  );
}
