import { Box, Button, Modal, useTheme } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import {
  postAdminJob,
  updateJobDetail,
} from "../../../../redux/admin/jobsSlice";
import { setAlert, setLoading } from "../../../../redux/configSlice";
import {
  ALERT_TYPE,
  ERROR_MSG,
  POST_JOB_ADMIN,
} from "../../../../utils/Constants";
import { useSelector } from "react-redux";
import locale from "../../../../i18n/locale";
import * as Yup from "yup";
import { validateSchema } from "../../../../utils/Common";
import BasicInfo from "./BasicInfo";
import DetailInfo from "./DetailInfo";
import CultureData from "./CultureData";
import {
  getCurrency,
  uploadPostJobCultureVideo,
} from "../../../../redux/employer/postJobSlice";
import { getLanguage } from "../../../../redux/candidate/myCvSlice";
import CustomDialog from "../../../common/CustomDialog";
import RecordingInProgress from "../../../common/postJobsSection/dialog/RecordingInProgress";
import Typography from "@mui/material/Typography";

export default function PostJob({
  show,
  handleClose,
  editJobId,
  postJobData,
  setPostJobData,
  getJobList,
  basicData,
}) {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 1000,
    bgcolor: "background.paper",
    // borderRadius: "10px",
    boxShadow: "none !important",
    height: { sm: "480px", lgmm: "650px", lgm: "750px", xl: "800px" },
    ".&focus-visible": {
      outlineColor: "none",
    },
  };

  const i18n = locale.en;
  const dispatch = useDispatch();
  const theme = useTheme();
  const VideoRef = useRef(null);

  const {
    titles,
    skills,
    tools,
    qualifications,
    requiredQua,
    currency,
    contractDetails,
    country,
    town,
    roleTypes,
    workSetup,
    initialSkills,
  } = useSelector((state) => state.postJobs);

  const BASIC = {
    lastKey: "",
    status_id: "",
    job_stage: "",
    job_title: "",
    job_type: "",
    team_member_user_id: "",
    company_id: "",
  };

  const { industries, language, association, nationality } = useSelector(
    (state) => state.myCv
  );

  const { personalities, traits } = useSelector((state) => state?.postJobs);

  //   const [jobDetail, setJobDetail] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [errors, setErrors] = useState([]);
  const [sameJobAndBio, setSameJobAndBio] = useState(true);
  const [openReviewVideoDialog, setOpenReviewVideoDialog] = useState(false);
  const [videoLink, setVideoLink] = useState("");
  const [openVideoRecording, setOpenVideoRecording] = useState(false);
  const [recorderVideoData, setRecordedVideoData] = useState(null);
  const [disabledButton, setDisabledButton] = useState(true);
  const [isPlaying, setIsPlaying] = useState(false);
  const [videoFile, setVideoFile] = useState();

  const basicInfoSchema = Yup.object().shape({
    job_title_id: Yup.object()
      .shape({
        value: Yup.mixed().required("Job title value is Required."),
        label: Yup.string().required("Job title label is Required."),
      })
      .nullable(true)
      .required("Job title is Required."),
    job_role_type: Yup.string().required("Role type is Required."),
    work_setup: Yup.string().required("Work setup is Required."),
    country_id: Yup.object()
      .shape({
        value: Yup.number().required("country_id value is Required."),
        label: Yup.string().required("country_id label is Required."),
      })
      .nullable(true)
      .required("country_id is Required."),
    town_id: Yup.object()
      .shape({
        value: Yup.number().required("town_id value is Required."),
        label: Yup.string().required("town_id label is Required."),
      })
      .nullable(true)
      .required("town_id is Required."),
    industry_id: Yup.array()
      .of(
        Yup.object().shape({
          value: Yup.mixed().required("Industry value is Required."),
          label: Yup.string().required("Industry label is Required."),
        })
      )
      .required("Industry is Required.")
      .min(1, "At least one industry is required."),
    skills: Yup.array()
      .of(
        Yup.object().shape({
          value: Yup.mixed().required("Skill value is Required."),
          label: Yup.string().required("Skill label is Required."),
        })
      )
      .required("Skills is Required.")
      .min(3, "At least three skills is required."),
    tools: Yup.array()
      .of(
        Yup.object().shape({
          value: Yup.mixed().required("Tool value is Required."),
          label: Yup.string().required("Tool label is Required."),
        })
      )
      .required("Tools is Required.")
      .min(3, "At least three tools is required."),
    nationality_ids: Yup.array()
      .of(Yup.number())
      .required("Nationality is Required.")
      .min(1, "At least one nationality is required."),
    language_ids: Yup.array()
      .of(Yup.number())
      .required("Language is Required.")
      .min(1, "At least one language is required."),
    required_qualification_id: Yup.number().required(
      "Qualification is Required."
    ),
    currency_id: Yup.number().required("Currency is Required."),
    job_start_date: Yup.string().required("Date is Required."),
    nationality_flag: Yup.bool(),
    description: Yup.string().required("Role summary is Required."),
    role_responsibilty: Yup.string().required(
      "Role responsibility is Required."
    ),
    role_requirements: Yup.string().required("Role requirements is Required."),
    primary_personality: Yup.string()
      .nullable()
      .required("Primary Personality is Required."),
    shadow_personality: Yup.string()
      .nullable()
      .required("Shadow Personality is Required."),
    grit_score: Yup.number().nullable().required("Grit Score is Required."),
    traits: Yup.array()
      .min(5, "At least five trait is required.")
      .required("Traits are Required."),
    job_questions: Yup.lazy((value) => {
      if (Array.isArray(value) && value.length > 0) {
        return Yup.array()
          .of(Yup.string().required("Question is Required."))
          .max(5, "At least five questions are required.")
          .required("Screen Questions are Required.");
      }
      return Yup.array().min(1, "At least one screen question is required.");
    }),
  });

  const sanitizeHTML = (htmlContent) => {
    const tempElement = document?.createElement("div");
    tempElement.innerHTML = htmlContent;

    const isContentEmpty = tempElement?.textContent?.trim() === "";
    return isContentEmpty ? "" : htmlContent;
  };

  const editJobDetail = async () => {
    let {
      job_title_id,
      job_role_type,
      work_setup,
      country_id,
      town_id,
      industry_id,
      skills,
      tools,
      nationality_ids,
      language_ids,
      required_qualification_id,
      job_start_date,
      currency_id,
      role_requirements,
      role_responsibilty,
      description,
      primary,
      shadow,
      grit_score,
      traits,
      primary_personality,
      shadow_personality,
      job_questions,
    } = postJobData;

    let testErrorState = {
      job_title_id,
      job_role_type,
      work_setup,
      country_id,
      town_id,
      industry_id,
      skills,
      tools,
      nationality_ids,
      language_ids,
      required_qualification_id,
      job_start_date,
      currency_id,
      role_requirements: sanitizeHTML(role_requirements),
      role_responsibilty: sanitizeHTML(role_responsibilty),
      description: sanitizeHTML(description),
      primary_personality: primary
        ? primary?.name
        : primary_personality
        ? primary_personality
        : "",
      shadow_personality: shadow
        ? shadow?.name
        : shadow_personality
        ? shadow_personality
        : "",
      grit_score,
      traits,
      job_questions,
    };
    validateSchema(basicInfoSchema, testErrorState)
      .then(() => {
        try {
          let data = {
            ...postJobData,
            currency_id: postJobData?.currency_id,
            job_start_date: postJobData?.job_start_date,
            contract_duration: postJobData?.contract_duration,
            job_type: postJobData?.job_type,
            job_role_type: postJobData?.job_role_type,
            title: postJobData?.job_title_id?.label,
            job_title_id: postJobData?.job_title_id?.value,
            company_id: postJobData?.company_id,
            town_id: postJobData?.town_id?.value, //town_id
            town_name: postJobData?.town_id?.label, //town_id
            alternative_title_id:postJobData?.alternateTitle && postJobData?.alternateTitle?.length>0 ?postJobData?.alternateTitle?.filter((item)=>item?.__isNew__ !== true)?.map((item)=>item?.value):"",
            alternative_title_value:postJobData?.alternateTitle?.filter((item)=>item?.__isNew__ === true)?.map((item)=>item?.label),
            country_id: postJobData?.country_id?.value,
            country_name: postJobData?.country_id?.label,
            skills: postJobData?.skills?.map((item) => {
              return item?.value;
            }), //job_tag Table-> Change tag_id
            tools: postJobData?.tools?.map((item) => {
              return item?.value;
            }), // job_tool Table -> Change tool_id (job_id)
            industry_id: postJobData?.industry_id?.map((item) => {
              return item?.value;
            }),
            preferred_qualification_ids:
              postJobData?.preferred_qualification_ids?.length > 0
                ? [postJobData?.preferred_qualification_ids?.[0]?.value]
                : [],
            association_ids:
              postJobData?.association_ids?.length > 0
                ? [postJobData?.association_ids?.[0]?.value]
                : [],
            screen_questions: postJobData?.job_questions,
            job_bio: postJobData?.job_bio,
            job_summary_flag: postJobData?.job_summary_flag,
            role_summary: postJobData?.description,
            role_responsibilty: postJobData?.role_responsibilty,
            role_requirements: postJobData?.role_requirements,
            job_notes: postJobData?.job_notes,
            candidate_video_complete_flag:
              postJobData?.jobDetail?.candidate_video_complete_flag,
            video_description: postJobData?.jobDetail?.video_description,
            briffing_video_flag: postJobData?.jobDetail?.briffing_video_flag,
            video_for_shortlisted_flag:
              postJobData?.jobDetail?.video_for_shortlisted_flag,
          };
          delete data?.alternateTitle
          dispatch(postAdminJob(data)).then((payload) => {
            if (payload?.payload?.status === "success") {
              dispatch(
                setAlert({
                  show: true,
                  type: ALERT_TYPE?.SUCCESS,
                  msg: i18n["alertMessage.Jobpostedsuccessfully"],
                })
              );
              setPostJobData(POST_JOB_ADMIN);
              videoFile &&
                uploadVideo(videoFile, payload?.payload?.data?.job_id);
              getJobList(0, basicData);
              handleClose();
            } else {
              dispatch(
                setAlert({
                  show: true,
                  type: ALERT_TYPE?.ERROR,
                  msg: ERROR_MSG,
                })
              );
            }
          });
        } catch (error) {
          dispatch(
            setAlert({
              show: true,
              type: ALERT_TYPE?.ERROR,
              msg: i18n["alertMessage.error"],
            })
          );
        }
      })
      .catch((error) => {
        setErrors(
          Object.keys(error)?.map((item) => ({
            message: error[item],
            key: item,
          }))
        );
      });
  };

  const getInitialData = async (basic) => {
    try {
      if (basic?.currency_id !== "") {
        await dispatch(getCurrency());
      }
      if (basic?.language_ids?.length !== 0) {
        await dispatch(getLanguage());
      }
    } catch (error) {
      dispatch(setLoading(false));
      dispatch(
        setAlert({
          show: true,
          type: ALERT_TYPE?.ERROR,
          msg: ERROR_MSG,
        })
      );
    }
  };

  const onHandleVideoRecording = () => {
    setOpenVideoRecording(false);
  };

  const handleVideoData = (data) => {
    setOpenVideoRecording(false);
    setOpenReviewVideoDialog(true);
    setRecordedVideoData(data);
    const link = URL.createObjectURL(data);
    setVideoLink(link);
  };

  const uploadVideo = async (file, jobId) => {
    const formData = new FormData();
    formData?.append("jobvideo", file);
    formData?.append("job_id", jobId);
    try {
      const { payload } = await dispatch(uploadPostJobCultureVideo(formData));
      if (payload?.status === "success") {
        // dispatch(
        //   setAlert({
        //     show: true,
        //     type: ALERT_TYPE?.SUCCESS,
        //     msg: i18n["alertMessage.VideouploadedSuccessfully"],
        //   })
        // );
        setOpenReviewVideoDialog(false);
      } else {
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE?.ERROR,
            msg: i18n["alertMessage.FailedtouploadVideo"],
          })
        );
      }
    } catch (error) {
      dispatch(setAlert({ show: true }));
    }
  };
  const createFileFromBlob = () => {
    const myFile = new File([recorderVideoData], "videoFile.webm", {
      type: recorderVideoData?.type,
    });
    setVideoFile(myFile);
    setOpenReviewVideoDialog(false);
  };

  useEffect(() => {
    getInitialData(postJobData);
  }, []);

  return (
    <>
      <Modal
        open={show}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box sx={{ height: "100%", overflow: "auto" }}>
            <BasicInfo
              jobDetail={postJobData}
              setJobDetail={setPostJobData}
              tools={tools}
              skills={skills}
              industries={industries}
              titles={titles}
              roleTypes={roleTypes}
              contractDetails={contractDetails}
              requiredQua={requiredQua}
              qualifications={qualifications}
              initialSkills={initialSkills}
              language={language}
              nationality={nationality}
              association={association}
              workSetup={workSetup}
              town={town}
              country={country}
              currency={currency}
              errors={errors}
              setErrors={setErrors}
            />
            <DetailInfo
              jobDetail={postJobData}
              setJobDetail={setPostJobData}
              errors={errors}
              setErrors={setErrors}
              setSameJobAndBio={setSameJobAndBio}
              sameJobAndBio={sameJobAndBio}
            />
            <CultureData
              jobDetail={postJobData}
              setJobDetail={setPostJobData}
              personalities={personalities}
              traits={traits}
              errors={errors}
              setErrors={setErrors}
            />
          </Box>
          <Box sx={{ background: "white" }}>
            <Button
              sx={{
                boxShadow: 0,
                fontSize: "14px",
                width: "333.33px",
                borderRadius: "0 0 0 10px",
                height: "60px",
                background: "lightgray",
                color: "black",
                padding: 3,
                ":hover": {
                  background: "lightgray",
                },
              }}
              variant="contained"
              onClick={handleClose}
            >
              cancel
            </Button>
            <Button
              variant="contained"
              color="recordVideoButton"
              sx={{
                width: "333.33px",
                height: "60px",
                fontSize: "15px",
                fontWeight: theme.typography.Bold,
                borderRadius: 0,
              }}
              onClick={() =>
                postJobData?.jobDetails?.briffing_video_flag &&
                setOpenVideoRecording(true)
              }
              disabled={!postJobData?.jobDetails?.briffing_video_flag}
            >
              upload video
            </Button>
            <Button
              sx={{
                boxShadow: 0,
                fontSize: "14px",
                width: "333.34px",
                borderRadius: "0 0 10px 0",
                height: "60px",
                padding: 3,
              }}
              variant="contained"
              color="redButton100"
              onClick={editJobDetail}
            >
              save
            </Button>
          </Box>
        </Box>
      </Modal>
      <CustomDialog
        show={openVideoRecording}
        hideButton={false}
        dialogWidth="xs"
        showFooter={false}
        // title={i18n["login.signUp"]}
        isApplyJob
        padding={0}
      >
        {openVideoRecording && (
          <RecordingInProgress
            onRecordingStop={handleVideoData}
            onCloseCamera={onHandleVideoRecording}
          />
        )}
      </CustomDialog>
      <CustomDialog
        show={openReviewVideoDialog}
        hideButton={false}
        onDialogClose={() => {
          setVideoLink("");
          setRecordedVideoData(null);
          setOpenReviewVideoDialog(false);
        }}
        dialogWidth="xs"
        showFooter={false}
        isApplyJob
        padding={0}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            gap: "10px",
          }}
        >
          <video
            autoPlay={false}
            loops
            preload="auto"
            poster=""
            style={{
              width: "80%",
              height: "auto",
              borderRadius: "10px",
              cursor: disabledButton ? "wait" : "pointer",
            }}
            src={videoLink}
            type="video/mp4"
            controls={false}
            onClick={() => {
              if (!disabledButton) {
                if (VideoRef?.current?.paused) {
                  VideoRef?.current?.play();
                  setIsPlaying(true);
                } else {
                  VideoRef?.current?.pause();
                  setIsPlaying(false);
                }
              }
            }}
            onLoadedData={() => {
              setDisabledButton(false);
            }}
            onEnded={() => setIsPlaying(false)}
          ></video>
          <Box>
            <Typography
              sx={{
                textAlign: "center",
                fontSize: "20px",
                fontWeight: 700,
              }}
            >
              A star performance!
            </Typography>
            <Typography
              sx={{
                textAlign: "center",
                fontSize: "14px",
                fontWeight: 700,
                marginBottom: 3,
              }}
            >
              delete or upload your video now.
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              width: "100%",
            }}
          >
            <Button
              variant="contained"
              color="deleteVideoButton"
              sx={{
                borderRadius: 0,
                width: "50%",
                height: "60px",
              }}
              onClick={() => {
                setVideoLink("");
                setRecordedVideoData(null);
                setOpenReviewVideoDialog(false);
              }}
            >
              delete
            </Button>
            <Button
              variant="contained"
              color="uploadVideoButton"
              sx={{
                borderRadius: 0,
                width: "50%",
                height: "60px",
              }}
              onClick={createFileFromBlob}
            >
              upload video
            </Button>
          </Box>
        </Box>
      </CustomDialog>
    </>
  );
}
