import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  Divider,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { ALERT_TYPE, COMPANY_CLAIMS } from "../../../../utils/Constants";
import { TableCells } from "../../../../utils/CommonComponent";
import companyLogo from "../../../../assets/Crayon_Favicon.svg";
import { getCompanyVouchers } from "../../../../redux/admin/maintenance";
import { setAlert } from "../../../../redux/configSlice";
import { useDispatch } from "react-redux";
import { dateConverterMonth } from "../../../../utils/DateTime";

export default function CompanyClaims({
  companyClaims,
  handleClose,
  selectedCompany,
}) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [companyVoucherInfo, setCompanyVoucherInfo] = useState([]);
  const [lastKey, setLastKey] = useState(false);

  const getCompanyVoucher = async (lastKeyy) => {
    try {
      const { payload } = await dispatch(
        getCompanyVouchers({
          lastKey: lastKeyy,
          company_id: selectedCompany?.company_id,
        })
      );
      if (payload?.status === "success") {
        if (payload?.pageNumber === 0) {
          setCompanyVoucherInfo(payload?.data);
          setLastKey(payload?.pageNumber + 1);
        } else if (payload?.data?.length > 0) {
          setCompanyVoucherInfo((prev) => [...prev, ...payload?.data]);
          setLastKey(payload?.pageNumber + 1);
        }
      }
    } catch (error) {
      dispatch(
        setAlert({
          show: true,
          type: ALERT_TYPE?.ERROR,
          msg: error,
        })
      );
    }
  };

  useEffect(() => {
    getCompanyVoucher(0);
  }, []);

  return (
    <Dialog
      open={companyClaims}
      fullWidth={true}
      //   maxWidth={"xs"}
      scroll="body"
      padding={0}
      sx={{
        // zIndex: 100000,
        "& .MuiPaper-root": {
          borderRadius: "25px",
          maxWidth: "660px !important",
        },
      }}
    >
      <DialogTitle sx={{ padding: "24px 20px" }}>
        <Box
          sx={{
            display: "flex",
            width: "290px",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box sx={{ display: "flex", gap: "13px", alignItems: "center" }}>
            <Box
              component={"img"}
              src={companyLogo}
              sx={{ width: 40, height: 40, borderRadius: "10px" }}
            />
            <Typography
              sx={{ fontSize: "14px", fontWeight: theme.typography.Bold }}
            >
              {selectedCompany?.company?.name}
            </Typography>
          </Box>
          <Box
            sx={{
              width: "105px",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography
              sx={{ fontSize: "14px", fontWeight: theme.typography.Bold }}
            >
              Claims
            </Typography>
            <Button
              variant="contained"
              color="submitButton"
              sx={{
                minWidth: "fit-content",
                height: "26px",
                borderRadius: "7px",
                padding: "7px",
                color: "black",
              }}
            >
              97
            </Button>
          </Box>
        </Box>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Box
            sx={{
              width: "20px",
              height: "20px",
              color: "#000000",
              border: 2,
              fontSize: "18px",
              borderRadius: "5px",
            }}
          >
            X
          </Box>
        </IconButton>
      </DialogTitle>
      <Divider />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "15px",
          width: "95%",
          mt: "8px",
          overflowY: "auto",
          height: "220px",
          "::-webkit-scrollbar": {
            height: "5px",
            background: "#DCDEEB",
            display: "unset !important",
          },
        }}
        id="matchTaleneList"
      >
        <InfiniteScroll
          style={{
            overflowX: "hidden",
            scrollbarWidth: "thin",
            display: "flex",
            flexDirection: "column",
            gap: "16px",
            width: "100%",
          }}
          dataLength={companyVoucherInfo?.length}
          next={() => getCompanyVoucher(lastKey)}
          scrollThreshold={"100px"}
          scrollableTarget={"matchTaleneList"}
          hasMore={true}
          endMessage={
            <p style={{ textAlign: "center" }}>
              <b>Yay! You have seen it all</b>
            </p>
          }
        >
          <TableContainer component={Paper} sx={{ height: "250px" }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCells
                    sx={{
                      padding: "22px 16px 0px 20px",
                    }}
                  >
                    <Typography variant="subtitle1" fontWeight="bold">
                      Voucher
                    </Typography>
                  </TableCells>
                  <TableCells
                    sx={{
                      padding: "22px 16px 0px 20px",
                    }}
                  >
                    <Typography
                      variant="subtitle1"
                      fontWeight="bold"
                      sx={{ textAlign: "center" }}
                    >
                      User
                    </Typography>
                  </TableCells>
                  <TableCells
                    sx={{
                      padding: "22px 16px 0px 20px",
                    }}
                  >
                    <Typography
                      variant="subtitle1"
                      fontWeight="bold"
                      sx={{ textAlign: "center" }}
                    >
                      Date Claimed
                    </Typography>
                  </TableCells>
                  <TableCells
                    sx={{
                      padding: "22px 16px 0px 20px",
                    }}
                  >
                    <Typography
                      variant="subtitle1"
                      fontWeight="bold"
                      sx={{ textAlign: "center" }}
                    >
                      Coins Expiry Date
                    </Typography>
                  </TableCells>
                </TableRow>
              </TableHead>

              <TableBody>
                {companyVoucherInfo?.map((row) => (
                  <TableRow
                    key={row?.voucher_claim_id}
                    style={{
                      "& .css-12zgwp-MuiTableCell-root": {
                        padding: "0 16px !important",
                      },
                    }}
                  >
                    <TableCells
                      sx={{
                        padding: "16px 16px 8px 20px",
                        fontWeight: theme.typography.Bold,
                      }}
                    >
                      {row?.voucher?.name}
                    </TableCells>
                    <TableCells
                      sx={{
                        padding: "16px 16px 8px 20px",
                        textAlign: "center",
                      }}
                    >
                      {row?.user?.first_name} {row?.user?.last_name}
                    </TableCells>
                    <TableCells
                      sx={{
                        padding: "16px 16px 8px 20px",
                        textAlign: "center",
                      }}
                    >
                      {dateConverterMonth(row?.created_at)}
                    </TableCells>
                    <TableCells
                      sx={{
                        padding: "16px 16px 8px 20px",
                        textAlign: "center",
                      }}
                    >
                      {dateConverterMonth(row?.expiry_coin_date)}
                    </TableCells>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </InfiniteScroll>
      </Box>
    </Dialog>
  );
}
