import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getApi } from "../../utils/Apis";
import { setLoading } from "../configSlice";

const initialState = {
  companylist: [],
  showBox: false,
  currentPageJobsCount: 0,
};

export const getHiringList = createAsyncThunk(
  "getHiringList",
  async (
    {
      lastKey = "",
      decodedToken,
      industryID = "",
      status = "",
      company_size = "",
      company_type = "",
      favourite = "",
      appliedJobs = "",
      query = "",
      tag_id = "",
      tool_id = "",
      job_title = "",
      town_id = "",
      region_id = "",
      salary = "",
      experience = "",
      highest_qualification_id = "",
      currency_id = "",
      company_id = "",
    },
    { dispatch }
  ) => {
    // let isAuthorize = decodedToken? true:false
    dispatch(setLoading(true));
    const { data } = await getApi(
      "/company/companyhiring?industry_id=" +
        industryID +
        "&lastKey=" +
        lastKey +
        "&status=" +
        status +
        "&company_size=" +
        company_size +
        "&company_type=" +
        company_type +
        "&favourite=" +
        favourite +
        "&appliedJobs=" +
        appliedJobs +
        "&query=" +
        query +
        "&tag_id=" +
        tag_id +
        "&tool_id=" +
        tool_id +
        "&town_id=" +
        town_id +
        "&region_id=" +
        region_id +
        "&salary=" +
        salary +
        "&experience=" +
        experience +
        "&highest_qualification_id=" +
        highest_qualification_id +
        "&currency_id=" +
        currency_id +
        "&company_id=" +
        company_id +
        "&job_title=" +
        job_title
    );
    dispatch(setLoading(false));
    return data;
  }
);

export const getCompanyJobs = createAsyncThunk(
  "getCompanyJobs",
  async ({ lastKey, company_id, job_status_id, appliedJobs }, { dispatch }) => {
    const { data } = await getApi(
      "/company/companyjobs?lastKey=" +
        lastKey +
        "&company_id=" +
        company_id +
        "&job_status_id=" +
        job_status_id +
        "&appliedJobs=" +
        appliedJobs,
      true
    );
    return data;
  }
);

export const whoshiring = createSlice({
  name: "configWhosHiring",
  initialState,
  reducers: {
    updateAllCompany: (state, action) => {
      return {
        ...state,
        companylist: [],
        showBox: false,
        currentPageJobsCount: 0,
      };
    },
  },
  extraReducers(builder) {
    builder.addCase(getHiringList.fulfilled, (state, action) => {
      if (action?.payload?.data?.length <= 0) {
        state.showBox = true;
        state.currentPageJobsCount = action?.payload?.data?.length || 0;
      }
      state.currentPageJobsCount = action?.payload?.data?.length || 0;
      state.companylist = [
        ...(state?.companylist || []),
        ...(action?.payload?.data?.map((company) => {
          return {
            ...company,
          };
        }) || []),
      ];
    });
  },
});

export const { updateAllCompany } = whoshiring.actions;

export default whoshiring.reducer;
