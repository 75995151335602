import { Box, Button, Typography } from "@mui/material";
import React from "react";
import greenTriangle from "../../assets/Characters/Green_Triangle_Happy.svg";
import locale from "../../i18n/locale";
import { useNavigate } from "react-router-dom";
import { getDecodedToken, getToken } from "../../utils/Common";
import { MEMBER_INFO } from "../../utils/Constants";

export default function MyTeamErrorBox({
  setShowBox,
  errorMessage,
  handleAddAnotherUser,
}) {
  const i18n = locale.en;
  const naviagte = useNavigate();
  const decodedToken = getDecodedToken(getToken());

  return (
    <Box
      sx={{
        textAlign: "center",
        display: "flex",
        flexDirection: "column",
        gap: 3,
        backgroundColor: "white",
        borderRadius: "25px",
      }}
    >
      <Box
        component={"img"}
        src={greenTriangle}
        sx={{ width: 96, height: 96, margin: "auto", mb: 0 }}
      />
      <Typography
        sx={{
          fontSize: "16px",
          fontWeight: 700,
        }}
      >
        {errorMessage}
      </Typography>
      <Button
        variant="contained"
        sx={{
          borderRadius: "0px 0px 25px 25px",
          width: "100%",
          height: "60px",
        }}
        onClick={() => {
          setShowBox(false);
          handleAddAnotherUser();
        }}
        color="redButton"
      >
        close
      </Button>
    </Box>
  );
}
