
import { Modal } from "@mui/material";
import React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import locale from "../../i18n/locale";
import { ALERT_TYPE } from "../../utils/Constants";
import { useDispatch } from "react-redux";
import CopyToClipboard from "react-copy-to-clipboard";
import { setAlert } from "../../redux/configSlice";

const CopyConfirmationModal = ({
  handleOpen,
  handleClose,
  handleSubscriptionOk,
  modalHeading,
  modalContent,
  handleSubscriptionCancel,
  shareUrl,
  setCount
}) => {
  const dispatch = useDispatch();

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    borderRadius: "12px",
    p: 4,
    boxShadow: "none !important",
    border: "1px solid black !important",
  };
  const i18n = locale.en;
  return (
    <Modal
      open={handleOpen}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography
          id="modal-modal-title"
          variant="h6"
          component="h2"
          textAlign={"center"}
          sx={{marginBottom:"10px"}}
        >

        {modalHeading?modalHeading:i18n["alertMessage.subScriptionHeading"]}
        </Typography>
        <Box>
        <Box sx={{textAlign:"center",marginBottom:"20px"}}>
        {modalContent?modalContent:i18n["alertMessage.subScription"]}
        </Box>
       </Box>
        <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Button
            sx={{
              height: "38px",
              mr: 2,
              borderRadius: "10px",
              color: "black",
            }}
            variant="contained"
            color="grayButton300"
            onClick={handleSubscriptionCancel}
          >
            cancel
          </Button>
          <CopyToClipboard
                  text={shareUrl?.shared_url}
                  onCopy={() => {
                    dispatch(
                      setAlert({
                        show: true,
                        type: ALERT_TYPE.SUCCESS,
                        msg: "Copied to clipboard",
                      })
                    )
                    ;setCount()
                  }}
                >
          <Button
            sx={{
              height: "38px",
              mr: 2,
              borderRadius: "10px",
              color: "black",
            }}
            variant="contained"
            color="grayButton300"
            onClick={handleSubscriptionOk}
          >
            copy
          </Button>
          </CopyToClipboard>
        </Box>
      </Box>
    </Modal>
  );
};

export default CopyConfirmationModal;
