import { useEffect, useState } from "react";
import JobCardFront from "./frontSide/JobCardFront";
import JobCardBack from "./backSide/JobCardBack";

import ReactCardFlip from "react-card-flip";
import ReviewVideoDialog from "../../common/ReviewVideoDialog";
import { useDispatch } from "react-redux";
import { getVideoLink } from "../../../redux/guest/jobsSlice";
import Signup from "../../login/signup";
import Login from "../../login/login";
import {
  ALERT_TYPE,
  AUTHORIZED_TAB_ITEMS_CANDIDATE,
  AUTHORIZED_TAB_ITEMS_CANDIDATE_PROMOTER,
  AUTHORIZED_TAB_ITEMS_EMPLOYER,
  AUTHORIZED_TAB_ITEMS_EMPLOYER_PROMOTER,
  AUTHORIZED_TAB_ITEMS_PROMOTER,
  AUTHORIZED_TAB_ITEMS_RECRUITER,
  AUTHORIZED_TAB_ITEMS_RECRUITER_PROMOTER,
} from "../../../utils/Constants";
import { setAlert } from "../../../redux/configSlice";
import { useNavigate } from "react-router-dom";
import ResendMailBox from "../../login/ResendMailBox";
import { getDecodedToken } from "../../../utils/Common";
import {
  setActiveTab,
  setIsAdmin,
  topBarCurrentTab,
} from "../../../redux/login/loginSlice";
import ForgotPasswordConfirmationModal from "../../login/ForgotPasswordConfirmationModal";

export default function JobCard({
  index,
  job,
  setJobId,
  onHandleClose,
  onClose,
  setOpenNotLoggedIn,
  setopenApplyJobDialog,
  setSelectedCard,
  jobslength,
  currentPageJobsCount,
  setJobVideoRequired,
  mobileText,
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isHorizontalFlipped, setisHorizontalFlipped] = useState(false);
  const [openReviewVideoDialog, setOpenReviewVideoDialog] = useState(false);
  const [videoLink, setVideoLink] = useState("");
  const [showLogin, setShowLogin] = useState(false);
  const [showSignup, setShowSignup] = useState(false);
  const [allVideoData, setAllVideoData] = useState("");
  const [resendMailBox, setResendMailBox] = useState(false);
  const [email, setEmail] = useState("");
  const[uploadedFileName, setUploadedFileName]= useState(null)


  const [isLoggedIn, setIsLoggedIn] = useState(
    Boolean(localStorage.getItem("token"))
  );
  const handleCloseDialog = () => {
    setVideoLink("");
    setOpenReviewVideoDialog(false);
  };
  const onHandleCloseSignup = () => {
    setShowSignup(false);
  };
  const [isForgotPassword, setIsForgotPassowrd] = useState(false);

  const onHandleLogin = async (payload) => {
    setShowLogin(false);
    const token = payload?.token;
    const decodedToken = getDecodedToken(token);
    const user = parseInt(decodedToken?.data?.role_id);
    const isPromoter = parseInt(decodedToken?.data?.promoter);
    const profileCompletion = decodedToken?.data?.profile_percent_complete;
    const isNavigate = decodedToken?.data?.employerProfileFlag;

    localStorage.setItem("token", token);
    onHandleClose();

    let tabs;
    if ([1, 2, 8].includes(user)) {
      dispatch(setIsAdmin(true));
      navigate("/admin/adminJobs/active-jobs", { replace: true });
    } else if (user === 4) {
      tabs = isPromoter
        ? AUTHORIZED_TAB_ITEMS_EMPLOYER_PROMOTER
        : AUTHORIZED_TAB_ITEMS_EMPLOYER;
      if (profileCompletion === 100 || isNavigate) {
        navigate("/employer/my-jobs", { replace: true });
        dispatch(setActiveTab("/employer/my-jobs"));
      } else {
        navigate("/employer/my-profile", { replace: true });
        dispatch(setActiveTab("/employer/my-profile"));
      }
    } else if (user === 5) {
      tabs = AUTHORIZED_TAB_ITEMS_PROMOTER;
      navigate("/promoter/shared-jobs", { replace: true });
      dispatch(setActiveTab("/promoter/shared-jobs"));
    } else if (user === 6) {
      tabs = isPromoter
        ? AUTHORIZED_TAB_ITEMS_RECRUITER_PROMOTER
        : AUTHORIZED_TAB_ITEMS_RECRUITER;
      if (profileCompletion === 100 || isNavigate) {
        navigate("/recruiter/my-jobs", { replace: true });
        dispatch(setActiveTab("/recruiter/my-jobs"));
      } else {
        navigate("/recruiter/my-profile", { replace: true });
        dispatch(setActiveTab("/recruiter/my-profile"));
      }
    } else {
      tabs = isPromoter
        ? AUTHORIZED_TAB_ITEMS_CANDIDATE_PROMOTER
        : AUTHORIZED_TAB_ITEMS_CANDIDATE;
      navigate("/candidate/my-jobs", { replace: true });
      dispatch(setActiveTab("/candidate/my-jobs"));
    }

    dispatch(topBarCurrentTab(tabs));
    setIsLoggedIn(true);
  };

  const handleCloseSignup = () => {
    setShowSignup(false);
  };

  const toggleForm = () => {
    setShowLogin((prevState) => !prevState);
    setShowSignup((prevState) => !prevState);
  };

  const handleCloseLogin = () => {
    setShowLogin(false);
  };

  const closeForgotPassword = () => {
    setShowSignup(true);
  };

  useEffect(() => {
    const fetchVideoLink = async () => {
      try {
        const dataLink = await dispatch(getVideoLink({ job_id: job?.job_id }));
        setAllVideoData(dataLink?.payload?.data);
        setVideoLink(dataLink?.payload?.data?.job_video_url);
      } catch (error) {
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE?.ERROR,
            msg: error,
          })
        );
      }
    };

    if (openReviewVideoDialog) {
      fetchVideoLink();
    }
  }, [dispatch, job?.job_id, openReviewVideoDialog]);
  return (
    <>
      <ReactCardFlip
        isFlipped={isHorizontalFlipped}
        flipDirection={"horizontal"}
        flipSpeedBackToFront="0.5"
        flipSpeedFrontToBack="0.5"
        key={job?.job_id}
      >
        <JobCardFront
          index={job.job_id}
          job={job}
          setJobId={setJobId}
          setJobVideoRequired={setJobVideoRequired}
          onHandleClose={onHandleClose}
          setopenApplyJobDialog={setopenApplyJobDialog}
          setisFlipped={setisHorizontalFlipped}
          onClose={onClose}
          handleReviewVideoDialog={setOpenReviewVideoDialog}
          setOpenNotLoggedIn={setOpenNotLoggedIn}
          setSelectedCard={setSelectedCard}
          jobslength={jobslength}
          indexNumber={index}
          currentPageJobsCount={currentPageJobsCount}
          setShowLogin={setShowLogin}
          mobileText={mobileText}
        />
        <JobCardBack
          setShowLogin={setShowLogin}
          index={job.job_id}
          job={job}
          setJobId={setJobId}
          setJobVideoRequired={setJobVideoRequired}
          onHandleClose={onHandleClose}
          setopenApplyJobDialog={setopenApplyJobDialog}
          setisFlipped={setisHorizontalFlipped}
          onClose={onClose}
          setOpenNotLoggedIn={setOpenNotLoggedIn}
          setSelectedCard={setSelectedCard}
          handleReviewVideoDialog={setOpenReviewVideoDialog}
          jobslength={jobslength}
          isHorizontalFlipped={isHorizontalFlipped}
          indexNumber={index}
          currentPageJobsCount={currentPageJobsCount}
          mobileText={mobileText}
        />
      </ReactCardFlip>
      {openReviewVideoDialog && (
        <ReviewVideoDialog
          videoLink={videoLink}
          open={openReviewVideoDialog}
          setOpen={handleCloseDialog}
          allVideoData={allVideoData}
        />
      )}
      {showLogin && (
        <Login
          handleLogin={onHandleLogin}
          openFunc={showLogin}
          toggleForm={toggleForm}
          closeFunc={handleCloseLogin}
          handleOpenLogin={setShowLogin}
          handleOpenSignUp={closeForgotPassword}
          handleForgotPassword={closeForgotPassword}
          setResendMailBox={setResendMailBox}
          uploadedFileName={uploadedFileName}
          setEmail={setEmail}
          handleConfirmPasswordChange={() => setIsForgotPassowrd(true)}
        />
      )}
      <ResendMailBox
        setResendMailBox={setResendMailBox}
        email={email}
        open={resendMailBox}
        handleClose={() => setResendMailBox(false)}
      />
      {showSignup && (
        <Signup
          onDialogClose={onHandleCloseSignup}
          toggleForm={toggleForm}
          openFunc={showSignup}
          closeFunc={handleCloseSignup}
          handleOpenSignUp={setShowSignup}
          uploadedFileName={uploadedFileName}

        />
      )}
      {isForgotPassword && (
        <ForgotPasswordConfirmationModal
          openFunc={isForgotPassword}
          closeFunc={() => setIsForgotPassowrd(false)}
        />
      )}
    </>
  );
}
