import React, { useCallback, useEffect, useRef, useState } from "react";
import { TalentCardStyledAccordion } from "../../../../utils/CommonComponent";
import {
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  IconButton,
  Slider,
  ThemeProvider,
  useTheme,
} from "@mui/material";
import SmallButton from "../../../common/SmallButton";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import profile from "../../../../assets/user_logo.svg";
import linkedin from "../../../../assets/linkedin.svg";

import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import Popper from "@mui/material/Popper";
import MenuList from "@mui/material/MenuList";
import Stack from "@mui/material/Stack";

import CopyToClipboardIcon from "../../../../assets/CircularIcon/Yellow/Circular Icons__Yellow_Move.svg";
import quickLinkIcon from "../../../../assets/CircularIcon/Blue/Circular Icons__Blue_Network.svg";
import playIcon from "../../../../assets/CircularIcon/Green/Circular Icons__Green_Play.svg";
import profileIcon from "../../../../assets/CircularIcon/Red/Circular Icons__Red_CV.svg";
import chatHistoryIcon from "../../../../assets/CircularIcon/Yellow/Circular Icons__Yellow_Chat History_2.svg";
import RedchatHistoryIcon from "../../../../assets/CircularIcon/Red/Circular Icons__Red_Chat History_1.svg";
import { MenuItem, Paper, Popover, Tooltip } from "@mui/material";
import MUIRadialChart from "../../../common/MUIRadialChart";
import contact from "../../../../assets/Padding Excluded/Black_Contact_Yellow.svg";
import email from "../../../../assets/Padding Excluded/Black_Email_Red.svg";
import locationIcon from "../../../../assets/Padding Excluded/Black_Location.svg";
import maleIcon from "../../../../assets/Padding Excluded/Black_Male.svg";
import femaleIcon from "../../../../assets/Padding Excluded/Black_Female.svg";
import editIcon from "../../../../assets/Padding Included/Yellow_Edit.svg";

import upClose from "../../../../assets/Padding Included/Black_Up_Close.svg";
import downClose from "../../../../assets/Padding Included/Black_Down_Open.svg";
import deleteIcon from "../../../../assets/Padding Excluded/Black_Trash_Delete_1 - Copy.svg";
import activeDownClose from "../../../../assets/Black_Down_Open - Copy.svg";
import TalentSVGButton from "../../../common/TalentSVGButton";
import { CheckCircle, Circle } from "@mui/icons-material";
import SVGButton from "../../../common/SVGButton";

import {
  calculateFontSize,
  calculateHeightTalentCard,
  deepCompare,
  getDecodedToken,
  getToken,
} from "../../../../utils/Common";
import Refferals from "../../adminTalent/DialogBox/Refferals";
import Databases from "../../adminTalent/DialogBox/Databases";
import VideoDialog from "../../adminTalent/DialogBox/VideoDialog";
import Document from "../../adminTalent/DialogBox/Document";
import { formatPhoneNumberIntl } from "react-phone-number-input";
import { Link, useLocation, useParams } from "react-router-dom";
import CommentBox from "../../adminTalent/DialogBox/CommentBox";
import { adminUsersTheme, employerDefaulTheme } from "../../../../utils/Theme";
import Slider2 from "../../../common/Slider2";
import EditEmployer from "./EditEmployer";
import CustomDialog from "../../../common/CustomDialog";
import Cropper from "react-easy-crop";
import ZoomOutIcon from "@mui/icons-material/Remove";
import ZoomInIcon from "@mui/icons-material/Add";
import locale from "../../../../i18n/locale";
import getCroppedImg from "../../../../utils/cropImage";
import { uploadProfilePic } from "../../../../redux/candidate/myProfileSlice";
import { useDispatch } from "react-redux";
import { setAlert } from "../../../../redux/configSlice";
import {
  ALERT_TYPE,
  ERROR_MSG,
  PASSWORD_REGEX,
  PERSONALITY,
  PERSONALITY_BUTTONS,
  PERSONALITY_EMPLOYER,
  USERS_STATUS,
} from "../../../../utils/Constants";
import HistoryBox from "../../../common/HistoryBox";
import { updateUserStatus } from "../../../../redux/admin/jobsSlice";
import ChangeUserStatus from "../../../common/ChangeUserStatus";
import CropModal from "../../../employer/myProfile/cropModal";
import LockResetIcon from "@mui/icons-material/LockReset";
import ChangePasswordModal from "../../../admin/users/team/ChangePasswordModal";
import {
  changePassword,
  verifyUsers,
} from "../../../../redux/admin/userAdminSlice";
import profile_challenger from "../../../../assets/Personality Logos/personality_characters_challenger.svg";
// import profile_challenger from "../../../../assets/Personality Logos/crayon_personality_grey_challeneger.svg";
import profile_character from "../../../../assets/Personality Logos/crayon_personality_character.svg";
import profile_collaborator from "../../../../assets/Personality Logos/crayon_personality_collaborator.svg";
import profile_contemplator from "../../../../assets/Personality Logos/crayon_personality_contemplator.svg";
import profile_grey_challenger from "../../../../assets/Personality Logos/crayon_personality_grey_challenger.svg";
import profile_grey_character from "../../../../assets/Personality Logos/crayon_personality_grey_character.svg";
import edit from "../../../../assets/Padding Excluded/Black_Edit.svg";
import EditEmployerPersonlaity from "./EditEmployerPersonlaity";
import VerifyUser from "../../adminTalent/DialogBox/VerifyUser";

export default function EmployerCard({
  employerData,
  getEmployerList,
  roleId,
  setEmployerData,
  allEmployerData,
}) {
  const theme = useTheme();
  const i18n = locale.en;
  const hiddenFileInput = useRef(null);
  const dispatch = useDispatch();
  const { id } = useParams();
  const params = useLocation();
  const decodedToken = getDecodedToken(getToken());

  const companyIndustries = employerData?.company_details
    ?.flatMap((company) => company?.industries)
    ?.map((industry) => industry?.industry_name)
    ?.filter((name) => name !== null && name !== undefined);

  const [confirmVerify, setConfirmVerify] = useState(false);
  const [openVerify, setOpenVerify] = useState(false);
  const [flip, setFlip] = useState(id ? true : false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openActive, setOpenActive] = useState(false);
  const [anchorElPersonality, setAnchorElPersonality] = useState(null);
  const [openEdit, setOpenEdit] = useState(false);
  const [editEmployer, setEditEmployer] = useState();
  const [openVideo, setOpenVideo] = useState(null);
  const [openDocument, setOpenDocument] = useState(null);
  const [anchorElReferral, setAnchorElReferral] = useState(null);
  const [openEditImage, setOpenEditImage] = useState(false);
  const [imagePreview, setImagePreview] = useState([]);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [imageName, setImageName] = useState("My pic");
  const [isOpenHistoryBox, setOpenHistoryBox] = useState(false);
  const [editPersonality, seteditPersonality] = useState(false);

  const open = Boolean(anchorEl);
  const openPersonality = Boolean(anchorElPersonality);
  const openReferral = Boolean(anchorElReferral);
  const openDialog = Boolean(openVideo);
  const openDocumentDialog = Boolean(openDocument);
  const anchorRef = useRef(null);
  const [imageBlob, setImageBlob] = useState();
  const [croppedImageUrl, setCroppedImageUrl] = useState(null);
  const [image, setImage] = useState([]);

  const [isPasswordChangeModal, setIsPasswordChangeModal] = useState(false);
  const [changePasswordData, setChangePasswordData] = useState({});
  const [changePasswordError, setChangePasswordError] = useState({
    change_password: false,
    confirm_password: false,
    errorText: "",
  });

  const [personalitiesData, setPersonalitiesData] = useState({
    ...PERSONALITY_EMPLOYER,
  });
  const [buttonArr, setButtonArr] = useState(PERSONALITY_BUTTONS);

  const handlePopoverCloseReferral = () => {
    setAnchorElReferral(null);
  };

  const handleToggle = () => {
    setOpenActive((prevOpen) => !prevOpen);
  };

  const handleCloseActive = (event) => {
    if (anchorRef?.current && anchorRef?.current?.contains(event?.target)) {
      return;
    }

    setOpenActive(false);
  };

  const handleListKeyDown = (event) => {
    if (event?.key === "Tab") {
      event?.preventDefault();
      setOpenActive(false);
    } else if (event?.key === "Escape") {
      setOpenActive(false);
    }
  };

  const handlePopoverClose = () => {
    setAnchorElPersonality(null);
  };

  const handlePopoverCloseVideo = () => {
    setOpenVideo(null);
  };

  const handlePopoverCloseDocument = () => {
    setOpenDocument(null);
  };

  const handleEmployerEdit = (empId) => {
    setOpenEdit(true);
    setEditEmployer(empId);
  };

  const handleClose = () => {
    setOpenEdit(false);
  };

  const handleUploadLogo = (e) => {
    setImagePreview(null);
    hiddenFileInput?.current?.click();
  };
  const handleSubscriptionCancel = () => {
    setImageName("My pic");
    setOpenEditImage(false);
    setImageBlob();
    setCroppedImageUrl(null);
    setImage();
  };
  const cropInputFUnction = (e) => {
    const files = e.target.files;
    if (files && files.length > 0) {
      const reader = new FileReader();
      reader.onload = () => setImage(reader?.result);
      reader.readAsDataURL(files?.[0]);
    }
  };
  const handleImageChange = async (event, name) => {
    if (event?.target?.files?.[0] === undefined) {
      return;
    }
    cropInputFUnction(event);
    const render = new FileReader();
    setZoom(1);
    setImageName(event?.target?.files?.[0].name);
    const imageData = window.URL.createObjectURL(
      new Blob(event?.target?.files, { type: "application/*" })
    );
    setImagePreview(imageData);
    setOpenEditImage(true);
    event.target.value = "";
  };

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const renderFooter = <></>;

  const handleZoom = (direction) => {
    const step = 0.5;
    let newZoom = zoom;

    if (direction === "+") {
      newZoom = Math.min(zoom + step, 3); // Limit zoom to maximum 3x
    } else if (direction === "-") {
      newZoom = Math.max(zoom - step, 1); // Limit zoom to minimum 1x
    }
    setZoom(newZoom);
  };

  const handleSubscriptionOk = async () => {
    // async (event) => {
    //   const croppedImage = await getCroppedImg(
    //     imagePreview,
    //     croppedAreaPixels,
    //     0
    //   );
    const formData = new FormData();
    const blobTofile = new File([imageBlob], imageName, {
      type: "image/jpeg",
    });

    formData.append("profile-pic", blobTofile);
    formData.append("user_id", employerData?.user_id);
    try {
      const { payload } = await dispatch(uploadProfilePic(formData));
      if (payload?.status === "success") {
        setEmployerData(
          allEmployerData?.map((item) => {
            return item?.user_id === employerData?.user_id
              ? { ...item, profile_url: payload?.data }
              : item;
          })
        );
        setOpenEditImage(false);
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE?.SUCCESS,
            msg: i18n["alertMessage.ProfilepicuploadedSuccessfully"],
          })
        );
      } else if (payload?.status === "error") {
        setImageName("My pic");
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE?.ERROR,
            msg: i18n["alertMessage.Imageformatnotsupported"],
          })
        );
      }
    } catch (error) {
      setImageName("My pic");
      dispatch(
        setAlert({
          show: true,
          type: ALERT_TYPE?.ERROR,
          msg: "",
        })
      );
    }
  };

  // const handleEmployerLogo = useCallback(
  //   async (event) => {
  //     const croppedImage = await getCroppedImg(
  //       imagePreview,
  //       croppedAreaPixels,
  //       0
  //     );
  //     const formData = new FormData();
  //     const blobTofile = new File([croppedImage], imageName, {
  //       type: "image/jpeg",
  //     });

  //     formData.append("profile-pic", blobTofile);
  //     formData.append("user_id", employerData?.user_id);
  //     try {
  //       const { payload } = await dispatch(uploadProfilePic(formData));
  //       if (payload?.status === "success") {
  //         setEmployerData(
  //           allEmployerData.map((item) => {
  //             return item.user_id === employerData?.user_id
  //               ? { ...item, profile_url: payload?.data }
  //               : item;
  //           })
  //         );
  //         setOpenEditImage(false);
  //         dispatch(
  //           setAlert({
  //             show: true,
  //             type: ALERT_TYPE?.SUCCESS,
  //             msg: i18n["alertMessage.ProfilepicuploadedSuccessfully"],
  //           })
  //         );
  //       } else if (payload?.status === "error") {
  //         setImageName("My pic");
  //         dispatch(
  //           setAlert({
  //             show: true,
  //             type: ALERT_TYPE?.ERROR,
  //             msg: i18n["alertMessage.Imageformatnotsupported"],
  //           })
  //         );
  //       }
  //     } catch (error) {
  //       setImageName("My pic");
  //       dispatch(
  //         setAlert({
  //           show: true,
  //           type: ALERT_TYPE?.ERROR,
  //           msg: "",
  //         })
  //       );
  //     }
  //   },
  //   [croppedAreaPixels]
  // );

  const handleStatusChange = async (status, statusName) => {
    const data = {
      user_status: status, // 3 for Suspended
      userId: employerData?.user_id,
    };

    const { payload } = await dispatch(updateUserStatus(data));
    if (payload?.status === "success") {
      dispatch(
        setAlert({
          show: true,
          type: ALERT_TYPE?.SUCCESS,
          msg: i18n["alertMessage.userstatuschange"],
        })
      );
      setOpenActive(false);
      setEmployerData(
        allEmployerData?.map((item) => {
          if (item?.user_id === employerData?.user_id) {
            return { ...item, user_status: status };
          }
          return item;
        })
      );
    } else {
      dispatch(
        setAlert({
          show: true,
          type: ALERT_TYPE?.ERROR,
          msg: ERROR_MSG,
        })
      );
    }
  };

  const handlePasswordModal = (data) => {
    setIsPasswordChangeModal(true);
    setChangePasswordData({ userId: data });
  };

  const handleUserVerify = async () => {
    try {
      const data = {
        Email: employerData?.email,
      };
      const { payload } = await dispatch(verifyUsers(data));
      if (payload?.status === "success") {
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE?.SUCCESS,
            msg: "User verified successfully!",
          })
        );
        setEmployerData(
          allEmployerData?.map((item) => {
            if (item?.user_id === employerData?.user_id) {
              return { ...item, is_verified: 1 };
            }
            return item;
          })
        );
        setConfirmVerify(false);
        setOpenVerify(false);
      } else {
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE?.ERROR,
            msg: "Error",
          })
        );
      }
    } catch (error) {
      dispatch(
        setAlert({
          show: true,
          type: ALERT_TYPE?.ERROR,
          msg: error,
        })
      );
    }
  };

  const handleModalClose = () => {
    setIsPasswordChangeModal(false);
    setChangePasswordError({
      change_password: false,
      confirm_password: false,
      errorText: "",
    });
  };

  const handleChangePasswordSubmit = async () => {
    let payload = {
      userid: changePasswordData?.userId,
      password: changePasswordData?.confirm_password,
    };
    let condition = deepCompare(
      changePasswordData?.change_password,
      changePasswordData?.confirm_password
    );
    if (
      condition?.status &&
      PASSWORD_REGEX?.test(changePasswordData?.change_password) &&
      PASSWORD_REGEX?.test(changePasswordData?.change_password)
    ) {
      try {
        let response = await dispatch(changePassword(payload));
        if (response?.payload?.status === "success") {
          setIsPasswordChangeModal(false);
          setChangePasswordError({
            change_password: false,
            confirm_password: false,
            errorText: "",
          });
          dispatch(
            setAlert({
              show: true,
              type: ALERT_TYPE?.SUCCESS,
              msg: "Password updated successfully",
            })
          );
        }
      } catch (error) {
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE?.ERROR,
            msg: "Something went wrong",
          })
        );
      }
    } else {
      if (condition?.status) {
        setChangePasswordError({
          change_password: false,
          confirm_password: false,
          errorText: "",
        });
      } else {
        setChangePasswordError({
          change_password: true,
          confirm_password: true,
          errorText: "",
        });
      }
    }
  };
  const handleChangePasswordOnChange = (e, name) => {
    let updatedData = { ...changePasswordData };
    updatedData[name] = e.target.value;
    setChangePasswordData({ ...updatedData });
    if (!PASSWORD_REGEX?.test(e.target.value)) {
      setChangePasswordError({
        [name]: false,
        errorText:
          e.target.id === "change_password"
            ? "change_password"
            : "confirm_password",
      });
      return;
    } else {
      setChangePasswordError({
        [name]: false,
        errorText: e.target.id === "change_password" ? "" : "",
      });
    }
  };

  const handleEdit = () => {
    seteditPersonality((prev) => !prev);
  };

  const handleOpenVerify = () => {
    setOpenVerify((prev) => !prev);
  };

  const handleCancelVerify = () => {
    setOpenVerify(false);
    setConfirmVerify(false);
  };

  return (
    <>
      <TalentCardStyledAccordion expanded={flip}>
        <AccordionSummary aria-controls="panel1a-content" id="panel1a-header">
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <SmallButton
              color="redButton"
              startIcon={
                <Box
                  component="img"
                  className="eye"
                  alt="eye logo"
                  src={flip ? upClose : downClose}
                  sx={{
                    height: 16,
                    width: { xs: 10, lg: 8, mlg: 10, xl: 15 },
                  }}
                />
              }
              padding={0}
              height={"101px"}
              width={20}
              fontWeight={700}
              borderRadius={flip ? "20px 0px 20px 0px" : "20px 0px 0px 20px"}
              onClick={() => setFlip((prev) => !prev)}
            />
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                paddingX: 1,
                maxHeight: "101px",
              }}
            >
              <Box sx={{ height: "50px", width: "100%" }}>
                <Box
                  component="img"
                  className="profileAvatar"
                  alt="crayon logo"
                  src={
                    employerData?.profile_url !== "No URL"
                      ? employerData?.profile_url
                      : profile
                  }
                  sx={{
                    // mr: 1,
                    height: "100% !important",
                    width: "95% !important",
                    borderRadius: "10px 10px 0 0 !important",
                    border: `1px solid ${theme.palette.borderColor}`,
                  }}
                />
                <input
                  ref={hiddenFileInput}
                  type="file"
                  accept="image/*"
                  onChange={(event) => handleImageChange(event, "company")}
                  style={{ display: "none" }}
                />
                <Box
                  component={"img"}
                  src={editIcon}
                  sx={{
                    position: "absolute",
                    height: "20px",
                    width: "20px",
                    top: "15px",
                    left: "85px",
                  }}
                  onClick={handleUploadLogo}
                />
              </Box>
              <SmallButton
                color="userID"
                label={employerData?.user_id}
                // mr={1}
                margin={"3px 0 0 0"}
                fontSize={10}
                fontWeight={700}
                alignItems="end"
                textColor="#000"
                borderRadius="0px 0px 6px 6px"
                marginTop="-2px"
                minWidth="50px"
                width="fit-content"
              ></SmallButton>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexGrow: 1,
                justifyContent: "space-between",
                flexDirection: "column",
                paddingBottom: 1,
                marginLeft: "10px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "end",
                      gap: 1,
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        // gap: "10px",
                      }}
                    >
                      <TalentSVGButton
                        color={"white"}
                        source={
                          employerData?.gender === "male"
                            ? maleIcon
                            : femaleIcon
                        }
                        height={24}
                        width={25}
                        padding={"0px !important"}
                      />
                      <Link
                        to={
                          params?.pathname?.includes("user-recruiters")
                            ? `/admin/users/user-recruiters/${employerData?.user_id}`
                            : `/admin/users/user-employers/${employerData?.user_id}`
                        }
                        target={"_blank"}
                        style={{
                          textDecoration: "none",
                          color: theme.palette.black,
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: "16px",
                            fontWeight: 700,
                            width: "500px",
                            overflow: "hidden",
                            lineHeight: 1,
                            wordBreak: "break-word",
                          }}
                          noWrap={true}
                        >
                          {employerData?.first_name} {employerData?.last_name}
                        </Typography>
                      </Link>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <TalentSVGButton
                      color={"white"}
                      source={email}
                      height={28}
                      width={18}
                      padding={"0 4px 0 3px !important"}
                    />
                    <Typography
                      sx={{
                        fontSize: "16px",
                        fontWeight: 700,
                        width: "500px",
                        overflow: "hidden",
                        lineHeight: 1,
                        wordBreak: "break-word",
                      }}
                      noWrap={true}
                    >
                      {employerData?.email}
                    </Typography>
                  </Box>
                </Box>
              </Box>

              <Box
                sx={{
                  display: "flex",
                }}
              >
                {/* <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  maxWidth: { xs: "75px", lg: "75px", mlg: "80px", xl: "90px" },
                  width: "100%",
                }}
              >
                <TalentSVGButton
                  padding={"0px !important"}
                  color={"white"}
                  source={userProfile}
                  height={28}
                  width={24}
                />
                <Typography
                  sx={{
                    fontSize: "12px",
                    fontWeight: 600,
                    // mb: "2px",

                    width: "100%",
                    whiteSpace: "nowrap", // Prevents text from wrapping
                    overflow: "hidden", // Hides any overflowing content
                    textOverflow: "ellipsis", // Adds dots at the end of overflowing text
                  }}
                >
                  24 years
                </Typography>
              </Box> */}
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <TalentSVGButton
                    padding={"0px !important"}
                    color={"white"}
                    source={locationIcon}
                    height={28}
                    width={23}
                  />
                  <Tooltip disableInteractive placement="top-end">
                    <Typography
                      sx={{
                        fontSize: "16px",
                        fontWeight: 700,
                        width: "500px",
                        overflow: "hidden",
                        lineHeight: 1,
                        wordBreak: "break-word",
                      }}
                      noWrap={true}
                    >
                      {employerData?.town_name || employerData?.country_name ? (
                        <>
                          {employerData?.town_name && (
                            <>{`${employerData.town_name}, `}</>
                          )}
                          {`${employerData?.country_name}`}
                        </>
                      ) : (
                        "-"
                      )}
                    </Typography>
                  </Tooltip>
                </Box>
                {/* <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  maxWidth: {
                    xs: "120px",
                    lg: "120px",
                    mlg: "200px",
                    xl: "250px",
                  },
                  width: "100%",
                }}
              >
                <TalentSVGButton
                  color={"white"}
                  source={salary}
                  height={20}
                  width={18}
                />
                <Typography
                  sx={{
                    fontSize: "12px",
                    fontWeight: 600,
                    width: "180px",
                    whiteSpace: "nowrap", // Prevents text from wrapping
                    overflow: "hidden", // Hides any overflowing content
                    textOverflow: "ellipsis",
                  }}
                >
                  $10,000pm to $40,000pm
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  maxWidth: {
                    xs: "75px",
                    lg: "75px",
                    mlg: "200px",
                    xl: "250px",
                  },
                  width: "100%",
                }}
              >
                <TalentSVGButton
                  color={"white"}
                  source={pending}
                  height={20}
                  width={18}
                />
                <Typography
                  sx={{
                    fontSize: "12px",
                    fontWeight: 600,
                    // mb: "2px",
                    whiteSpace: "nowrap",
                  }}
                >
                  4 years
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  maxWidth: {
                    xs: "100px",
                    lg: "100px",
                    mlg: "200px",
                    xl: "250px",
                  },
                  width: "100%",
                }}
              >
                <TalentSVGButton
                  color={"white"}
                  source={notice}
                  height={20}
                  width={18}
                />
                <Typography
                  sx={{
                    fontSize: "12px",
                    fontWeight: 600,
                    // mb: "2px",
                    whiteSpace: "nowrap",
                  }}
                >
                  4 weeks
                </Typography>
              </Box> */}
              </Box>
              {flip && (
                <>
                  <Box display={"flex"} alignItems={"center"}>
                    <TalentSVGButton
                      color={"white"}
                      source={contact}
                      height={16}
                      width={18}
                      padding={"0 0 0 3px !important"}
                    />

                    <Typography
                      sx={{
                        fontSize: "12px",
                        fontWeight: 500,
                      }}
                    >
                      {employerData?.contact_no
                        ? formatPhoneNumberIntl(
                            employerData?.contact_no?.includes("+")
                              ? employerData?.contact_no
                              : `+${employerData?.contact_no}`
                          )
                        : "-"}
                    </Typography>

                    <Box
                      component="img"
                      className="profileAvatar"
                      alt="crayon logo"
                      src={linkedin}
                      sx={{
                        mr: 1,
                        ml: 1,
                        width: "20px",
                        height: "20px",
                      }}
                    />
                    {employerData?.linkedin_profile_link ? (
                      <Link
                        to={`${employerData?.linkedin_profile_link}`}
                        target="_blank"
                        style={{
                          textDecoration: "none",
                          color: theme.palette.black,
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: "12px",
                            fontWeight: 500,
                            // mb: "2px",
                            width: "500px",
                            overflow: "hidden",
                            lineHeight: 1,
                            wordBreak: "break-word",
                          }}
                          noWrap={true}
                        >
                          {employerData?.linkedin_profile_link
                            ? employerData?.linkedin_profile_link
                            : "-"}
                        </Typography>
                      </Link>
                    ) : (
                      <Typography
                        sx={{
                          fontSize: "12px",
                          fontWeight: 500,
                          // mb: "2px",
                          width: "500px",
                          overflow: "hidden",
                          lineHeight: 1,
                          wordBreak: "break-word",
                        }}
                        noWrap={true}
                      >
                        {employerData?.linkedin_profile_link
                          ? employerData?.linkedin_profile_link
                          : "-"}
                      </Typography>
                    )}
                  </Box>
                </>
              )}
            </Box>
            <Box
              sx={{
                display: "flex",
                flexGrow: 1,
                alignItems: "center",
                justifyContent: "center",
                gap: { xs: "10px", lg: "15px", mlg: "20px" },
                padding: { xs: 0, lg: 0, mlg: "0 15px" },
              }}
            >
              {!flip && (
                <Box
                  sx={{
                    display: { xs: "flex" },
                    gap: "10px",
                  }}
                >
                  <MUIRadialChart
                    value={"77"}
                    chartName={"applications"}
                    size={calculateHeightTalentCard()}
                    max={500}
                    countFontSize={16}
                    labelFontSize={calculateFontSize()}
                    color={"Applied"}
                  />
                  <MUIRadialChart
                    value={"10"}
                    chartName={"shortlisted"}
                    size={calculateHeightTalentCard()}
                    max={100}
                    countFontSize={16}
                    labelFontSize={calculateFontSize()}
                    color={"Shortlisted"}
                  />
                  <MUIRadialChart
                    value={"20"}
                    chartName={"interviews"}
                    size={calculateHeightTalentCard()}
                    max={50}
                    countFontSize={16}
                    labelFontSize={calculateFontSize()}
                    color={"Interviewed"}
                  />
                </Box>
              )}
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-end",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {(decodedToken?.data?.role_id === 1 ||
                  decodedToken?.data?.role_id === 2) && (
                  <>
                    <LockResetIcon
                      sx={{
                        width: "30px",
                        height: "30px",
                        // marginTop: "5px",
                      }}
                      onClick={() => handlePasswordModal(employerData?.user_id)}
                    />
                    <Box
                      component={"img"}
                      src={editIcon}
                      sx={{
                        height: "26px",
                        width: "26px",
                      }}
                      onClick={() => handleEmployerEdit(employerData?.user_id)}
                    />
                    <TalentSVGButton
                      color={"white"}
                      source={deleteIcon}
                      height={24}
                      width={18}
                    />
                    {decodedToken?.data?.role_id === 1 ||
                    decodedToken?.data?.role_id === 2 ? (
                      <CheckCircle
                        sx={{
                          fontSize: 30,
                          color:
                            employerData?.is_verified === 0
                              ? `${theme.palette.submitButton.main}`
                              : `${theme.palette.greenSwitch.main}`,
                        }}
                        onClick={() =>
                          employerData?.is_verified === 0 &&
                          setOpenVerify((prev) => !prev)
                        }
                      />
                    ) : null}
                  </>
                )}
                <Box sx={{ display: "flex", paddingLeft: "10px" }}>
                  <Button
                    variant="contained"
                    color="lightGreenButton300"
                    sx={{
                      fontSize: "12px",
                      fontWeight: 500,
                      color: "white",
                      maxWidth: "70px",
                      padding: "8px 10px",
                      borderRadius: "0 0 0 10px",
                      height: "30px !important",
                      ":hover": {
                        boxShadow: "none",
                      },
                    }}
                  >
                    Shortlist
                  </Button>
                  <Button
                    variant="contained"
                    color="dateButton"
                    sx={{
                      fontSize: "12px",
                      fontWeight: 700,
                      color: "#000",
                      maxWidth: "90px",
                      padding: "8px 10px",
                      borderRadius: "0px !important",
                      borderRight: "1px solid #EBECF3",
                      borderBottom: "1px solid #EBECF3",
                      height: "30px !important",
                      width: "110px !important",
                      whiteSpace: "nowrap",
                      ":hover": {
                        boxShadow: "none",
                      },
                    }}
                  >
                    08 Jan 2024
                  </Button>
                  <Button
                    variant="contained"
                    color="dateButton"
                    sx={{
                      fontSize: "12px",
                      fontWeight: 700,
                      color: "#000",
                      padding: "8px 10px",
                      maxWidth: "70px",
                      borderRadius: "0px !important",
                      borderRight: "1px solid #EBECF3",
                      borderBottom: "1px solid #EBECF3",
                      height: "30px !important",
                      width: "max-content",
                      whiteSpace: "nowrap",
                      ":hover": {
                        boxShadow: "none",
                      },
                    }}
                  >
                    1 day
                  </Button>
                  <Button
                    ref={anchorRef}
                    id="composition-button"
                    aria-controls={open ? "composition-menu" : undefined}
                    aria-expanded={open ? "true" : undefined}
                    aria-haspopup="true"
                    onClick={handleToggle}
                    variant="contained"
                    color="base"
                    sx={{
                      fontSize: "12px",
                      fontWeight: 700,
                      color: "#000",
                      padding: "0 15px 0 0",
                      width: "120px",
                      gap: "5px",
                      justifyContent: "flex-end",
                      borderRadius: "0px !important",
                      borderRight: "1px solid #EBECF3",
                      borderBottom: "1px solid #EBECF3",
                      height: "30px !important",
                      ":hover": {
                        boxShadow: "none",
                      },
                    }}
                  >
                    {
                      USERS_STATUS?.find(
                        (item) => item?.id === employerData?.user_status
                      )?.name
                    }
                    <Circle
                      fontSize="string"
                      color={
                        USERS_STATUS?.find(
                          (item) => item?.id === employerData?.user_status
                        )?.color
                      }
                    />
                    <Box
                      component="img"
                      className="eye"
                      alt="eye logo"
                      src={activeDownClose}
                      sx={{
                        height: 25,
                        width: 20,
                      }}
                    />
                  </Button>
                  <ChangeUserStatus
                    currentStatus={employerData?.user_status}
                    openActive={openActive}
                    anchorRef={anchorRef}
                    // isAdmin={isAdmin}
                    handleListKeyDown={handleListKeyDown}
                    handleCloseActive={handleCloseActive}
                    handleStatusChange={handleStatusChange}
                    userStatus={USERS_STATUS}
                  />
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: { xs: "column", lg: "column", mlg: "row" },
                  gap: "10px",
                  paddingRight: "10px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                  }}
                >
                  <SmallButton
                    color="blueButton700"
                    label={"Permanent"}
                    value={"Permanent"}
                    mr="4px"
                    fontSize="12px"
                  ></SmallButton>

                  <SmallButton
                    color="blueButton700"
                    label={"in-office"}
                    fontSize="12px"
                  ></SmallButton>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <SVGButton
                    color={"white"}
                    source={CopyToClipboardIcon}
                    height={30}
                    width={30}
                    startIconMargin={"0px !important"}
                    padding={"0px !important"}
                  />
                  <SVGButton
                    color={"white"}
                    source={quickLinkIcon}
                    height={30}
                    width={30}
                    startIconMargin={"0px !important"}
                    padding={"0px !important"}
                    onClick={(event) => {
                      setAnchorElPersonality(event?.target);
                    }}
                  />
                  <Popover
                    id="dropdown-menu"
                    open={openPersonality}
                    anchorEl={anchorElPersonality}
                    onClose={handlePopoverClose}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "center",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "center",
                    }}
                    sx={{
                      "& .MuiPaper-root-MuiPopover-paper": {
                        minWidth: "18% !important",
                        borderRadius: "20px !important",
                        mt: 1,
                      },
                    }}
                  >
                    <Databases />
                  </Popover>
                  <SVGButton
                    color={"white"}
                    source={playIcon}
                    height={30}
                    width={30}
                    startIconMargin={"0px !important"}
                    padding={"0px !important"}
                    onClick={(event) => setOpenVideo(event?.target)}
                  />
                  <Popover
                    id="dropdown-menu"
                    open={openDialog}
                    anchorEl={openVideo}
                    onClose={handlePopoverCloseVideo}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "center",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "center",
                    }}
                    sx={{
                      "& .css-ll95b0-MuiPaper-root-MuiPopover-paper": {
                        minWidth: "10% !important",
                        borderRadius: "20px !important",
                        mt: 1,
                      },
                    }}
                  >
                    <VideoDialog handleOpen={handlePopoverCloseVideo} />
                  </Popover>
                  <SVGButton
                    color={"white"}
                    source={profileIcon}
                    height={30}
                    width={30}
                    startIconMargin={"0px !important"}
                    padding={"0px !important"}
                    onClick={(event) => setOpenDocument(event?.target)}
                  />
                  <Popover
                    id="dropdown-menu"
                    open={openDocumentDialog}
                    anchorEl={openDocument}
                    onClose={handlePopoverCloseDocument}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "center",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "center",
                    }}
                    sx={{
                      "& .css-ll95b0-MuiPaper-root-MuiPopover-paper": {
                        minWidth: "12% !important",
                        borderRadius: "20px !important",
                        mt: 1,
                      },
                    }}
                  >
                    <Document handleOpen={handlePopoverCloseDocument} />
                  </Popover>

                  <SVGButton
                    color={"white"}
                    source={chatHistoryIcon}
                    height={30}
                    width={30}
                    startIconMargin={"0px !important"}
                    padding={"0px !important"}
                    onClick={() => setOpenHistoryBox(true)}
                  />
                  <SVGButton
                    color={"white"}
                    source={RedchatHistoryIcon}
                    height={30}
                    width={30}
                    startIconMargin={"0px !important"}
                    padding={"0px !important"}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        </AccordionSummary>

        <AccordionDetails
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Grid item>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Box
                className="contentBoxLeft"
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <Box sx={{ marginTop: "11px" }}>
                  <Typography
                    sx={{
                      fontSize: "14px",
                      fontWeight: theme.typography.Bold,
                    }}
                  >
                    Company Info
                  </Typography>
                  <Box sx={{ display: "flex", gap: "30px", flexWrap: "wrap" }}>
                    {employerData?.company_details?.map((item) => {
                      return (
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "space-between",
                            gap: "8px",
                          }}
                        >
                          <Typography
                            sx={{
                              fontSize: "12px",
                              fontWeight: theme.typography.Medium,
                            }}
                          >
                            Company name :{" "}
                            {item?.company_name ? item?.company_name : "-"}
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: "12px",
                              fontWeight: theme.typography.Medium,
                            }}
                          >
                            Company size :{" "}
                            {item?.company_size ? (
                              <SmallButton
                                color="sizeTypeButton"
                                label={item?.company_size}
                                value={item?.company_size}
                                mr="4px"
                                fontSize="12px"
                              />
                            ) : (
                              "-"
                            )}
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: "12px",
                              fontWeight: theme.typography.Medium,
                            }}
                          >
                            Company type :{" "}
                            {item?.company_type ? (
                              <SmallButton
                                color="sizeTypeButton"
                                label={item?.company_type}
                                value={item?.company_type}
                                mr="4px"
                                fontSize="12px"
                              />
                            ) : (
                              "-"
                            )}
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: "12px",
                              fontWeight: theme.typography.Medium,
                            }}
                          >
                            Descripiton :{" "}
                            {item?.description ? item?.description : "-"}
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: "12px",
                              fontWeight: theme.typography.Medium,
                              display: "flex",
                              gap: "4px",
                            }}
                          >
                            Industries :{" "}
                            <Box>
                              <Slider2
                                flexwrap="wrap"
                                items={companyIndustries || []}
                                color="industiresButton"
                                gap={"5px"}
                              />
                            </Box>
                          </Typography>
                        </Box>
                      );
                    })}
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-around",
                    alignItems: "center",
                    width: "50%",
                  }}
                >
                  {employerData?.primary_personality_id && (
                    <Box
                      component="img"
                      height={100}
                      alt="Primary personality"
                      src={
                        (employerData?.primary_personality_id === 1 &&
                          profile_challenger) ||
                        (employerData?.primary_personality_id === 2 &&
                          profile_character) ||
                        (employerData?.primary_personality_id === 3 &&
                          profile_contemplator) ||
                        (employerData?.primary_personality_id === 4 &&
                          profile_collaborator) ||
                        profile_grey_challenger
                      }
                    />
                  )}

                  {employerData?.secondary_personality_id && (
                    <Box
                      component="img"
                      height={100}
                      alt="Shadow personality"
                      src={
                        (employerData?.secondary_personality_id === 1 &&
                          profile_challenger) ||
                        (employerData?.secondary_personality_id === 2 &&
                          profile_character) ||
                        (employerData?.secondary_personality_id === 3 &&
                          profile_contemplator) ||
                        (employerData?.secondary_personality_id === 4 &&
                          profile_collaborator) ||
                        profile_grey_character
                      }
                    />
                  )}
                  <MUIRadialChart
                    max={100}
                    value={employerData?.grit_score}
                    size={100}
                    color={"grit_score"}
                    chartName={"grit score"}
                    countFontSize={16}
                    labelFontSize={12}
                  />
                  {decodedToken?.data?.role_id === 1 && (
                    <SmallButton
                      minWidth="10px"
                      height={25}
                      endIcon={
                        <Box
                          component="img"
                          className="eye"
                          alt="eye logo"
                          src={edit}
                          sx={{
                            height: 10,
                            width: 12,
                          }}
                        />
                      }
                      color="yellowButton400"
                      borderRadius="5px"
                      label="edit"
                      mr="4px"
                      padding="10px !important"
                      onClick={handleEdit}
                    ></SmallButton>
                  )}
                </Box>
              </Box>
              <Box className="contentBoxRight">
                <ThemeProvider theme={adminUsersTheme}>
                  {flip && (
                    <CommentBox
                      talentData={employerData}
                      page={roleId === 4 ? "employer" : "recruiter"}
                    />
                  )}
                </ThemeProvider>
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center !important",
              }}
            >
              <SmallButton
                color="redButton"
                startIcon={
                  <Box
                    component="img"
                    className="eye"
                    alt="eye logo"
                    src={upClose}
                    sx={{
                      height: 16,
                      width: 16,
                    }}
                  />
                }
                onClick={() => setFlip((prev) => !prev)}
                startIconMargin="4px"
                marginTop="5px"
                height={19}
                width={142}
                fontWeight={700}
                borderRadius="7px 7px 0px 0px"
              />
            </Box>
          </Grid>
        </AccordionDetails>
        {openEditImage && (
          <CropModal
            handleOpen={openEditImage}
            handleClose={setOpenEditImage}
            handleSubscriptionOk={handleSubscriptionOk}
            handleSubscriptionCancel={handleSubscriptionCancel}
            imagePreview={imagePreview}
            image={image}
            setImage={setImage}
            croppedImageUrl={croppedImageUrl}
            setCroppedImageUrl={setCroppedImageUrl}
            imageBlob={imageBlob}
            setImageBlob={setImageBlob}
          />
        )}
        {editPersonality && (
          <EditEmployerPersonlaity
            show={editPersonality}
            talentContent={employerData}
            setEmployerData={setEmployerData}
            allEmployerData={allEmployerData}
            seteditPersonality={seteditPersonality}
            setPersonalitiesData={setPersonalitiesData}
            personalitiesData={personalitiesData}
            setButtonArr={setButtonArr}
            buttonArr={buttonArr}
          />
        )}
        {/* <CustomDialog
          dialogWidth="md"
          show={openEditImage}
          footer={renderFooter}
          isProfile
        >
          <IconButton
            aria-label="close"
            onClick={() => {
              setImageName("My pic");
              setOpenEditImage(false);
            }}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme?.palette?.grey[500],
            }}
          >
            <Box
              sx={{
                width: "20px",
                height: "20px",
                color: "#000000",
                border: 2,
                fontSize: "18px",
                borderRadius: "5px",
              }}
            >
              X
            </Box>
          </IconButton>
          <Box
            sx={{
              position: "absolute",
              top: "10%",
              left: "10%",
              right: "10%",
              bottom: "20%",
              ".reactEasyCrop_Contain": {
                maxWidth: "40% !important",
              },
            }}
          >
            <Cropper
              image={imagePreview}
              crop={crop}
              zoom={zoom}
              aspect={1}
              cropShape="round"
              showGrid={true}
              onCropChange={setCrop}
              onCropComplete={onCropComplete}
            />
          </Box>
          <Box
            className="controls"
            sx={{
              position: "absolute",
              bottom: "20px",
              left: "50%",
              width: "50%",
              transform: "translateX(-50%)",
              height: "90px",
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <Box
              sx={{
                display: "flex",
              }}
            >
              <Button variant="text" onClick={() => handleZoom("-")}>
                <ZoomOutIcon />
              </Button>
              <Box
                sx={{
                  width: 200,
                  mx: 3,
                }}
              >
                <Slider
                  defaultValue={0}
                  size="small"
                  value={zoom}
                  min={1}
                  max={3}
                  step={0.5}
                  aria-labelledby="Zoom"
                  onChange={(e) => {
                    setZoom(e.target.value);
                  }}
                  className="zoom-range"
                />
              </Box>
              <Button variant="text" onClick={() => handleZoom("+")}>
                <ZoomInIcon />
              </Button>
              <Button variant="text" onClick={() => setZoom(1)}>
                Reset
              </Button>
            </Box>

            <Box>
              <Button
                onClick={() => {
                  setImageName("My pic");
                  setOpenEditImage(false);
                }}
                disableElevation
                variant="outlined"
                color="redButton"
                sx={{ width: "130px", mr: 2 }}
              >
                {i18n["empMyProfile.cancel"]}
              </Button>
              <Button
                onClick={handleEmployerLogo}
                disableElevation
                variant="contained"
                color="redButton"
                sx={{ width: "130px" }}
              >
                {i18n["empMyProfile.upload"]}
              </Button>
            </Box>
          </Box>
        </CustomDialog> */}
      </TalentCardStyledAccordion>
      <ThemeProvider theme={employerDefaulTheme}>
        <EditEmployer
          show={openEdit}
          editEmployer={editEmployer}
          handleClose={handleClose}
          getEmployerList={getEmployerList}
          roleId={roleId}
        />
      </ThemeProvider>
      {isOpenHistoryBox && (
        <HistoryBox
          handleOpen={isOpenHistoryBox}
          handleClose={() => setOpenHistoryBox(false)}
          openFor={"jobs"}
          email={employerData?.email}
        />
      )}
      {isPasswordChangeModal && (
        <ChangePasswordModal
          errors={changePasswordError}
          formData={changePasswordData}
          handleFormInput={handleChangePasswordOnChange}
          handleCancel={handleModalClose}
          handleSubmit={handleChangePasswordSubmit}
          handleOpen={isPasswordChangeModal}
          handleClose={handleModalClose}
        />
      )}
      <VerifyUser
        show={openVerify}
        handleOpen={handleOpenVerify}
        handleCancel={handleCancelVerify}
        handleVerify={handleUserVerify}
        confirmVerify={confirmVerify}
        setConfirmVerify={setConfirmVerify}
        dialogText={`${employerData?.first_name} ${employerData?.last_name}`}
      />
    </>
  );
}
