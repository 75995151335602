import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import SearchBox from "./Dialogbox/SearchBox";
import AddCoinSpend from "./Dialogbox/AddCoinSpend";
import InfiniteScroll from "react-infinite-scroll-component";
import { ALERT_TYPE } from "../../../utils/Constants";
import logo from "../../../assets/Crayon_Favicon.svg";
import { TableCells } from "../../../utils/CommonComponent";
import CompanyCoins from "./Dialogbox/CompanyCoins";
import CoinsPrice from "./Dialogbox/CoinsPrice";
import SpendItems from "./Dialogbox/SpendItems";
import ConfirmationPopUp from "./Dialogbox/ConfirmationPopUp";
import {
  deleteSpendItem,
  getCoins,
  getList,
  getPlanList,
} from "../../../redux/admin/maintenance";
import { useDispatch } from "react-redux";
import { setAlert } from "../../../redux/configSlice";
import { dateConverterMonth } from "../../../utils/DateTime";
import AddSpendCoinModal from "./AddSpendCoinModal";
import Delete from "../../candidate/myCam/dialog/Delete";
import CrayonsPlans from "./Dialogbox/CrayonsPlans";
import EditCrayonPlan from "./EditCrayonPlan";

export default function CoinsHistory() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [addCoinSpend, setAddCoinSpend] = useState(false);
  const [coinsHistory, setCoinsHistory] = useState([]);
  const [companyCoins, setCompanyCoins] = useState(false);
  const [companyInfo, setCompanyInfo] = useState();
  const [confirm, setConfirm] = useState(false);
  const [info, setInfo] = useState({});
  const [lastKey, setLastKey] = useState(0);
  const [spendList, setSpendList] = useState([]);
  const [formData, setFormData] = useState({});
  const [errors, setErrors] = useState({});
  const [isAddSpendCoin, setIsAddSpendCoin] = useState(false);
  const [selectedCoinItem, setSelectedCoinItem] = useState({});
  const [isEdit, setIsEdit] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [planList, setPlanList] = useState([]);
  const [viewMoreExpanded, setViewMoreExpanded] = useState(false);
  const [editPlan, setEditPlan] = useState(false);
  const [crayonFormData, setCrayonFormData] = useState({});
  const [selectedCrayonCoinItem, setSelectedCrayonCoinItem] = useState({});

  const handleText = async (event) => {};

  const getCrayonPlan = async () => {
    try {
      let { payload } = await dispatch(getPlanList());
      if (payload?.status === "success") {
        setPlanList(payload?.data);
      }
    } catch (error) {
      dispatch(
        setAlert({
          show: true,
          type: ALERT_TYPE?.ERROR,
          msg: error,
        })
      );
    }
  };
  const getCoinsHistory = async (lastKeyy) => {
    try {
      const { payload } = await dispatch(getCoins({ lastKey: lastKeyy }));
      if (payload?.status === "success") {
        if (payload?.pageNumber === 0) {
          setCoinsHistory(payload?.data);
          setLastKey(payload?.pageNumber + 1);
        } else if (payload?.data?.length > 0) {
          setCoinsHistory((prev) => [...prev, ...payload?.data]);
          setLastKey(payload?.pageNumber + 1);
        }
      } else {
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE?.ERROR,
            msg: payload?.message,
          })
        );
      }
    } catch (error) {
      dispatch(
        setAlert({
          show: true,
          type: ALERT_TYPE?.ERROR,
          msg: error,
        })
      );
    }
  };

  const handleSelectChangePaid = (event, row, id, name) => {
    setCoinsHistory(
      coinsHistory?.map((item) => {
        return item?.spend_id === info?.row?.spend_id
          ? {
              ...item,
              [info?.switch === "paid" ? "paid" : "invoice"]: info?.id,
            }
          : item;
      })
    );
    setConfirm(false);
  };

  const getSpendList = async () => {
    try {
      let { payload } = await dispatch(getList());

      if (payload) {
        setSpendList(payload?.data);
      }
    } catch (error) {
      dispatch(
        setAlert({
          show: true,
          type: ALERT_TYPE?.ERROR,
          msg: "Something went wrong",
        })
      );
    }
  };

  const handleFormInput = (event, name) => {
    let updatedData = { ...formData };
    updatedData[name] = event?.target?.value;
    setFormData({ ...updatedData });
    const updatedError = errors;
    delete updatedError[name];
    setErrors(updatedError);
  };

  const handleCreateSelectOnChange = (ind, newValue, val, index) => {};

  const handleOpenAddSpendModal = (row) => {
    setIsAddSpendCoin(true);
    setSelectedCoinItem(row);
  };
  const handleOpenAddCrayonPlanModal = (row) => {
    setEditPlan(true);
    setSelectedCrayonCoinItem(row);
  };

  const handleCloseAddSpendModal = () => {
    setIsAddSpendCoin(false);
    setSelectedCoinItem({});
    setFormData({});
    setErrors({});
  };

  const handleDel = async () => {
    const payload = {
      transaction_name_id: selectedCoinItem?.transaction_name_id,
    };
    try {
      const response = await dispatch(deleteSpendItem(payload));
      if (response?.payload?.data) {
        getSpendList();
        setIsDelete(false);
      }
    } catch (error) {
      dispatch(
        setAlert({
          show: true,
          type: ALERT_TYPE?.ERROR,
          msg: "Something went wrong",
        })
      );
    }
  };

  const handleOpenDelete = (row) => {
    setSelectedCoinItem(row);
    setIsDelete(true);
  };
  useEffect(() => {
    getCoinsHistory(0);
    getCrayonPlan();
  }, []);

  useEffect(() => {
    getSpendList();
  }, []);

  const handlePlanClose = () => {
    setEditPlan(false);
  };
  const handlePlanOpen = () => {
    setEditPlan(true);
  };

  return (
    <>
      <Box sx={{ display: "flex", gap: "50px" }}>
        <Box sx={{ display: "flex", gap: "50px" }}>
          <CoinsPrice />
          <Box
            sx={{
              minHeight: viewMoreExpanded ? "auto" : "101px",
              zIndex: "999",
            }}
          >
            <SpendItems
              spendList={spendList}
              handleOpenAddSpendModal={handleOpenAddSpendModal}
              setIsEdit={setIsEdit}
              handleOpenDelete={handleOpenDelete}
              viewMoreExpanded={viewMoreExpanded}
              setViewMoreExpanded={setViewMoreExpanded}
            />
          </Box>
        </Box>
        <Box>
          <CrayonsPlans
            spendList={planList}
            handleOpenAddSpendModal={handleOpenAddCrayonPlanModal}
            setIsEdit={setEditPlan}
            selectedCrayonCoinItem={selectedCrayonCoinItem}
            setSelectedCrayonCoinItem={setSelectedCrayonCoinItem}
            getCrayonPlan={getCrayonPlan}
          />
        </Box>
      </Box>

      <Box
        sx={{
          background: "white",
          borderRadius: "25px",
          mt: "21px",
          boxShadow: `0px 5px 10px ${theme?.palette?.boxShadow?.main}`,
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Box sx={{ display: "flex", gap: "26px", width: "675px" }}>
            <Button
              variant="contained"
              color="redButton"
              sx={{
                width: "150px",
                height: "45px",
                borderRadius: "25px 0 25px 0",
                padding: "0px",
              }}
            >
              coin history
            </Button>

            <SearchBox
              onChange={(event) => handleText(event)}
              width="500px"
              margin="5px 0 0 0"
              placeholder="search for a transaction or company"
            />
          </Box>
          <Button
            variant="contained"
            color="yellowButton400"
            sx={{
              width: "150px",
              height: "45px",
              borderRadius: "0 25px 0 25px",
            }}
            onClick={() => setAddCoinSpend(true)}
          >
            add coin spend
          </Button>
        </Box>
        <Box>
          <InfiniteScroll
            style={{ overflow: "hidden" }}
            dataLength={coinsHistory?.length}
            next={() => getCoinsHistory(lastKey)}
            scrollThreshold={"100px"}
            scrollableTarget={"jobList"}
            hasMore={true}
            endMessage={
              <p style={{ textAlign: "center" }}>
                <b>Yay! You have seen it all</b>
              </p>
            }
          >
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCells>
                      <Typography variant="subtitle1" fontWeight="bold">
                        Spend ID
                      </Typography>
                    </TableCells>
                    <TableCells></TableCells>
                    <TableCells>
                      <Typography variant="subtitle1" fontWeight="bold">
                        Company
                      </Typography>
                    </TableCells>

                    <TableCells>
                      <Typography
                        variant="subtitle1"
                        fontWeight="bold"
                        textAlign={"center"}
                      >
                        User
                      </Typography>
                    </TableCells>
                    <TableCells>
                      <Typography
                        variant="subtitle1"
                        fontWeight="bold"
                        textAlign={"center"}
                      >
                        Item
                      </Typography>
                    </TableCells>
                    <TableCells>
                      <Typography
                        variant="subtitle1"
                        fontWeight="bold"
                        textAlign={"center"}
                      >
                        ID
                      </Typography>
                    </TableCells>
                    <TableCells>
                      <Typography
                        variant="subtitle1"
                        fontWeight="bold"
                        textAlign={"center"}
                      >
                        Coins
                      </Typography>
                    </TableCells>
                    <TableCells>
                      <Typography
                        variant="subtitle1"
                        fontWeight="bold"
                        textAlign={"center"}
                      >
                        Date
                      </Typography>
                    </TableCells>
                    <TableCells>
                      <Typography
                        variant="subtitle1"
                        fontWeight="bold"
                        textAlign={"center"}
                      >
                        Coin Expiry Date
                      </Typography>
                    </TableCells>
                    <TableCells>
                      <Typography
                        variant="subtitle1"
                        fontWeight="bold"
                        textAlign={"center"}
                      >
                        Spend Coins
                      </Typography>
                    </TableCells>
                    {/* <TableCells>
                      <Typography variant="subtitle1" fontWeight="bold" textAlign={"center"}>
                        Status
                      </Typography>
                      </TableCells>*/}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {coinsHistory?.map((row) => (
                    <TableRow
                      key={row?.transaction_id}
                      style={{
                        "& .css-12zgwp-MuiTableCell-root": {
                          padding: "0 16px !important",
                        },
                      }}
                    >
                      <TableCells sx={{ fontWeight: theme.typography.Bold }}>
                        {row?.transaction_id}
                      </TableCells>
                      <TableCells>
                        {
                          <Box
                            component={"img"}
                            src={
                              row?.company?.profile_url === "No URL"
                                ? logo
                                : row?.company?.profile_url
                            }
                            sx={{ height: 40, width: 40, borderRadius: "10px" }}
                          />
                        }
                      </TableCells>
                      <TableCells
                        sx={{
                          fontWeight: theme.typography.Bold,
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setCompanyInfo(row);
                          setCompanyCoins(true);
                        }}
                      >
                        {row?.company?.name}
                      </TableCells>
                      <TableCells sx={{ textAlign: "center" }}>
                        {row?.user?.first_name} {row?.user?.last_name}
                      </TableCells>
                      <TableCells sx={{ textAlign: "center" }}>
                        {row?.transaction_name?.name}
                      </TableCells>
                      <TableCells sx={{ textAlign: "center" }}>
                        {row?.id}
                      </TableCells>
                      <TableCells sx={{ textAlign: "center" }}>
                        {row?.coins}
                      </TableCells>
                      <TableCells sx={{ textAlign: "center" }}>
                        {row?.created_at === "null"
                          ? "-"
                          : dateConverterMonth(row?.created_at)}
                      </TableCells>
                      <TableCells sx={{ textAlign: "center" }}>
                        {row?.expiry_coin_date === null
                          ? "-"
                          : dateConverterMonth(row?.expiry_coin_date)}
                      </TableCells>
                      <TableCells sx={{ textAlign: "center" }}>
                        {row?.spend_coins === null ? "0" : row?.spend_coins}
                      </TableCells>

                      {/* <TableCells sx={{ textAlign: "center",}}>
                        {
                          <ToggleCheckedSwitch
                            checkedColor={
                              row?.status === "Paid"
                                ? "recentButton"
                                : "talentButton"
                            }
                            notCheckedColor={"employerButton"}
                            checked={row?.paid}
                            onChange={(event, id, name) =>
                              // handleSelectChangePaid(event, row, id, name)
                              {
                                if (!row?.paid) {
                                  setConfirm(true);
                                  setInfo({ row, id, switch: "paid" });
                                }
                              }
                            }
                          />
                        }
                      </TableCells>*/}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </InfiniteScroll>
        </Box>
      </Box>

      {addCoinSpend && (
        <AddCoinSpend
          addCoinSpend={addCoinSpend}
          handleClose={() => setAddCoinSpend(false)}
          getCoinsHistory={getCoinsHistory}
        />
      )}
      {companyCoins && (
        <CompanyCoins
          companyCoins={companyCoins}
          handleClose={() => setCompanyCoins(false)}
          companyInfo={companyInfo}
        />
      )}
      {isAddSpendCoin && (
        <AddSpendCoinModal
          handleOpen={handleOpenAddSpendModal}
          handleClose={handleCloseAddSpendModal}
          handleFormInput={handleFormInput}
          errors={errors}
          formData={formData}
          setFormData={setFormData}
          handleCreateSelectOnChange={handleCreateSelectOnChange}
          // handleCreate={handleCreate}
          // handleCancel={handleCancel}
          edit={isEdit}
          // setErrors={setErrors}
          isAddSpendCoin={isAddSpendCoin}
          setIsAddSpendCoin={setIsAddSpendCoin}
          setIsEdit={setIsEdit}
          setSelectedCoinItem={setSelectedCoinItem}
          getSpendList={getSpendList}
          selectedCoinItem={selectedCoinItem}
        />
      )}
      {editPlan && (
        <EditCrayonPlan
          isEditPlan={editPlan}
          setIsEditPlan={setEditPlan}
          handleOpen={handlePlanOpen}
          handleClose={handlePlanClose}
          getCrayonPlan={getCrayonPlan}
          selectedCrayonCoinItem={selectedCrayonCoinItem}
          setSelectedCrayonCoinItem={setSelectedCrayonCoinItem}
        />
      )}
      {isDelete && (
        <Delete
          show={isDelete}
          handleOpen={() => setIsDelete(false)}
          handleDelete={handleDel}
          dialogText={"admin"}
          handleCancel={() => setIsDelete(false)}
          confirmDelete={confirmDelete}
          setConfirmDelete={setConfirmDelete}
        />
      )}
      <ConfirmationPopUp
        handleOpen={confirm}
        handleClose={() => {
          setConfirm(false);
        }}
        info={info}
        handleYes={() => handleSelectChangePaid()}
      />
    </>
  );
}
