import React, { useState } from "react";
import upArrow from "../../../assets/Black_Up_Close - Copy.svg";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import SmallButton from "../../common/SmallButton";
import { Link } from "react-router-dom";
import { Typography } from "@mui/material";
import { useTheme } from "@emotion/react";
import redTriangleSmile from "../../../assets/Characters/CrayBotIcons/Crayon Craybot Notification-07.svg";
import editIcon from "../../../assets/Black_Edit copy.svg";
import inProcess from "../../../assets/CircularIcon/Yellow/Circular Icons__Yellow_In_Process.svg";
import { CheckCircle, Circle } from "@mui/icons-material";
import TrackButtonLayout from "../../common/TrackButtonLayout";
import SVGButton from "../../common/SVGButton";
import EditQandA from "../myJobs/EditQandA";
import { renderColor } from "../../../utils/Common";
import { truncate } from "lodash";

const TrackButton = ({ job, closeFunc, cardWidth, handleOpenRecordDialog }) => {
  const theme = useTheme();

  const [openQandADialog, setopenQandADialog] = useState(false);

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "column",
        flexGrow: 1,
      }}
    >
      <Box sx={{}}>
        <Box mb={2} sx={{ display: "flex", justifyContent: "space-between" }}>
          <Box paddingTop={"15px"} paddingLeft={"16px"}>
            <Box
              style={{ marginRight: "10px", fontWeight: theme.typography.Bold }}
            >
              Application Stage:
            </Box>
            {/*<SmallButton
              height={"25px"}
              backgroundColor={
                theme.manageTalent?.[
                  renderColor(job?.job_users?.[0]?.job_user_status?.name)
                ]?.main
              }
              label={job?.job_users?.[0]?.job_user_status?.name}
              cursor={"default"}
            />*/}
          </Box>
          <Box
            sx={{
              alignItems: "start",
              display: "flex",
              // width: { xs: "80px", lg: "90px", xl: "120px" },
              // width: "124px",
              flexDirection: "row",
              // border: `1px solid ${theme.palette.chart.gray}`,
              borderTop: 0,
              borderRight: 0,
              borderRadius: "0 25px 0px 10px",
              height: "70px",
              position: "relative",
            }}
          >
            <Button
              sx={{
                height: "auto",
                minWidth: "60px",
                minHeight: "45px",
                borderRadius: "0 0 0 10px",
                padding: 0,
                fontSize: 12,
                fontWeight: theme.typography.Bold,
                background:
                  theme.manageTalent[
                    renderColor(job?.job_users?.[0]?.job_user_status?.name)
                  ]?.main,
                cursor: "default",
                color: "white",
                ":hover": {
                  background:
                    theme.manageTalent[
                      renderColor(job?.job_users?.[0]?.job_user_status?.name)
                    ]?.main,
                },
              }}
            >
              {job?.job_users?.[0]?.job_user_status?.name
                ? job?.job_users?.[0]?.job_user_status?.name
                : "-"}
            </Button>
            <Button
              variant="contained"
              sx={{
                boxShadow: "none !important",
                height: "45px !important",
                borderRadius: "0 25px 0 0",
                // width: "100%",
                display: "flex",
                flexDirection: "column",
                width: "60px",
              }}
              onClick={() => {
                closeFunc((prevState) => !prevState);
              }}
              color="greyPlayButton"
            >
              <TrackButtonLayout
                job={job}
                colorPattern={[
                  job?.profileCompleted,
                  job?.cvCompleted,
                  true,
                  job?.JobCamVideo,
                ]}
              />
              <Box
                component={"img"}
                src={upArrow}
                sx={{
                  height: 25,
                  width: 25,
                }}
              />
            </Button>
            <Box
              sx={{
                position: "absolute",
                right: "0px",
                top: "43px",
                bottom: "0px",
                display: "flex",
                width: "120px",
                justifyContent: "center",
              }}
            >
              <Typography
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 1,
                  fontWeight: theme.typography.Bold,
                  fontSize: "14px",
                }}
              >
                {job?.job_status?.name || "Status"}{" "}
                <Circle
                  fontSize="string"
                  color={
                    job?.job_status?.name === "paused"
                      ? "pausedStatus"
                      : job?.job_status?.name === "closed"
                      ? "closedStatus"
                      : job?.job_status?.name === "rejected"
                      ? "rejectedStatus"
                      : job?.job_status?.name === "pending"
                      ? "pendingStatus"
                      : job?.job_status?.name === "active"
                      ? "activeStatus"
                      : ""
                  }
                />
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            // gap: 2,
            padding: "0px 15px 0px 15px",
          }}
        >
          <Typography
            sx={{
              fontSize: "12px",
              color: "black",
              width: `${cardWidth - 30}px`,
              minHeight: "30px",
              fontWeight: theme.typography.Medium,
            }}
          >
            A valid appliction requires, at a minimum, that your Profile and
            'the basics' section of your Crayon Vitae be completed.
          </Typography>
          <Box
            sx={{
              display: "flex",
              gap: 2,
              paddingLeft: "5px",
              alignItems: "center",
              margin: "10px 0 28px 0",
            }}
          >
            <Box
              component={"img"}
              src={redTriangleSmile}
              sx={{ width: 50, height: 50 }}
            />
            <Typography
              sx={{
                fontSize: "12px",
                color: "black",
                width: "198px",
                height: "30px",
                fontWeight: theme.typography.Medium,
              }}
            >
              Remember, the more you complete, the stronger you can compete!
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "11px",
              px: "15px",
            }}
          >
            <Link
              to={"/candidate/my-profile"}
              style={{
                textDecoration: "none",
                color: theme.palette.black,
              }}
            >
              <Button
                variant="contained"
                sx={{
                  borderRadius: "10px",
                  backgroundColor: theme.palette.chart.gray,
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                  padding: 1,
                  boxShadow: "none !important",
                  color: "black",
                  ":hover": {
                    backgroundColor: theme.palette.chart.gray,
                  },
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: 1,
                  }}
                >
                  {job?.profileCompleted ? (
                    <CheckCircle
                      sx={{
                        fontSize: 35,
                      }}
                      color="lightGreenButton300"
                    />
                  ) : (
                    <SVGButton
                      color={"white"}
                      source={inProcess}
                      height={30}
                      width={30}
                      startIconMargin={"0px !important"}
                      padding={"0px !important"}
                    />
                  )}{" "}
                  <Typography
                    sx={{
                      fontSize: "0.875rem",
                      fontWeight: 600,
                    }}
                  >
                    my Info
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "0.875rem",
                    }}
                  >
                    edit
                  </Typography>
                  <Box
                    component={"img"}
                    src={editIcon}
                    sx={{ width: 20, height: 20, paddingLeft: "6px" }}
                  />
                </Box>
              </Button>
            </Link>

            <Link
              to={"/candidate/my-cv"}
              style={{
                textDecoration: "none",
                color: theme.palette.black,
              }}
            >
              <Button
                variant="contained"
                sx={{
                  borderRadius: "10px",
                  backgroundColor: theme.palette.chart.gray,
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                  padding: 1,
                  boxShadow: "none !important",
                  color: "black",
                  ":hover": {
                    backgroundColor: theme.palette.chart.gray,
                  },
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: 1,
                  }}
                >
                  {job?.cvCompleted ? (
                    <CheckCircle
                      sx={{
                        fontSize: 35,
                      }}
                      color="lightGreenButton300"
                    />
                  ) : (
                    <SVGButton
                      color={"white"}
                      source={inProcess}
                      height={30}
                      width={30}
                      startIconMargin={"0px !important"}
                      padding={"0px !important"}
                    />
                  )}{" "}
                  <Typography
                    sx={{
                      fontSize: "0.875rem",
                      fontWeight: 600,
                    }}
                  >
                    Crayon vitae
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "0.875rem",
                    }}
                  >
                    edit
                  </Typography>
                  <Box
                    component={"img"}
                    src={editIcon}
                    sx={{ width: 20, height: 20, paddingLeft: "6px" }}
                  />
                </Box>
              </Button>
            </Link>
            <Button
              variant="contained"
              sx={{
                borderRadius: "10px",
                backgroundColor: theme.palette.chart.gray,
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
                padding: 1,
                boxShadow: "none !important",
                color: "black",
                ":hover": {
                  backgroundColor: theme.palette.chart.gray,
                },
              }}
              onClick={() => setopenQandADialog(true)}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 1,
                }}
              >
                <CheckCircle
                  sx={{
                    fontSize: 35,
                  }}
                  color="lightGreenButton300"
                />
                <Typography
                  sx={{
                    fontSize: "0.875rem",
                    fontWeight: 600,
                  }}
                >
                  Q&A
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "0.875rem",
                  }}
                >
                  edit
                </Typography>
                <Box
                  component={"img"}
                  src={editIcon}
                  sx={{ width: 20, height: 20, paddingLeft: "6px" }}
                />
              </Box>
            </Button>

            <Button
              variant="contained"
              sx={{
                borderRadius: "10px",
                backgroundColor: theme.palette.chart.gray,
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
                padding: 1,
                boxShadow: "none !important",
                color: "black",
                ":hover": {
                  backgroundColor: theme.palette.chart.gray,
                },
              }}
              onClick={handleOpenRecordDialog}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 1,
                }}
              >
                {job?.JobCamVideo ? (
                  <CheckCircle
                    sx={{
                      fontSize: 35,
                    }}
                    color="lightGreenButton300"
                  />
                ) : (
                  <SVGButton
                    color={"white"}
                    source={inProcess}
                    height={30}
                    width={30}
                    startIconMargin={"0px !important"}
                    padding={"0px !important"}
                  />
                )}{" "}
                <Typography
                  sx={{
                    fontSize: "0.875rem",
                    fontWeight: 600,
                  }}
                >
                  Video application
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "0.875rem",
                  }}
                >
                  edit
                </Typography>
                <Box
                  component={"img"}
                  src={editIcon}
                  sx={{ width: 20, height: 20, paddingLeft: "6px" }}
                />
              </Box>
            </Button>
          </Box>
        </Box>
      </Box>
      <Button
        variant="contained"
        color="redButton100"
        sx={{
          width: "100%",
          height: "60px",
          paddingY: "25px",
          borderRadius: "0 0 25px 25px",
        }}
        onClick={() => closeFunc((prevState) => !prevState)}
      >
        close
      </Button>
      {openQandADialog && (
        <EditQandA
          jobId={job?.job_id}
          openQandADialog={openQandADialog}
          setopenQandADialog={setopenQandADialog}
        />
      )}
    </Box>
  );
};

export default TrackButton;
