import {
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  InputBase,
  Paper,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { useTheme } from "@emotion/react";
import close from "../../../../assets/Padding Included/Black_Close.svg";
import logo from "../../../../assets/Crayon_Favicon.svg";
import matchMeIcon from "../../../../assets/Padding Excluded/Black_Match_me.svg";
import applyIcon from "../../../../assets/Padding Excluded/Black_Follower.svg";
import { useDispatch } from "react-redux";
import { setAlert } from "../../../../redux/configSlice";
import { ALERT_TYPE, ERROR_MSG } from "../../../../utils/Constants";
import {
  candidateShortlist,
  getTalentMatch,
} from "../../../../redux/guest/talentSlice";
import { CheckCircle } from "@mui/icons-material";
import InfiniteScroll from "react-infinite-scroll-component";
import locale from "../../../../i18n/locale";
import { debounce, getDecodedToken, getToken } from "../../../../utils/Common";
import { LightTooltip } from "../../../../utils/CommonComponent";
import { getAdminTalentJobList } from "../../../../redux/admin/jobsSlice";

export default function ShortlistMatchTalent({
  job,
  closeFunc,
  // setSelectedJob,
  // selectedJob,
  setMatchButton,
  setRunMatch,
  setShortlist,
  selectedJobID,
  setSelectedJobID,
  handleDropDataApi,
  talentDetail
}) {
  const theme = useTheme();
  const i18n = locale.en;
  const dispatch = useDispatch();
  const [selectedJob, setSelectedJob] = useState([]);
  //   const [match, setMatch] = useState();
  //   const [selected, setSelected] = useState([]);

  const [pageNumber, setpageNumber] = useState(0);

  const [hasMoreData, setHasMoreData] = useState(true);
  const [show, setShow] = useState(false);
  const decodedToken = getDecodedToken(getToken());
  const [isLoading, setIsLoading] = useState(false);
  const [searchText, setSearchText] = useState("");

  const getMatch = async (lastkeyy, query) => {
    try {
      const { payload } = await dispatch(
        getAdminTalentJobList({
          lastKey: pageNumber,
          query: query,
        })
      );
      if (payload?.pageNumber === 0 && payload?.data?.length === 0) {
        setShow(true);
        setHasMoreData(false);
        setSelectedJob([]);
        return;
      }
      if (payload?.status === "success") {
        setpageNumber(payload?.pageNumber + 1);
        setSelectedJob((prevState) => [
          ...prevState,
          ...payload?.data.map((item) => ({
            ...item,
            job_logo_url: item?.profile_url,
          })),
        ]);
        payload?.data?.length === 0 && setHasMoreData(false);
        setShow(false);
      }
    } catch (error) {
      dispatch(
        setAlert({
          show: true,
          type: ALERT_TYPE?.ERROR,
          msg: error,
        })
      );
    }
  };

  const shortlistCandidate = async () => {
    try {
      const data = {
        job_id: selectedJobID,
        candidate_id: job?.user_id,
      };
      const { payload } = await dispatch(candidateShortlist(data));
      if (payload?.status === "success") {
        if (payload?.message === "Already Shortlisted") {
          dispatch(
            setAlert({
              show: true,
              type: ALERT_TYPE?.ERROR,
              msg: "Candidate is already Shortlisted",
            })
          );
        } else {
          await handleDropDataApi(job?.user_id, true);
          dispatch(
            setAlert({
              show: true,
              type: ALERT_TYPE?.SUCCESS,
              msg: "Candidate is shortlisted",
            })
          );
        }
        closeFunc();
      }
    } catch (error) {
      dispatch(
        setAlert({
          show: true,
          type: ALERT_TYPE?.ERROR,
          msg: error,
        })
      );
    }
  };

  const debouncedHandleOnTextSearch = useCallback(
    debounce(
      async (newValue) => {
        if (!newValue) return; // Early return
        try {
          setpageNumber(0);
          const response = await getMatch(0, newValue?.target?.value);

          // if (response && response?.payload) {
          //   const { payload } = response;
          //   dispatch(
          //     payload?.status === "success"
          //       ? setMatch((prevState) => [
          //           ...prevState,
          //           ...payload?.data.map((item) => ({
          //             ...item,
          //             job_logo_url: item?.profile_url,
          //           })),
          //         ])
          //       : setAlert({
          //           show: true,
          //           type: ALERT_TYPE?.ERROR,
          //           msg: ERROR_MSG,
          //         })
          //   );
          // }
        } catch (error) {
          dispatch(
            setAlert({ show: true, type: ALERT_TYPE?.ERROR, msg: ERROR_MSG })
          );
        }
      },
      500, // Adjust delay as needed
      setIsLoading
    ),
    [dispatch, setSelectedJob, setIsLoading]
  );

  const handleSearch = async (event) => {
    setSearchText(event?.target?.value);
    setSelectedJob([]);
    debouncedHandleOnTextSearch(event);
  };

  useEffect(() => {
    getMatch();
  }, []);
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: { sm: "616px", xl: "626px" },
        width: "100%",
        justifyContent: "space-between",
      }}
    >
       <Box sx={{ }}>
        <Button
          variant="contained"
          color="likeThisButton"
          sx={{ borderRadius: "25px 0 25px 0", 
          fontSize: "14px", 
          gap: "5px",
          minWidth:"150px" }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "8px",
            }}
          >
            <Box
              component={"img"}
              src={matchMeIcon}
              sx={{
                height: "20px",
                width: "26px",
              }}
            />
          </Box>
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: theme.typography.Bold,
            }}
          >
            shortlist to a job
          </Typography>
        </Button>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          margin: talentDetail?"0px 17px 0 17px":"17px 17px 0 17px",
        }}
      >
         {talentDetail?"":<>
         <Box
          sx={{
            marginBottom: "0px",
            display: "flex",
            gap: "10px",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: theme.typography.Bold,
            }}
          >
            {i18n["matchMeBox.shortlistToJob"]}
          </Typography>
          <Tooltip disableInteractive>
            <LightTooltip
              title={
                "This percentage match does not necessarily mean you will or will not be shortlisted for a job, but it helps provide a quick indicator of your suitability. "
              }
              placement="right-end"
            >
              <Typography
                sx={{
                  padding: "5px",
                  height: "8px",
                  width: "8px",
                  borderRadius: "5px",
                  fontSize: "15px",
                  lineHeight: 0.1,
                  /* text-align: center; */
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  fontWeight: 700,
                  border: 1,
                }}
              >
                i
              </Typography>
            </LightTooltip>
          </Tooltip>
        </Box>
        <IconButton
          aria-label="close"
          onClick={() => {
            closeFunc();
            setSelectedJob([]);
          }}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "black",
            ":hover": {
              backgroundColor: "transparent",
            },
          }}
        >
          <Box
            component={"img"}
            src={close}
            sx={{
              border: 2,
              borderRadius: "5px",
              height: 20,
              width: 20,
            }}
          />
        </IconButton>
         </>}
        <Box sx={{ width: "325px", height: "15px", margin: "15px 0" }}>
          <Typography
            sx={{
              fontSize: "12px",
              fontWeight: theme.typography.Medium,
              lineHeight: "15px",
            }}
          >
            {i18n["matchMeBox.selectJob"]}
          </Typography>
        </Box>
        <Paper
          elevation={0}
          component="form"
          sx={{
            padding: "0px 10px",
            border: "1px solid #C9CAD8",
            mb: 1,
          }}
          height={"40px"}
          width={"308px"}
        >
          <InputBase
            sx={{
              fontWeight: theme.typography.Regular,
              width: "308px",
            }}
            placeholder={"Search for a job"}
            // value={searchText}
            onChange={(event) => {
              handleSearch(event);
            }}
          />
        </Paper>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            overflowY: "auto",
            gap: "10px",
            paddingRight: "10px",
            marginBottom: "10px",
            "::-webkit-scrollbar": {
              height: "5px",
              background: "#DCDEEB",
              display: "unset !important",
            },
          }}
          height={{ sm: `calc(616px - 193px)`, xl: `calc(626px - 193px)` }}
          className="scroll-container"
          id="shortListTaleneList"
        >
          <InfiniteScroll
            style={{
              overflowX: "hidden",
              scrollbarWidth: "thin",
              display: "flex",
              flexDirection: "column",
              gap: "10px",
            }}
            dataLength={selectedJob?.length || 0}
            next={() => getMatch(pageNumber, searchText)}
            hasMore={hasMoreData}
            loader={
              <Box
                style={{
                  margin: "15px 0 0 0",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CircularProgress color="inherit" size={20} />
              </Box>
            }
            scrollableTarget="shortListTaleneList"
            scrollThreshold={"50px"}
          >
            {selectedJob?.length > 0
              ? selectedJob?.map((item) => (
                  <Box
                    key={item?.company_id}
                    sx={{ display: "flex", alignItems: "center", gap: "9px" }}
                  >
                    <Box
                      component={"img"}
                      src={
                        item?.job_logo_url !== "No URL"
                          ? item?.job_logo_url
                          : logo
                      }
                      sx={{
                        height: "50px",
                        width: "50px",
                        borderRadius: "10px",
                      }}
                    />
                    <Tooltip
                      disableInteractive
                      title={item?.title}
                      placement="top-start"
                    >
                      <Typography
                        sx={{
                          fontSize: "15px",
                          fontWeight: theme.typography.Bold,
                          wordBreak: "break-word",
                          cursor: "pointer",
                          width: "200px",
                        }}
                        noWrap={true}
                        onClick={() => {
                          setSelectedJob((prev) =>
                            prev?.map((items) =>
                              items?.job_id === item?.job_id
                                ? {
                                    ...items,
                                    selected: items?.selected ? false : true,
                                  }
                                : { ...items, selected: false }
                            )
                          );
                          setSelectedJobID(item?.job_id);
                        }}
                      >
                        {item?.title}
                      </Typography>
                    </Tooltip>
                    {item?.job_id === selectedJobID && (
                      <CheckCircle
                        key={item?.job_id}
                        sx={{
                          fontSize: 27,
                          cursor: "pointer",
                        }}
                        color="checkColor"
                      />
                    )}
                  </Box>
                ))
              : show && (
                  <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <Typography>No Jobs</Typography>
                  </Box>
                )}
            <style>
              {`.infinite-scroll-component__outerdiv{
              height:auto
            }`}
            </style>
          </InfiniteScroll>
        </Box>
      </Box>
      <Grid
        container
        // padding="0 8px 8px 8px"
        alignItems="center"
        overflow={"hidden"}
        sx={{
          width: "100%",
          borderRadius: "0 0 10px 10px",
          height: "60px",
        }}
      >
        <Button
          variant="contained"
          sx={{
            borderRadius: 0,
            fontSize: "14px",
            fontWeight: theme.typography.Bold,
            whiteSpace: "nowrap",
            height: "100%",
            width: "33.33%",
            justifyContent: "center",
            ".MuiButton-startIcon": {
              marginRight: "0px !important",
              marginLeft: 0,
            },
            padding: "0px !important",
            gap: 1,
            boxShadow: "none !important",
          }}
          color="likeThisButton"
          startIcon={
            <Box
              component={"img"}
              sx={{
                height: "20px",
                width: "26px",
              }}
              src={matchMeIcon}
            />
          }
          onClick={() => {
            setShortlist(false);
            setRunMatch(false);
            setMatchButton(true);
          }}
        >
          {i18n["matchMeBox.match"]}
        </Button>
        <Button
          disabled={selectedJobID?.length === 0}
          variant="contained"
          sx={{
            borderRadius: "0 0 10px 0",
            width: "66.67%",
            height: "100%",
            fontSize: "14px",
            fontWeight: theme.typography.Bold,
            ":hover": {
              boxShadow: 0,
            },
            ".MuiButton-startIcon": {
              marginRight: "0px !important",
              marginLeft: 0,
            },
            padding: "0px !important",
            boxShadow: "none !important",
            gap: 1,
          }}
          color="applyButton"
          onClick={() => shortlistCandidate()}
          startIcon={
            <Box
              component={"img"}
              sx={{
                height: "20px",
                width: "24px",
              }}
              src={applyIcon}
            />
          }
        >
          {i18n["matchMeBox.shortlist"]}
        </Button>
      </Grid>
    </Box>
  );
}
