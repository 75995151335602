import { useTheme } from "@mui/material/styles";

import { Paper, InputBase, InputLabel } from "@mui/material";
import { Modal } from "@mui/material";
import React, { useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

const EditAssessmentQuestionModal = ({
  handleOpen,
  handleClose,
  handleSubmit,
  selectedQuestion,
  questFormInputValue,
  handleQuestionInput,
  error,
  setError,
  formData,
  handleFormInput,
}) => {
  const theme = useTheme();

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    bgcolor: "background.paper",
    borderRadius: "25px",
    // p: 4,
    boxShadow: "none !important",
    // border: "1px solid black !important",
    "&:focus-visible": {
      outline: "none",
    },
  };

  return (
    <Modal
      open={handleOpen}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
      <Box
          sx={{
            display: "flex",
            justifyContent: "end",
          }}
        >
          <Button
            variant="contained"
            color="yellowButton400"
            sx={{
              height: "45px",
              width: "200px",
              borderRadius: "0 25px 0 25px",
            }}
          >
            edit question
          </Button>
        </Box>
        <Box sx={{ mt: 3, mb: 4,  padding: 0, px:"20px" }}>
          <Paper
                elevation={3}
                sx={{
                  display: "flex",
                  borderRadius: "10px",
                  height: "40px",
                  boxShadow: "none",
                  border: error
                    ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
                    : `1px solid ${theme.palette.grayBorder}`,
                }}
              >
                <InputBase
                  // disabled={true}
                  type="type"
                  sx={{ ml: 2, mr: 2, width: "100%" }}
                  id="type"
                  placeholder={"Enter your Question"}
                  value={formData}
                  onChange={(e) => handleFormInput(e)}
                />
              </Paper>
          </Box>
        <Box
        sx={{ display: "flex", justifyContent: "flex-end" }}
        >
          <Button
            variant="contained"
            color="grayButton100"
            sx={{
              borderRadius: 0,
              width: "50%",
              height: "47px",
              borderBottomLeftRadius: 25,
            }}
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="yellowButton100"
            sx={{
              borderRadius: 0,
              width: "50%",
              height: "47px",
              borderBottomRightRadius: 25,
            }}
            onClick={handleSubmit}
          >
            edit
          </Button>
        </Box>
      
      </Box>
    </Modal>
  );
};

export default EditAssessmentQuestionModal;
