import {
  InputBase,
  Paper,
  Box,
  Modal,
  useTheme,
  Typography,
  Button,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  getCompanyCulture,
  getIndustries,
} from "../../../../redux/candidate/myCvSlice";
import { debounce, getDecodedToken, getToken } from "../../../../utils/Common";
import { setAlert, setLoading } from "../../../../redux/configSlice";
import {
  ALERT_TYPE,
  EDIT_COMPANY,
  ERROR_MSG,
} from "../../../../utils/Constants";
import { useDispatch } from "react-redux";
import CustomSelect from "../../../candidate/myCV/CustomSelect";
import SelectMenu from "../../../common/SelectMenu";
import locale from "../../../../i18n/locale";
import {
  getCompanySize,
  getCompanyType,
} from "../../../../redux/employer/empProfileSlice";
import InputBox from "../../../common/myProfileSection/InputBox";
import {
  getUserCompanyList,
  pinACompany,
  updateCompanyData,
} from "../../../../redux/admin/jobsSlice";
import { useParams } from "react-router-dom";
import TalentSVGButton from "../../../common/TalentSVGButton";
import pinIcon from "../../../../assets/Icons/pinIcon.svg";
import PinConfirmation from "../../adminJobs/editJobs/PinConfirmation";

export default function EditCompany({
  show,
  handleClose,
  editCompany,
  getCompanyLists,
  pinCompany,
  setAllCompanyData,
  allCompanyData,
}) {
  const i18n = locale.en;
  const theme = useTheme();
  const dispatch = useDispatch();
  const decodedToken = getDecodedToken(getToken());
  const { id } = useParams();
  const { companyType, companySize } = useSelector((state) => state.myProfile);
  const { initialIndustries, companyCultures, initialCompanyCulture } =
    useSelector((state) => state.myCv);
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 800,
    bgcolor: "background.paper",
    borderRadius: "10px",
    boxShadow: "none !important",
  };

  const [confirmPin, setConfirmPin] = useState(false);
  const [companyData, setCompanyData] = useState(EDIT_COMPANY);
  const [isLoading, setIsLoading] = useState(false);
  const [openPin, setOpenPin] = useState(false);

  const getAllData = async () => {
    try {
      dispatch(setLoading(true));
      await Promise.all([
        dispatch(getCompanyType()),
        dispatch(getCompanySize()),
        dispatch(getIndustries()),
        dispatch(getCompanyCulture()),
      ]);
      dispatch(setLoading(false));
    } catch (error) {
      dispatch(setLoading(false));
      dispatch(
        setAlert({
          show: true,
          type: ALERT_TYPE.ERROR,
          msg: ERROR_MSG,
        })
      );
    }
  };
  const getCompanyList = async () => {
    try {
      const { payload } = await dispatch(
        getUserCompanyList({ lastKey: 0, company_id: editCompany })
      );

      if (payload.status === "success") {
        setCompanyData({
          employer_id: payload?.data?.[0]?.user_id,
          company_id: payload?.data?.[0]?.company_id,
          company_name: payload?.data?.[0]?.name,
          hyperlink: payload?.data?.[0]?.website,
          notes: payload?.data?.[0]?.description,
          industry_ids: payload?.data?.[0]?.Industries?.map((item) => ({
            value: item.industry_id,
            label: item.industry_name,
          })),
          company_type: payload?.data?.[0]?.company_type,
          company_size: payload?.data?.[0]?.company_size,
          enabled: payload?.data?.enabled === "Active" ? 1 : 0,
          culture_ids: payload?.data?.[0]?.Company_Culture?.map((item) => ({
            value: item?.employer_culture_id,
            label: item?.employer_culture_name,
          })),
        });
      } else {
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE?.ERROR,
            msg: payload?.message,
          })
        );
      }
    } catch (error) {
      dispatch(
        setAlert({
          show: true,
          type: ALERT_TYPE?.ERROR,
          msg: "ERROR",
        })
      );
    }
  };
  const editCompanyData = async () => {
    try {
      let data = {
        ...companyData,
        industry_ids: companyData?.industry_ids?.map((item) => {
          return item?.value;
        }),
        culture_ids: companyData?.culture_ids?.map((item) => item?.value),
      };
      const { payload } = await dispatch(updateCompanyData(data));

      if (payload?.status === "success") {
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE?.SUCCESS,
            msg: "Company data updated successfully",
          })
        );
        handleClose();
        await getCompanyLists(0, id, "");
      } else {
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE?.ERROR,
            msg: payload?.message,
          })
        );
      }
    } catch (error) {
      dispatch(
        setAlert({
          show: true,
          type: ALERT_TYPE?.ERROR,
          msg: "ERROR",
        })
      );
    }
  };

  const debouncedHandleOnSelectInputChange = useCallback(
    debounce(
      async (newValue, type) => {
        let params = {
          title: newValue || "",
          limit: 5,
        };
        if (newValue) {
          try {
            if (type === "industry_ids") {
              dispatch(getIndustries(params));
            }
            if (type === "culture_ids") {
              dispatch(getCompanyCulture(params));
            }
          } catch (error) {
            dispatch(
              setAlert({
                show: true,
                type: ALERT_TYPE?.ERROR,
                msg: error,
              })
            );
          }
        }
      },
      750,
      setIsLoading
    ),
    []
  );

  //   const getIndValue = () => {
  //     if (companyData?.industry_ids?.length === 0) {
  //       return "";
  //     }
  //     let industries = companyData?.industry_ids?.map(
  //       (name) =>
  //         initialIndustries?.find(
  //           (industry) =>
  //             industry?.name === name || industry?.name === name?.label
  //         ) || name
  //     );
  //     let industry = {
  //       value: industries?.[0]?.id,
  //       label: industries?.[0]?.name,
  //     };
  //     return industry;
  //   };

  const getIndValue = () => {
    if (companyData?.industry_ids?.length === 0) {
      return "";
    }
    return companyData?.industry_ids?.map(
      (id) =>
        initialIndustries?.find((industry) => industry?.industry_id === id) ||
        id
    );
  };

  const handleOnSelectInputChange = (newValue, inputId) => {
    debouncedHandleOnSelectInputChange(newValue, inputId);
  };

  const handleOnSelectChange = async (ind, newValue, val, index) => {
    if (ind === "industry_ids") {
      let newCompanyData = {
        ...companyData,
      };
      newCompanyData[ind] = newValue;
      setCompanyData(newCompanyData);
    }
    if (ind === "culture_ids") {
      let newCompanyData = {
        ...companyData,
      };
      newCompanyData[ind] = newValue;
      setCompanyData(newCompanyData);
    }
  };
  const getCultureValue = () => {
    if (companyData?.culture_ids?.length === 0) {
      return "";
    }
    return companyData?.culture_ids?.map(
      (id) =>
        companyCultures?.find(
          (culture) => culture?.company_culture_id === id
        ) || id
    );
  };
  const handleCompanySize = (event) => {
    const {
      target: { value },
      target: { name },
    } = event;
    const newCompanyData = {
      ...companyData,
      [name]: companySize?.find((role) => role.id == value)?.name || "",
    };
    setCompanyData(newCompanyData);
  };

  const handleCompanyType = (event) => {
    const {
      target: { value },
      target: { name },
    } = event;

    const newCompanyData = {
      ...companyData,
      [name]: companyType?.find((role) => role.id == value)?.name || "",
    };

    setCompanyData(newCompanyData);
  };

  const handleInputChange = (event) => {
    const newCompanyData = {
      ...companyData,
      [event.target.id]: event.target.value,
    };
    setCompanyData(newCompanyData);
  };

  const handleChange = (event) => {
    let newCompanyData = {
      ...companyData,
      [event?.target?.id]: event.target.value,
    };
    setCompanyData(newCompanyData);
  };

  const handlePinJob = async () => {
    try {
      let data = {
        company_id: companyData?.company_id,
        value: !pinCompany,
      };
      const { payload } = await dispatch(pinACompany(data));
      if (payload?.status === "success") {
        setAllCompanyData((prevAllJobs) => {
          const updatedItem = prevAllJobs
            ?.filter((item) => item?.company_id === companyData?.company_id)
            ?.map((item) => ({ ...item, pin_company: !pinCompany }))?.[0];
          const remainingItems = prevAllJobs?.filter(
            (item) => item?.company_id !== companyData?.company_id
          );
          return [updatedItem, ...remainingItems];
        });
        setOpenPin(false);
        setConfirmPin(false);
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE?.SUCCESS,
            msg: "Company pinned successfully",
          })
        );
      } else {
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE?.ERROR,
            msg: "Error",
          })
        );
      }
    } catch (error) {
      dispatch(
        setAlert({
          show: true,
          type: ALERT_TYPE?.ERROR,
          msg: error,
        })
      );
    }
  };

  useEffect(() => {
    if (show) {
      getAllData();
      getCompanyList();
    }
  }, [show]);

  return (
    <>
      <Modal
        open={show}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box
            sx={{ p: 4, display: "flex", flexDirection: "column", gap: "16px" }}
          >
            <Box sx={{ display: "flex", width: "100%", gap: "10px" }}>
              {((decodedToken?.data?.role_id === 1 && !pinCompany) ||
                (decodedToken?.data?.role_id === 2 && !pinCompany)) && (
                <>
                  <Typography
                    sx={{
                      fontSize: "18px",
                      fontWeight: theme.typography.Bold,
                      textAlign: "center",
                    }}
                  >
                    Do you want to pin this company?
                  </Typography>

                  <TalentSVGButton
                    onClick={() => setOpenPin(true)}
                    color={"white"}
                    source={pinIcon}
                    outerHeight={25}
                    height={25}
                    width={25}
                    padding={"0px !important"}
                    startIconMargin={"0px !important"}
                    margin={"2px 0 0 0"}
                  />
                </>
              )}
            </Box>
            <Box
              sx={{
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
              }}
            >
              <Box
                sx={{
                  width: "45%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Typography sx={{ marginBottom: "4px" }}>
                  {i18n["empMyProfile.companyNameReq"]}
                </Typography>
                <Paper
                  sx={{
                    width: "100%",
                    display: "flex",
                    height: "40px",
                    borderRadius: "10px",
                    boxShadow: "none",
                    border: `1px solid ${theme.palette.grayBorder}`,
                  }}
                >
                  <InputBase
                    sx={{
                      ml: 2,
                      mr: 2,
                      width: "100%",
                    }}
                    id="company_name"
                    placeholder="Edit company name"
                    value={companyData?.company_name}
                    onChange={handleChange}
                  />
                </Paper>
              </Box>

              <Box
                sx={{
                  width: "45%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Typography sx={{ marginBottom: "4px" }}>
                  {i18n["empMyProfile.companyWebsiteReq"]}
                </Typography>
                <Paper
                  sx={{
                    width: "100%",
                    display: "flex",
                    height: "40px",
                    borderRadius: "10px",
                    boxShadow: "none",
                    border: `1px solid ${theme.palette.grayBorder}`,
                  }}
                >
                  <InputBase
                    sx={{
                      ml: 2,
                      mr: 2,
                      width: "100%",
                    }}
                    id="hyperlink"
                    placeholder="Edit company website link"
                    value={companyData?.hyperlink}
                    onChange={handleChange}
                  />
                </Paper>
              </Box>
            </Box>

            <Box
              sx={{
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
              }}
            >
              <Box
                sx={{
                  width: "45%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      width: "45%",
                    }}
                  >
                    <Typography sx={{ marginBottom: "4px" }}>
                      {i18n["empMyProfile.companySizeReq"]}
                    </Typography>
                    <SelectMenu
                      clear={true}
                      name="company_size"
                      value={companyData?.company_size}
                      onHandleChange={handleCompanySize}
                      options={companySize}
                      borderRadius="10px"
                      sx={{ width: "100%" }}
                      border={"1px solid #E0E0E0"}
                      placeholder={"Select the size of company"}
                      onFocus={() => {
                        if (companySize.length === 0) {
                          dispatch(getCompanySize());
                        }
                      }}
                    />
                  </Box>
                  <Box
                    sx={{
                      width: "45%",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <Typography sx={{ marginBottom: "4px" }}>
                      {i18n["empMyProfile.companyTypeReq"]}
                    </Typography>
                    <SelectMenu
                      clear={true}
                      name="company_type"
                      value={companyData?.company_type}
                      onHandleChange={handleCompanyType}
                      options={companyType}
                      borderRadius="10px"
                      sx={{ width: "100%" }}
                      border={"1px solid #E0E0E0"}
                      placeholder={"Select the size of company"}
                      onFocus={() => {
                        if (companyType.length === 0) {
                          dispatch(getCompanyType());
                        }
                      }}
                    />
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                  }}
                >
                  <Typography sx={{ marginBottom: "4px" }}>
                    {i18n["empMyProfile.companyIndustryReq"]}
                  </Typography>
                  <CustomSelect
                    inputId={"industry_ids"}
                    name={"industry_ids"}
                    variant={"multiSelect"}
                    closeMenuOnSelect={false}
                    isMulti={true}
                    multiSelectOptions={initialIndustries?.map((item) => ({
                      value: item?.industry_id,
                      label: item?.name,
                    }))}
                    placeholder={"Edit industries"}
                    onChange={(e, val) => handleOnSelectChange(e, val)}
                    onInputChange={handleOnSelectInputChange}
                    customStyle={{
                      option: (
                        styles,
                        { data, isDisabled, isFocused, isSelected }
                      ) => {
                        return {
                          ...styles,
                          color: isSelected ? "#fff" : "black",
                          ":active": {
                            ...styles[":active"],
                          },
                        };
                      },
                      control: (baseStyles) => ({
                        ...baseStyles,
                        boxShadow: "none",
                        borderRadius: "10px",
                        minHeight: `40px`,
                        border: "1px solid #E0E0E0",
                        "&:hover": "1px solid #E0E0E0",
                        width: `100%`,
                      }),
                    }}
                    multiOnFocus={() => {
                      if (initialIndustries.length === 0) {
                        dispatch(getIndustries());
                      }
                    }}
                    value={getIndValue()}
                    initialOptions={initialIndustries?.map((item) => ({
                      value: item?.industry_id,
                      label: item?.name,
                    }))}
                  />
                </Box>
              </Box>
              <Box sx={{ width: "45%" }}>
                <Box sx={{ width: "100%" }}>
                  <Typography sx={{ marginBottom: "4px" }}>
                    {i18n["empMyProfile.companyDescriptionReq"]}
                  </Typography>
                  <InputBox
                    multiline={true}
                    sx={{
                      height: "133px",
                      border: "1px solid #E0E0E0",
                    }}
                    placeholder={i18n["empMyProfile.companyDescriptionPlace"]}
                    value={companyData?.notes ? companyData?.notes : ""}
                    id="notes"
                    onChange={handleInputChange}
                  />
                </Box>
              </Box>
            </Box>
            <Box>
              <Box sx={{ width: "45%" }}>
                <Typography sx={{ marginBottom: "4px" }}>
                  {i18n["empMyProfile.companyCulture"]}
                </Typography>
                <CustomSelect
                  // isDisabled={!editable}
                  inputId={"culture_ids"}
                  name={"culture_ids"}
                  variant={"multiSelect"}
                  closeMenuOnSelect={true}
                  isMulti={true}
                  multiSelectOptions={companyCultures?.map((item) => ({
                    value: item?.company_culture_id,
                    label: item?.name,
                  }))}
                  placeholder={
                    "Select 5 adjectives that best describe your company culture"
                  }
                  onChange={(e, val) => handleOnSelectChange(e, val)}
                  onInputChange={handleOnSelectInputChange}
                  customStyle={{
                    option: (
                      styles,
                      { data, isDisabled, isFocused, isSelected }
                    ) => {
                      return {
                        ...styles,
                        color: isSelected ? "#fff" : "black",
                        ":active": {
                          ...styles[":active"],
                        },
                      };
                    },
                    control: (baseStyles) => ({
                      ...baseStyles,
                      backgroundColor: "transparent",
                      boxShadow: "none",
                      borderRadius: "10px",
                      minHeight: `40px`,
                      border: "1px solid #E0E0E0",
                      // border:
                      //   // getIndValue() === "" &&
                      //   errors?.find((error) => error?.key === "culture_ids")
                      //     ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
                      //     : "1px solid #E0E0E0",
                      "&:hover": "1px solid #E0E0E0",
                      // getIndValue() === "" &&
                      // errors?.find((error) => error?.key === "culture_ids")
                      //   ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
                      //   : "1px solid #E0E0E0",
                      width: `100%`,
                    }),
                  }}
                  multiOnFocus={() => {
                    if (companyCultures?.length === 0) {
                      dispatch(getCompanyCulture());
                    }
                  }}
                  value={getCultureValue()}
                  limitTags={5}
                  initialOptions={initialCompanyCulture?.map((item) => ({
                    value: item?.company_culture_id,
                    label: item?.name,
                  }))}
                />
              </Box>
            </Box>
          </Box>
          <Box>
            <Button
              sx={{
                boxShadow: 0,
                fontSize: "14px",
                width: "50%",
                borderRadius: "0 0 0 10px",
                height: "43px",
                background: "lightgray",
                color: "black",
                padding: 3,
                ":hover": {
                  background: "lightgray",
                },
              }}
              variant="contained"
              onClick={handleClose}
            >
              cancel
            </Button>
            <Button
              sx={{
                boxShadow: 0,
                fontSize: "14px",
                width: "50%",
                borderRadius: "0 0 10px 0",
                height: "43px",
                padding: 3,
              }}
              variant="contained"
              color="redButton100"
              onClick={editCompanyData}
            >
              edit
            </Button>
          </Box>
        </Box>
      </Modal>
      <PinConfirmation
        show={openPin}
        handleClose={() => {
          setOpenPin(false);
          setConfirmPin(false);
        }}
        handleDelete={handlePinJob}
        confirmPin={confirmPin}
        setConfirmPin={setConfirmPin}
        dialogText={`company ${companyData?.company_name}`}
      />
    </>
  );
}
