import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getApi, postApi } from "../../utils/Apis";
import { setLoading } from "../configSlice";
const initialState = {
  jobs: [],
  allJobs: [],
  showBox: false,
  currentPageJobsCount: 0,
  loggedin: false,
  cardsPadding: 30,
  filterExpanded: false,
  jobDetailsPageFilterData:[]
};
export const getAllJobs = createAsyncThunk(
  "getAllJobs",
  async ({ lastKey, user_id }, { dispatch }) => {
    dispatch(setLoading(true));
    const { data } = await getApi(
      "/getjobslist/filter?industry_id=&lastKey=" +
        lastKey +
        "&jobtype_id=&jobstage_id=&personalitytype_id=&title=&user_id=" +
        user_id +
        "&favourite=&appliedjobs=&recent="
    );
    dispatch(setLoading(false));
    return data;
  }
);

export const uploadJobImage = createAsyncThunk(
  "uploadJobImage",
  async (payload, { dispatch }) => {
    // dispatch(setLoading(true));
    const { data } = await postApi(
      "/upload/jobimage",
      payload,
      true,
      "multipart/form-data"
    ); //"multipart/form-data"
    // dispatch(setLoading(false));
    return data;
  }
);

export const getFilteredJobs = createAsyncThunk(
  "getFilteredJobs",
  async (
    {
      selectedFilters = "",
      lastKey = "",
      jobtype = "",
      jobstage = "",
      personalityType = "",
      job_title = "",
      favourites,
      recentjob,
      appliedjob,
      region_id = "",
      town_id = "",
      salary = "",
      experience = "",
      tool_id = "",
      tag_id = "",
      company_id = "",
      currency_id = "",
      highest_qualification_id = "",
      company_size = "",
      company_type = "",
      decodedToken,
      query = "",
    },
    { dispatch }
  ) => {
    dispatch(setLoading(true));
    // let isAuthorize = decodedToken? true:false

    const { data } = await getApi(
      "/getjobslist/filter?industry_id=" +
        selectedFilters +
        "&lastKey=" +
        lastKey +
        "&jobtype_id=" +
        jobtype +
        "&jobstage_id=" +
        jobstage +
        "&personalitytype_id=" +
        personalityType +
        "&query=" +
        query +
        "&job_title=" +
        job_title +
        "&favourite=" +
        favourites +
        "&appliedjobs=" +
        appliedjob +
        "&recent=" +
        recentjob +
        "&region_id=" +
        region_id +
        "&town_id=" +
        town_id +
        "&salary=" +
        salary +
        "&experience=" +
        experience +
        "&tool_id=" +
        tool_id +
        "&tag_id=" +
        tag_id +
        "&company_id=" +
        company_id +
        "&highest_qualification_id=" +
        highest_qualification_id +
        "&currency_id=" +
        currency_id +
        "&companysize=" +
        company_size +
        "&companytype=" +
        company_type
      // isAuthorize
    );
    dispatch(setLoading(false));
    return data;
  }
);

export const getVideoLink = createAsyncThunk(
  "getVideoLink",
  async (payload, { dispatch }) => {
    const { data } = await getApi(
      `/job/jobvideolink?job_id=${payload?.job_id}`,
      true
    );
    return data;
  }
);

export const getJobDetail = createAsyncThunk(
  "getJobDetail",
  async ({ job_id }, { dispatch }) => {
    // let isAuthorize = decodedToken? true:false
    dispatch(setLoading(true));
    const { data } = await getApi("/jobs/getjob?job_id=" + job_id);
    dispatch(setLoading(false));
    return data;
  }
);
export const getsingleJobDetail = createAsyncThunk(
  "getsingleJobDetail",
  async ({ job_id }, { dispatch }) => {
    // let isAuthorize = decodedToken? true:false
    dispatch(setLoading(true));
    const { data } = await getApi("/jobs/getjobdetails?job_id=" + job_id);
    dispatch(setLoading(false));
    return data;
  }
);
export const getMatchData = createAsyncThunk(
  "getMatchData",
  async ({ job_id, user_id }, { dispatch }) => {
    // dispatch(setLoading(true));
    const { data } = await getApi(
      "/job/jobmatching?job_id=" + job_id + "&candidate_user_id=" + user_id
    );
    // dispatch(setLoading(false));
    return data;
  }
);

export const getSearchedResult = createAsyncThunk(
  "getSearchedResult",
  async (payload, { dispatch }) => {
    // dispatch(setLoading(true));
    const { data } = await postApi("/getIdValue", payload, true);
    // dispatch(setLoading(false));
    return data;
  }
);

export const jobsSlice = createSlice({
  name: "configjobslice",
  initialState,
  reducers: {
    updateAllJobs: (state, action) => {
      return { ...state, allJobs: [], showBox: false, currentPageJobsCount: 0 };
    },
    setLoggedIn: (state, action) => {
      state.loggedin = action.payload;
    },
    setCardsPadding: (state, action) => {
      state.cardsPadding = action.payload;
    },
    setFilterExpanded: (state, action) => {
      state.filterExpanded = action.payload;
    },
    setJobDetailsPageFilterData: (state, action) => {
      state.jobDetailsPageFilterData = action.payload;
    },
    appliedFlagUpdate: (state, action) => {
      return {
        ...state,
        allJobs: state?.allJobs?.map((item) => {
          if (item?.job_id === action?.payload?.jobId) {
            return { ...item, applidflag: action?.payload?.applidflag };
          }
          return item;
        }),
        // allJobs: current(state)?.allJobs?.map((item) => {
        //   if (item?.job_id === action?.payload?.jobId) {
        //     return { ...item, applidflag: action?.payload?.applidflag };
        //   } else {
        //     return item;
        //   }
        // }),
      };
    },
  },
  extraReducers(builder) {
    builder.addCase(getFilteredJobs.fulfilled, (state, action) => {
      if (!action.payload) {
        // Handle undefined payload (e.g., log a warning, return early)
        return {
          ...state,
          allJobs: [],
          showBox: false,
          currentPageJobsCount: 0,
        }; // Or your desired default state
      }
      if (
        action?.payload?.data?.length <= 0 ||
        action?.payload?.status === "error"
      ) {
        state.currentPageJobsCount = action?.payload?.data?.length || 0;
        state.showBox = true;
      } else {
        state.currentPageJobsCount = action?.payload?.data?.length || 0;
        state.allJobs = [
          ...state?.allJobs,
          ...action?.payload?.data?.map((jobs) => {
            return {
              ...jobs,
            };
          }),
        ];
      }
    });
  },
});
// Action creators are generated for each case reducer function
export const {
  updateAllJobs,
  appliedFlagUpdate,
  setLoggedIn,
  setCardsPadding,
  setFilterExpanded,
  setJobDetailsPageFilterData
} = jobsSlice.actions;

export default jobsSlice.reducer;
