import { useTheme } from "@emotion/react";
import { Box, Button, InputBase, Paper, Typography } from "@mui/material";
import React, { useCallback, useEffect, useRef, useState } from "react";
import InputBox from "../InputBox";

import flipIcon from "../../../../assets/Crayon_User_Lite_Black.svg";
import PhoneInput, { formatPhoneNumberIntl } from "react-phone-number-input";

import { isValidPhoneNumber } from "libphonenumber-js/max";
import { CheckCircle } from "@mui/icons-material";
import locale from "../../../../i18n/locale";
import {
  ALERT_TYPE,
  ERROR_MSG,
  emailRegex,
  websiteUrlRegex,
} from "../../../../utils/Constants";
import SelectMenu from "../../SelectMenu";
import {
  getCompanySize,
  getCompanyType,
} from "../../../../redux/employer/empProfileSlice";
import { setAlert } from "../../../../redux/configSlice";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { uploadCompanyLogo } from "../../../../redux/candidate/myProfileSlice";
import getCroppedImg from "../../../../utils/cropImage";
import CropModal from "../../../employer/myProfile/cropModal";
import uploadLogoIcon from "../../../../assets/Padding Excluded/Black_Upload copy.svg";
import { getContactDetail } from "../../../../utils/Common";

const AddDetailsBackPage = ({
  basicData,
  setBasicData,
  handleSave,
  setIsFlipped,
  companies,
  initialCountryCode,
  setCompanyInfoCharLength,
  companyInfoCharLength,
}) => {
  const i18n = locale.en;
  const theme = useTheme();
  const countryCode = useRef("");
  const hiddenFileInput = useRef(null);
  const dispatch = useDispatch();
  const { companyType, companySize } = useSelector((state) => state?.myProfile);
  // const [companyInfoCharLength, setCompanyInfoCharLength] = useState(0);
  const [errors, setErrors] = useState({
    contact_person_name: "",
    contact_person_email: "",
    contact_person_number: "",
    hyperlink: "",
    company_type: "",
    company_size: "",
  });
  const [touched, setTouched] = useState({
    contact_person_name: false,
    contact_person_email: false,
    contact_person_number: false,
    hyperlink: false,
    company_type: false,
    company_size: false,
  });

  const [imagePreview, setImagePreview] = useState([]);
  const [imageName, setImageName] = useState("My pic");
  const [image, setImage] = useState([]);

  const [openEditImage, setOpenEditImage] = useState(false);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [imageBlob, setImageBlob] = useState();
  const [croppedImageUrl, setCroppedImageUrl] = useState(null);

  const validateData = () => {
    let isValid = true;
    if (!basicData?.editflag) {
      setErrors([]);
      setTouched({
        contact_person_name: false,
        contact_person_email: false,
        contact_person_number: false,
        hyperlink: false,
        company_type: false,
        company_size: false,
      });
      return true;
    }

    const newErrors = {
      hyperlink: "",
      company_type: "",
      company_size: "",
      contact_person_name: "",
      contact_person_email: "",
      contact_person_number: "",
    };
    // Validate Company Website
    if (
      !websiteUrlRegex?.test(basicData?.hyperlink) ||
      basicData?.hyperlink === ""
    ) {
      newErrors.hyperlink = "Company Website is required";
      isValid = false;
    }

    // Validate Company Type
    if (!basicData?.company_type || basicData?.company_type === "") {
      newErrors.company_type = "Company Type is required";
      isValid = false;
    }

    // Validate Company Size
    if (!basicData?.company_size || basicData?.company_size === "") {
      newErrors.company_size = "Company Size is required";
      isValid = false;
    }

    if (
      !basicData?.contact_person_name ||
      basicData?.contact_person_name === ""
    ) {
      newErrors.contact_person_name = "Contact person name is required";
      isValid = false;
    }
    if (
      !emailRegex?.test(basicData?.contact_person_email) ||
      basicData?.contact_person_email === ""
    ) {
      newErrors.contact_person_email = "Contact person email is required";
      isValid = false;
    }
    if (
      !basicData?.contact_person_number ||
      basicData?.contact_person_number === ""
    ) {
      newErrors.contact_person_number = "Contact person number is required";
      isValid = false;
    }

    // Set the errors state
    setErrors(newErrors);

    return isValid;
  };
  const handleInputChange = (event) => {
    const newProfileData = {
      ...basicData,
      [event.target.id]: event?.target?.value,
    };
    delete errors?.[event?.target?.id];
    setTouched({ ...touched, [event?.target?.id]: false });
    setBasicData({ ...newProfileData });
  };
  const handleContactChange = (value) => {
    const contactInfo = getContactDetail(formatPhoneNumberIntl(value));
    const newProfileData = {
      ...basicData,
      contact_person_number: contactInfo[1],
      country_code: contactInfo[0],
    };
    delete errors?.["contact_person_number"];
    setTouched({ ...touched, contact_person_number: false });
    setBasicData({ ...newProfileData });
  };
  const handleCountryChange = (value) => {
    countryCode.current = value;
  };

  const getAllData = async () => {
    try {
      await Promise.all([
        dispatch(getCompanyType()),
        dispatch(getCompanySize()),
      ]);
    } catch (error) {
      dispatch(
        setAlert({
          show: true,
          type: ALERT_TYPE?.ERROR,
          msg: ERROR_MSG,
        })
      );
    }
  };

  const handleCompanyType = (event) => {
    const {
      target: { value },
      target: { name },
    } = event;

    const newProfileData = {
      ...basicData,
      [name]: companyType?.find((role) => role?.id == value)?.name || "",
    };
    delete errors?.[name];
    setTouched({ ...touched, [name]: false });
    setBasicData({ ...newProfileData });
  };

  const handleCompanySize = (event) => {
    const {
      target: { value },
      target: { name },
    } = event;
    const newProfileData = {
      ...basicData,
      [name]: companySize?.find((role) => role?.id == value)?.name || "",
    };
    delete errors?.[name];
    setTouched({ ...touched, [name]: false });
    setBasicData({ ...newProfileData });
  };

  const handleSubscriptionOk = async () => {
    // const croppedImage = await getCroppedImg(
    //   imagePreview,
    //   croppedAreaPixels,
    //   0
    // );
    const formData = new FormData();
    const blobTofile = new File([imageBlob], imageName, {
      type: "image/jpeg",
    });

    formData?.append("company-logo", blobTofile);

    try {
      const { payload } = await dispatch(uploadCompanyLogo(formData));
      if (payload?.status === "success") {
        setBasicData({
          ...basicData,
          companyURL: payload?.data,
          logo: payload?.logo,
        });
        setOpenEditImage(false);

        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE?.SUCCESS,
            msg: i18n["alertMessage.CompanylogouploadedSuccessfully"],
          })
        );
      } else if (payload?.status === "error") {
        setImageName("My pic");
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE?.ERROR,
            msg: i18n["alertMessage.Imageformatnotsupported"],
          })
        );
      }
    } catch (error) {
      setImageName("My pic");
      dispatch(setAlert({ show: true }));
    }
  };

  const handleCompanyLogo = useCallback(
    async (event) => {
      const croppedImage = await getCroppedImg(
        imagePreview,
        croppedAreaPixels,
        0
      );
      const formData = new FormData();
      const blobTofile = new File([croppedImage], imageName, {
        type: "image/jpeg",
      });

      formData?.append("company-logo", blobTofile);

      try {
        const { payload } = await dispatch(uploadCompanyLogo(formData));
        if (payload?.status === "success") {
          setBasicData({
            ...basicData,
            companyURL: payload?.data,
            logo: payload?.logo,
          });
          setOpenEditImage(false);

          dispatch(
            setAlert({
              show: true,
              type: ALERT_TYPE?.SUCCESS,
              msg: i18n["alertMessage.CompanylogouploadedSuccessfully"],
            })
          );
        } else if (payload?.status === "error") {
          setImageName("My pic");
          dispatch(
            setAlert({
              show: true,
              type: ALERT_TYPE?.ERROR,
              msg: i18n["alertMessage.Imageformatnotsupported"],
            })
          );
        }
      } catch (error) {
        setImageName("My pic");
        dispatch(setAlert({ show: true }));
      }
    },
    [croppedAreaPixels]
  );

  const handleSubscriptionCancel = () => {
    setImageName("My pic");
    setOpenEditImage(false);
    setImageBlob();
    setCroppedImageUrl(null);
    setImage();
  };

  const handleImageClick = (e) => {
    setImagePreview(null);
    hiddenFileInput?.current?.click();
  };

  const handleZoom = (direction) => {
    const step = 0.5;
    let newZoom = zoom;

    if (direction === "+") {
      newZoom = Math.min(zoom + step, 3); // Limit zoom to maximum 3x
    } else if (direction === "-") {
      newZoom = Math.max(zoom - step, 1); // Limit zoom to minimum 1x
    }

    setZoom(newZoom);
  };

  const cropInputFUnction = (e) => {
    const files = e?.target?.files;
    if (files && files?.length > 0) {
      const reader = new FileReader();
      reader.onload = () => setImage(reader?.result);
      reader?.readAsDataURL(files?.[0]);
    }
  };

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);
  const handleImageChange = async (event) => {
    if (event?.target?.files?.[0] === undefined) {
      return;
    }
    cropInputFUnction(event);
    const render = new FileReader();
    setZoom(1);
    setImageName(event?.target?.files?.[0].name);
    const imageData = window.URL.createObjectURL(
      new Blob(event?.target?.files, { type: "application/*" })
    );
    setImagePreview(imageData);
    setOpenEditImage(true);
    event.target.value = "";
  };

  useEffect(() => {
    getAllData();
  }, []);

  return (
    <>
      <Box
        sx={{
          width: "100%",
          height: "530px",
          borderRadius: "25px",
          background: "white",
          position: "relative",
          boxShadow: "0px 5px 10px #C9CAD8",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <Button
          variant="contained"
          color="recordVideoButton"
          sx={{ width: "150px", height: "45px", borderRadius: "25px 0 25px 0", cursor:"default" }}
        >
          {i18n["empMyProfile.addACompany"]}
        </Button>
        <Box
          sx={{
            padding: "7px 16px 16px 16px",
            display: "flex",
            flexDirection: "column",
            gap: "7px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              gap: "10px",
              justifyContent: "space-between",
              alignItems: "end",
            }}
          >
            <Box
              sx={{
                width: "60%",
                display: "flex",
                flexDirection: "column",
                gap: "3px",
              }}
            >
              <Box>
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: theme.typography.Bold,
                  }}
                >
                  {i18n["empMyProfile.companyTypeReq"]}
                </Typography>
                <SelectMenu
                  readOnly={!basicData?.editflag}
                  name="company_type"
                  value={basicData?.company_type}
                  onHandleChange={handleCompanyType}
                  options={companyType}
                  borderRadius="10px"
                  clear={true}
                  height="38px"
                  sx={{
                    width: "100%",
                    border:
                      touched?.company_type && errors?.company_type !== ""
                        ? "1px solid red"
                        : `1px solid ${theme.palette.grayBorder}`,
                  }}
                  onBlur={() => {
                    setTouched((prevTouched) => ({
                      ...prevTouched,
                      company_type: true,
                    }));
                    validateData();
                  }}
                  placeholder={"Select company type"}
                />
              </Box>
              <Box>
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: theme.typography.Bold,
                  }}
                >
                  {i18n["empMyProfile.companySizeReq"]}
                </Typography>
                <SelectMenu
                  readOnly={!basicData?.editflag}
                  name="company_size"
                  value={basicData?.company_size}
                  onHandleChange={handleCompanySize}
                  options={companySize}
                  borderRadius="10px"
                  clear={true}
                  height="38px"
                  sx={{
                    width: "100%",
                    border:
                      touched?.company_size && errors?.company_size !== ""
                        ? "1px solid red"
                        : `1px solid ${theme.palette.grayBorder}`,
                  }}
                  onBlur={() => {
                    setTouched((prevTouched) => ({
                      ...prevTouched,
                      company_size: true,
                    }));
                    validateData();
                  }}
                  placeholder={"Number of employees"}
                />
              </Box>
            </Box>

            <Box
              sx={{
                width: "32%",
              }}
            >
              <input
                ref={hiddenFileInput}
                type="file"
                accept="image/*"
                onChange={(event) => handleImageChange(event, "company")}
                style={{ display: "none" }}
              />
              {basicData?.companyURL === "NO URL" ? (
                <Button
                  disabled={!basicData?.editflag}
                  color="UploadButton"
                  variant="contained"
                  type="file"
                  sx={{
                    height: "100px",
                    width: "100px",
                    fontSize: "14px",
                    padding: 0,
                    display: "flex",
                    flexDirection: "column",
                    gap: "20px",
                    borderRadius: "20px",
                    boxShadow: 0,
                    ":hover": {
                      boxShadow: 0,
                    },
                    border: "1px solid #E8E8E8",
                    "& .MuiButton-startIcon": {
                      margin: "0 !important",
                    },
                  }}
                  startIcon={
                    <Box
                      component={"img"}
                      src={uploadLogoIcon}
                      sx={{
                        width: "18px",
                        height: "25px",
                      }}
                    />
                  }
                  onClick={handleImageClick}
                >
                  {i18n["empMyCompanies.uploadLogo"]}
                </Button>
              ) : (
                <Box
                  sx={{ display: "flex", flexDirection: "column", gap: "8px" }}
                >
                  <Button
                    disabled={!basicData?.editflag}
                    color="UploadButton"
                    variant="contained"
                    type="file"
                    sx={{
                      position: "absolute",
                      top: "40px",
                      height: "30px",
                      width: "100px",
                      fontSize: "14px",
                      zIndex: "9999",
                      padding: 0,
                      display: "flex",
                      flexDirection: "column",
                      gap: "20px",
                      borderRadius: "10px",
                      boxShadow: 0,
                      ":hover": {
                        boxShadow: 0,
                      },
                      border: "1px solid #E8E8E8",
                      "& .MuiButton-startIcon": {
                        margin: "0 !important",
                      },
                    }}
                    // startIcon={
                    //   <Box
                    //     component={"img"}
                    //     src={uploadLogoIcon}
                    //     sx={{
                    //       width: "18px",
                    //       height: "25px",
                    //     }}
                    //   />
                    // }
                    onClick={handleImageClick}
                  >
                    {"Update logo"}
                  </Button>
                  <Box
                    component={"img"}
                    className="companyLogo"
                    alt="crayon logo"
                    src={basicData?.companyURL}
                    sx={{
                      height: "100px",
                      width: "100px",
                      // border: 1,
                      // borderColor: "lightgrey",
                      borderRadius: "10px",
                    }}
                  />
                </Box>
              )}
            </Box>
          </Box>

          <Box>
            <Typography
              sx={{
                fontSize: "14px",
                fontWeight: theme.typography.Bold,
              }}
            >
              {i18n["empMyProfile.companyWebsiteReq"]}
            </Typography>
            <InputBox
              readOnly={!basicData?.editflag}
              placeholder="Enter the company’s website address"
              value={basicData?.hyperlink ? basicData?.hyperlink : ""}
              id="hyperlink"
              onChange={handleInputChange}
              sx={{
                height: "38px",
                border:
                  touched?.hyperlink && errors?.hyperlink !== ""
                    ? "1px solid red"
                    : `1px solid ${theme.palette.grayBorder}`,
              }}
              onBlur={() => {
                setTouched((prevTouched) => ({
                  ...prevTouched,
                  hyperlink: true,
                }));
                validateData();
              }}
            />
          </Box>

          <Box>
            <Typography
              sx={{
                fontSize: "14px",
                fontWeight: theme.typography.Bold,
              }}
            >
              {i18n["empMyCompanies.contactPerson"]}
            </Typography>
            <InputBox
              readOnly={!basicData?.editflag}
              placeholder="Enter the company’s contact person"
              value={
                basicData?.contact_person_name
                  ? basicData?.contact_person_name
                  : ""
              }
              id="contact_person_name"
              onChange={handleInputChange}
              sx={{
                height: "38px",
                border:
                  touched?.contact_person_name &&
                  errors?.contact_person_name !== ""
                    ? "1px solid red"
                    : `1px solid ${theme.palette.grayBorder}`,
              }}
              onBlur={() => {
                setTouched((prevTouched) => ({
                  ...prevTouched,
                  contact_person_name: true,
                }));
                validateData();
              }}
            />
          </Box>

          <Box>
            <Typography
              sx={{
                fontSize: "14px",
                fontWeight: theme.typography.Bold,
              }}
            >
              {i18n["empMyCompanies.contactPersonEmail"]}
            </Typography>
            <InputBox
              readOnly={!basicData?.editflag}
              placeholder="Enter the contact person’s email"
              value={
                basicData?.contact_person_email
                  ? basicData?.contact_person_email
                  : ""
              }
              id="contact_person_email"
              onChange={handleInputChange}
              sx={{
                height: "38px",
                border:
                  touched?.contact_person_email &&
                  errors?.contact_person_email !== ""
                    ? "1px solid red"
                    : `1px solid ${theme.palette.grayBorder}`,
              }}
              onBlur={() => {
                setTouched((prevTouched) => ({
                  ...prevTouched,
                  contact_person_email: true,
                }));
                validateData();
              }}
            />
          </Box>
          <Box>
            <Typography
              sx={{
                fontSize: "14px",
                fontWeight: theme.typography.Bold,
              }}
            >
              {i18n["empMyCompanies.contactPersonPhone"]}
            </Typography>
            <Paper
              elevation={3}
              sx={{
                display: "flex",
                borderRadius: "10px",
                height: "38px !important",
                boxShadow: "none",
                padding: "0 10px",
                justifyContent: "space-between",
                border:
                  touched?.contact_person_number &&
                  !isValidPhoneNumber(
                    `${basicData?.country_code}${basicData?.contact_person_number}` ||
                      ""
                  )
                    ? "1px solid red"
                    : `1px solid ${theme.palette.grayBorder}`,
              }}
            >
              <PhoneInput
                readOnly={!basicData?.editflag}
                international
                id="contact_person_number"
                placeholder="Enter the contact person’s phone number"
                value={
                  basicData?.contact_person_number
                    ? `${basicData?.country_code}${basicData?.contact_person_number}`
                    : ""
                }
                defaultCountry={initialCountryCode.toUpperCase()}
                onChange={handleContactChange}
                onCountryChange={(value) => handleCountryChange(value)}
                onBlur={() => {
                  setTouched((prevTouched) => ({
                    ...prevTouched,
                    contact_person_number: true,
                  }));
                }}
              />
              {isValidPhoneNumber(
                `${basicData?.country_code}${basicData?.contact_person_number}` ||
                  ""
              ) && (
                <Typography
                  sx={{
                    color: theme.palette.tealColor.main,
                    fontSize: "14px",
                    alignItems: "center",
                    display: "flex",
                    gap: "5px",
                    marginRight: "5px",
                    justifyContent: "end",
                  }}
                >
                  {" "}
                  {i18n["empMyCompanies.correct"]}
                  <CheckCircle fontSize="small" />
                </Typography>
              )}
            </Paper>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            height: "60px",
          }}
        >
          <Button
            sx={{
              borderRadius: "0 0 0 25px",
              width: "30%",
              height: "100%",
              gap: "10px",
              ":hover": {
                boxShadow: 0,
              },
            }}
            color="backButton"
            variant="contained"
            onClick={() => setIsFlipped(false)}
          >
            back
            <Box
              component={"img"}
              src={flipIcon}
              sx={{
                width: "25px",
                height: "25px",
              }}
            />
          </Button>

          <Button
            sx={{
              borderRadius: "0 0 25px 0",
              width: "70%",
              height: "100%",
              ":hover": {
                boxShadow: 0,
              },
            }}
            color="saveButton"
            variant="contained"
            onClick={() => {
              setTouched({
                contact_person_name: true,
                contact_person_email: true,
                contact_person_number: true,
                hyperlink: true,
                company_type: true,
                company_size: true,
              });
              const isValid = validateData();
              isValid && handleSave();
            }}
          >
            save
          </Button>
        </Box>
      </Box>
      {openEditImage && (
        <CropModal
          handleOpen={openEditImage}
          handleClose={setOpenEditImage}
          handleSubscriptionOk={handleSubscriptionOk}
          handleSubscriptionCancel={handleSubscriptionCancel}
          imagePreview={imagePreview}
          image={image}
          setImage={setImage}
          croppedImageUrl={croppedImageUrl}
          setCroppedImageUrl={setCroppedImageUrl}
          imageBlob={imageBlob}
          setImageBlob={setImageBlob}
        />
      )}
    </>
  );
};

export default AddDetailsBackPage;
