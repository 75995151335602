import React, { useEffect, useRef, useState } from "react";
import AddVideo from "./dialog/AddVideo";
import { Box } from "@mui/material";
import { useDispatch } from "react-redux";
import {
  uploadEmployerMyCamVideo,
  getEmployerMyCamVideo,
} from "../../../redux/employer/myJobsSlice";
import { setAlert, setLoading } from "../../../redux/configSlice";
import { ALERT_TYPE } from "../../../utils/Constants";
import CounterDialog from "./dialog/CounterDialog";
import RecordingInProgress from "./dialog/RecordingInProgress";
import PublishVideo from "./dialog/PublishVideo";
import { getVideoFileDuration } from "../../../utils/Common";

export default function EmployerVideoAddSteps({
  handleStopRecording,
  handleCloseCamera,
  setMediaStream,
  mediaStream,
  mediaRecorderRef,
  step,
  setStep,
  userData,
  setUserData,
  recordedVideoData,
  setRecordedVideoData,
  setActiveStep,
  companyID,
  setCompanyID,
  setVideoLink,
  videoLink,
  allData,
  setAllData
}) {
  const dispatch = useDispatch();
  const [videoBlob, setVideoBlob] = useState("");
  // const[allData, setAllData]= useState({})
  const [textChange, setTextChange] = useState(
    recordedVideoData ? true : false
  );
  // const mediaRecorderRef = useRef(null);
  // const [step, setStep] = useState(1);
  // const [recordedVideoData, setRecordedVideoData] = useState(null);
  // const [videoLink, setVideoLink] = useState("");
  // const [userData, setUserData] = useState();
  // const [mediaStream, setMediaStream] = useState(null);

  // const [companyID, setCompanyID] = useState();

  const getVideo = async () => {
    setLoading(true);
    const { payload } = await dispatch(getEmployerMyCamVideo());
    console.log(
      {
        FITER: payload?.data?.filter((item) => item?.default),
        PAYLOAD: payload?.data,
      },
      "PAYLOAD"
    );
    if (payload?.status === "success") {
      if (payload?.data?.length > 0) {
        setAllData(payload?.data?.filter((item) => item?.default)?.[0])
        setUserData(payload?.data?.filter((item) => item?.default)?.[0]?.user);
        setVideoLink(
          payload?.data?.filter((item) => item?.default)?.[0]?.video_url
        );
        setRecordedVideoData(
          payload?.data?.filter((item) => item?.default)?.[0]?.video_url
        );
        setCompanyID(
          payload?.data?.filter((item) => item?.default)?.[0]?.company_id
        );
        payload?.data?.filter((item) => item?.default)?.[0]?.video_url ===
        "No URL"
          ? setStep(1)
          : setStep(4);
      } else {
        setStep(1);
      }
    } else {
      dispatch(
        setAlert({
          show: true,
          type: ALERT_TYPE?.ERROR,
          msg: "No video",
        })
      );
    }
    setLoading(false);
  };

  const uploadVideo = async () => {
    dispatch(setLoading(true));
    const myFile = new File([videoBlob], "videoFile.webm", {
      type: videoBlob?.type,
    });

    getVideoFileDuration(myFile, async (error, videoDuration, fileType) => {
      if (error) {
        setVideoLink("")
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE?.ERROR,
            msg: error,
          })
        );
        return;
      } else if (myFile?.size > 50000000) {
        setVideoLink("")
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE?.ERROR,
            msg: "Video size should be less than 50MB",
          })
        );
        return;
      }
      const formData = new FormData();
      formData?.append("company-video", myFile);
      formData?.append("company_id", companyID);
      try {
        const { payload } = await dispatch(uploadEmployerMyCamVideo(formData));
        if (payload?.status === "success") {
          dispatch(
            setAlert({
              show: true,
              type: ALERT_TYPE?.SUCCESS,
              msg: "Video uploaded Successfully!",
            })
          );
          setTextChange(true);
          await getVideo();
          dispatch(setLoading(false));
        } else {
          dispatch(setLoading(false));
          dispatch(
            setAlert({
              show: true,
              type: ALERT_TYPE?.ERROR,
              msg: "Failed to upload Video",
            })
          );
        }
        dispatch(setLoading(false));
      } catch (error) {
        dispatch(setAlert({ show: true }));
        dispatch(setLoading(false));
      }
    });
  };

  // const handleStopRecording = () => {
  //   if (
  //     mediaRecorderRef.current &&
  //     mediaRecorderRef.current.state === "recording"
  //   ) {
  //     mediaRecorderRef.current.stop();
  //   }
  // };

  // const handleCloseCamera = () => {
  //   if (mediaStream) {
  //     mediaStream.getTracks().forEach((track) => {
  //       track.stop();
  //     });
  //     setMediaStream(null);
  //   }
  // };

  // useEffect(() => {
  //   getVideo();
  // }, []);

  const handleVideoData = (videoData) => {
    setRecordedVideoData(videoData);
  };
  return (
    <Box
      sx={{
        background: "#FFF",
        boxShadow: "0px 3px 6px #00000029",
        width: { xs: "100%", lg: "100%", lgm: "50%" },
        borderRadius: "25px",
        display: "flex",
        // height: { xs: "auto", tablet: "784px" },
        // maxHeight:"683px",
        height: "calc(100% - 10%)",
        // maxWidth:"50%",
        flexDirection: "column",
        justifyContent: { xs: "start", lg: "space-between" },
        marginBottom: "30px",
        marginTop: { xs: "55px", md: "55px" },
      }}
    >
      {step === 1 && (
        <AddVideo
          nextStep={setStep}
          getVideo={getVideo}
          companyID={companyID}
          setTextChange={setTextChange}
          allData={allData}
          setAllData={setAllData}
        />
      )}
      {step === 2 && <CounterDialog nextStep={setStep} name={"company"} />}
      {step === 3 && (
        <RecordingInProgress
          nextStep={setStep}
          onRecordingStop={handleVideoData}
          uploadFunc={uploadVideo}
          name={"company"}
          handleStopRecording={handleStopRecording}
          handleCloseCamera={handleCloseCamera}
          setMediaStream={setMediaStream}
          mediaStream={mediaStream}
          mediaRecorderRef={mediaRecorderRef}
          setRecordedVideoData={setRecordedVideoData}
          setVideoBlob={setVideoBlob}
          setTextChange={setTextChange}
        />
      )}
      {step === 4 && (
        <PublishVideo
          nextStep={setStep}
          videoData={recordedVideoData}
          companyID={companyID}
          userData={userData}
          getVideo={getVideo}
          setActiveStep={setActiveStep}
          uploadVideo={uploadVideo}
          textChange={textChange}
          allData={allData}
          setAllData={setAllData}
        />
      )}
    </Box>
  );
}
