import React, { useCallback, useEffect, useState } from "react";
import { Box, InputLabel, Slider, Typography, useTheme } from "@mui/material";
import { setAlert } from "../../../../redux/configSlice";
import {
  ALERT_TYPE,
  BASIC_RANGEMARKS,
  BASIC_RANGEMARKS2,
  BASIC_RANGEMARKS_SLIDER,
  EXPERIENCE,
  NOTICEPERIOD,
} from "../../../../utils/Constants";
import { useDispatch } from "react-redux";
import locale from "../../../../i18n/locale";
import CustomSelect from "../../../candidate/myCV/CustomSelect";
import {
  checkNumberRange,
  debounce,
  formatNumber,
  genarateLabelValue,
  genarateLabelValueForFreelance,
  textValue,
} from "../../../../utils/Common";
import {
  getIndustries,
  getSkills,
  getTitles,
  updateToInitialIndustrie,
  updateToInitialSkills,
} from "../../../../redux/candidate/myCvSlice";
import { useSelector } from "react-redux";
import SelectMenu from "../../../common/SelectMenu";
import {
  getTools,
  updateToInitialTools,
} from "../../../../redux/employer/postJobSlice";
import SalarySlider from "../../../common/SalarySlider";
import { BlueSwitch } from "../../../../utils/CommonComponent";

export default function EditBasicInfo({
  talentDetails,
  errors,
  setTalentDetails,
  getAllData,
  setErrors,
  setRangeValue,
  rangeValue,
  industries,
  initialIndustries,
  skills,
  initialSkills,
  tools,
  initialTools,
  setSalaryRange,
  salaryRange,
  singleSalaryValue,
  setSingleSalaryValue,
}) {
  const theme = useTheme();
  const i18n = locale.en;
  const dispatch = useDispatch();

  const { titles, currency, qualifications } = useSelector(
    (state) => state?.myCv
  );
  const { roleTypes, workSetup } = useSelector((state) => state?.postJobs);
  const [isLoading, setIsLoading] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [interval, setInterval] = useState();
  const [salaryRangeValue, setSalaryRangeValue] = useState(
    talentDetails?.salary?.[0] > 0 ? true : false
  );

  const handleOnSelectChange = (ind, newValue, val, inputId) => {
    let newBasicData = { ...talentDetails };
    if (ind === "industries_ids" || ind === "tool_ids" || ind === "skill_ids") {
      if (ind === "industries_ids") {
        dispatch(updateToInitialIndustrie());
        setErrors((prev) =>
          prev?.filter((item) => item?.key !== "industries_id")
        );
      }
      if (ind === "tool_ids") {
        dispatch(updateToInitialTools());
        setErrors((prev) => prev?.filter((item) => item?.key !== "tools_id"));
      }
      if (ind === "skill_ids") {
        dispatch(updateToInitialSkills());
        setErrors((prev) => prev?.filter((item) => item?.key !== "tags_id"));
      }
      newBasicData[ind] = newValue;
    } else if (
      newValue &&
      newValue?.label &&
      !["industries_ids", "skill_ids", "tool_ids"].includes(newValue)
    ) {
      if (inputId === "current_job_title_id") {
        setErrors((prev) =>
          prev?.filter((item) => item?.key !== "current_job_title")
        );
      } else if (inputId === "dream_job_title_id") {
        setErrors((prev) =>
          prev?.filter((item) => item?.key !== "dream_job_title")
        );
      }
      newBasicData[inputId] = newValue;
    } else {
      newBasicData[inputId] = newValue?.id || newValue;
    }

    setTalentDetails(newBasicData);
    setErrors((prev) => prev?.filter((item) => item?.key !== ind));
  };

  const debouncedHandleOnSelectInputChange = useCallback(
    debounce(
      async (newValue, type) => {
        let params = {
          title: newValue || "",
          limit: 200,
        };
        if (newValue) {
          try {
            if (type === "industries_ids") {
              dispatch(getIndustries(params));
            }
            if (type === "skill_ids") {
              dispatch(getSkills(params));
            }
            if (type === "tool_ids") {
              dispatch(getTools(params));
            }
            if (
              type === "current_job_title_id" ||
              type === "dream_job_title_id"
            ) {
              dispatch(getTitles(params));
            }
          } catch (error) {
            console.log(error, "error");
          }
        }
      },
      750,
      setIsLoading
    ),
    []
  );

  const handleOnSelectInputChange = (newValue, inputId) => {
    debouncedHandleOnSelectInputChange(newValue, inputId);
  };

  const onCreateOption = (data, inputId) => {
    let newtalentDetails = {};
    if (data) {
      // Create a new value from the user input
      newtalentDetails = {
        ...talentDetails,
        [inputId]: { label: data, value: data },
      };
    }
    setTalentDetails(newtalentDetails);
  };

  const onMenuOpen = () => {
    if (titles?.length < 1) {
      getAllData("titles");
    }
    setIsMenuOpen(true);
  };
  const onMenuClose = () => {
    setIsMenuOpen(false);
  };

  const handleJobRoleChange = (event) => {
    let newBasicData = { ...talentDetails };
    const currencySalary = currency?.find(
      (item) => item?.currency_id === talentDetails?.currency_id
    );
    let range;
    if (event?.target?.value === 3) {
      let { rangeArray, interval } = genarateLabelValueForFreelance(
        currencySalary ? currencySalary : { max_rate: 2050 }
      );
      range = rangeArray;
      setInterval(interval);
      setRangeValue([range?.length / 10, range?.length / 2]);
      setSingleSalaryValue(range?.length / 2);
      newBasicData = {
        ...newBasicData,
        salary: [
          (range?.length / 10) * interval,
          (range?.length / 2) * interval,
        ],
      };
    } else {
      range = genarateLabelValue(
        currencySalary ? currencySalary : { max_salary: 205000 }
      );
      if (talentDetails?.currency_id === 1) {
        newBasicData = {
          ...newBasicData,
          salary: [30000, 100000],
        };
        setRangeValue([3, 10]);
        setSingleSalaryValue(10);
      } else {
        newBasicData = {
          ...newBasicData,
          salary: [
            2000,
            Math.floor(currencySalary?.max_salary / 2 / 100) * 100,
          ],
        };
        setRangeValue([2, 5.2]);
        setSingleSalaryValue(5.2);
      }
    }
    setSalaryRange(range);
    newBasicData = {
      ...newBasicData,
      employment_type:
        event?.target?.value === "None"
          ? ""
          : roleTypes?.find((role) => role?.id == event?.target?.value)?.name,
    };
    setErrors(errors?.filter((item) => item?.key !== event?.target?.name));
    setTalentDetails(newBasicData);
  };

  const handleWorkSetupChange = (event) => {
    const {
      target: { value },
      target: { name },
    } = event;
    const temp = workSetup?.find((role) => role?.id === value);
    const newtalentDetails = {
      ...talentDetails,
      [name]: temp?.name || "",
    };
    setTalentDetails(newtalentDetails);
    setErrors((prev) => prev?.filter((item) => item?.key !== "work_setup"));
  };

  const handleChange = (event) => {
    const {
      target: { value },
      target: { name },
      target: { id },
    } = event;
    let newtalentDetails = {};

    let range;
    if (name === "currency_id") {
      const currencySalary = currency?.find(
        (item) => item?.currency_id === value
      );
      if (talentDetails?.employment_type === "freelance") {
        let { rangeArray, interval } = genarateLabelValueForFreelance(
          currencySalary ? currencySalary : { max_rate: 2050 }
        );
        range = rangeArray;
        setInterval(interval);
        setRangeValue([range?.length / 10, range?.length / 2]);
        newtalentDetails = {
          ...talentDetails,
          [name]: value,
          salary: [
            (range?.length / 10) * interval,
            (range?.length / 2) * interval,
          ],
        };
      } else {
        range = genarateLabelValue(
          currencySalary ? currencySalary : { max_salary: 205000 }
        );
        if (currencySalary?.currency === "ZAR") {
          setRangeValue([3, 10]);
          setSingleSalaryValue(10);
          newtalentDetails = {
            ...talentDetails,
            [name]: value,
            salary: [30000, 100000],
          };
        } else {
          newtalentDetails = {
            ...talentDetails,
            [name]: value,
            salary: [
              2000,
              Math.floor(currencySalary?.max_salary / 2 / 100) * 100,
            ],
          };
          setRangeValue([2, 5.2]);
          setSingleSalaryValue(5.2);
        }
      }
      setSalaryRange(range);
      setTalentDetails(newtalentDetails);
    }

    if (name === "qualification_level") {
      newtalentDetails = {
        ...talentDetails,
        [name]: value,
      };
    }
    setErrors((prev) => prev?.filter((item) => item?.key !== name));
    setTalentDetails(newtalentDetails);
  };

  const handleRangeSliderChange = (event, newValue) => {
    setRangeValue(newValue);
    let newArr = newValue?.map((val) => val * 1000);
    const newBasicData = {
      ...talentDetails,
      [event?.target?.name]:
        talentDetails?.currency_id === 1
          ? [newArr?.[0] * 10, newArr?.[1] * 10]
          : newArr,
    };
    const filteredErrors = errors?.filter(
      (item) => item?.key != event?.target?.name
    );
    setErrors(filteredErrors);
    setTalentDetails(newBasicData);
  };

  const handleRangeSliderChange2 = (event, newValue) => {
    setRangeValue(newValue);
    let newArr = newValue?.map((val) => Math.round(val * interval));
    const newBasicData = {
      ...talentDetails,
      [event?.target?.name]: newArr,
    };

    const filteredErrors = errors?.filter(
      (item) => item?.key != event?.target?.name
    );
    setErrors(filteredErrors);
    setTalentDetails(newBasicData);
  };

  const singleValueSlider2 = (event) => {
    const {
      target: { value },
      target: { name },
    } = event;
    let newArr = [0, Math.round(value * interval)];
    const newBasicData = {
      ...talentDetails,
      [event?.target?.name]: newArr,
    };
    setSingleSalaryValue(value);
    const filteredErrors = errors?.filter(
      (item) => item?.key != event?.target?.name
    );
    setErrors(filteredErrors);
    setTalentDetails(newBasicData);
  };

  const singleValueSlider = (event) => {
    const {
      target: { value },
      target: { name },
    } = event;
    setSingleSalaryValue(value);
    let newArr = [0, value * 1000];
    const newBasicData = {
      ...talentDetails,
      [event?.target?.name]:
        talentDetails?.currency_id === 1
          ? [newArr?.[0] * 10, newArr?.[1] * 10]
          : newArr,
    };
    const filteredErrors = errors?.filter(
      (item) => item?.key != event?.target?.name
    );
    setErrors(filteredErrors);
    setTalentDetails(newBasicData);
  };

  const handleSalaryValue = (event) => {
    const {
      target: { value },
      target: { name },
      target: { id },
    } = event;
    setSalaryRangeValue(event?.target?.checked);

    if (talentDetails?.employment_type === "freelance") {
      if (talentDetails?.currency_id === 1) {
        setRangeValue([
          talentDetails?.salary?.[0] / 100,
          talentDetails?.salary?.[1] / 100,
        ]);
        setSingleSalaryValue(talentDetails?.salary?.[1] / 100);
      } else {
        setRangeValue([
          talentDetails?.salary?.[0] / interval,
          talentDetails?.salary?.[1] / interval,
        ]);
        setSingleSalaryValue(talentDetails?.salary?.[1] / interval);
      }
    } else {
      if (talentDetails?.currency_id === 1) {
        setRangeValue([
          talentDetails?.salary?.[0] / 10000,
          talentDetails?.salary?.[1] / 10000,
        ]);
        setSingleSalaryValue(talentDetails?.salary?.[1] / 10000);
      } else {
        setRangeValue([
          talentDetails?.salary?.[0] / 1000,
          talentDetails?.salary?.[1] / 1000,
        ]);
        setSingleSalaryValue(talentDetails?.salary?.[1] / 1000);
      }
    }
    let newArr = [0, talentDetails?.salary?.[1]];
    const newBasicData = {
      ...talentDetails,
      salary:
        talentDetails?.currency_id === 1 ? [newArr?.[0], newArr?.[1]] : newArr,
    };
    const filteredErrors = errors?.filter(
      (item) => item?.key != event?.target?.name
    );
    setErrors(filteredErrors);
    setTalentDetails(newBasicData);
  };

  const rangeValueHandler = (value) => {
    const symbol = currency?.find(
      (curr) => curr?.currency_id === talentDetails?.currency_id
    )
      ? currency?.find(
          (curr) => curr?.currency_id === talentDetails?.currency_id
        )
      : "$";
    let valueNumberRange = checkNumberRange(symbol?.max_salary);
    let extractedValue = salaryRange?.find(
      (item) => item?.value === value
    )?.label;
    let finalValue = extractedValue ? parseFloat(extractedValue) : value;

    return `${symbol?.symbol ? symbol?.symbol : symbol} ${
      valueNumberRange === "thousands"
        ? formatNumber(value * 1000)
        : formatNumber(value * 10000)
    }`;
  };

  const rangeValueHandler2 = (value) => {
    const symbol = currency?.find(
      (curr) => curr?.currency_id === talentDetails?.currency_id
    )
      ? currency?.find(
          (curr) => curr?.currency_id === talentDetails?.currency_id
        )
      : "$";
    return `${symbol?.symbol ? symbol?.symbol : symbol} ${formatNumber(
      value * interval
    )}`;
  };

  const expHandleChange = (event) => {
    const {
      target: { value },
      target: { name },
    } = event;
    const newtalentDetails = {
      ...talentDetails,
      [name]: value,
    };
    const filteredErrors = errors?.filter(
      (item) => item?.key !== "experience_id"
    );
    setErrors(filteredErrors);
    setTalentDetails(newtalentDetails);
  };

  const noticeValue = (value) => {
    const text = NOTICEPERIOD?.[value]?.label;
    return text;
  };

  const noticeHandleChange = (event) => {
    const {
      target: { value },
      target: { name },
    } = event;

    const newtalentDetails = {
      ...talentDetails,
      [name]: value,
    };
    const filteredErrors = errors?.filter((item) => item?.key !== name);
    setErrors(filteredErrors);
    setTalentDetails(newtalentDetails);
  };

  const getIndValue = () => {
    if (talentDetails?.industry?.length === 0) {
      return [];
    }

    return talentDetails?.industry?.map(
      (industry) => industries?.find((ind) => ind?.id === industry) || industry
    );
  };

  const getSkillValue = () => {
    if (talentDetails?.tags?.length === 0) {
      return [];
    }

    return talentDetails?.tags?.map(
      (skill) => skills?.find((sk) => sk?.id === skill) || skill
    );
  };

  const getToolValue = () => {
    if (talentDetails?.tools?.length === 0) {
      return [];
    }

    return talentDetails?.tools?.map(
      (id) => tools?.find((tool) => tool?.id === id) || id
    );
  };

  useEffect(() => {
    const currencySalary = currency?.find(
      (item) => item?.currency_id === talentDetails?.currency_id
    );
    let range;
    let salaryInterval;
    if (talentDetails?.employment_type === "freelance") {
      let { rangeArray, interval } = genarateLabelValueForFreelance(
        currencySalary ? currencySalary : { max_rate: 2050 }
      );
      range = rangeArray;
      salaryInterval = interval;
      setInterval(interval);
      setRangeValue([range?.length / 10, range?.length / 2]);
      setSingleSalaryValue(range?.length / 2);
    } else {
      range = genarateLabelValue(
        currencySalary ? currencySalary : { max_salary: 205000 }
      );
    }
    setSalaryRange(range);

    if (talentDetails?.employment_type === "freelance") {
      if (talentDetails?.currency_id === 1) {
        talentDetails?.salary?.[0] > 0
          ? setRangeValue([
              talentDetails?.salary?.[0] / 100,
              talentDetails?.salary?.[1] / 100,
            ])
          : setSingleSalaryValue(talentDetails?.salary?.[1] / 100);
      } else {
        talentDetails?.salary?.[0] > 0
          ? setRangeValue([
              talentDetails?.salary?.[0] / salaryInterval,
              talentDetails?.salary?.[1] / salaryInterval,
            ])
          : setSingleSalaryValue(talentDetails?.salary?.[1] / salaryInterval);
      }
    } else {
      if (talentDetails?.currency_id === 1) {
        talentDetails?.salary?.[0] > 0
          ? setRangeValue([
              talentDetails?.salary?.[0] / 10000,
              talentDetails?.salary?.[1] / 10000,
            ])
          : setSingleSalaryValue(talentDetails?.salary?.[1] / 10000);
      } else {
        talentDetails?.salary?.[0] > 0
          ? setRangeValue([
              talentDetails?.salary?.[0] / 1000,
              talentDetails?.salary?.[1] / 1000,
            ])
          : setSingleSalaryValue(talentDetails?.salary?.[1] / 1000);
      }
    }
  }, []);

  return (
    <Box
      sx={{
        p: 4,
        pb: 0,
        display: "flex",
        flexDirection: "column",
        gap: "16px",
      }}
    >
      <Typography sx={{ fontWeight: theme.typography.Bold }}>
        {i18n["myProfile.Thebasic"]}
      </Typography>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Box sx={{ width: "100%" }}>
          <InputLabel
            htmlFor="current_job_title"
            sx={{
              color: "black",
              paddingBottom: "2px",
              fontSize: "14px",
              fontWeight: theme?.typography?.Bold,
            }}
          >
            {i18n["myCV.currentJobTitleLabelReq"]}
          </InputLabel>
          <Box width="100%">
            <CustomSelect
              variant="createSelect"
              onInputChange={handleOnSelectInputChange}
              onChange={handleOnSelectChange}
              onCreateOption={onCreateOption}
              placeholder={i18n["myCV.currentJobTitle"]}
              inputId="current_job_title_id"
              name="current_job_title_id"
              value={
                talentDetails?.current_job_title_id?.label &&
                talentDetails?.current_job_title_id?.value
                  ? {
                      label: talentDetails?.current_job_title_id?.label
                        ? talentDetails?.current_job_title_id?.label
                        : "",
                      value: talentDetails?.current_job_title_id?.value
                        ? talentDetails?.current_job_title_id?.value
                        : "",
                    }
                  : null
              }
              onMenuOpen={onMenuOpen}
              onMenuClose={onMenuClose}
              // option={arr?.map((item)=>({value:item?.value,label:item?.label}))}
              option={titles?.map((item) => ({
                value: item?.job_title_id,
                label: item?.title,
              }))}
              isLoading={isLoading}
              customStyle={{
                menu: (provided) => ({
                  ...provided,
                  zIndex: 9999,
                }),
                menuList: (provided) => ({
                  ...provided,
                  "&::-webkit-scrollbar": {
                    width: "6px",
                    height: "6px",
                    borderRadius: "2.5px",
                    backgroundColor: "#efefef",
                  },
                  "&::-webkit-scrollbar-thumb": {
                    // backgroundColor: `${theme?.colors?.primary['400']}`,
                    borderRadius: "2.5px",
                  },
                }),
                option: (
                  styles,
                  { data, isDisabled, isFocused, isSelected }
                ) => {
                  return {
                    ...styles,
                    // backgroundColor: "",
                    color: isSelected ? "#fff" : "black",
                    ":active": {
                      ...styles[":active"],
                      // backgroundColor: "#d3d3d3",
                    },
                  };
                },
                control: (baseStyles) => ({
                  ...baseStyles,
                  boxShadow: "none",
                  borderRadius: "10px",
                  height: `40px`,
                  border: errors?.find(
                    (error) => error?.key === "current_job_title"
                  )
                    ? `1px solid ${theme?.jobCard?.jobStatus?.Rejected?.main}`
                    : "1px solid #E0E0E0",
                  "&:hover": errors?.find(
                    (error) => error?.key === "current_job_title"
                  )
                    ? `1px solid ${theme?.jobCard?.jobStatus?.Rejected?.main}`
                    : "1px solid #E0E0E0",
                  width: `100%`,
                }),
              }}
            />
          </Box>
        </Box>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-end",
          }}
        >
          <InputLabel
            htmlFor="dream_job_title_id"
            sx={{
              color: "black",
              paddingBottom: "2px",
              fontSize: "14px",
              fontWeight: theme?.typography?.Bold,
              width: "94%",
            }}
          >
            {i18n["myCV.dreamNextJobLabelReq"]}
          </InputLabel>
          <Box width="94%">
            <CustomSelect
              variant="createSelect"
              onInputChange={handleOnSelectInputChange}
              onChange={handleOnSelectChange}
              onCreateOption={onCreateOption}
              placeholder={i18n["myCV.dreamNextJobTitle"]}
              inputId="dream_job_title_id"
              name="dream_job_title_id"
              value={
                talentDetails?.dream_job_title_id?.label &&
                talentDetails?.dream_job_title_id?.value
                  ? {
                      label: talentDetails?.dream_job_title_id?.label
                        ? talentDetails?.dream_job_title_id?.label
                        : "",
                      value: talentDetails?.dream_job_title_id?.value
                        ? talentDetails?.dream_job_title_id?.value
                        : "",
                    }
                  : null
              }
              onMenuOpen={onMenuOpen}
              onMenuClose={onMenuClose}
              option={titles?.map((item) => ({
                value: item?.job_title_id,
                label: item?.title,
              }))}
              isLoading={isLoading}
              customStyle={{
                menu: (provided) => ({
                  ...provided,
                  zIndex: 9999,
                }),
                menuList: (provided) => ({
                  ...provided,
                  "&::-webkit-scrollbar": {
                    width: "6px",
                    height: "6px",
                    borderRadius: "2.5px",
                    backgroundColor: "#efefef",
                  },
                  "&::-webkit-scrollbar-thumb": {
                    borderRadius: "2.5px",
                  },
                }),
                option: (
                  styles,
                  { data, isDisabled, isFocused, isSelected }
                ) => {
                  return {
                    ...styles,
                    // backgroundColor: "",
                    color: isSelected ? "#fff" : "black",
                    ":active": {
                      ...styles[":active"],
                      // backgroundColor: "#d3d3d3",
                    },
                  };
                },
                control: (baseStyles) => ({
                  ...baseStyles,
                  boxShadow: "none",
                  borderRadius: "10px",
                  height: `40px`,

                  border: errors?.find(
                    (error) => error.key === "dream_job_title"
                  )
                    ? `1px solid ${theme?.jobCard?.jobStatus?.Rejected?.main}`
                    : "1px solid #E0E0E0",
                  "&:hover": errors?.find(
                    (error) => error?.key === "dream_job_title"
                  )
                    ? `1px solid ${theme?.jobCard?.jobStatus?.Rejected?.main}`
                    : "1px solid #E0E0E0",
                  width: "100%",
                  // 'borderColor': theme?.colors?.primary['400'],
                }),
              }}
            />
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Box sx={{ width: "100%" }}>
          <InputLabel
            htmlFor="employment_type"
            sx={{
              color: "black",
              paddingBottom: "2px",
              fontSize: "14px",
              fontWeight: theme?.typography?.Bold,
            }}
          >
            {i18n["myCV.preferedWorkTypeLabelReq"]}
          </InputLabel>
          <SelectMenu
            clear={true}
            border={
              errors?.find((error) => error?.key === "employment_type")
                ? `1px solid ${theme?.jobCard?.jobStatus?.Rejected?.main}`
                : "1px solid #E0E0E0"
            }
            borderRadius="10px"
            height="40px"
            width={"100%"}
            name="employment_type"
            value={talentDetails?.employment_type}
            onHandleChange={handleJobRoleChange}
            onFocus={() => {
              if (roleTypes?.length === 0) {
                getAllData("roleType");
              }
            }}
            options={roleTypes}
            placeholder={"Select the work type you'd prefer"}
          />
        </Box>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-end",
          }}
        >
          <InputLabel
            htmlFor="work_setup"
            sx={{
              color: "black",
              paddingBottom: "2px",
              fontSize: "14px",
              fontWeight: theme?.typography?.Bold,
              width: "94%",
            }}
          >
            {i18n["myCV.preferedWorkSetupReq"]}
          </InputLabel>
          <SelectMenu
            clear={true}
            border={
              // !talentDetails?.work_setup &&
              errors?.find((error) => error?.key === "work_setup")
                ? `1px solid ${theme?.jobCard?.jobStatus?.Rejected?.main}`
                : "1px solid #E0E0E0"
            }
            borderRadius="10px"
            // height={`${inputBoxHeight}px`}
            height="40px"
            width={"94%"}
            name="work_setup"
            value={talentDetails?.work_setup}
            onHandleChange={handleWorkSetupChange}
            onFocus={() => {
              if (workSetup?.length === 0) {
                getAllData("workSetup");
              }
            }}
            options={workSetup}
            placeholder={"Select the work setup you'd prefer"}
          />
        </Box>
      </Box>

      <Box sx={{ width: "100%" }}>
        <InputLabel
          htmlFor="industries"
          sx={{
            color: "black",
            paddingBottom: "2px",
            fontSize: "14px",
            fontWeight: theme?.typography?.Bold,
          }}
        >
          {i18n["myCV.industriesLabelReq"]}
        </InputLabel>

        <CustomSelect
          inputId={"industries_ids"}
          name={"industries_ids"}
          variant={"multiSelect"}
          closeMenuOnSelect={false}
          // defaultValue={[ { value: "chocolate", label: "Chocolate" }]}
          isMulti={true}
          multiSelectOptions={industries?.map((item) => ({
            value: item?.industry_id,
            label: item?.name,
          }))}
          placeholder={"Select your preferred industry(ies) (min 1, max 5)"}
          onChange={(e, val) => handleOnSelectChange(e, val)}
          customStyle={{
            option: (styles, { data, isDisabled, isFocused, isSelected }) => {
              return {
                ...styles,
                // backgroundColor: "",
                color: isSelected ? "#fff" : "black",
                ":active": {
                  ...styles[":active"],
                  // backgroundColor: "#d3d3d3",
                },
              };
            },
            control: (baseStyles) => ({
              ...baseStyles,
              boxShadow: "none",
              borderRadius: "10px",
              minHeight: `40px`,

              border:
                // getIndValue()?.length < 1 &&
                errors?.find((error) => error?.key === "industries_id")
                  ? `1px solid ${theme?.jobCard?.jobStatus?.Rejected?.main}`
                  : "1px solid #E0E0E0",
              "&:hover":
                // getIndValue()?.length < 1 &&
                errors?.find((error) => error?.key === "industries_id")
                  ? `1px solid ${theme?.jobCard?.jobStatus?.Rejected?.main}`
                  : "1px solid #E0E0E0",
              width: `100%`,
              // 'borderColor': theme?.colors?.primary['400'],
            }),
          }}
          onInputChange={handleOnSelectInputChange}
          multiOnFocus={() => {
            if (industries?.length === 0) {
              getAllData("industry");
            }
          }}
          value={
            talentDetails?.industries_ids?.length > 0
              ? talentDetails?.industries_ids
              : []
          }
          limitTags={5}
          initialOptions={initialIndustries?.map((item) => ({
            value: item?.industry_id,
            label: item?.name,
          }))}
        />
      </Box>

      <Box sx={{ width: "100%" }}>
        <InputLabel
          htmlFor="tags"
          sx={{
            color: "black",
            paddingBottom: "2px",
            fontSize: "14px",
            fontWeight: theme?.typography?.Bold,
          }}
        >
          {i18n["myCV.skillsLabelReq"]}
        </InputLabel>

        <CustomSelect
          inputId={"skill_ids"}
          name={"skill_ids"}
          variant={"createMultiSelect"}
          closeMenuOnSelect={false}
          isMulti={true}
          multiSelectOptions={skills?.map((item) => ({
            value: item?.tag_id,
            label: item?.name,
          }))}
          placeholder={"Enter your top skills (min 3, max 10)"}
          onChange={(e, val) => handleOnSelectChange(e, val)}
          customStyle={{
            option: (styles, { data, isDisabled, isFocused, isSelected }) => {
              return {
                ...styles,
                color: isSelected ? "#fff" : "black",
                ":active": {
                  ...styles[":active"],
                },
              };
            },
            control: (baseStyles) => ({
              ...baseStyles,
              boxShadow: "none",
              borderRadius: "10px",
              minHeight: `40px`,

              border:
                // getSkillValue()?.length < 3 &&
                errors?.find((error) => error?.key === "tags_id")
                  ? `1px solid ${theme?.jobCard?.jobStatus?.Rejected?.main}`
                  : "1px solid #E0E0E0",
              "&:hover":
                // getSkillValue()?.length < 3 &&
                errors?.find((error) => error?.key === "tags_id")
                  ? `1px solid ${theme?.jobCard?.jobStatus?.Rejected?.main}`
                  : "1px solid #E0E0E0",
              width: `100%`,
            }),
          }}
          onInputChange={handleOnSelectInputChange}
          multiOnFocus={() => {
            if (skills?.length === 0) {
              getAllData("skills");
            }
          }}
          value={
            talentDetails?.skill_ids?.length > 0 ? talentDetails?.skill_ids : []
          }
          limitTags={10}
          initialOptions={initialSkills?.map((item) => ({
            value: item?.tag_id,
            label: item?.name,
          }))}
        />
      </Box>

      <Box sx={{ width: "100%" }}>
        <InputLabel
          htmlFor="tools"
          sx={{
            color: "black",
            paddingBottom: "2px",
            fontSize: "14px",
            fontWeight: theme?.typography?.Bold,
          }}
        >
          {i18n["postAJob.toolsLableReq"]}
        </InputLabel>

        <CustomSelect
          inputId={"tool_ids"}
          name={"tool_ids"}
          variant={"createMultiSelect"}
          closeMenuOnSelect={false}
          isMulti={true}
          multiSelectOptions={tools?.map((item) => ({
            value: item?.tool_id,
            label: item?.name,
          }))}
          placeholder={
            "Enter the software tools you are most proficient in (min 3, max 10)"
          }
          onChange={(e, val) => handleOnSelectChange(e, val)}
          customStyle={{
            option: (styles, { data, isDisabled, isFocused, isSelected }) => {
              return {
                ...styles,
                color: isSelected ? "#fff" : "black",
                ":active": {
                  ...styles[":active"],
                },
              };
            },
            control: (baseStyles) => ({
              ...baseStyles,
              boxShadow: "none",
              borderRadius: "10px",
              minHeight: `40px`,

              border:
                // getToolValue()?.length < 3 &&
                errors?.find((error) => error?.key === "tools_id")
                  ? `1px solid ${theme?.jobCard?.jobStatus?.Rejected?.main}`
                  : "1px solid #E0E0E0",
              "&:hover":
                // getToolValue()?.length < 3 &&
                errors?.find((error) => error?.key === "tools_id")
                  ? `1px solid ${theme?.jobCard?.jobStatus?.Rejected?.main}`
                  : "1px solid #E0E0E0",
              width: `100%`,
            }),
          }}
          onInputChange={handleOnSelectInputChange}
          multiOnFocus={() => {
            if (tools?.length === 0) {
              getAllData("tools");
            }
          }}
          value={
            talentDetails?.tool_ids?.length > 0 ? talentDetails?.tool_ids : []
          }
          limitTags={10}
          initialOptions={initialTools?.map((item) => ({
            value: item?.tool_id,
            label: item?.name,
          }))}
        />
      </Box>

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Box sx={{ width: "100%" }}>
          <InputLabel
            htmlFor="qualification_level"
            sx={{
              color: "black",
              paddingBottom: "2px",
              fontSize: "14px",
              fontWeight: theme?.typography?.Bold,
            }}
          >
            {i18n["myCV.qualificationLevelLabelReq"]}
          </InputLabel>
          <SelectMenu
            clear={true}
            border={
              // !talentDetails?.qualification_level &&
              errors?.find((error) => error?.key === "qualification_level")
                ? `1px solid ${theme?.jobCard?.jobStatus?.Rejected?.main}`
                : "1px solid #E0E0E0"
            }
            borderRadius="10px"
            height="40px"
            width={"100%"}
            name="qualification_level"
            value={talentDetails?.qualification_level}
            onHandleChange={handleChange}
            onFocus={() => {
              if (qualifications?.length === 0) {
                getAllData("qualifications");
              }
            }}
            options={qualifications}
            placeholder={"Select the level of your highest qualification"}
          />
        </Box>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-end",
          }}
        >
          <InputLabel
            htmlFor="currency_id"
            sx={{
              color: "black",
              paddingBottom: "2px",
              fontSize: "14px",
              fontWeight: theme?.typography?.Bold,
              width: "94%",
            }}
          >
            {i18n["myCV.preferedCurrencyLabelReq"]}
          </InputLabel>
          <SelectMenu
            clear={true}
            border={
              // !talentDetails?.currency_id &&
              errors?.find((error) => error?.key === "currency_id")
                ? `1px solid ${theme?.jobCard?.jobStatus?.Rejected?.main}`
                : "1px solid #E0E0E0"
            }
            borderRadius="10px"
            height="40px"
            width={"94%"}
            name="currency_id"
            value={talentDetails?.currency_id}
            onHandleChange={handleChange}
            onFocus={() => {
              if (currency?.length === 0) {
                getAllData("currency");
              }
            }}
            options={currency}
            placeholder={"Select the currency you’d like to earn moola in"}
          />
        </Box>
      </Box>

      <Box sx={{ width: "100%" }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "30px",
            height: "41px",
          }}
        >
          <InputLabel
            htmlFor="salary"
            sx={{
              color: "black",
              fontSize: "14px",
              fontWeight: theme?.typography?.Bold,
            }}
          >
            {talentDetails?.employment_type === "freelance"
              ? i18n["myCV.requiredSalaryRangeLabel2"]
              : i18n["myCV.requiredSalaryRangeLabel"]}
          </InputLabel>
          <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
            <Typography
              sx={{ fontSize: "14px", fontWeight: theme.typography.Bold }}
            >
              single value
            </Typography>
            <BlueSwitch
              id="salary_value"
              checked={salaryRangeValue}
              onChange={(event) => handleSalaryValue(event)}
            />
            <Typography
              sx={{ fontSize: "14px", fontWeight: theme.typography.Bold }}
            >
              range value
            </Typography>
          </Box>
        </Box>
        {/*<Slider
          disableSwap
          sx={{
            width: "98%",
            ml: 1,
            marginTop: "30px",
            color: "transparent",
            "& .MuiSlider-rail": {
              backgroundColor: theme?.palette?.eyeview100?.main,
              height: "10px",
              border:
                rangeValue?.at(0) === 0 && rangeValue?.at(1) === 0
                  ? `1px solid ${theme?.jobCard?.jobStatus?.Rejected?.main}`
                  : "1px solid #E0E0E0",
            },
            "& .MuiSlider-track": {
              backgroundColor: theme?.palette?.salaryRange?.main,
              borderRadius: "3px",
              height: "10px",
            },
            "& .MuiSlider-thumb": {
              backgroundColor: theme?.palette?.salaryRange?.contrastText,
              borderRadius: "6px",
            },
            "& .MuiSlider-markLabel": {
              fontSize: "12px",
            },
            "& .MuiSlider-valueLabel.MuiSlider-valueLabelOpen": {
              backgroundColor: "#EBECF3",
            },
            "& .MuiSlider-valueLabel:before": {
              display: "none",
            },
            "& .MuiSlider-valueLabel": {
              color: "#000",
            },
          }}
          disabled={!talentDetails?.currency_id}
          name="salary"
          getAriaLabel={() => "Temperature range"}
          value={rangeValue}
          onChange={
            talentDetails?.employment_type === "freelance"
              ? handleRangeSliderChange2
              : handleRangeSliderChange
          }
          color="redButton100"
          valueLabelDisplay="on"
          valueLabelFormat={
            talentDetails?.employment_type === "freelance"
              ? rangeValueHandler2
              : rangeValueHandler
          }
          getAriaValueText={
            talentDetails?.employment_type === "freelance"
              ? rangeValueHandler2
              : rangeValueHandler
          }
          marks={salaryRange}
          // marks={
          //talentDetailsbasicData.employment_type == "freelance"
          //     ? BASIC_RANGEMARKS2
          //     : salaryRange
          // }
          min={0}
          max={salaryRange?.length - 1}
          step={0.1}
        />*/}

        <SalarySlider
          basicData={talentDetails}
          rangeValue={rangeValue}
          handleRangeSliderChange={handleRangeSliderChange}
          handleRangeSliderChange2={handleRangeSliderChange2}
          rangeValueHandler={rangeValueHandler}
          rangeValueHandler2={rangeValueHandler2}
          salaryRange={salaryRange}
          errors={errors}
          salaryRangeValue={salaryRangeValue}
          singleValueSlider={singleValueSlider}
          singleValueSlider2={singleValueSlider2}
          singleSalaryValue={singleSalaryValue}
          color={"redButton100"}
          name={"salary"}
          jobRoleType={talentDetails?.employment_type}
          sx={{
            width: "98%",
            ml: 1,
            marginTop: "30px",
            color: "transparent",
            "& .MuiSlider-rail": {
              backgroundColor: theme?.palette?.eyeview100?.main,
              height: "10px",
              border:
                rangeValue?.at(0) === 0 && rangeValue?.at(1) === 0
                  ? `1px solid ${theme?.jobCard?.jobStatus?.Rejected?.main}`
                  : "1px solid #E0E0E0",
            },
            "& .MuiSlider-track": {
              backgroundColor: theme?.palette?.salaryRange?.main,
              borderRadius: "3px",
              height: "10px",
            },
            "& .MuiSlider-thumb": {
              backgroundColor: theme?.palette?.salaryRange?.contrastText,
              borderRadius: "6px",
            },
            "& .MuiSlider-markLabel": {
              fontSize: "12px",
            },
            "& .MuiSlider-valueLabel.MuiSlider-valueLabelOpen": {
              backgroundColor: "#EBECF3",
            },
            "& .MuiSlider-valueLabel:before": {
              display: "none",
            },
            "& .MuiSlider-valueLabel": {
              color: "#000",
            },
          }}
        />
      </Box>

      <Box sx={{ width: "100%" }}>
        <InputLabel
          htmlFor="experience"
          sx={{
            height: "41px",
            color: "black",
            paddingBottom: "2px",
            fontSize: "14px",
            fontWeight: theme?.typography?.Bold,
          }}
        >
          {i18n["myCV.yearsOfExperienceLabel"]}
        </InputLabel>
        <Slider
          name="experience"
          aria-label="Custom marks"
          value={talentDetails?.experience}
          color="experience"
          getAriaValueText={textValue}
          min={0}
          max={20}
          step={1}
          onChange={expHandleChange}
          valueLabelDisplay="on"
          valueLabelFormat={textValue}
          marks={EXPERIENCE}
          sx={{
            width: "98%",
            marginTop: "30px",
            "& .MuiSlider-valueLabelLabel": {},
            "& .MuiSlider-rail": {
              backgroundColor: theme?.palette?.eyeview100?.main,
              height: "10px",
              border: errors?.find((error) => error?.key === "experience_id")
                ? `1px solid ${theme?.jobCard?.jobStatus?.Rejected?.main}`
                : "1px solid #E0E0E0",
            },
            "& .MuiSlider-track": {
              backgroundColor: theme?.palette?.experience?.main,
              borderRadius: "3px",
              height: "10px",
            },
            "& .MuiSlider-markLabel": {
              fontSize: "12px",
            },
            "& .MuiSlider-thumb": {
              backgroundColor: theme?.palette?.experience?.contrastText,
              borderRadius: "6px",
            },
            "& .MuiSlider-valueLabel.MuiSlider-valueLabelOpen": {
              backgroundColor: "#EBECF3",

              left: talentDetails?.experience_id === 0 ? "0px !important" : "",
              right:
                talentDetails?.experience_id === 20 ? "0px !important" : "",
            },
            "& .MuiSlider-valueLabel:before": {
              display: "none",
            },
            "& .MuiSlider-valueLabel": {
              color: "#000",
            },
          }}
        />
      </Box>

      <Box sx={{ width: "100%" }}>
        <InputLabel
          htmlFor="notice_period_id"
          sx={{
            height: "41px",
            color: "black",
            paddingBottom: "2px",
            fontSize: "14px",
            fontWeight: theme.typography.Bold,
          }}
        >
          {i18n["myCV.noticePeriodLabel"]}
        </InputLabel>
        <Slider
          name="notice_period_id"
          aria-label="Custom marks"
          value={talentDetails?.notice_period_id}
          color="noticePeriod"
          getAriaValueText={noticeValue}
          onChange={noticeHandleChange}
          valueLabelDisplay="on"
          valueLabelFormat={noticeValue}
          marks={NOTICEPERIOD}
          min={0}
          max={7}
          step={1}
          sx={{
            width: "98%",
            marginTop: "30px",
            "& .MuiSlider-valueLabelLabel": {},
            "& .MuiSlider-rail": {
              backgroundColor: theme.palette.eyeview100.main,
              height: "10px",
              border: errors?.find((error) => error?.key === "notice_period_id")
                ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
                : "1px solid #E0E0E0",
            },

            "& .MuiSlider-track": {
              backgroundColor: theme?.palette?.noticePeriod?.main,
              borderRadius: "3px",
              height: "10px",
            },
            "& .MuiSlider-thumb": {
              backgroundColor: theme?.palette?.noticePeriod?.contrastText,
              borderRadius: "6px",
            },
            "& .MuiSlider-valueLabel.MuiSlider-valueLabelOpen": {
              backgroundColor: "#EBECF3",
              left:
                talentDetails?.notice_period_id === 0 ||
                talentDetails?.notice_period_id === null
                  ? "0px !important"
                  : "",
              right:
                talentDetails?.notice_period_id === 7 ? "0px !important" : "",
            },
            "& .MuiSlider-valueLabel:before": {
              display: "none",
            },
            "& .MuiSlider-valueLabel": {
              color: "#000",
            },
            "& .MuiSlider-markLabel": {
              fontSize: "12px",
              textWrap: " wrap",
              width: "70px",
              textAlign: "center",
            },
          }}
        />
      </Box>
    </Box>
  );
}
