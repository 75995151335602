import { Box, Button, IconButton, Input, Typography } from "@mui/material";
import chat from "../../../assets/JMD/MessageIcon.svg";
import Video from "../../../assets/JMD/VideoIconGreyOrange.svg";
import ClientInterview from "../../../assets/JMD/ClientInterviewIconOrange.svg";
import SVGButton from "../SVGButton";
import { useDispatch } from "react-redux";
import {
  adminInterview,
  updateCalendarLink,
} from "../../../redux/employer/myJobsSlice";
import { setAlert } from "../../../redux/configSlice";
import { ALERT_TYPE } from "../../../utils/Constants";
import locale from "../../../i18n/locale";
import CloseIcon from "../CloseIcon";
import { useEffect, useState } from "react";
import { useTheme } from "@emotion/react";

export default function ChatBox({ handleOpen, jobDetail, userID,calendar_link, job_id ,setJobDetail }) {
  const dispatch = useDispatch();
  const i18n = locale.en;
  const [buttonClickedNamed, setButtonClickedName] = useState("");
  const [urlLink, setUrlLink] = useState();
  const [confirmationFalseUrl, setConfirmationFalseUrl] = useState(false);
  const [showUpdateLinkTab, setShowUpdateLinkTab] = useState(false);
  const [updateLinkConfiramTab, setUpdateLinkConfirmTab] = useState(false);
  const [showNoUpdateLinkTab, setShowNoUpdateLinkTab] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const theme = useTheme();

  const interview = async (name, type) => {
    const data = {
      job_id: jobDetail?.job_id,
      status: name,
      candidate_id: userID,
    };
    try {
      const { payload } = await dispatch(adminInterview(data));
      if (payload?.status === "success") {
        if(name === "crayonInterview"){
            setButtonClickedName("crayonInterview")
        }else{
          if(type){
            setShowNoUpdateLinkTab(false)
            setConfirmationFalseUrl(true)
          }else{
            setButtonClickedName("clientInterview");
            setUrlLink(true)
          }
        }
        // dispatch(
        //   setAlert({
        //     show: true,
        //     type: ALERT_TYPE?.SUCCESS,
        //     msg: i18n["alertMessage.emailsent"],
        //   })
        // );
      }
    } catch (error) {}
  };

  const handleCrayonInterview = () => {
    handleOpen();
    setButtonClickedName("");
  };
  const handleSendAnyway = () => {
    // setConfirmationFalseUrl(true);
    interview("clientInterview", "noUrl");
  };
  const handleupdateLink = () => {
    setShowNoUpdateLinkTab(false)
    setShowUpdateLinkTab(true);
  };
  const handeUpdateAndSend = () => {
    setUpdateLinkConfirmTab(true);
  };
  const handleBack = () => {
    setShowUpdateLinkTab(false)
    setShowNoUpdateLinkTab(true)
  };
  const handleupdateAndSend = async () => {
    const payload = {
      job_id: job_id,
      link: inputValue,
    };
    try {
      const response = await dispatch(updateCalendarLink(payload));
      if (response?.payload?.status === "success") {
        setShowUpdateLinkTab(false)
        setUpdateLinkConfirmTab(true)
        setJobDetail((prev)=>({...prev, calendar_link:inputValue}))
      }
    } catch (error) {
      console.log(error, "error");
    }
  };
  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };
  return (
    <Box
      sx={{
        padding: "10px",
        paddingBottom: buttonClickedNamed ? "0px" : "10px",
        position: "relative", // Ensure the container is positioned correctly
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography
          sx={{
            fontWeight: 700,
            fontSize: "14px",
          }}
        >
          {buttonClickedNamed ? "Client interview" : "Let's connect"}
        </Typography>
        {/* <IconButton
          aria-label="close"
          onClick={() => {
            handleOpen();
            setButtonClickedName("");
          }}
          sx={{
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton> */}
        <Box
          sx={{
            cursor: "pointer",
          }}
          onClick={() => {
            handleOpen();
            setButtonClickedName("");
          }}
        >
          <CloseIcon />
        </Box>
      </Box>
      {buttonClickedNamed === "" ? (
        <>
          <Box sx={{ display: "flex", pt: 1, cursor: "pointer" }}>
            <SVGButton
              color={"white"}
              source={chat}
              height={35}
              width={35}
              startIconMargin={"0px !important"}
              padding={"0px !important"}
              cursor={"pointer"}
            />
            <Typography
              sx={{
                fontWeight: 700,
                fontSize: "14px",
                marginLeft: "16px !important",
                margin: "auto",
              }}
            >
              Start a chat
            </Typography>
          </Box>
          <Box
            sx={{ display: "flex", pt: 1, cursor: "pointer" }}
            onClick={() => {
              interview("crayonInterview");
              // setButtonClickedName("crayonInterview");
            }}
          >
            <SVGButton
              color={"white"}
              source={Video}
              height={35}
              width={35}
              startIconMargin={"0px !important"}
              padding={"0px !important"}
              cursor={"pointer"}
            />
            <Typography
              sx={{
                fontWeight: 700,
                fontSize: "14px",
                marginLeft: "16px !important",
                margin: "auto",
              }}
            >
              Request a Crayon interview
            </Typography>
          </Box>
          <Box
            sx={{ display: "flex", pt: 1, cursor: "pointer" }}
            onClick={() => {
              if(calendar_link){
                interview("clientInterview");
              }
              else{
                setButtonClickedName("clientInterview")
                setShowNoUpdateLinkTab(true)
              }
            }}
          >
            <SVGButton
              color={"white"}
              source={ClientInterview}
              height={35}
              width={35}
              startIconMargin={"0px !important"}
              padding={"0px !important"}
              cursor={"pointer"}
            />
            <Typography
              sx={{
                fontWeight: 700,
                fontSize: "14px",
                marginLeft: "16px !important",
                margin: "auto",
              }}
            >
              {jobDetail?.job_type === "Recruit"
                ? "Request a client interview"
                : "Request an interview"}
            </Typography>
          </Box>
        </>
      ) : buttonClickedNamed === "crayonInterview" ? (
        <Box>
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: theme.typography.Regular,
              marginBottom: "5px",
              marginTop: "10px",
            }}
          >
            An invite has been sent for this candidate to schedule a recorded
            interview with the assigned Talent Partner.
          </Typography>
          <Typography
            sx={{ fontSize: "14px", fontWeight: theme.typography.Regular }}
          >
            You can view the recording under the video section when ready.
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              marginTop: "10px",
            }}
          >
            <Button
              variant="contained"
              color="nandos"
              sx={{
                fontSize: "12px",
                fontWeight: theme.typography.Bold,
                borderRadius: "10px 10px 0 0",
                padding: "6px 6px",
                height: "30px",
                width: "100px",
              }}
              onClick={handleCrayonInterview}
            >
              sounds good
            </Button>
          </Box>
        </Box>
      ) : buttonClickedNamed === "clientInterview" ? (
        <Box>
          {urlLink && (
            <>
              <Typography
                sx={{
                  fontSize: "14px",
                  fontWeight: theme.typography.Regular,
                  marginBottom: "5px",
                  marginTop: "10px",
                }}
              >
                An invite has been sent for this candidate to schedule some time
                with you using your provided calendar scheduling link.
              </Typography>
              <Typography
                sx={{ fontSize: "14px", fontWeight: theme.typography.Regular }}
              >
                Keep an eye out for the system-generated email and meeting in
                your diary.
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "10px",
                }}
              >
                <Button
                  variant="contained"
                  color="nandos"
                  sx={{
                    fontSize: "12px",
                    fontWeight: theme.typography.Bold,
                    borderRadius: "10px 10px 0 0",
                    padding: "6px 6px",
                    height: "30px",
                    width: "100px",
                  }}
                  onClick={handleCrayonInterview}
                >
                  sounds good
                </Button>
              </Box>
            </>
          )}
          {showNoUpdateLinkTab && (
            <>
              <Typography
                sx={{
                  fontSize: "14px",
                  fontWeight: theme.typography.Regular,
                  marginBottom: "5px",
                  marginTop: "10px",
                }}
              >
                 Whoops, it doesn’t look like you’ve added a calendar scheduling link yet. The email will be sent to the candidate without a booking link.
              </Typography>
              <Typography
                sx={{ fontSize: "14px", fontWeight: theme.typography.Regular }}
              >
                Do you want to add a scheduling link to your profile now or send without a link?
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "10px",
                }}
              >
                <Button
                  variant="contained"
                  color="grayButton700"
                  sx={{
                    fontSize: "12px",
                    fontWeight: theme.typography.Bold,
                    borderRadius: "10px 0px 0 0",
                    padding: "6px 6px",
                    height: "30px",
                    width: "100px",
                    color: "black",
                  }}
                  onClick={handleSendAnyway}
                >
                  send anyway
                </Button>
                <Button
                  variant="contained"
                  color="nandos"
                  sx={{
                    fontSize: "12px",
                    fontWeight: theme.typography.Bold,
                    borderRadius: "0px 10px 0 0",
                    padding: "6px 6px",
                    height: "30px",
                    width: "100px",
                  }}
                  onClick={handleupdateLink}
                >
                  update link
                </Button>
              </Box>
            </>
          )}
          {confirmationFalseUrl && (
            <>
              <Typography
                sx={{
                  fontSize: "14px",
                  fontWeight: theme.typography.Regular,
                  marginBottom: "5px",
                  marginTop: "10px",
                }}
              >
                An request has been sent to this candidate to connect with you
                to setup some for an interview.
              </Typography>
              <Typography
                sx={{ fontSize: "14px", fontWeight: theme.typography.Regular }}
              >
                Keep an eye out for the system-generated email as you were cc’ed
                in this request.
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "10px",
                }}
              >
                <Button
                  variant="contained"
                  color="nandos"
                  sx={{
                    fontSize: "12px",
                    fontWeight: theme.typography.Bold,
                    borderRadius: "10px 10px 0 0",
                    padding: "6px 6px",
                    height: "30px",
                    width: "100px",
                  }}
                  onClick={handleCrayonInterview}
                >
                  sounds good
                </Button>
              </Box>
            </>
          )}
          {updateLinkConfiramTab && (
            <>
              <Typography
                sx={{
                  fontSize: "14px",
                  fontWeight: theme.typography.Regular,
                  marginBottom: "5px",
                  marginTop: "10px",
                }}
              >
                An invite has been sent for this candidate to schedule some time
                with you using your provided calendar scheduling link.
              </Typography>
              <Typography
                sx={{ fontSize: "14px", fontWeight: theme.typography.Regular }}
              >
                Keep an eye out for the system-generated email and meeting in
                your diary.
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "10px",
                }}
              >
                <Button
                  variant="contained"
                  color="nandos"
                  sx={{
                    fontSize: "12px",
                    fontWeight: theme.typography.Bold,
                    borderRadius: "10px 10px 0 0",
                    padding: "6px 6px",
                    height: "30px",
                    width: "100px",
                  }}
                  onClick={handleCrayonInterview}
                >
                  sounds good
                </Button>
              </Box>
            </>
          )}
          {showUpdateLinkTab && (
            <>
              <Typography
                sx={{
                  fontSize: "14px",
                  fontWeight: theme.typography.Regular,
                  marginBottom: "10px",
                  marginTop: "10px",
                }}
              >
                Add a scheduling link to your profile below:
              </Typography>
              <input
                type="text"
                placeholder="Enter a scheduling link, e.g. Calendly, etc."
                style={{
                  height: "32px",
                  borderRadius: "10px",
                  border: "1px solid #C9CAD8",
                  width: "90%",
                  outline: "none",
                }}
                value={inputValue}
                onChange={(e) => handleInputChange(e)}
              />
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "15px",
                }}
              >
                <Button
                  variant="contained"
                  color="grayButton700"
                  sx={{
                    fontSize: "12px",
                    fontWeight: theme.typography.Bold,
                    borderRadius: "10px 0px 0 0",
                    padding: "6px 6px",
                    height: "30px",
                    width: "100px",
                    color: "black",
                  }}
                  onClick={handleBack}
                >
                  back
                </Button>
                <Button
                  variant="contained"
                  color="nandos"
                  sx={{
                    fontSize: "12px",
                    fontWeight: theme.typography.Bold,
                    borderRadius: "0px 10px 0 0",
                    padding: "6px 0px",
                    height: "30px",
                    width: "100px",
                  }}
                  onClick={handleupdateAndSend}
                >
                  update & send
                </Button>
              </Box>
            </>
          )}
        </Box>
      ) : (
        ""
      )}
    </Box>
  );
}
