import { Box, Typography, useTheme } from "@mui/material";
import React from "react";
import CustomDialog from "../../../common/CustomDialog";
import CloseIcon from "../../../common/CloseIcon";

export default function JobVideoStepBrief({ show, handleOpen, content }) {
  const theme = useTheme();
  return (
    <CustomDialog
      show={show}
      hideButton={false}
      dialogWidth="xs"
      showFooter={false}
      onDialogClose={handleOpen}
      padding={0}
    >
      <CloseIcon onClick={handleOpen}/>

      <Box
        sx={{
          flexGrow: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: "30px",
          flexDirection: "column",
          gap: "30px",
        }}
      >
        <Typography
          sx={{
            fontSize: "14px",
            textAlign: "center",
            fontWeight: theme.typography.Regular,
          }}
        >
          {content ? content : "No brief added for this particular job."}
          {/* {" "}
          Give us your elevator pitch! Record or upload a short intro video,
          highlighting your experience, skills, preferred bag of tools and what
          your ideal next career steps might look like.{" "} */}
        </Typography>
        {/* <Typography
          sx={{
            fontSize: "14px",
            textAlign: "center",
            fontWeight: theme.typography.Regular,
          }}
        >
          Completing your profile with a video increases your chances with
          employers and is often the first thing looked at!
        </Typography> */}
      </Box>
    </CustomDialog>
  );
}
