import {
  Box,
  CircularProgress,
  InputBase,
  Paper,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { debounce } from "../../../../../utils/Common";
import { setAlert } from "../../../../../redux/configSlice";
import { ALERT_TYPE, ERROR_MSG } from "../../../../../utils/Constants";
import { companyJobsList } from "../../../../../redux/admin/jobsSlice";
import InfiniteScroll from "react-infinite-scroll-component";
import Ozow from "../../../../../assets/Crayon_Favicon.svg";
import { Link } from "react-router-dom";

export default function JobsBox({ companyData }) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [lastKey, setLastKey] = useState(0);
  const [jobsList, setJobsList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [searchText, setSearchText] = useState("");

  const getJobList = async (lastkeyy, text) => {
    setIsLoading(true);
    const data = {
      lastKey: lastkeyy,
      status_id: "",
      job_stage: "",
      job_title: text,
      job_type: "",
      team_member_user_id: "",
      company_id: companyData?.company_id,
      job_id: "",
    };
    const { payload } = await dispatch(companyJobsList(data));
    if (payload?.status === "success") {
      if (payload?.data?.length <= 0 && payload?.pageNumber === 0) {
        setJobsList([]);
      } else {
        if (payload?.pageNumber === 0) {
          setLastKey(payload?.pageNumber + 1);
          setJobsList(payload?.data);
        } else {
          setLastKey(payload?.pageNumber + 1);
          setJobsList((prevState) => [...prevState, ...payload?.data]);
        }
      }
    } else if (payload === undefined) {
      return;
    } else {
      dispatch(
        setAlert({
          show: true,
          type: ALERT_TYPE?.ERROR,
          msg: "Something went wrong! please relaod the window",
        })
      );
    }
    setIsLoading(false);
  };

  const debouncedHandleOnTextSearch = useCallback(
    debounce(
      async (newValue) => {
        if (!newValue) return; // Early return
        try {
          const response = await getJobList(0, newValue?.target?.value);
          //   setSearchTitle(newValue?.target?.value);
          if (response && response?.payload) {
            const { payload } = response;
            dispatch(
              payload?.status === "success"
                ? setJobsList(payload?.data)
                : setAlert({
                    show: true,
                    type: ALERT_TYPE?.ERROR,
                    msg: "Error",
                  })
            );
          }
        } catch (error) {
          dispatch(
            setAlert({ show: true, type: ALERT_TYPE?.ERROR, msg: ERROR_MSG })
          );
        }
      },
      1000, // Adjust delay as needed
      setIsLoading
    ),
    [dispatch, setJobsList, setIsLoading]
  );

  const handleSearch = async (event) => {
    setLastKey(0);
    setSearchText(event?.target?.value);
    setJobsList([]);
    debouncedHandleOnTextSearch(event);
  };

  useEffect(() => {
    getJobList(0, "");
  }, []);

  return (
    <Box sx={{ padding: "16px 24px 17px 18px" }}>
      <Typography
        sx={{ fontSize: "12px", fontWeight: theme.typography.regular }}
      >
        Select or search for a job you’d like to explore.
      </Typography>

      <Paper
        elevation={0}
        component="form"
        sx={{
          padding: "0px 10px",
          border: "1px solid #C9CAD8",
          margin: "13px 0 17px 0",
        }}
        height={"40px"}
        width={"392px"}
      >
        <InputBase
          sx={{
            fontWeight: theme.typography.Regular,
            width: "392px",
          }}
          placeholder={"Enter job title"}
          // value={searchText}
          onKeyDown={(event) => {
            if (event.key === "Enter") {
              event.preventDefault(); // Stop page reload
            }
          }}
          onChange={(event) => {
            handleSearch(event);
          }}
        />
      </Paper>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          overflowY: "auto",
          height: "405px",
          "::-webkit-scrollbar": {
            height: "5px",
            background: "#DCDEEB",
            display: "unset !important",
          },
        }}
        id="matchTaleneList"
      >
        <InfiniteScroll
          style={{
            overflowX: "hidden",
            scrollbarWidth: "thin",
            display: "flex",
            flexDirection: "column",
            gap: "10px",
          }}
          dataLength={jobsList?.length}
          next={() => getJobList(lastKey, searchText)}
          hasMore={true}
          scrollableTarget="matchTaleneList"
          scrollThreshold={"50px"}
        >
          {jobsList?.length > 0 ? (
            jobsList?.map((item, index) => {
              return (
                <Box sx={{ display: "flex", mt: 2 }}>
                  <Box
                    component="img"
                    className="profileAvatar"
                    alt="crayon logo"
                    src={
                      item?.companyURL !== "No URL" ? item?.companyURL : Ozow
                    }
                    sx={{
                      mr: 1,
                      height: "35px !important",
                      width: "35px !important",
                      borderRadius: "5px",
                    }}
                  />
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <Link
                      to={`/admin/job-detail/${`${
                        item?.town_name?.replace("/", "") +
                        " " +
                        item?.region_name?.replace("/", "")
                      }`}/${item?.job_id}`}
                      target={"_blank"}
                      style={{
                        textDecoration: "none",
                        color: theme.palette.black,
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "12px",
                          fontWeight: 700,
                          cursor: "pointer",
                          ml: "5px",
                        }}
                        key={index}
                      >
                        {item?.title}
                      </Typography>
                    </Link>
                  </Box>
                </Box>
              );
            })
          ) : isLoading ? (
            <Box
              style={{
                margin: "15px 0 0 0",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CircularProgress color="inherit" size={20} />
            </Box>
          ) : (
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <Typography>No Jobs</Typography>
            </Box>
          )}
        </InfiniteScroll>
      </Box>
    </Box>
  );
}
