import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useState } from "react";
import upArrow from "../../../../assets/Black_Up_Close - Copy.svg";
import downArrow from "../../../../assets/Black_Down_Open - Copy.svg";
import { TableCells } from "../../../../utils/CommonComponent";
import TalentSVGButton from "../../../common/TalentSVGButton";
import editIcon from "../../../../assets/Padding Included/Yellow_Edit.svg";
import InfiniteScroll from "react-infinite-scroll-component";
import AddEditAssessmentScoreModal from "./AddEditAssessmentScoreModal";
import { useDispatch } from "react-redux";
import { editAssessmentScore } from "../../../../redux/candidate/myStatusFilter";
import { setLoading } from "../../../../redux/configSlice";

const ScoreItemsModal = ({
  spendList,
  getScore,
  viewMoreExpanded,
  setViewMoreExpanded,
}) => {
  const theme = useTheme();
  const [selectedScoreItem, setSelectedScoreItem] = useState({});
  const [isScoreModal, setIsScoreModal] = useState(false);
  const dispatch = useDispatch();
  const [formData, setFormData] = useState();
  const [isEdit, setIsEdit] = useState(false);

  const handleViewMore = () => {
    setViewMoreExpanded((prevState) => !prevState);
  };

  const handleOpenAddSpendModal = (row) => {
    setIsScoreModal(true);
    setSelectedScoreItem(row);
  };
  const handleFormInput = (e) => {};

  const handleCancel = () => {
    setIsScoreModal(false);
    setIsEdit(false);
  };
  const editScore = async () => {
    dispatch(setLoading({ value: true, apiName: "editScore" }));

    let payload = {
      score_id: selectedScoreItem?.score_id,
      name: formData?.name,
      value:Number(formData?.score)
    };
    try {
      let response = await dispatch(editAssessmentScore(payload));
      if (response?.payload?.data?.status === "success") {
        getScore();
      }
      dispatch(setLoading({ value: false, apiName: "editScore" }));
    } catch (error) {
      dispatch(setLoading({ value: false, apiName: "editScore" }));
    }
  };
  const handleCreate = () => {
    editScore();
    setIsScoreModal(false);
    setIsEdit(false);
  };
  const handleClose = () => {
    setIsScoreModal(false);
    setIsEdit(false);
  };

  return (
    <>
      <Box
        sx={{
          width: "400px",
          minHeight: "101px",
          background: "white",
          borderRadius: "25px",
          boxShadow: `0px 5px 10px ${theme?.palette?.boxShadow?.main}`,
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Button
            variant="contained"
            color="redButton"
            sx={{
              height: "45px",
              width: "150px",
              borderRadius: "25px 0 25px 0",
            }}
          >
            score items
          </Button>
        </Box>
        {viewMoreExpanded && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "15px",
              width: "95%",
              mt: "8px",
              overflowY: "auto",
              // height: "360px",
              // height: "360px",
              "::-webkit-scrollbar": {
                height: "5px",
                background: "#DCDEEB",
                display: "unset !important",
              },
            }}
            id="matchTaleneList"
          >
            <InfiniteScroll
              style={{
                overflowX: "hidden",
                scrollbarWidth: "thin",
                display: "flex",
                flexDirection: "column",
                gap: "16px",
                width: "100%",
              }}
              dataLength={spendList?.length}
              //   next={() => getAssociations(lastKey, searchTitle)}
              scrollThreshold={"100px"}
              scrollableTarget={"matchTaleneList"}
              hasMore={true}
              endMessage={
                <p style={{ textAlign: "center" }}>
                  <b>Yay! You have seen it all</b>
                </p>
              }
            >
              <TableContainer
                component={Paper}
                sx={{
                  boxShadow: "none !important",
                  borderRadius: "0px !important",
                  height: "300px",
                }}
              >
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCells>
                        <Typography
                          variant="subtitle1"
                          fontWeight="bold"
                          textAlign={"center"}
                        >
                          Item
                        </Typography>
                      </TableCells>
                      <TableCells>
                        <Typography
                          variant="subtitle1"
                          fontWeight="bold"
                          textAlign={"center"}
                        >
                          Text options
                        </Typography>
                      </TableCells>

                      <TableCells>
                        <Typography
                          variant="subtitle1"
                          fontWeight="bold"
                          textAlign={"center"}
                        >
                          Value
                        </Typography>
                      </TableCells>
                      <TableCells></TableCells>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {spendList?.map((row, index) => (
                      <TableRow
                        key={row?.id}
                        style={{
                          "& .css-12zgwp-MuiTableCell-root": {
                            padding: "0 16px !important",
                          },
                        }}
                      >
                        <TableCells
                          sx={{ padding: "0px 18px", textAlign: "center" }}
                        >
                          Score item {row?.score_id}
                        </TableCells>
                        <TableCells
                          sx={{ padding: "0px 18px", textAlign: "center" }}
                        >
                          {row?.name}
                        </TableCells>
                        <TableCells
                          sx={{ padding: "0px 18px", textAlign: "center" }}
                        >
                          {row?.value}
                        </TableCells>
                        <TableCells
                          sx={{ padding: "0px 18px", textAlign: "center" }}
                        >
                          <Box sx={{ display: "flex", gap: "8px" }}>
                            <TalentSVGButton
                              onClick={() => {
                                handleOpenAddSpendModal(row);
                                setIsEdit(true);
                              }}
                              color={"white"}
                              source={editIcon}
                              height={26}
                              width={26}
                              padding={"0px !important"}
                              startIconMargin={"0px !important"}
                            />
                          </Box>
                        </TableCells>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </InfiniteScroll>
          </Box>
        )}
        <Button
          sx={{
            color: "black",
            padding: 0,
            width: "100%",
            height: !viewMoreExpanded ? `calc(100% - 45px)` : "45px",
            fontSize: "16px !important",
            fontWeight: theme.typography.Bold,
            alignItems: "flex-end",
            marginTop: !viewMoreExpanded ? "28px" : "",
            paddingBottom: "16px",
            ".MuiButton-startIcon": {
              marginRight: "0px !important",
              marginLeft: "-18px !important",
            },
          }}
          endIcon={
            <Box
              component="img"
              sx={{
                height: 30,
                width: 30,
                cursor: "pointer",
              }}
              alt="crayon logo"
              src={viewMoreExpanded ? upArrow : downArrow}
            />
          }
          onClick={handleViewMore}
        >
          {viewMoreExpanded ? "Close" : "View items"}
        </Button>
      </Box>
      {isScoreModal && (
        <AddEditAssessmentScoreModal
          handleOpen={isScoreModal}
          handleClose={handleClose}
          handleCreate={handleCreate}
          handleCancel={handleCancel}
          isEdit={isEdit}
          selectedScoreItem={selectedScoreItem}
          formData={formData}
          setFormData={setFormData}
          handleFormInput={handleFormInput}
        />
      )}
    </>
  );
};

export default ScoreItemsModal;
