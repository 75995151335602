import { Box, Button, Typography, useTheme } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import upArrow from "../../../assets/Black_Up_Close - Copy.svg";
import downArrow from "../../../assets/Black_Down_Open - Copy.svg";
import flip from "../../../assets/NewFlipIcon.svg";

export default function CompanyCultureFrontCard({
  compInfo,
  setisHorizontalFlipped,
}) {
  const theme = useTheme();
  const typographyRef = useRef(null);
  const [viewMoreExpanded, setViewMoreExpanded] = useState(false);

  const [isOverflowing, setIsOverflowing] = useState(false);

  const handleViewMore = () => {
    setViewMoreExpanded((prevState) => !prevState);
  };

  useEffect(() => {
    const typographyElement = typographyRef?.current;

    if (typographyElement) {
      const fullHeight = typographyElement?.scrollHeight;
      const lineHeight = 20;
      const clampedHeight = lineHeight * 5;

      if (fullHeight > clampedHeight) {
        setIsOverflowing(true);
      }
    }
  }, [compInfo?.notes]);

  return (
    <>
      <Box
        sx={{
          minHeight: "225px",
          height: "auto",
          // width: { xs: "360px", lg: "750px", xl: "750px" },
          background: "#ffff",
          boxShadow: "0px 5px 10px #00000029",
          borderRadius: "25px",
          flex: "1",
        }}
      >
        <Box>
          <Button
            variant="contained"
            color="boxTitleButton"
            sx={{
              height: "45px",
              width: "150px",
              fontSize: "14px",
              borderRadius: "25px 0px 10px 0px",
              padding: "0px !important",
              cursor: "default",
              ":hover": {
                boxShadow: "none !important",
              },
            }}
          >
            company culture
          </Button>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", xl: "row" },
            marginTop: "8px",
          }}
        >
          <Box
            sx={{
              display: { xs: "flex" },
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
              //   gap: "10px",
              width: {
                xs360: "90%",
                xs390: "87%",
                sm: "100%",
                lgmm: "50%",
                lg: "100% !important",
              },
              minWidth: {
                xs360: "90%",
                xs390: "87%",
                sm: "100%",
                lgmm: "50%",
                lg: "100% !important",
              },
              //   padding: {
              //     xs: "8px 0 30px 12px",
              //     lgmm: "8px 0 30px 12px",
              //     mlg: "8px 0 30px 12px",
              //     xl: "8px 0 30px 12px",
              //   },
              boxSizing: "border-box",
            }}
          >
            <Typography
              sx={{
                fontSize: "14px",
                fontWeight: theme.typography.Bold,
                width: `calc(100% - 44px)`,
              }}
            >
              Company bio
            </Typography>

            <Typography
              ref={typographyRef}
              sx={{
                fontSize: "12px",
                fontWeight: theme.typography.Regular,
                overflow: viewMoreExpanded ? "scroll" : "hidden",
                display: "-webkit-box",
                WebkitLineClamp: 5,
                WebkitBoxOrient: "vertical",
                width: `calc(100% - 44px)`,
                height: "auto",
                lineHeight: "20px",
              }}
            >
              {compInfo?.notes}
            </Typography>
            {isOverflowing && (
              <Box sx={{ width: `calc(100% - 44px)` }}>
                <Button
                  sx={{
                    color: "black",
                    padding: 0,
                    fontSize: "12px !important",
                    fontWeight: theme.typography.Bold,
                    ".MuiButton-startIcon": {
                      marginRight: "0px !important",
                      marginLeft: "-18px !important",
                    },
                    height: "30px",
                  }}
                  startIcon={
                    <Box
                      component="img"
                      sx={{
                        height: 30,
                        width: 30,
                        cursor: "pointer",
                      }}
                      alt="crayon logo"
                      src={viewMoreExpanded ? upArrow : downArrow}
                    />
                  }
                  onClick={handleViewMore}
                >
                  {viewMoreExpanded ? "less" : "more"}
                </Button>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
      <Button
        variant="contained"
        color="redButton100"
        sx={{
          width: "20px",
          height: "100px",
          padding: 0,
          position: "absolute",
          right: 0,
          top: `calc(50% - 50px)`,
          minWidth: "20px",
          fontSize: "20px",
          borderRadius: "10px 0 0 10px",
        }}
        // onClick={() => setisHorizontalFlipped(true)}
        onClick={() => setisHorizontalFlipped((prev) => !prev)}
      >
        <Box
          component={"img"}
          src={flip}
          sx={{
            height: "20px",
            width: "15px",
          }}
        />
      </Button>
    </>
  );
}
