import { Box, Button, Typography, useTheme } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import jobVideoHeaderLogo from "../../../../assets/Padding Excluded/White_jobVideo.svg";
import locale from "../../../../i18n/locale";
import { useDispatch } from "react-redux";
import { setAlert } from "../../../../redux/configSlice";
import { ALERT_TYPE } from "../../../../utils/Constants";
import videoRecordedIcon from "../../../../assets/Cam/JobVideoIcon.svg"
import myVideoHeaderLogo from "../../../../assets/Cam/MyVideoIcon.svg";
import stopRecording from "../../../../assets/Cam/StopRecordingIcon.svg";
import CloseIcon from "../../CloseIcon";

const MAX_VIDEO_LENGTH_MS = 10000; // 1 minute in milliseconds
const MAX_VIDEO_SIZE_BYTES = 100 * 1024 * 1024; // 100 MB in bytes

export default function RecordingInProgress({
  nextStep,
  onRecordingStop,
  uploadFunc,
  name,
  handleStopRecording,
  handleCloseCamera,
  setMediaStream,
  mediaStream,
  mediaRecorderRef,
  setRecordedVideoData,
  setVideoBlob,
  setTextChange,
  page
}) {
  const mediaStreamRef = useRef(null);
  const i18n = locale.en;
  const theme = useTheme();
  const dispatch = useDispatch();
  // const [mediaStream, setMediaStream] = useState(null);
  const [recording, setRecording] = useState(false);
  const [mediaStreamReady, setMediaStreamReady] = useState(false);
  const videoRef = useRef();
  // const mediaRecorderRef = useRef(null);
  const recordedChunksRef = useRef([]);
  const recordingStartTimeRef = useRef(null);
  const [seconds, setSeconds] = useState(60);

  const formattedTime = `${Math.floor(seconds / 60)}:${(
    seconds % 60
  ).toLocaleString("en-US", { minimumIntegerDigits: 2 })}`;

  // const handleStopRecording = () => {
  //   if (
  //     mediaRecorderRef.current &&
  //     mediaRecorderRef.current.state === "recording"
  //   ) {
  //     mediaRecorderRef.current.stop();
  //   }
  // };
  const handleStartRecording = () => {
    recordedChunksRef.current = [];
    mediaRecorderRef.current = new MediaRecorder(mediaStream);
    mediaRecorderRef.current.ondataavailable = (e) => {
      if (e?.data?.size > 0) {
        recordedChunksRef?.current?.push(e.data);
        if (recordedChunksRef?.current?.length === 1) {
          setTimeout(handleStopRecording, MAX_VIDEO_LENGTH_MS);
        }
        // Check if the recorded video size exceeds the maximum limit
        if (
          recordedChunksRef?.current?.reduce(
            (acc, chunk) => acc + chunk.size,
            0
          ) > MAX_VIDEO_SIZE_BYTES
        ) {
          alert("The recorded video exceeds the maximum size limit (100 MB).");
          handleStopRecording();
        }
      }
    };
    mediaRecorderRef.current.onstop = async () => {
      const blob = new Blob(recordedChunksRef?.current, { type: "video/webm" });
      const file = new File([blob], "recorded_video.webm", {
        type: "video/webm",
        lastModified: Date.now(),
      });
      const url = URL.createObjectURL(blob);
      onRecordingStop(url);
      const a = document.createElement("a");
      a.href = url;
      setTextChange(false);
      setRecordedVideoData(URL?.createObjectURL(file));
      setVideoBlob(blob);
      URL.revokeObjectURL(url);
      setRecording(false);
    };
    recordingStartTimeRef.current = Date.now();
    mediaRecorderRef?.current?.start();
    setRecording(true);
  };


  // const handleCloseCamera = () => {
  //   if (mediaStream) {
  //     mediaStream.getTracks().forEach((track) => {
  //       track.stop();
  //     });
  //     setMediaStream(null);
  //   }
  // };

  const closeRecording = () => {
    handleCloseCamera();
    handleStopRecording();
    nextStep(4);
  };
  useEffect(() => {
    const interval = setInterval(() => {
      setSeconds((prevSeconds) => {
        if (prevSeconds === 0) {
          clearInterval(interval);
          closeRecording();
          // Call the function when the timer reaches 0
          // return 0;
        }
        return prevSeconds - 1;
      });
    }, 1000);

    return () => clearInterval(interval);
  }, [mediaStream]);

  useEffect(() => {
    async function setupCamera() {
      try {
        const stream = await navigator?.mediaDevices?.getUserMedia({
          video: true,
          audio: true,
        });
        videoRef.current.srcObject = stream;
        setMediaStream(stream);
        setMediaStreamReady(true);
      } catch (error) {
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE?.ERROR,
            msg: error,
          })
        );
      }
    }

    setupCamera();
    return () => {
      if (mediaStreamRef?.current) {
        mediaStreamRef?.current?.getTracks()?.forEach((track) => {
          track?.stop();
        });
      }
    };
    // return () => {
    //   if (mediaStream) {
    //     mediaStream.getTracks().forEach((track) => {
    //       track.stop();
    //     });
    //   }
    // };
  }, []);
  useEffect(() => {
    // Start recording when the MediaStream is ready
    if (mediaStreamReady) {
      handleStartRecording();
    }
  }, [mediaStreamReady]);
  useEffect(() => {
    mediaStreamRef.current = mediaStream;
  }, [mediaStream]);
  return (
    <>
    <Box sx={{ position: "relative" }}>
        <Button
          variant="contained"
          color={page === "jobVideo"?"peachPayment":"precium"}
          sx={{
            borderRadius: "25px 0 10px 0",
            width: "150px",
            height: "45px",
            fontSize: "14px",

            fontWeight: theme.typography.Bold,
            gap: "10px",
          }}
        >
          <Box
            component={"img"}
            src={page === "jobVideo"?videoRecordedIcon:myVideoHeaderLogo}
            sx={{
              height: "20px",
              width: "24px",
            }}
          />
          {name} video
        </Button>
        <Box
          sx={{
            cursor: "pointer",
          }}
          onClick={() => {nextStep(1);handleCloseCamera()}}
        >
          <CloseIcon />
        </Box>
      </Box>
      {/* <Button
        variant="contained"
        color={name === "company" ? "myVideoButton" : "jobVideoButton"}
        sx={{
          borderRadius: "25px 0 25px 0",
          width: "250px",
          height: "60px",
          fontSize: "20px",
          fontWeight: theme.typography.Bold,
          gap: "10px",
        }}
      >
        <Box
          component={"img"}
          src={jobVideoHeaderLogo}
          sx={{
            height: "20px",
            width: "24px",
          }}
        />
        {name} {i18n["empMyCam.video"]}
      </Button> */}
      <Box
        sx={{
          width: "70%",
          margin: "auto",
          marginTop: "39px",
          flexDirection: "column",
          alignItems: "center",
          display: "flex",
          // gap: "20px",
        }}
      >
        <Typography
          sx={{
            fontSize: "20px",
            fontWeight: theme.typography.Bold,
            textAlign: "center",
          }}
        >
          {/* {i18n["empMyCam.recordingInProgress"]} */}
          Recording in progress…
        </Typography>
        {/* <Typography
          sx={{
            fontSize: "14px",
            textAlign: "center",

            fontWeight: theme.typography.Regular,
          }}
        >
          {i18n["empMyCam.stopRecordingText"]}
        </Typography> */}
      </Box>

      {/* {(videoRef.current) ? */}
      <Box
        sx={{
          flexGrow: 1,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: "8px",
          padding: "0 30px 23px 30px",
          // gap: "8px",
          width: "100%",
          boxSizing: "border-box",
        }}
      >
          <Box
          sx={{
            width: { xs: "100%", tb: "100%", lg1440: "75%" },
            boxSizing: "border-box",
            maxWidth: { xs: "100%", lg: "55%", lg440: "400px" },
            maxHeight: { lg440: "360px" },
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
         <video
          ref={videoRef}
          style={{
            width: "100%",
            borderRadius: "25px",
            height: "auto",
          }}
          autoPlay
          playsInline
          muted
        ></video>
        <Typography
          sx={{
            marginBottom: "30px",
            marginTop: "30px",
            fontSize: "16px",
            fontWeight: theme.typography.Bold,
            textAlign: "center",
          }}
        >
          {formattedTime}
        </Typography>
        <Box
            sx={{
              width: "100%",
              flexDirection: "column",
              alignItems: "center",
              display: "flex",
            }}
          >
            <Typography
              sx={{
                fontSize: "14px",
                textAlign: "center",

                fontWeight: theme.typography.Regular,
              }}
            >
              Stop recording below once you’re complete or if you want to start
              again. Max recording length = 1 minute.
            </Typography>
          </Box>
        </Box>
 
      </Box>

      <Box
        sx={{
          margin: "auto",
          width: "100%",
        }}
      >
        <Button
          variant="contained"
          color="videoButton"
          sx={{
            borderRadius: "0 0 25px 25px",
            width: "100%",
            height: "60px",
          }}
          startIcon={
            <Box
            component={"img"}
            src={stopRecording}
            sx={{ width: 20, height: 20 }}
          />
          }
          onClick={() => {
            handleStopRecording();
            handleCloseCamera();
            nextStep(4);
          }}
        >
          Stop Recording
        </Button>
      </Box>
    </>
  );
}
