import React, { useEffect, useRef } from "react";
import { Box, Dialog, DialogTitle, IconButton } from "@mui/material";
import CloseIcon from "./CloseIcon";
import YouTubeEmbed from "../guest/contact/YouTubeEmbed";

const ReviewVideoDialog = ({ videoLink, open, setOpen, allVideoData }) => {
  const videoRef = useRef(null);
  return (
    <Dialog
      open={open}
      hideButton={false}
      dialogWidth="sm"
      sx={{
        padding: "30px",
      }}
      scroll="body"
    >
      <DialogTitle>
        <IconButton
          aria-label="close"
          onClick={() => {
            setOpen();
          }}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme?.palette?.grey[500],
          }}
          disableRipple={true}
        >
          {/* <Box
            sx={{
              width: "20px",
              height: "20px",
              color: "#000000",
              border: 2,
              fontSize: "18px",
              borderRadius: "5px",
            }}
          >
            X
          </Box> */}
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          gap: "10px",
          padding: "20px",
          minHeight: "300px",
          minWidth: "440px",
          boxSizing: "border-box",
          // minWidth:"100%",
          // maxWidth:"100%",
          // maxHeight:"300px"
        }}
      >
        {allVideoData?.linkFlag ? (
          <Box
            sx={{
              height: "300px",
              minWidth: "400px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              boxSizing: "border-box",
            }}
          >
            <YouTubeEmbed
              videoId={
                allVideoData?.job_video_url || allVideoData?.job_video_link || allVideoData?.video_url || allVideoData?.video_link
              }
              width={"360px"}
              borderRadius={"0px"}
              // height={"auto"}
            />
          </Box>
        ) : (
          <video
            autoPlay
            // muted
            ref={videoRef}
            style={{
              width: "100%",
              height: "300px",
              borderRadius: "10px",
              aspectRatio: "4/3",
              objectFit: "cover",
            }}
            onCanPlay={() => videoRef?.current.play()}
            src={videoLink}
            type="video/mp4"
            controls
          ></video>
        )}
      </Box>
    </Dialog>
  );
};

export default ReviewVideoDialog;
