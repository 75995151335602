import { Box, CircularProgress, Typography, useTheme } from "@mui/material";
import React from "react";
import location from "../../../../assets/Red_Location.svg";
import experienceLogo from "../../../../assets/Yellow_Pending.svg";
import education from "../../../../assets/Red_Education.svg";
import noticePeriod from "../../../../assets/Green_Notice_Period.svg";

export default function BasicInfo({
  town,
  region,
  experience,
  educationInfo,
  notice,
  talentInfoLoading,
}) {
  const theme = useTheme();
  return talentInfoLoading ? (
    <Box
      style={{
        margin: "15px 0",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "80%",
      }}
    >
      <CircularProgress color="inherit" size={20} />
    </Box>
  ) : (
    <>
      <Box>
        <Box
          sx={{
            width: "100%",
            height: "fit-content",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            marginLeft:"6px",
            gap:"5px"
          }}
        >
          <Box
            component="img"
            sx={{
              height: 16,
              width: 15,
              maxHeight: { xs: 16 },
              maxWidth: { xs: 15 },
            }}
            alt="location"
            src={location}
          />
          <Typography
            sx={{
              fontWeight: theme.typography.Regular,
              fontSize: "12px",
              letterSpacing: "0.25px",
            }}
          >
            {town}, {region}
          </Typography>
        </Box>
        <Box
          sx={{
            width: "100%",
            height: "fit-content",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <Box
            component="img"
            sx={{
              height: 16,
              width: 15,
              maxHeight: { xs: 16 },
              maxWidth: { xs: 15 },
            }}
            alt="experience"
            src={experienceLogo}
          />
          <Typography
            sx={{
              fontWeight: theme.typography.Regular,
              fontSize: "12px",
              letterSpacing: "0.25px",
            }}
          >
            {experience} years
          </Typography>
        </Box>
        <Box
          sx={{
            width: "100%",
            height: "fit-content",
            display: "flex",
            flexDirection: "row",
            alignItems: "flex-start",
          }}
        >
          <Box
            component="img"
            sx={{
              width: 25,
            }}
            alt="education"
            src={education}
          />
          <Typography
            sx={{
              fontWeight: theme.typography.Regular,
              fontSize: "12px",
              letterSpacing: "0.25px",
              width: "170px",
              marginTop: "5px",
            }}
          >
            {educationInfo}
          </Typography>
        </Box>
        <Box
          sx={{
            width: "100%",
            height: "fit-content",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <Box
            component="img"
            sx={{
              height: 16,
              width: 15,
              maxHeight: { xs: 16 },
              maxWidth: { xs: 15 },
            }}
            alt="noticePeriod"
            src={noticePeriod}
          />
          <Typography
            sx={{
              fontWeight: theme.typography.Regular,
              fontSize: "12px",
              letterSpacing: "0.25px",
            }}
          >
            {notice}
          </Typography>
        </Box>
      </Box>
    </>
  );
}
