import Box from "@mui/material/Box";
import { Outlet } from "react-router-dom";
import LeftDrawer from "./LeftDrawer";
import { Button } from "@mui/material";
import { useTheme } from "@emotion/react";
import { useState } from "react";
import LeftArrow from "../common/LeftArrow";
import RightArrow from "../common/RightArrow";
import { useDispatch } from "react-redux";
import { setLeftMenuButton } from "../../redux/admin/jobsSlice";
import { useSelector } from "react-redux";

export default function Admin() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [leftMenu, setLeftMenu] = useState(false);
  const { leftMenuButton } = useSelector((state) => state.adminSlice);

  return (
    <Box
      sx={{
        display: "flex",
      }}
    >
      <Box
        sx={{
          // width: {
          //   xs: !leftMenu ? "8%" : "19%",
          //   xl: !leftMenu ? "7%" : "16%",
          //   xxl: !leftMenu ? "5%" : "10%",
          // },
          position: "absolute",
          zIndex: "1100",
          display: "flex",
        }}
        onMouseEnter={() => {
          dispatch(setLeftMenuButton(true));
          setLeftMenu(true);
        }}
        onMouseLeave={() => {
          dispatch(setLeftMenuButton(false));
          setLeftMenu(false);
        }}
      >
        <LeftDrawer leftExpanded={leftMenu} />
        {/* <Button
          variant="contained"
          sx={{
            position: "static",
            borderRadius: "0 10px 10px 0",
            minWidth: "30px !important",
            padding: "0px",
            height: "45px",
            backgroundColor: theme.palette.redButton100.main,
            ":hover": {
              backgroundColor: theme.palette.redButton100.main,
              boxShadow: "none",
              cursor: "pointer", // Change cursor to indicate it's clickable
            },
          }}
 
        >
          {leftMenu ? <LeftArrow /> : <RightArrow />}
        </Button> */}
      </Box>
      <Box
        sx={{
          // width: "80%",
          ml: "110px",
          mr: "40px",
          flexGrow: 1,
          height: {
            xs: `calc(100vh - 80px)`,
            lg: `calc(100vh - 80px)`,
            mlg: `calc(100vh - 80px)`,
            xl: `calc(100vh - 100px)`,
            xxl: `calc(100vh - 120px)`,
          },
          overflow: "scroll",
        }}
        id={"jobList"}
      >
        <Outlet />
      </Box>
    </Box>
  );
}
