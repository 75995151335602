import { useState, useRef, useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import locale from "../../../i18n/locale";
import {
  getCandidateBadge,
  setCurrentStep,
  uploadProfilePic,
} from "../../../redux/candidate/myProfileSlice";
import { setAlert, setLoading } from "../../../redux/configSlice";
import {
  ALERT_TYPE,
  CANDIDATE_MY_PROFILE_LEFT,
  CANDIDATE_PROFILE,
  ERROR_MSG,
} from "../../../utils/Constants";
import { useTheme } from "@emotion/react";
import TheBasics from "./TheBasics";
import { getProfile } from "../../../redux/candidate/myProfileSlice";
import { useNavigate } from "react-router-dom";
import { Dialog, Grid, Paper, Popover } from "@mui/material";

import grey_profile_collaborator from "../../../assets/Personality Logos/crayon_personality_grey_collaborator.svg";
import grey_profile_contemplator from "../../../assets/Personality Logos/crayon_personality_grey_contemplator.svg";
import grey_profile_challenger from "../../../assets/Personality Logos/crayon_personality_grey_challenger.svg";
import grey_profile_character from "../../../assets/Personality Logos/crayon_personality_grey_character.svg";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import DisplayFollowedCompanies from "./dialog/DisplayFollowedCompanies";
import SavaAndExit from "./dialog/SavaAndExit";
import LeftArrow from "../../common/LeftArrow";
import RightArrow from "../../common/RightArrow";
import boostProfile from "../../../assets/Icons/CandidateProfile/Black_Dashboard_1.svg";
import followBiz from "../../../assets/Icons/CandidateProfile/Following_biz.svg";
import following from "../../../assets/Icons/CandidateProfile/Following_Company.svg";
import invite from "../../../assets/Padding Excluded/Crayon Icon_Promote.svg"
import joinedGray from "../../../assets/Badges/Candidate/Gray badges/crayon_talent_grey_badge_joined.svg";
import crayonVitaGray from "../../../assets/Badges/Candidate/Gray badges/crayon_talent_grey_badge_vitae in full.svg";
import personalAssessmentGray from "../../../assets/Badges/Candidate/Gray badges/crayon_talent_grey_badge_personal assesment.svg";
import camVideoGray from "../../../assets/Badges/Candidate/Gray badges/crayon_talent_grey_badge_cam_video.svg";
import appliedJobGray from "../../../assets/Badges/Candidate/Gray badges/crayon_talent_grey_badge_applied for job.svg";
import shortlistedGray from "../../../assets/Badges/Candidate/Gray badges/crayon_talent_grey_badge_shortlisted for job.svg";
import hiredGray from "../../../assets/Badges/Candidate/Gray badges/crayon_talent_grey_badge_hired.svg";
import profileDoneGray from "../../../assets/Badges/Candidate/Gray badges/crayon_promoter_grey_profile done.svg";
import sharedJobGray from "../../../assets/Badges/Candidate/Gray badges/crayon_promoter_grey_shared a job.svg";
import refferalGray from "../../../assets/Badges/Candidate/Gray badges/crayon_promoter_grey_refferal signed up.svg";
import bountyGray from "../../../assets/Badges/Candidate/Gray badges/crayon_promoter_grey_bounty earned.svg";
import joined from "../../../assets/Badges/Candidate/Coloured/crayon_talent_badge_joined.svg";
import crayonVita from "../../../assets/Badges/Candidate/Coloured/crayon_talent_badge_vitae in full.svg";
import personalAssessment from "../../../assets/Badges/Candidate/Coloured/crayon_talent_badge_personal_assesment.svg";
import camVideo from "../../../assets/Badges/Candidate/Coloured/crayon_talent_badge_cam_video.svg";
import appliedJob from "../../../assets/Badges/Candidate/Coloured/crayon_talent_badge_applied for job.svg";
import shortlisted from "../../../assets/Badges/Candidate/Coloured/crayon_talent_badge_shortlisted for job.svg";
import hired from "../../../assets/Badges/Candidate/Coloured/crayon_talent_badge_hired.svg";
import profileDone from "../../../assets/Badges/Candidate/Coloured/crayon_promoter_profile done.svg";
import sharedJob from "../../../assets/Badges/Candidate/Coloured/crayon_promoter_shared a job.svg";
import refferal from "../../../assets/Badges/Candidate/Coloured/crayon_promoter_refferal signed up.svg";
import bounty from "../../../assets/Badges/Candidate/Coloured/crayon_promoter_bounty earned.svg";
import LeftSideProfilePanel from "./LeftSideProfilePanel";
import OpenCameraDialog from "../../common/ProfilePicCaptureDialog";
import { nanoid } from "@reduxjs/toolkit";
import FollowCompany from "./dialog/FollowCompany";
import { useSelector } from "react-redux";
import { getCountries } from "../../../redux/employer/postJobSlice";
import {
  getCompanyCulture,
  getDisablity,
  getLanguage,
  getNationality,
} from "../../../redux/candidate/myCvSlice";
import CropModal from "../../employer/myProfile/cropModal";
import useViewport from "../../common/ViewPort";
import CandidateMyProfileCardRow from "./CandidateMyProfileCardRow";
import {
  getCompanySize,
  getCompanyType,
} from "../../../redux/employer/empProfileSlice";
import ReviewVideoDialog from "../../common/ReviewVideoDialog";
import Settings from "./dialog/Settings";
import settingIcon from "../../../assets/Padding Excluded/White_Settings.svg";
import ResetPassword from "./dialog/ResetPassword";
import HideItems from "./dialog/HideItems";
import GoDark from "./dialog/GoDark";
import DeleteProfile from "./dialog/DeleteProfile";
import { setFilterExpanded } from "../../../redux/guest/jobsSlice";
import BoostIcon from "../../../assets/Padding Excluded/BoostProfileIcon.svg";

export default function ProfileCardNew() {
  const { width } = useViewport();
  const i18n = locale.en;
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const followRef = useRef(null);
  const followRefMobile = useRef(null);
  // const followedRef = useRef(null);
  const hiddenFileInput = useRef(null);
  const currentStep = useSelector((state) => state?.myProfileSli?.currentStep);
  const [expanded, setExpanded] = useState(false);
  const [image, setImage] = useState([]);
  const [imageName, setImageName] = useState("My pic");
  const [profile, setProfile] = useState(CANDIDATE_PROFILE);
  const [profileCompletion, setProfileCompletion] = useState({
    totalProfileCompletion: 0,
    profileCompletion: 0,
    cvBasics: 0,
    workLife: 0,
    studyLife: 0,
    refrences: 0,
  });
  const profileProgressDataValues = useSelector(
    (state) => state.myProfileSli.profileProgressDataValues
  );
  const filterExpanded = useSelector(
    (state) => state?.configjobslice?.filterExpanded
  );
  const [openEditImage, setOpenEditImage] = useState(false);
  const [imagePreview, setImagePreview] = useState([]);
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [errors, setErrors] = useState([]);
  const [anchorElCompanyDialog, setAnchorElCompanyDialog] = useState(null);
  const [anchorElCompanyDialogMobile, setAnchorElCompanyDialogMobile] =
    useState(null);
  const openAddCompanyDialogMobile = Boolean(anchorElCompanyDialogMobile);
  const openAddCompanyDialog = Boolean(anchorElCompanyDialog);
  const { nationality, language } = useSelector((state) => state.myCv);
  const [selectedStep, setSelectedStep] = useState();
  const [openSaveAndExitDialog, setOpenSaveAndExitDialog] = useState(false);
  const [leftExpanded, setLeftExpand] = useState(true);
  const [rightExpanded, setRightExpanded] = useState(true);
  const [followedCompanyCount, setFollowedCompanyCount] = useState(0);
  const [openCameraDialog, setOpenCameraDialog] = useState(false);
  const [imageBlob, setImageBlob] = useState();
  const [croppedImageUrl, setCroppedImageUrl] = useState(null);
  const [arrSlider2, setArrSlider2] = useState([
    grey_profile_contemplator,
    grey_profile_collaborator,
    grey_profile_character,
    grey_profile_challenger,
  ]);
  const followBizRef = useRef(null);
  const [openFollowBiz, setOpenFollowBiz] = useState(null);
  const followBizAnchor = Boolean(openFollowBiz);
  const followingBtnRef = useRef(null);
  const [followingBtnOpen, setFollowingBtnOpen] = useState(null);
  const followingBtnAnchor = Boolean(followingBtnOpen);
  const settingsRef = useRef(null);
  const [settingButton, setSettingButton] = useState(null);
  const [resetPasswordButton, setResetPasswordButton] = useState(null);
  const [hideItems, setHideItems] = useState(null);
  const [goDark, setGoDark] = useState(null);
  const [deleteProfile, setDeleteProfile] = useState(null);
  const [openReviewVideoDialog, setOpenReviewVideoDialog] = useState(false);
  const [videoLink, setVideoLink] = useState("");

  const [isLoading, setIsLoading] = useState(false);

  const boxRef = useRef(null);
  const [anchorElMobile, setAnchorElMobile] = useState(null);
  const [progressButton, setProgressButton] = useState(false);
  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const [candidateBadge, setCandidateBadge] = useState([]);
  const [rightBoxY, setRightBoxY] = useState((window.innerWidth * 380) / 1920);

  const getNewWidth = () => {
    setRightBoxY((window.innerWidth * 380) / 1920);
  };
  useEffect(() => {
    if (width > 1024) {
      setLeftExpand(true);
      setRightExpanded(true);
    } else {
      setLeftExpand(false);
      setRightExpanded(false);
    }
  }, [width]);

  const handleClose = () => {
    // setAnchorEl(null);
    setFollowingBtnOpen(null);
  };

  const calculateHeight = () => {
    const screenWidth = window.innerWidth;

    if (screenWidth < 600) {
      return 100;
    } else if (screenWidth < 900) {
      return 125;
    } else if (screenWidth < 1366) {
      // md
      return 125;
    } else if (screenWidth < 1650) {
      return 125;
    } else if (screenWidth < 1785) {
      return 125;
    } else if (screenWidth < 1920) {
      return 125;
    } else if (screenWidth < 2559) {
      return 125;
    } else {
      return 125;
    }
  };

  const getBadgeData = async () => {
    try {
      const { payload } = await dispatch(getCandidateBadge());
      if (payload?.status === "success") {
        setCandidateBadge(payload?.data);
      } else {
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE.ERROR,
            msg: payload?.message,
          })
        );
      }
    } catch (error) {
      dispatch(
        setAlert({
          show: true,
          type: ALERT_TYPE.ERROR,
          msg: error,
        })
      );
    }
  };
  const handleSubscriptionOk = async () => {
    const formData = new FormData();
    const blobTofile = new File([imageBlob], imageName, {
      type: "image/jpeg",
    });
    formData.append("profile-pic", blobTofile);
    try {
      const { payload } = await dispatch(uploadProfilePic(formData));
      if (payload?.status === "success") {
        setProfile({ ...profile, profile_url: payload?.data });
        setImage(payload?.data);
        getBadgeData();
        setOpenEditImage(false);
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE?.SUCCESS,
            msg: "Profile pic uploaded Successfully!",
          })
        );
        setImageBlob();
        setCroppedImageUrl(null);
      } else if (payload?.status === "error") {
        setImageName("My pic");
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE?.ERROR,
            msg: "Image format not supported",
          })
        );
      }
    } catch (error) {
      setImageName("My pic");
      dispatch(
        setAlert({
          show: true,
          type: ALERT_TYPE?.ERROR,
          msg: "",
        })
      );
    }
  };

  const cropInputFUnction = (e) => {
    const files = e?.target?.files;
    if (files && files?.length > 0) {
      const reader = new FileReader();
      reader.onload = () => setImage(reader?.result);
      reader?.readAsDataURL(files?.[0]);
    }
  };
  const handleImageChange = async (event) => {
    if (event?.target?.files?.[0] === undefined) {
      return;
    }
    cropInputFUnction(event);
    const render = new FileReader();
    setZoom(1);
    setImageName(event?.target?.files?.[0].name);
    const imageData = window.URL.createObjectURL(
      new Blob(event?.target?.files, { type: "application/*" })
    );
    setImagePreview(imageData);
    setOpenEditImage(true);
    event.target.value = "";
  };
  const handleSubscriptionCancel = () => {
    setImageName("My pic");
    setOpenEditImage(false);
    setImageBlob();
    setCroppedImageUrl(null);
    setImage();
  };
  const handleImageClick = () => {
    setImagePreview(null);
    hiddenFileInput?.current?.click();
  };

  const getAllData = async () => {
    setIsLoading(true);
    try {
      const { payload } = await dispatch(getProfile());
      if (payload?.status === "success") {
        if (typeof payload?.data == "string") {
          // setProfile({});
        } else {
          // let updatedNationalityList;
          // if (payload?.data?.nationality_ids) {
          //   updatedNationalityList = payload?.data?.nationality_ids
          //     ?.map((nation) =>
          //       nationality?.find((nati) => nati?.id === nation)
          //     )
          //     ?.map((i) => ({ label: i?.name, value: i?.nationality_id }));
          // }
          // let updatedLanguageList;
          // if (payload?.data?.language_ids) {
          //   updatedLanguageList = payload?.data?.language_ids
          //     ?.map((lang) =>
          //       language?.find((language) => language?.id === lang)
          //     )
          //     ?.map((i) => ({ label: i?.name, value: i?.language_id }));
          // }
          setProfile({
            ...payload?.data,
            reveal_gender: payload?.data?.reveal_gender,
            criminal_flag:
              payload?.data?.criminal_flag === null
                ? true
                : payload?.data?.criminal_flag,
            company_type: payload?.data?.company_type,
            company_size: payload?.data?.company_size,
            company_culture: payload?.data?.company_culture?.map((item) => ({
              value: item?.culture_id,
              label: item?.name,
            })),
          });
          setImage(payload?.data?.profile_url);
          const profileCompletionPercentage = {
            totalProfileCompletion: payload?.data?.profile_percent_complete,
            profileCompletion: payload?.data?.profile_completed ? 25 : 0,
            cvBasics: payload?.data?.cv_basic_completed ? 10 : 0,
            workLife: payload?.data?.work_life_completed ? 5 : 0,
            studyLife: payload?.data?.study_life_completed ? 5 : 0,
            refrences: payload?.data?.refrences_completed ? 5 : 0,
          };
          setProfileCompletion(profileCompletionPercentage);
          setFollowedCompanyCount(payload?.data?.followCompanyCount);
        }
      } else {
        // dispatch(
        //   setAlert({
        //     show: true,
        //     type: ALERT_TYPE?.ERROR,
        //     msg: payload?.message,
        //   })
        // );
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      dispatch(setAlert({ show: true }));
    }
  };
  const getAll = async () => {
    try {
      dispatch(setLoading(true));
      await Promise.all([
        dispatch(getCountries()),
        dispatch(getNationality()),
        dispatch(getLanguage()),
        dispatch(getDisablity()),
        dispatch(getCompanyType()),
        dispatch(getCompanySize()),
        dispatch(getCompanyCulture()),
      ]);

      dispatch(setLoading(false));
    } catch (error) {
      dispatch(setLoading(false));
      dispatch(
        setAlert({
          show: true,
          type: ALERT_TYPE.ERROR,
          msg: ERROR_MSG,
        })
      );
    }
  };
  useEffect(() => {
    getAll();
    getBadgeData();
  }, []);

  const handleInfoSave = () => {
    handleStep(1);
    getAllData();
  };
  const getProfileData = (profileData) => {
    setProfile(profileData);
  };

  const handleOpenCompanyDialogMobile = (event) => {
    setAnchorElCompanyDialogMobile(followRef?.current);
  };

  const handleCloseCompanyDialogMobile = () => {
    setAnchorElCompanyDialogMobile(null);
    setOpenFollowBiz(null);
  };
  const handleOpenSaveAndExitDialog = () => {
    setOpenSaveAndExitDialog((prevState) => !prevState);
  };

  const handleClickOutside = (event) => {
    if (boxRef?.current && !boxRef?.current?.contains(event?.target)) {
      setProgressButton(false);
    }
  };
  const handleStep = (step) => {
    dispatch(setCurrentStep(step));
    if (step === 1) {
      setExpanded(false);
    }
    if (step === 2) {
      setExpanded(true);
    } else if (step === 3) {
      navigate("/candidate/my-cv");
    } else if (step === 4) {
      navigate("/candidate/assessment");

      // window.open(
      //   "https://docs.google.com/forms/d/e/1FAIpQLScKTA3IfBUw8xvdP03-hMFX1UObzO_pPcd5p61OsKAf3whbrg/viewform"
      // );
    } else if (step === 5) {
      navigate("/candidate/my-cam");
    }
  };
  const handleImageData = (data) => {
    const id = nanoid();
    const base64Image = data?.replace(/^data:image\/jpeg;base64,/, "");
    setImage(data)
    const binaryData = atob(base64Image);

    const blob = new Blob(
      [new Uint8Array([...binaryData]?.map((char) => char?.charCodeAt(0)))],
      { type: "application/*" }
    );
    setImageName(id + ".jpeg");
    const imageData = window.URL.createObjectURL(blob);
    setZoom(1);
    setImagePreview(imageData);
    setOpenEditImage(true);
  };
  const handlePhotoCapture = () => {
    setOpenCameraDialog((prevState) => !prevState);
  };

  const handleRightClick = () => {
    setArrSlider2([...arrSlider2?.slice(1), ...arrSlider2?.slice(0, 1)]);
  };
  const handleLeftClick = () => {
    setArrSlider2([
      ...arrSlider2?.slice(arrSlider2?.length - 1),
      ...arrSlider2?.slice(0, arrSlider2?.length - 1),
    ]);
  };

  const handleClickMyInfo = () => {
    setExpanded((prevState) => !prevState);
    !expanded ? dispatch(setCurrentStep(2)) : dispatch(setCurrentStep(1));
  };

  const handleCloseDialog = () => {
    setVideoLink("");
    setOpenReviewVideoDialog(false);
  };

  useEffect(() => {
    window.addEventListener("resize", getNewWidth);
    return () => {
      window.removeEventListener("resize", getNewWidth);
    };
  }, []);
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    setSelectedStep(currentStep);
    currentStep === 2 && setExpanded(true);
    if (profileProgressDataValues) {
      getAllData();
    }
  }, [currentStep, profileProgressDataValues]);
  if (width === 0) {
    return null;
  }
  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Box
          item
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: `${width >= 768 && "10px"}`,
            position: `${width < 1024 ? "absolute" : "relative"}`,
            // width: leftExpanded
            //   ? { xs: "150px", mdl: "123px", mlg: "150px", xl: "150px" }
            //   : "30px",
            width: leftExpanded ? "125px" : "30px",
            zIndex: "30",
          }}
        >
          <Button
            variant="contained"
            sx={{
              position: "static",
              borderRadius: "0 10px 10px 0",
              minWidth: "30px",
              width: "30px",
              height: "45px",
            }}
            color="filterButtons"
            onClick={() => {
              setLeftExpand((prevState) => !prevState);
              setRightExpanded((prevState) => !prevState);
              dispatch(setFilterExpanded(!filterExpanded));
            }}
          >
            {leftExpanded ? <LeftArrow /> : <RightArrow />}
          </Button>
          {leftExpanded && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "10px",
                width: `${width < 768 && "135px"}`,
                paddingTop: `${width < 768 && "10px"}`,
                background: `${width > 0 && width < 768 && "#EBECF3"}`,
                boxShadow: `${
                  width > 0 && width < 768 && "0px 5px 10px #00000029"
                }`,
                opacity: `${width < 768 && "1"}`,
                borderRadius: `${width < 768 && "10px"}`,
                paddingBottom: `${width < 768 && "10px"}`,
              }}
              className="filterSec"
            >
              <Paper
                sx={{
                  background: "transparent",
                  // marginRight: "1px",
                  boxShadow: 0,
                  // maxWidth: `${width < 768 && "150px"}`,
                  maxWidth: "125px",
                }}
              >
                <LeftSideProfilePanel
                  panelData={CANDIDATE_MY_PROFILE_LEFT}
                  side="left"
                  onChangeFilter={handleStep}
                  selectedButton={selectedStep}
                  color="filterButtons"
                />
              </Paper>
              <style>
                {`.filterSec::-webkit-scrollbar {
                    width: 0px !important;
                    background-color: #EFEEEE; /* Set the background color of the scrollbar */
                  }
                  .filterSec::-webkit-scrollbar-thumb {
                    background-color: white;
                    width: 0px;
                    box-shadow: 0px 3px 3px #00000029;
                    border-radius: 0px;
                  }`}
              </style>
            </Box>
          )}
        </Box>

        <Box
          sx={{
            height: {
              xs: `calc(100vh - 70px)`,
              sm: `calc(100vh - 70px )`,
              md: `calc(100vh - 70px)`,
              lg: `calc(100vh - 80px)`,
              xl: `calc(100vh - 100px)`,
              xxl: "calc(100vh - 120px)",
            },
            display: "flex",
            flexGrow: 1,
            alignItems: "center",
            flexDirection: "column",
            width: `calc(100vw - 210px)`,
            position: "sticky",
            overflowY: "scroll",
          }}
        >
          <Grid
            sx={{
              display: "flex",
              alignItems: { xs: "center", xl: "flex-start" },
              position: "relative",
              height: "auto",
              flexDirection: "column",
              // marginLeft: { xs: "10px", lg: "30px", xl: "35px" },
              // marginRight: { xs: "10px", lg: "10px", xl: "35px" },
              // width: { xs: "90%", md24: "96.5%" },
              gap: expanded ? "40px" : "0px",
              width: "100%",
              paddingLeft: "30px",
              paddingRight: "30px",
              marginTop: "55px",
            }}
          >
            <CandidateMyProfileCardRow
              profile={profile}
              handlePhotoCapture={handlePhotoCapture}
              hiddenFileInput={hiddenFileInput}
              handleImageChange={handleImageChange}
              handleImageClick={handleImageClick}
              calculateHeight={calculateHeight}
              handleLeftClick={handleLeftClick}
              arrSlider2={arrSlider2}
              handleRightClick={handleRightClick}
              expanded={expanded}
              handleClickMyInfo={handleClickMyInfo}
              leftExpanded={leftExpanded}
              setVideoLink={setVideoLink}
              setOpenReviewVideoDialog={setOpenReviewVideoDialog}
            />
            <Box
              sx={{
                width: "100%",
                textAlign: "center",
                display: "none",
              }}
            >
              <Button
                sx={{
                  height: "45px",
                  width: "125px",
                  borderRadius: "0 0 25px 25px",
                  fontSize: "14px",
                  fontWeight: theme.typography.Bold,
                }}
                size="small"
                variant="contained"
                color="basicColor"
                endIcon={expanded ? <ExpandLess /> : <ExpandMore />}
                onClick={() => {
                  handleClickMyInfo();
                }}
              >
                my info
              </Button>
            </Box>
            {!expanded || (!expanded && !currentStep === 2) ? (
              <Box
                sx={{
                  top: { xs: "30px", md24: "30px", mdl: "70px" },
                  padding: "0px !important",
                  cursor: "auto !important",
                  boxShadow: "0px 5px 10px #00000029",
                  borderRadius: "25px",
                  marginBottom: "0px !important",
                  position: "relative",
                  background: "#ffff",
                  display: "flex",
                  flexDirection: `${width > 1024 ? "row" : "column"}`,
                  gap: "10px",
                  marginX: { xs: "10px", lg: "0px" },
                }}
                width={"100%"}
              >
                <Button
                  variant="contained"
                       color="yoco"
                  sx={{
                    height: "45px",
                    minWidth: "125px",
                    maxWidth: "125px",
                    fontSize: {
                      xs: "14px",
                      xl: "14px",
                    },
                    borderRadius: "25px 0px 10px 0px",
                    cursor: "default",
                    ":hover": {
                      boxShadow: "none !important",
                    },
                  }}
                >
                  my badges
                </Button>
                <Box
                  sx={{
                    display: "flex",
                    paddingY: "15px",
                    gap: "10px",
                    flexWrap: "wrap",
                    justifyContent: { xs: "center", md24: "flex-start" },
                  }}
                  // width={width < 1024 ? "100%" : `calc(100% - 369px)`}
                  width={width < 1024 ? "100%" : `100%`}
                >
                  <Box
                    component="img"
                    height={{
                      xs: 90,
                      lg: 90,
                      lgm: 110,
                      mlg: 110,
                      xl: 125,
                      xxl: 150,
                    }}
                    alt="Joined"
                    src={candidateBadge?.joined ? joined : joinedGray}
                  />
                  <Box
                    component="img"
                    height={{
                      xs: 90,
                      lg: 90,
                      lgm: 110,
                      mlg: 110,
                      xl: 125,
                      xxl: 150,
                    }}
                    alt="Joined"
                    src={
                      candidateBadge?.crayon_vitae ? crayonVita : crayonVitaGray
                    }
                  />
                  <Box
                    component="img"
                    height={{
                      xs: 90,
                      lg: 90,
                      lgm: 110,
                      mlg: 110,
                      xl: 125,
                      xxl: 150,
                    }}
                    alt="Joined"
                    src={
                      candidateBadge?.personality_assesment
                        ? personalAssessment
                        : personalAssessmentGray
                    }
                  />
                  <Box
                    component="img"
                    height={{
                      xs: 90,
                      lg: 90,
                      lgm: 110,
                      mlg: 110,
                      xl: 125,
                      xxl: 150,
                    }}
                    alt="Joined"
                    src={
                      candidateBadge?.crayon_cam_video ? camVideo : camVideoGray
                    }
                  />
                  <Box
                    component="img"
                    height={{
                      xs: 90,
                      lg: 90,
                      lgm: 110,
                      mlg: 110,
                      xl: 125,
                      xxl: 150,
                    }}
                    alt="Joined"
                    src={
                      candidateBadge?.profile_pic
                        ? profileDone
                        : profileDoneGray
                    }
                  />
                  <Box
                    component="img"
                    height={{
                      xs: 90,
                      lg: 90,
                      lgm: 110,
                      mlg: 110,
                      xl: 125,
                      xxl: 150,
                    }}
                    alt="Joined"
                    src={
                      candidateBadge?.applied_job ? appliedJob : appliedJobGray
                    }
                  />
                  <Box
                    component="img"
                    height={{
                      xs: 90,
                      lg: 90,
                      lgm: 110,
                      mlg: 110,
                      xl: 125,
                      xxl: 150,
                    }}
                    alt="Joined"
                    src={
                      candidateBadge?.shortlisted
                        ? shortlisted
                        : shortlistedGray
                    }
                  />
                  <Box
                    component="img"
                    height={{
                      xs: 90,
                      lg: 90,
                      lgm: 110,
                      mlg: 110,
                      xl: 125,
                      xxl: 150,
                    }}
                    alt="Joined"
                    src={candidateBadge?.hired ? hired : hiredGray}
                  />
                  <Box
                    component="img"
                    height={{
                      xs: 90,
                      lg: 90,
                      lgm: 110,
                      mlg: 110,
                      xl: 125,
                      xxl: 150,
                    }}
                    alt="Joined"
                    src={candidateBadge?.shared_job ? sharedJob : sharedJobGray}
                  />
                  <Box
                    component="img"
                    height={{
                      xs: 90,
                      lg: 90,
                      lgm: 110,
                      mlg: 110,
                      xl: 125,
                      xxl: 150,
                    }}
                    alt="Joined"
                    src={
                      candidateBadge?.referral_signedup
                        ? refferal
                        : refferalGray
                    }
                  />
                  <Box
                    component="img"
                    height={{
                      xs: 90,
                      lg: 90,
                      lgm: 110,
                      mlg: 110,
                      xl: 125,
                      xxl: 150,
                    }}
                    alt="Joined"
                    src={candidateBadge?.bounty_earned ? bounty : bountyGray}
                  />
                </Box>
              </Box>
            ) : null}
            {expanded || currentStep === 2 ? (
              <Box
                sx={{
                  marginTop: "30px",
                  padding:
                    width < 1024
                      ? "20px 10px 0px 10px !important"
                      : "30px 30px 0px 30px !important",
                  marginX: width < 1024 ? "10px" : "0px",
                  cursor: "auto !important",
                  boxShadow: "0px 5px 10px #00000029",
                  borderRadius: "25px",
                  marginBottom: "30px !important",
                  position: "relative",
                  background: "#ffff",
                  width: { xs: "90%", md24: "90.5%", mdl: "96.5%" },
                }}
              >
                <TheBasics
                  profile={profile}
                  handleProfileData={getProfileData}
                  errors={errors}
                  setErrors={setErrors}
                  handleInfoSave={handleInfoSave}
                  isLoadingData={isLoading}
                />
              </Box>
            ) : null}
          </Grid>
        </Box>
        <Box
          item
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: `${width >= 768 && "10px"}`,
            direction: "rtl",
            position: `${width < 1024 ? "absolute" : "relative"}`,
            zIndex: "30",
            // width: rightExpand && "150px",
            right: "0",
            // width: leftExpanded
            //   ? { xs: "150px", mdl: "150px", mlg: "150px", xl: "150px" }
            //   : "30px",
            width: rightExpanded ? "125px" : "30px",
          }}
        >
          <Button
            variant="contained"
            sx={{
              position: "sticky",
              top: 0,
              borderRadius: "10px 0 0 10px",
              minWidth: "30px",
              width: "30px",
              height: "45px",
            }}
            color="filterButtons"
            onClick={() => {
              setLeftExpand((prevState) => !prevState);
              setRightExpanded((prevState) => !prevState);
              dispatch(setFilterExpanded(!filterExpanded));
            }}
          >
            {rightExpanded ? <RightArrow /> : <LeftArrow />}
          </Button>
          {rightExpanded && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "10px",
                height: {
                  xs: `calc(100vh - 95px)`,
                  sm: `calc(100vh - 120px )`,
                  md: `calc(100vh - 120px)`,
                  lg: `calc(100vh - 135px)`,
                  xl: `calc(100vh - 155px)`,
                  xxl: "calc(100vh - 175px )",
                },
                width: `${width < 768 && "135px"}`,
                paddingTop: `${width < 768 && "10px"}`,
                paddingBottom: `${width < 768 && "10px"}`,
                background: `${width > 0 && width < 768 && "#EBECF3"}`,
                boxShadow: `${
                  width > 0 && width < 768 && "0px 5px 10px #00000029"
                }`,
                opacity: `${width < 768 && "1"}`,
                borderRadius: `${width < 768 && "10px"}`,
                overflowY: rightExpanded ? "scroll" : "unset",
              }}
              className="rightfilterSec"
            >
              <style>
                {`.rightfilterSec::-webkit-scrollbar {
                      display:none;
                    }
                    `}
              </style>
              <Paper
                sx={{
                  background: "transparent",
                  boxShadow: 0,
                  gap: "10px",
                  display: "flex",
                  height: "100px",
                  flexDirection: "column",
                  // maxWidth: `${width < 768 && "150px"}`,
                  maxWidth: "125px",
                }}
              >
                <Button
                  variant="contained"
                  width="100%"
                  color="tymeColor"
                  endIcon={
                    <Box
                      component={"img"}
                      src={followBiz}
                      sx={{
                        height: "25px",
                        width: "20px",
                        paddingRight: "10px",
                        paddingLeft: "0px",
                        margin: "0px",
                        "&.MuiBox-root": {
                          margin: "0px",
                        },
                      }}
                    />
                  }
                  sx={{
                    height: "45px !important",
                    fontSize: theme.typography.fontSize.xs,
                    gap: "0px",
                    // padding: "12.25px 6px 12.25px 0",
                    width: { xs: "100%" },
                    padding:"16px 6px",
                    borderRadius: "10px 0 0 10px",
                    justifyContent: "flex-start !important",
                    "& .MuiSvgIcon-root": {
                      fontSize: "16px",
                    },
                  }}
                  ref={followBizRef}
                  onClick={() => {
                    setOpenFollowBiz(followBizRef.current);
                  }}
                >
                  follow a biz
                </Button>
                <Popover
                  open={followBizAnchor}
                  anchorEl={openFollowBiz}
                  onClose={() => setOpenFollowBiz(null)}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  sx={{
                    borderRadius: "0px !important",
                    "& .MuiPopover-paper": {
                      width: "360px",
                      height: {
                        xs: "440px",
                        lg: "440px",
                        lgm: "500px",
                        mlg: "550px",
                        xl: "572px",
                      },
                      borderRadius: "25px !important",
                    },
                  }}
                >
                  <FollowCompany
                    getProfileData={getAllData}
                    followedRef={followRefMobile}
                    newTitle={"Follow Company"}
                    show={openAddCompanyDialogMobile}
                    newCompanyFollowed={() =>
                      setFollowedCompanyCount(followedCompanyCount + 1)
                    }
                    handleOpen={handleOpenCompanyDialogMobile}
                    handleClose={handleCloseCompanyDialogMobile}
                    setAnchorEl={setAnchorElMobile}
                    setAnchorElCompanyDialog={setAnchorElCompanyDialogMobile}
                  />
                </Popover>
                <Button
                  variant="contained"
                  width="100%"
                  color="yoco"
                  // onClick={handleClick}
                  onClick={() => {
                    setFollowingBtnOpen(followingBtnRef.current);
                  }}
                  ref={followingBtnRef}
                  endIcon={
                    <Box
                      component={"img"}
                      src={following}
                      sx={{
                        height: "20px",
                        
                        width: "20px",
                        paddingRight: "10px",
                        paddingLeft: "0px",
                        margin: "0px",
                        "&.MuiBox-root": {
                          margin: "0px",
                        },
                      }}
                    />
                  }
                  sx={{
                    height: "45px !important",
                    fontSize: theme.typography.fontSize.xs,
                    gap: "0px",
                    padding:"1.5px 6px",
                    // padding: "12.25px 6px 12.25px 0",
                    width: { xs: "100%" },
                    borderRadius: "10px 0 0 10px",
                    justifyContent: "flex-start !important",
                    "& .MuiSvgIcon-root": {
                      fontSize: "16px",
                    },
                  }}
                >
                  following ({followedCompanyCount})
                </Button>
                <Popover
                  id="dropdown-menu"
                  // anchorEl={anchorEl}
                  // open={Boolean(anchorEl)}
                  // onClose={handleClose}
                  open={Boolean(followingBtnOpen)}
                  anchorEl={followingBtnOpen}
                  onClose={() => setFollowingBtnOpen(null)}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  sx={{
                    borderRadius: "0px !important",
                    "& .MuiPopover-paper": {
                      width: "360px",
                      height: {
                        sm: "440px",
                        lg: "440px",
                        lgm: "500px",
                        mlg: "550px",
                        xl: "572px",
                      },
                      borderRadius: "25px !important",
                    },
                  }}
                >
                  <DisplayFollowedCompanies
                    getProfileData={getAllData}
                    followRef={followingBtnAnchor}
                    openDialog={Boolean(followingBtnOpen)}
                    totalfollowedCompanies={followedCompanyCount}
                    handleClose={handleClose}
                    setAnchorEl={setFollowingBtnOpen}
                    setAnchorElCompanyDialog={setAnchorElCompanyDialog}
                  />
                </Popover>
                <Button
                  variant="contained"
                  width="100%"
                  // color="employerButton"
                  endIcon={
                    <Box
                      component={"img"}
                      src={invite}
                      sx={{
                        height: "20px",
                        width: "20px",
                        paddingRight: "10px",
                        paddingLeft: "0px",
                        margin: "0px",
                        "&.MuiBox-root": {
                          margin: "0px",
                        },
                      }}
                    />
                  }
                  sx={{
                    fontSize: theme.typography.fontSize.xs,
                    backgroundColor: theme.palette.safair.main,
                    width: "125px",
                    height: "45px !important",
                    padding:"16px 6px",
                    // gap: "8px",
                    // padding: "10.25px 10px 10.25px 0",
                    "& .MuiSvgIcon-root": {
                      fontSize: "16px",
                    },
                    borderRadius: "10px 0 0 10px",
                    ":hover": {
                      boxShadow: "none !important",
                      backgroundColor: theme.palette.pinkButton200.main,
                    },
                    gap: "0px",
                    // padding: "12.25px 6px 12.25px 0",
                    justifyContent: "flex-start !important",
                  }}
                >
                  invite mates
                </Button>
                <Button
                  variant="contained"
                  width="100%"
                  endIcon={
                    <Box
                      component={"img"}
                      src={BoostIcon}
                      sx={{
                        height: "20px",
                        width: "19px !important",
                        paddingRight: "10px",
                        paddingLeft: "0px",
                        margin: "0px",
                        "&.MuiBox-root": {
                          margin: "0px",
                        },
                      }}
                    />
                  }
                  // color="employerButton"
                  sx={{
                    backgroundColor: theme.palette.discovery.main,
                    fontSize: theme.typography.fontSize.xs,
                    width: "125px",
                    height: "45px",
                    // gap: "8px",
                    // padding: "10.25px 10px 10.25px 0",
                    "& .MuiSvgIcon-root": {
                      fontSize: "16px",
                    },
                    borderRadius: "10px 0 0 10px",
                    ":hover": {
                      boxShadow: "none !important",
                      backgroundColor: theme.palette.tealColor.main,
                    },
                    gap: "0px",
                    padding: "12.25px 6px 12.25px 0",
                    justifyContent: "flex-start !important",
                  }}
                >
                  boost profile
                </Button>
                <Button
                  variant="contained"
                  ref={settingsRef}
                  endIcon={
                    <Box
                      component={"img"}
                      src={settingIcon}
                      sx={{
                        height: "20px",
                        width: "20px",
                        paddingRight: "7px",
                        paddingLeft: "0px",
                        margin: "0px",
                        "&.MuiBox-root": {
                          margin: "0px",
                        },
                      }}
                    />
                  }
                  sx={{
                    gap: "15px",
                    backgroundColor: theme.palette.rejectedStatus.main,
                    height: "45px",
                    fontSize: theme.typography.fontSize.xs,
                    // gap: "8px",
                    // padding: "10.25px 10px 10.25px 0",
                    width: { xs: "100%" },
                    borderRadius: "10px 0 0 10px",
                    // justifyContent: "flex-start !important",
                    "& .MuiButton-endIcon": {
                      marginLeft: "0px !important",
                    },
                    ":hover": {
                      boxShadow: "none !important",
                      backgroundColor: theme.palette.rejectedStatus.main,
                    },
                    "& .MuiSvgIcon-root": {
                      fontSize: "16px",
                    },
                    padding: "12.25px 6px 12.25px 0",
                  }}
                  width="100%"
                  onClick={() => {
                    setSettingButton(settingsRef.current);
                  }}
                >
                  settings
                </Button>

                <Popover
                  id="dropdown-menu"
                  // anchorEl={anchorEl}
                  // open={Boolean(anchorEl)}
                  // onClose={handleClose}
                  open={Boolean(settingButton)}
                  anchorEl={settingButton}
                  onClose={() => setSettingButton(null)}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  sx={{
                    "& .MuiPopover-paper": {
                      borderRadius: "25px 0 25px 25px !important",
                    },
                  }}
                >
                  <Settings
                    onClose={() => setSettingButton(null)}
                    setResetPasswordButton={setResetPasswordButton}
                    settingsRef={settingsRef}
                    setHideItems={setHideItems}
                    setGoDark={setGoDark}
                    setDeleteProfile={setDeleteProfile}
                  />
                </Popover>

                <Popover
                  id="dropdown-menu"
                  // anchorEl={anchorEl}
                  // open={Boolean(anchorEl)}
                  // onClose={handleClose}
                  open={Boolean(resetPasswordButton)}
                  anchorEl={resetPasswordButton}
                  onClose={() => setResetPasswordButton(null)}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  sx={{
                    "& .MuiPopover-paper": {
                      borderRadius: "25px 0 25px 25px !important",
                    },
                  }}
                >
                  <ResetPassword onClose={() => setResetPasswordButton(null)} />
                </Popover>

                <Popover
                  id="dropdown-menu"
                  // anchorEl={anchorEl}
                  // open={Boolean(anchorEl)}
                  // onClose={handleClose}
                  open={Boolean(hideItems)}
                  anchorEl={hideItems}
                  onClose={() => setHideItems(null)}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  sx={{
                    "& .MuiPopover-paper": {
                      borderRadius: "25px 0 25px 25px !important",
                    },
                  }}
                >
                  <HideItems
                    onClose={() => setHideItems(null)}
                    setSettingButton={setSettingButton}
                    settingsRef={settingsRef}
                  />
                </Popover>

                <Popover
                  id="dropdown-menu"
                  // anchorEl={anchorEl}
                  // open={Boolean(anchorEl)}
                  // onClose={handleClose}
                  open={Boolean(goDark)}
                  anchorEl={goDark}
                  onClose={() => setGoDark(null)}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  sx={{
                    "& .MuiPopover-paper": {
                      borderRadius: "25px 0 25px 25px !important",
                    },
                  }}
                >
                  <GoDark
                    onClose={() => setGoDark(null)}
                    setSettingButton={setSettingButton}
                    settingsRef={settingsRef}
                  />
                </Popover>

                <Popover
                  id="dropdown-menu"
                  // anchorEl={anchorEl}
                  // open={Boolean(anchorEl)}
                  // onClose={handleClose}
                  open={Boolean(deleteProfile)}
                  anchorEl={deleteProfile}
                  onClose={() => setDeleteProfile(null)}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  sx={{
                    "& .MuiPopover-paper": {
                      borderRadius: "25px 0 25px 25px !important",
                    },
                  }}
                >
                  <DeleteProfile
                    onClose={() => setDeleteProfile(null)}
                    setSettingButton={setSettingButton}
                    settingsRef={settingsRef}
                  />
                </Popover>
              </Paper>
            </Box>
          )}
        </Box>

        <SavaAndExit
          show={openSaveAndExitDialog}
          handleOpen={handleOpenSaveAndExitDialog}
        />
      </Box>
      <Dialog open={openCameraDialog} fullWidth={true} maxWidth={"sm"}>
        <OpenCameraDialog
          onRecordingStop={setOpenCameraDialog}
          handleImage={handleImageData}
        />
      </Dialog>
      {openEditImage && (
        <CropModal
          handleOpen={openEditImage}
          handleClose={setOpenEditImage}
          handleSubscriptionOk={handleSubscriptionOk}
          handleSubscriptionCancel={handleSubscriptionCancel}
          imagePreview={imagePreview}
          image={image}
          setImage={setImage}
          croppedImageUrl={croppedImageUrl}
          setCroppedImageUrl={setCroppedImageUrl}
          imageBlob={imageBlob}
          setImageBlob={setImageBlob}
        />
      )}
      {openReviewVideoDialog && (
        <ReviewVideoDialog
          videoLink={videoLink}
          open={openReviewVideoDialog}
          setOpen={handleCloseDialog}
        />
      )}
    </>
  );
}
