import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  IconButton,
  Typography,
  useTheme,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { setAlert } from "../../../redux/configSlice";
import { ALERT_TYPE } from "../../../utils/Constants";
import CounterDialog from "../../common/myCamSection/dialog/CounterDialog";
import RecordingInProgress from "../../common/myCamSection/dialog/RecordingInProgress";
import Delete from "../../common/myCamSection/dialog/Delete";
// import jobVideoHeaderLogo from "../../../assets/Padding Excluded/White_jobVideo.svg";
import jobVideoHeaderLogo from "../../../assets/Cam/JobVideoIcon.svg";

import jobVideoicon from "../../../assets/CrayBotIcons/Crayon Job Video Character.svg";
import uploadNewVideoIcon from "../../../assets/Padding Excluded/Black_Upload.svg";
import { RadioButtonChecked } from "@mui/icons-material";
import {
  deleteEmployerJobVideo,
  uploadPostJobCultureVideo,
} from "../../../redux/employer/postJobSlice";
import JobVideoBriefBox from "../../common/myCamSection/dialog/JobVideoBriefBox";
import PlayButton from "../PlayButton";
import deleteIcon from "../../../assets/Padding Excluded/Black_Trash_Delete.svg";
import locale from "../../../i18n/locale";
import useViewport from "../ViewPort";

export default function UploadJobVideo({
  show,
  closeFunc,
  job_id,
  setHasJobVideo,
  job,
  getJobs,
  setAllJobs,
}) {
  const i18n = locale.en;
  const theme = useTheme();
  const dispatch = useDispatch();
  const VideoRef = useRef(null);
  const mediaRecorderRef = useRef(null);
  const { width } = useViewport();

  const [mediaStream, setMediaStream] = useState(null);
  const [steps, setSteps] = useState(1);
  const [recordedVideoData, setRecordedVideoData] = useState(null);
  const [videoLink, setVideoLink] = useState("");
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [showBrief, setShowBrief] = useState(false);
  const [disabledButton, setDisabledButton] = useState(true);
  const [isPlaying, setIsPlaying] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);

  const fileAccept = "video/*";
  const hiddenFileInput = useRef(null);

  useEffect(() => {
    if (recordedVideoData) {
      setVideoLink(recordedVideoData);
    }
  }, [recordedVideoData]);

  const handleCloseBriefBox = () => {
    setShowBrief(false);
  };

  const handleVideoData = (videoData) => {
    setRecordedVideoData(videoData);
  };

  const validateFileSize = (file) => {
    if (file?.size > 100000000) {
      dispatch(
        setAlert({
          show: true,
          type: ALERT_TYPE?.ERROR,
          msg: i18n["alertMessage.Videosizeshouldbelessthan100MB"],
        })
      );
      return false;
    }
    return true;
  };
  const uploadVideo = async (file) => {
    const formData = new FormData();
    const myFile = new File([file], "videoFile.webm", {
      type: file?.type,
    });
    formData?.append("jobvideo", myFile);
    formData?.append("job_id", job_id);

    try {
      const { payload } = await dispatch(uploadPostJobCultureVideo(formData));
      if (payload?.status === "success") {
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE?.SUCCESS,
            msg: "Video uploaded Successfully!",
          })
        );
        setAllJobs([]);
        await getJobs("", "", 0);
        setHasJobVideo(false);
        closeFunc();
      } else {
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE?.ERROR,
            msg: "Failed to upload Video",
          })
        );
      }
    } catch (error) {
      dispatch(setAlert({ show: true }));
    }
  };
  const handleFileChange = async (event) => {
    const selectedFile = event?.target?.files?.[0];

    if (!selectedFile) {
      return;
    }

    if (validateFileSize(selectedFile)) {
      await uploadVideo(selectedFile);
    }
  };
  const handleFileClick = () => {
    hiddenFileInput?.current?.click();
  };

  const handleOpenDelete = () => {
    closeFunc();
    setOpenDelete((prevState) => !prevState);
    setConfirmDelete(false);
  };
  const handleCancelDelete = () => {
    setOpenDelete((prevState) => !prevState);
    setConfirmDelete(false);
  };

  const handleDeleteVideo = async () => {
    try {
      const { payload } = await dispatch(
        deleteEmployerJobVideo({ job_id: job_id })
      );
      if (payload?.status === "success") {
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE?.SUCCESS,
            msg: "Job video removed successfully!",
          })
        );
        handleCancelDelete();
      }
    } catch (error) {}
  };

  const handleStopRecording = () => {
    if (
      mediaRecorderRef?.current &&
      mediaRecorderRef?.current?.state === "recording"
    ) {
      mediaRecorderRef?.current?.stop();
    }
  };

  const handleCloseCamera = () => {
    if (mediaStream) {
      mediaStream?.getTracks()?.forEach((track) => {
        track?.stop();
      });
      setMediaStream(null);
    }
  };

  return (
    <>
      <Dialog
        open={show}
        fullWidth={true}
        sx={{
          "& .MuiPaper-root": {
            borderRadius: "25px",
            maxWidth: "760px",
            height: "739px",
          },
        }}
      >
        <DialogTitle
          sx={{
            padding: 0,
          }}
        >
          <IconButton
            aria-label="close"
            onClick={() => {
              closeFunc();
              //   setSteps(1);
            }}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <Box
              sx={{
                width: "20px",
                height: "20px",
                color: "#000000",
                border: 2,
                fontSize: "18px",
                borderRadius: "5px",
              }}
            >
              X
            </Box>
          </IconButton>
        </DialogTitle>
        {steps === 1 && (
          <>
            <Button
              variant="contained"
              color="jobVideoButton"
              sx={{
                borderRadius: "25px 0 25px 0",
                width: "250px",
                height: "60px",
                fontSize: "20px",
                fontWeight: theme.typography.Bold,
                gap: "10px",
              }}
            >
              <Box
                component={"img"}
                src={jobVideoHeaderLogo}
                sx={{
                  height: "20px",
                  width: "24px",
                }}
              />
              {i18n["empMyCam.jobvideo"]}
            </Button>

            <Box
              sx={{
                padding: "14px 20px 41px",
                flexDirection: "column",
                alignItems: "center",
                display: "flex",
                gap: "37px",
              }}
            >
              <Box
                sx={{
                  width: "70%",
                  flexDirection: "column",
                  alignItems: "center",
                  display: "flex",
                  gap: "20px",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "20px",
                    fontWeight: theme.typography.Bold,
                    textAlign: "center",
                  }}
                >
                  {i18n["empMyCam.rollCamera"]}
                </Typography>
                <Typography
                  sx={{
                    fontSize: "14px",
                    textAlign: "center",
                    width: "453px",
                    fontWeight: theme.typography.Regular,
                  }}
                >
                  {i18n["empMyCam.rollCamera1"]}
                </Typography>
              </Box>

              <Box
                sx={{
                  width: "500px",
                  height: "435px",
                  flexGrow: 1,
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <Box
                  component={"img"}
                  src={jobVideoicon}
                  sx={{
                    height: "359px",
                    width: "auto",
                  }}
                />
                <Box
                  sx={{
                    display: "flex",
                    width: "100%",
                    background: theme.palette.mainBackground,
                    borderRadius: "0 0 25px 25px",
                    height: "60px",
                  }}
                >
                  <Box
                    sx={{
                      width: "70%",
                      margin: "auto",
                      padding: "0 0 0 20px ",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "15px",
                        fontWeight: theme.typography.Bold,
                      }}
                    >
                      {job?.title}
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "13px",
                        fontWeight: theme.typography.Regular,
                      }}
                    >
                      {job?.CompanyName}
                    </Typography>
                  </Box>
                  <Button
                    variant="contained"
                    color="videoButton"
                    sx={{
                      width: "30%",
                      height: "100%",
                      borderRadius: "0 0 25px 0",
                    }}
                    onClick={() => setShowBrief(true)}
                  >
                    {i18n["empMyCam.theBrief"]}
                  </Button>
                  {showBrief && (
                    <JobVideoBriefBox
                      show={showBrief}
                      handleOpen={handleCloseBriefBox}
                    />
                  )}
                </Box>
              </Box>
            </Box>

            <Box
              sx={{
                display: "flex",
              }}
            >
              <Button
                variant="contained"
                color="videoButton"
                sx={{
                  width: "50%",
                  height: "60px",
                  borderRadius: "0 0 0px 25px",
                  fontSize: "14px",
                  gap: "8px",
                  fontWeight: theme.typography.Bold,
                }}
                onClick={() => setSteps(2)}
              >
                <RadioButtonChecked />
                {i18n["empMyCam.recordvideo"]}
              </Button>
              <input
                accept={fileAccept}
                ref={hiddenFileInput}
                type="file"
                onChange={handleFileChange}
                style={{ display: "none" }}
              />
              <Button
                variant="contained"
                color="uploadVideoButton"
                sx={{
                  width: "50%",
                  height: "60px",
                  borderRadius: "0 0 25px 0px",
                  fontSize: "15px",
                  gap: "10px",
                  fontWeight: theme.typography.Bold,
                }}
                onClick={handleFileClick}
              >
                <Box
                  component={"img"}
                  src={uploadNewVideoIcon}
                  sx={{
                    height: "20px",
                    width: "24px",
                  }}
                />
                {i18n["empMyCam.uploadvideo"]}
              </Button>
            </Box>
          </>
        )}
        {steps === 2 && <CounterDialog nextStep={setSteps} name={"job"} />}
        {steps === 3 && (
          <Box
            sx={{
              width: "100%",
              height: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <RecordingInProgress
              nextStep={setSteps}
              onRecordingStop={handleVideoData}
              uploadFunc={uploadVideo}
              name={"job"}
              handleStopRecording={handleStopRecording}
              handleCloseCamera={handleCloseCamera}
              setMediaStream={setMediaStream}
              mediaStream={mediaStream}
              mediaRecorderRef={mediaRecorderRef}
            />
          </Box>
        )}
        {steps === 4 && (
          <>
            <Button
              variant="contained"
              color="jobVideoButton"
              sx={{
                borderRadius: "25px 0 25px 0",
                width: "250px",
                height: "60px",
                fontSize: "20px",
                fontWeight: theme.typography.Bold,
                gap: "10px",
              }}
            >
              <Box
                component={"img"}
                src={jobVideoHeaderLogo}
                sx={{
                  height: "20px",
                  width: "24px",
                }}
              />
              {i18n["empMyCam.jobvideo"]}
            </Button>

            <Box
              sx={{
                width: "70%",
                margin: "auto",
                flexDirection: "column",
                alignItems: "center",
                display: "flex",
                gap: "20px",
              }}
            >
              <Typography
                sx={{
                  fontSize: "20px",
                  fontWeight: theme.typography.Bold,
                  textAlign: "center",
                }}
              >
                {i18n["empMyCam.nailedIt"]}
              </Typography>
              <Typography
                sx={{
                  fontSize: "14px",
                  textAlign: "center",

                  fontWeight: theme.typography.Regular,
                }}
              >
                {i18n["empMyCam.successfullyRecorded"]}
              </Typography>
              <Typography
                sx={{
                  fontSize: "14px",
                  textAlign: "center",

                  fontWeight: theme.typography.Regular,
                }}
              >
                {i18n["empMyCam.rerecord"]}
              </Typography>
            </Box>

            <Box
              sx={{
                flexGrow: 1,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: "30px",
                flexDirection: "column",
                position: "relative",
              }}
            >
              {!isPlaying && <PlayButton />}

              <video
                ref={VideoRef}
                preload="auto"
                loops
                autoPlay={false}
                poster=""
                style={{
                  width: disabledButton ? "74.4%" : "75%",
                  borderRadius: "25px 25px 0 0",
                  height: "auto",
                  aspectRatio: "4/3",
                  objectFit: "cover",
                  cursor: disabledButton ? "wait" : "pointer",
                  border: disabledButton
                    ? `1px solid ${theme.palette.grayButton600.main}`
                    : "none",
                  borderBottom: "none", // To remove the border for the bottom side
                }}
                muted={false}
                src={videoLink || recordedVideoData}
                type="video/mp4"
                onClick={() => {
                  if (!disabledButton) {
                    if (VideoRef?.current?.paused) {
                      VideoRef?.current?.play();
                      setIsPlaying(true);
                    } else {
                      VideoRef?.current?.pause();
                      setIsPlaying(false);
                    }
                  }
                }}
                onLoadedData={() => {
                  setDisabledButton(false);
                }}
                onEnded={() => setIsPlaying(false)}
              ></video>
              <Box
                sx={{
                  display: "flex",
                  width: "75%",
                  background: theme.palette.mainBackground,
                  borderRadius: "0 0 25px 25px",
                  height: "60px",
                }}
              >
                <Box
                  sx={{
                    width: "70%",
                    margin: "auto",
                    padding: "0 0 0 20px ",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "15px",
                      fontWeight: theme.typography.Bold,
                    }}
                  >
                    {job?.title}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "13px",
                      fontWeight: theme.typography.Regular,
                    }}
                  >
                    {job?.CompanyName}
                  </Typography>
                </Box>
                <Button
                  variant="contained"
                  color="videoButton"
                  sx={{
                    width: "30%",
                    height: "100%",
                    borderRadius: "0 0 25px 0",
                  }}
                  onClick={() => setShowBrief(true)}
                >
                  {i18n["empMyCam.theBrief"]}
                </Button>
                {showBrief && (
                  <JobVideoBriefBox
                    show={showBrief}
                    handleOpen={handleCloseBriefBox}
                  />
                )}
              </Box>
            </Box>

            <Box
              sx={{
                margin: "auto",
                width: "100%",
                height: "60px",
              }}
            >
              <Button
                variant="contained"
                color="deleteVideoButton"
                sx={{
                  borderRadius: "0 0 0 25px",
                  width: "33.33%",
                  height: "100%",
                  gap: "8px",
                }}
                onClick={handleOpenDelete}
              >
                <Box component={"img"} src={deleteIcon} sx={{ height: 18 }} />
                {i18n["empMyCam.delete"]}
              </Button>
              <Button
                variant="contained"
                color="videoButton"
                sx={{
                  borderRadius: 0,
                  width: "33.33%",
                  height: "100%",
                  gap: "8px",
                }}
                onClick={() => setSteps(2)}
              >
                {/* <RadioButtonChecked /> */}
                {(width > 767 || width === 0) && (
                <Box
                  component={"img"}
                  src={jobVideoHeaderLogo}
                  sx={{
                    height: "20px",
                    width: "24px",
                  }}
                />
              )}
                {i18n["empMyCam.recordNewVideo"]}
              </Button>
              <input
                accept={fileAccept}
                ref={hiddenFileInput}
                type="file"
                onChange={handleFileChange}
                style={{ display: "none" }}
              />
              <Button
                variant="contained"
                color="uploadVideoButton"
                sx={{
                  borderRadius: "0 0 25px 0",
                  width: "33.33%",
                  height: "100%",
                  gap: "8px",
                }}
                onClick={handleFileClick}
              >
                <Box
                  component={"img"}
                  src={uploadNewVideoIcon}
                  sx={{
                    height: "20px",
                    width: "24px",
                  }}
                />
                {i18n["empMyCam.uploadNewVideo"]}
              </Button>
            </Box>
          </>
        )}
      </Dialog>
      <Delete
        show={openDelete}
        handleOpen={handleOpenDelete}
        handleCancel={handleCancelDelete}
        handleDelete={handleDeleteVideo}
        confirmDelete={confirmDelete}
        setConfirmDelete={setConfirmDelete}
      />
    </>
  );
}
