import { useTheme } from "@emotion/react";
import {
  Box,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
  Button,
} from "@mui/material";
import Slider from "@mui/material/Slider";
import React, { useCallback, useEffect, useState } from "react";
import locale from "../../../../i18n/locale";
import CustomSelect from "../../../candidate/myCV/CustomSelect";
import { useDispatch } from "react-redux";
import {
  checkNumberRange,
  debounce,
  formatNumber,
  genarateLabelValue,
  genarateLabelValueForFreelance,
  rangeValueExperience,
} from "../../../../utils/Common";
import {
  getIndustries,
  getLanguage,
  getAssociation,
  getNationality,
} from "../../../../redux/candidate/myCvSlice";
import {
  getTitles,
  getSkills,
  getQualification,
  getRequiredQualification,
  getCurrency,
  getCountry,
  getRoleTypes,
  getWorkSetup,
  getTown,
  getTools,
  updateToInitialSkills,
  getContractDetails,
} from "../../../../redux/employer/postJobSlice";
import { setAlert, setLoading } from "../../../../redux/configSlice";
import {
  ALERT_TYPE,
  BASIC_MARKS,
  BASIC_RANGEMARKS_SLIDER,
  ERROR_MSG,
} from "../../../../utils/Constants";
import SelectMenu from "../../../common/SelectMenu";
import AutoComplete from "../../../common/AutoComplete";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import {
  BlueSwitch,
  getFemaleIcon,
  getMaleIcon,
} from "../../../../utils/CommonComponent";
import { CheckCircle } from "@mui/icons-material";
import black from "../../../../assets/Characters/Race/crayon_race_black.svg";
import white from "../../../../assets/Characters/Race/crayon_race_white.svg";
import indian from "../../../../assets/Characters/Race/crayon_race_indian.svg";
import coloured from "../../../../assets/Characters/Race/crayon_race_coloured.svg";
import own_internet from "../../../../assets/Padding Included/Crayon Icon_Own Internet.svg";
import own_equipment from "../../../../assets/Padding Included/Crayon Icon_Own Equipment.svg";
import own_vehicle from "../../../../assets/Padding Included/Crayon Icon_Own Vehicle.svg";
import maleBlack from "../../../../assets/male_blue.svg";
import maleWhite from "../../../../assets/male_white.svg";
import femaleBlack from "../../../../assets/female_pink.svg";
import femaleWhite from "../../../../assets/female_white (2).svg";
import SalarySlider from "../../../common/SalarySlider";

export default function BasicInfo({
  jobDetail,
  setJobDetail,
  tools,
  skills,
  industries,
  titles,
  roleTypes,
  contractDetails,
  requiredQua,
  qualifications,
  initialSkills,
  language,
  nationality,
  association,
  workSetup,
  country,
  town,
  currency,
  errors,
  setErrors,
}) {
  const i18n = locale.en;
  const theme = useTheme();
  const currentDate = new Date();
  const dispatch = useDispatch();
  const [salaryRange, setSalaryRange] = useState(BASIC_RANGEMARKS_SLIDER);
  const [expRange, setExpRange] = useState(
    jobDetail?.experience ? jobDetail?.experience : [0, 2]
  );
  const [salaryRangeValue, setSalaryRangeValue] = useState(
    jobDetail?.salary?.[0] > 0 ? true : false
  );
  const [singleSalaryValue, setSingleSalaryValue] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [interval, setInterval] = useState();
  // const [rangeValue, setRangeValue] = useState(
  //   jobDetail?.salary
  //     ? [
  //         jobDetail?.job_role_type === "freelance"
  //           ? jobDetail?.salary?.[0] / 5
  //           : jobDetail?.salary?.[0] / 2000,
  //         jobDetail?.job_role_type === "freelance"
  //           ? jobDetail?.salary?.[1] / 5
  //           : jobDetail?.salary?.[1] / 2000,
  //       ]
  //     : [0, 20]
  // );
  const [rangeValue, setRangeValue] = useState([]);

  const getAllDataNew = async (para, country_id) => {
    try {
      switch (para) {
        case "titles":
          await dispatch(getTitles());
          return;
        case "jobRole":
          await dispatch(getRoleTypes());
          return;
        case "contractDetails":
          await dispatch(getContractDetails());
          return;
        case "workSetup":
          await dispatch(getWorkSetup());
          return;
        case "country":
          await dispatch(getCountry());
          return;
        case "townsMain":
          await dispatch(getTown(country_id));
          return;
        case "industries":
          await dispatch(getIndustries());
          return;
        case "skills":
          await dispatch(getSkills());
          return;
        case "tools":
          await dispatch(getTools());
          return;
        case "nationality":
          await dispatch(getNationality());
          return;
        case "language":
          await dispatch(getLanguage());
          return;
        case "requiredQua":
          await dispatch(getRequiredQualification());
          return;
        case "qualifications":
          await dispatch(getQualification());
          return;
        case "associations":
          await dispatch(getAssociation());
          return;

        case "currency":
          await dispatch(getCurrency());
          return;

        default:
          return;
      }
    } catch (error) {
      dispatch(setLoading(false));
      dispatch(
        setAlert({
          show: true,
          type: ALERT_TYPE?.ERROR,
          msg: ERROR_MSG,
        })
      );
    }
  };

  const handleOnSelectInputChange = (newValue, inputId) => {
    debouncedHandleOnSelectInputChange(newValue, inputId);
  };

  const debouncedHandleOnSelectInputChange = useCallback(
    debounce(
      async (newValue, type) => {
        let params = {
          title: newValue || "",
          limit: 200,
        };
        if (newValue) {
          // getlist(params, type);
          try {
            if (type === "industry_id") {
              dispatch(
                getIndustries({ ...params, setState: setIsLoading, type })
              );
            }
            if (type === "skills") {
              dispatch(getSkills({ ...params, setState: setIsLoading, type }));
            }
            if (type === "tools") {
              dispatch(getTools({ ...params, setState: setIsLoading, type }));
            }
            if (type === "preferred_qualification_ids") {
              dispatch(
                getQualification({ ...params, setState: setIsLoading, type })
              );
            }
            if (type === "association_ids") {
              dispatch(
                getAssociation({ ...params, setState: setIsLoading, type })
              );
            }
            if (type === "job_title_id" || type === "alternateTitle") {
              dispatch(getTitles({ ...params, setState: setIsLoading, type }));
            }
            if (type === "town_id") {
              dispatch(
                getTown({
                  ...params,
                  id: jobDetail?.country_id?.value,
                  setState: setIsLoading,
                  type,
                })
              );
            }
          } catch (error) {
            dispatch(
              setAlert({
                show: true,
                type: ALERT_TYPE?.ERROR,
                msg: error,
              })
            );
          }
        }
      },
      750,
      setIsLoading
    ),
    []
  );

  const handleOnSelectChange = (ind, newValue, val, inputId) => {
    let newJobDetailData = { ...jobDetail };
    if (ind === "industry_id" || ind === "tools" || ind === "skills"|| 
      ind === "alternateTitle"
    ) {
      newJobDetailData[ind] = newValue;
      if (ind === "tags") {
        dispatch(updateToInitialSkills());
      }
    } else if (
      newValue &&
      newValue?.label &&
      !["industry_id", "tags", "tools"]?.includes(newValue)
    ) {
      newJobDetailData[inputId] = newValue;
    } else {
      newJobDetailData[inputId] = newValue?.id || newValue;
    }
    if (inputId === "preferred_qualification_ids" && newValue?.value) {
      newJobDetailData[inputId] = [newValue];
    }
    if (inputId === "association_ids" && newValue?.value) {
      newJobDetailData[inputId] = [newValue];
    }
    if (inputId === "country_id" && newValue?.value) {
      getAllDataNew("townsMain", newValue?.value);
      newJobDetailData["town_id"] = "";
    }
    if (inputId === "association_ids" && newValue === null) {
      newJobDetailData["association_ids"] = [];
    }
    if (newJobDetailData?.job_title_id?.value && inputId === "job_title_id") {
      setErrors(errors?.filter((item) => item?.key !== inputId));
    }
    // if (newJobDetailData?.association_ids) {
    //   setErrors(errors.filter((item) => item.key !== inputId));
    // }
    if (newJobDetailData?.industry_id?.length > 0 && ind === "industry_id") {
      setErrors(errors?.filter((item) => item?.key !== ind));
    }
    if (newJobDetailData?.skills?.length >= 3 && ind === "skills") {
      setErrors(errors?.filter((item) => item?.key !== ind));
    }
    if (newJobDetailData?.tools?.length >= 3 && ind === "tools") {
      setErrors(errors?.filter((item) => item?.key !== ind));
    }
    if (newJobDetailData?.country_id && inputId === "country_id") {
      setErrors(errors?.filter((item) => item?.key !== inputId));
    }
    if (newJobDetailData?.town_id && inputId === "town_id") {
      setErrors(errors?.filter((item) => item.key !== inputId));
    } else if (newJobDetailData?.industry_id?.length > 0) {
      setErrors(errors?.filter((item) => item?.key !== ind));
    } else if (newJobDetailData?.skills?.length >= 3) {
      setErrors(errors?.filter((item) => item?.key !== ind));
    } else if (newJobDetailData?.tools?.length >= 3) {
      setErrors(errors?.filter((item) => item?.key !== ind));
    } else if (newJobDetailData?.country_id) {
      setErrors(errors?.filter((item) => item?.key !== ind));
    } else if (newJobDetailData?.town_id) {
    }
    if (newJobDetailData?.tools?.length >= 3 && ind === "tools") {
      setErrors(errors?.filter((item) => item?.key !== ind));
    }
    if (newJobDetailData?.country_id && inputId === "country_id") {
      setErrors(errors?.filter((item) => item?.key !== inputId));
    }
    if (newJobDetailData?.town_id && inputId === "town_id") {
      setErrors(errors?.filter((item) => item?.key !== inputId));
    }
    setJobDetail(newJobDetailData);
  };

  const onCreateOption = (data, inputId) => {
    let newJobDetailData = {};
    if (data) {
      // Create a new value from the user input
      newJobDetailData = {
        ...jobDetail,
        [inputId]: { label: data, value: data },
      };
    }
    setErrors(errors?.filter((item) => item?.key !== inputId));
    setJobDetail(newJobDetailData);
  };
  const onMenuOpen = () => {
    if (titles?.length < 49) {
      getAllDataNew("titles");
    }
    setIsMenuOpen(true);
  };
  const onMenuClose = () => {
    setIsMenuOpen(false);
  };

  const handleJobRoleChange = (event) => {
    let newJobDetailData = { ...jobDetail };
    const currencySalary = currency?.find(
      (item) => item?.currency_id === jobDetail?.currency_id
    );
    let range;
    if (event?.target?.value === 3) {
      let { rangeArray, interval } = genarateLabelValueForFreelance(
        currencySalary ? currencySalary : { max_rate: 2050 }
      );
      range = rangeArray;
      setInterval(interval);
      setRangeValue([range?.length / 10, range?.length / 2]);
      setSingleSalaryValue(range?.length / 2);
      newJobDetailData = {
        ...newJobDetailData,
        salary: [
          (range?.length / 10) * interval,
          (range?.length / 2) * interval,
        ],
      };
    } else {
      range = genarateLabelValue(
        currencySalary ? currencySalary : { max_salary: 205000 }
      );
      if (jobDetail?.currency_id === 1) {
        newJobDetailData = {
          ...newJobDetailData,
          salary: [30000, 100000],
        };
        setRangeValue([3, 10]);
        setSingleSalaryValue(10);
      } else {
        newJobDetailData = {
          ...newJobDetailData,
          salary: [
            2000,
            Math.floor(currencySalary?.max_salary / 2 / 100) * 100,
          ],
        };
        setRangeValue([2, 5.2]);
        setSingleSalaryValue(5.2);
      }
    }
    setSalaryRange(range);
    newJobDetailData = {
      ...newJobDetailData,
      job_role_type:
        event?.target?.value === "None"
          ? ""
          : roleTypes?.find((role) => role?.id == event?.target?.value)?.name,
      contract_duration:
        event?.target?.value !== 2 ? "" : jobDetail?.contract_duration,
    };
    setErrors(errors?.filter((item) => item?.key !== event?.target?.name));
    setJobDetail(newJobDetailData);
  };

  const handleContractDurationChange = (event) => {
    const newJobDetailData = {
      ...jobDetail,
      contract_duration:
        event?.target?.value === "None"
          ? ""
          : event?.target?.value?.split(" ")?.[0],
    };
    setJobDetail(newJobDetailData);
  };

  const getQuaValue = () => {
    if (jobDetail?.preferred_qualification_ids?.length == 0) {
      return [];
    }
    return jobDetail?.preferred_qualification_ids;
  };
  const getSkillValue = () => {
    if (jobDetail?.skills?.length == 0) {
      return [];
    }
    return jobDetail?.skills?.map(
      (id) => skills?.find((skill) => skill?.id == id) || id
    );
  };

  const getToolValue = () => {
    if (jobDetail?.tools?.length == 0) {
      return [];
    }

    return jobDetail?.tools?.map(
      (id) => tools?.find((tool) => tool?.id == id) || id
    );
  };

  const getIndustriesValue = () => {
    if (jobDetail?.industry_id?.length === 0) {
      return [];
    }
    return jobDetail?.industry_id?.map(
      (id) => industries?.find((industry) => industry?.industry_id === id) || id
    );
  };
  const getAlternateTitleValue = () => {
    if (jobDetail?.alternateTitle?.length == 0) {
      return [];
    }
    return jobDetail?.alternateTitle?.map(
      (id) => titles?.find((industry) => industry?.job_title_id === id) || id
    );
  };
  const getLangValue = () => {
    if (jobDetail?.language_ids?.length === 0) {
      return [];
    }
    return jobDetail?.language_ids?.map(
      (lang) =>
        language?.find((language) => {
          return language?.language_id == lang;
        }) || lang
    );
  };

  const getNationalityValue = () => {
    if (jobDetail?.nationality_ids?.length === 0) {
      return [];
    }
    return jobDetail?.nationality_ids?.map(
      (national) =>
        nationality?.find((nationality) => {
          return nationality?.nationality_id == national;
        }) || national
    );
  };

  const getAssociationValue = () => {
    if (jobDetail?.association_ids?.length === 0) {
      return [];
    }
    return jobDetail?.association_ids;
  };

  const handleMultipleAutoComplete = ({
    event,
    newValue,
    id,
    minTags,
    maxTags,
  }) => {
    if (newValue?.length > maxTags) {
      newValue?.splice(newValue, 1);
      dispatch(
        setAlert({
          show: true,
          type: ALERT_TYPE?.ERROR,
          msg: `The limit is ${minTags} to ${maxTags}`,
        })
      );
      return;
    }
    let newJobDetailData = {
      ...jobDetail,
      [id]: newValue?.map((val) => val?.inputValue || val?.id || val),
    };
    setErrors(errors?.filter((item) => item?.key !== id));
    setJobDetail(newJobDetailData);
  };

  const handleWorkSetup = (event) => {
    const {
      target: { value },
      target: { name },
      target: { id },
    } = event;
    const newJobDetailData = {
      ...jobDetail,
      [name || id]: workSetup?.find((work) => work?.id == value)?.name || "",
    };
    setErrors(errors?.filter((item) => item?.key !== name));
    setJobDetail(newJobDetailData);
  };

  const handleChange = (event) => {
    const {
      target: { value },
      target: { name },
      target: { id },
    } = event;
    let newJobDetailData = { ...jobDetail };
    let range;
    if (name === "currency_id") {
      const currencySalary = currency?.find(
        (item) => item?.currency_id === value
      );
      if (jobDetail?.job_role_type === "freelance") {
        let { rangeArray, interval } = genarateLabelValueForFreelance(
          currencySalary ? currencySalary : { max_rate: 2050 }
        );
        range = rangeArray;
        setInterval(interval);
        setRangeValue([range?.length / 10, range?.length / 2]);
        setSingleSalaryValue(range?.length / 2);
        newJobDetailData = {
          ...jobDetail,
          [name]: value,
          salary: [
            (range?.length / 10) * interval,
            (range?.length / 2) * interval,
          ],
        };
      } else {
        range = genarateLabelValue(
          currencySalary ? currencySalary : { max_salary: 205000 }
        );
        if (currencySalary?.currency === "ZAR") {
          setRangeValue([3, 10]);
          setSingleSalaryValue(10);
          newJobDetailData = {
            ...jobDetail,
            [name]: value,
            salary: [30000, 100000],
          };
        } else {
          newJobDetailData = {
            ...jobDetail,
            [name]: value,
            salary: [
              2000,
              Math.floor(currencySalary?.max_salary / 2 / 100) * 100,
            ],
          };
          setRangeValue([2, 5.2]);
          setSingleSalaryValue(5.2);
        }
      }
      setSalaryRange(range);
      setJobDetail(newJobDetailData);
    }
  };

  const handleDateChange = (newValue) => {
    let formatedDate =
      dayjs(newValue)?.format("YYYY-MM-DD") === "Invalid Date"
        ? ""
        : dayjs(newValue)?.format("YYYY-MM-DD");
    const newJobDetailData = {
      ...jobDetail,
      job_start_date: formatedDate,
    };
    const filteredErrors = errors?.filter(
      (item) => item?.key != "job_start_date"
    );
    setErrors(filteredErrors);
    setJobDetail(newJobDetailData);
  };

  const rangeValueHandler = (value) => {
    const symbol = currency?.find(
      (curr) => curr?.currency_id === jobDetail?.currency_id
    )
      ? currency?.find((curr) => curr?.currency_id === jobDetail?.currency_id)
      : "$";
    let valueNumberRange = checkNumberRange(symbol?.max_salary);
    let extractedValue = salaryRange?.find(
      (item) => item?.value === value
    )?.label;
    let finalValue = extractedValue ? parseFloat(extractedValue) : value;
    return `${symbol?.symbol ? symbol?.symbol : symbol} ${
      valueNumberRange === "thousands"
        ? formatNumber(value * 1000)
        : formatNumber(value * 10000)
    }`;
  };

  const rangeValueHandler2 = (value) => {
    const symbol = currency?.find(
      (curr) => curr?.currency_id === jobDetail?.currency_id
    )
      ? currency?.find((curr) => curr?.currency_id === jobDetail?.currency_id)
      : "$";
    return `${symbol?.symbol ? symbol?.symbol : symbol} ${formatNumber(
      value * interval
    )}`;
  };

  const handleRangeSliderChange = (event, newValue) => {
    setRangeValue(newValue);
    let newArr = newValue?.map((val) => val * 1000);
    const newJobDetailData = {
      ...jobDetail,
      [event?.target?.name]:
        jobDetail?.currency_id === 1
          ? [newArr?.[0] * 10, newArr?.[1] * 10]
          : newArr,
    };
    const filteredErrors = errors?.filter(
      (item) => item?.key != event?.target?.name
    );
    setErrors(filteredErrors);
    setJobDetail(newJobDetailData);
  };

  const handleRangeSliderChange2 = (event, newValue) => {
    setRangeValue(newValue);
    let newArr = newValue?.map((val) => Math.round(val * interval));
    const newJobDetailData = {
      ...jobDetail,
      [event?.target?.name]: newArr,
    };

    const filteredErrors = errors?.filter(
      (item) => item?.key != event?.target?.name
    );
    setErrors(filteredErrors);
    setJobDetail(newJobDetailData);
  };

  const singleValueSlider2 = (event) => {
    const {
      target: { value },
      target: { name },
    } = event;
    let newArr = [0, Math.round(value * interval)];
    const newBasicData = {
      ...jobDetail,
      [event?.target?.name]: newArr,
    };
    setSingleSalaryValue(value);
    const filteredErrors = errors?.filter(
      (item) => item?.key != event?.target?.name
    );
    setErrors(filteredErrors);
    setJobDetail(newBasicData);
  };

  const singleValueSlider = (event) => {
    const {
      target: { value },
      target: { name },
    } = event;
    setSingleSalaryValue(value);
    let newArr = [0, value * 1000];
    const newBasicData = {
      ...jobDetail,
      [event?.target?.name]:
        jobDetail?.currency_id === 1
          ? [newArr?.[0] * 10, newArr?.[1] * 10]
          : newArr,
    };
    const filteredErrors = errors?.filter(
      (item) => item?.key != event?.target?.name
    );
    setErrors(filteredErrors);
    setJobDetail(newBasicData);
  };

  const handleSalaryValue = (event) => {
    const {
      target: { value },
      target: { name },
      target: { id },
    } = event;
    setSalaryRangeValue(event?.target?.checked);

    if (jobDetail?.job_role_type === "freelance") {
      if (jobDetail?.currency_id === 1) {
        setRangeValue([
          jobDetail?.salary?.[0] / 100,
          jobDetail?.salary?.[1] / 100,
        ]);
        setSingleSalaryValue(jobDetail?.salary?.[1] / 100);
      } else {
        setRangeValue([
          jobDetail?.salary?.[0] / interval,
          jobDetail?.salary?.[1] / interval,
        ]);
        setSingleSalaryValue(jobDetail?.salary?.[1] / interval);
      }
    } else {
      if (jobDetail?.currency_id === 1) {
        setRangeValue([
          jobDetail?.salary?.[0] / 10000,
          jobDetail?.salary?.[1] / 10000,
        ]);
        setSingleSalaryValue(jobDetail?.salary?.[1] / 10000);
      } else {
        setRangeValue([
          jobDetail?.salary?.[0] / 1000,
          jobDetail?.salary?.[1] / 1000,
        ]);
        setSingleSalaryValue(jobDetail?.salary?.[1] / 1000);
      }
    }
    let newArr = [0, jobDetail?.salary?.[1]];
    const newBasicData = {
      ...jobDetail,
      salary:
        jobDetail?.currency_id === 1 ? [newArr?.[0], newArr?.[1]] : newArr,
    };
    const filteredErrors = errors?.filter(
      (item) => item?.key != event?.target?.name
    );
    setErrors(filteredErrors);
    setJobDetail(newBasicData);
  };

  const expHandleChange = (event, newValue) => {
    setExpRange(newValue);
    let newArr = newValue?.map((val) => val);
    const newJobDetailData = {
      ...jobDetail,
      [event?.target?.name]: newArr,
    };

    const filteredErrors = errors?.filter(
      (item) => item?.key != event?.target?.name
    );
    setErrors(filteredErrors);
    setJobDetail(newJobDetailData);
  };

  const handleColor = (event, color) => {
    const newBasicData = {
      ...jobDetail,
      skinz: color,
    };
    setJobDetail(newBasicData);
  };

  const handleSwitch = (event) => {
    const newBasicData = {
      ...jobDetail,
      [event?.target?.id]: event?.target?.checked,
    };
    setJobDetail(newBasicData);
  };

  const handleGender = (event, gender) => {
    const newBasicData = {
      ...jobDetail,
      gender: gender,
    };
    const filteredErrors = errors?.filter((item) => item?.key != "gender");
    setErrors(filteredErrors);

    setJobDetail(newBasicData);
  };

  const handleRequiredQualificationLevel = (event) => {
    const {
      target: { value },
      target: { name },
      target: { id },
    } = event;
    const newJobDetailData = {
      ...jobDetail,
      [name || id]: requiredQua?.find((work) => work?.id == value)?.id,
    };
    setErrors(errors?.filter((item) => item?.key !== name));
    setJobDetail(newJobDetailData);
  };

  useEffect(() => {
    const currencySalary = currency?.find(
      (item) => item?.currency_id === jobDetail?.currency_id
    );
    let range;
    let salaryInterval;
    if (jobDetail?.job_role_type === "freelance") {
      let { rangeArray, interval } = genarateLabelValueForFreelance(
        currencySalary ? currencySalary : { max_rate: 2050 }
      );
      range = rangeArray;
      salaryInterval = interval;
      setInterval(interval);
      setRangeValue([range?.length / 10, range?.length / 2]);
      setSingleSalaryValue(range?.length / 2);
    } else {
      range = genarateLabelValue(
        currencySalary ? currencySalary : { max_salary: 205000 }
      );
    }
    setSalaryRange(range);

    if (jobDetail?.job_role_type === "freelance") {
      if (jobDetail?.currency_id === 1) {
        jobDetail?.salary?.[0] > 0
          ? setRangeValue([
              jobDetail?.salary?.[0] / 100,
              jobDetail?.salary?.[1] / 100,
            ])
          : setSingleSalaryValue(jobDetail?.salary?.[1] / 100);
      } else {
        jobDetail?.salary?.[0] > 0
          ? setRangeValue([
              jobDetail?.salary?.[0] / salaryInterval,
              jobDetail?.salary?.[1] / salaryInterval,
            ])
          : setSingleSalaryValue(jobDetail?.salary?.[1] / salaryInterval);
      }
    } else {
      if (jobDetail?.currency_id === 1) {
        jobDetail?.salary?.[0] > 0
          ? setRangeValue([
              jobDetail?.salary?.[0] / 10000,
              jobDetail?.salary?.[1] / 10000,
            ])
          : setSingleSalaryValue(jobDetail?.salary?.[1] / 10000);
      } else {
        jobDetail?.salary?.[0] > 0
          ? setRangeValue([
              jobDetail?.salary?.[0] / 1000,
              jobDetail?.salary?.[1] / 1000,
            ])
          : setSingleSalaryValue(jobDetail?.salary?.[1] / 1000);
      }
    }
  }, []);

  return (
    <Box sx={{ p: 4, display: "flex", flexDirection: "column", gap: "16px" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          mb: 3,
          flexDirection: "column",
          gap: { xs: "22px", lgm: "42px" },
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
          }}
        >
          <InputLabel
            htmlFor="job_title_id"
            sx={{
              color: "black",
              paddingBottom: "5px",
              fontSize: "14px",
              fontWeight: theme.typography.Bold,
            }}
          >
            {i18n["postAJob.jobTitleLabelReq"]}
          </InputLabel>
          <Box>
            <CustomSelect
              variant="createSelect"
              onInputChange={handleOnSelectInputChange}
              onChange={handleOnSelectChange}
              onCreateOption={onCreateOption}
              placeholder={i18n["postAJob.jobTitle"]}
              inputId="job_title_id"
              name="job_title_id"
              value={
                jobDetail?.job_title_id?.label && jobDetail?.job_title_id?.value
                  ? {
                      label: jobDetail?.job_title_id?.label
                        ? jobDetail?.job_title_id?.label
                        : "",
                      value: jobDetail?.job_title_id?.value
                        ? jobDetail?.job_title_id?.value
                        : "",
                    }
                  : null
              }
              onMenuOpen={onMenuOpen}
              onMenuClose={onMenuClose}
              option={titles?.map((item) => ({
                value: item?.job_title_id,
                label: item?.title,
              }))}
              isLoading={isLoading}
              customStyle={{
                menu: (provided) => ({
                  ...provided,
                  zIndex: 9999,
                }),
                menuList: (provided) => ({
                  ...provided,
                  "&::-webkit-scrollbar": {
                    width: "6px",
                    height: "6px",
                    borderRadius: "2.5px",
                    backgroundColor: "#efefef",
                  },
                  "&::-webkit-scrollbar-thumb": {
                    borderRadius: "2.5px",
                  },
                }),
                control: (baseStyles) => ({
                  ...baseStyles,
                  boxShadow: "none",
                  borderRadius: "10px",
                  minHeight: "40px",
                  border:
                    // !titles?.find(
                    //   (title) => title.id == jobDetail.job_title_id
                    // ) &&
                    // !jobDetail.job_title_id &&
                    errors?.find((error) => error?.key == "job_title_id")
                      ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
                      : "1px solid #E0E0E0",
                  "&:hover":
                    // !titles?.find(
                    //   (title) => title.id == jobDetail.job_title_id
                    // ) &&
                    // !jobDetail.job_title_id &&
                    errors?.find((error) => error?.key == "job_title_id")
                      ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
                      : "1px solid #E0E0E0",
                  width: `100%`,
                }),
              }}
            />
          </Box>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
          }}
        >
          <InputLabel
            htmlFor="work_setup"
            sx={{
              color: "black",
              paddingBottom: "5px",
              fontSize: "14px",
              fontWeight: theme.typography.Bold,
            }}
          >
            {i18n["postAJob.workSetupLableReq"]}
          </InputLabel>
          <SelectMenu
            borderRadius="10px"
            name="work_setup"
            value={jobDetail?.work_setup}
            onHandleChange={handleWorkSetup}
            options={workSetup}
            clear={true}
            border={
              // !jobDetail.work_setup &&
              errors?.find((error) => error?.key == "work_setup")
                ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
                : "1px solid #E0E0E0"
            }
            onFocus={() => {
              if (workSetup?.length === 0) {
                getAllDataNew("workSetup");
              }
            }}
            placeholder={"Select between remote, in-office or hybrid"}
          />
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", mdl: "row" },
            width: "100%",
            gap: { xs: "22px", lgmm: "42px" },
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={{
              display: "flex",
              width: "50%",
              flexDirection: "column",
            }}
          >
            <InputLabel
              htmlFor="job_role_type"
              sx={{
                color: "black",
                paddingBottom: "5px",
                fontSize: "14px",
                fontWeight: theme.typography.Bold,
              }}
            >
              {i18n["postAJob.roleTypeLabelReq"]}
            </InputLabel>
            <SelectMenu
              borderRadius="10px"
              name="job_role_type"
              value={jobDetail?.job_role_type}
              onHandleChange={handleJobRoleChange}
              options={roleTypes}
              clear={true}
              border={
                // !jobDetail.job_role_type &&
                errors?.find((error) => error?.key == "job_role_type")
                  ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
                  : "1px solid #E0E0E0"
              }
              onFocus={() => {
                if (roleTypes?.length === 0) {
                  getAllDataNew("jobRole");
                }
              }}
              placeholder={"Select between contract, freelance or permanent"}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              width: "50%",
              flexDirection: "column",
            }}
          >
            <InputLabel
              htmlFor="Contract_Duration"
              sx={{
                color: "black",
                paddingBottom: "5px",
                fontSize: "14px",
                fontWeight: theme.typography.Bold,
              }}
            >
              {i18n["postAJob.contemplatorsact_DurationLabelOpt"]}
            </InputLabel>
            <Paper
              elevation={3}
              sx={{
                display: "flex",
                borderRadius: "10px",
                height: "40px",
                boxShadow: "none",
                border: `1px solid ${theme.palette.grayBorder}`,
              }}
            >
              <Select
                disabled={jobDetail?.job_role_type !== "contract"}
                sx={{
                  height: "40px",
                  width: "100%",
                  borderRadius: "10px",

                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "none",
                  },
                }}
                renderValue={(selected) => {
                  if (!selected || selected === "None") {
                    return (
                      <em style={{ opacity: "0.4", fontStyle: "normal" }}>
                        Select the contract duration
                      </em>
                    );
                  } else {
                    return selected + " months";
                  }
                }}
                onFocus={() => {
                  if (contractDetails?.length === 0) {
                    getAllDataNew("contractDetails");
                  }
                }}
                onChange={handleContractDurationChange}
                value={jobDetail?.contract_duration || "None"}
              >
                <MenuItem value="None">
                  <em>None</em>
                </MenuItem>
                {contractDetails?.map((button) => (
                  <MenuItem
                    key={button?.contract_duration_id}
                    value={button?.name}
                  >
                    {button?.name}
                  </MenuItem>
                ))}
              </Select>
            </Paper>
          </Box>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", mdl: "row" },
            width: "100%",
            gap: { xs: "22px", lgmm: "42px" },
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={{
              display: "flex",
              width: "47%",
              flexDirection: "column",
            }}
          >
            <InputLabel
              htmlFor="country_id"
              sx={{
                color: "black",
                paddingBottom: "5px",
                fontSize: "14px",
                fontWeight: theme.typography.Bold,
              }}
            >
              {i18n["postAJob.countryReq"]}
            </InputLabel>
            <CustomSelect
              onMenuOpen={() => {
                if (country?.length === 0) {
                  getAllDataNew("country");
                }
              }}
              variant="default"
              onInputChange={handleOnSelectInputChange}
              onChange={handleOnSelectChange}
              placeholder={"Select"}
              inputId="country_id"
              name="country_id"
              value={jobDetail?.country_id}
              isLoading={isLoading}
              filterOption={"filter"}
              customStyle={{
                menu: (provided) => ({
                  ...provided,
                  zIndex: 9999,
                }),
                menuList: (provided) => ({
                  ...provided,
                  "&::-webkit-scrollbar": {
                    width: "6px",
                    height: "6px",
                    borderRadius: "2.5px",
                    backgroundColor: "#efefef",
                  },
                  "&::-webkit-scrollbar-thumb": {
                    borderRadius: "2.5px",
                  },
                }),
                option: (
                  styles,
                  { data, isDisabled, isFocused, isSelected }
                ) => {
                  return {
                    ...styles,
                    color: isSelected ? "#fff" : "black",
                    ":active": {
                      ...styles[":active"],
                    },
                  };
                },
                control: (baseStyles) => ({
                  ...baseStyles,
                  boxShadow: "none",
                  borderRadius: "10px",
                  border: errors?.find((error) => error?.key == "country_id")
                    ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
                    : "1px solid #E0E0E0",
                  "&:hover": errors?.find((error) => error?.key == "country_id")
                    ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
                    : "1px solid #E0E0E0",
                  width: `100%`,
                }),
              }}
              option={country?.map((item) => ({
                value: item?.country_id,
                label: item?.name,
              }))}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              width: "47%",
              flexDirection: "column",
            }}
          >
            <InputLabel
              htmlFor="town_id"
              sx={{
                color: "black",
                paddingBottom: "5px",
                fontSize: "14px",
                fontWeight: theme.typography.Bold,
              }}
            >
              {/* City/Town */}
              {i18n["postAJob.cityTown"]}
            </InputLabel>

            <CustomSelect
              isDisabled={!jobDetail?.country_id}
              onMenuClose={() => {
                getAllDataNew(
                  "townsMain",
                  jobDetail?.town?.country?.country_id
                );
              }}
              onMenuOpen={() => {}}
              variant="default"
              onInputChange={handleOnSelectInputChange}
              onChange={handleOnSelectChange}
              placeholder={"Select"}
              inputId="town_id"
              name="town_id"
              // value={jobDetail?.town_id ? jobDetail?.town_id : null}
              value={jobDetail?.town_id}
              isLoading={isLoading}
              customStyle={{
                menu: (provided) => ({
                  ...provided,
                  zIndex: 9999,
                }),
                menuList: (provided) => ({
                  ...provided,
                  "&::-webkit-scrollbar": {
                    width: "6px",
                    height: "6px",
                    borderRadius: "2.5px",
                    backgroundColor: "#efefef",
                  },
                  "&::-webkit-scrollbar-thumb": {
                    borderRadius: "2.5px",
                  },
                }),
                option: (
                  styles,
                  { data, isDisabled, isFocused, isSelected }
                ) => {
                  return {
                    ...styles,
                    color: isSelected ? "#fff" : "black",
                    ":active": {
                      ...styles[":active"],
                    },
                  };
                },
                control: (baseStyles) => ({
                  ...baseStyles,
                  boxShadow: "none",
                  borderRadius: "10px",
                  border: errors?.find((error) => error?.key === "town_id")
                    ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
                    : "1px solid #E0E0E0",
                  "&:hover": errors?.find((error) => error?.key === "town_id")
                    ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
                    : "1px solid #E0E0E0",
                }),
              }}
              option={town?.map((item) => ({
                value: item?.town_id,
                label: item?.name,
              }))}
            />
          </Box>
        </Box>

        {/* Associated Industries */}
        <Box
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <InputLabel
              htmlFor="associated_industries"
              sx={{
                color: "black",
                paddingBottom: "5px",
                fontSize: "14px",
                fontWeight: theme.typography.Bold,
              }}
            >
              Alternative Job Title(s){" "}
              <span
                style={{
                  fontSize: "14px",
                  fontWeight: theme.typography.Regular,
                }}
              >
                (optional)
              </span>
            </InputLabel>
            <CustomSelect
              inputId={"alternateTitle"}
              name={"alternateTitle"}
              variant={"createMultiSelect"}
              closeMenuOnSelect={false}
              isMulti={true}
              isLoading={isLoading?.["alternateTitle"]}
              multiSelectOptions={titles?.map((item) => ({
                value: item?.job_title_id,
                label: item?.name,
              }))}
              placeholder={
                "Select or add up to 5 alternative job titles for the role (we use this for matching purposes)"
              }
              onChange={(e, val) => handleOnSelectChange(e, val)}
              customStyle={{
                option: (
                  styles,
                  { data, isDisabled, isFocused, isSelected }
                ) => {
                  return {
                    ...styles,
                    color: isSelected ? "#fff" : "black",
                    ":active": {
                      ...styles[":active"],
                    },
                  };
                },
                control: (baseStyles) => ({
                  ...baseStyles,
                  boxShadow: "none",
                  borderRadius: "10px",
                  minHeight: `40px`,
                  border: errors?.find((error) =>
                    error?.key?.startsWith("industry_id")
                  )
                    ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
                    : "1px solid #E0E0E0",
                  "&:hover": errors?.find((error) =>
                    error?.key?.startsWith("industry_id")
                  )
                    ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
                    : "1px solid #E0E0E0",
                  width: `100%`,
                }),
              }}
              onInputChange={handleOnSelectInputChange}
              multiOnFocus={() => {
                getAllDataNew("titles");
              }}
              value={getAlternateTitleValue()}
              limitTags={5}
            />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <InputLabel
            htmlFor="associated_industries"
            sx={{
              color: "black",
              paddingBottom: "5px",
              fontSize: "14px",
              fontWeight: theme.typography.Bold,
            }}
          >
            {i18n["postAJob.associated_industriesLabelReq"]}
          </InputLabel>

          <CustomSelect
            inputId={"industry_id"}
            name={"industry_id"}
            variant={"multiSelect"}
            closeMenuOnSelect={false}
            isMulti={true}
            multiSelectOptions={industries?.map((item) => ({
              value: item?.industry_id,
              label: item?.name,
            }))}
            placeholder={
              "Enter any industry(ies) relevant to the role (min 1, max 5)"
            }
            onChange={(e, val) => handleOnSelectChange(e, val)}
            customStyle={{
              option: (styles, { data, isDisabled, isFocused, isSelected }) => {
                return {
                  ...styles,
                  color: isSelected ? "#fff" : "black",
                  ":active": {
                    ...styles[":active"],
                  },
                };
              },
              control: (baseStyles) => ({
                ...baseStyles,
                boxShadow: "none",
                borderRadius: "10px",
                minHeight: `40px`,
                border:
                  // getIndustriesValue().length === 0 &&
                  errors?.find((error) => error?.key?.startsWith("industry_id"))
                    ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
                    : "1px solid #E0E0E0",
                "&:hover":
                  // getIndustriesValue().length === 0 &&
                  errors?.find((error) => error?.key?.startsWith("industry_id"))
                    ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
                    : "1px solid #E0E0E0",
                width: `100%`,
              }),
            }}
            onInputChange={handleOnSelectInputChange}
            multiOnFocus={() => {
              if (industries?.length < 49) {
                getAllDataNew("industries");
              }
            }}
            value={getIndustriesValue()}
            limitTags={5}
          />
        </Box>
        {/* skills */}
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <InputLabel
            htmlFor="skills"
            sx={{
              color: "black",
              paddingBottom: "5px",
              fontSize: "14px",
              fontWeight: theme.typography.Bold,
            }}
          >
            {i18n["postAJob.skillsLabelReq"]}
          </InputLabel>

          <CustomSelect
            inputId={"skills"}
            name={"skills"}
            variant={"createMultiSelect"}
            closeMenuOnSelect={false}
            isMulti={true}
            multiSelectOptions={skills?.map((item) => ({
              value: item?.tag_id,
              label: item?.name,
            }))}
            placeholder={
              "Enter any required skill(s) relating to the job (min 3, max 10)"
            }
            onChange={(e, val) => handleOnSelectChange(e, val)}
            customStyle={{
              option: (styles, { data, isDisabled, isFocused, isSelected }) => {
                return {
                  ...styles,
                  color: isSelected ? "#fff" : "black",
                  ":active": {
                    ...styles[":active"],
                  },
                };
              },
              control: (baseStyles) => ({
                ...baseStyles,
                boxShadow: "none",
                borderRadius: "10px",
                minHeight: `40px`,

                border:
                  // getSkillValue()?.length < 3 &&
                  errors?.find((error) => error?.key?.startsWith("skills"))
                    ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
                    : "1px solid #E0E0E0",
                "&:hover":
                  // getSkillValue()?.length < 3 &&
                  errors?.find((error) => error?.key?.startsWith("skills"))
                    ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
                    : "1px solid #E0E0E0",
                width: `100%`,
              }),
            }}
            onInputChange={handleOnSelectInputChange}
            multiOnFocus={() => {
              if (skills?.length < 49) {
                getAllDataNew("skills");
              }
            }}
            value={getSkillValue()}
            limitTags={10}
            initialOptions={initialSkills?.map((item) => ({
              value: item?.tag_id,
              label: item?.name,
            }))}
          />
        </Box>
        {/* Tools */}
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <InputLabel
            htmlFor="tools"
            sx={{
              color: "black",
              paddingBottom: "5px",
              fontSize: "14px",
              fontWeight: theme.typography.Bold,
            }}
          >
            {i18n["postAJob.toolsLableReq"]}
          </InputLabel>
          <CustomSelect
            inputId={"tools"}
            name={"tools"}
            variant={"createMultiSelect"}
            closeMenuOnSelect={false}
            isMulti={true}
            multiSelectOptions={tools?.map((item) => ({
              value: item?.tool_id,
              label: item?.name,
            }))}
            placeholder={
              "Enter any required software tools relating to the job (min 3, max 10)"
            }
            onChange={(e, val) => handleOnSelectChange(e, val)}
            customStyle={{
              option: (styles, { data, isDisabled, isFocused, isSelected }) => {
                return {
                  ...styles,
                  color: isSelected ? "#fff" : "black",
                  ":active": {
                    ...styles[":active"],
                  },
                };
              },
              control: (baseStyles) => ({
                ...baseStyles,
                boxShadow: "none",
                borderRadius: "10px",
                minHeight: `40px`,

                border:
                  // getToolValue()?.length < 3 &&
                  errors?.find((error) => error?.key?.startsWith("tools"))
                    ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
                    : "1px solid #E0E0E0",
                "&:hover":
                  // getToolValue()?.length < 3 &&
                  errors?.find((error) => error?.key == "tools")
                    ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
                    : "1px solid #E0E0E0",
                width: `100%`,
              }),
            }}
            onInputChange={handleOnSelectInputChange}
            multiOnFocus={() => {
              if (tools?.length < 49) {
                getAllDataNew("tools");
              }
            }}
            value={getToolValue()}
            limitTags={10}
          />
        </Box>

        {/* Nationality,  language */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ display: "flex", flexDirection: "column", width: "47%" }}>
            <InputLabel
              htmlFor="nationality_ids"
              sx={{
                color: "black",
                paddingBottom: "5px",
                fontSize: "14px",
                fontWeight: theme.typography.Bold,
              }}
            >
              {i18n["postAJob.nationalityReq"]}
            </InputLabel>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                gap: "50px",
              }}
            >
              <Box
                sx={{
                  width: "-webkit-fill-available",
                  maxWidth: "450px",
                }}
              >
                <AutoComplete
                  multiple={true}
                  borderRadius="10px"
                  id="nationality_ids"
                  value={getNationalityValue()}
                  onChange={handleMultipleAutoComplete}
                  sx={{
                    // display: "inline-table",
                    display: "flex",
                    alignItems: "center",
                    fontSize: "14px",
                    width: "100%",
                    border:
                      // jobDetail.nationality_ids.length === 0 &&
                      errors?.find((error) => error?.key === "nationality_ids")
                        ? // &&
                          // !jobDetail?.nationality_flag
                          "1px solid red"
                        : `1px solid ${theme.palette.grayBorder}`,
                  }}
                  placeholder={"Select any nationality(s) required for the job"}
                  data={nationality}
                  onFocus={() => {
                    if (nationality?.length === 0) {
                      getAllDataNew("nationality");
                    }
                  }}
                  disableCloseOnSelect={true}
                  disabled={jobDetail?.nationality_flag}
                ></AutoComplete>
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "47%",
              maxWidth: "675px",
            }}
          >
            <InputLabel
              htmlFor="language_ids"
              sx={{
                color: "black",
                paddingBottom: "5px",
                fontSize: "14px",
                fontWeight: theme.typography.Bold,
              }}
            >
              {i18n["postAJob.languagesLabelReq"]}
            </InputLabel>
            <AutoComplete
              multiple={true}
              borderRadius="10px"
              id="language_ids"
              value={getLangValue()}
              onChange={handleMultipleAutoComplete}
              sx={{
                // display: "inline-table",
                display: "flex",
                fontSize: "14px",
                alignItems: "center",
                border: errors?.find((error) => error?.key === "language_ids")
                  ? "1px solid red"
                  : `1px solid ${theme.palette.grayBorder}`,
              }}
              placeholder={"Select any language(s) required for the job"}
              data={language}
              disableCloseOnSelect={true}
              onFocus={() => {
                if (language?.length === 0) {
                  getAllDataNew("language");
                }
              }}
            ></AutoComplete>
          </Box>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", mdl: "row" },
            width: "100%",
            gap: { xs: "22px", lgmm: "42px" },
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={{
              display: "flex",
              width: "47%",
              flexDirection: "column",
            }}
          >
            <InputLabel
              htmlFor="required_qualification_id"
              sx={{
                color: "black",
                paddingBottom: "5px",
                fontSize: "14px",
                fontWeight: theme.typography.Bold,
              }}
            >
              {i18n["postAJob.requiredQualificationLableReq"]}
            </InputLabel>
            <SelectMenu
              borderRadius="10px"
              name="required_qualification_id"
              value={jobDetail?.required_qualification_id}
              onHandleChange={handleRequiredQualificationLevel}
              options={requiredQua}
              border={
                // !jobDetail.required_qualification_id &&
                errors?.find(
                  (error) => error?.key == "required_qualification_id"
                )
                  ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
                  : "1px solid #E0E0E0"
              }
              onFocus={() => {
                if (requiredQua?.length === 0) {
                  getAllDataNew("requiredQua");
                }
              }}
              placeholder={"Select the minimum required qualification level"}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              width: "47%",
              flexDirection: "column",
            }}
          >
            <InputLabel
              htmlFor="preferred_qualification_ids"
              sx={{
                color: "black",
                paddingBottom: "5px",
                fontSize: "14px",
                fontWeight: theme.typography.Bold,
              }}
            >
              {i18n["postAJob.preferredQualificationLabelOpt"]}
            </InputLabel>
            <CustomSelect
              variant="default"
              onInputChange={handleOnSelectInputChange}
              onChange={handleOnSelectChange}
              placeholder={"Enter any preferred qualification(s)"}
              inputId="preferred_qualification_ids"
              name="preferred_qualification_ids"
              value={getQuaValue()}
              onMenuOpen={() => {
                if (qualifications?.length < 49) {
                  getAllDataNew("qualifications");
                }
                setIsMenuOpen(true);
              }}
              onMenuClose={onMenuClose}
              option={qualifications?.map((item) => ({
                value: item?.qualification_id,
                label: item?.name,
              }))}
              isLoading={isLoading}
              customStyle={{
                option: (
                  styles,
                  { data, isDisabled, isFocused, isSelected }
                ) => {
                  return {
                    ...styles,
                    color: isSelected ? "#fff" : "black",
                    ":active": {
                      ...styles[":active"],
                    },
                  };
                },
                menu: (provided) => ({
                  ...provided,
                  zIndex: 9999,
                }),
                menuList: (provided) => ({
                  ...provided,
                  "&::-webkit-scrollbar": {
                    width: "6px",
                    height: "6px",
                    borderRadius: "2.5px",
                    backgroundColor: "#efefef",
                  },
                  "&::-webkit-scrollbar-thumb": {
                    borderRadius: "2.5px",
                  },
                }),
                control: (baseStyles) => ({
                  ...baseStyles,
                  boxShadow: "none",
                  borderRadius: "10px",
                  minHeight: `40px`,
                }),
              }}
            />
          </Box>
        </Box>

        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <InputLabel
            htmlFor="association_ids"
            sx={{
              color: "black",
              paddingBottom: "5px",
              fontSize: "14px",
              fontWeight: theme.typography.Bold,
            }}
          >
            {i18n["postAJob.preferredAssociationsLabel"]}
          </InputLabel>
          <CustomSelect
            variant="default"
            onInputChange={handleOnSelectInputChange}
            onChange={handleOnSelectChange}
            placeholder={"Enter any preferred association(s)"}
            inputId="association_ids"
            name="association_ids"
            value={getAssociationValue()}
            onMenuOpen={() => {
              if (association?.length < 49) {
                getAllDataNew("associations");
              }
              setIsMenuOpen(true);
            }}
            onMenuClose={onMenuClose}
            option={association?.map((item) => ({
              value: item?.association_id,
              label: item?.name,
            }))}
            isLoading={isLoading}
            customStyle={{
              option: (styles, { data, isDisabled, isFocused, isSelected }) => {
                return {
                  ...styles,
                  color: isSelected ? "#fff" : "black",
                  ":active": {
                    ...styles[":active"],
                  },
                };
              },
              menu: (provided) => ({
                ...provided,
                zIndex: 9999,
              }),
              menuList: (provided) => ({
                ...provided,
                "&::-webkit-scrollbar": {
                  width: "6px",
                  height: "6px",
                  borderRadius: "2.5px",
                  backgroundColor: "#efefef",
                },
                "&::-webkit-scrollbar-thumb": {
                  borderRadius: "2.5px",
                },
              }),
              control: (baseStyles) => ({
                ...baseStyles,
                boxShadow: "none",
                borderRadius: "10px",
                minHeight: `40px`,

                border:
                  // jobDetail.association_ids.length <= 0 &&
                  errors?.find((error) => error?.key === "association_ids")
                    ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
                    : "1px solid #E0E0E0",
                "&:hover":
                  // jobDetail.association_ids.length <= 0 &&
                  errors?.find((error) => error?.key === "association_ids")
                    ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
                    : "1px solid #E0E0E0",
              }),
            }}
          />
        </Box>

        <Box
          sx={{
            display: "flex",
            gap: "10px",
            flexWrap: "wrap",
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={{
              width: "47%",
              maxWidth: "450px",
            }}
          >
            <InputLabel
              htmlFor="beginDate"
              sx={{
                color: "black",
                paddingBottom: "5px",
                fontSize: "14px",
                fontWeight: theme.typography.Bold,
              }}
            >
              {i18n["postAJob.preferredStartDateReq"]}
            </InputLabel>
            <LocalizationProvider dateAdapter={AdapterDayjs} locale="en-gb">
              <DatePicker
                name="job_start_date"
                value={jobDetail?.job_start_date}
                onChange={handleDateChange}
                // inputFormat="DD-MM-YY"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    value={jobDetail?.job_start_date}
                    // inputProps={{
                    //   placeholder: "DD-MM-YY",
                    // }}
                    sx={{
                      width: "100%",
                      "& .MuiInputBase-root": {
                        height: "40px",
                        width: "100%",
                        borderRadius: "10px",
                        border: errors?.find(
                          (error) => error?.key == "job_start_date"
                        )
                          ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
                          : "1px solid #E0E0E0",
                        ":hover": {
                          border: errors?.find(
                            (error) => error?.key == "job_start_date"
                          )
                            ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
                            : "1px solid #E0E0E0",
                        },
                      },
                      "& .MuiIconButton-root": {
                        color: theme.palette.yellowColor, // Change this to the desired color
                      },
                      "& fieldset": {
                        border: "none",
                      },
                    }}
                  />
                )}
              />
            </LocalizationProvider>
          </Box>
          <Box
            sx={{
              width: "47%",
            }}
          >
            <InputLabel
              htmlFor="currency_id"
              sx={{
                color: "black",
                paddingBottom: "5px",
                fontSize: "14px",
                fontWeight: theme.typography.Bold,
              }}
            >
              {i18n["postAJob.currencyIdLabelReq"]}
            </InputLabel>
            <SelectMenu
              clear={true}
              borderRadius="10px"
              name="currency_id"
              value={jobDetail?.currency_id}
              onHandleChange={handleChange}
              options={currency}
              border={
                jobDetail?.currency_id?.length === 0 &&
                errors?.find((error) => error?.key === "currency_id")
                  ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
                  : "1px solid #E0E0E0"
              }
              onFocus={() => {
                if (currency?.length === 0) {
                  getAllDataNew("currency");
                }
              }}
              placeholder={"Select the preferred currency of payment"}
            />
          </Box>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            paddingLeft: "10px",
            paddingRight: "10px",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center", gap: "30px" }}>
            <InputLabel
              htmlFor="salary_id"
              sx={{
                color: "black",
                fontSize: "14px",
                fontWeight: theme.typography.Bold,
              }}
            >
              {jobDetail?.job_role_type === "freelance"
                ? i18n["myCV.requiredSalaryRangeLabel2"]
                : i18n["myCV.requiredSalaryRangeLabel"]}
            </InputLabel>
            <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
              <Typography
                sx={{ fontSize: "14px", fontWeight: theme.typography.Bold }}
              >
                single value
              </Typography>
              <BlueSwitch
                id="salary_value"
                checked={salaryRangeValue}
                onChange={(event) => handleSalaryValue(event)}
              />
              <Typography
                sx={{ fontSize: "14px", fontWeight: theme.typography.Bold }}
              >
                range value
              </Typography>
            </Box>
          </Box>
          <SalarySlider
            basicData={jobDetail}
            rangeValue={rangeValue}
            handleRangeSliderChange={handleRangeSliderChange}
            handleRangeSliderChange2={handleRangeSliderChange2}
            rangeValueHandler={rangeValueHandler}
            rangeValueHandler2={rangeValueHandler2}
            salaryRange={salaryRange}
            errors={errors}
            salaryRangeValue={salaryRangeValue}
            singleValueSlider={singleValueSlider}
            singleValueSlider2={singleValueSlider2}
            singleSalaryValue={singleSalaryValue}
            color={"salarySlider"}
            name={"salary"}
            sx={{
              marginTop: 4,
              width: "90%",
              // ml: 1,
              "& .MuiSlider-rail": {
                backgroundColor: theme.palette.SliderBackground,
                height: "10px",
              },
              "& .MuiSlider-track": {
                height: "10px",
              },
              "& .MuiSlider-thumb": {
                borderRadius: "15%",
                background: "white",
              },
              "& .MuiSlider-valueLabel": {
                fontSize: 12,
                fontWeight: "normal",
                top: -6,
                backgroundColor: theme.palette.grayBackground,
                borderRadius: 1,
                color: theme.palette.text.primary,
                "&:before": {
                  display: "none",
                },
                "& *": {
                  background: "transparent",
                  color: theme.palette.mode === "dark" ? "#fff" : "#000",
                },
              },
            }}
            jobRoleType={jobDetail?.job_role_type}
          />
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            paddingLeft: "10px",
            paddingRight: "10px",
          }}
        >
          <InputLabel
            htmlFor="experience_id"
            sx={{
              color: "black",
              paddingBottom: "5px",
              fontSize: "14px",
              fontWeight: theme.typography.Bold,
            }}
          >
            How many years of work experience does the role require?
          </InputLabel>
          <Slider
            disableSwap
            valueLabelDisplay="on"
            sx={{
              marginTop: 4,
              width: "90%",
              ml: "1px",
              "& .MuiSlider-rail": {
                backgroundColor: theme.palette.SliderBackground,
                height: "10px",
              },
              "& .MuiSlider-track": {
                height: "10px",
              },
              "& .MuiSlider-thumb": {
                borderRadius: "15%",
                background: "white",
              },
              "& .MuiSlider-valueLabel": {
                fontSize: 12,
                fontWeight: "normal",
                top: -6,
                backgroundColor: theme.palette.grayBackground,
                borderRadius: 1,
                color: theme.palette.text.primary,
                "&:before": {
                  display: "none",
                },
                "& *": {
                  background: "transparent",
                  color: theme.palette.mode === "dark" ? "#fff" : "#000",
                },
              },
            }}
            name="experience"
            getAriaLabel={() => "Temperature range"}
            value={expRange}
            onChange={expHandleChange}
            color="experienceSlider"
            valueLabelFormat={rangeValueExperience}
            getAriaValueText={rangeValueExperience}
            min={0}
            max={20}
            step={1}
            marks={BASIC_MARKS}
          />
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            mb: 4,
            width: "100%",
          }}
        >
          <Box
            sx={{
              width: "50%",
            }}
          >
            <Box
              sx={{
                display: "flex",
                gap: "15px",
                alignItems: "center",
              }}
            >
              <Typography
                sx={{
                  fontSize: "14px",
                  fontWeight: theme.typography.Bold,
                  marginBottom: "4px",
                }}
              >
                Preferred Crayon Skinz (optional)
              </Typography>
              <Typography
                sx={{
                  fontSize: "14px",
                  fontWeight: theme.typography.Regular,
                }}
              >
                No preference
              </Typography>
              <BlueSwitch
                id="skinz_flag"
                checked={jobDetail?.skinz_flag}
                onChange={(event) => {
                  setJobDetail({
                    ...jobDetail,
                    skinz: null,
                    skinz_flag: event?.target?.checked,
                  });
                }}
              />
            </Box>

            <Box
              sx={{
                display: "flex",
                gap: "40px",
                filter: `grayscale(${jobDetail?.skinz_flag ? 1 : 0})`,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "flex-end",
                  cursor: "pointer",
                }}
                onClick={(event) => {
                  !jobDetail?.skinz_flag && handleColor(event, 0);
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Box
                    component={"img"}
                    src={white}
                    sx={{
                      height: {
                        xs: "60px",
                        lg: "60px",
                        mlg: "100px",
                        xl: "120px",
                      },
                      width: {
                        xs: "60px",
                        lg: "60px",
                        mlg: "100px",
                        xl: "120px",
                      },
                    }}
                  />
                  <Typography
                    sx={{
                      color: "black",
                      fontSize: "14px",
                      fontWeight: theme.typography.Regular,
                    }}
                  >
                    White
                  </Typography>
                </Box>
                <Box sx={{ width: "20px" }}>
                  {jobDetail?.skinz === 0 && (
                    <CheckCircle
                      sx={{
                        fontSize: 30,
                      }}
                      color="checkColor"
                    />
                  )}
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "flex-end",
                  cursor: "pointer",
                }}
                onClick={(event) => {
                  !jobDetail?.skinz_flag && handleColor(event, 1);
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Box
                    component={"img"}
                    src={coloured}
                    sx={{
                      height: {
                        xs: "60px",
                        lg: "60px",
                        mlg: "100px",
                        xl: "120px",
                      },
                      width: {
                        xs: "60px",
                        lg: "60px",
                        mlg: "100px",
                        xl: "120px",
                      },
                    }}
                  />
                  <Typography
                    sx={{
                      color: "black",
                      fontSize: "14px",
                      fontWeight: theme.typography.Regular,
                    }}
                  >
                    Coloured
                  </Typography>
                </Box>
                <Box sx={{ width: "20px" }}>
                  {jobDetail?.skinz === 1 && (
                    <CheckCircle
                      sx={{
                        fontSize: 30,
                      }}
                      color="checkColor"
                    />
                  )}
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "flex-end",
                  cursor: "pointer",
                }}
                onClick={(event) => {
                  !jobDetail?.skinz_flag && handleColor(event, 2);
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Box
                    component={"img"}
                    src={indian}
                    sx={{
                      height: {
                        xs: "60px",
                        lg: "60px",
                        mlg: "100px",
                        xl: "120px",
                      },
                      width: {
                        xs: "60px",
                        lg: "60px",
                        mlg: "100px",
                        xl: "120px",
                      },
                    }}
                  />
                  <Typography
                    sx={{
                      color: "black",
                      fontSize: "14px",
                      fontWeight: theme.typography.Regular,
                    }}
                  >
                    Indian
                  </Typography>
                </Box>
                <Box sx={{ width: "20px" }}>
                  {jobDetail?.skinz === 2 && (
                    <CheckCircle
                      sx={{
                        fontSize: 30,
                      }}
                      color="checkColor"
                    />
                  )}
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "flex-end",
                  cursor: "pointer",
                }}
                onClick={(event) => {
                  !jobDetail?.skinz_flag && handleColor(event, 3);
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Box
                    component={"img"}
                    src={black}
                    sx={{
                      height: {
                        xs: "60px",
                        lg: "60px",
                        mlg: "100px",
                        xl: "120px",
                      },
                      width: {
                        xs: "60px",
                        lg: "60px",
                        mlg: "100px",
                        xl: "120px",
                      },
                    }}
                  />
                  <Typography
                    sx={{
                      color: "black",
                      fontSize: "14px",
                      fontWeight: theme.typography.Regular,
                    }}
                  >
                    Black
                  </Typography>
                </Box>
                <Box sx={{ width: "20px" }}>
                  {jobDetail?.skinz === 3 && (
                    <CheckCircle
                      sx={{
                        fontSize: 30,
                      }}
                      color="checkColor"
                    />
                  )}
                </Box>
              </Box>
            </Box>
          </Box>

          <Box
            sx={{
              width: "50%",
            }}
          >
            <Typography
              sx={{
                fontSize: "14px",
                mt: "20px",
                fontWeight: theme.typography.Bold,
                marginBottom: "4px",
              }}
            >
              {i18n["postAJob.workSetupRequirementsOpt"]}

              {/* Work setup requirements */}
            </Typography>

            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "flex-end",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Box
                    component={"img"}
                    src={own_vehicle}
                    sx={{
                      height: "70px",
                      width: "70px",
                    }}
                  />
                  <Typography
                    sx={{
                      color: "black",
                      fontSize: "14px",
                      fontWeight: 500,
                    }}
                  >
                    Own transport
                  </Typography>
                </Box>
                <Box sx={{ width: "50px", position: "relative", top: "9px" }}>
                  <BlueSwitch
                    id="own_transport"
                    checked={jobDetail?.own_transport}
                    onChange={handleSwitch}
                  />
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "flex-end",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Box
                    component={"img"}
                    src={own_equipment}
                    sx={{
                      height: "70px",
                      width: "70px",
                    }}
                  />
                  <Typography
                    sx={{
                      color: "black",
                      fontSize: "14px",
                      fontWeight: 500,
                    }}
                  >
                    Own equipment
                  </Typography>
                </Box>
                <Box sx={{ width: "50px", position: "relative", top: "9px" }}>
                  <BlueSwitch
                    id="own_equipment"
                    checked={jobDetail?.own_equipment}
                    onChange={handleSwitch}
                  />
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "flex-end",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Box
                    component={"img"}
                    src={own_internet}
                    sx={{
                      height: "70px",
                      width: "70px",
                    }}
                  />
                  <Typography
                    sx={{
                      color: "black",
                      fontSize: "14px",
                      fontWeight: 500,
                    }}
                  >
                    Own internet
                  </Typography>
                </Box>
                <Box sx={{ width: "50px", position: "relative", top: "9px" }}>
                  <BlueSwitch
                    id="internet_access"
                    checked={jobDetail?.internet_access}
                    onChange={handleSwitch}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box>
          <Box
            sx={{
              display: "flex",
              gap: "15px",
              alignItems: "center",
            }}
          >
            <Typography
              sx={{
                fontSize: "14px",
                fontWeight: theme.typography.Bold,
                marginBottom: "4px",
              }}
            >
              Preferred gender (optional)
            </Typography>
            <Typography
              sx={{
                fontSize: "14px",
                fontWeight: theme.typography.Regular,
              }}
            >
              No preference
            </Typography>
            <BlueSwitch
              id="gender_flag"
              checked={jobDetail?.gender_flag}
              onChange={(event) => {
                setJobDetail({
                  ...jobDetail,
                  gender: "",
                  gender_flag: event?.target?.checked,
                });
              }}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              gap: "30px",
            }}
          >
            <Paper
              elevation={3}
              sx={{
                width: "176px",
                height: "40px",
                boxShadow: "none",
                border: `1px solid ${theme.palette.grayBorder}`,
                borderRadius: "10px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Button
                  startIcon={getMaleIcon(
                    jobDetail?.gender,
                    jobDetail?.gender_flag
                  )}
                  variant="contained"
                  color={
                    jobDetail?.gender == "male"
                      ? "blueButton400"
                      : "whiteButton"
                  }
                  disabled={jobDetail?.gender_flag}
                  onClick={(event) => handleGender(event, "male")}
                  sx={{
                    height: "40px",
                    width: "85px",
                    fontWeight: theme.typography.Regular,
                    borderRadius: "10px",
                    fontSize: "14px",
                    color: jobDetail?.gender == "female" && theme.palette.black,
                    background:
                      jobDetail?.gender == "female" && theme.palette.base.main,
                    ":hover": {
                      boxShadow: "none",
                    },
                  }}
                >
                  {i18n["empMyProfile.male"]}
                </Button>
                <Button
                  startIcon={getFemaleIcon(
                    jobDetail?.gender,
                    jobDetail?.gender_flag
                  )}
                  sx={{
                    height: "40px",
                    width: "85px",
                    fontWeight: theme.typography.Regular,
                    borderRadius: "10px",
                    fontSize: "14px",
                    color: jobDetail?.gender == "male" && theme.palette.black,
                    background:
                      jobDetail?.gender == "male" && theme.palette.base.main,
                    ":hover": {
                      boxShadow: "none",
                    },
                  }}
                  variant="contained"
                  color={
                    jobDetail?.gender == "female"
                      ? "purpleButton300"
                      : "grayBorder"
                  }
                  onClick={(event) => handleGender(event, "female")}
                  disabled={jobDetail?.gender_flag}
                >
                  {i18n["empMyProfile.female"]}
                </Button>
              </Box>
            </Paper>
            {errors && errors?.["gender"] && (
              <Typography style={{ color: "red" }}>
                {`*${errors?.["gender"]?.message}`}
              </Typography>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
