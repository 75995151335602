import React from "react";
import { Box, Button, Typography } from "@mui/material";
import greenTriangle from "../../assets/Characters/Green_Triangle_Happy.svg";
import { useNavigate } from "react-router-dom";
import { getDecodedToken, getToken } from "../../utils/Common";
import locale from "../../i18n/locale";
import { setProfileSteps } from "../../redux/employer/empProfileSlice";
import { useDispatch } from "react-redux";

export default function CompanyInfoNotCompleted() {
  const i18n = locale.en;
  const dispatch = useDispatch();
  const naviagte = useNavigate();

  let decodedToken = getDecodedToken(getToken());

  const handleClick = () => {
    dispatch(setProfileSteps(2));
    decodedToken?.data?.role_id === 6
      ? naviagte("/recruiter/my-profile", { state: { step: 1 } })
      : naviagte("/employer/my-profile", { state: { step: 1 } });
  };

  return (
    <Box
      sx={{
        textAlign: "center",
        display: "flex",
        flexDirection: "column",
        gap: 3,
        backgroundColor: "white",
        borderRadius: "25px",
      }}
    >
      <Box
        component={"img"}
        src={greenTriangle}
        sx={{ width: 96, height: 96, margin: "auto", mb: 0 }}
      />
      <Typography
        sx={{
          fontSize: "22px",
          fontWeight: 700,
          textAlign: "center",
        }}
      >
        {i18n["empMyJobs.profilenotcomplete"]}
        <br /> {i18n["empMyJobs.profilenotcomplete2"]}
      </Typography>

      <Typography
        sx={{
          fontSize: "16px",
          fontWeight: 700,
        }}
      >
        {i18n["empMyJobs.profilenotcomplete3"]}
      </Typography>

      <Button
        variant="contained"
        sx={{
          borderRadius: "0px 0px 25px 25px",
          width: "100%",
          height: "60px",
        }}
        onClick={handleClick}
        color="redButton"
      >
        {"companyInfo"}
      </Button>
    </Box>
  );
}
