import React, { useEffect } from "react";
import topcornerLogo from "../../assets/TemplateImages/Crayon_Favicon-png.png";
import craybot from "../../assets/TemplateImages/Crayon Craybot Notification-07-png.png";
import crayyonLogo from "../../assets/TemplateImages/Crayon_Logo-png.png";
import character from "../../assets/TemplateImages/characterImg (1).png";
import headingLogo from "../../assets/TemplateImages/6284e0b2c121edee2a941a7c_Variant3.png";
import profile_character from "../../assets/TemplateImages/crayon_personality_character.png";
import profile_contemplator from "../../assets/TemplateImages/crayon_personality_contemplator.png";
import profile_challenger from "../../assets/TemplateImages/crayon_personality_grey_challeneger.png";
import profile_collaborator from "../../assets/TemplateImages/crayon_personality_collaborator.png";
// import profile_grey_character from "../../assets/TemplateImages/crayon_personality_contemplator-png.png";
// import profile_grey_challenger from "../../assets/TemplateImages/crayon_personality_contemplator-png.png";
import Blue_Salary from "../../assets/TemplateImages/Blue_Salary-png.png";
import Red_Location from "../../assets/TemplateImages/Red_Location-png.png";
import Green_Duration from "../../assets/TemplateImages/Green_Duration-png.png";
import Yellow_Calendar from "../../assets/TemplateImages/Yellow_Calendar-png.png";
import { Box, Button, Typography, useTheme } from "@mui/material";
import html2canvas from "html2canvas";
import { uploadJobImage } from "../../redux/guest/jobsSlice";
import { useDispatch } from "react-redux";

export default function NewTemplate({
  title,
  location,
  salary,
  jobID,
  experience,
  startDate,
  handleApprove,
  formData,
  statusName,
  shadowPersonality,
  primaryPersonality,
  companyLogo,
  setIsShowCard,
  getUrl = () => {},
  setShowShareInfo = () => {},
}) {
  const theme = useTheme();
  const dispatch = useDispatch();
  let imageblob;
  // const setTemplate = async () => {
  //   const template = document.getElementById("template");
  //   console.log(template, "TEMP");
  //   const canvas = await html2canvas(template);
  // canvas.toBlob(function (blob) {
  //   const formData = new FormData();
  //   formData.append("profile-pic", blob, "filename.png");
  //   const { payload } = dispatch(uploadProfilePic(formData));
  //   setsrc(payload?.data);
  // });
  // };

  // const setTemplate = async () => {
  //   const template = document.getElementById("template");
  //   const canvas = await html2canvas(template);
  //   imageblob = canvas.toDataURL("image/jpeg", 1.0);
  //   console.log({ template, imageblob, canvas });
  //   setsrc(imageblob);
  // };

  // const setTemplate = async () => {
  //   setShareUrl("");
  //   const template = document.getElementById("template");
  //   const canvas = await html2canvas(template);
  //   let formData = new FormData();
  //   return new Promise((resolve) => {
  //     canvas.toBlob(
  //       (blob) => {
  //         const fileName = "image.jpg";
  //         const file = new File([blob], fileName, { type: "image/jpeg" });
  //         setsrc(URL.createObjectURL(file));
  //         formData.append("job_id", jobID);
  //         formData.append("job-image", file);
  //         dispatch(uploadJobImage(formData));
  //         resolve(file);
  //       },
  //       "image/jpeg",
  //       1.0
  //     );
  //   }).then((file) => {
  //     // Assuming getUrl function is defined somewhere
  //     getUrl(jobID);
  //   });
  // };

  // const setTemplate = async () => {
  //   const template = document.getElementById("template");
  //   const canvas = await html2canvas(template);
  //   const formData = new FormData();
  //   try {
  //     const blob = await new Promise((resolve) => {
  //       canvas.toBlob(resolve, "image/jpeg", 1.0);
  //     });
  //     const fileName = "image.jpg";
  //     const file = new File([blob], fileName, { type: "image/jpeg" });
  //     // setsrc(URL.createObjectURL(file));
  //     formData.append("job_id", jobID);
  //     formData.append("job-image", file);
  //     await dispatch(uploadJobImage(formData));
  //   } catch (error) {
  //     console.error("Error generating or uploading image:", error);
  //   }
  // };

  const setTemplate = async () => {
    if(setIsShowCard){
      setIsShowCard(true)
    }
    const template = document.getElementById("template");
    const canvas = await html2canvas(template);
    try {
      const formDataTemp = new FormData();
      const blob = await new Promise((resolve) => {
        canvas.toBlob(resolve, "image/jpeg", 1.0);
      });
      const fileName = "image.jpg";
      const file = new File([blob], fileName, { type: "image/jpeg" });
      // setsrc(URL.createObjectURL(file));
      formDataTemp.append("job_id", jobID);
      formDataTemp.append("job-image", file);
      formData.append("job_id", jobID);
      formData.append("jobimage", file);
      formData.append("status", statusName);
      await dispatch(uploadJobImage(formDataTemp));
      await getUrl(jobID);
      setShowShareInfo(true);
      await handleApprove(formData);
      if(setIsShowCard){
        setIsShowCard(false)
      }
    } catch (error) {
      if(setIsShowCard){
        setIsShowCard(false)
      }
    }
  };

  const getPersonalityImage = (personality) => {
    switch (personality) {
      case "collaborator":
        return profile_collaborator;
      case "challenger":
        return profile_challenger;
      case "character":
        return profile_character;
      case "contemplator":
        return profile_contemplator;
      default:
        return null;
    }
  };

  useEffect(() => {
    if (title !== undefined) {
      setTemplate();
    }
  }, [title]);

  return (
    <Box
      sx={{
        minHeight: "620px",
        width: "1200px",
        justifyContent: "center",
        alignItems: "center",
        padding: "50px",
        // display: imageblob === undefined ? "flex" : "none",
        position: "absolute",
        left: 0,
        opacity: 0,
        zIndex: "-99999",
      }}
    >
      <Box
        id="template"
        sx={{
          background: "white",
          position: "relative",
          overflow: "hidden",
          minHeight: "627px",
          width: "1200px",
          border: "1px solid lightgray",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Box
          component={"img"}
          src={topcornerLogo}
          sx={{
            height: "283px",
            width: "300px",
            position: "absolute",
            top: "-25px",
            left: "-25px",
            // zIndex: "-10",
          }}
        />
        <Button
          variant="contained"
          color="error"
          sx={{
            borderRadius: "0px 0px 20px 20px",
            position: "absolute",
            top: "0px",
            right: "20px",
            fontSize: "1.2rem",
            height: "65px",
            width: "230px",
          }}
        >
          {" "}
          CLICK TO APPLY{" "}
        </Button>
        <Box
          sx={{
            position: "absolute",
            bottom: "20px",
            left: "10px",
            display: "flex",
            alignItems: "end",
          }}
        >
          <Box
            component={"img"}
            src={craybot}
            sx={{ width: "200px", height: "200px" }}
          ></Box>
          <Typography
            sx={{
              width: "404px",
              fontSize: "16px",
              fontWeight: theme.typography.Bold,
              textAlign: "left",
              marginRight: "10px",
            }}
          >
            Share a job with your network and earn moola via Crayon Bounty if
            someone you refer gets hired!
          </Typography>
          <Box
            component={"img"}
            src={crayyonLogo}
            sx={{ width: "220px", height: "57px" }}
          ></Box>
        </Box>
        <Box
          component={"img"}
          src={character}
          sx={{
            height: "550px",
            width: "355px",
            position: "absolute",
            right: "-25px",
            bottom: "0px",
            // zIndex: "-10",
          }}
        ></Box>
        <Box
          sx={{
            width: "700px",
            margin: "0px auto",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              paddingLeft: "70px",
              display: "flex",
              position: "absolute",
              top: "60px",
              width: "100%",
              flexDirection: "column",
              alignItems: "center",
              height: "100%",
              left: "-20px",
              gap: "20px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                // position: "absolute",
                // top: "60px",
                width: "705px",
              }}
            >
              <Box
                component={"img"}
                src={companyLogo !== "No URL" ? companyLogo : topcornerLogo}
                sx={{ height: "150px", width: "150px", marginRight: "20px" }}
              ></Box>
              <Typography
                sx={{
                  fontSize: "48px",
                  fontWeight: theme.typography.Bold,
                  textAlign: "left",
                }}
              >
                {title}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                marginTop: "0px",
                width: "705px",
                // gap: "40px",
              }}
            >
              <Box sx={{ marginTop: "0px" }}>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Box
                    component={"img"}
                    src={Blue_Salary}
                    sx={{ height: "55px", width: "55px", marginRight: "10px" }}
                  />
                  <Typography
                    sx={{
                      fontSize: "24px",
                      fontWeight: theme.typography.Bold,
                      width: "346px",
                      minHeight: "32px",
                    }}
                  >
                    {salary}
                  </Typography>
                </Box>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Box
                    component={"img"}
                    src={Red_Location}
                    sx={{ height: "55px", width: "55px", marginRight: "10px" }}
                  />
                  <Typography
                    sx={{
                      fontSize: "24px",
                      fontWeight: theme.typography.Bold,
                      width: "277px",
                      minHeight: "32px",
                    }}
                  >
                    {location}
                  </Typography>
                </Box>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Box
                    component={"img"}
                    src={Green_Duration}
                    sx={{ height: "55px", width: "55px", marginRight: "10px" }}
                  />
                  <Typography
                    sx={{
                      font: "normal normal bold 24px/32px Nunito",
                      width: "132px",
                      minHeight: "32px",
                    }}
                  >
                    {experience}
                  </Typography>
                </Box>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Box
                    component={"img"}
                    src={Yellow_Calendar}
                    sx={{
                      height: "40px",
                      width: "40px",
                      marginRight: "10px",
                      padding: "7px",
                    }}
                  />
                  <Typography
                    sx={{
                      font: "normal normal bold 24px/32px Nunito",
                      width: "167px",
                      minHeight: "32px",
                    }}
                  >
                    {startDate}
                  </Typography>
                </Box>
              </Box>
              <Box
                sx={{ display: "grid", marginLeft: "10px", marginTop: "0px" }}
              >
                <Box
                  component={"img"}
                  src={getPersonalityImage(primaryPersonality)}
                  sx={{
                    height: "118px",
                    width: "118px",
                    marginRight: "20px",
                    marginBottom: "10px",
                  }}
                ></Box>
                <Box
                  component={"img"}
                  src={getPersonalityImage(shadowPersonality)}
                  sx={{ height: "120px", width: "120px", marginRight: "20px" }}
                ></Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
