import {
  Box,
  Button,
  IconButton,
  InputBase,
  Modal,
  Paper,
  Switch,
  TextField,
  Typography,
  alpha,
  useTheme,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import locale from "../../../../i18n/locale";
import InputBox from "../../../common/myProfileSection/InputBox";
import maleBlack from "../../../../assets/male_blue.svg";
import maleWhite from "../../../../assets/male_white.svg";
import femaleBlack from "../../../../assets/female_pink.svg";
import femaleWhite from "../../../../assets/female_white (2).svg";
import PhoneInput, { formatPhoneNumberIntl } from "react-phone-number-input";
import { isValidPhoneNumber } from "react-phone-number-input";
import { CheckCircle } from "@mui/icons-material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import CustomSelect from "../../../candidate/myCV/CustomSelect";
import {
  ALERT_TYPE,
  EDIT_EMPLOYER,
  ERROR_MSG,
  USER_ROLES,
  emailRegex,
  isValidLinkedInUrl,
} from "../../../../utils/Constants";
import { useDispatch } from "react-redux";
import {
  getUserEmployer,
  updateEmployerData,
} from "../../../../redux/admin/jobsSlice";
import { setAlert, setLoading } from "../../../../redux/configSlice";
import { getCountries } from "../../../../redux/employer/empProfileSlice";
import { getTown } from "../../../../redux/employer/postJobSlice";
import dayjs from "dayjs";
import {
  debounce,
  getContactDetail,
  validateSchema,
} from "../../../../utils/Common";
import { useSelector } from "react-redux";
import * as Yup from "yup";
import { getFemaleIcon, getMaleIcon } from "../../../../utils/CommonComponent";
const regionNames = new Intl.DisplayNames(["en"], { type: "region" });

export default function EditEmployer({
  show,
  handleClose,
  editEmployer,
  getEmployerList,
  roleId,
}) {
  const i18n = locale.en;
  const theme = useTheme();
  const currentDate = new Date();
  const { countries, town } = useSelector((state) => state.postJobs);
  const dispatch = useDispatch();
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 800,
    bgcolor: "background.paper",
    borderRadius: "10px",
    boxShadow: "none !important",
  };

  const [errors, setErrors] = useState([]);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [employerData, setEmployerData] = useState(EDIT_EMPLOYER);
  const [previousCountryCode, setPreviousCountryCode] = useState("");
  const [contactNumber, setContactNumber] = useState(
    employerData?.contact_no || ""
  );

  const infoFormSchema = Yup.object().shape({
    first_name: Yup.string().required("First Name is Required."),
    last_name: Yup.string().required("Surname is required"),
    email: Yup.string()
      .matches(emailRegex, "Invalid email address") // Use custom regex for email validation
      .required("Email is Required."),
    dob: Yup.string().required("dob is Required."),
    country_id: Yup.mixed().required("Country is Required."),
    town_id: Yup.string().required("Town/City is Required."),
    linkedin_profile_link: Yup.string()
      .when("validateLinkedin", {
        is: true,
        then: (schema) =>
          schema
            .test(
              "isValidLinkedIn",
              "Invalid LinkedIn URL",
              (value) => !value || isValidLinkedInUrl(value)
            )
            .required("Hyperlink is Required."),
        otherwise: (schema) => schema.optional(),
      })
      .nullable()
      .notRequired(),
    calendar_link: Yup.string()
      .when("validateCalendarLink", {
        is: true,
        then: (schema) =>
          schema
            .test(
              "isValidCalendar",
              "Invalid Calendar URL",
              (value) => !value || isValidLinkedInUrl(value)
            )
            .required("Hyperlink is Required."),
        otherwise: (schema) => schema.optional(),
      })
      .nullable()
      .notRequired(),
    gender: Yup.string()
      .when("gender_flag", {
        is: false,
        then: (schema) => schema.required("gender is required."),
        otherwise: (schema) => schema.optional(),
      })
      .nullable()
      .notRequired(),
  });

  const getAllData = async () => {
    try {
      dispatch(setLoading(true));
      await Promise.all([
        // dispatch(getTown("")),
        dispatch(getCountries()),
      ]);
      dispatch(setLoading(false));
    } catch (error) {
      dispatch(setLoading(false));
      dispatch(
        setAlert({
          show: true,
          type: ALERT_TYPE.ERROR,
          msg: ERROR_MSG,
        })
      );
    }
  };

  const handlePrefer = (event, name) => {
    const preferToSay = event?.target?.checked ? true : false;
    const newProfileData = {
      ...employerData,
      [name]: preferToSay,
      gender: null,
    };
    setErrors(errors?.filter((item) => item.key !== "gender"));
    setEmployerData(newProfileData);
  };

  const handleGender = (event, gender) => {
    const newEmployerData = {
      ...employerData,
      gender: gender,
    };
    setErrors(errors?.filter((item) => item.key !== "gender"));
    setEmployerData(newEmployerData);
  };

  const getEmployerData = async () => {
    try {
      const { payload } = await dispatch(
        getUserEmployer({
          lastKey: 0,
          roleid: roleId,
          userId: editEmployer,
        })
      );

      if (payload.status === "success") {
        setErrors([]);
        setEmployerData({
          employer_id: payload?.data?.[0]?.user_id,
          contact_no: payload?.data?.[0]?.contact_no,
          country_code: payload?.data?.[0]?.country_code,
          country_name: payload?.data?.[0]?.country_name,
          country_id: payload?.data?.[0]?.country_id,
          town_id: payload?.data?.[0]?.town_id,
          town_name: payload?.data?.[0]?.town_name,
          gender: payload?.data?.[0]?.gender,
          gender_flag: payload?.data?.[0]?.gender_flag,
          dob: payload?.data?.[0]?.dob,
          linkedin_profile_link: payload?.data?.[0]?.linkedin_profile_link,
          calendar_link: payload?.data?.[0]?.calendar_link,
          skinz: payload?.data?.[0]?.skinz,
          notes: payload?.data?.[0]?.notes,
          grit_score: payload?.data?.[0]?.grit_score,
          first_name: payload?.data?.[0]?.first_name,
          last_name: payload?.data?.[0]?.last_name,
          email: payload?.data?.[0]?.email,
        });
        setContactNumber(payload?.data?.[0]?.contact_no);
      } else {
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE?.ERROR,
            msg: i18n["alertMessage.Loginasemployertoaccess"],
          })
        );
      }
    } catch (error) {
      dispatch(
        setAlert({
          show: true,
          type: ALERT_TYPE?.ERROR,
          msg: i18n["alertMessage.error"],
        })
      );
    }
  };

  const editEmployerData = async () => {
    let {
      first_name,
      linkedin_profile_link,
      last_name,
      email,
      dob,
      country_id,
      town_id,
      calendar_link,
      gender,
      gender_flag,
    } = employerData;
    let testErrorState = {
      first_name,
      last_name,
      email,
      dob,
      country_id,
      town_id,
      linkedin_profile_link,
      validateLinkedin: linkedin_profile_link?.length > 0 ? true : false,
      calendar_link,
      validateCalendarLink: calendar_link?.length > 0 ? true : false,
      gender,
      gender_flag,
    };
    validateSchema(infoFormSchema, testErrorState)
      .then(() => {
        const contactInfo = getContactDetail(
          formatPhoneNumberIntl(
            contactNumber?.startsWith("+")
              ? contactNumber
              : `${employerData?.country_code}${contactNumber}`
          )
        );
        let data = {
          ...employerData,
          contact_no: contactInfo ? contactInfo?.[1] : contactNumber,
          country_code: contactInfo
            ? contactInfo?.[0]
            : employerData?.country_code,
        };
        try {
          if (
            !isValidPhoneNumber(
              contactNumber?.startsWith("+")
                ? contactNumber
                : `${employerData?.country_code}${contactNumber}` || ""
            )
          ) {
            let phoneError = {
              message: "Invalid contact number",
              key: "contact_no",
            };
            //
            let exap = [...errors, phoneError];
            setErrors(exap);
            return;
          }
          dispatch(updateEmployerData(data)).then((payload) => {
            if (payload?.payload?.status === "success") {
              dispatch(
                setAlert({
                  show: true,
                  type: ALERT_TYPE?.SUCCESS,
                  msg: i18n["alertMessage.Employerdataupdatedsuccessfully"],
                })
              );
              handleClose();
              getEmployerList(0);
            } else {
              dispatch(
                setAlert({
                  show: true,
                  type: ALERT_TYPE?.ERROR,
                  msg: i18n["alertMessage.Loginasemployertoaccess"],
                })
              );
            }
          });
        } catch (error) {
          dispatch(
            setAlert({
              show: true,
              type: ALERT_TYPE?.ERROR,
              msg: i18n["alertMessage.error"],
            })
          );
        }
      })
      .catch((error) => {
        setErrors(
          Object.keys(error)?.map((item) => ({
            message: error[item],
            key: item,
          }))
        );
      });
  };

  const handleInputChange = (event) => {
    const newEmployerData = {
      ...employerData,
      [event.target.id]: event?.target?.value,
    };

    // if (event.target.id === "first_name" || event.target.id === "surname") {
    //   setEmployerData((prev) => ({
    //     ...prev,
    //     user: {
    //       ...prev.user,
    //       [event.target.id]: event.target.value,
    //     },
    //   }));
    //   // setErrors(errors?.filter((item) => item.key !== event.target.id));
    // }
    // if (event.target.id === "email") {
    //   validateEmail(event.target.value) &&
    // setErrors(errors?.filter((item) => item.key !== event.target.id));
    // }
    setErrors(errors?.filter((item) => item.key !== event.target.id));
    setEmployerData(newEmployerData);
  };
  const handleContactNumber = async (name, value) => {
    setContactNumber(value);
  };

  const handleDateChange = (newValue) => {
    let formatedDate =
      dayjs(newValue)?.format("YYYY-MM-DD") === "Invalid Date"
        ? ""
        : dayjs(newValue)?.format("YYYY-MM-DD");
    const newEmployerData = {
      ...employerData,
      dob: formatedDate,
    };
    const filteredErrors = errors?.filter((item) => item.key != "dob");
    setErrors(filteredErrors);
    setEmployerData(newEmployerData);
  };

  const debouncedHandleOnSelectInputChange = useCallback(
    debounce(
      async (newValue, type) => {
        let params = {
          title: newValue || "",
          limit: 200,
        };
        if (newValue) {
          try {
            if (type !== "country_id") {
              dispatch(
                getTown({
                  ...params,
                  id: employerData?.country_id,
                })
              );
            }
          } catch (error) {
            dispatch(
              setAlert({
                show: true,
                type: ALERT_TYPE?.ERROR,
                msg: error,
              })
            );
          }
        }
      },
      750,
      setIsLoading
    ),
    []
  );

  const handleOnSelectInputChange = (newValue, inputId) => {
    debouncedHandleOnSelectInputChange(newValue, inputId);
  };

  const extractPhoneNumber = (
    oldCountryCode,
    newCountryCode,
    fullPhoneNumber
  ) => {
    if (fullPhoneNumber === undefined || fullPhoneNumber === null) {
      return `${newCountryCode}`;
    }
    let oldCountryCodeStr = String(oldCountryCode);
    let newCountryCodeStr = String(newCountryCode);
    let normalizedNumber = fullPhoneNumber?.startsWith("+")
      ? fullPhoneNumber?.substring(1)
      : fullPhoneNumber;
    if (normalizedNumber?.startsWith(oldCountryCodeStr)) {
      normalizedNumber = normalizedNumber?.substring(oldCountryCodeStr?.length);
    }

    return `${newCountryCodeStr}${normalizedNumber}`;
  };

  const handleOnSelectChange = async (ind, newValue, val, inputId) => {
    if (inputId === "country_id") {
      let countryId = newValue?.value;
      let selectedCountry = countries?.find((item) => item.id === countryId);

      if (selectedCountry && selectedCountry?.country_code) {
        let updatedNumber = extractPhoneNumber(
          previousCountryCode,
          selectedCountry?.country_code,
          employerData?.contact_no
        );
        setPreviousCountryCode(selectedCountry?.country_code);
        const newEmployerData = {
          ...employerData,
          country_code: `+${selectedCountry?.country_code}`,
          country_id: countryId,
          town_id: "",
          town_name: "",
          country_name: newValue?.label || employerData?.country_name,
          contact_no: `+${updatedNumber}`,
        };
        setContactNumber(`+${updatedNumber}`);
        setEmployerData(newEmployerData);
        setErrors(errors?.filter((item) => item.key !== "country_id"));
        try {
          dispatch(setLoading(true));
          const { payload } = await dispatch(getTown(countryId));
          dispatch(setLoading(false));
        } catch (error) {
          dispatch(setLoading(false));
          dispatch(
            setAlert({
              show: true,
              type: ALERT_TYPE.ERROR,
              msg: ERROR_MSG,
            })
          );
        }
      }
    }
    if (inputId === "town_id") {
      const newEmployerData = {
        ...employerData,
        town_id: newValue?.value,
        town_name: newValue?.label,
      };
      setEmployerData(newEmployerData);
      setErrors(errors?.filter((item) => item.key !== "town_id"));
    }
  };

  const [pageIsLoad, setPageIsLoad] = useState(false);

  const handleChange = async (value) => {
    if (pageIsLoad) {
      setPageIsLoad(false);
      return;
    }
    let selectedCountry = countries?.find((item) => item.id === value);
    let newEmployerData;
    newEmployerData = {
      ...employerData,
      country_code:
        selectedCountry?.country_code_name || employerData?.country_code,
      country_id: value || employerData?.country_id,
      town_id: "",
      town_name: "",
      country_name: selectedCountry?.name,
    };
    if (value === undefined && selectedCountry?.country_code) {
      setContactNumber(`${selectedCountry?.country_code}`);
    }
    setErrors(errors?.filter((item) => item.key !== "country_id"));
    setEmployerData(newEmployerData);
  };

  const handleCountryIdChange = async (countryId) => {
    let updatedCountryCode = countries?.filter(
      (item) => item?.country_id === countryId
    )?.[0]?.country_code;
    setPreviousCountryCode(updatedCountryCode);
    handleChange(countryId);
  };

  const onMenuClose = async () => {
    try {
      const { payload } = await dispatch(
        getTown(employerData?.country_id || "")
      );
    } catch (error) {
      dispatch(
        setAlert({
          show: true,
          type: ALERT_TYPE?.ERROR,
          msg: ERROR_MSG,
        })
      );
    }

    setIsMenuOpen(true);
  };

  const onMenuOpen = async () => {
    if (town?.length === 0) {
      try {
        // dispatch(setLoading(true));
        const { payload } = await dispatch(
          getTown(employerData?.country_id || "")
        );
        // dispatch(setLoading(false));
      } catch (error) {
        dispatch(setLoading(false));
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE?.ERROR,
            msg: ERROR_MSG,
          })
        );
      }
    }
    setIsMenuOpen(true);
  };

  useEffect(() => {
    if (show) {
      getAllData();
      getEmployerData();
      setPageIsLoad(true);
    }
  }, [show]);

  return (
    <Modal
      open={show}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Box
          sx={{ p: 4, display: "flex", flexDirection: "column", gap: "16px" }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              mb: "20px",
            }}
          >
            <Box sx={{ width: "47%" }}>
              <Typography
                sx={{
                  marginBottom: "4px",
                  fontSize: "14px",
                  fontWeight: theme.typography.Bold,
                }}
              >
                {i18n["empMyProfile.firstNameReq"]}
              </Typography>
              <InputBox
                id="first_name"
                value={employerData?.first_name}
                onChange={handleInputChange}
                placeholder={i18n["empMyProfile.firstNamePlace"]}
                sx={{
                  border: errors?.find((error) => error?.key == "first_name")
                    ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
                    : "1px solid #E0E0E0",
                }}
              />
            </Box>
            <Box sx={{ width: "47%" }}>
              <Typography
                sx={{
                  marginBottom: "4px",
                  fontSize: "14px",
                  fontWeight: theme.typography.Bold,
                }}
              >
                {i18n["empMyProfile.surnameReq"]}
              </Typography>
              <InputBox
                placeholder={i18n["empMyProfile.surnamePlace"]}
                value={employerData?.last_name}
                id="last_name"
                onChange={handleInputChange}
                sx={{
                  border: errors?.find((error) => error?.key == "last_name")
                    ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
                    : "1px solid #E0E0E0",
                }}
              />
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              mb: "20px",
            }}
          >
            <Box sx={{ width: "47%" }}>
              <Typography
                sx={{
                  marginBottom: "4px",
                  fontSize: "14px",
                  fontWeight: theme.typography.Bold,
                }}
              >
                {i18n["empMyProfile.emailReq"]}
              </Typography>
              <InputBox
                placeholder={i18n["empMyProfile.emailPlace"]}
                value={employerData?.email}
                id="email"
                onChange={handleInputChange}
                sx={{
                  border: errors?.find((error) => error?.key == "email")
                    ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
                    : "1px solid #E0E0E0",
                }}
              />
            </Box>
            <Box sx={{ width: "47%" }}>
              <Typography
                sx={{
                  marginBottom: "4px",
                  fontSize: "14px",
                  fontWeight: theme.typography.Bold,
                }}
              >
                {i18n["myProfile.genderLabelOpt"]}
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  gap: "8px",
                }}
              >
                <Paper
                  elevation={3}
                  sx={{
                    width: "176px",
                    height: "40px",
                    boxShadow: "none",
                    border: errors?.find((error) => error?.key === "gender")
                      ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
                      : `1px solid ${theme.palette.grayBorder}`,
                    borderRadius: "10px",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Button
                      startIcon={getMaleIcon(
                        employerData?.gender,
                        employerData?.gender_flag
                      )}
                      variant="contained"
                      color={
                        employerData?.gender == "male"
                          ? "blueButton400"
                          : "whiteButton"
                      }
                      onClick={(event) => handleGender(event, "male")}
                      sx={{
                        height: "40px",
                        width: "85px",
                        fontWeight: theme.typography.Regular,
                        borderRadius: "10px",
                        fontSize: "14px",
                        color:
                          employerData?.gender == "female" &&
                          theme.palette.black,
                        background:
                          employerData?.gender == "female" &&
                          theme.palette.base.main,
                        ":hover": {
                          boxShadow: "none",
                        },
                      }}
                      disabled={employerData?.gender_flag}
                    >
                      {i18n["empMyProfile.male"]}
                    </Button>
                    <Button
                      startIcon={getFemaleIcon(
                        employerData?.gender,
                        employerData?.gender_flag
                      )}
                      sx={{
                        height: "40px",
                        width: "85px",
                        fontWeight: theme.typography.Regular,
                        borderRadius: "10px",
                        fontSize: "14px",
                        color:
                          employerData?.gender == "male" && theme.palette.black,
                        background:
                          employerData?.gender == "male" &&
                          theme.palette.base.main,
                        ":hover": {
                          boxShadow: "none",
                        },
                      }}
                      variant="contained"
                      color={
                        employerData?.gender == "female"
                          ? "purpleButton300"
                          : "grayBorder"
                      }
                      onClick={(event) => handleGender(event, "female")}
                      disabled={employerData?.gender_flag}
                    >
                      {i18n["empMyProfile.female"]}
                    </Button>
                  </Box>
                </Paper>

                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Typography
                    sx={{
                      minWidth: "fit-content",
                    }}
                  >
                    Prefer not to say
                  </Typography>
                  <Switch
                    checked={employerData?.gender_flag}
                    sx={{
                      "& .MuiSwitch-switchBase.Mui-checked": {
                        color: theme.palette.blueButton700.main,
                        "&:hover": {
                          backgroundColor: alpha(
                            theme.palette.blueButton700.main,
                            theme.palette.action.hoverOpacity
                          ),
                        },
                      },
                      "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track":
                        {
                          backgroundColor: theme.palette.blueButton700.main,
                        },
                      "& .MuiSwitch-track": {
                        margin: "auto",
                        height: "60% !important",
                      },
                      "& .MuiSwitch-thumb": {
                        borderRadius: "6px !important",
                      },
                      "& .MuiButtonBase-root-MuiSwitch-switchBase": {
                        borderRadius: "15% !important",
                      },
                      "& .MuiButtonBase-root": {
                        padding: "9px !important",
                      },
                    }}
                    onChange={(event) => {
                      handlePrefer(event, "gender_flag");
                    }}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              mb: "20px",
            }}
          >
            <Box sx={{ width: "47%" }}>
              <Typography
                sx={{
                  marginBottom: "4px",
                  fontSize: "14px",
                  fontWeight: theme.typography.Bold,
                }}
              >
                {i18n["empMyProfile.phoneNumberReq"]}
              </Typography>
              <Paper
                sx={{
                  display: "flex",
                  width: "100%",
                  height: "40px",
                  borderRadius: "10px",
                  boxShadow: "none",
                }}
              >
                <Box
                  sx={{
                    background: "transparent",
                    width: "100%",
                    height: "38px",
                    borderRadius: "10px",
                    display: "flex",
                    justifyContent: "space-between",
                    border: errors?.find((error) => error?.key === "contact_no")
                      ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
                      : `1px solid ${theme.palette.grayBorder}`,
                  }}
                >
                  <PhoneInput
                    international
                    id="contact_no"
                    placeholder="Enter phone number"
                    countryCallingCodeEditable={false}
                    value={
                      contactNumber?.startsWith("+")
                        ? contactNumber
                        : `${employerData?.country_code}${contactNumber}` || ""
                    }
                    onChange={(value) => {
                      handleContactNumber("contact_no", value);
                    }}
                    // defaultCountry={employerData?.country_code?.toUpperCase()}
                    focusInputOnCountrySelection={true}
                    onCountryChange={(value) => {
                      const newCountryId = value
                        ? countries?.find(
                            (val) => val?.name === regionNames?.of(value)
                          )?.id
                        : "";
                      handleCountryIdChange(newCountryId);
                    }}
                    style={{
                      font: "inherit",
                      padding: "10px",
                    }}
                  />
                  {/* {isValidPhoneNumber(contactNumber || "") && ( */}
                  {isValidPhoneNumber(employerData?.contact_no || "") && (
                    <Typography
                      sx={{
                        color: theme.palette.tealColor.main,
                        fontSize: "14px",
                        alignItems: "center",
                        display: "flex",
                        gap: "5px",
                        marginRight: "5px",
                        justifyContent: "end",
                      }}
                    >
                      Correct <CheckCircle fontSize="small" />
                    </Typography>
                  )}
                </Box>
              </Paper>
            </Box>
            <Box
              sx={{
                width: "47%",
              }}
            >
              <Typography
                sx={{
                  marginBottom: "4px",
                  fontSize: "14px",
                  fontWeight: theme.typography.Bold,
                }}
              >
                {i18n["empMyProfile.birthDateReq"]}
              </Typography>
              <Box
                sx={{
                  width: "100%",
                }}
              >
                <LocalizationProvider dateAdapter={AdapterDayjs} locale="en-gb">
                  <DatePicker
                    name="dob"
                    value={employerData?.dob}
                    onChange={handleDateChange}
                    maxDate={currentDate}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        sx={{
                          width: "100%",
                          "& .MuiInputBase-root": {
                            height: "40px",
                            width: "100%",
                            borderRadius: "10px",
                            border: errors?.find(
                              (error) => error?.key === "dob"
                            )
                              ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
                              : "1px solid #E0E0E0",
                            ":hover": {
                              border: errors?.find(
                                (error) => error?.key === "dob"
                              )
                                ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
                                : "1px solid #E0E0E0",
                            },
                          },
                          "& fieldset": {
                            border: "none",
                          },
                          "& .MuiIconButton-root": {
                            color: theme.palette.DobTextColor, // Change this to the desired color
                          },
                        }}
                      />
                    )}
                  />
                </LocalizationProvider>
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              mb: "20px",
            }}
          >
            <Box sx={{ width: "47%" }}>
              <Typography
                sx={{
                  marginBottom: "4px",
                  fontSize: "14px",
                  fontWeight: theme.typography.Bold,
                }}
              >
                {i18n["empMyProfile.countryReq"]}
              </Typography>
              <CustomSelect
                onMenuOpen={() => {}}
                variant="default"
                onInputChange={handleOnSelectInputChange}
                onChange={handleOnSelectChange}
                placeholder={"Select"}
                inputId="country_id"
                name="country_id"
                value={{
                  value: employerData?.country_id,
                  label: employerData?.country_name,
                }}
                isLoading={isLoading}
                filterOption={"filter"}
                customStyle={{
                  menu: (provided) => ({
                    ...provided,
                    zIndex: 9999,
                  }),
                  menuList: (provided) => ({
                    ...provided,
                    "&::-webkit-scrollbar": {
                      width: "6px",
                      height: "6px",
                      borderRadius: "2.5px",
                      backgroundColor: "#efefef",
                    },
                    "&::-webkit-scrollbar-thumb": {
                      borderRadius: "2.5px",
                    },
                  }),
                  option: (
                    styles,
                    { data, isDisabled, isFocused, isSelected }
                  ) => {
                    return {
                      ...styles,
                      color: isSelected ? "#fff" : "black",
                      ":active": {
                        ...styles[":active"],
                      },
                    };
                  },
                  control: (baseStyles) => ({
                    ...baseStyles,
                    boxShadow: "none",
                    borderRadius: "10px",
                    border: errors?.find((error) => error?.key == "country_id")
                      ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
                      : "1px solid #E0E0E0",
                    "&:hover": errors?.find(
                      (error) => error?.key == "country_id"
                    )
                      ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
                      : "1px solid #E0E0E0",
                    width: `100%`,
                  }),
                }}
                option={countries?.map((item) => ({
                  value: item?.country_id,
                  label: item?.name,
                }))}
              />
            </Box>
            <Box sx={{ width: "47%" }}>
              <Typography
                sx={{
                  marginBottom: "4px",
                  fontSize: "14px",
                  fontWeight: theme.typography.Bold,
                }}
              >
                {i18n["empMyProfile.cityTownReq"]}
              </Typography>
              <Box
                sx={{
                  width: "100%",
                }}
              >
                <CustomSelect
                  // isDisabled={!employerData.country_id}
                  onMenuClose={onMenuClose}
                  onMenuOpen={onMenuOpen}
                  variant="default"
                  onInputChange={handleOnSelectInputChange}
                  onChange={handleOnSelectChange}
                  placeholder={"Select"}
                  inputId="town_id"
                  name="town_id"
                  value={{
                    value: employerData?.town_id,
                    label: employerData?.town_name,
                  }}
                  isLoading={isLoading}
                  customStyle={{
                    menu: (provided) => ({
                      ...provided,
                      zIndex: 9999,
                    }),
                    menuList: (provided) => ({
                      ...provided,
                      "&::-webkit-scrollbar": {
                        width: "6px",
                        height: "6px",
                        borderRadius: "2.5px",
                        backgroundColor: "#efefef",
                      },
                      "&::-webkit-scrollbar-thumb": {
                        borderRadius: "2.5px",
                      },
                    }),
                    option: (
                      styles,
                      { data, isDisabled, isFocused, isSelected }
                    ) => {
                      return {
                        ...styles,
                        color: isSelected ? "#fff" : "black",
                        ":active": {
                          ...styles[":active"],
                        },
                      };
                    },
                    control: (baseStyles) => ({
                      ...baseStyles,
                      boxShadow: "none",
                      borderRadius: "10px",
                      border: errors?.find((error) => error?.key == "town_id")
                        ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
                        : "1px solid #E0E0E0",
                      "&:hover": errors?.find(
                        (error) => error?.key == "town_id"
                      )
                        ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
                        : "1px solid #E0E0E0",
                    }),
                  }}
                  option={town?.map((item) => ({
                    value: item?.town_id,
                    label: item?.name,
                  }))}
                />
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              mb: "20px",
            }}
          >
            <Box sx={{ width: "47%" }}>
              <Typography
                sx={{
                  marginBottom: "4px",
                  fontSize: "14px",
                  fontWeight: theme.typography.Bold,
                }}
              >
                {i18n["empMyProfile.linkedinProfileOpt"]}
              </Typography>
              <InputBox
                placeholder={i18n["empMyProfile.linkedprofile"]}
                value={
                  employerData?.linkedin_profile_link
                    ? employerData?.linkedin_profile_link
                    : ""
                }
                sx={{
                  border: errors?.find(
                    (error) => error?.key === "linkedin_profile_link"
                  )
                    ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
                    : "1px solid #E0E0E0",
                }}
                id="linkedin_profile_link"
                onChange={handleInputChange}
              />
            </Box>
            <Box sx={{ width: "47%" }}>
              <Typography
                sx={{
                  marginBottom: "4px",
                  fontSize: "14px",
                  fontWeight: theme.typography.Bold,
                }}
              >
                {i18n["empMyProfile.calendarLinkOpt"]}
              </Typography>
              <InputBox
                placeholder={i18n["empMyProfile.linkedprofile"]}
                value={
                  employerData?.calendar_link ? employerData?.calendar_link : ""
                }
                sx={{
                  border: errors?.find(
                    (error) => error?.key === "calendar_link"
                  )
                    ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
                    : "1px solid #E0E0E0",
                }}
                id="calendar_link"
                onChange={handleInputChange}
              />
            </Box>
          </Box>
        </Box>
        <Box>
          <Button
            sx={{
              boxShadow: 0,
              fontSize: "14px",
              width: "50%",
              borderRadius: "0 0 0 10px",
              height: "43px",
              background: "lightgray",
              color: "black",
              padding: 3,
              ":hover": {
                background: "lightgray",
              },
            }}
            variant="contained"
            onClick={handleClose}
          >
            cancel
          </Button>
          <Button
            sx={{
              boxShadow: 0,
              fontSize: "14px",
              width: "50%",
              borderRadius: "0 0 10px 0",
              height: "43px",
              padding: 3,
            }}
            variant="contained"
            color="redButton100"
            onClick={editEmployerData}
          >
            edit
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}
