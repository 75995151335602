import {
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import React from "react";
import strongMatch from "../../assets/CrayBotIcons/Crayon Craybot Notification-07.svg";
import averageMatch from "../../assets/CrayBotIcons/Crayon Craybot Notification-09.svg";
import weakMatch from "../../assets/CrayBotIcons/Crayon Craybot Notification-05.svg";
import matchMeIcon from "../../assets/Padding Excluded/Black_Match_me.svg";

import { useTheme } from "@emotion/react";
import MUIRadialChart from "./MUIRadialChart";
import close from "../../assets/Padding Included/Black_Close.svg";
import { Link } from "react-router-dom";
import viewIcon from "../../assets/Padding Excluded/White_View.svg";
import applyIcon from "../../assets/Padding Excluded/Black_Follower.svg";
import { useDispatch } from "react-redux";
import locale from "../../i18n/locale";
import { getDecodedToken, getToken } from "../../utils/Common";
import { LightTooltip } from "../../utils/CommonComponent";
import CloseIcon from "./CloseIcon";

export default function MatchDialogBox({
  setMatchButton,
  job,
  closeFunc,
  applyFunction,
  match,
  setMatch,
}) {
  const theme = useTheme();
  const i18n = locale.en;

  const dispatch = useDispatch();

  const decodedToken = getDecodedToken(getToken());

  const getColor = (value) => {
    if (value <= 24) {
      return "weakMatch";
    } else if (value <= 74) {
      return "averageMatch";
    } else {
      return "strongMatch";
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        justifyContent: "space-between",
      }}
    >
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Box sx={{ marginBottom: "16px" }}>
          <Button
            variant="contained"
            color="betaVersion"
            sx={{
              borderRadius: "25px 0px 10px 0px",
              color: "white",
              fontSize: theme.typography.fontSize.sm,
              fontWeight: theme.typography.Bold,
              width: "150px",
              height: "45px",
              padding: "0px",
              ":hover": {
                boxShadow: "none !important",
              },
            }}
            startIcon={
              <Box
                component={"img"}
                sx={{
                  height: "20px",
                  width: "26px",
                }}
                src={matchMeIcon}
              />
            }
          >
            {i18n["matchMeBox.beta"]}
          </Button>
          <Box
            onClick={() => {
              setMatchButton();
            }}
          >
            <CloseIcon top="15px" right="15px" />
          </Box>
        </Box>

        <Box
          sx={{
            padding: "0px 16px 0 19px",
            height: "150px",
            // marginBottom: "20px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              gap: "10px",
              height: "19px",
            }}
          >
            <Typography
              sx={{
                fontSize: theme.typography.fontSize.sm,
                fontWeight: theme.typography.Bold,
              }}
            >
              {i18n["matchMeBox.matchPer"]}
            </Typography>
            <Tooltip disableInteractive>
              <LightTooltip
                title={
                  "This percentage match does not necessarily mean you will or will not be shortlisted for a job, but it helps provide a quick indicator of your suitability. "
                }
                placement="right-end"
              >
                <Typography
                  sx={{
                    padding: "5px",
                    height: "8px",
                    width: "8px",
                    borderRadius: "5px",
                    fontSize: "15px",
                    lineHeight: 0.1,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    fontWeight: 700,
                    border: 1,
                  }}
                >
                  i
                </Typography>
              </LightTooltip>
            </Tooltip>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              marginTop: "13px",
              // gap: 2,
            }}
          >
            <Typography
              sx={{
                fontSize: theme.typography.fontSize.xs,
                color: "black",
                fontWeight: theme?.typography?.Regular,
              }}
            >
              {i18n["matchMeBox.craybotInfo"]}
            </Typography>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                height: "75px",
                marginTop: "10px",
              }}
            >
              <Box
                component={"img"}
                src={
                  match?.matchingPercentage <= 25
                    ? weakMatch
                    : match?.matchingPercentage <= 75
                    ? averageMatch
                    : strongMatch
                }
                sx={{ width: 75, height: 75, position: "absolute", left: 0 }}
              />
              <Typography
                sx={{
                  fontSize: theme.typography.fontSize.xs,
                  textAlign: "left",
                  color: "black",
                  fontWeight: theme?.typography?.Bold,
                  marginLeft: "66px",
                  width: "200px",
                }}
              >
                {i18n["matchMeBox.remember"]}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          gap: "30px",
          flexDirection: "column",
          width: "100%",
          marginTop: "20px",
          marginBottom:
            match?.matchingPercentage <= 25
              ? "21px"
              : match?.matchingPercentage <= 75
              ? "21px"
              : "36px",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          {match ? (
            <MUIRadialChart
              value={match?.matchingPercentage}
              chartName={"match"}
              max={100}
              size={130}
              countFontSize={20}
              labelFontSize={15}
              color={getColor(match?.matchingPercentage)}
              formattedValue={`${match?.matchingPercentage} %`}
            />
          ) : (
            <Box
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                margin: "0px",
                height: "130px",
                width: "130px",
              }}
            >
              <CircularProgress color="inherit" size={20} />
            </Box>
          )}
        </Box>
        <Typography
          sx={{
            fontWeight: theme?.typography?.Bold,
            fontSize: theme.typography.fontSize.sm,
            textAlign: "center",
            lineHeight: "19px",
            height:
              match?.matchingPercentage <= 25
                ? "59px"
                : match?.matchingPercentage <= 75
                ? "59px"
                : "44px",
          }}
          width={`${
            match?.matchingPercentage <= 25
              ? "284px"
              : match?.matchingPercentage <= 75
              ? "288px"
              : "284px"
          }`}
        >
          {match &&
            (match?.matchingPercentage <= 25
              ? i18n["matchMeBox.weakMatch"]
              : match?.matchingPercentage <= 75
              ? i18n["matchMeBox.avgMatch"]
              : i18n["matchMeBox.strongMatch"])}
        </Typography>
      </Box>
      <Grid
        container
        alignItems="center"
        overflow={"hidden"}
        sx={{
          width: "100%",
          borderRadius: "0 0 25px 25px",
          height: "60px",
        }}
      >
        <Link
          to={
            job?.post_incognito
              ? `/jobs/${job?.country_name?.replace(
                  /\s|\//g,
                  ""
                )}/${job?.town_name?.replace(
                  /\s|\//g,
                  ""
                )}/${job?.title?.replace(/\s|\//g, "")}/${job?.job_id}`
              : `/jobs/${job?.country_name?.replace(
                  /\s|\//g,
                  ""
                )}/${job?.town_name?.replace(
                  /\s|\//g,
                  ""
                )}/${job?.CompanyName?.replace(
                  /\s|\//g,
                  ""
                )}/${job?.title?.replace(/\s|\//g, "")}/${job?.job_id}`
          }
          target={"_blank"}
          style={{
            textDecoration: "none",
            color: theme?.palette?.black,
            width: "50%",
            height: "100%",
          }}
        >
          <Button
            variant="contained"
            sx={{
              borderRadius: 0,
              fontSize: "14px",
              fontWeight: "bold",
              whiteSpace: "nowrap",
              height: "100%",
              width: "100%",
              justifyContent: "center",
              ".MuiButton-startIcon": {
                marginRight: "0px !important",
                marginLeft: 0,
                justifyContent: "end",
                width: "26px",
              },
              padding: "0px !important",
              boxShadow: "none !important",
            }}
            color="tymeColor"
            startIcon={
              <Box
                component={"img"}
                sx={{
                  height: 17,
                  width: 28,
                  padding: 0,
                  paddingRight:"5px"
                }}
                src={viewIcon}
              />
            }
          >
            {i18n["matchMeBox.viewMore"]}
          </Button>
        </Link>
        <Button
          variant="contained"
          sx={{
            borderRadius: "0 0 25px 0",
            width: "50%",
            height: "100%",
            fontSize: "14px",
            fontWeight: "bold",
            ":hover": {
              boxShadow: 0,
            },
            ".MuiButton-startIcon": {
              marginRight: "0px !important",
              marginLeft: 0,
            },
            padding: "0px !important",
            boxShadow: "none !important",
            gap: 1,
          }}
          color="nandos"
          onClick={() => {
            applyFunction(job);
          }}
          startIcon={
            <Box
              component={"img"}
              sx={{
                height: "20px",
                width: "24px",
              }}
              src={applyIcon}
            />
          }
        >
          {job?.applidflag === true ? "applied" : i18n["matchMeBox.apply"]}
        </Button>
      </Grid>
    </Box>
  );
}
