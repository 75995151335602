import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import { useSelector } from 'react-redux'
// import Loader from './Loader';
import "../../index.css";
// import loaderGif from "../../assets/Ghost.gif"
import LoaderGif from "../../assets/Loader.gif"
export default function SimpleBackdrop() {
  const isLoading = useSelector((state) => state.config.loading)

  // const [open, setOpen] = React.useState(false);
  // const handleClose = () => {
  //   setOpen(false);
  // };

  return (
  //  ( isLoading&& <Loader/>)
    <div>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1111111111 }}
        open={isLoading}
        // onClick={handleClose}
        
      >
        {/* <CircularProgress color="inherit" /> */}
        {/* <Loader/> */}
        <img style={{height:"100px", width:"100px"}} src={LoaderGif} alt="Loading..." />
        {/* <div className="loader"></div> */}
      </Backdrop>
    </div>
  );
}