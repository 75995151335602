import { Box} from "@mui/material";
import React from "react";
import SmallButton from "../../../common/SmallButton";

export default function EditPlan({handleDorpdownClick}) {
  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "8px" }}>
      <SmallButton
        color="liteButton"
        borderRadius="5px"
        label={"Lite"}
        mr={1}
        fontSize={10}
        fontWeight={700}
        alignItems="end"
        onClick={()=>handleDorpdownClick("lite")}
      />
      <SmallButton
        color="talentButton"
        borderRadius="5px"
        label={"Talent"}
        mr={1}
        fontSize={10}
        fontWeight={700}
        alignItems="end"
        onClick={()=>handleDorpdownClick("talent")}

      />
      {/* <SmallButton
        color="talentButton"
        borderRadius="5px"
        label={"Talent Starter"}
        mr={1}
        fontSize={10}
        fontWeight={700}
        alignItems="end"
      />
      <SmallButton
        color="talentButton"
        borderRadius="5px"
        label={"Talent Scaler"}
        mr={1}
        fontSize={10}
        fontWeight={700}
        alignItems="end"
      />
      <SmallButton
        color="talentButton"
        borderRadius="5px"
        label={"Talent Launcher"}
        mr={1}
        fontSize={10}
        fontWeight={700}
        alignItems="end"
      />
      <SmallButton
        color="talentButton"
        borderRadius="5px"
        label={"Talent Enterprise"}
        mr={1}
        fontSize={10}
        fontWeight={700}
        alignItems="end"
      /> */}
    </Box>
  );
}
