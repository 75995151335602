import { Box, Button } from "@mui/material";
import React from "react";
import MUIRadialChart from "../../../../common/MUIRadialChart";

export default function CountersBox({ companyData }) {
  return (
    <>
      <Button
        variant="contained"
        color="recentButton"
        sx={{ width: "150px", height: "45px", borderRadius: "0 0 25px 0" }}
      >
        stats
      </Button>

      <Box sx={{ display: "flex", padding: "21px 10px 28px 20px", gap: "6px" }}>
        <MUIRadialChart
          value={companyData?.totalTalent || 0}
          size={100}
          color={"matched"}
          chartName={"my talent"}
          countFontSize={14}
          labelFontSize={12}
        />
        <MUIRadialChart
          value={companyData?.jobUserDataCount || 0}
          size={100}
          color={"application"}
          chartName={"applicants"}
          countFontSize={14}
          labelFontSize={12}
        />
        <MUIRadialChart
          value={companyData?.companyUserDataCount || 0}
          size={100}
          color={"follower"}
          chartName={"followers"}
          countFontSize={14}
          labelFontSize={12}
        />
        <MUIRadialChart
          value={companyData?.totaljobs || 0}
          size={100}
          color={"shortlisting"}
          chartName={"job postings"}
          countFontSize={14}
          labelFontSize={12}
        />
        <MUIRadialChart
          value={companyData?.totalactivejobs || 0}
          size={100}
          color={"activeJob"}
          chartName={"active jobs"}
          countFontSize={14}
          labelFontSize={12}
        />
        <MUIRadialChart
          value={companyData?.totalhiredjobs || 0}
          size={100}
          color={"hired"}
          chartName={"hires"}
          countFontSize={14}
          labelFontSize={12}
        />
      </Box>
    </>
  );
}
