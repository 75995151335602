import { Box, Button, InputBase, Typography, useTheme } from "@mui/material";
import React from "react";
import CustomDialog from "../../common/CustomDialog";
import RedTriangle from "../../../assets/Characters/Red_Triangle_Blank.svg";
import locale from "../../../i18n/locale";

export default function RunMatchBox({ openEdit, handleClose }) {
  const i18n = locale.en;
  return (
    <CustomDialog
      show={openEdit}
      hideButton={false}
      dialogWidth="xs"
      showFooter={false}
      onDialogClose={handleClose}
      padding={0}
    >
      <Box
        sx={{
          padding: 4,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "16px",
        }}
      >
        <Box
          component={"img"}
          src={RedTriangle}
          sx={{ width: 150, height: 100 }}
        />
        <Typography
          fontSize={16}
          fontWeight={"bold"}
          textAlign={"center"}
          paragraph
          sx={{ mb: 0 }}
        >
          {i18n["manageJob.itmighttakesometime"]}
        </Typography>
      </Box>
      <Box>
        <Button
          sx={{
            boxShadow: 0,
            fontSize: "14px",
            width: "100%",
            borderRadius: 0,
            height: "43px",
            color: "black",
            padding: 3,
          }}
          color="submitButton"
          variant="contained"
          onClick={handleClose}
        >
          okay
        </Button>
      </Box>
    </CustomDialog>
  );
}
