import React, { useState } from "react";
import { SolutionsCardAccordion } from "../../../../utils/CommonComponent";
import { useParams } from "react-router-dom";
import {
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import SmallButton from "../../../common/SmallButton";
import upClose from "../../../../assets/Padding Included/Black_Up_Close.svg";
import downClose from "../../../../assets/Padding Included/Black_Down_Open.svg";
import profile from "../../../../assets/Crayon_Favicon.svg";
import titleIcon from "../../../../assets/TopBarIcons/SolutionsTabIcon.svg";
import { CheckCircle } from "@mui/icons-material";
import TalentSVGButton from "../../../common/TalentSVGButton";
import cancel from "../../../../assets/CircularIcon/Red/Circular Icons__Red_Close_2.svg";
import {
  getDecodedToken,
  getToken,
  getUserColor,
} from "../../../../utils/Common";
import likeThis from "../../../../assets/Padding Excluded/White_Like.svg";
import loveThis from "../../../../assets/Padding Excluded/White_I_Love_This.svg";
import reject from "../../../../assets/Padding Excluded/White_Reject.svg";
import deleteIcon from "../../../../assets/Padding Excluded/Black_Trash_Delete_1 - Copy.svg";
import editIcon from "../../../../assets/Padding Included/Yellow_Edit.svg";
import {
  convertDatetimeAgo,
  dateConverterMonth,
} from "../../../../utils/DateTime";
import ChangeSolutionsStatus from "./ChangeSolutionsStatus";
import SVGButton from "../../../common/SVGButton";
import RedPlay from "../../../../assets/CircularIcon/Red/Circular Icons__Red_Play.svg";
import RedDown from "../../../../assets/CircularIcon/Red/Circular Icons__Red_Down_Expand.svg";
import GreenUpClose from "../../../../assets/CircularIcon/Green/Circular Icons__Green_Up_Close.svg";
import GreenPlay from "../../../../assets/CircularIcon/Green/Circular Icons__Green_Play copy.svg";
import GreenPlayGrey from "../../../../assets/CircularIcon/Grey/Circular_Icon_Grey_Play.svg";
import ReviewVideoDialog from "../../../common/ReviewVideoDialog";
import { useDispatch } from "react-redux";
import {
  deleteSolution,
  updateSolutionPriority,
} from "../../../../redux/admin/maintenance";
import { setAlert } from "../../../../redux/configSlice";
import { ALERT_TYPE } from "../../../../utils/Constants";
import { truncate } from "lodash";

export default function SolutionAdminCard({
  key,
  index,
  solutionContentData,
  setSolutionList,
  solutionList,
  setIsEdit,
  setIsAddSoutionModal,
  setEditSolutionData,
}) {
  const { id } = useParams();
  const theme = useTheme();
  const dispatch = useDispatch();
  const [flip, setFlip] = useState(id ? true : false);
  const [openReviewVideoDialog, setOpenReviewVideoDialog] = useState(false);
  const [viewVideoLink, setViewVideolink] = useState("");

  const decodedToken = getDecodedToken(getToken());
  const handleCloseDialog = () => {
    setViewVideolink("");
    setOpenReviewVideoDialog(false);
  };

  const changePriority = async (priorityFlag) => {
    try {
      let data = {
        solution_id: solutionContentData?.solution_id,
        priority_id: solutionContentData?.priority,
        flag: priorityFlag,
      };
      const { payload } = await dispatch(updateSolutionPriority(data));
      if (payload?.status === "success") {
        const index = solutionList?.findIndex(
          (solution) =>
            solution?.solution_id === solutionContentData?.solution_id
        );
        if (index === -1) return;

        if (priorityFlag) {
          // Swap with the previous item if it exists
          if (index > 0) {
            const newSolutions = [...solutionList];
            [newSolutions[index], newSolutions[index - 1]] = [
              newSolutions[index - 1],
              newSolutions[index],
            ];
            setSolutionList(newSolutions);
          }
        } else {
          // Swap with the next item if it exists
          if (index < solutionList?.length - 1) {
            const newSolutions = [...solutionList];
            [newSolutions[index], newSolutions[index + 1]] = [
              newSolutions[index + 1],
              newSolutions[index],
            ];
            setSolutionList(newSolutions);
          }
        }
      }
    } catch (error) {}
  };

  const deleteSol = async (priorityFlag) => {
    try {
      let data = {
        solution_id: solutionContentData?.solution_id,
      };
      const { payload } = await dispatch(deleteSolution(data));
      if (payload?.status === "success") {
        setSolutionList(
          solutionList?.filter(
            (item) => item?.solution_id !== solutionContentData?.solution_id
          )
        );
      } else {
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE?.ERROR,
            msg: "Error",
          })
        );
      }
    } catch (error) {
      dispatch(
        setAlert({
          show: true,
          type: ALERT_TYPE?.ERROR,
          msg: error,
        })
      );
    }
  };

  return (
    <>
      <SolutionsCardAccordion expanded={flip}>
        <AccordionSummary aria-controls="panel1-content" id="panel1-header">
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Box
              sx={{
                display: "flex",
                gap: "15px",
                alignItems: "center",
              }}
            >
              <SmallButton
                color="redButton"
                startIcon={
                  <Box
                    component="img"
                    className="eye"
                    alt="eye logo"
                    src={flip ? upClose : downClose}
                    sx={{
                      height: 16,
                      width: { xs: 10, lg: 8, mlg: 10, xl: 15 },
                    }}
                  />
                }
                padding={0}
                startIconMargin="0px"
                height={"75px"}
                width={20}
                fontWeight={theme.typography.Bold}
                borderRadius={flip ? "10px 0px 0px 0px" : "10px 0px 0px 10px"}
                onClick={() => setFlip((prev) => !prev)}
              />

              <Box
                sx={{
                  width: "50px",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Box
                  component="img"
                  className="profileAvatar"
                  alt="crayon logo"
                  src={profile}
                  sx={{
                    mr: 1,
                    height: "47px !important",
                    width: "47px !important",
                    borderRadius: "10px 10px 0 0",
                    border: `1px solid ${theme.palette.borderColor}`,
                  }}
                />
                <SmallButton
                  color="userID"
                  label={`${
                    solutionContentData?.solution_id
                      ? solutionContentData?.solution_id
                      : "-"
                  }`}
                  mr={1}
                  fontSize={10}
                  fontWeight={700}
                  alignItems="end"
                  textColor="#000"
                  borderRadius="0px 0px 6px 6px"
                  marginTop="-2px"
                  minWidth="50px"
                  width="fit-content"
                ></SmallButton>
              </Box>

              <Box sx={{ height: "75px" }}>
                <Box sx={{ display: "flex", gap: "20px" }}>
                  <Box
                    sx={{ display: "flex", alignItems: "center", gap: "10px" }}
                  >
                    <Box
                      component={"img"}
                      src={titleIcon}
                      sx={{ width: 20, height: 23, marginLeft: "-4px" }}
                    />
                    <Typography
                      sx={{
                        fontSize: "16px",
                        fontWeight: theme.typography.Bold,
                        marginLeft: "-3px",
                      }}
                    >
                      {solutionContentData?.title?.charAt(0)?.toUpperCase() +
                        solutionContentData?.title?.slice(1)}
                    </Typography>
                  </Box>
                  <Box>
                    {solutionContentData?.user_types
                      ?.split(",")
                      ?.map((item, index) => {
                        return (
                          <Button
                            variant="contained"
                            color={getUserColor(item)}
                            sx={{
                              width: "75px",
                              height: "30px",
                              borderRadius:
                                (index === 0 &&
                                  solutionContentData?.user_types?.split(",")
                                    ?.length === 1 &&
                                  "0 0 10px 10px") ||
                                (index === 0 && "0 0 0 10px") ||
                                (index ===
                                  solutionContentData?.user_types?.split(",")
                                    ?.length -
                                    1 &&
                                  "0 0 10px 0"),
                              padding: "0 7px !important",
                              fontSize: "12px",
                              fontWeight: theme.typography.Bold,
                              boxShadow: "none !important",
                              cursor: "default",
                            }}
                          >
                            {item}
                          </Button>
                        );
                      })}
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                    marginBottom: "4px",
                  }}
                >
                  <Box
                    component={"img"}
                    src={cancel}
                    sx={{ width: "12px", height: "15px", marginLeft: "0px" }}
                  />
                  <Tooltip
                    title={solutionContentData?.industry_challenge}
                    placement="top-end"
                    disableInteractive
                  >
                    <Typography
                      sx={{
                        fontSize: "12px",
                        fontWeight: theme.typography.Regular,
                      }}
                    >
                      {truncate(solutionContentData?.industry_challenge, {
                        length: 10,
                      })}
                    </Typography>
                  </Tooltip>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                    marginLeft: "-1px",
                  }}
                >
                  <CheckCircle
                    sx={{
                      height: "15px",
                      width: "15px",
                    }}
                    color="checkColor"
                  />
                  <Tooltip
                    title={solutionContentData?.crayon_solution}
                    placement="top-end"
                    disableInteractive
                  >
                    <Typography
                      sx={{
                        fontSize: "12px",
                        fontWeight: theme.typography.Regular,
                      }}
                    >
                      {truncate(solutionContentData?.crayon_solution, {
                        length: 10,
                      })}
                    </Typography>
                  </Tooltip>
                </Box>
              </Box>
            </Box>
            <Box sx={{ height: "75px" }}>
              <Button
                variant="contained"
                color="redButton"
                sx={{
                  minWidth: "60px !important",
                  maxWidth: "60px !important",
                  height: "30px",
                  borderRadius: "0 0 0 10px",
                  padding: "0 11px !important",
                  // paddingLeft: "11px !important",
                  boxShadow: "none !important",
                  // justifyContent: "flex-start",
                }}
              >
                <Box
                  component="img"
                  className="eye"
                  alt="eye logo"
                  src={reject}
                  sx={{
                    height: 15,
                    width: 11,
                  }}
                />
                <Typography
                  sx={{
                    fontSize: "12px",
                    fontWeight: theme.typography.Bold,
                    width: "40px",
                  }}
                >
                  {solutionContentData?.dislike || 0}
                </Typography>
              </Button>
              <Button
                variant="contained"
                color="checkColor"
                sx={{
                  minWidth: "60px !important",
                  maxWidth: "60px !important",
                  height: "30px",
                  borderRadius: "0px",
                  padding: "0 11px !important",
                  // paddingLeft: "11px !important",
                  boxShadow: "none !important",
                  // justifyContent: "flex-start",
                }}
              >
                <Box
                  component="img"
                  className="eye"
                  alt="eye logo"
                  src={likeThis}
                  sx={{
                    height: 15,
                    width: 11,
                  }}
                />
                <Typography
                  sx={{
                    fontSize: "12px",
                    fontWeight: theme.typography.Bold,
                    width: "40px",
                  }}
                >
                  {solutionContentData?.like || 0}
                </Typography>
              </Button>
              <Button
                variant="contained"
                color="promoterButton"
                sx={{
                  minWidth: "60px !important",
                  maxWidth: "60px !important",
                  height: "30px",
                  borderRadius: "0 0 10px 0",
                  padding: "0 11px !important",
                  // paddingLeft: "11px !important",
                  boxShadow: "none !important",
                  // justifyContent: "flex-start",
                }}
              >
                <Box
                  component="img"
                  className="eye"
                  alt="eye logo"
                  src={loveThis}
                  sx={{
                    height: 15,
                    width: 17,
                  }}
                />
                <Typography
                  sx={{
                    fontSize: "12px",
                    fontWeight: theme.typography.Bold,
                    width: "40px",
                  }}
                >
                  {solutionContentData?.loveit || 0}
                </Typography>
              </Button>
            </Box>

            <Box
              sx={{ height: "75px", display: "flex", flexDirection: "column" }}
            >
              <Box sx={{ display: "flex", marginRight: "21px" }}>
                {(decodedToken?.data?.role_id === 1 ||
                  decodedToken?.data?.role_id === 2) && (
                  <TalentSVGButton
                    // onClick={() => handleEmployerEdit(jobContent?.job_id)}
                    color={"white"}
                    source={editIcon}
                    // outerHeight={22}
                    height={33}
                    width={34}
                    padding={"0px !important"}
                    startIconMargin={"0px !important"}
                    onClick={() => {
                      setIsAddSoutionModal(true);
                      setIsEdit(true);
                      setEditSolutionData(solutionContentData);
                    }}
                  />
                )}
                {(decodedToken?.data?.role_id === 1 ||
                  decodedToken?.data?.role_id === 2) && (
                  <TalentSVGButton
                    color={"white"}
                    source={deleteIcon}
                    height={24}
                    width={18}
                    padding={"0 8px 0 0 !important"}
                    startIconMargin={"0px !important"}
                    onClick={deleteSol}
                  />
                )}
                <Button
                  variant="contained"
                  color="dateButton"
                  sx={{
                    fontSize: "12px",
                    fontWeight: 700,
                    color: "#000",
                    minWidth: "100px",
                    maxWidth: "100px !important",
                    padding: "0px",
                    borderRadius: "0 0 0 10px !important",
                    borderLeft: "1px solid #EBECF3",
                    borderRight: "1px solid #EBECF3",
                    borderBottom: "1px solid #EBECF3",
                    height: "30px !important",
                    whiteSpace: "nowrap",
                    ":hover": {
                      boxShadow: "none",
                    },
                  }}
                >
                  {dateConverterMonth(solutionContentData?.created_at)}
                </Button>

                <Tooltip
                  title={`${convertDatetimeAgo(
                    solutionContentData?.created_at
                  )} ago`}
                  placement="top-end"
                  disableInteractive
                >
                  <Button
                    variant="contained"
                    color="dateButton"
                    sx={{
                      fontSize: "12px",
                      fontWeight: theme.typography.Bold,
                      color: "#000",
                      padding: "0 5px",
                      minWidth: "75px !important",
                      maxWidth: "75px !important",
                      borderRadius: "0px !important",
                      borderRight: "1px solid #EBECF3",
                      borderBottom: "1px solid #EBECF3",
                      height: "30px !important",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      display: "block",
                      ":hover": {
                        boxShadow: "none",
                      },
                    }}
                  >
                    {convertDatetimeAgo(solutionContentData?.created_at)} ago
                  </Button>
                </Tooltip>
                <Box
                  sx={{
                    borderTopRightRadius: "10px",
                    width: "100px",
                    height: "30px",
                  }}
                >
                  <ChangeSolutionsStatus />
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  marginLeft: "60px",
                }}
              >
                <Box>
                  <SVGButton
                    color={"white"}
                    source={
                      solutionContentData?.challenge_video !== "No URL"
                        ? RedPlay
                        : GreenPlayGrey
                    }
                    height={35}
                    width={35}
                    startIconMargin={"0px !important"}
                    padding={"0px !important"}
                    onClick={() => {
                      if (solutionContentData?.challenge_video !== "No URL") {
                        setViewVideolink(solutionContentData?.challenge_video);
                        setOpenReviewVideoDialog(true);
                      }
                    }}
                  />

                  <SVGButton
                    color={"white"}
                    source={
                      solutionContentData?.solution_video !== "No URL"
                        ? GreenPlay
                        : GreenPlayGrey
                    }
                    height={35}
                    width={35}
                    startIconMargin={"0px !important"}
                    padding={"0px !important"}
                    onClick={() => {
                      if (solutionContentData?.solution_video !== "No URL") {
                        setViewVideolink(solutionContentData?.solution_video);
                        setOpenReviewVideoDialog(true);
                      }
                    }}
                  />
                </Box>
                <Box>
                  <SVGButton
                    color={"white"}
                    source={GreenUpClose}
                    height={35}
                    width={35}
                    startIconMargin={"0px !important"}
                    padding={"0px !important"}
                    onClick={() => changePriority(true)}
                  />

                  <SVGButton
                    color={"white"}
                    source={RedDown}
                    height={35}
                    width={35}
                    startIconMargin={"0px !important"}
                    padding={"0px !important"}
                    onClick={() => changePriority(false)}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box
            sx={{
              display: "flex",
              width: "100%",
              borderTop: "1px solid #C9CAD8",
            }}
          >
            <Box
              sx={{
                width: "20px",
                height: "auto",
                borderRadius: "0 0 0 10px",
                mt: "-2px",
                background: `${theme.palette.talentButton.main}`,
              }}
            ></Box>
            <Box sx={{ width: "100%" }}>
              <Box
                sx={{
                  display: "flex",
                  gap: "5px",
                  width: "100%",
                  justifyContent: "center",
                  marginBottom: "8px",
                }}
              >
                {solutionContentData?.solution_types
                  ?.split(",")
                  ?.map((item, index) => {
                    return (
                      <Button
                        variant="contained"
                        color={"recruitButton"}
                        sx={{
                          minWidth: "fit-content !important",
                          height: "30px",
                          borderRadius: "0 0 10px 10px",
                          padding: "0 7px !important",
                          fontSize: "12px",
                          fontWeight: theme.typography.Bold,
                          boxShadow: "none !important",
                          cursor: "default",
                        }}
                      >
                        {item}
                      </Button>
                    );
                  })}
              </Box>

              <Box sx={{ marginLeft: "15px", display: "flex", gap: "87px" }}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "15px",
                    width: "50%",
                  }}
                >
                  <Box>
                    <Typography
                      sx={{
                        fontSize: "12px",
                        fontWeight: theme.typography.Bold,
                      }}
                    >
                      Industry challenge
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "12px",
                        fontWeight: theme.typography.Bold,
                      }}
                    >
                      {solutionContentData?.industry_challenge}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography
                      sx={{
                        fontSize: "12px",
                        fontWeight: theme.typography.Bold,
                      }}
                    >
                      Industry challenge detail
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "12px",
                        fontWeight: theme.typography.Regular,
                      }}
                    >
                      {solutionContentData?.industry_challenge_detail}
                    </Typography>
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "15px",
                    width: "50%",
                    marginRight: "21px",
                  }}
                >
                  <Box>
                    <Typography
                      sx={{
                        fontSize: "12px",
                        fontWeight: theme.typography.Bold,
                      }}
                    >
                      Crayon solution
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "12px",
                        fontWeight: theme.typography.Bold,
                      }}
                    >
                      {solutionContentData?.crayon_solution}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography
                      sx={{
                        fontSize: "12px",
                        fontWeight: theme.typography.Bold,
                      }}
                    >
                      Crayon solution detail
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "12px",
                        fontWeight: theme.typography.Regular,
                      }}
                    >
                      {solutionContentData?.crayon_solution_detail}
                    </Typography>
                  </Box>
                </Box>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center !important",
                }}
              >
                <SmallButton
                  color="redButton"
                  startIcon={
                    <Box
                      component="img"
                      className="eye"
                      alt="eye logo"
                      src={upClose}
                      sx={{
                        height: 16,
                        width: 16,
                      }}
                    />
                  }
                  onClick={() => setFlip((prev) => !prev)}
                  startIconMargin="4px"
                  marginTop="5px"
                  height={20}
                  width={75}
                  fontWeight={700}
                  borderRadius="7px 7px 0px 0px"
                />
              </Box>
            </Box>
          </Box>
        </AccordionDetails>
      </SolutionsCardAccordion>
      {openReviewVideoDialog && (
        <ReviewVideoDialog
          videoLink={viewVideoLink}
          open={openReviewVideoDialog}
          setOpen={handleCloseDialog}
        />
      )}
    </>
  );
}
