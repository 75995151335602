import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getApi, postApi } from "../../utils/Apis";
import { setLoading } from "../configSlice";
const initialState = {
  candidate: [],
};
export const getCandidateJobs = createAsyncThunk(
  "getCandidateJobs",
  async (payload, { dispatch }) => {
    dispatch(setLoading(true));
    const { data } = await getApi(
      `/candidate/job?jobstatus_id=&candidatestatus_id=&lastKey=`,
      true
    );
    dispatch(setLoading(false));
    return data;
  }
);

export const getVideoJobsList = createAsyncThunk(
  "getVideoJobsList",
  async ({ lastKey }, { dispatch }) => {
    dispatch(setLoading(true));
    const { data } = await getApi(
      `/candidate/jobvideos?lastKey=${lastKey}`,
      true
    );
    dispatch(setLoading(false));
    return data;
  }
);

export const deleteCandidateUploadedVideo = createAsyncThunk(
  "deleteCandidateUploadedVideo",
  async (payload, { dispatch }) => {
    dispatch(setLoading(true));
    const { data } = await getApi(`/candidate/deletecamvideo`, true);
    dispatch(setLoading(false));
    return data;
  }
);

export const deleteCandidateJobVideo = createAsyncThunk(
  "deleteCandidateJobVideo",
  async ({ job_id }, { dispatch }) => {
    dispatch(setLoading(true));
    const { data } = await getApi(
      `/candidate/deletejobcamvideo?job_id=${job_id}`,
      true
    );
    dispatch(setLoading(false));
    return data;
  }
);

export const uploadMyCamVideo = createAsyncThunk(
  "uploadMyCamVideo",
  async (payload, { dispatch }) => {
    // dispatch(setLoading(true));
    const { data } = await postApi(
      "/upload/cam",
      payload,
      true,
      "multipart/form-data"
    );
    // dispatch(setLoading(false));
    return data;
  }
);

export const uploadJobCamVideo = createAsyncThunk(
  "uploadJobCamVideo",
  async (payload, { dispatch }) => {
    dispatch(setLoading(true));
    const { data } = await postApi(
      "/upload/jobcam",
      payload,
      true,
      "multipart/form-data"
    );
    dispatch(setLoading(false));
    return data;
  }
);
export const uploadJobCamVideoWithOutLogin = createAsyncThunk(
  "uploadJobCamVideo",
  async (payload, { dispatch }) => {
    dispatch(setLoading(true));
    const { data } = await postApi(
      "upload/jobcamwithoutlogin",
      payload,
      true,
      "multipart/form-data"
    );
    dispatch(setLoading(false));
    return data;
  }
);
export const getMyCamVideo = createAsyncThunk(
  "getMyCamVideo",
  async ({ user_id }, { dispatch }) => {
    // dispatch(setLoading(true));
    const { data } = await getApi(
      user_id
        ? `/candidate/camvideo?user_id=${user_id}`
        : `/candidate/camvideo`,
      true
    );
    // dispatch(setLoading(false));

    return data;
  }
);

export const getCandidateVideoLink = createAsyncThunk(
  "getCandidateVideoLink",
  async (payload, { dispatch }) => {
    const { data } = await getApi(
      `/candidate/jobcamvideo?job_id=${payload.job_id}`,
      true
    );
    return data;
  }
);

export const getPromoterJobs = createAsyncThunk(
  "getPromoterJobs",
  async ({ lastKey, jobStageID, jobStatusID }, { dispatch }) => {
    dispatch(setLoading({value:true, apiName:"getPromoterJobs"}));
    const { data } = await getApi(
      `/promoter/getSharedJobs?lastKey=${lastKey}&jobstage_id=${jobStageID}&jobstatus_id=${jobStatusID}`,
      true
    );
    // dispatch(setLoading(false));
    dispatch(setLoading({value:false,apiName:"getPromoterJobs"}));
    return data;
  }
);

export const getPromoterJobReferrals = createAsyncThunk(
  "getPromoterJobReferrals",
  async ({ lastKey, job_id }, { dispatch }) => {
    // dispatch(setLoading(true));
    const { data } = await getApi(
      `/promoter/getJobReferrals?lastKey=${lastKey}&job_id=${job_id}`,
      true
    );
    // dispatch(setLoading(false));
    return data;
  }
);

export const getPromoterReferrals = createAsyncThunk(
  "getPromoterReferrals",
  async ({ lastKey, user_id }, { dispatch }) => {
    dispatch(setLoading(true));
    const { data } = await getApi(
      user_id === undefined
        ? `/promoter/getUserReferrals?lastKey=${lastKey}`
        : `/promoter/getUserReferrals?lastKey=${lastKey}&user_id=${user_id}`,
      true
    );
    dispatch(setLoading(false));
    return data;
  }
);

export const getPromoterReferralsList = createAsyncThunk(
  "getPromoterReferralsList",
  async ({ lastKey, user_id }, { dispatch }) => {
    // dispatch(setLoading(true));
    const { data } = await getApi(
      `/promoter/getReferralUserJob?lastKey=${lastKey}&candidate_id=${user_id}`,
      true
    );
    // dispatch(setLoading(false));
    return data;
  }
);

export const getCandidateFilteredJob = createAsyncThunk(
  "getCandidateFilteredJob",
  async (
    {
      filterMyStatus,
      filterJobStatus,
      filterMyStage = "",
      filterJobStage = "",
      lastKey = "",
      job_title = "",
      region_id = "",
      tag_id = "",
      town_id = "",
      tool_id = "",
      salary = "",
      experience = "",
      company_id = "",
      currency_id = "",
      highest_qualification_id = "",
    },
    { dispatch }
  ) => {
    if (filterMyStatus === "1111" || filterMyStatus === "My Status") {
      filterMyStatus = "";
    }
    if (filterJobStatus === "1111" || filterJobStatus === "Job Status") {
      filterJobStatus = "";
    }
    if (filterMyStage === "1111" || filterMyStage === "my stages") {
      filterMyStage = "";
    }
    if (filterJobStage === "1111" || filterJobStage === "job stages") {
      filterJobStage = "";
    }
    // dispatch(setLoading(true));
    const { data } = await getApi(
      `/candidate/job?jobstatus_id=${filterJobStatus}&candidatestatus_id=${filterMyStatus}&lastKey=${lastKey}&job_title=${job_title}&region_id=${region_id}&tag_id=${tag_id}&town_id=${town_id}&tool_id=${tool_id}&company_id=${company_id}&currency_id=${currency_id}&highest_qualification_id=${highest_qualification_id}&salary=${salary}&experience=${experience}&mystage=${filterMyStage}&jobstage=${filterJobStage}`,
      true
    );
    // dispatch(setLoading(false));
    return data;
  }
);

export const changeStatus = createAsyncThunk(
  "changeStatus",
  async (payload, { dispatch }) => {
    dispatch(setLoading(true));
    const { data } = await postApi("/candidate/statuschange", payload, true);
    dispatch(setLoading(false));
    return data;
  }
);

export const candidateJobs = createSlice({
  name: "configcandidatejobs",
  initialState,
  reducers: {},
});
// Action creators are generated for each case reducer function
export default candidateJobs.reducer;
