import {
  Box,
  Button,
  Divider,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import companyLogo from "../../assets/Crayon_Favicon.svg";
import { dateConverterFullMonth } from "../../utils/DateTime";
import calendarIcon from "../../assets/Padding Included/Yellow_Calendar.svg";
import companyIcon from "../../assets/Padding Excluded/Blue_Following_Company-.svg";
import playButtonIcon from "../../assets/Padding Excluded/Black_reactive_job copy.svg";
import playButtonIconWhite from "../../assets/Padding Excluded/Black_reactive_job.svg";

import CompanyLink from "../../assets/Padding Excluded/teal_World_Website.svg";
import LocationIcon from "../../assets/Padding Included/Red_Location.svg";
import MUIRadialChart from "../common/MUIRadialChart";
import FollowIcon from "../../assets/Padding Excluded/Black_Company Followers.svg";

import { useDispatch } from "react-redux";
import { setAlert } from "../../redux/configSlice";
import { ALERT_TYPE, ERROR_MSG } from "../../utils/Constants";
import SmallButton from "./SmallButton";
import upArrow from "../../assets/Black_Up_Close - Copy.svg";
import downArrow from "../../assets/Black_Down_Open - Copy.svg";
import { Link } from "react-router-dom";
import { followCompany, unFollow } from "../../redux/candidate/myProfileSlice";
import jwt_decode from "jwt-decode";
import ReviewVideoDialog from "./ReviewVideoDialog";
// import Slider2 from "./Slider2";
import { useRef } from "react";
import useViewport from "./ViewPort";
import { truncateText } from "../../utils/Common";
import ReactCardFlip from "react-card-flip";
import CompanyInfoFrontCard from "./Company/CompanyInfoFrontCard.js";
import Slider2 from "./Slider2";
import CompanyStatsFrontCard from "./Company/CompanyStatsFrontCard.js";
import CompanyStatsBackCard from "./Company/CompanyStatsBackCard.js";
import CompanyInfoBackCard from "./Company/CompanyInfoBackCard.js";
// import CompanyInfoBackCard from "./Company/CompanyInfoBackCard";

export default function CompanyInfoCard({
  companyid,
  companyDetail,
  companyFollowed,
  setCompanyFollowed,
  leftExpanded,
  newCardWidth,
  newPadding,
}) {
  const dispatch = useDispatch();
  const theme = useTheme();
  const { width } = useViewport();
  const [viewMoreExpanded, setViewMoreExpanded] = useState(false);
  const [openReviewVideoDialog, setOpenReviewVideoDialog] = useState(false);
  const [videoLink, setVideoLink] = useState("");
  const [showMobileView, setShowMobileView] = useState(false);
  const [isHorizontalFlippedInfo, setisHorizontalFlippedInfo] = useState(false);
  const [isHorizontalFlipped, setisHorizontalFlipped] = useState(false);

  useEffect(() => {
    if (width >= 1023) {
      setShowMobileView(false);
    } else {
      setShowMobileView(true);
    }
  }, [width]);

  const industries = companyDetail?.employer_industries?.map(
    (item) => item?.industry?.name
  );

  const culture = companyDetail?.employer_cultures?.map(
    (item) => item?.company_culture?.name
  );

  const token = localStorage?.getItem("token");
  let decodedToken;
  if (token) {
    decodedToken = jwt_decode(token);
  }

  const calculateHeight = () => {
    const screenWidth = window.innerWidth;

    if (screenWidth < 600) {
      // xs
      // return 60;
      return 100;
    } else if (screenWidth < 900) {
      // sm
      // return 70;
      return 125;
    } else if (screenWidth < 1366) {
      // md
      return 125;
    } else if (screenWidth < 1785) {
      return 125;
    } else if (screenWidth < 1920) {
      // lg
      return 125;
    } else if (screenWidth < 2559) {
      // xl
      return 125;
    } else {
      // xxl or greater
      return 125;
    }
  };

  const handleViewMore = () => {
    setViewMoreExpanded((prevState) => !prevState);
  };

  const followComp = async () => {
    try {
      const data = {
        company_id: companyid,
      };
      if (decodedToken?.data?.role_id === 3) {
        !companyFollowed ? setCompanyFollowed(true) : setCompanyFollowed(false);
        const { payload } = !companyFollowed
          ? await dispatch(followCompany(data))
          : await dispatch(unFollow(data));
        if (
          payload?.data?.status === "success" ||
          payload?.status === "success"
        ) {
          dispatch(
            setAlert({
              show: true,
              type: ALERT_TYPE?.SUCCESS,
              msg: "Company followed",
            })
          );
        }
      } else {
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE?.ERROR,
            msg: "Login as candidate to follow",
          })
        );
      }
    } catch (error) {
      dispatch(
        setAlert({
          show: true,
          type: ALERT_TYPE?.ERROR,
          msg: ERROR_MSG,
        })
      );
    }
  };

  const handleCloseDialog = () => {
    setVideoLink("");
    setOpenReviewVideoDialog(false);
  };

  const [mainBoxHeight, setMainBoxHeight] = useState("auto"); // Initial height is set to "auto"
  const mainBoxRef = useRef(null);

  useEffect(() => {
    const mainBoxContent = mainBoxRef.current;
    if (mainBoxContent?.scrollHeight > mainBoxContent?.clientHeight) {
      setMainBoxHeight(`${mainBoxContent?.scrollHeight + 30}px`);
    } else {
      setMainBoxHeight("auto");
    }
  }, [viewMoreExpanded]);
  if (width === 0) {
    return null;
  }

  return (
    <>
      <Box
        sx={{
          display: "flex",
          // marginBottom: { xl: "38px" },
          flexDirection: { xs: "column", lg: "row" },
          // alignItems: { xs: "center", lg: "baseline", xl: "center" },
          justifyContent:{lg:"center"},
          alignItems: "center",
          width: { xs: "100%", md: "100%" },
          // margin: "55px 30px",
          gap: "30px",
        }}
      >
        <Box
          sx={{
            minWidth: {
              xs: "340px",
              xs390: "360px",
              sm: "90%",
              tablet: "100%",
              md24: "96.5%",
              lg: "45%",
              mlg: `calc(50% - 15px)`,
            },
            width: {
              xs: "340px",
              xs390: "360px",
              sm: "90%",
              tablet: "100%",
              md24: "96.5%",
              lg: "45%",
              mlg: `calc(50% - 15px)`,
            },
            // background: "#ffff",
            // boxShadow: "0px 5px 10px #00000029",
            // borderRadius: "25px",
            // marginRight: "30px",
            display: "flex",
            flexDirection: "column",
            marginBottom: { xs: "30px", lg: "0px" },
            minHeight: {
              xs: "300px",
              tablet: "225px",
              lg: "280px",
              mlg: "225px",
            },
            // order:"1"
          }}
        >
          <Box sx={{ width: "100%" }}>
            <ReactCardFlip
              isFlipped={isHorizontalFlippedInfo}
              flipDirection={"horizontal"}
              flipSpeedBackToFront="0.5"
              flipSpeedFrontToBack="0.5"
              // key={job?.job_id}
            >
              <CompanyInfoFrontCard
                companyDetail={companyDetail}
                setisHorizontalFlipped={setisHorizontalFlippedInfo}
                isHorizontalFlipped={isHorizontalFlippedInfo}
                setVideoLink={setVideoLink}
                setOpenReviewVideoDialog={setOpenReviewVideoDialog}
              />
              <CompanyInfoBackCard
                companyDetail={companyDetail}
                setisHorizontalFlipped={setisHorizontalFlippedInfo}
                isHorizontalFlipped={isHorizontalFlippedInfo}
                setVideoLink={setVideoLink}
                setOpenReviewVideoDialog={setOpenReviewVideoDialog}
              />
              {/* <Box
                sx={{
                  height: {
                    xs: "300px",
                    tablet: "225px",
                    lg: "280px",
                    mlg: "225px",
                  },
                  background: "#ffff",
                  boxShadow: "0px 5px 10px #00000029",
                  borderRadius: "25px",
                }}
              ></Box> */}
              {/* <CompanyInfoBackCard
              profile={profile}
              hiddenFileInput={hiddenFileInput}
              handleImageClick={handleImageClick}
              handleImageChange={handleImageChange}
              handlePhotoCapture={handlePhotoCapture}
              setisHorizontalFlipped={setisHorizontalFlippedInfo}
              isHorizontalFlipped={isHorizontalFlippedInfo}
              expanded={expanded}
              handleClickMyInfo={handleClickMyInfo}
              setOpenReviewVideoDialog={setOpenReviewVideoDialog}
              setVideoLink={setVideoLink}
            /> */}
            </ReactCardFlip>
          </Box>
        </Box>
        <Box
          sx={{
            minWidth: {
              xs: "340px",
              xs390: "360px",
              sm: "90%",
              tablet: "100%",
              md24: "96.5%",
              lg: "50%",
              mlg: `calc(50% - 15px)`,
            },
            width: {
              xs: "340px",
              xs390: "360px",
              sm: "90%",
              tablet: "100%",
              md24: "96.5%",
              lg: "50%",
              mlg: `calc(50% - 15px)`,
            },
            // background: "#ffff",
            // boxShadow: "0px 5px 10px #00000029",
            // borderRadius: "25px",
            // marginRight: "30px",
            display: "flex",
            flexDirection: "column",
            marginBottom: { xs: "30px", lg: "0px" },
            minHeight: {
              xs: "212px",
              tablet: "225px",
              lg: "280px",
              mlg: "225px",
            },
            // order:"1"
          }}
        >
          <Box sx={{ width: "100%" }}>
            <ReactCardFlip
              isFlipped={isHorizontalFlipped}
              flipDirection={"horizontal"}
              flipSpeedBackToFront="0.5"
              flipSpeedFrontToBack="0.5"
              // key={job?.job_id}
            >
              <CompanyStatsFrontCard
                companyDetail={companyDetail}
                calculateHeight={calculateHeight}
                setisHorizontalFlipped={setisHorizontalFlipped}
              />
              <CompanyStatsBackCard
                companyDetail={companyDetail}
                calculateHeight={calculateHeight}
                setisHorizontalFlipped={setisHorizontalFlipped}
              />
              {/* <CandidatePersonalityFrontCard
              profile={profile}
              handleLeftClick={handleLeftClick}
              arrSlider2={arrSlider2}
              handleRightClick={handleRightClick}
              calculateHeight={calculateHeight}
              setisHorizontalFlipped={setisHorizontalFlipped}
            />
            <CandidatePersonalityBackCard
              profile={profile}
              setisHorizontalFlipped={setisHorizontalFlipped}
              isHorizontalFlipped={isHorizontalFlipped}
              calculateHeight={calculateHeight}
            /> */}
            </ReactCardFlip>
          </Box>
        </Box>
      </Box>

      <Box
        ref={mainBoxRef}
        width={{ xs: "100%", md24: "auto" }}
        // display="flex"
        display="none"
        sx={{
          margin: "55px auto 0",
          flexDirection: {
            xs: "column",
            md24: "column",
            lg: "column",
            lgmm: "row",
            marginLeft: { xs: "0px", md24: `${newPadding}px` },
          },
          justifyContent: { xs: "center", md24: "start" },
          alignItems: { xs: "center", xl1920: "flex-start" },
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", md24: "row", lg: "row" },
            justifyContent: { md24: "start" },
            width: { md24: "100%", lgmm: "auto" },
          }}
        >
          <Box
            sx={{
              width: { xs360: "340px", xs390: `${newCardWidth}px` },
              background: "#fff",
              boxShadow: `0px 5px 10px ${theme.palette.boxShadow.main} !important`,
              borderRadius: "25px",
              height: "225px",
              marginRight: { xs: "0px", md24: `${newPadding}px` },
              marginBottom: { xs: "20px", md24: "0px" },
            }}
          >
            <Box
              width={"100%"}
              textAlign={"end"}
              sx={{
                display: "flex",
                justifyContent: "end",
                marginBottom: "6px",
              }}
            >
              <Box
                sx={{
                  height: "45px",
                  color: "white",
                  minWidth: "60px",
                  display: "flex",
                  fontSize: "12px",
                  maxWidth: "60px",
                  justifyContent: "center",
                  borderRadius: "0 0 0 10px",
                  paddingX: "0px",
                  fontWeight: theme.typography.Bold,
                  alignItems: "center",
                  background: theme.palette.companySizeButton.main,
                }}
              >
                {companyDetail?.company_type
                  ? companyDetail?.company_type
                  : "-"}
              </Box>
              <Box
                sx={{
                  height: "45px",
                  color: "white",
                  minWidth: "60px",
                  display: "flex",
                  paddingX: "0px",
                  fontSize: "12px",
                  maxWidth: "60px",
                  justifyContent: "center",
                  alignItems: "center",
                  fontWeight: theme.typography.Bold,
                  background: theme.palette.betaVersion.main,
                }}
              >
                {companyDetail?.company_size
                  ? companyDetail?.company_size
                  : "-"}
              </Box>
              <Button
                variant="contained"
                color="playButton"
                sx={{
                  borderRadius: "0 25px 0 0",
                  fontSize: "12px",
                  height: "auto",
                  minHeight: "45px",
                  minWidth: "60px",
                  maxWidth: "60px",
                  background:
                    companyDetail?.companyVideoURL === "NO URL" &&
                    theme.palette.grayButton600.main,
                  ":hover": {
                    boxShadow: 0,
                  },
                }}
                onClick={() => {
                  if (companyDetail?.companyVideoURL !== "NO URL") {
                    setVideoLink(companyDetail?.companyVideoURL);
                    setOpenReviewVideoDialog(true);
                  }
                }}
              >
                <Box
                  component={"img"}
                  src={
                    companyDetail?.companyVideoURL === "NO URL"
                      ? playButtonIcon
                      : playButtonIconWhite
                  }
                  sx={{
                    height: "16px",
                    width: "20px",
                  }}
                />
              </Button>
            </Box>
            <Box
              sx={{
                display: "flex",
                gap: "15px",
                paddingLeft: "15px",
              }}
            >
              <Box
                component="img"
                className="companyLogo"
                alt="crayon logo"
                width={{
                  xs: "30%",
                  md24: (leftExpanded && width) === 1024 ? "30%" : "100%",
                }}
                src={
                  companyDetail?.companyURL !== "NO URL"
                    ? companyDetail?.companyURL
                    : companyLogo
                }
                sx={{
                  height: "100px",
                  width: "100px",
                  borderRadius: "10px",
                }}
              />
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                  cursor: "default",
                }}
                width={{
                  xs: "63%",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "start",
                    height: "25px",
                    gap: 1,
                  }}
                >
                  <Box
                    component={"img"}
                    src={companyIcon}
                    sx={{
                      height: "20px",
                      width: "20px",
                    }}
                  />
                  <Tooltip
                    disableInteractive
                    title={`${companyDetail?.name} `}
                    placement="top-end"
                  >
                    <Typography
                      sx={{
                        height: "25px",
                        alignItems: "flex-end",
                        fontSize: "20px",
                        fontWeight: 700,
                        width: {
                          xs: "100%",
                        },
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {companyDetail?.name
                        ? width < 1024
                          ? truncateText(companyDetail?.name, 25)
                          : companyDetail?.name
                        : "-"}
                    </Typography>
                  </Tooltip>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    gap: 1,
                    width: "100%",
                  }}
                >
                  <Box
                    component={"img"}
                    src={LocationIcon}
                    sx={{
                      height: "20px",
                      width: "20px",
                    }}
                  />
                  <Typography
                    sx={{
                      fontSize: "14px",
                      fontWeight: 700,
                      width: {
                        xs: "100%",
                      },
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {companyDetail?.town_name && companyDetail?.country_name
                      ? width < 1024
                        ? truncateText(
                            `${companyDetail?.town_name}, ${companyDetail?.country_name}`,
                            25
                          )
                        : `${companyDetail?.town_name}, ${companyDetail?.country_name}`
                      : "-"}
                  </Typography>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    gap: 1,
                    width: "100%",
                  }}
                >
                  <Box
                    component={"img"}
                    src={CompanyLink}
                    sx={{
                      height: "20px",
                      width: "20px",
                    }}
                  />
                  <Typography
                    sx={{
                      fontSize: "14px",
                      fontWeight: 700,
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                      width: { xs: "100%" },
                    }}
                  >
                    <Link
                      style={{ textDecoration: "none", color: "black" }}
                      target="_blank"
                      to={companyDetail?.website}
                    >
                      {companyDetail?.website
                        ? width < 1024
                          ? truncateText(companyDetail?.website, 25)
                          : companyDetail?.website
                        : "-"}
                    </Link>
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    gap: 1,
                    width: "100%",
                  }}
                >
                  <Box
                    component={"img"}
                    src={calendarIcon}
                    sx={{
                      height: "20px",
                      width: "20px",
                    }}
                  />
                  <Typography
                    sx={{
                      fontSize: "14px",
                      fontWeight: 700,
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {companyDetail?.created_at
                      ? width <= 1024
                        ? truncateText(
                            dateConverterFullMonth(companyDetail?.created_at),
                            10
                          )
                        : dateConverterFullMonth(companyDetail?.created_at)
                      : "-"}
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                gap: "5px",
                marginBottom: "20px",
                marginLeft: "15px",
                marginTop: "12px",
                width: {
                  xs360: "305px",
                  xs390: `${newCardWidth - 40}px`,
                  xl: `${newCardWidth - 30}px`,
                  xl1920: `${newCardWidth - 40}px`,
                },
              }}
            >
              <Slider2
                items={industries || []}
                color={"industriesButton"}
                hideTagsAfter={2}
              />
            </Box>
          </Box>

          <Box
            sx={{
              width: { xs360: "340px", xs390: `${newCardWidth}px` },
              background: "#fff",
              boxShadow: `0px 5px 10px ${theme.palette.boxShadow.main} !important`,
              borderRadius: "25px",
              height: "225px",
              marginBottom: { xs: "20px", md24: "0px" },
              padding: "14px 15px 0px 15px",
              boxSizing: "border-box",
              position: "relative",
            }}
          >
            <Box
              // width={{
              //   xs: "100%",
              //   md24: "90%",
              // }}
              display={"flex"}
              flexDirection={"column"}
            >
              <Typography
                sx={{
                  fontSize: "12px",
                  fontWeight: theme.typography.Bold,
                }}
              >
                About
              </Typography>
              <Typography
                sx={{
                  fontSize: "12px",
                  fontWeight: theme.typography.fontWeightMedium,
                  height: viewMoreExpanded ? "120px" : "105px",
                  whiteSpace: "unset",
                  lineHeight: "1.6",
                  width: "100%",
                  overflow: viewMoreExpanded ? "scroll" : "hidden",
                }}
                noWrap={!viewMoreExpanded}
              >
                {companyDetail?.description}
              </Typography>
            </Box>
            <Box
              sx={{
                width: { xs: "100%", tablet: "90%" },
                display: "block",
              }}
            >
              {companyDetail?.description?.split(/\s+/)?.length > 40 ? (
                <Button
                  sx={{
                    color: "black",
                    padding: 0,
                    fontSize: "12px !important",
                    fontWeight: theme.typography.Bold,
                    ".MuiButton-startIcon": {
                      marginRight: "0px !important",
                      marginLeft: "-18px !important",
                    },
                    height: "30px",
                  }}
                  startIcon={
                    <Box
                      component="img"
                      sx={{
                        height: 30,
                        width: 30,
                        cursor: "pointer",
                      }}
                      alt="crayon logo"
                      src={viewMoreExpanded ? upArrow : downArrow}
                    />
                  }
                  onClick={handleViewMore}
                >
                  {viewMoreExpanded ? "less" : "more"}
                </Button>
              ) : (
                <Box sx={{ height: "30px" }} />
              )}
            </Box>
            <Box sx={{ position: "absolute", bottom: "0px" }}>
              {culture?.length > 0 && (
                <Box
                  sx={{
                    display: "flex",
                    gap: "5px",
                    // width: "90%",
                    // width: { xs: "340px", md24: "90%" },
                    width: {
                      xs360: "305px",
                      xs390: `${newCardWidth - 40}px`,
                      xl: `${newCardWidth - 30}px`,
                      xl1920: `${newCardWidth - 40}px`,
                    },
                    marginBottom: "16px",
                    minHeight: "25px",
                  }}
                >
                  <Slider2
                    items={culture || []}
                    color={"industriesButton"}
                    hideTagsAfter={2}
                    slidertype={"company"}
                  />
                </Box>
              )}
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            paddingTop: { md24: "20px", lgmm: "0px" },
            width: { md24: "100%" },
          }}
        >
          <Box
            sx={{
              width: {
                xs360: "340px",
                xs390: `${newCardWidth}px`,
                md24: `${newCardWidth + newCardWidth + newPadding}px`,
              },
              flex: "1",
              background: "#fff",
              boxShadow: `0px 5px 10px ${theme.palette.boxShadow.main} !important`,
              borderRadius: "25px",
              minHeight: { md24: "225px" },
              height: { xs: "auto", md24: "225px", xl1920: "auto" },
              marginLeft: { xs: "0px", lgmm: `${newPadding}px` },
              display: "flex",
              flexDirection: "column",
              // width:{md24:"100%"}
            }}
          >
            <Box
              sx={{
                flex: "1",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                  // flexGrow: 1,
                  alignItems: "center",
                  justifyContent: {
                    xs: "center",
                    md24: "center",
                    xl: "space-evenly",
                  },
                  flexWrap: "wrap",
                  gap: "8px",
                  marginBottom: { xs: "15px", md24: "0px" },
                  marginTop: { xs: "15px", md24: "0px" },
                }}
              >
                <MUIRadialChart
                  size={calculateHeight()}
                  max={100}
                  countFontSize={20}
                  labelFontSize={12}
                  chartName={"job postings"}
                  value={companyDetail?.TotalJobCount}
                  color={"jobPosting"}
                />
                <MUIRadialChart
                  size={calculateHeight()}
                  max={100}
                  countFontSize={20}
                  labelFontSize={12}
                  chartName={"active jobs"}
                  value={companyDetail?.ActiveJobCount}
                  color={"jobPosting"}
                />
                <MUIRadialChart
                  size={calculateHeight()}
                  max={500}
                  countFontSize={20}
                  labelFontSize={12}
                  chartName={"applications"}
                  value={companyDetail?.TotalUserCount}
                  color={"Applied"}
                />
                <MUIRadialChart
                  size={calculateHeight()}
                  max={100}
                  countFontSize={20}
                  labelFontSize={12}
                  chartName={"shortlistings"}
                  value={companyDetail?.TotalUserShortlisted}
                  color={"Applied"}
                />
                <MUIRadialChart
                  size={calculateHeight()}
                  max={50}
                  countFontSize={20}
                  labelFontSize={12}
                  chartName={"interviews"}
                  value={companyDetail?.TotalUserInterviewed}
                  color={"Interviewed"}
                />
                <MUIRadialChart
                  size={calculateHeight()}
                  max={50}
                  countFontSize={20}
                  labelFontSize={12}
                  chartName={"hires"}
                  value={companyDetail?.TotalUserHired}
                  color={"jobPosting"}
                />
              </Box>
            </Box>

            <Box width={"100%"} textAlign={"end"}>
              <Button
                variant="contained"
                color="companyFollowButton"
                sx={{
                  borderRadius: "10px 0 25px 0 ",
                  width: "150px",
                  height: "45px",
                  fontSize: "14px !important",
                  fontWeight: theme.typography.Bold,
                  ":hover": {
                    boxShadow: 0,
                  },
                  ".MuiButton-startIcon": {
                    marginRight: "10px",
                  },
                }}
                startIcon={
                  <Box
                    component={"img"}
                    sx={{
                      height: 20,
                      width: 20,
                    }}
                    src={FollowIcon}
                  />
                }
                onClick={followComp}
              >
                {companyFollowed ? "following" : "follow"}
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>

      <Box
        display="none"
        // display={"flex"}
        // width={{ xs: `calc(100vw - 296px)`, lg: `calc(100vw - 296px)`, mlg: `calc(100vw - 350px)`, xl: `calc(100vw - 350px)` }}
        ref={mainBoxRef}
        width="100%"
        // width={{xs:"95%",md24:"100%"}}
        maxHeight={{ md24: `calc(50vh - 100px)` }}
        flexDirection={{
          xs: "column",
          md24: (leftExpanded && width) === 1024 ? "column" : "row",
        }}
        sx={{
          background: "#fff",
          boxShadow: `0px 5px 10px ${theme.palette.boxShadow.main} !important`,
          borderRadius: "25px",
          margin: "55px auto 0",
          justifyContent: {
            md24: (leftExpanded && width) === 1024 ? "" : "space-between",
          },
        }}
      >
        <Box
          display={"flex"}
          flexDirection={"column"}
          gap={"16px"}
          padding={{
            xs: "5px",
            md24: (leftExpanded && width) === 1024 ? "5px" : "25px 0 0 30px",
          }}
          width={{
            xs: "100%",
            md24: (leftExpanded && width) === 1024 ? "100%" : "35%",
          }}
        >
          <Box
            sx={{
              display: "flex",
              gap: "15px",
            }}
          >
            <Box
              component="img"
              className="companyLogo"
              alt="crayon logo"
              width={{
                xs: "30%",
                md24: (leftExpanded && width) === 1024 ? "30%" : "100%",
              }}
              //   src={image?.length > 0 ? image || compInfo?.profileURL : companyLogo}
              src={
                companyDetail?.companyURL !== "NO URL"
                  ? companyDetail?.companyURL
                  : companyLogo
              }
              sx={{
                height: {
                  xs: "85px",
                  md24: "100px",
                  mlg: "125px",
                  xl: "125px",
                  xxl: "140px",
                },
                width: {
                  xs: "85px",
                  md24: "100px",
                  mlg: "125px",
                  xl: "125px",
                  xxl: "140px",
                },
                // marginLeft: "15px",
                // marginRight: "15px",
                // border: 1,
                // borderColor: "lightgrey",
                borderRadius: "10px",
              }}
            />
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "10px",
                cursor: "default",
              }}
              width={{
                xs: "70%",
                md24: (leftExpanded && width) === 1024 ? "70%" : "100%",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "start",
                  height: "25px",
                  gap: 1,
                }}
              >
                <Box
                  component={"img"}
                  src={companyIcon}
                  sx={{
                    height: "20px",
                    width: "20px",
                  }}
                />
                <Tooltip
                  disableInteractive
                  title={`${companyDetail?.name} `}
                  placement="top-end"
                >
                  <Typography
                    sx={{
                      height: "25px",
                      alignItems: "flex-end",
                      fontSize: {
                        xs: "14px",
                        md24: "15px",
                        mlg: "18px",
                        xl: "18px",
                      },
                      fontWeight: 700,
                      width: {
                        xs: "100%",
                        mlg: "200px",
                        xl: "250px",
                      },
                      whiteSpace: "nowrap", // Prevents text from wrapping
                      overflow: "hidden", // Hides any overflowing content
                      textOverflow: "ellipsis",
                    }}
                  >
                    {companyDetail?.name
                      ? width < 1024
                        ? truncateText(companyDetail?.name, 25)
                        : companyDetail?.name
                      : "-"}
                  </Typography>
                </Tooltip>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  gap: 1,
                  width: "100%",
                }}
              >
                <Box
                  component={"img"}
                  src={LocationIcon}
                  sx={{
                    height: "20px",
                    width: "20px",
                  }}
                />
                <Typography
                  sx={{
                    fontSize: {
                      xs: "14px",
                      md24: "15px",
                      mlg: "15px",
                    },
                    fontWeight: 700,
                    width: {
                      xs: "100%",
                    },
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                  }}
                >
                  {companyDetail?.town_name && companyDetail?.country_name
                    ? width < 1024
                      ? truncateText(
                          `${companyDetail?.town_name}, ${companyDetail?.country_name}`,
                          25
                        )
                      : `${companyDetail?.town_name}, ${companyDetail?.country_name}`
                    : "-"}
                  {/* 
                {companyDetail?.town_name
                  ? `${companyDetail?.town_name}, ${companyDetail?.country_name}`
                  : "-"} */}
                </Typography>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  gap: 1,
                  width: "100%",
                }}
              >
                <Box
                  component={"img"}
                  src={CompanyLink}
                  sx={{
                    height: "20px",
                    width: "20px",
                  }}
                />
                <Typography
                  sx={{
                    fontSize: {
                      xs: "14px",
                      md24: "15px",
                      mlg: "15px",
                    },
                    fontWeight: 700,
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    width: { xs: "100%" },
                  }}
                >
                  <Link
                    style={{ textDecoration: "none", color: "black" }}
                    target="_blank"
                    to={companyDetail?.website}
                  >
                    {companyDetail?.website
                      ? width < 1024
                        ? truncateText(companyDetail?.website, 25)
                        : companyDetail?.website
                      : "-"}
                    {/* {companyDetail?.website || "-"} */}
                  </Link>
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  gap: 1,
                  width: "100%",
                }}
              >
                <Box
                  component={"img"}
                  src={calendarIcon}
                  sx={{
                    height: "20px",
                    width: "20px",
                  }}
                />
                <Typography
                  sx={{
                    fontSize: {
                      xs: "14px",
                      md24: "15px",
                      mlg: "15px",
                    },
                    fontWeight: 700,
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                  }}
                >
                  {/* Date joined:{" "} */}
                  {companyDetail?.created_at
                    ? width <= 1024
                      ? truncateText(
                          dateConverterFullMonth(companyDetail?.created_at),
                          10
                        )
                      : dateConverterFullMonth(companyDetail?.created_at)
                    : "-"}
                  {/* {dateConverterFullMonth(companyDetail?.created_at)} */}
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                gap: "5px",
              }}
            >
              {companyDetail?.company_type && (
                <SmallButton
                  color={"companyTypeButton"}
                  fontSize={12}
                  height={25}
                  label={companyDetail?.company_type}
                />
              )}
              {companyDetail?.company_size && (
                <SmallButton
                  fontSize={12}
                  color={"companySizeButton"}
                  height={25}
                  label={companyDetail?.company_size}
                  //   label={truncate(job?.work_setup, { length: 12 })}
                />
              )}
            </Box>
            <Box
              sx={{
                display: "flex",
                gap: "5px",
                marginBottom: "20px",
                width: { xs: "340px", md24: "420px" },
              }}
            >
              <Slider2
                items={industries || []}
                color={"industriesButton"}
                hideTagsAfter={2}
              />
            </Box>
          </Box>
        </Box>

        <Divider
          orientation={
            showMobileView
              ? "horizontal"
              : (leftExpanded && width) === 1024
              ? "horizontal"
              : "vertical"
          }
          variant="middle"
          flexItem
        />

        <Box
          display={"flex"}
          flexDirection={"column"}
          alignItems={{ xs: "center", sm: "start" }}
          marginTop={{
            xs: "20px",
            md24: (leftExpanded && width) === 1024 ? "20px" : "50px",
          }}
          // width={"20%"}
          width={{ md24: (leftExpanded && width) === 1024 ? "100%" : "20%" }}
          padding={{
            xs: "5px",
            md24: (leftExpanded && width) === 1024 ? "5px" : "0px 0px 0px 20px",
          }}
        >
          <Box
            width={{
              xs: "100%",
              md24: (leftExpanded && width) === 1024 ? "100%" : "90%",
            }}
            display={"flex"}
            flexDirection={"column"}
          >
            <Typography
              sx={{
                fontSize: "12px",
                fontWeight: theme.typography.Bold,
              }}
            >
              About
            </Typography>
            <Typography
              sx={{
                fontSize: "12px",
                fontWeight: theme.typography.fontWeightMedium,
                // height: viewMoreExpanded ? `calc(50vh - 208px)` : "106px",
                height: viewMoreExpanded ? "200px" : "106px",
                whiteSpace: "unset",
                lineHeight: "1.6",
                width: "100%",
                overflow: viewMoreExpanded ? "scroll" : "hidden",
              }}
              // maxHeight={{xs:"200px", md24:`calc(50vh - 500px)`}}
              noWrap={!viewMoreExpanded}
            >
              {companyDetail?.description}
            </Typography>
          </Box>
          <Box
            sx={{
              width: { xs: "100%", tablet: "90%" },
              // display: {
              //   xs: "block",
              //   // tb: "block",
              //   // md24: (leftExpanded && width) === 1024 ? "none" : "block",
              // },
            }}
          >
            {companyDetail?.description?.split(/\s+/)?.length > 35 && (
              <Button
                sx={{
                  color: "black",
                  padding: 0,
                  fontSize: "12px !important",
                  fontWeight: theme.typography.Bold,
                  ".MuiButton-startIcon": {
                    marginRight: "0px !important",
                    marginLeft: "-18px !important",
                  },
                  height: "30px",
                }}
                startIcon={
                  <Box
                    component="img"
                    sx={{
                      height: 30,
                      width: 30,
                      cursor: "pointer",
                    }}
                    alt="crayon logo"
                    src={viewMoreExpanded ? upArrow : downArrow}
                  />
                }
                onClick={handleViewMore}
              >
                {viewMoreExpanded ? "less" : "more"}
              </Button>
            )}
          </Box>

          {culture?.length > 0 && (
            <Box
              sx={{
                display: "flex",
                gap: "5px",
                // width: "90%",
                // width: { xs: "340px", md24: "90%" },
                width: { xs360: "340px", xs390: "355px", md24: "250px" },
                marginBottom: "16px",
                minHeight: "25px",
              }}
            >
              <Slider2
                items={culture || []}
                color={"industriesButton"}
                hideTagsAfter={2}
                slidertype={"company"}
              />
            </Box>
          )}
        </Box>

        <Divider
          orientation={
            showMobileView
              ? "horizontal"
              : (leftExpanded && width) === 1024
              ? "horizontal"
              : "vertical"
          }
          variant="middle"
          flexItem
        />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: {
              md24: (leftExpanded && width) === 1024 ? "100%" : "40%",
              mlg: "50%",
            },
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box
            width={"100%"}
            textAlign={"end"}
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: { xs: "10px", md24: "30px" },
            }}
          >
            <Box width={"100%"} textAlign={"end"}>
              <Button
                variant="contained"
                color="playButton"
                sx={{
                  borderRadius: {
                    xs: "0px",
                    md24:
                      (leftExpanded && width) === 1024
                        ? "0px"
                        : "0 25px 0 10px",
                  },
                  fontSize: "14px",
                  height: "auto",
                  minHeight: "45px",
                  minWidth: "60px",
                  background:
                    companyDetail?.companyVideoURL === "NO URL" &&
                    theme.palette.grayButton600.main,
                  ":hover": {
                    boxShadow: 0,
                  },
                }}
                // disabled={
                //   companyDetail?.companyVideoURL === "NO URL" ? true : false
                // }
                onClick={() => {
                  if (companyDetail?.companyVideoURL !== "NO URL") {
                    setVideoLink(companyDetail?.companyVideoURL);
                    setOpenReviewVideoDialog(true);
                  }
                }}
              >
                <Box
                  component={"img"}
                  src={
                    companyDetail?.companyVideoURL === "NO URL"
                      ? playButtonIcon
                      : playButtonIconWhite
                  }
                  sx={{
                    height: "16px",
                    width: "20px",
                  }}
                />
              </Button>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                // flexGrow: 1,
                alignItems: "center",
                justifyContent: {
                  xs: "center",
                  md24: "center",
                  xl: "space-evenly",
                },
                flexWrap: "wrap",
                gap: "8px",
                marginBottom: { xs: "15px", md24: "0px" },
              }}
            >
              <MUIRadialChart
                size={calculateHeight()}
                max={100}
                countFontSize={20}
                labelFontSize={12}
                chartName={"job postings"}
                value={companyDetail?.TotalJobCount}
                color={"jobPosting"}
              />
              <MUIRadialChart
                size={calculateHeight()}
                max={100}
                countFontSize={20}
                labelFontSize={12}
                chartName={"active jobs"}
                value={companyDetail?.ActiveJobCount}
                color={"jobPosting"}
              />
              <MUIRadialChart
                size={calculateHeight()}
                max={500}
                countFontSize={20}
                labelFontSize={12}
                chartName={"applications"}
                value={companyDetail?.TotalUserCount}
                color={"Applied"}
              />
              <MUIRadialChart
                size={calculateHeight()}
                max={100}
                countFontSize={20}
                labelFontSize={12}
                chartName={"shortlistings"}
                value={companyDetail?.TotalUserShortlisted}
                color={"Applied"}
              />
              <MUIRadialChart
                size={calculateHeight()}
                max={50}
                countFontSize={20}
                labelFontSize={12}
                chartName={"interviews"}
                value={companyDetail?.TotalUserInterviewed}
                color={"Interviewed"}
              />
              <MUIRadialChart
                size={calculateHeight()}
                max={50}
                countFontSize={20}
                labelFontSize={12}
                chartName={"hires"}
                value={companyDetail?.TotalUserHired}
                color={"jobPosting"}
              />
            </Box>
          </Box>
          <Box width={"100%"} textAlign={"end"}>
            <Button
              variant="contained"
              color="companyFollowButton"
              sx={{
                borderRadius: "10px 0 25px 0 ",
                width: "150px",
                height: "45px",
                fontSize: "14px !important",
                fontWeight: theme.typography.Bold,
                ":hover": {
                  boxShadow: 0,
                },
                ".MuiButton-startIcon": {
                  marginRight: "10px",
                },
              }}
              startIcon={
                <Box
                  component={"img"}
                  sx={{
                    height: 20,
                    width: 20,
                  }}
                  src={FollowIcon}
                />
              }
              onClick={followComp}
            >
              {companyFollowed ? "following" : "follow"}
            </Button>
          </Box>
        </Box>

        {openReviewVideoDialog && (
          <ReviewVideoDialog
            videoLink={videoLink}
            open={openReviewVideoDialog}
            setOpen={handleCloseDialog}
            allVideoData={companyDetail}
          />
        )}
      </Box>
    </>
  );
}
