import { useState } from "react";
import Button from "@mui/material/Button";
import locale from "../../../i18n/locale";
import { styled } from "@mui/material/styles";
import Menu from "@mui/material/Menu";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

import editIconHover from "../../../assets/Padding Excluded/Black_edit_job copy.svg";
import editIcon from "../../../assets/Padding Excluded/Black_edit_job.svg";

import pauseIconHover from "../../../assets/Padding Excluded/Black_pause_jobs copy.svg";
import pauseIcon from "../../../assets/Padding Excluded/Black_pause_jobs.svg";

import reActiveIconHover from "../../../assets/Padding Excluded/Black_reactive_job copy.svg";
import reActiveIcon from "../../../assets/Padding Excluded/Black_reactive_job.svg";

import duplicateIconHover from "../../../assets/Padding Excluded/Black_duplicate_job copy.svg";
import duplicateIcon from "../../../assets/Padding Excluded/Black_duplicate_job.svg";

import closeIconHover from "../../../assets/Padding Excluded/Black_close_Job copy.svg";
import closeIcon from "../../../assets/Padding Excluded/Black_close_Job.svg";

import viewIconHover from "../../../assets/Padding Excluded/Black_view._job copy.svg";
import viewIcon from "../../../assets/Padding Excluded/Black_view._job.svg";
import copyIcon from "../../../assets/Padding Excluded/Black_Documents.svg";
import toolIcon from "../../../assets/White_Tools.svg";
import downArrow from "../../../assets/Black_Down_Open.svg";
import upArrow from "../../../assets/Black_Up_Close.svg";

import {
  duplicateThejob,
  getManage,
  statusChange,
} from "../../../redux/employer/myJobsSlice";
import { useDispatch } from "react-redux";
import { setAlert } from "../../../redux/configSlice";
import { ALERT_TYPE, ERROR_MSG } from "../../../utils/Constants";
import Modal from "@mui/material/Modal";
import { useNavigate } from "react-router-dom";
import HoverBtn from "./HoverBtn";
import jwt_decode from "jwt-decode";
import { getToken } from "../../../utils/Common";
import CopyToClipboard from "react-copy-to-clipboard";
import { StyledMenu } from "../../../utils/CommonComponent";

const ManageButtonMenu = ({ job, cardWidth }) => {
  const i18n = locale.en;
  const history = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [manage, setManage] = useState("");
  const dispatch = useDispatch();
  const [temp, setTemp] = useState("");
  const [open2, setOpen2] = useState(false);

  const handleClose2 = () => setOpen2(false);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    borderRadius: "12px",
    p: 4,
    boxShadow: "none !important",
    border: "1px solid rgb(197, 197, 197) !important",
    ".&focus-visible": {
      outlineColor: "none",
    },
  };

  const pauseJobData = {
    job_id: job?.job_id,
    status: "paused",
  };
  const closeJobData = {
    job_id: job?.job_id,
    status: "closed",
  };
  const reactivateJobData = {
    job_id: job?.job_id,
    status: "reactivate",
  };
  const duplicateJobData = {
    job_id: job?.job_id,
  };

  const token = getToken();

  let decodedToken;
  if (token) {
    decodedToken = jwt_decode(token);
  }
  const reactivateJob = async () => {
    try {
      const { payload } = await dispatch(statusChange(reactivateJobData));
      if (payload?.status === "success") {
        setManage(payload?.statusname);
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE?.SUCCESS,
            msg: "Job Reactivated successfully!",
          })
        );
        handleClose2();
      } else {
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE?.ERROR,
            msg: payload?.message,
          })
        );
      }
    } catch (error) {
      dispatch(setAlert({ show: true }));
    }
  };
  const pauseJob = async () => {
    try {
      const { payload } = await dispatch(statusChange(pauseJobData));
      if (payload?.status === "success") {
        setManage(payload?.statusname);
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE?.SUCCESS,
            msg: "Job Paused successfully!",
          })
        );
        handleClose2();
      } else {
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE?.ERROR,
            msg: payload?.message,
          })
        );
      }
    } catch (error) {
      dispatch(setAlert({ show: true }));
    }
  };
  const closeJob = async () => {
    try {
      const { payload } = await dispatch(statusChange(closeJobData));
      if (payload?.status === "success") {
        setManage(payload?.statusname);
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE?.SUCCESS,
            msg: "Job Closed successfully!",
          })
        );
        handleClose2();
      } else {
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE?.ERROR,
            msg: payload?.message,
          })
        );
      }
    } catch (error) {
      dispatch(setAlert({ show: true }));
    }
  };
  const duplicateJob = async () => {
    try {
      const { payload } = await dispatch(duplicateThejob(duplicateJobData));
      if (payload?.status === "success") {
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE?.SUCCESS,
            msg: "Job Duplicated successfully!",
          })
        );
        decodedToken?.data?.role_id === 6
          ? history(`/recruiter/post-a-job/${payload?.data?.[0]?.job_id}`)
          : history(`/employer/post-a-job/${payload?.data?.[0]?.job_id}`);
        handleClose2();
      } else {
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE?.ERROR,
            msg: payload?.message,
          })
        );
      }
    } catch (error) {
      dispatch(setAlert({ show: true }));
    }
  };

  const handleOpen2 = (str) => {
    setOpen2(true);
    setTemp(str);
  };
  const handleJobStatus = () => {
    if (temp === "a") {
      decodedToken?.data?.role_id === 6
        ? history(`/recruiter/post-a-job/${job?.job_id}`)
        : history(`/employer/post-a-job/${job?.job_id}`);
    } else if (temp === "b") {
      pauseJob();
    } else if (temp === "c") {
      closeJob();
    } else if (temp === "d") {
      duplicateJob();
    } else if (temp === "e") {
      reactivateJob();
    }
  };

  const getManageData = async () => {
    try {
      // dispatch(setLoading(true));
      const [manage] = await Promise.all([dispatch(getManage(job?.job_id))]);
      setManage(manage?.payload?.statusname);
      // dispatch(setLoading(false));
    } catch (error) {
      // dispatch(setLoading(false));
      dispatch(
        setAlert({
          show: true,
          type: ALERT_TYPE?.ERROR,
          msg: ERROR_MSG,
        })
      );
    }
  };

  const handleClick = (event) => {
    setAnchorEl(event?.currentTarget);
    getManageData();
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickViewJob = () => {
    const url = job?.post_incognito
      ? `/jobs/${job?.country_name?.replace(
          /\s|\//g,
          ""
        )}/${job?.townName?.replace(/\s|\//g, "")}/${job?.title?.replace(
          /\s|\//g,
          ""
        )}/${job?.job_id}`
      : `/jobs/${job?.country_name?.replace(
          /\s|\//g,
          ""
        )}/${job?.townName?.replace(/\s|\//g, "")}/${job?.CompanyName?.replace(
          /\s|\//g,
          ""
        )}/${job?.title?.replace(/\s|\//g, "")}/${job?.job_id}`;
    window.open(url, "_blank");
  };

  const copyToClipboard = async () => {
    try {
      let textToCopy = job?.post_incognito
        ? `${window.location.origin}/jobs/${`${job?.country_name?.replace(
            /\s|\//g,
            ""
          )}`}/${job?.townName?.replace(/\s|\//g, "")}/${job?.title?.replace(
            /\s|\//g,
            ""
          )}/${job?.job_id}`
        : `${window.location.origin}/jobs/${`${job?.country_name?.replace(
            /\s|\//g,
            ""
          )}`}/${job?.townName?.replace(
            /\s|\//g,
            ""
          )}/${job?.CompanyName?.replace(/\s|\//g, "")}/${job?.title?.replace(
            /\s|\//g,
            ""
          )}/${job?.job_id}`;
      await navigator?.clipboard?.writeText(textToCopy);
      dispatch(
        setAlert({
          show: true,
          type: ALERT_TYPE?.SUCCESS,
          msg: "Copied to clipboard",
        })
      );
      handleClose();
    } catch (err) {
      dispatch(
        setAlert({
          show: true,
          type: ALERT_TYPE?.ERROR,
          msg: err,
        })
      );
    }
  };
  return (
    <>
      <Button
        id="customized-button"
        aria-controls={open ? "customized-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        variant="contained"
        elevation={0}
        onClick={handleClick}
        endIcon={
          <Box
            component={"img"}
            src={open ? downArrow : upArrow}
            sx={{
              height: 25,
              width: 25,
            }}
          />
        }
        color="manageButton"
        sx={{
          boxShadow: 0,
          width: "100%",
          height: "60px",
          borderRadius: "0 0 0 25px",
          fontSize: "14px",
          padding: "21px 20px 17px 29px",
          justifyContent: "flex-start",
          "& .MuiButton-endIcon": {
            paddingLeft: "14px",
            margin: "0px !important",
          },
        }}
      >
        <Box
          component="img"
          sx={{
            height: "21px",
            width: "21px",
            paddingRight: "24px",
          }}
          alt="toolIcon"
          src={toolIcon}
        />
        {i18n["manageJob.manage"]}
      </Button>
      <StyledMenu
        id="customized-menu"
        MenuListProps={{
          "aria-labelledby": "customized-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            padding: 0,
            boxShadow: 0,
            width: cardWidth / 2,
          }}
        >
          <HoverBtn
            btnContent={i18n["manageJob.editJob"]}
            clickFunction={() => handleOpen2("a")}
            color={"editJobButton"}
            hoverIcon={editIconHover}
            icon={editIcon}
            height="20px"
            width="20px"
          />
          {manage === "paused" || manage === "closed" ? (
            <HoverBtn
              btnContent={i18n["manageJob.reactivateJob"]}
              clickFunction={() => handleOpen2("e")}
              color={"reactiveJobButton"}
              hoverIcon={reActiveIconHover}
              icon={reActiveIcon}
              height="20px"
              width="20px"
            />
          ) : (
            <HoverBtn
              btnContent={i18n["manageJob.pauseJob"]}
              clickFunction={() => handleOpen2("b")}
              color={"pauseJobButton"}
              hoverIcon={pauseIconHover}
              // icon={pauseIcon}
              height="20px"
              width="20px"
            />
          )}

          <HoverBtn
            btnContent={i18n["manageJob.closeJob"]}
            clickFunction={() => handleOpen2("c")}
            color={"closeJobButton"}
            hoverIcon={closeIconHover}
            icon={closeIcon}
            height="20px"
            width="20px"
          />
          <HoverBtn
            btnContent={i18n["manageJob.duplicateJob"]}
            clickFunction={() => handleOpen2("d")}
            color={"duplicateJobButton"}
            hoverIcon={duplicateIconHover}
            icon={duplicateIcon}
            height="20px"
            width="20px"
            // marginLeft="-4px"
            // marginRight="3px"
          />

          <Modal
            open={open2}
            onClose={handleClose2}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                Are you sure that you want to {temp === "a" && "Edit"}
                {temp === "b" && "Pause"}
                {temp === "c" && "Close"}
                {temp === "d" && "Duplicate"}
                {temp === "e" && "Reactivate"} this job?
              </Typography>
              <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                <Button
                  sx={{
                    height: "38px",
                    mr: 2,
                    borderRadius: "10px",
                    color: "black",
                  }}
                  variant="contained"
                  color="grayButton300"
                  onClick={handleJobStatus}
                >
                  YES
                </Button>
                <Button
                  sx={{
                    height: "38px",
                    mr: 2,
                    borderRadius: "10px",
                  }}
                  onClick={handleClose2}
                  variant="contained"
                  color="redButton100"
                >
                  CANCEL
                </Button>
              </Box>
            </Box>
          </Modal>

          <HoverBtn
            btnContent={"view job"}
            clickFunction={handleClickViewJob}
            color={"viewJobButton"}
            hoverIcon={viewIconHover}
            icon={viewIcon}
            height="18px"
            width="27px"
          />
          <HoverBtn
            btnContent={"copy link"}
            clickFunction={copyToClipboard}
            color={"copyJobButton"}
            hoverIcon={copyIcon}
            icon={copyIcon}
            height="20px"
            width="20px"
          />
        </Box>
      </StyledMenu>
    </>
  );
};

export default ManageButtonMenu;
