import React, { useEffect, useState } from "react";
import {
  changePassword,
  deleteAdmin,
  editAdmin,
  getAdmin,
  getRoleList,
} from "../../../../redux/admin/userAdminSlice";
import { useDispatch } from "react-redux";

import {
  Box,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import IconSection from "../../adminTalent/IconSection";
import InfiniteScroll from "react-infinite-scroll-component";
import { useTheme } from "@emotion/react";
import { ExpandMore } from "@mui/icons-material";
import CommonCreate from "./CommonCreate";
import { setAlert } from "../../../../redux/configSlice";
import { ALERT_TYPE, PASSWORD_REGEX } from "../../../../utils/Constants";
import {
  deepCompare,
  emailRegex,
  validateSchema,
} from "../../../../utils/Common";
import * as Yup from "yup";
import TalentSVGButton from "../../../common/TalentSVGButton";
import editIcon from "../../../../assets/Padding Included/Yellow_Edit.svg";
import deleteIcon from "../../../../assets/Padding Excluded/Black_Trash_Delete_1 - Copy.svg";
import profile from "../../../../assets/user_logo.svg";
import Delete from "../../../candidate/myCam/dialog/Delete";
import ChangePasswordModal from "./ChangePasswordModal";
import LockResetIcon from "@mui/icons-material/LockReset";

const UserCrayonTeam = () => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const [tableList, setTableList] = useState([]);
  const [jobTitleCount, setJobTitleCount] = useState(0);
  const [openAdd, setOpenAdd] = useState(false);
  const [isPasswordChangeModal, setIsPasswordChangeModal] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [selectedPool, setSelectedPool] = useState();
  const [changePasswordData, setChangePasswordData] = useState({});
  const [isEditModal, setIsEditModal] = useState(false);
  const [formData, setFormData] = useState({});
  const [errors, setErrors] = useState({});
  const [selectedRowUserType, setSelectedRowUserType] = useState("");
  const [changePasswordError, setChangePasswordError] = useState({
    change_password: false,
    confirm_password: false,
    errorText: "",
  });
  const [roleList, setRoleList] = useState([]);

  let roles = {
    2: "admin",
    1: "superadmin",
    8: "talent partner",
    9: "team member",
  };
  const getAdminList = async (lastkeyy) => {
    try {
      let response = await dispatch(
        getAdmin({ role_type_id: "", lastKey: lastkeyy })
      );
      if (response?.payload?.status === "success") {
        setTableList(
          response?.payload?.data?.map((item) => ({
            id: item?.user_id,
            firstName: item?.user?.first_name,
            lastName: item?.user?.last_name,
            email: item?.user?.email,
            userId: item?.user_id,
            userType:
              item?.admin === true
                ? "Admin"
                : item?.superadmin === true
                ? "Super Admin"
                : item?.talent_partner === true
                ? "Talent Partner"
                : "",
            calendar_link: item?.calendar_link,
            profile_pic: item?.profile_url,
          }))
        );
      }
    } catch (error) {}
  };

  const handleChangePasswordSubmit = async () => {
    let payload = {
      userid: changePasswordData?.userId,
      password: changePasswordData?.confirm_password,
    };
    let condition = deepCompare(
      changePasswordData?.change_password,
      changePasswordData?.confirm_password
    );
    if (
      condition?.status &&
      PASSWORD_REGEX?.test(changePasswordData?.change_password) &&
      PASSWORD_REGEX?.test(changePasswordData?.change_password)
    ) {
      try {
        let response = await dispatch(changePassword(payload));
        if (response?.payload?.status === "success") {
          setIsPasswordChangeModal(false);
          setChangePasswordError({
            change_password: false,
            confirm_password: false,
            errorText: "",
          });
          dispatch(
            setAlert({
              show: true,
              type: ALERT_TYPE?.SUCCESS,
              msg: "Password updated successfully",
            })
          );
        }
      } catch (error) {
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE?.ERROR,
            msg: "Something went wrong",
          })
        );
      }
    } else {
      if (condition?.status) {
        setChangePasswordError({
          change_password: false,
          confirm_password: false,
          errorText: "",
        });
      } else {
        setChangePasswordError({
          change_password: true,
          confirm_password: true,
          errorText: "",
        });
      }
    }
  };
  const EDIT_ADMIN_SCHEMA = Yup.object().shape({
    first_name: Yup.string().required("First name is required"),
    last_name: Yup.string().required("Last name is required"),
    email: Yup.string()
      .matches(emailRegex, "Invalid email address")
      .required("Email is Required."),
    calendar_link: Yup.string().required("Calendar link is required"),
  });
  const handleChangePasswordOnChange = (e, name) => {
    let updatedData = { ...changePasswordData };
    updatedData[name] = e.target.value;
    setChangePasswordData({ ...updatedData });
    if (!PASSWORD_REGEX?.test(e.target.value)) {
      setChangePasswordError({
        [name]: false,
        errorText:
          e.target.id === "change_password"
            ? "change_password"
            : "confirm_password",
      });
      return;
    } else {
      setChangePasswordError({
        [name]: false,
        errorText: e.target.id === "change_password" ? "" : "",
      });
    }
  };
  const handleFormInput = (event, name) => {
    let updatedData = { ...formData };
    updatedData[name] = event.target.value;
    const updatedError = errors;
    delete updatedError[name];
    setErrors(updatedError);
    setFormData({ ...updatedData });
  };
  const handleCreateSelectOnChange = (ind, newValue, val, index) => {};
  const handleEdit = async () => {
    let payload = {
      first_name: formData?.first_name,
      last_name: formData?.last_name,
      email: formData?.email,
      userid: formData?.userId,
      roleTypeId: formData?.roleType?.value,
      calendar_link: formData?.calendar_link,
      profile_pic: formData?.profile_pic,
    };
    validateSchema(EDIT_ADMIN_SCHEMA, payload)
      .then(() => {
        try {
          dispatch(editAdmin(payload)).then((payload) => {
            if (payload?.payload?.status === "success") {
              setOpenAdd(false);
              getAdminList();
              dispatch(
                setAlert({
                  show: true,
                  type: ALERT_TYPE?.SUCCESS,
                  msg: "Admin edit successfully",
                })
              );
              setIsEditModal(false);
              setFormData({});
              setErrors({});
            } else {
              dispatch(
                setAlert({
                  show: true,
                  type: ALERT_TYPE?.ERROR,
                  msg: "Something went wrong",
                })
              );
            }
          });
        } catch (error) {
          dispatch(
            setAlert({
              show: true,
              type: ALERT_TYPE?.ERROR,
              msg: "Something went wrong",
            })
          );
        }
      })
      .catch((error) => {
        setErrors(error);
      });
  };

  const handleCancel = () => {
    setIsEditModal(false);
    setErrors({});
    setOpenAdd(false);
    setFormData({});
  };
  const handleEditDialog = (data) => {
    setIsEditModal(true);
    setOpenAdd(true);
    let typeId;
    if (data?.userType === "Super Admin") {
      typeId = 1;
    } else if (data?.userType === "Admin") {
      typeId = 2;
    } else if (data?.userType === "Talent Partner") {
      typeId = 8;
    } else {
      typeId = 9;
    }
    setFormData({
      ...data,
      first_name: data?.firstName,
      last_name: data?.lastName,
      email: data?.email,
      userId: data?.userId,
      roleType: { label: roles[typeId], value: typeId },
    });
    setSelectedRowUserType(typeId);
  };

  const handleDeleteDialog = (pool) => {
    setConfirmDelete(false);
    setSelectedPool(pool?.userId);
    setIsDelete(true);
  };
  const handlePasswordModal = (data) => {
    setIsPasswordChangeModal(true);
    setChangePasswordData({ userId: data?.userId });
  };
  const handleDel = async () => {
    try {
      const response = await dispatch(deleteAdmin(selectedPool));
      if (response?.payload?.data) {
        getAdminList();
        setIsDelete(false);
      }
    } catch (error) {
      dispatch(
        setAlert({
          show: true,
          type: ALERT_TYPE?.ERROR,
          msg: "Something went wrong",
        })
      );
    }
  };

  const handleModalClose = () => {
    setIsPasswordChangeModal(false);
    setChangePasswordError({
      change_password: false,
      confirm_password: false,
      errorText: "",
    });
  };

  const getCrayonRoles = async () => {
    try {
      let response = await dispatch(getRoleList());
      if (response?.payload?.status === "success") {
        setRoleList(response?.payload?.data);
      }
    } catch (error) {
      console.log(error, "error");
    }
  };

  useEffect(() => {
    getCrayonRoles();
  }, [openAdd]);

  useEffect(() => {
    getAdminList();
  }, []);
  return (
    <>
      {openAdd ? (
        <CommonCreate
          errors={errors}
          formData={formData}
          handleFormInput={handleFormInput}
          handleCancel={handleCancel}
          handleCreate={handleEdit}
          pageTitle={"Super Admin"}
          edit={isEditModal}
          handleSelectOnChange={handleCreateSelectOnChange}
          handleCreateSelectOnChange={handleCreateSelectOnChange}
          roleList={roleList}
          setFormData={setFormData}
        />
      ) : (
        <Box sx={{ ml: "16px" }}>
          <Typography
            sx={{
              fontSize: "36px",
              fontWeight: 700,
              textAlign: "center",
            }}
          >
            Crayon Team
          </Typography>
          <Grid
            container
            spacing={2}
            flexDirection={"column"}
            sx={{
              display: { xs: "none", md: "flex" },
              marginTop: "30px",
              background: "white",
              borderRadius: "25px",
            }}
            boxShadow={1}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                gap: "10px",
              }}
            >
              <Box sx={{ width: 160 }}></Box>
              <Box mt={2}>
                <IconSection />
              </Box>
              <Box sx={{}}></Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                paddingX: 2,
                paddingY: 1,
                borderBottom: 2,
                borderColor: theme.palette.grayBorder,
              }}
            >
              <Box>
                <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                  <Typography
                    sx={{
                      fontWeight: 700,
                    }}
                  ></Typography>
                </Box>
              </Box>
            </Box>
            <InfiniteScroll
              style={{ overflow: "visible" }}
              dataLength={tableList?.length}
              // next={() => getTitles(lastKey, searchTitle)}
              scrollThreshold={"10px"}
              scrollableTarget={"jobList"}
              hasMore={tableList?.length < jobTitleCount ? true : false}
            >
              <Box
                sx={{
                  mt: 2,
                }}
              >
                <TableContainer
                  component={Box}
                  sx={{
                    overflowX: "unset",
                  }}
                >
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell sx={{ width: "55px" }}></TableCell>
                        <TableCell>
                          <Typography
                            variant="subtitle1"
                            fontWeight="bold"
                            style={{
                              display: "flex",
                              alignItems: "center",
                              // justifyContent: "center",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <span>First Name</span>
                              <span>
                                <ExpandMore
                                  sx={{
                                    width: "20px",
                                    height: "20px",
                                    marginTop: "5px",
                                  }}
                                />
                              </span>
                            </div>
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography
                            variant="subtitle1"
                            fontWeight="bold"
                            style={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <span>Last Name</span>
                              <span>
                                <ExpandMore
                                  sx={{
                                    width: "20px",
                                    height: "20px",
                                    marginTop: "5px",
                                  }}
                                />
                              </span>
                            </div>
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography
                            variant="subtitle1"
                            fontWeight="bold"
                            style={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <span>User Type</span>{" "}
                              <span>
                                <ExpandMore
                                  sx={{
                                    width: "20px",
                                    height: "20px",
                                    marginTop: "5px",
                                  }}
                                />
                              </span>
                            </div>
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography
                            variant="subtitle1"
                            fontWeight="bold"
                            style={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <span>Email</span>{" "}
                              <span>
                                <ExpandMore
                                  sx={{
                                    width: "20px",
                                    height: "20px",
                                    marginTop: "5px",
                                  }}
                                />
                              </span>
                            </div>
                          </Typography>
                        </TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {tableList?.map((row, index) => {
                        return (
                          <TableRow
                            key={row?.id}
                            style={{
                              background: `${index % 2 === 0 ? "#F2F4FB" : ""}`,
                              "& .css-12zgwp-MuiTableCell-root": {
                                padding: "0 16px !important",
                              },
                            }}
                          >
                            <TableCell
                              sx={{
                                width: "46px",
                                textAlign: "center",
                                padding: "0px !important",
                              }}
                            >
                              {row?.profile_pic !== "NO URL" ? (
                                <Box
                                  component="img"
                                  className="profileAvatar"
                                  alt="logo"
                                  src={row?.profile_pic}
                                  sx={{
                                    height: "46px !important",
                                    width: "46px !important",
                                    borderRadius: "10px",
                                  }}
                                />
                              ) : (
                                <Box
                                  component={"img"}
                                  className="profileAvatar"
                                  src={profile}
                                  sx={{
                                    height: "46px !important",
                                    width: "46px !important",
                                    borderRadius: "10px",
                                  }}
                                />
                              )}
                            </TableCell>
                            <TableCell>{row?.firstName}</TableCell>
                            <TableCell>{row?.lastName}</TableCell>
                            <TableCell>{row?.userType}</TableCell>
                            <TableCell>{row?.email}</TableCell>
                            <TableCell
                              sx={{
                                display: "flex",
                              }}
                            >
                              <TalentSVGButton
                                color={"white"}
                                source={editIcon}
                                height={34}
                                width={35}
                                padding={0}
                                onClick={() => handleEditDialog(row)}
                              />
                              <TalentSVGButton
                                color={"white"}
                                source={deleteIcon}
                                height={24}
                                width={18}
                                padding={0}
                                onClick={() => handleDeleteDialog(row)}
                              />
                              {/* <TalentSVGButton
                          color={"white"}
                          source={<LockResetIcon/>}
                          width= "30px"
                          height= "30px"
                          padding={0}
                          onClick={() => handeResetPassword(row)}
                        /> */}
                              {/* <Box  > */}
                              <LockResetIcon
                                sx={{
                                  width: "30px",
                                  height: "30px",
                                  // marginTop: "5px",
                                }}
                                onClick={() => handlePasswordModal(row)}
                              />
                              {/* </Box> */}
                            </TableCell>
                            {/* <TableCell
                      sx={{
                        display: "flex",
                      }}
                    >
                      <TalentSVGButton
                        color={"white"}
                        source={editIcon}
                        height={34}
                        width={35}
                        padding={0}
                        onClick={() => handleEditDialog(row)}
                      />
                      <TalentSVGButton
                        color={"white"}
                        source={deleteIcon}
                        height={24}
                        width={18}
                        padding={0}
                        onClick={() => handleDeleteDialog(row)}
                      />
                    </TableCell> */}
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </InfiniteScroll>
          </Grid>
        </Box>
      )}
      {isDelete && (
        <Delete
          show={isDelete}
          handleOpen={() => setIsDelete(false)}
          handleDelete={handleDel}
          dialogText={"admin"}
          handleCancel={() => setIsDelete(false)}
          confirmDelete={confirmDelete}
          setConfirmDelete={setConfirmDelete}
        />
      )}
      {isPasswordChangeModal && (
        <ChangePasswordModal
          errors={changePasswordError}
          formData={changePasswordData}
          handleFormInput={handleChangePasswordOnChange}
          handleCancel={handleModalClose}
          handleSubmit={handleChangePasswordSubmit}
          handleOpen={isPasswordChangeModal}
          handleClose={handleModalClose}
        />
      )}
    </>
  );
};

export default UserCrayonTeam;
