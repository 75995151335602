import React from "react";
import { Box, IconButton, Typography, useTheme } from "@mui/material";

export default function VideoApp({ handleClose, jobContent }) {
  const theme = useTheme();

  return (
    <Box
      sx={{
        padding: "10px !important",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography
          sx={{
            fontWeight: theme.typography.Bold,
            fontSize: "12px",
          }}
        >
          The brief:
        </Typography>
        <IconButton
          aria-label="close"
          onClick={() => {
            handleClose();
          }}
          sx={{
            position: "absolute",
            top: 0,
            right: "5px",
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Box
            sx={{
              width: "5px",
              height: "5px",
              color: "#000000",
              fontSize: "10px",
            }}
          >
            X
          </Box>
        </IconButton>
      </Box>
      <Typography
        sx={{ fontSize: "12px", fontWeight: theme.typography.Regular }}
      >
        {jobContent?.video_description}
      </Typography>
    </Box>
  );
}
