import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  InputBase,
  Paper,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import locale from "../../i18n/locale";
import { ALERT_TYPE, GET_COINS } from "../../utils/Constants";
import InfiniteScroll from "react-infinite-scroll-component";
import { Navigate, useNavigate } from "react-router-dom";
import CoinSpendBox from "./CoinSpendBox";
import { useDispatch } from "react-redux";
import {
  claimVoucher,
  getTopUpPlans,
  purchaseRequest,
} from "../../redux/employer/empProfileSlice";
import { setAlert } from "../../redux/configSlice";
import {
  getNotificationCount,
  setCoinsCount,
  setNotificationCount,
} from "../../redux/candidate/alerts";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import { useSelector } from "react-redux";
import CloseIcon from "./CloseIcon";

export default function GetCoins({
  width,
  rightExpand,
  handleClose,
  setOpenNotication,
  coinsRef,
  setOpenGetCoins,
  setConfirm,
}) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const i18n = locale.en;
  const navigate = useNavigate();

  const { company_id } = useSelector((state) => state?.configalert);

  const [showPurchase, setShowPurchase] = useState(false);
  const [purchaseHitory, setPurchaseHistory] = useState();
  const [lastKey, setLastKey] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [voucherName, setVoucherName] = useState("");
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);
  const [topUpPlans, setTopUpPlans] = useState([]);
  const [lastKeyTopUp, setLastKeyTopUp] = useState(0);

  const handleVoucher = (event) => {
    const { value } = event?.target;
    const trimmedValue = value.trim();
    setError("");
    setVoucherName(trimmedValue);
  };

  const getCount = async () => {
    try {
      const { payload } = await dispatch(getNotificationCount());
      if (payload?.status === "success") {
        dispatch(setNotificationCount(payload?.count));
        dispatch(setCoinsCount(payload?.coinsCounter));
      }
    } catch (error) {}
  };

  const handleClaim = async () => {
    setIsLoading(true);
    try {
      let data = {
        name: voucherName,
      };
      const { payload } = await dispatch(claimVoucher(data));
      if (payload?.status === "success") {
        setIsLoading(false);
        if (payload?.data?.status === "fail") {
          // dispatch(
          //   setAlert({
          //     show: true,
          //     type: ALERT_TYPE?.ERROR,
          //     msg: payload?.data?.message,
          //   })
          // );
          setError(payload?.data?.message);
        } else {
          dispatch(
            setAlert({
              show: true,
              type: ALERT_TYPE?.SUCCESS,
              msg: "Voucher claimed successfully",
            })
          );
          getCount();
          handleClose();
        }
      } else {
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE?.ERROR,
            msg: payload?.message,
          })
        );
      }
    } catch (error) {}
  };

  const getTopUp = async (lastKeyy) => {
    try {
      const { payload } = await dispatch(getTopUpPlans());
      if (payload?.status === "success") {
        setTopUpPlans(payload?.data);
        setLastKeyTopUp(payload?.pageNumber);
      } else {
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE?.ERROR,
            msg: payload?.message,
          })
        );
      }
    } catch (error) {
      dispatch(
        setAlert({
          show: true,
          type: ALERT_TYPE?.ERROR,
          msg: error,
        })
      );
    }
  };

  const handlePurchase = async () => {
    try {
      let data = {
        cost: purchaseHitory?.cost,
        coins: purchaseHitory?.coins,
        currency_id: purchaseHitory?.currency_id,
        company_id: company_id,
        transaction_name_id: purchaseHitory?.transaction_name_id,
      };
      const { payload } = await dispatch(purchaseRequest(data));
      if (payload?.status === "success") {
        window.open(`/payment/${payload?.payment_transaction_id}`, "_blank");
        // setConfirm(true);
        handleClose();
      } else {
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE?.ERROR,
            msg: payload?.message,
          })
        );
      }
    } catch (error) {
      dispatch(
        setAlert({
          show: true,
          type: ALERT_TYPE?.ERROR,
          msg: error,
        })
      );
    }
  };

  useEffect(() => {
    getTopUp();
  }, []);

  return (
    <>
      <Box
        ref={coinsRef}
        sx={{
          display: "flex",
          flexDirection: "column",
          // justifyContent: "space-between",
          // width: { xs: "360px", mddl: `${width}px` },
          width: { xs: "360px", mddl: "360px" },
          minHeight: "477px",
          background: "#ffff",
          borderRadius: "0 0 25px 25px",
          boxShadow: "0px 5px 10px #00000029 !important",
          zIndex: 999,
          position: "absolute",
          // padding: "0px 0px 0 20px",
          right: 0,
          boxSizing: "border-box",
          // marginRight: !rightExpand && "119px",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Button
            variant="contained"
            color="getCoins"
            sx={{
              width: "175px",
              height: "45px",
              borderRadius: "0px 0px 25px 0px",
              fontSize: "14px",
              fontWeight: theme.typography.Bold,
              textAlign: "left",
              justifyContent: "center",
            }}
          >
            {i18n["myplan.crayoncoins"]}
          </Button>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              // width: { xs: "135px", mddl: `calc(${width}px - 215px)` },
              width: { xs: "135px", mddl: `calc(360px - 215px)` },
            }}
          >
            <CloseIcon onClick={() => handleClose()} />
            {/* <IconButton
              aria-label="close"
              onClick={() => handleClose()}
              sx={{
                position: "absolute",
                right: 8,
                color: "#000000",
                //   color: (theme) => theme.palette.grey[500],
              }}
              disableRipple={true}
            >
              <Box
                sx={{
                  width: "20px",
                  height: "20px",
                  color: "#000000",
                  border: 2,
                  fontSize: "18px",
                  borderRadius: "5px",
                }}
              >
                X
              </Box>
            </IconButton> */}
          </Box>
        </Box>
        <Box
          sx={{
            width: { xs: "93%", mddl: "100%" },
            margin: "18px 0 26px 23px",
          }}
        >
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: theme.typography.Bold,
              width: "91%",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            {i18n["myplanPopup.cointopup"]}
          </Typography>
          <Box
            sx={{
              minHeight: "144px",
              display: "flex",
              flexDirection: "column",
              gap: "15px",
              width: "91%",
              mt: "10px",
              "::-webkit-scrollbar": {
                height: "5px",
                background: "#DCDEEB",
                display: "unset !important",
              },
            }}
            id="matchTaleneList"
          >
            {topUpPlans?.length > 0 ? (
              topUpPlans?.map((item) => {
                return (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                    onClick={() => {
                      // setShowPurchase(true);
                      setPurchaseHistory(item);
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontWeight: theme.typography.Regular,
                      }}
                    >
                      {item?.coins} coins{" "}
                      {item?.discount > 0 && `(${item?.discount}% discount)`}
                    </Typography>

                    <Button
                      variant="contained"
                      color="submitButton"
                      sx={{
                        minWidth: "50px !important",
                        height: "25px",
                        borderRadius: "5px",
                        padding: "4px 2px !important",
                        fontSize: "12px",
                        fontWeight: theme.typography.Bold,
                        color: "black",
                      }}
                    >
                      R{item?.cost}
                    </Button>
                  </Box>
                );
              })
            ) : (
              <Box
                style={{
                  margin: "15px 0 0 0",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CircularProgress color="inherit" size={20} />
              </Box>
            )}
          </Box>
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: theme.typography.Regular,
              width: `calc(100% - 100px)`,
              mt: "15px",
            }}
          >
            {i18n["myplanPopup.cannotberefunded"]}
          </Typography>

          <Box
            sx={{
              mt: "10px",
              display: "flex",
              flexDirection: "column",
              gap: "5px",
            }}
          >
            <Typography
              sx={{ fontSize: "14px", fontWeight: theme.typography.Bold }}
            >
              {i18n["myplanPopup.claimavoucher"]}
            </Typography>

            <Paper
              sx={{
                display: "flex",
                justifyContent: "space-between",
                height: "40px",
                borderRadius: "10px",
                boxShadow: "none",
                width: "90%",
              }}
            >
              <Paper
                elevation={0}
                component="form"
                sx={{
                  display: "flex",
                  borderRadius: "10px 0 0 10px",
                  alignItems: "center",
                  border: "1px solid #E0E0E0",
                  width: "100%",
                  // width: 1,
                }}
              >
                <InputBase
                  sx={{
                    ml: 2,
                    width: 1,
                  }}
                  value={voucherName}
                  onChange={(event) => handleVoucher(event)}
                  // onKeyDown={() => voucherName !== "" && handleClaim()}
                  onKeyDown={(event) => {
                    if (event.key === "Enter") {
                      event.preventDefault(); // Stop page reload
                      handleClaim();
                    }
                  }}
                  placeholder={i18n["myplanPopup.thevouchercode"]}
                  //   type={inputType}
                />
                {isLoading && (
                  <Box
                    style={{
                      mr: "2px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "100%",
                    }}
                  >
                    <CircularProgress color="inherit" size={20} />
                  </Box>
                )}
                {error !== "" && (
                  <Typography
                    sx={{
                      fontSize: "14px",
                      fontWeight: theme.typography.Regular,
                      width: `calc(100% - 100px)`,
                      textAlign: "end",
                      mr: "2px",
                      color: "red",
                    }}
                  >
                    {error}
                  </Typography>
                )}
              </Paper>
              <Button
                sx={{
                  minWidth: "fit-content",
                  boxShadow: 0,
                  height: "40px",
                  width: "100px",
                  borderRadius: "0 10px 10px 0",
                }}
                variant="contained"
                color="getCoins"
                onClick={() => voucherName !== "" && handleClaim()}
              >
                {i18n["myplanPopup.claim"]}
              </Button>
            </Paper>
          </Box>

          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: theme.typography.Regular,
              width: `calc(100% - 100px)`,
              mt: "10px",
              mb: "15px",
            }}
          >
            {i18n["myplanPopup.coinsmayexpire"]}
          </Typography>
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: theme.typography.Regular,
              width: `calc(100% - 100px)`,
            }}
          >
            {i18n["myplanPopup.youcanalso"]}
          </Typography>

          {purchaseHitory && (
            <>
              <Typography
                sx={{
                  fontSize: "14px",
                  fontWeight: theme.typography.Bold,
                  mt: "15px",
                  mb: "10px",
                }}
              >
                {i18n["myplanPopup.purchaseSummary"]}
              </Typography>
              <Box
                sx={{
                  width: "91%",
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                  // onClick={() => {
                  //   setShowPurchase(true);
                  //   setPurchaseHistory((prev) => [...prev, item]);
                  // }}
                >
                  <Typography
                    sx={{
                      fontSize: "14px",
                      fontWeight: theme.typography.Regular,
                    }}
                  >
                    {purchaseHitory?.coins} coins{" "}
                    {purchaseHitory?.discount > 0 &&
                      `(${purchaseHitory?.discount}% discount)`}
                  </Typography>
                  <Box sx={{ display: "flex", gap: "4px" }}>
                    <Button
                      variant="contained"
                      color="submitButton"
                      sx={{
                        minWidth: "50px !important",
                        height: "25px",
                        borderRadius: "5px",
                        padding: "4px 2px !important",
                        fontSize: "12px",
                        fontWeight: theme.typography.Bold,
                        color: "black",
                      }}
                    >
                      R{purchaseHitory?.cost}
                    </Button>
                  </Box>
                </Box>
              </Box>
            </>
          )}
        </Box>
        {purchaseHitory && (
          <Button
            variant="contained"
            color="getCoins"
            sx={{
              width: "100%",
              height: "45px",
              borderRadius: "0 0 25px 25px",
            }}
            // onClick={() => setConfirm(true)}
            onClick={() => handlePurchase()}
          >
            {i18n["myplanPopup.confirmpurchase"]}
          </Button>
        )}
      </Box>
    </>
  );
}
