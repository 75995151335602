import { useEffect, useState, useRef } from "react";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, ContentState, convertFromHTML } from "draft-js";
import { convertToHTML } from "draft-convert";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useTheme } from "@emotion/react";
import { Button } from "@mui/material";

const customStyle = `
    .public-DraftStyleDefault-block {
      margin: 0;
    }
  `;

const toolBarOptions = {
  options: [
    "inline",
    "link",
    "colorPicker",
    "textAlign",
    "list",
    // "remove",
    // "history",
  ],
  inline: {
    options: ["italic", "bold", "underline", "strikethrough"],
  },
  list: {
    options: ["unordered", "ordered"],
  },
  textAlign: {
    options: ["left", "center", "right", "justify"],
  },
};

const getRGBValues = (text) => {
  const rgbValues = text.match(/\((.*?)\)/)[1].split(",");
  const color = `rgb(${rgbValues[0]}, ${rgbValues[1]}, ${rgbValues[2]})`;
  return color;
};
const styleToHTML = (style) => {
  if (style === "BOLD") {
    return <strong />;
  }
  if (style === "ITALIC") {
    return <em />;
  }
  if (style === "UNDERLINE") {
    return <u />;
  }
  if (style === "STRIKETHROUGH") {
    return <del />;
  }
  if (style?.includes("bgcolor-rgb")) {
    let bgcolor = getRGBValues(style);
    return <span style={{ background: bgcolor }} />;
  }
  if (style?.includes("color-rgb")) {
    let color = getRGBValues(style);
    return <span style={{ color }} />;
  }
  return undefined;
};

const blockToHTML = (block) => {
  const textAlign = block?.data && block?.data["text-align"];

  const TagName =
    block?.type === "unordered-list-item"
      ? "ul"
      : block?.type === "ordered-list-item"
      ? "ol"
      : "p";
  if (textAlign) {
    if (block?.type === "unstyled") {
      return <p style={{ textAlign }} />;
    }
    return (
      <TagName>
        <li style={{ textAlign }}>{block?.text}</li>
      </TagName>
    );
  }
};

const entityToHTML = (entity, originalText) => {
  if (entity?.type === "LINK") {
    return (
      <a
        href={entity?.data["url"]}
        target={entity?.data["targetOption"]}
        rel="noopener noreferrer"
      >
        {originalText}
      </a>
    );
  }

  return originalText;
};
const options = {
  blockToHTML,
  styleToHTML,
  entityToHTML,
};

export default function TextEditor({
  value,
  editorIndex,
  type,
  title,
  onInputCHange,
  placeholder = "Write here...",
  minHeight = "133px",
  width,
  padding = "16px",
  border = 0,
  borderRadius = "25px",
  disabled,
  isButton,
  handleEditBtn,
  paddingRight
}) {
  const StyledPaper = styled(Paper)(({ theme }) => ({
    borderRadius: borderRadius,
    boxShadow: "none",
    minHeight: minHeight,
    width: width,
    padding: padding,
    position: "relative",
    border: border,
    ".toolbar-class": {
      border: 0,
      marginLeft: "-10px",
      ".rdw-option-wrapper": {
        border: 0,
      },
      ".rdw-dropdown-wrapper": {
        border: 0,
      },
      // ".rdw-editor-toolbar": {
      //   border: "0px solid #F1F1F1 !important",
      // },
    },
  }));

  const editorRef = useRef(null);
  const theme = useTheme();
  const [editorState, setEditorState] = useState(() => {
    if (value) {
      const blocksFromHTML = convertFromHTML(value);
      const content = ContentState?.createFromBlockArray(
        blocksFromHTML?.contentBlocks,
        blocksFromHTML?.entityMap
      );
      return EditorState?.createWithContent(content);
    }
    return EditorState?.createEmpty();
  });
  const [convertedContent, setConvertedContent] = useState("");

  const onEditorStateChange = (editorState) => {
    setEditorState(editorState);
    const html = convertToHTML(options)(editorState?.getCurrentContent());
    setConvertedContent(html);
    onInputCHange(html, type, editorIndex);
  };

  const handleKeyCommand = (command, editorState) => {
    if (command === "backspace") {
      const selection = editorState?.getSelection();
      const contentState = editorState?.getCurrentContent();
      const block = contentState?.getBlockForKey(selection?.getStartKey());

      if (
        block?.getType() === "unordered-list-item" &&
        selection?.getStartOffset() === 0
      ) {
        // If backspace is pressed at the start of an unordered-list-item, modify the content
        const newContentState = contentState?.merge({
          blockMap: contentState
            ?.getBlockMap()
            ?.map((contentBlock) =>
              contentBlock?.getKey() === block?.getKey()
                ? contentBlock?.merge({ type: "unstyled", text: "" })
                : contentBlock
            ),
        });

        const newEditorState = EditorState?.push(
          editorState,
          newContentState,
          "change-block-type"
        );
        onEditorStateChange(newEditorState);
        return "handled";
      }
    }

    return "not-handled";
  };

  useEffect(() => {
    // This effect will update the initial value on component mount
    if ((value && !convertedContent) || value !== convertedContent) {
      const blocksFromHTML = convertFromHTML(
        value === null || value === undefined ? "" : value
      );
      const content = ContentState?.createFromBlockArray(
        blocksFromHTML?.contentBlocks,
        blocksFromHTML?.entityMap
      );
      setEditorState(EditorState?.createWithContent(content));
      setConvertedContent(value);
    }
  }, [value, convertedContent]);

  return (
    <StyledPaper>
      <Typography
        sx={{
          fontSize: "12px",
          fontWeight: theme.typography.Regular,
        }}
      >
        {title}
      </Typography>
      <style>{customStyle}</style>
      <Editor
        readOnly={disabled}
        // editorState={editorState}
        toolbarStyle={{
          border: 0,
          // padding:"1px"
        }}
        editorState={editorState}
        onEditorStateChange={onEditorStateChange}
        placeholder={placeholder}
        toolbar={toolBarOptions}
        ref={editorRef}
        handleKeyCommand={handleKeyCommand}
        toolbarClassName={{ backgroundColor: "red" }}
        editorStyle={{ paddingRight:paddingRight, fontSize: "14px",}}
        // toolbarClassName="toolbar-class"
      />
      {isButton && 
      <Button
      variant="contained"
      color="nandos"
      sx={{
        borderRadius: "0 10px 0 10px",
        width: "150px",
        height: "45px",
        position: "absolute",
        zIndex:100,
        right: "0px",
        top: "0px",
        fontSize: "12px",
        fontWeight: theme.typography.Bold,
      }}
      onClick={handleEditBtn}
    >
      edit
    </Button>
      }
      
    </StyledPaper>
  );
}
