import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getApi } from "../../utils/Apis";
import { setLoading } from "../configSlice";
const initialState = {
  candidateCVData: [],
};
export const getCVBasics = createAsyncThunk(
  "getCVBasics",
  async (payload, { dispatch }) => {
    dispatch(setLoading(true));
    const { data } = await getApi(`/cv/getbasics`, true);
    dispatch(setLoading(false));
    return data;
  }
);

export const getCVWorkLife = createAsyncThunk(
  "getCVWorkLife",
  async (payload, { dispatch }) => {
    dispatch(setLoading(true));
    const { data } = await getApi(`/cv/getworklife`, true);
    dispatch(setLoading(false));
    return data;
  }
);

export const getCVStudyLife = createAsyncThunk(
  "getCVStudyLife",
  async (payload, { dispatch }) => {
    dispatch(setLoading(true));
    const { data } = await getApi(`/cv/getstudylife`, true);
    dispatch(setLoading(false));
    return data;
  }
);

export const myCVNew = createSlice({
  name: "configmycvnew",
  initialState,
  reducers: {},
  //
});
// Action creators are generated for each case reducer function

export default myCVNew.reducer;
