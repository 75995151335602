import { useTheme } from "@emotion/react";
import { Box, Button, InputLabel, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import ScoreItemsModal from "./ScoreItemsModal";
import { useDispatch } from "react-redux";
import {
  editQuestion,
  getAssessmentList,
  getAssessmentScore,
} from "../../../../redux/candidate/myStatusFilter";
import TalentSVGButton from "../../../common/TalentSVGButton";
import editIcon from "../../../../assets/Padding Included/Yellow_Edit.svg";
import { setLoading } from "../../../../redux/configSlice";
import EditAssessmentQuestionModal from "./EditAssessmentQuestionModal";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { exportAssessment } from "../../../../redux/employer/myJobsSlice";

const AdminAssessment = () => {
  const theme = useTheme();
  const [selectedScoreItem, setSelectedScoreItem] = useState({});
  const [isEdit, setIsEdit] = useState(false);
  const dispatch = useDispatch();
  const [viewMoreExpanded, setViewMoreExpanded] = useState(false);
  const [assessmentList, setAssessmentList] = useState();
  const [isAssessment, setIsAssessment] = useState(false);
  const [selectedQuestion, setSelectedQuestion] = useState(false);
  const [scoreList, setScoreList] = useState();
  const [questFormInputValue, setQuestFormInputValue] = useState();
  const [error, setError] = useState(false);
  const [formDate, setFormDate] = useState({ from: "", to: "" });
  const getList = async () => {
    dispatch(setLoading({ value: true, apiName: "getList" }));
    let payload = {
      lastKey: 0,
      limit: 100,
    };
    try {
      let response = await dispatch(getAssessmentList(payload));
      if (response?.payload?.data?.status === "success") {
        setAssessmentList(response?.payload?.data?.data?.questions);
      }
      dispatch(setLoading({ value: false, apiName: "getList" }));
    } catch (error) {
      dispatch(setLoading({ value: false, apiName: "getList" }));
    }
  };
  const getScore = async () => {
    dispatch(setLoading({ value: true, apiName: "getScore" }));

    try {
      let response = await dispatch(getAssessmentScore());
      if (response?.payload?.data?.status === "success") {
        setScoreList(response?.payload?.data?.data);
      }
      dispatch(setLoading({ value: false, apiName: "getScore" }));
    } catch (error) {
      dispatch(setLoading({ value: false, apiName: "getScore" }));
    }
  };
  const editQuestionAssessment = async () => {
    dispatch(setLoading({ value: true, apiName: "editQuestionAssessment" }));

    let payload = {
      question_id: selectedQuestion?.question_id,
      question: questFormInputValue,
    };
    try {
      let response = await dispatch(editQuestion(payload));
      if (response?.payload?.data?.status === "success") {
        setIsAssessment(false);
        getList();
      }
      dispatch(setLoading({ value: false, apiName: "editQuestionAssessment" }));
    } catch (error) {
      console.log(error, "error");
      dispatch(setLoading({ value: false, apiName: "editQuestionAssessment" }));
    }
  };

  const handleCloseAssessmentModal = () => {
    setIsAssessment(false);
  };

  const handleQuestionInput = (e) => {
    setQuestFormInputValue(e?.target?.value);
  };
  const handleDateChange = (newValue, key) => {
    let formatedDate =
      dayjs(newValue)?.format("YYYY-MM-DD") === "Invalid Date"
        ? ""
        : dayjs(newValue)?.format("YYYY-MM-DD");
    const newJobDetailData = {
      ...formDate,
      [key]: formatedDate,
    };
    setFormDate(newJobDetailData);
  };
  const convertToNoonISODate=(dateString)=> {
    const date = new Date(dateString);
    date.setUTCHours(12, 0, 0, 0); // Set time to 12:00:00.000 UTC
    return date.toISOString();
  }
  function convertToCustomFormat(dateString) {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = 20;
    const minutes = 15;
    const seconds = String(date.getSeconds()).padStart(2, '0');
    const milliseconds = String(date.getMilliseconds()).padStart(3, '0');
    return `${year}-${month}-${day}T${hours}${minutes}:${seconds}:${milliseconds}`;
  }
  const exportFile = async () => {
    let payload ={
      start_date:formDate?.from,
      end_date: formDate?.to
    }
  
    try {
      let response = await dispatch(exportAssessment(payload));
      if (response?.payload) {
        const fileURL = window.URL.createObjectURL(
          new Blob([response?.payload])
        );
        const fileLink = document.createElement("a");
        fileLink.href = fileURL;
        fileLink.setAttribute("download", "Crayon Personality Assessment.csv");
        document.body.appendChild(fileLink);
        fileLink.click();
      }
    } catch (error) {
      console.log(error, "error");
    }
  };

  const handleExport = () => {
    exportFile();
  };

  useEffect(() => {
    getList();
    getScore();
  }, []);

  useEffect(() => {
    if (selectedQuestion?.question) {
      setQuestFormInputValue(selectedQuestion?.question);
    }
  }, [selectedQuestion]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        marginTop: "55px",
      }}
    >
      <Box
        sx={{
          background: "white",
          borderRadius: "25px",
          //   mt: "21px",
          boxShadow: `0px 5px 10px ${theme?.palette?.boxShadow?.main}`,
          width: "65%",
          marginRight: "27px",
        }}
      >
        <Box>
          <Box sx={{ display: "flex" }}>
            <Button
              variant="contained"
              color="redButton"
              sx={{
                width: "150px",
                height: "45px",
                borderRadius: "25px 0 25px 0",
                padding: "0px",
              }}
            >
              question
            </Button>
          </Box>
        </Box>
        <Box sx={{ padding: "25px" }}>
          {assessmentList?.map((item, index) => {
            return (
              <Box sx={{ width: "90%", marginBottom: "10px" }}>
                <Box>
                  <Typography
                    sx={{
                      fontWeight: theme?.typography?.Regular,
                      fontSize: "14px",
                      marginBottom: "10px",
                    }}
                  >
                    {item?.question_id}
                    {". "}
                    {item?.question}
                    <span>
                      <TalentSVGButton
                        onClick={() => {
                          setIsAssessment(true);
                          setSelectedQuestion(item);
                        }}
                        color={"white"}
                        source={editIcon}
                        height={26}
                        width={26}
                        padding={"0px !important"}
                        startIconMargin={"0px !important"}
                      />
                    </span>
                  </Typography>
                </Box>
              </Box>
            );
          })}
        </Box>
      </Box>
      <Box
        sx={{
          width: "30%",
        }}
      >
        <ScoreItemsModal
          spendList={scoreList}
          setIsEdit={setIsEdit}
          viewMoreExpanded={viewMoreExpanded}
          setViewMoreExpanded={setViewMoreExpanded}
          getScore={getScore}
        />
        <Box
          sx={{
            background: "white",
            borderRadius: "25px",
            mt: "21px",
            width: "400px",
            paddingBottom: "20px",
            boxShadow: `0px 5px 10px ${theme?.palette?.boxShadow?.main}`,
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "20px",
            }}
          >
            <Button
              variant="contained"
              color="redButton"
              onClick={handleExport}
              sx={{
                height: "45px",
                width: "150px",
                borderRadius: "25px 0 25px 0",
              }}
            >
              Export csv
            </Button>
          </Box>
          {/* <Button onClick={handleExport}>Export</Button> */}
          <Box sx={{ marginBottom: "20px", marginX: "20px" }}>
            <InputLabel
              htmlFor="work_setup"
              sx={{
                color: "black",
                paddingBottom: "5px",
                fontSize: "14px",
                fontWeight: theme.typography.Bold,
              }}
            >
              from
            </InputLabel>
            <LocalizationProvider dateAdapter={AdapterDayjs} locale="en-gb">
              <DatePicker
                name="from"
                value={formDate?.from}
                maxDate={new Date()}
                onChange={(newValue) => {
                  handleDateChange(newValue, "from");
                }}
                // inputFormat="DD-MM-YY"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    value={formDate?.from}
                    // inputProps={{
                    //   placeholder: "DD-MM-YY",
                    // }}
                    sx={{
                      width: "100%",
                      "& .MuiInputBase-root": {
                        height: "40px",
                        width: "100%",
                        borderRadius: "10px",
                        border: "1px solid #E0E0E0",
                        ":hover": "1px solid #E0E0E0",
                      },
                      "& .MuiIconButton-root": {
                        color: theme.palette.yellowColor, // Change this to the desired color
                      },
                      "& fieldset": {
                        border: "none",
                      },
                    }}
                  />
                )}
              />
            </LocalizationProvider>
          </Box>
          <Box sx={{ marginBottom: "20px", marginX: "20px" }}>
            <InputLabel
              htmlFor="work_setup"
              sx={{
                color: "black",
                paddingBottom: "5px",
                fontSize: "14px",
                fontWeight: theme.typography.Bold,
              }}
            >
              to
            </InputLabel>
            <LocalizationProvider dateAdapter={AdapterDayjs} locale="en-gb">
              <DatePicker
                name="to"
                value={formDate?.to}
                maxDate={new Date()}
                minDate={formDate?.from}
                onChange={(newValue) => {
                  handleDateChange(newValue, "to");
                }}
                // inputFormat="DD-MM-YY"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    value={formDate?.to}
                    // inputProps={{
                    //   placeholder: "DD-MM-YY",
                    // }}
                    sx={{
                      width: "100%",
                      "& .MuiInputBase-root": {
                        height: "40px",
                        width: "100%",
                        borderRadius: "10px",
                        border: "1px solid #E0E0E0",
                        ":hover": "1px solid #E0E0E0",
                      },
                      "& .MuiIconButton-root": {
                        color: theme.palette.yellowColor, // Change this to the desired color
                      },
                      "& fieldset": {
                        border: "none",
                      },
                    }}
                  />
                )}
              />
            </LocalizationProvider>
          </Box>
        </Box>
        {isAssessment && (
          <EditAssessmentQuestionModal
            handleOpen={isAssessment}
            handleClose={handleCloseAssessmentModal}
            handleSubmit={editQuestionAssessment}
            selectedQuestion={selectedQuestion}
            formData={questFormInputValue}
            handleFormInput={handleQuestionInput}
            error={error}
            setError={setError}
          />
        )}

     
      </Box>
    </Box>
  );
};

export default AdminAssessment;
