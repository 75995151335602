import React, { useEffect, useState } from "react";
import ReactCardFlip from "react-card-flip";
import SharedJobsCardFront from "./SharedJobsCardFront";
import SharedJobsCardBack from "./SharedJobsCardBack";
import ReviewVideoDialog from "../../common/ReviewVideoDialog";
import { getEmployerVideoLink } from "../../../redux/employer/empJobListing";
import { useDispatch } from "react-redux";
import { setAlert } from "../../../redux/configSlice";
import { ALERT_TYPE } from "../../../utils/Constants";

export default function SharedJobsCard({ index, job, getJobs, cardWidth }) {
  const dispatch = useDispatch();
  const [isHorizontalFlipped, setisHorizontalFlipped] = useState(false);
  const [openReviewVideoDialog, setOpenReviewVideoDialog] = useState(false);
  const [videoLink, setVideoLink] = useState("");

  const handleCloseDialog = () => {
    setVideoLink("");
    setOpenReviewVideoDialog(false);
  };

  useEffect(() => {
    const fetchVideoLink = async () => {
      try {
        const dataLink = await dispatch(
          getEmployerVideoLink({ job_id: job?.job_id })
        );
        setVideoLink(dataLink?.payload?.data?.job_video_url);
      } catch (error) {
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE?.ERROR,
            msg: error,
          })
        );
      }
    };

    if (openReviewVideoDialog) {
      fetchVideoLink();
    }
  }, [dispatch, job?.job_id, openReviewVideoDialog]);

  return (
    <>
      <ReactCardFlip
        isFlipped={isHorizontalFlipped}
        flipDirection={"horizontal"}
        flipSpeedBackToFront="0.5"
        flipSpeedFrontToBack="0.5"
        key={job?.job_id}
      >
        <SharedJobsCardFront
          index={job.job_id}
          job={job}
          setisFlipped={setisHorizontalFlipped}
          handleReviewVideoDialog={setOpenReviewVideoDialog}
          cardWidth={cardWidth}
        />
        <SharedJobsCardBack
          index={job.job_id}
          cardWidth={cardWidth}
          job={job}
          isFlipped={isHorizontalFlipped}
          setisFlipped={setisHorizontalFlipped}
          handleReviewVideoDialog={setOpenReviewVideoDialog}
        />
      </ReactCardFlip>
      {openReviewVideoDialog && (
        <ReviewVideoDialog
          videoLink={videoLink}
          open={openReviewVideoDialog}
          setOpen={handleCloseDialog}
        />
      )}
    </>
  );
}
