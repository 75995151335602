import React from "react";
import {
  addTalentPool,
  getFreshTalentList,
} from "../../../../redux/admin/jobsSlice";
import { setAlert } from "../../../../redux/configSlice";
import { ALERT_TYPE } from "../../../../utils/Constants";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { useState } from "react";
import logo from "../../../../assets/Company Logos/Ogilvy Restructures.jpg";
import {
  Box,
  CircularProgress,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import { CheckCircle } from "@mui/icons-material";
import { useTheme } from "@emotion/react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useParams } from "react-router-dom";

export default function AddFreshTalent({
  handleCloseFreshTalent,
  getJobList,
  setAllJobs,
}) {
  const dispatch = useDispatch();

  const theme = useTheme();

  const { poolId } = useParams();

  const [talentList, setTalents] = useState([]);
  const [lastKey, setLastKey] = useState("");
  const [totalJob, setTotalJob] = useState(0);
  const [hasMoreData, setHasMoreData] = useState(true);

  const getTalents = async (lastkeyy) => {
    const data = {
      lastKey: lastkeyy,
      title: "",
      applicantpool: false,
      followerpull: false,
      pool_id: "",
      job_id: "",
      stage: "",
    };

    const { payload } = await dispatch(getFreshTalentList(data));
    if (payload?.status === "success") {
      payload.data.length === 0 && setHasMoreData(false);
      if (lastkeyy === "") {
        setLastKey(payload.pageNumber + 1);
        setTalents(payload.data);
        setTotalJob(payload.talentCount);
      } else {
        setLastKey(payload.pageNumber + 1);
        setTalents((prevState) => [...prevState, ...payload.data]);
        setTotalJob(payload.talentCount);
      }
    } else {
      dispatch(
        setAlert({
          show: true,
          type: ALERT_TYPE.ERROR,
          msg: "Something went wrong! please relaod the window",
        })
      );
    }
  };

  const addToPool = async (canID) => {
    try {
      const data = {
        candidate_id: canID,
        pool_id: poolId,
      };
      const { payload } = await dispatch(addTalentPool(data));
      if (payload.status === "success") {
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE.SUCCESS,
            msg: "Talent added to pool successfully",
          })
        );
        setAllJobs([]);
        handleCloseFreshTalent();
        await getJobList(0);
      } else {
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE.ERROR,
            msg: payload?.message?.message,
          })
        );
      }
    } catch (error) {}
  };

  useEffect(() => {
    getTalents(0);
  }, []);
  return (
    <Box
      sx={{
        padding: "19px 15px 0px 15px !important",
      }}
    >
      <Box sx={{ display: "flex", gap: "10px", flexDirection: "column" }}>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography
            sx={{
              fontSize: "16px",
              fontWeight: theme.typography.Medium,
              letterSpacing: "0.32px",
            }}
          >
            Add fresh talent
          </Typography>
          <IconButton
            aria-label="close"
            onClick={() => {
              handleCloseFreshTalent();
            }}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
            disableRipple="true"
          >
            <Box
              sx={{
                width: "20px",
                height: "20px",
                color: "#000000",
                border: 2,
                fontSize: "18px",
                borderRadius: "5px",
              }}
            >
              X
            </Box>
          </IconButton>
        </Box>
        <Typography
          sx={{
            fontSize: "12px",
            fontWeight: theme.typography.Medium,
            width: "307px",
          }}
        >
          Add a fresh talent from the list of talents
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            overflowY: "auto",
     
            gap: "10px",
            paddingRight: "10px",
            marginBottom: "10px",
            "::-webkit-scrollbar": {
              height: "5px",
              background: "#DCDEEB",
              display: "unset !important",
            },
          }}
          height={{ sm: `calc(576px - 130px)`, xl: `calc(586px - 130px)` }}
          className="match-scroll-container"
          id="matchTaleneList"
        >
          <InfiniteScroll
            style={{
              overflowX: "hidden",
              scrollbarWidth: "thin",
              display: "flex",
              flexDirection: "column",
              gap: "10px",
            }}
            dataLength={talentList?.length || 0}
            next={() => getTalents(lastKey)}
            hasMore={hasMoreData}
            loader={
              <Box
                style={{
                  margin: "15px 0 0 0",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CircularProgress color="inherit" size={20} />
              </Box>
            }
            scrollableTarget="matchTaleneList"
            scrollThreshold={"50px"}
          >
            {talentList?.map((item) => (
              <Box
                key={item.user_id}
                sx={{ display: "flex", alignItems: "center", gap: "9px" }}
              >
                <Box
                  component={"img"}
                  src={
                    item?.profile_url !== "No URL" ? item?.profile_url : logo
                  }
                  sx={{
                    height: "50px",
                    width: "50px",
                    borderRadius: "10px",
                  }}
                />
                <Tooltip
                  disableInteractive
                  title={`${item?.first_name} ${item?.last_name}`}
                  placement="top-start"
                >
                  <Typography
                    sx={{
                      fontSize: "15px",
                      fontWeight: theme.typography.Bold,
                      wordBreak: "break-word",
                      cursor: "pointer",
                    }}
                    onClick={() => addToPool(item.user_id)}
                    noWrap={true}
                  >
                    {`${item?.first_name} ${item?.last_name}`}
                  </Typography>
                </Tooltip>
                {item.Followed_flag && (
                  <>
                    <CheckCircle
                      sx={{
                        fontSize: 27,
                        cursor: "pointer",
                      }}
                      color="checkColor"
                    />
                  </>
                )}
              </Box>
            ))}
          </InfiniteScroll>
        </Box>
      </Box>
    </Box>
  );
}
