import { ThemeProvider, useTheme } from "@emotion/react";
import { Box, Button, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import constructionIcon from "../assets/PricingUnderConstructIcon.svg";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { addSubscriber } from "../redux/guest/contactSlice";
import { useSelector } from "react-redux";
import { whosHiringTheme } from "../utils/componentThemes/whosHiringTheme";
import HiringCard from "../components/guest/whoshiring/HiringCard";
import ApplyJobs from "../components/guest/jobs/ApplyJobs";
import CustomDialog from "../components/common/CustomDialog";

const MoreCoolStuff = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [inputValue, setInputValue] = useState();
  const companyData = useSelector(
    (state) => state?.myProfileSli?.footerCompanyData
  );
  const [isValidEmail, setIsValidEmail] = useState(true);
  let dispatch = useDispatch();
  const [openApplyJobDialog, setopenApplyJobDialog] = useState(false);
  const [selectedJobId, setSelectedJobId] = useState(0);
  const [favouriteFilter, setFavouriteFilter] = useState({
    favourite: "",
    appliedJobs: "",
  });
  const handleChange = (e) => {
    setInputValue(e.target.value);
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setIsValidEmail(emailRegex.test(e.target.value));
  };
  const handlePolicy = () => {
    navigate("/privacyPolicy");
  };
  const handleService = () => {
    navigate("/termsOfService");
  };
  const handleSubscribe = async () => {
    if (!isValidEmail) {
      return;
    }
    let payload = {
      email: inputValue,
    };
    try {
      if (inputValue) {
        let response = await dispatch(addSubscriber(payload));
        if (response?.payload?.status === "success") {
          setInputValue();
        }
      }
    } catch (error) {}
  };
  const onHandleClose = () => {
    setopenApplyJobDialog(false);
  };

  return (
    <Box
      sx={{
        overflowY: "auto",
        // gap: "30px",
        columnGap: "30px",
        overFlowX: "auto",
        display: "grid",
        gridTemplateColumns: "repeat(4, minmax(360px, 360px))",
        paddingLeft: "20px",
      }}
      height="100%"
    >
      <Box sx={{ boxSizing: "border-box", paddingTop: "10px", height: "100%" }}>
        <Typography
          sx={{
            fontSize: "16px",
            fontWeight: theme.typography.Bold,
            marginBottom: "5px",
            color: "#fff",
            lineHeight: "19px",
            letterSpacing: "0px",
          }}
        >
          Captain’s (b)log
        </Typography>
        <Box
          sx={{
            height: "530px",
            width: "360px",
            borderRadius: "25px",
            background: "#FFFFFF",
            boxShadow: "0px 2px 4px #00000029",
            display: "flex",
            flexDirection: "column",
            // alignItems: "center",
          }}
        >
          <Typography
            sx={{
              fontSize: "20px",
              fontWeight: theme.typography.Bold,
              marginBottom: "10px",
              paddingX: "30px",
              marginTop: "20px",
            }}
          >
            Under construction…{" "}
          </Typography>
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: theme.typography.Bold,
              marginBottom: "70px",
              paddingLeft: "30px",
              paddingRight: "30px",
            }}
          >
            Here we’ll be previewing the latest founder blog or vlog posts and
            linking through to our standalone Captain’s (b)log as we foray into
            unchartered territory in the talent space… arrrr you ready?!
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box
              component={"img"}
              sx={{ width: "150px", height: "150px" }}
              src={constructionIcon}
            />
          </Box>
        </Box>
      </Box>
      <Box sx={{ boxSizing: "border-box", paddingTop: "10px", height: "100%" }}>
        <Typography
          sx={{
            fontSize: "16px",
            fontWeight: theme.typography.Bold,
            marginBottom: "5px",
            color: "#fff",
            lineHeight: "19px",
            letterSpacing: "0px",
          }}
        >
          Crayon Doodle
        </Typography>
        <Box
          sx={{
            height: "530px",
            width: "360px",
            borderRadius: "25px",
            background: "#FFFFFF",
            boxShadow: "0px 2px 4px #00000029",
            display: "flex",
            flexDirection: "column",
            // alignItems: "center",
          }}
        >
          <Typography
            sx={{
              fontSize: "20px",
              fontWeight: theme.typography.Bold,
              marginBottom: "10px",
              paddingX: "30px",
              marginTop: "20px",
            }}
          >
            Under construction…{" "}
          </Typography>
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: theme.typography.Bold,
              marginBottom: "70px",
              paddingLeft: "30px",
              paddingRight: "30px",
            }}
          >
            A doodle page?! Yip. Our name is Crayon, so why not let you draw?
            Visit us to find your next big gig, or simply to draw whatever your
            heart designs. Stick it up on the doodle wall and watch as others
            upvote it to the top. Graphic designers, where you at?
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box
              component={"img"}
              sx={{ width: "150px", height: "150px" }}
              src={constructionIcon}
            />
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          boxSizing: "border-box",
          paddingTop: "10px",
          height: "100%",
        }}
      >
        <Typography
          sx={{
            fontSize: "16px",
            fontWeight: theme.typography.Bold,
            marginBottom: "5px",
            color: "#fff",
            lineHeight: "19px",
            letterSpacing: "0px",
          }}
        >
          Work with Crayon
        </Typography>
        <Box
          sx={{
            width: "360px",
          }}
        >
          <ThemeProvider theme={whosHiringTheme}>
            <HiringCard
              job={companyData}
              setJobId={setSelectedJobId}
              onHandleClose={onHandleClose}
              setopenApplyJobDialog={setopenApplyJobDialog}
              getCompanyHiringList={() => {}}
              favouriteFilter={favouriteFilter}
              filterCompany={2}
              currentPageJobsCount={1}
              jobslength={1}
              index={1}
              page={"footer"}
            />
          </ThemeProvider>
        </Box>
        {/* <Box
          sx={{
            height: "530px",
            width: "360px",
            borderRadius: "25px",
            background: "#FFFFFF",
            boxShadow: "0px 2px 4px #00000029",
            display: "flex",
            flexDirection: "column",
            // alignItems: "center",
          }}
        >
          <Typography
            sx={{
              fontSize: "20px",
              fontWeight: theme.typography.Bold,
              marginBottom: "10px",
              paddingX: "30px",
              // marginTop: "20px",
            }}
          >
            Under construction…{" "}
          </Typography>
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: theme.typography.Bold,
              marginBottom: "70px",
              paddingLeft: "30px",
              paddingRight: "30px",
            }}
          >
            Here we’ll adding our Company hiring card
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box
              component={"img"}
              sx={{ width: "150px", height: "150px" }}
              src={constructionIcon}
            />
          </Box>
        </Box> */}
      </Box>
      <Box sx={{ boxSizing: "border-box", paddingTop: "10px", height: "100%" }}>
        <Box></Box>
        <Typography
          sx={{
            letterSpacing: "0px",
            lineHeight: "19px",
            fontSize: "16px",
            fontWeight: theme.typography.Bold,
            color: "#fff",
            marginTop: "22px",
            marginBottom: "15px",
          }}
        >
          Contact us
        </Typography>
        <Typography
          sx={{
            letterSpacing: "0px",
            lineHeight: "19px",
            fontSize: "16px",
            fontWeight: theme.typography.Bold,
            color: "#fff",
            marginBottom: "15px",
          }}
        >
          Captain’s (b)log
        </Typography>
        <Typography
          sx={{
            letterSpacing: "0px",
            lineHeight: "19px",
            fontSize: "16px",
            fontWeight: theme.typography.Bold,
            color: "#fff",
            marginBottom: "15px",
          }}
        >
          Crayon Doodle
        </Typography>
        <Typography
          sx={{
            letterSpacing: "0px",
            lineHeight: "19px",
            fontSize: "16px",
            fontWeight: theme.typography.Bold,
            color: "#fff",
            marginBottom: "15px",
          }}
        >
          Work with Crayon
        </Typography>
        <Typography
          sx={{
            letterSpacing: "0px",
            lineHeight: "19px",
            fontSize: "16px",
            fontWeight: theme.typography.Bold,
            color: "#fff",
          }}
        >
          The Legals
        </Typography>
        <Typography
          sx={{
            letterSpacing: "0px",
            lineHeight: "19px",
            fontSize: "14px",
            fontWeight: theme.typography.Bold,
            color: "#fff",
            cursor: "pointer",
          }}
          onClick={handleService}
        >
          Terms of Service
        </Typography>
        <Typography
          sx={{
            letterSpacing: "0px",
            lineHeight: "19px",
            fontSize: "14px",
            fontWeight: theme.typography.Bold,
            color: "#fff",
            marginBottom: "10px",
            cursor: "pointer",
          }}
          onClick={handlePolicy}
        >
          Privacy Policy
        </Typography>
        <Typography
          sx={{
            letterSpacing: "0px",
            lineHeight: "19px",
            fontSize: "16px",
            fontWeight: theme.typography.Bold,
            color: "#fff",
            marginBottom: "5px",
          }}
        >
          The Crayon Chronicles
        </Typography>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            // backgroundColor: "#6A1B9A", // Purple background
            // padding: "8px",
            borderRadius: "10px",
            maxWidth: "400px",
            margin: "0 auto",
          }}
        >
          <TextField
            value={inputValue || ""}
            variant="outlined"
            placeholder="Add your email address"
            size="small"
            onChange={handleChange}
            sx={{
              backgroundColor: "#fff", // White background for input
              borderRadius: "10px 0 0 10px",
              // marginRight: "8px",
              flexGrow: 1,
            }}
          />
          <Button
            variant="contained"
            onClick={handleSubscribe}
            sx={{
              backgroundColor: "#CE0A24",
              color: "#fff",
              textTransform: "none",
              borderRadius: "0px 10px 10px 0px",
              "&:hover": {
                backgroundColor: "#CE0A24",
              },
            }}
          >
            Subscribe
          </Button>
        </Box>
        <Typography
          sx={{
            marginTop: "5px",
            fontSize: "12px",
            fontWeight: theme.typography.Regular,
            letterSpacing: "0px",
            lineHeight: "19px",
            color: "#fff",
          }}
        >
          By subscribing to our newsletter you agree to our Privacy Policy and
          provide consent to receive updates from Crayon. You can opt out at any
          time.
        </Typography>
      </Box>
      <CustomDialog
        show={openApplyJobDialog}
        hideButton={false}
        onDialogClose={onHandleClose}
        dialogWidth="xs"
        padding={0}
        showFooter={false}
        isApplyJob
      >
        <ApplyJobs
          jobId={selectedJobId}
          setopenApplyJobDialog={setopenApplyJobDialog}
        />
      </CustomDialog>
    </Box>
  );
};

export default MoreCoolStuff;
