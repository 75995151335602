import { Button } from "@mui/material";
import React from "react";
import LeftArrow from "./LeftArrow";
import RightArrow from "./RightArrow";

export default function LeftFilterButton({ onClick, leftExpanded, color }) {
  return (
    <Button
      variant="contained"
      color={color}
      sx={{
        position: "static",
        borderRadius: "0 10px 10px 0",
        minWidth: "30px !important",
        maxWidth: "30px !important",
        padding: "0px !important",
        height: "45px",
        ":hover": {
          boxShadow: "none",
        },
      }}
      onClick={() => onClick()}
    >
      {!leftExpanded ? <LeftArrow /> : <RightArrow />}
    </Button>
  );
}
