import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getApi } from "../../utils/Apis";
import { setLoading } from "../configSlice";
const initialState = {
  employerjobs: [],
  profilePercentage: {},
};
export const getAllJobsListing = createAsyncThunk(
  "getAllJobsListing",
  async (payload, { dispatch }) => {
    try {
      dispatch(setLoading(true));
      const { data } = await getApi(
        `/admin/employer/job?jobstage_id=&jobstatus_id=`,
        true
      );
      dispatch(setLoading(false));
      return data;
    } catch (error) {
      // Handle the error appropriately (e.g., display an error message)
      alert("Error occurred while fetching job listings:", error);
      throw error;
    }
  }
);

export const getProfilePercentage = createAsyncThunk(
  "getProfilePercentage",
  async (payload, { dispatch }) => {
    const { data } = await getApi(
      "/employer/getEmployerProfilePercentage",
      true
    );
    return data;
  }
);

export const getEmployerVideoLink = createAsyncThunk(
  "getEmployerVideoLink",
  async (payload, { dispatch }) => {
    const { data } = await getApi(
      `/job/jobvideolink?job_id=${payload.job_id}`,
      true
    );
    return data;
  }
);

export const getFilteredJobsListing = createAsyncThunk(
  "getFilteredJobsListing",
  async (
    {
      selectedFilters = "",
      selectedStatusFilter = "",
      lastKey,
      job_title = "",
      region_id = "",
      tag_id = "",
      town_id = "",
      tool_id = "",
      salary = "",
      experience = "",
      company_id = "",
      currency_id = "",
      highest_qualification_id = "",
      assignedJobFlag = "",
    },
    { dispatch }
  ) => {
    if (selectedFilters === "1111" || selectedFilters === "all") {
      selectedFilters = "";
    }
    if (selectedStatusFilter === "1111" || selectedStatusFilter === "all") {
      selectedStatusFilter = "";
    }
    const { data } = await getApi(
      `/admin/employer/job?jobstage_id=${selectedFilters}&jobstatus_id=${selectedStatusFilter}&lastKey=${lastKey}&job_title=${job_title}&region_id=${region_id}&tag_id=${tag_id}&town_id=${town_id}&tool_id=${tool_id}&company_id=${company_id}&currency_id=${currency_id}&highest_qualification_id=${highest_qualification_id}&salary=${salary}&experience=${experience}&assignedJobFlag =${assignedJobFlag}`,
      true
    );
    return data;
  }
);
export const getMySpec = createAsyncThunk(
  "getMySpec",
  async (
    {
      selectedFilters = "",
      selectedStatusFilter = "",
      lastKey,
      job_title = "",
      region_id = "",
      tag_id = "",
      town_id = "",
      tool_id = "",
      salary = "",
      experience = "",
      company_id = "",
      currency_id = "",
      highest_qualification_id = "",
      assignedJobFlag = "",
      mySpec = "",
    },
    { dispatch }
  ) => {
    if (selectedFilters === "1111" || selectedFilters === "all") {
      selectedFilters = "";
    }
    if (selectedStatusFilter === "1111" || selectedStatusFilter === "all") {
      selectedStatusFilter = "";
    }
    const { data } = await getApi(
      `/admin/employer/job?jobstage_id=${selectedFilters}&jobstatus_id=${selectedStatusFilter}&lastKey=${lastKey}&job_title=${job_title}&region_id=${region_id}&tag_id=${tag_id}&town_id=${town_id}&tool_id=${tool_id}&company_id=${company_id}&currency_id=${currency_id}&highest_qualification_id=${highest_qualification_id}&salary=${salary}&experience=${experience}&assignedJobFlag =${assignedJobFlag} &${mySpec}=true`,
      true
    );
    return data;
  }
);
export const empJobListing = createSlice({
  name: "configemployerjobs",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(getProfilePercentage.fulfilled, (state, action) => {
      state.profilePercentage = action?.payload?.data;
    });
  },
});
// Action creators are generated for each case reducer function

export default empJobListing.reducer;
