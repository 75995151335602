import { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import locale from "../../../i18n/locale";
import {
  BASIC_RANGEMARKS_SLIDER,
  CVBASIC,
  EXPERIENCE,
  NOTICEPERIOD,
  SALARY_VALUE_OPTION,
} from "../../../utils/Constants";
import SelectMenu from "../../common/SelectMenu";
import Slider from "@mui/material/Slider";
import {
  getTitles,
  getIndustries,
  getSkills,
  getWorkExperience,
  getNoticePeriod,
  getQualifications,
  getCurrency,
  uploadCv,
  addBasicData,
  updateToInitialIndustrie,
  updateToInitialSkills,
  updateToInitialCulture,
  getCompanyCulture,
  setCVName,
} from "../../../redux/candidate/myCvSlice";

import { setAlert, setLoading } from "../../../redux/configSlice";
import { ALERT_TYPE, ERROR_MSG } from "../../../utils/Constants";
import { InputLabel } from "@mui/material";
import {
  getRoleTypes,
  getTools,
  getWorkSetup,
  updateToInitialTools,
} from "../../../redux/employer/postJobSlice";
import { getCVBasics } from "../../../redux/candidate/myCVNew";
import nextIcon from "../../../assets/Black_Right_Next.svg";
import { useTheme } from "@emotion/react";
import CompleteCV from "./dialogBox/CompleteCV";
import NextDialog from "./dialogBox/NextDialog";
import uploadCVIcon from "../../../assets/MyCV/UploadCv.svg";
import scrapeLinkedinIcon from "../../../assets/MyCV/GrabLinkedinIcon.svg";
import CustomSelect from "./CustomSelect";
import {
  checkNumberRange,
  formatNumber,
  genarateLabelValue,
  genarateLabelValueForFreelance,
  textValue,
  validateSchema,
} from "../../../utils/Common";
import { CANDIDATE_BASIC_SCHEMA } from "../../../utils/formSchema";
import {
  getProfileProgressData,
  setCurrentStep,
} from "../../../redux/candidate/myProfileSlice";
import _ from "lodash";
import UploadCV from "./dialogBox/UploadCV";
import useViewport from "../../common/ViewPort";
import SalarySlider from "../../common/SalarySlider";
import { BlueSwitch, ToggleSwitch } from "../../../utils/CommonComponent";
import { useNavigate } from "react-router-dom";

export default function TheBasicsNew({
  changeStep,
  handleComplete,
  setProfileCompletion,
  step,
  initialData,
  setInitialData,
  basicData,
  setBasicData,
}) {
  const i18n = locale.en;
  const theme = useTheme();
  const navigate = useNavigate();
  const isLoadings = useSelector((state) => state.config.loading);

  // const fileAccept = "application/pdf, application/doc, application/docx";
  const fileAccept = "application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document";
  const dispatch = useDispatch();
  const { width } = useViewport();
  const hiddenFileInput = useRef(null);
  const [salaryRange, setSalaryRange] = useState(BASIC_RANGEMARKS_SLIDER);
  const [rangeValue, setRangeValue] = useState([]);
  const [interval, setInterval] = useState();
  const [errors, setErrors] = useState([]);
  const [nextDialog, setNextDialog] = useState(false);
  const [saveAndExitDialog, setsaveAndExitDialog] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isLoading, setIsLoading] = useState({});
  const [boxGap, setBoxGap] = useState((window.innerWidth * 120) / 1920);
  const [openCvBox, setOpenCvBox] = useState(false);
  const [inputBoxWidth, setInputBoxWidth] = useState(
    (window.innerWidth * 672) / 1920
  );
  const {
    titles,
    industries,
    skills,
    qualifications,
    currency,
    initialIndustries,
    initialSkills,
    cVName,
    companyCultures,
    initialCompanyCulture,
  } = useSelector((state) => state.myCv);
  const { tools, roleTypes, workSetup, initialTools } = useSelector(
    (state) => state.postJobs
  );

  const [salaryRangeValue, setSalaryRangeValue] = useState(false);
  const [singleSalaryValue, setSingleSalaryValue] = useState(0);
  const getNewWidth = () => {
    setInputBoxWidth((window.innerWidth * 672) / 1920);
    setBoxGap((window.innerWidth * 120) / 1920);
  };
  const noticeValue = (value) => {
    const text = NOTICEPERIOD[value].label;
    return text;
  };

  const rangeValueHandler = (value) => {
    const symbol = currency?.find(
      (curr) => curr.currency_id === basicData?.currency_id
    )
      ? currency?.find((curr) => curr.currency_id === basicData?.currency_id)
      : "$";
    let valueNumberRange = checkNumberRange(symbol?.max_salary);

    return `${symbol?.symbol ? symbol?.symbol : symbol} ${
      valueNumberRange === "thousands"
        ? formatNumber(value * 1000)
        : formatNumber(value * 10000)
    }`;
  };

  const rangeValueHandler2 = (value) => {
    const symbol = currency?.find(
      (curr) => curr.currency_id === basicData?.currency_id
    )
      ? currency?.find((curr) => curr.currency_id === basicData?.currency_id)
      : "$";
    return `${symbol?.symbol ? symbol?.symbol : symbol} ${formatNumber(
      value * interval
    )}`;
  };

  const handleSaveButton = async (btnType, skip) => {
    try {
      await dispatch(getProfileProgressData());
      // if (cVName === "No file chosen" && !skip) {
      //   setOpenCvBox(true);
      //   return;
      // }
      let updatedPayload = { ...basicData };
      if (typeof updatedPayload?.["current_job_title_id"]?.value === "number") {
        updatedPayload["current_job_title_id"] =
          basicData?.current_job_title_id?.value;
      }
      if (typeof updatedPayload?.["dream_job_title_id"]?.value === "number") {
        updatedPayload["dream_job_title_id"] =
          basicData?.dream_job_title_id?.value;
      }
      if (typeof updatedPayload?.["current_job_title_id"]?.value === "string") {
        updatedPayload["current_job_title_id"] =
          basicData?.current_job_title_id?.value;
        updatedPayload["currentTitle"] = basicData?.current_job_title_id?.value;
      }
      if (typeof updatedPayload?.["dream_job_title_id"]?.value === "string") {
        updatedPayload["dream_job_title_id"] =
          basicData?.dream_job_title_id?.value;
        updatedPayload["dreamTitle"] = basicData?.dream_job_title_id?.value;
      }
      if (updatedPayload["industry_id"]?.length > 0) {
        updatedPayload["industries"] = [11];
        delete updatedPayload["industry_id"];
      }
      if (updatedPayload["industries"]?.length > 0) {
        updatedPayload["industries"] = basicData?.industries?.map(
          (item) => item.value
        );
      }
      if (updatedPayload["culture_ids"]?.length > 0) {
        updatedPayload["culture_ids"] = basicData?.culture_ids?.map(
          (item) => item?.value
        );
      }
      if (updatedPayload["tags"]?.length > 0) {
        updatedPayload["tags"] = basicData?.tags?.map((item) => item.value);
      }
      if (updatedPayload["tools"]?.length > 0) {
        updatedPayload["tools"] = basicData?.tools?.map((item) => item.value);
      }
      validateSchema(CANDIDATE_BASIC_SCHEMA, updatedPayload)
        .then(() => {
          try {
            dispatch(addBasicData({ ...updatedPayload }))
              .then((payload) => {
                if (payload?.payload?.status === "success") {
                  setInitialData({ ...basicData });
                  dispatch(
                    setAlert({
                      show: true,
                      type: ALERT_TYPE.SUCCESS,
                      msg: "Basic data added successfully!",
                    })
                  );
                  if (btnType === "workLife") {
                    changeStep(1, "component");
                  }
                  if (btnType === "lockItIn") {
                    dispatch(setCurrentStep(2));
                    navigate("/candidate/my-profile");
                  }
                  setErrors([]);
                  //BELOW FUNCTION ARE FOR POPUP BOXES
                  handleComplete(0);
                } else {
                  setErrors(
                    payload?.payload?.message?.map((item) => ({
                      message: item?.message,
                      key: item?.key,
                    }))
                  );
                }
              })
              .catch((error) => {
                dispatch(
                  setAlert({
                    show: true,
                    type: ALERT_TYPE.ERROR,
                    msg: "Error",
                  })
                );
              });
          } catch (error) {
            dispatch(
              setAlert({
                show: true,
                type: ALERT_TYPE.ERROR,
                msg: "Error",
              })
            );
          }
        })
        .catch((error) => {
          setErrors(
            Object.keys(error)?.map((item) => ({
              message: error[item],
              key: item,
            }))
          );
        });
    } catch (error) {
      dispatch(
        setAlert({
          show: true,
          type: ALERT_TYPE?.ERROR,
          msg: ERROR_MSG,
        })
      );
    }
  };

  const getAllData = async (para) => {
    try {
      switch (para) {
        case "current_job_title_id":
          await dispatch(
            getTitles({ setState: setIsLoading, type: "current_job_title_id" })
          );
          return;
        case "dream_job_title_id":
          await dispatch(
            getTitles({ setState: setIsLoading, type: "dream_job_title_id" })
          );
          return;
        case "culture_ids":
          await dispatch(
            getCompanyCulture({ setState: setIsLoading, type: "culture_ids" })
          );
          return;
        case "industry":
          await dispatch(
            getIndustries({ setState: setIsLoading, type: "industries" })
          );
          return;
        case "skills":
          await dispatch(getSkills({ setState: setIsLoading, type: "tags" }));
          return;
        case "tools":
          await dispatch(getTools({ setState: setIsLoading, type: "tools" }));
          return;
        case "roleType":
          await dispatch(getRoleTypes());
          return;
        case "workSetup":
          await dispatch(getWorkSetup());
          return;
        case "qualifications":
          await dispatch(getQualifications());
          return;
        case "currency":
          await dispatch(getCurrency());
          return;
        default:
          return;
      }
    } catch (error) {
      dispatch(setLoading(false));
      dispatch(
        setAlert({
          show: true,
          type: ALERT_TYPE.ERROR,
          msg: ERROR_MSG,
        })
      );
    }
  };

  const getInitialData = async (basic) => {
    // dispatch(setLoading(true));
    if (basic?.culture_ids?.length > 0) {
      await getAllData("culture_ids");
    }
    if (basic?.current_job_title_id !== "") {
      await getAllData("current_job_title_id");
    }
    if (basic?.dream_job_title_id !== "") {
      await getAllData("dream_job_title_id");
    }
    if (basic?.industry_id?.length > 0) {
      await getAllData("industry");
    }
    if (basic?.tags?.length > 0) {
      await getAllData("skills");
    }
    if (basic?.tools?.length > 0) {
      await getAllData("tools");
    }
    if (basic?.qualification_level !== "") {
      await getAllData("qualifications");
    }
    if (basic?.currency_id !== "") {
      await getAllData("currency");
    }
    if (basic?.employment_type !== "") {
      await getAllData("roleType");
    }
    if (basic?.work_setup !== "") {
      await getAllData("workSetup");
    }
    dispatch(setLoading(false));
  };

  const getWorkSet = async () => {
    try {
      await Promise.all([
        dispatch(getNoticePeriod()),
        dispatch(getWorkExperience()),
      ]);
    } catch (error) {
      dispatch(setLoading(false));
      dispatch(
        setAlert({
          show: true,
          type: ALERT_TYPE.ERROR,
          msg: ERROR_MSG,
        })
      );
    }
  };

  const getInitialSalary = (basicData) => {
    const currencySalary = currency?.find(
      (item) => item?.currency_id === basicData?.currency_id
    );
    let range;
    let salaryInterval;
    if (basicData?.employment_type === "freelance") {
      let { rangeArray, interval } = genarateLabelValueForFreelance(
        currencySalary ? currencySalary : { max_rate: 2050 }
      );
      range = rangeArray;
      salaryInterval = interval;
      setInterval(interval);
      setRangeValue([range?.length / 10, range?.length / 2]);
      setSingleSalaryValue(range?.length / 2);
    } else {
      range = genarateLabelValue(
        currencySalary ? currencySalary : { max_salary: 205000 }
      );
    }
    setSalaryRange(range);

    if (basicData?.employment_type === "freelance") {
      if (basicData?.currency_id === 1) {
        basicData?.salary?.[0] > 0
          ? setRangeValue([
              basicData?.salary?.[0] / 100,
              basicData?.salary?.[1] / 100,
            ])
          : setSingleSalaryValue(basicData?.salary?.[1] / 100);
      } else {
        basicData?.salary?.[0] > 0
          ? setRangeValue([
              basicData?.salary?.[0] / salaryInterval,
              basicData?.salary?.[1] / salaryInterval,
            ])
          : setSingleSalaryValue(basicData?.salary?.[1] / salaryInterval);
      }
    } else {
      if (basicData?.currency_id === 1) {
        basicData?.salary?.[0] > 0
          ? setRangeValue([
              basicData?.salary?.[0] / 10000,
              basicData?.salary?.[1] / 10000,
            ])
          : setSingleSalaryValue(basicData?.salary?.[1] / 10000);
      } else {
        basicData?.salary?.[0] > 0
          ? setRangeValue([
              basicData?.salary?.[0] / 1000,
              basicData?.salary?.[1] / 1000,
            ])
          : setSingleSalaryValue(basicData?.salary?.[1] / 1000);
      }
    }
  };

  const getCVBasicData = async () => {
    dispatch(setLoading(true));
    const { payload } = await dispatch(getCVBasics());
    if (payload?.status === "success" || payload?.status === "sucess") {
      const basic = payload?.data;
      // const cvlink = basic?.cv_link?.split("/");
      const parts = basic?.cv_link ? basic.cv_link.split('/') : [];
      dispatch(setCVName(parts[parts?.length - 1] ? parts[parts?.length - 1] : "No file chosen"));
      setSalaryRangeValue(basic?.salary?.[0] > 0 ? true : false);
      if (payload?.message === "cv basic is not completed") {
        setBasicData(CVBASIC);
        setInitialData(CVBASIC);
        return;
      }

      basic.culture_ids = basic?.culture_ids?.map((item) => ({
        value: item?.culture_id,
        label: item?.name,
      }));

      basic.industries = basic?.industry_id?.map((item) => ({
        value: item?.industry_id,
        label: item?.name,
      }));
      basic.tags = basic?.tags?.map((item) => ({
        value: item?.tag_id,
        label: item?.name,
      }));
      basic.tools = basic?.tools?.map((item) => ({
        value: item?.tool_id,
        label: item?.name,
      }));
      // const salary = [
      //   basic?.employment_type === "freelance"
      //     ? basic?.salary?.[0] / 5
      //     : basic?.salary?.[0] / 2000,
      //   basic?.employment_type === "freelance"
      //     ? basic?.salary?.[1] / 5
      //     : basic?.salary?.[1] / 2000,
      // ];

      // const experience = basic.experience.map((item) => {
      //   return item * 10;
      // });
      if (basic?.current_job_title_id) {
        let currentJob = {
          value: basic?.current_job_title_id,
          label: basic?.currentTitle,
        };
        basic.current_job_title_id = currentJob;
      }
      if (basic?.dream_job_title_id) {
        let dreamJob = {
          value: basic?.dream_job_title_id,
          label: basic?.dreamTitle,
        };
        basic.dream_job_title_id = dreamJob;
      }

      if (basic?.currency_id === null) {
        basic.currency_id = 1;
        basic.salary = [0, 100000];
      }

      const profileCompletionPercentage = {
        profileCompletion: 25,
        cvBasics: payload?.data?.cv_basic_completed ? 10 : 0,
        workLife: payload?.data?.work_life_completed ? 5 : 0,
        studyLife: payload?.data?.study_life_completed ? 5 : 0,
        references: payload?.data?.user_reference_completed ? 5 : 0,
      };
      // await getInitialSalary(basic);
      setBasicData(basic);
      setInitialData(basic);
      await getInitialData(basic);
      // setExpRange(experience);
      // setRangeValue(salary === "undefined" ? [] : salary);
      setProfileCompletion(profileCompletionPercentage);
    } else if (payload?.status === "error") {
      return;
    } else {
      setOpenCvBox(true);
      return;
    }
    // dispatch(setLoading(false));
  };

  const handleFileClick = () => {
    hiddenFileInput?.current?.click();
  };

  const handleFileChange = async (event) => {
    const formData = new FormData();
    formData.append("cv", event?.target?.files?.[0]);
    try {
      const { payload } = await dispatch(uploadCv(formData));
      if (payload?.status === "success") {
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE?.SUCCESS,
            msg: "CV uploaded Successfully!",
          })
        );
        dispatch(setCVName(event?.target?.files?.[0]?.name));
        setOpenCvBox(false);
      } else {
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE?.ERROR,
            msg: payload?.message,
          })
        );
      }
    } catch (error) {
      dispatch(setAlert({ show: true }));
    }
  };

  const handleRangeSliderChange = (event, newValue) => {
    setRangeValue(newValue);
    let newArr = newValue?.map((val) => val * 1000);
    const newBasicData = {
      ...basicData,
      [event?.target?.name]:
        basicData?.currency_id === 1
          ? [newArr?.[0] * 10, newArr?.[1] * 10]
          : newArr,
    };
    const filteredErrors = errors?.filter(
      (item) => item?.key != event?.target?.name
    );
    setErrors(filteredErrors);
    setBasicData(newBasicData);
  };

  const handleRangeSliderChange2 = (event, newValue) => {
    setRangeValue(newValue);
    let newArr = newValue?.map((val) => Math.round(val * interval));
    const newBasicData = {
      ...basicData,
      [event?.target?.name]: newArr,
    };

    const filteredErrors = errors?.filter(
      (item) => item?.key != event?.target?.name
    );
    setErrors(filteredErrors);
    setBasicData(newBasicData);
  };

  const singleValueSlider2 = (event) => {
    const {
      target: { value },
      target: { name },
    } = event;
    let newArr = [0, Math.round(value * interval)];
    const newBasicData = {
      ...basicData,
      [event?.target?.name]: newArr,
    };
    setSingleSalaryValue(value);
    const filteredErrors = errors?.filter(
      (item) => item?.key != event?.target?.name
    );
    setErrors(filteredErrors);
    setBasicData(newBasicData);
  };

  const singleValueSlider = (event) => {
    const {
      target: { value },
      target: { name },
    } = event;
    setSingleSalaryValue(value);
    let newArr = [0, value * 1000];
    const newBasicData = {
      ...basicData,
      [event?.target?.name]:
        basicData?.currency_id === 1
          ? [newArr?.[0] * 10, newArr?.[1] * 10]
          : newArr,
    };
    const filteredErrors = errors?.filter(
      (item) => item?.key != event?.target?.name
    );
    setErrors(filteredErrors);
    setBasicData(newBasicData);
  };

  const expHandleChange = (event) => {
    const {
      target: { value },
      target: { name },
    } = event;

    const newBasicData = {
      ...basicData,
      [name]: value,
    };
    const filteredErrors = errors?.filter(
      (item) => item?.key !== event?.target?.name
    );
    setErrors(filteredErrors);
    setBasicData(newBasicData);
  };

  const noticeHandleChange = (event) => {
    const {
      target: { value },
      target: { name },
    } = event;

    const newBasicData = {
      ...basicData,
      [name]: value,
    };
    const filteredErrors = errors?.filter((item) => item?.key !== name);
    setErrors(filteredErrors);
    setBasicData(newBasicData);
  };

  const handleChange = (event) => {
    const {
      target: { value },
      target: { name },
      target: { id },
    } = event;
    let newBasicData = { ...basicData };
    let range;
    if (name === "currency_id") {
      const currencySalary = currency?.find(
        (item) => item?.currency_id === value
      );
      if (basicData?.employment_type === "freelance") {
        let { rangeArray, interval } = genarateLabelValueForFreelance(
          currencySalary ? currencySalary : { max_rate: 2050 }
        );
        range = rangeArray;
        setInterval(interval);
        setRangeValue([range?.length / 10, range?.length / 2]);
        setSingleSalaryValue(range?.length / 2);
        newBasicData = {
          ...basicData,
          [name]: value,
          salary: [
            (range?.length / 10) * interval,
            (range?.length / 2) * interval,
          ],
        };
      } else {
        range = genarateLabelValue(
          currencySalary ? currencySalary : { max_salary: 205000 }
        );
        if (currencySalary?.currency === "ZAR") {
          setRangeValue([3, 10]);
          setSingleSalaryValue(10);
          newBasicData = {
            ...basicData,
            [name]: value,
            salary: [30000, 100000],
          };
        } else {
          newBasicData = {
            ...basicData,
            [name]: value,
            salary: [
              2000,
              Math.floor(currencySalary?.max_salary / 2 / 100) * 100,
            ],
          };
          setRangeValue([2, 5.2]);
          setSingleSalaryValue(5.2);
        }
      }
      setSalaryRange(range);
      setBasicData(newBasicData);
    }
  };

  const handleQualification = (event) => {
    const {
      target: { value },
      target: { name },
      target: { id },
    } = event;
    let newBasicData = {
      ...basicData,
      [name]: value,
    };
    setBasicData(newBasicData);
  };

  const onMenuOpen = (type) => {
    if (titles?.length < 1) {
      getAllData(type);
    }
    setIsMenuOpen(true);
  };
  const onMenuClose = () => {
    setIsMenuOpen(false);
  };

  const handleOnSelectChange = (ind, newValue, val, inputId) => {
    let newBasicData = { ...basicData };
    if (
      ind === "industries" ||
      ind === "tools" ||
      ind === "tags" ||
      ind === "culture_ids"
    ) {
      if (ind === "culture_ids") {
        dispatch(updateToInitialCulture());
      }
      if (ind === "industries") {
        dispatch(updateToInitialIndustrie());
      }
      if (ind === "tools") {
        dispatch(updateToInitialTools());
      }
      if (ind === "tags") {
        dispatch(updateToInitialSkills());
      }
      newBasicData[ind] = newValue;
    } else if (
      newValue &&
      newValue?.label &&
      !["industries", "tags", "tools"].includes(newValue)
    ) {
      newBasicData[inputId] = newValue;
    } else {
      newBasicData[inputId] = newValue?.id || newValue;
    }
    setBasicData(newBasicData);
  };
  const updateDebounceFunc = async (newValue, type) => {
    let params = {
      title: newValue || "",
      limit: 200,
    };
    if (newValue) {
      if (newValue) {
        try {
          if (type === "culture_ids") {
            dispatch(
              getCompanyCulture({ ...params, setState: setIsLoading, type })
            );
          }
          if (type === "industries") {
            dispatch(
              getIndustries({ ...params, setState: setIsLoading, type })
            );
          }
          if (type === "tags") {
            dispatch(getSkills({ ...params, setState: setIsLoading, type }));
          }
          if (type === "tools") {
            dispatch(getTools({ ...params, setState: setIsLoading, type }));
          }
          if (
            type === "current_job_title_id" ||
            type === "dream_job_title_id"
          ) {
            dispatch(getTitles({ ...params, setState: setIsLoading, type }));
          }
        } catch (error) {
          dispatch(
            setAlert({
              show: true,
              type: ALERT_TYPE?.ERROR,
              msg: "Something went wrong",
            })
          );
        }
      }
    }
  };
  const debounceFunction = _.debounce(updateDebounceFunc, 750);

  const handleOnSelectInputChange = (newValue, inputId) => {
    debounceFunction(newValue, inputId);
  };
  const onCreateOption = (data, inputId) => {
    let newBasicData = {};
    if (data) {
      // Create a new value from the user input
      newBasicData = {
        ...basicData,
        [inputId]: { label: data, value: data },
      };
    }
    setBasicData(newBasicData);
  };

  const handleJobRoleChange = (event) => {
    let newBasicData = { ...basicData };
    const currencySalary = currency?.find(
      (item) => item?.currency_id === basicData?.currency_id
    );
    let range;
    if (event?.target?.value === 3) {
      let { rangeArray, interval } = genarateLabelValueForFreelance(
        currencySalary ? currencySalary : { max_rate: 2050 }
      );
      range = rangeArray;
      setInterval(interval);
      setRangeValue([range?.length / 10, range?.length / 2]);
      setSingleSalaryValue(range?.length / 2);
      newBasicData = {
        ...newBasicData,
        salary: [
          (range?.length / 10) * interval,
          (range?.length / 2) * interval,
        ],
      };
    } else {
      range = genarateLabelValue(
        currencySalary ? currencySalary : { max_salary: 205000 }
      );
      if (basicData?.currency_id === 1) {
        newBasicData = {
          ...newBasicData,
          salary: [30000, 100000],
        };
        setRangeValue([3, 10]);
        setSingleSalaryValue(10);
      } else {
        newBasicData = {
          ...newBasicData,
          salary: [
            2000,
            Math.floor(currencySalary?.max_salary / 2 / 100) * 100,
          ],
        };
        setRangeValue([2, 5.2]);
        setSingleSalaryValue(5.2);
      }
    }
    setSalaryRange(range);
    newBasicData = {
      ...newBasicData,
      employment_type:
        event?.target?.value === "None"
          ? ""
          : roleTypes?.find((role) => role?.id == event?.target?.value)?.name,
      contract_duration:
        event?.target?.value !== 2 ? "" : basicData?.contract_duration,
    };
    setErrors(errors?.filter((item) => item?.key !== event?.target?.name));
    setBasicData(newBasicData);
  };

  const handleWorkSetupChange = (event) => {
    const {
      target: { value },
      target: { name },
    } = event;
    const temp = workSetup?.find((role) => role?.id === value);
    const newBasicData = {
      ...basicData,
      [name]: temp?.name || "",
    };
    setBasicData(newBasicData);
  };

  const getIndValue = () => {
    if (basicData?.industries?.length === 0) {
      return [];
    }

    return basicData?.industries?.map(
      (industry) => industries?.find((ind) => ind?.id === industry) || industry
    );
  };
  const getToolValue = () => {
    if (basicData?.tools?.length === 0) {
      return [];
    }

    return basicData?.tools?.map(
      (id) => tools?.find((tool) => tool?.id === id) || id
    );
  };
  const getCultureValue = () => {
    if (basicData?.culture_ids?.length === 0) {
      return [];
    }

    return basicData?.culture_ids?.map(
      (id) => companyCultures?.find((culture) => culture?.id === id) || id
    );
  };

  const getSkillValue = () => {
    if (basicData?.tags?.length === 0) {
      return [];
    }

    return basicData?.tags?.map(
      (skill) => skills?.find((sk) => sk?.id === skill) || skill
    );
  };

  const handlesaveAndExitDialog = () => {
    setsaveAndExitDialog((prevState) => !prevState);
  };

  const handleOpenNext = () => {
    setNextDialog((prevState) => !prevState);
  };

  const handleSalaryValue = (event) => {
    const {
      target: { value },
      target: { name },
      target: { id },
    } = event;
    setSalaryRangeValue(event?.target?.checked);

    if (basicData?.employment_type === "freelance") {
      if (basicData?.currency_id === 1) {
        setRangeValue([
          basicData?.salary?.[0] / 100,
          basicData?.salary?.[1] / 100,
        ]);
        setSingleSalaryValue(basicData?.salary?.[1] / 100);
      } else {
        setRangeValue([
          basicData?.salary?.[0] / interval,
          basicData?.salary?.[1] / interval,
        ]);
        setSingleSalaryValue(basicData?.salary?.[1] / interval);
      }
    } else {
      if (basicData?.currency_id === 1) {
        setRangeValue([
          basicData?.salary?.[0] / 10000,
          basicData?.salary?.[1] / 10000,
        ]);
        setSingleSalaryValue(basicData?.salary?.[1] / 10000);
      } else {
        setRangeValue([
          basicData?.salary?.[0] / 1000,
          basicData?.salary?.[1] / 1000,
        ]);
        setSingleSalaryValue(basicData?.salary?.[1] / 1000);
      }
    }
    let newArr = [0, basicData?.salary?.[1]];
    const newBasicData = {
      ...basicData,
      salary:
        basicData?.currency_id === 1 ? [newArr?.[0], newArr?.[1]] : newArr,
    };
    const filteredErrors = errors?.filter(
      (item) => item?.key != event?.target?.name
    );
    setErrors(filteredErrors);
    setBasicData(newBasicData);
  };

  useEffect(() => {
    window.addEventListener("resize", getNewWidth);
    return () => {
      window.removeEventListener("resize", getNewWidth);
    };
  }, []);

  useEffect(() => {
    getCVBasicData();
    getWorkSet();
  }, []);

  useEffect(() => {
    getInitialSalary(basicData);
  }, [currency]);

  return (
    <>
      <Box>
        <input
          accept={fileAccept}
          ref={hiddenFileInput}
          type="file"
          onChange={handleFileChange}
          style={{ display: "none" }}
          required
        />
        <Box
          sx={{
            display: "flex",
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          <Box>
            <Button
              variant="contained"
              color="yoco"
              sx={{
                fontSize: theme.typography.fontSize.sm,
                fontWeight: theme.typography.Bold,
                minHeight: "45px",
                minWidth: { xs: "", md24: "150px" },
                padding: { xs: "6px 16px", md24: "6px 16px" },
                borderRadius: "25px 0 10px 0",
                textWrap: "nowrap",
                boxShadow: "none",
                "& .MuiButton-startIcon": {
                  marginLeft: "0px",
                },
              }}
            >
              {i18n["myCV.thebasics"]}
            </Button>
          </Box>
          <Box sx={{ textAlign: { xs: "right", md24: "" } }}>
            <Button
              variant="contained"
              color={
                cVName !== "No file chosen" ? "checkColor" : "peachPayment"
              }
              sx={{
                fontSize: theme.typography.fontSize.sm,
                fontWeight: theme.typography.Bold,
                minHeight: "45px",
                minWidth: { xs: "", md24: "150px" },
                // borderRadius:{xs:"0px",md24:"0 0 0 10px"},
                borderRadius: "0 0 0 10px",
                padding: { xs: "6px", md24: "0px" },
                textWrap: "nowrap",
                boxShadow: "none",
                "& .MuiButton-startIcon": {
                  marginLeft: "0px",
                },
              }}
              onClick={handleFileClick}
              startIcon={
                <Box
                  component={"img"}
                  src={uploadCVIcon}
                  sx={{
                    display: { xs: "none", tablet: "block" },
                    height: "20px",
                    width: "26px",
                  }}
                />
              }
            >
              {cVName !== "No file chosen" ? "CV uploaded" : "upload my CV"}
            </Button>
            <Button
              variant="contained"
              color="precium"
              sx={{
                fontSize: theme.typography.fontSize.sm,
                fontWeight: theme.typography.Bold,
                minHeight: "45px",
                minWidth: { xs: "", md24: "150px" },
                // borderRadius:{xs:"0px",md24:"0 0 0 25px"},
                padding: { xs: "5px", md24: "0px" },
                borderRadius: "0 25px 0 0",
                textWrap: "nowrap",
                "& .MuiButton-startIcon": {
                  marginLeft: "0px",
                },
              }}
              startIcon={
                <Box
                  component={"img"}
                  src={scrapeLinkedinIcon}
                  sx={{
                    display: { xs: "none", tablet: "block" },
                    height: "20px",
                    width: "20px",
                  }}
                />
              }
            >
              grab LinkedIn
            </Button>
            <Typography
              sx={{
                fontSize: "14px",
                fontWeight: theme?.typography?.Regular,
                ml: 1,
                mt: "4px",
              }}
            >
              {cVName}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box sx={{ padding: "0px 32px 0px 32px !important" }}>
        <Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", md24: "row" },
              justifyContent: "space-between",
              mb: 3,
              gap: { xs: "20px", xl: "120px" },
            }}
          >
            <Box sx={{ width: "100%" }}>
              <InputLabel
                htmlFor="current_job_title"
                sx={{
                  color: "black",
                  paddingBottom: "2px",
                  fontSize: "14px",
                  fontWeight: theme?.typography?.Bold,
                }}
              >
                {i18n["myCV.currentJobTitleLabelReq"]}
              </InputLabel>
              <Box width={{ xs: "100%", xl: `${inputBoxWidth}px` }}>
                <CustomSelect
                  variant="createSelect"
                  onInputChange={handleOnSelectInputChange}
                  onChange={handleOnSelectChange}
                  onCreateOption={onCreateOption}
                  placeholder={i18n["myCV.currentJobTitle"]}
                  inputId="current_job_title_id"
                  name="current_job_title_id"
                  value={
                    basicData?.current_job_title_id?.label &&
                    basicData?.current_job_title_id?.value
                      ? {
                          label: basicData?.current_job_title_id?.label
                            ? basicData?.current_job_title_id?.label
                            : "",
                          value: basicData?.current_job_title_id?.value
                            ? basicData?.current_job_title_id?.value
                            : "",
                        }
                      : null
                  }
                  onMenuOpen={() => onMenuOpen("current_job_title_id")}
                  onMenuClose={onMenuClose}
                  option={titles?.map((item) => ({
                    value: item?.job_title_id,
                    label: item?.title,
                  }))}
                  isLoading={isLoading["current_job_title_id"]}
                  customStyle={{
                    menu: (provided) => ({
                      ...provided,
                      zIndex: 9999,
                    }),
                    menuList: (provided) => ({
                      ...provided,
                      "&::-webkit-scrollbar": {
                        width: "6px",
                        height: "6px",
                        borderRadius: "2.5px",
                        backgroundColor: "#efefef",
                      },
                      "&::-webkit-scrollbar-thumb": {
                        borderRadius: "2.5px",
                      },
                    }),
                    option: (
                      styles,
                      { data, isDisabled, isFocused, isSelected }
                    ) => {
                      return {
                        ...styles,
                        color: isSelected ? "#fff" : "black",
                        width: `100%`,
                        ":active": {
                          ...styles[":active"],
                        },
                      };
                    },
                    control: (baseStyles) => ({
                      ...baseStyles,
                      boxShadow: "none",
                      borderRadius: "10px",
                      height: `40px`,
                      border: errors?.find(
                        (error) => error?.key === "current_job_title_id"
                      )
                        ? `1px solid ${theme?.jobCard?.jobStatus?.Rejected?.main}`
                        : "1px solid #E0E0E0",
                      "&:hover": errors?.find(
                        (error) => error?.key === "current_job_title_id"
                      )
                        ? `1px solid ${theme?.jobCard?.jobStatus?.Rejected?.main}`
                        : "1px solid #E0E0E0",
                      width: `100%`,
                    }),
                  }}
                />
              </Box>
            </Box>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: { xs: "flex-start", md24: "flex-end" },
              }}
            >
              <InputLabel
                htmlFor="dream_job_title_id"
                sx={{
                  color: "black",
                  paddingBottom: "2px",
                  fontSize: "14px",
                  fontWeight: theme?.typography?.Bold,
                  width: { xs: "100%", xl: `${inputBoxWidth}px` },
                }}
              >
                {i18n["myCV.dreamNextJobLabelReq"]}{" "}
                <span
                  style={{
                    fontSize: theme.typography.fontSize.sm,
                    fontWeight: theme.typography.Regular,
                  }}
                >
                  (optional)
                </span>
              </InputLabel>
              <Box width={{ xs: "100%", xl: `${inputBoxWidth}px` }}>
                <CustomSelect
                  variant="createSelect"
                  onInputChange={handleOnSelectInputChange}
                  onChange={handleOnSelectChange}
                  onCreateOption={onCreateOption}
                  placeholder={i18n["myCV.dreamNextJobTitle"]}
                  inputId="dream_job_title_id"
                  name="dream_job_title_id"
                  value={
                    basicData?.dream_job_title_id?.label &&
                    basicData?.dream_job_title_id?.value
                      ? {
                          label: basicData?.dream_job_title_id?.label
                            ? basicData?.dream_job_title_id?.label
                            : "",
                          value: basicData?.dream_job_title_id?.value
                            ? basicData?.dream_job_title_id?.value
                            : "",
                        }
                      : null
                  }
                  onMenuOpen={() => onMenuOpen("dream_job_title_id")}
                  onMenuClose={onMenuClose}
                  option={titles?.map((item) => ({
                    value: item?.job_title_id,
                    label: item?.title,
                  }))}
                  isLoading={isLoading["dream_job_title_id"]}
                  customStyle={{
                    menu: (provided) => ({
                      ...provided,
                      zIndex: 9999,
                    }),
                    menuList: (provided) => ({
                      ...provided,
                      "&::-webkit-scrollbar": {
                        width: "6px",
                        height: "6px",
                        borderRadius: "2.5px",
                        backgroundColor: "#efefef",
                      },
                      "&::-webkit-scrollbar-thumb": {
                        borderRadius: "2.5px",
                      },
                    }),
                    option: (
                      styles,
                      { data, isDisabled, isFocused, isSelected }
                    ) => {
                      return {
                        ...styles,
                        color: isSelected ? "#fff" : "black",
                        ":active": {
                          ...styles[":active"],
                        },
                      };
                    },
                    control: (baseStyles) => ({
                      ...baseStyles,
                      boxShadow: "none",
                      borderRadius: "10px",
                      height: `40px`,

                      border: errors?.find(
                        (error) => error?.key === "dream_job_title_id"
                      )
                        ? `1px solid ${theme?.jobCard?.jobStatus?.Rejected?.main}`
                        : "1px solid #E0E0E0",
                      "&:hover": errors?.find(
                        (error) => error?.key === "dream_job_title_id"
                      )
                        ? `1px solid ${theme?.jobCard?.jobStatus?.Rejected?.main}`
                        : "1px solid #E0E0E0",
                      width: "100%",
                    }),
                  }}
                />
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", md24: "row" },
              justifyContent: "space-between",
              mb: 3,
              gap: { xs: `20px`, xl: "120px" },
            }}
          >
            <Box sx={{ width: "100%" }}>
              <InputLabel
                htmlFor="employment_type"
                sx={{
                  color: "black",
                  paddingBottom: "2px",
                  fontSize: "14px",
                  fontWeight: theme?.typography?.Bold,
                }}
              >
                {i18n["myCV.preferedWorkTypeLabelReq"]}
              </InputLabel>
              <SelectMenu
                clear={true}
                border={
                  errors?.find((error) => error?.key === "employment_type")
                    ? `1px solid ${theme?.jobCard?.jobStatus?.Rejected?.main}`
                    : "1px solid #E0E0E0"
                }
                borderRadius="10px"
                height="40px"
                width={{ xs: "100%", xl: `${inputBoxWidth}px` }}
                name="employment_type"
                value={basicData?.employment_type}
                onHandleChange={handleJobRoleChange}
                onFocus={() => {
                  if (roleTypes?.length === 0) {
                    getAllData("roleType");
                  }
                }}
                options={roleTypes}
                placeholder={"Select the work type you'd prefer"}
              />
            </Box>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: { xs: "start", md24: "flex-end" },
              }}
            >
              <InputLabel
                htmlFor="work_setup"
                sx={{
                  color: "black",
                  paddingBottom: "2px",
                  fontSize: "14px",
                  fontWeight: theme?.typography?.Bold,
                  width: { xs: "100%", xl: `${inputBoxWidth}px` },
                }}
              >
                {i18n["myCV.preferedWorkSetupReq"]}
              </InputLabel>
              <SelectMenu
                clear={true}
                border={
                  errors?.find((error) => error?.key === "work_setup")
                    ? `1px solid ${theme?.jobCard?.jobStatus?.Rejected?.main}`
                    : "1px solid #E0E0E0"
                }
                borderRadius="10px"
                height="40px"
                width={{ xs: "100%", xl: `${inputBoxWidth}px` }}
                name="work_setup"
                value={basicData?.work_setup}
                onHandleChange={handleWorkSetupChange}
                onFocus={() => {
                  if (workSetup?.length === 0) {
                    getAllData("workSetup");
                  }
                }}
                options={workSetup}
                placeholder={"Select the work setup you'd prefer"}
              />
            </Box>
          </Box>
          <Box sx={{ mb: 3, width: "100%" }}>
            <InputLabel
              htmlFor="industries"
              sx={{
                color: "black",
                paddingBottom: "2px",
                fontSize: "14px",
                fontWeight: theme?.typography?.Bold,
              }}
            >
              {i18n["myCV.industriesLabelReq"]}
            </InputLabel>

            <CustomSelect
              inputId={"industries"}
              name={"industries"}
              variant={"multiSelect"}
              closeMenuOnSelect={false}
              isMulti={true}
              isLoading={isLoading["industries"]}
              multiSelectOptions={industries?.map((item) => ({
                value: item?.industry_id,
                label: item?.name,
              }))}
              placeholder={"Select your preferred industry(ies) (min 1, max 5)"}
              onChange={(e, val) => handleOnSelectChange(e, val)}
              customStyle={{
                option: (
                  styles,
                  { data, isDisabled, isFocused, isSelected }
                ) => {
                  return {
                    ...styles,
                    color: isSelected ? "#fff" : "black",
                    ":active": {
                      ...styles[":active"],
                    },
                  };
                },
                control: (baseStyles) => ({
                  ...baseStyles,
                  boxShadow: "none",
                  borderRadius: "10px",
                  minHeight: `40px`,

                  border: errors?.find((error) => error?.key === "industries")
                    ? `1px solid ${theme?.jobCard?.jobStatus?.Rejected?.main}`
                    : "1px solid #E0E0E0",
                  "&:hover": errors?.find(
                    (error) => error?.key === "industries"
                  )
                    ? `1px solid ${theme?.jobCard?.jobStatus?.Rejected?.main}`
                    : "1px solid #E0E0E0",
                  width: `100%`,
                }),
              }}
              onInputChange={handleOnSelectInputChange}
              multiOnFocus={() => {
                if (industries?.length === 0) {
                  getAllData("industry");
                }
              }}
              value={getIndValue()}
              limitTags={5}
              initialOptions={initialIndustries?.map((item) => ({
                value: item?.industry_id,
                label: item?.name,
              }))}
            />
          </Box>

          <Box sx={{ mb: 3, width: "100%" }}>
            <InputLabel
              htmlFor="tags"
              sx={{
                color: "black",
                paddingBottom: "2px",
                fontSize: "14px",
                fontWeight: theme?.typography?.Bold,
              }}
            >
              {i18n["myCV.skillsLabelReq"]}
            </InputLabel>

            <CustomSelect
              inputId={"tags"}
              name={"tags"}
              variant={"createMultiSelect"}
              closeMenuOnSelect={false}
              isMulti={true}
              isLoading={isLoading["tags"]}
              multiSelectOptions={skills?.map((item) => ({
                value: item?.tag_id,
                label: item?.name,
              }))}
              placeholder={"Enter your top skills (min 3, max 10)"}
              onChange={(e, val) => handleOnSelectChange(e, val)}
              customStyle={{
                option: (
                  styles,
                  { data, isDisabled, isFocused, isSelected }
                ) => {
                  return {
                    ...styles,
                    color: isSelected ? "#fff" : "black",
                    ":active": {
                      ...styles[":active"],
                    },
                  };
                },
                control: (baseStyles) => ({
                  ...baseStyles,
                  boxShadow: "none",
                  borderRadius: "10px",
                  minHeight: `40px`,

                  border: errors?.find((error) => error?.key === "tags")
                    ? `1px solid ${theme?.jobCard?.jobStatus?.Rejected?.main}`
                    : "1px solid #E0E0E0",
                  "&:hover": errors?.find((error) => error?.key === "tags")
                    ? `1px solid ${theme?.jobCard?.jobStatus?.Rejected?.main}`
                    : "1px solid #E0E0E0",
                  width: `100%`,
                }),
              }}
              onInputChange={handleOnSelectInputChange}
              multiOnFocus={() => {
                if (skills?.length === 0) {
                  getAllData("skills");
                }
              }}
              value={getSkillValue()}
              limitTags={10}
              initialOptions={initialSkills?.map((item) => ({
                value: item?.tag_id,
                label: item?.name,
              }))}
            />
          </Box>
          <Box sx={{ mb: 3, width: "100%" }}>
            <InputLabel
              htmlFor="tools"
              sx={{
                color: "black",
                paddingBottom: "2px",
                fontSize: "14px",
                fontWeight: theme?.typography?.Bold,
              }}
            >
              {i18n["postAJob.toolsLableReq"]}
            </InputLabel>

            <CustomSelect
              inputId={"tools"}
              name={"tools"}
              variant={"createMultiSelect"}
              closeMenuOnSelect={false}
              isMulti={true}
              isLoading={isLoading["tools"]}
              multiSelectOptions={tools?.map((item) => ({
                value: item?.tool_id,
                label: item?.name,
              }))}
              placeholder={
                "Enter the software tools you are most proficient in (min 3, max 10)"
              }
              onChange={(e, val) => handleOnSelectChange(e, val)}
              customStyle={{
                option: (
                  styles,
                  { data, isDisabled, isFocused, isSelected }
                ) => {
                  return {
                    ...styles,
                    color: isSelected ? "#fff" : "black",
                    ":active": {
                      ...styles[":active"],
                    },
                  };
                },
                control: (baseStyles) => ({
                  ...baseStyles,
                  boxShadow: "none",
                  borderRadius: "10px",
                  minHeight: `40px`,

                  border: errors?.find((error) => error?.key === "tools")
                    ? `1px solid ${theme?.jobCard?.jobStatus?.Rejected?.main}`
                    : "1px solid #E0E0E0",
                  "&:hover": errors?.find((error) => error?.key === "tools")
                    ? `1px solid ${theme?.jobCard?.jobStatus?.Rejected?.main}`
                    : "1px solid #E0E0E0",
                  width: `100%`,
                }),
              }}
              onInputChange={handleOnSelectInputChange}
              multiOnFocus={() => {
                if (tools?.length === 0) {
                  getAllData("tools");
                }
              }}
              value={getToolValue()}
              limitTags={10}
              initialOptions={initialTools?.map((item) => ({
                value: item?.tool_id,
                label: item?.name,
              }))}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: { xs: "column", md24: "row" },
              mb: 3,
              gap: { xs: `20px`, xl: "120px" },
            }}
          >
            <Box sx={{ width: "100%" }}>
              <InputLabel
                htmlFor="qualification_level"
                sx={{
                  color: "black",
                  paddingBottom: "2px",
                  fontSize: "14px",
                  fontWeight: theme?.typography?.Bold,
                }}
              >
                {i18n["myCV.qualificationLevelLabelReq"]}
              </InputLabel>
              <SelectMenu
                clear={true}
                border={
                  errors?.find((error) => error?.key === "qualification_level")
                    ? `1px solid ${theme?.jobCard?.jobStatus?.Rejected?.main}`
                    : "1px solid #E0E0E0"
                }
                borderRadius="10px"
                height="40px"
                width={{ xs: "100%", xl: `${inputBoxWidth}px` }}
                name="qualification_level"
                value={basicData?.qualification_level}
                onHandleChange={handleQualification}
                onFocus={() => {
                  if (qualifications?.length === 0) {
                    getAllData("qualifications");
                  }
                }}
                options={qualifications}
                placeholder={"Select the level of your highest qualification"}
              />
            </Box>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-end",
              }}
            >
              <InputLabel
                htmlFor="currency_id"
                sx={{
                  color: "black",
                  paddingBottom: "2px",
                  fontSize: "14px",
                  fontWeight: theme?.typography?.Bold,
                  width: { xs: "100%", xl: `${inputBoxWidth}px` },
                }}
              >
                {i18n["myCV.preferedCurrencyLabelReq"]}
              </InputLabel>
              <SelectMenu
                clear={true}
                border={
                  errors?.find((error) => error?.key === "currency_id")
                    ? `1px solid ${theme?.jobCard?.jobStatus?.Rejected?.main}`
                    : "1px solid #E0E0E0"
                }
                borderRadius="10px"
                height="40px"
                width={{ xs: "100%", xl: `${inputBoxWidth}px` }}
                name="currency_id"
                value={basicData?.currency_id}
                onHandleChange={handleChange}
                onFocus={() => {
                  if (currency?.length === 0) {
                    getAllData("currency");
                  }
                }}
                options={currency}
                placeholder={"Select the currency you’d like to earn moola in"}
              />
            </Box>
          </Box>

          <Box sx={{ width: "100%" }}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "30px",
                height: "41px",
              }}
            >
              <InputLabel
                htmlFor="salary"
                sx={{
                  color: "black",
                  fontSize: "14px",
                  fontWeight: theme?.typography?.Bold,
                }}
              >
                {basicData.employment_type === "freelance"
                  ? i18n["myCV.requiredSalaryRangeLabel2"]
                  : i18n["myCV.requiredSalaryRangeLabel"]}
              </InputLabel>
              <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
                <Typography
                  sx={{ fontSize: "14px", fontWeight: theme.typography.Bold }}
                >
                  single salary
                </Typography>
                <ToggleSwitch
                  color="discovery"
                  id="salary_value"
                  checked={salaryRangeValue}
                  onChange={(event) => handleSalaryValue(event)}
                />
                <Typography
                  sx={{ fontSize: "14px", fontWeight: theme.typography.Bold }}
                >
                  salary range
                </Typography>
              </Box>
            </Box>
            <SalarySlider
              basicData={basicData}
              rangeValue={rangeValue}
              handleRangeSliderChange={handleRangeSliderChange}
              handleRangeSliderChange2={handleRangeSliderChange2}
              rangeValueHandler={rangeValueHandler}
              rangeValueHandler2={rangeValueHandler2}
              salaryRange={salaryRange}
              errors={errors}
              salaryRangeValue={salaryRangeValue}
              singleValueSlider={singleValueSlider}
              singleValueSlider2={singleValueSlider2}
              singleSalaryValue={singleSalaryValue}
              color={"redButton100"}
              name={"salary"}
              jobRoleType={basicData?.employment_type}
              sx={{
                width: "98%",
                ml: 1,
                marginTop: "30px",
                color: "transparent",
                "& .MuiSlider-rail": {
                  backgroundColor: theme?.palette?.eyeview100?.main,
                  height: "10px",
                  border:
                    rangeValue?.at(0) === 0 && rangeValue?.at(1) === 0
                      ? `1px solid ${theme?.jobCard?.jobStatus?.Rejected?.main}`
                      : "1px solid #E0E0E0",
                },
                "& .MuiSlider-track": {
                  backgroundColor: theme?.palette?.discovery?.main,
                  borderRadius: "3px",
                  height: "10px",
                },
                "& .MuiSlider-thumb": {
                  backgroundColor: theme?.palette?.discovery?.contrastText,
                  borderRadius: "6px",
                },
                "& .MuiSlider-markLabel": {
                  fontSize: "12px",
                },
                "& .MuiSlider-valueLabel.MuiSlider-valueLabelOpen": {
                  backgroundColor: "#EBECF3",
                },
                "& .MuiSlider-valueLabel:before": {
                  display: "none",
                },
                "& .MuiSlider-valueLabel": {
                  color: "#000",
                },
              }}
            />
          </Box>
          <Box sx={{ width: "100%" }}>
            <InputLabel
              htmlFor="experience"
              sx={{
                height: "41px",
                color: "black",
                paddingBottom: "2px",
                fontSize: "14px",
                fontWeight: theme?.typography?.Bold,
              }}
            >
              {i18n["myCV.yearsOfExperienceLabel"]}
            </InputLabel>
            <Slider
              name="experience_id"
              aria-label="Custom marks"
              value={basicData?.experience_id}
              color="blueButton800"
              getAriaValueText={textValue}
              min={0}
              max={20}
              step={1}
              onChange={expHandleChange}
              valueLabelDisplay="on"
              valueLabelFormat={textValue}
              marks={
                width < 768
                  ? [EXPERIENCE?.[0], EXPERIENCE?.[EXPERIENCE?.length - 1]]
                  : EXPERIENCE
              }
              sx={{
                width: "98%",
                marginTop: "30px",
                color: "transparent",
                "& .MuiSlider-valueLabelLabel": {},
                "& .MuiSlider-rail": {
                  backgroundColor: theme?.palette?.eyeview100?.main,
                  height: "10px",
                  border: errors?.find((error) => error?.key === "experience")
                    ? `1px solid ${theme?.jobCard?.jobStatus?.Rejected?.main}`
                    : "1px solid #E0E0E0",
                },
                "& .MuiSlider-track": {
                  backgroundColor: theme?.palette?.experience?.main,
                  borderRadius: "3px",
                  height: "10px",
                },
                "& .MuiSlider-markLabel": {
                  fontSize: "12px",
                },
                "& .MuiSlider-thumb": {
                  backgroundColor: theme?.palette?.experience?.contrastText,
                  borderRadius: "6px",
                },
                "& .MuiSlider-valueLabel.MuiSlider-valueLabelOpen": {
                  backgroundColor: "#EBECF3",

                  left: basicData?.experience_id === 0 ? "0px !important" : "",
                  right:
                    basicData?.experience_id === 20 ? "0px !important" : "",
                },
                "& .MuiSlider-valueLabel:before": {
                  display: "none",
                },
                "& .MuiSlider-valueLabel": {
                  color: "#000",
                },
              }}
            />
          </Box>
          <Box sx={{ width: "100%" }}>
            <InputLabel
              htmlFor="notice_period_id"
              sx={{
                height: "41px",
                color: "black",
                paddingBottom: "2px",
                fontSize: "14px",
                fontWeight: theme.typography.Bold,
              }}
            >
              {i18n["myCV.noticePeriodLabel"]}
            </InputLabel>
            <Slider
              // orientation="horizontal"
              name="notice_period_id"
              aria-label="Custom marks"
              value={basicData?.notice_period_id}
              color="yellowButton100"
              getAriaValueText={noticeValue}
              onChange={noticeHandleChange}
              valueLabelDisplay="on"
              valueLabelFormat={noticeValue}
              // marks={NOTICEPERIOD}
              marks={
                width < 768
                  ? [
                      NOTICEPERIOD?.[0],
                      NOTICEPERIOD?.[NOTICEPERIOD?.length - 1],
                    ]
                  : NOTICEPERIOD
              }
              min={0}
              max={7}
              step={1}
              sx={{
                width: "98%",
                marginTop: "30px",
                color: "transparent",
                "& .MuiSlider-valueLabelLabel": {},
                "& .MuiSlider-rail": {
                  backgroundColor: theme.palette.eyeview100.main,
                  height: "10px",
                  border: errors?.find(
                    (error) => error?.key === "notice_period_id"
                  )
                    ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
                    : "1px solid #E0E0E0",
                },

                "& .MuiSlider-track": {
                  backgroundColor: theme.palette.noticePeriod.main,
                  borderRadius: "3px",
                  height: "10px",
                },
                "& .MuiSlider-thumb": {
                  backgroundColor: theme.palette.noticePeriod.contrastText,
                  borderRadius: "6px",
                },
                "& .MuiSlider-valueLabel.MuiSlider-valueLabelOpen": {
                  backgroundColor: "#EBECF3",
                  left:
                    basicData?.notice_period_id === 0 ? "0px !important" : "",
                  right:
                    basicData?.notice_period_id === 7 ? "0px !important" : "",
                },
                "& .MuiSlider-valueLabel:before": {
                  display: "none",
                },
                "& .MuiSlider-valueLabel": {
                  color: "#000",
                },
                "& .MuiSlider-markLabel": {
                  fontSize: "12px",
                  textWrap: " wrap",
                  width: "70px",
                  textAlign: "center",
                },
              }}
            />
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            pt: 5,
          }}
        >
          <Button
            onClick={() => {
              handleSaveButton("lockItIn", false);
            }}
            sx={{
              boxShadow: 0,
              padding: "0px",
              fontSize: "14px",
              width: "150px",
              height: "60px",
              borderRadius: "10px 0px 0px 0px",
              backgroundColor: theme.palette.lockItIn.main,
              color: "black",
              "&:hover": {
                backgroundColor: theme.palette.lockItIn.main,
              },
            }}
            variant="contained"
          >
            {"lock it in"}
          </Button>
          <Button
            color="nandos"
            onClick={() => {
              handleSaveButton("workLife", false);
            }}
            sx={{
              padding: "0px",
              boxShadow: 0,
              fontSize: "14px",
              width: "150px",
              height: "60px",
              borderRadius: "0px 10px 0px 0px",
              // backgroundColor: theme.palette.nextButton.main,
              // "&:hover": {
              //   backgroundColor: theme.palette.nextButton.main,
              // },
            }}
            endIcon={
              <Box
                component={"img"}
                src={nextIcon}
                sx={{
                  height: "25px",
                  width: "25px",
                }}
              />
            }
            variant="contained"
          >
            {"work life"}
          </Button>
        </Box>
        <UploadCV
          handleClose={() => setOpenCvBox((prev) => !prev)}
          show={openCvBox}
          setOpenCvBox={setOpenCvBox}
          handleFileClick={handleFileClick}
          changeStep={changeStep}
          handleSaveButton={handleSaveButton}
        />
        <CompleteCV
          handleOpen={handlesaveAndExitDialog}
          show={saveAndExitDialog}
          setNextDialog={setNextDialog}
          setsaveAndExitDialog={setsaveAndExitDialog}
        />
        <NextDialog
          handleOpen={handleOpenNext}
          show={nextDialog}
          changeStep={changeStep}
          handleComplete={handleComplete}
        />
      </Box>
    </>
  );
}
