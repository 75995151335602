import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getApi, postApi } from "../../utils/Apis";
import { setLoading } from "../configSlice";
const initialState = {};
export const getAllQuestions = createAsyncThunk(
  "getAllQuestions",
  async (payload, { dispatch }) => {
    dispatch(setLoading(true));
    const { data } = await getApi(
      "/candidate/getquestion?job_id=" + payload,
      true
    );
    dispatch(setLoading(false));
    return data;
  }
);
export const getEditQuestionAndAnswer = createAsyncThunk(
  "getEditQuestionAndAnswer",
  async (payload, { dispatch }) => {
    dispatch(setLoading(true));
    const { data } = await getApi(
      "/candidate/QuestionsAnswers?job_id=" + payload,
      true
    );
    dispatch(setLoading(false));
    return data;
  }
);

export const postUpdatedAnswers = createAsyncThunk(
  "postUpdatedAnswers",
  async (payload, { dispatch }) => {
    dispatch(setLoading(true));
    const { data } = await postApi("/candidate/updateAnswers", payload, true);
    dispatch(setLoading(false));
    return data;
  }
);

export const getAllQuestionsWithoutLogin = createAsyncThunk(
  "getAllQuestionsWithoutLogin",
  async (payload, { dispatch }) => {
    // dispatch(setLoading(true));
    const { data } = await getApi(
      "/candidate/getquestionwithoutlogin?job_id=" + payload,
      true
    );
    // dispatch(setLoading(false));
    return data;
  }
);
export const postAnswers = createAsyncThunk(
  "postAnswers",
  async (payload, { dispatch }) => {
    // dispatch(setLoading(true));
    const { data } = await postApi("/candidate/applywithlogin", payload, true);
    // dispatch(setLoading(false));
    return data;
  }
);
export const postAnswersWithoutLogin = createAsyncThunk(
  "postAnswersWithoutLogin",
  async (payload, { dispatch }) => {
    // dispatch(setLoading(true));
    const { data } = await postApi(
      "/candidate/applywithoutlogin",
      payload,
      true
    );
    // dispatch(setLoading(false));
    return data;
  }
);
export const getQuestions = createSlice({
  name: "configquestion",
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action?.payload;
    },
    setAlert: (state, action) => {
      state.alert.show = action?.payload?.show;
      state.alert.type = action?.payload?.type;
      state.alert.msg = action?.payload?.msg;
    },
  },
});
// Action creators are generated for each case reducer function
export default getQuestions.reducer;
