import React, { useEffect, useRef } from "react";
import { Box, Typography } from "@mui/material";
import raceBlack from "../../assets/Characters/Race/crayon_race_black.svg";
import raceColoured from "../../assets/Characters/Race/crayon_race_coloured.svg";
import raceIndian from "../../assets/Characters/Race/crayon_race_indian.svg";
import raceWhite from "../../assets/Characters/Race/crayon_race_white.svg";
import { useQuery } from "../../utils/Common";
import { CheckCircle } from "@mui/icons-material";
import { useParams } from "react-router-dom";

export default function Race({
  setSkinColor,
  id,
  handleClosePopover,
  handleFilterSelection,
  setIsFilterApplied,
  getTalentMyJobStatusCount,
  setBasicData,
  setSelected,
  selected,
  onFilterSelection,
  isClear,
  setIsClear,
  setOpenPopovers,
  openPopovers,
  setSelectedBtn,
  panelData,
}) {
  const query = useQuery();
  const boxRef = useRef();
  const { jobId } = useParams();
  let skinValue = {
    white: 0,
    coloured: 1,
    indian: 2,
    black: 3,
  };
  const handleSkin = async (value, skinid) => {
    let skins = skinValue[value];

    let updateselected = selected;
    onFilterSelection();
    if (selected?.includes(skinid)) {
      const replacedValue = query
        ?.get("skinz")
        ?.split(",")
        ?.filter((item) => item !== value);

      handleFilterSelection(
        "skinz",
        replacedValue?.length > 0 ? replacedValue : "skinz"
      );
      setBasicData((prev) => ({
        ...prev,
        skin_id: value
          ? selected?.filter((item) => item !== skinid)?.join(",")
          : "",
      }));
      setSelected(selected?.filter((item) => item !== skinid));
      handleClosePopover(id);
      await getTalentMyJobStatusCount(jobId);
      return;
    } else {
      setIsFilterApplied(true);

      setSkinColor(
        skins == query?.get("skinz") ? "" : (prev) => [...prev, skins]
      );
      updateselected?.push(skins);
      setSelected([...updateselected]);
    }
    setBasicData((prev) => ({
      ...prev,
      skin_id: value ? updateselected?.join(",") : "",
    }));

    handleFilterSelection(
      "skinz",
      value && query?.get("skinz")
        ? `${query?.get("skinz")},${value}`
        : value || "skinz"
    );

    handleClosePopover(id);
    await getTalentMyJobStatusCount(jobId);
  };

  const handleClickOutside = (event) => {
    if (
      boxRef?.current &&
      !boxRef?.current?.contains(event?.target) &&
      selected?.length === 0
    ) {
      const initialOpenState = {};
      panelData?.forEach((item) => {
        initialOpenState[item?.id] = false;
      });
      setOpenPopovers(initialOpenState);
      setSelectedBtn([1111]);
    }
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (isClear) {
      setSelected([]);
      setIsClear(false);
    }
    if (query.get("skinz")) {
      let skinzValue = query.get("skinz").split(",");
      let skinzID = skinzValue?.map((item) => {
        return skinValue[item];
      });
      setSelected(skinzID);
    }
  }, [isClear, query.get("skinz")]);

  return (
    <Box
      ref={boxRef}
      sx={{
        width: 118,
        padding: "16px 25px 16px 5px",
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "flex-start",
        gap: "15px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row-reverse",
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "flex-end",
            cursor: "pointer",
          }}
          onClick={() => {
            handleSkin("white", 0);
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Box component={"img"} height={50} width={50} src={raceWhite} />{" "}
            <Typography
              sx={{
                color: "black",
                fontSize: "12px",
                fontWeight: 500,
              }}
            >
              White
            </Typography>
          </Box>
          <Box sx={{ width: "20px" }}>
            {selected?.includes(0) && (
              <CheckCircle
                sx={{
                  fontSize: 20,
                }}
                color="checkColor"
              />
            )}
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",

            alignItems: "flex-end",
            cursor: "pointer",
          }}
          onClick={() => {
            handleSkin("coloured", 1);
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Box component={"img"} height={50} width={50} src={raceColoured} />{" "}
            <Typography
              sx={{
                color: "black",
                fontSize: "12px",
                fontWeight: 500,
              }}
            >
              Coloured
            </Typography>
          </Box>
          <Box sx={{ width: "20px" }}>
            {selected?.includes(1) && (
              <CheckCircle
                sx={{
                  fontSize: 20,
                }}
                color="checkColor"
              />
            )}
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row-reverse",
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",

            alignItems: "flex-end",
            cursor: "pointer",
          }}
          onClick={() => {
            handleSkin("indian", 2);
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Box component={"img"} height={50} width={50} src={raceIndian} />{" "}
            <Typography
              sx={{
                color: "black",
                fontSize: "12px",
                fontWeight: 500,
              }}
            >
              Indian
            </Typography>
          </Box>
          <Box sx={{ width: "20px" }}>
            {selected?.includes(2) && (
              <CheckCircle
                sx={{
                  fontSize: 20,
                }}
                color="checkColor"
              />
            )}
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "flex-end",
            cursor: "pointer",
          }}
          onClick={() => {
            handleSkin("black", 3);
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Box component={"img"} height={50} width={50} src={raceBlack} />{" "}
            <Typography
              sx={{
                color: "black",
                fontSize: "12px",
                fontWeight: 500,
              }}
            >
              Black
            </Typography>
          </Box>
          <Box sx={{ width: "20px" }}>
            {selected?.includes(3) && (
              <CheckCircle
                sx={{
                  fontSize: 20,
                }}
                color="checkColor"
              />
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
