import React from "react";
import { Box, Button, Typography, useTheme } from "@mui/material";
import blueHalfSmile from "../../../../assets/Characters/ResumeUpload.svg";
import CustomDialog from "../../../common/CustomDialog";
import CloseIcon from "../../../common/CloseIcon";

export default function UploadCV({
  show,
  handleClose,
  setOpenCvBox,
  handleFileClick,
  changeStep,
  handleSaveButton,
}) {
  const theme = useTheme();

  return (
    <CustomDialog
      show={show}
      hideButton={false}
      dialogWidth="xs"
      showFooter={false}
      onDialogClose={handleClose}
      padding={0}
    >
      <Box
        sx={{
          textAlign: "center",
          display: "flex",
          flexDirection: "column",
          // gap: 3,
        }}
      >
        <Box
          component={"img"}
          src={blueHalfSmile}
          sx={{ width: 101, height: 111,margin: "auto",marginY:"15px"}}
        />
        <Box
          sx={{
            cursor: "pointer",
          }}
          onClick={() => {
            handleClose();
          }}
        >
          <CloseIcon />
        </Box>
        <Typography
          sx={{
            fontSize: "25px",
            fontWeight: theme.typography.Bold,
            paddingBottom:"15px",
            // paddingY:"15px"
          }}
        >
          CV, please.
        </Typography>
        <Typography
          sx={{
            fontSize: "18px",
            fontWeight: theme.typography.Bold,
            paddingBottom:"15px",
            paddingX:"15px"
          }}
        >
     Traditional CVs are very 2000s, but let’s kickoff with what you’ve got and take it from there.
        </Typography>
        <Typography
          sx={{
            fontSize: "18px",
            fontWeight: theme.typography.Regular,
              paddingBottom:"25px",
              paddingX:"15px"

          }}
        >
      We know it’s frustrating giving us your CV and then inputting data. CV scraping is shaky at best and we use this standardised data to match you to your dream job!
        </Typography>
        <Box>
          <Button
            variant="contained"
            color="lockItIn"
            sx={{
              borderRadius: "0 0 0 25px",
              width: "50%",
              height: "57px",
              color: "black",
            }}
            onClick={() => {
              setOpenCvBox(false);
              // handleSaveButton("workLife", true);
            }}
          >
            skip for now
          </Button>
          <Button
            variant="contained"
            sx={{
              borderRadius: "0 0 25px 0",
              width: "50%",
              height: "57px",
            }}
            color="redButton"
            onClick={() => {
              handleFileClick();
            }}
          >
            upload CV
          </Button>
        </Box>
      </Box>
    </CustomDialog>
  );
}
