import { Box, Button, Modal, Typography } from "@mui/material";
import React from "react";
import { useTheme } from "@emotion/react";
import CompanyModalPopupModal from "./bot.svg";
import { useNavigate } from "react-router-dom";
import CloseIcon from "../../common/CloseIcon";

const PriceIsRightModal = ({ open, setOpen, handleReviewLater }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    height: "auto",
    width: { xs: "360px", tablet: "450px" },
    bgcolor: "background.paper",
    borderRadius: "25px",
    boxShadow: "none !important",
    "&:focus-visible": {
      outline: "none",
    },
  };

  const navigateGoLearnMore = () => {
    window.open("https://www.crayondemo.co.za/Pricing/", { target: "_blank" });
    // setOpen()
  };

  return (
    <Modal
      open={open}
      onClose={setOpen}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      {/* <CloseIcon/> */}

      <Box sx={style}>
        <Box sx={{ position: "relative" }}>
          <CloseIcon onClick={setOpen} />
        </Box>
        <Box
          sx={{
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
            // gap: 3,
            width: "100%",
          }}
        >
          <Box
            sx={{
              padding: { xs: "0 10px", tablet: "0 15px" },
            }}
          >
            <Box
              component={"img"}
              src={CompanyModalPopupModal}
              sx={{ width: 150, height: 125, marginTop: "20px" }}
            />
            <Typography
              sx={{
                fontSize: "20px",
                fontWeight: theme.typography.Bold,
                lineHeight: "27px",
                marginBottom: "20px",
              }}
            >
              The price is right!
            </Typography>
            <Typography
              sx={{
                fontSize: "16px",
                fontWeight: theme.typography.Bold,
                marginBottom: "20px",
              }}
            >
              It’s free to post a job, with success fees starting from 5% of
              annual CTC.
            </Typography>
            <Typography
              sx={{
                fontSize: "14px",
                fontWeight: theme.typography.Regular,
                marginBottom: "20px",
              }}
            >
              We’ve had to prioritise the page rollout, and asking you for money
              wasn’t top of the list. Our pricing page will be dropping soon,
              but for now, you can learn more about the different offerings and
              pricing at our product demo site!
            </Typography>
          </Box>
          <Box sx={{ width: "100%" }}>
            <Button
              sx={{
                width: "100%",
                borderRadius: "0px 0px 25px 25px",
                height: "60px",
                fontWeight: theme.typography.Bold,
                fontSize: "14px",
              }}
              variant="contained"
              onClick={() => {
                navigateGoLearnMore();
              }}
              color="nandos"
            >
              learn more
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default PriceIsRightModal;
