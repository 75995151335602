import React, { useEffect, useRef, useState } from "react";
import Template from "./Template";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getJobDetail } from "../../redux/guest/jobsSlice";
import { getDecodedToken, getToken } from "../../utils/Common";
import { setAlert, setLoading } from "../../redux/configSlice";
import { ALERT_TYPE, ERROR_MSG } from "../../utils/Constants";
import CommonLoader from "../common/CommonLoader";
import Loader from "../common/Loader";
import { useSelector } from "react-redux";

export default function ShareJob() {
  const dispatch = useDispatch();
  const { id } = useParams();
  const decodedToken = getDecodedToken(getToken());
  const templateRef = useRef(null);
  const [jobInfo, setJobInfo] = useState();
  // const [isLoading, setIsLoading] = useState(true);
  const isLoading = useSelector((state) => state.config.loading)

  const handleCardClick = async () => {
    // setIsLoading(true);
    dispatch(setLoading(true))
    try {
      const { payload } = await dispatch(
        getJobDetail({
          job_id: id,
          user_id: "",
          decodedToken: decodedToken,
        })
      );
      if (payload?.status === "success") {
        setJobInfo(payload?.data);
      }
      // setIsLoading(false);
    dispatch(setLoading(false))
    } catch (error) {
      // setIsLoading(false);
    dispatch(setLoading(false))
      dispatch(
        setAlert({
          show: true,
          type: ALERT_TYPE?.ERROR,
          msg: ERROR_MSG,
        })
      );
    }
  };

  useEffect(() => {
    handleCardClick();
  }, []);

  return   <Template
  title={jobInfo?.title}
  location={`${jobInfo?.town?.name} ${jobInfo?.town?.country?.name}`}
  company={jobInfo?.employer_profile?.company_name}
  salary={`${jobInfo?.salary?.currency?.symbol}${jobInfo?.salary?.min} to ${
    jobInfo?.salary?.currency?.symbol
  }${jobInfo?.salary?.max} ${
    jobInfo?.type === "freelance" ? "per hour" : "per month"
  }`}
  reference={templateRef}
  onClick={""}
/>
  // !isLoading && 

}
