import { Box, useTheme } from "@mui/material";
import React, { useEffect } from "react";
import { useState } from "react";
import CircularSlider from "react-circular-slider-svg";

const CircularRangeSlider = ({
  minvalue,
  maxValue,
  prepandToValue,
  appendToValue,
  arcColor,
  arcBackgroundColor,
  onChange,
  value,
  setBountyAmount,
  bountyAmount,
  id,
  suggestedValue,
}) => {
  const CHANGE_THRESHOLD = 10;
  const theme = useTheme();
  const [value1, setValue1] = useState();
  const [Bounty, setBounty] = useState();

  useEffect(() => {
    setValue1(value === 0 ? 0 / maxValue : (value * 100) / maxValue);
    setBounty(
      value === 0
        ? 0 / maxValue
        : (Math.round((value * maxValue) / 100) * 100) / maxValue
    );
  }, [value, maxValue]);

  const roundToNearest = (num) => {
    let roundToNearestvalue;
    if (maxValue <= 5000) {
      roundToNearestvalue = 10;
    } else if (maxValue <= 40000) {
      roundToNearestvalue = 50;
    } else {
      roundToNearestvalue = 100;
    }
    const FixedNum = Number((num / roundToNearestvalue).toFixed(4));
    return Math.ceil(FixedNum) * roundToNearestvalue;
  };

  const setValue1WithLimits = (currentValue, newValue, limit) => {
    const actualValue = (newValue * maxValue) / 100;
    const roundedValue = roundToNearest(actualValue);

    const finalValue = Math.min(roundedValue, maxValue);
    const finalValuePercentage = (finalValue * 100) / maxValue;

    let temp;

    if (finalValuePercentage > currentValue + limit) {
      temp = Math.min(
        roundToNearest((currentValue * maxValue) / 100),
        maxValue
      );
      setValue1(currentValue);
      setBountyAmount({ ...bountyAmount, [id]: temp });
    } else if (finalValuePercentage < currentValue - limit) {
      temp = Math.min(
        roundToNearest((currentValue * maxValue) / 100),
        maxValue
      );
      setValue1(currentValue);
      setBountyAmount({ ...bountyAmount, [id]: temp });
    } else {
      temp = Math.min(
        roundToNearest((finalValuePercentage * maxValue) / 100),
        maxValue
      );
      setValue1(finalValuePercentage);
      setBountyAmount({
        ...bountyAmount,
        [id]: temp,
      });
    }
    setBounty(temp);
    if (finalValuePercentage === 100 && newValue === 100 && currentValue < 90) {
      setBounty(0);
    }
  };

  return (
    <Box className="CircularSlider" sx={{ position: "relative" }}>
      <CircularSlider
        handle1={{
          value: value1,
          onChange: (v) => setValue1WithLimits(value1, v, CHANGE_THRESHOLD),
        }}
        angleType={{
          direction: "cw",
          axis: "+y",
        }}
        trackWidth={8}
        arcColor={arcColor}
        arcBackgroundColor={arcBackgroundColor}
        startAngle={0}
        endAngle={348}
      />
      <Box
        className="SliderValue-container"
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <span
          className="SliderValue"
          style={{
            fontSize: "20px",
            fontWeight: theme.typography.Bold,
            whiteSpace: "nowrap",
          }}
        >
          {prepandToValue && `${prepandToValue}`}
          {/* {value === 0 ? 0 : Math.round((value1 * maxValue) / 100)} */}
          {value === 0 ? 0 : Bounty}
          {appendToValue && `${appendToValue}`}
        </span>
        <Box
          className="label"
          sx={{
            fontSize: "12px",
            fontWeight: theme.typography.Bold,
          }}
        >
          Bounty
        </Box>
      </Box>
    </Box>
  );
};

export default CircularRangeSlider;
