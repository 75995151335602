import { Box, Button, Typography, useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import CancelIcon from "../../../../assets/Padding Excluded/Black_Trash_Delete_2.svg";

import myVideoHeaderLogo from "../../../../assets/Cam/MyVideoIcon.svg";
import oneImage from "../../../../assets/CrayBotIcons/Crayon Craybot QA Character_1.svg";
import twoImage from "../../../../assets/CrayBotIcons/Crayon Craybot QA Character_2.svg";
import threeImage from "../../../../assets/CrayBotIcons/Crayon Craybot QA Character_3.svg";
import fourImage from "../../../../assets/CrayBotIcons/Crayon Craybot QA Character_4.svg";
import fiveImage from "../../../../assets/CrayBotIcons/Crayon Craybot QA Character_5.svg";
import CloseIcon from "../../../common/CloseIcon";
import VideoIcon from "../../../../assets/Cam/JobVideoIcon.svg";

const CounterDialog = ({ nextStep, name, page }) => {
  const theme = useTheme();
  const [count, setCount] = useState(5);

  useEffect(() => {
    const interval = setInterval(() => {
      if (count > 1) {
        setCount(count - 1);
      } else {
        nextStep(3);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [count]);

  const getImageSrc = (number) => {
    switch (number) {
      case 1:
        return oneImage;
      case 2:
        return twoImage;
      case 3:
        return threeImage;
      case 4:
        return fourImage;
      case 5:
        return fiveImage;

      default:
        return null;
    }
  };
  return (
    <>
      <Box sx={{ display: "flex", justifyContent: "space-between",position:"relative" }}>
        <Button
          variant="contained"
          color={page==="jobVideo"?"peachPayment":"precium"}
          sx={{
            borderRadius: "25px 0 10px 0",
            width: "150px",
            height: "45px",
            fontSize: "14px",

            fontWeight: theme.typography.Bold,
            gap: "10px",
          }}
        >
          <Box
            component={"img"}
            src={page==="jobVideo"?VideoIcon:myVideoHeaderLogo}
            sx={{
              height: "20px",
              width: "24px",
            }}
          />
          {name} video
        </Button>
        <Box
          sx={{
            cursor: "pointer",
          }}
          onClick={() => {nextStep(1);}}
        >
          <CloseIcon />
        </Box>
      </Box>
      <Typography
          sx={{
            fontSize: "20px",
            fontWeight: theme.typography.Bold,
            textAlign: "center",
            marginBottom: "30px",
            marginTop:"30px"
          }}
        >
          Get ready…
        </Typography>
      <Box
        sx={{
          width: "70%",
          margin: "auto",
          marginTop: "30px",
          flexGrow: 1,
          flexDirection: "column",
          alignItems: "center",
          display: "flex",
          flex:{xs:"1", lg1440:""},
          justifyContent:{tb:"center",lg1440:""}
        }}
      >
       
        <Box
          sx={{
            border: "2px solid #C9CAD8",
            borderRadius: "25px",
            paddingTop: {xs:"30px",tb:"55px"},
            paddingBottom: {xs:"30px",tb:"55px"},
            paddingX: {xs:"40px",tb:"87px"},
          }}
        >
          <Box
            component={"img"}
            src={getImageSrc(count)}
            sx={{
              //  width: "259px", height: "300px",
              width: "218px",
              height: {
                xs: "200px",
                xs360: "200px",
                xs390: "200px",
                md: "250px",
                md24: "250px",
                lgm: "250px",
              },
              maxHeight: "250px",
              // margin: "80px 0 138px 0"
            }}
          />
        </Box>
        <Typography
          sx={{
            fontSize: "14px",
            textAlign: "center",
            fontWeight: theme.typography.Regular,
            marginTop: "40px",
          }}
        >
          Recording starts after the countdown!
        </Typography>
      </Box>
      <Box
        sx={{
          margin: "auto",
          width: "100%",
        }}
      >
        <Button
          variant="contained"
          color="redButton100"
          sx={{
            borderRadius: "0 0 25px 25px",
            width: "100%",
            height: "60px",
            fontSize: "14px",
            fontWeight: theme.typography.Bold,
          }}
          // startIcon={
          //   <Box component={"img"} src={CancelIcon} sx={{ height: 15 }} />
          // }
          onClick={() => nextStep(1)}
        >
          cancel recording
        </Button>
      </Box>
    </>
  );
};

export default CounterDialog;
