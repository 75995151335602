import React from "react";
import NameInfo from "./NameInfo";
import { DataGrid } from "@mui/x-data-grid";

export default function MemberInfo({ rows, role }) {
  const columns = [
    {
      field: "name",
      headerName: "Name",
      width: 350,
      renderCell: (params) => {
        return (
          <div>
            <NameInfo
              avatarInitial={params?.value?.avatarInitial}
              name={params?.value?.fullname}
              email={params?.value?.email}
              userID={params?.row?.userId}
              role={role}
            />
          </div>
        );
      },
    },
  ];
  return (
    <div style={{ height: `calc(100vh - 400px)`, width: "100%" }}>
      <DataGrid
        rows={rows}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 25 },
          },
        }}
        pageSizeOptions={[25, 50]}
        checkboxSelection
      />
    </div>
  );
}
