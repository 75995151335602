import { useState, useEffect } from "react";
import { useTheme } from "@mui/material/styles";
import Button from "@mui/material/Button";
import { alpha, styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { Avatar, CircularProgress } from "@mui/material";
import Box from "@mui/material/Box";
import Switch from "@mui/material/Switch";
import { useDispatch } from "react-redux";
import {
  setAlert,
  setLoading,
} from "../../../redux/employer/employerJobsConfigSlice";
import { ALERT_TYPE, ERROR_MSG } from "../../../utils/Constants";
import {
  assignJobs,
  getAllTeamMembersList,
} from "../../../redux/employer/myTeams";
import InfiniteScroll from "react-infinite-scroll-component";
import { Link } from "react-router-dom";
import jwt_decode from "jwt-decode";
import { getColorByIndex, getToken } from "../../../utils/Common";
import { ToggleSwitch } from "../../../utils/CommonComponent";
import locale from "../../../i18n/locale";

export default function ButtomMenu({ jobId, closeFunc, newCardWidth }) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const i18n = locale.en;

  const [teamMembers, setTeamMembers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [assignedJobText, setAssignedJobText] = useState("");

  const token = getToken();
  let decodedToken;
  if (token) {
    decodedToken = jwt_decode(token);
  }
  const handleGetTeamMembersDetails = async (companyId) => {
    setIsLoading(true);
    try {
      const { payload } = await dispatch(
        getAllTeamMembersList({ job_id: jobId })
      );
      if (payload?.status === "success") {
        setTeamMembers((prev) => [...prev, ...payload?.data]);
        setIsLoading(false);
      } else if (payload?.status === "fail") {
        setAssignedJobText(payload?.message);
        setIsLoading(false);
      } else {
        setIsLoading(false);
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE?.ERROR,
            msg: ERROR_MSG,
          })
        );
      }
    } catch (error) {
      setIsLoading(false);
      dispatch(setLoading(false));
      dispatch(
        setAlert({
          show: true,
          type: ALERT_TYPE?.ERROR,
          msg: ERROR_MSG,
        })
      );
    }
  };

  const handleJobAccess = async (event, user_id) => {
    try {
      const data = {
        userid: user_id,
        jobid: jobId,
        flag: event?.target?.checked,
      };
      const { payload } = await dispatch(assignJobs(data));
      if (payload?.status === "success") {
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE?.SUCCESS,
            msg: "Job assigned successfully",
          })
        );
      } else {
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE?.SUCCESS,
            msg: payload?.error,
          })
        );
      }
    } catch (error) {
      dispatch(
        setAlert({
          show: true,
          type: ALERT_TYPE?.SUCCESS,
          msg: error,
        })
      );
    }
  };

  // const handleClick = (event, companyId) => {
  //   if (companyId) {
  useEffect(() => {
    handleGetTeamMembersDetails(jobId);
  }, []);

  // }
  //   setAnchorEl(event.currentTarget);
  // };

  return (
    <Box
      sx={{
        display: "flex",
        flexGrow: 1,
        flexDirection: "column",
        // justifyContent: "space-between",
      }}
    >
      <Typography
        sx={{
          padding: "6px 16px 16px 19px",
          fontSize: theme.typography.fontSize.xs,
          fontWeight: theme.typography.Regular,
          lineHeight: "16px",
          height: "48px",
          width: `calc(${newCardWidth - 50}px)`,
          color: "black",
        }}
      >
        {i18n["empMyCam.whoCanAccessText"]}
      </Typography>
      <Box
        id="talentList"
        sx={{
          margin: "0 16px 16px 16px",
          height: "100%",
          overflowY: "scroll",
          "::-webkit-scrollbar": {
            height: "5px",
            background: "#DCDEEB",
            display: "unset !important",
          },
        }}
        className="talentList"
      >
        <InfiniteScroll
          style={{
            height: "100%",
            overflowX: "hidden",
            scrollbarWidth: "thin",
          }}
          dataLength={teamMembers?.length}
          hasMore={true}
          scrollThreshold={"50px"}
          scrollableTarget="talentList"
          endMessage={
            <p style={{ textAlign: "center" }}>
              <b>Yay! You have seen it all</b>
            </p>
          }
        >
          {teamMembers?.length > 0 ? (
            teamMembers?.map((teamMember, index) => (
              <Box
                key={teamMember.user_id} // Added key for better list rendering
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "start",
                  paddingBottom: "10px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    gap: "10px",
                    alignItems: "center",
                  }}
                >
                  <Avatar
                    sx={{
                      bgcolor: getColorByIndex(index),
                      borderRadius: "10px",
                    }}
                  >
                    {teamMember?.user?.first_name?.[0]}
                  </Avatar>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "start",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: theme.typography.fontSize.sm,
                        fontWeight: theme.typography.Bold,
                      }}
                    >
                      {teamMember?.user?.first_name}
                    </Typography>
                    <Box>
                      <Typography
                        sx={{
                          fontSize: theme.typography.fontSize.xs,
                          fontWeight: theme.typography.Regular,
                        }}
                      >
                        {teamMember?.employer_role_type?.name}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
                <ToggleSwitch
                  defaultChecked={
                    teamMember?.assigned_job !== null ||
                    teamMember?.role_type_id === 1 ||
                    teamMember?.role_type_id === 2 ||
                    teamMember?.isSuperAdminFlag
                  }
                  color={
                    teamMember?.role_type_id === 1 ||
                    teamMember?.role_type_id === 2 ||
                    teamMember?.isSuperAdminFlag
                      ? "adminCheckColor"
                      : "checkColor"
                  }
                  disabled={
                    teamMember?.role_type_id === 1 ||
                    teamMember?.role_type_id === 2 ||
                    teamMember?.isSuperAdminFlag
                  }
                  onChange={(event) => {
                    handleJobAccess(event, teamMember?.user_id);
                  }}
                />
              </Box>
            ))
          ) : isLoading ? (
            <Box
              sx={{
                margin: "15px 0 0 0",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CircularProgress color="inherit" size={20} />
            </Box>
          ) : assignedJobText === "" ? (
            <Typography
              sx={{
                fontSize: "14px",
                fontWeight: 700,
                mr: 1,
                mt: 1,
              }}
            >
              {assignedJobText}
            </Typography>
          ) : null}

          {/* <style>
            {`.accessList-infinite-scroll-component::-webkit-scrollbar {
                width: 7px !important;
                display: unset;
                background-color: #F5F5F5; 
                }

                .infinite-scroll-component__outerdiv {
                  height:100%
                }

                .accessList-infinite-scroll-component::-webkit-scrollbar-thumb {
                  background-color: #fff;
                  width: 5px;
                  box-shadow: 0px 3px 3px #00000029;
                  border-radius: 3px;
              }`}
          </style> */}
        </InfiniteScroll>
      </Box>
      <Box sx={{ display: "flex", width: "100%" }}>
        <Link
          to={
            decodedToken?.data?.role_id === 6
              ? "/recruiter/my-team"
              : "/employer/my-team"
          }
          style={{
            textDecoration: "none",
            color: theme.palette.black,
            flexGrow: 1,
          }}
        >
          <Button
            variant="contained"
            sx={{
              borderRadius: "0 0 25px 0",
              width: "100%",
              padding: "25px 20px",
              fontSize: theme.typography.fontSize.sm,
              fontWeight: theme.typography.Bold,
              height: "60px",
            }}
            color="addEditMemberButton"
          >
            add/edit a team member
          </Button>
        </Link>
      </Box>
    </Box>

    //   // </StyledMenu>
    // </>
  );
}
