import React, { useCallback, useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  Grid,
  IconButton,
  Button,
  Tooltip,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import InfiniteScroll from "react-infinite-scroll-component";
import { useDispatch } from "react-redux";
import {
  addLanguages,
  editLanguage,
  getAllLanguages,
  removeLanguages,
} from "../../../redux/admin/maintenance";
import { dateConverterMonth } from "../../../utils/DateTime";
import DeleteIcon from "@mui/icons-material/Delete";
import { setAlert } from "../../../redux/configSlice";
import { ALERT_TYPE, ERROR_MSG } from "../../../utils/Constants";
import AddNew from "./Dialogbox/AddNew";
import Delete from "./Dialogbox/Delete";
import EditIcon from "@mui/icons-material/Edit";
import Edit from "./Dialogbox/Edit";
import SearchBox from "./Dialogbox/SearchBox";
import { debounce } from "../../../utils/Common";

export default function Languages() {
  const dispatch = useDispatch();
  const [lastKey, setLastKey] = useState(0);
  const [tableData, setTableData] = useState([]);
  const [languageCount, setlanguageCount] = useState(0);
  const [openDelete, setOpenDelete] = useState(false);
  const [openAdd, setOpenAdd] = useState(false);
  const [deleteLanguage, setDeleteLanguage] = useState();
  const [newLanguageTitle, setNewLanguageTitle] = useState("");
  const [openEdit, setOpenEdit] = useState(false);
  const [editableNationality, setEditableNationality] = useState();
  const [nationalityName, setnationalityName] = useState("");
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [searchTitle, setSearchTitle] = useState("");

  const getLanguages = async (lastkeyy, title) => {
    try {
      let data = { lastKey: lastkeyy, title: title };
      const { payload } = await dispatch(getAllLanguages(data));
      if (payload.status === "success") {
        setlanguageCount(payload.count);
        if (lastkeyy === 0) {
          setTableData(payload.data);
          setLastKey(payload.pageNumber + 1);
        } else {
          setLastKey(payload.pageNumber + 1);
          setTableData((prevState) => [...prevState, ...payload.data]);
        }
      }
    } catch (error) {}
  };

  const removeLanguage = async () => {
    try {
      const data = {
        language_id: deleteLanguage,
      };
      const { payload } = await dispatch(removeLanguages(data));
      if (payload.status === "success") {
        setOpenDelete(false);
        setTableData([]);
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE.SUCCESS,
            msg: "Language removed successfully",
          })
        );
        setConfirmDelete(false);
        await getLanguages(0, searchTitle);
      }
    } catch (error) {}
  };

  const handleAddNewLanguage = async () => {
    try {
      if (newLanguageTitle.trim().length !== 0) {
        const data = {
          title: newLanguageTitle,
        };
        const { payload } = await dispatch(addLanguages(data));
        if (payload.status === "success") {
          setTableData([]);
          setNewLanguageTitle("");
          setOpenAdd(false);
          dispatch(
            setAlert({
              show: true,
              type: ALERT_TYPE.SUCCESS,
              msg: "Language added successfully",
            })
          );
          await getLanguages(0, searchTitle);
        } else {
          dispatch(
            setAlert({
              show: true,
              type: ALERT_TYPE.ERROR,
              msg: payload?.message?.message,
            })
          );
        }
      } else {
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE.ERROR,
            msg: "Field is blank",
          })
        );
      }
    } catch (error) {}
  };

  const handleEditCompany = async () => {
    try {
      const data = {
        language_id: editableNationality,
        title: nationalityName,
      };
      const { payload } = await dispatch(editLanguage(data));
      if (payload.status === "success") {
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE.SUCCESS,
            msg: "Language edited successfully",
          })
        );
        setOpenEdit(false);
        await getLanguages(0, searchTitle);
      } else {
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE.ERROR,
            msg: payload.message.message,
          })
        );
      }
    } catch (error) {}
  };

  const handleOpenDelete = (jobId) => {
    setOpenDelete((prevState) => !prevState);
    setDeleteLanguage(jobId);
  };

  const handleCancelDelete = () => {
    setOpenDelete((prevState) => !prevState);
    setConfirmDelete(false);
  };

  const handleOpenAdd = () => {
    setOpenAdd((prevState) => !prevState);
    setNewLanguageTitle("");
  };

  const handleNewJob = (event) => {
    setNewLanguageTitle(event.target.value);
  };

  const handleEdit = (event) => {
    setnationalityName(event.target.value);
  };

  const handleOpenEdit = (currCompID, compName) => {
    setOpenEdit((prevState) => !prevState);
    setEditableNationality(currCompID);
    setnationalityName(compName);
  };

  const handleText = async (event) => {
    setSearchTitle(event?.target?.value);
    debouncedHandleOnTextSearch(event);
  };

  const debouncedHandleOnTextSearch = useCallback(
    debounce(
      async (newValue) => {
        if (!newValue) return; // Early return
        try {
          const response = await getLanguages(0, newValue.target.value);

          if (response && response.payload) {
            const { payload } = response;
            dispatch(
              payload.status === "success"
                ? setTableData(payload.data)
                : setAlert({ show: true, type: ALERT_TYPE.ERROR, msg: "Error" })
            );
          }
        } catch (error) {
          dispatch(
            setAlert({ show: true, type: ALERT_TYPE.ERROR, msg: ERROR_MSG })
          );
        }
      },
      500, // Adjust delay as needed
      setIsLoading
    ),
    [dispatch, setTableData, setIsLoading]
  );
  useEffect(() => {
    getLanguages(0, searchTitle);
  }, []);

  return (
    <Box sx={{ ml: "16px" }}>
      <Typography
        sx={{
          fontSize: "36px",
          fontWeight: 700,
        }}
      >
        Languages ({languageCount})
      </Typography>

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          gap: "10px",
          marginTop: "16px",
        }}
      >
        <SearchBox onChange={(event) => handleText(event)} />
        <Button
          variant="contained"
          color="redButton"
          onClick={handleOpenAdd}
          sx={{ borderRadius: "10px" }}
        >
          Add
        </Button>
      </Box>

      <Grid
        container
        spacing={2}
        flexDirection={"column"}
        sx={{
          display: { xs: "none", md: "flex" },
          marginTop: "30px",
          marginLeft: "0px",
        }}
      >
        <InfiniteScroll
          style={{ overflow: "hidden" }}
          dataLength={tableData.length}
          next={() => getLanguages(lastKey, searchTitle)}
          scrollThreshold={"100px"}
          scrollableTarget={"jobList"}
          hasMore={true}
          endMessage={
            <p style={{ textAlign: "center" }}>
              <b>Yay! You have seen it all</b>
            </p>
          }
        >
          <Box
            sx={{
              mt: 2,
            }}
          >
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <Typography variant="subtitle1" fontWeight="bold">
                        Id
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="subtitle1" fontWeight="bold">
                        Name
                      </Typography>
                    </TableCell>
                    {/*<TableCell>
                            <Typography variant="subtitle1" fontWeight="bold">
                              Users
                            </Typography>
                          </TableCell>*/}
                    <TableCell>
                      <Typography variant="subtitle1" fontWeight="bold">
                        Created At
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="subtitle1" fontWeight="bold">
                        Updated At
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="subtitle1" fontWeight="bold">
                        Actions
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {tableData.map((row) => (
                    <TableRow
                      key={row.id}
                      style={{
                        "& .css-12zgwp-MuiTableCell-root": {
                          padding: "0 16px !important",
                        },
                      }}
                    >
                      <TableCell>{row?.language_id}</TableCell>
                      <TableCell>{row?.language}</TableCell>
                      <TableCell>
                        {dateConverterMonth(row.created_at)}
                      </TableCell>
                      <TableCell>
                        {dateConverterMonth(row.updated_at)}
                      </TableCell>
                      <TableCell>
                        <Tooltip
                          disableInteractive
                          title="edit"
                          placement="top-end"
                        >
                          <IconButton
                            aria-label="edit"
                            color="blueButton400"
                            sx={{
                              padding: "0 !important",
                              minWidth: "18px !important",
                              "& .MuiSvgIcon-root": {
                                width: "18px",
                              },
                            }}
                          >
                            <EditIcon
                              onClick={() =>
                                handleOpenEdit(row?.language_id, row?.language)
                              }
                              sx={{ cursor: "pointer" }}
                            />
                          </IconButton>
                        </Tooltip>
                        <Tooltip
                          title="delete"
                          placement="top-end"
                          disableInteractive
                        >
                          <IconButton
                            aria-label="edit"
                            color="blueButton400"
                            sx={{
                              padding: "0 !important",
                              minWidth: "18px !important",
                              "& .MuiSvgIcon-root": {
                                width: "18px",
                              },
                            }}
                          >
                            <DeleteIcon
                              onClick={() => handleOpenDelete(row?.language_id)}
                              sx={{ cursor: "pointer" }}
                            />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </InfiniteScroll>
      </Grid>
      <Delete
        show={openDelete}
        handleOpen={handleOpenDelete}
        handleDelete={removeLanguage}
        dialogText={"language"}
        handleCancel={handleCancelDelete}
        confirmDelete={confirmDelete}
        setConfirmDelete={setConfirmDelete}
      />
      <AddNew
        show={openAdd}
        handleOpen={handleOpenAdd}
        handleAdd={handleAddNewLanguage}
        handleNewJob={handleNewJob}
        newTitle={newLanguageTitle}
        dialogText={"language"}
      />
      <Edit
        show={openEdit}
        handleOpen={handleOpenEdit}
        handleEdit={handleEditCompany}
        handleEditJob={handleEdit}
        inputName={nationalityName}
        dialogText={"language"}
        singleInput={true}
      />
    </Box>
  );
}
