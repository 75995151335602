import { Box, Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import JobsCardComponent from "./JobsCardComponent";
import { useDispatch } from "react-redux";
import {
  adminJobsFilter,
  setLeftMenuButton,
} from "../../../redux/admin/jobsSlice";
import { setAlert } from "../../../redux/configSlice";
import { ALERT_TYPE } from "../../../utils/Constants";
import { useParams } from "react-router-dom";
import LeftArrow from "../../common/LeftArrow";
import RightArrow from "../../common/RightArrow";
import { useTheme } from "@emotion/react";
import LeftDrawer from "../LeftDrawer";
const AdminJobdetailInfo = () => {
  const theme = useTheme();
  const { id } = useParams();

  const [allJobs, setAllJobs] = useState([]);
  const [lastKey, setLastKey] = useState();
  const [basicData, setBasicData] = useState();
  const dispatch = useDispatch();
  const [totalJob, setTotalJob] = useState(0);
  const [leftMenu, setLeftMenu] = useState(false);

  const getJobList = async (lastkeyy, newBasicData) => {
    const data = {
      ...newBasicData,
      lastKey: lastkeyy,
      status_id: "",
      job_type: "",
      job_id: id,
    };
    const { payload } = await dispatch(adminJobsFilter(data));
    if (payload?.status === "success") {
      setAllJobs(payload?.data?.[0]);
    } else if (payload === undefined) {
      return;
    } else {
      dispatch(
        setAlert({
          show: true,
          type: ALERT_TYPE?.ERROR,
          msg: "Something went wrong! please relaod the window",
        })
      );
    }
  };
  useEffect(() => {
    getJobList();
  }, []);
  return (
    <Box sx={{ display: "flex" }}>
      <Box
        sx={{ position: "absolute", zIndex: "1100", display: "flex" }}
        onMouseEnter={() => {
          dispatch(setLeftMenuButton(true));
          setLeftMenu(true);
        }}
        onMouseLeave={() => {
          dispatch(setLeftMenuButton(false));
          setLeftMenu(false);
        }}
      >
        <LeftDrawer leftExpanded={leftMenu} />
        <Button
          variant="contained"
          sx={{
            position: "static",
            borderRadius: "0 10px 10px 0",
            minWidth: "30px !important",
            padding: "0px",
            height: "45px",
            backgroundColor: theme.palette.redButton100.main,
            ":hover": {
              backgroundColor: theme.palette.redButton100.main,
              boxShadow: "none",
              cursor: "pointer", // Change cursor to indicate it's clickable
            },
          }}
          // onClick={() => {
          //   setLeftMenu((prevState) => !prevState);
          // }}
        >
          {leftMenu ? <LeftArrow /> : <RightArrow />}
        </Button>
      </Box>
      <Box
        sx={{
          width: "100%",
          margin: "55px 40px 0 110px !important",
          overflowY: "auto", // Add this line to make the Box scrollable vertically
          maxHeight: "calc(100vh - 100px)", // Adjust the max height as needed
        }}
      >
        <JobsCardComponent
          //   key={index}
          index={allJobs.job_id}
          jobContentData={allJobs}
          //   onManageTalent={onHandleManageTalent}
          getJobList={getJobList}
          setAllJobs={setAllJobs}
          setJobsLastKey={setLastKey}
          basicData={basicData}
          page={""}
          setTotalJob={setTotalJob}
        />
      </Box>
    </Box>
  );
};

export default AdminJobdetailInfo;
