import React, { useEffect, useRef, useState } from "react";
import { Box, Button, Tooltip } from "@mui/material";
import Typography from "@mui/material/Typography";
import profile from "../../../assets/user_logo.svg";
import SmallButton from "../../common/SmallButton";
import linkedin from "../../../assets/linkedin.svg";
import { useTheme } from "@emotion/react";
import { Link, useParams } from "react-router-dom";
import jwt_decode from "jwt-decode";
import {
  convertDOB,
  dateConverterFullMonth,
  monthYear,
  yearConverter,
} from "../../../utils/DateTime";
import { getCandidateCV } from "../../../redux/employer/myJobsSlice";
import { useDispatch } from "react-redux";
import { setAlert, setLoading } from "../../../redux/configSlice";
import { ALERT_TYPE, ERROR_MSG } from "../../../utils/Constants";
import { formatCurrencyWithCommas } from "../../../utils/Currency";
import MUIRadialChart from "../../common/MUIRadialChart";
import TalentSVGButton from "../../common/TalentSVGButton";
import locationIcon from "../../../assets/CircularIcon/Red/Circular Icons__Red_Location.svg";
import experienceIcon from "../../../assets/CircularIcon/Red/Circular Icons__Red_Pending.svg";
import salaryIcon from "../../../assets/CircularIcon/Red/Circular Icons__Red_Salary.svg";
import noticePeriodIcon from "../../../assets/CircularIcon/Red/Circular Icons__Red_Calendor.svg";
import preferenceIcon from "../../../assets/CircularIcon/Red/Circular Icons__Red_Title_Job_Experience.svg";
import workHistory from "../../../assets/CircularIcon/Yellow/Circular Icons_Yellow_Experience.svg";
import referenceIcon from "../../../assets/CircularIcon/Yellow/Circular Icons__Yellow_Reference.svg";
import professionalIcon from "../../../assets/CircularIcon/Yellow/Circular Icons_Yellow_Professional.svg";
import maleIcon from "../../../assets/CircularIcon/Blue/Circular Icons__Blue_Male.svg";
import femaleIcon from "../../../assets/CircularIcon/Blue/Circular Icons__Blue_Feamale.svg";
import nationalityIcon from "../../../assets/CircularIcon/Blue/Circular Icons__Blue_Country_Language.svg";
import languageIcon from "../../../assets/CircularIcon/Blue/Circular Icons__Blue_Refer.svg";
import mailIcon from "../../../assets/CircularIcon/Blue/Circular Icons__Blue_Mail.svg";
import setup from "../../../assets/CircularIcon/Blue/Circular Icons__Blue_Maintenance.svg";
import skills from "../../../assets/CircularIcon/Yellow/Circular Icons__Yellow_Skills.svg";
import tools from "../../../assets/CircularIcon/Yellow/Circular Icons__Yellow_Tool.svg";
import birthDate from "../../../assets/CircularIcon/Blue/Circular Icons_Blue_Birthday.svg";
import cvIcon from "../../../assets/CircularIcon/Red/Circular Icons_Red_CV-268.svg";
import indutryIcon from "../../../assets/CircularIcon/Red/Circular Icons_Red_Company.svg";
import contactIcon from "../../../assets/CircularIcon/Blue/Circular Icons_Blue_Contact Details.svg";
import portfolioIcon from "../../../assets/CircularIcon/Red/Circular Icons_Red_Portfolio.svg";
import educationIcon from "../../../assets/CircularIcon/Red/Circular Icons_Red_Education.svg";
import educationHistory from "../../../assets/CircularIcon/Yellow/Circular Icons__Yellow_Education.svg";
import ownEquipment from "../../../assets/Padding Excluded/Crayon Icon_Own Equipment.svg";
import ownInternet from "../../../assets/Padding Excluded/Crayon Icon_Own Internet.svg";
import ownVehicle from "../../../assets/Padding Excluded/Crayon Icon_Own Vehicle.svg";
import crayonIcon from "../../../assets/Crayon_Favicon.svg";
import crayonLogo from "../../../assets/Crayon_Logo.svg";
import profile_challenger from "../../../assets/Personality Logos/personality_characters_challenger.svg";
// import profile_challenger from "../../../assets/Personality Logos/crayon_personality_grey_challeneger.svg";
import profile_grey_challenger from "../../../assets/Personality Logos/crayon_personality_grey_challenger.svg";
import profile_grey_character from "../../../assets/Personality Logos/crayon_personality_grey_character.svg";
import profile_character from "../../../assets/Personality Logos/crayon_personality_character.svg";
import profile_collaborator from "../../../assets/Personality Logos/crayon_personality_collaborator.svg";
import profile_contemplator from "../../../assets/Personality Logos/crayon_personality_contemplator.svg";
import CommonLoader from "../../common/CommonLoader";
import {
  calculateDaysBetween,
  createMarkup,
  splitStringWithParentheses,
} from "../../../utils/Common";
import locale from "../../../i18n/locale";
import { formatPhoneNumberIntl } from "react-phone-number-input";
import Loader from "../../common/Loader";
import * as Flag from "country-flag-icons/react/3x2";
import { useSelector } from "react-redux";

export default function CandidateCVPageNew({
  pdfRef,
  leftExpanded,
  candidateCV,
  exportAsPDF,
}) {
  const i18n = locale.en;
  const theme = useTheme();
  const dispatch = useDispatch();
  const { id } = useParams();
  // const [isLoading, setIsLoading] = useState(true);
  const [stateData, setStateData] = useState([]);
  const token = localStorage?.getItem("token");
  const isLoading = useSelector((state) => state.config.loading);

  let decodedToken;
  if (token) {
    decodedToken = jwt_decode(token);
  }

  const CountryFlag = ({ countryCode }) => {
    const FlagComponent = Flag[countryCode];
    return FlagComponent ? (
      <FlagComponent
        title={countryCode}
        className="..."
        style={{ width: 22, height: 20 }}
      />
    ) : null;
  };

  const handleCandidateCV = async () => {
    // setIsLoading(true);
    dispatch(setLoading(true));
    try {
      const user = {
        user_id: id !== undefined ? id : "",
      };
      const { payload } = await dispatch(getCandidateCV(user));
      if (payload?.status === "success") {
        setStateData(payload?.data?.at(0));
        // setCVLink(payload?.data?.candidate_profile?.candidate_info?.cv_link);
      }
      // setIsLoading(false);
      dispatch(setLoading(false));
    } catch (error) {
      dispatch(
        setAlert({
          show: true,
          type: ALERT_TYPE.ERROR,
          msg: ERROR_MSG,
        })
      );
      // setIsLoading(false);
      dispatch(setLoading(false));
    }
  };

  const calculateHeight = () => {
    const screenWidth = window.innerWidth;

    if (screenWidth < 600) {
      // xs
      return 100;
    } else if (screenWidth < 900) {
      // sm
      return 100;
    } else if (screenWidth < 1366) {
      // md
      return 100;
    } else if (screenWidth < 1920) {
      // lg
      return 100;
    } else if (screenWidth < 2559) {
      // xl
      return 100;
    } else {
      // xxl or greater
      return 100;
    }
  };

  useEffect(() => {
    handleCandidateCV();
  }, []);

  const calculateAge = (dateString) => {
    const birthDate = new Date(dateString);
    const currentDate = new Date();
    let years = currentDate?.getUTCFullYear() - birthDate?.getUTCFullYear();
    let months = currentDate?.getUTCMonth() - birthDate?.getUTCMonth();
    if (
      months < 0 ||
      (months === 0 && currentDate?.getUTCDate() < birthDate?.getUTCDate())
    ) {
      years--;
      months = 12 + months;
    }
    // If the age is less than a month, return "1 month"
    if (years === 0 && months === 0) {
      return "1 month";
    }
    let result = "";
    if (years > 0) result += `${years} year${years > 1 ? "s" : ""} `;
    if (months > 0) result += `${months} month${months > 1 ? "s" : ""}`;
    return result?.trim();
  };

  return (
    !isLoading && (
      <Box
        width={!candidateCV ? "62%" : "100%"}
        marginTop={!candidateCV && "55px"}
        sx={{
          cursor: "auto !important",
          boxShadow: "0px 5px 10px #00000029 !important",
          borderRadius: "25px",
          // marginBottom: "110px !important",
          marginBottom: !candidateCV && "55px",
          position: "relative",
          background: "#ffff",
        }}
      >
        <Box
          ref={pdfRef}
          sx={{
            // padding: { xs: "0px", lgmm: "50px" },
            // paddingLeft: "50px",
            // paddingRight: { xs: "1px", lgmm: "50px" },
            padding: exportAsPDF ? "50px" : { xs: "1px", lgmm: "50px" },
            display: "flex",
            flexDirection: exportAsPDF
              ? "row"
              : {
                  xs: "column",
                  md24: leftExpanded ? "column" : "row",
                  lg: "row",
                  mdl: "row",
                },
            gap: { xs: "10px", lgmm: "30px" },
            justifyContent: {
              xs: "center",
              lgmm: "space-between",
              lgm: "start",
            },
          }}
        >
          <Box
            sx={{
              width: {
                xs: "100%",
                md24: leftExpanded ? "100%" : "38%",
                lg: "38%",
                //  lgmm: "474px"
              },
              gap: "20px",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Box
              sx={{
                width: {
                  xs: "100%",
                  // lgmm: "474px"
                },
                backgroundColor: theme.palette.cvPageBackground.main,
                borderRadius: "25px",
              }}
            >
              <Button
                variant="contained"
                color="applyButton"
                sx={{
                  minWidth: "100% !important",
                  
                  minHeight: "45px",
                  fontSize: "20px",
                  fontWeight: theme.typography.Bold,
                  borderRadius: "10px 10px 0 0",
                }}
              >
                {i18n["myCV.curriculamVitae"]}
              </Button>
              <Box
                sx={{
                  // width: "100%",
                  padding: "15px",
                }}
              >
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    gap: "45px",
                    marginTop: "10px",
                  }}
                >
                  <Box>
                    <Box
                      component="img"
                      className="profileAvatar"
                      alt="crayon logo"
                      src={
                        stateData?.profile_url !== "No URL" &&
                        stateData?.profile_url !== undefined
                          ? stateData?.profile_url
                          : profile
                      }
                      sx={{
                        width: "112px",
                        minHeight: "112px",
                        borderRadius: "25px",
                        display: "block",
                      }}
                    />
                  </Box>
                  <Box sx={{ minWidth: "226px", minHeight: "88.5px" }}>
                    <Typography
                      sx={{
                        fontSize: "30px",
                        fontWeight: theme.typography.Bold,
                        wordWrap: "break-word",
                      }}
                      // noWrap={true}
                    >
                      {stateData?.first_name}
                    </Typography>

                    <Typography
                      sx={{
                        fontSize: "30px",
                        fontWeight: theme.typography.Bold,
                        wordWrap: "break-word",
                      }}
                      // noWrap={true}
                    >
                      {stateData?.last_name}
                    </Typography>

                    <Typography
                      sx={{
                        fontSize: "24px",
                        fontWeight: theme.typography.Bold,
                        wordWrap: "break-word",
                      }}
                      // noWrap={true}
                    >
                      {stateData?.jobTitle}
                    </Typography>
                  </Box>
                </Box>
                <Typography
                  sx={{
                    marginTop: "10px",
                    fontSize: "14px",
                    fontWeight: theme.typography.Regular,
                    minHeight: "65px",
                    width: "100%",
                    //   whiteSpace: "nowrap",
                    wordBreak: "break-word",
                    overflow: "hidden", // Hides any overflowing content
                    textOverflow: "ellipsis", // Adds dots at the end of overflowing text
                  }}
                >
                  {stateData?.my_bio}
                </Typography>
                <Box
                  sx={{ display: "flex", flexDirection: "column", gap: "10px" }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      gap: "12px",
                      width: "100%",
                    }}
                  >
                    <TalentSVGButton
                      color={"white"}
                      source={locationIcon}
                      height={30}
                      width={30}
                      startIconMargin={"0px !important"}
                      padding={"0px !important"}
                    />
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        gap: "5px",
                        width: "90%",
                      }}
                    >
                      <Typography
                        sx={{
                          width: { xs: "35%", lgmm: "28%" },
                          fontSize: "14px",
                          fontWeight: theme.typography.Bold,
                        }}
                      >
                        Location:
                      </Typography>

                      <CountryFlag countryCode={stateData?.country_code_name} />
                      <Typography
                        sx={{
                          width: {
                            xs: "65%",
                            //  lgmm: "295px"
                          },
                          fontSize: "14px",
                          fontWeight: theme.typography.Regular,
                        }}
                      >
                        {`${
                          stateData?.town_name && stateData?.country_name
                            ? `${stateData?.town_name}, ${stateData?.country_name}`
                            : "-"
                        }`}
                        {/* {stateData?.town_name}, {stateData?.country_name} */}
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      gap: "12px",
                      width: "100%",
                    }}
                  >
                    <TalentSVGButton
                      color={"white"}
                      source={experienceIcon}
                      height={30}
                      width={30}
                      startIconMargin={"0px !important"}
                      padding={"0px !important"}
                    />
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        gap: "5px",
                        width: "90%",
                      }}
                    >
                      <Typography
                        sx={{
                          width: {
                            xs: "35%",
                            lgmm: "28%",
                            // lgmm: "111px"
                          },

                          fontSize: "14px",
                          fontWeight: theme.typography.Bold,
                        }}
                      >
                        Experience:
                      </Typography>
                      <Typography
                        sx={{
                          width: {
                            xs: "65%",
                            // lgmm: "295px"
                          },
                          fontSize: "14px",
                          fontWeight: theme.typography.Regular,
                        }}
                      >
                        {stateData?.experienceYearEnd >= 0
                          ? `${
                              stateData?.experienceYearEnd <= 1
                                ? `${stateData?.experienceYearEnd} year`
                                : `${stateData?.experienceYearEnd} years`
                            }`
                          : "-"}
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      gap: "12px",
                      width: "100%",
                    }}
                  >
                    <TalentSVGButton
                      color={"white"}
                      source={salaryIcon}
                      height={30}
                      width={30}
                      startIconMargin={"0px !important"}
                      padding={"0px !important"}
                    />
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        gap: "5px",
                        width: "90%",
                      }}
                    >
                      <Typography
                        sx={{
                          width: {
                            xs: "35%",
                            lgmm: "28%",
                            // lgmm: "111px"
                          },
                          fontSize: "14px",
                          fontWeight: theme.typography.Bold,
                        }}
                      >
                        Preferred Salary:
                      </Typography>
                      <Typography
                        sx={{
                          width: {
                            xs: "65%",
                            //  lgmm: "295px"
                          },
                          fontSize: "14px",
                          fontWeight: theme.typography.Regular,
                        }}
                      >
                        {stateData?.salaryMax ? (
                          <>
                            {stateData?.currencySymbol}
                            {formatCurrencyWithCommas(
                              stateData?.salaryMin
                            )} to {stateData?.currencySymbol}
                            {formatCurrencyWithCommas(stateData?.salaryMax)}
                            {stateData?.employment_type === "freelance"
                              ? " per hour"
                              : " per month"}
                          </>
                        ) : (
                          "-"
                        )}
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      gap: "12px",
                      width: "100%",
                    }}
                  >
                    <TalentSVGButton
                      color={"white"}
                      source={educationIcon}
                      height={30}
                      width={30}
                      startIconMargin={"0px !important"}
                      padding={"0px !important"}
                    />
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        gap: "5px",
                        width: "90%",
                      }}
                    >
                      <Typography
                        sx={{
                          width: {
                            xs: "35%",
                            lgmm: "28%",
                            //  lgmm: "111px"
                          },
                          fontSize: "14px",
                          fontWeight: theme.typography.Bold,
                        }}
                      >
                        Highest Education:
                      </Typography>
                      <Typography
                        sx={{
                          width: {
                            xs: "65%",
                            //  lgmm: "295px"
                          },
                          fontSize: "14px",
                          fontWeight: theme.typography.Regular,
                        }}
                      >
                        {stateData?.HighestQual || "-"}
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      gap: "12px",
                      width: "100%",
                    }}
                  >
                    <TalentSVGButton
                      color={"white"}
                      source={noticePeriodIcon}
                      height={30}
                      width={30}
                      startIconMargin={"0px !important"}
                      padding={"0px !important"}
                    />
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        gap: "5px",
                        width: "90%",
                      }}
                    >
                      <Typography
                        sx={{
                          width: {
                            xs: "35%",
                            lgmm: "28%",
                            //  lgmm: "111px"
                          },
                          fontSize: "14px",
                          fontWeight: theme.typography.Bold,
                        }}
                      >
                        Notice Period:
                      </Typography>
                      <Typography
                        sx={{
                          width: {
                            xs: "65%",
                            // lgmm: "295px"
                          },
                          fontSize: "14px",
                          fontWeight: theme.typography.Regular,
                        }}
                      >
                        {stateData?.noticePeriod_description
                          ? stateData?.noticePeriod_description
                          : "-"}
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      gap: "12px",
                      width: "100%",
                    }}
                  >
                    <TalentSVGButton
                      color={"white"}
                      source={cvIcon}
                      height={30}
                      width={30}
                      startIconMargin={"0px !important"}
                      padding={"0px !important"}
                    />
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        gap: "5px",
                        width: "90%",
                      }}
                    >
                      <Typography
                        sx={{
                          width: {
                            xs: "35%",
                            lgmm: "28%",
                            //  lgmm: "111px"
                          },
                          fontSize: "14px",
                          fontWeight: theme.typography.Bold,
                        }}
                      >
                        Links:
                      </Typography>
                      <Box
                        sx={{
                          width: {
                            xs: "65%",
                            // lgmm: "295px"
                          },
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        {stateData?.linkedin_profile_link ? (
                          <Link
                            to={stateData?.linkedin_profile_link}
                            target={"_blank"}
                            style={{
                              textDecoration: "none",
                              color: theme.palette.black,
                            }}
                          >
                            <Box
                              component="img"
                              className="profileAvatar"
                              alt="crayon logo"
                              src={linkedin}
                              sx={{
                                mr: 1,
                                width: "20px",
                                minHeight: "20px",
                                cursor: "pointer",
                              }}
                            />
                          </Link>
                        ) : (
                          <Box
                            component="img"
                            className="profileAvatar"
                            alt="crayon logo"
                            src={linkedin}
                            sx={{
                              mr: 1,
                              width: "20px",
                              minHeight: "20px",
                              cursor: "pointer",
                            }}
                          />
                        )}
                        <Link
                          to={`/candidate-cv/${stateData?.user_id}`}
                          target={"_blank"}
                          style={{
                            textDecoration: "none",
                            color: theme.palette.black,
                          }}
                        >
                          <Box
                            component="img"
                            className="profileAvatar"
                            alt="crayon logo"
                            src={crayonIcon}
                            sx={{
                              mr: 1,
                              width: "20px",
                              minHeight: "20px",
                              cursor: "pointer",
                            }}
                          />
                        </Link>
                        {stateData?.cv_url !== "No URL" ? (
                          <Link
                            to={`${stateData?.cv_url}`}
                            target={"_blank"}
                            style={{
                              textDecoration: "none",
                              color: theme.palette.black,
                            }}
                          >
                            <TalentSVGButton
                              cursor={"pointer"}
                              color={"white"}
                              source={portfolioIcon}
                              height={25}
                              width={25}
                              startIconMargin={"0px !important"}
                              padding={"0px !important"}
                            />
                          </Link>
                        ) : (
                          <TalentSVGButton
                            cursor={"pointer"}
                            color={"white"}
                            source={portfolioIcon}
                            height={25}
                            width={25}
                            startIconMargin={"0px !important"}
                            padding={"0px !important"}
                          />
                        )}
                      </Box>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      gap: "12px",
                      width: "100%",
                    }}
                  >
                    <TalentSVGButton
                      color={"white"}
                      source={preferenceIcon}
                      height={30}
                      width={30}
                      startIconMargin={"0px !important"}
                      padding={"0px !important"}
                    />
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        gap: "5px",
                        width: "90%",
                      }}
                    >
                      <Typography
                        sx={{
                          width: {
                            xs: "35%",
                            lgmm: "28%",
                            // lgmm: "111px"
                          },
                          fontSize: "14px",
                          fontWeight: theme.typography.Bold,
                        }}
                      >
                        Preferences:
                      </Typography>
                      <Box
                        sx={{
                          width: {
                            xs: "65%",
                            //  lgmm: "295px"
                          },
                          display: "flex",
                          alignItems: "center",
                          flexWrap: "wrap",
                          gap: { xs: "5px" },
                        }}
                      >
                        {stateData?.employment_type && (
                          <SmallButton
                            fontSize={12}
                            textColor={"black"}
                            color="lockItIn"
                            fontWeight={theme.typography.Bold}
                            height={25}
                            letterSpacing="0"
                            p="8px"
                            label={stateData?.employment_type}
                            mr="8px"
                          />
                        )}
                        {stateData?.work_setup && (
                          <SmallButton
                            fontSize={12}
                            textColor={"black"}
                            color="lockItIn"
                            fontWeight={theme.typography.Bold}
                            height={25}
                            letterSpacing="0"
                            p="8px"
                            label={stateData?.work_setup}
                            mr="8px"
                          />
                        )}

                        <SmallButton
                          fontSize={12}
                          textColor={"black"}
                          color="lockItIn"
                          fontWeight={theme.typography.Bold}
                          height={25}
                          letterSpacing="0"
                          p="8px"
                          label={
                            stateData?.relocate === "1"
                              ? "willing to relocate"
                              : "not willing to relocate"
                          }
                          mr="8px"
                        />
                      </Box>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "12px",
                      alignItems: "flex-start",
                      width: "100%",
                    }}
                  >
                    <TalentSVGButton
                      color={"white"}
                      source={indutryIcon}
                      height={30}
                      width={30}
                      startIconMargin={"0px !important"}
                      padding={"0px !important"}
                    />
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "flex-start",
                        gap: "5px",
                        width: "90%",
                      }}
                    >
                      <Typography
                        sx={{
                          width: {
                            xs: "35%",
                            lgmm: "28%",
                            //  lgmm: "111px"
                          },
                          fontSize: "14px",
                          fontWeight: theme.typography.Bold,
                        }}
                      >
                        Industries:
                      </Typography>
                      <Box
                        sx={{
                          width: {
                            xs: "65%",
                            //  lgmm: "295px"
                          },
                          display: "flex",
                          alignItems: "center",
                          flexWrap: "wrap",
                          gap: "5px",
                        }}
                      >
                        {stateData?.UserIndustries
                          ? stateData?.UserIndustries?.split(",")?.map(
                              (item) => {
                                return (
                                  <SmallButton
                                    fontSize={12}
                                    fontWeight={theme.typography.Bold}
                                    minWidth="10px"
                                    textColor={"black"}
                                    height={25}
                                    color="lockItIn"
                                    borderRadius="5px"
                                    label={item}
                                    mr="4px"
                                  ></SmallButton>
                                );
                              }
                            )
                          : "-"}
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>

            <Box
              sx={{
                width: {
                  xs: "100%",
                  // lgmm: "474px"
                },
                backgroundColor: theme.palette.cvPageBackground.main,
                borderRadius: "25px",
              }}
            >
              <Button
                variant="contained"
                color="personalDetail"
                sx={{
                  minWidth: "150px !important",
                  maxWidth: "150px !important",
                  minHeight: "45px",
                  fontSize: "16px",
                  padding:"6px 6px",
                  fontWeight: theme.typography.Bold,
                  borderRadius: "25px 0px 10px 0",
                }}
              >
                Personal Details
              </Button>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                  padding: "15px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    gap: "12px",
                    width: "100%",
                  }}
                >
                  <TalentSVGButton
                    color={"white"}
                    source={stateData?.gender ? maleIcon : femaleIcon}
                    height={30}
                    width={30}
                    startIconMargin={"0px !important"}
                    padding={"0px !important"}
                  />
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      gap: "5px",
                      width: "90%",
                    }}
                  >
                    <Typography
                      sx={{
                        width: {
                          xs: "35%",
                          lgmm: "28%",
                          // lgmm: "111px"
                        },
                        fontSize: "14px",
                        fontWeight: theme.typography.Bold,
                      }}
                    >
                      About me:
                    </Typography>

                    <Box
                      sx={{
                        width: {
                          xs: "65%",
                          //  lgmm: "295px"
                        },
                        display: "flex",
                        alignItems: "center",
                        flexWrap: "wrap",
                        gap: "5px",
                      }}
                    >
                      {!stateData?.gender &&
                        !stateData?.dob &&
                        !stateData?.skinz &&
                        "-"}
                      {stateData?.gender && stateData?.gender !== null && (
                        <SmallButton
                          fontSize={12}
                          textColor={"black"}
                          color="lockItIn"
                          fontWeight={theme.typography.Bold}
                          height={25}
                          letterSpacing="0"
                          p="8px"
                          label={stateData?.gender}
                          mr="8px"
                        />
                      )}
                      {stateData?.skinz && stateData?.skinz !== null && (
                        <SmallButton
                          fontSize={12}
                          textColor={"black"}
                          color="lockItIn"
                          fontWeight={theme.typography.Bold}
                          height={25}
                          letterSpacing="0"
                          p="8px"
                          label={stateData?.skinz}
                          mr="8px"
                        />
                      )}
                      {stateData?.dob && (
                        <SmallButton
                          fontSize={12}
                          textColor={"black"}
                          color="lockItIn"
                          fontWeight={theme.typography.Bold}
                          height={25}
                          letterSpacing="0"
                          p="8px"
                          label={`${calculateAge(stateData?.dob)}`}
                          mr="8px"
                        />
                      )}
                    </Box>
                  </Box>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    gap: "12px",
                    width: "100%",
                  }}
                >
                  <TalentSVGButton
                    color={"white"}
                    source={birthDate}
                    height={30}
                    width={30}
                    startIconMargin={"0px !important"}
                    padding={"0px !important"}
                  />
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      gap: "5px",
                      width: "90%",
                    }}
                  >
                    <Typography
                      sx={{
                        width: {
                          xs: "35%",
                          lgmm: "28%",
                          //  lgmm: "111px"
                        },
                        fontSize: "14px",
                        fontWeight: theme.typography.Bold,
                      }}
                    >
                      Birth date:
                    </Typography>
                    {stateData?.dob ? (
                      <Typography
                        sx={{
                          width: {
                            xs: "65%",
                            // lgmm: "295px"
                          },
                          fontSize: "14px",
                          fontWeight: theme.typography.Regular,
                        }}
                      >
                        {dateConverterFullMonth(stateData?.dob)}
                      </Typography>
                    ) : (
                      "-"
                    )}
                  </Box>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    gap: "12px",
                    width: "100%",
                  }}
                >
                  <TalentSVGButton
                    color={"white"}
                    source={mailIcon}
                    height={30}
                    width={30}
                    startIconMargin={"0px !important"}
                    padding={"0px !important"}
                  />
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      gap: "5px",
                      width: "90%",
                    }}
                  >
                    <Typography
                      sx={{
                        width: {
                          xs: "35%",
                          lgmm: "28%",
                          //  lgmm: "111px"
                        },
                        fontSize: "14px",
                        fontWeight: theme.typography.Bold,
                      }}
                    >
                      Email:
                    </Typography>
                    <Typography
                      sx={{
                        width: {
                          xs: "65%",
                          //  lgmm: "295px"
                        },
                        fontSize: "14px",
                        fontWeight: theme.typography.Regular,
                      }}
                    >
                      {stateData?.email || "-"}
                    </Typography>
                  </Box>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    gap: "12px",
                    width: "100%",
                  }}
                >
                  <TalentSVGButton
                    color={"white"}
                    source={contactIcon}
                    height={30}
                    width={30}
                    startIconMargin={"0px !important"}
                    padding={"0px !important"}
                  />
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      gap: "5px",
                      width: "90%",
                    }}
                  >
                    <Typography
                      sx={{
                        width: {
                          xs: "35%",
                          lgmm: "28%",
                          // lgmm: "111px"
                        },
                        fontSize: "14px",
                        fontWeight: theme.typography.Bold,
                      }}
                    >
                      Mobile:
                    </Typography>
                    <Typography
                      sx={{
                        width: {
                          xs: "65%",
                          // lgmm: "295px"
                        },
                        fontSize: "14px",
                        fontWeight: theme.typography.Regular,
                      }}
                    >
                      {formatPhoneNumberIntl(
                        stateData?.contact_no?.startsWith("+")
                          ? stateData?.contact_no
                          : stateData?.contact_no?.startsWith(
                              stateData?.country_code?.slice(1)
                            )
                          ? `${
                              stateData?.country_code
                            }${stateData?.contact_no?.slice(
                              stateData?.country_code?.length - 1
                            )}`
                          : `${stateData?.country_code}${stateData?.contact_no}`
                      ) || "-"}
                    </Typography>
                  </Box>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "12px",
                    alignItems: "flex-start",
                    width: "100%",
                  }}
                >
                  <TalentSVGButton
                    color={"white"}
                    source={nationalityIcon}
                    height={30}
                    width={30}
                    startIconMargin={"0px !important"}
                    padding={"0px !important"}
                  />
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "flex-start",
                      gap: "5px",
                      width: "90%",
                    }}
                  >
                    <Typography
                      sx={{
                        width: {
                          xs: "35%",
                          lgmm: "28%",
                          //  lgmm: "111px"
                        },
                        fontSize: "14px",
                        fontWeight: theme.typography.Bold,
                      }}
                    >
                      Nationalit(ies):
                    </Typography>
                    <Box
                      sx={{
                        width: {
                          xs: "65%",
                          // lgmm: "295px"
                        },
                        display: "flex",
                        alignItems: "center",
                        flexWrap: "wrap",
                        gap: "5px",
                      }}
                    >
                      {stateData?.UserNationality
                        ? stateData?.UserNationality?.split(",")?.map(
                            (item) => {
                              return (
                                <SmallButton
                                  fontSize={12}
                                  fontWeight={theme.typography.Bold}
                                  minWidth="10px"
                                  textColor={"black"}
                                  height={25}
                                  color="lockItIn"
                                  borderRadius="5px"
                                  label={item}
                                  mr="4px"
                                ></SmallButton>
                              );
                            }
                          )
                        : "-"}
                    </Box>
                  </Box>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "12px",
                    alignItems: "flex-start",
                    width: "100%",
                  }}
                >
                  <TalentSVGButton
                    color={"white"}
                    source={languageIcon}
                    height={30}
                    width={30}
                    startIconMargin={"0px !important"}
                    padding={"0px !important"}
                  />
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "flex-start",
                      gap: "5px",
                      width: "90%",
                    }}
                  >
                    <Typography
                      sx={{
                        width: {
                          xs: "35%",
                          lgmm: "28%",
                          // lgmm: "111px"
                        },
                        fontSize: "14px",
                        fontWeight: theme.typography.Bold,
                      }}
                    >
                      Languages(s):
                    </Typography>
                    <Box
                      sx={{
                        width: {
                          xs: "65%",
                          // lgmm: "295px"
                        },
                        display: "flex",
                        alignItems: "center",
                        flexWrap: "wrap",
                        gap: "5px",
                      }}
                    >
                      {stateData?.UserLanguage
                        ? stateData?.UserLanguage?.split(",")?.map((item) => {
                            return (
                              <SmallButton
                                fontSize={12}
                                fontWeight={theme.typography.Bold}
                                minWidth="10px"
                                textColor={"black"}
                                height={25}
                                color="lockItIn"
                                borderRadius="5px"
                                label={item}
                                mr="4px"
                              ></SmallButton>
                            );
                          })
                        : "-"}
                    </Box>
                  </Box>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    gap: "12px",
                    width: "100%",
                  }}
                >
                  <TalentSVGButton
                    color={"white"}
                    source={setup}
                    height={30}
                    width={30}
                    startIconMargin={"0px !important"}
                    padding={"0px !important"}
                  />
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      gap: "5px",
                      width: "90%",
                    }}
                  >
                    <Typography
                      sx={{
                        width: {
                          xs: "35%",
                          lgmm: "28%",
                          // lgmm: "111px",
                        },
                        fontSize: "14px",
                        fontWeight: theme.typography.Bold,
                      }}
                    >
                      Setup:
                    </Typography>
                    <Box
                      sx={{
                        width: {
                          xs: "65%",
                          xl: "80%",
                          // lgmm: "295px",
                        },
                        display: "flex",
                        alignItems: "center",
                        justifyContent: {
                          xs: "center",
                          tablet: "start",
                          lgmm: "start",
                        },
                        flexWrap: { xs: "wrap", xl: "nowrap" },
                        gap: stateData?.driving_license
                          ? { xs: "9px", xl: "4px" }
                          : "13px",
                      }}
                    >
                      {stateData?.internet_access &&
                      stateData?.own_equipment &&
                      stateData?.own_transport ? (
                        <>
                          {stateData?.own_transport && (
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <Box
                                component="img"
                                alt="own vehicle"
                                src={ownVehicle}
                                sx={{
                                  minHeight: "30px",
                                  width: "30px",
                                }}
                              />
                              <Typography
                                sx={{
                                  fontSize: "12px",
                                  fontWeight: theme.typography.Regular,
                                }}
                              >
                                own transport
                              </Typography>
                            </Box>
                          )}
                          {stateData?.driving_license && (
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <Box
                                component="img"
                                alt="own vehicle"
                                src={ownVehicle}
                                sx={{
                                  minHeight: "30px",
                                  width: "30px",
                                }}
                              />
                              <Typography
                                sx={{
                                  fontSize: "12px",
                                  fontWeight: theme.typography.Regular,
                                }}
                              >
                                license
                              </Typography>
                            </Box>
                          )}
                          {stateData?.own_equipment && (
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <Box
                                component="img"
                                alt="own vehicle"
                                src={ownEquipment}
                                sx={{
                                  minHeight: "30px",
                                  width: "30px",
                                }}
                              />
                              <Typography
                                sx={{
                                  fontSize: "12px",
                                  fontWeight: theme.typography.Regular,
                                }}
                              >
                                own equipment
                              </Typography>
                            </Box>
                          )}
                          {stateData?.internet_access && (
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <Box
                                component="img"
                                alt="own vehicle"
                                src={ownInternet}
                                sx={{
                                  minHeight: "30px",
                                  width: "30px",
                                }}
                              />
                              <Typography
                                sx={{
                                  fontSize: "12px",
                                  fontWeight: theme.typography.Regular,
                                }}
                              >
                                own internet
                              </Typography>
                            </Box>
                          )}
                        </>
                      ) : (
                        "-"
                      )}
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>

            <Box
              sx={{
                width: {
                  xs: "100%",
                  // lgmm: "474px"
                },
                backgroundColor: theme.palette.cvPageBackground.main,
                borderRadius: "25px",
              }}
            >
              <Button
                variant="contained"
                color="skillsAndTools"
                sx={{
                  minWidth: "150px !important",
                  maxWidth: "150px !important",
                  minHeight: "45px",
                  fontSize: "16px",
                  fontWeight: theme.typography.Bold,
                  borderRadius: "25px 0px 10px 0",
                }}
              >
                Skills & Tools
              </Button>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                  padding: "15px",
                  alignItems: "flex-start",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "12px",
                    alignItems: "flex-start",
                  }}
                >
                  <TalentSVGButton
                    color={"white"}
                    source={skills}
                    height={30}
                    width={30}
                    startIconMargin={"0px !important"}
                    padding={"0px !important"}
                  />
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "flex-start",
                      gap: "5px",
                    }}
                  >
                    <Typography
                      sx={{
                        width: { xs: "111px", lgmm: "140px" },
                        fontSize: "14px",
                        fontWeight: theme.typography.Bold,
                      }}
                    >
                      Skills:
                    </Typography>
                    <Box
                      sx={{
                        width: {
                          xs: "95%",
                          // lgmm: "295px"
                        },
                        display: "flex",
                        alignItems: "center",
                        flexWrap: "wrap",
                        gap: "5px",
                      }}
                    >
                      {stateData?.UserSkills
                        ? stateData?.UserSkills?.split(",")?.map((item) => {
                            return (
                              <SmallButton
                                fontSize={12}
                                fontWeight={theme.typography.Bold}
                                minWidth="10px"
                                textColor={"black"}
                                height={25}
                                color="lockItIn"
                                borderRadius="5px"
                                label={item}
                                mr="4px"
                              ></SmallButton>
                            );
                          })
                        : "-"}
                    </Box>
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "12px",
                    alignItems: "flex-start",
                  }}
                >
                  <TalentSVGButton
                    color={"white"}
                    source={tools}
                    height={30}
                    width={30}
                    startIconMargin={"0px !important"}
                    padding={"0px !important"}
                  />
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "flex-start",
                      gap: "5px",
                    }}
                  >
                    <Typography
                      sx={{
                        width: { xs: "111px", lgmm: "140px" },
                        fontSize: "14px",
                        fontWeight: theme.typography.Bold,
                      }}
                    >
                      Tools:
                    </Typography>
                    <Box
                      sx={{
                        width: {
                          xs: "95%",
                          //  lgmm: "295px"
                        },
                        display: "flex",
                        alignItems: "center",
                        flexWrap: "wrap",
                        gap: "5px",
                      }}
                    >
                      {stateData?.UserTools
                        ? splitStringWithParentheses(stateData?.UserTools)?.map(
                            (item, index) => (
                              <SmallButton
                                key={index}
                                fontSize={12}
                                fontWeight={theme.typography.Bold}
                                minWidth="10px"
                                textColor={"black"}
                                height={25}
                                color="lockItIn"
                                borderRadius="5px"
                                label={item?.trim()}
                                mr="4px"
                              ></SmallButton>
                            )
                          )
                        : "-"}
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>

            <Box
              sx={{
                width: {
                  xs: "100%",
                  // lgmm: "474px"
                },
                backgroundColor: theme.palette.cvPageBackground.main,
                borderRadius: "25px",
              }}
            >
              <Button
                variant="contained"
                color="precium"
                sx={{
                  minWidth: "150px !important",
                  maxWidth: "150px !important",
                  minHeight: "45px",
                  fontSize: "16px",
                  fontWeight: theme.typography.Bold,
                  borderRadius: "25px 0px 10px 0",
                }}
              >
                Personality
              </Button>

              <Box
                sx={{
                  display: "flex",
                  padding: "15px",
                  gap: "15px",
                  justifyContent: { xs: "center", lgmm: "start" },
                }}
              >
                <Box
                  component="img"
                  height={{ xs: "100px", lg: "100px", xl: "100px" }}
                  // sx={{ margin: "0 -22px 0 -22px" }}
                  alt="Personality"
                  src={
                    (stateData?.primaryName === "collaborator" &&
                      profile_collaborator) ||
                    (stateData?.primaryName === "challenger" &&
                      profile_challenger) ||
                    (stateData?.primaryName === "character" &&
                      profile_character) ||
                    (stateData?.primaryName === "contemplator" &&
                      profile_contemplator) ||
                    profile_grey_challenger
                  }
                />
                <Box
                  component="img"
                  height={{ xs: "100px", lg: "100px", xl: "100px" }}
                  // sx={{ margin: "0 -22px 0 -22px" }}
                  // alt="job_exp"
                  alt="Personality"
                  src={
                    (stateData?.shadowName === "collaborator" &&
                      profile_collaborator) ||
                    (stateData?.shadowName === "challenger" &&
                      profile_challenger) ||
                    (stateData?.shadowName === "character" &&
                      profile_character) ||
                    (stateData?.shadowName === "contemplator" &&
                      profile_contemplator) ||
                    profile_grey_character
                  }
                />
                <MUIRadialChart
                  value={
                    stateData?.grit_score != null && [stateData?.grit_score]
                  }
                  chartName={"grit score"}
                  backgroundRingColor="darkGray"
                  max={100}
                  size={calculateHeight()}
                  countFontSize={16}
                  labelFontSize={12}
                  color={"assesmentColor"}
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  gap: 1,
                  flexWrap: "wrap",
                  padding: "0 15px 15px 15px",
                }}
              >
                {stateData?.UserTraits
                  ? stateData?.UserTraits?.split(",")?.map((item) => {
                      return (
                        <SmallButton
                          fontWeight={500}
                          minWidth="10px"
                          textColor={theme.palette.base.main}
                          height={25}
                          color="precium"
                          borderRadius="5px"
                          label={item}
                        ></SmallButton>
                      );
                    })
                  : "-"}

                <Typography
                  sx={{
                    fontSize: "10px",
                    fontWeight: theme.typography.Regular,
                    textAlign: "left",
                    width: { xs: "100%", lgmm: "431px" },
                  }}
                >
                  The <b>Grit Score</b> is an indicative measure of an
                  individual’s tenacity and determination to persevere in the
                  face of adversity and overcome obstacles towards achieving
                  long-term goals. There is no good or bad score as both a high
                  and low value offer unique perspectives.
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              width: {
                xs: "100%",
                md24: leftExpanded ? "100%" : "60%",
                lg: "60%",
                // lgmm: "540px",
                // lgm: "100%",
                // xl: "681px",
                // xll: "790px",
                // xxl: "1355px",
              },
              display: "flex",
              flexDirection: "column",
              gap: "20px",
            }}
          >
            <Box
              sx={{
                width: {
                  xs: "100%",
                  // lg: "387px",
                  // lgmm: "540px",
                  // lgm: "100%",
                  // xl: "681px",
                  // xll: "790px",
                  // xxl: "1355px",
                },
                // backgroundColor: theme.palette.cvPageBackground.main,
                borderRadius: "25px",
                border: `1px solid ${theme.palette.borderColor}`,
              }}
            >
              <Button
                variant="contained"
                color="workhistory"
                sx={{
                  minWidth: "150px !important",
                  maxWidth: "150px !important",
                  minHeight: "45px",
                  fontSize: "16px",
                  fontWeight: theme.typography.Bold,
                  borderRadius: "25px 0px 10px 0",
                }}
              >
                Experience
              </Button>

              <Box sx={{ padding: "15px" }}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "12px",
                  }}
                >
                  <TalentSVGButton
                    color={"white"}
                    source={workHistory}
                    height={30}
                    width={30}
                    startIconMargin={"0px !important"}
                    padding={"0px !important"}
                  />
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "5px",
                      width: {
                        xs: "100%",
                        // lgmm: "95%"
                      },
                    }}
                  >
                    <Typography
                      sx={{
                        width: {
                          xs: "100%",
                          // lgmm: "605px"
                        },
                        fontSize: "18px",
                        fontWeight: theme.typography.Bold,
                      }}
                    >
                      Work History {`${!stateData?.work_exp ? "-" : ""}`}
                    </Typography>
                    {stateData?.work_exp?.map((item) => {
                      return (
                        <>
                          <Box sx={{ display: "flex" }}>
                            <Typography
                              sx={{
                                fontSize: "14px",
                                fontWeight: theme.typography.Bold,
                                wordWrap: "break-word",
                                display: "inline-block",
                              }}
                            >
                              {item?.title},{" "}
                              {item?.name && (
                                <span
                                  style={{
                                    fontSize: "14px",
                                    fontWeight: theme.typography.Medium,
                                  }}
                                >
                                  {item?.name}
                                </span>
                              )}
                            </Typography>
                          </Box>
                          <Box sx={{ display: "flex", width: "90%" }}>
                            <Typography
                              sx={{
                                fontSize: "14px",
                                fontWeight: theme.typography.Bold,
                                wordWrap: "break-word",
                                display: "inline-block",
                              }}
                            >
                              {monthYear(item?.start_date)} -{" "}
                              {monthYear(item?.end_date)},
                              {item?.title && (
                                <span
                                  style={{
                                    fontSize: "14px",
                                    fontWeight: theme.typography.Regular,
                                  }}
                                >
                                  {`(${calculateDaysBetween(
                                    item?.start_date,
                                    item?.end_date
                                  )})`}
                                </span>
                              )}
                            </Typography>
                          </Box>
                          <Typography
                            sx={{
                              fontWeight: theme.typography.Regular,
                              fontSize: "14px",
                              overflow: "hidden",
                              display: "-webkit-box",
                              WebkitBoxOrient: "vertical",
                              WebkitLineClamp: 3,
                              wordBreak: "break-word",
                            }}
                            color={theme.palette.black100}
                            gutterBottom={true}
                            dangerouslySetInnerHTML={createMarkup(
                              item?.clients_worked_on_awards
                            )}
                          />
                        </>
                      );
                    })}
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    gap: "21px",
                    width: {
                      xs: "100%",
                      // lgmm: "650px"
                    },
                    // flexDirection: { lg: "column", lgm: "row", xl: "row" },
                    flexDirection: "column",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "12px",
                      width: "100%",
                    }}
                  >
                    <TalentSVGButton
                      color={"white"}
                      source={educationHistory}
                      height={30}
                      width={30}
                      startIconMargin={"0px !important"}
                      padding={"0px !important"}
                    />
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "5px",
                        width: {
                          xs: "90%",
                          //  lgmm: "95%"
                        },
                      }}
                    >
                      <Typography
                        sx={{
                          width: "100%",
                          fontSize: "18px",
                          fontWeight: theme.typography.Bold,
                        }}
                      >
                        Educational History
                        {`${
                          (stateData?.UserQualification?.length === 0 &&
                            !stateData?.UserSchool) ||
                          (stateData?.UserQualification === undefined &&
                            stateData?.UserSchool === undefined)
                            ? "-"
                            : ""
                        }`}
                      </Typography>

                      {stateData?.UserQualification?.map((item) => {
                        return (
                          <Box
                            sx={{
                              width: {
                                xs: "95%",
                                //  lgmm: "100%"
                              },
                              minHeight: "60px",
                              marginBottom: "10px",
                            }}
                          >
                            <Tooltip
                              disableInteractive
                              title={`${item?.qualification?.name}, ${item?.institution?.name}`}
                              placement="top-start"
                            >
                              <Typography
                                sx={{
                                  fontSize: "14px",
                                  fontWeight: theme.typography.Bold,
                                  wordWrap: "break-word",
                                  display: "inline-block",
                                }}
                              >
                                {item?.qualification?.name},{" "}
                                {item?.institution?.name && (
                                  <span
                                    style={{
                                      fontSize: "14px",
                                      fontWeight: theme.typography.Medium,
                                    }}
                                  >
                                    {item.institution.name}
                                  </span>
                                )}
                              </Typography>
                            </Tooltip>

                            <Typography
                              sx={{
                                fontSize: "14px",
                                fontWeight: theme.typography.Regular,
                              }}
                            >
                              Completed: {yearConverter(item?.year_ended)}
                            </Typography>
                            <Typography
                              sx={{
                                fontSize: "14px",
                                fontWeight: theme.typography.Regular,
                              }}
                            >
                              Type: {item?.qualification_type?.name}
                            </Typography>
                            {item?.grade && (
                              <Typography
                                sx={{
                                  fontSize: "14px",
                                  fontWeight: theme.typography.Regular,
                                }}
                              >
                                Grade: {`${item?.grade}`}
                              </Typography>
                            )}
                          </Box>
                        );
                      })}
                      {stateData?.UserSchool?.map((item) => {
                        return (
                          <Box
                            sx={{
                              width: "100%",
                              minHeight: "40px",
                            }}
                          >
                            <Box sx={{ display: "flex" }}>
                              <Typography
                                sx={{
                                  fontSize: "14px",
                                  fontWeight: theme.typography.Bold,
                                  textWrap: "wrap",
                                }}
                              >
                                {item?.name}
                              </Typography>
                            </Box>

                            <Typography
                              sx={{
                                fontSize: "14px",
                                fontWeight: theme.typography.Regular,
                              }}
                            >
                              Completed: {item?.year_ended}
                            </Typography>
                          </Box>
                        );
                      })}
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "row",
                      gap: "12px",
                    }}
                  >
                    <TalentSVGButton
                      color={"white"}
                      source={professionalIcon}
                      height={30}
                      width={30}
                      startIconMargin={"0px !important"}
                      padding={"0px !important"}
                    />
                    <Box
                      sx={{
                        width: "100%",
                        minHeight: "60px",
                        display: "flex",
                        flexDirection: "column",
                        gap: "5px",
                      }}
                    >
                      <Typography
                        sx={{
                          marginTop: "6px",
                          lineHeight: "1",
                          fontSize: "18px",
                          fontWeight: theme.typography.Bold,
                        }}
                      >
                        Professional Associations{" "}
                        {`${!stateData?.UserAssociation ? "-" : ""}`}
                      </Typography>
                      {stateData?.UserAssociation?.map((item) => {
                        return (
                          <>
                            <Box
                              sx={{
                                width: "100%",
                                minHeight: "40px",
                              }}
                            >
                              <Box sx={{ display: "flex" }}>
                                <Typography
                                  sx={{
                                    fontSize: "14px",
                                    fontWeight: theme.typography.Bold,
                                  }}
                                >
                                  {item?.name}
                                </Typography>
                              </Box>

                              <Typography
                                sx={{
                                  fontSize: "14px",
                                  fontWeight: theme.typography.Regular,
                                }}
                              >
                                {item?.year_ended}
                              </Typography>
                            </Box>
                          </>
                        );
                      })}
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box
              sx={{
                width: {
                  xs: "100%",
                  // lg: "387px",
                  // lgmm: "540px",
                  // lgm: "100%",
                  // xl: "681px",
                  // xll: "790px",
                  // xxl: "1355px",
                },
                // backgroundColor: theme.palette.cvPageBackground.main,
                borderRadius: "25px",
                border: `1px solid ${theme.palette.borderColor}`,
              }}
            >
              <Button
                variant="contained"
                color="reference"
                sx={{
                  minWidth: "150px !important",
                  maxWidth: "150px !important",
                  minHeight: "45px",
                  fontSize: "16px",
                  fontWeight: theme.typography.Bold,
                  borderRadius: "25px 0px 10px 0",
                }}
              >
                References
              </Button>
              <Box
                sx={{
                  padding: "15px",
                  display: "flex",
                  flexWrap: "wrap",
                  gap: "20px",
                  // justifyContent: "space-between",
                }}
              >
                {stateData?.reference
                  ? stateData?.reference?.map((refer) => (
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          gap: "12px",
                          minWidth: "155px",
                          width: {
                            xs: "90%",
                            //  lgmm: "100%"
                          },
                        }}
                      >
                        <TalentSVGButton
                          color={"white"}
                          source={referenceIcon}
                          height={30}
                          width={30}
                          startIconMargin={"0px !important"}
                          padding={"0px !important"}
                        />
                        <Box
                          sx={{
                            width: {
                              xs: "90%",
                              //  lgmm: "100%"
                            },
                          }}
                        >
                          <Typography
                            sx={{
                              fontSize: "14px",
                              fontWeight: theme.typography.Bold,
                              wordWrap: "break-word",
                            }}
                          >
                            {refer?.name}
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: "14px",
                              fontWeight: theme.typography.Regular,
                              wordWrap: "break-word",
                            }}
                          >
                            {refer?.title}
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: "14px",
                              fontWeight: theme.typography.Regular,
                              wordWrap: "break-word",
                            }}
                          >
                            {refer?.company_name}
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: "14px",
                              fontWeight: theme.typography.Regular,
                              wordWrap: "break-word",
                            }}
                          >
                            {formatPhoneNumberIntl(
                              `${refer?.country_code}${refer?.contact}`
                            )}
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: "14px",
                              fontWeight: theme.typography.Regular,
                              wordWrap: "break-word",
                            }}
                          >
                            {refer?.email}
                          </Typography>
                        </Box>
                      </Box>
                    ))
                  : "-"}
              </Box>
            </Box>
            <Box
              sx={{
                height: "100%",
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "flex-end",
              }}
            >
              <Box
                component={"img"}
                src={crayonLogo}
                sx={{ width: "214px", height: "56px", opacity: 0.5 }}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    )
  );
}
