import styled from "@emotion/styled";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  CircularProgress,
  Grid,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import redTalent from "../../../../assets/Padding Excluded/Black_Talent_Red.svg";
import TalentSVGButton from "../../../common/TalentSVGButton";
import InfoIcon from "../../../common/InfoIcon";
import { useDispatch } from "react-redux";
import SmallButtonTalent from "../../../common/SmallButtonTalent";
import TalentsCard from "../../../common/TalentsCard";
import { buildSearchNew } from "../../../../redux/admin/searchSlice";

const StyledAccordion = styled(Accordion)(({ theme }) => ({
  boxShadow: "none",
  "& .MuiAccordionSummary-root": {
    padding: "0px !important",
  },
  ".MuiPaper-root-MuiAccordion-root": {
    backgroundColor: "#E5E5E5 !important",
  },
}));

const TitleAccordion = ({
  titlesList,
  basicData,
  handleSearch,
  moduleName,
  additionalPayload,
}) => {
  const [candidateList, setCandidateList] = useState({});
  const [currentOpen, setCurrentOpen] = useState(null);
  const [lastKeyy, setLastKeyy] = useState(0);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const mainScrollRef = useRef(null);
  const [isLoadingMore, setIsLoadingMore] = useState(false);

  const getCandidateData = async (titleId, pageNumber) => {
    if (isLoadingMore) return;
    setLoading(true);
    let updatedData = {
      titleListFlag: false,
      title_id: titleId,
      [moduleName]: basicData?.keyword,
    };
    if (additionalPayload) {
      Object.keys(additionalPayload).forEach((key) => {
        updatedData[key] = additionalPayload[key];
      });
    }
    setIsLoadingMore(true);
    const { payload } = await dispatch(
      buildSearchNew({ lastKey: lastKeyy, payload: updatedData })
    );
    if (payload?.status == "success") {
      setLastKeyy(payload?.pageNumber + 1);
      setCandidateList((prevList) => ({
        ...prevList,
        [titleId]: [...(prevList[titleId] || []), ...payload?.data],
      }));
      setLoading(false);
      setIsLoadingMore(false);
    }
  };

  const handleAccordionChange = (titleId) => {
    setCandidateList({});
    setLastKeyy(0);
    setCurrentOpen((prevOpen) => (prevOpen === titleId ? null : titleId));
    if (!candidateList[titleId]) {
      getCandidateData(titleId, 1);
    }
  };

  const handleMainScroll = () => {
    const { scrollTop, scrollHeight, clientHeight } = mainScrollRef?.current;
    if (scrollTop + clientHeight >= scrollHeight - 10) {
      handleSearch(basicData?.keyword);
    }
  };
  const handleAccordionDetailsScroll = (titleId) => (event) => {
    const { scrollTop, scrollHeight, clientHeight } = event?.target;
    const scrollTrigger = scrollHeight * 0.8;
    if (scrollTop + clientHeight >= scrollTrigger && !isLoadingMore) {
      getCandidateData(titleId, lastKeyy);
    }
  };
  return (
    <Grid item xs={12} sm={6} md={8} lg={9} xl={10} sx={{ width: "100%" }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-end",
          mb: 2,
        }}
      >
        <Box
          sx={{
            display: "flex",
            width: "130px",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <TalentSVGButton
            padding={"0px !important"}
            color={"white"}
            source={redTalent}
            height={28}
            width={23}
            startIconMargin={"0px !important"}
          />
          <Typography sx={{ fontSize: "12px", fontWeigh: 900 }}>
            Talent pools
          </Typography>
          <InfoIcon />
        </Box>
        <Box
          sx={{
            display: "flex",
            width: "100px",
            justifyContent: "space-evenly",
            alignItems: "center",
            mr: 2,
          }}
        >
          <Typography sx={{ fontSize: "10px" }}>all talent</Typography>
          <Typography sx={{ fontSize: "10px" }}>my talent</Typography>
        </Box>
      </Box>
      <div
        ref={mainScrollRef}
        onScroll={handleMainScroll}
        style={{ overflowY: "auto", maxHeight: "600px" }}
      >
        {titlesList?.map((title, index) => (
          <StyledAccordion
            expanded={currentOpen === title?.title_id}
            onChange={() => handleAccordionChange(title?.title_id)}
            key={`${index}${title?.title_id}`}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              // aria-controls={`panel${index}-content`}
              key={`panel${index}${title?.title_id}`}
            >
              <Box
                sx={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography sx={{ fontWeight: "bold" }}>
                  {title?.title}
                </Typography>
                <Box sx={{ alignItems: "flex-end" }}>
                  <SmallButtonTalent
                    key={`panel2${index}${title?.title_id}`}
                    fontWeight={700}
                    textColor={"#FFFFFF"}
                    color="recruitButton"
                    label={title?.total}
                    mr={3}
                  />
                  <SmallButtonTalent
                    key={`pane3${index}${title?.title_id}`}
                    fontWeight={700}
                    textColor={"#000"}
                    color="grayButton200"
                    label={title?.mytalent_count}
                  />
                </Box>
              </Box>
            </AccordionSummary>
            <AccordionDetails
              onScroll={handleAccordionDetailsScroll(title?.title_id)}
              style={{
                overflowY: "auto",
                maxHeight: "400px",
                minHeight: "50px",
              }}
            >
              {currentOpen === title?.title_id &&
                candidateList[title?.title_id]?.map((candidate, idx) => (
                  <TalentsCard
                    talentContent={candidate}
                    key={`panel4${idx}${title?.title_id}`}
                    getJobList={getCandidateData}
                    setAllTalent={setCandidateList}
                    telId={candidate?.candidate_user_id}
                  />
                ))}
              {loading && (
                <Box sx={{ textAlign: "center" }}>
                  <CircularProgress />
                </Box>
              )}
            </AccordionDetails>
          </StyledAccordion>
        ))}
      </div>
    </Grid>
  );
};

export default TitleAccordion;
