import { useEffect, useRef, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import { useTheme } from "@mui/material/styles";
import _, { truncate } from "lodash";
import { truncateString } from "../../utils/Common";

export default function ButtonPanel({
  selected = [],
  panelData,
  side,
  color = "filterButtons",
  onChangeFilter = () => {},
  type,
}) {
  const theme = useTheme();
  const buttonRef = useRef();
  const [selectedBtns, setSelectedBtn] = useState([panelData?.[0]?.id]);
  const commonTruncateString = (str, length) => {
    return _.truncate(str, {
      length: length,
      separator: " ",
      omission: "...",
    });
  };
  useEffect(() => {
    // selected.length > 0 &&
    setSelectedBtn(selected?.length > 0 ? selected : [panelData?.[0]?.id]);
  }, [panelData, selected]);

  const removeBtnSelection = (selectedBtnList, id) => {
    selectedBtnList?.splice(
      selectedBtns?.findIndex((selectedBtn) => selectedBtn == id),
      1
    );
  };
  const handleButtonClick = (event, { id, name, title }) => {
    if (title == true) {
      if (selectedBtns?.find((selectedBtn) => selectedBtn == id)) {
        const selectedBtnList = [...selectedBtns];
        id !== 1111 && removeBtnSelection(selectedBtnList, id);
        setSelectedBtn(selectedBtnList);
        onChangeFilter(selectedBtnList);
      } else {
        setSelectedBtn([panelData?.[0]?.id]);
        onChangeFilter([panelData?.[0]?.id]);
      }
    } else {
      if (selectedBtns?.find((selectedBtn) => selectedBtn == id)) {
        const selectedBtnList = [...selectedBtns];
        id !== 1111 && removeBtnSelection(selectedBtnList, id);
        if (selectedBtnList?.length == 0) {
          setSelectedBtn([panelData?.[0]?.id]);
          onChangeFilter([panelData?.[0]?.id]);
        } else {
          setSelectedBtn(selectedBtnList);
          onChangeFilter(selectedBtnList);
        }
      } else {
        if (
          selectedBtns?.find((selectedBtn) => selectedBtn == panelData?.[0]?.id)
        ) {
          const selectedBtnList = [...selectedBtns];
          id !== 1111 && removeBtnSelection(selectedBtnList, id);
          selectedBtnList.push(id);
          setSelectedBtn(selectedBtnList);
          onChangeFilter(selectedBtnList);
        } else {
          setSelectedBtn((prevState) => [...prevState, id]);
          onChangeFilter([...selectedBtns, id]);
        }
      }
    }
  };

  const renderColor = (column) => {
    switch (column) {
      case "review":
        return "takealot";
      case "shortlist":
        return "oros";
      case "interview":
        return "peachPayment";
      case "assessment":
        return "precium";
      case "offer":
        return "outsurance";
      case "hired":
        return "springboks";
      default:
        return "filterButtons";
    }
  };

  return (
    // <Grid xs >
    <Box
      sx={{
        // mt: { sx: 0, sm: topMargin ? '68px' : '16px' },
        // textAlign: side === "right" && "end",
        // display: { xs: "none", sm: "flex" },
        display: { xs: "flex" },
        flexDirection: "column",
        gap: "10px",
        // overflow: { xs: "auto", sm: "hidden" },
      }}
    >
      {panelData?.map((btn) => (
        <Tooltip
          disableInteractive
          title={btn?.name}
          key={btn?.id}
          placement="top-end"
        >
          <Button
            sx={{
              padding: "6px 7px",
              borderRadius: "10px",
              // fontSize: "14px",
              fontSize: theme.typography.fontSize.xs,
              borderBottomLeftRadius: side === "left" ? { sm: 0 } : "10px",
              borderTopLeftRadius: side === "left" ? { sm: 0 } : "10px",
              borderTopRightRadius: side === "right" ? { sm: 0 } : "10px",
              borderBottomRightRadius: side === "right" ? { sm: 0 } : "10px",
              textOverflow: "ellipsis",
              overflow: type !== "common" && "hidden",
              wordBreak: type === "common" && "break-word",
              whiteSpace: "normal",
              lineHeight: 1.2,
              display: "-webkit-box",
              WebkitLineClamp: 2,
              WebkitBoxOrient: "vertical",
              minWidth: "125px",
              fontWeight:
                btn.title || selectedBtns?.includes(btn?.id)
                  ? theme.typography.Bold
                  : theme.typography.Regular,
              "&:hover": {
                backgroundColor: theme?.palette?.[renderColor(btn?.name)]?.main,
                color: theme?.palette?.white,
                fontWeight: theme.typography.Bold,
              },
              height: "45px",
              textTransform: btn?.name?.includes("crayon")
                ? "capitalize"
                : "unset",
            }}
            onClick={(e) => handleButtonClick(e, btn)}
            disableElevation
            variant="contained"
            color={
              selectedBtns?.includes(btn?.id) ? renderColor(btn?.name) : "base"
            }
            key={btn.id}
            ref={buttonRef}
          >
            {type === "common"
              ? commonTruncateString(btn?.name, 28, buttonRef.current)
              : truncateString(btn?.name, 20, buttonRef.current)}
          </Button>
        </Tooltip>
      ))}
    </Box>
    // </Grid>
  );
}
