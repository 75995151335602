import { Box, Grid } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useDispatch } from "react-redux";
import { setLoading } from "../../../redux/configSlice";
import { BASIC_SEARCH } from "../../../utils/Constants";
import { getMySpec } from "../../../redux/employer/empJobListing";
import useViewport from "../ViewPort";
import { Button, Paper, ThemeProvider } from "@mui/material";
import { employerMyJobsTheme } from "../../../utils/componentThemes/employerMyJobsTheme";
import MyJobsCard from "../../common/myJobsSectionEmployerAndRecruiter/MyJobsCardNew";
import CustomDialog from "../CustomDialog";
import ProfileNotCompleted from "../ProfileNotCompleted";
import NoJobsBox from "../myJobsSectionEmployerAndRecruiter/NoJobsBox";
import MySpecFlipCardComponent from "../../common/myJobsSectionEmployerAndRecruiter/MySpecFlipCardComponent"

const MySpec = ({
  specSelectBtn,
  setItemsFetched,
  newPadding,
  newCardWidth,
  setActiveStep,
  setSelectedPage
}) => {
  const gridRef = useRef();
  const [allJobs, setAllJobs] = useState([]);
  const [lastKey, setLastKey] = useState("");
  const dispatch = useDispatch();
  const [basicData, setBasicData] = useState(BASIC_SEARCH);
  const [showBox, setShowBox] = useState(false);
  const [currentPageJobsCount, setCurrentPageJobsCount] = useState(0);
  const { width } = useViewport();
  // const [newPadding, setNewPadding] = useState(15);
  const [showNoInfoBox, setShowNoInfoBox] = useState(false);
  // const [newCardWidth, setNewCardWidth] = useState(340);
  const [jobListFilter, setJobListFilter] = useState([]);

  let obj = {
    0: "mySpec",
    1: "jobposted",
    2: "jobdraft",
  };

  const calculatedHeight = () => {
    // Calculate the desired height based on screen resolution or any other logic
    // For example, you can use window.innerWidth or window.innerHeight to adjust the height dynamically.
    const screenWidth = window.innerWidth;
    if (screenWidth < 600) {
      // xs
      return `calc(100vh - 95px)`;
    } else if (screenWidth < 900) {
      // sm
      return `calc(100vh - 80px)`;
    } else if (screenWidth < 1366) {
      // md
      return `calc(100vh - 90px)`;
    } else if (screenWidth < 1920) {
      // lg
      return `calc(100vh - 80px)`;
    } else if (screenWidth < 2559) {
      // xl
      return `calc(100vh - 100px)`;
    } else {
      // xxl or greater
      return `calc(100vh - 120px)`;
    }
  };
  const getJobList = async (
    selectedFilters = jobListFilter,
    lastkey = lastKey
  ) => {
    let specFilter = obj[specSelectBtn];
    dispatch(setLoading({ value: true, apiName: "getJobList" }));
    const data = {
      selectedFilters:
        selectedFilters?.[0] !== 1111 ? selectedFilters?.toString() : "",
      lastKey: lastkey,
      ...basicData,
      mySpec: specFilter,
    };
    const { payload } = await dispatch(getMySpec(data));
    if (payload?.status === "success") {
      if (payload?.data?.length === 0 && payload?.pagenumber === 0) {
        setShowBox(true);
        setCurrentPageJobsCount(payload?.data?.length);
      } else {
        setCurrentPageJobsCount(payload?.data?.length);
        setShowBox(false);
        setLastKey(payload?.pagenumber + 1);
        setAllJobs((prevState) => [...prevState, ...payload?.data]);
        setItemsFetched(true);
      }
      // setIsDataLoading(false);
    }
    dispatch(setLoading({ value: false, apiName: "getJobList" }));
  };

  const handleSubmitBtn = () =>{
    setActiveStep(0);
    setSelectedPage(1);
  }

  useEffect(() => {
    setAllJobs([]);
    setLastKey(0);
    getJobList(jobListFilter, 0);
  }, [specSelectBtn]);
  
  return (
    // <Box
    //   item
    //   sx={{
    //     display: "flex",
    //     flexGrow: 1,
    //     alignItems: "center",
    //     flexDirection: "column",
    //     width: `calc(100vw - 255px)`,
    //     position: "sticky",
    //   }}
    //   ref={gridRef}
    //   className="grigBox"
    // >
    <InfiniteScroll
      key={``}
      height={calculatedHeight()}
      dataLength={allJobs.length}
      scrollThreshold={"100px"}
      next={() => getJobList(jobListFilter, lastKey)}
      style={{
        overflowX: "hidden",
      }}
      hasMore={true}
    >
      <Grid
        container
        gap={width <= 768 ? "10px" : `${newPadding}px`}
        columns={{
          xs: 1,
          md: 1,
          lg: 3,
          mlg: 3.5,
          xl: 4,
          xxl: 6,
          xxxl: 8,
        }}
        justifyContent={width <= 768 && "center"}
        sx={{
          position: "relative",
          padding: width <= 768 ? "10px 10px" : `0 ${newPadding}px`,
          top: width <= 768 ? "45px" : "55px",
        }}
      >
        {allJobs?.length > 0 && !showNoInfoBox ? (
          allJobs?.map((job, index) => (
            <Box
            sx={{
              width: newCardWidth,
              height: { xs: "556px", lg: "556px", xl: "582px" },
            }}
          >
            <ThemeProvider theme={employerMyJobsTheme}>
              <MySpecFlipCardComponent  
                index={index}
                job={job}
                newCardWidth={newCardWidth}
                getJobs={getJobList}
                setAllJobs={setAllJobs}
                allJobs={allJobs}
                jobslength={allJobs?.length}
                currentPageJobsCount={currentPageJobsCount}
                setActiveStep={setActiveStep}
                setSelectedPage={setSelectedPage}
              />
            </ThemeProvider>
          </Box>
          ))
        ): (
          showBox && (
            <Box
              sx={{
                textAlign: "center",
                fontWeight: "bold",
                margin: "auto",
              }}
            >
              <NoJobsBox handleSubmitBtn={handleSubmitBtn} page="mySpec"/>
            </Box>
          )
        )}
      </Grid>

      <style>
        {`.infinite-scroll-component::-webkit-scrollbar {
                  display:none
                }
                .infinite-scroll-component__outerdiv {
                  width: 100%
                }`}
      </style>
    </InfiniteScroll>
    // </Box>
  );
};

export default MySpec;
