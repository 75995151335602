import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getApi, postApi } from "../../utils/Apis";
import { setLoading } from "../configSlice";
const initialState = {
  talent: [],
  talentList: [],
  talentNextPageID: "",
  showBox: false,
  currentPageJobsCount: 0,
};
export const getTalentList = createAsyncThunk(
  "getTalentList",
  async (
    {
      selectedFilters,
      lastKey,
      personalityType,
      user_id,
      jobtype,
      tag_id = "",
      tool_id = "",
      talent_title = "",
      town_id = "",
      region_id = "",
      salary = "",
      experience = "",
      highest_qualification_id = "",
      currency_id = "",
    },
    { dispatch }
  ) => {
    dispatch(setLoading(true));
    const { data } = await getApi(
      "/gettalentslist?lastKey=" +
        lastKey +
        "&user_id=" +
        user_id +
        "&industry_id=" +
        selectedFilters +
        "&personalitytype_id=" +
        personalityType +
        "&jobtype_id=" +
        jobtype +
        "&tag_id=" +
        tag_id +
        "&tool_id=" +
        tool_id +
        "&talent_title=" +
        talent_title +
        "&town_id=" +
        town_id +
        "&region_id=" +
        region_id +
        "&salary=" +
        salary +
        "&experience=" +
        experience +
        "&highest_qualification_id=" +
        highest_qualification_id +
        "&currency_id=" +
        currency_id
    );
    dispatch(setLoading(false));
    return data;
  }
);

export const favouriteJob = createAsyncThunk(
  "favouriteJob",
  async (payload, { dispatch }) => {
    dispatch(setLoading(true));
    const { data } = await postApi("/favouritejob", payload, true);
    dispatch(setLoading(false));
    return data;
  }
);

export const setReferal = createAsyncThunk(
  "setReferal",
  async (payload, { dispatch }) => {
    dispatch(setLoading(true));
    const { data } = await postApi("/promoter/sharejob", payload, true);
    dispatch(setLoading(false));
    return data;
  }
);

export const convertToPromoter = createAsyncThunk(
  "convertToPromoter",
  async (payload, { dispatch }) => {
    dispatch(setLoading(true));
    const { data } = await postApi(
      "/promoter/converttopromoter",
      payload,
      true
    );
    dispatch(setLoading(false));
    return data;
  }
);
// http://localhost:3002/promoter/getSocialUrls?job_id=1321

export const getShareUrl = createAsyncThunk(
  "getShareUrl",
  async (payload, { dispatch }) => {
    const { data } = await getApi(
      "/promoter/getSocialUrls?job_id=" + payload,
      true
    );
    return data;
  }
);

export const favouriteCompany = createAsyncThunk(
  "favouriteCompany",
  async (payload, { dispatch }) => {
    dispatch(setLoading(true));
    const { data } = await postApi("/favouritecompany", payload, true);
    dispatch(setLoading(false));
    return data;
  }
);

export const referAFriend = createAsyncThunk(
  "referAFriend",
  async (payload, { dispatch }) => {
    dispatch(setLoading(true));
    const { data } = await postApi("/referfriend", payload, true);
    dispatch(setLoading(false));
    return data;
  }
);

export const getFilteredTalent = createAsyncThunk(
  "getFilteredTalent",
  async (
    {
      selectedFilters,
      nextPageId = "",
      personalityType,
      jobtype,
      tag_id = "",
      tool_id = "",
      talent_title = "",
      town_id = "",
      region_id = "",
      salary = "",
      experience = "",
      highest_qualification_id = "",
      currency_id = "",
      employment_type = "",
      work_setup = "",
      recent = "",
      active = "",
      indemand = "",
      favourite = "",
      mytalent = "",
      decodedToken,
      query = "",
      company_id = "",
    },
    { dispatch }
  ) => {
    if (selectedFilters === "1111" || selectedFilters === "all industries") {
      selectedFilters = "";
    }
    if (jobtype === "1111" || jobtype === "all talent") {
      jobtype = "";
    }
    if (personalityType === "1111" || personalityType === "all types") {
      personalityType = "";
    }

    const { data } = await getApi(
      "/gettalentslist?nextPageId=" +
        nextPageId +
        "&industry_id=" +
        selectedFilters +
        "&personalitytype_id=" +
        personalityType +
        "&jobtype_id=" +
        jobtype +
        "&tag_id=" +
        tag_id +
        "&tool_id=" +
        tool_id +
        "&query=" +
        query +
        "&talent_title=" +
        talent_title +
        "&town_id=" +
        town_id +
        "&region_id=" +
        region_id +
        "&salary=" +
        salary +
        "&experience=" +
        experience +
        "&highest_qualification_id=" +
        highest_qualification_id +
        "&currency_id=" +
        currency_id +
        "&employment_type=" +
        employment_type +
        "&work_setup=" +
        work_setup +
        "&recent=" +
        recent +
        "&active=" +
        active +
        "&indemand=" +
        indemand +
        "&favourite=" +
        favourite +
        "&mytalent=" +
        mytalent +
        "&company_id=" +
        company_id
      // isAuthorize
    );
    return data;
  }
);

export const getTalentBackSideData = createAsyncThunk(
  "getTalentBackSideData",
  async ({ candidate_id }, { dispatch }) => {
    const { data } = await getApi(
      `/candidatefilpdetails?candidate_id=${candidate_id}`,
      true
    );
    return data;
  }
);

export const getTalentMatch = createAsyncThunk(
  "getTalentMatch",
  async (payload, { dispatch }) => {
    const { data } = await getApi(
      "/employer/getEmployerJobList?lastKey=" + payload?.lastKey,
      true
    );
    return data;
  }
);

export const candidateShortlist = createAsyncThunk(
  "candidateShortlist",
  async (payload, { dispatch }) => {
    dispatch(setLoading(true));
    const { data } = await postApi(
      "/candidate/shortlistcandidate",
      payload,
      true
    );
    dispatch(setLoading(false));
    return data;
  }
);

export const talentSlice = createSlice({
  name: "configtalent",
  initialState,
  reducers: {
    updateAllTalent: (state, action) => {
      return {
        ...state,
        talentList: [],
        showBox: false,
        currentPageJobsCount: 0,
      };
    },
    setTalentList: (state, action) => {
      state.talentList = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getFilteredTalent?.fulfilled, (state, action) => {
      const data = action?.payload?.data || [];
      if (data?.length <= 0) {
        state.showBox = true;
        state.currentPageJobsCount = 0;
        state.talentNextPageID = "";
      } else {
        state.currentPageJobsCount = data?.length;
        state.talentNextPageID = data?.nextPageId;
      }
      state.talentNextPageID = action?.payload?.nextPageId;
      state.talentList = [
        ...state?.talentList,
        ...data?.map((talent) => ({
          ...talent,
        })),
      ];
    });
  },
});
// Action creators are generated for each case reducer function
export const { updateAllTalent, setTalentList } = talentSlice.actions;

export default talentSlice.reducer;
