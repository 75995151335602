import React, { useState } from "react";
import {
  Avatar,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  ThemeProvider,
  Typography,
} from "@mui/material";
import forgotPasswordIcon from "../../assets/ConfirmForgotPassword.svg";
import { useTheme } from "@emotion/react";
import { forgotPasswordPagetheme } from "../../utils/Theme";
import CloseIcon from "../common/CloseIcon";

const ForgotPasswordConfirmationModal = ({
  openFunc,
  closeFunc,
}) => {
  const theme = useTheme();

  return (
    <ThemeProvider theme={forgotPasswordPagetheme}>
      <Dialog
        dialogWidth="sm"
        padding={0}
        showFooter={false}
        scroll="body"
        sx={{
          zIndex: 100000,
          margin: "0px",
          "& .MuiPaper-root": {
            borderRadius: "25px",
            backgroundColor: "backgroundColor",
            margin: "0px",
          },
          "& .MuiDialog-paperScrollBody": {
            width: "100%",
            maxWidth: { xs: "97%", tablet: "450px" },
          },
          "& .MuiPaper-root-MuiDialog-paper": {
            width: "100%",
            maxWidth: "100%",
          },
        }}
        open={openFunc}
        onDialogClose={closeFunc}
      >
        <DialogTitle onClose={closeFunc} sx={{ padding: "0px" }}>
          <CloseIcon onClick={closeFunc} />
        </DialogTitle>
        <Box
          sx={{
            width: "100%",
            marginTop: "25px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            paddingBottom: "0px",
          }}
        >
          <Avatar src={forgotPasswordIcon} sx={{ width: 96, height: 125 }} />
          <Typography
            sx={{
              fontSize: "20px",
              lineHeight: "27px",
              marginBottom: "20px",
              fontWeight: theme.typography.Bold,
              textAlign: "center",
            }}
          >
            Thanks, your password reset <br />
            link has been sent.
          </Typography>
          <Typography
            sx={{
              fontSize: "16px",
              lineHeight: "22px",
              marginBottom: "20px",

              fontWeight: theme.typography.Bold,
            }}
          >
            Who’saGoodBooi?@2!
          </Typography>
          <Typography
            sx={{
              fontSize: "14px",
              marginBottom: "20px",
              fontWeight: theme.typography.Regular,
            }}
          >
            Please check your inbox and spam folder in case it’s not there.
          </Typography>
        </Box>
        <DialogActions
          sx={{
            display: "flex",
            justifyContent: "center",
            overflow: "hidden",
            padding: 0,
          }}
        >
          <Button
            sx={{
              width: "100%",
              borderRadius: 0,
              padding: 3,
              height: "60px",
              marginLeft: "0 !important",
              fontSize: "14px",
              fontWeight: theme.typography.Bold,
            }}
            variant="contained"
            color="resetButton"
            onClick={closeFunc}
          >
            close
          </Button>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
};

export default ForgotPasswordConfirmationModal;
