import { Box, Button, useTheme } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useQuery } from "../../utils/Common";
import { useParams } from "react-router-dom";

export default function QualificationBox({
  setQualification,
  buttonID,
  handleClosePopover,
  handleFilterSelection,
  setIsFilterApplied,
  getTalentMyJobStatusCount,
  setBasicData,
  requiredQua,
  onFilterSelection,
  setOpenPopovers,
  openPopovers,
  setSelectedButton,
  panelData,
}) {
  const theme = useTheme();
  const query = useQuery();
  const boxRef = useRef();
  const { jobId } = useParams();
  const [selectedBtns, setSelectedBtn] = useState([]);
  const [openDropDown, setOpenDropDownId] = useState(null);

  const qualificationRef = useRef(selectedBtns);

  useEffect(() => {
    qualificationRef.current = selectedBtns;
    qualificationRef?.current?.length &&
      setQualification(qualificationRef?.current);
  }, [selectedBtns]);

  useEffect(() => {
    let companies = [];
    const companySizeParams = query?.get("qualification");
    if (companySizeParams) {
      const sizes = companySizeParams?.split(",");
      sizes?.map((items) => {
        let selectedSize = requiredQua?.find((item) => item?.name === items);
        companies?.push(selectedSize?.id);
      });
      setSelectedBtn(companies);
    }
  }, []);

  const removeBtnSelection = (selectedBtnList, id) => {
    selectedBtnList?.splice(
      selectedBtns?.findIndex((selectedBtn) => selectedBtn === id),
      1
    );
  };
  const handleButtonClick = async (event, { id, name, title, dropDown }) => {
    onFilterSelection();
    if (dropDown) {
      setOpenDropDownId((prevState) => (prevState === id ? null : id));
      return;
    }
    if (title === true) {
      if (selectedBtns?.find((selectedBtn) => selectedBtn === id)) {
        const selectedBtnList = [...selectedBtns];
        removeBtnSelection(selectedBtnList, id);
        setSelectedBtn(selectedBtnList);
      } else {
      }
    } else {
      if (selectedBtns?.find((selectedBtn) => selectedBtn === id)) {
        const selectedBtnList = [...selectedBtns];
        removeBtnSelection(selectedBtnList, id);
        setSelectedBtn(selectedBtnList);
      } else {
        if (
          selectedBtns?.find(
            (selectedBtn) => selectedBtn === requiredQua?.[0]?.id
          )
        ) {
          const selectedBtnList = [...selectedBtns];
          selectedBtnList?.push(id);
          setSelectedBtn(selectedBtnList);
        } else {
          setSelectedBtn((prevState) => [...prevState, id]);
        }
      }
    }

    if (!qualificationRef?.current?.includes(id)) {
      qualificationRef?.current?.push(id);

      const qualificationName = qualificationRef?.current?.map((item) => {
        return requiredQua?.find((qua) => qua?.id === item)?.name;
      });

      handleFilterSelection(
        "qualification",
        qualificationRef?.current?.length > 0
          ? qualificationName
          : "qualification"
      );
      setIsFilterApplied(true);

      setBasicData((prevBasicData) => ({
        ...prevBasicData,
        qualification_id: qualificationRef?.current?.join(","),
      }));
      handleClosePopover(buttonID);
      await getTalentMyJobStatusCount(jobId);
    } else {
      const index = qualificationRef?.current?.indexOf(id);
      if (index !== -1) {
        qualificationRef?.current?.splice(index, 1);
      }
      const qualificationName = qualificationRef?.current?.map((item) => {
        return requiredQua?.find((qua) => qua?.id === item)?.name;
      });
      handleFilterSelection(
        "qualification",
        qualificationRef?.current?.length > 0
          ? qualificationName
          : "qualification"
      );
      setIsFilterApplied(true);
      setBasicData((prevBasicData) => ({
        ...prevBasicData,
        qualification_id: qualificationRef?.current?.join(","),
      }));
      handleClosePopover(buttonID);
      await getTalentMyJobStatusCount(jobId);
    }
  };

  const handleClickOutside = (event) => {
    if (
      boxRef?.current &&
      !boxRef?.current?.contains(event?.target) &&
      qualificationRef?.current?.length === 0
    ) {
      const initialOpenState = {};
      panelData?.forEach((item) => {
        initialOpenState[item?.id] = false;
      });
      setOpenPopovers(initialOpenState);
      setSelectedButton([1111]);
    }
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <Box
      ref={boxRef}
      sx={{
        width: 370,
        gap: "8px",
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      {requiredQua?.map((item) => {
        return (
          <Button
            sx={{
              padding: "6px 7px",
              // paddingTop: item?.id === 1 && "10px",
              lineHeight: "inherit",
              borderRadius: "5px",
              width: "100%",
              borderBottomRightRadius: "5px",
              mr: { xs: 1, sm: 0 },
              minWidth: { xs: "90px", sm: 0 },
              fontSize: { lg: "12px", xl: "16px" },
              fontWeight: selectedBtns?.includes(item?.id)
                ? theme?.typography?.Bold
                : theme?.typography?.Regular,
              "&:hover": {
                backgroundColor: theme?.palette?.redButton200?.main,
                color: theme?.palette?.white,
                fontWeight: theme?.typography?.Bold,
              },
            }}
            onClick={(e) => {
              handleButtonClick(e, item);
            }}
            disableElevation
            variant="contained"
            color={selectedBtns?.includes(item?.id) ? "redButton200" : "base"}
            key={item?.id}
          >
            {item?.name}
          </Button>
        );
      })}
    </Box>
  );
}
