import {  InputBase, InputLabel, Modal, Paper } from "@mui/material";
import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import "cropperjs/dist/cropper.css";
import { useTheme } from "@emotion/react";
import CustomSelect from "../../candidate/myCV/CustomSelect";
import { useDispatch } from "react-redux";
import {
  addSpendItem,
  editSpendItem,
} from "../../../redux/admin/maintenance";
import { setAlert } from "../../../redux/configSlice";
import { ALERT_TYPE } from "../../../utils/Constants";
import * as Yup from "yup";
import { validateSchema } from "../../../utils/Common";

const AddSpendCoinModal = ({
  handleOpen,
  handleClose,
  handleCreateSelectOnChange,
  setIsAddSpendCoin,
  edit,
  setIsEdit,
  setSelectedCoinItem,
  getSpendList,
  selectedCoinItem,
}) => {
  const [formData, setFormData] = useState({});
  const [errors, setErrors] = useState({});
  const dispatch = useDispatch();
  const formSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    amount: Yup.number()
      .typeError("Amount must be a number")
      .required("Amount is required")
      .min(0, "Amount must be at least 0"),
    transaction_type: Yup.string().required("Transaction type is required"),
    discount: Yup.number()
      .typeError("Discount must be a number")
      .required("Discount is required")
      .min(0, "Discount must be at least 0")
      .max(100, "Discount cannot be more than 100"),
  });

  const theme = useTheme();
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: { xs: "auto", tablet: 700 },
    bgcolor: "background.paper",
    borderRadius: "25px",
    boxShadow: "none !important",
    "&:focus-visible": {
      outline: "none",
    },
  };

  const handleFormInput = (event, name) => {
    event?.preventDefault();
    event?.stopPropagation();
    setFormData((prev) => ({ ...prev, [name]: event?.target?.value }));
    const updatedError = errors;
    delete updatedError[name];
    setErrors(updatedError);
  };

  const handleSelectOnChange = (newValue, inputId) => {
    let updatedState = { ...formData };
    updatedState["transaction_type"] = inputId;
    setFormData({ ...updatedState });
  };

  const transitionTypeList = [
    { label: "True", value: "1" },
    { label: "False", value: "0" },
  ];

  const addItem = async () => {
    let payload = {
      name: formData?.name,
      amount: formData?.amount,
      transaction_type:
        formData?.transaction_type?.value === "0" ? false : true,
      discount: formData?.discount,
    };
    validateSchema(formSchema, payload)
      .then(() => {
        dispatch(addSpendItem(payload)).then((payload) => {
          if (payload?.payload?.status === "success") {
            getSpendList();
            handleCancel();
          } else {
            dispatch(
              setAlert({
                show: true,
                type: ALERT_TYPE?.ERROR,
                msg: "Something went wrong",
              })
            );
          }
        });
      })
      .catch((error) => {
        setErrors(error);
      });
  };

  const editItem = async () => {
    let payload = {
      transaction_name_id: selectedCoinItem?.transaction_name_id,
      name: formData?.name,
      amount: formData?.amount,
      transaction_type:
        formData?.transaction_type?.value === "0" ? false : true,
      discount: formData?.discount,
    };
    validateSchema(formSchema, payload)
      .then(() => {
        dispatch(editSpendItem(payload)).then((payload) => {
          if (payload?.payload?.status === "success") {
            getSpendList();
            handleCancel();
          } else {
            dispatch(
              setAlert({
                show: true,
                type: ALERT_TYPE?.ERROR,
                msg: "Something went wrong",
              })
            );
          }
        });
      })
      .catch((error) => {
        setErrors(error);
      });
  };
  const handleCreate = () => {
    if (edit) {
      editItem();
    } else {
      addItem();
    }
  };

  const handleCancel = () => {
    setIsEdit(false);
    setIsAddSpendCoin(false);
    setSelectedCoinItem({});
    setFormData({});
    setErrors({});
  };
  useEffect(() => {
    if (selectedCoinItem) {
      let updatedData = {
        name: selectedCoinItem?.name,
        amount: selectedCoinItem?.amount,
        transaction_type:
          selectedCoinItem?.transaction_type === false
            ? { label: "False", value: "0" }
            : { label: "True", value: "1" },
        discount: selectedCoinItem?.discount,
      };
      setFormData({ ...updatedData });
    }
  }, [selectedCoinItem]);

  return (
    <Modal open={handleOpen} onClose={handleClose}>
      <Box sx={style}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "end",
          }}
        >
          <Button
            variant="contained"
            color="yellowButton400"
            sx={{
              height: "45px",
              width: "200px",
              borderRadius: "0 25px 0 25px",
            }}
          >
            {edit ? "Edit Spend Coins" : "Add Spend Coins"}
          </Button>
        </Box>
        <Box sx={{ padding: "20px" }}>
          <Box sx={{ marginBottom: "5px" }}>
            <InputLabel
              htmlFor="name"
              sx={{
                color: "black",
                paddingBottom: "2px",
                fontSize: "14px",
                fontWeight: 600,
              }}
            >
              Name
            </InputLabel>
            <Paper
              elevation={3}
              sx={{
                display: "flex",
                borderRadius: "10px",
                height: "40px",
                boxShadow: "none",
                border: errors?.["name"]
                  ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
                  : `1px solid ${theme.palette.grayBorder}`,
              }}
            >
              <InputBase
                sx={{ ml: 2, mr: 2, width: "100%" }}
                id="name"
                type="text"
                placeholder={"Enter your name"}
                value={formData?.name}
                onChange={(e) => handleFormInput(e, "name")}
              />
            </Paper>
          </Box>
          <Box sx={{ marginBottom: "5px" }}>
            <InputLabel
              htmlFor="amount"
              sx={{
                color: "black",
                paddingBottom: "2px",
                fontSize: "14px",
                fontWeight: 600,
              }}
            >
              Amount
            </InputLabel>
            <Paper
              elevation={3}
              sx={{
                display: "flex",
                borderRadius: "10px",
                height: "40px",
                boxShadow: "none",
                border: errors?.["amount"]
                  ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
                  : `1px solid ${theme.palette.grayBorder}`,
              }}
            >
              <InputBase
                type="number"
                sx={{ ml: 2, mr: 2, width: "100%" }}
                id="amount"
                placeholder={"Enter your amount"}
                value={formData?.amount}
                onChange={(e) => handleFormInput(e, "amount")}
              />
            </Paper>
          </Box>
          <Box sx={{ marginBottom: "5px" }}>
            <InputLabel
              htmlFor="transaction_type"
              sx={{
                color: "black",
                paddingBottom: "2px",
                fontSize: "14px",
                fontWeight: 600,
              }}
            >
              Transition Type
            </InputLabel>
            <CustomSelect
              onMenuOpen={() => {}}
              variant="default"
              onInputChange={handleCreateSelectOnChange}
              onChange={handleSelectOnChange}
              placeholder={`Select RoleType`}
              inputId="transaction_type"
              name="transaction_type"
              isClearable={false}
              value={formData?.transaction_type}
              customStyle={{
                menu: (provided) => ({
                  ...provided,
                  zIndex: 9999,
                  width: "100%",
                }),
                menuList: (provided) => ({
                  ...provided,
                  "&::-webkit-scrollbar": {
                    width: "6px",
                    height: "6px",
                    borderRadius: "2.5px",
                    backgroundColor: "#efefef",
                  },
                  "&::-webkit-scrollbar-thumb": {
                    borderRadius: "2.5px",
                  },
                  width: "100%",
                }),
                option: (
                  styles,
                  { data, isDisabled, isFocused, isSelected }
                ) => {
                  return {
                    ...styles,
                    color: isSelected ? "#fff" : "black",
                    ":active": {
                      ...styles[":active"],
                      width: "100%",
                    },
                  };
                },
                control: (baseStyles) => ({
                  ...baseStyles,
                  boxShadow: "none",
                  borderRadius: "10px",
                  border: "1px solid #E0E0E0",
                  "&:hover": "1px solid #E0E0E0",
                  width: `100%`,
                }),
              }}
              option={transitionTypeList?.map((item) => ({
                value: item?.value,
                label: item?.label,
              }))}
            />
          </Box>
          <Box sx={{ marginBottom: "5px" }}>
            <InputLabel
              htmlFor="discount"
              sx={{
                color: "black",
                paddingBottom: "2px",
                fontSize: "14px",
                fontWeight: 600,
              }}
            >
              Discount
            </InputLabel>
            <Paper
              elevation={3}
              sx={{
                display: "flex",
                borderRadius: "10px",
                height: "40px",
                boxShadow: "none",
                border: errors?.["discount"]
                  ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
                  : `1px solid ${theme.palette.grayBorder}`,
              }}
            >
              <InputBase
                type="number"
                sx={{ ml: 2, mr: 2, width: "100%" }}
                id="discount"
                placeholder={"Enter your discount in %"}
                value={formData?.discount}
                onChange={(e) => handleFormInput(e, "discount")}
              />
            </Paper>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            width: "100%",
          }}
        >
          <Button
            variant="contained"
            color="grayButton100"
            sx={{
              borderRadius: 0,
              width: "50%",
              height: "47px",
              borderBottomLeftRadius: 25,
            }}
            onClick={handleCancel}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="yellowButton100"
            sx={{
              borderRadius: 0,
              width: "50%",
              height: "47px",
              borderBottomRightRadius: 25,
            }}
            onClick={handleCreate}
          >
            {edit ? "Edit" : "Create"}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default AddSpendCoinModal;
