import { useCallback, useEffect, useRef, useState } from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import {
  CANDIDATE_MY_PROFILE_LEFT,
  CVBASIC,
  STUDY_LIFE_STUDY,
  TEMPLATE_NAME,
  WORK,
  steps,
} from "../../../utils/Constants";
import { useDispatch } from "react-redux";
import TheBasicsNew from "./TheBasicsNew";
import StudyLifeNew from "./StudyLifeNew";
import { useNavigate } from "react-router-dom";
import {
  Paper,
  Popover,
  Step,
  StepLabel,
  Stepper,
  useTheme,
} from "@mui/material";
import Modal from "@mui/material/Modal";
import { CheckCircle } from "@mui/icons-material";
import WorkLifeNew from "./WorkLifeNew";
import References from "./References";
import LeftArrow from "../../common/LeftArrow";
import RightArrow from "../../common/RightArrow";
import upClose from "../../../assets/Black_Up_Close.svg";
import downClose from "../../../assets/Black_Down_Open.svg";
import LeftSideProfilePanel from "../myProfile/LeftSideProfilePanel";
import {
  getProfileProgressData,
  setCurrentStep,
  setCvSteps,
} from "../../../redux/candidate/myProfileSlice";
import CandidateCVPageNew from "../cvPage/CandidateCVPageNew";
import ReactToPrint from "react-to-print";
import { deepCompare, getDecodedToken, getToken } from "../../../utils/Common";
import { useSelector } from "react-redux";
import useViewport from "../../common/ViewPort";
import ClassicCVPage from "../cvPage/ClassicCVPage";
import { setFilterExpanded } from "../../../redux/guest/jobsSlice";

export default function MyCV({ handleCandidateDynamicTheme }) {
  const { width } = useViewport();
  const REFERENCE = {
    reference_id: null,
    name: "",
    company_name: "",
    title: "",
    contact: "",
    email: "",
    country_code: "",
  };
  const [step, setStep] = useState(0);
  const dispatch = useDispatch();
  const theme = useTheme();
  const pdfRef = useRef();
  const navigate = useNavigate();
  const boxRef = useRef();
  const currentStep = useSelector((state) => state.myProfileSli.currentStep);
  const filterExpanded = useSelector(
    (state) => state?.configjobslice?.filterExpanded
  );
  const [progressButton, setProgressButton] = useState(false);
  const [displayD, setDisplayD] = useState("none");
  const [expanded, setExpanded] = useState(false);
  const [completed, setCompleted] = useState({});
  const [leftExpanded, setLeftExpand] = useState(true);
  const [rightExpand, setRightExpand] = useState(true);
  const [anchorEl, setAnchorEl] = useState(null);
  const onBeforeGetContentResolve = useRef(null);
  const [basicData, setBasicData] = useState(CVBASIC);
  const [initialData, setInitialData] = useState(CVBASIC);
  const [workData, setWorkData] = useState([WORK]);
  const [initialWorkData, setInitialWorkData] = useState([WORK]);
  const [studyData, setStudyData] = useState(STUDY_LIFE_STUDY);
  const [initialStudyData, setInitialStudyData] = useState(STUDY_LIFE_STUDY);
  const [referenceData, setReferenceData] = useState([REFERENCE]);
  const [initialReferenceData, setInitialReferenceData] = useState([REFERENCE]);
  const [isOpenValidationModal, setIsOpenValidationModal] = useState(false);
  const [presentStepCount, setPresentStepCount] = useState();
  const [exportAsPDF, setExportAsPDF] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState(TEMPLATE_NAME);
  const [selectedTemplateID, setSelectedTemplateID] = useState(1);

  const [profileProgressDataValues, setProfileProgressDataValues] = useState({
    my_info: 0,
    cv_basic_info: 0,
    study_life: 0,
    cv_work_life: 0,
    cv_refernce: 0,
  });
  const [profileCompletion, setProfileCompletion] = useState({
    profileCompletion: 0,
    cvBasics: 0,
    workLife: 0,
    studyLife: 0,
    references: 0,
  });
  const [rightBoxY, setRightBoxY] = useState((window.innerWidth * 380) / 1920);
  const open = Boolean(anchorEl);
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    borderRadius: "12px",
    p: 4,
    boxShadow: "none !important",
    border: "1px solid rgb(197, 197, 197) !important",
  };
  const getProgressData = async () => {
    const { payload } = await dispatch(getProfileProgressData());
    setCompleted({
      0: payload?.data?.cv_basic_info === 10 ? true : false,
      1: payload?.data?.cv_work_life === 5 ? true : false,
      2: payload?.data?.study_life === 5 ? true : false,
      3: payload?.data?.cv_refernce === 5 ? true : false,
      4:
        payload?.data?.cv_basic_info +
          payload?.data?.cv_work_life +
          payload?.data?.study_life +
          payload?.data?.cv_refernce ===
        25
          ? true
          : false,
    });
    setProfileProgressDataValues(payload?.data);
  };
  const decodedToken = getDecodedToken(getToken());

  const handleChangeStep = (value, comp) => {
    handleStep(value, comp);
  };

  window.onclick = (e) => {
    if (
      e.target.className !== "candidate-profile-viewer-dropdown" &&
      e.target.id !== "candidate-profile-viewer-btn"
    ) {
      setDisplayD("none");
    }
  };

  const scrollToTop = () => {
    // Scroll to the top of the page with smooth behavior
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  const handleStep = (steps, comp) => {
    if (comp) {
      dispatch(setCvSteps(steps));
      scrollToTop();
      setStep(steps);
    } else {
      if (step === 0) {
        let data = deepCompare(basicData, initialData);
        if (data?.status === true) {
          dispatch(setCvSteps(steps));
          scrollToTop();
          setStep(steps);
        } else {
          validationModalOpen();
        }
      }
      if (step === 1) {
        let updatedWorkData = workData.map((item) => {
          let newItem = {
            ...item,
            start_date:
              item?.start_date === "Invalid Date" ? "" : item?.start_date,
            end_date: item?.end_date === "Invalid Date" ? "" : item?.end_date,
          };
          if (newItem?.send_date) {
            delete newItem.send_date;
          }
          return newItem;
        });
        let workDataValidate = deepCompare(updatedWorkData, initialWorkData);
        if (workDataValidate?.status === true) {
          dispatch(setCvSteps(steps));
          scrollToTop();
          setStep(steps);
        } else {
          validationModalOpen();
        }
      }
      if (step === 2) {
        let data = deepCompare(studyData, initialStudyData);
        if (data?.status === true) {
          dispatch(setCvSteps(steps));
          scrollToTop();
          setStep(steps);
        } else {
          validationModalOpen();
        }
      }
      if (step === 3) {
        let data = deepCompare(referenceData, initialReferenceData);
        if (data?.status === true) {
          dispatch(setCvSteps(steps));
          scrollToTop();
          setStep(steps);
        } else {
          validationModalOpen();
        }
      }
      if (step === 4) {
        dispatch(setCvSteps(steps));
        scrollToTop();
        setStep(steps);
      }
    }
  };

  const handleLeftSide = (step) => {
    dispatch(setCurrentStep(step));
    if (step === 1) {
      navigate("/candidate/my-profile");
    } else if (step === 2) {
      navigate("/candidate/my-profile");
      setExpanded(true);
    } else if (step === 3) {
      navigate("/candidate/my-cv");
    } else if (step === 4) {
      navigate("/candidate/assessment");
      // window.open(
      //   "https://docs.google.com/forms/d/e/1FAIpQLScKTA3IfBUw8xvdP03-hMFX1UObzO_pPcd5p61OsKAf3whbrg/viewform"
      // );
    } else if (step === 5) {
      navigate("/candidate/my-cam");
    }
  };
  const handleClickOutside = (event) => {
    if (boxRef.current && !boxRef.current.contains(event.target)) {
      setProgressButton(false);
    }
  };

  const handleComplete = (steps) => {
    const newCompleted = completed;
    getProgressData();
    newCompleted[steps || step] = true;
    setCompleted(newCompleted);
    // handleNext();
    scrollToTop();
  };

  useEffect(() => {
    dispatch(setCurrentStep(3));
    handleCandidateDynamicTheme(decodedToken?.data?.personality);
    // getProgressData();
    // completedStep();
  }, []);

  const getNewWidth = () => {
    setRightBoxY((window.innerWidth * 380) / 1920);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleAfterPrint = useCallback(() => {
    setExportAsPDF(false);
  }, []);

  const handleOnBeforeGetContent = useCallback(() => {
    return new Promise((resolve) => {
      onBeforeGetContentResolve.current = resolve;
      setExportAsPDF(true);
      setTimeout(() => {
        resolve();
      }, 1000);
    });
  }, []);
  const handleBeforePrint = useCallback(() => {}, []);
  const reactToPrintContent = useCallback(() => {
    return pdfRef.current;
  }, [pdfRef.current]);

  const reactToPrintTrigger = useCallback(() => {
    return (
      <Button
        variant="contained"
        color="applyButton"
        sx={{
          minWidth: "150px !important",
          height: "45px",
          fontSize: "15px",
          fontWeight: theme.typography.Bold,
          borderRadius: "0px 0px 10px 10px",
        }}
      >
        export to PDF
      </Button>
    );
  }, []);
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  useEffect(() => {
    window.addEventListener("resize", getNewWidth);
    return () => {
      window.removeEventListener("resize", getNewWidth);
    };
  }, []);
  useEffect(() => {
    handleCandidateDynamicTheme(decodedToken?.data?.personality);
    // getProgressData();
    // completedStep();
  }, []);

  const validationModalClose = () => {
    setIsOpenValidationModal(false);
  };
  const validationModalOpen = () => {
    setIsOpenValidationModal(true);
  };
  const handleYesBtnValModal = () => {
    dispatch(setCvSteps(presentStepCount));
    scrollToTop();
    setStep(presentStepCount);
    setIsOpenValidationModal(false);
  };

  const handleChangeTemplate = (ID) => {
    let selectedTemp = TEMPLATE_NAME?.map((template) => ({
      ...template,
      selected: template?.id === ID,
    }));
    setSelectedTemplateID(ID);
    setSelectedTemplate(selectedTemp);
    handleClose();
  };

  useEffect(() => {
    if (width > 1024) {
      setLeftExpand(true);
      setRightExpand(true);
    } else {
      setLeftExpand(false);
      setRightExpand(false);
    }
  }, [width]);
  if (width === 0) {
    return null;
  }
  return (
    <>
      <Grid
        container
        spacing={0}
        flexDirection={{ xs: "column", sm: "row" }}
        justifyContent="space-between"
        flexWrap={"nowrap"}
        gap={"30px"}
      >
        <Grid
          item
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: `${width >= 768 && "10px"}`,
            width: leftExpanded ? "125px" : "30px",
            minWidth: leftExpanded ? "125px" : "30px",
            zIndex: "30",
            position: `${width < 768 ? "absolute" : "relative"}`,
          }}
        >
          <Button
            variant="contained"
            sx={{
              boxShadow: "0px 5px 10px #00000029 !important",
              position: "static",
              borderRadius: "0 10px 10px 0",
              minWidth: "30px",
              width: "30px",
              height: "45px",
              backgroundColor: `${theme.palette.filterButtons.main}`,
              "&:hover": {
                backgroundColor: `${theme.palette.filterButtons.main}`,
              },
            }}
            onClick={() => {
              setLeftExpand((prevState) => !prevState);
              setRightExpand((prevState) => !prevState);
              dispatch(setFilterExpanded(!filterExpanded));
            }}
          >
            {leftExpanded ? <LeftArrow /> : <RightArrow />}
          </Button>
          {leftExpanded && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "10px",
                // height: {
                //   xs: `calc(100vh - 95px)`,
                //   sm: `calc(100vh - 120px )`,
                //   md: `calc(100vh - 120px)`,
                //   lg: `calc(100vh - 135px)`,
                //   xl: `calc(100vh - 155px)`,
                //   xxl: "calc(100vh - 175px)",
                // },
                width: `${width < 768 && "135px"}`,
                paddingTop: `${width < 768 && "10px"}`,
                paddingBottom: `${width < 768 && "10px"}`,
                background: `${width > 0 && width < 768 && "#EBECF3"}`,
                boxShadow: `${
                  width > 0 && width < 768 && "0px 5px 10px #00000029"
                }`,
                opacity: `${width < 768 && "1"}`,
                borderRadius: `${width < 768 && "10px"}`,
                overflowY: leftExpanded ? "scroll" : "unset",
              }}
              className="filterSec"
            >
              <Paper
                sx={{
                  background: "transparent",
                  marginRight: "1px",
                  boxShadow: 0,
                  // maxWidth: `${width < 768 && "150px"}`,
                  maxWidth: "125px",
                }}
              >
                <LeftSideProfilePanel
                  panelData={CANDIDATE_MY_PROFILE_LEFT}
                  side="left"
                  onChangeFilter={handleLeftSide}
                  selectedButton={currentStep}
                  color="filterButtons"
                />
              </Paper>
            </Box>
          )}
        </Grid>
        <Grid
          container
          width={{ xs: "100%", tb24: `calc(100vw - 300px)` }}
          justifyContent={"center"}
          gap={"10px"}
          alignContent={"flex-start"}
          flexGrow={1}
          sx={{
            height: {
              xs: `calc(100vh - 70px)`,
              sm: `calc(100vh - 70px )`,
              md: `calc(100vh - 70px)`,
              lg: `calc(100vh - 80px)`,
              xl: `calc(100vh - 100px)`,
              xxl: "calc(100vh - 120px)",
            },
            // height:"100vh"
            overflowY: "scroll",
            overflowX: "hidden",
          }}
        >
          <Grid
            sx={{
              display: "flex",
              alignItems: step === 4 ? "center" : "flex-start",
              position: "relative",
              height: "auto",
              flexDirection: "column",
              padding: { xs: "5px", md24: "0px" },
              marginTop: { xs: "40px", tablet: "55px" },
            }}
            width={
              step === 4
                ? { xs: "100%", lg: "100%", mlg: "90%", xl: "83.2%" }
                : "100%"
            }
          >
            {step === 4 && (
              <Box sx={{ width: "100%", textAlign: "center" }}>
                <Button
                  variant="contained"
                  color="applyButton"
                  sx={{
                    width: "150px !important",
                    height: "45px",
                    fontSize: "14px",
                    fontWeight: theme.typography.Bold,
                    borderRadius: "10px 10px 0px 0px",
                    textWrap: "nowrap",
                    "& .MuiButton-endIcon": {
                      marginLeft: "0px",
                    },
                  }}
                  onClick={handleClick}
                  endIcon={
                    <Box
                      component="img"
                      src={open ? upClose : downClose}
                      sx={{
                        height: "30px",
                        width: "25px",
                      }}
                    />
                  }
                >
                  select template
                </Button>

                <Popover
                  id="dropdown-menu"
                  open={open}
                  anchorEl={anchorEl}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                  sx={{
                    "& .MuiPaper-root": {
                      borderRadius: "0px 0px 25px 25px !important",
                      height: "157px",
                      width: "340px",
                      padding: "10px 17px 0px 17px",
                    },
                  }}
                >
                  <Box>
                    <Typography
                      sx={{
                        fontSize: "16px",
                        fontWeight: theme.typography.Regular,
                        marginBottom: "16px",
                      }}
                    >
                      Choose from one of the following 3 templates:
                    </Typography>
                    <Box sx={{ height: "102px" }}>
                      {selectedTemplate?.map((item) => {
                        return (
                          <Typography
                            key={item?.id} // Added a unique key for each item
                            sx={{
                              fontSize: "20px",
                              fontWeight: "bold", // Corrected fontWeight
                              display: "flex",
                              alignItems: "center",
                              gap: "10px",
                              cursor: "pointer",
                            }}
                            onClick={() => handleChangeTemplate(item?.id)}
                          >
                            {item?.name}
                            {item?.selected && (
                              <CheckCircle
                                sx={{
                                  fontSize: 20,
                                }}
                                color="checkColor"
                              />
                            )}
                          </Typography>
                        );
                      })}
                    </Box>
                  </Box>
                </Popover>
              </Box>
            )}
            <Box
              width="100%"
              sx={{
                cursor: "auto !important",
                boxShadow: "0px 5px 10px #00000029 !important",
                borderRadius: "25px",
                marginBottom: step !== 4 && "110px !important",
                position: "relative",
                background: "#ffff",
              }}
            >
              {step === 0 && (
                <TheBasicsNew
                  changeStep={handleChangeStep}
                  handleComplete={handleComplete}
                  setProfileCompletion={setProfileCompletion}
                  step={step}
                  initialData={initialData}
                  setInitialData={setInitialData}
                  basicData={basicData}
                  setBasicData={setBasicData}
                />
              )}
              {step === 1 && (
                <WorkLifeNew
                  changeStep={handleChangeStep}
                  handleComplete={handleComplete}
                  step={step}
                  workData={workData}
                  setWorkData={setWorkData}
                  initialWorkData={initialWorkData}
                  setInitialWorkData={setInitialWorkData}
                />
              )}
              {step === 2 && (
                <StudyLifeNew
                  changeStep={handleChangeStep}
                  handleComplete={handleComplete}
                  step={step}
                  initialStudyData={initialStudyData}
                  setInitialStudyData={setInitialStudyData}
                  studyData={studyData}
                  setStudyData={setStudyData}
                />
              )}
              {step === 3 && (
                <References
                  changeStep={handleChangeStep}
                  handleComplete={handleComplete}
                  step={step}
                  referenceData={referenceData}
                  setReferenceData={setReferenceData}
                  initialReferenceData={initialReferenceData}
                  setInitialReferenceData={setInitialReferenceData}
                />
              )}
            </Box>
            <Box sx={{ width: "100%" }}>
              {step === 4 && selectedTemplateID === 1 && (
                <CandidateCVPageNew
                  changeStep={handleChangeStep}
                  handleComplete={handleComplete}
                  pdfRef={pdfRef}
                  step={step}
                  leftExpanded={leftExpanded}
                  candidateCV={true}
                  exportAsPDF={exportAsPDF}
                />
              )}
            </Box>
            <Box sx={{ width: "100%" }}>
              {step === 4 && selectedTemplateID === 2 && (
                <ClassicCVPage
                  changeStep={handleChangeStep}
                  handleComplete={handleComplete}
                  pdfRef={pdfRef}
                  step={step}
                  leftExpanded={leftExpanded}
                  candidateCV={true}
                  exportAsPDF={exportAsPDF}
                />
              )}
            </Box>

            {step === 4 && (
              <Box
                sx={{
                  width: "100%",
                  textAlign: "center",
                  marginBottom: "30px",
                }}
              >
                <ReactToPrint
                  content={reactToPrintContent}
                  documentTitle="AwesomeFileName"
                  onAfterPrint={handleAfterPrint}
                  onBeforeGetContent={handleOnBeforeGetContent}
                  onBeforePrint={handleBeforePrint}
                  removeAfterPrint
                  trigger={reactToPrintTrigger}
                />
              </Box>
            )}
          </Grid>
        </Grid>
        <Grid
          item
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: `${width >= 768 && "10px"}`,
            direction: "rtl",
            position: `${width < 768 ? "absolute" : "relative"}`,
            zIndex: "30",
            // width: rightExpand ? "150px" : "30px",
            width: rightExpand ? "125px" : "30px",
            minWidth: rightExpand ? "125px" : "30px",
            right: "0",
          }}
        >
          <Button
            variant="contained"
            sx={{
              position: "sticky",
              top: 0,
              boxShadow: "0px 5px 10px #00000029 !important",
              borderRadius: "10px 0 0 10px",
              minWidth: "30px",
              width: "30px",
              height: "45px",
              backgroundColor: `${theme.palette.filterButtons.main}`,
              "&:hover": {
                backgroundColor: `${theme.palette.filterButtons.main}`,
              },
            }}
            onClick={() => {
              setLeftExpand((prevState) => !prevState);
              setRightExpand((prevState) => !prevState);
              dispatch(setFilterExpanded(!filterExpanded));
            }}
          >
            {rightExpand ? <RightArrow /> : <LeftArrow />}
          </Button>
          {rightExpand && (
            <Box
              className="rightfilterSec"
              sx={{
                display: "flex",
                flexDirection: "column",
                // width: "141px",
                width: `${width < 768 && "125px"}`,
                minWidth: "125px",
                paddingTop: `${width < 768 && "10px"}`,
                paddingLeft: `${width < 768 && "10px"}`,
                paddingBottom: `${width < 768 && "10px"}`,
                background: `${width > 0 && width < 768 && "#EBECF3"}`,
                boxShadow: `${
                  width > 0 && width < 768 && "0px 5px 10px #00000029"
                }`,
                opacity: `${width < 768 && "1"}`,
                borderRadius: `${width < 768 && "10px"}`,
              }}
            >
              <Stepper
                nonLinear
                activeStep={step}
                orientation="vertical"
                sx={{
                  "& .MuiStepConnector-root": {
                    marginLeft: "20px",
                  },
                  "& .MuiStepConnector-line": {
                    borderColor: theme.palette.filterButtons.main,
                    minHeight: "40px",
                    margin: "5px 0px 6px 0px",
                  },
                }}
              >
                {steps?.map((item, index) => (
                  <Step
                    key={item.label}
                    completed={completed[index]}
                    sx={{
                      "& .Mui-active": {
                        color:
                          theme.palette.filterButtons.contrastText +
                          " !important",
                        fill: theme.palette.filterButtons.contrastText,
                      },
                      "& .MuiStepIcon-root": {
                        color:
                          step !== index && theme.palette.filterButtons.main,
                        width: "25px !important",
                        height: "25px !important",
                        fontSize: "12px",
                      },
                      "& .MuiStepIcon-text": {
                        fill:
                          step === index
                            ? theme.palette.filterButtons.main
                            : theme.palette.base.main,
                        fontSize: "12px",
                      },
                      "& .MuiStepLabel-labelContainer": {
                        color: theme.palette.base.contrastText,
                      },
                      "& .MuiStepLabel-label": {
                        fontSize: theme.typography.fontSize.xs,
                        fontWeight:
                          step === index
                            ? theme.typography.Bold
                            : theme.typography.Regular,
                      },
                      "& .MuiStepLabel-label.Mui-active": {
                        color: theme.palette.filterButtons.contrastText,
                      },
                      "& .MuiStepLabel-label.Mui-completed": {
                        color: theme.palette.filterButtons.main,
                      },
                    }}
                  >
                    <StepLabel
                      onClick={() => {
                        setPresentStepCount(index);
                        handleStep(index);
                      }}
                      color="filterButtons"
                      style={{
                        border: `2px solid ${item.borderColor}`,
                        borderRadius: "10px 0 0 10px",
                        cursor: "pointer",
                        height: "40px",
                        padding: "0 10px",
                        backgroundColor:
                          step === index
                            ? theme.palette.filterButtons.main
                            : theme.palette.base.main,
                        // boxShadow: "0px 5px 10px #00000029",
                        flexDirection: "row-reverse",
                      }}
                    >
                      {item.label}
                    </StepLabel>
                  </Step>
                ))}
              </Stepper>
            </Box>
          )}
        </Grid>
      </Grid>

      <Modal
        open={isOpenValidationModal}
        onClose={validationModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Unsaved data detected on this page. Are you sure you want to leave?
            Any unsaved data will be lost.
          </Typography>
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <Button
              sx={{
                height: "38px",
                mr: 2,
                borderRadius: "10px",
                color: "black",
              }}
              variant="contained"
              color="grayButton300"
              onClick={handleYesBtnValModal}
            >
              YES
            </Button>
            <Button
              sx={{
                height: "38px",
                mr: 2,
                borderRadius: "10px",
              }}
              onClick={validationModalClose}
              variant="contained"
              color="redButton100"
            >
              CANCEL
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
}
