import { Box, Button, Typography, useTheme } from "@mui/material";
import React, { useState } from "react";
import editIcon from "../../../../assets/Padding Included/Yellow_Edit.svg";
import { useDispatch } from "react-redux";
import EditBounty from "./EditBounty";
import { getDecodedToken, getToken } from "../../../../utils/Common";
import InputBox from "../../../common/InputBox";
import SuccessRatePopup from "./SuccessRatePopup";

export default function SuccessPopup({
  handleClose,value,jobId,allJobs,setAllJobs
}) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const decodedToken = getDecodedToken(getToken());
  const [editOpen, setEditOpen] = useState(false);
  const [errors, setErrors] = useState();
  const [formState, setFormState] = useState("");

  const handleUpdateSuccess = () => {};

  const handleBountyChane = (e) => {
    setFormState(e.target.value);
  };
  return (
    <>
      <Box sx={{ padding: "9px" }}>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Typography
          sx={{ fontSize: "12px", fontWeight: theme.typography.Bold }}
        >
          Edit Success Rate
        </Typography>
        {decodedToken?.data?.role_id !== 8 && (
            <Box
              component={"img"}
              src={editIcon}
              sx={{
                width: 25,
                height: 25,
                cursor: "pointer",
              }}
              onClick={() => setEditOpen(true)}
            />
          )}
          </Box>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography
              sx={{
                fontSize: "12px",
                width: "120px",
                fontWeight: theme.typography.Bold,
              }}
            >
              Success Rate
            </Typography>
            <Typography
            sx={{ fontSize: "12px", fontWeight: theme.typography.Bold }}
          >
            {`${
              value
                ? value
                : "0"
            }`}
          </Typography>
          </Box>
        </Box>
      </Box>
 
      {editOpen && (
        <SuccessRatePopup
          open={editOpen}
          handleClose={() => setEditOpen(false)}
          value={value}
          jobId={jobId}
          allJobs={allJobs}
          setAllJobs={setAllJobs}
          handleClosePopup={handleClose}
        />
      )}
    </>
  );
}
