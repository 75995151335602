import React, { useEffect, useState } from "react";
import {
  Avatar,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  IconButton,
  InputBase,
  Paper,
  ThemeProvider,
  Typography,
} from "@mui/material";
import forgotPasswordIcon from "../../assets/Characters/CrayBotIcons/Crayon Craybot Notification-08.svg";
import { useTheme } from "@emotion/react";
import { forgotPasswordPagetheme } from "../../utils/Theme";
import { useDispatch } from "react-redux";
import { forgotPassword } from "../../redux/login/loginSlice";
import { setAlert } from "../../redux/configSlice";
import { ALERT_TYPE, ERROR_MSG } from "../../utils/Constants";
import locale from "../../i18n/locale";
import CloseIcon from "../common/CloseIcon";
import ForgotPasswordConfirmationModal from "./ForgotPasswordConfirmationModal";

const ForgotPassword = ({
  openFunc,
  closeFunc,
  openPrevDialog,
  closeSignupModal,
  closeLoginModal,
  handleConfirmPasswordChange,
}) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const i18n = locale.en;
  const [email, setEmail] = useState("");
  // const [isForgotPassword, setIsForgotPassowrd] = useState(false);

  const forgotPass = async () => {
    try {
      let data = {
        email: email,
      };
      const { payload } = await dispatch(forgotPassword(data));
      if (payload?.Status === "Success") {
        // setIsForgotPassowrd(true);
        handleConfirmPasswordChange();
        closeFunc();
        if (closeSignupModal) {
          closeSignupModal();
        }
        if (closeLoginModal) {
          closeLoginModal();
        }
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE?.SUCCESS,
            msg: i18n["alertMessage.emailsent"],
          })
        );
      } else {
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE?.ERROR,
            msg: payload?.message?.Message,
          })
        );
      }
    } catch (error) {
      dispatch(
        setAlert({
          show: true,
          type: ALERT_TYPE?.ERROR,
          msg: ERROR_MSG,
        })
      );
    }
  };

  return (
    <>
      <ThemeProvider theme={forgotPasswordPagetheme}>
        <Dialog
          dialogWidth="sm"
          padding={0}
          showFooter={false}
          scroll="body"
          sx={{
            zIndex: 100000,
            margin: "0px",
            "& .MuiPaper-root": {
              borderRadius: "25px",
              backgroundColor: "backgroundColor",
              margin: "0px",
            },
            "& .MuiDialog-paperScrollBody": {
              width: "100%",
              maxWidth: { xs: "97%", tablet: "450px" },
            },
            "& .MuiPaper-root-MuiDialog-paper": {
              width: "100%",
              maxWidth: "100%",
            },
          }}
          open={openFunc}
          onDialogClose={closeFunc}
        >
          <DialogTitle onClose={closeFunc} sx={{ padding: "0px" }}>
            {/* <IconButton
            aria-label="close"
            onClick={closeFunc}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
            disableRipple="true"
          >
            <Box
              sx={{
                width: "20px",
                height: "20px",
                color: "#000000",
                border: 2,
                fontSize: "18px",
                borderRadius: "5px",
              }}
            >
              X
            </Box>
          </IconButton> */}
            <CloseIcon onClick={closeFunc} />
          </DialogTitle>
          <Box
            sx={{
              width: "100%",
              marginTop: "25px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              paddingBottom: "0px",
            }}
          >
            <Avatar src={forgotPasswordIcon} sx={{ width: 96, height: 125 }} />
            <Typography
              sx={{
                fontSize: "20px",
                lineHeight: "27px",
                marginBottom: "20px",
                fontWeight: theme.typography.Bold,
              }}
            >
              It's ok, we all forget <br />
              our first dog's name.
            </Typography>
            <Typography
              sx={{
                fontSize: "16px",
                lineHeight: "22px",
                marginBottom: "20px",

                fontWeight: theme.typography.Bold,
              }}
            >
              Who’saGoodBooi?@1!
            </Typography>
            <Typography
              sx={{
                fontSize: "14px",
                marginBottom: "20px",
                fontWeight: theme.typography.Regular,
              }}
            >
              Enter the email address you used when you signed up.
            </Typography>

            <Box sx={{ width: "88%", padding: 0, marginBottom: "20px" }}>
              <Paper
                sx={{
                  display: "flex",
                  height: "50px",
                  width: "100% !important",
                  borderRadius: "10px !important",
                  background: "white !important",
                  boxShadow: "none",
                  border: `1px solid`,
                  borderColor: "borderColor",
                }}
              >
                <InputBase
                  id="email"
                  placeholder="email address"
                  onChange={(event) => setEmail(event?.target?.value)}
                  sx={{ ml: 2, mr: 2, width: "100%" }}
                />
              </Paper>
            </Box>
            <Typography
              sx={{
                fontSize: "14px",
                marginBottom: "20px",
                fontWeight: theme.typography.Regular,
              }}
            >
              Ah wait, I remember it, let’s{" "}
              <span
                style={{
                  fontSize: "14px",
                  fontWeight: theme.typography.Bold,
                  cursor: "pointer",
                }}
                onClick={openPrevDialog}
              >
                login!
              </span>
            </Typography>
          </Box>
          <DialogActions
            sx={{
              display: "flex",
              justifyContent: "center",
              overflow: "hidden",
              padding: 0,
            }}
          >
            <Button
              sx={{
                width: "100%",
                borderRadius: 0,
                padding: 3,
                height: "60px",
                marginLeft: "0 !important",
                fontSize: "14px",
                fontWeight: theme.typography.Bold,
              }}
              variant="contained"
              color="resetButton"
              onClick={forgotPass}
            >
              ok, let’s reset
            </Button>
          </DialogActions>
        </Dialog>
      </ThemeProvider>
      {/* {isForgotPassword && (
        <ForgotPasswordConfirmationModal
          openFunc={isForgotPassword}
          closeFunc={() => setIsForgotPassowrd(false)}
        />
      )} */}
    </>
  );
};

export default ForgotPassword;
