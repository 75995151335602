import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import NoRecord from "../../common/NoRecord";
import TalentCard from "../../guest/talent/TalentCardNew";
import { Box, Grid } from "@mui/material";
import InfiniteScroll from "react-infinite-scroll-component";
import useViewport from "../../common/ViewPort";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { getAllHiredTalent } from "../../../redux/employer/myTeams";
import { setAlert, setLoading } from "../../../redux/configSlice";
import { ALERT_TYPE } from "../../../utils/Constants";
import { getFilteredTalent } from "../../../redux/guest/talentSlice";
import CommonLoader from "../../common/CommonLoader";
import Loader from "../../common/Loader";
import { setCardsPadding } from "../../../redux/guest/jobsSlice";

export default function HiredTalent({
  selectedFilter,
  leftExpanded,
  rightExpand,
}) {
  const { width } = useViewport();
  const gridRef = useRef();
  const isLoading = useSelector((state) => state.config.loading);
  const dispatch = useDispatch();
  const [allTalent, setAllTalent] = useState([]);
  const [newCardWidth, setNewCardWidth] = useState(340);
  const [newPadding, setNewPadding] = useState(25);
  const [lastKey, setLastKey] = useState(0);
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [itemsFetched, setItemsFetched] = useState(false);

  const { currentPageJobsCount, showBox } = useSelector(
    (state) => state.configMyTeams
  );
  const { company_id } = useSelector((state) => state?.configalert);
  const calculatedHeight = () => {
    // Calculate the desired height based on screen resolution or any other logic
    // For example, you can use window.innerWidth or window.innerHeight to adjust the height dynamically.
    const screenWidth = window.innerWidth;

    if (screenWidth < 600) {
      // xs
      return `calc(100vh - 95px)`;
    } else if (screenWidth < 900) {
      // sm
      return `calc(100vh - 80px)`;
    } else if (screenWidth < 1366) {
      // md
      return `calc(100vh - 90px)`;
    } else if (screenWidth < 1920) {
      // lg
      return `calc(100vh - 80px)`;
    } else if (screenWidth < 2559) {
      // xl
      return `calc(100vh - 100px)`;
    } else {
      // xxl or greater
      return `calc(100vh - 120px)`;
    }
  };

  function calculateCardWidthAndPadding(totalWidth) {
    let cardWidth = 340;
    let padding = 25;
    let numCards = Math.floor(totalWidth / (cardWidth + padding));
    let remainingSpace =
      totalWidth - (numCards * cardWidth + padding * (numCards + 1));

    while (remainingSpace > 0) {
      if (remainingSpace < cardWidth + padding) {
        if (padding < 30) {
          padding++;
          remainingSpace =
            totalWidth - (numCards * cardWidth + padding * (numCards + 1));
        } else {
          break;
        }
      } else {
        numCards++;
      }
    }

    cardWidth = (totalWidth - padding * (numCards + 1)) / numCards;
    if (cardWidth > 380) {
      cardWidth = 380;
    }
    remainingSpace =
      totalWidth - (numCards * cardWidth + padding * (numCards + 1));

    padding = padding + remainingSpace / (numCards + 1);

    remainingSpace =
      totalWidth - (numCards * cardWidth + padding * (numCards + 1));

    setNewCardWidth(cardWidth - 1);
    setNewPadding(padding);
    dispatch(setCardsPadding(padding));
    return { numCards, cardWidth, padding, remainingSpace };
  }
  const getNewWidth = () => {
    if (gridRef?.current) {
      calculateCardWidthAndPadding(gridRef?.current?.clientWidth);
    }
  };

  const getHiredTalent = async (lastkeyy) => {
    try {
      // lastkeyy === 0 && setIsDataLoading(true);
      dispatch(setLoading(true));
      const { payload } = await dispatch(
        getAllHiredTalent({
          lastKey: lastkeyy,
          company_id: company_id,
        })
      );
      if (payload?.status === "success") {
        if (payload?.data?.length <= 0) {
          setLastKey(payload?.pageNumber + 1);
          setAllTalent(payload?.data);
        } else {
          setLastKey(payload?.pageNumber + 1);
          setAllTalent((prevState) => [...prevState, ...payload.data]);
        }
        setItemsFetched(true);
      } else {
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE?.ERROR,
            msg: payload?.message,
          })
        );
      }
      // setIsDataLoading(false);
      dispatch(setLoading(false));
    } catch (error) {
      dispatch(
        setAlert({
          show: true,
          type: ALERT_TYPE?.ERROR,
          msg: "Error",
        })
      );
      // setIsDataLoading(false);
      dispatch(setLoading(false));
    }
  };

  useEffect(() => {
    company_id && getHiredTalent(0);
  }, [company_id]);

  useLayoutEffect(() => {
    if (gridRef?.current) {
      calculateCardWidthAndPadding(gridRef?.current?.clientWidth);
    }
    window.addEventListener("resize", getNewWidth);
    return () => {
      window.removeEventListener("resize", getNewWidth);
    };
  }, [itemsFetched, selectedFilter, rightExpand, leftExpanded]);

  return (
    !isLoading &
    (
      <Box
        item
        sx={{
          display: "flex",
          flexGrow: 1,
          alignItems: "center",
          flexDirection: "column",
          width: `calc(100vw - 255px)`,
          position: "sticky",
          // marginTop: "109px",
        }}
        ref={gridRef}
        className="grigBox"
      >
        <InfiniteScroll
          height={calculatedHeight()}
          dataLength={allTalent?.length}
          next={() => getHiredTalent(lastKey)}
          hasMore={true}
          style={{
            overflowX: "hidden",
          }}
          scrollThreshold={"100px"}
          endMessage={
            <p style={{ textAlign: "center" }}>
              <b>Yay! You have seen it all</b>
            </p>
          }
        >
          <Grid
            container
            gap={width < 768 ? "10px" : `${newPadding}px`}
            columns={{
              xs: 1,
              md: 1,
              lg: 3,
              mlg: 3.5,
              xl: 4,
              xxl: 6,
              xxxl: 8,
            }}
            justifyContent={width < 768 && "center"}
            sx={{
              position: "relative",
              padding: width < 768 ? "10px 10px" : `0 ${newPadding}px`,
              top: width <= 768 ? "35px" : "109px",
            }}
          >
            {allTalent?.length > 0
              ? allTalent?.map((talent, index) => {
                  return (
                    <Box
                      sx={{
                        width: newCardWidth,
                      }}
                    >
                      <TalentCard
                        // index={nanoid()}
                        job={talent}
                        allTalent={allTalent}
                        jobslength={allTalent?.length}
                        index={index}
                        currentPageJobsCount={currentPageJobsCount}
                      />
                    </Box>
                  );
                })
              : showBox && (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      width: "100%",
                      justifyContent: "center",
                    }}
                  >
                    <NoRecord />
                  </Box>
                )}
          </Grid>
          <style>
            {`.infinite-scroll-component::-webkit-scrollbar {
                  display:none;
          }
          .infinite-scroll-component__outerdiv{
            width:100%
          }
          `}
          </style>
        </InfiniteScroll>
      </Box>
    )
  );
}
