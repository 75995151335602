import { Box, Button, DialogTitle, IconButton, Paper, Typography, useTheme } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'

const ProfilePicCaptureDialog = ({ onRecordingStop, handleImage }) => {
    const [mediaStream, setMediaStream] = useState(null);

    const [mediaStreamReady, setMediaStreamReady] = useState(false);
    const videoRef = useRef();


    useEffect(() => {
        async function setupCamera() {
            try {
                const stream = await navigator?.mediaDevices?.getUserMedia({ video: true });
                videoRef.current.srcObject = stream;
                setMediaStream(stream);
                setMediaStreamReady(true);
            } catch (error) {
                console.error('Error accessing camera:', error);
            }
        }

        setupCamera();

        return () => {
            if (mediaStream) {
                mediaStream?.getTracks()?.forEach((track) => {
                    track?.stop();
                });
            }
        };
    }, []);

    const handlePhotoCapture = () => {
        if (mediaStreamReady && videoRef?.current) {
            const video = videoRef?.current;

            // Create a canvas element to capture the photo
            const canvas = document?.createElement("canvas");
            canvas.width = video?.videoWidth;
            canvas.height = video?.videoHeight;
            const context = canvas?.getContext("2d");

            // Draw the current frame from the video onto the canvas
            context.drawImage(video, 0, 0, canvas?.width, canvas?.height);

            // Convert the canvas content to a data URL (base64 encoded image)
            const photoDataUrl = canvas?.toDataURL("image/jpeg");

            // Now you can use `photoDataUrl` for your purposes, such as displaying it in an image tag or sending it to a server.
            handleImage(photoDataUrl)
        }
    }
    const handleCloseCamera = () => {
        if (mediaStream) {
            mediaStream?.getTracks()?.forEach((track) => {
                track.stop();
            });
            setMediaStream(null);
        }
    };
    return (
        <>
            <DialogTitle onClose={() => {
                handleCloseCamera()
                onRecordingStop(prevState => !prevState)
            }}>
                <IconButton
                    aria-label="close"
                    onClick={() => {
                        handleCloseCamera()
                        onRecordingStop(prevState => !prevState)
                    }}
                    sx={{
                        position: "absolute",
                        right: 8,
                        top: 8,
                        color: (theme) => theme?.palette?.grey[500],
                    }}
                    disableRipple={true}
                >
                    <Box
                        sx={{
                            width: "20px",
                            height: "20px",
                            color: "#000000",
                            border: 2,
                            fontSize: "18px",
                            borderRadius: "5px",

                        }}
                    >
                        X
                    </Box>
                </IconButton>
            </DialogTitle>
            <Paper sx={{
                padding: 3,
                paddingBottom: 0,
                display: "flex",
                flexDirection: "column",
                gap: 3,
                height: "30rem"
            }}>
                <Box sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    borderRadius: "15px",
                    marginBottom: 2,
                    gap: 2,
                    flexGrow: 1
                }}>
                    <Box sx={{
                        height: "100%",
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        borderRadius: "25px",
                    }}>
                        <video ref={videoRef} style={{
                            width: "70%",
                            height: "auto",
                            borderRadius: "25px",
                        }} autoPlay playsInline muted></video>
                    </Box>
                </Box>
                <Box>

                    <Typography
                        sx={{
                            textAlign: "center",
                            fontSize: "20px",
                            fontWeight: 700,
                        }}
                    >
                        Opening Camera
                    </Typography>
                    <Typography
                        sx={{
                            textAlign: "center",
                            fontSize: "14px",
                            fontWeight: 700,
                            marginBottom: 3,
                        }}
                    >
                        Click capture to complete Recording
                    </Typography>
                </Box>
                <Box sx={{
                    margin: "auto",
                    width: "80%"
                }}>
                    <Button
                        variant="contained"
                        color="redButton100"
                        sx={{
                            borderRadius: 0,
                            width: "100%",
                            height: "47px",
                            borderTopLeftRadius: 25,
                            borderTopRightRadius: 25
                        }}
                        startIcon={
                            <Box
                                sx={{
                                    background: "white",
                                    height: 15,
                                    width: 15,
                                    borderRadius: 1
                                }}
                            />
                        }
                        onClick={() => {
                            handlePhotoCapture()
                            handleCloseCamera()
                            onRecordingStop(prevState => !prevState)
                        }
                        }
                    >
                        Capture
                    </Button>
                </Box>
            </Paper >
        </>

    )
}

export default ProfilePicCaptureDialog