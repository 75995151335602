import React, { useEffect } from "react";
import tempimg from "../../assets/images/temp1.svg";
import backImg from "../../assets/images/bg.png";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import WorkIcon from "@mui/icons-material/Work";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import CampaignIcon from "@mui/icons-material/Campaign";
import { Box, Typography, useTheme } from "@mui/material";
import html2canvas from "html2canvas";
import { uploadProfilePic } from "../../redux/candidate/myProfileSlice";
import { useDispatch } from "react-redux";
import { uploadJobImage } from "../../redux/guest/jobsSlice";

export default function Template({
  title,
  location,
  company,
  salary,
  reference,
  onClick,
  id,
  setsrc,
  jobID,
  job,
  getUrl,
  setShareUrl,
}) {
  const theme = useTheme();
  const dispatch = useDispatch();
  let imageblob;

  // const setTemplate = async () => {
  //   const template = document.getElementById("template");
  //   console.log(template, "TEMP");
  //   const canvas = await html2canvas(template);
  // canvas.toBlob(function (blob) {
  //   const formData = new FormData();
  //   formData.append("profile-pic", blob, "filename.png");
  //   const { payload } = dispatch(uploadProfilePic(formData));
  //   setsrc(payload?.data);
  // });
  // };

  // const setTemplate = async () => {
  //   const template = document.getElementById("template");
  //   const canvas = await html2canvas(template);
  //   imageblob = canvas.toDataURL("image/jpeg", 1.0);
  //   console.log({ template, imageblob, canvas });
  //   setsrc(imageblob);
  // };

  // const setTemplate = async () => {
  //   setShareUrl("");
  //   const template = document.getElementById("template");
  //   const canvas = await html2canvas(template);
  //   let formData = new FormData();
  //   return new Promise((resolve) => {
  //     canvas.toBlob(
  //       (blob) => {
  //         const fileName = "image.jpg";
  //         const file = new File([blob], fileName, { type: "image/jpeg" });
  //         setsrc(URL.createObjectURL(file));
  //         formData.append("job_id", jobID);
  //         formData.append("job-image", file);
  //         dispatch(uploadJobImage(formData));
  //         resolve(file);
  //       },
  //       "image/jpeg",
  //       1.0
  //     );
  //   }).then((file) => {
  //     // Assuming getUrl function is defined somewhere
  //     getUrl(jobID);
  //   });
  // };

  const setTemplate = async () => {
    setShareUrl("");
    const template = document.getElementById("template");
    const canvas = await html2canvas(template);
    const formData = new FormData();
    try {
      const blob = await new Promise((resolve) => {
        canvas.toBlob(resolve, "image/jpeg", 1.0);
      });
      const fileName = "image.jpg";
      const file = new File([blob], fileName, { type: "image/jpeg" });
      setsrc(URL.createObjectURL(file));
      formData.append("job_id", jobID);
      formData.append("job-image", file);
      await dispatch(uploadJobImage(formData));
      await getUrl(jobID); // Pass uploadedFile if provided by uploadJobImage
    } catch (error) {
      console.error("Error generating or uploading image:", error);
    }
  };

  useEffect(() => {
    if (title !== undefined) {
      setTemplate();
    }
  }, [title]);

  return (
    <Box
      sx={{
        height: "fit-content",
        width: "fit-content",
        display: imageblob === undefined ? "flex" : "none",
        justifyContent: "center",
        alignItems: "center",
        position: "absolute",
        opacity: 0,
        zIndex: "-99999",
      }}
    >
      <Box
        id="template"
        sx={{
          height: "500px",
          width: "800px",
          border: "1px solid lightgray",
          display: "flex",
          justifyContent: "space-between",
          backgroundImage: `url(${backImg})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
        }}
      >
        <Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              width: "180px",
              justifyContent: "space-between",
              marginLeft: "20px",
            }}
          >
            <CampaignIcon
              color="secondary"
              sx={{
                fontSize: 80,
              }}
            />
            <Box>
              <Typography
                sx={{ fontSize: "20px", fontWeight: theme.typography.Bold }}
              >
                New
              </Typography>
              <Typography
                sx={{ fontSize: "20px", fontWeight: theme.typography.Bold }}
              >
                Job Alert
              </Typography>
            </Box>
          </Box>
          <Box
            component={"img"}
            src={tempimg}
            sx={{
              height: "400px",
              width: "350px",
            }}
          />
        </Box>

        <Box
          sx={{
            display: "flex",
            gap: "20px",
            flexDirection: "column",
            marginRight: "20px",
            marginTop: "20px",
          }}
        >
          <Typography
            sx={{ fontSize: "24px", fontWeight: theme.typography.Bold }}
          >
            {title}
          </Typography>
          <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
            <LocationOnIcon
              sx={{ fontSize: 40, color: theme.palette.addQuestionButton.main }}
            />
            <Typography
              sx={{ fontSize: "20px", fontWeight: theme.typography.Bold }}
            >
              {location}
            </Typography>
          </Box>
          <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
            <WorkIcon
              sx={{ fontSize: 40, color: theme.palette.noticePeriod.main }}
            />
            <Typography
              sx={{ fontSize: "20px", fontWeight: theme.typography.Bold }}
            >
              {company}
            </Typography>
          </Box>
          <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
            <AccountBalanceWalletIcon
              sx={{ fontSize: 40, color: theme.palette.salaryRange.main }}
            />
            <Typography
              sx={{ fontSize: "20px", fontWeight: theme.typography.Bold }}
            >
              {salary}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
