import { useState } from "react";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Unstable_Grid2";
import { ALERT_TYPE } from "../../../utils/Constants";
import { useDispatch } from "react-redux";
import { setAlert } from "../../../redux/configSlice";
import { favouriteCompany } from "../../../redux/guest/talentSlice";
import { Button, Card, Tooltip, Typography } from "@mui/material";

import logo from "../../../assets/Crayon_Favicon.svg";
import playButtonIconWhite from "../../../assets/Padding Excluded/Black_reactive_job.svg";
import favouriteGrey from "../../../assets/Crayon Favourite Icon_Grey.svg";
import favouriteRed from "../../../assets/Crayon Favourite Icon_Red.svg";
import linkedUsers from "../../../assets/HomePage/Black_Linked_User_Databases.svg";
import redLocation from "../../../assets/Red_Location.svg";
import FollowIcon from "../../../assets/Padding Excluded/Black_Company Followers.svg";
import flip from "../../../assets/NewFlipIcon.svg";
import NewSlider from "../../common/NewSlider";

import MUIRadialChart from "../../common/MUIRadialChart";
import { convertDatetimeAgo } from "../../../utils/DateTime";
import { Link } from "react-router-dom";
import {
  followCompany,
  unFollow,
} from "../../../redux/candidate/myProfileSlice";
import TextWrapper from "../../common/TextWrapper";
import {
  getDecodedToken,
  getToken,
  setCookie,
  splitString,
} from "../../../utils/Common";
import pinIcon from "../../../assets/Icons/pinIcon.svg";

export default function HiringCardFront({
  index,
  job,
  setJobId,
  setopenApplyJobDialog,
  setisFlipped,
  isHorizontalFlipped,
  newCardWidth,
  handleReviewVideoDialog,
  getCompanyHiringList,
  jobslength,
  currentPageJobsCount,
  indexNumber,
  page,
}) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [isStar, setIsStarSelected] = useState(
    job?.favouriteflag
      ? job?.favouriteflag
      : document.cookie
          ?.match(/\d+/g)
          ?.map((item) => parseInt(item, 10))
          ?.includes(job?.company_id)
  );

  const [isCompanyFollowed, setIsCompanyFollowed] = useState(job?.followflag);

  let decodedToken = getDecodedToken(getToken());

  const handleFavourite = (event, companyid) => {
    setIsStarSelected(!isStar);
    setCookie("favoriteCompany", companyid, 30);
  };

  const handleStar = async () => {
    if (decodedToken?.data?.role_id === 3) {
      await dispatch(favouriteCompany({ company_id: job?.company_id }));
      setIsStarSelected(!isStar);
    } else {
      dispatch(
        setAlert({
          show: true,
          type: ALERT_TYPE?.ERROR,
          msg: "Login as candidate to add this job as favourite",
        })
      );
    }
  };

  const followComp = async (compID, isCompanyFollowed) => {
    try {
      const data = {
        company_id: compID,
      };
      const { payload } = !isCompanyFollowed
        ? await dispatch(followCompany(data))
        : await dispatch(unFollow(data));
      if (
        payload?.data?.status === "success" ||
        payload?.status === "success"
      ) {
        !isCompanyFollowed
          ? setIsCompanyFollowed(true)
          : setIsCompanyFollowed(false);
        getCompanyHiringList();
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE?.SUCCESS,
            msg: !isCompanyFollowed
              ? "Company followed successfully"
              : "Company unfollowed successfully",
          })
        );
      } else {
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE?.ERROR,
            msg: "Login as candidate to follow",
          })
        );
      }
    } catch (error) {
      dispatch(
        setAlert({
          show: true,
          type: ALERT_TYPE?.ERROR,
          msg: error?.message,
        })
      );
    }
  };

  return (
    <Card
      sx={{
        borderRadius: "25px",
        position: "relative",
        boxShadow: "0px 5px 10px #00000029",
      }}
      className="cardboxdetail"
    >
      <Grid sx={{ padding: "15px 8px 0px 15px" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "15px",
          }}
        >
          <Link
            to={`/jobs/company/${job?.company_id}`}
            target={"_blank"}
            style={{
              width: "50%",
              textDecoration: "none",
              color: theme.palette.black,
            }}
          >
            <Box
              component={"img"}
              src={job?.profile_url !== "No URL" ? job?.profile_url : logo}
              sx={{
                height: "60px",
                width: "60px",
                borderRadius: "10px",
              }}
            />
          </Link>
          <Box>
            <Button
              color={"precium"}
              variant="contained"
              sx={{
                height: "auto",
                minWidth: "60px",
                minHeight: "45px",
                maxWidth: "70px",
                borderRadius: "0 0 0 10px",
                position: "absolute",
                right: 120,
                top: 0,
                padding: 0,
                fontSize: 12,
                fontWeight: theme.typography.Bold,
                boxShadow: 0,
                cursor: "default",
                color: theme.jobCard.jobType.recruit.contrastText,
                ":hover": {
                  boxShadow: 0,
                },
              }}
            >
              {job?.company_type ? job?.company_type : "-"}
            </Button>
            <Button
              color={"peachPayment"}
              variant="contained"
              sx={{
                height: "auto",
                minWidth: "60px",
                minHeight: "45px",
                borderRadius: "0 0 0 0px",
                position: "absolute",
                right: 60,
                top: 0,
                padding: 0,
                fontSize: 12,
                fontWeight: theme.typography.Bold,
                boxShadow: 0,
                cursor: "default",
                color: theme.jobCard.jobType.recruit.contrastText,
                ":hover": {
                  boxShadow: 0,
                },
              }}
            >
              {job?.company_size ? job?.company_size : "-"}
            </Button>
            <Button
              color="playButton"
              variant="contained"
              sx={{
                position: "absolute",
                right: 0,
                top: 0,
                zIndex: 10,
                height: "auto",
                minWidth: "60px !important",
                minHeight: "45px",
                borderRadius: "0px 25px 0px 0px",
                cursor: job?.video_url === "No URL" ? "default" : "pointer",
                background:
                  job?.video_url === "No URL" &&
                  theme.palette.grayButton600.main,
                padding: 0,
                ":hover": {
                  boxShadow: "none",
                },
              }}
              onClick={() =>
                job?.video_url === "No URL" ? "" : handleReviewVideoDialog(true)
              }
            >
              <Box
                component={"img"}
                src={
                  // job?.video_url === "No URL"
                  //   ? playButtonIcon
                  //   : playButtonIconWhite
                  playButtonIconWhite
                }
                sx={{
                  height: "16px",
                  width: "20px",
                }}
              />
            </Button>
          </Box>

          <Typography
            sx={{
              position: "absolute",
              top: "47px",
              right: "73px",
              fontSize: "12px",
              fontWeight: theme.typography.Regular,
              // paddingRight: "8px",
              color: theme.palette.grayButton600.main,
            }}
          >
            {`joined ${convertDatetimeAgo(job?.created_at)} ago`}
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            gap: "5px",
            alignItems: "center",
            maxWidth: "100%",
          }}
        >
          {job?.pin_company && (
            // <TalentSVGButton
            //   color={"white"}
            //   source={pinIcon}
            //   outerHeight={20}
            //   height={20}
            //   width={20}
            //   padding={"0px !important"}
            //   startIconMargin={"0px !important"}
            // />
            <Box
              component="img"
              sx={{
                height: 20,
                width: 20,
                maxHeight: { xs: 20 },
                maxWidth: { xs: 20 },
                cursor: "pointer",
              }}
              alt="pinIcon"
              src={pinIcon}
            />
          )}
          <Tooltip disableInteractive title={job?.name} placement="top-start">
            <Link
              to={`/jobs/company/${job?.company_id}`}
              target={"_blank"}
              style={{
                maxWidth: `calc(100% - 20px)`,
                textDecoration: "none",
                color: theme.palette.black,
              }}
            >
              <Typography
                sx={{
                  fontSize: "20px",
                  fontWeight: theme.typography.Bold,
                  minHeight: "27px",
                  wordBreak: "break-word",
                }}
                noWrap={true}
              >
                {job?.name}
              </Typography>
            </Link>
          </Tooltip>
          {page === "footer" ? (
            ""
          ) : (
            <Box
              component={"img"}
              src={isStar ? favouriteRed : favouriteGrey}
              sx={{
                height: "20px",
                width: "20px",
              }}
              onClick={(event) =>
                decodedToken === undefined
                  ? handleFavourite(event, job?.company_id)
                  : handleStar(job?.company_id)
              }
            />
          )}
        </Box>
        <Box
          sx={{
            display: "flex",
            marginBottom: "10px",
            gap: "10px",
            alignItems: "center",
          }}
        >
          <Box
            component="img"
            sx={{
              height: 16,
              width: 15,
              maxHeight: { xs: 16 },
              maxWidth: { xs: 15 },
              // marginLeft: "-6px !important",
            }}
            alt="location"
            src={redLocation}
          />
          <Typography
            sx={{ fontSize: "14px", fontWeight: theme.typography.Bold }}
          >
            {job?.town ? `${job?.town}, ` : "-"}
            {job?.country ? `${job?.country}` : "-"}
          </Typography>
        </Box>

        <Box
          sx={{
            // marginBottom: "15px",
            // display: "flex",
            // flexDirection: "column",
            // gap: "10px",
            // height: "25px",
            display: "flex",
            // gap: "5px",
            marginBottom: "10px",
            minHeight: "25px",
            flexDirection: "column",
            gap: "10px",
            height: "25px",
            marginRight: "15px",
          }}
        >
          <NewSlider
            items={
              splitString(job?.Industries)?.sort(
                (a, b) => a.length - b.length
              ) || []
            }
            color={"recordVideoButton"}
            hideTagsAfter={3}
            height="25px"
            job={job}
            index={indexNumber}
            jobslength={jobslength}
            currentPageJobsCount={currentPageJobsCount}
            id={job?.company_id}
          />
        </Box>
        <Box
          sx={{
            // marginBottom: "15px",
            // display: "flex",
            // flexDirection: "column",
            // gap: "10px",
            // height: "25px",
            display: "flex",
            // gap: "5px",
            marginBottom: "10px",
            minHeight: "25px",
            flexDirection: "column",
            gap: "10px",
            height: "25px",
            marginRight: "15px",
          }}
        >
          <NewSlider
            items={
              splitString(job?.Company_Culture)?.sort(
                (a, b) => a.length - b.length
              ) || []
            }
            color={"precium"}
            hideTagsAfter={3}
            height="25px"
            job={job}
            index={indexNumber}
            jobslength={jobslength}
            currentPageJobsCount={currentPageJobsCount}
            id={job?.company_id}
          />
        </Box>
        <TextWrapper
          width={"307px"}
          textAlign={"left"}
          height={"112px"}
          size={12}
          letterSpacing="0.25px"
          marginBottom={page === "footer" ? "16px" : "37px"}
          lineHeight={1.6}
          fontWeight={theme.typography.Medium}
        >
          {job?.description}
        </TextWrapper>

        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "15px",
          }}
        >
          <MUIRadialChart
            value={job?.totaljobs}
            chartName={"job postings"}
            max={100}
            size={100}
            countFontSize={16}
            labelFontSize={12}
            color={"matched"}
          />
          <MUIRadialChart
            value={job?.totalactivejobs}
            chartName={"active jobs"}
            max={100}
            size={100}
            countFontSize={16}
            labelFontSize={12}
            color={"activeJobNew"}
          />
          <MUIRadialChart
            value={job?.totalhiredjobs}
            chartName={"hires"}
            max={100}
            size={100}
            countFontSize={16}
            labelFontSize={12}
            color={"hiredNew"}
          />
        </Box>
        <Box
          sx={{ display: "flex", marginLeft: "-15px", marginRight: "-15px" }}
        >
          <Link
            to={`/jobs/company/${job?.company_id}`}
            target={"_blank"}
            style={{
              width: "50%",
              textDecoration: "none",
              color: theme.palette.black,
            }}
          >
            <Button
              variant="contained"
              color="viewJobs"
              sx={{
                borderRadius: "0 0 0 25px",
                fontSize: "16px",
                padding: "19px 31px 19px 49px",
                fontWeight: theme.typography.Bold,
                width: "100%",
                height: "60px",
                textWrap: "nowrap",
                //   "& .MuiButton-startIcon": {
                //     margin: "0px",
                //   },
              }}
              startIcon={
                <Box
                  component={"img"}
                  src={linkedUsers}
                  sx={{
                    height: "30px",
                    width: "28px",
                  }}
                />
              }
            >
              view jobs
            </Button>
          </Link>

          <Button
            variant="contained"
            color={isCompanyFollowed ? "yoco" : "nandos"}
            sx={{
              borderRadius: "0 0 25px 0px",
              fontSize: "16px",
              padding: "19px 31px 19px 49px",
              fontWeight: theme.typography.Bold,
              width: "50%",
              height: "60px",
              textWrap: "nowrap",
              //   "& .MuiButton-startIcon": {
              //     margin: "0px",
              //   },
            }}
            onClick={() => followComp(job?.company_id, isCompanyFollowed)}
            startIcon={
              <Box
                component={"img"}
                sx={{
                  height: 20,
                  width: 20,
                }}
                src={FollowIcon}
              />
            }
          >
            {isCompanyFollowed ? "following" : "follow"}
          </Button>
        </Box>
      </Grid>
      <Button
        variant="contained"
        color="nandos"
        sx={{
          width: "20px",
          height: "100px",
          padding: 0,
          position: "absolute",
          right: 0,
          top: `calc(50% - 50px)`,
          minWidth: "20px",
          fontSize: "20px",
          borderRadius: "10px 0 0 10px",
        }}
        onClick={() => setisFlipped(true)}
      >
        <Box
          component={"img"}
          src={flip}
          sx={{
            height: "20px",
            width: "15px",
          }}
        />
      </Button>
    </Card>
  );
}
