import { Box, Button } from "@mui/material";
import React from "react";
import MUIRadialChart from "../../../common/MUIRadialChart";

export default function TalentCardRunMatchPopup({ talentContent }) {
  return (
    <>
      <Button
        variant="contained"
        color="recentButton"
        sx={{ width: "150px", height: "45px", borderRadius: "0 0 25px 0" }}
      >
        stats
      </Button>

      <Box sx={{ display: "flex", padding: "21px 10px 28px 20px", gap: "6px", justifyContent:"center" }}>
        <MUIRadialChart
          value={talentContent?.total_user_count || talentContent?.TotalUserCount || 0}
          size={100}
          color={"application"}
          chartName={"applied"}
          countFontSize={16}
          labelFontSize={12}
        />
        <MUIRadialChart
          value={talentContent?.total_user_shortlist ||
            talentContent?.totalusershortlisted||0}
          size={100}
          color={"shortlisting"}
          chartName={"shortlisted"}
          countFontSize={16}
          labelFontSize={12}
        />
        <MUIRadialChart
          value={talentContent?.total_user_interview||
            talentContent?.totaluserinterviewed||0}
          size={100}
          color={"interview"}
          chartName={"interviewed"}
          countFontSize={16}
          labelFontSize={12}
        />
      </Box>
      {/* <Box sx={{ position: "fixed", width: "450px", textAlign: "center" }}>
        <Button
          variant="contained"
          color="recentButton"
          sx={{
            width: "100px",
            height: "45px",
            borderRadius: "0 0 25px 25px",
            padding: "0px !important",
          }}
        >
          run match
        </Button>
      </Box> */}
    </>
  );
}
