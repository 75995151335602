import {
  Box,
  Button,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  styled,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import SearchBox from "./Dialogbox/SearchBox";
import InfiniteScroll from "react-infinite-scroll-component";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { ALERT_TYPE, VOUCHERS } from "../../../utils/Constants";
import { TableCells, ToggleSwitch } from "../../../utils/CommonComponent";
import TalentSVGButton from "../../common/TalentSVGButton";
import deleteIcon from "../../../assets/Padding Excluded/Black_Trash_Delete_1 - Copy.svg";
import editIcon from "../../../assets/Padding Included/Yellow_Edit.svg";
import AddVoucher from "./Dialogbox/AddVoucher";
import Claims from "./Dialogbox/Claims";
import CompanyClaims from "./Dialogbox/CompanyClaims";
import { useDispatch } from "react-redux";
import {
  deleteVoucher,
  getVouchers,
  updateVoucherStatus,
} from "../../../redux/admin/maintenance";
import { setAlert } from "../../../redux/configSlice";
import { dateConverterMonth } from "../../../utils/DateTime";
import Delete from "../../candidate/myCam/dialog/Delete";

export default function Vouchers() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [addVoucher, setAddVoucher] = useState(false);
  const [claims, setClaims] = useState(false);
  const [companyClaims, setCompanyClaims] = useState(false);
  const [voucherInfo, setVocuherInfo] = useState([]);
  const [searchTitle, setSearchTitle] = useState("");
  const [lastKey, setLastKey] = useState(0);
  const [claimVoucher, setClaimVoucher] = useState();
  const [selectedCompany, setSelectedCompany] = useState("");
  const [editVoucherData, setEditVoucherData] = useState({});
  const [edit, setEdit] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [deleteVoucherID, setDeleteVoucherID] = useState("");

  const handleText = async (event) => {
    setSearchTitle(event?.target?.value);
    // debouncedHandleOnTextSearch(event);
  };

  const getVouchersInfo = async (lastKeyy) => {
    try {
      const { payload } = await dispatch(getVouchers({ lastKey: lastKeyy }));
      if (payload?.status === "success") {
        if (payload?.pageNumber === 0) {
          setVocuherInfo(payload?.data);
          setLastKey(payload?.pageNumber + 1);
        } else if (payload?.data?.length > 0) {
          setVocuherInfo((prev) => [...prev, ...payload?.data]);
          setLastKey(payload?.pageNumber + 1);
        }
      } else {
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE?.ERROR,
            msg: payload?.message,
          })
        );
      }
    } catch (error) {
      dispatch(
        setAlert({
          show: true,
          type: ALERT_TYPE?.ERROR,
          msg: error,
        })
      );
    }
  };

  const handleSelectChange = async (event, id, name, voucherId) => {
    try {
      let data = {
        voucher_id: voucherId,
        status: id,
      };
      const { payload } = await dispatch(updateVoucherStatus(data));
      if (payload?.status === "success") {
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE?.SUCCESS,
            msg: "Status changed successfully",
          })
        );
        setVocuherInfo(
          voucherInfo?.map((item) =>
            item?.voucher_id === voucherId ? { ...item, status: id } : item
          )
        );
      } else {
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE?.ERROR,
            msg: payload?.message,
          })
        );
      }
    } catch (error) {
      dispatch(
        setAlert({
          show: true,
          type: ALERT_TYPE?.ERROR,
          msg: error,
        })
      );
    }
  };

  const handleEditVoucher = (row) => {
    setEditVoucherData({
      ...row,
      voucher_name: row?.name,
      coins: row?.coins,
      duration: row?.duration,
      use: row?.multiple_use ? 2 : 1,
    });
    setAddVoucher(true);
    setEdit(true);
  };

  const handleDelete = async () => {
    try {
      const { payload } = await dispatch(
        deleteVoucher({ voucher_id: deleteVoucherID })
      );
      if (payload?.status === "success") {
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE?.SUCCESS,
            msg: "Voucher deleted successfully",
          })
        );
        getVouchersInfo(0);
        setConfirmDelete(false);
        setOpenDelete(false);
      } else {
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE?.ERROR,
            msg: payload?.message,
          })
        );
      }
    } catch (error) {
      dispatch(
        setAlert({
          show: true,
          type: ALERT_TYPE?.ERROR,
          msg: error,
        })
      );
    }
  };

  // const handleSelectChange2 = (event, id, name, index) => {
  //   const newStudyData = talentDetails?.qualificationdata;
  //   if (newStudyData?.[index]) {
  //     newStudyData[index]["completed"] = event?.target?.checked?.toString();
  //     setTalentDetails({ ...talentDetails, newStudyData });
  //   }
  // };

  useEffect(() => {
    getVouchersInfo(0);
  }, []);

  return (
    <>
      <Box
        sx={{
          background: "white",
          borderRadius: "25px",
          mt: "45px",
          boxShadow: `0px 5px 10px ${theme?.palette?.boxShadow?.main}`,
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Button
            variant="contained"
            color="redButton"
            sx={{
              width: "150px",
              height: "45px",
              borderRadius: "25px 0 25px 0",
            }}
          >
            vouchers
          </Button>
          <Box sx={{ display: "flex", gap: "250px" }}>
            <SearchBox
              onChange={(event) => handleText(event)}
              width="500px"
              margin="5px 0 0 0"
              placeholder="search for a voucher"
            />
            <Button
              variant="contained"
              color="yellowButton400"
              sx={{
                width: "150px",
                height: "45px",
                borderRadius: "0 25px 0 25px",
              }}
              onClick={() => setAddVoucher(true)}
            >
              add a voucher
            </Button>
          </Box>
        </Box>

        <Box>
          <InfiniteScroll
            style={{ overflow: "hidden" }}
            dataLength={voucherInfo?.length}
            next={() => getVouchersInfo(lastKey, searchTitle)}
            scrollThreshold={"100px"}
            scrollableTarget={"jobList"}
            hasMore={true}
            endMessage={
              <p style={{ textAlign: "center" }}>
                <b>Yay! You have seen it all</b>
              </p>
            }
          >
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCells>
                      <Typography variant="subtitle1" fontWeight="bold">
                        Voucher Name
                      </Typography>
                    </TableCells>
                    <TableCells>
                      <Typography
                        variant="subtitle1"
                        fontWeight="bold"
                        textAlign={"center"}
                      >
                        Coins
                      </Typography>
                    </TableCells>

                    <TableCells>
                      <Typography
                        variant="subtitle1"
                        fontWeight="bold"
                        textAlign={"center"}
                      >
                        Multiple Use
                      </Typography>
                    </TableCells>
                    <TableCells>
                      <Typography
                        variant="subtitle1"
                        fontWeight="bold"
                        textAlign={"center"}
                      >
                        Duration
                      </Typography>
                    </TableCells>
                    <TableCells>
                      <Typography
                        variant="subtitle1"
                        fontWeight="bold"
                        textAlign={"center"}
                      >
                        Claims
                      </Typography>
                    </TableCells>
                    <TableCells>
                      <Typography
                        variant="subtitle1"
                        fontWeight="bold"
                        textAlign={"center"}
                      >
                        Date Created
                      </Typography>
                    </TableCells>
                    <TableCells>
                      <Typography
                        variant="subtitle1"
                        fontWeight="bold"
                        textAlign={"center"}
                      >
                        Claim Expiry Date
                      </Typography>
                    </TableCells>
                    <TableCells>
                      <Typography
                        variant="subtitle1"
                        fontWeight="bold"
                        textAlign={"center"}
                      >
                        Created By
                      </Typography>
                    </TableCells>
                    <TableCells>
                      <Typography
                        variant="subtitle1"
                        fontWeight="bold"
                        textAlign={"center"}
                      >
                        Status
                      </Typography>
                    </TableCells>
                    <TableCells></TableCells>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {voucherInfo?.map((row) => (
                    <TableRow
                      key={row.voucher_id}
                      style={{
                        "& .css-12zgwp-MuiTableCell-root": {
                          padding: "0 16px !important",
                        },
                      }}
                    >
                      <TableCells>{row?.name}</TableCells>
                      <TableCells sx={{ textAlign: "center" }}>
                        {row?.coins}
                      </TableCells>
                      <TableCells sx={{ textAlign: "center" }}>
                        {row?.multiple_use ? "Yes" : "No"}
                      </TableCells>
                      <TableCells sx={{ textAlign: "center" }}>
                        {row?.duration}
                      </TableCells>
                      <TableCells
                        sx={{
                          cursor: "pointer",
                          fontWeight: theme.typography.Bold,
                          textAlign: "center",
                        }}
                        onClick={() => {
                          setClaims(true);
                          setClaimVoucher(row);
                        }}
                      >
                        {row?.claims}
                      </TableCells>
                      <TableCells sx={{ textAlign: "center" }}>
                        {dateConverterMonth(row?.created_at)}
                      </TableCells>
                      <TableCells sx={{ textAlign: "center" }}>
                        {dateConverterMonth(row?.expiry_date)}
                      </TableCells>
                      <TableCells sx={{ textAlign: "center" }}>
                        {row?.user?.first_name} {row?.user?.last_name}
                      </TableCells>
                      <TableCells sx={{ textAlign: "center" }}>
                        {
                          <ToggleSwitch
                            color="recentButton"
                            checked={row?.status}
                            onChange={(event, id, name) =>
                              handleSelectChange(
                                event,
                                id,
                                name,
                                row?.voucher_id
                              )
                            }
                          />
                        }
                      </TableCells>
                      <TableCells sx={{ textAlign: "center" }}>
                        <Box sx={{ display: "flex", gap: "8px" }}>
                          <TalentSVGButton
                            onClick={() => handleEditVoucher(row)}
                            color={"white"}
                            source={editIcon}
                            // outerHeight={22}
                            height={33}
                            width={34}
                            padding={"0px !important"}
                            startIconMargin={"0px !important"}
                          />
                          <TalentSVGButton
                            color={"white"}
                            source={deleteIcon}
                            height={24}
                            width={18}
                            padding={"0 8px 0 0 !important"}
                            startIconMargin={"0px !important"}
                            onClick={() => {
                              setDeleteVoucherID(row?.voucher_id);
                              setOpenDelete(true);
                            }}
                          />
                        </Box>
                      </TableCells>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </InfiniteScroll>
        </Box>
      </Box>

      {addVoucher && (
        <AddVoucher
          addVoucher={addVoucher}
          handleClose={() => setAddVoucher(false)}
          getVouchersInfo={getVouchersInfo}
          editVoucherData={editVoucherData}
          edit={edit}
          setEdit={setEdit}
        />
      )}
      {claims && (
        <Claims
          openClaim={claims}
          handleClose={() => setClaims(false)}
          setCompanyClaims={setCompanyClaims}
          claimVoucherInfo={claimVoucher}
          setSelectedCompany={setSelectedCompany}
        />
      )}

      {companyClaims && (
        <CompanyClaims
          companyClaims={companyClaims}
          handleClose={() => {
            setCompanyClaims(false);
            setClaims(true);
          }}
          selectedCompany={selectedCompany}
        />
      )}
      <Delete
        show={openDelete}
        handleOpen={() => setOpenDelete((prevState) => !prevState)}
        handleCancel={() => setOpenDelete((prevState) => !prevState)}
        handleDelete={handleDelete}
        confirmDelete={confirmDelete}
        setConfirmDelete={setConfirmDelete}
        dialogText={"voucher"}
      />
    </>
  );
}
