import {
  Box,
  Button,
  IconButton,
  InputBase,
  Paper,
  Switch,
  TextField,
  Typography,
  alpha,
  useTheme,
} from "@mui/material";
import React, { useCallback, useEffect, useRef, useState } from "react";
import InputBox from "../../../common/InputBox";
import locale from "../../../../i18n/locale";
import {
  ToggleSwitch,
  getFemaleIcon,
  getMaleIcon,
} from "../../../../utils/CommonComponent";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { CheckCircle } from "@mui/icons-material";
import { isValidPhoneNumber } from "react-phone-number-input";
import PhoneInput from "react-phone-number-input";
import dayjs from "dayjs";
import CustomSelect from "../../../candidate/myCV/CustomSelect";
import {
  ALERT_TYPE,
  ERROR_MSG,
  PASSWORD_REGEX,
} from "../../../../utils/Constants";
import { setAlert, setLoading } from "../../../../redux/configSlice";
import { getTown } from "../../../../redux/employer/postJobSlice";
import {
  checkPhoneNumber,
  debounce,
  getDecodedToken,
  getToken,
} from "../../../../utils/Common";
import { useDispatch } from "react-redux";
import { resetPassword } from "../../../../redux/candidate/myProfileSlice";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import black from "../../../../assets/Characters/Race/crayon_race_black.svg";
import uploadCVIcon from "../../../../assets/MyCV/UploadCv.svg";
import white from "../../../../assets/Characters/Race/crayon_race_white.svg";
import indian from "../../../../assets/Characters/Race/crayon_race_indian.svg";
import coloured from "../../../../assets/Characters/Race/crayon_race_coloured.svg";
import own_internet from "../../../../assets/Padding Included/Crayon Icon_Own Internet.svg";
import own_equipment from "../../../../assets/Padding Included/Crayon Icon_Own Equipment.svg";
import own_vehicle from "../../../../assets/Padding Included/Crayon Icon_Own Vehicle.svg";
import { changePassword } from "../../../../redux/admin/userAdminSlice";
import TalentSVGButton from "../../../common/TalentSVGButton";
import pinIcon from "../../../../assets/Icons/pinIcon.svg";
import { pinATalent } from "../../../../redux/admin/jobsSlice";
import PinConfirmation from "../../adminJobs/editJobs/PinConfirmation";
import { uploadCvAdmin } from "../../../../redux/candidate/myCvSlice";

export default function EditTalentProfile({
  talentDetails,
  errors,
  handleInputChange,
  setTalentDetails,
  setErrors,
  setContactNumber,
  contactNumber,
  nationality,
  language,
  characterCount,
  count,
  countries,
  town,
  getAllData,
  setAllTalent,
  allTalent,
  pinTalentFlag,
  cvLink,
}) {
  const theme = useTheme();
  const i18n = locale.en;
  const currentDate = new Date();
  const profileDataRef = useRef(talentDetails);
  const decodedToken = getDecodedToken(getToken());
  const [openPin, setOpenPin] = useState(false);
  const fileAccept =
    "application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document";

  const dispatch = useDispatch();
  const regionNames = new Intl.DisplayNames(["en"], { type: "region" });
  const [previousCountryCode, setPreviousCountryCode] = useState(
    talentDetails?.country_code
  );
  const hiddenFileInput = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [pageIsLoad, setPageIsLoad] = useState(false);
  const [inputType, setInputType] = useState("password");
  const [showPassword, setShowPassword] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [confirmPin, setConfirmPin] = useState(false);
  const handleDateChange = (newValue) => {
    const newProfileData = {
      ...talentDetails,
      dob: dayjs(newValue).format("YYYY-MM-DD"),
    };
    const filteredErrors = errors?.filter((item) => item?.key !== "dob");
    setErrors(filteredErrors);
    setTalentDetails(newProfileData);
  };

  const handleCountryChange = async (value) => {
    if (pageIsLoad) {
      setPageIsLoad(false);
      return;
    } else {
      let selectedCountry = countries?.find((item) => item.id === value);
      const newProfileData = {
        ...talentDetails,
        country_code: selectedCountry
          ? `+${selectedCountry?.country_code}`
          : talentDetails?.country_code,
        country_id: value || talentDetails?.country_id,
        town_id: value ? talentDetails?.town_id : "",
        townName: value ? talentDetails?.townName : "",
        country: selectedCountry
          ? selectedCountry?.name
          : talentDetails?.country,
      };
      if (value === undefined && selectedCountry?.country_code) {
        setContactNumber(`${selectedCountry?.country_code}`);
      }
      setTalentDetails(newProfileData);
    }
  };

  const handleCountryIdChange = async (countryId) => {
    let updatedCountryCode = countries?.filter(
      (item) => item?.country_id === countryId
    )?.[0]?.country_code;
    setPreviousCountryCode(updatedCountryCode);
    handleCountryChange(countryId);
  };
  const handleContactNumber = async (name, value) => {
    setContactNumber(value);
    setTalentDetails({
      ...talentDetails,
      contact_no: value,
    });

    const filteredErrors = errors?.filter((item) => item?.key !== "contact_no");
    setErrors(filteredErrors);
  };

  const handlePrefer = (event, name) => {
    const preferToSay = event?.target?.checked ? true : false;
    const newProfileData = {
      ...talentDetails,
      [name]: preferToSay,
      gender: null,
    };
    setErrors(errors?.filter((item) => item.key !== "gender"));
    setTalentDetails(newProfileData);
  };

  const handleGender = (event, gender) => {
    const newProfileData = {
      ...talentDetails,
      gender: gender,
    };
    const filteredErrors = errors?.filter((item) => item.key !== "gender");
    setErrors(filteredErrors);
    setTalentDetails(newProfileData);
  };
  const debouncedHandleOnSelectInputChange = useCallback(
    debounce(
      async (newValue, type) => {
        let params = {
          title: newValue || "",
          id: profileDataRef.current?.country_id,
          limit: 200,
        };
        if (newValue) {
          try {
            if (type === "town_id") {
              dispatch(getTown(params));
            }
          } catch (error) {
            dispatch(
              setAlert({
                show: true,
                type: ALERT_TYPE?.ERROR,
                msg: error,
              })
            );
          }
        }
      },
      750,
      setIsLoading
    ),
    []
  );
  const handleOnSelectInputChange = (newValue, inputId) => {
    debouncedHandleOnSelectInputChange(newValue, inputId);
  };

  const handleOnSelectChange = async (ind, newValue, val, inputId) => {
    if (inputId === "country_id") {
      let countryId = newValue?.value;
      let selectedCountry = countries?.find((item) => item.id === countryId);

      if (selectedCountry && selectedCountry?.country_code) {
        let updatedNumber = checkPhoneNumber(
          previousCountryCode,
          selectedCountry?.country_code,
          contactNumber
        );

        setPreviousCountryCode(selectedCountry?.country_code);
        const newProfileData = {
          ...talentDetails,
          country_code: selectedCountry
            ? selectedCountry?.country_code
            : talentDetails?.country_code,
          country_id: newValue !== undefined ? countryId : "",
          town_id: "",
          townName: "",
          country:
            newValue !== undefined
              ? newValue?.label || talentDetails?.country_name
              : "",
        };
        setContactNumber(updatedNumber);
        setTalentDetails(newProfileData);
        setErrors(errors?.filter((item) => item?.key !== "country_id"));
        try {
          dispatch(setLoading(true));
          const { payload } = await dispatch(getTown(countryId));
          dispatch(setLoading(false));
        } catch (error) {
          dispatch(setLoading(false));
          dispatch(
            setAlert({
              show: true,
              type: ALERT_TYPE.ERROR,
              msg: ERROR_MSG,
            })
          );
        }
      } else {
        const newProfileData = {
          ...talentDetails,
          country_code: "",
          country_id: "",

          town_id: "",
          townName: "",
          country: "",
        };
        setTalentDetails(newProfileData);
      }
    }
    if (inputId === "town_id") {
      const newProfileData = {
        ...talentDetails,
        towns: newValue,
      };
      setTalentDetails(newProfileData);
      setErrors(errors?.filter((item) => item?.key !== "town_id"));
    }
    if (ind === "nationality_ids") {
      let newProfileData = {};
      newProfileData = {
        ...talentDetails,
        [ind]: newValue,
      };
      setTalentDetails(newProfileData);
      setErrors(errors?.filter((item) => item?.key !== "nationality_ids"));
    }
    if (ind === "language_ids") {
      let newProfileData = {};
      newProfileData = {
        ...talentDetails,
        [ind]: newValue,
      };
      setTalentDetails(newProfileData);
      setErrors(errors?.filter((item) => item?.key !== "language_ids"));
    }
  };

  const onMenuClose = async () => {
    try {
      const { payload } = await dispatch(
        getTown(talentDetails?.country_id || "")
      );
    } catch (error) {
      dispatch(
        setAlert({
          show: true,
          type: ALERT_TYPE.ERROR,
          msg: ERROR_MSG,
        })
      );
    }
  };

  const onMenuOpen = async () => {
    if (town?.length === 0) {
      try {
        const { payload } = await dispatch(
          getTown(talentDetails?.country_id || "")
        );
      } catch (error) {
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE.ERROR,
            msg: ERROR_MSG,
          })
        );
      }
    }
  };

  const handleSwitch = (event) => {
    const newProfileData = {
      ...talentDetails,
      [event.target.id]: Number(event?.target?.checked),
    };
    setTalentDetails(newProfileData);
  };

  const getNatiValue = () => {
    if (talentDetails?.nationality_ids?.length === 0) {
      return [];
    }
    return talentDetails?.nationality_ids?.map(
      (nation) => nationality?.find((nati) => nati.id === nation) || nation
    );
  };
  const getLangValue = () => {
    if (talentDetails?.language_ids?.length === 0) {
      return [];
    }
    return talentDetails?.language_ids?.map(
      (lang) => language?.find((language) => language.id === lang) || lang
    );
  };

  const handlePassword = (event) => {
    setNewPassword(event?.target?.value);
    if (!PASSWORD_REGEX?.test(event?.target?.value)) {
      setErrors((prev) => [
        ...prev,
        { message: "password not matching", key: "password" },
      ]);
      return;
    } else {
      const filteredErrors = errors?.filter((item) => item?.key !== "password");
      setErrors(filteredErrors);
    }
  };

  const handlePasswordReset = async () => {
    const payload = {
      userid: talentDetails?.user_id,
      password: newPassword,
    };
    if (!PASSWORD_REGEX?.test(newPassword)) {
      setErrors((prev) => [
        ...prev,
        { message: "password not matching", key: "password" },
      ]);
      return;
    }
    try {
      const response = await dispatch(changePassword(payload));
      if (response?.payload?.status === "success") {
        // setNewPassword("");
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE.SUCCESS,
            msg: "Password reset Successfully!",
          })
        );
      } else {
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE.ERROR,
            msg: response?.payload?.message,
          })
        );
      }
    } catch (error) {
      dispatch(setAlert({ show: true }));
    }
  };

  const handleShowPassword = () => {
    if (showPassword) setInputType("password");
    else setInputType("text");

    setShowPassword(!showPassword);
  };

  const handleColor = (event, color) => {
    const newProfileData = {
      ...talentDetails,
      skinz: color,
    };
    setTalentDetails(newProfileData);
    setErrors((prev) => prev?.filter((item) => item?.key !== "skinz"));
  };

  const handleChangeSetup = (event, name) => {
    const newProfileData = {
      ...talentDetails,
      [name]: event?.target?.checked,
    };
    setTalentDetails(newProfileData);
  };

  const handlePinTalent = async () => {
    try {
      let data = {
        talent_id: talentDetails?.user_id,
        value: !pinTalentFlag,
      };
      const { payload } = await dispatch(pinATalent(data));
      if (payload?.status === "success") {
        setAllTalent((prevAllJobs) => {
          const updatedItem = prevAllJobs
            .filter(
              (item) => item?.candidate_user_id === talentDetails?.user_id
            )
            .map((item) => ({
              ...item,
              pin_talent: !pinTalentFlag,
            }))[0];
          const remainingItems = prevAllJobs.filter(
            (item) => item?.candidate_user_id !== talentDetails?.user_id
          );
          return [updatedItem, ...remainingItems];
        });
        setOpenPin(false);
        setConfirmPin(false);
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE?.SUCCESS,
            msg: "Talent pinned successfully",
          })
        );
      } else {
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE?.ERROR,
            msg: "Error",
          })
        );
      }
    } catch (error) {
      dispatch(
        setAlert({
          show: true,
          type: ALERT_TYPE?.ERROR,
          msg: error,
        })
      );
    }
  };
  const handleFileClick = () => {
    hiddenFileInput?.current?.click();
  };
  const handleFileChange = async (event) => {
    const formData = new FormData();
    formData.append("cv", event?.target?.files?.[0]);
    formData.append("candidate_id", talentDetails?.user_id);
    try {
      const { payload } = await dispatch(uploadCvAdmin(formData));
      if (payload?.status === "success") {
        console.log(
          payload,
          payload?.data?.[0]?.cv_link,
          "payloadhandleFileChange"
        );
        // const parts =
        //   payload && payload.data && payload.data[0]?.cv_link
        //     ? payload.data[0].cv_link.split("/")
        //     : [];
        // let name =
        //   parts.length > 0 ? parts[parts.length - 1] : "No file chosen";
        // console.log(
        //   parts.length > 0 ? parts[parts.length - 1] : "No file chosen",
        //   "CUP"
        // );
        // setCVName(name);
        const allData = [...allTalent];
        const userIdToUpdate = talentDetails?.user_id;
        const updatedData = allData.map((talent) =>
          talent.user_id === userIdToUpdate
            ? {
                ...talent,
                cv_link: payload?.data?.[0]?.cv_link,
                cv_url: payload?.cv_url,
              }
            : talent
        );
        setAllTalent([...updatedData]);
      } else {
      }
    } catch (error) {
      dispatch(setAlert({ show: true }));
    }
  };

  useEffect(() => {
    profileDataRef.current = talentDetails;
    // if (talentDetails?.cv_link) {
    //   if (cVName === "No file chosen") {
    //     const parts = talentDetails?.cv_link
    //       ? talentDetails.cv_link.split("/")
    //       : [];
    //     setCVName(
    //       parts[parts?.length - 1] ? parts[parts?.length - 1] : "No file chosen"
    //     );
    //   }
    // } else {
    //   setCVName("No file chosen");
    // }
  }, [talentDetails]);

  return (
    <>
      <Box
        sx={{
          p: 4,
          pb: 0,
          display: "flex",
          flexDirection: "column",
          gap: "16px",
          position: "relative",
        }}
      >
        <Box sx={{ position: "absolute", right: "0", top: "0", width: "100%" }}>
          <Button
            variant="contained"
            color={cvLink !== null ? "checkColor" : "peachPayment"}
            sx={{
              fontSize: theme.typography.fontSize.sm,
              fontWeight: theme.typography.Bold,
              minHeight: "45px",
              minWidth: { xs: "", md24: "150px" },
              // borderRadius:{xs:"0px",md24:"0 0 0 10px"},
              position: "absolute",
              right: "0",
              top: "0",
              borderRadius: "0 0 0 10px",
              padding: { xs: "6px", md24: "0px" },
              textWrap: "nowrap",
              boxShadow: "none",
              "& .MuiButton-startIcon": {
                marginLeft: "0px",
              },
            }}
            onClick={handleFileClick}
            startIcon={
              <Box
                component={"img"}
                src={uploadCVIcon}
                sx={{
                  display: { xs: "none", tablet: "block" },
                  height: "20px",
                  width: "26px",
                }}
              />
            }
          >
            {cvLink !== null ? "CV uploaded" : "upload my CV"}
          </Button>
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: theme?.typography?.Regular,
              ml: 1,
              mt: "4px",
              position: "absolute",
              right: "0",
              top: "42px",
            }}
          >
            {cvLink !== null ? cvLink : "No file selected"}
          </Typography>
        </Box>

        <Box
          sx={{
            display: "flex",
            width: "100%",
            // justifyContent: "space-between",
          }}
        >
          <Typography
            sx={{ fontWeight: theme.typography.Bold, width: "250px" }}
          >
            {i18n["myProfile.Profileinfo"]}
          </Typography>
          <input
            accept={fileAccept}
            ref={hiddenFileInput}
            type="file"
            onChange={handleFileChange}
            style={{ display: "none" }}
            required
          />

          <Box sx={{ display: "flex", gap: "10px", width: "100%" }}>
            {((decodedToken?.data?.role_id === 1 && !pinTalentFlag) ||
              (decodedToken?.data?.role_id === 2 && !pinTalentFlag)) && (
              <>
                <Typography
                  sx={{
                    fontSize: "18px",
                    fontWeight: theme.typography.Bold,
                    textAlign: "center",
                    marginLeft: "25px",
                  }}
                >
                  Do you want to pin this candidate?
                </Typography>

                <TalentSVGButton
                  onClick={() => setOpenPin(true)}
                  color={"white"}
                  source={pinIcon}
                  outerHeight={25}
                  height={25}
                  width={25}
                  padding={"0px !important"}
                  startIconMargin={"0px !important"}
                  margin={"2px 0 0 0"}
                />
              </>
            )}
          </Box>
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Box sx={{ width: "50%" }}>
            <Typography
              sx={{
                color: "black",
                paddingBottom: "2px",
                fontSize: "14px",
                fontWeight: theme?.typography?.Bold,
              }}
            >
              {i18n["myProfile.nameLabelRequired"]}
            </Typography>
            <InputBox
              sx={{
                width: "95%",
                height: "38px",
                borderRadius: "10px",
                border: errors?.find((error) => error?.key === "first_name")
                  ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
                  : "1px solid #E0E0E0",
              }}
              placeholder={i18n["myProfile.name"]}
              value={talentDetails?.first_name}
              id="first_name"
              onChange={handleInputChange}
            />
          </Box>
          <Box
            sx={{
              width: "50%",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-end",
            }}
          >
            <Typography
              sx={{
                color: "black",
                paddingBottom: "2px",
                fontSize: "14px",
                fontWeight: theme?.typography?.Bold,
                width: "94%",
              }}
            >
              {i18n["myProfile.surnameLabelRequired"]}
            </Typography>
            <InputBox
              sx={{
                width: "94%",
                height: "38px",
                borderRadius: "10px",
                border: errors?.find((error) => error?.key === "last_name")
                  ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
                  : "1px solid #E0E0E0",
              }}
              placeholder={i18n["myProfile.surname"]}
              value={talentDetails?.last_name}
              id="last_name"
              onChange={handleInputChange}
            />
          </Box>
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Box sx={{ width: "50%" }}>
            <Typography
              sx={{
                color: "black",
                paddingBottom: "2px",
                fontSize: "14px",
                fontWeight: theme?.typography?.Bold,
              }}
            >
              {i18n["myProfile.emailLabelRequired"]}
            </Typography>
            <InputBox
              sx={{
                width: "95%",
                height: "38px",
                borderRadius: "10px",
                border: errors?.find((error) => error?.key === "email")
                  ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
                  : "1px solid #E0E0E0",
              }}
              placeholder={i18n["myProfile.email"]}
              value={talentDetails?.email}
              id="email"
              onChange={handleInputChange}
            />
          </Box>
          <Box sx={{ width: "47%" }}>
            <Typography
              sx={{
                color: "black",
                paddingBottom: "2px",
                fontSize: "14px",
                fontWeight: theme?.typography?.Bold,
              }}
            >
              {i18n["myProfile.genderLabelOpt"]}
            </Typography>
            <Box sx={{ display: "flex", gap: "8px" }}>
              <Paper
                elevation={3}
                sx={{
                  width: "176px",
                  height: "40px",
                  boxShadow: "none",
                  border: errors?.find((error) => error?.key === "gender")
                    ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
                    : `1px solid ${theme.palette.grayBorder}`,
                  borderRadius: "10px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Button
                    name="gender"
                    startIcon={getMaleIcon(talentDetails?.gender)}
                    variant="contained"
                    color={
                      talentDetails?.gender === "male"
                        ? "blueButton400"
                        : "grayBorder"
                    }
                    onClick={(event) => handleGender(event, "male")}
                    sx={{
                      borderRadius: "10px",
                      height: "40px",
                      width: "85px",
                      fontWeight: 600,
                      fontSize: "12px",
                      color:
                        talentDetails?.gender === "female" &&
                        theme.palette.black,
                      background:
                        talentDetails?.gender === "female" &&
                        theme.palette.base.main,
                      ":hover": {
                        boxShadow: "none",
                      },
                    }}
                    disabled={talentDetails?.gender_flag}
                  >
                    {i18n["empMyProfile.male"]}
                  </Button>
                  <Button
                    name="gender"
                    startIcon={getFemaleIcon(talentDetails?.gender)}
                    sx={{
                      borderRadius: "10px",
                      height: "40px",
                      width: "85px",
                      fontWeight: 600,
                      fontSize: "12px",
                      color:
                        talentDetails?.gender === "male" && theme.palette.black,
                      background:
                        talentDetails?.gender === "male" &&
                        theme.palette.base.main,
                      ":hover": {
                        boxShadow: "none",
                      },
                    }}
                    disabled={talentDetails?.gender_flag}
                    variant="contained"
                    color={
                      talentDetails?.gender === "female"
                        ? "purpleButton300"
                        : "grayBorder"
                    }
                    onClick={(event) => handleGender(event, "female")}
                  >
                    {i18n["empMyProfile.female"]}
                  </Button>
                </Box>
              </Paper>

              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Typography
                  sx={{
                    minWidth: "fit-content",
                  }}
                >
                  Prefer not to say
                </Typography>
                <Switch
                  checked={talentDetails?.gender_flag}
                  sx={{
                    "& .MuiSwitch-switchBase.Mui-checked": {
                      color: theme.palette.blueButton700.main,
                      "&:hover": {
                        backgroundColor: alpha(
                          theme.palette.blueButton700.main,
                          theme.palette.action.hoverOpacity
                        ),
                      },
                    },
                    "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
                      backgroundColor: theme.palette.blueButton700.main,
                    },
                    "& .MuiSwitch-track": {
                      margin: "auto",
                      height: "60% !important",
                    },
                    "& .MuiSwitch-thumb": {
                      borderRadius: "6px !important",
                    },
                    "& .MuiButtonBase-root-MuiSwitch-switchBase": {
                      borderRadius: "15% !important",
                    },
                    "& .MuiButtonBase-root": {
                      padding: "9px !important",
                    },
                  }}
                  onChange={(event) => {
                    handlePrefer(event, "gender_flag");
                  }}
                />
              </Box>
            </Box>
          </Box>
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            width: "100%",
          }}
        >
          <Box sx={{ width: "50%" }}>
            <Typography
              sx={{
                color: "black",
                paddingBottom: "2px",
                fontSize: "14px",
                fontWeight: theme?.typography?.Bold,
              }}
            >
              {i18n["myProfile.contactLabelReq"]}
            </Typography>

            <Paper
              sx={{
                display: "flex",
                width: "95%",
                height: "38px",
                borderRadius: "10px",
                boxShadow: "none",
              }}
            >
              <Box
                sx={{
                  background: "transparent",
                  width: "100%",
                  height: "38px",
                  borderRadius: "10px",
                  display: "flex",
                  justifyContent: "space-between",
                  border: errors?.find((error) => error?.key === "contact_no")
                    ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
                    : "1px solid #E0E0E0",
                }}
              >
                <PhoneInput
                  international
                  id="contact_no"
                  placeholder="Enter phone number"
                  countryCallingCodeEditable={false}
                  value={
                    contactNumber?.startsWith("+")
                      ? contactNumber
                      : `${talentDetails?.country_code}${contactNumber}` || ""
                  }
                  onChange={(value) => {
                    handleContactNumber("contact_no", value);
                  }}
                  // defaultCountry={talentDetails?.country_code?.toUpperCase()}
                  focusInputOnCountrySelection={true}
                  onCountryChange={(value) => {
                    const newCountryId = value
                      ? countries?.find(
                          (val) => val?.name === regionNames?.of(value)
                        )?.id
                      : "";
                    handleCountryIdChange(newCountryId);
                  }}
                  style={{
                    font: "inherit",
                    padding: "10px",
                  }}
                />
                {isValidPhoneNumber(contactNumber || "") && (
                  <Typography
                    sx={{
                      color: theme.palette.tealColor.main,
                      fontSize: "14px",
                      alignItems: "center",
                      display: "flex",
                      gap: "5px",
                      marginRight: "5px",
                      justifyContent: "end",
                    }}
                  >
                    Correct <CheckCircle fontSize="small" />
                  </Typography>
                )}
              </Box>
            </Paper>
          </Box>
          <Box
            sx={{
              width: "23%",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-end",
            }}
          >
            <Typography
              sx={{
                color: "black",
                paddingBottom: "2px",
                fontSize: "14px",
                fontWeight: theme?.typography?.Bold,
                width: "88%",
              }}
            >
              {i18n["myProfile.birthLabelRequired"]}
            </Typography>
            <LocalizationProvider dateAdapter={AdapterDayjs} locale="en-gb">
              <DatePicker
                name="dob"
                value={talentDetails?.dob}
                onChange={handleDateChange}
                maxDate={currentDate}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    sx={{
                      width: "88%",

                      "& .MuiInputBase-root": {
                        height: "40px",
                        width: "100%",
                        borderRadius: "10px",
                        border: errors?.find((error) => error?.key === "dob")
                          ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
                          : "1px solid #E0E0E0",
                      },
                      "& fieldset": {
                        border: "none",
                      },
                      "& .MuiIconButton-root": {
                        color: theme.palette.yellowColor,
                      },
                    }}
                  />
                )}
              />
            </LocalizationProvider>
          </Box>
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Box sx={{ width: "50%" }}>
            <Typography sx={{ marginBottom: "4px" }}>
              {i18n["myProfile.countryRequired"]}
            </Typography>
            <CustomSelect
              onMenuOpen={() => {
                if (countries?.length === 0) {
                  getAllData("country");
                }
              }}
              variant="default"
              onInputChange={handleOnSelectInputChange}
              onChange={handleOnSelectChange}
              placeholder={"Select"}
              inputId="country_id"
              name="country_id"
              value={{
                value: talentDetails?.country_id,
                label: talentDetails?.country,
              }}
              isLoading={isLoading}
              filterOption={"filter"}
              customStyle={{
                menu: (provided) => ({
                  ...provided,
                  zIndex: 9999,
                }),
                menuList: (provided) => ({
                  ...provided,
                  "&::-webkit-scrollbar": {
                    width: "6px",
                    height: "6px",
                    borderRadius: "2.5px",
                    backgroundColor: "#efefef",
                  },
                  "&::-webkit-scrollbar-thumb": {
                    borderRadius: "2.5px",
                  },
                }),
                option: (
                  styles,
                  { data, isDisabled, isFocused, isSelected }
                ) => {
                  return {
                    ...styles,
                    color: isSelected ? "#fff" : "black",
                    ":active": {
                      ...styles[":active"],
                    },
                  };
                },
                control: (baseStyles) => ({
                  ...baseStyles,
                  boxShadow: "none",
                  borderRadius: "10px",
                  width: "95%",
                  border: errors?.find((error) => error?.key === "country_id")
                    ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
                    : "1px solid #E0E0E0",
                  "&:hover": errors?.find(
                    (error) => error?.key === "country_id"
                  )
                    ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
                    : "1px solid #E0E0E0",
                }),
              }}
              option={countries?.map((item) => ({
                value: item?.country_id,
                label: item?.name,
              }))}
            />
          </Box>
          <Box sx={{ width: "47%" }}>
            <Typography sx={{ marginBottom: "4px" }}>
              {i18n["myProfile.townLabelRequired"]}
            </Typography>
            <CustomSelect
              onMenuClose={onMenuClose}
              onMenuOpen={() => {
                if (town?.length === 0) {
                  onMenuOpen();
                }
              }}
              variant="default"
              onInputChange={handleOnSelectInputChange}
              onChange={handleOnSelectChange}
              placeholder={i18n["myProfile.city"]}
              inputId="town_id"
              name="town_id"
              value={talentDetails?.towns}
              isLoading={isLoading}
              customStyle={{
                menu: (provided) => ({
                  ...provided,
                  zIndex: 9999,
                }),
                menuList: (provided) => ({
                  ...provided,
                  "&::-webkit-scrollbar": {
                    width: "6px",
                    height: "6px",
                    borderRadius: "2.5px",
                    backgroundColor: "#efefef",
                  },
                  "&::-webkit-scrollbar-thumb": {
                    borderRadius: "2.5px",
                  },
                }),
                option: (
                  styles,
                  { data, isDisabled, isFocused, isSelected }
                ) => {
                  return {
                    ...styles,
                    color: isSelected ? "#fff" : "black",
                    ":active": {
                      ...styles[":active"],
                    },
                  };
                },
                control: (baseStyles) => ({
                  ...baseStyles,
                  border: errors?.find((error) => error?.key === "town_id")
                    ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
                    : "1px solid #E0E0E0",
                  "&:hover": errors?.find((error) => error?.key === "town_id")
                    ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
                    : "1px solid #E0E0E0",
                  boxShadow: "none",
                  borderRadius: "10px",
                }),
              }}
              option={town?.map((item) => ({
                value: item?.town_id,
                label: item?.name,
              }))}
            />
          </Box>
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Box sx={{ width: "50%" }}>
            <Typography sx={{ marginBottom: "4px" }}>
              {i18n["myProfile.nationalityLabelRequired"]}
            </Typography>
            <CustomSelect
              onMenuOpen={() => {
                if (nationality?.length === 0) {
                  getAllData("nationality");
                }
              }}
              variant="multiSelect"
              filterOption={"filter"}
              onInputChange={handleOnSelectInputChange}
              onChange={handleOnSelectChange}
              placeholder={i18n["myProfile.nationality"]}
              inputId="nationality_ids"
              name="nationality_ids"
              value={
                talentDetails?.nationality_ids?.length > 0
                  ? talentDetails?.nationality_ids
                  : []
              }
              isLoading={isLoading}
              customStyle={{
                menu: (provided) => ({
                  ...provided,
                  zIndex: 9999,
                }),
                menuList: (provided) => ({
                  ...provided,
                  "&::-webkit-scrollbar": {
                    width: "6px",
                    height: "6px",
                    borderRadius: "2.5px",
                    backgroundColor: "#efefef",
                  },
                  "&::-webkit-scrollbar-thumb": {
                    borderRadius: "2.5px",
                  },
                }),
                option: (
                  styles,
                  { data, isDisabled, isFocused, isSelected }
                ) => {
                  return {
                    ...styles,
                    color: isSelected ? "#fff" : "black",
                    ":active": {
                      ...styles[":active"],
                    },
                  };
                },
                control: (baseStyles) => ({
                  ...baseStyles,
                  border: errors?.find(
                    (error) => error?.key === "nationality_ids"
                  )
                    ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
                    : "1px solid #E0E0E0",
                  "&:hover": errors?.find(
                    (error) => error?.key === "nationality_ids"
                  )
                    ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
                    : "1px solid #E0E0E0",
                  boxShadow: "none",
                  borderRadius: "10px",
                  width: "95%",
                }),
              }}
              multiSelectOptions={nationality?.map((item) => ({
                value: item?.nationality_id,
                label: item?.name,
              }))}
            />
          </Box>
          <Box
            sx={{
              width: "47%",
            }}
          >
            <Typography sx={{ marginBottom: "4px" }}>
              {i18n["myProfile.languageLabelRequired"]}
            </Typography>
            <CustomSelect
              onMenuOpen={() => {
                if (language?.length === 0) {
                  getAllData("language");
                }
              }}
              variant="multiSelect"
              onInputChange={handleOnSelectInputChange}
              onChange={handleOnSelectChange}
              placeholder={i18n["myProfile.language"]}
              inputId="language_ids"
              name="language_ids"
              filterOption={"filter"}
              value={talentDetails?.language_ids}
              isLoading={isLoading}
              customStyle={{
                menu: (provided) => ({
                  ...provided,
                  zIndex: 9999,
                }),
                menuList: (provided) => ({
                  ...provided,
                  "&::-webkit-scrollbar": {
                    width: "6px",
                    height: "6px",
                    borderRadius: "2.5px",
                    backgroundColor: "#efefef",
                  },
                  "&::-webkit-scrollbar-thumb": {
                    borderRadius: "2.5px",
                  },
                }),
                option: (
                  styles,
                  { data, isDisabled, isFocused, isSelected }
                ) => {
                  return {
                    ...styles,
                    color: isSelected ? "#fff" : "black",
                    ":active": {
                      ...styles[":active"],
                    },
                  };
                },
                control: (baseStyles) => ({
                  ...baseStyles,
                  border: errors?.find((error) => error?.key === "language_ids")
                    ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
                    : "1px solid #E0E0E0",
                  "&:hover": errors?.find(
                    (error) => error?.key === "language_ids"
                  )
                    ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
                    : "1px solid #E0E0E0",
                  boxShadow: "none",
                  borderRadius: "10px",
                }),
              }}
              multiSelectOptions={language?.map((item) => ({
                value: item?.language_id,
                label: item?.name,
              }))}
            />
          </Box>
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Box sx={{ width: "50%" }}>
            <Typography sx={{ marginBottom: "4px" }}>
              {i18n["myProfile.myBioRequired"]}{" "}
              <span>({characterCount}/500)</span>
            </Typography>
            <InputBox
              multiline={true}
              sx={{
                width: "95%",
                height: "120px",
                borderRadius: "10px",
                alignItems: "flex-start",
                border: errors?.find((error) => error?.key === "my_bio")
                  ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
                  : "1px solid #E0E0E0",
              }}
              placeholder={i18n["myProfile.myBioPlace"]}
              value={talentDetails?.my_bio}
              id="my_bio"
              onChange={handleInputChange}
            />
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "47%",
              gap: "15px",
            }}
          >
            <Box sx={{ width: "100%" }}>
              <Typography sx={{ marginBottom: "4px" }}>
                {i18n["myProfile.linkedInLabelRequired"]}
              </Typography>
              <InputBox
                sx={{
                  width: "100%",
                  height: "38px",
                  borderRadius: "10px",
                  border: errors?.find(
                    (error) => error?.key === "linkedin_profile_link"
                  )
                    ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
                    : "1px solid #E0E0E0",
                }}
                placeholder={i18n["myProfile.linkedIn"]}
                value={talentDetails?.linkedin_profile_link}
                id="linkedin_profile_link"
                onChange={handleInputChange}
              />
            </Box>
            {/*<Box sx={{ width: "100%" }}>
              <Typography sx={{ marginBottom: "4px" }}>
                {i18n["myProfile.passwordChangeLabelOpt"]}
              </Typography>
              <Paper
                sx={{
                  display: "flex",
                  height: "38px",
                  borderRadius: "10px",
                  border: `1px solid ${theme.palette.grayBorder}`,
                  width: "100%",
                  boxShadow: "none",
                }}
              >
                <Paper
                  elevation={0}
                  component="form"
                  sx={{
                    display: "flex",
                    borderRadius: "25px",
                    alignItems: "center",
                    width: 1,
                  }}
                >
                  <InputBase
                    sx={{
                      ml: 2,
                      width: 1,
                    }}
                    value={newPassword}
                    onChange={(event) => handlePassword(event)}
                    placeholder={i18n["myProfile.password"]}
                    type={inputType}
                  />
                </Paper>
                <IconButton
                  color=""
                  aria-label="reset password"
                  component="button"
                  onClick={handleShowPassword}
                >
                  {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                </IconButton>

                <Button
                  sx={{
                    ml: "4px",
                    minWidth: "fit-content",
                    boxShadow: 0,
                    borderRadius: "0 10px 10px 0",
                    height: "auto",
                  }}
                  variant="contained"
                  color="basicColor"
                  onClick={handlePasswordReset}
                  disabled={newPassword.length === 0 ? true : false}
                >
                  {i18n["myProfile.resetPassword"]}
                </Button>
              </Paper>
              {errors?.find((error) => error?.key === "password") && (
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: theme.typography.Medium,
                    color: "red",
                    mt: "4px",
                    // textAlign: "center",
                    // width: "272px",
                  }}
                >
                  {i18n["alert.passwordmustcontain"]}
                </Typography>
              )}
            </Box>*/}
          </Box>
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Box
            sx={{
              width: "47.5%",
              borderRadius: "10px",
              border:
                errors?.find((error) => error?.key === "skinz") &&
                `1px solid ${theme.jobCard.jobStatus.Rejected.main}`,
            }}
          >
            <Typography sx={{ marginBottom: "4px" }}>
              Select your Crayon Skinz*
            </Typography>
            <Box sx={{ display: "flex", gap: "15px" }}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "flex-end",
                  cursor: "pointer",
                }}
                onClick={(event) => handleColor(event, 0)}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Box
                    component={"img"}
                    src={white}
                    sx={{
                      height: "60px",
                      width: "60px",
                    }}
                  />
                  <Typography
                    sx={{
                      color: "black",
                      fontSize: "14px",
                      fontWeight: 500,
                    }}
                  >
                    White
                  </Typography>
                </Box>
                <Box sx={{ width: "20px" }}>
                  {talentDetails?.skinz === 0 && (
                    <CheckCircle
                      sx={{
                        fontSize: 25,
                      }}
                      color="checkColor"
                    />
                  )}
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "flex-end",
                  cursor: "pointer",
                }}
                onClick={(event) => handleColor(event, 1)}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Box
                    component={"img"}
                    src={coloured}
                    sx={{
                      height: "60px",
                      width: "60px",
                    }}
                  />
                  <Typography
                    sx={{
                      color: "black",
                      fontSize: "14px",
                      fontWeight: 500,
                    }}
                  >
                    Coloured
                  </Typography>
                </Box>
                <Box sx={{ width: "20px" }}>
                  {talentDetails?.skinz === 1 && (
                    <CheckCircle
                      sx={{
                        fontSize: 25,
                      }}
                      color="checkColor"
                    />
                  )}
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "flex-end",
                  cursor: "pointer",
                }}
                onClick={(event) => handleColor(event, 2)}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Box
                    component={"img"}
                    src={indian}
                    sx={{
                      height: "60px",
                      width: "60px",
                    }}
                  />
                  <Typography
                    sx={{
                      color: "black",
                      fontSize: "14px",
                      fontWeight: 500,
                    }}
                  >
                    Indian
                  </Typography>
                </Box>
                <Box sx={{ width: "20px" }}>
                  {talentDetails?.skinz === 2 && (
                    <CheckCircle
                      sx={{
                        fontSize: 25,
                      }}
                      color="checkColor"
                    />
                  )}
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "flex-end",
                  cursor: "pointer",
                }}
                onClick={(event) => handleColor(event, 3)}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Box
                    component={"img"}
                    src={black}
                    sx={{
                      height: "60px",
                      width: "60px",
                    }}
                  />
                  <Typography
                    sx={{
                      color: "black",
                      fontSize: "14px",
                      fontWeight: 500,
                    }}
                  >
                    Black
                  </Typography>
                </Box>
                <Box sx={{ width: "20px" }}>
                  {talentDetails?.skinz === 3 && (
                    <CheckCircle
                      sx={{
                        fontSize: 25,
                      }}
                      color="checkColor"
                    />
                  )}
                </Box>
              </Box>
            </Box>
          </Box>

          <Box
            sx={{
              width: "50%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <Typography sx={{ marginBottom: "4px" }}>
              {i18n["myProfile.yourSetupOpt"]}
            </Typography>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "flex-end",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Box
                    component={"img"}
                    src={own_vehicle}
                    sx={{
                      height: "70px",
                      width: "70px",
                    }}
                  />
                  <Typography
                    sx={{
                      color: "black",
                      fontSize: "14px",
                      fontWeight: 500,
                    }}
                  >
                    Own transport
                  </Typography>
                </Box>
                <Box sx={{ width: "50px", position: "relative", top: "9px" }}>
                  <ToggleSwitch
                    color="blueButton700"
                    onChange={(event) =>
                      handleChangeSetup(event, "own_transport")
                    }
                    checked={talentDetails?.own_transport}
                  />
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "flex-end",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Box
                    component={"img"}
                    src={own_equipment}
                    sx={{
                      height: "70px",
                      width: "70px",
                    }}
                  />
                  <Typography
                    sx={{
                      color: "black",
                      fontSize: "14px",
                      fontWeight: 500,
                    }}
                  >
                    Own equipment
                  </Typography>
                </Box>
                <Box sx={{ width: "50px", position: "relative", top: "9px" }}>
                  <ToggleSwitch
                    color="blueButton700"
                    onChange={(event) =>
                      handleChangeSetup(event, "own_equipment")
                    }
                    checked={talentDetails?.own_equipment}
                  />
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "flex-end",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Box
                    component={"img"}
                    src={own_internet}
                    sx={{
                      height: "70px",
                      width: "70px",
                    }}
                  />
                  <Typography
                    sx={{
                      color: "black",
                      fontSize: "14px",
                      fontWeight: 500,
                    }}
                  >
                    Own internet
                  </Typography>
                </Box>
                <Box sx={{ width: "50px", position: "relative", top: "9px" }}>
                  <ToggleSwitch
                    color="blueButton700"
                    onChange={(event) =>
                      handleChangeSetup(event, "internet_access")
                    }
                    checked={talentDetails?.internet_access}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      <PinConfirmation
        show={openPin}
        handleClose={() => {
          setOpenPin(false);
          setConfirmPin(false);
        }}
        handleDelete={handlePinTalent}
        confirmPin={confirmPin}
        setConfirmPin={setConfirmPin}
        dialogText={`candidate ${talentDetails?.first_name} ${talentDetails?.last_name}`}
      />
    </>
  );
}
