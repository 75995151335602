import React, { useCallback, useEffect, useState } from "react";
import {
  Box,
  InputBase,
  Paper,
  Typography,
  useTheme,
  Button,
} from "@mui/material";
import SmallButtonTalent from "../../../common/SmallButtonTalent";
import SelectMenu from "../../../common/SelectMenu";
import { useDispatch } from "react-redux";
import { setAlert, setLoading } from "../../../../redux/configSlice";
import {
  ALERT_TYPE,
  CANDIDATE_STATUS,
  ERROR_MSG,
  STAGE_ARRAY,
} from "../../../../utils/Constants";
import {
  getAdminTalentJobList,
  getTalentDatabases,
  getTalentPool,
  getTalentPoolForSearch,
} from "../../../../redux/admin/jobsSlice";
import jwt_decode from "jwt-decode";
import upClose from "../../../../assets/Black_Up_Close - Copy.svg";
import downClose from "../../../../assets/Black_Down_Open - Copy.svg";
import upCloseWhite from "../../../../assets/Black_Up_Close.svg";
import downCloseWhite from "../../../../assets/Black_Down_Open.svg";
import CustomSelect from "../../../candidate/myCV/CustomSelect";
import { getCompanies } from "../../../../redux/employer/empProfileSlice";
import { useSelector } from "react-redux";
import { debounce, getDecodedToken, getToken } from "../../../../utils/Common";
import { useLocation } from "react-router-dom";

const TalentFilters = ({
  title,
  totlaCount,
  // handleJobRoleChange,
  // handleInputSearch,
  // handleJobStatus,
  // handleAssociatedJob,
  // handleTalentPool,
  // filtersValue,
  // setOpenFilter,
  // openFilter,
  handleDatabases,
  databases,
  getDatabases,
  // handleOnSelectChange,
  // handleOnSelectInputChange,
  // setAssociatedJobs,
  // associatedJobs,
  // setPoolData,
  // poolData,
  setAllTalent,
  getJobList,
  additionalHandleChange
}) => {
  const theme = useTheme();
  const BASIC = {
    lastKey: 0,
    applicantpool: false,
    followerpool: false,
    pool_id: "",
    job_id: "",
    stage: "",
  };
  const dispatch = useDispatch();
  const { companies } = useSelector((state) => state.myProfile);
  const decodedToken = getDecodedToken(getToken());
  const params = useLocation();
  const { leftMenuButton } = useSelector((state) => state.adminSlice);
  const [basicData, setBasicData] = useState(BASIC);
  const [lastKey, setLastKey] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [searchTitle, setSearchTitle] = useState("");
  const [associatedJobs, setAssociatedJobs] = useState([]);
  const [poolData, setPoolData] = useState([]);
  const [openFilter, setOpenFilter] = useState(false);
  const [stageArray, setStageArray] = useState([
    {
      id: 1,
      name: "incomplete",
    },
    {
      id: 2,
      name: "matched",
    },
    {
      id: 3,
      name: "review",
    },
    {
      id: 4,
      name: "considering",
    },
    {
      id: 5,
      name: "shortlist",
    },
    {
      id: 6,
      name: "interview",
    },
    {
      id: 7,
      name: "assessment",
    },
    {
      id: 8,
      name: "offer",
    },
    {
      id: 9,
      name: "hired",
    },
    {
      id: 10,
      name: "rejected",
    },
  ]);
  const [filtersValue, setFiltersValue] = useState({
    quickSearch: "",
    talentStage: "",
    talentPool: "",
    job: "",
    talentStatus: "",
    databases: "",
  });
  // const [openFilter, setOpenFilter] = useState(false);
  const getAllData = async (para) => {
    try {
      switch (para) {
        case "companies":
          await dispatch(getCompanies());
          return;
        case "associatedJobs":
          const { payload: jobsPayload } = await dispatch(
            getAdminTalentJobList({ lastKey: 0 })
          );
          if (jobsPayload?.status === "success") {
            setAssociatedJobs(jobsPayload?.data);
          }
          return;
        case "poolData":
          const { payload: poolPayload } = await dispatch(
            getTalentPoolForSearch({ lastKey: 0 })
          );
          if (poolPayload?.status === "success") {
            setPoolData(poolPayload?.data);
          }
          return;
        default:
          return;
      }
    } catch (error) {
      dispatch(setLoading(false));
      dispatch(
        setAlert({
          show: true,
          type: ALERT_TYPE?.ERROR,
          msg: ERROR_MSG,
        })
      );
    }
  };
  const handleJobRoleChange = async (event) => {
    const {
      target: { value },
      target: { name },
      target: { id },
    } = event;
    const temp = stageArray.find((item) => item.id === value);

    let newBasicData = {
      ...basicData,
      stage: value === "" ? "" : [temp?.name] || "",
    };

    setBasicData(newBasicData);
    if (setAllTalent) {
      setAllTalent([]);
    }

    await getJobList("", filtersValue?.quickSearch, newBasicData);
    setFiltersValue({
      ...filtersValue,
      talentStage: temp?.name,
    });
    setOpenFilter(false);
  };
  const handleInputSearch = async (event) => {
    let newBasicData = {
      ...basicData,
      title: event?.target?.value,
    };
    setBasicData(newBasicData);
    setFiltersValue({
      ...filtersValue,
      quickSearch: event?.target?.value,
    });
    if(title === "All Talent" || title === "All Promoters"){
      debouncedHandleOnTextSearch(event, newBasicData);
    }else{
      additionalHandleChange(event)
    }
  };
  const debouncedHandleOnTextSearch = useCallback(
    debounce(
      async (newValue, newBasicData) => {
        if (!newValue) return; // Early return
        try {
          // let newBasicData = {
          //   ...basicData,
          //   title: newValue?.target?.value,
          // };
          // setBasicData(newBasicData);
          const response = await getJobList(
            0,
            newValue?.target?.value,
            newBasicData
          );
          // setSearchTitle(newValue?.target?.value);
          if (response && response?.payload) {
            const { payload } = response;
            if (payload?.status === "success") {
              if (setAllTalent) {
                setAllTalent(
                  payload?.data?.map((item) => ({
                    currencyname: item?.currencyname,
                    candidate_user_id: item?.candidate_user_id,
                    first_name: item?.first_name,
                    last_name: item?.last_name,
                    job_title: item?.jobTitle,
                    dob: item?.dob,
                    town_name: item?.town_name,
                    country_name: item?.country_name,
                    currency_symbol: item?.currencySymbol,
                    salary_min: item?.salaryMin,
                    salary_max: item?.salaryMax,
                    experience: item?.experienceYearEnd,
                    notice_period: item?.noticePeriod_description,
                    email: item?.email,
                    total_user_count: item?.TotalUserCount,
                    total_user_shortlist: item?.totalusershorlisted,
                    total_user_interview: item?.totaluserinterviewed,
                    employement_type: item?.employment_type,
                    work_setup: item?.work_setup,
                    created_at: item?.created_at,
                    maxInterviewStageName:
                      item?.maxInterviewStageName === null
                        ? "-"
                        : item?.maxInterviewStageName,
                    gender: item?.gender,
                    ...item,
                  }))
                );
              }
            } else {
              dispatch(
                setAlert({
                  show: true,
                  type: ALERT_TYPE?.ERROR,
                  msg: "Error",
                })
              );
            }
          }
          setOpenFilter(false);
        } catch (error) {
          dispatch(
            setAlert({ show: true, type: ALERT_TYPE?.ERROR, msg: ERROR_MSG })
          );
        }
      },
      1000, // Adjust delay as needed
      setIsLoading
    ),
    [dispatch, setAllTalent, setIsLoading]
  );
  const handleAssociatedJob = async (event) => {
    const {
      target: { value },
      target: { name },
      target: { id },
    } = event;

    let newBasicData = {
      ...basicData,
      job_id: value === "" ? "" : [value],
    };
    setBasicData(newBasicData);
    if (setAllTalent) {
      setAllTalent([]);
    }
    await getJobList("", filtersValue?.quickSearch, newBasicData);
    setFiltersValue({
      ...filtersValue,
      job: value,
    });
    setOpenFilter(false);
  };
  const handleTalentPool = async (event) => {
    const {
      target: { value },
      target: { name },
      target: { id },
    } = event;
    let newBasicData = {
      ...basicData,
      pool_id: value === "" ? "" : value,
    };
    setBasicData(newBasicData);
    if (setAllTalent) {
      setAllTalent([]);
    }

    await getJobList("", filtersValue?.quickSearch, newBasicData);
    setFiltersValue({
      ...filtersValue,
      talentPool: value,
    });
    setOpenFilter(false);
  };
  const handleJobStatus = async (event) => {
    const {
      target: { value },
      target: { name },
      target: { id },
    } = event;

    let newBasicData = {
      ...basicData,
      user_status: value === "" ? "" : value,
    };
    setBasicData(newBasicData);
    if (setAllTalent) {
      setAllTalent([]);
    }
    await getJobList("", filtersValue?.quickSearch, newBasicData);
    setFiltersValue({
      ...filtersValue,
      talentStatus: value,
    });
    setOpenFilter(false);
  };
  const handleOnSelectChange = async (ind, newValue, val, inputId) => {
    let newBasicData = {
      ...basicData,
      company_id: newValue !== null && [newValue?.value],
    };
    setBasicData(newBasicData);
    setFiltersValue({
      ...filtersValue,
      databases: newValue,
    });
    if (setAllTalent) {
      setAllTalent([]);
    }
    await getJobList("", filtersValue?.quickSearch, newBasicData);
    setOpenFilter(false);
  };
  const debouncedHandleOnSelectInputChange = useCallback(
    debounce(
      async (newValue, type) => {
        let params = {
          title: newValue || "",
          limit: 200,
        };
        if (newValue) {
          // getlist(params, type);
          try {
            if (type === "company_id") {
              dispatch(getCompanies(params));
            }
          } catch (error) {
            dispatch(
              setAlert({
                show: true,
                type: ALERT_TYPE.ERROR,
                msg: error,
              })
            );
          }
        }
      },
      750,
      setIsLoading
    ),
    []
  );

  const handleOnSelectInputChange = (newValue, inputId) => {
    debouncedHandleOnSelectInputChange(newValue, inputId);
  };
    const allTalenthandleOnSelectChange = async (ind, newValue, val, inputId, name) => {
    if (newValue === null) {
      setAssociatedJobs([]);
      setPoolData([]);
    }
    let filters = {
      ...filtersValue,
      [inputId]: newValue,
    };
    let newBasicData = {
      ...basicData,
      company_id:
        newValue !== null && inputId === "databases"
          ? [newValue?.value]
          : filters?.databases !== "" && filters?.databases !== null
          ? [filters?.databases?.value]
          : "",
      job_id:
        inputId === "job" && newValue !== null
          ? [newValue?.value]
          : filters?.job !== "" && filters?.job !== null
          ? [filters?.job?.value]
          : "",
      pool_id:
        inputId === "talentPool" && newValue !== null
          ? [newValue?.value]
          : filters?.talentPool !== "" && filters?.talentPool !== null
          ? [filters?.talentPool?.value]
          : "",
    };
    setBasicData(newBasicData);
    setFiltersValue({
      ...filtersValue,
      [inputId]: newValue,
    });
    setAllTalent([]);
    await getJobList("", filtersValue?.quickSearch, newBasicData);
    setOpenFilter(false);
  };
  const allTalenthandleOnSelectInputChange = (newValue, inputId) => {
    allTalentdebouncedHandleOnSelectInputChange(newValue, inputId);
  };
  const allTalentdebouncedHandleOnSelectInputChange = useCallback(
    debounce(
      async (newValue, type) => {
        let params = {
          title: newValue || "",
          limit: 200,
        };
        if (newValue) {
          try {
            if (type === "databases") {
              dispatch(getCompanies(params));
            }
            if (type === "job") {
              setAssociatedJobs([]);
              const { payload } = await dispatch(
                getAdminTalentJobList({ lastKey: 0, query: newValue })
              );
              if (payload?.status === "success") {
                setAssociatedJobs(payload?.data);
              }
            }
            if (type === "talentPool") {
              setPoolData([]);
              const { payload } = await dispatch(
                getTalentPoolForSearch({ lastKey: 0, query: newValue })
              );
              if (payload?.status === "success") {
                setPoolData(payload.data);
              }
            }
          } catch (error) {
            dispatch(
              setAlert({
                show: true,
                type: ALERT_TYPE.ERROR,
                msg: error,
              })
            );
          }
        }
      },
      750,
      setIsLoading
    ),
    []
  );
  const handleClearBtn = async() =>{
    setBasicData(BASIC)
    setFiltersValue({
      quickSearch: "",
      talentStage: "",
      talentPool: "",
      job: "",
      talentStatus: "",
      databases: "",
    })
    if(additionalHandleChange){
      additionalHandleChange("")
    }
    if(getJobList){
      await getJobList("", "", {
        lastKey: 0,
        applicantpool: false,
        followerpool: false,
        pool_id: "",
        job_id: "",
        stage: "",
      })
    }
    setOpenFilter(false)
  }

  useEffect(() => {
    sessionStorage.setItem(`${title}`, JSON.stringify({ ...basicData }));
    sessionStorage.setItem(`searchTitle`, JSON.stringify(filtersValue?.quickSearch));
  }, [filtersValue,basicData]);
  return (
    <Paper
      sx={{
        // p: "20px",
        borderRadius: "25px",
        mt: "55px",
        position: "absolute",
        left: "110px",
        zIndex: 1000,
        width: `calc(100% - 150px)`,
      }}
    >
    <Box sx={{display:"flex",justifyContent:"space-between"}}>
      <Button
        variant="contained"
        color="talentButton"
        sx={{
          width: "150px",
          height: "45px",
          borderRadius: "25px 0 25px 0",
          padding: "0px !important",
        }}
      >
        {title} ({totlaCount})
      </Button>
      <Button
          variant="contained"
          color="talentButton"
          sx={{
            width: "150px",
            height: "45px",
            borderRadius: "0 25px 0 25px",
            padding: "0px !important",
          
          }}
          onClick={()=>{
            if(Object.keys(filtersValue)?.some((item)=>filtersValue[item] !== "")){
              handleClearBtn()
            }
          }}
        >
          clear all
        </Button>
      </Box>
     
      {!openFilter && (
        <Box sx={{ padding: "12px 14px 20px 14px" }}>
          <Box sx={{ display: "flex" }} gap={3}>
            <Paper
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "40px",
                borderRadius: "10px",
                boxShadow: "none",
                border: `1px solid ${theme.palette.grayBorder}`,
                width: "50%",
              }}
            >
              <InputBase
                id="keyword"
                onChange={(event) => {
                  handleInputSearch(event);
                }}
                value={filtersValue?.quickSearch}
                placeholder={"Enter quick search term..."}
                sx={{
                  ml: 2,
                  mr: 2,
                  width: "100%",
                }}
              />
            </Paper>
          </Box>
        </Box>
      )}

      {openFilter && (
        <>
          <Box sx={{ padding: "12px 14px 20px 14px" }}>
            <Box sx={{ display: "flex" }} gap={3} mt={2}>
              <Paper
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "40px",
                  borderRadius: "10px",
                  boxShadow: "none",
                  border: `1px solid ${theme.palette.grayBorder}`,
                  width: "50%",
                }}
              >
                <InputBase
                  id="keyword"
                  onChange={(event) => {
                    handleInputSearch(event);
                  }}
                  defaultValue={filtersValue?.quickSearch}
                  placeholder={"Enter quick search term..."}
                  sx={{ ml: 2, mr: 2, width: "100%" }}
                />
              </Paper>
              <Box sx={{ width: "50%" }}>
                <SelectMenu
                  name="stage"
                  clear={true}
                  value={filtersValue?.talentStage}
                  onHandleChange={(title === "All Talent" || title === "All Promoters" )?handleJobRoleChange:()=>{}}
                  options={STAGE_ARRAY}
                  placeholder={"Stage"}
                  borderRadius="10px !important"
                />
              </Box>
            </Box>
            <Box sx={{ display: "flex" }} gap={3} mt={2}>
              <Box sx={{ width: "50%" }}>
                <SelectMenu
                  name="status"
                  clear={true}
                  // readOnly={true}
                  value={filtersValue?.talentStatus}
                  onHandleChange={(title === "All Talent" || title === "All Promoters" )?handleJobStatus:()=>{}}
                  options={CANDIDATE_STATUS}
                  placeholder={"Status"}
                  borderRadius="10px !important"
                />
              </Box>
              <Box sx={{ width: "50%" }}>
                <CustomSelect
                  // variant="createSelect"
                  variant="default"
                  onInputChange={title === "All Talent" ?allTalenthandleOnSelectInputChange:()=>{}}
                  onChange={title === "All Talent" ?allTalenthandleOnSelectChange:()=>{}}

                  placeholder={
                    decodedToken?.data?.role_id
                      ? "Select job"
                      : "Associated Jobs"
                  }
                  inputId="job"
                  name="job"
                  value={filtersValue?.job}
                  onMenuOpen={() => {
                    if (associatedJobs?.length < 1 && title === "All Talent") {
                      getAllData("associatedJobs");
                    }
                  }}
                  onMenuClose={() => {}}
                  option={associatedJobs?.map((item) => ({
                    value: item?.job_id,
                    label: item?.title,
                  }))}
                  isLoading={isLoading["job_id"]}
                  customStyle={{
                    menu: (provided) => ({
                      ...provided,
                      zIndex: 9999,
                    }),
                    menuList: (provided) => ({
                      ...provided,
                      "&::-webkit-scrollbar": {
                        width: "6px",
                        height: "6px",
                        borderRadius: "2.5px",
                        backgroundColor: "#efefef",
                      },
                      "&::-webkit-scrollbar-thumb": {
                        borderRadius: "2.5px",
                      },
                    }),
                    option: (
                      styles,
                      { data, isDisabled, isFocused, isSelected }
                    ) => {
                      return {
                        ...styles,
                        color: isSelected ? "#fff" : "black",
                        ":active": {
                          ...styles[":active"],
                        },
                      };
                    },
                    control: (baseStyles) => ({
                      ...baseStyles,
                      boxShadow: "none",
                      borderRadius: "10px",
                      height: `40px`,
                      width: `100%`,
                    }),
                  }}
                />
              </Box>
            </Box>
            <Box
              sx={
                decodedToken.data.role_id === 1 &&
                params?.pathname?.includes("all-talent")
                  ? { display: "flex", flexDirection: "row-reverse" }
                  : { display: "flex" }
              }
              gap={3}
              mt={2}
            >
              <Box sx={{ width: "50%" }}>
                <CustomSelect
                  // variant="createSelect"
                  variant="default"
                  onInputChange={title === "All Talent" ?allTalenthandleOnSelectInputChange:()=>{}}

                  onChange={title === "All Talent" ?allTalenthandleOnSelectChange:()=>{}}

                  placeholder={"Talent pools"}
                  inputId="talentPool"
                  name="talentPool"
                  value={filtersValue?.talentPool}
                  onMenuOpen={() => {
                    if (poolData?.length < 1 && title === "All Talent") {
                      getAllData("poolData");
                    }
                  }}
                  onMenuClose={() => {}}
                  option={poolData?.map((item) => ({
                    value: item?.pool_id,
                    label: item?.title,
                  }))}
                  isLoading={isLoading["job_id"]}
                  customStyle={{
                    menu: (provided) => ({
                      ...provided,
                      zIndex: 9999,
                    }),
                    menuList: (provided) => ({
                      ...provided,
                      "&::-webkit-scrollbar": {
                        width: "6px",
                        height: "6px",
                        borderRadius: "2.5px",
                        backgroundColor: "#efefef",
                      },
                      "&::-webkit-scrollbar-thumb": {
                        borderRadius: "2.5px",
                      },
                    }),
                    option: (
                      styles,
                      { data, isDisabled, isFocused, isSelected }
                    ) => {
                      return {
                        ...styles,
                        color: isSelected ? "#fff" : "black",
                        ":active": {
                          ...styles[":active"],
                        },
                      };
                    },
                    control: (baseStyles) => ({
                      ...baseStyles,
                      boxShadow: "none",
                      borderRadius: "10px",
                      height: `40px`,
                      width: `100%`,
                    }),
                  }}
                />
              </Box>
              <Box sx={{ width: "50%" }}>
                {decodedToken.data.role_id === 1 &&
                  params?.pathname?.includes("all-talent") && (
                    <CustomSelect
                      // variant="createSelect"
                      variant="default"
                      onInputChange={title === "All Talent" ?allTalenthandleOnSelectInputChange:()=>{}}

                      onChange={title === "All Talent" ?allTalenthandleOnSelectChange:()=>{}}
                      placeholder={"Select the name of the company"}
                      inputId="databases"
                      name="databases"
                      value={
                        filtersValue?.databases !== "" &&
                        filtersValue?.databases !== null
                          ? {
                              value: filtersValue?.databases?.value,
                              label: filtersValue?.databases?.label,
                            }
                          : null
                      }
                      onMenuOpen={() => {
                        if (companies?.length < 1) {
                          getAllData("companies");
                        }
                      }}
                      onMenuClose={() => {}}
                      option={companies?.map((item) => ({
                        value: item?.company_id,
                        label: item?.name,
                      }))}
                      isLoading={isLoading["company_id"]}
                      customStyle={{
                        menu: (provided) => ({
                          ...provided,
                          zIndex: 9999,
                        }),
                        menuList: (provided) => ({
                          ...provided,
                          "&::-webkit-scrollbar": {
                            width: "6px",
                            height: "6px",
                            borderRadius: "2.5px",
                            backgroundColor: "#efefef",
                          },
                          "&::-webkit-scrollbar-thumb": {
                            borderRadius: "2.5px",
                          },
                        }),
                        option: (
                          styles,
                          { data, isDisabled, isFocused, isSelected }
                        ) => {
                          return {
                            ...styles,
                            color: isSelected ? "#fff" : "black",
                            ":active": {
                              ...styles[":active"],
                            },
                          };
                        },
                        control: (baseStyles) => ({
                          ...baseStyles,
                          boxShadow: "none",
                          borderRadius: "10px",
                          height: `40px`,
                          width: `100%`,
                        }),
                      }}
                    />
                  )}
              </Box>
            </Box>
          </Box>
        </>
      )}

      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          position: "absolute",
        }}
      >
        <Button
          variant="contained"
          color="talentButton"
          sx={{
            borderRadius: "0px 0px 10px 10px ",
            width: 75,
            height: 20,
          }}
          onClick={() => setOpenFilter((prev) => !prev)}
        >
          <Box
            component={"img"}
            src={openFilter ? upCloseWhite : downCloseWhite}
            sx={{
              height: 30,
              width: 30,
            }}
          />
        </Button>
      </Box>
    </Paper>
  );
};

export default TalentFilters;
