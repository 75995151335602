import { Box, Button, Typography, useTheme } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import ProfileProgressButtonLayout from "./myProfileSection/ProfileProgressButtonLayout";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { LinearProgressWithLabel } from "../../utils/CommonComponent";
import ProgressButtonDropDown from "./ProgressButtonDropDown";
import locale from "../../i18n/locale";
import { getProfilePercentage } from "../../redux/employer/empJobListing";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import downArrow from "../../assets/Black_Down_Open - Copy.svg";
import upArrow from "../../assets/Black_Up_Close - Copy.svg";

export default function EmployerProfileCompletion({
  position = "relative",
  progressButton,
  setProgressButton,
  profileCompletion,
  rightBoxRef,
}) {
  const i18n = locale.en;
  const theme = useTheme();
  const dispatch = useDispatch();
  const boxRef = useRef();

  const profileSteps = useSelector((state) => state.myProfile.profileSteps);
  const profilePercentage = useSelector(
    (state) => state.employerjoblisting.profilePercentage
  );
  // const [profilePercentage, setProfilePercentage] = useState({
  //   myInfo: 0,
  //   companyInfo: 0,
  //   myPlan: 0,
  //   billing: 0,
  // });

  const getProfilePercentageData = async () => {
    const { payload } = await dispatch(getProfilePercentage());
    // setProfilePercentage(payload?.data);
  };

  const getColor = () => {
    const value =
      profilePercentage?.myInfo +
      profilePercentage?.companyInfo +
      profilePercentage?.myPlan +
      profilePercentage?.billing;
    if (value === 0) {
      return "redButton";
    } else if (value === 25) {
      return "peachPayment";
    } else if (value === 50) {
      return "orangeButton";
    } else if (value === 75) {
      return "tymeColor";
    } else if (value === 100) {
      return "greenColor";
    }
  };

  useEffect(() => {
    getProfilePercentageData();
  }, [profileSteps]);

  return (
    <Box
      ref={rightBoxRef}
      sx={{
        // width: { xs: "50%", xl: "30%" },
        width: "100%",
        minWidth: { xs: "350px", xl: "400px" },
        background: "#ffff",
        borderRadius: progressButton ? "25px 0 0 0" : "0 0 25px 25px",
        boxShadow: "0px 8px 10px -2px #00000029",
        position: position,
        right: 0,
        display: "flex",
        flexDirection: "column",
        zIndex: "999",
        boxSizing: "border-box",
      }}
    >
      <Box sx={{ display: "flex", flexDirection: "row" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            flexGrow: 1,
            gap: "5px",
          }}
        >
          <Typography
            sx={{
              fontSize: theme.typography.fontSize.sm,
              fontWeight: theme.typography.Bold,
              padding: "11px 0 0 13px",
            }}
          >
            {i18n["empMyProfile.profileCompletion"]}
          </Typography>
          {/* <Box
            sx={{
              paddingRight: "32px",
              width: "100%",
            }}
          >
            <LinearProgressWithLabel
              value={
                profilePercentage?.myInfo +
                profilePercentage?.companyInfo +
                profilePercentage?.myPlan +
                profilePercentage?.billing
              }
              fontSize={"12px"}
              fontWeight={theme.typography.Regular}
            />
          </Box>*/}
        </Box>
        <Button
          variant="contained"
          color={getColor()}
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            padding: "11px 24px 15px 24px",
            gap: "7px",
            minWidth: "60px !important",
            maxWidth: "60px !important",
            height: "45px",
            borderRadius: "0 0 0 10px",
            boxShadow: "none !important",
            zIndex: "999",
            fontSize: theme.typography.fontSize.xs,
            fontWeight: theme.typography.Bold,
          }}
          onClick={() => setProgressButton((prev) => !prev)}
        >
          {`${
            profilePercentage?.myInfo +
            profilePercentage?.companyInfo +
            profilePercentage?.myPlan +
            profilePercentage?.billing
          }%`}
        </Button>
        <Button
          variant="contained"
          color="lightGreyColor"
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            padding: "11px 24px 15px 24px",
            gap: "7px",
            width: { xs: "60px", tb: "65px" },
            height: "45px",
            borderRadius: "0",
            boxShadow: "none !important",
            zIndex: "999",
          }}
          onClick={() => setProgressButton((prev) => !prev)}
        >
          <ProfileProgressButtonLayout
            profileCompletion={
              profilePercentage?.myInfo +
              profilePercentage?.companyInfo +
              profilePercentage?.myPlan +
              profilePercentage?.billing
            }
          />

          <Box
            component={"img"}
            src={progressButton ? upArrow : downArrow}
            sx={{
              width: "30px",
              height: "30px",
              position: "absolute",
              top: "13px",
            }}
          />
        </Button>
      </Box>
      <Typography
        sx={{
          fontSize: theme.typography.fontSize.xs,
          fontWeight: theme.typography.Regular,
          padding: "13px 28px 0 22px",
        }}
      >
        Jobs can only be posted at 50%, and unlocked at 100%.
      </Typography>
      {progressButton && (
        <Box
          ref={boxRef}
          sx={{
            backgroundColor: "white",
            position: "absolute",
            top: "70px",
            right: 0,
            width: "100%",
            boxShadow: "0px 5px 10px #00000029",
            zIndex: "999",
            borderRadius: "0 0 25px 25px",
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
          }}
        >
          <ProgressButtonDropDown
            profilePercentage={profilePercentage}
            setProgressButton={setProgressButton}
          />
        </Box>
      )}
    </Box>
  );
}
