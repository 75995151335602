import { useTheme } from "@mui/material/styles";

import { Paper, InputBase } from "@mui/material";
import { Modal } from "@mui/material";
import React, { useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import CustomDialog from "./CustomDialog";
import { ToggleSwitch } from "../../utils/CommonComponent";

const DeleteConfirmationModal = ({
  handleOpen,
  handleClose,
  handleLinkedPoolSave,
  handleLinkedPoolCancel,
  talentName,
  confirmDelete,
  setConfirmDelete,
  candidateFirstName,
  candidateLastName,
}) => {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    borderRadius: "12px",
    p: 4,
    boxShadow: "none !important",
    border: "1px solid black !important",
  };

  const handleDeleteConfirm = (event) => {
    setConfirmDelete(event.target.checked);
  };

  return (
    <CustomDialog
      show={handleOpen}
      hideButton={false}
      dialogWidth="xs"
      showFooter={false}
      onDialogClose={handleClose}
      padding={0}
    >
      <Box
        sx={{
          padding: 4,
        }}
      >
        <Typography
          variant="h5"
          fontWeight={"Bold"}
          textAlign={"center"}
          sx={{
            marginBottom: 3,
          }}
        >
          Sure you want to delete?
        </Typography>
        <Typography
          fontSize={16}
          fontWeight={"bold"}
          textAlign={"center"}
          paragraph
        >
          {`Please confirm that you want to delete the candidate '${candidateFirstName} ${candidateLastName}' from the '${talentName}' Talent Pool?`}
        </Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <ToggleSwitch
            color={"greenSwitch"}
            checked={confirmDelete}
            onChange={(event) => handleDeleteConfirm(event)}
          />
        </Box>
      </Box>
      <Box>
        <Button
          sx={{
            boxShadow: 0,
            fontSize: "14px",
            width: "50%",
            borderRadius: 0,
            height: "43px",
            background: "lightgray",
            color: "black",
            padding: 3,
          }}
          variant="contained"
          color="grayButton300"
          onClick={handleLinkedPoolCancel}
        >
          cancel
        </Button>
        <Button
          sx={{
            boxShadow: 0,
            fontSize: "14px",
            width: "50%",
            borderRadius: 0,
            height: "43px",
            padding: 3,
          }}
          onClick={handleLinkedPoolSave}
          variant="contained"
          color="redButton100"
          disabled={!confirmDelete}
        >
          continue
        </Button>
      </Box>
    </CustomDialog>
  );
};

export default DeleteConfirmationModal;
