import React, { useEffect, useState, useRef, useCallback } from "react";
import HomeNew from "./HomeNew";
import HireTalent from "./HireTalent";
import FindWork from "./FindWork";
import Recruit from "./Recruit";
import {
  Box,
  Button,
  Paper,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";

import profile from "../assets/Crayon_Favicon.svg";
import EarnCash from "./EarnCash";
import useViewport from "../components/common/ViewPort";
import LeftArrow from "../components/common/LeftArrow";
import RightArrow from "../components/common/RightArrow";
import { useDispatch } from "react-redux";
import { getCompanyList } from "../redux/login/loginSlice";
import { useNavigate } from "react-router-dom";
import _ from "lodash";
import { useSelector } from "react-redux";
import ReactCardFlip from "react-card-flip";

export default function HomeFlip() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { width } = useViewport();
  const navigate = useNavigate();
  const [isHorizontalFlipped, setisHorizontalFlipped] = useState(false);

  const [componentToFlip, setComponentToFlip] = useState(0);
  const [isRotate, setIsRotate] = useState(false);
  const [mobileView, setMobileView] = useState(true);
  const [leftExpanded, setLeftExpand] = useState(true);
  const [rightExpanded, setRightExpanded] = useState(true);
  const [recentCompanyList, setRecentCompanyList] = useState([]);
  const [popularCompanyList, setPopularCompanyList] = useState([]);
  const [recentPage, setRecentPage] = useState(0);
  const [popularPage, setPopularPage] = useState(0);
  const [loadingRecent, setLoadingRecent] = useState(false);
  const [loadingPopular, setLoadingPopular] = useState(false);
  const [hasMoreRecent, setHasMoreRecent] = useState(true);
  const [hasMorePopular, setHasMorePopular] = useState(true);
  const recentObserver = useRef();
  const popularObserver = useRef();
  const [frontIndex, setFrontIndex] = useState(0);
  const [backIndex, setBackIndex] = useState(1);
  const [toggle, setToggle] = useState(true);
  const topBarCompanyLogoClicked = useSelector(
    (state) => state?.myProfileSli?.topBarCompanyLogoClicked
  );
  useEffect(() => {
    // setComponentToFlip(0);
    // setFrontIndex(0)
    // setBackIndex(1)
    changeIndex(0)
    setisHorizontalFlipped((prev) => !prev);
  }, [topBarCompanyLogoClicked]);

  useEffect(() => {
    if (width > 1024) {
      setLeftExpand(true);
      setRightExpanded(true);
    } else {
      setLeftExpand(false);
      setRightExpanded(false);
    }
  }, [width]);
  const getCompanyRecent = async (page) => {
    if (loadingRecent || !hasMoreRecent) return;
    setLoadingRecent(true);
    let payload = {
      recent: true,
      page,
    };
    try {
      let response = await dispatch(getCompanyList(payload));
      if (response?.payload?.status === "success") {
        if (response.payload.data.length === 0) {
          setHasMoreRecent(false);
        } else {
          setRecentCompanyList((prevList) => [
            ...prevList,
            ...response?.payload?.data,
          ]);
        }
      }
    } catch (error) {
      console.log(error, "error");
    } finally {
      setLoadingRecent(false);
    }
  };
  const changeIndex = (newValue) => {
    if (newValue < 0 || newValue > 4) {
      console.error("newValue must be between 0 and 4");
      return; 
    }
    if (toggle) {
      setBackIndex(newValue);
    } else {
      setFrontIndex(newValue);
    }
    setToggle(!toggle);
  };
  const getCompanyPopular = async (page) => {
    if (loadingPopular || !hasMorePopular) return;
    setLoadingPopular(true);
    let payload = {
      popular: true,
      page,
    };
    try {
      let response = await dispatch(getCompanyList(payload));
      if (response?.payload?.status === "success") {
        if (response.payload.data.length === 0) {
          setHasMorePopular(false);
        } else {
          setPopularCompanyList((prevList) => [
            ...prevList,
            ...response?.payload?.data,
          ]);
        }
      }
    } catch (error) {
      console.log(error, "error");
    } finally {
      setLoadingPopular(false);
    }
  };

  const handleRecentBtn = (id) => {
    window.open(`/jobs/company/${id?.company_id}`, "_blank");
  };

  const handlePopularBtn = (id) => {
    window.open(`/jobs/company/${id?.company_id}`, "_blank");
  };

  useEffect(() => {
    if (width === 0) {
      setMobileView(true);
    } else {
      if (width > 1024) {
        setMobileView(true);
      } else {
        setMobileView(false);
      }
    }
  }, [width]);

  useEffect(() => {
    getCompanyRecent(recentPage);
  }, [recentPage]);

  useEffect(() => {
    getCompanyPopular(popularPage);
  }, [popularPage]);

  const recentLastItemRef = useRef();
  const popularLastItemRef = useRef();

  const recentLastItemObserver = useCallback(
    (node) => {
      if (recentObserver.current) recentObserver.current.disconnect();
      recentObserver.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMoreRecent) {
          setRecentPage((prevPage) => prevPage + 1);
        }
      });
      if (node) recentObserver.current.observe(node);
    },
    [setRecentPage, hasMoreRecent]
  );

  const popularLastItemObserver = useCallback(
    (node) => {
      if (popularObserver.current) popularObserver.current.disconnect();
      popularObserver.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMorePopular) {
          setPopularPage((prevPage) => prevPage + 1);
        }
      });
      if (node) popularObserver.current.observe(node);
    },
    [setPopularPage, hasMorePopular]
  );

  useEffect(() => {
    if (recentLastItemRef.current) {
      recentLastItemObserver(recentLastItemRef.current);
    }
  }, [recentCompanyList, recentLastItemObserver]);

  useEffect(() => {
    if (popularLastItemRef.current) {
      popularLastItemObserver(popularLastItemRef.current);
    }
  }, [popularCompanyList, popularLastItemObserver]);

  if (width === 0) {
    return null;
  }

  const truncateString = (str, length) => {
    return _.truncate(str, {
      length: length,
      separator: " ",
      omission: "...",
    });
  };
  const renderComponent = (value) => {
    switch (value) {
      case 0:
        return (
          <HomeNew
            setComponentToFlip={changeIndex}
            setIsRotate={setIsRotate}
            isRotate={isRotate}
            mobileView={mobileView}
            setMobileView={setMobileView}
            setisHorizontalFlipped={setisHorizontalFlipped}
          />
        );
      case 1:
        return (
          <HireTalent
            // setComponentToFlip={setComponentToFlip}
            setComponentToFlip={changeIndex}
            setIsRotate={setIsRotate}
            isRotate={isRotate}
            mobileView={mobileView}
            setMobileView={setMobileView}
            setisHorizontalFlipped={setisHorizontalFlipped}
          />
        );
      case 2:
        return (
          <FindWork
            // setComponentToFlip={setComponentToFlip}
            setComponentToFlip={changeIndex}
            setIsRotate={setIsRotate}
            isRotate={isRotate}
            mobileView={mobileView}
            setMobileView={setMobileView}
            setisHorizontalFlipped={setisHorizontalFlipped}
          />
        );
      case 3:
        return (
          <Recruit
            // setComponentToFlip={setComponentToFlip}
            setComponentToFlip={changeIndex}
            setIsRotate={setIsRotate}
            isRotate={isRotate}
            mobileView={mobileView}
            setMobileView={setMobileView}
            setisHorizontalFlipped={setisHorizontalFlipped}
          />
        );
      case 4:
        return (
          <EarnCash
            // setComponentToFlip={setComponentToFlip}
            setComponentToFlip={changeIndex}
            setIsRotate={setIsRotate}
            isRotate={isRotate}
            mobileView={mobileView}
            setMobileView={setMobileView}
            setisHorizontalFlipped={setisHorizontalFlipped}
          />
        );
      default:
        return null;
    }
  };
  return (
    <>
      <Box
        sx={{
          perspective: "10000px",
          height: { xs: "100%", tablet: "calc(100% - 150px)", mdl: "100%" },
          display: "flex",
        }}
      >
        <Box
          item
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: `${width >= 768 && "10px"}`,
            position: `${width < 1024 ? "absolute" : "relative"}`,
            // width: leftExpanded
            //   ? { xs: "150px", mdl: "150px", mlg: "150px", xl: "150px" }
            //   : "30px",
            width: leftExpanded ? "125" : "30px",
            minWidth: leftExpanded ? "125px" : "30px",
            zIndex: "30",
          }}
        >
          <Button
            variant="contained"
            sx={{
              position: "static",
              borderRadius: "0 10px 10px 0",
              minWidth: "30px",
              width: "30px",
              height: "45px",
              padding: "8px 16px 7px",
            }}
            color="activeButton"
            onClick={() => {
              setLeftExpand((prevState) => !prevState);
              setRightExpanded((prevState) => !prevState);
            }}
          >
            {leftExpanded ? <LeftArrow /> : <RightArrow />}
          </Button>
          {leftExpanded && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "10px",
                width: `${width < 768 && "135px"}`,
                minWidth: "125px",
                paddingTop: `${width < 768 && "10px"}`,
                background: `${width > 0 && width < 768 && "#EBECF3"}`,
                boxShadow: `${
                  width > 0 && width < 768 && "0px 5px 10px #00000029"
                }`,
                opacity: `${width < 768 && "1"}`,
                borderRadius: `${width < 768 && "10px"}`,
                paddingBottom: `${width < 768 && "10px"}`,
                overflowY: leftExpanded ? "scroll" : "unset",
                overflowX: "hidden",
                maxHeight: `${width < 1024 ? "84vh" : "100vh"}`,
              }}
              className="filterSec"
            >
              <Paper
                sx={{
                  background: "transparent",
                  marginRight: "1px",
                  boxShadow: 0,
                  // maxWidth: `${width < 768 && "150px"}`,
                  maxWidth: "125px",
                  color: "black",
                }}
              >
                <Button
                  variant="contained"
                  color="activeButton"
                  sx={{
                    fontWeight: theme.typography.Bold,
                    height: "45px",
                    fontSize: theme.typography.fontSize.xs,
                    minWidth: "125px",
                    width: { xs: "100%" },
                    padding: "0px",
                    borderRadius: "0 10px 10px 0",
                    // justifyContent: "flex-start !important",
                    marginBottom: "10px",
                    ":hover": {
                      boxShadow: "none !important",
                    },
                    "& .MuiSvgIcon-root": {
                      fontSize: theme.typography.fontSize.xs,
                    },
                  }}
                  width="100%"
                >
                  latest companies
                </Button>
                {recentCompanyList?.map((item, index) => {
                  const isLastItem = recentCompanyList.length === index + 1;
                  return (
                    <Tooltip
                      disableInteractive
                      title={item?.name}
                      key={item?.id}
                      placement="top-end"
                    >
                      <Box
                        ref={isLastItem ? recentLastItemRef : null}
                        sx={{
                          display: "flex",
                          height: "45px",
                          fontSize: theme.typography.fontSize.xs,
                          fontWeight: theme.typography.Regular,
                          background: "#FFFFFF",
                          lineHeight: "normal",
                          alignItems: "center",
                          minWidth: "125px",
                          width: { xs: "100%" },
                          borderRadius: "0 10px 10px 0",
                          marginBottom: "10px",
                          justifyContent: "flex-start !PORTANT",
                          cursor: "pointer",
                        }}
                        onClick={() => handleRecentBtn(item)}
                      >
                        <Box
                          component={"img"}
                          src={
                            item?.profile_url === "No URL"
                              ? profile
                              : item?.profile_url
                          }
                          sx={{
                            height: "40px",
                            width: "40px",
                            margin: "0px",
                            padding: "0px",
                            borderRadius: "10px",
                            marginLeft: "5px",
                          }}
                        ></Box>
                        <Typography
                          sx={{
                            width: "95px",
                            textAlign: "center",
                            fontSize: theme.typography.fontSize.xs,
                            fontWeight: theme.typography.Regular,
                            wordBreak:"break-word"
                          }}
                        >
                          {truncateString(item?.name, 22)}
                        </Typography>
                      </Box>
                    </Tooltip>
                  );
                })}
              </Paper>
              <style>
                {`
                  .rightfilterSec::-webkit-scrollbar {
                    display:none;
                  }
                `}
              </style>
            </Box>
          )}
        </Box>
        <Box sx={{ position: "relative", width: "100%", height: "100%" }}>
          <ReactCardFlip
            isFlipped={isHorizontalFlipped}
            flipDirection={"horizontal"}
            flipSpeedBackToFront=".5"
            flipSpeedFrontToBack=".5"
            key={1}
          >
            {renderComponent(frontIndex)}  
            {renderComponent(backIndex)}     
          </ReactCardFlip>
        </Box>

        {/* <Box
          sx={{
            position: "relative",
            width: "100%",
            height: "100%",
            transition:
              "all 0.5s linear(0 0%, 0 1.8%, 0.01 3.6%, 0.03 6.35%, 0.07 9.1%, 0.13 11.4%, 0.19 13.4%, 0.27 15%, 0.34 16.1%, 0.54 18.35%, 0.66 20.6%, 0.72 22.4%, 0.77 24.6%, 0.81 27.3%, 0.85 30.4%, 0.88 35.1%, 0.92 40.6%, 0.94 47.2%, 0.96 55%, 0.98 64%, 0.99 74.4%, 1 86.4%, 1 100%) 0s",
            transformStyle: "preserve-3d",
            transform: !isRotate ? "rotateY(360deg)" : "rotateY(0deg)",
          }}
        >
          <Box
            sx={{
              position: "absolute",
              width: "100%",
              height: "100%",
              WebkitBackfaceVisibility: "hidden",
              backfaceVisibility: "hidden",
            }}
          >
            {componentToFlip === 0 && (
              renderComponent(0)
            )}
            {componentToFlip === 1 && (
              renderComponent(1)
            )}
            {componentToFlip === 2 && (
              renderComponent(2)
            )}
            {componentToFlip === 3 && (
              renderComponent(3)
            )}
            {componentToFlip === 4 && (
              renderComponent(4)
            )}
          </Box>
        </Box> */}
        <Box sx={{ gap: "10px", display: "flex", flexDirection: "column" }}>
          <Box
            item
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: `${width >= 768 && "10px"}`,
              direction: "rtl",
              position: `${width < 1024 ? "absolute" : "relative"}`,
              zIndex: "30",
              right: "0",
              // width: leftExpanded
              //   ? { xs: "150px", mdl: "150px", mlg: "150px", xl: "150px" }
              //   : "30px",
              width: rightExpanded ? "125" : "30px",
              minWidth: rightExpanded ? "125px" : "30px",
            }}
          >
            <Button
              variant="contained"
              sx={{
                position: "sticky",
                top: 0,
                borderRadius: "10px 0 0 10px",
                minWidth: "30px",
                width: "30px",
                height: "45px",
              }}
              color="activeButton"
              onClick={() => {
                setLeftExpand((prevState) => !prevState);
                setRightExpanded((prevState) => !prevState);
              }}
            >
              {rightExpanded ? <RightArrow /> : <LeftArrow />}
            </Button>
          </Box>
          {rightExpanded && (
            <Box
              item
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: `${width >= 768 && "10px"}`,
                position: `${width < 1024 ? "absolute" : "relative"}`,
                zIndex: "30",
                top: `${width < 1024 && "45px"} `,
                right: "0",
                // width: leftExpanded
                //   ? { xs: "160px", mdl: "160px", mlg: "160px", xl: "160px" }
                //   : "30px",
                width: rightExpanded ? "125px" : "30px",
                overflowY: rightExpanded ? "scroll" : "unset",
                overflowX: "hidden",
                maxHeight: `${width < 1024 ? "86.5vh" : "100vh"} `,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                  width: `${width < 768 && "135px"}`,
                  minWidth: "125px",
                  paddingTop: `${width < 768 && "10px"}`,
                  background: `${width > 0 && width < 768 && "#EBECF3"}`,
                  paddingBottom: `${width > 0 && width < 768 && "10px"}`,
                  boxShadow: `${
                    width > 0 && width < 768 && "0px 5px 10px #00000029"
                  }`,
                  opacity: `${width < 768 && "1"}`,
                  borderRadius: `${width < 768 && "10px"}`,
                }}
                className="rightfilterSec"
              >
                <style>
                  {`.rightfilterSec::-webkit-scrollbar {
                      display:none;
                    }
                    `}
                </style>
                <Paper
                  sx={{
                    background: "transparent",
                    boxShadow: 0,
                    gap: "10px",
                    display: "flex",
                    flexDirection: "column",
                    // maxWidth: `${width < 768 && "150px"}`,
                    maxWidth: "125px",
                    color: "black",
                  }}
                >
                  <Button
                    variant="contained"
                    color="activeButton"
                    sx={{
                      height: "45px",
                      fontSize: theme.typography.fontSize.xs,
                      fontWeight: theme.typography.Bold,
                      minWidth: "125px",
                      width: { xs: "100%" },
                      borderRadius: "10px 0 0 10px",
                      // justifyContent: "flex-start !PORTANT",
                      padding: "0px",
                      ":hover": {
                        boxShadow: "none !PORTANT",
                      },
                      "& .MuiSvgIcon-root": {
                        fontSize: theme.typography.fontSize.xs,
                      },
                    }}
                    width="100%"
                  >
                    popular companies
                  </Button>
                  {popularCompanyList?.map((item, index) => {
                    const isLastItem = popularCompanyList.length === index + 1;
                    return (
                      <Tooltip
                        disableInteractive
                        title={item?.name}
                        key={item?.id}
                        placement="top-end"
                      >
                        <Box
                          ref={isLastItem ? popularLastItemRef : null}
                          sx={{
                            display: "flex",
                            height: "45px",
                            fontSize: theme.typography.fontSize.xs,
                            fontWeight: theme.typography.Regular,
                            background: "#FFFFFF",
                            lineHeight: "normal",
                            alignItems: "center",
                            minWidth: "125px",
                            width: { xs: "100%" },
                            borderRadius: "10px 0 0 10px",
                            cursor: "pointer",
                          }}
                          onClick={() => handlePopularBtn(item)}
                        >
                          <Typography
                            sx={{
                              width: "95px",
                              textAlign: "center",
                              fontSize: theme.typography.fontSize.xs,
                              fontWeight: theme.typography.Regular,
                              wordBreak:"break-word"
                            }}
                          >
                            {truncateString(item?.name, 22)}
                          </Typography>
                          <Box
                            component={"img"}
                            src={
                              item?.profile_url === "No URL"
                                ? profile
                                : item?.profile_url
                            }
                            sx={{
                              height: "40px",
                              width: "40px",
                              margin: "0px",
                              padding: "0px",
                              borderRadius: "10px",
                              marginLeft: "5px",
                              marginRight:"5px"
                            }}
                          ></Box>
                        </Box>
                      </Tooltip>
                    );
                  })}
                </Paper>
              </Box>
            </Box>
          )}
        </Box>
      </Box>
    </>
  );
}
