import moment from "moment";
export const convertDatetimeAgo = (value) => {
  return moment.utc(value).local().startOf("seconds").fromNow(true);
};
export const convertDatetimeWithValue = (value) => {
  if (value) {
    const now = moment();
    const datetime = moment.utc(value).local();
    const diffInDays = now.diff(datetime, "days");
    const result = 30 - diffInDays;
    return Math.max(0, result); // Ensure the result is not negative
  } else {
    return 0;
  }
};

export const convertDatetimeWithValue2 = (value) => {
  const now = moment().startOf("day");
  const datetime = moment.utc(value, "YYYY-MM-DD").startOf("day").local();
  const diffInDays = now.diff(datetime, "days");
  const result = 30 - diffInDays;
  return Math.max(0, result); // Ensure the result is not negative
};

export const convertDateToISO = (dateString) => {
  const parts = dateString.split("/");
  const isoDate = moment(
    `${parts[2]}-${parts[1]}-${parts[0]}`,
    "YYYY-MM-DD"
  ).toISOString();
  return isoDate;
};

export const getDateFromToday = (numDays) => {
  const today = moment();
  const futureDate = today.add(numDays, "days");
  return futureDate.format("DD/MM/YYYY");
};

export const convertDatetimeWithoutAgo = (value) => {
  return moment.utc(value).local().startOf("seconds").fromNow(true);
};
export const dateConverter = (value) => {
  return moment(value).format("YYYY-MM-DD");
};

export const dateConverterMonth = (value) => {
  return moment.utc(value).format("DD MMM YYYY");
};

export const dateMonthConverter = (value) => {
  return moment(value, "DD-MM-YY").format("DD MMM YYYY");
};

export const dateConverterFullMonth = (value) => {
  return moment(value).format("DD MMMM YYYY");
};

export const dateConverterFullMonth2 = (value) => {
  return moment(value, "DD-MM-YYYY").format("DD MMMM YYYY");
};
export const dateConverterFullMonth3 = (value) => {
  return moment(value, "YYYY-MM-DD").format("DD MMMM YYYY");
};
export const monthYear = (value) => {
  return moment(value).format("MMM YYYY");
};

export const yearConverter = (value) => {
  return moment(value).format("YYYY");
};

// export const convertDOB = (value) => {
//   const years = moment().diff(moment(value), "years");

//   if (years < 1) {
//     const months = moment().diff(moment(value), "months");
//     return `${months} months`;
//   } else {
//     return `${years} years`;
//   }
// };

export const convertManageDOB = (value) => {
  return moment().diff(moment(value), "years");
};

export const convertDOB = (value) => {
  const birthDate = moment(value);
  const currentDate = moment();

  const years = currentDate.diff(birthDate, "years");
  const months = currentDate.diff(birthDate, "months");

  const remainingMonths = months - years * 12;

  const yearsOutput =
    years > 0 ? `${years} ${years === 1 ? "year" : "years"}` : "";
  const monthsOutput =
    remainingMonths > 0
      ? `${remainingMonths} ${remainingMonths === 1 ? "month" : "months"}`
      : "";
  return `${yearsOutput} ${monthsOutput}`.trim();
};

// export const convertDOBInYears = (value) => {
//   const birthDate = moment(value);
//   const currentDate = moment();
//   const years = currentDate.diff(birthDate, "years");
//   const yearsOutput =
//     years > 0 ? `${years} ${years === 1 ? "year" : "years"}` : "";
//   return `${yearsOutput}`.trim();
// };

export const convertDOBInYearsAndMonths = (value) => {
  const birthDate = moment(value);
  const currentDate = moment();
  const years = currentDate.diff(birthDate, "years");
  const months = currentDate.diff(birthDate, "months") % 12;

  if (years > 0) {
    const yearsOutput = `${years} ${years === 1 ? "year" : "years"}`;
    return yearsOutput;
  } else {
    return months > 0
      ? `${months} ${months === 1 ? "month" : "months"}`
      : "1 month";
  }
};
export const convertDOBInYears = (value) => {
  const birthDate = moment(value);
  const currentDate = moment();
  const years = currentDate.diff(birthDate, "years");
  const months = currentDate.diff(birthDate, "months") % 12;

  const fractionalYears = months / 12;
  const totalYears = years + fractionalYears;

  return Math.round(totalYears);
};

export const weekConvert = (value) => {
  // if (value === null) {
  //   return;
  // }
  return value?.includes("month")
    ? moment.duration(value?.at(0), "months")?.asDays()
    : moment.duration(value?.at(0), "weeks")?.asDays();
};

export const numberToWords = (num) => {
  if (isNaN(num)) {
    return "Invalid input: Please provide a number.";
  }

  const ones = [
    "",
    "First",
    "Second",
    "Third",
    "Fourth",
    "Fifth",
    "Sixth",
    "Seventh",
    "Eighth",
    "Ninth",
  ];
  const teens = [
    "",
    "Eleventh",
    "Twelfth",
    "Thirteenth",
    "Fourteenth",
    "Fifteenth",
    "Sixteenth",
    "Seventeenth",
    "Eighteenth",
    "Nineteenth",
  ];
  const tens = [
    "",
    "Tenth",
    "Twentieth",
    "Thirtieth",
    "Fortieth",
    "Fiftieth",
    "Sixtieth",
    "Seventieth",
    "Eightieth",
    "Ninetieth",
  ];

  if (num === 0) {
    return "zero";
  }

  const getHundred = (num) => {
    if (num > 99) {
      return ones[Math.floor(num / 100)] + " hundred " + getTen(num % 100);
    } else {
      return getTen(num);
    }
  };

  const getTen = (num) => {
    if (num < 10) {
      return ones[num];
    } else if (num >= 11 && num <= 19) {
      return teens[num - 10];
    } else {
      return (
        tens[Math.floor(num / 10)] + (num % 10 > 0 ? " " + ones[num % 10] : "")
      );
    }
  };
  const isNegative = num < 0;
  num = Math.abs(num);

  const getGroup = (num, i) => {
    if (num === 0) {
      return "";
    }
    return getHundred(num) + " ";
  };

  const groups = [];
  while (num > 0) {
    groups.push(num % 1000);
    num = Math.floor(num / 1000);
  }

  if (groups.length === 0) {
    return "zero";
  }

  const words = [];
  for (let i = 0; i < groups.length; i++) {
    if (groups[i] !== 0) {
      words.unshift(getGroup(groups[i], i));
    }
  }

  const result = (isNegative ? "negative " : "") + words.join(" ");
  return result.trim();
};

export const calculateDaysFromDateToCurrent = (dateString) => {
  const inputDate = moment(dateString, "YYYY-MM-DD");
  const currentDate = moment();
  const daysDifference = currentDate.diff(inputDate, "days");

  return daysDifference;
};

export const daysRemainingToComplete30Days = (inputDate) => {
  const startDate = moment(inputDate).add(1, "days");
  const endDate = startDate.clone().add(30, "days");
  const currentDate = moment();
  const daysRemaining = endDate.diff(currentDate, "days");
  if (daysRemaining < 0) {
    return 0;
  }
  return daysRemaining;
};
