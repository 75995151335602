import { createTheme } from "@mui/material/styles";

import { PostAjobTheme } from "../Theme";

const postAjobRecruitTheme = createTheme({
  ...PostAjobTheme,
  palette: {
    ...PostAjobTheme.palette,
    filterButtons: {
      main: "#009AE0",
      contrastText: "#FFFFFF",
    },
    pageButtons: {
      main: "#FFFFFF",
      contrastText: "#000000",
    },
    activePageButton: {
      main: "#009AE0",
      contrastText: "#FFFFFF",
    },
    activeStepButtons: {
      main: "#009AE0",
      contrastText: "#FFFFFF",
      contrastCircle: "#FFFFFF",
      iconText: "#009AE0",
      circleColor: "#009AE0",
    },
    stepButtonColor: {
      main: "#009AE0",
      contrastText: "#FFFFFF",
    },
    stepButtons: {
      main: "#FFFFFF",
      contrastText: "#009AE0",
      iconText: "#FFFFFF",
    },
  },
});

export { postAjobRecruitTheme };
