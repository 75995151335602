import { Box, Button, Grid, Slider, Switch, Typography } from "@mui/material";
import React, { useState } from "react";
import TalentSVGButton from "../../../common/TalentSVGButton";
import alert from "../../../../assets/Padding Excluded/Black_Alert_Red.svg";
import styled from "@emotion/styled";
import { alpha } from "@material-ui/core";
import SmallButton from "../../../common/SmallButton";
import locale from "../../../../i18n/locale";
import Slider2 from "../../../common/Slider2";
import { useTheme } from "@emotion/react";
import {
  BASIC_RANGEMARKS,
  BASIC_RANGEMARKS2,
  EXPERIENCE,
} from "../../../../utils/Constants";
import { textValue } from "../../../../utils/Common";

const BlueSwitch = styled(Switch)(({ theme }) => ({
  "& .MuiSwitch-switchBase.Mui-checked": {
    color: theme.palette.blueButton700.main,
    "&:hover": {
      backgroundColor: alpha(
        theme.palette.blueButton700.main,
        theme.palette.action.hoverOpacity
      ),
    },
  },
  "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
    backgroundColor: theme.palette.blueButton700.main,
  },
  "& .MuiSwitch-track": {
    margin: "auto",
    height: "60% !important",
  },
  "& .MuiSwitch-thumb": {
    borderRadius: "6px !important",
  },
  "& .MuiButtonBase-root-MuiSwitch-switchBase": {
    borderRadius: "15% !important",
  },
  "& .MuiButtonBase-root": {
    padding: "9px !important",
  },
}));

const rangeMarks = [
  {
    value: 0,
    label: "0",
  },
  {
    value: 20,
    label: "20k",
  },
  {
    value: 40,
    label: "40k",
  },
  {
    value: 60,
    label: "60k",
  },
  {
    value: 80,
    label: "80k",
  },
  {
    value: 100,
    label: "100k+",
  },
];

const marks = [
  {
    value: 0,
    label: "0",
  },
  {
    value: 20,
    label: "2",
  },
  {
    value: 40,
    label: "4",
  },
  {
    value: 60,
    label: "6",
  },
  {
    value: 80,
    label: "8",
  },
  {
    value: 100,
    label: "10",
  },
];

function rangeValueExperience(value) {
  return `${value / 10} years`;
}

const formatNumber = (num) => {
  if (num < 1000) {
    return num.toString();
  } else if (num < 1000000) {
    return (num / 1000).toFixed(0) + "k";
  } else {
    return (num / 1000000).toFixed(0) + "M";
  }
};

function rangeValueHandler(value) {
  return formatNumber(value * 1000);
}

export default function JobAlert({ talentContent }) {
  const i18n = locale.en;
  const theme = useTheme();
  const [rangeValue, setRangeValue] = useState([
    talentContent?.employment_type === "freelance"
      ? talentContent?.salary_min / 5
      : talentContent?.salary_min / 2000,
    talentContent?.employment_type === "freelance"
      ? talentContent?.salary_max / 5
      : talentContent?.salary_max / 2000,
  ]);
  const [expRange, setExpRange] = useState(talentContent?.experience);
  const industryUsers = talentContent?.industry?.map(
    (item) => item?.industry_name
  );

  const rangeValueHandler2 = (value) => {
    return value * 5;
  };

  const handleRangeSliderChange = (event, newValue) => {
    setRangeValue(newValue);
  };

  const expHandleChange = (event, newValue) => {
    setExpRange(event?.target?.value);
  };

  const handleRangeSlider = (event, newValue) => {
    setRangeValue(newValue);
  };

  const handleRangeSlider2 = (event, newValue) => {
    setRangeValue(newValue);
  };

  return (
    <Grid
      sx={{
        border: `1px solid ${theme.palette.boxBorderColor}`,
        borderRadius: "20px",
        overflow: "hidden",
        width: "100%",
        mt: 4,
        pt: 2,
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          padding: "0 16px",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <TalentSVGButton
            color={"white"}
            source={alert}
            height={24}
            width={18}
            padding={"0 8px 0 0 !important"}
          />
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: 700,
            }}
          >
            {"Alerts"}
          </Typography>
        </Box>

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            padding: "16px",
          }}
        >
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: 700,
              minWidth: "fit-content",
            }}
          >
            {"Toggle all alerts"}
          </Typography>
          <BlueSwitch id="alert_switch" />
        </Box>
      </Box>

      <Box sx={{ display: "flex", mt: 1, padding: "0 16px" }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "flex-start",
            flexDirection: "column",
            height: "160px",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Typography
              sx={{
                fontSize: "12px",
                fontWeight: 700,
                mr: 1,
              }}
            >
              {i18n["allTalent.type"]}:
            </Typography>
            {(talentContent?.employement_type ||
              talentContent?.employment_type) && (
              <SmallButton
                minWidth="10px"
                height={25}
                color="recruitButton"
                borderRadius="5px"
                label={
                  talentContent?.employement_type ||
                  talentContent?.employment_type
                }
              />
            )}
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Typography
              sx={{
                fontSize: "12px",
                fontWeight: 700,
                mr: 1,
              }}
            >
              {i18n["allTalent.region"]}:
            </Typography>
            {talentContent?.country_name && (
              <SmallButton
                textColor={"black"}
                minWidth="10px"
                height={25}
                color="grayButton700"
                borderRadius="5px"
                label={talentContent?.country_name}
              />
            )}
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Typography
              sx={{
                fontSize: "12px",
                fontWeight: 700,
                mr: 1,
              }}
            >
              {i18n["allTalent.currency"]}:
            </Typography>
            {(talentContent?.currency_symbol ||
              talentContent?.currencySymbol) && (
              <SmallButton
                textColor={"black"}
                minWidth="10px"
                height={25}
                color="grayButton700"
                borderRadius="5px"
                label={
                  talentContent?.currency_symbol ||
                  talentContent?.currencySymbol
                }
              />
            )}
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Typography
              sx={{
                fontSize: "12px",
                fontWeight: 700,
                mr: 1,
              }}
            >
              {i18n["allTalent.industry"]}:
            </Typography>
            <Box
              sx={{
                display: "flex",
                width: "100%",
              }}
            >
              <Slider2
                // flexwrap="wrap"
                items={industryUsers || []}
                color="recruitButton"
                // gap={"5px"}
                hideTagsAfter={3}
              />
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Typography
              sx={{
                fontSize: "12px",
                fontWeight: 700,
                mr: 1,
                width: "75px",
              }}
            >
              {"Highest Qualification"}:
            </Typography>
            {talentContent?.highestQual?.description && (
              <SmallButton
                minWidth="10px"
                height={25}
                color="recruitButton"
                borderRadius="5px"
                label={talentContent?.highestQual?.description}
              />
            )}
          </Box>
        </Box>
      </Box>

      <Box sx={{ mt: 2, padding: "0 16px" }}>
        <Typography
          sx={{
            fontSize: "12px",
            fontWeight: 700,
            mr: 1,
          }}
        >
          {"Monthly salary range"}:
        </Typography>
        <Slider
          disableSwap
          name="salary"
          getAriaLabel={() => "Temperature range"}
          value={rangeValue}
          onChange={
            talentContent?.employment_type === "freelance"
              ? handleRangeSlider2
              : handleRangeSlider
          }
          color="redButton100"
          valueLabelDisplay="on"
          valueLabelFormat={
            talentContent?.employment_type === "freelance"
              ? rangeValueHandler2
              : rangeValueHandler
          }
          getAriaValueText={
            talentContent?.employment_type === "freelance"
              ? rangeValueHandler2
              : rangeValueHandler
          }
          marks={rangeMarks}
          sx={{
            marginTop: 4,
            width: "90%",
            ml: 3,
            "& .MuiSlider-rail": {
              backgroundColor: theme.palette.eyeview100.main,
              height: "10px",
            },
            "& .MuiSlider-track": {
              height: "10px",
            },
            "& .MuiSlider-thumb": {
              borderRadius: "15%",
              background: "white",
            },
            "& .MuiSlider-valueLabel": {
              fontSize: 12,
              fontWeight: "700",
              top: -6,
              backgroundColor: theme.palette.grayBackground,
              borderRadius: 1,
              color: theme.palette.text.primary,
              "&:before": {
                display: "none",
              },
              "& *": {
                background: "transparent",
                color: theme.palette.mode === "dark" ? "#fff" : "#000",
              },
            },
          }}
        />
      </Box>

      <Box sx={{ mt: 2, padding: "0 16px" }}>
        <Typography
          sx={{
            fontSize: "12px",
            fontWeight: 700,
            mr: 1,
          }}
        >
          {"Year of experience"}:
        </Typography>
        <Slider
          name="experience_id"
          aria-label="Custom marks"
          value={expRange}
          color="blueButton800"
          getAriaValueText={textValue}
          min={0}
          max={20}
          step={1}
          onChange={expHandleChange}
          valueLabelDisplay="on"
          valueLabelFormat={textValue}
          marks={EXPERIENCE}
          sx={{
            marginTop: 4,
            width: "90%",
            ml: 3,
            "& .MuiSlider-rail": {
              backgroundColor: theme.palette.eyeview100.main,
              height: "10px",
            },
            "& .MuiSlider-track": {
              height: "10px",
            },
            "& .MuiSlider-thumb": {
              borderRadius: "15%",
              background: "white",
            },
            "& .MuiSlider-valueLabel": {
              fontSize: 12,
              fontWeight: "normal",
              top: -6,
              backgroundColor: theme.palette.grayBackground,
              borderRadius: 1,
              color: theme.palette.text.primary,
              "&:before": {
                display: "none",
              },
              "& *": {
                background: "transparent",
                color: theme.palette.mode === "dark" ? "#fff" : "#000",
              },
            },
          }}
        />
      </Box>

      <Grid
        container
        alignItems="center"
        overflow={"hidden"}
        sx={{
          width: "100%",
          height: 51,
          mt: 2,
        }}
      >
        <Button
          variant="contained"
          id="broad"
          sx={{
            borderRadius: 0,
            width: "100%",
            height: "100%",
            fontSize: "14px",
          }}
          color="grayButton200"
        >
          Update
        </Button>
      </Grid>
    </Grid>
  );
}
