import { useEffect, useState } from "react";
import MyJobCardFront from "./MyJobCardFront";
import MyJobCardBack from "./MyJobCardBack";

import ReactCardFlip from "react-card-flip";
import { useDispatch } from "react-redux";
import ReviewVideoDialog from "../../common/ReviewVideoDialog";
import { setAlert } from "../../../redux/configSlice";
import { ALERT_TYPE } from "../../../utils/Constants";
import { getVideoLink } from "../../../redux/guest/jobsSlice";
import CandidateExternalLinkBox from "./CandidateExternalLinkBox";
import locale from "../../../i18n/locale";

export default function JobCard({
  index,
  job,
  getJobs,
  statusChanged,
  setIsExpanded,
  cardWidth,
  getmyStatus,
  jobslength,
  currentPageJobsCount,
  setCandidateJobs
}) {
  const i18n = locale.en;
  const [isHorizontalFlipped, setisHorizontalFlipped] = useState(false);

  const [openReviewVideoDialog, setOpenReviewVideoDialog] = useState(false);

  const [videoLink, setVideoLink] = useState("");
  const [openExternal, setOpenExternal] = useState(false);
  const [noExternal, setNoExternal] = useState(false);
  const [selectedJob, setSelectedJob] = useState();
  const [allVideoData, setAllVideoData]= useState("")

  const handleCloseDialog = () => {
    setVideoLink("");
    setOpenReviewVideoDialog(false);
  };
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchVideoLink = async () => {
      try {
        const dataLink = await dispatch(getVideoLink({ job_id: job?.job_id }));
        setAllVideoData(dataLink?.payload?.data)
        setVideoLink(dataLink?.payload?.data?.job_video_url);
      } catch (error) {
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE?.ERROR,
            msg: error,
          })
        );
      }
    };

    if (openReviewVideoDialog) {
      fetchVideoLink();
    }
  }, [dispatch, job?.job_id, openReviewVideoDialog]);
  return (
    <>
      <ReactCardFlip
        isFlipped={isHorizontalFlipped}
        flipDirection={"horizontal"}
        flipSpeedBackToFront="0.5"
        flipSpeedFrontToBack="0.5"
        containerStyle={{
          height: "100%",
        }}
        key={job?.job_id}
      >
        <MyJobCardFront
          cardWidth={cardWidth}
          index={index}
          job={job}
          getJobs={getJobs}
          setisFlipped={setisHorizontalFlipped}
          setIsExpanded={setIsExpanded}
          statusChanged={statusChanged}
          handleReviewVideoDialog={setOpenReviewVideoDialog}
          getmyStatus={getmyStatus}
          jobslength={jobslength}
          indexNumber={index}
          currentPageJobsCount={currentPageJobsCount}
          setOpenExternal={setOpenExternal}
          setNoExternal={setNoExternal}
          setSelectedJob={setSelectedJob}
          setCandidateJobs={setCandidateJobs}
        />
        <MyJobCardBack
          cardWidth={cardWidth}
          index={index}
          job={job}
          getJobs={getJobs}
          setisFlipped={setisHorizontalFlipped}
          setIsExpanded={setIsExpanded}
          handleReviewVideoDialog={setOpenReviewVideoDialog}
          getmyStatus={getmyStatus}
          isHorizontalFlipped={isHorizontalFlipped}
          indexNumber={index}
          jobslength={jobslength}
          currentPageJobsCount={currentPageJobsCount}
          setOpenExternal={setOpenExternal}
          setNoExternal={setNoExternal}
          setSelectedJob={setSelectedJob}
          setCandidateJobs={setCandidateJobs}
        />
      </ReactCardFlip>
      {openReviewVideoDialog && (
        <ReviewVideoDialog
          videoLink={videoLink}
          open={openReviewVideoDialog}
          setOpen={handleCloseDialog}
          allVideoData={allVideoData}
        />
      )}
      {openExternal && (
        <CandidateExternalLinkBox
          open={openExternal}
          handleClose={() => setOpenExternal(false)}
          selectedJob={selectedJob}
          height={"454px"}
          text1={i18n["linkitUp.yourapplicationissafe"]}
          text1Margin={"19px 0 16px 0"}
          text2={i18n["linkitUp.jobhasanexternal"]}
          text2Margin={"0 0 16px 0"}
          text3={i18n["linkitUp.applicationviaCrayon"]}
          text3Width={"310px"}
          text3Margin={"0 0 19px 0"}
          buttonText={i18n["linkitUp.visitexternallink"]}
          buttonClick={() => window.open(selectedJob?.external_link)}
        />
      )}
      {noExternal && (
        <CandidateExternalLinkBox
          open={noExternal}
          handleClose={() => setNoExternal(false)}
          selectedJob={selectedJob}
          height={"270px"}
          text1={i18n["linkitUp.yourapplicationissafewith"]}
          text1Margin={"19px 0 0 0"}
          text3={i18n["linkitUp.jobdoesnothaveanexternallink"]}
          text3Margin={"19px 0 19px 0"}
          text3Width={"218px"}
          buttonText={i18n["linkitUp.close"]}
          buttonClick={() => setNoExternal(false)}
        />
      )}
    </>
  );
}
