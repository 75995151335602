import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getApi, postApi } from "../../utils/Apis";
import { setLoading } from "../configSlice";

const initialState = {
  titles: [],
  skills: [],
  tools: [],
  initialTools: [],
  initialSkills: [],
  initialQualifications: [],
  initialTitle: [],
  initialTown: [],
  workExperience: [],
  qualifications: [],
  requiredQua: [],
  currency: [],
  country: [],
  countries: [],
  town: [],
  roleTypes: [],
  workSetup: [],
  salary: [],
  personalities: [],
  traits: [],
  contractDetails: [],
};

export const getJob = createAsyncThunk(
  "getJob",
  async (payload, { dispatch }) => {
    const endPoint = "/job/get?job_id=" + payload;
    const { data } = await getApi(endPoint, true);
    return data;
  }
);

export const getTitles = createAsyncThunk(
  "getTitles",
  async (payload, { dispatch }) => {
    if (payload?.setState)
      payload?.setState((prevState) => ({
        ...prevState,
        [payload?.type]: true,
      }));
    const { data } = await getApi(
      `${
        payload?.title
          ? `/job/titles?title=${payload?.title}&limit=${payload?.limit}`
          : `/job/titles?limit=200`
      }`,
      true
    );
    // const { data } = await getApi("/job/titles", true);
    if (payload?.setState)
      payload?.setState((prevState) => ({
        ...prevState,
        [payload?.type]: false,
      }));
    return { data, initial: payload?.title ? false : true };
  }
);

export const getSkills = createAsyncThunk(
  "getSkills",
  async (payload, { dispatch }) => {
    if (payload?.setState)
      payload?.setState((prevState) => ({
        ...prevState,
        [payload?.type]: true,
      }));
    const { data } = await getApi(
      `${
        payload?.title
          ? `/skills?title=${payload?.title}&limit=${payload?.limit}`
          : "/skills"
      }`,
      true
    );
    if (payload?.setState)
      payload?.setState((prevState) => ({
        ...prevState,
        [payload?.type]: false,
      }));
    // const { data } = await getApi("/skills", true);
    return { data, initial: payload?.title ? false : true };
  }
);

export const getWorkExperience = createAsyncThunk(
  "getWorkExperience",
  async (payload, { dispatch }) => {
    const { data } = await getApi("/experiences", true);
    return data;
  }
);

export const getQualification = createAsyncThunk(
  "getQualification",
  async (payload, { dispatch }) => {
    if (payload?.setState)
      payload?.setState((prevState) => ({
        ...prevState,
        [payload?.type]: true,
      }));
    const { data } = await getApi(
      `${
        payload?.title
          ? `/qualifications?title=${payload?.title}&limit=${payload?.limit}`
          : "/qualifications"
      }`,
      true
    );
    if (payload?.setState)
      payload?.setState((prevState) => ({
        ...prevState,
        [payload?.type]: false,
      }));
    return { data, initial: payload?.title ? false : true };
  }
);

export const getRequiredQualification = createAsyncThunk(
  "getRequiredQualification",
  async (payload, { dispatch }) => {
    const { data } = await getApi("/highestQual", true);
    return data;
  }
);

export const getCurrency = createAsyncThunk(
  "getCurrency",
  async (payload, { dispatch }) => {
    const { data } = await getApi("/currencies", true);
    return data;
  }
);

export const getRoleTypes = createAsyncThunk(
  "getRoleTypes",
  async (payload, { dispatch }) => {
    const { data } = await getApi("/job/roletypes", true);
    return data;
  }
);

export const getWorkSetup = createAsyncThunk(
  "getWorkSetup",
  async (payload, { dispatch }) => {
    const { data } = await getApi("/job/worktypes", true);
    return data;
  }
);

export const getCountry = createAsyncThunk(
  "getCountry",
  async (payload, { dispatch }) => {
    const { data } = await getApi("/countries", true);
    return data;
  }
);

export const getCountries = createAsyncThunk(
  "getCountries",
  async (payload, { dispatch }) => {
    const { data } = await getApi("/countries", true);
    return data;
  }
);

export const getTown = createAsyncThunk(
  "getTown",
  async (payload, { dispatch }) => {
    if (payload?.setState)
      payload?.setState((prevState) => ({
        ...prevState,
        [payload?.type]: true,
      }));
    // const { data } = await getApi("/towns?country_id=" + payload, true);
    const { data } = await getApi(
      payload?.title
        ? `/towns?country_id=${payload?.id}&title=${payload?.title}&limit=${payload?.limit}`
        : payload?.payload
        ? `/towns?country_id=${payload?.payload}`
        : `/towns?country_id=${payload}`,
      true
    );
    if (payload?.setState)
      payload?.setState((prevState) => ({
        ...prevState,
        [payload?.type]: false,
      }));
    return { data, initial: payload?.title ? false : true };
  }
);

export const getSalary = createAsyncThunk(
  "getSalary",
  async (payload, { dispatch }) => {
    const { data } = await getApi("/salaries?currency_id=" + payload, true);
    return data;
  }
);

export const getPersonalities = createAsyncThunk(
  "getPersonalities",
  async (payload, { dispatch }) => {
    const { data } = await getApi("/personalities", true);
    return data;
  }
);

export const getTraits = createAsyncThunk(
  "getTraits",
  async (payload, { dispatch }) => {
    const { data } = await getApi("/job/trait", true);
    return data;
  }
);

export const getContractDetails = createAsyncThunk(
  "getContractDetails",
  async (payload, { dispatch }) => {
    const { data } = await getApi("/job/getContractDuration", true);
    return data;
  }
);

export const getTools = createAsyncThunk(
  "getTools",
  async (payload, { dispatch }) => {
    if (payload?.setState)
      payload?.setState((prevState) => ({
        ...prevState,
        [payload?.type]: true,
      }));
    const { data } = await getApi(
      `${
        payload?.title
          ? `/tools?title=${payload?.title}&limit=${payload?.limit}`
          : `/tools?limit=200`
      }`,
      true
    );
    if (payload?.setState)
      payload?.setState((prevState) => ({
        ...prevState,
        [payload?.type]: false,
      }));
    return { data, initial: payload?.title ? false : true };
  }
);

export const getEmployerCompanyList = createAsyncThunk(
  "getEmployerCompanyList",
  async (payload, { dispatch }) => {
    const { data } = await getApi("/job/getEmployerComapny", true);
    return data;
  }
);

export const getBasicData = createAsyncThunk(
  "getBasicData",
  async (payload, { dispatch }) => {
    const { data } = await getApi("/job/getJobBasics?job_id=" + payload, true);
    return data;
  }
);

export const addBasicData = createAsyncThunk(
  "addBasicData",
  async (payload, { dispatch }) => {
    dispatch(setLoading(true));
    const { data } = await postApi("/job/create/basics", payload, true);
    dispatch(setLoading(false));
    return data;
  }
);

export const getSuccessFee = createAsyncThunk(
  "getSuccessFee",
  async ({ is_interview_require, is_video_require }, { dispatch }) => {
    const { data } = await getApi(
      `/job/successfees?is_video_require=${is_video_require}&is_interview_require=${is_interview_require}`,
      true
    );
    return data;
  }
);

export const getDetailData = createAsyncThunk(
  "getDetailData",
  async (payload, { dispatch }) => {
    const { data } = await getApi(
      "/job/getJobTheDetails?job_id=" + payload,
      true
    );
    return data;
  }
);

export const addDetailData = createAsyncThunk(
  "addDetailData",
  async (payload, { dispatch }) => {
    dispatch(setLoading(true));
    const { data } = await postApi("/job/create/details", payload, true);
    dispatch(setLoading(false));
    return data;
  }
);

export const uploadSpecData = createAsyncThunk(
  "uploadSpecData",
  async (payload, { dispatch }) => {
    dispatch(setLoading(true));
    const { data } = await postApi(
      "/upload/jobspec",
      payload,
      true,
      "multipart/form-data"
    );
    dispatch(setLoading(false));
    return data;
  }
);

export const getBountyInfo = createAsyncThunk(
  "getBountyInfo",
  async (payload, { dispatch }) => {
    dispatch(setLoading(true));
    const { data } = await getApi("/job/bounty?job_id=" + payload, true);
    dispatch(setLoading(false));
    return data;
  }
);

export const saveBountyDetail = createAsyncThunk(
  "saveBountyDetail",
  async (payload, { dispatch }) => {
    dispatch(setLoading(true));
    const { data } = await postApi("/job/create/bounty", payload, true);
    dispatch(setLoading(false));
    return data;
  }
);

export const getCultureData = createAsyncThunk(
  "getCultureData",
  async (payload, { dispatch }) => {
    const { data } = await getApi("/job/getJobCulture?job_id=" + payload, true);
    return data;
  }
);

export const deleteEmployerJobVideo = createAsyncThunk(
  "deleteEmployerJobVideo",
  async ({ job_id }, { dispatch }) => {
    dispatch(setLoading(true));
    const { data } = await getApi(
      `/employer/deletejobvideo?job_id=${job_id}`,
      true
    );
    dispatch(setLoading(false));
    return data;
  }
);

export const addCultureData = createAsyncThunk(
  "addCultureData",
  async (payload, { dispatch }) => {
    dispatch(setLoading(true));
    const { data } = await postApi("/job/create/culture", payload, true);
    dispatch(setLoading(false));
    return data;
  }
);

export const uploadPostJobCultureVideo = createAsyncThunk(
  "uploadPostJobCultureVideo",
  async (payload, { dispatch }) => {
    dispatch(setLoading(true));
    // const { data } = await postApi("/upload/jobvideo", payload, true)
    const { data } = await postApi(
      "/upload/jobvideo",
      payload,
      true,
      "multipart/form-data"
    );
    dispatch(setLoading(false));
    return data;
  }
);

export const postJobSlice = createSlice({
  name: "postJob",
  initialState,
  reducers: {
    updateToInitialTools: (state, action) => {
      return { ...state, tools: state.initialTools };
    },
    updateToInitialSkills: (state, action) => {
      return { ...state, skills: state.initialSkills };
    },
    updateToInitialTown: (state, action) => {
      return { ...state, town: state.initialTown };
    },
    updateToInitialTitles: (state, action) => {
      return { ...state, titles: state.initialTitle };
    },
    updateToInitialqualifications: (state, action) => {
      return { ...state, qualifications: state.initialQualifications };
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getTitles.fulfilled, (state, action) => {
        state.initialTitle = action?.payload?.initial
          ? action?.payload?.data?.data?.map((title) => {
              return { ...title, id: title?.job_title_id, name: title?.title };
            })
          : state.initialTitle;
        state.titles = action?.payload?.data?.data?.map((title) => {
          return {
            ...title,
            id: title?.job_title_id,
            name: title?.title,
          };
        });
      })
      .addCase(getSkills?.fulfilled, (state, action) => {
        state.initialSkills = action?.payload?.initial
          ? action?.payload?.data?.data?.map((skill) => {
              return { ...skill, id: skill?.tag_id, name: skill?.tag };
            })
          : state.initialSkills;
        state.skills = action?.payload?.data?.data?.map((skill) => {
          return {
            ...skill,
            id: skill?.tag_id,
            name: skill?.tag,
          };
        });
      })
      .addCase(getTools.fulfilled, (state, action) => {
        state.initialTools = action?.payload?.initial
          ? action?.payload?.data?.data.map((tool) => {
              return {
                ...tool,
                id: tool?.tool_id,
                name: tool?.name,
              };
            })
          : state.initialTools;
        state.tools = action?.payload?.data?.data?.map((tool) => {
          return {
            ...tool,
            id: tool?.tool_id,
            name: tool?.name,
          };
        });
      })
      .addCase(getWorkExperience.fulfilled, (state, action) => {
        state.workExperience = action?.payload?.data?.map((exp) => {
          return {
            ...exp,
            value: exp?.experience_id,
            label: exp?.year,
          };
        });
      })
      .addCase(getQualification.fulfilled, (state, action) => {
        state.initialQualifications = action?.payload?.initial
          ? action?.payload?.data?.data?.map((qua) => {
              return {
                ...qua,
                id: qua?.qualification_id,
                name: qua?.name,
              };
            })
          : state.initialQualifications;
        state.qualifications = action?.payload?.data?.data?.map((qua) => {
          return {
            ...qua,
            id: qua?.qualification_id,
            name: qua?.name,
          };
        });
      })
      .addCase(getRequiredQualification.fulfilled, (state, action) => {
        state.requiredQua = action?.payload?.data?.map((qua) => {
          return {
            ...qua,
            id: qua?.highest_qualification_id,
            name: qua?.description,
          };
        });
      })
      .addCase(getCurrency.fulfilled, (state, action) => {
        state.currency = action?.payload?.data?.map((curr) => {
          return {
            ...curr,
            id: curr?.currency_id,
            name: curr?.currency,
          };
        });
      })
      .addCase(getCountry.fulfilled, (state, action) => {
        state.country = action?.payload?.data?.map((curr) => {
          return {
            ...curr,
            id: curr?.country_id,
            name: curr?.name,
          };
        });
      })
      .addCase(getCountries.fulfilled, (state, action) => {
        state.countries = action?.payload?.data?.map((curr) => {
          return {
            ...curr,
            id: curr?.country_id,
            name: curr?.name,
          };
        });
      })
      .addCase(getTown.fulfilled, (state, action) => {
        state.initialTown = action?.payload?.initial
          ? action?.payload?.data?.data?.map((curr) => {
              return {
                ...curr,
                id: curr?.town_id,
                name: curr?.name,
              };
            })
          : state.initialTown;
        state.town = action?.payload?.data?.data?.map((curr) => {
          return {
            ...curr,
            id: curr?.town_id,
            name: curr?.name,
          };
        });
      })
      .addCase(getWorkSetup.fulfilled, (state, action) => {
        state.workSetup = action.payload.data.map((curr) => {
          return {
            ...curr,
            id: curr.job_work_type_id,
            name: curr.name,
          };
        });
      })
      .addCase(getRoleTypes.fulfilled, (state, action) => {
        state.roleTypes = action.payload.data.map((curr) => {
          return {
            ...curr,
            id: curr.job_role_type_id,
            name: curr.name,
          };
        });
      })
      .addCase(getPersonalities.fulfilled, (state, action) => {
        state.personalities = action.payload.data.map((curr) => {
          return {
            ...curr,
            id: curr.personality_id,
            name: curr.name,
          };
        });
      })
      .addCase(getTraits.fulfilled, (state, action) => {
        state.traits = action.payload.data.map((curr) => {
          return {
            ...curr,
            id: curr.trait_id,
            name: curr.name,
          };
        });
      })
      .addCase(getContractDetails.fulfilled, (state, action) => {
        state.contractDetails = action.payload.data.map((contract) => {
          return {
            ...contract,
            id: contract.id,
            name: contract.name,
          };
        });
      });
  },
});
export const {
  updateToInitialTools,
  updateToInitialSkills,
  updateToInitialTown,
  updateToInitialTitles,
  updateToInitialqualifications,
} = postJobSlice.actions;
// Action creators are generated for each case reducer function
export default postJobSlice.reducer;
