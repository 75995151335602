import { Box, Button, Typography, useTheme } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import Delete from "./Delete";
import { setAlert } from "../../../../redux/configSlice";
import { ALERT_TYPE } from "../../../../utils/Constants";
import { useDispatch } from "react-redux";
// import deleteIcon from "../../../../assets/Padding Excluded/Black_Trash_Delete.svg";
// import myVideoHeaderLogo from "../../../../assets/Padding Excluded/White_myVideo.svg";
// import uploadNewVideoIcon from "../../../../assets/Padding Excluded/Black_Upload.svg";
import { RadioButtonChecked } from "@mui/icons-material";
import BriefBox from "./BriefBox";
import {
  deleteEmployerUploadedVideo,
  uploadEmployerMyCamVideo,
} from "../../../../redux/employer/myJobsSlice";
import PlayButton from "../../../common/PlayButton";
import locale from "../../../../i18n/locale";
import {
  getDecodedToken,
  getToken,
  getVideoFileDuration,
} from "../../../../utils/Common";
import ReviewVideoDialog from "../../ReviewVideoDialog";
import useViewport from "../../ViewPort";
import jobVideoHeaderLogo from "../../../../assets/Cam/JobVideoIcon.svg";
import myVideoHeaderLogo from "../../../../assets/Cam/MyVideoIcon.svg";
import TakeTwoIcon from "../../../../assets/Cam/TakeTwoIcon.svg";
import uploadNewVideoIcon from "../../../../assets/Cam/UploadVideoIcon.svg";
import deleteIcon from "../../../../assets/Cam/DeleteIcon.svg";
const buttonSize = 60;
const triangleSize = 25;
export default function PublishVideo({
  videoData,
  nextStep,
  companyID,
  userData,
  getVideo,
  setActiveStep,
  uploadVideo,
  textChange,
  allData,
  setAllData
}) {
  const i18n = locale.en;
  const theme = useTheme();
  const dispatch = useDispatch();
  const VideoRef = useRef(null);

  const hiddenFileInput = useRef(null);
  const fileAccept = "video/*";
  const { width } = useViewport();

  const [openReviewVideoDialog, setOpenReviewVideoDialog] = useState(false);
  const [showBrief, setShowBrief] = useState(false);
  const [disabledButton, setDisabledButton] = useState(true);
  const [isPlaying, setIsPlaying] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const decodedToken = getDecodedToken(getToken());

  const [videoLink, setVideoLink] = useState(null);
  const handleOpenDelete = () => {
    setOpenDelete((prevState) => !prevState);
    setConfirmDelete(false);
  };
  const handleCancelDelete = () => {
    setOpenDelete((prevState) => !prevState);
    setConfirmDelete(false);
  };

  const handleRecordNewVideo = () => {
    nextStep(2);
  };

  const handleFileChange = async (event) => {
    const file = event?.target?.files?.[0];

    getVideoFileDuration(file, async (error, videoDuration, fileType) => {
      if (error) {
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE?.ERROR,
            msg: error,
          })
        );
        return;
      } else if (
        file?.name?.split(".")?.pop()?.toLowerCase() !== "mp4" &&
        file?.name?.split(".")?.pop()?.toLowerCase() !== "mov"&&
        file?.name?.split(".")?.pop()?.toLowerCase() !== "webm"
      ) {
        setVideoLink("")
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE?.ERROR,
            msg: "Please upload .mp4 or .mov file",
          })
        );
        return;
      } else if (videoDuration > 60) {
        setVideoLink("")
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE?.ERROR,
            msg: "Video length should be less than 1 min",
          })
        );
        return;
      } else if (file?.size > 100000000) {
        setVideoLink("")
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE?.ERROR,
            msg: "Video size should be less than 100MB",
          })
        );
        return;
      }

      const formData = new FormData();
      formData?.append("company-video", event?.target?.files?.[0]);
      formData?.append("company_id", companyID);
      try {
        const { payload } = await dispatch(uploadEmployerMyCamVideo(formData));
        if (payload?.status === "success") {
          dispatch(
            setAlert({
              show: true,
              type: ALERT_TYPE?.SUCCESS,
              msg: "Video uploaded Successfully!",
            })
          );
          // await getVideo()
          nextStep(4);
          setVideoLink(URL.createObjectURL(event?.target?.files?.[0]));
          VideoRef?.current?.load();
          getVideo();
        } else {
          dispatch(
            setAlert({
              show: true,
              type: ALERT_TYPE?.ERROR,
              msg: "Failed to upload Video",
            })
          );
        }
      } catch (error) {
        dispatch(setAlert({ show: true }));
      }
    });
  };
  const handleFileClick = () => {
    // hiddenFileInput?.current?.click();
    uploadVideo();
  };

  const handleCloseBriefBox = () => {
    setShowBrief(false);
  };

  const deleteUploadedVideo = async () => {
    try {
      const { payload } = await dispatch(
        deleteEmployerUploadedVideo({
          company_id: companyID,
        })
      );
      if (payload?.status === "success") {
        nextStep(1);
        setAllData({})
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE?.SUCCESS,
            msg: "Uploded video removed successfully!",
          })
        );
      }
    } catch (error) {
      dispatch(
        setAlert({
          show: true,
          type: ALERT_TYPE?.ERROR,
          msg: error,
        })
      );
    }
  };

  const handleCloseDialog = () => {
    setOpenReviewVideoDialog(false);
  };

  return (
    <>
      <>
        <Button
          variant="contained"
          color="precium"
          sx={{
            borderRadius: "25px 0 10px 0",
            width: "150px",
            height: "45px",
            fontSize: "14px",

            fontWeight: theme.typography.Bold,
            gap: "5px",
            padding: "6px 6px",
          }}
        >
          <Box
            component={"img"}
            src={myVideoHeaderLogo}
            sx={{
              height: "20px",
              width: "24px",
            }}
          />
          company video
        </Button>
        <Box
          sx={{
            width: "70%",
            margin: "auto",
            flexDirection: "column",
            alignItems: "center",
            display: "flex",
            gap: "20px",
            marginTop: "30px",
          }}
        >
          <Typography
            sx={{
              fontSize: "20px",
              fontWeight: theme.typography.Bold,
              textAlign: "center",
            }}
          >
            And the Oscar goes to…
          </Typography>
        </Box>
        <Box
          sx={{
            flexGrow: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "30px",
            paddingTop: "0px",
            paddingBottom: "0px",
            flexDirection: "column",
            position: "relative",
            boxSizing: "border-box",
          }}
        >
          <Box
            sx={{ width: { xs: "100%", mdl: "60%", lg: "60%", lgm: "60%" } }}
          >
            {allData?.linkFlag === true && (
              <div className="video-responsive">
                <iframe
                  style={{ 
                    borderRadius: "25px 25px 0 0",
                  height: "auto",
                  width: "100%",
                  aspectRatio: "4/3",
                  objectFit: "cover",
                  cursor: disabledButton ? "wait" : "pointer",
                  border: disabledButton
                    ? `1px solid ${theme.palette.grayButton600.main}`
                    : "none",
                  borderBottom: "none", // To remove the border for the bottom side
                   }}
                  width="320px"
                  height="240px"
                  s
                  src={`https://www.youtube.com/embed/${allData?.video_url}`}
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  title="Embedded youtube"
                ></iframe>
              </div>
            )}
            {!isPlaying && (allData?.linkFlag === false || Object.keys(allData)?.length ===0 ) && (
              <PlayButton
                buttonSize={buttonSize}
                triangleSize={triangleSize}
                top={"calc(50% - 100px)"}
              />
            )}
            {(allData?.linkFlag === false || Object.keys(allData)?.length ===0 ) && (
              <video
                preload="auto"
                ref={VideoRef}
                loops
                autoPlay={false}
                poster=""
                style={{
                  borderRadius: "25px 25px 0 0",
                  height: "auto",
                  width: "100%",
                  aspectRatio: "4/3",
                  objectFit: "cover",
                  cursor: disabledButton ? "wait" : "pointer",
                  border: disabledButton
                    ? `1px solid ${theme.palette.grayButton600.main}`
                    : "none",
                  borderBottom: "none", // To remove the border for the bottom side
                }}
                muted={false}
                src={videoLink || videoData}
                type="video/mp4"
                onClick={() => {
                  // if (!disabledButton) {
                  //   if (VideoRef?.current?.paused) {
                  //     VideoRef?.current?.play();
                  //     setIsPlaying(true);
                  //   } else {
                  //     VideoRef?.current?.pause();
                  //     setIsPlaying(false);
                  //   }
                  // }
                  setOpenReviewVideoDialog(true);
                }}
                onLoadedData={() => {
                  setDisabledButton(false);
                }}
                onEnded={() => setIsPlaying(false)}
              ></video>
            )}
            <Box
              sx={{
                display: "flex",
                width: { xs: "100%", lgm: "100%" },
                background: theme.palette.mainBackground,
                borderRadius: "0 0 25px 25px",
                height: "60px",
                marginTop: "-6.5px",
              }}
            >
              <Box
                sx={{
                  width: "68%",
                  margin: "auto",
                  padding: "0 0 0 20px ",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "15px",
                    fontWeight: theme.typography.Bold,
                  }}
                >
                  Crayon Cam
                </Typography>
                <Typography
                  sx={{
                    fontSize: "13px",
                    fontWeight: theme.typography.Regular,
                  }}
                >
                  Staring: {userData?.first_name || decodedToken?.data?.fName}{" "}
                  {userData?.last_name || decodedToken?.data?.lName}
                </Typography>
              </Box>
              <Button
                variant="contained"
                color="videoButton"
                sx={{
                  width: "30%",
                  height: "100%",
                  borderRadius: "0 0 25px 0",
                }}
                onClick={() => setShowBrief(true)}
              >
                the brief
              </Button>
            </Box>
            <Box
              sx={{
                width: "100%",
                // margin: "auto",
                flexDirection: "column",
                alignItems: "center",
                display: "flex",
                gap: "20px",
                marginTop: "30px",
                marginBottom: { lg: "30px", lg1440: "0px" },
              }}
            >
              <Typography
                sx={{
                  fontSize: "14px",
                  textAlign: "center",

                  fontWeight: theme.typography.Regular,
                }}
              >
                Your video has been successfully recorded. Hollywood awaits!
              </Typography>
              <Typography
                sx={{
                  fontSize: "14px",
                  textAlign: "center",

                  fontWeight: theme.typography.Regular,
                }}
              >
                {textChange
                  ? "You can re-record or upload a new video below."
                  : "Review your view and then lock it in, or try again if you weren’t happy with the first take."}
              </Typography>
            </Box>
          </Box>
        </Box>
        {showBrief && (
          <BriefBox show={showBrief} handleOpen={handleCloseBriefBox} text2={"Adding your company video increases your chances of attracting top talent and is often the first thing looked at by candidates!"} text1={"Give us your company elevator pitch! Record or upload a short intro video, highlighting the nature of your business, what makes it great to work at, company culture, etc. "} />
        )}
        {/* <Box
          sx={{
            width: "70%",
            margin: "auto",
            flexDirection: "column",
            alignItems: "center",
            display: "flex",
            gap: "20px",
          }}
        >
          <Typography
            sx={{
              fontSize: "20px",
              fontWeight: theme.typography.Bold,
              textAlign: "center",
            }}
          >
            {i18n["empMyCam.oscarGoesTo"]}
          </Typography>
          <Typography
            sx={{
              fontSize: "14px",
              textAlign: "center",

              fontWeight: theme.typography.Regular,
            }}
          >
            {i18n["empMyCam.publishVideoText"]}
          </Typography>
          <Typography
            sx={{
              fontSize: "14px",
              textAlign: "center",

              fontWeight: theme.typography.Regular,
            }}
          >
            {i18n["empMyCam.publishVideoText1"]}
          </Typography>
        </Box> */}
        {/* 
        <Box
          sx={{
            flexGrow: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "30px",
            flexDirection: "column",
            position: "relative",
          }}
        >
          {!isPlaying && <PlayButton />}
          <video
            ref={VideoRef}
            loops
            preload="auto"
            autoPlay={false}
            poster=""
            style={{
              width: disabledButton ? "74.4%" : "75%",
              borderRadius: "25px 25px 0 0",
              height: "auto",
              aspectRatio: "4/3",
              objectFit: "cover",
              cursor: disabledButton ? "wait" : "pointer",
              border: disabledButton
                ? `1px solid ${theme.palette.grayButton600.main}`
                : "none",
              borderBottom: "none", // To remove the border for the bottom side
            }}
            muted={false}
            src={videoLink || videoData}
            type="video/mp4"
            onClick={() => {
              // if (!disabledButton) {
              //   if (VideoRef?.current?.paused) {
              //     VideoRef?.current?.play();
              //     setIsPlaying(true);
              //   } else {
              //     VideoRef?.current?.pause();
              //     setIsPlaying(false);
              //   }
              // }
              setOpenReviewVideoDialog(true);
            }}
            onLoadedData={() => {
              setDisabledButton(false);
            }}
            onEnded={() => setIsPlaying(false)}
          ></video>
          <Box
            sx={{
              display: "flex",
              width: "75%",
              background: theme.palette.mainBackground,
              borderRadius: "0 0 25px 25px",
              height: "60px",
            }}
          >
            <Box
              sx={{
                width: "70%",
                margin: "auto",
                padding: "0 0 0 20px ",
              }}
            >
              <Typography
                sx={{
                  fontSize: "15px",
                  fontWeight: theme.typography.Bold,
                }}
              >
                {i18n["empMyCam.crayonCam"]}
              </Typography>
              <Typography
                sx={{
                  fontSize: "13px",
                  fontWeight: theme.typography.Regular,
                }}
              >
                {i18n["empMyCam.staring"]} {userData?.first_name}{" "}
                {userData?.last_name}
              </Typography>
            </Box>
            <Button
              variant="contained"
              color="videoButton"
              sx={{
                width: "30%",
                height: "100%",
                borderRadius: "0 0 25px 0",
              }}
              onClick={() => setShowBrief(true)}
            >
              {i18n["empMyCam.theBrief"]}
            </Button>
          </Box>
        </Box> */}

        {/* {showBrief && (
          <BriefBox show={showBrief} handleOpen={handleCloseBriefBox} />
        )} */}

        <Box
          sx={{
            margin: "auto",
            width: "100%",
            height: "60px",
          }}
        >
          <Button
            variant="contained"
            color="deleteVideoButton"
            sx={{
              borderRadius: "0 0 0 25px",
              width: "33.33%",
              height: "100%",
              gap: "8px",
            }}
            onClick={handleOpenDelete}
          >
            <Box
              component={"img"}
              src={deleteIcon}
              sx={{
                height: "20px",
                display: { xs: "none", tablet: "block" },
                width: "24px",
              }}
            />
            {i18n["empMyCam.delete"]}
          </Button>
          {textChange ? (
            <Button
              variant="contained"
              color="yoco"
              sx={{
                borderRadius: 0,
                width: "33.33%",
                height: "100%",
                gap: "8px",
              }}
              onClick={() => hiddenFileInput?.current?.click()}
            >
              <Box
                component={"img"}
                src={uploadNewVideoIcon}
                sx={{
                  height: "20px",
                  width: "24px",
                }}
              />
              upload new video
            </Button>
          ) : (
            <Button
              variant="contained"
              color="yoco"
              sx={{
                borderRadius: 0,
                width: "33.33%",
                height: "100%",
                gap: "8px",
              }}
              onClick={handleRecordNewVideo}
            >
              {(width > 767 || width === 0) && (
                <Box
                  component={"img"}
                  src={TakeTwoIcon}
                  sx={{
                    height: "20px",
                    width: "24px",
                  }}
                />
              )}
              {/* {width > 767 && <RadioButtonChecked />} */}
              {/* record new video */}
              take two
            </Button>
          )}
          {/* <Button
            variant="contained"
            color="videoButton"
            sx={{
              borderRadius: 0,
              width: "33.33%",
              height: "100%",
              gap: "8px",
            }}
            onClick={handleRecordNewVideo}
          >
            {(width > 767 || width === 0) && (
                <Box
                  component={"img"}
                  src={jobVideoHeaderLogo}
                  sx={{
                    height: "20px",
                    width: "24px",
                  }}
                />
              )}
            {i18n["empMyCam.recordNewVideo"]}
          </Button> */}
          <input
            accept={fileAccept}
            ref={hiddenFileInput}
            type="file"
            onChange={handleFileChange}
            style={{ display: "none" }}
          />
          {textChange ? (
            <Button
              variant="contained"
              color="nandos"
              sx={{
                borderRadius: "0 0 25px 0",
                width: "33.33%",
                height: "100%",
                gap: "8px",
              }}
              onClick={handleRecordNewVideo}
            >
              {/* <RadioButtonChecked /> */}
              {(width > 767 || width === 0) && (
                <Box
                  component={"img"}
                  src={jobVideoHeaderLogo}
                  sx={{
                    height: "20px",
                    width: "24px",
                  }}
                />
              )}
              record new video
            </Button>
          ) : (
            <Button
              variant="contained"
              color="nandos"
              sx={{
                borderRadius: "0 0 25px 0",
                width: "33.33%",
                height: "100%",
                gap: "8px",
              }}
              onClick={handleFileClick}
            >
              <Box
                component={"img"}
                src={myVideoHeaderLogo}
                sx={{
                  height: "20px",
                  width: "24px",
                  display: { xs: "none", tablet: "block" },
                }}
              />
              lock it in
            </Button>
          )}
          {/* <Button
            variant="contained"
            color="uploadVideoButton"
            sx={{
              borderRadius: "0 0 25px 0",
              width: "33.33%",
              height: "100%",
              gap: "8px",
            }}
            onClick={handleFileClick}
          >
            <Box
              component={"img"}
              src={uploadNewVideoIcon}
              sx={{
                height: "20px",
                width: "24px",
                display: { xs: "none", tablet: "block" },
              }}
            />
            lock it in
          </Button> */}
        </Box>
      </>
      <Delete
        show={openDelete}
        handleOpen={handleOpenDelete}
        handleCancel={handleCancelDelete}
        handleDelete={deleteUploadedVideo}
        confirmDelete={confirmDelete}
        setConfirmDelete={setConfirmDelete}
      />
      {openReviewVideoDialog && (
        <ReviewVideoDialog
          videoLink={videoData}
          open={openReviewVideoDialog}
          setOpen={handleCloseDialog}
        />
      )}
    </>
  );
}
