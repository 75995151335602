import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getApi, postApi } from "../../utils/Apis";
import { setLoading } from "../configSlice";
const initialState = {
  notificationCount: 0,
  coinsCount: 0,
  company_id: "",
  openCoinBox: false,
};

export const saveAlertSetting = createAsyncThunk(
  "saveAlertSetting",
  async (payload, { dispatch }) => {
    dispatch(setLoading({value:true, apiName:"saveAlertSetting"}));
    const { data } = await postApi("/user/updatealertsetting", payload, true);
    dispatch(setLoading({value:false, apiName:"saveAlertSetting"}));

    return data;
  }
);

export const getAlertSettings = createAsyncThunk(
  "getAlertSettings",
  async (payload, { dispatch }) => {
    // dispatch(setLoading(true));
    const { data } = await getApi(`/user/getalertsetting`, true);
    // dispatch(setLoading(false));
    return data;
  }
);

export const getNotificationCount = createAsyncThunk(
  "getNotificationCount",
  async (payload, { dispatch }) => {
    // dispatch(setLoading(true));
    const { data } = await getApi(`/user/getalertcounter`, true);
    // dispatch(setLoading(false));
    return data;
  }
);

export const getAlertsList = createAsyncThunk(
  "getAlertsList",
  async ({ lastKey }, { dispatch }) => {
    // dispatch(setLoading(true));
    const { data } = await getApi(`/user/getalerts?lastKey=${lastKey}`, true);
    // dispatch(setLoading(false));
    return data;
  }
);

export const readNotification = createAsyncThunk(
  "readNotification",
  async ({ emailLog = "", readflag = "" }, { dispatch }) => {
    // dispatch(setLoading(true));
    const { data } = await getApi(
      `/user/readmessage?email_log_id=${emailLog}&readAllFlag=${readflag}`,
      true
    );
    // dispatch(setLoading(false));
    return data;
  }
);

export const deleteNotification = createAsyncThunk(
  "deleteNotification",
  async (payload, { dispatch }) => {
    dispatch(setLoading(true));
    const { data } = await postApi("/user/deleteflag", payload, true);
    dispatch(setLoading(false));
    return data;
  }
);

export const alerts = createSlice({
  name: "configalerts",
  initialState,
  reducers: {
    setNotificationCount: (state, action) => {
      state.notificationCount = action.payload;
    },
    setCoinsCount: (state, action) => {
      state.coinsCount = action.payload;
    },
    setOpenCoinBox: (state, action) => {
      state.openCoinBox = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getNotificationCount.fulfilled, (state, action) => {
      state.notificationCount = action?.payload?.count;
      state.coinsCount = action?.payload?.coinsCounter;
      state.company_id = action?.payload?.company_id;
    });
  },
});
// Action creators are generated for each case reducer function
export const { setNotificationCount, setCoinsCount, setOpenCoinBox } =
  alerts.actions;

export default alerts.reducer;
