import { Box, Button, Popover, Typography, useTheme } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import CustomDialog from "../../../common/CustomDialog";
import upArrow from "../../../../assets/Black_Up_Close - Copy.svg";
import PostAs from "./PostAs";
import { getPostAsCompany } from "../../../../redux/admin/jobsSlice";
import { useDispatch } from "react-redux";
import { ALERT_TYPE, ERROR_MSG } from "../../../../utils/Constants";
import { setAlert } from "../../../../redux/configSlice";

export default function PostAsPopUp({
  openEdit,
  handleClose,
  postJobData,
  setPostJobData,
  handleNextStep,
  errors,
  setCompanyList,
  companyList,
}) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const postJobButtonref = useRef(null);
  const [selectedCompany, setSelectedCompany] = useState();
  const [openPostAs, setOpenPostAs] = useState(null);
  const anchorEl = Boolean(openPostAs);
  const [defaultCompany, setDefaultCompany] = useState();
  const [companyListLoading, setCompanyListLoading] = useState(false);
  const [lastKey, setLastKey] = useState(0);
  const [searchText, setSearchText] = useState("");

  const handleCloseRecruit = (event) => {
    setOpenPostAs(null);
  };

  const handleClickRecruit = (event) => {
    setOpenPostAs(postJobButtonref?.current);
  };

  const getCompanyList = async (lastkey, searchTitle) => {
    setCompanyListLoading(true);
    try {
      const { payload } = await dispatch(
        getPostAsCompany({ lastKey: lastkey, title: searchTitle })
      );
      if (payload?.status === "success") {
        setPostJobData({
          ...postJobData,
          company_id: payload?.data?.filter((item) => item?.default)?.[0]
            ?.company_id,
        });
        setSelectedCompany(
          payload?.data?.filter((item) => item?.default)?.[0]?.company_id
        );
        setDefaultCompany(payload?.data?.filter((item) => item?.default)?.[0]);
        setCompanyList((prev) => [...prev, ...payload?.data]);
        setLastKey(payload?.pageNumber + 1);
      }
      setCompanyListLoading(false);
    } catch (error) {
      dispatch(
        setAlert({
          show: true,
          type: ALERT_TYPE?.ERROR,
          msg: ERROR_MSG,
        })
      );
    }
  };

  useEffect(() => {
    getCompanyList(0, "");
  }, []);

  return (
    <CustomDialog
      show={openEdit}
      hideButton={false}
      dialogWidth="xs"
      showFooter={false}
      onDialogClose={handleClose}
      padding={0}
    >
      <Typography
        sx={{
          fontSize: "18px",
          fontWeight: theme.typography.Bold,
          textAlign: "center",
        }}
      >
        Post As
      </Typography>
      <Box
        sx={{
          display: "flex",
          width: "100%",
          justifyContent: "center",
          alignItems: "center",
          gap: "10px",
          padding: "16px 0",
        }}
      >
        <Button
          ref={postJobButtonref}
          color="displayCompanyButton"
          sx={{
            border: errors?.find((error) => error?.key === "company")
              ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
              : `1px solid ${theme.palette.borderColor}`,
            boxShadow: "none",
            ":hover": {
              boxShadow: "none",
            },
            borderRadius: "10px",
            fontSize: "14px",
            fontWeight: theme.typography.Regular,
            padding: "10px 10px 11px 19px",
          }}
          variant="contained"
          onClick={handleClickRecruit}
        >
          <Typography
            sx={{
              width: "100%",
              fontSize: "14px",
              fontWeight: theme.typography.Regular,
              textWrap: "noWrap",
            }}
          >
            {selectedCompany
              ? `${
                  companyList?.find((item) => {
                    return item.company_id === selectedCompany;
                  })?.name
                }`
              : "Select an employer company"}
          </Typography>
          <Box
            component={"img"}
            src={upArrow}
            sx={{
              height: "25px",
              width: "25px",
            }}
          />
        </Button>
      </Box>
      <Popover
        open={anchorEl}
        anchorEl={openPostAs}
        onClose={handleCloseRecruit}
        sx={{
          "& .MuiPaper-root": {
            borderRadius: "25px",
          },
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <PostAs
          setPostJobData={setPostJobData}
          postJobData={postJobData}
          setSelectedCompany={setSelectedCompany}
          selectedCompany={selectedCompany}
          handleCloseRecruit={handleCloseRecruit}
          companyList={companyList}
          setCompanyList={setCompanyList}
          defaultCompany={defaultCompany}
          companyListLoading={companyListLoading}
          getCompanyList={getCompanyList}
          lastKey={lastKey}
          setSearchText={setSearchText}
          searchText={searchText}
        />
      </Popover>

      <Box>
        <Button
          sx={{
            boxShadow: 0,
            fontSize: "14px",
            width: "50%",
            borderRadius: 0,
            height: "43px",
            color: "black",
            padding: 3,
          }}
          color="submitButton"
          variant="contained"
          onClick={handleClose}
        >
          cancel
        </Button>
        <Button
          sx={{
            boxShadow: 0,
            fontSize: "14px",
            width: "50%",
            borderRadius: 0,
            height: "43px",
            padding: 3,
          }}
          variant="contained"
          color="redButton100"
          onClick={handleNextStep}
        >
          next
        </Button>
      </Box>
    </CustomDialog>
  );
}
