import Button from "@mui/material/Button";
import { Tooltip } from "@mui/material";

export default function SmallButtonTalent({
  color,
  label,
  startIcon,
  endIcon,
  textColor,
  height = 20,
  startIconMargin,
  onClick,
  value,
  justifyContent,
  disabled,
  borderRadius,
  fontWeight,
  padding,
  margin,
  alignItems,
  minWidth,
  cursor,
  marginLeft = 0,
  ref,
  ...props
}) {
  return (
    <Tooltip
      disableInteractive
      title={value ? value : label}
      placement="top-end"
    >
      <Button
        variant="contained"
        disabled={disabled}
        color={color}
        startIcon={startIcon}
        endIcon={endIcon}
        onClick={onClick}
        margin={margin}
        ref={ref}
        sx={{
          margin: margin,
          cursor: cursor,
          justifyContent: justifyContent,
          fontSize: 10,
          fontWeight: fontWeight ? fontWeight : 300,
          letterSpacing: "0.75px",
          height: height,
          boxShadow: 0,
          borderRadius: borderRadius ? borderRadius : "5px",
          color: textColor,
          minWidth: minWidth ? minWidth : "fit-content",
          padding: padding ? padding : "0 8px",
          ".MuiButton-startIcon": {
            marginRight: startIconMargin,
            marginLeft: marginLeft,
          },
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          "&:hover": {
            boxShadow: "none",
          },
          alignItems: alignItems,
          ...props,
        }}
      >
        {label}
      </Button>
    </Tooltip>
  );
}
