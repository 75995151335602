import { Box, Button, Tooltip, Typography } from "@mui/material";
import React from "react";
import location from "../../../../assets/Red_Location.svg";
import salary from "../../../../assets/Blue_Salary.svg";
import date from "../../../../assets/Green_Notice_Period.svg";
import pending from "../../../../assets/Yellow_Pending.svg";
import runMatchIcon from "../../../../assets/Padding Excluded/Black_Match_me.svg";
import download from "../../../../assets/Padding Excluded/Black_Upload.svg";
import { formatCurrencyWithCommas } from "../../../../utils/Currency";
import { dateConverterFullMonth } from "../../../../utils/DateTime";
import {
  getDecodedToken,
  getToken,
  renderColor,
} from "../../../../utils/Common";
import { useDispatch } from "react-redux";
import { exportCSV } from "../../../../redux/employer/myJobsSlice";
import { setAlert } from "../../../../redux/configSlice";
import { ALERT_TYPE, ERROR_MSG } from "../../../../utils/Constants";
import profile from "../../../../assets/Crayon_Favicon.svg";

export default function CardsTopBar({
  jobDetail,
  theme,
  talents,
  isFilterApplied,
  reRunMatch,
}) {
  const dispatch = useDispatch();
  const decodedToken = getDecodedToken(getToken());
  const findStatus = () => {
    const lastObjectWithItems = talents
      ?.slice()
      ?.reverse()
      ?.find((obj) => obj?.items?.length > 0);
    return lastObjectWithItems?.status;
  };
  const exportAsCSV = async () => {
    try {
      const { payload } = await dispatch(
        exportCSV({ job_id: jobDetail?.job_id })
      );
      if (payload) {
        const fileURL = window.URL.createObjectURL(new Blob([payload]));
        const fileLink = document.createElement("a");
        fileLink.href = fileURL;
        fileLink.setAttribute("download", "filename.csv");
        document.body.appendChild(fileLink);
        fileLink.click();
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE?.SUCCESS,
            msg: "CSV exported successfully",
          })
        );
      } else {
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE?.ERROR,
            msg: ERROR_MSG,
          })
        );
      }
    } catch (error) {
      dispatch(
        setAlert({
          show: true,
          type: ALERT_TYPE?.ERROR,
          msg: ERROR_MSG,
        })
      );
    }
  };

  return (
    <Box sx={{display:"flex"}}>
      <Box component={"img"} src={jobDetail?.company?.profile_url} sx={{height:"60px", width:"60px", borderRadius:"10px",  marginTop:"10px", marginLeft:"15px"}}>
      </Box>
      <Box sx={{flex:"1"}}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          marginLeft: "15px",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", gap: "20px" }}>
          <Tooltip
            disableInteractive
            arrow
            title={jobDetail?.title}
            placement="top"
          >
            <Typography
              sx={{
                fontWeight: theme.typography.Bold,
                fontSize: "20px",
                overflow: "hidden",
                display: "-webkit-box",
                WebkitBoxOrient: "vertical",
                WebkitLineClamp: 1,
                wordBreak: "break-all",
                marginTop: "7px",
              }}
            >
              {jobDetail?.title}
            </Typography>
          </Tooltip>
          <Box sx={{ height: "100%" }}>
            <Button
              variant="contained"
              sx={{
                height: "30px",
                borderRadius: "0 0 0 10px",
                boxShadow: "none !important",
                padding: "0px !important",
                fontSize: "12px",
                fontWeight: theme.typography.Bold,
                minWidth: "60px !important",
                maxWidth:"70px",
                background:
                  theme.manageTalent[
                    renderColor(
                      isFilterApplied ? jobDetail?.stage?.name : findStatus()
                    )
                  ]?.main,
                "&:hover": {
                  background:
                    theme.manageTalent[
                      renderColor(
                        isFilterApplied ? jobDetail?.stage?.name : findStatus()
                      )
                    ]?.main,
                },
              }}
            >
              {isFilterApplied ? jobDetail?.stage?.name : findStatus()}
            </Button>
            <Button
              variant="contained"
              sx={{
                height: "30px",
                borderRadius: "0 0 10px 0",
                background: theme.manageTalent["manageMatched"]?.main,
                padding: "0px !important",
                width: "100px",
                fontSize: "12px",
                fontWeight: theme.typography.Bold,
                "& .MuiButton-startIcon": {
                  marginRight: "4px !important",
                },
                "&:hover": {
                  background: theme.manageTalent["manageMatched"]?.main,
                },
              }}
              onClick={() => {
                reRunMatch();
              }}
              startIcon={
                <Box
                  component={"img"}
                  src={runMatchIcon}
                  sx={{ height: "15px", width: "18px" }}
                />
              }
            >
              run match
            </Button>
          </Box>
        </Box>
        {(decodedToken?.data?.role_id === 1 ||
          decodedToken?.data?.role_id === 2) && (
          <Button
            variant="contained"
            color="submitButton"
            sx={{
              height: "30px",
              borderRadius: "0 25px 0 10px",
              padding: "0px !important",
              width: "100px",
              fontSize: "12px",
              fontWeight: theme.typography.Bold,
              "& .MuiButton-startIcon": {
                marginRight: "4px !important",
              },
            }}
            onClick={() => {
              exportAsCSV();
            }}
            startIcon={
              <Box
                component={"img"}
                src={download}
                sx={{ height: "15px", width: "18px" }}
              />
            }
          >
            {"export .csv"}
          </Button>
        )}
      </Box>
      <Box
        sx={{
          display: "flex",
          width: "fit-content",
          gap: "60px",
          justifyContent: "space-between",
          marginLeft: "12px",
          alignItems:"center"
        }}
      >
        <Box
          sx={{
            width: "fit-content",
            height: "fit-content",
            display: "flex",
            flexDirection: "row",
            // alignItems: "flex-start",
            gap: "5px",
          }}
        >
          <Box
            component="img"
            sx={{
              height: 16,
              width: 15,
              maxHeight: { xs: 16 },
              maxWidth: { xs: 15 },
            }}
            alt="salary"
            src={salary}
          />
          <Typography
            sx={{
              fontWeight: theme.typography.Bold,
              fontSize: "14px",
              letterSpacing: "0.25px",
            }}
          >
            {/* {jobDetail?.salary?.max &&
            jobDetail?.salary?.min &&
            jobDetail?.salary?.currency?.symbol ? (
              <>
                {jobDetail?.salary?.currency?.symbol}
                {formatCurrencyWithCommas(jobDetail?.salary?.min)} to{" "}
                {jobDetail?.salary?.currency?.symbol}
                {formatCurrencyWithCommas(jobDetail?.salary?.max)} per month
              </>
            ) : (
              "-"
            )} */}

            {jobDetail?.salary?.min === 0 ? (
              <>
                {jobDetail?.salary?.currency?.symbol}
                {formatCurrencyWithCommas(jobDetail?.salary?.max)}{" "}
                {jobDetail?.type === "freelance" ? "per hour" : "per month"}
              </>
            ) : jobDetail?.salary?.currency?.symbol ||
              jobDetail?.salary?.min ||
              jobDetail?.salary?.max ? (
              <>
                {jobDetail?.salary?.currency?.symbol}
                {formatCurrencyWithCommas(jobDetail?.salary?.min)} to{" "}
                {jobDetail?.salary?.currency?.symbol}
                {formatCurrencyWithCommas(jobDetail?.salary?.max)}{" "}
                {jobDetail?.type === "freelance" ? "per hour" : "per month"}
              </>
            ) : (
              "-"
            )}
          </Typography>
        </Box>

        <Box
          sx={{
            width: "fit-content",
            height: "fit-content",
            display: "flex",
            flexDirection: "row",
            alignItems: "flex-end",
            gap: "5px",
            // marginLeft: "8px",
          }}
        >
          <Box
            component="img"
            sx={{
              height: 16,
                      width: 15,
                      maxHeight: { xs: 16 },
                      maxWidth: { xs: 15 },
            }}
            alt="location"
            src={location}
          />
          <Typography
            sx={{
              fontWeight: theme.typography.Bold,
              fontSize: "14px",
              letterSpacing: "0.25px",
              display: "flex",
              height: "20px",
            }}
          >
            {jobDetail?.town?.name && jobDetail?.town?.country?.name
              ? `${jobDetail?.town?.name}, ${jobDetail?.town?.country?.name}`
              : "-"}
          </Typography>
        </Box>
        <Box
          sx={{
            width: "fit-content",
            height: "fit-content",
            display: "flex",
            flexDirection: "row",
            alignItems: "flex-end",
            gap: "5px",
            // marginLeft: "8px",
          }}
        >
          <Box
            component="img"
            sx={{
              height: 16,
              width: 15,
              maxHeight: { xs: 16 },
              maxWidth: { xs: 15 },
            }}
            alt="experience"
            src={pending}
          />
          <Typography
            sx={{
              fontWeight: theme.typography.Bold,
              fontSize: "14px",
              letterSpacing: "0.25px",
            }}
          >
            {jobDetail?.experience?.year_start} to{" "}
            {jobDetail?.experience?.year_end} years
          </Typography>
        </Box>
        <Box
          sx={{
            width: "fit-content",
            height: "fit-content",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: "5px",
            // marginLeft: "8px",
          }}
        >
          <Box
            component="img"
            sx={{
              height: 16,
              width: 15,
              maxHeight: { xs: 16 },
              maxWidth: { xs: 15 },
            }}
            alt="date"
            src={date}
          />
          <Typography
            sx={{
              fontWeight: theme.typography.Bold,
              fontSize: "14px",
              letterSpacing: "0.25px",
            }}
          >
            {jobDetail?.job_start_date
              ? dateConverterFullMonth(jobDetail?.job_start_date)
              : "-"}
          </Typography>
        </Box>
      </Box>
      </Box>
    </Box>
  );
}
