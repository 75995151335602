import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import { useTheme } from "@mui/material/styles";

export default function ButtonPanel({
  activeButton,
  // setActiveButton,
  panelData,
  side,
  color,
  onClickFunc,
}) {
  const theme = useTheme();
  // const [activeButton, setActiveButton] = useState(0)

  const handleButtonClick = (btnValue) => {
    onClickFunc(btnValue);
    // setActiveButton(btnValue)
  };
  return (
    // <Grid xs >
    <Box
      sx={{
        // mt: { sx: 0, sm: topMargin ? '68px' : '16px' },
        textAlign: side === "right" && "end",
        display: { xs: "flex" },
        flexDirection: "column",
        gap: "10px",
        // overflow: { xs: "auto", sm: "hidden" },
      }}
    >
      {panelData?.map((btn) => (
        <Tooltip
          disableInteractive
          title={btn?.name}
          key={btn?.id}
          placement="top-end"
        >
          <Button
            onClick={() => handleButtonClick(btn?.value)}
            variant="contained"
            color={activeButton === btn?.value ? color : "whiteButton"}
            sx={{
              borderRadius: "0 10px 10px 0",
              height: "45px",
              fontSize: theme.typography.fontSize.xs,
              fontWeight:
                activeButton === btn?.value
                  ? theme.typography.Bold
                  : theme.typography.Regular,
              ":hover": {
                fontWeight: theme.typography.Bold,
                color: theme.palette.baseColorButton.contrastText,
                background: theme.palette.baseColorButton.main,
              },
              minWidth: { xs: "125px", sm: 0 },
              padding: "0px",
              lineHeight: "inherit",
              borderBottomLeftRadius: side === "left" ? { sm: 0 } : "10px",
              borderTopLeftRadius: side === "left" ? { sm: 0 } : "10px",
              borderTopRightRadius: side === "right" ? { sm: 0 } : "10px",
              borderBottomRightRadius: side === "right" ? { sm: 0 } : "10px",
              mr: { xs: 1, sm: 0 },
              width: "125px",
              "&:hover": {
                // opacity: 1,
                // backgroundColor: theme.palette[btn.color]?.main,
                backgroundColor: theme.palette[color].main,
                color: theme.palette.white,
                fontWeight: theme.typography.Bold,
              },
            }}
          >
            {btn?.name}
          </Button>
        </Tooltip>
      ))}
    </Box>
    // </Grid>
  );
}
