import { Box, Button, Typography, useTheme } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import InputBox from "../../../common/InputBox";
import { ALERT_TYPE, COINS_PRICE } from "../../../../utils/Constants";
import {
  getCoinsPrice,
  updateCoinsPrice,
} from "../../../../redux/admin/maintenance";
import { useDispatch } from "react-redux";
import { setAlert } from "../../../../redux/configSlice";
import { debounce } from "../../../../utils/Common";

export default function CoinsPrice() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [coinsValue, setCoinsValue] = useState([]);
  const [coins, setCoins] = useState();

  const updateCoinPrice = async (coin) => {
    try {
      let data = {
        currency_id: coin?.id,
        cost: Number(coin?.value),
      };
      const { payload } = await dispatch(updateCoinsPrice(data));
      if (payload?.status === "success") {
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE?.SUCCESS,
            msg: "Currency updated successfully",
          })
        );
      } else {
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE?.ERROR,
            msg: payload?.message,
          })
        );
      }
    } catch (error) {
      dispatch(
        setAlert({
          show: true,
          type: ALERT_TYPE?.ERROR,
          msg: error,
        })
      );
    }
  };

  const getCoinsPriceInfo = async (lastKeyy) => {
    try {
      const { payload } = await dispatch(getCoinsPrice());
      // setCoinsValue(payload);
      if (payload?.status === "success") {
        setCoinsValue(payload?.data);
      } else {
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE?.ERROR,
            msg: payload?.message,
          })
        );
      }
    } catch (error) {
      dispatch(
        setAlert({
          show: true,
          type: ALERT_TYPE?.ERROR,
          msg: error,
        })
      );
    }
  };

  useEffect(() => {
    const delayFun = setTimeout(() => {
      coins?.value !== "" && coins && updateCoinPrice(coins);
    }, 1000);
    return () => clearTimeout(delayFun);
  }, [coins]);

  useEffect(() => {
    getCoinsPriceInfo();
  }, []);

  return (
    <Box
      sx={{
        width: "400px",
        height: "119px",
        background: "white",
        borderRadius: "25px",
        mt: "45px",
        boxShadow: `0px 5px 10px ${theme?.palette?.boxShadow?.main}`,
      }}
    >
      <Button
        variant="contained"
        color="redButton"
        sx={{ height: "45px", width: "150px", borderRadius: "25px 0 25px 0" }}
      >
        coin price
      </Button>

      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          padding: "8px 28px",
          justifyContent: "space-between",
        }}
      >
        {coinsValue?.map((item) => {
          return (
            <Box
              key={item?.coin_amount_id}
              sx={{
                display: "flex",
                width: "40%",
                justifyContent: "space-between",
                alignItems: "center",
                gap: "16px",
                padding: "8px 0",
              }}
            >
              <Typography
                sx={{ fontSize: "16px", fontWeight: theme.typography.Bold }}
              >
                {item?.currency?.currency}
              </Typography>
              <InputBox
                id="company_user"
                value={item?.cost}
                // onChange={(e) => handleInputChange(e, item?.currency_id)}
                onChange={(e) => {
                  setCoinsValue(
                    coinsValue?.map((coin) =>
                      coin?.currency_id === item?.currency_id
                        ? { ...coin, cost: e?.target?.value }
                        : coin
                    )
                  );
                  setCoins({ value: e?.target?.value, id: item?.currency_id });
                }}
                //   placeholder={i18n["coinHistory.theassociateduser"]}
                sx={{
                  border: "1px solid #E0E0E0",
                  borderRadius: "10px !important",
                  "& .MuiInputBase-root": {
                    margin: "0 !important",
                  },
                  "& .MuiInputBase-input": {
                    width: "75px !important",
                    textAlign: "center",
                  },
                }}
              />
            </Box>
          );
        })}
      </Box>
    </Box>
  );
}
