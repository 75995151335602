import {
  Box,
  Button,
  IconButton,
  InputBase,
  Paper,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useState } from "react";
import CloseIcon from "../../../common/CloseIcon";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { ALERT_TYPE, PASSWORD_REGEX } from "../../../../utils/Constants";
import { resetLoggedInUserPassword } from "../../../../redux/employer/empProfileSlice";
import { useDispatch } from "react-redux";
import { setAlert } from "../../../../redux/configSlice";
import { validateSchema } from "../../../../utils/Common";
import * as Yup from "yup";
import locale from "../../../../i18n/locale";

export default function ResetPassword({ onClose }) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const i18n = locale.en;
  const [errors, setErrors] = useState();
  const [passwordData, setPasswordData] = useState({
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
  });
  const [showPassword, setShowPassword] = useState(false);
  const [fieldTouched, setFieldTouched] = useState([]);
  const [changePasswordError, setChangePasswordError] = useState([
    {
      change_password: false,
      confirm_password: false,
      errorText: "",
    },
  ]);

  const handleChange = (e, key) => {
    let updatedFormValue = { ...passwordData };
    updatedFormValue[key] = e?.target?.value;
    setPasswordData({ ...updatedFormValue });
    if (!PASSWORD_REGEX?.test(e.target.value)) {
      setChangePasswordError((prev) => {
        const errorText =
          "Password must contain at least 8 characters, one uppercase, one lowercase, one number, and one special character";
        const existingErrorIndex = prev.findIndex((error) => error.key === key);
        const newError = {
          change_password: false,
          confirm_password: false,
          errorText: errorText,
          key: key,
        };
        if (existingErrorIndex !== -1) {
          return prev.map((error, index) =>
            index === existingErrorIndex ? newError : error
          );
        }
        return [...prev, newError];
      });
    } else {
      setChangePasswordError((prev) => {
        const existingErrorIndex = prev.findIndex((error) => error.key === key);
        const newError = {
          change_password: false,
          confirm_password: false,
          errorText: "",
          key: "",
        };
        if (existingErrorIndex !== -1) {
          return prev.map((error, index) =>
            index === existingErrorIndex ? newError : error
          );
        }
        return [...prev, newError];
      });
      setErrors(errors?.filter((item) => item?.key !== key));
    }
  };

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const validationSchema = Yup.object().shape({
    currentPassword: Yup.string().required("Current Password required"),
    newPassword: Yup.string().required("New Password required"),
    confirmPassword: Yup.string().required("Confirm Password required"),
  });

  const handlePasswordReset = async () => {
    const params = {
      new_password: passwordData?.newPassword,
    };
    // if (!PASSWORD_REGEX?.test(passwordData?.newPassword)) {
    //   setErrors((prev) => [
    //     ...prev,
    //     { message: "password not matching", key: "password" },
    //   ]);
    //   return;
    // }
    validateSchema(validationSchema, passwordData)
      .then(() => {
        try {
          dispatch(resetLoggedInUserPassword(params)).then((payload) => {
            if (payload?.payload?.status === "success") {
              // setNewPassword("");
              dispatch(
                setAlert({
                  show: true,
                  type: ALERT_TYPE.SUCCESS,
                  msg: "Password reset Successfully!",
                })
              );
            } else {
              dispatch(
                setAlert({
                  show: true,
                  type: ALERT_TYPE.ERROR,
                  msg: payload?.payload?.message,
                })
              );
            }
          });
        } catch (error) {
          dispatch(setAlert({ show: true }));
        }
      })
      .catch((error) => {
        setErrors(
          Object.keys(error)?.map((item) => ({
            message: error[item],
            key: item,
          }))
        );
      });
  };

  return (
    <Box sx={{ width: "300px", height: "auto", background: "#FFFFFF" }}>
      <Box>
        <Button
          variant="contained"
          color="yoco"
          sx={{
            width: "150px",
            height: "45px",
            boxShadow: "none !important",
            borderRadius: "25px 0 10px 0",
            padding: "0px",
          }}
        >
          reset password
        </Button>
        <Box onClick={onClose}>
          <CloseIcon />
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "8px",
          padding: "8px 12px 13px 11px",
        }}
      >
        <Typography
          sx={{
            fontSize: theme.typography.fontSize.sm,
            fontWeight: theme.typography.Regular,
          }}
        >
          Enter your current password
        </Typography>
        <Paper
          sx={{
            display: "flex",
            height: "40px",
            width: "100% !important",
            borderRadius: "10px  !important",
            boxShadow: "none",
            backgroundColor: "#fff !important",
            border: errors?.find((error) => error.key === "currentPassword")
              ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
              : `1px solid ${theme.palette.borderColor}`,
          }}
        >
          <InputBase
            sx={{ ml: 2, mr: 2, width: "100%" }}
            id="currentPassword"
            value={passwordData?.currentPassword}
            onChange={(e) => {
              handleChange(e, "currentPassword");
            }}
            onBlur={(e) => {
              setFieldTouched([...fieldTouched, "currentPassword"]);
            }}
            type={showPassword ? "text" : "password"}
          />
          <IconButton
            sx={{
              py: 0,
              "& .MuiSvgIcon-root": {
                width: "25px !important",
                height: "25px !important",
              },
            }}
            color=""
            aria-label="reset password"
            component="button"
            onClick={handleShowPassword}
            disableRipple={true}
          >
            {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
          </IconButton>
        </Paper>

        {changePasswordError?.find(
          (item) => item?.key === "currentPassword"
        ) && (
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: theme.typography.Medium,
              color: "red",
              mt: "4px",
              // textAlign: "center",
              // width: "272px",
            }}
          >
            {i18n["alert.passwordmustcontain"]}
          </Typography>
        )}

        <Typography
          sx={{
            fontSize: theme.typography.fontSize.sm,
            fontWeight: theme.typography.Regular,
          }}
        >
          Enter your new password
        </Typography>
        <Paper
          sx={{
            display: "flex",
            height: "40px",
            width: "100% !important",
            borderRadius: "10px  !important",
            boxShadow: "none",
            backgroundColor: "#fff !important",
            border: errors?.find((error) => error.key === "newPassword")
              ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
              : `1px solid ${theme.palette.borderColor}`,
          }}
        >
          <InputBase
            sx={{ ml: 2, mr: 2, width: "100%" }}
            id="newPassword"
            value={passwordData?.newPassword}
            onChange={(e) => {
              handleChange(e, "newPassword");
            }}
            onBlur={(e) => {
              setFieldTouched([...fieldTouched, "newPassword"]);
            }}
            type={showPassword ? "text" : "password"}
          />
          <IconButton
            sx={{
              py: 0,
              "& .MuiSvgIcon-root": {
                width: "25px !important",
                height: "25px !important",
              },
            }}
            color=""
            aria-label="reset password"
            component="button"
            onClick={handleShowPassword}
            disableRipple={true}
          >
            {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
          </IconButton>
        </Paper>
        {changePasswordError?.find((item) => item?.key === "newPassword") && (
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: theme.typography.Medium,
              color: "red",
              mt: "4px",
              // textAlign: "center",
              // width: "272px",
            }}
          >
            {i18n["alert.passwordmustcontain"]}
          </Typography>
        )}
        <Typography
          sx={{
            fontSize: theme.typography.fontSize.sm,
            fontWeight: theme.typography.Regular,
          }}
        >
          Confirm your new password
        </Typography>
        <Paper
          sx={{
            display: "flex",
            height: "40px",
            width: "100% !important",
            borderRadius: "10px  !important",
            boxShadow: "none",
            backgroundColor: "#fff !important",
            border: errors?.find((error) => error.key === "confirmPassword")
              ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
              : `1px solid ${theme.palette.borderColor}`,
          }}
        >
          <InputBase
            sx={{ ml: 2, mr: 2, width: "100%" }}
            id="confirmPassword"
            value={passwordData?.confirmPassword}
            onChange={(e) => {
              handleChange(e, "confirmPassword");
            }}
            onBlur={(e) => {
              setFieldTouched([...fieldTouched, "confirmPassword"]);
            }}
            type={showPassword ? "text" : "password"}
          />
          <IconButton
            sx={{
              py: 0,
              "& .MuiSvgIcon-root": {
                width: "25px !important",
                height: "25px !important",
              },
            }}
            color=""
            aria-label="reset password"
            component="button"
            onClick={handleShowPassword}
            disableRipple={true}
          >
            {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
          </IconButton>
        </Paper>
        {changePasswordError?.find(
          (item) => item?.key === "confirmPassword"
        ) && (
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: theme.typography.Medium,
              color: "red",
              mt: "4px",
              // textAlign: "center",
              // width: "272px",
            }}
          >
            {i18n["alert.passwordmustcontain"]}
          </Typography>
        )}
      </Box>

      <Box>
        <Button
          variant="contained"
          color="redButton"
          sx={{ width: "100%", height: "60px", borderRadius: "0 0 25px 25px" }}
          onClick={handlePasswordReset}
        >
          reset password
        </Button>
      </Box>
    </Box>
  );
}
