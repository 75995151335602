import { Box, Slider, Typography, useTheme } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { formatNumber, useQuery } from "../../utils/Common";
import { BASIC_RANGEMARKS } from "../../utils/Constants";

function rangeValueHandler(value) {
  return formatNumber(value * 2000);
}

export default function SalaryBox({
  setSalary,
  buttonID,
  handleClosePopover,
  handleFilterSelection,
  setIsFilterApplied,
  getTalentMyJobStatusCount,
  setBasicData,
  onFilterSelection,
  setOpenPopovers,
  openPopovers,
  setSelectedBtn,
  panelData,
}) {
  const [rangeValue, setRangeValue] = useState([0, 20]);
  const boxRef = useRef();
  const theme = useTheme();
  const query = useQuery();
  const navigate = useNavigate();
  const { jobId } = useParams();

  const [sliderTouched, setSliderTouched] = useState(false);

  const salaryRef = useRef(rangeValue);
  useEffect(() => {
    // Update the ref whenever the state changes
    salaryRef.current =
      JSON.stringify(rangeValue) === JSON.stringify([0, 20]) ? [] : rangeValue;
    salaryRef?.current?.length && setSalary(salaryRef?.current);
  }, [rangeValue]);

  const handleRangeSliderChange = async (event, newValue) => {
    setSliderTouched(true);
    setRangeValue(newValue);
    // let newArr = newValue.map((val) => val * 1000);
    // const newBasicData = {
    //   ...basicData,
    //   [event.target.name]: newArr,
    // };
    // setBasicData(newBasicData);
    setIsFilterApplied(true);
    setBasicData((prevBasicData) => ({
      ...prevBasicData,
      salary: [newValue[0] * 2000, newValue?.[1] * 2000],
    }));

    handleFilterSelection(
      "salary",
      newValue ? [newValue?.[0] * 2000, newValue?.[1] * 2000] : "salary"
    );
    setTimeout(async () => {
      newValue?.length > 0 && onFilterSelection();
      newValue?.length > 0 && handleClosePopover(buttonID);
      newValue?.length > 0 && (await getTalentMyJobStatusCount(jobId));
    }, 5000);
    // navigate(
    //   `/employer/manage-talent/${jobId}?gender=${
    //     query.get("gender") === null ? "" : query.get("gender")
    //   }&race=${
    //     query.get("race") === null ? "" : query.get("race")
    //   }&qualification=${
    //     query.get("qualification") === null ? "" : query.get("qualification")
    //   }&salary=${[newValue[0] * 2000, newValue[1] * 2000]}&experience=${
    //     query.get("experience") === null ? "" : query.get("experience")
    //   }`
    // );
  };

  const handleClickOutside = (event) => {
    if (
      boxRef?.current &&
      !boxRef?.current?.contains(event?.target) &&
      !sliderTouched
    ) {
      const initialOpenState = {};
      panelData?.forEach((item) => {
        initialOpenState[item?.id] = false;
      });
      setOpenPopovers(initialOpenState);
      setSelectedBtn([1111]);
    }
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    let salaryParams;
    if (query?.get("salary") !== null || query?.get("salary") !== "") {
      salaryParams = query?.get("salary")?.split(",");
    }
    if (query?.get("salary")?.length > 0) {
      setSliderTouched(true);
      setRangeValue([salaryParams?.[0] / 2000, salaryParams?.[1] / 2000]);
    }
  }, []);

  return (
    <Box
      ref={boxRef}
      sx={{
        width: "1000px",
        // py: 2,
      }}
    >
      <Typography sx={{ fontWeight: 500 }}>Range/Rate</Typography>
      <Slider
        disableSwap
        valueLabelDisplay="on"
        // disabled={!basicData.currency_id}
        name="salary"
        getAriaLabel={() => "Temperature range"}
        value={rangeValue}
        onChange={handleRangeSliderChange}
        color="redButton100"
        // step={basicData.job_role_type == "freelance" && 1}
        valueLabelFormat={rangeValueHandler}
        getAriaValueText={rangeValueHandler}
        marks={BASIC_RANGEMARKS}
        sx={{
          marginTop: 4,
          width: "97%",
          ml: 1,
          "& .MuiSlider-rail": {
            backgroundColor: theme?.palette?.eyeview100?.main,
            height: "10px",
          },
          "& .MuiSlider-track": {
            height: "10px",
          },
          "& .MuiSlider-thumb": {
            borderRadius: "15%",
            background: "white",
          },
          "& .MuiSlider-valueLabel": {
            fontSize: 12,
            fontWeight: "normal",
            top: -6,
            backgroundColor: theme?.palette?.grayBackground,
            borderRadius: 1,
            color: theme?.palette?.text?.primary,
            "&:before": {
              display: "none",
            },
            "& *": {
              background: "transparent",
              color: theme?.palette?.mode === "dark" ? "#fff" : "#000",
            },
          },
        }}
      />
    </Box>
  );
}
