import React, { useEffect, useRef, useState } from "react";
import { Button, MenuList, Box, Typography, Popover } from "@mui/material";
import sortLogo from "../../../assets/Padding Included/White_Sort_Filter.svg";
import { useDispatch } from "react-redux";
import {
  getTalentJobStatusApplications,
  setPreviosSortOption,
  setSortColumn,
  setSortingOption,
} from "../../../redux/employer/myJobsSlice";
import { useTheme } from "@emotion/react";
import { useSelector } from "react-redux";
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

const SortButton = ({
  setBasicData,
  basicData,
  jobId,
  jobStatusId,
  handleSortedValue,
  columnStatus,
  setRandomLoading,
  randomLoading,
  setTalents,
  talents,
  setHasMore,
  color,
  setSelectedTab
}) => {
  const sortOption = useSelector((state) => state?.myJobs?.sortOption);
  const sortColumn = useSelector((state) => state?.myJobs?.sortColumn);
  const prevSortOption = useSelector((state) => state?.myJobs?.prevSortOption);
  const [anchorEl, setAnchorEl] = useState(null);
  const sortRef =  useRef()
  const sortingOptions = [
    { key: 1, value: "a to z" },
    { key: 9, value: "most recent" },
    { key: 6, value: "match %" },
    { key: 3, value: "salary" },
    { key: 5, value: "qualification" },
  ];
  const theme = useTheme();
  const open = Boolean(anchorEl);
  const dispatch = useDispatch();

  const handleClick = (event) => {
    setAnchorEl(sortRef.current);
    setSelectedTab()
  };
  const handleClose = () => {
    setAnchorEl(null);
    setSelectedTab("")
  };

  const handleSort = async (sortoption) => {
    setHasMore(true);
    setAnchorEl(null);
    setSelectedTab("")
    setRandomLoading((prev) => ({ ...prev, [columnStatus]: true }));
    let currentSortOption;
    if (sortoption === 3 && sortOption === 3) {
      currentSortOption = 2;
    } else if (sortoption === 5 && sortOption === 5) {
      currentSortOption = 4;
    } else if (sortoption === 6 && sortOption === 6) {
      currentSortOption = 7;
    } else if (sortoption === 1 && sortOption === 1) {
      currentSortOption = 8;
    } else {
      currentSortOption = sortoption; // Handle other cases if needed
    }
    dispatch(setSortingOption(currentSortOption));
    dispatch(setPreviosSortOption(currentSortOption));
    dispatch(setSortColumn(jobStatusId));
    try {
      const data = {
        job_id: jobId,
        job_status_id: jobStatusId,
        ...basicData,
        sortType: currentSortOption,
      };
      if (sortoption === 9) {
        data["recent"] = false;
        data["sortType"] = "";
      }
      let payload;
      payload = await dispatch(
        getTalentJobStatusApplications({ pageNumber: 0, payload: data })
      );
      setBasicData({ ...basicData, sortType: sortoption });
      handleSortedValue(jobStatusId, payload?.payload, columnStatus);
    } catch (error) {
      setRandomLoading((prev) => ({ ...prev, [columnStatus]: false }));
    }
  };

  useEffect(() => {
    if (jobStatusId !== sortColumn) {
      dispatch(setSortingOption(""));
    } else {
      dispatch(setSortingOption(prevSortOption));
    }
  }, [open]);

  return (
    <>
      <Box
        variant="text"
        id="fade-button"
        ref={sortRef}
        aria-controls={open ? "fade-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={()=>{handleClick();setSelectedTab(jobStatusId)}}
        paddingRight="20px"
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
        }}
      >
        <Box
          component="img"
          alt="sortLogo"
          src={sortLogo}
          height={50}
          width={50}
        />
        <Typography
          sx={{
            color: "white",
            fontSize: "12px",
            fontWeight: theme.typography.Bold,
          }}
        >
          sort
        </Typography>
      </Box>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        sx={{
          "& .css-ll95b0-MuiPaper-root-MuiPopover-paper": {
            minWidth: "100px !important",
            // borderRadius: "10px !important",
            // mt: 1,
            marginTop:"-3px",
            borderRadius:"0 0 10px 10px"
          },
        }}
      >
        {sortingOptions
          ?.filter(
            (item) =>
              item.key !== 2 &&
              item?.key !== 4 &&
              item?.key !== 7 &&
              item?.key !== 8
          )
          ?.map((selectedOption, index) => (
            <MenuList
              sx={{
                padding: 0,
                background:theme?.manageTalent?.[color]?.main,
              }}
            >
              <Button
                sx={{
                  borderRadius: "10px",
                  width: "100%",
                  color:theme?.manageTalent?.[color]?.contrastText,
                  fontSize: "12px",
                  fontWeight: theme.typography.Medium,
                  ".MuiButton-endIcon": {
                    color:"white"
                  },
                }}
                variant="text"
                onClick={() => handleSort(selectedOption?.key)}
                endIcon={
                  (selectedOption?.key === 3 && sortOption === 3) ||
                  (selectedOption?.key === 5 && sortOption === 5) ||
                  (selectedOption?.key === 6 && sortOption === 6) ||
                  (selectedOption?.key === 1 && sortOption === 1) ? (
                    <KeyboardArrowUpIcon sx={{color:"#fff"}}/>
                  ) : (
                    ((selectedOption?.key === 3 && sortOption === 2) ||
                      (selectedOption?.key === 5 && sortOption === 4) ||
                      (selectedOption?.key === 6 && sortOption === 7) ||
                      (selectedOption?.key === 1 && sortOption === 8)) && (
                      <KeyboardArrowDownIcon  sx={{color:"#fff"}} /> 
                    )
                  )
                }
              >
                {selectedOption?.value}
              </Button>
            </MenuList>
          ))}
      </Popover>
      {/* <StyledMenuSort
        id="fade-menu"
        MenuListProps={{
          "aria-labelledby": "fade-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
      >
      {sortingOptions
          ?.filter(
            (item) =>
              item.key !== 2 &&
              item?.key !== 4 &&
              item?.key !== 7 &&
              item?.key !== 8
          )
          ?.map((selectedOption, index) => (
            <MenuList
              sx={{
                padding: 0,
              }}
            >
              <Button
                sx={{
                  borderRadius: "10px",
                  width: "100%",
                  color: "black",
                  fontSize: "14px",
                  fontWeight: theme.typography.Medium,
                }}
                variant="text"
                onClick={() => handleSort(selectedOption?.key)}
                endIcon={
                  (selectedOption?.key === 3 && sortOption === 3) ||
                  (selectedOption?.key === 5 && sortOption === 5) ||
                  (selectedOption?.key === 6 && sortOption === 6) ||
                  (selectedOption?.key === 1 && sortOption === 1) ? (
                    <ArrowUpwardIcon />
                  ) : (
                    ((selectedOption?.key === 3 && sortOption === 2) ||
                      (selectedOption?.key === 5 && sortOption === 4) ||
                      (selectedOption?.key === 6 && sortOption === 7) ||
                      (selectedOption?.key === 1 && sortOption === 8)) && (
                      <ArrowDownwardIcon />
                    )
                  )
                }
              >
                {selectedOption?.value}
              </Button>
            </MenuList>
          ))}
      </StyledMenuSort> */}
    </>
  );
};

export default SortButton;
