import React, { useEffect, useRef, useState } from "react";
import { Box, Button, Paper, ThemeProvider } from "@mui/material";
import ButtonPanel from "../../common/ButtonPanel";
import {
  MY_TEAMS_LEFT_PANEL,
  MY_TEAMS_INVITE_STATUS,
} from "../../../utils/Constants";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import {
  setInviteStatus,
  setSelectedFilter,
} from "../../../redux/employer/myTeamFilter";
import { useDispatch } from "react-redux";
import LeftArrow from "../../common/LeftArrow";
import RightArrow from "../../common/RightArrow";
import { getProfilePercentage } from "../../../redux/employer/empJobListing";
import { getDecodedToken, getToken, useQuery } from "../../../utils/Common";
import useViewport from "../../common/ViewPort";
import { talentListingPageTheme } from "../../../utils/Theme";
import HiredTalent from "./HiredTalent";
import { useSelector } from "react-redux";
import { getAllTypes } from "../../../redux/allTypes";
import { getAllTalentType } from "../../../redux/guest/talentTypes";
import { getAllIndustries } from "../../../redux/configSlice";
import { setFilterExpanded } from "../../../redux/guest/jobsSlice";

const MyTeams = () => {
  const dispatch = useDispatch();
  const query = useQuery();
  const params = useLocation();
 
  const navigate = useNavigate();
  const selectedIndustries = useRef([]);
  const selectedTalent = useRef([]);
  const selectedActivity = useRef([]);
  const selectedType = useRef([]);
  const allTalent = useSelector((state) => state.configtalentslice?.talentList);
  const allIndustries = useSelector((state) => state.config?.industries);
  const allTypes = useSelector((state) => state.configAllTypes?.types);
  const allTalentTypes = useSelector((state) => state.talenttype?.talentType);
  const filterExpanded = useSelector(
    (state) => state?.configjobslice?.filterExpanded
  );
  const decodedToken = getDecodedToken(getToken());
  const selectedTeamStatusFilter = useRef([]);
  const selectedInviteStatusFilter = useRef([]);
  const { width } = useViewport();
  const [rightExpand, setRightExpand] = useState(true);
  const [leftExpanded, setLeftExpand] = useState(true);
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [profilePercentage, setProfilePercentage] = useState({
    myInfo: 0,
    companyInfo: 0,
    myPlan: 0,
    billing: 0,
  });
  const [talentListingDynamicTheme, setTalentListingDynamicTheme] = useState(
    talentListingPageTheme
  );

  const handleFilterSelection = (paramType, filterName) => {
    // Toggle filter selection
    if (
      filterName?.[0] === "Team Status" ||
      filterName?.[0] === "Invite Status"
    ) {
      filterName?.[0] === "Team Status" &&
        (selectedTeamStatusFilter.current = []);
      filterName?.[0] === "Invite Status" &&
        (selectedInviteStatusFilter.current = []);
      const queryParams = new URLSearchParams(window.location.search);
      queryParams.delete(paramType); // Remove the parameter from the query string
      navigate(
        decodedToken?.data?.role_id === 6
          ? `/recruiter/my-team?${queryParams.toString()}`
          : `/employer/my-team?${queryParams.toString()}`
      );
      return;
    } else {
      const updatedFilters = selectedFilters?.includes(filterName)
        ? selectedFilters?.filter((filter) => filter !== filterName)
        : [filterName];
      const queryParams = new URLSearchParams(window.location.search);
      queryParams.set(paramType, updatedFilters?.join(","));
      navigate(`${window.location.pathname}?${queryParams.toString()}`);
      setSelectedFilters(updatedFilters);
    }
  };

  const teamStatusFilter = (selectedFilter) => {
    if (selectedFilter?.includes(6) && selectedFilter?.[0] !== 6) {
      selectedFilter = [6];
    }
    if (selectedFilter?.[0] === 6 && selectedFilter?.length > 1) {
      selectedFilter = selectedFilter?.filter((item) => item !== 6);
    }
    dispatch(
      setSelectedFilter(selectedFilter[0] === 1 ? [1111] : selectedFilter)
    );
    let teamFilter = [];
    selectedFilter?.map((item) => {
      let selected = MY_TEAMS_LEFT_PANEL?.find((filter) => filter?.id === item);
      teamFilter?.push(selected?.name);
    });

    selectedTeamStatusFilter.current = selectedFilter;
    handleFilterSelection("teamstatus", teamFilter);
  };

  const inviteStatusFilter = (selectedFilter) => {
    dispatch(setInviteStatus(selectedFilter));

    let inviteFilter = [];
    selectedFilter?.map((item) => {
      let selected = MY_TEAMS_INVITE_STATUS?.find(
        (filter) => filter?.id === item
      );
      inviteFilter?.push(selected?.name);
    });

    selectedInviteStatusFilter.current = selectedFilter;
    handleFilterSelection("invitestatus", inviteFilter);
  };

  const getProfilePercentageData = async () => {
    const { payload } = await dispatch(getProfilePercentage());
    setProfilePercentage(payload?.data);
  };

  const getInitialData = async () => {
    await dispatch(getAllTalentType());
    await dispatch(getAllTypes());
    await dispatch(getAllIndustries());
  };

  useEffect(() => {
    getInitialData();
    getProfilePercentageData();
  }, []);

  useEffect(() => {
    let teamStatusIDs = [];
    let inviteStatusIDs = [];
    query
      .get("teamstatus")
      ?.split(",")
      ?.map((item) => {
        let statusID = MY_TEAMS_LEFT_PANEL?.find((team) => team?.name === item);
        teamStatusIDs?.push(statusID?.id);
      });
    selectedTeamStatusFilter.current = teamStatusIDs;
    dispatch(
      setSelectedFilter(teamStatusIDs[0] === 1 ? [1111] : teamStatusIDs)
    );
    query
      .get("invitestatus")
      ?.split(",")
      ?.map((item) => {
        let statusID = MY_TEAMS_INVITE_STATUS?.find(
          (team) => team?.name === item
        );
        inviteStatusIDs?.push(statusID?.id);
      });
    selectedInviteStatusFilter.current = inviteStatusIDs;
    dispatch(setInviteStatus(inviteStatusIDs));
  }, []);

  useEffect(() => {
    if (width > 1024) {
      setLeftExpand(true);
      setRightExpand(true);
    } else {
      setLeftExpand(false);
      setRightExpand(false);
    }
  }, [width]);
  useEffect(() => {
    if(params?.state?.value === "waiting"){

    }
  }, [params]);
  if (width === 0) {
    return null;
  }

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <Box
        item
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: `${width >= 768 && "10px"}`,
          position: `${width < 768 ? "absolute" : "relative"}`,
          // width: leftExpanded
          //   ? { xs: "150px", lg: "123px", mlg: "150px", xl: "150px" }
          //   : "30px",
          width: leftExpanded ? "125px" : "30px",
          minWidth: leftExpanded ? "125px" : "30px",
          zIndex: "30",
        }}
      >
        <Button
          variant="contained"
          sx={{
            position: "static",
            borderRadius: "0 10px 10px 0",
            minWidth: "30px",
            width: "30px",
            height: "45px",
          }}
          color="filterButtons"
          onClick={() => {
            setLeftExpand((prevState) => !prevState);
            setRightExpand((prevState) => !prevState);
            dispatch(setFilterExpanded(!filterExpanded));
          }}
        >
          {leftExpanded ? <LeftArrow /> : <RightArrow />}
        </Button>
        {leftExpanded && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              height: {
                xs: `calc(100vh - 95px)`,
                sm: `calc(100vh - 120px )`,
                md: `calc(100vh - 120px)`,
                lg: `calc(100vh - 135px)`,
                xl: `calc(100vh - 155px)`,
                xxl: "calc(100vh - 175px)",
              },
              width: `${width < 768 && "135px"}`,
              paddingTop: `${width < 768 && "10px"}`,
              background: `${width > 0 && width < 768 && "#EBECF3"}`,
              boxShadow: `${
                width > 0 && width < 768 && "0px 5px 10px #00000029"
              }`,
              opacity: `${width < 768 && "1"}`,
              borderRadius: `${width < 768 && "10px"}`,
              overflowX: "hidden",
              overflowY: leftExpanded ? "scroll" : "unset",
            }}
            className="filterSec"
          >
            <Paper
              sx={{
                background: "transparent",
                gap: "10px",
                display: "flex",
                flexDirection: "column",
                boxShadow: 0,
                //maxWidth: `${width < 768 && "150px"}`,
                maxWidth: "125px",
              }}
            >
              <ButtonPanel
                selected={selectedTeamStatusFilter?.current}
                color={"filterButtons"}
                panelData={MY_TEAMS_LEFT_PANEL}
                side="left"
                onChangeFilter={teamStatusFilter}
              />
            </Paper>
            <style>
              {`.filterSec::-webkit-scrollbar {
                      width: 0px !important;
                      background-color: #EFEEEE; /* Set the background color of the scrollbar */
                    }
                    .filterSec::-webkit-scrollbar-thumb {
                      background-color: white;
                      width: 0px;
                      box-shadow: 0px 3px 3px #00000029;
                      border-radius: 0px;
                    }`}
            </style>
          </Box>
        )}
      </Box>

      {selectedTeamStatusFilter?.current?.includes(6) ? (
        <ThemeProvider theme={talentListingDynamicTheme}>
          <HiredTalent
            selectedFilter={selectedTeamStatusFilter?.current}
            leftExpanded={leftExpanded}
            rightExpand={rightExpand}
          />
        </ThemeProvider>
      ) : (
        <Box
          item
          sx={{
            display: "flex",
            flexGrow: 1,
            alignItems: "center",
            flexDirection: "column",
            width: selectedTeamStatusFilter?.current?.includes(6)
              ? ""
              : `calc(100vw - 360px)`,
            padding: selectedTeamStatusFilter?.current?.includes(6)
              ? ""
              : "0 30px",
          }}
        >
          <Box
            sx={{ width: "100%", marginTop: width <= 768 ? "55px" : "55px" }}
          >
            <Outlet />
          </Box>
        </Box>
      )}

      <Box
        item
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: `${width >= 768 && "10px"}`,
          direction: "rtl",
          position: `${width < 768 ? "absolute" : "relative"}`,
          zIndex: "30",
          // width: rightExpand && "150px",
          right: "0",
          // width: leftExpanded
          //   ? { xs: "150px", mdl: "123px", mlg: "150px", xl: "150px" }
          //   : "30px",
          width: rightExpand ? "125px" : "30px",
          minWidth: rightExpand ? "125px" : "30px",
        }}
      >
        <Button
          variant="contained"
          sx={{
            position: "sticky",
            top: 0,
            borderRadius: "10px 0 0 10px",
            minWidth: "30px",
            width: "30px",
            height: "45px",
          }}
          color="filterButtons"
          onClick={() => {
            setLeftExpand((prevState) => !prevState);
            setRightExpand((prevState) => !prevState);
            dispatch(setFilterExpanded(!filterExpanded));
          }}
        >
          {rightExpand ? <RightArrow /> : <LeftArrow />}
        </Button>
        {rightExpand && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: `${width < 768 && "135px"}`,
              minWidth: "125px",
              paddingTop: `${width < 768 && "10px"}`,
              // paddingLeft: `${width < 768 && "10px"}`,
              background: `${width > 0 && width < 768 && "#EBECF3"}`,
              boxShadow: `${
                width > 0 && width < 768 && "0px 5px 10px #00000029"
              }`,
              opacity: `${width < 768 && "1"}`,
              borderRadius: `${width < 768 && "10px"}`,
              height: {
                xs: `calc(100vh - 95px)`,
                sm: `calc(100vh - 120px )`,
                md: `calc(100vh - 120px)`,
                lg: `calc(100vh - 135px)`,
                xl: `calc(100vh - 155px)`,
                xxl: "calc(100vh - 175px )",
              },
              overflowY: rightExpand ? "scroll" : "unset",
            }}
            className="rightfilterSec"
          >
            <style>
              {`.rightfilterSec::-webkit-scrollbar {
                      display:none;
                    }
                    `}
            </style>
            <Paper
              sx={{
                background: "transparent",
                boxShadow: 0,
                gap: "10px",
                display: "flex",
                flexDirection: "column",
                // maxWidth: `${width < 768 && "150px"}`,
                maxWidth: "125px",
              }}
            >
              <ButtonPanel
                selected={selectedInviteStatusFilter?.current}
                panelData={MY_TEAMS_INVITE_STATUS}
                side="right"
                color={"filterButtons"}
                onChangeFilter={inviteStatusFilter}
              />

              {selectedTeamStatusFilter?.current?.includes(6) && (
                <>
                  <ButtonPanel
                    selected={selectedTalent?.current}
                    topMargin={true}
                    panelData={allTalentTypes?.filter(
                      (item) => !item.hasOwnProperty("job_work_type_id")
                    )}
                    side="right"
                    color={"filterButtons"}
                    // onChangeFilter={onChangeFilterJobType}
                  />
                </>
              )}
            </Paper>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default MyTeams;
