import { Box, Grid } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { useTheme } from "@emotion/react";
import Button from "@mui/material/Button";
import { useDispatch } from "react-redux";
import {
  getAllIndustries,
  setAlert,
  setLoading,
} from "../../../redux/configSlice";
import {
  ALERT_TYPE,
  AUTHORIZED_TAB_ITEMS_CANDIDATE,
  AUTHORIZED_TAB_ITEMS_CANDIDATE_PROMOTER,
  AUTHORIZED_TAB_ITEMS_EMPLOYER,
  AUTHORIZED_TAB_ITEMS_EMPLOYER_PROMOTER,
  AUTHORIZED_TAB_ITEMS_PROMOTER,
  AUTHORIZED_TAB_ITEMS_RECRUITER,
  AUTHORIZED_TAB_ITEMS_RECRUITER_PROMOTER,
  BASIC_SEARCH,
  JOBS_RIGHT_COMPANIES_BUTTON,
  JOBS_RIGHT_STAGES_BUTTON_GROUP,
  PUBLIC_TAB_ITEMS,
} from "../../../utils/Constants";
import CustomDialog from "../../common/CustomDialog";
import ApplyJobs from "./ApplyJobs";
import LeftArrow from "../../common/LeftArrow";
import RightArrow from "../../common/RightArrow";

import { useSelector } from "react-redux";
import ButtonPanel from "../../common/ButtonPanel";
import { getAllTypes } from "../../../redux/allTypes";
import { getAllJobRoleType } from "../../../redux/jobRole";
import { getAllStages } from "../../../redux/stages";
import JobsDetailsPage from "../../common/JobsDetailsPage";
import NotLoggedIn from "../../common/NotLoggedIn";
import {
  getDecodedToken,
  getToken,
  setLocalStorage,
  useQuery,
} from "../../../utils/Common";
import Login from "../../login/login";
import Signup from "../../login/signup";
import {
  getFilteredJobs,
  setJobDetailsPageFilterData,
  setLoggedIn,
  updateAllJobs,
} from "../../../redux/guest/jobsSlice";
import useViewport from "../../common/ViewPort";
import ResendMailBox from "../../login/ResendMailBox";
import LandingShareingModal from "./LandingShareingModal";
import AllCompaniesFIlter from "./AllCompaniesFIlter";
import { setActiveTab, setIsAdmin, topBarCurrentTab } from "../../../redux/login/loginSlice";
import ForgotPasswordConfirmationModal from "../../login/ForgotPasswordConfirmationModal";

export default function JobsDetailPage() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let { pathname } = useLocation();
  const { width } = useViewport();
  const allIndustries = useSelector((state) => state?.config?.industries);
  const allJobTypes = useSelector((state) => state?.jobtype?.jobRoleType);
  const allStages = useSelector((state) => state?.configstages?.stages);
  const allTypes = useSelector((state) => state?.configAllTypes?.types);
  let decodedToken = getDecodedToken(getToken());
  const [isForgotPassword, setIsForgotPassowrd] = useState(false);
  const[uploadedFileName, setUploadedFileName]= useState(null)

  const [openNotLoggedIn, setOpenNotLoggedIn] = useState(false);

  const [resendMailBox, setResendMailBox] = useState(false);
  const [email, setEmail] = useState("");
  const [job, setJob] = useState([]);
  const [openApplyJobDialog, setopenApplyJobDialog] = useState(false);
  const [rightExpand, setRightExpand] = useState(true);
  const [leftExpanded, setLeftExpand] = useState(true);
  const [showSignup, setShowSignup] = useState(false);
  // const [isAdmin, setIsAdmin] = useState(false);
  // const [currentTabs, setcurrentTabs] = useState(PUBLIC_TAB_ITEMS);
  // const [activeTab, setActiveTab] = useState(pathname?.slice(1));
  const [showLandingModal, setShowLandingModal] = useState(false);
  const [showLogin, setShowLogin] = useState(false);
  const [noNavigate, setNoNavigate] = useState(false);
  const selectedIndustries = useRef([]);
  const [basicData, setBasicData] = useState(BASIC_SEARCH);
  const { companyid } = useParams();
  const [itemsFetched, setItemsFetched] = useState(false);
  const selectedJobTypes = useRef([]);
  const selectedAllStages = useRef([]);
  const selectedAllPost = useRef([]);
  const selectedAllTypes = useRef([]);
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [filtersType, setFiltersType] = useState([allTypes?.[0]?.id]);
  const [filtersJobType, setFiltersJobType] = useState([allJobTypes?.[0]?.id]);
  const [filtersJobStage, setFiltersJobStage] = useState([allStages?.[0]?.id]);
  const [favouriteFilter, setFavouriteFilter] = useState({
    recent: "",
    favourite: "",
    appliedJobs: "",
  });
  const [filters, setFilters] = useState([allIndustries?.[0]?.id]);
  const [searchedJobs, setSearchedJobs] = useState("");

  const query = useQuery();
  const [lastKey, setLastKey] = useState("");
  const [isFilterApplied, setIsFilterApplied] = useState(false);

  const [companySize, setCompanySize] = useState("");
  const [companyType, setCompanyType] = useState("");
  const [isLoggedIn, setIsLoggedIn] = useState(
    Boolean(localStorage.getItem("token"))
  );
  const { id } = useParams();
  let params = useParams();

  useEffect(() => {
    if (width >= 768) {
      setLeftExpand(true);
      setRightExpand(true);
    } else {
      setLeftExpand(false);
      setRightExpand(false);
    }
  }, [width]);
  const getTypes = async () => {
    dispatch(getAllTypes());
  };
  const getIndustries = async () => {
    dispatch(getAllIndustries());
  };
  const getJobTypes = async () => {
    dispatch(getAllJobRoleType());
  };
  const getStages = async () => {
    dispatch(getAllStages());
  };
  const onHandleApplyClose = () => {
    setopenApplyJobDialog(false);
  };

  const onClose = () => {
    setOpenNotLoggedIn(false);
  };

  const onHandleCloseSignup = () => {
    setShowSignup(false);
  };

  const onHandleClose = () => {
    setShowSignup(false);
  };
  // const onHandleLogin = async (payload) => {
  //   setShowLogin(false);
  //   const user = parseInt(getDecodedToken(payload?.token)?.data?.role_id);
  //   const isPromoter = parseInt(
  //     getDecodedToken(payload?.token)?.data?.promoter
  //   );
  //   localStorage.setItem("token", payload?.token);
  //   onHandleClose();
  //   const jwt = localStorage?.getItem("token");
  //   const parts = jwt?.split(".");
  //   if (parts?.length !== 3) {
  //     throw new Error("Invalid JWT");
  //   }
  //   const encodedPayload = parts[1];
  //   const decodedPayload = atob(encodedPayload);
  //   const payloadData = JSON.parse(decodedPayload);
  //   const profileCompletion = payloadData?.data?.profile_percent_complete;
  //   const isNavigate = payloadData?.data?.employerProfileFlag;

  //   let tabs;
  //   if (noNavigate) {
  //     setShowLandingModal(false);
  //     setNoNavigate(false);
  //     setShowLogin(false);
  //   }else{
  //     if (user === 1 || user === 2 || user === 8) {
  //       // setIsAdmin(true);
  //       dispatch(setIsAdmin(true));
  
  //       navigate("/admin/adminJobs/active-jobs", { replace: true });
  //     } else if (user === 4) {
  //       if (profileCompletion === 100) {
  //         tabs = isPromoter
  //           ? AUTHORIZED_TAB_ITEMS_EMPLOYER_PROMOTER
  //           : AUTHORIZED_TAB_ITEMS_EMPLOYER;
  //         navigate("/employer/my-jobs", { replace: true });
  //         dispatch(setActiveTab("/employer/my-jobs"));
  
  //         // setActiveTab("/employer/my-jobs");
  //       } else if (isNavigate) {
  //         tabs = isPromoter
  //           ? AUTHORIZED_TAB_ITEMS_EMPLOYER_PROMOTER
  //           : AUTHORIZED_TAB_ITEMS_EMPLOYER;
  //         navigate("/employer/my-jobs", { replace: true });
  //         dispatch(setActiveTab("/employer/my-jobs"));
  
  //         // setActiveTab("/employer/my-jobs");
  //       } else {
  //         tabs = isPromoter
  //           ? AUTHORIZED_TAB_ITEMS_EMPLOYER_PROMOTER
  //           : AUTHORIZED_TAB_ITEMS_EMPLOYER;
  //         navigate("/employer/my-profile", { replace: true });
  //         dispatch(setActiveTab("/employer/my-profile"));
  
  //         // setActiveTab("/employer/my-profile");
  //       }
  //     } else if (user === 5) {
  //       if (profileCompletion === 0) {
  //         tabs = AUTHORIZED_TAB_ITEMS_PROMOTER;
  //         navigate("/promoter/shared-jobs", { replace: true });
  //         dispatch(setActiveTab("/promoter/shared-jobs"));
  
  //         // setActiveTab("/promoter/shared-jobs");
  //       } else {
  //         tabs = AUTHORIZED_TAB_ITEMS_PROMOTER;
  //         navigate("/promoter/shared-jobs", { replace: true });
  //         dispatch(setActiveTab("/promoter/shared-jobs"));
  
  //         // setActiveTab("/promoter/shared-jobs");
  //       }
  //     } else if (user === 6) {
  //       if (profileCompletion === 100) {
  //         tabs = isPromoter
  //           ? AUTHORIZED_TAB_ITEMS_RECRUITER_PROMOTER
  //           : AUTHORIZED_TAB_ITEMS_RECRUITER;
  //         navigate("/recruiter/my-jobs", { replace: true });
  //         dispatch(setActiveTab("/recruiter/my-jobs"));
  
  //         // setActiveTab("/recruiter/my-jobs");
  //       } else if (isNavigate) {
  //         tabs = isPromoter
  //           ? AUTHORIZED_TAB_ITEMS_RECRUITER_PROMOTER
  //           : AUTHORIZED_TAB_ITEMS_RECRUITER;
  //         navigate("/recruiter/my-jobs", { replace: true });
  //         dispatch(setActiveTab("/recruiter/my-jobs"));
  
  //         // setActiveTab("/recruiter/my-jobs");
  //       } else {
  //         tabs = isPromoter
  //           ? AUTHORIZED_TAB_ITEMS_RECRUITER_PROMOTER
  //           : AUTHORIZED_TAB_ITEMS_RECRUITER;
  //         navigate("/recruiter/my-profile", { replace: true });
  //         dispatch(setActiveTab("/recruiter/my-profile"));
  
  //         // setActiveTab("/recruiter/my-profile");
  //       }
  //     } else {
  //       if (profileCompletion === 0) {
  //         tabs = isPromoter
  //           ? AUTHORIZED_TAB_ITEMS_CANDIDATE_PROMOTER
  //           : AUTHORIZED_TAB_ITEMS_CANDIDATE;
  //         navigate("/candidate/my-jobs", { replace: true });
  //         dispatch(setActiveTab("/candidate/my-jobs"));
  
  //         // setActiveTab("/candidate/my-jobs");
  //       } else {
  //         tabs = isPromoter
  //           ? AUTHORIZED_TAB_ITEMS_CANDIDATE_PROMOTER
  //           : AUTHORIZED_TAB_ITEMS_CANDIDATE;
  //         navigate("/candidate/my-jobs", { replace: true });
  //         dispatch(setActiveTab("/candidate/my-jobs"));
  
  //         // setActiveTab("/candidate/my-jobs");
  //       }
  //     }
  //   }
  
  //   dispatch(topBarCurrentTab(tabs));
  //   // setcurrentTabs(tabs);
  //   setIsLoggedIn(true);
  // }
  const onHandleLogin = async (payload) => {
    setShowLogin(false);
    const token = payload?.token;
    const decodedToken = getDecodedToken(token);
    const user = parseInt(decodedToken?.data?.role_id);
    const isPromoter = parseInt(decodedToken?.data?.promoter);
    const profileCompletion = decodedToken?.data?.profile_percent_complete;
    const isNavigate = decodedToken?.data?.employerProfileFlag;

    localStorage.setItem("token", token);
    onHandleClose();

    let tabs;

    if (noNavigate) {
      setShowLandingModal(false);
      setNoNavigate(false);
      setShowLogin(false);
    }else{
    if ([1, 2, 8].includes(user)) {
        dispatch(setIsAdmin(true));
        navigate("/admin/adminJobs/active-jobs", { replace: true });
    } else if (user === 4) {
        tabs = isPromoter ? AUTHORIZED_TAB_ITEMS_EMPLOYER_PROMOTER : AUTHORIZED_TAB_ITEMS_EMPLOYER;
        if (profileCompletion === 100 || isNavigate) {
            navigate("/employer/my-jobs", { replace: true });
            dispatch(setActiveTab("/employer/my-jobs"));
        } else {
            navigate("/employer/my-profile", { replace: true });
            dispatch(setActiveTab("/employer/my-profile"));
        }
    } else if (user === 5) {
        tabs = AUTHORIZED_TAB_ITEMS_PROMOTER;
        navigate("/promoter/shared-jobs", { replace: true });
        dispatch(setActiveTab("/promoter/shared-jobs"));
    } else if (user === 6) {
        tabs = isPromoter ? AUTHORIZED_TAB_ITEMS_RECRUITER_PROMOTER : AUTHORIZED_TAB_ITEMS_RECRUITER;
        if (profileCompletion === 100 || isNavigate) {
            navigate("/recruiter/my-jobs", { replace: true });
            dispatch(setActiveTab("/recruiter/my-jobs"));
        } else {
            navigate("/recruiter/my-profile", { replace: true });
            dispatch(setActiveTab("/recruiter/my-profile"));
        }
    } else {
        tabs = isPromoter ? AUTHORIZED_TAB_ITEMS_CANDIDATE_PROMOTER : AUTHORIZED_TAB_ITEMS_CANDIDATE;
        navigate("/candidate/my-jobs", { replace: true });
        dispatch(setActiveTab("/candidate/my-jobs"));
    }}

    dispatch(topBarCurrentTab(tabs));
    setIsLoggedIn(true);
};
  const handleCloseSignup = () => {
    setShowSignup(false);
  };

  const toggleForm = () => {
    setShowLogin((prevState) => !prevState);
    setShowSignup((prevState) => !prevState);
  };

  const handleCloseLogin = () => {
    setShowLogin(false);
  };

  const closeForgotPassword = () => {
    setShowSignup(true);
  };

  useEffect(() => {
    getIndustries();
    getJobTypes();
    getStages();
    getTypes();
  }, []);

  // useEffect(() => {
  //   if (localStorage.getItem("firstTimePop")) {
  //     setShowLandingModal(false);
  //   } else if (!decodedToken?.data && !localStorage.getItem("firstTimePop")) {
  //     localStorage.setItem("firstTimePop", "firstTimePop");
  //     setShowLandingModal(true);
  //   }
  // }, []);

  // useEffect(() => {
  //   if (params?.id && !params?.shareToken && !decodedToken?.data) {
  //     setShowLandingModal(true);
  //   }
  // }, [params, decodedToken]);

  useEffect(() => {
    if (params?.shareToken) {
      setShowLandingModal(true);
    }
  }, [params?.shareToken]);
  const handleLetsGo = () => {
    setShowLandingModal(false);
  };
  const handleLogin = () => {
    setShowLandingModal(false);
    setNoNavigate(true);
    setShowLogin(true);
  };
  const getJobList = async (
    selectedFilters = [],
    jobtype = [],
    jobstage = [],
    personalityType = [],
    lastkeyy = 0,
    job_title = "",
    filteralltype = favouriteFilter
  ) => {
    dispatch(setLoading({ value: true, apiName: "getJobList" }));

    let data = {
      selectedFilters:
        selectedFilters?.[0] !== 1111 ? selectedFilters?.toString() : "",
      lastKey: lastkeyy?.toString(),
      jobtype: jobtype?.[0] !== 1111 ? jobtype?.toString() : "",
      jobstage: jobstage?.[0] !== 1111 ? jobstage?.toString() : "",
      personalityType:
        personalityType?.[0] !== 1111 ? personalityType?.toString() : "",
      favourites: filteralltype?.favourite || "",
      recentjob: filteralltype?.recent || "",
      appliedjob: filteralltype?.appliedJobs || "",
      company_size:
        query.get("companySize") !== null
          ? decodeURIComponent(query.get("companySize"))
          : companySize?.toString(),
      company_type:
        query.get("companyType") !== null
          ? decodeURIComponent(query.get("companyType"))
          : companyType?.toString(),
      ...basicData,
      // company_id: companyid ? [companyid] : "",
      job_title: query.get("job_title")
        ? query.get("job_title")
        : basicData?.job_title,
      query: query.get("query") ? query.get("query") : "",

      town_id: query.get("location")
        ? query.get("location")
        : query.size < 1
        ? ""
        : basicData?.town_id,
      region_id: query.get("region_id")
        ? query.get("region_id")
        : query.size < 1
        ? ""
        : basicData?.region_id,
      tag_id: query.get("tag_id")
        ? query.get("tag_id")
        : query.size < 1
        ? ""
        : basicData?.tag_id,
      tool_id: query.get("tool_id")
        ? query.get("tool_id")
        : query.size < 1
        ? ""
        : basicData?.tool_id,
      company_id:
        query.get("company_id") || companyid
          ? query.get("company_id") || Number(companyid)
          : query.size < 1
          ? ""
          : basicData?.company_id,
      currency_id: query.get("currency_id")
        ? query.get("currency_id")
        : query.size < 1
        ? ""
        : basicData?.currency_id,
      highest_qualification_id: query.get("highest_qualification_id")
        ? query.get("highest_qualification_id")
        : query.size < 1
        ? ""
        : basicData?.highest_qualification_id,
      salary: query.get("salary")
        ? query.get("salary")
        : query.size < 1
        ? ""
        : basicData?.salary,
      experience: query.get("experience")
        ? query.get("experience")
        : query.size < 1
        ? ""
        : basicData?.experience,
      // limit: 20,
      decodedToken: decodedToken,
    };

    const { payload } = await dispatch(getFilteredJobs(data));

    if (payload?.status === "success") {
      if (payload?.data?.length <= 0) {
        // setShowBox(true);
      } else {
        // setShowBox(false);
        setLastKey(payload?.pageNumber + 1);
        setItemsFetched(true);
      }
      // setIsDataLoading(false);
      dispatch(setLoading({ value: false, apiName: "getJobList" }));
    }
  };

  const handleFilterSelection = (paramType, filterName) => {
    // Toggle filter selection
    if (
      filterName[0] === "all job types" ||
      filterName[0] === "all industries" ||
      filterName[0] === "all stages" ||
      filterName[0] === "all activity" ||
      filterName[0] === "all types"
    ) {
      filterName[0] === "all industries" && (selectedIndustries.current = []);
      filterName[0] === "all job types" && (selectedJobTypes.current = []);
      filterName[0] === "all stages" && (selectedAllStages.current = []);
      filterName[0] === "all activity" && (selectedAllPost.current = []);
      filterName[0] === "all types" && (selectedAllTypes.current = []);
      const queryParams = new URLSearchParams(window.location.search);
      queryParams.delete(paramType); // Remove the parameter from the query string
      // navigate(`/jobs?${queryParams.toString()}`);
      return;
    } else {
      const updatedFilters = selectedFilters?.includes(filterName)
        ? selectedFilters?.filter((filter) => filter !== filterName)
        : [filterName];
      const queryParams = new URLSearchParams(window.location.search);
      queryParams.set(paramType, updatedFilters?.join(","));
      // navigate(`${window.location.pathname}?${queryParams.toString()}`);
      window.open(`/jobs?${queryParams.toString()}`);
      setSelectedFilters(updatedFilters);
    }
  };

  const onChangeFilter = (selectedFilter) => {
    // dispatch(setJobDetailsPageFilterData())
    localStorage.setItem(
      "detailsFilterIndustry",
      JSON.stringify(selectedFilter)
    );
    setIsFilterApplied(true);
    let industry = [];
    selectedFilter?.map((type) => {
      let selectedJobType = allIndustries?.find(
        (jobtype) => jobtype?.id === type
      );
      industry?.push(selectedJobType?.name);
    });
    handleFilterSelection("filter", industry);
    dispatch(updateAllJobs());
    setLastKey("");
    setFilters(selectedFilter);
    getJobList(
      selectedFilter,
      filtersJobType,
      filtersJobStage,
      filtersType,
      "",
      searchedJobs,
      favouriteFilter
    );
  };
  const onChangeFilterJobType = (selectedFilter) => {
    setIsFilterApplied(true);
    let jobs = [];
    selectedFilter?.map((type) => {
      let selectedJobType = allJobTypes?.find(
        (jobtype) => jobtype?.id === type
      );
      jobs?.push(selectedJobType?.name?.replace("Crayon", "")?.trim());
    });
    dispatch(updateAllJobs());
    setLastKey("");
    setFiltersJobType(jobs);
    handleFilterSelection("jobType", jobs);
    getJobList(
      filters,
      jobs,
      filtersJobStage,
      filtersType,
      "",
      searchedJobs,
      favouriteFilter
    );
  };
  const handleDropDownFilters = (selectedFilter) => {
    setIsFilterApplied(true);
    if (selectedFilter === 1) {
      setCompanySize("");
      setCompanyType("");
    }
  };
  const onChangeFilterJobStage = (selectedFilter) => {
    localStorage.setItem("detailsFilterStage", JSON.stringify(selectedFilter));
    setIsFilterApplied(true);
    let stage = [];
    selectedFilter?.map((type) => {
      let selectedJobType = allStages?.find(
        (stagetype) => stagetype?.id === type
      );
      stage?.push(selectedJobType?.name);
    });
    handleFilterSelection("stage", stage);
    dispatch(updateAllJobs());
    setLastKey("");
    setFiltersJobStage(selectedFilter);
    getJobList(
      filters,
      filtersJobType,
      selectedFilter,
      filtersType,
      "",
      searchedJobs,
      favouriteFilter
    );
  };
  const onChangefavourite = (selectedFilter) => {
    setIsFilterApplied(true);
    if (
      (decodedToken && decodedToken?.data?.role_id !== 4) ||
      selectedFilter?.includes(2) ||
      selectedFilter?.includes(1111)
    ) {
      let posts = [];
      selectedFilter?.map((types) => {
        let selectedJobType = JOBS_RIGHT_STAGES_BUTTON_GROUP?.find(
          (type) => type?.id === types
        );
        posts?.push(selectedJobType?.name);
      });
      handleFilterSelection(
        "activity",
        selectedFilter?.includes(1111) ? ["all activity"] : posts
      );
      dispatch(updateAllJobs());
      setLastKey("");
      const allTypeFilter = {
        recent: selectedFilter?.includes(2) ? true : "",
        favourite: selectedFilter?.includes(3) ? true : "",
        appliedJobs: selectedFilter?.includes(4) ? true : "",
      };
      setFavouriteFilter(allTypeFilter);
      getJobList(
        filters,
        filtersJobType,
        filtersJobStage,
        filtersType,
        "",
        searchedJobs,
        allTypeFilter
      );
    } else {
      handleFilterSelection("activity", ["all activity"]);
      dispatch(
        setAlert({
          show: true,
          type: ALERT_TYPE?.ERROR,
          msg:
            decodedToken?.data?.role_id !== 4
              ? "Login first to apply this filter"
              : "Login as candidate to apply this filter",
        })
      );
    }
  };
  const onChangeFilterType = (selectedFilter) => {
    localStorage.setItem("detailsFilterType", JSON.stringify(selectedFilter));
    setIsFilterApplied(true);
    let selectedtypes = [];
    selectedFilter?.map((types) => {
      let selectedJobType = allTypes?.find((type) => type?.id === types);
      selectedtypes?.push(selectedJobType?.name);
    });
    handleFilterSelection("Type", selectedtypes);
    dispatch(updateAllJobs());
    setLastKey("");
    setFiltersType(selectedFilter);
    getJobList(
      filters,
      filtersJobType,
      filtersJobStage,
      selectedFilter,
      "",
      searchedJobs,
      favouriteFilter
    );
  };

  useEffect(() => {
    if (params?.shareToken) {
      setLocalStorage(
        "ShareToken",
        JSON.stringify({ shareToken: params?.shareToken, id: params?.id })
      );
    }
  }, [params]);

  if (width === 0) {
    return null;
  }
  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexWrap: "nowrap",
        }}
      >
        <Grid
          item
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: `${width >= 768 && "10px"}`,
            position: `${width < 768 ? "absolute" : "relative"}`,
            // width: leftExpanded
            //   ? { xs: "150px", mdl: "123px", mlg: "150px", xl: "150px" }
            //   : "30px",
            width: leftExpanded ? "125" : "30px",
            minWidth: leftExpanded ? "125px" : "30px",
            // width: leftExpanded && "150px",
            zIndex: "30",
          }}
        >
          <Button
            variant="contained"
            sx={{
              position: "static",
              borderRadius: "0 10px 10px 0",
              minWidth: "30px",
              width: "30px",
              height: "45px",
            }}
            color="filterButtons"
            onClick={() => {
              setLeftExpand((prevState) => !prevState);
            }}
          >
            {leftExpanded ? <LeftArrow /> : <RightArrow />}
          </Button>
          {leftExpanded && (
            <Box
              sx={{
                display: "flex",
              flexDirection: "column",
              gap: "10px",
              height: {
                xs: `calc(100vh - 95px)`,
                sm: `calc(100vh - 120px )`,
                md: `calc(100vh - 120px)`,
                lg: `calc(100vh - 135px)`,
                xl: `calc(100vh - 155px)`,
                xxl: "calc(100vh - 175px)",
              },
              width: `${width < 768 && "135px"}`,
              paddingTop: `${width < 768 && "10px"}`,
              maxWidth: "125px",
              background: `${width > 0 && width < 768 && "#EBECF3"}`,
              boxShadow: `${
                width > 0 && width < 768 && "0px 5px 10px #00000029"
              }`,
              opacity: `${width < 768 && "1"}`,
              borderRadius: `${width < 768 && "10px"}`,
              overflowX: "hidden",
              overflowY: leftExpanded ? "scroll" : "unset",
              }}
              className="filterSec"
            >
              <ButtonPanel
                selected={selectedIndustries?.current}
                panelData={allIndustries}
                side="left"
                color="filterButtons"
                onChangeFilter={onChangeFilter}
              />
              <Button
                variant="contained"
                sx={{
                  position: "static",
                  borderRadius: "0 10px 10px 0",
                  minWidth: "30px",
                  width: "30px",
                  height: "45px",
                }}
                color="filterButtons"
                onClick={() => {
                  setLeftExpand((prevState) => !prevState);
                }}
              >
                {leftExpanded ? <LeftArrow /> : <RightArrow />}
              </Button>
              <style>
                {`.filterSec::-webkit-scrollbar {
                      width: 0px !important;
                      background-color: #EFEEEE; /* Set the background color of the scrollbar */
                    }
                    .filterSec::-webkit-scrollbar-thumb {
                      background-color: white;
                      width: 0px;
                      box-shadow: 0px 3px 3px #00000029;
                      border-radius: 0px;
                    }`}
              </style>
            </Box>
          )}
        </Grid>

        <Grid
          item
          xs={12}
          sm={6}
          md={8}
          lg={9}
          xl={10}
          sx={{
            paddingTop: 0,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: {
              xs: `calc(100vw - 306px)`,
              lg: `calc(100vw - 306px)`,
              mlg: `calc(100vw - 360px)`,
            },
          }}
          flexGrow="1 !important"
        >
          <Box
            sx={{
              // paddingX: "30px",
              paddingX: `${(width < 768) & (width !== 0) ? "15px" : "30px"}`,
              height: {
                // xs: `calc(100vh - 210px)`,
                xs: `calc(100vh - 80px)`,
                lg: `calc(100vh - 80px)`,
                xl: `calc(100vh - 100px)`,
              },
              overflow: "scroll",
              paddingTop: "55px",
              paddingBottom: "40px",
              width: "100%",
              boxSizing: "border-box",
            }}
            className="jobDetailSec"
          >
            <JobsDetailsPage
              jobId={id}
              theme={theme}
              setOpenNotLoggedIn={setOpenNotLoggedIn}
              setShowLogin={setShowLogin}
              showLogin={showLogin}
            />
          </Box>
        </Grid>

        <Grid
          item
          sx={{
            // display:`${width < 768 ?"none":"flex"}`,
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            direction: "rtl",
            // width: rightExpand
            //   ? { xs: "123px", lg: "123px", mlg: "150px", xl: "150px" }
            //   : "30px",

            // display:`${width < 768 ?"absolute":"flex"}`,
            position: `${width < 768 ? "absolute" : "relative"}`,
            right: "0",
            // width: leftExpanded
            //   ? { xs: "123px", lg: "123px", mlg: "150px", xl: "150px" }
            //   : "30px",
            width: rightExpand ? "125px" : "30px",
            minWidth: rightExpand ? "125px" : "30px",
            zIndex: "30",
          }}
        >
          <Button
            variant="contained"
            sx={{
              position: "sticky",
              top: 0,
              borderRadius: "10px 0 0 10px",
              minWidth: "30px",
              width: "30px",
              height: "45px",
            }}
            color="filterButtons"
            onClick={() => {
              setRightExpand((prevState) => !prevState);
            }}
          >
            {rightExpand ? <RightArrow /> : <LeftArrow />}
          </Button>
          {rightExpand && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "10px",
                height: {
                  xs: `calc(100vh - 95px)`,
                  sm: `calc(100vh - 120px )`,
                  md: `calc(100vh - 120px)`,
                  lg: `calc(100vh - 135px)`,
                  xl: `calc(100vh - 155px)`,
                  xxl: "calc(100vh - 175px)",
                },
                overflowY: rightExpand ? "scroll" : "unset",
              }}
              className="rightfilterSec"
            >
              <style>
                {`.rightfilterSec::-webkit-scrollbar {
                       display: none;/* Set the background color of the scrollbar */
                    }`}
              </style>
              <ButtonPanel
                selected={selectedJobTypes.current}
                topMargin={true}
                panelData={allJobTypes}
                side="right"
                color="filterButtons"
                onChangeFilter={onChangeFilterJobType}
              />
              <AllCompaniesFIlter
                panelData={JOBS_RIGHT_COMPANIES_BUTTON}
                setCompanySize={setCompanySize}
                setCompanyType={setCompanyType}
                companySize={companySize}
                companyType={companyType}
                onChangeFilter={handleDropDownFilters}
                side="right"
                color="filterButtons"
                type="detailPage"
              />
              <ButtonPanel
                selected={selectedAllStages.current}
                panelData={allStages}
                side="right"
                color="filterButtons"
                onChangeFilter={onChangeFilterJobStage}
              />
              <ButtonPanel
                selected={selectedAllPost.current}
                panelData={JOBS_RIGHT_STAGES_BUTTON_GROUP}
                onChangeFilter={onChangefavourite}
                side="right"
                color="filterButtons"
              />
              <ButtonPanel
                selected={selectedAllTypes.current}
                panelData={allTypes}
                side="right"
                color="filterButtons"
                onChangeFilter={onChangeFilterType}
              />
              <Button
                variant="contained"
                sx={{
                  position: "sticky",
                  top: 0,
                  borderRadius: "10px 0 0 10px",
                  minWidth: "30px",
                  width: "30px",
                  height: "45px",
                }}
                color="filterButtons"
                onClick={() => {
                  setRightExpand((prevState) => !prevState);
                }}
              >
                {rightExpand ? <RightArrow /> : <LeftArrow />}
              </Button>
            </Box>
          )}
        </Grid>
      </Box>
      <CustomDialog
        show={openApplyJobDialog}
        hideButton={false}
        onDialogClose={onHandleApplyClose}
        dialogWidth="xs"
        padding={0}
        showFooter={false}
        isApplyJob
        backgroundColor={theme.palette.applyBoxBackground}
      >
        <ApplyJobs
          jobId={job?.job_id}
          setopenApplyJobDialog={setopenApplyJobDialog}
          jobVideoRequired={job?.is_video_require}
        />
      </CustomDialog>

      <CustomDialog
        show={openNotLoggedIn}
        hideButton={false}
        onDialogClose={onClose}
        dialogWidth="xs"
        padding={0}
        showFooter={false}
      >
        <NotLoggedIn
          setOpenNotLoggedIn={setOpenNotLoggedIn}
          onClose={onClose}
        />
      </CustomDialog>
      {showLogin && (
        <Login
          handleLogin={onHandleLogin}
          openFunc={showLogin}
          toggleForm={toggleForm}
          closeFunc={handleCloseLogin}
          handleOpenLogin={setShowLogin}
          handleOpenSignUp={closeForgotPassword}
          handleForgotPassword={closeForgotPassword}
          setResendMailBox={setResendMailBox}
          setEmail={setEmail}
          uploadedFileName={uploadedFileName}
          handleConfirmPasswordChange={()=>setIsForgotPassowrd(true)}
        />
      )}
      <ResendMailBox
        setResendMailBox={setResendMailBox}
        email={email}
        open={resendMailBox}
        handleClose={() => setResendMailBox(false)}
      />

      {showSignup && (
        <Signup
          onDialogClose={onHandleCloseSignup}
          toggleForm={toggleForm}
          openFunc={showSignup}
          closeFunc={handleCloseSignup}
          handleOpenSignUp={setShowSignup}
          uploadedFileName={uploadedFileName}
        />
      )}
      {(params?.shareToken && showLandingModal) && (
        <LandingShareingModal
          handleOpen={showLandingModal}
          handleClose={() => setShowLandingModal(false)}
          handleLogin={handleLogin}
          handleLetsGo={handleLetsGo}
        />
      )}
        {isForgotPassword && (
        <ForgotPasswordConfirmationModal
          openFunc={isForgotPassword}
          closeFunc={() => setIsForgotPassowrd(false)}
        />
      )}
    </>
  );
}
