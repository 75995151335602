import {
  Box,
  Button,
  Card,
  CircularProgress,
  IconButton,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import locale from "../../../i18n/locale";
import close from "../../../assets/Padding Included/Black_Close.svg";
import trackIcon from "../../../assets/Padding Excluded/TrackIcon.svg";
import profile from "../../../assets/Crayon_Favicon.svg";
import InfiniteScroll from "react-infinite-scroll-component";
import { renderColor } from "../../../utils/Common";
import { ALERT_TYPE } from "../../../utils/Constants";
import { setAlert } from "../../../redux/configSlice";
import { useDispatch } from "react-redux";
import { getPromoterReferralsList } from "../../../redux/candidate/candidateJobs";
import { Link } from "react-router-dom";
import CloseIcon from "../../common/CloseIcon";

export default function ReferralsCardBack({
  index,
  job,
  setisFlipped,
  isFlipped,
  handleReviewVideoDialog,
  cardWidth,
}) {
  const i18n = locale.en;
  const theme = useTheme();
  const dispatch = useDispatch();

  const [referralsData, setReferralsData] = useState([]);

  const [lastKey, setLastKey] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [count, setCount] = useState(0);

  const getReferralsList = async (lastkey) => {
    setIsLoading(true);
    try {
      const data = {
        lastKey: lastkey,
        user_id: job?.user_id,
      };
      const { payload } = await dispatch(getPromoterReferralsList(data));
      if (payload?.status === "success") {
        setCount(payload?.count);
        if (payload?.data?.length === 0) {
          setReferralsData([]);
        } else {
          if (payload?.pagenumber === 0) {
            setLastKey(payload?.pagenumber + 1);
            setReferralsData(payload?.data);
          } else {
            setLastKey(payload?.pagenumber + 1);
            setReferralsData((prevState) => [...prevState, ...payload?.data]);
          }
        }
        setIsLoading(false);
      } else if (payload?.status === "fail") {
        setIsLoading(false);
        return;
      } else {
        setIsLoading(false);
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE?.ERROR,
            msg: payload?.message,
          })
        );
      }
    } catch (error) {
      setIsLoading(false);
      dispatch(
        setAlert({
          show: true,
          type: ALERT_TYPE?.ERROR,
          msg: "Something went wrong! please relaod the window",
        })
      );
    }
  };

  useEffect(() => {
    if (isFlipped && referralsData?.length === 0) {
      getReferralsList(0);
    }
  }, [isFlipped]);

  return (
    <Card
      sx={{
        borderRadius: "25px",
        position: "relative",
        boxShadow: "0px 5px 10px #00000029",
        height: "",
      }}
      width={cardWidth}
      className="cardboxdetail"
    >
      <Box sx={{ display: "flex", flexDirection: "column", gap: "10px" }}>
        <Box sx={{ marginBottom: "15px" }}>
          <Button
            variant="contained"
            color="referredButton"
            sx={{
              height: "45px",
              borderRadius: "25px 0px 10px 0px",
              color: "white",
              fontWeight: 400,
              ":hover": {
                boxShadow: "none !important",
              },
            }}
            startIcon={
              <Box
                component={"img"}
                sx={{
                  height: 20,
                  width: 20,
                }}
                src={trackIcon}
              />
            }
          >
            {i18n["promoter.referraljobstatus"]}({count})
          </Button>
          <Box
            onClick={() => {
              setisFlipped(false);
            }}
          >
            <CloseIcon />
          </Box>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            overflowY: "auto",
            gap: "10px",
            paddingRight: "10px",
            marginBottom: "25px",
            "::-webkit-scrollbar": {
              height: "5px",
              background: "#DCDEEB",
              display: "unset !important",
            },
          }}
          height={{ sm: `calc(565px - 155px)`, xl: `calc(576px - 155px)` }}
          className="match-scroll-container"
          id="matchTaleneList"
        >
          <InfiniteScroll
            style={{
              overflowX: "hidden",
              scrollbarWidth: "thin",
              display: "flex",
              flexDirection: "column",
              gap: "12px",
            }}
            dataLength={referralsData?.length || 0}
            next={() => getReferralsList(lastKey ? lastKey : "")}
            hasMore={true}
            loader={
              isLoading && (
                <Box
                  style={{
                    margin: "15px 0 0 0",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <CircularProgress color="inherit" size={20} />
                </Box>
              )
            }
            scrollableTarget="matchTaleneList"
            scrollThreshold={"50px"}
          >
            {referralsData?.length > 0
              ? referralsData?.map((item) => {
                  return (
                    <Box
                      sx={{
                        display: "flex",
                        paddingX: "18px",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Box
                        sx={{ width: "240px", display: "flex", gap: "10px" }}
                      >
                        <Link
                          to={`/jobs/company/${job?.company_id}`}
                          target={"_blank"}
                          style={{
                            textDecoration: "none",
                            color: theme.palette.black,
                          }}
                        >
                          <Box
                            component={"img"}
                            src={
                              item?.profile_url !== "NO URL"
                                ? item?.profile_url
                                : profile
                            }
                            sx={{
                              height: "50px",
                              width: "50px",
                              borderRadius: "10px",
                            }}
                          />
                        </Link>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: "4px",
                            width: "100%",
                          }}
                        >
                          <Tooltip
                            disableInteractive
                            title={item?.title}
                            placement="top-end"
                          >
                            <Link
                              to={
                                item?.post_incognito
                                  ? `/jobs/${item?.country_name?.replace(
                                      /\s|\//g,
                                      ""
                                    )}/${item?.town_name?.replace(
                                      /\s|\//g,
                                      ""
                                    )}/${item?.title?.replace(/\s|\//g, "")}/${
                                      item?.job_id
                                    }`
                                  : `/jobs/${item?.country_name?.replace(
                                      /\s|\//g,
                                      ""
                                    )}/${item?.town_name?.replace(
                                      /\s|\//g,
                                      ""
                                    )}/${item?.CompanyName?.replace(
                                      /\s|\//g,
                                      ""
                                    )}/${item?.title?.replace(/\s|\//g, "")}/${
                                      item?.job_id
                                    }`
                              }
                              target={"_blank"}
                              style={{
                                textDecoration: "none",
                                color: theme.palette.black,
                                maxWidth: "96%",
                              }}
                            >
                              <Typography
                                sx={{
                                  maxWidth: "190px",
                                  fontSize: "15px",
                                  fontWeight: theme.typography.Bold,
                                }}
                                noWrap
                              >
                                {item?.title || "-"}
                              </Typography>
                            </Link>
                          </Tooltip>
                          {item?.bounty_amount && (
                            <Button
                              variant="contained"
                              color={"basicColor"}
                              sx={{
                                borderRadius: "5px",
                                boxShadow: "none",
                                height: "25px",
                                minWidth: "fit-content",
                                padding: "0px 6px",
                              }}
                            >
                              {item?.bounty_amount}
                            </Button>
                          )}
                        </Box>
                      </Box>
                      <Button
                        variant="contained"
                        sx={{
                          borderRadius: "5px",
                          boxShadow: "none",
                          backgroundColor:
                            theme.manageTalent?.[renderColor(item?.stage_name)]
                              ?.main,
                          "&:hover": {
                            backgroundColor:
                              theme.manageTalent?.[
                                renderColor(item?.stage_name)
                              ]?.main,
                          },
                          fontSize: "12px",
                          fontWeight: theme.typography.Bold,
                          height: "25px",
                          width: "fit-content",
                          padding: "0px 6px",
                        }}
                      >
                        {item?.stage_name || "-"}
                      </Button>
                    </Box>
                  );
                })
              : !isLoading && (
                  <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontWeight: theme.typography.Bold,
                      }}
                    >
                      No Data
                    </Typography>
                  </Box>
                )}
          </InfiniteScroll>
        </Box>
      </Box>

      <Box
        flexWrap={"nowrap"}
        alignItems="center"
        overflow={"hidden"}
        sx={{
          width: "100%",
          borderRadius: "0 0 25px 25px",
          height: "60px",
        }}
      >
        <Button
          variant="contained"
          sx={{
            borderRadius: "0 0 25px 0",
            width: "100%",
            height: "100%",
            fontSize: "14px",
            fontWeight: "bold",
            ":hover": {
              boxShadow: 0,
            },
            ".MuiButton-startIcon": {
              marginRight: "0px !important",
              marginLeft: 0,
            },
            padding: "0px !important",
            boxShadow: "none !important",
            gap: 1,
          }}
          color="basicColor"
          onClick={() => setisFlipped(false)}
        >
          back
        </Button>
      </Box>
    </Card>
  );
}
