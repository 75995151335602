import {
  Box,
  Button,
  InputBase,
  Paper,
  Popover,
  useTheme,
} from "@mui/material";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { debounce, getDecodedToken, getToken } from "../../../../utils/Common";
import upClose from "../../../../assets/Black_Up_Close.svg";
import downClose from "../../../../assets/Black_Down_Open.svg";
import { useDispatch } from "react-redux";
import SolutionTypeModal from "./SolutionTypeModal";
import CustomSelect from "../../../candidate/myCV/CustomSelect";

export default function SolutionsFilter({
  title,
  handleInputSearch,
  total,
  filtersValue,
  setFiltersValue,
  setOpenFilter,
  openFilter,
  isAddSolutionModal,
  setIsAddSoutionModal,
  solutionTypeList,
  getSolution,
  setEditSolutionData,
  editSolutionData,
  setIsEdit,
  getSolutionList
}) {
  const theme = useTheme();
  const decodedToken = getDecodedToken(getToken());
  const dispatch = useDispatch();
  const solutionTypeRef = useRef(null);
  const [solutionTypeBtn, setSolutionTypeBtn] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const anchorElSolutionTypes = Boolean(solutionTypeBtn);
  const userType = [
    { label: "Employers", value: "1" },
    { label: "Recruiters", value: "2" },
    { label: "Candidates", value: "3" },
    { label: "Promoters", value: "4" },
  ];
  let selectStyle = {
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        color: isSelected ? "#fff" : "black",
        ":active": {
          ...styles[":active"],
        },
      width: `100%`,
      };
    },
    menu: (provided) => ({
      ...provided,
      zIndex: 9999,
      width:"100%"
    }),
    control: (baseStyles) => ({
      ...baseStyles,
      boxShadow: "none",
      borderRadius: "10px",
      width: `100%`,
    }),
  };
  const handleOnSelectChange = (ind, newValue, val, inputId) => {
    const updatedData = {
      ...filtersValue,
      [inputId]: newValue,
    };
    setFiltersValue(updatedData);
    getSolutionList(0,updatedData["user_types"]?.value,updatedData["solution_types"]?.label,updatedData["quickSearch"])
  };
 
  const debouncedHandleOnSelectInputChange = useCallback(
    debounce(
      async (newValue, type) => {
        let params = {
          title: newValue || "",
          limit: 200,
        };
        if (newValue) {
          try {
            if (type === "solution_types") {
            }
          } catch (error) {}
        }
      },
      750,
      setIsLoading
    ),
    []
  );

  const handleOnSelectInputChange = (newValue, inputId) => {
    debouncedHandleOnSelectInputChange(newValue, inputId);
  };

  return (
    <>
      <Paper
        sx={{
          // p: "20px",
          borderRadius: "25px",
          mt: "55px",
          position: "absolute",
          left: "110px",
          zIndex: 1000,
          width: `calc(100% - 150px)`,
        }}
      >
        <Button
          variant="contained"
          color="talentButton"
          sx={{
            width: "150px",
            height: "45px",
            borderRadius: "25px 0 25px 0",
            padding: "0px !important",
          }}
        >
          {title} ({total})
        </Button>
        <Box sx={{ padding: "12px 14px 8px 14px" }}>
          <Box sx={{ display: "flex", marginBottom: "10px" }} gap={3}>
            <Paper
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "40px",
                borderRadius: "10px",
                boxShadow: "none",
                border: `1px solid ${theme.palette.grayBorder}`,
                width: "50%",
              }}
            >
              <InputBase
                id="keyword"
                onChange={(event) => {
                  handleInputSearch(event);
                }}
                defaultValue={filtersValue?.quickSearch}
                placeholder={"Enter quick search term..."}
                sx={{
                  ml: 2,
                  mr: 2,
                  width: "100%",
                }}
              />
            </Paper>
          </Box>
          {openFilter === true && (
            <Box sx={{width:"50%"}}>
              <Box sx={{ marginBottom: "10px", width:"100%" }}>
                <CustomSelect
                  isClearable={true}
                  onMenuOpen={()=>{}}
                  closeMenuOnSelect={false}
                  variant="default"
                  onInputChange={handleOnSelectInputChange}
                  onChange={handleOnSelectChange}
                  // placeholder={"Search or add job title"}
                  placeholder={"Select Users Types"}
                  inputId="user_types"
                  name="user_types"
                  value={
                    filtersValue?.user_types?.value
                      ? filtersValue?.user_types
                      : null
                  }
                  isLoading={false}
                  customStyle={selectStyle}
                  filterOption={"filter"}
                  option={userType}
                />
              </Box>
              <Box sx={{width:"100%"}}>
                <CustomSelect
                  onMenuOpen={()=>{}}
                  isClearable={true}
                  closeMenuOnSelect={false}
                  variant="default"
                  isMulti={true}
                  onInputChange={handleOnSelectInputChange}
                  onChange={handleOnSelectChange}
                  // placeholder={"Search or add job title"}
                  placeholder={"Select Solution Types"}
                  inputId="solution_types"
                  name="solution_types"
                  value={
                    filtersValue?.solution_types?.value > 0
                      ? filtersValue?.solution_types
                      : null
                  }
                
                  isLoading={false}
                  customStyle={selectStyle}
                  option={solutionTypeList?.map((item) => ({
                    value: item?.solution_type_id,
                    label: item?.name,
                  }))}
                />
              </Box>
            </Box>
          )}
        </Box>
        {(decodedToken?.data?.role_id === 1 ||
          decodedToken?.data?.role_id === 2) && (
          <Box
            sx={{
              display: "flex",
              alignItems: "flex-end",
              flexDirection: "column",
            }}
          >
            <Button
              ref={solutionTypeRef}
              onClick={() => setSolutionTypeBtn(solutionTypeRef?.current)}
              variant="contained"
              color="recentButton"
              sx={{
                width: "150px",
                height: "45px",
                borderRadius: anchorElSolutionTypes
                  ? "0 !important"
                  : "25px 0 0 0",
                padding: "0px !important",
              }}
              // onClick={() => setOpen(true)}
            >
              solution types
            </Button>
            <Popover
              open={anchorElSolutionTypes}
              anchorEl={solutionTypeBtn}
              onClose={() => setSolutionTypeBtn(null)}
              anchorOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              sx={{
                "& .MuiPopover-paper": {
                  borderRadius: "25px 0 25px 25px !important",
                  width: "400px !important",
                },
              }}
            >
              <SolutionTypeModal
                getSolution={getSolution}
                solutionTypeList={solutionTypeList}
                setSolutionTypeBtn={setSolutionTypeBtn}
              />
            </Popover>
            <Button
              variant="contained"
              color="talentButton"
              sx={{
                width: "150px",
                height: "45px",
                borderRadius: "0 0 25px 0",
                padding: "0px !important",
              }}
              onClick={() => {
                setIsEdit(false)
                setIsAddSoutionModal(true);
                setEditSolutionData({ ...editSolutionData, title: "" });
              }}
            >
              add a solution
            </Button>
          </Box>
        )}
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            position: "absolute",
          }}
        >
          <Button
            variant="contained"
            color="talentButton"
            sx={{
              borderRadius: "0px 0px 10px 10px ",
              width: 75,
              height: 20,
            }}
            onClick={() => setOpenFilter((prev) => !prev)}
          >
            <Box
              component={"img"}
              src={openFilter ? upClose : downClose}
              sx={{
                height: 30,
                width: 30,
              }}
            />
          </Button>
        </Box>
      </Paper>
    </>
  );
}
