import { CheckCircle } from "@mui/icons-material";
import { Box, Button, Typography } from "@mui/material";
import React from "react";
import pendingIcon from "../../assets/CircularIcon/Yellow/Circular Icons__Yellow_In_Process.svg";
import locale from "../../i18n/locale";
import { useTheme } from "@emotion/react";
import { setProfileSteps } from "../../redux/employer/empProfileSlice";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getDecodedToken, getToken } from "../../utils/Common";

export default function ProgressButtonDropDown({
  profilePercentage,
  setProgressButton,
}) {
  const numberCheck = 25;
  const i18n = locale.en;
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let decodedToken = getDecodedToken(getToken());

  const handleChange = (value) => {
    navigate(
      decodedToken?.data?.role_id === 6
        ? "/recruiter/my-profile"
        : "/employer/my-profile"
    );
    setProgressButton(false);
    dispatch(setProfileSteps(value));
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        padding: "15px 23px 21px 17px",
        gap: 1,
      }}
      className="employer-profile-viewer-dropdown"
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          gap: 1,
        }}
      >
        <Button
          variant="contained"
          // color="grayButton100"
          sx={{
            ":hover": {
              backgroundColor: theme?.palette?.chart?.gray,
              boxShadow: "none",
            },
            backgroundColor: theme?.palette?.chart?.gray,
            color: "black",
            borderRadius: "10px",
            width: "100%",
            display: "flex",
            justifyContent: "start",
            // gap: 1,
            boxShadow: 0,
            fontSize: theme.typography.fontSize.sm,
            fontWeight: theme.typography.Bold,
            height: "45px",
            padding: "7px 15px 7px 12px",
          }}
          onClick={() => handleChange(1)}
        >
          {profilePercentage?.myInfo === numberCheck ? (
            <CheckCircle
              sx={{
                height: "30px",
                width: "30px",
                paddingRight: "12px",
              }}
              color="progressBar"
            />
          ) : (
            <Box
              component={"img"}
              src={pendingIcon}
              sx={{
                height: "25px",
                width: "25px",
                paddingRight: "14px",
                paddingLeft: "3px",
              }}
            ></Box>
          )}
          {i18n["profileCompletion.myinfo"]}
          <Box
            sx={{
              flexGrow: 1,
              display: "flex",
              justifyContent: "end",
              alignItems: "center",
              paddingRight: "11px",
              paddingBottom: "2px",
            }}
          >
            <Button
              variant="contained"
              color={
                profilePercentage?.myInfo === numberCheck
                  ? "progressBar"
                  : "grayButton400"
              }
              sx={{
                height: "9px",
                minWidth: 13,
                padding: "0px",
                borderRadius: "5px",
              }}
            ></Button>
          </Box>
          <Typography
            sx={{
              fontSize: theme.typography.fontSize.xs,
              fontWeight: theme.typography.Bold,
              width: "10ch",
              textAlign: "end",
            }}
          >
            {`${
              profilePercentage?.myInfo === numberCheck ? numberCheck : 0
            }% (25%)`}
          </Typography>
        </Button>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          gap: 1,
        }}
      >
        <Button
          variant="contained"
          // color="grayButton100"
          sx={{
            ":hover": {
              backgroundColor: theme?.palette?.chart?.gray,
              boxShadow: "none",
            },
            backgroundColor: theme?.palette?.chart?.gray,
            color: "black",
            borderRadius: "10px",
            width: "100%",
            display: "flex",
            justifyContent: "start",
            // gap: 1,
            boxShadow: 0,
            fontSize: theme.typography.fontSize.sm,
            fontWeight: theme.typography.Bold,
            height: "45px",
            padding: "7px 15px 7px 12px",
          }}
          onClick={() => handleChange(2)}
        >
          {profilePercentage?.companyInfo === numberCheck ? (
            <CheckCircle
              sx={{
                height: "30px",
                width: "30px",
                paddingRight: "12px",
              }}
              color="progressBar"
            />
          ) : (
            <Box
              component={"img"}
              src={pendingIcon}
              sx={{
                height: "25px",
                width: "25px",
                paddingRight: "14px",
                paddingLeft: "3px",
              }}
            ></Box>
          )}
          {i18n["profileCompletion.companyinfo"]}
          <Box
            sx={{
              flexGrow: 1,
              display: "flex",
              justifyContent: "end",
              alignItems: "center",
              paddingRight: "11px",
              paddingBottom: "2px",
            }}
          >
            <Button
              variant="contained"
              color={
                profilePercentage?.companyInfo === numberCheck
                  ? "progressBar"
                  : "grayButton400"
              }
              sx={{
                height: "9px",
                minWidth: 13,
                padding: "0px",
                borderRadius: "5px",
              }}
            ></Button>
          </Box>
          <Typography
            sx={{
              fontSize: theme.typography.fontSize.xs,
              fontWeight: theme.typography.Bold,
              width: "10ch",
              textAlign: "end",
            }}
          >
            {`${
              profilePercentage?.companyInfo === numberCheck ? numberCheck : 0
            }% (25%)`}
          </Typography>
        </Button>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          gap: 1,
        }}
      >
        <Button
          variant="contained"
          // color="grayButton100"
          sx={{
            ":hover": {
              backgroundColor: theme?.palette?.chart?.gray,
              boxShadow: "none",
            },
            backgroundColor: theme?.palette?.chart?.gray,
            color: "black",
            borderRadius: "10px",
            width: "100%",
            display: "flex",
            justifyContent: "start",
            // gap: 1,
            boxShadow: 0,
            fontSize: theme.typography.fontSize.sm,
            fontWeight: theme.typography.Bold,
            height: "45px",
            padding: "7px 15px 7px 12px",
          }}
          onClick={() => handleChange(4)}
        >
          {profilePercentage?.myPlan === numberCheck ? (
            <CheckCircle
              sx={{
                height: "30px",
                width: "30px",
                paddingRight: "12px",
              }}
              color="progressBar"
            />
          ) : (
            <Box
              component={"img"}
              src={pendingIcon}
              sx={{
                height: "25px",
                width: "25px",
                paddingRight: "14px",
                paddingLeft: "3px",
              }}
            ></Box>
          )}
          {i18n["profileCompletion.myplan"]}
          <Box
            sx={{
              flexGrow: 1,
              display: "flex",
              justifyContent: "end",
              alignItems: "center",
              paddingRight: "11px",
              paddingBottom: "2px",
            }}
          >
            <Button
              variant="contained"
              color={
                profilePercentage?.myPlan === numberCheck
                  ? "progressBar"
                  : "grayButton400"
              }
              sx={{
                height: "9px",
                minWidth: 13,
                padding: "0px",
                borderRadius: "5px",
              }}
            ></Button>
          </Box>
          <Typography
            sx={{
              fontSize: theme.typography.fontSize.xs,
              fontWeight: theme.typography.Bold,
              width: "10ch",
            }}
          >
            {`${
              profilePercentage?.myPlan === numberCheck ? numberCheck : 0
            }% (25%)`}
          </Typography>
        </Button>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          gap: 1,
        }}
      >
        <Button
          variant="contained"
          // color="grayButton100"
          sx={{
            ":hover": {
              backgroundColor: theme?.palette?.chart?.gray,
              boxShadow: "none",
            },
            backgroundColor: theme?.palette?.chart?.gray,
            color: "black",
            borderRadius: "10px",
            width: "100%",
            display: "flex",
            justifyContent: "start",
            // gap: 1,
            boxShadow: 0,
            fontSize: theme.typography.fontSize.sm,
            fontWeight: theme.typography.Bold,
            height: "45px",
            padding: "7px 15px 7px 12px",
          }}
          onClick={() => handleChange(5)}
        >
          {profilePercentage?.billing === numberCheck ? (
            <CheckCircle
              sx={{
                height: "30px",
                width: "30px",
                paddingRight: "12px",
              }}
              color="progressBar"
            />
          ) : (
            <Box
              component={"img"}
              src={pendingIcon}
              sx={{
                height: "25px",
                width: "25px",
                paddingRight: "14px",
                paddingLeft: "3px",
              }}
            ></Box>
          )}
          {i18n["profileCompletion.billinginfo"]}
          <Box
            sx={{
              flexGrow: 1,
              display: "flex",
              justifyContent: "end",
              alignItems: "center",
              paddingRight: "11px",
              paddingBottom: "2px",
            }}
          >
            <Button
              variant="contained"
              color={
                profilePercentage?.billing === numberCheck
                  ? "progressBar"
                  : "grayButton400"
              }
              sx={{
                height: "9px",
                minWidth: 13,
                padding: "0px",
                borderRadius: "5px",
              }}
            ></Button>
          </Box>
          <Typography
            sx={{
              fontSize: theme.typography.fontSize.xs,
              fontWeight: theme.typography.Bold,
              width: "10ch",
              textAlign: "end",
            }}
          >
            {`${
              profilePercentage?.billing === numberCheck ? numberCheck : 0
            }% (25%)`}
          </Typography>
        </Button>
      </Box>
    </Box>
  );
}
