import { Box } from "@mui/material";
import React from "react";
import profile_challenger from "../../../../assets/Personality Logos/personality_characters_challenger.svg";

// import profile_challenger from "../../../../assets/Personality Logos/crayon_personality_grey_challeneger.svg";
import profile_grey_challenger from "../../../../assets/Personality Logos/crayon_personality_grey_challenger.svg";
import profile_grey_character from "../../../../assets/Personality Logos/crayon_personality_grey_character.svg";
import profile_character from "../../../../assets/Personality Logos/crayon_personality_character.svg";
import profile_collaborator from "../../../../assets/Personality Logos/crayon_personality_collaborator.svg";
import profile_contemplator from "../../../../assets/Personality Logos/crayon_personality_contemplator.svg";
import MUIRadialChart from "../../../common/MUIRadialChart";

export default function CandidateFlipCircles(
  shadow,
  primary,
  gritScore,
  index
) {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "flex-start",
        width: "fit-content",
        gap: "10px",
        //   marginLeft: "10px",
      }}
    >
      {shadow?.primary !== undefined ? (
        <Box
          // sx={{ margin: "0 -8px 8px -8px" }}
          component="img"
          height={55}
          alt="Primary personality"
          src={
            (shadow?.primary === "collaborator" && profile_collaborator) ||
            (shadow?.primary === "challenger" && profile_challenger) ||
            (shadow?.primary === "character" && profile_character) ||
            (shadow?.primary === "contemplator" && profile_contemplator) ||
            profile_grey_challenger
          }
        />
      ) : (
        <Box
          // sx={{ margin: "0 -8px 8px -8px" }}
          component="img"
          height={55}
          alt="Primary personality"
          src={profile_grey_challenger}
        />
      )}
      {/* </Box> */}
      {shadow?.shadow !== undefined ? (
        <Box
          // sx={{ margin: "0 -8px 8px -8px" }}
          component="img"
          height={55}
          alt="Shadow personality"
          src={
            (shadow?.shadow === "collaborator" && profile_collaborator) ||
            (shadow?.shadow === "challenger" && profile_challenger) ||
            (shadow?.shadow === "character" && profile_character) ||
            (shadow?.shadow === "contemplator" && profile_contemplator) ||
            profile_grey_character
          }
        />
      ) : (
        <Box
          // sx={{ margin: "0 -8px 8px -8px" }}
          component="img"
          height={55}
          alt="Shadow personality"
          src={profile_grey_character}
        />
      )}
      <Box>
        <MUIRadialChart
          value={shadow?.gritScore === null ? 0 : shadow?.gritScore}
          chartName={"grit score"}
          max={100}
          size={55}
          countFontSize={14}
          labelFontSize={9}
          color={"grit_score_new"}
        />
      </Box>
    </Box>
  );
}
