import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { approveUser, getApprovalList } from "../../../redux/employer/myTeams";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import InfiniteScroll from "react-infinite-scroll-component";
import ApproveConfirmationModal from "./ApproveConfirmationModal";
import { dateConverterMonth } from "../../../utils/DateTime";
import { CheckCircle } from "@mui/icons-material";
import cancel from "../../../assets/CircularIcon/Red/Circular Icons__Red_Close_2.svg";
import TalentSVGButton from "../../common/TalentSVGButton";
import { ToggleCheckedSwitch } from "../../../utils/CommonComponent";
import MyTeamApprovalModal from "./MyTeamApprovalModal";

const ApproveTeamMemberTable = ({ companyId, getTeamsMember }) => {
  const [tableList, setTableList] = useState([]);
  const [isApproveModal, setIsApproveModal] = useState(false);
  const [modalHeading, setModalHeading] = useState("");
  const [selectedUser, setSelectedUser] = useState();
  const [roleTypeId, setRoleTypeId] = useState();
  const [openModal, setOpenModal] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const[claimOwner, setClaimOwner]= useState(false);
  const dispatch = useDispatch();
  const getApprovalLisitng = async (id) => {
    let payload = id;
    try {
      let reseponce = await dispatch(getApprovalList(payload));
      if (reseponce?.payload?.status === "success") {
        setTableList(reseponce?.payload?.data?.map((item)=>({...item,action:false})));
      }
    } catch (error) {
      console.log(error, "error");
    }
  };

  useEffect(() => {
    if (companyId) {
      getApprovalLisitng(companyId);
    }
  }, [companyId]);

  const handleApprove = (row) => {
    setSelectedUser(row);
    setModalHeading("Approval");
    setRoleTypeId(row?.role_type_id);
    setIsApproveModal(true);
  };
  const handleReject = (row) => {
    setSelectedUser(row);
    setModalHeading("Rejection");
    setRoleTypeId(row?.role_type_id);
    setIsApproveModal(true);
  };
  const handleSelectChange = (event, row, id, name) => {
    setSelectedUser(row);
    setRoleTypeId(row?.role_type_id);
    let updatedData = tableList?.map((item)=>{if(item?.user_id === row?.user_id){
      return{ ...item, action:!item?.action}
    }else{
      return item
    }})
    setTableList([...updatedData])
    if(event?.target?.checked === true){
      if (row?.claim_ownership === true) {
        setConfirmDelete(false)
        setOpenModal(true);
        setClaimOwner(true)
      } else {
        setOpenModal(true);
        setConfirmDelete(false)
        setClaimOwner(false)
      }
    }
  };
  const handleApprovalSubmitButton = () => {
    UpdateApproved("Approval");
  };
  const handleDeclineRequest = () => {
    UpdateApproved("Rejection");
  };

  const UpdateApproved = async (val) => {
    let payload = {
      talentid: selectedUser?.user_id,
      company_id: selectedUser?.company_id,
      approvetype: val === "Approval" ? true : false,
      roleTypeId: roleTypeId,
    };

    try {
      let response = await dispatch(approveUser(payload));
      if (response?.payload?.status === "success") {
        setIsApproveModal();
        getApprovalLisitng(companyId);
        getTeamsMember();
        setConfirmDelete(false)
        setOpenModal(false);
      }
    } catch (error) {}
  };
  const handleApproveModalSubmit = () => {
    modalHeading === "Approval"
      ? UpdateApproved("Approval")
      : UpdateApproved("Rejection");
  };
  const handleApproveModalClose = () => {
    setIsApproveModal(false);
  };

  return (
    <Box>
      <InfiniteScroll
        style={{ overflow: "visible" }}
        dataLength={tableList?.length}
        // next={() => getTitles(lastKey, searchTitle)}
        scrollThreshold={"10px"}
        scrollableTarget={"jobList"}
        // hasMore={tableList?.length < jobTitleCount ? true : false}
      >
        <Box
          sx={{
            mt: 2,
          }}
        >
          <TableContainer
            component={Box}
            sx={{
              overflowX: "unset",
            }}
          >
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Typography
                      variant="subtitle1"
                      fontWeight="bold"
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <span>First Name</span>
                      </div>
                    </Typography>
                  </TableCell>

                  <TableCell>
                    <Typography
                      variant="subtitle1"
                      fontWeight="bold"
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <span>Last Name</span>
                      </div>
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      variant="subtitle1"
                      fontWeight="bold"
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <span>Company Name</span>
                      </div>
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      variant="subtitle1"
                      fontWeight="bold"
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <span>Secondary Company Request</span>
                      </div>
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      variant="subtitle1"
                      fontWeight="bold"
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <span>Claim OwnerShip</span>
                      </div>
                    </Typography>
                  </TableCell>

                  <TableCell>
                    <Typography
                      variant="subtitle1"
                      fontWeight="bold"
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <span>Email</span>
                      </div>
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      variant="subtitle1"
                      fontWeight="bold"
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <span>Type</span>
                      </div>
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      variant="subtitle1"
                      fontWeight="bold"
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <span>Created At</span>
                      </div>
                    </Typography>
                  </TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {tableList?.map((row, index) => {

                  return (
                    <TableRow
                      key={row?.id}
                      style={{
                        background: `${index % 2 === 0 ? "#F2F4FB" : ""}`,
                        "& .css-12zgwp-MuiTableCell-root": {
                          padding: "0 16px !important",
                        },
                      }}
                    >
                      <TableCell>{row?.user?.first_name}</TableCell>
                      <TableCell>{row?.user?.last_name}</TableCell>
                      <TableCell>{row?.company?.name}</TableCell>
                      <TableCell>
                        {row?.secondary_company_request === null ||
                        row?.secondary_company_request === false
                          ? "No"
                          : "Yes"}
                      </TableCell>
                      <TableCell>
                        {row?.claim_ownership === null ||
                        row?.claim_ownership === false
                          ? "No"
                          : "Yes"}
                      </TableCell>
                      <TableCell>{row?.user?.email}</TableCell>
                      <TableCell>{row?.employer_role_type?.name}</TableCell>
                      <TableCell>
                        {dateConverterMonth(row?.created_at)}
                      </TableCell>
                      <TableCell
                        sx={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <ToggleCheckedSwitch
                          checkedColor={"recentButton"}
                          notCheckedColor={"employerButton"}
                          checked={row?.action}
                          onChange={(event, id, name) => {
                            handleSelectChange(event, row, id, name);
                          }}
                        />
                        {/* <CheckCircle
                          sx={{
                            fontSize: 27,
                            cursor: "pointer",
                            marginRight: "5px",
                          }}
                          color="checkColor"
                          onClick={() => handleApprove(row)}
                        />
                        <TalentSVGButton
                          padding={"0px !important"}
                          color={"white"}
                          source={cancel}
                          height={27}
                          width={23}
                          onClick={() => handleReject(row)}
                        /> */}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </InfiniteScroll>
      {isApproveModal && (
        <ApproveConfirmationModal
          handleOpen={isApproveModal}
          handleClose={handleApproveModalClose}
          handleSubmit={handleApproveModalSubmit}
          modalHeading={modalHeading}
          selectedUser={selectedUser}
        />
      )}
      {openModal && (
        <MyTeamApprovalModal
          open={openModal}
          setOpen={setOpenModal}
          confirmDelete={confirmDelete}
          setConfirmDelete={setConfirmDelete}
          handleApprovalSubmitButton={handleApprovalSubmitButton}
          handleDeclineRequest={handleDeclineRequest}
          claimOwner={claimOwner}
        />
      )}
    </Box>
  );
};

export default ApproveTeamMemberTable;
