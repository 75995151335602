import React from "react";
import locale from "../../../i18n/locale";
import { Box, Typography, useTheme } from "@mui/material";
import { splitStringWithParentheses } from "../../../utils/Common";
import { formatCurrencyWithCommas } from "../../../utils/Currency";
import { Circle } from "@mui/icons-material";
import useViewport from "../../common/ViewPort";

export default function ClassCVPageLeftSide({ exportAsPDF, stateData }) {
  const i18n = locale.en;
  const theme = useTheme();
  const { width } = useViewport();
  // const [isLoading, setIsLoading] = useState(true);

  const calculateAge = (dateString) => {
    const birthDate = new Date(dateString);
    const currentDate = new Date();
    let years = currentDate?.getUTCFullYear() - birthDate?.getUTCFullYear();
    let months = currentDate?.getUTCMonth() - birthDate?.getUTCMonth();
    if (
      months < 0 ||
      (months === 0 && currentDate?.getUTCDate() < birthDate?.getUTCDate())
    ) {
      years--;
      months = 12 + months;
    }
    // If the age is less than a month, return "1 month"
    if (years === 0 && months === 0) {
      return "1 month";
    }
    let result = "";
    if (years > 0) result += `${years} year${years > 1 ? "s" : ""} `;
    if (months > 0) result += `${months} month${months > 1 ? "s" : ""}`;
    return result?.trim();
  };
  return (
    <Box
      className="leftSide"
      sx={{
        width: exportAsPDF ? "316px" : { md24: "316px" },
        padding: "42px",
        background: theme.palette.classicCVColor.main,
        borderRadius:
          width > 390 || exportAsPDF ? "25px 0 0 25px" : "25px 25px 0 0",
      }}
    >
      <Typography
        sx={{
          fontSize: "30px",
          fontWeight: theme.typography.Bold,
          color: "white",
        }}
      >
        Curriculum Vitae
      </Typography>
      <Typography
        sx={{
          fontSize: "30px",
          fontWeight: theme.typography.Bold,
          color: "white",
          marginBottom: "40px",
        }}
      >
        {`${stateData?.first_name} ${stateData?.last_name}`}
      </Typography>

      <Box sx={{ marginBottom: "20.58px" }}>
        <Typography
          sx={{
            fontSize: "18px",
            fontWeight: theme.typography.Bold,
            color: "white",
            marginBottom: "5.5px",
          }}
        >
          About Me
        </Typography>
        <Box sx={{ display: "flex", flexWrap: "wrap", marginBottom: "18px" }}>
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: theme.typography.Regular,
              color: "white",
              display: "flex",
              alignItems: "center",
            }}
          >
            {stateData?.gender ? (
              <>
                {stateData?.gender?.charAt(0)?.toUpperCase() +
                  stateData?.gender?.slice(1)}
                <Circle
                  fontSize="string"
                  sx={{
                    margin: "0 4px 3px 4px",
                    color: "white",
                    height: "10px",
                    width: "10px",
                  }}
                />
              </>
            ) : (
              "-"
            )}
          </Typography>
          {stateData?.UserNationality ? (
            stateData?.UserNationality?.split(",")?.map(
              (item, index, array) => {
                const isLastElement = index === array?.length - 1;
                return (
                  <Typography
                    key={index}
                    sx={{
                      fontSize: "14px",
                      fontWeight: "regular", // Adjust according to your theme setup
                      color: "white",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    {item}
                    {!isLastElement && ","}
                    {isLastElement && (
                      <Circle
                        fontSize="string"
                        sx={{
                          margin: "0 4px 3px 4px",
                          color: "white",
                          height: "10px",
                          width: "10px",
                        }}
                      />
                    )}
                  </Typography>
                );
              }
            )
          ) : (
            <Typography sx={{ color: "white" }}>-</Typography>
          )}
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: theme.typography.Regular,
              color: "white",
              display: "flex",
              alignItems: "center",
            }}
          >
            {stateData?.experienceYearEnd ? (
              <>
                {stateData?.experienceYearEnd} years' experience
                <Circle
                  fontSize="string"
                  sx={{
                    margin: "0 4px 3px 4px",
                    color: "white",
                    height: "10px",
                    width: "10px",
                  }}
                />
              </>
            ) : (
              "-"
            )}
          </Typography>
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: theme.typography.Regular,
              color: "white",
              marginRight: "4px",
            }}
          >
            {stateData?.dob ? `${calculateAge(stateData?.dob)} old` : "-"}
          </Typography>
        </Box>

        <Box sx={{ display: "flex", flexWrap: "wrap", marginBottom: "18px" }}>
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: theme.typography.Regular,
              color: "white",
              marginRight: "4px",
            }}
          >
            Setup:
          </Typography>

          {stateData?.own_transport && (
            <Typography
              sx={{
                fontSize: "14px",
                fontWeight: theme.typography.Regular,
                color: "white",
                display: "flex",
                alignItems: "center",
              }}
            >
              {i18n["myCV.own_transport"]}
              <Circle
                fontSize="string"
                sx={{
                  margin: "0 4px 3px 4px",
                  color: "white",
                  height: "10px",
                  width: "10px",
                }}
              />
            </Typography>
          )}
          {stateData?.driving_license && (
            <Typography
              sx={{
                fontSize: "14px",
                fontWeight: theme.typography.Regular,
                color: "white",
                display: "flex",
                alignItems: "center",
              }}
            >
              {i18n["myCV.driving_license"]}
              <Circle
                fontSize="string"
                sx={{
                  margin: "0 4px 3px 4px",
                  color: "white",
                  height: "10px",
                  width: "10px",
                }}
              />
            </Typography>
          )}
          {stateData?.own_equipment && (
            <Typography
              sx={{
                fontSize: "14px",
                fontWeight: theme.typography.Regular,
                color: "white",
                display: "flex",
                alignItems: "center",
              }}
            >
              {i18n["myCV.own_equipment"]}
              <Circle
                fontSize="string"
                sx={{
                  margin: "0 4px 3px 4px",
                  color: "white",
                  height: "10px",
                  width: "10px",
                }}
              />
            </Typography>
          )}
          {stateData?.internet_access && (
            <Typography
              sx={{
                fontSize: "14px",
                fontWeight: theme.typography.Regular,
                color: "white",
                display: "flex",
                alignItems: "center",
              }}
            >
              {i18n["myCV.internet_access"]}
            </Typography>
          )}
        </Box>

        <Box
          sx={{
            display: "flex",
            gap: "4px",
            flexWrap: "wrap",
            marginBottom: "18px",
          }}
        >
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: theme.typography.Regular,
              color: "white",
            }}
          >
            {i18n["myCV.highestEducation"]}
          </Typography>

          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: theme.typography.Regular,
              color: "white",
            }}
          >
            {stateData?.HighestQual || "-"}
          </Typography>
        </Box>

        <Box sx={{ display: "flex", gap: "4px", flexWrap: "wrap" }}>
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: theme.typography.Regular,
              color: "white",
            }}
          >
            {i18n["myCV.noticePeriod1"]}
          </Typography>

          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: theme.typography.Regular,
              color: "white",
            }}
          >
            {stateData?.noticePeriod_description
              ? stateData?.noticePeriod_description
              : "-"}
          </Typography>
        </Box>
      </Box>

      <Box sx={{ marginBottom: "20.58px" }}>
        <Typography
          sx={{
            fontSize: "18px",
            fontWeight: theme.typography.Bold,
            color: "white",
            marginBottom: "5.5px",
          }}
        >
          {i18n["myCV.skillsLabel"]}
        </Typography>
        <Box sx={{ display: "flex", flexWrap: "wrap" }}>
          {stateData?.UserSkills ? (
            stateData?.UserSkills?.split(",")?.map((item, index, array) => {
              const isLastElement = index === array?.length - 1;
              return (
                <Typography
                  key={index}
                  sx={{
                    fontSize: "14px",
                    fontWeight: "regular", // Adjust according to your theme setup
                    color: "white",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {item}
                  {!isLastElement && (
                    <Circle
                      fontSize="string"
                      sx={{
                        margin: "0 4px 3px 4px",
                        color: "white",
                        height: "10px",
                        width: "10px",
                      }}
                    />
                  )}
                </Typography>
              );
            })
          ) : (
            <Typography sx={{ color: "white" }}>-</Typography>
          )}
        </Box>
      </Box>

      <Box sx={{ marginBottom: "20.58px" }}>
        <Typography
          sx={{
            fontSize: "18px",
            fontWeight: theme.typography.Bold,
            color: "white",
            marginBottom: "5.5px",
          }}
        >
          {i18n["myCV.tools"]}
        </Typography>
        <Box sx={{ display: "flex", flexWrap: "wrap" }}>
          {stateData?.UserTools ? (
            splitStringWithParentheses(stateData?.UserTools)?.map(
              (item, index, array) => {
                const isLastElement = index === array?.length - 1;
                return (
                  <Typography
                    key={index}
                    sx={{
                      fontSize: "14px",
                      fontWeight: "regular", // Adjust according to your theme setup
                      color: "white",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    {item?.trim()}
                    {!isLastElement && (
                      <Circle
                        fontSize="string"
                        sx={{
                          margin: "0 4px 3px 4px",
                          color: "white",
                          height: "10px",
                          width: "10px",
                        }}
                      />
                    )}
                  </Typography>
                );
              }
            )
          ) : (
            <Typography sx={{ color: "white" }}>-</Typography>
          )}
        </Box>
      </Box>

      <Box sx={{ marginBottom: "20.58px" }}>
        <Typography
          sx={{
            fontSize: "18px",
            fontWeight: theme.typography.Bold,
            color: "white",
            marginBottom: "5.5px",
          }}
        >
          {i18n["myCV.nationality"]}
        </Typography>
        <Box sx={{ display: "flex", flexWrap: "wrap" }}>
          {stateData?.UserNationality ? (
            stateData?.UserNationality?.split(",")?.map(
              (item, index, array) => {
                const isLastElement = index === array?.length - 1;
                return (
                  <Typography
                    key={index}
                    sx={{
                      fontSize: "14px",
                      fontWeight: "regular", // Adjust according to your theme setup
                      color: "white",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    {item}
                    {!isLastElement && (
                      <Circle
                        fontSize="string"
                        sx={{
                          margin: "0 4px 3px 4px",
                          color: "white",
                          height: "10px",
                          width: "10px",
                        }}
                      />
                    )}
                  </Typography>
                );
              }
            )
          ) : (
            <Typography sx={{ color: "white" }}>-</Typography>
          )}
        </Box>
      </Box>

      <Box sx={{ marginBottom: "20.58px" }}>
        <Typography
          sx={{
            fontSize: "18px",
            fontWeight: theme.typography.Bold,
            color: "white",
            marginBottom: "5.5px",
          }}
        >
          {i18n["myCV.language"]}
        </Typography>
        <Box sx={{ display: "flex", flexWrap: "wrap" }}>
          {stateData?.UserLanguage ? (
            stateData?.UserLanguage?.split(",")?.map((item, index, array) => {
              const isLastElement = index === array?.length - 1;
              return (
                <Typography
                  key={index}
                  sx={{
                    fontSize: "14px",
                    fontWeight: "regular", // Adjust according to your theme setup
                    color: "white",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {item}
                  {!isLastElement && (
                    <Circle
                      fontSize="string"
                      sx={{
                        margin: "0 4px 3px 4px",
                        color: "white",
                        height: "10px",
                        width: "10px",
                      }}
                    />
                  )}
                </Typography>
              );
            })
          ) : (
            <Typography sx={{ color: "white" }}>-</Typography>
          )}
        </Box>
      </Box>

      <Box sx={{ marginBottom: "20.58px" }}>
        <Typography
          sx={{
            fontSize: "18px",
            fontWeight: theme.typography.Bold,
            color: "white",
            marginBottom: "5.5px",
          }}
        >
          {i18n["myCV.preferences"]}
        </Typography>
        <Box sx={{ display: "flex", flexWrap: "wrap", marginBottom: "18px" }}>
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: theme.typography.Regular,
              color: "white",
              marginRight: "4px",
            }}
          >
            {i18n["myCV.industries"]}
          </Typography>
          {stateData?.UserIndustries ? (
            stateData?.UserIndustries?.split(",")?.map((item, index, array) => {
              const isLastElement = index === array?.length - 1;
              return (
                <Typography
                  key={index}
                  sx={{
                    fontSize: "14px",
                    fontWeight: "regular", // Adjust according to your theme setup
                    color: "white",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {item}
                  {!isLastElement && (
                    <Circle
                      fontSize="string"
                      sx={{
                        margin: "0 4px 3px 4px",
                        color: "white",
                        height: "10px",
                        width: "10px",
                      }}
                    />
                  )}
                </Typography>
              );
            })
          ) : (
            <Typography sx={{ color: "white" }}>-</Typography>
          )}
        </Box>
        <Box sx={{ display: "flex", flexWrap: "wrap", marginBottom: "18px" }}>
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: theme.typography.Regular,
              color: "white",
              marginRight: "4px",
            }}
          >
            {i18n["myCV.worksetup"]}
          </Typography>

          {stateData?.employment_type && (
            <Typography
              sx={{
                fontSize: "14px",
                fontWeight: "regular", // Adjust according to your theme setup
                color: "white",
                display: "flex",
                alignItems: "center",
              }}
            >
              {stateData?.employment_type}
              <Circle
                fontSize="string"
                sx={{
                  margin: "0 4px 3px 4px",
                  color: "white",
                  height: "10px",
                  width: "10px",
                }}
              />
            </Typography>
          )}

          {stateData?.work_setup && (
            <Typography
              sx={{
                fontSize: "14px",
                fontWeight: "regular", // Adjust according to your theme setup
                color: "white",
                display: "flex",
                alignItems: "center",
              }}
            >
              {stateData?.work_setup}
              <Circle
                fontSize="string"
                sx={{
                  margin: "0 4px 3px 4px",
                  color: "white",
                  height: "10px",
                  width: "10px",
                }}
              />
            </Typography>
          )}

          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: "regular", // Adjust according to your theme setup
              color: "white",
              marginRight: "4px",
            }}
          >
            {stateData?.relocate === "1"
              ? "willing to relocate"
              : "not willing to relocate"}
          </Typography>
        </Box>
        <Box sx={{ display: "flex", flexWrap: "wrap", marginBottom: "18px" }}>
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: theme.typography.Regular,
              color: "white",
              marginRight: "4px",
            }}
          >
            {i18n["myCV.workculture"]}
          </Typography>
          {stateData?.UserCulture ? (
            stateData?.UserCulture?.split(",")?.map((item, index, array) => {
              const isLastElement = index === array?.length - 1;
              return (
                <Typography
                  key={index}
                  sx={{
                    fontSize: "14px",
                    fontWeight: "regular", // Adjust according to your theme setup
                    color: "white",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {item}
                  {!isLastElement && (
                    <Circle
                      fontSize="string"
                      sx={{
                        margin: "0 4px 3px 4px",
                        color: "white",
                        height: "10px",
                        width: "10px",
                      }}
                    />
                  )}
                </Typography>
              );
            })
          ) : (
            <Typography sx={{ color: "white" }}>-</Typography>
          )}
        </Box>
        <Box sx={{ display: "flex", flexWrap: "wrap", marginBottom: "18px" }}>
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: theme.typography.Regular,
              color: "white",
              marginRight: "4px",
            }}
          >
            {i18n["myCV.salary"]}
          </Typography>
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: "regular", // Adjust according to your theme setup
              color: "white",
              marginRight: "4px",
            }}
          >
            {stateData?.salaryMax ? (
              <>
                {stateData?.currencySymbol}
                {formatCurrencyWithCommas(stateData?.salaryMin)} to{" "}
                {stateData?.currencySymbol}
                {formatCurrencyWithCommas(stateData?.salaryMax)}
                {stateData?.employment_type === "freelance"
                  ? " per hour"
                  : " per month"}
              </>
            ) : (
              <Typography sx={{ color: "white" }}>-</Typography>
            )}
          </Typography>
        </Box>
      </Box>

      <Box sx={{ marginBottom: "20.58px" }}>
        <Typography
          sx={{
            fontSize: "18px",
            fontWeight: theme.typography.Bold,
            color: "white",
            marginBottom: "5.5px",
          }}
        >
          {i18n["myCV.personality"]}
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            gap: "4px",
            marginBottom: "18px",
          }}
        >
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: theme.typography.Regular,
              color: "white",
            }}
          >
            {i18n["myCV.primarypersonality"]}
          </Typography>
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: theme.typography.Regular,
              color: "white",
            }}
          >
            {stateData?.primaryName
              ? stateData?.primaryName?.charAt(0)?.toUpperCase() +
                stateData?.primaryName?.slice(1)
              : "-"}
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            gap: "4px",
            marginBottom: "18px",
          }}
        >
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: theme.typography.Regular,
              color: "white",
            }}
          >
            {i18n["myCV.shadowpersonality"]}
          </Typography>
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: theme.typography.Regular,
              color: "white",
            }}
          >
            {stateData?.shadowName
              ? stateData?.shadowName?.charAt(0)?.toUpperCase() +
                stateData?.shadowName?.slice(1)
              : "-"}
          </Typography>
        </Box>
        <Box sx={{ display: "flex", flexWrap: "wrap", marginBottom: "18px" }}>
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: theme.typography.Regular,
              color: "white",
              marginRight: "4px",
            }}
          >
            {i18n["myCV.traits"]}
          </Typography>
          {stateData?.UserTraits ? (
            stateData?.UserTraits?.split(",")?.map((item, index, array) => {
              const isLastElement = index === array?.length - 1;
              return (
                <Typography
                  key={index}
                  sx={{
                    fontSize: "14px",
                    fontWeight: "regular", // Adjust according to your theme setup
                    color: "white",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {item}
                  {!isLastElement && (
                    <Circle
                      fontSize="string"
                      sx={{
                        margin: "0 4px 3px 4px",
                        color: "white",
                        height: "10px",
                        width: "10px",
                      }}
                    />
                  )}
                </Typography>
              );
            })
          ) : (
            <Typography sx={{ color: "white" }}>-</Typography>
          )}
        </Box>
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            gap: "4px",
            marginBottom: "18px",
          }}
        >
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: theme.typography.Regular,
              color: "white",
            }}
          >
            {i18n["myCV.gritscore"]}
          </Typography>
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: theme.typography.Regular,
              color: "white",
            }}
          >
            {stateData?.grit_score || "-"}
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            gap: "4px",
            marginBottom: "18px",
          }}
        >
          <Typography
            sx={{
              fontSize: "10px",
              fontWeight: theme.typography.Regular,
              color: "white",
            }}
          >
            {i18n["myCV.gritscoreInfo"]}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}
