import { useTheme } from "@mui/material/styles";

import { Paper, InputBase } from "@mui/material";
import { Modal } from "@mui/material";
import React, { useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

const SearchSaveModal = ({
  handleOpen,
  handleClose,
  handleSubmit,
  modalName,
  setModalName,
  searchID,
}) => {
  const theme = useTheme();
  const [errors, setErrors] = useState();
  // const [name, setName] = useState("");
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    borderRadius: "12px",
    p: 4,
    boxShadow: "none !important",
    border: "1px solid black !important",
    ".&focus-visible": {
      outlineColor: "none",
    },
  };
  return (
    <Modal
      open={handleOpen}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography
          id="modal-modal-title"
          variant="h6"
          component="h2"
          textAlign={"center"}
        >
          Save Search
        </Typography>
        <Box>
          <Box sx={{ mt: 3, mb: 4, width: "100%", padding: 0 }}>
            <Paper
              sx={{
                display: "flex",
                height: "50px",
                width: "100% !important",
                borderRadius: "10px  !important",
                boxShadow: "none",
                backgroundColor: "#fff !important",
                border: errors?.find((error) => error.key === "username")
                  ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
                  : "1px solid borderColor",
              }}
            >
              <InputBase
                disabled={searchID}
                id="username"
                value={modalName}
                onChange={(e) => {
                  setModalName(e.target.value);
                }}
                placeholder={"Enter Search Name"}
                sx={{
                  ml: 2,
                  mr: 2,
                  width: "100%",
                  borderRadius: "10px",
                  paddingLeft: "10px",
                  border: "1px solid #CCCCCC",
                }}
              />
            </Paper>
          </Box>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            sx={{
              height: "38px",
              mr: 2,
              borderRadius: "10px",
              color: "black",
            }}
            variant="contained"
            color="grayButton300"
            onClick={handleSubmit}
            disabled={modalName?.length>0?false:true}
          >
            SAVE
          </Button>
          <Button
            sx={{
              height: "38px",
              mr: 2,
              borderRadius: "10px",
            }}
            onClick={handleClose}
            variant="contained"
            color="redButton100"
          >
            CANCEL
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default SearchSaveModal;
