import { Box, Button, Typography, useTheme } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import locale from "../../i18n/locale";
import { LinearProgressWithLabel } from "../../utils/CommonComponent";
import ProfileProgressButtonLayout from "./ProfileProgressButtonLayout";
import { CheckCircle, ExpandLess, ExpandMore } from "@mui/icons-material";
import SVGButton from "./SVGButton";

import inProcess from "../../assets/CircularIcon/Yellow/Circular Icons__Yellow_In_Process.svg";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getProfileProgressData } from "../../redux/candidate/myProfileSlice";
import { useSelector } from "react-redux";
import useViewport from "./ViewPort";
import downArrow from "../../assets/Black_Down_Open - Copy.svg";
import upArrow from "../../assets/Black_Up_Close - Copy.svg";
import crayBot from "../../assets/CrayBotIcons/Crayon Craybot Notification-07.svg";
import { getDecodedToken, getToken } from "../../utils/Common";

export default function CandidateProfileCompletion({
  position = "relative",
  progressButton,
  setProgressButton,
  profileCompletion,
  rightBoxRef,
}) {
  const i18n = locale.en;
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const boxRef = useRef();
  const decodedToken = getDecodedToken(getToken());
  const currentStep = useSelector((state) => state.myProfileSli.currentStep);
  const profileProgressDataValues = useSelector(
    (state) => state.myProfileSli.profileProgressDataValues
  );
  // const [progressButton, setProgressButton] = useState(false);

  const getProgressData = async () => {
    const { payload } = await dispatch(getProfileProgressData());
  };

  const handlePageChange = (test) => {
    if (test === "a") {
      navigate("/candidate/my-profile");
    } else if (test === "b") {
      navigate("/candidate/my-cv");
    } else if (test === "c") {
      navigate("/candidate/assessment");
      // window.open(
      //   "https://docs.google.com/forms/d/e/1FAIpQLScKTA3IfBUw8xvdP03-hMFX1UObzO_pPcd5p61OsKAf3whbrg/viewform"
      // );
    } else if (test === "d") {
      navigate("candidate/my-cam");
    }
    setProgressButton(false);
  };

  const handleClickOutside = (event) => {
    if (boxRef.current && !boxRef.current.contains(event.target)) {
      // setProgressButton(false);
    }
  };

  const getColor = () => {
    let value;
    if (decodedToken?.data?.role_id === 5) {
      value = profileProgressDataValues?.my_info === 25 ? 100 : 0;
    } else {
      value =
        profileProgressDataValues?.my_info +
        profileProgressDataValues?.cv_basic_info +
        profileProgressDataValues?.study_life +
        profileProgressDataValues?.cv_work_life +
        profileProgressDataValues?.cv_refernce +
        profileProgressDataValues?.cam +
        profileProgressDataValues?.personality;
    }

    if (value === 0) {
      return "redButton";
    } else if (value <= 25) {
      return "peachPayment";
    } else if (value > 25 && value <= 50) {
      return "orangeButton";
    } else if (value > 50 && value <= 75) {
      return "tymeColor";
    } else if (value > 75 && value <= 100) {
      return "greenColor";
    }
  };

  useEffect(() => {
    getProgressData();
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [currentStep]);
  // useEffect(() => {
  //   if (candidatePageHeading && viewPortWidth < 568) {
  //     setProgressButton(false);
  //   }
  //   if (progressButton && viewPortWidth < 568) {
  //     setCandidatePageHeading(false);
  //     setProgressButton(true);
  //   }
  // }, [candidatePageHeading, progressButton, viewPortWidth]);

  return (
    <>
      <Box
        ref={rightBoxRef}
        sx={{
          // width: { xs: "50%", xl: "30%" },
          width: "100%",
          minWidth: { xs: "350px", xl: "400px" },
          background: "#ffff",
          borderRadius: progressButton ? "25px 0 0 0" : "0 0 25px 25px",
          boxShadow: "0px 8px 10px -2px #00000029",
          position: position,
          right: 0,
          display: "flex",
          flexDirection: "column",
          zIndex: "999",
          boxSizing: "border-box",
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "row" }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              flexGrow: 1,
              gap: "5px",
            }}
          >
            <Button
              variant="contained"
              color="lightGrey"
              sx={{
                fontSize: theme.typography.fontSize.sm,
                fontWeight: theme.typography.Bold,
                borderRadius: "25px 0 10px 0",
                boxShadow: "none !important",
                width: "150px",
                height: "45px",
                color: "black",
              }}
            >
              {"profile %"}
            </Button>
          </Box>

          <Button
            variant="contained"
            color={getColor()}
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              padding: "11px 24px 15px 24px",
              gap: "7px",
              minWidth: "60px !important",
              maxWidth: "60px !important",
              height: "45px",
              borderRadius: "0 0 0 10px",
              boxShadow: "none !important",
              zIndex: "999",
              fontSize: theme.typography.fontSize.xs,
              fontWeight: theme.typography.Bold,
            }}
            onClick={() => setProgressButton((prev) => !prev)}
          >
            {profileProgressDataValues
              ? decodedToken?.data?.role_id !== 5
                ? `${
                    profileProgressDataValues?.my_info +
                    profileProgressDataValues?.cv_basic_info +
                    profileProgressDataValues?.study_life +
                    profileProgressDataValues?.cv_work_life +
                    profileProgressDataValues?.cv_refernce +
                    profileProgressDataValues?.cam +
                    profileProgressDataValues?.personality
                  }%`
                : "100%"
              : "0%"}
          </Button>
          <Button
            variant="contained"
            color="lightGreyColor"
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              padding: "11px 24px 15px 24px",
              gap: "7px",
              width: { xs: "60px", tb: "65px" },
              height: "45px",
              borderRadius: "0",
              boxShadow: "none !important",
              zIndex: "999",
            }}
            onClick={() => setProgressButton((prev) => !prev)}
          >
            <ProfileProgressButtonLayout
              profileCompletion={profileProgressDataValues?.my_info}
              cvBasics={profileProgressDataValues?.cv_basic_info}
              studyLife={profileProgressDataValues?.study_life}
              workLife={profileProgressDataValues?.cv_work_life}
              references={profileProgressDataValues?.cv_refernce}
              mycam={profileProgressDataValues?.cam}
              assesment={profileProgressDataValues?.personality}
            />
            <Box
              component={"img"}
              src={progressButton ? upArrow : downArrow}
              sx={{
                width: "30px",
                height: "30px",
                position: "absolute",
                top: "13px",
              }}
            />
          </Button>
        </Box>

        {progressButton && (
          <Box
            ref={boxRef}
            sx={{
              backgroundColor: "white",
              position: "absolute",
              top: "45px",
              right: 0,
              width: "100%",
              boxShadow: "0px 5px 10px #00000029",
              zIndex: "999",
              borderRadius: "0 0 25px 25px",
              borderTopLeftRadius: 0,
              borderTopRightRadius: 0,
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                padding: "10px 20px 15px 15px",
              }}
              className="candidate-profile-viewer-dropdown"
            >
              <Box
                sx={{
                  display: "flex",
                  gap: "30px",
                  justifyContent: "flex-end",
                  marginBottom: "13px",
                }}
              >
                <Box
                  component={"img"}
                  src={crayBot}
                  sx={{
                    width: "100px",
                    height: "100px",
                    position: "absolute",
                    left: 0,
                  }}
                />

                <Box
                  sx={{ display: "flex", flexDirection: "column", gap: "10px" }}
                >
                  <Typography
                    sx={{
                      fontSize: theme.typography.fontSize.xs,
                      fontWeight: theme.typography.Regular,
                      textAlign: "left",
                      // color: "gray",
                      width: "223px",
                      lineHeight: "16px",
                    }}
                  >
                    Applications are only valid once you have completed the ‘my
                    info’ and ‘the basics’ section of your Crayon vitae.
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: theme.typography.fontSize.xs,
                      fontWeight: theme.typography.Regular,
                      textAlign: "left",
                      // color: "gray",
                      width: "200px",
                      lineHeight: "16px",
                    }}
                  >
                    Remember, the more you complete, the more you can compete!
                  </Typography>
                </Box>
              </Box>
              {decodedToken?.data?.role_id === 5 ? (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    gap: 1,
                  }}
                >
                  <Button
                    variant="contained"
                    sx={{
                      ":hover": {
                        backgroundColor: theme?.palette?.chart?.gray,
                        boxShadow: "none",
                      },
                      backgroundColor: theme?.palette?.chart?.gray,
                      color: "black",
                      borderRadius: "10px",
                      width: "100%",
                      display: "flex",
                      justifyContent: "start",
                      // gap: 1,
                      boxShadow: 0,
                      fontSize: theme.typography.fontSize.sm,
                      fontWeight: theme.typography.Bold,
                      height: "45px",
                      padding: "7px 15px 7px 12px",
                    }}
                    onClick={() => handlePageChange("a")}
                  >
                    {profileProgressDataValues?.my_info ? (
                      <CheckCircle
                        sx={{
                          height: "30px",
                          width: "30px",
                          marginRight: "10px",
                        }}
                        color="progressBar"
                      />
                    ) : (
                      <SVGButton
                        color={"white"}
                        source={inProcess}
                        height={{ xs: 25, lgm: 25 }}
                        width={{ xs: 25, lgm: 25 }}
                        margin={"0px 10px 0 0"}
                        startIconMargin={"0px !important"}
                        padding={"0px !important"}
                        justifyContent="center"
                        outerHeight={30}
                        outerWidth={30}
                      />
                    )}
                    my info
                    <Box
                      sx={{
                        flexGrow: 1,
                        display: "flex",
                        justifyContent: "end",
                        alignItems: "center",
                      }}
                    >
                      <Button
                        variant="contained"
                        color={
                          profileProgressDataValues?.my_info
                            ? "progressBar"
                            : "grayButton400"
                        }
                        sx={{
                          height: "6px",
                          minWidth: { xs: "7px", lgm: "10px" },
                          padding: "0px",
                          borderRadius: "5px",
                        }}
                      ></Button>
                    </Box>
                    <Typography
                      sx={{
                        fontSize: theme.typography.fontSize.xs,
                        fontWeight: theme.typography.Bold,
                        width: "90px",
                        textAlign: "end",
                      }}
                      color={"GrayText"}
                    >
                      {`${
                        profileProgressDataValues?.my_info === 25
                          ? "100"
                          : profileProgressDataValues?.my_info
                      }%` || "0%"}{" "}
                      (100%)
                    </Typography>
                  </Button>
                </Box>
              ) : (
                <>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      gap: 1,
                    }}
                  >
                    <Button
                      variant="contained"
                      sx={{
                        ":hover": {
                          backgroundColor: theme?.palette?.chart?.gray,
                          boxShadow: "none",
                        },
                        backgroundColor: theme?.palette?.chart?.gray,
                        color: "black",
                        borderRadius: "10px",
                        width: "100%",
                        display: "flex",
                        justifyContent: "start",
                        // gap: 1,
                        boxShadow: 0,
                        fontSize: theme.typography.fontSize.sm,
                        fontWeight: theme.typography.Bold,
                        height: "45px",
                        padding: "7px 15px 7px 12px",
                      }}
                      onClick={() => handlePageChange("a")}
                    >
                      {profileProgressDataValues?.my_info ? (
                        <CheckCircle
                          sx={{
                            height: "30px",
                            width: "30px",
                            marginRight: "10px",
                          }}
                          color="progressBar"
                        />
                      ) : (
                        <SVGButton
                          color={"white"}
                          source={inProcess}
                          height={{ xs: 25, lgm: 25 }}
                          width={{ xs: 25, lgm: 25 }}
                          margin={"0px 10px 0 0"}
                          startIconMargin={"0px !important"}
                          padding={"0px !important"}
                          justifyContent="center"
                          outerHeight={30}
                          outerWidth={30}
                        />
                      )}
                      my info
                      <Box
                        sx={{
                          flexGrow: 1,
                          display: "flex",
                          justifyContent: "end",
                          alignItems: "center",
                        }}
                      >
                        <Button
                          variant="contained"
                          color={
                            profileProgressDataValues?.my_info
                              ? "progressBar"
                              : "grayButton400"
                          }
                          sx={{
                            height: "6px",
                            minWidth: { xs: "7px", lgm: "10px" },
                            padding: "0px",
                            marginRight:"5px",
                            borderRadius: "5px",
                          }}
                        ></Button>
                      </Box>
                      <Typography
                        sx={{
                          fontSize: theme.typography.fontSize.xs,
                          fontWeight: theme.typography.Bold,
                          width: "10ch",
                          textAlign: "end",
                        }}
                        color={"GrayText"}
                      >
                        {`${profileProgressDataValues?.my_info}%` || "0%"} (25%)
                      </Typography>
                    </Button>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      gap: 1,
                      marginTop: "11px",
                    }}
                  >
                    <Button
                      variant="contained"
                      sx={{
                        ":hover": {
                          backgroundColor: theme?.palette?.chart?.gray,
                          boxShadow: "none",
                        },
                        backgroundColor: theme?.palette?.chart?.gray,
                        color: "black",
                        borderRadius: "10px",
                        width: "100%",
                        display: "flex",
                        justifyContent: "start",
                        // gap: 1,
                        boxShadow: 0,
                        fontSize: theme.typography.fontSize.sm,
                        fontWeight: theme.typography.Bold,
                        height: "45px",
                        padding: "7px 15px 7px 12px",
                      }}
                      onClick={() => handlePageChange("b")}
                    >
                      {profileProgressDataValues?.cv_basic_info > 0 &&
                      profileProgressDataValues?.cv_work_life > 0 &&
                      profileProgressDataValues?.study_life > 0 &&
                      profileProgressDataValues?.cv_refernce > 0 ? (
                        <CheckCircle
                          sx={{
                            height: "30px",
                            width: "30px",
                            marginRight: "10px",
                          }}
                          color="progressBar"
                        />
                      ) : (
                        <SVGButton
                          color={"white"}
                          source={inProcess}
                          height={{ xs: 25, lgm: 25 }}
                          width={{ xs: 25, lgm: 25 }}
                          margin={"0px 10px 0 0"}
                          startIconMargin={"0px !important"}
                          padding={"0px !important"}
                          justifyContent="center"
                          outerHeight={30}
                          outerWidth={30}
                        />
                      )}
                      Crayon vitae
                      <Box
                        sx={{
                          display: "flex",
                          padding: 0,
                          alignItems: "center",
                          justifyContent: "end",
                          flexGrow: 1,
                          gap: 0.2,
                        }}
                      >
                        <Button
                          variant="contained"
                          color={
                            profileProgressDataValues?.cv_basic_info
                              ? "progressBar"
                              : "grayButton400"
                          }
                          sx={{
                            height: "6px",
                            minWidth: { xs: "7px", lgm: "10px" },
                            padding: "0px",
                            borderRadius: "5px 0 0 5px",
                          }}
                        ></Button>
                        <Button
                          variant="contained"
                          color={
                            profileProgressDataValues?.cv_work_life
                              ? "progressBar"
                              : "grayButton400"
                          }
                          sx={{
                            height: "6px",
                            minWidth: { xs: "7px", lgm: "10px" },
                            padding: "0px",
                            borderRadius: "0px",
                          }}
                        ></Button>
                        <Button
                          variant="contained"
                          color={
                            profileProgressDataValues?.study_life
                              ? "progressBar"
                              : "grayButton400"
                          }
                          sx={{
                            height: "6px",
                            minWidth: { xs: "7px", lgm: "10px" },
                            padding: "0px",
                            borderRadius: "0px",
                          }}
                        ></Button>
                        <Button
                          variant="contained"
                          color={
                            profileProgressDataValues?.cv_refernce
                              ? "progressBar"
                              : "grayButton400"
                          }
                          sx={{
                            height: "6px",
                            minWidth: { xs: "7px", lgm: "10px" },
                            padding: "0px",
                            marginRight:"5px",
                            borderRadius: "0 5px 5px 0",
                          }}
                        ></Button>
                      </Box>
                      <Typography
                        sx={{
                          fontSize: theme.typography.fontSize.xs,
                          fontWeight: theme.typography.Bold,
                          width: "10ch",
                          textAlign: "end",
                        }}
                        color={"GrayText"}
                      >
                        {profileProgressDataValues?.cv_basic_info +
                          profileProgressDataValues?.cv_work_life +
                          profileProgressDataValues?.study_life +
                          profileProgressDataValues?.cv_refernce}
                        % (25%)
                      </Typography>
                    </Button>
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      gap: 1,
                      marginTop: "11px",
                    }}
                  >
                    <Button
                      variant="contained"
                      sx={{
                        ":hover": {
                          backgroundColor: theme?.palette?.chart?.gray,
                          boxShadow: "none",
                        },
                        backgroundColor: theme?.palette?.chart?.gray,
                        color: "black",
                        borderRadius: "10px",
                        width: "100%",
                        display: "flex",
                        justifyContent: "start",
                        // gap: 1,
                        boxShadow: 0,
                        fontSize: theme.typography.fontSize.sm,
                        fontWeight: theme.typography.Bold,
                        height: "45px",
                        padding: "7px 15px 7px 12px",
                      }}
                      onClick={() => handlePageChange("c")}
                    >
                      {profileProgressDataValues?.personality === 25 ? (
                        <CheckCircle
                          sx={{
                            height: "30px",
                            width: "30px",
                            marginRight: "10px",
                          }}
                          color="progressBar"
                        />
                      ) : (
                        <SVGButton
                          color={"white"}
                          margin={"0px 10px 0 0"}
                          source={inProcess}
                          height={{ xs: 25, lgm: 25 }}
                          width={{ xs: 25, lgm: 25 }}
                          startIconMargin={"0px !important"}
                          padding={"0px !important"}
                          justifyContent="center"
                          outerHeight={30}
                          outerWidth={30}
                        />
                      )}
                      assessment
                      <Box
                        sx={{
                          flexGrow: 1,
                          display: "flex",
                          justifyContent: "end",
                          alignItems: "center",
                        }}
                      >
                        <Button
                          variant="contained"
                          color={
                            profileProgressDataValues?.personality === 25
                              ? "progressBar"
                              : "grayButton400"
                          }
                          sx={{
                            height: "6px",
                            minWidth: { xs: "7px", lgm: "10px" },
                            padding: "0px",
                            borderRadius: "5px",
                            marginRight:"5px",
                          }}
                        ></Button>
                      </Box>
                      <Typography
                        sx={{
                          fontSize: theme.typography.fontSize.xs,
                          fontWeight: theme.typography.Bold,
                          width: "10ch",
                          textAlign: "end",
                        }}
                        color={"GrayText"}
                      >
                        {profileProgressDataValues?.personality === 25
                          ? "25%"
                          : "0%"}
                        (25%)
                      </Typography>
                    </Button>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      gap: 1,
                      marginTop: "11px",
                    }}
                  >
                    <Button
                      variant="contained"
                      sx={{
                        ":hover": {
                          backgroundColor: theme?.palette?.chart?.gray,
                          boxShadow: "none",
                        },
                        backgroundColor: theme?.palette?.chart?.gray,
                        color: "black",
                        borderRadius: "10px",
                        width: "100%",
                        display: "flex",
                        justifyContent: "start",
                        // gap: 1,
                        boxShadow: 0,
                        fontSize: theme.typography.fontSize.sm,
                        fontWeight: theme.typography.Bold,
                        height: "45px",
                        padding: "7px 15px 7px 12px",
                      }}
                      onClick={() => handlePageChange("d")}
                    >
                      {profileProgressDataValues?.cam === 25 ? (
                        <CheckCircle
                          sx={{
                            height: "30px",
                            width: "30px",
                            marginRight: "10px",
                          }}
                          color="progressBar"
                        />
                      ) : (
                        <SVGButton
                          color={"white"}
                          margin={"0px 10px 0 0"}
                          source={inProcess}
                          height={{ xs: 25, lgm: 25 }}
                          width={{ xs: 25, lgm: 25 }}
                          startIconMargin={"0px !important"}
                          padding={"0px !important"}
                          justifyContent="center"
                          outerHeight={30}
                          outerWidth={30}
                        />
                      )}
                      Crayon cam
                      <Box
                        sx={{
                          flexGrow: 1,
                          display: "flex",
                          justifyContent: "end",
                          alignItems: "center",
                        }}
                      >
                        <Button
                          variant="contained"
                          color={
                            profileProgressDataValues?.cam === 25
                              ? "progressBar"
                              : "grayButton400"
                          }
                          sx={{
                            height: "6px",
                            minWidth: { xs: "7px", lgm: "10px" },
                            padding: "0px",
                            borderRadius: "5px",
                            marginRight:"5px",
                          }}
                        ></Button>
                      </Box>
                      <Typography
                        sx={{
                          fontSize: theme.typography.fontSize.xs,
                          fontWeight: theme.typography.Bold,
                          width: "10ch",
                          textAlign: "end",
                        }}
                        color={"GrayText"}
                      >
                        {profileProgressDataValues?.cam === 25 ? "25%" : "0%"}
                        (25%)
                      </Typography>
                    </Button>
                  </Box>
                </>
              )}
            </Box>
          </Box>
        )}
      </Box>
    </>
  );
}
