import { Box, Button, Typography, useTheme } from "@mui/material";
import React from "react";
import CloseIcon from "../../../common/CloseIcon";
import { ToggleSwitch } from "../../../../utils/CommonComponent";

export default function HideItems({ onClose, settingsRef, setSettingButton }) {
  const theme = useTheme();
  return (
    <Box sx={{ width: "300px", height: "auto", background: "#FFFFFF" }}>
      <Box>
        <Button
          variant="contained"
          color="checkers"
          sx={{
            width: "150px",
            height: "45px",
            boxShadow: "none !important",
            borderRadius: "25px 0 10px 0",
            padding: "0px",
          }}
        >
          hide items
        </Button>
        <Box onClick={onClose}>
          <CloseIcon />
        </Box>
      </Box>

      <Box
        sx={{
          padding: "10px 10px 14px 16px",
          display: "flex",
          flexDirection: "column",
          gap: "15px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography
            sx={{
              fontSize: theme.typography.fontSize.sm,
              fontWeight: theme.typography.Regular,
            }}
          >
            Hide your profile
          </Typography>

          <ToggleSwitch
            color="greenSwitch"
            //   checked={JSON?.parse(work?.completed?.toLowerCase())}
            //   onChange={(event, id, name) =>
            //     handleSelectChange2(event, id, name, index)
            //   }
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography
            sx={{
              fontSize: theme.typography.fontSize.sm,
              fontWeight: theme.typography.Regular,
            }}
          >
            Hide your age
          </Typography>

          <ToggleSwitch
            color="greenSwitch"
            //   checked={JSON?.parse(work?.completed?.toLowerCase())}
            //   onChange={(event, id, name) =>
            //     handleSelectChange2(event, id, name, index)
            //   }
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography
            sx={{
              fontSize: theme.typography.fontSize.sm,
              fontWeight: theme.typography.Regular,
            }}
          >
            Hide your profile picture
          </Typography>

          <ToggleSwitch
            color="greenSwitch"
            //   checked={JSON?.parse(work?.completed?.toLowerCase())}
            //   onChange={(event, id, name) =>
            //     handleSelectChange2(event, id, name, index)
            //   }
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography
            sx={{
              fontSize: theme.typography.fontSize.sm,
              fontWeight: theme.typography.Regular,
            }}
          >
            Hide your video
          </Typography>

          <ToggleSwitch
            color="greenSwitch"
            //   checked={JSON?.parse(work?.completed?.toLowerCase())}
            //   onChange={(event, id, name) =>
            //     handleSelectChange2(event, id, name, index)
            //   }
          />
        </Box>
      </Box>

      <Box>
        <Button
          variant="contained"
          color="darkGrey"
          sx={{
            width: "100%",
            height: "60px",
            borderRadius: "0 0 25px 25px",
            color: "black",
          }}
          onClick={() => {
            setSettingButton(settingsRef?.current);
            onClose();
          }}
        >
          back to settings
        </Button>
      </Box>
    </Box>
  );
}
