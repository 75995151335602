import { Box, Button, Typography, useTheme } from "@mui/material";
import React, { useState } from "react";
import pricingTabIcon from "../../../../assets/TopBarIcons/PricingTabIcon.svg";
import CustomDialog from "../../../common/CustomDialog";
import { BlueSwitch } from "../../../../utils/CommonComponent";

export default function AdminUnlockJob({
  handleClose,
  text1,
  text1Width,
  text2,
  text2a,
  text2Width,
  text3,
  text3Width,
  text4Width,
  text4,
  text5,
  text5Width,
  buttonText,
  show,
  coinsBalance,
  coinsUse,
  buttonClick,
  gap,
  display = "inline-block",
  minHeight = "300px",
  confirmUnlock,
  setConfirmUnlock,
}) {
  const theme = useTheme();

  return (
    <CustomDialog
      show={show}
      hideButton={false}
      dialogWidth="xs"
      showFooter={false}
      onDialogClose={handleClose}
      padding={0}
    >
      <Box
        sx={{
          width: "360px",
          minHeight: minHeight,
          margin: "auto",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: gap,
        }}
      >
        <Box
          component={"img"}
          src={pricingTabIcon}
          sx={{ height: 75, width: 96 }}
        />
        <Typography
          sx={{
            fontSize: "20px",
            fontWeight: theme.typography.Bold,
            width: text1Width,
            textAlign: "center",
          }}
        >
          {text1}
        </Typography>
        <Typography
          sx={{
            fontSize: "18px",
            fontWeight: theme.typography.Medium,
            width: text2Width,
            textAlign: "center",
          }}
        >
          {text2}{" "}
          {
            <span
              style={{ fontSize: "18px", fontWeight: theme.typography.Bold }}
            >
              {coinsBalance}
            </span>
          }{" "}
          {text2a}
        </Typography>
        <Typography
          sx={{
            fontSize: "18px",
            fontWeight: theme.typography.Medium,
            width: text3Width,
            textAlign: "center",
            display: display,
          }}
        >
          {text3}{" "}
          {
            <span
              style={{ fontSize: "18px", fontWeight: theme.typography.Bold }}
            >
              {coinsUse}
            </span>
          }
        </Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "8px",
          }}
        >
          <Typography
            sx={{ fontSize: "18px", fontWeight: theme.typography.Medium }}
          >
            no
          </Typography>
          <BlueSwitch
            checked={confirmUnlock}
            onChange={(event) => setConfirmUnlock(event?.target?.checked)}
          />
          <Typography
            sx={{ fontSize: "18px", fontWeight: theme.typography.Medium }}
          >
            yes
          </Typography>
        </Box>
      </Box>

      <Button
        variant="contained"
        color="getCoins"
        sx={{
          width: "100%",
          height: "60px",
          borderRadius: "0 0 0 25px",
        }}
        onClick={() => buttonClick(confirmUnlock)}
      >
        unlock
      </Button>
    </CustomDialog>
  );
}
