import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import { useTheme } from "@mui/material/styles";
import { Typography } from "@mui/material";
// import useViewport from "../../common/ViewPort";

export default function LeftSideProfilePanel({
  panelData,
  side,
  onChangeFilter = () => {},
  selectedButton,
  color = "blueButton800",
}) {
  const theme = useTheme();
  const [selectedBtns, setSelectedBtn] = useState(selectedButton);
  // const { width } = useViewport();

  useEffect(() => {
    setSelectedBtn(selectedButton);
  }, [panelData, selectedButton]);

  const removeBtnSelection = (id) => {
    setSelectedBtn(id);
  };
  const handleButtonClick = (event, { id, label }) => {
    setSelectedBtn(id);
    removeBtnSelection(id);
    onChangeFilter(id);
  };

  return (
    <Box
      sx={{
        textAlign: side === "right" && "end",
        // display: { xs: "none", sm: "flex" },
        display: { xs: "flex" },
        flexDirection: "column",
        gap: "10px",
      }}
    >
      {panelData?.map((btn) => (
        <Tooltip
          disableInteractive
          title={btn?.name}
          key={btn?.id}
          placement="top-end"
        >
          <Button
            sx={{
              padding: "0px",
              lineHeight: "inherit",
              borderRadius: "10px",
              // boxShadow: "0px 5px 10px #00000029 !important",
              borderBottomLeftRadius: side === "left" ? { sm: 0 } : "10px",
              borderTopLeftRadius: side === "left" ? { sm: 0 } : "10px",
              borderTopRightRadius: side === "right" ? { sm: 0 } : "10px",
              borderBottomRightRadius: side === "right" ? { sm: 0 } : "10px",
              mr: { xs: 1, sm: 0 },
              width: "125px",
              // minWidth: { xs: "90px", sm: 0 },
              fontWeight:
                btn?.name || selectedBtns === btn?.id
                  ? theme.typography.Bold
                  : theme.typography.Regular,
              "&:hover": {
                // opacity: 1,
                // backgroundColor: theme.palette[btn.color]?.main,
                backgroundColor: theme.palette[color].main,
                color: theme.palette.white,
                fontWeight: theme.typography.Bold,
              },

              height: "45px",
              // opacity: selectedBtns.includes(btn.id) ? 1 : 0.5
            }}
            onClick={(e) => {
              handleButtonClick(e, btn);
            }}
            disableElevation
            variant="contained"
            color={selectedBtns === btn?.id ? color : "base"}
            key={btn?.id}
          >
            <Typography
              sx={{
                fontSize: theme.typography.fontSize.xs,
                minWidth: "100px",
                color: selectedBtns === btn?.id && "white",
                fontWeight:
                  selectedBtns === btn?.id || btn?.title
                    ? theme.typography.Bold
                    : theme.typography.Regular,
              }}
            >
              {btn?.name}
            </Typography>
          </Button>
        </Tooltip>
      ))}
    </Box>
  );
}
