import React from "react";
import { Box, Button, Typography } from "@mui/material";
import greenTriangle from "../../assets/Characters/CrayBotIcons/Crayon Craybot Notification-09.svg";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@emotion/react";

export default function NoJobs({ text1, text2 }) {
  const naviagte = useNavigate();
  const theme = useTheme();
  return (
    <Box
      sx={{
        textAlign: "center",
        display: "flex",
        flexDirection: "column",
        gap: "6px",
        height: "340px",
        width: "360px",
        backgroundColor: "white",
        borderRadius: "25px",
        marginTop: "13px",
      }}
    >
      <Box
        component={"img"}
        src={greenTriangle}
        sx={{
          width: 150,
          height: 150,
          margin: "0 auto",
          marginTop: "10px",
          marginBottom: "18px",
        }}
      />

      <Typography
        sx={{
          fontSize: "20px",
          fontWeight: theme.typography.Bold,
          textAlign: "center",
          letterSpacing: "0.4px",
          // paddingX: 3,
        }}
      >
        {text1} <br /> {text2}
      </Typography>
      <Typography
        sx={{
          fontSize: "14px",
          fontWeight: theme.typography.Bold,
          letterSpacing: "0.28px",
        }}
      >
        Let's get cracking!
      </Typography>

      <Button
        variant="contained"
        sx={{
          borderRadius: "0px 0px 25px 25px",
          width: "100%",
          height: "60px",
          marginTop: "20px",
        }}
        color="redButton"
        onClick={() => naviagte("/jobs")}
      >
        share a job
      </Button>
    </Box>
  );
}
