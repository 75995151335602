import { Box, Button, Popover, Typography } from "@mui/material";
import React, { useState } from "react";

import Like from "../../../assets/StatusIcons/Like.svg";
import Pending from "../../../assets/StatusIcons/Pending.svg";
import IloveThis from "../../../assets/StatusIcons/LoveThisIcon.svg";
import NotForMe from "../../../assets/StatusIcons/Black_Reject.svg";
import upArrow from "../../../assets/Padding Excluded/White_Up_Close.svg";
import downArrow from "../../../assets/Padding Excluded/White_Down_Open.svg";
import rejectWhite from "../../../assets/Padding Excluded/RejectedIconWhite.svg";
import hiredWhite from "../../../assets/Padding Excluded/Green_Like_White.svg";

const statusIconComponents = {
  pending: Pending,
  "i like this": Like,
  "i love this": IloveThis,
  "not for me": NotForMe,
  rejected: rejectWhite,
  hired: hiredWhite,
};

const StatusButton = ({
  selectedStatus,
  options,
  handleStatusChange,
  cardWidth,
  matchButton,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event?.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const setButtonColor = (column) => {
    switch (column) {
      case "pending":
        return "oros";
      case "i like this":
        return "yoco";
      case "i love this":
        return "safair";
      case "not for me":
        return "peachPayment";
      case "hired":
        return "springboks";
      case "rejected":
        return "nandos";
      default:
        return "filterButtons";
    }
  };

  return (
    <>
      {(selectedStatus === "rejected" || selectedStatus === "hired") && (
        <Button
          sx={{
            fontSize: "14px",
            gap: "8px",
            borderRadius: "0 0 25px 0",
            width: "100%",
            height: "100%",
            color: "white !important",
            ":hover": {
              boxShadow: 0,
            },
            ".MuiButton-startIcon": {
              marginRight: "0px !important",
              marginLeft: 0,
            },
            padding: "0px !important",
          }}
          color={setButtonColor(selectedStatus)}
          variant="contained"
        >
          <Box
            component={"img"}
            sx={{
              height: 16,
              width: 16,
            }}
            alt="Status"
            src={statusIconComponents[selectedStatus]}
          />
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: "bold",
            }}
          >
            {selectedStatus}
          </Typography>
        </Button>
      )}
      <Button
        sx={{
          fontSize: "14px",
          justifyContent: "space-evenly !important",
          borderRadius: "0 0 25px 0",
          width: "100%",
          height: "100%",
          color: "white",
          ":hover": {
            boxShadow: 0,
          },
          ".MuiButton-startIcon": {
            marginRight: "0px !important",
            marginLeft: 0,
          },
          padding: "0px !important",
        }}
        color={setButtonColor(selectedStatus)}
        variant="contained"
        onClick={handleClick}
      >
        <Box
          component={"img"}
          sx={{
            height: 16,
            width: 16,
          }}
          alt="Status"
          src={statusIconComponents[selectedStatus]}
        />
        <Typography
          sx={{
            fontSize: "14px",
            fontWeight: "bold",
          }}
        >
          {selectedStatus}
        </Typography>
        <Box
          component={"img"}
          sx={{
            height: 13,
            width: 13,
          }}
          alt="Status"
          src={open ? downArrow : upArrow}
        />
      </Button>

      <Popover
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        sx={{
          "& .MuiPaper-root": {
            borderRadius: "10px 0px 0px 0px !important",
            boxShadow: "none",
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
          width={cardWidth}
        >
          {options
            ?.filter(
              (item) => item?.name !== "rejected" && item?.name !== "hired"
            )
            ?.map((status) => {
              const IconComponent = statusIconComponents[status?.name];
              return (
                <Button
                  variant="contained"
                  color={setButtonColor(status?.name)}
                  width={cardWidth}
                  sx={{
                    justifyContent: "space-evenly !important",
                    borderRadius: "0px",
                    padding: "6px 0px !important",
                    height: "60px",
                    color: "white",
                    ":hover": {
                      boxShadow: 0,
                    },
                  }}
                  onClick={() => {
                    handleStatusChange(status?.id);
                    handleClose();
                  }}
                >
                  <Box
                    component={"img"}
                    sx={{
                      height: 16,
                      width: 16,
                    }}
                    alt="Status"
                    src={IconComponent}
                  />
                  <Typography
                    sx={{
                      fontSize: "14px",
                      fontWeight: "bold",
                    }}
                  >
                    {status?.name}
                  </Typography>
                </Button>
              );
            })}
        </Box>
      </Popover>
    </>
  );
};

export default StatusButton;
