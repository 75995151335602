import { Box, Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import joined from "../../../assets/Badges/employerProfileBadges/joined.svg";
import profileDone from "../../../assets/Badges/employerProfileBadges/profileDone.svg";
import companyDone from "../../../assets/Badges/employerProfileBadges/companyInfoDone.svg";
import teamMemberDone from "../../../assets/Badges/employerProfileBadges/addedNewMember.svg";
import jobPostedDone from "../../../assets/Badges/employerProfileBadges/postedAJob.svg";
import cvUnlockedDone from "../../../assets/Badges/employerProfileBadges/cvUnlocked.svg";
import candidateShortlistedDone from "../../../assets/Badges/employerProfileBadges/candidateShortlisted.svg";
import hiredDone from "../../../assets/Badges/employerProfileBadges/hired.svg";
import sharedJob from "../../../assets/Badges/promoter/Coloured/crayon_promoter_shared a job.svg";
import refferal from "../../../assets/Badges/promoter/Coloured/crayon_promoter_refferal signed up.svg";

import grayJoined from "../../../assets/Badges/employerProfileBadges/grayIcons/grayJoined.svg";
import grayProfileDone from "../../../assets/Badges/employerProfileBadges/grayIcons/grayProfileDone.svg";
import grayCompanyDone from "../../../assets/Badges/employerProfileBadges/grayIcons/grayCompanyInfoDone.svg";
import grayTeamMemberDone from "../../../assets/Badges/employerProfileBadges/grayIcons/grayMemberAdded.svg";
import grayJobPostedDone from "../../../assets/Badges/employerProfileBadges/grayIcons/grayjobposted.svg";
import grayCVUnlockedDone from "../../../assets/Badges/employerProfileBadges/grayIcons/graycvUnlocked.svg";
import grayCandidateShortlistedDone from "../../../assets/Badges/employerProfileBadges/grayIcons/grayCandidateShortlist.svg";
import grayHiredDone from "../../../assets/Badges/employerProfileBadges/grayIcons/grayHired.svg";
import refferalGray from "../../../assets/Badges/promoter/Gray badges/crayon_promoter_grey_refferal signed up.svg";
import sharedJobGray from "../../../assets/Badges/promoter/Gray badges/crayon_promoter_grey_shared a job.svg";
import { getDecodedToken, getToken } from "../../../utils/Common";

import leftIcon from "../../../assets/Black_Left_Previous - Copy.svg";
import rightIcon from "../../../assets/Black_Right_Next - Copy.svg";
import useViewport from "../../common/ViewPort";

export default function Badges({ badgesData }) {
  const decodedToken = getDecodedToken(getToken());
  const [windowWidth, setWindowWidth] = useState();
  const { width } = useViewport();

  const [badgesArr, setBadgesArr] = useState([]);

  const handleLeftClick3 = () => {
    setBadgesArr([
      ...badgesArr?.slice(badgesArr?.length - 1),
      ...badgesArr?.slice(0, badgesArr?.length - 1),
    ]);
  };

  const handleRightClick3 = () => {
    setBadgesArr([...badgesArr?.slice(1), ...badgesArr?.slice(0, 1)]);
  };

  useEffect(() => {
    setWindowWidth(window.innerWidth);
  }, []);

  useEffect(() => {
    setBadgesArr([
      {
        id: 1,
        name: "joined",
        status: badgesData?.joined,
        colored: joined,
        gray: grayJoined,
      },
      {
        id: 2,
        name: "profile_pic",
        status: badgesData?.profile_pic,
        colored: profileDone,
        gray: grayProfileDone,
      },
      // {
      //   id: 3,
      //   name: "company_info",
      //   status: badgesData?.company_info,
      //   colored: companyDone,
      //   gray: grayCompanyDone,
      // },
      {
        id: 4,
        name: "added_team_member",
        status: badgesData?.added_team_member,
        colored: teamMemberDone,
        gray: grayTeamMemberDone,
      },
      {
        id: 5,
        name: "posted_job",
        status: badgesData?.posted_job,
        colored: jobPostedDone,
        gray: grayJobPostedDone,
      },
      {
        id: 6,
        name: "unlocked_cv",
        status: badgesData?.unlocked_cv,
        colored: cvUnlockedDone,
        gray: grayCVUnlockedDone,
      },
      {
        id: 7,
        name: "shortlisted_candidate",
        status: badgesData?.shortlisted_candidate,
        colored: candidateShortlistedDone,
        gray: grayCandidateShortlistedDone,
      },
      {
        id: 8,
        name: "hired",
        status: badgesData?.hired,
        colored: hiredDone,
        gray: grayHiredDone,
      },
      {
        id: 9,
        name: "shared_job",
        status: badgesData?.shared_job,
        colored: sharedJob,
        gray: sharedJobGray,
      },
      {
        id: 10,
        name: "referral_signedup",
        status: badgesData?.referral_signedup,
        colored: refferal,
        gray: refferalGray,
      },
    ]);
  }, [badgesData]);

  return (
    <>
      <Button
        variant="contained"
        color="boxTitleButton"
        sx={{
          height: "45px",
          minWidth:"125px",
          // width: {
          //   xs: "70px",
          //   sm: "90px",
          //   md: "90px",
          //   lg: "120px",
          //   xl: "150px",
          // },
          fontSize: {
            xs: "10px",
            sm: "12px",
            md: "12px",
            lg: "12px",
            xl: "14px",
          },
          borderRadius: "25px 0px 10px 0px",
          cursor: "default",
          ":hover": {
            boxShadow: "none !important",
          },
        }}
      >
        my badges
      </Button>
      <Box
        sx={{
          display: "flex",
          paddingY: "15px",
          alignItems: windowWidth < 1500 && "center",
          overflow: "auto",
          gap: "10px",
          // flexWrap: "wrap",
        }}
        // width={width < 400 ? "100%" : `calc(100% - 150px)`}
        width={width < 400 ? "100%" : "100%"}
      >
        {windowWidth < 1500 && (
          <Box
            component={"img"}
            src={leftIcon}
            sx={{
              height: "20px",
              width: "20px",
              cursor: "pointer",
            }}
            onClick={handleLeftClick3}
          />
        )}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",

            gap: {
              sm: "3px",
              lgmm: "10px",
              xl: "10px",
              xll: "10px",
            },
            overflow: "hidden",
          }}
        >
          {badgesArr?.map((item) => {
            return (
              <Box
                component="img"
                height={{
                  xs: 90,
                  lg: 90,
                  lgm: 110,
                  mlg: 110,
                  xl: 125,
                  xxl: 150,
                }}
                alt="Joined"
                src={item?.status ? item?.colored : item?.gray}
              />
            );
          })}
        </Box>
        {windowWidth < 1500 && (
          <Box
            sx={{
              height: "120px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Box
              component={"img"}
              src={rightIcon}
              sx={{
                height: "20px",
                width: "20px",
                cursor: "pointer",
              }}
              onClick={handleRightClick3}
            />
          </Box>
        )}
      </Box>
    </>
  );
}
