import { createTheme } from "@mui/material/styles";

import { jobListingPageTheme } from "../Theme";
const whosHiringTheme = createTheme({
  ...jobListingPageTheme,
  palette: {
    ...jobListingPageTheme.palette,
    companySizeType: {
      main: "#0C486B",
      contrastText: "#FFF",
    },
    industriesButton: {
      main: "#0077C2",
      contrastText: "#FFF",
    },

    viewJobs: {
      main: "#FFCB00",
      contrastText: "#FFF",
    },
    flipButton: {
      main: "#D1011B",
      contrastText: "#FFF",
    },
    jobTypeButton: {
      main: "#0C486B",
      contrastText: "#FFF",
    },
    followButton: {
      main: "#E4047C",
      contrastText: "#FFF",
    },
    chart: {
      ...jobListingPageTheme.palette.chart,
      Interviewed: "#CCF381",
      Applied: "#FFCB00",
      Shortlisted: "#FFCB00",
      grit_score: "#482683",
      strongMatch: "#47D281",
      averageMatch: "#FF9900",
      weakMatch: "#D1011B",
      jobposting: "#38A8AE",
      activeJobs: "#47D281",
      hires: "#429867",
    },
  },
});

export { whosHiringTheme };
