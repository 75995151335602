import React, { useState } from "react";
import { Box, Button, InputBase, Modal, Paper } from "@mui/material";
import { useTheme } from "@emotion/react";
import CustomSelect from "../../../candidate/myCV/CustomSelect";
export default function Edit({
  show,
  handleOpen,
  handleEdit,
  inputName,
  handleEditJob,
  existingCompany,
  data,
  dialogText,
  singleInput,
  handleOnChange,
  setExistingCompany,
  setcompanyName,
  isLoading,
}) {
  const theme = useTheme();
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    borderRadius: "10px",
    boxShadow: "none !important",
  };
  const [touched, setTouched] = useState({
    inputName: false,
    existingCompany: false,
  });

  const validation = () => {
    setTouched({
      inputName: true,
      existingCompany: true,
    });
  };

  const handleEditValidation = () => {
    if (!inputName && !existingCompany) {
      validation();
    } else {
      handleEdit();
    }
  };

  const handleOnSelectChange = async (ind, newValue, val, index) => {
    if (index === "existing_name") {
      setExistingCompany(newValue);
      setcompanyName("");
    }
  };
  return (
    <Modal
      open={show}
      onClose={handleOpen}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Box sx={{ padding: 4 }}>
          <Paper
            sx={{
              display: "flex",
              height: "40px",
              borderRadius: "10px",
              boxShadow: "none",
              border: touched.inputName
                ? "1px solid red"
                : `1px solid ${theme.palette.grayBorder}`,
            }}
          >
            <InputBase
              sx={{
                ml: 2,
                mr: 2,
                width: "100%",
              }}
              id="edit_input"
              value={inputName}
              onChange={handleEditJob}
              placeholder={`Change current ${dialogText} name`}
              onFocus={() => {
                setTouched({
                  inputName: false,
                  existingCompany: false,
                });
              }}
            />
          </Paper>
        </Box>
        {!singleInput && (
          <>
            <div style={{ display: "flex", alignItems: "center" }}>
              <hr style={{ flexGrow: 1, marginRight: "10px" }} />
              <span>Or</span>
              <hr style={{ flexGrow: 1, marginLeft: "10px" }} />
            </div>
            <Box sx={{ padding: 4 }}>
              <CustomSelect
                onMenuOpen={() => {}}
                variant="default"
                onInputChange={handleOnChange}
                onChange={handleOnSelectChange}
                placeholder={`Select from existing ${dialogText}`}
                inputId="existing_name"
                name="existing_name"
                value={existingCompany}
                isLoading={isLoading}
                customStyle={{
                  menu: (provided) => ({
                    ...provided,
                    zIndex: 9999,
                  }),
                  menuList: (provided) => ({
                    ...provided,
                    "&::-webkit-scrollbar": {
                      width: "6px",
                      height: "6px",
                      borderRadius: "2.5px",
                      backgroundColor: "#efefef",
                    },
                    "&::-webkit-scrollbar-thumb": {
                      borderRadius: "2.5px",
                    },
                  }),
                  option: (
                    styles,
                    { data, isDisabled, isFocused, isSelected }
                  ) => {
                    return {
                      ...styles,
                      color: isSelected ? "#fff" : "black",
                      ":active": {
                        ...styles[":active"],
                      },
                    };
                  },
                  control: (baseStyles) => ({
                    ...baseStyles,
                    boxShadow: "none",
                    borderRadius: "10px",
                    border: touched.existingCompany
                      ? "1px solid red"
                      : "1px solid #E0E0E0",
                    "&:hover": touched.existingCompany
                      ? "1px solid red"
                      : "1px solid #E0E0E0",
                    width: `100%`,
                  }),
                }}
                option={data?.map((item) => ({
                  value: item?.id,
                  label: item?.name,
                }))}
              />
            </Box>
          </>
        )}
        <Box>
          <Button
            sx={{
              boxShadow: 0,
              fontSize: "14px",
              width: "50%",
              borderRadius: "0 0 0 10px",
              height: "43px",
              background: "lightgray",
              color: "black",
              padding: 3,
              ":hover": {
                background: "lightgray",
              },
            }}
            variant="contained"
            onClick={handleOpen}
          >
            cancel
          </Button>
          <Button
            sx={{
              boxShadow: 0,
              fontSize: "14px",
              width: "50%",
              borderRadius: "0 0 10px 0",
              height: "43px",
              padding: 3,
            }}
            variant="contained"
            color="redButton100"
            onClick={handleEditValidation}
          >
            edit
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}
