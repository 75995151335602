import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getApi, postApi } from "../../utils/Apis";
import { setLoading } from "../configSlice";
import { PUBLIC_TAB_ITEMS } from "../../utils/Constants";

const initialState = {
  initialPopup: false,
  currentTabs:PUBLIC_TAB_ITEMS,
  isAdmin:false,
  activeTab:""
};

export const signup = createAsyncThunk(
  "signup",
  async (payload, { dispatch }) => {
    dispatch(setLoading(true));
    const { data } = await postApi("/signup", payload);
    dispatch(setLoading(false));
    return data;
  }
);

export const forgotPassword = createAsyncThunk(
  "forgotPassword",
  async (payload, { dispatch }) => {
    dispatch(setLoading(true));
    const { data } = await postApi("/forgotpassword", payload);
    dispatch(setLoading(false));
    return data;
  }
);

export const resetPassword = createAsyncThunk(
  "resetPassword",
  async ({ token, payload }, { dispatch }) => {
    dispatch(setLoading(true));
    const { data } = await postApi(
      `/jobs/resetpassword?token=${token}`,
      payload
    );
    dispatch(setLoading(false));
    return data;
  }
);

export const login = createAsyncThunk(
  "login",
  async (payload, { dispatch }) => {
    dispatch(setLoading(true));
    const { data } = await postApi("/login", payload);
    dispatch(setLoading(false));
    return data;
  }
);

export const logout = createAsyncThunk(
  "logout",
  async (payload, { dispatch }) => {
    dispatch(setLoading(true));
    const { data } = await getApi("/admin/logout", true);
    dispatch(setLoading(false));
    return data;
  }
);
export const getBillingDetails = createAsyncThunk(
  "getBillingDetails",
  async (payload, { dispatch }) => {
    // dispatch(setLoading(true));
    const { data } = await getApi(`/getbillinginfo?company_id=${payload?.company_id}`, true);
    // dispatch(setLoading(false));
    return data;
  }
);

export const addBillingDetails = createAsyncThunk(
  "addBillingDetails",
  async (payload, { dispatch }) => {
    dispatch(setLoading(true));
    const { data } = await postApi("/addbillinginfo", payload);
    dispatch(setLoading(false));
    return data;
  }
);

export const getCompanyList = createAsyncThunk(
  "getCompanyList",
  async (payload, { dispatch }) => {
    dispatch(setLoading(true));
    const { data } = await getApi(payload?.recent?`/getcompanyinfo?recent=${true}&lastKey=${payload?.page}`:`/getcompanyinfo?popular=${true}&lastKey=${payload?.page}`);
    dispatch(setLoading(false));
    return data;
  }
);


export const resendMail = createAsyncThunk(
  "resendMail",
  async ({ email }, { dispatch }) => {
    dispatch(setLoading(true));
    const { data } = await getApi(
      email !== ""
        ? `/resendverification?type=signup&Email=${email}`
        : "/resendverification?type=signup",
      true
    );
    dispatch(setLoading(false));
    return data;
  }
);

export const resendMailPost = createAsyncThunk(
  "resendMailPost",
  async (payload, { dispatch }) => {
    dispatch(setLoading(true));
    const { data } = await postApi("/resendverification", payload);
    dispatch(setLoading(false));
    return data;
  }
);

export const profileVerify = createAsyncThunk(
  "profileVerify",
  async ({ token }, { dispatch }) => {
    dispatch(setLoading(true));
    const { data } = await getApi(`/jobs/profileverify?token=${token}`, true);
    dispatch(setLoading(false));
    return data;
  }
);

export const getPaymentdetail = createAsyncThunk(
  "getPaymentdetail",
  async ({ param }, { dispatch }) => {
    dispatch(setLoading(true));
    const { data } = await getApi(`/getpaymentpagedetail?payment_transaction_id=${param}`, true);
    dispatch(setLoading(false));
    return data;
  }
);
export const loginSlice = createSlice({
  name: "config",
  initialState,
  reducers: {
    setInitialPopup: (state, action) => {
      state.initialPopup = action.payload;
    },
    topBarCurrentTab:(state,action)=>{
      state.currentTabs = action.payload;
    },
    setIsAdmin:(state,action)=>{
      state.isAdmin = action.payload;
    },
    setActiveTab:(state,action)=>{
      state.activeTab = action.payload;
    },
  },
});

export const {setActiveTab, setInitialPopup,topBarCurrentTab,setIsAdmin } = loginSlice.actions;

export default loginSlice.reducer;
