import { useTheme } from "@mui/material/styles";

import { Paper, InputBase } from "@mui/material";
import { Modal } from "@mui/material";
import React, { useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import CustomDialog from "../../../common/CustomDialog";

export default function ConfirmationPopUp({
  handleOpen,
  handleClose,
  handleYes,
  info,
}) {
  return (
    <CustomDialog
      show={handleOpen}
      hideButton={false}
      dialogWidth="xs"
      showFooter={false}
      onDialogClose={handleClose}
      padding={0}
    >
      <Box>
        <Typography
          fontSize={16}
          fontWeight={"bold"}
          textAlign={"center"}
          paragraph
          sx={{
            padding: 4,
          }}
        >
          Please confirm that you want to continue.
        </Typography>
        <Box>
          <Button
            sx={{
              boxShadow: 0,
              fontSize: "14px",
              width: "50%",
              borderRadius: 0,
              height: "43px",
              background: "lightgray",
              color: "black",
              padding: 3,
            }}
            color="greyPlayButton"
            variant="contained"
            onClick={handleClose}
          >
            cancel
          </Button>
          <Button
            sx={{
              boxShadow: 0,
              fontSize: "14px",
              width: "50%",
              borderRadius: 0,
              height: "43px",
              padding: 3,
            }}
            variant="contained"
            color="redButton100"
            onClick={() => {
              handleYes();
            }}
          >
            continue
          </Button>
        </Box>
      </Box>
    </CustomDialog>
  );
}
