import { Box, Button, Typography, useTheme } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import InformationIcon from "../../../assets/InformationIcon.svg";

export default function PreLoginLeftSideBox({
  employerRightBoxY,
  showSearchBar,
  firstTypographyText,
  secondTypographyText,
  profileSteps,
}) {
  const params = useLocation();
  const theme = useTheme();
  const leftBoxRef = useRef();

  let leftSideText;
  const cvSteps = useSelector((state) => state?.myProfileSli?.cvSteps);
  const cardsPadding = useSelector(
    (state) => state?.configjobslice?.cardsPadding
  );

  const filterExpanded = useSelector(
    (state) => state?.configjobslice?.filterExpanded
  );

  const [pageHeading, setPageHeading] = useState(false);
  switch (true) {
    case params.pathname.includes("/jobs"):
      leftSideText = "find work";
      break;
    case params.pathname.includes("/talent"):
      leftSideText = "find talent";
      break;
    case params?.pathname?.includes("/whoshiring"):
      leftSideText = "find a biz";
      break;
    case params?.pathname?.includes("/solutions"):
      leftSideText = "the ideas page";
      break;
    case params?.pathname === "/contact":
      leftSideText = "say hi to us";
      break;
    case params?.pathname?.includes("payment"):
      leftSideText = "payment";
      break;
    case params?.pathname === "/":
      leftSideText = "welcome";
      break;
    case params?.pathname === "/assessment":
      leftSideText = "DISCover me";
      break;
    case params?.pathname === "/pricing":
      leftSideText = "price is nice";
      break;
    case params?.pathname === "/forbidden":
      leftSideText = "hi, howzit!";
      break;
    case params?.pathname === "/pageNotFound":
      leftSideText = "hi, howzit!";
      break;
    case params?.pathname === "/privacyPolicy":
        leftSideText = "the legals";
      break;
    case params?.pathname === "/termsOfService":
        leftSideText = "the legals";
      break;
    default:
      break;
  }

  const handleOutsideClick = (event) => {
    if (leftBoxRef?.current && !leftBoxRef?.current?.contains(event?.target)) {
      setPageHeading(false);
    }
  };
  useEffect(() => {
    document?.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document?.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [params]);

  return (
    <>
      {!pageHeading ? (
        <Button
          ref={leftBoxRef}
          variant="contained"
          color="checkers"
          sx={{
            width: { xs: "90px", tb: "125px" },
            height: "45px",
            borderRadius: "0 0 10px 10px",
            boxShadow: "0px 8px 10px -2px #00000029",
            position: "absolute",
            left: {
              xs: "35px",
              tb: "50px",
              md24: filterExpanded
                ? `${125 + cardsPadding}px`
                : `${30 + cardsPadding}px`,
            },
            zIndex: "999",
            padding: "0px",
            fontSize: theme.typography.fontSize.sm,
            fontWeight: theme.typography.Bold,
          }}
          onClick={() => setPageHeading((prev) => !prev)}
          endIcon={
            <Box
              component={"img"}
              sx={{
                height: 15,
                width: 15,
              }}
              src={InformationIcon}
            />
          }
        >
          {leftSideText}
        </Button>
      ) : (
        <Box
          ref={leftBoxRef}
          sx={{
            // width: { xs: "fit-content", xl: "fit-content" },
            width: "360px",
            // width: `400px`,
            background: "#ffff",
            borderRadius: "0 0 10px 10px",
            boxShadow: "0px 8px 10px -2px #00000029",
            position: "absolute",
            top: {
              xs: showSearchBar ? "115px" : "70px",
              md: showSearchBar ? "115px" : "90px",
              lg: showSearchBar ? "125px" : "90px",
              // xl: "100px",
              xl: showSearchBar ? "125px" : "90px",
              xxl: showSearchBar ? "125px" : "90px",
            },
            left: {
              xs: "35px",
              tb: "50px",
              md24: filterExpanded
                ? `${125 + cardsPadding}px`
                : `${30 + cardsPadding}px`,
            },
            padding: "0px 10px 0 0",
            display: "flex",
            flexDirection: "column",
            gap: "8px",
            // height: "75px",
            height: "auto",
            zIndex: "1001",
            boxSizing: "border-box",
          }}
          onClick={() => setPageHeading((prev) => !prev)}
        >
          <Box sx={{ display: "flex", gap: "10px" }}>
            <Button
              variant="contained"
              color="checkers"
              sx={{
                width: { xs: "90px", tb: "125px" },
                height: "45px",
                borderRadius: "0 0 10px 0",
                boxShadow: "none !important",
                padding: "0px",
                fontSize: theme.typography.fontSize.sm,
                fontWeight: theme.typography.Bold,
              }}
              endIcon={
                <Box
                  component={"img"}
                  sx={{
                    height: 15,
                    width: 15,
                    marginLeft: "2px",
                  }}
                  src={InformationIcon}
                />
              }
            >
              {leftSideText}
            </Button>
            <Typography
              sx={{
                fontSize: theme.typography.fontSize.sm,
                fontWeight: theme.typography.Bold,
                textOverflow: "ellipsis",
                padding: "14px 10px 0 0",
              }}
              // noWrap
            >
              {firstTypographyText}
            </Typography>
          </Box>
          <Typography
            sx={{
              fontSize: theme.typography.fontSize.xs,
              fontWeight: theme.typography.Regular,
              width: "100%",
              padding: "0px 0px 10px 10px",
              boxSizing: "border-box",
            }}
            // noWrap
          >
            {secondTypographyText}
          </Typography>
        </Box>
      )}
    </>
  );
}
