import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
// import useViewport from "./ViewPort";
import { styled, useTheme } from "@mui/material/styles";
import { getDecodedToken, getToken } from "../../utils/Common";
import {
  TAB_ITEMS_ADMIN,
  TAB_ITEMS_CANDIDATE,
  TAB_ITEMS_CANDIDATE_PROMOTER,
  TAB_ITEMS_EMPLOYER,
  TAB_ITEMS_EMPLOYER_PROMOTER,
  TAB_ITEMS_PROMOTER,
  TAB_ITEMS_RECRUITER,
  TAB_ITEMS_RECRUITER_PROMOTER,
  USER_ROLES,
  currentTabs1,
} from "../../utils/Constants";
import { useLocation, Link, useNavigate } from "react-router-dom";
import viewCVIcon from "../../assets/Padding Excluded/Black_Lock.svg";
import candidateLite from "../../assets/Crayon_User_Lite.svg";
import candidateTalent from "../../assets/TopBarIcons/CrayonTalentIcon.svg";
import candidateProfile from "../../assets/Padding Excluded/Black_Man_Happy - Copy.svg";
import promoterlogo from "../../assets/Padding Excluded/Crayon Icon_Promote.svg";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const TopBarMenuList = ({
  handlePopoverCloseTopBar,
  selectedLabel,
  setSelectedLabel,
  handleNavigate,
}) => {
  // const { width } = useViewport();
  let decodedToken = getDecodedToken(getToken());
  const userType = decodedToken?.data?.role_id;
  const isPromoter = decodedToken?.data?.promoter;
  const planType = decodedToken?.data?.talent_plan;
  let { pathname } = useLocation();
  const theme = useTheme();
  const handleClick = (label) => {
    setSelectedLabel(label);
  };
  const excludeLabels = ["logout", "Lite", "Talent", "Candidate", "Promoter"];

  const generateTabs = (tabItems, userTypeCondition) =>
    tabItems
      ?.filter(({ label }) => !excludeLabels?.includes(label))
      ?.map(({ label, path }) => (
        <Tab
          sx={{
            display: "flex",
            flexDirection: "row-reverse",
            backgroundColor: "#FFFFFF",
            width: { xs: "100%", md: "100px", mlg: "110px", xl: "120px" },
            minHeight: "45px",
            padding: "3px 7px 5px 8px!important",
            textTransform: "none",
            color: "black",
            fontSize: { xs: "14px", md: "14px", xl: "16px", xxl: "24px" },
            fontWeight: { xs: 500, md: 700 },
            opacity: 1,
            borderRadius: "0 0 10px 10px",
          }}
          key={path}
          value={path}
          to={path}
          label={label}
          component={Link}
          onClick={() => {
            handleClick(label);
            handlePopoverCloseTopBar(label);
          }}
        />
      ));
      
  return (
    <>
      <>
        {userType === USER_ROLES?.at(3)?.role_id && isPromoter
          ? TAB_ITEMS_EMPLOYER_PROMOTER?.filter((item) =>
              pathname.includes("admin")
                ? item.label !== "Talent"
                : item.label !== "Lite"
            )
              ?.filter(({ label }) =>
                ["Lite", "Talent", "Candidate", "Promoter"]?.includes(label)
              )
              ?.map(({ label, path }) =>
                label === "Talent" && !planType ? (
                  <Tab
                    disabled
                    sx={{
                      display: "flex",
                      flexDirection: "row-reverse",
                      backgroundColor:
                        (label === "Lite" &&
                          theme.palette.topBarLiteButton.main) ||
                        (label === "Talent" && theme.palette.redButton100.main),
                      width: {
                        xs: "100%",
                        md: "100px",
                        mlg: "110px",
                        xl: "120px",
                      },
                      minHeight: "45px",
                      padding: "3px 7px 5px 8px!important",
                      textTransform: "none",
                      "&.Mui-disabled": {
                        color: "white !important",
                      },
                      fontSize: {
                        xs: "14px",
                        md: "14px",
                        xl: "16px",
                        xxl: "24px",
                      },
                      fontWeight: { xs: 500, md: 700 },
                      opacity: 1,
                      borderRadius: "10px 10px 10px 10px",
                    }}
                    key={path}
                    value={path}
                    label={label}
                    component={Link}
                    onClick={() => {
                      handleClick(label);
                      handlePopoverCloseTopBar(label);
                    }}
                    icon={
                      label === "Talent" && (
                        <Box
                          component={"img"}
                          src={viewCVIcon}
                          sx={{
                            height: "20px",
                            width: "20px",
                            ml: 1,
                            mb: "0px !important",
                          }}
                        />
                      )
                    }
                  />
                ) : (
                  <Tab
                    sx={{
                      display: "flex",
                      flexDirection: "row-reverse",
                      backgroundColor:
                        (label === "Lite" &&
                          theme.palette.topBarLiteButton.main) ||
                        (label === "Talent" && theme.palette.redButton100.main),
                      width: {
                        xs: "100%",
                        md: "100px",
                        mlg: "110px",
                        xl: "120px",
                      },
                      minHeight: "45px",
                      padding: "3px 7px 5px 8px!important",
                      textTransform: "none",
                      color:
                        (label === "Lite" && "white") ||
                        (label === "Talent" && "white") ||
                        "black",
                      fontSize: {
                        xs: "14px",
                        md: "14px",
                        xl: "16px",
                        xxl: "24px",
                      },
                      fontWeight: { xs: 500, md: 700 },
                      opacity: 1,
                      borderRadius: "10px 10px 10px 10px",
                    }}
                    key={path}
                    value={path}
                    to={label !== "logout" ? path : "/jobs"}
                    label={label}
                    component={Link}
                    onClick={() => {
                      handleClick(label);
                      handlePopoverCloseTopBar(label);
                    }}
                    icon={
                      (label === "Lite" && (
                        <Box
                          component={"img"}
                          src={candidateLite}
                          sx={{
                            height: "20px",
                            width: "20px",
                            ml: 1,
                            mb: "0px !important",
                          }}
                        />
                      )) ||
                      (label === "Talent" && (
                        <Box
                          component={"img"}
                          src={candidateTalent}
                          sx={{
                            height: "25px",
                            width: "28px",
                            ml: 1,
                            mb: "0px !important",
                          }}
                        />
                      ))
                    }
                  />
                )
              )
          : userType === USER_ROLES?.at(3)?.role_id &&
            TAB_ITEMS_EMPLOYER?.filter((item) =>
              pathname.includes("admin")
                ? item.label !== "Talent"
                : item.label !== "Lite"
            )
              ?.filter(({ label }) =>
                ["Lite", "Talent", "Candidate", "Promoter"]?.includes(label)
              )
              ?.map(({ label, path }) =>
                label === "Talent" && !planType ? (
                  <Tab
                    disabled
                    sx={{
                      display: "flex",
                      flexDirection: "row-reverse",
                      backgroundColor:
                        (label === "Lite" &&
                          theme.palette.topBarLiteButton.main) ||
                        (label === "Talent" && theme.palette.redButton100.main),
                      width: {
                        xs: "100%",
                        md: "100px",
                        mlg: "110px",
                        xl: "120px",
                      },
                      minHeight: "45px",
                      padding: "3px 7px 5px 8px!important",
                      textTransform: "none",
                      "&.Mui-disabled": {
                        color: "white !important",
                      },
                      fontSize: {
                        xs: "14px",
                        md: "14px",
                        xl: "16px",
                        xxl: "24px",
                      },
                      fontWeight: { xs: 500, md: 700 },
                      opacity: 1,
                      borderRadius: "10px 10px 10px 10px",
                    }}
                    key={path}
                    value={path}
                    label={label}
                    component={Link}
                    onClick={() => {
                      handleClick(label);
                      handlePopoverCloseTopBar(label);
                    }}
                    icon={
                      label === "Talent" && (
                        <Box
                          component={"img"}
                          src={viewCVIcon}
                          sx={{
                            height: "20px",
                            width: "20px",
                            ml: 1,
                            mb: "0px !important",
                          }}
                        />
                      )
                    }
                  />
                ) : (
                  <Tab
                    sx={{
                      display: "flex",
                      flexDirection: "row-reverse",
                      backgroundColor:
                        (label === "Lite" &&
                          theme.palette.topBarLiteButton.main) ||
                        (label === "Talent" && theme.palette.redButton100.main),
                      width: {
                        xs: "100%",
                        md: "100px",
                        mlg: "110px",
                        xl: "120px",
                      },
                      minHeight: "45px",
                      padding: "3px 7px 5px 8px!important",
                      textTransform: "none",
                      color:
                        (label === "Lite" && "white") ||
                        (label === "Talent" && "white") ||
                        "black",
                      fontSize: {
                        xs: "14px",
                        md: "14px",
                        xl: "16px",
                        xxl: "24px",
                      },
                      fontWeight: { xs: 500, md: 700 },
                      opacity: 1,
                      borderRadius: "10px 10px 10px 10px",
                    }}
                    key={path}
                    value={path}
                    to={label !== "logout" ? path : "/jobs"}
                    label={label}
                    component={Link}
                    onClick={() => {
                      handleClick(label);
                      handlePopoverCloseTopBar(label);
                    }}
                    icon={
                      (label === "Lite" && (
                        <Box
                          component={"img"}
                          src={candidateLite}
                          sx={{
                            height: "20px",
                            width: "20px",
                            ml: 1,
                            mb: "0px !important",
                          }}
                        />
                      )) ||
                      (label === "Talent" && (
                        <Box
                          component={"img"}
                          src={candidateTalent}
                          sx={{
                            height: "25px",
                            width: "28px",
                            ml: 1,
                            mb: "0px !important",
                          }}
                        />
                      ))
                    }
                  />
                )
              )}
        {userType === USER_ROLES?.at(4)?.role_id && isPromoter
          ? TAB_ITEMS_RECRUITER_PROMOTER?.filter((item) =>
              pathname.includes("admin")
                ? item.label !== "Talent"
                : item.label !== "Lite"
            )
              ?.filter(({ label }) =>
                ["Lite", "Talent", "Candidate", "Promoter"]?.includes(label)
              )
              ?.map(({ label, path }) =>
                label === "Talent" && !planType ? (
                  <Tab
                    disabled
                    sx={{
                      display: "flex",
                      flexDirection: "row-reverse",
                      backgroundColor:
                        (label === "Lite" &&
                          theme.palette.topBarLiteButton.main) ||
                        (label === "Talent" && theme.palette.redButton100.main),
                      width: {
                        xs: "100%",
                        md: "100px",
                        mlg: "110px",
                        xl: "120px",
                      },
                      minHeight: "45px",
                      padding: "3px 7px 5px 8px!important",
                      textTransform: "none",
                      // color:
                      //   (label === "Lite" && "white") ||
                      //   (label === "Talent" && "white") ||
                      //   "black",
                      "&.Mui-disabled": {
                        color: "white !important",
                      },
                      fontSize: {
                        xs: "14px",
                        md: "14px",
                        xl: "16px",
                        xxl: "24px",
                      },
                      fontWeight: { xs: 500, md: 700 },
                      opacity: 1,
                      borderRadius: "10px 10px 10px 10px",
                    }}
                    key={path}
                    value={path}
                    to={label !== "logout" ? path : "/jobs"}
                    label={label}
                    component={Link}
                    onClick={() => {
                      handleClick(label);
                      handlePopoverCloseTopBar(label);
                    }}
                    icon={
                      label === "Talent" && (
                        <Box
                          component={"img"}
                          src={viewCVIcon}
                          sx={{
                            height: "20px",
                            width: "20px",
                            ml: 1,
                            mb: "0px !important",
                          }}
                        />
                      )
                    }
                  />
                ) : (
                  <Tab
                    sx={{
                      display: "flex",
                      flexDirection: "row-reverse",
                      backgroundColor:
                        (label === "Lite" &&
                          theme.palette.topBarLiteButton.main) ||
                        (label === "Talent" && theme.palette.redButton100.main),
                      width: {
                        xs: "100%",
                        md: "100px",
                        mlg: "110px",
                        xl: "120px",
                      },
                      minHeight: "45px",
                      padding: "3px 7px 5px 8px!important",
                      textTransform: "none",
                      color:
                        (label === "Lite" && "white") ||
                        (label === "Talent" && "white") ||
                        "black",
                      fontSize: {
                        xs: "14px",
                        md: "14px",
                        xl: "16px",
                        xxl: "24px",
                      },
                      fontWeight: { xs: 500, md: 700 },
                      opacity: 1,
                      borderRadius: "10px 10px 10px 10px",
                    }}
                    key={path}
                    value={path}
                    to={label !== "logout" ? path : "/jobs"}
                    label={label}
                    component={Link}
                    onClick={() => {
                      handleClick(label);
                      handlePopoverCloseTopBar(label);
                    }}
                    icon={
                      (label === "Lite" && (
                        <Box
                          component={"img"}
                          src={candidateLite}
                          sx={{
                            height: "20px",
                            width: "20px",
                            ml: 1,
                            mb: "0px !important",
                          }}
                        />
                      )) ||
                      (label === "Talent" && (
                        <Box
                          component={"img"}
                          src={candidateTalent}
                          sx={{
                            height: "25px",
                            width: "28px",
                            ml: 1,
                            mb: "0px !important",
                          }}
                        />
                      ))
                    }
                  />
                )
              )
          : userType === USER_ROLES?.at(4)?.role_id &&
            TAB_ITEMS_RECRUITER?.filter((item) =>
              pathname.includes("admin")
                ? item.label !== "Talent"
                : item.label !== "Lite"
            )
              ?.filter(({ label }) =>
                ["Lite", "Talent", "Candidate", "Promoter"]?.includes(label)
              )
              ?.map(({ label, path }) =>
                label === "Talent" && !planType ? (
                  <Tab
                    disabled
                    sx={{
                      display: "flex",
                      flexDirection: "row-reverse",
                      backgroundColor:
                        (label === "Lite" &&
                          theme.palette.topBarLiteButton.main) ||
                        (label === "Talent" && theme.palette.redButton100.main),
                      width: {
                        xs: "100%",
                        md: "100px",
                        mlg: "110px",
                        xl: "120px",
                      },
                      minHeight: "45px",
                      padding: "3px 7px 5px 8px!important",
                      textTransform: "none",
                      // color:
                      //   (label === "Lite" && "white") ||
                      //   (label === "Talent" && "white") ||
                      //   "black",
                      "&.Mui-disabled": {
                        color: "white !important",
                      },
                      fontSize: {
                        xs: "14px",
                        md: "14px",
                        xl: "16px",
                        xxl: "24px",
                      },
                      fontWeight: { xs: 500, md: 700 },
                      opacity: 1,
                      borderRadius: "10px 10px 10px 10px",
                    }}
                    key={path}
                    value={path}
                    to={label !== "logout" ? path : "/jobs"}
                    label={label}
                    component={Link}
                    onClick={() => {
                      handleClick(label);
                      handlePopoverCloseTopBar(label);
                    }}
                    icon={
                      label === "Talent" && (
                        <Box
                          component={"img"}
                          src={viewCVIcon}
                          sx={{
                            height: "20px",
                            width: "20px",
                            ml: 1,
                            mb: "0px !important",
                          }}
                        />
                      )
                    }
                  />
                ) : (
                  <Tab
                    sx={{
                      display: "flex",
                      flexDirection: "row-reverse",
                      backgroundColor:
                        (label === "Lite" &&
                          theme.palette.topBarLiteButton.main) ||
                        (label === "Talent" && theme.palette.redButton100.main),
                      width: {
                        xs: "100%",
                        md: "100px",
                        mlg: "110px",
                        xl: "120px",
                      },
                      minHeight: "45px",
                      padding: "3px 7px 5px 8px!important",
                      textTransform: "none",
                      color:
                        (label === "Lite" && "white") ||
                        (label === "Talent" && "white") ||
                        "black",
                      fontSize: {
                        xs: "14px",
                        md: "14px",
                        xl: "16px",
                        xxl: "24px",
                      },
                      fontWeight: { xs: 500, md: 700 },
                      opacity: 1,
                      borderRadius: "10px 10px 10px 10px",
                    }}
                    key={path}
                    value={path}
                    to={label !== "logout" ? path : "/jobs"}
                    label={label}
                    component={Link}
                    onClick={() => {
                      handleClick(label);
                      handlePopoverCloseTopBar(label);
                    }}
                    icon={
                      (label === "Lite" && (
                        <Box
                          component={"img"}
                          src={candidateLite}
                          sx={{
                            height: "20px",
                            width: "20px",
                            ml: 1,
                            mb: "0px !important",
                          }}
                        />
                      )) ||
                      (label === "Talent" && (
                        <Box
                          component={"img"}
                          src={candidateTalent}
                          sx={{
                            height: "25px",
                            width: "28px",
                            ml: 1,
                            mb: "0px !important",
                          }}
                        />
                      ))
                    }
                  />
                )
              )}
        {userType === USER_ROLES?.at(2)?.role_id && isPromoter
          ? TAB_ITEMS_CANDIDATE_PROMOTER?.filter(({ label }) =>
              ["Lite", "Talent", "Candidate", "Promoter"]?.includes(label)
            )?.map(({ label, path }) => (
              <Tab
                sx={{
                  display: "flex",
                  flexDirection: "row-reverse",
                  backgroundColor:
                    label === "Candidate" &&
                    theme.palette.topBarCandidateButton.main,
                  width: {
                    xs: "100%",
                    md: "100px",
                    mlg: "110px",
                    xl: "120px",
                  },
                  minHeight: "45px",
                  padding: "3px 7px 5px 8px!important",
                  textTransform: "none",
                  color:
                    label === "Candidate"
                      ? "white"
                      : `${theme.palette.grayButton100.light} !important`,
                  fontSize: {
                    xs: "14px",
                    md: "14px",
                    xl: "16px",
                    xxl: "24px",
                  },
                  fontWeight: {
                    xs: label === selectedLabel ? 700 : 500,
                    md: 700,
                  },
                  opacity: 1,
                  borderRadius: "10px 10px 10px 10px",
                }}
                key={path}
                value={path}
                to={label !== "logout" ? path : "/jobs"}
                label={label}
                component={Link}
                onClick={() => {
                  handleClick(label);
                  handlePopoverCloseTopBar(label);
                }}
                icon={
                  label === "Candidate" && (
                    <Box
                      component={"img"}
                      src={candidateProfile}
                      sx={{
                        height: "20px",
                        width: "20px",
                        ml: 1,
                      }}
                    />
                  )
                }
              />
            ))
          : userType === USER_ROLES?.at(2)?.role_id &&
            TAB_ITEMS_CANDIDATE?.filter(({ label }) =>
              ["Lite", "Talent", "Candidate", "Promoter"]?.includes(label)
            )?.map(({ label, path }) => (
              <Tab
                sx={{
                  display: "flex",
                  flexDirection: "row-reverse",
                  backgroundColor:
                    label === "Candidate" &&
                    theme.palette.topBarCandidateButton.main,
                  width: {
                    xs: "100%",
                    md: "100px",
                    mlg: "110px",
                    xl: "120px",
                  },
                  minHeight: "45px",
                  padding: "3px 7px 5px 8px!important",
                  textTransform: "none",
                  color:
                    label === "Candidate"
                      ? "white"
                      : `${theme.palette.grayButton100.light} !important`,
                  fontSize: {
                    xs: "14px",
                    md: "14px",
                    xl: "16px",
                    xxl: "24px",
                  },
                  fontWeight: {
                    xs: label === selectedLabel ? 700 : 500,
                    md: 700,
                  },
                  // opacity: 1,
                  borderRadius: "10px 10px 10px 10px",
                }}
                key={path}
                value={path}
                to={label !== "logout" ? path : "/jobs"}
                label={label}
                component={Link}
                onClick={() => {
                  handleClick(label);
                  handlePopoverCloseTopBar(label);
                }}
                icon={
                  label === "Candidate" && (
                    <Box
                      component={"img"}
                      src={candidateProfile}
                      sx={{
                        height: "20px",
                        width: "20px",
                        ml: 1,
                      }}
                    />
                  )
                }
              />
            ))}
        {userType === USER_ROLES?.at(5)?.role_id &&
          TAB_ITEMS_PROMOTER?.filter(({ label }) =>
            ["Lite", "Talent", "Candidate", "Promoter"]?.includes(label)
          )?.map(({ label, path }) => (
            <Tab
              sx={{
                display: "flex",
                alignItems: "center",
                flexDirection: "row-reverse",
                backgroundColor:
                  label === "Promoter" && theme.palette.promoterButton.main,
                width: {
                  xs: "100%",
                  md: "100px",
                  mlg: "110px",
                  xl: "120px",
                },
                minHeight: "45px",
                // padding: "3px 7px 5px 8px!important",
                padding:
                  label === "Promoter" ? "0px" : "3px 7px 5px 8px !important",
                textTransform: "none",
                color:
                  label === "Promoter"
                    ? "white"
                    : `${theme.palette.grayButton100.light} !important`,
                fontSize: {
                  xs: "14px",
                  md: "14px",
                  xl: "16px",
                  xxl: "24px",
                },
                fontWeight: { xs: 500, md: 700 },
                opacity: 1,
                borderRadius: "10px 10px 10px 10px",
              }}
              key={path}
              value={path}
              to={label !== "logout" ? path : "/jobs"}
              label={label}
              component={Link}
              onClick={() => {
                handleClick(label);
                handlePopoverCloseTopBar(label);
              }}
              icon={
                label === "Promoter" && (
                  <Box
                    component={"img"}
                    src={promoterlogo}
                    sx={{
                      height: "25px",
                      width: "25px",
                      ml: "4px",
                    }}
                  />
                )
              }
            />
          ))}
        {(userType === USER_ROLES?.at(0)?.role_id ||
          userType === USER_ROLES?.at(1)?.role_id ||
          userType === USER_ROLES?.at(6)?.role_id) &&
          TAB_ITEMS_ADMIN?.filter(({ label }) =>
            ["Lite", "Talent", "Candidate", "Promoter"]?.includes(label)
          )?.map(({ label, path }) => (
            <Tab
              sx={{
                display: "flex",
                flexDirection: "row-reverse",
                width: {
                  xs: "100%",
                  md: "100px",
                  mlg: "110px",
                  xl: "120px",
                },
                minHeight: "45px",
                padding: "3px 7px 5px 8px!important",
                textTransform: "none",
                color:
                  label === "Candidate"
                    ? "white"
                    : `${theme.palette.grayButton100.light} !important`,
                fontSize: {
                  xs: "14px",
                  md: "14px",
                  xl: "16px",
                  xxl: "24px",
                },
                fontWeight: { xs: 500, md: 700 },
                opacity: 1,
                borderRadius: "10px 10px 10px 10px",
              }}
              key={path}
              value={path}
              to={label !== "logout" ? path : "/jobs"}
              label={label}
              component={Link}
              onClick={() => {
                handleClick(label);
                handlePopoverCloseTopBar(label);
              }}
            />
          ))}
      </>
      {decodedToken && (
        <>
          <Accordion
            sx={{
              boxShadow: "none",
              "& .MuiAccordionSummary-root": {
                // padding: "0px !important",
                // display:"flex !important",
                // justifyContent:"center !important"
                fontSize: "14px",
                fontWeight: "500",
              },
              "& .MuiTypography-body1": {
                fontSize: "14px",
                fontWeight: "500",
              },
              "& .MuiAccordionSummary-content": {
                flexGrow: 0,
                fontSize: "14px",
                fontWeight: "500",
              },
              ".MuiPaper-root-MuiAccordion-root": {
                backgroundColor: "#E5E5E5 !important",
              },
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>Quick Links</Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ padding: "0px", boxShadow: "none" }}>
              <List sx={{ padding: "0px" }}>
                {currentTabs1?.map(({ label, path }) => (
                  <ListItem key={label} disablePadding>
                    <ListItemButton
                      onClick={() => {
                        setSelectedLabel(label);
                        handleNavigate(path);
                      }}
                      sx={{
                        textAlign: "center",
                        fontWeight: label === selectedLabel ? 700 : 500,
                        "& .MuiListItemText-multiline": {
                          fontWeight:
                            label === selectedLabel
                              ? "700 !important"
                              : "500 !important",
                          fontSize: "14px",
                          color: "black",
                        },
                        "& .MuiListItemText-primary": {
                          fontWeight:
                            label === selectedLabel
                              ? "700 !important"
                              : "500 !important",
                          fontSize: "14px",
                          color: "black",
                        },
                      }}
                    >
                      <ListItemText
                        sx={{
                          fontSize: "14px",
                          fontWeight: label === selectedLabel ? 700 : 500,
                        }}
                        primary={label}
                      />
                    </ListItemButton>
                  </ListItem>
                ))}
              </List>
            </AccordionDetails>
          </Accordion>
        </>
      )}
      {/* <Tabs orientation="vertical">
        {userType === USER_ROLES?.at(3)?.role_id && isPromoter
          ? TAB_ITEMS_EMPLOYER_PROMOTER?.filter((item) =>
              pathname.includes("admin")
                ? item.label !== "Talent"
                : item.label !== "Lite"
            )
              ?.filter(
                (item) =>
                  item.label !== "logout" ||
                  item.label === "Talent" ||
                  item.label === "Lite"
              )
              ?.map(({ label, path }) =>
                label === "Talent" && !planType ? (
                  <Tab
                    disabled
                    sx={{
                      display: "flex",
                      flexDirection: "row-reverse",
                      backgroundColor:
                        (label === "Lite" &&
                          theme.palette.topBarLiteButton.main) ||
                        (label === "Talent" && theme.palette.redButton100.main),
                      width: {
                        xs: "100%",
                        md: "100px",
                        mlg: "110px",
                        xl: "120px",
                      },
                      minHeight: "45px",
                      padding: "3px 7px 5px 8px!important",
                      textTransform: "none",
                      "&.Mui-disabled": {
                        color: "white !important",
                      },
                      fontSize: {
                        xs: "14px",
                        md: "14px",
                        xl: "16px",
                        xxl: "24px",
                      },
                      fontWeight: { xs: 500, md: 700 },
                      opacity: 1,
                      borderRadius: "0 0 10px 10px",
                    }}
                    key={path}
                    value={path}
                    label={label}
                    component={Link}
                    onClick={() => {
                      handleClick(label);
                      handlePopoverCloseTopBar(label);
                    }}
                    icon={
                      label === "Talent" && (
                        <Box
                          component={"img"}
                          src={viewCVIcon}
                          sx={{
                            height: "20px",
                            width: "20px",
                            ml: 1,
                            mb: "0px !important",
                          }}
                        />
                      )
                    }
                  />
                ) : (
                  <Tab
                    sx={{
                      display: "flex",
                      flexDirection: "row-reverse",
                      backgroundColor:
                        (label === "Lite" &&
                          theme.palette.topBarLiteButton.main) ||
                        (label === "Talent" && theme.palette.redButton100.main),
                      width: {
                        xs: "100%",
                        md: "100px",
                        mlg: "110px",
                        xl: "120px",
                      },
                      minHeight: "45px",
                      padding: "3px 7px 5px 8px!important",
                      textTransform: "none",
                      color:
                        (label === "Lite" && "white") ||
                        (label === "Talent" && "white") ||
                        "black",
                      fontSize: {
                        xs: "14px",
                        md: "14px",
                        xl: "16px",
                        xxl: "24px",
                      },
                      fontWeight: { xs: 500, md: 700 },
                      opacity: 1,
                      borderRadius: "0 0 10px 10px",
                    }}
                    key={path}
                    value={path}
                    to={label !== "logout" ? path : "/jobs"}
                    label={label}
                    component={Link}
                    onClick={() => {
                      handleClick(label);
                      handlePopoverCloseTopBar(label);
                    }}
                    icon={
                      (label === "Lite" && (
                        <Box
                          component={"img"}
                          src={candidateLite}
                          sx={{
                            height: "20px",
                            width: "20px",
                            ml: 1,
                            mb: "0px !important",
                          }}
                        />
                      )) ||
                      (label === "Talent" && (
                        <Box
                          component={"img"}
                          src={candidateTalent}
                          sx={{
                            height: "25px",
                            width: "28px",
                            ml: 1,
                            mb: "0px !important",
                          }}
                        />
                      ))
                    }
                  />
                )
              )
          : userType === USER_ROLES?.at(3)?.role_id &&
            TAB_ITEMS_EMPLOYER?.filter((item) =>
              pathname.includes("admin")
                ? item.label !== "Talent"
                : item.label !== "Lite"
            )
              ?.filter(
                (item) =>
                  item.label !== "logout" ||
                  item.label === "Talent" ||
                  item.label === "Lite"
              )
              ?.map(({ label, path }) =>
                label === "Talent" && !planType ? (
                  <Tab
                    disabled
                    sx={{
                      display: "flex",
                      flexDirection: "row-reverse",
                      backgroundColor:
                        (label === "Lite" &&
                          theme.palette.topBarLiteButton.main) ||
                        (label === "Talent" && theme.palette.redButton100.main),
                      width: {
                        xs: "100%",
                        md: "100px",
                        mlg: "110px",
                        xl: "120px",
                      },
                      minHeight: "45px",
                      padding: "3px 7px 5px 8px!important",
                      textTransform: "none",
                      "&.Mui-disabled": {
                        color: "white !important",
                      },
                      fontSize: {
                        xs: "14px",
                        md: "14px",
                        xl: "16px",
                        xxl: "24px",
                      },
                      fontWeight: { xs: 500, md: 700 },
                      opacity: 1,
                      borderRadius: "0 0 10px 10px",
                    }}
                    key={path}
                    value={path}
                    label={label}
                    component={Link}
                    onClick={() => {
                      handleClick(label);
                      handlePopoverCloseTopBar(label);
                    }}
                    icon={
                      label === "Talent" && (
                        <Box
                          component={"img"}
                          src={viewCVIcon}
                          sx={{
                            height: "20px",
                            width: "20px",
                            ml: 1,
                            mb: "0px !important",
                          }}
                        />
                      )
                    }
                  />
                ) : (
                  <Tab
                    sx={{
                      display: "flex",
                      flexDirection: "row-reverse",
                      backgroundColor:
                        (label === "Lite" &&
                          theme.palette.topBarLiteButton.main) ||
                        (label === "Talent" && theme.palette.redButton100.main),
                      width: {
                        xs: "100%",
                        md: "100px",
                        mlg: "110px",
                        xl: "120px",
                      },
                      minHeight: "45px",
                      padding: "3px 7px 5px 8px!important",
                      textTransform: "none",
                      color:
                        (label === "Lite" && "white") ||
                        (label === "Talent" && "white") ||
                        "black",
                      fontSize: {
                        xs: "14px",
                        md: "14px",
                        xl: "16px",
                        xxl: "24px",
                      },
                      fontWeight: { xs: 500, md: 700 },
                      opacity: 1,
                      borderRadius: "0 0 10px 10px",
                    }}
                    key={path}
                    value={path}
                    to={label !== "logout" ? path : "/jobs"}
                    label={label}
                    component={Link}
                    onClick={() => {
                      handleClick(label);
                      handlePopoverCloseTopBar(label);
                    }}
                    icon={
                      (label === "Lite" && (
                        <Box
                          component={"img"}
                          src={candidateLite}
                          sx={{
                            height: "20px",
                            width: "20px",
                            ml: 1,
                            mb: "0px !important",
                          }}
                        />
                      )) ||
                      (label === "Talent" && (
                        <Box
                          component={"img"}
                          src={candidateTalent}
                          sx={{
                            height: "25px",
                            width: "28px",
                            ml: 1,
                            mb: "0px !important",
                          }}
                        />
                      ))
                    }
                  />
                )
              )}
        {userType === USER_ROLES?.at(4)?.role_id && isPromoter
          ? TAB_ITEMS_RECRUITER_PROMOTER?.filter((item) =>
              pathname.includes("admin")
                ? item.label !== "Talent"
                : item.label !== "Lite"
            )
              ?.filter(
                (item) =>
                  item.label !== "logout" ||
                  item.label === "Talent" ||
                  item.label === "Lite"
              )
              ?.map(({ label, path }) =>
                label === "Talent" && !planType ? (
                  <Tab
                    disabled
                    sx={{
                      display: "flex",
                      flexDirection: "row-reverse",
                      backgroundColor:
                        (label === "Lite" &&
                          theme.palette.topBarLiteButton.main) ||
                        (label === "Talent" && theme.palette.redButton100.main),
                      width: {
                        xs: "100%",
                        md: "100px",
                        mlg: "110px",
                        xl: "120px",
                      },
                      minHeight: "45px",
                      padding: "3px 7px 5px 8px!important",
                      textTransform: "none",
                      // color:
                      //   (label === "Lite" && "white") ||
                      //   (label === "Talent" && "white") ||
                      //   "black",
                      "&.Mui-disabled": {
                        color: "white !important",
                      },
                      fontSize: {
                        xs: "14px",
                        md: "14px",
                        xl: "16px",
                        xxl: "24px",
                      },
                      fontWeight: { xs: 500, md: 700 },
                      opacity: 1,
                      borderRadius: "0 0 10px 10px",
                    }}
                    key={path}
                    value={path}
                    to={label !== "logout" ? path : "/jobs"}
                    label={label}
                    component={Link}
                    onClick={() => {
                      handleClick(label);
                      handlePopoverCloseTopBar(label);
                    }}
                    icon={
                      label === "Talent" && (
                        <Box
                          component={"img"}
                          src={viewCVIcon}
                          sx={{
                            height: "20px",
                            width: "20px",
                            ml: 1,
                            mb: "0px !important",
                          }}
                        />
                      )
                    }
                  />
                ) : (
                  <Tab
                    sx={{
                      display: "flex",
                      flexDirection: "row-reverse",
                      backgroundColor:
                        (label === "Lite" &&
                          theme.palette.topBarLiteButton.main) ||
                        (label === "Talent" && theme.palette.redButton100.main),
                      width: {
                        xs: "100%",
                        md: "100px",
                        mlg: "110px",
                        xl: "120px",
                      },
                      minHeight: "45px",
                      padding: "3px 7px 5px 8px!important",
                      textTransform: "none",
                      color:
                        (label === "Lite" && "white") ||
                        (label === "Talent" && "white") ||
                        "black",
                      fontSize: {
                        xs: "14px",
                        md: "14px",
                        xl: "16px",
                        xxl: "24px",
                      },
                      fontWeight: { xs: 500, md: 700 },
                      opacity: 1,
                      borderRadius: "0 0 10px 10px",
                    }}
                    key={path}
                    value={path}
                    to={label !== "logout" ? path : "/jobs"}
                    label={label}
                    component={Link}
                    onClick={() => {
                      handleClick(label);
                      handlePopoverCloseTopBar(label);
                    }}
                    icon={
                      (label === "Lite" && (
                        <Box
                          component={"img"}
                          src={candidateLite}
                          sx={{
                            height: "20px",
                            width: "20px",
                            ml: 1,
                            mb: "0px !important",
                          }}
                        />
                      )) ||
                      (label === "Talent" && (
                        <Box
                          component={"img"}
                          src={candidateTalent}
                          sx={{
                            height: "25px",
                            width: "28px",
                            ml: 1,
                            mb: "0px !important",
                          }}
                        />
                      ))
                    }
                  />
                )
              )
          : userType === USER_ROLES?.at(4)?.role_id &&
            TAB_ITEMS_RECRUITER?.filter((item) =>
              pathname.includes("admin")
                ? item.label !== "Talent"
                : item.label !== "Lite"
            )
              ?.filter(
                (item) =>
                  item.label !== "logout" ||
                  item.label === "Talent" ||
                  item.label === "Lite"
              )
              ?.map(({ label, path }) =>
                label === "Talent" && !planType ? (
                  <Tab
                    disabled
                    sx={{
                      display: "flex",
                      flexDirection: "row-reverse",
                      backgroundColor:
                        (label === "Lite" &&
                          theme.palette.topBarLiteButton.main) ||
                        (label === "Talent" && theme.palette.redButton100.main),
                      width: {
                        xs: "100%",
                        md: "100px",
                        mlg: "110px",
                        xl: "120px",
                      },
                      minHeight: "45px",
                      padding: "3px 7px 5px 8px!important",
                      textTransform: "none",
                      // color:
                      //   (label === "Lite" && "white") ||
                      //   (label === "Talent" && "white") ||
                      //   "black",
                      "&.Mui-disabled": {
                        color: "white !important",
                      },
                      fontSize: {
                        xs: "14px",
                        md: "14px",
                        xl: "16px",
                        xxl: "24px",
                      },
                      fontWeight: { xs: 500, md: 700 },
                      opacity: 1,
                      borderRadius: "0 0 10px 10px",
                    }}
                    key={path}
                    value={path}
                    to={label !== "logout" ? path : "/jobs"}
                    label={label}
                    component={Link}
                    onClick={() => {
                      handleClick(label);
                      handlePopoverCloseTopBar(label);
                    }}
                    icon={
                      label === "Talent" && (
                        <Box
                          component={"img"}
                          src={viewCVIcon}
                          sx={{
                            height: "20px",
                            width: "20px",
                            ml: 1,
                            mb: "0px !important",
                          }}
                        />
                      )
                    }
                  />
                ) : (
                  <Tab
                    sx={{
                      display: "flex",
                      flexDirection: "row-reverse",
                      backgroundColor:
                        (label === "Lite" &&
                          theme.palette.topBarLiteButton.main) ||
                        (label === "Talent" && theme.palette.redButton100.main),
                      width: {
                        xs: "100%",
                        md: "100px",
                        mlg: "110px",
                        xl: "120px",
                      },
                      minHeight: "45px",
                      padding: "3px 7px 5px 8px!important",
                      textTransform: "none",
                      color:
                        (label === "Lite" && "white") ||
                        (label === "Talent" && "white") ||
                        "black",
                      fontSize: {
                        xs: "14px",
                        md: "14px",
                        xl: "16px",
                        xxl: "24px",
                      },
                      fontWeight: { xs: 500, md: 700 },
                      opacity: 1,
                      borderRadius: "0 0 10px 10px",
                    }}
                    key={path}
                    value={path}
                    to={label !== "logout" ? path : "/jobs"}
                    label={label}
                    component={Link}
                    onClick={() => {
                      handleClick(label);
                      handlePopoverCloseTopBar(label);
                    }}
                    icon={
                      (label === "Lite" && (
                        <Box
                          component={"img"}
                          src={candidateLite}
                          sx={{
                            height: "20px",
                            width: "20px",
                            ml: 1,
                            mb: "0px !important",
                          }}
                        />
                      )) ||
                      (label === "Talent" && (
                        <Box
                          component={"img"}
                          src={candidateTalent}
                          sx={{
                            height: "25px",
                            width: "28px",
                            ml: 1,
                            mb: "0px !important",
                          }}
                        />
                      ))
                    }
                  />
                )
              )}
        {userType === USER_ROLES?.at(2)?.role_id && isPromoter
          ? TAB_ITEMS_CANDIDATE_PROMOTER?.filter(
              (item) => item.label !== "logout" || item.label !== "Candidate"
            )?.map(({ label, path }) => (
              <Tab
                sx={{
                  display: "flex",
                  flexDirection: "row-reverse",
                  backgroundColor:
                    label === "Candidate" &&
                    theme.palette.topBarCandidateButton.main,
                  width: {
                    xs: "100%",
                    md: "100px",
                    mlg: "110px",
                    xl: "120px",
                  },
                  minHeight: "45px",
                  padding: "3px 7px 5px 8px!important",
                  textTransform: "none",
                  color:
                    label === "Candidate"
                      ? "white"
                      : `${theme.palette.grayButton100.light} !important`,
                  fontSize: {
                    xs: "14px",
                    md: "14px",
                    xl: "16px",
                    xxl: "24px",
                  },
                  fontWeight: {
                    xs: label === selectedLabel ? 700 : 500,
                    md: 700,
                  },
                  opacity: 1,
                  borderRadius: "10px 10px 10px 10px",
                }}
                key={path}
                value={path}
                to={label !== "logout" ? path : "/jobs"}
                label={label}
                component={Link}
                onClick={() => {
                  handleClick(label);
                  handlePopoverCloseTopBar(label);
                }}
                icon={
                  label === "Candidate" && (
                    <Box
                      component={"img"}
                      src={candidateProfile}
                      sx={{
                        height: "20px",
                        width: "20px",
                        ml: 1,
                      }}
                    />
                  )
                }
              />
            ))
          : userType === USER_ROLES?.at(2)?.role_id &&
            TAB_ITEMS_CANDIDATE?.filter(
              (item) => item.label !== "logout" || item.label !== "Candidate"
            )?.map(({ label, path }) => (
              <Tab
                sx={{
                  display: "flex",
                  flexDirection: "row-reverse",
                  backgroundColor:
                    label === "Candidate" &&
                    theme.palette.topBarCandidateButton.main,
                  width: {
                    xs: "100%",
                    md: "100px",
                    mlg: "110px",
                    xl: "120px",
                  },
                  minHeight: "45px",
                  padding: "3px 7px 5px 8px!important",
                  textTransform: "none",
                  color:
                    label === "Candidate"
                      ? "white"
                      : `${theme.palette.grayButton100.light} !important`,
                  fontSize: {
                    xs: "14px",
                    md: "14px",
                    xl: "16px",
                    xxl: "24px",
                  },
                  fontWeight: {
                    xs: label === selectedLabel ? 700 : 500,
                    md: 700,
                  },
                  // opacity: 1,
                  borderRadius: "10px 10px 10px 10px",
                }}
                key={path}
                value={path}
                to={label !== "logout" ? path : "/jobs"}
                label={label}
                component={Link}
                onClick={() => {
                  handleClick(label);
                  handlePopoverCloseTopBar(label);
                }}
                icon={
                  label === "Candidate" && (
                    <Box
                      component={"img"}
                      src={candidateProfile}
                      sx={{
                        height: "20px",
                        width: "20px",
                        ml: 1,
                      }}
                    />
                  )
                }
              />
            ))}
        {userType === USER_ROLES?.at(5)?.role_id &&
          TAB_ITEMS_PROMOTER?.filter(
            (item) => item.label !== "logout" || item.label !== "Promoter"
          )?.map(({ label, path }) => (
            <Tab
              sx={{
                display: "flex",
                alignItems: "center",
                flexDirection: "row-reverse",
                backgroundColor:
                  label === "Promoter" && theme.palette.promoterButton.main,
                width: {
                  xs: "100%",
                  md: "100px",
                  mlg: "110px",
                  xl: "120px",
                },
                minHeight: "45px",
                // padding: "3px 7px 5px 8px!important",
                padding:
                  label === "Promoter" ? "0px" : "3px 7px 5px 8px !important",
                textTransform: "none",
                color:
                  label === "Promoter"
                    ? "white"
                    : `${theme.palette.grayButton100.light} !important`,
                fontSize: {
                  xs: "14px",
                  md: "14px",
                  xl: "16px",
                  xxl: "24px",
                },
                fontWeight: { xs: 500, md: 700 },
                opacity: 1,
                borderRadius: "0 0 10px 10px",
              }}
              key={path}
              value={path}
              to={label !== "logout" ? path : "/jobs"}
              label={label}
              component={Link}
              onClick={() => {
                handleClick(label);
                handlePopoverCloseTopBar(label);
              }}
              icon={
                label === "Promoter" && (
                  <Box
                    component={"img"}
                    src={promoterlogo}
                    sx={{
                      height: "25px",
                      width: "25px",
                      ml: "4px",
                    }}
                  />
                )
              }
            />
          ))}
        {(userType === USER_ROLES?.at(0)?.role_id ||
          userType === USER_ROLES?.at(1)?.role_id ||
          userType === USER_ROLES?.at(6)?.role_id) &&
          TAB_ITEMS_ADMIN?.filter((item) => item.label !== "logout")?.map(
            ({ label, path }) => (
              <Tab
                sx={{
                  display: "flex",
                  flexDirection: "row-reverse",
                  width: {
                    xs: "100%",
                    md: "100px",
                    mlg: "110px",
                    xl: "120px",
                  },
                  minHeight: "45px",
                  padding: "3px 7px 5px 8px!important",
                  textTransform: "none",
                  color:
                    label === "Candidate"
                      ? "white"
                      : `${theme.palette.grayButton100.light} !important`,
                  fontSize: {
                    xs: "14px",
                    md: "14px",
                    xl: "16px",
                    xxl: "24px",
                  },
                  fontWeight: { xs: 500, md: 700 },
                  opacity: 1,
                  borderRadius: "0 0 10px 10px",
                }}
                key={path}
                value={path}
                to={label !== "logout" ? path : "/jobs"}
                label={label}
                component={Link}
                onClick={() => {
                  handleClick(label);
                  handlePopoverCloseTopBar(label);
                }}
              />
            )
          )}
      </Tabs> */}
      <Tabs orientation="vertical">
        {userType === USER_ROLES?.at(3)?.role_id && isPromoter
          ? generateTabs(TAB_ITEMS_EMPLOYER_PROMOTER, userType)
          : userType === USER_ROLES?.at(3)?.role_id
          ? generateTabs(TAB_ITEMS_EMPLOYER, userType)
          : userType === USER_ROLES?.at(4)?.role_id && isPromoter
          ? generateTabs(TAB_ITEMS_RECRUITER_PROMOTER, userType)
          : userType === USER_ROLES?.at(4)?.role_id
          ? generateTabs(TAB_ITEMS_RECRUITER, userType)
          : userType === USER_ROLES?.at(2)?.role_id && isPromoter
          ? generateTabs(TAB_ITEMS_CANDIDATE_PROMOTER, userType)
          : userType === USER_ROLES?.at(2)?.role_id
          ? generateTabs(TAB_ITEMS_CANDIDATE, userType)
          : userType === USER_ROLES?.at(5)?.role_id
          ? generateTabs(TAB_ITEMS_PROMOTER, userType)
          : userType === USER_ROLES?.at(0)?.role_id ||
            userType === USER_ROLES?.at(1)?.role_id ||
            userType === USER_ROLES?.at(6)?.role_id
          ? generateTabs(TAB_ITEMS_ADMIN, userType)
          : null}
      </Tabs>
    </>
  );
};

export default TopBarMenuList;
