import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  IconButton,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useCallback, useRef, useState } from "react";
import InputBox from "../../../common/InputBox";
import locale from "../../../../i18n/locale";
import { ALERT_TYPE, COIN_SPEND, ERROR_MSG } from "../../../../utils/Constants";
import CustomSelect from "../../../candidate/myCV/CustomSelect";
import { debounce, validateSchema } from "../../../../utils/Common";
import {
  getAssociatedJobs,
  getAssociatedUser,
  getCompanies,
  getSpendItem,
  updateInitialAssociatedJobs,
  updateInitialAssociatedUser,
} from "../../../../redux/employer/empProfileSlice";
import { setAlert } from "../../../../redux/configSlice";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { getTitles } from "../../../../redux/employer/postJobSlice";
import { adminJobsFilter } from "../../../../redux/admin/jobsSlice";
import { getCompanyTeamMember } from "../../../../redux/candidate/myProfileSlice";
import { Modal, Slider } from "@mui/material";
import _ from "lodash";
import AutoComplete from "../../../common/AutoComplete";
import * as Yup from "yup";
import { coinSpend } from "../../../../redux/admin/maintenance";

export default function AddCoinSpend({
  addCoinSpend,
  handleClose,
  getCoinsHistory,
}) {
  const dispatch = useDispatch();
  const theme = useTheme();
  const i18n = locale.en;
  const { companies, associatedJobs, associatedUsers, spendItem } = useSelector(
    (state) => state?.myProfile
  );

  const titlesRef = useRef([]);
  const teammemberRef = useRef([]);
  const [coinSpendInfo, setCoinSpendInfo] = useState(COIN_SPEND);
  const [errors, setErrors] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleOnSelectChange = async (ind, newValue, val, index) => {
    setCoinSpendInfo({
      ...coinSpendInfo,
      [index]: newValue,
    });
    setErrors(errors?.filter((item) => item?.key !== index));
    await dispatch(
      getAssociatedUser({
        company_id: newValue?.value,
        name: "",
      })
    );
    await dispatch(
      getAssociatedJobs({
        company_id: newValue?.value,
        title: "",
      })
    );
  };

  const onCreateOption = async (data, inputId, index) => {
    if (data) {
      setCoinSpendInfo((prev) => ({
        ...prev,
        [inputId]: data,
      }));
    }
  };

  const handleInputChange = (event) => {
    const { id, value, name } = event.target;
    setCoinSpendInfo({ ...coinSpendInfo, [id]: value });
  };

  const handleMultipleAutoComplete = ({
    event,
    newValue,
    id,
    minTags,
    maxTags,
  }) => {
    setErrors(errors?.filter((item) => item?.key !== id));
    setCoinSpendInfo({
      ...coinSpendInfo,
      [id]: newValue?.amount,
      transaction_name_id: newValue?.transaction_name_id,
      transaction_type: newValue?.transaction_type,
    });
  };

  const updateDebounceFunc = async (newValue, type) => {
    let params = {
      title: newValue || "",
      limit: 200,
    };
    if (newValue) {
      try {
        if (type === "company_name") {
          dispatch(getCompanies(params));
        }
        if (type === "associated_job") {
          dispatch(
            getAssociatedJobs({
              company_id: coinSpendInfo?.company_name?.value,
              title: params?.title,
            })
          );
        }
        if (type === "company_user") {
          dispatch(
            getAssociatedUser({
              company_id: coinSpendInfo?.company_name?.value,
              name: params?.title,
            })
          );
        }
      } catch (error) {
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE?.ERROR,
            msg: "Something went wrong",
          })
        );
      }
    }
  };
  const debounceFunction = _.debounce(updateDebounceFunc, 750);

  const handleOnSelectInputChange = (newValue, inputId) => {
    debounceFunction(newValue, inputId);
  };

  const onMenuOpen = async () => {
    if (companies?.length < 1) {
      dispatch(getCompanies());
    }
    if (associatedJobs?.length < 1) {
      dispatch(
        getAssociatedJobs({
          company_id: coinSpendInfo?.company_name?.value,
          name: "",
        })
      );
    }
    if (associatedUsers?.length < 1) {
      dispatch(
        getAssociatedUser({
          company_id: coinSpendInfo?.company_name?.value,
          name: "",
        })
      );
    }
    if (spendItem?.length < 1) {
      dispatch(getSpendItem());
    }
    setIsMenuOpen(true);
  };
  const onMenuClose = () => {
    setIsMenuOpen(false);
  };

  const getSpendItemValue = () => {
    if (coinSpendInfo?.spend_item) {
      return [];
    }
    return spendItem?.find(
      (item) => item?.spend_id === coinSpendInfo?.spend_item
    );
  };

  const spendInfoSchema = Yup.object().shape({
    company_name: Yup.object()
      .shape({
        value: Yup.mixed().required("Company name value is Required."),
        label: Yup.string().required("Company name label is Required."),
      })
      .nullable(true)
      .required("Company is Required."),
    company_user: Yup.object()
      .shape({
        value: Yup.mixed().required("Company User value is Required."),
        label: Yup.string().required("Company User label is Required."),
      })
      .nullable(true)
      .required("Company User is Required."),
    spend_item: Yup.string().required("Spend item is Required."),
    // associated_job: Yup.number().positive("Numeric Field 3 must be a positive number."),
  });

  const handleSave = async () => {
    let { company_name, company_user, spend_item, associated_job } =
      coinSpendInfo;
    let testErrorState = {
      company_name,
      company_user,
      spend_item,
      associated_job,
    };
    validateSchema(spendInfoSchema, testErrorState)
      .then(() => {
        try {
          let data = {
            company_id: coinSpendInfo?.company_name?.value,
            employer_id: coinSpendInfo?.company_user?.value,
            amount: coinSpendInfo?.spend_item,
            transaction_name_id: coinSpendInfo?.transaction_name_id,
            transaction_type: coinSpendInfo?.transaction_type,
          };
          dispatch(coinSpend(data)).then((payload) => {
            if (payload?.payload?.status === "success") {
              dispatch(
                setAlert({
                  show: true,
                  type: ALERT_TYPE?.SUCCESS,
                  msg: "Coin spend added successfully",
                })
              );
              getCoinsHistory(0);
              dispatch(updateInitialAssociatedUser());
              dispatch(updateInitialAssociatedJobs());
              handleClose();
            } else {
              dispatch(
                setAlert({
                  show: true,
                  type: ALERT_TYPE?.ERROR,
                  msg: payload?.message,
                })
              );
            }
          });
        } catch (error) {
          dispatch(
            setAlert({
              show: true,
              type: ALERT_TYPE?.ERROR,
              msg: error,
            })
          );
        }
      })
      .catch((error) => {
        setErrors(
          Object.keys(error)?.map((item) => ({
            message: error[item],
            key: item,
          }))
        );
      });
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: { xs: "auto", tablet: 547 },
    bgcolor: "background.paper",
    borderRadius: "25px",
    boxShadow: "none !important",
  };

  return (
    <Modal
      open={addCoinSpend}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      {/*<ModalDialog>
        <ModalClose />
        <Typography>Modal title</Typography>
  </ModalDialog>*/}

      <Box sx={style}>
        <Box>
          <Button
            variant="contained"
            color="yellowButton400"
            sx={{
              width: "150px",
              height: "45px",
              borderRadius: "25px 0 25px 0",
              position: "absolute",
              top: 0,
              left: 0,
              fontSize: "14px",
              fontWeight: theme.typography.Bold,
            }}
          >
            new coin spend
          </Button>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <Box
              sx={{
                width: "20px",
                height: "20px",
                color: "#000000",
                border: 2,
                fontSize: "18px",
                borderRadius: "5px",
              }}
            >
              X
            </Box>
          </IconButton>
        </Box>
        <Box
          mt="59px"
          padding="0 25px"
          sx={{ display: "flex", flexDirection: "column", gap: "10px" }}
        >
          <Box sx={{ display: "flex", flexDirection: "column", gap: "4px" }}>
            <Typography
              sx={{ fontSize: "12px", fontWeight: theme.typography.Regular }}
            >
              Company Name
            </Typography>
            <CustomSelect
              variant="createSelect"
              onInputChange={handleOnSelectInputChange}
              onChange={handleOnSelectChange}
              onCreateOption={onCreateOption}
              placeholder={i18n["empMyProfile.companyNamePlace"]}
              placeholderFontSize={"12px"}
              inputId="company_name"
              name="company_name"
              value={
                coinSpendInfo?.company_name?.label &&
                coinSpendInfo?.company_name?.value
                  ? {
                      label: coinSpendInfo?.company_name?.label
                        ? coinSpendInfo?.company_name?.label
                        : "",
                      value: coinSpendInfo?.company_name?.value
                        ? coinSpendInfo?.company_name?.value
                        : "",
                    }
                  : null
              }
              onMenuOpen={onMenuOpen}
              onMenuClose={onMenuClose}
              option={companies?.map((item) => ({
                value: item?.company_id,
                label: item?.name,
              }))}
              isLoading={isLoading}
              customStyle={{
                menu: (provided) => ({
                  ...provided,
                  zIndex: 9999,
                }),
                menuList: (provided) => ({
                  ...provided,
                  "&::-webkit-scrollbar": {
                    width: "6px",
                    height: "6px",
                    borderRadius: "2.5px",
                    backgroundColor: "#efefef",
                  },
                  "&::-webkit-scrollbar-thumb": {
                    borderRadius: "2.5px",
                  },
                }),
                option: (
                  styles,
                  { data, isDisabled, isFocused, isSelected }
                ) => {
                  return {
                    ...styles,
                    color: isSelected ? "#fff" : "black",
                    ":active": {
                      ...styles[":active"],
                    },
                  };
                },
                control: (baseStyles) => ({
                  ...baseStyles,
                  boxShadow: "none",
                  borderRadius: "10px",
                  height: `40px`,
                  border: errors?.find((error) => error?.key === "company_name")
                    ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
                    : "1px solid #E0E0E0",
                  "&:hover": errors?.find(
                    (error) => error?.key === "company_name"
                  )
                    ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
                    : "1px solid #E0E0E0",
                }),
              }}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "4px",
              width: "300px",
            }}
          >
            <Typography
              sx={{ fontSize: "12px", fontWeight: theme.typography.Regular }}
            >
              Company User
            </Typography>

            <CustomSelect
              variant="default"
              onInputChange={handleOnSelectInputChange}
              onChange={handleOnSelectChange}
              onCreateOption={onCreateOption}
              placeholderFontSize={"12px"}
              placeholder={"Select the associated user"}
              inputId="company_user"
              name="company_user"
              value={
                coinSpendInfo?.company_user?.label &&
                coinSpendInfo?.company_user?.value
                  ? {
                      label: coinSpendInfo?.company_user?.label
                        ? coinSpendInfo?.company_user?.label
                        : "",
                      value: coinSpendInfo?.company_user?.value
                        ? coinSpendInfo?.company_user?.value
                        : "",
                    }
                  : null
              }
              onMenuOpen={onMenuOpen}
              onMenuClose={onMenuClose}
              option={associatedUsers?.map((item) => ({
                value: item?.user_id,
                label: `${item?.user?.first_name} ${item?.user?.last_name}`,
              }))}
              isLoading={isLoading["company_name"]}
              customStyle={{
                menu: (provided) => ({
                  ...provided,
                  zIndex: 9999,
                }),
                menuList: (provided) => ({
                  ...provided,
                  "&::-webkit-scrollbar": {
                    width: "6px",
                    height: "6px",
                    borderRadius: "2.5px",
                    backgroundColor: "#efefef",
                  },
                  "&::-webkit-scrollbar-thumb": {
                    borderRadius: "2.5px",
                  },
                }),
                option: (
                  styles,
                  { data, isDisabled, isFocused, isSelected }
                ) => {
                  return {
                    ...styles,
                    color: isSelected ? "#fff" : "black",
                    ":active": {
                      ...styles[":active"],
                    },
                  };
                },
                control: (baseStyles) => ({
                  ...baseStyles,
                  boxShadow: "none",
                  borderRadius: "10px",
                  height: `40px`,

                  border: errors?.find((error) => error?.key === "company_user")
                    ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
                    : "1px solid #E0E0E0",
                  "&:hover": errors?.find(
                    (error) => error?.key === "company_user"
                  )
                    ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
                    : "1px solid #E0E0E0",
                  width: `100%`,
                }),
              }}
            />
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "4px",
              width: "300px",
            }}
          >
            <Typography
              sx={{ fontSize: "12px", fontWeight: theme.typography.Regular }}
            >
              Spend Item
            </Typography>
            {/* <InputBox
              id="spend_item"
              value={coinSpendInfo?.spend_item}
              onChange={handleInputChange}
              placeholder={i18n["coinHistory.selecttheitem"]}
              sx={{
                "& .MuiInputBase-input": {
                  fontSize: "12px !important",
                },
                border: errors?.find((error) => error?.key == "spend_item")
                  ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
                  : "1px solid #E0E0E0",
                width: "100% !important",
                borderRadius: "10px !important",
                "& .MuiInputBase-root": {
                  margin: "0 10px",
                },
              }}
            />*/}

            <AutoComplete
              multiple={false}
              borderRadius="10px"
              id="spend_item"
              value={getSpendItemValue()}
              onChange={handleMultipleAutoComplete}
              sx={{
                // display: "inline-table",
                // display: "inline-table",
                display: "flex",
                fontSize: "14px",

                alignItems: "center",
                border: errors?.find((error) => error?.key == "spend_item")
                  ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
                  : "1px solid #E0E0E0",
                width: "100% !important",
                borderRadius: "10px !important",
                "& .MuiAutocomplete-input": {
                  fontSize: "13px !important",
                },
              }}
              placeholder={i18n["coinHistory.selecttheitem"]}
              data={spendItem}
              onFocus={() => {
                onMenuOpen();
              }}
            ></AutoComplete>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "4px",
              width: "300px",
            }}
          >
            <Typography
              sx={{ fontSize: "15px", fontWeight: theme.typography.Regular }}
            >
              Associated Job
            </Typography>
            <CustomSelect
              isDisabled={coinSpendInfo?.transaction_type}
              variant="default"
              onInputChange={handleOnSelectInputChange}
              onChange={handleOnSelectChange}
              onCreateOption={onCreateOption}
              placeholderFontSize={"12px"}
              placeholder={i18n["coinHistory.thejobassociated"]}
              inputId="associated_job"
              name="associated_job"
              value={
                coinSpendInfo?.associated_job?.label &&
                coinSpendInfo?.associated_job?.value
                  ? {
                      label: coinSpendInfo?.associated_job?.label
                        ? coinSpendInfo?.associated_job?.label
                        : "",
                      value: coinSpendInfo?.associated_job?.value
                        ? coinSpendInfo?.associated_job?.value
                        : "",
                    }
                  : null
              }
              onMenuOpen={onMenuOpen}
              onMenuClose={onMenuClose}
              option={associatedJobs?.map((item) => ({
                value: item?.job_title_id,
                label: item?.title,
              }))}
              isLoading={isLoading["associated_job"]}
              customStyle={{
                menu: (provided) => ({
                  ...provided,
                  zIndex: 9999,
                }),
                menuList: (provided) => ({
                  ...provided,
                  "&::-webkit-scrollbar": {
                    width: "6px",
                    height: "6px",
                    borderRadius: "2.5px",
                    backgroundColor: "#efefef",
                  },
                  "&::-webkit-scrollbar-thumb": {
                    borderRadius: "2.5px",
                  },
                }),
                control: (baseStyles) => ({
                  ...baseStyles,
                  "& .placeholder": {
                    fontSize: "14px !important",
                  },
                  boxShadow: "none",
                  borderRadius: "10px",
                  height: "40px",
                  border: errors?.find(
                    (error) => error?.key == "associated_job"
                  )
                    ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
                    : "1px solid #E0E0E0",
                  "&:hover": errors?.find(
                    (error) => error?.key == "associated_job"
                  )
                    ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
                    : "1px solid #E0E0E0",
                  width: `100%`,
                }),
              }}
            />
          </Box>
        </Box>
        <Box sx={{ width: "100%", textAlign: "center", mt: "18px" }}>
          <Button
            variant="contained"
            color="submitButton"
            sx={{
              width: "150px",
              height: "45px",
              borderRadius: "25px 25px 0 0",
              color: "black",
              fontSize: "14px",
              fontWeight: theme.typography.Bold,
            }}
            onClick={() => handleSave()}
          >
            save
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}
