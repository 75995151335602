import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import Chip from "@mui/material/Chip";
import { styled } from "@mui/system";
import { useState } from "react";
import * as React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import { Box } from "@mui/material";
import { StyledAutocomplete } from "../../utils/CommonComponent";

// const StyledAutocomplete = styled(Autocomplete)`
// .MuiAutocomplete-popupIndicator {
//   display: none;
// }
// `;

export default function AutoComplete({
  filtering = false,
  id,
  placeholder,
  sx,
  value,
  defaultValue,
  onChange,
  data,
  height,
  disabled = false,
  multiple = false,
  limitTags = 5,
  allowCustomInput = true,
  index,
  maxTags,
  minTags,
  onBlur,
  showAddOption = false,
  disableCloseOnSelect,
  onFocus,
  border = `1px solid #E0E0E0`,
  borderRadius = "10px",
  autoCompleteRef,
  readOnly,
  width,
  onInputChange,
  key,
}) {
  const optionData = data;
  const [open, setOpen] = useState(false);
  // const loading = open && data?.length === 0;

  return (
    <Paper
      elevation={3}
      sx={{
        display: "flex",
        height: height ? height : "40px",
        boxShadow: "none",
        borderRadius: borderRadius,
        border: border,
        ...sx,
      }}
    >
      <StyledAutocomplete
        key={key && key}
        readOnly={readOnly}
        ref={autoCompleteRef}
        // loading={loading}
        // loadingText={
        //   loading ? (
        //     <Box
        //       style={{
        //         display: "flex",
        //         justifyContent: "center",
        //         alignItems: "center",
        //       }}
        //     >
        //       <CircularProgress color="inherit" size={20} />
        //     </Box>
        //   ) : null
        // }
        open={open}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        showAddOption={showAddOption}
        multiple={multiple}
        limitTags={limitTags}
        sx={{
          width: "100%",
          "& .MuiOutlinedInput-notchedOutline": {
            border: "none",
          },
        }}
        disableCloseOnSelect={disableCloseOnSelect}
        disabled={disabled}
        allowCustomInput={allowCustomInput}
        size="small"
        id={id}
        index={index}
        onBlur={onBlur}
        placeholder={placeholder}
        value={value}
        defaultValue={defaultValue}
        onFocus={onFocus}
        onInputChange={(event, newValue) =>
          onInputChange && onInputChange({ event, newValue })
        }
        onChange={(event, newValue) =>
          onChange({ event, newValue, id, index, limitTags, minTags, maxTags })
        }
        // filterOptions={filterFunction}
        // filterOptions={(options, params) => {
        //   // const filteredOptions = options
        //   //   .filter((val) => val.name !== "") // Filter out objects with empty names
        //   //   .reduce((uniqueMap, obj) => {
        //   //     if (!uniqueMap[obj.name]) {
        //   //       uniqueMap[obj.name] = obj;
        //   //     }
        //   //     return uniqueMap;
        //   //   }, {});

        //   const newOptionsArr = Object.values(options);
        //   const { inputValue } = params;

        //   if (
        //     inputValue &&
        //     inputValue?.length &&
        //     inputValue?.length > 1 &&
        //     allowCustomInput &&
        //     showAddOption
        //   ) {
        //     // Suggest the creation of a new value
        //     const isExisting = newOptionsArr.some(
        //       (option) => inputValue === option.name
        //     );
        //     if (inputValue !== "" && !isExisting) {
        //       newOptionsArr.push({
        //         inputValue,
        //         name: `Add "${inputValue}"`,
        //       });
        //     }
        //   }

        //   const filtered = filtering
        //     ? newOptionsArr.filter((option) =>
        //         option.name.toLowerCase().includes(inputValue.toLowerCase())
        //       )
        //     : newOptionsArr.filter((option) =>
        //         option.name.toLowerCase().startsWith(inputValue.toLowerCase())
        //       );

        //   if (
        //     filtered?.length === 0 &&
        //     inputValue !== "" &&
        //     allowCustomInput &&
        //     showAddOption
        //   ) {
        //     // If no matching options found, add the "Add" option
        //     filtered.push({
        //       inputValue,
        //       name: `Add "${inputValue}"`,
        //     });
        //   }
        //   return filtered;
        // }}
        filterOptions={createFilterOptions({
          ignoreCase: true, // Case-insensitive filtering
          matchFrom: "any", // Match anywhere in the label
          stringify: (option) => option.name || option, // Handle object values
        })}
        selectOnFocus
        clearOnBlur
        handlehomeendkeysfilter="true"
        options={optionData}
        getOptionLabel={(option) => {
          // Value selected with enter, right from the input
          if (typeof option === "string") {
            return option;
          }
          // Add "xxx" option created dynamically
          if (option.inputValue) {
            return option.inputValue;
          }
          // Regular option
          return option.name || "";
        }}
        renderOption={(props, option) => {
          if (option.name !== null) {
            return <li {...props}>{option.name}</li>;
          }
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder={placeholder}
            sx={{
              "& .MuiOutlinedInput-root.MuiInputBase-sizeSmall": {
                paddingRight: "0px !important",
                paddingTop: multiple ? "3px" : "6px",
              },
            }}
          />
        )}
        renderTags={(value, getTagProps) => {
          if (multiple && value[0] !== "") {
            return value.map((option, index) => (
              <>
                <Chip
                  variant="outlined"
                  label={option?.name || option}
                  sx={{
                    borderRadius: "10px !important",
                  }}
                  {...getTagProps({ index })}
                />
              </>
            ));
          }
        }}
      />
    </Paper>
  );
}
