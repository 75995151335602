import { Box, Button, Typography, useTheme } from "@mui/material";
import React, { useRef, useState } from "react";
import Delete from "./Delete";
import {
  deleteCandidateUploadedVideo,
  uploadMyCamVideo,
} from "../../../../redux/candidate/candidateJobs";
import { setAlert } from "../../../../redux/configSlice";
import { ALERT_TYPE } from "../../../../utils/Constants";
import { useDispatch } from "react-redux";

import uploadNewVideoIcon from "../../../../assets/Cam/UploadVideoIcon.svg";
import deleteIcon from "../../../../assets/Cam/DeleteIcon.svg";
import { RadioButtonChecked } from "@mui/icons-material";
import BriefBox from "./BriefBox";
import PlayButton from "../../../common/PlayButton";
import { getProfileProgressData } from "../../../../redux/candidate/myProfileSlice";
import {
  getDecodedToken,
  getToken,
  getVideoFileDuration,
} from "../../../../utils/Common";
import useViewport from "../../../common/ViewPort";
import ReviewVideoDialog from "../../../common/ReviewVideoDialog";
import myVideoHeaderLogo from "../../../../assets/Cam/MyVideoIcon.svg";
import TakeTwoIcon from "../../../../assets/Cam/TakeTwoIcon.svg";
import jobVideoHeaderLogo from "../../../../assets/Cam/JobVideoIcon.svg";

const buttonSize = 60;
const triangleSize = 25;
const PublishVideo = ({
  videoData,
  nextStep,
  userData,
  getVideo,
  setActiveStep,
  uploadVideo,
  textChange,
}) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const VideoRef = useRef(null);
  const { width } = useViewport();
  const hiddenFileInput = useRef(null);
  const fileAccept = "video/*";
  const decodedToken = getDecodedToken(getToken());
  const [openReviewVideoDialog, setOpenReviewVideoDialog] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [disabledButton, setDisabledButton] = useState(true);
  const [isPlaying, setIsPlaying] = useState(false);
  const [showBrief, setShowBrief] = useState(false);
  const [videoLink, setVideoLink] = useState(null);
  const handleOpenDelete = () => {
    setOpenDelete((prevState) => !prevState);
    setConfirmDelete(false);
  };
  const handleCloseDialog = () => {
    setOpenReviewVideoDialog(false);
  };

  const handleCancelDelete = () => {
    setOpenDelete((prevState) => !prevState);
    setConfirmDelete(false);
  };

  const handleRecordNewVideo = () => {
    nextStep(2);
  };
  const handleCloseBriefBox = () => {
    setShowBrief(false);
  };

  const handleFileChange = async (event) => {
    getVideoFileDuration(event?.target?.files?.[0], (error, videoDuration) => {
      if (error) {
        setVideoLink("")
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE?.ERROR,
            msg: error,
          })
        );
      }
      if (event?.target?.files?.[0].size > 100000000) {
        setVideoLink("")
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE?.ERROR,
            msg: "Video size should be less than 100MB",
          })
        );
        return;
      } else if (
        event?.target?.files?.[0]?.name.split(".")?.pop()?.toLowerCase() !==
          "mp4" &&
        event?.target?.files?.[0]?.name?.split(".")?.pop()?.toLowerCase() !==
          "mov"
          &&
          event?.target?.files?.[0]?.name?.split(".")?.pop()?.toLowerCase() !==
            "webm"
      ) {
        setVideoLink("")
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE?.ERROR,
            msg: "Please upload .mp4 or .mov file",
          })
        );
        return;
      } else if (videoDuration > 60) {
        setVideoLink("")
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE?.ERROR,
            msg: "Video length should be less than 1 min",
          })
        );
        return;
      }

      const formData = new FormData();
      formData?.append("cam", event?.target?.files?.[0]);
      try {
        dispatch(uploadMyCamVideo(formData)).then(({ payload }) => {
          if (payload?.status === "success") {
            dispatch(
              setAlert({
                show: true,
                type: ALERT_TYPE.SUCCESS,
                msg: "Video uploaded Successfully!",
              })
            );
            nextStep(4);
            setVideoLink(URL?.createObjectURL(event?.target?.files?.[0]));
            VideoRef?.current?.load();
            getVideo();
          } else {
            dispatch(
              setAlert({
                show: true,
                type: ALERT_TYPE.ERROR,
                msg: "Failed to upload Video",
              })
            );
          }
        });
      } catch (error) {
        dispatch(setAlert({ show: true }));
      }
    });
  };
  const handleFileClick = () => {
    // hiddenFileInput?.current?.click();
    // setActiveStep(3);
    uploadVideo();
  };

  const deleteUploadedVideo = async () => {
    try {
      const { payload } = await dispatch(deleteCandidateUploadedVideo());
      if (payload?.status === "success") {
        await dispatch(getProfileProgressData());
        nextStep(1);
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE.SUCCESS,
            msg: "Uploded video removed successfully!",
          })
        );
      }
    } catch (error) {
      dispatch(
        setAlert({
          show: true,
          type: ALERT_TYPE.ERROR,
          msg: error,
        })
      );
    }
  };

  return (
    <>
      <>
        <Button
          variant="contained"
          color="precium"
          sx={{
            borderRadius: "25px 0 10px 0",
            width: "150px",
            height: "45px",
            fontSize: "14px",

            fontWeight: theme.typography.Bold,
            gap: "10px",
          }}
        >
          <Box
            component={"img"}
            src={myVideoHeaderLogo}
            sx={{
              height: "20px",
              width: "24px",
            }}
          />
          my video
        </Button>
        <Box
          sx={{
            width: "70%",
            margin: "auto",
            flexDirection: "column",
            alignItems: "center",
            display: "flex",
            gap: "20px",
            marginTop: "30px",
          }}
        >
          <Typography
            sx={{
              fontSize: "20px",
              fontWeight: theme.typography.Bold,
              textAlign: "center",
            }}
          >
            And the Oscar goes to…
          </Typography>
        </Box>
        <Box
          sx={{
            flexGrow: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "30px",
            paddingTop: "0px",
            paddingBottom: "0px",
            flexDirection: "column",
            position: "relative",
            boxSizing: "border-box",
          }}
        >
          <Box
            sx={{ width: { xs: "100%", mdl: "60%", lg: "60%", lgm: "60%" } }}
          >
            {!isPlaying && (
              <PlayButton
                buttonSize={buttonSize}
                triangleSize={triangleSize}
                top={"calc(50% - 100px)"}
              />
            )}
            <video
              preload="auto"
              ref={VideoRef}
              loops
              autoPlay={false}
              poster=""
              style={{
                borderRadius: "25px 25px 0 0",
                height: "auto",
                width: "100%",
                aspectRatio: "4/3",
                objectFit: "cover",
                cursor: disabledButton ? "wait" : "pointer",
                border: disabledButton
                  ? `1px solid ${theme.palette.grayButton600.main}`
                  : "none",
                borderBottom: "none", // To remove the border for the bottom side
              }}
              muted={false}
              src={videoLink || videoData}
              type="video/mp4"
              onClick={() => {
                // if (!disabledButton) {
                //   if (VideoRef?.current?.paused) {
                //     VideoRef?.current?.play();
                //     setIsPlaying(true);
                //   } else {
                //     VideoRef?.current?.pause();
                //     setIsPlaying(false);
                //   }
                // }
                setOpenReviewVideoDialog(true);
              }}
              onLoadedData={() => {
                setDisabledButton(false);
              }}
              onEnded={() => setIsPlaying(false)}
            ></video>
            <Box
              sx={{
                display: "flex",
                width: { xs: "100%", lgm: "100%" },
                background: theme.palette.mainBackground,
                borderRadius: "0 0 25px 25px",
                height: "60px",
                marginTop: "-6.5px",
              }}
            >
              <Box
                sx={{
                  width: "68%",
                  margin: "auto",
                  padding: "0 0 0 20px ",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "15px",
                    fontWeight: theme.typography.Bold,
                  }}
                >
                  Crayon Cam
                </Typography>
                <Typography
                  sx={{
                    fontSize: "13px",
                    fontWeight: theme.typography.Regular,
                  }}
                >
                  Staring: {userData?.first_name || decodedToken?.data?.fName}{" "}
                  {userData?.last_name || decodedToken?.data?.lName}
                </Typography>
              </Box>
              <Button
                variant="contained"
                color="videoButton"
                sx={{
                  width: "30%",
                  height: "100%",
                  borderRadius: "0 0 25px 0",
                }}
                onClick={() => setShowBrief(true)}
              >
                the brief
              </Button>
            </Box>
            <Box
              sx={{
                width: "100%",
                // margin: "auto",
                flexDirection: "column",
                alignItems: "center",
                display: "flex",
                gap: "20px",
                marginTop: "30px",
                marginBottom: { lg: "30px", lg1440: "0px" },
              }}
            >
              <Typography
                sx={{
                  fontSize: "14px",
                  textAlign: "center",

                  fontWeight: theme.typography.Regular,
                }}
              >
                Your video has been successfully recorded. Hollywood awaits!
              </Typography>
              <Typography
                sx={{
                  fontSize: "14px",
                  textAlign: "center",

                  fontWeight: theme.typography.Regular,
                }}
              >
                {textChange
                  ? "You can re-record or upload a new video below."
                  : "Review your view and then lock it in, or try again if you weren’t happy with the first take."}
              </Typography>
            </Box>
          </Box>
        </Box>
        {showBrief && (
          <BriefBox show={showBrief} handleOpen={handleCloseBriefBox} />
        )}

        <Box
          sx={{
            margin: "auto",
            width: "100%",
            height: "60px",
          }}
        >
          <Button
            variant="contained"
            color="deleteVideoButton"
            sx={{
              borderRadius: "0 0 0 25px",
              width: "33.33%",
              height: "100%",
              gap: "8px",
            }}
            onClick={handleOpenDelete}
          >
            <Box
              component={"img"}
              src={deleteIcon}
              sx={{
                height: "20px",
                display: { xs: "none", tablet: "block" },
                width: "24px",
              }}
            />
            delete
          </Button>
          {textChange ? (
            <Button
              variant="contained"
              color="yoco"
              sx={{
                borderRadius: 0,
                width: "33.33%",
                height: "100%",
                gap: "8px",
              }}
              onClick={() => hiddenFileInput?.current?.click()}
            >
              <Box
                component={"img"}
                src={uploadNewVideoIcon}
                sx={{
                  height: "20px",
                  width: "24px",
                }}
              />
              upload video
            </Button>
          ) : (
            <Button
              variant="contained"
              color="yoco"
              sx={{
                borderRadius: 0,
                width: "33.33%",
                height: "100%",
                gap: "8px",
              }}
              onClick={handleRecordNewVideo}
            >
              {(width > 767 || width === 0) && (
                <Box
                  component={"img"}
                  src={TakeTwoIcon}
                  sx={{
                    height: "20px",
                    width: "24px",
                  }}
                />
              )}
              {/* {width > 767 && <RadioButtonChecked />} */}
              {/* record new video */}
              take two
            </Button>
          )}

          <input
            accept={fileAccept}
            ref={hiddenFileInput}
            type="file"
            onChange={handleFileChange}
            style={{ display: "none" }}
          />
          {textChange ? (
            <Button
              variant="contained"
              color="nandos"
              sx={{
                borderRadius: "0 0 25px 0",
                width: "33.33%",
                height: "100%",
                gap: "8px",
              }}
              onClick={handleRecordNewVideo}
            >
              {/* <RadioButtonChecked /> */}
              {(width > 767 || width === 0) && (
                <Box
                  component={"img"}
                  src={jobVideoHeaderLogo}
                  sx={{
                    height: "20px",
                    width: "24px",
                  }}
                />
              )}
              record new video
            </Button>
          ) : (
            <Button
              variant="contained"
              color="nandos"
              sx={{
                borderRadius: "0 0 25px 0",
                width: "33.33%",
                height: "100%",
                gap: "8px",
              }}
              onClick={handleFileClick}
            >
              <Box
                component={"img"}
                src={myVideoHeaderLogo}
                sx={{
                  height: "20px",
                  width: "24px",
                  display: { xs: "none", tablet: "block" },
                }}
              />
              lock it in
            </Button>
          )}
        </Box>
      </>
      <Delete
        show={openDelete}
        handleOpen={handleOpenDelete}
        handleCancel={handleCancelDelete}
        handleDelete={deleteUploadedVideo}
        confirmDelete={confirmDelete}
        setConfirmDelete={setConfirmDelete}
      />
      {openReviewVideoDialog && (
        <ReviewVideoDialog
          videoLink={videoData}
          open={openReviewVideoDialog}
          setOpen={handleCloseDialog}
        />
      )}
    </>
  );
};

export default PublishVideo;
