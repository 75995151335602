import React from "react";
import { Box } from "@mui/material";
import SmallButtonTalent from "./SmallButtonTalent";

export default function TalentSVGButton({
  color,
  source,
  onClick,
  height,
  width,
  padding,
  margin,
  startIconMargin,
  minWidth,
  cursor,
  marginLeft,
  outerHeight = 31,
  outerWidth = 20,
}) {
  return (
    <SmallButtonTalent
      onClick={onClick}
      color={color}
      startIcon={
        source ? (
          <Box
            component="img"
            className="eye"
            alt="logo"
            src={source}
            sx={{
              height: height,
              width: width,
            }}
          />
        ) : (
          <Box
            component="div"
            sx={{
              height: height,
              width: width,
              background: "grey",
            }}
          />
        )
      }
      cursor={cursor}
      minWidth={minWidth}
      padding={padding}
      justifyContent={"center"}
      borderRadius={50}
      height={outerHeight}
      width={outerWidth}
      fontWeight={700}
      margin={margin}
      startIconMargin={startIconMargin}
      marginLeft={marginLeft}
    />
  );
}
