import {
  Box,
  Button,
  InputAdornment,
  InputBase,
  Paper,
  Popover,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import commentIcon from "../../../../assets/Padding Excluded/Black_Chat.svg";
import TalentSVGButton from "../../../common/TalentSVGButton";
import sort from "../../../../assets/Padding Excluded/Black_Sort_Filter.svg";
import { setAlert } from "../../../../redux/configSlice";
import { ALERT_TYPE, COMMENT_SORTING } from "../../../../utils/Constants";
import {
  addJobComment,
  getAllComments,
} from "../../../../redux/admin/jobsSlice";
import locale from "../../../../i18n/locale";
import { useDispatch } from "react-redux";
import { dateConverterMonth } from "../../../../utils/DateTime";
import InfiniteScroll from "react-infinite-scroll-component";
import profile from "../../../../assets/user_logo.svg";
import SortDropDown from "../../../common/SortDropDown";
import { StyledTextField } from "../../../../utils/CommonComponent";

export default function CommentBox({
  jobContent,
  totalComment,
  setTotalComment,
  getComments,
  setComments,
  comments,
}) {
  const i18n = locale.en;
  const theme = useTheme();
  const dispatch = useDispatch();
  const [inputValue, setInputValue] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [anchorElComment, setAnchorElComment] = useState(null);
  const [currentSort, setCurrentSort] = useState(1);
  const sendComment = async () => {
    try {
      setIsSubmitting(true);

      const comment = {
        job_id: jobContent?.job_id,
        comment: inputValue,
      };

      const { payload } = await dispatch(addJobComment(comment));

      if (payload?.status === "success") {
        setInputValue("");
        await getComments(jobContent?.job_id);
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE.SUCCESS,
            msg: "Comment Added Successfully!",
          })
        );
      } else {
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE.ERROR,
            msg: payload?.message,
          })
        );
      }
    } catch (error) {
      dispatch(
        setAlert({
          show: true,
          type: ALERT_TYPE.ERROR,
          msg: error,
        })
      );
    } finally {
      setIsSubmitting(false);
    }
  };

  const handlePostButtonClick = () => {
    if (!isSubmitting && inputValue !== "") {
      sendComment();
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter" && !isSubmitting && inputValue !== "") {
      event.preventDefault();
      sendComment();
    }
  };

  // const getComments = async (sort) => {
  //   try {
  //     const { payload } = await dispatch(
  //       getAllComments({ job_id: jobContent?.job_id, sort_type: sort })
  //     );
  //     if (payload?.status == "success") {
  //       setComments(payload?.data);
  //       setTotalComment(payload?.count);
  //     } else {
  //       dispatch(
  //         setAlert({
  //           show: true,
  //           type: ALERT_TYPE?.ERROR,
  //           msg: payload?.message,
  //         })
  //       );
  //     }
  //   } catch (error) {
  //     dispatch(
  //       setAlert({
  //         show: true,
  //         type: ALERT_TYPE?.ERROR,
  //         msg: error,
  //       })
  //     );
  //   }
  // };

  const handleSort = async (sortID) => {
    setCurrentSort(sortID);
    setAnchorElComment(null);
    await getComments(sortID);
  };

  const handlePopoverCloseReferral = () => {
    setAnchorElComment(null);
  };
  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Button
          variant="contained"
          color="activeButton"
          sx={{
            width: "150px",
            height: "45px",
            borderRadius: "25px 0 25px 0",
            padding: "6px 0 6px 10px!important",
          }}
          startIcon={
            <Box
              component={"img"}
              src={commentIcon}
              sx={{
                width: 20,
                height: 20,
              }}
            />
          }
        >
          {i18n["allTalent.comments"]} ({totalComment})
        </Button>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            cursor: "pointer",
            paddingRight: "20px",
          }}
          onClick={(event) => {
            setAnchorElComment(event.target);
          }}
        >
          <TalentSVGButton
            color={"white"}
            source={sort}
            outerHeight={22}
            height={18}
            width={15}
            padding={"0px !important"}
            startIconMargin={"0px !important"}
          />
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: 700,
            }}
          >
            {"Sort"}
          </Typography>
        </Box>
        <Popover
          id="dropdown"
          open={Boolean(anchorElComment)}
          anchorEl={anchorElComment}
          onClose={handlePopoverCloseReferral}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          sx={{
            "& .MuiPaper-root-MuiPopover-paper": {
              minWidth: "18% !important",
              borderRadius: "20px !important",
              mt: 1,
            },
          }}
        >
          <SortDropDown
            list={COMMENT_SORTING?.filter((item) => item?.id !== currentSort)}
            handleClick={handleSort}
          />
        </Popover>
      </Box>
      <Box
        id="talentList"
        sx={{ overflow: "hidden", height: "160px", padding: "0 18px" }}
      >
        <InfiniteScroll
          style={{
            height: "100%",
            overflowX: "hidden",
            scrollbarWidth: "thin",
          }}
          dataLength={comments !== undefined && comments?.length}
          hasMore={true}
          scrollableTarget="talentList"
          endMessage={
            <p style={{ textAlign: "center" }}>
              <b>Yay! You have seen it all</b>
            </p>
          }
        >
          {comments?.length > 0 ? (
            comments?.map((comment) => {
              return (
                <Box sx={{ display: "flex", mt: 2 }}>
                  <Box
                    component="img"
                    className="profileAvatar"
                    alt="crayon logo"
                    src={
                      comment?.profile_url !== "NO URL"
                        ? comment?.profile_url
                        : profile
                    }
                    sx={{
                      mr: 1,
                      width: 20,
                      height: 20,
                    }}
                  />
                  <Box
                    sx={{
                      flexGrow: 1,
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontWeight: theme.typography.SemiBold,
                        mr: 1,
                      }}
                    >
                      {`${comment?.user?.first_name} ${
                        comment?.user?.last_name
                      }  (${
                        comment?.employer_profile
                          ? comment?.employer_profile?.company_name
                          : "-"
                      })`}
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontWeight: theme.typography.Regular,
                        mr: 1,
                        lineBreak: "anywhere",
                      }}
                    >
                      {comment?.comment}
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "12px",
                        fontWeight: 400,
                        mr: 1,
                        color: theme.palette.grayButton.main,
                        textAlign: "end",
                      }}
                    >
                      {dateConverterMonth(comment.created_at)}
                    </Typography>
                  </Box>
                </Box>
              );
            })
          ) : (
            <Box
              sx={{
                width: "100%",
                textAlign: "center",
                mt: 4,
                color: theme.palette.placeholder,
              }}
            >
              {i18n["pendingJobs.noData"]}
            </Box>
          )}
          <style>
            {`.infinite-scroll-component::-webkit-scrollbar {
                  width: 7px !important;
                  background-color: #F5F5F5; /* Set the background color of the scrollbar */
                }

                .infinite-scroll-component__outerdiv {
                  height:100%
                }

                .infinite-scroll-component::-webkit-scrollbar-thumb {
                  background-color: #888c; /* Set the color of the scrollbar thumb */
                }`}
          </style>
        </InfiniteScroll>
      </Box>
      <Box sx={{ padding: "5px 20px 12px 20px" }}>
        <Paper
          sx={{
            display: "flex",
            height: "40px",
            borderRadius: "10px",
            boxShadow: "none",
            border: "1px solid #E0E0E0",
          }}
        >
          <Paper
            elevation={0}
            component="form"
            sx={{
              display: "flex",
              borderRadius: "25px",
              alignItems: "center",
              width: 1,
            }}
          >
            <InputBase
              sx={{
                ml: 2,
                width: 1,
              }}
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
              onKeyDown={handleKeyDown}
              placeholder="add your comment here"
              type={"text"}
            />
          </Paper>
          <Button
            sx={{
              borderRadius: "0 10px 10px 0",
              fontSize: "12px",
              height: "40px !important",
            }}
            variant="contained"
            color="activeButton"
            onClick={handlePostButtonClick}
          >
            post
          </Button>
        </Paper>
      </Box>
    </>
  );
}
