import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ALERT_TYPE, ERROR_MSG } from "../utils/Constants";
import { getApi, postApi } from "../utils/Apis";
const initialState = {
  loading: false,
  alert: {
    show: false,
    type: ALERT_TYPE.ERROR, // set default type to avoid warning in console
    msg: ERROR_MSG,
  },
  industries: [],
  pendingAPi: [],
};
export const getAllIndustries = createAsyncThunk(
  "getAllIndustries",
  async (payload, { dispatch }) => {
    const { data } = await getApi("/industries");
    return data;
  }
);
export const setUserEditPlan = createAsyncThunk(
  "setEditPlan",
  async (payload, { dispatch }) => {
    const { data } = await postApi("/admin/editplan", payload, true);
    return data;
  }
);
export const configSlice = createSlice({
  name: "config",
  initialState,
  reducers: {
    setLoading: (state, action) => {
      if (typeof action.payload === "object") {
        const loadingState = action?.payload?.value;
        const apiName = action?.payload?.apiName;
        if (loadingState) {
          state.loading = loadingState;
          state.pendingAPi = [...state.pendingAPi, apiName];
        }
        if (!loadingState) {
          const pendingApi = state.pendingAPi?.filter(
            (item) => item !== apiName
          );
          if (pendingApi?.length === 0) {
            state.loading = loadingState;
            state.pendingAPi =[...pendingApi]
          }else{
            state.pendingAPi =[...pendingApi]
          }
        }
      } else {
        state.loading = action?.payload;
      }
    },
    setAlert: (state, action) => {
      state.alert.show = action?.payload?.show;
      state.alert.type = action?.payload?.type;
      state.alert.msg = action?.payload?.msg;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getAllIndustries?.pending, (state, action) => {
        // state.loading = true;
      })
      .addCase(getAllIndustries?.fulfilled, (state, action) => {
        const allIndustries = action?.payload?.data?.map((industry, index) => {
          return {
            ...industry,
            id: industry.industry_id,
            color: "blueButton600",
            name: industry.name.toLowerCase(),
          };
        });

        const obj = {
          id: 1111,
          name: "all industries",
          color: "blueButton600",
          title: true,
        };
        allIndustries?.unshift(obj);
        state.industries = allIndustries;
      })
      .addCase(getAllIndustries?.rejected, (state, action) => {
      });
  },
});
// Action creators are generated for each case reducer function
export const { setLoading, setAlert } = configSlice.actions;
export default configSlice.reducer;
