import React, { useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import NameInfo from "./NameInfo";
import SelectPermission from "./SelectPermission";
import { dateConverterMonth } from "../../../utils/DateTime";
import { Button } from "@mui/material";
import CircleIcon from "@mui/icons-material/Circle";
import Actions from "./Actions";
import { ToggleCheckedSwitch } from "../../../utils/CommonComponent";
import { editTeamMemberStatus } from "../../../redux/employer/myTeams";
import { useDispatch } from "react-redux";

export default function TeamsDataTable({
  rows,
  setDeleted,
  role,
  superAdminFlag,
  companyId,
  filteredMember,
}) {
  const dispatch = useDispatch();
  const handleStatus = async (userId, status, params) => {
    const payload = {
      company_id: companyId,
      assign_license: status,
      employer_id: userId,
    };
    if (status === false) {
      payload["license_id"] = params?.row?.license_id;
    }
    try {
      const response = await dispatch(editTeamMemberStatus(payload));
      if (response?.payload?.status === "success") {
        filteredMember();
      }
    } catch (error) {
      console.log(error, "error");
    }
  };
  const handleSelectChange = (event, params, id, name) => {
    handleStatus(params?.row?.userId, id, params);
  };
  const columns = [
    {
      field: "name",
      headerName: "Name",
      width: 350,
      renderCell: (params) => {
        return (
          <NameInfo
            avatarInitial={params?.value?.avatarInitial}
            name={params?.value?.fullname}
            email={params?.value?.email}
            userID={params?.row?.userId}
            role={role}
            isSuperAdmin={params?.row?.isSuperAdmin}
          />
        );
      },
    },
    {
      field: "Status",
      headerName: "Status",
      width: 200,
      renderCell: (params) => {
        return (
          <div>
            {params?.row?.Status === true ? (
              // <ActiveChip label="Active"/>
              <Button
                startIcon={<CircleIcon />}
                size="small"
                variant="outlined"
                sx={{
                  padding: "0 8px 0 5px",
                  color: "green",
                  borderRadius: "8px",
                  border: "1px solid #009700",
                  height: 1,
                  fontSize: "14px",
                  fontWeight: "bold",
                  background: "#defbde",
                }}
              >
                online{" "}
              </Button>
            ) : (
              <Button
                disabled
                startIcon={
                  <CircleIcon
                    sx={{
                      fontSize: "small",
                    }}
                  />
                }
                size="small"
                variant="outlined"
                sx={{
                  padding: "0 5px",
                  color: "gray",
                  borderRadius: "8px",
                  border: "1px solid gray",
                  height: 1,
                  fontSize: "14px",
                  fontWeight: "bold",
                  background: "lightGray",
                }}
              >
                offline
              </Button>
            )}
          </div>
        );
      },
    },
    {
      field: "dateAdded",
      headerName: "Date Added",
      width: 190,
      renderCell: (params) => dateConverterMonth(params?.row?.dateAdded),
    },
    {
      field: "lastActive",
      headerName: "Last Active",
      width: 190,
      renderCell: (params) =>
        params?.row?.lastActive === null
          ? "-"
          : dateConverterMonth(params?.row?.lastActive),
    },
    {
      field: "licenseFlag",
      headerName: "Active Status",
      width: 190,
      renderCell: (params) => {
        return (
          <ToggleCheckedSwitch
            disabled={
              params?.row?.isSuperAdmin ||
              params?.row?.role_type_id === 5 ||
              params?.row?.role_type_id === 4
            }
            checkedColor={"recentButton"}
            notCheckedColor={"employerButton"}
            checked={params?.row?.licenseFlag}
            onChange={(event, id, name) => {
              if (params?.row) {
                handleSelectChange(event, params, id, name);
              }
            }}
          />
        );
      },
    },
    {
      field: "permissions",
      headerName: "Permissions",
      width: 250,
      renderCell: (params) => {
        return (
          <SelectPermission
            selectedPermission={params?.row?.permissions}
            user_id={params?.row?.userId}
            role={role}
            superAdminFlag={superAdminFlag}
          />
        );
      },
    },
    {
      field: "actions",
      headerName: "",
      sortable: false,
      width: 140,
      renderCell: (params) => {
        return (
          <Actions
            userID={params?.row?.userId}
            permission={params?.row?.permissions}
            setDeleted={setDeleted}
            role={role}
            userName={params?.row?.name?.fullname}
            isSuperAdmin={params?.row?.isSuperAdmin}
            superAdminFlag={superAdminFlag}
          />
        );
      },
    },
  ];
  const [selectedRows, setSelectedRows] = useState([]);
  const handleSelectionChange = (selection) => {
    setSelectedRows(selection);
  };
  return (
    <div style={{ height: `calc(100vh - 400px)`, width: "100%" }}>
      <DataGrid
        rows={rows}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: { pageSize: 25 },
          },
        }}
        onRowSelectionModelChange={handleSelectionChange}
        pageSizeOptions={[25, 50]}
        checkboxSelection
      />
    </div>
  );
}
