import Button from "@mui/material/Button";
import { Tooltip } from "@mui/material";

export default function SmallButton({
  color,
  label,
  startIcon,
  endIcon,
  textColor,
  height = 20,
  startIconMargin,
  onClick,
  value,
  justifyContent,
  disabled,
  boxShadow,
  backgroundColor,
  fontSize = 10,
  fontWeight = 700,
  cursor,
  width = "fit-content",
  padding = "0 8px",
  margin = 0,
  ref,
  ...props
}) {
  return (
    <Tooltip disableInteractive title={value ? value : label} placement="top">
      <Button
        variant="contained"
        ref={ref}
        disabled={disabled}
        color={color}
        startIcon={startIcon}
        endIcon={endIcon}
        onClick={onClick}
        sx={{
          cursor: cursor,
          justifyContent: justifyContent,
          fontSize: fontSize,
          fontWeight: fontWeight,
          letterSpacing: "0.75px",
          height: height,
          margin: margin,
          boxShadow: 0,
          borderRadius: "5px",
          color: textColor,
          backgroundColor: backgroundColor,
          minWidth: width,
          padding: padding,
          ".MuiButton-startIcon": {
            marginRight: startIconMargin,
            marginLeft: 0,
          },
          ":hover": {
            boxShadow: "none",
            backgroundColor: backgroundColor,
          },
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          ...props,
        }}
      >
        {label}
      </Button>
    </Tooltip>
  );
}
