import { useTheme } from "@emotion/react";
import { Box, Button, Typography } from "@mui/material";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import OurStoryFooterComponent from "./OurStoryFooterComponent";
import WhatTheFaqFooterComponent from "./WhatTheFaqFooterComponent";
import CultureFooterComponent from "./CultureFooterComponent";
import HowThisPageWork from "./HowThisPageWork";
import HowTheSiteWork from "./HowTheSiteWork";
import MoreCoolStuff from "./MoreCoolStuff";
import Facebookcon from "../assets/Footer/crayon_social_Facebook.svg";
import InstagramIcon from "../assets/Footer/crayon_social_Instagram.svg";
import TwitterIcon from "../assets/Footer/crayon_social_X.svg";
import LinkedinIcon from "../assets/Footer/crayon_social_LinkedIn.svg";
import YoutubeIcon from "../assets/Footer/crayon_social_Youtube.svg";
const tabs = [
  "how this page works",
  "how the site works",
  "what da FAQ?!",
  "culture add",
  "our story",
  "more cool stuff",
];
const colors = {
  "how this page works": "#38A8AE",
  "how the site works": "#00A9E0",
  "what da FAQ?!": "#CE0A24",
  "culture add": "#FFC425",
  "our story": "#EC5228",
  "more cool stuff": "#5932D9",
};

const FooterTabs = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const handlePolicy = () => {
    navigate("/privacyPolicy");
  };
  const handleService = () => {
    navigate("/termsOfService");
  };
  const [activeTab, setActiveTab] = useState(tabs[0]);
  const tabComponent = {
    "how this page works": (
      <Box sx={{ background: colors["how this page works"], height: "100%" }}>
        <HowThisPageWork />
      </Box>
    ),
    "how the site works": (
      <Box sx={{ background: colors["how the site works"], height: "100%" }}>
        <HowTheSiteWork />
      </Box>
    ),
    "what da FAQ?!": (
      <Box sx={{ background: colors["what da FAQ?!"], height: "100%" }}>
        <WhatTheFaqFooterComponent />
      </Box>
    ),
    "culture add": (
      <Box sx={{ background: "#FFFFFF", height: "100%" }}>
        <CultureFooterComponent />
      </Box>
    ),
    "our story": (
      <Box sx={{ background: colors["our story"], height: "100%" }}>
        <OurStoryFooterComponent />
      </Box>
    ),
    "more cool stuff": (
      <Box
        sx={{
          background: colors["more cool stuff"],
          height: "100%",
          position: "relative",
        }}
      >
        <MoreCoolStuff />
        {/* <Box
          sx={{
            position: "absolute",
            right: "0",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Button
            sx={{
              color: "#FFF",
              fontSize: "14px",
              fontWeight: theme.typography.Bold,
            }}
            onClick={handleService}
          >
            Terms of Service
          </Button>
          <Button
            sx={{
              color: "#FFF",
              fontSize: "14px",
              fontWeight: theme.typography.Bold,
            }}
            onClick={handlePolicy}
          >
            Privacy Policy
          </Button>
        </Box> */}
      </Box>
    ),
  };

  return (
    <Box sx={{ height: "100%", position: "relative" }}>
      <Box sx={{ display: "flex", alignItems: "flex-end", width: "100%" }}>
        {tabs?.map((item) => {
          return (
            <Button
              className={`footer-btn ${item === activeTab ? "active" : ""}`}
              sx={{
                background: `${colors[item]}!important`,
                width: "200px",
                color: "white",
                fontSize: {
                  md: "12px",
                  lg: "14px",
                  mlg: "14px",
                  lgm: "16px",
                  xl: "16px",
                  xxl: "16px",
                },
                fontWeight: "700",
                borderRadius:
                  item === activeTab ? "20px 20px 0 0" : "0 20px 0 0",
                position: "relative",
                "--bgcolor": colors[item],
                flex: "1",
                minHeight: item === activeTab ? "60px" : "45px",
              }}
              onClick={() => {
                setActiveTab(item);
              }}
            >
              {item}
            </Button>
          );
        })}
      </Box>
      <Box
        sx={{
          height: "200px",
          maxHeight: "200px",
          minHeight: "200px",
          width: "40px",
          // background: "red",
          position: "absolute",
          right: 0,
          top: `calc(50% - 100px)`,
          borderRadius: "10px 0px 0px 10px",
          display: "flex",
          flexDirection: "column",
          gap: "0px",
        }}
      >
        <Link
          to={`https://x.com/crayon_za`}
          style={{
            textDecoration: "none",
            color: theme.palette.black,
            height: "40px",
            maxheight: "40px",
            maxWidth: "40px",
            minWiidth: "40px",
            minHeight: "40px",
            width: "40px",
          }}
          target="_blank"
        >
          <Box
            component="img"
            sx={{
              height: "40px",
              maxheight: "40px",
              maxWidth: "40px",
              minWiidth: "40px",
              minHeight: "40px",
              width: "40px",
            }}
            src={TwitterIcon}
          ></Box>
        </Link>
        <Link
          to={`https://facebook.com/crayonjobs`}
          style={{
            textDecoration: "none",
            color: theme.palette.black,
            height: "40px",
            maxheight: "40px",
            maxWidth: "40px",
            minWiidth: "40px",
            minHeight: "40px",
            width: "40px",
          }}
          target="_blank"
        >
          <Box
            component="img"
            sx={{
              height: "40px",
              maxheight: "40px",
              maxWidth: "40px",
              minWiidth: "40px",
              minHeight: "40px",
              width: "40px",
            }}
            src={Facebookcon}
          ></Box>
        </Link>
        <Link
          to={`https://youtube.com/@crayonjobs`}
          style={{
            textDecoration: "none",
            color: theme.palette.black,
            height: "40px",
            maxheight: "40px",
            maxWidth: "40px",
            minWiidth: "40px",
            minHeight: "40px",
            width: "40px",
          }}
          target="_blank"
        >
          <Box
            component="img"
            sx={{
              height: "40px",
              maxheight: "40px",
              maxWidth: "40px",
              minWiidth: "40px",
              minHeight: "40px",
              width: "40px",
            }}
            src={YoutubeIcon}
          ></Box>
        </Link>
        <Box
          component="img"
          sx={{
            height: "40px",
            maxheight: "40px",
            maxWidth: "40px",
            minWiidth: "40px",
            minHeight: "40px",
            width: "40px",
          }}
          src={InstagramIcon}
        ></Box>
        <Link
          to={`https://linkedin.com/company/crayon_za`}
          style={{
            textDecoration: "none",
            color: theme.palette.black,
            height: "40px",
            maxheight: "40px",
            maxWidth: "40px",
            minWiidth: "40px",
            minHeight: "40px",
            width: "40px",
          }}
          target="_blank"
        >
          <Box
            component="img"
            sx={{
              height: "40px",
              maxheight: "40px",
              maxWidth: "40px",
              minWiidth: "40px",
              minHeight: "40px",
              width: "40px",
            }}
            src={LinkedinIcon}
          ></Box>
        </Link>
      </Box>
      <Box
        sx={{
          height: "100%",
          minHeight: { xs360: "100px", lgm: "600px" },
          maxHeight: { xs360: "600px", lgm: "100%" },
        }}
      >
        {tabComponent[activeTab]}
      </Box>
    </Box>
  );
};

export default FooterTabs;
