import { Box, Button, Modal, Typography } from "@mui/material";
import React from "react";
import CloseIcon from "../CloseIcon";
import { useTheme } from "@emotion/react";
import CompanyModalPopupModal from "../../../assets/CompanyModalPopupImage.svg";
import { useNavigate } from "react-router-dom";

const ExisitingModalSelectModal = ({
  open,
  setOpen,
  handleSubmit,
  isSecondaryCompany,
}) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    height: "auto",
    width: { xs: "360px", tablet: "450px" },
    bgcolor: "background.paper",
    borderRadius: "25px",
    boxShadow: "none !important",
    // border: "1px solid black !important",
    "&:focus-visible": {
      outline: "none",
    },
  };
  const navigateContactUs = () => {
    navigate("/contact");
  };
  return (
    <Modal
      open={open}
      onClose={() => {
        setOpen(false);
        // setopenApplyJobDialog(false);
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Box
          sx={{
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
            // gap: 3,
            width: "100%",
          }}
        >
          <CloseIcon top="15px" right="15px" onClick={() => setOpen(false)} />
          <Box
            sx={{
              padding: { xs: "0 10px", tablet: "0 15px" },
            }}
          >
            <Box
              component={"img"}
              src={CompanyModalPopupModal}
              sx={{ width: 136, height: 125, marginTop: "20px" }}
            />
            <Typography
              sx={{
                fontSize: "20px",
                fontWeight: theme.typography.Bold,
                lineHeight: "27px",
                marginBottom: "20px",
              }}
            >
              Hang on a second
            </Typography>
            <Typography
              sx={{
                fontSize: "16px",
                fontWeight: theme.typography.Bold,
                marginBottom: "20px",
              }}
            >
              {!isSecondaryCompany
                ? "This company account already exists as a standalone Employer account."
                : "This company account already exists as a Recruiter client account."}
            </Typography>
            <Typography
              sx={{
                fontSize: "14px",
                fontWeight: theme.typography.Bold,
                marginBottom: "20px",
              }}
            >
              You can request to join this company as a Team Member, request a
              transfer of this company to your account, or contact the Crayon
              team to investigate and resolve. Otherwise, exit and create a new
              company with a different name.
            </Typography>
            <Typography
              sx={{
                fontSize: "14px",
                marginBottom: "20px",
                fontWeight: theme?.typography.Regular,
              }}
            >
              Note: If a transfer of the account is approved, you will gain
              access to the existing talent pool and be responsible for any
              costs currently associated with that account.
            </Typography>
          </Box>
          {!isSecondaryCompany ? (
            <>
              <Box sx={{ width: "100%" }}>
                <Button
                  sx={{
                    width: "33.33%",
                    borderRadius: "0px 0px 0px 25px",
                    height: "60px",
                    fontWeight: theme.typography.Bold,
                    fontSize: "14px",
                  }}
                  onClick={navigateContactUs}
                  variant="contained"
                  color="newSubmit"
                >
                  contact Crayon
                </Button>
                <Button
                  sx={{
                    width: "33.33%",
                    borderRadius: "0px",
                    height: "60px",
                    fontWeight: theme.typography.Bold,
                    fontSize: "14px",
                  }}
                  variant="contained"
                  onClick={() => {
                    handleSubmit("requestTransfer");
                  }}
                  color="tymeColor"
                >
                  request transfer
                </Button>
                <Button
                  sx={{
                    width: "33.33%",
                    borderRadius: "0px 0px 25px 0px",
                    height: "60px",
                    fontWeight: theme.typography.Bold,
                    fontSize: "14px",
                  }}
                  variant="contained"
                  onClick={() => {
                    handleSubmit("joinCompany");
                  }}
                  color="nandos"
                >
                  join company
                </Button>
              </Box>
            </>
          ) : (
            <>
              <Box sx={{ width: "100%" }}>
                <Button
                  sx={{
                    width: "50%",
                    borderRadius: "0px 0px 0px 25px",
                    height: "60px",
                    fontWeight: theme.typography.Bold,
                    fontSize: "14px",
                  }}
                  variant="contained"
                  color="newSubmit"
                  onClick={navigateContactUs}
                >
                  contact Crayon
                </Button>
                <Button
                  sx={{
                    width: "50%",
                    borderRadius: "0px 0px 25px 0px",
                    height: "60px",
                    fontWeight: theme.typography.Bold,
                    fontSize: "14px",
                  }}
                  variant="contained"
                  color="nandos"
                  onClick={() => {
                    handleSubmit("requestTransfer");
                  }}
                >
                  Claim ownership
                </Button>
              </Box>
            </>
          )}
        </Box>
      </Box>
    </Modal>
  );
};

export default ExisitingModalSelectModal;
