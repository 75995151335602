import { Box, Button, Typography } from "@mui/material";
import React from "react";
import greenTriangle from "../../../assets/Characters/Green_Triangle_Happy.svg";
import locale from "../../../i18n/locale";
import { useNavigate } from "react-router-dom";
import { getDecodedToken, getToken } from "../../../utils/Common";
import { useTheme } from "@emotion/react";

export default function NotApproved({ handleCancelRequest }) {
  const i18n = locale.en;
  const theme = useTheme()
  const naviagte = useNavigate();
  const decodedToken = getDecodedToken(getToken());
  const handleClick = () => {
    naviagte(
      decodedToken?.data?.role_id === 6
        ? "/recruiter/my-jobs"
        : "/employer/my-jobs"
    );
  };
  return (
    <>
      <Box
        sx={{
          textAlign: "center",
          display: "flex",
          flexDirection: "column",
          gap: 3,
          backgroundColor: "white",
          borderRadius: "25px",
        }}
      >
        <Box
          component={"img"}
          src={greenTriangle}
          sx={{ width: 96, height: 96, margin: "auto", mb: 0 }}
        />
        <Typography
          sx={{
            fontSize: "16px",
            fontWeight: 700,
          }}
        >
          Your request is pending for the added company
        </Typography>
      </Box>
      <Box sx={{width:"100%", marginTop:"25px" }}>
        <Button
          variant="contained"
          sx={
            {
              width:"50%",
              height: "60px",
              // mr: 2,
              fontSize:"14px",
              fontWeight:theme.typography.Bold,

              borderRadius:"0 0 0 25px"
            }
          }
          onClick={handleCancelRequest}
          color="newSubmit"
        >
          cancel request
        </Button>
        <Button
          variant="contained"
          sx={
            {
              height: "60px",
              width:"50%",
              fontSize:"14px",
              borderRadius:"0 0 25px 0",
              fontWeight:theme.typography.Bold,

            }
          }
          onClick={handleClick}
          color="redButton"
        >
          go to myJobs
        </Button>
      </Box>
    </>
  );
}
