import { Box, Tooltip, Typography, useTheme } from "@mui/material";
import React from "react";
import { LightTooltip } from "../../../../utils/CommonComponent";
import { CheckCircle } from "@mui/icons-material";
import coinIcon from "../../../../assets/Crayon Talent/Crayon Coin.svg";

export default function CrayonDirectExanded() {
  const theme = useTheme();
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "25px",
        marginBottom: "25px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: 2,
        }}
      >
        <Box
          sx={{
            display: "flex",
            position: "relative",
            gap: "10px",
          }}
        >
          <Box>
            <Box
              component={"img"}
              src={coinIcon}
              sx={{ height: "100px", width: "100px" }}
            />
            <Typography
              style={{
                position: "absolute",
                top: "28%",
                left: "28%",
                fontSize: "28px",
                fontWeight: "bold",
                color: "#FFF",
              }}
            >
              10
            </Typography>
          </Box>
          <Box>
            <Tooltip disableInteractive>
              <LightTooltip title={"Coins left"} placement="right-end">
                <Typography
                  sx={{
                    height: "17px",
                    width: "17px",
                    borderRadius: "5px",
                    fontSize: "12px",
                    lineHeight: 0.1,
                    /* text-align: center; */
                    position: "absolute",
                    top: "15px",
                    right: "-13px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    fontWeight: theme.typography.Bold,
                    boxShadow: "0px 2px 4px #00000029",
                    border: 1,
                  }}
                >
                  i
                </Typography>
              </LightTooltip>
            </Tooltip>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          margin: "auto",
          flexDirection: "column",
          gap: "15px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            gap: "78px",
          }}
        >
          <Typography
            sx={{
              width: "221px",
              fontSize: theme.typography.fontSize.sm,
              fontWeight: theme.typography.Regular,
            }}
          >
            Dashboard Management
          </Typography>
          <CheckCircle
            sx={{ width: 25, height: 25 }}
            color="DirectCompleteButton"
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            gap: "78px",
          }}
        >
          <Typography
            sx={{
              width: "221px",
              fontSize: theme.typography.fontSize.sm,
              fontWeight: theme.typography.Regular,
            }}
          >
            Auto matching %
          </Typography>
          <CheckCircle
            sx={{ width: 25, height: 25 }}
            color="DirectCompleteButton"
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            gap: "78px",
          }}
        >
          <Typography
            sx={{
              width: "221px",
              fontSize: theme.typography.fontSize.sm,
              fontWeight: theme.typography.Regular,
            }}
          >
            Drag and drop candidates
          </Typography>
          <CheckCircle
            sx={{ width: 25, height: 25 }}
            color="DirectCompleteButton"
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            gap: "78px",
          }}
        >
          <Typography
            sx={{
              width: "221px",
              fontSize: theme.typography.fontSize.sm,
              fontWeight: theme.typography.Regular,
            }}
          >
            Screening Q&A
          </Typography>
          <CheckCircle
            sx={{ width: 25, height: 25 }}
            color="DirectCompleteButton"
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            gap: "78px",
          }}
        >
          <Typography
            sx={{
              width: "221px",
              fontSize: theme.typography.fontSize.sm,
              fontWeight: theme.typography.Regular,
            }}
          >
            Application videos
          </Typography>
          <CheckCircle
            sx={{ width: 25, height: 25 }}
            color="DirectCompleteButton"
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            gap: "78px",
          }}
        >
          <Typography
            sx={{
              width: "221px",
              fontSize: theme.typography.fontSize.sm,
              fontWeight: theme.typography.Regular,
            }}
          >
            Post to multiple job boards
          </Typography>
          <CheckCircle
            sx={{ width: 25, height: 25 }}
            color="DirectCompleteButton"
          />
        </Box>
      </Box>
    </Box>
  );
}
