import React, { useEffect, useState } from "react";
import CustomDialog from "../../../common/CustomDialog";
import { Box, Button, InputBase, Modal, Paper } from "@mui/material";
import { useTheme } from "@emotion/react";
import SelectMenu from "../../../common/SelectMenu";
import { useSelector } from "react-redux";
import AutoComplete from "../../../common/AutoComplete";
import CustomSelect from "../../../candidate/myCV/CustomSelect";

export default function EditNewQualification({
  show,
  handleOpen,
  currQualification,
  currQualificationType,
  handleEditQual,
  handleEdit,
  existingQualification,
  existingQualificationType,
  handleOnChange,
  setExistingCompany,
  setcompanyName,
  isLoading,
}) {
  const theme = useTheme();
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    borderRadius: "10px",
    boxShadow: "none !important",
  };
  const { typeOfQualification, qualification } = useSelector(
    (state) => state.myCv
  );

  const [touched, setTouched] = useState({
    currQualification: false,
    currQualificationType: false,
    existingQualification: false,
    existingQualificationType: false,
  });

  const validation = () => {
    setTouched({
      currQualification: !currQualification ? true : false,
      currQualificationType: !currQualificationType ? true : false,
      existingQualification: !existingQualification ? true : false,
      existingQualificationType: !existingQualificationType ? true : false,
    });
  };

  const handleEditValidation = () => {
    if (
      !currQualification &&
      !currQualificationType &&
      !existingQualification &&
      !existingQualificationType
    ) {
      validation();
    } else {
      handleEdit();
    }
  };

  const handleOnSelectChange = async (ind, newValue, val, index) => {
    if (index === "qualification_id") {
      setExistingCompany(newValue);
      setcompanyName("");
    }
  };

  return (
    <Modal
      open={show}
      onClose={handleOpen}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Box
          sx={{
            padding: 4,
          }}
        >
          <Paper
            sx={{
              display: "flex",
              height: "40px",
              borderRadius: "10px",
              boxShadow: "none",
              border: touched.currQualification
                ? "1px solid red"
                : `1px solid ${theme.palette.grayBorder}`,
            }}
          >
            <InputBase
              sx={{ ml: 2, mr: 2, width: "100%" }}
              id="current_qualification"
              value={currQualification}
              onChange={handleEditQual}
              placeholder={"Edit current qualification title"}
              // onFocus={() => {
              //   setTouched({
              //     currQualification: false,
              //     currQualificationType: !currQualificationType ? true : false,
              //     existingQualification: false,
              //     existingQualificationType: false,
              //   });
              // }}
            />
          </Paper>
          <SelectMenu
            clear={true}
            name="required_qualification_id"
            value={currQualificationType}
            onHandleChange={handleEditQual}
            options={typeOfQualification}
            borderRadius={"10px"}
            sx={{
              width: "100%",
              mt: 2,
              border: touched.currQualificationType
                ? "1px solid red"
                : `1px solid ${theme.palette.grayBorder}`,
              boxShadow: "none !important",
            }}
            // onFocus={() => {
            //   setTouched({
            //     currQualification: !currQualification ? true : false,
            //     currQualificationType: false,
            //     existingQualification: false,
            //     existingQualificationType: false,
            //   });
            // }}
            placeholder="Enter the minimum required qualification level"
          />
        </Box>
        <div style={{ display: "flex", alignItems: "center" }}>
          <hr style={{ flexGrow: 1, marginRight: "10px" }} />
          <span>Or</span>
          <hr style={{ flexGrow: 1, marginLeft: "10px" }} />
        </div>
        <Box
          sx={{
            padding: 4,
          }}
        >
          <CustomSelect
            onMenuOpen={() => {}}
            variant="default"
            onInputChange={handleOnChange}
            onChange={handleOnSelectChange}
            placeholder="Change from existing qualification"
            inputId="qualification_id"
            name="qualification_id"
            value={existingQualification}
            isLoading={isLoading}
            customStyle={{
              menu: (provided) => ({
                ...provided,
                zIndex: 9999,
              }),
              menuList: (provided) => ({
                ...provided,
                "&::-webkit-scrollbar": {
                  width: "6px",
                  height: "6px",
                  borderRadius: "2.5px",
                  backgroundColor: "#efefef",
                },
                "&::-webkit-scrollbar-thumb": {
                  borderRadius: "2.5px",
                },
              }),
              option: (styles, { data, isDisabled, isFocused, isSelected }) => {
                return {
                  ...styles,
                  color: isSelected ? "#fff" : "black",
                  ":active": {
                    ...styles[":active"],
                  },
                };
              },
              control: (baseStyles) => ({
                ...baseStyles,
                boxShadow: "none",
                borderRadius: "10px",
                border: touched.existingQualification
                  ? "1px solid red"
                  : "1px solid #E0E0E0",
                "&:hover": touched.existingQualification
                  ? "1px solid red"
                  : "1px solid #E0E0E0",
                width: `100%`,
              }),
            }}
            option={qualification?.map((item) => ({
              value: item?.id,
              label: item?.name,
            }))}
          />
          <SelectMenu
            clear={true}
            name="existing_required_qualification_id"
            value={existingQualificationType}
            onHandleChange={handleEditQual}
            options={typeOfQualification}
            borderRadius={"10px"}
            sx={{
              width: "100%",
              mt: 2,
              border: touched.existingQualificationType
                ? "1px solid red"
                : `1px solid ${theme.palette.grayBorder}`,
              boxShadow: "none !important",
            }}
            // onFocus={() => {
            //   setTouched({
            //     currQualification: false,
            //     currQualificationType: false,
            //     existingQualification: !existingQualification ? true : false,
            //     existingQualificationType: false,
            //   });
            // }}
            placeholder="Enter the minimum required qualification level"
          />
        </Box>
        <Box>
          <Button
            sx={{
              boxShadow: 0,
              fontSize: "14px",
              width: "50%",
              borderRadius: 0,
              height: "43px",
              background: "lightgray",
              color: "black",
              padding: 3,
            }}
            variant="contained"
            onClick={handleOpen}
          >
            cancel
          </Button>
          <Button
            sx={{
              boxShadow: 0,
              fontSize: "14px",
              width: "50%",
              borderRadius: 0,
              height: "43px",
              padding: 3,
            }}
            variant="contained"
            color="redButton100"
            onClick={handleEditValidation}
          >
            edit
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}
