import { Box, Button, Card, Grid, Typography, useTheme } from "@mui/material";
import React, { useState } from "react";
import profile from "../../../assets/user_logo.svg";
import { convertDatetimeAgo } from "../../../utils/DateTime";
import job_volume from "../../../assets/Padding Excluded/Crayon Icon_Promote.svg";
import playButtonIcon from "../../../assets/Padding Excluded/Black_reactive_job copy.svg";
import playButtonIconWhite from "../../../assets/Padding Excluded/Black_reactive_job.svg";
import MUIRadialChart from "../../common/MUIRadialChart";
import favouriteGrey from "../../../assets/Crayon Favourite Icon_Grey.svg";
import favouriteRed from "../../../assets/Crayon Favourite Icon_Red.svg";
import job_exp from "../../../assets/Padding Included/Green_Duration.svg";
import BlueCurrency from "../../../assets/Blue_Salary.svg";
import redLocation from "../../../assets/Red_Location.svg";
import calendar from "../../../assets/Padding Included/Yellow_Calendar.svg";
import { formatCurrencyWithCommas } from "../../../utils/Currency";
import TextWrapper from "../../common/TextWrapper";
import { Link } from "react-router-dom";
import {
  calculateMUIHeight,
  getDecodedToken,
  getToken,
  setCookie,
} from "../../../utils/Common";
import SubscriptionPopModal from "../../guest/talent/SubscriptionPopModal";
import NewSlider from "../../common/NewSlider";
import SmallButton from "../../common/SmallButton";
import trackIcon from "../../../assets/Padding Excluded/TrackIcon.svg";
import { favouriteJob } from "../../../redux/guest/talentSlice";
import { setAlert } from "../../../redux/configSlice";
import { ALERT_TYPE } from "../../../utils/Constants";
import { useDispatch } from "react-redux";

export default function ReferralsCardFront({
  index,
  job,
  setisFlipped,
  handleReviewVideoDialog,
  cardWidth,
  indexNumber,
  jobslength,
  currentPageJobsCount,
}) {
  const theme = useTheme();
  const dispatch = useDispatch();
  let decodedToken = getDecodedToken(getToken());
  const [isSubscriptionModal, setIsSubscriptionModal] = useState(false);
  const industryNames = job?.industry?.map(
    (industry) => industry?.industry_name
  );

  const [isStar, setIsStarSelected] = useState(
    job?.favourite_talent
      ? job?.favourite_talent
      : document.cookie
          ?.match(/\d+/g)
          ?.map((item) => parseInt(item, 10))
          ?.includes(job?.user_id)
  );

  const handleFavourite = (event, talentid) => {
    setIsStarSelected(!isStar);
    setCookie("favoriteTalent", talentid, 30);
  };

  const handleStar = async () => {
    if (decodedToken?.data?.role_id === 4) {
      await dispatch(favouriteJob({ reqid: job?.user_id }));
      setIsStarSelected(!isStar);
    } else {
      dispatch(
        setAlert({
          show: true,
          type: ALERT_TYPE?.ERROR,
          msg: "Login as employer to add this talent as favourite",
        })
      );
    }
  };

  return (
    <Card
      sx={{
        borderRadius: "25px",
        position: "relative",
        boxShadow: "0px 5px 10px #00000029",
      }}
    >
      <Grid
        container
        justifyContent="space-between"
        alignItems="start"
        sx={{
          borderRadius: "25px 25px 0 0",
          flexWrap: "nowrap",
        }}
      >
        {false ? (
          <Box
            component="img"
            sx={{
              height: 60,
              width: 60,
              ml: "15px",
              mt: "15px",
              borderRadius: "10px",
            }}
            alt="profile"
            src={job?.profile_url}
          />
        ) : (
          <Box
            component="img"
            sx={{
              height: 60,
              width: 60,
              ml: "15px",
              mt: "15px",
            }}
            alt="profile"
            src={profile}
          />
        )}
        <Box>
          <Box
            sx={{
              display: "flex",
              flexWrap: "nowrap",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Button
              color={"recentButton"}
              variant="contained"
              sx={{
                height: "auto",
                minWidth: "60px",
                minHeight: "45px",
                borderRadius: "0 0 0 10px",
                padding: 0,
                fontSize: 12,
                fontWeight: theme.typography.Bold,
                boxShadow: 0,
                cursor: "default",
                color: theme.jobCard.jobType.recruit.contrastText,
                ":hover": {
                  boxShadow: 0,
                },
              }}
            >
              recent
            </Button>
            <Box
              sx={{
                display: "flex",
              }}
            >
              <Button
                variant="contained"
                color="basicColor"
                sx={{
                  height: "45px",
                  width: "60px",
                  minWidth: "60px",
                  maxHeight: { xs: "60px" },
                  maxWidth: { xs: "60px" },
                  borderRadius: 0,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  ":hover": { boxShadow: 0 },
                }}
              >
                <Box
                  component="img"
                  sx={{
                    height: 30,
                    width: 30,
                    maxHeight: { xs: 30 },
                    maxWidth: { xs: 30 },
                  }}
                  alt="job_volume"
                  src={job_volume}
                />
              </Button>
              <Button
                color="playButton"
                variant="contained"
                onClick={() =>
                  job?.cam_link === null ? "" : handleReviewVideoDialog(true)
                }
                sx={{
                  minWidth: "60px",
                  width: "60px",
                  height: 45,
                  background:
                    job?.cam_link === null && theme.palette.grayButton600.main,
                  borderRadius: "0 25px 0 0",
                  padding: 0,
                  ":hover": {
                    boxShadow: "none",
                  },
                }}
              >
                <Box
                  component={"img"}
                  src={
                    // job?.cam_link === null
                    //   ? playButtonIcon
                    //   : playButtonIconWhite
                    playButtonIconWhite
                  }
                  sx={{
                    height: "14px",
                    width: "14px",
                  }}
                />
              </Button>
            </Box>
          </Box>
          <Typography
            sx={{
              fontWeight: 400,
              fontSize: 12,
              letterSpacing: "0.75px",
              opacity: 0.8,
              paddingTop: "8px",
            }}
          >
            joined {convertDatetimeAgo(job?.created_at)} ago
          </Typography>
        </Box>
      </Grid>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "5px",
          m: "15px",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
          {decodedToken?.data?.talent_plan ? (
            <Link
              to={`/candidate-cv/${job?.user_id}`}
              target="_blank"
              style={{
                textDecoration: "none",
                color: theme.palette.black,
                height: "20px",
              }}
            >
              <Typography
                sx={{
                  fontSize: "20px",
                  fontWeight: theme.typography.Bold,
                }}
                noWrap
              >
                {job?.first_name || "-"}
              </Typography>
            </Link>
          ) : (
            <Typography
              sx={{
                fontSize: "20px",
                fontWeight: theme.typography.Bold,
              }}
              noWrap
            >
              {job?.first_name || "-"}
            </Typography>
          )}
          <Box
            component={"img"}
            src={isStar ? favouriteRed : favouriteGrey}
            sx={{
              height: "20px",
              width: "20px",
            }}
            onClick={(event) =>
              decodedToken === undefined
                ? handleFavourite(event, job?.user_id)
                : handleStar(job?.user_id)
            }
          />
        </Box>
        <Typography
          sx={{
            fontSize: "20px",
            fontWeight: theme.typography.Bold,
          }}
          noWrap
        >
          {job?.jobTitle || "-"}
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "start",
              gap: "10px",
              height: "14px",
            }}
          >
            <Box
              component="img"
              sx={{
                height: 16,
                width: 15,
                maxHeight: { xs: 16 },
                maxWidth: { xs: 15 },
              }}
              alt="currency"
              src={BlueCurrency}
            />
            <Typography
              sx={{
                fontWeight: 700,
                fontSize: 14,
                letterSpacing: "0.25px",
                lineHeight: 1,
              }}
            >
              {job?.salaryMin === 0 ? (
                <>
                  {job?.currencySymbol}
                  {formatCurrencyWithCommas(job?.salaryMax)}{" "}
                  {job?.employment_type === "freelance"
                    ? "per hour"
                    : "per month"}
                </>
              ) : job?.currencySymbol || job?.salaryMin || job?.salaryMax ? (
                <>
                  {job?.currencySymbol}
                  {formatCurrencyWithCommas(job?.salaryMin)} to{" "}
                  {job?.currencySymbol}
                  {formatCurrencyWithCommas(job?.salaryMax)}{" "}
                  {job?.employment_type === "freelance"
                    ? "per hour"
                    : "per month"}
                </>
              ) : (
                "-"
              )}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "start",
              gap: "10px",
              height: "14px",
            }}
          >
            <Box
              component="img"
              sx={{
                height: 16,
                width: 15,
                maxHeight: { xs: 16 },
                maxWidth: { xs: 15 },
              }}
              alt="location"
              src={redLocation}
            />
            <Typography
              sx={{
                fontWeight: 700,
                fontSize: 14,
                letterSpacing: "0.25px",
                lineHeight: 1,
              }}
            >
              {job?.town_name || "-"}
              {job?.country_name && `, ${job?.country_name} `}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "start",
              gap: "10px",
              height: "14px",
            }}
          >
            <Box
              component="img"
              sx={{
                height: 16,
                width: 15,
                maxHeight: { xs: 16 },
                maxWidth: { xs: 15 },

              }}
              alt="job_exp"
              src={job_exp}
            />
            <Typography
              sx={{
                fontWeight: 700,
                fontSize: 14,
                letterSpacing: "0.25px",
                lineHeight: 1,
              }}
            >
              {job?.experienceYear ? `${job?.experienceYear} years` : "-"}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "start",
              gap: "10px",
              height: "14px",
            }}
          >
            <Box
              component="img"
              sx={{
                height: 16,
                width: 14,
                maxHeight: { xs: 16 },
                maxWidth: { xs: 14 },
              }}
              alt="calendar"
              src={calendar}
            />
            <Typography
              sx={{
                fontWeight: 700,
                fontSize: 14,
                letterSpacing: "0.25px",
                lineHeight: 1,
              }}
            >
              {job?.noticePeriod_description
                ? job?.noticePeriod_description?.replace("calendar", "")
                : "-"}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          width: "calc(100% - 20px)",
        }}
      >
        <Grid
          sx={{
            flexGrow: 1,
            display: "flex",
            flexDirection: "column",
            width: "100%",
          }}
          padding={"0 0 0 15px "}
          gap={"15px"}
          minHeight="135px"
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 1,
              height: "60px",
            }}
          >
            <Box sx={{ height: "25px" }}>
              {job?.employment_type ? (
                <SmallButton
                  fontSize={12}
                  color={"jobTypeButton"}
                  height={25}
                  value={job?.employment_type}
                  label={job?.employment_type}
                  mr="4px"
                />
              ) : null}
              {job?.work_setup ? (
                <SmallButton
                  fontSize={12}
                  color={"workSetupButton"}
                  height={25}
                  value={job?.work_setup}
                  label={job?.work_setup}
                  mr="4px"
                />
              ) : null}
            </Box>

            <NewSlider
              items={industryNames?.sort((a, b) => a.length - b.length) || []}
              color={"industriesButton"}
              hideTagsAfter={3}
              height="25px"
              job={job}
              index={indexNumber}
              jobslength={jobslength}
              id={job?.user_id}
              currentPageJobsCount={currentPageJobsCount}
            />
          </Box>

          <TextWrapper
            size={12}
            color={theme.palette.black100}
            letterSpacing="0.25px"
            height="56px"
            marginBottom={"0px !important"}
          >
            {job?.my_bio || "-"}
          </TextWrapper>
        </Grid>
        <Box sx={{ display: "flex" }}></Box>
      </Box>
      <Grid
        container
        padding="5px 15px 15px 15px"
        justifyContent="space-around"
        flexWrap={"nowrap"}
      >
        <MUIRadialChart
          value={job?.TotalUserCount}
          chartName={"applications"}
          max={500}
          size={calculateMUIHeight()}
          countFontSize={16}
          labelFontSize={12}
          color={"Applied"}
        />
        <MUIRadialChart
          value={job?.totalusershorlisted}
          chartName={"shortlistings"}
          max={100}
          size={calculateMUIHeight()}
          countFontSize={16}
          labelFontSize={12}
          color={"Shortlisted"}
        />
        <MUIRadialChart
          value={job?.totaluserinterviewed}
          chartName={"interviews"}
          max={50}
          size={calculateMUIHeight()}
          countFontSize={16}
          labelFontSize={12}
          color={"Interviewed"}
        />
      </Grid>
      <Grid
        container
        flexWrap={"nowrap"}
        alignItems="center"
        overflow={"hidden"}
        sx={{
          width: "100%",
          borderRadius: "0 0 25px 25px",
          height: "60px",
        }}
      >
        <Button
          variant="contained"
          sx={{
            borderRadius: "0 0 25px 0",
            width: "100%",
            height: "100%",
            fontSize: "14px",
            fontWeight: "bold",
            ":hover": {
              boxShadow: 0,
            },
            ".MuiButton-startIcon": {
              marginRight: "0px !important",
              marginLeft: 0,
            },
            padding: "0px !important",
            boxShadow: "none !important",
            gap: 1,
          }}
          color="basicColor"
          onClick={() => setisFlipped(true)}
          startIcon={
            <Box
              component={"img"}
              sx={{
                height: 20,
                width: 20,
              }}
              src={trackIcon}
            />
          }
        >
          {"track"}
        </Button>
      </Grid>
      {
        <SubscriptionPopModal
          handleOpen={isSubscriptionModal}
          handleClose={() => setIsSubscriptionModal(false)}
          handleSubscriptionOk={() => setIsSubscriptionModal(false)}
        />
      }
    </Card>
  );
}
