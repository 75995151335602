import { Box, Typography, useTheme } from "@mui/material";
import React from "react";
import CustomDialog from "../../../common/CustomDialog";
import locale from "../../../../i18n/locale";

export default function BriefBox({ show, handleOpen, text2, text1 }) {
  const i18n = locale.en;
  const theme = useTheme();
  return (
    <CustomDialog
      show={show}
      hideButton={false}
      dialogWidth="xs"
      showFooter={false}
      onDialogClose={handleOpen}
      padding={0}
    >
      <Box
        sx={{
          flexGrow: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: "30px",
          flexDirection: "column",
          gap: "30px",
        }}
      >
        <Typography
          sx={{
            fontSize: "14px",
            textAlign: "center",
            fontWeight: theme.typography.Regular,
          }}
        >
          {text1?text1:i18n["empMyCam.briefBoxText"]}
        </Typography>
        <Typography
          sx={{
            fontSize: "14px",
            textAlign: "center",
            fontWeight: theme.typography.Regular,
          }}
        >
          {text2?text2:i18n["empMyCam.briefBoxText1"]}
        </Typography>
      </Box>
    </CustomDialog>
  );
}
