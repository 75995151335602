import { Box, CircularProgress, Grid } from "@mui/material";
import React from "react";

import TagsSlider from "./TagsSlider";

export default function CandidateFlipInfo({
  primary,
  shadow,
  gritScore,
  skills,
  traits,
  tools,
  talentInfoLoading,
}) {
  const jobTags = skills?.map((tag) => tag?.lable);
  const traitsTags = traits?.map((trait) => trait?.label);
  const toolTags = tools?.map((tool) => tool?.lable);
  return talentInfoLoading ? (
    <Box
      style={{
        margin: "15px 0",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "80%",
      }}
    >
      <CircularProgress color="inherit" size={20} />
    </Box>
  ) : (
    <Grid
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-end",
        // marginLeft: "4px",
        marginBottom: "8px",
        gap: 1,
        width: "192px",
        marginTop: "6px",
      }}
    >
      {jobTags?.length>0 && <TagsSlider
        items={jobTags ? jobTags : []}
        color={"yoco"}
        hideTagsAfter={4}
      /> }
      {toolTags?.length>0 &&  <TagsSlider
        items={toolTags ? toolTags : []}
        color={"filterButtons"}
        hideTagsAfter={4}
      /> }
     {traitsTags?.length>0 && <TagsSlider
        items={traitsTags ? traitsTags : []}
        color={"skillsButton"}
        hideTagsAfter={4}
      /> }
    </Grid>
  );
}
