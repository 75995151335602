import Box from "@mui/material/Box";
import Grid from "@mui/material/Unstable_Grid2";
import MyJobsCard from "./MyJobsCardNew";
import ButtonPanel from "../../common/ButtonPanel";
import InfiniteScroll from "react-infinite-scroll-component";
import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { getCandidateFilteredJob } from "../../../redux/candidate/candidateJobs";
import { getJobStatus } from "../../../redux/status";
import { useSelector } from "react-redux";
import { getMyStatusFilter } from "../../../redux/candidate/myStatusFilter";
import { Button, Paper } from "@mui/material";
import LeftArrow from "../../common/LeftArrow";
import RightArrow from "../../common/RightArrow";
import FiltersPanel from "./FiltersPanel";
import RightFiltersPanel from "./RightFiltersPanel";
import NoJobsBox from "./NoJobsBox";
import { ThemeProvider } from "@emotion/react";
import { candidateMyJobsTheme } from "../../../utils/componentThemes/candidateMyJobsTheme";
import jwt_decode from "jwt-decode";
import { ALL_STAGES, BASIC_SEARCH, JOB_STAGES } from "../../../utils/Constants";
import useViewport from "../../common/ViewPort";
import CommonLoader from "../../common/CommonLoader";
import Loader from "../../common/Loader";
import { setLoading } from "../../../redux/configSlice";
import {
  setCardsPadding,
  setFilterExpanded,
} from "../../../redux/guest/jobsSlice";

export default function Talent({ handleCandidateDynamicTheme }) {
  const dispatch = useDispatch();
  const token = localStorage?.getItem("token");
  let decodedToken;
  if (token) {
    decodedToken = jwt_decode(token);
  }
  const { width } = useViewport();
  const isLoading = useSelector((state) => state.config.loading);
  const filterExpanded = useSelector(
    (state) => state?.configjobslice?.filterExpanded
  );
  const jobStatus = useSelector((state) => state?.configjobstatus?.status);
  const myStatus = useSelector(
    (state) => state?.configMyStatus?.mystatusfilter
  );
  const [candidateJobs, setCandidateJobs] = useState([]);
  const [myStatusFilter, setMyStatusFilter] = useState([myStatus?.[0]?.id]);
  const [allStageFilter, setAllStageFilter] = useState([ALL_STAGES?.[0]?.id]);
  const [jobStageFilter, setAllJobStageFilter] = useState([
    JOB_STAGES?.[0]?.id,
  ]);
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [jobStatusFilter, setJobStatusFilter] = useState([jobStatus?.[0]?.id]);
  const [rightExpand, setRightExpand] = useState(true);
  const [leftExpanded, setLeftExpand] = useState(true);
  const [basicData, setBasicData] = useState(BASIC_SEARCH);
  const [showBox, setShowBox] = useState(false);
  const [searchBox, setSearchBox] = useState(false);
  const [lastKey, setLastKey] = useState("");
  const [rightBoxY, setRightBoxY] = useState((window.innerWidth * 380) / 1920);
  const statusChanged = useRef();

  const [itemsFetched, setItemsFetched] = useState(false);
  useEffect(() => {
    if (width > 1024) {
      setLeftExpand(true);
      setRightExpand(true);
    } else {
      setLeftExpand(false);
      setRightExpand(false);
    }
  }, [width]);
  const getStatus = async () => {
    await dispatch(getJobStatus());
  };

  const calculatedHeight = () => {
    const screenWidth = window.innerWidth;

    if (screenWidth < 600) {
      // xs
      return `calc(100vh - 95px)`;
    } else if (screenWidth < 900) {
      // sm
      return `calc(100vh - 80px)`;
    } else if (screenWidth < 1366) {
      // md
      return `calc(100vh - 80px)`;
    } else if (screenWidth < 1920) {
      // lg
      return `calc(100vh - 80px)`;
    } else if (screenWidth < 2559) {
      // xl
      return `calc(100vh - 100px)`;
    } else {
      // xxl or greater
      return `calc(100vh - 120px)`;
    }
  };

  const getmyStatus = async () => {
    await dispatch(getMyStatusFilter());
  };
  // responsive functions
  const gridRef = useRef(); // Create a ref to the grid card element
  const [newCardWidth, setNewCardWidth] = useState(340);
  const [newPadding, setNewPadding] = useState(15);
  const [currentPageJobsCount, setCurrentPageJobsCount] = useState(0);

  const selectedMyStages = useRef([]);
  const selectedJobStages = useRef([]);

  function calculateCardWidthAndPadding(totalWidth) {
    let cardWidth = 340;
    let padding = width < 390 ? 20 : 15;
    // let padding = 15;
    let numCards = Math.floor(totalWidth / (cardWidth + padding));
    let remainingSpace =
      totalWidth - (numCards * cardWidth + padding * (numCards + 1));

    while (remainingSpace > 0) {
      if (remainingSpace < cardWidth + padding) {
        if (padding < 30) {
          padding++;
          remainingSpace =
            totalWidth - (numCards * cardWidth + padding * (numCards + 1));
        } else {
          break;
        }
      } else {
        numCards++;
      }
    }

    cardWidth = (totalWidth - padding * (numCards + 1)) / numCards;
    if (cardWidth > 380) {
      cardWidth = 380;
    }
    remainingSpace =
      totalWidth - (numCards * cardWidth + padding * (numCards + 1));

    padding = padding + remainingSpace / (numCards + 1);

    remainingSpace =
      totalWidth - (numCards * cardWidth + padding * (numCards + 1));

    setNewCardWidth(cardWidth - 1);
    setNewPadding(padding);
    dispatch(setCardsPadding(padding));
    return { numCards, cardWidth, padding, remainingSpace };
  }
  const getNewWidth = () => {
    if (gridRef?.current) {
      calculateCardWidthAndPadding(gridRef?.current?.clientWidth);
    }
    setRightBoxY((window.innerWidth * 380) / 1920);
  };
  useLayoutEffect(() => {
    if (gridRef?.current) {
      calculateCardWidthAndPadding(gridRef?.current?.clientWidth);
    }
    window.addEventListener("resize", getNewWidth);
    return () => {
      window.removeEventListener("resize", getNewWidth);
    };
  }, [itemsFetched, leftExpanded, rightExpand]);

  // responsive functions

  const getJobs = async (
    filterMyStatus = myStatusFilter,
    filterJobStatus = jobStatusFilter,
    filterMyStage = [],
    filterJobStage = [],
    lastkey = lastKey
  ) => {
    dispatch(setLoading({ value: true, apiName: "getJobs" }));

    //  setIsDataLoading(true);
    // dispatch(setLoading(true))
    const data = {
      filterMyStatus:
        filterMyStatus?.[0] !== 1111 ? filterMyStatus?.toString() : "",
      filterJobStatus:
        filterJobStatus !== 1111 ? filterJobStatus?.toString() : "",
      filterMyStage:
        filterMyStage?.[0] !== 1111 ? filterMyStage?.toString() : "",
      filterJobStage:
        filterJobStage?.[0] !== 1111 ? filterJobStage?.toString() : "",
      lastKey: statusChanged?.current ? "" : lastkey,
      ...basicData,
    };
    const { payload } = await dispatch(getCandidateFilteredJob(data));

    if (payload?.status === "success") {
      if (payload?.data?.length <= 0 && payload?.pageNumber === 0) {
        setShowBox(true);
        setCurrentPageJobsCount(payload?.data?.length || 0);
      } else {
        setCurrentPageJobsCount(payload?.data?.length || 0);
        setShowBox(false);
        setLastKey(payload?.pageNumber + 1);
        statusChanged?.current
          ? setCandidateJobs(payload?.data)
          : setCandidateJobs((prevState) => [...prevState, ...payload?.data]);
        setItemsFetched(true);
        statusChanged.current = false;
      }
      // setIsDataLoading(false);
      // dispatch(setLoading(false))
      dispatch(setLoading({ value: false, apiName: "getJobs" }));
    }
  };
  useEffect(() => {
    getJobs();
    getStatus();
    getmyStatus();
    // getProgressData();
    handleCandidateDynamicTheme(decodedToken?.data?.personality);
  }, []);

  const leftStatusFilter = (selectedFilter) => {
    setCandidateJobs([]);
    setMyStatusFilter(selectedFilter);
    getJobs(
      selectedFilter,
      jobStatusFilter,
      allStageFilter,
      jobStageFilter,
      ""
    );
  };

  const onChangeFilterMyStage = (selectedFilter) => {
    let stage = [];
    selectedFilter?.map((type) => {
      let selectedJobType = ALL_STAGES?.find(
        (stagetype) => stagetype?.id === type
      );
      stage?.push(selectedJobType?.name);
    });
    selectedMyStages.current = selectedFilter;
    setCandidateJobs([]);
    setLastKey("");
    setAllStageFilter(selectedFilter);
    getJobs(
      myStatusFilter,
      jobStatusFilter,
      selectedFilter,
      jobStageFilter,
      ""
    );
  };

  const onChangeFilterJobStage = (selectedFilter) => {
    let stage = [];
    selectedFilter?.map((type) => {
      let selectedJobType = JOB_STAGES?.find(
        (stagetype) => stagetype?.id === type
      );
      stage?.push(selectedJobType?.name);
    });
    selectedJobStages.current = selectedFilter;
    setCandidateJobs([]);
    setLastKey("");
    setAllJobStageFilter(selectedFilter);
    getJobs(
      myStatusFilter,
      jobStatusFilter,
      allStageFilter,
      selectedFilter,
      ""
    );
  };

  const rightStatusFilter = (selectedFilter) => {
    setCandidateJobs([]);
    setJobStatusFilter(selectedFilter);
    getJobs(myStatusFilter, selectedFilter, allStageFilter, jobStageFilter, "");
  };
  if (width === 0) {
    return null;
  }
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <Box
        item
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: `${width >= 768 && "10px"}`,
          position: `${width < 768 ? "absolute" : "relative"}`,
          // width: leftExpanded
          //   ? { xs: "150px", mdl: "123px", mlg: "150px", xl: "150px" }
          //   : "30px",
          width: leftExpanded ? "125px" : "30px",
          minWidth: leftExpanded ? "125px" : "30px",
          // width: leftExpanded && "150px",
          zIndex: "30",
        }}
      >
        <Button
          variant="contained"
          sx={{
            boxShadow: "0px 5px 10px #00000029 !important",
            position: "static",
            borderRadius: "0 10px 10px 0",
            minWidth: "30px",
            width: "30px",
            height: "45px",
          }}
          color="filterButtons"
          onClick={() => {
            setLeftExpand((prevState) => !prevState);
            setRightExpand((prevState) => !prevState);
            dispatch(setFilterExpanded(!filterExpanded));
          }}
        >
          {leftExpanded ? <LeftArrow /> : <RightArrow />}
        </Button>
        {leftExpanded && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              // height: {
              //   xs: `calc(100vh - 95px)`,
              //   sm: `calc(100vh - 120px )`,
              //   md: `calc(100vh - 120px)`,
              //   lg: `calc(100vh - 135px)`,
              //   xl: `calc(100vh - 155px)`,
              //   xxl: "calc(100vh - 175px)",
              // },
              width: `${width < 768 && "135px"}`,
              paddingTop: `${width < 768 && "10px"}`,
              background: `${width > 0 && width < 768 && "#EBECF3"}`,
              boxShadow: `${
                width > 0 && width < 768 && "0px 5px 10px #00000029"
              }`,
              opacity: `${width < 768 && "1"}`,
              borderRadius: `${width < 768 && "10px"}`,
              paddingBottom: `${width < 768 && "10px"}`,
              overflowX: "hidden",
              overflowY: leftExpanded ? "scroll" : "unset",
            }}
            className="filterSec"
          >
            <Paper
              sx={{
                background: "transparent",
                gap: "10px",
                display: "flex",
                flexDirection: "column",
                boxShadow: 0,
                // maxWidth: `${width < 768 && "150px"}`,
                maxWidth: "125px",
              }}
            >
              <FiltersPanel
                panelData={myStatus}
                side="left"
                onChangeFilter={leftStatusFilter}
              />

              <ButtonPanel
                selected={selectedMyStages?.current}
                panelData={ALL_STAGES}
                side="left"
                color="filterButtons"
                onChangeFilter={onChangeFilterMyStage}
              />
            </Paper>
            <style>
              {`.filterSec::-webkit-scrollbar {
                      width: 0px !important;
                      background-color: #EFEEEE; /* Set the background color of the scrollbar */
                    }
                    .filterSec::-webkit-scrollbar-thumb {
                      background-color: white;
                      width: 0px;
                      box-shadow: 0px 3px 3px #00000029;
                      border-radius: 0px;
                    }`}
            </style>
          </Box>
        )}
      </Box>
      {/* {!isLoading &&  */}
      <Box
        item
        sx={{
          display: "flex",
          flexGrow: 1,
          alignItems: "center",
          flexDirection: "column",
          width: `calc(100vw - 255px)`,
          position: "sticky",
        }}
        ref={gridRef}
        className="grigBox"
      >
        <InfiniteScroll
          key={`${jobStatusFilter}, ${myStatusFilter}, ${allStageFilter}, ${jobStageFilter}`}
          scrollThreshold={"100px"}
          height={calculatedHeight()}
          dataLength={candidateJobs?.length}
          next={() =>
            getJobs(
              myStatusFilter,
              jobStatusFilter,
              allStageFilter,
              jobStageFilter,
              lastKey
            )
          }
          hasMore={true}
          style={{
            overflowX: "hidden",
          }}
          endMessage={
            <p style={{ textAlign: "center" }}>
              <b>Yay! You have seen it all</b>
            </p>
          }
        >
          <Grid
            container
            gap={width <= 768 ? "10px" : `${newPadding}px`}
            columns={{
              xs: 1,
              md: 1,
              lg: 3,
              mlg: 3.5,
              xl: 4,
              xxl: 6,
              xxxl: 8,
            }}
            justifyContent={width <= 768 && "center"}
            sx={{
              position: "relative",
              padding: width <= 768 ? "10px 10px" : `0 ${newPadding}px`,
              top: searchBox
                ? width <= 768
                  ? "45px"
                  : "55px"
                : width <= 768
                ? "45px"
                : "55px",
            }}
          >
            {candidateJobs?.length > 0
              ? candidateJobs?.map((talent, index) => (
                  <Box
                    sx={{
                      width: newCardWidth,
                      height: "560px",
                    }}
                  >
                    <ThemeProvider theme={candidateMyJobsTheme}>
                      <MyJobsCard
                        cardWidth={newCardWidth}
                        index={index}
                        job={talent}
                        getJobs={getJobs}
                        statusChanged={statusChanged}
                        getmyStatus={getmyStatus}
                        jobslength={candidateJobs?.length}
                        currentPageJobsCount={currentPageJobsCount}
                        setCandidateJobs={setCandidateJobs}
                      />
                    </ThemeProvider>
                  </Box>
                ))
              : showBox && (
                  <Box
                    sx={{
                      margin: "auto",
                    }}
                  >
                    <NoJobsBox />
                  </Box>
                )}
          </Grid>
          <style>
            {`.infinite-scroll-component::-webkit-scrollbar {
                   display:none
                 }
                 .infinite-scroll-component__outerdiv {
                   width: 100%
                 }`}
          </style>
        </InfiniteScroll>
      </Box>
      {/* // } */}
      <Box
        item
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: `${width >= 768 && "10px"}`,
          direction: "rtl",
          position: `${width < 768 ? "absolute" : "relative"}`,
          zIndex: "30",
          // width: rightExpand && "150px",
          right: "0",
          // width: leftExpanded
          //   ? { xs: "150px", mdl: "123px", mlg: "150px", xl: "150px" }
          //   : "30px",
          width: rightExpand ? "125px" : "30px",
          minWidth: rightExpand ? "125px" : "30px",
        }}
      >
        <Button
          variant="contained"
          sx={{
            position: "sticky",
            top: 0,
            borderRadius: "10px 0 0 10px",
            minWidth: "30px",
            width: "30px",
            height: "45px",
          }}
          color="filterButtons"
          onClick={() => {
            setLeftExpand((prevState) => !prevState);
            setRightExpand((prevState) => !prevState);
            dispatch(setFilterExpanded(!filterExpanded));
          }}
        >
          {rightExpand ? <RightArrow /> : <LeftArrow />}
        </Button>
        {rightExpand && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: `${width < 768 && "135px"}`,
              minWidth: "125px",
              paddingTop: `${width < 768 && "10px"}`,
              paddingBottom: `${width < 768 && "10px"}`,
              background: `${width > 0 && width < 768 && "#EBECF3"}`,
              boxShadow: `${
                width > 0 && width < 768 && "0px 5px 10px #00000029"
              }`,
              opacity: `${width < 768 && "1"}`,
              borderRadius: `${width < 768 && "10px"}`,
              // height: {
              //   xs: `calc(100vh - 95px)`,
              //   sm: `calc(100vh - 120px )`,
              //   md: `calc(100vh - 120px)`,
              //   lg: `calc(100vh - 135px)`,
              //   xl: `calc(100vh - 155px)`,
              //   xxl: "calc(100vh - 175px )",
              // },
              overflowY: rightExpand ? "scroll" : "unset",
            }}
            className="rightfilterSec"
          >
            <style>
              {`.rightfilterSec::-webkit-scrollbar {
                       width: 0px !important;
                       background-color: #EFEEEE; /* Set the background color of the scrollbar */
                    }
                    .rightfilterSec::-webkit-scrollbar-thumb {
                      background-color: white;
                      width: 0px;
                      box-shadow: 0px 3px 3px #00000029;
                      border-radius: 3px;
                    }`}
            </style>
            <Paper
              sx={{
                background: "transparent",
                boxShadow: 0,
                gap: "10px",
                display: "flex",
                flexDirection: "column",
                // maxWidth: `${width < 768 && "150px"}`,
                maxWidth: "125px",
              }}
            >
              <RightFiltersPanel
                panelData={jobStatus}
                side="right"
                onChangeFilter={rightStatusFilter}
              />
              <ButtonPanel
                selected={selectedJobStages?.current}
                panelData={JOB_STAGES}
                side="right"
                color="filterButtons"
                onChangeFilter={onChangeFilterJobStage}
              />
            </Paper>
          </Box>
        )}
      </Box>
    </Box>
  );
}
