import { Box, Button, Grid, IconButton, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTheme } from "@emotion/react";
import close from "../../assets/Padding Included/Black_Close.svg";
import facebookLogo from "../../assets/socialmedialogo/facebooklogo.svg";
import twitterLogo from "../../assets/socialmedialogo/twitter.png";
import linkedinLogo from "../../assets/socialmedialogo/linkedin.svg";
import { useDispatch } from "react-redux";
import locale from "../../i18n/locale";
import TalentSVGButton from "../common/TalentSVGButton";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from "react-share";
import { convertToPromoter, setReferal } from "../../redux/guest/talentSlice";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import CopyConfirmationModal from "./CopyConfirmationModal";
import SharingJob from "./SharingJob";
import { getDecodedToken, getToken } from "../../utils/Common";
import viewIcon from "../../assets/Padding Excluded/Crayon Icon_Promote.svg";
import emailIcon from "../../assets/Padding Excluded/White_Email.svg";
import Template from "./Template";
import { formatCurrencyWithCommas } from "../../utils/Currency";
import job_volume from "../../assets/Padding Excluded/Crayon Icon_Promote.svg";
import CloseIcon from "../common/CloseIcon";

export default function ShareInfo({
  setJobPromote,
  setShareInfo,
  jobID,
  shareUrl,
  setShowEmailBox,
  setShowLogin,
  job,
  shareInfo,
  setSrc,
  getUrl,
  setShareUrl,
  setShowShareInfo,
  isShowCard,
}) {
  let template;
  const decodedToken = getDecodedToken(getToken());
  const dispatch = useDispatch();
  const [showCopyModal, setShowCopyModal] = useState(false);
  const [openSharingBox, setOpenSharingBox] = useState(false);
  let name = "Crayon";
  let descrption = "This is a Job hositing portal";
  const i18n = locale.en;
  const theme = useTheme();
  // const[shareUrl, setShareUrl] = useState(`https://f3fe-2405-201-6802-a843-cde8-fcfa-9037-960d.ngrok-free.app?meta_url=https://example.com&meta_title=${name}&meta_description=${descrption}&meta_image=https://dfstudio-d420.kxcdn.com/wordpress/wp-content/uploads/2019/06/digital_camera_photo-980x653.jpg&user=976&name=sandy&userId=${jobID}`)
  const handleUrl = () => {
    return `https://f3fe-2405-201-6802-a843-cde8-fcfa-9037-960d.ngrok-free.app?meta_url=https://example.com&meta_title=${name}&meta_description=${descrption}&meta_image=https://dfstudio-d420.kxcdn.com/wordpress/wp-content/uploads/2019/06/digital_camera_photo-980x653.jpg&user=976&name=sandy&userId=${jobID}`;
  };

  const setCount = async (type) => {
    let payload = {
      job_id: jobID,
      // "platform_type":"facebook"
      platform_type: type,
    };
    try {
      let response = await dispatch(setReferal(payload));
      if (response?.payload?.status === "success") {
        // await dispatch(
        //   convertToPromoter({ candidate_id: decodedToken?.data?.user_id })
        // );
        localStorage.setItem("token", response?.payload?.data?.[0]?.token);
        setOpenSharingBox(true);
        setJobPromote(false);
      }
    } catch (error) {
      // console.log(error,"error")
    }
  };
  const handleCopyLink = () => {
    setShowCopyModal(true);
  };

  const handleSubscriptionCancel = () => {
    setShowCopyModal(false);
  };

  useEffect(() => {
    job?.job_image && getUrl(jobID);
  }, []);

  return (
    <>
      <Box
        sx={{ display: "flex", flexDirection: "column", marginBottom: "135px" }}
      >
        <Box sx={{ marginBottom: "15px" }}>
          <Button
            variant="contained"
            color="referredButton"
            sx={{
              borderRadius: "25px 0px 10px 0px",
              color: "white",
              fontWeight: 700,
              cursor: "default",
              ":hover": {
                boxShadow: "none !important",
              },
              width: "150px",
              height: "45px",
              padding: "0px",
            }}
            startIcon={
              <Box
                component="img"
                sx={{
                  height: 30,
                  width: 30,
                  maxHeight: { xs: 30 },
                  maxWidth: { xs: 30 },
                }}
                alt="job_volume"
                src={job_volume}
              />
            }
          >
            {i18n["promoter.topCornorText"]}
          </Button>
          <Box
            onClick={() => {
              setShareInfo(false);
              setShowShareInfo(false);
              setJobPromote(false);
            }}
          >
            <CloseIcon top="15px" right="15px" />
          </Box>
        </Box>

        <Box
          sx={{
            padding: "0px 16px 0 19px",
            marginBottom: "29px",
          }}
        >
          <Typography
            sx={{
              fontSize: theme.typography.fontSize.sm,
              fontWeight: theme?.typography?.Bold,
              lineHeight: "19px",
              marginBottom: "10px",
            }}
          >
            {i18n["promoter.headingText2"]}
          </Typography>

          <Typography
            sx={{
              fontSize: theme.typography.fontSize.xs,
              color: "black",
              fontWeight: theme?.typography?.Regular,
              textAlign: "left",
              lineHeight: "16px",
            }}
          >
            {i18n["promoter.shareToInfo"]}
          </Typography>
        </Box>
        <>
          <Box sx={{ display: "flex" }}>
          <Box
            sx={{ display: "flex" }}
            onClick={() => {
              if (!isShowCard) {
                setCount("facebook");
              }
            }}
          >
            <FacebookShareButton
              url={shareUrl?.shared_url}
              quote="Testing React-share"
              disabled={isShowCard}
            >
              <Box display={"flex"} alignItems={"center"}>
                <Box width={"77px"}>
                  <TalentSVGButton
                    color={"white"}
                    source={facebookLogo}
                    outerHeight={43}
                    height={43}
                    width={77}
                    padding={"0px !important"}
                    startIconMargin={"0px !important"}
                  />
                </Box>
                <Typography
                  sx={{ fontSize: "12px", fontWeight: theme.typography.Medium }}
                >
                  Facebook
                </Typography>
              </Box>
            </FacebookShareButton>
          </Box>
        </Box>
        <Box sx={{ display: "flex", marginTop: "11px" }}>
          <Box
            sx={{ display: "flex" }}
            onClick={() => {
              if (!isShowCard) {
                setCount("linkedIn");
              }
            }}
          >
            <LinkedinShareButton
              url={shareUrl?.shared_url}
              disabled={isShowCard}
            >
              <Box display={"flex"} alignItems={"center"}>
                <Box width={"77px"}>
                  <TalentSVGButton
                    color={"white"}
                    source={linkedinLogo}
                    outerHeight={43}
                    height={43}
                    width={77}
                    padding={"0px !important"}
                    startIconMargin={"0px !important"}
                  />
                </Box>

                <Typography
                  sx={{ fontSize: "12px", fontWeight: theme.typography.Medium }}
                >
                  Linkedin
                </Typography>
              </Box>
            </LinkedinShareButton>
          </Box>
        </Box>
        <Box sx={{ display: "flex", marginTop: "11px" }}>
          <Box
            sx={{ display: "flex" }}
            onClick={() => {
              if (!isShowCard) {
                setCount("twitter");
              }
            }}
          >
            <TwitterShareButton
              url={shareUrl?.shared_url}
              disabled={isShowCard}
            >
              <Box display={"flex"} alignItems={"center"}>
                <Box width={"77px"}>
                  <TalentSVGButton
                    color={"white"}
                    source={twitterLogo}
                    outerHeight={43}
                    height={43}
                    width={77}
                    padding={"0px !important"}
                    startIconMargin={"0px !important"}
                  />
                </Box>
                <Box>
                  <Typography
                    sx={{
                      fontSize: "12px",
                      fontWeight: theme.typography.Medium,
                    }}
                  >
                    X(Twitter)
                  </Typography>
                </Box>
              </Box>
            </TwitterShareButton>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            cursor: "pointer",
            marginTop: "11px",
          }}
          onClick={() => handleCopyLink()}
        >
          <Box
            width={"77px"}
            height={"40px"}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <ContentCopyIcon fontSize="large" />
          </Box>
          <Typography
            sx={{ fontSize: "12px", fontWeight: theme.typography.Medium }}
          >
            Copy Link
          </Typography>
        </Box>
          </>
      </Box>
      {/*<Box
        sx={{
          display: "flex",
          gap: 2,
          flexDirection: "column",
          margin: "auto",
          width: "100%",
          marginBottom: "38px",
          alignItems: "center",
        }}
      >
        <Typography
          sx={{
            fontWeight: theme?.typography?.Bold,
            fontSize: "14px",
            textAlign: "center",
          }}
          width={"284px"}
        >
          {i18n["promoter.text3"]}
        </Typography>
        </Box>*/}
      <Grid
        container
        alignItems="center"
        overflow={"hidden"}
        sx={{
          width: "100%",
          borderRadius: "0 0 25px 25px",
          height: "60px",
        }}
      >
        <Button
          variant="contained"
          sx={{
            borderRadius: 0,
            fontSize: "14px",
            fontWeight: "bold",
            whiteSpace: "nowrap",
            height: "100%",
            width: "50%",
            justifyContent: "center",
            ".MuiButton-startIcon": {
              // marginRight: "0px !important",
              // marginLeft: 0,
              justifyContent: "end",
              width: "26px",
            },
            padding: "0px !important",
            boxShadow: "none !important",
          }}
          onClick={() => {
            setShareInfo(false);
            setShowShareInfo(false);
          }}
          color="grayButton600"
        >
          {i18n["promoter.back"]}
        </Button>

        <Button
          variant="contained"
          sx={{
            borderRadius: "0 0 25px 0",
            width: "50%",
            height: "100%",
            fontSize: "14px",
            fontWeight: "bold",
            ":hover": {
              boxShadow: 0,
            },
            ".MuiButton-startIcon": {
              marginRight: "0px !important",
              marginLeft: 0,
            },
            padding: "0px !important",
            boxShadow: "none !important",
            gap: 1,
          }}
          color="applyButton"
          onClick={() => {
            // applyFunction(jobID);
            if (decodedToken === undefined) {
              setShowLogin(true);
            } else {
              setShowEmailBox(true);
            }
          }}
          startIcon={
            <Box
              component={"img"}
              sx={{
                height: 20,
                width: 20,
              }}
              src={emailIcon}
            />
          }
        >
          {i18n["promoter.referafriend"]}
        </Button>
      </Grid>
      {showCopyModal && (
        <CopyConfirmationModal
          handleOpen={showCopyModal}
          handleClose={() => {
            setShowCopyModal(false);
          }}
          handleSubscriptionOk={() => {
            setShowCopyModal(false);
          }}
          modalHeading={"Copy Confirmation"}
          handleSubscriptionCancel={handleSubscriptionCancel}
          modalContent={
            "Thanks,Link has been copied. Feel free to share with your mates."
          }
          shareUrl={shareUrl}
          setCount={() => {
            if (!isShowCard) {
              setCount("copy_link");
            }
          }}
        />
      )}
      {/*openSharingBox && !decodedToken?.data?.promoter && (
        <SharingJob
          handleClose={() => setOpenSharingBox(false)}
          openEdit={openSharingBox}
        />
      )*/}
      {/*shareInfo && (
        <Template
          jobID={job?.job_id}
          title={job?.title}
          location={`${job?.town_name} ${job?.country_name}`}
          company={job?.employer_profile_company_name}
          salary={`${job?.currency_symbol}${formatCurrencyWithCommas(
            job?.salary_min
          )} to ${job?.currency_symbol}${formatCurrencyWithCommas(
            job?.salary_max
          )} ${job?.type === "freelance" ? "per hour" : "per month"}`}
          id={`${template}`}
          reference={""}
          onClick={""}
          setsrc={setSrc}
          job={job}
          getUrl={getUrl}
          setShareUrl={setShareUrl}
        />
      )*/}
    </>
  );
}
