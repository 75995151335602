import { useCallback, useRef, useState } from "react";
import { Box, Button, IconButton, InputLabel, Typography } from "@mui/material";
import locale from "../../../../i18n/locale";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import InputBox from "../../../common/InputBox";
import { useDispatch } from "react-redux";
import { getTitles } from "../../../../redux/employer/postJobSlice";
import {
  getCandidateCompany,
  getCompanies,
} from "../../../../redux/employer/empProfileSlice";
import { useTheme } from "@emotion/react";
import PhoneInput from "react-phone-number-input";
import { numberToWords } from "../../../../utils/DateTime";
import { CheckCircle } from "@mui/icons-material";
import { isValidPhoneNumber } from "react-phone-number-input";

import CancelIcon from "@mui/icons-material/Cancel";
import { debounce } from "../../../../utils/Common";
import CustomSelect from "../../../candidate/myCV/CustomSelect";

export default function EditReferences({
  talentDetails,
  setTalentDetails,
  errors,
  setErrors,
  companies,
  titles,
  getAllData,
  initialCountryCode,
}) {
  const theme = useTheme();
  const i18n = locale.en;
  const countryCode = useRef();
  const dispatch = useDispatch();
  const REFERENCE = {
    reference_id: null,
    name: "",
    company_name: null,
    title: null,
    contact: "",
    email: "",
    country_code: "",
  };

  const [inValidContacts, setInvalidContacts] = useState([
    {
      referenceID: null,
      contactTouched: false,
      contactValid: false,
    },
  ]);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const validateNumber = (index, phoneNumber) => {
    const updatedInvalidContactsData = [...inValidContacts];
    updatedInvalidContactsData[index] = {
      ...updatedInvalidContactsData[index],
      contactTouched: true,
      contactValid: isValidPhoneNumber(phoneNumber || ""),
    };
    setInvalidContacts(updatedInvalidContactsData);
  };

  const handleInputValue = (event, index, fieldName) => {
    const updatedReferenceData = talentDetails?.reference;
    updatedReferenceData[index] = {
      ...updatedReferenceData[index],
      [event.target.id]:
        event.target.id === "contact"
          ? event.target.value
          : event.target.value || "",
    };
    setTalentDetails({ ...talentDetails, reference: updatedReferenceData });
    let obj = { ...errors };
    if (event?.target?.value) {
      delete obj[`[${index}].${fieldName}`];
    }
    // setErrors({ ...obj });
    setErrors((prev) =>
      prev?.filter(
        (item) => item?.key !== `referencedata[${index}].${fieldName}`
      )
    );
  };

  const handleContactNumber = (name, value, index) => {
    const updatedReferenceData = talentDetails?.reference;
    updatedReferenceData[index] = {
      ...updatedReferenceData[index],
      [name]: value,
      country_code: countryCode?.current,
    };
    setTalentDetails({ ...talentDetails, reference: updatedReferenceData });
    let obj = { ...errors };
    if (value) {
      delete obj[`[${index}].${name}`];
    }
    // setErrors({ ...obj });
    setErrors((prev) =>
      prev?.filter((item) => item?.key !== `referencedata[${index}].contact`)
    );
  };

  const onMenuOpen = () => {
    if (titles.length === 0) {
      getAllData("titles");
    }
    setIsMenuOpen(true);
  };

  const onMenuClose = () => {
    setIsMenuOpen(false);
  };

  const debouncedHandleOnSelectInputChange = useCallback(
    debounce(
      async (newValue, type) => {
        let params = {
          title: newValue || "",
          limit: 200,
        };
        if (newValue) {
          try {
            if (type === "title") {
              dispatch(getTitles(params));
            }
            if (type === "company_name") {
              dispatch(getCandidateCompany(params));
            }
          } catch (error) {
            console.log(error, "error");
          }
        }
      },
      750,
      setIsLoading
    ),
    []
  );

  const handleOnSelectInputChange = (newValue, inputId) => {
    debouncedHandleOnSelectInputChange(newValue, inputId);
  };
  const onCreateOption = (data, inputId, index) => {
    const updatedReferenceData = talentDetails?.reference;
    if (data && inputId === "company_name") {
      updatedReferenceData[index] = {
        ...updatedReferenceData[index],
        [inputId]: data,
        company_id: data,
      };
    }
    if (data && inputId === "title") {
      updatedReferenceData[index] = {
        ...updatedReferenceData[index],
        [inputId]: data,
        title_id: data,
      };
    }
    setTalentDetails({ ...talentDetails, reference: updatedReferenceData });
    let obj = { ...errors };
    if (data) {
      delete obj[`[${index}].${inputId}`];
      if (inputId === "company_name") {
        delete obj[`referencedata[${index}].${inputId}`];
        setErrors((prev) =>
          prev?.filter(
            (item) => item?.key !== `referencedata[${index}].${inputId}`
          )
        );
      } else {
        delete obj[`referencedata[${index}].${inputId}`];
        setErrors((prev) =>
          prev?.filter(
            (item) => item?.key !== `referencedata[${index}].${inputId}`
          )
        );
      }
    }
    // setErrors({ ...obj });
  };

  const handleOnSelectChange = (ind, newValue, val, inputId) => {
    const updatedReferenceData = talentDetails?.reference;
    if (inputId === "company_name") {
      updatedReferenceData[ind] = {
        ...updatedReferenceData[ind],
        [inputId]: newValue?.label,
        company_id: newValue?.value,
      };
    }
    if (inputId === "title") {
      updatedReferenceData[ind] = {
        ...updatedReferenceData[ind],
        [inputId]: newValue?.label,
        title_id: newValue?.value,
      };
    }
    setTalentDetails({ ...talentDetails, reference: updatedReferenceData });
    let obj = { ...errors };
    if (newValue?.label || newValue?.value) {
      delete obj[`[${ind}].${inputId}`];
      if (inputId === "company_name") {
        delete obj[`referencedata[${ind}].${inputId}`];
        setErrors((prev) =>
          prev?.filter(
            (item) => item?.key !== `referencedata[${ind}].${inputId}`
          )
        );
      } else {
        delete obj[`referencedata[${ind}].${inputId}`];
        setErrors((prev) =>
          prev?.filter(
            (item) => item?.key !== `referencedata[${ind}].${inputId}`
          )
        );
      }
    }

    // setErrors({ ...obj });
  };

  const removeReference = (event, index) => {
    if (talentDetails?.reference?.length > 1) {
      const newWorkData = talentDetails?.reference?.filter(
        (data, i) => i !== index
      );
      setTalentDetails({ ...talentDetails, reference: newWorkData });
    }
  };

  const addReference = () => {
    setTalentDetails((prevState) => ({
      ...prevState,
      reference: [...(prevState?.reference || []), REFERENCE],
    }));

    setInvalidContacts((prevState) => [
      {
        referenceID: null,
        contactTouched: false,
        contactValid: false,
      },
      ...prevState,
    ]);
  };

  return (
    <>
      <Box
        sx={{
          p: 4,
          pt: 0,
          display: "flex",
          flexDirection: "column",
          gap: "16px",
        }}
      >
        <Typography sx={{ fontWeight: theme.typography.Bold }}>
          {i18n["myProfile.Reference"]}
        </Typography>

        {talentDetails?.reference?.length >= 0 &&
          talentDetails?.reference?.map((work, index) => (
            <Box key={index}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  mb: 1,
                }}
              >
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  {index != 0 && (
                    <IconButton
                      aria-label="edit"
                      color="redButton"
                      sx={{
                        padding: "0 !important",
                        marginRight: "4px",
                      }}
                      onClick={(event) => removeReference(event, index)}
                    >
                      <RemoveCircleIcon />
                    </IconButton>
                  )}
                  <Typography
                    sx={{
                      fontWeight: theme.typography.Bold,
                      flex: 1,
                    }}
                  >
                    {numberToWords(talentDetails?.reference?.length - index) +
                      ` Reference`}
                  </Typography>
                </Box>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  mb: 3,
                }}
              >
                <Box sx={{ width: "50%" }}>
                  <InputLabel
                    htmlFor="company_name"
                    sx={{
                      color: "black",
                      paddingBottom: "2px",
                      fontSize: "14px",
                      fontWeight: theme.typography.Bold,
                    }}
                  >
                    {i18n["myCV.referencePersonNameAndSurnameReq"]}
                  </InputLabel>

                  <InputBox
                    sx={{
                      width: "94%",
                      // border:
                      //   // !work?.name &&
                      //   // errors?.find((error) => error.key === "name")
                      //   errors[`reference[${index}].${["name"]}`]
                      //     ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
                      //     : "1px solid #E0E0E0",
                      border: errors?.find(
                        (item) => item?.key === `referencedata[${index}].name`
                      )
                        ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
                        : "1px solid #E0E0E0",
                      borderRadius: "10px",
                      height: "40px",
                    }}
                    value={work?.name}
                    id="name"
                    placeholder={"Enter the name of your reference"}
                    onChange={(event) => handleInputValue(event, index, "name")}
                  />
                </Box>

                <Box
                  sx={{
                    width: "50%",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-end",
                  }}
                >
                  <InputLabel
                    htmlFor="company_name"
                    sx={{
                      color: "black",
                      paddingBottom: "2px",
                      fontSize: "14px",
                      fontWeight: theme.typography.Bold,
                      width: "94%",
                    }}
                  >
                    {i18n["myCV.companyNameLabelReq"]}
                  </InputLabel>
                  <Box sx={{ width: "94%" }}>
                    <CustomSelect
                      index={index}
                      variant="createSelect"
                      onInputChange={handleOnSelectInputChange}
                      onChange={handleOnSelectChange}
                      onCreateOption={onCreateOption}
                      placeholder={"Select or add the relevant company"}
                      inputId="company_name"
                      name="company_name"
                      value={
                        work?.company_name || work?.company_id
                          ? {
                              label: work?.company_name,
                              value: work?.company_id,
                            }
                          : null
                      }
                      onMenuOpen={() => {
                        if (companies?.length === 0) {
                          getAllData("companies");
                        }
                        setIsMenuOpen(true);
                      }}
                      onMenuClose={onMenuClose}
                      option={companies?.map((item) => ({
                        value: item?.candidate_company_id,
                        label: item?.name,
                      }))}
                      isLoading={isLoading}
                      customStyle={{
                        option: (
                          styles,
                          { data, isDisabled, isFocused, isSelected }
                        ) => {
                          return {
                            ...styles,
                            color: isSelected ? "#fff" : "black",
                            ":active": {
                              ...styles[":active"],
                            },
                          };
                        },
                        menu: (provided) => ({
                          ...provided,
                          zIndex: 9999,
                        }),
                        menuList: (provided) => ({
                          ...provided,
                          "&::-webkit-scrollbar": {
                            width: "6px",
                            height: "6px",
                            borderRadius: "2.5px",
                            backgroundColor: "#efefef",
                          },
                          "&::-webkit-scrollbar-thumb": {
                            borderRadius: "2.5px",
                          },
                        }),
                        control: (baseStyles) => ({
                          ...baseStyles,
                          boxShadow: "none",
                          borderRadius: "10px",
                          height: `40px`,
                          width: `100%`,

                          border: errors?.find(
                            (item) =>
                              item?.key ===
                              `referencedata[${index}].company_name`
                          )
                            ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
                            : "1px solid #E0E0E0",
                          "&:hover": errors?.find(
                            (item) =>
                              item?.key ===
                              `referencedata[${index}].company_name`
                          )
                            ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
                            : "1px solid #E0E0E0",
                        }),
                      }}
                    />
                  </Box>
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  mb: 3,
                }}
              >
                <Box sx={{ width: "50%" }}>
                  <InputLabel
                    htmlFor="title"
                    sx={{
                      color: "black",
                      paddingBottom: "2px",
                      fontSize: "14px",
                      fontWeight: theme.typography.Bold,
                    }}
                  >
                    {i18n["myCV.jobTitleLableReq"]}
                  </InputLabel>
                  <Box sx={{ width: "95%" }}>
                    <CustomSelect
                      index={index}
                      variant="createSelect"
                      onInputChange={handleOnSelectInputChange}
                      onChange={handleOnSelectChange}
                      onCreateOption={onCreateOption}
                      placeholder={i18n["myCV.currentJobTitle"]}
                      inputId="title"
                      name="title"
                      value={
                        work?.title_id || work?.title
                          ? {
                              value: work?.title_id,
                              label: work?.title,
                            }
                          : null
                      }
                      onMenuOpen={onMenuOpen}
                      onMenuClose={onMenuClose}
                      option={titles?.map((item) => ({
                        value: item?.job_title_id,
                        label: item?.title,
                      }))}
                      isLoading={isLoading}
                      customStyle={{
                        option: (
                          styles,
                          { data, isDisabled, isFocused, isSelected }
                        ) => {
                          return {
                            ...styles,
                            color: isSelected ? "#fff" : "black",
                            ":active": {
                              ...styles[":active"],
                            },
                          };
                        },
                        menu: (provided) => ({
                          ...provided,
                          zIndex: 9999,
                        }),
                        menuList: (provided) => ({
                          ...provided,
                          "&::-webkit-scrollbar": {
                            width: "6px",
                            height: "6px",
                            borderRadius: "2.5px",
                            backgroundColor: "#efefef",
                          },
                          "&::-webkit-scrollbar-thumb": {
                            borderRadius: "2.5px",
                          },
                        }),
                        control: (baseStyles) => ({
                          ...baseStyles,
                          boxShadow: "none",
                          borderRadius: "10px",
                          height: `40px`,

                          border: errors?.find(
                            (item) =>
                              item?.key === `referencedata[${index}].title`
                          )
                            ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
                            : "1px solid #E0E0E0",
                          "&:hover": errors?.find(
                            (item) =>
                              item?.key === `referencedata[${index}].title`
                          )
                            ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
                            : "1px solid #E0E0E0",
                        }),
                      }}
                    />
                  </Box>
                </Box>
                <Box
                  sx={{
                    width: "47%",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-end",
                  }}
                >
                  <InputLabel
                    htmlFor="contact_number"
                    sx={{
                      color: "black",
                      paddingBottom: "2px",
                      fontSize: "14px",
                      fontWeight: theme.typography.Bold,
                      width: "100%",
                    }}
                  >
                    {i18n["myCV.contactNumber"]}
                  </InputLabel>
                  <Box
                    sx={{
                      background: "transparent",
                      width: "100%",
                      display: "flex",
                      justifyContent: "space-between",
                      height: "38px",
                      borderRadius: "10px",
                      border: errors?.find(
                        (item) =>
                          item?.key === `referencedata[${index}].contact`
                      )
                        ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
                        : "1px solid #E0E0E0",
                    }}
                  >
                    <PhoneInput
                      international
                      id="contact"
                      placeholder="Enter phone number"
                      countryCallingCodeEditable={false}
                      value={
                        work?.contact?.startsWith("+")
                          ? work?.contact
                          : `${work?.country_code}${work?.contact}` || ""
                      }
                      onChange={(value) => {
                        handleContactNumber("contact", value, index);
                      }}
                      defaultCountry={initialCountryCode.toUpperCase()}
                      focusInputOnCountrySelection={true}
                      onCountryChange={(value) => {
                        countryCode.current = value;
                      }}
                      style={{
                        font: "inherit",
                        padding: "10px",
                      }}
                      onBlur={() => validateNumber(index, work?.contact)}
                    />
                    {isValidPhoneNumber(work?.contact || "") && (
                      <Typography
                        sx={{
                          color: theme.palette.tealColor.main,
                          fontSize: "14px",
                          alignItems: "center",
                          display: "flex",
                          gap: "5px",
                          marginRight: "5px",
                          justifyContent: "end",
                        }}
                      >
                        Correct <CheckCircle fontSize="small" />
                      </Typography>
                    )}
                    {inValidContacts?.find(
                      (item) => item?.referenceID === work?.reference_id
                    )?.contactTouched
                      ? !isValidPhoneNumber(work?.contact || "") && (
                          <Typography
                            sx={{
                              color: "red",
                              fontSize: "14px",
                              alignItems: "center",
                              display: "flex",
                              gap: "5px",
                              marginRight: "5px",
                              justifyContent: "end",
                            }}
                            noWrap
                          >
                            Invalid Contact <CancelIcon fontSize="small" />
                          </Typography>
                        )
                      : null}
                  </Box>
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  mb: 3,
                }}
              >
                <Box sx={{ width: "50%" }}>
                  <InputLabel
                    htmlFor="contact_number"
                    sx={{
                      color: "black",
                      paddingBottom: "2px",
                      fontSize: "14px",
                      fontWeight: theme.typography.Bold,
                    }}
                  >
                    {i18n["myProfile.emailLabelRequired"]}
                  </InputLabel>
                  <InputBox
                    sx={{
                      border: errors?.find(
                        (item) => item?.key === `referencedata[${index}].email`
                      )
                        ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
                        : "1px solid #E0E0E0",
                      borderRadius: "10px",
                      height: "40px",
                      width: "95%",
                    }}
                    value={work?.email}
                    id="email"
                    type="email"
                    placeholder={"Enter their email address"}
                    onChange={(event) =>
                      handleInputValue(event, index, "email")
                    }
                  />
                </Box>
                <Box sx={{ width: "50%" }}></Box>
              </Box>
            </Box>
          ))}
      </Box>
      <Button
        variant="contained"
        color="greenSwitch"
        onClick={addReference}
        sx={{
          borderRadius: "0px 10px 10px 0px",
          width: "230px",
          height: "41px",
          marginBottom: "32px",
        }}
      >
        {"+ add a reference"}
      </Button>
    </>
  );
}
