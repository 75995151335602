import { useTheme } from "@mui/material/styles";

import { Modal } from "@mui/material";
import React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Bot from "../../../assets/EndModalBot.svg";
const EndModal = ({
  handleOpen,
  handleClose,
  handleSubmit,
  role_id,
  handleTakeMeHome,
}) => {
  const theme = useTheme();

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    borderRadius: "25px",
    // p: 4,
    boxShadow: "none !important",
    // border: "1px solid black !important",
    "&:focus-visible": {
      outline: "none",
    },
  };

  return (
    <Modal
      open={handleOpen}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Box
          sx={{
            padding: "25px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Box component="img" src={Bot} sx={{ width: 83, height: 125 }}></Box>
          <Box
            sx={{
              fontSize: "20px",
              marginBottom: "25px",
              fontWeight: "700",
              textAlign: "center",
            }}
          >
            Error: No personality found. <br />
            (just kidding)
          </Box>
          <Box
            sx={{
              fontSize: "14x",
              fontWeight: "700",
              marginBottom: "30px",
              textAlign: "center",
            }}
          >
            CrayBot is processing the results.
            <br /> We’ll update your profile in a bit.
          </Box>
          <Box
            sx={{
              fontSize: "14x",
              fontWeight: "700",
              marginBottom: "30px",
              textAlign: "center",
            }}
          >
            {role_id === 3
              ? "If you haven’t done so already, the final step towards creating a complete profile is your Crayon Cam intro video!"
              : "You’ll also receive an email with a summary of the results. You can check out the footer below for more info on the different personality aspects."}
          </Box>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          {role_id === 3 ? (
            <>
              <Button
                variant="contained"
                color="grayButton100"
                sx={{
                  borderRadius: 0,
                  width: "50%",
                  minHeight: "60px",
                maxHeight:"60px",
                fontWeight:theme.typography.Bold,
                fontSize:"14px",
                  borderBottomLeftRadius: 25,
                }}
                onClick={handleClose}
              >
                not now, thanks
              </Button>
              <Button
                variant="contained"
                color="applyButton"
                sx={{
                  borderRadius: 0,
                  width: "50%",
                  minHeight: "60px",
                maxHeight:"60px",
                fontWeight:theme.typography.Bold,
                fontSize:"14px",
                  borderBottomRightRadius: 25,
                }}
                onClick={handleSubmit}
              >
                shoot my shot
              </Button>
            </>
          ) : (
            <Button
              variant="contained"
              color="applyButton"
              sx={{
                width: "100%",
                height: "60px",
                minHeight:"14px",
                maxHeight:"60px",
                fontWeight:theme.typography.Bold,
                borderRadius: "0 0 25px 25px",
              }}
              onClick={handleTakeMeHome}
            >
              take me home
            </Button>
          )}
        </Box>
      </Box>
    </Modal>
  );
};

export default EndModal;
