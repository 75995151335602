import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getApi, postApi } from "../../utils/Apis";
import { setLoading } from "../configSlice";
const initialState = {
  myTeamsAdminList: [],
};

export const verifyUsers = createAsyncThunk(
  "verifyUsers",
  async (payload, { dispatch }) => {
    dispatch(setLoading(true));
    const { data } = await postApi("/admin/verifyuser", payload, true);
    dispatch(setLoading(false));
    return data;
  }
);

export const createAdmin = createAsyncThunk(
  "createAdmin",
  async (payload, { dispatch }) => {
    const { data } = await postApi("/admin/addCrayonAdmin", payload, true);
    return data;
  }
);

export const uploadCrayonTeamLogo = createAsyncThunk(
  "uploadCrayonTeamLogo",
  async (payload, { dispatch }) => {
    dispatch(setLoading(true));
    const { data } = await postApi(
      "/upload/profilepic",
      payload,
      true,
      "multipart/form-data"
    ); //"multipart/form-data"
    dispatch(setLoading(false));
    return data;
  }
);

export const editAdmin = createAsyncThunk(
  "editAdmin",
  async (payload, { dispatch }) => {
    const { data } = await postApi("/admin/updateCrayonAdmin", payload, true);
    return data;
  }
);

export const adminAssignJob = createAsyncThunk(
  "adminAssignJob",
  async (payload, { dispatch }) => {
    const { data } = await postApi("/admin/adminassignjob", payload, true);
    return data;
  }
);

export const getAdmin = createAsyncThunk(
  "getAdminList",
  async ({ role_type_id = "", lastKey = 0, title = "" }, { dispatch }) => {
    const { data } = await getApi(
      `/admin/getCrayonAdmin?roleType=${role_type_id}&lastKey=${lastKey}&title=${title}`
    );
    return data;
  }
);
export const deleteAdmin = createAsyncThunk(
  "getAdminList",
  async (payload, { dispatch }) => {
    const { data } = await getApi(`/admin/deleteCrayonAdmin?userid=${payload}`);
    return data;
  }
);
export const changePassword = createAsyncThunk(
  "changePassword",
  async (payload, { dispatch }) => {
    const { data } = await postApi("/reset/password", payload, true);
    return data;
  }
);
export const getRoleList = createAsyncThunk(
  "getRoleList",
  async (payload, { dispatch }) => {
    const { data } = await getApi(`/admin/getCrayonRoles`);
    return data;
  }
);

export const userAdmin = createSlice({
  name: "userAdmin",
  initialState,
  reducer: {
    getAdminList: (state, action) => {
      state.myTeamsAdminList = action?.payload;
    },
  },
});
export const { getAdminList } = userAdmin.actions;
export default userAdmin.reducer;
