import { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import locale from "../../../i18n/locale";
import {
  STUDY_LIFE_ASSOCIATION,
  STUDY_LIFE_QUALIFICATION,
  STUDY_LIFE_SCHOOL,
} from "../../../utils/Constants";
import _, { cloneDeep } from "lodash";
import IconButton from "@mui/material/IconButton";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import { Button, InputBase, InputLabel, Paper } from "@mui/material";
import {
  getSchool,
  getInstitute,
  getQualification,
  getTypeQualificationValue,
  getAssociation,
  addStudyData,
  uploadCv,
  setCVName,
  uploadQualificationDocument,
} from "../../../redux/candidate/myCvSlice";
import { ALERT_TYPE, ERROR_MSG } from "../../../utils/Constants";
import { setAlert, setLoading } from "../../../redux/configSlice";
import { getCVStudyLife } from "../../../redux/candidate/myCVNew";
import SelectMenu from "../../common/SelectMenu";
import nextIcon from "../../../assets/Black_Right_Next.svg";
import previousIcon from "../../../assets/Black_Left_Previous - Copy.svg";
import { useTheme } from "@emotion/react";
import SelectMenuYear from "../../common/SelectMenuYear";
import { yearConverter } from "../../../utils/DateTime";
import CustomSelect from "./CustomSelect";
import { validateSchema } from "../../../utils/Common";
import { ToggleSwitch } from "../../../utils/CommonComponent";
import * as Yup from "yup";
import uploadCVIcon from "../../../assets/MyCV/UploadCv.svg";
import scrapeLinkedinIcon from "../../../assets/MyCV/GrabLinkedinIcon.svg";
import InputBox from "../../common/InputBox";
import view from "../../../assets/Padding Excluded/Black_view._job.svg";
import { Link } from "react-router-dom";

export default function StudyLifeNew({
  changeStep,
  handleComplete,
  step,
  initialStudyData,
  setInitialStudyData,
  studyData,
  setStudyData,
}) {
  const i18n = locale.en;
  let maxOffset = 63;
  let thisYear = new Date().getFullYear();
  let allYears = [];
  for (let x = 0; x <= maxOffset; x++) {
    allYears.push({ id: x + 1, name: thisYear - x });
  }
  const dispatch = useDispatch();
  const theme = useTheme();
  const hiddenFileInput = useRef(null);
  const hiddenDocumentInput = useRef([]);
  // const fileAccept = "application/pdf, application/doc, application/docx";
  const fileAccept = "application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document";
  const [errors, setErrors] = useState([]);
  const [noStudyExp, setNoStudyExp] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [inputBoxWidth, setInputBoxWidth] = useState(
    (window.innerWidth * 672) / 1920
  );
  const [selectFiledWidth, setSelectFieldWidth] = useState(
    (window.innerWidth * 395) / 1920
  );
  const [boxGap, setBoxGap] = useState((window.innerWidth * 120) / 1920);
  const {
    school,
    institution,
    qualification,
    association,
    typeOfQualification,
    cVName,
  } = useSelector((state) => state.myCv);

  const removeStudy = (event, index) => {
    if (studyData.qualificationdata?.length > 1) {
      const newStudy = studyData?.qualificationdata?.filter(
        (data, i) => i + 1 !== index
      );
      const newStudyData = { ...studyData, qualificationdata: newStudy };
      setStudyData(newStudyData);
    }
  };
  const qualificationSchema = Yup.object().shape({
    institution_id: Yup.string().required("Institution is required"),
    year_ended: Yup.string().required("Year of completion is required"),
    qualification_id: Yup.string().required("Qualification is required"),
    qualificationtype_id: Yup.string().required(
      "Type of qualification is required"
    ),
  });

  const schoolSchema = Yup.object().shape({
    school_id: Yup.string().required("School name is required"),
  });
  const studyLifeSchema = Yup.object().shape({
    qualificationdata: Yup.array().when("study_life_flag", {
      is: false,
      then: (schema) => schema.of(qualificationSchema),
      otherwise: (schema) => schema.optional(),
    }),
    schooldata: Yup.array().when("study_life_flag", {
      is: false,
      then: (schema) => schema.of(schoolSchema),
      otherwise: (schema) => schema.optional(),
    }),
    study_life_flag: Yup.bool(),
  });
  const handleSaveButton = async (step) => {
    let data = {
      qualificationdata: studyData?.qualificationdata?.map((item) => {
        const newItem = {
          ...item,
          institution_id: item?.institution_id?.value,
          qualification_id: item?.qualification_id?.value,
          qualification_document: item?.qualification_document
            ? item?.qualification_document
            : "",
        };
        if (item?.qualification_name) {
          delete newItem?.qualification_name;
        }
        if (item?.institution_name) {
          delete newItem?.institution_name;
        }
        return newItem;
      }),
      schooldata: studyData?.schooldata?.map((item) => {
        const newItem = {
          ...item,
          year_completion:
            item?.year_completion === "" ? null : String(item?.year_completion),
          school_id: item?.school_id?.value ?? item?.school_id,
        };

        return newItem;
      }),
      associationdata: studyData?.associationdata
        ?.filter((i) => i?.association_id !== "" || undefined)
        ?.map((item) => {
          const newItem = {
            ...item,
            year_completion: item?.year_completion
              ? String(item?.year_completion)
              : null,
            association_id: item?.association_id?.value || item?.association_id,
          };
          return newItem;
        }),
      study_life_flag: noStudyExp,
    };
    validateSchema(studyLifeSchema, {
      schooldata: data?.schooldata,
      qualificationdata: data?.qualificationdata,
      study_life_flag: data?.study_life_flag,
    })
      .then(() => {
        dispatch(
          addStudyData(
            noStudyExp
              ? {
                  qualificationdata: [],
                  schooldata: [],
                  associationdata: [],
                  study_life_flag: noStudyExp,
                }
              : {
                  qualificationdata: studyData?.qualificationdata?.map(
                    (item) => {
                      const newItem = {
                        ...item,
                        institution_id: item?.institution_id?.value,
                        qualification_id: item?.qualification_id?.value,
                        qualification_document: item?.qualification_document
                          ? item?.qualification_document
                          : "",
                      };
                      if (item?.qualification_name) {
                        delete newItem?.qualification_name;
                      }
                      if (item.institution_name) {
                        delete newItem?.institution_name;
                      }
                      return newItem;
                    }
                  ),
                  schooldata: studyData?.schooldata
                    ?.filter((i) => i?.school_id !== "" || undefined)
                    ?.map((item) => {
                      const newItem = {
                        ...item,
                        year_completion:
                          item?.year_completion === "" ||
                          item?.year_completion === null
                            ? null
                            : String(item?.year_completion),
                        school_id: item?.school_id?.value ?? item?.school_id,
                      };

                      return newItem;
                    }),
                  associationdata: studyData?.associationdata
                    ?.filter((i) => i?.association_id !== "" || undefined)
                    ?.map((item) => {
                      const newItem = {
                        ...item,
                        year_completion: item?.year_completion
                          ? String(item?.year_completion)
                          : null,
                        association_id:
                          item?.association_id?.value || item?.association_id,
                      };

                      return newItem;
                    }),
                  study_life_flag: noStudyExp,
                }
          )
        ).then((payload) => {
          if (payload?.payload?.status === "success") {
            dispatch(
              setAlert({
                show: true,
                type: ALERT_TYPE?.SUCCESS,
                msg: "Basic data added successfully!",
              })
            );
            setErrors([]);
            changeStep(step, "component");
            handleComplete(2);
          } else if (payload?.payload?.status === "error") {
            dispatch(
              setAlert({
                show: true,
                type: ALERT_TYPE?.ERROR,
                msg: "Something went wrong",
              })
            );
          } else {
            dispatch(
              setAlert({
                show: true,
                type: ALERT_TYPE?.ERROR,
                msg: payload?.payload?.message,
              })
            );
          }
        });
      })
      .catch((error) => {
        setErrors(error);
      });
  };

  const getAllData = async (para) => {
    try {
      switch (para) {
        case "school":
          await dispatch(
            getSchool({ setState: setIsLoading, type: "school_id" })
          );
          return;
        case "institution":
          await dispatch(
            getInstitute({ setState: setIsLoading, type: "institution_id" })
          );
          return;
        case "qualification":
          await dispatch(
            getQualification({
              setState: setIsLoading,
              type: "qualification_id",
            })
          );
          return;
        case "association":
          await dispatch(
            getAssociation({ setState: setIsLoading, type: "association_id" })
          );
          return;
        case "typeQualification":
          await dispatch(getTypeQualificationValue());
          return;
        default:
          return;
      }
    } catch (error) {
      dispatch(setLoading(false));
      dispatch(
        setAlert({
          show: true,
          type: ALERT_TYPE?.ERROR,
          msg: ERROR_MSG,
        })
      );
    }
  };

  const getCVStydyLifeData = async () => {
    try {
      const { payload } = await dispatch(getCVStudyLife());
      const processQualificationData = (qualificationdata) => {
        return qualificationdata?.length > 0
          ? qualificationdata.map((item) => ({
              ...item,
              year_ended: yearConverter(item?.year_ended),
              institution_id: {
                label: item?.institution_name,
                value: item?.institution_id,
              },
              qualification_id: {
                label: item?.qualification_name,
                value: item?.qualification_id,
              },
            }))
          : [STUDY_LIFE_QUALIFICATION];
      };
      const processSchoolData = (schooldata) => {
        return schooldata?.length > 0
          ? schooldata?.map((item) => ({
              ...item,
              school_id: {
                value: item?.school_id,
                label: item?.school_name,
              },
            }))
          : [STUDY_LIFE_SCHOOL];
      };

      const processAssociationData = (associationdata) => {
        return associationdata?.length > 0
          ? associationdata?.map((item) => ({
              ...item,
              association_id: {
                value: item?.association_id,
                label: item?.association_name,
              },
            }))
          : [STUDY_LIFE_ASSOCIATION];
      };

      if (payload?.status === "success") {
        await dispatch(getTypeQualificationValue());
        setNoStudyExp(
          payload?.study_experience ? payload?.study_experience : false
        );

        const updatedQualificationData = processQualificationData(
          payload?.qualificationdata
        );
        const updatedSchoolData = processSchoolData(payload?.schooldata);
        const updatedAssociationData = processAssociationData(
          payload?.associationdata
        );

        setStudyData((prevStudyData) => ({
          qualificationdata: updatedQualificationData,
          schooldata: updatedSchoolData,
          associationdata: updatedAssociationData,
        }));
        setInitialStudyData((prevStudyData) => ({
          qualificationdata: updatedQualificationData,
          schooldata: updatedSchoolData,
          associationdata: updatedAssociationData,
        }));
      } else if (payload?.status === "error") {
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE.ERROR,
            msg: "Fill the study life details",
          })
        );
      } else {
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE.ERROR,
            msg: payload?.data,
          })
        );
      }
    } catch (error) {
      dispatch(setLoading(false));
      dispatch(
        setAlert({
          show: true,
          type: ALERT_TYPE.ERROR,
          msg: ERROR_MSG,
        })
      );
    }
  };

  const handleQualificationType = (event, index) => {
    const {
      target: { value },
      target: { name },
    } = event;

    let newCultureData = cloneDeep(studyData);
    newCultureData.qualificationdata[index] = {
      ...newCultureData?.qualificationdata?.[index],
      [name]: value,
    };
    setStudyData(newCultureData);
    let obj = { ...errors };
    if (event?.target?.value) {
      delete obj[`qualificationdata[${index}].qualificationtype_id`];
    }
    setErrors({ ...obj });
  };

  const getYearEndedValue = (idx) => {
    if (studyData?.qualificationdata?.[idx]?.year_ended === "") {
      return "";
    } else {
      const convertedYear = yearConverter(
        studyData?.qualificationdata?.[idx]?.year_ended
      );
      const year = allYears?.find((item) => item.name == convertedYear)?.name;
      return year;
    }
  };

  const getSchoolYear = (idx) => {
    if (studyData?.schooldata?.[idx]?.year_completion === "") {
      return "";
    } else {
      const year = allYears?.find(
        (item) => item?.name == studyData?.schooldata?.[idx]?.year_completion
      )?.name;
      return year;
    }
  };

  const getAssociationYear = (idx) => {
    if (studyData?.associationdata?.[idx]?.year_completion === "") {
      return "";
    } else {
      const year = allYears?.find(
        (item) =>
          item?.name == studyData?.associationdata?.[idx]?.year_completion
      )?.name;
      return year;
    }
  };

  const handleSchoolYearChange = (event, index) => {
    const newStudyData = JSON.parse(JSON.stringify(studyData));
    newStudyData.schooldata[index].year_completion =
      allYears?.find((item) => item?.id === event?.target?.value)?.name || "";
    setStudyData(newStudyData);
  };

  const handleAssociationYearChange = (event, index) => {
    const newStudyData = JSON.parse(JSON.stringify(studyData));
    newStudyData.associationdata[index].year_completion =
      allYears?.find((item) => item?.id === event?.target?.value)?.name || "";
    setStudyData(newStudyData);
  };
  const handleSelectChange = (event, index) => {
    const newStudyData = JSON.parse(JSON.stringify(studyData));
    newStudyData.qualificationdata[index].year_ended =
      allYears
        ?.find((item) => item?.id === event?.target?.value)
        ?.name?.toString() || "";

    setStudyData(newStudyData);
    let obj = { ...errors };
    if (event?.target?.value) {
      delete obj[`qualificationdata[${index}].year_ended`];
    }
    setErrors({ ...obj });
  };
  const handleSelectChange2 = (event, id, name, index) => {
    const newStudyData = JSON.parse(JSON.stringify(studyData));
    if (newStudyData?.qualificationdata?.[index]) {
      newStudyData.qualificationdata[index]["completed"] =
        event?.target?.checked?.toString();
      setStudyData(newStudyData);
    }
  };
  const handleSelectChange3 = (event, id, name, index) => {
    const newStudyData = JSON.parse(JSON.stringify(studyData));
    if (newStudyData?.schooldata?.[index]) {
      newStudyData.schooldata[index]["still_completing_flag"] =
        event?.target?.checked;
      setStudyData(newStudyData);
    }
  };
  const handleSelectChange4 = (event, id, name, index) => {
    const newStudyData = JSON.parse(JSON.stringify(studyData));
    if (newStudyData?.associationdata?.[index]) {
      newStudyData.associationdata[index]["still_completing_flag"] =
        event?.target?.checked;
      setStudyData(newStudyData);
    }
  };

  const addStudy = () => {
    const newStudyData = {
      ...studyData,
      qualificationdata: [
        ...studyData?.qualificationdata,
        STUDY_LIFE_QUALIFICATION,
      ],
    };
    setStudyData(newStudyData);
  };

  const addSchool = () => {
    const newStudyData = {
      ...studyData,
      schooldata: [...studyData?.schooldata, STUDY_LIFE_SCHOOL],
    };
    setStudyData(newStudyData);
  };

  const removeSchool = (event, index) => {
    if (studyData.schooldata.length > 1) {
      const newStudy = studyData?.schooldata?.filter(
        (data, i) => i + 1 !== index
      );
      const newStudyData = { ...studyData, schooldata: newStudy };
      setStudyData(newStudyData);
    }
  };

  const addAssociation = () => {
    const newStudyData = {
      ...studyData,
      associationdata: [...studyData?.associationdata, STUDY_LIFE_ASSOCIATION],
    };
    setStudyData(newStudyData);
  };

  const removeAssociation = (event, index) => {
    if (studyData?.associationdata?.length > 1) {
      const newStudy = studyData?.associationdata?.filter(
        (data, i) => i + 1 !== index
      );
      const newStudyData = { ...studyData, associationdata: newStudy };
      setStudyData(newStudyData);
    }
  };

  const handleNoStudy = (event) => {
    const isChecked = event?.target?.checked;
    setErrors([]);
    setNoStudyExp(isChecked);
  };

  const getNewWidth = () => {
    setInputBoxWidth((window.innerWidth * 672) / 1920);
    setSelectFieldWidth((window.innerWidth * 395) / 1920);
    setBoxGap((window.innerWidth * 120) / 1920);
  };

  const onMenuOpen = () => {
    if (institution?.length === 0) {
      getAllData("institution");
    }
    setIsMenuOpen(true);
  };
  const onMenuClose = () => {
    setIsMenuOpen(false);
  };
  const updateDebounceFunc = async (newValue, type) => {
    let params = {
      title: newValue || "",
      limit: 200,
    };
    if (newValue) {
      try {
        if (type === "institution_id") {
          dispatch(getInstitute({ ...params, setState: setIsLoading, type }));
        }
        if (type === "qualification_id") {
          dispatch(
            getQualification({ ...params, setState: setIsLoading, type })
          );
        }
        if (type === "school_id") {
          dispatch(getSchool({ ...params, setState: setIsLoading, type }));
        }
        if (type === "association_id") {
          dispatch(getAssociation({ ...params, setState: setIsLoading, type }));
        }
      } catch (error) {
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE?.ERROR,
            msg: "Something went wrong",
          })
        );
      }
    }
  };
  const debounceFunction = _.debounce(updateDebounceFunc, 750);

  const handleOnSelectInputChange = (newValue, inputId) => {
    debounceFunction(newValue, inputId);
  };

  const handleOnSelectChange = (ind, newValue, val, index) => {
    const updatedWorkData = JSON.parse(JSON.stringify(studyData));
    if (index === "institution_id") {
      updatedWorkData.qualificationdata[ind].institution_id = newValue;
    }
    if (index === "qualification_id") {
      updatedWorkData.qualificationdata[ind].qualification_id = newValue;
    }
    if (index === "association_id") {
      updatedWorkData.associationdata[ind].association_id = newValue;
    }
    if (index === "school_id") {
      updatedWorkData.schooldata[ind].school_id = newValue;
    }

    setStudyData(updatedWorkData);
    let obj = { ...errors };
    if (newValue?.label || newValue?.value) {
      if (index === "school_id") {
        delete obj[`schooldata[${ind}].${index}`];
      } else {
        delete obj[`qualificationdata[${ind}].${index}`];
      }
    }
    setErrors({ ...obj });
  };

  const handleDocument = (index) => {
    if (studyData?.qualificationdata?.[index]?.qualification_id) {
      hiddenDocumentInput?.current?.[index]?.click();
    } else {
      dispatch(
        setAlert({
          show: true,
          type: ALERT_TYPE.ERROR,
          msg: "First select qualification",
        })
      );
    }
  };

  const handleQualDocument = async (event, index) => {
    const formData = new FormData();
    formData.append("qualificationdocument", event?.target?.files?.[0]);
    formData.append(
      "qualification_id",
      studyData?.qualificationdata?.[index]?.qualification_id?.value
    );
    try {
      const { payload } = await dispatch(uploadQualificationDocument(formData));
      if (payload?.status === "success") {
        let newCultureData = cloneDeep(studyData);
        newCultureData.qualificationdata[index] = {
          ...newCultureData?.qualificationdata?.[index],
          qualification_document: payload?.data,
          qualification_url: payload?.qualification_url,
        };
        setStudyData(newCultureData);
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE.SUCCESS,
            msg: "Qualification document uploaded successfully!",
          })
        );
      } else if (payload?.status === "error") {
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE?.ERROR,
            msg: "First select qualification",
          })
        );
      } else {
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE.ERROR,
            msg: payload?.message,
          })
        );
      }
    } catch (error) {
      dispatch(setAlert({ show: true }));
    }
  };

  const handleFileClick = () => {
    hiddenFileInput?.current?.click();
  };

  const handleFileChange = async (event) => {
    const formData = new FormData();
    formData.append("cv", event?.target?.files?.[0]);
    try {
      const { payload } = await dispatch(uploadCv(formData));
      if (payload?.status === "success") {
        dispatch(setCVName(event?.target?.files?.[0]?.name));
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE.SUCCESS,
            msg: "CV uploaded Successfully!",
          })
        );
      } else {
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE.ERROR,
            msg: payload?.message,
          })
        );
      }
    } catch (error) {
      dispatch(setAlert({ show: true }));
    }
  };

  const handleInputChange = (event, index) => {
    const {
      target: { value },
      target: { id },
    } = event;
    let newCultureData = cloneDeep(studyData);
    newCultureData.qualificationdata[index] = {
      ...newCultureData?.qualificationdata?.[index],
      [id]: value,
    };
    setStudyData(newCultureData);
  };

  useEffect(() => {
    window.addEventListener("resize", getNewWidth);
    return () => {
      window.removeEventListener("resize", getNewWidth);
    };
  }, []);

  useEffect(() => {
    getCVStydyLifeData();
  }, []);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          width: "100%",
          justifyContent: "space-between",
          alignItems: "baseline",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", md24: "row" },
          }}
        >
          <Box>
            <Button
              variant="contained"
              color="yoco"
              sx={{
                fontSize: theme.typography.fontSize.sm,
                fontWeight: theme.typography.Bold,
                minHeight: "45px",
                minWidth: { xs: "", md24: "150px" },
                padding: { xs: "6px 16px", md24: "6px 16px" },
                borderRadius: "25px 0 10px 0",
                textWrap: "nowrap",
                boxShadow: "none",
                "& .MuiButton-startIcon": {
                  marginLeft: "0px",
                },
              }}
            >
              {i18n["myCV.studylife"]}
            </Button>
          </Box>
          <Box
            sx={{
              // display: "flex",
              display: { xs: "none", md24: "flex" },
              alignItems: "center",
              ml: 1,
            }}
          >
            <ToggleSwitch
              onChange={handleNoStudy}
              checked={noStudyExp}
              color="greenSwitch"
            />
            <Typography
              sx={{
                // fontSize: "15px",
                fontWeight: theme?.typography?.Regular,
              }}
            >
              {i18n["myCV.noStudyExp"]}
            </Typography>
          </Box>
        </Box>
        <Box sx={{ textAlign: "right" }}>
          <input
            accept={fileAccept}
            ref={hiddenFileInput}
            type="file"
            onChange={handleFileChange}
            style={{ display: "none" }}
            required
          />
          <Button
            variant="contained"
            color={cVName !== "No file chosen" ? "checkColor" : "peachPayment"}
            sx={{
              fontSize: theme.typography.fontSize.sm,
              fontWeight: theme.typography.Bold,
              minHeight: "45px",
              minWidth: { xs: "", md24: "150px" },
              // borderRadius:{xs:"0px",md24:"0 0 0 10px"},
              borderRadius: "0 0 0 10px",
              padding: { xs: "6px", md24: "0px" },
              textWrap: "nowrap",
              boxShadow: "none",
              "& .MuiButton-startIcon": {
                marginLeft: "0px",
              },
            }}
            onClick={handleFileClick}
            startIcon={
              <Box
                component={"img"}
                src={uploadCVIcon}
                sx={{
                  display: { xs: "none", tablet: "block" },
                  height: "20px",
                  width: "26px",
                }}
              />
            }
          >
            {cVName !== "No file chosen" ? "CV uploaded" : "upload my CV"}
          </Button>
          <Button
            variant="contained"
            color="precium"
            sx={{
              fontSize: theme.typography.fontSize.sm,
              fontWeight: theme.typography.Bold,
              minHeight: "45px",
              minWidth: { xs: "", md24: "150px" },
              // borderRadius:{xs:"0px",md24:"0 0 0 25px"},
              padding: { xs: "5px", md24: "0px" },
              borderRadius: "0 25px 0 0",
              textWrap: "nowrap",
              "& .MuiButton-startIcon": {
                marginLeft: "0px",
              },
            }}
            startIcon={
              <Box
                component={"img"}
                src={scrapeLinkedinIcon}
                sx={{
                  display: { xs: "none", tablet: "block" },
                  height: "20px",
                  width: "20px",
                }}
              />
            }
          >
            grab LinkedIn
          </Button>
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: theme?.typography?.Regular,
              ml: 1,
              mt: "4px",
            }}
          >
            {cVName}
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          // display: "flex",
          display: { xs: "flex", md24: "none" },
          alignItems: "center",
          ml: 1,
        }}
      >
        <ToggleSwitch
          onChange={handleNoStudy}
          checked={noStudyExp}
          color="greenSwitch"
        />
        <Typography
          sx={{
            // fontSize: "15px",
            fontWeight: theme?.typography?.Regular,
          }}
        >
          {i18n["myCV.noStudyExp"]}
        </Typography>
      </Box>
      <Box sx={{ padding: "0px 32px 0px 32px !important" }}>
        {studyData?.qualificationdata &&
          studyData?.qualificationdata?.map((work, index) => (
            <Box key={index} sx={{ mb: "38px" }}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  mb: "23px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "flex-start",
                    flexDirection: "column",
                    gap: "20px",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "row",
                    }}
                  >
                    {index !== 0 && (
                      <IconButton
                        aria-label="edit"
                        color="redButton"
                        sx={{
                          padding: "0 !important",
                          marginRight: "4px",
                        }}
                        onClick={(event) => removeStudy(event, index + 1)}
                      >
                        <RemoveCircleIcon />
                      </IconButton>
                    )}
                    <Typography
                      sx={{
                        fontSize: "18px",
                        fontWeight: theme?.typography?.Bold,
                        flex: 1,
                      }}
                    >
                      {index === 0
                        ? i18n["myCV.latestEducation"]
                        : i18n["myCV.previousEducation"]}
                    </Typography>
                  </Box>
                  {/* {index !== 0 && (
                    <Typography
                      sx={{
                        fontSize: "18px",
                        fontWeight: theme?.typography?.Bold,
                        flex: 1,
                      }}
                    >
                      {"Latest Education"} {i18n["myCV.mostRecent"]}
                    </Typography>
                  )} */}
                </Box>

                <Box>
                  <Typography
                    sx={{
                      fontSize: "18px",
                      fontWeight: theme?.typography?.Medium,
                      pl: 1,
                      pr: 1,
                    }}
                  >
                    {index === 0 ? i18n["myCV.mostRecent"] : ""}
                  </Typography>
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: { xs: "column", md24: "row" },
                  gap: { xs: `20px`, xl: "120px" },
                  alignItems: "center",
                  mb: "22px",
                  width: "100%",
                }}
              >
                <Box sx={{ width: { xs: "100%", xl: `${inputBoxWidth}px` } }}>
                  <InputLabel
                    htmlFor="institution_id"
                    sx={{
                      color: "black",
                      paddingBottom: "2px",
                      fontSize: "14px",
                      fontWeight: theme.typography.Bold,
                    }}
                  >
                    {i18n["myCV.institutionLabelReq"]}
                  </InputLabel>

                  <CustomSelect
                    isDisabled={noStudyExp}
                    index={index}
                    variant="default"
                    onInputChange={handleOnSelectInputChange}
                    onChange={handleOnSelectChange}
                    placeholder={"Select or add the name of the institution"}
                    inputId="institution_id"
                    name="institution_id"
                    value={
                      studyData?.qualificationdata?.[index]?.institution_id
                        ? studyData?.qualificationdata?.[index]?.institution_id
                        : null
                    }
                    onMenuOpen={onMenuOpen}
                    onMenuClose={onMenuClose}
                    option={institution?.map((item) => ({
                      value: item?.institution_id,
                      label: item?.name,
                    }))}
                    isLoading={isLoading["institution_id"]}
                    customStyle={{
                      option: (
                        styles,
                        { data, isDisabled, isFocused, isSelected }
                      ) => {
                        return {
                          ...styles,
                          color: isSelected ? "#fff" : "black",
                          ":active": {
                            ...styles[":active"],
                          },
                        };
                      },
                      menu: (provided) => ({
                        ...provided,
                        zIndex: 9999,
                      }),
                      menuList: (provided) => ({
                        ...provided,
                        "&::-webkit-scrollbar": {
                          width: "6px",
                          height: "6px",
                          borderRadius: "2.5px",
                          backgroundColor: "#efefef",
                        },
                        "&::-webkit-scrollbar-thumb": {
                          borderRadius: "2.5px",
                        },
                      }),
                      control: (baseStyles) => ({
                        ...baseStyles,
                        boxShadow: "none",
                        borderRadius: "10px",
                        height: `40px`,
                        border: errors?.[
                          `qualificationdata[${index}].institution_id`
                        ]
                          ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
                          : "1px solid #E0E0E0",
                        "&:hover": errors?.[
                          `qualificationdata[${index}].institution_id`
                        ]
                          ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
                          : "1px solid #E0E0E0",
                        width: `100%`,
                      }),
                    }}
                  />
                </Box>
                <Box
                  sx={{
                    display: " flex",
                    width: { xs: "100%", md24: "45%" },
                    gap: { xs: "20px", md24: "43px" },
                  }}
                >
                  <Box
                    sx={{
                      width: { xs: "70%", xl: `${selectFiledWidth}px` },
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-end",
                    }}
                  >
                    <InputLabel
                      htmlFor="year_ended"
                      sx={{
                        color: "black",
                        paddingBottom: "2px",
                        fontSize: "14px",
                        fontWeight: theme?.typography?.Bold,
                        width: { xs: "100%", xl: `${selectFiledWidth}px` },
                      }}
                    >
                      {i18n["myCV.yearOfCompletionLabelReq"]}
                    </InputLabel>
                    <SelectMenuYear
                      index={index}
                      clear={true}
                      disabled={noStudyExp}
                      border={
                        errors?.[`qualificationdata[${index}].year_ended`]
                          ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
                          : "1px solid #E0E0E0"
                      }
                      borderRadius="10px"
                      height="40px"
                      width={{ xs: "100%", xl: `${selectFiledWidth}px` }}
                      id="year_ended"
                      name="year_ended"
                      value={getYearEndedValue(index)}
                      onHandleChange={(event) =>
                        handleSelectChange(event, index)
                      }
                      options={allYears}
                      placeholder={"Select"}
                    />
                  </Box>
                  <Box
                    sx={{
                      width: "fit-content",
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                      marginTop: "21px",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontWeight: theme?.typography?.Regular,
                        textWrap: "nowrap",
                      }}
                    >
                      {i18n["myCV.stillCompletingLabel"]}
                    </Typography>
                    <ToggleSwitch
                      color="greenSwitch"
                      disabled={noStudyExp}
                      checked={JSON?.parse(work?.completed?.toLowerCase())}
                      onChange={(event, id, name) =>
                        handleSelectChange2(event, id, name, index)
                      }
                    />
                  </Box>
                </Box>
              </Box>
              <Box sx={{ mb: "22px", width: "100%" }}>
                <InputLabel
                  htmlFor="qualification_id"
                  sx={{
                    color: "black",
                    paddingBottom: "2px",
                    fontSize: "14px",
                    fontWeight: theme?.typography?.Bold,
                  }}
                >
                  {i18n["myCV.qualificationLabelReq"]}
                </InputLabel>

                <CustomSelect
                  isDisabled={noStudyExp}
                  index={index}
                  variant="default"
                  onInputChange={handleOnSelectInputChange}
                  onChange={handleOnSelectChange}
                  placeholder={"Select or add the name of the qualification"}
                  inputId="qualification_id"
                  name="qualification_id"
                  value={
                    studyData?.qualificationdata?.[index]?.qualification_id
                      ? studyData?.qualificationdata?.[index]?.qualification_id
                      : null
                  }
                  onMenuOpen={() => {
                    if (qualification?.length === 0) {
                      getAllData("qualification");
                    }
                    setIsMenuOpen(true);
                  }}
                  onMenuClose={onMenuClose}
                  option={qualification?.map((item) => ({
                    value: item?.qualification_id,
                    label: item?.name,
                  }))}
                  isLoading={isLoading["qualification_id"]}
                  customStyle={{
                    option: (
                      styles,
                      { data, isDisabled, isFocused, isSelected }
                    ) => {
                      return {
                        ...styles,
                        color: isSelected ? "#fff" : "black",
                        ":active": {
                          ...styles[":active"],
                        },
                      };
                    },
                    menu: (provided) => ({
                      ...provided,
                      zIndex: 9999,
                    }),
                    menuList: (provided) => ({
                      ...provided,
                      "&::-webkit-scrollbar": {
                        width: "6px",
                        height: "6px",
                        borderRadius: "2.5px",
                        backgroundColor: "#efefef",
                      },
                      "&::-webkit-scrollbar-thumb": {
                        borderRadius: "2.5px",
                      },
                    }),
                    control: (baseStyles) => ({
                      ...baseStyles,
                      boxShadow: "none",
                      borderRadius: "10px",
                      height: `40px`,
                      width: `100%`,
                      border: errors?.[
                        `qualificationdata[${index}].qualification_id`
                      ]
                        ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
                        : "1px solid #E0E0E0",
                      "&:hover": errors?.[
                        `qualificationdata[${index}].qualification_id`
                      ]
                        ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
                        : "1px solid #E0E0E0",
                    }),
                  }}
                />
              </Box>

              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  flexDirection: { xs: "column", md24: "row" },
                  gap: { xs: "20px", md24: "70px" },
                }}
              >
                <Box sx={{ width: { xs: "100%", md24: "30%" } }}>
                  <InputLabel
                    htmlFor="qualificationtype_id"
                    sx={{
                      color: "black",
                      paddingLeft: "10px",
                      paddingBottom: "2px",
                      fontSize: "14px",
                      fontWeight: theme?.typography?.Bold,
                    }}
                  >
                    {i18n["myCV.typeOfQualificationLabelReq"]}
                  </InputLabel>
                  <SelectMenu
                    clear={true}
                    border={
                      errors?.[
                        `qualificationdata[${index}].qualificationtype_id`
                      ]
                        ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
                        : "1px solid #E0E0E0"
                    }
                    borderRadius="10px"
                    height={"40px"}
                    width={"100%"}
                    disabled={noStudyExp}
                    name="qualificationtype_id"
                    value={work?.qualificationtype_id}
                    onFocus={() => {
                      if (typeOfQualification?.length === 0) {
                        getAllData("typeQualification");
                      }
                    }}
                    onHandleChange={(event) =>
                      handleQualificationType(event, index)
                    }
                    options={typeOfQualification}
                    placeholder={i18n["myCV.typeOfqualificationPlaceholder"]}
                  />
                </Box>
                {/* <Box sx={{display:"flex",justifyContent:{xs:"space-between", tablet:""}}}> */}
                <Box sx={{ width: { xs: "100%", md24: "30%" } }}>
                  <InputLabel
                    htmlFor="garde"
                    sx={{
                      color: "black",
                      paddingLeft: "10px",
                      paddingBottom: "2px",
                      fontSize: "14px",
                      fontWeight: theme?.typography?.Bold,
                    }}
                  >
                Grade  <span
                        style={{
                          fontSize: theme.typography.fontSize.sm,
                          fontWeight: theme.typography.Regular,
                        }}
                      >
                        (optional)
                      </span>
                  </InputLabel>
                  <InputBox
                    disabled={noStudyExp}
                    sx={{
                      width: "100%",
                      height: "38px",
                      borderRadius: "10px",
                    }}
                    placeholder={i18n["myCV.gradePlaceholder"]}
                    value={studyData?.qualificationdata?.[index]?.grade || ""}
                    id="grade"
                    onChange={(event) => handleInputChange(event, index)}
                  />
                </Box>
                <Box sx={{ width: { xs: "100%", md24: "30%" } }}>
                  <InputLabel
                    htmlFor="garde"
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      color: "black",
                      paddingLeft: "10px",
                      paddingBottom: "2px",
                      fontSize: "14px",
                      fontWeight: theme?.typography?.Bold,
                    }}
                  >
                    <span>
                      {i18n["myCV.documentLabelOpt"]}{" "}
                      <span
                        style={{
                          fontSize: theme.typography.fontSize.sm,
                          fontWeight: theme.typography.Regular,
                        }}
                      >
                        (optional)
                      </span>
                    </span>
                    {studyData?.qualificationdata?.[index]
                      ?.qualification_document &&
                      !noStudyExp && (
                        <Link
                          to={`${studyData?.qualificationdata?.[index]?.qualification_url}`}
                          style={{
                            textDecoration: "none",
                            color: theme.palette.black,
                          }}
                          target="_blank"
                        >
                          <span
                            style={{
                              display: "flex",
                              gap: "12px",
                              cursor: "pointer",
                            }}
                          >
                            preview
                            <Box
                              component="img"
                              className="eye"
                              alt="eye logo"
                              src={view}
                              sx={{
                                height: 20,
                                width: 20,
                              }}
                            />
                          </span>
                        </Link>
                      )}
                  </InputLabel>
                  <Paper
                    sx={{
                      display: "flex",
                      height: "38px",
                      // borderRadius: "10px",
                      // border: `1px solid ${theme.palette.grayBorder}`,
                      width: "100%",
                      boxShadow: "none",
                    }}
                  >
                    <input
                      key={index}
                      accept={fileAccept}
                      ref={(el) => (hiddenDocumentInput.current[index] = el)}
                      type="file"
                      onChange={(event) => {
                        handleQualDocument(event, index);
                      }}
                      style={{ display: "none" }}
                      required
                    />
                    <Button
                      disabled={noStudyExp}
                      sx={{
                        minWidth: "fit-content",
                        boxShadow: 0,
                        borderRadius: "10px",
                        height: "auto",
                      }}
                      variant="contained"
                      color="nandos"
                      onClick={() => {
                        handleDocument(index);
                      }}
                    >
                      {i18n["myCV.upload"]}
                    </Button>
                    {/* <Paper
                      elevation={0}
                      component="form"
                      sx={{
                        display: "flex",
                        borderRadius: "25px",
                        alignItems: "center",
                        width: 1,
                      }}
                    >
                      <InputBase
                        disabled={noStudyExp}
                        sx={{
                          ml: 2,
                          width: 1,
                        }}
                        placeholder={i18n["myCV.documentPlaceholder"]}
                      />
                    </Paper> */}
                  </Paper>
                  <Typography
                    sx={{
                      fontSize: "14px",
                      fontWeight: theme?.typography?.Regular,
                      ml: 1,
                      mt: "4px",
                      textAlign: "start",
                    }}
                  >
                    {studyData?.qualificationdata?.[
                      index
                    ]?.qualification_document
                      ?.split("/")
                      ?.slice(2)
                      ?.join("/")}
                  </Typography>
                </Box>
                {/* </Box> */}
              </Box>
            </Box>
          ))}
      </Box>
      <Button
        disabled={noStudyExp}
        variant="contained"
        color="nandos"
        onClick={addStudy}
        sx={{
          borderRadius: "0px 10px 10px 0px",
          width: "150px",
          height: "45px",
          padding: "0px",
        }}
      >
        {i18n["myCV.previousStudyBottonText"]}
      </Button>
      <Box
        sx={{
          padding: {
            xs: "32px 32px 0px 32px !important",
            md24: "32px 52px 0px 32px !important",
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
            alignItems: "center",
            mb: 3,
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography
              sx={{
                color: "black",
                paddingBottom: "2px",
                fontSize: "14px",
                fontWeight: theme?.typography?.Bold,
              }}
            >
              {i18n["myCV.highSchoolLabel"]}
            </Typography>
          </Box>
        </Box>
        {studyData?.schooldata &&
          studyData?.schooldata?.map((schools, index) => (
            <Box key={index} sx={{ mb: 3 }}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
              >
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  {index !== 0 && (
                    <IconButton
                      aria-label="edit"
                      color="redButton"
                      sx={{
                        padding: "0 !important",
                        marginRight: "4px",
                      }}
                      onClick={(event) => removeSchool(event, index + 1)}
                    >
                      <RemoveCircleIcon />
                    </IconButton>
                  )}
                  <Typography
                    sx={{
                      fontSize: "18px",
                      fontWeight: theme?.typography?.Bold,
                      flex: 1,
                    }}
                  >
                    {index > 0 && i18n["myCV.previousEducation"]}
                  </Typography>
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  gap: { xs: `20px`, xl: "120px" },
                  flexDirection: { xs: "column", md24: "row" },
                  alignItems: "center",
                  mb: 3,
                  width: "100%",
                }}
              >
                <Box sx={{ width: { xs: "100%", xl: `${inputBoxWidth}px` } }}>
                  <InputLabel
                    htmlFor="school_id"
                    sx={{
                      color: "black",
                      paddingBottom: "2px",
                      fontSize: "14px",
                      fontWeight: theme?.typography?.Bold,
                    }}
                  >
                    {i18n["myCV.schoolNameReq"]}
                  </InputLabel>
                  <CustomSelect
                    isDisabled={noStudyExp}
                    index={index}
                    variant="default"
                    onInputChange={handleOnSelectInputChange}
                    onChange={handleOnSelectChange}
                    placeholder={i18n["myCV.schoolPlaceholder"]}
                    inputId="school_id"
                    name="school_id"
                    value={
                      studyData?.schooldata?.[index]?.school_id
                        ? studyData?.schooldata?.[index]?.school_id
                        : null
                    }
                    onMenuOpen={() => {
                      if (school?.length === 0) {
                        getAllData("school");
                      }
                      setIsMenuOpen(true);
                    }}
                    onMenuClose={onMenuClose}
                    option={school?.map((item) => ({
                      value: item?.school_id,
                      label: item?.name,
                    }))}
                    isLoading={isLoading["school_id"]}
                    customStyle={{
                      option: (
                        styles,
                        { data, isDisabled, isFocused, isSelected }
                      ) => {
                        return {
                          ...styles,
                          color: isSelected ? "#fff" : "black",
                          ":active": {
                            ...styles[":active"],
                          },
                        };
                      },
                      menu: (provided) => ({
                        ...provided,
                        zIndex: 9999,
                      }),
                      menuList: (provided) => ({
                        ...provided,
                        "&::-webkit-scrollbar": {
                          width: "6px",
                          height: "6px",
                          borderRadius: "2.5px",
                          backgroundColor: "#efefef",
                        },
                        "&::-webkit-scrollbar-thumb": {
                          borderRadius: "2.5px",
                        },
                      }),
                      control: (baseStyles) => ({
                        ...baseStyles,
                        boxShadow: "none",
                        borderRadius: "10px",
                        height: `40px`,
                        border: errors?.[`schooldata[${index}].school_id`]
                          ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
                          : "1px solid #E0E0E0",
                        "&:hover": errors?.[`schooldata[${index}].school_id`]
                          ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
                          : "1px solid #E0E0E0",
                        width: `100%`,
                      }),
                    }}
                  />
                </Box>
                <Box
                  sx={{
                    display: " flex",
                    width: { xs: "100%", md24: "45%" },
                    gap: { xs: "20px", md24: "43px" },
                  }}
                >
                  <Box
                    sx={{
                      width: { xs: "45%", xl: `${selectFiledWidth}px` },
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-end",
                    }}
                  >
                    <InputLabel
                      htmlFor="school_year_end"
                      sx={{
                        color: "black",
                        paddingBottom: "2px",
                        fontSize: "14px",
                        fontWeight: theme?.typography?.Bold,
                        width: { xs: "100%", xl: `${selectFiledWidth}px` },
                      }}
                    >
                      {i18n["myCV.yearOfCompletionLabelOpt"]}
                    </InputLabel>
                    <SelectMenuYear
                      clear={true}
                      disabled={noStudyExp}
                      border="1px solid #C9CAD8"
                      borderRadius="10px"
                      height="40px"
                      width={{ xs: "100%", xl: `${selectFiledWidth}px` }}
                      id="school_year_end"
                      name="school_year_end"
                      value={getSchoolYear(index)}
                      onHandleChange={(event) =>
                        handleSchoolYearChange(event, index)
                      }
                      options={allYears}
                      placeholder={"Select"}
                    />
                  </Box>
                  <Box
                    sx={{
                      width: "fit-content",
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                      marginTop: "21px",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontWeight: theme?.typography?.Regular,
                        textWrap: "nowrap",
                      }}
                    >
                      {i18n["myCV.stillCompletingLabel"]}
                    </Typography>
                    <ToggleSwitch
                      color="greenSwitch"
                      disabled={noStudyExp}
                      checked={schools?.still_completing_flag}
                      onChange={(event, id, name) =>
                        handleSelectChange3(event, id, name, index)
                      }
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
          ))}
      </Box>

      <Button
        disabled={noStudyExp}
        variant="contained"
        color="nandos"
        onClick={addSchool}
        sx={{
          borderRadius: "0px 10px 10px 0px",
          width: "150px",
          height: "45px",
          padding: "0px",
        }}
      >
        {"+ school / A-Level"}
      </Button>

      <Box
        sx={{
          padding: {
            xs: "32px 32px 0px 32px !important",
            md24: "32px 52px 0px 32px !important",
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
            alignItems: "center",
            mb: 3,
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography
              sx={{
                color: "black",
                paddingBottom: "2px",
                fontSize: "14px",
                fontWeight: theme?.typography?.Bold,
              }}
            >
              {i18n["myCV.professionalAssociationLabelOpt"]}
            </Typography>
          </Box>
        </Box>
        {studyData?.associationdata &&
          studyData?.associationdata?.map((associations, index) => (
            <Box key={index} sx={{ mb: 3 }}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
              >
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  {index !== 0 && (
                    <IconButton
                      aria-label="edit"
                      color="redButton"
                      sx={{
                        padding: "0 !important",
                        marginRight: "4px",
                      }}
                      onClick={(event) => removeAssociation(event, index + 1)}
                    >
                      <RemoveCircleIcon />
                    </IconButton>
                  )}
                  <Typography
                    sx={{
                      fontSize: "18px",
                      fontWeight: theme?.typography?.Bold,
                      flex: 1,
                    }}
                  >
                    {index > 0 && i18n["myCV.previousEducation"]}
                  </Typography>
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  gap: { xs: `20px`, xl: "120px" },
                  alignItems: "center",
                  flexDirection: { xs: "column", md24: "row" },
                  mb: 3,
                  width: "100%",
                }}
              >
                <Box sx={{ width: { xs: "100%", xl: `${inputBoxWidth}px` } }}>
                  <InputLabel
                    htmlFor="association_id"
                    sx={{
                      color: "black",
                      paddingBottom: "2px",
                      fontSize: "14px",
                      fontWeight: theme?.typography?.Bold,
                    }}
                  >
                    {i18n["myCV.associationLabel"]}
                  </InputLabel>
                  <CustomSelect
                    isDisabled={noStudyExp}
                    index={index}
                    variant="default"
                    onInputChange={handleOnSelectInputChange}
                    onChange={handleOnSelectChange}
                    placeholder={i18n["myCV.associationPlaceholder"]}
                    inputId="association_id"
                    name="association_id"
                    value={
                      studyData?.associationdata?.[index]?.association_id
                        ? studyData?.associationdata?.[index]?.association_id
                        : null
                    }
                    onMenuOpen={() => {
                      if (association?.length === 0) {
                        getAllData("association");
                      }
                      setIsMenuOpen(true);
                    }}
                    onMenuClose={onMenuClose}
                    option={association?.map((item) => ({
                      value: item?.association_id,
                      label: item?.name,
                    }))}
                    isLoading={isLoading["association_id"]}
                    customStyle={{
                      option: (
                        styles,
                        { data, isDisabled, isFocused, isSelected }
                      ) => {
                        return {
                          ...styles,
                          color: isSelected ? "#fff" : "black",
                          ":active": {
                            ...styles[":active"],
                          },
                        };
                      },
                      menu: (provided) => ({
                        ...provided,
                        zIndex: 9999,
                      }),
                      menuList: (provided) => ({
                        ...provided,
                        "&::-webkit-scrollbar": {
                          width: "6px",
                          height: "6px",
                          borderRadius: "2.5px",
                          backgroundColor: "#efefef",
                        },
                        "&::-webkit-scrollbar-thumb": {
                          borderRadius: "2.5px",
                        },
                      }),
                      control: (baseStyles) => ({
                        ...baseStyles,
                        boxShadow: "none",
                        borderRadius: "10px",
                        height: `40px`,
                        border: "",
                        "&:hover": "",
                        width: `100%`,
                      }),
                    }}
                  />
                </Box>
                <Box
                  sx={{
                    display: " flex",
                    width: { xs: "100%", md24: "45%" },
                    gap: { xs: "20px", md24: "43px" },
                  }}
                >
                  <Box
                    sx={{
                      width: { xs: "45%", xl: `${selectFiledWidth}px` },
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-end",
                    }}
                  >
                    <InputLabel
                      htmlFor="association_year_ended"
                      sx={{
                        color: "black",
                        paddingBottom: "2px",
                        fontSize: "14px",
                        fontWeight: theme?.typography?.Bold,
                        width: { xs: "100%", xl: `${selectFiledWidth}px` },
                      }}
                    >
                      {i18n["myCV.yearOfCompletionLabelOpt"]}
                    </InputLabel>
                    <SelectMenuYear
                      clear={true}
                      disabled={noStudyExp}
                      border="1px solid #C9CAD8"
                      borderRadius="10px"
                      height="40px"
                      width={{ xs: "100%", xl: `${selectFiledWidth}px` }}
                      id="association_year_end"
                      name="association_year_end"
                      onHandleChange={(event) =>
                        handleAssociationYearChange(event, index)
                      }
                      value={getAssociationYear(index)}
                      options={allYears}
                      placeholder={"Select"}
                    />
                  </Box>
                  <Box
                    sx={{
                      width: "fit-content",
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                      marginTop: "21px",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontWeight: theme?.typography?.Regular,
                        textWrap: "nowrap",
                      }}
                    >
                      {i18n["myCV.stillCompletingLabel"]}
                    </Typography>

                    <ToggleSwitch
                      color="greenSwitch"
                      disabled={noStudyExp}
                      checked={associations.still_completing_flag}
                      onChange={(event, id, name) =>
                        handleSelectChange4(event, id, name, index)
                      }
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
          ))}
      </Box>
      <Button
        disabled={noStudyExp}
        variant="contained"
        color="nandos"
        onClick={addAssociation}
        sx={{
          borderRadius: "0px 10px 10px 0px",
          width: "150px",
          height: "45px",
          padding: "0px",
        }}
      >
        {"+ association"}
      </Button>

      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          pt: 5,
        }}
      >
        <Button
          sx={{
            boxShadow: 0,
            padding: "0px",
            fontSize: "14px",
            width: "150px",
            height: "60px",
            borderRadius: "10px 0px 0px 0px",
            color: "black",
          }}
          startIcon={
            <Box
              component={"img"}
              src={previousIcon}
              sx={{
                height: "25px",
                width: "25px",
              }}
            />
          }
          variant="contained"
          color="lockItIn"
          onClick={() => {
            handleSaveButton(1);
            // changeStep(1, "component");
          }}
        >
          work life
        </Button>
        <Button
          onClick={() => handleSaveButton(3)}
          sx={{
            boxShadow: 0,
            padding: "0px",
            fontSize: "14px",
            width: "150px",
            height: "60px",
            borderRadius: "0px 10px 0px 0px",
          }}
          endIcon={
            <Box
              component={"img"}
              src={nextIcon}
              sx={{
                height: "25px",
                width: "25px",
              }}
            />
          }
          variant="contained"
          color="nandos"
        >
          references
        </Button>
      </Box>
    </>
  );
}
