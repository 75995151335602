import { useTheme } from "@emotion/react";
import { Box, Typography } from "@mui/material";
import React from "react";
import FreshPrinceIcon from "../assets/FreshPrinceIcon.svg";

const OurStoryFooterComponent = () => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        overflowY: "hidden",
        // gap: "30px",
        columnGap: "30px",
        overFlowX: "auto",
        display: "grid",
        paddingLeft: "20px",
        paddingRight: "40px",
        gridTemplateColumns: "repeat(5, minmax(360px, 360px))",
      }}
      height="100%"
    >
      <Box sx={{ boxSizing: "border-box", paddingTop: "10px", height: "100%" }}>
        <Typography
          sx={{
            fontSize: "16px",
            fontWeight: theme.typography.Bold,
            marginBottom: "5px",
            color: "#fff",
            lineHeight: "19px",
            letterSpacing: "0px",
          }}
        >
          The back story
        </Typography>
        <Typography
          sx={{
            fontSize: "14px",
            fontWeight: theme.typography.Regular,
            color: "#fff",
            letterSpacing: "0px",
            lineHeight: "19px",
          }}
        >
          Now, this is a story all about how,
        </Typography>
        <Typography
          sx={{
            fontSize: "14px",
            fontWeight: theme.typography.Regular,
            color: "#fff",
            letterSpacing: "0px",
            lineHeight: "19px",
          }}
        >
          Your strife’s been flipped, turned upside down
        </Typography>
        <Typography
          sx={{
            fontSize: "14px",
            fontWeight: theme.typography.Regular,
            color: "#fff",
            letterSpacing: "0px",
            lineHeight: "19px",
          }}
        >
          And we’d like to take a minute, just sit right there,
        </Typography>
        <Typography
          sx={{
            fontSize: "14px",
            fontWeight: theme.typography.Regular,
            color: "#fff",
            letterSpacing: "0px",
            lineHeight: "19px",
            marginBottom: "10px",
          }}
        >
          We’ll tell you how you’ve landed on this platform right here
        </Typography>
        <Typography
          sx={{
            fontSize: "14px",
            fontWeight: theme.typography.Regular,
            color: "#fff",
            letterSpacing: "0px",
            lineHeight: "19px",
          }}
        >
          In a WordPress site born and raised
        </Typography>
        <Typography
          sx={{
            fontSize: "14px",
            fontWeight: theme.typography.Regular,
            color: "#fff",
            letterSpacing: "0px",
            lineHeight: "19px",
          }}
        >
          On LinkedIn is where we spent most of our days
        </Typography>
        <Typography
          sx={{
            fontSize: "14px",
            fontWeight: theme.typography.Regular,
            color: "#fff",
            letterSpacing: "0px",
            lineHeight: "19px",
          }}
        >
          Billin’ out, faxin’, maxin’ out pools
        </Typography>
        <Typography
          sx={{
            fontSize: "14px",
            fontWeight: theme.typography.Regular,
            color: "#fff",
            letterSpacing: "0px",
            lineHeight: "19px",
          }}
        >
          And all groupin’ some people using our tools
        </Typography>
        <Typography
          sx={{
            fontSize: "14px",
            fontWeight: theme.typography.Regular,
            color: "#fff",
            letterSpacing: "0px",
            lineHeight: "19px",
          }}
        >
          When a couple of guys they just weren’t that good
        </Typography>
        <Typography
          sx={{
            fontSize: "14px",
            fontWeight: theme.typography.Regular,
            color: "#fff",
            letterSpacing: "0px",
            lineHeight: "19px",
          }}
        >
          Started making trouble in our WhatsApp group
        </Typography>
        <Typography
          sx={{
            fontSize: "14px",
            fontWeight: theme.typography.Regular,
            color: "#fff",
            letterSpacing: "0px",
            lineHeight: "19px",
          }}
        >
          We got in one little fight but our team ain’t scared
        </Typography>
        <Typography
          sx={{
            fontSize: "14px",
            fontWeight: theme.typography.Regular,
            color: "#fff",
            letterSpacing: "0px",
            lineHeight: "19px",
            marginBottom: "10px",
          }}
        >
          “Why we movin’ with these aunties, let’s build a platform instead”
        </Typography>
        <Typography
          sx={{
            fontSize: "14px",
            fontWeight: theme.typography.Regular,
            color: "#fff",
            letterSpacing: "0px",
            lineHeight: "19px",
          }}
        >
          With a move of the mouse, year seven or eight
        </Typography>
        <Typography
          sx={{
            fontSize: "14px",
            fontWeight: theme.typography.Regular,
            color: "#fff",
            letterSpacing: "0px",
            lineHeight: "19px",
          }}
        >
          Yelled to those who were shabby, “Yo homies, don’t be haters”
        </Typography>
        <Typography
          sx={{
            fontSize: "14px",
            fontWeight: theme.typography.Regular,
            color: "#fff",
            letterSpacing: "0px",
            lineHeight: "19px",
          }}
        >
          We looked at our income, there was enough cash to spare
        </Typography>
        <Typography
          sx={{
            fontSize: "14px",
            fontWeight: theme.typography.Regular,
            color: "#fff",
            letterSpacing: "0px",
            lineHeight: "19px",
          }}
        >
          So you can sit on your phone to finds some jobs out there!
        </Typography>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            marginTop: "-22px",
            justifyContent: "flex-end",
          }}
        >
          <Box>
            <Box
              component="img"
              src={FreshPrinceIcon}
              sx={{ height: "101px", width: "144px" }}
            ></Box>
            <Typography
              sx={{
                fontSize: "14px",
                fontWeight: theme.typography.Regular,
                color: "#fff",
                letterSpacing: "0px",
                lineHeight: "19px",
              }}
            >
              The Fresh Prince of Bellville
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box sx={{ boxSizing: "border-box", paddingTop: "10px", height: "100%" }}>
        <Typography
          sx={{
            fontSize: "16px",
            fontWeight: theme.typography.Bold,
            marginBottom: "5px",
            color: "#fff",
            lineHeight: "19px",
            letterSpacing: "0px",
          }}
        >
          Traditional recruitment is broken
        </Typography>
        <Typography
          sx={{
            fontSize: "14px",
            fontWeight: theme.typography.Regular,
            marginBottom: "10px",
            color: "#fff",
            letterSpacing: "0px",
            lineHeight: "19px",
          }}
        >
          If you’re reading this, you’re on at least one side of the equation:
        </Typography>
        <Typography
          sx={{
            fontSize: "14px",
            fontWeight: theme.typography.Bold,
            marginBottom: "10px",
            color: "#fff",
            letterSpacing: "0px",
            lineHeight: "19px",
          }}
        >
          You’re hiring
        </Typography>
        <Typography
          sx={{
            fontSize: "14px",
            fontWeight: theme.typography.Regular,
            marginBottom: "10px",
            color: "#fff",
            letterSpacing: "0px",
            lineHeight: "19px",
          }}
        >
          If you’re reading this, you’re on at least one side of the equation:
          You’re hiring You’ve previously worked with traditional recruiters and
          been frustrated with the high fees, lack of understanding of your
          business and low calibre of candidates presented. Neither you nor the
          recruiter feels obligated towards the other as they’re working on risk
          and if something sticks, you’ll fork out the fee and hope the
          candidate stays.
        </Typography>
        <Typography
          sx={{
            fontSize: "14px",
            fontWeight: theme.typography.Regular,
            marginBottom: "10px",
            color: "#fff",
            letterSpacing: "0px",
            lineHeight: "19px",
          }}
        >
          Fast-forward a few years, you’re hiring via an internal talent team
          but getting overwhelmed by the volume of irrelevant applications and
          noise created due to zero-friction platforms like LinkedIn. You have
          an amazing brand and culture, but how do you communicate this via a
          job spec?!
        </Typography>
        <Typography
          sx={{
            fontSize: "14px",
            fontWeight: theme.typography.Bold,
            marginBottom: "10px",
            color: "#fff",
            letterSpacing: "0px",
            lineHeight: "19px",
          }}
        >
          You’re looking for work
        </Typography>
        <Typography
          sx={{
            fontSize: "14px",
            fontWeight: theme.typography.Regular,
            marginBottom: "10px",
            color: "#fff",
            letterSpacing: "0px",
            lineHeight: "19px",
          }}
        >
          #notanotherdataform - So many applications submitted but zero response
          and you’re left wondering if you’re still in the running or had your
          CV tossed on day 1. If via a recruiter, you probably have no idea who
          the company is and if direct, you’re not 100% sure what the role
          entails and if the business is a good fit for you.
        </Typography>
      </Box>
      <Box sx={{ boxSizing: "border-box", paddingTop: "10px", height: "100%" }}>
        <Typography
          sx={{
            fontSize: "16px",
            fontWeight: theme.typography.Bold,
            marginBottom: "5px",
            color: "#fff",
            lineHeight: "19px",
            letterSpacing: "0px",
          }}
        >
          The fix
        </Typography>
        <Typography
          sx={{
            fontSize: "14px",
            fontWeight: theme.typography.Regular,
            marginBottom: "10px",
            color: "#fff",
            letterSpacing: "0px",
            lineHeight: "19px",
          }}
        >
          One platform to connect all corners of the talent marketplace quadrant
          - candidates, employers, recruiters, and social networks.
        </Typography>
        <Typography
          sx={{
            fontSize: "14px",
            fontWeight: theme.typography.Bold,
            marginBottom: "10px",
            color: "#fff",
            letterSpacing: "0px",
            lineHeight: "19px",
          }}
        >
          Why do we exist?
        </Typography>
        <Typography
          sx={{
            fontSize: "14px",
            fontWeight: theme.typography.Regular,
            marginBottom: "10px",
            color: "#fff",
            letterSpacing: "0px",
            lineHeight: "19px",
          }}
        >
          Crayon exists to act as extension of your brand and provide you (the
          employer or recruiter) with the tools needed to cut through the noise.
          We believe your choice of talent platform is a candidate’s first
          touchpoint of your brand, so make it count. As a candidate, we enable
          you with a platform to showcase not only your skills, but your
          personality too.
        </Typography>
        <Typography
          sx={{
            fontSize: "14px",
            fontWeight: theme.typography.Bold,
            marginBottom: "10px",
            color: "#fff",
            letterSpacing: "0px",
            lineHeight: "19px",
          }}
        >
          Why now?
        </Typography>
        <Typography
          sx={{
            fontSize: "14px",
            fontWeight: theme.typography.Regular,
            marginBottom: "10px",
            color: "#fff",
            letterSpacing: "0px",
            lineHeight: "19px",
          }}
        >
          Local talent platforms are antiquated, lacklustre and expensive.
          International talent platforms have reduced friction to the point that
          you are drowned by irrelevance. We believe now is the time to offer
          the market a fresh start, using data and video to inform both sides of
          the marketplace, increase efficiencies, and thereby reduce the cost of
          hiring.
        </Typography>
      </Box>
      <Box sx={{ boxSizing: "border-box", paddingTop: "10px", height: "100%" }}>
        <Typography
          sx={{
            fontSize: "16px",
            fontWeight: theme.typography.Bold,
            marginBottom: "5px",
            color: "#fff",
            lineHeight: "19px",
            letterSpacing: "0px",
          }}
        >
          Our mission and vision
        </Typography>
        <Typography
          sx={{
            fontSize: "14px",
            fontWeight: theme.typography.Regular,
            marginBottom: "5px",
            color: "#fff",
            letterSpacing: "0px",
            lineHeight: "19px",
          }}
        >
          Our mission is simple and our vision is clear…
        </Typography>
        <Typography
          sx={{
            fontSize: "14px",
            fontWeight: theme.typography.Regular,
            marginBottom: "10px",
            color: "#fff",
            letterSpacing: "0px",
            lineHeight: "19px",
          }}
        >
          We aim to connect one million smiles by 2030, via becoming Africa’s
          most loved talent platform.
        </Typography>
        <Typography
          sx={{
            fontSize: "14px",
            fontWeight: theme.typography.Regular,
            marginBottom: "10px",
            color: "#fff",
            letterSpacing: "0px",
            lineHeight: "19px",
          }}
        >
          That’s it, that’s the brief. We hope you can join us in connecting at
          least two smiles; yours and your next hire.
        </Typography>
        <Typography
          sx={{
            fontSize: "14px",
            fontWeight: theme.typography.Regular,
            marginBottom: "10px",
            color: "#fff",
            letterSpacing: "0px",
            lineHeight: "19px",
          }}
        >
          Let’s get to work!
        </Typography>
      </Box>
      <Box sx={{ boxSizing: "border-box", paddingTop: "10px", height: "100%" }}>
        <Typography
          sx={{
            fontSize: "16px",
            fontWeight: theme.typography.Bold,
            marginBottom: "5px",
            color: "#fff",
            lineHeight: "19px",
            letterSpacing: "0px",
          }}
        >
          Our value and values
        </Typography>
        <Typography
          sx={{
            fontSize: "14px",
            fontWeight: theme.typography.Regular,
            marginBottom: "10px",
            color: "#fff",
            letterSpacing: "0px",
            lineHeight: "19px",
          }}
        >
          We are solving for the problem of how to cut through the noise and
          best identify talent that is right for your business. We do this by
          providing an extension of your employer brand, combine this with
          relevant friction via a bespoke platform, and then apply tech in the
          background to save you time and hire right, first time.
        </Typography>
        <Typography
          sx={{
            fontSize: "14px",
            fontWeight: theme.typography.Regular,
            color: "#fff",
            letterSpacing: "0px",
            lineHeight: "19px",
          }}
        >
          The Crayon platform has been built on a number of core values:
        </Typography>
        <Typography
          sx={{
            fontSize: "14px",
            fontWeight: theme.typography.Regular,
            color: "#fff",
            letterSpacing: "0px",
            lineHeight: "19px",
          }}
        >
          1) To encourage transparency - information is power and the more both
          the candidate and the employer have in hand, the better
        </Typography>
        <Typography
          sx={{
            fontSize: "14px",
            fontWeight: theme.typography.Regular,
            color: "#fff",
            letterSpacing: "0px",
            lineHeight: "19px",
          }}
        >
          2) To favour functionality over form - you are here to find
          work/talent, not be overwhelmed by pages of scrolling
        </Typography>
        <Typography
          sx={{
            fontSize: "14px",
            fontWeight: theme.typography.Regular,
            color: "#fff",
            letterSpacing: "0px",
            lineHeight: "19px",
          }}
        >
          3) To have fun while you’re at it - even if you don’t find a job or a
          candidate, our hope is that you at least leave with a smile
        </Typography>
      </Box>
    </Box>
  );
};

export default OurStoryFooterComponent;
