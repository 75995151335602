import {
  Box,
  Button,
  InputBase,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  deleteSubscriber,
  getSubscriber,
} from "../../../redux/guest/contactSlice";
import { useTheme } from "@emotion/react";
import InfiniteScroll from "react-infinite-scroll-component";
import { TableCells } from "../../../utils/CommonComponent";
import { dateConverterMonth } from "../../../utils/DateTime";
import TalentSVGButton from "../../common/TalentSVGButton";
import deleteIcon from "../../../assets/Padding Excluded/Black_Trash_Delete_1 - Copy.svg";
import Delete from "../../candidate/myCam/dialog/Delete";

const SubscribeList = () => {
  const [lastKey, setLastKey] = useState(0);
  const [subList, setSubList] = useState([]);
  const theme = useTheme();
  const [openDelete, setOpenDelete] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [selectedList, setSelectedList] = useState();
  const [searchValue, setSearchValue] = useState();
  const [totalCount, setTotalCount] = useState();
  let dispatch = useDispatch();

  const getSubList = async (lastKeyy, name, type) => {
    let payload = {
      lastKey: lastKeyy,
      email: name ? name : searchValue ? searchValue : "",
    };
    try {
      let response = await dispatch(getSubscriber(payload));
      if (response?.payload?.status === "success") {
        setTotalCount(response?.payload?.totalCount);
        if (type === "search") {
          setSubList(response?.payload?.data);
          setLastKey(response?.payload?.pageNumber + 1);
        } else {
          if (payload?.pageNumber === 0) {
            setSubList(response?.payload?.data);
            setLastKey(response?.payload?.pageNumber + 1);
          } else if (response?.payload?.data?.length > 0) {
            setSubList((prev) => [...prev, ...response?.payload?.data]);
            setLastKey(response?.payload?.pageNumber + 1);
          }
        }
      }
    } catch (error) {}
  };

  const handleText = (event) => {
    setSearchValue(event?.target?.value);
    getSubList(0, event?.target?.value, "search");
  };
  const handleDeleteSub = async () => {
    let payload = {
      email: selectedList,
    };
    try {
      let response = await dispatch(deleteSubscriber(payload));
      if (response?.payload?.status === "success") {
        let updatedList = subList.filter((item) => item.email !== selectedList);
        setSubList(updatedList);
      }
      setConfirmDelete(false);
      setOpenDelete(false);
    } catch (error) {}
  };
  const handleDeleteDialog = (poolId) => {
    // setPoolId(poolId);
    setSelectedList(poolId);
    setOpenDelete((prevState) => !prevState);
  };
  const handleCancelDelete = () => {
    setOpenDelete((prevState) => !prevState);
    setConfirmDelete(false);
  };
  useEffect(() => {
    getSubList(0);
  }, []);

  return (
    <>
      <Box
        sx={{
          background: "white",
          borderRadius: "25px",
          mt: "45px",
          boxShadow: `0px 5px 10px ${theme?.palette?.boxShadow?.main}`,
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Box sx={{ display: "flex", gap: "26px", width: "675px" }}>
            <Button
              variant="contained"
              color="redButton"
              sx={{
                width: "150px",
                height: "45px",
                borderRadius: "25px 0 25px 0",
                padding: "0px",
              }}
            >
              {`subscriber list (${totalCount})`}
            </Button>
            {/* <SearchBox
              onChange={(event) => handleText(event)}
              width="500px"
              margin="5px 0 0 0"
              placeholder="search for a transaction or company"
            /> */}
            <Box
              sx={{
                width: "500px",
                margin: "5px 0 0 0",
                backgroundColor: "white",
                display: "flex",
                flexDirection: "row",
                border: `1px solid ${theme.palette.grayBorder}`,
                borderRadius: "10px",
                paddingLeft: "5px",
              }}
            >
              <InputBase
                value={searchValue}
                sx={{
                  height: "40px",
                  width: "100%",
                  fontSize: "14px",
                }}
                onChange={handleText}
                placeholder={"search for a subscriber"}
              />
            </Box>
          </Box>
        </Box>
        <Box>
          <InfiniteScroll
            style={{ overflow: "hidden" }}
            dataLength={subList?.length}
            next={() => getSubList(lastKey)}
            scrollThreshold={"100px"}
            scrollableTarget={"jobList"}
            hasMore={true}
            endMessage={
              <p style={{ textAlign: "center" }}>
                <b>Yay! You have seen it all</b>
              </p>
            }
          >
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCells>
                      <Typography variant="subtitle1" fontWeight="bold">
                        Email
                      </Typography>
                    </TableCells>
                    <TableCells>
                      <Typography variant="subtitle1" fontWeight="bold">
                        Created at
                      </Typography>
                    </TableCells>
                    <TableCells></TableCells>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {subList?.map((row) => (
                    <TableRow
                      key={row?.payment_transaction_id}
                      style={{
                        "& .css-12zgwp-MuiTableCell-root": {
                          padding: "0 16px !important",
                        },
                      }}
                    >
                      <TableCells
                        sx={{
                          fontWeight: theme.typography.Bold,
                          padding: "22px 16px 10px 30px",
                        }}
                      >
                        {row?.email}
                      </TableCells>

                      <TableCells sx={{ textAlign: "left" }}>
                        {dateConverterMonth(row?.created_at)}
                      </TableCells>
                      <TableCells
                        sx={{
                          display: "flex",
                        }}
                      >
                        <TalentSVGButton
                          color={"white"}
                          source={deleteIcon}
                          height={24}
                          width={18}
                          padding={0}
                          onClick={() => {
                            handleDeleteDialog(row?.email);
                            // handleDeleteSub(row?.email);
                          }}
                        />
                      </TableCells>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </InfiniteScroll>
        </Box>
      </Box>
      <Delete
        show={openDelete}
        handleOpen={handleDeleteDialog}
        handleCancel={handleCancelDelete}
        handleDelete={handleDeleteSub}
        confirmDelete={confirmDelete}
        setConfirmDelete={setConfirmDelete}
      />
    </>
  );
};

export default SubscribeList;
