import { createTheme } from "@mui/material/styles";

import { PostAjobTheme } from "../Theme";

const postAjobDirectTheme = createTheme({
  ...PostAjobTheme,
  palette: {
    ...PostAjobTheme.palette,
    filterButtons: {
      main: "#38A8AE",
      contrastText: "#FFFFFF",
    },
    pageButtons: {
      main: "#FFFFFF",
      contrastText: "#000000",
    },
    activePageButton: {
      main: "#38A8AE",
      contrastText: "#FFFFFF",
    },
    activeStepButtons: {
      main: "#38A8AE",
      contrastText: "#FFFFFF",
      contrastCircle: "#FFFFFF",
      iconText: "#38A8AE",
      circleColor: "#38A8AE",
    },
    stepButtonColor: {
      main: "#38A8AE",
      contrastText: "#FFFFFF",
    },
    stepButtons: {
      main: "#FFFFFF",
      contrastText: "#38A8AE",
      iconText: "#FFFFFF",
    },
  },
});

export { postAjobDirectTheme };
