import { createTheme } from "@mui/material/styles";

import { employerDefaulTheme } from "../Theme";

const employerCompanyListTheme = createTheme({
  ...employerDefaulTheme,
  palette: {
    ...employerDefaulTheme.palette,
    getCoins: {
      main: "#B38E66",
      contrastText: "#FFF",
    },
    playButton: {
      main: "#47D281",
      contrastText: "#FFF",
    },
    editButton: {
      main: "#C9CAD8",
      contrastText: "#000",
    },
    viewJobsButton: {
      main: "#228CCE",
      contrastText: "#FFF",
    },
    deleteButton: {
      main: " #D1011B",
      contrastText: "#FFF",
    },
    UploadButton: {
      main: "#F2F4FB",
      contrastText: "#000",
    },
    flipButton: {
      main: "#FFCB00",
      contrastText: "#FFF",
    },
    saveButton: {
      main: "#FFCB00",
      contrastText: "#FFF",
    },
    backButton: {
      main: "#C9CAD8",
      contrastText: "#000",
    },
    companyTypeButton: {
      main: "#0C486B",
      contrastText: "#FFF",
    },
    companySizeButton: {
      main: "#0C486B",
      contrastText: "#FFF",
    },
    industriesButton: {
      main: "#0077C2",
      contrastText: "#FFF",
    },
  },
});

export { employerCompanyListTheme };
