import React, { useState } from "react";
import {
  Avatar,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputBase,
  Paper,
  ThemeProvider,
  Typography,
} from "@mui/material";
import forgotPasswordIcon from "../../assets/Characters/CrayBotIcons/Crayon Craybot Notification-08.svg";
import { useTheme } from "@emotion/react";
import { forgotPasswordPagetheme } from "../../utils/Theme";
import { useDispatch } from "react-redux";
import { resetPassword } from "../../redux/login/loginSlice";
import { setAlert } from "../../redux/configSlice";
import { ALERT_TYPE, ERROR_MSG, PASSWORD_REGEX } from "../../utils/Constants";
import locale from "../../i18n/locale";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import {
  getDecodedToken,
  getToken,
  setLocalStorage,
  useQuery,
  validateSchema,
} from "../../utils/Common";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { setLoggedIn } from "../../redux/guest/jobsSlice";
export default function CreateNewPassword({
  open,
  close,
  setOpenLogin,
  onHandleLogin,
  newToken,
}) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const query = useQuery();
  const navigate = useNavigate();
  const i18n = locale.en;
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errors, setErrors] = useState([]);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const validationSchema = Yup.object().shape({
    newpassword: Yup.string()
      .matches(
        PASSWORD_REGEX,
        "Password must contain at least 8 characters, one uppercase, one lowercase, one number, and one special character"
      )
      .required("Password required"),
    confirmpassword: Yup.string()
      .matches(
        PASSWORD_REGEX,
        "Password must contain at least 8 characters, one uppercase, one lowercase, one number, and one special character"
      )
      .required("Password required"),
  });

  const handleShowPassword = (text) => {
    if (text === "newpassword") {
      setShowNewPassword(!showNewPassword);
    } else {
      setShowConfirmPassword(!showConfirmPassword);
    }
  };
  const resetPass = async () => {
    let testData = {
      newpassword: newPassword,
      confirmpassword: confirmPassword,
    };
    if (newPassword !== confirmPassword) {
      // If they don't match, set an error
      setErrors([{ message: "Password didn't match", key: "match" }]);
      return; // Exit the function
    }
    validateSchema(validationSchema, testData)
      .then(() => {
        try {
          let data = {
            new_password: newPassword,
          };
          dispatch(resetPassword({ token: newToken, payload: data })).then(
            (payload) => {
              if (payload?.payload?.status === "success") {
                dispatch(
                  setAlert({
                    show: true,
                    type: ALERT_TYPE?.SUCCESS,
                    msg: i18n["alertMessage.newPassword"],
                  })
                );
                let decodedToken = getDecodedToken(newToken);
                let newUser = {
                  data: {
                    role_id: Number(decodedToken?.role_id),
                    //   role_id: 6,
                  },
                  token: newToken,
                };
                onHandleLogin(newUser);
                dispatch(setLoggedIn(true));
                //   navigate("/jobs");
                close();
                // setOpenLogin(true);
              } else {
                dispatch(
                  setAlert({
                    show: true,
                    type: ALERT_TYPE?.ERROR,
                    msg: ERROR_MSG,
                  })
                );
              }
            }
          );
        } catch (error) {
          dispatch(
            setAlert({
              show: true,
              type: ALERT_TYPE?.ERROR,
              msg: ERROR_MSG,
            })
          );
        }
      })
      .catch((error) => {
        setErrors(
          Object.keys(error)?.map((item) => ({
            message: error[item],
            key: item,
          }))
        );
      });
  };

  const handlePassword = (e) => {
    if (!PASSWORD_REGEX?.test(e.target.value)) {
      setErrors((prev) => [
        {
          message:
            "Password must contain at least 8 characters, one uppercase, one lowercase, one number, and one special character",
          key:
            e.target.id === "new_password" ? "newpassword" : "confirmpassword",
        },
      ]);
    }
    if (e.target.id === "new_password") {
      setNewPassword(e.target.value);
      PASSWORD_REGEX?.test(e.target.value) &&
        setErrors(errors?.filter((item) => item?.key !== "newpassword"));
    } else if (e.target.id === "confirm_password") {
      setConfirmPassword(e.target.value);
      PASSWORD_REGEX?.test(e.target.value) &&
        setErrors(errors?.filter((item) => item?.key !== "confirmpassword"));
    }
  };

  return (
    <ThemeProvider theme={forgotPasswordPagetheme}>
      <Dialog
        sx={{
          padding: 0,
          ".MuiPaper-root": {
            width: "450px",
            borderRadius: "25px",
            backgroundColor: `backgroundColor`,
          },
        }}
        open={open}
        onDialogClose={close}
      >
        <DialogTitle onClose={close}>
          <IconButton
            aria-label="close"
            onClick={close}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
            disableRipple="true"
          >
            <Box
              sx={{
                width: "20px",
                height: "20px",
                color: "#000000",
                border: 2,
                fontSize: "18px",
                borderRadius: "5px",
              }}
            >
              X
            </Box>
          </IconButton>
        </DialogTitle>
        <DialogContent
          sx={{
            width: "100%",
            padding: "30px 0 0",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {/* <Box
                      
                  > */}
          <Avatar src={forgotPasswordIcon} sx={{ width: 96, height: 150 }} />
          <Typography
            sx={{
              fontSize: "25px",
              fontWeight: theme.typography.Bold,
            }}
          >
            {i18n["login.createNewPassword"]}
          </Typography>
          {errors?.find((item) => item?.key === "match") && (
            <Typography
              sx={{
                fontSize: "14px",
                fontWeight: theme.typography.Medium,
                color: "red",
                textAlign: "center",
              }}
            >
              {i18n["alert.passwordsdonotmatch"]}
            </Typography>
          )}
          {(errors?.find((item) => item?.key === "newpassword") ||
            errors?.find((item) => item?.key === "confirmpassword")) && (
            <Typography
              sx={{
                fontSize: "14px",
                fontWeight: theme.typography.Medium,
                color: "red",
                textAlign: "center",
              }}
            >
              {i18n["alert.passwordmustcontain"]}
            </Typography>
          )}
          <Box
            sx={{
              mt: 3,
              width: "85%",
              padding: 0,
              display: "flex",
              flexDirection: "column",
              gap: "10px",
            }}
          >
            <Paper
              elevation={3}
              sx={{
                display: "flex",
                borderRadius: "10px !important",
                width: "100% !important",
                height: "40px",
                boxShadow: "none",
                border: errors?.find((item) => item?.key === "newpassword")
                  ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
                  : `1px solid ${theme.palette.grayBorder}`,
              }}
            >
              <InputBase
                type={`${showNewPassword ? "text" : "password"}`}
                sx={{ ml: 2, mr: 2, width: "100%" }}
                id="new_password"
                placeholder={"new password"}
                onChange={(e) => {
                  handlePassword(e);
                }}
              />
              <IconButton
                sx={{ py: 0 }}
                color=""
                aria-label="reset password"
                component="button"
                onClick={() => handleShowPassword("newpassword")}
                disableRipple={true}
              >
                {showNewPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
              </IconButton>
            </Paper>
            <Paper
              elevation={3}
              sx={{
                display: "flex",
                borderRadius: "10px !important",
                width: "100% !important",
                height: "40px",
                boxShadow: "none",
                border: errors?.find((item) => item?.key === "confirmpassword")
                  ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
                  : `1px solid ${theme.palette.grayBorder}`,
              }}
            >
              <InputBase
                type={`${showConfirmPassword ? "text" : "password"}`}
                sx={{ ml: 2, mr: 2, width: "100%" }}
                id="confirm_password"
                placeholder={"confirm password"}
                onChange={(e) => {
                  handlePassword(e);
                }}
              />
              <IconButton
                sx={{ py: 0 }}
                color=""
                aria-label="reset password"
                component="button"
                onClick={() => handleShowPassword("confirmPassword")}
                disableRipple={true}
              >
                {showConfirmPassword ? (
                  <VisibilityIcon />
                ) : (
                  <VisibilityOffIcon />
                )}
              </IconButton>
            </Paper>
          </Box>
        </DialogContent>
        <DialogActions
          sx={{
            display: "flex",
            justifyContent: "center",
            mt: 3,
            overflow: "hidden",
            padding: 0,
          }}
        >
          <Button
            sx={{
              width: "50%",
              height: "60px",
              borderRadius: 0,
              padding: 3,
              fontSize: "17px",
              fontWeight: theme.typography.Bold,
            }}
            variant="contained"
            color="backButton"
            onClick={close}
          >
            cancel
          </Button>
          <Button
            sx={{
              width: "50%",
              borderRadius: 0,
              padding: 3,
              height: "60px",
              marginLeft: "0 !important",
              fontSize: "17px",
              fontWeight: theme.typography.Bold,
            }}
            variant="contained"
            color="resetButton"
            onClick={resetPass}
          >
            create
          </Button>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
}
