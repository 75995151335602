import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getApi, postApi } from "../../utils/Apis";
import { setLoading } from "../configSlice";

const initialState = {
  contactType: [],
};

export const getContactType = createAsyncThunk(
  "getContactType",
  async (payload, { dispatch }) => {
    dispatch(setLoading(true));
    const { data } = await getApi("/getcontacttypes");
    dispatch(setLoading(false));
    return data;
  }
);
export const getContactUsList = createAsyncThunk(
  "getContactUsList",
  async (payload, { dispatch }) => {
    dispatch(setLoading(true));
    const { data } = await getApi(
      payload?.contact_type_id
        ? `/admin/getcontactpage?lastKey=${
            payload?.lastkey || 0
          }&contact_type_id=${payload?.contact_type_id}`
        : `/admin/getcontactpage?lastKey=${payload?.lastkey || 0}`
    );
    dispatch(setLoading(false));
    return data;
  }
);

export const queryResolve = createAsyncThunk(
  "queryResolve",
  async (payload, { dispatch }) => {
    dispatch(setLoading(true));
    const { data } = await postApi("/admin/resolvequery", payload, true);
    dispatch(setLoading(false));
    return data;
  }
);
export const postprivacypolicy = createAsyncThunk(
  "postprivacypolicy",
  async (payload, { dispatch }) => {
    dispatch(setLoading(true));
    const { data } = await postApi("/admin/postprivacypolicy", payload, true);
    dispatch(setLoading(false));
    return data;
  }
);
export const postTermCondition = createAsyncThunk(
  "postTermCondition",
  async (payload, { dispatch }) => {
    dispatch(setLoading(true));
    const { data } = await postApi("/admin/posttermcondition", payload, true);
    dispatch(setLoading(false));
    return data;
  }
);
export const getTermCondition = createAsyncThunk(
  "getTermCondition",
  async (payload, { dispatch }) => {
    dispatch(setLoading(true));
    const { data } = await getApi("/admin/termcondition");
    dispatch(setLoading(false));
    return data;
  }
);
export const getPrivacyPolicy = createAsyncThunk(
  "getPrivacyPolicy",
  async (payload, { dispatch }) => {
    dispatch(setLoading(true));
    const { data } = await getApi("/admin/privacypolicy");
    dispatch(setLoading(false));
    return data;
  }
);
export const getTermcondition = createAsyncThunk(
  "getTermcondition",
  async (payload, { dispatch }) => {
    dispatch(setLoading(true));
    const { data } = await getApi("/admin/termcondition");
    dispatch(setLoading(false));
    return data;
  }
);

export const addcontact = createAsyncThunk(
  "addcontact",
  async (payload, { dispatch }) => {
    dispatch(setLoading(true));
    const { data } = await postApi("/addcontact", payload, true);
    dispatch(setLoading(false));
    return data;
  }
);

export const uploadScreenshot = createAsyncThunk(
  "uploadScreenshot",
  async (payload, { dispatch }) => {
    dispatch(setLoading(true));
    const { data } = await postApi(
      "/upload/queryscreenshot",
      payload,
      true,
      "multipart/form-data"
    );
    dispatch(setLoading(false));
    return data;
  }
);
export const addSubscriber = createAsyncThunk(
  "addSubscriber",
  async (payload, { dispatch }) => {
    dispatch(setLoading(true));
    const { data } = await postApi("/addSubscriber", payload);
    dispatch(setLoading(false));
    return data;
  }
);
export const deleteSubscriber = createAsyncThunk(
  "deleteSubscriber",
  async (payload, { dispatch }) => {
    dispatch(setLoading(true));
    const { data } = await postApi(
      "/admin/maintenance/deleteSubscriber",
      payload
    );
    dispatch(setLoading(false));
    return data;
  }
);
export const getSubscriber = createAsyncThunk(
  "getSubscriber",
  async (payload, { dispatch }) => {
    dispatch(setLoading(true));
    const { data } = await getApi(
      `/admin/maintenance/getsubscribers?email=${payload?.email}&lastKey=${
        payload?.lastKey
      }&limit=${payload?.limit ? payload?.limit : 25}`
    );
    dispatch(setLoading(false));
    return data;
  }
);

export const contactSlice = createSlice({
  name: "configContactSlice",
  initialState,
  reducers: {
    contactList: (state, action) => {
      state.contactType = action?.payload;
    },
  },
});

export const { contactList } = contactSlice.actions;

export default contactSlice.reducer;
