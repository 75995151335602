import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  IconButton,
  Typography,
  useTheme,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { setAlert } from "../../../redux/configSlice";
import { ALERT_TYPE } from "../../../utils/Constants";
import CounterDialog from "./dialog/CounterDialog";
import RecordingInProgress from "./dialog/RecordingInProgress";
import Delete from "./dialog/Delete";
// import jobVideoHeaderLogo from "../../../assets/Padding Excluded/White_jobVideo.svg";
import jobVideoHeaderLogo from "../../../assets/Cam/JobVideoIcon.svg";

import jobVideoicon from "../../../assets/CrayBotIcons/Crayon Job Video Character.svg";
import uploadNewVideoIcon from "../../../assets/Cam/UploadVideoIcon.svg";
import { RadioButtonChecked } from "@mui/icons-material";
import {
  deleteEmployerJobVideo,
  uploadPostJobCultureVideo,
} from "../../../redux/employer/postJobSlice";
import JobVideoBriefBox from "./dialog/JobVideoBriefBox";
import PlayButton from "../PlayButton";
import deleteIcon from "../../../assets/Cam/DeleteIcon.svg";
import locale from "../../../i18n/locale";
import { getVideoFileDuration } from "../../../utils/Common";
import useViewport from "../ViewPort";
import lockItInIcon from "../../../assets/Cam/MyVideoIcon.svg";
import takeTwoIcon from "../../../assets/Cam/TakeTwoIcon.svg";
import addALink from "../../../assets/Cam/AddLinkLogo.svg";
import AddYoutubeLink from "./dialog/AddYoutubeLink";
import { updateCamLink } from "../../../redux/employer/myJobsSlice";

export default function EmployerJobVideoUpload({
  show,
  closeFunc,
  job_id,
  reloadVideo,
  allVideoJobs,
  setAllJobList,
  job,
  setSteps,
  steps,
  setActiveStep,
  setOpenRecordDialog,
  allData,
  setAllData,
  allJobsList,
}) {
  const i18n = locale.en;
  const theme = useTheme();
  const dispatch = useDispatch();
  const VideoRef = useRef(null);
  const mediaRecorderRef = useRef(null);
  const { width } = useViewport();
  // const [steps, setSteps] = useState(1);
  const [mediaStream, setMediaStream] = useState(null);
  const [recordedVideoData, setRecordedVideoData] = useState(null);
  const [videoLink, setVideoLink] = useState("");
  const [openDelete, setOpenDelete] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [showBrief, setShowBrief] = useState(false);
  const [disabledButton, setDisabledButton] = useState(true);
  const [isPlaying, setIsPlaying] = useState(false);
  const [videoBlob, setVideoBlob] = useState("");
  const [youtubeLink, setYoutubeLink] = useState("");
  const [youtubeLinkModal, setYoutubeLinkModal] = useState("");
  const [errors, setErrors] = useState(false);

  const [textChange, setTextChange] = useState(
    recordedVideoData ? true : false
  );
  const fileAccept = "video/*";
  const hiddenFileInput = useRef(null);
  const buttonSize = 60;
  const triangleSize = 25;
  const handleCloseBriefBox = () => {
    setShowBrief(false);
  };

  const validateFileSize = (file) => {
    if (file?.size > 100000000) {
      dispatch(
        setAlert({
          show: true,
          type: ALERT_TYPE?.ERROR,
          msg: "Video size should be less than 100MB",
        })
      );
      return false;
    }
    return true;
  };
  const uploadVideo = async (file, isUpload) => {
    getVideoFileDuration(file, async (error, videoDuration, fileType) => {
      if (error) {
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE?.ERROR,
            msg: error,
          })
        );
        return;
      } else if (
        file?.name?.split(".")?.pop()?.toLowerCase() !== "mp4" &&
        isUpload &&
        file?.name?.split(".")?.pop()?.toLowerCase() !== "mov" &&
        isUpload &&
        file?.name?.split(".")?.pop()?.toLowerCase() !== "webm" &&
        isUpload
      ) {
        setVideoLink("")
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE?.ERROR,
            msg: "Please upload .mp4 or .mov file",
          })
        );
        return;
      } else if (videoDuration > 60 && isUpload) {
        setVideoLink("")
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE?.ERROR,
            msg: "Video length should be less than 1 min",
          })
        );
        return;
      } else if (file?.size > 50000000) {
        setVideoLink("")
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE?.ERROR,
            msg: "Video size should be less than 50MB",
          })
        );
        return;
      }

      const formData = new FormData();
      const myFile = new File([file], "videoFile.webm", {
        type: file?.type,
      });
      formData?.append("jobvideo", myFile);
      formData?.append("job_id", job_id);

      try {
        const { payload } = await dispatch(uploadPostJobCultureVideo(formData));
        if (payload?.status === "success") {
          dispatch(
            setAlert({
              show: true,
              type: ALERT_TYPE?.SUCCESS,
              msg: "Video uploaded Successfully!",
            })
          );
          setAllJobList((prev) =>
            prev?.map((item) =>
              item.job_id === job_id
                ? {
                    ...item,
                    linkFlag: false,
                    job_video_url: URL.createObjectURL(file),
                  }
                : item
            )
          );
          closeFunc();
          reloadVideo(URL.createObjectURL(file));
        } else {
          dispatch(
            setAlert({
              show: true,
              type: ALERT_TYPE?.ERROR,
              msg: "Failed to upload Video",
            })
          );
        }
      } catch (error) {
        dispatch(setAlert({ show: true }));
      }
    });
  };
  const handleFileChange = async (event) => {
    const selectedFile = event?.target?.files?.[0];

    if (!selectedFile) {
      return;
    }

    if (validateFileSize(selectedFile)) {
      const link = URL.createObjectURL(selectedFile);
      setVideoLink(link);
      await uploadVideo(selectedFile, true);
    }
  };
  const handleFileClick = () => {
    // hiddenFileInput?.current?.click();
    uploadVideo(videoBlob, false);
    setActiveStep(3);
    setOpenRecordDialog(false);
    setSteps(1);
    setOpenRecordDialog((prevState) => !prevState);
  };

  // Callback function to receive recorded video data
  const handleVideoData = (videoData) => {
    setRecordedVideoData(videoData);
  };

  const handleOpenDelete = () => {
    closeFunc();
    setOpenDelete((prevState) => !prevState);
    setConfirmDelete(false);
  };
  const handleCancelDelete = () => {
    setOpenDelete((prevState) => !prevState);
    setConfirmDelete(false);
  };

  const handleDeleteVideo = async () => {
    try {
      const { payload } = await dispatch(
        deleteEmployerJobVideo({ job_id: job_id })
      );
      if (payload?.status === "success") {
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE?.SUCCESS,
            msg: "Job video removed successfully!",
          })
        );
        handleCancelDelete();
        setAllJobList([]);
        await allVideoJobs(0);
      }
    } catch (error) {}
  };

  const handleStopRecording = () => {
    if (
      mediaRecorderRef?.current &&
      mediaRecorderRef?.current?.state === "recording"
    ) {
      mediaRecorderRef?.current?.stop();
    }
  };

  const handleCloseCamera = () => {
    if (mediaStream) {
      mediaStream?.getTracks()?.forEach((track) => {
        track?.stop();
      });
      setMediaStream(null);
    }
  };

  const handleClose = () => {
    if (steps === 1 || steps === 2) {
      closeFunc();
    } else if (steps === 3) {
      setSteps(4);
    }

    handleStopRecording();
    handleCloseCamera();
  };
  const updatecamYoutubeLink = async (url) => {
    let payload = {
      // company_id: companyID,
      job_id: job_id,
      link: url,
    };
    try {
      let response = await dispatch(updateCamLink(payload));
      if (response?.payload?.status === "success") {
        let updatedList = allJobsList?.map((item) => {
          if (item?.job_id === job_id) {
            return {
              ...item,
              linkFlag: true,
              job_video_url: url,
              job_video_link: url,
            };
          } else {
            return item;
          }
        });
        setAllJobList(updatedList);
        setTextChange(true);
        closeFunc();
        setYoutubeLinkModal(false);
        // await getVideo();
      }
    } catch (error) {}
  };

  const handleSubmit = (url) => {
    updatecamYoutubeLink(url);
  };
  useEffect(() => {
    if (recordedVideoData) {
      setVideoLink(recordedVideoData);
    }
  }, [recordedVideoData]);

  return (
    <>
      <Dialog
        open={show}
        fullWidth={true}
        onClose={() => setOpenRecordDialog((prevState) => !prevState)}
        sx={{
          "& .MuiPaper-root": {
            borderRadius: "25px",
            maxWidth: "760px",
            height: { xs: "auto", md: "739px" },
          },
        }}
      >
        {/* <DialogTitle
          sx={{
            padding: 0,
          }}
        >
          <IconButton
            aria-label="close"
            onClick={() => {
              handleClose();
            }}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <Box
              sx={{
                width: "20px",
                height: "20px",
                color: "#000000",
                border: 2,
                fontSize: "18px",
                borderRadius: "5px",
              }}
            >
              X
            </Box>
          </IconButton>
        </DialogTitle> */}
        {steps === 1 && (
          <>
            <Button
              variant="contained"
              color="peachPayment"
              sx={{
                borderRadius: "25px 0 10px 0",
                width: "150px",
                height: "45px",
                fontSize: "14px",
                fontWeight: theme.typography.Bold,
                gap: "10px",
              }}
            >
              <Box
                component={"img"}
                src={jobVideoHeaderLogo}
                sx={{
                  height: "20px",
                  width: "24px",
                }}
              />
              {i18n["empMyCam.jobvideo"]}
            </Button>

            <Box
              sx={{
                padding: "30px 20px 31px",
                flexDirection: "column",
                alignItems: "center",
                display: "flex",
                gap: "37px",
                flex: "1",
              }}
            >
              <Box
                sx={{
                  width: { xs: "90%", md: "70%" },
                  flexDirection: "column",
                  alignItems: "center",
                  display: "flex",
                  gap: "20px",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "20px",
                    fontWeight: theme.typography.Bold,
                    textAlign: "center",
                  }}
                >
                  {i18n["empMyCam.rollCamera"]}
                </Typography>
                {/* <Typography
                  sx={{
                    fontSize: "14px",
                    textAlign: "center",
                    width: {xs:"90%",md:"453px"},
                    fontWeight: theme.typography.Regular,
                  }}
                >
                  {i18n["empMyCam.rollCamera1"]}
                </Typography> */}
              </Box>

              <Box
                sx={{
                  width: { xs: "90%", md: "500px" },
                  height: { xs: "auto", md: "auto" },
                  flexGrow: 1,
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <Box sx={{}}>
                  <Box
                    component={"img"}
                    src={jobVideoicon}
                    sx={{
                      height: {
                        xs: "200px",
                        xs360: "240px",
                        xs390: "266px",
                        md: "359px",
                      },
                      width: "auto",
                      border: "2px solid #C9CAD8",
                      borderRadius: "25px 25px 0 0",
                      borderBottom: "none",
                      marginBottom: "-6px",
                    }}
                  />
                  <Box
                    sx={{
                      display: "flex",
                      width: "100%",
                      background: theme.palette.mainBackground,
                      borderRadius: "0 0 25px 25px",
                      height: "60px",
                    }}
                  >
                    <Box
                      sx={{
                        width: { xs: "60%", md: "70%" },
                        margin: "auto",
                        padding: "0 0 0 20px ",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "15px",
                          fontWeight: theme.typography.Bold,
                        }}
                      >
                        {job?.title}
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "13px",
                          fontWeight: theme.typography.Regular,
                        }}
                      >
                        {job?.CompanyName}
                      </Typography>
                    </Box>
                    <Button
                      variant="contained"
                      color="videoButton"
                      sx={{
                        width: { xs: "40%", md: "30%" },
                        fontSize: "14px",
                        height: "100%",
                        borderRadius: "0 0 25px 0",
                      }}
                      onClick={() => setShowBrief(true)}
                    >
                      the brief
                    </Button>
                    {showBrief && (
                      <JobVideoBriefBox
                        show={showBrief}
                        handleOpen={handleCloseBriefBox}
                      />
                    )}
                  </Box>
                </Box>
                <Box
                  sx={{
                    width: { xs: "90%", md: "70%" },
                    flexDirection: "column",
                    alignItems: "center",
                    display: "flex",
                    // gap: "20px",
                    marginTop: "30px",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "14px",
                      textAlign: "center",
                      width: { xs: "90%", md: "453px" },
                      fontWeight: theme.typography.Regular,
                    }}
                  >
                    This is your chance to attract top talent with a brief
                    summary video of the job.
                  </Typography>
                </Box>
                {/* <Box
                  component={"img"}
                  src={jobVideoicon}
                  sx={{
                    height: {xs:"200px",xs360:"240px",xs390:"266px",md:"359px"},
                    width: "auto",
                  }}
                />
                <Box
                  sx={{
                    display: "flex",
                    width: "100%",
                    background: theme.palette.mainBackground,
                    borderRadius: "0 0 25px 25px",
                    height: "60px",
                  }}
                >
                  <Box
                    sx={{
                      width: {xs:"60%",md:"70%"},
                      margin: "auto",
                      padding: "0 0 0 20px ",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "15px",
                        fontWeight: theme.typography.Bold,
                      }}
                    >
                      {job?.title}
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "13px",
                        fontWeight: theme.typography.Regular,
                      }}
                    >
                      {job?.CompanyName}
                    </Typography>
                  </Box>
                  <Button
                    variant="contained"
                    color="videoButton"
                    sx={{
                      width: {xs:"40%",md:"30%"},
                      height: "100%",
                      borderRadius: "0 0 25px 0",
                    }}
                    onClick={() => setShowBrief(true)}
                  >
                    {i18n["empMyCam.theBrief"]}
                  </Button>
                  {showBrief && (
                    <JobVideoBriefBox
                      show={showBrief}
                      handleOpen={handleCloseBriefBox}
                    />
                  )}
                </Box> */}
              </Box>
            </Box>

            <Box
              sx={{
                display: "flex",
              }}
            >
              <Button
                variant="contained"
                color="uploadVideoButton"
                sx={{
                  width: "50%",
                  height: "60px",
                  borderRadius: "0 0 0 25px",
                  fontSize: "15px",
                  gap: "10px",
                  fontWeight: theme.typography.Bold,
                }}
                onClick={handleFileClick}
              >
                <Box
                  component={"img"}
                  src={uploadNewVideoIcon}
                  sx={{
                    height: "20px",
                    width: "24px",
                    display: { xs: "none", tablet: "block" },
                  }}
                />
                {i18n["empMyCam.uploadvideo"]}
              </Button>

              <input
                accept={fileAccept}
                ref={hiddenFileInput}
                type="file"
                onChange={handleFileChange}
                style={{ display: "none" }}
              />
              <Button
                variant="contained"
                color="tymeColor"
                sx={{
                  width: "50%",
                  height: "60px",
                  borderRadius: "0 0 0 0",
                  fontSize: "14px",
                  gap: "8px",
                  fontWeight: theme.typography.Bold,
                }}
                onClick={() => {
                  setYoutubeLinkModal(true);
                }}
              >
                <Box
                  component={"img"}
                  src={addALink}
                  sx={{
                    height: "20px",
                    width: "24px",
                  }}
                />
                add a link
              </Button>
              <Button
                variant="contained"
                color="videoButton"
                sx={{
                  width: "50%",
                  height: "60px",
                  borderRadius: "0 0 25px 0",
                  fontSize: "14px",
                  gap: "8px",
                  fontWeight: theme.typography.Bold,
                }}
                onClick={() => setSteps(2)}
              >
                {(width > 767 || width === 0) && (
                  <Box
                    component={"img"}
                    src={jobVideoHeaderLogo}
                    sx={{
                      height: "20px",
                      width: "24px",
                    }}
                  />
                )}
                {i18n["empMyCam.recordvideo"]}
              </Button>
            </Box>
          </>
        )}
        {steps === 2 && (
          <CounterDialog nextStep={setSteps} name={"job"} page="jobVideo" />
        )}
        {steps === 3 && (
          <Box
            sx={{
              width: "100%",
              height: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <RecordingInProgress
              nextStep={setSteps}
              onRecordingStop={handleVideoData}
              uploadFunc={uploadVideo}
              name={"job"}
              handleStopRecording={handleStopRecording}
              handleCloseCamera={handleCloseCamera}
              setMediaStream={setMediaStream}
              mediaStream={mediaStream}
              mediaRecorderRef={mediaRecorderRef}
              setRecordedVideoData={setRecordedVideoData}
              setVideoBlob={setVideoBlob}
              setTextChange={setTextChange}
              page="jobVideo"
            />
          </Box>
        )}
        {steps === 4 && (
          <>
            <Button
              variant="contained"
              color="jobVideoButton"
              sx={{
                borderRadius: "25px 0 10px 0",
                width: "150px",
                height: "45px",
                fontSize: "14px",
                fontWeight: theme.typography.Bold,
                gap: "10px",
              }}
            >
              <Box
                component={"img"}
                src={jobVideoHeaderLogo}
                sx={{
                  height: "20px",
                  width: "24px",
                }}
              />
              {i18n["empMyCam.jobvideo"]}
            </Button>

            <Box
              sx={{
                width: "70%",
                margin: "auto",
                flexDirection: "column",
                alignItems: "center",
                display: "flex",
                gap: "20px",
                marginTop: "30px",
              }}
            >
              <Typography
                sx={{
                  fontSize: "20px",
                  fontWeight: theme.typography.Bold,
                  textAlign: "center",
                }}
              >
                {i18n["empMyCam.nailedIt"]}
              </Typography>
              {/* <Typography
                sx={{
                  fontSize: "14px",
                  textAlign: "center",

                  fontWeight: theme.typography.Regular,
                }}
              >
                {i18n["empMyCam.successfullyRecorded"]}
              </Typography>
              <Typography
                sx={{
                  fontSize: "14px",
                  textAlign: "center",

                  fontWeight: theme.typography.Regular,
                }}
              >
                {i18n["empMyCam.rerecord"]}
              </Typography> */}
            </Box>

            <Box
              sx={{
                flexGrow: 1,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: "30px",
                flexDirection: "column",
                position: "relative",
              }}
            >
              {!isPlaying && (
                <PlayButton
                  buttonSize={buttonSize}
                  triangleSize={triangleSize}
                  top={"calc(50% - 100px)"}
                />
              )}

              <video
                ref={VideoRef}
                preload="auto"
                loops
                autoPlay={false}
                poster=""
                style={{
                  width: disabledButton ? "60%" : "60%",
                  borderRadius: "25px 25px 0 0",
                  height: "auto",
                  aspectRatio: "4/3",
                  objectFit: "cover",
                  cursor: disabledButton ? "wait" : "pointer",
                  border: disabledButton
                    ? `1px solid ${theme.palette.grayButton600.main}`
                    : "none",
                  borderBottom: "none", // To remove the border for the bottom side
                }}
                muted={false}
                src={videoLink || recordedVideoData}
                type="video/mp4"
                onClick={() => {
                  if (!disabledButton) {
                    if (VideoRef?.current?.paused) {
                      VideoRef?.current?.play();
                      setIsPlaying(true);
                    } else {
                      VideoRef?.current?.pause();
                      setIsPlaying(false);
                    }
                  }
                }}
                onLoadedData={() => {
                  setDisabledButton(false);
                }}
                onEnded={() => setIsPlaying(false)}
              ></video>
              <Box
                sx={{
                  display: "flex",
                  width: "60%",
                  background: theme.palette.mainBackground,
                  borderRadius: "0 0 25px 25px",
                  height: "60px",
                }}
              >
                <Box
                  sx={{
                    width: "70%",
                    margin: "auto",
                    padding: "0 0 0 20px ",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "15px",
                      fontWeight: theme.typography.Bold,
                    }}
                  >
                    {job?.title}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "13px",
                      fontWeight: theme.typography.Regular,
                    }}
                  >
                    {job?.CompanyName}
                  </Typography>
                </Box>
                <Button
                  variant="contained"
                  color="videoButton"
                  sx={{
                    width: "30%",
                    height: "100%",
                    borderRadius: "0 0 25px 0",
                  }}
                  onClick={() => setShowBrief(true)}
                >
                  {i18n["empMyCam.theBrief"]}
                </Button>
                {showBrief && (
                  <JobVideoBriefBox
                    show={showBrief}
                    handleOpen={handleCloseBriefBox}
                    text1={"You can include a video specific to each job. Here you will get to highlight the key requirements of the job, what sort of candidate should apply and any nuances not captured within the job spec."}
                    text2={"Make sure to keep it relavant to the particular job and rather highlight your overall business via the company video."}
                  />
                )}
              </Box>
              <Box
                sx={{
                  width: "70%",
                  margin: "auto",
                  flexDirection: "column",
                  alignItems: "center",
                  display: "flex",
                  gap: "20px",
                  marginTop: "30px",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "14px",
                    textAlign: "center",
                    fontWeight: theme.typography.Regular,
                  }}
                >
                  Your job video has been successfully recorded. Hollywood
                  awaits!
                </Typography>
                <Typography
                  sx={{
                    fontSize: "14px",
                    textAlign: "center",
                    fontWeight: theme.typography.Regular,
                  }}
                >
                  Review your video and then lock it in, or try again if you
                  weren’t happy with the first take.
                </Typography>
              </Box>
            </Box>

            <Box
              sx={{
                margin: "auto",
                width: "100%",
                height: "60px",
              }}
            >
              <Button
                variant="contained"
                color="deleteVideoButton"
                sx={{
                  borderRadius: "0 0 0 25px",
                  width: "33.33%",
                  height: "100%",
                  gap: "8px",
                }}
                onClick={handleOpenDelete}
              >
                <Box
                  component={"img"}
                  src={deleteIcon}
                  sx={{ height: 18, display: { xs: "none", tablet: "block" } }}
                />
                {i18n["empMyCam.delete"]}
              </Button>
              <Button
                variant="contained"
                color="yoco"
                sx={{
                  borderRadius: 0,
                  width: "33.33%",
                  height: "100%",
                  gap: "8px",
                }}
                onClick={() => setSteps(2)}
              >
                {/* {width > 767 && <RadioButtonChecked />} */}
                {(width > 767 || width === 0) && (
                  <Box
                    component={"img"}
                    src={takeTwoIcon}
                    sx={{
                      height: "20px",
                      width: "24px",
                    }}
                  />
                )}
                {/* record new video */}
                take two
              </Button>

              <input
                accept={fileAccept}
                ref={hiddenFileInput}
                type="file"
                onChange={handleFileChange}
                style={{ display: "none" }}
              />
              <Button
                variant="contained"
                color="nandos"
                sx={{
                  borderRadius: "0 0 25px 0",
                  width: "33.33%",
                  height: "100%",
                  gap: "8px",
                }}
                onClick={handleFileClick}
              >
                <Box
                  component={"img"}
                  src={lockItInIcon}
                  sx={{
                    height: "20px",
                    width: "24px",
                    display: { xs: "none", tablet: "block" },
                  }}
                />
                {/* {i18n["empMyCam.uploadNewVideo"]} */}
                lock it in
              </Button>
            </Box>
          </>
        )}
      </Dialog>
      <Delete
        show={openDelete}
        handleOpen={handleOpenDelete}
        handleCancel={handleCancelDelete}
        handleDelete={handleDeleteVideo}
        confirmDelete={confirmDelete}
        setConfirmDelete={setConfirmDelete}
      />
      {youtubeLinkModal && (
        <AddYoutubeLink
          open={youtubeLinkModal}
          setOpen={() => setYoutubeLinkModal(false)}
          handleSubmit={handleSubmit}
          youtubeLink={youtubeLink}
          setErrors={setErrors}
          errors={errors}
          setYoutubeLink={setYoutubeLink}
        />
      )}
    </>
  );
}
