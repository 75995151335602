import { Box, Button, Modal, Typography } from "@mui/material";
import React from "react";
import { useTheme } from "@emotion/react";
import CompanyModalPopupModal from "../../assets/CompanyModalPopupImage.svg";
import { useNavigate } from "react-router-dom";
import CloseIcon from "./CloseIcon";

const InitialApprovalModal = ({ open, setOpen, handleReviewLater }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    height: "auto",
    width: { xs: "360px", tablet: "450px" },
    bgcolor: "background.paper",
    borderRadius: "25px",
    boxShadow: "none !important",
    "&:focus-visible": {
      outline: "none",
    },
  };

  const navigateGoToMyTeam = () => {
    navigate("/employer/my-team",{state:{value:"waiting"}});
    setOpen()
  };

  return (
    <Modal
      open={open}
      onClose={setOpen}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Box
          sx={{
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
            // gap: 3,
            width: "100%",
          }}
        >
          <CloseIcon top="15px" right="15px" onClick={setOpen} />
          <Box
            sx={{
              padding: { xs: "0 10px", tablet: "0 15px" },
            }}
          >
            <Box
              component={"img"}
              src={CompanyModalPopupModal}
              sx={{ width: 136, height: 125, marginTop: "20px" }}
            />
            <Typography
              sx={{
                fontSize: "20px",
                fontWeight: theme.typography.Bold,
                lineHeight: "27px",
                marginBottom: "20px",
              }}
            >
              Hang on a second
            </Typography>
            <Typography
              sx={{
                fontSize: "16px",
                fontWeight: theme.typography.Bold,
                marginBottom: "20px",
              }}
            >
              You have new account and/or team member requests requiring review.
            </Typography>
            <Typography
              sx={{
                fontSize: "14px",
                fontWeight: theme.typography.Regular,
                marginBottom: "20px",
              }}
            >
              You can approve/reject these requests via your my team section.
            </Typography>
          </Box>
          <Box sx={{ width: "100%" }}>
            <Button
              sx={{
                width: "50%",
                borderRadius: "0px 0px 0px 25px",
                height: "60px",
                fontWeight: theme.typography.Bold,
                fontSize: "14px",
              }}
              onClick={handleReviewLater}
              variant="contained"
              color="newSubmit"
            >
              I’ll review later
            </Button>
            <Button
              sx={{
                width: "50%",
                borderRadius: "0px 0px 25px 0px",
                height: "60px",
                fontWeight: theme.typography.Bold,
                fontSize: "14px",
              }}
              variant="contained"
              onClick={() => {
                navigateGoToMyTeam();
              }}
              color="nandos"
            >
              go to my team
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default InitialApprovalModal;
