import { Box, Button, InputBase, Paper } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { useTheme } from "@emotion/react";
import SelectMenu from "../../common/SelectMenu";
import { getTeamMembers } from "../../../redux/admin/jobsSlice";
import { useDispatch } from "react-redux";
import { setAlert } from "../../../redux/configSlice";
import { ALERT_TYPE, POST_JOB_ADMIN } from "../../../utils/Constants";
import { getCompanies } from "../../../redux/employer/empProfileSlice";
import { useSelector } from "react-redux";
import CustomSelect from "../../candidate/myCV/CustomSelect";
import { debounce, getDecodedToken, getToken } from "../../../utils/Common";
import upClose from "../../../assets/Black_Up_Close.svg";
import downClose from "../../../assets/Black_Down_Open.svg";
import { useParams } from "react-router-dom";
import PostAsPopUp from "./postAJob/PostAsPopUp";
import JobType from "./postAJob/JobType";
import PostJob from "./postAJob/PostJob";
import _, { filter } from "lodash";

export default function Filters({
  total,
  title,
  // handleJobRoleChange,
  value,
  // handleJobType,
  // handleJobStatus,
  stageArray,
  jobTypeArray,
  // handleInputSearch,
  // handleTeamMember,
  jobStatus,
  // handleCompany,
  handleRecruiter,
  recruiter,
  // filtersValue,
  // setFiltersValue,
  // setOpenFilter,
  // openFilter,
  getJobList,
  basicData,
  setBasicData,
  setAllJobs,
  setSearchTitle,
  company,
  formStateRef
}) {
  const theme = useTheme();
  const decodedToken = getDecodedToken(getToken());
  const dispatch = useDispatch();
  const [teammember, setTeammember] = useState([]);
  const [companyList, setCompanyList] = useState([]);
  const optionsToLoadOnScroll = 12;
  const { compname } = useParams();
  const { leftMenuButton } = useSelector((state) => state.adminSlice);
  const [openFilter, setOpenFilter] = useState(false);

  const { companies } = useSelector((state) => state.myProfile);
  const [isLoading, setIsLoading] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [postJobData, setPostJobData] = useState(POST_JOB_ADMIN);
  const [open, setOpen] = useState(false);
  const [openJobType, setOpenJobType] = useState(false);
  const [errors, setErrors] = useState([]);
  const [openPostJob, setOpenPostJob] = useState(false);
  const [filtersValue, setFiltersValue] = useState({
    quickSearch: "",
    jobStage: "",
    jobType: "",
    companyName: "",
    jobStatus: "",
  });
  const handleClose = () => {
    setCompanyList([]);
    setPostJobData(POST_JOB_ADMIN);
    setOpenPostJob(false);
  };

  const getTeamMember = async (lastkeyy) => {
    try {
      const { payload } = await dispatch(getTeamMembers(""));
      if (payload?.status == "success") {
        setTeammember(payload?.data);
      } else {
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE.ERROR,
            msg: payload?.message,
          })
        );
      }
    } catch (error) {
      dispatch(
        setAlert({
          show: true,
          type: ALERT_TYPE.ERROR,
          msg: error,
        })
      );
    }
  };

  const handleScroll = (event) => {
    const target = event.target;
    if (target?.scrollHeight - target?.scrollTop === target?.clientHeight) {
      // User has scrolled to the bottom, load more options
      const remainingOptions = teammember?.slice(
        teammember?.length,
        teammember?.length + optionsToLoadOnScroll
      );
      setTeammember([...teammember, ...remainingOptions]);
    }
  };

  const onMenuOpen = async () => {
    if (companies.length < 1) {
      await dispatch(getCompanies());
    }
    setIsMenuOpen(true);
  };
  const onMenuClose = () => {
    setIsMenuOpen(false);
  };

  // const handleOnSelectInputChange = (newValue, inputId) => {
  //   if (!company) {
  //     debouncedHandleOnSelectInputChange(newValue, inputId);
  //   }
  // };

  const debouncedHandleOnSelectInputChange = useCallback(
    debounce(
      async (newValue, type) => {
        let params = {
          title: newValue || "",
          limit: 200,
        };
        if (newValue) {
          // getlist(params, type);
          try {
            if (type === "company_name") {
              dispatch(getCompanies(params));
            }
          } catch (error) {
            dispatch(
              setAlert({
                show: true,
                type: ALERT_TYPE.ERROR,
                msg: error,
              })
            );
          }
        }
      },
      750,
      setIsLoading
    ),
    []
  );

  const companyFilterSelected = async () => {
    if (compname && companies?.length === 1) {
      setFiltersValue({
        ...filtersValue,
        companyName: { value: companies?.[0]?.id, label: companies?.[0]?.name },
      });
      let newBasicData = {
        ...basicData,
        companyName: companies?.[0]?.id,
      };
      await getJobList(0, newBasicData);
    }
  };

  const handleStep1 = () => {
    let industries;
    industries = companyList?.find(
      (item) => item?.company_id === postJobData?.company_id
    )?.industry;

    let country = companyList?.find(
      (item) => item?.company_id === postJobData?.company_id
    )?.country;
    let town = companyList?.find(
      (item) => item?.company_id === postJobData?.company_id
    )?.town;

    setPostJobData({
      ...postJobData,
      industry_id: industries?.map((item) => {
        return { label: item?.industry?.name, value: item?.industry_id };
      }),
      country_id: { label: country?.country_name, value: country?.country_id },
      town_id: { label: town?.town_name, value: town?.town_id },
    });
    if (postJobData?.company_id === "") {
      setErrors([...errors, { key: "company", message: "select company" }]);
    } else {
      setOpen(false);
      setOpenJobType(true);
    }
  };

  const handleStep2 = () => {
    if (postJobData?.job_type === "") {
      setErrors([...errors, { key: "job_type", message: "select job type" }]);
    } else {
      setOpen(false);
      setOpenJobType(false);
      setOpenPostJob(true);
    }
  };

  useEffect(() => {
    if (compname && companies?.length === 1) {
      companyFilterSelected();
    }
  }, [compname, companies]);

  useEffect(() => {
    if (compname) {
      debouncedHandleOnSelectInputChange(compname, "company_name");
    }
  }, [compname]);

  const updateDebounceFunc = async (newValue, newBasicData) => {
    let params = {
      title: newValue || "",
      limit: 200,
    };
    if (newValue) {
      const response = await getJobList(0, newBasicData);
      if (response && response?.payload) {
        const { payload } = response;
      }
    } else {
      const response = await getJobList(0, newBasicData);
      if (response && response?.payload) {
      }
    }
  };
  const debouncedFunction = useCallback(_.debounce(updateDebounceFunc, 750), []);

  const handleInputSearch = (event) => {
    const value = event?.target?.value;
    setFiltersValue((prev) => ({
      ...prev,
      quickSearch: value,
    }));
    let newBasicData = {
      ...filtersValue,
      quickSearch: value,
    };
    // setBasicData(newBasicData);

    if (setSearchTitle) {
      setSearchTitle(value);
    }
    debouncedFunction(value, newBasicData);
  };

  useEffect(() => {
    sessionStorage.setItem(`${title}`,JSON.stringify({...filtersValue}))
  }, [filtersValue]);
// useEffect(()=>{
//   if(formStateRef){
//     formStateRef.current ={...filtersValue}
//   }
// },[{...filtersValue}])
  const handleTeamMember = async (event) => {
    const {
      target: { value },
      target: { name },
      target: { id },
    } = event;

    let newBasicData = {
      ...filtersValue,
      teamMember: event?.target?.value,
    };
    setFiltersValue({
      ...filtersValue,
      teamMember: event?.target?.value,
    });
    // setBasicData(newBasicData);
    setAllJobs([]);
    await getJobList("", newBasicData);
    setOpenFilter(false);
  };

  const handleCompany = async (event, ind, newValue, val, index) => {
    let newBasicData = {
      ...filtersValue,
      companyName: ind === null ? "" : ind?.value,
    };

    if (!company) {
      setFiltersValue({
        ...filtersValue,
        companyName: ind,
      });
    }
    await getJobList("", newBasicData);
    setOpenFilter(false);
  };

  const handleJobStatus = async (event) => {
    let newBasicData = {
      ...filtersValue,
      status_id: event.target.value,
    };
    // setBasicData(newBasicData);
    setFiltersValue({
      ...filtersValue,
      jobStatus: event?.target?.value,
    });
    setAllJobs([]);
    await getJobList("", newBasicData);

    setOpenFilter(false);
  };
  const handleJobRoleChange = async (event) => {
    const {
      target: { value },
      target: { name },
      target: { id },
    } = event;

    const temp = stageArray.find((item) => item.id === value);
    let newBasicData = {
      ...filtersValue,
      jobStage: temp?.name || "",
    };
    setFiltersValue({
      ...filtersValue,
      jobStage: temp?.name,
    });
    // setBasicData(newBasicData);
    setAllJobs([]);
    await getJobList("", newBasicData);
    setOpenFilter(false);
  };
  const handleJobType = async (event) => {
    const {
      target: { value },
      target: { name },
      target: { id },
    } = event;

    const temp = jobTypeArray.find((item) => item.id === value);
    let newBasicData = {
      ...filtersValue,
      jobType: temp?.name || "",
    };
    // setBasicData(newBasicData);
    setFiltersValue({
      ...filtersValue,
      jobType: temp?.name,
    });
    setAllJobs([]);
    await getJobList("", newBasicData);
    setOpenFilter(false);
  };
  const handleClearBtn = () =>{
    setFiltersValue({
      quickSearch: "",
      jobStage: "",
      jobType: "",
      companyName: "",
      jobStatus: "",
    })
    getJobList("", {
      quickSearch: "",
      jobStage: "",
      jobType: "",
      companyName: "",
      jobStatus: "",
    });
    setOpenFilter(false)
  }
  useEffect(()=>{
    let result = Object.keys(filtersValue)?.some((item)=>filtersValue[item] !== "")
  },[filtersValue])

  return (
    <>
      <Paper
        sx={{
          // p: "20px",
          borderRadius: "25px",
          mt: "55px",
          position: "absolute",
          left: "110px",
          zIndex: 1000,
          width: `calc(100% - 150px)`,
        }}
      >
        <Box sx={{display:"flex",justifyContent:"space-between"}}>
        <Button
          variant="contained"
          color="talentButton"
          sx={{
            width: "150px",
            height: "45px",
            borderRadius: "25px 0 25px 0",
            padding: "0px !important",
            cursor:"default"
          }}
        >
          {title} ({total})
        </Button>
        <Button
          variant="contained"
          color="talentButton"
          sx={{
            width: "150px",
            height: "45px",
            borderRadius: "0 25px 0 25px",
            padding: "0px !important",
          
          }}
          onClick={()=>{
            if(Object.keys(filtersValue)?.some((item)=>filtersValue[item] !== "")){
              handleClearBtn()
            }
          }}
        >
          clear all
        </Button>
        
        </Box>
        <Box sx={{ padding: "12px 14px 8px 14px" }}>
          <Box sx={{ display: "flex" }} gap={3}>
            <Paper
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "40px",
                borderRadius: "10px",
                boxShadow: "none",
                border: `1px solid ${theme.palette.grayBorder}`,
                width: "50%",
              }}
            >
              <InputBase
                id="keyword"
                onChange={(event) => {
                  handleInputSearch(event);
                }}
                value={filtersValue?.quickSearch}
                placeholder={"Enter quick search term..."}
                sx={{
                  ml: 2,
                  mr: 2,
                  width: "100%",
                }}
              />
            </Paper>
            {openFilter && (
              <Box sx={{ width: "50%" }}>
                <SelectMenu
                  clear={true}
                  name="job_role_type"
                  value={filtersValue?.jobStage}
                  onHandleChange={handleJobRoleChange}
                  options={stageArray}
                  placeholder={"Job stage"}
                  borderRadius="10px !important"
                />
              </Box>
            )}
          </Box>
          {openFilter && (
            <>
              <Box sx={{ display: "flex" }} gap={3} mt={2}>
                <Box sx={{ width: "50%" }}>
                  {value === "Recruit" || value === "Direct" ? (
                    <SelectMenu
                      readOnly={true}
                      clear={true}
                      name="job_type"
                      value={value}
                      onHandleChange={handleJobType}
                      options={jobTypeArray}
                      placeholder={"Job type"}
                      borderRadius="10px !important"
                    />
                  ) : (
                    <SelectMenu
                      clear={true}
                      name="job_type"
                      value={filtersValue?.jobType}
                      onHandleChange={handleJobType}
                      options={jobTypeArray}
                      placeholder={"Job type"}
                      borderRadius="10px !important"
                    />
                  )}
                </Box>
                <Box sx={{ width: "50%" }}>
                  {/* {decodedToken?.data?.role_id === 1 ? (
                    <CustomSelect
                      variant="default"
                      onInputChange={handleOnSelectInputChange}
                      onChange={handleCompany}
                      placeholder={"Select or add the name of the company"}
                      inputId="company_name"
                      name="company_name"
                      value={filtersValue?.companyName}
                      onMenuOpen={onMenuOpen}
                      onMenuClose={onMenuClose}
                      option={companies?.map((item) => ({
                        value: item?.company_id,
                        label: item?.name,
                      }))}
                      isLoading={isLoading}
                      customStyle={{
                        menu: (provided) => ({
                          ...provided,
                          zIndex: 9999,
                        }),
                        menuList: (provided) => ({
                          ...provided,
                          "&::-webkit-scrollbar": {
                            width: "6px",
                            height: "6px",
                            borderRadius: "2.5px",
                            backgroundColor: "#efefef",
                          },
                          "&::-webkit-scrollbar-thumb": {
                            borderRadius: "2.5px",
                          },
                        }),
                        option: (
                          styles,
                          { data, isDisabled, isFocused, isSelected }
                        ) => {
                          return {
                            ...styles,
                            color: isSelected ? "#fff" : "black",
                            ":active": {
                              ...styles[":active"],
                            },
                          };
                        },
                        control: (baseStyles) => ({
                          ...baseStyles,
                          boxShadow: "none",
                          borderRadius: "10px",
                          height: `40px`,
                          border: "1px solid #E0E0E0",
                          "&:hover": "1px solid #E0E0E0",
                          width: `100%`,
                          "& .css-1jqq78o-placeholder": {
                            color: "lightgray !important",
                          },
                        }),
                      }}
                    />
                  ) : (
                    <SelectMenu
                      clear={true}
                      name="team_member"
                      value={filtersValue?.teamMember}
                      onHandleChange={handleTeamMember}
                      options={teammember?.length > 0 && teammember}
                      placeholder={"Team members"}
                      onOpen={() => getTeamMember("")}
                      handleScroll={(event) => handleScroll(event)}
                      borderRadius="10px !important"
                    />
                  )} */}
                </Box>
              </Box>
              <Box sx={{ display: "flex" }} gap={3} mt={2}>
                <Box sx={{ width: "50%" }}>
                  {value === "pending" ||
                  value === "active" ||
                  value === "paused" ||
                  value === "closed" ? (
                    <SelectMenu
                      readOnly={true}
                      clear={true}
                      name="job_status"
                      value={value}
                      onHandleChange={handleJobStatus}
                      options={jobStatus}
                      placeholder={"Job status"}
                      borderRadius="10px !important"
                    />
                  ) : (
                    <SelectMenu
                      clear={true}
                      name="job_status"
                      value={filtersValue?.jobStatus}
                      onHandleChange={handleJobStatus}
                      options={jobStatus}
                      placeholder={"Job status"}
                      borderRadius="10px !important"
                    />
                  )}
                </Box>
                <Box sx={{ width: "50%" }}>
                  {decodedToken?.data?.role_id === 1 && (
                    <SelectMenu
                      clear={true}
                      readOnly={true}
                      name="recruiter"
                      onHandleChange={handleRecruiter}
                      placeholder={"Recruiter"}
                      borderRadius="10px !important"
                    />
                  )}
                </Box>
              </Box>
            </>
          )}
        </Box>
        {(decodedToken?.data?.role_id === 1 ||
          decodedToken?.data?.role_id === 2) && (
          <Box sx={{ textAlign: "end" }}>
            <Button
              variant="contained"
              color="talentButton"
              sx={{
                width: "150px",
                height: "45px",
                borderRadius: "25px 0 25px 0",
                padding: "0px !important",
              }}
              onClick={() => setOpen(true)}
            >
              post a job
            </Button>
          </Box>
        )}
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            position: "absolute",
          }}
        >
          <Button
            variant="contained"
            color="talentButton"
            sx={{
              borderRadius: "0px 0px 10px 10px ",
              width: 75,
              height: 20,
            }}
            onClick={() => setOpenFilter((prev) => !prev)}
          >
            <Box
              component={"img"}
              src={openFilter ? upClose : downClose}
              sx={{
                height: 30,
                width: 30,
              }}
            />
          </Button>
        </Box>
      </Paper>

      {open && (
        <PostAsPopUp
          openEdit={open}
          handleClose={() => {
            setOpen(false);
            setErrors([]);
          }}
          setPostJobData={setPostJobData}
          postJobData={postJobData}
          errors={errors}
          handleNextStep={() => {
            handleStep1();
          }}
          companyList={companyList}
          setCompanyList={setCompanyList}
        />
      )}
      {openJobType && (
        <JobType
          openEdit={openJobType}
          handleClose={() => {
            setOpenJobType(false);
            setErrors([]);
          }}
          handleBack={() => {
            setOpenJobType(false);
            setOpen(true);
            setErrors([]);
          }}
          errors={errors}
          setPostJobData={setPostJobData}
          postJobData={postJobData}
          handleNextStep={() => handleStep2()}
        />
      )}
      {openPostJob && (
        <PostJob
          show={openPostJob}
          handleClose={() => handleClose()}
          setPostJobData={setPostJobData}
          postJobData={postJobData}
          getJobList={getJobList}
          basicData={basicData}
        />
      )}
    </>
  );
}
