import React from "react";
import CustomDialog from "../../../common/CustomDialog";
import { Box, Button, Switch, Typography } from "@mui/material";
import styled from "@emotion/styled";
import { alpha } from "@material-ui/core";
import RedTriangle from "../../../../assets/Characters/Red_Triangle_Blank.svg";

const BlueSwitch = styled(Switch)(({ theme }) => ({
  "& .MuiSwitch-switchBase.Mui-checked": {
    color: theme.palette.greenButton.main,
    "&:hover": {
      backgroundColor: alpha(
        theme.palette.greenButton.main,
        theme.palette.action.hoverOpacity
      ),
    },
  },
  "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
    backgroundColor: theme.palette.greenButton.main,
  },
  "& .MuiSwitch-track": {
    margin: "auto",
    height: "60% !important",
  },
  "& .MuiSwitch-thumb": {
    borderRadius: "6px !important",
  },
  "& .MuiButtonBase-root-MuiSwitch-switchBase": {
    borderRadius: "15% !important",
  },
  // "& .MuiSwitch-thumb": {
  //   borderRadius: "6px !important",
  // },
}));

export default function PinConfirmation({
  show,
  handleClose,
  handleDelete,
  dialogText,
  confirmPin,
  setConfirmPin,
  text = "pin",
}) {
  const handleDeleteConfirm = (event) => {
    setConfirmPin(event.target.checked);
  };
  return (
    <CustomDialog
      show={show}
      hideButton={false}
      dialogWidth="xs"
      showFooter={false}
      onDialogClose={handleClose}
      padding={0}
    >
      <Box
        sx={{
          padding: 4,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Box
          component={"img"}
          src={RedTriangle}
          sx={{ width: 150, height: 100 }}
        />
        <Typography
          fontSize={16}
          fontWeight={"bold"}
          textAlign={"center"}
          paragraph
        >
          Please confirm that you want to {text} the selected {dialogText}
        </Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography>Click to confirm</Typography>
          <BlueSwitch
            checked={confirmPin}
            onChange={(event) => handleDeleteConfirm(event)}
          />
        </Box>
      </Box>
      <Box>
        <Button
          sx={{
            boxShadow: 0,
            fontSize: "14px",
            width: "50%",
            borderRadius: 0,
            height: "43px",
            background: "lightgray",
            color: "black",
            padding: 3,
            ":hover": {
              background: "lightgray",
            },
          }}
          variant="contained"
          onClick={handleClose}
        >
          cancel
        </Button>
        <Button
          sx={{
            boxShadow: 0,
            fontSize: "14px",
            width: "50%",
            borderRadius: 0,
            height: "43px",
            padding: 3,
          }}
          variant="contained"
          color="redButton100"
          onClick={handleDelete}
          disabled={!confirmPin}
        >
          continue
        </Button>
      </Box>
    </CustomDialog>
  );
}
