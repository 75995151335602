import {
  Box,
  Button,
  CircularProgress,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import Ozow from "../../../../assets/Crayon_Favicon.svg";
import InfiniteScroll from "react-infinite-scroll-component";
import { CheckCircle } from "@mui/icons-material";
import TalentSVGButton from "../../../common/TalentSVGButton";
import cancel from "../../../../assets/CircularIcon/Red/Circular Icons__Red_Close_2.svg";
import locale from "../../../../i18n/locale";
import { useDispatch } from "react-redux";
import { setAlert } from "../../../../redux/configSlice";
import {
  addTalentPool,
  setCandidateID,
  setCreatePool,
} from "../../../../redux/admin/jobsSlice";
import { ALERT_TYPE } from "../../../../utils/Constants";
import { useNavigate } from "react-router-dom";
import linkToPool from "../../../../assets/Black_Add_To_Pool.svg";

export default function LinkToPoolBox({
  talentData,
  userID,
  getTalent,
  lastKey,
  totalPoolData,
  removeFromPool,
  setTableData,
  poolIDRef,
  setButtonDisable,
  addToPool,
  setAnchorEl,
  apiPayload,
  buttonDisable,
}) {
  const i18n = locale.en;
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const addToPoolRef = useRef(false);

  const handleClick = () => {
    if (apiPayload) {
      navigate("/admin/admin-talent/talent-pool", {
        state: {
          payload: apiPayload,
        },
      });
    } else {
      navigate("/admin/admin-talent/talent-pool");
    }
    dispatch(setCreatePool(true));
    dispatch(setCandidateID(userID));
  };

  const handleCancel = (removePool) => {
    setButtonDisable(true);
    setTableData(
      talentData?.map((item) =>
        item?.pool_id === removePool?.pool_id
          ? {
              ...item,
              poolUserFlag: false,
              addToPoolFlag: false,
              removePoolFlag: item?.poolUserFlag ? true : false,
            }
          : item
      )
    );
  };

  return (
    <>
      <Button
        variant="contained"
        color="recordVideoButton"
        sx={{ width: "150px", height: "45px", borderRadius: "25px 0 25px 0" }}
        startIcon={
          <Box
            component={"img"}
            src={linkToPool}
            sx={{
              width: 20,
              height: 20,
            }}
          />
        }
      >
        link to pool
      </Button>
      <Box
        sx={{
          padding: "12px 20px !important",
        }}
      >
        <Typography
          sx={{ fontSize: "12px", fontWeight: theme.typography.Regular }}
        >
          {i18n["pendingJobs.selectorsearch"]}
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            overflowY: "auto",
            height: "380px",
            "::-webkit-scrollbar": {
              height: "5px",
              background: "#DCDEEB",
              display: "unset !important",
            },
          }}
          id="matchTaleneList"
        >
          <InfiniteScroll
            style={{
              overflowX: "hidden",
              scrollbarWidth: "thin",
            }}
            dataLength={talentData?.length || 0}
            next={() => getTalent(lastKey)}
            hasMore={talentData?.length < totalPoolData ? true : false}
            loader={
              <Box
                style={{
                  margin: "15px 0 0 0",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CircularProgress color="inherit" size={20} />
              </Box>
            }
            scrollableTarget="matchTaleneList"
            scrollThreshold={"50px"}
          >
            {talentData?.length > 0 ? (
              talentData?.map((item, index) => {
                return (
                  <Box sx={{ display: "flex", mt: 2 }}>
                    <Box
                      component="img"
                      className="profileAvatar"
                      alt="crayon logo"
                      src={
                        item?.profile_url !== "No URL"
                          ? item?.profile_url
                          : Ozow
                      }
                      sx={{
                        mr: 1,
                        height: "35px !important",
                        width: "35px !important",
                        borderRadius: "5px",
                      }}
                    />
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "12px",
                          fontWeight: 700,
                          cursor: "pointer",
                          ml: "5px",
                        }}
                        key={index}
                        onClick={() => {
                          if (!item?.addToPoolFlag) {
                            poolIDRef.current = item?.pool_id;
                            setButtonDisable(true);
                            setTableData((prev) =>
                              prev?.map((existingItem) =>
                                existingItem?.pool_id === item?.pool_id
                                  ? {
                                      ...existingItem,
                                      addToPoolFlag: true,
                                      removePoolFlag: false,
                                      // poolUserFlag: true,
                                    }
                                  : existingItem
                              )
                            );
                          }
                        }}
                      >
                        {item?.title}
                      </Typography>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        {item?.poolUserFlag && !item?.addToPoolFlag && (
                          <TalentSVGButton
                            padding={"0px !important"}
                            color={"white"}
                            source={cancel}
                            height={28}
                            width={23}
                            onClick={() => handleCancel(item)}
                          />
                        )}
                        {item?.addToPoolFlag && (
                          <>
                            <CheckCircle
                              sx={{
                                fontSize: 27,
                                cursor: "pointer",
                              }}
                              color="checkColor"
                            />
                            <TalentSVGButton
                              padding={"0px !important"}
                              color={"white"}
                              source={cancel}
                              height={28}
                              width={23}
                              onClick={() => handleCancel(item)}
                            />
                          </>
                        )}
                      </Box>
                    </Box>
                  </Box>
                );
              })
            ) : (
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <Typography>No Pools</Typography>
              </Box>
            )}
          </InfiniteScroll>
        </Box>
      </Box>
      <Box>
        <Button
          variant="contained"
          sx={{
            width: "50%",
            height: "60px",
            borderRadius: "0 0 0 25px",
            color: "black",
          }}
          color={"closeButton"}
          onClick={() => handleClick()}
        >
          {i18n["popupBoxText.createandadd"]}
        </Button>
        <Button
          variant="contained"
          sx={{
            width: "50%",
            height: "60px",
            borderRadius: "0 0 25px 0px",
          }}
          color={"redButton100"}
          onClick={() => {
            buttonDisable && addToPool(userID, poolIDRef?.current, true);
          }}
        >
          {i18n["popupBoxText.addtopool"]}
        </Button>
      </Box>
    </>
  );
}
