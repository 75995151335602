import { Box, Button, useTheme } from "@mui/material";
import React, { useState } from "react";

const HoverBtn = ({
  color,
  icon,
  hoverIcon,
  clickFunction,
  btnContent,
  height,
  width,
  marginLeft = "0px !important",
  marginRight = "8px !important",
}) => {
  const theme = useTheme();
  const [isHovered, setIsHovered] = useState(false);
  const toggleHover = () => {
    setIsHovered((prevState) => !prevState);
  };
  return (
    <Button
      sx={{
        boxShadow: 0,
        fontSize: theme.typography.fontSize.sm,
        fontWeight: theme.typography.Bold,
        height: "60px",
        borderRadius: 0,
        padding: "20px 15px 20px 25px",
        justifyContent: "start",
        "& .MuiButton-startIcon": {
          marginLeft: marginLeft,
          marginRight: marginRight,
        },
      }}
      // color="grayButton100"
      onMouseEnter={toggleHover}
      onMouseLeave={toggleHover}
      startIcon={
        <Box
          component={"img"}
          src={hoverIcon}
          height={height}
          width={width}
          style={{
            transition: "fill 0.3s ease", // Add a transition for a smooth color change
            "&:hover": {
              fill: "#FFFFFF", // Change the fill color on hover
            },
          }}
        />
      }
      onClick={clickFunction}
      variant="contained"
      color={color}
    >
      {btnContent}
    </Button>
  );
};

export default HoverBtn;
