import { Avatar, Box, Button, Typography, useTheme } from "@mui/material";
import React, { useState } from "react";
import { CheckCircle } from "@mui/icons-material";
import useViewport from "../../ViewPort";
import CrayonDirectPlan from "../../../../assets/Plan/CrayonDirectPlan.svg";
import CrayonRecruit from "../../../../assets/Plan/CrayonRecruit.svg";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import KeyboardArrowUpRoundedIcon from "@mui/icons-material/KeyboardArrowUpRounded";
import lockIcon from "../../../../assets/Padding Excluded/Black_Lock_new.svg";
import unlockIcon from "../../../../assets/Padding Excluded/Black_Unlock_new.svg";
import { setOpenCoinBox } from "../../../../redux/candidate/alerts";
import { useDispatch } from "react-redux";
import flip from "../../../../assets/Crayon_User_Lite.svg";
import candidateTalent from "../../../../assets/TopBarIcons/CrayonTalentIcon.svg";


const MyPlanNew = ({
  newCardWidth,
  planData,
  setUnlockCV,
  handleUnlock,
  isShowBorder,
}) => {
  const theme = useTheme();
  const { width } = useViewport();
  const [isDirectExpand, setIsDirectExpand] = useState(false);
  const [isRecruitExpand, setIsRecruitExpand] = useState(false);
  const dispatch = useDispatch();
  const handleTellMeMoreDirect = () => {
    setIsDirectExpand((prev) => !prev);
  };
  const handleUserLicenseDirect = () => {};
  const handleTellMeMoreRecruit = () => {
    setIsRecruitExpand((prev) => !prev);
  };
  const handleUserLicenseRecruit = () => {};
  const handleAddCoinDirect = () => {};
  const handleAddCoinRecruit = () => {};

  return (
    <Box
      sx={{
        display: "flex",
        height: "100%",
        //  {
        // xs: `calc(100vh - 95px)`,
        // sm: `calc(100vh - 120px )`,
        // md: `calc(100vh - 120px)`,
        // lg: `calc(100vh - 135px)`,
        // xl: `calc(100vh - 155px)`,
        // xxl: "calc(100vh - 175px)",
        // },
      }}
    >
      <Box
        flexDirection={{ xs: "column", lg: "row" }}
        sx={{
          width: "100%",
          display: "flex",
          gap: "25px",
          paddingBottom: "30px",
          height: { xs: "auto", md: "calc(100% - 10%)" },
          // height: { xs: "auto", },
          // marginBottom:"100px"
          //  marginTop: width <= 768 || width !== 0 ? "55px" : "55px",
        }}
      >
        <Box
          sx={{
            background: "#FFF",
            boxShadow: "0px 3px 6px #00000029",
            width: { xs: "100%", lg: "50%" },
            boxSizing: "border-box",
            borderRadius: planData?.current_plan !== "talent" ? "32px" : "25px",
            display: "flex",
            border:
              isShowBorder && planData?.current_plan !== "talent"
                ? `5px solid ${theme.palette.tymeColor.main}`
                : `0px solid transparent`,
            // borderBottom: planData?.current_plan !== "talent"? "" : 0,
            flexDirection: "column",
            height: isDirectExpand ? "fit-content" : "auto",
          }}
        >
          <Button
            variant="contained"
            color="tymeColor"
            sx={{
              borderRadius: "25px 0 10px 0",
              width: "150px",
              height: "45px",
              minHeight: "45px",
              maxHeight: "45px",
              fontSize: "14px",
              gap: "2px",
              padding: "6px 10px",
              fontWeight: theme.typography.Bold,
            }}
          >
            <Box
              component={"img"}
              src={flip}
              sx={{
                height: "20px",
                width: "24px",
              }}
            />
            Crayon Lite
          </Button>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              flex: "1",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                width: { xs: "95%", mlg: "70%" },

                // flex: ".6",
                // height:isDirectExpand?"75%":"55%",
                height: "100%",
                justifyContent: "space-between",
              }}
            >
              <Typography
                sx={{
                  fontSize: "20px",
                  fontWeight: theme.typography.Bold,
                  marginBottom: "20px",
                }}
              >
                Our kick-off offering!
              </Typography>
              <Avatar src={CrayonDirectPlan} sx={{ width: 145, height: 125 }} />

              <Typography
                sx={{
                  fontSize: "14px",
                  fontWeight: theme.typography.Regular,
                  marginBottom: "15px",
                  marginTop: "15px",
                  textAlign: "center",
                }}
              >
                Crayon Lite is our free, pay-as-you-grow base package minus the
                value-add of Crayon Talent. You are able to post and manage
                jobs, add additional users and invite your team members to
                promote and share jobs via Crayon Bounty.
              </Typography>
              <Typography
                sx={{
                  fontWeight: theme.typography.Regular,
                  fontSize: "14px",
                  marginBottom: "15px",
                  textAlign: "center",
                }}
              >
                You currently have{" "}
                <span style={{ fontWeight: "700", fontSize: "14px" }}>
                  {" "}
                  {planData?.your_plan?.total_plan_license
                    ? planData?.your_plan?.total_plan_license
                    : 0}{" "}
                </span>{" "}
                active user licenses assigned to your company account.
              </Typography>
              <Box
                sx={{
                  fontWeight: theme.typography.Regular,
                  fontSize: "14px",
                  marginBottom: "15px",
                  textAlign: "center",
                }}
              >
                You currently have{" "}
                <span style={{ fontWeight: "700", fontSize: "14px" }}>
                  {planData?.coins === null
                    ? 0
                    : planData?.coins === 0
                    ? 0
                    : planData?.coins}
                </span>{" "}
                coins in your Crayon wallet.
              </Box>
              {isDirectExpand ? (
                <Box sx={{ display: "" }}>
                  <Box
                    sx={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Box
                      sx={{
                        fontWeight: theme.typography.Regular,
                        fontSize: "14px",
                      }}
                    >
                      Free job postings via Crayon Direct or Recruit
                    </Box>
                    <Box>
                      <CheckCircle
                        sx={{
                          fontSize: 27,
                          cursor: "pointer",
                        }}
                        color="checkColor"
                      />
                    </Box>
                  </Box>
                  <Box
                    sx={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Box
                      sx={{
                        fontWeight: theme.typography.Regular,
                        fontSize: "14px",
                      }}
                    >
                      Drag-and-drop job management dashboard ATS
                    </Box>
                    <Box>
                      <CheckCircle
                        sx={{
                          fontSize: 27,
                          cursor: "pointer",
                        }}
                        color="checkColor"
                      />
                    </Box>
                  </Box>
                  <Box
                    sx={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Box
                      sx={{
                        fontWeight: theme.typography.Regular,
                        fontSize: "14px",
                      }}
                    >
                      Add company and job videos
                    </Box>
                    <Box>
                      <CheckCircle
                        sx={{
                          fontSize: 27,
                          cursor: "pointer",
                        }}
                        color="checkColor"
                      />
                    </Box>
                  </Box>
                  <Box
                    sx={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Box
                      sx={{
                        fontWeight: theme.typography.Regular,
                        fontSize: "14px",
                      }}
                    >
                      Access to auto-matched candidates
                    </Box>
                    <Box>
                      <CheckCircle
                        sx={{
                          fontSize: 27,
                          cursor: "pointer",
                        }}
                        color="checkColor"
                      />
                    </Box>
                  </Box>
                  <Box
                    sx={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Box
                      sx={{
                        fontWeight: theme.typography.Regular,
                        fontSize: "14px",
                      }}
                    >
                      Build a talent pool and company following
                    </Box>
                    <Box>
                      <CheckCircle
                        sx={{
                          fontSize: 27,
                          cursor: "pointer",
                        }}
                        color="checkColor"
                      />
                    </Box>
                  </Box>
                  <Box
                    sx={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Box
                      sx={{
                        fontWeight: theme.typography.Regular,
                        fontSize: "14px",
                      }}
                    >
                      Crayon Bounty referral sharing and tracking
                    </Box>
                    <Box>
                      <CheckCircle
                        sx={{
                          fontSize: 27,
                          cursor: "pointer",
                        }}
                        color="checkColor"
                      />
                    </Box>
                  </Box>
                  <Box
                    sx={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Box
                      sx={{
                        fontWeight: theme.typography.Regular,
                        fontSize: "14px",
                      }}
                    >
                      Add team members and control who sees what
                    </Box>
                    <Box>
                      <CheckCircle
                        sx={{
                          fontSize: 27,
                          cursor: "pointer",
                        }}
                        color="checkColor"
                      />
                    </Box>
                  </Box>
                  <Box
                    sx={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Box
                      sx={{
                        fontWeight: theme.typography.Regular,
                        fontSize: "14px",
                      }}
                    >
                      Shortlist candidates from the Talent page
                    </Box>
                    <Box>
                      <CheckCircle
                        sx={{
                          fontSize: 27,
                          cursor: "pointer",
                        }}
                        color="checkColor"
                      />
                    </Box>
                  </Box>
                  <Box
                    sx={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Box
                      sx={{
                        fontWeight: theme.typography.Regular,
                        fontSize: "14px",
                      }}
                    >
                      Access to the advanced admin panel
                    </Box>
                    <Box>
                      <CancelRoundedIcon
                        sx={{
                          fontSize: 27,
                          cursor: "pointer",
                        }}
                        color="nandos"
                      />
                    </Box>
                  </Box>
                  <Box
                    sx={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Box
                      sx={{
                        fontWeight: theme.typography.Regular,
                        fontSize: "14px",
                      }}
                    >
                      Reduced success fees and hiring credit
                    </Box>
                    <Box>
                      <CancelRoundedIcon
                        sx={{
                          fontSize: 27,
                          cursor: "pointer",
                        }}
                        color="nandos"
                      />
                    </Box>
                  </Box>
                  <Box
                    sx={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Box
                      sx={{
                        fontWeight: theme.typography.Regular,
                        fontSize: "14px",
                      }}
                    >
                      Unlimited platform usage
                    </Box>
                    <Box>
                      {" "}
                      <CancelRoundedIcon
                        sx={{
                          fontSize: 27,
                          cursor: "pointer",
                        }}
                        color="nandos"
                      />
                    </Box>
                  </Box>
                  <Box
                    sx={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Box
                      sx={{
                        fontWeight: theme.typography.Regular,
                        fontSize: "14px",
                      }}
                    >
                      Invites to monthly Crayon events (FOMO)
                    </Box>
                    <Box>
                      <CancelRoundedIcon
                        sx={{
                          fontSize: 27,
                          cursor: "pointer",
                        }}
                        color="nandos"
                      />
                    </Box>
                  </Box>
                </Box>
              ) : (
                ""
              )}
            </Box>
            <Box
              sx={{
                background: theme?.palette?.DefaultToggleColor,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                borderRadius: "0 0 25px 25px",
                // flex:".4"
                justifyContent: "space-between",
                // height:isDirectExpand?"25%":"45%"
                height: "265px",
                minHeight: "265px",
                maxHeight: "265px",
              }}
            >
              <Box
                sx={{
                  fontSize: "20px",
                  fontWeight: theme.typography.Bold,
                  marginBottom: "10px",
                  textAlign: "center",
                  marginTop: { xs: "15px", mlg: "25px" },
                  width: { xs: "95%", mlg: "90%" },
                }}
              >
                You need fresh talent, yesterday.
              </Box>
              <Box
                sx={{
                  fontWeight: theme.typography.Regular,
                  fontSize: "14px",
                  marginBottom: "20px",
                  textAlign: "center",
                  width: { xs: "95%", mlg: "90%" },
                }}
              >
                Crayon Lite is where your talent journey begins and gives you
                free access to job postings, company and job videos, and team
                management. Job, CV and video unlocks work on a pay-to-play
                model using Crayon Coins.
              </Box>
              <Box
                sx={{
                  fontSize: "20px",
                  fontWeight: theme.typography.Bold,
                  marginBottom: { xs: "15px", mlg: "25px" },

                  textAlign: "center",
                  width: { xs: "95%", mlg: "90%" },
                }}
              >
                All this value, for free
              </Box>
              <Box>
                {isDirectExpand ? (
                  <Button
                    variant="contained"
                    color="discovery"
                    sx={{
                      width: "150px",
                      height: "55px",
                      borderRadius: "10px 0 0 0",
                    }}
                    onClick={() => dispatch(setOpenCoinBox(true))}
                  >
                    add coin
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    color="tymeColor"
                    sx={{
                      width: "150px",
                      height: "55px",
                      borderRadius: "10px 0 0 0",
                    }}
                    onClick={handleTellMeMoreDirect}
                  >
                    tell me more
                  </Button>
                )}

                <Button
                  variant="contained"
                  color="nandos"
                  sx={{
                    width: "150px",
                    height: "55px",
                    borderRadius: "0 10px 0 0",
                  }}
                  onClick={() => setUnlockCV(true)}
                >
                  user license
                </Button>
                {isDirectExpand && (
                  <Button
                    onClick={() => {
                      setIsDirectExpand(false);
                    }}
                  >
                    <KeyboardArrowUpRoundedIcon
                      sx={{
                        fontSize: 27,
                        cursor: "pointer",
                        color: "black",
                      }}
                    />
                  </Button>
                )}
              </Box>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            background: "#FFF",
            boxShadow: "0px 3px 6px #00000029",
            width: { xs: "100%", lg: "50%" },
            borderRadius: planData?.current_plan === "talent" ? "32px" : "25px",
            border:
              isShowBorder && planData?.current_plan === "talent"
                ? `5px solid ${theme.palette.nandos.main}`
                : `0px solid transparent`,
            // borderBottom: planData?.current_plan === "talent"? "" : 0,
            display: "flex",
            flexDirection: "column",
            height: isRecruitExpand ? "fit-content" : "auto",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Button
              variant="contained"
              color="nandos"
              sx={{
                borderRadius: "25px 0 10px 0",
                width: "150px",
                height: "45px",
                minHeight: "45px",
                maxHeight: "45px",
                fontSize: "14px",
                gap: "2px",
                padding: "6px 6px",
                fontWeight: theme.typography.Bold,
              }}
            >
              <Box
                component={"img"}
                src={candidateTalent}
                sx={{
                  height: "20px",
                  width: "24px",
                }}
              />
              Crayon Talent
            </Button>
            {planData?.current_plan !== "talent" ? (
              <Box
                component={"img"}
                sx={{
                  height: 30,
                  width: 30,
                  marginRight: "30px",
                }}
                src={lockIcon}
              />
            ) : (
              <Box
                component={"img"}
                sx={{
                  height: 30,
                  width: 30,
                  marginRight: "30px",
                }}
                src={unlockIcon}
              />
            )}
          </Box>

          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              flex: "1",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                width: { xs: "95%", mlg: "70%" },

                // flex: ".6",
                // height:isRecruitExpand?"75%":"55%",
                height: "100%",
                justifyContent: "space-between",
              }}
            >
              <Typography
                sx={{
                  fontSize: "20px",
                  fontWeight: theme.typography.Bold,
                  marginBottom: "20px",
                }}
              >
                Our value offering!
              </Typography>
              <Avatar src={CrayonRecruit} sx={{ width: 145, height: 125 }} />

              <Typography
                sx={{
                  fontSize: "14px",
                  fontWeight: theme.typography.Regular,
                  marginBottom: "15px",
                  marginTop: "15px",
                  textAlign: "center",
                }}
              >
                {planData?.current_plan !== "talent"
                  ? "Crayon Talent unlocks the full power of the platform! You’ll gain access to our global pool of talent with enhanced search features, talent pools, and reduced hiring fees!"
                  : "You’ve unlocked the full power of the platform! You have access to our global pool of talent with unlimited job unlocks, enhanced search features, talent pools, and reduced hiring fees!"}
              </Typography>
              {planData?.current_plan !== "talent" ? (
                <Box
                  sx={{
                    fontWeight: theme.typography.Regular,
                    fontSize: "14px",
                    marginBottom: "15px",
                    textAlign: "center",
                  }}
                >
                  Based on your company’s talent pool (applicants and followers)
                  of
                  <span style={{ fontWeight: "700", fontSize: "14px" }}>
                    {" "}
                    {planData?.mytalentCount}{" "}
                  </span>
                  ,you would upgrade onto our{" "}
                  <span style={{ fontWeight: "700", fontSize: "14px" }}>
                    {" "}
                    {planData?.crayon_plan?.crayon_plan}{" "}
                  </span>{" "}
                  pricing package.
                </Box>
              ) : (
                <>
                  <Typography
                    sx={{
                      fontWeight: theme.typography.Regular,
                      fontSize: "14px",
                      marginBottom: "15px",
                      textAlign: "center",
                    }}
                  >
                    Based on your company’s talent pool (applicants and
                    followers) of{" "}
                    <span
                      style={{
                        fontSize: "14px",
                        fontWeight: theme.typography.Bold,
                      }}
                    >
                      {" "}
                      {planData?.mytalentCount}{" "}
                    </span>
                    , you are currently on our{" "}
                    <span
                      style={{
                        fontSize: "14px",
                        fontWeight: theme.typography.Bold,
                      }}
                    >
                      {" "}
                      {planData?.your_plan?.crayon_plan}{" "}
                    </span>{" "}
                    pricing package, which includes{" "}
                    <span
                      style={{
                        fontSize: "12px",
                        fontWeight: theme.typography.Bold,
                      }}
                    >
                      {" "}
                      {planData?.crayon_plan?.total_plan_license}{" "}
                    </span>{" "}
                    long-term user licenses.
                  </Typography>
                  <Typography
                    sx={{
                      fontWeight: theme.typography.Regular,
                      fontSize: "14px",
                      marginBottom: "5px",
                      textAlign: "center",
                    }}
                  >
                    You can add additional long-term user licenses below, or
                  </Typography>
                </>
              )}

              {isRecruitExpand ? (
                <Box sx={{ display: "" }}>
                  <Box
                    sx={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Box
                      sx={{
                        fontWeight: theme.typography.Regular,
                        fontSize: "14px",
                      }}
                    >
                      Everything offered as part of Crayon Lite
                    </Box>
                    <Box>
                      <CheckCircle
                        sx={{
                          fontSize: 27,
                          cursor: "pointer",
                        }}
                        color="nandos"
                      />
                    </Box>
                  </Box>
                  <Box
                    sx={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Box
                      sx={{
                        fontWeight: theme.typography.Regular,
                        fontSize: "14px",
                      }}
                    >
                      Access to our global pool of talent
                    </Box>
                    <Box>
                      <CheckCircle
                        sx={{
                          fontSize: 27,
                          cursor: "pointer",
                        }}
                        color="nandos"
                      />
                    </Box>
                  </Box>
                  <Box
                    sx={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Box
                      sx={{
                        fontWeight: theme.typography.Regular,
                        fontSize: "14px",
                      }}
                    >
                      Grow and manage your brand-specific talent pool
                    </Box>
                    <Box>
                      <CheckCircle
                        sx={{
                          fontSize: 27,
                          cursor: "pointer",
                        }}
                        color="nandos"
                      />
                    </Box>
                  </Box>
                  <Box
                    sx={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Box
                      sx={{
                        fontWeight: theme.typography.Regular,
                        fontSize: "14px",
                      }}
                    >
                      Advanced search functionality
                    </Box>
                    <Box>
                      <CheckCircle
                        sx={{
                          fontSize: 27,
                          cursor: "pointer",
                        }}
                        color="nandos"
                      />
                    </Box>
                  </Box>
                  <Box
                    sx={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Box
                      sx={{
                        fontWeight: theme.typography.Regular,
                        fontSize: "14px",
                      }}
                    >
                      Unlimited CV and video unlocks
                    </Box>
                    <Box>
                      <CheckCircle
                        sx={{
                          fontSize: 27,
                          cursor: "pointer",
                        }}
                        color="nandos"
                      />
                    </Box>
                  </Box>
                  <Box
                    sx={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Box
                      sx={{
                        fontWeight: theme.typography.Regular,
                        fontSize: "14px",
                      }}
                    >
                      Auto link jobs to talent pools
                    </Box>
                    <Box>
                      <CheckCircle
                        sx={{
                          fontSize: 27,
                          cursor: "pointer",
                        }}
                        color="nandos"
                      />
                    </Box>
                  </Box>
                  <Box
                    sx={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Box
                      sx={{
                        fontWeight: theme.typography.Regular,
                        fontSize: "14px",
                      }}
                    >
                      Save up to 40% on hiring fees via Crayon Recruit
                    </Box>
                    <Box>
                      <CheckCircle
                        sx={{
                          fontSize: 27,
                          cursor: "pointer",
                        }}
                        color="nandos"
                      />
                    </Box>
                  </Box>
                  <Box
                    sx={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Box
                      sx={{
                        fontWeight: theme.typography.Regular,
                        fontSize: "14px",
                      }}
                    >
                      The ability to finance success fees over up to a year
                    </Box>
                    <Box>
                      <CheckCircle
                        sx={{
                          fontSize: 27,
                          cursor: "pointer",
                        }}
                        color="nandos"
                      />
                    </Box>
                  </Box>
                  <Box
                    sx={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Box
                      sx={{
                        fontWeight: theme.typography.Regular,
                        fontSize: "14px",
                      }}
                    >
                      Reduced minimum success fee
                    </Box>
                    <Box>
                      <CheckCircle
                        sx={{
                          fontSize: 27,
                          cursor: "pointer",
                        }}
                        color="nandos"
                      />
                    </Box>
                  </Box>
                  <Box
                    sx={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Box
                      sx={{
                        fontWeight: theme.typography.Regular,
                        fontSize: "14px",
                      }}
                    >
                      Up to R50,000 in hiring credit
                    </Box>
                    <Box>
                      <CheckCircle
                        sx={{
                          fontSize: 27,
                          cursor: "pointer",
                        }}
                        color="nandos"
                      />
                    </Box>
                  </Box>
                  <Box
                    sx={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Box
                      sx={{
                        fontWeight: theme.typography.Regular,
                        fontSize: "14px",
                      }}
                    >
                      Create and save bespoke talent pools and searches
                    </Box>
                    <Box>
                      <CheckCircle
                        sx={{
                          fontSize: 27,
                          cursor: "pointer",
                        }}
                        color="nandos"
                      />
                    </Box>
                  </Box>
                  <Box
                    sx={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Box
                      sx={{
                        fontWeight: theme.typography.Regular,
                        fontSize: "14px",
                      }}
                    >
                      Access to the Crayon Cohort and monthly events
                    </Box>
                    <Box>
                      <CheckCircle
                        sx={{
                          fontSize: 27,
                          cursor: "pointer",
                        }}
                        color="nandos"
                      />
                    </Box>
                  </Box>
                </Box>
              ) : (
                ""
              )}
            </Box>
            <Box
              sx={{
                background: theme?.palette?.DefaultToggleColor,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                borderRadius: "0 0 25px 25px",
                // flex:".4"
                // height:isRecruitExpand?"25%":"45%",
                height: "265px",
                minHeight: "265px",
                maxHeight: "265px",
                justifyContent: "space-between",
              }}
            >
              <Box
                sx={{
                  fontSize: "20px",
                  fontWeight: theme.typography.Bold,
                  marginBottom: "10px",
                  textAlign: "center",
                  marginTop: { xs: "15px", mlg: "25px" },
                  width: { xs: "95%", mlg: "90%" },
                }}
              >
                Unlock the full power of the platform.
              </Box>
              <Box
                sx={{
                  fontWeight: theme.typography.Regular,
                  fontSize: "14px",
                  marginBottom: "20px",
                  textAlign: "center",
                  width: { xs: "95%", mlg: "90%" },
                }}
              >
                Crayon Talent is built for the longevity of talent and aims to
                grow your employer brand in the local, and international talent
                marketplace. You get full access to all platform features as
                well as the global database where you can build and grow talent
                pools and create advanced searches. The monthly cost is offset
                via unlimited unlocks and reduced hiring fees.
              </Box>
              <Box
                sx={{
                  fontSize: "20px",
                  fontWeight: theme.typography.Bold,
                  marginBottom: { xs: "15px", mlg: "25px" },

                  textAlign: "center",
                  width: { xs: "95%", mlg: "90%" },
                }}
              >
                Starting from R2,950 per month.
              </Box>
              <Box>
                {isRecruitExpand ? (
                  <Button
                    variant="contained"
                    color="discovery"
                    sx={{
                      width: "150px",
                      height: "55px",
                      borderRadius: "10px 0 0 0",
                    }}
                    onClick={() => dispatch(setOpenCoinBox(true))}
                  >
                    add coin
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    color="tymeColor"
                    sx={{
                      width: "150px",
                      height: "55px",
                      borderRadius: "10px 0 0 0",
                    }}
                    onClick={handleTellMeMoreRecruit}
                  >
                    tell me more
                  </Button>
                )}
                {planData?.current_plan !== "talent" ? (
                  <Button
                    variant="contained"
                    color="nandos"
                    sx={{
                      width: "150px",
                      height: "55px",
                      borderRadius: "0 10px 0 0",
                    }}
                    onClick={handleUnlock}
                  >
                    unlock talent
                  </Button>
                ) : (
                  // <Button
                  //   variant="contained"
                  //   color="editButton"
                  //   // sx={{ width: "50%", height: "60px", borderRadius: "0" }}
                  //   sx={{
                  //     width: "150px",
                  //     height: "55px",
                  //     borderRadius: "0 10px 0 0",
                  //   }}
                  //   onClick={() => {}}
                  // >
                  //   request downgrade
                  // </Button>
                  <Button
                    variant="contained"
                    color="nandos"
                    sx={{
                      width: "150px",
                      height: "55px",
                      borderRadius: "0 10px 0 0",
                    }}
                    onClick={() => setUnlockCV(true)}
                  >
                    user license
                  </Button>
                )}
                {isRecruitExpand && (
                  <Button
                    onClick={() => {
                      setIsRecruitExpand(false);
                    }}
                  >
                    <KeyboardArrowUpRoundedIcon
                      sx={{
                        fontSize: 27,
                        cursor: "pointer",
                        color: "black",
                      }}
                    />
                  </Button>
                )}
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default MyPlanNew;
