import React, { useEffect, useState } from "react";
import ReactCardFlip from "react-card-flip";
import AddDetailFrontPage from "./AddDetailFrontPage";
import AddDetailsBackPage from "./AddDetailsBackPage";
import {
  editSecondaryCompanyData,
  getCompanies,
  getCompanyDetail,
  postSecondaryCompanyData,
  clearAllCompany,
} from "../../../../redux/employer/empProfileSlice";
import { useDispatch } from "react-redux";
import { ALERT_TYPE, MY_COMPANIES_BASIC } from "../../../../utils/Constants";
import { setAlert } from "../../../../redux/configSlice";
import jwt_decode from "jwt-decode";
import { useSelector } from "react-redux";
import { formatPhoneNumberIntl } from "react-phone-number-input";
import {
  getContactDetail,
  getUserCountryFromGeolocation,
} from "../../../../utils/Common";

const AddCompanyDetailsCard = ({
  handleSubmission,
  editCompanyInfo,
  editCompanyID,
}) => {
  const token = localStorage?.getItem("token");
  let decodedToken;
  if (token) {
    decodedToken = jwt_decode(token);
  }
  const dispatch = useDispatch();
  const { companies } = useSelector((state) => state.myProfile);
  const { country, town } = useSelector((state) => state?.postJobs);
  const { initialIndustries, companyCultures, initialCompanyCulture } =
    useSelector((state) => state?.myCv);
  const [isHorizontalFlipped, setisHorizontalFlipped] = useState(false);
  const [basicData, setBasicData] = useState(MY_COMPANIES_BASIC);
  const [initialCountryCode, setInitialCountryCode] = useState("");
  const [companyInfoCharLength, setCompanyInfoCharLength] = useState(0);
  const [isEmployerModalOpen, setIsEmployerModalOpen] = useState(false);
  const [isRecruitModalFirst, setIsRecruitModalFirst] = useState(false);
  const [isRecruitModalSecond, setIsRecruitModalSecond] = useState(false);
  
  const handlesave = async () => {
    try {
      const contactInfo = getContactDetail(
        formatPhoneNumberIntl(
          `${basicData?.country_code}${basicData?.contact_person_number}`
        )
      );
      const data = {
        ...basicData,
        industry_ids: basicData?.industry_ids?.map((item) => {
          return item?.value;
        }),
        culture_ids: basicData?.culture_ids?.map((item) => {
          return item?.value;
        }),
        country_id: basicData?.country_id?.value,
        town_id: basicData?.town_id?.value,
        company_name: basicData?.company_name?.value,
        contact_person_number: contactInfo?.[1],
        country_code: contactInfo?.[0],
      };
      const editData = {
        ...basicData,
        industry_ids: basicData?.industry_ids?.map((item) => {
          return item?.value;
        }),
        culture_ids: basicData?.culture_ids?.map((item) => {
          return item?.value;
        }),
        country_id: basicData?.country_id?.value,
        town_id: basicData?.town_id?.value,
        company_name: basicData?.company_name?.label,
        company_id: basicData?.company_id,
        contact_person_number: contactInfo?.[1],
        country_code: contactInfo?.[0],
      };
      const { payload } = await dispatch(
        editCompanyID
          ? editSecondaryCompanyData(editData)
          : postSecondaryCompanyData(data)
      );
      if (payload?.status === "success") {
        setIsRecruitModalSecond(false)
        setIsRecruitModalFirst(false)
        setIsEmployerModalOpen(false)
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE?.SUCCESS,
            msg: "Company Added successfully!",
          })
        );

        handleSubmission();
      }
    } catch (error) {
      dispatch(setAlert({ show: true, type: ALERT_TYPE?.ERROR, msg: error }));
    }
  };

  const getCompanyData = async (companyID) => {
    try {
      const data = {
        company_id: companyID ? companyID : "",
      };
      const { payload } = await dispatch(getCompanyDetail(data));
      const selectedCompanyDetails = {
        ...basicData,
        company_name: {
          value: payload?.data?.company_id,
          label: payload?.data?.name,
        },
        industry_ids: payload?.data?.employer_industries?.map((val) => ({
          value: val?.industry_id,
          label: val?.industry?.name,
        })),
        hyperlink: payload?.data?.website,
        company_type: payload?.data?.company_type,
        company_size: payload?.data?.company_size,
        notes: payload?.data?.description,
        culture_ids: payload?.data?.employer_cultures?.map((val) => ({
          value: val?.company_culture_id,
          label: val?.company_culture?.name,
        })),
        country_id: {
          value: payload?.data?.country_id,
          label: payload?.data?.country_name,
        },
        town_id: {
          value: payload?.data?.town_id,
          label: payload?.data?.town_name,
        },
      };
      setCompanyInfoCharLength(payload?.data?.description?.length);
      delete selectedCompanyDetails?.editflag;
      setBasicData(selectedCompanyDetails);
    } catch (error) {
      dispatch(
        setAlert({
          show: true,
          type: ALERT_TYPE?.ERROR,
          msg: error,
        })
      );
    }
  };
  const handleSelectedCompany = (companyId) => {
    if (companyId) {
      getCompanyData(companyId);
    }
  };

  useEffect(() => {
    // dispatch(getCompanies({ limit: 5 }));
    dispatch(clearAllCompany());
    editCompanyID &&
      setBasicData({
        ...editCompanyInfo,
        editflag: editCompanyInfo?.editFlag,
        notes: editCompanyInfo?.description,
        hyperlink: editCompanyInfo?.website,
        company_name: {
          value: editCompanyInfo?.company_id,
          label: editCompanyInfo?.name,
          companyURL: editCompanyInfo?.companyURL,
        },
      });
  }, []);

  useEffect(() => {
    getUserCountryFromGeolocation()
      .then((countryCode) => {
        setInitialCountryCode(countryCode);
      })
      .catch((error) => {
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE?.ERROR,
            msg: "Error fetching country code",
          })
        );
      });
  }, []);

  return (
    <ReactCardFlip
      isFlipped={isHorizontalFlipped}
      flipDirection={"horizontal"}
      flipSpeedBackToFront="0.5"
      flipSpeedFrontToBack="0.5"
      containerStyle={{
        height: "100%",
      }}
    >
      <AddDetailFrontPage
        basicData={basicData}
        getCompanyData={handleSelectedCompany}
        setBasicData={setBasicData}
        setIsFlipped={setisHorizontalFlipped}
        editCompanyID={editCompanyID}
        companies={companies}
        setCompanyInfoCharLength={setCompanyInfoCharLength}
        country={country}
        town={town}
        companyCultures={companyCultures}
        initialIndustries={initialIndustries}
        initialCompanyCulture={initialCompanyCulture}
        companyInfoCharLength={companyInfoCharLength}
        handlesave={handlesave}
        isEmployerModalOpen={isEmployerModalOpen}
        setIsEmployerModalOpen={setIsEmployerModalOpen}
        isRecruitModalFirst={isRecruitModalFirst}
        setIsRecruitModalFirst={setIsRecruitModalFirst}
        isRecruitModalSecond={isRecruitModalSecond}
        setIsRecruitModalSecond={setIsRecruitModalSecond}
      />
      <AddDetailsBackPage
        basicData={basicData}
        setBasicData={setBasicData}
        handleSave={handlesave}
        setIsFlipped={setisHorizontalFlipped}
        editCompanyID={editCompanyID}
        companies={companies}
        initialCountryCode={initialCountryCode}
        setCompanyInfoCharLength={setCompanyInfoCharLength}
        companyInfoCharLength={companyInfoCharLength}
      />
    </ReactCardFlip>
  );
};

export default AddCompanyDetailsCard;
