import { Box, IconButton, Modal } from "@mui/material";
import React from "react";
import profilePic from "../../../assets/user_logo.svg";

export default function ViewProfilePic({ open, handleClose, profile }) {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    height: "500px",
    width: "500px",
    bgcolor: "background.paper",
    borderRadius: "10px",
    boxShadow: "none !important",
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <IconButton
          aria-label="close"
          onClick={() => {
            handleClose();
          }}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
          disableRipple={true}
        >
          <Box
            sx={{
              width: "20px",
              height: "20px",
              color: "#000000",
              border: 2,
              fontSize: "18px",
              borderRadius: "5px",
            }}
          >
            X
          </Box>
        </IconButton>

        <Box
          component={"img"}
          src={profile !== "No URL" ? profile : profilePic}
          sx={{
            width: "500px",
            height: "500px",
            borderRadius: "10px",
          }}
        />
      </Box>
    </Modal>
  );
}
