import {
  Box,
  Button,
  InputBase,
  Paper,
  useTheme,
  Typography,
  CircularProgress,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import linkToPool from "../../../../../assets/Black_Add_To_Pool.svg";
import locale from "../../../../../i18n/locale";
import { setAlert } from "../../../../../redux/configSlice";
import { ALERT_TYPE, ERROR_MSG } from "../../../../../utils/Constants";
import { debounce } from "../../../../../utils/Common";
import { useDispatch } from "react-redux";
import InfiniteScroll from "react-infinite-scroll-component";
import TalentSVGButton from "../../../../common/TalentSVGButton";
import cancel from "../../../../../assets/CircularIcon/Red/Circular Icons__Red_Close_2.svg";
import Ozow from "../../../../../assets/Crayon_Favicon.svg";
import {
  getCompanyPool,
  setCreatePool,
  updateCompnayTalentPool,
} from "../../../../../redux/admin/jobsSlice";
import { useNavigate } from "react-router-dom";

export default function CompanyTalentPool({ companyData }) {
  const i18n = locale.en;
  const theme = useTheme();
  const dispatch = useDispatch();
  const [lastKey, setLastKey] = useState(0);
  const [talentPools, setTalentPools] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [searchText, setSearchText] = useState("");

  const getCompanyPoolList = async (lastkeyy, searchTitle) => {
    setIsLoading(true);
    try {
      const { payload } = await dispatch(
        getCompanyPool({
          lastKey: lastkeyy,
          company_id: companyData?.company_id,
          title: searchTitle,
        })
      );

      if (payload?.status === "success") {
        if (payload?.pageNumber === 0) {
          setTalentPools(payload?.data);
          setLastKey(payload?.pageNumber + 1);
        } else {
          setLastKey(payload?.pageNumber + 1);
          setTalentPools((prevState) => [...prevState, ...payload?.data]);
        }
      } else {
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE.ERROR,
            msg: "ERROR",
          })
        );
      }
      setIsLoading(false);
    } catch (error) {
      dispatch(
        setAlert({
          show: true,
          type: ALERT_TYPE.ERROR,
          msg: "ERROR",
        })
      );
    }
  };

  const removeCompanyPool = async () => {
    try {
      let poolsToBeRemoved = talentPools
        ?.filter((item) => item?.remove)
        ?.map((item) => item?.pool_id);
      let data = {
        company_id: companyData?.company_id,
        pool_ids: poolsToBeRemoved,
      };
      const { payload } = await dispatch(updateCompnayTalentPool(data));

      if (payload?.status === "success") {
        setTalentPools(talentPools.filter((item) => !item.remove));
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE?.SUCCESS,
            msg: "Pool removed successfully",
          })
        );
      } else {
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE?.ERROR,
            msg: "ERROR",
          })
        );
      }
    } catch (error) {
      dispatch(
        setAlert({
          show: true,
          type: ALERT_TYPE.ERROR,
          msg: "ERROR",
        })
      );
    }
  };

  const debouncedHandleOnTextSearch = useCallback(
    debounce(
      async (newValue) => {
        if (!newValue) return; // Early return
        try {
          setLastKey(0);
          setTalentPools([]);
          const response = await getCompanyPoolList(0, newValue?.target?.value);
          if (response && response?.payload) {
            const { payload } = response;
            dispatch(
              payload?.status === "success"
                ? setTalentPools(payload?.data)
                : setAlert({
                    show: true,
                    type: ALERT_TYPE?.ERROR,
                    msg: ERROR_MSG,
                  })
            );
          }
        } catch (error) {
          dispatch(
            setAlert({ show: true, type: ALERT_TYPE?.ERROR, msg: ERROR_MSG })
          );
        }
      },
      500, // Adjust delay as needed
      setIsLoading
    ),
    [dispatch, setTalentPools, setIsLoading]
  );

  const handleSearch = async (event) => {
    setLastKey(0);
    setSearchText(event?.target?.value);
    setTalentPools([]);
    debouncedHandleOnTextSearch(event);
  };

  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/admin/admin-talent/talent-pool", {
      state: {
        companypool: {
          company_id: companyData?.company_id,
          user_id: companyData?.user_id,
        },
      },
    });
    dispatch(setCreatePool(true));
    // dispatch(setCandidateID(userID));
  };

  useEffect(() => {
    getCompanyPoolList(0, "");
  }, []);

  return (
    <>
      <Button
        variant="contained"
        color="recordVideoButton"
        sx={{ width: "150px", height: "45px", borderRadius: "25px 0 25px 0" }}
        startIcon={
          <Box
            component={"img"}
            src={linkToPool}
            sx={{
              width: 20,
              height: 20,
            }}
          />
        }
      >
        talent pools
      </Button>
      <Box
        sx={{
          padding: "18px 31px 13px 21px !important",
        }}
      >
        <Typography
          sx={{ fontSize: "12px", fontWeight: theme.typography.Regular }}
        >
          {i18n["adminUsers.talentPooltext"]}
        </Typography>

        <Paper
          elevation={0}
          component="form"
          sx={{
            padding: "0px 10px",
            border: "1px solid #C9CAD8",
            margin: "13px 0 17px 0",
          }}
          height={"40px"}
          width={"392px"}
        >
          <InputBase
            sx={{
              fontWeight: theme.typography.Regular,
              width: "308px",
            }}
            placeholder={"Search for talent pool"}
            // value={searchText}
            onKeyDown={(event) => {
              if (event.key === "Enter") {
                event.preventDefault(); // Stop page reload
              }
            }}
            onChange={(event) => {
              handleSearch(event);
            }}
          />
        </Paper>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            overflowY: "auto",
            height: "380px",
            "::-webkit-scrollbar": {
              height: "5px",
              background: "#DCDEEB",
              display: "unset !important",
            },
          }}
          id="matchTaleneList"
        >
          <InfiniteScroll
            style={{
              overflowX: "hidden",
              scrollbarWidth: "thin",
              display: "flex",
              flexDirection: "column",
              gap: "10px",
            }}
            dataLength={talentPools?.length || 0}
            next={() => getCompanyPoolList(lastKey, searchText)}
            hasMore={true}
            scrollableTarget="matchTaleneList"
            scrollThreshold={"50px"}
          >
            {talentPools?.length > 0 ? (
              talentPools?.map((item, index) => {
                return (
                  <Box sx={{ display: "flex", mt: 2 }}>
                    <Box
                      component="img"
                      className="profileAvatar"
                      alt="crayon logo"
                      src={item?.pool_url !== "No URL" ? item?.pool_url : Ozow}
                      sx={{
                        mr: 1,
                        height: "35px !important",
                        width: "35px !important",
                        borderRadius: "5px",
                      }}
                    />
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        gap: "16px",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "12px",
                          fontWeight: 700,
                          cursor: "pointer",
                          ml: "5px",
                        }}
                        key={index}
                      >
                        {item?.title}
                      </Typography>

                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        {!item?.remove && (
                          <TalentSVGButton
                            padding={"0px !important"}
                            color={"white"}
                            source={cancel}
                            height={28}
                            width={23}
                            onClick={() =>
                              setTalentPools(
                                talentPools?.map((pool) =>
                                  pool?.pool_id === item?.pool_id
                                    ? { ...pool, remove: true }
                                    : pool
                                )
                              )
                            }
                          />
                        )}
                      </Box>
                    </Box>
                  </Box>
                );
              })
            ) : isLoading ? (
              <Box
                style={{
                  margin: "15px 0 0 0",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CircularProgress color="inherit" size={20} />
              </Box>
            ) : (
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <Typography>No Pools</Typography>
              </Box>
            )}
          </InfiniteScroll>
        </Box>
      </Box>

      <Box>
        <Button
          variant="contained"
          sx={{
            width: "50%",
            height: "60px",
            borderRadius: "0 0 0 25px",
            color: "black",
          }}
          color={"closeButton"}
          onClick={() => handleClick()}
        >
          {i18n["adminUsers.createandadd"]}
        </Button>
        <Button
          variant="contained"
          sx={{
            width: "50%",
            height: "60px",
            borderRadius: "0 0 25px 0px",
          }}
          color={"redButton100"}
          onClick={() => {
            removeCompanyPool();
          }}
        >
          {i18n["adminUsers.updatepools"]}
        </Button>
      </Box>
    </>
  );
}
