import { Modal, Slider } from "@mui/material";
import React, { useRef, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import locale from "../../../i18n/locale";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";

const CropModal = ({
  handleOpen,
  handleClose,
  handleSubscriptionOk,
  handleSubscriptionCancel,
  imagePreview,
  image,
  setImage,
  croppedImageUrl,
  setCroppedImageUrl,
  imageBlob,
  setImageBlob,
}) => {
  const cropperRef = useRef(null);

  const [zoom, setZoom] = useState(0); 

  const handleZoomChange = (event, newZoom) => {
    setZoom(newZoom);
    const cropper = cropperRef?.current?.cropper;
    if (cropper) {
      cropper?.zoomTo(newZoom);
    }
  };
  const onCrop = () => {
    const imageElement = cropperRef?.current;
    const cropper = imageElement?.cropper;
    if (cropper) {
      cropper.getCroppedCanvas().toBlob((blob) => {
        if (blob) {
          setImageBlob(blob);
          const imageUrl = URL.createObjectURL(blob);
          setCroppedImageUrl(imageUrl);
        } else {
          console.error("Crop failed: Invalid blob.");
        }
      });
    }
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: {xs:"auto",tablet:700},
    bgcolor: "background.paper",
    borderRadius: "25px",
    boxShadow: "none !important",
    ".&focus-visible": {
      outlineColor: "none",
    },
  };
  const handleCropStart = (e) => {
    const cropper = cropperRef.current?.cropper;
    if (cropper && e?.detail?.action === "crop") {
      cropper.clear();
    }
  };

  const onCropEnd = () => {
    const cropper = cropperRef.current?.cropper;
    if (cropper) {
      cropper?.getCroppedCanvas()?.toBlob((blob) => {
        if (blob) {
          setImageBlob(blob);
          const imageUrl = URL.createObjectURL(blob);
          setCroppedImageUrl(imageUrl);
        } else {
          console.error("Crop failed: Invalid blob.");
        }
      });
    }
  };

  const i18n = locale.en;
  return (
    <Modal
      open={handleOpen}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography
          id="modal-modal-title"
          variant="h6"
          component="h2"
          textAlign={"center"}
          sx={{ marginBottom: "10px", padding: "32px 32px 0 32px" }}
        >
          Set Profile Picture
        </Typography>
        <Box sx={{ padding: "0 32px 32px 32px" }}>
          <div>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Box sx={{ marginRight: "25px" }}>
                {image && (
                  <Cropper
                    src={image}
                    style={{ height: 400, width: "100%" }}
                    initialAspectRatio={1}
                    guides={false}
                    ref={cropperRef}
                    crop={onCrop}
                    viewMode={1}
                    aspectRatio={1}
                    cropstart={handleCropStart}
                    cropend={onCropEnd}
                  />
                )}
              </Box>
              <Box
                sx={{
                  borderLeft: "1px solid lightgrey",
                  paddingLeft: "20px",
                  textAlign: "center",
                }}
              >
                <div>See your final image here </div>
                {croppedImageUrl && (
                  <Box
                    sx={{
                      textAlign: "center",
                      marginTop: "20px",
                    }}
                  >
                    <img
                      src={croppedImageUrl}
                      style={{
                        border: "1px solid lightgrey",
                        height: "100px",
                        width: "100px",
                        borderRadius: "10px",
                      }}
                      alt="Cropped"
                    />
                  </Box>
                )}
              </Box>
            </Box>
            <Box sx={{ width: 300, margin: "auto", paddingTop: "20px" }}>
              <Slider
                value={zoom}
                min={0}
                max={3}
                step={0.1}
                onChange={handleZoomChange}
                aria-labelledby="zoom-slider"
              />
            </Box>
          </div>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Button
            sx={{
              width: "50%",
              height: "60px",
              color: "black",
              borderRadius: "0 0 0 25px",
            }}
            variant="contained"
            color="submitButton"
            onClick={handleSubscriptionCancel}
          >
            cancel
          </Button>
          <Button
            sx={{ width: "50%", height: "60px", borderRadius: "0 0 25px 0" }}
            variant="contained"
            color="redButton"
            onClick={handleSubscriptionOk}
          >
            Upload
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default CropModal;
