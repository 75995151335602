import {
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTheme } from "@emotion/react";
import close from "../../../assets/Padding Included/Black_Close.svg";
import logo from "../../../assets/Company Logos/Ogilvy Restructures.jpg";
import matchMeIcon from "../../../assets/Padding Excluded/Black_Match_me.svg";
import applyIcon from "../../../assets/Padding Excluded/Black_Follower.svg";
import { useDispatch } from "react-redux";
import { setAlert } from "../../../redux/configSlice";
import { ALERT_TYPE } from "../../../utils/Constants";
import {
  candidateShortlist,
  getTalentMatch,
} from "../../../redux/guest/talentSlice";
import { CheckCircle } from "@mui/icons-material";
import InfiniteScroll from "react-infinite-scroll-component";
import locale from "../../../i18n/locale";
import { getDecodedToken, getToken } from "../../../utils/Common";
import { LightTooltip } from "../../../utils/CommonComponent";

export default function ShortlistBox({
  job,
  closeFunc,
  // setSelectedJob,
  // selectedJob,
  setMatchButton,
  setRunMatch,
  setShortlist,
  selectedJobID,
  setSelectedJobID,
}) {
  const theme = useTheme();
  const i18n = locale.en;
  const dispatch = useDispatch();
  const [selectedJob, setSelectedJob] = useState([]);
  //   const [match, setMatch] = useState();
  //   const [selected, setSelected] = useState([]);

  const [pageNumber, setpageNumber] = useState(0);

  const [hasMoreData, setHasMoreData] = useState(true);
  const decodedToken = getDecodedToken(getToken());

  const getMatch = async () => {
    try {
      const { payload } = await dispatch(
        getTalentMatch({
          lastKey: pageNumber,
        })
      );
      if (payload?.status === "success") {
        setpageNumber(payload?.pageNumber + 1);
        setSelectedJob((prevState) => [...prevState, ...payload?.data]);
        payload?.data?.length === 0 && setHasMoreData(false);
      }
    } catch (error) {
      dispatch(
        setAlert({
          show: true,
          type: ALERT_TYPE?.ERROR,
          msg: error,
        })
      );
    }
  };

  const shortlistCandidate = async () => {
    try {
      const data = {
        job_id: selectedJobID,
        candidate_id: job?.user_id,
      };
      const { payload } = await dispatch(candidateShortlist(data));
      if (payload?.status === "success") {
        if (payload?.message === "Already Shortlisted") {
          dispatch(
            setAlert({
              show: true,
              type: ALERT_TYPE?.ERROR,
              msg: "Candidate is already Shortlisted",
            })
          );
        } else {
          dispatch(
            setAlert({
              show: true,
              type: ALERT_TYPE?.SUCCESS,
              msg: "Candidate is shortlisted",
            })
          );
        }
      }
    } catch (error) {
      dispatch(
        setAlert({
          show: true,
          type: ALERT_TYPE?.ERROR,
          msg: error,
        })
      );
    }
  };

  useEffect(() => {
    getMatch();
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: { sm: "576px", xl: "586px" },
        width: "100%",
        justifyContent: "space-between",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          margin: "17px 17px 0 17px",
        }}
      >
        <Box
          sx={{
            marginBottom: "0px",
            display: "flex",
            gap: "10px",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: theme.typography.Bold,
            }}
          >
            {i18n["matchMeBox.shortlistToJob"]}
          </Typography>
          <Tooltip disableInteractive>
            <LightTooltip
              title={
                "This percentage match does not necessarily mean you will or will not be shortlisted for a job, but it helps provide a quick indicator of your suitability. "
              }
              placement="right-end"
            >
              <Typography
                sx={{
                  padding: "5px",
                  height: "8px",
                  width: "8px",
                  borderRadius: "50%",
                  fontSize: "15px",
                  // lineHeight: 0.1,
                  /* text-align: center; */
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  fontWeight: 700,
                  border: 1,
                }}
              >
                i
              </Typography>
            </LightTooltip>
          </Tooltip>
        </Box>
        <IconButton
          aria-label="close"
          onClick={() => {
            closeFunc();
            setSelectedJob([]);
          }}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "black",
            ":hover": {
              backgroundColor: "transparent",
            },
          }}
        >
          <Box
            component={"img"}
            src={close}
            sx={{
              border: 2,
              borderRadius: "5px",
              height: 20,
              width: 20,
            }}
          />
        </IconButton>
        <Box sx={{ width: "325px", height: "15px", margin: "15px 0" }}>
          <Typography
            sx={{
              fontSize: "12px",
              fontWeight: theme.typography.Medium,
              lineHeight: "15px",
            }}
          >
            {i18n["matchMeBox.selectJob"]}
          </Typography>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            overflowY: "auto",
            gap: "10px",
            paddingRight: "10px",
            marginBottom: "10px",
            "::-webkit-scrollbar": {
              height: "5px",
              background: "#DCDEEB",
              display: "unset !important",
            },
          }}
          height={{ sm: `calc(576px - 153px)`, xl: `calc(586px - 153px)` }}
          className="scroll-container"
          id="shortListTaleneList"
        >
          <InfiniteScroll
            style={{
              overflowX: "hidden",
              scrollbarWidth: "thin",
              display: "flex",
              flexDirection: "column",
              gap: "10px",
            }}
            dataLength={selectedJob?.length || 0}
            next={() => {
              getMatch({ lastKey: pageNumber });
            }}
            hasMore={hasMoreData}
            loader={
              <Box
                style={{
                  margin: "15px 0 0 0",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CircularProgress color="inherit" size={20} />
              </Box>
            }
            scrollableTarget="shortListTaleneList"
            scrollThreshold={"50px"}
          >
            {selectedJob?.length > 0 ? (
              selectedJob?.map((item) => (
                <Box
                  key={item?.company_id}
                  sx={{ display: "flex", alignItems: "center", gap: "9px" }}
                >
                  <Box
                    component={"img"}
                    src={
                      item?.job_logo_url !== "No URL"
                        ? item?.job_logo_url
                        : logo
                    }
                    sx={{
                      height: "50px",
                      width: "50px",
                      borderRadius: "10px",
                    }}
                  />
                  <Tooltip
                    disableInteractive
                    title={item?.title}
                    placement="top-start"
                  >
                    <Typography
                      sx={{
                        fontSize: "15px",
                        fontWeight: theme.typography.Bold,
                        wordBreak: "break-word",
                        cursor: "pointer",
                      }}
                      noWrap={true}
                      onClick={() => {
                        setSelectedJob((prev) =>
                          prev?.map((items) =>
                            items?.job_id === item?.job_id
                              ? {
                                  ...items,
                                  selected: items?.selected ? false : true,
                                }
                              : { ...items, selected: false }
                          )
                        );
                        setSelectedJobID(item?.job_id);
                      }}
                    >
                      {item?.title}
                    </Typography>
                  </Tooltip>
                  {item?.job_id === selectedJobID && (
                    <CheckCircle
                      key={item?.job_id}
                      sx={{
                        fontSize: 27,
                        cursor: "pointer",
                      }}
                      color="checkColor"
                    />
                  )}
                </Box>
              ))
            ) : (
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <Typography>No Jobs</Typography>
              </Box>
            )}
          </InfiniteScroll>
        </Box>
      </Box>
      <Grid
        container
        // padding="0 8px 8px 8px"
        alignItems="center"
        overflow={"hidden"}
        sx={{
          width: "100%",
          borderRadius: "0 0 25px 25px",
          height: "60px",
        }}
      >
        <Button
          variant="contained"
          sx={{
            borderRadius: 0,
            fontSize: "14px",
            fontWeight: theme.typography.Bold,
            whiteSpace: "nowrap",
            height: "100%",
            width: "50%",
            justifyContent: "center",
            ".MuiButton-startIcon": {
              marginRight: "0px !important",
              marginLeft: 0,
            },
            padding: "0px !important",
            gap: 1,
            boxShadow: "none !important",
          }}
          color="yoco"
          startIcon={
            <Box
              component={"img"}
              sx={{
                height: "20px",
                width: "26px",
              }}
              src={matchMeIcon}
            />
          }
          onClick={() => {
            setShortlist(false);
            setRunMatch(false);
            setMatchButton(true);
          }}
        >
          {i18n["matchMeBox.match"]}
        </Button>
        <Button
          disabled={selectedJobID?.length === 0}
          variant="contained"
          sx={{
            borderRadius: "0 0 25px 0",
            width: "50%",
            height: "100%",
            fontSize: "14px",
            fontWeight: theme.typography.Bold,
            ":hover": {
              boxShadow: 0,
            },
            ".MuiButton-startIcon": {
              marginRight: "0px !important",
              marginLeft: 0,
            },
            padding: "0px !important",
            boxShadow: "none !important",
            gap: 1,
          }}
          color="nandos"
          onClick={() => shortlistCandidate()}
          startIcon={
            <Box
              component={"img"}
              sx={{
                height: "20px",
                width: "24px",
              }}
              src={applyIcon}
            />
          }
        >
          {i18n["matchMeBox.shortlist"]}
        </Button>
      </Grid>
    </Box>
  );
}
