import React, { useState } from "react";

import { Avatar, Box, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import {
  getColorByIndex,
  getDecodedToken,
  getToken,
} from "../../../utils/Common";

const NameInfo = ({ name, email, userID, role, isSuperAdmin }) => {
  const [randomColor] = useState(() =>
    getColorByIndex(Math.floor(Math.random() * 1000))
  );

  const decodedToken = getDecodedToken(getToken());

  return (
    <Box
      sx={{
        display: "flex",
        gap: 2,
        alignItems: "center",
      }}
    >
      <Avatar sx={{ bgcolor: randomColor }}>{name?.[0]}</Avatar>
      {role === 1 || role === 2 || isSuperAdmin ? (
        <Link
          to={
            decodedToken?.data?.role_id === 6
              ? `/recruiter/my-team/team-info/${userID}`
              : `/employer/my-team/team-info/${userID}`
          }
          style={{ textDecoration: "none", color: "black" }}
        >
          <Box>
            <Typography
              sx={{
                fontWeight: "Bold",
                overflow: "hidden",
                whiteSpace: "nowrap",
                maxWidth: "200px",
              }}
              textOverflow={"ellipsis"}
            >
              {name}
            </Typography>
            <Typography>{email}</Typography>
          </Box>
        </Link>
      ) : (
        <Box>
          <Typography
            sx={{
              fontWeight: "Bold",
              overflow: "hidden",
              whiteSpace: "nowrap",
              maxWidth: "200px",
            }}
            textOverflow={"ellipsis"}
          >
            {name}
          </Typography>
          <Typography>{email}</Typography>
        </Box>
      )}
    </Box>
  );
};

export default NameInfo;
