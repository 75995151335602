import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import ManageJob from "../../common/myJobsSectionEmployerAndRecruiter/ManageJob";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import JobCard from "./JobCard";

const HeaderBox = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  "& .MuiButtonBase-root": {
    fontSize: 16,
    fontWeight: 700,
    minWidth: 47,
    padding: "2px 4px",
    borderRadius: 3,
    height: "31px",
    boxShadow: "none",
  },
}));

const StyledSelect = styled(Select)(({ theme }) => ({
  width: "40%",
  marginRight: "16px",
  borderRadius: "20px",
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: theme.palette.black,
  },
}));
const JOB = {
  id: "004",
  name: "Mike",
  logo: "",
  job: "Lead Developer",
  description: "Ozow Payments",
  status: {
    label: "interview",
    color: "purpleButton",
  },
  crayonComfort: true,
  address: "Cape Town, South Africa",
  salary: "R90,000pm",
  experience: "6 years",
  workType: "remote",
  jobType: "full-time",
  date: "25 Nov 2022",
  days: "42 days",
};
export default function ActiveJobDetail() {
  return (
    <Box>
      <HeaderBox>
        <Button
          variant="contained"
          color="orangeButton"
          sx={{ mr: 1, borderRadius: "5px", pa: 0 }}
        >
          004
        </Button>
        <Typography
          sx={{
            fontSize: "12px",
            fontWeight: 700,
            mr: 1,
          }}
        >
          ozow
        </Typography>
        <Typography
          sx={{
            fontSize: "36px",
            fontWeight: 700,
            mr: 1,
          }}
        >
          Lead Developer
        </Typography>
        <Typography
          sx={{
            fontSize: "36px",
            fontWeight: 400,
          }}
        >
          Ozow Payments{" "}
        </Typography>
      </HeaderBox>

      <JobCard jobContent={JOB} />

      <Box
        sx={{
          mt: 2,
        }}
      >
        <StyledSelect
          value={10}
          // onChange={handleChange}
          displayEmpty
          inputProps={{ "aria-label": "stack" }}
          size="small"
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          <MenuItem value={10}>Quick stack (all columns)</MenuItem>
          <MenuItem value={20}>Twenty</MenuItem>
          <MenuItem value={30}>Thirty</MenuItem>
        </StyledSelect>
        <StyledSelect
          value={10}
          // onChange={handleChange}
          displayEmpty
          inputProps={{ "aria-label": "filter" }}
          size="small"
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          <MenuItem value={10}>Quick filter (all columns)</MenuItem>
          <MenuItem value={20}>Twenty</MenuItem>
          <MenuItem value={30}>Thirty</MenuItem>
        </StyledSelect>
      </Box>
      <Box sx={{ mt: 1, overflowX: "auto", minHeight: "69vh" }}>
        <ManageJob />
      </Box>
    </Box>
  );
}
