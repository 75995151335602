import { useTheme } from "@emotion/react";
import { Box, IconButton, Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import SVGButton from "../../../common/SVGButton";
import cv from "../../../../assets/JMD/CVIcon.svg";
import greyCv from "../../../../assets/JMD/CVIconGrey.svg";
import user from "../../../../assets/JMD/VitaeIcon.svg";
import portfolio from "../../../../assets/JMD/PortfolioIcon.svg";
import portfolioGrey from "../../../../assets/JMD/PortfolioIconGrey.svg";
import greyUser from "../../../../assets/JMD/VitaeIconGrey.svg";
import portfolioLinkGrey from "../../../../assets/JMD/PortfolioLinkGreyIcon.svg";
import CloseIcon from "../../../common/CloseIcon";

export default function Document({
  userID,
  cvLink,
  handleOpen,
  portfolio_url,
  isPortfolioLink
}) {
  const theme = useTheme();
  return (
    <Box
      sx={{
        padding: "10px !important",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography
          sx={{
            fontWeight: 700,
            fontSize: "14px",
          }}
        >
          Documents
        </Typography>
        <IconButton
          aria-label="close"
          onClick={() => {
            handleOpen();
          }}
          sx={{
            position: "absolute",
            top: 0,
            right: "5px",
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Box
            sx={{
              width: "5px",
              height: "5px",
              color: "#000000",
              fontSize: "10px",
            }}
          >
          <CloseIcon />
          </Box>
        </IconButton>
      </Box>
      {userID ? (
        <Link
          to={`/candidate-cv/${userID}`}
          target="_blank"
          style={{
            textDecoration: "none",
            color: theme.palette.black,
            cursor: "pointer",
          }}
        >
          <Box sx={{ display: "flex", pt: 1 }}>
            {/* <SVGButton color={"redButton"} source={user} /> */}
            <SVGButton
              color={"white"}
              source={user}
              outerHeight={35}
              outerWidth={35}
              height={35}
              width={35}
              startIconMargin={"0px !important"}
              padding={"0px !important"}
            />
            <Typography
              sx={{
                fontWeight: 700,
                fontSize: "14px",
                marginLeft: "16px !important",
                margin: "auto",
              }}
            >
              Crayon Vitae
            </Typography>
          </Box>
        </Link>
      ) : (
        <Box sx={{ display: "flex", pt: 1, cursor: "default" }}>
          {/* <SVGButton color={"redButton"} source={user} /> */}
          <SVGButton
            color={"white"}
            source={greyUser}
            height={35}
            width={35}
            outerHeight={35}
            outerWidth={35}
            startIconMargin={"0px !important"}
            padding={"0px !important"}
            cursor={"default"}
          />
          <Typography
            sx={{
              fontWeight: 700,
              fontSize: "14px",
              marginLeft: "16px !important",
              margin: "auto",
            }}
          >
            Crayon Vitae
          </Typography>
        </Box>
      )}
      {cvLink !== "No URL" ? (
        <Link
          to={`${cvLink}`}
          target="_blank"
          style={{
            textDecoration: "none",
            color: theme.palette.black,
            cursor: "pointer",
          }}
        >
          <Box sx={{ display: "flex", pt: 1 }}>
            {/* <SVGButton color={"redButton"} source={cv} /> */}
            <SVGButton
              color={"white"}
              source={cv}
              height={35}
              width={35}
              outerHeight={35}
              outerWidth={35}
              startIconMargin={"0px !important"}
              padding={"0px !important"}
            />
            <Typography
              sx={{
                fontWeight: 700,
                fontSize: "14px",
                marginLeft: "16px !important",
                margin: "auto",
              }}
              // onClick={() => handleDownloadClick(cvLink)}
            >
              Original CV / resume
            </Typography>
          </Box>
        </Link>
      ) : (
        <Box sx={{ display: "flex", pt: 1, cursor: "default" }}>
          {/* <SVGButton color={"redButton"} source={cv} /> */}
          <SVGButton
            color={"white"}
            source={greyCv}
            height={35}
            width={35}
            outerHeight={35}
            outerWidth={35}
            startIconMargin={"0px !important"}
            padding={"0px !important"}
            cursor={"default"}
          />
          <Typography
            sx={{
              fontWeight: 700,
              fontSize: "14px",
              marginLeft: "16px !important",
              margin: "auto",
            }}
            // onClick={() => handleDownloadClick(cvLink)}
          >
            Original CV / resume
          </Typography>
        </Box>
      )}
      {portfolio_url !== "No URL" ? (
        <Link
          to={`${portfolio_url}`}
          target="_blank"
          style={{
            textDecoration: "none",
            color: theme.palette.black,
            cursor: "pointer",
          }}
        >
          <Box sx={{ display: "flex", pt: 1, cursor: "pointer" }}>
            {/* <SVGButton color={"redButton"} source={user} /> */}
            <SVGButton
              color={"white"}
              source={portfolio}
              height={35}
              width={35}
              outerHeight={35}
              outerWidth={35}
              startIconMargin={"0px !important"}
              padding={"0px !important"}
              cursor={"default"}
            />
            <Typography
              sx={{
                fontWeight: 700,
                fontSize: "14px",
                marginLeft: "16px !important",
                margin: "auto",
              }}
            >
              Portfolio
            </Typography>
          </Box>
        </Link>
      ) : (
        <Box sx={{ display: "flex", pt: 1, cursor: "default" }}>
          {/* <SVGButton color={"redButton"} source={user} /> */}
          <SVGButton
            color={"white"}
            source={portfolioGrey}
            height={35}
            width={35}
            outerHeight={35}
            outerWidth={35}
            startIconMargin={"0px !important"}
            padding={"0px !important"}
            cursor={"default"}
          />
          <Typography
            sx={{
              fontWeight: 700,
              fontSize: "14px",
              marginLeft: "16px !important",
              margin: "auto",
            }}
          >
            Portfolio
          </Typography>
        </Box>
      )}
      {isPortfolioLink && 
       <Box sx={{ display: "flex", pt: 1, cursor: "default" }}>
       {/* <SVGButton color={"redButton"} source={user} /> */}
       <SVGButton
         color={"white"}
         source={portfolioLinkGrey}
         height={35}
         width={35}
         outerHeight={35}
         outerWidth={35}
         startIconMargin={"0px !important"}
         padding={"0px !important"}
         cursor={"default"}
       />
       <Typography
         sx={{
           fontWeight: 700,
           fontSize: "14px",
           marginLeft: "16px !important",
           margin: "auto",
         }}
       >
         Portfolio link
       </Typography>
     </Box>
      }
    </Box>
  );
}
