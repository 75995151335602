import { useEffect } from "react";
import ProfileCardNew from "./ProfileCardNew";
import jwt_decode from "jwt-decode";

export default function MyCV({ handleCandidateDynamicTheme }) {
  const token = localStorage?.getItem("token");
  let decodedToken;
  if (token) {
    decodedToken = jwt_decode(token);
  }

  useEffect(() => {
    handleCandidateDynamicTheme(decodedToken?.data?.personality);
  }, []);

  return <ProfileCardNew />;
}
