import React, { useCallback } from "react";
import {
  getAdminTalentJobList,
  getLinkedJobTalent,
  removeLinkedJob,
} from "../../../../redux/admin/jobsSlice";
import { setAlert } from "../../../../redux/configSlice";
import { ALERT_TYPE, ERROR_MSG } from "../../../../utils/Constants";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { useState } from "react";
import logo from "../../../../assets/Crayon_Favicon.svg";
import {
  Box,
  CircularProgress,
  IconButton,
  InputBase,
  Paper,
  Tooltip,
  Typography,
} from "@mui/material";
import { CheckCircle, Circle } from "@mui/icons-material";
import { useTheme } from "@emotion/react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useParams } from "react-router-dom";
import TalentSVGButton from "../../../common/TalentSVGButton";
import cancel from "../../../../assets/CircularIcon/Red/Circular Icons__Red_Close_2.svg";
import locale from "../../../../i18n/locale";
import { debounce } from "../../../../utils/Common";

export default function JobsLinked({
  handleCloseLinkedToJob,
  setAllJobs,
  getJobList,
  setLinkedJobCount,
  linkedJobCount,
  setAnchorElJobLinked,
  setSelectedJob,
  getPoolDetails,
  setLinkJobButton,
}) {
  const dispatch = useDispatch();
  const i18n = locale.en;
  const theme = useTheme();
  const { poolId } = useParams();

  const [activeJobs, setActiveJobs] = useState([]);
  const [lastKey, setLastKey] = useState("");
  const [totalJob, setTotalJob] = useState(0);
  const [hasMoreData, setHasMoreData] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [isLoadingData, setIsLoadingData] = useState(true);

  const getLinkedJobsList = async (lastkeyy, query) => {
    let data = {
      lastKey: lastkeyy,
      pool_id: poolId,
      query: query,
      linkjobflag: true,
    };
    const { payload } = await dispatch(getAdminTalentJobList(data));

    if (payload?.status === "success") {
      payload?.data?.length === 0 && setHasMoreData(false);
      if (lastkeyy === "") {
        setLastKey(payload?.pageNumber + 1);
        setActiveJobs(payload?.data);
        setTotalJob(payload?.talentCount);
      } else {
        setLastKey(payload?.pageNumber + 1);
        setActiveJobs((prevState) => [...prevState, ...payload?.data]);
        setTotalJob(payload?.talentCount);
      }
      setIsLoadingData(false);
    } else if (payload === undefined) {
      return;
    } else {
      dispatch(
        setAlert({
          show: true,
          type: ALERT_TYPE?.ERROR,
          msg: "Something went wrong! please relaod the window",
        })
      );
    }
  };

  const removeLinkedJobFromPool = async (jobid) => {
    try {
      const data = {
        pool_id: poolId,
        job_id: jobid,
      };
      const { payload } = await dispatch(removeLinkedJob(data));
      setActiveJobs(activeJobs?.filter((item) => item?.job_id !== jobid));
      if (payload?.status === "success") {
        setLinkedJobCount(payload?.count);
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE?.SUCCESS,
            msg: "Linked job removed successfully",
          })
        );
        setSelectedJob([]);
        setAnchorElJobLinked(null);
        setLinkJobButton(false);
        await getPoolDetails();
        await getJobList(0, "", []);
      }
    } catch (error) {
      dispatch(
        setAlert({
          show: true,
          type: ALERT_TYPE?.ERROR,
          msg: error,
        })
      );
    }
  };

  const getJobStatusColor = (status) => {
    switch (status) {
      case "active":
        return "lightGreenButton300";
      case "paused":
        return "redButton100";
      case "closed":
        return "redButton100";
      case "pending":
        return "orangeButton";
      default:
        return "redButton100";
    }
  };
  const debouncedHandleOnTextSearch = useCallback(
    debounce(
      async (newValue) => {
        if (!newValue) return; // Early return
        try {
          setLastKey(0);
          setActiveJobs([]);
          const response = await getLinkedJobsList(0, newValue?.target?.value);

          // if (response && response?.payload) {
          //   const { payload } = response;
          //   dispatch(
          //     payload?.status === "success"
          //       ? setMatch((prevState) => [
          //           ...prevState,
          //           ...payload?.data.map((item) => ({
          //             ...item,
          //             job_logo_url: item?.profile_url,
          //           })),
          //         ])
          //       : setAlert({
          //           show: true,
          //           type: ALERT_TYPE?.ERROR,
          //           msg: ERROR_MSG,
          //         })
          //   );
          // }
        } catch (error) {
          dispatch(
            setAlert({ show: true, type: ALERT_TYPE?.ERROR, msg: ERROR_MSG })
          );
        }
      },
      500, // Adjust delay as needed
      setIsLoading
    ),
    [dispatch, setActiveJobs, setIsLoading]
  );

  const handleSearch = async (event) => {
    setLastKey(0);
    setSearchText(event?.target?.value);
    setActiveJobs([]);
    debouncedHandleOnTextSearch(event);
  };

  useEffect(() => {
    getLinkedJobsList(0, searchText);
  }, []);

  useEffect(() => {
    if (activeJobs?.length === 0) {
      setHasMoreData(false);
    }
  }, [activeJobs]);

  return (
    <Box
      sx={{
        padding: "19px 15px 0px 15px !important",
      }}
    >
      <Box sx={{ display: "flex", gap: "10px", flexDirection: "column" }}>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography
            sx={{
              fontSize: "16px",
              fontWeight: theme.typography.Medium,
              letterSpacing: "0.32px",
            }}
          >
            {i18n["popupBoxText.linkedJob"]}
          </Typography>
          <IconButton
            aria-label="close"
            onClick={() => {
              handleCloseLinkedToJob();
            }}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
            disableRipple="true"
          >
            <Box
              sx={{
                width: "20px",
                height: "20px",
                color: "#000000",
                border: 2,
                fontSize: "18px",
                borderRadius: "5px",
              }}
            >
              X
            </Box>
          </IconButton>
        </Box>
        <Typography
          sx={{
            fontSize: "12px",
            fontWeight: theme.typography.Medium,
            width: "307px",
          }}
        >
          {i18n["popupBoxText.linkedJobList"]}
        </Typography>

        <Paper
          elevation={0}
          component="form"
          sx={{
            padding: "0px 10px",
            border: "1px solid #C9CAD8",
            mb: 1,
          }}
          height={"40px"}
          width={"308px"}
        >
          <InputBase
            sx={{
              fontWeight: theme.typography.Regular,
              width: "308px",
            }}
            placeholder={"Search for a job"}
            // value={searchText}
            onChange={(event) => {
              handleSearch(event);
            }}
          />
        </Paper>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            overflowY: "auto",
            height: {
              sm: "340px",
              lg: "340px",
              lgm: "400px",
              mlg: "450px",
              xl: "470px",
            },
            gap: "10px",
            paddingRight: "10px",
            marginBottom: "10px",
            "::-webkit-scrollbar": {
              height: "5px",
              background: "#DCDEEB",
              display: "unset !important",
            },
          }}
          className="match-scroll-container"
          id="matchTaleneList"
        >
          <InfiniteScroll
            style={{
              overflowX: "hidden",
              scrollbarWidth: "thin",
              display: "flex",
              flexDirection: "column",
              gap: "10px",
            }}
            dataLength={activeJobs?.length || 0}
            next={() => getLinkedJobsList(lastKey, searchText)}
            hasMore={hasMoreData}
            loader={
              <Box
                style={{
                  margin: "15px 0 0 0",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CircularProgress color="inherit" size={20} />
              </Box>
            }
            scrollableTarget="matchTaleneList"
            scrollThreshold={"50px"}
          >
            {activeJobs?.length > 0 ? (
              activeJobs?.map((item) => (
                <Box
                  key={item?.job_id}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "9px",
                    justifyContent: "space-between",
                  }}
                >
                  <Box
                    sx={{ display: "flex", alignItems: "center", gap: "9px" }}
                  >
                    <Box
                      component={"img"}
                      src={
                        item?.profile_url !== "No URL"
                          ? item?.profile_url
                          : logo
                      }
                      sx={{
                        height: "50px",
                        width: "50px",
                        borderRadius: "10px",
                      }}
                    />
                    <Tooltip
                      disableInteractive
                      title={item?.title}
                      placement="top-start"
                    >
                      <Typography
                        sx={{
                          fontSize: "15px",
                          fontWeight: theme.typography.Bold,
                          // wordBreak: "break-word",
                          cursor: "pointer",
                          width: "200px",
                          // width: "165px",
                        }}
                        // onClick={() =>
                        //   !item?.linkStatus &&
                        //   getLinkedJobTalentList(item?.job_id)
                        // }
                        noWrap
                      >
                        {item?.title}
                      </Typography>
                    </Tooltip>
                  </Box>

                  <Box
                    sx={{ display: "flex", alignItems: "center", gap: "8px" }}
                  >
                    <TalentSVGButton
                      padding={"0px !important"}
                      color={"white"}
                      source={cancel}
                      height={28}
                      width={23}
                      onClick={() => removeLinkedJobFromPool(item?.job_id)}
                    />
                  </Box>
                  <Circle
                    // fontSize={"25px !important"}
                    sx={{ fontSize: "25px" }}
                    color={getJobStatusColor(item?.job_status)}
                    // sx={{ marginLeft: "10px" }}
                  />
                </Box>
              ))
            ) : isLoadingData ? (
              <Box
                style={{
                  margin: "15px 0 0 0",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CircularProgress color="inherit" size={20} />
              </Box>
            ) : (
              <Box
                sx={{
                  width: "100%",
                  textAlign: "center",
                  mt: 4,
                  color: "black",
                }}
              >
                No linked jobs
              </Box>
            )}
          </InfiniteScroll>
        </Box>
      </Box>
    </Box>
  );
}
