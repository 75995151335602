import React, { useState } from "react";
import { Box, Button, InputBase, Paper, Typography } from "@mui/material";
import greenTriangle from "../../assets/Characters/CrayBotIcons/Crayon Craybot Notification-09.svg";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@emotion/react";
import locale from "../../i18n/locale";
import { resendMail, resendMailPost } from "../../redux/login/loginSlice";
import { useDispatch } from "react-redux";
import { setAlert } from "../../redux/configSlice";
import { ALERT_TYPE, ERROR_MSG } from "../../utils/Constants";
import CustomDialog from "../common/CustomDialog";
import CloseIcon from "../common/CloseIcon";

export default function ResendMailBox({
  setResendMailBox,
  email,
  open,
  handleClose,
}) {
  const dispatch = useDispatch();
  const theme = useTheme();
  const i18n = locale.en;

  const resendVerificationMail = async () => {
    const load = {
      type: "signup",
      Email: email,
    };
    try {
      const { payload } = await dispatch(resendMailPost(load));
      if (payload?.status === "success") {
        setResendMailBox(false);
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE?.SUCCESS,
            msg: i18n["alertMessage.emailsent"],
          })
        );
      }
    } catch (error) {
      dispatch(
        setAlert({
          show: true,
          type: ALERT_TYPE?.ERROR,
          msg: ERROR_MSG,
        })
      );
    }
  };
  return (
    <CustomDialog
      show={open}
      hideButton={false}
      dialogWidth="xs"
      showFooter={false}
      onDialogClose={handleClose}
      padding={0}
    >
      <Box
        sx={{
          textAlign: "center",
          display: "flex",
          flexDirection: "column",
          gap: "6px",
          justifyContent: "space-between",
          height: "340px",
          backgroundColor: "white",
          borderRadius: "25px",
          marginTop: "13px",
        }}
      >
        <Box
          component={"img"}
          src={greenTriangle}
          sx={{
            width: 150,
            height: 150,
            margin: "0 auto",
            marginTop: "10px",
            marginBottom: "18px",
          }}
        />
        <Box
          sx={{
            cursor: "pointer",
          }}
          onClick={() => {
            handleClose();
          }}
        >
          <CloseIcon />
        </Box>
        <Typography
          sx={{
            padding: "0 20px",
            fontSize: "16px",
            fontWeight: theme.typography.Bold,
            letterSpacing: "0.28px",
          }}
        >
          Please check your inbox for the original verification email, otherwise
          you can send another verification link below.
        </Typography>
        <Button
          variant="contained"
          sx={{
            borderRadius: "0px 0px 25px 25px",
            width: "100%",
            height: "60px",
            marginTop: "20px",
          }}
          color="redButton"
          onClick={() => resendVerificationMail()}
        >
          resend mail
        </Button>
      </Box>
    </CustomDialog>
  );
}
