import { Box, Grid } from "@mui/material";

import { ThemeProvider } from "@emotion/react";
import React, { useEffect, useRef } from "react";
import AddNewCompanyCard from "./AddCompanyCards/AddNewCompanyCard";
import CompanyDetailCard from "./AddCompanyCards/CompanyDetailCard";
import { employerCompanyListTheme } from "../../../utils/componentThemes/employerCompanyListTheme";
import AddCompanyDetailsCard from "./AddCompanyCards/AddCompanyDetailsCard";
import { useState } from "react";
import {
  deleteCompany,
  getSecondaryCompanyData,
} from "../../../redux/employer/empProfileSlice";
import { useDispatch } from "react-redux";
import InfiniteScroll from "react-infinite-scroll-component";
import { setAlert } from "../../../redux/configSlice";
import { ALERT_TYPE } from "../../../utils/Constants";
import { setCardsPadding } from "../../../redux/guest/jobsSlice";

const AddCompany = ({ setActiveButton }) => {
  const dispatch = useDispatch();
  const gridRef = useRef();
  const [openAddCompanyDetails, setOpenAddCompanyDetails] = useState(false);
  const [companiesList, setCompaniesList] = useState([]);

  const [editCompanyInfo, setEditCompanyInfo] = useState({});
  const [companyID, setCompanyID] = useState();
  const [editCompanyID, setEditCompanyID] = useState(false);
  const [itemsFetched, setItemsFetched] = useState(false);
  const [newCardWidth, setNewCardWidth] = useState(340);
  const [newPadding, setNewPadding] = useState(25);
  const [currentPageJobsCount, setCurrentPageJobsCount] = useState(0);

  function calculateCardWidthAndPadding(totalWidth) {
    let cardWidth = 340;
    let padding = 25;
    let numCards = Math.floor(totalWidth / (cardWidth + padding));
    let remainingSpace =
      totalWidth - (numCards * cardWidth + padding * (numCards + 1));

    while (remainingSpace > 0) {
      if (remainingSpace < cardWidth + padding) {
        if (padding < 30) {
          padding++;
          remainingSpace =
            totalWidth - (numCards * cardWidth + padding * (numCards + 1));
        } else {
          break;
        }
      } else {
        numCards++;
      }
    }

    cardWidth = (totalWidth - padding * (numCards + 1)) / numCards;
    if (cardWidth > 380) {
      cardWidth = 380;
    }
    remainingSpace =
      totalWidth - (numCards * cardWidth + padding * (numCards + 1));

    padding = padding + remainingSpace / (numCards + 1);

    remainingSpace =
      totalWidth - (numCards * cardWidth + padding * (numCards + 1));

    setNewCardWidth(cardWidth - 1);
    setNewPadding(padding);
    dispatch(setCardsPadding(padding));
    return { numCards, cardWidth, padding, remainingSpace };
  }
  const getNewWidth = () => {
    if (gridRef?.current) {
      calculateCardWidthAndPadding(gridRef?.current?.clientWidth + 60);
    }
  };
  useEffect(() => {
    if (gridRef?.current) {
      calculateCardWidthAndPadding(gridRef?.current?.clientWidth + 60);
    }
    window.addEventListener("resize", getNewWidth);
    return () => {
      window.removeEventListener("resize", getNewWidth);
    };
  }, [itemsFetched]);

  const getSecondaryCompanies = async () => {
    const { payload } = await dispatch(getSecondaryCompanyData());
    if (payload?.status === "success") {
      setCurrentPageJobsCount(payload?.data?.length || 0);
      setCompaniesList(payload?.data);
      setItemsFetched(true);
    }
  };

  const handleCompanySave = () => {
    setOpenAddCompanyDetails(false);
    getSecondaryCompanies();
  };

  const handleDelete = async (compid) => {
    try {
      const { payload } = await dispatch(deleteCompany({ company_id: compid }));
      if (payload?.status === "success") {
        setCompaniesList(
          companiesList?.filter((item) => item.company_id !== compid)
        );
      } else {
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE?.ERROR,
            msg: payload?.message,
          })
        );
      }
    } catch (error) {
      dispatch(
        setAlert({
          show: true,
          type: ALERT_TYPE?.ERROR,
          msg: error,
        })
      );
    }
  };

  useEffect(() => {
    getSecondaryCompanies();
  }, [editCompanyID]);

  return (
    <ThemeProvider theme={employerCompanyListTheme}>
      <Box
        sx={{
          display: "flex",
          height: "100%",
        }}
      >
        <Grid
          container
          sx={{
            display: "flex",
            // alignItems: "center",
            flexGrow: 1,
            flexDirection: "column",
            width: `calc(100vw - 360px)`,
            height: "100%",
          }}
          ref={gridRef}
        >
          <InfiniteScroll
            dataLength={companiesList?.length}
            hasMore={true}
            next={() => getSecondaryCompanies()}
            style={{
              height: "100%",
            }}
          >
            <Grid
              container
              gap={`40px ${newPadding}px`}
              // columns={{
              //   xs: 1,
              //   md: 2,
              //   lg: 3,
              //   mlg: 3.5,
              //   xl: 4,
              //   xxl: 6,
              //   xxxl: 8,
              // }}
              sx={{
                position: "relative",
                height: "100%",
                // padding: "0 " + newPadding + "px",
                // top: "109px",
                // justifyContent: companiesList?.length >= 4 && "space-between",
              }}
            >
              {companiesList?.map((companyDetails, index) => {
                return (
                  <Box
                    sx={{
                      width: newCardWidth,
                    }}
                  >
                    {openAddCompanyDetails &&
                    companyID === companyDetails?.company_id ? (
                      <Box
                        sx={{
                          width: newCardWidth,
                        }}
                      >
                        <AddCompanyDetailsCard
                          handleSubmission={handleCompanySave}
                          editCompanyID={editCompanyID}
                          editCompanyInfo={editCompanyInfo}
                        />
                      </Box>
                    ) : (
                      <CompanyDetailCard
                        companyDetails={companyDetails?.company}
                        industries={companyDetails?.industry}
                        key={companyDetails?.company?.company_id}
                        defaultCompany={companyDetails?.default}
                        companyURL={companyDetails?.companyURL}
                        theme={employerCompanyListTheme}
                        companyVideo={companyDetails?.companyvideoURL}
                        // setActiveButton={setActiveButton}
                        setEditCompanyInfo={setEditCompanyInfo}
                        setEditCompanyID={setEditCompanyID}
                        setOpenAddCompanyDetails={setOpenAddCompanyDetails}
                        editFlag={companyDetails?.super_admin}
                        setCompanyID={setCompanyID}
                        handleDelete={handleDelete}
                        job={companyDetails}
                        indexNumber={index}
                        jobslength={companiesList?.length}
                        currentPageJobsCount={currentPageJobsCount}
                      />
                    )}
                  </Box>
                );
              })}
                 <Box
                  sx={{
                    width: newCardWidth,
                  }}
                >
                  <AddCompanyDetailsCard
                    handleSubmission={handleCompanySave}
                    editCompanyID={editCompanyID}
                    editCompanyInfo={editCompanyInfo}
                  />
                </Box>
              {openAddCompanyDetails && !companyID && (
                <Box
                  sx={{
                    width: newCardWidth,
                  }}
                >
                  <AddCompanyDetailsCard
                    handleSubmission={handleCompanySave}
                    editCompanyID={editCompanyID}
                    editCompanyInfo={editCompanyInfo}
                  />
                </Box>
              )}
              <Box
                sx={{
                  width: newCardWidth,
                  height: "530px",
                  borderRadius: "25px",
                  background: "white",
                  boxShadow: "0px 5px 10px #C9CAD8",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setOpenAddCompanyDetails((prevState) => !prevState);
                  setEditCompanyID(false);
                  setCompanyID("");
                }}
              >
                <AddNewCompanyCard companyOpened={openAddCompanyDetails} />
              </Box>
            </Grid>

            <style>
              {`.infinite-scroll-component__outerdiv{
              width:100%;
              height:100%;
            }`}
            </style>
          </InfiniteScroll>
        </Grid>
      </Box>
    </ThemeProvider>
  );
};

export default AddCompany;
