import {
  Box,
  Button,
  IconButton,
  InputBase,
  Modal,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useState } from "react";
import InputBox from "../../../common/InputBox";
import { ALERT_TYPE, NUMBERS_REGEX } from "../../../../utils/Constants";
import {
  updateJobBounty,
  updateTalentPool,
} from "../../../../redux/admin/jobsSlice";
import { setAlert } from "../../../../redux/configSlice";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import { validateSchema } from "../../../../utils/Common";

export default function EditBounty({
  open,
  handleClose,
  jobContent,
  setAllJobs,
  allJobs,
}) {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    height: "300px",
    width: "450px",
    bgcolor: "background.paper",
    borderRadius: "25px",
    // pyb: 4,
    boxShadow: "none !important",
    // border: "1px solid black !important",
    "&:focus-visible": {
      outline: "none",
    },
  };

  const bountyCoinInfo = Yup.object().shape({
    internal_bounty_amount: Yup.number().positive("internal amount required."),
    external_bounty_amount: Yup.number().positive("external amount required."),
    talent_partner_bounty_amount: Yup.number().positive(
      "talent partner amount required."
    ),
  });

  const theme = useTheme();
  const dispatch = useDispatch();
  const [bountyAmount, setBountyAmount] = useState({
    internal_bounty_amount: jobContent?.internal_bounty_amount,
    external_bounty_amount: jobContent?.external_bounty_amount,
    talent_partner_bounty_amount: jobContent?.talent_partner_bounty_amount,
    currency_symbol: jobContent?.currency_symbol,
  });

  const [errors, setErrors] = useState([]);

  const handleBountyChane = (event) => {
    const { value, id } = event.target;
    if (NUMBERS_REGEX.test(value)) {
      let updatedInfo = { ...bountyAmount, [id]: value };
      setBountyAmount(updatedInfo);
      setErrors(errors?.filter((item) => item?.key !== id));
    } else if (value === "") {
      // If the value doesn't match the regex, clear the input field
      setBountyAmount({ ...bountyAmount, [id]: "" });
    }
  };

  const handleUpdatePool = async () => {
    let {
      internal_bounty_amount,
      external_bounty_amount,
      talent_partner_bounty_amount,
    } = bountyAmount;
    let testErrorState = {
      internal_bounty_amount,
      external_bounty_amount,
      talent_partner_bounty_amount,
    };
    validateSchema(bountyCoinInfo, testErrorState)
      .then(() => {
        try {
          let data = {
            internal_bounty_amount: Number(
              bountyAmount?.internal_bounty_amount
            ),
            external_bounty_amount: Number(
              bountyAmount?.external_bounty_amount
            ),
            recruiter_bounty_amount: Number(
              bountyAmount?.talent_partner_bounty_amount
            ),
            job_id: jobContent?.job_id,
          };
          dispatch(updateJobBounty(data)).then((payload) => {
            if (payload?.payload?.status === "success") {
              dispatch(
                setAlert({
                  show: true,
                  type: ALERT_TYPE?.SUCCESS,
                  msg: "Bounty updated successfully",
                })
              );
              setAllJobs(
                allJobs?.map((item) =>
                  item?.job_id === jobContent?.job_id
                    ? {
                        ...item,
                        internal_bounty_amount:
                          bountyAmount?.internal_bounty_amount,
                        external_bounty_amount:
                          bountyAmount?.external_bounty_amount,
                        talent_partner_bounty_amount:
                          bountyAmount?.talent_partner_bounty_amount,
                      }
                    : item
                )
              );
              handleClose();
            } else {
              dispatch(
                setAlert({
                  show: true,
                  type: ALERT_TYPE?.ERROR,
                  msg: payload?.payload?.message?.message,
                })
              );
            }
          });
        } catch (error) {
          dispatch(
            setAlert({
              show: true,
              type: ALERT_TYPE?.ERROR,
              msg: "Error",
            })
          );
        }
      })
      .catch((error) => {
        setErrors(
          Object.keys(error)?.map((item) => ({
            message: error[item],
            key: item,
          }))
        );
      });
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Button
          variant="contained"
          color="promoterButton"
          sx={{ width: "150px", height: "45px", borderRadius: "25px 0 25px 0" }}
        >
          edit bounty
        </Button>
        <IconButton
          aria-label="close"
          onClick={() => {
            handleClose();
          }}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
          disableRipple={true}
        >
          <Box
            sx={{
              width: "20px",
              height: "20px",
              color: "#000000",
              border: 2,
              fontSize: "18px",
              borderRadius: "5px",
            }}
          >
            X
          </Box>
        </IconButton>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "30px",
            padding: "16px",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography
              sx={{
                fontSize: "14px",
                width: "120px",
                fontWeight: theme.typography.Bold,
              }}
            >
              Internal
            </Typography>
            <Box sx={{ display: "flex", gap: "10px", alignItems: "center" }}>
              <InputBox
                id="internal_bounty_amount"
                value={bountyAmount?.internal_bounty_amount}
                onChange={(event) => handleBountyChane(event)}
                placeholder={"Enter internal bounty amount"}
                sx={{
                  border: errors?.find(
                    (error) => error?.key == "internal_bounty_amount"
                  )
                    ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
                    : "1px solid #E0E0E0",
                  width: "80% !important",
                  borderRadius: "10px !important",
                  "& .MuiInputBase-root": {
                    margin: "0 10px",
                  },
                }}
              />
            </Box>
          </Box>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography
              sx={{
                fontSize: "14px",
                width: "120px",
                fontWeight: theme.typography.Bold,
              }}
            >
              External
            </Typography>
            <Box sx={{ display: "flex", gap: "10px", alignItems: "center" }}>
              <InputBox
                id="external_bounty_amount"
                value={bountyAmount?.external_bounty_amount}
                onChange={(event) => handleBountyChane(event)}
                placeholder={"Enter external bounty amount"}
                sx={{
                  border: errors?.find(
                    (error) => error?.key == "external_bounty_amount"
                  )
                    ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
                    : "1px solid #E0E0E0",
                  width: "80% !important",
                  borderRadius: "10px !important",
                  "& .MuiInputBase-root": {
                    margin: "0 10px",
                  },
                }}
              />
            </Box>
          </Box>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography
              sx={{
                fontSize: "14px",
                width: "120px",
                fontWeight: theme.typography.Bold,
              }}
            >
              Talent Partner
            </Typography>
            <Box sx={{ display: "flex", gap: "10px", alignItems: "center" }}>
              <InputBox
                id="talent_partner_bounty_amount"
                value={bountyAmount?.talent_partner_bounty_amount}
                onChange={(event) => handleBountyChane(event)}
                placeholder={"Enter internal bounty amount"}
                sx={{
                  border: errors?.find(
                    (error) => error?.key == "talent_partner_bounty_amount"
                  )
                    ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
                    : "1px solid #E0E0E0",
                  width: "80% !important",
                  borderRadius: "10px !important",
                  "& .MuiInputBase-root": {
                    margin: "0 10px",
                  },
                }}
              />
            </Box>
          </Box>
        </Box>

        <Button
          variant="contained"
          color="redButton"
          sx={{
            width: "100%",
            height: "60px",
            borderRadius: "0 0 25px 25px",
          }}
          onClick={() => handleUpdatePool()}
        >
          update
        </Button>
      </Box>
    </Modal>
  );
}
