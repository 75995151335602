import { Box, Button, Modal, Typography } from "@mui/material";
import React from "react";
import { useTheme } from "@emotion/react";
// import CompanyModalPopupModal from "../../assets/CompanyModalPopupImage.svg";
import { useNavigate } from "react-router-dom";
import PaymentBot from "../../assets/Characters/CrayBotIcons/Crayon_paymentPage.svg";
import QRImage from "../../assets/PaymentQrcode.png";
import CloseIcon from "../common/CloseIcon";

const PaymentModal = ({
  open,
  setOpen,
  handleReviewLater,
  cashAmount = "R1092.50",
}) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    height: "auto",
    width: { xs: "360px", tablet: "450px" },
    bgcolor: "background.paper",
    borderRadius: "25px",
    boxShadow: "none !important",
    "&:focus-visible": {
      outline: "none",
    },
  };
  const formatToTwoDecimalPlaces =(number)=> {
    if (typeof number !== 'number') {
      throw new Error('Input must be a number');
    }
    return Math.round(number * 100) / 100;
  }

  const navigateGoToMyTeam = () => {
    window.open("https://page.peachpayments.com/crayon", "_blank");
    setOpen();
  };

  return (
    <Modal
      open={open}
      onClose={setOpen}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Box
          sx={{
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
            // gap: 3,
            width: "100%",
          }}
        >
          <CloseIcon top="15px" right="15px" onClick={setOpen} />
          <Box
            sx={{
              padding: { xs: "0 10px", tablet: "0 15px" },
            }}
          >
            <Box
              component={"img"}
              src={PaymentBot}
              sx={{ width: 136, height: 125, marginTop: "20px" }}
            />
            <Typography
              sx={{
                fontSize: "20px",
                fontWeight: theme.typography.Bold,
                lineHeight: "27px",
                marginBottom: "20px",
              }}
            >
              Cash me outside, how ‘bout dat
            </Typography>
            <Typography
              sx={{
                fontSize: "14px",
                fontWeight: theme.typography.Bold,
                marginBottom: "20px",
              }}
            >
              We’re busy finalising our checkout process and will cater for
              automated payments in a couple of days.
            </Typography>
            <Typography
              sx={{
                fontSize: "14px",
                fontWeight: theme.typography.Regular,
                marginBottom: "20px",
              }}
            >
              You can either pay{" "}
              <span style={{ fontSize: "14px", fontWeight: "700" }}>
                {`R${formatToTwoDecimalPlaces(Number(cashAmount))}`}
              </span>{" "}
              now via an external link or we’ll be in touch with an invoice and
              payment request. Reference is your company name.
            </Typography>
            <Box
              component={"img"}
              src={QRImage}
              sx={{ width: 136, height: 125, marginBottom: "20px" }}
            />
          </Box>
          <Box sx={{ width: "100%" }}>
            <Button
              sx={{
                width: "50%",
                borderRadius: "0px 0px 0px 25px",
                height: "60px",
                fontWeight: theme.typography.Bold,
                fontSize: "14px",
              }}
              onClick={handleReviewLater}
              variant="contained"
              color="newSubmit"
            >
              skip for now
            </Button>
            <Button
              sx={{
                width: "50%",
                borderRadius: "0px 0px 25px 0px",
                height: "60px",
                fontWeight: theme.typography.Bold,
                fontSize: "14px",
              }}
              variant="contained"
              onClick={() => {
                navigateGoToMyTeam();
              }}
              color="nandos"
            >
              pay now
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default PaymentModal;
