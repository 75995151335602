import React from "react";
import CustomDialog from "../../../common/CustomDialog";
import { Box, Button, Switch, Typography } from "@mui/material";
import styled from "@emotion/styled";
import { alpha } from "@material-ui/core";
import RedTriangle from "../../../../assets/Characters/Red_Triangle_Blank.svg";
import locale from "../../../../i18n/locale";

const BlueSwitch = styled(Switch)(({ theme }) => ({
  "& .MuiSwitch-switchBase.Mui-checked": {
    color: theme.palette.greenButton.main,
    "&:hover": {
      backgroundColor: alpha(
        theme.palette.greenButton.main,
        theme.palette.action.hoverOpacity
      ),
    },
  },
  "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
    backgroundColor: theme.palette.greenButton.main,
  },
  "& .MuiSwitch-track": {
    margin: "auto",
    height: "60% !important",
  },
  "& .MuiSwitch-thumb": {
    borderRadius: "6px !important",
  },
  "& .MuiButtonBase-root-MuiSwitch-switchBase": {
    borderRadius: "15% !important",
  },
}));

export default function Delete({
  show,
  handleOpen,
  handleDelete,
  dialogText,
  confirmDelete,
  handleCancel,
  setConfirmDelete,
}) {
  const i18n = locale.en;
  const handleDeleteConfirm = (event) => {
    setConfirmDelete(event?.target?.checked);
  };

  return (
    <CustomDialog
      show={show}
      hideButton={false}
      dialogWidth="xs"
      showFooter={false}
      onDialogClose={handleOpen}
      padding={0}
    >
      <Box
        sx={{
          padding: 4,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Box
          component={"img"}
          src={RedTriangle}
          sx={{ width: 150, height: 100 }}
        />
        <Typography
          variant="h5"
          fontWeight={"Bold"}
          textAlign={"center"}
          sx={{
            marginBottom: 3,
          }}
        >
          {i18n["empMyCam.sureDelete"]}
        </Typography>
        <Typography
          fontSize={16}
          fontWeight={"bold"}
          textAlign={"center"}
          paragraph
        >
          {i18n["empMyCam.confirmDelete"]} {dialogText}
        </Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography>{i18n["empMyCam.clickToConfirm"]}</Typography>

          <BlueSwitch
            checked={confirmDelete}
            onChange={(event) => handleDeleteConfirm(event)}
          />
        </Box>
      </Box>
      <Box>
        <Button
          sx={{
            boxShadow: 0,
            fontSize: "14px",
            width: "50%",
            borderRadius: 0,
            height: "43px",
            background: "lightgray",
            color: "black",
            padding: 3,
            ":hover": {
              background: "lightgray",
            },
          }}
          variant="contained"
          onClick={handleCancel}
        >
          {i18n["empMyCam.cancel"]}
        </Button>
        <Button
          sx={{
            boxShadow: 0,
            fontSize: "14px",
            width: "50%",
            borderRadius: 0,
            height: "43px",
            padding: 3,
          }}
          variant="contained"
          color="redButton100"
          onClick={handleDelete}
          disabled={!confirmDelete}
        >
          {i18n["empMyCam.continue"]}
        </Button>
      </Box>
    </CustomDialog>
  );
}
