import React, { useRef, useState } from "react";
import { Box, Button, Typography, useTheme } from "@mui/material";
import { RadioButtonChecked } from "@mui/icons-material";
import { useDispatch } from "react-redux";
import jwt_decode from "jwt-decode";
import { uploadMyCamVideo } from "../../../../redux/candidate/candidateJobs";
import { setAlert } from "../../../../redux/configSlice";
import { ALERT_TYPE } from "../../../../utils/Constants";

import myVideoicon from "../../../../assets/CrayBotIcons/Crayon Cam Video Charactersvg.svg";
import myVideoHeaderLogo from "../../../../assets/Cam/MyVideoIcon.svg";
import uploadNewVideoIcon from "../../../../assets/Cam/UploadVideoIcon.svg";
import { getVideoFileDuration } from "../../../../utils/Common";
import jobVideoHeaderLogo from "../../../../assets/Cam/JobVideoIcon.svg";

const AddVideo = ({ nextStep, getVideo, setTextChange }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const hiddenFileInput = useRef(null);
  const fileAccept = "video/*";
  const [videoLink, setVideoLink] = useState(null);
  const VideoRef = useRef(null);

  const token = localStorage?.getItem("token");
  let decodedToken;
  if (token) {
    decodedToken = jwt_decode(token);
  }
  const handleFileChange = async (event) => {
    const selectedFile = event.target.files[0];
    if (!selectedFile) {
      return;
    }
    getVideoFileDuration(selectedFile, (error, videoDuration) => {
      if (error) {
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE?.ERROR,
            msg: error,
          })
        );
      }
      if (event?.target?.files?.[0]?.size > 100000000) {
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE?.ERROR,
            msg: "Video size should be less than 100MB",
          })
        );
        return;
      } else if (
        event?.target?.files?.[0]?.name?.split(".")?.pop()?.toLowerCase() !==
          "mp4" &&
        event?.target?.files?.[0]?.name?.split(".")?.pop()?.toLowerCase() !==
          "mov" && 
          event?.target?.files?.[0]?.name?.split(".")?.pop()?.toLowerCase() !==
          "webm" 
      
      ) {
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE?.ERROR,
            msg: "Please upload .mp4 or .mov file",
          })
        );
        return;
      } else if (videoDuration > 60) {
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE?.ERROR,
            msg: "Video length should be less than 1 min",
          })
        );
        return;
      }

      const formData = new FormData();
      formData?.append("cam", event?.target?.files?.[0]);
      try {
        dispatch(uploadMyCamVideo(formData)).then(({ payload }) => {
          if (payload?.status === "success") {
            dispatch(
              setAlert({
                show: true,
                type: ALERT_TYPE.SUCCESS,
                msg: "Video uploaded Successfully!",
              })
            );
            setTextChange(true);
            nextStep(4);
            getVideo().then(() => {
              setVideoLink(URL?.createObjectURL(event?.target?.files?.[0]));
              VideoRef?.current?.load();
            });
          } else {
            dispatch(
              setAlert({
                show: true,
                type: ALERT_TYPE.ERROR,
                msg: "Failed to upload Video",
              })
            );
          }
        });
      } catch (error) {
        dispatch(setAlert({ show: true }));
      }
    });
  };

  const handleFileClick = () => {
    hiddenFileInput?.current?.click();
  };

  return (
    <>
      <Button
        variant="contained"
        color="precium"
        sx={{
          borderRadius: "25px 0 10px 0",
          width: "150px",
          height: "45px",
          fontSize: "14px",

          fontWeight: theme.typography.Bold,
          gap: "10px",
        }}
      >
        <Box
          component={"img"}
          src={myVideoHeaderLogo}
          sx={{
            height: "20px",
            width: "24px",
          }}
        />
        my video
      </Button>
      <Box
        sx={{
          // width: { xs: "90%", md: "70%" },
          width: "100%",
          flexDirection: "column",
          alignItems: "center",
          display: "flex",
          gap: "20px",
          marginBottom: { xs: "10px", md: "" },
          marginTop: "30px",
        }}
      >
        <Typography
          sx={{
            fontSize: "20px",
            fontWeight: theme.typography.Bold,
            textAlign: "center",
          }}
        >
          You’re the star of the show!
        </Typography>
      </Box>
      <Box
        sx={{
          padding: { xs: "10px 20px 20px", md: "10px 20px 57px" },
          flexDirection: "column",
          alignItems: "center",
          display: "flex",
          gap: "25px",
          flex: { xs: "1", lg1440: "" },
          justifyContent: { tb: "center", lg1440: "" },
        }}
      >
        <Box
          component={"img"}
          src={myVideoicon}
          sx={{
            height: {
              xs: "200px",
              xs360: "200px",
              xs390: "200px",
              md24: "250px",
              lgm: "250px",
            },
            maxHeight: "250px",
            width: "auto",
          }}
        />
        <Box
          sx={{
            width: { xs: "90%", md: "70%" },
            flexDirection: "column",
            alignItems: "center",
            display: "flex",
            gap: "20px",
            marginBottom: { xs: "10px", md: "" },
          }}
        >
          <Typography
            sx={{
              fontSize: "14px",
              textAlign: "center",

              fontWeight: theme.typography.Regular,
            }}
          >
            Give us your elevator pitch! Record or upload a short intro video,
            highlighting your experience, skills, preferred bag of tools and
            what your ideal next career steps might look like.
          </Typography>
          <Typography
            sx={{
              fontSize: "14px",
              textAlign: "center",
              fontWeight: theme.typography.Regular,
            }}
          >
            Completing your profile with a video increases your chances with
            employers and is often the first thing looked at!
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
        }}
      >
        <input
          accept={fileAccept}
          ref={hiddenFileInput}
          type="file"
          onChange={handleFileChange}
          style={{ display: "none" }}
        />
        <Button
          variant="contained"
          color="yoco"
          sx={{
            width: "50%",
            height: "60px",
            borderRadius: "0 0 0px 25px",
            fontSize: "15px",
            gap: "10px",
            fontWeight: theme.typography.Bold,
          }}
          onClick={handleFileClick}
        >
          <Box
            component={"img"}
            src={uploadNewVideoIcon}
            sx={{
              height: "20px",
              width: "24px",
            }}
          />
          upload video
        </Button>
        <Button
          variant="contained"
          color="videoButton"
          sx={{
            width: "50%",
            height: "60px",
            borderRadius: "0 0  25px 0px",
            fontSize: "14px",
            gap: "8px",
            fontWeight: theme.typography.Bold,
          }}
          onClick={() => nextStep(2)}
        >
          <Box
            component={"img"}
            src={jobVideoHeaderLogo}
            sx={{
              height: "20px",
              width: "24px",
            }}
          />
          record video
        </Button>
      </Box>
    </>
  );
};

export default AddVideo;
