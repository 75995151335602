import { useTheme } from "@emotion/react";
import {
  Box,
  Button,
  Input,
  InputBase,
  InputLabel,
  Modal,
  Paper,
} from "@mui/material";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import InputBox from "../../../common/InputBox";
import {
  addSolution,
  editSolution,
  uploadChallangeVideo,
  uploadSolutionVideo,
} from "../../../../redux/admin/maintenance";
import CustomSelect from "../../../candidate/myCV/CustomSelect";
import { debounce, validateSchema } from "../../../../utils/Common";
import * as yup from "yup";
import { setAlert } from "../../../../redux/configSlice";
import { ALERT_TYPE } from "../../../../utils/Constants";
import ReviewVideoDialog from "../../../common/ReviewVideoDialog";
import PlayButton from "../../../common/PlayButton";
import videoPlaceholder from "../../../../assets/VideoPlaceholder.png";
import { BlueSwitch, ToggleSwitch } from "../../../../utils/CommonComponent";

const AddSolutionModal = ({
  handleClose,
  handleOpen,
  edit,
  handleCancel,
  setIsAddSoutionModal,
  isAddSolutionModal,
  solutionTypeList,
  getSolutionList,
  editSolutionData,
  setEditSolutionData,
}) => {
  const [formData, setFormData] = useState({
    user_types: [],
    solution_types: [],
    challenge_video_flag: false,
    solution_video_flag: false,
    challenge_link_flag: false,
    solution_link_flag: false,
  });
 
  const [errors, setErrors] = useState({});
  const dispatch = useDispatch();
  const theme = useTheme();
  const challangeVideoRef = useRef(null);
  const solutionVideoRef = useRef(null);
  const [videoIdSolution_video_link, setVideoIdSolution_video_link] =
    useState("");
  const [videoIdChallenge_video_link, setVideoIdChallenge_video_link] =
    useState("");
  const fileAccept = "video/*";
  const challangeVideoInput = useRef(null);
  const solutionVideoInput = useRef(null);
  const [openReviewVideoDialog, setOpenReviewVideoDialog] = useState(false);
  const [disabledButton, setDisabledButton] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [inMenuOpen, setIsMenuOpen] = useState(false);
  const [challangeVideoUploadURL, setChallangeVideoUploadURL] =
    useState("No URL");
  const [challangeVideoUploadLink, setChallangeVideoUploadLink] = useState("");
  const [solutionVideoUploadLink, setSolutionVideoUploadLink] = useState("");
  const [solutionVideoUploadURL, setSolutionVideoUploadURL] =
    useState("No URL");
  const [isPlaying, setIsPlaying] = useState(false);
  const [viewVideoLink, setViewVideolink] = useState("");
  const videoIdRegex =
    /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
  const youtubeRegex = /^(https?\:\/\/)?(www\.youtube\.com|youtu\.?be)\/.+$/;


  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: { xs: "auto", tablet: 700 },
    height: { xs: "auto", tablet: 700 },
    bgcolor: "background.paper",
    borderRadius: "25px",
    boxShadow: "none !important",
    "&:focus-visible": {
      outline: "none",
    },
  };

  const handleChallangeVideo = () => {
    challangeVideoInput.current.click();
  };

  const handleSolutionVideo = () => {
    solutionVideoInput.current.click();
  };

  const handleCloseDialog = () => {
    setViewVideolink("");
    setOpenReviewVideoDialog(false);
  };

  const validateFileSize = (file) => {
    if (file.size > 100000000) {
      dispatch(
        setAlert({
          show: true,
          type: ALERT_TYPE.ERROR,
          msg: "Video size should be less than 100MB",
        })
      );
      return false;
    }
    return true;
  };

  const handleFileChange = (event, text) => {
    const selectedFile = event.target.files[0];
    if (!selectedFile) {
      return;
    }

    if (validateFileSize(selectedFile)) {
      const link = URL.createObjectURL(selectedFile);
      if (text === "challangeVideo") {
        setChallangeVideoUploadURL(link);
        setChallangeVideoUploadLink(selectedFile);
        if (challangeVideoRef?.current) {
          challangeVideoRef?.current?.load();
        }
      } else if (text === "solutionVideo") {
        setSolutionVideoUploadURL(link);
        setSolutionVideoUploadLink(selectedFile);
        if (solutionVideoRef?.current) {
          solutionVideoRef?.current?.load();
        }
      }
    } else {
      dispatch(
        setAlert({
          show: true,
          type: ALERT_TYPE?.ERROR,
          msg: "Error",
        })
      );
    }
  };

  const uploadVideo = async (file, isUpload, solution_id, text) => {
    const formData = new FormData();
    const myFile = new File([file], "videoFile.webm", {
      type: file.type,
    });
    if (text === "challange") {
      formData.append("solutionchallengevideo", myFile);
      formData.append("solution_id", solution_id);
    } else {
      formData.append("solutionvideo", myFile);
      formData.append("solution_id", solution_id);
    }

    try {
      const { payload } = await dispatch(
        text === "challange"
          ? uploadChallangeVideo(formData)
          : uploadSolutionVideo(formData)
      );
      if (payload?.status === "success") {
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE.SUCCESS,
            msg: "Video uploaded Successfully!",
          })
        );
      } else {
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE.ERROR,
            msg: "Failed to upload Video",
          })
        );
      }
    } catch (error) {
      dispatch(
        setAlert({
          show: true,
          type: ALERT_TYPE.ERROR,
          msg: "Failed to upload Video",
        })
      );
    }
  };

  const handleFormInput = (event, name) => {
    const url = event.target.value;
    if (name === "challenge_video_link" || name === "solution_video_link") {
      if (!url) {
        if (name === "challenge_video_link") {
          setVideoIdChallenge_video_link("");
        } else if (name === "solution_video_link") {
          setVideoIdSolution_video_link("");
        }
      } else {
        const match = url.match(videoIdRegex);
        if (match && match[1]) {
          if (name === "challenge_video_link") {
            setVideoIdChallenge_video_link(match[1]);
          } else {
            setVideoIdSolution_video_link(match[1]);
          }
        } else {
          if (name === "challenge_video_link") {
            setVideoIdChallenge_video_link("");
          } else if (name === "solution_video_link") {
            setVideoIdSolution_video_link("");
          }
        }
      }
    }
    event?.preventDefault();
    event?.stopPropagation();
    setFormData((prev) => ({ ...prev, [name]: event?.target?.value }));
    const updatedError = errors;
    delete updatedError[name];
    setErrors(updatedError);
  };

  const handleChangeSetupchallenge_video = (event, name) => {
    const isChecked = event?.target?.checked;
    const newProfileData = {
      ...formData,
      [name]: isChecked,
      challenge_link_flag: isChecked ? false : formData.challenge_link_flag,
    };
    setFormData(newProfileData);
  };

  const handleChangeSetupchallenge_link = (event, name) => {
    const isChecked = event?.target?.checked;
    const newProfileData = {
      ...formData,
      [name]: isChecked,
      challenge_video_flag: isChecked ? false : formData.challenge_video_flag,
    };
    setFormData(newProfileData);
  };

  const handleChangeSetupSolution_video = (event, name) => {
    const isChecked = event?.target?.checked;
    const newProfileData = {
      ...formData,
      [name]: isChecked,
      solution_link_flag: isChecked ? false : formData.solution_link_flag,
    };
    setFormData(newProfileData);
  };

  const handleChangeSetupSolution_link = (event, name) => {
    const isChecked = event?.target?.checked;
    const newProfileData = {
      ...formData,
      [name]: isChecked,
      solution_video_flag: isChecked ? false : formData.solution_video_flag,
    };
    setFormData(newProfileData);
  };

  // const handleChangeSetupchallenge_video = (event, name) => {
  //   let newProfileData = {
  //     ...formData,
  //     [name]: event?.target?.checked,
  //   };
  //   if (event?.target?.checked) {
  //     newProfileData = {
  //       ...formData,
  //       "challenge_link_flag": !event?.target?.checked,
  //     };
  //   }
  //   setFormData(newProfileData);
  // };
  // const handleChangeSetupchallenge_link = (event, name) => {
  //   let newProfileData = {
  //     ...formData,
  //     [name]: event?.target?.checked,
  //   };
  //   if (event?.target?.checked) {
  //     newProfileData = {
  //       ...formData,
  //       "challenge_video_flag": !event?.target?.checked,
  //     };
  //   }
  //   setFormData(newProfileData);
  // };

  // const handleChangeSetupSolution_video = (event, name) => {
  //   let newProfileData = {
  //     ...formData,
  //     [name]: event?.target?.checked,
  //   };
  //   setFormData(newProfileData);

  //   if (event?.target?.checked) {
  //    let profileData = {
  //       ...formData,
  //       "solution_link_flag": !event?.target?.checked,
  //     };
  //   setFormData(profileData);
  //   }
  // };
  // const handleChangeSetupSolution_link = (event, name) => {
  //   let newProfileData = {
  //     ...formData,
  //     [name]: event?.target?.checked,
  //   };
  //   setFormData(newProfileData);
  //   if (event?.target?.checked) {
  //    let profileData = {
  //       ...formData,
  //       "solution_video_flag": !event?.target?.checked,
  //     };
  //   setFormData(profileData);

  //   }
  // };
  // const handleChangeSetup = (event, name) => {
  //   const isChecked = event?.target?.checked;
  //   setFormData((prevData) => {
  //     let newProfileData = { ...prevData, [name]: isChecked };
  //     // Handle challenge flags
  //     if (name === "challenge_video_flag" && isChecked) {
  //       newProfileData.challenge_link_flag = false;
  //     } else if (name === "challenge_link_flag" && isChecked) {
  //       newProfileData.challenge_video_flag = false;
  //     }
  //     // Handle solution flags
  //     if (name === "solution_video_flag" && isChecked) {
  //       newProfileData.solution_link_flag = false;
  //     } else if (name === "solution_link_flag" && isChecked) {
  //       newProfileData.solution_video_flag = false;
  //     }
  //     return newProfileData;
  //   });
  // };

  const userType = [
    { label: "Employers", value: "1" },
    { label: "Recruiters", value: "2" },
    { label: "Candidates", value: "3" },
    { label: "Promoters", value: "4" },
  ];
  const handleCreate = () => {
    if (edit) {
      editItem();
    } else {
      addItem();
    }
  };

  const validationSchema = yup.object().shape({
    title: yup.string().required("Title is required"),
    user_types:
      // yup.array()
      // .min(1, "At least one user_types is required")
      // .of(yup.string()),
      yup
        .array()
        .min(1, "At least one solution type is required")
        .of(
          yup.object().shape({
            value: yup.string().required(),
            label: yup.string().required(),
          })
        ),
    solution_types: yup
      .array()
      .min(1, "At least one solution type is required")
      .of(
        yup.object().shape({
          value: yup.string().required(),
          label: yup.string().required(),
        })
      ),
    industry_challenge: yup.string().required("Industry Challenge is required"),
    industry_challenge_detail: yup
      .string()
      .required("Industry Challenge Detail is required"),
    crayon_solution: yup.string().required("Crayon Solution is required"),
    crayon_solution_detail: yup
      .string()
      .required("Crayon Solution Detail is required"),
      solution_video_link: yup
      .string()
      .nullable() // Allows null or empty string
      .test(
        "is-valid-youtube-url",
        "Enter a valid YouTube URL",
        (value) => !value || youtubeRegex.test(value)
      ),
    challenge_video_link: yup
      .string()
      .nullable() // Allows null or empty string
      .test(
        "is-valid-youtube-url",
        "Enter a valid YouTube URL",
        (value) => !value || youtubeRegex.test(value)
      ),
  });

  const addItem = async () => {
    let payload = {
      industry_challenge: formData?.industry_challenge,
      industry_challenge_detail: formData?.industry_challenge_detail,
      crayon_solution: formData?.crayon_solution,
      crayon_solution_detail: formData?.crayon_solution_detail,
      title: formData?.title,
      user_types: formData?.user_types?.map((item) => item?.value), // employer 1 recruiter 2 candidate 3 promoter 4
      solution_types: formData?.solution_types
        ?.map((item) => item?.label)
        ?.join(","),
      challenge_video_link: formData?.challenge_video_link,
      solution_video_link: formData?.solution_video_link,
      challenge_video_flag: formData?.challenge_video_flag,
      solution_video_flag: formData?.solution_video_flag,
      challenge_link_flag: formData?.challenge_link_flag,
      solution_link_flag: formData?.solution_link_flag,
    };
    try {
      await validateSchema(validationSchema, formData);
      const result = await dispatch(addSolution(payload));
      if (result?.payload?.status === "success") {
        const solutionId = result?.payload?.data?.solution_id;
        // Ensure the first upload completes before starting the second
        challangeVideoUploadLink !== "" &&
          (await uploadVideo(
            challangeVideoUploadLink,
            true,
            solutionId,
            "challange"
          ));
        solutionVideoUploadLink !== "" &&
          (await uploadVideo(
            solutionVideoUploadLink,
            true,
            solutionId,
            "solution"
          ));
        await getSolutionList(0);
        setIsAddSoutionModal(false);
        setEditSolutionData({});
      } else {
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE.ERROR,
            msg: "Something went wrong",
          })
        );
      }
    } catch (error) {
      setErrors(error);
    }
  };

  const editItem = async () => {
    let payload = {
      industry_challenge: formData?.industry_challenge,
      industry_challenge_detail: formData?.industry_challenge_detail,
      crayon_solution: formData?.crayon_solution,
      crayon_solution_detail: formData?.crayon_solution_detail,
      title: formData?.title,
      user_types: formData?.user_types?.map((item) => item?.value), //employer 1 recuiter 2 candidate 3 promoter 4
      solution_types: formData?.solution_types
        ?.map((item) => item?.label)
        ?.join(","),
      like: editSolutionData?.like,
      dislike: editSolutionData?.dislike,
      loveit: editSolutionData?.loveit,
      solution_id: editSolutionData?.solution_id,
      challenge_video_link: formData?.challenge_video_link,
      solution_video_link: formData?.solution_video_link,
      challenge_video_flag: formData?.challenge_video_flag,
      solution_video_flag: formData?.solution_video_flag,
      challenge_link_flag: formData?.challenge_link_flag,
      solution_link_flag: formData?.solution_link_flag,
    };
    try {
      await validateSchema(validationSchema, formData);
      const result = await dispatch(editSolution(payload));
      if (result?.payload?.status === "success") {
        const solutionId = editSolutionData?.solution_id;
        // Ensure the first upload completes before starting the second
        challangeVideoUploadLink !== editSolutionData?.challenge_video &&
          (await uploadVideo(
            challangeVideoUploadLink,
            true,
            solutionId,
            "challange"
          ));
        solutionVideoUploadLink !== editSolutionData?.solution_video &&
          (await uploadVideo(
            solutionVideoUploadLink,
            true,
            solutionId,
            "solution"
          ));
        await getSolutionList(0);
        setIsAddSoutionModal(false);
        setEditSolutionData({});
      } else {
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE.ERROR,
            msg: "Something went wrong",
          })
        );
      }
    } catch (error) {
      setErrors(error);
    }
  };

  const debouncedHandleOnSelectInputChange = useCallback(
    debounce(
      async (newValue, type) => {
        let params = {
          title: newValue || "",
          limit: 200,
        };
        if (newValue) {
          try {
            if (type === "solution_types") {
            }
          } catch (error) {}
        }
      },
      750,
      setIsLoading
    ),
    []
  );

  const handleOnSelectInputChange = (newValue, inputId) => {
    debouncedHandleOnSelectInputChange(newValue, inputId);
  };
  const handleOnSelectChange = (ind, newValue, val, inputId) => {
    const updatedData = {
      ...formData,
      [ind]: newValue,
    };
    setFormData(updatedData);
    const updatedError = errors;
    delete updatedError[ind];
    setErrors(updatedError);
  };

  useEffect(() => {
    if (edit) {
      setFormData({
        ...formData,
        title:
          editSolutionData?.title?.charAt(0)?.toUpperCase() +
          editSolutionData?.title?.slice(1),
        user_types: editSolutionData?.user_types?.split(",")?.map((item) => {
          const foundType = userType.find((type) => type.label === item);
          return {
            value: foundType?.value || "",
            label: item,
          };
        }),
        solution_types: editSolutionData?.solution_types
          ?.split(",")
          ?.map((item) => {
            const foundType = solutionTypeList.find(
              (type) => type.name === item
            );
            return {
              value: foundType?.solution_type_id || "",
              label: item,
            };
          }),
        industry_challenge: editSolutionData?.industry_challenge,
        industry_challenge_detail: editSolutionData?.industry_challenge_detail,
        crayon_solution: editSolutionData?.crayon_solution,
        crayon_solution_detail: editSolutionData?.crayon_solution_detail,
        challenge_video_flag: editSolutionData?.challenge_video_flag,
        solution_video_flag: editSolutionData?.solution_video_flag,
        challenge_link_flag: editSolutionData?.challenge_link_flag,
        solution_link_flag: editSolutionData?.solution_link_flag,
        challenge_video_link: editSolutionData?.challenge_video_link,
        solution_video_link: editSolutionData?.solution_video_link,
      });
      const matchChallengeVideoLink = editSolutionData?.challenge_video_link?.match(videoIdRegex);
      const matchSolutionVideoLink = editSolutionData?.solution_video_link?.match(videoIdRegex);
      if (matchChallengeVideoLink && matchChallengeVideoLink[1]) {
        setVideoIdChallenge_video_link(matchChallengeVideoLink[1]);
      } else {
        setVideoIdChallenge_video_link("");
      }
      if (matchSolutionVideoLink && matchSolutionVideoLink[1]) {
        setVideoIdSolution_video_link(matchSolutionVideoLink[1]);
      } else {
        setVideoIdSolution_video_link("");
      }
      setChallangeVideoUploadURL(editSolutionData?.challenge_video);
      setChallangeVideoUploadLink(editSolutionData?.challenge_video);
      setSolutionVideoUploadURL(editSolutionData?.solution_video);
      setSolutionVideoUploadLink(editSolutionData?.solution_video);

    }
  }, [edit]);

  return (
    <>
      <Modal open={handleOpen} onClose={handleClose}>
        <Box sx={style}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "end",
            }}
          >
            <Button
              variant="contained"
              color="yellowButton400"
              sx={{
                height: "45px",
                width: "200px",
                borderRadius: "0 25px 0 25px",
              }}
            >
              {edit ? "edit a solution" : "add a solution"}
            </Button>
          </Box>

          <Box sx={{ padding: "20px", overflowY: "auto", height: "568px" }}>
            <Box sx={{ marginBottom: "5px" }}>
              <InputLabel
                htmlFor="title"
                sx={{
                  color: "black",
                  paddingBottom: "2px",
                  fontSize: "14px",
                  fontWeight: 600,
                }}
              >
                Title
              </InputLabel>
              <Paper
                elevation={3}
                sx={{
                  display: "flex",
                  borderRadius: "10px",
                  height: "40px",
                  boxShadow: "none",
                  border: errors?.["title"]
                    ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
                    : `1px solid ${theme.palette.grayBorder}`,
                }}
              >
                <InputBase
                  sx={{ ml: 2, mr: 2, width: "100%" }}
                  id="title"
                  type="text"
                  placeholder={"Enter your title"}
                  value={formData?.title}
                  onChange={(e) => handleFormInput(e, "title")}
                />
              </Paper>
            </Box>
            <Box sx={{ marginBottom: "5px" }}>
              <InputLabel
                htmlFor="user_types"
                sx={{
                  color: "black",
                  paddingBottom: "2px",
                  fontSize: "14px",
                  fontWeight: 600,
                }}
              >
                User Type
              </InputLabel>
              <CustomSelect
                closeMenuOnSelect={false}
                variant="multiSelect"
                isMulti={true}
                onInputChange={handleOnSelectInputChange}
                onChange={handleOnSelectChange}
                // placeholder={"Search or add job title"}
                placeholder={"Select Users Types"}
                inputId="user_types"
                name="user_types"
                value={
                  formData?.user_types?.length > 0 ? formData?.user_types : null
                }
                multiOnFocus={() => {
                  setIsMenuOpen(true);
                }}
                isLoading={false}
                customStyle={{
                  option: (
                    styles,
                    { data, isDisabled, isFocused, isSelected }
                  ) => {
                    return {
                      ...styles,
                      color: isSelected ? "#fff" : "black",
                      ":active": {
                        ...styles[":active"],
                      },
                    };
                  },
                  control: (baseStyles) => ({
                    ...baseStyles,
                    boxShadow: "none",
                    borderRadius: "10px",
                    height: `40px`,
                    border: errors?.["user_types"]
                      ? `1px solid ${theme?.jobCard?.jobStatus?.Rejected?.main}`
                      : "1px solid #E0E0E0",
                    "&:hover": errors?.["user_types"]
                      ? `1px solid ${theme?.jobCard?.jobStatus?.Rejected?.main}`
                      : "1px solid #E0E0E0",
                    width: `100%`,
                  }),
                }}
                multiSelectOptions={userType}
              />
            </Box>
            <Box sx={{ marginBottom: "5px" }}>
              <InputLabel
                htmlFor="solution_types"
                sx={{
                  color: "black",
                  paddingBottom: "2px",
                  fontSize: "14px",
                  fontWeight: 600,
                }}
              >
                Solution Types
              </InputLabel>
              <CustomSelect
                closeMenuOnSelect={false}
                variant="multiSelect"
                isMulti={true}
                onInputChange={handleOnSelectInputChange}
                onChange={handleOnSelectChange}
                // placeholder={"Search or add job title"}
                placeholder={"Select Solution Types"}
                inputId="solution_types"
                name="solution_types"
                value={
                  formData?.solution_types?.length > 0
                    ? formData?.solution_types
                    : null
                }
                multiOnFocus={() => {
                  setIsMenuOpen(true);
                }}
                isLoading={false}
                customStyle={{
                  option: (
                    styles,
                    { data, isDisabled, isFocused, isSelected }
                  ) => {
                    return {
                      ...styles,
                      color: isSelected ? "#fff" : "black",
                      ":active": {
                        ...styles[":active"],
                      },
                    };
                  },
                  control: (baseStyles) => ({
                    ...baseStyles,
                    boxShadow: "none",
                    borderRadius: "10px",
                    // height: `40px`,
                    border: errors?.["solution_types"]
                      ? `1px solid ${theme?.jobCard?.jobStatus?.Rejected?.main}`
                      : "1px solid #E0E0E0",
                    "&:hover": errors?.["solution_types"]
                      ? `1px solid ${theme?.jobCard?.jobStatus?.Rejected?.main}`
                      : "1px solid #E0E0E0",
                    width: `100%`,
                  }),
                }}
                multiSelectOptions={solutionTypeList?.map((item) => ({
                  value: item?.solution_type_id,
                  label: item?.name,
                }))}
              />
            </Box>
            <Box sx={{ marginBottom: "5px" }}>
              <InputLabel
                htmlFor="industry_challenge"
                sx={{
                  color: "black",
                  paddingBottom: "2px",
                  fontSize: "14px",
                  fontWeight: 600,
                }}
              >
                Industry Challenge
              </InputLabel>
              <InputBox
                multiline={true}
                sx={{
                  width: "100%",
                  height: "120px",
                  borderRadius: "10px",
                  alignItems: "flex-start",
                  border: errors?.["industry_challenge"]
                    ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
                    : "1px solid #E0E0E0",
                }}
                placeholder={"Enter your Industry Challenge"}
                value={formData?.industry_challenge}
                id="industry_challenge"
                onChange={(e) => handleFormInput(e, "industry_challenge")}
              />
            </Box>
            <Box sx={{ marginBottom: "5px" }}>
              <InputLabel
                htmlFor="industry_challenge_detail"
                sx={{
                  color: "black",
                  paddingBottom: "2px",
                  fontSize: "14px",
                  fontWeight: 600,
                }}
              >
                Industry Challenge Detail
              </InputLabel>
              <InputBox
                multiline={true}
                sx={{
                  width: "100%",
                  height: "120px",
                  borderRadius: "10px",
                  alignItems: "flex-start",
                  border: errors?.["industry_challenge_detail"]
                    ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
                    : "1px solid #E0E0E0",
                }}
                placeholder={"Enter your Industry Challenge Detail"}
                value={formData?.industry_challenge_detail}
                id="industry_challenge_detail"
                onChange={(e) =>
                  handleFormInput(e, "industry_challenge_detail")
                }
              />
            </Box>
            <Box sx={{ marginBottom: "5px" }}>
              <InputLabel
                htmlFor="crayon_solution"
                sx={{
                  color: "black",
                  paddingBottom: "2px",
                  fontSize: "14px",
                  fontWeight: 600,
                }}
              >
                Crayon Solution
              </InputLabel>
              <InputBox
                multiline={true}
                sx={{
                  width: "100%",
                  height: "120px",
                  borderRadius: "10px",
                  alignItems: "flex-start",
                  border: errors?.["crayon_solution"]
                    ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
                    : "1px solid #E0E0E0",
                }}
                placeholder={"Enter your Crayon Solution"}
                value={formData?.crayon_solution}
                id="crayon_solution"
                onChange={(e) => handleFormInput(e, "crayon_solution")}
              />
            </Box>
            <Box sx={{ marginBottom: "5px" }}>
              <InputLabel
                htmlFor="crayon_solution_detail"
                sx={{
                  color: "black",
                  paddingBottom: "2px",
                  fontSize: "14px",
                  fontWeight: 600,
                }}
              >
                Crayon Solution Detail
              </InputLabel>
              <InputBox
                multiline={true}
                sx={{
                  width: "100%",
                  height: "120px",
                  borderRadius: "10px",
                  alignItems: "flex-start",
                  border: errors?.["crayon_solution_detail"]
                    ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
                    : "1px solid #E0E0E0",
                }}
                placeholder={"Enter your Crayon Solution Detail"}
                value={formData?.crayon_solution_detail}
                id="crayon_solution_detail"
                onChange={(e) => handleFormInput(e, "crayon_solution_detail")}
              />
            </Box>
            <Box
              sx={{
                marginBottom: "5px",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Box
                sx={{ display: "flex", flexDirection: "column", width: "48%" }}
              >
                <Box sx={{ marginBottom: "5px" }}>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <InputLabel
                      htmlFor="title"
                      sx={{
                        color: "black",
                        paddingBottom: "2px",
                        fontSize: "14px",
                        fontWeight: 600,
                      }}
                    >
                      Youtube Challange Link
                    </InputLabel>
                    {/* <BlueSwitch
                      id="challenge_link_flag"
                      checked={formData?.challenge_link_flag}
                      onChange={(event) =>
                        handleChangeSetup(event, "challenge_link_flag")
                      }
                    /> */}
                    <ToggleSwitch
                      disabled={videoIdChallenge_video_link ? false : true}
                      color="blueButton700"
                      onChange={(event) =>
                        handleChangeSetupchallenge_link(
                          event,
                          "challenge_link_flag"
                        )
                      }
                      checked={formData?.challenge_link_flag}
                    />
                  </Box>

                  <Paper
                    elevation={3}
                    sx={{
                      display: "flex",
                      borderRadius: "10px",
                      height: "40px",
                      boxShadow: "none",
                      border: errors?.["challenge_video_link"]
                        ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
                        : `1px solid ${theme.palette.grayBorder}`,
                    }}
                  >
                    <InputBase
                      sx={{ ml: 2, mr: 2, width: "100%" }}
                      id="challenge_video_link"
                      type="text"
                      placeholder={"Enter your challenge Youtube link"}
                      value={formData?.challenge_video_link}
                      onChange={(e) =>
                        handleFormInput(e, "challenge_video_link")
                      }
                    />
                  </Paper>
                  <Box sx={{ marginY: "5px" }}>
                    {videoIdChallenge_video_link && (
                      <div className="video-responsive">
                        <iframe
                          style={{
                            borderRadius: "25px 25px 25px 25px",
                            height: "auto",
                            width: "100%",
                            aspectRatio: "4/3",
                            objectFit: "cover",
                            cursor: disabledButton ? "wait" : "pointer",
                            border: disabledButton
                              ? `1px solid ${theme.palette.grayButton600.main}`
                              : "none",
                            borderBottom: "none", // To remove the border for the bottom side
                          }}
                          width="320px"
                          height="240px"
                          src={`https://www.youtube.com/embed/${videoIdChallenge_video_link}`}
                          frameBorder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          allowFullScreen
                          title="Embedded youtube"
                        ></iframe>
                      </div>
                    )}
                  </Box>
                </Box>
              </Box>
              <Box
                sx={{ display: "flex", flexDirection: "column", width: "48%" }}
              >
                <Box sx={{ marginBottom: "5px" }}>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <InputLabel
                      htmlFor="title"
                      sx={{
                        color: "black",
                        paddingBottom: "2px",
                        fontSize: "14px",
                        fontWeight: 600,
                      }}
                    >
                      Youtube Solution Link
                    </InputLabel>

                    {/* <BlueSwitch
                      id="solution_link_flag"
                      checked={formData?.solution_link_flag}
                      onChange={(event) =>
                        handleChangeSetup(event, "solution_link_flag")
                      }
                    /> */}
                    <ToggleSwitch
                      disabled={videoIdSolution_video_link ? false : true}
                      color="blueButton700"
                      onChange={(event) =>
                        handleChangeSetupSolution_link(
                          event,
                          "solution_link_flag"
                        )
                      }
                      checked={formData?.solution_link_flag}
                    />
                  </Box>

                  <Paper
                    elevation={3}
                    sx={{
                      display: "flex",
                      borderRadius: "10px",
                      height: "40px",
                      boxShadow: "none",
                      border: errors?.["solution_video_link"]
                        ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
                        : `1px solid ${theme.palette.grayBorder}`,
                    }}
                  >
                    <InputBase
                      sx={{ ml: 2, mr: 2, width: "100%" }}
                      id="solution_video_link"
                      type="text"
                      placeholder={"Enter your solution Youtube link"}
                      value={formData?.solution_video_link}
                      onChange={(e) =>
                        handleFormInput(e, "solution_video_link")
                      }
                    />
                  </Paper>
                  <Box sx={{ marginY: "5px" }}>
                    {videoIdSolution_video_link && (
                      <div className="video-responsive">
                        <iframe
                          style={{
                            borderRadius: "25px 25px 25px 25px",
                            height: "auto",
                            width: "100%",
                            aspectRatio: "4/3",
                            objectFit: "cover",
                            cursor: disabledButton ? "wait" : "pointer",
                            border: disabledButton
                              ? `1px solid ${theme.palette.grayButton600.main}`
                              : "none",
                            borderBottom: "none", // To remove the border for the bottom side
                          }}
                          width="320px"
                          height="240px"
                          src={`https://www.youtube.com/embed/${videoIdSolution_video_link}`}
                          frameBorder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          allowFullScreen
                          title="Embedded youtube"
                        ></iframe>
                      </div>
                    )}
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box
              sx={{
                marginBottom: "5px",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <input
                accept={fileAccept}
                ref={challangeVideoInput}
                type="file"
                onChange={(event) => handleFileChange(event, "challangeVideo")}
                style={{ display: "none" }}
              />
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                }}
              >
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <InputLabel
                    htmlFor="crayon_solution_detail"
                    sx={{
                      color: "black",
                      paddingBottom: "2px",
                      fontSize: "14px",
                      fontWeight: 600,
                    }}
                  >
                    Upload Challange Video
                  </InputLabel>
                  {/* <BlueSwitch
                    id="challenge_video_flag"
                    checked={formData?.challenge_video_flag}
                    onChange={(event) =>
                      handleChangeSetup(event, "challenge_video_flag")
                    }
                  /> */}
                  <ToggleSwitch
                    disabled={
                      challangeVideoUploadURL !== "No URL" ? false : true
                    }
                    color="blueButton700"
                    onChange={(event) =>
                      handleChangeSetupchallenge_video(
                        event,
                        "challenge_video_flag"
                      )
                    }
                    checked={formData?.challenge_video_flag}
                  />
                </Box>

                <Button
                  variant="contained"
                  color="yellowButton100"
                  sx={{
                    borderRadius: "10px",
                    width: "fit-content",
                    height: "47px",
                  }}
                  onClick={handleChallangeVideo}
                >
                  upload video
                </Button>
                <Box
                  sx={{
                    flexGrow: 1,
                    // padding: "12px 12px 3px 12px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    position: "relative",
                    height: "240px",
                    width: "320px",
                  }}
                >
                  {challangeVideoUploadURL !== "No URL" && !isPlaying ? (
                    <PlayButton />
                  ) : null}
                  {challangeVideoUploadURL !== "No URL" ? (
                    <video
                      autoPlay={false}
                      loops
                      preload="auto"
                      style={{
                        width: "320px",
                        height: "240px",
                        borderRadius: "25px",
                        objectFit: "cover",
                        cursor: disabledButton ? "wait" : "pointer",
                      }}
                      controls={false}
                      ref={challangeVideoRef}
                      onClick={() => {
                        setViewVideolink(challangeVideoUploadURL);
                        setOpenReviewVideoDialog(true);
                      }}
                      onLoadedData={() => {
                        setDisabledButton(false);
                      }}
                      onEnded={() => setIsPlaying(false)}
                    >
                      <source src={challangeVideoUploadURL} type="video/mp4" />
                    </video>
                  ) : (
                    <Box
                      component={"img"}
                      src={videoPlaceholder}
                      sx={{
                        height: "70px",
                        width: "70px",
                      }}
                    />
                  )}
                </Box>
              </Box>
              <input
                accept={fileAccept}
                ref={solutionVideoInput}
                type="file"
                onChange={(event) => handleFileChange(event, "solutionVideo")}
                style={{ display: "none" }}
              />
              <Box
                sx={{
                  display: "flex",
                  alignItems: "flex-end",
                  flexDirection: "column",
                  gap: "10px",
                }}
              >
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <InputLabel
                    htmlFor="crayon_solution_detail"
                    sx={{
                      color: "black",
                      paddingBottom: "2px",
                      fontSize: "14px",
                      fontWeight: 600,
                    }}
                  >
                    Upload Solution Video
                  </InputLabel>
                  {/* <BlueSwitch
                    id="solution_video_flag"
                    checked={formData?.solution_video_flag}
                    onChange={(event) =>
                      handleChangeSetup(event, "solution_video_flag")
                    }
                  /> */}
                  <ToggleSwitch
                    disabled={
                      solutionVideoUploadURL !== "No URL" ? false : true
                    }
                    color="blueButton700"
                    onChange={(event) =>
                      handleChangeSetupSolution_video(
                        event,
                        "solution_video_flag"
                      )
                    }
                    checked={formData?.solution_video_flag}
                  />
                </Box>

                <Button
                  variant="contained"
                  color="yellowButton100"
                  sx={{
                    borderRadius: "10px",
                    width: "fit-content",
                    height: "47px",
                  }}
                  onClick={handleSolutionVideo}
                >
                  upload video
                </Button>

                <Box
                  sx={{
                    flexGrow: 1,
                    // padding: "12px 12px 3px 12px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    position: "relative",
                    height: "240px",
                    width: "320px",
                  }}
                >
                  {solutionVideoUploadURL !== "No URL" && !isPlaying ? (
                    <PlayButton />
                  ) : null}
                  {solutionVideoUploadURL !== "No URL" ? (
                    <video
                      autoPlay={false}
                      loops
                      preload="auto"
                      style={{
                        width: "320px",
                        height: "240px",
                        borderRadius: "25px",
                        objectFit: "cover",
                        cursor: disabledButton ? "wait" : "pointer",
                      }}
                      controls={false}
                      ref={solutionVideoRef}
                      onClick={() => {
                        // if (!disabledButton) {
                        //   if (solutionVideoRef?.current?.paused) {
                        //     solutionVideoRef?.current?.play();
                        //     setIsPlaying(true);
                        //   } else {
                        //     solutionVideoRef?.current?.pause();
                        //     setIsPlaying(false);
                        //   }
                        // }
                        setViewVideolink(solutionVideoUploadURL);
                        setOpenReviewVideoDialog(true);
                      }}
                      onLoadedData={() => {
                        setDisabledButton(false);
                      }}
                      onEnded={() => setIsPlaying(false)}
                    >
                      <source src={solutionVideoUploadURL} type="video/mp4" />
                    </video>
                  ) : (
                    <Box
                      component={"img"}
                      src={videoPlaceholder}
                      sx={{
                        height: "70px",
                        width: "70px",
                      }}
                    />
                  )}
                </Box>
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              width: "100%",
            }}
          >
            <Button
              variant="contained"
              color="grayButton100"
              sx={{
                borderRadius: 0,
                width: "50%",
                height: "47px",
                borderBottomLeftRadius: 25,
              }}
              onClick={handleCancel}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="yellowButton100"
              sx={{
                borderRadius: 0,
                width: "50%",
                height: "47px",
                borderBottomRightRadius: 25,
              }}
              onClick={handleCreate}
            >
              {edit ? "Edit" : "Create"}
            </Button>
          </Box>
        </Box>
      </Modal>
      {openReviewVideoDialog && (
        <ReviewVideoDialog
          videoLink={viewVideoLink}
          open={openReviewVideoDialog}
          setOpen={handleCloseDialog}
        />
      )}
    </>
  );
};

export default AddSolutionModal;
