import { OutlinedInput, alpha } from "@material-ui/core";
import {
  Accordion,
  Switch,
  Tooltip,
  styled,
  tooltipClasses,
  Box,
  LinearProgress,
  Typography,
  linearProgressClasses,
  InputBase,
  Button,
  Autocomplete,
  Menu,
  useTheme,
  TableCell,
} from "@mui/material";

import maleBlack from "../assets/male_blue.svg";
import maleWhite from "../assets/male_white.svg";
import femaleBlack from "../assets/female_pink.svg";
import femaleWhite from "../assets/female_white (2).svg";

export const TableCells = styled(TableCell)(({ theme }) => ({
  borderBottom: "none",
}));

export const getMaleIcon = (gender, gender_flag) => (
  <Box
    component="img"
    alt="male white"
    src={gender === "male" || gender_flag ? maleWhite : maleBlack}
    sx={{ width: "7px" }}
  />
);
export const getFemaleIcon = (gender, gender_flag) => (
  <Box
    component="img"
    alt="female white"
    src={gender === "female" || gender_flag ? femaleWhite : femaleBlack}
    sx={{ width: "9px" }}
  />
);

export const ToggleCheckedSwitch = styled(Switch)(
  ({ theme, checked, checkedColor, notCheckedColor }) => ({
    "& .MuiSwitch-switchBase.Mui-checked": {
      color: checked ? theme.palette[checkedColor].main : notCheckedColor,
      "&:hover": {
        backgroundColor: alpha(
          checked
            ? theme.palette[checkedColor].main
            : theme.palette[notCheckedColor].main,
          theme.palette.action.hoverOpacity
        ),
      },
    },
    "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
      backgroundColor: checked
        ? theme.palette[checkedColor].main
        : theme.palette[notCheckedColor].main,
    },
    "& .MuiSwitch-track": {
      margin: "auto",
      height: "50% !important",
      width: "90% !important",
      padding: "0px !important",
      backgroundColor: checked
        ? theme.palette[checkedColor].main
        : theme.palette[notCheckedColor].main,
    },
    ".MuiButtonBase-root.MuiSwitch-switchBase": {
      borderRadius: "15% !important",
      padding: "11px !important",
    },
    "& .MuiSwitch-thumb": {
      borderRadius: "6px !important",
      height: "15px !important",
      width: "15px !important",
      backgroundColor: checked
        ? theme.palette[checkedColor].main
        : theme.palette[notCheckedColor].main,
    },
    "& .MuiButtonBase-root-MuiSwitch-switchBase": {
      borderRadius: "15% !important",
      padding: "11px !important",
    },
  })
);

export const ToggleSwitch2 = styled(Switch)(
  ({ theme, checked, checkedColor, notCheckedColor }) => ({
    "& .MuiSwitch-switchBase.Mui-checked": {
      color: checked ? theme.palette[checkedColor].main : notCheckedColor,
      "&:hover": {
        backgroundColor: alpha(
          checked
            ? theme.palette[checkedColor].main
            : theme.palette[notCheckedColor].main,
          theme.palette.action.hoverOpacity
        ),
      },
    },
    "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
      backgroundColor: checked
        ? theme.palette[checkedColor].main
        : theme.palette[notCheckedColor].main,
    },
    "& .MuiSwitch-track": {
      margin: "auto",
      height: "60% !important",
      boxShadow: "0px 2px 4px #00000029",
      backgroundColor: checked
        ? theme.palette[checkedColor].main
        : theme.palette[notCheckedColor].main,
      // backgroundColor: "#E4E4EB",
    },
    "& .MuiSwitch-thumb": {
      borderRadius: "6px !important",
      backgroundColor: checked
        ? theme.palette[checkedColor].main
        : theme.palette[notCheckedColor].main,
    },
    "& .MuiButtonBase-root-MuiSwitch-switchBase": {
      borderRadius: "15% !important",
    },
    "&.Mui-disabled .MuiSwitch-switchBase": {
      color: checked
        ? theme.palette[checkedColor].main
        : theme.palette[notCheckedColor].main,
    },
    "&.Mui-disabled .MuiSwitch-track": {
      backgroundColor: checked
        ? theme.palette[checkedColor].main
        : theme.palette[notCheckedColor].main,
      opacity: 1,
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color: checked
        ? theme.palette[checkedColor].main
        : theme.palette[notCheckedColor].main,
    },
  })
);

export const ToggleSwitch = styled(Switch)(({ theme, color }) => ({
  "& .MuiSwitch-switchBase.Mui-checked": {
    color: theme.palette[color].main,
    "&:hover": {
      backgroundColor: alpha(
        theme.palette[color].main,
        theme.palette.action.hoverOpacity
      ),
    },
  },
  "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
    backgroundColor: theme.palette[color].main,
  },
  "& .MuiSwitch-track": {
    margin: "auto",
    height: "60% !important",
    boxShadow: "0px 2px 4px #00000029",
    // backgroundColor: "#E4E4EB",
  },
  "& .MuiSwitch-thumb": {
    borderRadius: "6px !important",
  },
  "& .MuiButtonBase-root-MuiSwitch-switchBase": {
    borderRadius: "15% !important",
  },
  "&.Mui-disabled .MuiSwitch-switchBase": {
    color: theme.palette[color].main,
  },
  "&.Mui-disabled .MuiSwitch-track": {
    backgroundColor: theme.palette[color].main,
    opacity: 1,
  },
  "&.Mui-disabled .MuiSwitch-thumb": {
    color: theme.palette[color].main,
  },
}));

export const BlueSwitchJobAlert = styled(Switch)(({ theme }) => ({
  "& .MuiSwitch-switchBase.Mui-checked": {
    color: theme.palette.blueButton700.main,
    "&:hover": {
      backgroundColor: alpha(
        theme.palette.blueButton700.main,
        theme.palette.action.hoverOpacity
      ),
    },
  },
  "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
    backgroundColor: theme.palette.blueButton700.main,
  },
  "& .MuiSwitch-track": {
    margin: "auto",
    height: "60% !important",
  },
  "& .MuiSwitch-thumb": {
    borderRadius: "6px !important",
  },
  "& .MuiButtonBase-root-MuiSwitch-switchBase": {
    borderRadius: "15% !important",
  },
  "& .MuiButtonBase-root": {
    padding: "9px !important",
  },
}));

export const BlueSwitch = styled(Switch)(({ theme }) => ({
  "& .MuiSwitch-switchBase.Mui-checked": {
    color: theme.palette.jobAndBioButton,
    "&:hover": {
      backgroundColor: alpha(
        theme.palette.jobAndBioButton,
        theme.palette.action.hoverOpacity
      ),
    },
  },
  "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
    backgroundColor: theme.palette.jobAndBioButton,
  },
  "& .MuiSwitch-track": {
    margin: "auto",
    height: "60% !important",
  },
  "& .MuiSwitch-thumb": {
    borderRadius: "6px !important",
  },
}));

export const StyledMenuSort = styled((props) => (
  <Menu
    elevation={4}
    // anchorOrigin={{
    //     vertical: 'bottom',
    //     horizontal: 'right',
    // }}
    // transformOrigin={{
    //     vertical: 'top',
    //     horizontal: 'right',
    // }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 5,
    minWidth: 80,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    backgroundColor: theme.palette.menuBackground,
    padding: "0 8px 0 9px",
    "& .MuiList-root": {
      paddingTop: 0,
    },
    "& .MuiTypography-root": {
      fontSize: "14px",
      fontWeight: 700,
    },
  },
  "& .MuiFormControlLabel-root": {
    height: "30px",
    "& .MuiTypography-root": {
      fontSize: "12px",
      color: theme.palette.lightText,
      fontWeight: 400,
    },
  },
}));

export const StyledBoxManageTalent = (props) => {
  const { children, color, selectedTab } = props;
  const theme = useTheme();
  return (
    <Box
      sx={{
        height: 45,
        backgroundColor: theme?.manageTalent?.[color]?.main,
        borderRadius:
          selectedTab === props?.column?.id ? "0 0 0 25px" : "0 0 25px 25px",
        color: theme.palette.white,
        justifyContent: "space-between",
        display: "flex",
        alignItems: "center",
        fontWeight: 500,
        cursor: "pointer",
        padding: "0 0 0 20px",
        position: "sticky",
        top: 0,
        zIndex: 9,
        overflow: "hidden",
      }}
    >
      {children}
    </Box>
  );
};

export const StyledMenu = styled((props) => (
  <Menu
    elevation={4}
    // anchorOrigin={{
    //   vertical: "bottom",
    //   horizontal: "right",
    // }}
    // transformOrigin={{
    //     vertical: 'top',
    //     horizontal: 'right',
    // }}
    anchorOrigin={{
      vertical: "top",
      horizontal: "left",
    }}
    transformOrigin={{
      vertical: "bottom",
      horizontal: "left",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: "0 10px 0 0",
    // width: "120px",
    // minWidth: 260,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    backgroundColor: theme.palette.topBar.main,

    "& .MuiList-root": {
      paddingTop: 0,
    },
    boxShadow: "none",
    "& .MuiTypography-root": {
      fontSize: "14px",
      fontWeight: 700,
    },
    "& .MuiMenu-list": {
      padding: 0,
    },
  },
  "& .MuiPopover-paper": {
    "& .MuiMenu-paper": {
      boxShadow: "none !important",
    },
  },
  "& .MuiFormControlLabel-root": {
    height: "30px",
    "& .MuiTypography-root": {
      fontSize: "14px",
      color: theme.palette.lightText,
      fontWeight: 400,
    },
  },
}));

export const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip disableInteractive {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 11,
    maxWidth: 180,
    borderRadius: 15,
    padding: "16px 12px",
  },
}));

export const StyledAccordionDraggableCard = styled(Accordion)(({ theme }) => ({
  marginBottom: 15,
  marginLeft: 7.5,
  background: `${theme.palette.white} !important`,
  borderRadius: "10px !important",
  width: "325px",
  height: "auto !important",
  // height: "150px",
  position: "unset",
  borderColor: `${theme.palette.white} !important`,
  boxShadow: "0px 5px 10px #00000029",
  "&::before": {
    opacity: "0 !important",
  },
  "& .MuiAccordionSummary-root": {
    alignItems: "start",
    padding: 0,
  },
  "& .MuiAccordionSummary-content": {
    margin: 0,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    ".summaryBox1": {
      margin: "8px 0",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
    },
    ".summaryBox2": {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      marginTop: 8,
    },
    ".dragDots": {
      height: 24,
      width: 24,
      maxHeight: { xs: 24 },
      maxWidth: { xs: 24 },
    },
    ".profileAvatar": {
      height: 30,
      width: 30,
      maxHeight: { xs: 30 },
      maxWidth: { xs: 30 },
      borderRadius: 6,
    },
    ".dotIcon": {
      position: "absolute",
      right: "30px",
      top: "-2px",
      width: "12px",
    },
    ".thumbs": {
      position: "absolute",
      right: "1px",
      top: "9px",
      width: "16px",
    },
    ".arrowFont": {
      fontSize: "1.1rem",
    },
  },
  "& .MuiAccordionSummary-expandIconWrapper": {
    color: theme.palette.redButton.main,
    position: "absolute",
    right: "30px",
    bottom: "2px",
    "& .MuiSvgIcon-root": {
      fontSize: "2rem",
    },
  },
  "& .MuiCollapse-root": {
    "& .MuiAccordionDetails-root": {
      display: "flex",
      padding: 0,
      marginTop: "-10px",
      ".contentBox1": {
        display: "flex",
        justifyContent: "space-between",
        padding: "0 8px",
      },
      ".contentBox2": {
        display: "flex",
        justifyContent: "space-between",
      },
      ".contentBox3": {
        display: "flex",
        justifyContent: "space-around",
        padding: "0 8px",
        marginTop: "-12px",
      },
      ".contentBox4": {
        display: "flex",
        justifyContent: "center",
        padding: "0 8px",
        margin: "8px 0",
      },
    },
  },
}));

export const StyledAccordionEmployerProfile = styled(Accordion)(
  ({ theme }) => ({
    marginTop: "0px",
    borderRadius: "25px",
    position: "unset",
    boxShadow: "none",
    width: "100%",
    // overflow: "scroll",
    // overflowX: "hidden",
    marginBottom: "0px !important",
    backgroundColor: "transparent",
    "& .MuiAccordionSummary-root": {
      padding: "0px !important",
      cursor: "auto !important",
      boxShadow: "-1px 3px 8px rgba(0, 0, 0, 0.1)",
      borderRadius: "25px",
      marginBottom: "0px !important",
      position: "relative",
      background: "#ffff",
      height: "100%",
    },
    "& .MuiAccordionSummary-content.Mui-expanded": {
      margin: "0 0 8px 0",
    },
    "& .MuiAccordionSummary-content": {
      margin: 0,
      gap: "40px",
      width: "100%",
      justifyContent: "space-between",
    },
    "& .MuiAccordionSummary-expandIconWrapper": {
      position: "absolute",
      right: "24px",
      top: "24px",
      color: theme.palette.white,
      background: theme.palette.lightText,
      width: 34,
      height: 34,
      borderRadius: 10,
      justifyContent: "center",
      alignItems: "center",
      cursor: "pointer",
      "& .MuiSvgIcon-root": {
        fontSize: "1.5rem",
      },
    },
    "& .MuiCollapse-root": {
      "& .MuiAccordionDetails-root": {
        display: "flex",
        paddingTop: 0,
        boxShadow: "-1px 3px 8px rgba(0, 0, 0, 0.1)",
        borderRadius: "17px",
        background: "#ffff",
        "& .MuiTypography-root": {
          fontSize: "14px",
          fontWeight: 500,
          color: theme.palette.black,
          opacity: 0.87,
        },
      },
    },
    "& .Mui-expanded": {
      margin: "0px !important",
    },
  })
);

export const StyledBoxDraggableCard = styled("img")(() => ({
  cursor: "pointer",
  height: 40,
  top: 10,
  width: 47,
  // position: "absolute",
}));

const BorderLinearProgress = styled(LinearProgress)(({ theme, value }) => ({
  height: "5px",
  borderRadius: 5,
  [`&.${linearProgressClasses?.colorPrimary}`]: {
    backgroundColor: theme?.palette?.grayBorder,
  },
  [`& .${linearProgressClasses?.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme?.palette?.lightGreenButton300?.main,
  },
}));

export function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ mb: 3 }}>
      <Box sx={{ minWidth: 35 }}>
        <Typography
          fontSize={props.fontSize}
          fontWeight={props.fontWeight}
          color="text.secondary"
        >{`${Math.round(props.value)}%`}</Typography>
      </Box>
      <Box sx={{ width: "100%", mr: 1 }}>
        <BorderLinearProgress variant="determinate" {...props} />
      </Box>
    </Box>
  );
}

export const GreenButtonSwitch = styled(Switch)(({ theme }) => ({
  "& .MuiSwitch-switchBase.Mui-checked": {
    color: theme.palette.greenButton.main,
    "&:hover": {
      backgroundColor: alpha(
        theme.palette.greenButton.main,
        theme.palette.action.hoverOpacity
      ),
    },
  },
  "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
    backgroundColor: theme.palette.greenButton.main,
  },
  "& .MuiSwitch-track": {
    margin: "auto",
    height: "60% !important",
  },
  "& .MuiSwitch-thumb": {
    borderRadius: "6px !important",
  },
}));

export const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "& .MuiInputBase-input": {
    borderRadius: 12,
    position: "relative",
    border: "1px solid #ced4da",
    fontSize: 14,
    fontWeight: "Bold",
    padding: "5px 26px 5px 12px",
    backgroundColor: "lightGray",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      borderRadius: 12,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
}));

export const TalentDetailStyledAccordion = styled(Accordion)(({ theme }) => ({
  marginTop: "4px",
  position: "unset",
  overflow: "hidden",
  borderRadius: "25px 25px !important",
  background: "#ebecf3",

  "& .MuiAccordionSummary-root": {
    // alignItems: 'start'
    flexDirection: "row-reverse",
    // marginBottom: '4px'
  },
  "& .MuiAccordionSummary-content.Mui-expanded": {
    margin: "0 0 8px 0",
  },
  "& .MuiAccordionSummary-content": {
    flexDirection: "column",
    margin: 0,
    ".summaryBox": {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      // marginRight: '8px',
      "& .MuiButtonBase-root": {
        letterSpacing: "-0.02em",
        // fontSize: 10,
        // fontWeight: 700,
        // minWidth: 28,
        // padding: '2px 8px',
        // borderRadius: 3,
        // height: '20px',
        // boxShadow: 'none'
      },
    },
    ".summaryBoxContent": {
      display: "flex",
      alignItems: "center",
    },
    ".profileAvatar": {
      height: 50,
      width: 50,
      borderRadius: 6,
    },

    "& .MuiTypography-root": {
      // fontWeight: 700,
      // fontSize: '20px',
    },
    "& .MuiButtonBase-root": {
      // padding: '2px 8px',
      // fontSize: 10,
      // fontWeight: 700,
      // minWidth: 30,
      // boxShadow: 'none',
      // borderRadius: 2
    },
  },
  "& .MuiAccordionSummary-expandIconWrapper": {
    color: theme.palette.white,
    background: theme.palette.redButton.main,
    width: 23.42,
    height: 23.71,
    borderRadius: 25,
    marginLeft: "-5px",
    marginRight: "20px",
    justifyContent: "center",
    alignItems: "center",

    // marginRight: '32px',
    // position: 'absolute',
    // right: '40px',
    // top: '12px',
    "& .MuiSvgIcon-root": {
      fontSize: "1.4rem",
    },
  },
  "& .MuiCollapse-root": {
    "& .MuiAccordionDetails-root": {
      display: "flex",
      paddingTop: 0,
      background: "white",
      padding: "8px 42px 0px",
      // padding: 0,
      "& .MuiButtonBase-root": {
        // padding: '0 8px',
        // fontSize: 10,
        // fontWeight: 700,
        // minWidth: 30,
        // padding: '1px 4px',
        // borderRadius: 3
      },
      ".contentBoxLeft": {
        width: "60%",
        // display: 'flex',
        // justifyContent: 'space-between',
        "& .MuiButtonBase-root": {
          padding: "0 8px",
          // fontSize: 10,
          // fontWeight: 700,
          // minWidth: 10,
          // padding: '1px 4px',
          // borderRadius: 3
        },
        // '& .MuiSvgIcon-root': {
        //     width: '20px'
        // }
      },
      ".contentBoxRight": {
        width: "35%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        "& .MuiButtonBase-root": {
          padding: "0 8px",
          // fontSize: 10,
          // fontWeight: 700,
          // minWidth: 10,
          // padding: '1px 4px',
          // borderRadius: 3
        },
        ".title": {
          fontSize: "12px",
          fontWeight: 700,
        },
        ".subTitle": {
          fontSize: "12px",
          fontWeight: 400,
        },
      },
    },
  },
  "& .MuiButtonBase-root": {
    // boxShadow: 'none',
    // padding: '0 16px'
  },
}));

export const SolutionsCardAccordion = styled(Accordion)(({ theme }) => ({
  borderRadius: "10px !important",
  position: "unset !important",
  marginBottom: "10px !important",
  "& .MuiAccordionSummary-root": {
    padding: "0px !important",
    height: "76px !important",
  },
  "& .MuiAccordionDetails-root": {
    padding: "0 !important",
  },
  "& .MuiCollapse-root": {
    position: "relative",
    // top: "40px",
    // left: "20px",
  },
}));

export const NewTalentCardAccordion = styled(Accordion)(({ theme }) => ({
  borderRadius: "10px !important",
  position: "unset !important",
  marginBottom: "10px !important",
  "& .MuiAccordionSummary-root": {
    padding: "0px !important",
    height: "76px !important",
  },
  "& .MuiAccordionDetails-root": {
    padding: "0 !important",
  },
  "& .MuiCollapse-root": {
    position: "relative",
    // top: "40px",
    // left: "20px",
  },
}));

export const NewJobsCardAccordion = styled(Accordion)(({ theme }) => ({
  borderRadius: "10px !important",
  position: "unset !important",
  marginBottom: "10px !important",
  "& .MuiAccordionSummary-root": {
    padding: "0px !important",
    height: "75px !important",
  },
  "& .MuiAccordionDetails-root": {
    padding: "0px !important",
  },
  "& .MuiCollapse-root": {
    position: "relative",
    // top: "40px",
    // left: "20px",
  },
}));

export const TalentCardStyledAccordion = styled(Accordion)(({ theme }) => ({
  marginTop: "4px",
  borderRadius: "25px 25px !important",
  position: "unset",
  overflow: "hidden",
  "& .MuiAccordionSummary-root": {
    flexDirection: "row-reverse",
    padding: "0px 0px 0px 0px",
    minHeight: "0px !important",
  },
  "& .MuiAccordionSummary-content.Mui-expanded": {
    margin: "0 0 8px 0",
  },
  // "& .MuiGrid-root": {
  //   justifyContent: "space-between",
  // },
  "& .MuiAccordionSummary-content": {
    flexDirection: "column",
    margin: 0,
    ".summaryBox": {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      // marginRight: '8px',
      "& .MuiButtonBase-root": {
        letterSpacing: "-0.02em",
        // fontSize: 10,
        // fontWeight: 700,
        // minWidth: 28,
        // padding: '2px 8px',
        // borderRadius: 3,
        // height: '20px',
        // boxShadow: 'none'
      },
    },
    ".summaryBoxContent": {
      display: "flex",
      alignItems: "center",
    },
    ".profileAvatar": {
      height: 20,
      width: 20,
      borderRadius: 6,
    },

    "& .MuiTypography-root": {
      // fontWeight: 700,
      // fontSize: '20px',
    },
    "& .MuiButtonBase-root": {
      // padding: '2px 8px',
      // fontSize: 10,
      // fontWeight: 700,
      // minWidth: 30,
      // boxShadow: 'none',
      // borderRadius: 2
    },
  },
  "& .MuiAccordionSummary-expandIconWrapper": {
    color: theme.palette.white,
    background: theme.palette.redButton.main,
    width: 23.42,
    height: 49,
    // borderRadius: 25,
    overflow: "hidden",
    borderRadius: "15px 0 0 15px",
    marginLeft: "-15px",
    marginRight: "20px",
    justifyContent: "center",
    alignItems: "center",

    // marginRight: '32px',
    // position: 'absolute',
    // right: '40px',
    // top: '12px',
    "& .MuiSvgIcon-root": {
      fontSize: "1.4rem",
    },
  },
  "& .MuiCollapse-root": {
    "& .MuiAccordionDetails-root": {
      display: "flex",
      padding: "8px 42px 0px",
      paddingTop: 0,
      // padding: 0,
      "& .MuiButtonBase-root": {
        // padding: '0 8px',
        // fontSize: 10,
        // fontWeight: 700,
        // minWidth: 30,
        // padding: '1px 4px',
        // borderRadius: 3
      },
      ".contentBoxLeft": {
        width: "50%",
        // display: 'flex',
        // justifyContent: 'space-between',
        "& .MuiButtonBase-root": {
          padding: "0 8px",
          // fontSize: 10,
          // fontWeight: 700,
          // minWidth: 10,
          // padding: '1px 4px',
          // borderRadius: 3
        },
        // '& .MuiSvgIcon-root': {
        //     width: '20px'
        // }
      },
      ".contentBoxRight": {
        width: "37%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        "& .MuiButtonBase-root": {
          padding: "0 8px",
          // fontSize: 10,
          // fontWeight: 700,
          // minWidth: 10,
          // padding: '1px 4px',
          // borderRadius: 3
        },
        ".title": {
          fontSize: "12px",
          fontWeight: 700,
        },
        ".subTitle": {
          fontSize: "12px",
          fontWeight: 400,
        },
      },
    },
  },
  "& .MuiButtonBase-root": {
    // boxShadow: 'none',
    // padding: '0 16px'
  },

  "& .MuiButton-startIcon": {
    display: "flex !important",
    margin: "0px !important",
  },
}));

export const UserCardStyledAccordion = styled(Accordion)(({ theme }) => ({
  // marginBottom: 15,
  // borderRadius: "10px !important",
  // background: `${theme.palette.white} !important`,
  // position: "unset",
  // // overflow: "hidden",
  // height: "auto !important",
  // "& .MuiAccordionSummary-root": {
  //   flexDirection: "row-reverse",
  //   padding: "0px 0px 0px 0px",
  //   minHeight: "0px !important",
  // },
  "& .MuiAccordionSummary-content": {
    margin: "0 !important",
    "& .Mui-expanded": {
      margin: "0 !important",
    },
  },
  // // "& .MuiGrid-root": {
  // //   justifyContent: "space-between",
  // // },
  // "& .MuiAccordionSummary-content": {
  //   flexDirection: "column",
  //   margin: 0,
  //   ".summaryBox": {
  //     display: "flex",
  //     justifyContent: "space-between",
  //     alignItems: "center",
  //     "& .MuiButtonBase-root": {
  //       letterSpacing: "-0.02em",
  //     },
  //   },
  //   ".summaryBoxContent": {
  //     display: "flex",
  //     alignItems: "center",
  //   },
  //   ".profileAvatar": {
  //     height: 20,
  //     width: 20,
  //     borderRadius: 6,
  //   },

  //   "& .MuiTypography-root": {},
  //   "& .MuiButtonBase-root": {},
  // },
  // "& .MuiAccordionSummary-expandIconWrapper": {
  //   color: theme.palette.white,
  //   background: theme.palette.redButton.main,
  //   width: 23.42,
  //   height: 49,
  //   overflow: "hidden",
  //   borderRadius: "15px 0 0 15px",
  //   marginLeft: "-15px",
  //   marginRight: "20px",
  //   justifyContent: "center",
  //   alignItems: "center",
  //   "& .MuiSvgIcon-root": {
  //     fontSize: "1.4rem",
  //   },
  // },
  // "& .MuiCollapse-root": {
  //   "& .MuiAccordionDetails-root": {
  //     display: "flex",
  //     padding: "0px 16px",
  //     "& .MuiButtonBase-root": {},
  //     ".contentBoxLeft": {
  //       width: "50%",
  //       "& .MuiButtonBase-root": {
  //         padding: "0 8px",
  //       },
  //     },
  //     ".contentBoxRight": {
  //       width: "37%",
  //       display: "flex",
  //       flexDirection: "column",
  //       alignItems: "center",
  //       "& .MuiButtonBase-root": {
  //         padding: "0 8px",
  //       },
  //       ".title": {
  //         fontSize: "12px",
  //         fontWeight: 700,
  //       },
  //       ".subTitle": {
  //         fontSize: "12px",
  //         fontWeight: 400,
  //       },
  //     },
  //   },
  // },
  // "& .MuiButton-startIcon": {
  //   display: "flex !important",
  //   margin: "0px !important",
  // },
  borderRadius: "10px !important",
  position: "unset !important",
  marginBottom: "10px !important",
  background: `${theme.palette.white} !important`,
  height: "auto !important",
  minHeight: "75px !important",
  "& .MuiAccordionSummary-root": {
    padding: "0px !important",
  },
  "& .MuiAccordionDetails-root": {
    padding: "0px 0px 0px 0px !important",
  },
  "& .MuiCollapse-root": {
    position: "relative",
    // top: "40px",
    // left: "20px",
  },
}));

export const StyledBox = styled("img")(() => ({
  cursor: "pointer",
  height: 50,
  width: 50,
}));

export const StyledTextField = styled(OutlinedInput)(({ theme }) => ({
  width: "100%",
  margin: "8px 0",
  paddingRight: "8px",

  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: theme.palette.grayBorder,
    borderRadius: "20px",
    transition: "border-color 0.3s", // Add transition for a smoother effect
  },

  "& :hover .MuiOutlinedInput-notchedOutline": {
    borderColor: theme.palette.grayBorder, // Change to the desired hover color
  },
}));

export const StyledButtonLeft = styled(Button)(({ theme }) => ({
  // marginRight: "24px",
  fontSize: theme.typography.fontSize.xs,
  width: "150px",
  height: "40px",
  border: `2px solid ${theme.palette.redButton100.main}`,
  // boxShadow: "0px 5px 10px #00000029",
  "& .MuiSvgIcon-root": {
    fontSize: "16px",
  },
  borderRadius: "10px",
}));
export const StyledButtonRight = styled(Button)(({ theme }) => ({
  // marginRight: "24px",
  fontSize: theme.typography.fontSize.xs,
  width: "150px",
  height: "40px",
  border: `2px solid ${theme.palette.redButton100.main}`,
  // boxShadow: "0px 5px 10px #00000029",
  "& .MuiSvgIcon-root": {
    fontSize: "16px",
  },
  borderRadius: "10px",
}));
export const StyledAutocomplete = styled(Autocomplete)(({ theme }) => ({
  // marginRight: "24px",
  // fontSize: "14px",
  // width: "150px",
  // height: "40px",
  // border: `2px solid ${theme.palette.redButton100.main}`,
  // // boxShadow: "0px 5px 10px #00000029",
  // "& .MuiSvgIcon-root": {
  //   fontSize: "16px",
  // },
  // borderRadius: "10px",
  "& .MuiAutocomplete-popupIndicator": {
    display: "none",
  },
}));

export const CandidateStyledAccordion = styled(Accordion)(({ theme }) => ({
  marginTop: "0px",
  borderRadius: "25px",
  position: "unset",
  boxShadow: "none",
  width: "100%",
  // overflow: "scroll",
  // overflowX: "hidden",
  marginBottom: "0px !important",
  backgroundColor: "transparent",
  "& .MuiAccordionSummary-root": {
    padding: "0px !important",
    cursor: "auto !important",
    boxShadow: "-1px 3px 8px rgba(0, 0, 0, 0.1)",
    borderRadius: "25px",
    marginBottom: "0px !important",
    position: "relative",
    background: "#ffff !important",
  },
  "& .MuiAccordionSummary-content.Mui-expanded": {
    margin: "0 0 8px 0",
  },
  "& .MuiAccordionSummary-content": {
    flexDirection: "column",
    margin: 0,
    ".companyLogo": {
      height: 73,
      width: 73,
      marginRight: "16px",
    },
  },
  "& .MuiAccordionSummary-expandIconWrapper": {
    position: "absolute",
    right: "24px",
    top: "24px",
    color: theme.palette.white,
    background: theme.palette.lightText,
    width: 34,
    height: 34,
    borderRadius: 10,
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    "& .MuiSvgIcon-root": {
      fontSize: "1.5rem",
    },
  },
  "& .MuiCollapse-root": {
    "& .MuiAccordionDetails-root": {
      display: "flex",
      paddingTop: 0,
      boxShadow: "-1px 3px 8px rgba(0, 0, 0, 0.1)",
      borderRadius: "17px",
      background: "#ffff",
      "& .MuiTypography-root": {
        fontSize: "14px",
        fontWeight: 500,
        color: theme.palette.black,
        opacity: 0.87,
      },
    },
  },
  "& .Mui-expanded": {
    margin: "0px !important",
  },
}));
