import {
  Box,
  CircularProgress,
  Typography,
  useTheme,
  useThemeProps,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import profile from "../../../../assets/user_logo.svg";
import InfiniteScroll from "react-infinite-scroll-component";
import { useDispatch } from "react-redux";
import { getTalentDatabases } from "../../../../redux/admin/jobsSlice";
import { setAlert } from "../../../../redux/configSlice";
import { ALERT_TYPE, ERROR_MSG } from "../../../../utils/Constants";

export default function Databases({
  openPersonality,
  anchorElPersonality,
  handlePopoverClose,
  userID,
}) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [databases, setDatabases] = useState([]);
  const [lastKey, setLastKey] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [count, setCount] = useState();

  const getDatabases = async (lastkeyy) => {
    setIsLoading(true);
    try {
      const { payload } = await dispatch(
        getTalentDatabases({ lastKey: lastkeyy, talent_id: userID })
      );
      if (payload?.status === "success") {
        setCount(payload?.count);
        if (lastkeyy === 0) {
          setDatabases(
            payload?.data?.map((item, index) => {
              return {
                ...item,
                id: index,
              };
            })
          );
          setLastKey(payload.pageNumber + 1);
          setIsLoading(false);
        } else {
          setLastKey(payload?.pageNumber + 1);
          setDatabases((prevState) => [
            ...prevState,
            ...payload?.data?.map((item, index) => {
              return {
                ...item,
                id: index,
                name: `${item?.user?.first_name} ${item?.user?.last_name}`,
              };
            }),
          ]);
          setIsLoading(false);
        }
      } else {
        setIsLoading(false);
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE?.ERROR,
            msg: ERROR_MSG,
          })
        );
      }
    } catch (error) {
      dispatch(
        setAlert({
          show: true,
          type: ALERT_TYPE?.ERROR,
          msg: ERROR_MSG,
        })
      );
    }
  };

  useEffect(() => {
    getDatabases(0);
  }, []);

  return (
    <Box
      sx={{
        padding: "16px !important",
      }}
    >
      <Typography
        sx={{
          fontSize: "12px",
          fontWeight: 700,
        }}
      >
        Databases ({count})
      </Typography>
      <Typography sx={{ fontSize: "11px", fontWeight: 700 }}>
        Below is the list of databases this user is linked to
      </Typography>

      <Box>
        <Box
          id="talentList"
          sx={{
            overflowY: "scroll",
            height: "380px",
            mt: 2,
            "::-webkit-scrollbar": {
              height: "5px",
              background: "#DCDEEB",
              display: databases?.length > 7 ? "unset !important" : "none",
            },
          }}
        >
          <InfiniteScroll
            style={{
              overflowX: "hidden",
              scrollbarWidth: "thin",
            }}
            dataLength={databases?.length}
            next={() => getDatabases(lastKey)}
            hasMore={true}
            scrollableTarget="talentList"
            scrollThreshold={"50px"}
            endMessage={
              <p style={{ textAlign: "center" }}>
                <b>Yay! You have seen it all</b>
              </p>
            }
          >
            {databases?.length > 0 ? (
              databases.map((item, index) => {
                return (
                  <Box sx={{ display: "flex", mt: 2 }}>
                    <Box
                      component="img"
                      className="profileAvatar"
                      alt="crayon logo"
                      src={
                        item?.profile_url !== "No URL"
                          ? item?.profile_url
                          : profile
                      }
                      sx={{
                        mr: 1,
                        height: "35px !important",
                        width: "35px !important",
                        borderRadius: "5px",
                      }}
                    />
                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                      <Typography
                        sx={{
                          fontSize: "12px",
                          fontWeight: 700,
                        }}
                      >
                        {`${item?.name}`}
                      </Typography>
                    </Box>
                  </Box>
                );
              })
            ) : isLoading ? (
              <Box
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CircularProgress color="inherit" size={20} />
              </Box>
            ) : (
              <Typography
                sx={{
                  fontSize: "12px",
                  fontWeight: theme.typography.Medium,
                  textAlign: "center",
                }}
              >
                No database
              </Typography>
            )}
            <style>
              {`.infinite-scroll-component::-webkit-scrollbar {
                      width: 7px !important;
                      background-color: #F5F5F5; /* Set the background color of the scrollbar */
                    }

                    .infinite-scroll-component__outerdiv {
                      height:100%;
                      width: 100%;
                    }

                    .infinite-scroll-component::-webkit-scrollbar-thumb {
                      background-color: #888c; /* Set the color of the scrollbar thumb */
                    }`}
            </style>
          </InfiniteScroll>
        </Box>
      </Box>
    </Box>
  );
}
