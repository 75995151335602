import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import CompanyCard from "./CompanyCard";
import { setAlert } from "../../../../redux/configSlice";
import { ALERT_TYPE } from "../../../../utils/Constants";
import { getUserCompanyList } from "../../../../redux/admin/jobsSlice";
import CompanyCardNew from "./CompanyCardNew";

export default function CompaniesDetailInfo() {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [companyData, setCompanyData] = useState([]);

  const getCompanyList = async (lastkeyy, compid, searchTitle) => {
    try {
      const { payload } = await dispatch(
        getUserCompanyList({
          lastKey: lastkeyy,
          company_id: id ? id : "",
          query: searchTitle,
        })
      );

      if (payload?.status === "success") {
        setCompanyData(payload?.data?.[0]);
      } else {
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE.ERROR,
            msg: "ERROR",
          })
        );
      }
    } catch (error) {
      dispatch(
        setAlert({
          show: true,
          type: ALERT_TYPE.ERROR,
          msg: "ERROR",
        })
      );
    }
  };

  useEffect(() => {
    getCompanyList(0);
  }, []);

  return (
    <Box
      sx={{
        marginTop: "55px",
      }}
    >
      <CompanyCardNew
        key={companyData?.company_id}
        companyData={companyData}
        getCompanyList={getCompanyList}
        setCompanyData={setCompanyData}
        allCompanyData={companyData}
      />
    </Box>
  );
}
