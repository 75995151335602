import { Circle } from "@mui/icons-material";
import {
  Box,
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Stack,
} from "@mui/material";
import React, { useState } from "react";
import { USERS_STATUS } from "../../utils/Constants";

export default function ChangeUserStatus({
  openActive,
  anchorRef,
  isAdmin,
  handleListKeyDown,
  handleCloseActive,
  handleStatusChange,
  currentStatus,
  userStatus,
}) {
  // const [userStatus, setUserStatus] = useState([
  // {
  //   id: 1,
  //   name: "Active",
  //   color: "lightGreenButton300",
  //   isAdminActive: isAdmin,
  // },
  // {
  //   id: 2,
  //   name: "Hide",
  //   color: "yellowButton100",
  //   isAdminActive: isAdmin,
  // },
  // {
  //   id: 3,
  //   name: "Suspend",
  //   color: "redButton100",
  //   isAdminActive: isAdmin,
  // },
  // {
  //   id: 4,
  //   name: "Blacklist",
  //   color: "black",
  //   isAdminActive: isAdmin,
  // },
  // ]);

  return (
    <Stack direction="row" spacing={2} sx={{ zIndex: "1000" }}>
      <Box>
        <Popper
          open={openActive}
          anchorEl={anchorRef.current}
          role={undefined}
          placement="bottom-start"
          transition
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === "bottom-start" ? "left top" : "left bottom",
              }}
            >
              <Paper
                sx={{
                  width: "100px !important",
                  borderRadius: "0px !important",
                }}
              >
                <ClickAwayListener onClickAway={handleCloseActive}>
                  <MenuList
                    autoFocusItem={openActive}
                    id="composition-menu"
                    aria-labelledby="composition-button"
                    onKeyDown={handleListKeyDown}
                  >
                    {userStatus
                      ?.filter((item) => item?.id !== currentStatus)
                      ?.map((item) => {
                        return (
                          <MenuItem
                            onClick={(event) => {
                              handleStatusChange(
                                item?.id,
                                item?.name?.toLowerCase()
                              );
                              handleCloseActive(event);
                            }}
                            sx={{ fontSize: "12px", fontWeight: 700 }}
                          >
                            {item?.name}
                            <Circle
                              fontSize="string"
                              color={item?.color}
                              sx={{ marginLeft: "10px" }}
                            />
                          </MenuItem>
                        );
                      })}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </Box>
    </Stack>
  );
}
