import { useState } from "react";
import { useTheme } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Chip from "@mui/material/Chip";
import Box from "@mui/material/Box";
import { CircularProgress } from "@mui/material";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: "250px",
    },
  },
};

function getStyles(option, value, theme) {
  return {
    fontWeight:
      value?.indexOf(option) === -1
        ? theme?.typography?.fontWeightRegular
        : theme?.typography?.fontWeightMedium,
  };
}

export default function SelectMenu({
  type,
  name,
  placeholder,
  sx,
  value,
  defaultValue,
  onHandleChange,
  options,
  disabled = false,
  id,
  input,
  multiple,
  onOpen,
  onFocus,
  onBlur,
  handleScroll,
  borderRadius = "25px",
  border = "1px solid #E0E0E0",
  height = "40px",
  width,
  clear,
  readOnly,
  padding,
}) {
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const loading = open && options?.length === 0;

  return (
    <Paper
      elevation={3}
      sx={{
        display: "flex",
        height: height,
        width: width,
        borderRadius: borderRadius,
        boxShadow: "none",
        ...sx,
      }}
    >
      <Select
        readOnly={readOnly}
        multiple={multiple}
        disabled={disabled}
        input={input}
        onBlur={onBlur}
        onFocus={onFocus}
        id={id}
        loading={loading}
        loadingText={
          loading ? (
            <Box
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CircularProgress color="inherit" size={20} />
            </Box>
          ) : null
        }
        // multiple={type == "multiple"}
        displayEmpty
        name={name}
        value={value}
        defaultValue={defaultValue}
        onChange={onHandleChange}
        onOpen={() => setOpen(true)}
        MenuProps={MenuProps}
        onScroll={handleScroll}
        onClose={() => setOpen(false)}
        sx={{
          width: "100%",
          overflow: "hidden",
          wordBreak: "break-word",
          textOverflow: "ellipsis",
          border: border,
          borderRadius: borderRadius,
          "& .MuiOutlinedInput-notchedOutline": {
            border: "none",
          },
          paddingLeft: padding,
          "& .MuiSelect-select": {
            fontSize: "14px", // Set font size for selected value
          },
        }}
        renderValue={(selected) => {
          if (!selected || selected?.length === 0) {
            return (
              <em style={{ opacity: "0.4", fontStyle: "normal", fontSize: "14px" }}>
                {placeholder}
              </em>
            );
          }
          if (type == "multiple") {
            return (
              <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                {selected?.map((value) => (
                  <Chip
                    variant="outlined"
                    key={value}
                    label={options?.find((item) => item?.id == value)?.name}
                    sx={{ fontSize: "14px" }} // Set font size for Chip
                  />
                ))}
              </Box>
            );
          } else {
            let data = options?.find((item) =>
              item?.id ? item?.id == selected : item?.user_id == selected
            );
            return typeof selected === "string"
              ? selected
              : data?.name
              ? data?.name
              : data?.user?.first_name + " " + data?.user?.last_name;
          }
        }}
      >
        {clear ? (
          <MenuItem value="" sx={{ fontSize: "14px" }}>
            <em>None</em>
          </MenuItem>
        ) : (
          <MenuItem disabled value="" sx={{ fontSize: "14px" }}>
            <em>{placeholder}</em>
          </MenuItem>
        )}
        {options?.length > 0 &&
          options?.map((option) => (
            <MenuItem
              key={option?.id ? option?.id : option?.user_id}
              value={option?.id ? option?.id : option?.user_id}
              sx={{ fontSize: "14px" }} // Set font size for MenuItem
            >
              {option?.id
                ? option?.name
                : option?.user?.first_name + " " + option?.user?.last_name}
            </MenuItem>
          ))}
      </Select>
    </Paper>
  );
}
