import React, { useEffect, useState } from "react";
import {
  changePassword,
  createAdmin,
  deleteAdmin,
  editAdmin,
  getAdmin,
  getRoleList,
} from "../../../../redux/admin/userAdminSlice";
import { useDispatch } from "react-redux";
import {
  deepCompare,
  emailRegex,
  validateSchema,
} from "../../../../utils/Common";
import { setAlert } from "../../../../redux/configSlice";
import { ALERT_TYPE, PASSWORD_REGEX } from "../../../../utils/Constants";
import CommonCreate from "./CommonCreate";
import AdminTable from "./AdminTable";
import * as Yup from "yup";
import Delete from "../../../candidate/myCam/dialog/Delete";
import ChangePasswordModal from "./ChangePasswordModal";

export default function CrayonsSuperAdmin() {
  const dispatch = useDispatch();
  const [tableList, setTableList] = useState([]);
  const [jobTitleCount, setJobTitleCount] = useState(0);
  const [selectedRows, setSelectedRows] = useState([]);
  const [openAdd, setOpenAdd] = useState(false);
  const [formData, setFormData] = useState({});
  const [errors, setErrors] = useState({});
  const [isDelete, setIsDelete] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [selectedPool, setSelectedPool] = useState();
  const [isEditModal, setIsEditModal] = useState(false);
  const [isPasswordChangeModal, setIsPasswordChangeModal] = useState(false);
  const [changePasswordData, setChangePasswordData] = useState({});
  const [roleList, setRoleList] = useState([]);
  const [changePasswordError, setChangePasswordError] = useState({
    change_password: false,
    confirm_password: false,
    errorText: "",
  });
  const getAdminList = async () => {
    try {
      let response = await dispatch(getAdmin({ role_type_id: "superadmin" }));
      if (response?.payload?.status === "success") {
        setTableList(
          response?.payload?.data?.map((item) => ({
            id: item?.user_id,
            firstName: item?.user?.first_name,
            lastName: item?.user?.last_name,
            email: item?.user?.email,
            userId: item?.user_id,
            roleType: { value: 1, label: "superadmin" },
            calendar_link: item?.calendar_link,
            profile_pic: item?.profile_url,
          }))
        );
      }
    } catch (error) {}
  };

  const formValidationSchema = Yup.object().shape({
    first_name: Yup.string().required("First name is required"),
    last_name: Yup.string().required("Last name is required"),
    email: Yup.string()
      .matches(emailRegex, "Invalid email address")
      .required("Email is Required."),
    password: Yup.string()
      .matches(
        PASSWORD_REGEX,
        "Password must contain at least 8 characters, one uppercase, one lowercase, one number, and one special character"
      )
      .required("Password required"),
    calendar_link: Yup.string().required("Calendar link is required"),
  });
  const handleSelectionChange = (selection) => {
    setSelectedRows(selection);
  };
  const handleOpenAdd = () => {
    setOpenAdd(true);
  };
  const handleSearch = () => {};
  const getTitles = () => {};
  const handleEditDialog = (data) => {
    setIsEditModal(true);
    setOpenAdd(true);
    setFormData({
      ...data,
      first_name: data?.firstName,
      last_name: data?.lastName,
      email: data?.email,
      userId: data?.userId,
    });
  };
  const handleDeleteDialog = (pool) => {
    setConfirmDelete(false);
    setSelectedPool(pool?.userId);
    setIsDelete(true);
  };

  const handleFormInput = (event, name) => {
    let updatedData = { ...formData };
    updatedData[name] = event.target.value;
    const updatedError = errors;
    delete updatedError[name];
    setErrors(updatedError);
    setFormData({ ...updatedData });
  };

  const handleCancel = () => {
    setIsEditModal(false);
    setErrors({});
    setOpenAdd(false);
    setFormData({});
  };

  useEffect(() => {
    getAdminList();
  }, []);

  const handleCreate = async () => {
    const data = {
      ...formData,
      first_name: formData?.first_name,
      last_name: formData?.last_name,
      email: formData?.email,
      password: formData?.password,
      calendar_link: formData?.calendar_link,
      role_id: 1,
    };
    validateSchema(formValidationSchema, data)
      .then(() => {
        try {
          dispatch(createAdmin(data)).then((payload) => {
            if (payload?.payload?.status === "success") {
              getAdminList();
              dispatch(
                setAlert({
                  show: true,
                  type: ALERT_TYPE?.SUCCESS,
                  msg: "Admin created successfully",
                })
              );
              setFormData({});
              setOpenAdd(false);
            } else {
              dispatch(
                setAlert({
                  show: true,
                  type: ALERT_TYPE?.ERROR,
                  msg: payload?.payload?.message,
                })
              );
            }
          });
        } catch (error) {
          dispatch(
            setAlert({
              show: true,
              type: ALERT_TYPE?.ERROR,
              msg: "Something went wrong",
            })
          );
        }
      })
      .catch((error) => {
        setErrors(error);
      });
  };
  const handlePasswordModal = (data) => {
    setIsPasswordChangeModal(true);
    setChangePasswordData({ userId: data?.userId });
  };
  const handleChangePasswordOnChange = (e, name) => {
    let updatedData = { ...changePasswordData };
    updatedData[name] = e.target.value;
    setChangePasswordData({ ...updatedData });
    if (!PASSWORD_REGEX?.test(e.target.value)) {
      setChangePasswordError({
        [name]: false,
        errorText:
          e.target.id === "change_password"
            ? "change_password"
            : "confirm_password",
      });
      return;
    } else {
      setChangePasswordError({
        [name]: false,
        errorText: e.target.id === "change_password" ? "" : "",
      });
    }
  };
  const handleChangePasswordSubmit = async () => {
    let payload = {
      userid: changePasswordData?.userId,
      password: changePasswordData?.confirm_password,
    };
    let condition = deepCompare(
      changePasswordData?.change_password,
      changePasswordData?.confirm_password
    );
    if (
      condition?.status &&
      PASSWORD_REGEX?.test(changePasswordData?.change_password) &&
      PASSWORD_REGEX?.test(changePasswordData?.change_password)
    ) {
      try {
        let response = await dispatch(changePassword(payload));
        if (response?.payload?.status === "success") {
          setIsPasswordChangeModal(false);
          setChangePasswordError({
            change_password: false,
            confirm_password: false,
            errorText: "",
          });
          dispatch(
            setAlert({
              show: true,
              type: ALERT_TYPE?.SUCCESS,
              msg: "Password updated successfully",
            })
          );
        }
      } catch (error) {
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE?.ERROR,
            msg: "Something went wrong",
          })
        );
      }
    } else {
      if (condition?.status) {
        setChangePasswordError({
          change_password: false,
          confirm_password: false,
          errorText: "",
        });
      } else {
        setChangePasswordError({
          change_password: true,
          confirm_password: true,
          errorText: "",
        });
      }
    }
  };
  const handleModalClose = () => {
    setIsPasswordChangeModal(false);
    setChangePasswordError({
      change_password: false,
      confirm_password: false,
      errorText: "",
    });
  };
  const handleDel = async () => {
    try {
      const response = await dispatch(deleteAdmin(selectedPool));
      if (response?.payload?.data) {
        getAdminList();
        setIsDelete(false);
      }
    } catch (error) {
      dispatch(
        setAlert({
          show: true,
          type: ALERT_TYPE?.ERROR,
          msg: "Something went wrong",
        })
      );
    }
  };
  const EDIT_ADMIN_SCHEMA = Yup.object().shape({
    first_name: Yup.string().required("First name is required"),
    last_name: Yup.string().required("Last name is required"),
    email: Yup.string()
      .matches(emailRegex, "Invalid email address")
      .required("Email is Required."),
  });

  const handleEdit = async () => {
    let payload = {
      profile_pic: formData?.profile_pic,
      calendar_link: formData?.calendar_link,
      first_name: formData?.first_name,
      last_name: formData?.last_name,
      email: formData?.email,
      userid: formData?.userId,
      roleTypeId: formData?.roleType?.value,
    };
    validateSchema(EDIT_ADMIN_SCHEMA, payload)
      .then(() => {
        try {
          dispatch(editAdmin(payload)).then((payload) => {
            if (payload?.payload?.status === "success") {
              setOpenAdd(false);
              getAdminList();
              dispatch(
                setAlert({
                  show: true,
                  type: ALERT_TYPE?.SUCCESS,
                  msg: "Admin edit successfully",
                })
              );
              setIsEditModal(false);
              setFormData({});
              setErrors({});
            } else {
              dispatch(
                setAlert({
                  show: true,
                  type: ALERT_TYPE?.ERROR,
                  msg: "Something went wrong",
                })
              );
            }
          });
        } catch (error) {
          dispatch(
            setAlert({
              show: true,
              type: ALERT_TYPE?.ERROR,
              msg: "Something went wrong",
            })
          );
        }
      })
      .catch((error) => {
        setErrors(error);
      });
  };

  const handleCreateSelectOnChange = (ind, newValue, val, index) => {};

  const getCrayonRoles = async () => {
    try {
      let response = await dispatch(getRoleList());
      if (response?.payload?.status === "success") {
        setRoleList(response?.payload?.data);
      }
    } catch (error) {
      console.log(error, "error");
    }
  };

  useEffect(() => {
    getCrayonRoles();
  }, [openAdd]);

  return (
    <>
      {openAdd ? (
        <CommonCreate
          errors={errors}
          formData={formData}
          handleFormInput={handleFormInput}
          handleCancel={handleCancel}
          handleCreate={isEditModal ? handleEdit : handleCreate}
          pageTitle={"Super Admin"}
          edit={isEditModal}
          handleCreateSelectOnChange={handleCreateSelectOnChange}
          roleList={roleList}
          setFormData={setFormData}
        />
      ) : (
        <AdminTable
          handleOpenAdd={handleOpenAdd}
          tableList={tableList}
          jobTitleCount={jobTitleCount}
          handleEditDialog={handleEditDialog}
          handleDeleteDialog={handleDeleteDialog}
          pageTitle={"Super Admins"}
          rightButtonText={"create super admin"}
          handeResetPassword={handlePasswordModal}
          width={"180px"}
        />
      )}
      {isDelete && (
        <Delete
          show={isDelete}
          handleOpen={() => setIsDelete(false)}
          handleDelete={handleDel}
          dialogText={"admin"}
          handleCancel={() => setIsDelete(false)}
          confirmDelete={confirmDelete}
          setConfirmDelete={setConfirmDelete}
        />
      )}
      {isPasswordChangeModal && (
        <ChangePasswordModal
          errors={changePasswordError}
          formData={changePasswordData}
          handleFormInput={handleChangePasswordOnChange}
          handleCancel={handleModalClose}
          handleSubmit={handleChangePasswordSubmit}
          handleOpen={isPasswordChangeModal}
          handleClose={handleModalClose}
        />
      )}
    </>
  );
}
