import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import locale from "../../../i18n/locale";
import InfiniteScroll from "react-infinite-scroll-component";
import { ALERT_TYPE } from "../../../utils/Constants";
import { useTheme } from "@emotion/react";
import eye from "../../../assets/Padding Excluded/Black_Eye.svg";
import eyeWhite from "../../../assets/Padding Excluded/Black_view._job.svg";
import settings from "../../../assets/Padding Excluded/White_Settings.svg";
import AlertIcon from "../../../assets/Padding Excluded/AlertNewIcon.svg";
import readAll from "../../../assets/Padding Excluded/White_Email.svg";
import deleteIcon from "../../../assets/Padding Excluded/Black_Trash_Delete.svg";
import { useDispatch } from "react-redux";
import {
  deleteNotification,
  getAlertsList,
  readNotification,
} from "../../../redux/candidate/alerts";
import { setAlert } from "../../../redux/configSlice";
import CloseIcon from "../../common/CloseIcon";

export default function Alerts({
  width,
  rightExpand,
  handleClose,
  handleDecrement,
  setOpenSetting,
  setOpenNotication,
  alertRef,
  getCount,
}) {
  const i18n = locale.en;
  const theme = useTheme();
  const dispatch = useDispatch();
  const [notifications, setNotication] = useState([]);
  const [lastKey, setLastKey] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const getAlerts = async (lastkeyy) => {
    setIsLoading(true);
    try {
      const { payload } = await dispatch(getAlertsList({ lastKey: lastkeyy }));
      if (payload?.status === "success") {
        if (payload?.data?.length === 0 && payload?.pageNumber === 0) {
          setIsLoading(false);
        }
        setLastKey(payload?.pageNumber + 1);
        setNotication((prev) => [...prev, ...payload?.data]);
      } else if (payload === undefined) {
        return;
      } else {
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE?.ERROR,
            msg: payload?.message,
          })
        );
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      dispatch(
        setAlert({
          show: true,
          type: ALERT_TYPE?.ERROR,
          msg: "Error",
        })
      );
    }
  };

  const readMessage = async (logID, readFlag) => {
    // await handleDecrement();
    try {
      const { payload } = await dispatch(
        readNotification({ emailLog: logID, readflag: readFlag })
      );
      if (payload?.status === "success") {
        if (readFlag !== "") {
          setNotication([]);
          await getCount();
          await getAlerts(0);
        } else {
          await getCount();
          setNotication((prev) =>
            prev?.map((item) =>
              item?.email_log_id === logID ? { ...item, read_flag: true } : item
            )
          );
        }
      } else {
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE?.ERROR,
            msg: payload?.message,
          })
        );
      }
    } catch (error) {
      dispatch(
        setAlert({
          show: true,
          type: ALERT_TYPE?.ERROR,
          msg: "Error",
        })
      );
    }
  };

  const deleteMessage = async (logID, readFlag) => {
    // await handleDecrement();
    try {
      const { payload } = await dispatch(
        deleteNotification({ email_log_id: logID })
      );
      if (payload?.status === "success") {
        await getCount();
        setNotication((prev) =>
          prev?.map((item) =>
            item?.email_log_id === logID ? { ...item, read_flag: true } : item
          )
        );
      } else {
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE?.ERROR,
            msg: payload?.message,
          })
        );
      }
    } catch (error) {
      dispatch(
        setAlert({
          show: true,
          type: ALERT_TYPE?.ERROR,
          msg: "Error",
        })
      );
    }
  };

  useEffect(() => {
    getAlerts(0);
  }, []);

  return (
    <>
      <Box
        ref={alertRef}
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          // width: `${width}px`,
          width: width,
          height: "322px",
          background: "#ffff",
          borderRadius: "0 0 25px 25px",
          boxShadow: "0px 5px 10px #00000029 !important",
          zIndex: 999,
          position: "absolute",
          // padding: "0px 0px 0 20px",
          right: 0,
          boxSizing: "border-box",
          marginRight: !rightExpand && "119px",
        }}
      >
        <Button
          variant="contained"
          color="recentButton"
          startIcon={
            <Box
              component="img"
              className="eye"
              alt="eye logo"
              src={AlertIcon}
              sx={{
                height: 16,
                width: 16,
              }}
            />
          }
          sx={{
            width: "150px",
            minWidth: "150px",
            maxWidth: "150px",
            height: "45px",
            borderRadius: "0px 0px 10px 0px",
            fontSize: "14px",
            fontWeight: theme.typography.Bold,
            textAlign: "left",
            justifyContent: "flex-start",
          }}
        >
          {i18n["alert.alert"]}
        </Button>
        <IconButton
          aria-label="close"
          onClick={() => handleClose()}
          sx={{
            position: "absolute",
            right: 8,
            //   color: (theme) => theme.palette.grey[500],
          }}
          disableRipple={true}
        >
          {/* <Box
            sx={{
              width: "20px",
              height: "20px",
              color: "#000000",
              border: 2,
              fontSize: "18px",
              borderRadius: "5px",
            }}
          >
            X
          </Box> */}
          <CloseIcon />
        </IconButton>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            overflowY: "auto",
            height: "209px",
            "::-webkit-scrollbar": {
              height: "5px",
              background: "#DCDEEB",
              display: "unset !important",
            },
          }}
          id="matchTaleneList"
        >
          <InfiniteScroll
            style={{
              overflowX: "hidden",
              scrollbarWidth: "thin",
              display: "flex",
              flexDirection: "column",
              gap: "11px",
            }}
            dataLength={notifications?.length || 0}
            next={() => getAlerts(lastKey)}
            hasMore={true}
            // loader={
            // <Box
            //   style={{
            //     margin: "15px 0 0 0",
            //     display: "flex",
            //     justifyContent: "center",
            //     alignItems: "center",
            //   }}
            // >
            //   <CircularProgress color="inherit" size={20} />
            // </Box>
            // }
            scrollableTarget="matchTaleneList"
            scrollThreshold={"50px"}
          >
            {notifications?.length > 0 ? (
              notifications?.map((item, index) => {
                return (
                  <Box
                    sx={{
                      display: "flex",
                      padding: "0 10px 0 18px",
                      alignItems:"center"
                      // width: "100%",
                    }}
                  >
                    {/* <Tooltip
                      disableInteractive
                      title={item?.subject}
                      placement="top-end"
                    > */}
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontWeight: item?.read_flag
                          ? theme.typography.Regular
                          : theme.typography.Bold,
                        width: "100%",
                      }}
                      noWrap
                    >
                      {item?.subject}
                    </Typography>
                    {/* </Tooltip> */}
                    <Box
                      component="img"
                      className="eye"
                      alt="eye logo"
                      src={eye}
                      sx={{
                        marginRight:"5px",
                        height: 20,
                        width: 20,
                        maxHeight: { xs: 20 },
                        maxWidth: { xs: 20 },
                        cursor: "pointer",
                      }}
                      onClick={() => readMessage(item?.email_log_id, "")}
                    />
                    <Box
                      component="img"
                      alt="delete"
                      src={deleteIcon}
                      sx={{
                        height: 16,
                        width: 15,
                        maxHeight: { xs: 16 },
                        maxWidth: { xs: 15 },
                        cursor: "pointer",
                      }}
                      onClick={() => deleteMessage(item?.email_log_id)}
                    />
                  </Box>
                );
              })
            ) : isLoading ? (
              <Box
                style={{
                  margin: "15px 0 0 0",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CircularProgress color="inherit" size={20} />
              </Box>
            ) : (
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: theme.typography.Bold,
                  }}
                >
                  No notifications
                </Typography>
              </Box>
            )}
          </InfiniteScroll>
        </Box>

        <Box>
          <Button
            onClick={() => {
              setOpenSetting(true);
              setOpenNotication(false);
            }}
            variant="contained"
            color="employerButton"
            sx={{
              width: "50%",
              minHeight: "60px",
              maxHeight: "60px",
              height: "60px",
              borderRadius: "0 0 0 25px",
              fontSize: "14px",
              fontWeight: theme.typography.Bold,
            }}
            startIcon={
              <Box
                component="img"
                className="eye"
                alt="eye logo"
                src={settings}
                sx={{
                  height: 26,
                  width: 26,
                }}
              />
            }
          >
            {i18n["alert.setting"]}
          </Button>
          <Button
            variant="contained"
            color="deleteVideoButton"
            sx={{
              width: "50%",
              minHeight: "60px",
              maxHeight: "60px",
              height: "60px",
              borderRadius: "0 0 25px 0",
              fontSize: "14px",
              fontWeight: theme.typography.Bold,
            }}
            onClick={() => readMessage("", true)}
            startIcon={
              <Box
                component="img"
                className="eye"
                alt="eye logo"
                src={eyeWhite}
                sx={{
                  height: 26,
                  width: 26,
                }}
              />
            }
          >
            {i18n["alert.readAll"]}
          </Button>
        </Box>
      </Box>
    </>
  );
}
