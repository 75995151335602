import * as React from "react";
import Box from "@mui/material/Box";
import { Grid, Typography, useTheme } from "@mui/material";

const steps = [
  {
    label: "Apply",
    description: `Apply if you're a fit`,
  },
  {
    label: "Q&A",
    description:
      "Answer the Q&A section",
  },
  {
    label: "It's selfie time!",
    description: `Record your application video if the job requires it
    (you can do this later too)`,
  },
  {
    label: "Join Crayon and complete your profile",
    description: `If you're not already with us, sign-up
    and complete your profile`,
  },
  {
    label: "Crayon vitae",
    description: `Complete at least ‘the basics’ section of your Crayon Vitae, but remember, the more you complete, the better you can compete!`,
  },
  {
    label: "Track",
    description: `Review the stage and status of your application on your Crayon dashboard`,
  },
];

export default function VerticalStepper({color}) {
  const theme = useTheme()
  return (
    <Grid container display="flex" margin={"auto"} padding={"20px"} paddingTop={"0px"}>
      <Box style={{ marginLeft: "20px" }}>
        <Grid container sx={{ marginTop: "20px" }}>
          {steps?.map((step, index) => (
            <Grid item xs={12} key={index} sx={{ marginTop: "10px" }}>
              <Box
                style={{
                  position: "relative",
                  paddingLeft: "20px",
                }}
              >
                {index < steps?.length-1 && (
                  <Box
                    style={{
                      position: "absolute",
                      left: "-10px",
                      top: "35px",
                      height: "95%",
                      borderLeft: `2px solid ${theme.palette?.[color]?.main}`,
                    }}
                  />
                )}
                <Box
                  style={{
                    backgroundColor: theme.palette?.[color]?.main,
                    borderRadius: "50%",
                    color: "#FFFFFF",
                    width: "30px",
                    height: "30px",
                    textAlign: "center",
                    lineHeight: "30px",
                    fontWeight: "bold",
                    position: "absolute",
                    top: "0px",
                    left: "-23px",
                  }}
                >
                  {index + 1}
                </Box>
                <Box style={{ marginLeft: "15px" }}>
                  <Typography
                    sx={{
                      fontWeight: "bold",
                      fontSize: 14,
                      letterSpacing: "0px",
                      // marginBottom: "8px",
                    }}
                  >
                    {step?.label}
                  </Typography>
                  <Typography
                    sx={{
                      fontWeight: theme.typography.Medium,
                      fontSize: 14,
                      letterSpacing: "0px",
                      marginBottom: "8px",
                    }}
                  >
                    {step?.description}
                  </Typography>
                </Box>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Grid>
  );
}
