import React, { useEffect, useRef, useState } from "react";
import { Box, Button, Skeleton, Tooltip } from "@mui/material";
import { nanoid } from "@reduxjs/toolkit";
import NewSmallButtonSlider from "../../common/NewSmallButtonSlider";

const JobsSlider = React.memo(
  ({
    items,
    color,
    type,
    flexwrap = "nowrap",
    gap = 0,
    job,
    jobslength,
    index,
    isBackSide,
    id,
    currentPageJobsCount,
    isHiringCard,
    companyJobsCalled,
    mobileText = false,
    ...props
  }) => {
    const boxRef = useRef(null);
    const moreRef = useRef(null);
    const tagRefs = useRef([]);
    const [buttonWidth, setButtonWidth] = useState([]);
    const [tagsLoading, settagsLoading] = useState(job ? true : false);
    const [open, setOpen] = useState(false);

    // useEffect(() => {
    //   if (items?.length > 0) {
    //     if (type) {
    //       const jobIndustries = items
    //         ?.map((industry) => industry?.industry?.name)
    //         ?.map((name) => ({
    //           name,
    //           width: 0,
    //         }))
    //       setButtonWidth([...jobIndustries])
    //     } else {
    //       let list = items?.map((name) => ({
    //         name,
    //         width: 0,
    //       }))
    //       setButtonWidth([...list])
    //     }
    //   }
    // }, [items])

    const arrayDiff = (array1, array2) => {
      return array1?.filter((item) => array2?.indexOf(item) < 0);
    };

    const remaining = (value) => {
      const arrayOfNames = visibleButtons?.map((obj) => obj);
      const difference = arrayDiff(items, arrayOfNames);
      return difference?.join(", ");
    };

    const [visibleButtons, setVisibleButtons] = useState([]);
    const [remainingButtons, setRemainingButtons] = useState(0);

    // const calculateVisibleButtons = () => {
    //   let cardWidth = document.querySelector('.slider')?.clientWidth
    //   let totalWidth = 35.6875
    //   let visibleButton = []
    //   let remainingButton = 0

    //   for (const item of buttonWidth) {
    //     if (
    //       totalWidth + item?.width <= cardWidth - 20 ||
    //       totalWidth + item?.width <= cardWidth
    //     ) {
    //       totalWidth += item?.width
    //       visibleButton?.push(item)
    //     } else {
    //       remainingButton++
    //     }
    //   }
    //   setVisibleButtons(visibleButton)
    //   setRemainingButtons(remainingButton)
    // }

    // useEffect(() => {
    //   calculateVisibleButtons()
    // }, [buttonWidth, items, boxRef.current?.clientWidth])
    const handleTouch = (event) => {
      event.stopPropagation();
      setOpen(!open);
    };
    const handleClickOutside = (event) => {
      if (moreRef.current) {
        setOpen(false);
      }
    };

    useEffect(() => {
      if (
        index <= jobslength &&
        (index >= jobslength - currentPageJobsCount || isBackSide) &&
        ((isHiringCard && companyJobsCalled) ||
          (!isHiringCard && !companyJobsCalled))
      ) {
        if (items?.length) {
          const moreTagWidth = 40;
          const containerWidth =
            boxRef?.current?.getBoundingClientRect()?.width - moreTagWidth;
          let totalWidth = 0;
          let count = 0;
          for (let i = 0; i < items?.length; i++) {
            const tempTagWidth =
              tagRefs.current[i]?.getBoundingClientRect().width + 5 || 0;
            totalWidth += tempTagWidth;

            if (totalWidth <= containerWidth) {
              count++;
            } else {
              break;
            }
          }

          if (
            count + 1 == items?.length &&
            totalWidth <= containerWidth + moreTagWidth
          ) {
            count++;
          }
          if (count == items?.length && (totalWidth || items?.length == 0)) {
            moreRef.current.style.display = "none";
          } else {
            moreRef.current.style.display = "inline";
          }
          setVisibleButtons(items?.slice(0, count));
          setRemainingButtons(items?.length - count);
        } else {
          moreRef.current.style.display = "none";
        }
        settagsLoading(false);
      }
    }, [tagsLoading, tagRefs?.current]);

    useEffect(() => {
      document.addEventListener("click", handleClickOutside, true);
      return () => {
        document.removeEventListener("click", handleClickOutside, true);
      };
    }, []);

    return (
      <>
        <Box
          ref={boxRef}
          className="slider"
          key={id + "skeleton"}
          sx={{
            width: "98%",
            gap: gap,
            height: "25px",
            justifyContent: "space-between",
            display:
              (!tagsLoading && tagRefs?.current?.length && items?.length) ||
              (!items?.length && !tagsLoading && !tagRefs?.current?.length)
                ? "none"
                : "flex",
          }}
        >
          <Skeleton variant="rounded" width={260} height={25} />
          <Skeleton variant="rounded" width={40} height={25} />
        </Box>
        <Box
          ref={boxRef}
          key={id + "slider"}
          className="slider"
          sx={{
            width: "100%",
            display: "flex",
            gap: gap,
            overflow: "hidden",
            ...props,
          }}
        >
          {visibleButtons?.map((item, index) => (
            <NewSmallButtonSlider
              setButtonWidth={setButtonWidth}
              buttonWidth={buttonWidth}
              tagsRef={(el) => (tagRefs.current[index] = el)}
              fontSize={12}
              key={nanoid()}
              color={color}
              height={25}
              index={index}
              value={item}
              label={item}
              mr="5px"
            />
          ))}
          <Tooltip
            disableInteractive
            open={open}
            onClose={() => setOpen(false)}
            disableHoverListener={mobileText}
            disableTouchListener={mobileText}
            disableFocusListener={mobileText}
            title={
              remaining(remainingButtons)
                ? remaining(remainingButtons)
                : `+ ${remainingButtons}`
            }
            placement="top-end"
          >
            <Button
              ref={moreRef}
              className="tagbutton"
              variant="contained"
              color={color}
              onTouchStart={mobileText ? handleTouch : null}
              onMouseEnter={!mobileText ? () => setOpen(true) : null}
              onMouseLeave={!mobileText ? () => setOpen(false) : null}
              sx={{
                cursor: "default",
                fontSize: 12,
                letterSpacing: "0.75px",
                height: 25,
                boxShadow: 0,
                borderRadius: "5px",
                minWidth: "fit-content",
                padding: "0 8px",
                ".MuiButton-startIcon": {
                  marginLeft: 0,
                },
                ":hover": {
                  boxShadow: "none",
                },
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              +{remainingButtons}
            </Button>
          </Tooltip>
        </Box>
      </>
    );
  }
);

export default JobsSlider;
