import {
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import React from "react";
import strongMatch from "../../../assets/CrayBotIcons/Crayon Craybot Notification-07.svg";
import averageMatch from "../../../assets/CrayBotIcons/Crayon Craybot Notification-09.svg";
import weakMatch from "../../../assets/CrayBotIcons/Crayon Craybot Notification-05.svg";
import MUIRadialChart from "../../common/MUIRadialChart";
import close from "../../../assets/Padding Included/Black_Close.svg";
import StatusButton from "./StatusButton";
import { Link } from "react-router-dom";
import viewIcon from "../../../assets/Padding Excluded/White_View.svg";
import jwt_decode from "jwt-decode";
import locale from "../../../i18n/locale";
import { LightTooltip } from "../../../utils/CommonComponent";
import matchMeIcon from "../../../assets/Padding Excluded/Black_Match_me.svg";
import CloseIcon from "../../common/CloseIcon";

const MatchMeButton = ({
  closeFunc,
  job,
  myStatus,
  handleCandidateStatus,
  cardWidth,
  match,
  setMatch,
}) => {
  const theme = useTheme();
  const i18n = locale.en;

  const token = localStorage?.getItem("token");
  let decodedToken;
  if (token) {
    decodedToken = jwt_decode(token);
  }

  const getColor = (value) => {
    if (value <= 24) {
      return "weakMatch";
    } else if (value <= 74) {
      return "averageMatch";
    } else {
      return "strongMatch";
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "560px",
        width: "100%",
      }}
    >
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Box sx={{ marginBottom: "15px" }}>
          <Button
            variant="contained"
            color="yoco"
            sx={{
              borderRadius: "25px 0px 10px 0px",
              color: "white",
              fontWeight: 400,
              ":hover": {
                boxShadow: "none !important",
              },
              width: "150px",
              height: "45px",
              ".MuiButton-startIcon": {
                marginRight: "0px !important",
                marginLeft: 0,
              },
              padding: "0px !important",
              gap: 1,
            }}
            startIcon={
              <Box
                component={"img"}
                sx={{
                  height: 20,
                  width: 20,
                }}
                src={matchMeIcon}
              />
            }
          >
            {i18n["matchMeBox.beta"]}
          </Button>
          <Box onClick={closeFunc}>
            <CloseIcon />
          </Box>
        </Box>

        <Box
          sx={{
            padding: "5px 16px",
            height: "150px",
            marginBottom: "12px",
          }}
        >
          <Box
            mb={2}
            sx={{
              display: "flex",
              gap: 1,
            }}
          >
            <Typography
              sx={{
                fontSize: theme.typography.fontSize.sm,
                fontWeight: theme.typography.Bold,
                color: "black",
              }}
            >
              {i18n["matchMeBox.matchPer"]}
            </Typography>
            <Tooltip disableInteractive>
              <LightTooltip
                title={
                  "This percentage match does not necessarily mean you will or will not be shortlisted for a job, but it helps provide a quick indicator of your suitability. "
                }
                placement="right-end"
              >
                <Typography
                  sx={{
                    padding: "5px",
                    height: "8px",
                    width: "8px",
                    borderRadius: "5px",
                    fontSize: "15px",
                    lineHeight: 0.1,
                    /* text-align: center; */
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    fontWeight: 700,
                    border: 1,
                  }}
                >
                  i
                </Typography>
              </LightTooltip>
            </Tooltip>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
            }}
          >
            <Typography
              sx={{
                fontSize: theme.typography.fontSize.xs,
                width: `${cardWidth - 38}px`,
                color: "black",
                fontWeight: theme.typography.Medium,
              }}
            >
              {i18n["matchMeBox.craybotInfo"]}
            </Typography>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
                width: "266px",
                height: "75px",
              }}
            >
              <Box
                component={"img"}
                src={
                  match?.matchingPercentage <= 25
                    ? weakMatch
                    : match?.matchingPercentage <= 75
                    ? averageMatch
                    : strongMatch
                }
                sx={{ width: 75, height: 75, position: "absolute", left: 0 }}
              />
              <Typography
                sx={{
                  fontSize: theme.typography.fontSize.xs,
                  textAlign: "left",
                  width: "200px",
                  color: "black",
                  fontWeight: theme.typography.Bold,
                }}
              >
                {i18n["matchMeBox.remember"]}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          gap: "32px",
          flexDirection: "column",
          margin: "auto",
          width: "100%",
          marginBottom: "38px",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          {match ? (
            <MUIRadialChart
              value={match?.matchingPercentage}
              chartName={"match"}
              max={100}
              size={135}
              countFontSize={theme.typography.fontSize.xl}
              labelFontSize={theme.typography.fontSize.xs}
              countFontWeight={theme.typography.Bold}
              labelFontWeight={theme.typography.Bold}
              color={getColor(match?.matchingPercentage)}
              formattedValue={`${match?.matchingPercentage} %`}
            />
          ) : (
            <Box
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                margin: "0px",
                height: "100px",
                width: "100px",
              }}
            >
              <CircularProgress color="inherit" size={20} />
            </Box>
          )}
        </Box>
        <Typography
          sx={{
            fontWeight: theme.typography.Bold,
            fontSize: theme.typography.fontSize.sm,
            textAlign: "center",
            width: "75%",
            color: "black",
          }}
        >
          {match &&
            (match?.matchingPercentage <= 25
              ? `Craybot thinks you’re a weak match for this role.`
              : match?.matchingPercentage <= 75
              ? `Craybot think you’re an average match for this role!`
              : `Craybot thinks you’re a strong match for this role!`)}
        </Typography>
      </Box>
      <Grid
        container
        alignItems="center"
        overflow={"hidden"}
        sx={{
          width: "100%",
          borderRadius: "0 0 25px 25px",
          height: "60px",
        }}
      >
        <Link
          to={
            job?.post_incognito
              ? `/jobs/${job?.country_name?.replace(
                  /\s|\//g,
                  ""
                )}/${job?.town_name?.replace(
                  /\s|\//g,
                  ""
                )}/${job?.title?.replace(/\s|\//g, "")}/${job?.job_id}`
              : `/jobs/${job?.country_name?.replace(
                  /\s|\//g,
                  ""
                )}/${job?.town_name?.replace(
                  /\s|\//g,
                  ""
                )}/${job?.CompanyName?.replace(
                  /\s|\//g,
                  ""
                )}/${job?.title?.replace(/\s|\//g, "")}/${job?.job_id}`
          }
          target={"_blank"}
          style={{
            textDecoration: "none",
            color: theme.palette.black,
            width: "50%",
            height: "100%",
          }}
        >
          <Button
            variant="contained"
            sx={{
              borderRadius: 0,
              fontSize: "14px",
              fontWeight: "bold",
              whiteSpace: "nowrap",
              height: "100%",
              width: "100%",
              justifyContent: "center",
              ".MuiButton-startIcon": {
                marginRight: "0px !important",
                marginLeft: 0,
                justifyContent: "end",
                width: "26px",
              },
              padding: "0px !important",
              boxShadow: "none !important",
            }}
            color="tymeColor"
            startIcon={
              <Box
                component={"img"}
                sx={{
                  height: 17,
                  width: 28,
                  padding: 0,
                  paddingRight:"5px"
                }}
                src={viewIcon}
              />
            }
          >
            {i18n["matchMeBox.viewMore"]}
          </Button>
        </Link>

        <Box
          sx={{
            width: "50%",
            height: "100%",
            postion: "relative",
          }}
        >
          <StatusButton
            theme={theme}
            matchButton={true}
            selectedStatus={job?.candidate_status}
            options={myStatus?.filter(
              (status) =>
                status.id !== 1111 &&
                status.id !== job?.candidate_job_status_id &&
                status.id !== 1
            )}
            handleStatusChange={handleCandidateStatus}
            cardWidth={`${cardWidth / 2}px`}
          />
        </Box>
      </Grid>
    </Box>
  );
};

export default MatchMeButton;
