import { Box, Button, Typography, useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import locale from "../../../i18n/locale";
import specIcon from "../../../assets/Padding Excluded/White_scrapeSpec.svg";
import scrapeLinkedinIcon from "../../../assets/MyCV/Black_View.svg";
import interanlCrayBot from "../../../assets/CrayBotIcons/Crayon Craybot Notification-01.svg";
import externalCrayBot from "../../../assets/CrayBotIcons/Crayon Craybot Notification-10.svg";
import partnerCrayBot from "../../../assets/CrayBotIcons/Crayon Craybot Notification-07.svg";
import noBountyCrayBot from "../../../assets/CrayBotIcons/Crayon Craybot Notification-09.svg";
import { ToggleSwitch } from "../../../utils/CommonComponent";
import nextIcon from "../../../assets/Black_Right_Next.svg";
import previousIcon from "../../../assets/Black_Left_Previous.svg";
import { useNavigate, useParams } from "react-router-dom";
import { getDecodedToken, getToken } from "../../../utils/Common";
import PostJobPopUp from "./dialog/PostJobPopUp";
import {
  getBountyInfo,
  saveBountyDetail,
} from "../../../redux/employer/postJobSlice";
import { setAlert, setLoading } from "../../../redux/configSlice";
import { ALERT_TYPE, ERROR_MSG } from "../../../utils/Constants";
import { useDispatch } from "react-redux";
import CircularRangeSlider from "../CircularRangeSlider";
import { formatCurrencyWithCommas } from "../../../utils/Currency";
import SavaAndExit from "./dialog/SavaAndExit";

export default function Bounty({
  changeStep,
  handleComplete,
  isEditPostjob,
  setSelectedPage,
  setActiveStep,
}) {
  const i18n = locale.en;
  const theme = useTheme();
  const history = useNavigate();
  const decodedToken = getDecodedToken(getToken());
  const dispatch = useDispatch();
  const [internalBounty, setInternalBounty] = useState(5000);
  const [externalBounty, setExternalBounty] = useState(5000);
  const [partnerBounty, setPartnerBounty] = useState(5000);
  const { jobId } = useParams();
  const [bountyAmount, setBountyAmount] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [postJobButton, setPostJobButton] = useState(false);
  const [openSaveAndExitDialog, setOpenSaveAndExitDialog] = useState(false);

  const handlePostJob = (text) => {
    if (text === "jobs") {
      history(
        decodedToken?.data?.role_id === 6
          ? "/recruiter/my-jobs"
          : "/employer/my-jobs"
      );
    } else {
      handleComplete(4);
      changeStep(5);
    }
  };

  const handleSelectChange2 = (event, id, name, index) => {
    if (name === "no_bounty_amount_flag" && id) {
      setBountyAmount({
        ...bountyAmount,
        internal_bounty_amount: 0,
        external_bounty_amount: 0,
        recruiter_bounty_amount: 0,
        [name]: event?.target?.checked,
      });
    } else if (name === "no_bounty_amount_flag" && !id) {
      setBountyAmount({
        ...bountyAmount,
        internal_bounty_amount: bountyAmount?.suggested_internal_bounty_amount,
        external_bounty_amount: bountyAmount?.suggested_external_bounty_amount,
        recruiter_bounty_amount:
          bountyAmount?.suggested_recruiter_bounty_amount,
        [name]: event?.target?.checked,
      });
    } else if (name === "singal_bounty_amount_flag" && id) {
      setBountyAmount({
        ...bountyAmount,
        internal_bounty_amount: 0,
        recruiter_bounty_amount: 0,
        [name]: event?.target?.checked,
      });
    } else if (name === "singal_bounty_amount_flag" && !id) {
      setBountyAmount({
        ...bountyAmount,
        internal_bounty_amount: bountyAmount?.suggested_internal_bounty_amount,
        external_bounty_amount: bountyAmount?.suggested_external_bounty_amount,
        recruiter_bounty_amount:
          bountyAmount?.suggested_recruiter_bounty_amount,
        [name]: event?.target?.checked,
      });
    } else {
      setBountyAmount({ ...bountyAmount, [name]: event?.target?.checked });
    }
  };

  const getBounty = async () => {
    try {
      // setIsLoading(true);
      dispatch(setLoading(true));
      const { payload } = await dispatch(getBountyInfo(jobId));
      if (payload?.status === "success") {
        setBountyAmount(payload?.data);
      } else {
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE?.ERROR,
            msg: payload?.message,
          })
        );
      }
      // setIsLoading(false);
      dispatch(setLoading(false));
    } catch (error) {
      // setIsLoading(false);
      dispatch(setLoading(false));
      dispatch(
        setAlert({
          show: true,
          type: ALERT_TYPE?.ERROR,
          msg: ERROR_MSG,
        })
      );
    }
  };

  const saveBounty = async (text) => {
    dispatch(setLoading(true));
    try {
      let data = {
        internal_bounty_amount:
          bountyAmount?.no_bounty_amount_flag ||
          bountyAmount?.singal_bounty_amount_flag
            ? 0
            : bountyAmount?.internal_bounty_amount === null
            ? Math.round(bountyAmount?.suggested_internal_bounty_amount)
            : Math.round(bountyAmount?.internal_bounty_amount),
        external_bounty_amount: bountyAmount?.no_bounty_amount_flag
          ? 0
          : bountyAmount?.external_bounty_amount === null
          ? Math.round(bountyAmount?.suggested_external_bounty_amount)
          : Math.round(bountyAmount?.external_bounty_amount),
        recruiter_bounty_amount:
          bountyAmount?.no_bounty_amount_flag ||
          bountyAmount?.singal_bounty_amount_flag
            ? 0
            : bountyAmount?.recruiter_bounty_amount === null
            ? Math.round(bountyAmount?.suggested_recruiter_bounty_amount)
            : Math.round(bountyAmount?.recruiter_bounty_amount),
        job_id: jobId,
        singal_bounty_amount_flag:
          bountyAmount?.singal_bounty_amount_flag === null
            ? false
            : bountyAmount?.singal_bounty_amount_flag,
        no_bounty_amount_flag:
          bountyAmount?.no_bounty_amount_flag === null
            ? false
            : bountyAmount?.no_bounty_amount_flag,
      };
      if (text === "post") {
        data["job_draft"] = false;
      } else if (text === "saveToDraft") {
        data["job_draft"] = true;
      }
      const { payload } = await dispatch(saveBountyDetail(data));
      if (payload?.status === "success") {
        if (text === "culture") {
          changeStep(3);
        } else if (text === "saveToDraft") {
          setOpenSaveAndExitDialog(false);
          setSelectedPage(3);
          setActiveStep(6);
        } else if (text === "preview") {
          handleComplete(4);
          changeStep(5);
        } else if ("post") {
          setPostJobButton(true);
        }
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE?.SUCCESS,
            msg: "Bounty added successfully",
          })
        );
        dispatch(setLoading(false));
      } else {
        dispatch(setLoading(false));
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE?.ERROR,
            msg: payload?.message,
          })
        );
      }
    } catch (error) {
      dispatch(setLoading(false));
      dispatch(
        setAlert({
          show: true,
          type: ALERT_TYPE?.ERROR,
          msg: ERROR_MSG,
        })
      );
    }
  };
  const handleOpenSaveAndExitDialog = () => {
    setOpenSaveAndExitDialog((prevState) => !prevState);
  };
  const handleModalSaveAndExit = () => {
    saveBounty("saveToDraft");
  };
  useEffect(() => {
    jobId && getBounty();
  }, []);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          width: "100%",
          justifyContent: "space-between",
        }}
      >
        <Box>
          <Button
            variant="contained"
            color="checkers"
            sx={{
              minHeight: "45px",
              minWidth: { xs: "", md24: "175px" },
              padding: { xs: "6px 16px", md24: "6px 16px" },
              borderRadius: "25px 0 10px 0",
              textWrap: "nowrap",
              boxShadow: "none",
              "& .MuiButton-startIcon": {
                marginLeft: "0px",
              },
              color: "white",
            }}
          >
            {i18n["postAJob.crayonBounty"]}
          </Button>
        </Box>
        <Box sx={{ display: { xs: "block", tablet: "none" } }}>
          <Button
            variant="contained"
            color="uploadJobspec"
            sx={{
              color: "white",
              minHeight: "45px",
              minWidth: { xs: "", md24: "175px" },
              borderRadius: "0 0 0 10px",
              padding: { xs: "6px", md24: "6px 16px" },
              textWrap: "nowrap",
              boxShadow: "none",
              "& .MuiButton-startIcon": {
                marginLeft: "0px",
              },
            }}
            // onClick={handleFileClick}
            startIcon={
              <Box
                component={"img"}
                src={specIcon}
                sx={{
                  display: { xs: "none", tablet: "block" },
                  height: "20px",
                  width: "26px",
                }}
              />
            }
          >
            upload spec
          </Button>
          <Button
            variant="contained"
            color="parseJobspec"
            sx={{
              color: "white",
              minHeight: "45px",
              minWidth: { xs: "", md24: "175px" },
              padding: { xs: "6px", md24: "6px 16px" },
              borderRadius: "0 25px 0 0",
              textWrap: "nowrap",
              "& .MuiButton-startIcon": {
                marginLeft: "0px",
              },
            }}
            startIcon={
              <Box
                component={"img"}
                src={scrapeLinkedinIcon}
                sx={{
                  display: { xs: "none", tablet: "block" },
                  height: "20px",
                  width: "20px",
                }}
              />
            }
          >
            parse spec
          </Button>
        </Box>
        <Box sx={{ display: { xs: "none", tablet: "block" } }}>
          <Button
            variant="contained"
            color="uploadJobspec"
            sx={{
              color: "white",
              minHeight: "45px",
              minWidth: { xs: "", md24: "175px" },
              borderRadius: "0 0 0 10px",
              textWrap: "nowrap",
              boxShadow: "none",
              "& .MuiButton-startIcon": {
                marginLeft: "0px",
              },
            }}
            // onClick={handleFileClick}
            startIcon={
              <Box
                component={"img"}
                src={specIcon}
                sx={{
                  display: { xs: "none", tablet: "block" },
                  height: "20px",
                  width: "26px",
                }}
              />
            }
          >
            {i18n["postAJob.uploadjobspec"]}
          </Button>
          <Button
            variant="contained"
            color="parseJobspec"
            sx={{
              color: "white",
              minHeight: "45px",
              minWidth: { xs: "", md24: "175px" },
              padding: { xs: "6px", md24: "6px 16px" },
              borderRadius: "0 25px 0 0",
              textWrap: "nowrap",
              "& .MuiButton-startIcon": {
                marginLeft: "0px",
              },
            }}
            startIcon={
              <Box
                component={"img"}
                src={scrapeLinkedinIcon}
                sx={{
                  display: { xs: "none", tablet: "block" },
                  height: "20px",
                  width: "20px",
                }}
              />
            }
          >
            {i18n["postAJob.parsejobspec"]}
          </Button>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          p: { xs: "20px 8px 0 8px", md24: "20px 8px 0 30px" },
          width: "100%",
          gap: "20px",
        }}
      >
        <Typography
          sx={{
            fontSize: "14px",
            fontWeight: theme.typography.Regular,
            width: `calc(100% - 55px)`,
          }}
        >
          {i18n["postAJob.crayonBountyText"]}
        </Typography>
        <Typography
          sx={{
            fontSize: "14px",
            fontWeight: theme.typography.Regular,
            width: `calc(100% - 55px)`,
          }}
        >
          {i18n["postAJob.crayonBountyText1"]}
        </Typography>
        <Box
          sx={{
            display: "flex",
            gap: "13px",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: theme.typography.Regular,
              width: "203px",
            }}
          >
            {i18n["postAJob.crayonBountyText2"]}
          </Typography>
          <ToggleSwitch
            name="singal_bounty_amount_flag"
            color="greenSwitch"
            defaultChecked={bountyAmount?.singal_bounty_amount_flag}
            onChange={(event, id, name) =>
              handleSelectChange2(event, id, "singal_bounty_amount_flag")
            }
          />
        </Box>

        <Box
          sx={{
            display: "flex",
            width: { xs: "100%", md24: `calc(100% - 100px)` },
            justifyContent: { xs: "center", md24: "space-between" },
            gap: "10px",
            flexWrap: { xs: "wrap", mddl: "nowrap" },
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <Typography
              sx={{
                fontSize: "14px",
                fontWeight: theme.typography.Bold,
                width: "100%",
              }}
            >
              {i18n["postAJob.teamBountyamount"]}
            </Typography>
            <CircularRangeSlider
              minvalue={
                bountyAmount ? bountyAmount?.internal_bounty_range?.[0] : []
              }
              maxValue={
                bountyAmount ? bountyAmount?.internal_bounty_range?.[1] : []
              }
              appendToValue={""}
              prepandToValue={bountyAmount ? bountyAmount?.currencySymbol : ""}
              arcColor={`${theme.palette.oros.main}`}
              arcBackgroundColor={`${theme.palette.submitButton.main}`}
              // onChange={setValue1WithLimits}
              value={
                bountyAmount
                  ? bountyAmount?.internal_bounty_amount !== null
                    ? bountyAmount?.internal_bounty_amount
                    : bountyAmount?.suggested_internal_bounty_amount
                  : 0
              }
              // suggestedValue={bountyAmount?.suggested_internal_bounty_amount}
              setBountyAmount={setBountyAmount}
              bountyAmount={bountyAmount}
              id={"internal_bounty_amount"}
            />

            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Box
                component={"img"}
                src={interanlCrayBot}
                sx={{ width: 80, height: 75, marginLeft: "-44px" }}
              />
              <Typography
                sx={{ fontSize: "14px", fontWeight: theme.typography.Regular }}
              >
                {i18n["postAJob.suggestsInternal"]}{" "}
                {
                  <span
                    style={{
                      fontSize: "14px",
                      fontWeight: theme.typography.Bold,
                    }}
                  >
                    {bountyAmount?.currencySymbol}
                    {formatCurrencyWithCommas(
                      bountyAmount?.suggested_internal_bounty_amount
                    )}
                  </span>
                }
                {i18n["postAJob.suggestsInternal2"]}
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <Typography
              sx={{
                fontSize: "14px",
                fontWeight: theme.typography.Bold,
                width: "100%",
              }}
            >
              {i18n["postAJob.externalBountyamount"]}
            </Typography>
            <CircularRangeSlider
              minvalue={
                bountyAmount ? bountyAmount?.external_bounty_range?.[0] : []
              }
              maxValue={
                bountyAmount ? bountyAmount?.external_bounty_range?.[1] : []
              }
              appendToValue={""}
              prepandToValue={bountyAmount ? bountyAmount?.currencySymbol : ""}
              arcColor={`${theme.palette.oros.main}`}
              arcBackgroundColor={`${theme.palette.submitButton.main}`}
              // onChange={setValue1WithLimits}
              value={
                bountyAmount
                  ? bountyAmount?.external_bounty_amount !== null
                    ? bountyAmount?.external_bounty_amount
                    : bountyAmount?.suggested_external_bounty_amount
                  : 0
              }
              // suggestedValue={bountyAmount?.suggested_internal_bounty_amount}
              setBountyAmount={setBountyAmount}
              bountyAmount={bountyAmount}
              id={"external_bounty_amount"}
            />

            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Box
                component={"img"}
                src={externalCrayBot}
                sx={{ width: 80, height: 75, marginLeft: "-16px" }}
              />
              <Typography
                sx={{ fontSize: "14px", fontWeight: theme.typography.Regular }}
              >
                {i18n["postAJob.suggestsInternal"]}{" "}
                {
                  <span
                    style={{
                      fontSize: "14px",
                      fontWeight: theme.typography.Bold,
                    }}
                  >
                    {bountyAmount?.currencySymbol}
                    {formatCurrencyWithCommas(
                      bountyAmount?.suggested_external_bounty_amount
                    )}
                  </span>
                }
                {i18n["postAJob.suggestsInternal2"]}
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <Typography
              sx={{
                fontSize: "14px",
                fontWeight: theme.typography.Bold,
                width: "100%",
              }}
            >
              {i18n["postAJob.partnerBountyamount"]}
            </Typography>
            <CircularRangeSlider
              minvalue={
                bountyAmount ? bountyAmount?.recruiter_bounty_range?.[0] : []
              }
              maxValue={
                bountyAmount ? bountyAmount?.recruiter_bounty_range?.[1] : []
              }
              appendToValue={""}
              prepandToValue={bountyAmount?.currencySymbol}
              arcColor={`${theme.palette.oros.main}`}
              arcBackgroundColor={`${theme.palette.submitButton.main}`}
              // onChange={setValue1WithLimits}
              value={
                bountyAmount
                  ? bountyAmount?.recruiter_bounty_amount !== null
                    ? bountyAmount?.recruiter_bounty_amount
                    : bountyAmount?.suggested_recruiter_bounty_amount
                  : 0
              }
              // suggestedValue={bountyAmount?.suggested_internal_bounty_amount}
              setBountyAmount={setBountyAmount}
              bountyAmount={bountyAmount}
              id={"recruiter_bounty_amount"}
            />

            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Box
                component={"img"}
                src={partnerCrayBot}
                sx={{ width: 80, height: 75, marginLeft: "-44px" }}
              />
              <Typography
                sx={{ fontSize: "14px", fontWeight: theme.typography.Regular }}
              >
                {i18n["postAJob.suggestsInternal"]}{" "}
                {
                  <span
                    style={{
                      fontSize: "14px",
                      fontWeight: theme.typography.Bold,
                    }}
                  >
                    {bountyAmount?.currencySymbol}
                    {formatCurrencyWithCommas(
                      bountyAmount?.suggested_recruiter_bounty_amount
                    )}
                  </span>
                }
                {i18n["postAJob.suggestsInternal2"]}
              </Typography>
            </Box>
          </Box>
        </Box>

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            flexDirection: { xs: "column", md24: "row" },
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography
              sx={{ fontSize: "14px", fontWeight: theme.typography.Regular }}
            >
              {i18n["postAJob.noBountyonthis"]}
            </Typography>
            <ToggleSwitch
              name="no_bounty_amount_flag"
              color="greenSwitch"
              defaultChecked={bountyAmount?.no_bounty_amount_flag}
              onChange={(event, id, name) =>
                handleSelectChange2(event, id, "no_bounty_amount_flag")
              }
            />
          </Box>
          {/* <Box sx={{ display: "flex", alignItems: "center" }}>
            <Box
              component={"img"}
              src={noBountyCrayBot}
              sx={{ width: 80, height: 75 }}
            />
            <Typography>{i18n["postAJob.crayBotwouldbeshocked"]}</Typography>
          </Box> */}
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          mt: "38px",
        }}
      >
        <Button
          variant="contained"
          color="tymeColor"
          sx={{
            width: "150px",
            height: "60px",
            fontSize: "15px",
            fontWeight: theme.typography.Bold,
            borderRadius: "10px 0 0 0",
            color: "white",
          }}
          startIcon={
            <Box
              component={"img"}
              src={previousIcon}
              sx={{
                height: "25px",
                width: "25px",
              }}
            />
          }
          onClick={() => {
            saveBounty("culture");
          }}
          // onClick={() =>
          //   saveCulture(
          //     decodedToken?.data?.role_id === 6
          //       ? "/recruiter/my-jobs"
          //       : "/employer/my-jobs"
          //   )
          // }
          // onClick={handleOpenSaveAndExitDialog}
        >
          culture add
        </Button>
        {/* <Button
          variant="contained"
          color="recordVideoButton"
          sx={{
            width: "150px",
            height: "60px",
            fontSize: "15px",
            fontWeight: theme.typography.Bold,
            borderRadius: 0,
          }}
          onClick={() => {
            saveBounty("preview");
          }}
        >
          preview job
        </Button> */}
        <Button
          variant="contained"
          color="saveAndExitButton"
          sx={{
            width: "150px",
            height: "60px",
            fontSize: theme.typography.fontSize.sm,
            fontWeight: theme.typography.Bold,
            borderRadius: "0",
          }}
          onClick={handleOpenSaveAndExitDialog}
        >
          save to drafts
        </Button>
        <Button
          variant="contained"
          color="detailButton"
          disabled={!bountyAmount?.job_post_flag}
          sx={{
            width: "150px",
            height: "60px",
            fontSize: "15px",
            fontWeight: theme.typography.Bold,
            borderRadius: "0 25px  0 0",
          }}
          endIcon={
            <Box
              component={"img"}
              src={nextIcon}
              sx={{
                height: "25px",
                width: "25px",
              }}
            />
          }
          onClick={() => {
            saveBounty("post");
          }}
        >
          {/*isEditPostjob ? "save job" : "post job"*/}
          post job
        </Button>
      </Box>
      {postJobButton && (
        <PostJobPopUp
          handleClose={() => setPostJobButton((prev) => !prev)}
          show={postJobButton}
          setPostJobButton={setPostJobButton}
          handlePostJob={handlePostJob}
        />
      )}
      {openSaveAndExitDialog && (
        <SavaAndExit
          show={openSaveAndExitDialog}
          handleOpen={handleOpenSaveAndExitDialog}
          handleModalSaveAndExit={handleModalSaveAndExit}
        />
      )}
    </>
  );
}
